<template>
  <div>
    <b-row>
      <b-col cols="12">
        <applicant-data
          ref="appInformation"
          v-model="leadInfo"
          :read-only="readOnly"
        />
      </b-col>

      <b-col cols="12">
        <co-app-data :lead-id="leadId" :read-only="readOnly" v-model="coApp" />
      </b-col>
      <b-col cols="12">
        <income-information v-model="incomes" :read-only="readOnly" />
      </b-col>

      <b-col cols="12">
        <services-information :read-only="readOnly" v-model="hardship">
          <template #total-month>
            $ {{ totalMonthLiving | currency }}
          </template>

          <template #amount-available>
            <div
              class="form-control d-flex align-items-center justify-content-start bg-transparent border"
            >
              $
              {{ Number(totalMonthlyNetIncome - totalMonthLiving) | currency }}
            </div>
          </template>
        </services-information>
      </b-col>

      <b-col cols="12">
        <house-information
          v-model="houses"
          :house-owner="ownHouse"
          @updateOwner="ownHouse = $event"
          :read-only="readOnly"
        />
      </b-col>

      <b-col cols="12">
        <goals-information
          ref="goalsInformation"
          :read-only="readOnly"
          v-model="goals"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CarsInformation from "@/views/debt-solution/views/court-info/components/cards/CarsInformationQuestionnaire.vue";
import ServicesInformation from "@/views/debt-solution/views/court-info/components/cards/ServicesInformationQuestionnaire.vue";
import HouseInformation from "@/views/debt-solution/views/court-info/components/cards/HouseInformationQuestionnaire.vue";
import IncomeInformation from "@/views/debt-solution/views/court-info/components/cards/IncomesInformationQuestionnaire.vue";
import GoalsInformation from "@/views/debt-solution/views/court-info/components/cards/GoalsInformationQuestionnaire.vue";
import ApplicantData from "@/views/debt-solution/views/court-info/components/cards/ApplicantData.vue";
import CoAppData from "@/views/debt-solution/views/court-info/components/cards/CoApplicantData.vue";

import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service.js";
import { mapGetters } from "vuex";

export default {
  components: {
    ApplicantData,
    CoAppData,
    CarsInformation,
    ServicesInformation,
    HouseInformation,
    IncomeInformation,
    GoalsInformation,
  },
  props: {
    leadId: {
      type: [Number, String],
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    caseId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
    }),
    totalMonthlyNetIncome() {
      return (
        this.incomes.c_applicant_monthly +
        this.incomes.adicional_monthly +
        this.incomes.net_income
      );
    },
    totalMonthLiving() {
      const total = this.hardship.cars.reduce(
        (sum, car) => sum + Number(car.amount),
        0
      );
      const totalOthers = this.hardship.others.reduce(
        (sum, other) => sum + Number(other.mont),
        0
      );
      const totalUtilities = this.hardship.utilities.reduce(
        (sum, other) => sum + Number(other.mont),
        0
      );

      const totalCards = this.hardship.cards.reduce(
        (sum, other) => sum + Number(other.pay),
        0
      );

      const totalHouses = this.houses.reduce(
        (sum, item) => sum + Number(item.cost),
        0
      );

      return Number(
        total +
          totalOthers +
          this.hardship.gasoline +
          totalUtilities +
          this.hardship.food +
          this.hardship.insurance +
          totalCards +
          totalHouses
      );
    },
  },

  data() {
    return {
      ownHouse: null,

      leadInfo: {
        dependents: [],
        first_name: "",
        middle_name: "",
        last_name: "",
        status: "",
        dob: "",
        ssn: "",
        cstatus: "",
        employer: "",
        mobile: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        employes: [],
      },
      coApp: {
        first_name: "",
        middle_name: "",
        last_name: "",
        status: "",
        dob: "",
        ssn: "",
        phone: "",
        email: "",
        cstatus: "",
        employer: "",
        dependents: "",
        full_amounts: "",
      },
      incomes: {
        gross_income: 0,
        tax_deduction: 0,
        net_income: 0,
        adicional_monthly: 0,
        c_applicant_monthly: 0,
        applicant_monthly: 0,
      },
      hardship: {
        utilities: [],
        cards: [],
        cars: [
          {
            id: 0,
            amount: 0,
          },
        ],
        others: [],
        insurance: 0,
        food: 0,
        gasoline: 0,
      },
      houses: [],
      goals: [],

      optionsUtilities: [
        { id: 1, text: "Electricity", mont: 0 },
        { id: 2, text: "Gas", mont: 0 },
        { id: 3, text: "Water", mont: 0 },
        { id: 4, text: "Telephone", mont: 0 },
        { id: 5, text: "Cable", mont: 0 },
        { id: 6, text: "Internet", mont: 0 },
        { id: 7, text: "Trash", mont: 0 },
      ],

      optionsHard: [
        { id: 1, text: "Child Support", mont: 0 },
        { id: 2, text: "Spouse Suppport", mont: 0 },
        { id: 3, text: "Clothes", mont: 0 },
        { id: 4, text: "Education", mont: 0 },
        { id: 5, text: "Health & SelfCare", mont: 0 },
        { id: 6, text: "Transportation", mont: 0 },
        { id: 7, text: "Laundry", mont: 0 },
        { id: 8, text: "Donations", mont: 0 },
        { id: 9, text: "Family Support", mont: 0 },
        { id: 10, text: "Recreation", mont: 0 },
        { id: 11, text: "Others", mont: 0 },
      ],
    };
  },

  methods: {
    async validateCards() {
      const appInformation = this.$refs.appInformation;
      const isValidAppInformation = await appInformation.validateForm();
      if (!isValidAppInformation) {
        appInformation.$el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return false;
      }

      const goalsInformation = this.$refs.goalsInformation;
      const isValidHouseInformation = await goalsInformation.validateForm();

      if (!isValidHouseInformation) {
        goalsInformation.$el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return false;
      }
      // if (this.documentType == 1) {
      //   const servicesCard = this.$refs.servicesInformation;
      //   const isValidateServices = await servicesCard.validateForm();
      //   if (!isValidateServices) {
      //     servicesCard.$el.scrollIntoView({
      //       behavior: "smooth",
      //       block: "start",
      //     });
      //     return false;
      //   }
      // }

      // if (this.documentType == 1) {
      //   const isValidHouseInformation =
      //     this.$refs.houseInformation.validateForm();
      //   if (!isValidHouseInformation) return false;
      // }

      // const isValidIncomeInformation =
      //   this.$refs.incomeInformation.validateForm();
      // if (!isValidIncomeInformation) return false;

      return await this.saveQuestionnaire();
    },

    async getQuestionnaireInfo() {
      try {
        this.addPreloader();
        const params = {
          lead_id: this.leadId,
        };

        const { data } = await CourtInfoService.getformationOfQuestionnaire(
          params
        );
        if (data.questionnaire) {
          this.leadInfo = data.questionnaire.lead;
          if (data.has_questionnaire) {
            this.houses = data.questionnaire.houses;

            this.coApp = data.questionnaire.co_app;
            this.incomes = data.questionnaire.incomes;
            this.hardship = data.questionnaire.hardship;

            this.ownHouse = data.questionnaire.own_house;
            if (data.questionnaire.goals) {
              this.goals = data.questionnaire.goals;
              this.goals.forEach((element, index) => {
                element.text = `${this.ordinalSuffixOf(index + 1)} Goal`;
              });
            } else {
              this.goals = [{ text: "1st Goal", goal: null, date: null }];
            }
            this.hardship.utilities.map((item) => {
              const opt = this.optionsUtilities.find(
                (option) => option.id == item.id
              );
              item.text = opt.text;
            });

            this.hardship.others.map((item) => {
              const opt = this.optionsHard.find(
                (option) => option.id == item.id
              );
              item.text = opt.text;
            });
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    async saveQuestionnaire() {
      try {
        this.addPreloader();
        const params = {
          lead_id: this.leadId,
          case_id: this.caseId,

          own_house: this.ownHouse,
          lead_info: this.leadInfo,
          co_app: this.coApp,
          incomes: this.incomes,
          hardship: this.hardship,
          user: this.currentUser.user_id,
          houses: this.houses,
          goals: this.goals,
        };

        const data = await CourtInfoService.saveInformationOfQuestionnaire(
          params
        );

        if (data.status === 200) {
          this.$emit("nextStep", 1);
          this.removePreloader();
          return true;
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }

      return false;
    },

    ordinalSuffixOf(i) {
      const j = i % 10;
      const k = i % 100;
      if (j === 1 && k !== 11) {
        return `${i}st`;
      }
      if (j === 2 && k !== 12) {
        return `${i}nd`;
      }
      if (j === 3 && k !== 13) {
        return `${i}rd`;
      }
      return `${i}th`;
    },
  },

  created() {
    this.getQuestionnaireInfo();
  },
};
</script>