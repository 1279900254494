/* eslint-disable no-useless-catch */
import { amgApi } from '@/service/axios';

class ChargeBackService {
  url = '/management/charge-back'

  async chargeBackSearch(body) {
    try {
      const data = await amgApi.post(`${this.url}/get-charge-backs`, body);
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async chargeBackSearchApproved(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/man-charge-back-search-approved`,
        body,
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async getPrograms() {
    try {
      const data = await amgApi.get(`${this.url}/programs`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async getCardTransactions(body) {
    try {
      const data = await amgApi.post(`${this.url}/get-card-transactions`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveChargeBack(body) {
    try {
      const data = await amgApi.post(`${this.url}/save-man-charge-back`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTrackingChargeBack(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/get-tracking-charge-back-v2`,
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async setPaid(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/man-charge-back/set-paid`,
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addEvidence(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/add-evidences-charge-back`,
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCountNotificationsChargeBack(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/get-count-notifications-charge-back`,
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async assignResponsible(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/assign-responsible-charge-back`,
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getReasonCodeListChargeBack(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/get-reason-code-list-charge-back`,
        body,
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async getEvidences(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/get-evidences-charge-back`,
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTransactionsByAuthcode(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/get-transactions-by-authcode`,
        body,
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async searchPaymentsV(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/search-payments-v`,
        body,
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async getTrackingPaymentsV(params) {
    try {
      const data = await amgApi.post(
        `${this.url}/get-tracking-payments-v`,
        params,
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async getAccessModule(params) {
    try {
      const data = await amgApi.post(
        `${this.url}/get-access-module`,
        params,
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async insertRequestToTransaction(body) {
    try {
      const data = await amgApi.post(`${this.url}/insert-request-to-transaction`, body);
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async sendSmsInvoice(body) {
    try {
      const data = await amgApi.post(`${this.url}/sent-message-invoice`, body);
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async registerChargeBack(body) {
    try {
      const data = await amgApi.post(`${this.url}/register-charge-back`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteEvidence(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/delete-evidences-charge-back`,
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getFileTracking(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/get-chargeback-file-tracking`,
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async insertPayment(body) {
    try {
      const data = await amgApi.post(`${this.url}/insert-payment`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async insertPaymentRemove(body) {
    try {
      const data = await amgApi.post(`${this.url}/insert-payment-remove`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeStatusFile(body) {
    try {
      const data = await amgApi.post(`${this.url}/revision-dispute-file`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateRejectedFile(body) {
    try {
      const data = await amgApi.post(`${this.url}/update-rejected-files`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeStatusDisputeChargeBack(body) {
    try {
      const data = await amgApi.post(`${this.url}/dispute-revision`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async validatePayment(body) {
    try {
      const data = await amgApi.post(`${this.url}/validate-payment`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getChargebackCounters(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/get-chargeback-counters`,
        body,
      );

      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async departmentSendsFilesToManagement(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/department-sends-files-to-management`,
        body,
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async disputeResponse(body) {
    try {
      const { data, status } = await amgApi.post(
        `${this.url}/dipute-response`,
        body,
      );
      return { data, status };
    } catch (error) {
      return { data: error.toString() };
    }
  }

  async getDepartmentDiscounts(body) {
    try {
      const data = await amgApi.post(
        `${this.url}/get-department-discounts`,
        body,
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async getAllDiscounts(body) {
    try {
      const data = await amgApi.post(`${this.url}/get-all-discounts`, body);
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async getTotalDiscount(body) {
    try {
      const { data } = await amgApi.post(`${this.url}/get-total-discount`, body);
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async importChargeback(body) {
    try {
      const data = await amgApi.post(`${this.url}/import-chargeback`, body);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new ChargeBackService();
