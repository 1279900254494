<template>
  <div>
    <div
      class="cont-list"
      :style="images.length &lt; 3 ? '' : 'overflow-y: scroll; height: 238px;'"
    >
      <label :for="source" style="width: 100%" @click="isSingle">
        <div
          class="uploader"
          style="
            padding: 15px 15px;
            border-radius: 5px;
            background: transparent;
            border: 1.3px solid #d1cfd7;
            cursor: pointer;
          "
          :class="{ dragging: isDragging, 'border-danger': errorDrag }"
          @dragenter="OnDragEnter"
          @dragleave="OnDragLeave"
          @dragover.prevent
          @drop="onDrop"
        >
          <div v-show="!images.length">
            <img
              src="/assets/images/drag-drop/folder-icon.png"
              class="mb-3 mt-5"
              alt
            />
            <!-- <i class="fa fa-cloud-upload"></i> -->
            <p
              style="font-size: 20px; font-weight: bold; margin-bottom: 10px"
              class="roboto-class"
            >
              Drag your files here
            </p>
            <div class="file-input">
              <div
                class="file-input roboto-class"
                style="font-weight: bold; font-size: 20px"
              >
                <!-- <i class="fas fa-cloud-upload-alt text-primary" style="font-size: 20px"></i> -->
                or Select a file
              </div>
              <input
                :id="source"
                type="file"
                :accept="filesValidation()"
                :multiple="!single"
                @change="onInputChange"
                :disabled="disabled"
              />
            </div>
          </div>

          <div
            v-show="images.length"
            class="images-preview d-flex justify-content-center"
            style="margin-top: 0px"
            :for="source"
          >
            <div
              v-for="(image, index) in images"
              :key="index"
              class="img-wrapper"
              style="
                border-radius: 20px;
                padding: 10px;
                background: #eeefe4;
                width: 120px;
              "
            >
              <!-- '/assets/images/drag-drop/UPLOADED-FILE.png' -->
              <img :src="imageFile(files[index].name)" />
              <div class="details">
                <span class="name" v-text="files[index].name.substr(0, 10)" />
                <span class="size" v-text="getFileSize(files[index].size)" />
                <button
                  style="position: absolute; top: 5px; right: 7px"
                  @click="removeFile($event, index)"
                >
                  <img
                    style="width: 85%"
                    :src="'/assets/images/drag-drop/trash.ico'"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
    <div
      v-if="filesToShow && filesToShow.length > 0"
      class="mt-1"
      style="
        padding: 15px 15px;
        border-radius: 5px;
        background: transparent;
        border: 1.3px solid #d1cfd7;
        cursor: pointer;
      "
    >
      <b-row>
        <template v-for="(file, index) in filesToShow">
          <b-col v-if="file.id" :key="file.id" cols="12" md="3">
            <span class="d-flex flex-column align-items-center">
              <img :src="imageFile(file.name)" width="50" />
              <div class="details mt-1">
                <a
                  class="name"
                  :href="file.url"
                  v-text="file.name.substr(0, 10)"
                />
                <a
                  class="size"
                  :href="file.url"
                  v-text="getFileSize(file.size)"
                />
                <b-badge
                  style="position: absolute; top: 5px; right: 7px"
                  variant="danger"
                  class="cursor-pointer"
                  @click="deleteFile(index, file)"
                >
                  <feather-icon class="text-white" icon="TrashIcon" />
                </b-badge>
              </div>
            </span>
          </b-col>
        </template>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "DragAndDrop",
  props: {
    filesArray: Array,
    single: Boolean,
    image: Boolean,
    errorDrag: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: "file",
    },
    filesToShow: Array,

    limitFilesChargeBack: {
      type: Number,
      required: false,
    },
    filesAcepted: {
      type: String,
      default: "*",
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      images: [],
      disable: {
        upload: false,
      },
      errorFile: false,
      files: [],
      folder_id: "",
      sendto: "",
      responsable: "",
      result: "",
      isDragging: false,
      dragCount: 0,
    };
  },

  created() {},

  mounted() {
    this.processFilesArray();
  },

  computed: {
    isNotUsedInChargeBack() {
      // when it is not used in Charge_Back it does not receive any limit as a property, so the file limit is undefined.
      return typeof this.limitFilesChargeBack === "undefined";
    },
  },

  methods: {
    processFilesArray() {
      Array.from(this.filesArray).forEach((file) => this.addImage(file));
    },

    imageFile(filename) {
      const extension = filename.split(".").pop();
      switch (extension) {
        case "jpg":
        case "png":
        case "gif":
          return "/assets/images/drag-drop/ext_files/jpg.png";
        case "xls":
        case "xlsx":
          return "/assets/images/drag-drop/ext_files/excel.png";
        case "html":
          return "html";
        case "pdf":
          return "/assets/images/drag-drop/ext_files/pdf.png";
        default:
          return "/assets/images/drag-drop/ext_files/archivodesc.png";
      }
    },

    addImage(file) {
      // when not used in CHARGE_BACK
      if (this.isNotUsedInChargeBack) {
        this.files.push(file);
        const img = new Image();
        const reader = new FileReader();
        reader.onload = (e) => {
          this.images.push(e.target.result);
          file.fileapproved = e.target.result;
        };
        reader.readAsDataURL(file);
      }
      // when used in CHARGE_BACK
      else {
        const numLimitFiles = this.limitFilesChargeBack;
        const isRepeatedFile =
          this.files.filter((fileItem) => fileItem.name == file.name).length !=
          0
            ? true
            : false;
        const exceedsFileLimit = this.files.length >= numLimitFiles;
        const extensionsPermitted = [
          "txt",
          "jpg",
          "png",
          "svg",
          "xls",
          "xlsx",
          "html",
          "pdf",
          "docx",
          "dot",
          "dotx",
          "eml",
          "mp3",
          "mp4",
          "pptx",
          "ppt",
          "wma",
          "zip",
          "rar",
          "avi",
          "wmv",
          "mov",
          "wav",
          "jpeg",
          "webp",
        ];

        const extensionFile = file.name.split(".").pop();
        const FileNotPermitted = !extensionsPermitted.includes(extensionFile);

        if (isRepeatedFile) {
          this.showInfoSwalCaro("NO DUPLICATE FILES ACCEPTED.");
          return;
        }
        // if (exceedsFileLimit && !this.limitFilesChargeBack) {
        if (exceedsFileLimit) {
          this.showInfoSwalCaro(`MAXIMUM ${numLimitFiles} FILES.`);
          return;
        }
        if (FileNotPermitted) {
          this.showInfoSwalCaro("TYPE OF FILE NOT ALLOWED.");
          return;
        }
        this.files.push(file);
        const img = new Image();
        const reader = new FileReader();
        reader.onload = (e) => {
          this.images.push(e.target.result);
          file.fileapproved = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    filesValidation() {
      if (this.image) {
        this.filesAcepted = "image/png, image/jpeg";
      }
      return this.filesAcepted;
    },
    onInputChange(e) {
      const { files } = e.target;
      Array.from(files).forEach((file) => this.addImage(file));
      this.$emit("input", this.files);
    },
    getFileSize(size) {
      const fSExt = ["Bytes", "KB", "MB", "GB"];
      let i = 0;

      while (size > 900) {
        size /= 1024;
        i++;
      }
      return `${Math.round(size * 100) / 100} ${fSExt[i]}`;
    },
    removeFile(e, key) {
      e.preventDefault();
      this.files.splice(key, 1);
      this.images.splice(key, 1);
      this.$emit("input", this.files);
    },
    removeAllFiles() {
      this.files = [];
      this.images = [];
    },
    deleteFile(index, file) {
      this.$emit("delete-file", index, file);
    },
    OnDragEnter(e) {
      e.preventDefault();

      this.dragCount++;
      this.isDragging = true;
      return false;
    },
    OnDragLeave(e) {
      e.preventDefault();
      this.dragCount--;
      if (this.dragCount <= 0) this.isDragging = false;
    },
    onDrop(e) {
      //validate if is dragging only one file
      if (this.single && e.dataTransfer.files.length > 1) {
        return;
      }
      let filesAceptedArray = [];
      let allFormatAcepted = false;
      if (this.filesAcepted != "*") {
        filesAceptedArray = this.filesAcepted
          .split(",")
          .map((format) => format.trim());
      } else {
        allFormatAcepted = true;
      }
      e.preventDefault();
      e.stopPropagation();
      this.isDragging = false;
      const { files } = e.dataTransfer;
      Array.from(files).forEach((file) => {
        if (allFormatAcepted) {
          this.addImage(file);
        } else if (this.isValidFileType(file.type, filesAceptedArray)) {
          this.addImage(file);
        }
      });
      this.$emit("input", this.files);
    },
    isSingle(e) {
      if (this.images.length > 0 && this.single) {
        e.preventDefault();
      }
    },
    isValidFileType(selectedFileType, allowedFormatsArray) {
      const selectedFileTypeParts = selectedFileType.split("/");

      return allowedFormatsArray.some((format) => {
        const formatParts = format.split("/");

        if (formatParts.length === 2 && formatParts[1] === "*") {
          // Comodín al final, ej. "image/*"
          return (
            selectedFileTypeParts[0] === formatParts[0] &&
            selectedFileTypeParts[1].startsWith(formatParts[1].slice(0, -1))
          );
        } else if (formatParts.length === 2) {
          // Sin comodines, ej. "image/jpeg"
          return format === selectedFileType;
        } else if (formatParts.length === 1 && format.endsWith("/*")) {
          // Comodín al principio, ej. "*/*"
          return (
            selectedFileTypeParts[0] === formatParts[0].slice(0, -2) ||
            formatParts[0] === "*"
          );
        }
        return false;
      });
    },
  },
};
</script>

<style>
.details {
  background-color: transparent !important;
}
</style>
