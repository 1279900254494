import Vue from 'vue'
import store from '@/store/index'

export const RecycleRequestNotification = () => {
  window.socket.bind('recycle-request-notifications-connection', async ({ message, to_id }) => {
    const currentSession = store.state.auth.currentUser.user_id
    if(to_id.includes(currentSession)){  
      const {isConfirmed,isDismissed} = await Vue.swal.fire({
        icon: "success",
        title: 'Connection',
        text: message,
      });


      if(isConfirmed)
      await window.amgApi.post("/commons/close-all-swal", {to_id: data.to_id});


      
    }
  })
}