<template>
  <div
    id="piggy-wrapper"
    class=" cursor-pointer"
  >
    <div class="piggy-wrap">
      <div class="piggy">
        <div class="nose" />
        <div class="mouth" />
        <div class="ear" />
        <div class="tail">
          <span />
          <span />
          <span />
          <span />
        </div>
        <div class="eye" />
        <div class="hole" />
      </div>
    </div>
    <div id="coin-wrap">
      <div class="coin">
        $
      </div>
    </div>
    <div class="legs" />
    <div class="legs back" />
  </div>
</template>

<script>
export default {

  props: {
    moneySize: {
      type: Number,
      default: null,
    },
  },

  data() {
    return { isActive: false }
  },
  watch: {
    moneySize() {
      // console.log('moneySize ', this.moneySize)
      this.mySize(this.moneySize)
    },
  },

  mounted() {
    this.mySize(this.moneySize)
  },

  methods: {
    mySize(val) {
      if (val === 0) {
        const el = document.getElementById('piggy-wrapper')
        el.classList.toggle('standard')
      }
      if (val === 1) {
        const elcoin = document.getElementById('coin-wrap')
        const el = document.getElementById('piggy-wrapper')

        // console.log('elcoin ', el)
        if (el.classList.contains('standard')) {
          el.classList.remove('standard')
        }
        el.classList.toggle('flashingLow')

        setTimeout(() => {
          el.classList.toggle('flashingLow')
          el.classList.toggle('shakeLow')
          elcoin.classList.toggle('enterCoin')
        }, 5000)
      } if (val === 2) {
        // console.log('elcoin ', el)
        const elcoin = document.getElementById('coin-wrap')
        const el = document.getElementById('piggy-wrapper')
        if (el.classList.contains('standard')) {
          el.classList.remove('standard')
        }
        el.classList.toggle('flashingMedium')

        setTimeout(() => {
          el.classList.toggle('flashingMedium')
          el.classList.toggle('shakeMedium')
          elcoin.classList.toggle('enterCoin')
        }, 5000)
      } if (val === 3) {
        const elcoin = document.getElementById('coin-wrap')

        const el = document.getElementById('piggy-wrapper')
        if (el.classList.contains('standard')) {
          el.classList.remove('standard')
        }
        el.classList.toggle('flashingHigh')

        setTimeout(() => {
          el.classList.toggle('flashingHigh')
          el.classList.toggle('shakeHigh')
          elcoin.classList.toggle('enterCoin')
        }, 5000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

@import "@/views/ce-digital/sub-modules/sales/views/dashboards/seller/pigg.scss";

</style>
