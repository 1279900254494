const leadFields = [
  {
    key: "selected",
    label: "",
    sortable: false,
  },
  {
    key: "lead_name",
    label: "Name",
    thClass: "text-left",
  },
  {
    key: "status",
    thClass: "text-center",
  },
  {
    key: "mobile",
    thClass: "text-left",
  },
  {
    key: "catcher_by",
    label: "catcher",
  },
  {
    key: "seller_by",
    label: "seller",
  },

  {
    key: "credit_report",
    label: "CR",
    thClass: "text-left",
  },
  {
    key: "programs",
    thClass: "text-left",
  },
  {
    key: "module",
    label: "origin"
  },
  {
    label: "Creation Date",
    thClass: "text-left",
    key: "created_at",
  },
  {
    key: "tracking",
    class: 'text-center'
  },
  {
    key: "actions",
    thClass: "text-left",
  },
  {
    key: "client_ceo",
    label: "client of ceo",
    thClass: "text-left",
  },
];

export default {
  leadFields,
};
