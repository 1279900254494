import Vue from "vue";
import moment from "moment";

Vue.filter("myGlobal", (created) => {
  if (created) return moment(created).format("MM/DD/YYYY");
  return "-";
});

Vue.filter("myGlobalWithHour", (created) => {
  if (created) return moment(created).format("MM/DD/YYYY h:mm A");
  return "-";
});

Vue.filter("myDateGlobal", (created) =>
  moment(created).format("ddd, D MMM YYYY")
);

Vue.filter("myDateGlobal2", (created) => moment(created).format("D MMM YYYY"));

Vue.filter("myDateGlobalWithHour", (created) =>
  moment(created).format("ddd, D MMM YYYY hh:mm A")
);

Vue.filter("myTime", (created) => {
  if (isOnlyTime(created)) {
    return moment(created, "HH:mm:ss").format("hh:mm A");
  }
  if (isDateWithTime(created)) {
    return moment(created, "YYYY-MM-DD HH:mm:ss").format("hh:mm A");
  }
  return "-";
});

Vue.filter("myTimeOrNothing", (created) => {
  if (isOnlyTime(created)) {
    return moment(created, "HH:mm:ss").format("hh:mm A");
  }
  if (isDateWithTime(created)) {
    return moment(created, "YYYY-MM-DD HH:mm:ss").format("hh:mm A");
  }
  return "";
});

Vue.filter("myFullTime", (created) => {
  if (isOnlyTime(created)) {
    return moment(created, "HH:mm:ss").format("HH:mm");
  }
  if (isDateWithTime(created)) {
    return moment(created, "YYYY-MM-DD HH:mm:ss").format("HH:mm");
  }
  return "-";
});

Vue.filter("myGlobalDay", (created) => {
  if (created) return moment(created).format("MM/DD/YYYY hh:mm A");
  return "-";
});
Vue.filter("countHoursMinute", (value) => {
  if (value) {
    const hour = parseInt(moment(value, "HH:mm:ss").format("HH"));
    const minutes = moment(value, "HH:mm:ss").format("mm");
    return `${hour} h ${minutes} min`;
  }
  return "-";
});

Vue.filter("countHour", (value) => {
  if (value) {
    const hour = parseInt(moment(value, "HH:mm:ss").format("HH"));
    return `${hour} h`;
  }
  return "-";
});

Vue.filter("myDate", function (created) {
  return moment(created).format("MMMM Do YYYY");
});
Vue.filter("formatDate", function (created) {
  return moment(created).format("YYYY-MM-DD");
});
Vue.filter("myDateText", function (created) {
  return moment(created).format("dddd, Do");
});

Vue.filter("myMonthYear", function (created) {
  return moment(created).format("MMM YY");
});

Vue.filter("myGlobalDayText", function (created) {
  if (created)
    return moment(created)
      .format("dddd, MMMM DD, YYYY")
      .toLocaleString("en-us", { weekday: "long" });
  return "-";
});

Vue.filter("myFromNow", function (created) {
  if (created) return moment(created).fromNow();
  return "-";
});

function isOnlyTime(time) {
  return moment(time, "HH:mm:ss", true).isValid();
}

function isDateWithTime(date) {
  return moment(date, true).isValid();
}

Vue.filter("Iso2CATime", function (createdAt) {
  const dateUTC = new Date(createdAt);
  const options = {
    timeZone: "America/Los_Angeles",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  };

  return dateUTC.toLocaleTimeString("en-US", options);
});

Vue.filter("Iso2CADateWithHour", function (createdAt) {
  const providedDate = new Date(createdAt);

  if (isNaN(providedDate.getTime())) {
    return "";
  }

  const dateUTC = new Date(createdAt);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour12: true,
    hour: "numeric",
    minute: "numeric",
    timeZone: "America/Los_Angeles",
  };

  return dateUTC.toLocaleTimeString("en-US", options);
});

Vue.filter("phoneFormat", function (value) {
  if (!value) return "";
  value = value.toString();
  return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6)}`;
});
