import derivationsNav from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.nav";
import Leads from "@/views/ce-digital/sub-modules/sales/views/leads/navigation/leads.navigation";
import Clients from "@/views/ce-digital/sub-modules/sales/views/clients/navigation/clients-group.navigation";
import CeSalesDashboardNavigation from "@/views/ce-digital/sub-modules/sales/views/dashboards/ce-sales-dashboard.navigation";
import ceSalesMadeNav from "@/views/ce-digital/sub-modules/sales/views/sales-made/sales-made.nav";
import commissionsNav from "@/views/ce-digital/sub-modules/sales/views/commissions/commissions.nav";
import calendarNav from "@/views/ce-digital/sub-modules/sales/views/calendar/calendar.nav";
import helpdeskNav from "@/views/ce-digital/sub-modules/sales/views/helpdesk/helpdesk.nav";
import EmployeeClaimsNavigation from "@/views/ce-digital/sub-modules/sales/views/employee-claims/navigation/employee-claims.nav";
import RequestNav from "@/views/ce-digital/sub-modules/sales/views/request/request.nav.js";
//import SalesProjectsNavigation from "@/views/ce-digital/sub-modules/sales/views/projects/sales-projects.navigation";
import RingCentralNavigation from "@/views/ce-digital/sub-modules/sales/views/ring-central/ring-central.nav.js";
import MOFNavigation from "@/views/ce-digital/sub-modules/sales/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/ce-digital/sub-modules/sales/views/recruitment-process/sales-recruitment_process.navigation";
import CourtInfoNav from "@/views/ce-digital/sub-modules/sales/views/cour-info/navigation/ds-court-info.nav";

// data
import sidebarItems from "@/views/credit-experts/data/sidebarItems";

const navigation = [
  {
    header: "SALES",
    module: 26,
    modules: sidebarItems,
  },
  ...CeSalesDashboardNavigation,
  ...derivationsNav,
  ...Leads,
  Clients,
  ...ceSalesMadeNav,
  // commissionsNav,
  calendarNav,
  helpdeskNav,
  EmployeeClaimsNavigation,
  RequestNav,
  RingCentralNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  ...CourtInfoNav,
];
export default navigation;
