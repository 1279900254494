

export default [
  {
    path: "messenger",
    name: "metamedia-messenger", 
    component: () => import(/* webpackChunkName: "metamedia-messenger" */ "@/views/commons/Messenger/Messenger.vue"),
    meta: {
      pageTitle: "Messenger",
      breadcrumb: [
        {
          text: "Messenger",
          active: true,
        },
      ], 
    },
    children:[]
  },
];
