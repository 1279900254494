export default {
    namespaced: true,
    state: {
        S_IS_ACTIVE: false,
        S_DATA_UR: null,
        S_TITLE: null,
        S_DETAIL_UR:null
    },
    getters: {
        G_IS_ACTIVE(state) {
            return state.S_IS_ACTIVE;
        },
        G_DATA_UR(state) {
            return state.S_DATA_UR;
        },
        G_TITLE(state) {
            return state.S_TITLE;
        },
        G_DETAIL_UR(state){
            return state.S_DETAIL_UR;
        }
    },
    mutations: {
        M_OPEN_ALERT(state, payload) {
            state.S_IS_ACTIVE = payload;
        },
        M_DATA_UR(state, payload) {
            state.S_DATA_UR = payload;
        },
        M_TITLE(state, payload) {
            state.S_TITLE = payload;
        },
        M_RESET(state){
            state.S_IS_ACTIVE = false;
            state.S_TITLE = '';
            state.S_DATA_UR = null;
        },
        M_DETAIL_UR(state,payload){
            state.S_DETAIL_UR = payload;
        }
    }
}