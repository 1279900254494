import { amgApi } from "@/service/axios";
class LeadTransactionService {
  async getTransactionsByLead(params) {
    const data = await amgApi.post(
      "/commons/lead/get-transactions-by-lead",
      params
    );
    return data;
  }
  async insertInitialPayment(params) {
    const data = await amgApi.post(
      "/note/first-note/insert-initial-payment",
      params
    );
    return data;
  }
  async getTypesTransaction(){
    const data = await amgApi.get(
      "/commons/lead/get-types-transaction"
    );
    return data;
  }
  async getStatusTransaction(){
    const data = await amgApi.get(
      "/commons/lead/get-status-transaction"
    );
    return data;
  }
  async getMethodsTransaction(){
    const data = await amgApi.get(
      "/commons/lead/get-methods-transaction"
    );
    return data;
  }
}
export default new LeadTransactionService();
