import store from "@/store";
import Notifications from '@/service/notification/index';
import schedulesService from "../commons/components/schedules/services/schedules.service"
import employeeClaims from "@/views/commons/commons-counter"
import RequestService from "@/views/commons/components/request/services/request.service.js"
import ManualOfFunctionService from '@/views/commons/components/manual-of-functions/services/manuals-of-functions.service';
import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';
import ProfileService from '@/views/main/menu.service';

const schedulesCounter = async () => {
  const params = { moduleid: store.state.auth.currentUser.modul_id }
  const data = await schedulesService.getAllCounters(params)

  const payload = {
    routeName: "schedules-rrhh",
    tag: data.data.grandTotal > 99 ? "99+" : data.data.grandTotal,
  }
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
}
const counterSalaryRecordManagement = async () => {
  await store.dispatch("SalaryRecordStore/A_GET_COUNTER_SALARY")
  const payload = {
    routeName: "salary-record-rrhh",
    tag: store.getters["SalaryRecordStore/G_COUNT_RRHH"],
  }
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
}                 

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id}
  const data = await RequestService.getCounterNotAsigned(params)

  const payload = {
      routeName: 'request-rrhh',
      tag: data[0].counter
  }
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload)
}

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'rrhh-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const essentialDocumentsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const response = await ProfileService.getCountersEssentialDocuments(params);
  if (response.data > 0) {
    const payload = {
      routeName: 'essential-documents',
      tag: response.data,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const mofcounter = async () => {
  const module = store.state.auth.currentUser.main_module;

  const params = {
    module_id: module,
  };

  const data = await ManualOfFunctionService.getPendigMOFReview(params);

  const payload = {
    routeName: 'manual-of-functions-rrhh',
    tag: data,
  };

  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'rrhh-recruitment-process',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({ user_id, moduleId });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: 'rrhh-customer-tickets',
      tag: data[0].v_total_open,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const allCountersHumanTalent = async () => {
  Promise.all([
    schedulesCounter(),
    projectsCounter(),
    counterSalaryRecordManagement(),
    employeeClaims("rrhh-employee-claims"),
    requestNotAsignedCounter(),
    mofcounter(),
    applicantsCounter(),
    essentialDocumentsCounter(),
    getPendinCustomerTickets(),
  ]);
};
export default allCountersHumanTalent;
