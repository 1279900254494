<template>
  <div>
    <b-modal ref="tracking-questionnaire" title="Tracking" size="lg" modal-class="modal-primary text-center" hide-footer
      @hidden="$emit('hidden')" title-tag="h3">
      <!-- table -->
      <b-table small ref="refTrackingQuestionnaire" :items="myProvider" :fields="fields" primary-key="id"
        table-class="text-nowrap" responsive="sm" show-emptycreated_at sticky-header="70vh" show-empty empty-text="There are no records to show" :busy="isBusy">
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(created_at)="data">
          <span>{{ data.item.created_at | myGlobalDay }}</span>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex justify-content-center">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL" boundary="viewport">
              <template #button-content>
                <b-img width="24" height="24" :src="assetsImg + '/images/icons/pdf.png'"
                  class="cursor-pointer align-self-center" />
              </template>
              <!-- ES -->
              <b-dropdown-item v-b-tooltip.hover.left="'Questionnaire ES'" variant="success">
                <div @click="questionnnairePdf(data.item.id, 'es')">
                  <feather-icon icon="DownloadIcon" />
                  <span class="align-middle ml-50">ES</span>
                </div>

              </b-dropdown-item>
              <!-- EN -->
              <b-dropdown-item v-b-tooltip.hover.left="'Questionnaire EN'">
                <div @click="questionnnairePdf(data.item.id, 'en')">

                  <feather-icon icon="DownloadIcon" />
                  <span class="align-middle ml-50">EN</span>
                </div>

              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>

// Import Data
import QuestionnnaireService from "@/views/crm/views/sales-made/components/modals/services/debt-solution/services/questionnnaire-service";
import { mapGetters } from "vuex";
export default {
  props: {
    salesClient: {
      type: Object,
      required: true
    },
    account: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      data: [],
      fields: [
        {
          key: "created_at",
          sortable: false,
          label: "Date",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "full_name",
          sortable: false,
          label: "Created By",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "actions",
          label: 'Actions',
          tdClass: "text-center",
          thClass: "text-center",
        }
      ],
      isBusy: false,

    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("tracking-questionnaire");
  },
  methods: {
    async myProvider() {
      try {
        const params = {
          account: this.account,
        };
        const { data, status } = await amgApi.post("/sales-made/debt-solution/get-tracking-questionnaire", params);
        if (status == 200) {
          this.items = data;
          return this.items || [];
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with tracking!"
        );
        throw error;
      }
    },
    async questionnnairePdf(id, type) {
      try {
        let params = {
          event_id: this.salesClient.event_id,
          trancking_id: id,
          language: type

        }
        this.addPreloader()
        const response = await QuestionnnaireService.questionnnairePdf(params);
        if (response.status === 200) {
          await this.forceFileDownloadPdf(response.data);
        }
      } catch (error) {
        console.log(error)

      } finally {
        this.removePreloader();
      }
    }
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}

.my {
  margin: 6px 0 0 6px;
}
</style>

