import { ProgramSlug } from "@/helpers/routes-slug";

export default [
  {
    path: "/statistics",
    name: "sn-statistics",
    component: () => import("@/views/social-network/views/statistics/Statistics.vue"),
    meta: {
      pageTitle: "Statistics",
      breadcrumb: [
        {
          text: "Statistics",
        },
      ],
      permittedRoles: [1,2,11],
    },
    redirect: { name: 'list-programs-social-network' },
    children: [
      {
        path: '',
        component: () => import('@/views/social-network/views/statistics/programs/ListPrograms.vue'),
        name: 'list-programs-social-network',
        meta: {
          pageTitle: "Statistics",
          breadcrumb: [
            {
              text: "Statistics",
              active: true,
            },
          ],
          permittedRoles: [1,2,11],
        },
      },
      {
        path: ':program/:flyer',
        name: 'detail-by-flyer-social-network',
        component: () => import('@/views/social-network/views/statistics/method-contact/FlyerDetail.vue'),
        meta: {
          pageTitle: "Statistics",
          breadcrumb: [
            {
              text: "Statistics",
              active: true,
            },
          ],
          permittedRoles: [1,2,11],
        },
        beforeEnter: (to, from, next) => {
          const program = to.params.program;
          to.meta.breadcrumb[0].text = ProgramSlug(program);
          next();
      },
      },
      {
        path: ':program',
        name: 'list-source-methods-social-network',
        component: () => import('@/views/social-network/views/statistics/method-contact/MethodsList.vue'),
        meta: {
          pageTitle: "Statistics",
          breadcrumb: [
            {
              text: "Statistics",
              active: true,
            },
          ],
          permittedRoles: [1,2,11],
        },
        beforeEnter: (to, from, next) => {
          const program = to.params.program;
          to.meta.breadcrumb[0].text = ProgramSlug(program);
          next();
      },
      },
    ]
  },
]
