<template>
  <b-dropdown
    variant="link"
    no-caret
    :right="$store.state.appConfig.isRTL"
    boundary="viewport"
    :toggle-class="toogleClass"
  >
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="align-middle text-body"
      />
    </template>
    <!-- derivationTrackingModal -->
    <b-dropdown-item
      v-if="
        currentTab != 'LOYAL' && currentTab != 'HOLD' && currentTab != 'CLOSED'
      "
      v-b-tooltip.hover.left="'Collect CR'"
      variant="success"
      @click="onRowCollectCR(rowData)"
    >
      <feather-icon icon="DollarSignIcon" />
      <span class="align-middle ml-50">Collect CR</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="
        options.includes('derivationTrackingModal') &&
        rowData.is_derivated === 1
      "
      v-b-tooltip.hover.left="'Sales process tracking'"
      variant="success"
      @click="onRowDerivationTracking(rowData)"
    >
      <feather-icon icon="DollarSignIcon" />
      <span class="align-middle ml-50">Sales process tracking</span>
    </b-dropdown-item>
    <!-- derivationActionModal -->
    <!-- <b-dropdown-item
      v-if="
        options.includes('derivationActionModal') &&
        rowData.is_derivated === 0 &&
        (currentModule == 22 ||
          currentModule == 21 ||
          currentModule == 20 ||
          currentModule == 23 ||
          currentModule == 26 ||
          currentModule == 6)
      "
      v-b-tooltip.hover.left="'Send to Sales'"
      @click="onRowDerivationAction(rowData, rowIndex)"
    >
      <feather-icon icon="CornerUpLeftIcon" />
      <span class="align-middle ml-50">Send to Sales</span>
    </b-dropdown-item> -->
    <!-- returnToSocialNetwork -->
    <b-dropdown-item
      v-if="
        options.includes('returnToSocialNetwork') && rowData.status_sn_id == 2
      "
      v-b-tooltip.hover.left="'Return to Social Network'"
      @click="onRowProcess(rowData.id)"
    >
      <feather-icon icon="CornerUpLeftIcon" />
      <span class="align-middle ml-50">Return</span>
    </b-dropdown-item>

    <!-- sendToCallReturns -->
    <b-dropdown-item
      v-if="
        options.includes('sendToCallReturns') &&
        ((fromBoost === true && statusCallRound === 1) ||
          fromBoost === false ||
          statusCallRound === 2) &&
        statusCallRound == 0
      "
      variant="primary"
      v-b-tooltip.hover.left="'Send to call rounds'"
      v-b-modal.modal-primary
      @click="onRowToCallReturns(rowData)"
    >
      <feather-icon icon="PhoneForwardedIcon" />
      <span class="align-middle ml-50">Send to call rounds</span>
    </b-dropdown-item>

    <!-- sendSMS -->
    <b-dropdown-item
      v-if="options.includes('sendSMS')"
      v-b-tooltip.hover.left="'Send SMS'"
      v-b-modal.modal-primary
      @click="onRowSms(rowData)"
    >
      <feather-icon icon="MessageSquareIcon" />
      <span class="align-middle ml-50">SMS</span>
    </b-dropdown-item>

    <!-- historySMS -->
    <b-dropdown-item
      v-if="options.includes('historySMS')"
      v-b-tooltip.hover.left="'History of SMS'"
      v-b-modal.modal-primary
      @click="onRowHistorySms(rowData)"
    >
      <feather-icon icon="RotateCcwIcon" />
      <span class="align-middle ml-50">History of SMS</span>
    </b-dropdown-item>

    <!-- historyEMAIL -->
    <b-dropdown-item
      v-if="options.includes('historyEmail')"
      v-b-tooltip.hover.left="'History of Email'"
      v-b-modal.modal-primary
      @click="onRowHistoryEmail(rowData)"
    >
      <feather-icon icon="RotateCcwIcon" />
      <span class="align-middle ml-50">History of Email</span>
    </b-dropdown-item>

    <!-- SendEmail -->
    <b-dropdown-item
      v-if="options.includes('sendEmail')"
      v-b-tooltip.hover.left="'Send Email'"
      v-b-modal.modal-primary
      @click="onRowEmail(rowData)"
    >
      <feather-icon icon="MailIcon" />
      <span class="align-middle ml-50">Send Email</span>
    </b-dropdown-item>

    <!-- Edit -->
    <b-dropdown-item
      v-if="options.includes('edit')"
      variant="warning"
      @click="onRowEdit(rowData.id)"
    >
      <feather-icon icon="TrashIcon" />
      <span class="align-middle ml-50">Edit</span>
    </b-dropdown-item>

    <!-- Add Service -->
    <b-dropdown-item
      v-if="options.includes('addService')"
      variant="warning"
      @click="onRowAddService(rowData)"
    >
      <feather-icon icon="FilePlusIcon" />
      <span class="align-middle ml-50">Add service</span>
    </b-dropdown-item>

    <!-- Change Service -->
    <b-dropdown-item
      v-if="options.includes('changeService')"
      :variant="$route.matched[0].meta.module == 26 ? 'warning' : 'success'"
      @click="onRowChangeService(rowData)"
    >
      <feather-icon icon="EditIcon" />
      <span class="align-middle ml-50">Change service</span>
    </b-dropdown-item>

    <!-- Delete -->
    <b-dropdown-item
      v-if="options.includes('delete')"
      variant="danger"
      @click="onRowDelete(rowData.id)"
    >
      <feather-icon icon="TrashIcon" />
      <span class="align-middle ml-50">Delete</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="
        options.includes('sendRecycle') &&
        isRecycleStatus() &&
        !isPendingRecycle
      "
      variant="danger"
      @click="onRowRecycle(rowData.id)"
    >
      <feather-icon icon="SendIcon" />
      <span class="align-middle ml-50">Send client to Recycle</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="options.includes('sendClosed') && !isInLoyalTab && !isInHoldTab"
      variant="primary"
      @click="onRowClosed(rowData.id)"
    >
      <feather-icon icon="XCircleIcon" />
      <span class="align-middle ml-50">Send client to Canceled</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="options.includes('sendRecover') && !isInLoyalTab && !isInHoldTab"
      variant="success"
      @click="onRowRecover(rowData.id)"
    >
      <feather-icon icon="CornerUpLeftIcon" />
      <span class="align-middle ml-50">Recover client</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="
        options.includes('reactivateClient') && !isInLoyalTab && !isInHoldTab
      "
      variant="success"
      @click="onRowReactivate(rowData.id)"
    >
      <feather-icon icon="CornerUpLeftIcon" />
      <span class="align-middle ml-50">Reactivate client</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="options.includes('createMonthlyPaymentPlusNCR')"
      variant="primary"
      @click="onRowMonthlyPaymentPlusNCR(rowData.id)"
    >
      <feather-icon icon="DollarSignIcon" />
      <span class="align-middle ml-50">Make a Monthly Payment</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="options.includes('assignAgent') && isRecycleStatus()"
      variant="primary"
      @click="assignAgentAnClient(rowData.id)"
    >
      <feather-icon icon="UserIcon" />
      <span class="align-middle ml-50">Reassign an agent</span>
    </b-dropdown-item>
    <!-- Insert here action to create workplan request, goes in /connection/views/clients/components/ClientsGrid.vue xd -->
    <b-dropdown-item
      v-if="options.includes('requestwp')"
      variant="primary"
      @click="createWorkPlanRequest(rowData)"
    >
      <feather-icon icon="BriefcaseIcon" />
      <span class="align-middle ml-50">Request WP</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="options.includes('recycleApprovedDenied')"
      variant="primary"
      @click="approvedDeniedClientRecycle(rowData.id)"
    >
      <feather-icon icon="RefreshCcwIcon" />
      <span class="align-middle ml-50">Recycle - Approved / Denied</span>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="options.includes('closedApprovedDenied')"
      variant="primary"
      @click="approvedDeniedClientClosed(rowData.id)"
    >
      <feather-icon icon="RefreshCcwIcon" />
      <span class="align-middle ml-50">Canceled - Approved / Denied</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    VueGoogleAutocomplete,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    rowData: {
      required: true,
    },
    rowIndex: {
      type: Number,
      default: null,
    },
    fromBoost: {
      type: Boolean,
      default: false,
    },
    statusCallRound: {
      type: Number,
    },
    countCalls: {
      type: Number,
    },
    toogleClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
    withOrNotResultsLoyalTab() {
      return this.$route.meta.with_results;
    },
    currentModule() {
      return this.$route.matched[0].meta.module;
    },
    isPendingRecycle() {
      return this.rowData.client_status_request == 1 ? true : false;
    },
    currentTab() {
      return this.$route.meta.connectionStatus;
    },
    isInLoyalTab() {
      return this.currentTab == "LOYAL";
    },
    isInClosedTab() {
      return this.currentTab == "CLOSED";
    },
    isInHoldTab() {
      return this.currentTab == "HOLD";
    },
  },
  methods: {
    onRowProcess(id) {
      this.$emit("onRowProcess", id);
    },
    onRowToCallReturns(item) {
      this.$emit("modalToCallOpen", item);
    },
    onRowSms(item) {
      this.$emit("modalSmsOpen", item);
    },
    onRowHistorySms(item) {
      this.$emit("modalHistorySmsOpen", item);
    },
    onRowEmail(item) {
      this.$emit("modalSendEmailOpen", item);
    },
    onRowHistoryEmail(item) {
      this.$emit("modalHistoryEmailOpen", item);
    },
    onRowEdit(id) {
      this.$emit("onRowEdit", id);
    },
    onRowAddService(item) {
      this.$emit("modalAddService", item);
    },
    onRowChangeService(item) {
      this.$emit("modalChangeService", item);
    },
    onRowDelete(id) {
      this.$emit("onRowDelete", id);
    },
    onRowRecycle(id) {
      this.$emit("modalSendRecycle", id);
    },
    onRowMonthlyPaymentPlusNCR(id) {
      this.$emit("modalMonthlyPaymentPlusNCR", id);
    },
    assignAgentAnClient(id) {
      this.$emit("openModalAsign", id);
    },
    onRowTracing(item) {
      this.$emit("onTracing", item, 2); // 2 Tracing
    },
    onRowRecycled(item) {
      this.$emit("onRecycled", item, 4); // 4 Recycled
    },
    onRowDerivationAction(item, index) {
      this.$emit("derivationActionModal", item, index);
    },
    onRowDerivationTracking(item) {
      this.$emit("derivationTrackingModal", item);
    },
    onRowCollectCR(item) {
      this.$emit("collectCr", item);
    },
    assignAgentAnClient(id) {
      this.$emit("openModalAsign", id);
    },
    createWorkPlanRequest(item) {
      this.$emit("createWpRequest", item);
    },
    assignAgentAnClient(id) {
      this.$emit("openModalAsign", id);
    },
    approvedDeniedClientRecycle(id) {
      this.$emit("openModalApprovedDeniedRecycle", id);
    },
    approvedDeniedClientClosed(id) {
      this.$emit("openModalApprovedDeniedClient", id);
    },
    isRecycleStatus() {
      return this.$route.meta?.connectionStatus != "RECYCLE";
    },
    onRowToCallConnection(item) {
      this.$emit("modalToCallConnection", item);
    },
    onRowClosed(id) {
      this.$emit("modalSendClosed", id);
    },
    onRowRecover(id) {
      this.$emit("modalRecover", id);
    },
    onRowReactivate(id) {
      this.$emit("modalReactivateClient", id);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.sidebar-xl {
  width: 90rem;
}
</style>
