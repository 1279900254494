export default [
    {
        path: "dashboard",
        name: "boostcredit-dashboard",
        component: () =>
            import(/* webpackChunkName: "BCDashboardMain" */ "@/views/boost-credit/views/dashboard/BcDashboard.vue"),
        meta: {
            pageTitle: "Dashboard",
            breadcrumb: [
                {
                    text: "Dashboard",
                },
            ],
        },
    }
];