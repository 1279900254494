import {amgApi} from '@/service/axios'

// General services on module MetaMedia after refactoring
class SocialNetworkService {


    async getStates(body) {
        try {
      const data = await amgApi.post('/lead/get-states', body)
            return data
        } catch (error) {
            console.log('Something went wrong on getStates:', error)
            throw error
        }
    }

    async getPrograms(body) {
        try {
            const data = await amgApi.post('/commons/all-fangapes', body)
            return data
        } catch (error) {
            console.log('Something went wrong on getStates:', error)
            throw error
        }
    }

    async getCampaignFlyers(payload) {
        try {
            const response = await amgApi.post('/social-network/bank-of-flyers/get-campaigns-flyers', payload)
            return response
        } catch (error) {
            console.log('Something went wrong on getCampaignFlyers', error)
        }
    }

    async uploadFlyer(body, headers){
        try {
            const response = await amgApi.post('/social-network/bank-of-flyers/upload-bank-flyers', body, headers)
            return response;
        } catch (err) {
            console.error(err)
        }
    }

    async updateFlyer(body, headers) {
        try {
            const response = await amgApi.post('/social-network/bank-of-flyers/update-bank-flyers', body, headers)
            return response.status;
        } catch (err) {
            console.error(err)
        }
    }

    async deleteFlyer(flyerId, userId) {
        try {
            const response = await amgApi.post('/social-network/bank-of-flyers/delete-bank-flyers', {
                flyer_id: flyerId,
                user_id: userId
            })
            return response
        } catch (err) {
            console.log(err)
        }
    }

}

export default new SocialNetworkService()
