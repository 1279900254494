<template>
  <div class="rc-chat-info">
    <div class="new-container pt-1">
      <div class="d-flex flex-column align-items-center my-1">
        <div
          class="title rc-color-dark d-flex justify-content-center align-items-center"
          v-if="currentChat.leadName && currentChat.leadId"
        >
          <router-link
            :to="routerDashboardLeadRedirectioner(moduleId, currentChat.leadId)"
            target="_blank"
            class="d-block text-primary"
            :class="textLink"
          >
            {{ currentChat.leadName | myFontCapitalize }}
          </router-link>
        </div>

        <div class="title rc-color-dark text-center">
          {{ currentChat.leadPhoneNumber }}
        </div>
      </div>

      <div v-if="clientsAccounts.length > 0" class="divider-v2 mt-2 mb-1"></div>

      <div v-if="clientsAccounts.length > 0" class="mx-2">
        <div class="rc-color-dark subtitle">Accounts:</div>

        <b-row>
          <b-col
            v-for="account in clientsAccounts"
            :key="account.id"
            style="margin-bottom: 4px"
            xl="6"
            cols="12"
          >
            <router-link
              class="font-weight-bolder mr-1"
              target="_blank"
              :to="routerDashboardRedirectioner(account.program_id, account.id)"
            >
              {{ account.account }}
            </router-link>
            <status-account :account="account" text></status-account>
            <div>
              <!-- Created At -->
              <span>{{ account.created_at | myGlobal }}</span>
            </div>
          </b-col>
        </b-row>
      </div>

      <div
        v-if="leadInformation && leadInformation.description"
        class="divider-v2 mt-2"
      ></div>
      <div
        class="px-2 pb-2 pt-1"
        v-if="leadInformation && leadInformation.description"
      >
        <div class="rc-color-dark subtitle">Description:</div>
        <show-more-text
          v-if="leadInformation.description"
          :text="leadInformation.description"
          :letters="100"
          :show-more-styles="{
            color: '#0099DD',
          }"
        />
      </div>
      <!-- <div class="divider-v2 mt-2"></div>
      <div class="py-2 d-flex justify-content-between cursor-pointer">
        <div>
          <span class="ml-1 rc-color-dark subtitle"> Notifications</span>
        </div>
        <div>
          <b-form-checkbox
            v-model="statusNotification"
            name="check-button"
            switch
            button-variant="secondary"
          >
          </b-form-checkbox>
        </div>
      </div> -->
      <div class="divider-v2"></div>
      <div class="py-2 d-flex justify-content-between cursor-pointer">
        <div>
          <feather-icon icon="StarIcon" size="16" class="ml-1 mb-1" />
          <span class="ml-1 subtitle rc-color-dark">Marked messages</span>
        </div>
        <div>
          <feather-icon
            icon="ChevronRightIcon"
            size="20"
            class="text-primary"
            @click="$emit('openMessageHighlight')"
          />
        </div>
      </div>
      <div class="divider-v2"></div>
      <div
        class="pb-1 d-flex justify-content-between cursor-pointer recent-files"
      >
        <div>
          <span class="ml-1 rc-color-dark subtitle"> Recent Files</span>
          <span class="ml-1">
            ({{ G_CURRENT_CHAT_FILES.filesCounter }} files)
          </span>
        </div>
        <div v-if="G_CURRENT_CHAT_FILES.filesCounter > 0">
          <feather-icon
            :icon="statusDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            size="20"
            class="text-primary"
            @click="statusDropdown = !statusDropdown"
            v-b-toggle.collapse-123456
          />
        </div>
      </div>
      <b-collapse id="collapse-123456">
        <div class="ml-1 pb-1">
          <div
            v-for="(file, index) in G_CURRENT_CHAT_FILES.files"
            :key="file.id"
            style="margin-bottom: 3px"
            v-if="index < 4"
          >
            <div class="d-flex justify-content-between align-items-start">
              <div>
                <feather-icon
                  icon="ImageIcon"
                  v-if="file.contentType.startsWith('image/')"
                />

                <feather-icon
                  icon="YoutubeIcon"
                  v-if="file.contentType.startsWith('video/')"
                />

                <feather-icon
                  icon="MicIcon"
                  v-if="file.contentType.startsWith('audio/')"
                />

                <feather-icon
                  icon="FileIcon"
                  v-if="
                    file.contentType.startsWith('application/') ||
                    file.contentType.startsWith('text/')
                  "
                />

                <span style="margin-left: 4px" class="text-truncate">{{
                  file.id + "." + file.contentType.split("/")[1]
                }}</span>
              </div>

              <b-dropdown
                size="md"
                variant="muted"
                toggle-class="text-decoration-none"
                no-caret
                dropleft
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreHorizontalIcon"
                    class="cursor-pointer text-light"
                  ></feather-icon>
                </template>
                <b-dropdown-item href="#" @click="downloadFileRc(file)"
                  >Download</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>
      </b-collapse>

      <div class="divider-v2" />

      <div class="pb-2">
        <div>
          <span class="ml-1 rc-color-dark subtitle"> Images </span>
          <span class="ml-1">
            ({{ G_CURRENT_CHAT_FILES.imagesCounter }} files)
          </span>
        </div>

        <div class="rc-display-images px-2">
          <div
            v-for="(item, index) in G_CURRENT_CHAT_FILES.images"
            :key="index"
            class="container-image"
            v-if="index < 2"
          >
            <img
              :src="item.route"
              loading="lazy"
              @click="currentImage = item.route"
            />
          </div>
          <div
            v-if="
              G_CURRENT_CHAT_FILES.images &&
              G_CURRENT_CHAT_FILES.images.length == 3
            "
            class="container-image"
          >
            <img
              :src="G_CURRENT_CHAT_FILES.images[2].route"
              loading="lazy"
              @click="currentImage = G_CURRENT_CHAT_FILES.images[2].route"
            />
          </div>

          <div
            v-if="
              G_CURRENT_CHAT_FILES.images &&
              G_CURRENT_CHAT_FILES.images.length > 3
            "
            class="container-image"
          >
            <img :src="G_CURRENT_CHAT_FILES.images[2].route" loading="lazy" />
            <div
              class="view-more-images"
              @click="currentImage = G_CURRENT_CHAT_FILES.images[2].route"
            >
              +{{ G_CURRENT_CHAT_FILES.images.length - 3 }}
            </div>
          </div>
        </div>
      </div>

      <div class="divider-v2" />

      <div class="button-block-contact">
        <b-button variant="outline-danger" block @click="blockContact()">
          <feather-icon icon="SlashIcon"></feather-icon>
          Block
        </b-button>
      </div>

      <image-viewer
        v-if="currentImage != ''"
        :objectImages="G_CURRENT_CHAT_FILES.images"
        :route="currentImage"
        @hidden="currentImage = ''"
        style="z-index: 1001 !important"
      />
    </div>
  </div>
</template>

<script>
import ShowMoreText from "@/views/commons/components/ring-central/chat-sms/components/utils/ShowMoreText.vue";
import { mapGetters, mapMutations } from "vuex";
import ImageViewer from "@/views/commons/components/paragon-soft/components/ImageViewer.vue";
import NestService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
import LeadService from "@/views/social-network/services/leads/index.js";
import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  props: {
    currentChat: {
      type: Object,
      required: true,
    },
  },
  components: {
    ShowMoreText,
    ImageViewer,
    StatusAccount,
  },

  data() {
    return {
      currentImage: "",
      statusDropdown: false,

      leadInformation: null,
      clientsAccounts: [],
    };
  },
  computed: {
    ...mapGetters({
      G_CURRENT_CHAT_FILES: "SmsInboxStore/G_CURRENT_CHAT_FILES",
      G_CURRENT_CREDENTIAL: "SmsInboxStore/G_CURRENT_CREDENTIAL",
      currentUser: "auth/currentUser",
    }),

    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },

  methods: {
    ...mapMutations({
      M_SET_CURRENT_CHAT: "SmsInboxStore/M_SET_CURRENT_CHAT",
      M_REMOVE_CHAT_BY_ID: "SmsInboxStore/M_REMOVE_CHAT_BY_ID",
    }),

    openAllFiles() {
      if (this.G_CURRENT_CHAT_FILES.images[0]) {
        this.currentImage = this.G_CURRENT_CHAT_FILES.images[0].route;
      }
    },

    async getLeadInformation() {
      if (!this.currentChat.leadId) return;

      try {
        const { data } = await LeadService.getLeadById({
          leadId: this.currentChat.leadId,
        });
        if (data && data.length > 0) {
          this.leadInformation = data[0];
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async getClientAccounts() {
      if (!this.currentChat.leadId) return;

      try {
        const { data } = await SmsService.getClientsAccountByLeadId(
          this.currentChat.leadId
        );
        if (data && data.length > 0) {
          this.clientsAccounts = data;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    downloadFileRc(item) {
      this.forceDownloadAnyFile(
        item.route,
        `${item.id}.${item.contentType.split("/")[1]}`
      );
    },

    async blockContact() {
      const result = await this.showConfirmSwal();
      if (!result.isConfirmed) return;

      try {
        const chatId = this.currentChat._id;
        const params = {
          isBlocked: true,
          blockedBy: {
            id: this.currentUser.user_id,
            name: this.currentUser.fullName,
          },
        };
        await NestService.updateChat(chatId, params);
      } catch (error) {
        this.showErrorSwal();
      }
    },
  },

  async mounted() {
    await this.getLeadInformation();
    await this.getClientAccounts();
  },
};
</script>

<style lang="scss">
.rc-chat-info {
  font-family: "Rubick" !important;
  width: 100%;
  background-color: #dceef6;
  border-left: 1px solid #ced0d1;

  height: 90%;
  overflow-y: auto;

  .recent-files {
    .btn {
      padding: 0 !important;
    }
  }

  .button-block-contact {
    padding: 0 1rem 0.5rem 1rem;

    .btn {
      border-radius: 14px;
    }
  }

  .new-container {
    background-color: #f0faff;
    border-radius: 5px;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .subtitle {
    font-size: 1.3rem;
  }

  .divider-v2 {
    width: 100%;
    height: 10px;
    border-top: 1px solid #e3e8e7;
  }

  .rc-display-images {
    padding-top: 0.5rem;
    display: flex;
    gap: 3px;
    width: 100%;

    .container-image {
      position: relative;
      width: 31%;
      height: 100px;

      img {
        width: 100%;
        height: 100px;
        border-radius: 10px;
        border: 5px solid #f0f2f5;
        object-fit: cover;
        cursor: pointer;
      }

      .view-more-images {
        position: absolute;
        background-color: rgba($color: #000000, $alpha: 0.5);
        top: 0;
        height: 100px;
        width: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        font-weight: 600;
        cursor: pointer;
        color: #ffffff;
      }
    }
  }

  .star {
    color: #f0e003;
    font-weight: light;
    fill: #f0e003;
  }
}

.dark-layout {
  .rc-chat-info {
    background-color: #111b21;
    border-left: 1px solid #374035;

    .new-container {
      background-color: #202c33;
    }

    .divider-v2 {
      border-top: 1px solid #111b21;
      background-color: #202c33;
    }

    .rc-display-images {
      img {
        border: 5px solid #2a3942;
      }
    }
  }
}
</style>

<style lang="scss">
.rc-chat-info {
  @media (min-width: 1651px) {
    .new-container {
      padding: 1rem;
    }
  }

  @media (min-width: 1201px) and (max-width: 1650px) {
    .new-container {
    }
  }

  @media (max-width: 1200px) {
    .new-container {
    }
  }
}
</style>