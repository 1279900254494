export default {
    namespaced: true,
    state: {
        S_UPDATE_TABLE: false,
    },
    
    mutations: {
        M_UPDATE_TABLE(state, payload){
            state.S_UPDATE_TABLE = payload
        }
    },
    
    actions: {
        A_UPDATE_TABLE({commit}, params) {
            commit('M_UPDATE_TABLE', params)
        }
    },
    
    getters: {
        G_UPDATE_TABLE(state) {
            return state.S_UPDATE_TABLE
        }
    }
}