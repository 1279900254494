<template>
  <div>
    <div>
      <b-form-group label="Type Payment">
        <div class="button-group">
          <button
            v-for="paymentType in paymentTypes"
            :key="paymentType.value"
            :class="{
              'first-button': paymentSelected === 0,
              'last-button': paymentSelected === paymentTypes.length - 1,
              selected: paymentType.value === paymentSelected,
              unselected: paymentType.value !== paymentSelected,
            }"
            @click="changeOptionSelected(paymentType.value)"
          >
            {{ paymentType.text }}
          </button>
        </div>
      </b-form-group>
    </div>
    <div class="py-1">
      <b-row v-if="paymentSelected == 1">
        <b-col>
          <ValidationObserver ref="formPayment">
            <b-table
              ref="refCards"
              small
              show-empty
              :fields="fields"
              :items="searchCards"
              sticky-header="50vh"
              :busy.sync="isBusy"
              class="table-new-customization position-relative custom-table"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(radio)="data">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="`card${data.item.id}`"
                  rules="required"
                >
                  <b-form-radio
                    v-model="card_id"
                    :disabled="data.item.count_declined >= 2"
                    :value="data.item.id"
                    :name="'card' + data.item.id"
                    :state="errors[0] ? false : null"
                    @change="changeCard(data.item.type_card)"
                  />
                </ValidationProvider>
              </template>

              <template v-slot:cell(card_holder_name)="data">
                <p class="mb-0 font-weight-bold">
                  {{ data.item.cardholdername }}
                </p>
              </template>
              <template v-slot:cell(card_number)="data">
                <p class="mb-0 font-weight-bold">
                  {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }}
                </p>
              </template>
              <template v-slot:cell(type)="data">
                <p class="mb-0 font-weight-bold">
                  {{ data.item.type_card }}
                </p>
              </template>
              <template v-slot:cell(mm)="data">
                <p class="mb-0 font-weight-bold">
                  {{ data.item.card_expi_month }}
                </p>
              </template>
              <template v-slot:cell(yy)="data">
                <p class="mb-0 font-weight-bold">
                  {{ data.item.card_expi_year }}
                </p>
              </template>
              <template v-slot:cell(cvc)="data">
                <p class="mb-0 font-weight-bold">
                  {{
                    data.item.cardsecuritycode != null
                      ? data.item.cardsecuritycode.length == 3
                        ? "XX" + data.item.cardsecuritycode.substr(2)
                        : "XXX" + data.item.cardsecuritycode.substr(3)
                      : ""
                  }}
                </p>
              </template>
              <template v-slot:cell(created_by)="data">
                <p class="mb-0 font-weight-bold">
                  <span>{{ data.item.created_by }}</span>
                  <br>
                  <span>{{ data.item.created_at | myGlobalDay }}</span>
                </p>
              </template>
              <template v-slot:cell(actions)="data">
                <feather-icon
                  size="16"
                  icon="TrashIcon"
                  class="cursor-pointer text-danger"
                  @click="openDeleteCreditCard(data.item.id)"
                />
              </template>
            </b-table>
          </ValidationObserver>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button
              variant="success"
              @click="createCreditOn = true"
            >
              ADD</b-button>
          </div>
        </b-col>
      </b-row>
      <div v-else>
        <ValidationObserver ref="formOther">
          <b-row>
            <b-col
              :md="methodSelected != 2 ? 6 : 12"
              class="mb-1"
            >
              <validation-provider
                v-slot="{ errors }"
                name="type"
                rules="required"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >TYPE
                    </b-input-group-text>
                  </template>
                  <b-select
                    v-if="!disableIp"
                    v-model="methodSelected"
                    name="type"
                    :options="optionsMethods"
                    value-field="value"
                    text-field="name"
                    @change="changeOptionType($event)"
                  />
                  <b-form-input
                    v-else
                    name="type"
                    :value="nameOtherPayment"
                    disabled
                  />
                </b-input-group>
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider></b-col>

            <b-col
              md="6"
              class="mb-1"
            >
              <validation-provider
                v-if="methodSelected != 2"
                v-slot="{ errors }"
                name="serial_number"
                rules="required"
              >
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >SERIAL NUMBER
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    v-model="serialNumber"
                    name="serial_number"
                    maxlength="15"
                    @input="serialNumber = serialNumber.toUpperCase()"
                  />
                </b-input-group>
                <small
                  v-if="errors.length > 0"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider></b-col>

            <template v-if="methodSelected == 5 || methodSelected == 6">
              <b-col
                md="6"
                class="mb-1"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="date"
                  rules="required"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text> DATE </b-input-group-text>
                    </template>
                    <b-form-datepicker
                      v-model="date"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                    />
                  </b-input-group>
                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </validation-provider></b-col>

              <b-col
                md="6"
                class="mb-1"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="PROGRAM"
                  rules="required"
                >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        class="title_lable"
                      >HOUR
                      </b-input-group-text>
                    </template>
                    <b-form-timepicker
                      v-model="hour"
                      locale="en"
                    />
                  </b-input-group>
                  <small
                    v-if="errors.length > 0"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </validation-provider></b-col>
            </template>
            <template v-if="methodSelected > 2">
              <b-col md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="file"
                  rules="required"
                ><b-input-group>
                  <template #prepend>
                    <b-input-group-text
                      class="title_lable"
                    >SELECT IMAGE
                    </b-input-group-text>
                  </template>
                  <b-form-group
                    class="form-control"
                    :style="
                      isDarkSkin
                        ? 'background-color: transparent !important'
                        : ''
                    "
                  >
                    <b-form-file
                      v-model="fileToUpload"
                      style="position: inherit"
                      @input="fileFill"
                    />
                    <span
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      File {{ errors[0] }}</span>
                  </b-form-group>
                </b-input-group>
                </validation-provider>
              </b-col>
            </template>
          </b-row>
        </ValidationObserver>
      </div>
      <delete-credit-card
        v-if="deleteCreditOn"
        :card_id="card_id"
        :lead_id="data.lead_id"
        @refresh="$refs.refCards.refresh()"
        @close="deleteCreditOn = false"
      />
      <create-credit-card
        v-if="createCreditOn"
        :lead="data.lead_id"
        @reload="$refs.refCards.refresh()"
        @close="createCreditOn = false"
      />
    </div>
  </div>
</template>
<script>
import dataFields from '@/views/commons/components/clients/dashboard/options/pay-module/modals/monthly-payment-modal/fields.data';
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services';
import OtherPaymentsService from '@/views/commons/components/other-payments/service/other-payment.service';
import DeleteCreditCard from '@/views/commons/components/clients/dashboard/options/pay-module/modals/DeleteCreditCard.vue';
import CreateCreditCard from '@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    DeleteCreditCard,
    CreateCreditCard,
  },
  props: {
    data: { required: true, type: Object },
    amount: { type: Number, default: 0 },
    amountPartial: { type: Number, default: 0 },
    disableIp: { type: Boolean, default: false },
    hideOtherPayments: { type: Boolean, default: false },
  },
  data() {
    return {
      membership: 2,
      ammount: '29.99',
      spinnerOn: false,
      type_card: '',
      isBusy: false,
      fields: dataFields,
      maskMoney: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        precision: 2,
        masked: false,
      },
      ownControl: false,
      card_id: null,
      deleteCreditOn: false,
      createCreditOn: false,
      monthlyOptions: ['29.99', '39.99', '49.99'],
      options: [
        { text: 'Membership', value: 1 },
        { text: 'Single', value: 2 },
      ],
      paymentSelected: 1,
      optionTypeSelected: '',
      paymentTypes: [
        { text: 'Credit Card', value: 1 },
        { text: 'Others', value: 2 },
      ],
      optionsType: [
        { name: 'Cash', value: 1 },
        { name: 'Check', value: 2 },
        { name: 'Money Order', value: 3 },
        { name: 'Cashier Check', value: 4 },
        { name: 'Deposit', value: 5 },
        { name: 'Zelle', value: 6 },
      ],
      fileToUpload: [],
      date: '',
      hour: '',
      fnamedoc: '',
      fsize: '',
      fimage: '',
      serialNumber: '',
      disabledButton: false,
      monthlyPayment: 1,
      methodSelected: 2,
      optionsMethods: [],
      typePaymentOther: 6,
    };
  },
  computed: {
    ...mapGetters({
      client: 'DebtSolutionClients/G_CLIENTS',
    }),
    inConnection() {
      return this.moduleId === 20;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    this.getClientTransactionMethods();
    if(this.hideOtherPayments){
      this.paymentTypes = this.paymentTypes.filter(item => item.text !== 'Others');
    }
  },
  methods: {
    async saveOtherPayment() {
      const validate = await this.$refs.formOther.validate();
      if (!validate) return false;

      const params = {
        method_selected: this.methodSelected,
        serial_number: this.serialNumber,
        date: this.date,
        hour: this.hour,
        file: this.fimage,
      };
      return params;
    },

    async savePayment() {
      const validate = await this.$refs.formPayment.validate();
      if (!validate) return false;

      const params = {
        card_id: this.card_id,
      };
      return params;
    },
    // Get the method payment based on conditions
    getMethodPayment() {
      if (this.method_payment === 0) {
        return this.newType === 0 ? 4 : 0;
      }
      return this.types;
    },

    async searchCards() {
      try {
        const data = await ClientsOptionsServices.searchcards({
          id: this.data.lead_id,
        });
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getClientTransactionMethods() {
      const { data } = await OtherPaymentsService.getClientTransactionMethods();
      this.optionsMethods = data.map(item => ({
        name: item.value.toUpperCase(),
        value: item.id,
      }));
    },
    async fileFill() {
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = async file => {
        this.fimage = file.target.result;
      };
    },
    changeOptionType(value) {
      this.methodSelected = value;
    },

    changeOptionSelected(value) {
      this.paymentSelected = value;
      this.$emit('changeOptionSelected', value);
    },
    openDeleteCreditCard(id) {
      this.$emit('openDeleteCreditCard', id);
    },
    changeCard(typeCard) {
      this.$emit('changeCard', typeCard);
    },
    refresh() {
      this.$emit('refresh');
    },
  },
};
</script>
<style scoped>
.button-group button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.button-group button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.button-group button {
  background-color: gray;
  color: white;
  border: none;
  padding: 5px 8px;
}
.button-group button.selected {
  background-color: #0077e6;
  transition: background-color 0.3s ease-in-out;
}

.button-group button.unselected {
  background-color: gray;
}
</style>
