<template>
  <b-modal
    v-model="onControl"
    title="Tracking"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    hide-footer
    @hidden="close"
  >
    <div>
      <b-table
        show-empty
        :items="trackingData"
        sticky-header="50vh"
        :fields="fields"
      />
    </div>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import axios from "axios";

export default {
  name: "TrackingApprovedFiles",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      onControl: false,
      trackingData: [],
      apiApp: process.env.VUE_APP_BASE_APP_URL,
      fields: [
        { key: "description", label: "Observation" },
        { key: "name_user", label: "Created By" },
        { key: "created_at", label: "Date" },
      ],
    };
  },
  async created() {
    await this.getTracking();
    this.onControl = true;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getTracking() {
      try {
        const params = { id: this.item.id };
        const data = await ClientsOptionsServices.getTrackingFilesAccount(
          params
        );
        // const data = await axios.post(`${this.apiApp}/client/get-tracking-files-account`, params)
        if (data.status === 200) {
          this.trackingData = data.data;
        }
      } catch (e) {
        console.log("Error on getTracking Files.vue", e);
      }
    },
  },
};
</script>

<style scoped>
</style>
