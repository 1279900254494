var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"xmd","header-class":"p-0","header-bg-variant":"transparent","modal-class":"custom-modal-amg","scrollable":""},on:{"hide":function($event){return _vm.hideModal(false)}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('header-modal-notes',{attrs:{"program":"Bussiness","info":_vm.noteInfo},on:{"close":function($event){return _vm.hideModal(false)}}})]},proxy:true},{key:"modal-footer",fn:function(){return [(_vm.showButtonSave)?_c('button-save',{on:{"click":_vm.saveNotesIncomplete}}):_vm._e(),(_vm.showButtonSave)?_c('button-save-and-complete',{on:{"click":_vm.saveNotesCompleted}}):_vm._e(),(_vm.showButtonUpdate && _vm.noteInfo.editModal)?_c('button-update',{on:{"click":_vm.updateNotesCompleted}}):_vm._e()]},proxy:true}]),model:{value:(_vm.modalUp),callback:function ($$v) {_vm.modalUp=$$v},expression:"modalUp"}},[(_vm.showNewVersion)?_c('div',{staticClass:"d-flex justify-content-end mb-1"},[_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.$emit('showNewVersion')}}},[_vm._v(" "+_vm._s(_vm.emptyNoteNewVersion ? "Updated New Version" : "Show New Version")+" ")])],1):_vm._e(),_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"ein","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"EIN","label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.note.ein.options},model:{value:(_vm.note.ein.value),callback:function ($$v) {_vm.$set(_vm.note.ein, "value", $$v)},expression:"note.ein.value"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"businessIdentification","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Business Identification","label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.note.businessIdentification.options},model:{value:(_vm.note.businessIdentification.value),callback:function ($$v) {_vm.$set(_vm.note.businessIdentification, "value", $$v)},expression:"note.businessIdentification.value"}}),(_vm.note.businessIdentification.value === 'N/A')?_c('validation-provider',{attrs:{"name":"businessIdentificationOtherValue","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mt-50",class:{ 'border-danger rounded': errors[0] },attrs:{"size":"sm"},model:{value:(_vm.note.businessIdentification.otherValue),callback:function ($$v) {_vm.$set(_vm.note.businessIdentification, "otherValue", $$v)},expression:"note.businessIdentification.otherValue"}})]}}],null,true)}):_vm._e()],1)]}}])})],1),(_vm.dateTypeAgreement)?_c('b-col',[_c('validation-provider',{attrs:{"name":"typeOfAgreement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type of Agreement","label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.note.typeOfAgreement.options},model:{value:(_vm.note.typeOfAgreement.value),callback:function ($$v) {_vm.$set(_vm.note.typeOfAgreement, "value", $$v)},expression:"note.typeOfAgreement.value"}})],1)]}}],null,false,1766105508)})],1):_vm._e()],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"typeOfBusiness","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type of Business","label-class":"font-weight-bolder"}},[_c('v-select',{class:[
                { 'disable-class': _vm.disabled },
                { 'border-danger rounded': errors[0] } ],attrs:{"options":_vm.note.typeOfBuisiness.options,"label":"name","multiple":""},model:{value:(_vm.note.typeOfBuisiness.value),callback:function ($$v) {_vm.$set(_vm.note.typeOfBuisiness, "value", $$v)},expression:"note.typeOfBuisiness.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"numberOfEmployees","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Number of Employees","label-class":"font-weight-bolder"}},[_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.note.numberOfEmployees.options,"reduce":function (value) { return value.value; }},model:{value:(_vm.note.numberOfEmployees.value),callback:function ($$v) {_vm.$set(_vm.note.numberOfEmployees, "value", $$v)},expression:"note.numberOfEmployees.value"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"annualIncome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Annual Income","label-class":"font-weight-bolder"}},[_c('v-select',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.note.annualIncome.options,"reduce":function (value) { return value.value; }},model:{value:(_vm.note.annualIncome.value),callback:function ($$v) {_vm.$set(_vm.note.annualIncome, "value", $$v)},expression:"note.annualIncome.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"newBusiness","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-class":"font-weight-bolder","label":"It a New Business?"}},[_c('b-form-radio-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.newBusiness.options},model:{value:(_vm.note.newBusiness.value),callback:function ($$v) {_vm.$set(_vm.note.newBusiness, "value", $$v)},expression:"note.newBusiness.value"}})],1)]}}])})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.note.newBusiness.value === 'No')?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"startBusiness","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"When did you start your business?","label-class":"font-weight-bolder"}},[_c('b-form-input',{class:{ 'border-danger': errors[0] },model:{value:(_vm.note.newBusiness.startBusiness),callback:function ($$v) {_vm.$set(_vm.note.newBusiness, "startBusiness", $$v)},expression:"note.newBusiness.startBusiness"}})],1)]}}],null,false,3509074942)})],1):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.note.newBusiness.value === 'No')?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"businessRegistration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Have you ever did any registration of your business in the city or county?","label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.newBusiness.registration.options},model:{value:(_vm.note.newBusiness.registration.value),callback:function ($$v) {_vm.$set(_vm.note.newBusiness.registration, "value", $$v)},expression:"note.newBusiness.registration.value"}})],1)]}}],null,false,1291361276)})],1):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(
            _vm.note.newBusiness.value === 'No' &&
            _vm.note.newBusiness.registration.value === 'Yes'
          )?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"registerBusiness","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"How did you register your business?","label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.newBusiness.registerBusiness.options},model:{value:(_vm.note.newBusiness.registerBusiness.value),callback:function ($$v) {_vm.$set(_vm.note.newBusiness.registerBusiness, "value", $$v)},expression:"note.newBusiness.registerBusiness.value"}})],1)]}}],null,false,1384174635)}),_c('validation-provider',{attrs:{"name":"registerBusinessText","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.newBusiness.registerBusiness.text),callback:function ($$v) {_vm.$set(_vm.note.newBusiness.registerBusiness, "text", $$v)},expression:"note.newBusiness.registerBusiness.text"}})]}}],null,false,3035365381)})],1):_vm._e()],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Origin Country","label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"clearable":false,"disabled":_vm.disabled,"label":"name","reduce":function (value) { return value.id; },"options":_vm.note.country.options},model:{value:(_vm.note.country.value),callback:function ($$v) {_vm.$set(_vm.note.country, "value", $$v)},expression:"note.country.value"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"information","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Information","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.information.value),callback:function ($$v) {_vm.$set(_vm.note.information, "value", $$v)},expression:"note.information.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"indications","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Indications","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.indications.value),callback:function ($$v) {_vm.$set(_vm.note.indications, "value", $$v)},expression:"note.indications.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"suggestions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Suggestions","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.suggestion.value),callback:function ($$v) {_vm.$set(_vm.note.suggestion, "value", $$v)},expression:"note.suggestion.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"pending","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pending","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.editorOption},model:{value:(_vm.note.pending.value),callback:function ($$v) {_vm.$set(_vm.note.pending, "value", $$v)},expression:"note.pending.value"}})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }