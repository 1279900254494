export default [
  {
    path: 'manual-of-functions',
    name: 'manual-of-functions-management',
    redirect: { name: 'manual-of-functions-management-redirect' },
    component: () => import(
      /* webpackChunkName: "Managment MOF" */
      '@/views/commons/components/manual-of-functions/TabManualsOfFunctions.vue'
    ),
    children: [
      {
        path: 'create-mof',
        name: 'manual-of-functions-management-redirect',
        component: () => import(
          /* webpackChunkName: "mof-all" */
          '@/views/commons/components/manual-of-functions/ManualOfFunctions.vue'
        ),
        meta: {
          permittedRoles: [1, 2, 17],
          pageTitle: 'Manuals Of Functions',
          moduleOfTab: 16,

          breadcrumb: [
            {
              text: 'List Of mofs',
              // active: true,
            },
          ],

          tab: 1,
          create_mof: 'manual-of-functions-management-redirect',
          review_mof: 'review-mof-by-management-redirect',
          review_mof_by_ceo: 'review-mof-by-ceo-management-redirect',
          review_approved: 'review-mof-approved-management-redirect',
        },
      },
      {
        path: 'review-mof',
        name: 'review-mof-by-management-redirect',
        component: () => import(
          /* webpackChunkName: "mof-all" */
          '@/views/commons/components/manual-of-functions/ManualOfFunctions.vue'
        ),
        meta: {
          permittedRoles: [1, 2, 17],
          pageTitle: 'Manuals Of Functions',
          moduleOfTab: 16,

          // permittedRoles: [],
          breadcrumb: [
            {
              text: 'Review mofs',
              // active: true,
            },
          ],
          tab: 2,
          create_mof: 'manual-of-functions-management-redirect',
          review_mof: 'review-mof-by-management-redirect',
          review_mof_by_ceo: 'review-mof-by-ceo-management-redirect',
          review_approved: 'review-mof-approved-management-redirect',
        },
      },

      {
        path: 'review-mof-by-ceo',
        name: 'review-mof-by-ceo-management-redirect',
        component: () => import(
          /* webpackChunkName: "mof-all" */
          '@/views/commons/components/manual-of-functions/ManualOfFunctions.vue'
        ),
        meta: {
          permittedRoles: [1, 2, 17],
          pageTitle: 'Manuals Of Functions',
          // permittedRoles: [],
          moduleOfTab: 16,

          breadcrumb: [
            {
              text: 'Review mofs',
              active: true,
            },
          ],
          tab: 3,
          create_mof: 'manual-of-functions-management-redirect',
          review_mof: 'review-mof-by-management-redirect',
          review_mof_by_ceo: 'review-mof-by-ceo-management-redirect',
          review_approved: 'review-mof-approved-management-redirect',
        },
      },

      {
        path: 'review-mof-approved',
        name: 'review-mof-approved-management-redirect',
        component: () => import(
          /* webpackChunkName: "mof-all" */
          '@/views/commons/components/manual-of-functions/ManualOfFunctions.vue'
        ),
        meta: {
          permittedRoles: [1, 2, 17],
          pageTitle: 'Manuals Of Functions',
          // permittedRoles: [],
          breadcrumb: [
            {
              text: 'Approved Mofs',
              active: true,
            },
          ],
          moduleOfTab: 16,
          tab: 4,
          create_mof: 'manual-of-functions-management-redirect',
          review_mof: 'review-mof-by-management-redirect',
          review_mof_by_ceo: 'review-mof-by-ceo-management-redirect',
          review_approved: 'review-mof-approved-management-redirect',
        },
      },

    ],
  },
];
