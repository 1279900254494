import { amgApi } from "@/service/axios";

class UsersLogService {
  async getIndexData(params) {
    try {
      const { data } = await amgApi.post("/management/users-log/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getUsers(params) {
    try {
      const { data } = await amgApi.post("/management/users-log/show", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getModules(params) {
    try {
      const { data } = await amgApi.post("/management/users-log/show", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async storeUserLog(params) {
    try {
      const data = await amgApi.post("/management/users-log/store", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getUserDetailedLog(params) {
    try {
      const { data } = await amgApi.post("/management/users-log/show", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateUserLogActivity(params, headers) {
    try {
      const { data } = await amgApi.post("/management/users-log/update", params, headers);
      return data;
    } catch (error) {
      localStorage.setItem('errorupdateUserLogActivity', JSON.stringify(error));
      throw error;
    }
  }

  async getActivityUserLog(params) {
    try {
      const { data } = await amgApi.post("/management/users-log/show", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getInactivityUserLog(params) {
    try {
      const { data } = await amgApi.post("/management/users-log/show", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new UsersLogService();
