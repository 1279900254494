import Vue from "vue";
import store from "@/store";
import router from "@/router/index";

const SettlementOfferNotification = () => {
  window.socket.bind("new-offer", async (data) => {
    if (
      (data.notification_recipients
        ? data.notification_recipients.includes(
            store.state.auth.currentUser.user_id
          )
        : "") ||
      (store.state.auth.currentUser.role_id == 3 &&
        store.state.auth.currentUser.modul_id == 5)
    ) {
      new Audio(require("@/assets/sounds/notification.mp3")).play();

      store.dispatch("OfferNotificationStore/A_GET_OFFER_NOTIFICATIONS", {
        role_id: store.state.auth.currentUser.role_id,
      });
      store.dispatch("DebtSolutionSettlementOfferStore/A_COUNTER_SETTLEMENTS", {
        role_id: store.state.auth.currentUser.role_id,
        user_id: store.state.auth.currentUser.user_id,
      });
    }

    if (data.receivers.includes(store.state.auth.currentUser.user_id)) {
      // show swal
      const htmlAlert = `New offer <span style="font-weight: bold;">${
        data.balance ? "with balance" : "without balance"
      }</span> was created by ${data.from} for client ${
        data.client
      }. Waiting for review.`;

      const res = await Vue.swal.fire({
        icon: "success",
        title: "Offer Created",
        html: htmlAlert,
      });
      if (res) {
        let data = {
          to_id: store.state.auth.currentUser.user_id,
        };
        let response = await amgApi.post("commons/close-all-swal", data);
        if (response.status == 200) {
          //
        }
      }
    }
  });
  window.socket.bind("approve-offer", async (data) => {
    // update offer notification counter
    if (
      store.state.auth.currentUser.role_id === 2 ||
      store.state.auth.currentUser.role_id === 1 ||
      (store.state.auth.currentUser.role_id == 3 &&
        store.state.auth.currentUser.modul_id == 5)
    ) {
      new Audio(require("@/assets/sounds/notification.mp3")).play();
      // update offer notification counter
      store.dispatch("OfferNotificationStore/A_GET_OFFER_NOTIFICATIONS", {
        role_id: store.state.auth.currentUser.role_id,
      });
      // update counter of grids
      store.dispatch("DebtSolutionSettlementOfferStore/A_COUNTER_SETTLEMENTS", {
        role_id: store.state.auth.currentUser.role_id,
        user_id: store.state.auth.currentUser.user_id,
      });
    }

    if (data.receivers) {
      if (data.receivers.includes(store.state.auth.currentUser.user_id)) {
        if (store.state.auth.currentUser.modul_id == 16) {
          const htmlAlert = `New offer <span style="font-weight: bold;">${
            data.balance ? "with balance" : "without balance"
          }</span> was approved by ${data.from} for client ${
            data.client
          }. Waiting for review.`;
          const res = await Vue.swal.fire({
            icon: "success",
            title: "Offer Created",
            html: htmlAlert,
          });
          if (res) {
            let data = {
              to_id: store.state.auth.currentUser.user_id,
            };

            let response = await amgApi.post("commons/close-all-swal", data);
            if (response.status == 200) {
              //
            }
          }
        } else if (store.state.auth.currentUser.modul_id == 5) {
          const htmlAlert = `Offer <span style="font-weight: bold;">${
            data.balance ? "with balance" : "without balance"
          }</span> was approved by ${data.from} for client ${data.client}.`;
          const res = await Vue.swal.fire({
            icon: "success",
            title: "Offer Approved",
            html: htmlAlert,
          });
          if (res) {
            let data = {
              to_id: store.state.auth.currentUser.user_id,
            };

            let response = await amgApi.post("commons/close-all-swal", data);
            if (response.status == 200) {
              //
            }
          }
        }
      }
    }
  });
  window.socket.bind("disapprove-offer", async (data) => {
    // update offer notification counter
    if (
      store.state.auth.currentUser.role_id === 2 ||
      store.state.auth.currentUser.role_id === 1 ||
      (store.state.auth.currentUser.role_id == 3 &&
        store.state.auth.currentUser.modul_id == 5)
    ) {
      // update notification of navbar
      store.dispatch("OfferNotificationStore/A_GET_OFFER_NOTIFICATIONS", {
        role_id: store.state.auth.currentUser.role_id,
      });
      // update counter of grids
      store.dispatch("DebtSolutionSettlementOfferStore/A_COUNTER_SETTLEMENTS", {
        role_id: store.state.auth.currentUser.role_id,
        user_id: store.state.auth.currentUser.user_id,
      });
    }

    // recievers id[],from str, client str\
    const htmlAlert = `Offer <span style="font-weight: bold;">${
      data.balance ? "with balance" : "without balance"
    }</span> was dissaproved by ${data.from} for client ${data.client} `;
    if (data.receivers.includes(store.state.auth.currentUser.user_id)) {
      const res = await Vue.swal.fire({
        icon: "warning",
        title: "Offer Disapproved",
        html: htmlAlert,
      });
      if (res) {
        let data = {
          to_id: store.state.auth.currentUser.user_id,
        };
        let response = await amgApi.post("commons/close-all-swal", data);
        if (response.status == 200) {
          //
        }
      }
    }
  });
};
export default SettlementOfferNotification;
