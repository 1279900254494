<template>
  <div>
    <b-modal
      modal
      :title="`Request CR (${ncrType})`"
      ref="create-ncr-request"
      size="md"
      modal-class="custom-modal-amg"
      @hidden="closeMe"
      header-bg-variant="transparent"
      title-class="h3 text-white font-weight-bolder"
      :no-close-on-backdrop="true"
      header-class="p-0"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            Request CR {{ ncrType.charAt(0).toUpperCase()+ncrType.slice(1).toLowerCase() }}
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="closeMe"
            />
          </div>
        </div>
      </template>

      <!-- INFORMATION -->
      <ValidationObserver ref="form">
        <b-row>
          <b-col cols="12">
            <verify-status-request
              v-if="moduleId === 5"
              ref="verifyStatusRequest"
              :type-permission-id="typePermissionId"
              :id-table="item.account ? item.account : item.lead_id"
              @state="wasApprovedCheif = $event"
              in-row
              :key="verifyStatusKey"
            ></verify-status-request>
          </b-col>
        </b-row>
        <b-row>
          <b-col :cols="isLead ? 6 : 4">
            <h5 class="text-left">
              {{ isLead ? "Lead" : "Client" }}
            </h5>
            <p class="rounded border-secondary font-medium-1 text-center py10">
              {{
                item.nickname
                  ? `${item.nickname} (${item.fullname})`
                  : item.fullname
              }}
            </p>
          </b-col>

          <b-col cols="4" v-if="!isLead">
            <h5 class="text-left">Account</h5>
            <p class="rounded border-secondary font-medium-1 text-center py10">
              {{ item.account }}
            </p>
          </b-col>

          <!-- DOCUMENT -->
          <b-col :cols="isLead ? (hasDob ? 6 : 4) : hasDob ? 4 : 2">
            <ValidationProvider
              name="document"
              :rules="`required`"
              v-slot="{ errors }"
            >
              <h5 class="text-left">Document</h5>
              <div
                class="rounded border-secondary font-medium-1 text-center py10"
                :class="{ 'border-danger': errors[0] }"
              >
                <b-form-radio-group
                  v-if="!emptyDocs"
                  v-model="document"
                  :options="documents"
                  plain
                />
                <span v-else class="text-danger"
                  >Please fill document number</span
                >
              </div>
            </ValidationProvider>
          </b-col>
          <b-col v-if="!hasDob" cols="2">
            <ValidationProvider
              name="dob"
              :rules="`required`"
              v-slot="{ errors }"
            >
              <div class="d-flex">
                <h5 class="text-left">DOB</h5>
                <feather-icon
                  icon="HelpCircleIcon"
                  class="text-info ml-1"
                  size="18"
                  v-b-tooltip.hover.right="
                    `The date of birth of this ${
                      isLead ? 'lead' : 'client'
                    } has not yet been registered in the system and is necessary to create the request. Please specify it`
                  "
                />
              </div>
              <kendo-datepicker
                v-mask="'##/##/####'"
                v-model="inputDob"
                :style="
                  isDarkSkin ? 'border-color: rgba(255, 255, 255, 0.4)' : ''
                "
                :class="[
                  'w-100 rounded bg-transparent k-picker-custom',
                  { 'text-white': isDarkSkin, 'border-danger': errors[0] },
                ]"
                :format="'MM/dd/yyyy'"
                class="leads-datepicker form-control"
              />
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- FILE ID VALIDATION -->
        <b-row class="mb-1" v-if="!isAutomatic">
          <b-col cols="6">
            <ValidationProvider
              name="file"
              :rules="`required`"
              v-slot="{ errors }"
            >
              <h5 class="text-left">
                File ID
                <b-badge
                  v-if="
                    (file && fileCurrentStatus !== 'no file') ||
                    (uploadedFile &&
                      fileCurrentStatus !== 'up to date' &&
                      expiration_date !== '')
                  "
                  v-b-tooltip.hover.right="
                    fileIsUpToDate
                      ? 'This ID file has not expired yet'
                      : 'The current ID file has expired. Upload a new one'
                  "
                  :variant="fileIsUpToDate ? 'success' : 'danger'"
                  >{{ fileIsUpToDate ? "up to date" : "expired" }}</b-badge
                >
                <b-badge
                  v-else
                  variant="warning"
                  v-b-tooltip.hover.right="
                    'ID file was not found. Upload a PDF'
                  "
                  >no file</b-badge
                >
              </h5>
              <b-form-file
                v-if="!fileIsUpToDate || fileCurrentStatus !== 'up to date'"
                v-model="uploadedFile"
                accept=".pdf"
                :class="{ 'border-danger rounded': errors[0] }"
                @input="checkIdFileFormat"
              />

              <b-input-group v-else>
                <b-form-input :value="file.file_name" disabled />
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    class="py-0"
                    v-b-tooltip.hover.top="'See file'"
                    @click="seeFileId(file.route)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </ValidationProvider>
          </b-col>

          <b-col v-if="!isAutomatic" cols="6">
            <h5 class="text-left">DOE</h5>
            <div
              v-if="fileIsUpToDate && fileCurrentStatus == 'up to date'"
              class="doediv rounded font-medium-1 text-center border"
            >
              <p class="mb-0">{{ file.expiration | myGlobal }}</p>
            </div>
            <ValidationProvider
              v-else
              name="expiration_date"
              :rules="`required`"
              v-slot="{ errors }"
            >
              <b-form-datepicker
                v-model="expiration_date"
                :min="new Date()"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                :class="{ 'border-danger': errors[0] }"
                @input="checkDoe"
              />
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- PRICES -->
        <b-row class="mb-1">
          <b-col cols="6">
            <ValidationProvider
              name="price"
              :rules="`required`"
              v-slot="{ errors }"
            >
              <h5 class="text-left">Price</h5>
              <div
                class="rounded border-secondary font-medium-1 text-center pricesdiv"
                :class="{ 'border-danger': errors[0] }"
              >
                <b-form-radio-group
                  v-model="option"
                  :options="options"
                  @input="selectedPrice"
                  plain
                />
              </div>
            </ValidationProvider>
          </b-col>

          <b-col cols="6" v-if="!isAutomatic">
            <h5 class="text-left">Suggest type of report</h5>
            <v-select
              :options="manualPlatforms"
              label="text"
              :reduce="(option) => option.value"
              v-model="suggestedPlatform"
            >
            </v-select>
          </b-col>

          <b-col v-if="cPriceOther" cols="6" class="mt-2">
            <ValidationProvider
              name="other"
              :rules="`required|validate-amount`"
              v-slot="{ errors }"
            >
              <b-input-group
                v-if="cPriceOther"
                prepend="$"
                class="inputOthers"
                :class="{ 'border-danger rounded': errors[0] }"
              >
                <money v-model="other" class="form-control" v-bind="vMoney" />
              </b-input-group>
            </ValidationProvider>
          </b-col>
        </b-row>

        <!-- CARDS -->
        <b-row class="mt-2">
          <b-col cols="12">
            <div class="title-card d-flex align-items-center">
              <h5 class="text-left mb-0">Cards</h5>
              <feather-icon
                icon="HelpCircleIcon"
                class="text-info ml-1"
                size="18"
                v-b-tooltip.hover.right="cardHelpText"
              />

              <b-button
                variant="outline-success ml-auto cursor-pointer"
                v-b-tooltip.hover.left="'ADD CARD'"
                @click="openCreateCreditCard"
              >
                <feather-icon class="text-success" icon="PlusIcon" size="15" />
                ADD CARD
              </b-button>
            </div>
            <credit-card-table
              :cards="cards"
              :optionName="option"
              @cardId="cardId"
              :usedCard="usedCard"
              :disabledCards="cardsToDisable"
              ref="creditCardTable"
            />
          </b-col>
        </b-row>

        <!-- OBS -->
        <b-row>
          <b-col cols="12">
            <h5 class="text-left">Observation (optional):</h5>
            <b-form-group>
              <b-form-textarea
                id="message"
                v-model="observation"
                rows="2"
                maxlength="1000"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationObserver>

      <template #modal-footer>
        <div class="d-flex justify-content-center">
          <b-button @click="createRequest()" variant="success">Create</b-button>
        </div>
      </template>

      <modal-approve-supervisor
        v-if="modalApproveSupervisorOn"
        :modal-approve-supervisor="modalApproveSupervisorOn"
        @closeModalApprove="modalApproveSupervisorOn = false"
        @approveSup="postCreateRequest"
      />
      <CreateCreditCard
        v-if="createCreditOn"
        :lead="item.lead_id"
        @reload="refreshCards"
        @close="closeCreateCreditCard"
      />

      <modal-send-request-permission
        v-if="showModalAppro"
        @close="showModalAppro = false"
        :type-permission-id="typePermissionId"
        :id-table="item.account ? item.account : item.lead_id"
        :client-name="item.fullname"
        :lead-id="item.lead_id"
        :client-account-id="item.client_account_id"
        :data-json="dataToApprove"
        @updatePermission="refreshVerifyStatusRequest"
      ></modal-send-request-permission>
    </b-modal>
  </div>
  <!-- -->
</template>

<script>
// STORE
import { mapGetters, mapActions } from "vuex";

// COMPONENTS
import CreditCardTable from "@/views/commons/components/ncr-programs/components/table/card/CreditCardTable.vue";
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";
import CreateCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue";
import ModalSendRequestPermission from "@/views/commons/components/permissions/ModalSendRequestPermission.vue";
import VerifyStatusRequest from "@/views/commons/components/permissions/components/VerifyStatusRequest.vue";

// SERVICES
import LeadService from "@/views/crm/services/lead/index.js";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";
import ClientDashboardServices from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services.js";
import SNLeadsService from "@/views/social-network/services/leads";
import SettingsServiceSp from "@/views/specialists/views/settings/ncr-settings/service/settings.service.js";
import PermissionService from "@/views/commons/components/permissions/services/permission.service.js";
import modalMixin from "@/mixins/modal";
export default {
  components: {
    CreditCardTable,
    ModalApproveSupervisor,
    CreateCreditCard,
    ModalSendRequestPermission,
    VerifyStatusRequest,
  },
  mixins: [modalMixin],
  props: {
    item: {
      type: Object,
      required: false,
    },
    ncrType: {
      type: String,
      required: false,
    },
    isLeadDashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      leadDob: null,
      inputDob: null,
      createCreditOn: false,
      onControl: false,
      document: null,
      documents: [],
      emptyDocs: false,
      dob: "",
      option: null,
      options: [],
      amount: "",
      expensesArray: [],
      expenses: 0,
      other: "",
      otherMin: "",
      card: "",
      usedCard: null,
      cardsToDisable: [],
      invalidCards: [],
      cards: [],
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      vmoneyValidate: false,
      observation: "",
      file: null,
      uploadedFile: null,
      fileCurrentStatus: "",
      fileIsUpToDate: false,
      expiration_date: "",
      modalApproveSupervisorOn: false,
      cardHelpText: "",
      manualPlatforms: [],
      suggestedPlatform: null,
      showModalAppro: null,
      wasApprovedCheif: {
        status: "NOT",
        exists: false,
        code: null,
      },
      verifyStatusKey: 1,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      getRefreshTable: "RequestNcrStore/getRefreshTable",
    }),
    cPriceZero() {
      return this.option == this.options[0].value;
    },
    cPriceOther() {
      return this.option == 3;
    },
    isLead() {
      return this.item.type == "LEAD";
    },
    isAutomatic() {
      return this.ncrType == "AUTOMATIC";
    },
    moduleId() {
      // mncr
      // return this.$route.matched[0].meta.module == 28
      //   ? 11
      //   : this.$route.matched[0].meta.module
      return this.$route.matched[0].meta.module;
    },
    getFileId() {
      return this.isAutomatic
        ? ""
        : this.fileIsUpToDate && this.fileCurrentStatus == "up to date"
        ? JSON.stringify(this.file)
        : this.uploadedFile;
    },

    nameModuleRoute() {
      const routers = {
        2: "crm",
        3: "bu",
        5: "ds",
        6: "ce",
        7: "bc",
        8: "tr",
        10: "cdp",
        29: "sp", // mncr // 11: "sp",
        20: "cn",
        22: "csd",
      };
      return routers[this.moduleId] || null;
    },

    entityId() {
      return this.isLead ? this.item.lead_id : this.item.client_account_id;
    },

    typePermissionId() {
      if (this.item.account) {
        return this.ncrType === "AUTOMATIC" ? 2 : 3;
      }
      return this.ncrType === "AUTOMATIC" ? 5 : 6;
    },
    hasDob() {
      return this.leadDob != null;
    },
    dataToApprove() {
      let dataToApprove = [
        {
          label: this.item.client_account_id ? "Client" : "Lead",
          value: this.item.fullname,
          section: "General Information",
        },
        {
          label: "Account",
          value: this.item.account,
          section: "General Information",
        },
        {
          label: "Document",
          value: this.documents[0].value,
          section: "General Information",
        },
        {
          label: "Price",
          value: "$ 0.00",
          section: "General Information",
        },
        {
          label: "Observation",
          value: this.observation,
          section: "General Information",
        },
      ];
      if (!this.item.client_account_id) {
        dataToApprove.splice(1, 1);
      }
      return dataToApprove;
    },
  },
  mounted() {
    this.toggleModal("create-ncr-request");
    this.startModal();
    this.startCardMethods();
    this.startPromise();
  },
  beforeDestroy() {
    try {
      window.socket.unbind("permission-attended-notification-debtsolution");
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "RequestNcrStore/CHANGE_REFRESH_TABLE",
      A_REQUEST_NCR_COUNTERS: "RequestNcrStore/A_REQUEST_NCR_COUNTERS",
      A_REQUEST_NCR_COUNTERS_BY_LEAD:
        "RequestNcrStore/A_REQUEST_NCR_COUNTERS_BY_LEAD",
      A_GET_COUNTERS_BY_CLIENT: "RequestNcrStore/A_GET_COUNTERS_BY_CLIENT",
    }),

    startModal() {
      this.activeSocketReloadRequestStatus();
    },
    startCardMethods() {
      this.setCardHelpText();
      if (this.item.used_cards) this.setUsedCards(); // set the invalid cards (failed attempts)
      this.usedCard = this.item.card_id ?? null; // set the card used previously
    },
    async startPromise() {
      (await this.item.type) == "LEAD" ? this.getLead() : this.searchAccount();
      if (!this.isAutomatic) await this.getManuaProviders();
      await this.validateFileId();
      await this.getDocuments();
      await this.setOptionPrices();
    },

    checkDoe(value) {
      const dateParts = value.split("-");
      const year = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]);
      const day = parseInt(dateParts[2]);

      const doe = new Date(year, month, day);
      const today = new Date();

      this.fileIsUpToDate = doe >= today && this.uploadedFile !== null;
    },

    checkIdFileFormat(value) {
      const extension = value?.name.split(".").pop();
      if (extension !== "pdf") {
        this.showInfoSwal(`Something went wrong`, `Upload a PDF`); // show message
        this.uploadedFile = null; // set the upload to null to activate provider rules
        this.fileCurrentStatus = "no file"; // set the new status to 'no file' because PDF is required
      } else {
        this.fileIsUpToDate = this.expiration_date !== "";
      }
    },
    selectedPrice(value) {
      if (!this.cPriceOther) this.amount = value;

      if (this.cPriceZero) {
        this.expenses = this.expensesArray[1];
      } else if (this.cPriceOther) {
        this.expenses = this.expensesArray[3];
      } else {
        this.expenses = this.expensesArray[2];
      }
    },

    getAmount() {
      return this.cPriceOther ? this.other : this.amount;
    },

    cardId(value) {
      this.card = value.id;
      this.options[1].disabled = false;
      this.options[2].disabled = false;
    },

    // for leads
    async getLead() {
      try {
        const response = await LeadService.getLead(this.item.lead_id);
        if (response[0].cards) this.cards = response[0].cards;
      } catch (error) {
        console.log(1);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong"
        );
      }
    },

    // for clients
    async searchAccount() {
      try {
        let params = {
          account: this.item.account,
          program: this.item.program_id,
          session: this.currentUser.user_id,
          role: this.currentUser.role_id,
        };
        const response = await ClientDashboardServices.searchAccountNcr(params);
        // this.cards = JSON.parse(response.data[0].cards);
        this.cards = response.data[0]?.cards
          ? JSON.parse(response.data[0].cards)
          : [];
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong"
        );
      }
    },
    activeSocketReloadRequestStatus() {
      try {
        window.socket.subscribe("debtsolution-notifications");
        window.socket.bind(
          "permission-attended-notification-debtsolution",
          async (response) => {
            if (response.user_receiver == this.currentUser.user_id) {
              this.verifyStatusKey++;
            }
          }
        );
      } catch (error) {
        console.log("Socket-settlement", error);
      }
    },
    async validateFileId() {
      try {
        const { data } = await NcrRequestService.validateFileId({
          id: this.entityId,
          isClient: !this.isLead,
        });
        this.file = data.file;

        this.fileIsUpToDate = data.status;
        this.fileCurrentStatus = data.file
          ? data.status
            ? "up to date"
            : "expired"
          : "no file";
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong"
        );
      }
    },

    async setOptionPrices() {
      const { data } = await NcrRequestService.getNcrPrices({
        type_ncr: this.ncrType,
      });
      const prices = Object.values(data[0]);
      this.expensesArray = Object.values(data[1]);
      prices.forEach((price, index) => {
        if (index !== 0) {
          // discard the ids, cause we just need the amounts
          this.options.push({
            value: index == 3 ? "3" : price.toString(),
            text:
              index == 3
                ? `Others (min: $${Number(price).toFixed(2)})`
                : "$" + Number(price).toFixed(2),
            disabled: index != 1 && this.cards?.length == 0 && this.isLead,
          });
        }
      });
      this.otherMin = prices[3];
      if (this.cards?.length == 0) this.option = prices[1].toString();
    },

    async getManuaProviders() {
      try {
        const { data } = await SettingsServiceSp.getAllManualNcrTypes({});
        Object.values(data).forEach((item) => {
          this.manualPlatforms.push({
            value: item.id,
            text: item.name,
          });
        });
      } catch (error) {
        throw error;
      }
    },

    async getDocuments() {
      try {
        this.addPreloader();
        const response = await SNLeadsService.getLeadDocument({
          lead_id: this.item.lead_id,
        });

        if (response.status == 200) {
          const docs = response.data[0];
          this.leadDob = docs.dob;
          delete docs.dob;

          Object.keys(docs).forEach((key) => {
            if (docs[key] !== null) {
              this.documents.push({
                value: key == "other" ? "CPN" : key.toUpperCase(),
                text: key == "other" ? "CPN" : key.toUpperCase(),
              });
            }
          });

          if (this.areAllDocumentsNull(docs)) {
            this.emptyDocs = true;
          } else {
            this.document = this.documents[0].value;
          }
        }
      } catch (error) {
        throw error;
      } finally {
        this.removePreloader();
      }
    },

    areAllDocumentsNull(obj) {
      for (const key in obj) {
        if (obj[key] !== null) {
          return false;
        }
      }
      return true;
    },

    setCardHelpText() {
      this.cardHelpText = this.isLead
        ? "If you choose a price bigger than zero, then you need to pick a valid card. In the other case, it's not neccesary"
        : "If you choose a price bigger than zero, but all your cards are expired, you can send the request because an AMG card will be used instead. In the other hand, if you choose price 0, you don't need to send a card";
    },

    setUsedCards() {
      const cards = JSON.parse(this.item.used_cards);
      cards.forEach((card) => {
        this.cardsToDisable.push(card.id);
      });
    },

    seeFileId(path) {
      window.open(path, "_blank");
    },

    checkUserRole() {
      return (
        this.isCeo ||
        this.isSupervisor ||
        this.isChief ||
        [2, 15].includes(this.moduleId)
      );
    },

    requiresSupPassword() {
      return (
        this.moduleId !== 7 &&
        !this.checkUserRole() &&
        (this.cPriceZero || (this.cPriceOther && this.other < this.otherMin))
      );
    },

    async createRequest() {
      const validation = await this.$refs.form.validate();
      if (validation) {
        if (this.requiresSupPassword()) {
          if (this.moduleId === 5) {
            // new request permissions
            if (this.wasApprovedCheif.status === "APPROVED") {
              await this.postCreateRequest();
              await this.updateTimesUsedPermission();

              return;
            }
            if (
              this.wasApprovedCheif.exists &&
              this.wasApprovedCheif.status !== "APPROVED"
            ) {
              this.showToast(
                "danger",
                "top-right",
                "Validate error",
                "XIcon",
                `Permission Action ${
                  this.wasApprovedCheif.status === "REJECTED"
                    ? "Was Rejected"
                    : "In Progress"
                }`
              );
            } else {
              this.showModalAppro = true;
            }
          } else {
            this.modalApproveSupervisorOn = true;
          }
          return;
        } else {
          await this.postCreateRequest();
          await this.updateTimesUsedPermission();
        }
      }
    },

    // execute this method after enter the supervisor password
    async postCreateRequest() {
      try {
        const response = await this.showConfirmSwal();
        if (!response.isConfirmed) return;
        this.addPreloader();
        const formData = new FormData();

        formData.append("parent_id", this.item.parent_id ?? "");

        formData.append("lead_id", this.item.lead_id);
        formData.append("client_account", this.item.account ?? "");
        formData.append("client_account_id", this.item.client_account_id ?? "");
        formData.append("type_document", this.document ?? "");
        formData.append("file", this.getFileId);
        formData.append("expiration", this.expiration_date);
        formData.append("type_ncr", this.ncrType);
        formData.append("module_id", this.moduleId);
        formData.append("program_id", this.item.program_id ?? 1);
        formData.append("created_by", this.currentUser.user_id);

        // if the default card is null, then append the picked card
        formData.append("card", this.usedCard ?? this.card);

        formData.append("cards_invalid", JSON.stringify(this.invalidCards));
        formData.append("amount", this.getAmount());
        formData.append("expenses", this.expenses);
        formData.append("obs", this.observation);
        formData.append("role_user_id", this.currentUser.role_id);
        formData.append("type_platform_id", this.suggestedPlatform ?? ""); 
        formData.append("dob", this.inputDob);
        const { data } = await NcrRequestService.create(formData);

        this.showGenericToast({
          position: "top-right",
          text: "Request created successfully",
        });

        this.saveMe();

        if (!this.isLeadDashboard) {
          const destinationRoute = this.isLead
            ? this.$route.matched[1].meta.routeLead
            : this.$route.matched[1].meta.routeClient;

          const tabName = this.$route.name;

          if (
            (this.isLead &&
              tabName != `${this.nameModuleRoute}-ncr-lead-pending`) ||
            (!this.isLead &&
              tabName != `${this.nameModuleRoute}-ncr-client-pending`)
          ) {
            this.$router.push({ name: destinationRoute });
          }
        }
      } catch (error) {
        if (this.isValidJSON(error.message)) {
          console.log(error.message);
          const { card_id, message } = JSON.parse(error.message);
          if (card_id) {
            this.cardsToDisable.push(this.card);
            this.card = "";
            this.$refs["creditCardTable"].verifyValidCards();

            this.showToast(
              "danger",
              "top-right",
              message,
              "XIcon",
              "Something went wrong"
            );
          } else {
            this.showToast(
              "danger",
              "top-right",
              message,
              "XIcon",
              "Something went wrong"
            );
          }
        } else {
          this.showToast(
            "danger",
            "top-right",
            error.message,
            "XIcon",
            "Something went wrong"
          );
        }
      } finally {
        this.removePreloader();
      }
    },
    async saveMe() {
      this.closeMe();
      // this.showSuccessSwal();
      if (this.isLeadDashboard) {
        this.$emit("reload");
        await this.A_REQUEST_NCR_COUNTERS_BY_LEAD(this.item.lead_id);
      } else {
        this.$emit("closeSearch");
        await this.CHANGE_REFRESH_TABLE(true);
        await this.A_REQUEST_NCR_COUNTERS(this.moduleId);
        await this.A_GET_COUNTERS_BY_CLIENT(this.item.client_account_id);
      }
      await this.CHANGE_REFRESH_TABLE(false);
    },
    closeMe() {
      this.$emit("close");
    },
    closeCreateCreditCard() {
      this.createCreditOn = false;
    },
    openCreateCreditCard() {
      this.createCreditOn = true;
    },
    async refreshCards() {
      if (this.isLead) {
        await this.getLead();
      } else {
        await this.searchAccount();
      }

      this.options[1].disabled = false;
      this.options[2].disabled = false;

      if (this.cards.length == 1) {
        this.option = null;
      }
    },
    isValidJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (ex) {
        return false;
      }
    },
    async updateTimesUsedPermission() {
      const data = await PermissionService.updateTimesUsedStatusRequest({
        code: this.wasApprovedCheif.code,
      });
    },

    refreshVerifyStatusRequest() {
      this.$refs.verifyStatusRequest.getVerifyStatusRequest();
      this.showModalAppro = false;
    },
  },
  watch: {
    cardsToDisable(value) {
      this.invalidCards = value.map((id) => ({ id }));
    },
    getRefreshTable(value) {
      if (value) {
        this.$refs.RequestNcrTable.refresh();
        this.CHANGE_REFRESH_TABLE(false);
      }
    },
  },
};
</script>
<style scoped>
*{
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.title-card {
  margin-bottom: 0.3rem;
}

.doediv {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricesdiv {
  height: 2.6rem;
  padding: 7px 0;
}
</style>
