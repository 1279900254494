<template>
  <div class="new-dashboard-payments-ce-ing">
    <b-row>
      <b-col xxl="3" xl="3" lg="12" md="12" cols="12">
        <b-row :key="countersKey">
          <b-col xxl="12" xl="12" lg="4" md="4" cols="12">
            <div
              class="custom-card-1"
              :class="
                Number(totalFeeCharges) > 0 ? 'active-cu' : 'without-payments'
              "
            >
              <div class="title">Total FEE, Charges and IP & Credits</div>
              <div class="amount">
                <div class="sub-dollar">$</div>
                {{ getAmountDecimal(totalFeeCharges)[0] }}.
                <div class="decimal">
                  {{ getAmountDecimal(totalFeeCharges)[1] }}
                </div>
              </div>
              <b-progress
                :value="getPorcent(totalPaidFeeCharges, totalFeeCharges)"
              ></b-progress>
              <b-row class="detail">
                <b-col cols="6">
                  <div class="subtitle">Total payments</div>
                  <div class="amount">
                    $ {{ totalPaidFeeCharges | currencyZero }}
                  </div>
                </b-col>
                <b-col cols="6">
                  <div
                    class="subtitle subtitle-right"
                    v-if="totalPendingFeeCharges >= 0"
                  >
                    Left to pay
                  </div>
                  <div class="subtitle subtitle-right" v-else>Overpayment</div>
                  <div
                    class="amount-red subtitle-right"
                    v-if="totalPendingFeeCharges > 0"
                  >
                    $ {{ totalPendingFeeCharges | currencyZero }}
                  </div>
                  <div class="amount-green subtitle-right" v-else>
                    $ {{ (totalPendingFeeCharges * -1) | currencyZero }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col xxl="12" xl="12" lg="4" md="4" cols="12">
            <div class="custom-card-2">
              <div class="title d-flex justify-content-between">
                <div>Total FEE + IP</div>
                <div v-if="totalAmountFee.count_approved > 0">
                  <b-badge
                    variant="light-warning cursor-info"
                    v-b-tooltip.hover
                    :title="`$${totalAmountFee.pending_approved} in ${totalAmountFee.count_approved} Other payments`"
                  >
                    {{ totalAmountFee.count_approved }}</b-badge
                  >
                </div>
              </div>
              <div class="amount">
                $ {{ getAmountDecimal(totalFeeIPCredits)[0] }}.<span
                  class="decimal"
                  >{{ getAmountDecimal(totalFeeIPCredits)[1] }}</span
                >
              </div>
              <b-progress :max="totalFeeIPCredits">
                <b-progress-bar
                  v-b-tooltip.hover
                  :title="`Paid ($${totalPaidFeeIPCredits})`"
                  :value="totalPaidFeeIPCredits"
                  variant="primary"
                  class="cursor-info"
                ></b-progress-bar>
                <b-progress-bar
                  v-b-tooltip.hover
                  :title="`$${totalAmountFee.pending_approved} in Other payments`"
                  :value="totalAmountFee.pending_approved"
                  variant="warning"
                  class="cursor-info"
                ></b-progress-bar>
              </b-progress>

              <b-row class="detail">
                <b-col cols="6">
                  <div class="subtitle">Total payments</div>
                  <div class="amount">
                    $ {{ totalPaidFeeIPCredits | currencyZero }}
                  </div>
                  <!-- initial payment -->
                  <div>
                    <span
                      style="font-size: 14px"
                      class="text-secondary cursor-info font-weight-bold"
                      v-b-tooltip.hover.top="'Initial Payment'"
                      v-if="
                        parseFloat(totalAmountCreditsAndIP.initital_payment) > 0
                      "
                    >
                      ($ {{ totalAmountCreditsAndIP.initital_payment }})
                    </span>
                  </div>
                </b-col>
                <b-col cols="6">
                  <div
                    class="subtitle subtitle-right"
                    v-if="totalAmountFee.pending >= 0"
                  >
                    Left to pay
                  </div>
                  <div class="subtitle subtitle-right" v-else>Overpayment</div>
                  <div
                    class="amount-red subtitle-right"
                    v-if="totalAmountFee.pending > 0"
                  >
                    $ {{ totalAmountFee.pending | currencyZero }}
                  </div>
                  <div class="amount-green subtitle-right" v-else>
                    $ {{ (totalAmountFee.pending * -1) | currencyZero }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col xxl="12" xl="12" lg="4" md="4" cols="12">
            <div class="custom-card-2">
              <div class="title d-flex justify-content-between">
                <div>Additional Charges</div>
                <div v-if="totalAmountCharges.count_pending_approved > 0">
                  <b-badge
                    variant="light-warning cursor-info"
                    v-b-tooltip.hover
                    :title="`$${totalAmountCharges.pending_approved} in ${totalAmountCharges.count_pending_approved} Other payments `"
                  >
                    {{ totalAmountCharges.count_pending_approved }}</b-badge
                  >
                </div>
              </div>
              <div class="amount">
                $ {{ getAmountDecimal(totalAmountCharges.charge)[0] }}.<span
                  class="decimal"
                  >{{ getAmountDecimal(totalAmountCharges.charge)[1] }}</span
                >
              </div>
              <b-progress :max="totalAmountCharges.charge">
                <b-progress-bar
                  v-b-tooltip.hover
                  :title="`Paid ($${totalAmountCharges.paid})`"
                  :value="totalAmountCharges.paid"
                  variant="primary"
                  class="cursor-info"
                ></b-progress-bar>
                <b-progress-bar
                  v-b-tooltip.hover
                  :title="`$${totalAmountCharges.pending_approved} in Other payments`"
                  :value="totalAmountCharges.pending_approved"
                  variant="warning"
                  class="cursor-info"
                ></b-progress-bar>
              </b-progress>
              <b-row class="detail">
                <b-col cols="6">
                  <div class="subtitle">Total payments</div>
                  <div class="amount">
                    $ {{ totalAmountCharges.paid | currencyZero }}
                  </div>
                </b-col>
                <b-col cols="6">
                  <div
                    class="subtitle subtitle-right"
                    v-if="totalAmountCharges.pending >= 0"
                  >
                    Left to pay
                  </div>
                  <div class="subtitle subtitle-right" v-else>Overpayment</div>
                  <div
                    class="amount-red subtitle-right"
                    v-if="totalAmountCharges.pending > 0"
                  >
                    $ {{ totalAmountCharges.pending | currencyZero }}
                  </div>
                  <div class="amount-green subtitle-right" v-else>
                    $ {{ totalAmountCharges.pending | currencyZero }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>

          <b-col xxl="12" xl="12" lg="4" md="4" cols="12">
            <div class="custom-card-2">
              <div class="title d-flex justify-content-between">
                <div>Charge Backs</div>
              </div>
              <div class="amount">
                $ {{ getAmountDecimal(totalChargeBacks.total)[0] }}.<span
                  class="decimal"
                  >{{ getAmountDecimal(totalChargeBacks.total)[1] }}</span
                >
              </div>
              <b-progress :max="totalChargeBacks.total">
                <b-progress-bar
                  v-b-tooltip.hover
                  :title="`Paid ($${totalChargeBacks.paid})`"
                  :value="totalChargeBacks.paid"
                  variant="primary"
                  class="cursor-info"
                ></b-progress-bar>
              </b-progress>

              <b-row class="detail">
                <b-col cols="6">
                  <div class="subtitle">Total payments</div>
                  <div class="amount">
                    $ {{ totalChargeBacks.paid | currencyZero }}
                  </div>
                </b-col>
                <b-col cols="6">
                  <div
                    class="subtitle subtitle-right"
                    v-if="totalChargeBacks.pending >= 0"
                  >
                    Left to pay
                  </div>
                  <div class="subtitle subtitle-right" v-else>Overpayment</div>
                  <div
                    class="amount-red subtitle-right"
                    v-if="totalChargeBacks.pending > 0"
                  >
                    $ {{ totalChargeBacks.pending | currencyZero }}
                  </div>
                  <div class="amount-green subtitle-right" v-else>
                    $ {{ totalChargeBacks.pending | currencyZero }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col xxl="9" xl="9" lg="12" md="12" cols="12">
        <div class="d-flex justify-content-end mb-1">
          <div
            id="profit_value"
            class="d-flex align-items-center px-2 py-1 border rounded"
            :style="
              isDarkSkin
                ? 'background-color: #343a40'
                : 'background-color: #efefef;'
            "
          >
            <div class="mr-2 text-warning" style="font-weight: bold">
              PROFIT:
            </div>
            <div
              class="h5 mb-0"
              :class="
                clientProfit > 0
                  ? 'text-success'
                  : clientProfit < 0
                  ? 'text-danger'
                  : ''
              "
              style="font-weight: bold"
            >
              {{ clientProfit | formatMoney }}
            </div>
          </div>
          <b-tooltip triggers="hover" target="profit_value" placement="top">
            <div style="font-weight: bolder">
              <span class="text-success">TOTAL PAYMENTS</span> -
              <span class="text-danger">TOTAL EXPENSES</span>
              <br />
              <span class="text-success">{{ totalPaidFeeCharges | currencyZero | formatMoney}}</span>
              -
              <span class="text-danger">{{ client.total_expenses | formatMoney }}</span>
            </div>
          </b-tooltip>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="tab-container">
            <div
              class="tab"
              :class="tabIndex == 0 ? 'active-cu' : ''"
              @click="tabIndex = 0"
            >
              <feather-icon icon="CalendarIcon" class="icon"></feather-icon>
              Payment schedule
            </div>
            <div
              class="tab"
              :class="tabIndex == 1 ? 'active-cu' : ''"
              @click="tabIndex = 1"
            >
              <charge-icon
                :width="17"
                :height="17"
                :active="tabIndex == 1"
                style="margin-bottom: 4px"
              />
              Charges

              <div
                v-if="totalAmountCharges.count_pending > 0"
                class="cont"
                v-b-tooltip.hover
                title="Pending to pay"
              >
                {{ totalAmountCharges.count_pending }}
              </div>
            </div>
            <div class="tab" @click="changeMenu(2)" :class="setStatusBadge(2)">
              <feather-icon icon="TrendingDownIcon" class="icon"></feather-icon>
              Returns

              <div
                v-if="totalReturns.counter > 0"
                class="cont"
                v-b-tooltip.hover
                title="Total returns"
              >
                {{ totalReturns.counter }}
              </div>
            </div>
            <div class="tab" :class="setStatusBadge(4)" @click="changeMenu(4)">
              <feather-icon icon="RepeatIcon" class="icon"></feather-icon>
              Charge Backs

              <div
                v-if="totalChargeBacks.counter > 0"
                class="cont"
                v-b-tooltip.hover
                :title="`${totalChargeBacks.counter} Charge Backs`"
              >
                {{ totalChargeBacks.counter }}
              </div>
            </div>
            <div
              class="tab"
              :class="tabIndex == 3 ? 'active-cu' : ''"
              @click="tabIndex = 3"
            >
              <feather-icon icon="DollarSignIcon" class="icon"></feather-icon>
              IP & Credits
            </div>
          </div>
          <div class="d-flex align-items-center">
            <b-button
              variant="primary"
              class="my-button mr-1 d-flex justify-content-center"
              style="gap: 4px"
              @click="showExpensesModal = true"
            >
              <span>EXPENSES</span>
              <b-badge
                v-if="total_expenses > 0"
                pill
                variant="danger"
                style="height: auto; width: 20px; padding: 3px 2px"
              >
                {{ total_expenses }}
              </b-badge>
            </b-button>
            <b-button
              variant="primary"
              class="my-button"
              v-if="!readOnly"
              @click="openCreditCardInfoModal"
            >
              + CREDIT CARDS

              <span
                v-if="countData > 0"
                class="badge rounded-pill bg-danger small"
                style="font-size: 90% !important; margin-left: 0.5rem"
              >
                {{ countData }}</span
              >
            </b-button>

            <div>
              <b-dropdown
                id="dropdown-left"
                text="INVOICE"
                variant="primary"
                class="ml-1"
              >
                <b-dropdown-item href="#" @click="showInvoice"
                  >PREVIEW PDF</b-dropdown-item
                >
                <b-dropdown-item href="#" @click="sendByEmail"
                  >SEND BY EMAIL</b-dropdown-item
                >
                <b-dropdown-item href="#" @click="sendBySms"
                  >SEND BY SMS</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <custom-table
            v-if="tabIndex == 0"
            :client-account-id="clientAccountId"
            :read-only="readOnly"
            :total-amount-fee-pending="parseFloat(totalAmountFee.pending)"
            with-tabs
            @refresh="initComponents"
          />
          <charge-table
            v-if="tabIndex == 1"
            :client-account-id="clientAccountId"
            :read-only="readOnly"
            @refresh="initComponents"
          />
          <returns-table
            v-if="tabIndex == 2"
            :client-account-id="clientAccountId"
            :read-only="readOnly"
            @refresh="initComponents"
          />
          <credits-and-ip-table
            v-if="tabIndex == 3"
            :client-account-id="clientAccountId"
            :read-only="readOnly"
            @refresh="initComponents"
          />

          <charge-back-table
            v-if="tabIndex == 4"
            :client-account-id="clientAccountId"
            :read-only="readOnly"
            @refresh="initComponents"
          />
        </div>
      </b-col>
    </b-row>

    <credit-card-modal
      v-if="creditCardOn"
      :client="client"
      @close="creditCardOn = false"
    />

    <expenses-modal
      v-if="showExpensesModal"
      @close="showExpensesModal = false"
    />
  </div>
</template>

<script>
import ChargeIcon from "@/icons/ChargeIcon.vue";
import CustomTable from "@/views/commons/components/clients/dashboard/options/pay-module/components/PaymentScheduleTable.vue";
import ChargeTable from "@/views/commons/components/clients/dashboard/options/pay-module/components/ChargesTable.vue";
import CreditCardModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/credit-card-modal/CreditCardModal.vue";
import ReturnsTable from "@/views/commons/components/clients/dashboard/options/pay-module/components/ReturnsTable.vue";
import ClientService from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
import PaymentScheduleService from "@/views/commons/components/clients/components/paymentSchedule/service/payment-schedule.service.js";
import CreditsAndIpTable from "@/views/commons/components/clients/dashboard/options/pay-module/components/CreditsTable.vue";
import ChargeBackTable from "@/views/commons/components/clients/dashboard/options/pay-module/components/ChargeBackPayments.vue";
import { mapGetters, mapActions } from "vuex";
import DashboardService from "@/views/commons/components/clients/dashboard/options/pay-module/components/service/dashboard.service.js";
import ExpensesModal from "@/views/ceo/dashboard/modals/expenses/ExpensesModal.vue";

export default {
  components: {
    CustomTable,
    ChargeIcon,
    ChargeTable,
    ReturnsTable,
    CreditsAndIpTable,
    CreditCardModal,
    ChargeBackTable,
    ExpensesModal,
  },
  props: {
    clientAccountId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      tabIndex: 0,
      creditCardOn: false,
      totalAmountFee: {
        fee: "0",
        paid: "0",
        pending: "0",
        pending_approved: "0",
        count_approved: "0",
      },

      totalAmountCharges: {
        charge: "0",
        paid: "0",
        pending: "0",
        count_pending: "0",
        pending_approved: "0",
        count_pending_approved: "0",
        counter: "0",
      },

      totalAmountCreditsAndIP: {
        initital_payment: "0",
        total_credits: "0",
        paid: "0",
        pending: "0",
      },

      totalChargeBacks: {
        total: "0",
        paid: "0",
        pending: "0",
        counter: "0",
      },

      totalReturns: {
        counter: "0",
      },
      countersKey: Math.random(),
      showExpensesModal: false,
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      countData: "DebtSolutionClients/G_COUNT_CREDIT_CARD",
      total_expenses: "ExpensesStore/G_TOTAL_GENERAL_EXPENSES_CLIENT",
      pending_expenses: "ExpensesStore/G_PENDING_EXPENSES_CLIENT",
      confirmed_expenses: "ExpensesStore/G_CONFIRMED_EXPENSES_CLIENT",
    }),

    totalFeeCharges() {
      const totalAmountFee = parseFloat(
        this.totalAmountFee.fee.replace(",", "")
      );
      const totalAmountCharges = parseFloat(
        this.totalAmountCharges.charge.replace(",", "")
      );
      const totalAmountCreditsAndIP = parseFloat(
        this.totalAmountCreditsAndIP.paid.replace(",", "")
      );

      const totalAmountChargeBacks = parseFloat(
        this.totalChargeBacks.total.replace(",", "")
      );

      const totalAmountPendingCharge = parseFloat(
        this.totalAmountCharges.pending.replace(",", "")
      );

      const result =
        totalAmountFee +
        totalAmountCharges +
        totalAmountCreditsAndIP +
        totalAmountChargeBacks -
        totalAmountPendingCharge;
      return result.toFixed(2);
    },

    totalPendingFeeCharges() {
      const totalAmountFee = parseFloat(
        this.totalAmountFee.pending.replace(",", "")
      );
      // const totalAmountCharges = parseFloat(
      //   this.totalAmountCharges.pending.replace(",", "")
      // );

      const totalAmountChargeBacks = parseFloat(
        this.totalChargeBacks.pending.replace(",", "")
      );

      return totalAmountFee + totalAmountChargeBacks;
    },

    totalPaidFeeCharges() {
      const totalAmountFee = parseFloat(
        this.totalAmountFee.paid.replace(",", "")
      );
      const totalAmountCharges = parseFloat(
        this.totalAmountCharges.paid.replace(",", "")
      );
      const totalAmountCreditsAndIP = parseFloat(
        this.totalAmountCreditsAndIP.paid.replace(",", "")
      );
      const totalAmountChargeBacks = parseFloat(
        this.totalChargeBacks.paid.replace(",", "")
      );
      return (
        totalAmountFee +
        totalAmountCharges +
        totalAmountCreditsAndIP +
        totalAmountChargeBacks
      );
    },

    totalFeeIPCredits() {
      const totalAmountFee = parseFloat(
        this.totalAmountFee.fee.replace(",", "")
      );
      const totalAmountCreditsAndIP = parseFloat(
        this.totalAmountCreditsAndIP.paid.replace(",", "")
      );

      return totalAmountFee + totalAmountCreditsAndIP;
    },

    totalPaidFeeIPCredits() {
      const totalAmountFee = parseFloat(
        this.totalAmountFee.paid.replace(",", "")
      );
      const totalAmountCreditsAndIP = parseFloat(
        this.totalAmountCreditsAndIP.paid.replace(",", "")
      );

      return totalAmountFee + totalAmountCreditsAndIP;
    },
    clientProfit() {
      // return this.client.profit;
      return this.totalPaidFeeCharges - this.client.total_expenses;
    },
  },
  methods: {
    ...mapActions({
      A_COUNT_EXPENSES_BY_CLIENT: "ExpensesStore/A_COUNT_EXPENSES_BY_CLIENT",
      A_COUNT_CHECK_EXPENSES_BY_CLIENT: "ExpensesStore/A_COUNT_EXPENSES_BY_CLIENT",
      A_TOTAL_EXPENSES_BY_CLIENT: "ExpensesStore/A_TOTAL_EXPENSES_BY_CLIENT"
    }),
    async sendByEmail() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          client_account_id: this.clientAccountId,
        };
        const { data } =
          await PaymentScheduleService.sendPaymentScheduleByEmail(params);
        this.showSuccessSwal(data.message);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async sendBySms() {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          client_account_id: this.clientAccountId,
        };
        const { data } = await PaymentScheduleService.sendPaymentScheduleBySms(
          params
        );
        this.showSuccessSwal(data.message);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async showInvoice() {
      try {
        this.addPreloader();
        this.showGenericToast({
          position: "top-center",
          variant: "info",
          icon: "InfoIcon",
          title: "Info",
          text: "Generating the invoice, please wait a moment.",
        });

        const params = {
          client_account_id: this.clientAccountId,
        };
        const { data } =
          await PaymentScheduleService.showPreviewPaymentScheduleInvoice(
            params
          );
        window.open(data.url, "_blank");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    openCreditCardInfoModal() {
      this.creditCardOn = true;
    },

    async initComponents() {
      await this.getTotalAmountFee();
      await this.getTotalAmountCharge();
      await this.getTotalCreditsAndInitialPayments();
      await this.getChargeBacks();
      await this.getReturns();
      await this.A_COUNT_EXPENSES_BY_CLIENT(this.$route.params.idClient);
      await this.A_TOTAL_EXPENSES_BY_CLIENT(this.$route.params.idClient);
      this.countersKey = Math.random();
    },

    getAmountDecimal(amount) {
      const newAmount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      var arrayValues = newAmount.split(".");
      let decimalValue = "00";
      if (arrayValues[1]) {
        decimalValue =
          arrayValues[1].length == 1 ? arrayValues[1] + "0" : arrayValues[1];
      }
      return [arrayValues[0], decimalValue];
    },

    getPorcent(paid, total) {
      const floatPaid = parseFloat(paid.toString().replace(",", ""));
      const floatTotal = parseFloat(total.toString().replace(",", ""));

      if (floatTotal == 0) {
        return 100;
      }
      return (floatPaid / floatTotal) * 100;
    },

    async getTotalAmountFee() {
      const params = {
        client_account_id: this.clientAccountId,
      };
      const { data } = await PaymentScheduleService.getTotalAmountFee(params);
      this.totalAmountFee = {
        fee: data.total[0].fee ? data.total[0].fee : "0",
        paid: data.total[0].paid ? data.total[0].paid : "0",
        pending: data.total[0].pending ? data.total[0].pending : "0",
        pending_approved: data.total[0].pending_approved
          ? data.total[0].pending_approved
          : "0",
        count_approved: data.total[0].count_approved
          ? data.total[0].count_approved
          : "0",
      };
    },

    async getTotalAmountCharge() {
      const params = {
        client_account_id: this.clientAccountId,
        search_txt: "",
        not_paginate: 1,
      };

      const { data } = await ClientService.getChargesByClientAccount(params);
      this.totalAmountCharges = {
        charge: data[0].charge ? data[0].charge : "0",
        paid: data[0].paid ? data[0].paid : "0",
        pending: data[0].pending ? data[0].pending : "0",
        count_pending: data[0].count_pending ? data[0].count_pending : "0",
        pending_approved: data[0].pending_approved
          ? data[0].pending_approved
          : "0",
        count_pending_approved: data[0].count_pending_approved
          ? data[0].count_pending_approved
          : "0",

        counter: data[0].counter ? data[0].counter : "0",
      };
    },

    async getTotalCreditsAndInitialPayments() {
      try {
        this.addPreloader();
        const { data } =
          await ClientService.getTotalCreditsAndInitialPaymentsByClientAccount(
            this.clientAccountId
          );

        if (data.length === 0) return;

        this.totalAmountCreditsAndIP = {
          initital_payment: data[0].initital_payment ?? "0",
          total_credits: data[0].total_credits ?? "0",
          paid: data[0].paid ?? "0",
          pending: data[0].pending ?? "0",
        };
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getChargeBacks() {
      try {
        const params = {
          client_account_id: this.clientAccountId,
          search_txt: null,
          per_page: null,
          current_page: null,
          not_paginate: true,
        };

        const { data } = await DashboardService.getChargeBacksByClientAccount(
          params
        );

        this.totalChargeBacks = {
          total: data[0].total ? data[0].total : "0",
          paid: data[0].paid ? data[0].paid : "0",
          pending: data[0].pending ? data[0].pending : "0",
          counter: data[0].counter ? data[0].counter : "0",
        };
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async getReturns() {
      try {
        const params = {
          client_account_id: this.clientAccountId,
          search_txt: null,
          per_page: null,
          current_page: null,
          not_paginate: true,
        };

        const { data } = await ClientService.getRefundsByClientAccount(params);

        this.totalReturns = {
          counter: data[0].counter ? data[0].counter : "0",
        };
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    changeMenu(index) {
      if (index == 2 && this.totalReturns.counter == 0) {
        return;
      }

      if (index == 4 && this.totalChargeBacks.counter == 0) {
        return;
      }

      this.tabIndex = index;
    },

    setStatusBadge(index) {
      let customClass = [];

      if (index == 1 && this.totalAmountCharges.counter == 0) {
        customClass.push("tab-desactived");
      }

      if (index == 2 && this.totalReturns.counter == 0) {
        customClass.push("tab-desactived");
      }

      if (index == 4 && this.totalChargeBacks.counter == 0) {
        customClass.push("tab-desactived");
      }

      if (this.tabIndex == index) {
        customClass.push("active-cu");
      }

      return customClass;
    },
  },

  created() {
    this.initComponents();
  },
};
</script>

<style lang="scss">
.new-dashboard-payments-ce-ing {
  height: 100%;

  .custom-card-1 {
    height: 40%;
    min-height: 220px;

    .title {
      color: #0090e7;
      font-size: 20px;
      font-weight: 600;
    }

    .amount {
      display: flex;
      align-items: end;
      font-size: 40px;
      font-weight: 600;
      margin: 0;
      letter-spacing: 1.5px;
      font-family: "Oxygen";

      .sub-dollar {
        font-size: 25px;
        margin: 0 5px 15px 0;
      }

      .decimal {
        font-size: 20px;
        margin-bottom: 7px;
      }
    }
  }

  .custom-card-2 {
    height: 30%;
    min-height: 200px;

    .title {
      font-size: 20px;
    }

    .amount {
      font-size: 30px;
      font-weight: 600;
      margin: 5px 0;
      letter-spacing: 1px;
      font-family: "Oxygen";

      .decimal {
        font-size: 15px;
      }
    }
  }

  .custom-card-1,
  .custom-card-2 {
    border-radius: 10px;
    background-color: #ffffff;
    margin-bottom: 10px;
    box-shadow: 0 4px 24px 0 rgba(131, 131, 131, 0.1);
    padding: 20px 30px 0 30px;
    color: #414141;

    .progress {
      background-color: #dfdfdf;
    }

    .detail {
      margin-top: 5px;

      .subtitle {
        color: #4f4e4e;
        font-size: 16px;
      }

      .subtitle-right {
        text-align: right;
      }

      .amount {
        color: #414141;
        font-size: 18px;
        margin: 0;
        letter-spacing: 0.5px;
      }

      .amount-red {
        color: #eb0000;
        font-size: 18px;
        margin: 0;
        font-weight: 700;
      }
      .amount-green {
        color: #04b400;
        font-size: 18px;
        margin: 0;
        font-weight: 700;
      }
    }
  }

  .active-cu {
    border: 3px solid #0090e7;
    background-color: #f2faff;
  }

  .without-payments {
    border: 3px solid #04b400;
  }

  .tab-container {
    display: flex;

    .tab {
      margin: 0 10px;
      padding: 5px 10px;
      border-radius: 25px;
      border: 2px solid #d9d9d9;
      background-color: #ffffff;
      color: #27292e;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      min-height: 38px;
      letter-spacing: 0.5px;
      position: relative;

      .icon {
        margin-bottom: 2px;
      }

      .cont {
        min-width: 25px;
        text-align: center;
        position: absolute;
        top: -25%;
        right: -10%;
        color: #ffffff;

        background-color: red;
        box-shadow: 0 4px 24px 0 rgba(131, 131, 131, 0.2);
        border-radius: 50%;
        font-size: 13px;
        padding: 2px 4px;
      }
    }

    .active-cu {
      border: 2px solid #0090e7;
      background-color: #e1f3fe;
    }

    .tab-desactived {
      background-color: #f5f5f5 !important;
      color: #adadad !important;
      cursor: not-allowed;
    }
  }

  .my-button {
    font-weight: 600;
    color: #ffffff;
    background-color: #0090e7 !important;
  }
}

.dark-layout {
  .new-dashboard-payments-ce-ing {
    .custom-card-1 {
      .title {
        color: #0090e7;
      }
    }

    .custom-card-1,
    .custom-card-2 {
      background-color: rgb(24, 24, 24);
      box-shadow: 0 4px 24px 0 rgba(131, 131, 131, 0.1);
      color: #ffffff;

      .progress {
        background-color: #dfdfdf;
      }

      .detail {
        .subtitle {
          color: #ffffff;
        }

        .amount {
          color: #ffffff;
        }
      }
    }

    // .active-cu {
    //   background-color: rgb(24, 24, 24);
    // }

    .tab-container {
      .tab {
        border: 2px solid #5a5959;
        background-color: #1d1d20;
        color: #ffffff;
      }

      .active-cu {
        background-color: #e1f3fe;
        color: #27292e;
      }

      .tab-desactived {
        background-color: #2f2f31 !important;
        color: #6f6f70 !important;
        cursor: not-allowed;
      }
    }
  }
}

@media (max-width: 1450px) and (min-width: 407px) {
  .new-dashboard-payments-ce-ing {
    .custom-card-1 {
      min-height: 200px;

      .title {
        font-size: 15px;
      }

      .amount {
        font-size: 30px;
        letter-spacing: 0.5px;

        .sub-dollar {
          font-size: 20px;
          margin: 0 5px 10px 0;
        }

        .decimal {
          font-size: 20px;
          margin-bottom: 4px;
        }
      }
    }

    .custom-card-2 {
      min-height: 200px;

      .title {
        font-size: 15px;
      }

      .amount {
        font-size: 25px;
        margin: 5px 0;

        .decimal {
          font-size: 12px;
        }
      }
    }

    .custom-card-1,
    .custom-card-2 {
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 20px 25px 0 25px;

      .detail {
        margin-top: 10px;

        .subtitle {
          font-size: 13px;
        }

        .amount {
          font-size: 15px;
          margin: 0;
          letter-spacing: 0.5px;
        }

        .amount-red {
          font-size: 15px;
          margin: 0;
        }
      }
    }
  }
}
</style>