import ListClientCompanyService from '@/views/commons/components/list-client-company/services/list-client-company.service'

export default {
    namespaced: true,
    state: {
        filterDataAdvisorCompany: [],
        filterDataPacksId: [{
            id_packs: null,
            description: 'All',
          }]
    },
    getters: {
        getDataAdvisorEmpty(state) {
            return state.filterDataAdvisorCompany.empty()
        },
        getDataPacksIdEmpty(state) {
            return state.filterDataPacksId.empty()
        },
        getDataPacksId(state) {
            return state.filterDataPacksId
        }
    },
    mutations: {
        SET_ADVISORS_PARAGON_FROM_BUSINESS(state, advisorsBusiness) {
            state.filterDataAdvisorCompany = advisorsBusiness
        },
        SET_NON_REPEATING_PACKS(state, advisorsBusiness) {
            state.filterDataAdvisorCompany = advisorsBusiness
        },
    },
    actions: {
        async getDataPacksFilter({ commit }) {
          const packs = await ListClientCompanyService.nonRepeatingPacksFilter()
          commit('SET_NON_REPEATING_PACKS', [{
            id_packs: null,
            description: 'All',
          }, ...formattedPacks])
        },
    }
}