export default [
  {
    path: 'categories',
    name: 'categories',
    component: () => import(/* webpackChunkName: "ManaCategories" */ '@/views/management/views/categories/Categories.vue'),
    meta: {
      // permittedRoles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      pageTitle: 'Categories',
      breadcrumb: [{
        text: 'Categories',
      }],
    },
  },
];
