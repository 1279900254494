import ProjectsMain from '@/views/commons/components/projects/ProjectsMain.vue';

export default [
  {
    path: '/specialist-digital/projects',
    name: 'specialist-digital-projects',
    component: ProjectsMain,
    meta: {
      pageTitle: 'Projects',
      breadcrumb: [
        {
          text: 'Projects',
        },
      ],
      permittedRoles: [1, 2, 17],
      tab: 'my_projects',
    },
  },
];
