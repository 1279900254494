export default [
    {
        path: "payments",
        name: "tax-research-payments",
        component: () => import(/* webpackChunkName: "TaxResearchPayments" */ '@/views/tax-research/views/payments/Payments.vue'),
        meta: {
            pageTitle: "Payments",
            breadcrumb: [
                {
                    text: "Payments",
                    active: true
                },
            ],
            route: "taxresearch"
        },
    }
]