export default [
  {
    path: 'letters',
    name: 'specialistdigital-letters',
    component: () => import(/* webpackChunkName: "SpecialistDigitalLetters" */ '@/views/specialist-digital/views/letters-round/components/LettersTab.vue'),
    redirect: { name: 'pending-letters-specialistdigital' },
    meta: {
      pageTitle: 'Letters Round',
      breadcrumb: [
        {
          text: 'Letters Round',
          active: true,
        },
      ],
    },
    children: [
      {
        path: 'returned',
        name: 'returned-letters-specialistdigital',
        component: () => import(/* webpackChunkName: "SpecialistDigitalLettersReturned" */ '@/views/specialist-digital/views/letters-round/components/LettersTable.vue'),
        meta: {
          isClientsTab: true,
          pageTitle: 'Letters Round',
          typeTab: 'returned',
          breadcrumb: [
            {
              text: 'Returned',
              active: true,
            },
          ],
        },
        props: { statusLetterType: 'returned' }
      },
      {
        path: 'pending',
        name: 'pending-letters-specialistdigital',
        component: () => import(/* webpackChunkName: "SpecialistDigitalLettersPending" */ '@/views/specialist-digital/views/letters-round/components/LettersTable.vue'),
        meta: {
          isClientsTab: true,
          pageTitle: 'Letters Round',
          typeTab: 'pending',
          breadcrumb: [
            {
              text: 'Pending',
              active: true,
            },
          ],
        },
        props: { statusLetterType: 'pending' }
      },
      {
        path: 'completed',
        name: 'completed-letters-specialistdigital',
        component: () => import(/* webpackChunkName: "SpecialistDigitalLettersCompleted" */ '@/views/specialist-digital/views/letters-round/components/LettersTable.vue'),
        meta: {
          isClientsTab: true,
          pageTitle: 'Letters Round',
          typeTab: 'completed',
          breadcrumb: [
            {
              text: 'Completed',
              active: true,
            },
          ],
        },
        props: { statusLetterType: 'completed' }
      },
    ],
  },
]
