<template>
  <div>
    <b-row>
      <b-col cols="12" md="3">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group label="Type">
            <v-select
              v-bind="typeScope.attr"
              v-model="formExpense.type"
              :class="{ 'invalid-select': errors.length > 0 }"
              :disabled="disabledType"
            ></v-select>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col cols="12" md="4" v-if="openFromDashboardCeo">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group label="Check number">
            <b-form-input
              v-model="formExpense.check_number"
              :class="{
                'border-danger': errors.length > 0,
              }"
            />
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col cols="12" md="5" v-if="openFromDashboardCeo">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group label="Check file">
            <template #append>
              <div
                v-if="formExpense.check_file"
                class="d-flex justify-content-center align-items-center"
              >
                <feather-icon
                  icon="FileIcon"
                  class="cursor-pointer text-danger ml-1"
                  size="20"
                  v-b-tooltip.hover.top="'Preview'"
                  @click="previewFile(formExpense.check_file)"
                />
                <feather-icon
                  icon="TrashIcon"
                  class="cursor-pointer text-danger ml-1"
                  size="20"
                  v-b-tooltip.hover.top="'Remove'"
                  @click="removeFile"
                />
              </div>
            </template>
            <b-form-file
              v-model="formExpense.check_file"
              :class="{
                'border-danger': errors.length > 0,
              }"
            />
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
    <slot name="selectors-general" />
    <b-row>
      <b-col cols="12">
        <b-form-group label="Attach File">
          <b-input-group>
            <template #append>
              <div
                class="d-flex justify-content-center align-items-center"
                v-if="formExpense.file"
              >
                <amg-icon
                  icon="FilePdfIcon"
                  class="cursor-pointer text-danger ml-1"
                  size="20"
                  v-b-tooltip.hover.top="'Preview'"
                  @click="previewPdf(formExpense.file)"
                />
                <feather-icon
                  icon="TrashIcon"
                  class="cursor-pointer text-danger ml-1"
                  size="20"
                  v-b-tooltip.hover.top="'Remove'"
                  @click="removeFile"
                />
              </div>
            </template>
            <b-form-file v-model="formExpense.file" accept=".pdf" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <slot name="transaction-inputs" />
  </div>
</template>
<script>
export default {
  name: "CheckForm",
  props: {
    formExpense: {
      type: Object,
      required: true,
    },
    disabledType: {
      type: Boolean,
      required: true,
    },
    programIdClient: {
      type: Number,
      default: () => null,
    },
    methodScope: {
      type: Object,
      required: true,
    },
    typeScope: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fileToUpload: null,
    };
  },
  computed: {

    openFromDashboardCeo() {
      return this.$route.matched[0].name === "ceo-dashboard";
    },
  },
  methods: {
    removeFile() {
      this.$emit("removeFile");
    },
    previewPdf(file) {
      this.$emit("previewPdf", file);
    },
  },
  mounted() {},
};
</script>
