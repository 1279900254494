import Vue from 'vue'
import Vuex from 'vuex'
import RoundLettersStore from '@/views/specialist-digital/views/letters-round/store/index'
import DirectRoundsStore from '@/views/specialist-digital/views/directs-round/store/index'

Vue.use(Vuex)

export default {
  SpecialistsDigitalRoundLettersStore: RoundLettersStore,
  SpecialistsDigitalDirectRoundsStore: DirectRoundsStore,
}
