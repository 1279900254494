import { otherServicesTab } from "@/views/commons/components/other-services/router/appointment.tab.js";

export default [
  {
    path: "other-services",
    name: "customerservice-other-services",
    redirect: { name: "cs-other-services-pending" },
    component: () => import(
      "@/views/commons/components/other-services/view/OtherServicesMain.vue"
    ),
    meta: {
      router: "cs-other-services",
      pageTitle: "Other Services",
      breadcrumb: [
        {
          text: "Other Services",
          active: true,
        },
      ],

      routePending: "cs-other-services-pending",
      routeCompleted: "cs-other-services-completed",
      routeExpired: "cs-other-services-expired",
      routeSuccessfull: "cs-other-services-successfull",
      routeUnsuccessful: "cs-other-services-unsuccessful",
    },
    children: otherServicesTab("cs-other-services","Other Services"),
  },
];