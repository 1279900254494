export default {
    namespaced: true,
    state: {
        S_COUNTER_DIRECT_ROUND_SPECIALISTS:0,
        S_VALID_DONE_DIRECT: true,
    },
    mutations: {
        M_SET_COUNTER_DIRECT_ROUND_SPECIALISTS(state, payload = { pending: 0 }) {
            state.S_COUNTER_DIRECT_ROUND_SPECIALISTS = payload.pending
        },
        M_VALID_DONE_DIRECT(state, data) {
          state.S_VALID_DONE_DIRECT = data;
        },
    },
    actions: {
        A_SET_COUNTER_DIRECT_ROUND_SPECIALIST({ commit }, payload = { pending: 0 }) {
            commit('M_SET_COUNTER_DIRECT_ROUND_SPECIALISTS', payload)
        },
        A_VALID_DONE_DIRECT({ commit }, data) {
          commit("M_VALID_DONE_DIRECT", data);
        },
    },
    getters: {
        G_COUNTER_PENDING_DIRECT_ROUND_SPECIALISTS: state => state.S_COUNTER_DIRECT_ROUND_SPECIALISTS,
        G_VALID_DONE_DIRECT(state) {
          return state.S_VALID_DONE_DIRECT;
        },
    }
}
