export default [
    {
        path: "letters-to-clients",
        name: "correspondence-letters-to-clients",
        redirect: { name: 'correspondence-letters-to-clients-pending' },
        component: () => import(/* webpackChunkName: "CorrespondenceLettersToClients" */ "@/views/correspondence/views/letters-to-clients/view/components/PendingTab.vue"),
        meta: {
            pageTitle: "Letters to Clients",
            breadcrumb: [
                {
                    text: "Letters to Clients",
                    active: true
                }
            ],

        },
        children: [
            {
                path: "pending",
                component: () => import("@/views/correspondence/views/letters-to-clients/view/components/SubTabs.vue"),
                redirect: { name: 'correspondence-letters-to-clients-sub-pending' },
                name: "correspondence-letters-to-clients-pending",
                meta: {
                    pageTitle: 'Letters to Clients',
                    breadcrumb: [
                        {
                            text: "Pending",
                            active: true
                        }
                    ],
                    tab: 1,
                    correspondenceClientRoute: 'correspondence-letters-to-clients-sub-pending',
                    correspondenceLeadRoute: 'correspondence-letters-to-leads-sub-pending',
                    //  permittedRoles:[1,2]
                },

                children: [
                    {
                        path: "clients",
                        component: () => import("@/views/correspondence/views/letters-to-clients/view/components/CorrespondencePending.vue"),
                        name: "correspondence-letters-to-clients-sub-pending",
                        meta: {
                            pageTitle: "Letters to Clients",
                            breadcrumb: [
                                {
                                    text: "Pending",
                                    active: true
                                }
                            ],
                            client: 1
                        }
                    },
                    {
                        path: "leads",
                        component: () => import("@/views/correspondence/views/letters-to-clients/view/components/CorrespondencePending.vue"),
                        name: "correspondence-letters-to-leads-sub-pending",
                        meta: {
                            pageTitle: "Letters to Leads",
                            breadcrumb: [
                                {
                                    text: "Pending",
                                    active: true
                                }
                            ],
                            client: 0
                        }
                    }
                ]
            },
            {
                path: "sent",
                component: () => import(/* webpackChunkName: "CorrespondenceLettersToClientsSent" */ "@/views/correspondence/views/letters-to-clients/view/components/SubTabs.vue"),
                name: "correspondence-letters-to-clients-sent",
                redirect: { name: 'correspondence-letters-to-clients-sub-sent' },
                meta: {
                    pageTitle: "Letters to Clients",
                    breadcrumb: [
                        {
                            text: "Sent",
                            active: true
                        }
                    ],
                    // permittedRoles:[1,2],
                    tab: 2,
                    correspondenceClientRoute: 'correspondence-letters-to-clients-sub-sent',
                    correspondenceLeadRoute: 'correspondence-letters-to-leads-sub-sent',
                },
                children: [
                    {
                        path: "clients",
                        component: () => import("@/views/correspondence/views/letters-to-clients/view/components/CorrespondenceSent.vue"),
                        name: "correspondence-letters-to-clients-sub-sent",
                        meta: {
                            pageTitle: "Letters to Clients",
                            breadcrumb: [
                                {
                                    text: "Send",
                                    active: true
                                }
                            ],
                            client: 1
                        }
                    },
                    {
                        path: "leads",
                        component: () => import("@/views/correspondence/views/letters-to-clients/view/components/CorrespondenceSent.vue"),
                        name: "correspondence-letters-to-leads-sub-sent",
                        meta: {
                            pageTitle: "Letters to Leads",
                            breadcrumb: [
                                {
                                    text: "Send",
                                    active: true
                                }
                            ],
                            client: 0
                        }
                    }
                ]
            }
        ]

    }
]