import Vue from "vue"

import store from "@/store"

const scheduleTransferAlert = () => {
  window.socket.bind("schedule-transfer-alert", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id
    if (sessionId === data.to_id) {
      const htmlSwal =
        `${
          '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
          '<div><h2 style="font-weight: 600;">NEW TRANSFER SCHEDULED</h2></div>' +
          '<div style="text-align: center">' +
          '<div style="font-weight: bolder;">AMOUNT: '
        }${data.currency} ${data.amount} </div>` +
        `<div style="font-weight: bolder;">DESTINATION ACCOUNT: ${data.destination_account} </div>` +
        "</div>"

      const res = await Vue.swal.fire({
        html: htmlSwal,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      })
      if (res) {
        window.open(
          `/management/companies/schedule-transfer/${data.company_id}`,
          "_blank"
        )
      }
    }
  })
}
export default scheduleTransferAlert
