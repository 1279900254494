<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-header-close
      hide-header
      :body-class="`rounded`"
      no-close-on-backdrop
      size="summary"
      @hidden="isActive = false"
      centered
      :ok-only="true"
      ok-variant="success"
      hide-footer
    >
      <div v-if="dataLetterRound">
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
        >
          <div class="d-flex align-items-center">
            <div class="icon-badge mr-1" :class="`bg-light-${setColor}`">
              <FeatherIcon
                :icon="setIcon"
                :class="`text-${setColor}`"
                size="20"
              />
            </div>
            <span
              :class="`text-${setColor}`"
              class="font-strong text-uppercase"
            >
              {{ alertTitle }}
            </span>
          </div>
          <div
            class="close-modal"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
            @click="closeAlert()"
          >
            <FeatherIcon icon="XIcon" size="16" />
          </div>
        </div>
        <div v-if="alertDescription" class="d-flex align-items-center">
          {{ alertDescription }}
        </div>
        <hr />
        <div class="d-flex flex-column" style="gap: 15px">
          <div
            v-for="info in alertInfo"
            :key="info.id"
            class="d-flex justify-content-between align-items-center"
          >
            <span class="font-strong">
              <FeatherIcon
                :icon="info.icon"
                class="mr-1 font-strong"
                size="14"
                svgClasees="font-small-4"
              />
              {{ info.label }}
            </span>
            <span class="font">
              <b-img
                v-if="info.label == 'Bureau'"
                :src="
                  dataLetterRound.bureau_id == 1
                    ? require('@/assets/images/icons/transunion.png')
                    : dataLetterRound.bureau_id == 2
                    ? require('@/assets/images/icons/experian.png')
                    : require('@/assets/images/icons/equifax.png')
                "
              />
              {{ info.value }}
            </span>
          </div>
          <!-- <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <FeatherIcon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
                CLIENT
            </span>
            <span class="font-strong">
              {{ dataLetterRound.client_name }}
            </span>
          </div> -->
        </div>
      </div>

      <hr />

      <b-row>
        <b-col>
          <b-button
            class="w-100 block"
            target="_blank"
            variant="info"
            @click="goToLetter()"
          >
            GO TO LETTER
          </b-button>
        </b-col>
        <b-col>
          <b-button
            class="w-100 block"
            target="_blank"
            variant="success"
            @click="closeAlert()"
          >
            OK
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      alertInfo: [
        {
          id: 1,
          icon: "UserIcon",
          label: "Client",
          value: null,
        },
        {
          id: 2,
          icon: "MapIcon",
          label: "Department",
          value: null,
        },
        {
          id: 3,
          icon: "UserCheckIcon",
          label: "Requested by",
          value: null,
        },
        {
          id: 4,
          icon: "HomeIcon",
          label: "Bureau",
          value: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_IS_ACTIVE: "AutoAlertLetterRound/G_IS_ACTIVE",
      G_DATA_LETTER_ROUND: "AutoAlertLetterRound/G_DATA_LETTER_ROUND",
      alertTitle: "AutoAlertLetterRound/G_TITLE",
      alertDescription: "AutoAlertLetterRound/G_DESCRIPTION",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    setIcon() {
      if (this.alertTitle == "MISSING FILES") return "FileMinusIcon";
      return "MailIcon";
    },

    setColor() {
      if (this.alertTitle == "MISSING FILES") return "danger";
      return "info";
    },

    routeName() {
      let routes = {
        // 5: "ds-letters-dispute-pending",
        5: "ds-letters-dispute-to-send-pending",
        7: "bc-letters-dispute-to-send-pending",
        8: "tr-letters-dispute-to-send-pending",
        9: "correspondence-letters-pending",
        10: "cd-letters-dispute-to-send-pending",
        12: "pr-letters-dispute-to-send-pending",
        20: "cn-letters-dispute-to-send-pending",
        21: "ce-letters-dispute-to-send-pending",
        22: "csd-letters-dispute-to-send-pending",
        28: "ad-letter-round-pending",
      };
      return routes[this.moduleId];
    },
    isActive: {
      get() {
        return this.G_IS_ACTIVE;
      },
      set(value) {
        this.$store.commit("AutoAlertLetterRound/M_OPEN_ALERT", value);
      },
    },

    dataLetterRound: {
      get() {
        return this.G_DATA_LETTER_ROUND;
      },
      set(value) {
        this.$store.commit("AutoAlertLetterRound/M_DATA_LETTER_ROUND", value);
      },
    },

    currentPath() {
      return this.$route.name;
    },
  },
  created() {
    const { client_name, module_name, created_by, bureau_name } =
      this.dataLetterRound;

    this.alertInfo.forEach((element, index) => {
      element.value = [client_name, module_name, created_by, bureau_name][
        index
      ];
    });
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "CorrespondenceLettersStore/CHANGE_REFRESH_TABLE",
      A_UPDATE_TABLE_IN_DEPARTAMENT:
        "SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE",
    }),
    goToLetter() {
      this.closeAlert();
      if (this.currentPath == this.routeName) {
        this.CHANGE_REFRESH_TABLE(true);
        this.A_UPDATE_TABLE_IN_DEPARTAMENT(true);
      } else {
        this.$router.push({
          name: this.routeName,
        });
      }
    },
    closeAlert() {
      const channel = socket.channel("round-letters-notifications", {});
      channel.trigger("client-close-all-round-letter-notification", {
        message: "close all",
        user_id: this.currentUser.user_id,
      });
      if (this.currentPath == this.routeName) {
        this.CHANGE_REFRESH_TABLE(true);
        this.A_UPDATE_TABLE_IN_DEPARTAMENT(true);
      }
      this.isActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-badge {
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
}
.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}
</style>
