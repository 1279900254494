import { amgApi } from '@/service/axios'

class SalesSellerService {
  async getSellersFromCeDigital(params) {
    const data = await amgApi.post('credit-experts-digital/get-users-from-sales-seller', params)
    return data
  }

  async countSellersPending(params) {
    const data = await amgApi.post('credit-experts-digital/count-sellers', params)
    return data
  }

  async updatePermissionSellerInSales(params) {
    const data = await amgApi.post('credit-experts-digital/update-permission-sales-seller', params)
    return data
  }

  async getSalesSellerTracking(params) {
    const data = await amgApi.post('credit-experts-digital/get-sales-seller-tracking', params)
    return data
  }

  async getSellersFromManagement(params) {
    const data = await amgApi.post('credit-experts-digital/get-sales-seller-users', params)
    return data
  }

  async updatePermissionSellerManagement(params) {
    const data = await amgApi.post('credit-experts-digital/update-permission-seller-management', params)
    return data
  }
  async getSellersFromApprovalProcess(params) {
    const data = await amgApi.post('credit-experts-digital/get-sales-seller-approval-process', params)
    return data
  }
  async getCounterApprovalProcess() {
    const data = await amgApi.post('credit-experts-digital/count-approval-process')
    return data
  }



}
export default new SalesSellerService()
