<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4025_360)">
      <path
        d="M0.58434 7.56037C0.245423 7.55491 0 7.31984 0 7.00278C0 6.67478 0.262953 6.43425 0.613557 6.44518C0.975847 6.45065 1.22127 6.68571 1.21543 7.01918C1.20958 7.33624 0.94663 7.56584 0.58434 7.56037Z"
        :fill="isDarkTheme ? darkColor : color"
      />
      <path
        d="M1.72971 9.45736C1.72971 9.77442 1.45507 10.0368 1.12784 10.0368C0.800608 10.0368 0.520125 9.76349 0.525969 9.45736C0.531812 9.15123 0.788922 8.90523 1.11615 8.89976C1.45507 8.88883 1.72971 9.13483 1.72971 9.45736Z"
        :fill="isDarkTheme ? darkColor : color"
      />
      <path
        d="M4.23646 4.07817C3.87417 4.1383 3.51773 4.19297 3.15544 4.2531C2.42501 4.3679 1.70043 4.47723 0.970006 4.58656C0.648619 4.63576 0.631089 4.61936 0.666149 4.31323C0.777173 3.29097 0.888198 2.26872 0.999223 1.24646C1.00507 1.18633 1.0226 1.12619 1.05181 1.0114C1.3732 1.30659 1.67121 1.56352 1.94585 1.84232C2.12115 2.01725 2.23802 2.02819 2.44254 1.85872C4.34165 0.251536 6.57382 -0.311526 9.06895 0.158603C10.933 0.508467 12.4348 1.44326 13.5625 2.89191C14.5501 4.1547 15.0117 5.57602 15 7.134C14.9766 9.97118 12.9139 12.6444 10.0448 13.5682C9.12154 13.8634 8.1866 14.0165 7.21075 14.0001C6.97702 13.9946 6.87184 13.9345 6.88937 13.7049C6.91274 13.4425 6.8134 13.0926 6.95949 12.945C7.09973 12.8029 7.47955 12.8685 7.76003 12.8575C10.3428 12.7701 12.6217 11.1246 13.4457 8.87239C14.3105 6.50534 13.4223 3.69004 11.0908 2.20312C9.37865 1.11526 7.52045 0.847397 5.52785 1.45419C4.68056 1.71112 3.93845 2.12112 3.2723 2.67325C3.09116 2.82631 3.07363 2.92471 3.26062 3.08324C3.60538 3.37297 3.93261 3.68457 4.26568 3.98523C4.25984 4.01257 4.24815 4.04537 4.23646 4.07817Z"
        :fill="isDarkTheme ? darkColor : color"
      />
      <path
        d="M6.2523 8.18909C6.35163 8.18909 6.44513 8.19455 6.54447 8.18909C6.73145 8.17815 6.83663 8.22735 6.91844 8.40775C7.05868 8.74121 7.45603 8.84508 7.8008 8.68108C8.20399 8.48975 8.23321 8.06882 7.8417 7.84469C7.40929 7.59869 6.9535 7.39096 6.51525 7.15589C5.2297 6.4671 5.42253 4.93645 6.57953 4.33512C6.8191 4.20939 6.93013 4.06726 6.88923 3.82126C6.84832 3.55886 6.98272 3.47686 7.23983 3.49873C7.33332 3.50966 7.43266 3.49873 7.532 3.49873C8.0579 3.49873 8.04622 3.49873 8.1105 3.99619C8.12218 4.09459 8.22736 4.22032 8.3267 4.26952C8.80002 4.50459 9.10387 4.87085 9.29086 5.32458C9.43695 5.68538 9.35514 5.80017 8.9461 5.80017C8.82923 5.80017 8.71237 5.79471 8.5955 5.80017C8.33839 5.81657 8.13387 5.80017 8.02869 5.51591C7.95272 5.29178 7.7073 5.22618 7.47357 5.23165C7.21646 5.23711 7.01194 5.36285 6.90091 5.59244C6.81326 5.78377 6.84248 5.95871 7.02947 6.0735C7.28073 6.22657 7.53784 6.37417 7.80664 6.50537C8.34423 6.7787 8.9052 7.0247 9.20321 7.57136C9.55381 8.22188 9.26749 9.14574 8.52538 9.57214C8.21568 9.74707 8.02869 9.91653 8.09297 10.2664C8.12218 10.4195 8.01116 10.4741 7.85923 10.4741C7.62549 10.4741 7.39176 10.4687 7.15802 10.4741C6.95935 10.4796 6.85417 10.4085 6.88923 10.2172C6.94182 9.91107 6.79573 9.74707 6.5094 9.59947C6.04193 9.36441 5.77314 8.96534 5.65627 8.47882C5.60368 8.24375 5.67964 8.12895 5.95428 8.16175C6.05946 8.20002 6.15296 8.18909 6.2523 8.18909Z"
        :fill="isDarkTheme ? darkColor : color"
      />
      <path
        d="M2.62964 12.1195C2.27319 12.1195 2.01024 11.879 2.00439 11.5455C2.00439 11.2339 2.26735 10.977 2.60042 10.9715C2.93934 10.9606 3.23735 11.2339 3.23735 11.551C3.23735 11.8571 2.95687 12.1195 2.62964 12.1195Z"
        :fill="isDarkTheme ? darkColor : color"
      />
      <path
        d="M4.89699 12.3819C5.24759 12.4038 5.49301 12.6607 5.4638 12.9778C5.43458 13.2894 5.13657 13.5244 4.80349 13.5026C4.48795 13.4807 4.225 13.2074 4.24253 12.9122C4.26006 12.606 4.56392 12.3655 4.89699 12.3819Z"
        :fill="isDarkTheme ? darkColor : color"
      />
    </g>
    <defs>
      <clipPath id="clip0_4025_360">
        <rect width="15" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
  
<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 50,
    },
    height: {
      type: Number,
      required: false,
      default: 50,
    },
    color: {
      type: String,
      required: false,
      default: "#27292E",
    },

    darkColor: {
      type: String,
      required: false,
      default: "#FFFFFF",
    },
  },

  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin == "dark";
    },
  },
};
</script>