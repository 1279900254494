import OtherPaymentsService from "@/views/commons/components/other-payments/service/other-payment.service"

export default {
  namespaced: true,
  state: {
    COUNTERS_OP: {
      zelle: 0,
      cash: 0,
      checks: 0,
    },
  },
  getters: {
    G_COUNTER_OP(state) {
      return state.COUNTERS_OP
    },
    G_SUM_COUNTER_OP(state) {
      return Object.values(state.COUNTERS_OP).reduce((a, b) => a + b, 0)
    },
  },
  mutations: {
    M_COUNTER_OP(state, payload) {
      state.COUNTERS_OP = payload
    },
  },
  actions: {
    async A_COUNTER_OP({ commit }, params) {
      const { data } = await OtherPaymentsService.getCountersOP({
        ...params,
      })
      commit("M_COUNTER_OP", data)
    },
  },
}
