<template>
  <b-modal
    v-model="modal.modalValidateDateAndHour"
    lazy
    centered
    header-bg-variant="primary"
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    title="Change Date And Hour"
    modal-class="modal-primary"
    scrollable
  >
    <b-container fluid>
      <program-client-header
        :client="validationData.client"
        :program="validationData.program"
        class="sticky-top mb-2"
      />
      <validation-observer ref="form">
        <b-row>
          <b-col>

            <div class="d-flex justify-content-between">
              <h4 class="font-weight-bold mb-1">
                Contact Schedule
              </h4>
              <div><b-button @click="changeToValidDate" size="sm" variant="success" style="float: right; " >Default Valid Date</b-button></div>

            </div>
            <b-row>
              <b-col lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="contactDate"
                  rules="required"
                >

                  <b-form-group
                    label-class="font-weight-bolder"
                    label-for="input-horizontal"
                    label="Day: "
                  >

                    <kendo-datepicker
                      id="contactDate"
                      v-model="validateData.contactDate"
                      v-mask="'##/##/####'"

                      :format="'MM/dd/yyyy'"
                      :class="{ 'border-danger': errors[0] }"
                      class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                      @change="onChangeDate"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="6">

                <validation-provider
                  v-slot="{ errors }"
                  name="contactHour"
                  rules="required"
                >

                  <b-form-group
                    id="contactHour"
                    label-class="font-weight-bolder"
                    label-for="input-horizontal"
                    label="To:"
                  >
                    <template #label>
                      <div class="d-flex justify-content-between">
                        <span>Hour:</span>  <tabler-icon
                          :id="'hours-target'"
                          class="text-warning cursor-pointer"
                          size="15"
                          icon="QuestionMarkIcon"
                        />

                        <b-tooltip
                          v-if="hoursToSee"
                          custom-class="ga"
                          :target="'hours-target'"
                          triggers="hover"

                          placement="top"
                        >
                          <div>
                            <h5 class="font-weight-bolder">
                              Office Hours
                            </h5>

                            <span

                              v-for="(item, i) in hoursToSee"
                              :key="i"
                            >
                              <span
                                class="font-weight-bolder text-justify"
                                style="max-width: 1px!important;"
                              >{{ item.day }} </span>
                              <span
                                class="text-justify"
                                style="max-width: 10px!important;"
                              > {{ ': From: ' + (item.from ? item.from :'00:00') + ' To: ' + (item.to ? item.to :'23:59') }}</span>
                              <br>
                            </span>
                          </div>
                        </b-tooltip>
                      </div>
                    </template>
                    <kendo-timepicker
                      v-model="validateData.contactHour"
                      :format="'HH:mm'"
                      minutes-step="30"
                      class="w-100 rounded bg-transparent k-picker-custom"
                      style="height: 2.73rem"
                      :class="{ 'border-danger': errors[0] }"
                      placeholder="Select hour"
                      @change="onChangeHour"
                    />

                  </b-form-group>

                </validation-provider></b-col>

            </b-row>

          </b-col>

        </b-row>
      </validation-observer>
    </b-container>

    <template #modal-footer>
      <b-row>
        <b-col>
          <b-button
            variant="primary"

            @click="updateDateAndHour"
          >
            Update
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>

import ProgramClientHeader from '@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue'
import OfficeHoursService
from '@/views/ce-digital/sub-modules/settings/views/office-hours/service/office-hours.service'
import moment from 'moment'
import NotesServices from '@/views/commons/components/first-notes/services/notes.service'

export default {
  components: { ProgramClientHeader },
  props: {
    modal: {
      type: Object,
      required: true,
    },
    validationData: {
      type: Object,
      required: true,
      default: () => ({
        client: {},
        program: {},
        contactDate: '',
        contactHour: '',
      }),
    },
  },
  data() {
    return {
      hoursToSee: null,
      dateInModal: '',
      noteValidation: [],
      fromHourSeconds: null,
      toHourSeconds: null,
      contactHourSecondsValidate48hours: null,
      contactHourSecondsValidate: null,
      validateHours: null,
      validateData: {
        client: {},
        program: {},
        contactDate: '',
        contactHour: '',
      },
    }
  },

  async mounted() {
    await this.officeHoursPerDay()
    this.validateData = { ...this.validationData }
    await this.validContactDate(this.validateData.contactDate)
    this.validateDateAndHour(this.validateData.contactHour)
  },
  methods: {
    generateHours(from, to) {
      const hours = []
      const fromHour = moment(from, 'HH:mm')
      const toHour = moment(to, 'HH:mm')
      let currentHour = fromHour
      while (currentHour.isBefore(toHour)) {
        hours.push(currentHour.format('HH:mm'))
        currentHour = currentHour.add(30, 'minutes')
      }
      return hours
    },
    async   changeToValidDate() {
      // ADD TWO DAYS TO A DATE

      const new_date = moment(moment(), 'MM/DD/yyyy').add(2, 'days').format('MM/DD/yyyy')
      const date = moment(new_date).format('dddd')
      switch (date) {
        case 'Sunday':
          this.validateHours = 1
          break
        case 'Monday':

          this.validateHours = 2
          break
        case 'Tuesday':

          this.validateHours = 3
          break
        case 'Wednesday':

          this.validateHours = 4
          break
        case 'Thursday':

          this.validateHours = 5
          break
        case 'Friday':

          this.validateHours = 6
          break
        case 'Saturday':

          this.validateHours = 7
          break
      }

      this.noteValidation = await NotesServices.validateDateAndHour({ days: this.validateHours })
      if (this.noteValidation.length > 0) {
        const { from, to } = this.noteValidation[0]
        const fromHour = moment(moment().add(10, 'minutes'), 'HH:mm:ss').format('HH:mm')
        const toHour = moment(to, 'HH:mm:ss').format('HH:mm')
        this.validateData.contactDate = new_date

        this.validateData.contactHour = this.generateHours(fromHour, toHour)[0]
      } else {
        const fromHour = moment(moment().add(10, 'minutes'), 'HH:mm:ss').format('HH:mm')
        const toHour = moment('23:59', 'HH:mm:ss').format('HH:mm')
        this.validateData.contactDate = new_date

        this.validateData.contactHour = this.generateHours(fromHour, toHour)[0]
      }

      await this.validContactDate(new_date)
      this.validateDateAndHour(this.validateData.contactHour)
    },

    async  updateDateAndHour() {
      const validate = await this.$refs.form.validate()

      if (validate) {
        if (this.contactHourSecondsValidate === false || this.contactHourSecondsValidate48hours === false) {
          if (this.contactHourSecondsValidate === false && this.contactHourSecondsValidate48hours === false) {
            this.showInfoSwalCaro(`Consider that contact time must be at least 48h and ${this.dateInModal}`, 'Warning')
            return
          }
          if (this.contactHourSecondsValidate48hours === false) {
            await this.showInfoSwalCaro('Contact time must be at least 48h', 'Warning')
            return
          } if (this.contactHourSecondsValidate === false) {
            await this.showInfoSwalCaro(`${this.dateInModal}`, 'Warning')
          }
        } else {
          const swal = await this.showConfirmSwal()
          if (swal.isConfirmed) {
            this.addPreloader()
            try {
              const params = {
                date: this.validateData.contactDate,
                hour: this.validateData.contactHour,
                sale_id: this.validateData.saleId,
              }

              const data = await NotesServices.updateDateAndHourRevision(params)
              if (data.status === 200) {
                this.$emit('revission')

                this.modal.modalValidateDateAndHour = false

                this.removePreloader()
              }
            } catch (error) {
              console.log(error)
              this.removePreloader()
              this.showErrorSwal(error)
            }
          }
        }
      }
    },

    async validContactDate(newValue) {
      const now = moment()
      const exp = moment(`${this.validateData.contactDate} ${this.validateData.contactHour}`)

      const days = exp.diff(now, 'hours')
      if (days >= 47) {
        this.contactHourSecondsValidate48hours = true
      } else {
        this.contactHourSecondsValidate48hours = false
      }
      const date = moment(newValue).format('dddd')
      switch (date) {
        case 'Sunday':
          this.dateInModal = 'Sunday'
          this.validateHours = 1
          break
        case 'Monday':
          this.dateInModal = 'Monday'
          this.validateHours = 2
          break
        case 'Tuesday':
          this.dateInModal = 'Tuesday'
          this.validateHours = 3
          break
        case 'Wednesday':
          this.dateInModal = 'Wednesday'
          this.validateHours = 4
          break
        case 'Thursday':
          this.dateInModal = 'Thursday'
          this.validateHours = 5
          break
        case 'Friday':
          this.dateInModal = 'Friday'
          this.validateHours = 6
          break
        case 'Saturday':
          this.dateInModal = 'Saturday'
          this.validateHours = 7
          break
      }

      this.noteValidation = await NotesServices.validateDateAndHour({ days: this.validateHours })
      if (this.noteValidation.length > 0) {
        const { from, to } = this.noteValidation[0]
        const fromHour = moment(from, 'HH:mm:ss').format('HH:mm')
        const toHour = moment(to, 'HH:mm:ss').format('HH:mm')

        this.dateInModal = `${this.dateInModal} contact hour is in the range of ${fromHour} to ${toHour}`
        this.fromHourSeconds = moment.duration(fromHour, 'seconds').asSeconds()
        this.toHourSeconds = moment.duration(toHour, 'seconds').asSeconds()
      } else {
        this.contactHourSecondsValidate = true
      }
    },

    validateDateAndHour(newValue) {
      const now = moment()
      const exp = moment(`${this.validateData.contactDate} ${this.validateData.contactHour}`)

      const days = exp.diff(now, 'hours')
      if (days <= 47) {
        this.contactHourSecondsValidate48hours = false
      } else {
        this.contactHourSecondsValidate48hours = true
      }
      if (this.noteValidation.length > 0) {
        const contactHour = moment(newValue, 'HH:mm').format('HH:mm')
        const contactHourSeconds = moment.duration(contactHour, 'seconds').asSeconds()

        if (contactHourSeconds >= this.fromHourSeconds && contactHourSeconds <= this.toHourSeconds) {
          this.contactHourSecondsValidate = true
        } else {
          this.contactHourSecondsValidate = false
        }
      } else {
        this.contactHourSecondsValidate = true
      }
    },
    onChangeDate() {
      this.validateData.contactHour = null
    },
    async onChangeHour() {
      await this.validContactDate(this.validateData.contactDate)
      this.validateDateAndHour(this.validateData.contactHour)
      if (this.validateData.contactHour) {
        if (this.contactHourSecondsValidate === false && this.contactHourSecondsValidate48hours === false) {
          this.showInfoSwalCaro(`Consider that contact time must be at least 48h and ${this.dateInModal}`, 'Info')
        } else if (this.contactHourSecondsValidate48hours === false) {
          this.showInfoSwalCaro('Contact time must be at least 48h', 'Info')
        } else if (this.contactHourSecondsValidate === false) {
          this.showInfoSwalCaro(`${this.dateInModal}`, 'Info')
        }
      }
    },
    async officeHoursPerDay() {
      try {
        const result = await OfficeHoursService.getOfficeHoursPerDay()
        if (result.status === 200) {
          this.OfficeHoursPerDay = result.data
          this.isBusy = true

          this.hoursToSee = result.data
          setTimeout(() => {
            this.isBusy = false
          }, 900)
        }
      } catch (error) {
        throw error
      }
    },
  },

}
</script>

<style scoped>

</style>
