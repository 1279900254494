/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { amgApi } from "@/service/axios";

class NotificationService {
  async getUserNotifications(body) {
    try {
      const data = await amgApi.post("/notification/get-notifications", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllUserNotifications(body) {
    try {
      const data = await amgApi.post(
        "/notification/get-all-notifications-by-id",
        body
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async readNotificationById(body) {
    try {
      const data = await amgApi.post(
        "/notification/read-notification-by-id",
        body
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async readAllNotificationsByIdUser(body) {
    try {
      const data = await amgApi.post(
        "/notification/read-all-notifications-by-id-user",
        body
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async updateNotification(body) {
    try {
      const data = await amgApi.post(
        "/notification/update-notifications",
        body
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async getCountLateAttendanceNotifications(body) {
    try {
      const data = await amgApi.post(
        "/notification/get-count-late-attendance-notifications",
        body
      );
      return data;
    } catch (err) {
      console.error("Error in getCountLateAttendanceNotifications", err);
    }
  }

  async getLateAttendanceNotifications(body) {
    try {
      const data = await amgApi.post(
        "/notification/get-late-attendance-notifications",
        body
      );
      return data;
    } catch (err) {
      console.error("Error in getLateAttendanceNotifications", err);
    }
  }

  async getProjectsPendingNotification(body) {
    try {
      const data = await amgApi.post(
        "/notification/get-projects-pending-notification",
        body
      );
      return data;
    } catch (err) {
      console.error("Error in getProjectsPendingNotification", err);
    }
  }

  async getProjectsPendingNotificationCeo(body) {
    try {
      const data = await amgApi.post(
        "/notification/get-projects-pending-notification-ceo",
        body
      );
      return data;
    } catch (err) {
      console.error("Error in getProjectsPendingNotificationCeo", err);
    }
  }

  async getProjectsPendingNotificationParagonChief(body) {
    try {
      const data = await amgApi.post(
        "/notification/get-projects-pending-notification-paragon-chief",
        body
      );
      return data;
    } catch (err) {
      console.error("Error in getProjectsPendingNotificationParagonChief", err);
    }
  }

  async getProjectMessagePending(body) {
    try {
      const data = await amgApi.post(
        "/notification/get-project-message-pending",
        body
      );
      return data;
    } catch (err) {
      console.error("Error in getProjectMessagePending", err);
    }
  }

  async getCountMeetings(body) {
    try {
      const data = await amgApi.post("/miro/meetings/get-count-meetings", body);
      return data;
    } catch (error) {
      console.log("Error in getCountMeetings");
    }
  }

  async getAllEmployeesWithoutSchedule(body) {
    try {
      const data = await amgApi.post(
        "/notification/get-all-employees-without-schedule",
        body
      );
      return data;
    } catch (err) {
      console.error("Error in get all employees without schedule", err);
    }
  }

  async updateAllNotifications(body) {
    try {
      const data = await amgApi.post(
        "/notification/update-all-notifications",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCountMeetingsRecruitment(body) {
    // eslint-disable-next-line no-useless-catch
    try {
      const data = await amgApi.post(
        "/recruitment/get-count-meeting-recruitment",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCountIndicatorsTickets(ctx) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-count-indicators-tickets",
        ctx
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getCountPendingCustomerTickets(ctx) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-count-pending-customer-tickets",
        ctx
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getPendingCustomerTickets(params) {
    try {
      const { data, status } = await amgApi.post(
        "/notification/get-customer-tickets-notification",
        params
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getCountPendingCustomerTicketsByModule(ctx) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-count-pending-customer-tickets-by-module",
        ctx
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getModulesWithTicketsInProgress(ctx) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-modules-with-tickets-in-progress",
        ctx
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getCountPendingCustomerTicketsByInOut(ctx) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-count-pending-customer-tickets-by-in-out",
        ctx
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getMeetingsPendingCustomerTickets(ctx) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-meetings-pending-customer-tickets",
        ctx
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async countTicketCustomerTaskCalendar(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/count-ticket-customer-task-calendar",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async getUsersAvailibilityCustomerTickets() {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-users-availability-customer-tickets"
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async isResponsibleCeo(ctx) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/is-responsible-ceo",
        ctx
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async isAccountResponsibleCeo(ctx) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/is-account-responsible-ceo",
        ctx
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getRbNotificationByModuleId(ctx) {
    try {
      const response = await amgApi.post(
        "/notification/get-rb-notification-by-module-id",
        ctx
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCounterNotifications(ctx) {
    try {
      const response = await amgApi.post(
        "/notification/get-counter-notifications",
        ctx
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async seenRbNotification(ctx) {
    try {
      const response = await amgApi.post(
        "/notification/seen-rb-notification",
        ctx
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new NotificationService();
