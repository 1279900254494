<template>
  <b-dropdown variant="outline-secondary">
    <template #button-content>
      <feather-icon icon="MailIcon" size="18" />
    </template>
    <b-dropdown-item-button @click="createEmail()">
      <feather-icon icon="PlusIcon" class="mr-1" />New Email
    </b-dropdown-item-button>
    <b-dropdown-item-button @click="showEmails()">
      <feather-icon icon="ListIcon" class="mr-1" />Show Emails
    </b-dropdown-item-button>
    <modal-send-email
      v-if="showSendEmail"
      :modal-send-email="true"
      :name-leads="[{ id: lead.id, name: lead.lead_name }]"
      @closeModal="showSendEmail = false"
      :is_for_leads="true"
    />
    <ModalHistoryLeadsEmail
      v-if="showHistoryEmail"
      :leadId="lead.id"
      @closeModal="showHistoryEmail = false"
    />
  </b-dropdown>
</template>

<script>
import ModalSendEmail from "@/views/commons/components/clients/modals/ModalSendEmail.vue";
import ModalHistoryLeadsEmail from "@/views/crm/views/Lead/lead-module/dashboard/simple-lead-email/ModalHistoryLeadsEmail.vue";

export default {
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showSendEmail: false,
      showHistoryEmail: false,
    };
  },
  components: {
    ModalSendEmail,
    ModalHistoryLeadsEmail,
  },
  methods: {
    createEmail() {
      this.showSendEmail = true;
    },
    async showEmails() {
      this.showHistoryEmail = true;
    },
  },
};
</script>

<style>
</style>