<template>
  <div style="margin-top: 5px">
    <b-badge pill variant="light-success" size="sm" class="cursor-pointer"
      v-b-tooltip.hover.bottom="'Open the analysis option details modal'" @click="showFinancialAnalysis(data)">
      <div class="d-flex">
        <feather-icon icon="CheckSquareIcon" style="margin-right: 4px" size="20" />
        <span style="padding-top: 1px">
          {{ data.ds_analysis_options }}
        </span>
      </div>
    </b-badge>
    <b-modal  title="Financial Analysis" @hidden="showSecondStep = false" hide-footer v-model="showSecondStep" size="xmd"
      v-if="showSecondStep" >
      <div>
        <view-financial-analysis :sales="eventIdAnalysis"></view-financial-analysis>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ViewFinancialAnalysis from "@/views/crm/views/sales-made/components/modals/services/debt-solution/components/ViewFinancialAnalysis";
export default {
  components: { ViewFinancialAnalysis },
  data() {
    return {
      showSecondStep: false,
      eventIdAnalysis: null,
    };
  },
  props: {
    data: {
      type: Object,
      required: false,
    },
  },
  methods: {
    showFinancialAnalysis(sales) {
      this.eventIdAnalysis = {
        lead_id: sales.lead_id,
        event_id: sales.event_id,
        client: sales.client,
        ds_analysis_options_id: sales.ds_analysis_options_id,
      };
      this.showSecondStep = true;
    },
  },
};
</script>
