<template>
  <div>
    <validation-observer
      ref="giveIndicationsForm"
      v-if="indications.length == 0"
    >
      <InputIndication
        :caseInfo="caseInfo"
        :readOnly="readOnly"
        @saveCommentary="saveCommentary"
      />
    </validation-observer>

    <b-row v-else class="mt-1">
      <b-col cols="12" v-if="caseInfo.is_lead == 1">
        <filter-slot
          :filter="[]"
          :filter-principal="filterPrincipal"
          :no-visible-principal-filter="true"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          :array-pagination="[5, 10, 15, 20, 50]"
          @reload="$refs['refNotesOverCI'].refresh()"
        >
          <b-table
            ref="refNotesOverCI"
            slot="table"
            class="blue-scrollbar"
            no-border-collapse
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="35vh"
            primary-key="id"
            responsive="sm"
            :items="getIndications"
            :busy.sync="isBusy"
            :per-page="paginate.perPage"
            :current-page="paginate.currentPage"
            small
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>

            <template #cell(text)="data">
              <change-sms
                :sms-data="data.item.text"
                :TextLength="150"
              ></change-sms>
            </template>
            <template #cell(first_name)="data">
              <strong>
                {{ data.item.first_name + " " + data.item.last_name }}</strong
              >
              <br />
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </template>
          </b-table>
        </filter-slot>
      </b-col>
      <b-col v-else cols="12">
        <filter-slot
          small
          responsive
          :filter="[]"
          :filter-principal="filterPrincipal"
          :no-visible-principal-filter="true"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="getIndications"
          @onChangeCurrentPage="getIndications"
          :array-pagination="[5, 10, 15, 20, 50]"
        >
          <template #table>
            <div class="notes-over-container">
              <div
                v-if="!isLoading && indications.length == 0"
                class="text-center my-2"
              >
                <strong>There are no notes to show</strong>
              </div>

              <div v-if="isLoading" class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>

              <div
                v-for="note in indications"
                v-else
                :key="note.id"
                class="notes-over"
              >
                <b-row>
                  <b-card
                    class="mx-3 my-1 w-100"
                    :class="note.date == today ? 'today' : 'not-today'"
                  >
                    <div class="d-flex justify-content-between mb-2">
                      <b-badge
                        variant="light-primary"
                        class="k-font-weight-bold"
                        v-if="note.date == today"
                        >{{ note.date | myDateText }}</b-badge
                      >
                      <span class="k-font-weight-bold" v-else>{{
                        note.date | myDateText
                      }}</span>
                      <span style="color: #8b4513">{{ note.type }}</span>
                    </div>

                    <div v-html="note.text" />

                    <div class="d-flex justify-content-between mt-2">
                      <div />
                      <div>
                        <span style="color: #baa345"
                          >By {{ note.first_name + " " + note.last_name }} ,
                          {{ note.created_at | myGlobalDay }}</span
                        >
                      </div>
                    </div>
                  </b-card>
                </b-row>
              </div>
            </div>
          </template>
        </filter-slot>
      </b-col>
    </b-row>
    <InputIndicationModal
      v-if="showModalIndication"
      :caseId="caseId"
      :readOnly="readOnly"
      @refresh="saveCommentary"
      @close="showModalIndication = false"
    />
  </div>
</template>

<script>
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service.js";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
import { quillEditor } from "vue-quill-editor";
import { mapGetters } from "vuex";
import InputIndication from "@/views/debt-solution/views/court-info/components/tabs/InputIndication.vue";
import InputIndicationModal from "@/views/debt-solution/views/court-info/components/modals/GiveIndicationsModal.vue";
import moment from "moment";

export default {
  props: {
    caseId: {
      type: Number,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    quillEditor,
    ChangeSms,
    InputIndication,
    InputIndicationModal,
  },
  data() {
    return {
      today: moment().format("YYYY-MM-DD"),
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "",
        model: "",
      },
      totalRows: 1,
      paginate: {
        perPage: 5,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isLoading: false,
      isBusy: false,
      caseInfo: {},
      commentary: "",
      editorOption: {
        modules: { toolbar: true },
      },
      indications: [],
      fields: [
        { key: "first_name", label: "Created By", sortable: false },
        { key: "text", label: "Indication", sortable: false },
      ],
      showModalIndication: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  async mounted() {
    await this.getCourtCase();
    this.getIndications();
  },
  methods: {
    async getCourtCase() {
      try {
        const response = await CourtInfoService.getCourtCase({
          case_id: this.caseId,
        });
        this.caseInfo = response[0];
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          this.getInternalErrors(error) || "Something went wrong"
        );
      }
    },

    async getIndications() {
      try {
        this.isLoading = true;
        const { data } = await CourtInfoService.getIndications({
          case_id: this.caseId,
          perPage: this.paginate.perPage,
          currentPage: this.paginate.currentPage,
        });
        this.indications = data.data;
        this.startPage = data.from ? data.from : 0;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
        return data.data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          this.getInternalErrors(error) || "Something went wrong"
        );
      } finally {
        this.isLoading = false;
      }
    },
    saveCommentary() {
      this.$emit("refresh");
    },
  },
};
</script>


<style lang="scss">
.quill-editor-tracking {
  .ql-container {
    height: 200px !important;
  }
}
.notes-over-container {
  height: 35vh;
  overflow-y: scroll;
  overflow-x: hidden;
  .notes-over {
    overflow-x: hidden !important;
  }
}

.not-today {
  border: 1px solid #f1f1f1 !important;
}
.today {
  border: 1px solid #0275d8 !important;
}
</style>