<template>
  <div class="d-flex justify-content-center align-items-center">
    <div>
      <hr />
      <div class="d-flex justify-content-center align-items-center">
        <b-alert
          style="padding: 0.71rem 1rem"
          class="m-0"
          show
          variant="warning"
        >
          <span>
            You can select the fields you want to display in the table, you must
            configure at least 5 visible fields.<feather-icon
              icon="InfoIcon"
              size="18"
              class="ml-1"
            />
          </span>
        </b-alert>
      </div>
      <hr />
      <b-input v-model="searchText" placeholder="Search columns" class="mb-2" />
      <div
        v-for="(item, index) in filteredColumns"
        :key="index"
        class="d-flex justify-content-between align-items-center"
      >
        <!-- <template v-if="item.label != 'Actions'"> -->
        <template v-if="!['Actions','Business Name'].includes(item.label)">
          <h5 class="text-uppercase">{{ item.label }}</h5>
          <b-form-checkbox
            v-model="item.visible"
            switch
            type="checkbox"
            class="mb-1"
          >
            <span class="slider"></span>
            <span class="on-off-label">Hide/Show</span>
          </b-form-checkbox>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import FilterColumnsServices from "@/views/crm/views/sales-made/components/filter-columns/services/filter-columns.services.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      filtersColumns: [],
      searchText: "",
      fieldsArray: [],
    };
  },
  props: {
    customFiltering: {
      type: Array,
    },
  },
  methods: {
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    // async getFields() {
    //   try {
    //     const { data } = await FilterColumnsServices.getSettingsFields({
    //       form_id: 1,
    //       user_id: this.currentUser.id,
    //     });
    //     this.fieldsArray = JSON.parse(data[0].fields);
    //     return this.fieldsArray;
    //   } catch (error) {
    //     this.showErrorSwal(error);
    //   } finally {
    //     this.removePreloader();
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    filteredColumns() {
      const search = this.searchText.toLowerCase().trim();
      if (!search) {
        return this.filtersColumns;
      } else {
        return this.filtersColumns.filter((item) =>
          item.label.toLowerCase().includes(search)
        );
      }
    },
  },
  watch: {},
  mounted() {
    // this.getFields();customFiltering object
    this.filtersColumns = this.deepClone(this.customFiltering);
    this.$emit("filter-updated", this.filtersColumns);
  },
};
</script>
<style>
.on-off-label {
  margin-left: 10px; /* Ajusta el espaciado a tu preferencia */
  font-size: 12px; /* Ajusta el tamaño de fuente a tu preferencia */
  color: #777; /* Ajusta el color a tu preferencia */
}
</style>
