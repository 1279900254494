export default [
  {
    path: 'settings',
    name: 'settings-logistic',
    component: () => import(/* */ '@/views/logistic/views/settings/Settings.vue'),
    redirect: { name: 'log-inventory-settings' },
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [{
        text: 'Settings',
      }],
    },
    children: [
      {
        path: 'inventory-settings',
        name: 'log-inventory-settings',
        component: () => import(/* webpackChunkName: "CrmAppointmentList" */ '@/views/logistic/views/settings/components/MaintenanceSettings.vue'),
        meta: {
          pageTitle: 'Settings',
          breadcrumb: [
            {
              text: 'Inventory',
              active: true,
            },
          ],
          permittedRoles: [1, 2, 3],
        },
      },
      {
        path: 'claims-settings',
        name: 'log-claims-settings',
        component: () => import('@/commons/employee-claims/components/settings/components/TypeRequest.vue'),
        meta: {
          pageTitle: 'Settings',
          breadcrumb: [
            {
              text: 'Claims',
              active: true,
            },
          ],
          permittedRoles: [1, 2, 3],
        },
      },
    ],
  },
];
