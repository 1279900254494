export default [
  {
    path: '/home',
    name: 'amg-menu',
    component: () => import(/* webpackChunkName: "AMGMain" */ '@/views/main/Menu.vue'),
    meta: {
      layout: 'horizontal',
      notPermittedRoles: [1],
    },
  },
  {
    path: '/dashboard',
    name: 'employee-dashboard',

    component: () => import(/* webpackChunkName: "AMGMain" */ '@/views/main/MainDashboard.vue'),
    meta: {
      layout: 'horizontal',
      notPermittedRoles: [1],
    },
  },
  {
    path: '/dashboard-ceo',
    name: 'ceo-dashboard',

    component: () => import(/* webpackChunkName: "AMGDashboardCeo" */ '@/views/ceo/DashboardCeo.vue'),
    meta: {
      layout: 'horizontal',
      notPermittedRoles: [1],
    },
  },
  {
    path: '/login2',
    name: 'login-old-soft',
    component: () => import(/* webpackChunkName: "AMGLoginFromOldSoft" */ '@/views/commons/LoginFromOldSoft'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
];
