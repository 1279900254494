import { requestWpTabs } from "@/views/commons/components/request-workplan/router/request-workplan.tabs.js";

export default [
  {
    path: "request-workplan",
    name: "request-workplan-analyst-department",
    redirect: { name: "sp-wp-pending" },
    meta: {
      pageTitle: "Request Workplan",
      breadcrumb: [
        {
          text: "Request Workplan",
          active: true,
        },
      ],
      routePending: "sp-wp-pending",
      routeCompleted: "sp-wp-completed",
      routeToRevision: "sp-wp-to-revision",
      routeDeleted: "sp-wp-deleted",
      module: 28,
    },
    component: () =>
      import(
        "@/views/commons/components/request-workplan/RequestWorkplanMain.vue"
      ),
    children: requestWpTabs("sp"),
  },
  {
    path: "leads/report/:idlead/:idfile/:accountId/:idBureau?/:idSection?/:section?",
    name: "report-lead-specialists-ad-cr",
    component: () =>
      import(
        /* webpackChunkName: "ncrProgramsSpecialist" */
        "@/views/commons/components/ncr/components/report/ReportLead.vue"
      ),
    props: (route) => ({
      idlead: route.params.idlead,
      idfile: route.params.idfile,
      accountId: route.params.accountId,
      idBureau: route.params.idBureau || false, // default bureau_id
      idSection: route.params.idSection || false, // default idSection
      section: route.params.section || false, // default idSection
    }),
    meta: {
      isClientsTab: true,
      module: 28,
      typeTable: 2,
      permittedRoles: [1, 2, 3, 8],
    },
    exact: false,
  },
  {
    path: "leads/step-deleted/:idlead/:idfile/:accountId/:requestId/:idBureau?/:section?",
    name: "report-step-deleted",
    component: () =>
      import(
        /* webpackChunkName: "DeleteItems" */
        "@/views/commons/components/ncr/components/delete/MainReport.vue"
      ),
    props: (route) => ({
      idlead: route.params.idlead,
      idfile: route.params.idfile,
      accountId: route.params.accountId,
      requestId: route.params.requestId,
      idBureau: route.params.idBureau || false, // default bureau_id
      section: route.params.section || false, // default idSection
    }),
    meta: {
      isClientsTab: true,
      module: 28,
      typeTable: 2,
      permittedRoles: [1, 2, 3, 8],
    },
    exact: true,
  },
];
