export default [
  {
    path: "zero-payment",
    name: "zero-payment-credit-experts",
    component: () => import(/* webpackChunkName: "CreditExpertsZeroPayment" */ "@/views/credit-experts/views/zero-payment/TemplateZeroPayment.vue"),
    meta: {
      pageTitle: "Zero Payment",
      breadcrumb: [
        {
          text: "ZERO PAYMENT",
          active: true,
        },
      ],
      permittedRoles: [1, 2, 11],
    },
  },
];
