export default [
  {
    path: "loans",
    name: "loans-rrhh",
    redirect: "/rrhh/loans/my-loans",
    component: () => import(/* webpackChunkName: "RrhhLoansMain" */ "@/views/commons/components/loans/LoansComponent.vue"),
    children: [
      {
        path: "general",
        name: "general-loans-rrhh",
        component: () => import(/* webpackChunkName: "RrhhLoansGeneral" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 3,
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "Loans",
              active: true,
            },
          ],
          route: "rrhh",
        },
      },
      {
        path: "my-loans",
        name: "my-loans-rrhh",
        component: () => import(/* webpackChunkName: "RrhhMyLoans" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 1,
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "My Loans",
              active: true,
            },
          ],
          route: "rrhh",
        },
      },
      {
        path: "loans-module",
        name: "loans-module-rrhh",
        component: () => import(/* webpackChunkName: "RrhhLoansModule" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 2,
          route: "rrhh",
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "Loans By Module",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      tab: 1,
      route: "rrhh",
    },
  },
];
