export default [
  {
    path: "chargeback",
    name: "administration-charge-back",
    component: () =>
      import(
        /* webpackChunkName: "ManaChargeBack" */ "@/views/commons/components/charge-back/ChargeBack.vue"
      ),
    redirect: { name: "administration-charge-back-in-process" },
    meta: {
      permittedRoles: [1, 2],
      pageTitle: "Charge Back",
      breadcrumb: [
        {
          text: "Charge Back",
        },
      ],
      routeInProcess: "administration-charge-back-in-process",
      routeApproved: "administration-charge-back-approved",
    },
    children: [
      {
        path: "",
        redirect: { name: "administration-charge-back-in-process" },
      },
      {
        path: "inprocess",
        component: () =>
          import(
            /* webpackChunkName: "ManaChargeBackGeneralInProcess" */ "@/views/commons/components/charge-back/charge-back-module/charge-back-general/ChargeBackGeneral.vue"
          ),
        name: "administration-charge-back-in-process",
        meta: {
          permittedRoles: [1, 2],
          status: 1,
          pageTitle: "Charge Back",
          breadcrumb: [
            {
              text: "In process",
              active: true,
            },
          ],
          routeInProcess: "administration-charge-back-in-process",
          routeApproved: "administration-charge-back-approved",
        },
      },
      {
        path: "approved",
        component: () =>
          import(
            /* webpackChunkName: "ManaChargeBackGeneralApproved" */ "@/views/commons/components/charge-back/charge-back-module/charge-back-general/ChargeBackGeneral.vue"
          ),
        name: "administration-charge-back-approved",
        meta: {
          permittedRoles: [1, 2],
          status: 2,
          pageTitle: "Charge Back",
          breadcrumb: [
            {
              text: "Approved",
              active: true,
            },
          ],
          routeInProcess: "administration-charge-back-in-process",
          routeApproved: "administration-charge-back-approved",
        },
      },
    ],
  },
];
