<template>
  <b-modal
    v-model="showModal"
    size="default"
    centered
    header-bg-variant="transparent"
    @hidden="closeModal"
  >
    <template #modal-title>
      <h5
        class="d-flex justify-content-start align-items-center w-100 text-uppercase font-weight-bolder text-primary"
        style="padding-top: 10px"
      >
        <feather-icon
          icon="ClipboardIcon"
          size="15"
          style="margin-right: 10px"
        />
        {{ taskId > 0 ? "update" : "Add" }} task to ticket
        {{ ticketCustomer.code ? `#${ticketCustomer.code}` : "" }}
      </h5>
    </template>
    <b-container fluid>
      <validation-observer ref="formAddTask">
        <b-row>
          <b-col cols="12">
            <validation-provider v-slot="{ errors }" rules="required|max:255">
              <b-form-group label="Title" class="font-weight-bolder">
                <b-form-input
                  v-model="title"
                  placeholder="Title"
                  class="font-weight-bolder"
                  :state="errors[0] ? false : null"
                />
                <small v-if="errors[0]" class="text-danger p-0">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider v-slot="{ errors }" rules="required|max: 3000">
              <b-form-group label="Description">
                <b-form-textarea
                  id="description"
                  v-model="description"
                  rows="3"
                  placeholder="Description"
                  :state="errors[0] ? false : null"
                />
                <small v-if="errors[0]" class="text-danger p-0">{{
                  errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-form-group label="Date">
                <kendo-datepicker
                  id="date"
                  v-model="date"
                  v-mask="'##/##/####'"
                  :first-day-of-week="1"
                  placeholder="MM/DD/YYYY"
                  :format="'MM/dd/yyyy'"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : ''"
                  class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" sm="6">
            <b-form-group label="Hour">
              <kendo-timepicker
                v-model="hour"
                v-mask="'##:##'"
                :format="'HH:mm'"
                :interval="30"
                class="w-100 rounded bg-transparent"
                placeholder="HH:mm"
                style="height: 2.73rem"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-container>
    <template #modal-footer>
      <b-button variant="secondary" class="mr-1" @click="closeModal">
        CANCEL
      </b-button>
      <b-button variant="primary" @click="saveTask">
        <feather-icon
          icon="ClipboardIcon"
          size="15"
          style="margin-right: 10px"
        />
        SAVE
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";

export default {
  props: {
    ticketCustomer: {
      type: Object,
      default: () => {},
      required: true,
    },
    taskId: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      title: null,
      description: null,
      date: null,
      hour: null,
      allDay: false,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  watch: {
    allDay() {
      this.hour = null;
    },
  },
  async created() {
    if (this.taskId > 0) {
      await this.getTask();
    }
  },
  mounted() {
    this.showModal = true;
  },
  methods: {
    ...mapActions({
      A_TASKS_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/A_TASKS_PENDING_CUSTOMER_TICKETS",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    async saveTask() {
      try {
        const isValid = await this.$refs.formAddTask.validate();
        if (!isValid) return;

        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        // return;
        const params = {
          title: this.title,
          description: this.description,
          maxDate: moment(this.date, "MM/DD/YYYY").format("YYYY-MM-DD"),
          hourTask: this.hour,
          createdBy: this.currentUser.user_id,
          ticketCustomerId: this.ticketCustomer.id,
          taskId: this.taskId,
          status: 1,
          allDay: this.allDay,
        };

        let status = null;
        let exist = 0;
        if (this.taskId > 0) {
          // update
          const { status: statusResponse, data } =
            await CustomerTicketsService.updateTicketCustomerTask(params);
          status = statusResponse;
          exist = data.duplicity;
        } else {
          // insert
          const { status: statusResponse, data } =
            await CustomerTicketsService.insertTicketCustomerTask(params);
          status = statusResponse;
          exist = data.duplicity;
        }

        this.removePreloader();
        if (status === 200 && exist === 1) {
          this.showWarningSwal("ALREADY EXIST", "This task already exist");
        } else if (status === 200) {
          this.showSuccessSwal();
          this.$emit("successTask");

          if (this.taskId === 0) {
            await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
              user_id: this.currentUser.user_id,
            });
          }
        }
      } catch (error) {
        this.showErrorSwal();
        this.removePreloader();
        console.log("error in saveTask:", error);
      }
    },
    async getTask() {
      try {
        const params = {
          taskId: this.taskId,
        };
        const { data, status } =
          await CustomerTicketsService.getTicketCustomerTaskId(params);

        if (status === 200) {
          console.log("data", data);
          this.title = data[0]?.title || null;
          this.description = data[0]?.description || null;
          this.date = moment(data[0].max_date).format("MM/DD/YYYY") || null;
          this.hour = data[0].hour_task;
          this.allDay = !!data[0].all_day;
          console.log("hour", this.hour);
        }
      } catch (error) {
        this.showErrorSwal();
        console.log("error in getTask:", error);
      }
    },
  },
};
</script>
