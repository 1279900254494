export default [
  {
    path: "dashboard",
    name: "dashboard-crm",
    component: () => import(/* webpackChunkName: "CrmDashboard" */ "@/views/crm/views/dashboard/Dashboard.vue"),
    meta: {
      isClientsTab: true,
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
    },
  },
];
