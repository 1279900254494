export default [
    {
        path: 'updates',
        name: 'specialistdigital-updates',
        component: () => import(/* webpackChunkName: "SpecialistDigitalUpdates" */ '@/views/specialist-digital/views/updates/components/UpdatesTab.vue'),
        redirect: { name: 'pending-updates-specialistdigital' },
        meta: {
            pageTitle: 'Updates',
            breadcrumb: [
                {
                    text: 'Updates',
                    active: true,
                }
            ]
        },
        children: [
            {
                path: 'pending',
                name: 'pending-updates-specialistdigital',
                component: () => import(/* webpackChunkName: "SpecialistDigitalUpdatesPending" */ '@/views/specialist-digital/views/updates/components/Table.vue'),
                meta: {
                    pageTitle: 'Updates',
                    typeTab: 'Pending',
                    tabId: 1,
                    breadcrumb: [
                        {
                            text: 'Pending',
                            active: true,
                        },
                    ],
                }
            },
            {
                path: 'completed',
                name: 'completed-updates-specialistdigital',
                component: () => import(/* webpackChunkName: "SpecialistDigitalUpdatesCompleted" */ '@/views/specialist-digital/views/updates/components/Table.vue'),
                meta: {
                    pageTitle: 'Updates',
                    typeTab: 'Completed',
                    tabId: 2,
                    breadcrumb: [
                        {
                            text: 'Completed',
                            active: true,
                        },
                    ],
                }
            },
            {
                path: 'deleted',
                name: 'deleted-updates-specialistdigital',
                component: () => import(/* webpackChunkName: "SpecialistDigitalUpdatesDeleted" */ '@/views/specialist-digital/views/updates/components/Table.vue'),
                meta: {
                    pageTitle: 'Updates',
                    typeTab: 'Deleted',
                    tabId: 3,
                    breadcrumb: [
                        {
                            text: 'Deletes',
                            active: true,
                        },
                    ],
                }
            }
        ]
    }
]