import { render, staticRenderFns } from "./UploadCiDocument.vue?vue&type=template&id=0d59b840"
import script from "./UploadCiDocument.vue?vue&type=script&lang=js"
export * from "./UploadCiDocument.vue?vue&type=script&lang=js"
import style0 from "./UploadCiDocument.vue?vue&type=style&index=0&id=0d59b840&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports