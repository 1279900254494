export default {
  path: "dashboard",
  name: "connection-dashboard",
  component: () =>
    import(
      /* webpackChunkName: "ConnectionDashboard" */ "@/views/ce-digital/sub-modules/connection/views/dashboard/Dashboard.vue"
    ),
  meta: {
    pageTitle: "Dashboard",
    parentModule: 21,
    module: 20,
    moduleId: 20,
    breadcrumb: [
      {
        text: "Dashboard",
        active: true,
      },
    ],
  },
};
