import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import AmgIcon from "./icons/AmgIcon.vue";
import TablerIcon from "./icons/TablerIcon.vue";
import FilterComponent from "./views/commons/FiltersComponent.vue";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import HeaderSlot from "@/@core/layouts/components/app-header-slot/AppBreadcrumbSlot.vue";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Tooltip from "@/views/commons/components/claims_v2/components/Stepers/Tooltip.vue";
import AnimatedNumber from "@/commons/AnimatedNumber.vue"

Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component(AmgIcon.name, AmgIcon);
Vue.component(TablerIcon.name, TablerIcon);
Vue.component("filters-component", FilterComponent);
Vue.component("filter-slot", FilterSlot);
Vue.component("header-slot", HeaderSlot);
Vue.component('vSelect', vSelect)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component("Tooltip", Tooltip);
Vue.component("AnimatedNumber", AnimatedNumber);


