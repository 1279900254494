export default [
  {
    path: "salary-record",
    name: "salary-record",
    component: () =>
      import(
        /* webpackChunkName: "SallaryRecord" */ "@/views/management/views/salary-record/SalaryRecord.vue"
      ),
    redirect: { name: "pending-salary" },
    meta: {
      pageTitle: "Salary Record",
      breadcrumb: [
        {
          text: "Salary Record",
        },
      ],
      employeeRoute: "information-employee-management",
    },
    children: [
      {
        path: "pending-salary",
        component: () =>
          import(
            /* webpackChunkName: "RequestSalary" */ "@/views/management/views/salary-record/content/SalaryGrid.vue"
          ),
        name: "pending-salary",
        meta: {
          pageTitle: "Pending Salary",
          breadcrumb: [
            {
              text: "Pending Salary",
              active: true,
            },
          ],
        },
      },
      {
        path: "completed-salary",
        component: () =>
          import(
            /* webpackChunkName: "CompletedSalary" */ "@/views/management/views/salary-record/content/SalaryGrid.vue"
          ),
        name: "completed-salary",
        meta: {
          pageTitle: "Completed Salary",
          breadcrumb: [
            {
              text: "Completed Salary",
              active: true,
            },
          ],
          completed: true,
        },
      },
    ],
  },
]
