<template>
  <b-modal
    v-model="onControl"
    :title="title"
    title-tag="h3"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    no-close-on-backdrop
    :hide-footer="editing === 3"
    @hidden="close"
  >
    <validation-observer ref="form">
      <validation-provider v-slot="{ errors }" name="title" rules="required">
        <b-form-group id="fieldset-1" label="Title" label-for="input-1">
          <b-form-input v-model="note.title" :disabled="editing === 3" />
          <span v-if="errors[0]" class="text-danger">
            Title {{ errors[0] }}</span
          >
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="body" rules="required">
        <b-form-group id="fieldset-2" label="Body" label-for="input-2">
          <b-form-textarea v-model="note.body" :disabled="editing === 3" />
          <span v-if="errors[0]" class="text-danger">
            Body {{ errors[0] }}</span
          >
        </b-form-group>
      </validation-provider>
    </validation-observer>
    <template #modal-footer>
      <b-button variant="primary" @click="saveQuickNote">
        <b-spinner v-if="spinnerOn" small /> Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    editing: null,
    dataQuick: null,
  },
  data() {
    return {
      onControl: false,
      title: "",
      note: {
        title: "",
        body: "",
        id: "",
      },
      spinnerOn: false,
    };
  },
  async created() {
    this.onControl = true;
    if (this.editing === 1) {
      this.title = "NEW QUICK NOTE";
    } else if (this.editing === 2) {
      this.title = "EDIT QUICK NOTE";
      this.note = { ...this.dataQuick };
      this.note.body = this.note.body.replaceAll("<br />", "\n");
    } else if (this.editing === 3) {
      this.title = "SHOW QUICK NOTE";
      this.note = { ...this.dataQuick };
      this.note.body = this.note.body.replaceAll("<br />", "\n");
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async saveQuickNote() {
      try {
        const res = await this.$refs.form.validate();
        if (res) {
          this.spinnerOn = true;
          const data = await ClientsOptionsServices.saveQuickNote({
            id_note: this.note.id,
            ntitle: this.note.title,
            nbody: this.note.body,
            p_user_id: this.currentUser.user_id,
            p_module_id: this.moduleId,
            p_type: false,
          });
          if (data.status === 200) {
            this.$emit("reload");
            this.showSuccessSwal();
            this.spinnerOn = true;
            this.close();
          }
        }
      } catch (e) {
        this.spinnerOn = true;
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped>
</style>
