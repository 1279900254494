<template>
  <b-modal
    ref="offer-phone"
    title="Add new phone"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    no-close-on-backdrop
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-row>
        <b-col>
          <b-form-group label="Phone:">
            <validation-provider v-slot="{ errors }" rules="required|mobile">
              <b-form-input
                v-model="newPhone"
                v-mask="'(###) ###-####'"
                :state="errors.length > 0 ? false : null"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Phone {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <button-save @click="savePhone()" />
    </template>
  </b-modal>
</template>

<script>
import PriorityOffersService from "@/views/debt-solution/views/priority-offers/services/priorityOffersService";
import buttonSave from "@/views/commons/utilities/ButtonSave.vue";
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    buttonSave,
    buttonCancel,
  },
  props: {
    creditors_id: null,
  },
  data() {
    return {
      newPhone: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("offer-phone");
  },
  methods: {
    close() {
      this.$emit("close");
    },

    async savePhone() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const response = await this.showConfirmSwal(`Are you sure the phone number <strong>${this.newPhone}</strong> is correct?`);
          if (response.isConfirmed) {
            this.addPreloader();
            const extPhone = this.newPhone;
            const data = await PriorityOffersService.setNewPhone({
              phone: this.newPhone,
              id_creditor: this.creditors_id,
            });
            if (data.status === 200) {
              this.showToast(
                "success",
                "top-right",
                "Success",
                "CheckIcon",
                "Successfully process"
              )
              this.$emit("closing", extPhone);
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style scoped></style>
