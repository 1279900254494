<template>
  <div>
    <b-modal
      ref="tracking"
      modal
      title="TRACKING"
      size="lg"
      modal-class="modal-primary "
      hide-footer
      title-tag="h3"
      @hidden="hiddeTracking"
    >
      <b-row cols="2">
        <b-col>
          <b-form-group class="my-2">
            <b-input-group :prepend="$route.meta.section.toUpperCase()">
              <div class="form-control h-auto bg-transparent border-secondary">
                {{ clientData.lead_name }}
              </div>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group class="mt-2">
            <b-input-group
              :prepend="clientData.type == 2 ? 'ACCOUNT' : 'ORIGIN MODULE'"
            >
              <div class="form-control h-auto bg-transparent border-secondary">
                {{
                  clientData.type == 2 ? clientData.account : clientData.module
                }}
              </div>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        ref="refTracking"
        small
        :items="myProvider"
        :fields="fields"
        primary-key="id"
        responsive="sm"
        show-empty
        sticky-header="70vh"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <!-- Column: Created By -->
        <template #cell(created_at)="data">
          <div>
            <small>{{ data.item.created_at | myGlobalWithHour }}</small>
          </div>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <div>
            <b-badge pill :variant="statusType(data.item.status)" style="padding: 6px; width: 100px;">
              {{ data.item.status }}</b-badge
            >
          </div>
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import clientsService from "@/views/ce-digital/sub-modules/sales/views/clients/service/clients.service";
export default {
  props: {
    clientData: {
      type: Object,
    },
  },
  mounted() {
    this.toggleModal("tracking");
  },
  computed: {

  },
  data() {
    return {
      fields: [
        { label: "CATCHER", key: "catcher_by" },
        { label: "SELLER", key: "seller_by" },
        { key: "status",    thClass: "text-center",
          tdClass: "text-center",},
        {
          label: "Created at",
          key: "created_at",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Observation",
          key: "obs",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },

  methods: {
    statusType(status) {
      switch (status) {
        case "TRACING":
          return "warning";
          break;
        case "RECYCLED":
          return "danger";

          break;
        case "SUCCESS":
          return "success";
          break;
      }
    },
    hiddeTracking() {
      this.$emit("hideModal");
    },

    async myProvider() {
      try {
        const { data } = await clientsService.trackingLeadsAndClients({
          derivation_id: this.clientData.derivation_id,
          type: this.clientData.type,
        });
        return data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with tracking!"
        );
        throw error;
      }
    },
  },
};
</script>
