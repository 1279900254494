export default {
	path: "report",
	name: "report-bussiness",
	component: () => import(/* webpackChunkName: "BusinessReports" */ "./index.vue"),
	meta: {
		pageTitle: "Report",
		breadcrumb: [
			{
				text: "Report",
				active: true,
			},
		],
	},
}
