export default [
  {
    path: "settings",
    name: "paragon-settings",
    component: () =>
      import(
        /* webpackChunkName: "Paragon" */ "@/views/paragon/views/settings/Settings.vue"
      ),
    redirect: { name: "paragon-settings-owners" },
    meta: {
      permittedRoles: [1, 2, 17, 11],
      pageTitle: "Settings Paragon",
      breadcrumb: [
        {
          text: "Settings Paragon",
        },
      ],
      routeOwners: "paragon-settings-owners",
      module: 12,
    },
    children: [
      {
        name: "paragon-settings-owners",
        path: "owners-ticket",
        component: () =>
          import(
            /* webpackChunkName: "ParagonGeneralOwners" */ "@/views/paragon/views/settings/OwnersTable.vue"
          ),
        meta: {
          permittedRoles: [1, 2, 17, 11],
          pageTitle: "Owner Ticket",
          breadcrumb: [
            {
              text: "Owner Ticket",
            },
          ],
          module: 12,
        },
      },
      {
        name: "paragon-settings-sections",
        path: "sections",
        component: () =>
          import(
            /* webpackChunkName: "ParagonGeneralSections" */ "@/views/paragon/views/settings/SectionsTable"
          ),
        meta: {
          permittedRoles: [1, 2, 17, 11],
          pageTitle: "Sections",
          breadcrumb: [
            {
              text: "Sections",
            },
          ],
          module: 12,
        },
      },
      {
        name: "paragon-settings-motives",
        path: "motives",
        component: () =>
          import(
            /* webpackChunkName: "ParagonGeneralMotives" */ "@/views/paragon/views/settings/MotivesTable"
          ),
        meta: {
          permittedRoles: [1, 2, 17, 11],
          pageTitle: "Motives & Options",
          breadcrumb: [
            {
              text: "Motives & Options",
            },
          ],
          module: 12,
        },
      },
      {
        name: "paragon-assign-teams",
        path: "assign-teams",
        component: () =>
          import(
            /* webpackChunkName: "ParagonAssignTeams" */ "@/views/paragon/views/settings/assign-teams/AssignTeams"
          ),
        meta: {
          permittedRoles: [1, 2, 17, 11],
          pageTitle: "Assign Teams",
          breadcrumb: [
            {
              text: "Assign Teams",
            },
          ],
        },
      },
    ],
  },
];
