import Pusher from "pusher-js"
import {
  RequestNotificationSocket,
  AssignedToNotificationSocket,
} from "@/views/logistic/socket/request.socket.js"
import ChangeStatusRequestNotificationSocket from "@/views/commons/components/request/socket/changeStatusRequest.socket.js"

const subscribeLogistic = (socket) => {
  try {
    socket.subscribe("channel-logistics")
    RequestNotificationSocket()
    AssignedToNotificationSocket()
    ChangeStatusRequestNotificationSocket()
  } catch (error) {
    console.error(error, "error")
  }
}

export default subscribeLogistic

// this is a test
