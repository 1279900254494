export default {
  path: "dashboard-super",
  name: "ce-dashboard-super",
  component: () =>
    import(
      /* webpackChunkName: "CEDCustomerServiceDashboardSuper" */ "@/views/ce-digital/sub-modules/customer-service/views/dashboard-super/index.vue"
    ),
  meta: {
    childModule: 6,
    programParent: 3,
    program: 22,
    pageTitle: "Dashboard",
    breadcrumb: [
      {
        text: "Dashboard",
      },
    ],
  },
};
