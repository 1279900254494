var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal",attrs:{"header-bg-variant":_vm.changeBg(_vm.newStatus),"no-close-on-backdrop":"","centered":"","size":"sm"},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h5',{staticStyle:{"font-family":"Montserrat","font-weight":"bolder","color":"#ffffff"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"size":"sm","variant":_vm.newStatus === 4 ? 'danger' : 'primary'},on:{"click":function($event){return _vm.closeTicket()}}},[_vm._v(" FINISH ")])]},proxy:true}])},[_c('validation-observer',{ref:"observer"},[(
          _vm.newStatus == null && _vm.statusFidelization == null && !_vm.isTypeExpense
        )?_c('b-row',{staticClass:"my-1"},[_c('b-col',[_c('b-button-group',[_c('b-button',{attrs:{"size":"md","variant":_vm.status === 6 ? 'primary' : 'outline-primary'},on:{"click":function($event){return _vm.selectOption(6)}}},[_c('strong',[_vm._v("SOLVED AND FIDELIZED")])]),_c('b-button',{attrs:{"size":"md","variant":_vm.status === 5 ? 'danger' : 'outline-danger'},on:{"click":function($event){return _vm.selectOption(5)}}},[_c('strong',[_vm._v("SOLVED NOT FIDELIZED")])])],1)],1)],1):_vm._e(),(_vm.status == 6)?_c('b-row',{staticClass:"mb-1"},[_c('b-col',[_c('validation-provider',{attrs:{"name":"Program","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Program","label-for":"program","state":errors[0] ? false : null}},[_c('v-select',{ref:"programSelector",class:{
                  'border-danger': errors[0],
                },attrs:{"input-id":"program","rules":"'required'","label":"label","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.G_PROGRAMS,"multiple":true,"close-on-select":false,"placeholder":"Select Program"},on:{"input":_vm.handleSelect},model:{value:(_vm.data.program),callback:function ($$v) {_vm.$set(_vm.data, "program", $$v)},expression:"data.program"}}),(errors[0])?_c('span',{staticClass:"text-danger"},[_vm._v(" Program "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}],null,false,779103272)})],1)],1):_vm._e(),_c('b-row',{staticClass:"my-1"},[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required|max:500","name":"osbservsation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Observation"}},[_c('b-form-textarea',{attrs:{"id":"textarea-rows","placeholder":"Write observation","rows":"10","no-resize":"","state":errors[0] ? false : null},model:{value:(_vm.observations),callback:function ($$v) {_vm.observations=$$v},expression:"observations"}})],1),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }