const chargeBackTrackingFields = [
    {
      key: "auth_code",
      label: "Authcode",
      visible: true,
    },
    {
      key: "status",
      label: "Status",
      visible: true,
    },
    {
      key: "departament",
      label: "DEPARTMENT",
      visible: true,
    },
    {
      key: "createdBy",
      label: "Created by",
      visible: true,
    },
  ];
  
  const disputeTrackingFields = [
    {
      key: "filename",
      label: "filename",
      visible: true,
    },
    {
      key: "status",
      label: "Status",
      visible: true,
    },
    {
      key: "observation",
      label: "observation",
      visible: true,
    },
    {
      key: "createdBy",
      label: "Created by",
      visible: true,
    },
  ];
  
  
  export { chargeBackTrackingFields, disputeTrackingFields };