import { amgApi } from "@/service/axios";

class LettersRoundsService {

  constructor() {
    this.route = "rounds-letters";
  }

  async search(params) {
    try {
      const { data } = await amgApi.post(
        `${this.route}/search-rounds-letters`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async joinPdf(params) {
    const { data } = await amgApi.post(this.route + "/join-pdf", params);
    return data;
  }

  async generateCourt(params) {
    const { data } = await amgApi.post(this.route + "/generate-court", params);
    return data;
  }

  async getTrackingsLetters(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/get-trackings-letters",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getTrackingsLetters");
    }
  }

  async addUrlTemporaryPdfNcr(params) {
    try {
      const data = await amgApi.post(
        `request-ncr/add-url-temporary-pdf-ncr`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getMergedPdfFiles(params) {
    const { data } = await amgApi.post(this.route + '/get-merged-pdf-files', params);
    return data;
  }

  async getCounterPendingRoundLetters() {
    return await amgApi.post(`${this.route}/count-pending-round-letter`);
  }

  async getCounterReturnedLettersDepartment(params) {
    return await amgApi.post(`${this.route}/count-returned-letters-department`, params)
  }
}
export default new LettersRoundsService();
