<template>
  <div>
    <b-nav
      card-header
      pills
      class="mb-0"
      v-if="$route.matched[0].meta.general || tabConversation"
      :class="tabConversation ? 'pl-1' : ''"
    >
      <b-nav-item
        @click="changeConversation(true)"
        exact
        :active="conversation"
        exact-active-class="active"
        :class="!tabConversation ? 'px-3' : 'mt-1'"
        :link-classes="['sub-tab-nav', bgTabsNavs]"
        >CONVERSATION
      </b-nav-item>
      <b-nav-item
        @click="changeConversation(false)"
        exact
        exact-active-class="active"
        :active="!conversation"
        :class="!tabConversation ? 'px-3' : 'mt-1'"
        :link-classes="['sub-tab-nav', bgTabsNavs]"
        >NO CONVERSATION
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="mb-0 border-top-primary border-2"
      :key="conversation"
    >
      <template #header v-if="withHeader">
        <b-card-title class="card-title-address">
          <div>Call Log</div>
        </b-card-title>
      </template>
      <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @onChangeCurrentPage="getClientLog"
        @reset-all-filters="resetAllFilters"
        @reload="getClientLog()"
        :double-paginate="doublePaginate"
      >
        <b-table
          id="rcTableee"
          slot="table"
          ref="rcTableee"
          :items="listCallRecording"
          :fields="fields"
          table-class="text-nowrap"
          class="custom-table"
          responsive="sm"
          no-border-collapse
          :sticky-header="maxHeight"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(status)="data">
            <div
              class="d-flex align-items-center custom-space w-100"
              :class="
                isDarkSkin
                  ? 'bg-dark'
                  : 'bg-white border-top-light border-bottom-light border-left-light'
              "
            >
              <div class="text-left d-flex flex-column">
                <!-- <div>{{ data.item.status }}</div> -->
                <!--<div v-if="minFields">
                  {{ data.item.start_time | myGlobalWithHour }}
                </div> -->
                <span>{{ data.item.start_time | myGlobal }}</span>
                <b class="text-center text-secondary">{{
                  data.item.start_time | myTime
                }}</b>
              </div>
            </div>
          </template>

          <template #cell(from)="data">
            <div
              class="d-flex align-items-center custom-space w-100"
              :class="
                isDarkSkin
                  ? 'bg-dark'
                  : 'bg-white border-top-light border-bottom-light'
              "
            >
              <div class="d-flex">
                <b-avatar class="mr-1" variant="light-success">
                  <!-- <feather-icon icon="PhoneOutgoingIcon" size="16"></feather-icon> -->
                  {{ data.item.from_name | initialLetters }}
                </b-avatar>
                <div>
                  <div>
                    {{ data.item.from_name }}
                  </div>
                  <b-badge variant="transparent" class="text-success px-0">
                    <feather-icon
                      icon="ArrowUpRightIcon"
                      size="20"
                    ></feather-icon>
                    {{ data.item.from_number }}
                  </b-badge>
                </div>
              </div>
            </div>
          </template>

          <template #cell(to)="data">
            <div
              class="d-flex align-items-center custom-space w-100"
              :class="
                isDarkSkin
                  ? 'bg-dark'
                  : 'bg-white border-top-light border-bottom-light'
              "
            >
              <div class="d-flex">
                <b-avatar
                  class="mr-1"
                  :variant="getCallStatus(data.item.status)[0]"
                  v-b-tooltip.hover
                  :title="data.item.status"
                >
                  <!-- <feather-icon
                    :icon="getCallStatus(data.item.status)[1]"
                    size="16"
                  ></feather-icon> -->
                  {{ data.item.to_name | initialLetters }}
                </b-avatar>
                <div>
                  <div>
                    {{ data.item.to_name }}
                  </div>
                  <div>
                    <b-badge
                      variant="transparent"
                      :class="getCallStatus(data.item.status, false)[0]"
                    >
                      <feather-icon
                        :icon="getCallStatus(data.item.status)[1]"
                        size="16"
                      ></feather-icon>
                      {{ data.item.to_number }}
                    </b-badge>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template #cell(start_time)="data">
            <!-- <div class="d-flex justify-content-center align-items-center"> -->
            <div
              class="d-flex flex-column align-items-center custom-space w-100"
              :class="
                isDarkSkin
                  ? 'bg-dark'
                  : 'bg-white border-top-light border-bottom-light'
              "
            >
              <span class="mt-1">{{ data.item.start_time | myGlobal }}</span>
              <b>{{ data.item.start_time | myTime }}</b>
            </div>
            <!-- </div> -->
          </template>

          <template #cell(record_url)="data">
            <div
              class="d-flex align-items-center custom-space w-100"
              :class="
                isDarkSkin
                  ? 'bg-dark'
                  : 'bg-white border-top-light border-bottom-light'
              "
            >
              <audio-player
                v-if="data.item.record_url"
                class="rounded-lg px-1 custom-audio border-light"
                :src="data.item.record_url"
                :index="data.item.call_id"
                :chat="true"
              />
              <span v-else>-</span>
            </div>
          </template>
          <template #cell(actions)="data">
            <div
              class="w-100 d-flex justify-content-around text-center custom-space"
              :class="
                isDarkSkin
                  ? 'bg-dark'
                  : 'bg-white border-top-light border-bottom-light border-right-light'
              "
            >
              <feather-icon
                icon="DownloadIcon"
                size="16"
                class="text-primary hover-me cursor-pointer mx-1"
                @click="downloadAudio(data.item)"
                v-b-tooltip.hover.v-primary="'DOWNLOAD CALL'"
              ></feather-icon>
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
  </div>
</template>

<script>
import Filters from "@/views/commons/components/ring-central/call-log/data/FiltersClient.js";
import Fields from "@/views/commons/components/ring-central/call-log/data/FieldsClient.js";
import CallLogService from "@/views/commons/components/ring-central/call-log/service/call-log.service.js";
import { mapGetters } from "vuex";
import WaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/WaveSurferPlayer.vue";
import AudioPlayer from "@/views/commons/components/customer-tickets/chat-components/AudioPlayer.vue";

export default {
  name: "ClientCallLog",
  components: { WaveSurferPlayer, AudioPlayer },
  props: {
    client_account: {
      type: String || Number,
      required: false,
      default: null,
    },
    leadId: {
      type: [String, Number],
    },
    maxHeight: {
      type: String,
      default: "70vh",
      required: false,
    },
    doublePaginate: {
      type: Boolean,
      required: false,
      default: true,
    },
    withHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    minFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    tabConversation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Number...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      filter: Filters,
      startPage: null,
      toPage: null,
      items: [],
      sortBy: "1",
      sortDesc: true,
      listCallRecording: [],
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        width: 180,
        backend: "MediaElement",
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      conversation: true,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    typeConversation() {
      if (this.$route.matched[3]) {
        const isConversation = this.$route.matched[3]
          ? this.$route.matched[3].meta.isConversation
          : null;
        return isConversation;
      } else {
        return this.conversation;
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    fields() {
      let newFields = [...Fields];

      // if (this.minFields) {
      //   newFields.splice(3, 1);
      // }

      return newFields;
    },
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  methods: {
    changeConversation(value) {
      this.conversation = value;
      this.getClientLog();
    },
    isResponsible() {
      this.$store.dispatch("NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO", {
        account_id: this.$route.params.idClient,
      });
      this.$store.dispatch("NotificationStore/A_IS_RESPONSIBLE_CEO", {
        lead_id: this.$route.params.id ? this.$route.params.id : this.leadId,
      });
    },
    resetAllFilters() {
      this.filter.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.getClientLog();
    },
    resetSearch() {
      this.searchInput = "";
      this.getClientLog();
    },
    async getClientLog() {
      try {
        this.addPreloader();
        const params = {
          module_id: this.moduleId || this.isTicketCustomer(),
          lead_id: this.$route.params.id ? this.$route.params.id : this.leadId,
          client_account: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.client_account,
          search_txt: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          tab: this.statusRouter,
          date_from: this.filter[0].model,
          date_to: this.filter[1].model,
          status: this.filter[2].model,
          hidden: this.filter[3].model,
          is_conversation: this.typeConversation,
        };
        const { data } = await CallLogService.getLeadCallLog(params);
        this.startPage = data.from ? data.from : 0;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
        this.listCallRecording = data.data;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      } finally {
        this.removePreloader();
      }
    },
    getCallStatus(status, isBg = true) {
      if (status === "Accepted" || status === "Call connected") {
        return [isBg ? "light-primary" : "text-primary", "ArrowDownLeftIcon"];
      }

      return [isBg ? "light-danger" : "text-danger", "XIcon"];
    },

    async hiddenCall(id) {
      const result = await this.showQuestionSwal(
        "Do you want to perform this action?",
        "This will hide the call for all users."
      );
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          const params = {
            call_id: id,
          };
          const { data } = await CallLogService.hiddenCall(params);
          this.getClientLog();
        } catch (e) {
          this.showErrorSwal(e);
        } finally {
          this.removePreloader();
        }
      }
    },

    isTicketCustomer() {
      return 1;
    },
    async downloadAudio({ record_url, call_id, start_time }) {
      try {
        const downloadBlob = (blob, fileName) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        };

        const response = await fetch(record_url);
        const blob = await response.blob();
        if (response.status === 404) {
          return this.showWarningSwal(
            "File no found",
            "try again or contact support"
          );
        }

        downloadBlob(blob, `call_${call_id}_${start_time}.mp3`);
      } catch (error) {
        this.showErrorSwal();
        console.error("Error al descargar el archivo de audio:", error);
      }
    },
  },
  created() {
    this.isResponsible();
  },
  filters: {
    initialLetters(value) {
      if (!value) return;
      const sanitizedValue = value.replace(/[-_.]/g, " ").split(" ");
      const newValue = sanitizedValue
        .map((item) => {
          return item.charAt();
        })
        .slice(0, 3)
        .join("");
      return newValue.toUpperCase();
    },
  },
};
</script>

<style scoped>
.mh-5 {
  min-height: 500px;
}
.custom-table
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th {
  background-color: #0090e7 !important;
  color: white;
  padding: 12px 10px !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child {
  border-top-right-radius: 10px !important;
}
.custom-table >>> tbody tr td {
  border-style: none !important;
}
.custom-table >>> .table tr td:first-child,
.custom-table-dark >>> .table tr td:first-child {
  border-radius: 10px 0px 0px 10px !important;
}
.custom-table >>> .table tr td:last-child,
.custom-table-dark >>> .table tr td:last-child {
  border-radius: 0px 10px 10px 0px !important;
}
.custom-table >>> .table tr td .custom-space,
.custom-table-dark >>> .table tr td .custom-space {
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  padding: 20px 5px !important;
}
.custom-table >>> .table tr td:first-child .custom-space,
.custom-table-dark >>> .table tr td:first-child .custom-space {
  border-radius: 10px 0px 0px 10px !important;
  padding-left: 20px !important;
}
.custom-table >>> .table tr td:last-child .custom-space,
.custom-table-dark >>> .table tr td:last-child .custom-space {
  border-radius: 0px 10px 10px 0px !important;
}
.b-table > tbody > tr {
  border: none !important;
}
.custom-img {
  width: 28px !important;
  height: 28px !important;
  border-radius: 50% !important;
}
.font-size-md {
  font-size: 13px;
}
.custom-audio {
  transform: scale(0.8) !important;
  border-width: 2px !important;
}
</style>
