<template>
  <div>
    <audio
      :ref="`audioPlayer-${index}`"
      class="audio-player"
      :src="src"
    />
    <div class="audio-player-content">
      <b-spinner v-if="isLoading" class="text-primary" />
      <button
        v-else
        class="btn btn-sm d-flex justify-content-center align-items-center"
        :class="
          isPlaying
            ? 'btn-primary'
            : isDarkSkin
              ? 'btn-outline-light'
              : 'btn-outline-primary'
        "
        style="border-radius: 0.5em"
        @click="!isPlaying ? playAudio() : pauseAudio()"
      >
        <feather-icon
          v-if="!isPlaying && !isLoading"
          icon="PlayIcon"
          size="14"
          :class="isDarkSkin ? 'text-light' : ''"
          @click="pauseAudio()"
        />
        <feather-icon
          v-else-if="isPlaying && !isLoading"
          icon="PauseIcon"
          size="14"
          :class="isDarkSkin ? 'text-light' : ''"
        />
      </button>

      <span>{{ formatTime(counter) }}</span>
      <svg
        :id="`waveform-${index}`"
        ref="audioProgress"
        xmlns="http://www.w3.org/2000/svg"
        width="180"
        height="50"
        viewBox="0 0 143 22"
        fill="none"
      >
        <path
          v-for="(path, index2) in totalFrames"
          :key="index2"
          :d="createSvgPath(index2 + 1)"
          :class="`wave-path-${index}`"
          :stroke="isDarkSkin ? '#ffffff' : '#0993E3'"
          stroke-width="2.5"
          stroke-linecap="round"
        />
      </svg>
      <span>{{
        formatTime(
          parseInt(time ? time : audioDuration) == 0
            ? 1
            : parseInt(time ? time : audioDuration)
        )
      }}</span>
      <template>
        <b-button
          v-if="isPlaying"
          class="btn btn-sm rounded-3"
          @click="changePlayingSpeed()"
        >
          {{ speedOptions.find(option=>option.selected==true).label }}
        </b-button>
      </template>
    </div>
  </div>
</template>
<script>
import { Howl } from 'howler';

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    time: {
      type: Number,
      required: false,
      default: 0,
    },
    chat: {
      type: Boolean,
      required: false,
      default: false,
    },
    customStyle:{
      type: String,
      require: false,
      default: ''
    }
  },
  data() {
    return {
      counter: 0,
      audioDuration: 0,
      isPlaying: false,
      isLoading: false,
      actualFrame: 0,
      interval: null,
      frameInterval: null,
      totalFrames: 30,
      isHolding: false,
      howlerAudio: null,
      speedOptions: [
        {
          value: 0.5,
          label: '0.5x',
          selected: false,
        },
        {
          value: 1,
          label: '1x',
          selected: true,
        },
        {
          value: 1.5,
          label: '1.5x',
          selected: false,
        },
        {
          value: 2,
          label: '2x',
          selected: false,
        },
      ],
    };
  },
  mounted() {
    //* Hide all svg paths
    this.hideSvg(0.1);
    // Initialize audio
    if (this.chat) {
      this.howlerAudio = new Howl({
        src: [this.src],
        format: ['wav'],
        onload: () => {
          this.audioDuration = this.howlerAudio.duration();
        },
      });
    }
    const waveForm = document.getElementById(`waveform-${this.index}`);
    waveForm.addEventListener('mousedown', e => {
      this.isHolding = true;
      this.isPlaying = false;
      this.handleHolding(e);
    });
    waveForm.addEventListener('mousemove', e => {
      this.handleHolding(e);
    });
    waveForm.addEventListener('mouseup', e => {
      this.isHolding = false;
      if (this.howlerAudio) {
        const audioController = this.$refs[`audioPlayer-${this.index}`];
        audioController.currentTime = this.counter;
        this.playAudio();
        this.isPlaying = true;
      }
    });
  },
  methods: {
    changePlayingSpeed() {
      if (!this.isPlaying) return;
      const selectedSpeed = this.speedOptions.findIndex(option => option.selected === true);
      this.speedOptions[selectedSpeed].selected = false;
      let selectedIndex=0;
      if (selectedSpeed === this.speedOptions.length - 1) {
        selectedIndex = 0;
        this.speedOptions[0].selected = true;
      } else {
        selectedIndex = selectedSpeed + 1;
        this.speedOptions[selectedSpeed + 1].selected = true;
      }
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.counter++;
        if (
          this.counter
              >= Math.floor(this.time ? this.time : this.audioDuration)
        ) {
          this.isPlaying = false;
          clearInterval(this.interval);
        }
      }, 1000 / this.speedOptions[selectedIndex].value);
      clearInterval(this.frameInterval);
      const wavePaths = document.querySelectorAll(`.wave-path-${this.index}`);

      this.frameInterval = setInterval(() => {
        if (this.actualFrame < wavePaths.length) {
          wavePaths[this.actualFrame].style.opacity = '1';
          this.actualFrame++;
        } else {
          clearInterval(this.frameInterval);
        }
      }, ((this.time ? this.time : this.audioDuration) / wavePaths.length) * (1000 / this.speedOptions[selectedIndex].value));
      const audioController = this.$refs[`audioPlayer-${this.index}`];
      audioController.playbackRate = this.speedOptions[selectedIndex].value;
    },
    handleHolding(e) {
      if (this.isHolding) {
        this.isPlaying = false;
        this.pauseAudio();
        const { audioProgress } = this.$refs;
        const { clientX } = e;
        const { left, width } = audioProgress.getBoundingClientRect();
        const clickPosition = clientX - left;
        const percentage = Math.min(Math.max(clickPosition / width, 0), 1);
        const audioDuration = this.audioDuration || 1; // Evitar división por cero

        const newTime = percentage * audioDuration;
        this.counter = newTime;
        this.actualFrame = Math.floor(
          (newTime / audioDuration) * this.totalFrames,
        );
        this.handleSvg();
      }
    },
    /**
     *? Create svg path
     */
    createSvgPath(index) {
      const vInitialPoint = 8;
      if (index === 1) {
        return `M5 ${vInitialPoint}V12`;
      }
      if (index === 20) {
        return `M${index * 5} ${vInitialPoint}V12`;
      }
      if (index % 7 === 0) {
        return `M${index * 5} ${vInitialPoint - 10}V22`;
      }
      if (index % 5 === 0) {
        return `M${index * 5} ${vInitialPoint - 8}V22`;
      }
      if (index % 3 === 0) {
        return `M${index * 5} ${vInitialPoint - 6}V20`;
      }
      if (index % 2 === 0) {
        return `M${index * 5} ${vInitialPoint - 4}V16`;
      }
      return `M${index * 5} 6V16`;
    },
    handleSvg() {
      clearInterval(this.frameInterval);
      clearInterval(this.interval);
      const wavePaths = document.querySelectorAll(`.wave-path-${this.index}`);
      for (let i = this.actualFrame; i < wavePaths.length; i++) {
        wavePaths[i].style.opacity = 0.1;
      }
      for (let i = 0; i < this.actualFrame; i++) {
        wavePaths[i].style.opacity = 1;
      }
    },
    /**
     *?hide svg paths
     */
    hideSvg(opacity) {
      const wavePaths = document.querySelectorAll(`.wave-path-${this.index}`);
      for (let i = 0; i < wavePaths.length; i++) {
        wavePaths[i].style.opacity = opacity;
      }
    },
    /**
     *? Initialize audio and set duration
     */
    initilizateAudio() {
      this.howlerAudio = new Howl({
        src: [this.src],
        format: ['wav'],
        onload: () => {
          this.audioDuration = this.time
            ? this.time
            : this.howlerAudio.duration();
          const audioController = this.$refs[`audioPlayer-${this.index}`];
          this.isLoading = false;
          if (this.actualFrame === this.totalFrames) {
            this.actualFrame = 0;
            this.hideSvg(0.1);
            this.counter = 0;
          }
          this.animateSvg();
          audioController.play();
          this.isPlaying = true;
          if (this.interval) clearInterval(this.interval);
          this.interval = setInterval(() => {
            this.counter++;
            if (
              this.counter
              >= Math.floor(this.time ? this.time : this.audioDuration)
            ) {
              this.isPlaying = false;
              clearInterval(this.interval);
            }
          }, 1000 / this.speedOptions.find(option => option.selected == true).value);
        },
      });
    },
    /**
     * ? Format time to minutes and seconds
     */
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    },
    /** */
    async playAudio() {
      this.isLoading = true;
      this.initilizateAudio();
    },
    animateSvg() {
      const wavePaths = document.querySelectorAll(`.wave-path-${this.index}`);
      if (this.frameInterval) clearInterval(this.frameInterval);
      this.frameInterval = setInterval(() => {
        if (this.actualFrame < wavePaths.length) {
          wavePaths[this.actualFrame].style.opacity = '1';
          this.actualFrame++;
        } else {
          clearInterval(this.frameInterval);
        }
      }, ((this.time ? this.time : this.audioDuration) / wavePaths.length) * (1000 / this.speedOptions.find(option => option.selected == true).value));
    },
    async pauseAudio() {
      try {
        const audioController = this.$refs[`audioPlayer-${this.index}`];
        audioController.pause();
        this.isPlaying = false;
        clearInterval(this.interval);
        clearInterval(this.frameInterval);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.audio-player-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  column-gap: 10px;
}
</style>
