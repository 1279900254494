export default [
    {
        path: "client-company/",
        name: "paragon-client-company",
        component: () => import(/* webpackChunkName: "PRClientCompany" */ "@/views/commons/components/list-client-company/ClientCompany.vue"),
        meta: {
            pageTitle: "Clients Company Data",
            breadcrumb: [
                {
                text: "Clients Company Data",
                active: true,
                },
            ],
        },
    },
];