export default [
  {
    path: "reports",
    name: "boostcredit-reports",
    redirect: { name: "boostcredit-reports-payment-reports" },
    component: () => import(/* webpackChunkName: "BCReports" */ "@/views/commons/components/reports/Reports.vue"),
    meta: {
      pageTitle: "Reports",
      permittedRoles: [1, 2, 3],
      breadcrumb: [
        {
          text: "Reports",
        },
      ],
    },
    children: [
      {
        path: "payment-reports",
        name: "boostcredit-reports-payment-reports",
        component: () =>
          import(
            /* webpackChunkName: "BCPaymentReports" */ "@/views/commons/components/reports/tabs/monthly-payment/MonthlyPayment.vue"
          ),
        meta: {
          pageTitle: "Reports",
          permittedRoles: [1, 2, 3],
          breadcrumb: [
            {
              text: "Reports",
            },
            {
              text: "Payment Reports",
            },
          ],
        },
      },
    ],
  },
];
