<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
        class="pt-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12" :class="isFromMeta ? 'order-12' : null">
            <h4 v-if="isFromMeta" class="card-title">STEP 2</h4>
            <b-row>
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="Title"
                  rules="required"
                  label="Title"
                >
                  <b-form-group
                    label="Title"
                    label-for="title"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      input-id="title"
                      v-model="event.title"
                      label="label"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      :options="G_EVENT_TITLES_OPTIONS"
                      :reduce="(val) => val.value"
                      :disabled="isDisabled"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-form-group label="Seller" label-for="seller">
                  <v-select
                    input-id="seller"
                    v-model="event.user_id"
                    label="label"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="sellers"
                    :disabled="isDisabled || isDisabledForSales"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    transition=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Location"
                  rules="required"
                >
                  <b-form-group label="Location" label-for="location">
                    <b-form-input
                      id="location"
                      v-model="event.location"
                      :state="errors[0] ? false : null"
                      :disabled="isDisabled"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description" label-for="description">
                  <b-form-textarea
                    id="description"
                    v-model="event.description"
                    :disabled="isDisabled"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" :class="isFromMeta ? 'order-1' : null">
            <h4 v-if="isFromMeta" class="card-title">STEP 1</h4>
            <b-row>
              <b-col md="4">
                <b-form-group label="Date" label-for="date">
                  <flat-pickr
                    v-model="event.date"
                    id="date"
                    placeholder="Date"
                    class="form-control"
                    :config="configFlatPickr"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="From" label-for="from">
                  <kendo-timepicker
                    :format="'HH:mm'"
                    v-model="event.from"
                    :interval="30"
                    :class="[
                      'w-100 rounded bg-transparent',
                      { 'text-white': isDarkSkin },
                    ]"
                    placeholder="Hour"
                    style="height: 2.73rem"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="To" label-for="to">
                  <kendo-timepicker
                    :format="'HH:mm'"
                    v-model="event.to"
                    :interval="30"
                    :class="[
                      'w-100 rounded bg-transparent',
                      { 'text-white': isDarkSkin },
                    ]"
                    placeholder="Hour"
                    style="height: 2.73rem"
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <validation-provider v-if="!isFromMeta">
                  <b-form-group label="State" label-for="State">
                    <b-form-input
                      :value="lead.state ? lead.state : 'UNK'"
                      disabled
                    />
                  </b-form-group>
                </validation-provider>
                <b-button v-else variant="outline-success" @click="onGetSeller">
                  <template v-if="isLoading">
                    <b-spinner small />
                    <span>Loading...</span>
                  </template>
                  <template v-else>
                    Next
                    <feather-icon icon="ArrowRightIcon" class="ml-50" />
                  </template>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- Form Actions -->
        <div class="d-flex float-right mt-2" v-if="isFromMeta">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :title="!lead.mobile ? 'Lead must have a mobile number' : ''"
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="!lead.mobile"
          >
            <template v-if="isLoading">
              <b-spinner small />
              <span>Loading...</span>
            </template>
            <span v-else>Save</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="$bvModal.hide('modal-event-create')"
            >Cancel</b-button
          >
        </div>
        <div class="d-flex float-right mt-2" v-else>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="isDisabled || isLoading"
          >
            <template v-if="isLoading">
              <b-spinner small />
              <span>Loading...</span>
            </template>
            <span v-else>Save</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="$bvModal.hide('modal-event-create')"
            >Cancel</b-button
          >
        </div>
      </b-form>
    </validation-observer>

    <!-- modal SALE MADE -->
    <b-modal
      id="modal-sale-made"
      title-class="h3 text-white"
      modal-class="modal-primary"
      centered
      size="sm"
      no-close-on-backdrop
      title="Sales Made"
      hide-footer
    >
      <modal-sale-made
        :lead="lead"
        :hasEventRegistered="false"
        @closeModal="closeModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import VueToggles from "vue-toggles";
import AppointmentsService from "@/views/crm/views/appoiments/service/appointments.service.js";
import ModalSaleMade from "@/views/crm/views/Lead/lead-event/ModalSaleMade.vue";

export default {
  components: {
    vSelect,
    flatPickr,
    VueToggles,
    ModalSaleMade,
  },
  props: {
    reply_id: {
      type: Number,
      default: 0,
    },
    appointmentValidate: {
      type: Boolean,
      default: false,
    },
    lead: {
      type: Object,
      required: true,
    },
    event_id: {
      type: Number,
      default: 0,
    },
    recovery: {
      type: Boolean,
      default: false,
    },
    idProcessSale: {
      type: String,
      default: null,
    },
    fromMeta: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { refFormObserver, getValidationState } = formValidation(() => {});

    return {
      refFormObserver,
      getValidationState,
    };
  },
  data() {
    return {
      isLoading: false,
      minDate: new Date(),
      maxDate: new Date(2050, 9, 1),
      currentDate: new Date(2017, 9, 15, 10, 30),
      minTime: new Date(1950, 0, 1, 0, 0, 0),
      maxTime: new Date(2049, 11, 31, 24, 0, 0),
      valueTime: "00:00:00",
      isDisabled:
        this.$route.matched[0]?.meta.module !== 15 || this.fromMeta
          ? false
          : true,
      sellers: [],
      event: {
        user_id: null,
        title: null,
        location: "",
        lead_id: this.lead.id,
        lead_name:
          this.lead.lead_name != "  "
            ? this.lead.lead_name
            : this.lead.nickname,
        all_day: "",
        date: "",
        from: "",
        to: "",
        state: this.lead.state,
        description: "",
        created_users: null,
        type: "event",
        modul: this.$route.matched[0]?.meta.module,
        event_filter_id: "",
        language: this.lead.language,
        seller: null,
        dateSp: null,
        dateEn: null,
        hourMsn: null,
        assignedRole: null,
        appointmentValidate: null,
        id_reply: null,
        id_process_sale: null,
        from_filter: null,
        call_answered: null, // lead not answered
        call_attended: null, // lead could not answered or says call later
        seller_phone: null
      },
      configFlatPickr: {
        dateFormat: "m/d/Y",
        locale: "en",
        minDate: `${moment().format("MM/DD/YYYY")}`,
      },
      dateNow: null,
    };
  },
  async created() {
    await Promise.all([
      this.getCatcherSeller(),
      this.getOwners(),
      this.setInitialDate(),
      this.setInitialFrom(),
    ]);
    this.removePreloader();
    this.dateNow = moment().format("MM/DD/YYYY");
    if (this.recovery) {
      this.event.id_process_sale = this.idProcessSale;
      this.event.from_filter = 13;
    }
    if (this.reply_id) {
      this.event.id_process_sale = this.idProcessSale;
      this.event.id_reply = this.reply_id;
    }
  },
  computed: {
    enableSwitch() {
      const evt = this.event;
      if (evt.title && evt.location != "" && evt.date && evt.from && evt.to) {
        return false;
      } else {
        evt.call_answered = false;
        evt.call_attended = false;
        return true;
      }
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_EVENT_TITLES_OPTIONS: "CrmEventStore/G_EVENT_TITLES",
      G_OWNERS: "CrmGlobalStore/G_OWNERS",
    }),

    isDisabledForSales() {
      return this.moduleId === 26 && this.currentUser.role_id == 5;
    },
    getRolesByModule() {
      // const roles = [2, 4].includes(this.modul) ? "[1,2,5]" : this.modul == 20 ? "[1,2,14,15,17]" :
      //                 this.modul === 26 ? "[1,2,5,14,17]" : "[1,2,3,5]";

      if (this.fromMeta) return "[1,2,5]";

      switch (this.moduleId) {
        case 2 || 4:
          return "[1,2,5]";
        case 11 || 25:
          return "[1,2,3]";
        case 20:
          return "[1,2,14,15,17]";
        case 26:
          return "[1,2,5,14,17]";
        default:
          return "[1,2,3,5]";
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    isFromMeta() {
      return this.moduleId === 15 && !this.fromMeta;
    },

    isCrm() {
      return this.$route.matched[0].meta.module === 2;
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-sale-made");
    },
    onClickToggleDialogue(prop) {
      this.event[prop] = !this.event[prop];
    },
    ...mapActions({
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      A_SET_EVENT: "CrmEventStore/A_SET_EVENT",
      A_GET_USER_APPOINTMENT_SN: "CrmLeadStore/A_GET_USER_APPOINTMENT_SN",
      A_GET_HOUR_SYSTEM: "global-store/A_GET_HOUR_SYSTEM",
      APPOINTMENT_EXIST: "RequestNcrStore/APPOINTMENT_EXIST",
    }),
    setInitialUser() {
      [11, 25, 26].indexOf(this.moduleId) === -1
        ? (this.event.user_id = this.currentUser.user_id)
        : null;
      this.moduleId !== 26
        ? (this.event.created_users = this.currentUser.user_id)
        : null;
    },
    setInitialDate() {
      this.event.date = moment().format("MM/DD/YYYY");
    },
    async setInitialFrom() {
      try {
        const response = await this.A_GET_HOUR_SYSTEM(this.lead.state);
        let nowDate = moment().format("MM/DD/YYYY");

        //GetHour close 30 min California
        let nowHour = moment(nowDate + " " + response);
        let fixedHour = 30 - (nowHour.minute() % 30);
        let dateTime = moment(nowHour)
          .add(fixedHour, "minutes")
          .format("HH:mm");
        this.event.from = dateTime;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    setInitialTo(from) {
      //30 mins more
      let to = moment(from, "HH:mm").add(30, "minutes").format("HH:mm");

      this.event.to = to;
    },
    // OBS Al listar los seller setea por defecto el usuario logueado pero no se encuentra dentro de los sellers del select
    async getOwners() {
      try {
        const roles = this.getRolesByModule;
        await this.A_GET_OWNERS({
          modul: this.fromMeta ? 2 : this.moduleId,
          body: { roles, type: "1", validate_filter: 1 },
        });
        this.sellers = this.G_OWNERS;
        this.setInitialUser();
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async onSubmit() {
      try {
        this.isLoading = true;
        await this.datesMsn();
        //GET SELLER NAME
        this.event.seller = this.sellers.filter(
          (item) => item.value === this.event.user_id
        )[0].label;

        this.event.seller_phone = this.sellers.filter(
          (item) => item.value === this.event.user_id
        )[0].phone;

        this.event.assignedRole = this.appointmentValidate ? 5 : null;
        this.event.appointmentValidate = this.appointmentValidate ? true : null;
        this.event.id_reply = this.appointmentValidate ? this.reply_id : null;

        const response = await this.A_SET_EVENT(this.event);

        if (this.isResponseSuccess(response)) {
          await AppointmentsService.updateStatusAppointments({
            status: 2,
            id: this.event_id,
          });

          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
          const params = {
            lead_id: this.lead.id,
            module_id: this.currentUser.modul_id,
          };
          await this.APPOINTMENT_EXIST(params);
          this.$emit("resetData", "event");
          this.$emit("refreshCountPending");
          this.$emit("sellerDone");
          this.isLoading = true;
          this.$bvModal.hide("modal-event-create");
          this.$emit("getAppointments");

          if (this.fromMeta) {
            this.$emit("sendMessageFromMeta", this.event);
          }
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong. ${response.message}`
          );
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    datesMsn() {
      //Dates to  send Msn
      this.event.dateSp = new Date(
        this.event.date.replace(/-/g, "/")
      ).toLocaleDateString("es-ES", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });
      this.event.dateEn = new Date(
        this.event.date.replace(/-/g, "/")
      ).toLocaleDateString("en-EN", {
        weekday: "long",
        month: "long",
        day: "numeric",
      });
      //Hour Msn
      let dateNew = moment(this.event.date + " " + this.event.from);
      this.event.hourMsn = dateNew.format("h:mm A");
    },
    async onGetSeller() {
      try {
        this.isLoading = true;
        if (
          this.event.date !== null &&
          this.event.from !== null &&
          this.event.to !== null
        ) {
          const response = await this.A_GET_USER_APPOINTMENT_SN({
            date: this.event.date,
            from: this.event.from,
            to: this.event.to,
          });
          if (this.isResponseSuccess(response)) {
            this.sellers = response.data.map((el) => ({
              label: `${el.user_name} (${el.c_appointments})`,
              value: el.id,
            }));
            this.isDisabled = false;
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCatcherSeller() {
      if (this.moduleId === 26) {
        const params = {
          lead_id: this.lead.id,
        };
        const data = await DerivationsService.getCatcherSeller(params);
        if (data.status === 200) {
          this.event.user_id = this.currentUser.user_id;
          this.event.created_users = data.data[0].catcher_id;
        }
      }
    },
  },
  watch: {
    "event.from"(newVal, oldVal) {
      if (newVal) {
        this.setInitialTo(newVal);
      }
    },
    "event.call_attended"(newVal, oldVal) {
      if (newVal) {
        this.$bvModal.show("modal-sale-made");
      }
    },
  },
  directives: {
    Ripple,
  },
};
</script>
