export default [
  {
    path: "update-request-origin",
    name: "update-request-analyst-department-origin",
    component: () =>
      import(
        /* webpackChunkName: "updateRequestSpecialist" */
        "@/views/specialists/sub-modules/analyst-department/views/update-request/UpdateRequestOrigin.vue"
      ),
    redirect: { name: "sp-update-request-process-origin" },
    meta: {
      module: 28,
      pageTitle: "Update Request",
      breadcrumb: [
        {
          text: "Update Request",
          active: true,
        },
      ],
      routePending: "sp-update-request-process-origin",
      routeCompleted: "sp-update-request-completed-origin",
      routeToRevision: "sp-update-request-to-revision-origin",
      routeDeleted: "sp-update-request-deleted-origin"
    },
    children: [
      {
        path: "in-process",
        name: "sp-update-request-process-origin",
        component: () =>
          import(
            /* webpackChunkName: "updateRequestProcess" */
            "@/views/commons/components/dispute-results/views/components/TableInProcess.vue"
          ),
        meta: {
          module: 28,
          status: 0,
          is_digital: false,
          permittedRoles: [1, 2, 3, 8, 14, 17, 21, 22],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "In Process",
              active: true,
            },
          ],
        },
      },
      {
        path: "completed",
        name: "sp-update-request-completed-origin",
        component: () =>
          import(
            /* webpackChunkName: "updateRequestCompleted" */
            "@/views/commons/components/dispute-results/views/components/TableCompleted.vue"
          ),
        meta: {
          module: 11,
          status: 1,
          is_digital: false,
          permittedRoles: [1, 2, 3, 8, 14, 17, 21, 22],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
        },
      },
      {
        path: "deleted",
        name: "sp-update-request-deleted-origin",
        component: () =>
          import(
            /* webpackChunkName: "updateRequestDeleted" */
            "@/views/commons/components/dispute-results/views/components/TableDeleted.vue"
          ),
        meta: {
          module: 28,
          status: 2,
          is_digital: false,
          permittedRoles: [1, 2, 3, 8, 14, 17, 21, 22],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Deleted",
              active: true,
            },
          ],
        },
      },
      {
        path: "to_revision",
        name: "sp-update-request-to-revision-origin",
        component: () =>
          import(
            /* webpackChunkName: "updateRequestCompleted" */
            "@/views/commons/components/dispute-results/views/components/TableToRevision.vue"
          ),
        meta: {
          module: 11,
          status: 3,
          is_digital: false,
          permittedRoles: [1, 2, 3, 8, 14, 17, 21, 22],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "To Revision",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "update-request",
    name: "update-request-analyst-department",
    component: () =>
      import(
        /* webpackChunkName: "updateRequestSpecialist" */
        "@/views/specialists/sub-modules/analyst-department/views/update-request/UpdateRequest.vue"
      ),
    redirect: { name: "sp-update-request-process" },
    meta: {
      module: 28,
      pageTitle: "Update Request",
      breadcrumb: [
        {
          text: "Update Request",
          active: true,
        },
      ],
      routePending: "sp-update-request-process",
      routeCompleted: "sp-update-request-completed",
      routeToRevision: "sp-update-request-to-revision",
      routeDeleted: "sp-update-request-deleted"
    },
    children: [
      {
        path: "in-process",
        name: "sp-update-request-process",
        component: () =>
          import(
            /* webpackChunkName: "updateRequestProcess" */
            "@/views/commons/components/dispute-results/views/components/tabs/TableInProcess.vue"
          ),
        meta: {
          module: 28,
          status: 0,
          is_digital: false,
          permittedRoles: [1, 2, 3, 8, 14, 17, 21, 22],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "In Process",
              active: true,
            },
          ],
          tab: 'IN PROCESS'
        },
      },
      {
        path: "completed",
        name: "sp-update-request-completed",
        component: () =>
          import(
            /* webpackChunkName: "updateRequestCompleted" */
            "@/views/commons/components/dispute-results/views/components/tabs/TableCompleted.vue"
          ),
        meta: {
          module: 11,
          status: 1,
          is_digital: false,
          permittedRoles: [1, 2, 3, 8, 14, 17, 21, 22],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
          tab: 'COMPLETED'
        },
      },
      {
        path: "deleted",
        name: "sp-update-request-deleted",
        component: () =>
          import(
            /* webpackChunkName: "updateRequestDeleted" */
            "@/views/commons/components/dispute-results/views/components/tabs/TableDeleted.vue"
          ),
        meta: {
          module: 28,
          status: 2,
          is_digital: false,
          permittedRoles: [1, 2, 3, 8, 14, 17, 21, 22],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Deleted",
              active: true,
            },
          ],
          tab: 'IN DELETED'
        },
      },
      {
        path: "to_revision",
        name: "sp-update-request-to-revision",
        component: () =>
          import(
            /* webpackChunkName: "updateRequestCompleted" */
            "@/views/commons/components/dispute-results/views/components/tabs/TableToRevision.vue"
          ),
        meta: {
          module: 11,
          status: 3,
          is_digital: false,
          permittedRoles: [1, 2, 3, 8, 14, 17, 21, 22],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "To Revision",
              active: true,
            },
          ],
          tab: 'TO REVISION'
        },
      },
    ],
  },
];
