var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter-principal":_vm.filterPrincipal,"filter":_vm.filters,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){_vm.$refs[("refSaleClients" + _vm.typeTab)].refresh()}},scopedSlots:_vm._u([{key:"custom-vselect",fn:function(){return [_c('FilterStatusAccount',{attrs:{"filters":_vm.filters[2]}})]},proxy:true},{key:"table",fn:function(){return [_c('b-table',{ref:("refSaleClients" + _vm.typeTab),attrs:{"slot":"table","small":"","no-provider-filtering":"","items":_vm.myProvider,"fields":_vm.tabFields,"primary-key":"id","responsive":"sm","show-empty":"","sticky-header":"70vh","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(lead_name)",fn:function(data){return [_c('router-link',{class:[_vm.textLink],attrs:{"target":"_blank","to":{
              name: 'sales-lead-show',
              params: {
                id: data.item.lead_id,
              },
              props: { programsAll: _vm.programsAll },
            }}},[_c('span',[_vm._v(_vm._s(data.item.lead_name))])]),_c('br'),(data.item.user_responsible)?_c('span',{staticClass:"text-warning"},[_c('tabler-icon',{attrs:{"icon":data.item.user_responsible === _vm.currentUser.user_id
                  ? 'StarIcon'
                  : 'LockIcon',"size":"15"}}),_c('strong',[_vm._v("CEO's client")])],1):_vm._e(),_c('div',[_c('span',[_vm._v(_vm._s(data.item.account))]),_c('status-account',{attrs:{"account":data.item}})],1)]}},{key:"cell(charges)",fn:function(data){return [_vm._v(" $ "+_vm._s(data.item.charges)+" ")]}},(
            (_vm.$route.matched[0].meta.module != 2 &&
              _vm.$route.matched[0].meta.module != 6) ||
            (_vm.$route.matched[0].meta.module == 6 && _vm.currentUser.role_id != 4)
          )?{key:"cell(monthly_amount)",fn:function(data){return [_vm._v(" $ "+_vm._s(data.item.monthly_amount)+" ")]}}:null,(
            (_vm.$route.matched[0].meta.module != 2 &&
              _vm.$route.matched[0].meta.module != 6) ||
            (_vm.$route.matched[0].meta.module == 6 && _vm.currentUser.role_id != 4)
          )?{key:"cell(last_payment)",fn:function(data){return [_c('div',[_c('span',{staticStyle:{"margin-left":"15px"}},[(data.item.state_lp == 1)?_c('b-img',{attrs:{"src":_vm.assetsImg + '/images/icons/lastpayment-out.ico'}}):_c('b-img',{attrs:{"src":_vm.assetsImg + '/images/icons/lastpayment-in.ico'}}),_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.item.last_payment == "-" ? "" : data.item.last_payment))+" ")],1)])]}}:null,{key:"cell(type_payment)",fn:function(data){return [_c('status-payment',{attrs:{"account":data.item}})]}},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.item.created_at))+" ")]}},{key:"cell(tracking)",fn:function(data){return [_c('div',{staticClass:"pt-1"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MenuIcon","size":"15"},on:{"click":function($event){return _vm.openTracking(data.item)}}})],1)]}},(
            _vm.currentUser.role_id == 1 ||
            _vm.currentUser.role_id == 2 ||
            _vm.currentUser.role_id == 5 ||
            _vm.currentUser.role_id == 14 ||
            _vm.currentUser.role_id == 17
          )?{key:"cell(actions)",fn:function(data){return [(
              data.item.user_responsible == _vm.currentUser.user_id ||
              data.item.user_responsible == null
            )?_c('actions-table',{attrs:{"options":_vm.actionOptions,"row-data":data.item,"fromCeDigital":true},on:{"modalAddService":_vm.modalAddService,"modalChangeService":_vm.modalChangeService,"onTracing":_vm.openActions,"onRecycled":_vm.openActions}}):_vm._e()]}}:null],null,true)})]},proxy:true}])}),(_vm.modalOpenAddService)?_c('modal-add-service',{attrs:{"modal-open-add-service":_vm.modalOpenAddService,"details-client":_vm.detailsClient,"type-modal":_vm.typeModal},on:{"hideModal":_vm.closeModalService,"changeProgram":_vm.openModalProgram}}):_vm._e(),(_vm.modal.programs)?_c(_vm.modalData.programs.programSelected,{tag:"component",attrs:{"type-modal":_vm.modalData.programs.typeModal,"sales-client":_vm.saleClientP,"programs-all":_vm.programsAll,"users-services":_vm.usersServices,"header-s":_vm.headerS},on:{"closeModal":_vm.hideModalProgram,"changeProgram":_vm.openModalProgram}}):_vm._e(),(_vm.stateActionsTypeTab)?_c('actions-type-tab-modal',{attrs:{"client-data":_vm.clientData},on:{"hideModal":function($event){_vm.stateActionsTypeTab = false},"refresh":function($event){_vm.$refs[("refSaleClients" + _vm.typeTab)].refresh()}}}):_vm._e(),(_vm.stateTracing)?_c('tracking-modal',{attrs:{"client-data":_vm.clientData},on:{"hideModal":function($event){_vm.stateTracing = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }