export default {
    namespaced: true,
    state: {
        S_IS_ACTIVE: false,
        S_DATA_WP: null,
        S_TITLE: null,
        S_DETAIL_WP:null
    },
    getters: {
        G_IS_ACTIVE(state) {
            return state.S_IS_ACTIVE;
        },
        G_DATA_WP(state) {
            return state.S_DATA_WP;
        },
        G_TITLE(state) {
            return state.S_TITLE;
        },
        G_DETAIL_WP(state){
            return state.S_DETAIL_WP;
        }
    },
    mutations: {
        M_OPEN_ALERT(state, payload) {
            state.S_IS_ACTIVE = payload;
        },
        M_DATA_WP(state, payload) {
            state.S_DATA_WP = payload;
        },
        M_TITLE(state, payload) {
            state.S_TITLE = payload;
        },
        M_RESET(state){
            state.S_IS_ACTIVE = false;
            state.S_TITLE = '';
            state.S_DATA_WP = null;
        },
        M_DETAIL_WP(state,payload){
            state.S_DETAIL_WP = payload;
        }
    }
}