export default {
  path: "migrations",
  name: "migrations-administration",
  component: () =>
    import(
      /* webpackChunkName: "Administration Migrations" */ "@/views/administration/views/migrations/MigrationsGrid.vue"
    ),
  meta: {
    permittedRoles: [1, 2, 6],
    pageTitle: 'Migrations',
    breadcrumb: [
      {
        text: "Clients",
        active: true,
      },
    ],
  },
};
