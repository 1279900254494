import { amgApi } from "@/service/axios";

class FinancialCommissionsService {
  async getCedAgentsCommissions(body) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/get-ced-agents-commissions",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getCedSupAssistCommissions(body) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/get-ced-sup-assist-commissions",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async createCommissionCed(body) {
    try {
      const data = await amgApi.post(
        "/credit-experts-digital/create-commision",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async createCommissionChargesCed(body) {
    try {
      if (String(body.description).includes("NCR")) {
        body.chargeId = 1;
      }
      const data = await amgApi.post(
        "/credit-experts-digital/create-charge-commission",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getCommissionForDashboardFinancial(body) {
    try {
      const data = await amgApi.post(
        "/credit-experts-digital/financial/get-commission-for-dashboard-financial",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new FinancialCommissionsService();
