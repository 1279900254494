<template>
  <b-card :class="isDarkSkin ? 'custom-bg-card' : 'custom-bg-card-light'">
    <component
      :is="tagType"
      v-model="modalUp"
      modal-class="modal-primary"
      modal
      title="Files"
      title-tag="h3"
      size="xmd"
      hide-footer
      scrollable
      @hidden="hideModal()"
    >
      <b-col>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <b-dropdown
              size="md"
              variant="custom-amg"
              toggle-class="text-decoration-none"
            >
              <template #button-content> New </template>
              <b-dropdown-item
                @click="openFolderModal"
                v-if="selectedFolder.file_name == null"
                ><feather-icon class="mr-1" icon="FolderPlusIcon" /> New
                Folder</b-dropdown-item
              >
              <b-dropdown-item @click="showModalUpload('single')"
                ><feather-icon class="mr-1" icon="FilePlusIcon" />Single
                File</b-dropdown-item
              >
              <b-dropdown-item @click="showModalUpload('multiple')"
                ><feather-icon class="mr-1" icon="FilePlusIcon" />Multiple
                File</b-dropdown-item
              >
            </b-dropdown>
            <b-button
              class="my-2 ml-1"
              :disabled="selectFiles.length == 0"
              variant="custom-amg"
              @click="openCopyMoveSidebar(1)"
            >
              Copy
            </b-button>
            <b-button
              v-if="!isFolderApp"
              :disabled="selectFiles.length == 0"
              variant="custom-amg"
              class="ml-1"
              @click="openCopyMoveSidebar(2)"
            >
              Move
            </b-button>
            <b-button
              v-if="selectedFolder.length !== 0"
              variant="custom-amg"
              class="ml-1"
              @click="checkAllFile()"
            >
              {{ isCheckedAll ? "Deselect All" : "Select All" }}
            </b-button>
          </div>
          <div :class="onlyRead ? 'my-1' : 'my-1'">
            <span class="d-flex align-items-center">
              <feather-icon
                icon="HomeIcon"
                size="24"
                class="text-primary cursor-pointer mr-1"
                @click="getFiles(null)"
              />
              <span>
                <span v-if="selectedFolder.file_name" class="font-large-1"
                  >/ &nbsp;</span
                >
                <span class="font-medium-5">{{
                  selectedFolder.file_name ? selectedFolder.file_name : ""
                }}</span>
              </span>
            </span>
          </div>
          <div>
            <b-form-radio-group
              v-model="selectedView"
              buttons
              button-variant="outline-primary"
            >
              <b-form-radio :value="true">
                <feather-icon icon="ListIcon" size="18" />
              </b-form-radio>
              <b-form-radio :value="false">
                <feather-icon icon="GridIcon" size="18" />
              </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>

        <validation-observer ref="fileForm">
          <b-row v-if="isShownUpload && typeOfUpload === 1">
            <b-col cols="3">
              <validation-provider
                v-slot="{ errors }"
                name="Type"
                rules="required"
              >
                <b-form-group label="TYPE">
                  <b-form-select v-model="form.type" :options="typesOptions" />
                  <span v-if="errors[0]" class="text-danger">
                    Type {{ errors[0] }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="3">
              <validation-provider
                v-slot="{ errors }"
                name="doe"
                rules="required"
              >
                <b-form-group label="DOE">
                  <b-form-datepicker
                    v-model="form.datexp"
                    :min="new Date()"
                    locale="en"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />
                  <span v-if="errors[0]" class="text-danger">
                    DOE {{ errors[0] }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="3">
              <validation-provider
                v-slot="{ errors }"
                name="file"
                rules="required"
              >
                <b-form-group label="FILE">
                  <b-form-file v-model="fileToUpload" @input="fileFill" />
                  <span v-if="errors[0]" class="text-danger">
                    File {{ errors[0] }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="2">
              <b-button
                style="margin-top: 1.85rem"
                variant="primary"
                @click="saveOneFile"
              >
                Upload
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row v-if="isShownUpload && typeOfUpload === 2" class="mb-2">
          <b-col cols="12">
            <div class="mb-1 d-flex justify-content-between">
              <div />
              <b-button variant="primary" @click="saveMultipleFiles">
                Upload
              </b-button>
            </div>
            <drag-and-drop
              ref="file-manager"
              v-model="uploadFiles"
              :files-array="uploadFiles"
            />
          </b-col>
        </b-row>
        <b-row v-if="selectedView">
          <b-table-simple
            responsive
            sticky-header="50vh"
            no-border-collapse
            class="position-relative font-small-3 table-new-customization filter-slot-new-customization"
            show-empty
            primary-key="id"
            style="background: #fff !important"
          >
            <b-thead>
              <b-tr>
                <b-th
                  v-if="lengthFilteredFiles > 0 && !onlyRead"
                  style="width: 10px !important"
                  ><b-form-checkbox
                    v-model="allSelected"
                    @change="checkAllFiles"
                /></b-th>
                <b-th
                  class="cursor-pointer"
                  @click="getFiles(selectedFolder.id, 3, 'asc')"
                >
                  <div class="d-flex justify-content-start">
                    <span style="margin-right: 0.5rem">NAME</span>
                    <div>
                      <feather-icon
                        class="d-block"
                        :style="firstSortName ? 'color: black' : ''"
                        icon="ChevronUpIcon"
                        size="9"
                      /><feather-icon
                        class="d-block"
                        :style="!firstSortName ? 'color: black' : ''"
                        icon="ChevronDownIcon"
                        size="9"
                      />
                    </div>
                  </div>
                </b-th>
                <b-th># FILES / SIZE</b-th>
                <b-th>DOE</b-th>
                <b-th
                  class="cursor-pointer"
                  @click="getFiles(selectedFolder.id, 7, 'asc')"
                  ><div class="d-flex justify-content-start">
                    <span style="margin-right: 0.5rem">UPLOAD BY</span>
                    <div>
                      <feather-icon
                        class="d-block"
                        icon="ChevronUpIcon"
                        size="9"
                        :style="firstUploadBy ? 'color: black' : ''"
                      />
                      <feather-icon
                        class="d-block"
                        icon="ChevronDownIcon"
                        size="9"
                        :style="!firstUploadBy ? 'color: black' : ''"
                      />
                    </div></div
                ></b-th>
                <b-th>MODIFIED BY</b-th>
                <b-th>Bureau</b-th>
                <b-th v-if="!onlyRead" class="text-center"> ACTIONS </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-if="isBusy">
                <b-th colspan="7">
                  <div
                    v-if="isBusy"
                    class="w-100 text-center text-primary my-2"
                  >
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </b-th>
              </b-tr>
              <template v-else>
                <template v-if="files.length > 0">
                  <b-tr
                    v-for="(item, index) in files.filter(
                      (file) => file.type == 'Folder'
                    )"
                    :key="index"
                  >
                    <b-td v-if="!onlyRead && lengthFilteredFiles > 0" />
                    <b-td>
                      <span
                        v-if="selectedFile.id !== item.id"
                        @click="searchFolder(item)"
                        ><feather-icon
                          icon="FolderIcon"
                          class="mr-50 text-secondary"
                          size="15"
                        /><span class="cursor-pointer">{{
                          item.file_name
                        }}</span></span
                      >
                      <span v-else>
                        <b-form-group>
                          <b-form-input
                            v-model="selectedFile.file_name"
                            class="d-inline"
                            style="width: 70%"
                            @keyup.enter="updateFolder(selectedFile)"
                          />
                          <tabler-icon
                            icon="CheckIcon"
                            size="20"
                            class="text-success ml-1 cursor-pointer"
                            @click="updateFolder(selectedFile)"
                          />
                          <tabler-icon
                            icon="XIcon"
                            size="20"
                            class="text-danger ml-1 cursor-pointer"
                            @click="selectedFile = []"
                          />
                        </b-form-group>
                      </span>
                    </b-td>
                    <b-td
                      ><span>{{ item.size }}</span></b-td
                    >
                    <b-td>
                      <span
                        v-if="item.expiration != null"
                        :class="
                          item.valid == 0
                            ? 'text-dark'
                            : item.valid == 1
                            ? 'text-warning'
                            : item.valid == 2
                            ? 'text-danger'
                            : ''
                        "
                      >
                        {{ item.expiration | myGlobal }}
                      </span>
                    </b-td>
                    <b-td
                      ><div class="row align-items-center">
                        <div class="col-2 p-0">
                          <b-avatar
                            variant="info"
                            :src="
                              item.user_image
                                ? item.user_image
                                : require('@/assets/images/logo/amg_logo.svg')
                            "
                          />
                        </div>
                        <div class="col-9 p-0">
                          <span>{{ item.user_name }}</span> <br />
                          <span>{{ item.created_at | myGlobalDay }}</span>
                        </div>
                      </div></b-td
                    >
                    <b-td
                      ><div
                        class="row align-items-center"
                        v-if="item.user_image_updated"
                      >
                        <div class="col-2 p-0">
                          <b-avatar
                            variant="info"
                            :src="
                              item.user_image_updated
                                ? item.user_image_updated
                                : require('@/assets/images/logo/amg_logo.svg')
                            "
                          />
                        </div>
                        <div class="col-9 p-0">
                          <span>{{ item.update_name }}</span> <br />
                          <span>{{ item.updated_at | myGlobalDay }}</span>
                        </div>
                      </div>
                    </b-td>
                    <b-td>-</b-td>
                    <b-td v-if="!onlyRead">
                      <div class="d-flex justify-content-center">
                        <feather-icon
                          v-if="!(item.file_name == 'APP' || isFolderApp)"
                          size="18"
                          class="cursor-pointer text-warning mr-1"
                          icon="EditIcon"
                          @click="selectItemToEdit(item)"
                        />
                        <feather-icon
                          v-if="!(item.file_name == 'APP' || isFolderApp)"
                          size="18"
                          class="cursor-pointer text-danger"
                          icon="TrashIcon"
                          @click="deleteCompanyFile(item, true)"
                        />
                      </div>
                    </b-td>
                  </b-tr>
                  <b-tr
                    v-for="(item, index) in files.filter(
                      (file) => file.type == 'File'
                    )"
                    :key="'n' + index"
                  >
                    <b-td v-if="!onlyRead && lengthFilteredFiles > 0">
                      <b-form-checkbox
                        v-model="item.check"
                        @change="onCheckFile(item)"
                      />
                    </b-td>
                    <b-td>
                      <a
                        v-if="selectedFile.id !== item.id"
                        :href="item.route_pdf"
                        target="_blank"
                      >
                        <amg-icon
                          icon="CustomFileIcon"
                          :style="item.type === 'Folder' ? 'fill: #ff9f43' : ''"
                          class="mr-50"
                          :class="{ 'text-warning': item.type === 'Folder' }"
                          size="15"
                        /><span>{{ item.file_name }}</span>
                      </a>
                      <span v-else>
                        <b-form-group>
                          <b-form-input
                            v-model="selectedFile.file_name"
                            class="d-inline"
                            style="width: 70%"
                            @keyup.enter="updateFile(selectedFile)"
                          />
                          <tabler-icon
                            icon="CheckIcon"
                            size="20"
                            class="text-success ml-1 cursor-pointer"
                            @click="updateFile(selectedFile)"
                          />
                          <tabler-icon
                            icon="XIcon"
                            size="20"
                            class="text-danger ml-1 cursor-pointer"
                            @click="selectedFile = []"
                          />
                        </b-form-group>
                      </span>
                    </b-td>
                    <b-td
                      ><span>{{ item.size }} KB</span></b-td
                    >
                    <b-td>
                      <span
                        v-if="item.expiration != null"
                        :class="
                          item.valid == 0
                            ? 'text-dark'
                            : item.valid == 1
                            ? 'text-warning'
                            : item.valid == 2
                            ? 'text-danger'
                            : ''
                        "
                      >
                        {{ item.expiration | myGlobal }}
                      </span>
                    </b-td>
                    <b-td
                      ><div class="row align-items-center">
                        <div class="col-2 p-0">
                          <b-avatar
                            variant="info"
                            :src="
                              item.user_image
                                ? item.user_image
                                : require('@/assets/images/logo/amg_logo.svg')
                            "
                          />
                        </div>
                        <div class="col-9 p-0">
                          <span>{{ item.user_name }}</span> <br />
                          <span>{{ item.created_at | myGlobalDay }}</span>
                        </div>
                      </div></b-td
                    >
                    <b-td>
                      <div
                        class="row align-items-center"
                        v-if="item.user_image_updated"
                      >
                        <div class="col-2 p-0">
                          <b-avatar
                            variant="info"
                            :src="
                              item.user_image_updated
                                ? item.user_image_updated
                                : require('@/assets/images/logo/amg_logo.svg')
                            "
                          />
                        </div>
                        <div class="col-9 p-0">
                          <span>{{ item.update_name }}</span> <br />
                          <span>{{ item.updated_at | myGlobalDay }}</span>
                        </div>
                      </div>
                    </b-td>
                    <b-td>
                      <b-img
                        v-if="item.bureau"
                        :src="
                          baseImg +
                          '/assets/images/bureaus/' +
                          item.bureau +
                          '.png'
                        "
                      />
                    </b-td>
                    <b-td v-if="!onlyRead">
                      <div class="d-flex justify-content-center">
                        <b-badge
                          v-if="item.status_file_client != null"
                          pill
                          variant="success"
                          class="cursor-pointer"
                          @click="getTracking(item)"
                        >
                          APPROVED
                        </b-badge>
                      </div>
                      <div class="d-flex justify-content-center">
                        <feather-icon
                          v-if="
                            item.status_in_app != null &&
                            item.status_file_client == null
                          "
                          class="cursor-pointer text-primary mr-1"
                          icon="ThumbsUpIcon"
                          size="18"
                          @click="changeStatusFile(1, item)"
                        />
                        <feather-icon
                          v-if="
                            item.status_in_app != null &&
                            item.status_file_client == null
                          "
                          class="cursor-pointer text-danger"
                          icon="ThumbsDownIcon"
                          size="18"
                          @click="changeStatusFile(0, item)"
                        />
                        <feather-icon
                          v-if="!(item.file_name == 'APP' || isFolderApp)"
                          size="18"
                          class="cursor-pointer text-warning mr-1"
                          icon="EditIcon"
                          @click="selectItemToEdit(item)"
                        />
                        <feather-icon
                          v-if="!(item.file_name == 'APP' || isFolderApp)"
                          size="18"
                          class="cursor-pointer text-danger"
                          icon="TrashIcon"
                          @click="deleteCompanyFile(item, false)"
                        />
                      </div>
                    </b-td>
                  </b-tr>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td colspan="100%">
                      <div class="row d-flex justify-content-center">
                        <div style="position: relative">
                          <img
                            src="@/assets/images/noData/no_data_re_kwbl.svg"
                            class="img-fluid custom-empty"
                            alt="scope"
                          />
                          <span
                            class="text-uppercase custom-span"
                            style="
                              position: absolute;
                              top: 50%;
                              left: 50%;
                              transform: translate(-50%, -50%);
                            "
                            >There are no files</span
                          >
                        </div>
                      </div>
                    </b-td>
                  </b-tr>
                </template>
              </template>
            </b-tbody>
          </b-table-simple>
        </b-row>
        <b-row v-else align-h="center">
          <template v-if="files.length > 0">
            <b-col
              v-for="(content, index) in files"
              :key="index"
              cols="6"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              :class="isDarkSkin ? 'hover-shadow-dark' : 'hover-shadow-light'"
            >
              <new-file-component
                :current-user="currentUser"
                :content="content"
                :folder="selectedFolder"
                :data-file="dataFile"
                :is-checked-all="isCheckedAll"
                :only-read="onlyRead"
                @contentClicked="searchFolder"
                @deleteFile="deleteFile"
                @refresh="refresh"
                @pushFiles="onCheckFile($event)"
              />
            </b-col>
          </template>

          <template v-else>
            <div style="position: relative">
              <img
                src="@/assets/images/noData/no_data_re_kwbl.svg"
                class="img-fluid custom-empty"
                alt="scope"
              />
              <span
                class="text-uppercase custom-span"
                style="
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                "
                >There are no files</span
              >
            </div>
          </template>
        </b-row>
        <new-folder-client-modal
          v-if="newFolderController"
          :account="client.account ? client.account : dataFile.account"
          :program_id="
            client.program_id ? client.program_id : dataFile.program_id
          "
          :client_account_id="dataFile ? dataFile.client_account_id : null"
          @close="closeFolderModal"
          @reload="getFiles"
        />
        <copy-move-sidebar
          v-if="copyMoveSidebar"
          :data-r="dataToPass"
          :user-id="currentUser.user_id"
          :client-account-id="dataFile ? dataFile.client_account_id : null"
          @close="closeCopyMoveSidebar"
          @reload="getFiles(selectedFolder.id)"
        />
        <tracking-approved-files
          v-if="modalTrackingApprovedFiles"
          :item="itemToSend"
          @close="modalTrackingApprovedFiles = false"
        />
        <modal-set-observation
          v-if="openObservationModal"
          @close="openObservationModal = false"
          @sendNotification="sendStatus"
        />
      </b-col>
    </component>
    <upload-files
      v-if="showUploadFile"
      :selected-folder="selectedFolder"
      :state="state"
      @close="showUploadFile = false"
      @reload="getFiles"
    />
  </b-card>
</template>
<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import NewFolderClientModal from "@/views/commons/components/clients/dashboard/options/files-module/modals/NewFolderClientModal.vue";
import CopyMoveSidebar from "@/views/commons/components/clients/dashboard/options/files-module/sidebars/CopyMoveSidebar.vue";
import FilesSimpleTable from "@/views/commons/components/clients/dashboard/options/files-module/components/FilesSimpleTable.vue";
import NewFileComponent from "@/views/commons/components/file-mananger/components/NewFileComponent.vue";
import TrackingApprovedFiles from "@/views/commons/components/clients/dashboard/options/files-module/components/TrackingApprovedFiles.vue";
import UploadFiles from "@/views/commons/components/clients/dashboard/options/files-module/modals/UploadFiles.vue";
import ModalSetObservation from "@/views/commons/components/clients/dashboard/options/files-module/components/ModalSetObservation.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DragAndDrop,
    NewFolderClientModal,
    CopyMoveSidebar,
    FilesSimpleTable,
    TrackingApprovedFiles,
    ModalSetObservation,
    NewFileComponent,
    UploadFiles,
  },
  props: {
    clientData: {
      type: Object,
      required: false,
    },
    dataFile: {
      type: Object,
      required: false,
    },
    componentMode: {
      // (1) div (2) b-modal
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      show: {
        folder: false,
      },
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      copyMoveSidebar: false,
      fileToUpload: [],
      uploadFiles: [],
      typesOptions: [
        {
          value: "ID",
          text: "ID",
        },
        {
          value: "UB",
          text: "UB",
        },
        {
          value: "Others",
          text: "Others",
        },
      ],
      form: {
        image: "",
        namedoc: "",
        account: "",
        size: "",
        user_id: "",
        type: "",
        datexp: "",
        folder_name: "",
        folder_id: "",
      },
      files: [],
      modalUp: false,
      newFolderController: false,
      selectFiles: [],
      selectedFolder: {
        id: null,
        file_name: null,
      },
      selectedFile: {},
      isBusy: false,
      fields: [
        {
          key: "checked",
          label: "",
          thStyle: {
            width: "20px",
          },
        },
        {
          key: "file_name",
          label: "Name",
        },
        {
          key: "size",
          label: "# Files / Size",
        },
        {
          key: "expiration",
          label: "DOE",
        },
        {
          key: "user_name",
          label: "Upload By",
        },
        {
          key: "update_name",
          label: "Modified by",
        },
        {
          key: "actions",
          label: "Actions",
          thStyle: {
            textAlign: "center",
          },
        },
      ],
      lastFileName: "",
      allSelected: false,
      isShownUpload: false,
      typeOfUpload: 1,
      dataToPass: [],
      lengthFilteredFiles: 0,
      firstSortName: true,
      firstUploadBy: true,
      itemToSend: {},
      modalTrackingApprovedFiles: false,
      openObservationModal: false,
      observationItem: {},
      selectedView: true,
      showUploadFile: false,
      state: null,
      isCheckedAll: false,
    };
  },
  computed: {
    onlyRead() {
      if (this.$route.params.idClient) {
        return (
          !(
            this.$store.getters[
              "NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"
            ].is_responsible === this.currentUser.user_id ||
            this.$store.getters[
              "NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"
            ].is_responsible === null
          ) || this.moduleId === 18
        );
      }
      return this.moduleId === 18;
    },
    isFolderApp() {
      return this.selectedFolder.file_name == "APP";
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      updateLetterTable:
        "SpecialistsDigitalRoundLettersStore/G_UPDATE_LETTER_TABLE",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    tagType() {
      return this.componentMode === 1 ? "div" : "b-modal";
    },
  },
  watch: {
    selectFiles() {
      if (this.lengthFilteredFiles === this.selectFiles.length) {
        this.allSelected = true;
      }
      if (this.lengthFilteredFiles > this.selectFiles.length) {
        this.allSelected = false;
      }
    },
  },
  async created() {
    this.modalUp = true;
    if (this.onlyRead) {
      this.fields.pop();
    }
    this.form.account = this.$route.params.idClient
      ? this.$route.params.idClient
      : this.dataFile.client_account_id;
    this.form.user_id = this.currentUser.user_id;
    await this.getFiles();
  },
  mounted() {
    this.selectedView = true;
  },
  methods: {
    ...mapActions({
      A_UPDATE_TABLE:
        "SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE",
    }),
    closeFolderModal() {
      this.newFolderController = false;
    },
    openFolderModal() {
      this.newFolderController = true;
    },
    async getFiles(id = null, orderBy = null, by = "asc") {
      try {
        this.addPreloader();
        let b = by;
        if (orderBy === 3) {
          b = this.firstSortName ? "asc" : "desc";
          this.firstSortName = !this.firstSortName;
        }

        if (orderBy === 7) {
          b = this.firstUploadBy ? "asc" : "desc";
          this.firstUploadBy = !this.firstUploadBy;
        }

        this.form.folder_id = id;
        this.form.folder_name = this.selectedFolder.file_name;
        this.isBusy = true;
        const data = await ClientsOptionsServices.getFilesClient({
          id: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.dataFile.client_account_id,
          folder_id: id,
          orderby: orderBy ?? 3,
          by: b,
        });
        if (data.status === 200) {
          this.files = data.data;
          const copyFiles = this.files.filter((file) => file.type === "File");
          this.lengthFilteredFiles = copyFiles.length;
          this.selectFiles = [];
          this.show.folder = false;
          this.isBusy = false;
          if (id === null) {
            this.selectedFolder = [];
            this.selectedFolder.id = null;
            this.selectedFolder.file_name = null;
          }
          this.files.forEach((element) => {
            this.$set(element, "check", false);
            this.$set(element, "editName", true);
          });
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    onCheckFile(item) {
      console.log(item);
      if (item.check) {
        this.selectFiles.push({
          id: item.id,
          route: item.route,
          file_name: item.file_name,
          size: item.size,
          extension: item.extension,
        });
      } else {
        for (let i = 0; i < this.selectFiles.length; i++) {
          if (this.selectFiles[i].id === item.id) {
            this.selectFiles.splice(i, 1);
          }
        }
      }
    },
    checkAllFiles() {
      this.selectFiles = [];

      for (const element of this.files) {
        if (element.type === "File") {
          if (this.allSelected) {
            this.$set(element, "check", true);
            this.selectFiles.push({
              id: element.id,
              route: element.route,
              file_name: element.file_name,
              size: element.size,
              extension: element.extension,
            });
          } else {
            this.$set(element, "check", false);
            this.selectFiles = [];
          }
        }
      }
    },
    checkAllFile() {
      this.isCheckedAll = !this.isCheckedAll;
    },
    async updateFolder(item) {
      try {
        this.addPreloader();
        const data = await ClientsOptionsServices.renameFolder({
          folder: this.lastFileName,
          route: item.route,
          newfolder: this.selectedFile.file_name,
          id: item.id,
          user_id: this.currentUser.user_id,
          account: this.client.account
            ? this.client.account
            : this.dataFile.account,
          idaccount: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.dataFile.client_account_id,
          program_id: this.client.program_id
            ? this.client.program_id
            : this.dataFile.program_id,
        });
        if (data.status === 200) {
          this.selectedFile = [];
          await this.getFiles(this.selectedFolder.id);
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "Renamed successfully"
          );
        }
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async updateFile(item) {
      try {
        this.addPreloader();
        const data = await ClientsOptionsServices.renameFile({
          file: this.lastFileName,
          route: item.route,
          newfile: this.selectedFile.file_name,
          id: item.id,
          user_id: this.currentUser.user_id,
          account: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.dataFile.client_account_id,
          extension: item.extension,
          folder_name: this.selectedFolder.file_name,
          folder_id: this.selectedFolder.id,
        });
        if (data.status === 200) {
          this.selectedFile = [];
          await this.getFiles(this.selectedFolder.id);
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "Renamed successfully"
          );
        }
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async selectItemToEdit(item) {
      this.lastFileName = item.file_name;
      this.selectedFile = item;
      const arraySplit = this.selectedFile.file_name.split(".");
      this.selectedFile.file_name = this.selectedFile.file_name.replace(
        `.${arraySplit[arraySplit.length - 1]}`,
        ""
      );
    },
    async searchFolder(item) {
      this.selectedFolder = item;
      await this.getFiles(item.id);
    },
    async deleteCompanyFile(file, isFolder) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          this.addPreloader();
          let data = [];
          if (isFolder) {
            data = await ClientsOptionsServices.deleteFolder({
              id: file.id,
              user_id: this.currentUser.user_id,
            });
          } else {
            data = await ClientsOptionsServices.deleteFile({
              id: file.id,
              user_id: this.currentUser.user_id,
            });
            await this.getMissingFiles();
          }
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            await this.getFiles(this.selectedFolder.id);
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    deleteFile(content) {
      const index = this.files.indexOf(content);
      if (index > -1) this.files.splice(index, 1);
    },
    async getMissingFiles() {
      const { data } = await ClientsOptionsServices.getMissingFiles({
        accountId: this.$route.params.idClient,
      });
      this.client.missing_files = data.data[0].missing_files;
      await this.$store.dispatch("ReportStore/A_MISSING_FILES", data);
    },
    async fileFill() {
      this.form.namedoc = this.fileToUpload.name;
      this.form.size = this.fileToUpload.size;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = async (file) => {
        this.form.image = file.target.result;
      };
    },
    async saveOneFile() {
      try {
        const result = await this.$refs.fileForm.validate();
        if (result) {
          this.addPreloader();
          const data = await ClientsOptionsServices.saveOneFileAccount(
            this.form
          );
          if (data.status === 200) {
            await this.getFiles(this.selectedFolder.id);
            this.showSuccessSwal();
            this.form.datexp = null;
            this.form.image = "";
            this.form.namedoc = "";
            this.form.size = "";
            this.form.type = "";
            this.form.account = this.$route.params.idClient
              ? this.$route.params.idClient
              : this.dataFile.client_account_id;
            this.form.user_id = this.currentUser.user_id;
            this.fileToUpload = [];
            this.isShownUpload = false;
            this.removePreloader();
            await Promise.all([
              this.$store.dispatch("DebtSolutionClients/A_COUNT_FILES", {
                idaccountclient: this.$route.params.idClient
                  ? this.$route.params.idClient
                  : this.dataFile.client_account_id,
              }),
            ]);
            await this.A_UPDATE_TABLE(true);
            await this.getMissingFiles();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        this.removePreloader();
      }
    },
    openCopyMoveSidebar(type) {
      this.dataToPass = { data: this.selectFiles, type };
      this.copyMoveSidebar = true;
    },
    async saveMultipleFiles() {
      try {
        this.addPreloader();
        const formData = new FormData();
        if (this.uploadFiles !== null && this.uploadFiles !== "") {
          this.uploadFiles.forEach((file) => {
            formData.append("images[]", file, file.name);
          });
        }
        const folder =
          this.selectedFolder.id === null ? "" : this.selectedFolder.id;
        const client_account_id = this.$route.params.idClient
          ? this.$route.params.idClient
          : this.dataFile.client_account_id;
        formData.append("account", client_account_id);
        formData.append("user_id", this.currentUser.user_id);
        formData.append("folder_id", folder);
        formData.append("folder_name", this.selectedFolder.file_name);

        const headers = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // eslint-disable-next-line func-names
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          }.bind(this),
        };
        const data = await ClientsOptionsServices.saveMultipleFilesAccount(
          formData,
          headers
        );
        if (data.status === 200) {
          await this.getFiles(this.selectedFolder.id);
          this.showSuccessSwal();
          this.form = {};
          this.form.account = this.$route.params.idClient
            ? this.$route.params.idClient
            : this.dataFile.client_account_id;
          this.form.user_id = this.currentUser.user_id;
          this.isShownUpload = false;
          this.$refs["file-manager"].removeAllFiles();
          this.uploadFiles = [];
          this.removePreloader();
          await Promise.all([
            this.$store.dispatch("DebtSolutionClients/A_COUNT_FILES", {
              idaccountclient: this.$route.params.idClient
                ? this.$route.params.idClient
                : this.dataFile.client_account_id,
            }),
          ]);
        }
      } catch (e) {
        this.showErrorSwal(e);
        this.removePreloader();
      }
    },
    closeCopyMoveSidebar() {
      this.copyMoveSidebar = false;
    },
    async changeStatusFile(status, item) {
      this.observationItem = item;
      if (status === 0) {
        this.openObservationModal = true;
      } else {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          await this.sendStatus({ status, observation: {} });
        }
      }
    },
    async sendStatus({ status, observation }) {
      const params = {
        status,
        title: observation.title,
        description: observation.description,
        user_id: this.currentUser.user_id,
        id: this.observationItem.id,
        account: this.$route.params.idClient
          ? this.$route.params.idClient
          : this.dataFile.client_account_id,
      };
      const data = await ClientsOptionsServices.changeStatusFilesApp(params);
      if (data.status === 200) {
        this.openObservationModal = false;
        this.showSuccessSwal();
        await this.getFiles(this.selectedFolder.id);
      }
    },
    async getTracking(item) {
      this.itemToSend = item;
      this.modalTrackingApprovedFiles = true;
    },
    hideModal() {
      this.modalUp = false;
      this.$emit("close");
    },
    async refresh() {
      await this.getFiles();
    },
    showModalUpload(state) {
      this.state = state;
      this.showUploadFile = true;
    },
  },
};
</script>
<style scoped>
.table-new-customization >>> thead tr th {
  background: rgba(63, 122, 250, 1) !important;
}
.custom-bg-card-light {
  background: rgba(250, 250, 250, 1);
}
.custom-empty {
  opacity: 0.3;
  height: 300px;
}
</style>
