import store from "@/store"
import router from "@/router/index"

const hasPriorityOrderAlert = async () => {
  const promise = new Promise(async (resolve, reject) => {
    await store.dispatch("SettlementStore/GET_COUNTER_PRIORITY_ORDER_ALERT")
    const data = store.getters["SettlementStore/S_COUNTER_PRIORITY_ORDER_ALERT"]
    if (data.high > 0 || data.medium > 0) {
      store.commit("modal-actions/M_VALIDATE_PAYMENT_PRIORITY_ALERT", true)
    }
    resolve()
  })
  return promise
}
const isuficiencyOfundsManagement = async () => {
  try {
    const promise = new Promise(async (resolve, reject) => {
      await store.dispatch("modal-actions/A_VALIDATE_BALANCE_BANK_ACCOUNT")
      resolve()
    })
    return promise
  } catch (error) {
    console.log(error)
  }
}

const fewChecksManagement = async () => {
  try {
    const promise = new Promise(async (resolve, reject) => {
      await store.dispatch("modal-actions/getCheckbooksWithFewChecks")
      resolve()
    })
    return promise
  } catch (error) {
    console.log(error)
  }
}

const validateModalActions = async (route) => {
  let currentUser = store.getters["auth/currentUser"]
  let currentPath = router.currentRoute.path
  let validateRouteClients = false
  let validateRouteSettlements = false
  // Show only for the clients section
  if (currentPath.startsWith("/debtsolution/clients/")) {
    validateRouteClients = !currentPath.startsWith(
      "/debtsolution/clients/account/"
    )
  }
  // Show only for the clients settlements
  validateRouteSettlements = currentPath.startsWith(
    "/debtsolution/settlements/"
  )

  if (
    currentUser.role_id === 2 &&
    currentUser.modul_id === 5 &&
    validateRouteClients
  ) {
    await fewChecksManagement()
    await isuficiencyOfundsManagement()
  } else if (
    currentUser.role_id === 2 &&
    currentUser.modul_id === 5 &&
    validateRouteSettlements
  ) {
    await hasPriorityOrderAlert()
  }
}

export { validateModalActions, hasPriorityOrderAlert }
