<template>
  <div>
    <b-button
      variant="primary"
      :disabled="!approvedAccounts"
      @click="exportAnalysis()"
      class="custom-button w-100"
    >
      EXPORT PDF
      <span v-if="qtyRemove > 0" class="ml-1">
        <b-badge pill variant="danger">
          {{ qtyRemove }}
        </b-badge>
      </span>
    </b-button>
  </div>
</template>
<script>
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service";

export default {
  props: {
    approvedAccounts: {
      type: Boolean,
      default: false,
    },
    ncrRequestId: {
      type: Number,
    },
    leadId: {
      type: Number,
    },
    qtyRemove: {
      type: Number,
      default: 0,
    },
    idClient: {
      default: null,
      require: false,
      type: String,
    },
    programClient: {
      default: 3,
      require: false,
      type: Number
    }
  },
  methods: {
    async exportAnalysis() {
      try {
        this.addPreloader();
        const params = {
          typeView: "remove",
          toViewRates: true,
          ncrRequestId: this.ncrRequestId,
          leadId: this.leadId,
          idClient: this.idClient,
          idProgram: this.programClient
        };
        const { data } = await NcrRequestService.exportAnalysisForRemove(
          params
        );
        await this.forceFileDownloadPdf(data);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style scoped>
.custom-button {
  background-color: #3f7afa;
  color: white;
}
</style>
