import {
  AssignTaskToDesigner,
  MarkMaterialAsRemarked,
  NotificationCommentTeamLeaderDesignByDesigner,
} from '@/views/creative/socket/creative.socket';

const subscribeCreative = socket => {
  try {
    socket.subscribe('channel-creative');
    socket.subscribe('notification-comment-team-leader-design');
    MarkMaterialAsRemarked();
    AssignTaskToDesigner();
    NotificationCommentTeamLeaderDesignByDesigner();
  } catch (error) {
    console.error(error, 'error instatiate socket (channel-creative)');
  }
};

export default subscribeCreative;
