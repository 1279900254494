const { amgApi } = require("@/service/axios");

class SendingToCreditorsService {
  async getSendingToCreditors(params) {
    try {
      const data = await amgApi.post(
        "/correspondence/get-sending-to-creditors",
        params
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  async printChecKPdf(params) {
    try {
      const data = await amgApi.post(
        "/correspondence/print-checK-pdf",
        params,
        {
          responseType: "blob",
        }
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  }
  async addShipment(params) {

    try {
      const data = await amgApi.post(
        "/correspondence/store-shipment-creditor-detail",
        params,
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async addReceptionFile(params) {
    try {
      const data = await amgApi.post(
        "/correspondence/reception-file",
        params,
        {
          "Content-Type": "multipart/form-data",
        },
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async getReceptionFile(params) {
    try {
      const data = await amgApi.post(
        `/correspondence/get-reception-file`,
        params
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async getSendingCreditorTracking(offerPaymentOrderId) {
    try {
      const data = await amgApi.get(
        `/correspondence/get-sending-creditor-tracking/${offerPaymentOrderId}`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  async getSendingToCreditorRegisteredPayment(params) {
    try {
      const data = await amgApi.post(
        `/correspondence/get-sending-to-creditors-registered-payment`,
        params
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async counterSendingToCreditors(params) {
    try {
      const data = await amgApi.get(
        `/correspondence/counter-sending-to-creditors`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default new SendingToCreditorsService();
