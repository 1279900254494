import { amgApi } from "@/service/axios";

class PermissionService{

    async sendPermissionRequest(parameters){
        try{
            const data = await amgApi.post('/actions-permission/send-request-permission',parameters)
            return data
        }catch(error){
            console.log(error)
            throw error;
        }
    }

    async getPermissionRequestType(parameters){
        try{
            const data = await amgApi.post('/actions-permission/get-request-permission-type',parameters)
            return data
        }catch(error){
            console.log(error)
            throw error;
        }
    }

    async getRequestsPermissionAction(parameters){
        try{
            const data = await amgApi.post('/actions-permission/get-request-permission-action',parameters)
            return data
        }catch(error){
            console.log(error)
            throw error;
        }
    }

    async getCounter(parameters){
        try{
            const data = await amgApi.post('/actions-permission/get-counter',parameters)
            return data
        }catch(error){
            console.log(error)
            throw error;
        }
    }

    async getChangeStatusRequest(parameters){
        try{
            const data = await amgApi.post('/actions-permission/change-status-request',parameters)
            return data
        }catch(error){
            console.log(error)
            throw error;
        }
    }

    async verifyStatusRequest(parameters){
        try{
            const data = await amgApi.post('/actions-permission/verify-status-request',parameters)
            return data
        }catch(error){
            console.log(error)
            throw error;
        }
    }

    async updateTimesUsedStatusRequest(parameters){
        try{
            const data = await amgApi.post('/actions-permission/update-times-status-request',parameters)
            return data
        }catch(error){
            console.log(error)
            throw error;
        }
    }

    async getTypesRequestAction(parameters){
        try{
            const data = await amgApi.post('/actions-permission/get-types-request-action',parameters)
            return data
        }catch(error){
            console.log(error)
            throw error;
        }
    }
}

export default new PermissionService
