<template>
  <b-modal
    v-model="control"
    centered
    title="File Viewer"
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    no-close-on-backdrop
    @hidden="close"
  >
    <b-container fluid>
      <!-- {{ fileType }} -->
      <div>
        <b-tabs v-model="activeTab" card>
          <b-tab no-body>
            <template #title>
              <h5><feather-icon icon="FileTextIcon" size="15" /> FILES</h5>
            </template>

            <b-col class="my-1">
              <p v-if="files.length === 0" class="text-success">
                There are no files to display
              </p>
              <b-form-checkbox
                v-if="files.length !== 0"
                v-model="selectAllFiles"
                @change="toggleSelectAllFiles"
                >Select All</b-form-checkbox
              >
            </b-col>

            <b-row>
              <b-col
                v-for="(file, index) in files"
                :key="index"
                cols="3"
                class="d-flex justify-content-center align-items-center p-2"
              >
                <div class="mr-1" :class="{ 'selected-file': file.selected }">
                  <a :href="file.file" target="_blank">
                    <feather-icon
                      v-if="
                        file.extension === 'xlsx' || file.extension === 'xls'
                      "
                      v-b-tooltip.hover="'Download'"
                      class="text-success"
                      icon="FileTextIcon"
                      size="30"
                    />
                    <feather-icon
                      v-else-if="
                        file.extension === 'docx' || file.extension === 'doc'
                      "
                      v-b-tooltip.hover="'Download'"
                      class="text-primary"
                      icon="FileTextIcon"
                      size="30"
                    />
                    <feather-icon
                      v-else-if="file.extension === 'pdf'"
                      v-b-tooltip.hover="'Download'"
                      class="text-danger"
                      icon="FileTextIcon"
                      size="30"
                    />

                    <feather-icon
                      v-else-if="
                        file.extension === 'zip' || file.extension === 'rar'
                      "
                      v-b-tooltip.hover="'Download'"
                      class="text-info"
                      icon="FileTextIcon"
                      size="30"
                    />

                    <feather-icon
                      v-else
                      v-b-tooltip.hover="'Download'"
                      class="text-secondary"
                      icon="FileTextIcon"
                      size="30"
                    />
                  </a>
                  <p
                    v-b-tooltip.hover.bottom="file.file_name"
                    class="pointer"
                    style="
                      margin: 0px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      width: 100px;
                    "
                  >
                    {{ file.file_name }}
                  </p>
                  <small>{{ file.created_at | myGlobalDay }}</small>
                </div>
                <b-form-checkbox
                  v-model="file.selected"
                  @change="updateSelectedFiles(file)"
                />
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body>
            <template #title>
              <h5><feather-icon icon="ImageIcon" size="15" /> MEDIA</h5>
            </template>
            <div class="my-1">
              <p v-if="media.length === 0" class="text-success">
                There are no files to display
              </p>
              <b-form-checkbox
                v-if="media.length !== 0"
                v-model="selectAllImages"
                @="toggleSelectAllImages"
                >Select All</b-form-checkbox
              >
            </div>
            <b-row>
              <b-col
                v-for="(file, index) in media"
                :key="index"
                class="mb-1"
                cols="2"
              >
                <b-form-checkbox
                  v-model="file.selected"
                  class="mb-1"
                  @change="updateSelectedImages(file)"
                />
                <b-img
                  :src="file.file"
                  v-bind="mainProps"
                  rounded
                  blank-color="#88f"
                  class="custom-image"
                  width="100px"
                  alt="Rounded image"
                  :class="{ 'selected-image': file.selected }"
                  style="cursor: pointer"
                  @click="openImageModal(file.file)"
                />
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body>
            <template #title>
              <h5><feather-icon icon="MusicIcon" size="15" /> AUDIO</h5>
            </template>

            <b-row>
              <!-- {{ audio }} -->

              <b-col cols="12" class="my-1">
                <p v-if="audio.length === 0" class="text-success">
                  There are no files to display
                </p>
                <b-form-checkbox
                  v-if="audio.length !== 0"
                  v-model="selectAllAudios"
                  @change="toggleSelectAllAudios"
                >
                  Select All
                </b-form-checkbox>
              </b-col>

              <b-col
                v-for="(audio_file, index) in audio.filter(
                  (audio) => audio.file != null
                )"
                :key="index"
                cols="6"
              >
                <!-- {{ audio_file }} -->
                <div
                  :class="{ 'selected-audio': audio_file.selected }"
                  class="mb-1"
                >
                  <audio-player
                    :index="index + 1 * -100"
                    :src="audio_file.file ? audio_file.file : ''"
                    style="width: 90%; height: auto"
                    :chat="true"
                  />
                  <b-form-checkbox
                    v-model="audio_file.selected"
                    class="mb-1"
                    @change="updateSelectedAudios(audio_file)"
                  >
                    <p
                      v-b-tooltip.hover.bottom="audio_file.file_name"
                      class="pointer"
                      style="
                        margin: 0px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 200px;
                      "
                    >
                      {{ audio_file.file_name }}
                    </p>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>
    </b-container>

    <template #modal-footer>
      <div>
        <b-button
          v-if="hasSelectedFiles"
          variant="outline-primary"
          @click="downloadFiles"
        >
          DOWNLOAD SELECTED
        </b-button>
        <b-button
          v-if="hasSelectedImages"
          variant="outline-primary"
          @click="downloadSelectedImages"
        >
          DOWNLOAD SELECTED
        </b-button>
        <b-button
          v-if="hasSelectedAudios"
          variant="outline-primary"
          @click="downloadSelectedAudios"
        >
          DOWNLOAD SELECTED
        </b-button>
      </div>
    </template>

    <image-viewer
      v-if="showImageViewer"
      :url-image="selectedImage"
      @hidden="showImageViewer = false"
    />
  </b-modal>
</template>

<script>
import ParticipantsServices from "@/views/commons/participants-tickets/services/participants.services";
import ImageViewer from "@/views/commons/components/customer-tickets/chat-components/ImageViewer.vue";

import AudioPlayer from "@/views/commons/components/customer-tickets/chat-components/AudioPlayer.vue";

export default {
  components: {
    ImageViewer,
    AudioPlayer,
  },

  props: {
    fileType: {
      type: Object,
      required: false,
      default: () => {},
    },

    urlImage: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      control: false,
      files: [],
      media: [],
      audio: [],
      activeTab: 0,

      selectAllImages: false,
      selectAllFiles: false,
      selectAllAudios: false,

      selectedImages: [],
      selectedFiles: [],
      selectedAudios: [],

      selectedImage: "",
      showImageViewer: false,
      mainProps: {},
    };
  },

  computed: {
    hasSelectedImages() {
      return this.selectedImages.length > 0;
    },
    hasSelectedFiles() {
      return this.selectedFiles.length > 0;
    },
    hasSelectedAudios() {
      return this.selectedAudios.length > 0;
    },
  },

  watch: {
    activeTab(newTab, oldTab) {
      this.selectedImages = [];
      this.selectedFiles = [];
      this.selectedAudios = [];

      this.selectAllImages = false;
      this.selectAllFiles = false;
      this.selectAllAudios = false;

      if (oldTab === 1) {
        this.media.forEach((image) => {
          image.selected = false;
        });
      }

      if (newTab === 0) {
        // Cuando se cambia a la pestaña "FILES", actualiza la prop fileType
        this.fileType.typeFileId = 2;
      } else if (newTab === 1) {
        // Cuando se cambia a la pestaña "MEDIA", actualiza la prop fileType
        this.fileType.typeFileId = 4;
      } else if (newTab === 2) {
        // Cuando se cambia a la pestaña "AUDIO", actualiza la prop fileType
        this.fileType.typeFileId = 3;
      }
      this.getFilesTicketsCustomer();
    },

    selectAllImages(newVal) {
      this.toggleSelectAllImages();
    },

    selectAllFiles(newVal) {
      this.toggleSelectAllFiles();
    },

    selectAllAudios(newVal) {
      this.toggleSelectAllAudios();
    },
  },

  mounted() {
    this.control = true;
  },

  created() {
    this.getFilesTicketsCustomer();
  },

  methods: {
    close() {
      this.$emit("hidden");
    },

    openImageModal(imageUrl) {
      this.showImageViewer = true;
      this.selectedImage = imageUrl;
    },

    toggleSelectAllImages() {
      if (this.selectAllImages) {
        this.selectedImages = this.media.map((image) => image.file);
      } else {
        this.selectedImages = [];
      }

      this.media.forEach((image) => {
        image.selected = this.selectAllImages;
      });
    },

    toggleSelectAllFiles() {
      if (this.selectAllFiles) {
        this.selectedFiles = this.files.map((file) => file.file);
      } else {
        this.selectedFiles = [];
      }

      this.files.forEach((file) => {
        file.selected = this.selectAllFiles;
      });
    },

    toggleSelectAllAudios() {
      if (this.selectAllAudios) {
        this.selectedAudios = this.media.map((audio) => audio.file);
      } else {
        this.selectedAudios = [];
      }

      this.audio.forEach((audio) => {
        audio.selected = this.selectAllAudios;
      });
    },

    updateSelectedImages(file) {
      if (file.selected) {
        this.selectedImages.push(file.file);
      } else {
        const index = this.selectedImages.indexOf(file.file);
        if (index !== -1) {
          this.selectedImages.splice(index, 1);
        }
      }
    },

    updateSelectedAudios(audio) {
      if (audio.selected) {
        this.selectedAudios.push(audio.file);
      } else {
        const index = this.selectedAudios.indexOf(audio.file);
        if (index !== -1) {
          this.selectedAudios.splice(index, 1);
        }
      }
    },

    updateSelectedFiles(file) {
      if (file.selected) {
        this.selectedFiles.push(file.file);
      } else {
        const index = this.selectedFiles.indexOf(file.file);
        if (index !== -1) {
          this.selectedFiles.splice(index, 1);
        }
      }
    },

    downloadSelectedImages() {
      this.selectedImages.forEach((imageUrl) => {
        const link = document.createElement("a");
        link.href = imageUrl;
        link.target = "_blank";
        link.style.display = "none"; // Oculta el enlace
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },

    downloadSelectedAudios() {
      this.selectedAudios.forEach((audioUrl) => {
        const link = document.createElement("a");
        link.href = audioUrl;
        link.download = "audio_file.mp3";
        link.target = "_blank"; // You can specify the filename here
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },

    downloadFiles() {
      if (this.activeTab === 0) {
        this.selectedFiles.forEach((fileUrl) => {
          window.open(fileUrl, "_blank");
        });
      } else {
        this.selectedImages.forEach((imageUrl) => {
          window.open(imageUrl, "_blank");
        });
      }
    },

    async getFilesTicketsCustomer() {
      try {
        const params = {
          ticketCustomerId: this.fileType.ticketId,
          typeFiles: this.fileType.typeFileId,
          limitFiles: null,
        };
        const { data } = await ParticipantsServices.getFilesTicketsCustomer(
          params
        );
        // Filtra los archivos y guárdalos en el arreglo correcto
        if (this.fileType.typeFileId === 2) {
          this.files = data.File; // Pestaña "FILES"
        } else if (this.fileType.typeFileId === 4) {
          this.media = data.Image; // Pestaña "MEDIA"
        } else if (this.fileType.typeFileId === 3) {
          this.audio = data.Audio; // Pestaña "AUDIO"
        }

        // Establecer el tab activo en función de typeFileId
        if (this.fileType.typeFileId === 2) {
          this.activeTab = 0; // Abre la pestaña "FILES"
        } else if (this.fileType.typeFileId === 4) {
          this.activeTab = 1; // Abre la pestaña "MEDIA"
        } else if (this.fileType.typeFileId === 3) {
          this.activeTab = 2; // Abre la pestaña "AUDIO"
        }
      } catch (error) {
        this.showErrorSwal();
        console.log("error in getFilesTicketsCustomer from chat:", error);
      }
    },
  },
};
</script>

<style scoped>
.file-card {
  border: 1px solid #ccc;
  border-radius: 7px;
  margin: 10px;
  padding: 10px;
  text-align: center;
}
.file-image {
  max-height: 200px;
}
.file-details {
  margin-top: 10px;
}
.file-name {
  font-weight: bold;
  margin: 0;
}
.custom-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.custom-image:hover {
  filter: grayscale(50%);
  background: #ccc;
}

.selected-image {
  border: 5px solid #00d25b;
}

.selected-file {
  border: 5px solid #00d25b;
  border-radius: 1rem;
  padding: 1rem;
}

.selected-audio {
  border: 5px solid #00d25b;
  border-radius: 1rem;
  padding: 1rem;
}

.my-light {
  background: aliceblue !important;
}

.dark {
  background: darkkhaki !important;
}
</style>
