export default [
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'name',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    visible: true,
    placeholder: '--Select a module--',
  },
];
