<template>
  <div>
    <b-modal
      v-model="onControl"
      title-class="h3 text-white"
      size="lg"
      centered
      :title="getTitle"
      hide-footer
      modal-class="modal-primary"
      @hide="close"
    >
      <validation-observer ref="form">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="6">
            <b-form-group label="Creditor Name">
              <validation-provider v-slot="{ errors }" rules="customRequired">
                <vue-autosuggest
                  ref="autocomplete"
                  id="creditor"
                  v-model="dataCreditor.tempEntryCreditorName"
                  :suggestions="filteredOptions"
                  :get-suggestion-value="getSuggestionValue"
                  :input-props="{
                    id: 'autosuggest__input',
                    class: ['form-control ', errors[0]],
                    placeholder: 'Select',
                  }"
                  @closed="
                    creditor
                      ? (dataCreditor.tempEntryCreditorName =
                          dataCreditor.tempEntryCreditorName)
                      : (dataCreditor.tempEntryCreditorName = null)
                  "
                  @input="onInputChange"
                  @selected="selectHandler"
                >
                  <template slot="before-input">
                    <div class="lds-dual-ring" v-show="creditorsLoading"></div>
                  </template>
                  <template slot-scope="{ suggestion }">
                    <span class="my-suggestion-item">{{
                      suggestion.item.value
                    }}</span>
                  </template>
                </vue-autosuggest>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="6">
            <validation-provider v-slot="{ errors }" rules="customRequired">
              <b-form-group label="Type" :class="errors[0]">
                <v-select
                  v-model="dataCreditor.type"
                  :options="types"
                  :reduce="(value) => value.id"
                  :class="errors[0]"
                  label="value"
                  transition=""
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="6" xs="6" sm="6" md="6" lg="6">
            <validation-provider v-slot="{ errors }" rules="customRequired">
              <b-form-group :class="errors[0]" label="Months Behind">
                <b-form-input
                  v-model="dataCreditor.month"
                  v-mask="'######'"
                  :class="errors[0]"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="3" v-if="isReposition && !isPreVent" class="mt-1">
            <b-form-group label="Has Car?">
              <div>
                <label style="color: #e26969; margin-right: 1rem">NO</label>
                <label class="switch">
                  <b-form-checkbox
                    v-model="dataCreditor.hasCar"
                    switch
                    type="checkbox"
                    value="1"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="3"
            class="mt-1"
            v-if="isReposition && !dataCreditor.hasCar && !isPreVent"
          >
            <b-form-group label="Was Auctioned?">
              <div>
                <label style="color: #e26969; margin-right: 1rem">NO</label>
                <label class="switch">
                  <b-form-checkbox
                    v-model="dataCreditor.isAuctioned"
                    switch
                    type="checkbox"
                    value="1"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="6" v-if="isCollection && !isPreVent" class="mt-1">
            <b-form-group label="Do you know the collection?">
              <div>
                <label style="color: #e26969; margin-right: 1rem">NO</label>
                <label class="switch">
                  <b-form-checkbox
                    v-model="dataCreditor.hasCollectionKnowledge"
                    switch
                    type="checkbox"
                    value="1"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="6" v-if="isCT && !isPreVent" class="mt-1">
            <b-form-group label="Judgment Exist?">
              <div>
                <label style="color: #e26969; margin-right: 1rem">NO</label>
                <label class="switch">
                  <b-form-checkbox
                    v-model="dataCreditor.judgmentExists"
                    switch
                    type="checkbox"
                    value="1"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="6" v-if="isPersonalLoan && !isPreVent" class="mt-1">
            <b-form-group label="Has Warranty?">
              <div>
                <label style="color: #e26969; margin-right: 1rem">NO</label>
                <label class="switch">
                  <b-form-checkbox
                    v-model="dataCreditor.hasWarranty"
                    switch
                    type="checkbox"
                    value="1"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="6"
            v-if="isCreditCard && isMonthsZero && !isPreVent "
          >
            <b-form-group label="Automatic Payments">
              <div>
                <label style="color: #e26969; margin-right: 1rem">OFF</label>
                <label class="switch">
                  <b-form-checkbox
                    v-model="dataCreditor.automaticPayments"
                    switch
                    type="checkbox"
                    value="1"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">ON</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="6"
            v-if="isPersonalLoan && dataCreditor.hasWarranty"
          >
            <validation-provider rules="customRequired" v-slot="{ errors }">
              <b-form-group label="Motive" :class="errors[0]">
                <v-select
                  v-model="dataCreditor.personalLoanMotive"
                  :options="personalLoanMotives"
                  :reduce="(value) => value.id"
                  label="value"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="6"
            v-if="isCT && dataCreditor.judgmentExists"
          >
            <validation-provider rules="customRequired" v-slot="{ errors }">
              <b-form-group :class="errors[0]" label="Judgment Type">
                <v-select
                  v-model="dataCreditor.judgmentOption"
                  :options="judgmentOptions"
                  :reduce="(value) => value.id"
                  label="value"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="6"
            v-if="isCollection && dataCreditor.hasCollectionKnowledge"
          >
            <b-form-group label="Collection Name">
              <validation-provider v-slot="{ errors }" rules="customRequired">
                <vue-autosuggest
                  ref="autocomplete2"
                  id="collectionName"
                  v-model="dataCreditor.tempEntryCollectionName"
                  :suggestions="filteredOptions2"
                  :get-suggestion-value="getSuggestionValue2"
                  :input-props="{
                    id: 'autosuggest__input',
                    class: ['form-control ', errors[0]],
                    placeholder: 'Select',
                  }"
                  @input="onInputChange2"
                  @selected="selectHandler2"
                >
                  <template slot="before-input">
                    <div class="lds-dual-ring" v-show="creditorsLoading2"></div>
                  </template>
                  <template slot-scope="{ suggestion }">
                    <span class="my-suggestion-item">{{
                      suggestion.item.value
                    }}</span>
                  </template>
                </vue-autosuggest>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="12" lg="6" v-if="isOthers && !isPreVent">
            <b-form-group label="New Debt Type">
              <validation-provider
                v-slot="{ errors }"
                rules="customRequired|max:50"
              >
                <b-form-input
                  v-model="dataCreditor.newDebtType"
                  maxlength="50"
                  :class="errors[0]"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="6" v-if="isCreditCard && !fromAppointment">
            <b-form-group label="Credit Card Number">
              <b-row>
                <!-- Input Number 1 -->
                <b-col cols="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="cardnumber1"
                    rules="customRequired|min:4|max:4"
                  >
                    <b-form-input
                      maxlength="4"
                      v-model="card.number1"
                      :class="errors[0]"
                      ref="input-1"
                      @input="activeFocus(1, 4)"
                      @blur="fillWithAstreisks(1)"
                    />
                  </validation-provider>
                </b-col>

                <!-- Input Number 2 -->
                <b-col cols="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="cardnumber2"
                    rules="customRequired|min:4|max:4"
                  >
                    <b-form-input
                      maxlength="4"
                      v-model="card.number2"
                      :class="errors[0]"
                      ref="input-2"
                      @input="activeFocus(2, 4)"
                      @blur="fillWithAstreisks(2)"
                    />
                  </validation-provider>
                </b-col>

                <!-- Input Number 3 -->
                <b-col cols="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="cardnumber3"
                    rules="customRequired|min:4|max:4"
                  >
                    <b-form-input
                      v-model="card.number3"
                      maxlength="4"
                      :class="errors[0]"
                      ref="input-3"
                      @input="activeFocus(3, 4)"
                      @blur="fillWithAstreisks(3)"
                    />
                  </validation-provider>
                </b-col>

                <!-- Input Number 4 -->
                <b-col cols="3">
                  <validation-provider
                    v-slot="{ errors }"
                    name="cardnumber4"
                    :rules="'customRequired|max:' + maxCardLength"
                  >
                    <b-form-input
                      v-model="card.number4"
                      :maxlength="maxCardLength"
                      :class="errors[0]"
                      ref="input-4"
                      @input="activeFocus(4, 4)"
                      @blur="fillWithAstreisks(4)"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col v-if="!isCreditCard || isPreVent" cols="12" xs="12" sm="12" md="12" lg="6">
            <b-form-group label="Account Number">
                <input
                  id="account"
                  v-model="dataCreditor.account"
                  v-mask="'##############################'"
                  class="form-control"
                  type="text"
                />
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="12" lg="3"
            ><validation-provider
              v-slot="{ errors }"
              rules="customRequired|money-1"
            >
              <b-form-group label="Total Balance">
                <b-input-group prepend="$">
                  <money
                    v-model="dataCreditor.balance"
                    v-bind="vMoney"
                    class="form-control"
                    :style="
                      errors[0] && validateMoney ? 'border-color:red' : ''
                    "
                  />
                </b-input-group>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="12" lg="3">
            <b-form-group label="Monthly Current Payment">
              <validation-provider
                v-slot="{ errors }"
                rules="customRequired|money-1"
              >
                <b-input-group prepend="$">
                  <money
                    v-model="dataCreditor.monthly"
                    v-bind="vMoney"
                    class="form-control"
                    :style="
                      errors[0] && validateMoney ? 'border-color:red' : ''
                    "
                  />
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" xs="12" sm="12" md="12" lg="3">
            <b-form-group label="Interest Rate">
              <validation-provider v-slot="{ errors }" rules="customRequired">
                <b-input-group :prepend-class="errors[0]" append="%">
                  <b-form-input
                    v-model="dataCreditor.interes"
                    v-mask="'##.##'"
                    :class="errors[0]"
                  />
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="3" class="mt-1" v-if="!fromAppointment">
            <b-form-group label="Unsecured Type">
              <validation-provider>
                <div>
                  <label style="color: #e26969; margin-right: 1rem">OFF</label>
                  <label class="switch">
                    <b-form-checkbox
                      v-model="dataCreditor.unsecured"
                      switch
                      type="checkbox"
                      value="1"
                      disabled
                    />
                  </label>
                  <label style="color: #56bec4; margin-left: 0.6rem">ON</label>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row class="mt-2">
          <b-col class="d-flex align-items-center justify-content-end mb-1">
            <b-button
              variant="outline-danger"
              class="rounded"
              style="border-width: 1px; margin-right: 20px"
              @click="close"
            >
              <feather-icon icon="XIcon" />
              <span> CANCEL </span>
            </b-button>

            <b-button
              variant="outline-success"
              class="rounded"
              style="border-width: 1px; margin-right: 7px"
              @click="savecreditor"
            >
              <span>
                <feather-icon icon="PlusIcon" />
                {{ state == 1 ? "ADD" : "UPDATE" }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import BillsSelect from "@/views/crm/views/sales-made/components/modals/services/debt-solution/components/BillsSelect.vue";
import { VueAutosuggest } from "vue-autosuggest";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import { ValidationProvider, extend } from "vee-validate";
import { amgApi } from "@/service/axios.js";
import { required } from "vee-validate/dist/rules";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

extend("customRequired", {
  ...required,
  message: "is-invalid",
});

export default {
  name: "modalCreditorNewDs",
  components: {
    VueAutosuggest,
    vSelect,
    ValidationProvider,
    BillsSelect,
  },
  props: {
    idevent: {
      type: Number,
      default: 0,
    },
    state: {
      type: Number,
      default: 0,
    },
    idcreditor: {
      type: [String, Number],
      default: '',
    },
    accountProg: {
      type: [String, Number],
      default: '',
    },
    type_in: {
      type: Number,
      default: 0,
    },
    client_id: {
      type: [String, Number],
      default: '',
    },
    trueRetainer: {
      type: Boolean,
      default: false,
    },
    dataCred: {
      type: Object,
      default: () => {},
    },
    lead_id: {
      type: [String, Number],
      default: '',
    },
    fromAppointment: {
      type: Boolean,
      default: false,
    },
    isPreVent:{
      type: Boolean,
      default: false,
    },
    idEventAccountApproved: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      //tempEntryCreditorName: "",
      //tempEntryCollectionName: "",
      personalLoanMotives: [
        { id: 1, value: "Home" },
        { id: 2, value: "Car" },
        { id: 3, value: "Others" },
      ],
      judgmentOptions: [
        { id: 1, value: "WG" },
        { id: 2, value: "Lien" },
        { id: 3, value: "Bank Levy" },
        { id: 4, value: "None" },
      ],
      //newDebtType: "",
      //judgmentOption: "",
      //personalLoanMotive: "",
      extraInput: "",
      collectionName: "",
      //hasCollectionKnowledge: false,
      //judgmentExists: false,
      //hasWarranty: false,
      //hasCar: false,
      //isAuctioned: false,
      card: {
        number1: "",
        number2: "",
        number3: "",
        number4: "",
      },
      maxCardLength: 1,
      filteredOptions: [],
      filteredOptions2: [],
      creditorsLoading: false,
      creditorsLoading2: false,
      collectionNameId: null,
      types: [],
      creditor: "",
      //account: "",
      //balance: "",
      //monthly: "",
      //possibleMonthly: "",
      month: null,
      //interes: 0,
      //type: null,
      bills: null,
      //unsecured: 1,
      //automaticPayments: false,
      creditors: [],
      valuecreditor: "",
      errorCredit: false,
      errorAccount: false,
      errorBalance: false,
      errorPayment: false,
      errorMonths: false,
      errorInteres: false,
      errorType: false,
      errorUnsecured: false,
      retainerFee: this.trueRetainer == 1,
      vMoney: {
        maxlength: 10,
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false,
      },
      useWatch: true,
      validateMoney: false,
      creditorName: null,
      onControl: false,
      dataCreditor: {},
      cambioCard: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isMonthsZero() {
      return (
        this.dataCreditor.month == 0 &&
        this.dataCreditor.month !== null &&
        this.dataCreditor.month !== ""
      );
    },
    isCreditCard() {
      return this.dataCreditor.type == 1;
    },
    isReposition() {
      return this.dataCreditor.type == 2;
    },
    isPersonalLoan() {
      return this.dataCreditor.type == 3;
    },
    isCT() {
      return this.dataCreditor.type == 6;
    },
    isCollection() {
      return this.dataCreditor.type == 8;
    },
    isOthers() {
      return this.dataCreditor.type == 9;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    getTitle() {
      return this.state == 1 ? "Add creditor" : "Edit Creditor";
    },
  },
  watch: {

    "card.number1"(val) {
      if (this.useWatch === true) {
        let firstChar = val.charAt(0);
        // if first char starts with 4, 5 or 6 the card can be 16 digits long
        if (firstChar == 4 || firstChar == 5 || firstChar == 6) {
          this.maxCardLength = 4;
        }
        // if first char starts with 3 the card can be 15 digits long
        else if (firstChar == 3) {
          this.maxCardLength = 3;
          this.card.number4 = this.card.number3.substring(0, 3);
        }
      }
    },
  },
  async created() {
    this.onControl = true;
    await this.allTypes();
    this.dataCreditor = this.dataCred;
    if (this.state == 2) {
      if (this.dataCred.card != null) {
        this.cambioCard = false;
        this.card.number1 = this.dataCred.card.substr(0, 4);
        this.card.number2 = this.dataCred.card.substr(4, 4);
        this.card.number3 = this.dataCred.card.substr(8, 4);
        this.card.number4 = this.dataCred.card.substr(12, 4);
      }
      this.cambioCard = true;
      this.creditor = this.dataCred.creditor;
    } else {
      this.dataCreditor.interes = 0;
    }
  },
  methods: {
    fillWithAstreisks(index) {
      if (index === 1) {
        this.card.number1 = this.card.number1.padEnd(4, "*");
      }
      if (index === 2) {
        this.card.number2 = this.card.number2.padEnd(4, "*");
      }
      if (index === 3) {
        this.card.number3 = this.card.number3.padEnd(4, "*");
      }
      if (index === 4) {
        this.card.number4 = this.card.number4.padEnd(this.maxCardLength, "*");
        this.card.number1 = this.card.number1.padEnd(4, "*");
        this.card.number2 = this.card.number2.padEnd(4, "*");
        this.card.number3 = this.card.number3.padEnd(4, "*");
      }
    },

    formattedDisplay(result) {
      return result.value;
    },
    close() {
      this.$emit("close", false);
    },
    async allTypes() {
      try {
        const response = await amgApi.get(
          "/sales-made/debt-solution/get-debts-types"
        );
        if (response.status === 200) {
          this.types = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async savecreditor() {
      const unsecuredToSend = this.dataCreditor.unsecured === 1;
      this.validateMoney = true;
      // Validate Money
      const success = await this.$refs.form.validate();
      if (success) {
        this.addPreloader();
        const route =
          this.state == 1
            ? "/sales-made/debt-solution/save-credits-debt-solution"
            : "/sale/update-credit-list-ds";
        let params = {
          event: this.idEventAccountApproved != null ? this.idEventAccountApproved : this.idevent,
          accountProg: this.accountProg,
          creditor: this.creditor,
          // account: this.dataCreditor.account,
          account:
            this.dataCreditor.type == 1 && this.fromAppointment === false
              ? this.card.number1 +
                this.card.number2 +
                this.card.number3 +
                this.card.number4
              : this.dataCreditor.account,
          balance: this.dataCreditor.balance,
          monthly: this.dataCreditor.monthly,
          possibleMonthly: this.dataCreditor.possibleMonthly,
          month: this.dataCreditor.month,
          interes: this.dataCreditor.interes,
          type: this.dataCreditor.type,
          unsecured: unsecuredToSend,
          type_in: this.type_in,
          user: this.currentUser.user_id,
          client_id: this.client_id,
          retainerFee: this.retainerFee == true ? 1 : 0,
          id_account: this.$route.params.idClient,
          card_number:
            this.dataCreditor.type == 1
              ? this.card.number1 +
                this.card.number2 +
                this.card.number3 +
                this.card.number4
              : null,
        };
        if (this.state == 2) {
          params.id = this.dataCreditor.id;
        }
        params.has_automatic_payments =
          this.isCreditCard && this.isMonthsZero
            ? this.dataCreditor.automaticPayments
            : null;
        params.has_car = this.isReposition ? this.dataCreditor.hasCar : null;
        params.was_auctioned =
          this.isReposition && !this.dataCreditor.hasCar
            ? this.dataCreditor.isAuctioned
            : null;
        params.has_warranty = this.isPersonalLoan
          ? this.dataCreditor.hasWarranty
          : null;
        params.motives =
          this.isPersonalLoan && this.dataCreditor.hasWarranty
            ? this.dataCreditor.personalLoanMotive
            : null;
        params.judgment_exists = this.isCT
          ? this.dataCreditor.judgmentExists
          : null;
        params.judgment_type =
          this.isCT && this.dataCreditor.judgmentExists
            ? this.dataCreditor.judgmentOption
            : null;
        params.has_collection_knowledge = this.isCollection
          ? this.dataCreditor.hasCollectionKnowledge
          : null;
        params.collection_name =
          this.isCollection && this.dataCreditor.hasCollectionKnowledge
            ? this.collectionName
            : null;
        params.collection_name_id =
          this.isCollection && this.dataCreditor.hasCollectionKnowledge
            ? this.collectionNameId
            : null;
        params.new_debt_type = this.isOthers
          ? this.dataCreditor.newDebtType
          : null;
        params.bills = this.bills;
        const response = await amgApi.post(route, params);
        if (response.status === 200) {
          this.$emit("reload");
          const v_text =
            this.state == 1
              ? "New creditor registered successfully"
              : " creditor updated successfully";
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "CheckIcon",
              variant: "success",
              text: v_text,
            },
          });
        }
        this.removePreloader();
      }
    },
    onInputChange(text) {
      this.creditor = null;
      this.creditorsLoading = true;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        this.creditorsLoading = false;
        return;
      }
      amgApi
        .post(`/sales-made/debt-solution/search-creditors?q=${text}`)
        .then((response) => {
          if (response.status === 200) {
            this.filteredOptions = [{ data: [...response.data] }];
            this.creditorsLoading = false;
          }
        });
    },
    onInputChange2(text) {
      this.collectionName = null;
      this.creditorsLoading2 = true;
      if (text === "" || text === undefined) {
        this.filteredOptions2 = [];
        this.creditorsLoading2 = false;
        return;
      }
      amgApi
        .post(`/sales-made/debt-solution/search-creditors?q=${text}`)
        .then((response) => {
          if (response.status === 200) {
            this.filteredOptions2 = [{ data: [...response.data] }];
            this.creditorsLoading2 = false;
          }
        });
    },
    selectHandler(value) {
      this.creditor = value.item.id;
    },
    selectHandler2(value) {
      this.collectionName = value.item.value;
      this.collectionNameId = value.item.id;
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.value;
    },
    getSuggestionValue2(suggestion) {
      return suggestion.item.value;
    },

    activeFocus: function (index, max) {
      let inputValue = this.$refs?.[`input-${index}`];
      if (inputValue.value.length === max - 1) {
        const nextElement = this.$refs?.[`input-${index + 1}`];
        if (nextElement) nextElement.focus();
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
.vue-autosuggest-error > div > input {
  border: 1px solid red !important;
}
.bri-select .vs__dropdown-toggle {
  border: 1px solid red !important;
}

.lds-dual-ring {
  position: absolute;
  right: 3rem;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  margin: 10px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
