<template>
  <b-col>
    <b-row class="justify-content-center">
      <template v-if="!item.editSeller">
        <span
          class="text-info text-truncate col-9 p-0"
          v-b-tooltip.hover.top="`Seller`"
        >
          {{ item.seller }}
        </span>
        <!-- <p v-if="item.commission" class="p-0 m-0 col-3 w-100">
          <small
            v-if="
              [1, 2, 17].includes(+currentUser.role_id) ||
              (item.user_id == currentUser.user_id && isSellerC)
            "
            class="text-primary font-weight-bold"
          >
            $ {{ item.commission[1].commission }}</small
          >
        </p> -->
        <template
          v-if="
            [1, 3, 5, 14, 15, 16].includes(+item.status) &&
            ([1, 2, 11, 14, 17].includes(+currentUser.role_id) ||
              isCoordinator) &&
            (moduleId === item.from_module ||
              (moduleId !== 26 && item.from_module !== 26))
          "
        >
          <div class="d-flex" style="gap: 5px">
            <feather-icon
              icon="Edit3Icon"
              class="cursor-pointer"
              @click="item.editSeller = true"
              size="18"
            />
            <feather-icon
              class="cursor-pointer"
              icon="ListIcon"
              @click="openTrackingCapturedByModal(2)"
              size="18"
            />
          </div>
        </template>
      </template>

      <b-input-group v-else size="sm" style="width: 180px">
        <template #prepend>
          <b-button
            @click="
              item.editSeller = false;
              item.sellerNew = item.seller;
            "
            variant="outline-danger"
            ><feather-icon icon="XSquareIcon" class="cursor-pointer"
          /></b-button>
        </template>

        <b-form-select
          v-model="item.sellerNew"
          text-field="label"
          value-field="id"
          :options="sellers"
          class="font-small-1"
          size="sm"
        />

        <template #append>
          <b-button
            variant="outline-primary"
            @click="saveNewSeller(item.seller, item.sellerNew, item.id, item)"
            ><feather-icon class="cursor-pointer" icon="SaveIcon"
          /></b-button>
        </template>
      </b-input-group>
    </b-row>
    <b-row class="justify-content-center mt-1">
      <template v-if="!item.editCaptured">
        <span
          class="text-warning text-truncate col-9 p-0"
          v-b-tooltip.hover.top="`Catcher`"
        >
          {{ item.captured }}
        </span>
        <!-- <p v-if="item.commission" class="p-0 m-0 col-3 w-100">
          <small
            v-if="
              [1, 2, 17].includes(+currentUser.role_id) ||
              (item.user_id == currentUser.user_id && isSellerC)
            "
            class="text-primary font-weight-bold"
          >
            $ {{ item.commission[0].commission }}</small
          >
        </p> -->
        <template
          v-if="
            [1, 3, 5, 14, 15, 16].includes(+item.status) &&
            ([1, 2, 11, 14, 17].includes(+currentUser.role_id) ||
              isCoordinator) &&
            (moduleId === item.from_module ||
              (moduleId !== 26 && item.from_module !== 26))
          "
        >
          <div class="d-flex" style="gap: 5px">
            <feather-icon
              icon="Edit3Icon"
              class="cursor-pointer"
              @click="
                item.editCaptured = true;
                item.editCaptured = item.captured;
              "
              size="18"
            />
            <feather-icon
              class="cursor-pointer"
              icon="ListIcon"
              @click="openTrackingCapturedByModal(1)"
              size="18"
            />
          </div>
        </template>
      </template>

      <b-input-group v-else size="sm" style="width: 180px">
        <template #prepend>
          <b-button @click="item.editCaptured = false" variant="outline-danger"
            ><feather-icon icon="XSquareIcon" class="cursor-pointer"
          /></b-button>
        </template>

        <b-form-select
          v-model="item.capturedNew"
          text-field="label"
          value-field="id"
          :options="captured"
          class="font-small-1"
          size="sm"
        />

        <template #append>
          <b-button variant="outline-primary" @click="saveNewCaptured()"
            ><feather-icon class="cursor-pointer" icon="SaveIcon"
          /></b-button>
        </template>
      </b-input-group>
    </b-row>
    <tracking-captured-by-modal
      v-if="modal.captuerd_by_tracking"
      :modal="modal"
      :captured-by-tracking="capturedByTracking"
    />
  </b-col>
</template>

<script>
import { mapGetters } from "vuex";
import TrackingCapturedByModal from "@/views/crm/views/sales-made/components/modals/TrackingCapturedByModal.vue";
import CrmService from "@/views/crm/services/crm.service";
export default {
  components: {
    TrackingCapturedByModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    sellers: {
      type: Array,
      required: true,
    },
    captured: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      capturedByTracking: {},
      modal: {
        captuerd_by_tracking: false,
      },
    };
  },
  methods: {
    async saveNewCaptured() {
      const [capturedNew2] = this.captured.filter(
        (val) => val.id === this.item.capturedNew
      );
      if (this.item.captured === capturedNew2.label) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "You can't select the same captured"
        );
      } else {
        this.$store.commit("app/SET_LOADING", true);
        try {
          const response = await CrmService.saveNewCaptured({
            capt: capturedNew2.id,
            id: this.item.id,
            user: this.currentUser.user_id,
          });
          if (response.status === 200) {
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "It was updated successfully"
            );
          } else return;
          this.item.capturedNew = capturedNew2.id;
          this.item.captured_id = capturedNew2.id;
          this.item.captured = capturedNew2.label;
          this.item.editCaptured = false;
          this.removePreloader();
        } catch (error) {
          this.showToast("danger", "top-right", "Error", "XIcon", error);
          this.removePreloader();
        }
      }
    },
    async saveNewSeller() {
      const [sellerNew2] = this.sellers.filter(
        (val) => val.id === this.item.sellerNew
      );
      if (this.item.seller === sellerNew2.label) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "You can't select the same captured"
        );
      } else {
        this.addPreloader();
        try {
          const response = await CrmService.saveNewSeller({
            sel: sellerNew2.id,
            id: this.item.id,
            user: this.currentUser.user_id,
            lead_client_derivation_id: this.item?.lead_client_derivation_id,
          });
          if (response.status === 200) {
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "It was updated successfully"
            );
          } else return;
          this.item.sellerNew = sellerNew2.id;
          this.item.seller_id = sellerNew2.id;
          this.item.seller = sellerNew2.label;
          this.item.editSeller = false;
          this.removePreloader();
        } catch (error) {
          this.showToast("danger", "top-right", "Error", "XIcon", error);
          this.removePreloader();
        }
      }
    },
    openTrackingCapturedByModal(type) {
      this.capturedByTracking.client = this.item.client;
      this.capturedByTracking.program = this.item.program;
      this.capturedByTracking.id = this.item.id;
      this.capturedByTracking.type = type;
      if (type === 1) this.capturedByTracking.tittle = "CAPTURED BY";
      else if (type === 2) this.capturedByTracking.tittle = "SELLER";
      else if (type === 3) this.capturedByTracking.tittle = "FEE";
      this.modal.captuerd_by_tracking = true;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>

<style></style>
