<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    header-class="p-0"
    title="Delete Credit Card"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Delete Credit Card
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="form">
      <label>Commentary</label>
      <validation-provider v-slot="{ errors }" name="comment" rules="required">
        <b-form-textarea
          id="textarea"
          v-model="commentary"
          required
          placeholder="Enter something..."
        />
        <label v-if="errors[0]" class="text-danger">
          Commentary {{ errors[0] }}
        </label>
      </validation-provider>
    </validation-observer>
    <template #modal-footer>
      <b-button variant="primary" @click="deleteCard" :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'">
        <b-spinner v-if="spinnerOn" small />
        SAVE
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import BankOfFlyersService from "@/views/commons/components/bank-of-flyers/bank-of-flyers.service";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";

export default {
  props: {
    lead_id: {
      type: Number,
      default: null,
    },
    card_id: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      ownControl: false,
      commentary: "",
      spinnerOn: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.ownControl = true;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },

    async deleteCard() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          this.spinnerOn = true;
          const params = {
            cardid: this.card_id,
            leadid: this.lead_id,
            user_id: this.currentUser.user_id,
            comment: this.commentary,
          };

          const data = await ClientsOptionsServices.deleteCardsById(params);
          if (data.status === 200) {
            this.$emit("refresh");
            this.showSuccessSwal();
            this.closeModal();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style scoped>
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
}
</style>
