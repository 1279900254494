<template>
  <b-card>
    <!-- <template #header> -->
    <b-card-title class="w-100 m-0 mb-2 px-1">
      <div class="d-block d-md-flex justify-content-between align-items-end">
        <h4 v-if="!isClientDashboard" class="font-weight-bolder w-100 border-bottom-secondary border-2 pb-1 mr-2">Credit Report</h4>
        <div class="text-right w-auto d-flex">
          <b-button
            variant="outline-primary"
            @click="showCreditReport = true"
            class="d-flex"
          >
            <feather-icon icon="FileTextIcon" class="mr-50"></feather-icon>
            HISTORY
            <span
              v-if="totalPending > 0"
              class="ml-1"
            >
              <feather-icon
                :badge="totalPending > 99 ? '99+' : totalPending"
                badge-classes="badge-danger"
              />
            </span>
          </b-button>
          <b-dropdown v-if="!isClientDashboard || !readOnly" class="ml-1 rounded-lg" right text="Send CR" variant="primary" :disabled="isResponsibleLead">
            <template #button-content>
              <!-- <feather-icon icon="FileTextIcon" /> -->
              Request CR
            </template>
            <b-dropdown-item @click="openModalCreateNcr('MANUAL')">
              <feather-icon icon="FileTextIcon" class="mr-50"></feather-icon>
              Manual
            </b-dropdown-item>
            <b-dropdown-item @click="openModalCreateNcr('AUTOMATIC')">
              <tabler-icon icon="DeviceLaptopIcon" class="mr-50"></tabler-icon>
              Automatic
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-card-title>
    <!-- </template> -->
    <b-card-body v-if="analyzedBySoft" class="px-1">
      <div v-if="ncrAvailable">
        <b-row class="justify-content-center mb-2 px-1">
          <b-col cols="12" lg="12" xl="7" class="bg-md-danger">
            <b-row class="h-100">
              <b-col
                v-if="score.transunion !== ''"
                cols="12"
                sm="4"
                xl="4"
                class="text-center pl-xl-0 custom-min-height"
              >
                <div
                  class="show-lead-score-cr w-100 h-100 shadow-lg rounded d-flex flex-column justify-content-center align-items-center border-light"
                  :style="`color: ${colorScoreTransunion(
                    score.transunion
                  )};display: inline-flex; justify-content: center; min-width: 63px;`"
                >
                  <img
                    :src="require('@/assets/images/icons/transunion-logo.png')"
                    width="100px"
                    height="50px"
                  />
                  <strong>{{
                    transunionCharAt == "N" ? "-" : score.transunion
                  }}</strong>
                </div>
              </b-col>
              <b-col
                v-if="score.experian !== ''"
                cols="12"
                sm="4"
                xl="4"
                class="text-center px-xl-0 my-1 my-sm-0 custom-min-height"
              >
                <div
                  class="show-lead-score-cr w-100 h-100 shadow-lg rounded d-flex flex-column justify-content-center align-items-center border-light"
                  :style="`color: ${colorScoreTransunion(
                    score.experian
                  )};display: inline-flex; justify-content: center; min-width: 63px;`"
                >
                  <img
                    :src="require('@/assets/images/icons/experian-logo.png')"
                    width="100px"
                    height="50px"
                  />
                  <strong>{{
                    experianCharAt == "N" ? "-" : score.experian
                  }}</strong>
                </div>
              </b-col>
              <b-col
                v-if="score.equifax !== ''"
                cols="12"
                sm="4"
                xl="4"
                class="text-center pr-xl-0 custom-min-height"
              >
                <div
                  class="show-lead-score-cr w-100 h-100 shadow-lg rounded d-flex flex-column justify-content-center align-items-center border-light"
                  :style="`color: ${colorScoreTransunion(
                    score.equifax
                  )};display: inline-flex; justify-content: center; min-width: 63px;`"
                >
                  <img
                    :src="require('@/assets/images/icons/equifax-logo.png')"
                    width="100px"
                    height="30px"
                  />
                  <strong style="transform: translateY(5px)">{{
                    equifaxCharAt == "N" ? "-" : score.equifax
                  }}</strong>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" lg="12" xl="5" class="mt-3 mt-xl-0">
            <!-- DETAIL OF REQUEST NCR -->
            <b-row>
              <b-col
                cols="12"
                class="d-flex justify-content-center align-items-center"
              >
                <div class="w-auto h-auto">
                  <div class="d-flex">
                    <p class="font-weight-bolder custom-width">Requested by:</p>
                    <p class="ml-1">
                      {{ ncrRequestData.requested_by }} ({{
                        ncrRequestData.requested_at | myGlobal
                      }}
                      - {{ ncrRequestData.requested_at | myTime }})
                    </p>
                  </div>
                  <div class="d-flex">
                    <p class="font-weight-bolder custom-width">Obtained by:</p>
                    <p class="ml-1">
                      {{ ncrRequestData.obtenied_by }} ({{
                        ncrRequestData.obtenied_at | myGlobal
                      }}
                      - {{ ncrRequestData.obtenied_at | myTime }})
                    </p>
                  </div>
                  <div class="d-flex">
                    <p class="font-weight-bolder custom-width">Provider:</p>
                    <p class="ml-1">{{ ncrRequestData.provider }}</p>
                  </div>
                  <div class="d-flex">
                    <p class="font-weight-bolder custom-width">Show CR: :</p>
                    <feather-icon
                      icon="FileTextIcon"
                      class="cursor-pointer text-danger ml-1 hover-me"
                      size="18"
                      @click="showFile()"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!--TABLE OF RESULTS REQUEST NCR-->
        <accounts-remove-settlement
          class="mt-4"
          :leadId="lead.id"
          :ncrRequestId="ncrRequestData.ncr_request_id"
          :scoreId="score.score_id"
          :editMode="false"
          :openBySeller="openBySeller"
        />
      </div>
      <div v-else-if="isLoading">
        <b-skeleton-wrapper :loading="isLoading">
          <template #loading>
            <b-card>
              <b-skeleton width="90%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
              <b-skeleton width="50%"></b-skeleton>
              <b-skeleton width="30%"></b-skeleton>
              <b-skeleton width="10%"></b-skeleton>
              <b-skeleton width="30%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="75%"></b-skeleton>
              <b-skeleton width="80%"></b-skeleton>
              <b-skeleton width="95%"></b-skeleton>
              <b-skeleton width="15%"></b-skeleton>
              <b-skeleton width="30%"></b-skeleton>
            </b-card>
          </template>
        </b-skeleton-wrapper>
      </div>
      <div
        v-else
        class="text-center custom-class d-flex justify-content-center align-items-center flex-column"
      >
        <img
          class="custom-img my-3"
          :src="
            !!ncrRequestData['analyzed_by_soft']
              ? require('@/assets/images/accounts/report-analizing.svg')
              : require('@/assets/images/accounts/no-report.svg')
          "
        />
        <span
          class="mt-2 custom-message"
          :class="
            !!ncrRequestData['analyzed_by_soft']
              ? 'text-warning'
              : 'text-secondary'
          "
          style="font-size: 18px"
          >{{ message }}</span
        >
      </div>
    </b-card-body>
    <div
      v-else
      class="text-center custom-class d-flex justify-content-center align-items-center position-relative flex-column"
    >
      <img
        class="custom-img my-3"
        :src="
          !!ncrRequestData['analyzed_by_soft']
            ? require('@/assets/images/accounts/report-analizing.svg')
            : require('@/assets/images/accounts/no-report.svg')
        "
      />
      <span
        class="mt-2 custom-message"
        :class="
          !!ncrRequestData['analyzed_by_soft']
            ? 'text-warning'
            : 'text-secondary'
        "
        style="font-size: 18px"
        >{{ message }}</span
      >
    </div>

    <!-- modal REQUEST CR CREATE -->
    <b-modal
      v-model="isModalRequestCR"
      ok-only
      title-class="h2 text-white"
      modal-class="modal-primary"
      centered
      size="lg"
      hide-footer
      @hidden="resetModalRequestCR"
    >
      <template #modal-header="{ close }">
        <h5 class="modal-title h2 text-white">Request CR</h5>

        <button type="button" aria-label="Close" class="close" @click="close">
          ×
        </button>
      </template>
    </b-modal>
    <modal-create-ncr-request
      v-if="modalCreateNcr"
      :item="ncrParams"
      :ncrType="selectedNcr"
      :isLeadDashboard="true"
      @close="closeModalCreateNcr"
      @reload="refreshTable"
    />
    <DetailClassification
      v-if="showDetail"
      @close="showDetail = false"
      :score_id="score.score_id"
      :nameLead="lead.lead_name"
    />
    <credit-report-modal
      v-if="showCreditReport"
      :lead="lead"
      @closeModal="showCreditReport = false"
    />
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Ripple from "vue-ripple-directive";

import CardLeadCreditReportObtained from "@/views/crm/views/Lead/lead-module/dashboard/CardLeadCreditReportObtained.vue";
import CardLeadCreditReportPending from "@/views/crm/views/Lead/lead-module/dashboard/CardLeadCreditReportPending.vue";
import ModalCreateNcrRequest from "@/views/commons/components/request-ncr/views/modals/ModalCreateNcrRequest.vue";
import NcrRequestLead from "@/views/crm/views/Lead/lead-module/dashboard/NcrRequestLead.vue";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";
import DetailClassification from "@/views/crm/views/Lead/lead-module/dashboard/modal/DetailClassification.vue";
import AccountsRemoveSettlement from "@/views/commons/components/request-ncr/views/components/AccountsRemoveSettlement.vue";
import CreditReportModal from "@/views/crm/views/Lead/lead-module/dashboard/modal/CreditReportModal.vue";

export default {
  components: {
    CardLeadCreditReportObtained,
    CardLeadCreditReportPending,
    ModalCreateNcrRequest,
    NcrRequestLead,
    DetailClassification,
    AccountsRemoveSettlement,
    CreditReportModal,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      cPendingNcrByLead: "RequestNcrStore/G_PENDING_BY_LEAD",
      cPendingNcrByClient: "RequestNcrStore/G_PENDING_BY_CLIENT",
    }),
    ncrParams() {
      const { user_id, role_id } = this.currentUser;
      return {
        lead_id: this.lead.id,
        fullname: this.lead.lead_name,
        nickname: this.lead.nickname,
        client_account_id: this.lead.client_account_id,
        account: this.lead.account,
        user_id,
        role_id,
        type: "LEAD",
        used_cards: JSON.stringify([]),
      };
    },
    totalPending(){
      return this.currentUser.modul_id == 2 ? this.cPendingNcrByLead : this.cPendingNcrByClient
    },
    transunionCharAt() {
      return this.score.transunion?.charAt(0);
    },
    experianCharAt() {
      return this.score.experian?.charAt(0);
    },
    equifaxCharAt() {
      return this.score.equifax?.charAt(0);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    colorAvg() {
      return this.average > 50
        ? "light-success"
        : this.average > 20
        ? "light-warning"
        : "light-danger";
    },
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
    leadId() {
      return this.$route.params.id
        ? parseInt(this.$route.params.id)
        : this.lead.id;
    },
  },
  async created() {
    await new Promise((resolve) => {
      this.countCreditReportPendings();
      if (this.validateIfJson(this.lead.score)) {
        this.lead.score = JSON.parse(this.lead.score);
      }
      if (this.lead.score && this.lead.score.length) {
        this.score.equifax = this.lead.score[0].equifax;
        this.score.experian = this.lead.score[0].experian;
        this.score.transunion = this.lead.score[0].transunion;
        this.score.score_id = this.lead.score[0].score_id;
      }
      this.getLastNcr();
      this.getSuggestProgram();
      resolve();
    });
  },
  async mounted(){
    if(this.currentUser.modul_id == 2){
      await this.A_REQUEST_NCR_COUNTERS_BY_LEAD(this.leadId);
    }else{
      await this.A_GET_COUNTERS_BY_CLIENT(this.lead.client_account_id);
    }
  },
  directives: { Ripple },
  props: {
    lead: {
      type: Object,
      required: true,
    },
    openBySeller: {
      type: Boolean,
      default: false,
    },
    isClientDashboard: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      countData: 0,
      keyModalRequestCR: 0,
      score: {
        equifax: "",
        experian: "",
        transunion: "",
        score_id: "",
      },
      isTabPendingActive: false,
      requestCr: {
        type_card: null,
        send_cr: null,
        documents: new Object(),
        document: "",
      },
      isModalRequestCR: false,
      selectedNcr: "",
      modalCreateNcr: false,
      showDetail: false,
      average: 0,
      ncrAvailable: false,
      ncrRequestData: {},
      showCreditReport: false,
      analyzedBySoft: true,
      message: "The client has not a credit report",
      isLoading: true,
    };
  },
  methods: {
    ...mapActions({
      A_COUNT_CREDIT_REPORT_PENDINGS:
        "CrmCreditReportStore/A_COUNT_CREDIT_REPORT_PENDINGS",
      A_GET_LEAD_DOCUMENT: "CrmLeadStore/A_GET_LEAD_DOCUMENT",
      A_REQUEST_NCR_COUNTERS_BY_LEAD:
        "RequestNcrStore/A_REQUEST_NCR_COUNTERS_BY_LEAD",
      A_GET_COUNTERS_BY_CLIENT: "RequestNcrStore/A_GET_COUNTERS_BY_CLIENT",
    }),
    async getLastNcr() {
      try {
        this.isLoading = true;
        const { data } = await NcrRequestService.getLastNcr({
          lead_id: this.lead.client_account_id == null ? this.lead.id : null,
          client_id: this.lead.client_account_id == null ? null : this.lead.client_account_id,
        });
        if (data.length > 0) {
          this.ncrRequestData = data[0];
          this.analyzedBySoft = data[0].analyzed_by_soft;
          if (
            data[0].status_analysis != "APPROVED" ||
            data[0].status_analysis == null
          ) {
            this.message = "The credit report is in process";
          } else {
            this.ncrAvailable = true;
          }
          this.$emit("changeStatusCr");
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isLoading = false;
      }
    },
    resetModalRequestCR() {
      this.requestCr = {
        type_card: null,
        send_cr: null,
        documents: new Object(),
        document: "",
      };
    },
    refreshTable() {
      this.$refs["ncrRequestLead"].refreshTable();
    },
    setDataBlank(key) {
      this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`] = {
        ...this[key],
      };
    },
    resetData(key) {
      this[key] = {
        ...this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`],
      };
    },
    colorScoreTransunion(score) {
      if (score <= 659) return "#ff0707";
      if (score >= 660 && score <= 699) return "#ffc107";
      if (score >= 700 && score <= 759) return "#bfff00";
      if (score >= 760 && score <= 850) return "#0dff34";
      return "#000";
    },
    async countCreditReportPendings() {
      try {
        const response = await this.A_COUNT_CREDIT_REPORT_PENDINGS({
          id: this.$route.params.id,
          modul: this.moduleId,
        });
        if (this.isResponseSuccess(response)) {
          this.countData =
            response.data[0].countPending > 99
              ? "+99"
              : response.data[0].countPending;
        }
      } catch (error) {
        console.log("Something went wrong countCreditReportPendings", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async showFile() {
      try {
        this.addPreloader();
        let params = {
          file_pdf: this.ncrRequestData.score_file,
        };
        const { data } = await NcrRequestService.addUrlTemporaryPdfNcr(params);
        window.open(data, "_blank");
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          error.message,
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },
    async openModalRequestCr() {
      this.addPreloader();
      await this.getDocumentLead();
      this.isModalRequestCR = true;
      this.removePreloader();
    },
    async getDocumentLead() {
      try {
        const response = await this.A_GET_LEAD_DOCUMENT({
          lead_id: this.lead.id,
        });
        if (this.isResponseSuccess(response)) {
          const documents = response.data[0];
          // eslint-disable-next-line no-nested-ternary
          this.requestCr.document = documents?.ssn
            ? 1
            : documents?.itin
            ? 2
            : documents?.other
            ? 3
            : null;
          this.requestCr.documents = response.data[0];
          this.requestCr.dob = response.data[0].dob;
        }
      } catch (error) {
        console.log("Something went wrong getDocumentLead", error);
      }
    },
    openModalCreateNcr(type) {
      this.modalCreateNcr = true;
      this.selectedNcr = type;
    },
    closeModalCreateNcr() {
      this.modalCreateNcr = false;
    },
    async getSuggestProgram() {
      const data = await NcrRequestService.getClassificationNcr({
        score_id: this.score.score_id,
      });
      const accounts = data.filter((item) => item.type === "ACCOUNTS");
      const sum = accounts.reduce((a, b) => a + b.probability, 0);
      const avg = sum / accounts.length || 0;
      this.average = avg;
    },

    validateIfJson(value) {
      try {
        JSON.parse(value);
      } catch (e) {
        return false;
      }
      return true;
    },
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.show-lead-score-cr {
  font-size: 25px;
  font-weight: bold;
  // border-radius: 50%;
  padding: 13px 7px;
}
.number-circle {
  width: 1rem;
  height: 1rem;
  text-align: center;
  border: 0.5px solid #fff;
  border-radius: 50%;
}
.number-circle {
  border: 0.5px solid #ff9f43;
  font-size: 8pt;
}
.custom-class {
  position: relative;
  width: 100%;
  min-height: 40vh !important;
}
.custom-class .custom-img {
  max-width: 40%;
  max-height: 200px;
  width: 200px !important;
  height: 300px !important;
  opacity: 0.3;
}
.text-shadow {
  text-shadow: 2px 0px 2px #a09f9f;
}
.custom-width {
  min-width: 100px !important;
}
.custom-min-height {
  min-height: 140px !important;
}
.custom-message {
  position: relative !important;
  font-size: 25px !important;
  font-weight: bolder !important;
}
</style>
