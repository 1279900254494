

export default [
  {
    path: 'applications',
    name: 'specialist-digital-applications',
    redirect: { name: 'application-pending-specialist-digital' },
    component: () => import(/* webpackChunkName: "SpecialistDigitalApplications" */ "@/views/specialist-digital/views/applications/Applications.vue"),
    meta: {
      router: 'specialist-digital',
      pageTitle: 'Applications',
      breadcrumb: [
        {
          text: 'APPLICATIONS',
          active: true,
        },
      ],
    },
    children: [
      {
        path: 'in-process',
        name: 'application-pending-specialist-digital',
        component: () => import(/* webpackChunkName: "SpecialistDigitalApplicationsInProcess" */ '@/views/commons/components/applications/views/TableAplications.vue'),
        meta: {
          router: 'specialist-digital-dashboard',
          statusApp: 1,
          pageTitle: 'Applications',
          breadcrumb: [
            {
              text: 'APPLICATIONS',
              active: true,
            },
          ],
        },
      },
      {
        path: 'completed',
        name: 'application-completed-specialist-digital',
        component: () => import(/* webpackChunkName: "SpecialistDigitalApplicationsCompleted" */ '@/views/commons/components/applications/views/TableAplications.vue'),
        meta: {
          router: 'specialist-digital',
          statusApp: 2,
          pageTitle: 'Applications',
          breadcrumb: [
            {
              text: 'APPLICATIONS',
              active: true,
            },
          ],
        },
      },
      {
        path: 'deleted',
        name: 'application-deleted-specialist-digital',
        component: () => import(/* webpackChunkName: "SpecialistDigitalApplicationsDeleted" */ '@/views/commons/components/applications/views/TableAplications.vue'),
        meta: {
          router: 'specialist-digital',
          statusApp: 3,
          pageTitle: 'Applications',
          breadcrumb: [
            {
              text: 'APPLICATIONS',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
