import store from '@/store';
import Vue from 'vue';

const justificationCreated = () => {
  window.socket.bind('justification-created', async data => {
    let temps = false;
    const sessionId = store.state.auth.currentUser.user_id;
    data.toId.forEach(element => {
      if (element === sessionId) temps = true;
    });
    if (temps) {
      const htmlAlert = `${`${'<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;" alt="alert-info-image">'
            + `<div><h2 style="font-weight: 600;">${
              data.title
            }</h2></div>`
            + '<div style="text-align: center;">'
            + '<div style="font-weight: bolder;">'}${
        data.notificationDescription
      } </div>`
      } </div>`;
      const res = await Vue.swal.fire({
        html: htmlAlert,
        confirmButtonText: 'Ok',
        allowOutsideClick: true,
        showConfirmButton: true,
      });
      if (res.value) {
        await window.amgApi.post('/commons/close-all-swal', data);
      }
    }
  });
};
export default justificationCreated;
