import store from "@/store"
import Vue from "vue"
import router from "@/router"
import NotificationMixin from "@/mixins/notification"

const newLeadRealtorRequest = () => {
  window.socket.bind("new-ncr-realtor-lead-request", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id
    const sessionRoleId = router
    if (
      data.user_id.includes(Number(sessionId)) &&
      sessionRoleId.currentRoute.matched[0]?.meta?.module == 25 &&
      sessionId != data.requested_by_id
    ) {
      const htmlSwal =
        '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
        '<div><h2 style="font-weight: 600;">NEW LEAD CR REALTOR REQUEST</h2></div>' +
        '<div style="text-align: left;margin-left: 50px;">' +
        '<div style="font-weight: bolder;">LEAD: ' +
        data.lead_name +
        " </div>" +
        '<div style="font-weight: bolder;">REQUESTED BY: ' +
        data.requested_by +
        " </div>" +
        "</div>"
      const result = await Vue.swal.fire({
        // icon: "success",
        html: htmlSwal,
        showConfirmButton: true,
        confirmButtonText: "OK",
      })
      if (result.value) {
        await window.amgApi.post("/commons/close-all-swal", {
          to_id: data.user_id,
        })
        NotificationMixin.methods.showWindowNotification(
          "New lead cr realtor request",
          `New lead cr realtor request from ${data.lead_name}`,
          undefined,
          undefined,
          () => {
            window.focus()
          }
        )
        router.push(data.redirect_to)
      }
    }
  })
}

const newLeadRealtorRequestDone = () => {
    window.socket.bind("new-ncr-realtor-lead-request-done", async (data) => {
      const sessionId = store.state.auth.currentUser.user_id
      const sessionRoleId = router
      // console.log(sessionRoleId.currentRoute, 'sessionRoleId.currentRoute')
      if (
        data.user_id == Number(sessionId) &&
        sessionRoleId.currentRoute.matched[0]?.meta?.module == 26
      ) {
        const htmlSwal =
          '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
          '<div><h2 style="font-weight: 600;">LEAD CR REQUEST APPROVED</h2></div>' +
          '<div style="text-align: left;margin-left: 50px;">' +
          '<div style="font-weight: bolder;">LEAD: ' +
          data.lead_name +
          " </div>" +
          '<div style="font-weight: bolder;">REQUESTED BY: ' +
          data.requested_by_name +
          " </div>" +
          "</div>"
        const result = await Vue.swal.fire({
          // icon: "success",
          html: htmlSwal,
          showConfirmButton: true,
          confirmButtonText: "OK",
        })
        if (result.value) {
          await window.amgApi.post("/commons/close-all-swal", {
            to_id: data.user_id,
          })
        }
      }
    })
  }

export { newLeadRealtorRequest, newLeadRealtorRequestDone }
