var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-request-personnel",attrs:{"size":"md","title":"Request new personnel","no-close-on-backdrop":true},on:{"hide":_vm.closeModalRequestPersonnel},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-start align-items-center text-white"},[_c('tabler-icon',{staticClass:"mr-1",attrs:{"icon":"UserPlusIcon","size":"20"}}),_c('h4',{staticClass:"m-0 p-0 text-white"},[_vm._v(" Request new personnel ")])],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100 d-flex justify-content-end align-items-center"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.registerRequestPersonnel(1)}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon","size":"15"}}),_c('strong',[_vm._v(" Save Draft")])],1),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"success"},on:{"click":function($event){return _vm.registerRequestPersonnel(2)}}},[_c('feather-icon',{attrs:{"icon":"SendIcon","size":"15"}}),_c('strong',[_vm._v(" Send to CEO")])],1)],1)]},proxy:true}])},[_c('b-container',{staticClass:"p-1"},[_c('validation-observer',{ref:"formRequestPersonnel"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Start Date"}},[_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"w-100 rounded bg-transparent k-picker-custom picker-select-date",class:errors[0]
                    ? 'border-danger'
                    : '',attrs:{"id":"start_date","min":_vm.minDate,"placeholder":"MM/DD/YYYY","format":'MM/dd/yyyy',"state":errors[0]
                    ? false
                    : null},model:{value:(_vm.recruitmentProcess.date_start),callback:function ($$v) {_vm.$set(_vm.recruitmentProcess, "date_start", $$v)},expression:"recruitmentProcess.date_start"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"quantity-employees","rules":"required|max:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Number of applicants (maximum 2 digits)"}},[_c('b-form-input',{class:errors[0] ? 'border-danger' : '',attrs:{"id":"quantity-employees","type":"number","state":errors[0] ? false : null},model:{value:(_vm.recruitmentProcess.new_employee_quantity),callback:function ($$v) {_vm.$set(_vm.recruitmentProcess, "new_employee_quantity", $$v)},expression:"recruitmentProcess.new_employee_quantity"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"minimum-amount","rules":"required"}},[_c('b-form-group',{attrs:{"label":"Minimum Salary"}},[_c('money',_vm._b({staticClass:"form-control input-form",class:{ 'border-danger': _vm.vmoneyValidate },attrs:{"id":"minimumAmount"},model:{value:(_vm.recruitmentProcess.min_amount),callback:function ($$v) {_vm.$set(_vm.recruitmentProcess, "min_amount", $$v)},expression:"recruitmentProcess.min_amount"}},'money',_vm.vMoney,false)),(_vm.vmoneyValidate)?_c('small',{staticClass:"text-danger"},[_vm._v(" Is required ")]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"maximum-amount","rules":"required"}},[_c('b-form-group',{attrs:{"label":"Maximum Salary"}},[_c('money',_vm._b({staticClass:"form-control input-form",class:{ 'border-danger': _vm.vmoneyValidate2 },attrs:{"id":"maximumAmount"},model:{value:(_vm.recruitmentProcess.max_amount),callback:function ($$v) {_vm.$set(_vm.recruitmentProcess, "max_amount", $$v)},expression:"recruitmentProcess.max_amount"}},'money',_vm.vMoney,false)),(_vm.vmoneyValidate2)?_c('small',{staticClass:"text-danger"},[_vm._v(" Is required ")]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":_vm.recruitmentProcess.mof_id?'11':12}},[_c('validation-provider',{attrs:{"name":"mof","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"MOF (Manual of functions)"}},[_c('v-select',{style:(errors[0] ? 'border: 1px solid red;':''),attrs:{"id":"select-mof","placeholder":"Please select MOF","label":"name","options":_vm.optionsMof,"reduce":function (option) { return option.id; },"width":"100%"},model:{value:(_vm.recruitmentProcess.mof_id),callback:function ($$v) {_vm.$set(_vm.recruitmentProcess, "mof_id", $$v)},expression:"recruitmentProcess.mof_id"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Select an option ")]):_vm._e()],1)]}}])})],1),(_vm.recruitmentProcess.mof_id)?_c('b-col',{attrs:{"cols":"1"}},[_c('tabler-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"mr-2 cursor-pointer text-primary hover-me",staticStyle:{"margin-top":"30px"},attrs:{"title":"View MOF","icon":"FileInvoiceIcon","size":"25"},on:{"click":function($event){return _vm.getMOF(_vm.recruitmentProcess.mof_id)}}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"textarea-reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Reason for request"}},[_c('b-form-textarea',{attrs:{"id":"textarea-reason","placeholder":"Enter Reason...","rows":"3","state":errors[0] ? false : null,"max-rows":"6"},model:{value:(_vm.recruitmentProcess.reason),callback:function ($$v) {_vm.$set(_vm.recruitmentProcess, "reason", $$v)},expression:"recruitmentProcess.reason"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"shift","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Shift"}},[_c('v-select',{style:(errors[0] ? 'border: 1px solid red;':''),attrs:{"id":"select-shift","placeholder":"Please select shift","label":"text","options":_vm.optionsShift,"value-field":"id","reduce":function (option) { return option.id; },"width":"100%"},model:{value:(_vm.recruitmentProcess.shift),callback:function ($$v) {_vm.$set(_vm.recruitmentProcess, "shift", $$v)},expression:"recruitmentProcess.shift"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Select an option ")]):_vm._e()],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Shift details"}},[_c('b-form-textarea',{attrs:{"id":"textarea-details","placeholder":"Enter details...","rows":"3","max-rows":"6"},model:{value:(_vm.recruitmentProcess.details),callback:function ($$v) {_vm.$set(_vm.recruitmentProcess, "details", $$v)},expression:"recruitmentProcess.details"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"languages","rules":"required"}},[_c('b-form-group',{attrs:{"label":"Languages"}},[_vm._v(" Are English skills required? "),_c('b-form-checkbox',{staticClass:"custom-control-primary pt-50",attrs:{"id":"languages-status","checked":_vm.recruitmentProcess.need_english,"value":1,"unchecked-value":2,"name":"check-button","switch":""},model:{value:(_vm.recruitmentProcess.need_english),callback:function ($$v) {_vm.$set(_vm.recruitmentProcess, "need_english", $$v)},expression:"recruitmentProcess.need_english"}},[_vm._v("YES")])],1)],1)],1),(_vm.recruitmentProcess.need_english==1)?_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"level_of_english","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Languages"}},[_c('v-select',{style:(errors[0] ? 'border: 1px solid red;':''),attrs:{"id":"select-languages","placeholder":"Please select languages","label":"text","options":_vm.optionsLanguages,"value-field":"id","reduce":function (option) { return option.id; },"width":"100%"},model:{value:(_vm.recruitmentProcess.level_of_english),callback:function ($$v) {_vm.$set(_vm.recruitmentProcess, "level_of_english", $$v)},expression:"recruitmentProcess.level_of_english"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" Select an option ")]):_vm._e()],1)]}}],null,false,1587317491)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Additional comments"}},[_c('b-form-textarea',{attrs:{"id":"textarea-commentary","placeholder":"Enter Commentary...","rows":"3","max-rows":"6"},model:{value:(_vm.recruitmentProcess.commentary),callback:function ($$v) {_vm.$set(_vm.recruitmentProcess, "commentary", $$v)},expression:"recruitmentProcess.commentary"}})],1)],1)],1)],1)],1),(_vm.showModalReview)?_c('show-modal-review',{attrs:{"mof-data":_vm.selectedMOF,"readonly":true},on:{"hidden":function($event){_vm.showModalReview = false},"reload":function($event){return _vm.refreshTable()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }