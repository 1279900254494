const listCrediCard = [
    {
        value: 1,
        text: "Of Client",
    },
    {
        value: 2,
        text: "Other Cards",
    },
];

const listtypeCard = [
    ///type_card
    {
        value: 1,
        text: "AMG Cards",
    },
    {
        value: 2,
        text: "CRM Cards",
    },
];

const listSendCR = [
    {
        text: "Yes",
        value: 1,
    },
    {
        text: "NO",
        value: 2,
    },
];

const optionsResult = [
    {
        text: "SUCCESSFUL",
        value: "2",
    },
    {
        text: "DISAPPROVED",
        value: "3",
    },
    {
        text: "INCORRECT INFORMATION",
        value: "6",
    },
    {
        text: "REJECTED",
        value: "9",
    },
];

const arrayColumnsTable = [
    {
        key: "radio",
        label: "",
        class: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "cardholdername",
        label: "Card Holder Name",
        class: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "cardnumber",
        label: "Card Number",
        class: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "type_card", //card_expi_month
        label: "Type",
        class: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "card_expi_month",
        label: "MM",
        class: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "card_expi_year",
        label: "YY",
        class: "text-left ",
        sortable: false,
        visible: true,
    },
    {
        key: "cardsecuritycode",
        label: "CVC",
        class: "text-left",
        sortable: false,
        visible: true,
    },
    {
        key: "Actions",
        label: "Actions",
        class: "text-left",
        sortable: false,
        visible: true,
    },
];


export default {
    listCrediCard,
    listtypeCard,
    listSendCR, 
    optionsResult,
    arrayColumnsTable,
}