export default [
  {
    path: "court-info",
    name: "court-info-specialistdigital",
    redirect: { name: "court-info-cases-specialistdigital-in-progress" },
    component: () =>
      import(
        /* webpackChunkName: "Managment call-log" */
        "@/views/debt-solution/views/court-info/CourtInfoMain.vue"
      ),
    meta: {
      pageTitle: "Cases",
      tabs: [
        {
          name: "PROGRESS",
          route: "court-info-cases-specialistdigital-in-progress",
        },
        {
          name: "UPDATE",
          route: "court-info-cases-specialistdigital-update",
        },
        {
          name: "DONE",
          route: "court-info-cases-specialistdigital-done",
        },
      ],
    },
    children: [{
      path: "cases/progress",
      name: "court-info-cases-specialistdigital-in-progress",
      component: () =>
        import(
          /* webpackChunkName: "call-log-all" */
          "@/views/debt-solution/views/court-info/components/CIGridTable.vue"
        ),
      meta: {
        pageTitle: "Court Info",
        breadcrumb: [
          {
            text: "Cases",
            active: true,
          },
        ],
        tab: 1,
      },
    },
    {
      path: "cases/update",
      name: "court-info-cases-specialistdigital-update",
      component: () =>
        import(
          /* webpackChunkName: "call-log-all" */
          "@/views/debt-solution/views/court-info/components/CIGridTable.vue"
        ),
      meta: {
        pageTitle: "Court Info",
        breadcrumb: [
          {
            text: "Cases",
            active: true,
          },
        ],
        tab: 3,
      },
    },
    {
      path: "cases/done",
      name: "court-info-cases-specialistdigital-done",
      component: () =>
        import(
          /* webpackChunkName: "call-log-all" */
          "@/views/debt-solution/views/court-info/components/CIGridTable.vue"
        ),
      meta: {
        pageTitle: "Court Info",
        breadcrumb: [
          {
            text: "Cases",
            active: true,
          },
        ],
        tab: 2,
      },
    },
    ],
  },
]
