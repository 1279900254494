import store from "@/store"
import chargeBackV2Service from "@/views/commons/components/charge-back_v2/service/charge.back.service.js"

export default {
  namespaced: true,
  state: {
    S_REFRESH_TABLE_CHARGE_BACK: false,
    S_SENT_CHARGEBACK_COUNTER: 0,
    S_OBSERVED_CHARGEBACK_COUNTER: 0,
    S_PENDING_CHARGEBACK_COUNTER: 0,
    S_DISPUTE_CHARGEBACK_COUNTER: 0,
    S_IN_PROCESS_CHARGEBACK_COUNTER: 0,
    S_REMOVE_CHARGEBACK_COUNTER: 0,
    S_PAYMENT_CLIENT_CHARGEBACK_COUNTER: 0,
    S_SOLUTION_CHARGEBACK_COUNTER: 0,
    S_TOTAL_DISCOUNTS: 0,
    S_PENALTY_DISCOUNTS: 0,
    S_TOTAL_CHARGEBACKS: 0,
  },
  getters: {
    G_REFRESH_TABLE_CHARGE_BACK(state) {
      return state.S_REFRESH_TABLE_CHARGE_BACK
    },
    G_SENT_CHARGEBACK_COUNTER(state) {
      return state.S_SENT_CHARGEBACK_COUNTER
    },
    G_OBSERVED_CHARGEBACK_COUNTER(state) {
      return state.S_OBSERVED_CHARGEBACK_COUNTER
    },
    G_PENDING_CHARGEBACK_COUNTER(state) {
      return state.S_PENDING_CHARGEBACK_COUNTER
    },
    G_DISPUTE_CHARGEBACK_COUNTER(state) {
      return state.S_DISPUTE_CHARGEBACK_COUNTER
    },
    G_IN_PROCCESS_CHARGEBACK_COUNTER(state) {
      return state.S_IN_PROCESS_CHARGEBACK_COUNTER
    },
    G_REMOVE_CHARGEBACK_COUNTER(state) {
      return state.S_REMOVE_CHARGEBACK_COUNTER
    },
    G_PAYMENT_CLIENT_CHARGEBACK_COUNTER(state) {
      return state.S_PAYMENT_CLIENT_CHARGEBACK_COUNTER
    },
    G_SOLUTION_CHARGEBACK_COUNTER(state) {
      return state.S_SOLUTION_CHARGEBACK_COUNTER
    },
    G_CHARGEBACK_TOTAL_DISCOUNTS(state) {
      return state.S_TOTAL_DISCOUNTS > 0 ? state.S_TOTAL_DISCOUNTS : "0.00"
    },
    G_CHARGEBACK_PENALTY_DISCOUNTS(state) {
      return state.S_PENALTY_DISCOUNTS > 0 ? state.S_PENALTY_DISCOUNTS : "0.00"
    },
    G_CHARGEBACK_TOTAL_CHARGEBACKS(state) {
      return state.S_TOTAL_CHARGEBACKS > 0 ? state.S_TOTAL_CHARGEBACKS : "0.00"
    },
  },
  mutations: {
    M_REFRESH_TABLE_CHARGE_BACK(state, payload) {
      state.S_REFRESH_TABLE_CHARGE_BACK = payload
    },
    M_SENT_CHARGEBACK_COUNTER(state, payload) {
      state.S_SENT_CHARGEBACK_COUNTER = payload
    },
    M_OBSERVED_CHARGEBACK_COUNTER(state, payload) {
      state.S_OBSERVED_CHARGEBACK_COUNTER = payload
    },
    M_PENDING_CHARGEBACK_COUNTER(state, payload) {
      state.S_PENDING_CHARGEBACK_COUNTER = payload
    },
    M_DISPUTE_CHARGEBACK_COUNTER(state, payload) {
      state.S_DISPUTE_CHARGEBACK_COUNTER = payload
    },
    M_IN_PROCESS_CHARGEBACK_COUNTER(state, payload) {
      state.S_IN_PROCESS_CHARGEBACK_COUNTER = payload
    },
    M_REMOVE_CHARGEBACK_COUNTER(state, payload) {
      state.S_REMOVE_CHARGEBACK_COUNTER = payload
    },
    M_PAYMENT_CLIENT_CHARGEBACK_COUNTER(state, payload) {
      state.S_PAYMENT_CLIENT_CHARGEBACK_COUNTER = payload
    },
    M_SOLUTION_CHARGEBACK_COUNTER(state, payload) {
      state.S_SOLUTION_CHARGEBACK_COUNTER = payload
    },
    M_CHARGEBACK_TOTAL_DISCOUNTS(state, payload) {
      state.S_TOTAL_DISCOUNTS = payload
    },
    M_CHARGEBACK_PENALTY_DISCOUNTS(state, payload) {
      state.S_PENALTY_DISCOUNTS = payload
    },
    M_CHARGEBACK_TOTAL_CHARGEBACKS(state, payload) {
      state.S_TOTAL_CHARGEBACKS = payload
    },
  },
  actions: {
    A_REFRESH_TABLE_CHARGE_BACK({ commit }, params) {
      commit("M_REFRESH_TABLE_CHARGE_BACK", params)
    },

    async A_CHARGEBACK_COUNTERS({ commit }, module) {
      try {
        const {
          in_process,
          remove_cb,
          payment_client,
          pending,
          observed,
          sent,
        } = await chargeBackV2Service.getChargebackCounters({
          module_id: module == 28 ? 11 : module,
        })

        const solution = remove_cb + payment_client
        const dispute = pending + observed + sent

        commit("M_IN_PROCESS_CHARGEBACK_COUNTER", in_process)

        commit("M_REMOVE_CHARGEBACK_COUNTER", remove_cb)
        commit("M_PAYMENT_CLIENT_CHARGEBACK_COUNTER", payment_client)
        commit("M_SOLUTION_CHARGEBACK_COUNTER", solution)

        commit("M_PENDING_CHARGEBACK_COUNTER", pending)
        commit("M_OBSERVED_CHARGEBACK_COUNTER", observed)
        commit("M_SENT_CHARGEBACK_COUNTER", sent)
        commit("M_DISPUTE_CHARGEBACK_COUNTER", dispute)

        // Update sidebar
        const counterSide = in_process + solution + dispute

        if (counterSide > 0) {
          const name = {
            16: "charge-back",
            3: "bu-charge-back-pending",
            5: "debt-charge-back-pending",
            6: "ce-charge-back-pending",
            7: "bc-charge-back-pending",
            8: "tr-charge-back-pending",
            10: "ci-charge-back-pending",
            28: "analyst-department-charge-back-pending",
            14: "bk-charge-back-pending",
            9: "paragon-charge-back-in-process",
          }

          const payload = {
            routeName: name[module] || null,
            tag: counterSide,
          }

          store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
        }
      } catch (error) {
        console.error(error)
      }
    },

    async A_CHARGEBACK_TOTAL_DISCOUNTS({ commit }, module) {
      const data = await chargeBackV2Service.getTotalDiscount({
        module_id: module,
      })
      const { penalty, chargeback, total } = data
      commit("M_CHARGEBACK_TOTAL_DISCOUNTS", total)
      commit("M_CHARGEBACK_PENALTY_DISCOUNTS", penalty)
      commit("M_CHARGEBACK_TOTAL_CHARGEBACKS", chargeback)
    },
  },
}
