<template>
  <div
    class="d-flex"
    :style="[
      currentUser.modul_id !== 22 ? 'display: inline-block !important;' : '',
      currentUser.modul_id == 2 ? 'height: 40px !important;' : '',
    ]"
  >
    <b-button-group class="mr-1">
      <b-button
        variant="info"
        :disabled="isResponsibleLead || isResponsibleAccount"
        style="background-color: #794aff !important; color: white !important"
        @click="modalListCourtInfo = true"
      >
        <div class="d-flex justify-content-center align-items-center">
          <span class="font-small-5"
            >Court Info
            <span v-if="G_IN_COURT > 0">({{ G_IN_COURT }})</span></span
          >
        </div>
      </b-button>

      <b-button
        v-if="hasRolesPermission"
        v-b-tooltip.hover.top="`Create Court Case`"
        variant="info"
        size="sm"
        style="
          margin-left: 3px;
          background-color: #794aff !important;
          color: white !important;
        "
        :disabled="isResponsibleLead || isResponsibleAccount"
        @click="modalCourtInfo = true"
      >
        <feather-icon icon="PlusIcon" />
      </b-button>
    </b-button-group>

    <list-case-modal
      v-if="modalListCourtInfo"
      :modal-list-court-info="modalListCourtInfo"
      :lead-id="leadId ? leadId : client.lead_id"
      :modul-id="moduleId"
      :lead-name="lead.full_name"
      @closeModalListCourtInfo="modalListCourtInfo = false"
    />

    <case-tracking-modal
      v-if="showTrackingCaseModal"
      :lead-id="leadId ? leadId : client.lead_id"
      @close="showTrackingCaseModal = false"
      @completeTracking="showTrackingCaseModal = false"
    />

    <modal-create-case-c-i
      v-if="modalCourtInfo"
      :lead-id="leadId ? leadId : client.lead_id"
      :modul-id="moduleId"
      @closeModalCourtInfo="(modalCourtInfo = false), updateCounter()"
      @onlyClose="modalCourtInfo = false"
    />
  </div>
</template>

<script>
import GavelIcon from "@/views/ui/icons/GavelIcon.vue";
import ListCaseModal from "@/views/debt-solution/views/court-info/components/modals/ListCaseModal.vue";
import { mapActions, mapGetters } from "vuex";
import CaseTrackingModal from "@/views/debt-solution/views/court-info/components/modals/TrackingCaseModal.vue";
import ModalCreateCaseCI from "@/views/debt-solution/views/court-info/components/modals/CreateCaseModal.vue";
import DashboardService from "@/views/paragon/views/clients/dashboard/services/dashboard.service";

export default {
  components: {
    GavelIcon,
    ListCaseModal,
    CaseTrackingModal,
    ModalCreateCaseCI,
  },
  props: {
    leadId: {
      type: [Number, String],
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      G_IN_COURT: "ParagonClientDashboard/G_IN_COURT",
      currentUser: "auth/currentUser",
    }),
    roleId() {
      return this.currentUser.role_id;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    hasRolesPermission() {
      return [1, 2, 3, 15, 17].includes(this.roleId);
    },
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  data() {
    return {
      modalListCourtInfo: false,
      showTrackingCaseModal: false,
      modalCourtInfo: false,
      lead: null,
    };
  },

  methods: {
    isResponsible() {
      this.$store.dispatch("NotificationStore/A_ACCOUNT_IS_RESPONSIBLE_CEO", {
        account_id: this.$route.params.idClient,
      });
      this.$store.dispatch("NotificationStore/A_IS_RESPONSIBLE_CEO", {
        lead_id: this.$route.params.id,
      });
    },
    ...mapActions({
      A_GET_IN_COURT: "ParagonClientDashboard/A_GET_IN_COURT",
    }),

    async getLeadInformation() {
      const { data } = await DashboardService.getLeadInformation({
        lead_id: this.leadId,
        client_account_id: this.$route.params.idClient,
      });
      this.lead = data.information;
    },

    async updateCounter() {
      this.A_GET_IN_COURT({
        client_account_id: this.$route.params.idClient ?? null,
        lead_id: this.$route.params.id,
      });
    },
  },

  async created() {
    this.isResponsible();
    this.getLeadInformation();
    this.updateCounter();
  },
};
</script>
