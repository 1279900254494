export default {
  namespaced: true,
  state: {
    S_COUNTER_INITIAL_PAYMENT: 0,
  },
  mutations: {
    M_SET_COUNTER_INITIAL_PAYMENT(state, payload) {
      state.S_COUNTER_INITIAL_PAYMENT = payload
    },

  },
  actions: {
    A_SET_COUNTERS_INITIAL_PAYMENT({ commit }, payload) {
      commit('M_SET_COUNTER_INITIAL_PAYMENT', payload)
    },
  },
}
