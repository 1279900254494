import CustomerTicketsMain from "@/views/commons/components/customer-tickets/CustomerTicketsMain.vue";
import { ticketsTabs } from "@/views/customer-tickets/router/tickets.router.js";

export default [
    {
        path: "/logistic/tickets",
        name: "logistic-customer-tickets",
        component: CustomerTicketsMain,
        redirect: { name: "customer-ticket-logistic-in-progress" },
        meta: {
            pageTitle: "Customer Tickets",
            breadcrumb: [
                {
                    text: "My tickets",
                },
            ],
            permittedRoles: [2, 17],
            tab: 4,
        },
        children: ticketsTabs("logistic", [2, 17]),
    },
];
