<template>
    <b-modal v-model="showModal" title="Credit report list" @hidden="closeModal()" size="lg">
        <ncr-request-lead
        :lead="lead"
        />
    </b-modal>
</template>
<script>
import NcrRequestLead from "@/views/crm/views/Lead/lead-module/dashboard/NcrRequestLead.vue"

export default {
    components: {
        NcrRequestLead
    },
    props: {
        lead: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            showModal: false
        }
    },
    created(){
        this.showModal = true;
    },
    methods: {
        closeModal(){
            this.$emit("closeModal")
        }
    }
}
</script>