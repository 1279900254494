import Vue from 'vue'
import store from '@/store/index'
const ApplicationsRequestNotification = () => {
    window.socket.bind('form-request-notifications-applications', async ({ message, to_id }) => {
        const currentSession = store.state.auth.currentUser.user_id
        if (to_id == currentSession) {
            const { isConfirmed } = await Vue.swal.fire({
                icon: "success",
                title: 'Application',
                text: message,
            });
            if (isConfirmed)
                await window.amgApi.post("/commons/close-all-swal", { to_id: data.to_id });
        }
    })
}
export default ApplicationsRequestNotification;