
import BankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service"
import DebtSolutionServices from "@/views/debt-solution/services/debt-solution.service.js";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    S_VALIDATE_BALANCE_BANK_ACCOUNT: false,
    S_VALIDATE_PAYMENT_PRIORITY_ALERT: false,
    S_VALIDATE_RESCHEDULE_APPOINTMENT: false,
    S_DATA_RESCHEDULE_APPOINTMENT: null,
    S_DATA_BANK_ACCOUNT: [],
    S_DATA_FEW_CHECKS: [],
    S_DATA_OLD_QUESTIONNAIRE: [],
    S_VALIDATE_OLD_QUESTIONNAIRE: false,
  },
  getters: {
    G_S_VALIDATE_OLD_QUESTIONNAIRE(state) {
      return state.S_VALIDATE_OLD_QUESTIONNAIRE;
    }
  },
  mutations: {
    M_VALIDATE_BALANCE_BANK_ACCOUNT(state, payload) {
      state.S_VALIDATE_BALANCE_BANK_ACCOUNT = payload
    },
    M_VALIDATE_PAYMENT_PRIORITY_ALERT(state, payload) {
      state.S_VALIDATE_PAYMENT_PRIORITY_ALERT = payload
    },
    M_VALIDATE_RESCHEDULE_APPOINTMENT(state, payload) {
      state.S_VALIDATE_RESCHEDULE_APPOINTMENT = payload
    },
    M_SET_DATA_RESCHEDULE_APPOINTMENT(state, payload) {
      state.S_DATA_RESCHEDULE_APPOINTMENT = payload
    },
    M_SET_DATA_BANK_ACCOUNT(state, payload) {
      state.S_DATA_BANK_ACCOUNT = payload
    },
    M_VALIDATE_FEW_CHECKS(state, payload) {
      state.S_VALIDATE_FEW_CHECKS = payload
    },
    M_VALIDATE_OLD_QUESTIONNAIRE(state, payload) {
      state.S_VALIDATE_OLD_QUESTIONNAIRE = payload
    },
    M_SET_DATA_FEW_CHECKS(state, payload) {
      state.S_DATA_FEW_CHECKS = payload
    },
    M_SET_DATA_OLD_QUESTIONNAIRE(state, payload) {
      state.S_DATA_OLD_QUESTIONNAIRE = payload
    }
  },
  actions: {
    async A_VALIDATE_BALANCE_BANK_ACCOUNT({ commit }, payload) {
      const { data } = await BankAccountsService.getIsuficiencyFunds()
      if (data.length !== 0) {
        commit("M_SET_DATA_BANK_ACCOUNT", data)
        commit("M_VALIDATE_BALANCE_BANK_ACCOUNT", true)
      }
    },
    async getCheckbooksWithFewChecks({ commit }, payload) {
      const { data } = await BankAccountsService.getCheckbooksWithFewChecks()
      if (data.length > 0) {
        commit("M_SET_DATA_FEW_CHECKS", data)
        commit("M_VALIDATE_FEW_CHECKS", true)
      }
    },
    async A_VALIDATE_OLD_QUESTIONNAIRE({ commit }, payload) {
      store.commit("app/SET_LOADING", true);
      const { data } = await DebtSolutionServices.notificationOldQquestionnaire(
        payload
      );
      // if (data.length > 0) {
        commit("M_SET_DATA_OLD_QUESTIONNAIRE", data)
        commit("M_VALIDATE_OLD_QUESTIONNAIRE", true)
      // }
      store.commit("app/SET_LOADING", false);
    },

    async A_VALIDATE_RESCHEDULE_APPOINTMENT({ commit }, payload) {

      commit("M_SET_DATA_RESCHEDULE_APPOINTMENT", payload);
      commit("M_VALIDATE_RESCHEDULE_APPOINTMENT", true);
    },
  },
}
