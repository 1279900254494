export default {
  path: "tracking-list",
  name: "connection-tracking-list-main",
  redirect: { name: "connection-tracking-list" },
  component: () =>
    import(
      /* webpackChunkName: "ConnectionTrackingList" */
      "@/views/commons/components/tracking-list/views/components/TrackingListTab.vue"
    ),
  meta: {
    pageTitle: "Tracking List",
    permittedRoles: [1, 2, 3],
    breadcrumb: [
      {
        text: "Tracking List",
      },
    ],
  },
  children: [
    {
      path: "tracking-list/in-course",
      component: () =>
        import(
          /* webpackChunkName: "ConnectionTrackingList" */
          "@/views/commons/components/tracking-list/views/components/TrackingInCourseGrid.vue"
        ),
      name: "connection-tracking-list",
      meta: {
        status: 1,
        pageTitle: "Tracking List",
        permittedRoles: [1, 2, 3],
        breadcrumb: [
          {
            text: "In course",
            active: true,
          },
        ],
        module: 7,
      },
    },
    {
      path: "tracking-list/completed",
      component: () =>
        import(
          /* webpackChunkName: "ConnectionTrackingList" */
          "@/views/commons/components/tracking-list/views/components/TrackingCompletedGrid.vue"
        ),
      name: "connection-tracking-list-completed",
      meta: {
        status: 2,
        permittedRoles: [1, 2, 3],
        pageTitle: "Tracking List",
        breadcrumb: [
          {
            text: "Completed",
            active: true,
          },
        ],
        module: 7,
      },
    },
    {
      path: "tracking-list/pending",
      component: () =>
        import(
          /* webpackChunkName: "ConnectionTrackingList" */
          "@/views/commons/components/tracking-list/views/components/TrackingPending.vue"
        ),
      name: "connection-tracking-list-pending",
      meta: {
        status: 3,
        permittedRoles: [1, 2, 3],
        pageTitle: "Tracking List",
        breadcrumb: [
          {
            text: "Pending",
            active: true,
          },
        ],
        module: 7,
      },
    },
  ],
};
