import { amgApi } from "@/service/axios";

class DisputeResultsService {
  constructor() {
    this.routeUr = "commons/dispute-results";
  }
  async getDisputeResults(parameters) {
    try {
      return (
        await amgApi.post(`${this.routeUr}/get-dispute-results`, parameters)
      ).data;
    } catch (error) {
      console.log(error);
    }
  }
  async getFilesDisputeResults(parameter) {
    try {
      return (
        await amgApi.post(
          `${this.routeUr}/get-files-dispute-results`,
          parameter
        )
      ).data;
    } catch (error) {
      console.log(error);
    }
  }
  async getEmailDisputeResults(parameter) {
    try {
      return (
        await amgApi.post(
          `${this.routeUr}/get-email-dispute-results`,
          parameter
        )
      ).data;
    } catch (error) {
      console.log(error);
    }
  }
  async updateDisputeResults(parameters) {
    try {
      const { status } = await amgApi.post(
        `${this.routeUr}/update-dispute-results`,
        parameters
      );

      return status === 200;
    } catch (error) {
      console.log(error);
    }
  }
  async updateReadDisputeResults(parameter) {
    try {
      const { status } = await amgApi.post(
        `${this.routeUr}/update-read-dispute-results`,
        parameter
      );
      return status === 200;
    } catch (error) {
      console.log(error);
    }
  }
  async getNotificationsDispute(parameter) {
    try {
      const result = await amgApi.post(
        "dispute/get-notifications-dispute",
        parameter
      );
      return result.data;
    } catch (error) {
      console.log(error);
    }
  }
  async updateCountDisputeId(params) {
    try {
      const result = await amgApi.post(
        "dispute/update-count-dispute-id",
        params
      );
      return result;
    } catch (error) {
      console.log(error);
    }
  }
  async deletedUpdateRequest(params) {
    const data = await amgApi.post(
      "/dispute/delete-round-letter-dispute",
      params
    );
    return data;
  }

  async toRevisionUpdateRequest(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeUr}/to-revision-update-request`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async returnToPending(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeUr}/return-to-update-request`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async completeUpdateRequest(params) {
    try {
      const data = await amgApi.post(
        `${this.routeUr}/complete-dispute-results`,
        params
      );
      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      throw new Error(errorMessage);
    }
  }
  async getTrackingUpdateRequest(params) {
    try {
      const data = await amgApi.post(
        `${this.routeUr}/get-tracking-update-request`,
        params
      );
      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      throw new Error(errorMessage);
    }
  }
  async getCounterUpdateRequest(params) {
    try {
      const data = await amgApi.post(
        `${this.routeUr}/get-counters-update-request`,
        params
      );
      return data.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      throw new Error(errorMessage);
    }
  }
  async storeRequestNcrForUpdate(params) {
    try {
      const data = await amgApi.post(
        `${this.routeUr}/store-request-ncr-for-update`,
        params
      );
      return data.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      throw new Error(errorMessage);
    }
  }
  async getStepUpdateRequest(params) {
    try {
      return await amgApi.post(
        `${this.routeUr}/get-step-update-request`,
        params
      );
    } catch (error) {
      throw new Error(error);
    }
  }
  async updateStepUpdateRequest(params) {
    try {
      return await amgApi.post(
        `${this.routeUr}/update-step-update-request`,
        params
      );
    } catch (error) {
      throw new Error(error);
    }
  }

  async addCommentWithoutDispute(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeUr}/add-comment-without-dispute`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getAddedAccounts(params){
    try {
      const { data } = await amgApi.post(
        `${this.routeUr}/get-added-accounts`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getAddAccountsCounter(params){
    try {
      const { data } = await amgApi.post(
        `${this.routeUr}/get-add-accounts-counter`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default new DisputeResultsService();
