import { requestWpTabs } from "@/views/commons/components/request-workplan/router/request-workplan.tabs.js";

export default [
  {
    path: "request-workplan",
    name: "request-workplan-business",
    redirect: { name: "bu-wp-pending" },
    meta: {
      pageTitle: "Request Workplan",
      breadcrumb: [
        {
          text: "Request Workplan",
          active: true,
        },
      ],
      routePending: "bu-wp-pending",
      routeCompleted: "bu-wp-completed",
      routeToRevision: "bu-wp-to-revision",
    },
    component: () =>
      import(
        "@/views/commons/components/request-workplan/RequestWorkplanMain.vue"
      ),
    children: requestWpTabs('bu')
  }
]