import Employees from '@/views/management/views/employees/employees.store';
import ChargeBackStore from '@/views/commons/components/charge-back/charge-back.store';
import ChargeBackStoreV2 from '@/views/commons/components/charge-back_v2/store/charge-back-v2.store';
import CompaniesStore from '@/views/management/views/companies/companies.store';
import SellerStore from '@/views/management/views/approval-process/sales/store/seller-permission.store';
import SettlementStore from '@/views/debt-solution/views/settlements/store/settlements.store';
import SalaryRecordStore from '@/views/management/views/salary-record/salary-record.store';
import PayrollStore from '@/views/commons/components/payroll-payments/store/payroll.store';
import ClaimsStore from '@/views/commons/components/claims_v2/store/index';
import ReportStore from "@/views/commons/components/reports/reports.store.js"
import ChatReportStore from "@/views/commons/components/reports/chat-reports.store";

export default {
  ManagementEmployeesStore: Employees,
  ChargeBackStore,
  ChargeBackStoreV2,
  ManagementCompaniesStore: CompaniesStore,
  SellerPermissionsStore: SellerStore,
  SettlementStore,
  SalaryRecordStore,
  ClaimsStore,
  PayrollStore,
  ManagementReportStore: ReportStore,
  ChatReportStore,
};
