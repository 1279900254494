<template>
  <div class="search-rc-calls-active">
    <validation-observer ref="formSearchCalls">
      <div class="text-center mb-1"></div>
      <b-row>
        <b-col cols="5" v-show="!isNegotiation">
          <validation-provider v-slot="{ errors, valid }" rules="required">
            <b-input-group>
              <template #prepend>
                <b-input-group-text> Service Phone </b-input-group-text>
              </template>
              <v-select
                style="min-width: 160px"
                v-model="callNumbers.module_number"
                :options="numbers"
                label="number_format"
                :reduce="(number) => number.number_format"
                :state="errors[0] ? false : valid ? true : null"
                :clearable="false"
              >
                <template #selected-option="{ number_format, you }">
                  <span>{{ number_format }} {{ you ? `(${you})` : "" }}</span>
                </template>

                <template #option="{ number_format, you }">
                  <span>{{ number_format }} {{ you ? `(${you})` : "" }}</span>
                </template>
              </v-select>
            </b-input-group>
          </validation-provider>
        </b-col>
        <b-col cols="5">
          <validation-provider v-slot="{ errors, valid }" rules="required">
            <b-input-group>
              <template #prepend>
                <b-input-group-text> Client Phone </b-input-group-text>
              </template>
              <b-form-input
                v-model="callNumbers.lead_number"
                v-mask="'(###) ###-####'"
                :disabled="isCurrentPhone"
                :state="errors[0] ? false : valid ? true : null"
              />

              <div class="icon-edit-float" :class="{ active: !isCurrentPhone }">
                <feather-icon
                  icon="Edit2Icon"
                  size="12"
                  :class="isCurrentPhone ? '' : ''"
                  class="cursor-pointer"
                  v-b-tooltip.hover
                  :title="isCurrentPhone ? 'Change Phone' : 'Cancel'"
                  @click="changePhone"
                ></feather-icon>
              </div>
            </b-input-group>
          </validation-provider>
        </b-col>
        <b-col cols="1">
          <div style="font-size: 0.8rem">Transfer</div>
          <b-form-checkbox v-model="callNumbers.transfer" switch>
          </b-form-checkbox>
        </b-col>
        <b-col cols="1">
          <div class="d-flex align-items-center h-100">
            <b-button
              @click="searchActiveCalls"
              size="sm"
              :variant="saving ? 'danger' : 'primary'"
            >
              <feather-icon
                icon="SearchIcon"
                size="18"
                class="cursor-pointer"
              ></feather-icon>
            </b-button>
          </div>
        </b-col>
        <!-- <b-col cols="3" v-if="call_recording_queue_counter.total > 0">
          <div class="d-flex align-items-center h-100 justify-content-end">
            <span
              class="border border-primary rounded counter-padding text-primary font-weight-bold"
            >
              {{ call_recording_queue_counter.completed }} /
              {{ call_recording_queue_counter.total }}
            </span>
          </div>
        </b-col> -->

        <b-col
          cols="12"
          class="mt-2"
          style="padding-left: 0 !important; padding-right: 0 !important"
        >
          <b-table
            ref="selectableTable"
            :items="calls"
            :fields="visibledFields"
            thead-tr-class="thead-class"
          >
            <!-- is empty  slot -->
            <template #empty>
              <div class="text-center">
                <feather-icon
                  icon="InfoIcon"
                  size="24"
                  class="mb-1"
                ></feather-icon>
                <p>No records found</p>
              </div>
            </template>
            <template #cell(direction)="data">
              <div>{{ data.item.direction }}</div>
            </template>

            <template #cell(start_time)="data">
              <div>{{ data.item.start_time | myGlobalWithHour }}</div>
            </template>
            <template #cell(status)="data">
              <div>{{ data.item.result }}</div>
            </template>

            <template #cell(duration)="data">
              <div class="w-100 d-flex justify-content-around text-center">
                {{
                  data.item.result != "Disconnected"
                    ? "-"
                    : convertMSeconds(data.item.duration)
                }}
              </div>
            </template>
            <template #cell(record_url)="data">
              <div class="w-100 d-flex justify-content-around text-center">
                <div
                  style="position: sticky; top: 0; z-index: 1"
                  v-if="data.item.show_item"
                >
                  <wave-surfer-player
                    style="height: 1.5rem; display: block !important"
                    class="audio-recording"
                    :url="data.item.record_url"
                    :options="audioOptions"
                    :wave-surfer-id="`wave-surfer-${data.index}`"
                    :elapsed-time-width="50"
                    :file_name="data.item.id"
                    @loading="data.item.isLoading = true"
                    @ready="data.item.isLoading = false"
                    @WaveSurferEvents="load"
                    custom-class="no-overflow"
                    :download="false"
                  ></wave-surfer-player>
                </div>
                <feather-icon
                  v-else
                  @click="data.item.show_item = !data.item.show_item"
                  icon="LoaderIcon"
                  class="text-primary cursor-pointer"
                  v-b-tooltip.hover
                  title="Load record"
                ></feather-icon>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import CallLogService from "@/views/commons/components/ring-central/call-log/service/call-log.service.js";
import RingcentralService from "@/service/commons/ringcentral.service.js";
import Fields from "@/views/commons/components/ring-central/call-log/data/FieldsCurrentCalls.js";
import { mapGetters } from "vuex";

export default {
  props: {
    value: {
      type: Object,
    },
    isNegotiation: {
      type: Boolean,
      default: false,
    },
    clientPhoneOriginal: {
      type: [String],
      required: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    module: {
      type: Number,
      default: null,
    },
  },

  computed: {
    callNumbers: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    visibledFields() {
      return Fields;
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      numbers: [],
      calls: [],
      selected: [],
      isCurrentPhone: true,
      searchInProgress: null,
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        width: 180,
        backend: "MediaElement",
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      call_recording_queue_counter: {
        total: 0,
        failed: 0,
        pending: 0,
        completed: 0,
        progress: 0,
      },
    };
  },
  watch: {
    calls: {
      handler() {
        this.$emit("countCalls", this.calls.length);
      },
    },
  },

  methods: {
    async validateForm() {
      const isValidated = await this.$refs.formSearchCalls.validate();
      return isValidated;
    },

    async searchActiveCalls() {
      const validateInformation = await this.validateForm();
      if (!validateInformation) {
        return;
      }

      this.$emit("hasSearch", true);

      try {
        this.addPreloader();

        const params = {
          module_number: this.callNumbers.module_number,
          lead_number: this.callNumbers.lead_number,
          transfer: this.callNumbers.transfer ?? false,
          negotiation: this.isNegotiation,
        };
        const { data } = await CallLogService.getTodayCalls(params);
        this.calls = data;
        if (data.length > 0) {
          let record_counters = data[0];
          this.call_recording_queue_counter.total = record_counters.total;
          this.call_recording_queue_counter.failed = record_counters.failed;
          this.call_recording_queue_counter.pending = record_counters.pending;
          this.call_recording_queue_counter.completed =
            record_counters.completed;
          this.call_recording_queue_counter.progress = record_counters.progress;
        }
      } catch (e) {
        if (e.response.status == 429) {
          this.showWarningSwal(
            e.response.statusText,
            "try again in one minute"
          );
        } else {
          this.showErrorSwal(e.response.statusText);
        }
      } finally {
        this.removePreloader();
      }
    },

    async getRCredentials() {
      try {
        const params = {
          user_id: this.currentUser.user_id,
        };

        const { data } = await RingcentralService.getCredentialsByModuleId(
          this.moduleId,
          params
        );
        this.numbers = data;
        if (data.length > 0) {
          this.callNumbers = {
            module_number: data[0].number_format,
            lead_number: this.clientPhoneOriginal,
            transfer: false,
          };

          if (this.clientPhoneOriginal) {
            setTimeout(this.searchActiveCalls, 300);
          }
        }
      } catch (e) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    changePhone() {
      this.isCurrentPhone = !this.isCurrentPhone;
      this.callNumbers.lead_number = this.isCurrentPhone
        ? this.clientPhoneOriginal
        : "";
    },

    addNewCallFromWebhook(call) {
      if (this.moduleId != call.from_module && this.moduleId != call.to_module)
        return;

      if (!this.callNumbers.lead_number || this.callNumbers.lead_number == "")
        return;

      if (
        !this.callNumbers.module_number ||
        this.callNumbers.module_number == ""
      )
        return;

      const fromNumber = call.from_number;
      const toNumber = call.to_number;

      if (
        fromNumber != this.callNumbers.lead_number &&
        fromNumber != this.callNumbers.module_number
      )
        return;

      if (
        toNumber != this.callNumbers.lead_number &&
        toNumber != this.callNumbers.module_number
      )
        return;

      if (call.webhook_state == "Disconnected") {
        this.searchInProgress = null;
      } else {
        this.searchInProgress = true;
      }
      this.searchActiveCalls();
    },

    // SOCKETS

    async bindChangeStateCall() {
      try {
        window.socket.bind(
          "change-call-state-notification",
          async ({ call }) => {
            this.addNewCallFromWebhook(call);
          }
        );
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    initWebsocket() {
      window.socket.unsubscribe("ringcentral-notifications");
      window.socket.subscribe("ringcentral-notifications");
    },
  },

  async created() {
    await this.getRCredentials();
    this.initWebsocket();
  },

  async mounted() {
    this.bindChangeStateCall();
  },

  beforeDestroy() {
    try {
      window.socket.unbind("change-call-state-notification");
    } catch (error) {}
  },
};
</script>

<style lang="scss">
.search-rc-calls-active {
  table {
    tr {
      border-top: rgba(0, 0, 0, 0);
    }
  }

  .thead-class {
    th {
      background-color: #0077e6 !important;
      color: #fff !important;
    }
  }

  .icon-edit-float {
    position: relative;
    right: 5px;
    top: -10px;
    background-color: #0090e7;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    &.active {
      background-color: #ff7b00;
    }
  }

  .call-in-progress {
    border-radius: 5px;
    background-color: #3ffaa01c;
    color: #606060;
  }

  .was-validated .custom-control-input:valid ~ .custom-control-label,
  .custom-control-input.is-valid ~ .custom-control-label {
    color: #606060;
  }
}

.dark-layout {
  .search-rc-calls-active {
    .table.b-table > tbody .b-table-row-selected.table-active td {
      background-color: #3c3c3c;
    }
  }

  .call-in-progress {
    background-color: #2d2e30;
    color: #ffffff;
  }
}

.counter-padding {
  padding: 0.2rem 0.5rem;
}
</style>