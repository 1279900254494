import Vue from 'vue';
import store from '@/store';

const NotificationStatusMeeting = () => {
  window.socket.bind('notification-status-meeting-ticket-customer', async data => {
    const { user_id, modul_id: module_id } = store.state.auth.currentUser;
    const { usersId, information, newMeeting } = data;

    if (usersId.includes(user_id)) {
      const status = {
        subtitle: {
          1: 'NEW MEETING',
          2: 'REPROGRAMMED',
          4: 'ATTENDED',
          5: 'NOT ATTENDED',
          6: 'NOT ANSWER',
        },
        icon: {
          1: 'ClockIcon',
          2: 'ClockIcon',
          4: 'UserCheckIcon',
          5: 'UserMinusIcon',
          6: 'UserXIcon',
        },
      };

      if (!newMeeting) {
        await Vue.prototype.$amgAlert({
          title: 'TICKET CUSTOMER MEETING',
          icon: status.icon[information.status],
          subtitle: status.subtitle[information.status],
          data: [
            {
              icon: 'UserIcon',
              title: 'CLIENT',
              description: information.clientName,
            },
            {
              icon: 'FileIcon',
              title: 'TICKET',
              description: information.ticketCode,
            },
            {
              icon: status.icon[information.status],
              title: 'STATUS',
              description: status.subtitle[information.status],
            },
          ],
          ok: async () => { await window.amgApi.post('/commons/close-all-swal', { to_id: user_id }); },
        });
      } else {
        await Vue.prototype.$amgAlert({
          title: 'TICKET CUSTOMER MEETING',
          icon: status.icon[information.status],
          subtitle: status.subtitle[information.status],
          data: [
            {
              icon: 'UserIcon',
              title: 'CLIENT',
              description: information.clientName,
            },
            {
              icon: 'FileIcon',
              title: 'TICKET',
              description: information.ticketCode,
            },
            {
              icon: status.icon[information.status],
              title: 'STATUS',
              description: status.subtitle[information.status],
            },
            {
              icon: 'CalendarIcon',
              title: information.status === 1 ? 'DATE ' : 'REPROGRAMM DATE',
              description: newMeeting.meetingDate,
            },
            {
              icon: 'ClockIcon',
              title: information.status === 1 ? 'HOURS' : 'REPROGRAMM HOURS',
              description: `${newMeeting.meetingStart} - ${newMeeting.meetingEnd}`,
            },
          ],
          ok: async () => { await window.amgApi.post('/commons/close-all-swal', { to_id: user_id }); },
        });
      }
      await store.dispatch('NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS', { user_id });
      await store.dispatch('NotificationStore/A_COUNT_INDICATORS_TICKETS', { user_id, module_id });
      await store.dispatch('NotificationStore/A_MEETINGS_PENDING_CUSTOMER_TICKETS');
    }
  });
};

export default NotificationStatusMeeting;
