// import ModalComponent from './ModalComponent.vue';
import AmgAlert from "@/views/commons/utilities/AmgAlert.vue"
const ModalPlugin = {
  install(Vue, options) {
    const modalInstances = []
    Vue.prototype.$amgAlert = function (propsData = {}) {
      modalInstances.forEach((instance) => {
        instance.close()
      })
      const ModalComponentConstructor = Vue.extend(AmgAlert)
      const modalInstance = new ModalComponentConstructor({
        propsData: {
          ...options,
          ...propsData,
        },
      })
      modalInstances.push(modalInstance)
      modalInstance.$mount()
      document.body.appendChild(modalInstance.$el)
    }
    Vue.prototype.$amgAlert.closeAll = function () {
      modalInstances.forEach((instance) => {
        instance.close()
      })
    }
  },
}

export default ModalPlugin
