export default {
  path: "loans",
  name: "loans-claimdepartment",
  redirect: "/claimdepartment/loans/loans-module",
  component: () => import(/* webpackChunkName: "ClaimDepartamentLoans" */ "@/views/commons/components/loans/LoansComponent.vue"),
  children: [
    {
      path: "my-loans",
      name: "my-loans-claimdepartment",
      component: () => import(/* webpackChunkName: "ClaimDepartamentLoansMyLoans" */ "@/views/commons/components/loans/LoansView.vue"),
      meta: {
        tab: 1,
        pageTitle: "Loans",
        breadcrumb: [
          {
            text: "My Loans",
            active: true,
          },
        ],
        route: "claimdepartment",
      },
    },
    {
      path: "loans-module",
      name: "loans-module-claimdepartment",
      component: () => import(/* webpackChunkName: "ClaimDepartamentLoansModule" */ "@/views/commons/components/loans/LoansView.vue"),
      meta: {
        tab: 2,
        route: "claimdepartment",
        pageTitle: "Loans",
        breadcrumb: [
          {
            text: "Loans By Module",
            active: true,
          },
        ],
      },
    },
  ],
  meta: {
    tab: 1,
    route: "claimdepartment",
  },
};
