export default
    {
        path: "dashboard",
        name: "dashboard-logistic",
        component: () => import(/* webpackChunkName: "LogisticDashboard" */ "@/views/logistic/views/dashboard/Dashboard.vue"),
        meta: {
            isClientsTab: true,
            pageTitle: "Dashboard",
            breadcrumb: [
                {
                    text: "Dashboard",
                    active: true,
                },
            ],
        },
    };

