import Derivations from '@/views/ce-digital/sub-modules/sales/views/derivations/Derivations.vue'
import DerivationsTable from '@/views/ce-digital/sub-modules/sales/views/derivations/components/DerivationsTable.vue'

export default [
    {
        path: 'derivations',
        name: 'sales-derivation',
        component: Derivations,
        redirect: { name: 'ced-sales-derivations-lead-list' },
        meta: {
            permittedRoles: [1, 2, 14, 17],
            pageTitle: 'Derivations',
            breadcrumb: [
                {
                    text: 'Derivations',
                    active: true,
                },
            ],
            module: 26,
        },
        children: [
            {
                path: 'lead-derivations',
                name: 'ced-sales-derivations-lead-list',
                component: DerivationsTable,
                meta: {
                    isLead: true,
                    module: 26,
                    route: "Lead",
                    permittedRoles: [1, 2, 14, 17],
                    pageTitle: "Lead",
                    breadcrumb: [
                        {
                            text: "Lead",
                            active: true,
                        },
                    ],
                },
            },
            {
                path: "client-derivations",
                name: "ced-sales-derivations-client-list",
                component: DerivationsTable,
                meta: {
                    isClient: true,
                    module: 26,
                    route: "Client",
                    permittedRoles: [1, 2, 14, 17],
                    pageTitle: "Client",
                    breadcrumb: [
                        {
                            text: "Client",
                            active: true,
                        },
                    ],
                },
            },
        ],
    },
]