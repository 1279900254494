
const tabs = [
    {
        name: "connection-loyal-in-progress",
        text: "Loyal",
        active: true,
        subTabs: [
            {
                name: "connection-loyal-in-progress",
                text: "In Progress",
                active: true,
                parent: "connection-clients-loyal",
                status_id: 11
            },
            {
                name: "connection-loyal-potential",
                text: "Potential",
                active: false,
                parent: "connection-clients-loyal",
                status_id: 12

            },
            {
                name: "connection-loyal-stand-by",
                text: "Stand By",
                active: false,
                parent: "connection-clients-loyal",
                status_id: 13

            },
        ],
    },
    {
        name: "connection-outstanding-hold",
        text: "Outstanding",
        active: false,
        subTabs: [
            {
                name: "connection-outstanding-hold",
                text: "Hold",
                active: true,
                parent: "connection-clients-outstanding",
                status_id: 2
            },
            {
                name: "connection-outstanding-cancel",
                text: "Cancel",
                active: false,
                parent: "connection-clients-outstanding",
                status_id: 4
            },
            {
                name: "connection-outstanding-closed",
                text: "Closed",
                active: false,
                parent: "connection-clients-outstanding",
                status_id: 6
            },
        ],
    },
];

const permittedRoles = [1, 2, 3, 14, 15, 17];

export default [
    {
        path: "clients",
        name: "connection-clients",
        component: () =>
            import(
                /* webpackChunkName: "ConnectionClients" */
                "@/views/ce-digital/sub-modules/connection/views/clients/components/ClientsTabsv2.vue"
            ),
        redirect: { name: "connection-loyal-in-progress" },
        meta: {
            module: 20,
            pageTitle: "Clients",
            breadcrumb: [
                {
                    text: "Connection",
                    active: true,
                },
            ],
            permittedRoles: permittedRoles,

        },
        children: [
            // In Progress
            {
                path: "loyal-in-progress",
                name: "connection-loyal-in-progress",
                component: () =>
                    import(
                        /* webpackChunkName: "ConnectionLoyalInProgress" */
                        "@/views/ce-digital/sub-modules/connection/views/clients/components/NewClientsGrid/ClientsGrid.vue"
                    ),
                meta: {
                    tab: 0,
                    connectionStatus: "LOYAL",
                    module: 20,
                    pageTitle: "Clients",
                    breadcrumb: [
                        {
                            text: "Loyal",
                            active: true,
                        },
                    ],
                    permittedRoles: permittedRoles,
                    tabs: tabs
                },
            },
            // Potential
            {
                path: "loyal-potential",
                name: "connection-loyal-potential",
                component: () =>
                    import(
                        /* webpackChunkName: "ConnectionLoyalPotential" */
                        "@/views/ce-digital/sub-modules/connection/views/clients/components/NewClientsGrid/ClientsGrid.vue"
                    ),
                meta: {
                    tab: 0,
                    connectionStatus: "LOYAL",
                    module: 20,
                    pageTitle: "Clients",
                    breadcrumb: [
                        {
                            text: "Loyal",
                            active: true,
                        },
                    ],
                    permittedRoles: permittedRoles,
                    tabs: tabs
                },
            },
            // Stand By
            {
                path: "loyal-closed",
                name: "connection-loyal-stand-by",
                component: () =>
                    import(
                        /* webpackChunkName: "ConnectionLoyalStandBy" */
                        "@/views/ce-digital/sub-modules/connection/views/clients/components/NewClientsGrid/ClientsGrid.vue"
                    ),
                meta: {
                    tab: 0,
                    connectionStatus: "LOYAL",
                    module: 20,
                    pageTitle: "Clients",
                    breadcrumb: [
                        {
                            text: "Loyal",
                            active: true,
                        },
                    ],
                    permittedRoles: permittedRoles,
                    tabs: tabs
                },
            },
            // Outstanding Hold
            {
                path: "outstanding-hold",
                name: "connection-outstanding-hold",
                component: () =>
                    import(
                        /* webpackChunkName: "ConnectionOutstandingHold" */
                        "@/views/ce-digital/sub-modules/connection/views/clients/components/NewClientsGrid/ClientsGrid.vue"
                    ),
                meta: {
                    tab: 1,
                    connectionStatus: "OUTSTANDING",
                    module: 20,
                    pageTitle: "Clients",
                    breadcrumb: [
                        {
                            text: "Outstanding",
                            active: true,
                        },
                    ],
                    permittedRoles: permittedRoles,
                    tabs: tabs
                },
            },
            // Outstanding Cancel
            {
                path: "outstanding-cancel",
                name: "connection-outstanding-cancel",
                component: () =>
                    import(
                        /* webpackChunkName: "ConnectionOutstandingCancel" */
                        "@/views/ce-digital/sub-modules/connection/views/clients/components/NewClientsGrid/ClientsGrid.vue"
                    ),
                meta: {
                    tab: 1,
                    connectionStatus: "OUTSTANDING",
                    module: 20,
                    pageTitle: "Clients",
                    breadcrumb: [
                        {
                            text: "Outstanding",
                            active: true,
                        },
                    ],
                    permittedRoles: permittedRoles,
                    tabs: tabs
                },
            },
            // Outstanding Closed
            {
                path: "outstanding-closed",
                name: "connection-outstanding-closed",
                component: () =>
                    import(
                        /* webpackChunkName: "ConnectionOutstandingClosed" */
                        "@/views/ce-digital/sub-modules/connection/views/clients/components/NewClientsGrid/ClientsGrid.vue"
                    ),
                meta: {
                    tab: 1,
                    connectionStatus: "OUTSTANDING",
                    module: 20,
                    pageTitle: "Clients",
                    breadcrumb: [
                        {
                            text: "Outstanding",
                            active: true,
                        },
                    ],
                    permittedRoles: permittedRoles,
                    tabs: tabs
                },
            },
        ],
    }
];
