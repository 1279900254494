import store from "@/store"
import Vue from "vue"
import router from "@/router"
import NotificationMixin from '@/mixins/notification'

const newOtherPaymentCeFinancialRequest = () => {
  window.socket.bind("new-other-payment-ce-digital-request", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id
    const sessionRoleId = router;
    if (data.user_id.includes(Number(sessionId)) && sessionRoleId.currentRoute.matched[0]?.meta?.module == 16) {
      const htmlSwal =
        '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
        '<div><h2 style="font-weight: 600;">NEW OTHER PAYMENT REQUEST</h2></div>' +
        '<div style="text-align: left;margin-left: 50px;">' +
        '<div style="font-weight: bolder;">CLIENT: ' +
        data.client_name +
        " </div>" +
        '<div style="font-weight: bolder;">AMOUNT: ' +
        "$ " +
        data.amount +
        " </div>" +
        '<div style="font-weight: bolder;">REQUESTED BY: ' +
        data.requested_by_full_name +
        " </div>" +
        "</div>"
      const result = await Vue.swal.fire({
        // icon: "success",
        html: htmlSwal,
        showConfirmButton: true,
        confirmButtonText: "OK",
      })
      if (result.value) {
        await window.amgApi.post("/commons/close-all-swal", {to_id: data.user_id})
        NotificationMixin.methods.showWindowNotification(
          "New Other Payment Request",
          `New Other Payment Request from ${data.client_name}`,
          undefined,
          undefined,
          () => {
            window.focus()
          }
        )
        router.push(data.redirect_to)
      }
    }
  })
}
  const resultFinancialRequest = () => {
    window.socket.bind("ce-digital-result-other-payment", async (data) => {
      const sessionId = store.state.auth.currentUser.user_id
      const sessionRoleId = router;
      if (data.user_id == sessionId && sessionRoleId.currentRoute.matched[0]?.meta?.module == 23) {
        const htmlSwal =
          '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
          '<div><h2 style="font-weight: 600;">OTHER PAYMENT RESULT</h2></div>' +
          '<div style="text-align: left;margin-left: 50px;">' +
          '<div style="font-weight: bolder;">CLIENT: ' +
          data.client_name +
          " </div>" +
          '<div style="font-weight: bolder;">AMOUNT: ' +
          "$ " +
          data.amount +
          " </div>" +
          '<div style="font-weight: bolder;">RESULT: ' +
          data.result +
          " </div>" +
          "</div>"
        const result = await Vue.swal.fire({
          // icon: "success",
          html: htmlSwal,
          showConfirmButton: true,
          confirmButtonText: "OK",
        })
        if (result) {
          await window.amgApi.post("/commons/close-all-swal", {to_id: data.user_id})
        }
      }
    })
  }
  
export {newOtherPaymentCeFinancialRequest, resultFinancialRequest}
