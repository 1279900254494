import store from "@/store"
import router from "@/router/index"
import Vue from "vue"

const derivationsAlertPusher = () => {
  window.socket.bind("seller-alert-new-derivation", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id
    const roleId = store.state.auth.currentUser.role_id
    if (roleId === 1 || roleId === 2 || roleId === 16 || roleId === 17) {
      const payload = {
        routeName: "sales-derivation",
        tag: data.all_count > 99 ? "99+" : data.all_count,
      }
      store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
      await store.dispatch(
        "ceDigitalSalesDerivations/A_DERIVATION_CLIENT_COUNTER",
        data.client_count
      )
      await store.dispatch(
        "ceDigitalSalesDerivations/A_DERIVATION_LEAD_COUNTER",
        data.lead_count
      )
    }
    if (
      sessionId === data.to_id &&
      router.currentRoute.matched[0]?.meta?.module === 26
    ) {
      const htmlAlert = `
                            <img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;" alt="alert-info-image">
                            <div style="font-weight: bold; text-align: center;"><span>New Derivation</span></div>
                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span>Name: ${data.lead_client_name}</span>
                                <span>Catcher: ${data.catcher_name}</span>
                                <span>From: ${data.from_module}</span>
                            </div>
                            `
      const res = await Vue.swal.fire({
        html: htmlAlert,
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        showConfirmButton: true,
      })
      if (res.value) {
        //
      }
    }
  })
}

export default derivationsAlertPusher
