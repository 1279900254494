export default {
  namespaced: true,
  state: {
      S_IS_ACTIVE_RC: false,
      S_DATA_RC: null,
      S_TITLE_RC: null,
  },
  getters: {
      G_IS_ACTIVE_RC(state) {
          return state.S_IS_ACTIVE_RC;
      },
      G_DATA_RC(state) {
          return state.S_DATA_RC;
      },
      G_TITLE_RC(state) {
          return state.S_TITLE_RC;
      },
  },
  mutations: {
      M_OPEN_ALERT_RC(state, payload) {
          state.S_IS_ACTIVE_RC = payload;
      },
      M_DATA_RC(state, payload) {
          state.S_DATA_RC = payload;
      },
      M_TITLE_RC(state, payload) {
          state.S_TITLE_RC = payload;
      },
      M_RESET_RC(state){
          state.S_IS_ACTIVE_RC = false;
          state.S_TITLE_RC = '';
          state.S_DATA_RC = null;
      },
  }
}
