import { amgApi } from '@/service/axios'

class LoansService {
  async getLoans(params) {
    try {
      const { data } = await amgApi.post('/loans/get-loans', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getCounterLoan(params) {
    try {
      const { data } = await amgApi.post('/loans/get-counter-loans', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getCounterLoanTab(params) {
    try {
      const { data } = await amgApi.post('/loans/get-counter-loan-tab', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async insertLoan(params) {
    try {
      const { data } = await amgApi.post('/loans/insert-loan', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async importLoan(params) {
    try {
      const { data } = await amgApi.post('/loans/import-loan', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getUserData(params) {
    try {
      const { data } = await amgApi.post('/loans/get-user-loan', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getReportLoan(params) {
    try {
      const { data } = await amgApi.post('/loans/get-report-loan', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async updateDateLoan(params) {
    try {
      const data  = await amgApi.post(
        '/loans/update-date-first-due',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getDataLoan(params) {
    try {
      const { data } = await amgApi.post(`/loans/get-data-loan`, params)
      return data
    } catch (error) {
      throw error
    }
  }

  async insertStatusLoan(params) {
    try {
      const { data } = await amgApi.post('/loans/insert-status-loan', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getTrackingLoan(params) {
    try {
      const { data } = await amgApi.post('/loans/get-tracking-loan', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getTrackingLoanPayDay(params) {
    try {
      const data = await amgApi.post(
        '/loans/tracking-loan-pay-day',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getPayDayLoan(params) {
    try {
      const { data } = await amgApi.post('/loans/get-pay', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getPayListLoan(params) {
    try {
      const { data } = await amgApi.post('/loans/get-pay-list-user', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async changeStatusPayLoan(params) {
    try {
      const { data } = await amgApi.post('/loans/edit-status-pay', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async insertPay(params) {
    try {
      const { data } = await amgApi.post('/loans/insert-pay-loan', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getDue(params) {
    try {
      const { data } = await amgApi.post('/loans/get-due-user', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async removeLoan(params) {
    try {
      const { data } = await amgApi.post('/loans/remove', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getDocumentTypes() {
    try {
      const { data } = await amgApi.get('/loans/document-types/get')
      return data
    } catch (error) {
      throw error
    }
  }
  async saveExternalUser(params) {
    try {
      const { data } = await amgApi.post('/loans/external-user/save', params)
      return data
    } catch (error) {
      throw error
    }
  }
  async getExternalUsers(params) {
    try {
      const { data } = await amgApi.post('/loans/external-user/get', params)
      return data
    } catch (error) {
      throw error
    }
  }
  async deleteExternalUser(params) {
    try {
      const { data } = await amgApi.post('/loans/external-user/delete', params)
      return data
    } catch (error) {
      throw error
    }
  }
  async getExternalUser(params) {
    try {
      const { data } = await amgApi.post('/loans/external-user/get-external-user', params)
      return data
    } catch (error) {
      throw error
    }
  }
  async getExternalUsersForSelect() {
    try {
      const { data } = await amgApi.get('/loans/external-user/get-external-users-for-select')
      return data
    } catch (error) {
      throw error
    }
  }
  async saveLoanCategory(params) {
    try {
      const { data } = await amgApi.post('/loans/loan-category/save-loan-category', params)
      return data
    } catch (error) {
      throw error
    }
  }
  async getAllLoanCategories() {
    try {
      const { data } = await amgApi.get('/loans/loan-category/get-all-loan-categories')
      return data
    } catch (error) {
      throw error
    }
  }
  async getExternalUserFiles(params) {
    try {
      const { data } = await amgApi.post('/loans/external-user/get-external-user-files', params)
      return data
    } catch (error) {
      throw error
    }
  }
  async exportExcel(params) {
    try {
      const { data } = await amgApi.post('/loans/get-export-excel', params, {
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw error
    }
  }
  async sendEmailLoans(params) {
    try {
      const { data } = await amgApi.post('/loans/get-pdf-loans', params)
      return data
    } catch (error) {
      throw error
    }
  }
  
}

export default new LoansService()
