import CustomerTicketsCeo from "@/views/customer-tickets/views/CustomerTicketsCeo.vue";
export const ticketsTabs = (abv, permitted_roles = null) => {
  return [
    {
      path: "customer",
      name: `customer-ticket-${abv}`,
      redirect: { name: `customer-ticket-${abv}-in-progress` },
      component: () =>
        import(
          /* webpackChunkName: "" */ "@/views/customer-tickets/views/TicketEmpty.vue"
        ),
      meta: {
        pageTitle: "Customer Tickets",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
        permittedRoles: permitted_roles,
        routeCustomer: `customer-ticket-${abv}`,
        routeExpense: `expense-ticket-${abv}`,
        routePayment: `payment-ticket-${abv}`,
        routeCustomerInProgress: `customer-ticket-${abv}-in-progress`,
        routeCustomerCompleted: `customer-ticket-${abv}-completed`,
        routeCustomerReviewRequest: `customer-ticket-${abv}-review-request`,
        routeCustomerNew: `customer-ticket-${abv}-new`,
        routeCustomerRejected: `customer-ticket-${abv}-rejected`,
        routeCustomerNotSolved: `customer-ticket-${abv}-not-solved`,
        routeCustomerFidelized: `customer-ticket-${abv}-completed-fidelized`,
        routeCustomerNotFidelized: `customer-ticket-${abv}-completed-not-fidelized`,
        type: "customer",
      },
      children: [
        {
          path: "in-progress",
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/customer-tickets/views/CustomerTicketsModules.vue"
            ),
          name: `customer-ticket-${abv}-in-progress`,
          meta: {
            permittedRoles: permitted_roles,
            tab: abv == "ceo" ? 1 : 4,
            tabTitle: "In Progress",
            pageTitle: "Customer ticket",
            breadcrumb: [
              {
                text: "In Progress",
                active: true,
              },
            ],
          },
        },
        {
          path: "completed",
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
            ),
          name: `customer-ticket-${abv}-completed`,
          meta: {
            permittedRoles: permitted_roles,
            tab: abv == "ceo" ? 2 : 4,
            tabTitle: "Completed",
            pageTitle: "Customer ticket",
            breadcrumb: [
              {
                text: "Completed",
                active: true,
              },
            ],
          },
          children: [],
        },
        {
          path: "rejected",
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
            ),
          name: `customer-ticket-${abv}-rejected`,
          meta: {
            permittedRoles: permitted_roles,
            tab: abv == "ceo" ? 3 : 4,
            tabTitle: "New",
            pageTitle: "Customer ticket",
            breadcrumb: [
              {
                text: "New",
                active: true,
              },
            ],
          },
        },

        {
          path: "not-solved",
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
            ),
          name: `customer-ticket-${abv}-not-solved`,
          meta: {
            permittedRoles: permitted_roles,
            tab: abv == "ceo" ? 5 : 4,
            tabTitle: "Not Solved",
            pageTitle: "Customer ticket",
            breadcrumb: [
              {
                text: "Not Solved",
                active: true,
              },
            ],
          },
        },

        {
          path: "new",
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
            ),
          name: `customer-ticket-${abv}-new`,
          meta: {
            permittedRoles: permitted_roles,
            tab: abv == "ceo" ? 6 : 4,
            tabTitle: "New",
            pageTitle: "Customer ticket",
            breadcrumb: [
              {
                text: "New",
                active: true,
              },
            ],
          },
        },

        {
          path: "review-request",
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
            ),
          name: `customer-ticket-${abv}-review-request`,
          meta: {
            permittedRoles: permitted_roles,
            tab: abv == "ceo" ? 7 : 4,
            tabTitle: "Review Request",
            pageTitle: "Customer ticket",
            breadcrumb: [
              {
                text: "Review Request",
                active: true,
              },
            ],
          },
        },
        {
          path: "fidelized",
          name: `customer-ticket-${abv}-completed-fidelized`,
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
            ),

          meta: {
            tab: abv == "ceo" ? 2 : 4,
            breadcrumb: [
              {
                text: "Fidelized",
                active: true,
              },
            ],
            tabTitle: "Completed",
            pageTitle: "Customer ticket",
          },
        },
        {
          path: "not-fidelized",
          name: `customer-ticket-${abv}-completed-not-fidelized`,
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
            ),
          meta: {
            tab: abv == "ceo" ? 2 : 4,
            breadcrumb: [
              {
                text: "Not Fidelized",
                active: true,
              },
            ],
            tabTitle: "Completed",
            pageTitle: "Customer ticket",
          },
        },
      ],
    },
    {
      path: "payment",
      name: `payment-ticket-${abv}`,
      redirect: { name: `payment-ticket-${abv}-in-progress` },
      component: () =>
        import(
          /* webpackChunkName: "" */ "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
        ),
      meta: {
        pageTitle: "Payment Tickets",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
        permittedRoles: permitted_roles,
        routeCustomer: `customer-ticket-${abv}`,
        routeExpense: `expense-ticket-${abv}`,
        routePayment: `payment-ticket-${abv}`,
        routePaymentInProgress: `payment-ticket-${abv}-in-progress`,
        routePaymentCompleted: `payment-ticket-${abv}-completed`,
        routePaymentReviewRequest: `payment-ticket-${abv}-review-request`,
        routePaymentNew: `payment-ticket-${abv}-new`,
        routePaymentRejected: `payment-ticket-${abv}-rejected`,
        routePaymentNotSolved: `payment-ticket-${abv}-not-solved`,
        routePaymentFidelized: `payment-ticket-${abv}-completed-fidelized`,
        routePaymentNotFidelized: `payment-ticket-${abv}-completed-not-fidelized`,
        type: "payment",
      },
      children: [
        {
          path: "in-progress",
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/customer-tickets/views/CustomerTicketsModules.vue"
            ),
          name: `payment-ticket-${abv}-in-progress`,
          meta: {
            permittedRoles: permitted_roles,
            tab: 1,
            tabTitle: "In Progress",
            pageTitle: "Payment ticket",
            breadcrumb: [
              {
                text: "In Progress",
                active: true,
              },
            ],
          },
        },
        {
          path: "completed",
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
            ),
          name: `payment-ticket-${abv}-completed`,
          meta: {
            permittedRoles: permitted_roles,
            tab: 2,
            tabTitle: "Completed",
            pageTitle: "Payment ticket",
            breadcrumb: [
              {
                text: "Completed",
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: "expense",
      name: `expense-ticket-${abv}`,
      redirect: { name: `expense-ticket-${abv}-in-progress` },
      component: () =>
        import(
          /* webpackChunkName: "" */ "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
        ),
      meta: {
        pageTitle: "Expense Tickets",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
        permittedRoles: permitted_roles,
        routeCustomer: `customer-ticket-${abv}`,
        routeExpense: `expense-ticket-${abv}`,
        routePayment: `payment-ticket-${abv}`,
        routeExpenseInProgress: `expense-ticket-${abv}-in-progress`,
        routeExpenseCompleted: `expense-ticket-${abv}-completed`,
        routeExpenseReviewRequest: `expense-ticket-${abv}-review-request`,
        routeExpenseNew: `expense-ticket-${abv}-new`,
        routeExpenseRejected: `expense-ticket-${abv}-rejected`,
        routeExpenseNotSolved: `expense-ticket-${abv}-not-solved`,
        routeExpenseFidelized: `expense-ticket-${abv}-completed-fidelized`,
        routeExpenseNotFidelized: `expense-ticket-${abv}-completed-not-fidelized`,
        type: "expense",
      },
      children: [
        {
          path: "in-progress",
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/customer-tickets/views/CustomerTicketsModules.vue"
            ),
          name: `expense-ticket-${abv}-in-progress`,
          meta: {
            permittedRoles: permitted_roles,
            tab: 1,
            tabTitle: "In Progress",
            pageTitle: "Expense ticket",
            breadcrumb: [
              {
                text: "In Progress",
                active: true,
              },
            ],
          },
        },
        {
          path: "completed",
          component: () =>
            import(
              /* webpackChunkName: "" */ "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
            ),
          name: `expense-ticket-${abv}-completed`,
          meta: {
            permittedRoles: permitted_roles,
            tab: 2,
            tabTitle: "Completed",
            pageTitle: "Expense ticket",
            breadcrumb: [
              {
                text: "Completed",
                active: true,
              },
            ],
          },
        },
      ],
    },
  ];
};
