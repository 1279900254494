import store from "@/store"  
export default [
  {
    path: "inconsistent-transactions",
    name: "tax-research-inconsistent-transactions",
    redirect: { name: "tax-research-inconsistent-transactions-only-authorize" }, 
    component: () =>
      import(
        /* webpackChunkName: "tax-research-inconsistent-transactions" */ "@/views/commons/inconsistent-transactions/InconsistentTransactions.vue"
      ),
    meta: {
      pageTitle: "Inconsistent Transactions",
      breadcrumb: [
        {
          text: "Inconsistent Transactions",
          active: true,
        },
      ],
      permittedRoles: [1,17],
      InconsistentTransactionsOnlyAuthorize: "tax-research-inconsistent-transactions-only-authorize",
      InconsistentTransactionsOnlySoft: "tax-research-inconsistent-transactions-only-soft",
    },
    children: [
      {
        path: "only-authorize",
        name: "tax-research-inconsistent-transactions-only-authorize",
        component: () =>
          import(
            /* webpackChunkName: "tax-research-inconsistent-transactions-grid" */ "@/views/commons/inconsistent-transactions/components/InconsistentTransactionsList.vue"
          ),
        meta: {
          pageTitle: "Inconsistent Transactions",
          breadcrumb: [
            {
              text: "Only Authorize",
              active: true,
            },
          ], 
          tab: "only_authorize",
        },
      },
      {
        path: "only-soft",
        name:  "tax-research-inconsistent-transactions-only-soft",
        component: () =>
          import(
            /* webpackChunkName: "tax-research-inconsistent-transactions-grid" */ "@/views/commons/inconsistent-transactions/components/InconsistentTransactionsList.vue"
          ),
        meta: {
          pageTitle: "Inconsistent Transactions",
          breadcrumb: [
            {
              text: "Only Soft",
              active: true,
            },
          ], 
          tab: "only_soft",
        },
      }, 
    ],
  },
];
