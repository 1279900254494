import smsNestService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
import smsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";

export default {
  namespaced: true,
  state: {
    S_SHOW_ICON: true,
    S_COUNTER_MESSAGES_NOT_VIEWED: 0,
    S_COUNTER_MESSAGES_NOT_READ_PHONE: 0,
    S_COUNTER_MESSAGES_NOT_READ_LEAD: 0,
    S_COUNTER_ALL_MESSAGES_UNREAD: 0,
    S_ALL_CONTACTS_BY_NUMBER: [],

    S_CURRENT_CHAT_MESSAGES: [],

    S_CURRENT_CHAT_FILES: {
      counter: 0,
      files: []
    },
    S_CURRENT_CREDENTIAL: null,
    S_CURRENT_TAB_CONTACTS: "INTERACTED",
    S_USERS_IMAGES: [],

    S_LIST_ACTIVE: false,
    S_LIST_TAB: 'active',

    S_ALL_TOPICS_BY_NUMBER: [],
    S_CURRENT_TOPIC: null,
    S_CURRENT_TOPIC_MESSAGES: [],

    S_CURRENT_TOPIC_LEADS_COUNT: 0,

  },
  getters: {
    G_SHOW_ICON(state) {
      return state.S_SHOW_ICON;
    },

    G_LIST_ACTIVE(state) {
      return state.S_LIST_ACTIVE;
    },

    G_LIST_TAB(state) {
      return state.S_LIST_TAB;
    },

    G_USERS_IMAGES(state) {
      return state.S_USERS_IMAGES;
    },

    G_CURRENT_TAB_CONTACTS(state) {
      return state.S_CURRENT_TAB_CONTACTS;
    },

    G_CURRENT_CREDENTIAL(state) {
      return state.S_CURRENT_CREDENTIAL
    },

    G_COUNTER_MESSAGES_NOT_VIEWED(state) {
      return state.S_COUNTER_MESSAGES_NOT_VIEWED
    },

    G_COUNTER_MESSAGES_NOT_READ_PHONE(state) {
      return state.S_COUNTER_MESSAGES_NOT_READ_PHONE
    },

    G_CONTACTS_BY_CREDENTIAL(state) {
      return state.S_ALL_CONTACTS_BY_NUMBER;
    },

    G_CURRENT_CHAT_FILES(state) {
      return state.S_CURRENT_CHAT_FILES;
    },

    G_COUNTER_MESSAGES_NOT_READ_LEAD(state) {
      return state.S_COUNTER_MESSAGES_NOT_READ_LEAD;
    },

    G_COUNTER_ALL_MESSAGES_UNREAD(state) {
      return state.S_COUNTER_ALL_MESSAGES_UNREAD;
    },



    G_ALL_TOPICS_BY_NUMBER(state) {
      return state.S_ALL_TOPICS_BY_NUMBER;
    },

    G_CURRENT_TOPIC(state) {
      return state.S_CURRENT_TOPIC;
    },

    G_CURRENT_TOPIC_MESSAGES(state) {
      return state.S_CURRENT_TOPIC_MESSAGES;
    },

    G_CURRENT_TOPIC_LEADS_COUNT(state) {
      return state.S_CURRENT_TOPIC_LEADS_COUNT;
    }

  },
  mutations: {
    M_SET_LIST_ACTIVE(state, payload) {
      state.S_LIST_ACTIVE = payload;
    },

    M_SET_LIST_TAB(state, payload) {
      state.S_LIST_TAB = payload;
    },

    M_SET_CURRENT_TOPIC_LEADS_COUNT(state, payload) {
      state.S_CURRENT_TOPIC_LEADS_COUNT = payload;
    },

    M_SET_USERS_IMAGES(state, payload) {
      state.S_USERS_IMAGES = payload;
    },

    M_SET_SHOW_ICON(state, payload) {
      state.S_SHOW_ICON = payload;
    },

    M_SET_CURRENT_TAB_CONTACTS(state, payload) {
      state.S_CURRENT_TAB_CONTACTS = payload;
    },


    M_SET_CURRENT_CREDENTIAL(state, payload) {
      state.S_CURRENT_CREDENTIAL = payload;
    },

    M_SET_COUNTER_MESSAGES_NOT_READ_PHONE(state, payload) {
      state.S_COUNTER_MESSAGES_NOT_READ_PHONE = payload;
    },

    M_SET_COUNTER_MESSAGES_NOT_READ_LEAD(state, payload) {
      state.S_COUNTER_MESSAGES_NOT_READ_LEAD = payload;
    },

    M_SET_COUNTER_ALL_MESSAGES_UNREAD(state, payload) {
      state.S_COUNTER_ALL_MESSAGES_UNREAD = payload;
    },


    M_SET_ADD_CONTACT(state, payload) {
      state.S_ALL_CONTACTS_BY_NUMBER = payload;
    },


    M_SET_CURRENT_CHAT_FILES(state, payload) {
      const images = payload.filter((file) => file.contentType.startsWith(
        'image/'
      ));

      const files = payload.filter((file) => !file.contentType.startsWith(
        'image/'
      ));

      state.S_CURRENT_CHAT_FILES = {
        images,
        imagesCounter: images.length,
        files,
        filesCounter: files.length,
        all: payload
      };
    },


    M_ADD_LAST_CONTACT(state, payload) {
      if (payload.credential_id != state.S_CURRENT_CREDENTIAL) return;

      const existingContactIndex = state.S_ALL_CONTACTS_BY_NUMBER.findIndex(contact => contact.id === payload.id);
      if (existingContactIndex !== -1) {
        state.S_ALL_CONTACTS_BY_NUMBER.splice(existingContactIndex, 1);
      }
      state.S_ALL_CONTACTS_BY_NUMBER.unshift(payload);
    },


    M_SET_ALL_TOPICS_BY_NUMBER(state, payload) {
      state.S_ALL_TOPICS_BY_NUMBER = payload;
    },

    M_SET_CURRENT_TOPIC(state, payload) {
      state.S_CURRENT_TOPIC = payload;
    },

    M_SET_CURRENT_TOPIC_MESSAGES(state, payload) {
      state.S_CURRENT_TOPIC_MESSAGES = payload;
    },

    M_UPDATE_TOPIC_BY_ID(state, payload) {
      const { topicId, column, value } = payload;
      const existingContactIndex = state.S_ALL_TOPICS_BY_NUMBER.findIndex(contact => contact.id == topicId);
      if (existingContactIndex !== -1) {
        state.S_ALL_TOPICS_BY_NUMBER[existingContactIndex][column] = value;
      }
    },

    M_ADD_LAST_TOPIC(state, payload) {
      state.S_ALL_TOPICS_BY_NUMBER.unshift(payload);
    },

    M_REMOVE_TOPIC_BY_ID(state, payload) {
      const existingContactIndex = state.S_ALL_TOPICS_BY_NUMBER.findIndex(contact => contact.id === payload);
      if (existingContactIndex !== -1) {
        state.S_ALL_TOPICS_BY_NUMBER.splice(existingContactIndex, 1);
      }
    },

  },

  actions: {
    async A_GET_CURRENT_CHAT_FILES({ commit }, id) {
      try {
        const response = await smsNestService.getAllFiles(id);
        commit("M_SET_CURRENT_CHAT_FILES", response.data);
        return response;
      } catch (error) {
        throw error;
      }
    },

    async A_GET_COUNTER_UNREAD_MESSAGES({ commit }, body) {
      try {
        const { data } = await smsNestService.getCounterUnReadMessages(body);
        commit("M_SET_COUNTER_MESSAGES_NOT_READ_PHONE", data);
        return data;
      } catch (error) {
        throw error;
      }
    },

    async A_GET_USERS_IMAGES({ commit }, body) {
      try {
        const data = await smsService.getAllUsersByModule(body);
        commit("M_SET_USERS_IMAGES", data);
        return data;
      } catch (error) {
        throw error;
      }
    },

    async A_GET_ALL_MESSAGES_UNREAD({ commit }) {
      try {
        const { data } = await smsNestService.getAllMessagesUnread();
        commit("M_SET_COUNTER_ALL_MESSAGES_UNREAD", data);
        return data;
      } catch (error) {
        throw error;
      }
    },

    async A_GET_COUNTER_MESSAGES_NOT_READ_LEAD({ commit }, body) {
      try {
        const { data } = await smsNestService.getAllMessagesUnreadByLeadPhone(body);
        commit("M_SET_COUNTER_MESSAGES_NOT_READ_LEAD", data);
        return data;
      } catch (error) {
        throw error;
      }
    },

    async A_GET_CURRENT_TOPIC_LEADS_COUNT({ commit }, body) {
      try {
        const { data } = await smsService.getLeadsListTotal(body);
        commit("M_SET_CURRENT_TOPIC_LEADS_COUNT", data);
        return data;
      } catch (error) {
        throw error;
      }
    },

  },
};
