<template>
  <b-modal
    v-model="control"
    size="lg"
    header-bg-variant="custom-amg"
    header-text-variant="dark"
    modal-class="custom-modal-amg"
    body-text-variant="dark"
    footer-text-variant="dark"
    no-close-on-backdrop
    scrollable
    class="border-0 border-white"
    header-class="p-0"
    hide-footer
    @hidden="closeModal"
    title-class="h1 text-white"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div
          v-if="
            !customerTicket.expense_id && !customerTicket.expense_transaction_id
          "
          class="d-flex align-items-center"
        >
          <h3
            class="text-uppercase font-weight-bolder"
            style="margin: 10px 0px"
          >
            <router-link
              class="text-white"
              style="color: white !important"
              :to="{
                name: dashboardRouteNameByClient(customerTicket),
                params: {
                  idClient: customerTicket.client_account_id,
                },
              }"
              target="_blank"
            >
              <feather-icon icon="LinkIcon" size="20" /> Redirect to
              {{
                customerTicket.name_client +
                " " +
                (customerTicket.account ? customerTicket.account : "")
              }}
              dashboard.</router-link
            >
          </h3>
        </div>
        <div v-else class="d-flex align-items-center">
          <h3 class="text-uppercase font-weight-bolder text-white">
            Expense Ticket Details
          </h3>
        </div>

        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--white' : ''"
          @click="closeModal()"
        >
          <feather-icon icon="XIcon" size="18" class="text-primary pointer" />
        </div>
      </div>
    </template>
    <b-row>
      <b-col cols="12">
        <div class="ticket-field">
          <b-card
            :style="
              isDarkSkin
                ? 'background-color: #1e1e1e'
                : 'background-color: #fff'
            "
            text-variant="white"
            class="text-left"
          >
            <h3 class="text-left text-uppercase font-weight-bolder">
              Ticket Details
            </h3>
            <hr />
            <b-row>
              <b-col cols="6">
                <b-card
                  :class="{ 'card-light text-dark': !isDarkSkin }"
                  body-class="p-1"
                >
                  <span class="title">Motive: </span>
                  <b-badge
                    pill
                    variant="primary"
                    style="font-size: 15px; padding: 3px 10px"
                  >
                    {{ customerTicket.motive }}
                  </b-badge>
                </b-card>
              </b-col>
              <b-col cols="6">
                <b-card
                  :class="{ 'card-light text-dark': !isDarkSkin }"
                  body-class="d-flex p-1"
                >
                  <span class="title">Ticket code:</span>
                  <h4 class="m-0">
                    {{ customerTicket.code }}
                  </h4>
                  <b-badge
                    pill
                    class="ml-1 clickable"
                    variant="primary"
                    @click="openModalParticipants()"
                  >
                    Show Participants
                  </b-badge>
                </b-card>
              </b-col>
              <b-col
                cols="6"
                v-if="
                  !customerTicket.expense_id &&
                  !customerTicket.expense_transaction_id
                "
              >
                <b-card
                  :class="{ 'card-light text-dark': !isDarkSkin }"
                  body-class="p-1"
                >
                  <label class="title">Customer:</label>
                  <h4 class="m-0" style="margin-bottom: 5px !important">
                    {{ customerTicket.name_client }}
                  </h4>
                </b-card>
              </b-col>
              <b-col cols="6">
                <b-card
                  :class="{ 'card-light text-dark': !isDarkSkin }"
                  body-class="p-1"
                >
                  <label class="title">Created By:</label>
                  <h4
                    class="d-flex m-0 justify-content-between align-items-end"
                  >
                    <div style="margin-bottom: 5px">
                      {{ customerTicket.created_by }}
                    </div>
                    <div style="font-size: 13px">
                      {{ customerTicket.created_at | myGlobalDay }}
                    </div>
                  </h4>
                </b-card>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-card
                  :class="{ 'card-light text-dark': !isDarkSkin }"
                  body-class="p-1"
                >
                  <label class="title">Description:</label>
                  <p
                    style="
                      font-family: Montserrat;
                      font-weight: bold;
                      font-size: 14px;
                      text-align: justify;
                      margin: 20px 0px;
                    "
                  >
                    <b-form-textarea
                      id="textarea-plaintext"
                      plaintext
                      :value="customerTicket.description"
                      rows="4"
                      no-resize
                    />
                  </p>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-card
                  :class="{ 'card-light': !isDarkSkin }"
                  body-class="d-flex"
                >
                  <label class="title">Status:</label>
                  <h4 class="m-0">
                    <b-badge
                      style="padding: 3px 10px"
                      pill
                      :variant="colorStatus(customerTicket)"
                    >
                      {{ customerTicket.status.toUpperCase() }}
                    </b-badge>
                  </h4>
                </b-card>
              </b-col>
              <b-col cols="6">
                <b-card
                  :class="{ 'card-light': !isDarkSkin }"
                  body-class="d-flex"
                >
                  <label class="title">Priority:</label>
                  <h4 class="m-0">
                    <b-badge
                      style="padding: 3px 10px"
                      pill
                      :variant="colorPriority(customerTicket)"
                    >
                      {{ customerTicket.priority.toUpperCase() }}
                    </b-badge>
                  </h4>
                </b-card>
              </b-col>
            </b-row>

            <b-card
              v-if="uploadedFiles.length !== 0"
              title="Attachments"
              class="mt-2"
            >
              <b-list-group-item
                v-for="(file_data, index) in uploadedFiles"
                :key="index"
                class="p-0"
                style="
                  padding: 0.5rem !important;
                  margin: 0.5rem 0;
                  border-radius: 10px;
                  background-color: transparent;
                "
                :style="
                  isDarkSkin
                    ? 'border: solid 1px white;'
                    : 'border: solid 1px gray;'
                "
              >
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <div style="display: flex; gap: 1rem; align-items: center">
                    <span class="text-primary">
                      <amg-icon
                        v-if="obtenerExtension(file_data.file_name) === 'docx'"
                        class="text-primary"
                        icon="FileWordIcon"
                        size="18"
                      />
                      <amg-icon
                        v-else-if="
                          obtenerExtension(file_data.file_name) === 'pdf'
                        "
                        class="text-danger"
                        icon="FilePdfIcon"
                        size="18"
                      />
                      <amg-icon
                        v-else-if="
                          obtenerExtension(file_data.file_name) === 'xlsx'
                        "
                        class="text-success"
                        icon="FileExcelIcon"
                        size="18"
                      />
                      <amg-icon
                        v-else-if="
                          obtenerExtension(file_data.file_name) === 'pptx' ||
                          obtenerExtension(file_data.file_name) === 'ppt'
                        "
                        class="text-warning"
                        icon="FilePowerpointIcon"
                        size="18"
                      />
                      <feather-icon
                        v-else-if="
                          obtenerExtension(file_data.file_name) === 'jpg' ||
                          obtenerExtension(file_data.file_name) === 'png'
                        "
                        class="text-info"
                        icon="ImageIcon"
                        size="18"
                      />
                      <tabler-icon
                        v-else
                        class="text-dark"
                        icon="FileDownloadIcon"
                        size="18"
                      />
                    </span>
                    <small
                      :href="file_data.file_route"
                      target="_blank"
                      :style="isDarkSkin ? 'color: white' : 'color: black'"
                      class="cursor-pointer"
                      @click="downloadFile(file_data.file_route)"
                    >
                      Click to Download Ticket Customer File
                      {{ index + 1 }} .{{
                        obtenerExtension(file_data.file_name)
                      }}
                    </small>
                  </div>
                  <div>
                    <span
                      v-b-tooltip.hover.top="'Download'"
                      class="bg-primary text-light cursor-pointer"
                      style="
                        padding: 0.3rem;
                        border-radius: 100%;
                        display: flex;
                      "
                      @click="downloadFile(file_data.file_route)"
                    >
                      <tabler-icon icon="CloudDownloadIcon" size="20" />
                    </span>
                  </div>
                </div>
              </b-list-group-item>
            </b-card>

            <b-card v-else no-body>
              <h4 class="text-info p-1 m-0">
                <feather-icon icon="XIcon" size="20" /> NO FILES ATTACHED
              </h4>
            </b-card>

            <b-card :class="{ 'card-light': !isDarkSkin }">
              <label class="title">Observation: </label>
              <p v-if="customerTicket.observations" class="text-dark">
                {{ customerTicket.observations }}
              </p>
              <p v-else class="text-dark text-center">No observations</p>
            </b-card>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <list-participants
      v-if="showModalParticipants"
      :params="paramsModalParticipants"
      @hidden="showModalParticipants = false"
    />
  </b-modal>
</template>

<script>
import GlobalService from "@/views/services/global.service";
import ListParticipants from "@/views/commons/participants-tickets/ListParticipantsTickets.vue";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";

export default {
  components: {
    ListParticipants,
  },
  props: {
    customerTicket: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fileUrl: null,
      control: false,
      uploadedFiles: [],
      paramsModalParticipants: {},
      showModalParticipants: false,
    };
  },
  mounted() {
    this.control = true;
    console.log(this.customerTicket);
  },

  created() {
    this.getFilesSaved();
  },

  methods: {
    openModalParticipants() {
      this.paramsModalParticipants = {
        ...this.customerTicket,
        readonly: true,
      };
      this.showModalParticipants = true;
    },
    obtenerExtension(nombreArchivo) {
      // Dividir el nombre del archivo en partes usando el punto como separador
      const partes = nombreArchivo.split(".");

      // Tomar la última parte que es la extensión
      const extension = partes[partes.length - 1];

      return extension;
    },

    downloadFile(file) {
      window.open(file, "_blank");
    },

    dashboardRouteNameByClient(account) {
      let route = null;
      if (account.program_id !== 3) {
        const routers = {
          1: "bussiness-dashboard-general",
          2: "boost-credit-dashboard-general",
          // 3: 'credit-experts-dashboard-general',
          4: "debt-solution-dashboard-general",
          5: "tax-research-dashboard-general",
          6: "court-info-dashboard-general",
          7: "specialists-dashboard-general",
          8: "bookeeping-dashboard-general",
          9: "paragon-dashboard-general",
          // 18: 'quality-client-dashboard',
          // 22: 'ce-customer-service-dashboard',
        };
        route = routers[account.program_id] || null;
      }
      if (account.program_id === 3) {
        if (account.client_type_id === 1)
          route = "credit-experts-dashboard-general";
        if (account.client_type_id === 2)
          route = "ce-customer-service-dashboard-general";
      }
      return route;
    },

    async getFilesSaved() {
      const params = {
        ca_id: this.customerTicket.id,
      };

      const response = await CustomerTicketsService.getFilesSaved(params);
      this.uploadedFiles = response.data;
    },

    async openModalClientDetail(item) {
      const params = {
        client_account_id: item,
      };

      console.log(params);
      const [clientInfo] = await GlobalService.getDetailsClient(params);
      this.clientInformation = clientInfo;
      this.modalClientDetailC = true;
    },

    closeModal() {
      this.$emit("hidden");
    },

    colorStatus(item) {
      const color = {
        1: "light-secondary",
        2: "light-primary",
        3: "light-warning",
        4: "light-danger",
        5: "light-info",
        6: "light-success",
        7: "light-info",
      };
      return color[item.id_status];
    },

    colorPriority(item) {
      const color = {
        low: "light-primary",
        medium: "light-warning",
        high: "light-danger",
      };
      return color[item.priority];
    },
  },
};
</script>

<style scoped lang="scss">
/* Your styles here */
.header-modal {
  border-radius: 3px 3px 0px 0px;
  position: relative;
  padding: 20px 0 20px 15px;
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
.header-modal {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}
.ticket-info {
  padding: 20px;
}

.ticket-field {
  margin-bottom: 10px;
}

/* Estilos adicionales personalizables */
.label {
  font-weight: bold;
  margin-right: 10px;
}

.title {
  font-weight: bold;
  margin-right: 10px;
  font-size: 1rem;
  font-family: Montserrat;
}

.card-light {
  background-color: #f1f3f4 !important;
}
</style>
