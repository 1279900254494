import LeadTableSales from '@/views/commons/components/lead-programs/components/lead-table/LeadTableSales.vue'

export default [
  {
    path: "leads",
    name: "credit-experts-leads",
    redirect: { name: "credit-experts-leads-list" },
    component: () =>
      import(/* webpackChunkName: "CreditExpertsLeads" */ "@/views/commons/components/lead-programs/Lead.vue"),
    meta: {
      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Leads",
        },
      ],
    },
    children: [
      {
        path: "",
        name: "credit-experts-leads-list",
        component: () => import(/* webpackChunkName: "CreditExpertsLeadsList" */ "@/views/commons/components/lead-programs/components/lead-table/LeadTable.vue"),
        meta: {
          route: "creditexperts",
          isClientsTab: true,
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
              to: "/creditexperts/leads",
            
            },
          ],
        },
      },
         // Leads with sales
         {
          path: "sales",
          name: "credit-experts-leads-sales",
          component: LeadTableSales,
          meta: {
              module: 6,
              isClientsTab: true,
              pageTitle: "Leads",
              route: "creditexperts",
              breadcrumb: [
                  {
                      text: "Sales",
                  },
              ],
          },
      },
    ],
  },
  {
    path: "leads/show/:id",
    name: "creditexperts-lead-show",
    component: () =>
      import(/* webpackChunkName: "CreditExpertsLeadsShow" */ "@/views/crm/views/Lead/lead-module/dashboard/default.vue"),
    props: true,
    meta: {
      isClientsTab: true,
      route: "creditexperts",
      contentClass: "leads-app",
    },
  },
  {
    path: "leads/report/:idlead/:idfile",
    name: "credit-experts-report-lead",
    component: () => import(/* webpackChunkName: "CreditExpertsLeadsReport" */ "@/views/commons/components/ncr/components/report/ReportLead.vue"),
    props: true,
    meta: {
      isClientsTab: true,
      pendingRoute: "ncr-pending",
      returnedRoute: "ncr-returned",
      completedRoute: "ncr-completed",
      reportLeadRoute: "report-lead",
      leadRoute: "lead-show",
    },
  },
];
