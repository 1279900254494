<template>
  <div>
    <b-tabs
      active-tab-class="p-0 "
      pills
      nav-class="mb-0 mt-1"
      active-nav-item-class="bg-info box-shadow-info border-info info"
    >
      <b-tab
        v-for="(title, index) in ['PENDING', 'CONFIRMED', 'ANNULLED']"
        :key="index"
        :title="title"
        :active="index === 0"
        @click="changeTab(index + 1)"
        :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
      >
        <template #title>
          {{ title }}
          <span class="ml-1">
            <feather-icon
              v-if="getCounterByTabTitle(index) > 0"
              icon
              :badge="getCounterByTabTitle(index)"
              badge-classes="badge-danger"
            />
          </span>
        </template>
      </b-tab>
      <div>
        <div class="d-flex justify-content-end">
          <b-button
            v-if="showCreateButton"
            @click="showAddOrEditExpenseModal = true"
            variant="primary"
          >
            {{ tabSelected === 1 ? 'Add Check' : 'Add E-Check' }}
          </b-button>
        </div>
        <check-expenses-table :tab="tab" :caId="$route.params.idClient" :method="tabSelected === 1 ? 'CHECK' : 'E-CHECK'" />
      </div>
    </b-tabs>
    <add-or-edit-expense-modal
      v-if="showAddOrEditExpenseModal"
      :account_number="client.account"
      :programIdClient="client.program_id"
      :tab="tabSelected === 1 ? 'CHECK' : 'E-CHECK'"
      @reload="refresh()"
      @close="showAddOrEditExpenseModal = false"
      :hasCheck="true"
    />
  </div>
</template>
<script>
import CheckExpensesTable from "@/views/commons/components/clients/dashboard/options/pay-module/components/CheckExpensesTable.vue";
import AddOrEditExpenseModal from "@/views/commons/expenses/components/Modal/AddOrEditExpense.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    CheckExpensesTable,
    AddOrEditExpenseModal,
  },
  props: {
    showCreateButton: {
      type: Boolean,
      default: false
    },
    tabSelected: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      tab: 1,
      showAddOrEditExpenseModal: false,
      pendingCounter: 0,
      confirmedCounter: 0,
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      pending_expenses: "ExpensesStore/G_PENDING_CHECK_EXPENSES_CLIENT",
      confirmed_expenses: "ExpensesStore/G_CONFIRMED_CHECK_EXPENSES_CLIENT",
      pending_ec_expenses: "ExpensesStore/G_PENDING_ECHECK_EXPENSES_CLIENT",
      confirmed_ec_expenses: "ExpensesStore/G_CONFIRMED_ECHECK_EXPENSES_CLIENT",
    }),
  },
  methods: {
    ...mapActions({
      refreshAfterCreateExpense: "ExpensesRequest/refreshAfterCreateExpense",
      refreshCountCheckExpenses:
        "ExpensesStore/A_COUNT_CHECK_EXPENSES_BY_CLIENT",
        refreshCountECheckExpenses:
        "ExpensesStore/A_COUNT_ECHECK_EXPENSES_BY_CLIENT",
    }),
    async changeTab(tab) {
      this.tab = tab;
    },
    getCounterByTabTitle(index) {
      const counters = {
        1: this.tabSelected == 1 ? this.pending_expenses : this.pending_ec_expenses,
        2: this.tabSelected == 1 ? this.confirmed_expenses : this.confirmed_ec_expenses,
      };

      return counters[index + 1] || 0;
    },
    refresh(){
      this.refreshAfterCreateExpense(true);
      this.refreshCountCheckExpenses(this.$route.params.idClient);
      this.refreshCountECheckExpenses(this.$route.params.idClient);
    }
  },
};
</script>