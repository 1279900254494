<template>
  <div class="details">
    <b-row v-if="details">
      <template v-for="(detail, key) in dataDetails">
        <b-col
          :sm="'12'"
          v-bind:key="key"
          v-if="detail && detail.length && includeElements(detail)"
        >
          <detail-component :details="detail" />
        </b-col>
        <template v-else-if="detail && !detail.length">
          <b-col
            cols="12"
            md="6"
            lg="12"
            sm="6"
            xl="6"
            v-bind:key="key"
            class="pt-1 pb-0 px-2 mb-50 custom-rounded"
            :class="{
              'bg-white': !isDarkSkin,
              'bg-dark': isDarkSkin,
              'rounded-left': key % 2 == 0,
              'rounded-right': key + 1 == dataDetails.length || key % 2 != 0,
            }"
          >
            <b-form-group style="border-style: none !important">
              <template #label>
                <div class="d-flex justify-content-left align-items-center">
                  <span class="bg-secondary circle-point mr-1"></span>
                  {{ detail.label }}
                </div>
              </template>
              <span v-if="detail.value" v-html="detail.value"></span>
              <span
                v-else
                :class="isDarkSkin ? 'text-secondary' : 'text-light'"
              >
                -
              </span>
            </b-form-group>
          </b-col>
        </template>
      </template>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import Ripple from "vue-ripple-directive";

import DetailComponent from "@/views/crm/views/Lead/components/DetailComponent.vue";

export default {
  name: "DetailComponent",
  components: {
    DetailComponent,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      /* G_TEMPLATES: 'CrmTemplateStore/G_TEMPLATES' */
    }),
    ...mapState({
      /* S_TEMPLATES: event => event.CrmTemplateStore.S_TEMPLATES */
    }),
  },
  created() {
    this.authUser = this.currentUser;
    this.dataDetails = this.details.flat().filter((item) => !!item);
  },
  data() {
    return {
      authUser: {},
      dataDetails: [],
    };
  },
  directives: { Ripple },
  methods: {
    ...mapActions({
      /* A_GET_TEMPLATES: 'CrmTemplateStore/A_GET_TEMPLATES' */
    }),
    includeElements(detail) {
      let result = 0;
      detail.forEach((element) => {
        if (element !== null) result++;
      });
      return result;
    },
  },
  mounted() {},
  props: {
    details: {
      type: Array,
      required: true,
    },
  },
  setup() {},
};
</script>

<style lang="scss">
.details {
  .form-group {
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(80, 85, 99, 0.2);
    height: calc(100% - 1rem);
    > div {
      min-height: 19.56px;
    }
  }
  .col-form-label {
    font-weight: 600;
    font-size: 1rem;
  }
}
.circle-point {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: flex;
}
@media screen and(max-width: 575px) {
  .custom-rounded {
    border-radius: 5px !important;
  }
}
</style>
