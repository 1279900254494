import SchedulesRouter from '@/views/rrhh/views/schedules/schedules.router';
import EmployeesRouter from '@/views/rrhh/views/employees/employees.router';
import FileManagerRouter from '@/views/rrhh/views/file-manager/file-manager.router';
import LoansRouter from '@/views/rrhh/views/loans/loans.router';
import HelpdeskRouter from '@/views/rrhh/views/helpdesk/helpdesk.route.js';
import ProductRequestRouter from '@/views/rrhh/views/request/request.router.js';
import HumanTalentMain from '@/views/rrhh/views/HumanTalentMain.vue';
import salaryRecordRouter from '@/views/rrhh/views/salary-record/salary-record.router.js';
import QrGenerator from '@/views/rrhh/views/qr-generator/qr-generator.router.js';
import RrhhAttendanceRouter from '@/views/rrhh/views/attendance/rrhh-attendance.router';
import RrhhPayrollPaymentsRouter from '@/views/rrhh/views/payroll-payments/rrhh-payroll-payments.router';
import SettingsRouter from '@/views/rrhh/views/settings/setting.router.js';
import SettingsLuiggiRouter from '@/views/rrhh/settings/router/settings.router.js';
import EmployeeClaimsRouter from '@/views/rrhh/views/employee-claims/router/employee-claims.router';
//import RrhhProjectsRouter from '@/views/rrhh/views/projects/rrhh-projects.router';
import CallLogRouter from '@/views/rrhh/views/call-log/call-log.router.js';
import OrganizationChartRouter from '@/views/rrhh/views/organization-chart/organization-chart.router';
import ManualOfFunctionsRouter from '@/views/rrhh/views/manual-of-functions/manual-of-functions.router.js';
import DashboardRouter from '@/views/rrhh/views/dashboard/dashboard.router.js';
import EssentialDocuments from '@/views/rrhh/views/essential-documents/router/essential-documents.router';
import PsycholaboralEvaluationSettings from '@/views/rrhh/views/psycholaboral-evaluation-settings/router/psycholaboral-evaluation-settings.router';
import RecruitmentProcessRouter from '@/views/rrhh/views/recruitment-process/rrhh-recruitment_process.router';
import CustomerTicketsRouter from '@/views/rrhh/views/customer-tickets/rrhh-customer-tickets.router';

const routes = [
  {
    path: '/rrhh',
    redirect: { name: 'dashboard-employee-claims-rrhh' },
    meta: { module: 17 },
    component: HumanTalentMain,
    children: [
      ...DashboardRouter,
      ...SchedulesRouter,
      ...EmployeesRouter,
      ...FileManagerRouter,
      ...LoansRouter,
      HelpdeskRouter,
      ...ProductRequestRouter,
      ...salaryRecordRouter,
      ...QrGenerator,
      ...RrhhAttendanceRouter,
      ...salaryRecordRouter,
      //...RrhhProjectsRouter,
      ...RrhhPayrollPaymentsRouter,
      ...SettingsRouter,
      ...SettingsLuiggiRouter,
      ...EmployeeClaimsRouter,
      ...CallLogRouter,
      ...OrganizationChartRouter,
      ...RecruitmentProcessRouter,
      ...ManualOfFunctionsRouter,
      ...EssentialDocuments,
      ...PsycholaboralEvaluationSettings,
      ...CustomerTicketsRouter,

    ],
  },
];
export default routes;
