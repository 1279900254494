import store from "@/store";
import Notifications from "@/service/notification/index";
import DirectsRoundService from "@/views/specialists/views/direct-round/directs-round.service.js";
import NcrRealtorService from "@/views/specialists/views/ncr-realtor/ncr-realtor.service.js";
import NcrProgramsService from "@/views/commons/components/ncr-programs/service/ncr-programs.service";
import ZeroPaymentService from "@/views/specialists/views/zero-payment/zero-payment.service";
import chargeBackV2Service from "@/views/commons/components/charge-back_v2/service/charge.back.service.js";
import employeeClaims from "@/views/commons/commons-counter";
import InquiriesClaimService from "@/views/commons/components/claims_v2/views/claim-inquiries/services/inquiry-claim.service.js";
import RequestService from "@/views/commons/components/request/services/request.service.js";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";
import RecruitmentService from "@/views/commons/components/recruitment-process/services/recruiment.service";

const pendingDirectRounds = async () => {
  const data = await DirectsRoundService.getPendingDirectRoundCount();
  if (data > 0) {
    const payload = {
      routeName: "specialists-direct-round",
      tag: data > 99 ? "+99" : data,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
    store.dispatch(
      "SpecialistDirectRoundStore/A_SET_COUNTER_DIRECT_ROUND_SPECIALIST",
      { pending: data }
    );
  }
};

const pendingNcrRealtor = async () => {
  const params = {
    iduser: store.state.auth.currentUser.user_id,
    num: 1,
    is_digital: 1,
  };
  const data = await NcrRealtorService.getPendingNcrRealtorCount(params);
  if (data.length > 0) {
    const payload = {
      routeName: "specialists-ncr-realtor",
      tag: data > 99 ? "+99" : data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
    store.dispatch(
      "SpecialistNcrRealtorStore/A_SET_COUNTER_NCR_REALTOR_SPECIALIST",
      { pending: data[0].cn }
    );
  }
};

const ncrProgramsCounter = async () => {
  const params = {
    id: store.state.auth.currentUser.user_id,
    type: 1,
    program: 7,
    role: store.state.auth.currentUser.role_id,
    modul: store.state.auth.currentUser.modul_id,
  };
  const data = await NcrProgramsService.getCounterNcrPrograns(params);
  if (data.length > 0) {
    const payload = {
      routeName: "specialists-ncr-programs",
      tag: data[0].cont,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const zeroPaymentCounter = async () => {
  const params = {
    id_global: 1,
    id_modul: 7,
    id_role: store.state.auth.currentUser.role_id,
    id_status: 3,
  };

  const data = await ZeroPaymentService.getZeroPaymentCounter(params);
  const counter = data.data.length;
  if (counter > 0) {
    const payload = {
      routeName: "specialists-zero-payment",
      tag: counter,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
    store.dispatch(
      "SpecialistZeroPaymentStore/A_SET_COUNTER_ZERO_PAYMENT_SPECIALISTS",
      { pending: data.length }
    );
  }
};

const chargebackCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;

  const params = {
    module_id: module == 28 ? 11 : module,
  };

  const { in_process, remove_cb, payment_client, pending, observed } =
    await chargeBackV2Service.getChargebackCounters(params);

  const solution = remove_cb + payment_client;
  const dispute = pending + observed;
  const counterSide = in_process + solution + dispute;

  const payload = {
    routeName: "analyst-department-charge-back",
    tag: counterSide,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "specialists-projects",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const inquiriesCounter = async () => {
  const { pending_inquiries, solved_inquiries } =
    await InquiriesClaimService.getInquiriesCounter({
      user_id: store.state.auth.currentUser.user_id,
    });

  if (pending_inquiries > 0) {
    const payload = {
      // routeName: 'specialists-inquiries',
      routeName: "analyst-department-inquiries",
      tag: pending_inquiries > 99 ? "+99" : pending_inquiries,
    };

    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: "request-specialists",
    tag: data[0].counter,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const requestNcrCounter = async () => {
  const { pending_ncr_client, pending_ncr_lead } =
    await NcrRequestService.getCountersNcr({});

  const sideCounter = pending_ncr_client + pending_ncr_lead;

  const payload = {
    routeName: "request-ncr-specialists",
    tag: sideCounter > 99 ? "+99" : sideCounter,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      // routeName: 'specialists-recruitment-process',
      routeName: "analyst-department-recruitment-process",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({
    user_id,
    moduleId,
  });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: "analyst-department-customer-tickets",
      tag: data[0].v_total_open,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const allCounterSpecialists = async () => {
  Promise.all([
    pendingDirectRounds(),
    projectsCounter(),
    pendingNcrRealtor(),
    ncrProgramsCounter(),
    zeroPaymentCounter(),
    chargebackCounter(),
    employeeClaims("analyst-department-employee-claims"),
    inquiriesCounter(),
    requestNotAsignedCounter(),
    requestNcrCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
  ]);
};
export default allCounterSpecialists;
