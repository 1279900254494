<template>
  <div>
    <ValidationObserver ref="formOther">
      <b-row>
        <b-col md="6" class="mb-1">
          <label>Type of payment</label>
          <validation-provider name="type" rules="required" v-slot="{ errors }">
            <b-input-group>
              <b-input-group-prepend class="w-40">
                <b-input-group-text class="title_lable w-100">
                  TYPE
                </b-input-group-text>
              </b-input-group-prepend>
              <b-select
                name="type"
                :options="optionsMethods"
                value-field="value"
                text-field="name"
                v-model="methodSelected"
                @change="changeOptionType($event)"
                v-if="!disableIp"
              />
              <b-form-input
                v-else
                name="type"
                :value="nameOtherPayment"
                disabled
              />
            </b-input-group>
            <small class="text-danger float-right" v-if="errors.length > 0">
              {{ errors[0] }}
            </small>
          </validation-provider></b-col
        >
        <template v-if="methodSelected != 2">
          <b-col md="6" class="mb-1">
            <label>Serial number</label>
            <validation-provider
              name="serial_number"
              rules="required"
              v-slot="{ errors }"
            >
              <b-input-group>
                <b-input-group-prepend class="w-40">
                  <b-input-group-text class="title_lable w-100">
                    SERIAL NUMBER
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  name="serial_number"
                  v-model="serialNumber"
                  maxlength="15"
                  @input="serialNumber = serialNumber.toUpperCase()"
                />
              </b-input-group>
              <small class="text-danger float-right" v-if="errors.length > 0">
                Serial number {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>

          <b-col md="6" class="mb-1">
            <label>Is the client the holder?</label>
            <b-input-group>
              <b-input-group-prepend class="w-40">
                <b-input-group-text class="title_lable w-100">
                  NO
                  <b-form-checkbox
                    v-model="isClientOwner"
                    name="check-button"
                    class="check-custom-other"
                    switch
                  />
                  YES
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                v-model="owner"
                name="isClientOwner"
                :disabled="isClientOwner"
              />
            </b-input-group>

            <validation-provider
              name="check-button"
              :rules="!isClientOwner ? 'required' : ''"
              v-slot="{ errors }"
            >
              <div class="hidden">
                <b-form-input v-model="owner" name="isClientOwner" />
              </div>
              <small class="text-danger float-right" v-if="errors.length > 0">
                Holder {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>
        </template>

        <template v-if="methodSelected == 5 || methodSelected == 6">
          <b-col md="6" class="mb-1">
            <label>Date of payment</label>
            <validation-provider
              name="date"
              rules="required"
              v-slot="{ errors }"
            >
              <b-input-group>
                <b-input-group-prepend class="w-40">
                  <b-input-group-text class="title_lable w-100">
                    DATE
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-datepicker
                  v-model="date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                />
              </b-input-group>
              <small class="text-danger float-right" v-if="errors.length > 0">
                Date {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>

          <b-col md="6" class="mb-1">
            <label>Hour of payment</label>
            <validation-provider
              name="PROGRAM"
              rules="required"
              v-slot="{ errors }"
            >
              <b-input-group>
                <b-input-group-prepend class="w-40">
                  <b-input-group-text class="title_lable w-100">
                    HOUR
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-timepicker
                  v-model="hour"
                  locale="en"
                ></b-form-timepicker>
              </b-input-group>
              <small class="text-danger float-right" v-if="errors.length > 0">
                Hour {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>
        </template>
        <template v-if="methodSelected > 2">
          <b-col md="6">
            <label>Upload the receipt</label>
            <validation-provider
              v-slot="{ errors }"
              name="file"
              rules="required"
            >
              <b-input-group>
                <b-input-group-prepend class="w-40">
                  <b-input-group-text class="title_lable w-100">
                    IMAGE
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-group
                  class="form-control"
                  :style="
                    isDarkSkin ? 'background-color: transparent !important' : ''
                  "
                >
                  <b-form-file
                    v-model="fileToUpload"
                    @input="fileFill"
                    style="position: inherit"
                  />
                </b-form-group>
              </b-input-group>
              <small class="text-danger float-right" v-if="errors.length > 0">
                File {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>
        </template>
      </b-row>
    </ValidationObserver>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import OtherPaymentsService from "@/views/commons/components/other-payments/service/other-payment.service";
import moment from "moment";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
export default {
  data() {
    return {
      methodSelected: 2,
      optionsMethods: [],
      fileToUpload: [],
      date: moment().format("YYYY-MM-DD"),
      hour: moment().format("HH:mm:ss"),
      fimage: "",
      serialNumber: "",
      typePaymentOther: 6,
      isClientOwner: true,
      owner: "",
    };
  },
  props: {
    amount: Number,
    isClient: Boolean,
    idClientOrLead: String,
    idProgram: Number,
    idModule: Number,
    chargeId: Number,
    chargeDescription: String,
    idCurrentUser: Number,
    modalityPayment: Number,
    saleId: Number,
    disableIp: Boolean,
    otherMethod: Number,
    paymentFinantial: Number,
    monthlyPayments: {
      type: Array,
      required: false,
      default: () => [],
    },
    partialAmount: {
      type: Number,
      required: false,
    },
  },
  created() {
    this.getClientTransactionMethods();
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    nameOtherPayment() {
      let options = this.optionsMethods.find(
        (item) => item.value == this.otherMethod
      );
      return options ? options.name : "";
    },
  },
  methods: {
    async saveOtherPayment(additionalChargeId = null) {
      let validate = await this.$refs["formOther"].validate();
      let datetime = null;
      if (validate) {
        if (this.date != "" && this.hour != "") {
          datetime = moment(
            `${this.date} ${this.hour}`,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
        }
        const form = {
          client_account_id: this.isClient ? this.idClientOrLead : null,
          lead_id: this.isClient ? null : parseInt(this.idClientOrLead),
          payment_method: this.methodSelected,
          payment_type: this.typePaymentOther,
          payment_modality: this.modalityPayment,
          settlement_date: datetime,
          serial_number: this.serialNumber,
          amount: this.amount,
          program_id: this.idProgram ? this.idProgram : this.client.program_id,
          module_id: this.idModule,
          created_by: this.idCurrentUser,
          image: this.fimage,
          isClient: this.isClient,
          sale_id: this.saleId,
          charge_id: this.chargeId,
          charge_description: this.chargeDescription,
          paymentFinantial: this.paymentFinantial,
          additional_charge_id: additionalChargeId,
          partial_amount: this.partialAmount ?? 0,
          parent_payment_id: null,
          owner: this.owner,
        };

        if (this.monthlyPayments.length > 0) {
          for (let i = 0; i < this.monthlyPayments.length; i++) {
            const payment = this.monthlyPayments[i];

            this.showToast(
              "info",
              "top-center",
              `Processing payment (${moment(payment.date).format(
                "MMMM-YYYY"
              )}) ${i + 1} of ${this.monthlyPayments.length}`,
              "CheckIcon",
              "Please wait"
            );

            form.amount = payment.amount;
            form.status = payment.status;
            form.dateMonthlyPayment = payment.date;
            const dateOriginal = moment(payment.pay_at);
            const dateModified = dateOriginal.date(moment().date());
            form.settlement_date =
              dateModified.format("YYYY-MM-DD") +
              " " +
              moment().format("HH:mm:ss");
            form.isOtherPayment = 1;

            let data = await OtherPaymentsService.saveOtherPayment(form);
            if (data.status == 200) {
              form.parent_payment_id =
                form.parent_payment_id ?? data.data.parent_payment_id;
              await ClientsOptionsServices.registerAdvancePayment(form);
            } else {
              this.showErrorSwal();
              this.monthlyPayments = [];
              break;
            }

            if (i == this.monthlyPayments.length - 1) {
              this.$emit("close");
              if (data.status == 200) {
                this.showSuccessSwal();
              }
            }
          }
          return;
        }

        let data = await OtherPaymentsService.saveOtherPayment(form);
        // **********************
        if (data.status == 200) {
          this.$emit("close");
          this.showSuccessSwal();
        } else {
          this.showErrorSwal();
        }
      }
    },

    async fileFill() {
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = async (file) => {
        this.fimage = file.target.result;
      };
    },

    changeOptionType(value) {
      this.methodSelected = value;
    },

    async getClientTransactionMethods() {
      let { data } = await OtherPaymentsService.getClientTransactionMethods();
      this.optionsMethods = data.map((item) => {
        return {
          name: item.value.toUpperCase(),
          value: item.id,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.w-40 {
  width: 40%;
}
</style>

<style lang="scss" >
.check-custom-other {
  .custom-control-label {
    margin-left: 5px;
  }
}
</style>