export default [
  {
    path: "commissions",
    name: "administration-commissions",
    component: () =>
      import(
        /* webpackChunkName: "AdministrationCommissions" */ "@/views/administration/views/comissions/Commissions.vue"
      ),
    meta: {
      permittedRoles: [1, 2, 6],
      pageTitle: "Commissions",
      breadcrumb: [
        {
          text: "Commissions",
          active: true,
        },
      ],
    },
  },
];
