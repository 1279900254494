import { amgApi } from "@/service/axios";
class PaymentScheduleService {
  async getPaymentScheduleByClient(params) {
    try {
      const data = await amgApi.post("/commons/get-payment-schedule-by-client", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  getTrackingPaymentScheduleByClient(params) {
    try {
      const data = amgApi.post("/commons/get-tracking-payment-schedule-by-client", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getTotalAmountFee(params) {
    try {
      const data = await amgApi.post("/commons/get-total-amount-fee", params);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
   async getLastPaymentDate(params) {
    try {
      const data = await amgApi.post("/commons/get-last-payment-date", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async sendPaymentScheduleByEmail(params) {
    try {
      const data = await amgApi.post("/commons/new-payments-schedule/sent-email", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async sendPaymentScheduleBySms(params) {
    try {
      const data = await amgApi.post("/commons/new-payments-schedule/sent-sms", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async showPreviewPaymentScheduleInvoice(params) {
    try {
      const data = await amgApi.post("/commons/new-payments-schedule/show-pdf", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new PaymentScheduleService();
