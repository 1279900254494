import store from '@/store';
import Vue from 'vue';

const seeMessages = () => {
  window.socket.bind('see-messages', async data => {
    if (data.messageData.length > 0) {
      store.dispatch('ticketmessagesstore/A_SET_MESSAGE_VIEWED', data.messageData);
      const exists = data.messageData.some(elemento => store.state.ticketmessagesstore.S_MESSAGES_VIEWED.includes(elemento));
      if (exists && store.state.ticketmessagesstore.S_PROJECT_SELECTED !== data.projectId) {
        if (data.createdBy === store.state.auth.currentUser.user_id) {
          await Vue.prototype.$amgAlert({
            title: 'Paragon Has Seen Your Message',
            icon: 'CheckIcon',
            subtitle: `From Project ${data.projectName}`,
            data: [

            ],
            ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
          });
        } else if (data.chiefParagon === store.state.auth.currentUser.user_id) {
          await Vue.prototype.$amgAlert({
            title: 'The Project Creator Has Seen Your Message',
            icon: 'CheckIcon',
            subtitle: `From Project ${data.projectName}`,
            data: [
            ],
            ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
          });
        }
      }
    }
  });
};
export default seeMessages;
