<template>
  <b-modal
    :title="title"
    v-model="showModal"
    @hide="$emit('close')"
    hide-footer
  >
    <b-table
      ref="listCharges"
      small
      responsive
      :fields="fields"
      :items="items"
      show-empty
      sticky-header="50vh"
      class="font-small-3 text-justify blue-scrollbar"
    >
      <template #cell(created_at)="data">
        <div>
          <div>{{ data.item.first_name + " " + data.item.last_name }}</div>
          <div>{{ data.item.created_at | myGlobalWithHour }}</div>
        </div>
      </template>

      <template #cell(amount)="data">
        $ {{ data.item.amount | currencyZero }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import OtherPaymentService from "@/views/commons/components/other-payments/service/other-payment.service.js";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },

    chargeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      items: [],
      fields: [
        {
          key: "created_at",
          sortable: false,
          label: "Date",
          visible: true,
        },
        {
          key: "voucher_id",
          sortable: false,
          label: "Voucher",
          visible: true,
        },
        {
          key: "amount",
          sortable: false,
          label: "Amount",
          visible: true,
        },
        {
          key: "status",
          sortable: false,
          label: "Status",
          visible: true,
        },
      ],
    };
  },

  methods: {
    async getTrackingOtherPayments() {
      const { data } = await OtherPaymentService.getOtherPaymentByCharge({
        charge_id: this.chargeId,
      });
      return (this.items = data);
    },
  },

  created() {
    this.getTrackingOtherPayments();
  },
};
</script>

<style>
</style>