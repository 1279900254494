import paymentsRoute from "@/views/bookeeping/views/payments/payments.router";
import fileManagerRoute from "@/views/bookeeping/views/file-manager/file-manager.router";
import helpdeskRoute from "@/views/bookeeping/views/helpdesk/helpdesk.route";
import leadRoute from "@/views/bookeeping/views/leads/leads.router";
import clientRoute from "@/views/bookeeping/views/clients/clients.router";
import saleMadeRoute from "@/views/bookeeping/views/sales-made/sales-made.router";
import reportRoute from "@/views/bookeeping/views/reports/reports.router";
import calendarRoute from "@/views/bookeeping/views/calendar/calendar.router";
import commissionsRoute from "@/views/bookeeping/views/commissions/commissions.router";
import claimsRoute from "@/views/bookeeping/views/claims-v2/claims.router.js";
import loansRoute from "@/views/bookeeping/views/loans/loans.router";
import schedulesRoute from "@/views/bookeeping/views/schedules/schedules.router";
import chargeBackRoute from "@/views/bookeeping/views/charge-back/charge-back.router";
import trackingListRoute from "@/views/bookeeping/views/tracking-list/tracking-list.router";
import zeroPaymentRoute from "@/views/bookeeping/views/zero-payment/router/index.js";
import ProductRequestRoute from "@/views/bookeeping/views/request/request.router.js";
import BookeepingAttendanceRouter from "@/views/bookeeping/views/attendance/bookeeping-attendance.router";
import EmployeeClaimsRouter from "@/views/bookeeping/views/employee-claims/router/employee-claims.router";
import BooKeepingMain from "@/views/bookeeping/views/BooKeepingMain.vue";
//import BookeepingProjectsRouter from "@/views/bookeeping/views/projects/bookeeping-projects.router";
import CallLogRouter from "@/views/bookeeping/views/ring-central/call-log/call-log.router.js";
import CourtInfoRouter from "@/views/bookeeping/views/cour-info/router/ds-court-info.router";
import DashboardCalls from "@/views/bookeeping/views/ring-central/dashboard-calls/dashboard.router.js";
import RecruitmentProcessRouter from "@/views/bookeeping/views/recruitment-process/bookeeping-recruitment_process.router.js";
import MOFRouter from "@/views/bookeeping/views/manual-of-functions/manual-of-functions.router.js";
import RequestWorkPlanRouter from "@/views/bookeeping/views/request-workplan/request-workplan.router.js";
import CustomerTicketsRouter from "@/views/bookeeping/views/customer-tickets/bookeeping-customer-tickets.router";
import InconsistentTransactionRouter from "@/views/bookeeping/views/inconsistent-transactions/router/inconsistent-transactions.router.js";
import BookeepingDashboardRouter from "@/views/bookeeping/views/dashboard/router/bookeeping-dashboard.router.js";
import BookeepingPendingPaymentsRouter from "@/views/bookeeping/views/pending-payments/router/pending-payments.router.js";
import CallImpressionRoute from "@/views/bookeeping/views/call-impression/router/call-impression.router.js";

const routes = [
  {
    path: "/bookeeping",
    redirect: { name: "bookeeping-dashboard-principal" },
    component: BooKeepingMain,
    meta: {
      module: 14,
      program: 8,
    },
    children: [
      ...BookeepingDashboardRouter,
      ...leadRoute,
      ...saleMadeRoute,
      ...clientRoute,
      ...paymentsRoute,
      ...reportRoute,
      fileManagerRoute,
      helpdeskRoute,
      calendarRoute,
      commissionsRoute,
      ...claimsRoute,
      loansRoute,
      ...schedulesRoute,
      ...chargeBackRoute,
      ...trackingListRoute,
      ...zeroPaymentRoute,
      ...ProductRequestRoute,
      ...BookeepingAttendanceRouter,
      ...EmployeeClaimsRouter,
      //...BookeepingProjectsRouter,
      ...CallLogRouter,
      ...CourtInfoRouter,
      ...DashboardCalls,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...CustomerTicketsRouter,
      ...RequestWorkPlanRouter,
      ...InconsistentTransactionRouter,
      ...BookeepingPendingPaymentsRouter,
      ...CallImpressionRoute
    ],
  },
];

export default routes;
