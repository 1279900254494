<template>
  <div>
    <validation-observer ref="refFormObserver">
      <!-- Form -->
      <b-form class="pt-2">
        <b-row>
          <b-col cols="12" class="text-center">
            <b-img-lazy class="mb-1" src="/assets/images/icons/sale.png" />
            <p class="font-weight-bolder text-primary">Was the sale made?</p>
          </b-col>
          <b-col
            v-if="data.parent_id || (!data.motive && !data.parent_id)"
            cols="12"
          >
            <validation-provider
              v-slot="{ errors }"
              name="Program"
              :rules="!clickNot || data.counter == 1 ? 'required' : null"
            >
              <b-form-group
                label="Program"
                label-for="program"
                :state="errors[0] ? false : null"
              >
                <!-- :options="G_PROGRAMS.filter((item) => item.id != 9)" -->
                <v-select
                  ref="programSelector"
                  v-model="data.program"
                  :rules="clickNot ? null : 'required'"
                  input-id="program"
                  label="label"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="availableProgramsToSelect"
                  :multiple="true"
                  :closeOnSelect="false"
                  :reduce="(val) => val.id"
                  placeholder="Select Program"
                  @input="handleSelect"
                />
                <span v-if="errors[0]" class="text-danger">
                  Program {{ errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col v-if="clickNot" cols="12">
            <feather-icon
              v-if="currentUser.role_id == 1 || currentUser.role_id == 2"
              icon="PlusIcon"
              size="20"
              class="cursor-pointer"
              style="float: right"
              title="NEW CATEGORY"
              @click="openEditMotiveModalOn"
            />
            <validation-provider
              v-slot="{ errors }"
              name="Motive"
              :rules="'required'"
            >
              <b-form-group
                label="Motive"
                label-for="motive"
                :state="errors[0] ? false : null"
              >
                <v-select
                  v-model="motive"
                  input-id="motive"
                  label="description"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="S_MOTIVES"
                  :multiple="true"
                  :selectable="(option) => option.id != 1"
                  placeholder="Select Motive"
                  @input="selectMotives()"
                >
                  <template #option="{ description, parent_id }">
                    <span
                      :style="`margin-left:${parent_id == null ? 0 : 15}px;`"
                      >{{ description }}</span
                    >
                  </template>
                </v-select>
                <span v-if="errors[0]" class="text-danger">
                  Motive {{ errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="Note"
              :rules="'required'"
            >
              <b-form-group
                label="Note"
                label-for="motive"
                :state="errors[0] ? false : null"
              >
                <b-textarea
                  v-model="text"
                  :rows="3"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Motive"
                />
                <span v-if="errors[0]" class="text-danger">
                  Note {{ errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <!-- Form Actions -->
        <div
          class="d-flex justify-content-center mt-2"
          v-if="!debtSolutionFlow"
        >
          <b-button
            v-if="!clickNot"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-2"
            :disabled="isLoading"
            @click="(data.done = 1), onSubmit()"
          >
            <template v-if="isLoading">
              <b-spinner small />
              <span>Loading...</span>
            </template>
            <template v-else>
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span>Yes</span>
            </template>
          </b-button>

          <b-button
            v-if="clickNot"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="info"
            class="mr-2"
            :disabled="isLoading"
            @click="(data.done = 0), onSubmit()"
          >
            <template v-if="isLoading">
              <b-spinner small />
              <span>Loading...</span>
            </template>
            <template v-else>
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span>Save</span>
            </template>
          </b-button>

          <b-button
            v-if="!is_client && !clickNot"
            id="not"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="danger"
            :disabled="isLoading"
            type="button"
            @click="clickNot = true"
          >
            <template v-if="isLoading">
              <b-spinner small />
              <span>Loading...</span>
            </template>
            <template v-else>
              <feather-icon icon="TrendingDownIcon" class="mr-50" />
              <span>Not</span>
            </template>
          </b-button>
          <b-button
            v-if="clickNot"
            id="return"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="warning"
            :disabled="isLoading"
            type="button"
            @click="clickNot = false"
          >
            <template v-if="isLoading">
              <b-spinner small />
              <span>Loading...</span>
            </template>
            <template v-else>
              <feather-icon icon="XIcon" class="mr-50" />
              <span>Return</span>
            </template>
          </b-button>
        </div>

        <!-- If the program selected is debt solution these buttons will appear -->
        <div v-else>
          <b-row class="text-center m-1">
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                @click="openCreditorsModal"
              >
                <feather-icon icon="ChevronRightIcon" class="mr-50" />
                <span>Next step</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </validation-observer>

    <create-edit-motive-modal
      v-if="createEditMotiveModalOn"
      :is-from-reply="true"
      :type-from-reply="5"
      @close="closeEditMotiveModalOn"
      @reload="A_GET_MOTIVES"
    />
    <add-creditor-ds-modal
      v-if="addCreditorModalController"
      :state-modal="1"
      :title="title_modal"
      :id_event="event.id"
      :isNewAppointmentDS="isNewAppointmentDS"
      :idEventAccountApproved="idEventAccountApproved"
      :id_lead="lead.id"
      @reload="emitReload"
      @close="close($event)"
      @hideOnly="hideOnlyAddCreditorDS()"
      @reloadAmountSaved="getEmitedData"
      ref="addCreditorDsModal"
    />
    <modal-financial-analysis-options
      v-if="openFinancialOptions"
      @closeModal="showAnalysisOptions = false"
      :event-id="event.id"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import CrmService from "@/views/crm/services/crm.service";
import Ripple from "vue-ripple-directive";
import NotesService from "@/views/crm/services/notes";
import vSelect from "vue-select";
import AddCreditorDsModal from "@/views/crm/views/Lead/lead-module/dashboard/modal/AddCreditorDsModal.vue";
import CreateEditMotiveModal from "@/views/social-network/views/settings/modals/CreateEditMotiveModal.vue";
import ModalFinancialAnalysisOptions from "@/views/crm/views/Lead/lead-module/dashboard/modal/ModalFinancialAnalysisOptions.vue";
import AppointmentServices from "@/views/crm/views/Lead/lead-event/services/appointments.services.js";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";

export default {
  components: {
    vSelect,
    CreateEditMotiveModal,
    AddCreditorDsModal,
    ModalFinancialAnalysisOptions,
  },
  props: {
    idProcessSale: {
      type: String,
      default: null,
    },
    onlyRead: {
      type: Boolean,
      // required: true,
    },
    event: {
      type: Object,
      // required: true,
    },
    lead: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      debtSolutionFlow: false,
      motive: null,
      clickNot: false,
      title_modal: "",
      isLoading: false,
      data: {
        program: null,
        motive: null,
        counter: null,
        done: null,
        parent_id: null,
      },
      createEditMotiveModalOn: false,
      addCreditorModalController: false,
      openFinancialOptions: false,
      mood: 0,
      motiveToSend: {},
      text: "",
      idEventAccountApproved: null,
      notAllowedPrograms: [],
      client_account_id: null,
      is_client: false,
    };
  },
  async created() {
    await this.A_GET_MOTIVES();
    await this.getAccountsByClient();
  },
  computed: {
    availableProgramsToSelect() {
      return this.G_PROGRAMS.filter(
        (item) => !this.notAllowedPrograms.includes(item.id)
      );
    },
    isCrm() {
      return this.$route.matched[0].meta.module === 2;
    },
    ...mapState({
      S_MOTIVES: (state) => state.CrmGlobalStore.S_MOTIVES,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_PROGRAMS: "CrmGlobalStore/G_PROGRAMS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    pathNameSaleMade() {
      switch (this.moduleId) {
        case 2:
          return "sales-made-crm-lead-pending";
        case 26:
          return "sales-made-cedigital-lead";
      }
    },
  },
  methods: {
    async getAccountsByClient() {
      const response = await CrmService.getAccountsByClient({
        lead_id: this.lead.id,
      });
      this.notAllowedPrograms = response.map((item) => item.program_id);
      this.notAllowedPrograms.push(9);
      if (response.length) {
        this.client_account_id = response[0].id;
        this.is_client = true;
      }
    },
    hideOnlyAddCreditorDS() {
      this.isNewAppointmentDS = false;
      this.addCreditorModalController = false;
    },
    async getEmitedData(data) {
      this.data.done = 1;
      this.emitReloadAmountSaved(data);
    },

    ...mapActions({
      A_GET_EVENTS: "CrmEventStore/A_GET_EVENTS",
      A_ATTEND_EVENT: "CrmEventStore/A_ATTEND_EVENT",
      A_GET_MOTIVES: "CrmGlobalStore/A_GET_MOTIVES",
      A_NOTSALE_EVENTS_MOTIVES: "CrmGlobalStore/A_NOTSALE_EVENTS_MOTIVES",
    }),
    close(data) {
      this.isNewAppointmentDS = false;
      this.$emit("closeModal", data);
      this.addCreditorModalController = false;
    },
    selectMotives() {
      if (this.motive?.length > 0) {
        this.data.motive = this.motive[0].id;
        this.data.counter = this.motive.some((m) =>
          [null, 1].includes(m.counter)
        )
          ? 1
          : 0;
        this.data.parent_id = this.motive.some((m) => m.parent_id !== null)
          ? 1
          : null;
      } else {
        this.data.motive = null;
        this.data.counter = null;
        this.data.parent_id = null;
      }
    },

    async openCreditorsModal() {
      this.isNewAppointmentDS = true;
      this.title_modal = "Creditors List";
      const data = await this.validateCompleteCrProcess();
      if (data[0].code == 1) {
        this.idEventAccountApproved = data[0].event_id;
      }
      this.addCreditorModalController = true;
    },
    async validateCompleteCrProcess() {
      try {
        this.addPreloader();
        const params = {
          eventId: this.event.id,
          leadId: this.lead.id,
          programId: this.data.program[0],
        };
        const { data } = await AppointmentServices.validateCompleteCrProcess(
          params
        );
        if (data[0].code != 1 && this.data.program[0] != 4) {
          this.showToast(
            "warning",
            "top-right",
            "Oops!",
            "AlertIcon",
            data[0].resp
          );
        }
        return data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async saveNote() {
      await NotesService.postCreateNote({
        user_id: this.currentUser.user_id,
        lead_id: this.lead.id,
        text: this.text,
        important: 1,
      });
    },
    async onSubmit() {
      const result = await this.$refs.refFormObserver.validate();
      if (!result) return;
      if (this.data.program.includes(3)) {
        const data = await this.validateCompleteCrProcess();
        if (data[0].resp != "success") return;
      }
      if (this.is_client) {
        this.addPreloader();
        const param = {
          prices: null,
          observation: "Service",
          contract: 1,
          program: this.data.program[0],
          fee: null,
          suggested: null,
          otherpricesp: null,
          event: this.event.id,
          json_noce: null,
          stateid: 0,
          account: this.client_account_id,
          captured: this.event.creator_id,
          seller: this.event.seller_id,
          type: 1,
          user_id: this.currentUser.user_id,
          module: 0,
          id_score: null,
          json_ce: null,
          type_client: "CLIENT",
        };
        try {
          const result = await LeadService.insertSaleMade(param);
          if (result.status == 200) {
            this.$router
              .push({ name: "sales-made-crm-client-pending" })
              .catch((err) => {
                console.log(err);
              });
            this.removePreloader();
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
        return;
      }

      try {
        this.isLoading = true;
        const response = await this.showConfirmSwal();
        if (!response.isConfirmed) {
          this.isLoading = false;
          return;
        }
        if (this.data.program == 4) {
          this.addCreditorModalController = true;
        } else {
          this.emitReloadAmountSaved();
        }
        this.isLoading = false;
      } catch (e) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(e)
        );
        this.isLoading = false;
      }
    },
    closeEditMotiveModalOn() {
      this.createEditMotiveModalOn = false;
    },
    openEditMotiveModalOn() {
      this.createEditMotiveModalOn = true;
      this.motiveToSend = {};
    },
    clickNotTrue() {
      return (this.clickNot = true);
    },
    emitReload() {
      this.addCreditorModalController = false;
    },

    async emitReloadAmountSaved(emmitedData = null) {
      this.addPreloader();
      const data = {
        idEvents: this.event.id,
        idLossMotives: this.data.motive,
        createBy: this.currentUser.user_id,
        notSale: this.data.counter,
        arrMotives: this.motive,
      };
      this.$emit("updateAppointment", emmitedData);
    },

    async emitReloadAmountSaved2(emmitedData = null) {
      const data = {
        idEvents: this.event.id,
        idLossMotives: this.data.motive,
        createBy: this.currentUser.user_id,
        notSale: this.data.counter,
        arrMotives: this.motive,
      };
      if (this.data.counter == null || this.data.counter == 1) {
        if (this.data.program[0] == 3) {
          await this.accountsRemoveToEvent();
        }
        await Promise.all(
          this.data.program.map((item) =>
            this.A_ATTEND_EVENT({
              id: this.event.id,
              program: item,
              done: this.data.done,
              module: this.moduleId,
              recovery_id: this.idProcessSale,
              derivation_id: this.lead?.derivation_id,
              type_client: "LEAD",
            })
          )
        );
        if (this.data.counter == 1) {
          await this.A_NOTSALE_EVENTS_MOTIVES(data);
          this.saveNote();
        }
      } else {
        const response = await this.A_NOTSALE_EVENTS_MOTIVES(data);
        this.saveNote();

        if (response.status === 200) {
          this.$emit("closeModal");
          this.showSuccessSwal();
        }

        await this.A_GET_EVENTS({ idLead: this.$route.params.id });
      }
      this.removePreloader();
      if (this.data.counter == 1 || this.data.counter == null) {
        await this.$router.push({
          name: this.pathNameSaleMade,
          // query: { done: this.data.done === 1 ? 1 : 2 },
        });
      }
    },
    async accountsRemoveToEvent() {
      await AppointmentServices.accountsRemoveToEvent({
        leadId: this.event.lead_id,
        eventId: this.event.id,
      });
    },
    handleSelect() {
      const lastSelectedOption =
        this.data.program[this.data.program.length - 1];
      if (lastSelectedOption == 4 || lastSelectedOption == 3) {
        this.data.program = [lastSelectedOption];
        this.debtSolutionFlow = lastSelectedOption == 4;
        this.$refs.programSelector.open = false;
      } else {
        this.debtSolutionFlow = false;
        if (this.data.program.includes(4) || this.data.program.includes(3)) {
          const index = this.data.program.findIndex((item) =>
            [3, 4].includes(item)
          );
          this.data.program.splice(index, 1);
          this.$refs.programSelector.open = true;
        }
      }
      if (this.is_client) {
        this.$refs.programSelector.open = false;
        this.debtSolutionFlow = false;
        this.data.program = [lastSelectedOption];
      }
    },
  },
  directives: {
    Ripple,
  },
  watch: {
    clickNot() {
      this.data = {
        program: null,
        motive: null,
        counter: null,
        done: null,
        parent_id: null,
      };
      this.motive = null;
    },
  },
};
</script>
