

export default [
  {
    path: "schedules",
    name: "correspondence-schedules",
    redirect: { name: "correspondence-schedules-report-by-module" },
    //  redirect: "/correspondence/schedules/report-by-module",
    //  component: () => import("../view/components/SchedulesTab.vue"),
    component: () => import(/* webpackChunkName: "CorrespondenceSchedules" */ "@/views/commons/components/schedules/SchedulesComponent.vue"),
    meta: {
      pageTitle: "Schedules",
      route: "correspondence",
      permittedRoles: [1],
      breadcrumb: [
        {
          text: "Schedules",
          active: true,
        },
      ],

    },
    children: [
      {
        path: "report",
        // component: () => import('../view/components/ReportByModule.vue'),
        component: () => import(/* webpackChunkName: "CorrespondenceSchedulesReport" */ "@/views/commons/components/schedules/SchedulesReport.vue"),
        name: "correspondence-schedules-report-by-module",
        meta: {
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Report By Module",
              active: true,
            },
          ],
          tab: 1,
          permittedRoles: [1],
          route: "correspondence"
        },
      },
      {
        path: "overtime",
        // component: () => import('../view/components/Overtime.vue'),
        component: () => import(/* webpackChunkName: "CorrespondenceSchedulesOvertime" */ "@/views/commons/components/schedules/SchedulesOvertime.vue"),
        name: "correspondence-schedules-overtime",
        meta: {
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Overtime",
              active: true,
            },
          ],
          tab: 2,
          permittedRoles: [1],
          route: "correspondence"
        },
      },
      {
        path: "justifications",
        // component:() => import('../view/components/Justifications.vue'),
        component: () => import(/* webpackChunkName: "CorrespondenceSchedulesJustifications" */ "@/views/commons/components/schedules/SchedulesJustifications.vue"),
        name: "correspondence-schedules-justifications",
        meta: {
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Justifications",
              active: true,
            },
          ],
          tab: 3,
          permittedRoles: [1],
          route: "correspondence"
        },
      },
    ],
  },
];
