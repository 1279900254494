export default [
  {
    path: "commissions",
    name: "commissions-management",
    component: () =>
      import(/* webpackChunkName: "ManaCommissions" */ "@/views/commons/components/commissions/CommissionsManagement.vue"),
    meta: {
      pageTitle: "Commissions",
      breadcrumb: [
        {
          text: "Commissions",
          active: true,
        },
      ],
      tab: "management",
    },
  },
];
