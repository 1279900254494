import { ref, readonly } from "@vue/composition-api"

let isBusy = ref(false)
const keyName = "cs_agentAwayStorage"

function toogleBusyState() {
  if (!isBusy.value) {
    // when overlay is activated
    if (localStorage.getItem(keyName) == null) {
      let data = {
        pauseStarted: new Date()
      }
      localStorage.setItem(keyName, JSON.stringify(data))
    }
  } else {
    localStorage.removeItem(keyName)
  }
  isBusy.value = !isBusy.value
}

// ....
if (localStorage.getItem(keyName) != null) {
  isBusy.value = true
}

export default function () {
  return {
    isBusy: readonly(isBusy),
    toogleBusyState
  }
}