import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";
import {
  headersManual,
  inputsRequireds,
  sanitizerBureau,
  validInputs,
} from "../data/lead-helpers-accounts-manual.field";

export default {
  namespaced: true,
  state: {
    S_ACCOUNTS: [],
    S_ACCOUNTS_FINAL: [],
    S_DONE_ACCOUNTS: false,
  },
  getters: {
    G_ACCOUNTS_TOTAL(state) {
      let x = state.S_ACCOUNTS_FINAL.filter((items, index) => {
        let total = items.filter(
          (x, y) =>
            validInputs(x, y) ===
            inputsRequireds.filter(
              (x) => x.visible && !x.opcional && x.key != "a_name"
            ).length
        ).length;
        return total > 0;
      });
      return x.length;
    },
    G_ACCOUNTS_INCOMPLETE(state) {
      let x = state.S_ACCOUNTS_FINAL.filter((items, index) => {
        let total = items.filter(
          (x, y) =>
            validInputs(x, y) ===
            inputsRequireds.filter(
              (x) => x.visible && !x.opcional && x.key != "a_name"
            ).length
        ).length;
        return total === 0;
      });
      return x.length;
    },
    G_ACCOUNTS(state) {
      let newAccounts = [];
      let keyNot = ["show_detail"];
      state.S_ACCOUNTS.forEach((items) => {
        let itemBureau = [];
        items.forEach((item, index) => {
          let total = validInputs(item, index);
          let totalHeaders = inputsRequireds.filter(
            (x) => x.visible && !x.opcional && x.key != "a_name"
          );
          if (total === totalHeaders.length) {
            let obj = sanitizerBureau(item);
            itemBureau.push(obj);
          } else {
            let obj = {};
            headersManual.forEach((ite) => {
              if (!keyNot.includes(ite.key)) {
                if (ite.type === "money") {
                  obj[ite.key] = "0.00";
                } else if (ite.type === "number") {
                  obj[ite.key] = 0;
                } else if (ite.type === "array") {
                  obj[ite.key] = "-";
                } else {
                  obj[ite.key] = "-";
                }
              }
            });
            itemBureau.push(obj);
          }
        });
        newAccounts.push(itemBureau);
      });
      state.S_ACCOUNTS_FINAL = newAccounts;
      return state.S_ACCOUNTS;
    },
    G_ACCOUNTS_FINAL(state) {
      let x = state.S_ACCOUNTS_FINAL.filter((items, index) => {
        let total = items.filter(
          (x, y) =>
            validInputs(x, y) ===
            inputsRequireds.filter(
              (x) => x.visible && !x.opcional && x.key != "a_name"
            ).length
        ).length;
        return total > 0;
      });
      return x;
    },
    G_DONE_ACCOUNTS(state) {
      return state.S_DONE_ACCOUNTS;
    },
  },
  mutations: {
    M_ACCOUNTS_ADD(state, payload) {
      state.S_ACCOUNTS = payload;
    },
    M_ACCOUNTS_FINAL(state, payload) {
      state.S_ACCOUNTS_FINAL = payload;
    },
    M_DONE_ACCOUNTS(state, payload) {
      state.S_DONE_ACCOUNTS = payload;
    },
  },
  actions: {
    A_ACCOUNTS_ADD({ commit }, payload) {
      commit("M_ACCOUNTS_ADD", payload);
    },
    A_ACCOUNTS_FINAL({ commit }, payload) {
      commit("M_ACCOUNTS_FINAL", payload);
    },
    async A_LIST_ACCOUNTS({ commit }, payload) {
      try {
        const data = await NcrRequestService.listCrManualLead({
          score_id: payload.score_id,
        });
        if (data.cr) {
          commit("M_ACCOUNTS_ADD", data.cr.account);
          commit("M_DONE_ACCOUNTS", data.cr.done || false);
        } else {
          commit("M_ACCOUNTS_ADD", []);
          commit("M_DONE_ACCOUNTS", false);
        }
      } catch (ex) {}
    },
  },
};
