<template>
  <div>
    <validation-observer ref="form">
      <b-modal
        v-model="modalUp"
        size="xmd"
        modal-class="custom-modal-amg"
        header-class="p-0"
        header-bg-variant="transparent"
        scrollable
        @hide="hideModal(false)"
      >
        <template #modal-header>
          <HeaderModalNotes
            :program="noteInfo.nameProgram"
            :info="noteInfo"
            :show-contact-schedule="specialNote"
            :from-debt="fromDebt"
            @close="hideModal(false)"
          />
        </template>
        <b-container>
          <b-row>
            <b-col
              v-if="
                notesSales[0].type_view == 2 && noteInfo.created >= '2021-06-15'
              "
              md="12"
              class="mb-1"
            >
              <!-- CONTACT SCHEDULE -->
              <div
                ref="clientScheduleForm"
                :class="
                  contactScheduleValidate
                    ? 'border-danger border-custom rounded'
                    : ''
                "
                class="mb-2"
              >
                <b-alert
                  fade
                  class="p-1 m-1"
                  v-if="contactScheduleValidate"
                  show
                  variant="danger"
                >
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="20"
                    class="mr-1"
                  />
                  Contact Schedule is required
                  <!-- Important: please reschedule this appointment -->
                </b-alert>
                <client-schedule
                  :noteInfo="noteInfo"
                  :contact_schedule="contact_schedule"
                  :valorEdit="valorEdit"
                  :switchAvailable="always_available"
                  :ds_contact_schedule="ds_contact_schedule"
                  :showLoaderProp="showLoader"
                  @getContactSchedule="getContactSchedule()"
                  @changeSwitch="setAlwaysAvailable"
                  @hidden="closeContactSchedule"
                />
              </div>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Origin Country"
                label-class="font-weight-bolder"
              >
                <v-select
                  v-model="origin_country"
                  label="name"
                  :clearable="false"
                  :reduce="(value) => value.id"
                  :options="countryOptions"
                  :disabled="valorEdit"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="isDebtSolutionModule" md="6"></b-col>
            <b-col lg="6" v-if="isDebtSolutionModule && fileName != null && fileName != ''">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group
                  label-class="font-weight-bolder"
                  label="Attach Call"
                >
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button
                        variant="info"
                        class="btn-icon"
                        :disabled="disabledFile"
                        title="Delete File"
                        @click="deleteAudio"
                      >
                        <feather-icon icon="Trash2Icon" class="text-white" />
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input
                      v-if="fileName"
                      v-model="fileName"
                      type="text"
                      class="bg-transparent text-info"
                      :class="{ 'border-danger': errors[0] }"
                      disabled
                    />
                    <b-form-file
                      v-else
                      v-model="audioCall"
                      placeholder="Choose a file or drop it here..."
                      browse-text="Audio"
                      accept="audio/*"
                      :class="{ 'border-danger': errors[0] }"
                      :disabled="disabledFile"
                    />
                  </b-input-group>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col v-if="fileAudio && isDebtSolutionModule && fileName != null && fileName != ''" lg="6">
              <!-- <audio controls="controls" preload="auto">
                <source :src="fileAudio" type="audio/wav" />
              </audio> -->
              <new-wave-surfer-player
                :url="fileAudio"
                :options="{
                  waveColor: '#99989A',
                  progressColor: '#0077E6',
                  barWidth: 3,
                  barRadius: 3,
                  cursorWidth: 0,
                  height: 20,
                  barGap: 3,
                  backend: 'MediaElement',
                  minPxPerSec: 5,
                  normalizeToZero: true,
                  waveOutlineWidth: 0,
                  barMinHeight: 2,
                }"
                :wave-surfer-id="`wave-surfer-1`"
                :elapsed-time-width="95"
                show-duration
              />
            </b-col>
            <b-col v-if="!fileAudio && isDebtSolutionModule && fileName != null && fileName != ''" lg="6"> </b-col>
            <b-col
              v-for="(question, index) in notesSales.filter(
                (note) => note.question_id !== '1085'
              )"
              :key="index"
              :cols="question.column"
            >
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-group
                  v-if="findFather(question.father)"
                  :label="question.question"
                  :disabled="valorEdit"
                >
                  <b-form-input
                    v-if="question.type == 2"
                    v-model="question.answer"
                    :class="{
                      'border-danger rounded': errors[0] && controller,
                    }"
                  />
                  <quill-editor
                    v-if="question.type === 0"
                    v-model="question.answer"
                    :disabled="valorEdit"
                    :options="{ modules: { toolbar: false } }"
                    :class="{
                      'border-danger rounded': errors[0] && controller,
                    }"
                  />
                  <b-form-radio-group
                    v-if="question.type == 1"
                    v-model="question.answer"
                    :options="JSON.parse(question.options)"
                    :class="{
                      'border-danger rounded': errors[0] && controller,
                    }"
                  />
                  <b-form-checkbox-group
                    v-if="question.type === 3"
                    v-model="question.answer"
                    :options="JSON.parse(question.options)"
                    :class="{
                      'border-danger rounded': errors[0] && controller,
                    }"
                  />
                  <span v-if="question.type === 3"> </span>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="notesSales[0].type_view == 2" md="12">
              <b-row>
                <b-col md="12" class="text-primary font-medium-2 mb-1">
                  More Information
                </b-col>
                <b-col class="mb-1 d-flex align-items-center" md="6">
                  <span
                    class="rounded-left border-info bg-info text-white w-25 text-center px-0"
                    style="padding: 3px 10px"
                    >Legal Status</span
                  >
                  <span
                    class="w-75 border-top-info border-right-info border-bottom-info rounded-right"
                    style="padding: 3px 10px"
                    >{{
                      showContactSchedule.status_lead
                        ? showContactSchedule.status_lead
                        : "-"
                    }}</span
                  >
                </b-col>
                <b-col class="mb-1 d-flex align-items-center" md="6">
                  <span
                    class="rounded-left border-info bg-info text-white text-center"
                    style="padding: 3px 10px; width: 35%"
                    >Monthly Payment</span
                  >
                  <span
                    class="border-top-info border-right-info border-bottom-info rounded-right"
                    style="padding: 3px 10px; width: 65%"
                    >$ {{ showContactSchedule.monthly_payment }}</span
                  >
                </b-col>
                <b-col class="mb-1 d-flex align-items-center" md="6">
                  <span
                    class="rounded-left border-info bg-info text-white w-25 text-center px-0"
                    style="padding: 3px 10px"
                    >Retainer Fee</span
                  >
                  <span
                    class="w-75 border-top-info border-right-info border-bottom-info rounded-right"
                    style="padding: 3px 10px"
                    >$ {{ showContactSchedule.retainer_fee }}</span
                  >
                </b-col>
                <b-col class="mb-1 d-flex align-items-center" md="6">
                  <span
                    class="rounded-left border-info bg-info text-white text-center"
                    style="padding: 3px 10px; width: 35%"
                    >Incomes</span
                  >
                  <span
                    class="border-top-info border-right-info border-bottom-info rounded-right"
                    style="padding: 3px 10px; width: 65%"
                    >$ {{ showContactSchedule.total_income }}</span
                  >
                </b-col>
                <b-col class="mb-1 d-flex align-items-center" md="6">
                  <span
                    class="rounded-left border-info bg-info text-white w-25 text-center"
                    style="padding: 3px 10px"
                    >Saving</span
                  >
                  <span
                    class="w-75 border-top-info border-right-info border-bottom-info rounded-right"
                    style="padding: 3px 10px"
                    >$ {{ showContactSchedule.saving }}</span
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="text-primary font-medium-2 mb-1">
                  Creditors List
                </b-col>
                <b-col md="12">
                  <b-table
                    small
                    class="font-small-3"
                    :items="listCredits"
                    :fields="creditorsFields"
                  >
                    <template #cell(actions)="data">
                      <feather-icon
                        icon="EyeIcon"
                        class="cursor-pointer text-primary"
                        v-b-tooltip.hover.top="'View creditor details...'"
                        @click="openCreditorDetails(data.item.id)"
                      />
                    </template>
                  </b-table>
                </b-col>
                <b-col class="mb-1" md="12">
                  <b-row
                    class="d-flex align-items-center justify-content-end font-small-3"
                  >
                    <b-col
                      md="6"
                      class="d-flex align-items-center justify-content-center"
                    >
                      <span
                        class="rounded-left border-info bg-info text-white text-center px-1"
                        style="padding: 3px 10px"
                        >Total</span
                      >
                      <span
                        class="border-top-info border-right-info border-bottom-info rounded-right px-2"
                        style="padding: 3px 10px"
                        >$
                        {{
                          showContactSchedule.total_balance
                            ? showContactSchedule.total_balance
                            : "0.00"
                        }}</span
                      >
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
        <template #modal-footer>
          <b-container fluid >
            <b-row v-if="notesCompleted">
              <b-col
                v-if="
                  valorEdit != true ||
                  (moduleId == 4 && noteInfo.statusSale != 4)
                "
                class="d-flex align-items-center justify-content-end"
              >
                <button-update v-if="!hasStatus" @click="saveNotesCompleted && noteInfo.editModal" />
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col class="d-flex align-items-center justify-content-end">
                <button-save
                  class="mr-1"
                  v-if="valorEdit !== true"
                  @click="saveNotesIncomplete"
                />
                <button-save-and-complete
                  v-if="valorEdit != true"
                  @click="saveNotesCompleted"
                />
              </b-col>
            </b-row>
          </b-container>
        </template>
      </b-modal>
    </validation-observer>
    <add-creditor-ds-modal
      v-if="addCreditorModalController"
      :id-creditor-to-pass="idCreditorToPass"
      :state-modal="stateModal"
      @close="addCreditorModalController = false"
      @reload="refreshTable()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import NotesServices from "@/views/commons/components/first-notes/services/notes.service";
import GlobalService from "@/views/services/global.service";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonSaveAndComplete from "@/views/commons/utilities/ButtonSaveAndComplete.vue";
import ButtonUpdate from "@/views/commons/utilities/ButtonUpdate.vue";
import HeaderModalNotes from "./HeaderModalNotes.vue";
import NewWaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/NewWaveSurferPlayer.vue";

// IMPORT CLIENT SCHEDULE COMPONENT
import ClientSchedule from "@/views/commons/components/first-notes/ClientSchedule.vue";
// IMPORT MODAL FOR VIEW ALL DETAILS FOR CREDITOR
import AddCreditorDsModal from "@/views/commons/components/clients/dashboard/options/acc-module/modals/AddCreditorDsModal.vue";

import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";

import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("valid", {
  ...required,
  message: "is-invalid",
});
export default {
  name: "ModalNotesAll",
  components: {
    ButtonUpdate,
    ButtonSaveAndComplete,
    ButtonSave,
    vSelect,
    HeaderModalNotes,
    quillEditor,
    AddCreditorDsModal,
    ClientSchedule,
    NewWaveSurferPlayer,
  },
  props: {
    isFromTaskNotes: {
      type: Boolean,
      default: false,
    },
    noteInfo: {
      type: Object,
      required: true,
      default: () => ({
        file: null,

        programSelected: "",
        roleId: null,
        notesProgram: null,
        nameProgram: null,
        nameClient: null,
        salesMades: null,
        module: null,
        type: null,
        editModal: false,
        statusSale: null,
        sourcesName: null,
        pack: null,
        created: null,
        originCountry: null,
        idLead: null,
        notSeller: null,
        capturedName: null,
        sellerName: null,
        trackings: null,
        notes_status: null,
        notes_status_new: null
      }),
    },
  },
  data() {
    return {
      showLoader: true,
      addCreditorModalController: null,
      idCreditorToPass: null,
      validateSchedule: true,
      modalContactSchedule: false,
      audioCall: null,
      fileAudio: null,
      fileName: null,
      controller: false,
      fromDebt: false,
      creditorsFields: [
        {
          key: "credit",
          label: "Creditors Name",
        },
        {
          key: "account",
          label: "# Account",
        },
        {
          key: "months",
          label: "Months Behind",
        },
        {
          key: "typt",
          label: "Type Account",
        },
        {
          key: "balance",
          label: "Total Balance",
          // formatter: (value) => `$ ${value.toFixed(2)}`,
        },
        {
          key: "actions",
        },
      ],
      specialNote: {},
      program: this.noteInfo.nameProgram,
      client_name: this.noteInfo.nameClient,
      ds_contact_schedule: [],
      always_available: 0,
      notesCompleted: true,
      origin_country: null,
      modalUp: false,
      notesSales: [{ type_view: null }],
      countryOptions: [],
      salesCreated: null,
      // CONTACT SCHEDULE OBJECT
      creditorData: [],
      contactScheduleValidate: "",
      listCredits: [],
    };
  },
  computed: {
    roleSales(){
      const {role_id} = this.currentUser;
      return [2,17].includes(role_id);
    },
    sellerId(){
      return  this.noteInfo.seller_id === this.currentUser.user_id;
    },
    hasStatus(){
      let valid;
      const status= this.noteInfo.statusSale;
      const hasModify =  this.roleSales ? true : this.sellerId;
      if(this.moduleId != 2){
         valid= [3,16].includes(status) && hasModify;
      }else{
        valid = [14,15].includes(status) && hasModify;
      }
      return !valid;
    },
    gridScheduleIsMounted() {
      return this.$refs.gridSchedule.$mount;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    showContactSchedule() {
      return this.notesSales[0];
    },
    valorEdit() {
     return this.moduleId == 16;
    },
    disabledFile() {
      return this.noteInfo.statusSale == 4 || this.noteInfo.notSeller;
    },
    deployMoment() {
      return this.$moment("2022-03-14");
    },
    isAfterLastDeploy() {
      return this.$moment(this.salesCreated).isAfter(this.deployMoment);
    },
    propToPass() {
      if (this.isFromTaskNotes) {
        return this.specialNote;
      }
      return this.noteInfo;
    },
    isDebtSolutionModule() {
      return this.noteInfo.nameProgram === "Debt Solution";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  async created() {
    this.specialNote = { ...this.noteInfo };
    await this.validateCreatesSale();
    this.addPreloader();
    await this.getNotesSales();
    await this.getCountrys();
    this.filterQuestions();
    this.getOriginCountry();
    await this.getDsListCredits();
    this.notesSales = this.notesSales.filter((val) => val.question_id != 1062);
    if (this.noteInfo.created < "2021-08-05") {
      this.notesSales = this.notesSales.filter(
        (val) => val.question != "Type of Agreement" && val.question_id != 1062
      );
    }
    this.cleanNotes();
    this.specialNote.captured = this.showContactSchedule.captured;
    this.specialNote.seller = this.showContactSchedule.seller;
    if (this.isFromTaskNotes) {
      this.fromDebt = true;
    }
    this.getContactSchedule();
    this.removePreloader();
  },
  methods: {
    validateContactSchedule() {
      if (this.noteInfo.programId != 8) {
        // not keybook
        if (this.always_available || this.ds_contact_schedule.length > 0) {
          this.contactScheduleValidate = false;
        } else {
          this.contactScheduleValidate = true;
          this.scrollDiv();
        }
      }
    },
    setAlwaysAvailable(value) {
      this.always_available = value ? 1 : 0;
      this.validateContactSchedule();
    },
    openContactSchedule() {
      this.modalContactSchedule = true;
    },
    closeContactSchedule() {
      this.modalContactSchedule = false;
    },
    ...mapActions({
      A_GET_CREATES_SALE: "CrmGlobalStore/A_GET_CREATES_SALE",
    }),
    async validateCreatesSale() {
      try {
        const response = await this.A_GET_CREATES_SALE(this.noteInfo.saleId);
        if (response.status == 200) {
          // here
        }
      } catch (error) {
        throw error;
      }
    },
    async getNotesSales() {
      try {
        const params = { id: this.noteInfo.saleId };
        const response = await NotesServices.notesSales(params);
        this.noteInfo.programId = response[0].program_id;

        let typeSelected = "";
        response.map((item) => {
          if (this.isAfterLastDeploy) {
            if (item.question == "Pending" && item.type == 3) {
              item.answer =
                item.answer.length > 0
                  ? JSON.parse(item.answer.replace(/\\/g, '"'))
                  : [];

              typeSelected = 0;
            }
          } else {
            typeSelected = 3;
          }
        });
        let id;
        for (let i = 0; i < response.length; i++) {
          if (
            response[i].question == "Pending" &&
            response[i].type == typeSelected
          ) {
            id = response[i].question_id;
          }
        }
        const index = response.map((el) => el.question_id);
        const indexOf = index.indexOf(id);
        response.splice(indexOf, 1);

        if (this.isDebtSolutionModule) {
          // Dropping audio call from notes it will be handled in a different variable
          this.notesSales = response.filter((item) => item.question_id != 1055);
          //SET THE SWITCH WITH THE VALUE OF ANSWER OF QUESTION 1085
          this.always_available =
            this.notesSales.filter((note) => note.question_id == "1085")[0]
              .answer || 0;
          response.find((item) => {
            // Setting the audio call into variables
            if (item.question_id == 1055 && !!item.answer) {
              this.fileAudio = item.url;
              this.fileName = item.answer.split("/")[2];
            }
          });
        } else {
          this.notesSales = response;
        }

        if (
          (typeSelected == 0 && this.notesSales.length >= 5) ||
          (typeSelected != 0 && this.notesSales.length >= 6)
        ) {
          this.contact_schedule = this.notesSales[typeSelected == 0 ? 4 : 5]
            .answer
            ? this.notesSales[typeSelected == 0 ? 4 : 5].answer
            : "";
          if (this.noteInfo.programId == 8) {
            this.contact_schedule = "";
          }
        } else {
          this.contact_schedule = "";
        }
        this.modalUp = true;
        this.removePreloader();

        // SET THE ALWAYS AVAILABLE SWITCH
        // this.contactScheduleObject.always_available = this.notesSales.find((element) => element.question_id == 1085).answer || 0;
      } catch (e) {
        this.showErrorSwal(e);
        this.removePreloader();
      }
    },
    async getDsListCredits() {
      try {
        const params = { event: this.noteInfo.event_id };
        const { data } = await NotesServices.getDsListCredits(params);
        this.listCredits = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getCountrys() {
      try {
        const response = await GlobalService.getCountrys();
        this.countryOptions = response;
      } catch (error) {
        this.showErrorSwal();
      }
    },
    getOriginCountry() {
      this.origin_country = this.noteInfo.originCountry
        ? this.noteInfo.originCountry
        : 146;
    },
    // Hide Modal
    hideModal(status) {
      this.modalUp = false;
      this.$emit("hide", status);
    },
    closeModal() {
      this.$emit("close", false);
    },
    deleteRequired(nota) {
      if (nota.answer != null || nota.answer != null) {
        this.$refs[`required${nota.question_id}`].$el.classList.remove(
          "required-text"
        );
      }
    },
    findFather(id) {
      if (id != null) {
        const father = this.notesSales.find(
          (element) => element.question_id == id
        );
        return father.answer === "YES";
      }
      return true;
    },
    async insertTrackingDerivation() {
      if (this.$route.matched[0].meta.module === 26) {
        const params = {
          lead_id: this.noteInfo.idLead,
          sale_status_id: 4,
          seller_id: this.noteInfo.seller_id,
          sale_id: this.noteInfo.saleId,
        };
        await DerivationsService.insertTrackingSaleStatusDerivation(params);
      }
    },
    scrollDiv() {
      const element = this.$refs.clientScheduleForm;
      if (typeof element != "undefined") {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    async saveNotesCompleted() {
      try {
        //SET THE ANSWER OF QUESTION 1085
        if (this.isDebtSolutionModule) {
          this.notesSales.filter(
            (note) => note.question_id == "1085"
          )[0].answer = this.always_available || 0;
        }

        if (!this.controller) this.controller = true;
        const result = await this.$refs.form.validate();
        this.validateContactSchedule();
        // VALIDATE IF CONTACT SCHEDULE HAS ITEMS
        // this.validateContactSchedule();

        if (result && !this.contactScheduleValidate) {
          const { value: confirm } = await this.showConfirmSwal();
          if (!confirm) return;

          this.addPreloader();

          if (this.isDebtSolutionModule && !!this.fileName) {
            this.notesSales.push({
              question_id: "1055",
              answer: `SM/${this.noteInfo.idLead}/${this.fileName}`,
            });
          }
          this.notesSales.map((item) => {
            if (item.type === 3) {
              item.answer = JSON.stringify(item.answer);
            }
          });

          const params = {
            notes: this.notesSales,
            sale_id: this.notesSales[0].sale_id,
            contact_schedule: this.contact_schedule,
            originCountry: this.originCountry,
            idLead: this.noteInfo.idLead,
            file_audio: this.fileAudio,
            file_name: this.fileName,
          };
          const response = await amgApi.post(
            "/sales-made/insert-sales-notes",
            params
          );
          if (response.status === 200) {
            this.showSuccessSwal("Notes Successfull");
            this.hideModal(true);
          }
          this.removePreloader();
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    async saveNotesIncomplete() {
      try {
        if (this.isDebtSolutionModule) {
          this.notesSales.filter(
            (note) => note.question_id == "1085"
          )[0].answer = this.always_available || 0;
        }

        const { value: confirm } = await this.showConfirmSwal();
        if (!confirm) return;
        this.addPreloader();

        if (this.isDebtSolutionModule && !!this.fileName) {
          this.notesSales.push({
            question_id: "1055",
            answer: `SM/${this.noteInfo.idLead}/${this.fileName}`,
          });
        }

        this.notesSales.map((item) => {
          if (item.type === 3) {
            item.answer = JSON.stringify(item.answer);
          }
        });

        const params = {
          always_available: this.always_available,
          notes: this.notesSales,
          sale_id: this.notesSales[0].sale_id,
          contact_schedule: this.contact_schedule,
          originCountry: this.originCountry,
          idLead: this.noteInfo.idLead,
          file_audio: this.fileAudio,
          file_name: this.fileName,
        };

        const response = await amgApi.post(
          "/sales-made/insert-sales-notes",
          params
        );

        if (response.status === 200) {
          this.showSuccessSwal("Notes Successfull");
          this.hideModal(true);
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    cleanNotes() {
      this.notesSales.map((note) => {
        if (note.answer == "null" || note.answer == null) {
          // eslint-disable-next-line no-param-reassign
          note.answer = "";
          this.notesCompleted = false;
        }
      });
      /* if (this.contact_schedule == '') {
        this.notesCompleted = false
      } */
    },
    cleanNotes() {
      this.notesSales.map((note) => {
        if (note.answer == "null" || note.answer == null) {
          // eslint-disable-next-line no-param-reassign
          note.answer = "";
          this.notesCompleted = false;
        }
      });
      /* if (this.contact_schedule == '') {
        this.notesCompleted = false
      } */
    },
    filterQuestions() {
      // filter repeat clients
      let noRepeatQuestions = [];
      const uniqueQuestion = {};
      noRepeatQuestions = this.notesSales;

      noRepeatQuestions = noRepeatQuestions.filter((current) => {
        const exists = !uniqueQuestion[current.question_id];
        uniqueQuestion[current.question_id] = true;
        return exists;
      });
      this.notesSales = noRepeatQuestions;
    },
    deleteAudio() {
      this.fileAudio = null;
      this.fileName = null;
      this.audioCall = null;
    },
    /* File */
    onAudioChange() {
      const file = this.audioCall;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.fileAudio = e.target.result;
      };
      this.fileName = file.name;
      reader.readAsDataURL(file);
    },

    // GET CONTACT SCHEDULE FOR CONTACT SCHEDULE SECTION/CARD
    async getContactSchedule() {
      try {
        this.showLoader = true;
        let params = {
          sale_id: this.noteInfo.saleId,
        };
        const { data } = await NotesServices.getContactSchedule(params);
        this.ds_contact_schedule = data[0] ? data : [];
        this.showLoader = false;
      } catch (error) {
        this.showErrorSwal(error);
        this.showLoader = false;
      } finally {
        this.showLoader = false;
      }
    },
    openCreditorDetails(id) {
      this.addCreditorModalController = true;
      this.stateModal = 2;
      this.idCreditorToPass = id;
    },
  },
  watch: {
    "ds_contact_schedule.length"(oldValue, newValue) {
      if (oldValue > 0) {
        this.contactScheduleValidate = false;
      } else {
        this.contactScheduleValidate = true;
      }
    },
    audioCall(newValue) {
      if (newValue) {
        this.onAudioChange();
      }
    },
  },
};
</script>

<style scoped>
.quill-editor {
  height: 100px;
}
.border-custom {
  border-width: 2px !important;
}
</style>
