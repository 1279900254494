import { amgApi } from "@/service/axios";

class PGRequestActionService {


  async show(id) {
    try {
      const data = await amgApi.post(`/paragon/request/pending-actions/show/${id}`,);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new PGRequestActionService();
