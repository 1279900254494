var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timeline-court-info"},[_c('div',{staticClass:"container"},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"timeline-steps"},_vm._l((_vm.timelineEvents),function(event,index){return _c('div',{key:index,staticClass:"timeline-step mb-5"},[_c('div',{staticClass:"timeline-content"},[(
                  event.step_id != 1 &&
                  event.step_id != 2 &&
                  event.step_id != 5
                )?_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(("Open Timeline " + (event.document_type))),expression:"`Open Timeline ${event.document_type}`",modifiers:{"hover":true,"top":true}}],staticClass:"icon-badge cursor-pointer",attrs:{"variant":'light-' +
                  _vm.getVariantStep(
                    event.step_id,
                    event.created_at,
                    event.validated_at
                  )},on:{"click":function($event){return _vm.openModalTracking(
                    event.case_id,
                    event.case_type_document_id
                  )}}},[_c('feather-icon',{attrs:{"icon":_vm.getIconStep(event.step_id),"size":"16"}})],1):_c('b-badge',{staticClass:"icon-badge",attrs:{"variant":'light-' +
                  _vm.getVariantStep(
                    event.step_id,
                    event.created_at,
                    event.validated_at
                  )}},[_c('feather-icon',{attrs:{"icon":_vm.getIconStep(event.step_id),"size":"16"}})],1),_c('p',{staticClass:"h6 mt-2 mb-1",class:_vm.isDarkSkin ? 'text-white' : ''},[_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(event.created_at))+" ")]),_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(event.steps),expression:"event.steps",modifiers:{"hover":true,"top":true}}],staticClass:"h6 text-primary mb-1 mb-lg-0 text-uppercase text-truncate"},[_c('strong',[_vm._v(_vm._s(event.steps))])]),(
                  event.step_id != 1 &&
                  event.step_id != 2 &&
                  event.step_id != 5
                )?_c('p',{staticClass:"h6 text-success mb-1 mb-lg-0 text-uppercase"},[_c('strong',[_vm._v(_vm._s(event.service_type))])]):_vm._e(),_c('p',{staticClass:"h6 mb-0 mb-lg-0",class:_vm.isDarkSkin ? 'text-white' : 'text-muted'},[_vm._v(" "+_vm._s(event.created_by)+" ")])],1)])}),0)])],1)],1),_c('div',{staticClass:"container"}),(_vm.showModalTracking)?_c('modal-tracking',{attrs:{"caseId":_vm.caseId,"step":_vm.step},on:{"close":function($event){_vm.showModalTracking = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }