import store from "@/store";

export const assignCreditAnalystNotification = () => {
  window.socket.bind(
    "assign-automatic-update-notification",
    async ({
      title,
      requestedUser,
      clientData,
      modules,
    }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];
      if (
        requestedUser.some((user) => user.id == user_id) &&
        current_module_id == modules[0]
      ) {
        store.commit("AutoAlertUpdateRequest/M_OPEN_ALERT", true);
        store.commit("AutoAlertUpdateRequest/M_DATA_UR", clientData);
        store.commit("AutoAlertUpdateRequest/M_TITLE", title);
      }
    }
  );
};

export const toRevisionUrNotification = () => {
  window.socket.bind(
    "to-revision-update-notification",
    async ({
      title,
      clientData,
      loggedInUserId,
      requestedUser
    }) => {
      const { user_id } =
        store.getters["auth/currentUser"];
      if (
        requestedUser.some((user) => user.id == user_id) && loggedInUserId != user_id
      ) {
        store.commit("AutoAlertUpdateRequest/M_OPEN_ALERT", true);
        store.commit("AutoAlertUpdateRequest/M_DATA_UR", clientData);
        store.commit("AutoAlertUpdateRequest/M_TITLE", title);
      }
    }
  );
};

export const returnUrNotification = () => {
  window.socket.bind(
    "return-ur-notification",
    async ({
      title,
      clientData,
      loggedInUserId
    }) => {
      console.log(clientData)
      const { user_id } =
        store.getters["auth/currentUser"];
      if (
        clientData.specialist_id == user_id &&
        loggedInUserId != user_id
      ) {
        store.commit("AutoAlertUpdateRequest/M_OPEN_ALERT", true);
        store.commit("AutoAlertUpdateRequest/M_DATA_UR", clientData);
        store.commit("AutoAlertUpdateRequest/M_TITLE", title);
      }
    }
  );
};

export const completeUrNotification = () => {
  window.socket.bind(
    "complete-ur-notification",
    async ({
      title,
      clientData,
      loggedInUserId,
      requestedUser
    }) => {
      const { user_id } =
        store.getters["auth/currentUser"];
      if (
        requestedUser.some((user) => user.id == user_id) && loggedInUserId != user_id
      ) {
        store.commit("AutoAlertUpdateRequest/M_OPEN_ALERT", true);
        store.commit("AutoAlertUpdateRequest/M_DATA_UR", clientData);
        store.commit("AutoAlertUpdateRequest/M_TITLE", title);
      }
    }
  );
};
