import { nestApi } from "@/service/axios";

class InstagramMetaService {
    async getInstagramChats(payload) {
        try {
            const data = await nestApi.post(`/instagram/chats`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async updateInstagramChat(id, payload) {
        try {
            const data = await nestApi.put(`/instagram/chats/${id}`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async getInstagramChatMessages(payload) {
        try {
            const data = await nestApi.post(`/instagram/messages`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async sendMessage(payload) {
        try {
            const data = await nestApi.post(`/instagram/messages/store`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async sendAttachments(payload) {
        try {
            const data = await nestApi.post(`/instagram/messages/send-attachments`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async markMessageAsSeen(payload) {
        try {
            const data = await nestApi.post(`/instagram/messages/mark-seen`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default new InstagramMetaService();