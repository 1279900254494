const state = {
  S_OPEN_TICKET_CUSTOMER_ID: null,
  S_TICKET_CUSTOMER_CURRENT_SESSION: null,
  S_NEW_TICKET_CUSTOMER_MESSAGE: null,
  S_NEW_REACTION_CUSTOMER_MESSAGE: null,
  S_NEW_VIEWER_TICKET_CUSTOMER_MESSAGE: null,
  S_INDICATORS_STATUS: null,
  S_PENDING_MESSAGES_TO_NOTIFY: [],
  S_CURRENT_VIEW: null,
  S_CODE: null,
  S_TYPE_TICKET: "customer",
  S_TICKET_PARTICIPANTS_STATUS: null,
};
const getters = {
  G_OPEN_TICKET_CUSTOMER_ID() {
    const openTicketCustomerId = state.S_OPEN_TICKET_CUSTOMER_ID;
    return openTicketCustomerId;
  },
  G_NEW_TICKET_CUSTOMER_MESSAGE() {
    const newTicketCustomerMessage = state.S_NEW_TICKET_CUSTOMER_MESSAGE;
    return newTicketCustomerMessage;
  },
  G_NEW_VIEWER_TICKET_CUSTOMER_MESSAGE() {
    const newViewerTicketCustomerMessage =
      state.S_NEW_VIEWER_TICKET_CUSTOMER_MESSAGE;
    return newViewerTicketCustomerMessage;
  },
  G_TICKET_CUSTOMER_CURRENT_SESSION() {
    const ticketCustomerCurrentSession =
      state.S_TICKET_CUSTOMER_CURRENT_SESSION;
    return ticketCustomerCurrentSession;
  },
  G_INDICATORS_STATUS() {
    const indicatorsStatus = state.S_INDICATORS_STATUS;
    return indicatorsStatus;
  },
  G_TYPE_TICKET() {
    const typeTicket = state.S_TYPE_TICKET;
    return typeTicket;
  },
  G_PENDING_MESSAGES_TO_NOTIFY() {
    const pendingMessagesToNotify = state.S_PENDING_MESSAGES_TO_NOTIFY;
    return pendingMessagesToNotify;
  },
  G_REACTION_CUSTOMER_MESSAGE() {
    const newReactionCustomerMessage = state.S_NEW_REACTION_CUSTOMER_MESSAGE;
    return newReactionCustomerMessage;
  },
  G_CURRENT_VIEW() {
    const currentView = state.S_CURRENT_VIEW;
    return currentView;
  },
  G_TICKET_PARTICIPANTS_STATUS() {
    const ticketParticipantStatus = state.S_TICKET_PARTICIPANTS_STATUS;
    return ticketParticipantStatus;
  },
  G_CODE() {
    return state.S_CODE;
  },
};
const mutations = {
  // eslint-disable-next-line no-shadow
  SET_OPEN_TICKET_CUSTOMER_ID(state, payload) {
    state.S_OPEN_TICKET_CUSTOMER_ID = payload;
  },
  // eslint-disable-next-line no-shadow
  SET_NEW_TICKET_CUSTOMER_MESSAGE(state, payload) {
    state.S_NEW_TICKET_CUSTOMER_MESSAGE = payload;
  },
  // eslint-disable-next-line no-shadow
  SET_NEW_VIEWER_TICKET_CUSTOMER_MESSAGE(state, payload) {
    state.S_NEW_VIEWER_TICKET_CUSTOMER_MESSAGE = payload;
  },
  SET_TICKET_CUSTOMER_CURRENT_SESSION(state, payload) {
    state.S_TICKET_CUSTOMER_CURRENT_SESSION = payload;
  },
  SET_INDICATORS_STATUS(state, payload) {
    state.S_INDICATORS_STATUS = payload;
  },
  SET_TYPE_TICKET(state, payload) {
    state.S_TYPE_TICKET = payload;
  },
  SET_PENDING_MESSAGES_TO_NOTIFY(state, payload) {
    state.S_PENDING_MESSAGES_TO_NOTIFY = payload;
  },
  SET_REACTION_CUSTOMER_MESSAGE(state, payload) {
    state.S_NEW_REACTION_CUSTOMER_MESSAGE = payload;
  },
  SET_CURRENT_VIEW(state, payload) {
    state.S_CURRENT_VIEW = payload;
  },
  SET_TICKET_PARTICIPANTS_STATUS(state, payload) {
    state.S_TICKET_PARTICIPANTS_STATUS = payload;
  },
  SET_CODE(state, payload) {
    state.S_CODE = payload;
  },
};
const actions = {
  async A_SET_OPEN_TICKET_CUSTOMER_ID({ commit }, params) {
    commit("SET_OPEN_TICKET_CUSTOMER_ID", params);
  },
  async A_SET_NEW_TICKET_CUSTOMER_MESSAGE({ commit }, params) {
    commit("SET_NEW_TICKET_CUSTOMER_MESSAGE", params);
  },
  async A_SET_NEW_VIEWER_TICKET_CUSTOMER_MESSAGE({ commit }, params) {
    commit("SET_NEW_VIEWER_TICKET_CUSTOMER_MESSAGE", params);
  },
  async A_SET_TICKET_CUSTOMER_CURRENT_SESSION({ commit }, params) {
    commit("SET_TICKET_CUSTOMER_CURRENT_SESSION", params);
  },
  async A_SET_INDICATORS_STATUS({ commit }, params) {
    commit("SET_INDICATORS_STATUS", params);
    commit("SET_TYPE_TICKET", "customer");
  },
  async A_SET_TYPE_TICKET({ commit }, params) {
    commit("SET_TYPE_TICKET", params);
  },
  async A_SET_PENDING_MESSAGES_TO_NOTIFY({ commit }, params) {
    commit("SET_PENDING_MESSAGES_TO_NOTIFY", params);
  },
  async A_SET_REACTION_CUSTOMER_MESSAGE({ commit }, params) {
    commit("SET_REACTION_CUSTOMER_MESSAGE", params);
  },
  async A_SET_TICKET_PARTICIPANTS_STATUS({ commit }, payload) {
    commit("SET_TICKET_PARTICIPANTS_STATUS", payload);
  },
  async A_SET_CODE({ commit }, code) {
    commit("SET_CODE", code);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
