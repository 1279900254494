import store from "@/store";
import Vue from "vue";
import router from '@/router/index';

const ncrProgramRealtorProcessed = () => {
  window.socket.bind("new-ncr-realtor-or-program-processed", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id;
    if (data.to_id == sessionId) {
      const result = await Vue.swal.fire({
        icon: "success",
        html: `<h2 style="font-weight: 600;">${data.message}</h2>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `Go to NCR ${ data.type.charAt(0).toUpperCase() + data.type.slice(1) }`,
        cancelButtonText: "Cancel",
      });
      if (result.value) {
        router.push({ name: `cdigital-ncr-${data.type}` })
        .catch( (err) => {
          if(
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            throw err;
          }
        });
        await window.amgApi.post("/commons/close-all-swal", data);
      } else {
        await window.amgApi.post("/commons/close-all-swal", data);
      }
    }
  });
};
export default ncrProgramRealtorProcessed;