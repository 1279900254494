var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{ref:"CreditCardExpensesTable",staticClass:"table-new-customization custom-table font-small-3 justify-content-start blue-scrollbar",attrs:{"id":"CreditCardExpensesTable","small":"","show-empty":"","sticky-header":"50vh","items":_vm.expensesData,"fields":_vm.visibleFields,"busy":_vm.isBusy},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(expenses_date)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-info"},[_c('feather-icon',{staticStyle:{"margin-right":"5px"},attrs:{"icon":"CalendarIcon"}}),_vm._v(" "+_vm._s(_vm._f("myGlobal")(item.expenses_date))+" ")],1)]}},{key:"cell(vendor)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-warning"},[_vm._v(" "+_vm._s(item.vendor)+" ")])]}},{key:"cell(method)",fn:function(ref){
var item = ref.item;
return [(item.method == 'CREDIT_CARD')?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 p-0 justify-content-end d-flex"},[_c('type-card',{attrs:{"type_card":item.type_card}})],1),_c('div',{staticClass:"col-8 p-0 d-flex justify-content-center align-items-center"},[_c('strong',[_vm._v("XXXX-"+_vm._s(item.cardnumber.slice(-4)))])])])]:[_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(item.method))])]]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" $ "+_vm._s(item.amount))]}},{key:"cell(details)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_c('span',[_c('strong',[_vm._v("Vendor: ")])]),_vm._v(" "+_vm._s(item.vendor)+" ")]),_c('span',{staticClass:"d-block"},[_c('span',[_c('strong',[_vm._v("Concept: ")])]),_vm._v(" "+_vm._s(item.concept)+" ")]),(item.reference_number)?_c('span',{staticClass:"d-block"},[_c('span',[_c('strong',[_vm._v("Ref. number: ")])]),_vm._v(" "+_vm._s(item.reference_number)+" ")]):_vm._e(),(item.url_file)?_c('span',{staticClass:"d-block"},[_c('span',[_c('strong',[_vm._v("Invoice: ")])]),_c('a',{attrs:{"href":item.url_file,"target":"_blank","title":item.file}},[_c('amg-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"FilePdfIcon","size":"18"}})],1)]):_vm._e()]}},{key:"cell(created_by)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(item.created_by))]),_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(item.created_at))+" ")])]}},{key:"cell(updated_by)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-start"},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(item.updated_at))+" ")]),(item.observation && _vm.tab == 3)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(item.observation),expression:"item.observation",modifiers:{"hover":true,"top":true}}],staticClass:"text-info",staticStyle:{"margin-left":"3px"},attrs:{"icon":"InfoIcon","variant":"info"}}):_vm._e()],1)]}},(_vm.tab === 2)?{key:"bottom-row",fn:function(){return [_c('b-th'),_c('b-th',{staticClass:"text-center py-0"},[_c('div',{staticClass:"m-0 p-1",staticStyle:{"font-weight":"600"}},[_vm._v("TOTAL")])]),_c('b-th',{staticClass:"text-center py-0"},[_c('div',{staticClass:"m-0 p-1",staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(_vm._f("formatMoney")(_vm.total))+" ")])]),_c('b-th',{attrs:{"colspan":4}})]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }