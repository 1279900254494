<template>
  <div>
    <b-row>
      <b-col cols="12" md="3">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group label="Type">
            <v-select
              v-bind="typeScope.attr"
              v-model="formExpense.type"
              :class="{ 'invalid-select': errors.length > 0 }"
              :disabled="disabledType"
            ></v-select>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col cols="12" :md="programIdClient ? 6 : 9">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <template>
            <b-form-group label="Credit Card">
              <v-select
                v-bind="cardsExpenseScope.attr"
                v-model="formExpense.credit_card_expense_id"
                :class="{ 'invalid-select': errors.length > 0 }"
              ></v-select>
            </b-form-group>
          </template>
        </ValidationProvider>
      </b-col>
    </b-row>
    <slot name="selectors-general" />
    <b-row>
      <b-col cols="6">
        <b-form-group label="Reference Number">
          <b-form-input
            placeholder="Enter Reference Number"
            v-model="formExpense.reference_number"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Invoice">
          <b-input-group>
            <template #append>
              <div
                class="d-flex justify-content-center align-items-center"
                v-if="formExpense.file"
              >
                <amg-icon
                  icon="FilePdfIcon"
                  class="cursor-pointer text-danger ml-1"
                  size="20"
                  v-b-tooltip.hover.top="'Preview'"
                  @click="previewPdf(formExpense.file)"
                />
                <feather-icon
                  icon="TrashIcon"
                  class="cursor-pointer text-danger ml-1"
                  size="20"
                  v-b-tooltip.hover.top="'Remove'"
                  @click="removeFile"
                />
              </div>
            </template>
            <b-form-file v-model="formExpense.file" accept=".pdf" />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <slot name="transaction-inputs" />
  </div>
</template>
<script>
export default {
  name: "CreditCardForm",
  props: {
    typeExpenses: {
      type: Array,
      required: true,
    },
    formExpense: {
      type: Object,
      required: true,
    },
    disabledType: {
      type: Boolean,
      required: true,
    },
    programIdClient: {
      type: Number,
      default: () => null,
    },
    methodScope: {
      type: Object,
      required: true,
    },
    typeScope: {
      type: Object,
      required: true,
    },
    dataCardsExpense: {
      type: Array,
      required: true,
    },
  },
  computed: {
    cardsExpenseScope() {
      return {
        attr: {
          options: this.dataCardsExpense,
          label: "value",
          reduce: (item) => item.id,
        },
      };
    },
  },
  methods: {
    removeFile(){
      this.$emit('removeFile')
    },
    previewPdf(file){
      this.$emit('previewPdf', file)
    }
  },
  mounted() {},
};
</script>
