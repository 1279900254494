import { th } from "date-fns/locale";

export default [
    {
      key: "start_time",
      label: "Call date",
      visible: true
    },
    {
      key: "audio_url",
      label: "Call record",
      visible: true,
      thClass: "text-center",
      tdClass: "d-flex justify-content-center"
    }
  ];
  