import store from "@/store";
import Vue from "vue";
import router from '@/router/index'
import MigrationsService from "@/views/ce-digital/sub-modules/settings/views/migrations/services/migrations.service";

const migrationRequestNewNotification = () => {
  window.socket.bind("new-migration-client-request-pending", async (dataNotification) => {
    const sessionId = store.state.auth.currentUser.user_id
    const arrUsers = dataNotification.to_id
    const newClientTypeId = dataNotification.new_client_type_id
    const destinationRoute = newClientTypeId === 1 ? "credit-experts-migrations" : "cdigital-migrations"
    var randomTime = Math.floor(Math.random() * 20 + 1) * 50;
    if (arrUsers.includes(Number(sessionId))) {
      // ********************** COUNTER NOTIFICATION *********************
      const params = {
        program: 3,
        client_type_id: newClientTypeId === 1 ? 2 : 1,
        user_id: sessionId,
      };
      const { data } = await MigrationsService.getClientPendingMigration(params);
      if (Object.keys(data).length > 0) {
        const payload = {
          routeName: destinationRoute,
          tag: data[0].count,
        };
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
        if(newClientTypeId === 2) {
          store.dispatch("CeDigitalMigrations/A_SET_COUNTERS_TABS_MIGRATIONS", { pending: data[0].count});
        } else {
          store.dispatch("CreditExpertsMigrationsStore/A_SET_COUNTERS_TABS_MIGRATIONS", { pending: data[0].count })
        }
      }
      // ********************** FIN COUNTER NOTIFICATION *********************
      setTimeout(async function () {
        const result = await Vue.swal.fire({
          icon: "success",
          html: `<h2 style="font-weight: 600;">${dataNotification.message}</h2>`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Go to Migrations",
          cancelButtonText: "Cancel",
        });
        if (result.value) {
          router.push({ name: destinationRoute })
          .catch( (err) => {
            if(
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')
            ) {
              throw err;
            }
          });
          await window.amgApi.post("/commons/close-all-swal", dataNotification);
        } else {
          await window.amgApi.post("/commons/close-all-swal", dataNotification);
        }
      }, randomTime);
    }
  });
};

export default migrationRequestNewNotification;
