import store from "@/store"
import router from "@/router/index"
import Vue from "vue"

const sellerPermissionAlertPusher = () => {
  window.socket.bind("update-seller-typesenior-settings", async (data) => {
    let temps = false
    const sessionId = store.state.auth.currentUser.user_id
    data.to_id.forEach((element) => {
      if (element === sessionId) {
        temps = true
      }
    })
    if (temps) {
      await store.dispatch("SellerPermissionsStore/GET_COUNTER_SELLERS", {
        _status: 1,
      })

      await store.dispatch("SellerPermissionsStore/GET_APPROVAL_PROCESS")
      const payload = {
        routeName: "management-approved",
        tag: store.getters["SellerPermissionsStore/S_COUNTER_APPROVAL_PROCESS"],
      }
      store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
    }
    if (temps && data.status === "PENDING") {
      const htmlAlert = `
                            <img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;" alt="alert-info-image">
                            <div style="font-weight: bold; text-align: center;"><span>New Seller Request!</span></div>
                            <div style="display: flex; flex-direction: column; text-align: start">
                                <span>User: ${data.user_name}</span>
                                <span>Current Level: ${data.typesenior}</span>
                                <span>Request Level: ${data.typesenior_pending} </span>
                                <span>Status: ${data.status}</span>
                            </div>
                            `

      const res = await Vue.swal.fire({
        html: htmlAlert,
        confirmButtonText: "OK",
        allowOutsideClick: true,
        showConfirmButton: true,
      })
      if (res.value) {
        window.open(
          "/management/approval-process/credit-experts-sellers/pending",
          "_blank"
        )

        const params = {
          to_id: store.state.auth.currentUser.user_id,
        }

        await window.amgApi.post("/commons/close-all-swal", params)
      }
    }
  })
}

export default sellerPermissionAlertPusher
