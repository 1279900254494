var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.fromMetamedia ||
      ((this.userCanSell[0].can_sell == 1 ||
        this.currentUser.role_id != 13) &&
        _vm.validateRecovery)
    )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:(_vm.getMessageIcon),expression:"getMessageIcon",modifiers:{"hover":true,"bottom":true}}],staticClass:"cursor-pointer",class:_vm.getMessageIcon != 'Add new appointment'
        ? 'text-secondary'
        : 'text-primary',attrs:{"icon":"CalendarIcon","size":"20"},on:{"click":function($event){return _vm.openModalCreateAppointment()}}}):_vm._e(),_c('b-modal',{attrs:{"id":"modal-event-create","no-close-on-backdrop":"","title-class":"h2 text-white","ok-only":"","modal-class":"modal-primary","centered":"","size":"lg","title":"Create Appointment","hide-footer":""}},[_c('modal-event-create',{attrs:{"reply_id":_vm.replyId,"lead":_vm.lead,"id-process-sale":_vm.idProcessSale,"appointment-validate":"","fromMeta":_vm.fromMetamedia},on:{"resetData":_vm.resetData,"sendMessageFromMeta":_vm.sendMessageFromMeta}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }