import Pusher from "pusher-js";

import store from "@/store";
import subscribeSocket from "./others";
import subscribeSocketDebtSolution from "./debt-solution";
import subscribeSocketMetaMedia from "./meta-media";
import subscribeSocketDepartament from "./departament";
import subscribeSocketManagement from "./management";
import subscribeSocketCorrespondence from "./correspondence";
import subscribeSocketCrm from "./crm";
import subscribeSocketSalesMade from "./sales-made";
import subscribeSocketExpenses from "./expenses";
import subscribeSocketOtherPayments from "./other-payments";
import subscribeClaimsSocket from "./claims";
import subscribeAppointmentsSocket from "./appointments";
import subscribeAutoAlertSocket from "./auto-alert";
import subscribeLogistic from "./logistics";
import subscribeConnection from "./connection";
import subscribeOldSocket from "./old-socket";
import subscribeNcrSocket from "./ncr";
import subscribeCreative from "./creative";
import subscribeTicketCustomer from "./ticket-customer";

import subscribeRiskClientsSocket from "./risk-clients";
import subscribeWorkPlanSocket from "./request-work-plan";
import subscribeRoundLetterSocket from "./round-letter";
import subscribeUpdateRequest from "./update-request";
import subscribeApplicationSocket from "./application-notification";
import subscribeDashboardCeo from "./dashboard-ceo";

export const subscribeAllSocket = () => {
  const socket = new Pusher(process.env.VUE_APP_APPLICATION_KEY, {
    cluster: process.env.VUE_APP_PUSHER_APPLICATION_CLUSTER,
    wsHost: process.env.VUE_APP_PUSHER_APP_HOST,
    wsPort: process.env.VUE_APP_PUSHER_APP_PORT,
    wssPort: process.env.VUE_APP_PUSHER_APP_PORT,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
    auth: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    },
  });
  window.socket = socket;
  if (socket == null || socket == undefined) {
    return;
  }
  subscribeSocket(window.socket);
  subscribeSocketDebtSolution(window.socket);
  subscribeSocketMetaMedia(window.socket);
  subscribeSocketDepartament(window.socket);
  subscribeSocketManagement(window.socket);
  subscribeSocketCorrespondence(window.socket);
  subscribeSocketCrm(window.socket);
  subscribeSocketSalesMade(window.socket);
  subscribeSocketExpenses(window.socket);
  subscribeSocketOtherPayments(window.socket);
  subscribeClaimsSocket(window.socket);
  subscribeAppointmentsSocket(window.socket);
  subscribeAutoAlertSocket(window.socket);
  subscribeLogistic(window.socket);
  subscribeConnection(window.socket);
  subscribeOldSocket(window.socket);
  subscribeNcrSocket(window.socket);
  subscribeCreative(window.socket);
  subscribeTicketCustomer(window.socket);
  subscribeRiskClientsSocket(window.socket);
  subscribeWorkPlanSocket(window.socket);
  subscribeRoundLetterSocket(window.socket);
  subscribeUpdateRequest(window.socket);
  subscribeApplicationSocket(window.socket);
  subscribeDashboardCeo(window.socket);
};

export const unsubscribeAllSocket = () => {
  window.socket.disconnect();
};
const storeUser = store.getters["auth/currentUser"];
if (storeUser != null) {
  subscribeAllSocket();
}
