<template>
  <b-modal
    v-model="onControl"
    size="lg"
    title="Observation"
    title-tag="h3"
    modal-class="modal-primary"
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-form-group label="Title">
        <b-row>
          <b-col cols="10">
            <v-select
              v-model="observation.value"
              label="text"
              :options="observation.options"
              @input="onChangeObservation"
            />
          </b-col>
          <b-col cols="2">
            <b-button
              variant="info"
              style="width: 100%"
              @click="newObservationModal = true"
            >Add new</b-button>
          </b-col>
        </b-row>
      </b-form-group>
      <validation-provider
        v-slot="{ errors }"
        name="observation"
        rules="required"
      >
        <b-form-group label="Observation">
          <b-form-textarea
            v-model="observation.description"
            rows="3"
            :state="errors[0] ? false : null"
            max-rows="6"
          />
        </b-form-group>
      </validation-provider>
    </validation-observer>
    <create-observation-modal
      v-if="newObservationModal"
      @close="newObservationModal = false"
      @addedObservation="addedObservation"
    />
    <template #modal-footer>
      <b-button
        variant="success"
        @click="sendNotification(0)"
      >Send Notification</b-button>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services'
import CreateObservationModal from '@/views/commons/components/clients/dashboard/options/files-module/components/CreateObservationModal.vue'
import vSelect from 'vue-select'

export default {
  name: 'ModalSetObservation',
  components: {
    CreateObservationModal,
    vSelect,
  },
  data() {
    return {
      onControl: false,
      observation: {
        value: null,
        modal: false,
        modalNew: false,
        title: null,
        description: null,
        options: [],
        item: {},
      },
      newObservationModal: false,
    }
  },
  async created() {
    this.onControl = true
    await this.getObservationsFiles()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async sendNotification(status) {
      const result = await this.$refs.form.validate()
      if (result) {
        const emittedData = { status, observation: this.observation }
        this.$emit('sendNotification', emittedData)
      }
    },
    async getObservationsFiles() {
      try {
        const data = await ClientsOptionsServices.getObservationsFiles({})
        if (data.status === 200) {
          this.observation.options = data.data
        }
      } catch (e) {
        console.log('Error on getObservationsFiles Files.vue', e)
      }
    },
    addedObservation(params) {
      this.observation.options.push(params)
    },
    onChangeObservation() {
      this.observation.description = this.observation.value.description
      this.observation.title = this.observation.value.text
    },
  },
}
</script>

<style scoped>

</style>
