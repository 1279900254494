<template>
  <b-row>
    <b-col>
      <h5>Agent</h5>
      <p class="rounded text-primary border-primary font-medium-1 text-center py-1s">
        {{ agent }}
      </p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ProgramClientHeader',
  props: {
    agent: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
.py-1s{
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
