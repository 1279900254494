<template>
  <b-card
    header-text-variant="white"
    header-bg-variant="info"
    header-class="p-0"
    body-class="pt-1 card-body-border"
  >
    <template #header>
      <div class="pl-1 pt-1">
        <p class="font-weight-bolder">{{ title }}</p>
      </div>
    </template>
    <validation-observer ref="formCars">
      <b-row>
        <b-col cols="12">
          <b-button
            :disabled="cars.length >= 5 || readOnly"
            variant="primary"
            @click="addCar()"
            size="sm"
          >
            CAR PAYMENTS
            <feather-icon v-if="cars.length < 5 && !readOnly" icon="PlusIcon" />
          </b-button>
        </b-col>

        <b-col
          v-for="(car, index) in cars"
          :key="index"
          class="mt-1"
          xl="2"
          lg="2"
          md="3"
          sm="3"
          cols="4"
        >
          <div class="d-flex align-items-center justify-content-between">
            <label>{{ `Car ${index + 1}` }}</label>
            <label v-if="index != 0 && index == cars.length - 1">
              <feather-icon
                v-if="!readOnly"
                icon="XCircleIcon"
                class="cursor-pointer text-danger font-medium-2"
                @click="cars.splice(index, 1)"
              />
            </label>
          </div>
          <validation-provider rules="validate-amount-zero" v-slot="{ errors }">
            <money
              :id="`car-${index}`"
              v-model="car.amount"
              v-bind="vMoney"
              :disabled="readOnly"
              class="form-control"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-col>
        <b-col class="mt-1" xl="2" lg="2" md="3" sm="3" cols="4">
          <validation-provider rules="validate-amount-zero" v-slot="{ errors }">
            <label>Gasoline</label>
            <money
              id="gasoline"
              v-model="gasoline"
              v-bind="vMoney"
              :disabled="readOnly"
              class="form-control"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "Cars Information",
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      cars: [],
      gasoline: 0,
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  
  methods: {
    addCar() {
      const index = this.cars.length;
      const car = {
        id: index + 1,
        amount: 0,
      };
      this.cars.push(car);
    },

    async validateForm() {
      const isValidated = await this.$refs.formCars.validate();
      return isValidated;
    },

    // async getQuestionnaireInfo() {
    //   try {
    //     this.addPreloader();
    //     const params = {
    //       lead_id: this.leadId,
    //     };

    //     const { data } = await CourtInfoService.getformationOfQuestionnaire(
    //       params
    //     );

    //     if (data.questionnaire) {
    //       this.cars = data.questionnaire.cars ?? [];
    //       this.gasoline = data.questionnaire.gasoline;
    //     }
    //   } catch (e) {
    //     this.showErrorSwal(e);
    //   } finally {
    //     this.removePreloader();
    //   }
    // },
  },

  watch: {
    cars() {
      this.$emit("updateCars", this.cars);
    },

    gasoline() {
      this.$emit("updateGasoline", this.gasoline);
    },
  },
};
</script>