import { requestWpTabs } from "@/views/commons/components/request-workplan/router/request-workplan.tabs.js";

export default [
  {
    path: "request-workplan",
    name: "request-workplan-paragon",
    redirect: { name: "paragon-wp-pending" },
    meta: {
      pageTitle: "Request Workplan",
      breadcrumb: [
        {
          text: "Request Workplan",
          active: true,
        },
      ],
      routePending: "paragon-wp-pending",
      routeCompleted: "paragon-wp-completed",
      routeToRevision: "paragon-wp-to-revision",
    },
    component: () =>
      import(
        "@/views/commons/components/request-workplan/RequestWorkplanMain.vue"
      ),
    children: requestWpTabs('paragon')
  }
]