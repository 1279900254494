import store from "@/store"
import Vue from "vue"
import { amgApi } from "@/service/axios"
import router from "@/router"

const activationApp = () => {
  window.socket.bind("activation-app", async (data) => {
    const accountClient = store.state.DebtSolutionClients.S_CLIENTS.account
    const sessionId = store.state.auth.currentUser.user_id
    const roleId = store.state.auth.currentUser.role_id
    if (data.account == accountClient && (roleId === 15 || roleId === 14)) {
      // commision for activation app
      const payload = {
        commision_type: 3,
        agent_id: sessionId,
        client_account: store.state.DebtSolutionClients.S_CLIENTS.id,
        module_id: router.currentRoute.matched[0]?.meta?.module,
      }
      try {
        await amgApi.post("/credit-experts-digital/create-commision", payload)
      } catch (error) {
        console.error(error)
      }
      const htmlAlert =
        `${
          '<img src="/assets/images/icons/swal/success.svg" style="margin-bottom: 10px;" alt="alert-info-image">' +
          '<div><h2 style="font-weight: 600;">APP ACTIVATED</h2></div>' +
          '<div style="text-align: center;">' +
          '<div style="font-weight: bolder;">Activated By: '
        }${data.account} </div>` + " </div>"
      const res = await Vue.swal.fire({
        html: htmlAlert,
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        showConfirmButton: true,
      })
      if (res.value) {
        await window.amgApi.post("/commons/close-all-swal", {
          to_id: sessionId,
        })
      }
    }
  })
}
export default activationApp
