<template>
  <b-modal
    v-model="modal.tracking"
    lazy
    centered
    header-bg-variant="primary"
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    title="Tracking"
    modal-class="modal-primary"
    hide-footer
    scrollable
  >
    <b-container fluid>
      <program-client-header
        :client="tracking.client"
        :program="tracking.program"
        :typeClient="typeClient"
        class="sticky-top"
      />
      <b-row class="mt-2">
        <b-table :fields="fields" :items="dataa" small sticky-header class="w-100">
          <template v-slot:cell(date)="data">
            {{ data.item.date | myGlobalWithHour }}
          </template>
          <template v-slot:cell(type)="data">
            <p
              class="text-capitalize my-auto"
              :class="
                data.item.type === 'send'
                  ? 'text-info'
                  : data.item.type === 'approved'
                  ? 'text-success'
                  : data.item.type === 'approved by supervisor'
                  ? 'text-success'
                  : data.item.type === 'approved by seller'
                  ? 'text-success'
                  : data.item.type === 'rejected by supervisor'
                  ? 'text-danger'
                  : data.item.type === 'rejected by seller'
                  ? 'text-danger'
                  : 'text-warning'
              "
            >
              {{ data.item.type }}
            </p>
          </template>
        </b-table>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import ProgramClientHeader from "@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue";
import CrmService from "@/views/crm/services/crm.service";
export default {
  name: "TrackingModal",
  components: { ProgramClientHeader },
  props: {
    modal: {
      type: Object,
      required: true,
    },
    tracking: {
      type: Object,
      required: true,
    },
    typeClient: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fields: [
        {
          key: "date",
          sortable: false,
          label: "Date",
        },
        {
          key: "user_name",
          sortable: false,
          label: "User",
        },
        {
          key: "type",
          sortable: false,
          label: "Action",
        },
        {
          key: "comments",
          sortable: false,
          label: "Observation",
        },
      ],
      dataa: [],
    };
  },
  methods: {
    async getSalesMadeTracking() {
      try {
        if (this.tracking.tabla?.length && this.tracking.tabla.length > 0) {
          this.dataa = this.tracking.tabla;
        } else {
          const { data: tracking } = await CrmService.getSalesMadeTracking({
            sale_id: this.tracking.saleId,
          });
          let track = tracking[0];
          this.tracking.tabla = JSON.parse(track.arr);
          this.dataa = this.tracking.tabla;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    "modal.tracking": function (val) {
      if (val) {
        this.getSalesMadeTracking();
      }
    },
  },
};
</script>

<style scoped>
</style>
