import { amgApi } from '@/service/axios'

class InitialPaymentService {
  async getTransactionsRequest(params) {
    const { data } = await amgApi.post('/authorize/get-transactions-request', params)
    return data
  }

  //generate-transaction
  async generateTransaction(params) {
    const { data } = await amgApi.post('/authorize/generate-transaction', params)
    return data
  }

  async initialPaymentCounter(params) {
    const data = await amgApi.post('/authorize/get-counter-transactions-request', params)
    return data;
  }
}

export default new InitialPaymentService()
