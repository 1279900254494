import store from "@/store"
import router from "@/router/index"
import Vue from "vue"
import moment from "moment"

const pendingPaymentAlert = () => {
  window.socket.bind("pending-payment-alert", async (data) => {
    const pendingPaymentData = JSON.parse(JSON.stringify(data.to_data))
    const sessionId = store.state.auth.currentUser.user_id

    pendingPaymentData.map(async (e) => {
      if (
        sessionId === e.contacted_by &&
        router.currentRoute.matched[0]?.meta?.module === 23
      ) {
        if (pendingPaymentData) {
          const hour_event = moment(e.date_event + " " + e.hour_event).format(
            "MM/DD/YYYY HH:mm"
          )
          const original_hour = e.original_hour
            ? moment(e.original_hour).format("MM/DD/YYYY HH:mm")
            : hour_event
          const htmlAlert =
            `${
              '<img src="/assets/images/icons/swal/bell-light.svg" style="margin-bottom: 10px;" alt="alert-info-image">' +
              '<div><h2 style="font-weight: 600;">REMINDER:</h2></div>' +
              '<div style="text-align: center;">' +
              "<br>" +
              `<div class="text-warning" style="font-size: 16px; font-weight: 600;">Date Original (CA): 
              ${original_hour}
              </div>` +
              `<div class="text-success" style="font-size: 16px; font-weight: 600;">Date Alert: 
              ${hour_event}
              </div>` +
              "<br>" +
              '<div style="margin-bottom: 5px"><span>Pending payment scheduled in 5 minutes!</span></div>' +
              '<div style="font-weight: bolder;">Client: '
            }${e.client_name}</div>` +
            `<div style="font-weight: bolder;">Account: ${e.account}</div>` +
            `<div style="font-weight: bolder;">Comentary: ${e.commentary}</div>` +
            `<div style="font-weight: bolder;">Amount to be charged: $ ${e.monthly_amount}</div>` +
            " </div>"
          const res = await Vue.swal.fire({
            html: htmlAlert,
            timer: 290000,
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            showConfirmButton: true,
            closeOnClickOutside: false,
          })
          if (res.value) {
            await window.amgApi.post(
              "/credit-experts-digital/financial/success-reminder-pending-payment",
              {
                client_account_id: e.client_account_id,
                contacted_by: e.contacted_by,
                program_id: e.program_id,
                module_id: e.module_id,
              }
            )
            await window.amgApi.post("/commons/close-all-swal", {
              to_id: sessionId,
            })
          }
        }
      }
    })
  })
}
export default pendingPaymentAlert
