import NewItemsService from "@/views/commons/components/request-ncr/services/new-items.service.js"

export default {
    namespaced: true,
    state: {
        S_REFRESH_CHARGES: false,
        S_COUNTER_NEW_ITEMS: 0,
        S_REFRESH_TO_REMOVE: false,
    },
    getters: {
        G_REFRESH_CHARGES(state) {
            return state.S_REFRESH_CHARGES;
        },
        G_COUNTER_NEW_ITEMS(state){
            return state.S_COUNTER_NEW_ITEMS;
        },
        G_REFRESH_TO_REMOVE(state) {
            return state.S_REFRESH_TO_REMOVE;
        },
    },
    mutations: {
        M_REFRESH_CHARGES(state, payload) {
            state.S_REFRESH_CHARGES = payload;
        },
        M_COUNTER_NEW_ITEMS(state, payload){
            state.S_COUNTER_NEW_ITEMS = payload;
        },
        M_REFRESH_TO_REMOVE(state, payload) {
            state.S_REFRESH_TO_REMOVE = payload;
        },
    },
    actions: {
        A_REFRESH_CHARGES({ commit }, payload) {
            commit('M_REFRESH_CHARGES', payload);
        },
        async A_GET_NEW_ITEMS_COUNTER({ commit }, params) {
            const { data } = await NewItemsService.getNewItemsCounter(params);
            commit("M_COUNTER_NEW_ITEMS", data);
        },
        A_REFRESH_TO_REMOVE({ commit }, payload) {
            commit('M_REFRESH_TO_REMOVE', payload);
        }
    }
}