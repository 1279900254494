import DisputeResultsService from "../services/dispute-results.service";
import store from "@/store";

const state = {
  refreshTable: false,

  inProcessItems: [],
  completedItems: [],
  client: {
    account: "",
    id: 0,
    name: "",
    account_id: "",
    email: [],
  },
  itemsTableModal: [],
  modalData: {},
  order: "asc",
  totalRows: 0,
  paginate: { currentPage: 1, perPage: 25 },
  startPage: 1,
  toPage: 1,
  programs: [
    { name: "Business", value: 1, module: 3 },
    { name: "BoostCredit", value: 2, module: 7 },
    { name: "CreditExperts", value: 3, module: 6 },
    { name: "DebtSolution", value: 4, module: 5 },
    { name: "TaxResearch", value: 5, module: 8 },
    { name: "CourtInfo", value: 6, module: 10 },
    { name: "Specialist", value: 7, module: 11 },
    { name: "KeyBook", value: 8, module: 14 },
    { name: "Paragon", value: 9, module: 12 },
    { name: "AnalystDepartment", value: 7, module: 28 },
    // for update request
    { name: "CustomerServiceCE", value: 3, module: 22 },
    { name: "Connection", value: 3, module: 20 },
  ],
  S_COUNTER_DISPUTE: 0,
  S_IN_PROCESS_UPDATE: 85,
  S_COMPLETED_UPDATE: 0,
  S_STEP_UR: 0,
};
const mutations = {
  M_SET_CLIENT(state, data) {
    state.client = data;
  },
  M_SET_EMAIL_MODAL(state, data) {
    state.client.email = data;
  },
  M_SET_MODAL_DATA(state, data) {
    state.modalData = data;
  },
  M_SET_DATA_TABLE_MODAL(state, data) {
    state.itemsTableModal = data;
  },
  M_FILL_IN_PROCESS_ITEMS(state, data) {
    state.inProcessItems = data;
  },
  M_FILL_COMPLETED_ITEMS(state, data) {
    state.completedItems = data;
  },
  M_SET_PAGINATION(state, { total, current_page, per_page, from, to }) {
    state.totalRows = total;
    state.paginate = { currentPage: current_page, perPage: per_page };
    state.startPage = from ? from : 1;
    state.toPage = to ? to : 1;
  },
  M_CHANGE_STATUS_ORDER(state) {
    state.order = state.order === "asc" ? "desc" : "asc";
  },
  M_SET_COUNTER_DISPUTE(state, data) {
    state.S_COUNTER_DISPUTE = data;
  },
  M_SET_IN_PROCESS_UPDATE(state, data) {
    state.S_IN_PROCESS_UPDATE = data;
  },
  M_SET_COMPLETED_UPDATE(state, data) {
    state.S_COMPLETED_UPDATE = data;
  },
  SET_REFRESH_TABLE(state, payload) {
    state.refreshTable = payload
  },
  M_IS_EXECUTING_METHOD(state, payload) {
    state.S_IS_EXECUTING_METHOD = payload;
  },
  SET_STEP_UR(state, step) {
    state.S_STEP_UR = step;
  },
};
const actions = {
  async fillInProcessData({ commit }, params) {
    const { data, ...pagination } =
      await DisputeResultsService.getDisputeResults(params);

    commit("M_FILL_IN_PROCESS_ITEMS", data);
    commit("M_SET_PAGINATION", pagination);
  },
  async fillCompletedData({ commit }, params) {
    const { data, ...pagination } =
      await DisputeResultsService.getDisputeResults(params);

    commit("M_FILL_COMPLETED_ITEMS", data);
    commit("M_SET_PAGINATION", pagination);
  },
  async fillDataTableModal({ commit }, param) {
    const data = await DisputeResultsService.getFilesDisputeResults(param);
    commit("M_SET_DATA_TABLE_MODAL", data);
  },
  async setEmailModal({ commit }, param) {
    const data = await DisputeResultsService.getEmailDisputeResults(param);

    commit("M_SET_EMAIL_MODAL", data);
  },
  async A_SET_COUNTER_DISPUTE({ commit }, param) {
    commit("M_SET_COUNTER_DISPUTE", param);
  },
  async A_UPDATE_REQUEST_COUNTERS({ commit }, module) {
    const user_id = store.state.auth.currentUser.user_id;
    const module_id = store.state.auth.currentUser.modul_id;
    const role_id = store.state.auth.currentUser.role_id;

    const { in_process_ur, complete_ur } = await DisputeResultsService.getCounterUpdateRequest({
      user_id,
      role_id,
      module_id
    });

    commit("M_SET_IN_PROCESS_UPDATE", in_process_ur);
    commit("M_SET_COMPLETED_UPDATE", complete_ur);

    // update sidebar

    const sideCounter = module_id == 28 ? in_process_ur + complete_ur : complete_ur;


    if (sideCounter >= 0) {
      const name = {
        28: "update-request-analyst-department",
        14: "update-request-bk",
        7: "update-request-boost-credit",
        3: "update-request-business",
        20: "update-request-connection",
        22: "update-request-ce-digital",
        10: "update-request-court-info",
        6: "update-request-credit-experts",
        5: "update-request-debt-solution",
        12: "update-request-paragon",
        8: "update-request-tax-research"
      }

      const payload = {
        routeName: name[module] || null,
        tag: sideCounter > 99 ? "+99" : sideCounter,
      }

      store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
    }
  },
  CHANGE_REFRESH_TABLE({ commit }, data) {
    commit("SET_REFRESH_TABLE", data);
  },
  A_IS_EXECUTING_METHOD({ commit }, data) {
    commit("M_IS_EXECUTING_METHOD", data);
  },
  async A_STEP_UR({ commit }, id) {
    try {
      const response = await DisputeResultsService.getStepUpdateRequest({
        id: id,
      });
      commit("SET_STEP_UR", response.data);
    } catch (error) {
      console.error("Error fetching step work plan:", error);
    }
  },
  async A_UPDATE_STEP_UR({ commit }, data) {
    try {
      await DisputeResultsService.updateStepUpdateRequest(data);
      commit("SET_STEP_UR", data.step);
      commit("SET_REFRESH_TABLE", true)
    } catch (error) {
      console.error("Error updating step work plan:", error);
    }
  },
};
const getters = {
  gInProcessItems: ({ inProcessItems }) => inProcessItems,
  gCompletedItems: ({ completedItems }) => completedItems,
  gClient: ({ client }) => client,
  gTotalRows: ({ totalRows }) => totalRows,
  gPaginate: ({ paginate }) => paginate,
  gStartPage: ({ startPage }) => startPage,
  gToPage: ({ toPage }) => toPage,
  gProgram:
    ({ programs }) =>
      (module) =>
        programs.filter((program) => program.module == module)[0],
  gModalData: ({ modalData }) => modalData,
  gItemsTable: ({ itemsTableModal }) => itemsTableModal,
  gOrder: ({ order }) => order,

  G_COUNTER_DISPUTE(state) {
    return state.S_COUNTER_DISPUTE;
  },
  G_IN_PROCESS_UPDATE(state) {
    return state.S_IN_PROCESS_UPDATE;
  },
  G_COMPLETED_UPDATE(state) {
    return state.S_COMPLETED_UPDATE;
  },
  getRefreshTable: (state) => state.refreshTable,
  G_IS_EXECUTING_METHOD(state) {
    return state.S_IS_EXECUTING_METHOD;
  },
  G_STEP_UR(state) {
    return state.S_STEP_UR;
  },
};

export default {
  namespaced: "DisputeResult",
  state,
  mutations,
  actions,
  getters,
};
