<template>
  <div
    :ref="target"
    class="details"
    :class="[isDarkSkin ? 'dark' : '', showTooltip ? 'show' : '']"
    :style="tooltip"
  >
    <div class="container-detail">
      <slot name="body"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "Tooltip",
  props: {
    target: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      showTooltip: false,
      tooltip: {
        top: 0,
        left: 0,
        display: "none",
      },
      tooltipAfter: {
        top: 0,
        left: 0,
        display: "none",
      },
    };
  },
  methods: {
    eventMouse() {
      try {
        let stepPadre = document.getElementById(`${this.target}`);
        stepPadre.addEventListener("mouseenter", ($event) => {
          this.showTooltip = true;
          const { top, left } = stepPadre.getBoundingClientRect();
          this.showTooltip = true;
          this.tooltip.display = "block";

          this.$nextTick(() => {
            const divDetail = this.$refs[this.target];
            const { height } = divDetail.getBoundingClientRect();
            this.tooltip.top = top - height - 5 + "px";
            this.tooltip.left = left - 58 + "px";

            this.tooltipAfter.top = this.tooltip.top;
            this.tooltipAfter.left = this.tooltip.left;
            this.tooltipAfter.display = this.tooltip.display;

            divDetail.addEventListener("mouseenter", () => {
              this.showTooltip = true;
              this.tooltip = { ...this.tooltipAfter };
            });

            divDetail.addEventListener("mouseleave", () => {
              this.cleanTooltip();
            });
            
          });
        });

        stepPadre.addEventListener("mouseleave", () => {
          this.cleanTooltip();
        });
      } catch (ex) {
        console.log(ex);
      }
    },
    cleanTooltip() {
      this.showTooltip = false;
      this.tooltip.top = 0;
      this.tooltip.left = 0;
      this.tooltip.display = "none";
    },
  },
  beforeDestroy() {
    // se elimina del body cuando se destruya el component
    if (this.$refs[this.target].parentNode === document.body) {
      document.body.removeChild(this.$refs[this.target]);
    } else {
      const padre = document.querySelector(this.to);
      padre.parentNode.parentNode.removeChild(this.$refs[this.target]);
    }
  },
  mounted() {
    // se agrega al body
    if (this.to == "") {
      document.body.appendChild(this.$refs[this.target]);
    } else {
      const padre = document.querySelector(this.to);
      padre.parentNode.parentNode.appendChild(this.$refs[this.target]);
    }

    this.eventMouse();
  },
};
</script>
<style scoped>
.details {
  background: #ffffff !important;
  width: 350px;
  position: absolute;
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: rgba(182, 173, 173, 0.4) 0 0 15px;
  z-index: 3000;
  display: none;
  padding-bottom: 10px;
}
.details .container-detail {
  padding: 10px 10px 0px 10px;
  height: 255px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .details:hover {
  display: block;
} */
.details.show {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.details::before {
  content: "";
  position: absolute;
  top: 97%;
  left: 20%;
  width: 15px;
  height: 15px;
  /* transform: translateX(-50%);
  border: 6px solid transparent;
  border-top-color: #ffffff; */
  background: #ffffff;
  box-shadow: rgba(182, 173, 173, 0.4) 0 0 15px;
  transform: rotate(45deg);
  z-index: -1000;
}

.details::after {
  content: "";
  position: absolute;
  top: 94%;
  left: 18%;
  width: 30px;
  height: 15px;
  background: #ffffff;
  z-index: -1000;
}
.details.dark::after {
  background: #17171a !important;
}
.details.dark {
  background: #17171a !important;
  box-shadow: rgba(0, 0, 0, 0.548) 0 0 15px;
}

.details.dark::before {
  background: #17171a !important;
  box-shadow: rgba(0, 0, 0, 0.548) 0 0 15px;
  /* border-top-color: #ffffff !important; */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.details .form-group {
  border-bottom: none;
}
</style>