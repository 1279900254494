<template>
  <div class="w-100">
    <!--DATA -->
    <b-card
      no-body
      class="w-100 m-0 pb-0 pt-2 px-2 mt-2"
      :class="{
        'bg-dark': isDarkSkin,
        'custom-bg': !isDarkSkin,
        'border-primary custom-card': showTabs,
      }"
    >
      <b-table
        :items="removeData"
        :fields="visibleFields"
        :sticky-header="'50vh'"
        :foot-clone="false"
        show-empty
        class="custom-table"
        :class="{ 'row-light': !isDarkSkin }"
        :head-row-variant="'grid-custom-tickets-header'"
        :foot-row-variant="'grid-custom-tickets-header'"
      >
        <template #cell(id)="{ index, item }">
          <div class="d-flex justify-content-center align-items-center font-size-md custom-height" style="min-width: 0px;">{{ index + 1 }}</div>
          <ul class="list-unstyled">
            <li
                v-for="(label, index) in bureauDetails(item)"
                :key="index"
                class="padding w-100 d-flex justify-content-center text-center text-capitalize"
              >
                <div style="width: 50px !important;" class="d-flex justify-content-center align-items-center">
                  <span class="text-truncate w-100" style="opacity: 0">{{ label.text }}</span>
                </div>
              </li>
          </ul>
        </template>
        <template #head(transunion)>
          <b-img
            :src="require('@/assets/images/icons/transunion.png')"
            class="custom-img"
            v-b-tooltip.hover.top="'TRANSUNION'"
          />
        </template>
        <template #head(experian)>
          <b-img
            :src="require('@/assets/images/icons/experian.png')"
            class="custom-img"
            v-b-tooltip.hover.top="'EXPERIAN'"
          />
        </template>
        <template #head(equifax)>
          <b-img
            :src="require('@/assets/images/icons/equifax.png')"
            class="custom-img"
            v-b-tooltip.hover.top="'EQUIFAX'"
          />
        </template>

        <template #cell(accounts)="{ item }">
          <!--REMOVE -->
          <ul
            class="list-unstyled w-100 m-0"
            style="min-width: 200px !important;"
          >
            <li
              class="w-100 d-flex align-items-center justify-content-between custom-height"
              style="font-weight: bolder;"
            >
              <div class="w-100 position-relative">
                <div
                  v-for="(label, index) in generalInfo(item)"
                  :key="index"
                  class="w-100"
                >
                  <div
                    class="w-100 d-flex flex-column justify-content-start text-capitalize text-center"
                    v-if="label.key === 'see_more'"
                  >
                    <div
                      class="d-flex flex-column text-center justify-content-center w-100 font-size-xmd font-weight-bolder"
                    >
                      {{ getRowCreditorName(item) }}
                    </div>
                    <span
                      class="w-100 mt-50 text-capitalize px-1"
                      :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                      style="font-size: 12px"
                    >
                      Account #: {{ getRowCreditorAccount(item) }}
                    </span>
                  </div>
                </div>
              </div>
            </li>
            <template v-for="(label, index) in bureauDetails(item)">
              <li
                :key="index"
                class="padding w-100 d-flex justify-content-center text-center text-capitalize"
              >
                <div style="width: 130px !important;" class="d-flex justify-content-center align-items-center">
                  <span class="text-truncate d-flex w-100">{{ label.text }}</span>
                </div>
              </li>
            </template>
          </ul>
        </template>
        <template #cell(classification_ncr)="{ item }">
          <div class="custom-height d-flex justify-content-center align-items-center h-100">
            <h6
              class="text-uppercase font-weight-bolder text-center"
              :style="`color: ${textColor[item.classification_ncr_id]}`"
            >
              {{ item.classification_ncr }}
            </h6>
          </div>
          <ul class="list-unstyled">
            <li
                v-for="(label, index) in bureauDetails(item)"
                :key="index"
                class="padding w-100 d-flex justify-content-center text-center text-capitalize"
              >
                <div style="width: 50px !important;">
                  <span class="text-truncate w-100" style="opacity: 0">{{ label.text }}</span>
                </div>
              </li>
          </ul>
        </template>
        <template #cell(transunion)="{ item }">
          <ItemBureau
            v-if="idClient == null || (!!idClient || toViewRates)"
            :analysisEvaluationForTab="analysisEvaluationForTab"
            :editMode="editMode"
            :item="item.items[0]"
            :labelsRemove="filteredLabels(item)"
            :others="{
              priorityColor,
              currentUser,
              openBySeller,
              moduleId,
              approvedAccounts,
              appointmentExist,
              typeView,
              removeOptions,
            }"
            :typeView="typeView"
            @selectBureauToNewItem="selectBureauToNewItem"
            @changeBoureauResult="changeBoureauResult"
            @update:other="updateOthers(item)"
            @updateRemoveCounter="updateRemoveCounter()"
          />
          <div v-else class="w-100 d-flex justify-content-center align-items-center">
            <img :src="require('@/assets/images/icons/transunion.png')" width="30px" height="30px" class="custom-bg-logo" />
          </div>
        </template>

        <template #cell(experian)="{ item }">
          <ItemBureau
            v-if="idClient == null || (!!idClient || toViewRates)"
            :analysisEvaluationForTab="analysisEvaluationForTab"
            :editMode="editMode"
            :item="item.items[1]"
            :labelsRemove="filteredLabels(item)"
            :others="{
              priorityColor,
              currentUser,
              openBySeller,
              moduleId,
              approvedAccounts,
              appointmentExist,
              typeView,
              removeOptions,
            }"
            :typeView="typeView"
            @selectBureauToNewItem="selectBureauToNewItem"
            @changeBoureauResult="changeBoureauResult"
            @update:other="updateOthers(item)"
            @updateRemoveCounter="updateRemoveCounter()"
          />
          <div v-else class="w-100 d-flex justify-content-center align-items-center">
            <img :src="require('@/assets/images/icons/experian.png')" width="30px" height="30px" class="custom-bg-logo" />
          </div>
        </template>

        <template #cell(equifax)="{ item }">
          <ItemBureau
            v-if="idClient == null ||(!!idClient || toViewRates)"
            :analysisEvaluationForTab="analysisEvaluationForTab"
            :editMode="editMode"
            :item="item.items[2]"
            :labelsRemove="filteredLabels(item)"
            :others="{
              priorityColor,
              currentUser,
              openBySeller,
              moduleId,
              approvedAccounts,
              appointmentExist,
              typeView,
              removeOptions,
            }"
            :typeView="typeView"
            @selectBureauToNewItem="selectBureauToNewItem"
            @changeBoureauResult="changeBoureauResult"
            @update:other="updateOthers(item)"
            @updateRemoveCounter="updateRemoveCounter()"
          />
          <div v-else class="w-100 d-flex justify-content-center align-items-center">
            <img :src="require('@/assets/images/icons/equifax.png')" width="30px" height="30px" class="custom-bg-logo" />
          </div>
        </template>

        <template #cell(detail)="{ item }">
          <div style="width: 40px !important;" class="d-flex justify-content-center align-items-center custom-height">
            <feather-icon
              icon="ChevronDownIcon"
              class="hover-me cursor-pointer"
              size="22"
              :class="{'rotate-180': item.showDetails}"
              @click="item.showDetails = !item.showDetails"
            />
          </div>
          <ul class="list-unstyled overflow-hidden">
            <li
                v-for="(label, index) in bureauDetails(item)"
                :key="index"
                class="padding w-100 d-flex justify-content-center text-center text-capitalize"
              >
                <div style="width: 50px !important;">
                  <span class="text-truncate w-100 d-none">{{ label.text }}</span>
                </div>
              </li>
          </ul>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import fields from "@/views/commons/components/request-ncr/data/items-to-remove.fields.js";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";
import LabelsRemove from "@/views/commons/components/request-ncr/data/result-analysis-cr-labels-remove";
import { mapGetters, mapActions } from "vuex";
import ExportAnalysisForRemove from "@/views/commons/components/request-ncr/views/components/ExportAnalysisForRemove.vue";

import ItemBureau from "@/views/commons/components/request-ncr/views/components/ItemBureau.vue"

export default {
  props: {
    dataGrid: {
      type: Array,
      default: () => [],
    },
    clientData: {
      type: Object,
      default: () => {},
    },


    leadId: {
      type: Number,
      require: true,
      default: 0,
    },
    ncrRequestId: {
      type: Number,
    },
    scoreId: {
      type: Number,
      default: 1,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    isAnalyzed: {
      default: 0,
    },
    idClient: {
      default: null,
      require: false,
      type: String,
    },
    showTabs: {
      type: Boolean,
      default: true,
      require: false,
    },
    openBySeller: {
      type: Boolean,
      default: false,
    },
    typeView_: {
      type: String,
      default: "REMOVE",
    },
    withoutAppointment: {
      type: Boolean,
      default: false,
    },
    moduleIdNcr: {
      type: Number,
      default: 0,
      require: false,
    },
    toViewRates: {
      type: Boolean,
      default: false,
      require: false,
    },
    inDahsboard: {
      type: Boolean,
      default: true,
      require: false,
    },
    showPdfButton: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ExportAnalysisForRemove,
    ItemBureau,
  },
  data() {
    return {
      labelsRemove: LabelsRemove,
      typeView: null,
      showDetails: {},
      fields: fields,
      items: [],
      textColor: {
        1: "#3498DB",
        2: "#1ABC9C",
        3: "#8E44AD",
        4: "#E74C3C",
        5: "#F1C40F",
        6: "#F39C12",
        7: "#95A5A6",
        8: "#34495E",
        9: "#BA4A00",
        10: "#7F8C8D",
      },
      priorityColor: {
        high: "#00F380",
        medium: "#FFAF00",
        low: "#FE0000",
        SETTLE: "#00F380",
        "NEEDS CONFIRMATION": "#FFAF00",
        "NOT SETTLE": "#FE0000",
      },
      itemsByFilter: [],
      editBureaus: false,
      vMoney: {
        decimal: ".",
        thousands: ",",
        precision: 0,
        masked: false,
        prefix: "$ ",
        min: 0.0,
      },
      accountResultData: {},
      showEditNcrAccountModal: false,
      accountSelected: [],
      removeOptions: [
        {
          label: "MEDIUM",
          value: "MEDIUM",
        },
        {
          label: "HIGH",
          value: "HIGH",
        },
      ],
      settlementOptions: [
        {
          label: "SETTLE",
          value: "SETTLE",
        },
        {
          label: "NEEDS CONFIRMATION",
          value: "NEEDS CONFIRMATION",
        },
        {
          label: "NOT SETTLE",
          value: "NOT SETTLE",
        },
      ],
      boureauName: null,
      removeData: [],
      settlementData: [],
      showAccountsModal: false,
      approvedAccounts: false,
      filterParams: {
        typeAccount: "REMOVE",
        idAccounts: [],
      },
      appointmentExist: true,
      addCreditorModalController: false,
      eventId: null,
      typeView: null,
      showCustomPreloader: false,
      customMessagePreloader:
        "The analysis is being generated. This may take a few minutes...",
      isAnalizedCurrent: null,
      showLess: true,
      qtySettlement: 0,
      duplicyBalance: {},
      qtyRemove: 0,
      maxLetter: 30,
      arrayNewItems: [],
    };
  },
  watch: {
    typeView: function () {
      if (this.openBySeller) {
        this.approvedAccountsByClient();
      }
    },
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isNcrGrid() {
      return (
        this.$route.name == "sp-ncr-lead-pending" ||
        this.$route.name == "sp-ncr-lead-finished" ||
        this.$route.name == "sp-ncr-lead-annulled" ||
        this.$route.name == "sp-ncr-client-pending"
      );
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      return this.fields.filter((item) => item.visible);
    },
    totalItemsRemove() {
      let totalRemove = 0;
      let arrayType = ["HIGH", "MEDIUM"];
      this.removeData.forEach((e) => {
        e.items = e.items.map((item) => {
          let is_checked = false;
          if (arrayType.includes(item.priority)) {
            if (item.bureau_id == 1 && item.transunion_result == 1) {
              is_checked = true;
            }
            if (item.bureau_id == 2 && item.experian_result === 1) {
              is_checked = true;
            }
            if (item.bureau_id == 3 && item.equifax_result === 1) {
              is_checked = true;
            }
          }
          item.is_checked = is_checked;
          return item;
        });
        let total = e.items.filter((x) => x.is_checked).length;
        totalRemove += total;
      });
      return totalRemove;
    },
    analysisEvaluationForTab() {
      return this.typeView == "REMOVE"
        ? this.accountResultData.status_result_remove
        : this.accountResultData.status_result_negotiate;
    },
    verifyAccountsSelected() {
      let accounts;
      if (this.typeView == "SETTLEMENTS") {
        accounts = this.settlementData.filter(
          (item) => item.selected && !item.selected_remove
        );
        if (!this.approvedAccounts) {
          this.qtySettlement = accounts.length;
        }
      } else {
        accounts = this.removeData.flatMap((entry) =>
          entry.items.filter(
            (item) => item.is_remove && !item.selected_negotiate
          )
        );
      }
      return accounts.length > 0;
    },
    labelsRemoveVisible() {
      this.modifyVisibilityLabelRemove("select", this.openBySeller);
      return this.labelsRemove.filter((x) => x.visible);
    },
    isDashboardLead(){
      return !!this.$route.params.id;
    },
    isDashboardClient(){
      return !!this.$route.params.idClient;
    },
    isSalesMade(){
      const keywords = ["sales-made","salesmade"];
      return keywords.some((keyword) => this.$route.path.includes(keyword));
    }
  },
  async mounted() {
    this.isAnalizedCurrent = this.isAnalyzed;
    this.typeView = this.typeView_;
      await this.myProvider();
  },
  methods: {
    selectBureauToNewItem(item) {
      if(item.new_item_selected){
        this.arrayNewItems.push(item.id);
      }else{
        let index = this.arrayNewItems.findIndex(x => x == item.id);
        this.arrayNewItems.splice(index, 1);
      }
    },
    async saveData(){
      const params = {
        items: this.arrayNewItems,
        client_name: this.clientData.client_name,
        client_account_id: this.clientData.client_account_id
      }
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.saveNewItems(params)
        if(data.success){
          this.showToast(
            "success",
            "top-right",
            "Congratulation!",
            "CheckIcon",
            "Successfully process"
          )
          this.$store.dispatch("NewItemsStore/A_REFRESH_CHARGES", true)
          await this.A_GET_NEW_ITEMS_COUNTER({
            client_account_id: this.$route.params.idClient,
          });
          this.$store.dispatch("NewItemsStore/A_REFRESH_TO_REMOVE", true)
          await this.getCountAccountsToRemove();
          this.$emit('savedData')
        }else{
          this.showToast(
            "danger",
            "top-right",
            "Oops!",
            "AlertOctagonIcon",
            "Failed process"
          )
        }
      } catch (error) {
        this.showErrorSwal(error)
      } finally {
        this.removePreloader();
      }
    },
    async getCountAccountsToRemove() {
      const params = {
        ncrRequestId: 0,
        idClient: this.$route.params.idClient,
        typeView: "REMOVE",
        leadId: 0,
        toViewRates: false,
      };
      await this.$store.dispatch("AnalysisCrStore/A_COUNT_ANALYSIS_CR", params);
    },
    isOnlyLow(item) {
      return item.items.every(
        (bureau) => bureau.priority == "LOW" || bureau.priority == "-"
      );
    },
    updateRemoveCounter() {
      let aux = this.removeAccountDetailIdSelecteds();
      this.qtyRemove = aux.length;
    },
    generalInfo(item) {
      return this.filteredLabels(item).filter((label) => !label.info);
    },
    bureauDetails(item) {
      return this.filteredLabels(item).filter((label) => label.info);
    },
    filteredLabels(item) {
      return !item.showDetails
        ? this.labelsRemoveVisible.slice(
            0,
            this.checkKeyExists(this.labelsRemoveVisible, "select") ? 3 : 2
          )
        : this.labelsRemoveVisible;
    },
    ...mapActions({
      A_APPOINTMENT_EXIST: "RequestNcrStore/APPOINTMENT_EXIST",
      A_GET_NEW_ITEMS_COUNTER: "NewItemsStore/A_GET_NEW_ITEMS_COUNTER",
    }),
    showingDetails(item) {
      this.$set(item, "showDetails", !item.showDetails);
    },
    getRowCreditorName({ items }) {
      const creditorObj = items.find((item) => item.creditor_name !== "-");
      return creditorObj ? creditorObj.creditor_name : null;
    },
    getRowCreditorAccount({ items }) {
      const creditorObj = items.find((item) => item.creditor_name !== "-");
      return creditorObj ? creditorObj.account_number : null;
    },
    async myProvider() {
      this.removeData = this.dataGrid;
    },
    analyzeBalance(array) {
      const colors = [
        "#EF5350",
        "#45B39D",
        "#F4D03F",
        "#F39C12",
        "#D35400",
        "#EC407A",
        "#AB47BC",
        "#9575CD",
        "#5C6BC0",
        "#42A5F5",
      ];

      const balances = array.map((item) => item.balance);
      const duplicyBalance = balances
        .filter((value, index, array) => {
          return index !== array.findIndex((el) => el === value);
        })
        .reduce((result, item, index) => {
          result[item] = {
            color: colors[index] ? colors[index] : null,
            cant: balances.filter((el) => el === item).length,
          };
          return result;
        }, {});

      this.duplicyBalance = { ...duplicyBalance };
    },
    async changeStatusResult(status) {
      const confirm = await this.showConfirmSwal();
      if (!confirm.isConfirmed) return;

      const params = {
        accountResultId: this.accountResultData.id,
        status: status,
        tab: this.typeView,
      };
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.changeStatusResult(params);
        if (data.success) {
          this.showSuccessToast();
        } else {
          this.showErrorToast();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
      await this.getResultAnalysisAccount();
      this.removePreloader();
    },
    changeClassificationSettlement(item) {
      if (item.priority) {
        this.changeClassification(item);
      } else {
        item.priority = item.priority_cpy;
        item.edit_data = false;
      }
    },
    modifyVisibility(nameKey, isVisible) {
      let keyField = this.fields.findIndex((item) => item.key == nameKey);
      if (keyField != -1) {
        this.fields[keyField].visible = isVisible;
      }
    },
    modifyVisibilityLabelRemove(nameKey, isVisible) {
      let keyField = this.labelsRemove.findIndex((item) => item.key == nameKey);
      if (keyField != -1) {
        this.labelsRemove[keyField].visible = isVisible;
      }
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async changeBoureauResult(item, boureau) {
      let boureauResult;
      switch (boureau) {
        case "transunion":
          boureauResult = "transunion_result";
          break;
        case "experian":
          boureauResult = "experian_result";
          break;
        case "equifax":
          boureauResult = "equifax_result";
          break;
      }
      let verifyResult = this.boureauChecksVerify(item, boureau);
      if (!verifyResult) return;

      const confirm = await this.showConfirmSwal(
        "Are you sure you want to change the " +
          this.capitalizeFirstLetter(boureau) +
          "'s result of account " +
          this.getCreditorName(item) +
          "?"
      );
      if (!confirm.isConfirmed) return;

      const params = {
        analysisAccountId: item.analysis_account_id,
        boureau: boureau,
        value: item[boureauResult] == 0 ? 1 : 0,
        id_detail: item.id,
        tab: this.typeView,
      };
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.changeResultBoureau(params);
        if (data.success) {
          this.showSuccessToast();
          this.myProvider();
        } else {
          this.showErrorToast();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    settlementAccountIdSelecteds() {
      let accountToSettlement = this.settlementData.filter(
        (item) => item.selected
      );
      return accountToSettlement;
    },
    removeAccountIdSelecteds() {
      let accountToRemove = this.removeData.filter((item) => item.selected);
      return accountToRemove;
    },
    verifySelecteds() {
      let aux = this.settlementData.filter((item) => item.selected);
      return aux.length > 0;
    },
    async addAccountsRemoveSelected() {
      const confirm = await this.showConfirmSwal();
      if (!confirm.isConfirmed) return;
      let accountDetailSelected = this.removeAccountDetailIdSelecteds();
      let accountSelecteds = this.removeAccountIdSelecteds();
      const params = {
        accountSelecteds: accountSelecteds,
        lead_id: this.leadId,
        createEvent: true,
        moduleId: this.currentUser.modul_id,
        userId: this.currentUser.user_id,
        accountDetailSelected: accountDetailSelected,
      };
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.addAccountsRemoveSelected(
          params
        );
        if (data.success) {
          this.myProvider();
          this.showToast(
            "success",
            "top-right",
            "Congratulation!",
            "CheckIcon",
            "Successfully process"
          );
          if (!this.showTabs) {
            this.$emit("newEvent", data);
            this.$emit("closeModal");
          } else {
            this.approvedAccountsByClient();
          }
        } else {
          this.showToast(
            "error",
            "top-right",
            "Oops!",
            "XIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    
    boureauChecksVerify(item, boureau) {
      let transResult = item.transunion_result;
      let expResult = item.experian_result;
      let eqResult = item.equifax_result;
      switch (boureau) {
        case "transunion":
          transResult = transResult == 0 ? 1 : 0;
          break;
        case "experian":
          expResult = expResult == 0 ? 1 : 0;
          break;
        case "equifax":
          eqResult = eqResult == 0 ? 1 : 0;
          break;
      }
      if (transResult == 0 && expResult == 0 && eqResult == 0) {
        this.showToast(
          "warning",
          "top-right",
          "Oops!",
          "AlertIcon",
          "There must be at least one bureau marked"
        );
        return false;
      } else {
        return true;
      }
    },
    getCreditorName(item) {
      if (this.typeView == "REMOVE") {
        if (item.items) {
          let credit = item.items.find((item) => item.creditor_name != "-");
          return credit["creditor_name"];
        } else {
          return item.creditor_name;
        }
      } else {
        return item.creditor_name;
      }
    },
    
    
    
    classResults(item, column) {
      return {
        "text-success": item[column] == 1,
        "text-success cursor-pointer icon":
          item[column] == 1 &&
          this.analysisEvaluationForTab == "OBSERVED" &&
          this.editMode,
        "text-warning cursor-pointer icon":
          (item[column] == 0 || item[column] == null) &&
          this.analysisEvaluationForTab == "OBSERVED" &&
          this.editMode,
        "text-secondary":
          (item[column] == 0 || item[column] == null) &&
          this.analysisEvaluationForTab != "OBSERVED",
      };
    },
    closeModal() {
      this.showEditNcrAccountModal = false;
      this.myProvider;
    },
    showSuccessToast() {
      this.showToast(
        "success",
        "top-right",
        "Success!",
        "CheckIcon",
        "Succesfully process"
      );
    },
    showErrorToast() {
      this.showToast("danger", "top-right", "Error", "XIcon", "Failed process");
    },
    addCustomPreloader(message = null) {
      this.showCustomPreloader = true;
      if (message) {
        this.customMessagePreloader = message;
      }
    },
    removeCustomPreloader() {
      this.showCustomPreloader = false;
    },
    removeAccountIdSelecteds() {
      const data = this.accDetailSelected.map((item) => ({
        cr_accounts_ac_id: item.cr_accounts_ac_id,
        analysis_account_id: item.analysis_account_id,
      }));
      const uniqueCrAccountsAcIds = new Set();
      const filteredData = data
        .filter((item) => {
          if (!uniqueCrAccountsAcIds.has(item.cr_accounts_ac_id)) {
            uniqueCrAccountsAcIds.add(item.cr_accounts_ac_id);
            return true;
          }
          return false;
        })
        .map((item) => ({
          cr_accounts_ac_id: item.cr_accounts_ac_id,
          analysis_account_id: item.analysis_account_id,
        }));
      return filteredData;
    },
    removeAccountDetailIdSelecteds() {
      const removeData = this.removeData.flatMap((entry) =>
        entry.items.filter((item) => item.is_remove && !item.selected_negotiate)
      );
      this.accDetailSelected = removeData;
      return removeData;
    },
    convertBureau(bureau) {
      let data = {
        1: "transunion",
        2: "experian",
        3: "equifax",
      };
      return data[bureau];
    },
    seeMore() {
      this.showLess = !this.showLess;
    },
    checkKeyExists(array, selectValue) {
      return array.some((field) => field.key === selectValue);
    },
    updateOthers(item) {
      item.approvedAccounts == 0 ? false : true;
    },
  },
};
</script>
<style scoped>
* {
  --customBgSuccess: #00c04f;
}
.fixed-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
  z-index: 9;
}
.custom-card {
  transform: translateY(-15px);
  border-radius: 0px !important;
}
.custom-img {
  filter: brightness(0) invert(1);
  height: 20px !important;
  width: 20px !important;
}
.custom-font-size {
  font-size: 13px !important;
}
.custom-bg {
  background-color: #eff3fc;
  box-shadow: none !important;
}
.custom-table {
  margin: 0px 10px 25px 10px !important;
  border-radius: 10px !important;
}
.hidden-x{
  overflow-x: hidden !important;
}
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #0090e7 !important;
  color: white;
}
.custom-table tbody tr td{
  border: 1px solid gray !important;
}
.custom-table .table tr td:first-child,
.custom-table-dark .table tr td:first-child{
  border-radius: 0px !important;
}
.row-light >>> tbody tr {
  background-color: white !important;
}
li.padding {
  height: 35px;
  border-top: 0.5px solid #e3e5ec !important;
}
.custom-height{
  height: 100px !important;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.icon {
  animation: heartbeat 1s infinite;
}
.font-size-md {
  font-size: 13px;
}
.font-size-xmd {
  font-size: 12px;
}
.font-size-sm{
  font-size: 11px !important;
}
.rotate-180 {
  transform: rotate(180deg) scale(1.3);
  color: #007DEB !important;
}
.custom-squad {
  width: 12px;
  height: 12px;
  display: flex;
}
.custom-bg-logo{
  opacity: 0.3 !important;
}
.table.b-table > tbody > tr{
  position: relative !important;
}
.table.b-table > tbody > tr.b-table-details > td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.table.b-table > tbody .b-table-row-selected.table-active td{
  background-color: #EBEEFC !important;
}
.custom-icon{
  width: 15px;
  height: 15px;
}
.custom-icon-md{
  width: 19px;
  height: 19px;
}
.size-legends{
  width: 19px !important;
  height: 19px !important;
}
.font-size-legend{
  font-size: 15px !important;
}
ul {
  margin: 0;
}
</style>
