export default {
  namespaced: true,
  state: {
    S_UPDATE_TABLE_EMPLOYEE_CLAIMS: false,
    S_COUNTER_EMPLOYEE_CLAIMS: {
      counterUser: 0,
      counterModule: 0,
    },
    S_ALERT_EMPLOYEE_CLAIMS: {
      status: false,
      employee: "",
      title: "",
      description: "",
    },
  },
  mutations: {
    M_UPDATE_TABLE_EMPLOYEE_CLAIMS(state, params) {
      state.S_UPDATE_TABLE_EMPLOYEE_CLAIMS = params;
    },
    M_COUNTER_EMPLOYEE_CLAIMS(state, params) {
      state.S_COUNTER_EMPLOYEE_CLAIMS = { ...params };
    },
    M_ALERT_EMPLOYEE_CLAIMS(state, params) {
      state.S_ALERT_EMPLOYEE_CLAIMS = { ...params };
    },
  },
  actions: {
    A_UPDATE_TABLE_EMPLOYEE_CLAIMS({ commit }, data) {
      commit("M_UPDATE_TABLE_EMPLOYEE_CLAIMS", data);
    },
    A_COUNTER_EMPLOYEE_CLAIMS({ commit }, data) {
      commit("M_COUNTER_EMPLOYEE_CLAIMS", data);
    },
    A_ALERT_EMPLOYEE_CLAIMS({ commit }, data) {
      commit("M_ALERT_EMPLOYEE_CLAIMS", data);
    },
  },
  getters: {
    G_UPDATE_TABLE_EMPLOYEE_CLAIMS(state) {
      return state.S_UPDATE_TABLE_EMPLOYEE_CLAIMS;
    },
    G_COUNTER_EMPLOYEE_CLAIMS(state) {
      return state.S_COUNTER_EMPLOYEE_CLAIMS;
    },
    G_ALERT_EMPLOYEE_CLAIMS(state) {
      return state.S_ALERT_EMPLOYEE_CLAIMS;
    },
  },
};
