export default [
  // {
  //   label: 'Select',
  //   key: 'select',
  //   thClass: 'text-center mx-0 px-0',
  //   tdClass: 'justify-content-center',
  // },
  {
    key: 'transaction_id',
    label: 'TRANSACTION ID',
  },
  {
    key: 'client',
    label: 'CLIENT',
  },
  {
    key: 'settlement_date',
    label: 'SETTLEMENT DATE',
  },
  {
    key: 'merchant_description',
    label: 'MERCHANT DESCRIPTION',
  },
  // {
  //   key: 'authorize_transaction_id',
  //   label: 'TRANSACTION',
  // },
  {
    key: 'amount',
    label: 'AMOUNT',
  },
  {
    key: 'actions',
    label: 'ACTIONS',
    tdClass: 'text-center',
    thClass: 'text-center',
  },
];
