<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#0FD680" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M17.7149 7.28877C17.8975 7.4739 18 7.72486 18 7.98653C18 8.24819 17.8975 8.49915 17.7149 8.68428L9.79055 16.7111C9.69907 16.8037 9.59032 16.8769 9.47063 16.9265C9.35094 16.9761 9.22271 17.0011 9.0934 17C8.9641 16.9989 8.8363 16.9717 8.71745 16.9201C8.59861 16.8685 8.49109 16.7934 8.40116 16.6993L5.27794 13.429C5.18811 13.336 5.11732 13.2259 5.06964 13.1051C5.02196 12.9843 4.99832 12.8551 5.00009 12.725C5.00186 12.5949 5.029 12.4664 5.07995 12.347C5.1309 12.2275 5.20465 12.1195 5.29698 12.029C5.3893 11.9385 5.49836 11.8674 5.6179 11.8198C5.73744 11.7721 5.86509 11.7489 5.99353 11.7514C6.12196 11.7539 6.24864 11.7821 6.36629 11.8343C6.48393 11.8866 6.59021 11.9619 6.67903 12.0559L9.1134 14.6047L16.3372 7.28877C16.52 7.10386 16.7678 7 17.0261 7C17.2844 7 17.5321 7.10386 17.7149 7.28877Z"
            fill="white" />
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            required: false,
            default: "30"
        },
        height: {
            type: String,
            required: false,
            default: "30"
        }
    }
}
</script>
  