<template>
  <div>
    <b-modal
      v-model="showModal"
      variant="primary"
      size="lg"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
    >
      <template #modal-title>
        <div
          class="text-uppercase text-white font-weight-bolder"
          style="padding: 5px"
        >
          <feather-icon
            icon="ClockIcon"
            size="15"
            class="mr-1"
          />
          Meetings to ticket
          <b v-if="ticketCustomer.code">{{ `"#${ticketCustomer.code}"` }}</b> of <b>{{ `"${ticketCustomer.name_client}"` }}</b>
        </div>
      </template>
      <b-container
        fluid
        class="pb-2 pt-1"
      >
        <b-table
          show-empty
          :fields="fields"
          :items="myProvider"
          table-class="text-nowrap"
          responsive
          small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(meeting_date)="{item, index}">
            <span :class="index === 0 ? 'font-weight-bolder':''">
              {{ item.meeting_date | myGlobal }}
            </span>
          </template>
          <template #cell(meeting_hours)="{item, index}">
            <span
              class="mr-1"
              :class="index === 0 ? 'font-weight-bolder':''"
            >
              <feather-icon
                icon="ClockIcon"
                size="12"
              />
              {{ item.meeting_start | myFullTime }}
            </span>
            -
            <span
              class="ml-1"
              :class="index === 0 ? 'font-weight-bolder':''"
            >
              <feather-icon
                icon="ClockIcon"
                size="12"
              />
              {{ item.meeting_end | myFullTime }}
            </span>
          </template>
          <template #cell(status)="{item, index}">
            <b-badge
              :id="`item_${index}`"
              :variant="bgColor[item.meeting_status_id]"
              class="cursor-pointer"
            >
              {{ item.meeting_status_name }}
            </b-badge>
            <b-tooltip
              :target="`item_${index}`"
              :variant="bgColor[item.meeting_status_id]"
              placement="right"
            >
              <b class="text-uppercase">{{ item.meeting_status_description }}</b>
            </b-tooltip>
            <feather-icon
              v-if="item.description_note"
              v-b-tooltip.hover.v-success
              v-b-tooltip.hover.right
              title="DESCRIPTION NOTE"
              icon="AlignLeftIcon"
              class="ml-1 text-success hover-me cursor-pointer"
              size="18"
              @click="descriptionSelected=item.description_note; showModalDescription=true"
            />
          </template>
          <template #cell(created_at)="{item, index}">
            <span :class="index === 0 ? 'font-weight-bolder':''">{{ item.created_at | myGlobalDay }}</span><br>
            <span :class="index === 0 ? 'font-weight-bolder':''">{{ item.created_by_name }}</span>
          </template>
        </b-table>
      </b-container>
    </b-modal>

    <!--MODAL SHOW DESCRIPTION NOTE-->
    <b-modal
      v-model="showModalDescription"
      header-bg-variant="transparent"
      size="default"
      hide-footer
      centered
      @hidden="descriptionSelected=null; showModalDescription=false"
    >
      <template #modal-title>
        <h5 class="text-uppercase font-weight-bolder">
          <feather-icon
            icon="AlignLeftIcon"
            class="mr-1 hover-me cursor-pointer"
            size="15"
          />
          Description note
        </h5>
      </template>
      <b-container fluid>
        <p class="text-center pb-1">
          {{ descriptionSelected }}
        </p>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import CustomerTicketsServices from '@/views/commons/components/customer-tickets/services/customer-tickets.service';
import { mapGetters } from 'vuex';

export default {
  props: {
    ticketCustomer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      fields: [
        {
          key: 'meeting_date',
          label: 'Date',
        },
        {
          key: 'meeting_hours',
          label: 'Hours',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'status',
          label: 'status',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'created at',
        },
      ],
      bgColor: {
        1: 'warning',
        2: 'info',
        3: 'success',
        4: 'success',
        5: 'secondary',
        6: 'danger',
      },
      descriptionSelected: null,
      showModalDescription: null,
    };
  },
  mounted() {
    this.showModal = true;
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',

    }),
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    async myProvider() {
      try {
        this.isBusy = true;
        const { data } = await CustomerTicketsServices.getMeetingTrackingTicketCustomer({ ticketCustomerId: this.ticketCustomer.id, user_id: this.currentUser.user_id });
        this.isBusy = false;
        return data;
      } catch (error) {
        this.isBusy = false;
        console.log('error in myProvider:', error);
      }
      return [];
    },
  },
};
</script>
