export default [
    {
      path: 'management-departments',
      name: 'management-departments',
      component: () => import(/* webpackChunkName: "ManageDepartaments" */ '@/views/management/views/departments/Departments.vue'),
      meta: {
        // permittedRoles: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        pageTitle: 'Departments',
        breadcrumb: [{
          text: 'Departments',
        }],
      },
    },
  ];