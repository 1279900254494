export default [
  {
    path: "clients/account/:idClient",
    name: "management-dashboard",
    redirect: { name: "personal-information-management" },
    component: () =>
      import(
        /* webpackChunkName: "AdministrationClientAccount" */ "@/views/administration/views/clients/Account.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
      isManagement: true,
    },
    children: [
      {
        path: "pay",
        name: "pay-management",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientAccountPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "acc",
        name: "acc-management",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientAccountAcc" */ "@/views/commons/components/clients/dashboard/options/acc-module/Acc.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acc",
              active: true,
            },
          ],
        },
      },
      {
        path: "dis",
        name: "dis-management",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientAccountDis" */ "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Dispute",
              active: true,
            },
          ],
        },
      },
      {
        path: "task-notes",
        name: "task-notes-management",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientAccountTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information",
        name: "personal-information-management",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientAccountPi" */ "@/views/administration/views/clients/dashboard/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files",
        name: "client-files-management",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientAccountFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr",
        name: "acr-management",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientAccountAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "report",
        name: "report-ca-management",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientAccountReport" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log",
        name: "call-log-management",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientAccountReport" */ "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
        },
      },
      {
        path: "tickets",
        name: "client-tickets-management",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
