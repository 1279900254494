import store from "@/store";
import Notifications from '@/service/notification/index';
import ZeroPaymentService from "@/views/boost-credit/views/zero-payment/service/zero-payment.service"
import ApplicationService from "@/views/commons/components/applications/services/applications.service"
import NcrProgramsService from "../commons/components/ncr-programs/service/ncr-programs.service"
import DisputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service"
import ChargeBackService from "@/views/commons/components/charge-back/charge-back.service"
import chargeBackV2Service from "@/views/commons/components/charge-back_v2/service/charge.back.service.js"
import ScheduleService from "@/views/commons/components/schedules/services/schedules.service"
import CallRoundsService from "@/views/commons/call-rounds/service/call-rounds.service"
import employeeClaims, { salesMadeCounter } from "@/views/commons/commons-counter"
import RequestService from "@/views/commons/components/request/services/request.service.js"
import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';
import LettersRoundService from "@/views/specialists/sub-modules/analyst-department/views/letter-round/services/letters-rounds.service.js";
import PotentialService from "@/views/commons/components/potencial-appointment/services/potencial-appointments.service";
import OtherServicesService from "@/views/commons/components/other-services/service/appointment.service";
import AppointmentService from '@/views/commons/components/appointments/service/appointment.service';

const ncrProgramsCounter = async () => {
  const params = {
    id: store.state.auth.currentUser.user_id,
    type: 1,
    program: 2,
    role: store.state.auth.currentUser.role_id,
    modul: store.state.auth.currentUser.modul_id,
  }
  const data = await NcrProgramsService.getCounterNcrPrograns(params)
  if (data.length > 0) {
    const payload = {
      routeName: "boostcredit-ncr-programs",
      tag: data[0].cont,
    }
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
  }
}
const applicationCounter = async () => {
  const params = {
    id: store.state.auth.currentUser.user_id,
  }
  const data = await ApplicationService.getNotificationsApp(params)

  if (data.length > 0) {
    const payload = {
      routeName: "boost-credit-applications",
      tag: data.length,
    }
    // store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
  }
}
const disputeCounter = async () => {
  const params = {
    read: 1,
    program_id: 2,
  }

  const data = await DisputeResultsService.getNotificationsDispute(params)
  if (data.length > 0) {
    const payload = {
      routeName: "boost-credit-dispute-results",
      tag: data[0].count,
    }
    store.dispatch(
      "BoostCreditDisputeResult/A_SET_COUNTER_DISPUTE",
      data[0].count
    )
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
  }
}
const chargeBackCounter = async () => {
  const params = {
    modul: 7,
  }
  const data = await ChargeBackService.getCountNotificationsChargeBack(params)

  if (data.length > 0) {
    const payload = {
      routeName: "boostcredit-charge-back",
      tag: data[0].cc,
    }
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
  }
}
const schedulesCounter = async () => {
  const params = {
    moduleid: 7,
  }
  const data = await ScheduleService.getSchedulesCounter(params)
  if (data) {
    const payload = {
      routeName: "schedules-boostcredit",
      tag: data.grandTotal > 99 ? "99+" : data.grandTotal,
    }
    store.dispatch("SchedulesStore/A_SET_COUNTERS_TABS_SCHEDULES", data)
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
  }
}

const ncrRealtorCounter = async () => {
  const params = {
    id: store.state.auth.currentUser.user_id,
    type: 2,
    program: 2,
    role: store.state.auth.currentUser.role_id,
    modul: store.state.auth.currentUser.modul_id,
  }
  const data = await NcrProgramsService.getCounterNcrPrograns(params)
  if (data.length > 0) {
    const payload = {
      routeName: "boostcredit-letters",
      tag: data[0].cont,
    }
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
  }
}

const zeroPaymentCounter = async () => {
  if (store.state.auth.currentUser != null) {
    const params = {
      id_global: store.state.auth.currentUser.user_id,
      id_status: 3,
      id_modul: 2, //Boost Credit
      id_role: store.state.auth.currentUser.role_id,
    }
    const { data } = await ZeroPaymentService.getZeroPaymentCounter(params)
    if (data.length > 0) {
      const payload = {
        routeName: "zero-payment-boost-credit",
        tag: data.length,
      }
      store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
    }
  }
}

const callRoundsCounter = async () => {
  const params = {
    id_user: store.state.auth.currentUser.user_id,
    id_role: store.state.auth.currentUser.role_id,
    id_program: 2,
  }
  const { data } = await CallRoundsService.contCallsRoundsUsers(params)
  if (data.length > 0) {
    const payload = {
      routeName: "call-rounds-boostcredit",
      tag: data[0].count,
    }
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
  }
}

const chargebackCounter = async () => {
  const module = store.state.auth.currentUser.modul_id

  const params = {
    module_id: module,
  }

  const { in_process, remove_cb, payment_client, pending, observed } =
    await chargeBackV2Service.getChargebackCounters(params)

  const solution = remove_cb + payment_client
  const dispute = pending + observed
  const counterSide = in_process + solution + dispute

  const payload = {
    routeName: "bc-charge-back",
    tag: counterSide,
  }

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
}

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id}
  const data = await RequestService.getCounterNotAsigned(params)

  const payload = {
      routeName: 'request-boostcredit',
      tag: data[0].counter
  }
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload)
}
const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'boost-credits-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'boost-credits-recruitment-process',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({ user_id, moduleId });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: 'boost-credit-customer-tickets',
      tag: data[0].v_total_open,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const returnedLettersCounter = async () => {
  const { data } = await LettersRoundService.getCounterReturnedLettersDepartment({
    moduleId: 7,
    isConnection: false,
  })

  // const value = data > 99 ? "99+" : data;

  const payload = {
    routeName: "bc-letters-dispute",
    tag: data,
  }

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
}

const getPendingPotential = async () => {
  const { modul_id } = store.state.auth.currentUser;
  const data = await PotentialService.getCountPending({ module_id: modul_id });
  const payload = {
    routeName: "boost-credit-potencial-appointment",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const getPendingOtherServices = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  const data = await OtherServicesService.getCountPending({
    module_id: modul_id,
    user_id,
    role_id,
  });
  const payload = {
    routeName: "boost-credit-other-services",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};
const getPendingAppointment = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  const { pending } = await AppointmentService.getCountAppointmentsPending({
    module_id: modul_id,
    user_id,
    role_id,
  });
  const payload = {
    routeName: "boost-credit-appointment",
    tag: pending,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};
const getPendingApplications = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  // let specialist_id = [1, 2].includes(role_id) || (role_id === 11 && modul_id === 6) ? null : user_id;
  let advisorId = ([1, 2].includes(role_id) || (role_id === 11 && modul_id === 6) || [11, 25, 28, 29, 30].includes(modul_id)) ? null : user_id;
  const params = {
    user_id,
    role_id,
    program_id: 2,
    specialist_id: null,
    module_id: modul_id,
    advisor_id: advisorId,
  };
  const data = await ApplicationService.getCountPending(params);
  const payload = {
    routeName: "boost-credit-applications",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const allCountersBoostCredit = async () => {
  Promise.all([
    salesMadeCounter('boost-credit-sales-made'),
    zeroPaymentCounter(),
    projectsCounter(),
    applicationCounter(),
    ncrProgramsCounter(),
    ncrRealtorCounter(),
    schedulesCounter(),
    callRoundsCounter(),
    chargebackCounter(),
    employeeClaims("bc-employee-claims"),
    requestNotAsignedCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
    returnedLettersCounter(),
    getPendingPotential(),
    getPendingOtherServices(),
    getPendingAppointment(),
    getPendingApplications(),
  ])
}
export default allCountersBoostCredit
