import { amgApi } from "@/service/axios";

class LeadService {
  async getLeads(params) {
    try {
      const data = await amgApi.post("commons/debt-solution/get-leads", params);
      return data.data;
    } catch (error) {
      console.log("Error getLeads");
    }
  }
  async getUsersAllPrograms(params) {
    try {
      const data = await amgApi.post("commons/users-all-programs", params);
      return data.data;
    } catch (error) {
      console.log("Error getUsersAllPrograms");
    }
  }
  async getUsersModule(modul, params){
    try {
      const data = await amgApi.post(`commons/user-module/${modul}`, params)
      return data.data
    } catch (e) {
      console.log(e)
    }
  }
  async createLeadEvent(params) {
    try {
      const data = await amgApi.post("lead/event/create-lead-event", params);
      return data.data;
    } catch (error) {
      console.log("Error createLeadEvent");
    }
  }
  async updateLeadEvent(params) {
    try {
      const data = await amgApi.post("lead/event/update-lead-event", params);
      return data;
    } catch (error) {
      console.log("Error createLeadEvent");
    }
  }
  async insertSaleAttendEvent(params) {
    try {
      const data = await amgApi.post(
        "/attend/insert-sale-attend-event",
        params
      );
      return data;
    } catch (error) {
      console.log("Error insertSaleAttendEvent");
      throw error;
    }
  }
  async insertSaleDebt(params) {
    try {
      const data = await amgApi.post("/attend/insert-sale-debt", params);
      return data;
    } catch (error) {
      console.log("Error insertSaleDebt");
      throw error;
    }
  }
  async financialAnalysisOption(params) {
    try {
      const data = await amgApi.post("/attend/financial-analysis-option", params);
      return data;
    } catch (error) {
      console.log("Error financialAnalysisOption");
      throw error;
    }
  }
  async getFinancialAnalysisOptions() {
    try {
      const data = await amgApi.get("/attend/get-financial-analysis-options");
      return data;
    } catch (error) {
      console.log("Error getFinancialAnalysisOptions");
      throw error;
    }
  }
  async insertProgramsDebt(params) {
    try {
      const data = await amgApi.post("/commons/attendend-program-debt", params);
      return data;
    } catch (error) {
      console.log("Error insertProgramsDebt");
      throw error;
    }
  }
  async insertProgramsDebtClient(params) {
    try {
      const data = await amgApi.post("/commons/attendend-program", params);
      return data;
    } catch (error) {
      console.log("Error insertProgramsDebtClient");
      throw error;
    }
  }
  async insertSaleMade(params) {
    try {
      const data = await amgApi.post(
        "/commons/attendend-program-client-sale-made",
        params
      );
      return data;
    } catch (error) {
      console.log("Error insertSaleMade");
      throw error;
    }
  }
  async getPrograms() {
    try {
      const data = await amgApi.get("/commons/programs");
      return data.data;
    } catch (error) {
      console.log("Error programs");
    }
  }
  async getLeadsSales(params) {
    try {
      const data = await amgApi.post("credit-experts-digital/financial/get-leads-sales", params);
      return data.data;
    } catch (error) {
      console.log("Error getLeads");
    }
  }
  async getCurrentFinancialAnalysis(params) {
    try {
      const data = await amgApi.post("/attend/get-current-financial-analysis", params);
      return data;
    } catch (error) {
      console.log("Error getFinancialAnalysisOptions");
      throw error;
    }
  }
  async updateFinancialAnalysisOptionA(params) {
    try {
      const data = await amgApi.post("/attend/update-financial-analysis-option-a", params);
      return data;
    } catch (error) {
      console.log("Error getFinancialAnalysisOptions");
      throw error;
    }
  }

  async validateCompleteCrProcess(body){
    try {
        const data = await amgApi.post("/sales-made/debt-solution/validate-complete-cr-process", body);
        return data;
    } catch (error) {
        throw error;
    }
  }

  async listCreditorsToEvent(body){
    try {
      const data = await amgApi.post("/crm/event/list-creditors-to-event", body);
    } catch (error) {
      throw error;
    }
  }

  async accountsRemoveToEvent(body){
    try {
        const data = await amgApi.post("/crm/event/accounts-remove-to-event", body);
        return data;
    } catch (error) {
        throw error;
    }
  }

  async getNotSolds(payload){
    try {
      const { data } = await amgApi.post('/sales-made/not-solds', payload);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllPrograms(payload){
    try {
      const { data } = await amgApi.get('/new-commons/programs/index', payload);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new LeadService();
