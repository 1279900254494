import { amgApi } from "@/service/axios"

class OtherPaymentsService {
  async getOtherManualCeDigitalPayments(params) {
    const data = await amgApi.post(
      "/credit-experts-digital/financial/get-other-manual-ce-digital-payments", params
    )
    return data
  }
  async approveOtherManualCeDigitalPayments(params) {
    const data = await amgApi.post(
      "/credit-experts-digital/financial/approve-other-payment-ce-digital", params
    )
    return data
  }
  async disapproveOtherManualCeDigitalPayments(params) {
    const data = await amgApi.post(
      "/credit-experts-digital/financial/disapprove-other-payment-ce-digital", params
    )
    return data
  }
  async getCeOtherPaymentsTracking(params) {
    const data = await amgApi.post(
      "/credit-experts-digital/financial/get-ce-other-payments-tracking", params
    )
    return data
  }
  async getCeOtherPaymentsPendingCounter() {
    const data = await amgApi.get(
      "/credit-experts-digital/financial/get-other-manual-pending-counter")
    return data
  }
  async getDisapprovedSaleCounter(params) {
    try {
      const data = await amgApi.get(
        "/credit-experts-digital/financial/get-disapproved-sale-counter"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new OtherPaymentsService()
