import Vue from "vue";
import Vuex from "vuex";
//import Test from "../views/test/test.store";


import Leads from "@/views/commons/components/lead-programs/store/leads.store";
import Clients from '@/views/boost-credit/views/clients/store/index'
import ZeroPayment from "@/views/commons/components/zero-payment/store/zero-payment.store";
import NcrPrograms from "@/views/commons/components/ncr-programs/store/ncr-programs.store";
import NcrRealtor from '@/views/commons/components/ncr-realtor/store/ncr-realtor.store'
import Applications from "@/views/commons/components/applications/store/applications.store";
import DisputeResult from '@/views/commons/components/dispute-results/store/dispute-results.store'
import TrackingListStore from "@/views/commons/components/tracking-list/store/tracking-list.store"
// import Creditors from "@/views/debt-solution/views/creditors/store/creditors.store";


Vue.use(Vuex);

export default {
  //BoostCreditTest: Test,
  BoostCreditZeroPayment: ZeroPayment,
  BoostCreditClients: Clients,
  BoostCreditLeads: Leads,
  BoostCreditNcrPrograms: NcrPrograms,
  BoostCreditNcrRealtor: NcrRealtor,
  BoostCreditApplications: Applications,
  BoostCreditDisputeResult: DisputeResult,
  BoostCreditTrackingList: TrackingListStore,
};