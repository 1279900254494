var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"xmd","title":"Details Claim","no-close-on-backdrop":false,"centered":"","hide-footer":"","content-class":"modal-details"},on:{"hide":_vm.closeMe},model:{value:(_vm.activeModal),callback:function ($$v) {_vm.activeModal=$$v},expression:"activeModal"}},[_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.loading,"variant":"primary","opacity":0.5,"blur":"2px","rounded":"sm"}},[_c('b-form',{ref:"formSolution"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":_vm.hasMoreThanOneAccount ? '6' : '4'}},[_c('span',{staticClass:"title-tag"},[_vm._v("CLIENT NAME")]),_c('div',{staticClass:"border border-primary rounded"},[_c('p',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s",staticStyle:{"gap":"5px"}},[_vm._v(" "+_vm._s(_vm.selectedClaim.client)+" ")])])]),_c('b-col',{attrs:{"cols":"12","lg":_vm.hasMoreThanOneAccount ? '6' : '4'}},[_c('span',{staticClass:"title-tag"},[_vm._v("PROGRAM")]),_c('div',{staticClass:"border border-primary rounded"},[_c('p',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s"},[_vm._v(" "+_vm._s(_vm.selectedClaim.program)+" ")])])]),_c('b-col',{attrs:{"cols":"12","lg":_vm.hasMoreThanOneAccount ? '12' : '4'}},[_c('span',{staticClass:"title-tag"},[_vm._v(_vm._s(_vm.hasMoreThanOneAccount ? "ACCOUNTS" : "ACCOUNT"))]),_c('div',{staticClass:"border border-primary rounded"},[_c('div',{staticClass:"text-primary d-flex align-items-center justify-content-center my-1s"},[_c('b-row',{staticClass:"d-flex justify-content-center text-center"},_vm._l((JSON.parse(_vm.selectedClaim.other_accounts)),function(item){return _c('b-col',{key:item.account},[_c('b-badge',{key:item.account,staticClass:"d-inline-flex",attrs:{"variant":item.account == _vm.selectedClaim.account
                        ? 'light-primary'
                        : 'light-info'}},[_c('status-account',{attrs:{"account":item,"text":false}}),(_vm.moduleId == 18)?_c('router-link',{staticClass:"text-primary",attrs:{"target":"_blank","to":{
                        name: 'quality-client-dashboard',
                        params: {
                          idClient: ("" + (item.id)),
                        },
                      }}},[_vm._v(" "+_vm._s(item.account)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.account)+" ")])],1)],1)}),1)],1)])])],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{staticClass:"mb-1"},[_c('span',{staticClass:"d-block text-uppercase text-primary fw-700"},[_c('strong',[_vm._v("CLAIM DATA")])]),_c('b-row',{staticClass:"mt-2"},[_c('itemValue',{staticClass:"col-12 col-sm-6 col-lg-4",attrs:{"text":_vm.selectedClaim.type_claim,"title":"Type"}}),_c('itemValue',{staticClass:"col-12 col-sm-6 col-lg-4",attrs:{"text":_vm.selectedClaim.source_claim,"title":"Source"}}),_c('itemValue',{staticClass:"col-12 col-sm-6 col-lg-4",attrs:{"text":_vm.selectedClaim.priority,"title":"Priority"}}),(_vm.selectedClaim.amount_department)?_c('itemValue',{staticClass:"col-12 col-sm-6 col-lg-4",attrs:{"text":'$ ' + _vm.selectedClaim.amount_department,"title":"Amount suggered by Dept."}}):_vm._e(),(_vm.selectedClaim.amount_client)?_c('itemValue',{staticClass:"col-12 col-sm-6 col-lg-4",attrs:{"text":'$ ' + _vm.selectedClaim.amount_client,"title":"Amount requested by client"}}):_vm._e(),_c('b-col',{staticClass:"col-12 col-sm-6 col-lg-4"},[_c('b-form-group',{attrs:{"label":"Reason","label-for":"select-1"}},[_c('div',{staticClass:"form-control d-flex align-items-center justify-content-center",class:_vm.skin == 'dark' ? 'disabled' : '',staticStyle:{"gap":"3px"}},[_vm._l((_vm.reasonData(_vm.selectedClaim)),function(reason,index){return [(index < 3)?_c('b-badge',{key:reason.id,staticClass:"px-1 text-primary",style:({
                          backgroundColor: 'rgba(0, 144, 231, 0.12)',
                        })},[_vm._v(" "+_vm._s(reason.name)+" ")]):_vm._e()]}),(_vm.reasonData(_vm.selectedClaim).length > 2)?[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"EyeIcon","size":"16","id":"tooltip-1"}}),_c('b-tooltip',{attrs:{"target":'tooltip-1',"triggers":"hover","placement":"top"}},[_vm._l((_vm.reasonData(_vm.selectedClaim)),function(reason){return [_c('b-badge',{key:reason.id,staticClass:"d-block mb-1s",attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(reason.name)+" ")])]})],2)]:_vm._e()],2)])],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"input-textarea","label":"Claim description","label-for":"textarea-description"}},[_c('b-form-textarea',{attrs:{"id":"textarea-no-resize","placeholder":"enter claim description","rows":"3","no-resize":"","disabled":""},model:{value:(_vm.selectedClaim.description),callback:function ($$v) {_vm.$set(_vm.selectedClaim, "description", $$v)},expression:"selectedClaim.description"}})],1)],1)],1)],1)],1)],1),(_vm.dataSolutionsForClaim.length > 0)?_c('StepLevel',{attrs:{"dataSolutionsForClaim":_vm.dataSolutionsForClaim,"selectedClaim":_vm.selectedClaim}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }