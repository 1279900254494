export default [
  {
    path: "/debtsolution/settings",
    name: "debt-solution-settings",
    redirect: { name: "debt-solution-settings-1" },
    component: () =>
      import(
       /* webpackChunkName: "DSSettings" */ "@/views/commons/components/settings/views/components/SettingsMain.vue"
      ),
    meta: {
      pageTitle: "Settings",
      breadcrumb: [
        {
          text: "Settings",
          active: true,
        },
      ],
      permittedRoles: [1, 2, 17]
    },
    children: [
      {
        path: "",
        name: "debt-solution-settings-1",
        redirect: { name: "debt-solution-settings-messages" },
        component: () =>
          import(/* webpackChunkName "DSSettingsGrid" */ "@/views/commons/components/settings/views/components/SettingsPanel.vue"),
        meta: {
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Messages",
              active: true
            }
          ],
          DebtSolutionSettingsMessages: "debt-solution-settings-messages",
          DebtSolutionSettingsShippindDays: "debt-solution-settings-shipping-days",
          permittedRoles: [1, 2, 17]
        },
        children: [
          {
            path: "message",
            name: "debt-solution-settings-messages",
            component: () => import( /* webpackChunkName: "DSSettingsGrid-main" */ "@/views/commons/components/settings/views/components/MessagesTab/SettingsMessagesMain.vue"),
            meta: {
              pageTitle: "Settings",
              breadcrumb: [
                {
                  text: "Messages",
                  active: true
                }
              ],
              permittedRoles: [1, 2, 17]
            },
          },
          {
            path: "shipping",
            name: "debt-solution-settings-shipping-days",
            component: () => import( /* webpackChunkName: "DSSettingsGrid-main" */ "@/views/commons/components/settings/views/components/ShippingDaysTab/ShippingDays.vue"),
            meta: {
              pageTitle: "Settings",
              breadcrumb: [
                {
                  text: "Shipping days",
                  active: true
                }
              ],
              permittedRoles: [1, 2, 17]
            },
            children: [
              {
                path: "shipping",
                name: "ds-shipping-days-table",
                component: () => import("@/views/commons/components/settings/views/components/ShippingDaysTab/components/ShippingDaysTable.vue"),
                meta: {
                  pageTitle: "Settings",
                  breadcrumb: [
                    {
                      text: "Shipping Days",
                      active: true,
                    },
                  ],
                  permittedRoles: [1, 2, 17]
                },
              }
            ]
          }
        ]
      },
      {
        path: "bank-accounts",
        name: "debt-solution-settings-bank-accounts",
        redirect: { name: "debt-solution-settings-bank-accounts-listing" },
        component: () =>
          import(/* webpackChunkName "DSSettingsGrid" */  "@/views/debt-solution/views/bank-account/components/BankAccountSubTab.vue"),
        meta: {
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Bank Accounts",
              active: true
            }
          ],
          permittedRoles: [1, 2, 17]
        },
        children: [
          {
            path: "listing",
            name: "debt-solution-settings-bank-accounts-listing",
            component: () => import("@/views/debt-solution/views/bank-account/components/BankAccountTable.vue"),
            meta: {
              pageTitle: "Settings",
              breadcrumb: [
                {
                  text: "Listing",
                  active: true
                }
              ],
              permittedRoles: [1, 2, 17]
            },
          },
          {
            path: "deleted",
            name: "debt-solution-settings-bank-accounts-deleted",
            component: () => import("@/views/debt-solution/views/bank-account/components/BankAccountTable.vue"),
            meta: {
              pageTitle: "Settings",
              breadcrumb: [
                {
                  text: "Deleted",
                  active: true
                }
              ],
              permittedRoles: [1, 2, 17]
            },
          },
        ]
      },
      {
        path: "court-info",
        name: "debt-solution-court-info",
        redirect: { name: "debt-solution-ci-message-cost" },
        component: () =>
          import(/* webpackChunkName "DSSettingsGrid" */  "@/views/debt-solution/views/settings/views/CourtInfo.vue"),
        meta: {
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Court Info",
              active: true
            }
          ],
          DebtSolutionMessageConfigEmail: "debt-solution-ci-message-cost",
          permittedRoles: [1, 2, 17]
        },
        children: [
          {
            path: "message-cost",
            name: "debt-solution-ci-message-cost",
            redirect: { name: "debt-solution-ci-cost" },
            component: () => import(/* webpackChunkName "DSSettingsGridTable" */"@/views/debt-solution/views/settings/views/message-cost-config/MessageCost.vue"),
            meta: {
              pageTitle: "Settings",
              breadcrumb: [
                {
                  text: "Messages / cost",
                  active: true
                }
              ],
              DebtSolutionCiMessage: "debt-solution-ci-message",
              permittedRoles: [1, 2, 17]
            },
            children: [
              {
                path: "cost",
                name: "debt-solution-ci-cost",
                component: () => import(/* webpackChunkName "DSSettingsGridTableMessage" */"@/views/debt-solution/views/settings/views/message-cost-config/components/Charges.vue"),
                meta: {
                  pageTitle: "Settings",
                  breadcrumb: [
                    {
                      text: "Cost / Task",
                      active: true
                    }
                  ],
                  permittedRoles: [1, 2, 17]
                },
              },
              {
                path: "message",
                name: "debt-solution-ci-message",
                component: () => import(/* webpackChunkName "DSSettingsGridTableMessage" */"@/views/debt-solution/views/settings/views/message-cost-config/components/MessageConfigTab.vue"),
                meta: {
                  pageTitle: "Settings",
                  breadcrumb: [
                    {
                      text: "Email",
                      active: true
                    }
                  ],
                  type: 1,
                  containsAtRange: 2,
                  permittedRoles: [1, 2, 17]
                },
              },
              {
                path: "sms",
                name: "debt-solution-ci-sms",
                component: () => import(/* webpackChunkName "DSSettingsGridTableMessage" */"@/views/debt-solution/views/settings/views/message-cost-config/components/MessageConfigTab.vue"),
                meta: {
                  pageTitle: "Settings",
                  breadcrumb: [
                    {
                      text: "SMS",
                      active: true
                    }
                  ],
                  type: 2,
                  containsAtRange: 1,
                  permittedRoles: [1, 2, 17]
                },
              },
              {
                path: "motives",
                name: "debt-solution-ci-motives",
                component: () => import(/* webpackChunkName "DSSettingsGridTableMessage" */"@/views/debt-solution/views/settings/views/message-cost-config/components/Motives.vue"),
                meta: {
                  pageTitle: "Settings",
                  breadcrumb: [
                    {
                      text: "Motives",
                      active: true
                    }
                  ],
                  containsAtRange: 1,
                  permittedRoles: [1, 2, 17]
                },
              },
            ]
          },
          {
            path: "manage-courts",
            name: "debt-solution-manage-courts",
            redirect: { name: "debt-solution-courts" },
            component: () =>
              import(/* webpackChunkName "DSSettingsCourtGrid" */ "@/views/debt-solution/views/settings/views/manageCI/components/MainCourts.vue"),
            meta: {
              pageTitle: "Settings",
              breadcrumb: [
                {
                  text: "Court Management",
                  active: true
                }
              ],
              permittedRoles: [1, 2, 17]
            },
            children: [
              {
                path: "court",
                name: "debt-solution-courts",
                component: () => import(/* webpackChunkName "courts" */"@/views/debt-solution/views/settings/views/manageCI/components/table/CourtManagementTable.vue"),
                meta: {
                  pageTitle: "Settings",
                  breadcrumb: [
                    {
                      text: "Courts management",
                      active: true
                    }
                  ],
                  permittedRoles: [1, 2, 17]
                }
              },
              {
                path: "attorney",
                name: "debt-solution-attorney",
                component: () => import(/* webpackChunkName "attorney" */"@/views/debt-solution/views/settings/views/manageCI/components/table/AttorneyManagementTable"),
                meta: {
                  pageTitle: "Settings",
                  breadcrumb: [
                    {
                      text: "Attorney",
                      active: true
                    }
                  ],
                  permittedRoles: [1, 2, 17]
                }
              }
            ]
          },
          {
            path: "documents-config",
            name: "debt-solution-documents-config",
            redirect: { name: "debt-solution-documents-config-document-service-type" },
            component: () =>
              import(/* webpackChunkName "DSSettingsDocumentsTemplate" */  "@/views/debt-solution/views/settings/views/documents-config/DocumentsConfigTemplate.vue"),
            meta: {
              pageTitle: "Settings",
              breadcrumb: [
                {
                  text: "Documents",
                  active: true
                }
              ],
              permittedRoles: [1, 2, 17]
            },
            children: [
              {
                path: "documents-service-type",
                name: "debt-solution-documents-config-document-service-type",
                component: () => import(/* webpackChunkName "DSSettingsDocumentsServiceTypeGrid" */"@/views/debt-solution/views/settings/views/documents-config/document-service-type/components/table/DocumentServiceTypeTable.vue"),
                meta: {
                  pageTitle: "Settings",
                  breadcrumb: [
                    {
                      text: "Service Type",
                      active: true
                    }
                  ],
                  permittedRoles: [1, 2, 17]
                }

              },
              {
                path: "documents",
                name: "debt-solution-documents-config-documents",
                redirect: { name: "debt-solution-ci-answer" },
                component: () => import(/* webpackChunkName "DSSettingsDocumentsGrid" */"@/views/debt-solution/views/settings/views/documents-config/documents/components/table/DocumentsTable.vue"),
                meta: {
                  tabs: [
                    {
                      name: "ANSWER",
                      route: "debt-solution-ci-answer",
                      tab: 1
                    },
                    {
                      name: "INVESTIGATION",
                      route: "debt-solution-ci-investigation",
                      tab: 2
                    }
                  ],
                  pageTitle: "Settings",
                  breadcrumb: [
                    {
                      text: "Documents",
                      active: true
                    }
                  ],
                  permittedRoles: [1, 2, 17],
                },
                children: [
                  {
                    path: "answer",
                    name: "debt-solution-ci-answer",
                    component: () => import(/* webpackChunkName "answer" */"@/views/debt-solution/views/settings/views/documents-config/documents/components/table/Grid.vue"),
                    meta: {
                      tab: 1,

                      pageTitle: "Document",
                      breadcrumb: [
                        {
                          text: "Answer",
                          active: true
                        }
                      ],
                      permittedRoles: [1, 2, 17]
                    }
                  },
                  {
                    path: "investigation",
                    name: "debt-solution-ci-investigation",
                    component: () => import(/* webpackChunkName "investigation" */"@/views/debt-solution/views/settings/views/documents-config/documents/components/table/Grid.vue"),
                    meta: {
                      tab: 2,
                      pageTitle: "Document",
                      breadcrumb: [
                        {
                          text: "Investigation",
                          active: true
                        }
                      ],
                      permittedRoles: [1, 2, 17]
                    }
                  }
                ]
              },
              {
                path: "document-formats",
                name: "debt-solution-documents-config-document-formats",
                component: () => import(/* webpackChunkName "DSSettingsDocumentFormatsGrid" */"@/views/debt-solution/views/settings/views/documents-config/document-formats/components/table/DocumentFormats.vue"),
                meta: {
                  pageTitle: "Settings",
                  breadcrumb: [
                    {
                      text: "Document Formats",
                      active: true
                    }
                  ],
                  permittedRoles: [1, 2, 17]
                }

              },
            ],
          },
        ]
      },
    ]
  },
];
