export default [
  {
    path: "dispute-results-origin",
    name: "credit-experts-dispute-results-origin",
    component: () => import(/* webpackChunkName: "CreditExpertsUpdateRequest" */ "@/views/credit-experts/views/dispute-results/views/DisputeResultsOrigin.vue"),
    redirect: { name: "ce-dispute-results-in-process-origin" },
    meta: {
      permittedRoles: [1, 2, 11, 3],
      pageTitle: "Update Request",
      /* breadcrumb: [
                        {
                            text: "NCR Realtor",
                            active: true,
                        },
                    ], */
    },
    children: [
      {
        path: "",
        name: "ce-dispute-results-in-process-origin",
        component: () => import(/* webpackChunkName: "CreditExpertsUpdateRequestInProcess" */ "@/views/commons/components/dispute-results/views/components/TableInProcess.vue"
        ),
        meta: {
          status: 0,
          permittedRoles: [1, 2, 11, 3],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "In Process",
              active: true,
            },
          ],
        },
      },
      {
        path: "",
        name: "ce-dispute-results-completed-origin",
        component: () => import(/* webpackChunkName: "CreditExpertsUpdateRequestCompleted" */ "@/views/commons/components/dispute-results/views/components/TableCompleted.vue"),
        meta: {
          status: 1,
          permittedRoles: [1, 2, 11, 3],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
        },
      },
    ],
  },

  {
    path: "dispute-results",
    name: "credit-experts-dispute-results",
    component: () => import(/* webpackChunkName: "CreditExpertsUpdateRequest" */ "@/views/credit-experts/views/dispute-results/views/DisputeResults.vue"),
    redirect: { name: "ce-dispute-results-in-process" },
    meta: {
      permittedRoles: [1, 2, 11, 3],
      pageTitle: "Update Request",
      /* breadcrumb: [
                        {
                            text: "NCR Realtor",
                            active: true,
                        },
                    ], */
    },
    children: [
      {
        path: "",
        name: "ce-dispute-results-in-process",
        component: () => import(/* webpackChunkName: "CreditExpertsUpdateRequestInProcess" */ "@/views/commons/components/dispute-results/views/components/tabs/TableInProcess.vue"
        ),
        meta: {
          status: 0,
          permittedRoles: [1, 2, 11, 3],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "In Process",
              active: true,
            },
          ],
          tab: 'IN PROCESS'
        },
      },
      {
        path: "",
        name: "ce-dispute-results-completed",
        component: () => import(/* webpackChunkName: "CreditExpertsUpdateRequestCompleted" */ "@/views/commons/components/dispute-results/views/components/tabs/TableCompleted.vue"),
        meta: {
          status: 1,
          permittedRoles: [1, 2, 11, 3],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
          tab: 'COMPLETED'
        },
      },
    ],
  },
];
