import { saleMadeTabs } from "@/views/commons/components/sales-made/router/sale-made-crm.router";

export default {
  path: "salesmade",
  name: "sales-made-management",
  redirect: { name: "sales-made-management-lead" },
  component: () => import("@/views/management/views/sales-made/Salesmade.vue"),
  children: saleMadeTabs("management"),
  meta: {
    pageTitle: "Sales Made",
    breadcrumb: [
      {
        text: "Sales Made",
      },
    ],
  },
};
