<template>
  <b-modal
    v-model="showModal"
    size="lg"
    header-bg-variant="transparent"
    hide-footer
    centered
    no-close-no-backdrop
    @hidden="closeModal()"
  >
    <template #modal-header>
      <div />
    </template>
    <b-container fluid class="pb-1" style="min-height: 200px">
      <b-row>
        <b-col
          cols="12"
          lg="6"
          class="d-flex justify-content-center align-items-center"
        >
          <div class="d-block text-center">
            <span style="font-size: 18px"
              >if you delete the time interval<br />
              the <b>{{ meetings.length }} pending meetings </b> will also be
              removed</span
            >
            <br />
            <strong style="font-size: 20px" class="mt-2 d-inline-flex"
              >Do you want to continue ?</strong
            >
            <div class="my-2">
              <b-button variant="primary" @click="deleteMeetings">
                YES
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="closeModal(false)"
              >
                CANCEL
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          class="d-flex justify-content-center align-items-center"
        >
          <b-list-group
            style="max-height: 350px; overflow-y: auto"
            class="w-100"
          >
            <template v-if="!loadData">
              <b-list-group-item
                v-for="(item, index) in meetings"
                :key="index"
                class="rounded bg-transparent w-100"
                style="margin: 5px 0px"
                :class="borderColor[item.meeting_status_id]"
              >
                <small>Created by {{ item.created_by_name }}</small>
                <div class="d-flex justify-content-between align-items-center">
                  <h5
                    v-b-tooltip.hover="'View in the grid'"
                    class="font-weight-bolder text-capitalize cursor-pointer text-primary hover-me-redirect"
                    style="margin-top: 5px; font-size: 14px"
                    @click="redirect(item)"
                  >
                    <feather-icon
                      icon="UserIcon"
                      size="13"
                      style="margin-right: 5px"
                    />
                    {{ item.name_client }}
                    <feather-icon
                      icon="ArrowUpRightIcon"
                      class="redirect-icon"
                      size="13"
                      style="margin-right: 5px"
                    />
                  </h5>
                  <strong v-if="item.number_phone" style="font-size: 12px">
                    <feather-icon
                      icon="PhoneIcon"
                      size="13"
                      style="margin-right: 5px"
                    />
                    {{ item.number_phone }}
                  </strong>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <strong style="font-size: 14px">
                    <feather-icon
                      icon="ClockIcon"
                      size="13"
                      style="margin-right: 5px"
                    />
                    {{ item.meeting_start | myFullTime }} -
                    {{ item.meeting_end | myFullTime }}
                  </strong>
                  <strong v-if="item.account" style="font-size: 12px">
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="13"
                      style="margin-right: 5px"
                    />
                    {{ item.account }}
                  </strong>
                </div>
              </b-list-group-item>
            </template>
            <b-list-group-item
              v-else
              class="rounded bg-transparent w-100 d-flex justify-content-center align-items-center border-0"
              style="margin: 5px 0px; min-height: 100px"
            >
              <div>
                <feather-icon
                  icon="BriefcaseIcon"
                  size="50"
                  class="grow-up"
                /><br />
                <span style="font-size: 16px" class="mt-2 d-inline-flex"
                  >LOADING ...</span
                >
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import CustomerServices from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import { getModuleText } from "@/utils/convertModule.js";
export default {
  props: {
    ticketMeetingsId: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      meetings: [],
      borderColor: {
        1: "border-warning",
        2: "border-info",
      },
      closeModalAvailability: true,
      loadData: false,
      moduleText: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    await this.getMeetingsbyId();
    this.convertModule();
  },
  mounted() {
    this.showModal = true;
  },
  methods: {
    closeModal(closeModalAvailability = false) {
      this.$emit("closeModal", closeModalAvailability);
    },
    async getMeetingsbyId() {
      try {
        this.loadData = true;
        const params = {
          ticketCustomerIds: JSON.stringify(this.ticketMeetingsId.ids),
        };
        const { data } =
          await CustomerServices.getTicketCustomerMeetingByRangeHours(params);
        this.meetings = data;
        this.loadData = false;
      } catch (error) {
        this.loadData = false;
        this.showErrorSwal();
        console.log("error", error);
      }
    },
    async deleteMeetings() {
      try {
        this.addPreloader();
        const params = {
          ticketCustomerIds: this.ticketMeetingsId.ids,
          date: this.ticketMeetingsId.date,
          hour_start: this.ticketMeetingsId.hour_start,
          hour_end: this.ticketMeetingsId.hour_end,
          user_id: this.currentUser.user_id,
        };
        const { status } = await CustomerServices.deletedTicketCustomerMeetings(
          params
        );
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal();
          this.$emit("deleteMeetings");
        }
      } catch (error) {
        this.showErrorSwal();
        console.log("error", error);
      }
    },
    redirect(item) {
      const routeParams = {
        code: item.code_ticket,
        searchByCode: true,
        module: item.module_id,
      };

      let routeName = "";
      const type = item.type == "payments" ? "payment" : item.type;
      // Establecer el nombre de la ruta según las condiciones
      if (item.id_status === 5 || item.id_status === 6) {
        routeName = `${type}-ticket-${this.moduleText}-in-progress`;
      } else if (item.id_status === 4) {
        routeName = `${type}-ticket-${this.moduleText}-rejected`;
      } else if (item.id_status === 3) {
        routeName = `${type}-ticket-${this.moduleText}-in-progress`;
      } else if (
        item.id_status === 2 ||
        (item.created_by === 1 && item.id_status === 1)
      ) {
        routeName = `${type}-ticket-${this.moduleText}-new`;
      } else if (item.id_status === 7) {
        routeName = `${type}-ticket-${this.moduleText}-not-solved`;
      }
      this.$store.dispatch("TicketCustomerStore/A_SET_CODE", item.code_ticket);
      // Realizar la navegación utilizando 'replace' en lugar de 'push'
      if (routeName) {
        this.$nextTick(() => {
          this.$router
            .replace({
              name: routeName,
              params: routeParams,
            })
            .catch((error) => {
              // Manejar específicamente la excepción NavigationDuplicated
              if (
                error.name === "NavigationDuplicated" &&
                error.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                if (
                  routeName ===
                  `${item.type}-ticket-${this.moduleText}-in-progress`
                ) {
                  this.$root.$emit("redirect-to-module", routeParams);
                }
                // Puedes omitir la excepción ya que se trata de una navegación redundante
              } else {
                // Lanzar otras excepciones
                throw error;
              }
            });
        });
      }

      // Cerrar el modal
      this.closeModal(true);
    },
    convertModule() {
      this.moduleText = getModuleText(this.moduleId);
    },
  },
};
</script>
<style scoped>
.hover-me-redirect {
  transition: 0.3s ease-in-out;
}
.hover-me-redirect:hover .redirect-icon {
  transform: scale(1.5);
}
.grow-up {
  animation: grow-up 1s ease-in-out infinite alternate;
}
@keyframes grow-up {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
</style>
