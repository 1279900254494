import store from "@/store";
import router from "@/router/index";
import Vue from "vue";
import moment from "moment";
import {
  swalErrorIcon,
  swalInfoIcon,
  swalSuccessIcon,
  swalWarningIcon,
} from "@/icons/statusIcons";
const DisapprovedNotification = () => {
  window.socket.bind("sales-made-notifications", async (response) => {
    const {
      target_users_id_or,
      target_users_id_de,
      lead_name,
      observation,
      seller_id,
      state,
      crm_chiefs,
      origin_module_id,
      type_client,
    } = response.notificationInfo;
    const user_id = store.state.auth.currentUser.user_id;
    let view_alert = false;
    let title = "";
    const stateActions = {
      2: {
        condition: () => target_users_id_or.includes(user_id),
        title: "SENT TO REVISION",
      },
      3: {
        condition: () => seller_id == user_id,
        title: "DISAPPROVED SALE",
      },
      4: {
        condition: () =>
          target_users_id_or.includes(user_id) || seller_id == user_id,
        title: "APPROVED SALE",
      },
      12: {
        condition: () => target_users_id_de.includes(user_id),
        title: "SENT TO REVISION",
      },
      13: {
        condition: () => crm_chiefs.includes(user_id),
        title: "SENT TO REVISION",
      },
      14: {
        condition: () =>
          (type_client == "LEAD" && crm_chiefs.includes(user_id)) ||
          (type_client == "CLIENT" && target_users_id_or.includes(user_id)),
        title: "DISAPPROVED SALE",
      },
      15: {
        condition: () => seller_id == user_id,
        title: "DISAPPROVED SALE",
      },
      16: {
        condition: () => target_users_id_or.includes(user_id),
        title: "DISAPPROVED SALE",
      },
    };
    const action = stateActions[state];
    if (stateActions[state]) {
      const action = stateActions[state];
      if (action.condition()) {
        view_alert = true;
      }
      title = action.title;
    }
    if (view_alert) {
      store.commit("SalesMadeStore/M_IsActive", true);
      store.commit("SalesMadeStore/M_SalesMadeInfo", response.notificationInfo);
      store.commit("SalesMadeStore/M_Title", title);
    }
  });
};

export { DisapprovedNotification };
