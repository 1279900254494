
export default {
  path: "report",
  name: "cedigital-report",
  component: () => import(/* webpackChunkName: "CEDCustomerServiceDashboardAgent" */ "@/views/ce-digital/sub-modules/customer-service/views/reports/components/ReportMain.vue"),
  meta: {
    childModule: 6,
    programParent: 3,
    program: 22,
    permittedRoles: [1,2,17],
    pageTitle: "Alert Time Report",
    breadcrumb: [
      {
        text: "Alert Time Report",
      },
    ],
  },
}

  