<template>
  <div>
    <b-modal
      v-model="showModal"
      variant="primary"
      size="lg"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
    >
      <template #modal-title>
        <div
          class="text-uppercase text-white font-weight-bolder"
          style="padding: 5px"
        >
          <feather-icon
            icon="ClockIcon"
            size="15"
            class="mr-1"
          />
          Clients of the CEO
        </div>
      </template>
      <b-container
        fluid
        class="pb-2 pt-1"
      >
        <b-table
          show-empty
          :fields="fields"
          :items="myProvider"
          table-class="text-nowrap"
          responsive
          small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <!-- responsible -->
          <template #cell(lead_name)="{item, index}">
            <span>
              {{ item.lead_name }}
            </span>
          </template>
          <!-- created_at -->
          <template #cell(created_at)="{item, index}">
            <span>
              {{ item.created_at | myGlobalDay }}
            </span>
          </template>
          <!-- updated_at -->
          <template #cell(updated_at)="{item, index}">
            <span>
              {{ item.updated_at | myGlobalDay }}
            </span>
          </template>
          <template #cell(ticket)="{item, index}">
            <span>
              {{ item.ticket }}
            </span>
          </template>
        </b-table>
      </b-container>
    </b-modal>

  </div>
</template>
<script>
import CustomerTicketsServices from '@/views/commons/components/customer-tickets/services/customer-tickets.service';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      showModal: false,
      fields: [
        {
          key: 'lead_name',
          label: 'Name',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'created_at',
          label: 'Start Date',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'updated_at',
          label: 'End Date',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'ticket',
          label: 'Ticket',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    };
  },
  mounted() {
    this.showModal = true;
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    async myProvider() {
      try {
        this.isBusy = true;
        const { data } = await CustomerTicketsServices.getHistoryClientOfCeo();
        this.isBusy = false;
        return data;
      } catch (error) {
        this.isBusy = false;
        console.log('error in myProvider:', error);
      }
      return [];
    },
  },
};
</script>
