export default
  {
    title: "File Manager",
    icon: "ArchiveIcon",
    route: "file-mananger-administration",
  }




