import store from "@/store"
import modalMixin from "@/mixins/modal"
import AlertActionService from "@/views/ce-digital/sub-modules/customer-service/views/auto-alerts/services"
const validateSubmodule = async (route) => {
  const subModules = [20, 22, 23, 26, 6]
  const notProtectedModules = [24, 6]
  const currUser = store.state.auth.currentUser
  if (
    subModules.includes(route.matched[0].meta.module) &&
    route.matched[0].meta.module !== 24 &&
    route.matched[0].meta.module !== 6 &&
    currUser
  ) {
    await store.dispatch("UserStore/A_GET_USER_STATUS_SUBMODULE_SESSION", {
      user_id: currUser.user_id,
      module_id: route.matched[0].meta.module,
    })
    // await this.A_GET_USER_STATUS_SUBMODULE_SESSION({
    //   user_id: currUser.user_id,
    //   module_id: route.matched[0].meta.module,
    // })
  }
  if (
    subModules.includes(route.matched[0].meta.module) &&
    route.matched[0].meta.module !== 24 &&
    route.matched[0].meta.module !== 22 &&
    route.matched[0].meta.module !== 6 &&
    (store.getters["UserStore/G_USER_STATUS_SESSION_SUB_MODULE"] === 4 ||
      store.getters["UserStore/G_USER_STATUS_SESSION_SUB_MODULE"] === 5) &&
    currUser
  ) {
    // if (store.getters['UserStore'].G_USER_STATUS_SESSION_SUB_MODULE !== 1 && this.moduleId !== route.matched[0].meta.module) return
    const parameters = {
      created_by: currUser.user_id,
      sub_module_id: route.matched[0].meta.module,
      status_sub_module_id: 1,
    }
    store.commit("UserStore/SET_USER_STATUS_SESSION_SUB_MODULE", [
      { status_session: 1 },
    ])
    await store.dispatch(
      "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
      parameters
    )
  } else if (route.matched[0].meta.module === 22) {
    const sessionId = store.state.auth.currentUser.user_id
    const { data } = await AlertActionService.getAlertAccepted({
      user_id: sessionId,
    })
    if (data?.length > 0) {
      store.commit("AutoAlertStore/OPEN_ALERT_WITH_ID", {
        bool: true,
        id_alert: data[0].id,
        client_data: data[0],
      })
    }
    await store.dispatch("UserStore/A_GET_USER_STATUS_SUBMODULE_SESSION", {
      user_id: currUser.user_id,
      module_id: route.matched[0].meta.module,
    })
  }
}

export { validateSubmodule }
