<template>
  <b-modal
    v-model="control"
    size="lg"
    no-close-on-backdrop
    scrollable
    :title="`${isEditMode ? 'Edit' : 'Create'} Ticket`"
    title-class="h3 font-weight-bolder text-white"
    class="border-0 border-white"
    modal-class="custom-modal-amg"
    @hidden="closeModal"
  >
    <b-container>
      <validation-observer ref="customerTicketForm">
        <b-row class="row">
          <b-col cols="6">
            <b-col
              v-if="
                Object.keys(localCustomerTicket).length === 0 &&
                !customerId &&
                !selectedExpense.id &&
                !selectedExpenseTransaction.id
              "
              class="p-0 my-1"
            >
              <small class="text-info">
                <feather-icon icon="InfoIcon" /> You can search by names or
                account number customer</small
              >
            </b-col>
            <b-col v-else class="p-0">
              <template
                v-if="!selectedExpense.id && !selectedExpenseTransaction.id"
              >
                <p
                  class="m-0 text-primary"
                  style="margin-bottom: 4px !important"
                >
                  Client
                </p>
                <div v-if="customerTicket.client_name" class="name-client-div">
                  {{ customerTicket.client_name }} ({{
                    customerTicket.account
                  }})
                </div>
                <hr />
              </template>
            </b-col>

            <b-col cols="12" class="p-0">
              <b-form-group
                v-if="
                  customerId == null &&
                  !selectedExpense.id &&
                  !selectedExpenseTransaction.id
                "
              >
                <validation-provider v-slot="{ errors }" rules="required">
                  <p class="m-0">Select a customer</p>
                  <vue-autosuggest
                    id="customer_account"
                    ref="autocomplete"
                    v-model="searchQuery"
                    :suggestions="filteredOptions"
                    :get-suggestion-value="getSuggestionValue"
                    :input-props="{
                      id: 'autosuggest__input',
                      class: [
                        'form-control ',
                        errors.length > 0
                          ? 'is-invalid'
                          : errors.length === 0 && searchQuery
                          ? 'is-validd'
                          : '',
                      ],
                      placeholder: 'Select a customer',
                      style:
                        'color:  #0090e7 !important; border-radius: 10px !important',
                      readonly: numberOfRequest > maximumNumberOfRequest,
                    }"
                    style="color: #0090e7 !important"
                    @input="onInputChange"
                    @selected="selectHandler"
                  >
                    <template slot="before-input">
                      <div v-show="creditorsLoading" class="lds-dual-ring" />
                    </template>
                    <template slot-scope="{ suggestion }">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          height: 2rem;
                        "
                        class="pointer"
                      >
                        <span
                          style="color: #0090e7 !important"
                          class="my-suggestion-item"
                          >{{ suggestion.item.full_name }}
                        </span>
                        <div
                          v-if="suggestion.item.status"
                          class="ml-1"
                          style="height: 10px; width: 10px; border-radius: 50%"
                          :style="
                            'background: ' +
                            filterColor(suggestion.item.status) +
                            ';'
                          "
                        />

                        <b-badge
                          v-if="suggestion.item.status_name"
                          pill
                          :variant="`light-${resolveUserStatusVariant(
                            suggestion.item.status_name
                          )}`"
                          class="text-capitalize"
                        >
                          {{ " " + suggestion.item.status_name }}
                        </b-badge>
                      </div>
                    </template>
                  </vue-autosuggest>
                  <small
                    v-if="numberOfRequest > maximumNumberOfRequest"
                    class="text-warning"
                    >Wait 5 seconds to search again</small
                  >
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12 mt-2" class="p-0">
              <b-form-group>
                <validation-provider v-slot="{ errors }" rules="required">
                  <p class="m-0">Motive</p>
                  <b-select
                    id="ticket_motive"
                    v-model="title_ticket"
                    :options="motives"
                    value-field="id"
                    text-field="name"
                    style="border-radius: 10px !important"
                    :class="
                      errors.length > 0
                        ? 'border-danger rounded'
                        : errors.length === 0 && title_ticket
                        ? 'border-sucjcess rounded'
                        : ''
                    "
                    :disabled="setMotive ? true : false"
                  />
                  <small v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
              <button
                v-if="currentUser.user_id == 1 && !setMotive"
                :style="isDarkSkin ? '' : 'color:white'"
                style="
                  background-color: rgb(0, 144, 231);
                  border: none;
                  padding: 0.5rem 2rem;
                  border-radius: 4px;
                "
                @click="openModalAddMotive"
              >
                Add Motive
              </button>
            </b-col>
            <b-col cols="6" class="p-0" style="margin: 2rem 0">
              <b-form-group label="Priority">
                <validation-provider v-slot="{ errors }" rules="required">
                  <b-form-radio-group
                    v-model="priority"
                    style="display: flex; gap: 1rem"
                    :options="priorities"
                    value-field="value"
                    text-field="text"
                    disabled-field="notEnabled"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-col>
          <b-col cols="6" style="height: fit-content">
            <div>
              <b-form-group>
                <b-form-file
                  ref="filepicker"
                  v-model="files"
                  multiple
                  style="display: none"
                />
                <b-list-group
                  v-if="files.length > 0"
                  style="display: flex; gap: 1rem"
                >
                  <div
                    class="cursor-pointer"
                    style="
                      border: 2px dashed gray;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      height: 220px;
                      margin-top: 1rem;
                      border-radius: 10px;
                    "
                    @click="handleFileUpload"
                  >
                    <span class="text-primary">
                      <tabler-icon icon="PhotoIcon" size="55" />
                    </span>
                    <p
                      class="m-0"
                      style="padding: 0.5rem"
                      :style="
                        isDarkSkin
                          ? 'font-size: 18px; max-width: 200px; text-align: center; color: white;'
                          : 'font-size: 20px; max-width: 200px; text-align: center; color: black;'
                      "
                    >
                      Drop your files here or
                      <span style="font-weight: bold">browse</span>
                    </p>
                    <small>
                      Supports: JPG, PNG, PDF, DOCX, PPTX , MP3, MP4
                    </small>
                  </div>
                  <div
                    class="d-flex"
                    style="
                      flex-direction: column;
                      gap: 1rem;
                      max-height: 120px;
                      overflow: auto;
                    "
                  >
                    <b-list-group-item
                      v-for="(file_data, index) in files"
                      :key="index"
                      class="list-group-item p-0"
                      :style="isDarkSkin ? '' : 'border: solid 1px gray'"
                      style="
                        background-color: transparent;
                        border-radius: 10px;
                        border: solid 1px white;
                      "
                    >
                      <b-col
                        class="p-0 m-0"
                        style="
                          display: flex;
                          gap: 1rem;
                          align-items: center;
                          justify-content: space-between;
                          padding: 0.5rem !important;
                        "
                      >
                        <div
                          class="cursor-pointer"
                          style="display: flex; align-items: center; gap: 1rem"
                          @click="openModalPreviewFile(file_data)"
                        >
                          <span class="text-primary">
                            <tabler-icon icon="FileIcon" size="25" />
                          </span>
                          <div style="display: flex; flex-direction: column">
                            <small class="m-0">
                              {{ file_data.name }}
                            </small>
                            <small>{{ formatFileSize(file_data.size) }}</small>
                          </div>
                        </div>
                        <div>
                          <span
                            class="text-light bg-danger cursor-pointer"
                            style="
                              padding: 0.3rem;
                              border-radius: 100%;
                              display: flex;
                            "
                            @click="removeFile(index)"
                          >
                            <feather-icon icon="Trash2Icon" size="20" />
                          </span>
                        </div>
                      </b-col>
                    </b-list-group-item>
                  </div>
                </b-list-group>
                <b-list-group v-else>
                  <div
                    class="cursor-pointer"
                    style="
                      border: 2px dashed gray;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      height: 220px;
                      margin-top: 1rem;
                      border-radius: 5px;
                    "
                    @click="handleFileUpload"
                  >
                    <span class="text-primary">
                      <tabler-icon icon="PhotoIcon" size="55" />
                    </span>
                    <p
                      class="m-0"
                      style="padding: 0.5rem"
                      :style="
                        isDarkSkin
                          ? 'font-size: 18px; max-width: 200px; text-align: center; color: white;'
                          : 'font-size: 20px; max-width: 200px; text-align: center; color: black;'
                      "
                    >
                      Drop your files here or
                      <span style="font-weight: bold">browse</span>
                    </p>
                    <small>
                      Supports: JPG, PNG, PDF, DOCX, PPTX , MP3, MP4
                    </small>
                  </div>
                </b-list-group>
              </b-form-group>
              <b-list-group
                v-if="
                  Object.keys(localCustomerTicket).length !== 0 &&
                  uploadedFiles.length !== 0
                "
                style="max-height: 150px; overflow: auto"
              >
                <small>Files previously loaded</small>
                <b-list-group-item
                  v-for="(file_data, index) in uploadedFiles"
                  :key="index"
                  class="p-0"
                  style="
                    padding: 0.5rem !important;
                    margin: 0.5rem 0;
                    border-radius: 10px;
                    background-color: transparent;
                  "
                  :style="
                    isDarkSkin
                      ? 'border: solid 1px white;'
                      : 'border: solid 1px gray;'
                  "
                >
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    "
                  >
                    <div style="display: flex; gap: 1rem; align-items: center">
                      <span class="text-primary">
                        <feather-icon icon="FileIcon" size="25" />
                      </span>
                      <small :href="file_data.file_route" target="_blank">
                        {{ file_data.file_name }}
                      </small>
                    </div>
                    <div>
                      <span
                        class="bg-primary text-light cursor-pointer"
                        style="
                          padding: 0.3rem;
                          border-radius: 100%;
                          display: flex;
                        "
                        @click="downloadFile(file_data.file_route)"
                      >
                        <tabler-icon icon="CloudDownloadIcon" size="20" />
                      </span>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col style="margin: 0 0 2rem 0">
            <b-form-group>
              <validation-provider v-slot="{ errors }" rules="required">
                <p class="m-0">Description</p>
                <b-form-textarea
                  id="problem_description"
                  v-model="problem_description"
                  placeholder="Write yout message"
                  rows="4"
                  :class="
                    errors.length > 0
                      ? 'is-invalid'
                      : errors.length === 0 && problem_description
                      ? 'is-validd'
                      : ''
                  "
                  style="border-radius: 10px"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <!--users-->
        <div
          class="p-1 rounded-lg"
          :style="
            isDarkSkin
              ? 'background-color: #1e1f23 !important;'
              : 'background-color: #FAFAFA;'
          "
        >
          <div>
            <b-badge variant="success" class="mr-1">
              {{ usersSelected.length }}
            </b-badge>
            <span class="lh-0 font-weigth-bolder">Participants</span>
          </div>
          <hr style="margin: 5px 0px 10px 0px" />
          <div
            class="d-flex justify-content-between align-items-center w-100 px-1"
          >
            <b-form-input
              v-model="searchParticipants"
              @input="onSearchParticipants"
              placeholder="Search by name"
              style="
                border-radius: 10px;
                max-width: 45%;
                color: #0090e7 !important;
              "
            />
            <div class="mr-1">
              <b-button
                v-if="currentUser.user_id === 1 && searchParticipants === ''"
                v-b-tooltip.hover.v-info
                v-b-tooltip.hover.top
                title="SELECT ALL PARTICIPANTS"
                variant="outline-primary"
                class="ml-1 shadow-none bg-custom-primary"
                @click="selectAllParticipants"
              >
                Select all
              </b-button>
              <b-button
                v-else
                v-b-tooltip.hover.v-info
                v-b-tooltip.hover.top
                title="SELECT ALL PARTICIPANTS"
                variant="outline-primary"
                class="ml-1 shadow-none bg-custom-primary"
                @click="selectParticipant"
              >
                Select
              </b-button>
              <b-button
                v-if="currentUser.user_id === 1"
                v-b-tooltip.hover.v-info
                v-b-tooltip.hover.top
                title="DISACER CHANGES"
                variant="outline-primary"
                class="ml-1 shadow-none bg-custom-secondary"
                @click="disaceChanges"
              >
                Deselect all
              </b-button>
            </div>
          </div>
          <div
            class="w-100 px-1 mt-1"
            style="max-height: 300px; overflow-y: auto; overflow-x: hidden"
          >
            <b-row>
              <template v-for="(item, index) in showUsers">
                <b-col
                  v-if="!item.hidden"
                  :key="index"
                  cols="12"
                  lg="6"
                  style="margin: 5px 0px; min-height: 80px"
                >
                  <div
                    class="d-flex justify-content-start align-items-center w-100 h-100 rounded p-1 border-dark cursor-pointer"
                    :class="item.selected ? 'bg-secondary text-light' : ''"
                    :style="
                      [1, localCustomerTicket.created_by_id].includes(
                        item.id_user
                      )
                        ? 'pointer-events: none !important'
                        : ''
                    "
                    @click="toggleSelectedEmployee(index)"
                  >
                    <b-img-lazy
                      :src="item.image"
                      :alt="item.user_name"
                      @error.native="item.image = brokenImage"
                      class="rounded-circle mr-1"
                      style="width: 60px; height: 60px; object-fit: cover"
                    />
                    <div class="d-block w-100">
                      <strong>{{ item.user_name }}</strong
                      ><br />
                      <!-- <pre>{{ item }}</pre> -->
                      <span>{{
                        getMainRolName(JSON.parse(item.modules))
                      }}</span>
                    </div>
                    <!--actions-->
                    <strong
                      v-if="
                        [1, localCustomerTicket.created_by_id].includes(
                          item.id_user
                        )
                      "
                      class="text-success"
                      style="font-size: 12px"
                    >
                      {{
                        localCustomerTicket.created_by_id === item.id_user
                          ? "CREATOR"
                          : "CEO"
                      }}
                    </strong>
                    <b-form-checkbox
                      v-else
                      v-model="item.selected"
                      :value="true"
                      :unchecked-value="false"
                      name="selected"
                      :state="item.selected ? true : null"
                      class="pointer-events-none"
                    />
                  </div>
                </b-col>
              </template>
            </b-row>
          </div>
        </div>
      </validation-observer>
    </b-container>
    <template #modal-footer>
      <b-button
        variant="outline-primary"
        @click="createTicket(1)"
        v-if="!setMotive"
      >
        Save Draft
      </b-button>
      <b-button variant="primary" @click="createTicket(2)">
        Send ticket
      </b-button>
    </template>
    <modal-preview-file
      v-if="showModalPreviewFile"
      :file="fileSelected"
      @close="showModalPreviewFile = false"
    />
    <modal-add-motive-customer-ticket
      v-if="openModalAddMotiveCustomer"
      @close="closeModalAddMotive"
      @updateMotives="updateMotives"
    />
  </b-modal>
</template>

<script>
import store from "@/store";
import { mapGetters, mapActions } from "vuex";
import { VueAutosuggest } from "vue-autosuggest";
import ModalPreviewFile from "@/views/commons/components/customer-tickets/components/modal/ModalPreviewFile.vue";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import ModalAddMotiveCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalAddMotiveCustomerTicket.vue";

export default {
  filters: {},

  data() {
    return {
      brokenImage: "https://cdn-icons-png.flaticon.com/256/149/149071.png",
      numberOfRequest: 0,
      control: false,
      openModalAddMotiveCustomer: false,
      showModalPreviewFile: false,
      fileSelected: null,
      resetTimeout: null,
      customer_account: "",
      title_ticket: "",
      problem_description: "",
      priority: null,
      files: [],
      customerList: [],
      priorities: [
        { text: "HIGH", value: "high" },
        { text: "MEDIUM", value: "medium" },
        { text: "LOW", value: "low" },
      ],

      localCustomerTicket: null,
      searchQuery: null,
      selectIsOpen: false,

      uploadedFiles: [],

      filteredOptions: [],
      creditorsLoading: false,

      motives: [],
      headerBgVariant: "dark",
      headerTextVariant: "light",
      customer_lead: null,
      validateLead: false,
      participants: [],
      optionsRoles: [1, 2, 17], // CEO, SUPERVISOR AND CHIEF
      users: [],
      allParticipants: false,
      maximumNumberOfRequest: 15,
      searchParticipants: "",
    };
  },

  components: {
    ModalPreviewFile,
    VueAutosuggest,
    ModalAddMotiveCustomerTicket,
  },

  props: {
    selectedExpense: {
      type: Object,
      default: () => {
        return {
          id: null,
        };
      },
    },
    selectedExpenseTransaction: {
      type: Object,
      default: () => {
        return {
          id: null,
        };
      },
    },
    file: {
      type: String,
      default: "",
    },

    customerTicket: {
      type: Object,
      default: () => {},
    },

    customerId: {
      type: String,
      default: null,
    },

    setMotive: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleIdValidate() {
      let isModule = [2, 15].includes(this.$route.matched[0].meta.module);
      return isModule || this.validateLead;
    },
    moduleClienAndLeads() {
      return [16, 18].includes(this.$route.matched[0].meta.module);
    },
    validateColor() {
      let module =
        this.currentUser.role_id === 1 ? 0 : this.currentUser.modul_id;
      return [2, 15, 0].includes(module);
    },
    usersSelected() {
      return this.users.filter((item) => item.selected === true);
    },
    showUsers() {
      return this.users.sort((a, b) =>
        a.selected > b.selected ? -1 : b.selected > a.selected ? 1 : 0
      );
    },
    isEditMode() {
      let counter = Object.keys(this.localCustomerTicket).length;
      return (
        counter !== 0 &&
        !this.localCustomerTicket.isTrusted &&
        ![100, 101, 102, 103].includes(this.setMotive)
      );
    },
  },

  watch: {
    files(oldVal, newVal) {
      if (oldVal.length > 0) {
      }
    },

    numberOfRequest(newValue) {
      if (newValue > this.maximumNumberOfRequest) {
        clearTimeout(this.resetTimeout);
        this.resetTimeout = setTimeout(() => {
          this.numberOfRequest = 0;
        }, 5000);
      }
    },
  },

  async mounted() {
    await this.getMotiveCustomerTicket();
    await this.getUsers();
    this.localCustomerTicket = { ...this.customerTicket };
    this.getForEditMode();
    const isNumber = /^\d+$/.test(this.customerId);
    if (this.customerId != null && !this.moduleIdValidate && !isNumber) {
      this.customer_account = this.customerId;
    }
    if (this.customerId != null && (this.moduleIdValidate || isNumber)) {
      this.customer_lead = this.customerId;
      if (this.customerTicket?.id) await this.getParticipantsTicket();
    }
    if (this.setMotive) {
      this.title_ticket = this.setMotive;
      this.problem_description = this.customerTicket.text || "";
    }
    if (this.customerTicket?.id) {
      await this.getParticipantsTicket();
    }
    if (this.selectedExpense.id) {
      this.users.forEach((element) => {
        if (this.selectedExpense.created_by_id === element.id_user) {
          element.selected = true;
        }
      });
    }
  },

  created() {
    this.getEditModeData();
    this.getCustomerByModule();
    this.getFilesSaved();
  },
  methods: {
    resolveUserStatusVariant(status) {
      if (status === "Pending") return "warning";
      if (status === "Active") return "success";
      if (status === "Inactive") return "secondary";
      if (status === "Not Contacted") return "danger";
      return "primary";
    },
    formatFileSize(size) {
      if (size === 0) return "0 Bytes";

      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

      const i = parseInt(Math.floor(Math.log(size) / Math.log(k)), 10);

      return `${parseFloat((size / k ** i).toFixed(2))} ${sizes[i]}`;
    },

    filterColor(value) {
      switch (value) {
        case 1:
          return "#00cc00";
        case 2:
          return "#ffc107";
        case 3:
          return "#0066ff";
        case 4:
          return "red";
        case 5:
          return "red";
        case 6:
          return "red";
        // case 7: return 'white';
        // case 8: return 'pink';
        // case 9: return 'pinkhot';
        // case 10: return 'orange';
        // case 11: return 'brow';
        // case 12: return 'lightblue';
        default:
          return "gray";
      }
    },

    async selectHandler(value) {
      if (value.item.leadstatus_id != null) {
        this.validateLead = true;
      } else {
        this.validateLead = false;
      }
      if (this.moduleIdValidate) {
        this.customer_lead = value.item.id;
      } else {
        this.customer_account = value.item.id;
      }
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.full_name;
    },

    getEditModeData() {
      this.localCustomerTicket = { ...this.customerTicket };
    },

    getForEditMode() {
      this.control = true;
      this.title_ticket = this.localCustomerTicket.motives_customer_ticket_id;
      this.problem_description = this.localCustomerTicket.description;
      this.priority = this.localCustomerTicket.priority;
      this.customer_account = this.localCustomerTicket.client_account;
      this.customer_lead = this.localCustomerTicket.lead_id;
    },

    async getCustomerByModule() {
      if (Object.keys(this.localCustomerTicket).length) {
        const params = {
          module_id:
            this.currentUser.role_id === 1 || this.moduleClienAndLeads
              ? 0
              : this.currentUser.modul_id,
          search: this.searchQuery,
          id: this.localCustomerTicket.client_account,
          lead_id: this.localCustomerTicket.lead_id,
        };
        const response = await CustomerTicketsService.getCustomerByModule(
          params
        );
        if (response.data[0]) {
          this.searchQuery = response.data[0].full_name;
          this.localCustomerTicket.client_account = parseInt(
            response.data[0].id,
            10
          )
            ? null
            : response.data[0].id; // if is lead return null
          this.localCustomerTicket.lead_id = parseInt(response.data[0].id, 10)
            ? parseInt(response.data[0].id, 10)
            : null; // if is lead return id
        }
      }
    },

    closeModal() {
      this.$emit("hidden");
    },

    openModalPreviewFile(file) {
      this.showModalPreviewFile = true;
      this.fileSelected = URL.createObjectURL(file);
    },

    downloadFile(url) {
      window.open(url, "_blank");
    },

    handleFileUpload() {
      this.$refs.filepicker.$el.childNodes[0].click();
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },

    openModalAddMotive() {
      this.openModalAddMotiveCustomer = true;
    },
    closeModalAddMotive() {
      this.openModalAddMotiveCustomer = false;
    },

    async createTicket(status) {
      try {
        const formData = new FormData();

        formData.append("ticket_id", this.localCustomerTicket.id);
        formData.append("title", this.title_ticket);
        formData.append("description", this.problem_description);
        formData.append("priority", this.priority);
        formData.append("created_by", this.currentUser.user_id);
        formData.append("client_account", this.customer_account ?? null);
        if (this.selectedExpense.id)
          formData.append("expense_id", this.selectedExpense.id);
        if (this.selectedExpenseTransaction.id)
          formData.append(
            "expense_transaction_id",
            this.selectedExpenseTransaction.id
          );
        formData.append(
          "lead_id",
          this.customer_account ? null : this.customer_lead
        );
        formData.append(
          "module_id",
          this.currentUser.user_id === 1 ? null : this.currentUser.modul_id
        );
        formData.append("status", status);
        // participants removes
        const removesParticipants = this.participants.filter(
          (item) =>
            !this.usersSelected.some(
              (element) => element.id_user === item.id_user
            )
        );
        formData.append(
          "removesParticipants",
          JSON.stringify(removesParticipants)
        );
        // participants not saved in the database
        const users = this.usersSelected.filter(
          (item) =>
            !this.participants.some(
              (element) => element.id_user === item.id_user
            )
        );
        const newParticipants = users.filter(
          (item) =>
            ![1, this.localCustomerTicket.created_by_id].includes(item.id_user)
        );
        formData.append("newParticipants", JSON.stringify(newParticipants));

        this.files.forEach((file) => {
          formData.append("files[]", file);
        });

        // add formData for payments
        if (this.localCustomerTicket.charge_back_id) {
          formData.append(
            "charge_back_id",
            this.localCustomerTicket.charge_back_id
          );
        }
        if (this.localCustomerTicket.transaction_id) {
          formData.append(
            "transaction_id",
            this.localCustomerTicket.transaction_id
          );
        }

        if (
          !this.selectedExpense.id &&
          !this.selectedExpenseTransaction.id &&
          !this.customer_account &&
          !this.moduleIdValidate &&
          !this.customer_lead &&
          this.moduleIdValidate
        ) {
          this.showInfoSwal(
            "Please fill in the required fields.",
            "Please select a customer to proceed."
          );
          return;
        }

        const validate = await this.$refs.customerTicketForm.validate();

        if (!validate) {
          return;
        }
        if (
          this.customer_account == null &&
          this.customer_lead == null &&
          !this.selectedExpense.id &&
          !this.selectedExpenseTransaction.id
        ) {
          this.showInfoSwal(
            "Please fill in the required fields.",
            "Please select a customer to proceed."
          );
          return;
        }

        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          const response = await CustomerTicketsService.insertCustomerTicket(
            formData
          );
          if (response.status === 200) {
            this.showSuccessSwal();
            this.removePreloader();
            await store.dispatch(
              "NotificationStore/A_COUNT_INDICATORS_TICKETS",
              { user_id: this.currentUser.user_id }
            );
            await store.dispatch(
              "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT",
              {
                user_id: this.currentUser.user_id,
              }
            );
            this.$emit("hidden");
            this.$emit("saved", response?.data);
          }

          if (response.status === 201) {
            this.removePreloader();
            this.showInfoSwal(
              "Please fill in the required fields.",
              response.data.message
            );
          }
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log(error);
      }
    },

    async getFilesSaved() {
      const params = {
        ca_id: this.localCustomerTicket.id,
      };

      try {
        const response = await CustomerTicketsService.getFilesSaved(params);

        if (response.status === 200) {
          this.uploadedFiles = response.data;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getMotiveCustomerTicket() {
      try {
        const { data } = await CustomerTicketsService.GetMotiveCustomerTicket();
        console.log("this.setMotive", data);
        this.motives = data
          .filter((motive) =>
            this.setMotive != null ? true : motive.type == "CUSTOMER"
          )
          .map((motive) => ({
            id: motive.id,
            name:
              motive.name.charAt(0).toUpperCase() +
              motive.name.slice(1).toLowerCase(),
          }));
        console.log("this.setMotive", this.motives);
      } catch (error) {
        console.error(error, "error");
      }
    },
    async updateMotives() {
      await this.getMotiveCustomerTicket();
    },

    async onInputChange(text) {
      this.customer_account = null;
      this.creditorsLoading = true;

      if (this.numberOfRequest > this.maximumNumberOfRequest) return;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        this.creditorsLoading = false;
        return;
      }
      this.numberOfRequest += 1;
      try {
        const params = {
          module_id:
            this.currentUser.role_id === 1 || this.moduleClienAndLeads
              ? 0
              : this.currentUser.modul_id,
          search: text,
          id: this.localCustomerTicket.id,
        };
        const response = await CustomerTicketsService.getCustomerByModule(
          params
        );
        if (response.status === 200) {
          this.filteredOptions = [{ data: [...response.data] }];
          this.creditorsLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getUsers() {
      try {
        this.isBusy = true;
        const params = {
          rolesId: JSON.stringify(this.optionsRoles),
          search: null,
          perpage: 500,
          npage: 1,
          moduleId: null,
        };
        const { data } = await CustomerTicketsService.getUserByRoles(params);
        if (data.data) {
          const users = this.participants.map((item) => item.id_user);
          const creatorId = this.localCustomerTicket.created_by_id;

          // if (this.currentUser.user_id !== 1) {
          //   // only Show CEO and the CREATOR
          //   this.users = data.data
          //     .filter((item) => [1, creatorId].includes(item.id_user))
          //     ?.map((item) => ({ ...item, selected: true }));
          // } else {
          // show all employees
          this.users = data.data.map((item) => ({
            ...item,
            selected:
              users.includes(item.id_user) ||
              [1, creatorId].includes(item.id_user),
          }));
          // }
        }
      } catch (error) {
        this.isBusy = false;
        this.showErrorSwal();
        console.log("error in getData: ", error);
      }
      return [];
    },

    async getParticipantsTicket() {
      try {
        const params = {
          ticketCustomerId: this.localCustomerTicket.id,
        };
        const { data } = await CustomerTicketsService.getParticipantsTickect(
          params
        );
        this.participants = data;
        this.allParticipants = this.participants.length === this.users.length;
        this.users.forEach((element) => {
          element.selected = this.participants.some(
            (item) => item.id_user === element.id_user
          );
        });
      } catch (error) {
        this.showErrorSwal();
        console.log("error in getParticipantsTicket: ", error);
      }
    },

    selectAllParticipants() {
      this.users.forEach((element) => {
        if (
          ![1, this.localCustomerTicket.created_by_id].includes(element.id_user)
        ) {
          element.selected = !this.allParticipants;
        }
      });
    },

    selectParticipant() {
      this.users.forEach((element) => {
        if (
          ![1, this.localCustomerTicket.created_by_id].includes(
            element.id_user
          ) &&
          !element.hidden
        ) {
          element.selected = true;
        }
      });
    },

    onSearchParticipants(text) {
      this.users = this.users.map((item) => {
        if (item.user_name.toLowerCase().includes(text.toLowerCase())) {
          return { ...item, hidden: false };
        }
        return { ...item, hidden: true };
      });
    },
    toggleSelectedEmployee(index) {
      this.users[index].selected = !this.users[index].selected;
      this.allParticipants = this.usersSelected.length === this.users.length;
    },
    disaceChanges() {
      this.users.forEach((element) => {
        element.selected = this.participants.some(
          (item) => item.id_user === element.id_user
        );
      });
    },
    getMainRolName(item) {
      for (let r of item) {
        if (r.main_module === 1) {
          return r.role_name;
        }
      }
      return "";
    },
  },
};
</script>

<style scoped>
/* Add any necessary styles here */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
h3,
span,
p,
small {
  font-family: montserrat;
}
.blink-animation {
  animation: blink 1s infinite; /* 1s de duración y repetición infinita */
}

@keyframes blink {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: red; /* Cambia a tu color deseado */
  }
  100% {
    border-color: transparent;
  }
}
.my-black {
  background-color: black !important;
}
.my-light {
  background-color: white !important;
}
.my-dark {
  background-color: #17161b !important;
}
.bg-my-dark {
  background-color: #17161b !important;
}
.text-my-dark {
  color: #5a5a5a !important;
}

.name-client-div {
  border: 1px solid #0090e7;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.bg-custom-primary {
  background-color: #3f7afa !important;
  color: white !important;
}

.bg-custom-secondary {
  background-color: #e7f1ff !important;
  color: #3f7afa !important;
}
</style>
