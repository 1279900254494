export const modifyVisibility = (fields, nameKey, isVisible) => {
  let keyField = fields.findIndex((item) => item.key == nameKey);
  if (keyField != -1) {
    fields[keyField].visible = isVisible;
  }
}
export const modifyLabel = (fields, nameKey, label) => {
  let keyField = fields.findIndex((item) => item.key == nameKey);
  if (keyField != -1) {
    fields[keyField].label = label;
  }
}
