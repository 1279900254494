//services
import ClientDashboardService from "@/views/paragon/views/clients/dashboard/services/dashboard.service";

const state = {
  months: [],
  years: [],
  personalInputs: {
    accountId: "",
    client: "",
    status: "",
    account: "",
    seller: "",
    services: "",
    address: "",
    phone: "",
    email: "",
    program_name: "",
    monthlyAmount: "",
    dob: "",
    address: {
      city: "",
      state: "",
      zipcode: "",
    },
    memo: {
      msg: "",
      user: "",
      date: "",
    },
  },
  cards: [],
  historyStatus: [],
  pack: {},
  packs: [],
  companyInputs: {
    companyName: "",
    webPage: "",
    ein: "",
    socialNetwork: "",
    bizType1: "",
    tollFree: "",
    bizType2: "",
    file_date: "",
    entity_number: "",
    phone: "",
    tax_status: "",
    email: "",
    facebook: "",
    fbn: "",
    instagram: "",
    mailingAddress: "",
  },
  taskData: [],
  taskPaginate: {
    current_page: 1,
    first_page_url: "/?page=1",
    from: null,
    last_page: 1,
    last_page_url: null,
    next_page_url: null,
    path: "/",
    per_page: 10,
    prev_page_url: null,
    to: null,
    total: 0,
  },
  notesData: [],
  charges: [],
  payments: [],
  scores: [],
  historySms: [],
  historyEmail: [],
  historyLetter: [],
  trackingChangeAddress: [],
  trackingCompanyData: [],
  historyMonthlyPayments: [],
  paymentData: [],
  noteInfo: {},
  S_IN_COURT: 0,
};
const mutations = {
  SET_MONTHS: (state, data) => {
    state.months = data;
  },
  SET_YEARS: (state, data) => {
    state.years = data;
  },
  SET_PERSONAL_INPUTS: (state, data) => {
    state.personalInputs = data;
  },
  SET_CARDS: (state, data) => {
    state.cards = data;
  },
  SET_PACKS: (state, data) => {
    state.packs = data;
  },
  SET_PACK: (state, data) => {
    state.pack = data;
  },
  SET_STATUS_PACK: (state, id) => {
    state.packs.map((el) => {
      el.id === id ? (el.status = "ENABLED") : "";
    });
  },
  SET_COMPANY_INPUTS: (state, data) => {
    state.companyInputs = data;
  },
  SET_MEMO: (state, data) => {
    state.personalInputs.memo = data;
  },
  SET_EIN: (state) => {
    state.companyInputs.ein += "-";
  },
  SET_TASK_DATA: (state, data) => {
    state.taskData = data;
  },
  SET_TASK_PAGINATE: (state, data) => {
    state.taskPaginate = data;
  },
  SET_NOTES_DATA: (state, data) => {
    state.notesData = data;
  },
  SET_CHARGES: (state, data) => {
    state.charges = data;
  },
  SET_PAYMENTS: (state, data) => {
    state.payments = data;
  },
  SET_SCORES: (state, data) => {
    state.scores = data;
  },
  SET_HISTORY_STATUS: (state, data) => {
    state.historyStatus = data;
  },
  SET_HISTORY_SMS: (state, data) => {
    state.historySms = data;
  },
  SET_HISTORY_EMAIL: (state, data) => {
    state.historyEmail = data;
  },
  SET_HISTORY_LETTER: (state, data) => {
    state.historyLetter = data;
  },
  SET_TRACKING_CHANGE_ADDRESS: (state, data) => {
    state.trackingChangeAddress = data;
  },
  SET_TRACKING_COMPANY_DATA(state, data) {
    state.trackingCompanyData = data;
  },
  SET_HISTORY_MONTHLY_PAYMENTS: (state, data) => {
    state.historyMonthlyPayments = data;
  },
  SET_PAYMENT_DATA: (state, data) => {
    state.paymentData = data;
  },
  SET_NOTE_INFO: (state, data) => {
    state.noteInfo = data;
  },
  SET_MAILING_ADDRESS: (state, data) => {
    state.companyInputs.mailingAddress = data;
  },
  M_IN_COURT: (state, data) => {
    state.S_IN_COURT = data;
  },
};
const actions = {
  getClientData: async ({ commit }, params) => {
    const {
      account,
      admin_name,
      advisor_connection_id,
      advisor_connection_name,
      advisor_connection_state,
      advisor_id,
      advisor_name,
      answer_bu,
      balance,
      c_call_status,
      c_dates_calls,
      c_dates_sms,
      c_file_status,
      c_sms_status,
      c_track_finish,
      c_track_id,
      call_status,
      captured_name,
      cards,
      charges,
      city,
      client_id,
      client_name,
      coapp_name,
      count_program,
      creates,
      data_itin,
      data_ssn,
      date_memo,
      dates_calls,
      dates_sms,
      day_payment,
      dob,
      email,
      event_id,
      file_status,
      form_company,
      goals,
      id,
      id_admin,
      id_spec,
      in_connection,
      language,
      lead_id,
      memo,
      min,
      mobile,
      module,
      monthly_amount,
      pack,
      packs,
      paid,
      program_id,
      program_name,
      rbp,
      s_welcome,
      sale_id,
      sales_date,
      selement_cost,
      seller_name,
      sms_status,
      sourcesname,
      spec_name,
      ssn,
      st_balance,
      start_date,
      state_admin,
      state_advisor,
      state_captured,
      state_email,
      state_name,
      state_seller,
      state_sms,
      state_sms_m,
      state_spec,
      states_eeuu_slug,
      status,
      status_call_round,
      status_connection,
      status_l,
      street,
      supervisor_id,
      supervisor_name,
      total_charge,
      total_payments,
      total_wp,
      track_finish,
      track_id,
      tracking_end,
      tracking_start,
      type_payment,
      user_memo,
      validate_sp,
      zipcode,
    } = await ClientDashboardService.getClientData(params);
    commit("SET_PERSONAL_INPUTS", {
      client: client_name,
      status,
      itin: data_itin,
      ssn: data_ssn,
      seller: seller_name,
      services: answer_bu,
      phone: "",
      programCounter: count_program,
      monthlyAmount: monthly_amount,
      account,
      accountId: id,
      email,
      dob,
      lead_id,
      program: program_name,
      typePayment: type_payment,
      company: form_company,
      phone: mobile,
      paymentDay: day_payment,
      program_name: program_name,
      address: {
        city,
        street,
        states_eeuu_slug,
        state: state_name,
        zipCode: zipcode,
      },
      advisor: { id: advisor_id, name: advisor_name },
      memo: { msg: memo, user: user_memo, date: date_memo },
      supervisor: { id: supervisor_id, name: supervisor_name },
      ssn: data_ssn,
      itin: data_itin,
    });
    commit("SET_PACKS", packs);
    commit("SET_CARDS", cards);
    commit("SET_NOTE_INFO", {
      programSelected: program_id,
      roleId: null,
      notesProgram: null,
      nameProgram: program_name,
      nameClient: client_name,
      salesMades: null,
      module,
      type: null,
      editModal: false,
      statusSale: null,
      sourcesName: sourcesname,
      pack,
      created: null,
      originCountry: null,
      idLead: lead_id,
      notSeller: null,
      capturedName: captured_name,
      sellerName: seller_name,
      trackings: null,
      notes_status: null,
      notes_status_new: null,
      saleId: sale_id,
    });
  },
  getCompanyData: async ({ commit }, params) => {
    const company = await ClientDashboardService.getCompanyData(params);

    if (company) {
      const {
        biz_address,
        biz_type,
        bor,
        ca,
        client_account_id,
        created_at,
        ein,
        ein_company_secret,
        id,
        ir,
        name,
        social_network,
        toll_free,
        updated_at,
        web_page,
        instagram,
        mailing_address,
        phone,
        email,
        entity_number,
        fbn,
        tax_status,
        facebook,
        file_date,
      } = company;

      commit("SET_COMPANY_INPUTS", {
        companyName: name,
        webPage: web_page,
        ein,
        einSecret: ein_company_secret,
        socialNetwork: social_network,
        bizType1: biz_type || null,
        tollFree: toll_free,
        bizType2: biz_address || null,
        file_date: "",
        entity_number: "",
        phone: "",
        tax_status: "",
        email: "",
        facebook: "",
        fbn: "",
        instagram: instagram,
        mailingAddress: mailing_address,
        phone: phone,
        email: email,
        entityNumber: entity_number,
        fbn: fbn,
        taxStatus: tax_status,
        facebook: facebook,
        fileDate: file_date,
      });
    }
  },
  getTaskData: async ({ commit }, params) => {
    const { data, ...values } = await ClientDashboardService.getTaskData(
      params
    );
    commit("SET_TASK_DATA", data);
    commit("SET_TASK_PAGINATE", values);
  },
  getNotesData: async ({ commit }, params) => {
    const data = await ClientDashboardService.getNotesData(params);
    const notes = [];

    for (const month in data) {
      notes.push(data[month]);
    }

    commit("SET_NOTES_DATA", notes);
  },
  setMonths: ({ commit }) => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      const day = new Date(`${i}/01/2022`);
      months.push(day.toLocaleString("en-US", { month: "long" }));
    }
    commit("SET_MONTHS", months);
  },
  setYears: ({ commit }) => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i <= 4; i++) {
      years.push({
        value: currentYear - i,
        text: currentYear - i,
      });
    }
    commit("SET_YEARS", years);
  },
  getCharges: async ({ commit }, params) => {
    const data = await ClientDashboardService.getCharges(params);
    commit("SET_CHARGES", data);
  },
  getPayments: async ({ commit }, params) => {
    const data = await ClientDashboardService.getPayments(params);
    commit("SET_PAYMENTS", data);
  },
  getScores: async ({ commit }, params) => {
    const data = await ClientDashboardService.getScores(params);
    commit("SET_SCORES", data);
  },
  getHistoryStatus: async ({ commit }, params) => {
    const data = await ClientDashboardService.getHistoryStatus(params);
    commit("SET_HISTORY_STATUS", data);
  },
  getHistorySms: async ({ commit }, params) => {
    const data = await ClientDashboardService.getHistorySms(params);
    commit("SET_HISTORY_SMS", data);
  },
  getHistoryEmail: async ({ commit }, params) => {
    const data = await ClientDashboardService.getHistoryEmail(params);
    commit("SET_HISTORY_EMAIL", data);
  },
  getHistoryLetter: async ({ commit }, params) => {
    const data = await ClientDashboardService.getHistoryLetter(params);
    commit("SET_HISTORY_LETTER", data);
  },
  getTrackingChangeAddress: async ({ commit }, params) => {
    const data = await ClientDashboardService.getTrackingChangeAddress(params);
    commit("SET_TRACKING_CHANGE_ADDRESS", data);
  },
  getTrackingCompanyData: async ({ commit }, params) => {
    const data = await ClientDashboardService.getTrackingCompanyData(params);
    commit("SET_TRACKING_COMPANY_DATA", data);
  },
  getHistoryMonthlyPayments: async ({ commit }, params) => {
    const data = await ClientDashboardService.getHistoryMonthlyPayments(params);
    commit("SET_HISTORY_MONTHLY_PAYMENTS", data);
  },
  getPaymentData: async ({ commit }, params) => {
    const data = await ClientDashboardService.getPaymentsData(params);
    commit("SET_PAYMENT_DATA", data);
  },
  A_GET_IN_COURT: async ({ commit }, params) => {
    const { data } = await ClientDashboardService.getInCourtByClient(
      params
    );
    commit("M_IN_COURT", data.counter);
  },
};
const getters = {
  getCompanyInputs: ({ companyInputs }) => companyInputs,
  getPersonalInputs: ({ personalInputs }) => personalInputs,
  getPacks: ({ packs }) => packs,
  getPack: ({ pack }) => pack,
  getCards: ({ cards }) => JSON.parse(cards),
  getMemo: ({ personalInputs }) => personalInputs.memo,
  getLeadId: ({ personalInputs }) => personalInputs.lead_id,
  getHistoryStatus: ({ historyStatus }) => historyStatus,
  getProgram: ({ personalInputs }) => personalInputs.program,
  getClient: ({ personalInputs }) => personalInputs.client,
  getAccountId: ({ personalInputs }) => personalInputs.accountId,
  getAccount: ({ personalInputs }) => personalInputs.account,
  getTaskData: ({ taskData }) => taskData,
  getTaskPaginate: ({ taskPaginate }) => taskPaginate,
  getNotesData: ({ notesData }) => notesData,
  getCharges: ({ charges }) => charges,
  getPayments: ({ payments }) => payments,
  getScores: ({ scores }) => scores,
  getLeadId: ({ personalInputs }) => personalInputs.lead_id,
  getCounterPrograms: ({ personalInputs }) => personalInputs.programCounter,
  getHistorySms: ({ historySms }) => historySms,
  getHistoryEmail: ({ historyEmail }) => historyEmail,
  getHistoryLetter: ({ historyLetter }) => historyLetter,
  getTrackingChangeAddress: ({ trackingChangeAddress }) =>
    trackingChangeAddress,
  getTrackingCompanyData: ({ trackingCompanyData }) =>
    trackingCompanyData[0] ? trackingCompanyData : "empty",
  getTypePayment: ({ personalInputs }) => personalInputs.typePayment,
  getHistoryMonthlyPayments: ({ historyMonthlyPayments }) =>
    historyMonthlyPayments,
  getPaymentData: ({ paymentData }) => paymentData[0],
  getNoteInfo: ({ noteInfo }) => noteInfo,

  getMonths: ({ months }) => months,
  getYears: ({ years }) => years,
  getEin: ({ companyInputs }) => companyInputs.ein,
  G_IN_COURT(state) {
    return state.S_IN_COURT;
  },
};

export default { namespaced: true, state, mutations, actions, getters };
