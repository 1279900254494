import crmService from "@/views/crm/services/crm.service";

export default {
  namespaced: true,
  state: {
    S_UPDATE_TABLE_CREDITORS: false,
    S_STATUS_EEUU: [],
    S_COUNTRIES: []
  },
  getters: {
    G_UPDATE_TABLE_CREDITORS(state) {
      return state.S_UPDATE_TABLE_CREDITORS
    },
    G_COUNTRIES(state) {
      return state.S_COUNTRIES
    },
    G_STATUS_EEUU(state) {
      return state.S_STATUS_EEUU
    },
  },
  mutations: {
    M_UPDATE_TABLE_CREDITORS(state, params) {
      state.S_UPDATE_TABLE_CREDITORS = params
    },
    M_STATUS_EEUU(state, params) {
      state.S_STATUS_EEUU = params
    },
    M_COUNTRIES(state, params) {
      state.S_COUNTRIES = params
    },
  },
  actions: {
    A_UPDATE_TABLE_CREDITORS({ commit }, data) {
      commit("M_UPDATE_TABLE_CREDITORS", data)
    },
    async A_GET_STATUS_EEUU({ commit }, params) {
      try {
        const response = await crmService.getStatesEeuu(params);
        commit('M_STATUS_EEUU',response)
      } catch (error) {
        throw error
      }
    },
    async A_GET_COUNTRIES({ commit }, params) {
      try {
        const response = await crmService.getCountries(params);
        commit('M_COUNTRIES',response)
      } catch (error) {
        throw error
      }
    }
  }
};
