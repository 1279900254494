import Settlement from "@/views/debt-solution/views/settlements/services/settlement.service.js";
import AccountsActionsService from "@/views/debt-solution/views/accounts-actions/service/actions.service";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    S_COUNTER_SETTLEMENTS: {
      to_be_approved: 0,
      to_pay: 0,
      unfinished: 0
    },
    S_COUNTER_SETTLEMENT_TOTAL: 0,
    S_COUNTER_OFFERS_PENDING: 0,
    S_COUNTER_PRIORITY_ORDER_ALERT: {},
    S_UPDATE_TO_BE_APPROVED_GRID: false,
    S_UPDATE_CREATE_SETTLEMENT_GRID_MODAL: false,

    S_CONFIRMED_PAYMENTS_TOTAL: 0,
    S_PENDING_TO_BE_PAID_TOTAL: 0,
    S_PAYMENTS_TO_BE_CONFIRMED_TOTAL: 0,
    S_REJECTED_PAYMENTS_TOTAL: 0,
    S_COUNTER_UNFINISHED: 0,
    S_COUNTER_TRANSFER_PENDING: 0,
  },
  mutations: {
    M_COUNTER_SETTLEMENTS(state, payload) {
      state.S_COUNTER_SETTLEMENTS = payload;
    },
    M_COUNTER_OFFERS_PENDING(state, payload) {
      state.S_COUNTER_OFFERS_PENDING = payload;
    },
    SET_COUNTER_PRIORITY_ORDER_ALERT(state, payload) {
      state.S_COUNTER_PRIORITY_ORDER_ALERT = payload;
    },
    M_UPDATE_TO_BE_APPROVED_GRID(state, payload) {
      state.S_UPDATE_TO_BE_APPROVED_GRID = payload;
    },
    M_UPDATE_CREATE_SETTLEMENT_GRID_MODAL(state, payload) {
      state.S_UPDATE_CREATE_SETTLEMENT_GRID_MODAL = payload;
    },
    M_CONFIRMED_PAYMENTS_TOTAL(state, payload) {
      state.S_CONFIRMED_PAYMENTS_TOTAL = payload;
    },
    M_PENDING_TO_BE_PAID_TOTAL(state, payload) {
      state.S_PENDING_TO_BE_PAID_TOTAL = payload;
    },
    M_PAYMENTS_TO_BE_CONFIRMED_TOTAL(state, payload) {
      state.S_PAYMENTS_TO_BE_CONFIRMED_TOTAL = payload;
    },
    M_REJECTED_PAYMENTS_TOTAL(state, payload) {
      state.S_REJECTED_PAYMENTS_TOTAL = payload;
    },
    M_COUNTER_UNFINISHED(state, payload) {
      state.S_COUNTER_UNFINISHED = payload;
    },
    M_COUNTER_TRANSFER_PENDING(state, payload) {
      state.S_COUNTER_TRANSFER_PENDING = payload;
    },
  },
  getters: {
    G_COUNTER_SETTLEMENTS(state) {
      return Object.values(state.S_COUNTER_SETTLEMENTS).reduce(
        (a, b) => a + b,
        0
      );
    },
    G_COUNTER_SETTLEMENTS_TO_BE_APPROVED(state) {
      return state.S_COUNTER_SETTLEMENTS.to_be_approved;
    },
    S_COUNTER_PRIORITY_ORDER_ALERT: (state) =>
      state.S_COUNTER_PRIORITY_ORDER_ALERT,
    G_UPDATE_TO_BE_APPROVED_GRID(state) {
      return state.S_UPDATE_TO_BE_APPROVED_GRID;
    },
    G_UPDATE_CREATE_SETTLEMENT_GRID_MODAL(state) {
      return state.S_UPDATE_CREATE_SETTLEMENT_GRID_MODAL;
    },
    G_CONFIRMED_PAYMENTS_TOTAL: (state) => state.S_CONFIRMED_PAYMENTS_TOTAL,
    G_PENDING_TO_BE_PAID_TOTAL: (state) => state.S_PENDING_TO_BE_PAID_TOTAL,
    G_PAYMENTS_TO_BE_CONFIRMED_TOTAL: (state) =>
      state.S_PAYMENTS_TO_BE_CONFIRMED_TOTAL,
    G_REJECTED_PAYMENTS_TOTAL: (state) => state.S_REJECTED_PAYMENTS_TOTAL,
    G_COUNTER_UNFINISHED: (state) => state.S_COUNTER_UNFINISHED,
    G_COUNTER_TRANSFER_PENDING: (state) => state.S_COUNTER_TRANSFER_PENDING,
  },

  actions: {
    async A_COUNTER_SETTLEMENTS({ commit, getters }, payload) {
      try {
        const response = await Settlement.countSettlements(payload);
        commit("M_COUNTER_SETTLEMENTS", response[0]);
        const role = store.state.auth.currentUser.role_id;

        if (role === 1) {
          store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", {
            tag: getters.G_COUNTER_SETTLEMENTS_TO_BE_APPROVED,
            routeName: "debtsolution-settlements",
          });
        } else if (role === 2 || role === 3) {
          store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", {
            tag: getters.G_COUNTER_SETTLEMENTS,
            routeName: "debtsolution-settlements",
          });
        }
        return response[0];
      } catch (error) {
        throw error;
      }
    },
    async A_COUNTER_OFFERS_PENDING({ commit }, payload) {
      try {
        const response = await Settlement.countOffersPending(payload);
        commit("M_COUNTER_OFFERS_PENDING", response.pending);
        return response.pending;
      } catch (error) {
        throw error;
      }
    },
    async GET_COUNTER_PRIORITY_ORDER_ALERT({ commit }, body) {
      try {
        let { data } = await Settlement.getCountByPriorityOrder();
        let objCounter = {
          high: data[0].high ? data[0].high : 0,
          medium: data[0].medium ? data[0].medium : 0,
        };
        commit("SET_COUNTER_PRIORITY_ORDER_ALERT", objCounter);
        return objCounter;
      } catch (err) {
        console.log("ERROR_GET_COUNTER [ACTION]", err);
        throw err;
      }
    },
    async A_GET_PAYMENT_STATUS_TOTAL({ commit }) {
      try {
        const data = await Settlement.getPaymentStatusTotal();
        commit("M_CONFIRMED_PAYMENTS_TOTAL", data.data[0].confirmed_payments);
        commit("M_PENDING_TO_BE_PAID_TOTAL", data.data[0].pending_to_be_paid);
        commit(
          "M_PAYMENTS_TO_BE_CONFIRMED_TOTAL",
          data.data[0].payments_to_be_confirmed
        );
        commit("M_REJECTED_PAYMENTS_TOTAL", data.data[0].rejected_payments);
      } catch (error) {
        throw error;
      }
    },
    async A_COUNTER_UNFINISHED({ commit }, body) {
      try {
        let { data, status } = await Settlement.getCountByPriorityOrder();
        if (status == 200) {
          commit("M_COUNTER_UNFINISHED", data[0].count_unfinished);
        }
      } catch (err) {
        console.log("ERROR_A_COUNTER_UNFINISHED [ACTION]", err);
        throw err;
      }
    },
    async A_COUNTER_TRANSFER_PENDING({ commit }, body) {
      try {
        const params={
          page: 1,
          per_page: 10,
          name_text:""
        }
        let { data } = await AccountsActionsService.getTransfersPending(params);
         commit("M_COUNTER_TRANSFER_PENDING", data.total);
        
      } catch (err) {
        console.log("ERROR_A_COUNTER_TRANSFER_PENDING [ACTION]", err);
        throw err;
      }
    },
  },
};
