import store from '@/store';

const userStatusEvent = () => {
  window.socket.bind('user-status-event', async data => {
    const participants = store.getters['TicketCustomerStore/G_TICKET_PARTICIPANTS_STATUS'];
    if (participants !== null && participants !== undefined) {
      for (let i = 0; i < participants.length; i += 1) {
        const participant = participants[i];
        if (participant.id_user === data.user_id) {
          participant.status_session_id = data.status_id;
          participant.status_session_name = data.status_name;
        }
      }
      store.dispatch('TicketCustomerStore/A_SET_TICKET_PARTICIPANTS_STATUS', participants);
    }
  });
};

export default userStatusEvent;
