export default [
  {
    path: "migrations",
    name: "credit-experts-migrations",
    redirect: { name: "credit-experts-migrations-list-pending" },
    component: () => import(/* webpackChunkName: "CreditExpertsMigrations" */ "@/views/credit-experts/views/migrations/Migrations.vue"),
    meta: {
      pageTitle: "Migrations",
      breadcrumb: [
        {
          text: "Migrations",
        },
      ],
      permittedRoles: [1, 2],
    },
    children: [
      {
        path: "",
        name: "credit-experts-migrations-list-pending",
        component: () => import(/* webpackChunkName: "CreditExpertsMigrationsListPending" */ "@/views/ce-digital/sub-modules/settings/views/migrations/components/MigrationsGrid.vue"),
        meta: {
          tabType: 1,
          isClientsTab: true,
          pageTitle: "Migrations",
          breadcrumb: [
            {
              text: "Pending",
              active: true,
            },
          ],
          permittedRoles: [1, 2],
        },
      },
      {
        path: "accepted",
        name: "credit-experts-migrations-list-accepted",
        component: () => import(/* webpackChunkName: "CreditExpertsMigrationsAccepted" */ "@/views/ce-digital/sub-modules/settings/views/migrations/components/MigrationsGrid.vue"),
        meta: {
          tabType: 2,
          isClientsTab: true,
          pageTitle: "Migrations",
          breadcrumb: [
            {
              text: "Accepted",
              active: true,
            },
          ],
          permittedRoles: [1, 2],
        },
      },
      {
        path: "rejected",
        name: "credit-experts-migrations-list-rejected",
        component: () => import(/* webpackChunkName: "CreditExpertsMigrationsListRejected" */ "@/views/ce-digital/sub-modules/settings/views/migrations/components/MigrationsGrid.vue"),
        meta: {
          tabType: 3,
          isClientsTab: true,
          pageTitle: "Migrations",
          breadcrumb: [
            {
              text: "Rejected",
              active: true,
            },
          ],
          permittedRoles: [1, 2],
        },
      }
    ]
  },
];
