import PermissionActionService from "@/views/commons/components/permissions/services/permission.service.js";

export default {
  namespaced: true,
  state: {
    COUNTER_REQUEST_PENDING: 0
  },
  getters: {
    G_COUNTER_REQUEST_PENDING(state) {
      return state.COUNTER_REQUEST_PENDING
    },
  },
  mutations: {
    M_COUNTER_REQUEST_PENDING(state, payload) {
      state.COUNTER_REQUEST_PENDING = payload
    },
  },
  actions: {
    async A_COUNTER_REQUEST_PENDING({ commit }, params) {
      const { data } = await PermissionActionService.getCounter({
        ...params,
      })
      commit("M_COUNTER_REQUEST_PENDING", data)
    },
  },
}
