var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form"},[_c('b-modal',{attrs:{"title":_vm.revission.reject ? 'Disapprove sale' : _vm.title,"title-class":"h3 text-white font-weight-bolder","size":"lg","no-close-on-backdrop":"","modal-class":"modal-primary","header-bg-variant":_vm.revission.reject || [3,14].includes(_vm.revission.type) ? 'danger' : 'primary'},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"disabled":_vm.disabledButton,"variant":_vm.revission.reject || [3,14].includes(_vm.revission.type)
            ? 'outline-danger'
            : 'outline-success'},on:{"click":_vm.revision}},[_vm._v(_vm._s(_vm.revission.reject || [3,14].includes(_vm.revission.type) ? "DISAPPROVE" : _vm.buttonText))])]},proxy:true}]),model:{value:(_vm.modal.revission),callback:function ($$v) {_vm.$set(_vm.modal, "revission", $$v)},expression:"modal.revission"}},[_c('b-container',{attrs:{"fluid":""}},[_c('program-client-header',{staticClass:"sticky-top mt-1",attrs:{"client":_vm.revission.nameClient,"program":_vm.revission.nameProgram}}),(_vm.revission.type == 4 && !_vm.revission.reject)?_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":"selectAdvisor","rules":_vm.revission.client_type_id == 1 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Advisor"}},[_c('v-select',{class:{
                  'border-danger': errors[0],
                },attrs:{"label":"user_name","disabled":_vm.revission.client_type_id === 2,"options":_vm.advisor,"reduce":function (val) { return val.id; },"transition":""},model:{value:(_vm.advisorId),callback:function ($$v) {_vm.advisorId=$$v},expression:"advisorId"}})],1)]}}],null,false,3528216869)})],1),(_vm.moduleId == 4)?_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":"Client Type"}},[(_vm.revission.client_type_id == 1)?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v("REGULAR")]):_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("DIGITAL")])],1)],1):_vm._e()],1):_vm._e(),_c('b-row',[_c('validation-provider',{staticClass:"w-100 px-1",attrs:{"name":"typeOfAgreement","rules":_vm.revission.reject || [3,14].includes(_vm.revission.type) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Observation"}},[_c('b-form-textarea',{class:{ 'border-danger': errors[0] },attrs:{"id":"textarea-1","placeholder":"Enter observation...","rows":"5","max-rows":"6"},model:{value:(_vm.observation),callback:function ($$v) {_vm.observation=$$v},expression:"observation"}})],1)]}}])})],1),(_vm.revission.type === 4 && !_vm.revission.reject)?_c('b-row',{staticClass:"mt-1"},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.sendSms),callback:function ($$v) {_vm.sendSms=$$v},expression:"sendSms"}},[_vm._v(" Send SMS ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }