<template>
  <div class="container-filter-message">
    <div
      class="message"
      v-for="message in messagesFind"
      :key="message._id"
      :class="messageId == message._id ? 'active' : ''"
      @click="messageId = message._id"
    >
      <div class="d-flex justify-content-between">
        <span>{{ message.direction == "Outbound" ? "You" : "" }}</span>
        <div>
          <span class="date">{{ naturalDate(message.creationTime) }}</span>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </div>
      </div>
      <p class="text">{{ message.subject }}</p>
    </div>
    <div v-if="messagesFind.length == 0" class="text-center mt-2">
      No messages found
    </div>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import NestSmsService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
export default {
  props: {
    value: {
      type: String,
    },

    currentChat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      messagesFind: [],
      searchTxt: "",
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    messageId: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  methods: {
    async searchMessages() {
      const params = {
        chatId: this.currentChat._id,
        column: "highlight",
        value: true,
        limit: 1000,
      };
      try {
        this.addPreloader();

        const { data } = await NestSmsService.findMessages(params);
        this.messagesFind = data;
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
  },

  created() {
    this.searchMessages();
  },

  watch: {
    currentChat() {
      this.messagesFind = [];
      this.searchTxt = "";
    },
  },
};
</script>