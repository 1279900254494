import store from '@/store';
import Vue from 'vue';

const notificationDeliverables = () => {

  window.socket.bind('notification-deliverables', async data => {
    const userId = store.state.auth.currentUser.user_id;

    // current User is ceo
    if (userId === 1) {
      await Vue.prototype.$amgAlert({
        title: 'Deliverables received',
        icon: 'CheckIcon',
        subtitle: `Project ${data.projectName}`,
        data: [
          {
            icon: 'GridIcon',
            title: 'Departament',
            description: data.department,
          },
          {
            icon: 'UserIcon',
            title: 'Created by',
            description: data.createdBy,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }
  });
};

export default notificationDeliverables;
