export default {
  namespaced: true,
  state: {
    S_COUNTER_PENDING: 0,
    S_COUNTER_IN_PROCESS: 0,
    S_COUNTER_FINISHED: 0,
    S_COUNTER_APPROVED: 0,
    S_COUNTER_REMARKS: 0,
  },
  mutations: {
    SET_COUNTERS(state, payload) {
      state.S_COUNTER_PENDING = payload.pending;
      state.S_COUNTER_IN_PROCESS = payload.in_process;
      state.S_COUNTER_FINISHED = payload.finished;
      state.S_COUNTER_APPROVED = payload.approved;
      state.S_COUNTER_REMARKS = payload.remarks;
    },
  },
};
