import { potencialTabRoute } from "@/views/commons/components/potencial-appointment/router/potencial.tab.route";

export default [
  {
    path: "potential",
    name: "potencial-financial-approval",
    redirect: { name: "sp-potencial-appointment-in-process" },
    component: () => import("@/views/commons/components/potencial-appointment/views/PotencialMain"),
    meta: {
      pageTitle: "Potential",
      router: "sp-potencial-appointment",
      breadcrumb: [
        {
          text: "Potential",
          active: true,
        },
      ],
      module:29,
      moduleId:29,
      permittedRoles: [1,2,17,27],
      routeInProcess: "sp-potencial-appointment-in-process",
      routeFinished: "sp-potencial-appointment-finished",
      routeCompleted: "sp-potencial-appointment-completed",
      routeRejected: "sp-potencial-appointment-rejected",
      routeExpired: "sp-potencial-appointment-expired",
      program:7
    },
    children: potencialTabRoute("sp-potencial-appointment", "Potential",[1,2,17,27])
  }
];
