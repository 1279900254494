export default {
    namespaced: true,
    state: {
        S_IsActive: false,
        S_DataClaim: null,
        S_Title: null,
        S_DetailClaim:null
    },
    getters: {
        G_IsActive(state) {
            return state.S_IsActive;
        },
        G_DataClaim(state) {
            return state.S_DataClaim;
        },
        G_Title(state) {
            return state.S_Title;
        },
        G_DetailClaim(state){
            return state.S_DetailClaim;
        }
    },
    mutations: {
        M_OpenAlert(state, payload) {
            state.S_IsActive = payload;
        },
        M_DataClaim(state, payload) {
            state.S_DataClaim = payload;
        },
        M_Title(state, payload) {
            state.S_Title = payload;
        },
        M_RESET(state){
            state.S_IsActive = false;
            state.S_Title = '';
            state.S_DataClaim = null;
        },
        M_DetailClaim(state,payload){
            state.S_DetailClaim = payload;
        }
    }
}