export default {
    namespaced: true,
    state: {
        S_UPDATED_NOTE_TABLE: false,
        S_DATA_NOTE: {
            title: null,
            note: null
        },
        S_SHOW_NOTE: false,
        S_TYPE_NOTE: null,
    },
    mutations: {
        UPDATED_NOTE_TABLE(state, params) {
            state.S_UPDATED_NOTE_TABLE = params;
        },
        DATA_NOTE(state, params) {
            state.S_DATA_NOTE = { ...params };
        },
        SHOW_NOTE(state, params) {
            state.S_SHOW_NOTE = params;
        },
        TYPE_NOTE(state, params) {
            state.S_TYPE_NOTE = params;
        },
    },
    actions: {
        A_UPDATED_NOTE_TABLE({ commit }, data) {
            commit("UPDATED_NOTE_TABLE", data);
        },
        A_DATA_NOTE({ commit }, data) {
            commit("DATA_NOTE", data);
        },
        A_SHOW_NOTE({ commit }, data) {
            commit("SHOW_NOTE", data);
        },
        A_TYPE_NOTE({ commit }, data) {
            commit("TYPE_NOTE", data);
        }
    },
    getters: {
        G_UPDATED_NOTE_TABLE(state) {
            return state.S_UPDATED_NOTE_TABLE;
        },
        G_DATA_NOTE(state) {
            return state.S_DATA_NOTE;
        },
        G_SHOW_NOTE(state) {
            return state.S_SHOW_NOTE;
        },
        G_TYPE_NOTE(state) {
            return state.S_TYPE_NOTE;
        },
    },
};
