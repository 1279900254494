import { amgApi } from "@/service/axios"

class MigrationsService {
	async getTrackingMigrationRequest(data) {
		let res = await amgApi.post("/clients/tracking-clients-migration", data)
		return res.data
	}

    async MigrationClientTypeRequest(data) {
        let res = await amgApi.post("/clients/clients-migration-request", data)
        return res.data
    }
    
    async getClientPendingMigration(data) {
        let res = await amgApi.post("/clients/get-count-migration-digital-request", data)
        return res
    }
    async getClientMigration(data) {
        try {
            const response = await amgApi.post("/clients/search-client-migration-request", data)
            return response
        } catch (e) {
            console.log(e)
            throw e
        }
    }
    
    async getAllAdvisors(payload) {
        const data = await amgApi.post("/program/get-program-users", payload);
        return data.data;
    }
}

export default new MigrationsService()