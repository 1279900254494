export default [
  {
    path: "po-box",
    name: "correspondence-po-box",
    redirect: { name: "correspondence-po-box-identify" },
    component: () => import(/* webpackChunkName: "CorrespondencePoBox" */ "@/views/commons/components/file-po-box/FilePOBoxTab.vue"),
    meta: {
      routeIdentify: "correspondence-po-box-identify",
      routeVerified: "correspondence-po-box-verified",
      routeDeleted: "correspondence-po-box-deleted",
      pageTitle: "File PO Box",
      breadcrumb: [
        {
          text: "File PO Box",
        },
      ],
    },
    children: [
      {
        path: "",
        name: "correspondence-po-box-identify",
        component: () => import(/* webpackChunkName: "CorrespondencePoBoxIdentify" */ "@/views/commons/components/file-po-box/components/POBoxIdentify.vue"),
        meta: {
          tab: 1,
          pageTitle: "File PO Box",
          breadcrumb: [
            {
              text: "Identify",
              active: true,
            },
          ],
        },
      },
      {
        path: "verified",
        name: "correspondence-po-box-verified",
        component: () => import(/* webpackChunkName: "CorrespondencePoBoxVerified" */ "@/views/commons/components/file-po-box/components/POBoxVerified.vue"),
        meta: {
          tab: 2,
          pageTitle: "File PO Box",
          breadcrumb: [
            {
              text: "Verified",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
