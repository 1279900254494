<template>
  <div class="main-content" :class="isDarkSkin ? 'dark-bg': null">
    <div class="d-flex flex-column justify-content-center align-items-center text-center content-element position-relative">
      <img
        class="img-load"
        :src="
          isDarkSkin
            ? require('@/assets/images/accounts/file-dark.svg')
            : require('@/assets/images/accounts/file.svg')
        "
      />
      <feather-icon icon="SearchIcon" size="40" class="animate-icon" />
      <div class="message mt-3">{{ message }}</div>
    </div>    
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      require: false,
      default: "Please wait",
    },
  },
};
</script>
<style scoped>
.dark-bg{
    background-color: #202d3aab !important;
}
.main-content {
  z-index: 9999 !important;
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c5c7c9c5;
  padding: 10px;
}
.content-element .message{
    font-size: 22px;
    text-align: center;
    font-weight: bolder;
    letter-spacing: 0.5px;
}
.content-element .animate-icon{
    position: absolute;
}
.content-element .img-load{
    max-width: 20vh;
    max-height: 20vh;
}
.animate-icon {
    position: absolute;
    top: calc(50% - 50px);
    animation: animateLoad 10s linear infinite alternate;
}
@keyframes animateLoad{
    0%{
        transform: translate(0);
    }20%{
        transform: translateX(-30px) translateY(-50px) scale(1);
    }25%{
        transform: translateX(-30px) translateY(-50px) scale(1.1);
    }40%{
        transform: translateX(50px) translateY(-50px) scale(1.1);
    }45%{
        transform: translateX(50px) translateY(-50px) scale(0.9);
    }60%{
        transform: translateX(30px) translateY(20px) scale(0.9);
    }65%{
        transform: translateX(30px) translateY(20px) scale(1);
    }80%{
        transform: translateX(30px) translateY(50px);
    }85%{
        transform: translateX(30px) translateY(50px);
    }100%{
        transform: translateX(-30px) translateY(50px);
    }
}
</style>