import { amgApi } from '@/service/axios'

class ProcessCRServices {
    async getProcessCR(params) {
        try {
            const data = await amgApi.post("specialist-digital/get-process-cr", params);
            return data;
        } catch (error) {
            console.log("Error getProcessCR");
        }
    }
    async insertTrackingDigitalClients(params) {
        try {
            const data = await amgApi.post('specialist-digital/insert-tracking-digital-clients', params)
            return data
        } catch (error) {
            console.log('Error insertTrackingDigitalClients')
        }
    }

    async setProcessDone(params) {
        try {
            const data = await amgApi.post("specialist-digital/set-process-cr-done", params);
            return data;
        } catch (error) {
            console.log("Error setProcessDone");
        }
    }
    async setProcessDelete(params) {
        try {
            const data = await amgApi.post("specialist-digital/set-process-cr-delete", params);
            return data;
        } catch (error) {
            console.log("Error setProcessDelete");
        }
    }
    async getCountProcessCE(params) {
        try {
            const data = await amgApi.post("specialist-digital/get-count-process-cr", params);
            return data;
        } catch (error) {
            console.log("Error getCountProcessCE");
        }
    }

    async getCountClientCE(params) {
        try {
            const data = await amgApi.post("specialist-digital/get-count-client-digital", params);
            return data;
        } catch (error) {
            console.log("Error getCountProcessCE");
        }
    }
    async getAdvisors() {
        try {
            const data = await amgApi.post("specialists-digital/get-advisors-specialists");
            return data;
        } catch (error) {
            console.log("Error getAdvisors");
        }
    }




}

export default new ProcessCRServices()
