import ceSOfficeHoursRouter from '@/views/ce-digital/sub-modules/settings/views/office-hours/office-hours.router'
import ceSNotesRouter from '@/views/ce-digital/sub-modules/settings/views/notes/notes.router'
import ceSCommissionsRouter from '@/views/ce-digital/sub-modules/settings/views/commissions/ce-set-commissions-router'
import ceSUsersRouter from '@/views/ce-digital/sub-modules/settings/views/users/router/users.router'
import ceMigrationsRouter from '@/views/ce-digital/sub-modules/settings/views/migrations/router/index'
import ceSMessagesRouter from '@/views/ce-digital/sub-modules/settings/views/messages/ce-set-messages-router'
import cePermissionsRouter from '@/views/ce-digital/sub-modules/settings/views/permissions/ce-permissions-router'
import UsersRelationshipRouter from '@/views/ce-digital/sub-modules/settings/views/users-relationship/routes'
import CeMotivesRouter from '@/views/ce-digital/sub-modules/settings/views/motives/motives.router.js'
import CeSetPricesRouter from '@/views/ce-digital/sub-modules/settings/views/prices/ce-set-prices-router'
import SettingsMain from '@/views/ce-digital/sub-modules/settings/views/SettingsMain.vue'
//import SettingsProjectsRouter from '@/views/ce-digital/sub-modules/settings/views/projects/settings-projects.router';
import MOFRouter from '@/views/ce-digital/sub-modules/settings/views/manual-of-functions/manual-of-functions.router.js'

const routes = [
  {
    path: '/cedigital/settings',
    redirect: { name: 'ce-digital-users' },
    component: SettingsMain,
    meta: {
      module: 24,
      parentModule: 21,
    },
    children: [
      ...ceSUsersRouter,
      ...ceSOfficeHoursRouter,
      ...ceSNotesRouter,
      ...ceSCommissionsRouter,
      ...ceSMessagesRouter,
      ...ceMigrationsRouter,
      ...cePermissionsRouter,
      ...UsersRelationshipRouter,
      ...CeMotivesRouter,
      ...CeSetPricesRouter,
      //...SettingsProjectsRouter,
      ...MOFRouter,
    ]
  },

];
export default routes;
