import clientsRouter from "@/views/ce-digital/sub-modules/customer-service/views/clients/clients.router";
import ncrRealtorRouter from "@/views/ce-digital/sub-modules/customer-service/views/ncr-realtor/router/index";
import applicationsRouter from "@/views/ce-digital/sub-modules/customer-service/views/applications/router/index";
import callRoundRouter from "@/views/ce-digital/sub-modules/customer-service/views/call-round/router/callround.router";
import clientsGridRouter from "@/views/ce-digital/sub-modules/customer-service/views/clients-table/router/index";
import dashboardTrackingRouter from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-tracking/dashboard-tracking.router";
import dashboardAgent from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-agent/dashboard.router";
import dashboardSuper from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-super/dashboard.router";
import chatAppRouter from "@/views/ce-digital/sub-modules/customer-service/views/chat-app/router";
import chatRevisionRouter from "@/views/ce-digital/sub-modules/customer-service/views/chat-revision/revision.router";
import fileAppRouter from "@/views/ce-digital/sub-modules/customer-service/views/file-app/router/index";
import notificationAppRouter from "@/views/ce-digital/sub-modules/customer-service/views/notification-app/router/index";
import AlertTrackingRouter from "@/views/ce-digital/sub-modules/customer-service/views/alert-tracking/alert-tracking.router";
import UserCommissionRouter from "@/views/ce-digital/sub-modules/customer-service/views/user-commission/user-commission.router";
import LeadsRouter from "@/views/ce-digital/sub-modules/customer-service/views/leads/leads.router";
import AgentAwayRouter from "@/views/ce-digital/sub-modules/customer-service/views/agent-away/agent-away.router";
import HelpDeskRouter from "@/views/ce-digital/sub-modules/customer-service/views/helpdesk/helpdesk.route";
import DisputeResultsRouter from "@/views/ce-digital/sub-modules/customer-service/views/dispute-results/router/index";
import CommissionsRouter from "@/views/ce-digital/sub-modules/customer-service/views/commissions/commissions.router";
import LettersRouter from "@/views/ce-digital/sub-modules/customer-service/views/letters/letters.router";
import directRoundRouter from "@/views/ce-digital/sub-modules/customer-service/views/direct-round/direct-round.router";
import reportsReport from "@/views/ce-digital/sub-modules/customer-service/views/reports/reports.router";
import CeCustomerServiceRouter from "@/views/ce-digital/sub-modules/customer-service/views/attendance/ce-customer-service.router";
import CustomerServiceMain from "@/views/ce-digital/sub-modules/customer-service/views/CustomerServiceMain.vue";
import EmployeeClaimsRouter from "@/views/ce-digital/sub-modules/customer-service/views/employee-claims/router/employee-claims.router";
import RequestRouter from "@/views/ce-digital/sub-modules/customer-service/views/request/request.router.js";
import RequestNcrRouter from "@/views/ce-digital/sub-modules/customer-service/views/request-ncr/request-ncr.router";
import RequestWorkplanRouter from "@/views/ce-digital/sub-modules/customer-service/views/request-workplan/request-workplan.router.js";
import ClaimsRouter from "@/views/ce-digital/sub-modules/customer-service/views/claims/claims.router.js";
//import CustomerServiceProjectsRouter from "@/views/ce-digital/sub-modules/customer-service/views/projects/customer-service-projects.router";
import CallLogRouter from "@/views/ce-digital/sub-modules/customer-service/views/ring-central/call-log/call-log.router.js";
import CourtInfoRouter from "@/views/ce-digital/sub-modules/customer-service/views/cour-info/router/ds-court-info.router";

import DashboardCalls from "@/views/ce-digital/sub-modules/customer-service/views/ring-central/dashboard-calls/dashboard.router.js";

import MOFRouter from "@/views/ce-digital/sub-modules/customer-service/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/ce-digital/sub-modules/customer-service/views/recruitment-process/customer-service-recruitment_process.router";
import CustomerTicketRouter from "@/views/ce-digital/sub-modules/customer-service/views/customer-tickets/customer-service-customer-tickets.router";
import SendingReportRouter from "@/views/ce-digital/sub-modules/customer-service/views/sending-report/sending-report.router.js";
import AppointmentRouter from "@/views/ce-digital/sub-modules/customer-service/views/appointment/appointment.router";
import PotencialAppointmentRouter from "@/views/ce-digital/sub-modules/customer-service/views/potencial-appointment/potencial-appointment.route";
import OtherServicesRouter from "@/views/ce-digital/sub-modules/customer-service/views/other-services/other-services.router";
import CommissionsSpecialistsRouter from "@/views/ce-digital/sub-modules/customer-service/views/commission-specialists/commissions-specialists.router";

import RequestClientStatusRouter from "@/views/ce-digital/sub-modules/customer-service/views/request-client-status/router/request-client-status.router";

const routes = [
  {
    path: "/cedigital/customerservice",
    name: "ce-digital-customer-service",
    redirect: { name: "ce-dashboard-super" },

    meta: {
      parentModule: 21,
      module: 22,
      childModule: 6,
      program: 3,
    },
    component: CustomerServiceMain,
    children: [
      ...clientsGridRouter,
      ...clientsRouter,
      ...callRoundRouter,
      ...ncrRealtorRouter,
      ...applicationsRouter,
      dashboardAgent,
      ...RequestNcrRouter,
      ...RequestWorkplanRouter,
      dashboardSuper,
      ...dashboardTrackingRouter,
      chatAppRouter,
      chatRevisionRouter,
      ...fileAppRouter,
      ...notificationAppRouter,
      ...AlertTrackingRouter,
      UserCommissionRouter,
      ...LeadsRouter,
      AgentAwayRouter,
      HelpDeskRouter,
      ...DisputeResultsRouter,
      ...CommissionsRouter,
      ...LettersRouter,
      ...directRoundRouter,
      reportsReport,
      ...CeCustomerServiceRouter,
      ...EmployeeClaimsRouter,
      ...RequestRouter,
      ...ClaimsRouter,
      //...CustomerServiceProjectsRouter,
      ...CallLogRouter,
      ...CourtInfoRouter,
      ...DashboardCalls,

      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...CustomerTicketRouter,
      ...SendingReportRouter,
      ...AppointmentRouter,
      ...PotencialAppointmentRouter,
      ...OtherServicesRouter,
      ...CommissionsSpecialistsRouter,
      ,
      ...RequestClientStatusRouter,
    ],
  },
];

export default routes;
