export default [
  {
      path: "dashboard",
      name: "admin-dashboard",
      component: () =>
          import(/* webpackChunkName: "ParagonDashboardMain" */ "@/views/paragon/views/dashboard/components/ParagonDashboard.vue"),
      meta: {
          pageTitle: "Dashboard",
          breadcrumb: [
              {
                  text: "Dashboard",
              },
          ],
      },
  }
];
