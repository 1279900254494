export default [
  {
    path: "/boost-credit-dashboard-client/:idClient",
    name: "boost-credit-dashboard-general",
    redirect: { name: "personal-information-boost-credit-general" },
    component: () =>
      import(
        /* webpackChunkName: "BCClientAccount" */ "@/views/boost-credit/views/clients/dashboard/AccountBoostCredit.vue"
      ),
    props: true,
    meta: {
      moduleName: "boost-credit",
      general: true,
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay-general",
        name: "pay-boost-credit-general",
        component: () =>
          import(
            /* webpackChunkName: "BCClientPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "dis",
        name: "dis-boost-credit-general",
        redirect: { name: "report-detail-bc-general" },
        component: () =>
          import(
            /* webpackChunkName: "BCClientDis" */ "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        meta: {
          isTicket: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Dispute",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "dispute-detail",
            name: "dispute-detail-bc-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/DisputeDetail.vue"
              ),
            meta: {
              isTicket: true,
              pageTitle: "Dispute Detail",
              breadcrumb: [
                {
                  text: "Dispute Detail",
                  active: true,
                },
              ],
            },
          },
          {
            path: "report-detail",
            name: "report-detail-bc-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
              ),
            meta: {
              isTicket: true,
              pageTitle: "Report Detail",
              breadcrumb: [
                {
                  text: "Report Detail",
                  active: true,
                },
              ],
            },
          },
          {
            path: "updates-detail",
            name: "updates-detail-bc-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/UpdateDetail.vue"
              ),
            meta: {
              isTicket: true,
              pageTitle: "Updates Detail",
              breadcrumb: [
                {
                  text: "Updates Detail",
                  active: true,
                },
              ],
            },
          },
          {
            path: "workplan-detail",
            name: "workplan-detail-bc-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Workplan Detail",
              breadcrumb: [
                {
                  text: "Workplan Detail",
                  active: true,
                },
              ],
            },
          },
            {
            path: "recommendation-detail",
            name: "recommendation-detail-bc-general",
            component: () =>
              import(
                /* webpackChunkName: "recommendationDetailBc" */   "@/views/commons/components/clients/dashboard/options/services/services.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Recommendations",
              breadcrumb: [
                {
                  text: "Recommendations",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "task-notes-general",
        name: "task-notes-boost-credit-general",
        component: () =>
          import(
            /* webpackChunkName: "BCClientTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information-general",
        name: "personal-information-boost-credit-general",
        component: () =>
          import(
            /* webpackChunkName: "BCClientPersonalInformation" */ "@/views/boost-credit/views/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files-general",
        name: "client-files-boost-credit-general",
        component: () =>
          import(
            /* webpackChunkName: "BCClientFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr-general",
        name: "acr-boost-credit-general",
        component: () =>
          import(
            /* webpackChunkName: "BCClientAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "report-general",
        name: "report-boost-credit-general",
        component: () =>
          import(
            /* webpackChunkName: "BCClientReport" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log-general",
        name: "call-log-boost-credit-general",
        // redirect: { name: 'boost-credit-client-general-call' },
        component: () =>
          import(
            "@/views/business/views/clients/dashboard/views/components/sections/CallLogSubtabs.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
        },
        // children: [
        //   {
        //     path: 'general-call',
        //     name: 'boost-credit-client-general-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'General',
        //       breadcrumb: [
        //         {
        //           text: 'General',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     path: 'ceo-call',
        //     name: 'boost-credit-client-ceo-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/CeoCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'CEO calls',
        //       breadcrumb: [
        //         {
        //           text: 'CEO calls',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        path: "tickets",
        name: "client-boost-credit-tickets-general",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
