import { amgApi } from "@/service/axios"

class SmartInputService {
    async store(params) {
        try {
            const { data } = await amgApi.post("/open-ai/completion/store", params)
            return data
        } catch (error) {
            throw error
        }
    }

}

export default new SmartInputService()
