export default [
  {
    path: "folders",
    name: "file-mananger-management",
    component: () => import(/* webpackChunkName: "ManaFileManager" */ "@/views/crm/views/file-mananger/FileMananger.vue"),
    meta: {
      pageTitle: "File Manager",
      breadcrumb: [
        {
          text: "File Manager",
          active: true,
        },
      ],
    },
  }
]
  