<template>
  <div class="view-financial" :style="{ marginLeft: marginAuto }">
    <b-row>
      <b-col cols="8">
        <div
          style="margin-top: 5px; box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3)"
          :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
          class="d-flex align-items-center my-border-radius-left my-border-radius-right"
        >
          <div
            style="background-color: #f59d1b; width: 30%"
            class="my-text-light my-p-1 my-border-radius-right my-border-radius-left pl-1"
          >
            • CLIENT :
          </div>
          <div class="ml-1">{{ sales.client }}</div>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="d-flex justify-content-end" :style="{ width: widthClient }">
          <ButtonExportAnalysis
            :leadId="sales.lead_id"
            :event-id="sales.event_id"
            :size="'sm'"
          />
        </div>
      </b-col>
    </b-row>
    <div v-if="!loading" class="mt-1">
      <b-row no-gutters>
        <b-col cols="5" class="col-space"></b-col>
        <b-col cols="3" class="col-space">
          <div
            :class="[
              isDarkTheme
                ? 'my-bg-secondary-dark my-border-top-2-dark'
                : 'my-bg-secondary my-border-top-2',
            ]"
            class="font-weight-bolder text-center my-h-2 d-flex align-items-center justify-content-center"
            style="color: #6e6888"
          >
            CURRENT SITUATION
          </div>
        </b-col>
        <b-col cols="3" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light2-dark' : 'my-bg-success']"
            class="font-weight-bolder my-border-top-2-success my-text-success my-h-2 d-flex align-items-center justify-content-center"
          >
            <check-icon :width="'12'" :height="'12'" />
            <span style="margin-left: 7px"> OPTION {{ selectedOption }}</span>
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="5" class="col-space">
          <div
            class="my-border-radius-left font-weight-bolder d-flex align-items-center my-h-3 pl-1"
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
          >
            <current-debt-icon :width="'25'" :height="'25'" />
            <span class="ml-1">CURRENT DEBT</span>
          </div>
        </b-col>
        <b-col cols="3" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-h-3 text-center px-1 d-flex align-items-center justify-content-center font-weight-bolder"
          >
            <b-row>
              <b-col cols="12">
                <div class="my-border-bottom my-pb-1">
                  $ {{ current.current_debt }}
                </div>
              </b-col>
              <b-col class="d-flex justify-content-center flex-wrap">
                <div class="my-font-1">INTEREST</div>
                <div
                  class="my-badge my-font-1"
                  :class="[
                    isDarkTheme ? 'my-bg-secondary-dark' : 'my-bg-secondary',
                  ]"
                >
                  {{ current.interest }} %
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="3" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-right my-h-3 text-center px-1 d-flex align-items-center justify-content-center font-weight-bolder"
          >
            <b-row>
              <b-col cols="12">
                <div class="my-border-bottom my-pb-1">
                  $ {{ option.current_debt }}
                </div>
              </b-col>
              <b-col class="d-flex justify-content-center flex-wrap">
                <div class="my-font-1">INTEREST</div>
                <div
                  class="my-badge my-font-1"
                  :class="[
                    isDarkTheme ? 'my-bg-success-light' : 'my-bg-success-light',
                  ]"
                  style="color: #0fd680"
                >
                  {{ option.interest }} %
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="5" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-left font-weight-bolder d-flex align-items-center my-h-2 pl-1"
          >
            <monthly-payment-icon :width="'25'" :height="'25'" />
            <span class="ml-1">MONTHLY PAYMENT</span>
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ current.monthly_payment }}
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-right d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ option.monthly_payment }}
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="5" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-left font-weight-bolder d-flex align-items-center my-h-2 pl-1"
          >
            <monthly-payment-icon :width="'25'" :height="'25'" />
            <span class="ml-1">DAILY INTEREST</span>
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ current.interest_daily }}
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-right d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ option.interest_daily }}
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="5" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-left font-weight-bolder d-flex align-items-center my-h-2 pl-1"
          >
            <monthly-interest-icon :width="'25'" :height="'25'" />
            <span class="ml-1">MONTHLY INTEREST</span>
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ current.interest_monthly }}
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-right d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ option.interest_monthly }}
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="5" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-left font-weight-bolder d-flex align-items-center my-h-2 pl-1"
          >
            <monthly-interest-icon :width="'25'" :height="'25'" />
            <span class="ml-1">ANNUAL INTEREST</span>
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ current.interest_year }}
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-right d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ option.interest_year }}
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="5" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-left font-weight-bolder d-flex align-items-center my-h-2 pl-1"
          >
            <monthly-payment-icon :width="'25'" :height="'25'" />
            <span class="ml-1">PRINCIPAL PAYMENT</span>
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ current.payment_principal }}
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-right d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ option.payment_principal }}
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="5" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-left font-weight-bolder d-flex align-items-center my-h-2 pl-1"
          >
            <time-icon :width="'25'" :height="'25'" />
            <span class="ml-1">TIME</span>
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="d-flex align-items-center justify-content-center my-h-2"
          >
            {{ current.payment_time }}
            {{ current.payment_time == 1 ? "MONTH" : "MONTHS" }}
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-right d-flex align-items-center justify-content-center my-h-2"
          >
            {{ option.payment_time }}
            {{ option.payment_time == 1 ? "MONTH" : "MONTHS" }}
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="5" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-left font-weight-bolder d-flex align-items-center my-h-2 pl-1"
          >
            <savings-icon :width="'25'" :height="'25'" />
            <span class="ml-1">SAVINGS</span>
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ current.saving }}
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-right d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ option.saving }}
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="5" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-left font-weight-bolder d-flex align-items-center my-h-2 pl-1"
          >
            <charge-icon :width="'25'" :height="'25'" />
            <span class="ml-1">SERVICE CHARGE </span>
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="d-flex align-items-center justify-content-center my-h-2"
          >
            {{ current.service }}
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-right d-flex align-items-center justify-content-center my-h-2"
          >
            $ {{ option.service }}
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col cols="5" class="col-space">
          <div
            :class="[isDarkTheme ? 'my-bg-light-dark' : 'my-bg-light']"
            class="my-border-radius-left font-weight-bolder d-flex align-items-center my-h-2 pl-1"
          >
            <total-amount-due-icon :width="'25'" :height="'25'" />
            <span class="ml-1">TOTAL AMOUNT DUE</span>
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[
              isDarkTheme
                ? 'my-bg-light-dark my-border-bottom-2-dark'
                : 'my-bg-light my-border-bottom-2',
            ]"
            class="d-flex align-items-center justify-content-center my-h-2 font-weight-bolder"
          >
            $ {{ current.total_debt }}
          </div>
        </b-col>
        <b-col cols="3" class="col-space text-center">
          <div
            :class="[isDarkTheme ? 'my-bg-light2-dark' : 'my-bg-light2']"
            class="my-border-radius-right-top my-text-success d-flex align-items-center my-border-bottom-2-success justify-content-center my-h-2 font-weight-bolder"
          >
            $ {{ option.total_debt }}
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import LeadService from "@/views/commons/components/lead-programs/service/lead.service.js"
import { mapGetters } from "vuex"
import ButtonExportAnalysis from "@/views/crm/views/Lead/components/ButtonExportAnalysis.vue"

import CurrentDebtIcon from "@/views/crm/views/sales-made/components/modals/services/debt-solution/icons/CurrentDebtIcon.vue"
import MonthlyPaymentIcon from "@/views/crm/views/sales-made/components/modals/services/debt-solution/icons/MonthlyPaymentIcon.vue"
import MonthlyInterestIcon from "@/views/crm/views/sales-made/components/modals/services/debt-solution/icons/MonthlyInterestIcon.vue"
import TimeIcon from "@/views/crm/views/sales-made/components/modals/services/debt-solution/icons/TimeIcon.vue"
import SavingsIcon from "@/views/crm/views/sales-made/components/modals/services/debt-solution/icons/SavingsIcon.vue"
import ChargeIcon from "@/views/crm/views/sales-made/components/modals/services/debt-solution/icons/ChargeIcon.vue"
import TotalAmountDueIcon from "@/views/crm/views/sales-made/components/modals/services/debt-solution/icons/TotalAmountDueIcon.vue"
import CheckIcon from "@/views/crm/views/sales-made/components/modals/services/debt-solution/icons/CheckIcon.vue"
import QuestionnaireService from "@/views/crm/views/sales-made/components/modals/services/debt-solution/services/questionnnaire-service.js"
export default {
  components: {
    ButtonExportAnalysis,
    CurrentDebtIcon,
    MonthlyPaymentIcon,
    MonthlyInterestIcon,
    TimeIcon,
    SavingsIcon,
    ChargeIcon,
    TotalAmountDueIcon,
    CheckIcon,
  },
  props: {
    sales: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      windowSize: "app/currentBreakPoint",
    }),

    marginAuto() {
      switch (this.windowSize) {
        case "xl":
          return "50px"
        default:
          return "20px"
      }
    },
    widthClient() {
      switch (this.windowSize) {
        case "xxl":
          return "81%"
        case "xl":
          return "85%"
        default:
          return "90%"
      }
    },

    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin == "dark"
    },

    selectedOption() {
      switch (this.sales.ds_analysis_options_id) {
        case 1:
          return "A"
        case 2:
          return "B"
        case 3:
          return "C"
        default:
          return "A"
      }
    },
  },
  data() {
    return {
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
        maxlength: 15,
      },
      loading: true,
      current: {},
      option: {},
    }
  },
  async mounted() {
    await this.getFinancialAnalysis()
  },
  methods: {
    async getFinancialAnalysis() {
      const params = {
        lead_id: this.sales.lead_id,
        event_id: this.sales.event_id,
      }
      // const { data } = await LeadService.getCurrentFinancialAnalysis(params);
      const { data } = await QuestionnaireService.getFinancialAnalysisData(
        params
      )
      this.current = data.current
      if (this.sales.ds_analysis_options_id == 1) {
        this.option = data.option_a
      } else if (this.sales.ds_analysis_options_id == 2) {
        this.option = data.option_b
      } else if (this.sales.ds_analysis_options_id == 3) {
        this.option = data.option_c
      }
      this.loading = false
    },
  },
}
</script>

<style scoped>
.view-financial {
  font-family: "Roboto Slab", sans-serif;
  /* background-color: #f9fafe !important; */
}

.my-text-success {
  color: #01a688;
}

.my-text-secondary {
  color: #666666;
}

.my-text-light {
  color: #ffffff;
}

.my-bg-success-strong {
  background-color: #040505;
}

.my-bg-success {
  background-color: #cdfff6;
}

.my-bg-success-light {
  background-color: #dcfff0;
}
.my-bg-success-light-dark {
  background-color: #037e48;
}

.my-bg-secondary {
  background-color: #f1f1f1;
}
.my-bg-secondary-dark {
  background-color: #323232;
  color: #ffffff !important;
}

.my-bg-light {
  background-color: #ffffff;
}
.my-bg-light2 {
  background-color: #ddfff9;
}
.my-bg-light2-dark {
  background-color: #323232;
  color: #ffffff;
}

.my-bg-light-dark {
  background-color: #1d1d20;
}

.my-border-bottom {
  border-bottom: 1px #666666 solid;
}

.my-border-top-2 {
  border-top: 3px #000000 solid;
}
.my-border-top-2-dark {
  border-top: 3px #797979 solid;
}
.my-border-top-2-success {
  border-top: 3px #01a688 solid;
}

.my-border-bottom-2 {
  border-bottom: 3px #000000 solid;
}
.my-border-bottom-2-dark {
  border-bottom: 3px #797979 solid;
}

.my-border-bottom-2-success {
  border-bottom: 3px #01a688 solid;
}

.my-border-radius-left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.my-border-radius-right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.my-border-radius-right-top {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 5px;
}

.my-badge {
  border-radius: 20px;
  width: 50%;
  margin-left: 3px;
}

.my-pb-1 {
  padding-bottom: 0.3rem;
  margin-bottom: 0.3rem;
}

.my-pt-1 {
  padding-top: 0.3rem;
}

.my-p-1 {
  padding: 0.5rem;
}

.my-h-1 {
  height: 2.5rem !important;
}

.my-h-2 {
  height: 3rem;
}

.my-h-3 {
  height: 5rem !important;
}

.my-font-1 {
  font-size: 0.8rem;
}

.col-space {
  margin: 3px;
}
</style>
