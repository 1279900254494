<template>
  <b-card>
    <b-row class="px-1 mb-2">
      <h3
        class="font-weight-bolder px-1 border-bottom-secondary border-2 pb-1 w-100"
      >
        Basic Information
      </h3>
    </b-row>
    <b-row>
      <!-- First Name -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="FirstName"
          rules="required"
        >
          <b-form-group label="First Name" label-for="first-name">
            <template #label>
              <span class="font-ce"> First Name </span>
            </template>
            <b-form-input
              id="first-name"
              v-model="userData.first_name"
              name="first-name"
              autofocus
              :state="errors[0] ? false : null"
              trim
              @keyup="capitalize('first_name')"
              :disabled="disabledForm"
              class="font-ce"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `First name ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Middle Name -->
      <b-col md="6" lg="3">
        <validation-provider name="Middle Name">
          <b-form-group label="Middle Name" label-for="middle-name">
            <template #label>
              <span class="font-ce"> Middle Name </span>
            </template>
            <b-form-input
              id="middle-name"
              v-model="userData.middle_name"
              trim
              :disabled="disabledForm"
              class="font-ce"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Last Name -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="LastName"
          rules="required"
        >
          <b-form-group label="Last Name" label-for="last-name">
            <template #label>
              <span class="font-ce"> Last Name </span>
            </template>
            <b-form-input
              id="last-name"
              v-model="userData.last_name"
              trim
              :state="errors[0] ? false : null"
              @keyup="capitalize('last_name')"
              :disabled="disabledForm"
              class="font-ce"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Last name ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Dob -->
      <b-col md="6" lg="3">
        <validation-provider name="DOB">
          <b-form-group label="DOB" label-for="dob">
            <template #label>
              <span class="font-ce"> DOB </span>
            </template>
            <kendo-datepicker
              :class="[
                'w-100 rounded bg-transparent k-picker-custom',
                { 'text-white': isDarkSkin },
              ]"
              :format="'MM/dd/yyyy'"
              v-model="userData.dob"
              v-mask="'##/##/####'"
              class="leads-datepicker font-ce"
              :disabled="disabledForm"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Gender -->
      <b-col md="6" lg="3">
        <validation-provider v-slot="{ errors }" name="Gender" rules="required">
          <b-form-group
            label="Gender"
            label-for="gender"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce"> Gender </span>
            </template>
            <v-select
              v-model="userData.gender"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="genderOptions"
              :clearable="false"
              input-id="gender"
              class="font-ce"
              :reduce="(el) => el.value"
              :disabled="disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Gender ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Language -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Language"
          rules="required"
        >
          <b-form-group
            label="Language"
            label-for="language"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce"> Language </span>
            </template>
            <v-select
              v-model="userData.language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="G_LANGUAGES"
              :clearable="false"
              input-id="language"
              class="font-ce"
              :reduce="(el) => el.value"
              :disabled="disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Language ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Origin Country -->
      <b-col md="6" lg="3">
        <b-form-group label="Origin Country" label-for="originCountry">
          <template #label>
            <span class="font-ce">Origin Country</span>
          </template>
          <v-select
            input-id="originCountry"
            v-model="userData.origin_country"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="font-ce"
            :options="G_COUNTRIES"
            :reduce="(el) => el.id"
            :clearable="false"
            :disabled="disabledForm"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="px-1 my-2">
      <h3
        class="font-weight-bolder px-1 border-bottom-secondary border-2 pb-1 w-100"
      >
        Identification
      </h3>
    </b-row>
    <b-row>
      <!-- <b-col md="6"> -->
      <template v-if="userData.id">
        <!-- SSN -->
        <b-col md="6" lg="3">
          <validation-provider name="SSN">
            <b-form-group label="SSN" label-for="ssn">
              <template #label>
                <span
                  class="font-ce d-flex justify-content-between align-items-center"
                >
                  SSN
                  <span
                    v-if="userData.id"
                    class="see-tracking"
                    @click="onModalTrackingChangeOpen(4, 'SSN')"
                    >See Tracking</span
                  >
                </span>
              </template>
              <b-input-group>
                <!--  moduleId === 11 && moduleId === 25 -->
                <span
                  class="font-ce form-control bg-transparent"
                  v-if="disabled.ssn && [11, 25, 28].includes(moduleId)"
                  >{{ userData.ssn }}</span
                >
                <span
                  class="font-ce form-control bg-transparent"
                  v-else-if="disabled.ssn"
                  >{{ encryptedSSN }}</span
                >
                <b-form-input
                  v-else
                  id="ssn"
                  ref="ssn"
                  v-model="userData.ssn"
                  autofocus
                  trim
                  maxlength="11"
                  class="font-ce"
                  v-mask="'###-##-####'"
                  @keyup="onValidSsn($event)"
                />
                <template v-if="userData.id && !disabledForm">
                  <b-input-group-append
                    v-if="!disabled.ssn"
                    class="border-right"
                  >
                    <b-button
                      variant="outline-primary"
                      class="btn-sm"
                      :disabled="disabled.saveSSN"
                      @click="onSubmitFields('ssn', 4)"
                    >
                      <feather-icon icon="SaveIcon" class="cursor-pointer" />
                    </b-button>
                  </b-input-group-append>
                  <b-input-group-append
                    v-if="
                      isCeo ||
                      isSupervisor ||
                      !userData.ssn ||
                      isCoordinator ||
                      isChief ||
                      isAdministrator ||
                      isAdminSpecialist ||
                      [11, 25, 28].includes(moduleId)
                    "
                    class="border-right"
                  >
                    <b-button
                      variant="outline-warning"
                      class="btn-sm"
                      @click="toggleElement('ssn')"
                    >
                      <feather-icon
                        v-if="disabled.ssn"
                        icon="Edit2Icon"
                        class="cursor-pointer"
                      />
                      <amg-icon
                        v-else
                        icon="Edit2SlashIcon"
                        class="cursor-pointer"
                      />
                    </b-button>
                  </b-input-group-append>
                </template>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- ITIN -->
        <b-col md="6" lg="3">
          <validation-provider name="ITIN">
            <b-form-group label="ITIN" label-for="itin">
              <template #label>
                <span
                  class="font-ce d-flex justify-content-between align-items-center"
                >
                  ITIN
                  <span
                    v-if="userData.id"
                    class="see-tracking"
                    @click="onModalTrackingChangeOpen(5, 'ITIN')"
                    >See Tracking</span
                  >
                </span>
              </template>
              <b-input-group>
                <!--  moduleId === 11 && moduleId === 25 -->
                <span
                  class="font-ce form-control bg-transparent"
                  v-if="disabled.itin && [11, 25, 28].includes(moduleId)"
                  >{{ userData.itin }}</span
                >
                <span
                  class="font-ce form-control bg-transparent"
                  v-else-if="disabled.itin"
                  >{{ encryptedITIN }}</span
                >
                <b-form-input
                  v-else
                  id="itin"
                  ref="itin"
                  v-model="userData.itin"
                  autofocus
                  trim
                  class="font-ce"
                  maxlength="11"
                  v-mask="'###-##-####'"
                  @keyup="onValidItin($event)"
                />
                <template v-if="userData.id && !disabledForm">
                  <b-input-group-append
                    v-if="!disabled.itin"
                    class="border-right"
                  >
                    <b-button
                      variant="outline-primary"
                      class="btn-sm"
                      :disabled="disabled.saveITIN"
                      @click="onSubmitFields('itin', 5)"
                    >
                      <feather-icon icon="SaveIcon" class="cursor-pointer" />
                    </b-button>
                  </b-input-group-append>
                  <b-input-group-append
                    v-if="
                      isCeo ||
                      isSupervisor ||
                      !userData.itin ||
                      isCoordinator ||
                      isChief ||
                      isAdministrator ||
                      isAdminSpecialist ||
                      [11, 25, 28].includes(moduleId)
                    "
                    class="border-right"
                  >
                    <b-button
                      variant="outline-warning"
                      class="btn-sm"
                      @click="toggleElement('itin')"
                    >
                      <feather-icon
                        v-if="disabled.itin"
                        icon="Edit2Icon"
                        class="cursor-pointer"
                      />
                      <amg-icon
                        v-else
                        icon="Edit2SlashIcon"
                        class="cursor-pointer"
                      />
                    </b-button>
                  </b-input-group-append>
                </template>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- CPN -->
        <b-col md="6" lg="3">
          <validation-provider name="CPN">
            <b-form-group label="CPN" label-for="cpn">
              <template #label>
                <span
                  class="font-ce d-flex justify-content-between align-items-center"
                >
                  CPN
                  <span
                    v-if="userData.id"
                    class="see-tracking"
                    @click="onModalTrackingChangeOpen(6, 'CPN')"
                    >See Tracking</span
                  >
                </span>
              </template>
              <b-input-group>
                <!--  moduleId === 11 && moduleId === 25 -->
                <span
                  class="font-ce form-control bg-transparent"
                  v-if="disabled.other && [11, 25, 28].includes(moduleId)"
                  >{{ userData.other }}</span
                >
                <span
                  class="font-ce form-control bg-transparent"
                  v-else-if="disabled.other"
                  >{{ encryptedCPN }}</span
                >

                <b-form-input
                  v-else
                  id="cpn"
                  ref="cpn"
                  v-model="userData.other"
                  autofocus
                  trim
                  class="font-ce"
                  maxlength="11"
                  v-mask="'###-##-####'"
                  @keyup="onValidCpn($event)"
                />
                <template v-if="userData.id && !disabledForm">
                  <b-input-group-append
                    v-if="!disabled.other"
                    class="border-right"
                  >
                    <b-button
                      variant="outline-primary"
                      class="btn-sm"
                      :disabled="disabled.saveCPN"
                      @click="onSubmitFields('cpn', 6, 'other')"
                    >
                      <feather-icon icon="SaveIcon" class="cursor-pointer" />
                    </b-button>
                  </b-input-group-append>

                  <b-input-group-append
                    v-if="
                      isCeo ||
                      isSupervisor ||
                      !userData.other ||
                      isCoordinator ||
                      isChief ||
                      isAdministrator ||
                      isAdminSpecialist ||
                      [11, 25, 28].includes(moduleId)
                    "
                    class="border-right"
                  >
                    <b-button
                      variant="outline-warning"
                      class="btn-sm"
                      @click="toggleElement('other')"
                    >
                      <feather-icon
                        v-if="disabled.other"
                        icon="Edit2Icon"
                        class="cursor-pointer"
                      />
                      <amg-icon
                        v-else
                        icon="Edit2SlashIcon"
                        class="cursor-pointer"
                      />
                    </b-button>
                  </b-input-group-append>
                  <!-- <b-input-group-append
                      class="cursor-pointer"
                      @click="onModalTrackingChangeOpen(6, 'CPN')"
                    >
                      <b-input-group-text>
                        <feather-icon icon="ListIcon" />
                      </b-input-group-text>
                    </b-input-group-append> -->
                </template>
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
      </template>
      <template v-else>
        <!-- SSN -->
        <b-col md="6" lg="3">
          <validation-provider
            v-slot="{ errors }"
            :rules="ssnActive ? 'required' : ''"
            name="SSN"
          >
            <b-form-group label-for="ssn">
              <template #label>
                <span
                  class="font-ce d-flex justify-content-between align-items-center"
                >
                  SSN
                  <b-checkbox
                    v-model="ssnActive"
                    switch
                    @change="changeSSNStatus"
                  >
                  </b-checkbox>
                </span>
              </template>

              <b-form-input
                id="ssn"
                v-model="userData.ssn"
                autofocus
                trim
                maxlength="11"
                class="font-ce"
                :state="errors[0] ? false : null"
                :disabled="!ssnActive"
                v-mask="'###-##-####'"
              />
              <span v-if="errors[0]" class="text-danger font-ce">{{
                `SSN ${errors[0]}`
              }}</span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- ITIN -->
        <b-col md="6" lg="3">
          <validation-provider
            v-slot="{ errors }"
            :rules="itinActive ? 'required' : ''"
            name="ITIN"
          >
            <b-form-group label-for="itin">
              <template #label>
                <span
                  class="font-ce d-flex justify-content-between align-items-center"
                >
                  ITIN
                  <b-checkbox
                    v-model="itinActive"
                    switch
                    @change="changeITINStatus"
                  >
                  </b-checkbox>
                </span>
              </template>
              <b-form-input
                id="itin"
                v-model="userData.itin"
                autofocus
                trim
                class="font-ce"
                :state="errors[0] ? false : null"
                :disabled="!itinActive"
                maxlength="11"
                v-mask="'###-##-####'"
              />
              <span v-if="errors[0]" class="text-danger font-ce">{{
                `ITIN ${errors[0]}`
              }}</span>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- CPN -->
        <b-col md="6" lg="3">
          <validation-provider
            v-slot="{ errors }"
            :rules="cpnActive ? 'required' : ''"
            name="CPN"
          >
            <b-form-group label-for="cpn">
              <template #label>
                <span
                  class="font-ce d-flex justify-content-between align-items-center"
                >
                  CPN
                  <b-checkbox
                    v-model="cpnActive"
                    switch
                    @change="changeCPNStatus"
                  >
                  </b-checkbox>
                </span>
              </template>
              <b-form-input
                id="cpn"
                v-model="userData.other"
                autofocus
                :disabled="!cpnActive"
                trim
                class="font-ce"
                :state="errors[0] ? false : null"
                maxlength="11"
                v-mask="'###-##-####'"
              />
              <span v-if="errors[0]" class="text-danger font-ce">{{
                `CPN ${errors[0]}`
              }}</span>
            </b-form-group>
          </validation-provider>
        </b-col>
      </template>
      <!-- Foreign ID -->
      <b-col md="6" lg="3">
        <validation-provider name="Foreign ID">
          <b-form-group label-for="foreign-id">
            <template #label>
              <span class="font-ce"> Foreign ID </span>
            </template>
            <b-form-input
              id="foreign-id"
              v-model="userData.foreign_id"
              autofocus
              trim
              maxlength="10"
              class="font-ce"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Lead State -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Legal Status"
          rules="required"
        >
          <b-form-group
            label-for="state-lead"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce"> Legal Status</span>
            </template>
            <v-select
              input-id="state-lead"
              v-model="userData.state_lead"
              :options="G_STATE_LEADS"
              :clearable="false"
              :reduce="(el) => el.id"
              label="label"
              class="font-ce"
              :disabled="disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Legal Status ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Marital Status -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Marital Status"
          rules="required"
        >
          <b-form-group
            label="Marital Status"
            label-for="marital-status"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce"> Marital Status </span>
            </template>
            <v-select
              v-model="userData.marital_status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="maritalStatusOptions"
              :clearable="false"
              input-id="marital-status"
              class="font-ce"
              :reduce="(el) => el.value"
              :disabled="disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Marital Status ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Work status -->
      <b-col md="6" lg="3">
        <b-form-group label-for="work-status" v-if="moduleId == 2">
          <template #label>
            <span class="font-ce"> Work Status </span>
          </template>
          <v-select
            input-id="work-status"
            v-model="userData.work_status"
            class="font-ce"
            :reduce="(value) => value.value"
            :options="workStatus.options"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";

export default {
  watch: {},
  mounted() {},
  components: {
    flatPickr,
  },
  props: {
    disabledForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    userData: {
      type: Object,
      required: true,
    },
    blankUserFields: {
      type: Object,
      required: false,
    },
    typeForm: {
      type: String,
      required: false, // newLead, editLead
    },
  },
  data() {
    return {
      blankUserData: new Object(),
      disabled: {
        ssn: true,
        itin: true,
        other: true,
        phone: true,
        mobile: true,
        saveSSN: false,
        saveITIN: false,
      },
      ssnActive: true,
      itinActive: false,
      cpnActive: false,
      genderOptions: [
        { label: "FEMALE", value: 1 },
        { label: "MALE", value: 2 },
      ],
      maritalStatusOptions: [
        { label: "SINGLE", value: 1 },
        { label: "MARRIED", value: 2 },
        { label: "DIVORCED", value: 3 },
        { label: "SEPARATED", value: 4 },
        { label: "WIDOWED", value: 5 },
      ],
      programsList: [],
      workStatus: {
        options: [
          {
            label: "EMPLOYED",
            value: 1,
          },
          {
            label: "SELF EMPLOYED",
            value: 2,
          },
          {
            label: "RETIRED",
            value: 3,
          },
          {
            label: "STUDENT",
            value: 4,
          },
          {
            label: "DISABILITY",
            value: 5,
          },
          {
            label: "UNEMPLOYED",
            value: 6,
          },
        ],
      },
    };
  },
  computed: {
    encryptedSSN() {
      return this.encryptDocument(this.userData.ssn);
    },
    encryptedITIN() {
      return this.encryptDocument(this.userData.itin);
    },
    encryptedCPN() {
      return this.encryptDocument(this.userData.other);
    },
    ...mapGetters({
      G_LANGUAGES: "CrmGlobalStore/G_LANGUAGES",
      G_STATE_LEADS: "CrmLeadStore/G_STATE_LEADS",
      G_COUNTRIES: "CrmGlobalStore/G_COUNTRIES",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ssnResult() {
      return this.ssnActive || this.itinActive || this.cpnActive;
    },
  },
  created() {
    this.formatInitialData();
    this.setDataBlank("userData");
  },
  methods: {
    setDataBlank(key) {
      this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`] =
        Object.assign({}, this[key]);
    },
    async onSubmitFields(key, typee, subkey) {
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            this.isPreloading(true);
            const response = await this.A_UPDATE_FIELDS_LEAD({
              ...this.blankUserFields,
              typee,
              [key]: this.userData[subkey]
                ? this.userData[subkey]
                : this.userData[key],
            });

            if (this.userData[subkey]) {
              key = subkey;
            }
            this.isPreloading(false);

            if (this.isResponseSuccess(response)) {
              this.blankUserData[key] = this.userData[key];

              this.toggleElement(key);

              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                "Something went wrong." + response.message
              );
          }
        })
        .catch((error) => {
          console.log("Something went wrong onSubmitFields: ", error);
          this.isPreloading(false);
          this.showErrorSwal();
        });
    },
    ...mapActions({
      A_VALID_UNIQUE_SSN: "CrmLeadStore/A_VALID_UNIQUE_SSN",
      A_VALID_UNIQUE_ITIN: "CrmLeadStore/A_VALID_UNIQUE_ITIN",
      A_VALID_UNIQUE_CPN: "CrmLeadStore/A_VALID_UNIQUE_CPN",
      A_UPDATE_FIELDS_LEAD: "CrmLeadStore/A_UPDATE_FIELDS_LEAD",
    }),
    resetElement(key, subkey) {
      const object = this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`];
      this[key][subkey] = object[subkey];
    },
    toggleElement(key) {
      this.disabled[key] = !this.disabled[key];
      if (this.disabled[key]) this.resetElement("userData", key);
    },
    onModalTrackingChangeOpen(type, name) {
      this.$emit("onModalTrackingChangeOpen", {
        type,
        name,
        mapFunction: (el) => ({
          ...el,
          main_row: el.fields,
          main_row_hide: el.fields_secret,
          seeHideCell: false,
        }),
      });
    },
    async onValidSsn(event) {
      try {
        if (event.code == "KeyC" || event.code == "ControlLeft") return true;
        this.$refs.ssn.$el.classList.remove("is-invalid");
        this.disabled.saveSSN = false;
        if (this.userData.ssn.length === 11) {
          this.isPreloading();
          const response = await this.A_VALID_UNIQUE_SSN({
            ssn: this.userData.ssn,
          });
          if (this.isResponseSuccess(response)) {
            if (response.data.code === "ssn") {
              this.disabled.saveSSN = true;
              this.$refs.ssn.$el.classList.remove("is-valid");
              this.$refs.ssn.$el.classList.add("is-invalid");
              this.showWarningSwal(
                "Alert!",
                "The ssn number already exists: " + response.data.message
              );
            }
          }
          this.isPreloading(false);
        }
      } catch (error) {
        console.log("Something went wrong onValidSsn: ", error);
        this.isPreloading(false);
        this.showErrorSwal();
      }
    },
    async onValidItin(event) {
      try {
        if (event.code == "KeyC" || event.code == "ControlLeft") return true;
        this.$refs.itin.$el.classList.remove("is-invalid");
        this.disabled.saveITIN = false;
        if (this.userData.itin.length === 11) {
          this.isPreloading(true);
          const response = await this.A_VALID_UNIQUE_ITIN({
            itin: this.userData.itin,
          });
          if (this.isResponseSuccess(response)) {
            if (response.data.code === "itin") {
              this.disabled.saveITIN = true;
              this.$refs.itin.$el.classList.remove("is-valid");
              this.$refs.itin.$el.classList.add("is-invalid");
              this.showWarningSwal(
                "Alert!",
                "The itin number already exists: " + response.data.message
              );
            }
          }
          this.isPreloading(false);
        }
      } catch (error) {
        console.log("Something went wrong onValidSsn: ", error);
        this.isPreloading(false);
        this.showErrorSwal();
      }
    },
    async onValidCpn(event) {
      try {
        if (event.code == "KeyC" || event.code == "ControlLeft") return true;
        this.$refs.cpn.$el.classList.remove("is-invalid");
        this.disabled.saveITIN = false;
        if (this.userData.other.length === 11) {
          this.isPreloading(true);
          const response = await this.A_VALID_UNIQUE_CPN({
            other: this.userData.other,
          });
          if (this.isResponseSuccess(response)) {
            if (response.data.code === "cpn") {
              this.disabled.saveITIN = true;
              this.$refs.cpn.$el.classList.remove("is-valid");
              this.$refs.cpn.$el.classList.add("is-invalid");
              this.showWarningSwal(
                "Alert!",
                "The cpn number already exists: " + response.data.message
              );
            }
          }
          this.isPreloading(false);
        }
      } catch (error) {
        console.log("Something went wrong onValidSsn: ", error);
        this.isPreloading(false);
        this.showErrorSwal();
      }
    },
    encryptDocument(document) {
      return document ? `xxx-xx-${document.substr(7)}` : "";
    },
    showWarningToastSSN() {
      this.showToast(
        "warning",
        "top-right",
        "Warning!",
        "AlertTriangleIcon",
        "At least one must be active"
      );
    },
    changeSSNStatus() {
      // if (!this.ssnResult) {
      //   this.ssnActive = true;
      //   this.userData.ssn = null;
      //   this.showWarningToastSSN();
      //   return;
      // }
      if (!this.ssnActive) {
        this.userData.ssn = null;
      }
    },
    changeITINStatus() {
      // if (!this.ssnResult) {
      //   this.itinActive = true;
      //   this.userData.itin = null;
      //   this.showWarningToastSSN();
      //   return;
      // }
      if (!this.itinActive) {
        this.userData.itin = null;
      }
    },
    changeCPNStatus() {
      // if (!this.ssnResult) {
      //   this.cpnActive = true;
      //   this.userData.other = null;
      //   this.showWarningToastSSN();
      //   return;
      // }
      if (!this.cpnActive) {
        this.userData.other = null;
      }
    },
    capitalize(el) {
      const element = this.userData[el];
      this.userData[el] =
        element.substr(0, 1).toUpperCase() + element.substr(1);
    },
    formatInitialData() {
      this.userData.dob = this.userData.dob
        ? moment(this.userData.dob).format("MM/DD/YYYY")
        : "";
      this.programsList = this.userData.programs;
    },
    returnProgramlist() {
      return this.programsList;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl {
  width: 90rem;
}
input.k-input {
  padding: 0.5rem 1rem !important;
  height: auto !important;
}
.k-select {
  background-color: transparent !important;
  background-image: none !important;
}
</style>
<style scoped>
* {
  font-family: "Montserrat", sans-serif !important;
}
.card-title h4 {
  font-size: 22px;
}
.font-ce {
  font-size: 16px;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}
.see-tracking {
  cursor: pointer;
  color: rgb(0, 183, 255);
  transition: text-decoration 0.1s, font-weight 0.1s;
}
.see-tracking:hover {
  text-decoration: underline;
  font-weight: bold;
}
</style>
