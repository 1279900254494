<template>
  <div>
    <b-modal
      ref="checks-tracking-modal"
      modal-class="custom-modal-amg"
      title-class="h3 text-white"
      :title="title"
      size="lg"
      header-bg-variant="transparent"
      header-class="p-0"
      @hidden="closeModal()"
      centered
      hide-footer
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>{{ title }}</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModal()"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <b-row class="client-info">
        <!-- Client -->
        <b-col cols="6" class="p-0">
          <span class="mr-1 mb-1">Pay to</span>
          <div
            class="mr-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>{{ getPayTo }}</p>
          </div>
        </b-col>
        <b-col cols="6" class="p-0">
          <span class="ml-1 mb-1">Memo</span>
          <div
            class="ml-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>{{ getMemo }}</p>
          </div>
        </b-col>
      </b-row>
      <div>
        <b-table
          ref="checks-tracking-table"
          id="checks-tracking-table"
          sticky-header="70vh"
          small
          show-empty
          responsive="sm"
          :items="items"
          :fields="filteredFields"
          class="position-relative table-new-customization"
          :class="['text-center']"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(created_at)="{ item }">
            <span>{{ item.created_at | myGlobalDay }}</span>
          </template>
          <template #cell(status_name)="{ item }">
            <span
              v-if="item.type_tracking == 1 || item.type_tracking == 3"
              :class="getProcess(item, 'color')"
              style="font-weight: bold"
              >{{ getProcess(item, "data") }}</span
            >
            <span
              v-if="item.type_tracking == 2"
              :class="colorTextStatusCheck(item)"
              style="font-weight: bold"
            >
              {{ statusCheckText(item) }}
            </span>
          </template>
          <template #cell(created_by_name)="{ item }">
            <span>{{
              item.created_by_name == "Paragon "
                ? "System"
                : item.created_by_name
            }}</span>
          </template>
          <template #cell(details)="{ item }">
            <div v-if="item.status_id == 9">
              <b-row>
                <b-col cols="12"
                  ><strong>#: </strong>{{ item.check_number }}</b-col
                >
              </b-row>
              <b-row v-if="dataRow.check_path">
                <b-col cols="12"
                  ><strong>Show: </strong
                  ><a :href="dataRow.check_path" target="_blank">
                    <amg-icon
                      class="cursor-pointer text-danger"
                      icon="FilePdfIcon"
                      size="18"
                    /> </a
                ></b-col>
              </b-row>
            </div>
            <div v-else-if="item.status_id == 6">
              <b-row>
                <b-col cols="12"
                  ><strong>Type shipping: </strong>
                  <span :class="item.type_shipping == 'regular' ? 'text-info' : 'text-warning'">
                    {{ item.type_shipping.toUpperCase() }}
                  </span>
                </b-col>
              </b-row>
              <b-row v-if="item.type_shipping == 'overnight'">
                <b-col cols="12"
                  ><strong>Tracking #: </strong
                  >{{ item.tracking_number }}</b-col
                >
              </b-row>
              <b-row
                v-if="
                  dataRow.evidence_path && item.type_shipping == 'overnight'
                "
              >
                <b-col cols="12"
                  ><strong>Evidence: </strong
                  ><a :href="dataRow.evidence_path" target="_blank">
                    <feather-icon
                      class="cursor-pointer text-danger"
                      icon="FileIcon"
                      size="18"
                    /> </a
                ></b-col>
              </b-row>
            </div>
            <div v-else-if="item.status_id == 7">
              <b-row>
                <b-col cols="12"
                  ><strong>Collect date: </strong
                  >{{ dataRow.collect_date | myGlobal }}</b-col
                >
              </b-row>
              <b-row v-if="dataRow.collect_path">
                <b-col cols="12"
                  ><strong>Evidence: </strong
                  ><a :href="dataRow.collect_path" target="_blank">
                    <feather-icon
                      class="cursor-pointer text-danger"
                      icon="FileIcon"
                      size="18"
                    /> </a
                ></b-col>
              </b-row>
            </div>
            <div v-else-if="[3, 8, 10].includes(item.status_id)">
              <b-row>
                <b-col cols="12"
                  ><strong>Observation: </strong>{{ item.observation }}</b-col
                >
              </b-row>
            </div>
            <div v-else-if="item.type_tracking == 1">
              <b-row>
                <b-col cols="12">{{ item.comment }}</b-col>
              </b-row>
            </div>
            <div v-else-if="item.type_tracking == 3">
              <b-row v-if="item.tif">
                <b-col cols="12"
                  ><strong>PIF: </strong
                  ><a :href="item.tif" target="_blank">
                    <feather-icon
                      class="cursor-pointer text-danger"
                      icon="FileIcon"
                      size="18"
                    /> </a
                ></b-col>
              </b-row>
            </div>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DashboardService from "@/views/ceo/service-dashboard/dashboard.service.js";
export default {
  name: "ChecksTrackingModal",
  props: {
    dataRow: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      filter: [],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      toPage: null,
      items: [],
      isBusy: false,
      fields: [
        {
          key: "created_by_name",
          label: "Created by",
          visible: true,
        },
        {
          key: "status_name",
          label: "Status",
          visible: true,
        },
        {
          key: "created_at",
          label: "date/time",
          visible: true,
        },
        {
          key: "details",
          label: "Details",
          visible: true,
          class: "text-left",
        },
      ],
      totalRows: null,
      previewItems: [],
    };
  },
  computed: {
    title() {
      return `${this.dataRow.method || "CHECK"} TRACKING`;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.route.matched[0].meta.module;
    },

    namePath() {
      return this.route.name;
    },

    filteredFields() {
      return this.fields.filter((field) => field.visible);
    },

    programId() {
      return this.moduleId == 2 ? 0 : this.route.matched[1].meta.program;
    },
    getPayTo() {
      const firstCheckWithVendor = this.previewItems.find(
        (check) => check.vendor
      );
      return firstCheckWithVendor ? firstCheckWithVendor.vendor : null;
    },
    getMemo() {
      const firstCheckWithVendor = this.previewItems.find(
        (check) => check.concept
      );
      return firstCheckWithVendor ? firstCheckWithVendor.concept : null;
    },
  },
  watch: {},
  async mounted() {
    await this.getChecksTracking();
    this.toggleModal("checks-tracking-modal");
  },
  async created() {},
  methods: {
    getProcess(item, type = "color") {
      if (item.type_tracking === 1) {
        switch (item.status) {
          case "PENDING":
            if (item.process === "VERIFICATION" && item.comment === "PENDING") {
              return type === "data" ? "CREATE SETTLEMENT" : "text-secondary";
            } else if (
              item.process === "VERIFICATION" &&
              item.comment != "PENDING"
            ) {
              return type == "data" ? "ACCEPT SETTLEMENT" : "text-warning";
            } else if (item.process === "UNDERVIEW") {
              return type == "data" ? "AUTHORIZE SETTLEMENT" : "text-primary";
            }
            break;
          case "REJECTED":
            return type == "data" ? "REJECTED SETTLEMENT" : "text-danger";
          case "APPROVED":
            return type == "data" ? "APPROVE SETTLEMENT" : "text-success";
        }
      } else {
        switch (item.status_num) {
          case 1:
            return type == "data" ? "GENERATE CHECK" : "text-secondary";
          case 2:
            return type == "data" ? item.status : "text-warning";
          case 3:
            return type == "data" ? item.status : "text-danger";
          case 4:
            return type == "data" ? item.status : "text-primary";
          case 5:
            return type == "data" ? item.status : "text-info";
          case 6:
            return type == "data" ? item.status : "text-success";
          case 7:
            return type == "data" ? item.status : "text-primary";
          case 8:
            return type == "data" ? item.status : "text-info";
          default:
            break;
        }
      }
    },
    colorTextStatusCheck(item) {
      switch (item.status_name) {
        case "PENDING":
          return "text-warning";
        case "SEND TO COURIER":
          return "text-primary";
        case "SEND TO CORRESPONDENCE":
          return "text-info";
        case "REJECTED":
          return "text-danger";
        case "COLLECTED":
          return "text-success";
        case "PENDING COLLECT":
          return "text-warning";
        case "PAYMENT MADE":
          return "text-success";
        default:
          return "text-warning";
      }
    },
    statusCheckText(item) {
      switch (item.status_name) {
        case "PENDING":
          return "IN CEO";
        case "SEND TO CORRESPONDENCE":
          return "IN CORRESPONDENCE";
        case "SEND TO COURIER":
          return "IN COURIER";
        default:
          return item.status_name;
      }
    },
    async getChecksTracking() {
      try {
        this.addPreloader();
        const data = await DashboardService.getChecksTracking({
          check_id: this.dataRow.check_id,
        });
        this.previewItems = data;
        const filterPendingReview = data.filter(
          (item) => item.status_name != "PENDING REVIEW"
        );
        this.items = filterPendingReview || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `key`);
      field.visible = bool;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
