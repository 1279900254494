<template>
  <validation-observer ref="reschedule">
    <b-row>
      <b-col>
        <validation-provider v-slot="{ errors }" name="date" rules="required">
          <b-form-group
            label-class="font-weight-bolder"
            label-for="input-horizontal"
            label="Date:"
          >
            <kendo-datepicker
              id="contactDate"
              v-model="date.date_event"
              v-mask="'##/##/####'"
              :format="'MM/dd/yyyy'"
              :class="{ 'border-danger': errors[0] }"
              class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col v-if="showHour">
        <validation-provider v-slot="{ errors }" name="hour" rules="required">
          <b-form-group
            id="contactHour"
            label-class="font-weight-bolder"
            label-for="input-horizontal"
            label="To:"
          >
            <template #label>
              <div class="d-flex justify-content-between">
                <span>Hour:</span>
                <tabler-icon
                  :id="'hours-target'"
                  class="text-warning cursor-pointer"
                  size="15"
                  icon="QuestionMarkIcon"
                />

                <b-tooltip
                  v-if="hoursToSee"
                  custom-class="ga"
                  :target="'hours-target'"
                  triggers="hover"
                  placement="top"
                >
                  <div>
                    <h5 class="font-weight-bolder">Office Hours</h5>

                    <span v-for="(item, i) in hoursToSee" :key="i">
                      <span
                        class="font-weight-bolder text-justify"
                        style="max-width: 1px !important"
                        >{{ item.day }}
                      </span>
                      <span
                        class="text-justify"
                        style="max-width: 10px !important"
                      >
                        {{
                          ": From: " +
                          (item.from ? item.from : "00:00") +
                          " To: " +
                          (item.to ? item.to : "23:59")
                        }}</span
                      >
                      <br />
                    </span>
                  </div>
                </b-tooltip>
              </div>
            </template>
            <kendo-timepicker
              v-model="date.hour_event"
              :format="'HH:mm'"
              minutes-step="30"
              class="w-100 rounded bg-transparent k-picker-custom"
              style="height: 2.73rem"
              :class="{ 'border-danger': errors[0] }"
              placeholder="Select hour"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button variant="primary" class="float-right" @click="submit()">
          <feather-icon icon="SaveIcon" class="text-white" size="15" />
          Save
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import moment from "moment";
import OfficeHoursService from "@/views/ce-digital/sub-modules/settings/views/office-hours/service/office-hours.service";
import NotesServices from "@/views/commons/components/first-notes/services/notes.service";
import { mapGetters } from "vuex";
export default {
  props: {
    alert: {
      type: Boolean,
      default: true,
    },
    showHour: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      date: {
        date_event: null,
        hour_event: null,
      },
      day: {
        name: null,
        number: null,
      },
      hoursToSee: null,
      fromHourS: null,
      toHourS: null,
      newHourS: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async officeHoursPerDay() {
      try {
        const result = await OfficeHoursService.getOfficeHoursPerDay();
        if (result.status === 200) {
          this.OfficeHoursPerDay = result.data;
          this.isBusy = true;
          this.hoursToSee = result.data;
          setTimeout(() => {
            this.isBusy = false;
          }, 900);
        }
      } catch (error) {
        throw error;
      }
    },
    // Convert new hours to seconds
    validateHour(newValue) {
      const newHour = moment(newValue, "HH:mm:ss").format("HH:mm");
      this.newHourS = moment.duration(newHour, "seconds").asSeconds();
    },
    // Validate input date by day
    async validateDate(newValue) {
      const now = moment(newValue).format("dddd");
      switch (now) {
        case "Sunday":
          this.day.name = "Sunday";
          this.day.number = 1;

          break;
        case "Monday":
          this.day.name = "Monday";
          this.day.number = 2;

          break;
        case "Tuesday":
          this.day.name = "Tuesday";
          this.day.number = 3;

          break;
        case "Wednesday":
          this.day.name = "Wednesday";
          this.day.number = 4;

          break;
        case "Thursday":
          this.day.name = "Thursday";
          this.day.number = 5;

          break;
        case "Friday":
          this.day.name = "Friday";
          this.day.number = 6;

          break;
        case "Saturday":
          this.day.name = "Saturday";
          this.day.number = 7;

          break;
      }
      // Check time of day
      const resultDay = await NotesServices.validateDateAndHour({
        days: this.day.number,
      });
      if (resultDay) {
        const { from, to } = resultDay[0];
        // Convert to hours
        const fromHour = moment(from, "HH:mm:ss").format("HH:mm");
        const toHour = moment(to, "HH:mm:ss").format("HH:mm");
        // Convert hours to seconds
        this.fromHourS = moment.duration(fromHour, "seconds").asSeconds();
        this.toHourS = moment.duration(toHour, "seconds").asSeconds();
      }
    },
    async submit() {
      try {
        const validate = await this.$refs.reschedule.validate();
        if (validate) {
          if (
            (this.newHourS >= this.fromHourS &&
              this.newHourS <= this.toHourS) ||
            !this.showHour
          ) {
            if (!this.alert) {
              this.$emit("submitSave", this.date);
              return;
            }
            const confirm = await this.showConfirmSwal(
              "Please confirm rescheduling"
            );
            if (confirm.value) {
              this.$emit("submitSave", this.date);
            }
          } else {
            this.showWarningSwal(
              "IMPORTANT!",
              "The time is not in the available"
            );
          }
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
  watch: {
    "date.date_event": {
      async handler(newVal) {
        if (newVal) {
          this.validateDate(newVal);
        }
      },
      deep: true,
    },
    "date.hour_event": {
      async handler(newVal) {
        if (newVal) {
          this.validateHour(newVal);
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.officeHoursPerDay();
  },
};
</script>

<style></style>
