<template>
  <div>
    <b-modal
      ref="modal-add-attorney"
      :title="attorneyId ? 'EDIT ATTORNEY' : 'CREATE NEW ATTORNEY'"
      title-tag="h3"
      modal-class="modal-primary"
      no-close-on-backdrop
      @hidden="close"
    >
      <validation-observer ref="form">
        <b-row>
          <b-col cols="6">
            <b-form-group label="NAME">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="dataSettingsAttorneyTimes.name"
                  maxlength="100"
                />
                <span v-if="errors[0]" class="text-danger mt-1"
                  >Name {{ errors[0] }}</span
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="PHONE NUMBER">
              <validation-provider v-slot="{ errors }" rules="min:14|max:14">
                <b-form-input
                  v-mask="'(###) ###-####'"
                  trim
                  :state="errors.length > 0 ? false : null"
                  v-model="dataSettingsAttorneyTimes.number"
                />
                <span v-if="errors[0]" class="text-danger mt-1"
                  >Phone Number {{ errors[0] }}</span
                >
              </validation-provider>
            </b-form-group>
          </b-col>

              <input
                type="hidden"
                :icon="passwordToggleIcon"
                v-model="dataSettingsAttorneyTimes.status"
              />
        </b-row>
      </validation-observer>
      <template #modal-footer="{ cancel }">
        <button-cancel @click="cancel()" />
        <button-save @click="save()" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import SettingsServices from "@/views/debt-solution/views/settings/views/manageCI/services/Settings.service.js";
import { mapGetters } from "vuex";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import buttonSave from "@/views/commons/utilities/ButtonSave.vue";
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue";
// import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: {
    buttonSave,
    buttonCancel,
    // VueGoogleAutocomplete,
  },
  mixins: [togglePasswordVisibility],
  props: {
    attorneyId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dataSettingsAttorneyTimes: {
        id: "",
        name: "",
        number: "",
        status: "",
      },
    };
  },
  async mounted() {
    this.toggleModal("modal-add-attorney");
    if (this.attorneyId) {
      this.getAttorneyManagement();
      this.dataSettingsAttorneyTimes.id = this.attorneyId;
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async save() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const { data } = await SettingsServices.saveNewAttorneyManagement(
            this.dataSettingsAttorneyTimes,
          );
          this.close();
          this.$emit("closing");
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            `Attorney saved successfully`
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async getAttorneyManagement() {
      try {
        const { data } = await SettingsServices.getAttorneyManagementById({
          id: this.attorneyId,
        });
        this.dataSettingsAttorneyTimes.number = data[0].number;
        this.dataSettingsAttorneyTimes.name = data[0].name;
        this.dataSettingsAttorneyTimes.status = data[0].status;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

  },
};
</script>
<style scoped>
.select__error {
  border: 1px solid #f1556c !important;
  border-radius: 5px;
}
</style>