import { nestApi } from "@/service/axios";

class SmsService {

    prefix = '/ringcentral';

    async getChats(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/chats/search`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getChats:", error);
            throw error;
        }
    }

    async findChat(id) {
        try {
            const data = await nestApi.get(`${this.prefix}/chats/${id}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getChats:", error);
            throw error;
        }
    }


    async createChat(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/chats`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on createChat:", error);
            throw error;
        }
    }

    async getMessages(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/messages/by-chat`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getMessages:", error);
            throw error;
        }
    }

    async searchMessages(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/messages/search`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on searchMessages:", error);
            throw error;
        }
    }

    async findMessages(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/messages/find`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on findMessages:", error);
            throw error;
        }
    }

    async updateMessage(id, payload) {
        try {
            const data = await nestApi.patch(`${this.prefix}/messages/${id}`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on updateMessage:", error);
            throw error;
        }
    }

    async asignImportantMessage(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/messages/asign-important`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on asignImportantMessage:", error);
            throw error;
        }
    }

    async updateChat(id, payload) {
        try {
            const data = await nestApi.patch(`${this.prefix}/chats/${id}`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on updateChat:", error);
            throw error;
        }
    }

    async getAllFiles(id) {
        try {
            const data = await nestApi.get(`${this.prefix}/chats/${id}/files`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getAllFiles:", error);
            throw error;
        }
    }

    async readAllByChat(id, payload) {
        try {
            const data = await nestApi.patch(`${this.prefix}/chats/${id}/messages/read-all`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on readAllByChat:", error);
            throw error;
        }
    }

    async getCounterUnReadMessages(phoneNumber) {
        try {
            const data = await nestApi.get(`${this.prefix}/chats/unread/${phoneNumber}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getCounterUnReadMessages:", error);
            throw error;
        }
    }

    async getClientChat(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/chats/client`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getClientChat:", error);
            throw error;
        }
    }

    async getAnswerByMessageId(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/messages/topic/message`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getAnswerByMessageId:", error);
            throw error;
        }
    }

    async getAllMessagesUnread() {
        try {
            const data = await nestApi.get(`${this.prefix}/chats/unread-all`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getAllMessagesUnread:", error);
            throw error;
        }
    }

    async getAllMessagesUnreadGroup() {
        try {
            const data = await nestApi.get(`${this.prefix}/chats/unread-all-group`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getAllMessagesUnreadGroup:", error);
            throw error;
        }
    }

    async getAllMessagesUnreadByLeadPhone(payload) {
        try {
            const data = await nestApi.post(`${this.prefix}/chats/unread-lead`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getAllMessagesUnreadByLeadPhone:", error);
            throw error;
        }
    }


    async getAllMessagesUnreadByCredentialId(credentialId) {
        try {
            const data = await nestApi.get(`${this.prefix}/messages/credential/${credentialId}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getAllMessagesUnreadGroup:", error);
            throw error;
        }
    }
}

export default new SmsService();
