import store from "@/store";
import Notifications from "@/service/notification/index";
import LettersService from "@/views/correspondence/views/letters/services/letters.services";
import ScheduleService from "@/views/commons/components/schedules/services/schedules.service";
import employeeClaims from "@/views/commons/commons-counter";
import SendingToCReditorService from "@/views/correspondence/views/sending-creditors/service/sending-creditors.service.js";
import RequestService from "@/views/commons/components/request/services/request.service.js";
import RecruitmentService from "@/views/commons/components/recruitment-process/services/recruiment.service";
import LettersToClientsService from "./views/letters-to-clients/service/lettersToClients.service";

const countLetterToClient = async () => {
  const response = await LettersToClientsService.contLetterClient();
  const item = response.data[0];
  if (Object.keys(item).length > 0) {
    const payload = {
      routeName: "correspondence-letters-to-clients",
      tag: item.cont,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const countLetter = async () => {
  const params = {
    id: store.state.auth.currentUser.user_id,
    modul: 9,
    program: 3,
    role: store.state.auth.currentUser.role_id,
    type: 2,
  };
  const response = await LettersService.counterLetter(params);
  if (response.length > 0) {
    const payload = {
      routeName: "correspondence-letters",
      tag: response[0].cont,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const schedulesCounter = async () => {
  const params = {
    moduleid: 9,
  };
  const data = await ScheduleService.getSchedulesCounter(params);
  if (data) {
    const payload = {
      routeName: "correspondence-schedules",
      tag: data.grandTotal > 99 ? "99+" : data.grandTotal,
    };
    store.dispatch("SchedulesStore/A_SET_COUNTERS_TABS_SCHEDULES", data);
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const sendingToCreditorCounter = async () => {
  const { data } = await SendingToCReditorService.counterSendingToCreditors();
  if (data) {
    const payload = {
      routeName: "correspondence-sending-creditors",
      tag: data > 99 ? "99+" : data,
    };
    store.dispatch(
      "SendingToCreditorsStore/A_SET_COUNTER_SENDING_TO_CREDITORS",
      data > 99 ? "99+" : data
    );
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: "request-correspondence",
    tag: data[0].counter,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "correspondence-projects",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "correspondence-recruitment-process",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({
    user_id,
    moduleId,
  });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: "correspondence-customer-tickets",
      tag: data[0].v_total_open,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const counterOtherPayments = async () => {
  await store.dispatch("other-payment-store/A_COUNTER_OP", {
    module_id: store.state.auth.currentUser.modul_id,
  });
  const payload = {
    routeName: "other-payments-correspondence",
    tag: store.getters["other-payment-store/G_SUM_COUNTER_OP"],
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const counterChecks = async () => {
  try {
    await store.dispatch("CheckStore/A_COUNTER_CHECK");
    
    const counter = store.getters["CheckStore/G_COUNTER_CHECK"];
    
    const counterNew = counter.find(item => item.name_status === "SEND TO CORRESPONDENCE");
    
    if (counterNew) {
      const payload = {
        routeName: "correspondence-checks",
        tag: counterNew.count,
      };
      
      store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
    } else {
      console.warn('No counter item with the status "SEND TO CORRESPONDENCE" found.');
    }
  } catch (error) {
    console.error('An error occurred while checking the counter:', error);
  }
};


const allCountersCorrespondence = async () => {
  Promise.all([
    countLetterToClient(),
    projectsCounter(),
    countLetter(),
    schedulesCounter(),
    counterOtherPayments(),
    employeeClaims("correspondence-employee-claims"),
    sendingToCreditorCounter(),
    requestNotAsignedCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
    counterChecks()
  ]);
};

export default allCountersCorrespondence;
