<template>
  <div
    id="app"
    v-loading.full="loading"
    class="h-100 blue-scrollbar"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <ModalsContainer :modul="currentModul" />

    <scroll-to-top v-if="enableScrollToTop" />

    <modal-welcome-actions ref="modal-welcome" />
    <modal-request-cr
      v-if="modalRequestCredit"
      :dataCreditReport="requestCreditData"
      :subModule="'attend'"
      @hideModal="closeModalRequestCr"
    />
    <AcceptanceAlert
      :key="keyAcceptance"
      @rebuildAcceptance="rebuildAcceptance"
    />
    <AgentAwayOverlay v-if="isBusy" />
    <AlertClaim v-if="IsActiveModalAlertClaim" />
    <AlertAppointment v-if="IsActiveModalAlertAppointment" />
    <SalesMadeNotification v-if="IsActiveModalSalesMadeNotification" />
    <DepartmentExpensesNotification
      v-if="IsActiveModalDepartmentExpensesNotification"
    />
    <AppointmentNotice v-if="IsActiveModalAppointmentNotice" />
    <ModalDetail
      :selectedClaim="G_DetailClaim"
      v-if="G_DetailClaim"
      @close="closeModalDetail"
    />
    <AlertNcr v-if="IsActiveModalAlertNcr && G_DATA_NCR != null" />
    <AlertRc v-if="IsActiveModalAlertRc && G_DATA_RC != null" />

    <AlertWorkPlan v-if="G_IS_ACTIVE_WP && G_DATA_WP != null" />

    <AlertRoundLetters v-if="G_IS_ACTIVE_RL && G_DATA_LETTER_ROUND != null" />

    <AlertSendReport v-if="G_IS_ACTIVE_SP && G_DATA_SEND_REPORT != null" />

    <AlertUpdateRequest v-if="G_IS_ACTIVE_UP && G_DATA_LETTER_UP != null" />

    <AlertReturnedLetter
      v-if="G_IS_ACTIVE_RETURNED_LETTER && G_CLIENT_DATA != null"
    />

    <UserInactivity
      v-if="onScreen"
      @inactivityDetected="inactivityDetected()"
      @activityDetected="activityDetected()"
    />
  </div>
</template>

<script>
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";

// CED Welcome New Client Actions
import { welcomeActionsMixin } from "@/views/ce-digital/sub-modules/customer-service/views/clients/welcome-actions/welcome-actions.mixin";
import ModalWelcomeActions from "@/views/ce-digital/sub-modules/customer-service/views/clients/welcome-actions/ModalWelcomeActions.vue";

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar } from "@vueuse/core";
import { mapGetters, mapActions, mapMutations } from "vuex";
import ModalsContainer from "@/views/commons/components/modals-container/ModalsContainer.vue";
import AcceptanceAlert from "@/views/ce-digital/sub-modules/customer-service/views/auto-alerts/AcceptanceAlert.vue";
import AgentAwayOverlay from "@/views/ce-digital/sub-modules/customer-service/views/agent-away/components/AgentAwayOverlay.vue";
import useAgentAway from "@/views/ce-digital/sub-modules/customer-service/views/agent-away/components/useAgentAway.js";
import AlertClaim from "@/views/commons/components/claims_v2/views/Alert/AlertClaim.vue";
import AlertAppointment from "@/views/commons/components/appointments/view/component/modal/AlertAppointment.vue";
import SalesMadeNotification from "@/views/commons/sales-made/SalesMadeNotification.vue";
import DepartmentExpensesNotification from "@/views/commons/expenses/Notification/DepartmentExpensesNotification.vue";
import AppointmentNotice from "@/views/commons/components/appointments/view/component/modal/AppointmentNotice.vue";
import AlertNcr from "@/views/commons/components/request-ncr/views/Alert/AlertNcr.vue";
import AlertRc from "@/views/commons/components/risky-clients/views/alertRiskClient.vue";
import AlertWorkPlan from "@/views/commons/components/request-workplan/views/components/Alert/AlertWorkPlan.vue";
import AlertRoundLetters from "@/views/commons/components/letters/components/modal/AlertRoundLetters.vue";
import AlertSendReport from "@/views/commons/components/clients/dashboard/options/report-module/modals/AlertSendReport.vue";
import AlertUpdateRequest from "@/views/commons/components/dispute-results/views/components/Alert/AlertUpdateRequest.vue";
import AlertReturnedLetter from "@/views/commons/components/letters/components/modal/AlertReturnedLetter.vue";
import store from "@/store";
const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");
import ModalRequestCr from "@/views/administration/views/ncr-programs/components/modal/CreditReport.vue";
import ModalDetail from "@/views/commons/components/claims_v2/components/Modals/ModalDetail.vue";
import UserLogEvent from "@/router/userLogEvent.js";
import UserInactivity from "@/views/management/views/users-log/components/UserInactivity.vue";
export default {
  components: {
    ModalWelcomeActions,
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    ScrollToTop,
    ModalsContainer,
    AgentAwayOverlay,
    ModalRequestCr,
    AcceptanceAlert,
    AlertClaim,
    AlertAppointment,
    SalesMadeNotification,
    DepartmentExpensesNotification,
    AppointmentNotice,
    ModalDetail,
    AlertNcr,
    AlertRc,
    AlertWorkPlan,
    AlertRoundLetters,
    AlertSendReport,
    AlertUpdateRequest,
    AlertReturnedLetter,
    UserInactivity,
  },

  mixins: [welcomeActionsMixin],
  async mounted() {
    const vm = this;
    this.A_GET_PROGRAMS();
    if (this.skin === "dark") {
      document.querySelector("html").classList.add("dark");
    } else if (document.querySelector("html").className.match("dark")) {
      document.querySelector("html").classList.remove("dark");
    }
    window.addEventListener(
      "load",
      (e) => {
        if (navigator.onLine) {
          vm.changeStatusSession(1);
        }
      },
      false
    );

    window.addEventListener(
      "online",
      (e) => {
        vm.changeStatusSession(1);
      },
      false
    );
    this.loadRegisterLogs();
    await this.$store.dispatch(
      "global-store/A_GET_NEW_PAYMENT_METHOD_DEPLOYED_PROGRAMS"
    );
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    requestCreditData() {
      return this.$store.state["global-store"].requestCreditData;
    },

    modalRequestCredit: {
      get() {
        return this.$store.state["global-store"].modalRequestCredit;
      },
      set(value) {
        this.$store.commit("global-store/SET_MODAL_REQUEST_CREDIT", value);
      },
    },
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    skin() {
      return this.$store.getters["appConfig/skin"];
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    ...mapGetters({
      loading: "app/loading",
      currentUser: "auth/currentUser",
      moduleId: "auth/moduleId",
      G_GET_USER_SESSIONS: "UserStore/G_GET_USER_SESSIONS",
      G_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/G_USER_STATUS_SESSION_SUB_MODULE",
      G_OPEN_MODAL_ACTIONS: "CeDigitalWelcomeActions/G_OPEN_MODAL_ACTIONS",
      statusActionsLettersAlert: "CeDigitalCsAlerts/getStatusAlert",
      statusPreviusLettersAlert: "CeDigitalCsAlerts/getStatusActionsAlert",
      G_TIME_LAST_TOAST: "AutoAlertStore/G_TIME_LAST_TOAST",
      G_SHOW_TOAST: "AutoAlertStore/G_SHOW_TOAST",
      G_SHOW_TOAST_APPOINTMENT: "AlertAppointment/G_SHOW_TOAST",
      IsActiveModalAlertClaim: "AutoAlertClaim/G_IsActive",
      IsActiveModalAlertAppointment: "AlertAppointment/G_IsActive",
      IsActiveModalSalesMadeNotification: "SalesMadeStore/G_IsActive",
      IsActiveModalDepartmentExpensesNotification:
        "DepartmentExpensesAlertStore/G_IsActive",
      IsActiveModalAppointmentNotice: "AlertAppointment/G_IsActiveNotice",
      G_DetailClaim: "AutoAlertClaim/G_DetailClaim",
      IsActiveModalAlertNcr: "AutoAlertNcr/G_IS_ACTIVE",
      G_DATA_NCR: "AutoAlertNcr/G_DATA_NCR",
      IsActiveModalAlertRc: "AutoAlertRc/G_IS_ACTIVE_RC",
      G_DATA_RC: "AutoAlertRc/G_DATA_RC",
      G_IS_ACTIVE_WP: "AutoAlertWorkPlan/G_IS_ACTIVE",
      G_DATA_WP: "AutoAlertWorkPlan/G_DATA_WP",
      G_IS_ACTIVE_RL: "AutoAlertLetterRound/G_IS_ACTIVE",
      G_DATA_LETTER_ROUND: "AutoAlertLetterRound/G_DATA_LETTER_ROUND",
      G_IS_ACTIVE_SP: "AutoAlertSendReport/G_IS_ACTIVE",
      G_DATA_SEND_REPORT: "AutoAlertSendReport/G_DATA_SEND_REPORT",
      G_IS_ACTIVE_UP: "AutoAlertUpdateRequest/G_IS_ACTIVE",
      G_DATA_LETTER_UP: "AutoAlertUpdateRequest/G_DATA_UR",
      G_IS_ACTIVE_RETURNED_LETTER: "AutoAlertReturnedLetters/G_IS_ACTIVE",
      G_CLIENT_DATA: "AutoAlertReturnedLetters/G_CLIENT_DATA",
    }),
    openModalActions() {
      return this.G_OPEN_MODAL_ACTIONS;
    },
    isToast() {
      return this.G_SHOW_TOAST;
    },
    isToastAppointment() {
      return this.G_SHOW_TOAST_APPOINTMENT;
    },
  },
  watch: {
    openModalActions(newVal) {
      if (newVal.bool === true) {
        this.$refs["modal-welcome"].$refs["modal-welcome-actions"].show();
      } else if (newVal.bool === false) {
        this.$refs["modal-welcome"].$refs["modal-welcome-actions"].hide();
      }
    },
    isToast(newVal) {
      if (newVal) {
        this.showToastAlert();
      }
    },
    isToastAppointment(newVal) {
      this.showToastAlert();
    },
  },
  methods: {
    ...mapActions({
      updateCurrentUserModuleRole: "auth/updateCurrentUserModuleRole",
      A_GET_USER_STATUS_SUBMODULE_SESSION:
        "UserStore/A_GET_USER_STATUS_SUBMODULE_SESSION",
      A_CHANGE_USER_STATUS_SESSION: "UserStore/A_CHANGE_USER_STATUS_SESSION",
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
      A_GET_PROGRAMS: "TransactionStatusStore/A_GET_PROGRAMS",
    }),
    ...mapMutations({
      showModalTaskToday: "TaskStore/M_SHOW_TASK_TODAY_MODAL",
      SET_USER_STATUS_SESSION: "UserStore/SET_USER_STATUS_SESSION",
      SET_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/SET_USER_STATUS_SESSION_SUB_MODULE",
    }),
    showToastAlert() {
      this.showToast(
        "warning",
        "top-right",
        "Alerts Available",
        "AlertTriangleIcon",
        "Alerts are available, if you wish to receive change the status to active"
      );
    },
    closeModalRequestCr() {
      this.modalRequestCredit = false;
    },
    changeStatusSession(status) {
      if (!this.currentUser) return;
      if (status === this.G_USER_STATUS_SESSION) return;
      this.SET_USER_STATUS_SESSION([{ status_session: status }]);
      const params = {
        id: this.currentUser.user_id,
        state: status.toString(),
      };
      this.A_CHANGE_USER_STATUS_SESSION(params);
    },
    rebuildAcceptance() {
      this.keyAcceptance = this.keyAcceptance + 1;
    },
    closeModalDetail() {
      this.$store.commit("AutoAlertClaim/M_DetailClaim", null);
    },
    //events user log
    async processUserLogEvent() {
      return;
      //event modal
      this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
        const modal = bvEvent.vueTarget.$refs;
        const componentId = bvEvent.componentId;
        if (modal && modalId === componentId) {
          setTimeout(async () => {
            const modalHeader = modal.header;
            const modalTitle = this.processTitleUserLog(modalHeader.innerText);

            this.currentUserLogComponent = {
              component_type: "modal",
              title: modalTitle ?? null,
              type: 2,
            };
            this.registerComponentUserLog(
              this.currentUserLogComponent,
              "navigation"
            );
          }, 200);
        }
      });

      this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
        this.updateUserLogActivity({ id: this.previousIdUserLog });
        this.resetSessionStorage();
      });
      this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
        this.updateUserLogActivity({ id: this.previousIdUserLog });
        this.resetSessionStorage();
      });

      //event sidebar
      this.$root.$on("bv::collapse::state", async (sidebarId, state) => {
        if (!sidebarId) return;
        const sidebar = document.getElementById(sidebarId);
        if (!sidebar) return;
        const sidebarHeader = sidebar.querySelector("header");
        if (!sidebarHeader) return;

        if (state) {
          const sidebarTitle = this.processTitleUserLog(
            sidebarHeader.innerText
          );

          this.currentUserLogComponent = {
            component_type: "sidebar",
            title: sidebarTitle ?? null,
            type: 2,
          };

          this.registerComponentUserLog(
            this.currentUserLogComponent,
            "navigation"
          );
        }

        if (!state) {
          this.updateUserLogActivity({ id: this.previousIdUserLog });
          this.resetSessionStorage();
        }
      });
    },
    processTitleUserLog(title) {
      let processedTitle = title.trim();
      let parts = processedTitle.split("\n");
      if (parts.length === 0) {
        processedTitle = processedTitle.replace(/\b\w/g, (l) =>
          l.toUpperCase()
        );
      }
      let filteredArray = parts.filter((item) => !item.includes("×"));
      processedTitle = filteredArray[0].replace(/\b\w/g, (l) =>
        l.toUpperCase()
      );
      return processedTitle;
    },
    resetSessionStorage() {
      this.previousIdUserLog = null;
      this.currentUserLogComponent = null;
    },

    triggerUserLogActivityUpdateOnUnload() {
      /* This function is triggered in two cases
        1. When the tab is reloaded or closed
        2. When the browser is closed
      */
      document.addEventListener("beforeunload", () => {
        this.updateUserLogActivity({
          id: sessionStorage.getItem("previousIdUserLogRoute"),
        }); // Update Route
        this.updateUserLogActivity({ id: this.previousIdUserLog }); // Update component
        this.resetSessionStorage();
      });
    },

    triggerUserLogActivityUpdateOnTabActivity() {
      if (this.$route.matched.length > 0 && this.intervalLogs) {
        clearInterval(this.intervalLogs);
      }
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
          this.updateUserLogActivity({
            id: sessionStorage.getItem("previousIdUserLogRoute"),
          }); // Update route user log
          this.updateUserLogActivity({ id: this.previousIdUserLog }); // Update component user log
          this.onScreen = false;
          return;
        }
        this.registerRouteUserLog("tab_change"); // Insert new route user log
        this.registerComponentUserLog(
          this.currentUserLogComponent,
          "tab_change"
        ); // Insert new component user log
        this.onScreen = true;
      });
      this.registerRouteUserLog("navigation");
    },
    updateUserLogActivity(userLog) {
      if (!userLog.id) return;
      UserLogEvent.updateUserLogActivity(userLog);
    },
    async registerComponentUserLog(component, type = "tab_change") {
      /* Create a new entry in users_log for the active component */
      if (!component) return;
      const data = await UserLogEvent[
        type === "navigation"
          ? "processUserLogEvent"
          : "processUserLogEventOnChangeTab"
      ](this.currentUser, this.$route.matched, component);
      this.previousIdUserLog = data.data;
    },
    async registerRouteUserLog(type = "tab_change") {
      /*
        Create a new entry in users log for the active route
      */
      await UserLogEvent[
        type === "navigation"
          ? "processUserLogEvent"
          : "processUserLogEventOnChangeTab"
      ](this.currentUser, this.$route.matched, {}, false);
    },
    loadRegisterLogs() {
      this.intervalLogs = setInterval(() => {
        if (this.$route.matched.length === 0) return;
        this.processUserLogEvent();
        this.triggerUserLogActivityUpdateOnUnload();
        this.triggerUserLogActivityUpdateOnTabActivity();
      }, 1000);
    },
    inactivityDetected() {
      this.updateUserLogActivity({
        id: sessionStorage.getItem("previousIdUserLogRoute"),
        tolerance: true,
      });
      this.updateUserLogActivity({
        id: this.previousIdUserLog,
        tolerance: true,
      });
      UserLogEvent.processRemoveItemSessionStorage();
    },
    activityDetected() {
      this.registerRouteUserLog("tab_change");
      this.registerComponentUserLog(this.currentUserLogComponent, "tab_change");
    },
  },
  data() {
    return {
      currentModul: null,
      keyAcceptance: 0,
      /* User Log related variablers */
      previousIdUserLog: null,
      currentUserLogComponent: null,
      isLoaded: false,
      intervalLogs: false,
      onScreen: true,
    };
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();
    const { enableScrollToTop } = $themeConfig.layout;
    const { isBusy } = useAgentAway();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    store.commit("app/UPDATE_WINDOW_HEIGHT", window.innerHeight);
    const { height: windowHeight } = useWindowSize();
    watch(windowHeight, (val) => {
      store.commit("app/UPDATE_WINDOW_HEIGHT", val);
    });

    return {
      skinClasses,
      enableScrollToTop,
      isBusy,
    };
  },
  destroyed() {
    this.$root.$off("bv::modal::hidden");
    this.$root.$off("bv::modal::hide");
    this.$root.$off("bv::collapse::state");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
