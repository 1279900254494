<template>
  <b-modal
    size="xmd"
    v-model="activeModal"
    title="Details Claim"
    :no-close-on-backdrop="false"
    @hide="closeMe"
    centered
    hide-footer
    content-class="modal-details"
  >
    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="primary"
      :opacity="0.5"
      blur="2px"
      rounded="sm"
    >
      <b-form ref="formSolution">
        <b-row>
          <b-col cols="12" :lg="hasMoreThanOneAccount ? '6' : '4'">
            <span class="title-tag">CLIENT NAME</span>
            <div class="border border-primary rounded">
              <p
                class="text-primary d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ selectedClaim.client }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" :lg="hasMoreThanOneAccount ? '6' : '4'">
            <span class="title-tag">PROGRAM</span>
            <div class="border border-primary rounded">
              <p
                class="text-primary d-flex align-items-center justify-content-center my-1s"
              >
                {{ selectedClaim.program }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" :lg="hasMoreThanOneAccount ? '12' : '4'">
            <span class="title-tag">{{
              hasMoreThanOneAccount ? "ACCOUNTS" : "ACCOUNT"
            }}</span>
            <div class="border border-primary rounded">
              <div
                class="text-primary d-flex align-items-center justify-content-center my-1s"
              >
                <b-row class="d-flex justify-content-center text-center">
                  <b-col
                    v-for="item in JSON.parse(selectedClaim.other_accounts)"
                    :key="item.account"
                  >
                    <b-badge
                      :key="item.account"
                      class="d-inline-flex"
                      :variant="
                        item.account == selectedClaim.account
                          ? 'light-primary'
                          : 'light-info'
                      "
                    >
                      <status-account
                        :account="item"
                        :text="false"
                      ></status-account>
                      <router-link
                        target="_blank"
                        :to="{
                          name: 'quality-client-dashboard',
                          params: {
                            idClient: `${item.id}`,
                          },
                        }"
                        v-if="moduleId == 18"
                        class="text-primary"
                      >
                        {{ item.account }}
                      </router-link>

                      <span v-else>
                        {{ item.account }}
                      </span>
                    </b-badge>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col md="12">
            <b-card class="mb-1">
              <span class="d-block text-uppercase text-primary fw-700">
                <strong>CLAIM DATA</strong>
              </span>
              <b-row class="mt-2">
                <itemValue
                  :text="selectedClaim.type_claim"
                  title="Type"
                  class="col-12 col-sm-6 col-lg-4"
                />
                <itemValue
                  :text="selectedClaim.source_claim"
                  title="Source"
                  class="col-12 col-sm-6 col-lg-4"
                />
                <itemValue
                  :text="selectedClaim.priority"
                  title="Priority"
                  class="col-12 col-sm-6 col-lg-4"
                />
                <itemValue
                  class="col-12 col-sm-6 col-lg-4"
                  v-if="selectedClaim.amount_department"
                  :text="'$ ' + selectedClaim.amount_department"
                  title="Amount suggered by Dept."
                />
                <itemValue
                  class="col-12 col-sm-6 col-lg-4"
                  v-if="selectedClaim.amount_client"
                  :text="'$ ' + selectedClaim.amount_client"
                  title="Amount requested by client"
                />
                <b-col class="col-12 col-sm-6 col-lg-4">
                  <b-form-group label="Reason" label-for="select-1">
                    <div
                      class="form-control d-flex align-items-center justify-content-center"
                      style="gap: 3px"
                      :class="skin == 'dark' ? 'disabled' : ''"
                    >
                      <template
                        v-for="(reason, index) in reasonData(selectedClaim)"
                      >
                        <b-badge
                          v-if="index < 3"
                          :key="reason.id"
                          :style="{
                            backgroundColor: 'rgba(0, 144, 231, 0.12)',
                          }"
                          class="px-1 text-primary"
                        >
                          {{ reason.name }}
                        </b-badge>
                      </template>
                      <template v-if="reasonData(selectedClaim).length > 2">
                        <feather-icon
                          icon="EyeIcon"
                          size="16"
                          class="text-primary"
                          id="tooltip-1"
                        />
                        <b-tooltip
                          :target="'tooltip-1'"
                          triggers="hover"
                          placement="top"
                        >
                          <template v-for="reason in reasonData(selectedClaim)">
                            <b-badge
                              :key="reason.id"
                              variant="light-primary"
                              class="d-block mb-1s"
                            >
                              {{ reason.name }}
                            </b-badge>
                          </template>
                        </b-tooltip>
                      </template>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="input-textarea"
                    label="Claim description"
                    label-for="textarea-description"
                  >
                    <b-form-textarea
                      v-model="selectedClaim.description"
                      id="textarea-no-resize"
                      placeholder="enter claim description"
                      rows="3"
                      no-resize
                      disabled
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <StepLevel
          :dataSolutionsForClaim="dataSolutionsForClaim"
          :selectedClaim="selectedClaim"
          v-if="dataSolutionsForClaim.length > 0"
        />
      </b-form>
    </b-overlay>
  </b-modal>
</template>
  <script>
import itemValue from "@/views/commons/components/claims_v2/components/itemValue.vue";
import StepLevel from "@/views/commons/components/claims_v2/components/Stepers/StepLevel.vue";
import ClaimV2Service from "@/views/commons/components/claims_v2/services/claims-v2.service";

import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

import { mapGetters } from "vuex";
export default {
  name: "ModalDetail",
  components: {
    itemValue,
    StepLevel,
    StatusAccount,
  },
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    selectedClaim: {
      type: Object,
      required: true,
      default: () => undefined,
    },
  },
  data() {
    return {
      activeModal: false,
      loading: false,
      formSolution: {
        statusSolution: "",
        claimId: 0,
        description: "",
        claimLevelId: 0,
        claimStatus: "",
        solutions: [],
        email: "",
        user_id: 0,
        scaleClaimLevel: null,
        statuHold: false,
        levelSelect: 0,
      },
      dataSolutionsForClaim: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      skin: "appConfig/skin",
    }),

    hasMoreThanOneAccount() {
      return JSON.parse(this.selectedClaim.other_accounts).length > 1;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    colorStatus() {
      let selected;
      switch (this.selectedClaim.cliente_account_status) {
        case 1:
          selected = "#00CC00"; //green
          break;
        case 2:
          selected = "yellow";
          break;
        case 3:
          selected = "blue";
          break;
        case 4:
          selected = "red";
          break;
        case 5:
          selected = "red";
          break;
        case 6:
          selected = "red";
          break;
      }
      return selected;
    },
  },
  watch: {
    selectedClaim: {
      async handler(value) {
        const { data } = await ClaimV2Service.getSolutionsForClaimQuality(
          value.claim_id
        );
        this.dataSolutionsForClaim = data;
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    this.activeModal = true;
    this.formSolution.user_id = this.currentUser.user_id;
    this.formSolution.claimLevelId = this.selectedClaim.level_id;
  },
  methods: {
    closeMe() {
      this.activeModal = false;
      this.$emit("close");
    },

    reasonData(item) {
      return JSON.parse(item.reasons);
    },
    otherAccounts(otherAccounts, account) {
      const newArray = JSON.parse(otherAccounts).filter((element) => {
        return element.account != account;
      });
      return newArray;
    },
  },
};
</script>

<style scoped>
.mb-1s {
  margin-bottom: 2px;
}
div.form-control.disabled {
  opacity: 0.5;
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.4);
}
.modal-details {
  position: relative;
}
</style>
