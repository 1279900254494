const { amgApi } = require("@/service/axios");

class LettersToClientsService {

    async getDataLettersToClients(params) {

        const data = await amgApi.post("/correspondence/get-all-correspondence-letters-to-clients", params)
        return data;

    }

    async getProgramsAdvanceSearch() {

        const data = await amgApi.post("/correspondence/get-programs-correspondence-letters-to-clients");
        return data.data
    }

    async sentLetterClient(params) {

        const data = await amgApi.post("/correspondence/sent-letter-client", params)
        return data
    }

    async contLetterClient() {

        const data = await amgApi.post("/correspondence/cont-letter-client")
        return data
    }

    async sentStatementClient(params) {

        const data = await amgApi.post("/correspondence/sent-statement-client", params)
        return data

    }

    async openLetterClientPdf(params) {

        const data = await amgApi.post("/correspondence/open-letter-client-pdf", params)
        return data
    }

    async openStatementClientPdf(params) {

        const data = await amgApi.post("/correspondence/open-statement-client-pdf", params)
        return data
    }

    async openPdfLetterClient(params) {

        const data = await amgApi.post("/correspondence/pdf-letter-client", params, {
            responseType: 'blob'
        })
        return data
    }

    async openPdfLetterClientBc(params) {

        const data = await amgApi.post("/correspondence/pdf-letter-clientbc", params, {
            responseType: 'blob'
        })
        return data
    }

    async openClientLetterCi(params) {
        try {
            const data = await amgApi.post("/correspondence/open-letter-client-ci", params);
            return data;
        } catch (err) {
            throw err;
        }
    }

}

export default new LettersToClientsService()