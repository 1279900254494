<template>
  <div class="header-rc-chat">
    <div class="container-info">
      <div
        class="d-flex flex-column justify-content-center cursor-pointer"
        @click="$emit('activeChatInfo')"
      >
        <div class="font-weight-bolder">
          {{ currentChat.leadName }}
        </div>
        <div class="font-weight-bolder">
          {{ currentChat.leadPhoneNumber }}
        </div>
      </div>
      <div class="ml-1 d-flex align-items-center" v-if="!currentChat.leadId">
        <feather-icon
          icon="RefreshCcwIcon"
          class="text-warning cursor-pointer"
          @click="syncLeadInfo()"
          v-b-tooltip.hover
          title="Sync lead information"
          size="20"
        ></feather-icon>
      </div>
      <div
        class="ml-1 d-flex align-items-center"
        v-if="currentChat.leadId && moduleId == 2"
      >
        <tabler-icon
          icon="InfoSquareIcon"
          class="text-primary cursor-pointer"
          v-b-tooltip.hover
          title="Information"
          size="24"
          @click="modalShowLead = true"
        ></tabler-icon>
      </div>
      <div
        class="ml-1 d-flex align-items-center"
        v-if="currentChat.leadId && moduleId == 2 && !currentChat.recycledBy"
      >
        <feather-icon
          icon="ArchiveIcon"
          class="text-warning cursor-pointer"
          v-b-tooltip.hover
          title="Recycle"
          size="20"
          @click="recycleLead()"
        ></feather-icon>
      </div>
      <div
        class="ml-1 d-flex align-items-center"
        v-if="currentChat.leadId && moduleId == 2 && !currentChat.recycledBy"
      >
        <btn-create-appointment :key="`app_${currentChat.leadId}`" :leadId="currentChat.leadId"/>

      </div>
    </div>
    <div class="container-icons">
      <div
        class="d-flex align-items-center p-0 m-0"
        style="height: auto !important"
      >
        <b-button
          @click="$emit('activeSearch')"
          variant="primary"
          class="custom-btn-v2"
        >
          <feather-icon
            size="18"
            class="icon-search-action"
            icon="SearchIcon"
          ></feather-icon>
        </b-button>
      </div>

      <b-dropdown
        size="md"
        variant="muted"
        toggle-class="text-decoration-none"
        no-caret
        right
      >
        <template #button-content>
          <b-button
            variant="primary"
            style="padding: 0.7rem 0.6rem"
            class="rc-color-buttons mr-2"
          >
            <feather-icon
              size="18"
              class="icon-menu-action"
              icon="MoreVerticalIcon"
            ></feather-icon>
          </b-button>
        </template>
        <b-dropdown-item href="#" @click="$emit('activeChatInfo')"
          >Information</b-dropdown-item
        >
      </b-dropdown>
    </div>

    <client-information
      v-if="modalShow"
      @hidden="modalShow = false"
      :leads-list="leadsList"
      :current-chat="currentChat"
    />

    <modal-lead-information
      v-if="modalShowLead"
      @hidden="modalShowLead = false"
      :lead-id="currentChat.leadId"
      :module-id="moduleId"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import ClientInformation from "@/views/commons/components/ring-central/chat-sms/components/RcChatSyncInformation.vue";
import ModalLeadInformation from "@/views/commons/components/ring-central/chat-sms/components/utils/ModalInformationLead.vue";
import  BtnCreateAppointment from "@/views/commons/components/ring-central/chat-sms/components/utils/BtnCreateAppointment.vue";

import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import NestService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
import LeadService from "@/service/commons/lead.service.js";
export default {
  props: {
    currentChat: {
      type: Object,
      required: true,
    },
  },
  components: {
    ClientInformation,
    ModalLeadInformation,
    BtnCreateAppointment,
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  data() {
    return {
      modalShow: false,
      modalShowLead: false,
      leadsList: [],
    };
  },

  methods: {
    ...mapMutations({
      M_UPDATE_CHAT_CONTACT: "SmsInboxStore/M_UPDATE_CHAT_CONTACT",
      M_SET_CURRENT_CHAT: "SmsInboxStore/M_SET_CURRENT_CHAT",
    }),

    async syncLeadInfo() {
      try {
        this.addPreloader();

        const { data } = await SmsService.getInfoChat({
          leadNumber: this.currentChat.leadPhoneNumber,
        });

        if (!data || data.length == 0) {
          this.showInfoSwal("There is no lead with this number");
          this.removePreloader();
          return;
        }

        if (data.length == 1) {
          const leadInfo = data[0];
          const params = {
            leadId: leadInfo.id,
            leadName: this.getName(leadInfo),
          };

          const { status } = await NestService.updateChat(
            this.currentChat._id,
            params
          );

          if (status == 200) {
            this.showSuccessSwal("OPERATION SUCCESSFULLY");
          }
          this.removePreloader();
        } else {
          this.leadsList = data;
          this.modalShow = true;
          this.removePreloader();
        }
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    getName(lead) {
      if (lead.first_name && lead.last_name) {
        return `${lead.first_name} ${lead.last_name}`;
      }

      if (lead.nickname) {
        return lead.nickname;
      }

      return "Unknown";
    },

    async recycleLead() {
      try {
        const confirm = await this.showConfirmSwal();
        if (!confirm.isConfirmed) return;

        await LeadService.update(this.currentChat.leadId, {
          recycled_by: this.currentUser.user_id,
        });

        const dateUTC = new Date();
        const isoDate = dateUTC.toISOString();

        const params = {
          recycledBy: {
            id: this.currentUser.user_id,
            name: this.currentUser.fullName,
            createdAt: isoDate,
          },
        };

        const { status } = await NestService.updateChat(
          this.currentChat._id,
          params
        );

        if (status == 200) {
          this.showSuccessSwal("OPERATION SUCCESSFULLY");
        }
      } catch (e) {
        this.showErrorSwal();
      }
    },
  },
};
</script>

<style lang="scss">
.header-rc-chat {
  font-family: "Rubick" !important;
  width: 100%;
  height: 8%;
  background-color: #f0faff;
  min-height: 60px;
  display: flex;
  padding: 0 0 0 2rem;
  align-items: center;
  box-shadow: 0px 10px 12px -5px rgba(0, 0, 0, 0.05);

  position: sticky;
  top: 0;
  z-index: 100;

  .container-info {
    flex: 0 0 auto;
    display: flex;
  }

  .custom-btn-v2 {
    padding: 0.7rem 0.6rem !important;
    background-color: #0099dd !important;
  }

  .custom-btn-v3 {
    .btn {
      padding: 0 !important;
    }
  }
  .container-icons {
    flex: 1;
    display: flex;
    justify-content: end;

    .b-input-custom {
      background-color: #f0f2f5;
      width: 100%;
    }

    .dropdown-menu {
      background-color: #ffffff;

      .dropdown-item:hover {
        background-color: #f5f6f6;
        color: #6e6b7b;
      }
    }
  }
}

.dark-layout {
  .header-rc-chat {
    background-color: #202c33;

    .container-icons {
      .b-input-custom {
        background-color: #202c33;
      }

      .icon-menu-action {
        color: #f0f2f5 !important;
        cursor: pointer;
      }

      .dropdown-menu {
        background-color: #233138;
        .dropdown-item:hover {
          background-color: #182229 !important;
          color: #d8d8d8;
        }
      }
    }
  }
}
</style>