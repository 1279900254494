<template>
  <div class="modal-vue-file-ci">
    <b-row
      class="mt-2 ci-container d-flex justify-content-center"
      v-if="files.length > 0"
    >
      <b-col
        sm="6"
        md="4"
        lg="3"
        class="mb-2"
        v-for="(item, index) in files"
        :key="index"
      >
        <b-card
          body-class="p-0 mx-1 my-1 d-flex flex-column justify-content-between"
          class="m-0 h-100"
        >
          <div v-show="item.status === 1" class="img-preview">
            <v-pdf
              v-if="item.extension == 'pdf'"
              :src="item.pdf"
              @progress="item.status = $event"
            />
            <img
              v-else
              :src="returnImage(item.extension, item.pdf)"
              class="py-2 amgIcon"
              :alt="item.extension"
            />
          </div>

          <b-skeleton-img v-if="item.status != 1"></b-skeleton-img>
          <div>
            <div class="document-title text-center pt-1 text-truncate">
              {{ item.name }}
            </div>
            <div class="d-flex justify-content-around">
              <div
                class="container-icon text-primary"
                @click="openPreview(item.pdf, item.name)"
                v-if="item.extension == 'pdf'"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="icon-badge text-primary"
                ></feather-icon>
              </div>
              <div class="container-icon">
                <b-link
                  size="sm"
                  :href="item.pdf"
                  target="_blank"
                  :disabled="!item.pdf"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="icon-badge"
                  ></feather-icon>
                </b-link>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <div v-else class="text-center mb-2 no-files-timeline">
      No matching files found
    </div>
    <preview-pdf
      v-if="showPreviewPdf"
      :pdf-url="currentPdf"
      :pdf-name="pdfName"
      @close="showPreviewPdf = false"
    ></preview-pdf>
  </div>
</template>
<script>
import VPdf from "vue-pdf";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";
export default {
  components: {
    VPdf,
    PreviewPdf,
  },
  props: {
    caseId: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      files: [],
      currentPdf: null,
      pdfName: null,
      showPreviewPdf: false,
    };
  },

  async created() {
    this.getCiFilesProvider();
  },
  methods: {
    async getCiFilesProvider() {
      const data = await CourtInfoService.getAllFilesByCase({
        case_id: this.caseId,
        case_type_document_id: this.step,
      });
      this.files = data.data;
    },
    openPreview(url, name) {
      this.currentPdf = url;
      this.pdfName = name;
      this.showPreviewPdf = true;
    },
    returnImage(item, url) {
      switch (item) {
        case "jpg":
        case "png":
        case "gif":
        case "jpeg":
        case "webp":
          return url;
        case "xls":
        case "xlsx":
          return "/assets/images/drag-drop/ext_files/excel.png";
        case "html":
          return "html";
        case "pdf":
          return "/assets/images/drag-drop/ext_files/pdf.png";
        case "docx":
        case "doc":
          return "/assets/images/drag-drop/ext_files/doc2.webp";
        default:
          return "/assets/images/drag-drop/doc2.png";
      }
    },
  },
};
</script>
<style lang="scss">
.modal-vue-file-ci {
  .img-preview,
  canvas {
    height: 100%;
    width: 100%;
    max-height: 260px;
    padding: 2px 8px;
  }
  .amgIcon {
    display: inline-block;
    fill: #ff9f43;
    transform-origin: 0px 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .card {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3) !important;
    .document-title {
      font-size: 17px !important;
      font-weight: 500 !important;
    }
    .container-icon {
      margin-top: 5px;
      border-radius: 5px;
      padding: 5px 10px;
      cursor: pointer;
      .icon-badge {
        width: 20px;
        height: 20px;
      }
      &:hover {
        background-color: rgba(219, 217, 217, 0.7);
      }
    }
  }
}
.dark-layout .modal-vue-file-ci .card {
  background-color: #1d1d20 !important;
  .container-icon {
    background-color: #1d1d20 !important;
    &:hover {
      background-color: #2e2e33 !important;
    }
  }
}
</style>