export default [
  {
    path: "settings",
    name: "management-settings-section",
    redirect: { name: "management-settings-general" },
    component: () =>
      import(
        /* webpackChunkName: "Managment Settings" */
        "@/views/management/views/settings/TabSettings.vue"
      ),
    meta: {
      pageTitle: 'Settings',
      permittedRoles: [1, 2, 3],
      breadcrumb: [
        {
          text: '',
        },
      ],
    },
    children: [
      // {
      //   path: 'office-hours',
      //   name: 'management-settings-office-hours',
      //   component: () => import(
      //     /* webpackChunkName: "Managment Settings Reason Code" */
      //     '@/commons/employee-claims/components/settings/components/OfficeHours.vue'
      //   ),
      //   meta: {
      //     pageTitle: 'Settings',
      //     permittedRoles: [1, 2, 3],
      //     breadcrumb: [
      //       {
      //         text: 'Reason Code',
      //       },
      //     ],
      //   },
      // },
      {
        path: "general",
        name: "management-settings-general",
        redirect: { name: "management-settings-type-request" },
        component: () =>
          import(/* webpackChunkName "DSSettingsGrid" */ "@/views/management/views/settings/SubTabSettings1.vue"),
        meta: {
          pageTitle: "General",
          breadcrumb: [
            {
              text: "General",
              active: true
            }
          ],
        },
        children: [
          {
            path: 'type-request',
            name: 'management-settings-type-request',
            component: () => import(
              /* webpackChunkName: "Managment Settings Reason Code" */
              '@/commons/employee-claims/components/settings/components/TypeRequest.vue'
            ),
            meta: {
              pageTitle: 'General',
              permittedRoles: [1, 2, 3],
              breadcrumb: [
                {
                  text: 'Type Request',
                },
              ],
            },
          },
          {
            path: 'merchant',
            name: 'management-settings-merchants',
            component: () => import(
              /* webpackChunkName: "Managment Settings Merchants" */
              '@/views/management/views/settings/views/merchant/MerchantsSettings.vue'
            ),
            meta: {
              pageTitle: 'General',
              permittedRoles: [1, 2, 3],
              breadcrumb: [
                {
                  text: 'Merchants',
                },
              ],
            },
          },
          {
            path: 'reason-code',
            name: 'management-settings-reason-code',
            component: () => import(
              /* webpackChunkName: "Managment Settings Reason Code" */
              '@/views/management/views/settings/views/reason-code/ReasonCode.vue'
            ),
            meta: {
              pageTitle: 'General',
              permittedRoles: [1, 2, 3],
              breadcrumb: [
                {
                  text: 'Reason Code',
                },
              ],
            },
          },
          {
            path: 'time',
            name: 'management-settings-time',
            component: () => import(
              /* webpackChunkName: "Managment Settings Time" */
              '@/views/management/views/settings/views/time/Time.vue'
            ),
            meta: {
              pageTitle: 'General',
              permittedRoles: [1, 2, 3],
              breadcrumb: [
                {
                  text: 'Time',
                },
              ],
            },
          },
          {
            path: "returns-assistance",
            name: "returns-assistance-settings",
            component: () =>
              import(
                '@/views/management/views/settings/views/returns_assistance/ReturnsAssistance.vue'
              ),
            meta: {
              pageTitle: "General",
              permittedRoles: [1, 2],
              breadcrumb: [
                {
                  text: "Returns Assistance",
                },
              ],
            },
          },
          {
            path: "gpt-prompts",
            name: "gpt-prompts-settings",
            component: () =>
              import(
                '@/views/management/views/settings/views/gpt-prompts/GptPrompts.vue'
              ),
            meta: {
              pageTitle: "General",
              permittedRoles: [1, 2],
              breadcrumb: [
                {
                  text: "GPT Prompts",
                },
              ],
            },
          },
          {
            path: "vocabulary",
            name: "vocabulary-settings",
            component: () =>
              import(
                '@/views/management/views/settings/views/call-transcription-vocabulary/Vocabulary.vue'
              ),
            meta: {
              pageTitle: "General",
              permittedRoles: [1, 2],
              breadcrumb: [
                {
                  text: "Automatic Notes: Vocabulary",
                },
              ],
            },
          },
        ],
      },
      // {
      //   path: 'type-request',
      //   name: 'management-settings-type-request',
      //   component: () => import(
      //     /* webpackChunkName: "Managment Settings Reason Code" */
      //     '@/commons/employee-claims/components/settings/components/TypeRequest.vue'
      //   ),
      //   path: "type-request",
      //   name: "management-settings-type-request",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "Managment Settings Reason Code" */
      //       "@/commons/employee-claims/components/settings/components/TypeRequest.vue"
      //     ),
      //   meta: {
      //     pageTitle: 'Settings',
      //     permittedRoles: [1, 2, 3],
      //     breadcrumb: [
      //       {
      //         text: 'Reason Code',
      //       },
      //     ],
      //   },
      // },
      // {
      //   path: 'reason-code',
      //   name: 'management-settings-reason-code',
      //   component: () => import(
      //     /* webpackChunkName: "Managment Settings Reason Code" */
      //     '@/views/management/views/settings/views/reason-code/ReasonCode.vue'
      //   ),
      //   meta: {
      //     pageTitle: 'Settings',
      //     permittedRoles: [1, 2, 3],
      //     breadcrumb: [
      //       {
      //         text: 'Reason Code',
      //       },
      //     ],
      //   },
      // },
      // {
      //   path: 'time',
      //   name: 'management-settings-time',
      //   component: () => import(
      //     /* webpackChunkName: "Managment Settings Time" */
      //     '@/views/management/views/settings/views/time/Time.vue'
      //   ),
      //   meta: {
      //     pageTitle: 'Settings',
      //     permittedRoles: [1, 2, 3],
      //     breadcrumb: [
      //       {
      //         text: 'Time',
      //       },
      //     ],
      //   },
      // },
      // {
      //   path: 'other',
      //   name: 'management-settings-other',
      //   component: () => import(
      //     /* webpackChunkName: "Managment Settings Time" */
      //     '@/views/management/views/settings/views/other/OtherSettings.vue'
      //   ),
      //   meta: {
      //     pageTitle: 'Payments',
      //     permittedRoles: [1, 2, 3],
      //     breadcrumb: [
      //       {
      //         text: 'Other Settings',
      //       },
      //     ],
      //   },
      // },
      {
        path: 'payments',
        name: 'payment-settings',
        redirect: { name: 'management-settings-by-companies' },
        component: () => import(
          '@/views/commons/setting/components/payment-setting-modules/MainPayments.vue'
        ),
        meta: {
          pageTitle: 'Payments',
          permittedRoles: [1, 2, 3],
          breadcrumb: [
            {
              text: 'Payments',
            },
          ],
        },
        children: [
          {
            path: 'by-companies',
            name: 'management-settings-by-companies',
            component: () => import(
              /* webpackChunkName: "Managment Settings Reason Code" */
              '@/views/commons/setting/components/payment-setting-modules/ByPaymentSetting.vue'
            ),
            meta: {
              pageTitle: 'Payments',
              permittedRoles: [1, 2, 3],
              breadcrumb: [
                {
                  text: 'By Companies',
                },
              ],
            },
          },
          {
            path: 'all-companies',
            name: 'management-settings-all-companies',
            component: () => import(
              /* webpackChunkName: "Managment Settings Merchants" */
              '@/views/commons/setting/components/payment-setting-modules/AllPaymentSetting.vue'
            ),
            meta: {
              pageTitle: 'Payments',
              permittedRoles: [1, 2, 3],
              breadcrumb: [
                {
                  text: 'All Companies',
                },
              ],
            },
          },
          {
            path: "module-access-config",
            name: "module-access-config-settings",
            component: () =>
              import(
                '@/views/management/views/settings/views/module_access_config/ModuleAccessConfig.vue'
              ),
            meta: {
              pageTitle: "Payments",
              permittedRoles: [1, 2],
              breadcrumb: [
                {
                  text: "Search Access",
                },
              ],
            },
          },
          {
            path: 'other',
            name: 'management-settings-other',
            component: () => import(
              /* webpackChunkName: "Managment Settings Time" */
              '@/views/management/views/settings/views/other/OtherSettings.vue'
            ),
            meta: {
              pageTitle: 'Payments',
              permittedRoles: [1, 2, 3],
              breadcrumb: [
                {
                  text: 'Penalties',
                },
              ],
            },
          },
        ],
      },
      // {
      //   path: 'merchant',
      //   name: 'management-settings-merchants',
      //   component: () => import(
      //     /* webpackChunkName: "Managment Settings Merchants" */
      //     '@/views/management/views/settings/views/merchant/MerchantsSettings.vue'
      //   ),
      //   meta: {
      //     pageTitle: 'Settings',
      //     permittedRoles: [1, 2, 3],
      //     breadcrumb: [
      //       {
      //         text: 'Merchants',
      //       },
      //     ],
      //   },
      // },
      // {
      //   path: "returns-assistance",
      //   name: "returns-assistance-settings",
      //   component: () =>
      //     import(
      //       '@/views/management/views/settings/views/returns_assistance/ReturnsAssistance.vue'
      //     ),
      //   meta: {
      //     pageTitle: "Settings",
      //     permittedRoles: [1, 2],
      //     breadcrumb: [
      //       {
      //         text: "Returns Assistance",
      //       },
      //     ],
      //   },
      // },
      // {
      //   path: "module-access-config",
      //   name: "module-access-config-settings",
      //   component: () =>
      //     import(
      //       '@/views/management/views/settings/views/module_access_config/ModuleAccessConfig.vue'
      //     ),
      //   meta: {
      //     pageTitle: "Settings",
      //     permittedRoles: [1, 2],
      //     breadcrumb: [
      //       {
      //         text: "Payment Search",
      //       },
      //     ],
      //   },
      // },
      // {
      //   path: "docusign",
      //   name: "docusign-credentials-settings",
      //   component: () =>
      //     import(
      //       '@/views/management/views/settings/views/docusign-credentials/DocusignSettings.vue'
      //     ),
      //   meta: {
      //     pageTitle: "Docusign Credentials",
      //     permittedRoles: [1],
      //     breadcrumb: [
      //       {
      //         text: "Credentials",
      //       },
      //     ],
      //   },
      // },
      {
        path: "ring-central",
        name: "ring-central-settings",
        component: () =>
          import(
            '@/views/management/views/settings/components/ring-central/RingCentral.vue'
          ),
        meta: {
          pageTitle: "Ring Central",
          permittedRoles: [1, 2],
          breadcrumb: [
            {
              text: "Ring Central",
            },
          ],
        },
      },
      {
        path: "management-dynamic-forms",
        name: "management-settings-dynamic-forms",
        component: () =>
          import(
            '@/views/management/views/settings/views/dynamic_forms/table/DynamicForms.vue'
          ),
        meta: {
          pageTitle: "Custom Fields",
          permittedRoles: [1, 2],
          breadcrumb: [
            {
              text: "Custom Fields",
            },
          ],
          section: 'custom-fields',
        },
      },
      {
        path: 'management-settings-availability',
        name: 'management-settings-availability',
        component: () => import('@/views/management/views/settings/components/availability/Availability.vue'),
        meta: {
          pageTitle: 'Custom Fields',
          permittedRoles: [1, 2],
          breadcrumb: [
            {
              text: 'Custom Fields',
            },
          ],
        },
      },
      {
        path: 'management-users-availability',
        name: 'management-users-availability',
        component: () => import('@/views/management/views/settings/components/users_availability/UsersAvailability.vue'),
        meta: {
          pageTitle: 'Custom Fields',
          permittedRoles: [1, 2],
          breadcrumb: [
            {
              text: 'Custom Fields',
            },
          ],
        },
      },
    ],
  },
];
