

export default [
  {
    path: 'price-of-charges',
    name: 'ced-settings-prices',
    component: () => import(/* webpackChunkName: "CEDSettingsPrices" */ '@/views/ce-digital/sub-modules/settings/views/prices/components/prices-modules/PricesPerModule.vue'),
    meta: {
      permittedRoles: [1, 17],
      pageTitle: 'Price of Charges',
      breadcrumb: [
        {
          text: 'Price of Charges',
          active: true,
        },
      ],
    },
    // children: [
    //   {
    //     path: 'customer-service',
    //     name: 'ced-settings-commissions-customer-service',
    //     component: () => import(/* webpackChunkName: "CEDSettingsCommissionsCustomerService" */ '@/views/ce-digital/sub-modules/settings/views/commissions/components/commissions-modules/CommissionPerModule.vue'),
    //     meta: {
    //       moduleIdNum: 22,
    //       pageTitle: 'Commissions',
    //       breadcrumb: [
    //         {
    //           text: 'Customer Service',
    //           active: true,
    //         },
    //       ],
    //       permittedRoles: [1, 17],
    //     },
    //   },
    //   {
    //     path: 'financial',
    //     name: 'ced-settings-commissions-financial',
    //     component: () => import(/* webpackChunkName: "CEDSettingsCommissionsFinancial" */ '@/views/ce-digital/sub-modules/settings/views/commissions/components/commissions-modules/CommissionPerModule.vue'),
    //     meta: {
    //       moduleIdNum: 23,
    //       pageTitle: 'Commissions',
    //       breadcrumb: [
    //         {
    //           text: 'Financial',
    //           active: true,
    //         },
    //       ],
    //       permittedRoles: [1, 17],
    //     },
    //   },
    //   {
    //     path: 'sales',
    //     name: 'ced-settings-commissions-sales',
    //     component: () => import(/* webpackChunkName: "CEDSettingsCommissionsSales" */ '@/views/ce-digital/sub-modules/settings/views/commissions/components/commissions-modules/CommissionPerModule.vue'),
    //     meta: {
    //       module: 24,
    //       moduleIdNum: 26,
    //       pageTitle: 'Commissions',
    //       breadcrumb: [
    //         {
    //           text: 'Sales',
    //           active: true,
    //         },
    //       ],
    //       permittedRoles: [1, 17],
    //     },
    //   },
    //   {
    //     path: 'customer-service-dynamic',
    //     name: 'ced-settings-commissions-financial-dynamic',
    //     component: () => import(/* webpackChunkName: "CEDSettingsCommissionsDynamic" */ '@/views/ce-digital/sub-modules/settings/views/commissions/components/dynamic-commissions/DynamicCommissions.vue'),
    //     meta: {
    //       module: 24,
    //       moduleIdNum: 23,
    //       pageTitle: 'Commissions',
    //       breadcrumb: [
    //         {
    //           text: 'Dynamic Commissions',
    //           active: true,
    //         },
    //       ],
    //       permittedRoles: [1, 17],
    //     },
    //   },
    //   {
    //     path: 'connection',
    //     name: 'ced-settings-commissions-connection',
    //     component: () => import(/* webpackChunkName: "CEDSettingsCommissionsConnection" */ '@/views/ce-digital/sub-modules/settings/views/commissions/components/commissions-modules/CommissionPerModule.vue'),
    //     meta: {
    //       module: 24,
    //       moduleIdNum: 20,
    //       pageTitle: 'Commissions',
    //       breadcrumb: [
    //         {
    //           text: 'Connection',
    //           active: true,
    //         },
    //       ],
    //       permittedRoles: [1, 17],
    //     },
    //   },
    // ],
  },
]
