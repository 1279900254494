<template>
  <b-modal
    v-model="ownControl"
    scrollable
    size="lg"
    :title="titleAddCharge"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bolder">
          {{ titleAddCharge }}
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="form">
      <template v-if="edit">
        <b-row>
          <b-col cols="12" lg="6">
            <div
              class="info-container"
              :class="isDarkSkin ? 'text-white' : 'text-dark'"
            >
              <p class="m-0" style="gap: 5px">
                Service | {{ client.program_name }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div
              class="info-container"
              :class="isDarkSkin ? 'text-white' : 'text-dark'"
            >
              <p class="m-0" style="gap: 5px">
                Client | {{ client.client_name }}
              </p>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="CONCEPT"
              rules="required"
            >
              <div
                class="info-container"
                :class="isDarkSkin ? 'text-white' : 'text-dark'"
              >
                <div class="m-0 d-flex" style="gap: 5px">
                  <p class="m-0" style="white-space: nowrap">Concept |</p>
                  <div>
                    <b-select
                      v-if="finishedLoading"
                      v-model="description"
                      :options="typecharges"
                      :state="errors[0] ? false : null"
                      value-field="id"
                      text-field="description"
                      style="border: none; padding: 0px; height: 20px"
                      :style="
                        isDarkSkin
                          ? 'background-color: #615b5b;'
                          : 'background-color: #e3e1e1'
                      "
                    />
                  </div>
                </div>
              </div>
              <label v-if="errors[0]" class="text-danger"
                >Concept {{ errors[0] }}</label
              >
            </validation-provider>
          </b-col>
          <b-col lg="6" class="mt-1" v-if="valDescription">
            <validation-provider
              v-slot="{ errors }"
              name="OTHERS"
              rules="required"
            >
              <div
                class="info-container"
                :class="isDarkSkin ? 'text-white' : 'text-dark'"
              >
                <div class="m-0 d-flex" style="gap: 5px">
                  <p class="m-0">Others |</p>
                  <div>
                    <b-input
                      v-if="finishedLoading"
                      v-model="notcharges"
                      :state="errors[0] ? false : null"
                      placeholder="Specify"
                      style="border: none; padding: 0px; height: 20px"
                      :style="
                        isDarkSkin
                          ? 'background-color: #615b5b;'
                          : 'background-color: #e3e1e1'
                      "
                    >
                      Specify
                    </b-input>
                  </div>
                </div>
              </div>
              <label v-if="errors[0]" class="text-danger"
                >Specify {{ errors[0] }}</label
              >
            </validation-provider>
          </b-col>
          <b-col v-if="validateCreditor" lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="CREDITOR"
              rules="required"
            >
              <div
                class="info-container"
                :class="isDarkSkin ? 'text-white' : 'text-dark'"
              >
                <div class="m-0 d-flex" style="gap: 5px">
                  <p class="m-0" style="white-space: nowrap">Creditor |</p>
                  <div>
                    <b-form-select
                      v-if="finishedLoading"
                      v-model="creditorSelect"
                      :state="errors[0] ? false : null"
                      value-field="id"
                      style="
                        border: none;
                        padding: 0px;
                        height: 20px;
                        width: 75%;
                      "
                      :style="
                        isDarkSkin
                          ? 'background-color: #615b5b;'
                          : 'background-color: #e3e1e1'
                      "
                    >
                      <b-form-select-option
                        v-for="item in creditors"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{
                          item.creditor_name + ("(" + item.account + ")")
                        }}</b-form-select-option
                      >
                    </b-form-select>
                  </div>
                </div>
              </div>
              <label v-if="errors[0]" class="text-danger"
                >Creditor {{ errors[0] }}</label
              >
            </validation-provider>
          </b-col>
          <b-col lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="AMOUNT"
              rules="money-2"
            >
              <div
                class="info-container"
                :class="isDarkSkin ? 'text-white' : 'text-dark'"
              >
                <div class="m-0 d-flex" style="gap: 5px">
                  <p class="m-0" style="white-space: nowrap">Amount |</p>
                  <div>
                    <money
                      v-if="finishedLoading"
                      id="input-1a"
                      v-model="ammount"
                      v-bind="maskMoney"
                      :disabled="true"
                      style="border: none; border-radius: 5px"
                      :style="
                        isDarkSkin
                          ? 'background-color: #615b5b; color: white;'
                          : 'background-color: #e3e1e1; color: black;'
                      "
                    />
                  </div>
                </div>
              </div>
              <label v-if="errors[0]" class="text-danger">
                {{ errors[0] }}
              </label>
            </validation-provider>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <b-col cols="12" lg="6">
            <div
              class="info-container"
              :class="isDarkSkin ? 'text-white' : 'text-dark'"
            >
              <p class="m-0" style="gap: 5px">
                Service | {{ client.program_name }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" lg="6">
            <div
              class="info-container"
              :class="isDarkSkin ? 'text-white' : 'text-dark'"
            >
              <p class="m-0" style="gap: 5px">
                Client | {{ client.client_name }}
              </p>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="CONCEPT"
              rules="required"
            >
              <div
                class="info-container"
                :class="isDarkSkin ? 'text-white' : 'text-dark'"
              >
                <div class="m-0 d-flex" style="gap: 5px">
                  <p class="m-0">Concept |</p>
                  <div class="w-75">
                    <b-select
                      v-if="finishedLoading"
                      v-model="description"
                      :options="typecharges"
                      value-field="id"
                      text-field="description"
                      :disabled="propChargeId !== null"
                      style="border: none; padding: 0px; height: 20px"
                      :style="
                        isDarkSkin
                          ? 'background-color: #615b5b;'
                          : 'background-color: #e3e1e1'
                      "
                    />
                  </div>
                </div>
              </div>
              <label v-if="errors[0]" class="text-danger">
                The concept {{ errors[0] }}
              </label>
            </validation-provider>
          </b-col>
          <b-col lg="6" class="mt-1" v-if="valDescription">
            <validation-provider
              v-slot="{ errors }"
              name="OTHERS"
              rules="required"
            >
              <div
                class="info-container"
                :class="isDarkSkin ? 'text-white' : 'text-dark'"
              >
                <div class="m-0 d-flex" style="gap: 5px">
                  <p class="m-0">Others |</p>
                  <div>
                    <b-input
                      v-if="finishedLoading"
                      v-model="notcharges"
                      :state="errors[0] ? false : null"
                      placeholder="Specify"
                      style="border: none; padding: 0px; height: 20px"
                      :style="
                        isDarkSkin
                          ? 'background-color: #615b5b;'
                          : 'background-color: #e3e1e1'
                      "
                    >
                      Specify
                    </b-input>
                  </div>
                </div>
              </div>
              <label v-if="errors[0]" class="text-danger"
                >Specify {{ errors[0] }}</label
              >
            </validation-provider>
          </b-col>
          <b-col v-if="validateCreditor" lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="CREDITOR"
              rules="required"
            >
              <div
                class="info-container"
                :class="isDarkSkin ? 'text-white' : 'text-dark'"
              >
                <div class="m-0 d-flex">
                  <p class="m-0" style="white-space: nowrap">Creditor |</p>
                  <div>
                    <b-form-select
                      v-if="finishedLoading"
                      v-model="creditorSelect"
                      :state="errors[0] ? false : null"
                      value-field="id"
                      style="
                        border: none;
                        padding: 0px;
                        height: 20px;
                        width: 75%;
                        margin-left: 4px;
                      "
                      :style="
                        isDarkSkin
                          ? 'background-color: #615b5b;'
                          : 'background-color: #e3e1e1'
                      "
                    >
                      <b-form-select-option
                        v-for="item in creditors"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{
                          item.creditor_name + ("(" + item.account + ")")
                        }}</b-form-select-option
                      >
                    </b-form-select>
                  </div>
                </div>
              </div>
              <label v-if="errors[0]" class="text-danger">
                The creditor {{ errors[0] }}
              </label>
            </validation-provider>
          </b-col>

          <b-col v-if="isNcr && moduleId === 22" lg="6" class="mt-1">
            <div
              class="info-container"
              :class="isDarkSkin ? 'text-white' : 'text-dark'"
            >
              <div class="m-0 d-flex" style="gap: 5px">
                <p class="m-0">Amount |</p>
                <div>
                  <b-form-radio-group
                    v-model="priceNcrSelected"
                    class="bg-transparent form-control d-flex flex-row justify-content-around"
                    style="
                      border: none;
                      padding: 0px;
                      height: 20px;
                      margin-left: 4px;
                    "
                    :style="
                      isDarkSkin
                        ? 'background-color: #615b5b;'
                        : 'background-color: #e3e1e1'
                    "
                  >
                    <b-form-radio value="0" style="white-space: nowrap">
                      $ 0.00</b-form-radio
                    >
                    <b-form-radio value="18.99" style="white-space: nowrap">
                      $ 18.99</b-form-radio
                    >
                    <b-form-radio value="other" style="white-space: nowrap">
                      Others</b-form-radio
                    >
                  </b-form-radio-group>
                </div>
              </div>
            </div>
          </b-col>

          <b-col v-else lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="AMOUNT"
              rules="money-2"
            >
              <div
                class="info-container"
                :class="isDarkSkin ? 'text-white' : 'text-dark'"
              >
                <div class="m-0 d-flex" style="gap: 5px">
                  <p class="m-0">Amount |</p>
                  <money
                    v-if="finishedLoading"
                    id="input-1a"
                    v-model="ammount"
                    v-bind="maskMoney"
                    :disabled="amount_charge || propAmount !== null"
                    class="py-0 pl-2"
                    style="border: none; border-radius: 5px"
                    :style="
                      isDarkSkin
                        ? 'background-color: #615b5b; color: white;'
                        : 'background-color: #e3e1e1; color: black;'
                    "
                  />
                </div>
              </div>
              <label v-if="errors[0]" class="text-danger">
                {{ errors[0] }}
              </label>
            </validation-provider></b-col
          >
        </b-row>
        <b-row
          class="mt-1 overflow-hidden"
          v-if="!(isNcr && priceNcrSelected == 0 && moduleId == 22)"
        >
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="Payment_Method"
              rules="required"
            >
              <b-form-group label="Payment Method" class="mt-2 mb-3">
                <b-form-radio-group
                  v-model="method_payment"
                  :state="errors[0] ? false : null"
                  :options="optionsPaymentMethod"
                  @change="type_payment = null"
                  name="radio-payment"
                />
                <label v-if="errors[0]" class="text-danger"
                  >Payment Method {{ errors[0] }}</label
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <transition
            name="slide-fade"
            class="d-flex flex-column col-6 overflow-hidden"
          >
            <b-form-group
              v-if="priceNcrSelected === 'other'"
              label="amount"
              class="pr-2"
            >
              <validation-provider
                v-slot="{ errors }"
                name="AMOUNT"
                rules="money-2"
              >
                <money
                  v-if="finishedLoading"
                  id="input-1a"
                  v-model="amountNcr"
                  v-bind="maskMoney"
                  class="form-control"
                  :style="{
                    borderColor:
                      errors[0] === 'The amount must be greater than 0.00' &&
                      validador
                        ? '#fc424a'
                        : isDarkSkin
                        ? '#d8d6de'
                        : 'rgb(217 209 209)',
                    opacity: isDarkSkin
                      ? errors[0] === 'The amount must be greater than 0.00' &&
                        validador
                        ? '1'
                        : '0.3'
                      : '1',
                  }"
                />
                <label v-if="errors[0]" class="text-danger">
                  {{ errors[0] }}
                </label>
              </validation-provider>
            </b-form-group>
          </transition>
        </b-row>
        <b-row v-if="method_payment === 1 || method_payment === 2">
          <b-col md="6">
            <b-form-group v-slot="{ ariaDescribedby }" label="Type Payment">
              <b-form-radio-group
                v-model="type_payment"
                :options="optionsTypePayment"
                :aria-describedby="ariaDescribedby"
                name="radio-inline"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="method_payment === 2" md="6">
            <validation-provider
              v-slot="{ errors }"
              name="AMOUNT_PARTIAL"
              :rules="
                propAdditionalCharge
                  ? `money-2|required|max_value:${ammount}|min_value:0`
                  : `money-2|required|min_value:0`
              "
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >AMOUNT PARTIAL
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <money
                  id="input-1"
                  v-model="ammount_partial"
                  :style="{
                    borderColor:
                      errors[0] === 'The amount must be greater than 0.00' &&
                      validador
                        ? '#fc424a'
                        : isDarkSkin
                        ? '#d8d6de'
                        : 'rgb(217 209 209)',
                    opacity: isDarkSkin
                      ? errors[0] === 'The amount must be greater than 0.00' &&
                        validador
                        ? '1'
                        : '0.3'
                      : '1',
                  }"
                  v-bind="maskMoney"
                  class="form-control"
                />
              </b-input-group>
              <label v-if="errors[0]" class="text-danger">
                {{
                  errors[0] == "is max"
                    ? `the amount has to be less than or equal to ${ammount}`
                    : errors[0]
                }}
              </label>
            </validation-provider></b-col
          >
        </b-row>
        <b-row>
          <b-col v-if="type_payment === 2 && method_payment !== 3" md="12">
            <other-payment-options
              :amount="ammount"
              :idClientOrLead="client.id"
              :isClient="true"
              :idProgram="this.client.program_id"
              :idModule="currentUser.modul_id"
              :idCurrentUser="currentUser.user_id"
              :chargeId="this.description"
              :chargeDescription="this.notcharges"
              :modalityPayment="chargePayment"
              :partial-amount="ammount_partial"
              ref="otherPaymentOptions"
            ></other-payment-options
          ></b-col>

          <!-- <b-col v-if="type_payment === 2 && method_payment !== 3" md="6">
            <validation-provider name="PROGRAM">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >SERIAL NUMBER
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  maxlength="15"
                  v-model="specify"
                />
              </b-input-group> </validation-provider
          ></b-col> -->
        </b-row>
        <b-row v-if="type_payment === 1">
          <b-col>
            <b-card no-body>
              <b-table
                ref="refCards"
                small
                show-empty
                sticky-header="50vh"
                :busy="isBusy"
                :fields="fields"
                :items="searchCards"
                class="font-small-3 text-center"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template v-slot:cell(radio)="data">
                  <b-form-radio
                    v-model="card_id"
                    :disabled="data.item.count_declined >= 2"
                    :value="data.item.id"
                    :name="'card' + data.item.id"
                    @change="changeCard(data.item.type_card)"
                  />
                </template>

                <template v-slot:cell(card_holder_name)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.cardholdername }}
                  </p>
                </template>
                <template v-slot:cell(card_number)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }}
                  </p>
                </template>
                <template v-slot:cell(type)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.type_card }}
                  </p>
                </template>
                <template v-slot:cell(mm)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.card_expi_month }}
                  </p>
                </template>
                <template v-slot:cell(yy)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.card_expi_year }}
                  </p>
                </template>
                <template v-slot:cell(cvc)="data">
                  <p class="mb-0 font-weight-bold">
                    {{
                      data.item.cardsecuritycode != null
                        ? data.item.cardsecuritycode.length == 3
                          ? "XX" + data.item.cardsecuritycode.substr(2)
                          : "XXX" + data.item.cardsecuritycode.substr(3)
                        : ""
                    }}
                  </p>
                </template>
                <template v-slot:cell(created_by)="data">
                  <p class="mb-0 font-weight-bold">
                    <span>{{ data.item.created_by }}</span>
                    <br />
                    <span>{{ data.item.created_at | myGlobalDay }}</span>
                  </p>
                </template>
                <template v-slot:cell(actions)="data">
                  <feather-icon
                    size="16"
                    icon="TrashIcon"
                    class="cursor-pointer text-danger"
                    @click="openDeleteCreditCard(data.item.id)"
                  />
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>

        <b-row v-if="type_payment === 1">
          <b-col>
            <div class="d-flex justify-content-end">
              <b-button variant="success" @click="openCreateCreditCard">
                ADD</b-button
              >
            </div>
          </b-col>
        </b-row></template
      >
    </validation-observer>

    <delete-credit-card
      v-if="deleteCreditOn"
      :card_id="card_id"
      :lead_id="client.lead_id"
      @refresh="refresh"
      @close="closeDeleteCreditCard"
    />

    <template #modal-footer>
      <b-button
        v-if="edit"
        class="d-flex justify-content-center"
        variant="primary"
        @click="editCharge"
        :disabled="spinnerOn"
        :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
      >
        <b-spinner v-if="spinnerOn" small />
        UPDATE
      </b-button>
      <b-button
        v-else
        class="d-flex justify-content-center"
        variant="primary"
        @click="insertCharge"
        :disabled="spinnerOn"
        :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
      >
        <b-spinner v-if="spinnerOn" small class="mr-1" />
        Pay
      </b-button>
      <!-- condept select -- ncr = 1, na = 2, investiagtion = 19, freeze = 20 -->
      <!-- client program id --- creditexperts = 3 -->
    </template>

    <create-credit-card
      v-if="createCreditOn"
      :lead="client.lead_id"
      @reload="refresh"
      @close="closeCreateCreditCard"
    />
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";
import FinancialCommissionsService from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service";
import dataFields from "@/views/commons/components/clients/dashboard/options/pay-module/modals/fields.data";
import DeleteCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/DeleteCreditCard.vue";
import CreateCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue";
import OtherPaymentOptions from "@/views/commons/components/clients/dashboard/options/pay-module/modals/OtherPaymentOptions.vue";

export default {
  components: {
    DeleteCreditCard,
    CreateCreditCard,
    OtherPaymentOptions,
  },
  props: {
    edit: {
      type: Object,
      default: null,
    },
    titleAddCharge: {
      type: String,
      default: "",
    },
    propChargeId: {
      type: Number,
      required: false,
      default: null,
    },
    propAmount: {
      type: Number,
      required: false,
      default: null,
    },
    propAdditionalCharge: {
      type: String,
      required: false,
      default: null,
    },
    withoutFeeCharge: {
      type: Boolean,
      required: false,
      default: false,
    },
    payPartialPayment: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      createCreditOn: false,
      creditorSelect: "",
      notcharges: null,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      types: null,
      type_card: "",
      spinnerOn: false,
      isBusy: false,
      card_id: null,
      fields: dataFields,
      specify: "",
      type_payment: null,
      method_payment: null,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      finishedLoading: false,
      typecharges: [],
      valDescription: false,
      description: this.propChargeId ? this.propChargeId : null,
      amount_charge: null,
      validateCreditor: false,
      optionsPaymentMethod: [
        { text: "Direct Payment", value: 1 },
        { text: "Parcial", value: 2 },
        { text: "Fee Charge", value: 3 },
      ],
      optionsTypePayment: [
        { text: "Credit Card", value: 1 },
        { text: "Others", value: 2 },
      ],
      descriptionName: null,
      optionsType: [
        { name: "Cash", value: 1 },
        { name: "Check", value: 2 },
        { name: "Money Order", value: 3 },
        { name: "Cashier Check", value: 4 },
        { name: "Deposit", value: 5 },
        { name: "Zelle", value: 6 },
      ],
      deleteCreditOn: false,
      ammount: this.propAmount ? this.propAmount : 0,
      ammount_partial: 0,
      validarMoney: false,
      creditors: [],
      errorConcept: false,
      errorAmmount: false,
      errorPayment: false,
      errorType: false,
      errorCard: false,
      errorType2: false,
      errorSerial: false,
      errorAmmountPartial: false,
      errorConceptSpecify: false,
      errosAutorize: [],
      messageAutorize: [],
      errosList: false,
      messageList: false,
      responseCode: "",
      validador: false,
      isNcr: false,
      priceNcrSelected: 0,
      amountNcr: 0,
      chargePayment: 4,
    };
  },

  async mounted() {
    this.ownControl = true;

    if (this.edit) {
      await this.editChargeInfo();
    }
    if (this.withoutFeeCharge) {
      this.optionsPaymentMethod = [
        { text: "Direct Payment", value: 1 },
        { text: "Parcial", value: 2 },
      ];
    }

    if (this.payPartialPayment) {
      this.optionsPaymentMethod = [{ text: "Direct Payment", value: 1 }];
      this.optionsTypePayment = [{ text: "Credit Card", value: 1 }];
    }

    await this.search();
    await this.getCreditors();
    this.finishedLoading = true;
  },

  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
    }),
    modul_id() {
      return this.currentUser.modul_id;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
  },

  watch: {
    description(oldVal) {
      console.log(oldVal);
      const myVal = this.typecharges.find((element) => {
        if (element.id === oldVal) {
          return element.description ? element.description : null;
        }
      });
      this.ammount = myVal.amount;
      if (this.ammount == 0 || this.ammount == null) {
        this.amount_charge = false;
      } else {
        this.amount_charge = true;
      }
      this.isNcr = oldVal == 1 && this.moduleId === 22;

      this.descriptionName = myVal ? myVal.description : null;

      if (this.description === 5) {
        this.valDescription = true;
      } else {
        this.valDescription = false;
      }

      if (
        this.description === 15 ||
        this.description === 16 ||
        this.description === 17
      ) {
        this.validateCreditor = true;
      } else {
        this.validateCreditor = false;
      }
    },
    priceNcrSelected(newVal) {
      if (newVal != "other") this.ammount = newVal;
      else this.ammount = 0;
    },
    amountNcr(newVal) {
      this.ammount = newVal;
    },
  },
  methods: {
    async editChargeInfo() {
      this.creditorSelect = this.edit.idcreditor;
      this.description = this.edit.t_charge;
      this.ammount = this.edit.amount;
      this.notcharges = this.edit.specify;
    },

    closeCreateCreditCard() {
      this.createCreditOn = false;
    },

    openCreateCreditCard() {
      this.createCreditOn = true;
    },

    refresh() {
      this.$refs.refCards.refresh();
    },

    changeCard(id) {
      this.type_card = id;
    },

    async search() {
      try {
        let data = [];
        if (this.moduleId === 3) {
          data = await ClientsOptionsServices.getAllSubCharges({
            entrances_id: 3,
            module_id: this.moduleId,
          });
          data.data = data.data.map((charge) => {
            return { ...charge, description: charge.name };
          });
        } else if (this.moduleId === 22) {
          data = await ClientsOptionsServices.getTypeChargesPost({
            idClient: this.$route.params.idClient,
            modul: this.modul_id,
            moduleId: this.moduleId,
          });
        } else {
          data = await ClientsOptionsServices.getTypeChargesDevSolutionClients(
            this.$route.params.idClient,
            this.modul_id
          );
        }
        this.typecharges = data?.data || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    closeModal() {
      this.$emit("close");
    },

    openDeleteCreditCard(id) {
      this.card_id = id;
      this.deleteCreditOn = true;
    },

    closeDeleteCreditCard() {
      this.deleteCreditOn = false;
    },

    async searchCards() {
      try {
        const data = await ClientsOptionsServices.searchcards({
          id: this.client.lead_id,
        });
        return data.data;
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

    async getCreditors() {
      try {
        const data = await ClientsOptionsServices.getCreditors({
          id: this.$route.params.idClient,
        });
        this.creditors = data.data;

        return this.creditors;
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

    async saveOtherPayment() {
      this.addPreloader();
      try {
        await this.$refs.otherPaymentOptions.saveOtherPayment(
          this.propAdditionalCharge
        );
        this.$emit("refresh");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async insertCharge() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) return;

      if (
        this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId) &&
        (this.method_payment == 2 || this.method_payment == 3) &&
        parseFloat(this.client.monthly_amount) == 0
      ) {
        this.showGenericToast({
          position: "top-right",
          variant: "warning",
          icon: "AlertTriangleIcon",
          title: "Warning",
          text: "You can't add a partial charge or fee charge if the client doesn't have a Monthly Payment greater than 0. Please update it.",
        });
        return;
      }

      const response = await this.showConfirmSwal();
      if (!response.isConfirmed) {
        return;
      }

      if (this.type_payment == 2) {
        await this.saveOtherPayment();
        this.$emit("refresh");
        this.closeModal();
        return;
      }

      this.addPreloader();

      try {
        this.validador = true;
        const result =
          this.moduleId === 22 && this.amount === 0
            ? true
            : await this.$refs.form.validate();

        if (result) {
          this.spinnerOn = true;
          const params = this.buildParams();
          const { data } = await ClientsOptionsServices.saveCharge(params);

          if (this.method_payment === 3) {
            this.showSuccessSwal();
            this.$emit("refresh");
            this.closeModal();
            return;
          }

          this.showGenericToast({
            position: "top-right",
            variant: data.info.variant,
            icon: data.info.icon,
            title: data.info.title,
            text: data.message,
          });

          if (data.response.responseCode == 1) {
            if (
              (this.moduleId === 22 ||
                this.moduleId === 23 ||
                this.moduleId === 20 ||
                this.moduleId === 26) &&
              (this.isAgent || this.isAssistantSupervisor || this.isSupervisor)
            ) {
              await FinancialCommissionsService.createCommissionChargesCed({
                chargeId: this.description,
                base_amount: this.ammount,
                agent_id: this.currentUser.user_id,
                client_account: this.$route.params.idClient,
                module_id: this.moduleId,
              });
            }

            this.$emit("refresh");
            this.closeModal();
          }
        } else {
          this.closeModal();
        }
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      } finally {
        this.spinnerOn = false;
        this.removePreloader();
      }
    },

    buildParams() {
      return {
        id: this.$route.params.idClient || this.client.id,
        account: this.client.account,
        concept: this.description,
        ammount: this.ammount.toString(),
        ammount_partial:
          this.ammount_partial === 0 ? "" : this.ammount_partial.toString(),
        method_payment: this.getMethodPayment(),
        card_id: this.card_id ? this.card_id : "",
        type_payment: this.getTypePayment(),
        type: this.types ? this.types.toString() : 0,
        specify: this.specify,
        user_id: this.currentUser.user_id,
        notcharges: this.notcharges ? this.notcharges : this.descriptionName,
        programid: this.client.program_id,
        type_process: 0,
        modul: this.modul_id,
        merchant: this.type_card ? this.type_card : "",
        idapp: null,
        nameCharge: null,
        settlementcost: null,
        idcreditor:
          this.creditorSelect == null || this.creditorSelect === ""
            ? null
            : this.creditorSelect,
        additional_charge_id: this.propAdditionalCharge,
      };
    },
    // Get method payment
    getMethodPayment() {
      if (
        this.priceNcrSelected == "0" &&
        this.description == 1 &&
        this.moduleId === 22
      ) {
        return "1";
      } else {
        return this.method_payment.toString();
      }
    },

    // Get type payment
    getTypePayment() {
      if (
        this.priceNcrSelected == "0" &&
        this.description == 1 &&
        this.moduleId === 22
      ) {
        return "2";
      } else {
        return this.type_payment ? this.type_payment.toString() : "";
      }
    },

    async editCharge() {
      try {
        this.validador = true;
        const result = await this.$refs.form.validate();
        const resultSwal = await this.showConfirmSwal();
        if (resultSwal.isConfirmed) {
          if (result) {
            this.spinnerOn = true;
            const params = {
              p_type_charge: this.description,
              p_id: this.edit.id,
              p_charge: this.notcharges === "" ? null : this.notcharges,
              p_idcreditor:
                this.creditorSelect == null || this.creditorSelect == ""
                  ? null
                  : this.creditorSelect,
              p_settlementcost: null,
            };

            const data = await ClientsOptionsServices.getChargeAccountsById(
              params
            );

            if (data.status === 200) {
              this.$emit("refresh");
              this.showSuccessSwal();
              this.closeModal();
            }
          }
        }
      } catch (e) {
        this.spinnerOn = false;
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>

<style scoped>
.space {
  width: 63%;
}

.title_lable {
  width: 150px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

* {
  --primary-color: #3f7afa;
}

.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}

.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}

.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
}

.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
}
</style>
