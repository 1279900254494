<template>
  <b-row class="p-0 m-0">
    <b-col class="p-0 m-0">
      <b-nav card-header pills class="mt-1 ml-1 mb-0 border-bottom-info">
        <b-nav-item
          v-for="(tab, index) in tabs"
          :key="index"
          :to="{ name: tab.route }"
          exact-active-class="active"
          :link-classes="['sub-tab-nav h-full', 'px-3', bgTabsNavs]"
          @click="filterTable(tab.filter)"
        >
          {{ tab.title }}
          <span v-if="tab.total > 0" class="ml-1">
            <b-badge pill variant="danger">
              {{ tab.total }}
            </b-badge>
          </span>
        </b-nav-item>
      </b-nav>
    </b-col>
    <b-col
      cols="8"
      class="p-0 m-0 d-flex flex-wrap justify-content-end"
      style="gap: 1rem"
      v-if="isTabCustomer"
    >
      <div v-b-tooltip.hover.top="'New Tickets'" v-b-tooltip.hover.v-primary>
        <b-button
          class="custom-button-style"
          :to="{ name: tabNew }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          variant="outline-primary"
          @click="updateTicketsCustomer(2)"
        >
          <span>New Tickets</span>
          <b-badge variant="primary">
            {{ G_COUNT_INDICATORS_TICKETS.v_total_open }}
          </b-badge>
        </b-button>
      </div>
      <div
        v-b-tooltip.hover.top="'In progress Tickets'"
        v-b-tooltip.hover.v-warning
      >
        <b-button
          class="custom-button-style"
          :to="{ name: tabInProgress }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          variant="outline-warning"
          @click="updateTicketsCustomer(3)"
        >
          <span>In progress</span>
          <b-badge variant="warning">
            {{ G_COUNT_INDICATORS_TICKETS.v_total_pending }}
          </b-badge>
        </b-button>
      </div>
      <div
        v-b-tooltip.hover.top="'Solved fidelized Tickets'"
        v-b-tooltip.hover.v-success
      >
        <b-button
          class="custom-button-style"
          :to="{ name: tabFidelized }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          variant="outline-success"
          @click="updateTicketsCustomer(6)"
        >
          <span>{{
            currentTabType === "customer" ? "Fidelized" : "Completed"
          }}</span>
          <b-badge variant="success">
            {{ G_COUNT_INDICATORS_TICKETS.v_solved_with_loyalty }}
          </b-badge>
        </b-button>
      </div>
      <div
        v-b-tooltip.hover.top="'Solved not fidelized Tickets'"
        v-b-tooltip.hover.v-info
      >
        <b-button
          class="custom-button-style"
          :to="{ name: tabNotFidelized }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          variant="outline-info"
          @click="updateTicketsCustomer(5)"
        >
          <span>Not fidalized</span>
          <b-badge variant="info">
            {{ G_COUNT_INDICATORS_TICKETS.v_solved_with_no_loyalty }}
          </b-badge>
        </b-button>
      </div>
      <div
        v-b-tooltip.hover.top="'Rejected Tickets'"
        v-b-tooltip.hover.v-danger
      >
        <b-button
          class="custom-button-style"
          :to="{ name: tabRejected }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          variant="outline-danger"
          @click="updateTicketsCustomer(4)"
        >
          <span>Rejected</span>
          <b-badge variant="danger">
            {{ G_COUNT_INDICATORS_TICKETS.v_total_rejected }}
          </b-badge>
        </b-button>
      </div>
      <div
        v-b-tooltip.hover.top="'Not solved Tickets'"
        v-b-tooltip.hover.v-secondary
      >
        <b-button
          :to="{ name: tabNotSolved }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          class="custom-button-style"
          variant="outline-secondary"
          @click="updateTicketsCustomer(7)"
        >
          <span>Not solved</span>
          <b-badge variant="secondary">
            {{ G_COUNT_INDICATORS_TICKETS.v_total_not_solved }}
          </b-badge>
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      test: null,
      type: "customer",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_COUNT_INDICATORS_TICKETS:
        "NotificationStore/G_COUNT_INDICATORS_TICKETS",
      currentType: "TicketCustomerStore/G_TYPE_TICKET",
      G_COUNT_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS",
    }),

    tabInProgress() {
      const path =
        this.$route.matched[this.orderMatchedParent].parent.meta.type;
      const meta = this.$route.matched[this.orderMatched].meta;
      const routes = {
        customer: meta.routeCustomerInProgress,
        payment: meta.routePaymentInProgress,
        expense: meta.routeExpenseInProgress,
      };
      return routes[path] || null;
    },
    tabNew() {
      const path =
        this.$route.matched[this.orderMatchedParent].parent.meta.type;
      const meta = this.$route.matched[this.orderMatched].meta;

      const routes = {
        customer: meta.routeCustomerNew,
        payment: meta.routePaymentNew,
        expense: meta.routeExpenseNew,
      };

      return routes[path] || null;
    },

    tabNotSolved() {
      const path =
        this.$route.matched[this.orderMatchedParent].parent.meta.type;
      const meta = this.$route.matched[this.orderMatched].meta;
      const routes = {
        customer: meta.routeCustomerNotSolved,
        payment: meta.routePaymentNotSolved,
        expense: meta.routeExpenseNotSolved,
      };

      return routes[path] || null;
    },

    tabRejected() {
      const path =
        this.$route.matched[this.orderMatchedParent].parent.meta.type;
      const meta = this.$route.matched[this.orderMatched].meta;

      const routes = {
        customer: meta.routeCustomerRejected,
        payment: meta.routePaymentRejected,
        expense: meta.routeExpenseRejected,
      };

      return routes[path] || null;
    },
    tabCompleted() {
      const path =
        this.$route.matched[this.orderMatchedParent].parent.meta.type;
      const meta = this.$route.matched[this.orderMatched].meta;

      const routes = {
        customer: meta.routeCustomerCompleted,
        payment: meta.routePaymentCompleted,
        expense: meta.routeExpenseCompleted,
      };

      return routes[path] || null;
    },
    tabFidelized() {
      const path =
        this.$route.matched[this.orderMatchedParent].parent.meta.type;
      const meta = this.$route.matched[this.orderMatched].meta;

      const routes = {
        customer: meta.routeCustomerFidelized,
        payment: meta.routePaymentFidelized,
        expense: meta.routeExpenseFidelized,
      };
      return routes[path] || null;
    },
    tabNotFidelized() {
      const path =
        this.$route.matched[this.orderMatchedParent].parent.meta.type;
      const meta = this.$route.matched[this.orderMatched].meta;

      const routes = {
        customer: meta.routeCustomerNotFidelized,
        payment: meta.routePaymentNotFidelized,
        expense: meta.routeExpenseNotFidelized,
      };

      return routes[path] || null;
    },
    orderMatchedParent() {
      const sizeMatched = {
        5: 4,
        4: 3,
        3: 2,
      };
      return sizeMatched[this.$route.matched.length] || "Unknown";
    },
    tabs() {
      return [
        {
          title: "CUSTOMERS",
          key: 1,
          route: this.$route.matched[this.orderMatched].meta.routeCustomer,
          total: Number(this.G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_open),
          filter: "customer",
        },
        {
          title: "PAYMENTS",
          key: 2,
          route: this.$route.matched[this.orderMatched].meta.routePayment,
          total: Number(this.G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_open_p),
          filter: "payment",
        },
        {
          title: "EXPENSES",
          key: 3,
          route: this.$route.matched[this.orderMatched].meta.routeExpense,
          total: Number(this.G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_open_e),
          filter: "expense",
        },
      ];
    },
    orderMatched() {
      const sizeMatched = {
        5: 3,
        4: 2,
        3: 1,
      };
      return (
        sizeMatched[this.validateComplete ? 1 : this.$route.matched.length] ||
        "Unknown"
      );
    },
    isTabExpense() {
      return this.$route.matched[this.orderMatched].meta.type == "expense";
    },
    isTabPayment() {
      return this.$route.matched[this.orderMatched].meta.type == "payment";
    },
    isTabCustomer() {
      return this.$route.matched[this.orderMatched].meta.type == "customer";
    },
    currentTabType() {
      return this.$route.matched[this.orderMatched].meta.type;
    },
  },
  async mounted() {
    this.getIndicators();
  },
  methods: {
    ...mapActions({
      A_COUNT_INDICATORS_TICKETS:
        "NotificationStore/A_COUNT_INDICATORS_TICKETS",
      A_COUNT_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
    }),
    async getPendings() {
      await this.A_COUNT_PENDING_CUSTOMER_TICKETS({
        user_id: this.currentUser.user_id,
        client_account_id: null,
        type_ticket: this.currentTabType,
      });
    },
    async getIndicators() {
      await this.A_COUNT_INDICATORS_TICKETS({
        user_id: this.currentUser.user_id,
        module_id: this.currentUser.main_module,
        type_ticket: this.currentTabType,
      });
    },
    updateTicketsCustomer(state) {
      this.$emit("updateTicketC", state);
    },
    async filterTable(type) {
      this.type = type;
      this.$emit("filterTable", type);
      this.getIndicators();
      this.getPendings();
    },
  },
};
</script>
<style scoped>
.content-header-left {
  width: 50% !important;
}
.custom-button-style {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem;
  align-items: center;
  width: 130px;
}

.custom-button-style-filter {
  padding: 0.7rem;
}
</style>
