<template>
  <b-col
    class="d-block d-xl-flex justify-conent-center align-items-center py-2"
  >
    <div
      v-if="!isLoading && allNotes.length == 0"
      class="text-center w-100"
    >
      <strong>There are no notes to show</strong>
    </div>
    <div
      v-if="isLoading"
      class="text-center w-100 text-primary my-2"
    >
      <b-spinner class="align-middle mr-1" />
      <strong>Loading ...</strong>
    </div>
    <b-row
      v-else
      class="px-2"
      style="height: auto;max-height: 350px;overflow-y: auto;"
    >
      <b-col
        v-for="note in allNotes"
        :key="note.id"
        cols="12"
        xl="12"
        lg="12"
        md="12"
        xs="12"

        class="notes-over d-block"
      >

        <b-card
          class="w-100 border-light shadow-sm"
          :style="
            isDarkSkin && note.note_status == 'DRAFT'
              ? 'background-color: #3a3a3a !important'
              : ''
          "
          :class="{ 'bg-light': note.note_status == 'DRAFT' }"
        >
          <div class="d-flex justify-content-between mb-2">
            <span class="k-font-weight-bold">{{
              note.date | myDateText
            }}</span>
            <span :style="'color: ' + note.type_color">{{
              note.type
            }}</span>
          </div>

          <div>{{ note.text }}</div>

          <div class="d-flex justify-content-between mt-2">
            <div />
            <div>
              <span
                style="color: #baa345"
              >By {{ note.created_by_name }} ,
                {{ note.created_at | myGlobalDay }}</span>

            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

import NotesService from '@/views/crm/services/notes';

export default {

  props: {
    idLead: null,
    month: null,
    year: null,
    nameClient: null,
  },
  data() {
    return {
      onControl: false,
      isEditing: false,
      noteToSend: [],
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      allNotes: [],
      addNotesModalController: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: '',
        model: '',
      },
      totalRows: 1,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      currentYear: '',
      currentMonth: '',
      isLoading: false,
      edit: true,

    };
  },
  async created() {
    this.onControl = true;
    this.currentYear = moment().format('YYYY');
    this.currentMonth = moment().format('M');
    await this.getNotes();
  },
  methods: {
    close() {
      this.$emit('close');
    },

    async getNotes() {
      try {
        this.addPreloader();

        const response = await NotesService.getLeadNotes({
          lead_id: this.idLead,
        });

        if (response.status == 200) {
          this.allNotes = response.data;
        }

        this.removePreloader();
      } catch (error) {
        this.removePreloader();

        throw error;
      }
    },
    // async getNotes() {
    //   try {
    //     this.addPreloader();
    //
    //     const response = await NotesService.getLeadNotesPaginate({
    //       lead_id: this.idLead,
    //       perPage: this.paginate.perPage,
    //       page: this.paginate.currentPage,
    //     });
    //
    //     if (response.status == 200) {
    //       this.allNotes = response.data;
    //       this.totalRows = response.data.total;
    //       this.startPage = response.data.from || 0;
    //       this.toPage = response.data.to || 0;
    //       this.paginate.currentPage = response.data.current_page;
    //       this.paginate.perPage = response.data.per_page;
    //       this.removePreloader();
    //     }
    //
    //     this.removePreloader();
    //   } catch (error) {
    //     this.removePreloader();
    //
    //     throw error;
    //   }
    // },

  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>

<style scoped>
h1, h2, h3, h4, h5, span, p, small{
  font-family: montserrat;
}
.notes-over {
  overflow-x: hidden;
}
</style>
