import Vue from "vue"
import Vuex from "vuex"

import BankOfFlyers from "@/views/paragon/views/bank-of-flyers/bank-of-flyers.store"
import ClientsDashboard from "../views/clients/dashboard/store"
import ProcessSale from "@/views/paragon/views/process-sale/process-sale.store"
Vue.use(Vuex)

export default {
  ParagonBankOfFlyers: BankOfFlyers,
  ParagonClientDashboard: ClientsDashboard,
  ProcessSaleStore: ProcessSale,
}
