<template>
  <div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterMain"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTable()"
    >
      <template #table>
        <b-table
          ref="refDerivationsTable"
          small
          show-empty
          responsive="sm"
          :fields="fields"
          :items="myProvider"
          :busy.sync="isBusy"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
        >

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(lead_client_name)="data">
            <router-link
              :class="[textLink]"
              target="_blank"
              class
              :to="{
                name: 'sales-lead-show',
                params: {
                  id: data.item.lead_id,
                },
              }"
            ><span>{{ data.item.lead_client_name }}</span>
            </router-link>
          </template>

          <template #cell(derived_from)="data">
            <div>
              <b-badge
                :variant="
                  data.item.derived_from === 'CUSTOMER SERVICE DIGITAL' ? 'success' :
                  data.item.derived_from === 'FINANCIAL' ? 'primary' :
                  data.item.derived_from === 'SALES' ? 'warning' :
                  data.item.derived_from === 'CUSTOMER SERVICE REGULAR' ? 'info' : ''"
              >
                {{ data.item.derived_from }}</b-badge>
            </div>
          </template>

          <template #cell(sale_process_status)="data">
            <b-badge
              v-if="data.item.sale_process_status !== null"
              variant="primary"
            >
              {{ data.item.sale_process_status }}
            </b-badge>
          </template>

          <template #cell(catcher_by)="data">
            <span>{{ data.item.catcher_name }} <br> {{ data.item.created_at | myGlobalWithHour }}</span>
          </template>

          <template #cell(seller_by)="data">
            <span>{{ data.item.seller_name }} <br> {{ data.item.updated_at | myGlobalWithHour }}</span>
          </template>

          <template #cell(action)="data">
            <b-badge
              :class="data.item.seller_name === '' ? 'cursor-pointer' : null"
              :variant="data.item.seller_name === '' ? 'warning' : 'success'"
              @click="data.item.seller_name === '' ? openAssignSellerModal(data.item) : null"
            >
              <span v-if="data.item.seller_name === ''">Assign seller</span>
              <span v-if="data.item.seller_name !== ''">Assigned</span>
            </b-badge>
          </template>

        </b-table>
      </template>
    </filter-slot>

    <assign-seller-modal
      v-if="assignSellerModal"
      :derivation-data="derivedData"
      @refresh="refreshTable"
      @closeModal="closeAssignSellerModal"
    />

  </div>
</template>

<script>
import fields from '@/views/ce-digital/sub-modules/sales/views/derivations/components/data/fields.derivations-table.data'
import filter from '@/views/ce-digital/sub-modules/sales/views/derivations/components/data/filters.derivations-table.data'
import DerivationsService from '@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service'
import AssignSellerModal from '@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/AssignSellerModal.vue'
import store from '@/store'

export default {
  name: 'DerivationsTable',
  components: { AssignSellerModal },
  data() {
    return {
      // table derivations data
      filter,
      totalRows: 0,
      filterMain: {
        type: 'input',
        inputType: 'text',
        placeholder: `Search ${this.$route.meta.pageTitle === 'Lead' ? 'Lead' : 'Client'}...`,
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      fields,
      derivedData: [],
      // modals and props to assign seller
      assignSellerModal: false,
      derivationData: null,
    }
  },
  computed: {
    textLink() {
      return this.$store.getters['appConfig/skin'] === 'dark'
        ? 'text-warning font-weight-bolder'
        : 'text-primary font-weight-bolder'
    },
  },
  async created() {
    await this.getAllCeModulesExceptSales()
    await this.getSaleProcessStatus()
  },
  methods: {
    async refreshTable() {
      this.$refs.refDerivationsTable.refresh()
      await this.refreshCounters()
    },
    async refreshCounters() {
      const params = {
        program_origin: 3,
      }
      const data = await DerivationsService.getLeadClientDerivationCounters(params)
      if (data.status === 200) {
        if (data.data[0].all_count > 0) {
          const payload = {
            routeName: 'sales-derivation',
            tag: data.data[0].all_count > 99 ? '99+' : data.data[0].all_count,
          }
          store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload)
          await store.dispatch('ceDigitalSalesDerivations/A_DERIVATION_CLIENT_COUNTER', data.data[0].client_count)
          await store.dispatch('ceDigitalSalesDerivations/A_DERIVATION_LEAD_COUNTER', data.data[0].lead_count)
        }
      }
    },
    async myProvider(ctx) {
      const params = {
        is_lead: this.$route.meta.isLead ? this.$route.meta.isLead : null,
        is_client: this.$route.meta.isClient ? this.$route.meta.isClient : null,
        name_text: this.filterMain.model,
        order_by: ctx.sortBy === '' ? 'lcd.created_at' : ctx.sortBy,
        order: ctx.sortDesc ? 'asc' : 'desc',
        page: ctx.currentPage,
        perPage: ctx.perPage,
        date_from: this.filter[0].model,
        date_to: this.filter[1].model,
        derived_from: this.filter[2].model,
        process_sale_status: this.filter[3].model,
      }
      const { data } = await DerivationsService.getLeadClientDerivedAdvanced(params)
      const {
        data: derivedData,
        total,
        from,
        current_page,
        per_page,
        last_page,
        to,
      } = data
      this.derivedData = derivedData
      this.paginate.currentPage = current_page
      this.startPage = from
      this.paginate.perPage = per_page
      this.nextPage = this.startPage + 1
      this.endPage = last_page
      this.totalRows = total
      this.toPage = to
      return this.derivedData || []
    },
    openAssignSellerModal(item) {
      this.derivedData = item
      this.assignSellerModal = true
    },
    closeAssignSellerModal() {
      this.assignSellerModal = false
    },
    async getAllCeModulesExceptSales() {
      const data = await DerivationsService.getAllCeModulesExceptSales()
      if (data.status === 200) {
        this.filter[2].options = data.data
      }
    },
    async getSaleProcessStatus() {
      const data = await DerivationsService.getSaleProcessStatus()
      if (data.status === 200) {
        this.filter[3].options = data.data
      }
    },
  },
}
</script>

<style scoped>

</style>
