export default [
    {
        path: "zero-payment",
        name: "administration-zero-payment",
        component: () => import(/* webpackChunkName: "ZeroPaymentZeroPayment" */ "@/views/administration/views/zero-payment/ZeroPayment.vue"),
        meta: {
            permittedRoles: [1],
            pageTitle: "Zero Payment",
            breadcrumb: [
                {
                    text: "ZERO PAYMENT",
                    ativate: true,
                }
            ]
        }
    }
]