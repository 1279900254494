

export default [
    {
        path: "loans",
        name: "loans-tax-research",
        redirect: { name: 'my-loans-tax-research' },
        component: () => import(/* webpackChunkName: "TaxResearchLoans" */ "@/views/commons/components/loans/LoansComponent.vue"),
        children: [
            {
                path: 'my-loans',
                name: "my-loans-tax-research",
                component: () => import(/* webpackChunkName: "TaxResearchLoansMyLoans" */ "@/views/commons/components/loans/LoansView.vue"),
                meta: {
                    tab: 1,
                    pageTitle: "Loans",
                    breadcrumb: [
                        {
                            text: "My Loans",
                            active: true,
                        },
                    ],
                    route: "taxresearch",
                }
            },
            {
                path: "loans-module",
                name: "loans-module-taxresearch",
                component: () => import(/* webpackChunkName: "TaxResearchLoansModule" */ "@/views/commons/components/loans/LoansView.vue"),
                meta: {
                    tab: 2,
                    route: "taxresearch",
                    pageTitle: "Loans",
                    breadcrumb: [
                        {
                            text: "Loans By Module",
                            active: true
                        }
                    ]
                }
            }
        ],
        meta: {
            tab: 1,
            route: "taxresearch",
        }
    }
]