<script>

export default {
  data() {
    return {

      control: false,

    };
  },

  mounted() {
    this.control = true;
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};

</script>

<template>
  <div>
    <b-modal
      ref="modal_create_mof"
      v-model="control"
      size="lg"
      title="AUTONOMY LEGEND"
      header-bg-variant="info"
      header-text-variant="dark"
      body-bg-variant="ligth"
      body-text-variant="dark"
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      no-close-on-backdrop
      scrollable
      class="border-0 border-white"
      @hidden="closeModal"
    >
      <b-row>
        <b-col class="d-flex align-items-stretch">
          <b-card :style="isDarkSkin ? 'background: #25282f' : ''">
            <h1 class="text-success text-center">
              <feather-icon
                icon="CircleIcon"
                size="25"
              /> HIGH
            </h1>
            <b-card-text class="text-center">
              Makes decisions in a mostly comprehensive manner in the process(es) for which it is responsible.
            </b-card-text>
          </b-card>
        </b-col>
        <b-col class="d-flex align-items-stretch">
          <b-card :style="isDarkSkin ? 'background: #25282f' : ''">
            <h1 class="text-warning text-center">
              <feather-icon
                icon="CircleIcon"
                size="25"
              /> MEDIUM
            </h1>
            <b-card-text class="text-center">
              It can make decisions in specific cases, such as contingency measures and changes for the improvement of the Process that corresponds to it.
            </b-card-text>
          </b-card>
        </b-col>
        <b-col class="d-flex align-items-stretch">
          <b-card :style="isDarkSkin ? 'background: #25282f' : ''">
            <h1 class="text-danger text-center">
              <feather-icon
                icon="CircleIcon"
                size="25"
              />
              LOW
            </h1>
            <b-card-text class="text-center">
              Receives instructions from the immediate superior, his activities are continuously supervised.
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <template #modal-footer>
        <p>AUTONOMY LEGEND</p>
      </template>
    </b-modal>
  </div>

</template>
