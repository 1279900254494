export default [
    {
      path: "court-info",
      name: "court-info-analyst-department",
      redirect: { name: "court-info-analyst-department-in-progress" },
      component: () =>
        import(
          /* webpackChunkName: "CourtInfoMain" */
          "@/views/debt-solution/views/court-info/CourtInfoMain.vue"
        ),
      meta: {
        module: 28,
        pageTitle: "Cases",
        tabs: [
          {
            name: "PROGRESS",
            route: "court-info-analyst-department-in-progress",
          },
          {
            name: "UPDATE",
            route: "court-info-analyst-department-update",
          },
          {
            name: "DONE",
            route: "court-info-analyst-department-done",
          },
        ],
      },
      children: [
        {
          path: "cases/progress",
          name: "court-info-analyst-department-in-progress",
          component: () =>
            import(
              /* webpackChunkName: "CIGridTableInProcess" */
              "@/views/debt-solution/views/court-info/components/CIGridTable.vue"
            ),
          meta: {
            permittedRoles: [1,2,17],
            pageTitle: "Court Info",
            breadcrumb: [
              {
                text: "Cases",
                active: true,
              },
            ],
            tab: 1,
          },
        },
        {
          path: "cases/update",
          name: "court-info-analyst-department-update",
          component: () =>
            import(
              /* webpackChunkName: "CIGridTableInProcess" */
              "@/views/debt-solution/views/court-info/components/CIGridTable.vue"
            ),
          meta: {
            permittedRoles: [1,2,17],
            pageTitle: "Court Info",
            breadcrumb: [
              {
                text: "Cases",
                active: true,
              },
            ],
            tab: 3,
          },
        },
        {
          path: "cases/done",
          name: "court-info-analyst-department-done",
          component: () =>
            import(
              /* webpackChunkName: "CIGridTableDone" */
              "@/views/debt-solution/views/court-info/components/CIGridTable.vue"
            ),
          meta: {
            permittedRoles: [1,2,17],
            pageTitle: "Court Info",
            breadcrumb: [
              {
                text: "Cases",
                active: true,
              },
            ],
            tab: 2,
          },
        },
      ],
    },
  ]
  