<template>
  <div>
    <b-modal
      ref="modal-add-courts"
      :title="courtId ? 'EDIT COURT MANAGEMENT' : 'CREATE NEW COURT MANAGEMENT'"
      title-tag="h3"
      modal-class="modal-primary"
      no-close-on-backdrop
      @hidden="close"
    >
      <validation-observer ref="form">
        <b-row>
          <b-col cols="6">
            <b-form-group label="COURT NAME">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="dataSettingsCourtsTimes.ciName"
                  maxlength="100"
                />
                <span v-if="errors[0]" class="text-danger mt-1"
                  >Court name {{ errors[0] }}</span
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="COUNTY">
              <b-form-input v-model="dataSettingsCourtsTimes.county" />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="ADDRESS">
              <validation-provider v-slot="{ errors }" rules="required|max:255">
                <vue-google-autocomplete
                  id="address"
                  ref="refAddress"
                  class="form-control input-form"
                  placeholder="Please type address"
                  country="us"
                  :state="errors.length > 0 ? false : null"
                  v-model="dataSettingsCourtsTimes.address"
                  v-on:placechanged="getAddressData"
                  maxlength="100"
                  :style="errors[0] ? 'border:1px solid red !important;' : ''"
                  :class="errors[0] ? 'rounded border border-danger' : ''"
                ></vue-google-autocomplete>

                <span v-if="errors[0]" class="text-danger mt-1"
                  >Address {{ errors[0] }}</span
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="STATE:">
              <validation-provider v-slot="{ errors }" rules="required">
                <v-select
                  label="label"
                  placeholder="Select State"
                  v-model="dataSettingsCourtsTimes.selectstate"
                  :options="G_STATES"
                  :reduce="(option) => option.value"
                  :state="errors.length > 0 ? false : null"
                  :class="errors[0] ? 'select__error' : ''"
                />
                <span v-if="errors[0]" class="text-danger mt-1"
                  >State {{ errors[0] }}</span
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="CITY">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  v-model="dataSettingsCourtsTimes.city"
                />
                <span v-if="errors[0]" class="text-danger mt-1"
                  >City {{ errors[0] }}</span
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="PHONE NUMBER">
              <validation-provider v-slot="{ errors }" rules="min:14|max:14">
                <b-form-input
                  v-mask="'(###) ###-####'"
                  trim
                  :state="errors.length > 0 ? false : null"
                  v-model="dataSettingsCourtsTimes.number"
                />
                <span v-if="errors[0]" class="text-danger mt-1"
                  >Number {{ errors[0] }}</span
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="WEBSITE">
              <validation-provider v-slot="{ errors }" rules="url">
                <b-form-input
                  placeholder="http://www.example.com"
                  type="url"
                  :state="errors.length > 0 ? false : null"
                  v-model="dataSettingsCourtsTimes.website"
                  maxlength="255"
                >
                </b-form-input>
                <span v-if="errors[0]" class="text-danger mt-1"
                  >Website {{ errors[0] }}</span
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="CASE SEARCH">
              <b-form-input
                v-model="dataSettingsCourtsTimes.caseSearch"
                maxlength="255"
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="DOCUMENT ACCESS">
              <b-form-input
                v-model="dataSettingsCourtsTimes.documentAccess"
                maxlength="100"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="USER">
              <b-form-input 
              v-model="dataSettingsCourtsTimes.user" 
              maxlength="100"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="PASSWORD">
              <b-input-group>
                <b-form-input
                  :type="passwordFieldType"
                  v-model="dataSettingsCourtsTimes.password"
                  maxlength="100"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <input
                type="hidden"
                :icon="passwordToggleIcon"
                v-model="dataSettingsCourtsTimes.state"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer="{ cancel }">
        <button-cancel @click="cancel()" />
        <button-save @click="save()" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import SettingsServices from "@/views/debt-solution/views/settings/views/manageCI/services/Settings.service.js";
import { mapGetters, mapActions } from "vuex";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import buttonSave from "@/views/commons/utilities/ButtonSave.vue";
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: {
    buttonSave,
    buttonCancel,
    VueGoogleAutocomplete,
  },
  mixins: [togglePasswordVisibility],
  props: {
    courtId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dataSettingsCourtsTimes: {
        id: "",
        county: "",
        number: "",
        caseSearch: "",
        website: "",
        address: "",
        documentAccess: "",
        user: "",
        password: "",
        state: "",
        ciName: "",
        selectstate: "",
        city: "",
      },
    };
  },
  async mounted() {
    this.toggleModal("modal-add-courts");
    await this.A_GET_STATES();
    if (this.courtId) {
      this.getCourtManagementById();
      this.dataSettingsCourtsTimes.id = this.courtId;
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_STATES: "StandarStore/G_STATES",
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    ...mapActions({
      A_GET_STATES: "StandarStore/A_GET_STATES",
    }),
    close() {
      this.$emit("close");
    },
    async save() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const { data } = await SettingsServices.saveNewSettingCourtManagement(
            this.dataSettingsCourtsTimes
          );
          this.close();
          this.$emit("closing");
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            `Court saved successfully`
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async getCourtManagementById() {
      try {
        const { data } = await SettingsServices.getCourtManagementById({
          id: this.courtId,
        });
        this.dataSettingsCourtsTimes.county = data[0].county;
        this.dataSettingsCourtsTimes.number = data[0].number;
        this.dataSettingsCourtsTimes.caseSearch = data[0].case_search;
        this.dataSettingsCourtsTimes.website = data[0].website;
        this.dataSettingsCourtsTimes.address = data[0].address;
        this.dataSettingsCourtsTimes.documentAccess = data[0].document_access;
        this.dataSettingsCourtsTimes.user = data[0].user;
        this.dataSettingsCourtsTimes.password = data[0].password;
        this.dataSettingsCourtsTimes.state = data[0].state;
        this.dataSettingsCourtsTimes.ciName = data[0].ci_name;
        this.dataSettingsCourtsTimes.selectstate = data[0].state_eeuu_id;
        this.dataSettingsCourtsTimes.city = data[0].city;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    getAddressData(data, placeResultData) {
      this.$refs.refAddress.$el.value = placeResultData.formatted_address;
      this.dataSettingsCourtsTimes.address = placeResultData.formatted_address;
      this.dataSettingsCourtsTimes.city = data.locality;
      this.dataSettingsCourtsTimes.selectstate = this.G_STATES.find(
        (item) => item.slug === data.administrative_area_level_1
      ).value;
    },
  },
};
</script>
<style scoped>
.select__error {
  border: 1px solid #f1556c !important;
  border-radius: 5px;
}
</style>