export default {
    namespaced: true,
    state: {
        S_COUNTER_NCR_LEADS: 0,
        S_COUNTER_NCR_LEADS_PENDING: 0,
        S_COUNTER_NCR_LEADS_RETURNED: 0,
    },
    mutations: {
        M_SET_COUNTER_NCR_LEADS(state, payload = { total: 0, countPending: 0, countReturned: 0 }) {
            state.S_COUNTER_NCR_LEADS = payload.total;
            state.S_COUNTER_NCR_LEADS_PENDING = payload.countPending;
            state.S_COUNTER_NCR_LEADS_RETURNED = payload.countReturned;
        },
    },
    actions: {
        A_SET_COUNTER_NCR_LEADS({ commit }, payload = { totalOver: 0, doneOver: 0, accountOver: 0 }) {
            commit('M_SET_COUNTER_NCR_LEADS', payload);
        }
    },
}