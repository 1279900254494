// import AnswersGuideNavigation from '@/views/social-network/views/answers-guide/answers-guide.navigation'
import ProductRequestNavigation from "@/views/social-network/views/request/request.nav.js";

import BankOfFlyersNavigation from "@/views/social-network/views/bank-of-flyers/bank-of-flyers.navigation";
import ClientNavigation from "@/views/social-network/views/clients/clients-group.navigation";
import FileManagerNavigation from "@/views/social-network/views/file-manager/file-manager.nav";
import ScheduleNavigation from "@/views/social-network/views/schedules/schedules.nav";
import CommissionsNavigation from "@/views/social-network/views/commissions/commissions.navigation";
import RecoveryNavigation from "@/views/social-network/views/recovery/recovery.nav";
import HelpdeskNavigation from "@/views/social-network/views/helpdesk/helpdesk.nav";
import SettingsNavigation from "@/views/social-network/views/settings/settings.navigation";
import CalendarNavigation from "@/views/social-network/views/calendar/calendar.nav";
import DashboardNavigation3 from "@/views/social-network/views/dashboard3/dashboard.navigation";
import EmployeeClaimsNavigation from "@/views/social-network/views/employee-claims/navigation/employee-claims.nav";
import LeadsNav from "../views/leads/leads.nav";
import StatisticsNavigation from "@/views/social-network/views/statistics/navigation/statistics.navigation";
import RingCentralNav from "@/views/social-network/views/ring-central/ring-central.nav.js";
import MOFNavigation from "@/views/social-network/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/social-network/views/recruitment-process/social-network-recruitment_process.navigation";
import requestNcrNav from "@/views/social-network/views/request-ncr/request-ncr.nav.js";
import NoAnswerNav from "@/views/social-network/views/no-answer/no-answer.nav.js";
import MessengerNavigation from "@/views/social-network/views/messenger/navigation/messenger.navigation.js";

const navigation = [
  {
    header: "METAMEDIA",
  },
  DashboardNavigation3,
  ...MessengerNavigation,
  {
    title: "LEADS",
    icon: "UserPlusIcon",
    children: [...LeadsNav, ...NoAnswerNav, requestNcrNav],
  },
  ...RecoveryNavigation,
  ClientNavigation,
  BankOfFlyersNavigation,
  ...StatisticsNavigation,
  ...CommissionsNavigation,
  ...CalendarNavigation,
  FileManagerNavigation,
  RingCentralNav,
  SettingsNavigation,
  {
    title: "RRHH",
    icon: "AwardIcon",
    children: [
      ProductRequestNavigation,
      EmployeeClaimsNavigation,
      RecruitmentProcessNavigation,
      ...MOFNavigation,
    ],
  },
  ScheduleNavigation,
  HelpdeskNavigation,
];
export default navigation;
