<template>
  <b-dropdown
    variant="link"
    no-caret
    :right="$store.state.appConfig.isRTL"
    boundary="viewport"
  >
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="align-middle text-body"
      />
    </template>
    <!-- derivationTrackingModal -->
    <b-dropdown-item
      v-if="
        options.includes('derivationTrackingModal') &&
        rowData.is_derivated === 1
      "
      v-b-tooltip.hover.left="'Sales process tracking'"
      variant="success"
      @click="onRowDerivationTracking(rowData)"
    >
      <feather-icon icon="DollarSignIcon" />
      <span class="align-middle ml-50">Sales process tracking</span>
    </b-dropdown-item>
    <!-- derivationActionModal -->
    <!-- <b-dropdown-item
      v-if="
        options.includes('derivationActionModal') && rowData.is_derivated === 0
      "
      v-b-tooltip.hover.left="'Send to Sales'"
      @click="onRowDerivationAction(rowData, rowIndex)"
    >
      <feather-icon icon="CornerUpLeftIcon" />
      <span class="align-middle ml-50">Send to Sales</span>
    </b-dropdown-item> -->
    <template v-if="!canDerivate">

      <!-- Add Service -->
      <b-dropdown-item
        v-if="options.includes('addService')"
        variant="warning"
        @click="onRowAddService(rowData)"
      >
        <feather-icon icon="FilePlusIcon" />
        <span class="align-middle ml-50">Add service</span>
      </b-dropdown-item>
      <!-- Change Service -->

      <b-dropdown-item
        v-if="options.includes('changeService')"
        :variant="$route.matched[0].meta.module == 26 ? 'warning' : 'success'"
        @click="onRowChangeService(rowData)"
      >
        <feather-icon icon="EditIcon" />
        <span class="align-middle ml-50">Change service</span>
      </b-dropdown-item>

    
      <!-- Sales Tracing -->
      <b-dropdown-item
        v-if="options.includes('tracing')"
        variant="warning"
        @click="onRowTracing(rowData)"
      >
        <feather-icon icon="TrendingUpIcon" />
        <span class="align-middle ml-50">Tracing</span>
      </b-dropdown-item>
      <!-- Sales Recycled -->
      <b-dropdown-item
        v-if="options.includes('recycled')"
        variant="danger"
        @click="onRowRecycled(rowData)"
      >
        <feather-icon icon="TrashIcon" />
        <span class="align-middle ml-50">Recycled </span>
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    VueGoogleAutocomplete,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    rowData: {
      required: true,
    },
    rowIndex: {
      type: Number,
      default: null,
    },
    fromBoost: {
      type: Boolean,
      default: false,
    },
    statusCallRound: {
      type: Number,
    },
    canDerivate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
  },
  methods: {
    onRowProcess(id) {
      this.$emit("onRowProcess", id);
    },
    onRowToCallReturns(item) {
      this.$emit("modalToCallOpen", item);
    },
    onRowSms(item) {
      this.$emit("modalSmsOpen", item);
    },
    onRowHistorySms(item) {
      this.$emit("modalHistorySmsOpen", item);
    },
    onRowEmail(item) {
      this.$emit("modalSendEmailOpen", item);
    },
    onRowHistoryEmail(item) {
      this.$emit("modalHistoryEmailOpen", item);
    },
    onRowEdit(id) {
      this.$emit("onRowEdit", id);
    },
    onRowAddService(item) {
      this.$emit("modalAddService", item);
    },
    onRowChangeService(item) {
      this.$emit("modalChangeService", item);
    },
    onRowDelete(id) {
      this.$emit("onRowDelete", id);
    },
    onRowTracing(item) {
      this.$emit("onTracing", item, 2); // 2 Tracing
    },
    onRowRecycled(item) {
      this.$emit("onRecycled", item, 4); // 4 Recycled
    },
    onRowDerivationAction(item, index) {
      this.$emit("derivationActionModal", item, index);
    },
    onRowDerivationTracking(item) {
      this.$emit("derivationTrackingModal", item);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.sidebar-xl {
  width: 90rem;
}
</style>
