<template>
  <b-card>
    <b-card-title>
      <h4
        class="font-weight-bolder px-50 border-bottom-secondary border-2 pb-1"
      >
        Lead Information
      </h4>
    </b-card-title>
    <b-row>
      <!-- Lead Owner -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Lead Owner"
          rules="required"
        >
          <b-form-group
            label="Lead Owner"
            label-for="userId"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce">Lead Owner</span>
            </template>
            <v-select
              id="userId"
              v-model="userData.user_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :options="user_creator_owner"
              :clearable="false"
              :reduce="(el) => el.id"
              :disabled="isClient || disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Lead Owner ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Lead Source -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Lead Source"
          rules="required"
        >
          <b-form-group
            label="Lead Source"
            label-for="sourceId"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce">Lead Source</span>
            </template>
            <v-select
              v-model="userData.source_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :options="G_SOURCE_LEADS"
              :reduce="(el) => el.id"
              :clearable="false"
              :disabled="isClient || disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Lead Source ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Lead Status -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Lead Status"
          rules="required"
        >
          <b-form-group
            label="Lead Status"
            label-for="leadStatusId"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span
                class="d-flex justify-content-between align-items-center font-ce"
              >
                Lead Status
                <span
                  v-if="userData.id"
                  class="see-tracking"
                  @click="onModalTrackingChangeOpen(9, 'STATUS(LEAD)')"
                  >See Tracking</span
                >
              </span>
            </template>
            <b-input-group>
              <v-select
                v-model="userData.leadstatus_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :options="status_lead"
                style="width: 1%; flex: 1 1 auto"
                :clearable="false"
                :reduce="(el) => el.id"
                :disabled="
                  (userData.id && disabled.leadstatus_id) || disabledForm
                "
              />
              <template v-if="userData.id && !disabledForm">
                <b-input-group-append
                  v-if="!disabled.leadstatus_id"
                  class="border-right"
                >
                  <b-button
                    variant="outline-primary"
                    class="btn-sm"
                    @click="onSubmitFields"
                  >
                    <feather-icon icon="SaveIcon" class="cursor-pointer" />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append class="border-right">
                  <b-button
                    variant="outline-warning"
                    class="btn-sm"
                    @click="toggleElement('leadstatus_id')"
                    :disabled="
                      (userData.leadstatus_id == 7 ||
                        $route.matched[0].meta.module == 22 ||
                        $route.matched[0].meta.module == 23) &&
                      $route.matched[0].meta.module == 26
                    "
                  >
                    <feather-icon
                      v-if="disabled.leadstatus_id"
                      icon="Edit2Icon"
                      class="cursor-pointer"
                    />
                    <amg-icon
                      v-else
                      icon="Edit2SlashIcon"
                      class="cursor-pointer"
                    />
                  </b-button>
                </b-input-group-append>
                <!-- <b-input-group-append
                  class="cursor-pointer"
                  @click="onModalTrackingChangeOpen(9, 'STATUS(LEAD)')"
                >
                  <b-input-group-text>
                    <feather-icon icon="ListIcon" />
                  </b-input-group-text>
                </b-input-group-append> -->
              </template>
            </b-input-group>
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Lead Status ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Source Name -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Source Name"
          rules="required"
        >
          <b-form-group
            label="Source Name"
            label-for="sourceNameId"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce">Source Name</span>
            </template>
            <v-select
              v-model="userData.sourcesname_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :options="G_SOURCE_NAMES"
              :reduce="(el) => el.id"
              :clearable="false"
              :disabled="isClient || disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Source Name ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, alphaNum, email } from "@validations";
import vSelect from "vue-select";

import formValidation from "@core/comp-functions/forms/form-validation";
import CRMService from "@/views/crm/services/crm.service.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    disabledForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    userData: {
      required: true,
    },
    typeEdit: {
      type: String,
      default: "lead",
    },
  },
  data() {
    return {
      alphaNum,
      blankUserData: new Object(),
      disabled: {
        leadstatus_id: true,
      },
      email,
      required,
      status_lead: [],
      user_creator_owner: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_STATUS_LEADS: "CrmLeadStore/G_STATUS_LEADS",
      G_SELLERS: "CrmGlobalStore/G_SELLERS",
      G_SOURCE_LEADS: "CrmLeadStore/G_SOURCE_LEADS",
      G_SOURCE_NAMES: "CrmGlobalStore/G_SOURCE_NAMES",
    }),
    isClient() {
      return this.typeEdit === "client";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {
    this.getStatusLead();
    this.getUserCreatorOwner();
    this.setDataBlank("userData");

    // this.lead.address = {
    //   id: this.lead.id,
    //   prename: "main",
    //   streetReal: this.lead.street,
    //   street: this.lead.street,
    //   city: this.lead.city,
    //   state: this.lead.states_eeuu_slug,
    //   zipcode: this.lead.zipcode,
    //   country: this.lead.country,
    // };
  },
  setup(props, { emit }) {
    const { refFormObserver, getValidationState } = formValidation(() => {});

    return {
      refFormObserver,
      getValidationState,
    };
  },
  methods: {
    async getStatusLead() {
      if (this.moduleId === 4) {
        const data = await CRMService.getStatusLeadById(7);
        data.forEach((element) => {
          this.status_lead.push({
            label: element.value,
            id: element.id,
          });
        });
      } else {
        this.status_lead = this.G_STATUS_LEADS;
      }
    },
    async getUserCreatorOwner() {
      if (this.moduleId === 4) {
        const data = await CRMService.getUserCreatorOwner({
          modul: 15,
        });
        data.forEach((element) => {
          this.user_creator_owner.push({
            label: element.user_name,
            id: element.id,
          });
        });
      } else {
        this.user_creator_owner = this.G_SELLERS;
      }
    },
    ...mapActions({
      A_UPDATE_FIELDS_LEAD: "CrmLeadStore/A_UPDATE_FIELDS_LEAD",
      A_GET_SELLERS: "CrmGlobalStore/A_GET_SELLERS",
    }),
    ...mapMutations({
      M_STATUS_LEADS_CLIENT: "CrmLeadStore/M_STATUS_LEADS_CLIENT",
    }),
    setDataBlank(key) {
      this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`] = {
        ...this[key],
      };
    },
    resetElement(key, subkey) {
      const object = this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`];
      this[key][subkey] = object[subkey];
    },
    capitalize(el) {
      const element = this.userData[el];
      this.userData[el] =
        element.substr(0, 1).toUpperCase() + element.substr(1);
    },
    toggleElement(key) {
      this.disabled[key] = !this.disabled[key];
      if (this.disabled[key]) this.resetElement("userData", key);
    },
    async onSubmitFields() {
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            this.isPreloading(true);
            const response = await this.A_UPDATE_FIELDS_LEAD({
              id: this.currentUser.user_id,
              id_lead: this.userData.id,
              id_user: this.currentUser.user_id,
              typee: 9,
              street: null,
              city: null,
              state: null,
              zipcode: null,
              country: null,
              other_street: null,
              other_city: null,
              other_state: null,
              other_zipcode: null,
              other_country: null,
              phoneh: null,
              phonem: null,
              ssn: null,
              itin: null,
              other: null,
              // statusLead: this.userData.leadstatus_id,
              statusLead: this.userData.leadstatus_id,
            });
            this.isPreloading(false);
            if (this.isResponseSuccess(response)) {
              this.blankUserData.leadstatus_id = this.userData.leadstatus_id;
              this.toggleElement("leadstatus_id");
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else {
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                `Something went wrong.${response.message}`
              );
            }
          }
        })
        .catch((error) => {
          console.log("Something went wrong onSubmitFields: ", error);
          this.isPreloading(false);
          this.showErrorSwal();
        });
    },
    onModalTrackingChangeOpen(type, name) {
      this.$emit("onModalTrackingChangeOpen", {
        type,
        name,
        mapFunction: (el) => ({
          ...el,
          main_row: el.fields,
          main_row_hide: el.fields_secret,
          seeHideCell: false,
        }),
      });
    },
  },
  watch: {
    G_STATUS_LEADS(newVal, oldVal) {
      if (this.moduleId != 4) {
        this.status_lead = newVal;
      }
    },
    G_SELLERS(newVal, oldVal) {
      if (this.moduleId != 4) {
        this.user_creator_owner = newVal;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.sidebar-xl {
  width: 90rem;
}
.see-tracking {
  cursor: pointer;
  color: rgb(0, 183, 255);
  transition: text-decoration 0.1s, font-weight 0.1s;
}
.see-tracking:hover {
  text-decoration: underline;
  font-weight: bold;
}
</style>
<style scoped>
* {
  font-family: "Montserrat", sans-serif !important;
}
.form-group {
  font-size: 16px;
}
.font-ce {
  font-size: 16px;
}
.card-title h4 {
  font-size: 22px;
}
</style>
