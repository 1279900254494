import { amgApi } from "@/service/axios";

class ZeroPaymentService {
  async getZeroPaymentCounter(params) {
    try {
      const { data, status } = await amgApi.post(
        "/clients/count-tab-zero-payment",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
}
export default new ZeroPaymentService;
