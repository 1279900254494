export default [
  {
    path: 'reports',
    name: 'management-reports',
    redirect: { name: 'management-reports-monthly-payment' },
    component: () => import(/* webpackChunkName: "ManaReports" */ '@/views/commons/components/reports/Reports.vue'),
    meta: {
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Reports',
        },
      ],
    },
    children: [
      {
        path: 'monthly-payment',
        name: 'management-reports-monthly-payment',
        component: () => import(/* webpackChunkName: "ManaReportsMonthly" */ '@/views/commons/components/reports/tabs/monthly-payment/MonthlyPayment.vue'),
        meta: {
          pageTitle: 'Reports',
          breadcrumb: [
            {
              text: 'Reports',
              active: false
            },
            {
              text: 'Monthly Payment',
              active: true
            },
          ],
        },
      },
      {
        path: 'global-income',
        name: 'management-reports-global-income',
        component: () => import(/* webpackChunkName: "ManaReportsGlobalIncome" */ '@/views/commons/components/reports/tabs/global-income/GlobalIncome.vue'),
        meta: {
          pageTitle: 'Reports',
          breadcrumb: [
            {
              text: 'Reports',
              active: false
            },
            {
              text: 'Global Income',
              active: true
            },
          ],
        },
      },
      {
        path: 'payment_status',
        name: 'management-reports-payment-status',
        component: () => import(/* webpackChunkName: "ManaReportsPaymentStatus" */ '@/views/commons/components/reports/tabs/payment-status/PaymentStatus.vue'),
        meta: {
          pageTitle: 'Reports',
          breadcrumb: [
            {
              text: 'Reports',
              active: false
            },
            {
              text: 'Payment Status',
              active: true
            },
          ],
        },
      },
      {
        path: 'vs_payment',
        name: 'management-reports-vs-payment',
        component: () => import(/* webpackChunkName: "ManaReportsVSPayment" */ '@/views/commons/components/reports/tabs/vs-payment/VsPayment.vue'),
        meta: {
          pageTitle: 'Reports',
          breadcrumb: [
            {
              text: 'Reports',
              active: false
            },
            {
              text: 'VS Payment',
              active: true
            },
          ],
        },
      },
      {
        path: 'general_balance',
        name: 'management-reports-general-balance',
        component: () => import(/* webpackChunkName: "ManaReportsGBalance" */ '@/views/commons/components/reports/tabs/general-balance/GeneralBalance.vue'),
        meta: {
          pageTitle: 'Reports',
          breadcrumb: [
            {
              text: 'Reports',
              active: false
            },
            {
              text: 'General Balance',
              active: true
            },
          ],
        },
      },
      {
        path: 'commercial',
        name: 'management-reports-comercial',
        component: () => import(/* webpackChunkName: "ManaReportsGBalance" */ '@/views/commons/components/reports/tabs/comercial-report/ComercialReport.vue'),
        meta: {
          permittedRoles: [1],
          pageTitle: 'Reports',
          breadcrumb: [
            {
              text: 'Reports',
              active: false
            },
            {
              text: 'Commercial',
              active: true
            },
          ],
        },
      },
      {
        path: 'daily-settlement',
        name: 'management-reports-daily-settlement',
        component: () => import(/* webpackChunkName: "ManaReportDailyPayment" */ '@/views/commons/components/reports/tabs/daily-settlement/DailySettlementGeneral.vue'),
        meta: {
          permittedRoles: [1, 2, 17],
          pageTitle: 'Reports',
          breadcrumb: [
            {
              text: 'Reports',
              active: false
            },
            {
              text: 'Daily Settlement',
              active: true
            },
          ],
        },
      },
    ],
  },
]
