import { amgApi } from "@/service/axios";

class ChargesMadeService {
  async getCeChargesMade(params) {
    const data = await amgApi.post(
      "/credit-experts-digital/financial/get-ce-digital-financial-charges",
      params
    );
    return data;
  }
  async getAllClientTypes(params) {
    const data = await amgApi.get("/commons/get-all-client-types", params);
    return data;
  }
  async getPaymentTypesFinancial(params) {
    const data = await amgApi.get(
      "/commons/get-payment-types-financial",
      params
    );
    return data;
  }
  // async getTypesTransactions(params) {
  //   const data = await amgApi.get("/commons/get-types-transactions", params);
  //   return data;
  // }
  async updateSettlementDateTransaction(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/update-settlement-date-transaction",
        params
      );
      return data;
    } catch (error) {
      console.log("Error updateSettlementDateTransaction");
    }
  }
  async trackingSettlementDate(params) {
    try {
      const { data } = await amgApi.post(
        "credit-experts-digital/financial/tracking-settlement-date-transaction",
        params
      );
      return data;
    } catch (error) {
      console.log("Error trackingSettlementDate");
    }
  }
}

export default new ChargesMadeService();
