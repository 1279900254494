<template>
    <svg :width="width" :height="height" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="29.9299" cy="29.9299" r="29.9299" fill="url(#paint0_linear_154_671)" />
        <path
            d="M29.5474 15.7024C31.6996 15.7024 33.8629 15.7585 36.015 15.68C37.4647 15.6239 38.6913 16.5773 38.6801 18.3608C38.669 20.3238 38.6801 22.2868 38.6801 24.2498C38.6801 26.6951 38.6913 29.1517 38.669 31.597C38.6578 32.1803 38.8474 32.371 39.405 32.3149C39.7953 32.2813 40.1855 32.3037 40.5758 32.3037C41.3118 32.3149 41.7467 32.5729 41.9586 33.1225C42.1593 33.6497 41.992 34.1545 41.4345 34.6481C40.442 35.5454 39.4384 36.4428 38.4348 37.3289C36.1154 39.4041 33.796 41.4681 31.4766 43.5544C30.0604 44.822 28.9787 44.822 27.5625 43.5544C24.273 40.6043 20.9834 37.6655 17.6938 34.7154C17.5712 34.6032 17.4374 34.491 17.337 34.3676C17.0248 33.975 16.891 33.5488 17.1028 33.0665C17.3147 32.5953 17.6827 32.3373 18.1845 32.3037C18.6082 32.2813 19.0431 32.2925 19.4669 32.2925C20.3589 32.2925 20.3589 32.2925 20.3589 31.4175C20.3589 28.3553 20.3589 25.2818 20.3589 22.2195C20.3589 21.0193 20.3589 19.8303 20.3589 18.63C20.3589 18.316 20.3924 17.9907 20.437 17.6878C20.6154 16.4764 21.5075 15.6912 22.7341 15.6912C25.0089 15.6912 27.2838 15.7024 29.5474 15.7024ZM32.9485 23.3637C32.9485 22.8925 32.7366 22.6346 32.3798 22.556C31.9672 22.4551 31.7107 22.7243 31.5435 23.0608C29.7593 26.5718 27.9863 30.0715 26.2133 33.5937C25.9568 34.0984 26.0683 34.5359 26.4251 34.7266C26.8266 34.9397 27.2392 34.7714 27.5068 34.2555C29.2687 30.7782 31.0305 27.3009 32.7924 23.8236C32.8704 23.6441 32.9597 23.4758 32.9485 23.3637ZM28.6107 26.1567C28.5996 24.5975 27.3953 23.3861 25.8453 23.3973C24.3064 23.3973 23.091 24.62 23.0798 26.1679C23.0798 27.7383 24.3064 28.961 25.8787 28.9498C27.4287 28.9386 28.6219 27.7159 28.6107 26.1567ZM30.4284 31.1932C30.4284 32.7412 31.6438 33.9975 33.1604 33.9975C34.7215 33.9975 35.9593 32.7524 35.9481 31.182C35.9481 29.634 34.7327 28.4226 33.1715 28.4226C31.6438 28.4226 30.4395 29.634 30.4284 31.1932Z"
            fill="white" />
        <path
            d="M24.5517 26.168C24.5517 25.4389 25.1427 24.8444 25.8564 24.8444C26.5477 24.8444 27.1833 25.4613 27.1945 26.1568C27.2056 26.8634 26.5477 27.514 25.8452 27.5028C25.1092 27.4916 24.5405 26.8971 24.5517 26.168Z"
            fill="white" />
        <path
            d="M33.1714 32.4943C32.4354 32.4831 31.8667 31.911 31.8667 31.1931C31.8667 30.5089 32.4912 29.8695 33.1825 29.8695C33.9073 29.8583 34.5318 30.5089 34.5095 31.238C34.476 31.9559 33.9185 32.5055 33.1714 32.4943Z"
            fill="white" />
        <defs>
            <linearGradient id="paint0_linear_154_671" x1="4.81016" y1="-1.06893" x2="59.8598" y2="59.8598"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.117499" stop-color="#67E1FF" />
                <stop offset="0.822018" stop-color="#27A6ED" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            required: false,
            default: "30"
        },
        height: {
            type: String,
            required: false,
            default: "30"
        }
    }
}
</script>
  