export default [

  {
    key: 'card_holder_name',
    sortable: false,
    label: 'Card Holder Name',
  },
  {
    key: 'card_number',
    sortable: false,
    label: 'Card Number',
  },
  {
    key: 'type',
    sortable: false,
    label: 'Type',
  },
  {
    key: 'mm',
    sortable: false,
    label: 'MM',
  },
  {
    key: 'yy',
    sortable: false,
    label: 'YY',
  },

  {
    key: 'cvc',
    sortable: false,
    label: 'CVC',
  },
  {
    key: 'commentary',
    sortable: false,
    label: 'Commentary',
  },
  {
    key: 'deleted_by',
    sortable: false,
    label: 'Deleted by',
  },

]
