export default [
  {
    path: "ncr-programs",
    name: "creditexperts-ncr-programs",
    redirect: { name: "credit-experts-ncr-programs" },
    component: () =>
      import(
        /* webpackChunkName: "CreditExpertsNcrPrograms" */ "@/views/commons/components/ncr-programs/NcrPrograms.vue"
      ),
    meta: {
      is_digital: 1,
      pageTitle: "NCR Programs",
      permittedRoles: [1, 2, 3, 11, 4],
      breadcrumb: [
        {
          text: "NCR Programs",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "credit-experts-ncr-programs",
        component: () =>
          import(
            /* webpackChunkName: "CreditExpertsTable" */ "@/views/commons/components/ncr-programs/components/table/ncr-programs/NcrProgramsTable.vue"
          ),
        meta: {
          status: null,
          module: 6,
          moduleId: 6,
          is_digital: 1,
          pageTitle: "NCR Programs",
          permittedRoles: [1, 2, 3, 11, 4],
          breadcrumb: [
            {
              text: "NCR Programs",
              to: "/creditexperts/ncr-programs",
              active: true,
            },
          ],
        },
      },
      {
        path: "return",
        name: "credit-experts-ncr-programs-return",
        component: () =>
          import(
            "@/views/commons/components/ncr-programs/components/table/ncr-programs/NcrProgramsTable.vue"
          ),
        meta: {
          status: 4,
          module: 6,
          moduleId: 6,
          is_digital: 1,
          pageTitle: "NCR Programs",
          permittedRoles: [1, 2, 3, 11, 4],
          breadcrumb: [
            {
              text: "NCR Programs",
              to: "/creditexperts/ncr-programs",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "clients/account/:id",
    name: "credit-experts-dashboard-client",
    redirect: { name: "personal-information" },
    component: () =>
      import(
        /* webpackChunkName: "CreditExpertsNcrRealtorClientsAccount" */ "@/views/commons/components/clients/dashboard/Account.vue"
      ),
    props: true,
    meta: {
      is_digital: 1,
      pageTitle: "Clients",
      permittedRoles: [1, 2, 3, 11, 4],
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
  },
];
