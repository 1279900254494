export default [
    {
        path: "settings",
        name: "bussiness-settings",
        redirect: { name: "settings-accesibility" },
        component: () => import(/* webpackChunkName: "BusinessSettings" */ './index.vue'),
        meta: {
            //name:"settings-accesibility",
            pageTitle: "Settings",
            breadcrumb: [
                {
                    text: "Settings",
                    active: true
                }
            ]
        },
        children: [
            {
                path: "accesibility",
                name: "settings-accesibility",
                component: () => import(/* webpackChunkName: "BusinessAccesibility" */ "./components/TabsAccesibility.vue"),
                meta: {
                    type: 'accesibility',
                    tab: 1,
                    pageTitle: "Settings",
                    breadcrumb: [
                        {
                            text: "Accesibility",
                            active: true,
                        },
                    ],
                },
            },
            {
                path: "entrances",
                name: "settings-entrances",
                redirect: { name: "bussiness_type" },
                component: () => import(/* webpackChunkName: "BusinessEntrances" */ "./components/EntrancesView.vue"),
                meta: {
                    type: 'entrances',
                    tab: 2,
                    pageTitle: "Settings",
                    route: "./EntrancesSettingsActive",
                    breadcrumb: [{
                        text: "Settings",
                        active: true
                    }],
                },
                children: [
                    {
                        path: "bussiness-type", //url
                        name: "bussiness_type", //name
                        component: () => import(/* webpackChunkName: "BusinessEntrancesTypes" */ "./components/EntrancesSettingsActive.vue"),
                        meta: {
                            type: 'entrances',
                            tab: 2,
                            pageTitle: "Entrances",
                            breadcrumb: [
                                {
                                    text: "Business Type",
                                    active: true,
                                },
                            ]
                        },
                        props: { EntranceType: 1, isDeleted: 0 }
                    },
                    {
                        path: "shipments", //url
                        name: "shipments_url", //name
                        component: () => import(/* webpackChunkName: "BusinessEntrancesShipments" */ "./components/EntrancesSettingsActive.vue"),
                        meta: {
                            type: 'entrances',
                            tab: 2,
                            pageTitle: "Entrances",
                            breadcrumb: [
                                {
                                    text: "Shipments",
                                    active: true,
                                },
                            ],
                        },
                        props: { EntranceType: 2, isDeleted: 0 }
                    },
                    {
                        path: "add-charge", //url
                        name: "add_charge", //name
                        //redirect: { name: "charge_active" },
                        component: () => import(/* webpackChunkName: "BusinessEntrancesAddCharge" */ "./components/EntrancesCharge.vue"),
                        props: { isDeleted: 0 },
                        meta: {
                            type: 'entrances',
                            tab: 2,
                            pageTitle: "Entrances",
                            breadcrumb: [
                                {
                                    text: "Add Charge",
                                    active: true,
                                },
                            ],
                        },
                    },
                    {
                        path: "tax-status", //url
                        name: "tax_status", //name
                        component: () => import(/* webpackChunkName: "BusinessEntrancesTaxStatus" */ "./components/EntrancesSettingsActive.vue"),
                        meta: {
                            type: 'entrances',
                            tab: 2,
                            pageTitle: "Entrances",
                            breadcrumb: [
                                {
                                    text: "Tax Status",
                                    active: true,
                                },
                            ],
                        },
                        props: { EntranceType: 4, isDeleted: 0 }
                    }
                ]
            },
            {
                path: "greetings",
                name: "settings-settings",
                component: () => import(/* webpackChunkName: "BusinessSettingsSettings" */ '@/views/debt-solution/views/settings/views/components/SettingsPanel.vue'),
                meta: {
                    tab: 3,
                    pageTitle: "Settings",
                    breadcrumb: [
                        {
                            text: "Greetings",
                            active: true
                        }
                    ],
                    route: "bussiness",
                }
            },
            //predefined-answer-settings
            {
                path: "first-note",
                name: "first-note-settings",
                component: () => import(/* webpackChunkName: "BusinessPredefinedAnswer" */ "@/views/business/views/settings/components/predefined-answers/PredefinedAnswers.vue"),
                meta: {
                    tab: 4,
                    pageTitle: "Settings",
                    breadcrumb: [
                        {
                            text: "First Note",
                            active: true
                        }
                    ],
                }
            }
        ]
    },

]