<template>
  <b-modal
    v-model="onControl"
    :title="title"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    no-close-on-backdrop
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-form-group label="Type:">
        <validation-provider v-slot="{ errors }" name="type" rules="required">
          <b-skeleton v-if="isLoading" type="input" />
          <b-form-select
            v-else
            :disabled="originIsFromReply"
            v-model="motiveType"
            text-field="description"
            value-field="id"
            :state="errors[0] ? false : null"
            :options="motiveLossTypes"
            @input="parentId = null"
          />
          <span v-if="errors[0]" class="text-danger">
            Type {{ errors[0] }}</span
          >
        </validation-provider>
      </b-form-group>
      <b-form-group
        v-if="moduleId === 2 && motiveType === 5"
        label="Choose parent account:"
      >
        <b-skeleton v-if="isLoading" type="input" />
        <b-form-select
          v-else
          v-model="parentId"
          text-field="motive_description"
          value-field="id"
          :disabled="isEditing"
          :options="motivesByModule"
        />
      </b-form-group>
      <b-form-group label="Description:">
        <validation-provider
          v-slot="{ errors }"
          name="description"
          rules="required"
        >
          <b-skeleton v-if="isLoading" type="input" />
          <b-form-input
            v-else
            v-model="descriptionMotive"
            :state="errors[0] ? false : null"
          />
          <span v-if="errors[0]" class="text-danger">
            Description {{ errors[0] }}</span
          >
        </validation-provider>
      </b-form-group>
    </validation-observer>
    <template #modal-footer="{ cancel }">
      <button-cancel @click="cancel()" />
      <button-save @click="saveMotiveLoss" />
    </template>
  </b-modal>
</template>

<script>
import SettingsService from "@/views/social-network/views/settings/settings.service";
import buttonSave from "@/views/commons/utilities/ButtonSave.vue";
import buttonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    buttonSave,
    buttonCancel,
  },
  props: {
    mood: {
      type: Number,
      default: 1,
    },
    motiveData: {
      type: Object,
      default: null,
    },
    isFromReply: {
      type: Boolean,
      default: false,
    },
    typeFromReply: {
      type: Number,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onControl: false,
      motiveId: null,
      motiveType: "",
      descriptionMotive: "",
      motiveLossTypes: [],
      motivesByModule: [],
      title: "",
      parentId: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    originIsFromReply(){
      if([1,2,11].includes(this.currentUser.role_id)  && this.moduleId == 15){
        return false;
      } else {
        return this.isFromReply;
      }
    }
   
  },
  async created() {
    this.isLoading = true;
    this.onControl = true;
    this.title = this.mood === 1 ? "Create Motive" : "Edit Motive";

    if (this.moduleId === 2) {
      this.motiveType = 4;
    }
    if (this.isFromReply) {
      this.motiveType = this.typeFromReply;
    }
    if (this.motiveData != null) {
      this.motiveType = this.motiveData.type_id;
      this.descriptionMotive = this.motiveData.desc_motive;
      this.motiveId = this.motiveData.motive_id;
    }
    await this.getAllMotiveLossTypes();
    await this.getMotivesByModule();

    this.isLoading = false;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async getAllMotiveLossTypes() {
      try {
        const data = await SettingsService.getMotiveLossTypesByModule({
          module_id: this.moduleId,
        });
        if (data.status === 200) {
          this.motiveLossTypes = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getMotivesByModule() {
      try {
        const data = await SettingsService.getMotivesByModule({
          module_id: this.moduleId,
        });
        if (data.status === 200) {
          this.motivesByModule = data.data;
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async saveMotiveLoss() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            this.addPreloader();
            const data = await SettingsService.saveMotiveLoss({
              motive_id: this.motiveId,
              description: this.descriptionMotive,
              created_by: this.currentUser.user_id,
              parent_id: this.parentId,
              type: this.motiveType,
            });
            if (data.status === 200) {
              this.removePreloader();
              this.showSuccessSwal();
              this.close();
              this.$emit("reload");
            }
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped>
</style>
