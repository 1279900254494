/* eslint-disable camelcase */
import Vue from 'vue'; // To get the 'component' of 'Notification';
import store from '@/store'; // To get user(s)
import router from '@/router';

const notificationParticipantsToTicketCustomer = () => {
  window.socket.bind('notification-participants-tickets-customer-status', async data => {
    const { user_id, modul_id } = store.state.auth.currentUser;
    const routesCustomerTicket = {
      2: 'crm-customer-tickets',
      3: 'business-customer-tickets',
      4: 'administration-customer-tickets',
      5: 'debt-solution-customer-tickets',
      6: 'credit-experts-customer-tickets',
      7: 'boost-credit-customer-tickets',
      8: 'tax-research-customer-tickets',
      9: 'correspondence-customer-tickets',
      10: 'court-info-customer-tickets',
      11: 'analyst-department-customer-tickets',
      12: 'paragon-customer-tickets',
      14: 'bookeeping-customer-tickets',
      15: 'social-network-customer-tickets',
      16: 'management-customer-tickets',
      17: 'rrhh-customer-tickets',
      18: 'quality-customer-tickets',
      19: 'logistic-customer-tickets',
      20: 'connection-customer-tickets',
      22: 'ce-digital-customer-service',
      23: 'financial-customer-tickets',
      25: 'specialist-digital-customer-tickets',
      26: 'sales-customer-tickets',
      27: 'creative-customer-tickets',
    };
    if (data.arrayUsersId.includes(user_id) && user_id !== 1) {
      const objectChat = {
        name: 'ModalTicketCustomer',
        props: {
          customerTicketChat: data.otherData,
          fileType: {},

        },
      };

      // if the 'current user' is in the 'customer ticket view', the notifications are 'not displayed'.
      let currentView = store.state.TicketCustomerStore.S_CURRENT_VIEW;
      currentView = currentView ? currentView.toLowerCase() : null;
      if (!currentView) {
        await Vue.prototype.$amgAlert({
          title: 'TICKET CUSTOMER STATUS UPDATE',
          icon: 'BellIcon',
          subtitle: 'Notification from Roger Segura',
          data: [
            {
              icon: 'StarIcon',
              title: 'Status:',
              description: `${data.status.toUpperCase()}`,
            },
            {
              icon: 'CalendarIcon',
              title: 'Motive:',
              description: data.otherData.ticketName,
            },

            {
              icon: 'BookmarkIcon',
              title: 'Observations:',
              description: data.otherData.observations,
            },

            {
              icon: 'UserIcon',
              title: 'Sender:',
              description: 'Roger Segura',
            },
            {
              icon: 'BookmarkIcon',
              title: 'Client:',
              description: `${data.otherData.customer_name} from ${data.otherData.moduleName}`,
            },
          ],
          okComponentTitle: 'Open Chat',
          dataComponent: objectChat,
          okTitle: 'Open ticket',
          // ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
          ok: async () => {
            router.push({ name: routesCustomerTicket[modul_id] });
          },
        });
      }
      // Recargar los contadores de customer tickets
      const currentUserId = store.state.auth.currentUser.user_id;
      const moduleId = store.state.auth.currentUser.modul_id;
      await store.dispatch('NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS', { user_id: currentUserId });
      await store.dispatch('NotificationStore/A_COUNT_INDICATORS_TICKETS', { user_id: currentUserId, module_id: moduleId });
      const countPendingTickets = await store.getters['NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS'];
      const payload = {
        routeName: routesCustomerTicket[moduleId],
        tag: countPendingTickets.v_total_open,
      };
      store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
    }
  });
};

export default notificationParticipantsToTicketCustomer;
