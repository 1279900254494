<template>
  <div>
    <b-modal
      v-model="showModal"
      variant="primary"
      size="lg"
      hide-footer
      no-close-on-backdrop
      @hidden="closeModal"
      modal-class="custom-modal-amg"
      header-close-variant="primary"
      title-class="h3 text-white"
      centered
    >
      <template #modal-title>
        <div
          class="text-uppercase text-white font-weight-bolder"
          style="padding: 5px"
        >
          <feather-icon icon="ClockIcon" size="15" class="mr-1" />
          Tracking of
          <b v-if="lead">{{ lead.name }}</b>
        </div>
      </template>
      <b-container fluid class="pb-2 pt-1">
        <b-table
          show-empty
          :fields="fields"
          :items="myProvider"
          table-class="text-nowrap"
          responsive
          small
          class="position-relative table-new-customization"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <!-- responsible -->
          <template #cell(responsible)="{ item, index }">
            <span :class="index === 0 ? 'font-weight-bolder' : ''">
              {{
                item.responsible
                  ? "Became a client of the ceo"
                  : "Ceased to be a client of the CEO"
              }}
            </span>
          </template>
          <!-- created_at -->
          <template #cell(created_at)="{ item, index }">
            <span :class="index === 0 ? 'font-weight-bolder' : ''">
              {{ item.created_at | myGlobalDay }}
            </span>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import CustomerTicketsServices from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import { mapGetters } from "vuex";

export default {
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      fields: [
        {
          key: "responsible",
          label: "Responsible",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_at",
          label: "Date",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    this.showModal = true;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    async myProvider() {
      try {
        this.isBusy = true;
        const { data } = await CustomerTicketsServices.getTrackingClientOfCeo({
          lead_id: this.lead.id,
        });
        this.isBusy = false;
        return data;
      } catch (error) {
        this.isBusy = false;
        console.log("error in myProvider:", error);
      }
      return [];
    },
  },
};
</script>
