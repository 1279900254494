export default {
  namespaced: true,
  state: {
    S_COUNTER_SETTLEMENT: 0,
  },
  getters: {},
  mutations: {
    M_SET_COUNTER_SETTLEMENT(state, payload) {
      state.S_COUNTER_SETTLEMENT = payload;
    },
  },
  actions: {
    A_SET_COUNTER_SETTLEMENTS({ commit }, payload) {
      commit("M_SET_COUNTER_SETTLEMENT", payload);
    },
  },
};
