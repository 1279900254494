import { getNavigationByModule } from '@/helpers/navigations';

export default {
  namespaced: true,
  state: {
    S_SIDEBAR_ITEMS: [],
  },
  getters: {
    G_SIDEBAR_ITEMS: state => state.S_SIDEBAR_ITEMS,
  },
  mutations: {
    UPDATE_SIDEBAR_ITEM_PROPERTY(state, payload) {
      // eslint-disable-next-line array-callback-return
      state.S_SIDEBAR_ITEMS.map(item => {
        
        if (item?.route === payload.routeName) {
          // eslint-disable-next-line no-param-reassign
          item.tag = payload.tag;
        }
        if (item.children && item.children.length > 0) {
          item.tag = 0;
          item.children.map(child => {
            if (child?.route === payload.routeName) {
              // eslint-disable-next-line no-param-reassign
              child.tag = payload.tag;
            }

            item.tag += child.tag;
          });
        }
      });
    },
    SET_NAVIGATION_ITEMS_BY_MODULE(state, payload) {
      const navigationItems = getNavigationByModule(payload.module);
      state.S_SIDEBAR_ITEMS = navigationItems;
    },
  },
};
