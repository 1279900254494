export default [
  {
    path: "reports",
    name: "claimdepartment-reports",
    redirect: { name: "claimdepartment-reports-payment-reports" },
    component: () => import(/* webpackChunkName: "ClaimDepartamentReports" */ "@/views/commons/components/reports/Reports.vue"),
    meta: {
      pageTitle: "Reports",
      permittedRoles: [1, 2, 3, 4],
      breadcrumb: [
        {
          text: "Reports",
        },
      ],
    },
    children: [
      {
        path: "payment-reports",
        name: "claimdepartment-reports-payment-reports",
        component: () => import(/* webpackChunkName: "ClaimDepartamentReportsPayments" */ "@/views/commons/components/reports/tabs/monthly-payment/MonthlyPayment.vue"),
        meta: {
          pageTitle: "Reports",
          permittedRoles: [1, 2, 3, 4],
          breadcrumb: [
            {
              text: "Reports",
            },
            {
              text: "Payment Reports",
            },
          ],
        },
      },
    ],
  },
];
