<template>
  <div>
    <filter-slot
      :filter="filters"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :total-rows="totalRows"
      :filter-principal="filterPrincipal"
      :double-paginate="false"
      @reload="getCharges"
      @onChangeCurrentPage="getCharges"
    >
      <template #buttons>
        <div class="ml-1">
          <b-button
            v-if="!agentConnection && !readOnly"
            variant="primary"
            :disabled="isResponsibleAccount"
            @click="openChargeModal(1, null)"
          >
            <feather-icon icon="PlusIcon" size="15" />
            ADD CHARGE
          </b-button>
        </div>
      </template>
      <template #table>
        <div class="container-charge-table-ce-ing" v-if="!loading">
          <b-row class="row-item header">
            <b-col cols="2" class="row-header custom-cols">Date</b-col>
            <b-col cols="3" class="row-header custom-cols justify-content-start"
              >Type</b-col
            >
            <b-col cols="2" class="row-header custom-cols">Amount</b-col>
            <b-col cols="2" class="row-header custom-cols">Paid</b-col>
            <b-col cols="3" class="row-header custom-cols">Status</b-col>
          </b-row>

          <b-row
            class="row-item detail"
            v-for="(item, index) in items"
            v-bind:key="index + '_item'"
          >
            <b-col cols="2" class="custom-cols">
              <div class="text-center">{{ item.created_at | myGlobal }}</div>
            </b-col>
            <b-col cols="3" class="custom-cols justify-content-start">
              <div class="text-primary">{{ item.charge }}</div>
              <div v-if="item.charge == 'NEW ITEMS CR'">
                <feather-icon
                  icon="EyeIcon"
                  class="text-success cursor-pointer ml-1"
                  v-b-tooltip.hover.top="'Show new items'"
                  @click="openNewItemsModal(item)"
                />
              </div>
            </b-col>
            <b-col cols="2" class="custom-cols">
              <div>
                <div class="text-truncate">
                  $ {{ item.amount | currencyZero }}
                </div>
              </div>
            </b-col>
            <b-col cols="2" class="custom-cols">
              <div>
                <div
                  :style="
                    [10, 11].includes(item.type_transaction_id)
                      ? 'text-decoration: line-through'
                      : ''
                  "
                  class="text-truncate"
                  :class="getTotalRowInfo(item).class_amount"
                >
                  $ {{ getTotalRowInfo(item).total_amount_paid | currencyZero }}

                  <feather-icon
                  v-if="item.was_paid_for_schedule && item.general_status != 'Fee Charge'"
                  icon="InfoIcon"
                  class="cursor-info text-primary"
                  size="20"
                  v-b-tooltip.hover
                  title="Partial amount paid from the schedule"
                  />
                </div>
              </div>
            </b-col>
            <!-- general status -->
            <b-col cols="3" class="custom-cols d-flex justify-content-between">
              <div
                class="d-flex align-items-center justify-content-center custom-status w-100"
              >
                <div
                  class="status"
                  :class="getGeneralStatus(item.details, item).classCus"
                  v-b-tooltip.hover
                  :title="getGeneralStatus(item.details, item).message"
                >
                  <strong>{{
                    getGeneralStatus(item.details, item).message
                  }}</strong>
                </div>
                <feather-icon
                  v-if="getGeneralStatus(item.details, item).getMessageFromOP"
                  icon="ClockIcon"
                  class="mx-1 cursor-info"
                  :class="
                    getGeneralStatus(item.details, item).message == 'DENIED'
                      ? 'text-danger'
                      : 'text-warning'
                  "
                  size="18"
                  v-b-tooltip.hover
                  :title="getGeneralStatus(item.details, item).getMessageFromOP"
                ></feather-icon>
              </div>
              <feather-icon
                v-if="item.general_status != 'Fee Charge'"
                :icon="item.show ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                class="cursor-pointer"
                size="23"
                @click="openCollapse(index)"
              ></feather-icon>
            </b-col>
            <!-- Transaction Details -->
            <b-col cols="12" class="pl-4">
              <b-collapse
                :visible="Boolean(item.show)"
                accordion="my-accordion"
                class="my-accordion"
              >
                <template v-for="(item, index) in item.details">
                  <b-row
                    v-if="item.transaction_id || item.from_op"
                    :key="index"
                  >
                    <b-col cols="12" class="mt-1">
                      <b-row class="header-accordion">
                        <b-col cols="2">
                          <span
                            :class="
                              ![10, 11].includes(item.type_transaction_id)
                                ? 'text-success'
                                : 'text-info'
                            "
                          >
                            Transaction Detail
                          </span>
                        </b-col>
                        <b-col cols="3"></b-col>
                        <b-col cols="5"></b-col>
                        <b-col cols="2">
                          <span class="p-1">Actions:</span>
                        </b-col>
                      </b-row>
                    </b-col>
                    <!-- Transaction Detail -->
                    <b-col cols="12" class="my-1 custom-status">
                      <b-row>
                        <!-- header -->
                        <b-col cols="2">
                          <p>Transaction ID:</p>
                          <p>Method:</p>
                          <p>Date:</p>
                          <p>Created By:</p>
                          <p>Status:</p>
                          <p>Amount:</p>
                        </b-col>
                        <!-- body: -->
                        <b-col cols="8">
                          <!-- Transaction ID -->
                          <p>
                            {{
                              item.transaction_id ? item.transaction_id : "-"
                            }}
                          </p>
                          <!-- Method -->
                          <p>
                            {{
                              item.from_op && !item.status_id
                                ? "Other Payment"
                                : item.method
                                ? item.method
                                : "-"
                            }}
                          </p>
                          <p>{{ item.settlement_date | myGlobal }}</p>
                          <p>{{ item.created_by ? item.created_by : "-" }}</p>
                          <!-- Status: -->
                          <div class="d-flex align-items-center">
                            <p
                              class="status"
                              :class="getStatusVariant(item).classCus"
                              v-b-tooltip.hover
                              :title="getStatusVariant(item).message"
                            >
                              {{ getStatusVariant(item).message }}
                            </p>
                          </div>
                          <!-- amount -->
                          <template>
                            <p
                              v-if="
                                ![10, 11].includes(item.type_transaction_id)
                              "
                              class="text-success"
                            >
                              $ {{ item.amount_paid }}
                            </p>
                            <p
                              style="text-decoration: line-through"
                              class="text-danger"
                              v-if="[10, 11].includes(item.type_transaction_id)"
                            >
                              ($ {{ item.amount_paid }})
                            </p>
                          </template>
                        </b-col>
                        <b-col cols="2">
                          <div class="badge-container">
                            <div
                              class="badge-action"
                              v-if="
                                item.void &&
                                !item.from_op &&
                                !readOnly &&
                                !item.charge_back_id &&
                                ![10, 11].includes(item.type_transaction_id)
                              "
                              @click="openRefundModal(item, 1)"
                            >
                              <void-icon
                                :height="15"
                                :width="15"
                                style="margin: 3px"
                              />
                              Void
                            </div>
                            <div
                              class="badge-action"
                              v-if="
                                item.refund &&
                                !item.from_op &&
                                !readOnly &&
                                !item.charge_back_id &&
                                ![10, 11].includes(item.type_transaction_id)
                              "
                              @click="
                                openRefundModal(
                                  item,
                                  2,
                                  getTotalRowInfo(item).amount_new_total
                                )
                              "
                            >
                              <refund-icon
                                :height="17"
                                :width="17"
                                style="margin: 3px"
                              />
                              Refund
                            </div>
                            <div
                              v-if="item.status == 'DENIED' && !readOnly"
                              class="badge-action"
                              @click="openChargeModal(2, item)"
                            >
                              <cash-icon
                                :height="17"
                                :width="17"
                                style="margin: 3px"
                              />
                              Pay
                            </div>
                            <div
                              v-if="item.from_op"
                              class="badge-action"
                              @click="openChargeTrackingPayment(item)"
                            >
                              <cash-icon
                                :height="17"
                                :width="17"
                                style="margin: 3px"
                              />
                              Tracking
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
                <!-- pending partial paid charge -->
                <b-row v-if="parseFloat(item.pending_paid_charge) > 0">
                  <b-col cols="12" class="mt-1">
                    <b-row class="header-accordion"
                      ><b-col cols="2">
                        <span class="text-warning"
                          >Transaction Detail</span
                        ></b-col
                      >
                      <b-col cols="3"></b-col>
                      <b-col cols="5"></b-col>
                      <b-col cols="2">
                        <span class="p-1">Actions:</span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" class="my-1">
                    <b-row>
                      <b-col cols="2">
                        <p>Transaction ID:</p>
                        <p>Method:</p>
                        <p>Status:</p>
                        <p>Amount:</p>
                      </b-col>
                      <b-col cols="8">
                        <p>-</p>
                        <p>-</p>
                        <p class="text-warning">Pending</p>
                        <p class="text-warning">
                          $ {{ item.pending_paid_charge }}
                        </p>
                      </b-col>
                      <b-col cols="2">
                        <div class="badge-container">
                          <div
                            v-if="!readOnly"
                            class="badge-action"
                            @click="openChargeModal(3, item)"
                          >
                            <cash-icon
                              :height="17"
                              :width="17"
                              style="margin: 3px"
                            />
                            Pay
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>

          <div
            v-if="items.length == 0"
            class="text-center mt-2"
            style="font-size: 15px"
          >
            There are no records to show
          </div>
        </div>

        <div class="container-charge-table-ce-ing w-100" v-else>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
        </div>
      </template>
    </filter-slot>

    <add-charge-modal
      v-if="showModalCharge"
      :title-add-charge="titleAddCharge"
      :edit="changeChargeId"
      @close="showModalCharge = false"
      @refresh="refreshComponents"
      :prop-charge-id="currentChargeId"
      :prop-amount="currentChargeAmount"
      :prop-additional-charge="currentAditionChargeId"
      :without-fee-charge="withoutFeeCharge"
      :pay-partial-payment="payPartialPayment"
    />

    <payment-charge-modal
      v-if="showPaymentChargeModal"
      :data-charge="dataCharge"
      :title-add-charge="titleAddCharge"
      @refresh="refreshComponents"
      @close="showPaymentChargeModal = false"
    />

    <void-refund-modal
      v-if="showModalVoidRefund"
      :void-refund-data="voidRefundData"
      :type-void-refund="typeModalVoidRefund"
      :disabled-partial-void-refund-amount="true"
      @close="showModalVoidRefund = false"
      @refresh="refreshComponents"
    />

    <modal-tracking-other-payment
      v-if="showModalTrackingChargePayment"
      :title="currentCharge"
      :chargeId="currentChargeId"
      @close="showModalTrackingChargePayment = false"
    />

    <new-items-modal
      v-if="showModalNewItems"
      :clientData="clientData"
      :editMode="false"
      :additionalData="additionalData"
      :hideFooter="true"
      @close="showModalNewItems = false"
    />
  </div>
</template>

<script>
import RefundIcon from "@/icons/Refund.vue";
import { mapGetters } from "vuex";
import AddChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/AddChargeModal.vue";
import PaymentChargeModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/PaymentChargeModal.vue";
import ClientService from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
import VoidIcon from "@/icons/Void.vue";
import CashIcon from "@/icons/Cash.vue";
import VoidRefundModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundModal.vue";
import ModalTrackingOtherPayment from "@/views/commons/components/clients/dashboard/options/pay-module/components/ModalTrackingChargeOtherPayment.vue";
import NewItemsModal from "@/views/commons/components/request-ncr/views/modals/NewItemsModal.vue";

export default {
  components: {
    RefundIcon,
    AddChargeModal,
    VoidIcon,
    CashIcon,
    VoidRefundModal,
    ModalTrackingOtherPayment,
    PaymentChargeModal,
    NewItemsModal,
  },

  props: {
    clientAccountId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      refreshTable: "NewItemsStore/G_REFRESH_CHARGES",
    }),
    roleId() {
      return this.currentUser.role_id;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    agentConnection() {
      return this.moduleId == 22 && [14, 15].some((rol) => rol == this.roleId);
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  data() {
    return {
      currentCharge: "",
      items: [],
      filters: [],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Transaction ID",
        model: "",
      },
      openModalChargeEdit: false,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: null,
      totalRows: 0,
      toPage: null,
      statusActive: false,

      titleAddCharge: "",
      changeChargeId: null,
      showModalCharge: false,
      showModalTrackingChargePayment: false,

      showModalVoidRefund: false,
      typeModalVoidRefund: 2,
      voidRefundData: {},

      currentTransactionId: null,
      loading: true,

      currentChargeId: null,
      currentChargeAmount: null,
      currentAditionChargeId: null,
      withoutFeeCharge: false,
      payPartialPayment: false,

      // Payment Charge
      showPaymentChargeModal: false,
      dataCharge: {},
      titleAddCharge: "",
      addtionalData: {},
      showModalNewItems: false,
    };
  },

  watch: {
    refreshTable(newVal){
      if(newVal){
        this.getCharges();
        this.$store.dispatch("NewItemsStore/A_REFRESH_CHARGES", false)
        this.$emit("refresh");
      }
    }
  },
  methods: {
    async openNewItemsModal(item){
      this.clientData = {
        client_name: item.details[0].client_name,
        client_account_id: this.clientAccountId
      }
      this.additionalData = {
        charge_id: item.addition_charge_id
      }
      this.showModalNewItems = true
    },
    getGeneralStatus(details, item) {
      let response = {
        classCus: item.general_status_class,
        message: item.general_status,
        getMessageFromOP: null,
      };
      if (details.length === 1) {
        const detail = details[0];
        if (detail.type_transaction_id == 10) {
          response.classCus = "status-set";
          response.message = "Void";
        }

        if (detail.type_transaction_id == 11) {
          response.classCus = "status-set";
          response.message = "Refund";
        }

        if (detail.charge_back_id) {
          response.classCus = "status-charge-back";
          response.message = "Charge Back";
        }

        if (detail.from_op && !detail.status_id) {
          response.classCus = item.general_status_class;
          response.message = item.general_status;
          response.getMessageFromOP = this.getMessageFromOP(detail.status);
        }
      }
      if (details.length > 1) {
        if (item.type_transaction_id == 10) {
          response.classCus = "status-set";
          response.message = "Void";
        }
        if (item.type_transaction_id == 11) {
          response.classCus = "status-set";
          response.message = "Refund";
        }
      }
      return response;
    },
    getStatusVariant(item) {
      let response = {
        classCus: "status-1",
        message: item.status,
      };

      if (item.charge_back_id) {
        response.classCus = "status-charge-back";
        response.message = "Charge Back";
      }

      if (!item.status_id) {
        response.classCus = "status-null";
        response.message = "Pending";
      }

      if (item.status_id == 5) {
        response.classCus = "status-set";
      }

      if (!item.transaction_id && item.from_op == 0) {
        response.classCus = "status-set";
        response.message = "Fee Charge";
      }

      if (item.type_transaction_id == 10) {
        response.classCus = "status-set";
        response.message = "Void";
      }

      if (item.type_transaction_id == 11) {
        response.classCus = "status-set";
        response.message = "Refund";
      }

      return response;
    },

    openChargeModal(type, item) {
      this.changeChargeId = null;
      if (type == 1) {
        this.titleAddCharge = "ADD CHARGE";
        this.currentChargeId = null;
        this.currentChargeAmount = null;
        this.currentAditionChargeId = null;
        this.withoutFeeCharge = false;
        this.payPartialPayment = false;
      } else {
        this.titleAddCharge = "PAY CHARGE";
        this.currentAditionChargeId = item.addition_charge_id;
        this.currentChargeId = item.type_charge;
        this.currentChargeAmount =
          type == 3
            ? Number(parseFloat(item.pending_paid_charge).toFixed(2))
            : Number(parseFloat(item.amount).toFixed(2));
        this.withoutFeeCharge = type == 2 ? true : false;
        this.payPartialPayment = type == 3 ? true : false;
      }
      this.showModalCharge = true;
    },

    openChargeTrackingPayment(item) {
      this.currentChargeId = item.addition_charge_id;
      this.currentCharge = item.charge;
      this.showModalTrackingChargePayment = true;
    },

    openRefundModal(data, type, new_amount = null) {
      this.currentTransactionId = data.transaction_id;
      this.typeModalVoidRefund = type; // 1 Void, 2 Refund
      this.voidRefundData = {
        transaction_id: data.transaction_id,
        merchant: data.merchant,
        client_name: data.client_name,
        amount: new_amount ? new_amount : data.amount_paid,
        settlement_date: data.settlement_date,
      };

      this.showModalVoidRefund = true;
    },

    async getCharges() {
      try {
        this.loading = true;

        const params = {
          client_account_id: this.clientAccountId,
          search_txt: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          current_page: this.paginate.currentPage,
        };

        const { data } = await ClientService.getChargesByClientAccount(params);

        this.startPage = data.from;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        this.toPage = data.to;

        this.items = data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },

    async addTransactionToAditionalCharge(transactionInfo) {
      try {
        this.addPreloader();
        const params = {
          user_id: this.currentUser.user_id,
          merchant_id: transactionInfo.merchant_id,
          client_account_id: this.clientAccountId,
          transaction_id: transactionInfo.transaction.transId,
          amount: transactionInfo.transaction.authAmount,
          additional_charge_id: this.currentAditionChargeId,
          card_number: transactionInfo.response.accountNumber,
          auth_code: transactionInfo.response.authCode,
        };

        const { data } = await ClientService.addTransactionToAditionCharge(
          params
        );

        this.showModalAddTransaction = false;
        this.refreshComponents();
        this.showSuccessSwal(null, data.message);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async refreshComponents() {
      this.showModalCharge = false;
      this.showModalVoidRefund = false;
      this.showPaymentChargeModal = false;
      this.dataCharge = {};

      this.$emit("refresh");
      await this.getCharges();
    },

    getMessageFromOP(status) {
      if (status == "DENIED") {
        return status;
      }
      return (
        "Waiting for other payment \n\
                 (" +
        status +
        ")"
      );
    },

    openCollapse(index) {
      this.items.forEach((item, index2) => {
        if (index2 != index) {
          item.show = false;
        }
      });

      this.items[index].show = !this.items[index].show;
    },

    paymentCharge(item) {
      const data = {
        id: item.addition_charge_id,
        fee: item.charge,
        amount: item.amount,
        t_charge: item.type_charge,
        t_id: item.transaction_id,
        ncr_request_id: item.ncr_request_id,
      };
      this.titleAddCharge = "ADD CHARGE";
      this.dataCharge = data;
      this.showPaymentChargeModal = true;
    },

    getTotalRowInfo(row) {
      let classAmount = "text-positive";
      if (row.type_transaction_id == 10 || row.type_transaction_id == 11) {
        classAmount = "text-danger";
      }
      const resp = {
        amount_paid: row.amount_paid,
        amount_new_total: row.amount_paid,
        class_amount: classAmount,
        total_amount_paid: row.total_amount_paid,
      };

      return resp;
    },
  },
};
</script>

<style lang="scss">
.container-charge-table-ce-ing {
  width: 100%;
  max-height: 640px;
  overflow-y: auto;
  padding: 0 20px 5px 20px;

  .text-positive {
    color: #04b400;
  }
  .partial-refund {
    color: #7751c0;
    margin-right: 3px;
  }

  .text-negative,
  .with-partial-refund {
    color: #e35403;
  }

  .with-partial-refund {
    text-decoration: line-through;
  }
  .header {
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #0090e7 !important;
    color: #ffffff !important;
  }

  .row-header {
    padding-left: 20px;
  }

  .row-item {
    min-height: 60px;
    margin-top: 10px !important;
    color: black;
    font-size: calc(1.2rem * var(--scale));
    text-align: left;

    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    background-color: #f5f5f5;

    .custom-cols {
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;

      .type {
        color: #e35403;
        font-weight: 600;
      }
    }
    .custom-status {
      .status {
        padding: 4px 15px;
        border-radius: 20px;
        font-weight: 600;
      }

      .status-null {
        background-color: #ffe0c3;
        color: #f17500;
      }

      .status-1 {
        color: #04b400;
        background-color: #c3ffe2;
      }

      .status-set {
        color: rgb(47, 43, 43);
      }

      .status-charge-back {
        color: rgb(47, 43, 43);
        background-color: #ff4c42;
      }

      .status-partial {
        background-color: #eaadfa;
        color: #8a15f8;
      }

      .void {
        cursor: pointer;
        background-color: #ffd2d2;
        padding: 4px 15px;
        border-radius: 20px;
      }
    }

    .my-accordion {
      background-color: #f5f5f5;
      border-top: 1px solid black;
      margin: 10px 0;
      text-align: left;
      font-size: 16px;

      .header-accordion {
        font-weight: 600;
      }

      .badge-container {
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;

        .badge-action {
          // margin-top: 5px;
          cursor: pointer;
          text-align: center;
          display: flex;
          align-items: center;
          margin-left: 5px;
          margin-right: 5px;
          border-radius: 20px;
          border: 1px solid #d9d9d9;
          padding: 8px 12px;
          color: black;
          margin-bottom: 3px;
        }
      }
    }
  }

  .detail {
    // border-left: 8px solid #e35403;
    padding-top: 12px;
  }
}

.dark-layout {
  .container-charge-table-ce-ing {
    .row-item {
      color: #ffffff;
      .custom-status {
        .status-set {
          color: #ffffff;
        }
      }

      background-color: #1d1d20;

      .my-accordion {
        background-color: #1d1d20;
        border-top: 1px solid #ffffff;

        .badge-container {
          .badge-action {
            border: 1px solid #d9d9d9;
            color: #ffffff;
          }
        }
      }
    }
  }
}

@media (max-width: 1450px) and (min-width: 407px) {
  .container-charge-table-ce-ing {
    padding: 0 15px;

    .row-item {
      min-height: 45px;
      margin-top: 10px !important;
      font-size: 13px;

      .custom-cols {
        .status,
        .pre-payment,
        .void {
          padding: 2px 10px;
        }
      }
    }

    .detail {
      border-left: 4px solid #e35403;
    }
  }
}

@media (max-width: 800px) and (min-width: 120px) {
  .container-charge-table-ce-ing {
    width: 800px;
    overflow-x: auto;
  }
}
</style>