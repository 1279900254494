const router = [
  {
    path: "settlements",
    name: "debtsolution-settlements",
    redirect: { name: 'debtsolution-settlement-acount-actions' },
    component: () => import(/* webpackChunkName: "DSPayNegotiations" */ "@/views/debt-solution/views/settlements/SettlementsMain.vue"),
    meta: {
      pageTitle: "Settlements",
      breadcrumb: [
        {
          text: "Settlements",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "settlements",
        name: "debtsolution-settlement-acount-actions",
        redirect: { name: 'debtsolution-settlements-to-be-approved' },
        component: () => import(/* webpackChunkName: "DSPayNegotiationsPending" */ "@/views/debt-solution/views/settlements/Settlements.vue"),
        meta: {
          pageTitle: "Settlements",
          breadcrumb: [
            {
              text: "Settlements",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "to-be-approved",
            name: "debtsolution-settlements-to-be-approved",
            component: () => import(/* webpackChunkName: "DSSettlementsOffers" */ "@/views/debt-solution/views/settlements/components/TabSettlement.vue"),
            meta: {
              pageTitle: "Settlements",
              // permittedRoles: [9, 10, 11, 2, 1],
              tab: 1,
              breadcrumb: [
                {
                  text: "To Be Approved",
                  active: true,
                },
              ],
            }
          },
          {
            path: "to-pay",
            name: "debtsolution-settlements-to-pay",
            component: () => import(/* webpackChunkName: "DSSettlementsOffers" */ "@/views/debt-solution/views/settlements/components/TabSettlement.vue"),
            meta: {
              pageTitle: "Settlements",
              // permittedRoles: [9, 10, 11, 2, 1],
              tab: 2,
              breadcrumb: [
                {
                  text: "To Pay",
                  active: true,
                },
              ],
            }
          },
          {
            path: "finished",
            name: "debtsolution-settlements-finished",
            redirect: { name: 'debtsolution-settlements-finished-amg' },
            component: () => import(/* webpackChunkName: "DSSettlementsOffers" */ "@/views/debt-solution/views/settlements/SettlementsFinished.vue"),
            meta: {
              pageTitle: "Settlements",
              breadcrumb: [
                {
                  text: "Finished",
                  active: true,
                },
              ],
              tab: 3,
            },
            children :[
              {
                path: "amg",
                name: "debtsolution-settlements-finished-amg",
                component: () => import(/* webpackChunkName: "DSSettlementsOffersFinished" */ "@/views/debt-solution/views/settlements/components/TabSettlement.vue"),
                meta: {
                  pageTitle: "Settlements",
                  breadcrumb: [
                    {
                      text: "Finished / Amg",
                      active: true,
                    },
                  ],
                  tab: 3,
                  request_type:1,
                },
              },
              {
                path: "client",
                name: "debtsolution-settlements-finished-client",
                component: () => import(/* webpackChunkName: "DSSettlementsOffersFinished" */ "@/views/debt-solution/views/settlements/components/TabSettlement.vue"),
                meta: {
                  pageTitle: "Settlements",
                  breadcrumb: [
                    {
                      text: "Finished / Client",
                      active: true,
                    },
                  ],
                  tab: 3,
                  request_type:2,
                },
              },
            ],
          },
          {
            path: "unfinished",
            name: "debtsolution-settlements-unfinished",
            component: () => import(/* webpackChunkName: "DSSettlementsOffers" */ "@/views/debt-solution/views/settlements/components/TabSettlement.vue"),
            meta: {
              pageTitle: "Settlements",
              breadcrumb: [
                {
                  text: "Unfinished",
                  active: true,
                },
              ],
              tab: 5,
            },
          },
          {
            path: "rejected",
            name: "debtsolution-settlements-rejected",
            component: () => import(/* webpackChunkName: "DSSettlementsOffers" */ "@/views/debt-solution/views/settlements/components/TabSettlementRejected.vue"),
            meta: {
              pageTitle: "Settlements",
              // permittedRoles: [9, 10, 11, 2, 1],
              tab: 4,
              breadcrumb: [
                {
                  text: "Rejected",
                  active: true,
                },
              ],
            }
          },
        ]
      },
      {
        path: "accountsactions",
        name: "debtsolution-accounts-actions",
        redirect: { name: "debtsolution-accounts-actions-list" },
        component: () =>
          import(
        /* webpackChunkName: "DSAccountActions" */ "@/views/debt-solution/views/accounts-actions/AccountsActions.vue"
          ),
        meta: {
          pageTitle: "Settlements",
          breadcrumb: [
            {
              text: "Accounts Actions",
            },
          ],
        },
        children: [
          {
            path: "",
            name: "debtsolution-accounts-actions-list",
            component: () =>
              import(
            /* webpackChunkName: "DSAccountActionList" */ "@/views/debt-solution/views/accounts-actions/components/table/AccountsActionsTable.vue"
              ),
            meta: {
              route: "debtsolution",
              isClientsTab: true,
              pageTitle: "Settlements",
              breadcrumb: [
                {
                  text: "Accounts Actions",
                  to: "/debtsolution/accountsactions",
                  active: true,
                },
              ],
            },
          },
          {
            path: "settlement",
            name: "debtsolution-settlement-offers-list",
            component: () =>
              import(
            /* webpackChunkName: "DSSettlementOffersList" */ "@/views/debt-solution/views/accounts-actions/components/SettlementOffersTable.vue"
              ),
            meta: {
              route: "debtsolution",
              isClientsTab: true,
              pageTitle: "Settlements",
              breadcrumb: [
                {
                  text: "Settlement Offers",
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ]
  },
]

export default router