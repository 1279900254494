export default [
    {
        path: "claims",
        name: "analyst-department-claims",
        component: () =>
            import(/* webpackChunkName: "LevelClaim" */ "@/views/commons/components/claims_v2/LevelClaim.vue"),
        redirect: { name: "claims-specialists-LevelZero" },
        meta: {
            pageTitle: "Claims",
            breadcrumb: [
                {
                    text: "Claims",
                },
            ],
            routeLevelZero: "claims-specialists-LevelZero",
            routeLevelOne: "claims-specialists-LevelOne",
            routeLevelTwo: "claims-specialists-LevelTwo",
            routeLevelThree: "claims-specialists-LevelThree",
            routeAnnulled: "claims-specialists-annulled",
            routeFinished: "claims-specialists-finished",
        },
        children: [
            {
                path: "level-zero",
                name: "claims-specialists-LevelZero",
                component: () =>
                    import(/* webpackChunkName: "LevelZero" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"),
                meta: {
                    isClientsTab: true,
                    pageTitle: "Claims",
                    breadcrumb: [
                        {
                            text: "Level-zero",
                            active: true,
                        },
                    ],
                    routeLevelZero: "claims-specialists-LevelZero",
                    routeLevelOne: "claims-specialists-LevelOne",
                    routeLevelTwo: "claims-specialists-LevelTwo",
                    routeLevelThree: "claims-specialists-LevelThree",
                    routeAnnulled: "claims-specialists-annulled",
                    routeFinished: "claims-specialists-finished",
                    stateLevel: 1,
                    stateClaim: "SCALED",
                },
            },
            {
                path: "level-one",
                name: "claims-specialists-LevelOne",
                component: () => import(/* webpackChunkName: "LevelOne" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"),
                meta: {
                    isClientsTab: false,
                    pageTitle: "Claims",
                    breadcrumb: [
                        {
                            text: "Level-one",
                            active: true,
                        },
                    ],
                    routeLevelZero: "claims-specialists-LevelZero",
                    routeLevelOne: "claims-specialists-LevelOne",
                    routeLevelTwo: "claims-specialists-LevelTwo",
                    routeLevelThree: "claims-specialists-LevelThree",
                    routeAnnulled: "claims-specialists-annulled",
                    routeFinished: "claims-specialists-finished",
                    stateLevel: 2,
                    stateClaim: "SCALED",
                },
            },
            {
                path: "level-two",
                name: "claims-specialists-LevelTwo",
                component: () => import(/* webpackChunkName: "LevelTwo" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"),
                meta: {
                    isClientsTab: false,
                    pageTitle: "Claims",
                    breadcrumb: [
                        {
                            text: "Level-two",
                            active: true,
                        },
                    ],
                    routeLevelZero: "claims-specialists-LevelZero",
                    routeLevelOne: "claims-specialists-LevelOne",
                    routeLevelTwo: "claims-specialists-LevelTwo",
                    routeLevelThree: "claims-specialists-LevelThree",
                    routeAnnulled: "claims-specialists-annulled",
                    routeFinished: "claims-specialists-finished",
                    stateLevel: 3,
                    stateClaim: "SCALED",
                },
            },
            {
                path: "level-three",
                name: "claims-specialists-LevelThree",
                component: () => import(/* webpackChunkName: "LevelThree" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"),
                meta: {
                    isClientsTab: false,
                    pageTitle: "Claims",
                    breadcrumb: [
                        {
                            text: "Level-three",
                            active: true,
                        },
                    ],
                    routeLevelZero: "claims-specialists-LevelZero",
                    routeLevelOne: "claims-specialists-LevelOne",
                    routeLevelTwo: "claims-specialists-LevelTwo",
                    routeLevelThree: "claims-specialists-LevelThree",
                    routeAnnulled: "claims-specialists-annulled",
                    routeFinished: "claims-specialists-finished",
                    stateClaim: "SCALED",
                    stateLevel: 4,
                },
            },
            {
                path: "annulled",
                name: "claims-specialists-annulled",
                component: () =>
                    import(
              /* webpackChunkName: "Pending" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"
                    ),
                meta: {
                    status: 5,
                    pageTitle: "Claims",
                    breadcrumb: [
                        {
                            text: "Annulled",
                            active: true,
                        },
                    ],
                    routeLevelZero: "claims-specialists-LevelZero",
                    routeLevelOne: "claims-specialists-LevelOne",
                    routeLevelTwo: "claims-specialists-LevelTwo",
                    routeLevelThree: "claims-specialists-LevelThree",
                    routeAnnulled: "claims-specialists-annulled",
                    routeFinished: "claims-specialists-finished",
                    stateClaim: "ANNULLED",
                    stateLevel: null,
                },
            },
            {
                path: "finished",
                component: () =>
                    import(
              /* webpackChunkName: "Pending" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"
                    ),
                name: "claims-specialists-finished",
                meta: {
                    status: 2,
                    pageTitle: "Claims",
                    breadcrumb: [
                        {
                            text: "Finished",
                            active: true,
                        },
                    ],
                    routeLevelZero: "claims-specialists-LevelZero",
                    routeLevelOne: "claims-specialists-LevelOne",
                    routeLevelTwo: "claims-specialists-LevelTwo",
                    routeLevelThree: "claims-specialists-LevelThree",
                    routeAnnulled: "claims-specialists-annulled",
                    routeFinished: "claims-specialists-finished",
                    stateClaim: "COMPLETED",
                    stateLevel: null
                },
            },
        ],
    },
]