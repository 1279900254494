import store from "@/store"
import router from "@/router/index"
import Vue from "vue"

const sellerResponseAlertPusher = () => {
  window.socket.bind("update-seller-typesenior-management", async (data) => {
    // const roleId = store.state.auth.currentUser.role_id
    const current_user = store.state.auth.currentUser.user_id
    // console.log('data', data)
    if (current_user === data.to_id || current_user === data.id_session) {
      const htmlAlert = ` 
            ${
              data.status === "APPROVED"
                ? '<img src="/assets/images/icons/swal/success.svg" style="margin-bottom: 10px;" alt="alert-info-image">'
                : '<img src="/assets/images/icons/swal/error.svg" style="margin-bottom: 10px;">'
            }
                            <div style="font-weight: bold; text-align: center;"><span>Request ${
                              data.status
                            }</span></div>
                            <div style="display: flex; flex-direction: column; text-align: start">
                                <span>User: ${data.user_name}</span>
                                <span>Current Level: ${data.typesenior}</span>
                                <span>Request Level: ${
                                  data.typesenior_pending
                                } </span>
                                <span>Status: ${data.status}</span>
                            </div>
                                        `
      const res = await Vue.swal.fire({
        html: htmlAlert,
        confirmButtonText: "OK",
        allowOutsideClick: true,
        showConfirmButton: true,
      })
      if (res.value) {
        // window.open('/management/approval-process/credit-experts-sellers/pending', '_blank');
        const params = {
          to_id: store.state.auth.currentUser.user_id,
        }
        await window.amgApi.post("/commons/close-all-swal", params)
      }
    }
  })
}

export default sellerResponseAlertPusher
