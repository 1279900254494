export default [
    {
      path: "dashboard-calls",
      name: "dashboard-calls-section-management",
      component: () =>
        import(
          /* webpackChunkName: "Managment call-log" */
          "@/views/commons/components/ring-central/dashboard/DashboardCall.vue"
        ),
      meta: {
        pageTitle: "Calls",
        permittedRoles: [1, 2, 17],
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
  
      },
    },
  ]
  