<template>
  <div>
    <b-modal
      ref="modalDataByProgram"
      modal-class="custom-modal-amg"
      title-class="h3 text-white"
      :title="title"
      size="xl"
      header-bg-variant="transparent"
      header-class="p-0"
      @hidden="closeModal()"
      centered
      hide-footer
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>{{ title }}</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModal()"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <div>
        <filter-slot
          :filter="[]"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          :send-multiple-sms="true"
          @reload="$refs['new-client-done-table'].refresh()"
          @sendMultipleSms="modalSmssOpen"
        >
          <!-- <template #buttons>
            <b-button
              variant="success"
              class="ml-1"
              :disabled="!selected.length"
              @click="modalSmssOpen"
            >
              <feather-icon icon="MessageCircleIcon" class="mr-50" />Send SMS
            </b-button>
          </template> -->
          <b-table
            slot="table"
            ref="new-client-done-table"
            id="new-client-done-table"
            sticky-header="70vh"
            small
            show-empty
            no-provider-filtering
            :busy.sync="isBusy"
            responsive="sm"
            :items="search"
            :fields="filteredFields"
            :per-page="paginate.perPage"
            :current-page="paginate.currentPage"
            :filter="filter"
            :has-provider="true"
            class="position-relative table-new-customization"
            :class="['text-center']"
            foot-clone
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(client)="data">
              <div class="text-left">
                <router-link
                  :class="textLink"
                  :to="routerClient(data.item)"
                  target="_blank"
                >
                  {{ data.item.client }}
                </router-link>
                <div>
                  {{ data.item.account }}
                </div>
              </div>
            </template>
            <template #cell(type)="data">
              <b-row
                class="cursor-pointer text-important"
                @click="openModalProgram(data.item, data.index)"
              >
                <b-col v-if="data.item.type === 1" v-b-tooltip.bottom="'Add'">
                  AD
                </b-col>
                <b-col v-else v-b-tooltip.bottom="'Change'"> CH </b-col>
              </b-row>
            </template>
            <template #cell(first_program)="data">
              <program-icon
                :programName="data.item.first_program"
                :programId="data.item.first_program_id"
              />
            </template>

            <template #cell(program)="data">
              <div v-if="onlyShowModules">
                <program-icon
                  :programName="data.item.program"
                  :programId="data.item.program_id"
                />
              </div>
              <div v-else>
                <div
                  @click="openModalProgram(data.item, data.index)"
                  class="cursor-pointer"
                >
                  <program-icon
                    :programName="data.item.program"
                    :programId="data.item.program_id"
                    :showGrayImg="data.item.haveRates !== 1"
                  />
                </div>
              </div>
            </template>
            <template #cell(executives)="data">
              <b-row class="justify-content-center">
                <span
                  class="text-info text-truncate col-9 p-0"
                  v-b-tooltip.hover.top="`Seller`"
                >
                  {{ data.item.seller }}
                </span>
                <!-- <p v-if="data.item.commission" class="p-0 m-0 col-3 w-100">
                  <template v-for="(item, index) in data.item.commission">
                    <small
                      v-if="item.type == 'sale'"
                      class="text-primary font-weight-bolder"
                      :key="index"
                    >
                      $ {{ item.commission }}
                    </small>
                  </template>
                </p> -->
              </b-row>
              <b-row class="mt-1 justify-content-center">
                <span
                  class="text-warning text-truncate col-9 p-0"
                  v-b-tooltip.hover.top="`Catcher`"
                >
                  {{ data.item.captured }}
                </span>
                <!-- <p v-if="data.item.commission" class="p-0 m-0 col-3 w-100">
                  <template v-for="(item, index) in data.item.commission">
                    <template v-if="item.type == 'capture'">
                      <small class="text-primary font-weight-bolder" :key="index">
                        $ {{ item.change != 1 ? item.commission : "-" }}
                      </small>
                    </template>
                  </template>
                </p> -->
              </b-row>
            </template>
            <template #cell(fee)="data">
              <b-row>
                <b-col>
                  <p>$ {{ data.item.fee | currency }}</p>
                </b-col>
              </b-row>
            </template>
            <template #cell(initial_amount)="data">
              <div
                class="cursor-pointer"
                @click="openInitialPaymentModal(data.item, data.index)"
              >
                <b-icon
                  v-if="data.item.initial_payment_status === 1"
                  icon="wallet2"
                  variant="muted"
                />
                <p
                  v-else-if="data.item.initial_payment_status === 2"
                  class="text-success font-weight-bold"
                >
                  $ {{ data.item.initial_amount | currency }}
                </p>
                <b-icon
                  v-else-if="data.item.initial_payment_status === 3"
                  icon="wallet2"
                  variant="warning"
                />
              </div>
            </template>
            <template #cell(contract_fee_status)="data">
              <b-icon
                :class="
                  data.item.initial_payment_status != 1 ? 'cursor-pointer' : ''
                "
                icon="file-text"
                :variant="
                  data.item.contract_fee_status === 0 ||
                  (data.item.contract_fee_status === 1 &&
                    data.item.initial_payment_status === 3)
                    ? 'muted'
                    : data.item.contract_fee_status === 1 &&
                      data.item.initial_payment_status === 2
                    ? 'success'
                    : data.item.contract_fee_status === 2
                    ? 'danger'
                    : ''
                "
                @click="
                  data.item.initial_payment_status != 1 &&
                    openContractFeeModal(data.item, data.index)
                "
              />
            </template>
            <template #cell(notes_status)="data">
              <b-icon
                v-if="data.item.creates > '2021-05-16 00:00:00'"
                icon="chat-square-text-fill"
                class="cursor-pointer"
                :variant="
                  data.item.notes_status_new == null
                    ? 'muted'
                    : data.item.notes_status_new == 0
                    ? 'success'
                    : 'warning'
                "
                @click="notesModal(data.item, data.index)"
              />
              <b-icon
                v-else
                icon="chat-square-text-fill"
                class="cursor-pointer"
                :variant="data.item.notes_status === 0 ? 'muted' : 'success'"
                @click="notesModal(data.item, data.index)"
              />
            </template>
            <template #cell(summary)="data">
              <div>
                <b-icon
                  @click="summaryModal(data.item, data.index)"
                  class="cursor-pointer font-medium-2"
                  icon="telephone-fill"
                  :variant="
                    data.item.notes_ids == null
                      ? 'muted'
                      : data.item.note_transcription_status == 'COMPLETED'
                      ? 'success'
                      : 'warning'
                  "
                  v-b-tooltip.hover.top="'Summary'"
                />
              </div>
            </template>
            <template #cell(trackings)="data">
              <b-icon
                class="cursor-pointer"
                icon="list-check"
                :variant="data.item.trackings ? 'success' : 'muted'"
                @click="
                  openTrackingModal(
                    data.item.program,
                    data.item.client,
                    data.item.trackings
                  )
                "
              />
            </template>
            <template #cell(status)="data">
              <p
                class="m-0 font-weight-bold font-small-3"
                :class="'color: text-' + data.item.status_variant"
              >
                {{ data.item.status_name }}
              </p>
            </template>
            <template #cell(date_time)="data">
              <div
                v-if="[3, 4].includes(statusTab)"
                class="font-weight-bold text-success mb-1"
                v-b-tooltip.hover.top="'Approved'"
              >
                {{ data.item.approved | myGlobalDay }}
              </div>
              <div class="text-warning" v-b-tooltip.hover.top="'Created'">
                {{ data.item.creates | myGlobalDay }}
              </div>
            </template>
            <template #foot(client)>
              <!-- <div>&nbsp;</div> -->
              <div class="d-flex flex-column mt-1" style="gap: 5px">
                <b-badge variant="success">SUBTOTAL:</b-badge>

                <b-badge variant="success">TOTAL:</b-badge>
              </div>
            </template>
            <template #foot(executives)>
              <div>&nbsp;</div>
              <!-- <div class="d-inline-flex w-100">
                <b-badge variant="light-info" class="col-6"> SELLER </b-badge>
                <b-badge variant="light-warning" class="col-6"> CATCHER </b-badge>
              </div>
              <div class="d-flex flex-column align-items-end mt-1">
                <div class="d-flex w-100">
                  <span class="col-6">$ {{ totals.subTCMS | currency }}</span>
                  <span class="col-6">$ {{ totals.subTCMC | currency }} </span>
                </div>
                <div class="d-flex w-100">
                  <span class="col-6">$ {{ totals.TCMS | currency }}</span>
                  <span class="col-6">$ {{ totals.TCMC | currency }}</span>
                </div>
              </div> -->
            </template>
            <template #foot(fee)>
              <!-- <div>&nbsp;</div> -->
              <div class="d-flex flex-column mt-1 align-items-center">
                <span>$ {{ totals.subTFEE | currency }}</span>

                <span>$ {{ totals.TFEE | currency }}</span>
              </div>
            </template>
            <template #foot(initial_amount)>
              <!-- <div>&nbsp;</div> -->
              <div class="d-flex flex-column mt-1 align-items-center">
                <span>$ {{ totals.subTIP | currency }}</span>

                <span>$ {{ totals.TIP | currency }}</span>
              </div>
            </template>
            <!-- <template #foot(contract_fee_status)>
              <div class="d-flex flex-column mt-1 align-items-center">
                <span>$ {{ totals.subTMA | currency }}</span>

                <span>$ {{ totals.TMA | currency }}</span>
              </div>
            </template> -->
            <template #foot()>&nbsp;</template>
          </b-table>
        </filter-slot>
      </div>
      <validate-date-and-hour
        v-if="modal.modalValidateDateAndHour"
        :modal="modal"
        :validation-data="modalData.validateDateAndHourData"
        @revission="modalRevisionAndValidateDateAndHour"
      />
      <component
        :is="modalData.programs.programSelected"
        v-if="modal.programs"
        :modal-services="modal.programs"
        :sales-client="modalData.programs.salesClient"
        :type-modal="modalData.programs.typeModal"
        :typeService="'add/change'"
        @closeModal="hideModalProgram"
      />
      <initial-payment-modal
        v-if="modal.initial_payment"
        :modal="modal"
        :initial_payment="modalData.initial_payment"
        @reload="searchSaleProgramById()"
        @close="hideInitialPaymentModal"
      />
      <revission-modal
        v-if="modal.revission"
        :modal="modal"
        :revission="modalData.revission"
        @click="
          searchSaleProgramById();
          modal.revission = false;
        "
        @response="
          searchSaleProgramById();
          modal.revission = false;
        "
      />

      <!-- NOTES -->
      <component
        :is="modalData.notes.programSelected"
        v-if="modal.notes"
        :note-info="modalData.notes"
        @hide="closeModalNotes"
        @reload="searchSaleProgramById()"
      />
      <contract-fee-modal
        v-if="modal.contract_fee"
        :modal="modal"
        :contract-fee="modalData.contractFee"
        @close="modal.contract_fee = false"
        @reload="searchSaleProgramById()"
      />
      <call-summary-modal
        v-if="showSummaryModal"
        :idNotes="itemSelected.notes_ids"
        :tab="'clients'"
        :saleData="itemSelected"
        @closeModal="showSummaryModal = false"
        @refreshTable="searchSaleProgramById()"
      />
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import vSelect from "vue-select";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import dataFilters from "@/views/crm/views/sales-made/components/add-change/filters.data";
import dataActions from "@/views/crm/views/sales-made/components/add-change/actions.data";
import CrmService from "@/views/crm/services/crm.service";
import TrackingModal from "@/views/crm/views/sales-made/components/modals/TrackingModal.vue";
import InitialPaymentModal from "@/views/crm/views/sales-made/components/modals/InitialPaymentModal.vue";
import FilesModal from "@/views/crm/views/sales-made/components/modals/FilesModal.vue";
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import RegionCreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/RegionCreditExpertsModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import RevissionModal from "@/views/crm/views/sales-made/components/modals/RevissionModal.vue";
import UrlModal from "@/views/crm/views/sales-made/components/modals/UrlModal.vue";
import { amgApi } from "@/service/axios";
import ContractFeeModal from "@/views/crm/views/sales-made/components/modals/ContractFeeModal.vue";
import ModalNotesTax from "@/views/commons/components/first-notes/ModalNotesTax.vue";
import ModalNotesFirst from "@/views/commons/components/first-notes/ModalNotesFirst.vue";
import ModalNotesBoost from "@/views/commons/components/first-notes/ModalNotesBoost.vue";
import ModalNotesParagon from "@/views/commons/components/first-notes/ModalNotesParagon.vue";
import ModalHistorySms from "@/views/crm/views/Lead/lead-sms/ModalHistorySms.vue";
import ModalSendSms from "@/views/crm/views/Lead/lead-sms/ModalSendSms.vue";
import ModalNotesCredit from "@/views/commons/components/first-notes/ModalNotasCredit.vue";
import ModalNotesSpecialist from "@/views/commons/components/first-notes/ModalNotesSpecialist.vue";
import ModalNotesAll from "@/views/commons/components/first-notes/ModalNotesAll.vue";
import NotesServices from "@/views/commons/components/first-notes/services/notes.service";
import moment from "moment";
import ValidateDateAndHour from "@/views/crm/views/sales-made/components/modals/ValidateDateAndHour.vue";
import LeadService from "@/views/ce-digital/sub-modules/sales/views/leads/service/leads.service";
import ProgramIcon from "@/views/commons/utilities/ProgramIcon.vue";
import BinnacleModal from "@/views/debt-solution/views/sales-made/modal/BinnacleModal.vue";
import CallSummaryModal from "@/views/crm/views/sales-made/components/modals/CallSummaryModal.vue";
import AnnulledSalesModalClients from "@/views/crm/views/sales-made/components/modals/AnnulledSalesModalClients.vue";
import NotSoldsClientsModal from "@/views/crm/views/sales-made/components/modals/NotSoldsClientsModal.vue";
import ProgramsCRMDone from "@/views/commons/sales-made/ProgramsCRMDone.vue";
import FilterButtonByMonth from "@/views/commons/sales-made/FilterButtonByMonth.vue";
import ModalReportSalesMade from "@/views/commons/sales-made/Modals/ModalReportSalesMade.vue";
import Vue from "vue";
import SalesMadeService from "@/views/commons/sales-made/sales-made.service.js";
export default {
  name: "SalesMadeAddChangeComponent",
  props: {
    actual_program: {
      default: null,
    },
    year: {
      default: null,
    },
    month: {
      default: null,
    },
    selected_program: {
      default: null,
    },
  },
  components: {
    // Utils Components
    FilterSlot,
    vSelect,
    // SMS Modal
    ModalSendSms,
    ModalHistorySms,
    // Contract Fee Modal
    ContractFeeModal,
    // Url Modal
    UrlModal,
    // Actions Modal
    RevissionModal,
    // Files Modal
    FilesModal,
    // Initial Payment Modal
    InitialPaymentModal,
    // Tracking Modal
    TrackingModal,
    // Programs Modals
    CreditExpertsModal,
    RegionCreditExpertsModal,
    BusinessModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    // Programs Notes Modal
    ModalNotesBoost,
    ModalNotesParagon,
    ModalNotesFirst,
    ModalNotesTax,
    ModalNotesCredit,
    ModalNotesSpecialist,
    ModalNotesAll,
    ValidateDateAndHour,
    ProgramIcon,
    BinnacleModal,
    CallSummaryModal,
    AnnulledSalesModalClients,
    NotSoldsClientsModal,
    ProgramsCRMDone,
    FilterButtonByMonth,
    ModalReportSalesMade,
  },
  data() {
    return {
      dataMonths: [],
      dataSalesMadeByPrograms: [],
      showNotSoldsModal: false,
      showAnnulledModal: false,
      chiefRols: [],
      actions: dataActions,
      totals: {
        TCMC: "0.00",
        TCMS: "0.00",
        TFEE: "0.00",
        TIP: "0.00",
        TMA: "0.00",
        subTCMC: "0.00",
        subTCMS: "0.00",
        subTFEE: "0.00",
        subTIP: "0.00",
        subTMA: "0.00",
      },
      contactHourSecondsValidate48hours: null,
      // Filter Slot Data
      filter: dataFilters,
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      basicSearch: true,
      startPage: null,
      toPage: null,
      // Modals data
      modal: {
        tracking: false,
        initial_payment: false,
        files: false,
        programs: false,
        revission: false,
        url: false,
        contract_fee: false,
        notes: false,
        modalSms: false,
        modalValidateDateAndHour: false,
        binnacle: false,
      },
      modalData: {
        historySms: {
          id: null,
          leadName: "",
        },
        sendSms: {
          leads_sms: [],
          typesms: null,
          leads_sms_o: [],
          name_leads_arr: [],
        },
        url: {
          client: "",
          program: "",
          amount: 0.0,
        },
        tracking: {
          program: "",
          client: "",
          tabla: "",
        },
        initial_payment: {
          payments: null,
          nameProgram: null,
          nameClient: null,
          type: null,
          editmodal: null,
          statusSale: null,
          sessionId: null,
          valorInitalPayment: null,
          feeprops: null,
          modul: null,
          cfeestatus: null,
          idtransaction: null,
          programid: null,
          allcards: null,
          role_id: null,
          type_payment_ce: null,
        },
        files: {
          id: null,
          program: "",
          client: "",
          sale_id: null,
          valorEdit: false,
        },
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {
            event_id: "",
          },
        },
        revission: {},
        contractFee: {
          programName: "",
          clientName: "",
          saleId: null,
          id: null,
          initialPaymentStatus: null,
          editModal: false,
          statusSale: null,
          st: null,
          type_payment_ce: null,
        },
        notes: {
          programSelected: "",
          roleId: null,
          notesProgram: null,
          nameProgram: null,
          nameClient: null,
          salesMades: null,
          module: null,
          type: null,
          editModal: false,
          statusSale: null,
          sourcesName: null,
          pack: null,
          created: null,
          originCountry: null,
          idLead: null,
          notSeller: null,
          capturedName: null,
          sellerName: null,
          trackings: null,
          notes_status: null,
          notes_status_new: null,
        },
        validateDateAndHourData: {
          program: "",
          client: "",
          saleId: null,
          contactSchedule: null,
          contactHour: null,
        },
        binnacle: {},
        statusHeader: false,
      },
      // Table Data
      items: [],
      selected: [],
      isBusy: false,
      fields: [
        {
          key: "client",
          label: "Client",
          sortable: false,
          visible: true,
          thStyle: { width: "170px" },
        },
        {
          key: "type",
          label: "TY",
          sortable: false,
          visible: true,
          thClass: "text-center",
        },
        {
          key: "first_program",
          label: "OR",
          sortable: false,
          visible: true,
          thClass: "text-center",
        },
        {
          key: "program",
          label: "DE",
          sortable: false,
          visible: true,
          thClass: "text-center",
        },
        {
          key: "captured",
          sortable: false,
          visible: false,
          label: "Catcher",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "seller",
          sortable: false,
          visible: false,
          label: "Seller",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "executives",
          sortable: false,
          visible: true,
          label: "Executives",
          class: "text-center",
          tdClass: "px-3",
          thStyle: { width: "250px" },
        },
        {
          key: "fee",
          sortable: false,
          visible: true,
          label: "Fee ($)",
          class: "text-center",
        },
        {
          key: "initial_amount",
          sortable: false,
          visible: true,
          label: "IP",
          tdClass: "text-center",
          thClass: "text-center",
          thStyle: { width: "125px" },
        },
        {
          key: "contract_fee_status",
          sortable: false,
          visible: true,
          label: "CF",
          tdClass: "text-center",
          thClass: "text-center",
          thStyle: { width: "70px" },
        },
        {
          key: "notes_status",
          sortable: false,
          visible: true,
          label: "NT",
          tdClass: "text-center",
          thClass: "text-center",
          thStyle: { width: "70px" },
        },
        {
          key: "summary",
          sortable: false,
          visible: true,
          label: "Call",
          tdClass: "text-center",
          thClass: "text-center",
          thStyle: { width: "70px" },
        },
        {
          key: "date_time",
          sortable: false,
          visible: true,
          label: "Date/Time",
        },
      ],
      selectAll: false,
      indexSelected: null,
      showSummaryModal: false,
      itemSelected: {},
      statusSale: null,
      originProgramSale: null,
    };
  },
  computed: {
    title() {
      return `Monthly Program Details for ${this.month.name}, ${this.year} - ${this.selected_program?.name}`;
    },
    isSeller() {
      if (!this.chiefRols.includes(this.currentUser.role_id)) {
        return this.currentUser.user_id;
      }
      return this.getFilterByKey("seller").model;
    },
    ...mapState({
      captured: (state) => state["crm-store"].capturedCrm,
      // TODO HACERLO GLOBAL
      programs: (state) => state["crm-store"].programs,
      stip: (state) => state["crm-store"].statusip,
      status: (state) => state["crm-store"].statusAddChange,
      statusFilter: (state) => state["crm-store"].statusFilter,
      typeOfSale: (state) => state["crm-store"].typeOfSale,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      programsBySales: "CrmSaleMadeStore/G_PROGRAMS_CRM_DONE",
      G_IS_ASSIST_SUPERVISOR: "auth/isAssistantSupervisor",
      G_CHIEF: "auth/isChief",
      G_IS_SUPERVISOR: "auth/isSupervisor",
      G_IS_SELLER: "auth/isSeller",
      G_IS_CEO: "auth/isCeo",
      G_MODULE_ID: "auth/moduleId",
      G_USER_ROLE: "auth/userRole",
      G_IS_ADVISOR: "auth/isAdvisor",
      G_USER_SESSION: "auth/userSession",
      G_ROLE_ID: "auth/roleId",
    }),
    onlyShowModules() {
      const modules = [2, 5, 7, 12, 14, 10, 3, 6, 8, 20, 22, 26, 18, 28, 29];
      return !modules.includes(this.G_MODULE_ID);
    },
    originProgram() {
      return this.getFilterByKey("origin").model;
    },
    metaRouteCed() {
      return this.$route.meta.parentModule == 21
        ? this.$route.meta.programParent
        : this.moduleId;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    namePath() {
      return this.$route.name;
    },

    filteredFields() {
      return this.fields.filter((field) => field.visible);
    },
    isAgentInConnection() {
      return this.moduleId == 20 && this.isAgent ? true : false;
    },
    statusTab() {
      return (
        this.$route.matched[3].meta.status ?? this.$route.matched[4].meta.status
      );
    },
    programId() {
      return this.moduleId == 2 ? 0 : this.$route.matched[1].meta.program;
    },
  },
  watch: {
    originProgram: {
      async handler(newVal) {
        this.getFilterByKey("origin").model = this.programId;
        this.getFilterByKey("captured_by").model = "";
        this.getFilterByKey("seller").model = "";
        if (!newVal) {
          this.getFilterByKey("captured_by").visible = false;
          this.getFilterByKey("seller").visible = false;
          return;
        }
        if (this.$route.matched[0].meta.module == 20) {
          await this.$store.dispatch("crm-store/getCaptured", {
            module: this.convertProgramToModule(newVal, 20),
            body: {
              roles: "[]",
              type: "0",
            },
          });
        } else {
          await this.$store.dispatch("crm-store/getCaptured", {
            module: this.convertProgramToModule(newVal, 22),
            body: {
              roles: "[]",
              type: "0",
            },
          });
        }

        this.getFilterByKey("captured_by").visible = true;
        this.getFilterByKey("seller").visible = !this.isAgentInConnection; // Do not show filter seller if isAgentInConnection
        this.getFilterByKey("captured_by").options = this.captured;
        this.getFilterByKey("seller").options = this.captured;
      },
      deep: true,
    },
  },
  async mounted() {
    this.toggleModal("modalDataByProgram");
    await this.getValidateCallsMaxDate();
  },
  async created() {
    this.updateRowSocketSummary();
    try {
      this.filterColumn("sms", !(this.moduleId == 4));
      // this.filterColumn("approved", ![1, 2].includes(this.statusTab));
      // this.filterColumn("actions", ![3, 4].includes(this.statusTab));
      this.getFilterByKey("origin").model = this.programId;
      if (this.metaRouteCed != 0) {
        this.getFilterByKey("origin").disabled = true;
      } else {
        this.getFilterByKey("origin").disabled = false;
      }
      await Promise.all([this.$store.dispatch("crm-store/getPrograms")]);
      this.getFilterByKey("origin").options = this.programs;
      this.getFilterByKey("destination").options = this.programs;
      // this.getFilterByKey("status").options = this.statusFilter;
      this.getFilterByKey("type").options = this.typeOfSale;
      this.getFilterByKey("ip").options = this.stip;
    } catch (error) {
      console.error(error);
    }

    this.addPaddingTd();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    summaryModal(item, index) {
      this.indexSelected = index;
      this.statusSale = item.status;
      this.itemSelected = item;
      this.showSummaryModal = true;
    },
    waitingFor(item) {
      switch (item.status) {
        case 1:
          return `Not ready to send`;
        case 2:
          return `Sent to revission`;
        case 12:
          return `Waiting for ${item.program_initials} response`;
        case 14:
          return `Waiting for ${item.first_program_initials} response`;
        default:
          return `-`;
      }
    },
    async moduleHasChief() {
      if (this.chiefRols.length == 0) {
        try {
          const hasChief = await CrmService.moduleHasChief({
            module_id: this.moduleId,
          });
          this.chiefRols = hasChief ? [1, 17] : [1, 2];
          return hasChief;
        } catch (error) {
          console.error(error);
        }
      }
    },
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    routerClient(item) {
      switch (this.moduleId) {
        case 2:
          return this.$route.meta.modulUrl
            ? `/${this.$route.meta.modulUrl}/leads/show/${item.lead_id}`
            : `/crm/leads/${item.lead_id}`;
        case 3:
          return {
            name: "bussiness-dashboard-clients",
            params: { idClient: item.client_account_id },
          };
        case 4:
          return `/administration/sales-made/report/${item.lead_id}`;
        case 5:
          return `/debtsolution/clients/account/${item.client_account_id}`;
        case 7:
          return `/boostcredit/clients/account/${item.client_account_id}`;
        case 8:
          return {
            name: "tax-research-client-dashboard",
            params: { idClient: item.client_account_id },
          };
        case 10:
          return {
            name: "claim-department-dashboard",
            params: { idClient: item.client_account_id },
          };
        case 12:
          return `/ti/clients/account/${item.client_account_id}`;
        case 14:
          return {
            name: "bookeeping-dashboard",
            params: { idClient: item.client_account_id },
          };
        case 18:
          return `/quality/clients/account/${item.client_account_id}`;
        case 20:
          return {
            name: "connection-clients-account",
            params: { idClient: item.client_account_id },
          };
        case 22:
          return this.routeToCustomerService(item.client_account_id);
        case 26:
          return { name: "sales-lead-show", params: { id: item.leadId } };
        case 29:
          return `/specialists/financial-approval/clients/account/${item.client_account_id}`;
        default:
          if (
            this.programId == 3 &&
            this.moduleId !== 26 &&
            this.moduleId !== 20
          ) {
            return `/creditexperts/clients/account/${item.client_account_id}`;
          }
          return null;
      }
    },
    openBinnacleModal(data) {
      this.modalData.binnacle.nameProgram = data.program;
      this.modalData.binnacle.idProgram = data.program_id;
      this.modalData.binnacle.client_type_id = data.client_type_id;
      this.modalData.binnacle.nameClient = data.client;
      // this.modalData.binnacle.type = state;
      this.modalData.binnacle.idsales = data.id;
      this.modalData.binnacle.initialPayment = data.initial_payment_status;
      this.modalData.binnacle.account = data.account;
      this.modalData.binnacle.leadId = data.lead_id;
      this.modalData.binnacle.datevent = data.event_date;
      this.modalData.binnacle.sellerName = data.seller;
      this.modalData.binnacle.language = data.language;
      this.modalData.binnacle.user_id = this.currentUser.user_id;
      this.modalData.binnacle.last_name = data.last_name;
      this.modalData.binnacle.seller_id = data.seller_id;

      this.modalData.binnacle.comes_from = data?.comes_from;

      this.modalData.binnacle.clientName = data.client;
      this.modalData.binnacle.mobile = data.mobile;
      this.modalData.binnacle.state = data.state;
      this.modalData.binnacle.status = data.status;
      this.modalData.binnacle.sale_id = data.id;
      this.modalData.binnacle.seller = data.seller;
      this.modalData.binnacle.origin = "DS";
      this.modal.binnacle = true;
    },
    isAllowedForAction(item, type) {
      switch (type) {
        case "revission":
          return (
            [1].includes(item.status) &&
            !this.chiefRols.includes(this.currentUser.role_id) &&
            item.seller_id == this.currentUser.user_id &&
            item.initial_payment_status == 2 &&
            item.contract_fee_status == 1 &&
            item.notes_status_new == 0 &&
            (item.note_transcription_status == "COMPLETED" ||
              this.compareDate(item.creates))
          );
        case "revissionByChief":
          return (
            [1].includes(item.status) &&
            this.chiefRols.includes(this.currentUser.role_id) &&
            item.seller_id == this.currentUser.user_id &&
            item.initial_payment_status == 2 &&
            item.contract_fee_status == 1 &&
            item.notes_status_new == 0 &&
            (item.note_transcription_status == "COMPLETED" ||
              this.compareDate(item.creates))
          );
        case "approveForOR":
          return (
            item.first_program_id == this.programId &&
            this.chiefRols.includes(this.currentUser.role_id) &&
            [2, 14].includes(item.status)
          );
        case "disapproveForOR":
          return (
            item.first_program_id == this.programId &&
            this.chiefRols.includes(this.currentUser.role_id) &&
            item.seller_id != this.currentUser.user_id &&
            [2, 14].includes(item.status)
          );
        case "revissionDanger":
          return (
            item.first_program_id == this.programId &&
            item.contract_fee_status == 1 &&
            item.notes_status_new == 0 &&
            item.initial_payment_status == 2 &&
            !this.chiefRols.includes(this.currentUser.role_id) &&
            item.seller_id == this.currentUser.user_id &&
            [3].includes(item.status)
          );
        case "approveForDE":
          return (
            item.program_id == this.programId &&
            this.chiefRols.includes(this.currentUser.role_id) &&
            [12].includes(item.status)
          );
        case "disapproveForDE":
          return (
            item.program_id == this.programId &&
            this.chiefRols.includes(this.currentUser.role_id) &&
            [12].includes(item.status)
          );
        // case "revissionDangerByChief":
        //   return (
        //     item.first_program_id == this.programId &&
        //     item.contract_fee_status == 1 &&
        //     item.notes_status_new == 0 &&
        //     item.initial_payment_status == 2 &&
        //     this.chiefRols.includes(this.currentUser.role_id) &&
        //     [14].includes(item.status)
        //   );
      }
    },
    compareDate(creates) {
      let diff = new Date(creates) < new Date(this.validateCalls);
      return diff;
    },
    async recycleSaleMade(item) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          await Promise.all([
            this.recycleClient(
              item.client_account_id,
              item.lead_client_derivation_id
            ),
            this.annulSaleRecycle(item),
          ]);
          this.showSuccessSwal();
          this.$refs["new-client-done-table"].refresh();
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    async annulSale(sale) {
      try {
        const swal = await this.showConfirmSwal(
          "Are you sure?",
          "Are you sure annuled this sale"
        );
        if (swal.isConfirmed) {
          const response = await amgApi.post("/sales-made/annul-sale", {
            id: sale.id,
            id_event: sale.event_id,
            user: this.currentUser.user_id,
          });
          if (response.status === 200) {
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Your sale has been annulled successfully"
            );
            this.$refs["new-client-done-table"].refresh();
          } else {
            this.showErrorSwal();
          }
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "Error",
          "XIcon",
          "Some error, please try again or contact to support"
        );
      }
    },
    async recycleClient(clientAccountId, derivationId) {
      const params = {
        derivation_id: derivationId,
        status: 4, // Tracing(2) or Recycled(4)
        type: clientAccountId ? 2 : 1, // Lead(1) or Client(2)
        user_id: this.currentUser.user_id,
        obs: "Recycled from Sales Made",
      };
      await LeadService.updateLeadsAndClients(params);
    },
    async annulSaleRecycle(sale) {
      await amgApi.post("/sales-made/annul-sale", {
        id: sale.id,
        id_event: sale.event_id,
        user: this.currentUser.user_id,
      });
    },
    routeToCustomerService(clientAccountId) {
      if (
        this.isCeo ||
        this.isSupervisor ||
        this.isChief ||
        this.isAssistantSupervisor
      ) {
        return {
          name: "ce-customer-service-dashboard",
          params: {
            idClient: clientAccountId,
            hasAccess: true,
            typeAccess: 6, // Special Access,
            wayAccess: 4, // Special Access,
          },
        };
      }
      return {
        name: "",
      };
    },
    hideInitialPaymentModal(val) {
      this.modal.initial_payment = false;
      // if (val) {
      //   this.$refs["new-client-done-table"].refresh();
      // }
    },
    modalSmsOpen(item) {
      this.modalData.sendSms.leads_sms = [];
      this.modalData.sendSms.typesms = 1;
      this.modalData.sendSms.leads_sms_o = [];
      this.modalData.sendSms.leads_sms_o.push(item.lead_id);
      this.modalData.sendSms.name_leads_arr = [
        { name: item.client, id: item.lead_id },
      ];
      this.modal.modalSms = true;
    },
    modalSmssOpen() {
      this.modalData.sendSms.typesms = 0;
      this.modalData.sendSms.name_leads_arr = this.selected.map((el) => ({
        name: el.client,
        id: el.lead_id,
      }));
      this.modalData.sendSms.leads_sms = this.selected.map((el) => el.lead_id);
      this.modal.modalSms = true;
    },
    modalSmsClose() {
      this.modal.modalSms = false;
    },
    modalHistorySmsOpen(item) {
      this.modalData.historySms.leadName = item.client;
      this.modalData.historySms.id = item.lead_id;
      this.$bvModal.show("modal-history-sms");
    },
    // Rol advisor boostcredit

    async search() {
      try {
        await this.moduleHasChief();
        const params = {
          captured:
            this.getFilterByKey("captured_by").model == "" ||
            this.getFilterByKey("captured_by").model == null
              ? 0
              : this.getFilterByKey("captured_by").model,
          from: this.getFilterByKey("from").model,
          to: this.getFilterByKey("to").model,
          module_id: this.moduleId,
          actual_program: this.programId,
          destination_program: this.selected_program.id,
          seller: this.isSeller,
          statusip: this.getFilterByKey("ip").model,
          text: this.filterPrincipal.model,
          type: this.getFilterByKey("type").model,
          perPage: this.paginate.perPage,
          // recovered: this.moduleId == 4 ? 1 : null,
          from_connection: this.moduleId === 20 ? 1 : 0,
          page: this.paginate.currentPage,
          statusTab: this.statusTab,
          year: this.year,
          month: this.month.id,
        };
        const { data } = await CrmService.getSalesMadeClients(params);
        this.startPage = data.paginator.from;
        this.toPage = data.paginator.to;
        this.totalRows = data.paginator.total;
        const selectedIds = this.selected.map((s) => s.id);
        let index = 0;
        while (selectedIds.length > 0 && index < data.paginator.data.length) {
          if (selectedIds.includes(data.data[index].id)) {
            const { id } = data.data[index];
            data.data[index].selected = true;
            const deleted = selectedIds.findIndex((s) => s === id);
            if (deleted !== -1) selectedIds.splice(deleted, 1);
          }
          index += 1;
        }
        this.items = data.paginator.data;
        this.totals = data.totals;
        this.items.forEach((item) => {
          item._rowVariant = this.paintRowDanger(item) ? "danger" : null;
        });
        return this.items;
      } catch (e) {
        this.showToast("danger", "top-right", "Error", "XIcon", e);
        return [];
      }
    },
    async searchSaleProgramById() {
      if (this.statusSale == 2) {
        this.$router.push({
          name: this.$route.matched[2].meta.routeForApprovedClient,
        });
        this.$refs["new-client-done-table"].refresh();
      } else if (this.statusSale == 4) {
        if (this.originProgramSale == this.programId) {
          this.$router.push({
            name: this.$route.matched[2].meta.routeDoneClient,
          });
        } else {
          this.$router.push({
            name: this.$route.matched[2].meta.routeForApprovedClientCompleted,
          });
        }
      } else {
        let sale = this.items[this.indexSelected];
        const params = {
          saleId: sale.id,
        };
        const { data } = await CrmService.searchSaleProgramById(params);
        const params2 = {
          captured:
            this.getFilterByKey("captured_by").model == "" ||
            this.getFilterByKey("captured_by").model == null
              ? 0
              : this.getFilterByKey("captured_by").model,
          from: this.getFilterByKey("from").model,
          to: this.getFilterByKey("to").model,
          module_id: this.moduleId,
          actual_program: this.programId,
          destination_program: this.getFilterByKey("destination").model,
          seller: this.isAgentInConnection
            ? this.G_USER_SESSION
            : this.isSeller(), //show only sales made if isAgentInConnection
          statusip: this.getFilterByKey("ip").model,
          text: this.filterPrincipal.model,
          type: this.getFilterByKey("type").model,
          perPage: this.paginate.perPage,
          // recovered: this.moduleId == 4 ? 1 : null,
          from_connection: this.moduleId === 20 ? 1 : 0,
          page: this.paginate.currentPage,
          statusTab: this.statusTab,
        };
        const { data: footer } = await CrmService.getSalesMadeClients(params2);
        this.totals = footer.totals;
        let newRow = data[0];
        newRow.tcmc = sale.tcmc;
        newRow.tcms = sale.tcms;
        newRow.tfee = sale.tfee;
        newRow.tip = sale.tip;
        newRow.tma = sale.tma;
        const keysNewRow = Object.keys(newRow);
        const keysOldRow = Object.keys(this.items[this.indexSelected]);
        keysNewRow.forEach((item) => {
          if (keysOldRow.includes(item)) {
            Vue.set(this.items[this.indexSelected], item, newRow[item]);
          }
        });
        Vue.set(this.items[this.indexSelected], "_rowVariant", null);
      }
    },
    openContractFeeModal(data, index) {
      this.statusSale = data.status;
      this.indexSelected = index;
      if (
        data.seller_id == this.currentUser.user_id ||
        this.isCeo ||
        (this.isSupervisor &&
          this.$route.matched[0].meta.module === 2 &&
          data.from_module !== 26) ||
        (this.isSupervisor &&
          this.$route.matched[0].meta.module === 26 &&
          data.from_module === 26) ||
        this.isCoordinator ||
        this.isTeamLeader ||
        this.G_IS_ASSIST_SUPERVISOR ||
        (this.isChief &&
          this.$route.matched[0].meta.module === 2 &&
          data.from_module !== 26) ||
        (this.isChief &&
          this.$route.matched[0].meta.module === 26 &&
          data.from_module === 26)
      ) {
        this.modalData.contractFee.editModal = true;
      } else {
        this.modalData.contractFee.editModal = false;
      }
      this.modalData.contractFee.captured_id = data.captured_id;
      this.modalData.contractFee.seller_id = data.seller_id;
      this.modalData.contractFee.type_payment_ce = data.type_payment_ce;
      this.modalData.contractFee.statusSale = data.status;
      this.modalData.contractFee.clientName = data.client;
      this.modalData.contractFee.programName = data.program;
      this.modalData.contractFee.id = data.lead_id;
      this.modalData.contractFee.saleId = data.id;
      this.modalData.contractFee.initialPaymentStatus =
        data.initial_payment_status;
      this.modal.contract_fee = true;
    },

    // Notes
    async notesModal(data, index) {
      this.addPreloader();
      this.modalData.notes.capture_id = data.capture_id;
      this.modalData.notes.seller_id = data.seller_id;
      this.modalData.notes.capturedName = data.captured;
      this.modalData.notes.sellerName = data.seller;
      this.modalData.notes.trackings = data.trackings;
      this.modalData.notes.nameProgram = data.program;
      this.modalData.notes.nameClient = data.client;
      this.modalData.notes.statusSale = data.status;
      this.modalData.notes.sourcesName = data.sourcesname;
      this.modalData.notes.pack = data.pack;
      this.modalData.notes.originCountry = data.origin_country;
      this.modalData.notes.idLead = data.lead_id;
      this.modalData.notes.created = data.creates;
      this.modalData.notes.saleId = data.id;
      this.modalData.notes.module = this.G_MODULE_ID;
      this.modalData.notes.rolId = this.G_ROLE_ID;
      this.modalData.notes.notes_status = data.notes_status;
      this.modalData.notes.notes_status_new = data.notes_status_new;
      this.modalData.notes.event_id = data.event_id;
      this.modalData.notes.editModal =
        (data.from_module == this.moduleId ||
          (data.from_module == 0 && this.moduleId == 2)) &&
        (this.isChief || this.isSupervisor || this.isSeller);
      this.modalData.notes.notSeller =
        (data.seller_id != this.G_USER_SESSION && this.G_IS_SELLER) || //(data.from_module !== 26 ? this.G_IS_SELLER : (this.isSupervisor && this.moduleId === 2 && data.from_module === 26))
        (this.G_IS_SUPERVISOR &&
          this.moduleId === 2 &&
          data.from_module === 26) ||
        (this.G_IS_SUPERVISOR &&
          this.moduleId === 26 &&
          data.from_module !== 26) ||
        (this.isChief && this.moduleId === 2 && data.from_module === 26) ||
        (this.isChief && this.moduleId === 26 && data.from_module !== 26);

      if (this.moduleId === 26) {
        this.modalData.notes.notSeller =
          data.seller_id != this.G_USER_SESSION &&
          this.G_IS_SELLER &&
          !this.isChief;
        this.modalData.notes.editModal =
          this.G_IS_CEO ||
          this.G_IS_SUPERVISOR ||
          this.G_IS_ASSIST_SUPERVISOR ||
          this.isCoordinator ||
          this.isTeamLeader ||
          this.G_USER_SESSION == data.seller_id ||
          this.isChief;
      }
      this.openModalNotes(data.creates, data.program_id, index, data.status);
    },
    async getValidateCallsMaxDate() {
      try {
        const data = await CrmService.getValidateCallsMaxDate();
        this.validateCalls = data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    openModalNotes(created, program, index, status) {
      this.indexSelected = index;

      this.statusSale = status;
      switch (true) {
        case created >= "2020-05-28" && program == 1:
          this.modalData.notes.programSelected = "ModalNotesFirst"; // ready
          break;
        case created >= "2020-10-29" && program == 2:
          this.modalData.notes.programSelected = "ModalNotesBoost"; // ready
          break;
        case created >= "2021-03-04" && program == 3:
          this.modalData.notes.programSelected = "ModalNotesCredit"; // ready
          break;
        case created >= "2020-09-24" && program == 5:
          this.modalData.notes.programSelected = "ModalNotesTax"; // ready
          break;
        case created >= "2020-10-23" && program == 7:
          this.modalData.notes.programSelected = "ModalNotesSpecialist"; // next
          break;
        case program == 9:
          this.modalData.notes.programSelected = "ModalNotesParagon"; // ready
          break;
        default:
          // next next
          this.modalData.notes.programSelected = "ModalNotesAll";
          break;
      }

      this.modal.notes = true;
    },
    closeModalNotes(status) {
      this.modal.notes = false;
      if (status) {
        // this.$refs["new-client-done-table"].refresh();
        this.searchSaleProgramById();
        this.removePreloader();
        this.showSuccessSwal("OPERATION SUCCESSFULLY");
      }
    },
    modalRevisionAndValidateDateAndHour() {
      this.modal.revission = true;
    },
    async revisionSale(state, data, index) {
      this.indexSelected = index;
      this.statusSale = state;
      this.originProgramSale = data.first_program_id;
      this.modalData.revission.capture_id = data.capture_id;
      this.modalData.revission.seller_id = data.seller_id;
      this.modalData.revission.nameProgram = data.program;
      this.modalData.revission.idProgram = data.program_id;
      this.modalData.revission.nameClient = data.client;
      this.modalData.revission.type = state;
      this.modalData.revission.idsales = data.id;
      this.modalData.revission.initialPayment = data.initial_payment_status;
      this.modalData.revission.account = data.account;
      this.modalData.revission.leadId = data.lead_id;
      this.modalData.revission.datevent = data.event_date;
      this.modalData.revission.sellerName = data.seller;
      this.modalData.revission.language = data.language;
      this.modalData.revission.user_id = this.currentUser.user_id;
      this.modalData.revission.last_name = data.last_name;
      this.modalData.revission.client_type_id = data.client_type_id;
      this.modalData.revission.account = data.client_account_id;
      this.modalData.revission.comes_from = data?.from_module;
      this.modalData.revission.sale_type = data?.type;
      this.modalData.revission.first_program = data?.first_program;
      this.modalData.revission.first_program_id = data?.first_program_id;
      this.modalData.revission.account_id = data?.client_account_id;
      this.modalData.validateDateAndHourData.saleId = data.id;
      this.modalData.validateDateAndHourData.program = data.program;
      this.modalData.validateDateAndHourData.client = data.client;
      this.modalData.revission.status = data.status;
      this.modalData.revission.typeSale = data.type;
      this.modalData.revission.reject = false;
      await this.validateDateAndHourDataV();
      this.modal.revission = this.modal.modalValidateDateAndHour !== true;
    },
    async validateDateAndHourDataV() {
      const data = await NotesServices.validateDateAndHourRevision({
        sale_id: this.modalData.revission.idsales,
      });
      if (data.status === 200) {
        if (data) {
          if (data.data.length === 2) {
            const now = moment();
            const exp = moment(`${data.data[0].answer} ${data.data[1].answer}`);

            const days = exp.diff(now, "hours");
            if (days <= 47) {
              this.modalData.validateDateAndHourData.contactDate =
                data.data[0].answer;
              this.modalData.validateDateAndHourData.contactHour =
                data.data[1].answer;
              this.modal.modalValidateDateAndHour = true;
              this.contactHourSecondsValidate48hours = false;
            } else {
              this.contactHourSecondsValidate48hours = true;
            }
          }
        }
      }
    },
    hideModalProgram(refresh) {
      if (refresh) {
        this.searchSaleProgramById();
      }
      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.$store.commit("app/SET_LOADING", false);
    },
    openTrackingModal(program, client, tabla) {
      this.modalData.tracking.program = program;
      this.modalData.tracking.client = client;
      if (tabla) {
        this.modalData.tracking.tabla = JSON.parse(tabla);
        this.modal.tracking = true;
      }
    },
    async openInitialPaymentModal(data, index) {
      this.indexSelected = index;
      this.statusSale = data.status;
      try {
        this.addPreloader();
        this.modalData.initial_payment.other_method = data.other_method;
        this.modalData.initial_payment.programid = data.program_id;
        this.modalData.initial_payment.type_payment_ce = data.type_payment_ce;
        this.modalData.initial_payment.sessionId = this.currentUser.user_id;
        this.modalData.initial_payment.cfeestatus = data.contract_fee_status;
        this.modalData.initial_payment.id_transaction = data.transaction_id;
        this.modalData.initial_payment.editmodal = this.enableEditModal(data);

        this.modalData.initial_payment.statusSale = data.status;
        this.modalData.initial_payment.comissions = data.commission;
        this.modalData.initial_payment.nameProgram = data.program;
        this.modalData.initial_payment.nameClient = data.client;
        this.modalData.initial_payment.valorInitalPayment =
          data.initial_payment_status;
        if (this.$route.matched[0].meta.module === 26) {
          this.modalData.initial_payment.editmodal =
            data.seller_id === this.currentUser.user_id ||
            this.isCeo ||
            this.isSupervisor ||
            this.G_IS_ASSIST_SUPERVISOR ||
            this.G_CHIEF;
        }
        this.modalData.initial_payment.feeprops = data.fee;
        const cards = await amgApi.post("/clients/search-cards-clients", {
          id: data.lead_id,
        });
        if (cards.status === 200) {
          this.modalData.initial_payment.allcards = cards.data;
        }
        const response = await amgApi.post(
          "/sales-made/get-payments-sales-made",
          { id: data.id }
        );
        if (response.status === 200) {
          [this.modalData.initial_payment.payments] = response.data;
        }
        this.modalData.initial_payment.modul = 2;
        this.modalData.initial_payment.role_id = this.currentUser.role_id;
        this.modalData.initial_payment.seller_id = data.seller_id;
        this.modal.initial_payment = true;
      } catch (error) {
        this.showErrorSwal();
      }
    },
    async openModalProgram(data, index) {
      this.statusSale = data.status;
      this.indexSelected = index;
      switch (data.program_id) {
        case 1:
          this.modalData.programs.programSelected = "business-modal";
          break;
        case 2:
          this.modalData.programs.programSelected = "boost-credit-modal";
          break;
        case 3:
          this.modalData.programs.programSelected = "credit-experts-modal";
          break;
        case 4:
          this.modalData.programs.programSelected = "debt-solution-modal";
          break;
        case 5:
          this.modalData.programs.programSelected = "tax-research-modal";
          break;
        case 6:
          this.modalData.programs.programSelected = "general-support-modal";
          break;
        case 7:
          this.modalData.programs.programSelected = "specialist-modal";
          break;
        case 8:
          this.modalData.programs.programSelected = "key-book-modal";
          break;
        case 9:
          this.modalData.programs.programSelected = "paragon-modal";
          break;
        default:
          break;
      }
      if (this.modalData.programs.programSelected !== "") {
        this.modalData.programs.typeModal = data.haveRates == 1 ? 2 : 1;
        this.modalData.statusHeader = true;
        this.modalData.programs.salesClient = data;
        this.modal.programs = true;
      }
    },
    openFilesModal(data) {
      this.modalData.files.id = data.lead_id;
      this.modalData.files.program = data.program;
      this.modalData.files.client = data.client;
      this.modalData.files.sale_id = data.id;
      this.modalData.files.client_account_id = data.client_account_id;
      this.modalData.files.program_id = data.program_id;
      const isCeoOrSupervisor =
        this.currentUser.role_id == "1" || this.currentUser.role_id == "2";
      const saleStatus = data.status == "4";
      if (
        (this.currentUser.user_id == data.seller_id ||
          isCeoOrSupervisor ||
          this.isChief ||
          this.isAssistantSupervisor) &&
        saleStatus == false
      ) {
        this.modalData.files.valorEdit = true;
      }
      this.modal.files = true;
    },
    enableEditModal(data) {
      return;
      data.user_id === this.currentUser.user_id ||
        this.isCeo ||
        this.isTeamLeader ||
        this.isCoordinator ||
        (this.isSupervisor &&
          this.$route.matched[0].meta.module !== 26 &&
          data.from_module !== 26) ||
        (this.isSupervisor &&
          this.$route.matched[0].meta.module === 26 &&
          data.from_module === 26) ||
        (this.isChief &&
          this.$route.matched[0].meta.module !== 26 &&
          data.from_module !== 26) ||
        (this.isChief &&
          this.$route.matched[0].meta.module === 26 &&
          data.from_module === 26) ||
        this.isChief ||
        (this.$route.matched[0].meta.module === 20 &&
          data.seller_id === this.currentUser.user_id);
    },
    getFilterByKey(key) {
      const filter = this.filter.find((filter) => filter.key === `${key}`);

      return filter;
    },
    paintRowDanger(item) {
      try {
        const { status, from_module } = item;
        const sellerRol = [3, 5];
        const chiefSupervisor = [2, 17];
        let rolId;
        if (this.moduleId != 20) {
          const { role_id } = this.currentUser;
          rolId = role_id;
        } else {
          const { role_id } = this.currentUser;
          rolId = role_id === 2 ? 3 : role_id;
        }
        const isModuleMatch = from_module === this.moduleId;
        const isSeller =
          status === 3 && isModuleMatch && sellerRol.includes(rolId);

        const isChiefCrmOrProgram =
          status === 14 && chiefSupervisor.includes(rolId) && isModuleMatch;
        return isSeller || isChiefCrmOrProgram;
      } catch (ex) {
        console.log(ex);
        return false;
      }
    },
    async updateRowSocketSummary() {
      try {
        window.socket.bind("update-salemade-summary", async ({ data }) => {
          const found = this.items.find((note) => {
            if (note.notes_ids != null) {
              let parsedNote = JSON.parse(note.notes_ids);
              let noteId = parsedNote[0];

              return noteId == data.note_id;
            }
            return false;
          });
          if (found) {
            let index = this.items.findIndex((note) => {
              if (note.notes_ids != null) {
                let parsedNote = JSON.parse(note.notes_ids);
                let noteId = parsedNote[0];

                return noteId == data.note_id;
              }
            });

            if (index !== -1) {
              this.indexSelected = index;
              await this.searchSaleProgramById();
            }
          }
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
