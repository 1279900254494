import { amgApi } from '@/service/axios';

class DashboardPaymentsService {

    async getChargeBacksByClientAccount(params) {
        try {
            const { data } = await amgApi.post("/commons/payments-charge-backs", params);
            return data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getCreditCardExpenses(params){
        try {
            const data = await amgApi.post("expenses/credit-card/index", params)
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getCheckExpenses(params){
        try {
            const data = await amgApi.post("expenses/check/get-expenses", params)
            return data;
        } catch (error) {
            throw error;
        }
    }

}

export default new DashboardPaymentsService();
