import DashboardNav from "@/views/specialists/sub-modules/accounts-resolution/views/dashboard/navigation/dash.nav";

const navigation = [
  {
    header: "ACCOUNTS RESOLUTION",
    module: 30,
    modules: [
      {
        value: 28,
        text: "ANALYST DEPARTMENT",
        route: "analyst-department-dashboard",
      },
      {
        value: 29,
        text: "FINANCIAL APPROVAL",
        route: "financial-approval-dashboard",
      },
      {
        value: 30,
        text: "ACCOUNTS RESOLUTION",
        route: "accounts-resolution-dashboard",
      },
    ],
  },
  DashboardNav,
];

export default navigation;
