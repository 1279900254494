<template>
  <div>
    <b-row>
      <b-col cols="12" md="7" v-if="searchClientInput">
        <b-form-group label="Search client" v-slot="{ ariaDescribedby }">
          <b-input-group>
            <template #prepend>
              <b-form-radio-group
                size="sm"
                v-model="typeClient"
                v-if="isCheck || isECheck"
                stacked
                :style="{
                  marginRight: '0.5rem',
                }"
              >
                <b-form-radio
                  value="CLIENT"
                  :aria-describedby="ariaDescribedby"
                  checked
                >
                  Client
                </b-form-radio>
                <b-form-radio value="LEAD" :aria-describedby="ariaDescribedby">
                  Lead
                </b-form-radio>
              </b-form-radio-group>

              <b-select
                v-model="typeSearchClient"
                v-if="typeClient === 'CLIENT'"
              >
                <option value="BY_NAME">By Name</option>
                <option value="BY_ACCOUNT">By Account</option>
              </b-select>
            </template>
            <template #append>
              <b-button
                v-if="typeSearchClient === 'BY_ACCOUNT'"
                variant="primary"
                @click="searchClientAccount"
                :disabled="loadingSearchClient"
              >
                <template v-if="loadingSearchClient">
                  <b-spinner small></b-spinner>
                  <span class="sr-only">Loading...</span>
                </template>
                <template v-else>
                  <feather-icon icon="SearchIcon" />
                </template>
              </b-button>
            </template>
            <b-form-input
              v-if="typeSearchClient === 'BY_ACCOUNT'"
              placeholder="Enter client account"
              v-model="searchClient"
              @keyup.enter="searchClientAccount"
            ></b-form-input>
            <template v-else>
              <v-select
                style="flex: 1 !important"
                placeholder="Search by client name"
                @search="onSearch"
                :options="dataClientsSelected"
                :filterable="false"
                label="client"
                @input="onSelectedClient"
                v-model="selectedClient"
              >
                <template v-slot:option="option">
                  <template v-if="option.type_client === 'CLIENT'">
                    <span>{{ option.client }} | {{ option.account }}</span>
                    <StatusAccount
                      :account="{ ...option, status: option.status }"
                    />
                  </template>
                  <template v-else>
                    <span>{{ option.client }}</span>
                    <span class="d-block"
                      >{{ option.created_at | myGlobal }} |
                      {{ option.id }}
                    </span>
                  </template>
                </template>
              </v-select>
            </template>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" :md="searchClientInput ? 2 : 3" v-if="formExpense.type">
        <ValidationProvider
          :rules="isAmg ? 'required|validate-amount' : ''"
          v-slot="{ errors }"
        >
          <b-form-group label="Amount">
            <money
              required
              v-bind="vMoney"
              v-model="formExpense.amount"
              class="form-control"
              :disabled="!isAmg && formExpense.type"
              :class="{
                'border-danger': errors.length > 0 && vmoneyValidate,
              }"
            ></money>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col cols="12" md="3" v-if="formExpense.type">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group :label="titleDate">
            <b-form-datepicker
              id="date"
              v-model="formExpense.date"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :state="errors.length > 0 ? false : null"
              :max="isCreditCard || isECheck ? getDatePicker.max : null"
              :min="isCheck ? getDatePicker.min : null"
            />
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col cols="12" md="3" v-if="isAmg && isCreditCard">
        <b-form-group label="Recurring Payment" style="width: 100%">
          <b-form-radio-group
            v-model="formExpense.recurring_payment"
            buttons
            button-variant="outline-primary"
            class="w-100"
          >
            <b-form-radio :value="1">YES</b-form-radio>
            <b-form-radio :value="0">NO</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
        v-if="isAmg && hasRecurringPayment && isCreditCard"
      >
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group label="Start Date">
            <b-form-datepicker
              id="startDate"
              :disabled="!hasRecurringPayment"
              v-model="formExpense.start_date"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :state="errors.length > 0 && hasRecurringPayment ? false : null"
              :min="getDatePicker.min"
            />
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
    <!-- CLIENTS VARIOS -->
    <clients-expense
      v-if="!isAmg && formExpense.type"
      ref="clientsExpense"
      :amount.sync="formExpense.amount"
      :clientsExpenses.sync="formExpense.clients_expenses"
      @loaded="searchClientDefault"
      :programId="programIdClient"
      :inDashboard="inDashboardClient"
      :loadingSearch.sync="loadingSearchClient"
      :typeSearchClient="typeSearchClient"
    />
    <!-- END CLIENTS VARIOS -->
  </div>
</template>
<script>
import VMoney from "v-money";
import moment from "moment";
import _ from "lodash";
import ClientsExpense from "@/views/commons/expenses/components/ClientsExpense.vue";
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  name: "TransactionInputs",
  props: {
    inDashboardClient: {
      type: Boolean,
      required: true,
    },
    isCheck: {
      type: Boolean,
      default: () => false,
    },
    isECheck:{
      type: Boolean,
      default: () => false,
    },
    vmoneyValidate: {
      type: Boolean,
      required: true,
    },
    isCreditCard: {
      type: Boolean,
      default: () => false,
    },
    formExpense: {
      type: Object,
      required: true,
    },
    programIdClient: {
      type: Number,
      default: () => null,
    },
    account_number: {
      type: String,
      default: () => null,
    },
  },
  components: {
    VMoney,
    ClientsExpense,
    StatusAccount,
  },
  directives: { money: VMoney },
  data() {
    return {
      loadingSearchClient: false,
      typeClient: "CLIENT",
      searchClient: "",
      selectedClient: null,
      typeSearchClient: "BY_ACCOUNT",
      dataClientsSelected: [],
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
    };
  },
  computed: {
    openFromDashboardCeo() {
      return this.$route.matched[0].name === "ceo-dashboard";
    },
    searchClientInput() {
      if (this.inDashboardClient) return false;
      return !this.isAmg && this.formExpense.type;
    },
    isAmg() {
      return this.formExpense.type === "AMG";
    },
    hasRecurringPayment() {
      return !!this.formExpense.recurring_payment;
    },
    titleDate() {
      let title;
      switch (this.formExpense.method) {
        case "CREDIT_CARD":
          title = "Payment Date";
          break;
        case "CHECK":
          title = "Date";
          break;
        default:
          title = "Date";
          break;
      }
      return title;
    },
    getDatePicker() {
      return {
        min: moment().format("YYYY-MM-DD"),
        max: moment().format("YYYY-MM-DD"),
      };
    },
    programId() {
      return this.$route.matched[0].meta?.program;
    },
  },
  watch: {
    account_number: {
      async handler(value) {
        if (value != "" && value != null && value != undefined) {
          this.searchClient = value;
        }
      },
      immediate: true,
      deepp: true,
    },
    typeClient(value) {
      this.searchClient = "";
      this.selectedClient = null;
      this.typeSearchClient = value === "LEAD" ? "BY_NAME" : "BY_ACCOUNT";
      this.dataClientsSelected = [];
    },
  },
  methods: {
    async searchClientAccount() {
      try {
        if (this.searchClient.length === 0)
          throw new Error("Please enter a client account");
        const refClientExpense = this.$refs.clientsExpense;
        await refClientExpense.search(this.searchClient);
        this.searchClient = "";
      } catch (ex) {
        const message = ex.message ?? ex;
        this.showAlertWarning(message);
      }
    },
    showAlertWarning(message) {
      this.showToast(
        "warning",
        "top-right",
        "Warning",
        "AlertTriangleIcon",
        message
      );
    },
    async searchClientDefault() {
      this.$nextTick(async () => {
        if (
          this.account_number != "" &&
          this.account_number != null &&
          this.account_number != undefined
        ) {
          await this.searchClientAccount();
        }
      });
    },
    onSearch(search, loading) {
      if (search.length > 0) {
        loading(true);
        this.searchCreditors(loading, search, this);
      }
    },
    searchCreditors: _.debounce(async function (loading, search, vm) {
      vm.dataClientsSelected = [];
      const data = await DepartmentExpenses.getClientAccounts({
        search,
        type: vm.typeSearchClient,
        program_id: vm.programId,
        typeClient: vm.typeClient,
        is_ceo: this.openFromDashboardCeo,
      });
      vm.dataClientsSelected = data;
      loading(false);
    }, 100),
    async onSelectedClient(client) {
      try {
        if (!client) return;
        const refClientExpense = this.$refs.clientsExpense;
        await refClientExpense.search("", client);
        this.selectedClient = null;
      } catch (ex) {
        const message = ex.message ?? ex;
        this.showAlertWarning(message);
      }
    },
  },
};
</script>
