export default [
  {
    path: 'essential-documents',
    name: 'essential-documents',
    component: () => import(
      '@/views/commons/essential-documents/EssentialDocuments.vue'
    ),
    meta: {
      pageTitle: 'Essential documents',
      breadcrumb: [
        {
          text: 'Essential documents',
          active: true,
        },
      ],
    },
  },
];
