import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import { validateSubmodule } from "@/helpers/routes";
import { validateModalActions } from "@/helpers/open-modal-alerts";
import { updateCountersByModule } from "@/helpers/counters";
import store from "@/store";
import crm from "@/views/crm/router";
import messages from "@/commons/messages/messages.router";
import socialNetwork from "@/views/social-network/router";
import debtSolution from "@/views/debt-solution/router";
import BoostCredit from "@/views/boost-credit/router";
import management from "@/views/management/router";
import LogReaderRouter from "@/views/log-reader/log-reader.router";
import rrhhRouter from "@/views/rrhh/router";
import logistic from "@/views/logistic/router";
import businessRouter from "@/views/business/router";
import paragon from "@/views/paragon/router";
import bookeeping from "@/views/bookeeping/router";
import courtInfo from "@/views/court-info/router";
import usersRouter from "@/views/users/router";
import correspondence from "@/views/correspondence/router";
import taxresearchRouter from "@/views/tax-research/router";
import customerservice from "@/views/ce-digital/sub-modules/customer-service/router";
import ceDigital from "@/views/ce-digital/router";
import financial from "@/views/ce-digital/sub-modules/financial/router";
import settings from "@/views/ce-digital/sub-modules/settings/router";
import specialistdigital from "@/views/specialist-digital/router";
import qualityRouter from "@/views/quality/router";
import creditExpertsRouter from "@/views/credit-experts/router";
import salesRouter from "@/views/ce-digital/sub-modules/sales/router";
import administrationRouter from "@/views/administration/router";
import ceDigitalConnection from "@/views/ce-digital/sub-modules/connection/router";
// import especialists from '@/views/specialists/router';
import assistanceRouter from "@/views/assistance/router/index";
import creativeRouter from "@/views/creative/router";
//import projectsRouter from "@/views/projects/router/index";
import CustomerTicketsRouter from "@/views/customer-tickets/router/index";
import DashboardDebtSolution from "@/views/dashboard-client/router/debt-solution.router";
import DashboardParagon from "@/views/dashboard-client/router/paragon.router";
import DashboardBussiness from "@/views/dashboard-client/router/bussiness.router";
import DashboardBoostCredit from "@/views/dashboard-client/router/boost-credit.router";
import DashboardCreditExperts from "@/views/dashboard-client/router/credit-experts.router";
import DashboardCourtInfo from "@/views/dashboard-client/router/court-info.router";
import DashboardTaxResearch from "@/views/dashboard-client/router/tax-research.router";
import DashboardBookeping from "@/views/dashboard-client/router/bookeeping.router";
import DashboardSpecialists from "@/views/dashboard-client/router/specialists.router";
import DashboardCreditExpertsDigital from "@/views/dashboard-client/router/credit-experts-digital.router";
import TicketCustomerLeads from "@/views/dashboard-client/router/leads.router";
import pages from "./routes/pages";
import commons from "./routes/amg/common";
import uiElements from "./routes/ui-elements/index";
import users from "./routes/amg/users";
import specialistsMenu from "@/views/specialists/router/index-menu";
import specialistsAnalystDepartment from "@/views/specialists/sub-modules/analyst-department/router/index";
import specialistsAccountsResolution from "@/views/specialists/sub-modules/accounts-resolution/router/index";
import specialistsFinancialApproval from "@/views/specialists/sub-modules/financial-approval/router/index";
import UserLogEvent from "@/router/userLogEvent.js";
import UploadRouter from "@/views/commons/configurations/upload/router/index.js";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // scrollBehavior() {
  //   return { x: 0, y: 0 }
  // },
  routes: [
    { path: "/", redirect: { name: "employee-dashboard" } },
    { path: "/home", redirect: { name: "amg-menu" } },
    ...LogReaderRouter,
    ...crm,
    ...socialNetwork,
    ...debtSolution,
    ...BoostCredit,
    ...management,
    ...users,
    ...messages,
    ...uiElements,
    ...commons,
    ...rrhhRouter,
    ...logistic,
    ...paragon,
    ...businessRouter,
    ...taxresearchRouter,
    ...usersRouter,
    ...pages,
    ...ceDigital,
    ...customerservice,
    ...financial,
    ...settings,
    ...correspondence,
    ...bookeeping,
    ...courtInfo,
    ...specialistdigital,
    ...qualityRouter,
    ...creditExpertsRouter,
    ...administrationRouter,
    ...salesRouter,
    ...ceDigitalConnection,
    // ...especialists,
    ...creativeRouter,
    //...projectsRouter,
    ...assistanceRouter,
    ...CustomerTicketsRouter,
    ...DashboardDebtSolution,
    ...DashboardParagon,
    ...DashboardBussiness,
    ...DashboardBoostCredit,
    ...DashboardCreditExperts,
    ...DashboardCreditExpertsDigital,
    ...DashboardCourtInfo,
    ...DashboardTaxResearch,
    ...DashboardBookeping,
    ...DashboardSpecialists,
    ...TicketCustomerLeads,
    ...specialistsMenu,
    ...specialistsAnalystDepartment,
    ...specialistsAccountsResolution,
    ...specialistsFinancialApproval,
    ...UploadRouter,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});
router.onReady((route) => {
  validateSubmodule(route);
});
router.beforeEach((to, from, next) => {
  const isLoggedIn = isUserLoggedIn();
  const userData = getUserData();
  const isNewPageRender = from.matched.length == 0;
  if (!isNewPageRender) {
    const userLogEvent = UserLogEvent.processUserLogEvent(userData, to.matched);
  }
  router.onReady(() => {
    if (
      to.matched[0]?.meta?.module != undefined &&
      from.matched[0]?.meta?.module != to.matched[0]?.meta?.module
    ) {
      if (userData) {
        store.dispatch(
          "auth/updateCurrentUserModuleRole",
          to.matched[0]?.meta?.module
        );
        store.commit("TaskStore/M_SHOW_TASK_TODAY_MODAL");
        updateCountersByModule(to.matched[0]?.meta?.module);
      }
      validateModalActions(to);
      store.commit("SidebarStore/SET_NAVIGATION_ITEMS_BY_MODULE", {
        module: to.matched[0]?.meta?.module,
      });
    }
  });

  if (isLoggedIn) {
    if (!canNavigate(to, userData.arrRoles, userData.eng_soft)) {
      // Redirect to login if not logged in
      if (userData.role_id == 1 && to.name == "employee-dashboard") {
        return next({ name: "ceo-dashboard" });
      }
      if (!isLoggedIn) return next({ name: "auth-login" });
      // If logged in => not authorized
      if (to.name !== "misc-not-authorized") {
        return next({ name: "misc-not-authorized" });
      }
    }
  } else if (
    to.name !== "auth-login" &&
    to.name !== "forgot-password" &&
    to.name !== "reset-password" &&
    to.name !== "expired-password" &&
    to.name !== "login-old-soft"
  ) {
    store.commit("resetState");
    return (window.location.href = "/login");
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }
  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
