import { requestNcrSubTab } from "@/views/commons/components/request-ncr/router/request-ncr.subtabs";
import { LetterDirectCompletedSubTabs, LetterDirectToSendSubTabs } from "@/views/commons/components/letters-v2/router/dispute-letters-sub-tabs.router.js";
import { requestWpTabs } from "@/views/commons/components/request-workplan/router/request-workplan.tabs.js";

export default [
  {
    path: "specialist",
    name: "debt-solution-specialist", //debt-solution-specialist
    component: () =>
      import(
        /* webpackChunkName: "DSSpecialist" */ "@/views/debt-solution/views/specialist/views/SpecialistTemplate.vue"
      ),
    redirect: { name: "debt-solution-specialist-ncr-realtor" },
    meta: {
      pageTitle: "Specialist",
      breadcrumb: [
        {
          text: "Specialist",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "ncr-realtor",
        name: "debt-solution-specialist-ncr-realtor",
        redirect: { name: "debt-solution-specialist-ncr-realtor-in-process" },
        component: () =>
          import(
            /* webpackChunkName: "DSSpecialistNcrRealtor" */ "@/views/debt-solution/views/ncr-realtor/views/NcrRealtorTemplate.vue"
          ),
        meta: {
          pageTitle: "Specialist",
          breadcrumb: [
            {
              text: "NCR Realtor",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "in-process",
            name: "debt-solution-specialist-ncr-realtor-in-process",
            component: () =>
              import(
                /* webpackChunkName: "DSSpecialistNcrRealtorGrid" */ "@/views/commons/components/ncr-realtor/views/components/TableNcrRealtor.vue"
              ),
            meta: {
              statusNCR: 0,
              pageTitle: "NCR Realtor",
              breadcrumb: [
                {
                  text: "In Process",
                  active: true,
                },
              ],
            },
          },
          {
            path: "completed",
            name: "debt-solution-specialist-ncr-realtor-completed",
            component: () =>
              import(
                /* webpackChunkName: "DSSpecialistNcrRealtorGrid" */ "@/views/commons/components/ncr-realtor/views/components/TableNcrRealtor.vue"
              ),
            meta: {
              statusNCR: 1,
              pageTitle: "NCR Realtor",
              breadcrumb: [
                {
                  text: "Completed",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "ncr-programs",
        name: "debt-solution-specialist-ncr-programs",
        redirect: { name: "specialist-ncr-programs" },
        component: () =>
          import(
            /* webpackChunkName: "DSSpecialistNcrPrograms" */ "@/views/commons/components/ncr-programs/NcrPrograms.vue"
          ),
        meta: {
          pageTitle: "NCR Programs",
          permittedRoles: [1, 2, 3],
          breadcrumb: [
            {
              text: "NCR Programs",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "",
            name: "specialist-ncr-programs",
            component: () =>
              import(
                /* webpackChunkName: "DsSpecialistNcrProgramsTable" */ "@/views/commons/components/ncr-programs/components/table/ncr-programs/NcrProgramsTable.vue"
              ),
            meta: {
              status: null,
              module: 5,
              moduleId: 5,
              pageTitle: "NCR Programs",
              permittedRoles: [1, 2, 3],
              breadcrumb: [
                {
                  text: "NCR",
                  active: true,
                },
              ],
            },
          },
          {
            path: "return",
            name: "specialist-ncr-programs-return",
            component: () =>
              import(
                "@/views/commons/components/ncr-programs/components/table/ncr-programs/NcrProgramsTable.vue"
              ),
            meta: {
              status: 4,
              module: 5,
              moduleId: 5,
              pageTitle: "NCR Programs",
              permittedRoles: [1, 2, 3],
              breadcrumb: [
                {
                  text: "Returned BY ADM",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "dispute",
        name: "debtsolution-specialist-dispute",
        redirect: { name: "debtsolution-specialist-dispute-direct" },
        component: () =>
          import(
            /* webpackChunkName: "DSSpecialistDispute" */ "@/views/debt-solution/views/dispute/DisputeMain.vue"
          ),
        meta: {
          pageTitle: "Dispute",
          breadcrumb: [
            {
              text: "Dispute",
            },
          ],
          routeDirectDispute: "debtsolution-specialist-dispute-direct",
          routeLettersDispute: "ds-letters-dispute",
        },
        children: [
          {
            path: "direct-dispute",
            name: "debtsolution-specialist-dispute-direct",
            redirect: { name: "ds-pending-directs-round" },

            component: () =>
              import(
                /* webpackChunkName: "DSSpecialistDisputeDirect" */ "@/views/ce-digital/sub-modules/customer-service/views/direct-round/direct-round.vue"
              ),
            meta: {
              pageTitle: "Dispute",
              breadcrumb: [
                {
                  text: "Direct",
                  active: true,
                },
              ],
              routeDirectPending: "ds-pending-directs-round",
              routeDirectCompleted: "ds-completed-directs-round",
              routeDirectToDeleted: "ds-deleted-directs-round",
            },

            children: [
              {
                path: "pending",
                name: "ds-pending-directs-round",
                component: () =>
                  import(
                    /* webpackChunkName:  "pendingDirectsRound" */
                    "@/views/specialists/sub-modules/analyst-department/views/direct-round/components/DirectRounds.vue"
                  ),
                meta: {
                  typeTab: "pending",
                  pageTitle: "Direct Dispute",
                  breadcrumb: [
                    {
                      text: "Pending",
                      active: true,
                    },
                  ],
                  permittedRoles: [1, 2, 16, 17],
                },
                props: { statusDirectType: "pending" },
              },
              {
                path: "completed",
                name: "ds-completed-directs-round",
                component: () =>
                  import(
                    /* webpackChunkName: "completedDirectsRound" */
                    "@/views/specialists/sub-modules/analyst-department/views/direct-round/components/DirectRounds.vue"
                  ),
                meta: {
                  typeTab: "completed",
                  pageTitle: "Direct Dispute",
                  breadcrumb: [
                    {
                      text: "Completed",
                      active: true,
                    },
                  ],
                  permittedRoles: [1, 2, 16, 17],
                },
                props: { statusDirectType: "completed" },
              },
              {
                path: "deleted",
                name: "ds-deleted-directs-round",
                component: () =>
                  import(
                    /* webpackChunkName: "deletedDirectsRound" */
                    "@/views/specialists/sub-modules/analyst-department/views/direct-round/components/DirectRounds.vue"
                  ),
                meta: {
                  typeTab: "deleted",
                  pageTitle: "Direct Dispute",
                  breadcrumb: [
                    {
                      text: "Deleted",
                      active: true,
                    },
                  ],
                  permittedRoles: [1, 2, 16, 17],
                },
                props: { statusDirectType: "deleted" },
              },
            ],
          },

          {
            path: "dispute-letters",
            name: "ds-letters-dispute",
            redirect: { name: "ds-letters-dispute-to-send" },
            component: () =>
              import("@/views/commons/components/letters-v2/LettersMain.vue"),
            meta: {
              pageTitle: "Letter Dispute",
              module: 5,
              breadcrumb: [
                {
                  text: "Letter Dispute",
                  active: true,
                },
              ],
              routeToSend: "ds-letters-dispute-to-send",
              routeCompleted: "ds-letters-dispute-completed",
              routeDeleted: "ds-letters-dispute-deleted",
              routeExpired: "ds-letters-dispute-expired",
            },
            children: [
              {
                path: "to-send",
                name: "ds-letters-dispute-to-send",
                component: () =>
                  import(
                    "@/views/commons/components/letters-v2/LetterDisputeToSend.vue"
                  ),
                redirect: { name: "ds-letters-dispute-to-send-pending" },
                meta: {
                  pageTitle: "Letter Dispute",
                  tabName: "completed",
                  breadcrumb: [
                    {
                      text: "Completed",
                      active: true,
                    },
                  ],
                  routeToSendPending: "ds-letters-dispute-to-send-pending",
                  routeToSendInQueue: "ds-letters-dispute-to-send-in-queue",
                  routeToSendReturned: "ds-letters-dispute-to-send-returned",
                },
                children: LetterDirectToSendSubTabs("to-send", "ds"),
              },

              {
                path: "completed",
                name: "ds-letters-dispute-completed",
                component: () =>
                  import(
                    "@/views/commons/components/letters-v2/LetterDisputeCompleted.vue"
                  ),
                redirect: { name: "ds-letters-dispute-completed-in-correspondence" },
                meta: {
                  pageTitle: "Letter Dispute",
                  tabName: "completed",
                  breadcrumb: [
                    {
                      text: "Completed",
                      active: true,
                    },
                  ],
                  routeCompletedInCorrespondence:
                    "ds-letters-dispute-completed-in-correspondence",
                  routeCompletedSent: "ds-letters-dispute-completed-sent",
                },
                children: LetterDirectCompletedSubTabs("completed", "ds"),
              },
              {
                path: "deleted",
                name: "ds-letters-dispute-deleted",
                component: () =>
                  import(
                    "@/views/commons/components/letters-v2/components/LettersDisputeTable.vue"
                  ),
                meta: {
                  pageTitle: "Letter Dispute",
                  tabName: "deleted",
                  breadcrumb: [
                    {
                      text: "Deleted",
                      active: true,
                    },
                  ],
                },
              },
              {
                path: "expired",
                name: "ds-letters-dispute-expired",
                component: () =>
                  import(
                    "@/views/commons/components/letters-v2/components/LettersDisputeTable.vue"
                  ),
                meta: {
                  pageTitle: "Letter Dispute",
                  tabName: "expired",
                  breadcrumb: [
                    {
                      text: "Expired",
                      active: true,
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
      {
        path: "dispute-results-origin",
        name: "debt-solution-specialist-dispute-results-origin",
        component: () =>
          import(
            /* webpackChunkName: "DSSpecialistDisputeResults" */ "@/views/debt-solution/views/dispute-results/views/DisputeResultsOrigin.vue"
          ),
        redirect: {
          name: "debt-solution-specialist-dispute-results-in-process-origin",
        },
        children: [
          {
            path: "in-process",
            name: "debt-solution-specialist-dispute-results-in-process-origin",
            component: () =>
              import(
                /* webpackChunkName: "DSSpecialistDisputeInProcess" */ "@/views/commons/components/dispute-results/views/components/TableInProcess.vue"
              ),
            meta: {
              status: 0,
              pageTitle: "Update Request",
              breadcrumb: [
                {
                  text: "In Process",
                  active: true,
                },
              ],
            },
          },
          {
            path: "completed",
            name: "debt-solution-specialist-dispute-results-completed-origin",
            component: () =>
              import(
                /* webpackChunkName: "DSSpecialistDisputeCompleted" */ "@/views/commons/components/dispute-results/views/components/TableCompleted.vue"
              ),
            meta: {
              status: 1,
              pageTitle: "Update Request",
              breadcrumb: [
                {
                  text: "Completed",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "dispute-results",
        name: "debt-solution-specialist-dispute-results",
        component: () =>
          import(
            /* webpackChunkName: "DSSpecialistDisputeResults" */ "@/views/debt-solution/views/dispute-results/views/DisputeResults.vue"
          ),
        redirect: {
          name: "debt-solution-specialist-dispute-results-in-process",
        },
        children: [
          {
            path: "in-process",
            name: "debt-solution-specialist-dispute-results-in-process",
            component: () =>
              import(
                /* webpackChunkName: "DSSpecialistDisputeInProcess" */ "@/views/commons/components/dispute-results/views/components/tabs/TableInProcess.vue"
              ),
            meta: {
              status: 0,
              pageTitle: "Update Request",
              breadcrumb: [
                {
                  text: "In Process",
                  active: true,
                },
              ],
              tab: 'IN PROCESS'
            },
          },
          {
            path: "completed",
            name: "debt-solution-specialist-dispute-results-completed",
            component: () =>
              import(
                /* webpackChunkName: "DSSpecialistDisputeCompleted" */ "@/views/commons/components/dispute-results/views/components/tabs/TableCompleted.vue"
              ),
            meta: {
              status: 1,
              pageTitle: "Update Request",
              breadcrumb: [
                {
                  text: "Completed",
                  active: true,
                },
              ],
              tab: 'COMPLETED'
            },
          },
        ],
      },
      {
        path: "ncr",
        name: "debt-solution-specialist-ncr",
        redirect: { name: "debt-solution-specialist-ncr-pending" },
        component: () =>
          import(
            /* webpackChunkName: "DSSpecialistNcr" */ "@/views/commons/components/ncr/NcrMain.vue"
          ),
        meta: {
          isClientsTab: true,
          pendingRoute: "debt-solution-specialist-ncr-pending",
          returnedRoute: "debt-solution-specialist-ncr-returned",
          completedRoute: "debt-solution-specialist-ncr-completed",
          reportLeadRoute: "debt-solution-specialist-report-lead",
          // leadRoute: "debt-solution-specialist-lead-show",
          permittedRoles: [9, 10, 11, 2, 1],
        },
        children: [
          {
            path: "pending",
            name: "debt-solution-specialist-ncr-pending",
            component: () =>
              import(
                /* webpackChunkName: "DSNcrPending" */ "@/views/commons/components/ncr/components/ContentPending.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "NCR",
              breadcrumb: [
                {
                  text: "Pending",
                  active: true,
                },
              ],
              pendingRoute: "debt-solution-specialist-ncr-pending",
              returnedRoute: "debt-solution-specialist-ncr-returned",
              completedRoute: "debt-solution-specialist-ncr-completed",
              reportLeadRoute: "debt-solution-specialist-report-lead",
              // leadRoute: "debt-solution-specialist-lead-show",
              permittedRoles: [9, 10, 11, 2, 1, 3],
            },
          },
          {
            path: "returned",
            name: "debt-solution-specialist-ncr-returned",
            component: () =>
              import(
                /* webpackChunkName: "DSSpecialistNcrReturned" */ "@/views/commons/components/ncr/components/ContentReturned.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "NCR",
              breadcrumb: [
                {
                  text: "Returned",
                  active: true,
                },
              ],
              pendingRoute: "debt-solution-specialist-ncr-pending",
              returnedRoute: "debt-solution-specialist-ncr-returned",
              completedRoute: "debt-solution-specialist-ncr-completed",
              reportLeadRoute: "debt-solution-specialist-report-lead",
              // leadRoute: "debt-solution-specialist-lead-show",
              permittedRoles: [9, 10, 11, 2, 1, 3],
            },
          },
          {
            path: "completed",
            name: "debt-solution-specialist-ncr-completed",
            component: () =>
              import(
                /* webpackChunkName: "DSSpecialistNcrCompleted" */ "@/views/commons/components/ncr/components/ContentCompleted.vue"
              ),

            meta: {
              isClientsTab: true,
              pageTitle: "NCR",
              breadcrumb: [
                {
                  text: "Completed",
                  active: true,
                },
              ],
              pendingRoute: "debt-solution-specialist-ncr-pending",
              returnedRoute: "debt-solution-specialist-ncr-returned",
              completedRoute: "debt-solution-specialist-ncr-completed",
              reportLeadRoute: "debt-solution-specialist-report-lead",
              // leadRoute: "debt-solution-specialist-lead-show",
              permittedRoles: [9, 10, 11, 2, 1, 3],
            },
          },
          {
            path: "reportLead/:idlead/:idfile",
            name: "debt-solution-specialist-report-lead",
            component: () =>
              import(
                /* webpackChunkName: "DSSpecialistReportLead" */ "@/views/commons/components/ncr/components/report/ReportLead.vue"
              ),
            props: true,
            meta: {
              isClientsTab: true,
              pendingRoute: "debt-solution-specialist-ncr-pending",
              returnedRoute: "debt-solution-specialist-ncr-returned",
              completedRoute: "debt-solution-specialist-ncr-completed",
              reportLeadRoute: "debt-solution-specialist-report-lead",
              // leadRoute: "debt-solution-specialist-lead-show",
              permittedRoles: [9, 10, 11, 2, 1, 3],
            },
          },
        ],
      },
      {
        path: "request-ncr",
        name: "request-ncr-debt-solution",
        redirect: { name: "ds-ncr-client" },
        meta: {
          pageTitle: "Request NCR",
          breadcrumb: [
            {
              text: "Request NCR",
              active: true,
            },
          ],
          routeClient: "ds-ncr-client",
          routeLead: "ds-ncr-lead",
        },
        component: () =>
          import(
            /* webpackChunkName: "DefaultNameSection" */
            "@/views/commons/components/request-ncr/RequestNcrMain.vue"
          ),
        children: [
          {
            path: "client",
            name: "ds-ncr-client",
            redirect: { name: "ds-ncr-client-pending" },
            component: () =>
              import(
                /* webpackChunkName: "DefaultNameFirstTab" */
                "@/views/commons/components/request-ncr/RequestNcrClient.vue"
              ),
            meta: {
              pageTitle: "Request NCR",
              tabName: "client",
              breadcrumb: [
                {
                  text: "Client",
                  active: true,
                },
              ],
              routeClientPending: "ds-ncr-client-pending",
              routeClientFinished: "ds-ncr-client-finished",
              routeClientAnnulled: "ds-ncr-client-annulled",
            },
            children: requestNcrSubTab("client", "CLIENT", "ds"),
          },
          {
            path: "lead",
            name: "ds-ncr-lead",
            component: () =>
              import(
                /* webpackChunkName: "DefaultNameSecondTab" */
                "@/views/commons/components/request-ncr/RequestNcrLead.vue"
              ),
            redirect: { name: "ds-ncr-lead-pending" },
            meta: {
              pageTitle: "Request NCR",
              tabName: "lead",
              tabTitle: "Lead",
              breadcrumb: [
                {
                  text: "Lead",
                  active: true,
                },
              ],
              routeLeadPending: "ds-ncr-lead-pending",
              routeLeadFinished: "ds-ncr-lead-finished",
              routeLeadAnnulled: "ds-ncr-lead-annulled",
            },
            children: requestNcrSubTab("lead", "LEAD", "ds"),
          },
        ],
      },
      {
        path: "request-workplan",
        name: "request-workplan-debt-solution",
        redirect: { name: "ds-wp-pending" },
        meta: {
          pageTitle: "Request Workplan",
          breadcrumb: [
            {
              text: "Request Workplan",
              active: true,
            },
          ],
          routePending: "ds-wp-pending",
          routeCompleted: "ds-wp-completed",
          routeToRevision: "ds-wp-to-revision",
        },
        component: () =>
          import(
            "@/views/commons/components/request-workplan/RequestWorkplanMain.vue"
          ),
        children: requestWpTabs("ds"),
      },
    ],
  },
];
