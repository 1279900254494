<template>
  <div>
    <header-slot>
      <template #actions>
        <b-row class="w-100 pb-2">
          <b-col>
            <b-button
              v-show="isModuleActualEqualTwo"
              v-if="!isOnlyLead"
              variant="success"
              @click="openModalSearch()"
            >
              <feather-icon
                icon="SearchIcon"
                size="15"
                class="mr-50 text-white"
              />Payments Search
            </b-button>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <b-container class="m-0 w-100" fluid>
      <b-nav card-header pills class="m-0">
        <b-nav-item
          :to="{ name: $route.meta.newsTab }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          News
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.meta.tracingTab }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          Tracing
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.meta.successTab }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          Success
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.meta.recycledTab }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          Recycled
        </b-nav-item>
      </b-nav>
      <template>
        <b-card class="border-top-primary border-3 border-table-radius px-0">
          <router-view :key="$route.name" />
        </b-card>
      </template>
    </b-container>
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>


<script>
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";

export default {
  components: {
    ModalSearchPayment,
  },
  data() {
    return {
      campo1: "",
      modalGlobalSearch: false,
      clientsGlobal: [],
      changeNavAction: true,
      openModalSearchPayment: false,
      isOnlyLead: false,
      moduleActual: null,
      permitStatus: null,
    };
  },
  computed: {
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
  },
  async created() {
    await Promise.all([this.moduleId()]);
  },
  mounted() {
    this.getAccessModules();
  },
  methods: {
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
  },
};
</script>

<style scoped></style>
