import { sumTag } from '@/@core/utils/utils.js';
import customerTickets from '@/views/ce-digital/sub-modules/financial/view/customer-tickets/financial-customer-tickets.navigation';
import clients from '@/views/ce-digital/sub-modules/financial/view/clients/clients.nav';

export default {
  title: 'Clients',
  icon: 'UsersIcon',
  children: [clients, customerTickets],
  tag: sumTag([clients, customerTickets]),
};
