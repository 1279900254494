import { render, staticRenderFns } from "./Grid.vue?vue&type=template&id=2ed013d0&scoped=true"
import script from "./Grid.vue?vue&type=script&lang=js"
export * from "./Grid.vue?vue&type=script&lang=js"
import style0 from "./Grid.vue?vue&type=style&index=0&id=2ed013d0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ed013d0",
  null
  
)

export default component.exports