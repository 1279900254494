export default [{
  path: '/bussiness-dashboard-client/:idClient',
  name: 'bussiness-dashboard-general',
  redirect: { name: 'bussiness-client-task-notes-general' },
  component: () => import(/* webpackChunkName: "BusinessDashboardClients" */ '@/views/business/views/clients/dashboard/views/Dashboard.vue'),
  meta: {
    moduleName: 'bussiness',
    general: true,
    pageTitle: 'Client',
    breadcrumb: [
      {
        text: 'Personal Information',
        active: true,
      },
    ],
    module: 3,
  },
  children: [
    {
      path: 'information-general',
      name: 'bussiness-client-information-general',
      component: () => import(/* webpackChunkName: "BusinessClientInformation" */ '@/views/business/views/clients/dashboard/views/components/sections/Information.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'task-notes-general',
      name: 'bussiness-client-task-notes-general',
      component: () => import(/* webpackChunkName: "BusinessClientTaskNotes" */ '@/views/business/views/clients/dashboard/views/components/sections/TaskNotes.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'payments-general',
      name: 'bussiness-client-payments-general',
      component: () => import(/* webpackChunkName: "BusinessClientPayments" */ '@/views/business/views/clients/dashboard/views/components/sections/Payments.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'files-general',
      name: 'bussiness-client-o-files-general',
      component: () => import(/* webpackChunkName: "BusinessClientFiles" */'@/views/business/views/clients/dashboard/views/components/sections/Files.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'access-credentials-general',
      name: 'bussiness-client-access-credentials-general',
      component: () => import(/* webpackChunkName: "BusinessClientAccessCredentials" */ '@/views/business/views/clients/dashboard/views/components/sections/AccessCredential.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'call-log-general',
      name: 'bussiness-client-call-log-general',
      // redirect: { name: 'bussiness-client-general-call' },
      component: () => import('@/views/business/views/clients/dashboard/views/components/sections/CallLogSubtabs.vue'),
      meta: {
        pageTitle: 'Call Log',
        breadcrumb: [
          {
            text: 'Recording',
            active: true,
          },
        ],
      },
      // children: [
      //   {
      //     path: 'general-call',
      //     name: 'bussiness-client-general-call',
      //     component: () => import('@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue'),
      //     meta: {
      //       isClientsTab: true,
      //       pageTitle: 'General',
      //       breadcrumb: [
      //         {
      //           text: 'General',
      //           active: true,
      //         },
      //       ],
      //     },
      //   },
      //   {
      //     path: 'ceo-call',
      //     name: 'bussiness-client-ceo-call',
      //     component: () => import('@/views/commons/components/ring-central/call-log/components/CeoCallLog.vue'),
      //     meta: {
      //       isClientsTab: true,
      //       pageTitle: 'CEO calls',
      //       breadcrumb: [
      //         {
      //           text: 'CEO calls',
      //           active: true,
      //         },
      //       ],
      //     },
      //   },
      // ],
    },
    {
      path: 'tickets',
      name: 'client-bussiness-tickets-general',
      component: () => import('@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Tickets',
            active: true,
          },
        ],
      },
    },
  ],

}];
