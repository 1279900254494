import DashboardRouter from "../views/dashboard/dashboard.router"
import SalesMadeRouter from "../views/sales-made/sales-made.router"
import LeadsRouter from "../views/leads/leads.router"
import ClientsRouter from "../views/clients/clients.router"
import CalendarRouter from "../views/calendar/calendar.router"
import ClientCompany from "../views/client-company/client-company.router"
import SchedulesRouter from "../views/schedules/schedules.router"
import CommissionsRouter from "../views/commissions/commissions.router"
import ReportRouter from "../views/report/report.router"
import ClientDashboard from "../views/clients/dashboard.router"
import ClaimsRouter from "../views/claims-v2/claims.router"
import ZeropaymentRouter from "../views/zero-payment/zero-payment.router"
import LoansRouter from "../views/loans/loans.router"
import FileManagerRouter from "../views/file-manager/file-manager.router"
import PaymentsRouter from "../views/payments/router/payments.router"
import SettingsRouter from "../views/settings/settings.router"
import ChargeBackRouter from "@/views/business/views/charge-back/charge-back.router"
import HelpdeskRouter from "@/views/business/views/helpdesk/helpdesk.router"
import RequestNcrRouter from "@/views/business/views/request-ncr/request-ncr.router"
import RequestWorkplanRouter from "@/views/business/views/request-workplan/request-workplan.router.js";
import ExpensesRouter from "../views/expenses/expenses.router"
import ProductRequestRouter from "@/views/business/views/request/request.router.js"
import OtherPaymentRouter from "@/views/business/views/other-payments/other-payments.router"
import BusinessMain from "@/views/business/views/BusinessMain.vue"
import BuAttendanceRouter from "@/views/business/views/attendance/bu-attendance.router"
import EmployeeClaimsRouter from "@/views/business/views/employee-claims/router/employee-claims.router"
//import BusinessProjectsRouter from '@/views/business/views/projects/business-projects.router';
import CallLogRouter from '@/views/business/views/ring-central/call-log/call-log.router.js';
import CourInfoRouter from '@/views/business/views/cour-info/router/ds-court-info.router';
import DashboardCalls from "@/views/business/views/ring-central/dashboard-calls/dashboard.router.js";
import MOFRouter from '@/views/business/views/manual-of-functions/manual-of-functions.router.js'
import RecruitmentProcessRouter from '@/views/business/views/recruitment-process/business-recruitment_process.router.js';
import CustomerTicketsRouter from '@/views/business/views/customer-tickets/business-customer-tickets.router';
import InconsistentTransactionRouter from '@/views/business/views/inconsistent-transactions/router/inconsistent-transactions.router.js';
import CallImpressionRouter from '@/views/business/views/call-impression/router/call-impression.router.js';

const routes = [
  {
    path: '/bussiness',
    meta: { module: 3, program: 1},
    redirect: { name: 'dashboard-bussiness' },
    component: BusinessMain,
    children: [
      ...ClientDashboard,
      ...DashboardRouter,
      ...LeadsRouter,
      ...ClientsRouter,
      ...ClientCompany,
      ...SalesMadeRouter,
      ...RequestNcrRouter,
      ...RequestWorkplanRouter,
      ...CommissionsRouter,
      ...SchedulesRouter,
      ...LoansRouter,
      ReportRouter,
      ZeropaymentRouter,
      ...ClaimsRouter,
      FileManagerRouter,
      ...PaymentsRouter,
      ...SettingsRouter,
      ...CalendarRouter,
      ...ChargeBackRouter,
      HelpdeskRouter,
      ExpensesRouter,
      ...ProductRequestRouter,
      ...OtherPaymentRouter,
      ...BuAttendanceRouter,
      ...EmployeeClaimsRouter,
      //...BusinessProjectsRouter,
      ...CallLogRouter,
      ...CourInfoRouter,
      ...DashboardCalls,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...CustomerTicketsRouter,
      ...InconsistentTransactionRouter,
      ...CallImpressionRouter,
    ],
  },
];

export default routes;
