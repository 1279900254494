import store from '@/store'; // To get user(s)
import Vue from 'vue'; // To get the 'component' of 'Notification';

const notificationRequestRefundVoidToManagement = () => {

  window.socket.bind('notification-essential-document', async data => {
    const userModule = store.state.auth.currentUser.main_module;

    // show alert to management
    if (userModule === 17) {
      await Vue.prototype.$amgAlert({
        title: 'ESSENTIAL DOCUMENTS UPLOADED',
        icon: 'AlertCircleIcon',
        subtitle: `Employee ${data.userName}`,
        data: [
          {
            icon: 'userIcon',
            title: `From ${data.department}`,
            description: '',
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }
  });
};

export default notificationRequestRefundVoidToManagement;
