const { amgApi } = require("@/service/axios");

class LeadsService {
  async getLeads(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/sales/get-leads",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getLeads");
    }
  }
  async updateLeadsAndClients(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/sales/update-sales-leads-clients",
        params
      );
      return data;
    } catch (error) {
      console.log("Error updateLeadsAndClients");
    }
  }
  async getUsersModule(modul, params){
    try {
      const data = await amgApi.post(`commons/user-module/${modul}`, params)
      return data.data      
    } catch (e) {
      console.log(e)
    }
  }
  async getPrograms() {
    try {
      const data = await amgApi.get("/commons/programs");
      return data.data;
    } catch (error) {
      console.log("Error programs");
    }
  }
  async getUsersAllPrograms(params) {
    try {
      const data = await amgApi.post("commons/users-all-programs", params);
      return data.data;
    } catch (error) {
      console.log("Error getUsersAllPrograms");
    }
  }
  async insertSaleDebt(params) {
    try {
      const data = await amgApi.post("/attend/insert-sale-debt", params);
      return data;
    } catch (error) {
      console.log("Error insertSaleDebt");
      throw error;
    }
  }

  async createNcrRealtorRequest(params) {
    try {
      const data = await amgApi.post("/credit-experts-digital/sales/create-ncr-realtor-request", params);
      return data;
    } catch (error) {
      console.log("Error createNcrRealtorRequest");
      throw error;
    }
  }
  async getNcrRealtorRequestPending(params) {
    try {
      const data = await amgApi.post("/credit-experts-digital/sales/get-ncr-realtor-pending-requests", params);
      return data;
    } catch (error) {
      console.log("Error getNcrRealtorRequestPending");
      throw error;
    }
  }
}

export default new LeadsService();
