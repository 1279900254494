<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-footer
      hide-header-close
      hide-header
      :body-class="`rounded`"
      no-close-on-backdrop
      no-close-on-esc
      size="summary"
      @hidden="isActive = false"
      centered
    >
      <div v-if="dataAppointment">
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
        >
          <div class="d-flex align-items-center" style="gap: 7px">
            <span class="title-alert font-strong"> APPOINTMENT ALERT </span>
          </div>

          <!-- <div
            class="close-modal"
            @click="closeModal"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
          >
            <feather-icon icon="XIcon" size="16" />
          </div> -->

        </div>

        <hr />
        <div class="d-flex flex-column" style="gap: 15px">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CLIENT
            </span>
            <span class="font-strong">{{ dataAppointment.lead_name }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              APPOINTMENT TYPE
            </span>
            <span class="font-strong">{{ dataAppointment.tc_description }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="WatchIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              SYSTEM HOUR
            </span>
            <span class="font-strong">{{ dataAppointment.system_hour | myGlobalDay}}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="WatchIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CLIENT HOUR
            </span>
            <span class="font-strong">{{ dataAppointment.original_hour | myGlobalDay}}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="PhoneIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              MOBILE
            </span>
            <span class="font-strong">{{ dataAppointment.mobile }}</span>
          </div>

          <div v-if = "is_reschedule">

            <validation-observer ref="formReschedule">
              <b-row>
                <b-col lg="12" xl="6">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <b-form-group label="DAY: ">
                      <b-form-datepicker
                        v-model="inputDate"
                        class="form-control"
                        :class="errors[0] && validate ? 'border-danger' : ''"
                        :min="new Date()"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        @input="changeDate"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col lg="12" xl="6">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <b-form-group label="HOUR: ">
                      <b-form-select
                        v-model="inputTime"
                        :options="timeOptions"
                        class="form-control"
                        :class="errors[0] && validate ? 'border-danger' : ''"
                        :disabled="inputDate == null"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </validation-observer>
          </div>

          <div v-if="is_cancel">

            <validation-observer ref="formCancel">
              <b-row>
                <b-col>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <b-form-group label="Motives:">
                      <b-form-select
                        v-model="motive_id"
                        class="form-control"
                        :options="cancelation_motives"
                        :class="
                          errors[0] && validate ? 'border-danger' : ''
                        "
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </validation-observer>
          </div>

        </div>
        <hr />
        <div>
        <!-- <b-col cols="12" class="text-center my-1">
            This alert will close in
            <b> {{ ` ${timeInMinutes} minutes, ${timeInSeconds} seconds` }}</b>
        </b-col> -->
        <b-row v-if="alert_buttons" >
          <b-col :cols="12" class="d-flex align-items-center justify-content-center">
            <b-button
              size="md"
              variant="outline-success"
              target="_blank"
              class="w-100"
              @click="goToAppointment('in-process')"
              >OK
            </b-button>
          </b-col>
          <!-- <b-col :cols="4" class="d-flex align-items-center justify-content-center">
            <b-button
            size="md"
              variant="outline-primary"
              @click="is_reschedule = true, alert_buttons = false"

            >
              RESCHEDULE
            </b-button>
          </b-col>
          <b-col :cols="4" class="d-flex align-items-center justify-content-center">
            <b-button
            size="md"
              variant="outline-danger"
              @click="is_cancel = true, alert_buttons = false"
              class="w-100"
            >
              CANCEL
            </b-button>
          </b-col> -->
        </b-row>
        <!-- <b-row v-if="is_reschedule">
          <b-col :cols="6" class="d-flex align-items-center justify-content-center">
            <b-button
              size="md"
              variant="outline-success"
              @click="rescheduleAppointment"
              class="w-100"
            >
              SAVE
            </b-button>
          </b-col>
          <b-col :cols="6" class="d-flex align-items-center justify-content-center">
            <b-button
                size="md"
                variant="outline-danger"
                @click="alert_buttons = true, is_reschedule = false"
                class="w-100"
              >
                BACK
            </b-button>
          </b-col>
        </b-row>
        <b-row v-if="is_cancel">
          <b-col :cols="6" class="d-flex align-items-center justify-content-center">
            <b-button
              size="md"
              variant="outline-success"
              @click="cancelAppointment"
              class="w-100"
            >
              SAVE
            </b-button>
          </b-col>
          <b-col :cols="6" class="d-flex align-items-center justify-content-center">
            <b-button
                size="md"
                variant="outline-danger"
                @click="alert_buttons = true, is_cancel = false"
                class="w-100"
              >
                BACK
            </b-button>
          </b-col>
        </b-row> -->
      </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex"
import SettingsServiceSp from "@/views/specialists/sub-modules/financial-approval/views/settings/service/settings.service.js";
import FinancialApprovalService from "@/views/specialists/sub-modules/financial-approval/services/financial-approval.service";
import AppointmentService from "@/views/commons/components/appointments/service/appointment.service";
export default {
  name: "AlertAppointment",
  data() {
    return {
      alert_buttons: true,
      is_reschedule: false,
      is_cancel: false,
      detailAppointment: null,
      cancelation_motives: [],
      //FORM
      validate: false,
      inputDate: null,
      inputTime: null,
      motive_id: null,
      timeOptions: [],
      schedule: [],
      timeInMinutes: 0,
      timeInSeconds: 58,
      temp: null,
    }
  },
  computed: {
    ...mapGetters({
      G_IsActive: "AlertAppointment/G_IsActive",
      G_DataAppointment: "AlertAppointment/G_DataAppointment",
      title: "AlertAppointment/G_Title",
      currentUser: "auth/currentUser",
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
    }),
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },

    isActive: {
      get() {
        return this.G_IsActive
      },
      set(value) {
        this.$store.commit("AlertAppointment/M_OpenAlert", value)
        // setTimeout(() => {
        //   this.dataAppointment = null
        // }, 500)
      },
    },

    dataAppointment: {
      get() {
        return this.G_DataAppointment
      },
      set(value) {
        this.$store.commit("AlertAppointment/M_DataAppointment", value)
      },
    },

  },
  mounted() {
    this.timeInMinutes = 0;
    this.timeInSeconds = 58;
    // this.startTemp();
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "AlertAppointment/CHANGE_REFRESH_TABLE",
    }),
    startTemp(){
      this.temp = setInterval(() => {
        if (this.timeInSeconds > 0) {
          this.timeInSeconds--;
        } else {
          if (this.timeInMinutes > 0) {
            this.timeInMinutes--;
            this.timeInSeconds = 59;
          } else {
            const params = {
              id: this.dataAppointment.appointment_alert_id,
            }
            FinancialApprovalService.chageStatusNotContacted(params)
            this.closeModal();
          }
        }
      }, 1000);
    },
    goToAppointment(status) {
      this.closeModal();
      const subTabName = this.$route.name;
      const targetTabName = `sp-appointment-${status}`;
      if(this.$route.matched[1].meta.router == 'sp-appointment'){
        if (subTabName == targetTabName) {
          this.CHANGE_REFRESH_TABLE(true);
          return
        }
        this.$router.push({ name: targetTabName });
        return
      }
      const url = this.$router.resolve({
        name: targetTabName,
      }).href;
      window.open(url, '_blank');
    },
    async acceptAppointment() {

      let confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        let result = await this.updateAppointmentStatus('accept');
        const url = this.$router.resolve({
          name: this.clientDashboardRouteName,
          params: {
            idClient: this.dataAppointment.client_account_id,
          },
        }).href;
        window.open(url, '_blank');
        this.goToAppointment('accepted')
        this.removePreloader();
        if (result) {
          this.showSuccessSwal();
          // this.$emit("refreshTable");
        } else {
          this.showErrorSwal();
        }
        this.closeModal();
      }
    },
    async rescheduleAppointment() {
      this.validate = true;
      const isValid = await this.$refs.formReschedule.validate();
      if (!isValid) {
        return;
      }
      let confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        let result = await FinancialApprovalService.storeDate({
          form_appointment_id: this.dataAppointment.form_appointment_id,
          appointment_date: this.inputDate,
          appointment_time: this.inputTime,
        });
        await this.updateAppointmentStatus('reschedule');
        this.goToAppointment('in-process')
        this.removePreloader();
        if (result) {
          this.showSuccessSwal();
          // this.$emit("refreshTable");
        } else {
          this.showErrorSwal();
        }
        this.closeModal();
      }
    },
    async cancelAppointment() {
      this.validate = true;
      const isValid = await this.$refs.formCancel.validate();
      if (!isValid) {
        return;
      }
      let confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        let result = await this.updateAppointmentStatus('cancel');
        this.goToAppointment('rejected')
        this.removePreloader();
        if (result) {
          this.showSuccessSwal();
          // this.$emit("refreshTable");
        } else {
          this.showErrorSwal();
        }
        this.closeModal();
      }
    },
    async updateAppointmentStatus(status) {
      const {data} = await AppointmentService.updateAppointmentStatus({
        type: 'app_status',
        appointment_id: this.dataAppointment.appointment_id,
        button_selected: status,
        created_by: this.currentUser.user_id,
        motive: this.getMotiveTextById(this.motive_id),
        type_charge: this.dataAppointment.type_charge_id
      });
      return data;
    },


    async getMotives(){
      const { data } = await SettingsServiceSp.getAppointmentCancellationMotives();
      this.cancelation_motives = data.map((motive) => {
        return {
          value: motive.id,
          text: motive.name,
        };
      });
    },

    getMotiveTextById(motive_id) {
      const motive = this.cancelation_motives.find((motive) => motive.value === motive_id)
      if(motive){
        return motive.text
      }
      return "-";
    },

    closeModal() {
      clearInterval(this.temp);
      this.timeInMinutes = 0;
      this.timeInSeconds = 58;
      const channel = socket.channel("appointments-notifications", {});
      channel.trigger("client-close-all-appointment-notification", {
        message: "close all",
        user_id: this.currentUser.user_id,
      });
      this.isActive = false;

    },
    async getSchedule() {
      const { data } = await SettingsServiceSp.getAppointmentSchedule();
      this.schedule = data;
    },
    changeDate(value) {
      this.timeOptions = [];
      this.inputTime = null;
      const selectedDate = new Date(value);
      const dayOfWeek = selectedDate.getDay();
      const { start_hour, end_hour } = this.schedule.find(
        (item) => item.day == dayOfWeek + 1
      );
      this.timeOptions = this.generateTimeOptions(start_hour, end_hour, 30);
    },



  },
  async created(){
    await this.getSchedule();
    await this.getMotives();
  }
}
</script>
<style lang="scss" scoped>
.icon-badge {
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &.completed {
    background-color: rgba(40, 199, 111, 0.1);
    color: #468844;
  }
  &.pending {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.reactivated {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.scaled {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
}

.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}
</style>
