<template>
  <div class="rc-chat-welcome-container">
    <div class="text-center">
      <img
        width="auto"
        height="250px"
        src="https://play-lh.googleusercontent.com/r57x1MpC8bGErJLGGa45MLJT3IcBtzszdQ2ANrrgN0PG1gGUvSswuwbf9zFbgkVF9oM"
      />
      <h1 class="my-2 title">Ring Central Web</h1>

      <p v-if="!findAll" class="description">
        Create an sms to start
      </p>

      <p v-else class="description">
        Select an item on the left for a more detailed look.
      </p>
      <div v-if="!findAll" class="text-center">
        <b-button variant="primary" @click="openModalCreateChat()">
          + NEW SMS
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import NestSmsService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
import { mapGetters } from "vuex";
export default {
  props: {
    findAll: {
      type: Boolean,
      default: true,
    },

    toNumber: {
      type: String,
    },

    credential: {
      type: Object,
    },
  },

  data() {
    return {
      showModalCreateChat: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  methods: {
    async openModalCreateChat() {
      if (!this.toNumber) {
        this.showInfoSwal("Lead without phone number");
        return;
      }

      if (!this.credential) {
        this.showInfoSwal("Select a Line Number");
        return;
      }

      try {
        this.addPreloader();

        const createdBy = {
          id: this.currentUser.user_id,
          name: this.currentUser.fullName,
        };

        const firstNumber = this.credential.number_format;
        const secondNumber = this.toNumber;

        const params = {
          firstNumber: firstNumber,
          secondNumber: secondNumber,
          createdBy: createdBy,
        };

        const { status } = await NestSmsService.createChat(params);

        if (status == 201) {
          const params2 = {
            modulePhoneNumber: this.credential.number_format,
            clientPhoneNumber: this.toNumber,
          };
          const { data } = await NestSmsService.getClientChat(params2);
          if (data) {
            this.$emit("chatCreated", data);
          }
        }
      } catch (error) {
        if (error.response.data.statusCode == 409) {
          this.showInfoSwal(error.response.data.message);
        } else {
          this.showErrorSwal(error.response.data.message);
        }
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style lang="scss">
.rc-chat-welcome-container {
  font-family: "Rubick" !important;
  height: 100%;
  width: 100%;
  background-color: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    font-size: 30px;
    font-weight: 600;
  }

  .description {
    font-size: 18px;
  }
}

.dark-layout {
  .rc-chat-welcome-container {
    background-color: #202c33;
  }
}
</style>