<template>
  <div>
    <b-modal
      ref="modal"
      :header-bg-variant="changeBg(newStatus)"
      no-close-on-backdrop
      centered
      size="sm"
      @hidden="$emit('hidden')"
    >
      <template #modal-title>
        <h5
          style="font-family: Montserrat; font-weight: bolder; color: #ffffff"
        >
          {{ title }}
        </h5>
      </template>

      <validation-observer ref="observer">
        <b-row
          v-if="
            newStatus == null && statusFidelization == null && !isTypeExpense
          "
          class="my-1"
        >
          <b-col>
            <b-button-group>
              <b-button
                size="md"
                :variant="status === 6 ? 'primary' : 'outline-primary'"
                @click="selectOption(6)"
              >
                <strong>SOLVED AND FIDELIZED</strong>
              </b-button>
              <b-button
                size="md"
                :variant="status === 5 ? 'danger' : 'outline-danger'"
                @click="selectOption(5)"
              >
                <strong>SOLVED NOT FIDELIZED</strong>
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <b-row v-if="status == 6" class="mb-1">
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="Program"
              rules="required"
            >
              <b-form-group
                label="Program"
                label-for="program"
                :state="errors[0] ? false : null"
              >
                <v-select
                  ref="programSelector"
                  v-model="data.program"
                  input-id="program"
                  rules="'required'"
                  label="label"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="G_PROGRAMS"
                  :multiple="true"
                  :close-on-select="false"
                  placeholder="Select Program"
                  :class="{
                    'border-danger': errors[0],
                  }"
                  @input="handleSelect"
                />
                <span v-if="errors[0]" class="text-danger">
                  Program {{ errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:500"
              name="osbservsation"
            >
              <b-form-group label="Observation">
                <b-form-textarea
                  id="textarea-rows"
                  v-model="observations"
                  placeholder="Write observation"
                  rows="10"
                  no-resize
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
              <small v-if="errors[0]" class="text-danger">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <b-button
          size="sm"
          :variant="newStatus === 4 ? 'danger' : 'primary'"
          @click="closeTicket()"
        >
          FINISH
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    customerTicket: {
      type: Object,
      default: null,
      required: true,
    },
    newStatus: {
      type: Number,
      default: null,
    },
    statusFidelization: {
      type: Number,
      default: null,
    },
    isTypeExpense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        program: null,
      },
      selectProgramModalOn: false,
      observations: null,
      status: null,
      options: [
        { value: 6, text: "" },
        { value: 5, text: "Solved but not fidelized" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_PROGRAMS: "CrmGlobalStore/G_PROGRAMS",
    }),
    title() {
      if (this.customerTicket)
        return `Close ticket of ${this.customerTicket.name_client}`.toUpperCase();
      return "";
    },
    currentTabType() {
      return this.$route.matched[this.isCeo ? 1 : 2].meta.type;
    },
  },
  async mounted() {
    this.toggleModal("modal");
    this.status = this.newStatus || this.statusFidelization;
    await this.A_GET_PROGRAMS();
  },
  methods: {
    handleSelect() {},

    ...mapActions({
      A_COUNT_INDICATORS_TICKETS:
        "NotificationStore/A_COUNT_INDICATORS_TICKETS",
      A_COUNT_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
      A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
    }),

    selectOption(value) {
      this.status = value;
    },

    closeModal() {
      this.$emit("closeModal");
    },

    async closeTicket() {
      if (!this.status && !this.isTypeExpense) {
        this.showInfoSwal("Select a fidelization status");
        return;
      }

      const validation = await this.$refs.observer.validate();
      if (!validation) return;

      const resultState = await this.showConfirmSwal();
      if (!resultState.isConfirmed) return;

      try {
        this.addPreloader();
        const obj = this.customerTicket;
        obj.customer_status_id =
          this.isTypeExpense && this.status != 7 ? 9 : this.status; // when is expenses the status is 9 (completed)
        obj.observations = this.observations;
        obj.updated_by = this.currentUser.user_id;
        obj.customer_name = this.customerTicket.name_client;
        obj.programs = this.data.program;

        const res = await CustomerTicketsService.closeTicket(obj);
        if (res.status === 200) {
          await this.A_COUNT_INDICATORS_TICKETS({
            user_id: this.currentUser.user_id,
            module_id: this.currentUser.main_module,
          });
          await this.A_COUNT_PENDING_CUSTOMER_TICKETS({
            user_id: this.currentUser.user_id,
            type_ticket: this.currentTabType,
          });
          await this.A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE({
            user_id: this.currentUser.user_id,
          });
          this.$emit("refreshTable");
          this.removePreloader();
          this.showSuccessSwal();
          this.$emit("closeModal");
        }
      } catch (error) {
        console.error(error);
        this.removePreloader();
      }
    },
    changeBg(status) {
      let color;

      switch (status) {
        case 4:
          color = "danger";
          break;
        case 7:
          color = "warning";
          break;
        default:
          color = "primary";
      }

      return color;
    },
  },
};
</script>
