// Applicant
const optionsApplicantEmployer = [
    {
        id:1,
        label: 'EMPLOYED'
    },
    {
        id:2,
        label: 'UNEMPLOYED'
    },
    {
        id:3,
        label: 'RETIRED'
    },
    {
        id:4,
        label: 'DISABILITY'
    },
    {
        id:5,
        label: 'DEPENDENT'
    }
]

const optionsApplicantTypeOfPayment = [
    {
        id: 1,
        label: 'CASH'
    },
    {
        id: 2,
        label: 'CHECK'
    },
    {
        id: 3,
        label: 'MONEY TRANSFER'
    },
    {
        id: 4,
        label: 'OTHER'
    }
]

const optionsApplicantRemunerated = [
    {
        id: 1,
        label: 'WEEKLY'
    },
    {
        id: 2,
        label: 'BIWEEKLY'
    },
    {
        id: 3,
        label: 'MONTHLY'
    }
]

const optionsApplicantDepentends = [
    {
        label: 1
    },
    {
        label: 2
    },
    {
        label: 3
    },
    {
        label: 4
    },
    {
        label: 5
    },
    {
        label: 6
    },
    {
        label: 7
    },
    {
        label: 8
    },
    {
        label: 9
    },
    {
        label: 10
    }
]

// CoApplicant

const optionsCoApplicantEmployee = [
    {
        id:1,
        label: 'EMPLOYED'
    },
    {
        id:2,
        label: 'UNEMPLOYED'
    },
    {
        id:3,
        label: 'RETIRED'
    },
    {
        id:4,
        label: 'DISABILITY'
    },
    {
        id:5,
        label: 'DEPENDENT'
    }
]

const optionsCoApplicantTypeOfPayment = [
    {
        id: 1,
        label: 'CASH'
    },
    {
        id: 2,
        label: 'CHECK'
    }
]

const optionsCoApplicantDepentends = [
    {
        label: 1
    },
    {
        label: 2
    },
    {
        label: 3
    },
    {
        label: 4
    },
    {
        label: 5
    },
    {
        label: 6
    },
    {
        label: 7
    },
    {
        label: 8
    },
    {
        label: 9
    },
    {
        label: 10
    }
]

export default {
    optionsApplicantEmployer, optionsApplicantTypeOfPayment, optionsApplicantDepentends, optionsApplicantRemunerated, optionsCoApplicantEmployee, optionsCoApplicantTypeOfPayment, optionsCoApplicantDepentends,
  };