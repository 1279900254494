<template>
  <div>
    <b-modal
      ref="modal_recruitment_process"
      v-model="onControl"
      size="md"
      modal-class="custom-modal-amg"
      no-close-on-backdrop
      title-class="h3 font-weight-bolder text-white"
      hide-footer
      centered
      @hidden="close"
      header-class="p-0"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <feather-icon icon="PlusIcon" class="text-white" size="22" />
            <span> {{ ticket.code ? getTitle() : getTitleDetails() }}</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--white' : ''"
            @click="close()"
          >
            <feather-icon icon="XIcon" size="18" class="text-primary pointer" />
          </div>
        </div>
      </template>
      <p>Description:</p>
      <div
        class="mb-1"
        style="
          padding: 1rem;
          border-radius: 10px;
          max-height: 250px;
          overflow: auto;
        "
        :style="
          isDarkSkin
            ? 'border: solid 1px #3d3d3d'
            : 'border:solid 1px rgb(192 192 192)'
        "
      >
        <b-form-textarea
          id="textarea-plaintext"
          :style="isDarkSkin ? 'color:white' : 'color:gray'"
          plaintext
          :value="details.description || ticket.value"
          rows="10"
          no-resize
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    ticket: {
      type: Object,
      default: () => {},
    },
    details: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      onControl: false,
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {},
  async created() {
    this.onControl = true;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getTitle() {
      const ticketTitle = this.ticket.code
        ? `TICKET: ${this.ticket.code}`
        : "INFO NOT FOUND";

      const nameClient = this.ticket.name_client;

      if (!this.ticket.accounts) {
        if (nameClient) {
          return `${ticketTitle} - ${nameClient} - NOT ACCOUNTS1`;
        } else {
          return `${ticketTitle}`;
        }
      }

      // Agregar información de las cuentas al título
      const accountsInfo = JSON.parse(this.ticket.accounts);
      const accountsNames = accountsInfo.map((account) => account.account);
      const accountsTitle = `Accounts: ${accountsNames.join(", ")}`;

      return `${ticketTitle} - ${nameClient} - \n${accountsTitle}`;
    },
    getTitleDetails() {
      const ticketTitle = this.details.code
        ? `TICKET ${this.details.code}`
        : "INFO NOT FOUND";

      const nameClient = this.details.name_client;

      if (!this.details.accounts) {
        if (nameClient) {
          return `${ticketTitle} - ${nameClient} - NOT ACCOUNTS1`;
        } else {
          return `${ticketTitle}`;
        }
      }
      // Agregar información de las cuentas al título
      const accountsInfo = JSON.parse(this.details.accounts);
      const accountsNames = accountsInfo.map((account) => account.account);
      const accountsTitle = `Accounts: ${accountsNames.join(", ")}`;

      return `${ticketTitle} - ${nameClient} - \n${accountsTitle}`;
    },
  },
};
</script>
<style scope lang="scss">
.header-modal {
  border-radius: 3px 3px 0px 0px;
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.5rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
.header-modal {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}
</style>
