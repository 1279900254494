Object.defineProperty(Array.prototype,'isNotEmpty',{
    value: function(){ return this.length > 0} ,
    enumerable: false,
    writable: true, // para permitir que la función sea sobrescrita más adelante si es necesario
    configurable: true // para permitir que la propiedad sea eliminada más adelante si es necesario
})

Object.defineProperty(Array.prototype,'isEmpty',{
    value: function(){ return this.length === 0},
    enumerable: false,
    writable: true, // para permitir que la función sea sobrescrita más adelante si es necesario
    configurable: true // para permitir que la propiedad sea eliminada más adelante si es necesario
})