export default [
    {
      path: "workplan-requests",
      name: "specialistdigital-workplan-requests",
      redirect: { name: "specialistdigital-workplan-pending" },
      component: () =>
        import(
          /* webpackChunkName: "specialistsWorkplanRequests" */
          "@/views/specialist-digital/views/connection-clients/ConnectionClients.vue"
        ),
      meta: {
        pageTitle: "Connection Clients",
        module: 11,
        moduleId: 11,
        breadcrumb: [
          {
            text: "Connection Clients Pending",
            active: true,
          },
        ],
      },
      children: [
        {
          path: "workplan-requests-pending",
          name: "specialistdigital-workplan-pending",
          component: () =>
            import(
              /* webpackChunkName: "specialistsWorkplanRequestsP" */
              "@/views/specialists/views/workplan-and-letters-request/WorkPlans.vue"
            ),
          meta: {
            pageTitle: "Connection Clients",
            completed: 0,
            breadcrumb: [
              {
                text: "Connection Clients Pending",
                active: true,
              },
            ],
          },
        },
        {
          path: "workplan-requests-completed",
          name: "specialistdigital-workplan-completed",
          component: () =>
            import(
              /* webpackChunkName: "specialistsWorkplanRequestsC" */
              "@/views/specialists/views/workplan-and-letters-request/WorkPlans.vue"
            ),
          meta: {
            pageTitle: "Connection Clients",
            completed: 1,
            breadcrumb: [
              {
                text: "Connection Clients Completed",
                active: true,
              },
            ],
          },
        },
        {
          path: "workplan-requests-annul",
          name: "specialistdigital-workplan-annul",
          component: () =>
            import(
              /* webpackChunkName: "specialistsWorkplanRequestsC" */
              "@/views/specialists/views/workplan-and-letters-request/WorkPlans.vue"
            ),
          meta: {
            pageTitle: "Connection Clients",
            completed: 2,
            breadcrumb: [
              {
                text: "Connection Clients Annul",
                active: true,
              },
            ],
          },
        },
      ],
    },
  ];
  