
export default [
  {
    path: 'payments-commissions',
    name: 'ced-commissions-financial',
    component: () => import(/* webpackChunkName: "CEDFinancialCommissions" */ '@/views/ce-digital/sub-modules/financial/view/commissions/FinancialCommissions.vue'),
    meta: {
      parentModule: 21,
      pageTitle: 'Commissions',
      breadcrumb: [
        {
          text: 'Commissions',
          active: true,
        },
      ],
    },
  },
]
