import { amgApi } from "@/service/axios";

class DashboardCeoService {
  async refreshDashboard(params) {
    try {
      const data = await amgApi.post("/ceo/dashboard/general/update", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getLastUpdate() {
    try {
      const data = await amgApi.post("/ceo/dashboard/general/show");
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getSales(params) {
    try {
      const { data } = await amgApi.post("/ceo/dashboard/sales/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async detailSales(params) {
    try {
      const { data } = await amgApi.post("/ceo/dashboard/sales/show", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getExpenses(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDetailsExpenses(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getIncomes(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/incomes/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getMonthlyPayments(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/monthly-payments-progress/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getMissingMonthlypayments(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/clients-no-payment/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDetailedMissingMonthlyPayments(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/clients-no-payment/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getMissingPaymentsInHoldClients(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/clients-in-hold/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDetailedMissingPaymentsInHoldClients(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/clients-in-hold/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDetailsMonthlyPayments(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/monthly-payments-progress/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDetailsIncomes(params) {
    try {
      const { data } = await amgApi.post("/ceo/dashboard/incomes/show", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAnnualSummary(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/annual-expenses/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async clientsStatusIndex(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/clients-status/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async programs() {
    const data = await amgApi.get("/commons/programs");
    return data;
  }

  async getDepartments() {
    const data = await amgApi.get("/new-commons/departments/index");
    return data;
  }

  async getEmployeeTurnover(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/employee-turnover/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDetailsEmployeeTurnover(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/employee-turnover/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async clientsStatusFilter(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/clients-status/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAnnualIncomesSummary(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/annual-incomes-summary-chart/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getSalesGraphicIndex(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/get-data-sales-graphic/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTasks() {
    try {
      const { data } = await amgApi.get("/ceo/dashboard/tasks/index");
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getMeeting() {
    try {
      const { data } = await amgApi.get("/ceo/dashboard/meetings/index");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getSalesGraphicShow(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/get-data-sales-graphic/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getMarketingRoiIndex(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/marketing-roi/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getMarketingRoiDetailedData(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/marketing-roi/show",
        params
      );
      return data;
    } catch (err) {
      throw err;
    }
  }

  async getDsStatistics(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/ds-statistics/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDsStatisticsModal(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/ds-statistics/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCeStatistics(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/credit-statistics/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCeStatisticsModal(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/credit-statistics/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async convertProgram(params) {
    try {
      const { data } = await amgApi.post(
        "/new-commons/program-to-module/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getProgramsByNewStatus(params) {
    try {
      const { data } = await amgApi.post(
        "/commons/get-programs-new-status",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDeployedNewPaymentsPrograms() {
    try {
      const { data } = await amgApi.post(
        "/commons/get-deployed-new-payments-modules"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async inNewStatusClientAccount(params) {
    try {
      const { data } = await amgApi.post(
        "/commons/new-deployment-by-client-account",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getClientAutomaticPayments(params) {
    try {
      const data = await amgApi.post(
        "/commons/get-client-automatic-payments",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getClientAutomaticPaymentsDetail(params) {
    try {
      const data = await amgApi.post(
        "/commons/get-client-automatic-payments-detail",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getNcrData(params) {
    try {
      const { data } = await amgApi.post("/ceo/dashboard/ncr/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getNcrDetails(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/ncr/details/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllModules() {
    try {
      const { data } = await amgApi.get("/module/get-modules");
      return data;
    } catch (error) {
      console.log("Something went wrong on allModules ", error);
      throw error;
    }
  }

  async chargeBackStatusTracking(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/tracking-status-chargeback/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTotalsTicket(params) {
    try {
      const { data } = await amgApi.post("/helpdesk/get-totals-ticket", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTicketsDetails(params) {
    try {
      const { data } = await amgApi.post(
        "/helpdesk/get-tickets-details",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCustomerTicket(params) {
    try {
      const { data } = await amgApi.post(
        "/customer-tickets/get-customer-ticket",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCounterOthersPayments(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/counter-other-payments",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCounterAttendance(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/attendance/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAttendance(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/attendance/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getExpensesByDepartment(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/department/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getExpensesByBank(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/bank/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getExpensesByCreditCard(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/credit-card/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRequestExpenses(params) {
    try {
      const data = await amgApi.get(
        "/ceo/dashboard/expenses/department/request/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getExpensesByCard(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/index",
        params
      );
      return data;
    } catch (error) {}
  }

  async changeExpenseStatus(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/update",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getClientByExpenseDepartmentId(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/client-by-expense-department",
        params
      );
      return data;
    } catch (error) {}
  }
  async totalDepartmentExpenses(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/counter-expenses",
        params
      );
      return data;
    } catch (error) {}
  }
  async getTransactionsByCard(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-transactions-by-card",
        params
      );
      return data;
    } catch (error) {}
  }

  async importTransactions(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/import-expense-transaction",
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getImportedTransactions(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/imported-transactions/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCreditCards(params) {
    try {
      const data = await amgApi.post("/expenses/list-cards", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTotalImportedsByBank(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/imported-transactions/show-by-bank",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTotalImportedsByCard(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/imported-transactions/show-by-card",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getImportedTransactionsData(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/imported-transactions/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async existTransaction() {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/exist-transaction"
      );
      return data;
    } catch (error) {}
  }

  async getHistoryImports(params) {
    try {
      return await amgApi.post(
        "/ceo/dashboard/expenses/imported-transactions/get-history",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async getHistoryDetails(params) {
    try {
      return await amgApi.post(
        "/ceo/dashboard/expenses/imported-transactions/get-history-details",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async getImportedTransactionsByCard(params) {
    try {
      return await amgApi.post(
        "/ceo/dashboard/expenses/imported-transactions/get-imported-transactions-by-card",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async getImportedTransactionsDetails(params) {
    try {
      return await amgApi.post(
        "/ceo/dashboard/expenses/imported-transactions/get-imported-transactions-details",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async getBankCards() {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-bank-card"
      );
      return data;
    } catch (error) {}
  }

  async updateVendorConcept(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/update-vendor-concept",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateAmount(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/update-amount",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateUnmatchedTransactions(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/imported-transactions/update-unmatcheds",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTotalsByCreditCard(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-totals-by-card",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTotalsByModule(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-totals-by-module",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getUnMatchedTransactions(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-unmatched-transactions",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getUnmatchedTransactionsToSync(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-unmatched-transactions-to-sync",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async selectManualSyncImport(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/select-manual-sync-import",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDetailExpenses(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-detail-expenses",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCardList(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-card-list",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDepartmentList(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-department-list",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getResumeTotals(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-resume-totals",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCheckExpensesByStatus(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/check/get-by-status",
        params
      );
      return data;
    } catch (error) {}
  }

  async approveCheck(params) {
    try {
      const data = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/check/approve",
        params
      );
      return data;
    } catch (error) {}
  }

  async uploadCheck(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/check/upload",
        params
      );
      return data;
    } catch (error) {}
  }

  async changeStatusCheck(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/check/change-status",
        params
      );
      return data;
    } catch (error) {}
  }

  async getCreditCardsCounter() {
    try {
      const { data } = await amgApi.get(
        "/ceo/dashboard/expenses/department/request/credit-cards"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getChecksCounter() {
    try {
      const { data } = await amgApi.get(
        "/ceo/dashboard/expenses/department/request/checks"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAccountList(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/check/account-list",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async validateCheckNumber(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/check/validate-check-number",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeStatusCollect(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/check/change-status-collect",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async annulCheck(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/check/annul-check",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCardTab(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-card-tab",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPendingApprovalChecks(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/check/pending-approval-checks",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getChecksTracking(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/check/tracking/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCountInProcess(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-count-in-process",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPendingEchecks(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/e-check/get-pending-approval", params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async approveEcheck(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/e-check/approve",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async collectEcheck(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/e-check/collect",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getMatchedImportExpensesCard(params) {
    try {
      const { data } = await amgApi.post(
        "/ceo/dashboard/expenses/department/request/card/get-matched-card-expenses",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new DashboardCeoService();
