
const ProgramSlug = (slug) => {
    switch(slug){
        case 'bc':
          return 'Boost Credit'
        case 'bu':
          return 'Business'
        case 'ce':
          return 'Credit Experts'
        case 'ds':
          return 'Debt Solution'
        case 'tr':
          return 'Tax Research'
      }
}

export { ProgramSlug }