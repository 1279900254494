import pendingPaymentsNavigation from "@/views/ce-digital/sub-modules/financial/view/pending-payments/navigation/pending-payments.navigation";
import dashboardNavigation from "@/views/ce-digital/sub-modules/financial/view/dashboards/ce-dashboard.navigation";
import commissionsNav from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.nav";
import zeroPaymentNavigation from "@/views/ce-digital/sub-modules/financial/view/zero-payment/zero-payment-ce-digital.nav";
import paymentsNav from "@/views/ce-digital/sub-modules/financial/view/payments/payments.nav";
import chargesMadeNavigation from "@/views/ce-digital/sub-modules/financial/view/charges-made/charges-made.nav";
import clients from "@/views/ce-digital/sub-modules/financial/view/clients/clients-group.navigation";
import notificationAppNavigation from "@/views/ce-digital/sub-modules/financial/notifications-app/navigation/index";
import deletedCardsNav from "@/views/ce-digital/sub-modules/financial/view/deleted-cards/deleted-cards.nav";
import AppPaymentsMadeNav from "@/views/ce-digital/sub-modules/financial/view/app-payments-made/app-payments-made.nav";
import leadNav from "@/views/ce-digital/sub-modules/financial/view/leads/leads.nav";
import HelpDeskNav from "@/views/ce-digital/sub-modules/financial/view/helpdesk/helpdesk.nav";
import GeneralCommissionsNav from "@/views/ce-digital/sub-modules/financial/view/general-commissions/general-commissions.nav";
import chargeBackNav from "@/views/credit-experts/views/charge-back/charge-back.nav";
import otherPaymentsNavigation from "@/views/ce-digital/sub-modules/financial/view/other-payments/other-payments.nav.js";
import EmployeeClaimsNavigation from "@/views/ce-digital/sub-modules/financial/view/employee-claims/navigation/employee-claims.nav";
import RequestNav from "@/views/ce-digital/sub-modules/financial/view/request/request.nav.js";
import RingCentralNavigation from "@/views/ce-digital/sub-modules/financial/view/ring-central/ring-central.nav.js";
import CourtInfoNavigation from "@/views/ce-digital/sub-modules/financial/view/cour-info/navigation/ds-court-info.nav";
import MOFNavigation from "@/views/ce-digital/sub-modules/financial/view/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/ce-digital/sub-modules/financial/view/recruitment-process/financial-recruitment_process.navigation";
import InconsistentTransactionNavigation from "@/views/ce-digital/sub-modules/financial/view/inconsistent-transactions/navigation/inconsistent-transactions.nav.js";

// data
import sidebarItems from "@/views/credit-experts/data/sidebarItems";

const navigation = [
  {
    header: "FINANCIAL",
    module: 23,
    modules: sidebarItems,
  },
  ...dashboardNavigation,
  leadNav,
  clients,
  ...pendingPaymentsNavigation,
  // ...commissionsNav,
  otherPaymentsNavigation,
  ...zeroPaymentNavigation,
  ...paymentsNav,
  chargesMadeNavigation,
  notificationAppNavigation,
  deletedCardsNav,
  AppPaymentsMadeNav,
  HelpDeskNav,
  // GeneralCommissionsNav,
  EmployeeClaimsNavigation,
  ...chargeBackNav,
  RequestNav,
  ...CourtInfoNavigation,
  RingCentralNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  ...InconsistentTransactionNavigation,
];
export default navigation;
