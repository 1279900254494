<template>
  <div class="clientExpenses">
    <b-table
      class="table-new-customization"
      :fields="filterFields"
      :items="dataClient"
      show-empty
      small
      sticky-header="30vh"
    >
      <template #cell(amount)="{ item }">
        <money
          :disabled="loading"
          v-bind="vMoney"
          v-model="item.amount"
          class="form-control form-control-sm"
        ></money>
      </template>
      <template #cell(action)="{ item, index }">
        <feather-icon
          icon="TrashIcon"
          class="cursor-pointer"
          :class="loading ? 'text-secondary' : 'text-danger'"
          size="18"
          v-b-tooltip.hover.right="'Remove'"
          @click="deleteClientExpense(item, index)"
        />
      </template>
    </b-table>
  </div>
</template>
<script>
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";
import VMoney from "v-money";

export default {
  name: "ClientsExpense",
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    clientsExpenses: {
      type: Array,
      default: () => [],
    },
    inDashboard: {
      type: Boolean,
      default: false,
    },
    loadingSearch: {
      type: Boolean,
      default: false,
    },
    programId: {
      type: Number,
      default: () => null,
    },
    typeSearchClient: {
      type: String,
      default: () => "BY_ACCOUNT",
    },
  },
  data() {
    return {
      loading: false,
      dataClient: [],
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      fields: [
        {
          key: "client",
          label: "Client",
          class: "text-left",
          visible: true,
          thClass: "col-6",
        },
        {
          key: "amount",
          label: "Amount",
          class: "text-left",
          visible: true,
          thClass: "col-3 text-center",
          tdClass: "pl-1",
        },
        {
          key: "action",
          label: "Action",
          class: "text-left",
          visible: true,
          thClass: "col-3 text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  directives: { money: VMoney },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    programIdRouter() {
      if (this.programId) return this.programId;
      return this.$route.matched[0].meta.program;
    },
    filterFields() {
      if (this.inDashboard) {
        this.fields.forEach((item) => {
          item.visible = item.key != "action";
        });
      }
      return this.fields.filter((item) => item.visible);
    },
    openFromDashboardCeo() {
      return this.$route.matched[0].name === "ceo-dashboard";
    },
  },
  watch: {
    dataClient: {
      handler(value) {
        const total = this.dataClient.reduce(
          (acc, item) => acc + item.amount,
          0
        );
        this.$emit("update:amount", total);
        this.$emit("update:clientsExpenses", value);
      },
      deep: true,
    },
    loading(value) {
      this.$emit("update:loadingSearch", value);
    },
  },
  created() {},
  methods: {
    async search(search, clientSelected = null) {
      try {
        this.loading = true;
        let data;
        if (this.typeSearchClient == "BY_ACCOUNT" && !clientSelected) {
          if (!this.formatValid(search))
            throw new Error(
              "Please, enter the complete client account without special characters"
            );
          data = await DepartmentExpenses.getClientAccounts({
            search,
            type: this.typeSearchClient,
            program_id: this.programIdRouter,
            is_ceo: this.openFromDashboardCeo,
            typeClient: "CLIENT",
          });
          if (!data.client) throw new Error("Client not found");
        } else {
          data = clientSelected;
        }

        const exist = this.dataClient.find((client) => client.id == data.id);
        if (exist) throw new Error("Client is already selected");
        this.loading = false;
        this.dataClient.push({
          ...data,
          client:
            data.account && data.type_client == "CLIENT"
              ? `${data.account} - ${data.client}`
              : `${data.id} - ${data.client}`,
        });
      } catch (ex) {
        this.loading = false;
        throw ex;
      }
    },
    deleteClientExpense(item, index) {
      if (this.loading) return 0;
      this.dataClient.splice(index, 1);
    },
    formatValid(cadena) {
      // Expresión regular para validar el formato
      const regex = /^[A-Z]{2}\d+$/;

      // Testea la cadena contra la expresión regular
      return regex.test(cadena.trim());
    },
  },
  mounted() {
    this.dataClient = this.clientsExpenses;
    this.$emit("loaded");
    console.log(this.dataClient);
  },
};
</script>
<style>
.clientExpenses .table-new-customization table {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
