export default [
    {
        path: "dashboard",
        name: "bookeeping-dashboard-principal",
        component: () =>
            import(/* webpackChunkName: "BookeepingDashboardMain" */ "@/views/bookeeping/views/dashboard/BkDashboard.vue"),
        meta: {
            pageTitle: "Dashboard",
            breadcrumb: [
                {
                    text: "Dashboard",
                },
            ],
        },
    }
];