import store from '@/store';
import Vue from 'vue';
import moment from 'moment';

const events = {
    SIGNED: 'signed',
    DECLINED: 'declined',
    COMPLETED: 'completed',
    DELIVERED: 'delivered',

}


const captureDocusignEvent = () => {
    window.socket.bind("docusign-event", async ({ content }) => {
        const user_id = store.state.auth.currentUser.user_id;

        if (user_id != content.registered_by) return;

        if (content.action === events.SIGNED) {
            await Vue.prototype.$amgAlert({
                title: 'Document Signed',
                icon: 'Edit3Icon',
                subtitle: `Document ${content.filename} has been signed`,
                data: [
                    {
                        icon: 'UserIcon',
                        title: 'Signed by',
                        description: content.signed_by,
                    },
                    {
                        icon: 'CalendarIcon',
                        title: 'Signed at',
                        description: moment(content.signed_at).format('MM/DD/YYYY HH:ss')

                    },
                ],/*  */
            });
        }

        else if (content.action === events.DECLINED) {
            await Vue.prototype.$amgAlert({
                title: 'Document Declined',
                icon: 'Edit3Icon',
                subtitle: `Document ${content.filename} has been declined`,
                data: [
                    {
                        icon: 'UserIcon',
                        title: 'Declined by',
                        description: content.declined_by,
                    },
                    {
                        icon: 'CalendarIcon',
                        title: 'Declined at',
                        description: moment(content.declined_at).format('MM/DD/YYYY HH:ss')
                    },
                ],
            });
        }
        store.dispatch("DocusignStore/A_REFRESH");
    });
}

const docusignClosePreloader = () => {
    window.socket.bind("docusign-close-preloader-event", async ({ content }) => {
        store.commit("app/SET_LOADING", false);
    });
}

export {
    docusignClosePreloader,
    captureDocusignEvent
}