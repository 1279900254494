import store from '@/store'
import Vue from "vue";

const PerformanceUpdatedNotification = () => {
  window.socket.bind('performance-updated-notifications-debtsolution', async ({ message, to_id }) => {
    const currentSession = store.state.auth.currentUser.user_id
    if (to_id.includes(currentSession)) {
      Vue.swal.fire({
        icon: "success",
        title: 'DebtSolution',
        text: message,
      });
    }
  })
}

export default PerformanceUpdatedNotification