export default [
    {
        key: 'id',
        label: '#',
        visible: true,
        thClass: 'text-center text-white',
        tdClass: 'text-center position-relative p-0',
    },
    {
        key: 'accounts',
        label: 'accounts',
        visible: true,
        thClass: 'text-center text-white',
        tdClass: 'p-0 col-2 text-center',
    },
    {
        key: 'state',
        label: 'tags',
        visible: true,
        thClass: 'text-center',
        tdClass: 'p-0 col-2 text-center position-relative',
    },
    {
        key: 'classification_ncr',
        label: 'type',
        visible: true,
        thClass: 'text-center text-white',
        tdClass: 'p-0 text-center col-2 position-relative',
    },
    {
        key: 'priority',
        label: 'CLASSIFICATION',
        visible: true,
        thClass: 'text-center',
        tdClass: 'p-0 text-center col-1 position-relative'
    },
    {
        key: 'transunion',
        label: 'TU',
        visible: true,
        thClass: 'text-center',
        tdClass: 'text-center',
        tdClass: 'p-0 col-3'
    },
    {
        key: 'experian',
        label: 'EX',
        visible: true,
        thClass: 'text-center',
        tdClass: 'text-center',
        tdClass: 'p-0 col-3'
    },
    {
        key: 'equifax',
        label: 'EQ',
        visible: true,
        thClass: 'text-center',
        tdClass: 'text-center',
        tdClass: 'p-0 col-3'
    },
    {
        key: 'balance',
        label: 'balance',
        visible: false,
        thClass: 'text-center',
        tdClass: 'p-0 text-center',
    },
    {
        key: 'high_credit',
        label: 'high credit',
        visible: false,
        thClass: 'text-center',
        tdClass: 'p-0 text-center'
    },
    {
        key: 'average',
        label: 'average %',
        visible: false,
        thClass: 'text-center',
        tdClass: 'text-center',
        tdClass: 'p-0'
    },
    {
        key: 'detail',
        label: '',
        visible: true,
        thClass: 'text-center',
        tdClass: 'text-center position-relative p-0 position-relative',
    },
]
