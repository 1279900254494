import { amgApi } from "@/service/axios";

class CreditorsService {
  async GET_CREDITORS_PARENTS(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/get-creditors-parents",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error GET_CREDITORS_PARENTS");
    }
  }
  async GET_CREDITORS(params) {
    try {
      const data = await amgApi.post("debt-solution/get-creditors", params);
      return data.data;
    } catch (error) {
      console.log("Error GET_CREDITORS");
    }
  }
  async SAVE_CREDITORS(params) {
    try {
      const data = await amgApi.post("debt-solution/save-creditors", params);
      return data;
    } catch (error) {
      console.log("Error SAVE_CREDITORS");
    }
  }
  async EDIT_CREDITORS(params) {
    try {
      const data = await amgApi.post("debt-solution/edit-creditors", params);
      return data.data;
    } catch (error) {
      console.log("Error EDIT_CREDITORS");
    }
  }
  async DELETE_CREDITORS(params) {
    try {
      const data = await amgApi.post("debt-solution/delete-creditors", params);
      return data.data;
    } catch (error) {
      console.log("Error DELETE_CREDITORS");
    }
  }
  async getListClient(params) {
    try {
      const data = await amgApi.post("debt-solution/get-list-client", params);
      return data.data;
    } catch (error) {
      console.log("Error getListClient");
    }
  }
  async getTimezones() {
    try {
      const data = await amgApi.get("debt-solution/get-timezones");
      return data;
    } catch (error) {
      console.log("Error getTimezones");
    }
  }
  async getSendTypes() {
    try {
      const data = await amgApi.get("debt-solution/get-send-types");
      return data;
    } catch (error) {
      console.log("Error getSendTypes");
    }
  }
  async searchCreditors(text) {
    try {
      const data = await amgApi.post(
        `/sales-made/debt-solution/search-creditors`,
        { q: text }
      );
      return data;
    } catch (error) {
      console.log("Error searchCreditors");
    }
  }
  async saveKeyword(params){
    try {
      const data = await amgApi.post("debt-solution/save-keywords", params);
      return data;
    } catch (error) {
      console.log(error)
    }
  }
  async deleteKeyword(params){
    try {
      const data = await amgApi.post("debt-solution/delete-keywords", params);
      return data;
    } catch (error) {
      console.log(error)
    }
  }
  async getKeywords(params){
    try {
      const data = await amgApi.post("debt-solution/get-keywords", params);
      return data;
    } catch (error) {
      console.log(error)
    }
  }
}
export default new CreditorsService();
