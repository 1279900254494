import store from "@/store";
import chargeBackV2Service from "@/views/commons/components/charge-back_v2/service/charge.back.service.js";
import settlementsMadeService from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service.js";
import SettlementService from "@/views/debt-solution/views/settlements/services/settlement.service.js";
import employeeClaims, { salesMadeCounter } from "@/views/commons/commons-counter";
import SalesMadeService from "@/views/debt-solution/views/sales-made/services/sales-made.service.js";
import ClientService from "@/views/commons/components/clients/services/clients.services.js";
import RequestService from "@/views/commons/components/request/services/request.service.js";
import Notifications from "@/service/notification/index";
import PermissionActionService from "@/views/commons/components/permissions/services/permission.service.js";
import ClientDashboardService from "@/views/paragon/views/clients/dashboard/services/dashboard.service";
import RecruitmentService from "@/views/commons/components/recruitment-process/services/recruiment.service";
import helpDeskService from "../commons/components/helpdesk/services/helpdesk.service";
import SchedulesService from "../commons/components/schedules/services/schedules.service";
import ZeroPaymentService from "./views/zero-payment/zero-payment.service";
import NcrProgramsService from "../commons/components/ncr-programs/service/ncr-programs.service";
import ChargeBackService from "../commons/components/charge-back/charge-back.service";
import PotentialService from "@/views/commons/components/potencial-appointment/services/potencial-appointments.service";
import OtherServicesService from "@/views/commons/components/other-services/service/appointment.service";
import AppointmentService from '@/views/commons/components/appointments/service/appointment.service';
import ApplicationService from '@/views/commons/components/applications/services/applications.service';

const chargeBackCounter = async () => {
  const params = {
    modul: store.state.auth.currentUser.modul_id,
  };
  const data = await ChargeBackService.getCountNotificationsChargeBack(params);
  const payload = {
    routeName: "debtsolution-charge-back",
    tag: data[0].cc,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const ncrProgramsCounter = async () => {
  const params = {
    id: store.state.auth.currentUser.user_id,
    type: store.state.auth.currentUser.role_id <= 2 ? 1 : 2,
    program: 4, // Debt-Solution
    role: store.state.auth.currentUser.role_id,
    modul: store.state.auth.currentUser.modul_id,
  };
  const data = await NcrProgramsService.getCounterNcrPrograns(params);
  if (data.length > 0) {
    const payload = {
      routeName: "debtsolution-ncr-programs",
      tag: data[0].cont,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const zeroPaymentCounter = async () => {
  const params = {
    id_global: store.state.auth.currentUser.user_id,
    id_status: 3,
    id_modul: 4, // Debt-Solution
    id_role: store.state.auth.currentUser.role_id,
  };
  const { data } = await ZeroPaymentService.getZeroPaymentCounter(params);
  if (data.length > 0) {
    const payload = {
      routeName: "zero-payment-debt-solution",
      tag: data.length,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const ncrRealtorCounter = async () => {
  const params = {
    id: store.state.auth.currentUser.user_id,
    type: store.state.auth.currentUser.role_id <= 2 ? 1 : 2,
    program: 4, // Debt-Solution
    role: store.state.auth.currentUser.role_id,
    modul: store.state.auth.currentUser.modul_id,
  };
  const data = await NcrProgramsService.getCounterNcrPrograns(params);
  if (data.length > 0) {
    const payload = {
      routeName: "debt-solution-ncr-realtor",
      tag: data[0].cont,
    };
  }
};
const schedulesCounter = async () => {
  const params = {
    moduleid: 5,
  };
  const data = await SchedulesService.getSchedulesCounter(params);

  if (data) {
    const payload = {
      routeName: "schedules-debtsolution",
      tag: data.grandTotal,
    };
    store.dispatch("SchedulesStore/A_SET_COUNTERS_TABS_SCHEDULES", data);
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const helpDeskCounter = async () => {
  const params = {
    modul_id: 5,
    user_id: store.state.auth.currentUser.user_id,
  };
  const { data } = await helpDeskService.getCounterNotifications(params);
  if (Object.keys(data).length > 0) {
    const payload = {
      routeName: "helpdesk-debt-solution",
      tag: data.total,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const settlementsMadeCounter = async () => {
  const { role_id } = store.state.auth.currentUser;
  const params = {
    user_id: role_id === 3 ? store.state.auth.currentUser.user_id : 0,
  };
  const { notTif, notComplete } =
    await settlementsMadeService.countSettlementsMade(params);

  // the advisor only sees the notifications of the offers that do not have pif
  const count = role_id === 3 ? notTif : notTif + notComplete;
  if (count > 0) {
    const payload = {
      routeName: "debt-solution-settlements-made",
      tag: count,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const settlementCounter = async () => {
  const role = store.state.auth.currentUser.role_id;
  // show only tu supervisor
  if (role == 2 || role == 1 || role == 3) {
    const data = await SettlementService.countSettlements({
      role_id: role,
      user_id: store.state.auth.currentUser.user_id,
    });
    let total = 0;
    // if is advisor or supervisor
    if (role == 2 || role == 3) {
      total = Object.values(data[0]).reduce((a, b) => a + b, 0);
    } else {
      total = data[0].to_be_approved;
    }
    if (data) {
      const payload = {
        routeName: "debtsolution-settlements",
        tag: total,
      };
      store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
    }
  }
};

const chargebackCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;

  const params = {
    module_id: module,
  };

  const { in_process, remove_cb, payment_client, pending, observed } =
    await chargeBackV2Service.getChargebackCounters(params);

  const solution = remove_cb + payment_client;
  const dispute = pending + observed;
  const counterSide = in_process + solution + dispute;

  const payload = {
    routeName: "debt-charge-back",
    tag: counterSide,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "debt-solution-projects",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const salesMadeCounterOld = async () => {
  let counter = 0;
  const params = {
    id_program: 4,
    id_status: 2,
    crm: 0,
    id_rol: 1,
    initial_status: null,
    state_h: null,
    is_done: 1,
    module_id: 2,
  };
  try {
    const { data } = await SalesMadeService.getCounterSalesMadePending(params);
    counter = data;
  } catch (error) {
    throw error;
  }

  const payload = {
    routeName: "debtsolution-sales-made-home",
    tag: counter,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const newClientWelcomedCounter = async () => {
  let counter = 0;
  try {
    const { data } = await ClientService.getDsClientCounterNewAndNotWelcomed();
    if (data.length > 0) {
      counter = data[0].counter;
    }
  } catch (error) {
    throw error;
  }

  const payload = {
    routeName: "debt-s-clients",
    tag: counter,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: "request-debtsolution",
    tag: data[0].counter,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const permissionRequestAction = async () => {
  const params = {
    module_id: store.state.auth.currentUser.modul_id,
    user_id: store.state.auth.currentUser.user_id,
  };
  const { data } = await PermissionActionService.getCounter(params);
  const payload = {
    routeName: "permission-section-debtsolution",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const CourtInfoCounter = async () => {
  const user_id = store.state.auth.currentUser.user_id;
  const role_id = store.state.auth.currentUser.role_id;
  const { data } = await ClientDashboardService.getCountCasesPending({
    role_id: role_id,
    advisor_id: user_id,
  });
  const payload = {
    routeName: "court-info-debtsolution",
    tag:
      role_id == 2 ? data.quantity_s[0].quantity : data.quantity_a[0].quantity,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "debt-solution-recruitment-process",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({
    user_id,
    moduleId,
  });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: "debt-solution-customer-tickets",
      tag: data[0].v_total_open,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const getPendingPotential = async () => {
  const { modul_id } = store.state.auth.currentUser;
  const data = await PotentialService.getCountPending({ module_id: modul_id });
  const payload = {
    routeName: "debt-solution-potencial-appointment",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const getPendingOtherServices = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  const data = await OtherServicesService.getCountPending({
    module_id: modul_id,
    user_id,
    role_id,
  });
  const payload = {
    routeName: "debt-solution-other-services",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};
const getPendingAppointment = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  const { pending } = await AppointmentService.getCountAppointmentsPending({
    module_id: modul_id,
    user_id,
    role_id,
  });
  const payload = {
    routeName: "debt-solution-appointment",
    tag: pending,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};
const getPendingApplications = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  // let specialist_id = [1, 2].includes(role_id) || (role_id === 11 && modul_id === 6) ? null : user_id;
  let advisorId = ([1, 2].includes(role_id) || (role_id === 11 && modul_id === 6) || [11, 25, 28, 29, 30].includes(modul_id)) ? null : user_id;
  const params = {
    user_id,
    role_id,
    program_id: 4,
    specialist_id: null,
    module_id: modul_id,
    advisor_id: advisorId,
  };
  const data = await ApplicationService.getCountPending(params);
  const payload = {
    routeName: "debt-solution-applications",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const allCountersDebtSolution = async () => {
  Promise.all([
    salesMadeCounter('debtsolution-sales-made-home'),
    projectsCounter(),
    ncrProgramsCounter(),
    zeroPaymentCounter(),
    schedulesCounter(),
    helpDeskCounter(),
    ncrRealtorCounter(),
    settlementsMadeCounter(),
    settlementCounter(),
    chargebackCounter(),
    employeeClaims("ds-employee-claims"),
    // salesMadeCounter(),
    newClientWelcomedCounter(),
    requestNotAsignedCounter(),
    permissionRequestAction(),
    CourtInfoCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
    getPendingPotential(),
    getPendingOtherServices(),
    getPendingAppointment(),
    getPendingApplications(),
  ]);
};
export default allCountersDebtSolution;
