<template>
  <div>

    <header-slot />

    <b-row>
      <b-col>

        <b-card
          style="height: 95%;"
          class="p-2 "
          no-body
        >
          <b-overlay
            style="height: 95%;"
            :show="loadingData"
            :variant="skin"
          >
            <h2 class="text-center">
              {{ currentUser.fullName.toUpperCase() }} <br> <span class="font-medium-2">{{ currentUser.role_name.toUpperCase() }} {{ currentUser.level_name =='begginer' ? 'TRAINER':currentUser.level_name.toUpperCase()  }}</span>
            </h2>

            <hr>
            <b-row>
              <b-col class="mb-1 carolina">
                <div
                  class="p-1 rounded text-center carolina-content carolina-back cursor-pointer "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailSales(1)"
                >
                  <h4>Today</h4>
                  <hr>
                  <div>
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.today_count ? paymentsCounter.today_count: 0 }}</span>
                  </div>
                </div>
                <div
                  class="p-1 rounded text-center carolina-content carolina-front "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailSales(1)"
                >
                  <h4>Today</h4>
                  <hr>
                  <div
                    class="cursor-pointer "
                  >
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.today_count ? paymentsCounter.today_count: 0 }}</span>
                  </div>
                </div>
              </b-col>
              <b-col class="mb-1 carolina">
                <div
                  class="p-1 rounded text-center carolina-content carolina-back "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailSales(null)"
                >
                  <h4>Month</h4>
                  <hr>
                  <div
                    class="cursor-pointer "
                  >
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.month_count ? paymentsCounter.month_count: 0 }}</span>
                  </div>
                </div>
                <div
                  class="p-1 rounded text-center carolina-content carolina-front "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailSales(null)"
                >
                  <h4>Month</h4>
                  <hr>
                  <div
                    class="cursor-pointer "
                  >
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.month_count ? paymentsCounter.month_count: 0 }}</span>
                  </div>
                </div>
              </b-col>
              <b-col class="mb-1 carolina">
                <div
                  class="p-1 rounded text-center carolina-content carolina-back "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailLead()"
                >
                  <h4>Leads</h4>
                  <hr>
                  <div
                    class="cursor-pointer "
                  >
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.leads_count ? paymentsCounter.leads_count: 0 }}</span>
                  </div>
                </div>
                <div
                  class="p-1 rounded text-center carolina-content carolina-front "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailLead()"
                >
                  <h4>Leads</h4>
                  <hr>
                  <div
                    class="cursor-pointer "
                  >
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.leads_count ? paymentsCounter.leads_count: 0 }}</span>
                  </div>
                </div>
              </b-col>

            </b-row>
            <b-row>
              <b-col class="mb-1 carolina">
                <div
                  class="p-1 rounded text-center bri-content carolina-back "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                >
                  <h4>Task</h4>
                  <hr>
                  <div
                    class="cursor-pointer "
                  >
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.task_count ? paymentsCounter.task_count: 0 }}</span>
                  </div>
                </div>
                <div
                  class="p-1 rounded text-center bri-content carolina-front "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                >
                  <h4>Task</h4>
                  <hr>
                  <div
                    class="cursor-pointer "
                  >
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.task_count ? paymentsCounter.task_count: 0 }}</span>
                  </div>
                </div>
              </b-col>
              <b-col class="mb-1 carolina">
                <div
                  class="p-1 rounded text-center bri-content carolina-back "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailRealtor()"
                >
                  <h4>Realtor</h4>
                  <hr>
                  <div
                    class="cursor-pointer "
                  >
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.realtor_count ? paymentsCounter.realtor_count: 0 }}</span>
                  </div>
                </div>
                <div
                  class="p-1 rounded text-center bri-content carolina-front "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailRealtor()"
                >
                  <h4>Realtor</h4>
                  <hr>
                  <div
                    class="cursor-pointer "
                  >
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.realtor_count ? paymentsCounter.realtor_count: 0 }}</span>
                  </div>
                </div>
              </b-col>
              <b-col class="mb-1 carolina">
                <div
                  class="p-1 rounded text-center bri-content carolina-back "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailNcr()"
                >
                  <h4>Ncr</h4>
                  <hr>
                  <div
                    class="cursor-pointer "
                  >
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.ncr_count ? paymentsCounter.ncr_count: 0 }}</span>
                  </div>
                </div>
                <div
                  class="p-1 rounded text-center bri-content carolina-front "
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailNcr()"
                >
                  <h4>Ncr</h4>
                  <hr>
                  <div
                    class="cursor-pointer "
                  >
                    <span
                      style="font-size: 2rem"
                    > {{ paymentsCounter.ncr_count ? paymentsCounter.ncr_count: 0 }}</span>
                  </div>
                </div>
              </b-col>

            </b-row>

          </b-overlay>
        </b-card>

      </b-col>
      <b-col md="8">
        <b-row class="mb-2 ">
          <b-col><div class="text-center">

            <b-form-select
              v-model="paymentMonth"
              :options="optionMonthsTemporal"
              @input="getDashboardSellerCounterInfo"
            />

          </div></b-col>
          <b-col><div class="text-center">

            <b-form-select
              v-model="paymentYear"
              :options="optionYears"
              @input="getDashboardSellerCounterInfo"
            />

          </div></b-col>
        </b-row>
        <b-card
          class="p-2"
          no-body
        >
          <b-overlay
            :show="loadingProductivity"
            :variant="skin"
          >
            <b-row>
              <b-col md="11">
                <h2
                  class="text-center "
                  style="padding-left: 100px; "
                >
                  Monthly Productivity
                </h2>
              </b-col>
              <b-col md="1"> <b-form-checkbox
                v-model="changeMonthly"
                switch
              /></b-col>

            </b-row>
            <e-charts
              v-if="changeMonthly"
              :key="monthKey"
              ref="line"
              autoresize
              height="300"
              :options="optionCaro"
              auto-resize
            />
            <!-- probar -->

            <VueApexCharts
              v-else
              :key="monthKeyBar"
              width="100%"
              height="390"
              :options="barDepartmentOpts"
              :series="barDepartmentOpts.series"
            />

          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-4">

      <b-col md="8">
        <b-card

          no-body
        >
          <b-overlay
            :show="loadingAppointment"
            :variant="skin"
            style="height: 100%;"
          >

            <template id="calendar-caro-template">
              <div class="container-caro">

                <div class="single-day-container-caro">
                  <div
                    class="day-container-caro"
                    style="margin-bottom:12px"
                  >
                    <b-row>
                      <b-col>
                        <div class="d-flex justify-content-end">

                          <span
                            :style="isDarkSkin ? 'color: black' : 'color: white'"

                            class=" mr-1"
                          >{{ appointment? 'Appointment': 'Task' }}</span>

                          <b-form-checkbox
                            v-model="appointment"
                            switch
                          />
                        </div>
                      </b-col>
                    </b-row>
                    <div class="day-info">
                      <div class="day-number">
                        {{ selectedDay.number }}
                      </div>
                      <div class="day-name">
                        {{ selectedDay.name }}
                      </div>

                    </div>

                  </div>
                  <div class="event-container-caro ">
                    <ul class="event-list">

                      <li
                        v-for="(dayEvent, index) in dayEvents"
                        :key="`dayEvent-${index}`"
                        class="cursor-pointer"
                        :class="{ 'active': dayEvent.isActive }"
                        @click="openModalEditEventShow(dayEvent)"
                      >
                        <span style="font-size: 17px; font-weight: bolder">{{ dayEvent.title }}</span>
                        <b-row
                          class="w-100"
                          style="font-size: 16px; "
                        >
                          <b-col>
                            <ul>
                              <li>
                                {{ dayEvent.lead_mobile }}
                              </li>
                              <li>
                                <router-link
                                  :class="skin === 'dark' ? 'font-weight-bolder' : 'font-weight-bolder'"
                                  :to="`/cedigital/sales/leads/show/${dayEvent.lead_id}`"
                                  target="_blank"
                                > {{ dayEvent.lead_name }}</router-link>

                              </li>
                              <li v-if="appointment">
                                {{ (dayEvent.date + " " + dayEvent.from) | myHourTime }}
                                {{ (dayEvent.date + " " + dayEvent.to) | myHourTime }}
                              </li>
                              <li v-if="!appointment">
                                {{ (dayEvent.date + " " + dayEvent.hour) | myHourTime }}
                                {{ (dayEvent.date + " " + dayEvent.hour) | myHourTime }}
                              </li>
                              <li>
                                <span class="text-danger"> {{ dayEvent.real_time | myHourTime }} {{ dayEvent.state }}</span>
                              </li>
                              <li v-if="appointment">
                                <span
                                  :class="dayEvent.is_attended === 1? 'text-primary' : 'text-info'"
                                  class="font-weight-bolder"
                                >{{ dayEvent.is_attended === 1? 'Attended' : 'Pending' }}</span>
                              </li>
                            </ul>
                            <hr :style="isDarkSkin? 'background-color: black': 'background-color: white'">
                          </b-col>

                        </b-row>

                      </li>
                    </ul>
                  </div>
                </div>
                <div class="calendar-caro-container-caro">
                  <div class="calendar-caro">
                    <div class="header-caro row-caro flex-middle">
                      <div class="col-caro col-start">
                        <div
                          class="icon"
                          @click="prevMonth"
                        >
                          <feather-icon
                            icon="ArrowLeftIcon"
                            size="20"
                          />
                        </div>
                      </div>
                      <div class="col-caro col-center">
                        <transition
                          tag="div"
                          :name="typeTransition"
                        >
                          <div
                            :key="month"
                            class="month-name"
                          >
                            {{ month }}
                          </div>
                        </transition>
                      </div>
                      <div
                        class="col-caro col-end"
                        @click="nextMonth"
                      >
                        <div class="icon">
                          <feather-icon
                            icon="ArrowRightIcon"
                            size="20"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="days row-caro">
                      <div
                        v-for="(day, index) in dayNames"
                        :key="`h-${index}`"
                        class="col-caro col-center"
                      >
                        <div class="day-name">
                          {{ day }}
                        </div>
                      </div>
                    </div>
                    <div class="body-caro">
                      <div
                        v-for="(row, index) in rows"
                        :key="`r-${index}`"
                        class="row"
                      >

                        <div
                          v-for="(day, index) in row.days"
                          :key="`d-${index}+${another}`"
                          class="col-caro cell-caro  "
                          :style="day.notSelect ? 'pointer-events:none' : ''"

                          :class="[{'has-events': day.hasEvents},{'no-select': day.day==selectDate}]"
                          @click="updateDate(day,day.notSelect)"
                        >

                          <transition-group
                            tag="div"
                            :name="typeTransition"
                          >
                            <div
                              :key="day.formattedDate"
                              class="day-number"
                              :style="day.notSelect ? 'opacity:0.5; pointer-events:none' : ''"
                            >
                              {{ day.formattedDate }}
                            </div>
                          </transition-group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-overlay>
        </b-card>
      </b-col>

      <b-col md="4">

        <b-card
          style="height: 94%;"
          class="p-2 "
          no-body
        >
          <b-overlay
            :show="loadingData"
            :variant="skin"
            style="height: 100%;"
          >
            <h2 class="text-center">
              Commissions
            </h2>

            <hr>
            <sales-commission-component
              :payment-month="paymentMonth"
              :payment-year="paymentYear"
            />

          </b-overlay>
        </b-card>

      </b-col>
    </b-row>

    <b-row />
    <modal-detail-sales
      v-if="modalDetailSalesOn"
      :today="today"
      :date-month="paymentMonth"
      :date-year="paymentYear"
      @close="modalDetailSalesOn = false"
    />
    <modal-detail-lead
      v-if="modalDetailLeadsOn"
      :date-month="paymentMonth"
      :date-year="paymentYear"
      @close="modalDetailLeadsOn = false"
    />
    <modal-detail-ncr
      v-if="modalDetailNcrsOn"
      :date-month="paymentMonth"
      :date-year="paymentYear"
      @close="modalDetailNcrsOn = false"
    />
    <modal-detail-realtor
      v-if="modalDetailRealtorOn"
      :date-month="paymentMonth"
      :date-year="paymentYear"
      @close="modalDetailRealtorOn = false"
    />

    <b-modal
      :id="isModal ? 'modal-event-edit-modal' : 'modal-event-edit'"
      ok-only
      modal-class="modal-primary"
      class="zindex-4"
      centered
      size="lg"
      title="EDIT"
      hide-footer
      no-close-on-backdrop
    >
      <modal-event-edit
        :modul="module"
        :only-read="onlyRead"
        :lead="lead"
        :event="selectedEvent"
        @updated="updatedEvent"
      />
    </b-modal>

  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import ECharts from 'vue-echarts'

import moment from 'moment'
import DashboardSalesServices from '@/views/ce-digital/sub-modules/sales/views/dashboards/dashboard-sales-services'
import SalesCommissionComponent from '@/views/ce-digital/sub-modules/sales/views/dashboards/components/SalesCommissionComponent.vue'
import ModalDetailSales from '@/views/ce-digital/sub-modules/sales/views/dashboards/components/ModalDetailSales.vue'
import ModalDetailLead from '@/views/ce-digital/sub-modules/sales/views/dashboards/components/ModalDetailLead.vue'
import ModalDetailNcr from '@/views/ce-digital/sub-modules/sales/views/dashboards/components/ModalDetailNcr.vue'
import ModalDetailRealtor from '@/views/ce-digital/sub-modules/sales/views/dashboards/components/ModalDetailRealtor.vue'

import TaskCalendar from '@/views/crm/views/calendar/components/TaskCalendar.vue'
import ModalEventEdit from '@/views/crm/views/Lead/lead-event/ModalEventEdit.vue'
import EventService from '@/views/crm/services/event'
import VueApexCharts from 'vue-apexcharts'

export default {

  components: {

    ECharts,
    SalesCommissionComponent,
    ModalDetailSales,
    ModalDetailLead,
    ModalDetailNcr,
    ModalDetailRealtor,

    TaskCalendar,
    ModalEventEdit,

    VueApexCharts,
  },
  filters: {
    getTime(value) {
      const dateFormat = 'h:mm A'
      return moment(value).format(dateFormat)
    },
  },
  props: {
    isModal: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      changeMonthly: false,
      monthKeyBar: 0,
      barDepartmentOpts: {
        chart: { type: 'bar', toolbar: { show: false }, background: '#0000' },
        theme: { mode: 'light' },
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],

        series: [
          {
            name: 'Sales',

            data: [45, 52, 38, 45, 19, 23, 2, 15, 32, 12, 26, 63],
          },
          {
            name: 'Add Service',

            data: [45, 52, 38, 45, 19, 23, 2, 15, 32, 12, 26, 63],
          },
          {
            name: 'Change Service',

            data: [45, 52, 38, 45, 19, 23, 2, 15, 32, 12, 26, 63],
          },
        ],
      },

      onlyRead: false,
      lead: {},
      salesClient: {},
      selectedEvent: {},
      showModalAddService: false,
      programsAll: [],
      servicesUsers: [],
      module: 26,

      modalDetailSalesOn: false,
      modalDetailLeadsOn: false,
      modalDetailNcrsOn: false,
      modalDetailRealtorOn: false,
      today: '',
      another: 0,
      currentMonth: new Date(),
      selectedDate: new Date(),
      events: [
      ],
      transitionDirection: '',
      currentPage: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },

      //
      optionCaro: {

        backgroundColor: 'rgba(0,0,0,0)',
        textStyle: {
          color: '#999999',
        },
        title: {
          textStyle: {
            color: '#666666',
          },
          subtextStyle: {
            color: '#999999',
          },
        },
        color: [
        ],

        tooltip: {
          trigger: 'axis',
        },
        legend: {
          textStyle: {
            color: '#999999',
          },
          data: ['Sales', 'Add Service', 'Change Service'],
        },
        label: {
          normal: {
            textStyle: {
              color: '#349e8e',
            },
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },

        xAxis: {
          color: '#801414',
          categoryAxis: {

            axisLabel: {
              show: true,
              textStyle: {
                color: '#801414',
              },
            },

          },
          type: 'category',
          textStyle: {
            color: '#ca1b1b',
          },

          data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Sales',
            type: 'line',

            data: [],
          },
          {
            name: 'Add Service',
            type: 'line',

            data: [],
          },
          {
            name: 'Change Service',
            type: 'line',

            data: [],
          },

        ],
      },

      monthKey: 0,

      paymentsCounter: {},

      loadingData: true,
      loadingProductivity: true,
      loadingAppointment: true,
      rowss: [],
      selectDate: '',

      paymentMonth: parseInt(moment().format('MM')),
      paymentYear: parseInt(moment().format('YYYY')),
      optionMonths: [
        { text: 'January', value: 1 },
        { text: 'February', value: 2 },
        { text: 'March', value: 3 },
        { text: 'April', value: 4 },
        { text: 'May', value: 5 },
        { text: 'June', value: 6 },
        { text: 'July', value: 7 },
        { text: 'August', value: 8 },
        { text: 'September', value: 9 },
        { text: 'October', value: 10 },
        { text: 'November', value: 11 },
        { text: 'December', value: 12 },
      ],
      optionYears: [],
      appointment: true,
    }
  },

  computed: {
    ...mapGetters({ currentUser: 'auth/currentUser', skin: 'appConfig/skin' }),

    optionMonthsTemporal() {
      return this.paymentYear == moment().format('YYYY') ? this.optionMonths.slice(0, moment().format('MM')) : this.optionMonths
    },

    selectedDay() {
      return {
        number: moment(this.selectedDate).format('D'),
        name: moment(this.selectedDate).format('dddd'),
      }
    },

    month() {
      const dateFormat = 'MMMM YYYY'
      return moment(this.currentMonth).format(dateFormat)
    },

    dayNames() {
      const dateFormat = 'ddd'
      const startDate = moment(this.currentMonth).isoWeekday(1)

      const days = []
      for (let i = 0; i < 7; i += 1) {
        days.push(moment(moment(startDate).add(i, 'day')).format(dateFormat))
      }
      return days
    },
    rows: {
      // getter
      get() {
        return this.rowss
      },
      // setter
      set(newValue) {
        const monthStart = moment(newValue).startOf('month')
        const monthEnd = moment(monthStart).endOf('month')
        const startDate = moment(monthStart).isoWeekday(1)
        const endDate = moment(monthEnd).isoWeekday(7)

        const dateFormat = 'D'

        let days = []
        let day = startDate
        let formattedDate = ''
        let iterar = 0
        // const rowss = []

        while (day <= endDate) {
          for (let i = 0; i < 7; i += 1) {
            formattedDate = moment(day).format(dateFormat)
            const dayInfo = {
              day,
              formattedDate,
              type: '',
              hasEvents: moment().format('MM/DD/YYYY') == day.format('MM/DD/YYYY'),
              notSelect: moment(newValue).format('MM/YYYY') !== day.format('MM/YYYY'),
            }
            days.push(dayInfo)
            day = moment(day).add(1, 'day')
            iterar++
          }
          this.rowss.push({ days })
          days = []
          // eslint-disable-next-line no-plusplus
        }
        this.another++
      },
    },

    dayEvents() {
      return this.findEvents(this.selectedDate, true)
    },

    typeTransition() {
      return `${
        this.transitionDirection === 'next' ? 'slide-left' : 'slide-right'
      }`
    },
  },

  watch: {
    appointment() {
      this.reload()
    },
    skin(val) {
      if (val === 'light') {
        this.optionCaro.color = [
          '#4DD0E1',
          '#00ACC1',
          '#006064',
          '#00695C',
        ]
        this.barDepartmentOpts.theme.mode = 'light'
        this.monthKeyBar++
      } else {
        this.optionCaro.color = [
          '#039BE5',
          '#0288D1',
          '#01579B',
          '#80D8FF',
        ]
        this.barDepartmentOpts.theme.mode = 'dark'
        this.monthKeyBar++
      }
    },

    currentPage(val, oldVal) {
      this.transitionDirection = this.getTransitionDirection(oldVal, val)
    },
  },

  async created() {
    await this.getDashboardSellerCounterInfo()
    await this.getDashboardSellerAppointments()
    await this.getDashboardSellerGraphic()

    await this.getPrograms()

    this.rows = this.currentMonth
    if (this.isDarkSkin) {
      this.optionCaro.color = [
        '#039BE5',
        '#0288D1',
        '#01579B',
        '#80D8FF',
      ]
      this.barDepartmentOpts.theme.mode = 'dark'
    } else {
      this.optionCaro.color = [
        '#4DD0E1',
        '#00ACC1',
        '#006064',
        '#00695C',
      ]
      this.barDepartmentOpts.theme.mode = 'light'
    }
    // day

    this.selectedDate = moment().format('YYYY-MM-DD')
    this.optionYears = []
    // years
    for (let x = 2022; x <= moment().format('YYYY'); x++) {
      this.optionYears.push({ text: x, value: x })
    }
  },
  methods: {
    ...mapActions({

      A_GET_PROGRAMS: 'CrmGlobalStore/A_GET_PROGRAMS',

    }),

    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS()
      } catch (error) {
        console.log('Something went wrong getPrograms:', error)
        this.showToast(
          'danger',
          'top-right',
          'Oop!',
          'AlertOctagonIcon',
          this.getInternalErrors(error),
        )
      }
    },

    resetData(key) {
      this[key] = {
        ...this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`],
      }
    },
    async openModalEditEventShow(event) {
      try {
        if (this.appointment) {
          this.lead = { ...event }
          const { id } = event
          const response = await EventService.getEvent({ id })
          this.resetData('selectedEvent')
          if (this.isResponseSuccess(response)) {
            [this.selectedEvent] = response.data
            this.selectedEvent.user_id = {
              label: this.selectedEvent.seller_name,
              value: this.selectedEvent.seller_id,
            }
            this.$bvModal.show(
              this.isModal ? 'modal-event-edit-modal' : 'modal-event-edit',
            )
          } else {
            this.showToast(
              'warning',
              'top-right',
              'Warning!',
              'AlertTriangleIcon',
              `Something went wrong. ${response.message}`,
            )
          }
        }
      } catch (error) {
        console.log('Something went wrong getEvents', error)
        this.showToast(
          'danger',
          'top-right',
          'Oop!',
          'AlertOctagonIcon',
          this.getInternalErrors(error),
        )
      }
    },
    closeViewMoreEvents() {
      this.viewMoreEventsController = false
    },

    updatedEvent() {
      this.$bvModal.hide(
        this.isModal ? 'modal-event-edit-modal' : 'modal-event-edit',
      )
      this.emitEventDateChange()
    },
    async openModalAddServive(data) {
      await this.getAllPrograms()
      await this.getSellers()
      this.showModalAddService = true
      this.$bvModal.hide('modal-event-edit')
      const sales = {
        id: this.lead.id,
        lead_name: this.lead.lead_name,
        event_id: data,
      }
      this.salesClient = sales
    },

    openModalDetailSales(today) {
      this.today = today
      this.modalDetailSalesOn = true
    },
    openModalDetailLead() {
      this.modalDetailLeadsOn = true
    },
    openModalDetailNcr() {
      this.modalDetailNcrsOn = true
    },
    openModalDetailRealtor() {
      this.modalDetailRealtorOn = true
    },
    async getDashboardSellerAppointments(date) {
      try {
        this.loadingAppointment = true
        const params = {
          user_id: this.currentUser.user_id,
          date: date || moment().format('YYYY-MM-DD'),
        }
        const response = this.appointment ? await DashboardSalesServices.getDashboardSellerAppointments(params) : await DashboardSalesServices.getDashboardSellerTasks(params)

        if (response.status === 200) {
          this.events = JSON.parse(response.data[0].events_month)

          this.loadingAppointment = false
        }
      } catch (e) {
        this.loadingAppointment = false
        console.log(e)
      }
    },
    async getDashboardSellerCounterInfo() {
      await this.getDashboardSellerGraphic()
      try {
        this.loadingData = true
        const params = {
          user_id: this.currentUser.user_id,
          // date: moment(`${this.paymentMonth}-${moment().format('DD')}-${this.paymentYear}`).format('YYYY-MM-DD'),
          date: moment(`${this.paymentYear}-${this.paymentMonth.toString().padStart(2, '0')}-${moment().format('DD')}`).format('YYYY-MM-DD'),
        }
        const responseCounter = await DashboardSalesServices.getDashboardSellerCounter(params)

        if (responseCounter.status === 200) {
          this.paymentsCounter = responseCounter.data[0]
          this.loadingData = false
        }
      } catch (e) {
        this.loadingData = false
        console.log(e)
      }
    },

    async getDashboardSellerGraphic() {
      try {
        this.loadingProductivity = true
        const params = {
          user_id: this.currentUser.user_id,
          // date: moment(`${this.paymentMonth}-${moment().format('DD')}-${this.paymentYear}`).format('YYYY-MM-DD'),
          date: moment(`${this.paymentYear}-${this.paymentMonth.toString().padStart(2, '0')}-${moment().format('DD')}`).format('YYYY-MM-DD'),
        }

        const responseProductivity = await DashboardSalesServices.getDashboardSellerProductivity(params)
        console.log(responseProductivity.status)
        if (responseProductivity.status === 200) {
          this.optionCaro.series[0].data = []
          this.optionCaro.series[1].data = []
          this.optionCaro.series[2].data = []

          this.barDepartmentOpts.series[0].data = []
          this.barDepartmentOpts.series[1].data = []
          this.barDepartmentOpts.series[2].data = []

          JSON.parse(responseProductivity.data[0].month_data_sales).map(item => {
            this.barDepartmentOpts.series[0].data.push(item.value)
            this.optionCaro.series[0].data.push(item.value)
          })
          JSON.parse(responseProductivity.data[0].month_data_service).map(item => {
            this.barDepartmentOpts.series[1].data.push(item.value)
            this.optionCaro.series[1].data.push(item.value)
          })
          JSON.parse(responseProductivity.data[0].month_data_change).map(item => {
            this.barDepartmentOpts.series[2].data.push(item.value)
            this.optionCaro.series[2].data.push(item.value)
          })

          setTimeout(() => {
            this.monthKeyBar++
            this.loadingProductivity = false
          }, 1000)
        }
      } catch (e) {
        this.loadingProductivity = false
        console.log(e)
      }
    },

    nextMonth() {
      this.rowss = []
      this.currentMonth = moment(this.currentMonth).add(1, 'month')
      this.getDashboardSellerAppointments(this.currentMonth)
      this.rows = this.currentMonth

      this.updateCurrentPage(this.currentMonth)
    },

    reload() {
      this.rowss = []
      this.currentMonth = moment(this.currentMonth)
      this.getDashboardSellerAppointments(this.currentMonth)
      this.rows = this.currentMonth
      this.updateCurrentPage(this.currentMonth)
    },
    prevMonth() {
      this.rowss = []
      this.currentMonth = moment(this.currentMonth).subtract(1, 'month')
      this.getDashboardSellerAppointments(this.currentMonth)
      this.rows = this.currentMonth
      this.updateCurrentPage(this.currentMonth)
    },

    updateDate(day, notSelect) {
      this.selectDate = day.day
      if (!notSelect) {
        this.selectedDate = day.day
      }
    },

    updateCurrentPage(date) {
      this.currentPage = {
        month: moment(date).month(),
        year: moment(date).year(),
      }
    },

    findEvents(day) {
      const hasEvents = this.events.reduce((list, ev) => {
        if (moment(day).isSame(moment(ev.date).format('MM/DD/YYYY'))) {
          ev.isActive = ev.allDay
              || moment(
                new Date(),
              ).isBetween(
                new Date(ev.startDate),
                new Date(ev.endDate),
              )
          list.push(ev)
        }
        return list
      }, [])
      return hasEvents
    },

    getTransitionDirection(fromPage, toPage) {
      if (!fromPage || !toPage) return ''
      if (fromPage.year !== toPage.year) return fromPage.year < toPage.year ? 'next' : 'prev'
      if (fromPage.month !== toPage.month) return fromPage.month < toPage.month ? 'next' : 'prev'
      return ''
    },
  },

}
</script>

<style lang="scss" scoped>
@import "@/views/ce-digital/sub-modules/sales/views/dashboards/seller/caro.scss";
.carolina {
  perspective: 1000px;

  position:relative;
}

.carolina-content {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, .3);
  transition: all 1s;

  position:absolute;
  top:22px;
  left:15;
  width:80%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.bri-content {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, .3);
  transition: all 1s;

  position:absolute;
  top:170px;
  left:15;
  width:80%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.carolina-back{
  transform:rotateY(180deg);
}

.carolina:hover .carolina-front {
  transform: rotateY(-180deg);
}

.carolina:hover .carolina-back {
  transform: rotateY(0);
}

</style>
