import DepartmentExpensesService from "@/views/commons/expenses/services/expenses-department.service";

export default {
  namespaced: true,
  state: {
    S_TAB_PRINCIPAL:[],
    S_TOTAL_EXPENSES_CLIENT: 0,
    S_PENDING_EXPENSES_CLIENT: 0,
    S_CONFIRMED_EXPENSES_CLIENT: 0,
    S_PENDING_CHECK_EXPENSES_CLIENT: 0,
    S_CONFIRMED_CHECK_EXPENSES_CLIENT: 0,
    S_ANNULLED_CHECK_EXPENSES_CLIENT: 0,
    S_TOTAL_GENERAL_EXPENSES_CLIENT: 0,
    S_TOTAL_EXPENSES_CLIENT: 0,
    S_TOTAL_CHECK_EXPENSES_CLIENT: 0,
    S_TOTAL_ECHECK_EXPENSES_CLIENT: 0,
    S_PENDING_ECHECK_EXPENSES_CLIENT: 0,
    S_CONFIRMED_ECHECK_EXPENSES_CLIENT: 0,
    S_ANNULLED_ECHECK_EXPENSES_CLIENT: 0,
  },
  mutations: {
    M_TAB_PRINCIPAL(state, value){
      state.S_TAB_PRINCIPAL = value
    },
    M_TOTAL_EXPENSES_CLIENT(state, value) {
      state .S_TOTAL_EXPENSES_CLIENT = value
    },
    M_PENDING_EXPENSES_CLIENT(state, value) {
      state .S_PENDING_EXPENSES_CLIENT = value
    },
    M_CONFIRMED_EXPENSES_CLIENT(state, value) {
      state .S_CONFIRMED_EXPENSES_CLIENT = value
    },
    M_PENDING_CHECK_EXPENSES_CLIENT(state, value) {
      state .S_PENDING_CHECK_EXPENSES_CLIENT = value
    },
    M_CONFIRMED_CHECK_EXPENSES_CLIENT(state, value) {
      state .S_CONFIRMED_CHECK_EXPENSES_CLIENT = value
    },
    // M_ANNULLED_CHECK_EXPENSES_CLIENT(state, value) {
    //   state .S_TOTAL_CHECK_EXPENSES_CLIENT = value
    // },
    M_TOTAL_GENERAL_EXPENSES_CLIENT(state, value) {
      state .S_TOTAL_GENERAL_EXPENSES_CLIENT = value
    },
    M_TOTAL_CHECK_EXPENSES_CLIENT(state, value) {
      state .S_TOTAL_CHECK_EXPENSES_CLIENT = value
    },
    M_TOTAL_ECHECK_EXPENSES_CLIENT(state, value) {
      state .S_TOTAL_ECHECK_EXPENSES_CLIENT = value
    },
    M_PENDING_ECHECK_EXPENSES_CLIENT(state, value) {
      state .S_PENDING_ECHECK_EXPENSES_CLIENT = value
    },
    M_CONFIRMED_ECHECK_EXPENSES_CLIENT(state, value) {
      state .S_CONFIRMED_ECHECK_EXPENSES_CLIENT = value
    },
    M_ANNULLED_ECHECK_EXPENSES_CLIENT(state, value) {
      state .S_ANNULLED_ECHECK_EXPENSES_CLIENT = value
    },
  },
  getters: {
    G_TAB_PRINCIPAL(state){
      return state.S_TAB_PRINCIPAL;
    },
    G_TOTAL_EXPENSES_CLIENT(state) {
      return state.S_TOTAL_EXPENSES_CLIENT
    },
    G_PENDING_EXPENSES_CLIENT(state) {
      return state.S_PENDING_EXPENSES_CLIENT
    },
    G_CONFIRMED_EXPENSES_CLIENT(state) {
      return state.S_CONFIRMED_EXPENSES_CLIENT
    },
    G_PENDING_CHECK_EXPENSES_CLIENT(state) {
      return state.S_PENDING_CHECK_EXPENSES_CLIENT
    },
    G_CONFIRMED_CHECK_EXPENSES_CLIENT(state) {
      return state.S_CONFIRMED_CHECK_EXPENSES_CLIENT
    },
    // G_ANNULLED_CHECK_EXPENSES_CLIENT(state) {
    //   return state.S_ANNULLED_CHECK_EXPENSES_CLIENT
    // },
    G_TOTAL_GENERAL_EXPENSES_CLIENT(state) {
      return state.S_TOTAL_GENERAL_EXPENSES_CLIENT
    },
    G_TOTAL_CHECK_EXPENSES_CLIENT(state) {
      return state.S_TOTAL_CHECK_EXPENSES_CLIENT
    },
    G_PENDING_ECHECK_EXPENSES_CLIENT(state) {
      return state.S_PENDING_ECHECK_EXPENSES_CLIENT
    },
    G_CONFIRMED_ECHECK_EXPENSES_CLIENT(state) {
      return state.S_CONFIRMED_ECHECK_EXPENSES_CLIENT
    },
    G_TOTAL_ECHECK_EXPENSES_CLIENT(state) {
      return state.S_TOTAL_ECHECK_EXPENSES_CLIENT
    },
  },
  actions: {
    A_TAB_PRINCIPAL({commit},value){
      commit("M_TAB_PRINCIPAL",value);
    },
    async A_COUNT_EXPENSES_BY_CLIENT({commit}, value) {
      try {
        const {
         pendings,
         confirmeds,
        } = await DepartmentExpensesService.getCountersExpensesDashboardClient({
          client_account_id: value
        })

        const total = pendings + confirmeds

        commit("M_PENDING_EXPENSES_CLIENT", pendings)
        commit("M_CONFIRMED_EXPENSES_CLIENT", confirmeds)
        commit("M_TOTAL_EXPENSES_CLIENT", total)
      } catch (error) {
        console.error(error)
      }
    },
    async A_COUNT_CHECK_EXPENSES_BY_CLIENT({ commit }, value) {
      try {
        const data = await DepartmentExpensesService.getCountersCheckExpensesDashboardClient({
          client_account_id: value,
        });
        const total = parseInt(data[0].pendings) + parseInt(data[0].confirmeds);
        commit("M_TOTAL_CHECK_EXPENSES_CLIENT", total);
        commit("M_PENDING_CHECK_EXPENSES_CLIENT", data[0].pendings);
        commit("M_CONFIRMED_CHECK_EXPENSES_CLIENT", data[0].confirmeds);
        commit("M_ANNULLED_CHECK_EXPENSES_CLIENT", data[0].annulleds);
      } catch (error) {
        console.error(error);
      }
    },

    async A_COUNT_ECHECK_EXPENSES_BY_CLIENT({ commit }, value) {
      try {
        const data = await DepartmentExpensesService.getCountersECheckExpensesDashboardClient({
          client_account_id: value,
        });
        const total = parseInt(data[0].pendings) + parseInt(data[0].confirmeds);
        commit("M_TOTAL_ECHECK_EXPENSES_CLIENT", total);
        commit("M_PENDING_ECHECK_EXPENSES_CLIENT", data[0].pendings);
        commit("M_CONFIRMED_ECHECK_EXPENSES_CLIENT", data[0].confirmeds);
        commit("M_ANNULLED_ECHECK_EXPENSES_CLIENT", data[0].annulleds);
      } catch (error) {
        console.error(error);
      }
    },

    async A_TOTAL_EXPENSES_BY_CLIENT({ commit }, value) {
      try {
        const data = await DepartmentExpensesService.getTotalExpensesByClientAccount(
          {
            client_account_id: value,
          }
        );
        commit("M_TOTAL_GENERAL_EXPENSES_CLIENT", data[0].total);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
