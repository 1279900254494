import store from '@/store';
import Notifications from '@/service/notification/index';
import ChargeBackService from '@/views/commons/components/charge-back/charge-back.service';
import chargeBackV2Service from '@/views/commons/components/charge-back_v2/service/charge.back.service.js';
import ScheduleService from '@/views/commons/components/schedules/services/schedules.service';
import ZeroPaymentService from '@/views/debt-solution/views/zero-payment/zero-payment.service';
import employeeClaims, { salesMadeCounter } from '@/views/commons/commons-counter';
import RequestService from '@/views/commons/components/request/services/request.service.js';
import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';
import LoansService from '../commons/components/loans/services/loans.service';
import NcrProgramsService from '../commons/components/ncr-programs/service/ncr-programs.service';
import HelpDeskService from '../commons/components/helpdesk/services/helpdesk.service';

const loanCounter = async () => {
  const statusShow = store.state.auth.currentUser.modul_id === 16
    ? 4
    : store.state.auth.currentUser.modul_id === 17
      ? 3
      : store.state.auth.currentUser.role_id === 2
        || store.state.auth.currentUser.role_id === 1
        ? 2
        : 0;

  const params = {
    id_module: store.state.auth.currentUser.modul_id,
    page: 1,
    status: 1,
    type: statusShow,
  };
  const data = await LoansService.getCounterLoan(params);
  const payload = {
    routeName: 'loans-bussiness',
    tag: data[0].counter,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const ncrProgramsCounter = async () => {
  const params = {
    id: store.state.auth.currentUser.user_id,
    type: 1,
    program: 1,
    role: store.state.auth.currentUser.role_id,
    modul: store.state.auth.currentUser.modul_id,
  };
  const data = await NcrProgramsService.getCounterNcrPrograns(params);
  if (data.length > 0) {
    const payload = {
      routeName: 'ncr-business',
      tag: data[0].cont,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};
const chargeBackCounter = async () => {
  const params = {
    modul: 3,
  };
  const data = await ChargeBackService.getCountNotificationsChargeBack(params);

  if (data.length > 0) {
    const payload = {
      routeName: 'charge-back-business',
      tag: data[0].cc,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};
const schedulesCounter = async () => {
  const params = {
    moduleid: 3,
  };
  const data = await ScheduleService.getSchedulesCounter(params);
  if (data) {
    const payload = {
      routeName: 'schedules-bussiness',
      tag: data.grandTotal > 99 ? '99+' : data.grandTotal,
    };
    store.dispatch('SchedulesStore/A_SET_COUNTERS_TABS_SCHEDULES', data);
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};
async function ZeroPaymentCounter() {
  const params = {
    id_global: store.state.auth.currentUser.user_id,
    id_status: 3,
    id_modul: 1,
    id_role: store.state.auth.currentUser.role_id,
  };
  const { data } = await ZeroPaymentService.getZeroPaymentCounter(params);
  if (data.length > 0) {
    const payload = {
      routeName: 'zeropayment-business',
      tag: data.length,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
}

const paragonCounter = async () => {
  const params = {
    modul_id: 3,
    user_id: store.state.auth.currentUser.user_id,
  };
  const { data } = await HelpDeskService.getCounterNotifications(params);
  if (Object.keys(data).length > 0) {
    const payload = {
      routeName: 'helpdesk-bussiness',
      tag: data.total,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const chargebackCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;

  const params = {
    module_id: module,
  };

  const {
    in_process, remove_cb, payment_client, pending, observed,
  } = await chargeBackV2Service.getChargebackCounters(params);

  const solution = remove_cb + payment_client;
  const dispute = pending + observed;
  const counterSide = in_process + solution + dispute;

  const payload = {
    routeName: 'business-charge-back-in-process',
    tag: counterSide,
  };

  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: 'request-bussiness',
    tag: data[0].counter,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'business-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'business-recruitment-process',
      tag: data.data[0].cn,
    };
    console.log(payload);
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({ user_id, moduleId });
  console.log(data)
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: 'business-customer-tickets',
      tag: data[0].v_total_open,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const allCountersBusiness = async () => {
  Promise.all([
    salesMadeCounter('bussiness-sales-made'),
    ZeroPaymentCounter(),
    projectsCounter(),
    paragonCounter(),
    ncrProgramsCounter(),
    schedulesCounter(),
    loanCounter(),
    chargebackCounter(),
    requestNotAsignedCounter(),
    employeeClaims('bussiness-employee-claims'),
    applicantsCounter(),
    getPendinCustomerTickets(),
  ]);
};
export default allCountersBusiness;
