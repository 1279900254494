import SalesMadeService from "@/views/debt-solution/views/sales-made/services/sales-made.service.js"

export default {
    namespaced: true,
    state: {
        S_COUNTER_SALES_MADE_PENDING: 0,
    },
    getters: {
        G_COUNTER_SALES_MADE_PENDING(state) {
            return state.S_COUNTER_SALES_MADE_PENDING
        }
    },
    mutations: {
        M_COUNTER_SALES_MADE_PENDING(state, params) {
            state.S_COUNTER_SALES_MADE_PENDING = params
        }
    },
    actions: {
        A_UPDATE_TABLE_CREDITORS({ commit }, data) {
            commit("M_UPDATE_TABLE_CREDITORS", data)
        },
        async A_GET_STATUS_EEUU({ commit }, params) {
            try {
                const response = await crmService.getStatesEeuu(params);
                commit('M_STATUS_EEUU', response)
            } catch (error) {
                throw error
            }
        },
        async A_GET_COUNTRIES({ commit }, params) {
            try {
                const response = await crmService.getCountries(params);
                commit('M_COUNTRIES', response)
            } catch (error) {
                throw error
            }
        },
        async A_GET_COUNTER_SALES_MADE_PENDING({ commit }, params) {
            try {
                const response = await SalesMadeService.getCounterSalesMadePending(params);
                commit('M_COUNTER_SALES_MADE_PENDING', response.data)
            } catch (error) {
                throw error
            }
        }
    }
};
