export default [
  {
    path: "call-rounds",
    name: "call-rounds-creditexperts",
    component: () => import(/* webpackChunkName: "CreditExpertsCallRounds" */ "@/views/commons/call-rounds/components/sub-tabs/TabGridActives.vue"),
    meta: {
      programId: 3,
      isClientsTab: true,
      pageTitle: "Call Rounds",
      breadcrumb: [
        {
          text: "Actives",
          active: true,
        },
      ],
    },
  },
];
