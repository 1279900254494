import Vue from 'vue'
import store from '@/store/index'

export const ncrNotificationsPusher = () => {
    window.socket.bind('ncr-notifications-connection', async ({message,to_id})=>{
        
        const currentSession = store.state.auth.currentUser.user_id

        if(currentSession == to_id){

            const {isConfirmed, isDismissed} = await Vue.swal.fire({
                icon: "success",
                title: 'NOTICE',
                text: message,
              });

            if(isConfirmed)
                await window.amgApi.post("/commons/close-all-swal", {to_id});


        }




    })
}