import Vue from "vue";
import ChargeBackService from "@/views/commons/components/charge-back/charge-back.service";

const state = {
  S_CHARGE_BACKS: [],
  S_COUNT_CHARGEBACK: 0,
};
const getters = {
  G_CHARGE_BACKS() {
    return state.S_CHARGE_BACKS;
  },
};
const mutations = {
  SET_CHARGE_BACK(state, params) {
    Vue.set(state, params.destination, params.data);
  },
  SET_COUNT_CHARGEBACK(state, payload) {
    state.S_COUNT_CHARGEBACK = payload;
  },
};
const actions = {
  async A_GET_CHARGE_BACKS({ commit }, body) {
    //refresh-search-charge-back
    try {
      const response = await ChargeBackService.chargeBackSearchInProcess(body);
      commit("SET_CHARGE_BACK", {
        destination: "S_CHARGE_BACKS",
        data: response,
      });
      return response;
    } catch (error) {
      console.log("ERROR_GET_CHARGE_BACKS [ACTION]", error);
      throw error;
    }
  },
  async A_GET_CHARGE_BACKS_APPROVED({ commit }, body) {
    //refresh-search-charge-back-approved
    try {
      const response = await ChargeBackService.chargeBackSearchApproved(body);
      return response;
    } catch (error) {
      console.log("ERROR_GET_CHARGE_BACKS [ACTION]", error);
      throw error;
    }
  },
  async A_GET_NOTIFICATIONS_CHARGE_BACKS({ commit }, module) {
    try {
      //count-charge-back
      const response = await ChargeBackService.getCountNotificationsChargeBack({
        modul: module === 16 || module === 4 ? null : module,
      });
      let cont = 0;
      if (response.status === 200) {
        cont = response.data[0].cc;
      }
      commit("SET_COUNT_CHARGEBACK", cont);
      return cont;
    } catch (error) {
      console.log("ERROR_GET_NOTIFICATIONS_CHARGE_BACKS [ACTION]", error);
      throw error;
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
