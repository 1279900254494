<template>
  <div>
    <b-modal
      ref="modal-load-credit-report"
      :title="subModule != null ? 'REQUEST CR' : 'LOAD CREDIT REPORT'"
      modal-class="modal-primary"
      title-tag="h3"
      size="xmd"
      scrollable
      @hidden="hideModal"
    >
      <validation-observer ref="refFormCreditReport">
        <div>
          <b-row class="mb-1 mt-2">
            <!-- ACCOUNT || LEAD -->
            <b-col lg="6" class="mb-1">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text
                    class="title_lable d-flex justify-content-center"
                    style="width: 105px"
                    >{{ subModule != null ? "LEAD" : "ACCOUNT" }}
                  </b-input-group-text>
                </template>

                <span
                  style="'border-color:rgba(255, 255, 255, 0.4)'; "
                  :style="
                    isDarkSkin
                      ? 'background: #17171A;opacity: 0.3;color:white'
                      : 'background: #efefef'
                  "
                  class="form-control"
                  >{{
                    subModule != null
                      ? form.lead_name
                      : dataCreditReport.account
                  }}</span
                >
              </b-input-group>
            </b-col>

            <!-- DOB -->
            <b-col lg="6" class="mb-1" v-if="subModule != null">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text
                    class="title_lable d-flex justify-content-center"
                    style="width: 105px"
                    >DOB
                  </b-input-group-text>
                </template>

                <span
                  style="'border-color:rgba(255, 255, 255, 0.4)'; "
                  :style="
                    isDarkSkin
                      ? 'background: #17171A;opacity: 0.3;color:white'
                      : 'background: #efefef'
                  "
                  class="form-control"
                  >{{ form.dob | myGlobal }}</span
                >
              </b-input-group>
            </b-col>

            <!-- NAME CLIENT || SELLER -->
            <b-col lg="6" class="mb-1">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text
                    class="title_label d-flex justify-content-center"
                    style="width: 105px"
                    >{{ subModule != null ? "SELLER" : "CLIENT" }}
                  </b-input-group-text>
                </template>

                <span
                  style="'border-color:rgba(255, 255, 255, 0.4)'; "
                  :style="
                    isDarkSkin
                      ? 'background: #17171A;opacity: 0.3;color:white'
                      : 'background: #efefef'
                  "
                  class="form-control"
                >
                  {{
                    subModule != null
                      ? form.seller_name
                      : dataCreditReport.client_name
                  }}</span
                >
              </b-input-group>
            </b-col>

            <!-- MOBILE -->
            <b-col lg="6" class="mb-1" v-if="subModule != null">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text
                    class="title_lable d-flex justify-content-center"
                    style="width: 105px"
                    >MOBILE
                  </b-input-group-text>
                </template>

                <span
                  style="'border-color:rgba(255, 255, 255, 0.4)'; "
                  :style="
                    isDarkSkin
                      ? 'background: #17171A;opacity: 0.3;color:white'
                      : 'background: #efefef'
                  "
                  class="form-control"
                  >{{ form.mobile }}</span
                >
              </b-input-group>
            </b-col>

            <!-- ITIN -->
            <b-col lg="6" class="mb-1" v-if="subModule != null">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text
                    class="title_lable d-flex justify-content-center"
                    style="width: 105px"
                    >{{ form.document_name }}
                  </b-input-group-text>
                </template>

                <span
                  style="'border-color:rgba(255, 255, 255, 0.4)'; "
                  :style="
                    isDarkSkin
                      ? 'background: #17171A;color:white; border-color:rgba(255, 255, 255, 0.4)'
                      : 'background: #efefef'
                  "
                  class="form-control d-flex justify-content-between"
                >
                  {{ getDocument(form.document) }}
                  <div>
                    <feather-icon
                      v-if="!show.document"
                      icon="EyeIcon"
                      class="ml-2 pointer text-success"
                      @click="viewDocument"
                    />
                    <feather-icon
                      v-else
                      icon="EyeOffIcon"
                      class="ml-2 pointer text-danger"
                      @click="viewDocument"
                    />
                  </div>
                </span>
              </b-input-group>
            </b-col>

            <!-- BUREAU || SOURCE -->
            <b-col lg="6" class="mb-1" v-if="subModule != null">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text
                    class="title_lable d-flex justify-content-center"
                    style="width: 105px"
                    >{{
                      form.document_id == 1 || form.document_id == 3
                        ? "SOURCE"
                        : "BUREAU"
                    }}
                  </b-input-group-text>
                </template>
                <div
                  style="'border-color:rgba(255, 255, 255, 0.4)'; "
                  :style="
                    isDarkSkin
                      ? 'background: #17171A;color:white; border-color:rgba(255, 255, 255, 0.4)'
                      : 'background: #efefef'
                  "
                  class="form-control"
                >
                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                    v-if="form.document_id == 1 || form.document_id == 3"
                  >
                    <div class="d-flex">
                      <b-form-radio
                        v-if="s1 != 1"
                        v-model="source"
                        value="1"
                        :aria-describedby="ariaDescribedby"
                        class="mr-1"
                        :disabled="isDisabled"
                        >&nbsp;
                        <img
                          :src="baseUrl + '/images/icons/iq.png'"
                          title="IdentityIQ"
                        />
                      </b-form-radio>

                      <b-form-radio
                        v-if="s2 != 2"
                        v-model="source"
                        value="2"
                        :aria-describedby="ariaDescribedby"
                        class="mr-1"
                        :disabled="isDisabled"
                        >&nbsp;
                        <img
                          :src="baseUrl + '/images/icons/mf.png'"
                          title="MyFree"
                        />
                      </b-form-radio>

                      <b-form-radio
                        v-if="s3 != 3"
                        v-model="source"
                        value="3"
                        :aria-describedby="ariaDescribedby"
                        class="mr-1"
                        :disabled="isDisabled"
                        >&nbsp;
                        <img
                          :src="baseUrl + '/images/icons/pg.png'"
                          title="PrivacyGuard"
                        />
                      </b-form-radio>

                      <b-form-radio
                        v-if="s4 != 7"
                        v-model="source"
                        value="7"
                        :aria-describedby="ariaDescribedby"
                        class="mr-1"
                        :disabled="isDisabled"
                      >
                        &nbsp;
                        <img
                          :src="baseUrl + '/images/icons/siq.png'"
                          title="MyScoreIQ"
                        />
                      </b-form-radio>
                    </div>
                  </b-form-group>
                  <span
                    v-if="
                      errorBureau &&
                      (form.document_id == 1 || form.document_id == 3)
                    "
                    class="text-danger"
                  >
                    Bureau is required</span
                  >

                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                    v-if="form.document_id == 2"
                  >
                    <div class="d-flex">
                      <b-form-radio
                        v-if="s1 != 4"
                        v-model="source"
                        value="4"
                        :aria-describedby="ariaDescribedby"
                        class="mr-1"
                        :disabled="isDisabled"
                        >&nbsp;
                        <img
                          :src="baseUrl + '/images/icons/transunion.png'"
                          title="Transunion"
                        />
                      </b-form-radio>

                      <b-form-radio
                        v-if="s2 != 5"
                        v-model="source"
                        value="5"
                        :aria-describedby="ariaDescribedby"
                        class="mr-1"
                        :disabled="isDisabled"
                        >&nbsp;
                        <img
                          :src="baseUrl + '/images/icons/experian.png'"
                          title="Experian"
                        />
                      </b-form-radio>

                      <b-form-radio
                        v-if="s3 != 6"
                        v-model="source"
                        value="6"
                        :aria-describedby="ariaDescribedby"
                        class="mr-1"
                        :disabled="isDisabled"
                        >&nbsp;
                        <img
                          :src="baseUrl + '/images/icons/equifax.png'"
                          title="Equifax"
                        />
                      </b-form-radio>
                    </div>
                  </b-form-group>
                  <span
                    v-if="errorBureau && form.document_id == 2"
                    class="text-danger mt-2"
                  >
                    Bureau is required</span
                  >
                </div>
              </b-input-group>
            </b-col>

            <!-- ADDRESS -->
            <b-col sm="12" v-if="subModule != null">
              <b-form-group label="Address" label-class="font-weight-bolder">
                <b-form-input v-model="form.street" :disabled="true" />
              </b-form-group>
            </b-col>

            <!-- CITY -->
            <b-col sm="6" v-if="subModule != null">
              <b-form-group label="City" label-class="font-weight-bolder">
                <b-form-input v-model="form.city" :disabled="true" />
              </b-form-group>
            </b-col>

            <!-- STATE -->
            <b-col sm="6" v-if="subModule != null">
              <b-form-group label="State" label-class="font-weight-bolder">
                <b-form-input v-model="form.states" :disabled="true" />
              </b-form-group>
            </b-col>

            <!-- ZIP CODE -->
            <b-col sm="6" v-if="subModule != null">
              <b-form-group label="Zip Code" label-class="font-weight-bolder">
                <b-form-input v-model="form.zipcode" :disabled="true" />
              </b-form-group>
            </b-col>

            <!-- COUNTRY -->
            <b-col sm="6" v-if="subModule != null">
              <b-form-group label="Country" label-class="font-weight-bolder">
                <b-form-input v-model="form.country" :disabled="true" />
              </b-form-group>
            </b-col>

            <!-- Provider -->
            <b-col sm="12" v-if="subModule == null">
              <validation-provider
                v-slot="{ errors }"
                name="Provider"
                rules="required"
              >
                <b-form-group
                  label="Provider"
                  label-class="font-weight-bolder"
                  :state="errors[0] ? false : null"
                >
                  <v-select
                    v-model="form.platform"
                    :clearable="false"
                    label="name"
                    :reduce="(value) => value.id"
                    :options="platforms"
                  />
                  <span v-if="errors[0]" class="text-danger">
                    Provider {{ errors[0] }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Credit Card -->
            <b-col sm="9" class="mb-1" v-if="subModule != null">
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    label="Credit Card:"
                    v-slot="{ ariaDescribedby }"
                    label-class="font-weight-bolder"
                  >
                    <b-form-radio-group
                      v-model="attemp.type_card"
                      :options="listCrediCard"
                      :aria-describedby="ariaDescribedby"
                      @change="typeCard"
                    />
                  </b-form-group>
                </b-col>
                <b-col v-if="attemp.type_card == 2">
                  <b-form-group
                    v-slot="{ ariaDescribedby }"
                    label-class="font-weight-bolder"
                  >
                    <b-form-radio-group
                      v-model="attemp.bc"
                      :options="listtypeCard"
                      :aria-describedby="ariaDescribedby"
                      @change="typeCard"
                    />
                  </b-form-group>
                </b-col>

                <!-- Table Credit Card of Clients -->
                <b-col
                  sm="12"
                  v-if="attemp.type_card != '' || attemp.type_card != null"
                >
                  <b-table
                    sticky-header
                    :items="cards"
                    :fields="arrayColumnsTable"
                    :class="{ 'border-danger': errorTable }"
                    responsive="sm"
                  >
                    <template #cell(radio)="data">
                      <validation-provider
                        v-slot="{ errors }"
                        name="id_card"
                        rules="required"
                        ref="modalRcrValidaTableCreditCard"
                      >
                        <b-form-radio
                          v-model="attemp.id_card"
                          :value="data.item.id"
                          :disabled="
                            data.item.id == card1 || data.item.id == card2
                              ? true
                              : false
                          "
                          plain
                          @input="validOptionTable"
                        />
                        <span style="display: none">{{ errors[0] }}</span>
                      </validation-provider>
                    </template>
                    <template #cell(cardholdername)="data">{{
                      data.item.cardholdername
                    }}</template>
                    <template #cell(cardnumber)="data">{{
                      "XXXX-XXXX-XXXX-" + data.item.cardnumber
                    }}</template>
                    <template #cell(type_card)="data">{{
                      data.item.type_card
                    }}</template>
                    <template #cell(card_expi_month)="data">{{
                      data.item.card_expi_month
                    }}</template>
                    <template #cell(card_expi_year)="data">{{
                      data.item.card_expi_year
                    }}</template>
                    <template #cell(cardsecuritycode)="data">{{
                      "XX" + data.item.cardsecuritycode
                    }}</template>
                    <template #cell(Actions)="data">
                      <a
                        @click="openModalCard(data.item.id)"
                        style="cursor: pointer"
                      >
                        <feather-icon
                          icon="EyeIcon"
                          class="ml-2 pointer text-success"
                        />
                      </a>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>

            <!-- SEND CR -->
            <b-col sm="3" class="mb-1" v-if="subModule != null">
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    label="Send CR:"
                    v-slot="{ ariaDescribedby }"
                    label-class="font-weight-bolder"
                  >
                    <b-form-radio-group
                      v-model="form.send_cr"
                      :options="listSendCR"
                      :aria-describedby="ariaDescribedby"
                      disabled="disabled"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <label for="">
                    <strong>Language | {{ form.language }}</strong>
                  </label>
                </b-col>
              </b-row>
            </b-col>

            <!-- Seller Note -->
            <b-col
              sm="12"
              v-if="attemp.note_seller != null && attemp.note_seller != ''"
            >
              <b-form-group label="Seller Note">
                <b-form-textarea
                  v-model="attemp.note_seller"
                  rows="3"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <!-- RESULT -->
            <b-col sm="12" v-if="subModule != null">
              <validation-provider
                v-slot="{ errors }"
                name="Provider"
                rules="required"
              >
                <b-form-group label="Result" label-class="font-weight-bolder">
                  <v-select
                    v-model="attemp.status"
                    label="text"
                    :options="optionsResult"
                    :disabled="isDisabled"
                    :class="errors[0] ? 'border-danger' : ''"
                  />
                  <span v-if="errors[0]" class="text-danger">
                    Provider {{ errors[0] }}</span
                  >
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Add notes (Optional) -->
            <b-col
              sm="12"
              v-if="
                attemp.status != null &&
                attemp.status.value != 2 &&
                subModule != null
              "
            >
              <b-form-group label="Add notes(Optional)">
                <b-form-textarea v-model="attemp.note" rows="3" max-rows="6">
                </b-form-textarea>
              </b-form-group>
            </b-col>

            <!-- File pdf -->
            <b-col
              sm="6"
              v-if="
                (subModule != null &&
                  attemp.status != null &&
                  attemp.status.value == 2) ||
                subModule == null
              "
            >
              <validation-provider
                v-slot="{ errors }"
                name="file-pdf"
                rules="required"
              >
                <div class="d-flex align-items-center">
                  <a
                    :href="route_pdf"
                    class="mr-1"
                    v-if="route_pdf"
                    target="_black"
                  >
                    <img
                      :src="baseUrl + '/images/icons/pdf.png'"
                      style="width: 20px"
                    />
                  </a>
                  <b-form-group
                    label="Select Pdf"
                    class="w-100"
                    :state="errors[0] ? false : null"
                  >
                    <b-form-file
                      v-model="fileToUploadPdf"
                      :class="errors[0] ? 'border-danger' : ''"
                      @input="fileFill('pdf')"
                    />

                    <span v-if="errors[0]" class="text-danger">
                      File {{ errors[0] }}</span
                    >
                  </b-form-group>
                </div>
              </validation-provider>
            </b-col>

            <!-- File Html -->
            <b-col
              sm="6"
              v-if="
                (subModule != null &&
                  attemp.status != null &&
                  attemp.status.value == 2 &&
                  form.document_id == 1) ||
                (subModule != null &&
                  attemp.status != null &&
                  attemp.status.value == 2 &&
                  form.document_id == 3) ||
                subModule == null
              "
            >
              <validation-provider
                v-slot="{ errors }"
                name="file-html"
                rules="required"
              >
                <div class="d-flex align-items-center">
                  <a
                    :href="route_html"
                    class="mr-1 text-success"
                    v-if="route_html"
                    target="_black"
                  >
                    <feather-icon icon="FileTextIcon" size="20" />
                  </a>
                  <b-form-group
                    label="Select Html"
                    class="w-100"
                    :state="errors[0] ? false : null"
                  >
                    <b-form-file
                      v-model="fileToUploadHtml"
                      @input="fileFill('html')"
                      :class="errors[0] ? 'border-danger' : ''"
                    />
                    <span v-if="errors[0]" class="text-danger">
                      File {{ errors[0] }}</span
                    >
                  </b-form-group>
                </div>
              </validation-provider>
            </b-col>
            <b-col v-else sm="6"></b-col>

            <!-- Date -->
            <b-col
              sm="3"
              v-if="
                (subModule == 'new' &&
                  attemp.status != null &&
                  attemp.status.value == 2) ||
                (subModule == 'reUpload' && form.document_id == 2)
              "
            >
              <b-form-group label="Date">
                <b-form-datepicker
                  id="example-datepicker"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en-US"
                  class="mb-2"
                  :format="'MM/dd/yyyy'"
                  v-model="attemp.report_date"
                />
              </b-form-group>
            </b-col>

            <!-- TransUnion -->
            <b-col
              sm="3"
              v-if="
                (subModule == 'new' &&
                  attemp.status != null &&
                  attemp.status.value == 2) ||
                (subModule == 'reUpload' && form.document_id == 2)
              "
            >
              <b-form-group label="TransUnion">
                <b-form-input
                  v-model="attemp.tra"
                  maxlength="3"
                  rows="3"
                  max-rows="6"
                  :disabled="subModule == 'attend'"
                />
              </b-form-group>
            </b-col>

            <!-- Experian -->
            <b-col
              sm="3"
              v-if="
                (subModule == 'new' &&
                  attemp.status != null &&
                  attemp.status.value == 2) ||
                (subModule == 'reUpload' && form.document_id == 2)
              "
            >
              <b-form-group label="Experian">
                <b-form-input
                  v-model="attemp.exp"
                  maxlength="3"
                  :disabled="subModule == 'attend'"
                />
              </b-form-group>
            </b-col>

            <!-- Equifax -->
            <b-col
              sm="3"
              v-if="
                (subModule == 'new' &&
                  attemp.status != null &&
                  attemp.status.value == 2) ||
                (subModule == 'reUpload' && form.document_id == 2)
              "
            >
              <b-form-group label="Equifax">
                <b-form-input
                  v-model="attemp.equ"
                  maxlength="3"
                  :disabled="subModule == 'attend'"
                />
              </b-form-group>
            </b-col>

            <!-- DATE -->
            <b-col
              sm="3"
              v-if="subModule == 'reUpload' && form.document_id == 2"
            >
              <b-form-group label="Date">
                <b-form-datepicker
                  id="example-datepicker"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  locale="en-US"
                  class="mb-2"
                  :format="'MM/dd/yyyy'"
                  v-model="attemp.report_date"
                />
              </b-form-group>
            </b-col>

            <!-- TransUnion -->
            <b-col
              sm="3"
              v-if="subModule == 'reUpload' && form.document_id == 2"
            >
              <b-form-group label="TransUnion">
                <b-form-input v-model="attemp.tra" max-rows="6" />
              </b-form-group>
            </b-col>

            <!-- Experian -->
            <b-col
              sm="3"
              v-if="subModule == 'reUpload' && form.document_id == 2"
            >
              <b-form-group label="Experian">
                <b-form-input v-model="attemp.exp" maxlength="3" />
              </b-form-group>
            </b-col>

            <!-- Equifax -->
            <b-col
              sm="3"
              v-if="subModule == 'reUpload' && form.document_id == 2"
            >
              <b-form-group label="Equifax">
                <b-form-input v-model="attemp.equ" maxlength="3" />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </validation-observer>

      <ModalViewCard
        v-if="modalCard"
        :type="1"
        :card="dataCard"
        :isEditable="false"
        @onClose="modalCard = false"
      />

      <template #modal-footer>
        <b-button v-if="subModule == null" variant="danger" @click="hideModal">
          Cancelar
        </b-button>

        <b-button v-if="subModule == null" variant="success" @click="saveFile">
          Save
        </b-button>
        <b-button
          v-if="subModule == 'new'"
          :variant="subModule != null ? 'warning' : 'success'"
          @click="saveRequest"
        >
          {{ subModule != null ? "Process" : "Save" }}
        </b-button>

        <b-button
          v-if="subModule == 'reUpload' || subModule == 'attend'"
          variant="warning"
          @click="saveRequestUpload"
        >
          Process
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* SERVICES */
import NcrProgramsService from "@/views/administration/views/ncr-programs/services/NcrPrograms.service.js";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";

/* DATA */
import CreditReportData from "@/views/administration/views/ncr-programs/components/data/creditReport.data.js";

/* MODALS */
import ModalViewCard from "@/views/commons/components/credit-cards/ModalViewCard.vue";

import { mapGetters } from "vuex";

/* COMPONENTS */
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
    ModalViewCard,
  },
  props: {
    dataCreditReport: {
      type: Object,
    },
    subModule: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      platforms: [],
      provider: "",
      fileToUploadPdf: [],
      fileToUploadHtml: [],
      listCrediCard: CreditReportData.listCrediCard,
      listtypeCard: CreditReportData.listtypeCard,
      listSendCR: CreditReportData.listSendCR,
      optionsResult: CreditReportData.optionsResult,
      arrayColumnsTable: CreditReportData.arrayColumnsTable,
      show: {
        document: false,
      },
      cards: [],
      form: {
        lead_name: "",
        account: this.dataCreditReport.id_account,
        id: "",
        platform: "",
        date: this.dataCreditReport.created_at,
        dateonline: "",
        usernameonline: "",
        passwordonline: "",
        membernumberonline: "",
        document_id: "",
        pdf: {
          pdf: "",
          name: "",
          size: "",
        },
        html: {
          html: "",
          name: "",
          size: "",
        },
        user_id: "",
        idncr: this.dataCreditReport.nc_id,
        requestBy: this.dataCreditReport.request_by,
        requestFrom: this.dataCreditReport.request_from,
        send_cr: "",
      },
      attemp: {
        lead_id: "",
        type_card: "",
        id_card: "",
        bc: "",
        request_id: "",
        lead_id: "",
        status: null,
        note: "",
        tra: "",
        exp: "",
        equ: "",
        report_date: "",
        note_seller: "",
      },
      modalCard: false,
      dataCard: null,
      s1: 0,
      s2: 0,
      s3: 0,
      s4: 0,
      card1: "",
      card2: "",
      source: "",
      errorTable: "", //Catch the Of Client table error
      errorBureau: "",
      route_pdf: "",
      route_html: "",
    };
  },
  created() {
    if (this.subModule != null) {
      switch (this.subModule) {
        case "new":
          this.getLeadData();
          break;
        case "reUpload":
          this.getLeadDataUpload();
          break;
        case "attend":
          this.getLeadDataUpload();
          break;
        case "successful":
          this.getLeadDataUpload();
          break;
        default:
          break;
      }
    } else {
      this.platformAll();
    }
  },
  mounted() {
    this.toggleModal("modal-load-credit-report");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    isDisabled() {
      switch (this.subModule) {
        case "new":
          return false;
          break;
        case "reUpload":
          return true;
          break;
        case "successful":
          return true;
          break;
        default:
          break;
      }
    },

    getTab() {
      return this.$route.matched[2].meta.tab;
    },
    getSubTab() {
      return this.$route.meta.tab;
    },
  },

  watch: {
    async source(newValue) {
      this.errorBureau = false;
    },
  },

  methods: {
    hideModal(action = false) {
      this.$emit("hideModal", action);
    },

    // Get credit reporting platforms
    async platformAll() {
      this.addPreloader();
      try {
        const { data } = await NcrProgramsService.getPlataform({ type: 1 });
        this.platforms = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    async fileFill(file) {
      if (file == "pdf") {
        this.form.pdf.name = this.fileToUploadPdf.name;
        this.form.pdf.size = this.fileToUploadPdf.size;
        const reader = new FileReader();
        reader.readAsDataURL(this.fileToUploadPdf);
        reader.onload = async (file) => {
          this.form.pdf.pdf = file.target.result;
        };
      } else {
        this.form.html.name = this.fileToUploadHtml.name;
        this.form.html.size = this.fileToUploadHtml.size;
        const reader = new FileReader();
        reader.readAsDataURL(this.fileToUploadHtml);
        reader.onload = async (file) => {
          this.form.html.html = file.target.result;
        };
      }
    },

    async saveRequestUpload() {
      const result = await this.$refs.refFormCreditReport.validate();

      try {
        if (result && !this.errorBureau && !this.errorTable) {
          this.addPreloader();
          const params = {
            city: this.form.city,
            country: this.form.country,
            dob: this.form.dob,
            document: this.form.document,
            document_id: this.form.document_id,
            document_name: this.form.document_name,
            equ: this.attemp.equ,
            exp: this.attemp.exp,
            html: this.form.html,
            pdf: this.form.pdf,
            id_card: this.attemp.id_card,
            language: this.form.language,
            lead_id: this.attemp.lead_id,
            lead_name: this.form.lead_name,
            mobile: this.form.mobile,
            note_seller: this.attemp.note_seller,
            report_date: this.attemp.report_date,
            request_id: this.attemp.request_id,
            score_id: this.dataCreditReport.score_id,
            seller_name: this.form.seller_name,
            send_cr: this.form.send_cr,
            source: this.source,
            states: this.form.states,
            status: this.attemp.status,
            street: this.form.street,
            tp: this.attemp.bc,
            tra: this.attemp.tra,
            type_card: this.attemp.type_card,
            user_id: this.currentUser.user_id,
            zipcode: this.form.zipcode,
          };

          const { data } = await NcrProgramsService.ncrLeadsProcessRequest(
            params
          );
          this.showSuccessSwal();
          this.hideModal(true);
        } else {
          this.validOptionTable();
        }
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async saveRequest() {
      const result = await this.$refs.refFormCreditReport.validate();
      try {
        if (result && !this.errorBureau && !this.errorTable) {
          this.addPreloader();
          const params = {
            bc: this.attemp.bc,
            equ: this.attemp.equ,
            exp: this.attemp.exp,
            html: this.form.html,
            id_card: this.attemp.id_card,
            lead_id: this.attemp.lead_id,
            note: this.attemp.note,
            pdf: this.form.pdf,
            report_date: this.attemp.report_date,
            request_id: this.attemp.request_id,
            score_id: this.dataCreditReport.score_id,
            source: this.source,
            status: this.attemp.status,
            tra: this.attemp.tra,
            type_card: this.form.type_card,
            user_id: this.currentUser.user_id,
          };

          const { data } = await NcrProgramsService.ncrLeadsProcessNewRequest(
            params
          );
          if (data.type == "error") {
            this.showErrorSwal(data.msg);
          } else {
            this.showSuccessSwal();
            this.hideModal(true);
          }
        } else {
          this.validOptionTable();
        }
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async saveFile() {
      const result = await this.$refs.refFormCreditReport.validate();
      try {
        if (result) {
          this.form.user_id = this.currentUser.user_id;
          if (
            this.form.usernameonline == "" &&
            this.form.passwordonline == "" &&
            this.form.membernumberonline == ""
          ) {
            this.form.usernameonline = "null";
            this.form.passwordonline = "null";
            this.form.membernumberonline = "null";
          }

          this.addPreloader();
          const { data } = await NcrProgramsService.saveReportClient(this.form);
          if (data.type == "success") {
            this.showSuccessSwal();
            this.$emit("onLeadCreditReport", this.dataCreditReport, {
              route_pdf: data.data.route_pdf,
              score_id: data.data.score_id,
            });
            this.hideModal();
          } else {
            this.showErrorSwal(data.msg);
          }
        }
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    // http://localhost:8080/administration/ncr-leads/in-process/pending
    async getLeadDataUpload() {
      this.addPreloader();
      try {
        const { data } = await NcrLeadsService.ncrLeadsGetRequest({
          score_id: this.dataCreditReport.score_id,
        });
        this.form.lead_name = data[0].lead_name;
        this.form.mobile = data[0].mobile;
        this.form.document = data[0].document == null ? "" : data[0].document;
        this.form.document_name = data[0].document_name;
        this.form.document_id = data[0].document_id;
        this.form.street = data[0].street;
        this.form.city = data[0].city;
        this.form.states = data[0].states;
        this.form.country = data[0].country;
        this.form.zipcode = data[0].zipcode;
        this.form.language = data[0].language;
        this.form.type_card = data[0].type_card;
        this.attemp.id_card = data[0].id_card;
        this.form.send_cr = data[0].send_cr;
        this.attemp.note = data[0].note;
        this.form.seller_name = data[0].seller_name;
        this.attemp.bc = data[0].tp;

        this.attemp.status =
          this.subModule == "attend"
            ? this.getStatus(data[0].status)
            : { text: "SUCCESSFUL", value: 2 };

        this.source = data[0].source;
        this.attemp.note_seller = data[0].note_seller;
        this.form.lead_id = data[0].lead_id;
        this.form.dob = data[0].dob;

        this.attemp.request_id = data[0].request_id;

        this.route_pdf = data[0].route_pdf;
        this.route_html = data[0].route_html;

        this.attemp.equ = data[0].equifax;
        this.attemp.exp = data[0].experian;
        this.attemp.tra = data[0].transunion;
        this.attemp.report_date = data[0].date;

        this.attemp.lead_id = data[0].lead_id;
        this.attemp.type_card = data[0].type_card;

        if (this.attemp.type_card == 1) {
          this.getListCards();
        } else if (this.attemp.type_card == 2) {
          if (this.attemp.bc == 1) {
            this.getListCardsTpUsers();
          } else if (this.attemp.bc == 2) {
            this.getListCardsTpclients();
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    getStatus(id) {
      if (id != undefined) {
        let status = this.optionsResult.find((elem) => elem.value == id);
        return status;
      }
      return null;
    },

    async getLeadData() {
      this.addPreloader();
      try {
        const { data } = await NcrLeadsService.ncrLeadsGetNewRequest({
          score_id: this.dataCreditReport.score_id,
        });
        this.form.lead_name = data[0].lead_name;
        this.form.mobile = data[0].mobile;
        this.form.document = data[0].document == null ? "" : data[0].document;
        this.form.document_name = data[0].document_name;
        this.form.document_id = data[0].document_id;
        this.form.street = data[0].street;
        this.form.city = data[0].city;
        this.form.states = data[0].states;
        this.form.country = data[0].country;
        this.form.zipcode = data[0].zipcode;
        this.form.language = data[0].language;
        this.form.type_card = data[0].type_card;
        this.form.id_card = data[0].id_card;
        this.form.send_cr = data[0].send_cr;
        this.form.seller_name = data[0].seller_name;
        this.form.tp = data[0].tp;
        this.form.source = data[0].source;
        this.form.lead_id = data[0].lead_id;
        this.form.dob = data[0].dob;
        this.attemp.lead_id = data[0].lead_id;
        this.attemp.request_id = data[0].request_id;
        this.attemp.type_card = 1;

        let plataform = JSON.parse(data[0].plataform);
        let cards = JSON.parse(data[0].cards);
        this.beginValidate(plataform, cards);

        if (this.getSubTab == "returned") {
          this.typeCard();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    getDocument(value) {
      if (!this.show.document) {
        if (value != null && value != "") {
          return "xxx-xx-" + value.substr(-4);
        }
        return "xxx-xx-xxx";
      } else {
        return value;
      }
    },

    beginValidate(plataform, cards) {
      // Begin validate for source
      let r1 = 0;
      let r2 = 0;

      if (plataform.length == 1) {
        r1 = plataform[0].plataform_id;
        this.s1 = r1 == 1 || r1 == 4 ? r1 : 0;
        this.s2 = r1 == 2 || r1 == 5 ? r1 : 0;
        this.s3 = r1 == 3 || r1 == 6 ? r1 : 0;
        this.s4 = r1 == 7 ? r1 : 0;
      } else if (plataform.length == 2) {
        r1 = plataform[0].plataform_id;
        r2 = plataform[1].plataform_id;

        if (r1 == 1 || r1 == 4) {
          this.s1 = r1;
        } else if (r1 == 2 || r1 == 5) {
          this.s2 = r1;
        } else if (r1 == 3 || r1 == 6) {
          this.s3 = r1;
        } else if (r1 == 7) {
          this.s4 = r1;
        }

        if (r2 == 1 || r2 == 4) {
          this.s1 = r2;
        } else if (r2 == 2 || r2 == 5) {
          this.s2 = r2;
        } else if (r2 == 3 || r2 == 6) {
          this.s3 = r2;
        } else if (r2 == 7) {
          this.s4 = r2;
        }
      }
      // End validate for source

      //Begin validate for cards
      if (cards != null) {
        if (cards.length == 1) {
          this.card1 = cards[0].card_id;
        } else if (cards.length == 2) {
          this.card1 = cards[0].card_id;
          this.card2 = cards[1].card_id;
        }

        //End validate for cards
        if (this.form.type_card == 1) {
          this.getListCards();
        } else if (this.form.type_card == 2) {
          if (this.form.tp == 1) {
            this.getListCardsTpUsers();
          } else if (this.form.tp == 2) {
            this.getListCardsTpclients();
          }
        }
      }
    },

    async getListCards() {
      try {
        const { data } = await NcrProgramsService.getListCards({
          id: this.form.lead_id,
        });
        this.cards = data;
      } catch (error) {}
    },

    viewDocument() {
      this.show.document = !this.show.document;
    },

    typeCard() {
      this.cards = [];
      this.attemp.id_card = "";
      if (this.attemp.type_card == 1) {
        this.getListCards();
      } else if (this.attemp.type_card == 2) {
        if (this.attemp.bc == 1) {
          this.getListCardsTpUsers();
        } else if (this.attemp.bc == 2) {
          this.getListCardsTpclients();
        }
      }
    },

    async getListCardsTpUsers() {
      try {
        const { data } = await NcrProgramsService.getCardsTpUsers();
        this.cards = data;
      } catch (error) {
        console.log(error);
      }
    },

    async getListCardsTpclients() {
      try {
        const { data } = await NcrProgramsService.getCardsTpClients();
        this.cards = data;
      } catch (error) {
        console.log(error);
      }
    },

    async openModalCard(id) {
      try {
        const { data } = await NcrProgramsService.getCreditCard({ id: id });
        this.dataCard = data[0];
        this.modalCard = true;
      } catch (error) {
        console.log(error);
      }
    },

    /* Validate option column of the 'Of Client' table */
    async validOptionTable() {
      if (this.source == null || this.source == "") {
        this.errorBureau = true;
      }
      const validateCreditCard =
        await this.$refs.modalRcrValidaTableCreditCard?.validate(); // Validation by the Ref of the column of the table "Of Client"
      if (!validateCreditCard?.valid) {
        this.errorTable = "is required"; // Stores the error
      } else {
        this.errorTable = ""; // Remove the set error
      }
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
