import { amgApi } from "@/service/axios";

class NcrProgramsService {

    async getPlataform(params) {
        try {
            const data = await amgApi.post("/commons/get-plataform", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on getPlataform:", error);
            throw error;
        }
    }

    async saveReportClient(params) {
        try {
            const data = await amgApi.post("/administration/ncr-programs/save-report-client", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on saveReportClient:", error);
            throw error;
        }
    }

    async processReport(params) {
        try {
            const data = await amgApi.post("/administration/ncr-programs/process-report", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on processReport:", error);
            throw error;
        }
    }

    async getOtherCardShowLA(params) {
        try {
            const data = await amgApi.post("/administration/ncr-programs/get-other-card-show-la", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on getOtherCardShowLA:", error);
            throw error;
        }
    }

    async getCardShowLA(params) {
        try {
            const data = await amgApi.post("/administration/ncr-programs/get-card-show-la", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on getCardShowLA:", error);
            throw error;
        }
    }

    async getStatesLeads() {
        try {
            const data = await amgApi.get("/commons/state-leads");
            return data;
        } catch (error) {
            console.log("Something went wrong on getStatesLeads:", error);
            throw error;
        }
    }

    async getStatesEeuus() {
        try {
            const data = await amgApi.post("/administration/ncr-programs/get-states-eeuus");
            return data;
        } catch (error) {
            console.log("Something went wrong on getStatesEeuus:", error);
            throw error;
        }
    }

    async updateLA(params) {
        try {
            const data = await amgApi.post("/administration/ncr-programs/update-la", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on updateLA:", error);
            throw error;
        }
    }

    async insertCardScores(params) {
        try {
            const data = await amgApi.post("/administration/ncr-programs/insert-card-scores", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on insertCardScores:", error);
            throw error;
        }
    }

    async updateCardScores(params) {
        try {
            const data = await amgApi.post("/administration/ncr-programs/update-card-ccores", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on insertCardScores:", error);
            throw error;
        }
    }

    async amnulNcrLetter(params) {
        try {
            const data = await amgApi.post("/commons/amnul-ncr-letter", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on insertCardScores:", error);
            throw error;
        }
    }

    async completedNcr(params) {
        try {
            const data = await amgApi.post("/commons/completed-ncr", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on completedNcr:", error);
            throw error;
        }
    }

    async getApplicationData(params) {
        try {
            const data = await amgApi.post("/administration/ncr-programs/ncr-leads-get-aplicaction-data", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on getApplicationData:", error);
            throw error;
        }
    }

    async saveApplicationData(params) {
        try {
            const data = await amgApi.post("/administration/ncr-programs/ncr-leads-save-aplicaction-data", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on saveApplicationData:", error);
            throw error;
        }
    }

    async getListCards(params) {
        try {
            const data = await amgApi.post("/cards/search-cards", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on getListCards:", error);
            throw error;
        }
    }

    async getCardsTpUsers() {
        try {
            const data = await amgApi.get("/cards/get-cards-tp-users");
            return data;
        } catch (error) {
            console.log("Something went wrong on getCardsTpUsers:", error);
            throw error;
        }
    }

    async getCardsTpClients() {
        try {
            const data = await amgApi.get("/cards/get-cards-tp-clients");
            return data;
        } catch (error) {
            console.log("Something went wrong on getCardsTpClients:", error);
            throw error;
        }
    }

    async getCreditCard(params) {
        try {
            const data = await amgApi.post("/cards/get-cards-data-by-id", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on getCreditCard:", error);
            throw error;
        }
    }

    async ncrLeadsProcessNewRequest(params) {
        try {
            const data = await amgApi.post("/administration/ncr-leads/ncr-leads-process-new-request", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on ncrLeadsProcessNewRequest:", error);
            throw error;
        }
    }

    async ncrLeadsProcessRequest(params) {
        try {
            const data = await amgApi.post("/administration/ncr-leads/ncr-leads-process-request", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on ncrLeadsProcessRequest:", error);
            throw error;
        }
    }

    async ncrLeadsChangeTab(params) {
        try {
            const data = await amgApi.post("/administration/ncr-leads/ncr-leads-change-tab", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on ncrLeadsChangeTab:", error);
            throw error;
        }
    }

    async ncrLeadsSendMessage(params) {
        try {
            const data = await amgApi.post("/administration/ncr-leads/ncr-leads-send-message", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on ncrLeadsSendMessage:", error);
            throw error;
        }
    }
}

export default new NcrProgramsService();