import CustomerTicketsMain from '@/views/commons/components/customer-tickets/CustomerTicketsMain.vue';
import { ticketsTabs } from "@/views/customer-tickets/router/tickets.router.js";

export default [
  {
    path: '/quality/tickets',
    name: 'quality-customer-tickets',
    redirect: { name: "customer-ticket-quality-in-progress" },
    component: CustomerTicketsMain,
    meta: {
      pageTitle: 'Customer Tickets',
      breadcrumb: [
        {
          text: 'My tickets',
        },
      ],
      permittedRoles: [2, 17],
      tab: 4,
    },
    children: ticketsTabs("quality", [2, 17]),

  },
];
