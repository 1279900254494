<template>
  <div>
    <b-modal
      ref="modal-welcome-actions"
      hide-header
      hide-footer
      hide-header-close
      no-close-on-backdrop
      size="sm"
      sticky-header="300px"
      @hidden="$refs['modal-welcome-actions'].hide()"
    >
      <!--        <pre>{{ notAvailableData }} Count: {{ countNotAnswered }}</pre>-->
      <!--        <pre>{{ digitalClientData }}</pre>-->
      <b-card :bg-variant="isDarkSkin ? 'dark' : 'light'" class="mt-2">
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <span class="mt-2 mb-2" style="font-weight: bold; font-size: 15px"
            >ACTIONS
          </span>
          <b-avatar style="width: 100px; height: 100px" />
          <div
            class="mt-2 mb-2"
            style="
              font-weight: bold;
              font-size: 15px;
              align-items: center;
              display: flex;
              flex-direction: column;
            "
          >
            <span>{{ digitalClientData.lead_name }}</span>
            <div
              style="
                font-weight: bold;
                font-size: 15px;
                align-items: center;
                display: flex;
                flex-direction: column;
              "
              class="mb-2"
            >
              <span>{{ digitalClientData.mobile }}</span>
            </div>
            <badge-risky-clients
              :id-account="digitalClientData.client_account_id"
              :risk-level="digitalClientData.level"
              :risk-description="digitalClientData.comments"
              :risk-type="digitalClientData.type"
            />
            <transition name="fade" mode="out-in">
              <b-alert
                :show="dismissCountDown"
                variant="transparent"
                @dismiss-count-down="countDownChanged"
              >
                <span style="font-weight: bold">Time left: {{ minDate }}</span>
              </b-alert>
            </transition>
          </div>
        </div>
        <validation-observer ref="form">
          <b-row class="justify-content-center">
            <b-col v-for="(act, i) in actionArray" :key="i" cols="3">
              <b-button
                :id="`act-target-${i}`"
                class
                :to="
                  act.id === 1
                    ? {
                        name: 'ce-customer-service-dashboard',
                        params: {
                          idClient: digitalClientData.client_account_id,
                          hasAccess: true, // is alert
                          typeAccess: digitalClientData.alert_type, // Welcome,
                          wayAccess: 3, // enter by modal
                        },
                      }
                    : null
                "
                size="sm"
                :style="`border-radius: 50%; width: 50px; height: 50px`"
                :variant="setVariant(act.id)"
                :disabled="isSuccess === true"
                @mouseover="
                  act.id === 2
                    ? (upNotAvailable = true)
                    : (upNotAvailable = false)
                "
                @click="act.id !== 2 ? saveAction(act) : null"
              >
                <feather-icon
                  :icon="act.icon"
                  size="30"
                  :style="{
                    left: `23px`,
                    bottom: `10px`,
                    position: `absolute`,
                    color: isDarkSkin ? `#222222` : null,
                    transform: act.id === 2 ? `rotate(135deg)` : null,
                  }"
                />
              </b-button>
              <b-tooltip
                :target="`act-target-${i}`"
                triggers="hover"
                placement="top"
              >
                <span>{{ act.action }}</span>
              </b-tooltip>
            </b-col>
            <b-col v-if="upNotAvailable === true" cols="5" class="mt-2">
              <validation-provider
                v-slot="{ errors }"
                name="Date"
                rules="required"
              >
                <b-form-group label="Date">
                  <kendo-datepicker
                    id="contactDate"
                    v-model="notAvailableData.date"
                    v-mask="'##/##/####'"
                    :format="'yyyy/MM/dd'"
                    :class="{ 'border-danger': errors[0] }"
                    class="leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col v-if="upNotAvailable === true" cols="5" class="mt-2">
              <validation-provider
                v-slot="{ errors }"
                name="Time"
                rules="required"
              >
                <b-form-group label="Time">
                  <kendo-timepicker
                    v-model="notAvailableData.time"
                    :format="'HH:mm'"
                    minutes-step="30"
                    class="w-100 rounded bg-transparent k-picker-custom"
                    :class="{ 'border-danger': errors[0] }"
                    style="height: 2.73rem"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-if="upNotAvailable === true"
              cols="2"
              style="margin-top: 50px"
            >
              <b-button
                size="sm"
                variant="success"
                style="position: relative; right: 15px"
                :disabled="isSuccess === true"
                @click="saveAction({ id: 2 })"
                >Save</b-button
              >
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import WelcomeActionService from "@/views/ce-digital/sub-modules/customer-service/views/clients/welcome-actions/service/welcome-action.service";
import ApplicationService from "@/views/commons/components/applications/services/applications.service";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients.vue";

export default {
  name: "ModalWelcomeActions",
  components: {
    BadgeRiskyClients,
  },
  data() {
    return {
      clientDataToRiskyBadge: {},

      isSuccess: false,
      notAvailableData: {
        date: null,
        time: null,
      },
      upNotAvailable: false,
      countNotAnswered: 0,
      actionArray: [],
      actionIcons: [],
      digitalClientData: {},
      // time left
      dismissCountDown: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/G_USER_STATUS_SESSION_SUB_MODULE",
      G_OPEN_MODAL_ACTIONS: "CeDigitalWelcomeActions/G_OPEN_MODAL_ACTIONS",
      G_SEC_TO_LEAVE: "CeDigitalWelcomeActions/G_SEC_TO_LEAVE",
      G_ALERT_TYPE: "CeDigitalWelcomeActions/G_ALERT_TYPE",
    }),
    minDate() {
      return `${Math.floor(this.dismissCountDown / 60)}:${
        this.dismissCountDown % 60 < 10 && this.dismissCountDown % 60 > 0
          ? "0"
          : ""
      }${this.dismissCountDown % 60 ? this.dismissCountDown % 60 : "00"}`;
    },
    alertId() {
      return this.G_OPEN_MODAL_ACTIONS.id_alert;
    },
    openModalAct() {
      return this.G_OPEN_MODAL_ACTIONS.bool;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    async dismissCountDown(newVal) {
      if (newVal === 0) {
        await this.getSecToLeaveAgentAction();
        this.dismissCountDown = this.G_SEC_TO_LEAVE;
      }
    },
    async alertId(newVal) {
      if (newVal) {
        await this.getNewDigitalClient(this.alertId);
      }
    },
    async openModalAct(newVal) {
      if (newVal) {
        await this.getNewDigitalClient(this.alertId);
        await this.getSecToLeaveAgentAction();
        this.dismissCountDown = this.G_SEC_TO_LEAVE;
      }
    },
  },
  async created() {
    this.actionIcons.length === 0
      ? this.actionIcons.push("PhoneIcon", "PhoneIcon", "XIcon")
      : this.actionIcons;
    await this.getActions();
    this.clientDataToRiskyBadge = {
      id_account: this.digitalClientData.client_account_id,
      risk_description: this.digitalClientData.description,
      risk_level: this.digitalClientData.level,
      risk_type: this.digitalClientData.type,
    };
  },
  methods: {
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
      A_SEC_TO_LEAVE: "CeDigitalWelcomeActions/A_SEC_TO_LEAVE",
    }),
    ...mapMutations({
      SET_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/SET_USER_STATUS_SESSION_SUB_MODULE",
    }),
    async getSecToLeaveAgentAction() {
      if (this.alertId !== null) {
        const params = {
          alert_id: this.alertId,
        };
        const data = await WelcomeActionService.getSecToLeaveAgentAction(
          params
        );
        if (data.status === 200) {
          this.A_SEC_TO_LEAVE(data.data[0].time_in_sec);
          if (data.data[0].time_in_sec === 0) {
            await this.saveAction({ id: 0 });
          }
        }
      }
    },
    async countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
      if (dismissCountDown === 0) {
        await this.saveAction({ id: 0 });
      }
    },
    startStatus(status) {
      const params = {
        created_by: this.currentUser.user_id,
        sub_module_id: this.moduleId,
        status_sub_module_id: status,
      };
      this.SET_USER_STATUS_SESSION_SUB_MODULE([{ status_session: status }]);
      this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(params);
    },
    async getActions() {
      const data = await WelcomeActionService.getAgentActions();
      if (data.status === 200) {
        this.actionArray = data.data;
      }
      this.actionArray.forEach(
        (obj, index) => (obj.icon = this.actionIcons[index])
      );
    },

    async getNewDigitalClient(id) {
      const params = {
        alert_id: id,
      };
      const data = await WelcomeActionService.getNewDigitalClient(params);
      if (data.status === 200) {
        this.digitalClientData =
          data.data.length > 0 ? data.data[0] : this.digitalClientData;
        this.countNotAnswered = this.digitalClientData.calls_count + 1;
      }
    },

    async closeModal(id) {
      this.isSuccess = true;
      const params = {
        id: this.currentUser.user_id,
        bool: false,
      };
      const response = await WelcomeActionService.pusherAgentAction(params);
      if (response.status === 200 && id !== 1) {
        this.isSuccess = false;
        this.startStatus(1);
      } else if (response.status === 200 && id === 1) {
        this.isSuccess = false;
        this.startStatus(5);
      }
    },
    async saveAction(item) {
      if (item.id === 1) {
        const params = {
          agent_id: this.currentUser.user_id,
          alert_id: this.G_OPEN_MODAL_ACTIONS.id_alert,
          agent_action_id: item.id,
        };
        await WelcomeActionService.saveAgentAction(params);
        if (this.isAgent) {
          await document.documentElement.requestFullscreen();
        }
        await this.closeModal(item.id);

        if ([6, 7, 9].some((e) => e === this.digitalClientData.alert_type)) {
          const paramsAssign = {
            user_id: this.currentUser.user_id,
            app_id: digitalClientData.app_id,
          };
          await ApplicationService.assignAgentToApplication(paramsAssign);
        }
      } else if (item.id === 2) {
        // Manual -- call round
        const validation = await this.$refs.form.validate();
        if (validation) {
          const params = {
            agent_id: this.currentUser.user_id,
            alert_id: this.G_OPEN_MODAL_ACTIONS.id_alert,
            agent_action_id: item.id,
            date_event: this.notAvailableData.date,
            hour_event: this.notAvailableData.time,
          };
          await WelcomeActionService.saveAgentAction(params);
          await this.closeModal(item.id);
        }
      } else if (item.id === 3) {
        const params = {
          agent_id: this.currentUser.user_id,
          alert_id: this.G_OPEN_MODAL_ACTIONS.id_alert,
          client_account_id: this.digitalClientData.client_account_id,
          agent_action_id: item.id,
          calls_count: this.countNotAnswered,
        };
        await WelcomeActionService.saveAgentAction(params);
        await this.closeModal(item.id);
      } else if (item.id === 0) {
        const params = {
          agent_id: this.currentUser.user_id,
          alert_id: this.G_OPEN_MODAL_ACTIONS.id_alert,
          agent_action_id: item.id,
        };
        await WelcomeActionService.saveAgentAction(params);
        await this.closeModal(item.id);
      }
    },
    setVariant(id) {
      switch (id) {
        case 1:
          return "success";
          break;
        case 2:
          return "danger";
          break;
        case 3:
          return "warning";
          break;
      }
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
