

export default [
  {
    path: 'app-payments-made',
    name: 'ce-digital-app-payments-made',
    component: () => import(/* webpackChunkName: "CEDFinancialAppPaymentsMade" */ "@/views/ce-digital/sub-modules/financial/view/app-payments-made/AppPaymentsMade.vue"),
    meta: {
      pageTitle: 'App Payments made',
      parentModule: 21,
      breadcrumb: [
        {
          text: 'App Payments made',
          active: true,
        },
      ],
      permittedRoles: [1, 2, 14, 17],
    },
  },
]
