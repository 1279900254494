<template>
  <b-modal
    :ref="timelineRef"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="TIME LINE TICKET"
    size="modal-timeline"
    hide-footer
    centered
    hide-header-close
    hide-header
    @hidden="close()"
  >
    <timeline-component :title="title" :subtitle="subtitle" :typeData="typeData" :timeLine="timeLine" :timelineRef="timelineRef" @close="close"/>
  </b-modal>
</template>
<script>
import TimelineComponent from './TimelineComponent.vue'

export default {
  components: {
    TimelineComponent
  },
  props: {
    title: String,
    subtitle: String,
    typeData: {
      type: String,
      default: "status",
    },
    timeLine: {
      type: Array,
      default: () => [],
    },
    timelineRef: {
      type: String,
      default: "amg-timeline",
    },
  },
  data() {
    return {}
  },
  mounted() {
    this.toggleModal(this.timelineRef)
  },
  created() {},
  methods: {
    close() {
      this.$refs[this.timelineRef].hide()
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>

</style>