export default {
  namespaced: true,
  state: {
    S_UPDATED_LEADS_TABLE: false,
  },
  mutations: {
    UPDATED_LEADS_TABLE(state, params) {
      state.S_UPDATED_LEADS_TABLE = params;
    },
  },
  actions: {
    A_UPDATED_LEADS_TABLE({ commit }, data) {
      commit("UPDATED_LEADS_TABLE", data);
    },
  },
  getters: {
    G_UPDATED_LEADS_TABLE(state) {
      return state.S_UPDATED_LEADS_TABLE;
    },
  },
};
