<template>
  <b-card>
    <b-row align-h="center">
      <b-col cols="5" class="flex-column">
        <validation-observer ref="formProgramPayment">
          <b-form-group label="Monthly payment">
            <validation-provider
              v-slot="{ errors }"
              rules="customRequired|money-1"
            >
              <b-input-group prepend="$">
                <money
                  v-model="programPayment"
                  v-bind="vMoney"
                  class="form-control"
                  :class="{ 'border-danger': errors[0] && validateMoney }"
                  :disabled="fdlead ? false : state == 0"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Retainer fee">
            <validation-provider v-slot="{ errors }" rules="customRequired">
              <v-select
                v-model="retainerFee"
                :options="retainerFeeOptions"
                :reduce="(item) => item.value"
                :class="errors[0] ? 'border-color:red' : ''"
                :disabled="fdlead ? false : state == 0"
                label="label"
                :clearable="false"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group label="Settlement cost">
            <validation-provider rules="customRequired">
              <b-form-select
                id="settlement"
                v-model="settlementCost"
                :disabled="fdlead ? false : state == 0"
              >
                <b-form-select-option
                  v-for="(item, index) in arraySettlementCost"
                  :key="index"
                  :value="item"
                  >{{ item + ".00 %" }}</b-form-select-option
                >
              </b-form-select>
            </validation-provider>
          </b-form-group>
          <!-- <div class="d-flex align-items-center justify-content-center">
            <b-button variant="outline-custom-amg">
              SAVE
              <feather-icon
                icon="SaveIcon"
                size="16"
                class="cursor-pointer"
                @click="addProgramPayment"
              />
            </b-button>
          </div> -->
          <!-- <div
            v-if="state !== '2'"
            class="w-100"
            style="display: flex; justify-content: end; align-items: center"
          >
            <b-row class="w-100 pt-1 pb-1 pr-0-1 pl-0">
              <b-col
                class="p-0"
                v-if="
                  lengthTable != 0 &&
                  !passwordIsCorrectAndCreditorLength &&
                  !directRoles
                "
              >
                <field-approve-supervisor
                  v-if="lengthTable != 0"
                  pt-1
                  :moduleId="2"
                  :isRequired="
                    !passwordIsCorrectAndCreditorLength && !directRoles
                  "
                  :isModalAddThis="true"
                  @okPassword="statePassword"
                  class="stateCreditor p-0"
                />
              </b-col>
              <b-col class="d-flex justify-content-end">
                <div
                  class="d-flex justify-content-center"
                  v-if="!hideNextButtons || stateCreditor == 1"
                >
                  <span class="text-primary mr-1 mt-1 h4"
                    >Was the sale made?</span
                  >
                  <div id="btnYes" class="p-0 mr-2">
                    <b-button
                      class="h-100"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      :disabled="creditorsIsNotNull || programPayment == 0"
                      @click="finish()"
                    >
                      <feather-icon icon="CheckIcon" class="mr-50" />
                      <span>Yes</span>
                    </b-button>
                  </div>
                  <b-tooltip
                    v-if="creditorsIsNotNull || programPayment == 0"
                    disabled-triggers="never"
                    target="btnYes"
                    triggers="hover"
                    placement="top"
                  >
                    <b-row
                      class="d-flex w-100 justify-content-center mx-0 mt-1 mb-1"
                    >
                      <feather-icon
                        size="40"
                        icon="InfoIcon"
                        class="text-primary"
                      />
                    </b-row>
                    <small v-if="creditorsIsNotNull">
                      Please,
                      <strong class="text-primary"> add creditors </strong> to
                      the list to continue the sale.
                    </small>
                    <small v-else>
                      Please, you must complete the
                      <strong class="text-primary">
                        Program Payment, Retainer fee and Settlement cost
                      </strong>
                      before proceeding with the sale
                    </small>
                  </b-tooltip>
                  <b-button
                    @click="close()"
                    id="not"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="danger"
                    type="button"
                    class="mr-1"
                  >
                    <feather-icon icon="TrendingDownIcon" class="mr-50" />
                    <span>Not</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div> -->
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import FieldApproveSupervisor from "@/views/commons/utilities/FieldApproveSupervisor.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import DebtSolutionService from "@/views/debt-solution/services/debt-solution.service.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SalesMadeService from "@/views/commons/components/sales-made/add-sale/service/sales-made-service.js";
import EventService from "@/views/crm/services/event/index.js";

export default {
  components: {
    FieldApproveSupervisor,
  },
  directives: {
    Ripple,
  },
  props: {
    fdlead: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      required: true,
      default: null,
    },
    // retainerFee: {
    //   required: true,
    // },
    lengthTable: {
      type: Number,
      required: true,
    },
    idEventAccountApproved: {
      type: Number,
      default: null,
    },
    id_event: {
      type: Number,
      required: true,
    },
  },
  created() {
    this.getDetailsOfPayment();
    this.getEvent();
  },
  data() {
    return {
      retainerFeeOptions: [
        { value: 0.0, label: "0%" },
        { value: 0.01, label: "1%" },
        { value: 0.02, label: "2%" },
        { value: 0.03, label: "3%" },
        { value: 0.04, label: "4%" },
        { value: 0.05, label: "5%" },
        { value: 0.06, label: "6%" },
        { value: 0.07, label: "7%" },
        { value: 0.08, label: "8%" },
        { value: 0.09, label: "9%" },
        { value: 0.1, label: "10%" },
      ],
      arraySettlementCost: Array.from({ length: 21 }, (_, index) => index + 5),
      programPayment: 0,
      settlementCost: 25,
      vMoney: {
        maxlength: 10,
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false,
      },
      validateMoney: false,
      retainerFee: { value: 0.1, label: "10%" },
      event: null,
    };
  },
  methods: {
    async getEvent() {
      try {
        const { data: event } = await EventService.getEvent({
          id: this.id_event,
        });
        if (event.length > 0) {
          this.event = event[0];
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async addProgramPayment() {
      try {
        this.validateMoney = true;
        const success = await this.$refs.formProgramPayment.validate();
        if (success) {
          return await this.showQuestionSwal(
            "Are you sure?",
            null,
            "Yes",
            "Cancel",
            "question",
            {
              allowEscapeKey: false,
              allowOutsideClick: false,
              showDenyButton: true,
              showCancelButton: true,
              denyButtonText: "No",
              customClass: {
                confirmButton: "btn btn-outline-success mr-1",
                cancelButton: "btn btn-outline-warning mr-1",
                denyButton: "btn btn-outline-danger  mr-1",
              },
              focusConfirm: false,
            }
          ).then(async (result) => {
            if (result.isConfirmed) {
              let params = {
                id_event:
                  this.idEventAccountApproved != null
                    ? this.idEventAccountApproved
                    : this.id_event,
                program_payment: this.programPayment,
                retainer_fee: this.retainerFee == "10%" ? 0.1 : this.retainerFee,
                settlement_cost: this.settlementCost,
              };

              await DebtSolutionService.UpdateProgramPaymethAndRetainer(params);

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "CheckIcon",
                  variant: "success",
                  text: "Program payment values registered successfully!",
                },
              });
              return true;
            } else if (result.isDenied) {
              this.close();
            }
          });
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async close() {
      if (this.rejectedAppointments || this.hideFooter) {
        this.$emit("close");
      } else {
        this.addPreloader();
        try {
          let data = {
            id_event: this.id_event,
            lead_pending_appointment: 1,
          };
          if (this.idEventAccountApproved != null) {
            await this.listCreditorsToEvent();
          }

          this.showSuccessSwal(
            "Successfully!",
            "This sale has been sent to the Rejected DS Sales list at Sales Made section."
          );
          this.$emit("close", data);
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    async getDetailsOfPayment() {
      const { data } = await SalesMadeService.getDetailsOfPayment({
        event_id: this.idEventAccountApproved ?? this.id_event,
      });
      if (data && data.length > 0) {
        this.retainerFee = data[0].retainer_fee || "10%";
        this.settlementCost = data[0].settlement_cost || 25;
        this.programPayment = data[0].program_payment || 0;
      }
    },
    async listCreditorsToEvent() {
      await EventService.listCreditorsToEvent({
        leadId: this.event.lead_id,
        eventId: this.event.id,
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    directRoles() {
      if (this.currentUser.role_id == 1) {
        return true;
      } else if (this.currentUser.role_id == 2) {
        return true;
      } else {
        return false;
      }
    },
    passwordIsCorrectAndCreditorLength() {
      return (
        (this.okPassword && this.lengthTable > 0) || this.stateCreditor == 1
      );
    },
  },
};
</script>
