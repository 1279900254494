export default [
  {
    path: 'inventory',
    name: 'logistic-inventory',
    redirect: { name: 'logistic-inventory-stock' },
    component: () => import(/* webpackChunkName: "LogisticInventory" */ '@/views/logistic/views/inventory2/Inventory.vue'),
    meta: {
      permittedRoles: [1, 2, 3],
      pageTitle: 'Inventory',
      breadcrumb: [
        {
          text: 'Stock',
          active: true,
        },
      ],
    },
    children: [
      {
        path: 'stock',
        name: 'logistic-inventory-stock',
        component: () => import(/* webpackChunkName: "LogisticInventoryStock" */ '@/views/logistic/views/inventory2/components/Stock.vue'),
        meta: {
          permittedRoles: [1, 2, 3],
          pageTitle: 'Inventory',
          breadcrumb: [
            {
              text: 'Stock',
              active: true,
            },
          ],
        },
      },
      {
        path: 'history',
        name: 'logistic-inventory-history',
        redirect: { name: 'input-inventory-stock' },
        component: () => import(/* webpackChunkName: "LogisticInventoryHistory" */ '@/views/logistic/views/inventory2/components/InputOutputTab.vue'),
        meta: {
          permittedRoles: [1, 2, 3],
          pageTitle: 'Inventory',
          breadcrumb: [
            {
              text: 'History',
              active: true,
            },
          ],
        },
        children: [
          {
            path: 'input',
            name: 'input-inventory-stock',
            component: () => import(/* webpackChunkName: "LogisticInventoryStock" */ '@/views/logistic/views/inventory2/components/InputOutputTable.vue'),
            meta: {
              permittedRoles: [1, 2, 3],
              pageTitle: 'Inventory',
              breadcrumb: [
                {
                  text: 'History',
                  active: true,
                },
                {
                  text: 'Input',
                  active: true,
                },
              ],
            },
          },
          {
            path: 'output',
            name: 'output-inventory-history',
            component: () => import(/* webpackChunkName: "LogisticInventoryStock" */ '@/views/logistic/views/inventory2/components/OutputTable.vue'),
            meta: {
              permittedRoles: [1, 2, 3],
              pageTitle: 'Inventory',
              breadcrumb: [
                {
                  text: 'History',
                  active: true,
                },
                {
                  text: 'Output',
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },
];
