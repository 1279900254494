import LeadsNavigation from "@/views/specialists/views/leads/leads.nav";
import SalesMadeNavigation from "@/views/specialists/views/sales-made/sales-made.nav";
import ApplicationsNavigation from "@/views/specialists/views/applications/navigation";
import CalendarNavigation from "@/views/specialists/views/calendar/calendar.nav";
import SettingNavigation from "@/views/specialists/views/settings/settings.nav";
import FileManagerNavigation from "@/views/specialists/views/file-mananger/file-mananger.nav";
import ProductRequestNavigation from "@/views/specialists/views/request/request.nav.js";
import PaymentsNavigation from "@/views/specialists/views/payments/payments.nav";
import ZeroPaymentNavigation from "@/views/specialists/views/zero-payment/zero-payment.nav";
import PaymentReportNavigation from "@/views/specialists/views/report/report.nav";
import WorkPlanAndLettersRequestNavigation from "@/views/specialists/views/workplan-and-letters-request/work-plans.nav";
import ClaimsNavigation from "@/views/specialists/views/claims-v2/claims.nav";
import CommissionNavigation from "@/views/specialists/views/commissions/commissions.nav";
import helpdeskNavigation from "@/views/specialists/views/helpdesk/helpdesk.nav";
import RequestNcrNav from "@/views/specialists/views/request-ncr/request-ncr.nav";
import UpdateRequestNavigation from "@/views/specialists/views/update-request/update-request.nav";
import DirectRoundNavigation from "@/views/specialists/views/direct-round/direct-round.nav";
import SchedulesNavigation from "@/views/specialists/views/schedules/schedules.nav";
import ChargeBackNavigation from "@/views/specialists/views/charge-back/charge-back.nav";
import LettersNavigation from "@/views/specialists/views/letters/navigation/index";
import NcrNavigation from "@/views/specialists/views/ncr/ncr.nav";
import clientsNavigation from '@/views/specialists/views/clients/clients-group.navigation';
import OtherPaymentsNavigation from "@/views/specialists/views/other-payments/other-payments.nav";
import EmployeeClaimsNavigation from "@/views/specialists/views/employee-claims/navigation/employee-claims.nav";
import InquiriesNavigation from "@/views/specialists/views/inquiries/inquiries.nav.js";
import DashboardNavigationV2 from "@/views/specialists/views/dashboard_v2/navigation/specialists-dashboard.nav";
import SpecialistsProjectsNavigation from "@/views/specialists/views/projects/specialists-projects.navigation";
import RingCentralNav from "@/views/specialists/views/ring-central/ring-central.nav.js";
import CourtInfoNavigation from "@/views/specialists/views/cour-info/navigation/ds-court-info.nav";

import MOFNavigation from "@/views/specialists/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from '@/views/specialists/views/recruitment-process/specialists-recruitment_process.navigation';

const navigation = [
  {
    header: "SPECIALISTS",
  },
  DashboardNavigationV2,
  clientsNavigation,
  ClaimsNavigation,
  NcrNavigation,
  RequestNcrNav,
  UpdateRequestNavigation,
  ...LettersNavigation,
  DirectRoundNavigation,
  WorkPlanAndLettersRequestNavigation,
  ApplicationsNavigation,
  LeadsNavigation,
  SalesMadeNavigation,
  CommissionNavigation,
  PaymentReportNavigation,
  PaymentsNavigation,
  ZeroPaymentNavigation,
  ChargeBackNavigation,
  FileManagerNavigation,
  CalendarNavigation,
  SettingNavigation,
  SchedulesNavigation,
  helpdeskNavigation,
  ProductRequestNavigation,
  ...InquiriesNavigation,
  OtherPaymentsNavigation,
  SpecialistsProjectsNavigation,
  EmployeeClaimsNavigation,
  RingCentralNav,
  ...CourtInfoNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
];
export default navigation;
