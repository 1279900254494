

export default {
  path: "/chat-revision",
  component: () => import(/* webpackChunkName: "CEDCustomerServiceChatRevision" */ "@/views/ce-digital/sub-modules/customer-service/views/chat-revision/view/index.vue"),
  name: "customerservice-chat-revision",
  redirect: { name: "customerservice-chat-revision-pending" },
  meta: {
    pageTitle: "Chat Revision",
    breadcrumb: [
      {
        text: "Revision",
        active: true,
      },
    ],
    permittedRoles: [2, 16, 17],
  },
  children: [
    {
      path: "pending",
      component: () => import(/* webpackChunkName: "CEDCustomerServiceChatRevisionPending" */ "@/views/ce-digital/sub-modules/customer-service/views/chat-revision/view/Grid.vue"),
      name: "customerservice-chat-revision-pending",
      meta: {
        pageTitle: "Pending",
        breadcrumb: [
          {
            text: "Pending",
            active: true,
          },
        ],
        tab: 1,
        permittedRoles: [1, 2, 14, 16, 17],
      },
    },
    {
      path: "accepted",
      component: () => import(/* webpackChunkName: "CEDCustomerServiceChatRevisionPending" */ "@/views/ce-digital/sub-modules/customer-service/views/chat-revision/view/Grid.vue"),
      name: "customerservice-chat-revision-accepted",
      meta: {
        pageTitle: "Accepted",
        breadcrumb: [
          {
            text: "Accepted",
            active: true,
          },
        ],
        tab: 2,
        permittedRoles: [1, 2, 14, 16, 17],
      },
    },
    {
      path: "rejected",
      component: () => import(/* webpackChunkName: "CEDCustomerServiceChatRevisionPending" */ "@/views/ce-digital/sub-modules/customer-service/views/chat-revision/view/Grid.vue"),
      name: "customerservice-chat-revision-rejected",
      meta: {
        pageTitle: "Rejected",
        breadcrumb: [
          {
            text: "Rejected",
            active: true,
          },
        ],
        tab: 3,
        permittedRoles: [1, 2, 14, 16, 17],
      },
    },
  ],
};
