<template>
  <div>
    <b-badge
        v-if="riskType != null"
        v-b-modal.modal-action
        v-b-tooltip.hover
        pill
        :title="riskDescription"
        class="text-uppercase"
        :variant="getRiskVariant(riskType)"
        @click="openModalInsertCommentRisky(item)"
    >
      {{ riskType === "non-risky" ? "": riskLevel }} {{ riskType == "risky" ? "risk" : riskType }}
    </b-badge>

    <modal-insert-comments-risky
        v-if="modalInsertCommentsRiskyOn"
        :data="comments"
        @close="closeModalInsertCommentRisky"
    />
  </div>
</template>

<script>

import ModalInsertCommentsRisky from '@/views/management/views/risky-clients/modals/ModalInsertCommentsRisky.vue'

export default {
  name: 'BadgeRiskyClients',
  components: {
    ModalInsertCommentsRisky,
  },
  props: {
    idAccount: String,
    riskLevel: String,
    riskType: String,
    riskDescription: String,
  },
  data() {
    return {
      item: { },
      comments: '',
      modalInsertCommentsRiskyOn: false,
      modalInsertComment: false,
    }
  },
  watch: {
    idAccount() {
      this.item = {
        id_account: this.idAccount,
        risk_level: this.riskLevel,
        risk_type: this.riskType,
        risk_description: this.riskDescription,
      }
    },
  },
  created() {
    this.item = {
      id_account: this.idAccount,
      risk_level: this.riskLevel,
      risk_type: this.riskType,
      risk_description: this.riskDescription,
    }
  },
  methods: {
    getRiskVariant(risk_type){
      if (risk_type == "non-risky") {
        return "success";
      } else if (risk_type == "risky") {
        return "danger";
      } else {
        return "secondary";
      }
    },
    openModalInsertCommentRisky(data) {
      this.modalInsertCommentsRiskyOn = true
      this.comments = data
    },
    closeModalInsertCommentRisky() {
      this.modalInsertCommentsRiskyOn = false
    },
  },
}
</script>

<style scoped>

</style>
