/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class TicketCostumer {
  async sendMessage(body) {
    try {
      const { data, status } = await amgApi.post('/customer-tickets/send-message', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getCurrentChatTime(body) {
    try {
      const data = await amgApi.post('/customer-tickets/get-current-chat-time', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async sendReaction(body) {
    try {
      const data = await await amgApi.post('/customer-tickets/send-reaction', body);
      return data;
    } catch (error) {
      return error;
    }
  }

  async getMessages(body) {
    try {
      const { data, status } = await amgApi.post('/customer-tickets/get-messages', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async viewTicketCustomerMessage(body) {
    try {
      const { data, status } = await amgApi.post('/customer-tickets/view-ticket-customer-message', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async viewTicketCustomerMessages(body) {
    try {
      const { data, status } = await amgApi.post('/customer-tickets/view-ticket-customer-messages', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async setCurrentSession(body) {
    try {
      const { data, status } = await amgApi.post('/customer-tickets/set-current-session', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async deleteCurrentSession(body) {
    try {
      const { data, status } = await amgApi.post('/customer-tickets/delete-current-session', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getMessageReactionsDetail(body) {
    try {
      const { data, status } = await amgApi.post('/customer-tickets/get-message-reactions-detail', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getLastMessageNotViewed(body) {
    try {
      const { data, status } = await amgApi.post('/customer-tickets/get-last-message-not-viewed', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getNoteNoteAccountId(body) {
    try {
      const { data, status } = await amgApi.post('/customer-tickets/get-note-note-account-id', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

}
export default new TicketCostumer();
