import { amgApi } from "@/service/axios";

class ParagonService {
  async getModulesAvailable(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/get-modules-available",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getOwners(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/get-owners",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async insertRelationShip(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/insert-relationship",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getModuleEngineers(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/get-module-engineers",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async deleteModuleEngineers(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/delete-module-engineers",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getSectionsTable(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/get-sections-table",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async deleteSectionModules(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/delete-section-modules",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getModulesSection(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/get-modules-section",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async insertRelationShipSection(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/insert-relationship-section",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async insertMotive(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/insert-motive",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getMotives(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/get-motives",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async addTeam(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/teams/store",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTeamByProject(request_id) {
    try {
      const { data } = await amgApi.post(
        `/paragon/settings/teams/show/${request_id}`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAssignedTeams() {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/assign-teams/index"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateNameTeam(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/teams/update",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getParticipantsUnassignedTeams() {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/participants-unassigned/index"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async updateAssignTeam(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/assign-teams/update",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async insertSupervisorAssignTeam(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/assign-supervisor-teams/store",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateSupervisorAssignTeam(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/assign-supervisor-teams/update",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteTeam(id, params) {
    try {
      const { data } = await amgApi.post(
        `/paragon/settings/teams/destroy/${id}`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getOwnersByType(type) {
    try {
      const { data } = await amgApi.get(
        `/paragon/settings/owner/index/${type}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeDefaultTeam(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/settings/default-teams/update",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new ParagonService();
