export default [
    {
        path: "payments",
        name: "business-payment",
        redirect: { name: 'business-payment-list-clients' },
        component: () => import(/* webpackChunkName: "BusinessPayment" */ '@/views/commons/components/payments/views/components/PaymentsTab.vue'),
        meta: {
            pageTitle: "Payments",
            breadcrumb: [
                {
                    text: "Payments",
                    active: true
                },
            ],
            route: "business"
        },
        children: [
            {
                path: "clients",
                component: () => import(/* webpackChunkName: "BusinessClients" */ '@/views/commons/components/payments/views/components/PaymentsClientsGrid.vue'),
                name: "business-payment-list-clients",
                meta: {
                    pageTitle: "Payments",
                    breadcrumb: [{
                        text: "Clients",
                        active: true
                    }],
                    route: "business"
                }
            },
            {
                path: "leads",
                component: () => import(/* webpackChunkName: "BusinessLeads" */ '@/views/commons/components/payments/views/components/PaymentsLeadsGrid.vue'),
                name: "business-payment-list-leads",
                meta: {
                    pageTitle: "Payments",
                    breadcrumb: [{
                        text: "Leads",
                        active: true
                    }],
                    route: "business"
                }
            }
        ]
    }
]