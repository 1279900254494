import leadsRoute from "@/views/administration/views/leads/leads.router";
import salesMadeRouter from "@/views/administration/views/sales-made/sales-made.router";
import zeroPaymentRouter from "@/views/administration/views/zero-payment/router/zero-payment.router.js";
import FileManagerRouter from "@/views/administration/views/file-manager/file-manager.router.js";
import FilePoBoxRouter from "@/views/administration/views/file-po-box/file-po-box.router.js";
import CalendarRouter from "@/views/administration/views/calendar/calendar.router.js";
import SchedulesRouter from "@/views/administration/views/schedules/schedules.router";
import LoansRouter from "@/views/administration/views/loans/loans.router";
import HelpDeskRouter from "@/views/administration/views/helpdesk/helpdesk.route";
import ProductRequestRouter from "@/views/administration/views/request/request.router.js";
import SettingsRouter from "@/views/administration/views/settings/settings.router";
import migrationsRouter from "@/views/administration/views/migrations/migrations.router";
import letterRouter from "@/views/administration/views/letters/router/letters.router";
import clientRouter from "@/views/administration/views/clients/clients.router";
import AdministrationMain from "@/views/administration/views/AdministrationMain.vue";
import initialPaymentRouter from "@/views/administration/views/initial-payment/initial-payment.router";
import StatusChangeRouter from "@/views/administration/views/status-change/router/status-change.router.js";
import ClientsRouter from "@/views/administration/views/clients/router/clients.router.js";
import ReportRouter from "@/views/administration/views/report/router/report.router.js";
import CommissionsRouter from "@/views/administration/views/comissions/router/commissions.router.js";
import registerPaymentRouter from "@/views/administration/views/register-payment/register-payment.router";
import admAttendanceRouter from "@/views/administration/views/attendance/adm-attendance.router";
import employeeClaimsRouter from "@/views/administration/views/employee-claims/router/employee-claims.router";
//import AdministrationProjectsRouter from "@/views/administration/views/projects/administration-projects.router";
import CallLogRouter from "@/views/administration/views/call-log/call-log.router.js";
import chargeBackRouter from "@/views/administration/views/charge-back/charge-back.router.js";
import CourtInfoRouter from "@/views/administration/views/cour-info/router/ds-court-info.router";
import RecruitmentProcessRouter from '@/views/administration/views/recruitment-process/administration-recruitment_process.router';
import MOFRouter from '@/views/administration/views/manual-of-functions/manual-of-functions.router.js';
import CustomerTicketsRouter from '@/views/administration/views/customer-tickets/administration-customer-tickets.router';
import DashboardRouter from '@/views/administration/views/dashboard/router/dashboard.router.js';


const routes = [
  {
    path: "/administration",
    redirect: { name: "lead-administration" },
    meta: {
      module: 4,
    },
    component: AdministrationMain,
    children: [
      ...leadsRoute,
      ...salesMadeRouter,
      ...zeroPaymentRouter,
      ...ClientsRouter,
      ...StatusChangeRouter,
      ...ReportRouter,
      ...FileManagerRouter,
      ...FilePoBoxRouter,
      CalendarRouter,
      ...SchedulesRouter,
      ...LoansRouter,
      HelpDeskRouter,
      ...ProductRequestRouter,
      SettingsRouter,
      initialPaymentRouter,
      migrationsRouter,
      ...letterRouter,
      ...clientRouter,
      ...CommissionsRouter,
      ...registerPaymentRouter,
      ...admAttendanceRouter,
      ...employeeClaimsRouter,
      //...AdministrationProjectsRouter,
      ...CallLogRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...chargeBackRouter,
      ...CourtInfoRouter,
      ...CustomerTicketsRouter,
      ...DashboardRouter
    ],
  },
];

export default routes;
