import { amgApi } from '@/service/axios'

class RoiReportsService {
  async getDataReport(body) {
    try {
      const data = await amgApi.post('/process-sale/get-data-reports', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getProcessSaleRecoveryId(body) {
    try {
      const data = await amgApi.post('/process-sale/get-process-sale-recovery-id', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getReportEventMotives(body) {
    try {
      const data = await amgApi.post('/process-sale/get-report-event-motives', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getValidationEditReplay(body) {
    try {
      const data = await amgApi.post('/process-sale/validate-edit-replay', body)
      return data
    } catch (error) {
      throw error
    }
  }
  async getReportRepliesSales(body) {
    try {
      const data = await amgApi.post('/process-sale/get-sales-by-flyer-id', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getReportRepliesSalesRecovery(body) {
    try {
      const data = await amgApi.post('/process-sale/get-sales-by-flyer-id-recovery', body)
      return data
    } catch (error) {
      throw error
    }
  }
  async getReportRepliesMotives(body) {
    try {
      const data = await amgApi.post('/process-sale/get-report-replies-motives', body)
      return data
    } catch (error) {
      throw error
    }
  }
  async getLeadsValueByFlyerId(body) {
    try {
      const data = await amgApi.post('/process-sale/get-leads-value-by-flyer-id', body)
      return data
    } catch (error) {
      throw error
    }
  }
  async validateRecoverySeller(body) {
    try {
      const data = await amgApi.post('/process-sale/validate-recovery-seller', body)
      return data
    } catch (error) {
      throw error
    }
  }
  async getIdReplay(body) {
    try {
      const data = await amgApi.post('/process-sale/get-id-replay', body)
      return data
    } catch (error) {
      throw error
    }
  }
}

export default new RoiReportsService()
