export const welcomeActionsMixin = {
  methods: {
    toggleModal() {
      // this.$emit('bv::toggle::modal', 'modal-change-status-performance', '#toggle-btn')
      // this.$refs['modal-change-status-performance'].toggle('#toggle-btn')
      this.$bvModal.show('modal-welcome-actions')
    },
    toggleModalSwall() {
      this.$bvModal.show('modal-welcome-actions-swall')
    },
  },
}
