import axios from "axios";

export const amgApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

export const amgApiApp = axios.create({
  baseURL: process.env.VUE_APP_BASE_APP_URL,
});

export const amgApiFake = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_FAKE,
});

export const nestApi = axios.create({
  baseURL: process.env.VUE_APP_RINGCENTRAL_APP_API,
});

export const metaApi = axios.create({
  baseURL: process.env.VUE_APP_META_API,
});

export const serverlessApi = axios.create({
  baseURL: process.env.VUE_APP_SERVERLESS_API,
});

nestApi.interceptors.request.use(config => {
  const token = localStorage.getItem('accessToken')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});