import ProductRequestRoute from "@/views/crm/views/request/request.router.js";
import FileManangerRoute from "@/views/crm/views/file-mananger/file-mananger.router.js";
import HelpdeskRoute from "@/views/crm/views/helpdesk/helpdesk.route.js";
import manageCommissionsRouter from "@/views/crm/views/manage-commissions/manage-commissions.router";
import OtherPaymentsRouter from "@/views/crm/views/other-payments/other-payments.router";
import ReportsRouter from "@/views/crm/views/reports/reports.router";
import CrmAttendanceRouter from "@/views/crm/views/attendance/crm-attendance.router";
//import crmProjectsRouter from "@/views/crm/views/projects/crm-projects.router";
import calendarRoute from "../views/calendar/calendar.router";
import leadRoute from "../views/Lead/lead.route";
import SalesMadeRoute from "../views/sales-made/sales-made.router";
import clientsRoute from "../views/clients/clients.router";
import commissionsRoute from "../views/commissions/commissions.router";
import viewDashboard from "../views/dashboard/dashboard.router";
import PaymentsRoute from "../views/payments/payments.router";
import ListsRoute from "../views/lists/lists.router";
import LoansRoute from "../views/loans/loans.router";
import SettingsRoute from "../views/settings/router/settings.router";
import RequestNcrRouter from "../views/request-ncr/request-ncr.router";
import CrmMain from "../views/Main.vue";
import EmployeeClaimsRouter from "@/views/crm/views/employee-claims/router/employee-claims.router";
import CallLogRouter from "@/views/crm/views/ring-central/call-log/call-log.router.js";
import DashboardCalls from "@/views/crm/views/ring-central/dashboard-calls/dashboard.router.js";

import CourtInfoRouter from "@/views/crm/views/cour-info/router/ds-court-info.router";

import MOFRouter from "@/views/crm/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/crm/views/recruitment-process/crm-recruitment_process.router";
import CustomerTicketsRouter from "@/views/crm/views/customer-tickets/crm-customer-tickets.router";
import CallImpressionRouter from "@/views/crm/views/call-impression/router/call-impression.router.js";
import SMSInboxRouter from "@/views/crm/views/ring-central/sms-inbox/chat-sms.router.js";

const routes = [
  {
    path: "/crm",
    redirect: { name: "dashboard-crm" },
    meta: {
      module: 2,
    },
    component: CrmMain,
    children: [
      ...calendarRoute,
      ...leadRoute,
      ...clientsRoute,
      SalesMadeRoute,
      ...commissionsRoute,
      ...LoansRoute,
      ...viewDashboard,
      ...PaymentsRoute,
      ...ListsRoute,
      FileManangerRoute,
      ...RequestNcrRouter,
      HelpdeskRoute,
      ...SettingsRoute,
      ReportsRouter,
      ...ProductRequestRoute,
      ...manageCommissionsRouter,
      ...CrmAttendanceRouter,
      ...OtherPaymentsRouter,
      ...EmployeeClaimsRouter,
      //...crmProjectsRouter,
      ...CallLogRouter,
      ...CourtInfoRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...DashboardCalls,

      ...CustomerTicketsRouter,
      ...CallImpressionRouter,
      ...SMSInboxRouter,
    ],
  },
];

export default routes;
