export default [
  {
    path: "sending-creditors",
    name: "correspondence-sending-creditors",
    redirect: { name: "correspondence-sending-creditors-in-proccess" },
    component: () => import(/* webpackChunkName: "CorrespondenceSendingCreditors" */ "@/views/correspondence/views/sending-creditors/view/SendingCreditors.vue"),
    meta: {
      pageTitle: "Sending to Creditors",
      route: "correspondence",
      breadcrumb: [
        {
          text: "Sending Creditors",
          active: true,
        },
      ],

    },
    children: [
      {
        path: "in-proccess",
        component: () => import(/* webpackChunkName: "CorrespondenceSendingCreditorsInProccess" */ "@/views/correspondence/views/sending-creditors/view/components/Grid.vue"),
        name: "correspondence-sending-creditors-in-proccess",
        route: "correspondence",
        meta: {
          pageTitle: "Sending to Creditors",
          breadcrumb: [
            {
              text: "In Proccess",
              active: true,
            },
          ],
          inProccessTab:
            "correspondence-sending-creditors-in-proccess",
          sentTab: "correspondence-sending-creditors-sent",
          notSentTab: "correspondence-sending-creditors-not-sent",


        },
      },
      {
        path: "sent",
        component: () => import(/* webpackChunkName: "CorrespondenceSendingCreditorsSent" */ "@/views/correspondence/views/sending-creditors/view/components/Grid.vue"),
        name: "correspondence-sending-creditors-sent",
        route: "correspondence",
        meta: {
          pageTitle: "Sending to Creditors",
          breadcrumb: [
            {
              text: "Sent",
              active: true,
            },
          ],
          inProccessTab:
            "correspondence-sending-creditors-in-proccess",
          sentTab: "correspondence-sending-creditors-sent",
          notSentTab: "correspondence-sending-creditors-not-sent",

        },
      },
      {
        path: "not-sent",
        component: () => import(/* webpackChunkName: "CorrespondenceSendingCreditorsNotSent" */ "@/views/correspondence/views/sending-creditors/view/components/Grid.vue"),
        name: "correspondence-sending-creditors-not-sent",
        route: "correspondence",
        meta: {
          pageTitle: "Sending to Creditors",
          breadcrumb: [
            {
              text: "Not Sent",
              active: true,
            },
          ],
          inProccessTab:
            "correspondence-sending-creditors-in-proccess",
          sentTab: "correspondence-sending-creditors-sent",
          notSentTab: "correspondence-sending-creditors-not-sent",

        },
      },
    ],
  },
];
