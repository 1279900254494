export default
    {
        path: "vendors",
        name: "vendors-logistic",
        component: () => import(/* webpackChunkName: "LogisticVendors" */ "@/views/logistic/views/vendors/Vendors.vue"),
        meta: {
            isClientsTab: true,
            pageTitle: "Vendors",
            breadcrumb: [
                {
                    text: "Vendors",
                    active: true,
                },
            ],
        },
    };

