// import SchedulesNavigation from "@/views/rrhh/views/schedules/schedules.nav"
import salaryRecordNavigation from '@/views/rrhh/views/salary-record/salary-record.navigation.js';
import EmployeesNavigation from '@/views/rrhh/views/employees/employees.navigation';
import FileManagerNavigation from '@/views/rrhh/views/file-manager/file-manager.nav';
import LoansNavigation from '@/views/rrhh/views/loans/loans.nav';
import HelpdeskNavigation from '@/views/rrhh/views/helpdesk/helpdesk.nav';
import ProductRequestkNavigation from '@/views/rrhh/views/request/request.nav.js';
import QrGeneratorNavigation from '@/views/rrhh/views/qr-generator/qr-generator.navigation';
import RrhhAttendanceNavigation from '@/views/rrhh/views/attendance/rrhh-attendance.navigation';
import RrhhPayrollPaymentsNavigation from '@/views/rrhh/views/payroll-payments/rrhh-payroll-payments.navigation';
import SettingsNavigation from '@/views/rrhh/views/settings/setting.nav.js';
import EmployeeClaimsNavigation from '@/views/rrhh/views/employee-claims/navigation/employee-claims.nav';
import DashboardNavigation from '@/views/rrhh/views/dashboard/dashboard.navigation.js';
//import RrhhProjectsNavigation from '@/views/rrhh/views/projects/rrhh-projects.navigation';
import CallLogNavigation from '@/views/rrhh/views/call-log/call-log.nav.js';
import organizationChartNavigation from '@/views/rrhh/views/organization-chart/organization-chart.navigation';
import essentialDocuments from '@/views/rrhh/views/essential-documents/navigation/essential-documents.nav';
import PsycholaboralEvaluationSettings from '@/views/rrhh/views/psycholaboral-evaluation-settings/navigation/psycholaboral-evaluation-settings.nav';

import ManualOfFunctionsNavigation from '@/views/rrhh/views/manual-of-functions/manual-of-functions.nav';
import RecruitmentProcessNavidation from '@/views/rrhh/views/recruitment-process/rrhh-recruitment_process.navigation';
import CustomerTicketsNavigation from '@/views/rrhh/views/customer-tickets/rrhh-customer-tickets.navigation';

const navigation = [
  {
    header: 'RRHH',
  },
  ...DashboardNavigation,
  // ...SchedulesNavigation,

  ...salaryRecordNavigation,
  ...EmployeesNavigation,
  ...FileManagerNavigation,
  ...LoansNavigation,
  HelpdeskNavigation,
  ProductRequestkNavigation,
  QrGeneratorNavigation,
  RrhhAttendanceNavigation,
  //RrhhProjectsNavigation,
  RrhhPayrollPaymentsNavigation,
  // EmployeeClaimsNavigation,
  organizationChartNavigation,
  SettingsNavigation,
  ...CallLogNavigation,
  RecruitmentProcessNavidation,
  ...ManualOfFunctionsNavigation,
  essentialDocuments,
  PsycholaboralEvaluationSettings,
  CustomerTicketsNavigation,
];

export default navigation;
