export default [
  {
    path: 'users',
    name: 'paragon-users-users',
    redirect: { name: "paragon-users-active" },
    component: () => import(/* webpackChunkName: "PRUsers" */ '@/views/paragon/views/users/Users.vue'),
    meta: {
      eng_soft: true,
      isClientsTab: true,
      permittedRoles: [],
      pageTitle: 'Users',
      route: 'ti/users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "paragon-users-active",
        component: () =>
          import(/* webpackChunkName: "PRUsersActive" */ "@/views/users/views/users/components/TabActive.vue"),
        meta: {
          isActive: 1,
          permittedRoles: [],
          eng_soft: true,
          route: 'ti/users',
          pageTitle: "Users",
          breadcrumb: [
            {
              text: "Active",
              active: true,
            },
          ],
        },
      },
      {
        path: "inactive",
        name: "paragon-users-inactive",
        component: () =>
          import(/* webpackChunkName: "PRUsersInactive" */ "@/views/users/views/users/components/TabActive.vue"),
        meta: {
          isActive: 0,
          permittedRoles: [],
          eng_soft: true,
          route: 'ti/users',
          pageTitle: "Users",
          breadcrumb: [
            {
              text: "Inactive",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: 'users',
    name: 'users-crud-paragon',
    redirect: { name: "paragon-users-active" },
    component: () => import(/* webpackChunkName: "PRUsersCrudActive" */ '@/views/users/commons/users/user-view/UsersView.vue'),
    meta: {
      pageTitle: 'User',
      breadcrumb: [
        {
          text: 'Create User',
        }
      ],
      route: 'ti/users',
      layout: "horizontal"
    },
    children: [
      {
        path: "edit/:id",
        name: "users-edit-paragon",
        component: () => import(/* webpackChunkName: "PRUsersEdit" */ "@/views/users/commons/users/user-view/UsersView.vue"),
        meta: {
          tittleBtn: 'Update',
          pageTitle: "Edit User",
          route: 'ti/users',
          breadcrumb: [
            {
              text: "User",
              active: true,
            },
          ],
        },
      },
      {
        path: "create",
        name: "users-create-paragon",
        component: () => import(/* webpackChunkName: "PRUsersCreate" */ "@/views/users/commons/users/user-view/UsersView.vue"),
        meta: {
          tittleBtn: 'Save',
          pageTitle: "Create User",
          route: 'ti/users',
          breadcrumb: [
            {
              text: "User",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "users/session/:id",
    name: 'users-session-paragon',
    component: () => import(/* webpackChunkName: "PRUsersSessionId" */ "@/views/users/commons/users/table-sessions/TableSessions.vue"),
    meta: {
      pageTitle: "Access List",
      route: 'ti/users',
      breadcrumb: [
        {
          text: "Index",
          active: true,
        },
      ],
    },
  },
]