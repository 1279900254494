/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class WorkAreasService {
  async getWorkAreas(params) {
    try {
      const data = await amgApi.post('organization-chart/get-work-areas', params);
      return data;
    } catch (error) {
      console.log('Error getWorkAreas');
    }
  }

  async insertWorkArea(params) {
    try {
      const data = await amgApi.post('organization-chart/insert-work-area', params);
      return data;
    } catch (error) {
      console.log('Error insertWorkArea');
    }
  }

  async searchEmployeesByModule(params) {
    try {
      const data = await amgApi.post('organization-chart/search-employee-by-module', params);
      return data;
    } catch (error) {
      console.log('Error searchEmployeesByModule');
    }
  }

  async assignEmployees(params) {
    try {
      const data = await amgApi.post('organization-chart/assign-employees', params);
      return data;
    } catch (error) {
      console.log('Error assignEmployees');
    }
  }

  async getNodeOrganizationChart(params) {
    try {
      const data = await amgApi.post('organization-chart/get-node-organization-chart', params);
      return data;
    } catch (error) {
      console.log('Error getNodeOrganizationChart');
    }
  }

  async getDataOrganizationChart(params) {
    try {
      const data = await amgApi.post('organization-chart/get-data-organization-chart', params);
      return data;
    } catch (error) {
      console.log('Error getDataOrganizationChart');
    }
  }

  async getDataOrganizationChartGeneral(params) {
    try {
      const data = await amgApi.post('organization-chart/get-data-organization-chart-general', params);
      return data;
    } catch (error) {
      console.log('Error getDataOrganizationChartGeneral');
    }
  }

  async deleteWorkArea(params) {
    try {
      const data = await amgApi.post('organization-chart/delete-work-area', params);
      return data;
    } catch (error) {
      console.log('Error deleteWorkArea');
    }
  }

  async editWorkArea(params) {
    try {
      const data = await amgApi.post('organization-chart/edit-work-area', params);
      return data;
    } catch (error) {
      console.log('Error editWorkArea');
    }
  }

  async getMof(params) {
    try {
      const data = await amgApi.post('manuals-of-functions/get-mofs', params);
      return data;
    } catch (error) {
      console.log('Error editWorkArea');
    }
  }

  async removeWorkAreaEmployee(params) {
    try {
      const data = await amgApi.post('organization-chart/remove-work-area-employee', params);
      return data;
    } catch (error) {
      console.log('Error removeWorkAreaEmployee');
    }
  }

  async getRoles(params) {
    try {
      const data = await amgApi.post('organization-chart/get-roles', params);
      return data;
    } catch (error) {
      console.log('Error getRoles');
    }
  }

  async getMember(params) {
    try {
      const data = await amgApi.post('organization-chart/get-member', params);
      return data;
    } catch (error) {
      console.log('Error getMember');
    }
  }
}
export default new WorkAreasService();
