export default [
  {
    path: "success",
    name: "connection-success",
    redirect: { name: "connection-loyal-grid" },
    component: () =>
      import(
        /* webpackChunkName: "ConnectionSuccess" */
        "@/views/ce-digital/sub-modules/connection/views/success/Success.vue"
      ),
    meta: {
      module: 20,
      pageTitle: "Success",
      permittedRoles: [1, 2, 14, 15, 17],
      breadcrumb: [
        {
          text: "Success",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "loyal",
        name: "connection-loyal-grid",
        component: () =>
          import(
            /* webpackChunkName: "ConnectionLoyalGrid" */
            "@/views/commons/components/success/components/SuccessGrid.vue"
          ),
        meta: {
          module: 20,
          pageTitle: "Loyal Success",
          permittedRoles: [1, 2, 14, 15, 17],
          breadcrumb: [
            {
              text: "Loyal Success",
              active: true,
            },
          ],
          status: 'LOYAL_SUCCESS',
        },
      },
      {
        path: "hold",
        name: "connection-hold-grid",
        component: () =>
          import(
            /* webpackChunkName: "ConnectionHoldGrid" */
            "@/views/commons/components/success/components/SuccessGrid.vue"
          ),
        meta: {
          module: 20,
          pageTitle: "Hold Success",
          permittedRoles: [1, 2, 14, 15, 17],
          breadcrumb: [
            {
              text: "Hold Success",
              active: true,
            },
          ],
          status: 'HOLD_SUCCESS',
        },
      },
    ],
  },
];
