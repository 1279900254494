import OfferNotificationService from "@/service/offer-notification";

export default {
  namespaced: true,
  state: {
    S_OFFER_NOTIFICATION: { unread_notifications: 0, settlements: [] },
    S_ALL_OFFER_NOTIFICATIONS: [],
    S_LETTER_PUSHER: false,
    S_LETTER_DATA: [],
    S_LETTER_CLIENT_ALERT: {},
  },
  getters: {
    G_OFFER_NOTIFICATION_COUNTER(state) {
      return state.S_OFFER_NOTIFICATION.unread_notifications;
    },
  },
  mutations: {
    M_OFFER_NOTIFICATION(state, payload) {
      state.S_OFFER_NOTIFICATION = payload;
    },
    SET_ALL_OFFER_NOTIFICATIONS(state, payload) {
      state.S_ALL_OFFER_NOTIFICATIONS = payload;
    },
  },
  actions: {
    async A_GET_OFFER_NOTIFICATIONS({ commit }, body) {
      try {
        const response = await OfferNotificationService.getDsSettlementsCounterByRole(
          body
        );
        commit("M_OFFER_NOTIFICATION", { unread_notifications: response.length, settlements: response });
        return response;
      } catch (error) {
        throw error;
      }
    },

    async A_GET_ALL_OFFER_NOTIFICATIONS({ commit }, body) {
      try {
        const response =
          await OfferNotificationService.getAllSettlementNotifiactions(body);
        commit("SET_ALL_OFFER_NOTIFICATIONS", response.data);
        return response;
      } catch (error) {
        throw error;
      }
    },
  },
};
