<template>
  <div>
    <b-modal
      ref="modal"
      no-close-on-backdrop
      :title="`${status == 1 ? 'Request' : 'Revoke'} Fidelized:  ${
        customerTicket.name_client
      }`"
      :header-bg-variant="status == 1 ? 'primary' : 'warning'"
      title-class="h4 text-white font-weight-bolder"
      centered
      size="alert"
      @hidden="$emit('closemodal')"
    >
      <validation-observer ref="observer">
        <b-row class="my-1">
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:500"
              name="osbservsation"
            >
              <b-form-group label="Observation">
                <b-form-textarea
                  id="textarea-rows"
                  v-model="observations"
                  placeholder="Write observation"
                  rows="10"
                  no-resize
                  :state="errors[0] ? false : null"
                />
              </b-form-group>
              <small v-if="errors[0]" class="text-danger">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <b-button
          size="sm"
          :variant="status == 1 ? 'primary' : 'warning'"
          @click="requestTicketFidelized()"
        >
          {{ status == 1 ? "Request" : "Revoke" }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    customerTicket: {
      type: Object,
      default: null,
      required: true,
    },
    status: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      observations: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async mounted() {
    this.toggleModal("modal");
  },
  methods: {
    async requestTicketFidelized() {
      try {
        this.addPreloader();
        const data = {
          userId: this.currentUser.user_id,
          ticketId: this.customerTicket.id,
          observations: this.observations,
          status: this.status,
        };
        await CustomerTicketsService.requestTicketFidelized(data);
        this.showSuccessSwal(
          "Success",
          `The ticket has been ${
            this.status == 1 ? "requested" : "revoked"
          } fidelized`
        );
        this.$emit("save");
      } catch (error) {
        throw error;
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
