var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"NEW SMS","size":"md","centered":"","ok-title":"Save","hide-footer":""},on:{"hidden":function($event){return _vm.$emit('hidden')}},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('validation-observer',{ref:"form"},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"From:"}},[_c('div',{staticClass:"d-flex",staticStyle:{"margin-top":"5px"}},[_c('span',{staticStyle:{"margin-right":"5px"}},[_vm._v("Lead")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.optionSearch),callback:function ($$v) {_vm.optionSearch=$$v},expression:"optionSearch"}}),_c('span',[_vm._v("Number")])],1)])],1),(!_vm.optionSearch)?_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"rules":"customRequired"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Search:"}},[_c('vue-autosuggest',{ref:"autocomplete",attrs:{"id":"leads","suggestions":_vm.filteredOptions,"get-suggestion-value":_vm.getSuggestionValue,"input-props":{
                id: 'autosuggest__input',
                class: ['form-control ', errors[0]],
                placeholder: 'Search by Name, Phone number',
              }},on:{"input":_vm.onInputChange,"selected":_vm.selectHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.lead_name ? suggestion.item.lead_name : suggestion.item.nickname))]),_vm._v(" - "),_c('span',[_vm._v(_vm._s(suggestion.item.mobile))])]}}],null,true),model:{value:(_vm.searchTxt),callback:function ($$v) {_vm.searchTxt=$$v},expression:"searchTxt"}},[_c('template',{slot:"before-input"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingAutosuggest),expression:"loadingAutosuggest"}],staticClass:"lds-dual-ring"})])],2)],1)]}}],null,false,1200277577)})],1):_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Phone Number:"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(###) ###-####'),expression:"'(###) ###-####'"}],class:errors[0] ? 'is-invalid' : '',attrs:{"id":"to-phone","trim":"","maxlength":"14"},model:{value:(_vm.toNumber),callback:function ($$v) {_vm.toNumber=$$v},expression:"toNumber"}})],1)]}}])})],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$emit('hidden')}}},[_vm._v(" Cancel ")]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":_vm.storeChat}},[_vm._v(" Create ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }