import store from '@/store';
import Vue from "vue";

const changeStatusLead = () => {
    window.socket.bind('change-status-lead', async data => {
        const sessionId = store.state.auth.currentUser.user_id
        if (data.receiving_user == sessionId) {
            const result = await Vue.swal.fire({
                icon: "success",
                html: `You have a new lead in the closed pending status!<br><h2 style="font-weight: 600;">${data.lead_name}</h2>`,
                showConfirmButton: true,
            })
            if (result.value) {
                await window.amgApi.post('/commons/close-all-swal', data)
            }
        }
    })
}
export default changeStatusLead