export default [
  {
    path: 'calendar',
    name: 'calendar-ce-sales',
    component: () => import('./default.vue'),
    redirect: { name: 'sales-calendar-appointment' },
    children: [
      {
        path: '',
        redirect: { name: 'sales-calendar-appointment' },
      },
      {
        path: 'appointment',
        component: () => import('@/views/crm/views/calendar/components/appointment/default.vue'),
        name: 'sales-calendar-appointment',
        meta: {
          module: 26,
          moduleId: 26,
          permittedRoles: [1, 2, 5, 14, 15, 17],
          pageTitle: 'Calendar',
          breadcrumb: [
            {
              text: 'Appointments',
              active: true,
            },
          ],
        },
      },
      {
        path: 'task',
        component: () => import('@/views/crm/views/calendar/components/task/default.vue'),
        name: 'sales-calendar-task',
        meta: {
          module: 26,
          moduleId: 26,
          permittedRoles: [1, 2, 5, 14, 15, 17],
          pageTitle: 'Calendar',
          breadcrumb: [
            {
              text: 'Tasks',
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      module: 26,
      moduleId: 26,
      permittedRoles: [1, 2, 5,14, 15, 17],
      pageTitle: 'Calendar',
      breadcrumb: [
        {
          text: 'Calendar',
        },
      ],
    },
  },
]
