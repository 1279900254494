var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0 border-0",attrs:{"no-body":""}},[_c('filter-slot',{attrs:{"filter":_vm.isAdvisor,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['refListTable'].refresh()},"reset-all-filters":function($event){return _vm.$refs.refListTable.refresh()}}},[_c('b-table',{ref:"refListTable",staticClass:"position-relative table-new-customization",attrs:{"slot":"table","primary-key":"id","empty-text":"No matching records found","select-mode":"multi","responsive":"sm","table-class":"text-nowrap","sticky-header":"68vh","small":"","sort-icon-left":"","show-empty":"","current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"fields":_vm.fields,"items":_vm.myProvider,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.isBusy},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:busy":function($event){_vm.isBusy=$event},"row-selected":_vm.onRowSelected},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(lead_name)",fn:function(data){return [_c('div',[_c('router-link',{class:[_vm.textLink],attrs:{"target":"_blank","to":{
                name: _vm.pathNameDashboard,
                params: {
                  id: data.item.id,
                },
                props: { programsAll: _vm.programsAll },
              }}},[_c('span',[_vm._v(_vm._s(data.item.lead_name))])])],1),(data.item.user_responsible_date)?_c('div',{staticClass:"text-warning font-weight-bolder"},[_c('feather-icon',{staticStyle:{"margin-right":"3px"},attrs:{"icon":"StarIcon"}}),_vm._v(" CEO's client ")],1):_vm._e()]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-badge',{staticClass:"text-capitalize w-75",attrs:{"pill":"","variant":("light-" + (_vm.resolveUserStatusVariant(data.item.status)))}},[_vm._v(_vm._s(data.item.status))]),_c('p')],1)]}},{key:"cell(credit_report)",fn:function(data){return [_c('div',[_c('strong',{class:("text-" + (data.item.credit_report == 1 ? 'danger' : 'success'))},[_vm._v(_vm._s(data.item.credit_report == 1 ? "NO" : "YES"))])])]}},{key:"cell(programs)",fn:function(data){return [(data.item.programs)?_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"0.5rem"}},[_vm._l((JSON.parse(data.item.programs)),function(program,key){return [_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(program.value),expression:"program.value",modifiers:{"hover":true,"top":true}}],key:key,staticStyle:{"width":"40px"},attrs:{"thumbnail":"","fluid":"","src":_vm.getProgramImageRoute(program.id)}})]})],2):_vm._e()]}},{key:"cell(created_at)",fn:function(data){return [_c('div',[_c('div',[_vm._v(_vm._s(data.item.owner))]),_c('small',[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.created_at)))])])]}},(_vm.currentUser.role_id < 4 || _vm.isTeamLeader || _vm.isAgent || _vm.isChief)?{key:"cell(actions)",fn:function(data){return [(
              data.item.user_responsible == _vm.currentUser.user_id ||
              data.item.user_responsible == null
            )?_c('div',[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Delete'),expression:"'Delete'",modifiers:{"hover":true,"top":true}}],staticClass:"text-danger cursor-pointer",attrs:{"icon":"TrashIcon","size":"22"},on:{"click":function($event){return _vm.onRowDelete(data.item)}}})],1):_vm._e()]}}:null],null,true)})],1)],1),(_vm.modalAddProgramsState)?_c('modal-add-programs',{attrs:{"sales-client":_vm.salesClient,"current-user":_vm.currentUser,"programs-all":_vm.programsAll,"users-services":_vm.servicesUsers,"newLead":true},on:{"hideModal":_vm.closeModalAddPrograms,"changeProgram":_vm.openModalProgram,"leadEvent":_vm.leadEvent}}):_vm._e(),(_vm.derivationActionModal)?_c('derivation-action-modal',{attrs:{"derivation-data":_vm.derivationData},on:{"send":_vm.updateRow,"closeModal":_vm.closeDerivationActionModal}}):_vm._e(),(_vm.derivationTrackingModal)?_c('derivation-tracking-modal',{attrs:{"derivation-data":_vm.derivationData},on:{"closeModal":_vm.closeDerivationTrackingModal}}):_vm._e(),(_vm.modalListCR)?_c('modal-list-c-r',{attrs:{"lead":_vm.lead},on:{"hidden":function($event){_vm.modalListCR = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }