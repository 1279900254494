<template>
  <b-modal
    v-model="onControl"
    hide-footer
    modal-class="custom-modal-amg"
    centered
    size="sm"
    header-class="p-0"
    header-bg-variant="transparent"
    header-text-variant="primary"
    @hidden="close"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <feather-icon icon="PlusIcon" class="text-white" size="22" />
          <span> New Folder</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--white' : ''"
          @click="close()"
        >
          <feather-icon icon="XIcon" size="18" class="text-primary pointer" />
        </div>
      </div>
    </template>
    <b-row>
      <b-col>
        <validation-observer ref="form">
          <label>Folder name</label>
          <validation-provider
            v-slot="{ errors }"
            name="folderName"
            rules="required"
          >
            <b-form-input v-model="newFolderName" @keyup.enter="createFolder" />
            <label v-if="errors[0]" class="text-danger">
              <span>Folder name {{ errors[0] }}</span>
            </label>
          </validation-provider>
        </validation-observer>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex align-items-center justify-content-end mt-1">
        <b-button variant="custom-amg" @click="createFolder">
          <b-spinner v-if="spinnerOn" small />
          Create
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";

export default {
  props: {
    account: null,
    program_id: null,
    client_account_id: null,
  },
  data() {
    return {
      newFolderName: "",
      onControl: false,
      spinnerOn: false,
    };
  },
  created() {
    this.onControl = true;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async createFolder() {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          this.spinnerOn = true;
          const data = await ClientsOptionsServices.createFolder({
            folder: this.newFolderName,
            user_id: this.currentUser.user_id,
            account: this.account,
            program_id: this.program_id,
            idaccount: this.$route.params.idClient
              ? this.$route.params.idClient
              : this.client_account_id,
          });
          if (data.status === 200) {
            this.$emit("reload");
            this.close();
            this.spinnerOn = false;
            this.showSuccessSwal();
          }
        }
      } catch (error) {
        this.spinnerOn = true;
        this.showErrorSwal(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
  },
};
</script>

<style scoped lang="scss">
.header-modal {
  border-radius: 3px 3px 0px 0px;
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
.header-modal {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}
</style>
