import { amgApi } from "@/service/axios";

class NcrLeadsService {
  async getLeadsInProcessAdPending(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-search-in-process-ad-pending", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getLeadsInProcessAdPending:", error);
      throw error;
    }
  }

  async getLeadsSearchInProcessAdReturned(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-search-in-process-ad-returned", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getLeadsInProcessAdPending:", error);
      throw error;
    }
  }

  async getAdministrators(params, id) {
    try {
      const data = await amgApi.post("/commons/user-module/" + id, params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getAdministrators:", error);
      throw error;
    }

  }

  async getProvider(params) {
    try {
      const data = await amgApi.get("/commons/get-provider");
      return data;
    } catch (error) {
      console.log("Something went wrong on getProvider:", error);
      throw error;
    }

  }

  async getCardsUsers() {
    try {
      const data = await amgApi.get("/commons/get-cards-users");
      return data;
    } catch (error) {
      console.log("Something went wrong on getCardsUsers:", error);
      throw error;
    }

  }

  async updateAdministrator(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-update-administrator", params);
    } catch (error) {
      console.log("Something went wrong on ncrLeadsUpdateAdministrator:", error);
      throw error;
    }
  }

  async getTrackingAdministratorStatus(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-get-tracking-administrator", params);
      return data
    } catch (error) {
      console.log("Something went wrong on getTrackingAdministratorAdmin:", error);
      throw error;
    }
  }

  async getReports(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-get-credit-report", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getTrackingAdministratorAdmin:", error);
      throw error;
    }
  }

  async allStatus() {
    try {
      const data = await amgApi.get("/lead/get-all-status-letters");
      return data;
    } catch (error) {
      console.log("Something went wrong on allStatus:", error);
      throw error;
    }
  }

  async ncrLeadsProcessCreditReport(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-process-credit-report", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on ncrLeadsProcessCreditReport:", error);
      throw error;
    }
  }

  async ncrLeadsGetNewRequest(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-get-new-request", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on ncrLeadsGetNewRequest:", error);
      throw error;
    }
  }

  async ncrLeadsGetRequest(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-get-request", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on ncrLeadsGetRequest:", error);
      throw error;
    }
  }

  async ncrLeadsUploadPdf(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-upload-pdf", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on ncrLeadsUploadPdf:", error);
      throw error;
    }
  }

  async ncrLeadsUploadPdfToCancel(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-upload-file-cancel", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on ncrLeadsUploadPdfToCancel:", error);
      throw error;
    }
  }

  async ncrLeadsRejectRequest(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-reject-request", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on ncrLeadsRejectRequest:", error);
      throw error;
    }
  }

  async getLeadsSearchToCancelAd(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-search-to-cancel-ad", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getLeadsSearchToCancelAd:", error);
      throw error;
    }
  }

  async getSearchCompletedSuccessfullAd(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-search-completed-successfull-ad", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getSearchCompletedSuccessfullAd:", error);
      throw error;
    }
  }

  async getTrackingNcrLeadSmsEmail(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/get-tracking-ncr-lead", params);
      return data
    } catch (error) {
      console.log("Something went wrong on getTrackingNcrLeadSmsEmail:", error);
      throw error;
    }
  }

  async getSearchCompletedInvalidAd(params) {
    try {
      const data = await amgApi.post("/administration/ncr-leads/ncr-leads-search-completed-invalid-ad", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getSearchCompletedSuccessfullAd:", error);
      throw error;
    }
  }

  async ncrLeadsCountInProcess(params) {
    try {
      const data = await amgApi.post("/lead/ncr/ncr-leads-count-in-process", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getSearchCompletedSuccessfullAd:", error);
      throw error;
    }

  }

  async getTpCardsCrm(params) {
    try {
      const data = await amgApi.post("/cards/search-tp-cards-crm", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getTpCardsCrm:", error);
      throw error;
    }
  }

  async getTpCards(params) {
    try {
      const data = await amgApi.post("/cards/search-tp-cards", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getTpCards:", error);
      throw error;
    }
  }

  async getAllStatusClientAccount(params) {
    try {
      const data = await amgApi.post("/commons/get-all-status-client-account", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getAllStatusClientAccount:", error);
      throw error;
    }
  }


}

export default new NcrLeadsService();
