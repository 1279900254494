var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"header-text-variant":"white","header-bg-variant":"info","header-class":"p-0","body-class":"pt-1 card-body-border"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"pl-1 pt-1"},[_c('p',{staticClass:"font-weight-bolder"},[_vm._v("Cost of living")])])]},proxy:true}])},[_c('validation-observer',{ref:"formServices"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"options":_vm.optionsUtilities,"label":"text","multiple":"","placeholder":"Utilities","disabled":_vm.readOnly,"close-on-select":false},model:{value:(_vm.hardship.utilities),callback:function ($$v) {_vm.$set(_vm.hardship, "utilities", $$v)},expression:"hardship.utilities"}})],1)],1),_c('b-row',[_vm._l((_vm.hardship.utilities),function(service,index){return _c('b-col',{key:service.id,staticClass:"mt-1",attrs:{"xl":"2","lg":"2","md":"3","sm":"3","cols":"4"}},[_c('div',[_c('validation-provider',{attrs:{"rules":"validate-amount-zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(service.text))]),_c('money',_vm._b({staticClass:"form-control",attrs:{"id":("inp-" + index),"disabled":_vm.readOnly},model:{value:(service.mont),callback:function ($$v) {_vm.$set(service, "mont", $$v)},expression:"service.mont"}},'money',_vm.vMoney,false)),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])}),_c('b-col',{staticClass:"mt-1",attrs:{"xl":"2","lg":"2","md":"3","sm":"3","cols":"6"}},[_c('label',[_vm._v("Total Utilities")]),_c('div',{staticClass:"form-control d-flex align-items-center justify-content-start bg-transparent border"},[_vm._v(" $ "+_vm._s(_vm._f("currencyZero")(_vm.totalUtilities))+" ")])])],2),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"xl":"4","lg":"4","md":"4","sm":"4","cols":"4"}},[_c('div',[_c('validation-provider',{attrs:{"rules":"validate-amount-zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Food(expenses in store or eating outside)")]),_c('money',_vm._b({staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":"food","disabled":_vm.readOnly},model:{value:(_vm.hardship.food),callback:function ($$v) {_vm.$set(_vm.hardship, "food", $$v)},expression:"hardship.food"}},'money',_vm.vMoney,false)),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('b-col',{staticClass:"mt-1",attrs:{"xl":"2","lg":"2","md":"3","sm":"3","cols":"4"}},[_c('div',[_c('validation-provider',{attrs:{"rules":"validate-amount-zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Insurance")]),_c('money',_vm._b({staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":"insurance","disabled":_vm.readOnly},model:{value:(_vm.hardship.insurance),callback:function ($$v) {_vm.$set(_vm.hardship, "insurance", $$v)},expression:"hardship.insurance"}},'money',_vm.vMoney,false)),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[(!_vm.readOnly)?_c('b-button',{attrs:{"disabled":_vm.readOnly,"variant":"success","size":"sm"},on:{"click":function($event){return _vm.addCards()}}},[_vm._v(" ADD CARDS "),_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1):_vm._e()],1)],1),_c('b-row',{staticClass:"mt-2"},_vm._l((_vm.hardship.cards),function(item,index){return _c('b-col',{key:index,attrs:{"cols":"2"}},[_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"30px"},attrs:{"cols":"3"}},[_c('span',{staticClass:"p_desing"},[_c('feather-icon',{attrs:{"icon":"CreditCardIcon"}})],1)]),_c('b-col',{attrs:{"cols":"9"}},[_c('feather-icon',{staticClass:"float-right text-danger cursor-pointer",attrs:{"icon":"XSquareIcon"},on:{"click":function($event){return _vm.deleteCards(item.id)}}}),_c('validation-provider',{attrs:{"rules":"validate-amount-zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":""}},[_vm._v("Pay")]),_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"item.id","disabled":_vm.readOnly},model:{value:(item.pay),callback:function ($$v) {_vm.$set(item, "pay", $$v)},expression:"item.pay"}},'money',_vm.vMoney,false)),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[(!_vm.readOnly)?_c('b-button',{attrs:{"disabled":_vm.hardship.cars.length >= 5 || _vm.readOnly,"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.addCar()}}},[_vm._v(" CAR PAYMENTS "),(_vm.hardship.cars.length < 5 && !_vm.readOnly)?_c('feather-icon',{attrs:{"icon":"PlusIcon"}}):_vm._e()],1):_vm._e()],1),_vm._l((_vm.hardship.cars),function(car,index){return _c('b-col',{key:index,staticClass:"mt-1",attrs:{"xl":"2","lg":"2","md":"3","sm":"3","cols":"4"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('label',[_vm._v(_vm._s(("Car " + (index + 1))))]),(index != 0 && index == _vm.hardship.cars.length - 1)?_c('label',[(!_vm.readOnly)?_c('feather-icon',{staticClass:"cursor-pointer text-danger font-medium-2",attrs:{"icon":"XCircleIcon"},on:{"click":function($event){return _vm.hardship.cars.splice(index, 1)}}}):_vm._e()],1):_vm._e()]),_c('validation-provider',{attrs:{"rules":"validate-amount-zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control",attrs:{"id":("car-" + index),"disabled":_vm.readOnly},model:{value:(car.amount),callback:function ($$v) {_vm.$set(car, "amount", $$v)},expression:"car.amount"}},'money',_vm.vMoney,false)),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)}),_c('b-col',{staticClass:"mt-1",attrs:{"xl":"2","lg":"2","md":"3","sm":"3","cols":"4"}},[_c('validation-provider',{attrs:{"rules":"validate-amount-zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Gasoline")]),_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"gasoline","disabled":_vm.readOnly},model:{value:(_vm.hardship.gasoline),callback:function ($$v) {_vm.$set(_vm.hardship, "gasoline", $$v)},expression:"hardship.gasoline"}},'money',_vm.vMoney,false)),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],2),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"options":_vm.optionsHard,"label":"text","multiple":"","placeholder":"Others","disabled":_vm.readOnly,"close-on-select":false},model:{value:(_vm.hardship.others),callback:function ($$v) {_vm.$set(_vm.hardship, "others", $$v)},expression:"hardship.others"}})],1)],1),_c('b-row',[_vm._l((_vm.hardship.others),function(service,index){return _c('b-col',{key:service.id,staticClass:"mt-1",attrs:{"xl":"2","lg":"2","md":"3","sm":"3","cols":"4"}},[_c('div',[_c('validation-provider',{attrs:{"rules":"validate-amount-zero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(service.text))]),_c('money',_vm._b({staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":("inp-" + index),"disabled":_vm.readOnly},model:{value:(service.mont),callback:function ($$v) {_vm.$set(service, "mont", $$v)},expression:"service.mont"}},'money',_vm.vMoney,false)),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])}),_c('b-col',{staticClass:"mt-1",attrs:{"xl":"2","lg":"2","md":"3","sm":"3","cols":"6"}},[_c('label',[_vm._v("Total Others")]),_c('div',{staticClass:"form-control d-flex align-items-center justify-content-start bg-transparent border"},[_vm._v(" $ "+_vm._s(_vm._f("currencyZero")(_vm.totalHardship))+" ")])])],2),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"xl":"4","lg":"4","md":"4","sm":"4","cols":"4"}},[_c('div',[_c('label',[_vm._v("Total monthly living cost")]),_c('div',{staticClass:"form-control d-flex align-items-center justify-content-start bg-transparent border"},[_vm._t("total-month")],2)])]),_c('b-col',{staticClass:"mt-1",attrs:{"xl":"2","lg":"2","md":"3","sm":"3","cols":"4"}},[_c('div',[_c('label',[_vm._v("Amount available")]),_vm._t("amount-available")],2)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }