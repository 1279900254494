var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modalTracking",attrs:{"centered":"","modal":"","size":"lg","header-class":"p-0","no-close-on-backdrop":"","header-bg-variant":"transparent border-bottom border-bottom-2","modal-class":"modal-primary","title":"TRACKING","title-class":"h2 text-white","hide-footer":""},on:{"hidden":_vm.hideModal},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('ModalHeader',{attrs:{"dataItem":_vm.dataItem,"title":'TRACKING'},on:{"close":function($event){return _vm.hideModal()}}})]},proxy:true}]),model:{value:(_vm.modalCenter),callback:function ($$v) {_vm.modalCenter=$$v},expression:"modalCenter"}},[_c('b-container',[_c('b-table',{ref:"refTrackingTable",staticClass:"position-relative font-small-3",attrs:{"slot":"table","primary-key":"id","empty-text":"No matching records found","select-mode":"multi","responsive":"sm","table-class":"text-nowrap","sticky-header":"68vh","small":"","show-empty":"","fields":_vm.visibleFields,"items":_vm.myProvider},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(created_at)",fn:function(data){return [_c('div',{staticStyle:{"paddingtop":"5px"}},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.created_at)))])])]}},{key:"cell(typesenior)",fn:function(data){return [_c('div',{staticStyle:{"paddingtop":"5px"}},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(data.item.typesenior))])])]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center align-v-center"},[_c('b-badge',{staticClass:"text-center w-100",class:data.item.status == 'PENDING'
                  ? 'bg-light-dark'
                  : data.item.status == 'APPROVED'
                  ? 'bg-light-success'
                  : data.item.status == 'DENIED' ||
                    data.item.status == 'CANCELED'
                  ? 'bg-light-danger'
                  : 'bg-light-warning',attrs:{"pill":""}},[_vm._v(" "+_vm._s(data.item.status)+" ("+_vm._s(data.item.typesenior_pending)+") ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }