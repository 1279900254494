var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"xmd","header-class":"p-0","header-bg-variant":"transparent","modal-class":"custom-modal-amg","scrollable":""},on:{"hide":function($event){return _vm.hideModal(false)}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('header-modal-notes',{attrs:{"program":"Tax Research","info":_vm.noteInfo},on:{"close":function($event){return _vm.hideModal(false)}}})]},proxy:true},{key:"modal-footer",fn:function(){return [(_vm.showButtonSave)?_c('button-save',{on:{"click":_vm.saveNotesIncomplete}}):_vm._e(),(_vm.showButtonSave)?_c('button-save-and-complete',{on:{"click":_vm.saveNotesCompleted}}):_vm._e(),(_vm.showButtonUpdate && _vm.noteInfo.editModal)?_c('button-update',{on:{"click":_vm.updateNotesCompleted}}):_vm._e()]},proxy:true}]),model:{value:(_vm.modalUp),callback:function ($$v) {_vm.modalUp=$$v},expression:"modalUp"}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('b-form-group',{attrs:{"label":"Marital Status","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"maritalStatus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{class:{ 'border-danger': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.note.maritalStatus.options},model:{value:(_vm.note.maritalStatus.value),callback:function ($$v) {_vm.$set(_vm.note.maritalStatus, "value", $$v)},expression:"note.maritalStatus.value"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Dependents","label-class":"font-weight-bolder","disabled":_vm.disabled}},[_c('validation-provider',{attrs:{"name":"dependents","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],class:{ 'border-danger rounded': errors[0] },attrs:{"type":"number"},model:{value:(_vm.note.dependents.value),callback:function ($$v) {_vm.$set(_vm.note.dependents, "value", $$v)},expression:"note.dependents.value"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"migrationSituation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Migratory Situation","label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.note.migrationSituation.options},model:{value:(_vm.note.migrationSituation.value),callback:function ($$v) {_vm.$set(_vm.note.migrationSituation, "value", $$v)},expression:"note.migrationSituation.value"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"bankruptcy","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Bankruptcy","label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.note.bankruptcy.options},model:{value:(_vm.note.bankruptcy.value),callback:function ($$v) {_vm.$set(_vm.note.bankruptcy, "value", $$v)},expression:"note.bankruptcy.value"}})],1)]}}])})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.note.bankruptcy.value === 'YES')?_c('b-col',[_c('validation-provider',{attrs:{"name":"bankruptcyYear","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Bankruptcy Year","label-class":"font-weight-bolder"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],class:{ 'border-danger': errors[0] },attrs:{"disabled":_vm.disabled,"type":"number"},model:{value:(_vm.note.bankruptcy.bankruptcyYear),callback:function ($$v) {_vm.$set(_vm.note.bankruptcy, "bankruptcyYear", $$v)},expression:"note.bankruptcy.bankruptcyYear"}})],1)]}}],null,false,1098434069)})],1):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.note.bankruptcy.value === 'YES')?_c('b-col',[_c('validation-provider',{attrs:{"name":"bankruptcyChapter","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Chapter","label-class":"font-weight-bolder"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],class:{ 'border-danger': errors[0] },attrs:{"disabled":_vm.disabled,"type":"number"},model:{value:(_vm.note.bankruptcy.chapter),callback:function ($$v) {_vm.$set(_vm.note.bankruptcy, "chapter", $$v)},expression:"note.bankruptcy.chapter"}})],1)]}}],null,false,2025140725)})],1):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"taxYearsPending","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tax Years pending","label-class":"font-weight-bolder"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('####'),expression:"'####'"}],class:{ 'border-danger': errors[0] },attrs:{"disabled":_vm.disabled,"type":"number"},model:{value:(_vm.note.taxYearsPending.value),callback:function ($$v) {_vm.$set(_vm.note.taxYearsPending, "value", $$v)},expression:"note.taxYearsPending.value"}})],1)]}}])})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Origin Country","label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"clearable":false,"disabled":_vm.disabled,"label":"name","reduce":function (value) { return value.id; },"options":_vm.note.country.options},model:{value:(_vm.note.country.value),callback:function ($$v) {_vm.$set(_vm.note.country, "value", $$v)},expression:"note.country.value"}})],1)],1),(_vm.dateTypeAgreement)?_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"typeOfAgreement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type of Agreement","label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.note.typeOfAgreement.options},model:{value:(_vm.note.typeOfAgreement.value),callback:function ($$v) {_vm.$set(_vm.note.typeOfAgreement, "value", $$v)},expression:"note.typeOfAgreement.value"}})],1)]}}],null,false,2352462322)})],1):_vm._e(),(_vm.isAfterLastDeploy)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Pending","label-class":"font-weight-bolder"}},[_c('b-form-checkbox-group',{attrs:{"options":_vm.note.pending.options},model:{value:(_vm.note.pending.value),callback:function ($$v) {_vm.$set(_vm.note.pending, "value", $$v)},expression:"note.pending.value"}})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"information","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Information","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.information.value),callback:function ($$v) {_vm.$set(_vm.note.information, "value", $$v)},expression:"note.information.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"indications","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Indications","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.indications.value),callback:function ($$v) {_vm.$set(_vm.note.indications, "value", $$v)},expression:"note.indications.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"suggestions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Suggestions","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.suggestion.value),callback:function ($$v) {_vm.$set(_vm.note.suggestion, "value", $$v)},expression:"note.suggestion.value"}})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }