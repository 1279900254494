var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"xmd","header-class":"p-0","header-bg-variant":"transparent","modal-class":"custom-modal-amg","scrollable":""},on:{"hide":function($event){return _vm.hideModal(false)}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('header-modal-notes',{attrs:{"program":"Bussiness","info":_vm.noteInfo},on:{"close":function($event){return _vm.hideModal(false)}}})]},proxy:true},{key:"modal-footer",fn:function(){return [(_vm.showButtonSave)?_c('button-save',{on:{"click":_vm.saveNotesIncomplete}}):_vm._e(),(_vm.showButtonSave)?_c('button-save-and-complete',{on:{"click":_vm.saveNotesCompleted}}):_vm._e(),(_vm.showButtonUpdate && _vm.noteInfo.editModal)?_c('button-update',{on:{"click":_vm.updateNotesCompleted}}):_vm._e()]},proxy:true}]),model:{value:(_vm.modalUp),callback:function ($$v) {_vm.modalUp=$$v},expression:"modalUp"}},[(_vm.showNewVersion)?_c('div',{staticClass:"d-flex justify-content-end mb-1"},[_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.$emit('showNewVersion')}}},[_vm._v(" "+_vm._s(_vm.emptyNoteNewVersion ? "Updated New Version" : "Show New Version")+" ")])],1):_vm._e(),_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Tax ID"}},[_c('validation-provider',{attrs:{"name":"taxId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.note.taxId.options,"disabled":_vm.disabled,"clearable":false},model:{value:(_vm.note.taxId.value),callback:function ($$v) {_vm.$set(_vm.note.taxId, "value", $$v)},expression:"note.taxId.value"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Contact Time"}},[_c('validation-provider',{attrs:{"name":"contactTime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.note.contactTime.options,"disabled":_vm.disabled,"clearable":false},model:{value:(_vm.note.contactTime.value),callback:function ($$v) {_vm.$set(_vm.note.contactTime, "value", $$v)},expression:"note.contactTime.value"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"Work Status"}},[_c('validation-provider',{attrs:{"name":"workStatus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.note.workStatus.options,"disabled":_vm.disabled,"clearable":false},model:{value:(_vm.note.workStatus.value),callback:function ($$v) {_vm.$set(_vm.note.workStatus, "value", $$v)},expression:"note.workStatus.value"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Type of Business"}},[_c('validation-provider',{attrs:{"name":"typeOfBusiness","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.note.typeOfBuisiness.options,"disabled":_vm.disabled,"clearable":false},model:{value:(_vm.note.typeOfBuisiness.value),callback:function ($$v) {_vm.$set(_vm.note.typeOfBuisiness, "value", $$v)},expression:"note.typeOfBuisiness.value"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Emergency Contact"}},[_c('validation-provider',{attrs:{"name":"emergencyContact","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"disabled":_vm.disabled},model:{value:(_vm.note.emergencyContact.value),callback:function ($$v) {_vm.$set(_vm.note.emergencyContact, "value", $$v)},expression:"note.emergencyContact.value"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Is It New?"}},[_c('validation-provider',{attrs:{"name":"isItNew","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.note.isItNew.options,"disabled":_vm.disabled,"clearable":false},model:{value:(_vm.note.isItNew.value),callback:function ($$v) {_vm.$set(_vm.note.isItNew, "value", $$v)},expression:"note.isItNew.value"}})]}}])})],1)],1),(_vm.note.isItNew.value === 'NO')?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Have The Client Registered His/Her Business?"}},[_c('validation-provider',{attrs:{"name":"registeredBusiness","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.note.registeredBusiness.options,"disabled":_vm.disabled,"clearable":false},model:{value:(_vm.note.registeredBusiness.value),callback:function ($$v) {_vm.$set(_vm.note.registeredBusiness, "value", $$v)},expression:"note.registeredBusiness.value"}})]}}],null,false,1675735786)})],1)],1):_vm._e(),(
          _vm.note.isItNew.value === 'NO' &&
          _vm.note.registeredBusiness.value === 'YES'
        )?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"How did the client register it?"}},[_c('validation-provider',{attrs:{"name":"howDidTheClientRegistered","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.note.howDidTheClientRegistered.options,"disabled":_vm.disabled,"clearable":false},model:{value:(_vm.note.howDidTheClientRegistered.value),callback:function ($$v) {_vm.$set(_vm.note.howDidTheClientRegistered, "value", $$v)},expression:"note.howDidTheClientRegistered.value"}})]}}],null,false,900658730)})],1)],1):_vm._e(),(
          _vm.note.isItNew.value === 'NO' &&
          _vm.note.registeredBusiness.value === 'YES'
        )?_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Company Name"}},[_c('validation-provider',{attrs:{"name":"companyName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"disabled":_vm.disabled},model:{value:(_vm.note.companyName.value),callback:function ($$v) {_vm.$set(_vm.note.companyName, "value", $$v)},expression:"note.companyName.value"}})]}}],null,false,1972469349)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"What does the client need?"}},[_c('validation-provider',{attrs:{"name":"whatDoesTheClientNeed","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.whatDoesTheClientNeed.value),callback:function ($$v) {_vm.$set(_vm.note.whatDoesTheClientNeed, "value", $$v)},expression:"note.whatDoesTheClientNeed.value"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"What did you suggest and recommend?"}},[_c('validation-provider',{attrs:{"name":"whatDidYouSuggest","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.whatDidYouSuggest.value),callback:function ($$v) {_vm.$set(_vm.note.whatDidYouSuggest, "value", $$v)},expression:"note.whatDidYouSuggest.value"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Is there any indication for the advisor?"}},[_c('validation-provider',{attrs:{"name":"isAnyIndicator","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.isAnyIndicator.value),callback:function ($$v) {_vm.$set(_vm.note.isAnyIndicator, "value", $$v)},expression:"note.isAnyIndicator.value"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"pending","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pending"}},[_c('b-form-checkbox-group',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.note.pending.options},model:{value:(_vm.note.pendingValue),callback:function ($$v) {_vm.$set(_vm.note, "pendingValue", $$v)},expression:"note.pendingValue"}})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }