<template>
  <div>
    <div class="container ml-1">
      <audio
        ref="audioPlayer"
        controls
        style="display: none"
      />
      <div
        v-if="audioURL"
        class="play-stop"
        @click="playStopReproductor()"
      >
        <feather-icon
          v-if="!$refs.audioPlayer.paused"
          icon="PlayIcon"
          size="20"
          :class="!isDarkSkin ? 'text-primary':'text-white'"
        />
        <feather-icon
          v-else
          icon="PauseIcon"
          size="20"
          :class="!isDarkSkin ? 'text-primary':'text-white'"
        />
        <svg
          v-show="audioDuration > 0"
          xmlns="http://www.w3.org/2000/svg"
          width="220"
          height="50"
          viewBox="0 0 143 22"
          fill="none"
        >
          <path
            v-for="(path, index) in 26"
            :key="index"
            :d="createSvgPath(index + 1)"
            class="wave-path"
            :stroke="!isDarkSkin ? '#0993E3':'#ffffff'"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      </div>
      <canvas
        v-show="isRecording"
        class="visualizer rounded bg-white"
        height="50px"
        width="100%"
      />

      <feather-icon
        v-if="audioURL"
        v-b-tooltip.hover.v-danger
        icon="XCircleIcon"
        size="25"
        class="text-danger cursor-pointer mr-1"
        title="CANCEL"
        @click="deleteAudio()"
      />

      <feather-icon
        v-if="audioURL"
        v-b-tooltip.hover.v-success
        icon="SendIcon"
        size="25"
        class="text-success cursor-pointer rounded-circle"
        style="padding: 3px;"
        title="SEND"
        @click="sendAudio()"
      />
    </div>
  </div>
</template>
<script>
import { Howl, Howler } from 'howler';

export default {
  data() {
    return {
      mediaRecorder: null,
      chunks: [],
      isRecording: false,
      canvas: null,
      canvasCtx: null,
      dataArray: null,
      analyser: null,
      audioCtx: null,
      audioDuration: 0,
      audioURL: null,
      audioFile: null,
      media: null,
      key: 0,
    };
  },
  mounted() {
    this.canvas = document.querySelector('.visualizer');
    this.canvasCtx = this.canvas.getContext('2d');
    this.canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.hideSvg('0');
  },
  destroyed() {
    this.mediaRecorder = null;
    this.chunks = [];
    this.isRecording = false;
    this.canvas = null;
    this.canvasCtx = null;
    this.dataArray = null;
    this.analyser = null;
    this.audioCtx = null;
    this.audioDuration = 0;
    this.audioURL = null;
    this.audioFile = null;
    if (this.media) {
      this.media.getAudioTracks().forEach(track => {
        track.stop();
      });
    }
  },

  methods: {
    sendAudio() {
      if (this.media) {
        this.media.getAudioTracks().forEach(track => {
          track.stop();
        });
      }
      this.hideSvg('0');

      this.$emit('sendAudio', this.audioURL);
    },
    deleteAudio() {
      this.audioURL = null;
      this.audioDuration = 0;
      this.mediaRecorded = false;
      this.hideSvg('0');
      this.$emit('closeAudio');
    },
    startRecording() {
      if (navigator.mediaDevices.getUserMedia) {
        const constraints = { audio: true };
        navigator.mediaDevices.getUserMedia(constraints).then(this.onSuccess);
      }
    },

    stopRecording() {
      this.mediaRecorder.stop();
      this.isRecording = false;
      this.hideSvg('0.1');
    },
    onSuccess(stream) {
      this.media = stream;
      this.mediaRecorder = new MediaRecorder(stream);
      this.visualize(stream);
      const audioController = this.$refs.audioPlayer;
      this.mediaRecorder.addEventListener('stop', () => {
        const audioBlob = new Blob(this.chunks, { type: 'audio/wav' });
        const audioURL = URL.createObjectURL(audioBlob);

        this.audioURL = audioBlob;
        this.audioFile = audioURL;
        const howlerAudio = new Howl({
          src: [audioURL],
          format: ['wav'],
          onload: () => {
            this.audioDuration = howlerAudio.duration();
          },
        });
        audioController.src = audioURL;
      });
      this.mediaRecorder.addEventListener('dataavailable', e => {
        if (this.chunks) {
          this.chunks.push(e.data);
        }
      });
      this.audioDuration = 0;
      this.chunks = [];
      this.audioURL = null;
      this.mediaRecorder.start();
      this.isRecording = true;
    },

    visualize(stream) {
      if (!this.audioCtx) {
        this.audioCtx = new AudioContext();
      }
      const source = this.audioCtx.createMediaStreamSource(stream);

      this.analyser = this.audioCtx.createAnalyser();
      this.analyser.fftSize = 512;
      this.bufferLength = this.analyser.frequencyBinCount;
      this.dataArray = new Uint8Array(this.bufferLength);

      source.connect(this.analyser);
      this.draw();
    },
    draw(color) {
      const WIDTH = this.canvas.width;
      const HEIGHT = this.canvas.height;

      requestAnimationFrame(this.draw);

      this.analyser.getByteFrequencyData(this.dataArray);

      this.canvasCtx.fillStyle = '#d0f0ff';
      this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      this.canvasCtx.lineWidth = 2;
      this.canvasCtx.strokeStyle = 'rgb(0, 0, 0)';

      this.canvasCtx.beginPath();

      //  let sliceWidth = (WIDTH * 1.0) / this.bufferLength;
      const barWidth = (WIDTH / this.bufferLength) * 2;
      let barHeight;
      let x = 0;
      for (let i = 0; i < this.bufferLength; i++) {
        barHeight = this.dataArray[i] / 4;

        this.canvasCtx.fillStyle = '#0090E7';
        this.canvasCtx.fillRect(
          x,
          (HEIGHT - barHeight) / 1.5,
          barWidth,
          barHeight,
        );

        x += barWidth + 1;
      }

      this.canvasCtx.lineTo(this.canvas.width, this.canvas.height / 2);
      this.canvasCtx.stroke();
    },

    playStopReproductor() {
      const audioController = this.$refs.audioPlayer;
      if (audioController.paused) {
        audioController.play();
        this.hideSvg('0.1');

        this.animateSvg();
      } else {
        audioController.pause();
      }
    },
    animateSvg() {
      const wavePaths = document.querySelectorAll('.wave-path');
      // with audioduration we can calculate the time of the animation
      const time = this.audioDuration * 1000;
      // set opacity gradually to 1 in 2 seconds
      for (let i = 0; i < wavePaths.length; i++) {
        setTimeout(() => {
          wavePaths[i].style.opacity = '1';
        }, (time / wavePaths.length) * i);
      }
    },
    hideSvg(opacity) {
      const wavePaths = document.querySelectorAll('.wave-path');
      for (let i = 0; i < wavePaths.length; i++) {
        wavePaths[i].style.opacity = opacity;
      }
    },
    createSvgPath(index) {
      const vInitialPoint = 8;
      if (index === 1) {
        return `M5 ${vInitialPoint}V12`;
      }
      if (index === 20) {
        return `M${index * 5} ${vInitialPoint}V12`;
      }
      if (index % 7 === 0) {
        return `M${index * 5} ${vInitialPoint - 10}V22`;
      }
      if (index % 5 === 0) {
        return `M${index * 5} ${vInitialPoint - 8}V22`;
      }
      if (index % 3 === 0) {
        return `M${index * 5} ${vInitialPoint - 6}V20`;
      }
      if (index % 2 === 0) {
        return `M${index * 5} ${vInitialPoint - 4}V16`;
      }
      return `M${index * 5} 6V16`;
    },

  },
};
</script>
<style scoped>
.container {
  display: flex;
  /* width: 100%; */
  justify-content: center;
  align-items: center;
}
.play-stop {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
