<template>
  <b-modal
    title="New Permissions Request"
    v-model="modalShow"
    centered
    no-close-on-backdrop
    @hide="$emit('close')"
  >
    <div class="d-block">
      <validation-observer ref="form">
        <b-container>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Type:">
                <b-form-input
                  type="text"
                  disabled
                  v-model="permissionType.name"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Code:">
                <b-form-input disabled v-model="request.code"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Reason:">
                <b-form-input
                  disabled
                  v-model="permissionType.reason"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Comment:">
                <b-form-input
                  type="text"
                  required
                  v-model="request.comment"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </validation-observer>
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-button
          variant="secondary"
          class="mr-1"
          @click="$emit('close')"
          >Cancel</b-button
        >
        <b-button variant="primary" @click="sendRequest"> Send Request </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import PermissionService from "@/views/commons/components/permissions/services/permission.service.js";
import { mapGetters } from "vuex";

export default {
  props: {
    typePermissionId: {
      type: Number,
      required: true,
    },
    idTable: {
      type: [Number, String],
      required: true,
    },
    leadId: {
      type: [Number, String],
      required: false,
    },
    clientAccountId: {
      type: String,
      required: false,
    },
    clientName: {
      type: String,
      required: false,
    },
    dataJson: {
      type: Array,
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      modalShow: true,
      permissionType: {
        name: "",
        reason: "",
      },
      request: {
        code: "",
        comment: "",
      },
    };
  },

  methods: {
    async getPermissionRequestType() {
      const params = {
        type_id: this.typePermissionId,
        id_table: this.idTable,
      };
      const { data } = await PermissionService.getPermissionRequestType(params);
      this.permissionType = data[0];

      this.generatePermissionCode(data[0].initial, data[0].prefix);
    },

    async sendRequest() {
      try {
        this.addPreloader();
        const params = {
          type_id: this.typePermissionId,
          user_id: this.currentUser.user_id,
          comment: this.request.comment,
          code: this.request.code,
          model_pk: this.idTable,
          lead_id: this.leadId,
          client_account_id: this.clientAccountId,
          client_name: this.clientName,
          data_json: this.dataJson,
        };

        const { data } = await PermissionService.sendPermissionRequest(params);
         this.showToast(
          "success",
          "top-right",
          "Congratulations",
          "CheckIcon",
          `You've successfully created a request`
        );

        this.$emit('updatePermission');
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    generatePermissionCode(initialModule, prefix) {
      this.request.code = initialModule + "-" + prefix + "-" + this.idTable;
    },
  },

  mounted() {
    this.getPermissionRequestType();
  },
};
</script>

<style>
</style>