import Pusher from "pusher-js"
//import DepartamentNotification
import {
  //assignDepartamentNotifications,
  DepartamentNotificationNewChargeback,
  DepartamentNotificationChargebackFileEvidenceStatus,
  NotificationDepartamentChangeStatusDispute,
  DepartamentOfDisputesentResponse,
  DepartamentOfPaymentValidation,
  NewClaimNotification,
} from "@/views/commons/socket/departament-notifications.socket.js"

const subscribeSocketDepartament = (socket) => {
  try {
    socket.subscribe("departament-notifications")
    NotificationDepartamentChangeStatusDispute()
    DepartamentNotificationNewChargeback()
    DepartamentNotificationChargebackFileEvidenceStatus()
    DepartamentOfDisputesentResponse()
    DepartamentOfPaymentValidation()
    NewClaimNotification()
  } catch (error) {
    console.error(error, "error")
  }
}
export default subscribeSocketDepartament
