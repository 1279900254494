import store from "@/store";
import Vue from "vue";
import HelpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import NotificationMixin from "@/mixins/notification";

const updateOwnerNotification = () => {
  window.socket.bind("update-owner", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id;
    if (data.to_id == sessionId) {
      // ********************** COUNTER NOTIFICATION *********************
      const params = {
        modul_id: 12,
        user_id: sessionId,
      };

      if (store.state.auth.currentUser.is_reviewer) {
        const tab = {
          user_id: 124,
          is_reviewer: 1
        }
        const { data } = await HelpdeskService.getCountTab(tab)
        store.commit("HelpdeskStore/SET_REVIEWER_COUNTER_NOTIFICATIONS", data.total)
      } else {
        const { data } = await HelpdeskService.getCountTab(params)
        store.commit("HelpdeskStore/SET_COUNTER_NOTIFICATIONS", data.total)
      }

      const response = await HelpdeskService.getCounterNotifications(params);
      if (Object.keys(response.data).length > 0) {
        const payload = {
          routeName: "helpdesk-ti",
          tag: response.data.total,
        };
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
      }
      // ********************** FIN COUNTER NOTIFICATION *********************

      const result = await Vue.swal.fire({
        icon: "success",
        html: `<h2 style="font-weight: 600;">${data.message}</h2><h3><br><b>Ticket:</b> ${data.ticket_id}</h3>`,
        showConfirmButton: true,
      });

      if (result.value) {
        await window.amgApi.post("/commons/close-all-swal", data);
      }
      NotificationMixin.methods.showWindowNotification(
        "New Message on Ticket",
        data.message,
        undefined,
        undefined,
        () => {
          window.focus();
        }
      );
    }
  });
};
export default updateOwnerNotification;
