export default {
  path: 'helpdesk',
  name: 'helpdesk-debt-solution',
  component: () => import(/* webpackChunkName: "DSHelpdesk" */ './Helpdesk.vue'),
  meta: {
    pageTitle: 'Help Desk',
    breadcrumb: [
      {
        text: 'Help Desk',
        active: true,
      },
    ],
  },
}
