<template>
  <div class="pt-1 scroll-kanban max-height-conversation">
    <CoolLightBox
      :items="itemImage"
      :index="showImage"
      :effect="'fade'"
      @close="showImage = null"
    />
    <div
      class="rounded desc-ticket py-1 px-2"
      :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
      :style="
        isDarkSkin ? 'border:1px solid #434343' : 'border:1px solid #e4eaec'
      "
    >
      <div>{{ details.description }}</div>
    </div>
    <div class="my-1 font-weight-bolder">CONVERSATION</div>
    <div
      class="mb-1 rounded card-comments"
      :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
      :style="
        isDarkSkin ? 'border:1px solid #434343' : 'border:1px solid #e4eaec'
      "
      ref="scrollList"
    >
      <template v-for="(item, index) in commentsFiltered">
        <div
          :key="index"
          :class="{
            'cursor-pointer': item.is_file,
            me: item.type == 1,
            other: item.type == 2,
            'text-right': item.type == 1,
            'text-left': item.type == 2,
          }"
          class="p-1 mb-1 content-comment"
        >
          <div class="font-weight-bolder" style="margin: 1px 2px">
            <span> {{ item.user_name }} </span>
          </div>
          <div
            v-if="item.is_file"
            :class="cardComment(item.type)"
            class="rounded"
          >
            <div
              v-if="['png', 'jpge', 'jpg'].includes(item.file_type)"
              :style="containerImg(item)"
              @click="openImage(item.file_type, item)"
            ></div>
            <span
              v-else
              @click="openImage(item.file_type, item)"
              :class="isDarkSkin ? 'text-linked-i' : 'text-linked'"
            >
              {{ item.content }}
            </span>
          </div>
          <div v-else :class="cardComment(item.type)" class="rounded">
            {{ item.content }}

            <div
              class="ml-1 text-right"
              style="color: #6e6e6e; font-size: 11px"
            >
              {{ item.date_time | myFromNow }}
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="my-2">
      <b-input-group class="wo-focus">
        <b-form-textarea
          v-model="comment.content"
          rows="1"
          max-rows="1"
          class="rounded"
          no-resize
          placeholder="Type a message..."
          @keyup.enter="insertComment"
        ></b-form-textarea>
        <template #append>
          <div class="d-flex align-items-center justify-content-center">
            <b-button
              variant="secondary"
              class="rounded ml-1 bg-orange"
              @click="modalFiles = true"
            >
              <feather-icon icon="PaperclipIcon" />
            </b-button>
            <b-button
              variant="secondary"
              class="rounded ml-1 bg-orange"
              @click="insertComment"
              ><feather-icon icon="SendIcon"
            /></b-button>
          </div>
        </template>
      </b-input-group>
    </div>
    <UploadFiles
      v-if="modalFiles"
      :showModal="modalFiles"
      @sendFiles="sendFiles"
      @closeModal="closeModalFiles"
    />
  </div>
</template>

<script>
import store from "@/store";
import CoolLightBox from "vue-cool-lightbox";
import UploadFiles from "@/views/commons/components/helpdesk/components/modals/UploadFiles.vue";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
export default {
  components: {
    UploadFiles,
    CoolLightBox,
  },
  props: {
    ticket_id: {
      type: String,
      default: 0,
    },
  },
  data() {
    return {
      comment: {
        content: "",
        type: 1,
        is_file: false,
        file: null,
      },
      modalFiles: false,
      files: [],
      comments: [],
      details: {},
      showImage: null,
      itemImage: [],
    };
  },
  computed: {
    selectedType() {
      return this.currentUser.eng_soft ? 1 : 2;
    },
    currentUser() {
      return store.getters["auth/currentUser"];
    },
    commentsFiltered() {
      if (this.toogleComment) {
        return this.comments.filter((item) => {
          return item.type == this.selectedType;
        });
      } else {
        return this.comments;
      }
    },
  },
  mounted() {
    this.getTicket();
    this.getComments();
    const channel = socket.channel("channel-ticket-alert", {});
    channel.bind("client-send-message", (payload) => {
      if (payload.ticket_id != this.ticket_id) return;
      this.comments.push(payload);
      this.scrollCommentsBottom();
    });
  },
  methods: {
    containerImg(item) {
      return `background-image: url(${item.file_path}); background-size: cover; background-position: center; width: 100%; height: 100px;border-radius:10px`;
    },
    async getTicket() {
      this.details = await helpdeskService.getTicket({
        ticket_id: this.ticket_id,
      });
    },
    cardComment(type) {
      return `card-comment-${type == 1 ? "me" : "other"} ${
        this.isDarkSkin ? "dark" : ""
      }`;
    },
    openImage(extension, image) {
      if (
        extension == "jpg" ||
        extension == "png" ||
        extension == "jpeg" ||
        extension == "webp"
      ) {
        this.itemImage = [image.file_path];
        this.showImage = 0;
      } else {
        this.forceDownload(image.file_path, image.file_name);
      }
    },
    forceDownload(url, filename) {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async getComments() {
      try {
        this.addPreloader();
        const params = {
          ticket_id: this.ticket_id,
        };
        const response = await helpdeskService.getCommentsIssue(params);
        this.comments = response.data;
        this.scrollCommentsBottom();
      } catch (e) {
        console.error(e);
      } finally {
        this.removePreloader();
      }
    },
    async insertComment(event) {
      if (event.shiftKey) {
        event.preventDefault();
        return;
      }
      this.comment.content = this.comment.content.trim();
      if (this.comment.content == null || this.comment.content == "") {
        return;
      }
      const newMessage = {
        ticket_id: this.ticket_id,
        user_id: this.currentUser.user_id,
        content: this.comment.content,
        date_time: new Date(),
        type: this.selectedType,
        user_name: this.currentUser.fullName,
      };
      this.comments.push(newMessage);
      this.scrollCommentsBottom();
      const tempContent = this.comment.content;
      this.comment.content = null;
      try {
        const params = {
          ticket_id: this.ticket_id,
          content: tempContent,
          user_id: this.currentUser.user_id,
          type: this.selectedType,
        };
        await helpdeskService.insertCommentFromParagon(params);
        this.triggerMessageSocket(newMessage);
      } catch (e) {
        this.$swal.fire({
          icon: "error",
          title: e,
        });
        this.comment.content = tempContent;
        this.comments.splice(this.components.length - 1, 1);
      }
    },
    triggerMessageSocket(message) {
      const channel = socket.channel("channel-ticket-alert", {});
      if (channel) {
        channel.trigger("client-send-message", message);
      }
    },
    scrollCommentsBottom() {
      this.$nextTick(() => {
        this.$refs.scrollList.scroll({
          top: this.$refs.scrollList.scrollHeight,
          behavior: "smooth",
        });
      });
    },
    closeModalFiles() {
      this.modalFiles = false;
    },

    async sendFiles(files) {
      if (files.length == 0) return;
      this.addPreloader();
      try {
        let formData = new FormData();
        formData.append("ticket_id", this.ticket_id);
        formData.append("userId", this.currentUser.user_id);
        formData.append("type", this.selectedType);
        formData.append("is_upload_message", 1);
        files.forEach((file) => {
          formData.append("files[]", file);
        });
        const response = await helpdeskService.uploadFilesCommentFromParagon(
          formData
        );
        this.comments = response.data;
        this.closeModalFiles();
        this.scrollCommentsBottom();
        this.removePreloader();
        this.$emit("refresh");
      } catch (e) {
        this.$swal.fire({
          icon: "error",
          title: e,
        });
        this.removePreloader();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-comment-other {
  background: #0090e7 !important;
  white-space: pre-wrap;
  padding: 10px;
  border: 0.7px solid rgb(228 234 236);
  color: white;
  div {
    color: white !important;
  }
  &.dark {
    background: rgb(32 32 36);
    border: 0.7px solid rgb(32 32 36);
  }
}

.card-comment-me {
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  white-space: pre-wrap;
  background: white;
  padding: 10px;
  border: 0.7px solid rgb(228 234 236);
  &.dark {
    div {
      color: white !important;
    }
    background: #272729;
    border: 0.7px solid #272729;
  }
}

.bg-rgba {
  background: rgb(0 0 0 / 4%) !important;
}
.bg-rgba-light {
  background: rgb(0 0 0 / 1%) !important;
}
.desc-ticket {
  min-height: 70px;
  max-height: 70px;
  overflow-y: auto;
  font-size: 1rem;
}
.max-height-conversation {
  max-height: 65vh !important;
  overflow: hidden;
}
.card-comments {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  font-size: 1rem;
  min-height: 38vh;
  max-height: 38vh;
  overflow-y: auto;
}
@media (max-width: 1600px) {
  .card-comments {
    min-height: 33vh;
    max-height: 33vh;
  }
}
.me {
  margin-left: auto;
}
.other {
  margin-right: auto;
}
.content-comment {
  max-width: 80%;
  border-radius: 20px;
  padding: 5px 10px;
}
.bg-orange-i {
  color: #ff6045;
  font-size: 1.5rem;
}
.bg-orange {
  background: #ff6045 !important;
  border: 1px solid #ff6045 !important;
  color: white !important;
  &:active {
    background: #fa7a64 !important;
    border: 1px solid #f38875 !important;
    color: white !important;
  }
  &:focus {
    background: #fa7a64 !important;
    border: 1px solid #f38875 !important;
    color: white !important;
  }
}
.smooth-scroll {
  transition: all 0.5s ease-in-out;
}
.text-linked {
  color: #ff6045;
  font-weight: 600;
  cursor: pointer;
}
.text-linked-i {
  color: #000;
  font-weight: 600;
  cursor: pointer;
}
.wo-focus {
  &:focus-within {
    box-shadow: none !important;
  }
}
</style>
