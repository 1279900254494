<template>
  <div class="w-100">
    <b-row>
      <b-col
        cols="12"
        lg="6"
        class="px-1 content-calendar"
      >
        <!--HEADER CALENDAR-->
        <div class="d-block d-sm-flex w-100 justify-content-start align-items-center mb-1 pl-1">
          <strong
            style="font-size: 16px"
            class="mr-2"
          >Month:</strong>
          <div class="d-flex justify-content-center align-items-center border-primary rounded">
            <b-button
              variant="primary"
              class="p-0"
              style="padding: 5px !important;border-radius: 3px 0px 0px 3px !important;"
              size="sm"
              :disabled="disabledPreviousMonth && month <= currentMonth && year <= currentYear"
              @click="nextMonth(-1)"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="17"
                class="hover-me"
              />
            </b-button>
            <span
              class="text-center w-100"
              style="font-size: 13px;padding: 3px 15px;min-width: 150px;"
            >{{ monthAndYear
            }}</span>
            <b-button
              variant="primary"
              class="p-0"
              style="padding: 5px !important;border-radius: 0px 3px 3px 0px !important;"
              size="sm"
              @click="nextMonth(1)"
            >
              <feather-icon
                icon="ChevronRightIcon"
                size="17"
                class="hover-me"
              />
            </b-button>
          </div>
        </div>
        <!--DAYS OF CALENDAR-->
        <div class="container-calendar d-block">
          <!--names-->
          <div
            class="grid-calendar border-bottom"
            style="min-width: 305px"
          >
            <span class="name-day">sun</span>
            <span class="name-day">mon</span>
            <span class="name-day">tue</span>
            <span class="name-day">wed</span>
            <span class="name-day">thu</span>
            <span class="name-day">fri</span>
            <span class="name-day">sat</span>
          </div>
          <!--GRID CALENDAR-->
          <div
            v-if="!loadCalendar"
            class="grid-calendar"
            style="min-width: 285px"
          >
            <div
              v-for="(space, index) in spaceEmptyStart"
              :key="((index + 1) * -1)"
              class="number-day"
              style="background-color: transparent !important;pointer-events: none;"
            />
            <!--days-->
            <div
              v-for="(item, index) in hoursAvailables"
              :key="index"
              class="position-relative number-day"
              :class="item.day === day ? 'day-selected' : ''"
              :style="(!item.range_hours)
                ? 'pointer-events: none; color: #82868B;' :
                  item.range_hours ? 'border-radius: 5px; border: 1px solid #2591E7; color: #2591E7;' : ''"
              @click="day = index + 1"
            >
              <span>{{ item.day }}</span>
              <small
                v-if="item.meeting_date === todayDate"
                class="indicator"
              />
            </div>
          </div>
          <b-skeleton-table
            v-else
            :rows="4"
            :columns="5"
            :table-props="{ bordered: true, striped: true }"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        lg="6"
        class="p-0 p-md-2 content-meeting"
      >
        <!--LIST MEETINGS-->
        <h5
          class="text-uppercase text-center font-weight-bolder mb-1 mt-4 mt-lg-0"
          style="font-size: 15px;text-stroke: 0.5px currentColor;"
        >
          Hours Available
        </h5>
        <b-list-group
          style="max-height:280px;min-height:280px;overflow-y: auto;overflow-x: hidden;border-radius: 0px !important;"
          class="p-0"
        >
          <!--WE ARE SELECTING NEW HOURS-->
          <template v-if="hoursSelectedDay.length > 0">
            <div
              v-for="(item, index) in hoursSelectedDay"
              :key="item.range_start"
              class="d-flex align-items-center"
              :class="!item.nro_correlative ? null: bgColor[item.status]"
            >
              <!--RANGE OF HOURS-->
              <div
                :id="`item_${index}`"
                class="w-100"
              >
                <b-list-group-item
                  :disabled="item.meeting_date < todayDate"
                  class="d-flex align-items-center rounded cursor-pointer border w-100"
                  :style="!item.nro_correlative ? 'margin: 4px 0px;':'margin: 0px !important; border-radius: 0px !important;border: 0px solid transparent !important;'"
                  :class="item.selected ? `${ item.id ? `${bgColor[item.status]} disabled` : 'bg-primary' } text-light font-weight-bolder` : `${item.busy ? 'disabled':''} bg-transparent ${item.is_main ? 'justify-content-around':'justify-content-start'}`"
                  @click="!item.busy ? toggleSelectedHours(index) : null"
                >
                  <transition
                    name="fade"
                    mode="out-in"
                  >
                    <template v-if="item.selected">
                      <feather-icon
                        v-if="item.id"
                        icon="ClockIcon"
                        size="18"
                      />
                      <b-form-checkbox
                        v-else
                        v-model="item.selected"
                        :value="true"
                        :state="true"
                        class="pointer-events-none"
                      />
                    </template>
                  </transition>
                  <span class="mx-1">Start : {{ item.range_start | myFullTime }}</span>
                  <span class="mx-1">End : {{ item.range_end | myFullTime }}</span>
                  <tabler-icon
                    v-if="item.busy"
                    icon="TicketIcon"
                    size="18"
                    style="z-index: 999"
                  />
                </b-list-group-item>
                <template>
                  <b-tooltip
                    v-if="item.status_description"
                    :target="`item_${index}`"
                    placement="top"
                  >
                    <b
                      class="text-uppercase"
                      style="font-size: 12px"
                    >{{ item.status_description }}</b>
                  </b-tooltip>
                  <b-tooltip
                    v-if="item.busy"
                    :target="`item_${index}`"
                    placement="top"
                  >
                    <b
                      class="text-uppercase"
                      style="font-size: 12px;margin-right: 5px;"
                    >
                      Assigned to ticket {{ `"#${item.ticketCode}""` }}
                    </b>
                  </b-tooltip>
                </template>
              </div>
              <feather-icon
                v-if="item.id && [1,2].includes(item.status) && item.is_main"
                v-b-tooltip.hover.v-danger
                v-b-tooltip.hover.left
                :title="item.nro_correlative ? 'CANCEL RANGE' : 'CANCEL'"
                class="text-danger cursor-pointer mx-1"
                icon="XCircleIcon"
                size="25"
                @click="cancelMeeting(item.id, item.nro_correlative)"
              />
            </div>
          </template>
          <!--WE THERE AREN'T DATA-->
          <template v-else>
            <b-list-group-item
              class="d-flex justify-content-center align-items-center pointer-events-none w-100"
              style="max-height: 280px;min-height: 280px;border: none !important;"
            >
              <div class="d-flex justify-content-center align-items-center text-center flex-wrap">
                <feather-icon
                  icon="ClockIcon"
                  size="65"
                  class="m-1 text-secondary"
                  :class="loadHours ? 'rotate-element' : null"
                />
                <span
                  v-if="!loadHours"
                  class="text-secondary mt-1 w-100"
                  style="font-size: 18px"
                >
                  select a day to display the available hours
                </span>
                <span
                  v-else
                  class="text-primary ml-1 mt-1 w-100 text-center"
                  style="font-size: 18px"
                >
                  loading ...
                </span>
              </div>
            </b-list-group-item>
          </template>
        </b-list-group>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="12">
        <strong
          v-if="month < currentMonth && year <= currentYear"
          class="d-inline-flex justify-content-center align-content-center w-100 text-warning text-uppercase"
        >you are looking at the dates of the previous month</strong>
      </b-col>
    </b-row>
    <b-row
      v-if="!returnHours && (`${year}-${month}-${day}` >= todayDate)"
      class="mt-1 border-top"
    >
      <b-col
        cols="12"
        class="mt-1"
      >
        <b-button
          variant="primary"
          class="mr-1 float-right"
          :disabled="!haveHoursSelected"
          @click="saveMeeting"
        >
          <feather-icon
            icon="ClockIcon"
            class="mr-1"
          />
          PROGRAM
        </b-button>
        <b-button
          variant="secondary"
          class="mr-1 float-right"
          @click="closeModal"
        >
          CANCEL
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import CustomerTicketsServices from '@/views/commons/components/customer-tickets/services/customer-tickets.service';

export default {
  props: {
    ticketCustomer: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    returnHours: {
      type: Boolean,
      default: false,
      required: false,
    },
    defaultToday: {
      type: Boolean,
      default: true,
      required: false,
    },
    variant: {
      type: String,
      default: 'primary',
      required: false,
    },
    disabledPreviousMonth: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      currentMonth: parseInt(moment().format('MM'), 10),
      currentYear: parseInt(moment().format('YYYY'), 10),
      today: parseInt(moment().format('DD'), 10),
      todayDate: moment().format('YYYY-MM-DD'),
      month: parseInt(moment().format('MM'), 10),
      year: parseInt(moment().format('YYYY'), 10),
      day: null,
      hoursAvailables: [],
      loadHours: false,
      hoursMeetings: [],
      bgColor: {
        0: 'bg-primary',
        1: 'bg-warning',
        2: 'bg-info',
        3: 'bg-success',
        4: 'bg-success',
        5: 'bg-secondary',
        6: 'bg-danger',
      },
      hoursSelectedDay: [],
      loadCalendar: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    monthAndYear() {
      return moment(`${this.month}/${this.year}`, 'MM/YYYY').format('MMMM YYYY');
    },
    spaceEmptyStart() {
      return parseInt(
        moment(`${this.year}-${this.month}`, 'YYYY-MM').startOf('month').day(),
        10,
      );
    },
    haveHoursSelected() {
      return this.hoursSelectedDay.filter(item => item.selected && !item.id).length;
    },
  },
  watch: {
    async day(newVal) {
      this.hoursSelectedDay = [];
      this.$emit('selectedHours', []);
      if (newVal) {
        await this.getMeetingsHoursTicketCustomer();
      }
    },
  },
  async mounted() {
    this.loadCalendar = true;
    await this.getMeetingsHoursCEO();
    this.day = this.defaultToday ? this.today : null;
    this.loadCalendar = false;
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    success() {
      this.$emit('success');
    },
    async nextMonth(value) {
      this.hoursSelectedDay = [];
      const newDate = moment(`${this.year}-${this.month}`, 'YYYY-MM').add(value, 'month');
      this.year = parseInt(newDate.format('YYYY'), 10);
      this.month = parseInt(newDate.format('MM'), 10);
      await this.getMeetingsHoursCEO();
      await this.getMeetingsHoursTicketCustomer();
    },
    async getMeetingsHoursCEO() {
      try {
        this.loadCalendar = true;
        const params = {
          month: this.month,
          year: this.year,
          moduleId: this.ticketCustomer.module_id,
        };
        const { data } = await CustomerTicketsServices.getMeetingsHoursCeo(params);
        if (!data.data.length) return;

        this.hoursAvailables = this.hoursAvailablesToRange(data.isOneHourRange, data.data);

        this.loadCalendar = false;
      } catch (error) {
        this.loadCalendar = false;
        console.log('Ocurred error in getMeetingsHoursCEO: ', error);
      }
    },
    toggleSelectedHours(index) {
      this.hoursSelectedDay.forEach((item, i) => {
        if (i === index) {
          item.selected = !item.selected;
        }

        // only modify status of hours selected and haven't id
        if (item.selected && !item.id) {
          item.status = 1;
        } else if (!item.selected && !item.id) {
          item.status = 0;
        }
      });

      if (this.returnHours) {
        const hoursSelected = this.hoursSelectedDay.filter(item => item.selected && !item.id);
        this.$emit('selectedHours', hoursSelected);
      }
    },
    async saveMeeting() {
      try {
        if (!this.validHours()) return;

        const { isConfirmed } = await this.showConfirmSwal('VERY IMPORTANT !', 'This action will delete all previous meetings that are pending.');
        if (!isConfirmed) return;

        this.addPreloader();

        // params to save (only new hours selected)
        const meetingDateSelected = this.hoursSelectedDay.filter(item => item.selected && !item.id)?.[0].meeting_date || null;
        const hoursIndividuals = meetingDateSelected ? JSON.parse(this.hoursAvailables.find(item => item.meeting_date === meetingDateSelected).range_hours) : null;
        const rangeHoursSelected = this.hoursSelectedDay.filter(item => item.selected && !item.id);

        const startHour = rangeHoursSelected.reduce((a, b) => {
          const rangePrevious = moment(`1970-01-01 ${a.range_start}`, 'YYYY-MM-DD HH:mm:ss');
          const rangeCurrent = moment(`1970-01-01 ${b.range_start}`, 'YYYY-MM-DD HH:mm:ss');
          return rangeCurrent.isBefore(rangePrevious) ? b : a;
        }, rangeHoursSelected[0]).range_start;

        const endHour = rangeHoursSelected.reduce((a, b) => {
          const rangePrevious = moment(`1970-01-01 ${a.range_end}`, 'YYYY-MM-DD HH:mm:ss');
          const rangeCurrent = moment(`1970-01-01 ${b.range_end}`, 'YYYY-MM-DD HH:mm:ss');
          return rangeCurrent.isAfter(rangePrevious) ? b : a;
        }, rangeHoursSelected[0]).range_end;

        const hoursSelected = hoursIndividuals.filter(item => item.range_start >= startHour && item.range_end <= endHour).map(item => ({
          busy: false,
          id: null,
          is_main: null,
          meeting_date: meetingDateSelected,
          nro_correlative: null,
          range_end: item.range_end,
          range_start: item.range_start,
          selected: true,
          status: 1,
          status_description: '',
          ticketCode: null,
        }));

        const params = {
          ticketCustomerId: this.ticketCustomer.id,
          meetings: hoursSelected,
          createdBy: this.currentUser.user_id,
          createdByName: this.currentUser.fullName,
          clientName: this.ticketCustomer.name_client,
          ticketCode: this.ticketCustomer.code,
          isCorrelative: hoursSelected.length > 1 ? 1 : 0,
          account: this.ticketCustomer.account,
          numberPhone: this.ticketCustomer.number_phone,
        };

        const { status } = await CustomerTicketsServices.insertMeetingTicket(params);
        if (status === 200) {
          this.showSuccessSwal();
          await this.getMeetingsHoursTicketCustomer();
          this.$emit('success');
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal();
        this.removePreloader();
        console.log('error', error);
      }
    },
    validHours() {
      const hoursSelected = this.hoursSelectedDay.filter(item => item.selected && !item.id);
      if (hoursSelected.length === 0) {
        return false;
      } if (hoursSelected.length === 1) {
        return true;
      }
      // correlative hours
      let isValid = true;
      hoursSelected.forEach((item, index) => {
        const beforeHourEnd = index > 0 ? hoursSelected[index - 1].range_end : null;

        if (beforeHourEnd && item.range_start !== beforeHourEnd) {
          isValid = false;
        }
      });

      if (!isValid) {
        this.showWarningSwal('VERY IMPORTANT', 'You cannot select more than one range of hours that are not correlative.');
      }
      return isValid;
    },
    async getMeetingsHoursTicketCustomer() {
      this.day = !this.day ? this.today : this.day;
      try {
        this.loadHours = true;
        // WE FIND HOURS selected of CEO of according a day
        const { group_range: rangeHours, meeting_date: meetingDate, hours_no_availables: noAvailables } = this.hoursAvailables.find(item => item.day === this.day);

        if (!rangeHours) {
          this.day = null;
          this.hoursSelectedDay = [];
          this.loadHours = false;
          return;
        }
        const hoursNoAvailables = noAvailables ? JSON.parse(noAvailables) : null;

        // GET HOURS OF SPECIFY DAY OF TICKET CUSTOMER
        const params = {
          ticketCustomerId: this.ticketCustomer.id,
          meetingDate: `${this.year}-${this.month}-${this.day}`,
          createdBy: this.currentUser.user_id,
        };

        const { data: meeting } = await CustomerTicketsServices.getMeetingTicketCustomer(params);
        if (!meeting) {
          this.loadHours = false;
          return;
        }

        let beforeCorrelative = null;

        // ordering hours and verifying if there are meetings to show selected default
        this.hoursSelectedDay = JSON.parse(rangeHours).map(item => {
          const rangeStart = item.range_start.split('.')[0];
          const rangeEnd = item.range_end.split('.')[0];

          const meetingFind = meeting.find(element => element.meeting_start >= rangeStart && element.meeting_end <= rangeEnd);

          const noAvailable = hoursNoAvailables
            ? hoursNoAvailables.find(element => element.meeting_start >= rangeStart && element.meeting_end <= rangeEnd && element.ticket_id !== this.ticketCustomer.id)
            : false;

          const hours = {
            meeting_date: meetingDate,
            range_start: rangeStart,
            range_end: rangeEnd,
            selected: !!meetingFind,
            status: meetingFind?.meeting_status_id || 0,
            status_description: meetingFind?.meeting_status_description || '',
            id: meetingFind?.id || null,
            busy: !!noAvailable,
            ticketCode: noAvailable ? noAvailable.ticket_code : null,
            nro_correlative: meetingFind?.nro_correlative,
            is_main: !beforeCorrelative || (beforeCorrelative !== meetingFind?.nro_correlative),
          };
          beforeCorrelative = meetingFind?.nro_correlative || null;

          return hours;
        }).sort((a, b) => (a.range_start < b.range_start ? -1 : 1));

        this.loadHours = false;
      } catch (error) {
        this.loadHours = false;
        this.showErrorSwal();
        console.log('error to getMeetingsHoursTicketCustomer: ', error);
      }
    },
    async cancelMeeting(meetingId, nroCorrelative) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        const params = {
          meetingId,
          deletedBy: this.currentUser.user_id,
          nroCorrelative,
        };
        const { status } = await CustomerTicketsServices.cancelMeetingTicketCustomer(params);
        if (status === 200) {
          this.showSuccessSwal();
          await this.getMeetingsHoursTicketCustomer();
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal();
        console.log('error to cancelMeeting: ', error);
      }
    },
    hoursAvailablesToRange(isOneHourRange, data) {
      // ordered range (order ASC)
      const myData = data.map(item => {
        const range = item.range_hours ? JSON.parse(item.range_hours).sort((a, b) => {
          const hourA = new Date(`1970-01-01T${a.range_start}`);
          const hourB = new Date(`1970-01-01T${b.range_start}`);
          return hourA - hourB;
        }) : null;

        item.range_hours = range ? JSON.stringify(range) : null;

        return { ...item, day: parseInt(moment(item.meeting_date).format('DD'), 10), group_range: !isOneHourRange ? item.range_hours : null };
      });

      // group by hours
      if (isOneHourRange) {
        for (let i = 0; i < myData.length; i += 1) {
          const nextRange = myData[i].range_hours ? JSON.parse(myData[i].range_hours) : null;

          let mainHours = [];
          if (nextRange) {
            nextRange.forEach(item => {
              const currentRange = mainHours.length ? mainHours[mainHours.length - 1] : null;

              if (!currentRange) {
                mainHours.push(item);
              } else {
                const hourStart = moment(`1970-01-01 ${currentRange.range_start}`, 'YYYY-MM-DD HH:mm:ss');
                const hourEnd = moment(`1970-01-01 ${item.range_end}`, 'YYYY-MM-DD HH:mm:ss');

                const diff = hourEnd.diff(hourStart, 'minutes');

                if (currentRange.range_end === item.range_start && diff <= 60) {
                  mainHours[mainHours.length - 1].range_end = item.range_end;
                } else {
                  mainHours.push(item);
                }
              }
            });
          }
          myData[i].group_range = mainHours;
          mainHours = [];
        }

        // clear leftover range hours
        return myData.map(item => {
          const sanitizeGroupRange = item.group_range.map(hour => {
            const hourStart = moment(`1970-01-01 ${hour.range_start}`, 'YYYY-MM-DD HH:mm:ss');
            const hourEnd = moment(`1970-01-01 ${hour.range_end}`, 'YYYY-MM-DD HH:mm:ss');
            const diff = hourEnd.diff(hourStart, 'minutes');

            if (diff === 60) return hour;
          }).filter(hour => !!hour);

          item.group_range = JSON.stringify(sanitizeGroupRange);
          return item;
        });
      }

      return myData;
    },
  },
};
</script>
<style scoped>
*{
  --custom-blue: #2591E7;
}
.grid-calendar {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0px;
  margin: 0px auto;
  position: relative;
}

.grid-calendar .name-day,
.grid-calendar .number-day,
.grid-calendar .number-day-dark-skin {
  text-align: center;
  font-size: 10px;
  padding: 10px 0px;
  /* border-bottom: 1px solid #ccc; */
  text-transform: uppercase;
  transition: 0.3s ease-in-out all;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.grid-calendar .name-day {
  font-weight: 600;
}

.grid-calendar .number-day,
.grid-calendar .number-day-dark-skin {
  cursor: pointer;
  margin: 2px;
}

.grid-calendar .number-day:hover,
.grid-calendar .number-day-dark-skin:hover {
  background-color: var(--custom-blue);
  /* border-radius: 5px; */
}
/*
.grid-calendar .number-day span,
.grid-calendar .number-day-dark-skin span {
  transition: 0.3s ease-in-out all;
} */

.grid-calendar .number-day:hover span,
.grid-calendar .number-day-dark-skin:hover span {
  cursor: pointer;
  font-size: 15px;
  transform: traslateY(-5px);
  color: white;
}

/* .number-day:nth-child(7n+1),
.number-day:nth-child(7n+3),
.number-day:nth-child(7n+5),
.number-day:nth-child(7n+7)
{
  background-color: #F8F9F9;
}
.number-day-dark-skin:nth-child(7n+1),
.number-day-dark-skin:nth-child(7n+3),
.number-day-dark-skin:nth-child(7n+5),
.number-day-dark-skin:nth-child(7n+7){
  background-color: #f8f9f917;
} */

.day-selected{
  background-color: var(--custom-blue) !important;
  color: white !important;
}
.day-selected span{
  font-size: 15px;
}
.day-selected .indicator{
  background-color: white !important;
}

.indicator,
.indicator-dark {
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  bottom: 3px;
  transition: 0.3s ease-in-out all;
}
.indicator{
  background-color: currentColor;
}

.grid-calendar .number-day:hover .indicator,
.grid-calendar .number-day-dark-skin:hover .indicator {
  background-color: white;
}

.rotate-me{
  transition: 0.3s ease-in-out all;
}
.rotate-me:hover{
  transform: rotate(180deg);
}

/*---ANIMATIONs----*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to
{
  opacity: 0;
  transition: opacity 0s !important;
}

.slide-fade-enter-active, .slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
.slide-fade-item {
  transition: transform 0.5s;
}
.rotate-element{
  transition: 0.3s ease-in-out all;
  color: var(--custom-blue) !important;
  animation: rotate-element 1s infinite;
}
@keyframes rotate-element {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 400px){
  .container-calendar{
  transform: scale(0.98) translateX(-10px);
  }
  .container-calendar{
    height: 200px;
  }
}
@media screen and (max-width: 380px){
  .container-calendar{
  transform: scale(0.8) translateX(-20px) translateY(-40px);
  }
}
@media screen and (max-width: 350px){
  .container-calendar{
    transform: scale(0.7) translateX(-45px) translateY(-40px);
  }
}
@media screen and (max-width: 300px){
  .container-calendar{
    transform: scale(0.65) translateX(-60px) translateY(-40px);
  }
}
</style>
