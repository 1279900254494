import store from "@/store";
import Notifications from "@/service/notification/index";
import router from "@/router/index";
import HelpDeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
// import connectionService from "@/views/ce-digital/sub-modules//connection/views/clients/services/connection.service";
import NcrProgramsService from "@/views/commons/components/ncr-programs/service/ncr-programs.service.js";
import employeeClaims, {
  salesMadeCounter,
} from "@/views/commons/commons-counter";
import RequestService from "@/views/commons/components/request/services/request.service.js";
import RecruitmentService from "@/views/commons/components/recruitment-process/services/recruiment.service";
import LettersRoundService from "@/views/specialists/sub-modules/analyst-department/views/letter-round/services/letters-rounds.service.js";
import PotentialService from "@/views/commons/components/potencial-appointment/services/potencial-appointments.service";
import OtherServicesService from "@/views/commons/components/other-services/service/appointment.service";
import AppointmentService from "@/views/commons/components/appointments/service/appointment.service";
import ApplicationService from "@/views/commons/components/applications/services/applications.service";

const paragonCounter = async () => {
  const params = {
    modul_id: 20,
    user_id: store.state.auth.currentUser.user_id,
  };
  const { data } = await HelpDeskService.getCounterNotifications(params);
  if (Object.keys(data).length > 0) {
    const payload = {
      routeName: "helpdesk-connection",
      tag: data.total,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

// const recyclePendingCounter = async () => {
//   const data = await connectionService.getCountRecyclePending();
//   let role = store.state.auth.currentUser.role_id;
//   if (Object.keys(data).length > 0 && role !== 15 && role !== 14) {
//     store.dispatch("connectionStore/A_SET_COUNTER_RECYCLE", data.data[0].count);
//   }
// };

// const counterHoldUnassigned = async () => {
//   const data = await connectionService.getCounterHoldUnassigned();
//   let role = store.state.auth.currentUser.role_id;
//   if (Object.keys(data).length > 0 && role !== 15 && role !== 14) {
//     store.dispatch("connectionStore/A_SET_COUNTER_HOLD", data.data[0].count);
//   }
// };

// const counterLoyaldUnassigned = async () => {
//   const data = await connectionService.getCounterLoyalUnassigned();
//   let role = store.state.auth.currentUser.role_id;
//   if (Object.keys(data).length > 0 && role !== 15 && role !== 14) {
//     store.dispatch("connectionStore/A_SET_COUNTER_LOYAL", data.data[0].count);
//   }
// };
const counterClientConnectionGrid = async () => {
  const allowedRoleIds = [1, 2, 17, 11];

  let params = {
    module_id: 20,
    user_role:
      allowedRoleIds.includes(store.state.auth.currentUser.role_id) ||
      store.getters["auth/isAssistantSupervisor"]
        ? 1
        : store.state.auth.currentUser.role_id,
    user_id:
      allowedRoleIds.includes(store.state.auth.currentUser.role_id) ||
      store.getters["auth/isAssistantSupervisor"]
        ? 1
        : store.state.auth.currentUser.user_id,
  };
  const data = await store.dispatch(
    "connectionStore/A_GET_CE_GRID_COUNTERS",
    params
  );
  if (data.length > 0) {
    let properties = data[0];
    let total = properties.outstanding + properties.loyal;

    const payload = {
      routeName: "connection-clients",
      tag: total,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

// const counterTotalClientPending = async () => {
//   const data = await connectionService.getCounterTotalClientPending();
//   const dataAgent = await connectionService.getCounterTotalClientWithoutReading(
//     { advisor_connection_id: store.state.auth.currentUser.user_id }
//   );
//   let role = store.state.auth.currentUser.role_id;
//   if (Object.keys(data.data).length > 0 && role !== 15 && role !== 14) {
//     const payload = {
//       routeName: "connection-clients",
//       tag: data.data[0].total,
//     };
//     store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
//   } else if (Object.keys(dataAgent.data).length > 0 && role == 15) {
//     const payload = {
//       routeName: "connection-clients",
//       tag: dataAgent.data[0].total,
//     };
//     store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
//   }
// };

// const counterClientWithoutReadingLoyal = async () => {
//   const data = await connectionService.getCounterClientWithoutReading({
//     advisor_connection_id: store.state.auth.currentUser.user_id,
//   });
//   let role = store.state.auth.currentUser.role_id;
//   if (Object.keys(data).length > 0 && role == 15) {
//     store.dispatch(
//       "connectionStore/A_SET_COUNTER_LOYAL_WITHOUT_READING",
//       data.data[0]
//     );
//   }
// };

// const counterClientWithoutReadingHold = async () => {
//   const data = await connectionService.getCounterClientWithoutReading({
//     advisor_connection_id: store.state.auth.currentUser.user_id,
//   });
//   let role = store.state.auth.currentUser.role_id;
//   if (Object.keys(data).length > 0 && role == 15) {
//     store.dispatch(
//       "connectionStore/A_SET_COUNTER_HOLD_WITHOUT_READING",
//       data.data[1]
//     );
//   }
// };

// const counterClientLoyalReadingTabResult = async () => {
//   const data = await connectionService.getCounterClientLoyalReadingTab({
//     advisor_connection_id: store.state.auth.currentUser.user_id,
//   });
//   let role = store.state.auth.currentUser.role_id;
//   if (Object.keys(data).length > 0 && role == 15) {
//     store.dispatch(
//       "connectionStore/A_SET_COUNTER_LOYAL_WITHOUT_READING_RESULT",
//       data.data[0]
//     );
//   }
// };

// const counterClientLoyalReadingTabNoResult = async () => {
//   const data = await connectionService.getCounterClientLoyalReadingTab({
//     advisor_connection_id: store.state.auth.currentUser.user_id,
//   });
//   let role = store.state.auth.currentUser.role_id;
//   if (Object.keys(data).length > 0 && role == 15) {
//     store.dispatch(
//       "connectionStore/A_SET_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT",
//       data.data[1]
//     );
//   }
// };

const ncrRealtorCounter = async () => {
  const params = {
    user_id: store.state.auth.currentUser.user_id,
    num: 1,
    module_id: router.currentRoute.matched[0]?.meta?.module,
  };
  const data = await NcrProgramsService.getCounterNcrRealtor(params);
  if (data.length > 0) {
    const payload = {
      routeName: "connection-ncr-realtor",
      tag: data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: "request-connection",
    tag: data[0].counter,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};
const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "connection-projects",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "connection-recruitment-process",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const { data } = await Notifications.getCountPendingCustomerTickets({
    user_id,
  });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: "connection-customer-tickets",
      tag: data[0].v_total_open,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const returnedLettersCounter = async () => {
  const { data } =
    await LettersRoundService.getCounterReturnedLettersDepartment({
      moduleId: 20,
      isConnection: true,
    });

  const payload = {
    routeName: "cn-letters-dispute",
    tag: data,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const getPendingPotential = async () => {
  const { modul_id } = store.state.auth.currentUser;
  const data = await PotentialService.getCountPending({ module_id: modul_id });
  const payload = {
    routeName: "connection-potencial-appointment",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const getPendingOtherServices = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  const data = await OtherServicesService.getCountPending({
    module_id: modul_id,
    user_id,
    role_id,
  });
  const payload = {
    routeName: "connection-other-services",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};
const getPendingAppointment = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  const { pending } = await AppointmentService.getCountAppointmentsPending({
    module_id: modul_id,
    user_id,
    role_id,
  });
  const payload = {
    routeName: "connection-appointment",
    tag: pending,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};
const getPendingApplications = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  let advisorId =
    [1, 2, 17].includes(role_id) ||
    (role_id === 11 && modul_id === 6) ||
    [11, 25, 28, 29, 30].includes(modul_id)
      ? null
      : user_id;
  const params = {
    user_id,
    role_id,
    program_id: 3,
    specialist_id: null,
    module_id: modul_id,
    advisor_id: advisorId,
  };
  const data = await ApplicationService.getCountPending(params);
  const payload = {
    routeName: "connection-applications",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const allCountersCeDigitalConnection = async () => {
  await Promise.all([
    salesMadeCounter("connection-sales-made"),
    paragonCounter(),
    projectsCounter(),
    ncrRealtorCounter(),
    // recyclePendingCounter(),
    // counterHoldUnassigned(),
    // counterLoyaldUnassigned(),
    // counterTotalClientPending(),
    counterClientConnectionGrid(),
    // counterClientWithoutReadingLoyal(),
    // counterClientWithoutReadingHold(),
    // counterClientLoyalReadingTabResult(),
    // counterClientLoyalReadingTabNoResult(),
    employeeClaims("connection-employee-claims"),
    requestNotAsignedCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
    returnedLettersCounter(),
    getPendingPotential(),
    getPendingOtherServices(),
    getPendingAppointment(),
    getPendingApplications(),
  ]);
};
export default allCountersCeDigitalConnection;
