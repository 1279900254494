export default {
  namespaced: true,
  state: {
    S_IS_ACTIVE: false,
    S_DATA_LETTER_ROUND: null,
    S_TITLE: null,
    S_DESCRIPTION: null,
    S_DETAIL_LETTER_ROUND: null,
  },
  getters: {
    G_IS_ACTIVE(state) {
      return state.S_IS_ACTIVE;
    },
    G_DATA_LETTER_ROUND(state) {
      return state.S_DATA_LETTER_ROUND;
    },
    G_TITLE(state) {
      return state.S_TITLE;
    },
    G_DESCRIPTION(state) {
      return state.S_DESCRIPTION;
    },
    G_DETAIL_LETTER_ROUND(state) {
      return state.S_DETAIL_LETTER_ROUND;
    },
  },
  mutations: {
    M_OPEN_ALERT(state, payload) {
      state.S_IS_ACTIVE = payload;
    },
    M_DATA_LETTER_ROUND(state, payload) {
      state.S_DATA_LETTER_ROUND = payload;
    },
    M_TITLE(state, payload) {
      state.S_TITLE = payload;
    },
    M_DESCRIPTION(state, payload) {
      state.S_DESCRIPTION = payload;
    },
    M_RESET(state) {
      state.S_IS_ACTIVE = false;
      state.S_TITLE = "";
      state.S_DATA_NCR = null;
    },
    M_DETAIL_LETTER_ROUND(state, payload) {
      state.S_DETAIL_LETTER_ROUND = payload;
    }
  }
}
