<template>
  <b-modal
    v-model="control"
    centered
    header-bg-variant="info"
    hide-footer
    @hidden="closeModal"
  >

    <template #modal-title>
      <p style="margin: 10px 0px; font-family: Montserrat;"><b-icon
        icon="clock-history"
        aria-hidden="true"
      />
        Ticket summary tracking</p>
    </template>
    <filter-slot
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :filter="filters"
      :filter-principal="{}"
      :no-visible-principal-filter="true"
      @reload="$refs['refTrackingSummaryTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="refTrackingSummaryTable"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="60vh"
          primary-key="id"
          responsive="sm"
          :items="getTicketSummaryTracking"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(summary)="data">
            <b-form-textarea
              v-model="data.item.summary"
              readonly
            />
          </template>
          <template #cell(created_at)="data">
            <span>{{ data.item.created_by_name }}</span>
            <br>
            <span>{{ data.item.created_at | myGlobalWithHour }} </span>
          </template>

          <template #cell(updated_at)="data">
            <span>{{ data.item.updated_by_name }}</span>
            <br>
            <span>{{ data.item.updated_at | myGlobalWithHour }} </span>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-if="data.item.updated_at"
              size="sm"
              variant="primary"
              @click="recoverVersion(data.item)"
            >
              Restore version
            </b-button>
            <b-badge
              v-else
              variant="light-info"
            >
              Current version
            </b-badge>
          </template>

        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex';
import CustomerTicketsService from '@/views/commons/components/customer-tickets/services/customer-tickets.service';

export default {
  props: {
    customerTicket: {
      type: Object,
      default: () => {},
    },
    summaryData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      control: false,
      filterPrincipal: [],
      fields: [
        {
          key: 'summary',
          sortable: false,
          label: 'Summary',
          visible: true,
        },

        {
          key: 'created_at',
          sortable: false,
          label: 'Created by',
          visible: true,
        },
        {
          key: 'updated_at',
          sortable: false,
          label: 'Updated by',
          visible: true,
        },
        {
          key: 'actions',
          sortable: false,
          label: 'Actions',
          visible: true,
        },

      ],
      filters: [],

      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      sortBy: 'created_at',
      sortDesc: true,
      searchInput: '',
      isBusy: false,
      trackingItems: [],
      openModalRecruitmentStatus: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },

  mounted() {
    this.control = true;
    this.getTicketSummary();
  },

  methods: {
    closeModal() {
      this.$emit('hidden');
    },

    async getTicketSummary() {
      // summaryData
      try {
        const params = {
          ticket_customer_id: this.customerTicket.id,
        };
        const { data } = await CustomerTicketsService.getTicketCustomerSummary(params);
        if (data[0]) {
          const { id, summary } = data[0];
          this.$emit('update:summaryData', { id, summary });
        }
      } catch (error) {
        this.removePreloader();
        console.log(error);
      }
    },

    async getTicketSummaryTracking(ctx) {
      const response = await CustomerTicketsService.getTicketCustomerSummaryTracking({
        page: ctx.currentPage,
        perPage: ctx.perPage,
        ticket_customer_id: this.customerTicket.id,

      });
      this.trackingItems = response.data.data;
      this.startPage = response.data.from;
      this.paginate.currentPage = response.data.current_page;
      this.paginate.perPage = response.data.per_page;
      this.totalRows = response.data.total;
      this.toPage = response.data.to;
      return this.trackingItems || [];
    },

    async recoverVersion(data) {
      try {
        const params = {
          summary_id: this.summaryData.id,
          summary: data.summary,
          ticket_customer_id: this.customerTicket.id,
          user_id: this.currentUser.user_id,
        };

        // const validate = await this.$refs.summaryForm.validate();
        const { isConfirmed } = await this.showConfirmSwal();

        // if (!validate) {
        //   return;
        // }

        if (!isConfirmed) {
          return;
        }

        this.addPreloader();
        const { status } = await CustomerTicketsService.insertTicketCustomerSummary(params);

        if (status === 200) {
          // this.getSummaryTicket();
          this.removePreloader();
          this.closeModal();
          this.showSuccessSwal();
        }
      } catch (error) {
        this.removePreloader();
        console.log(error);
      }
    },

  },

};

</script>
