

export default [
    {
        path: "ncr/realtor",
        name: "cdigital-ncr-realtor",  //debt-solution-ncr-realtor
        component: () => import(/* webpackChunkName: "CEDCustomerServiceSalesNcrRealtor" */ "@/views/ce-digital/sub-modules/customer-service/views/ncr-realtor/views/NcrRealtorTemplate.vue"),
        redirect: { name: 'ncr-realtor-in-process-cdigital' },
        meta: {
            pageTitle: "NCR Realtor",
            breadcrumb: [
                {
                    text: "NCR Realtor",
                    active: true,
                },
            ],
            is_digital: 2,
            permittedRoles: [1, 2, 14, 15, 17],
        },
        children: [
            {
                path: '/cedigital/customerservice/ncr/realtor/in-process',
                name: 'ncr-realtor-in-process-cdigital',
                component: () => import(/* webpackChunkName: "CEDCustomerServiceSalesNcrRealtorInProcess" */ "@/views/commons/components/ncr-realtor/views/components/TableNcrRealtor.vue"),
                meta: {
                    statusNCR: 0,
                    pageTitle: "NCR Realtor",
                    is_digital: 2,
                    breadcrumb: [
                        {
                            text: "NCR Realtor",
                            active: true,
                        },
                    ],
                    permittedRoles: [1, 2, 14, 15, 16, 17],
                },
            },
            {
                path: '/cedigital/customerservice/ncr/realtor/completed',
                name: 'ncr-realtor-completed-cdigital',
                component: () => import(/* webpackChunkName: "CEDCustomerServiceSalesNcrRealtorCompleted" */ "@/views/commons/components/ncr-realtor/views/components/TableNcrRealtor.vue"),
                meta: {
                    statusNCR: 1,
                    is_digital: 2,
                    pageTitle: "NCR Realtor",
                    breadcrumb: [
                        {
                            text: "NCR Realtor",
                            active: true,
                        },
                    ],
                    permittedRoles: [1, 2, 14, 15, 16, 17],
                },
            }
        ]

    },
]