/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import dashboard from "./dashboard";
// import appsAndPages from "./apps-and-pages";
// import others from "./others";
// import chartsAndMaps from "./charts-and-maps";
// import uiElements from "./ui-elements";
// import formAndTable from "./forms-and-table";
import crm from "@/views/crm/navigation/index"
import socialNetwork from "@/views/social-network/navigation"
import debtSolution from "@/views/debt-solution/navigation"
import boostCredit from "@/views/boost-credit/navigation"
import management from "@/views/management/navigation"
import rrhh from "@/views/rrhh/navigation"
import logistic from "@/views/logistic/navigation"
import paragon from "@/views/paragon/navigation"
import bussiness from "@/views/business/navigation"
import bookeeping from "@/views/bookeeping/navigation"
import courtInfo from "@/views/court-info/navigation"
import taxresearch from "@/views/tax-research/navigation"
import users from "@/views/users/navigation"
import correspondence from "@/views/correspondence/navigation"
import customerservicenavigation from "@/views/ce-digital/sub-modules/customer-service/navigation"
import settingsnavigation from "@/views/ce-digital/sub-modules/settings/navigation"
import financial from "@/views/ce-digital/sub-modules/financial/navigation"
import specialistdigital from "@/views/specialist-digital/navigation"
import quality from "@/views/quality/navigation"
import creditExperts from "@/views/credit-experts/navigation"
import administration from "@/views/administration/navigation"
import connection from "@/views/ce-digital/sub-modules/connection/navigation"
import specialist from "@/views/specialists/navigation"
import creative from "@/views/creative/navigation"

import salesnavigation from "@/views/ce-digital/sub-modules/sales/navigation"
import analystDepartment from "@/views/specialists/sub-modules/analyst-department/navigation"
import accountsResolution from "@/views/specialists/sub-modules/accounts-resolution/navigation"
import financialApproval from "@/views/specialists/sub-modules/financial-approval/navigation"
// import paragon from "@/views/paragon/navigation";
// Array of sections

const amgSections = [
  ...socialNetwork,
  ...boostCredit,
  ...crm,
  ...management,
  ...rrhh,
  ...logistic,
  ...debtSolution,
  ...paragon,
  ...bussiness,
  ...taxresearch,
  ...users,
  ...correspondence,
  ...bookeeping,
  ...courtInfo,
  ...customerservicenavigation,
  ...settingsnavigation,
  ...financial,
  ...specialistdigital,
  ...quality,
  ...creditExperts,
  ...salesnavigation,
  ...administration,
  ...connection,
  ...specialist,
  ...creative,
  ...analystDepartment,
  ...accountsResolution,
  ...financialApproval,
]
// Array of sections

let sectionsToShow = []
if (process.env.VUE_APP_ENVIROMENT != "deploy") {
  sectionsToShow = [...amgSections]
} else {
  sectionsToShow = amgSections
}

export default sectionsToShow
