<template>
  <div>
    <div class="text-left" style="margin-bottom: -15px !important">
      <router-link
        v-if="moduleId != 16"
        :class="[textLink]"
        :to="{
          name: toRoute,
          params: {
            id: item.lead_id,
          },
        }"
        target="_blank"
        >{{ item.client }}</router-link
      >
      <div v-else class="text-warning" style="font-weight: bold">
        {{ item.client }}
      </div>
      <FeatherIcon
        v-if="parseInt(item.is_urgent) === 1"
        icon="InfoIcon"
        v-b-tooltip.hover.right.v-danger
        title="IS URGENT"
        class="ml-1 text-danger cursor-pointer"
        size="18"
      />
      <tabler-icon
        v-if="item.status != 1 && moduleId != 16"
        icon="MessageCircleIcon"
        class="text-primary ml-1 cursor-pointer"
        size="22"
        @click="openBinnacleModal()"
      />
      <p class="mb-0">
        {{ item.mobile }}
      </p>
      <p class="mb-0">
        <small>{{ item.state }}, {{ item.country }}</small>
      </p>
      <p>
        <small>{{ item.sourcesname }}</small>
      </p>
    </div>
    <BinnacleModal
      v-if="modal.binnacle"
      :binnacle="binnacle"
      :disableSends="item.status == 4"
      @close="modal.binnacle = false"
    />
  </div>
</template>

<script>
import BinnacleModal from "@/views/debt-solution/views/sales-made/modal/BinnacleModal.vue";
export default {
  components: {
    BinnacleModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    moduleId: {
      type: Number,
      required: true,
    },
    toRoute: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      binnacle: {},
      modal: {
        binnacle: false,
      },
    };
  },
  methods: {
    openBinnacleModal() {
      if (this.item.binnacle_origin == "DS") {
        this.$set(this.item, "binnacle_cont", 0);
      }
      this.binnacle.clientName = this.item.client;
      this.binnacle.mobile = this.item.mobile;
      this.binnacle.state = this.item.state;
      this.binnacle.status = this.item.status;
      this.binnacle.sale_id = this.item.id;
      this.binnacle.seller = this.item.seller;
      this.binnacle.origin = "CRM";
      this.modal.binnacle = true;
    },
  },
};
</script>