var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modalNotesFirstCurrent",attrs:{"modal-class":"custom-modal-amg","header-class":"p-0","header-bg-variant":"transparent","title":"Business Note","size":"xmd","no-close-on-backdrop":"","scrollable":""},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('header-modal-notes',{attrs:{"info":_vm.noteInfo,"program":"Bussiness"},on:{"close":function($event){return _vm.$emit('hidden')}}})]},proxy:true},{key:"modal-footer",fn:function(){return [(_vm.showButtonSave)?_c('button-save',{on:{"click":_vm.saveNotesIncomplete}}):_vm._e(),(_vm.showButtonSave)?_c('button-save-and-complete',{on:{"click":_vm.saveNotesCompleted}}):_vm._e(),(_vm.showButtonUpdate)?_c('button-update',{on:{"click":_vm.updateNotesCompleted}}):_vm._e()]},proxy:true}])},[(!_vm.isNewSale)?_c('div',{staticClass:"d-flex justify-content-end mb-1"},[_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.$emit('showOldVersion')}}},[_vm._v("Show Old Version")])],1):_vm._e(),_c('validation-observer',{ref:"form"},[_c('b-row',{staticClass:"mx-0"},[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Tax ID"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.taxIdOptions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},model:{value:(_vm.note.taxId),callback:function ($$v) {_vm.$set(_vm.note, "taxId", $$v)},expression:"note.taxId"}})]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Contact Time"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.contactTimeOptions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},model:{value:(_vm.note.contactTime),callback:function ($$v) {_vm.$set(_vm.note, "contactTime", $$v)},expression:"note.contactTime"}})]}}])})],1)],1),_c('hr',{staticClass:"w-100 mx-1"})],1),_c('b-row',{staticClass:"border rounded-lg m-1 p-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("1. ADMINISTRATIVE")]),_c('hr')]),_c('b-col',{attrs:{"cols":"12","sm":_vm.isNotRegister ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"New Business"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},on:{"input":function($event){return _vm.resetFields(1)}},model:{value:(_vm.note.new_business),callback:function ($$v) {_vm.$set(_vm.note, "new_business", $$v)},expression:"note.new_business"}})]}}])})],1)],1),(_vm.isNotNewBusiness)?[_c('b-col',{attrs:{"cols":"12","sm":_vm.isNotRegister ? 6 : 4}},[_c('b-form-group',{attrs:{"label":"Register"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},on:{"input":function($event){return _vm.resetFields(2)}},model:{value:(_vm.note.register),callback:function ($$v) {_vm.$set(_vm.note, "register", $$v)},expression:"note.register"}})]}}],null,false,1906172668)})],1)],1)]:_vm._e(),(_vm.isNewBusiness || _vm.isNotRegister)?[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Notes"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.editorOption,"disabled":_vm.disabled},model:{value:(_vm.note.notes),callback:function ($$v) {_vm.$set(_vm.note, "notes", $$v)},expression:"note.notes"}})]}}],null,false,2679304942)})],1)],1)]:_vm._e(),(_vm.isNotNewBusiness && _vm.isRegister)?[[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Company"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},on:{"input":function($event){return _vm.resetFields(3)}},model:{value:(_vm.note.corporation),callback:function ($$v) {_vm.$set(_vm.note, "corporation", $$v)},expression:"note.corporation"}})]}}],null,false,1259060602)})],1)],1),(_vm.isCorporation)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Type"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.corporationTypeOptions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},model:{value:(_vm.note.corporationType),callback:function ($$v) {_vm.$set(_vm.note, "corporationType", $$v)},expression:"note.corporationType"}})]}}],null,false,1898057422)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":_vm.notesCompleted && _vm.note.corporationImgeUrl
                    ? 'Show Company Image'
                    : 'Upload Company Image'}},[_c('b-input-group',[(_vm.note.corporationImgeUrl)?_c('b-input-group-append',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Preview Image'),expression:"'Preview Image'",modifiers:{"hover":true,"left":true}}],staticClass:"cursor-pointer",attrs:{"is-text":""},on:{"click":function($event){return _vm.openPreviewImage(_vm.note.corporationImgeUrl)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1):_vm._e(),_c('b-form-file',{attrs:{"placeholder":"Choose an image or drop it here...","drop-placeholder":"Drop image here...","accept":"image/jpeg, image/png, image/bmp, image/webp","disabled":_vm.disabled},on:{"change":function($event){return _vm.loadFile($event, 1)}},model:{value:(_vm.note.corporationImge),callback:function ($$v) {_vm.$set(_vm.note, "corporationImge", $$v)},expression:"note.corporationImge"}})],1)],1)],1)]:_vm._e()],(_vm.isNotNullCorporation)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Bank Account"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},on:{"input":function($event){return _vm.resetFields(4)}},model:{value:(_vm.note.bankAccount),callback:function ($$v) {_vm.$set(_vm.note, "bankAccount", $$v)},expression:"note.bankAccount"}})]}}],null,false,507028980)})],1)],1),(_vm.isBankAccount)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Bank Type"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.bankTypeOptions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},model:{value:(_vm.note.bankType),callback:function ($$v) {_vm.$set(_vm.note, "bankType", $$v)},expression:"note.bankType"}})]}}],null,false,2131512878)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Bank Name"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.bankNameOptions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},model:{value:(_vm.note.bankName),callback:function ($$v) {_vm.$set(_vm.note, "bankName", $$v)},expression:"note.bankName"}})]}}],null,false,168227150)})],1)],1)]:_vm._e()]:_vm._e(),(_vm.isNotNullBankAccount)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Bookkeeping Up To Date"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},on:{"input":function($event){return _vm.resetFields(5)}},model:{value:(_vm.note.bookeeping),callback:function ($$v) {_vm.$set(_vm.note, "bookeeping", $$v)},expression:"note.bookeeping"}})]}}],null,false,2647003883)})],1)],1),(_vm.isBookeepingUpToDate)?[_c('b-col',{staticClass:"12",attrs:{"sm":"4"}},[_c('b-form-group',{attrs:{"label":"Bookkeeping Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var ariaDescribedby = ref.ariaDescribedby;
return [_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"w-100",attrs:{"id":"btn-radios-1","options":_vm.bookeepingTypeOptions,"aria-describedby":ariaDescribedby,"name":"bookeepingType","buttons":"","button-variant":errors.length > 0 ? 'outline-danger' : 'outline-primary',"state":errors[0] ? false : null,"disabled":_vm.disabled},model:{value:(_vm.note.bookeepingType),callback:function ($$v) {_vm.$set(_vm.note, "bookeepingType", $$v)},expression:"note.bookeepingType"}})]}}],null,true)})]}}],null,false,2250880575)})],1)]:_vm._e(),(!_vm.isBookeepingUpToDate && _vm.isNotNullBookeepingUpToDate)?[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Bookkeeping Description"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.editorOption,"disabled":_vm.disabled},model:{value:(_vm.note.bookeepingDescription),callback:function ($$v) {_vm.$set(_vm.note, "bookeepingDescription", $$v)},expression:"note.bookeepingDescription"}})]}}],null,false,2332280178)})],1)],1)]:_vm._e()]:_vm._e(),(_vm.isNotNullBookeepingUpToDate)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Taxes Up To Date"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},model:{value:(_vm.note.taxes),callback:function ($$v) {_vm.$set(_vm.note, "taxes", $$v)},expression:"note.taxes"}})]}}],null,false,3583695651)})],1)],1),(_vm.isNotNullTaxes)?[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Tax Description"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.editorOption,"disabled":_vm.disabled},model:{value:(_vm.note.taxesDescription),callback:function ($$v) {_vm.$set(_vm.note, "taxesDescription", $$v)},expression:"note.taxesDescription"}})]}}],null,false,3573685872)})],1)],1)]:_vm._e()]:_vm._e(),(_vm.isNotNullTaxes)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Merchant"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},on:{"input":function($event){return _vm.resetFields(6)}},model:{value:(_vm.note.merchant),callback:function ($$v) {_vm.$set(_vm.note, "merchant", $$v)},expression:"note.merchant"}})]}}],null,false,673019579)})],1)],1),(_vm.isMerchant)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Merchant Type"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.merchantTypeOptions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},model:{value:(_vm.note.merchantType),callback:function ($$v) {_vm.$set(_vm.note, "merchantType", $$v)},expression:"note.merchantType"}})]}}],null,false,103313070)})],1)],1)]:_vm._e(),(_vm.isNotNullMerchant && !_vm.isMerchant)?[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Merchant Description"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.editorOption,"disabled":_vm.disabled},model:{value:(_vm.note.merchantDescription),callback:function ($$v) {_vm.$set(_vm.note, "merchantDescription", $$v)},expression:"note.merchantDescription"}})]}}],null,false,539883873)})],1)],1)]:_vm._e()]:_vm._e(),(_vm.isNotNullMerchant)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Business Credit"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},model:{value:(_vm.note.businessCredit),callback:function ($$v) {_vm.$set(_vm.note, "businessCredit", $$v)},expression:"note.businessCredit"}})]}}],null,false,2601902195)})],1)],1),(_vm.isNotNullBusinessCredit)?[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Business Credit Description"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.editorOption,"disabled":_vm.disabled},model:{value:(_vm.note.businessCreditDescription),callback:function ($$v) {_vm.$set(_vm.note, "businessCreditDescription", $$v)},expression:"note.businessCreditDescription"}})]}}],null,false,3455804832)})],1)],1)]:_vm._e()]:_vm._e()]:_vm._e()],2),(_vm.isNotNewBusiness && _vm.isRegister && _vm.isNotNullBusinessCredit)?[_c('b-row',{staticClass:"border rounded-lg m-1 p-1"},[_c('b-col',{attrs:{"cols":"12 mb-2 pb-1 border-bottom"}},[_c('strong',[_vm._v("2. MARKETING")])]),(_vm.isNotNullBusinessCredit)?[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Logo"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},on:{"input":function($event){return _vm.resetFields(7)}},model:{value:(_vm.note.logo),callback:function ($$v) {_vm.$set(_vm.note, "logo", $$v)},expression:"note.logo"}})]}}],null,false,252751067)})],1)],1),(_vm.isLogo)?[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.notesCompleted && _vm.note.logoImageUrl
                    ? 'Show Logo'
                    : 'Upload Logo'}},[_c('b-input-group',[(_vm.note.logoImageUrl)?_c('b-input-group-append',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Preview Image'),expression:"'Preview Image'",modifiers:{"hover":true,"left":true}}],staticClass:"cursor-pointer",attrs:{"is-text":""},on:{"click":function($event){return _vm.openPreviewImage(_vm.note.logoImageUrl)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1):_vm._e(),_c('b-form-file',{attrs:{"placeholder":"Choose an image or drop it here...","drop-placeholder":"Drop image here...","accept":"image/jpeg, image/png, image/bmp, image/webp","disabled":_vm.disabled},on:{"change":function($event){return _vm.loadFile($event, 2)}},model:{value:(_vm.note.logoImage),callback:function ($$v) {_vm.$set(_vm.note, "logoImage", $$v)},expression:"note.logoImage"}})],1)],1)],1)]:_vm._e(),(!_vm.isLogo && _vm.isNotNullLogo)?[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Logo Description"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.editorOption,"disabled":_vm.disabled},model:{value:(_vm.note.logoDescription),callback:function ($$v) {_vm.$set(_vm.note, "logoDescription", $$v)},expression:"note.logoDescription"}})]}}],null,false,1806258560)})],1)],1)]:_vm._e()]:_vm._e(),(_vm.isNotNullLogo)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Website"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},on:{"input":function($event){return _vm.resetFields(8)}},model:{value:(_vm.note.website),callback:function ($$v) {_vm.$set(_vm.note, "website", $$v)},expression:"note.website"}})]}}],null,false,2621996420)})],1)],1),(_vm.isWebsite)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Website Type"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.websiteTypeOptions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},model:{value:(_vm.note.websiteType),callback:function ($$v) {_vm.$set(_vm.note, "websiteType", $$v)},expression:"note.websiteType"}})]}}],null,false,3817838446)})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Website URL"}},[_c('validation-provider',{attrs:{"rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"placeholder":"Write here...","disabled":_vm.disabled},model:{value:(_vm.note.websiteUrl),callback:function ($$v) {_vm.$set(_vm.note, "websiteUrl", $$v)},expression:"note.websiteUrl"}})]}}],null,false,3508122380)})],1)],1)]:_vm._e(),(_vm.isNotNullWebsite)?[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Website Description"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.editorOption,"disabled":_vm.disabled},model:{value:(_vm.note.websiteDescription),callback:function ($$v) {_vm.$set(_vm.note, "websiteDescription", $$v)},expression:"note.websiteDescription"}})]}}],null,false,396575280)})],1)],1)]:_vm._e()]:_vm._e(),(_vm.isNotNullWebsite)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Fan Page"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},on:{"input":function($event){return _vm.resetFields(9)}},model:{value:(_vm.note.fanpage),callback:function ($$v) {_vm.$set(_vm.note, "fanpage", $$v)},expression:"note.fanpage"}})]}}],null,false,2714316452)})],1)],1),(_vm.isFanpage)?[_c('b-col',{attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":"Fan Page Type"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.fanpageTypeOptions,"multiple":"","placeholder":"Select an option","disabled":_vm.disabled},on:{"input":_vm.buildFanpageUrl},model:{value:(_vm.note.fanpageType),callback:function ($$v) {_vm.$set(_vm.note, "fanpageType", $$v)},expression:"note.fanpageType"}})]}}],null,false,800522058)})],1)],1),_vm._l((_vm.note.fanpageUrl),function(item,index){return [_c('b-col',{key:index,attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label":((_vm.note.fanpageType[index]) + " Url")}},[_c('validation-provider',{attrs:{"rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"placeholder":"Write here...","disabled":_vm.disabled},model:{value:(_vm.note.fanpageUrl[index]),callback:function ($$v) {_vm.$set(_vm.note.fanpageUrl, index, $$v)},expression:"note.fanpageUrl[index]"}})]}}],null,true)})],1)],1)]})]:_vm._e(),(_vm.isNotNullFanpage)?[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Fan Page Description"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.editorOption,"disabled":_vm.disabled},model:{value:(_vm.note.fanpageDescription),callback:function ($$v) {_vm.$set(_vm.note, "fanpageDescription", $$v)},expression:"note.fanpageDescription"}})]}}],null,false,3799153233)})],1)],1)]:_vm._e()]:_vm._e(),(_vm.isNotNullFanpage)?[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Ads"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.generalOPtions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},on:{"input":function($event){return _vm.resetFields(10)}},model:{value:(_vm.note.ads),callback:function ($$v) {_vm.$set(_vm.note, "ads", $$v)},expression:"note.ads"}})]}}],null,false,1047273904)})],1)],1),(_vm.isAds)?[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Ads Type"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': errors.length > 0 },attrs:{"options":_vm.adsTypeOptions,"clearable":false,"placeholder":"Select an option","disabled":_vm.disabled},model:{value:(_vm.note.adsType),callback:function ($$v) {_vm.$set(_vm.note, "adsType", $$v)},expression:"note.adsType"}})]}}],null,false,1623255502)})],1)],1)]:_vm._e(),(_vm.isNotNullAds)?[_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Ads Description"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.editorOption,"disabled":_vm.disabled},model:{value:(_vm.note.adsDescription),callback:function ($$v) {_vm.$set(_vm.note, "adsDescription", $$v)},expression:"note.adsDescription"}})]}}],null,false,476216925)})],1)],1)]:_vm._e()]:_vm._e()],2)]:[_c('b-row',{staticClass:"border rounded-lg m-1 p-1 disabled-row",attrs:{"disabled":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',{staticClass:"text-muted"},[_vm._v("2. MARKETING")]),_c('hr')])],1)],_c('b-row',{staticClass:"mx-1 p-1 border rounded-lg"},[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("3. COMMENTS ")]),_c('hr')]),_c('b-col',{attrs:{"cols":"12","sm":"12"}},[_c('b-form-group',[_c('quill-editor',{attrs:{"options":_vm.editorOption,"disabled":_vm.disabled},model:{value:(_vm.note.comments),callback:function ($$v) {_vm.$set(_vm.note, "comments", $$v)},expression:"note.comments"}})],1)],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }