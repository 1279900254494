import Pusher from "pusher-js"
import {
  ApprovalProcessConfirmDebit,
  ApprovalProcessGenerateOrdenPending,
  BankAccountInputBlanace,
  AssignChargebackResponsible,
  UpdateColumnTimeExpired,
  NotifyEvidenceFileUploadToManagement,
  NotifyManagementOfPaymentInsert,
  NewClaimRequestRegistered,
  ObserveSaleNotification,
  NotificationSaleMade,
} from "@/views/management/management.socket.js"
// import { BinnacleMessageNotification } from "@/views/debt-solution/deb-solution.socket"
import { SalaryRecordNotification } from "@/views/management/views/salary-record/salary-record.socket.js"

const subscribeSocketManagement = (socket) => {
  try {
    socket.subscribe("management-notifications")
    ApprovalProcessConfirmDebit()
    ApprovalProcessGenerateOrdenPending()
    BankAccountInputBlanace()
    NewClaimRequestRegistered()
    // BinnacleMessageNotification()
    AssignChargebackResponsible()
    UpdateColumnTimeExpired()
    NotifyEvidenceFileUploadToManagement()
    NotifyManagementOfPaymentInsert()
    SalaryRecordNotification()
    ObserveSaleNotification()
    NotificationSaleMade()
  } catch (error) {
    console.error(error, "error")
  }
}

export default subscribeSocketManagement
