import { requestWpTabs } from "@/views/commons/components/request-workplan/router/request-workplan.tabs.js";

export default [
  {
    path: "request-workplan",
    name: "request-workplan-ce-digital",
    redirect: { name: "ce-digital-wp-pending" },
    meta: {
      pageTitle: "Request Workplan",
      breadcrumb: [
        {
          text: "Request Workplan",
          active: true,
        },
      ],
      routePending: "ce-digital-wp-pending",
      routeCompleted: "ce-digital-wp-completed",
      routeToRevision: "ce-digital-wp-to-revision",
    },
    component: () =>
      import(
        "@/views/commons/components/request-workplan/RequestWorkplanMain.vue"
      ),
    children: requestWpTabs('ce-digital')
  }
]