<template>
  <b-modal
    title="NEW SMS"
    v-model="modalShow"
    size="md"
    @hidden="$emit('hidden')"
    centered
    ok-title="Save"
    hide-footer
  >
    <validation-observer ref="form">
      <b-row class="mb-2">
        <b-col cols="4">
          <b-form-group label="From:">
            <div class="d-flex" style="margin-top: 5px">
              <span style="margin-right: 5px">Lead</span>
              <b-form-checkbox
                v-model="optionSearch"
                name="check-button"
                switch
              >
              </b-form-checkbox>
              <span>Number</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="8" v-if="!optionSearch">
          <validation-provider v-slot="{ errors }" rules="customRequired">
            <b-form-group label="Search:">
              <vue-autosuggest
                ref="autocomplete"
                id="leads"
                v-model="searchTxt"
                :suggestions="filteredOptions"
                :get-suggestion-value="getSuggestionValue"
                :input-props="{
                  id: 'autosuggest__input',
                  class: ['form-control ', errors[0]],
                  placeholder: 'Search by Name, Phone number',
                }"
                @input="onInputChange"
                @selected="selectHandler"
              >
                <template slot="before-input">
                  <div class="lds-dual-ring" v-show="loadingAutosuggest"></div>
                </template>
                <template slot-scope="{ suggestion }">
                  <span class="my-suggestion-item">{{
                    suggestion.item.lead_name
                      ? suggestion.item.lead_name
                      : suggestion.item.nickname
                  }}</span>
                  - <span>{{ suggestion.item.mobile }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="8" v-else>
          <validation-provider v-slot="{ errors }" rules="required">
            <b-form-group label="Phone Number:">
              <b-form-input
                id="to-phone"
                v-mask="'(###) ###-####'"
                v-model="toNumber"
                trim
                maxlength="14"
                :class="errors[0] ? 'is-invalid' : ''"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
    <div class="d-flex justify-content-end">
      <b-button variant="outline-secondary" @click="$emit('hidden')">
        Cancel
      </b-button>
      <b-button class="ml-1" variant="primary" @click="storeChat">
        Create
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import NestSmsService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import { mapGetters } from "vuex";
import { VueAutosuggest } from "vue-autosuggest";
export default {
  props: {
    credential: {
      type: Object,
      required: true,
    },
  },
  components: {
    VueAutosuggest,
  },
  data() {
    return {
      modalShow: true,
      toNumber: null,
      optionSearch: false,

      filteredOptions: [],

      loadingAutosuggest: false,
      leadSelected: {
        id: null,
      },
      searchTxt: null,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  methods: {
    async storeChat() {
      let isValid = this.$refs["form"].validate();
      if (!isValid) return;

      try {
        this.addPreloader();

        const firstNumber = this.credential.number_format;

        let secondNumber = null;
        let leadId = null;
        let leadName = null;

        if (!this.optionSearch) {
          if (!this.leadSelected.id) return;

          secondNumber = this.leadSelected.mobile;
          leadId = this.leadSelected.id;
          leadName = this.leadSelected.lead_name
            ? this.leadSelected.lead_name
            : this.leadSelected.nickname;
        } else {
          secondNumber = this.toNumber;
        }

        const createdBy = {
          id: this.currentUser.user_id,
          name: this.currentUser.fullName,
        };

        const params = {
          firstNumber: firstNumber,
          secondNumber: secondNumber,
          createdBy: createdBy,
          leadId: leadId,
          leadName: leadName,
        };
        const { data, status } = await NestSmsService.createChat(params);
        if (status == 201) {
          this.showSuccessSwal();
          this.$emit("newChat", data);
        }
      } catch (error) {
        if (error.response.data.statusCode == 409) {
          this.showInfoSwal(error.response.data.message);
        } else {
          this.showErrorSwal(error.response.data.message);
        }
      } finally {
        this.removePreloader();
      }
    },

    async onInputChange(text) {
      this.loadingAutosuggest = true;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        this.loadingAutosuggest = false;
        return;
      }

      try {
        const { data } = await SmsService.searchLeadByPhoneName({
          search: text,
        });

        this.filteredOptions = [
          {
            data: data.data,
          },
        ];
        // this.filteredOptions = data;
        this.loadingAutosuggest = false;
      } catch (error) {
        console.log(error);
      }
    },

    selectHandler(lead) {
      if (lead === undefined || lead === null) {
        return;
      }

      this.leadSelected = { ...lead.item };
      this.text = "";
    },

    getSuggestionValue(suggestion) {
      const name = suggestion.item.lead_name
        ? suggestion.item.lead_name
        : suggestion.item.nickname;
      return name + " - " + suggestion.item.mobile;
    },
  },

  watch: {
    optionSearch() {
      this.searchTxt = null;
      this.leadSelected = {
        id: null,
      };
    },
  },

  mounted() {},
};
</script>