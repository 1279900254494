import Vue from "vue";
import Vuex from "vuex";

// import Transaction from '../views/transactions/store'
import Request from "@/views/logistic/views/request/request.store.js";

Vue.use(Vuex);

export default {
    // LogisticTransactionStore: Transaction|
    RequestStore: Request,
}