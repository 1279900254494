export default [
    {
      path: "calendar",
      name: "calendar-bussiness",
      component: () => import(/* webpackChunkName: "BusinessCalendar" */ "@/views/business/views/calendar/calendar.vue"),
      meta: {
        pageTitle: "Calendar",
        breadcrumb: [
          {
            text: "Calendar",
          },
        ],
        permittedRoles: [1,2],
      },
    },
  ];
  