import { requestNcrSubTab } from "@/views/commons/components/request-ncr/router/request-ncr.subtabs";

export default [
  {
    path: "request-ncr",
    name: "request-ncr-quality",
    redirect: { name: "qua-ncr-client" },
    meta: {
      pageTitle: "Request NCR",
      breadcrumb: [
        {
          text: "Request NCR",
          active: true,
        },
      ],
      routeClient: "qua-ncr-client",
      routeLead: "qua-ncr-lead",
    },
    component: () =>
      import(
        /* webpackChunkName: "DefaultNameSection" */
        "@/views/commons/components/request-ncr/RequestNcrMain.vue"
      ),
    children: [
      {
        path: "client",
        name: "qua-ncr-client",
        redirect: { name: "qua-ncr-client-pending" },
        component: () =>
          import(
            /* webpackChunkName: "DefaultNameFirstTab" */
            "@/views/commons/components/request-ncr/RequestNcrClient.vue"
          ),
        meta: {
          pageTitle: "Request NCR",
          tabName: "client",
          breadcrumb: [
            {
              text: "Client",
              active: true,
            },
          ],
          routeClientPending: "qua-ncr-client-pending",
          routeClientFinished: "qua-ncr-client-finished",
          routeClientAnnulled: "qua-ncr-client-annulled",
          paramTab: "CLIENT",
        },
        children: requestNcrSubTab("client", "CLIENT", "qua"),
      },
      {
        path: "lead",
        name: "qua-ncr-lead",
        component: () =>
          import(
            /* webpackChunkName: "DefaultNameSecondTab" */
            "@/views/commons/components/request-ncr/RequestNcrLead.vue"
          ),
        redirect: { name: "qua-ncr-lead-pending" },
        meta: {
          pageTitle: "Request NCR",
          tabName: "lead",
          tabTitle: "Lead",
          breadcrumb: [
            {
              text: "Lead",
              active: true,
            },
          ],
          routeLeadPending: "qua-ncr-lead-pending",
          routeLeadFinished: "qua-ncr-lead-finished",
          routeLeadAnnulled: "qua-ncr-lead-annulled",
        },
        children: requestNcrSubTab("lead", "LEAD", "qua"),
      },
    ],
  },
];
