import { amgApi } from '@/service/axios';

class RequestClientStatus {
  async getRequestClientStatus(body) {
    try {
      const data = await amgApi.post('/management/request-client-status/get-request-client-status', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeStatus(params) {
    try{
        const data = await amgApi.post('/administration/change-status', params)
       return data
    } catch (error) {
        throw error;
    }
  }

  async ChangeStatusRejected(params) {
    try{
       const data = await amgApi.post('/administration/change-status-rejected', params)
       return data
    } catch (error) {
       throw error;
    }
  }

  
  async getCountPendingChangeStatus(params) {
    try{
       const data = await amgApi.post('/administration/get-count-pending-change-status',params)
       return data
    } catch (error) {
       throw error;
    }
  }

} 
export default new RequestClientStatus();
