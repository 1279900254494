<template>
  <div>
    <div class="step-progress__wrapper">
      <div
        class="step-progress__wrapper-before"
        :style="{
          'background-color': passiveColor,
          height: lineThickness + 'px',
        }"
      ></div>
      <div class="step-progress__bar">
        <div
          v-for="(step, index) in steps"
          :key="index"
          class="step-progress__step"
          :style="{
            '--activeColor': activeColor,
            '--passiveColor': passiveColor,
            '--activeBorder': activeThickness + 'px',
            '--passiveBorder': passiveThickness + 'px',
          }"
          :class="{
            'step-progress__step--active': index === currentStep - 1,
            'step-progress__step--valid': index < currentStep - 1,
          }"
        >
          <span>{{ index + 1 }}</span>
          <amg-icon
            size="38"
            class="step-progress__step-icon"
            :icon="icons[index]"
          />
          <div class="step-progress__step-label">
            {{ step }}
          </div>
        </div>
      </div>
      <div
        class="step-progress__wrapper-after"
        :style="{
          transform:
            'scaleX(' + scaleX + ') translateY(-50%) perspective(1000px)',
          'background-color': activeColor,
          height: lineThickness + 'px',
        }"
      ></div>
    </div>
    <div class="mt-5" v-if="!viewOnly">
      <slot :step="currentStep"></slot>
      <div v-if="finalStep" class="d-flex justify-content-center">
        <img
          src="@/assets/images/icons/completed.svg"
          alt="scope"
          class="img-fluid"
          style="height: 40vh"
        />
      </div>
    </div>
    <div class="buttons" v-if="!viewOnly">
      <b-button
        v-if="canGoBack"
        variant="custom-amg"
        :disabled="!canGoBack"
        size="md"
        @click="prev(currentStep)"
      >
        {{ prevButtonLabel }}</b-button
      >
      <b-button
        v-if="canProceed"
        variant="custom-amg"
        size="md"
        @click="next(currentStep)"
        :disabled="!canProceed"
      >
        {{ nextButtonLabel }}</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "StepProgress",
  props: {
    steps: {
      type: Array,
      default() {
        return [];
      },
      validator(val) {
        return val && val.length > 0;
      },
    },
    icons: {
      type: Array,
      required: false,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
    iconClass: {
      type: String,
      default: "CheckCircleIcon",
    },
    activeColor: {
      type: String,
      default: "#3F7AFA",
    },
    passiveColor: {
      type: String,
      default: "gray",
    },
    activeThickness: {
      type: Number,
      default: 5,
    },
    passiveThickness: {
      type: Number,
      default: 5,
    },
    lineThickness: {
      type: Number,
      default: 12,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
    validationNext: {
      type: Boolean,
      default: false,
    },
    validationPrev: {
      type: Boolean,
      default: false,
    },
    nextButtonText: {
      type: [String, Array],
      default() {
        return Array(this.steps.length).fill("Next");
      },
    },
    prevButtonText: {
      type: [String, Array],
      default() {
        return Array(this.steps.length).fill("Prev");
      },
    },
  },
  computed: {
    scaleX() {
      let step = this.currentStep - 1;
      if (step < 0) {
        step = 0;
      } else if (step >= this.steps.length) {
        step = this.steps.length - 1;
      }
      return step / (this.steps.length - 1);
    },
    finalStep() {
      return this.steps.length === this.currentStep - 1;
    },
    canProceed() {
      return this.currentStep <= this.steps.length && this.validationNext;
    },
    canGoBack() {
      return this.currentStep > 1 && this.validationPrev;
    },
    prevButtonLabel() {
      return Array.isArray(this.prevButtonText)
        ? this.prevButtonText[this.currentStep - 1] || "Prev"
        : this.prevButtonText;
    },
    nextButtonLabel() {
      return Array.isArray(this.nextButtonText)
        ? this.nextButtonText[this.currentStep - 1] || "Next"
        : this.nextButtonText;
    },
  },
  methods: {
    next() {
      if (this.canProceed) {
        this.$emit("step-changed", this.currentStep + 1);
      }
    },
    prev() {
      if (this.canGoBack) {
        this.$emit("step-prev", this.currentStep - 1);
      }
    },
  },
};
</script>

<style lang="scss">
.step-progress {
  &__wrapper {
    width: 90%;
    margin: 0 auto;
    position: relative;
  }

  &__wrapper-before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    height: 12px;
    width: 100%;
    background-color: gray;
    transform: translateY(-50%) perspective(1000px);
  }

  &__wrapper-after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    height: 12px;
    width: 100%;
    background-color: #3f7afa;
    transform: scaleX(0) translateY(-50%) perspective(1000px);
    transform-origin: left center;
    transition: transform 0.5s ease;
  }

  &__bar {
    width: 100%;
    display: flex;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }

  &__step {
    z-index: 2;
    position: relative;
    --activeColor: #3f7afa;
    --passiveColor: gray;
    --activeBorder: 5px;
    --passiveBorder: 5px;
    span {
      color: var(--passiveColor);
      transition: 0.3s ease;
      display: block;
      font-size: 50px;
      transform: translate3d(0, 0, 0) scale(1) perspective(1000px);
      font-weight: 900;
      text-align: center;
      opacity: 1;
    }

    &--active {
      span {
        color: var(--activeColor);
      }
      .step-progress__step-label {
        color: var(--activeColor);
      }
      .step-progress__step-icon {
        opacity: 1;
      }
    }

    &--valid {
      .step-progress__step-icon {
        opacity: 1;
        transform: translate3d(-50%, -50%, 0) scale(1) perspective(1000px);
      }
      span {
        color: var(--activeColor);
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(2) perspective(1000px);
      }
      .step-progress__step-label {
        color: var(--activeColor);
      }
    }

    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) perspective(1000px);
      width: 75px;
      height: 75px;
      background-color: #fff;
      border-radius: 50%;
      border: var(--passiveBorder) solid var(--passiveColor);
      transition: 0.3s ease;

      @media (max-width: 767px) {
        width: 40px;
        height: 40px;
      }
    }

    &--active:after {
      border: var(--activeBorder) solid var(--activeColor);
    }

    &--valid:after {
      background-color: var(--activeColor);
      border: var(--activeBorder) solid var(--activeColor);
    }
  }

  &__step-label {
    position: absolute;
    top: calc(100% + 25px);
    left: 50%;
    transform: translateX(-50%) perspective(1000px);
    white-space: nowrap;
    font-size: 18px;
    font-weight: 600;
    color: gray;
    transition: 0.3s ease;
  }

  &__step-label-detail {
    position: absolute;
    top: calc(100% + 50px);
    left: 50%;
    transform: translateX(-50%) perspective(1000px);
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: gray;
    transition: 0.3s ease;
  }

  &__step-icon {
    font-size: 36px;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transition: transform 0.3s ease;
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(0) perspective(1000px);

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
}
.buttons {
  text-align: center;
}
.buttons button {
  margin-left: 10px;
}
</style>
