export default {
  namespaced: true,
  state: {
    S_COUNTER_PENDING_DIRECT_ROUNDS: 0,
    S_UPDATE_LETTER_TABLE: false,
  },
  mutations: {
    M_SET_COUNTER_PENDING_DIRECT_ROUNDS(state, payload = { pending: 0 }) {
      state.S_COUNTER_PENDING_DIRECT_ROUNDS = payload.pending
    },
    M_SET_UPDATE_LETTER_TABLE(state, payload) {
      state.S_UPDATE_LETTER_TABLE = payload
    }
  },
  actions: {
    A_SET_COUNTER_PENDING_DIRECT_ROUNDS({ commit }, payload = { pending: 0 }) {
      commit('M_SET_COUNTER_PENDING_DIRECT_ROUNDS', payload)
    },
    A_S_UPDATE_LETTER_TABLE({ commit }, payload) {
      commit('M_SET_UPDATE_LETTER_TABLE', payload)
    }
  },
  getters: {
    G_COUNTER_PENDING_DIRECT_ROUNDS: state => state.S_COUNTER_PENDING_DIRECT_ROUNDS,
    G_UPDATE_LETTER_TABLE: state => state.S_UPDATE_LETTER_TABLE
  }
}