import { amgApi } from "@/service/axios";

class CreativeRequestService {
  async searchCreativeDesign(params) {
    try {
      const { data } = await amgApi.post(
        "/commissions/get-report-commissions",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPrograms() {
    try {
      const data = await amgApi.get("/creative/creative-request/get-programs");
      return data
    } catch (error) {
      throw error;
    }
  }
  async getdesignRequestCounterNotifications(params) {
    try {
      const data = await amgApi.post("/creative/creative-request/count-get-requets", params);
      return data
    } catch (error) {
      throw error;
    }
  }
  async getdesignRequestCounterNotificationsParagon(params) {
    try {
      const data = await amgApi.post("/creative/creative-request/count-get-requets-paragon", params);
      return data
    } catch (error) {
      throw error;
    }
  }

  async updateStatusViewMessages(params) {
    try {
      const response = await amgApi.post('/creative/creative-request/update-status-view-messages-module',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async insertCreativeBankFlyers(params) {
    try {
      const response = await amgApi.post('/creative/creative-request/insert-creative-bank-flyers',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async getRequests(params) {
    try {
      const data = await amgApi.post("/creative/creative-request/get-requests", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRequestTracking(params) {
    try {
      const data = await amgApi.post("/creative/creative-request/get-request-tracking", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDesignMaterialsByRequest(params) {
    try {
      const { data } = await amgApi.post("/creative/creative-request/get-design-materials-by-request", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDesignMaterialStatuses() {
    try {
      const data = await amgApi.post("/creative/creative-request/get-design-material-statuses");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCreativeDesignRequestDetails(params) {
    try {
      const { data } = await amgApi.post("/creative/creative-request/get-creative-design-request-details", params);
      return data;
    } catch (err) {
      throw err;
    }
  }

  async uploadDesignFilesForCreativeRequest(params) {
    try {
      const response = await amgApi.post("/creative/creative-request/upload-design-files-for-creative-request", params);
      return response;
    } catch (err) {
      throw err;
    }
  }

  async uploadVideoByChunks(params) {
    try {
      const response = await amgApi.post("/creative/video/upload-by-chunks", params);
      return response;
    } catch (err) {
      throw err;
    }
  }

  async changeRequestStatusDuedate(params) {
    try {
      const data = await amgApi.post("/creative/creative-request/change-request-status-duedate", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getMaterialTracking(params) {
    try {
      const data = await amgApi.post("/creative/creative-request/get-material-tracking", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getVideoMaterial(params){
    try {
      const data = await amgApi.post("/creative/creative-request/get-video-material", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async changeMaterialStatus(params) {
    try {
      const data = await amgApi.post("/creative/creative-request/change-material-status", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async saveNote(params){
    try {
      const data = await amgApi.post("/creative/creative-request/save-note", params)
      return data
    } catch (error) {
      throw error;
    }
  }

  async updateNote(params){
    try {
      const data = await amgApi.post("/creative/creative-request/update-note", params)
      return data
    } catch (error) {
      throw error;
    }
  }

  async getNote(params){
    try {
      const data = await amgApi.post("/creative/creative-request/get-note", params)
      return data
    } catch (error) {
      throw error;
    }
  }

  async saveRequestRevission(params){
    try {
      const data = await amgApi.post("/creative/creative-request/save-request-revision", params)
      return data;
    } catch (error) {
      throw error;
    }
  }

  async reuploadFile(params){
    try {
      const data = await amgApi.post("/creative/creative-request/upload-design-files-for-creative-request", params)
      return data;
    } catch (error) {
      throw error;
    }
  }

  async seeMessagesChatMaterial(params){
    try {
      const data = await amgApi.post("/creative/creative-request/see-messages-chat-material", params)
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getParagonDesigners(){
    try {
      const data = await amgApi.post("/creative/creative-request/get-paragon-designer-users")
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeDesigner(params){
    try{
      const data = await amgApi.post("/creative/creative-request/change-designer", params)
      return data;
    } catch (error) {
      throw error;
    }
  }
  
  async getParagonDesignerUsers() {
    try {
      const { data } = await amgApi.post("/creative/creative-request/get-paragon-designer-users");
      return data;
    } catch (err) {
      console.error("Something went wrong on getParagonDesignerUsers:", err);
    }
  }

  async createTaskInCreativeRequest(params) {
    try {
      const { data } = await amgApi.post("/creative/creative-request/create-task-in-creative-design-request", params);
      return data;
    } catch (err) {
      console.error("Something went wrong on createTaskInCreativeRequest:", err);
    }
  }
  async getDesignRequestStatuses() {
    try {
      const { data } = await amgApi.post("/creative/creative-request/get-design-request-statuses");
      return data;
    } catch (err) {
      console.error("Something went wrong on getDesignRequestStatuses:", err);
    }
  }
  async getRequestData(params){
    try {
      const data = await amgApi.post("/creative/creative-request/get-request-data", params);
      return data;
    } catch (err) {
      throw err;
    }
  }
  async getDesignMaterialAnotationsRequest(params){
    try {
      const data = await amgApi.post("/creative/creative-request/get-design-material-anotations-request", params);
      return data.data;  
    } catch (error) {
      throw error;
    }
  }

  async getDesignMaterialSelection(params){
      try {
        const data = await amgApi.post("/creative/creative-request/get-design-material-selected", params);
        return data.data;
      } catch (error) {
        throw error;
      }
    }

    async saveDesignMaterialAnotations(params){
      try {
        const data = await amgApi.post("/creative/creative-request/save-design-material-anotations", params);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async getMaterialAnotationsTracking(params){
      try {
        const data = await amgApi.post("/creative/creative-request/get-material-anotation-tracking", params);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async downloadMaterial(params){
      try {
          const data = await amgApi.post("/creative/creative-request/download-material", params, {
            responseType: "blob",
          });
          return data;
      } catch (error) {
          throw error;
      }
    }
    async NotificationCommentTeamLeaderDesignByDesigner(params){
      try {
        const data = await amgApi.post("/creative/creative-request/notification-team-leader-design-by-designer", params);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async getListByDesigner(params){
      try {
        const data = await amgApi.post("/creative/creative-request/get-list-by-designer", params);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async getDataByRequest(params){
      try {
        const data = await amgApi.post("/creative/creative-request/get-data-by-request", params);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async setAsSeen(params){
      try {
        const data = await amgApi.post("/creative/creative-request/set-as-seen", params);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async setAsSeenAll(params){
      try {
        const data = await amgApi.post("/creative/creative-request/set-as-seen-all", params);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async deleteContentGridReference(params){
      try {
        const data = await amgApi.post("/creative/creative-request/delete-content-grid-reference", params);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async sendToParagon(params){
      try {
        const data = await amgApi.post("/creative/creative-request/send-to-paragon", params);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async seeRequestNotifications(params){
      try {
        const data = await amgApi.post("/creative/creative-request/see-request-notifications", params);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async deleteRequest(params){
      try {
        const data = await amgApi.post("/creative/creative-request/delete-request", params);
        return data;
      } catch (error) {
        throw error;
      }
    }
}

export default new CreativeRequestService();