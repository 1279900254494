import CsAlertService from "../services/index.js";

export default {
  namespaced: true,
  state: {
    isPendingModal: false,
    alertActive: false,
    alertActionsActive: false,
    listPendingToCall: [],
    listPendingToCallId: [],
    currentAlert: {},
    appId: null,
    title: "Client name",
    message:
      "Este es elmodal que te redirigira al dasboard del cliente o reprogramara la cita",
    dateTime: "",
    countCalls: 0,
    params: {
      pending_to_call_id: 0, // desde SET_CURRENT_ALERT
      client_account_id: "", // desde SET_CURRENT_ALERT
      alert_action_id: 0, // desde modal
      global_alert_id: 0, 
      user_id: 0, // desde modal
      calls_count: 0, // desde SET_CURRENT_ALERT
      date_to_call: "", // desde SET_CURRENT_ALERT
      alert_type_id: 0, // desde SET_CURRENT_ALERT
    },
    // -- Alert Chat State
    alertChatActive: false,
    chatInfo: {},
  },
  mutations: {
    SET_PENDING_ALERT(state, status) {
      state.isPendingModal = status;
    },
    SET_ACTIVE_ALERT(state, status) {
      state.alertActive = status;
    },
    SET_ACTIVE_ACTIONS_ALERT(state, status) {
      state.alertActionsActive = status;
    },
    SET_DATE_TIME(state, dateTime) {
      state.params.date_to_call = dateTime;
    },
    SET_TITLE(state, title) {
      state.title = title;
    },
    SET_MESSAGE(state, message) {
      state.message = message;
    },
    SET_COUNT_CALLS(state, count) {
      state.params.countCalls = count;
    },
    SET_APP_ID(state, status) {
      state.appId = status;
    },
    ADD_COUNT_CALLS(state) {
      state.params.calls_count++;
    },
    SET_PARAMS(state, data) {
      state.params = data;
    },
    FILL_PENDING_TO_CALL(state, list) {
      state.listPendingToCall = list;
      // state.currentAlert = list[0]
      list.map((el) => state.listPendingToCallId.push({ alert_id: el.id }));
    },
    ADD_PENDING_TO_CALL(state, item) {
      state.listPendingToCall.push(item);
    },
    DELETE_LAST_ITEM_TO_CALL(state) {
      // state.currentAlert = {}

      state.listPendingToCall.splice(0, 1);

      // state.currentAlert = {...state.listPendingToCall[0]}
    },
    SET_CURRENT_ALERT(state, obj) {
      state.currentAlert = obj;

      const {
        id,
        client_account_id,
        global_alert_id,
        calls_count,
        date_to_call,
        alert_type_id,
      } = obj;

      state.params = {
        pending_to_call_id: id,
        client_account_id,
        global_alert_id,
        calls_count,
        date_to_call,
        alert_type_id,
      };
    },
    SET_USER_IN_ALERT(state, id) {
      state.params.user_id = id;
    },
    SET_ALERT_ACTION_ID_IN_ALERT(state, action) {
      state.params.alert_action_id = action;
    },
    SET_ALERT_CHAT(state, data) {
      state.alertChatActive = data;
    },
    SET_CHAT_INFO(state, data) {
      state.chatInfo = data;
    },
  },
  actions: {
    setPendingAlert({ commit }, status) {
      commit("SET_PENDING_ALERT", status);
    },
    setActiveAlert({ commit }, status) {
      commit("SET_ACTIVE_ALERT", status);
    },
    setActiveAlert({ commit }, status) {
      commit("SET_ACTIVE_ACTIONS_ALERT", status);
    },
    async available({ commit, state }) {
      const status = await CsAlertService.clientAvailable(state.params);
      return status;
    },
    async notAvailable({ commit, state }) {
      commit("ADD_COUNT_CALLS");
      const status = await CsAlertService.clientNotAvailable(state.params);
      return status;
    },
    async notAnswered({ commit, state }) {
      commit("ADD_COUNT_CALLS");

      const status = await CsAlertService.clientNotAnswered(state.params);
      return status;
    },
    async acceptAlert({ state, commit, getters }, { alert_id, user_id }) {
      commit("SET_CURRENT_ALERT", getters.getCurrentAlert);

      state.listPendingToCall.splice(0, 1);

      // state.currentAlert = {...state.listPendingToCall[0]}
    },
    SET_CURRENT_ALERT(state, obj) {
      state.currentAlert = obj;
    },
  },
  actions: {
    setPendingAlert({ commit }, status) {
      commit("SET_PENDING_ALERT", status);
    },
    setActiveAlert({ commit }, status) {
      commit("SET_ACTIVE_ALERT", status);
    },
    setActiveAlert({ commit }, status) {
      commit("SET_ACTIVE_ACTIONS_ALERT", status);
    },
    async available({ commit, state }) {
      const status = await CsAlertService.clientAvailable(state.params);
      return status;
    },
    async notAvailable({ commit, state }) {
      commit("ADD_COUNT_CALLS");
      const status = await CsAlertService.clientNotAvailable(state.params);
      return status;
    },
    async notAnswered({ commit, state }) {
      commit("ADD_COUNT_CALLS");
      const status = await CsAlertService.clientNotAnswered(state.params);
      return status;
    },
    async acceptAlert({ state, commit, getters }, { alert_id, user_id }) {
      commit("SET_CURRENT_ALERT", getters.getCurrentAlert);

      const data = await CsAlertService.captureAlert(alert_id, user_id);
      commit("DELETE_LAST_ITEM_TO_CALL");
    },
    async setAlertChat({ commit, state }, status) {
      commit("SET_ALERT_CHAT", status);
    },
  },
  getters: {
    getStatusAlert: (state) => state.alertActive,
    getStatusActionsAlert: (state) => state.alertActionsActive,
    getStatusPendingAlert: (state) => state.isPendingModal,
    getTitle: (state) => state.title,
    getMessage: (state) => state.message,
    getAlertTypeId: (state) => state.params.alert_type_id,
    getApplicationId: (state) => state.appId,
    getAlertChat: (state) => state.alertChatActive,
    getListPendingToCall: (state) => state.listPendingToCall,
    getListPendingToCallId: (state) => state.listPendingToCallId,
    getCurrentAlert: (state) => state.listPendingToCall[0],
    getAlert: (state) => state.currentAlert,
    getChatInfo: (state) => state.chatInfo,
    listIsEmpty: (state) => {
      return state.listPendingToCall.length <= 1;
    },
    params: (state) => state.params
  },
};
