import PotentialService from "@/views/commons/components/potencial-appointment/services/potencial-appointments.service.js";
import store from "@/store";

export default {
    namespaced: true,
    state: {
        S_PENDING_POTENCIAL: 0,
    },
    getters: {
        G_PENDING_POTENCIAL(state) {
            return state.S_PENDING_POTENCIAL;
        }
    },
    mutations: {
        M_PENDING_POTENCIAL(state, payload) {
            state.S_PENDING_POTENCIAL = payload;
        }
    },
    actions: {
        async A_GET_PENDING_POTENTIAL({ commit }) {
            const { modul_id } = store.state.auth.currentUser;
            const data = await PotentialService.getCountPending({ module_id: modul_id });
            commit("M_PENDING_POTENCIAL", data);
            const routesModules = {
                5: "debt-solution-potencial-appointment",
                6: "credit-experts-potencial-appointment",
                7: "boost-credit-potencial-appointment",
                20: "connection-potencial-appointment",
                22: "customerservice-potencial-appointment",
                29: "potencial-financial-approval",
            }
            const payload = {
                routeName: routesModules[modul_id],
                tag: data,
            };
            store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
        }
    }
}