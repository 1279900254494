export default [
  {
    path: "clients",
    name: "analyst-department-clients",
    redirect: { name: "clients-main" },
    component: () =>
      import(
        /* webpackChunkName: "clientsSpecialists" */
        "@/views/specialists/views/clients/TabClientsSpecialists.vue"
      ),
    meta: {
      module: 11,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "clients-main",
        component: () =>
          import(
            /* webpackChunkName: "clientsMainSpecialists" */
            "@/views/specialists/views/clients/ClientsSpecialists.vue"
          ),
        meta: {
          tab: 1,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
        },
      },
      {
        path: "old",
        name: "clients-main-old",
        component: () =>
          import(
            /* webpackChunkName: "clientsMainSpecialists" */
            "@/views/specialists/views/clients/ClientsSpecialistsOld.vue"
          ),
        meta: {
          type: "OLD",
          tab: 1,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
        },
      },
      {
        path: "shared",
        name: "clients-shared",
        redirect: { name: "shared-of-program" },
        component: () =>
          import(
            /* webpackChunkName: "clientsSharedSpecialists" */
            "@/views/specialists/views/clients/shared/ClientsShared.vue"
          ),
        meta: {
          tab: 1,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Shared",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "",
            name: "shared-of-program",
            component: () =>
              import(
                /* webpackChunkName: "sharedOfProgramSpecialists" */
                "@/views/specialists/views/clients/shared/OfTheProgram.vue"
              ),
            meta: {
              tab: 1,
              module: 11,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Of the program",
                  active: true,
                },
              ],
            },
          },
          {
            path: "other-program",
            name: "shared-other-program",
            component: () =>
              import(
                /* webpackChunkName: "sharedOtherProgramSpecialists" */
                "@/views/specialists/views/clients/shared/FromOtherProgram.vue"
              ),
            meta: {
              tab: 1,
              module: 11,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "From other program",
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: "leads/report/:idlead/:idfile/:accountId",
    name: "report-lead-specialists",
    component: () =>
      import(
        /* webpackChunkName: "reportLeadSpecialists" */
        "@/views/commons/components/ncr/components/report/ReportLead.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      module: 11,
      pendingRoute: "ncr-pending",
      returnedRoute: "ncr-returned",
      completedRoute: "ncr-completed",
      reportLeadRoute: "report-lead",
      leadRoute: "lead-show",
    },
  },
  {
    path: "clients/account/:idClient",
    name: "specialists-client-dashboard",
    redirect: { name: "personal-information-specialist" },
    component: () =>
      import(
        /* webpackChunkName: "accountSpecialists" */
        "@/views/specialists/views/clients/dashboard/AccountSpecialists.vue"
      ),
    meta: {
      module: 11,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Personal information",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay",
        name: "pay-specialist",
        component: () =>
          import(
            /* webpackChunkName: "paySpecialists" */
            "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "dis",
        name: "dis-specialist",
        redirect: { name: "analysis-cr-spad" },
        component: () =>
          import(
            /* webpackChunkName: "disSpecialists" */
            "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Dispute",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "dispute-detail",
            name: "dispute-detail-spad",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/DisputeDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Disputes",
                  active: true,
                },
              ],
            },
          },
          {
            path: "report-detail",
            name: "report-detail-spad",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Credit Report",
                  active: true,
                },
              ],
            },
          },
          {
            path: "updates-detail",
            name: "updates-detail-spad",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/UpdateDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Updates",
                  active: true,
                },
              ],
            },
          },
            {
            path: "workplan-detail",
            name: "workplan-detail-spad",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Workplan",
                  active: true,
                },
              ],
            },
          },
            {
            path: "recommendation-detail",
            name: "recommendation-detail-spad",
            component: () =>
              import(
                /* webpackChunkName: "SpadClientAccountDisDetail" */   "@/views/commons/components/clients/dashboard/options/services/services.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Recommendations",
                  active: true,
                },
              ],
            },
          },
          {
            path: "analysis-cr",
            name: "analysis-cr-spad",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/analysis/AccountsClients.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Items",
                  active: true,
                },
              ],
            },
          },
            {
            path: "workplan-detail",
            name: "workplan-detail-adm",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Workplan Detail",
              breadcrumb: [
                {
                  text: "Workplan Detail",
                  active: true,
                },
              ],
            },
          },
            {
            path: "recommendation-detail",
            name: "recommendation-detail-bc",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */   "@/views/commons/components/clients/dashboard/options/services/services.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Recommendations",
              breadcrumb: [
                {
                  text: "Recommendations",
                  active: true,
                },
              ],
            },
          },
        ],
      },

      {
        path: "acc",
        name: "acc-specialist",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientAccountAcc" */ "@/views/commons/components/clients/dashboard/options/acc-module/Acc.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acc",
              active: true,
            },
          ],
        },
      },
      {
        path: "task-notes",
        name: "task-notes-specialist",
        component: () =>
          import(
            /* webpackChunkName: "taskNotesSpecialists" */
            "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotesNew.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information",
        name: "personal-information-specialist",
        component: () =>
          import(
            /* webpackChunkName: "personalInformationSpecialists" */
            "@/views/commons/components/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files",
        name: "client-files-specialist",
        component: () =>
          import(
            /* webpackChunkName: "clientFilesSpecialists" */
            "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr",
        name: "acr-specialist",
        component: () =>
          import(
            /* webpackChunkName: "acrSpecialists" */
            "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "report",
        name: "report-specialist",
        component: () =>
          import(
            /* webpackChunkName: "reportSpecialists" */
            "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Report",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log",
        name: "call-log-client-specialist",
        redirect: { name: "call-log-conversation-specialist" },
        component: () =>
          import(
            "@/views/commons/components/ring-central/call-log/tabs/ClientTabs.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
          callConversationRoute: "call-log-conversation-specialist",
          callNoConversationRoute: "call-log-not-conversation-specialist",
        },
        children: [
          {
            path: "conversation",
            name: "call-log-conversation-specialist",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Internal",
                  active: true,
                },
              ],
              isConversation: 1,
            },
          },
          {
            path: "no-conversation",
            name: "call-log-not-conversation-specialist",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Internal",
                  active: true,
                },
              ],
              isConversation: 0,
            },
          },
        ],
      },
      {
        path: "services",
        name: "services-client-specialist",
        component: () =>
          import(
            /* webpackChunkName: "servicesClient" */
            "@/views/commons/components/clients/dashboard/options/services/services.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 29,
          pageTitle: "Services",
          breadcrumb: [
            {
              text: "Services",
              active: true,
            },
          ],
        },
      },
      {
        path: "tickets",
        name: "client-tickets-specialist",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
