<template>
  <div class="p-1">
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :active="hasPending"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        @click="changeStatusFilter(1)"
      >
        Pending
        <feather-icon icon="ClockIcon" size="13" style="margin-left: 5px" />
      </b-nav-item>
      <b-nav-item
        :active="!hasPending && !showOldTab"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        @click="changeStatusFilter(2)"
      >
        Finished
        <feather-icon
          icon="CheckCircleIcon"
          size="13"
          style="margin-left: 5px"
        />
      </b-nav-item>
      <b-nav-item
        :active="showOldTab"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        @click="changeStatusFilter(3)"
      >
        Old NCR
        <feather-icon icon="ClockIcon" size="13" style="margin-left: 5px" />
      </b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
      style="max-height: 250px; overflow-y: auto; overflow-x: hidden"
    >
      <b-table
        v-show="!showOldTab"
        no-provider-filtering
        ref="RequestNcrTable"
        :items="myProvider"
        :fields="fields.filter((f) => f.visible)"
        primary-key="id"
        table-class="text-nowrap"
        responsive
        sticky-header
        show-empty
        :busy.sync="isBusy"
        :per-page="filterSlot.paginate.perPage"
        :current-page="filterSlot.paginate.currentPage"
        id="table-request-ncr"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(type)="data">
          <div class="text-info text-center">
            <b-badge
              style="width: 90px"
              :class="
                data.item.type_ncr == 'AUTOMATIC'
                  ? 'item-magenta'
                  : 'item-purple'
              "
            >
              <span>
                {{ data.item.type_ncr }}
              </span>
            </b-badge>
          </div>
        </template>
        <template #cell(number_of_attempts)="{ value }">
          <b-badge variant="primary">
            {{ value }}
          </b-badge>
        </template>
        <template #cell(created_by)="data">
          <div>
            {{ data.item.created_by }}
            <br />
            {{ data.item.created_at | myGlobalDay }}
          </div>
        </template>
        <template #cell(tracking)="{ item }">
          <FeatherIcon
            @click="openModalTrackingNcrRequest(item)"
            class="pointer text-info"
            icon="ListIcon"
            size="20"
          />
        </template>
        <template #cell(actions)="data">
          <amg-icon
            v-if="data.item.status_id == 3"
            v-b-tooltip.hover.top="'View PDF'"
            @click="viewPdfNcr(data.item)"
            class="cursor-pointer mt-0 mr-0 mx-2 bigger text-danger"
            icon="FilePdfIcon"
            style="height: 18px; width: 18px"
          />
          <template
            v-if="
              data.item.amount_automatic > 0 &&
              data.item.status_id != 4 &&
              data.item.type_ncr == 'AUTOMATIC'
            "
          >
            <tabler-icon
              v-if="data.item.is_collected == 1"
              icon="CoinIcon"
              size="19"
              class="text-warning cursor-pointer oscillate"
              v-b-tooltip.hover.left="'Collect Payment'"
              @click="collectPayment(data.item)"
            />
            <tabler-icon
              v-else
              icon="CoinIcon"
              size="19"
              class="text-success"
              v-b-tooltip.hover.left="'This ncr already was collected'"
            />
          </template>
        </template>
      </b-table>
      <card-lead-credit-report-obtained
        :lead="lead"
        :isBusy="false"
        v-show="showOldTab"
      />
    </b-card>
    <ModalCollectCR
      :lead="lead"
      :amount="amount"
      :score_id="score_id"
      :isNcr="isNcr"
      :idNcr="id_ncr"
      v-if="modalCollectCR"
      @hidden="modalCollectCR = false"
      @onCollectedPayment="closeModalCollectCR"
    />
    <TrackingRequestNcrModal
      v-if="trackingParams.isOpen"
      :dataNcr="trackingParams"
      :isClient="false"
      @close="closeModalTrackingNcrRequest"
    />
  </div>
</template>

<script>
// store
import { mapGetters } from "vuex";

//service
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";

//modales
import ModalCollectCR from "@/views/ce-digital/sub-modules/sales/views/leads/view/modals/ModalCollectCR.vue";
import TrackingRequestNcrModal from "@/views/commons/components/request-ncr/views/modals/TrackingRequestNcrModal.vue";
import CardLeadCreditReportObtained from "@/views/crm/views/Lead/lead-module/dashboard/CardLeadCreditReportObtained.vue";

export default {
  components: {
    ModalCollectCR,
    TrackingRequestNcrModal,
    CardLeadCreditReportObtained,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasPending: true,
      filterSlot: {
        filterSearch: {
          type: "input",
          inputType: "text",
          placeholder: "Client...",
          model: "",
        },
        paginate: {
          currentPage: 1,
          perPage: 10,
        },
        //rows
        totalRows: 50,
        startRow: 0,
        endRow: 0,
        toPage: 0,
      },
      isBusy: false,
      fields: [
        {
          key: "type",
          label: "Type ",
          visible: true,

          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "created_by",
          label: "Requested By",
          tdClass: "text-center",
          thClass: "text-center",
          visible: true,
        },
        {
          key: "transunion",
          label: "TU",
          thClass: "text-center px-1 mx-0",
          tdClass: "text-center px-1 mx-0",
          visible: true,
        },
        {
          key: "experian",
          label: "EX",
          thClass: "text-center px-1 mx-0",
          tdClass: "text-center px-1 mx-0",
          visible: true,
        },
        {
          key: "equifax",
          label: "EQ",
          thClass: "text-center px-1 mx-0",
          tdClass: "text-center px-1 mx-0",
          visible: true,
        },
        {
          key: "number_of_attempts",
          label: "Attempts",
          tdClass: "text-center px-0 mx-0",
          thClass: "text-center px-1 mx-0",
          visible: true,
        },
        {
          key: "tracking",
          label: "Tracking",
          tdClass: "text-center px-0 mx-0",
          thClass: "px-0 mx-0",
          visible: true,
        },
        {
          key: "actions",
          label: "Actions",
          tdClass: "text-center",
          thClass: "text-center",
          visible: false,
        },
      ],
      modalCollectCR: false,
      amount: 0,
      id_ncr: null,
      score_id: null,
      trackingParams: {
        isOpen: false,
        fullname: null,
        id: null,
      },
      showOldTab: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  methods: {
    changeStatusFilter(tab) {
      this.hasPending = tab == 1;
      if (tab == 3) {
        this.showOldTab = true;
      } else {
        this.showOldTab = false;
        this.$refs.RequestNcrTable.refresh();
      }
    },
    refreshTable() {
      this.$refs.RequestNcrTable.refresh();
    },
    async myProvider(ctx) {
      let moduleId = this.currentUser.modul_id
      this.fields[7].visible = !this.hasPending;
      try {
        let params = {
          // search: this.lead.id,
          entityId: this.lead.id,
          clientAccountId: this.lead.client_account_id,
          moduleId: moduleId,
          entityType: this.lead.client_account_id == null ? "lead" : "client",
          subTab: this.hasPending ? 1 : 2,
          ...ctx,
        };
        const { data } = await NcrRequestService.getRequestNcr(params);
        this.startPage = data.from;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        this.filterSlot.totalRows = data.total;
        this.filterSlot.toPage = data.to;
        this.filterSlot.startRow = data.from;
        this.filterSlot.endRow = data.to;
        return data.data;
      } catch (ex) {
        return [];
      }
    },
    badgeVariant(auto, manual) {
      if (auto == 0 || manual == 0) {
        return "light-warning";
      } else {
        return "light-success";
      }
    },
    badgeColor(badge) {
      let badgeClass = {
        1: "light-secondary",
        2: "light-info",
        3: "light-success",
        4: "light-danger",
        5: "light-secondary",
        6: "light-warning",
      };
      return badgeClass[badge];
    },
    collectPayment(item) {
      this.isNcr = !!item.amount_automatic;
      this.lead.score = item.id;
      this.amount = Number(item.amount_automatic);
      this.score_id = item.score_id;
      this.id_ncr = item.id;
      this.modalCollectCR = true;
    },
    closeModalCollectCR() {
      this.modalCollectCR = false;
      this.refreshTable();
    },
    openModalTrackingNcrRequest({ fullname, id }) {
      this.trackingParams = {
        isOpen: true,
        fullname,
        id,
      };
    },
    closeModalTrackingNcrRequest() {
      this.trackingParams.isOpen = false;
    },
    async viewPdfNcr(item) {
      let win;
      try {
        this.addPreloader();
        let params = {
          file_pdf: item.file_pdf,
        };
        win = window.open("", "_blank");
        const { data } = await NcrRequestService.addUrlTemporaryPdfNcr(params);
        win.location.href = data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          error.message,
          "XIcon",
          "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped>
.item-purple {
  background-color: #009fbd !important;
  border-color: #009fbd !important;
}

.item-magenta {
  background-color: #89375f !important;
  border-color: #89375f !important;
}

@keyframes oscillate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.oscillate {
  animation-name: oscillate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
</style>
