export default [
  {
    path: 'calendar',
    name: 'calendar-crm',
    component: () => import(/* webpackChunkName: "Calendar" */ '@/views/crm/views/calendar/default.vue'),
    redirect: { name: 'crm-calendar-appointment' },
    children: [
      {
        path: '',
        redirect: { name: 'crm-calendar-appointment' },
      },
      {
        path: 'appointment',
        component: () => import(/* webpackChunkName: "calendar-appointment" */ '@/views/crm/views/calendar/components/appointment/default.vue'),
        name: 'crm-calendar-appointment',
        meta: {
          pageTitle: 'Calendar',
          breadcrumb: [
            {
              text: 'Appointments',
              active: true,
            },
          ],
        },
      },
      {
        path: 'task',
        component: () => import(/* webpackChunkName: "calendar-task" */ '@/views/crm/views/calendar/components/task/default.vue'),
        name: 'crm-calendar-task',
        meta: {
          pageTitle: 'Calendar',
          breadcrumb: [
            {
              text: 'Tasks',
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      pageTitle: 'Calendar',
      breadcrumb: [
        {
          text: 'Calendar',
        },
      ],
    },
  },
]
