import Vue from "vue";

Vue.filter('statusAccountClient',(status)=>{
    switch(parseInt(status)){
        case 1 :
             return 'Active'
        break;
        case 2 :
             return 'Hold' 
        break;
        case 3 :
             return 'Transition'
        break;
        case 4 :
             return 'Canceled'
        break;
        case 5 :
             return 'Loyal'
        break;
        case 6 :
             return 'Closed'
        break;
  }
})

Vue.filter("statusResult", (params) => {
    switch (params) {
        case 0:
            return 'PENDING'
            break;
        case 1:
            return 'APPROVED'
            break;
        case 2:
            return 'DENIED'
            break;

    }
});

Vue.filter("statusPaid", (params) => {
    switch (params) {
        case 0:
            return 'PENDING'
            break;
        case 1:
            return 'YES'
            break;
        case 2:
            return 'NO'
            break;
    }
})
Vue.filter("statusApplication", (params) => {
    switch (params) {
        case 0:
        case "PENDING":
            return 'PENDING'
        case 1:
        case "ACCEPTED":
            return 'ACCEPTED'
        case 2:
        case "REJECTED":
            return 'REJECTED'
    }
})


Vue.filter("notesZeroPayment", (value) => {
    let selected
    switch (parseInt(value)) {
        case 1:
            selected = "Approve"
            break;
        case 2:
            selected = "Disapprove"
            break;
        case 3:
            selected = "Send"
            break;
    }
    return selected ? selected : 'errror'
});
Vue.filter("nameStatusNote", (value) => {
    
        switch(value){
            case 1:
                return 'APPROVED'
            break;
            case 2:
                return 'DISAPPROVED'
            break;
            case 4:
                return 'PENDING'
            case 5:
                return 'UNDERVIEW'
            break;
        }
    
})
Vue.filter("statusNcrRealtor", (value) => {
    
    switch(parseInt(value)){
        case 0:
            return 'PENDING'
        break;
        case 1:
            return 'COMPLETED'
        break;
    }

})
Vue.filter("statusNcrRealtorRead", (value) => {
    
    switch(parseInt(value)){
        case 0:
            return 'NO'
        break;
        case 1:
            return 'YES'
        break;
    }

})
Vue.filter('statusDisputeResultsRead',(read)=>{
    switch(read){
        case 0:
            return 'YES'
        break;
        case 1:
            return 'NO'
        break;
    }
})

Vue.filter('statusSession', (value) => {
    switch (value) {
        case 1:
            return "point-green-c";
        case 2:
            return "point-orange-c";
        case 3:
            return "point-red-c";
        default:
            return "point-gray-c";
    }
})
