<template>
  <div>
    <b-modal
      ref="myModal"
      v-model="modalUp"
      modal
      size="lg"
      header-class="p-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="ADD SALE"
      title-class="h2 text-white"
      hide-footer
      @hidden="hideModal"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <modal-service-header
          :type-modal="changeTypeModal"
          :programs-all="programsAll"
          :users-services="usersServices"
          :sales="salesClient"
          :isFromLeadDashboard="isFromLeadDashboard"
          :newLead="newLead"
          :headerS="headerS"
          @close="hideModal(false)"
          @changeProgram="changeProgram"
          @sendLeadEvent="sendLeadEvent"
        />
      </template>
    </b-modal>
  </div>
</template>
<script>
import ModalServiceHeader from "@/views/crm/views/sales-made/components/modals/services/ModalServiceHeader.vue";
import LeadService from "../../service/lead.service";
export default {
  components: {
    ModalServiceHeader,
  },
  props: {
    salesClient: {
      type: Object,
    },
    currentUser: {
      type: Object,
    },
    usersServices: {
      type: Array,
      default: () => [],
    },
    programsAll: {
      type: Array,
      default: () => [],
    },
    isFromLeadDashboard: {
      type: Boolean,
      default: false,
    },
    newLead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalUp: true,
      typeModal: 0,
      event: 0,
    };
  },
  computed: {
    changeTypeModal() {
      this.$route.matched[0].meta.module !== 26
        ? (this.typeModal = 6)
        : (this.typeModal = 7);
      return this.typeModal;
    },
    headerS() {
      let seller;
      if (this.salesClient.event) {
        seller = {
          id: this.salesClient.event.seller_id,
          user_name: this.salesClient.event.seller_name,
        };
      }
      return {
        program: null,
        seller: seller,
        captured: null,
      };
    },
  },
  methods: {
    hideModal() {
      this.$refs["myModal"].hide();
      this.modalUp = false;
      this.$emit("hideModal", false);
    },
    async changeProgram(data) {
      if (data) {
        if (this.newLead && ![3, 4].includes(data.program.id)) {
          const confirm = await this.showConfirmSwal();
          if (!confirm.isConfirmed) return;
          await this.createLeadEvent();
        }
        this.$emit("changeProgram", {
          ...data,
          derivation_id: this.salesClient?.derivation_id,
        });
      }
    },
    async createLeadEvent() {
      //salesClient.id debtS-leads
      //salesClient.lead_id debtS-Clients
      const data = await LeadService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: this.salesClient.id
          ? this.salesClient.id
          : this.salesClient.lead_id,
      });

      if (data) {
        this.$emit("leadEvent", data);
      }
    },
    sendLeadEvent(data) {
      this.$emit("leadEvent", data);
    },
  },
  mounted() {
    this.changeProgram();
    // this.createLeadEvent();
  },
  created() {
    const data = this.changeProgram();
  },
};
</script>
<style scoped></style>
