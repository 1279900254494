var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"variant":"primary","size":"lg","hide-footer":"","no-close-on-backdrop":""},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"text-uppercase text-white font-weight-bolder",staticStyle:{"padding":"5px"}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ClockIcon","size":"15"}}),_vm._v(" Meetings to ticket "),(_vm.ticketCustomer.code)?_c('b',[_vm._v(_vm._s(("\"#" + (_vm.ticketCustomer.code) + "\"")))]):_vm._e(),_vm._v(" of "),_c('b',[_vm._v(_vm._s(("\"" + (_vm.ticketCustomer.name_client) + "\"")))])],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-container',{staticClass:"pb-2 pt-1",attrs:{"fluid":""}},[_c('b-table',{attrs:{"show-empty":"","fields":_vm.fields,"items":_vm.myProvider,"table-class":"text-nowrap","responsive":"","small":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(meeting_date)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{class:index === 0 ? 'font-weight-bolder':''},[_vm._v(" "+_vm._s(_vm._f("myGlobal")(item.meeting_date))+" ")])]}},{key:"cell(meeting_hours)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{staticClass:"mr-1",class:index === 0 ? 'font-weight-bolder':''},[_c('feather-icon',{attrs:{"icon":"ClockIcon","size":"12"}}),_vm._v(" "+_vm._s(_vm._f("myFullTime")(item.meeting_start))+" ")],1),_vm._v(" - "),_c('span',{staticClass:"ml-1",class:index === 0 ? 'font-weight-bolder':''},[_c('feather-icon',{attrs:{"icon":"ClockIcon","size":"12"}}),_vm._v(" "+_vm._s(_vm._f("myFullTime")(item.meeting_end))+" ")],1)]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('b-badge',{staticClass:"cursor-pointer",attrs:{"id":("item_" + index),"variant":_vm.bgColor[item.meeting_status_id]}},[_vm._v(" "+_vm._s(item.meeting_status_name)+" ")]),_c('b-tooltip',{attrs:{"target":("item_" + index),"variant":_vm.bgColor[item.meeting_status_id],"placement":"right"}},[_c('b',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.meeting_status_description))])]),(item.description_note)?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-success",modifiers:{"hover":true,"v-success":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],staticClass:"ml-1 text-success hover-me cursor-pointer",attrs:{"title":"DESCRIPTION NOTE","icon":"AlignLeftIcon","size":"18"},on:{"click":function($event){_vm.descriptionSelected=item.description_note; _vm.showModalDescription=true}}}):_vm._e()]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{class:index === 0 ? 'font-weight-bolder':''},[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.created_at)))]),_c('br'),_c('span',{class:index === 0 ? 'font-weight-bolder':''},[_vm._v(_vm._s(item.created_by_name))])]}}])})],1)],1),_c('b-modal',{attrs:{"header-bg-variant":"transparent","size":"default","hide-footer":"","centered":""},on:{"hidden":function($event){_vm.descriptionSelected=null; _vm.showModalDescription=false}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h5',{staticClass:"text-uppercase font-weight-bolder"},[_c('feather-icon',{staticClass:"mr-1 hover-me cursor-pointer",attrs:{"icon":"AlignLeftIcon","size":"15"}}),_vm._v(" Description note ")],1)]},proxy:true}]),model:{value:(_vm.showModalDescription),callback:function ($$v) {_vm.showModalDescription=$$v},expression:"showModalDescription"}},[_c('b-container',{attrs:{"fluid":""}},[_c('p',{staticClass:"text-center pb-1"},[_vm._v(" "+_vm._s(_vm.descriptionSelected)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }