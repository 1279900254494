import MessengerMetaService from "@/views/commons/Messenger/components/services/messenger.meta.service.js";

export default {
    namespaced: true,
    state: {
        S_FB_PAGES: [],
        S_CURRENT_FB_PAGE: null,
        S_MESSENGER_META_TABS: [
            //{ id: "all", name: "All messages" },
            { id: "messenger", name: "Messenger" },
            { id: "instagram", name: "Instagram" },
            { id: "facebook_comments", name: "Facebook comments" },
            { id: "instagram_comments", name: "Instagram comments" },
            // { id: "spam", name: "Spam" },
            { id: "lists", name: "Lists" },
            // { id: "classification", name: "Classification" },
            { id: "privacy_policy", name: "Privacy Policy", url: "https://amgbusiness.us/privacy-policy" },
        ],
        S_CURRENT_MESSENGER_META_TAB: null,
        S_MESSENGER_META_FILTERS: [
            { id: "all", name: "All" },
            { id: "unread", name: "Unread" },
            // { id: "interacted", name: "Interacted" },
            // { id: "not_interacted", name: "Not Interacted" },
        ],

        S_MESSENGER_META_FILTERS_ADVANCED: {
            fromDate: null,
            toDate: null,
            classification: null,
            category: null,
        },

        //MESSENGER AND INSTAGRAM
        S_SEARCH_MESSAGE: "",
        S_SEARCH_TEXT: "",
        S_CURRENT_MESSENGER_MESSAGE_SIDEBAR: 1,

        S_CURRENT_MESSENGER_META_FILTER: null,
        // INSTAGRAM COMMENTS
        S_CURRENT_INSTAGRAM_COMMENT_SIDEBAR: null,
        S_CURRENT_INSTAGRAM_COMMENT_CHAT: null,
        // INSTAGRAM MESSAGES
        S_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR: null,
        // MESSENGER MESSAGES
        S_CURRENT_MESSENGER_CONVERSATION_SIDEBAR: null,
        //COUNTER MESSENGER/INSTAGRAM CHATS
        S_MESSENGER_UNREAD_CHATS_COUNTER: [],
        S_INSTAGRAM_UNREAD_CHATS_COUNTER: [],



        S_META_LIST_FILTERS: [
            { id: "active", name: "Active" },
            { id: "deleted", name: "Deleted" },
        ],
        S_CURRENT_META_LIST_FILTER: { id: "active", name: "Active" },
        S_CURRENT_META_LIST: null,
        S_CURRENT_LISTS_ITEMS: [],

        // FACEBOOK COMMENTS
        S_CURRENT_FB_COMMENT_SIDEBAR: null,
        S_CURRENT_FB_COMMENT_CHAT: null,

    },
    getters: {
        G_FB_PAGES(state) {
            return state.S_FB_PAGES;
        },
        G_CURRENT_FB_PAGE(state) {
            return state.S_CURRENT_FB_PAGE;
        },
        G_MESSENGER_META_TABS(state) {
            return state.S_MESSENGER_META_TABS;
        },
        G_CURRENT_MESSENGER_META_TAB(state) {
            return state.S_CURRENT_MESSENGER_META_TAB;
        },
        G_MESSENGER_META_FILTERS(state) {
            return state.S_MESSENGER_META_FILTERS;
        },
        G_CURRENT_MESSENGER_META_FILTER(state) {
            return state.S_CURRENT_MESSENGER_META_FILTER
        },
        G_MESSENGER_META_FILTERS_ADVANCED(state) {
            return state.S_MESSENGER_META_FILTERS_ADVANCED
        },

        G_SEARCH_TEXT(state) {
            return state.S_SEARCH_TEXT;
        },

        G_META_LIST_FILTERS(state) {
            return state.S_META_LIST_FILTERS;
        },
        G_CURRENT_META_LIST_FILTER(state) {
            return state.S_CURRENT_META_LIST_FILTER;
        },

        G_CURRENT_META_LIST(state) {
            return state.S_CURRENT_META_LIST;
        },

        G_CURRENT_LISTS_ITEMS(state) {
            return state.S_CURRENT_LISTS_ITEMS
        },

        // INSTAGRAM COMMENTS
        G_CURRENT_INSTAGRAM_COMMENT_SIDEBAR(state) {
            return state.S_CURRENT_INSTAGRAM_COMMENT_SIDEBAR;
        },
        G_CURRENT_INSTAGRAM_COMMENT_CHAT(state) {
            return state.S_CURRENT_INSTAGRAM_COMMENT_CHAT;
        },

        // INSTAGRAM MESSAGES
        G_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR(state) {
            return state.S_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR;
        },

        // MESSENGER MESSAGES
        G_CURRENT_MESSENGER_CONVERSATION_SIDEBAR(state) {
            return state.S_CURRENT_MESSENGER_CONVERSATION_SIDEBAR;
        },

        //MESSENGER AND INSTAGRAM
        G_SEARCH_MESSAGE(state) {
            return state.S_SEARCH_MESSAGE;
        },
        G_CURRENT_MESSENGER_MESSAGE_SIDEBAR(state) {
            return state.S_CURRENT_MESSENGER_MESSAGE_SIDEBAR;
        },

        // FACEBOOK COMMENTS
        G_CURRENT_FB_COMMENT_SIDEBAR(state) {
            return state.S_CURRENT_FB_COMMENT_SIDEBAR;
        },
        G_CURRENT_FB_COMMENT_CHAT(state) {
            return state.S_CURRENT_FB_COMMENT_CHAT;
        },

        //COUNTER MESSENGER/INSTAGRAM CHATS
        G_MESSENGER_UNREAD_CHATS_COUNTER(state) {
            return state.S_MESSENGER_UNREAD_CHATS_COUNTER;
        },
        G_INSTAGRAM_UNREAD_CHATS_COUNTER(state) {
            return state.S_INSTAGRAM_UNREAD_CHATS_COUNTER;
        },
    },
    mutations: {
        M_SET_FB_PAGES(state, payload) {
            state.S_FB_PAGES = payload;
        },
        M_SET_CURRENT_FB_PAGE(state, payload) {
            state.S_CURRENT_FB_PAGE = payload;
        },
        // INSTAGRAM COMMENTS
        M_SET_CURRENT_INSTAGRAM_COMMENT_SIDEBAR(state, payload) {
            state.S_CURRENT_INSTAGRAM_COMMENT_SIDEBAR = payload;
        },
        M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT(state, payload) {
            state.S_CURRENT_INSTAGRAM_COMMENT_CHAT = payload;
        },
        // INSTAGRAM MESSAGES
        M_SET_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR(state, payload) {
            state.S_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR = payload;
        },
        // MESSENGER MESSAGES
        M_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR(state, payload) {
            state.S_CURRENT_MESSENGER_CONVERSATION_SIDEBAR = payload;
        },

        M_SET_MESSENGER_META_FILTERS_ADVANCED(state, payload) {
            state.S_MESSENGER_META_FILTERS_ADVANCED = payload;
        },

        // MESSENGER AND INSTAGRAM
        M_SET_SEARCH_TEXT(state, payload) {
            state.S_SEARCH_TEXT = payload;
        },
        M_SET_SEARCH_MESSAGE(state, payload) {
            state.S_SEARCH_MESSAGE = payload;
        },
        M_SET_CURRENT_MESSENGER_MESSAGE_SIDEBAR(state, payload) {
            state.S_CURRENT_MESSENGER_MESSAGE_SIDEBAR = payload;
        },


        M_SET_CURRENT_META_LIST_FILTER(state, payload) {
            state.S_CURRENT_META_LIST_FILTER = payload;
        },
        M_SET_CURRENT_META_LIST(state, payload) {
            state.S_CURRENT_META_LIST = payload;
        },
        M_SET_CURRENT_LISTS_ITEMS(state, payload) {
            state.S_CURRENT_LISTS_ITEMS = payload;
        },
        // FACEBOOK COMMENTS
        M_SET_CURRENT_FB_COMMENT_SIDEBAR(state, payload) {
            state.S_CURRENT_FB_COMMENT_SIDEBAR = payload;
        },
        M_SET_CURRENT_FB_COMMENT_CHAT(state, payload) {
            state.S_CURRENT_FB_COMMENT_CHAT = payload;
        },

        M_UPDATE_FB_COMMENT_CHAT(state, updatedComment) {
            state.S_CURRENT_FB_COMMENT_CHAT = state.S_CURRENT_FB_COMMENT_CHAT.map(
                (comment) => {
                    if (comment.id === updatedComment.id) {
                        return updatedComment;
                    }
                    return comment;
                }
            );
            console.log('state.S_CURRENT_FB_COMMENT_CHAT', state.S_CURRENT_FB_COMMENT_CHAT);
        },

        M_SET_NEW_OR_UPDATE_LIST(state, payload) {
            if (!payload._id) return;

            let index = state.S_CURRENT_LISTS_ITEMS.findIndex((filter) => filter._id == payload._id);
            if (index !== -1) {
                if (payload.deletedBy) {
                    state.S_CURRENT_LISTS_ITEMS[index].deletedBy = payload.deletedBy;
                    return;
                }

                if (payload.name) {
                    state.S_CURRENT_LISTS_ITEMS[index].name = payload.name;
                }
                if (payload.lastMessage) {
                    state.S_CURRENT_LISTS_ITEMS[index].lastMessage = payload.lastMessage;
                }
                if (payload.countLeads) {
                    state.S_CURRENT_LISTS_ITEMS[index].countLeads = payload.countLeads;
                }
                if (payload.filters) {
                    state.S_CURRENT_LISTS_ITEMS[index].filters = payload.filters;
                }
            } else {
                state.S_CURRENT_LISTS_ITEMS.unshift(payload);
            }
        },

        //COUNTER MESSENGER/INSTAGRAM CHATS
        M_SET_MESSENGER_UNREAD_CHATS_COUNTER(state, payload) {
            state.S_MESSENGER_UNREAD_CHATS_COUNTER = payload;
        },
        M_SET_INSTAGRAM_UNREAD_CHATS_COUNTER(state, payload) {
            state.S_INSTAGRAM_UNREAD_CHATS_COUNTER = payload;
        },

    },
    actions: {
        async A_GET_FB_PAGES({ commit }, body) {
            try {
                const { data } = await MessengerMetaService.getFacebookPages(body);
                if (data.length > 0) {
                    commit("M_SET_FB_PAGES", data);
                    commit("M_SET_CURRENT_FB_PAGE", data[0]);
                }
            } catch (error) {
                throw error;
            }
        },
        A_SET_CURRENT_FB_PAGE({ commit }, payload) {
            commit("M_SET_CURRENT_FB_PAGE", payload);
        },

        A_SET_CURRENT_MESSENGER_META_TAB({ state }, payload) {
            let index = state.S_MESSENGER_META_TABS.findIndex((tab) => tab.id === payload);
            if (index !== -1) {
                state.S_CURRENT_MESSENGER_META_TAB = state.S_MESSENGER_META_TABS[index];
            }
        },
        A_SET_CURRENT_MESSENGER_META_FILTER({ state }, payload) {
            let index = state.S_MESSENGER_META_FILTERS.findIndex((filter) => filter.id === payload);
            if (index !== -1) {
                state.S_CURRENT_MESSENGER_META_FILTER = state.S_MESSENGER_META_FILTERS[index];
            }
        },
        // INSTAGRAM COMMENTS
        A_SET_CURRENT_INSTAGRAM_COMMENT_SIDEBAR({ commit }, payload) {
            commit("M_SET_CURRENT_INSTAGRAM_COMMENT_SIDEBAR", payload);
        },
        async A_SET_CURRENT_INSTAGRAM_COMMENT_CHAT({ commit }, post_id) {
            try {
                // set null to show loader
                commit("M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT", null);
                const { data } = await MessengerMetaService.getInstagramCommentChat({ post_id });
                commit("M_SET_CURRENT_INSTAGRAM_COMMENT_CHAT", data);
            } catch (error) {
                throw error;
            }
        },

        // INSTAGRAM MESSAGES
        A_SET_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR({ commit }, payload) {
            commit("M_SET_CURRENT_INSTAGRAM_CONVERSATION_SIDEBAR", payload);
        },

        // MESSENGER MESSAGES
        A_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR({ commit }, payload) {
            commit("M_SET_CURRENT_MESSENGER_CONVERSATION_SIDEBAR", payload);
        },

        // MESSENGER AND INSTAGRAM
        A_SET_SEARCH_TEXT({ commit }, payload) {
            commit("M_SET_SEARCH_TEXT", payload);
        },

        A_SET_SEARCH_MESSAGE({ commit }, payload) {
            commit("M_SET_SEARCH_MESSAGE", payload);
        },
        A_SET_CURRENT_MESSENGER_MESSAGE_SIDEBAR({ commit }, payload) {
            commit("M_SET_CURRENT_MESSENGER_MESSAGE_SIDEBAR", payload);
        },


        // FACEBOOK COMMENTS
        A_SET_CURRENT_FB_COMMENT_SIDEBAR({ commit }, payload) {
            commit("M_SET_CURRENT_FB_COMMENT_SIDEBAR", payload);
        },

        async A_SET_CURRENT_FB_COMMENT_CHAT({ commit }, post_id) {
            try {
                // set null to show loader
                commit("M_SET_CURRENT_FB_COMMENT_CHAT", null);
                const { data } = await MessengerMetaService.getFacebookCommentChat({ post_id });
                commit("M_SET_CURRENT_FB_COMMENT_CHAT", data);
            } catch (error) {
                throw error;
            }
        },

        A_UPDATE_FB_COMMENT_CHAT({ commit }, newComment) {
            commit('M_UPDATE_FB_COMMENT_CHAT', newComment);
        },

        //COUNTER MESSENGER/INSTAGRAM CHATS
        async A_SET_MESSENGER_UNREAD_CHATS_COUNTER({ commit }) {
            try {
                const { data } = await MessengerMetaService.getMessengerUnreadChatsCount();
                commit("M_SET_MESSENGER_UNREAD_CHATS_COUNTER", data);
            } catch (error) {
                throw error;
            }
        },
        async A_SET_INSTAGRAM_UNREAD_CHATS_COUNTER({ commit }) {
            try {
                const { data } = await MessengerMetaService.getInstagramUnreadChatsCount();
                commit("M_SET_INSTAGRAM_UNREAD_CHATS_COUNTER", data);
            } catch (error) {
                throw error;
            }
        },
    },
};
