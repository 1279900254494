<template>
  <div>
    <b-row class="w-100">
      <b-col class="mb-1 moving-card">
        <div
          class="p-1 rounded text-center moving-card-content moving-card-back cursor-pointer w-100"
          :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
        >
          <div>
            <span
              style="font-size: 2rem"
            > $ {{ commissionAmount | currency }}</span>
          </div>
        </div>
        <div
          class="p-1 rounded text-center moving-card-content moving-card-front w-100"
          :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
        >
          <div
            class="cursor-pointer "
          >
            <span
              style="font-size: 2rem"
            > $ {{ commissionAmount | currency }}</span>
          </div>
        </div>
      </b-col>

    </b-row>

    <b-row>
      <piggy
        :key="piggyKey"
        :money-size="piggyLevel"
      />
    </b-row>
  </div>
</template>

<script >
import { mapGetters } from 'vuex'
import DashboardSalesServices from '@/views/ce-digital/sub-modules/sales/views/dashboards/dashboard-sales-services'
import Piggy from '@/views/ce-digital/sub-modules/sales/views/dashboards/seller/piggy.vue'

export default {
  name: 'SalesCommissionComponent',
  components: {
    Piggy,
  },
  props: {
    paymentMonth: {
      type: Number,
      default: null,
    },
    paymentYear: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      piggyKey: 1,
      commissionAmount: '',
      piggyLevel: 0,
    }
  },

  computed: {
    ...mapGetters({ currentUser: 'auth/currentUser', skin: 'appConfig/skin' }),
  },
  watch: {
    async paymentMonth(oldValue, newValue) {
      if (newValue) {
        await this.getSalesCommissionByUserId()
        await this.getPiggyLevelByUserId()
      }
    },
    async paymentYear() {
      await this.getSalesCommissionByUserId()
      await this.getPiggyLevelByUserId()
    },
  },

  async created() {
    await this.getSalesCommissionByUserId()
    await this.getPiggyLevelByUserId()
  },

  methods: {
    async getSalesCommissionByUserId() {
      const data = await DashboardSalesServices.getSalesCommissionByUserId({
        user: this.currentUser.user_id,
        month: this.paymentMonth,
        year: this.paymentYear,
        module: this.$route.matched[0].meta.module,
      })
      if (data.status === 200) {
        if (data.data.length > 0) {
          this.commissionAmount = data.data[0].total
        }
      }
    },
    async getPiggyLevelByUserId() {
      this.piggyKey += 1
      this.piggyLevel = 0
      const data = await DashboardSalesServices.getPiggyLevelByUserId({
        user: this.currentUser.user_id,
        month: this.paymentMonth,
        year: this.paymentYear,
      })
      if (data.status === 200) {
        if (data.data.length > 0) {
          this.piggyLevel = data.data[0].piggy_level
        }
      }
    },
  },
}
</script>

<style  lang="scss"  scoped>
@import "@/views/ce-digital/sub-modules/sales/views/dashboards/seller/caro.scss";

.moving-card {
  perspective: 1000px;

  position:relative;
}

.moving-card-content {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, .3);
  transition: all 1s;

  position:absolute;
  top:22px;
  left:15;
  width:80%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.bri-content {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, .3);
  transition: all 1s;

  position:absolute;
  top:170px;
  left:15;
  width:80%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.moving-card-back{
  transform:rotateY(180deg);
}

.moving-card:hover .moving-card-front {
  transform: rotateY(-180deg);
}

.moving-card:hover .moving-card-back {
  transform: rotateY(0);
}
</style>
