import { amgApi } from "@/service/axios";

class LettersService {
  async getPendingRoundLetters(params) {
    try {
      const { data } = await amgApi.post("specialists-digital/search-round-letter-pending", params);
      return data;
    } catch (error) {
      console.error("Error in getPendingRoundLetters", error);
    }
  }

  async getCompletedRoundLetters(params) {
    try {
      const { data } = await amgApi.post("specialists-digital/search-round-letter-completed", params);
      return data;
    } catch (error) {
      console.error("Error in getCompletedRoundLetters", error);
    }
  }

  async getAdvisorsSpecialists() {
    try {
      const { data } = await amgApi.post("specialists-digital/get-advisors-specialists");
      return data;
    } catch (error) {
      console.error("Error in getAdvisorsSpecialists", error);
    }
  }

  async getLettersStatus() {
    try {
      const { data } = await amgApi.post("specialists-digital/get-letters-status");
      return data;
    } catch (error) {
      console.error("Error in getLettersStatus", error);
    }
  }

  async getAllBureaus() {
    try {
      const { data } = await amgApi.post("specialists-digital/get-all-bureaus");
      return data;
    } catch (error) {
      console.error("Error in getLettersStatus", error);
    }
  }

  async getAllFormats() {
    try {
      const { data } = await amgApi.get("lead/ncr/all-formats");
      return data;
    } catch (error) {
      console.error("Error in getAllFormats", error);
    }
  }

  async getAllTitles() {
    try {
      const { data } = await amgApi.get("lead/ncr/all-titles");
      return data;
    } catch (error) {
      console.error("Error in getAllTitles", error);
    }
  }

  async getAllSecuresSocial(params) {
    try {
      const { data } = await amgApi.post("lead/ncr/all-secure-social", params);
      return data;
    } catch (error) {
      console.error("Error in getAllSecuresSocial", error);
    }
  }

  async getRoundLetterData(params) {
    try {
      const { data } = await amgApi.post("dispute/get-round-id", params);
      return data;
    } catch (error) {
      console.error("Error in getRoundLetterData", error);
    }
  }

  async getReturnedLettersCount() {
    try {
      const { data } = await amgApi.post('specialists-digital/get-returned-letters-count');
      return data;
    } catch (error) {
      console.error("Error in getReturnedLettersCount", error);
    }
  }

  async insertProcessLetters(params) {
    try {
      const { data } = await amgApi.post(
        "debt-solution/insert-process-letters",
        params
      );
      return data;
    } catch (error) {
      console.error("Error insertProcessLetters", error);
    }
  }
}
export default new LettersService();