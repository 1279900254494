<template>
  <div>
    <b-container>
      <b-col
        style="padding:0rem; margin: 3rem 0 2rem 0"
        md="12"
      >
        <h1 style="margin:0">
          <feather-icon
            icon="ToolIcon"
            size="20"
          />
          Custom Availability Hours
        </h1>
      </b-col>
      <b-row>

        <b-col md="6">

          <b-card
            :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
            title="Available modules"
            sub-title="Select the module to change its range of hours"
          >

            <b-overlay
              :show="showLoading"
              variant="primary"
              :opacity="0.50"
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm"
              style="max-height: 500px;"
            >
              <b-list-group class="scrollable-list">
                <b-list-group-item
                  v-for="(module, key) in modules"
                  :key="key"
                  :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
                  class="d-flex justify-content-between"
                  @click="changeRangeModuleAvailability(module)"
                >
                  <h4 style="margin:0">
                    {{ module.name }}
                  </h4>
                  <div style="display:flex; align-items:center; gap:1rem">
                    <span>HALF-HOUR</span>
                    <b-form-checkbox
                      v-model="module.is_one_hour"
                      :checked="module.is_one_hour"
                      switch
                      value="1"
                      unchecked-value="0"
                      @change="changeRangeModuleAvailability(module)"
                    />
                    <span>ONE HOUR</span>
                  </div>
                  <!-- @click.stop -->
                </b-list-group-item>
              </b-list-group>
            </b-overlay>
            <b-row class="d-flex justify-content-between mt-2">

              <b-col>
                <b-form-group
                  id="input-group-3"
                  label-for="input-3"
                >

                  <b-form-select
                    v-model="selected"
                    :options="options"
                    @change="onSelectChangeActions"
                  />

                </b-form-group>
              </b-col>
              <b-col class="text-right">
                <b-button
                  href="#"
                  variant="success"
                  @click="saveChanges"
                >SAVE CHANGES
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="6">
          <div
            style="display: flex;padding: 1.5rem;border-radius: 5px;flex-direction: column; margin: 0 0 2rem 0"
            :style="isDarkSkin?'background: #282828;':'background: #f7f3ff;'"
          >
            <h4>Customize your alert to receive your notification</h4>
            <div style=" padding-top: 2rem; display: flex; align-items: center; gap: 2rem;">
              <div>
                <span>
                  Select the time you want to set (in minutes)
                </span>
              </div>
              <div>
                <kendo-timepicker
                  v-model="currentTime"
                  :format="'HH:mm'"
                  :interval="10"
                  class="w-100 rounded"
                  :class="!isDarkSkin? 'bg-white':'bg-transparent'"
                  placeholder="Warning time"
                  style="height: 2.73rem"
                  :min="min"
                  :max="max"
                />
              </div>
            </div>
          </div>
          <div
            style="display: flex;padding: 1.5rem 1.5rem 0.5rem 1.5rem;border-radius: 5px;flex-direction: column; margin: 0 0 2rem 0"
            :style="isDarkSkin?'background: #282828;':'background: #f7f3ff;'"
          >
            <h4 style="margin: 0 0 2rem 0">
              Modules with one hour range:
            </h4>
            <div style="display:flex; flex-flow: wrap;">
              <div v-if="noModulesWithOneHourRange">
                Not existing modules
              </div>
              <div
                v-for="(module, key) in modules"
                :key="key"
              >
                <div
                  v-if="module.is_one_hour === 1"
                  style="display:flex; padding:0.7rem; border-radius: 10px; font-weight: bold; margin: 0 1rem 1rem 0;color:white"
                  class="bg-primary"
                >
                  {{ module.name }}
                </div>
              </div>
            </div>
          </div>

          <div
            style="display: flex;padding: 1.5rem 1.5rem 0.5rem 1.5rem;border-radius: 5px;flex-direction: column;"
            :style="isDarkSkin?'background: #282828;':'background: #f7f3ff;'"
          >
            <h4 style="margin: 0 0 2rem 0">
              Modules with half-hour range:
            </h4>
            <div style="display:flex; flex-flow: wrap;">
              <div v-if="noModulesWithHalfHourRange">
                Not existing modules
              </div>
              <div
                v-for="(module, key) in modules"
                :key="key"
              >
                <div
                  v-if="module.is_one_hour === 0"
                  style="display:flex; padding:0.7rem; border-radius: 10px; font-weight: bold; margin: 0 1rem 1rem 0;color:white"
                  class="bg-secondary"
                >
                  {{ module.name }}
                </div>
              </div>
            </div>
          </div>

        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomerService from '@/views/commons/components/customer-tickets/services/customer-tickets.service';

export default {
  components: { },

  data() {
    return {
      min: new Date(2000, 0, 1, 0, 10, 0),
      max: new Date(2000, 0, 1, 1, 0, 0),
      currentTime: '',
      options: [
        {
          value: null,
          text: 'Select an action',
          disabled: true,
        },
        {
          value: 1,
          text: 'Default module ranges',
        },
        {
          value: 2,
          text: 'All ranges in HALF-HOUR',
        },
        {
          value: 3,
          text: 'All ranges in ONE HOUR',
        },
      ],

      selected: null,
      showLoading: true,
      modules: [],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    noModulesWithOneHourRange() {
      return !this.modules.some(module => module.is_one_hour === 1);
    },
    noModulesWithHalfHourRange() {
      return !this.modules.some(module => module.is_one_hour === 0);
    },
  },
  created() {
  },
  mounted() {
    this.getRangeModulesAvailability();
  },
  methods: {
    async getRangeModulesAvailability() {
      const response = await CustomerService.getRangeModulesAvailability();
      this.modules = response.data.data;
      this.currentTime = response.data.data[0].time_alert;
      this.showLoading = false;
    },
    async onSelectChangeActions() {
      switch (this.selected) {
        case 1:
          this.saveChanges('defaultModules');
          break;
        case 2:
          this.saveChanges('allHalfHourModules');
          break;
        case 3:
          this.saveChanges('allHourModules');
          break;
        default:
          break;
      }
      this.selected = null;
    },
    async saveChanges(x) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        if (x === 'defaultModules') {
          this.modules.forEach(module => {
            module.is_one_hour = module.name === 'BUSINESS' || module.name === 'KEYBOOK' ? 1 : 0;
          });
        } else if (x === 'allHalfHourModules') {
          this.modules.forEach(module => {
            module.is_one_hour = 0;
          });
        } else if (x === 'allHourModules') {
          this.modules.forEach(module => {
            module.is_one_hour = 1;
          });
        } else {
          x = null;
        }
        const ctx = {
          modules: this.modules,
          currentTime: this.currentTime,
        };
        const response = await CustomerService.updateRangeModulesAvailability(ctx);
        if (response.status === 200) {
          this.showSuccessSwal();
          this.getRangeModulesAvailability();
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeRangeModuleAvailability(module) {
      module.is_one_hour = module.is_one_hour === 1 ? 0 : 1;
    },
  },
};
</script>

<style scoped>

.scrollable-list {
  max-height: 500px;
  overflow-y: auto;
}

.my-bg-info-dark{
 opacity: 0.9;
 border: 2px solid #99e8a4;
 box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
}

.my-bg-info-light{
 opacity: 0.9;
 border: 2px solid #00D25B;
 box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
}

.my-badge-dark{
  background-color: #bda0e8;
  padding: 0.7rem;
  font-weight: bold !important;
  color: #1e1e1e !important;
  box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
}

.my-badge-light{
  background-color: #8357D3;
  padding: 0.7rem;
  font-weight: bold !important;
  box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-webkit-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 11px 20px -5px rgba(0,0,0,0.75);
}

.bg-dark{
  background-color: #282828 !important;
  transition: 0.3s;
}

.bg-dark:hover{
  background-color: #1e1e1e !important;
}

.bg-light{
  background-color: #f7f3ff !important;
  transition: 0.3s;
}

.bg-light:hover{
  background-color: #f0e9ff !important;
}

</style>
