<template>
  <b-modal
    scrollable
    title="Collect CR"
    ref="modal-collect-cr"
    size="lg"
    modal-class="modal-primary"
    @hidden="$emit('hidden')"
    title-tag="h3"
    :no-close-on-backdrop="true"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col cols="6">
          <h5 class="text-left" :style="{ marginTop: '4px' }">Lead</h5>
          <p
            class="rounded text-primary border-primary font-medium-1 text-center py10"
          >
            {{ lead.lead_name || lead.nickname }}
          </p>
        </b-col>
      </b-row>
      <b-row class="mt-1 rounded">
        <b-col :cols="stateOthers ? '9' : '12'">
          <div class="d-flex justify-content-start">
            <p class="px-3 paddingY bg-primary bordeL text-white fontW my-0">
              AMOUNT
            </p>
            <b-form-group
              v-slot="{ ariaDescribedby }"
              :class="
                ((client.option == '' && stateOthers == false) ||
                  (stateOthers && client.others == '')) &&
                onSaveAmount
                  ? 'border-danger'
                  : 'border-light'
              "
              class="w-100 m-0"
              required
            >
              <div
                class="d-flex justify-content-around my-0 border bordeR paddingY"
              >
                <b-form-radio
                  v-if="isNcr"
                  v-model="client.option"
                  :aria-describedby="ariaDescribedby"
                  name="some-radios"
                  :value="amount"
                >
                  $ {{ amount }}
                </b-form-radio>
                <template v-else>
                  <b-form-radio
                    v-model="client.option"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="0.00"
                    @change="selectOtherAmount(false)"
                    >$ 0.00</b-form-radio
                  >
                  <b-form-radio
                    v-model="client.option"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="18.99"
                    @change="selectOtherAmount(false)"
                    >$ 18.99</b-form-radio
                  >
                  <b-form-radio
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    :value="client.others"
                    @change="selectOtherAmount(true)"
                    >Others
                  </b-form-radio>
                </template>
              </div>
            </b-form-group>
          </div>
        </b-col>
        <b-col v-if="stateOthers" cols="3" class="d-flex justify-content-end">
          <ValidationProvider v-slot="{ errors }" name="AMOUNT" rules="money-2">
            <b-input-group prepend="$" class="inputOthers">
              <money
                v-model="client.others"
                class="form-control"
                v-bind="vMoney"
                :style="{
                  borderColor:
                    errors[0] === 'The amount must be greater than 0.00' &&
                    validador
                      ? '#fc424a'
                      : isDarkSkin
                      ? '#d8d6de'
                      : 'rgb(217 209 209)',
                }"
              />
            </b-input-group>
            <label v-if="errors[0]" class="text-danger">
              {{ errors[0] }}
            </label>
          </ValidationProvider>
        </b-col>
      </b-row>
      <!-- Method Payment -->
      <div class="w-100 d-flex flex-column justify-content-center mt-1">
        <b-table
          :style="['fontSize:5px', 'fontWeight:thin', 'overflow-y: scroll']"
          small
          :items="cards"
          :fields="fieldsCards"
          class="w-100 mb-0"
          sticky-header
        >
          <template #cell(select)="data">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-radio
                v-model="cardSelected"
                :value="data.item.id"
                @change="assignIndexSelected(data.index)"
                :class="errors[0] && validCard ? 'border-danger' : ''"
              />
            </ValidationProvider>
          </template>
          <template #cell(cardnumber)="data">
            <center>xxxx-xxxx-xxxx-{{ data.item.cardnumber }}</center>
          </template>
          <template #cell(cardsecuritycode)>
            <center>xxx</center>
          </template>
          <template #cell(created_by)="data">
            <div class="text-center">
              <b-container>
                <b-row>{{ data.item.created_by }}</b-row>
                <b-row>{{ data.item.created_at | myGlobalWithHour }}</b-row>
              </b-container>
            </div>
          </template>
          <template #cell(action)="data">
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="Trash2Icon"
                class="text-danger cursor-pointer"
                size="15"
                @click="openModalDeleteCard(data.item.id)"
              />
            </div>
          </template>
        </b-table>
        <span
          v-if="!hasCardSelected && validCard"
          class="text-danger mt-0 ml-1"
        >
          Card is required
        </span>

        <div class="d-flex flex-row-reverse">
          <b-button variant="primary" @click="openModalCardCreate()">
            <feather-icon icon="PlusIcon" size="16" />
            Add Card
          </b-button>
        </div>
      </div>
    </ValidationObserver>
    <template #modal-footer>
      <div class="d-flex justify-content-center">
        <b-button variant="outline-success" size="lg" @click="collectCR">
          Collect
        </b-button>
      </div>
    </template>
    <!-- Modal Approve -->
    <!-- <modal-approve-supervisor
      v-if="ModalApproveSupervisor"
      :modalApproveSupervisor="ModalApproveSupervisor"
      @closeModalApprove="closeModalApprove"
      @approveSup="approveSup"
    /> -->
    <modal-card-create
      v-if="isActiveModalCreateCard"
      @click="closeModalCardCreate"
      @new="refresCardTable"
      :ifModalCard="isActiveModalCreateCard"
      :idlead="lead.id"
      :session="currentUser.user_id"
    />
    <!-- <delete-card
      :card_id="cardSelected"
      :lead_id="lead_id"
      :ifModalCard="isActiveModalDeleteCard"
      :session="currentUser.user_id"
      @click="closeModalDeleteCard()"
      @new="refresCardTable"
    /> -->
  </b-modal>
</template>
<script>
// store
import { mapGetters, mapMutations } from "vuex";
//components
import ModalApproveSupervisor from "@/views/commons/components/clients/dashboard/information-client/modals/ModalApproveSupervisor.vue";
import ModalCardCreate from "@/views/crm/views/payments/components/ModalCardCreate.vue";
import DeleteCard from "@/views/crm/views/sales-made/components/modals/DeleteCardModal.vue";
//services
import cardsFields from "@/views/commons/components/ncr-realtor/views/data/fields-cards.fields";
import FinancialCommissionsService from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service";
import PaymentsService from "@/views/crm/views/payments/service/payments.service";
import ModalMixin from "@/mixins/modal";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import LeadCrmService from "@/views/crm/services/lead";
export default {
  props: {
    lead: {
      type: Object,
      default: () => {},
    },
    amount: {
      type: Number,
      default: 0,
    },
    score_id: {
      type: Number,
      required: false,
      default: null,
    },
    isNcr: {
      type: Boolean,
      default: false,
      required: false,
    },
    idNcr: {
      type: Number,
      default: null,
      required:false
    }
  },
  mixins: [ModalMixin],
  components: {
    ModalApproveSupervisor,
    ModalCardCreate,
    DeleteCard,
    DragAndDrop,
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    hasCardSelected() {
      return this.cardSelected ? true : false;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {
    //toggle modal if mounted
    this.toggleModal("modal-collect-cr");
    this.getLeadCards();
    this.client.option = this.amount;
  },
  data() {
    return {
      cardData: [],
      cardSelected: null,
      amountPartial: 0,
      fieldsCards: cardsFields,
      isVisible: false,
      client: {
        account: "",
        name: "",
        card: 0,
        obs: "",
        option: "",
        typencr: null,
        others: "",
      },
      stateOthers: false,
      hasClient: false,
      indexCardSelected: null,
      isLoading: false,
      lead_id: 0,
      isActiveModalCreateCard: false,
      isActiveModalDeleteCard: false,
      realtorIsBeenPaid: false,
      vMoney: {
        decimal: ".",
        thousands: ",",
        precision: 2,
        masked: false,
        prefix: "$ ",
        min: 0.0,
      },
      methodPaymentSelected: null,
      onSaveAmount: false,
      optionsMethodsPayment: [{ text: "Direct Payment", value: 1 }],
      optionsTypePayment: [
        { text: "Credit Card", value: 1 } /*,{ text:'Others',value:2 } */,
      ],
      optionsType: [
        { id: 1, text: "Cash" },
        { id: 2, text: "Check" },
        { id: 3, text: "Money Order" },
        { id: 4, text: "Cashier Check " },
      ],
      serialNumber: "",
      showCards: false,
      typeSelect: 0,
      ModalApproveSupervisor: false,
      typePaymentSelected: null,
      cards: [],
      validador: true,
      validCard: false,
      validAmount: false,
      idDocument: [],
    };
  },
  methods: {
    async getLeadCards() {
      try {
        const cards = await PaymentsService.searchcards({ id: this.lead.id });
        this.cards = cards;
      } catch (error) {
        console.error(error);
      }
    },
    selectOtherAmount(value) {
      this.stateOthers = value;
      this.onSaveAmount = false;
    },
    assignIndexSelected(value) {
      this.indexCardSelected = value;
    },
    openModalCardCreate() {
      this.isActiveModalCreateCard = true;
    },
    closeModalCardCreate(visible = false) {
      this.isActiveModalCreateCard = visible;
    },
    refresCardTable(data) {
      this.assignCardData(data);
    },
    assignCardData(arr) {
      this.cards != [] ? (this.cards = []) : "";
      arr.map((el) => {
        el.cardnumber ? this.cards.push(el) : "";
      });
    },
    async collectCR() {
      try {
        if (this.client.option == "" && this.client.others == "") {
          this.showInfoSwal("Invalid information", "Please select an amount");
          return;
        }
        const success = await this.$refs.form.validate();
        if (success) {
          this.addPreloader();
          const { data } = await LeadCrmService.postLeadPayment({
            amount: this.stateOthers ? this.client.others : this.client.option,
            card_id: this.cards[this.indexCardSelected].id,
            lead_id: this.lead.id,
            method: 10,
            observation: "Collect CR",
            user_id: this.currentUser.user_id,
            score_id: this.score_id,
            NcrRequestId:this.idNcr,
          });
          if (
            data.transaction.responseCode == 1 ||
            data.transaction.responseCode == ""
          ) {
            const paramsCommission = {
              chargeId: 1,
              base_amount: this.stateOthers
                ? this.client.others
                : this.client.option,
              agent_id: this.currentUser.user_id,
              client_account: null,
              score_id: this.lead.score,
              lead_id: this.lead.id,
              module_id: this.moduleId,
              trans_id: data.transaction.transId,
            };
            const insertCommission =
              await FinancialCommissionsService.createCommissionChargesCed(
                paramsCommission
              );

            this.$emit("onCollectedPayment");
            this.showSuccessSwal();
          } else {
            this.showErrorSwal(
              data.transaction?.messages?.message[0].description ||
                data.transaction.errors.error[0].errorText,
              "Transaction Declined",
              "warning"
            );
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    ...mapMutations({
      // Used to update the payments table when this modal is called from the client dashboard
      setPaymentsTableStatus: "DebtSolutionClients/SET_UPDATE_PAYMENTS_TABLE",
    }),
  },
};
</script>
<style scoped>
.paddingY {
  padding-bottom: 8px;
  padding-top: 8px;
}
.paddingX {
  padding-left: 45px;
  padding-right: 45px;
}
.marginR {
  margin-right: 10px;
}
.bordeR {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bordeL {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.fontW {
  font-weight: bold;
}
.inputOthers {
  height: 36px;
}

.title_lable {
  width: 150px;
}
</style>
