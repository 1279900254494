export default [
  {
    path: "dashboard",
    name: "dashboard-quality",
    component: () => import("@/views/quality/views/dashboard/Dashboard.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
    },
  },
]
