import BinnacleService from "@/views/commons/components/paragon-soft/services/PGBinnacle.service.js";

export default {
  namespaced: true,
  state: {
    S_CHATS: [],
    S_CURRENT_CHAT_ID: null,
    S_MESSAGES: [],
    S_CURRENT_CHAT_HEADER: null,
    S_CURRENT_MEMBERS: [],
    S_EMOJIS: [],
    S_TOTAL_MESSAGES: 0,
  },
  getters: {
    G_CHATS(state) {
      return state.S_CHATS;
    },
    G_CURRENT_CHAT_ID(state) {
      return state.S_CURRENT_CHAT_ID;
    },
    G_MESSAGES(state) {
      return state.S_MESSAGES;
    },
    G_CURRENT_CHAT_HEADER(state) {
      return state.S_CURRENT_CHAT_HEADER;
    },
    G_CURRENT_CHAT_MEMBERS(state) {
      return state.S_CURRENT_MEMBERS;
    },
    G_EMOJIS(state) {
      return state.S_EMOJIS;
    },
    G_TOTAL_MESSAGES(state) {
      return state.S_TOTAL_MESSAGES;
    },
  },
  mutations: {
    M_SET_CHATS(state, payload) {
      state.S_CHATS = payload;
    },
    M_SET_CURRENT_CHAT_ID(state, payload) {
      state.S_CURRENT_CHAT_ID = payload;
    },
    M_SET_MESSAGES(state, payload) {
      state.S_MESSAGES = payload;
    },
    M_SET_CURRENT_CHAT_HEADER(state, payload) {
      state.S_CURRENT_CHAT_HEADER = payload;
    },
    M_CURRENT_MEMBERS(state, payload) {
      state.S_CURRENT_MEMBERS = payload;
    },
    M_ADD_MEMBER(state, payload) {
      state.S_CURRENT_MEMBERS.push(payload);
    },
    M_SET_USER_PHOTO(state) {
      state.S_MESSAGES.forEach((message) => {
        const user_id = message.created_by;
        const participant_index = state.S_CURRENT_MEMBERS.findIndex(
          (participant) => participant.user_id === user_id
        );
        if (participant_index != -1) {
          const user_photo =
            state.S_CURRENT_MEMBERS[participant_index].user_photo;
          if (user_photo) {
            message.user_photo = user_photo;
          }
        }
      });
    },
    M_SET_EMOJIS(state, payload) {
      state.S_EMOJIS = payload;
    },
    M_SET_REACTIONS_IN_MESSAGE_BY_ID(state, payload) {
      state.S_MESSAGES.forEach((message) => {
        if (message.message_id === payload.message_id) {
          message.reactions = payload.reactions;
        }
      });
    },
    M_SET_TOTAL_MESSAGES(state, payload) {
      state.S_TOTAL_MESSAGES = payload;
    },
  },
  actions: {
    async A_GET_CHATS({ commit }, body) {
      try {
        const response = await BinnacleService.getBinnacles(body);
        commit("M_SET_CHATS", response);
        return response;
      } catch (error) {
        throw error;
      }
    },

    async A_GET_MESSAGES({ commit }, body) {
      try {
        const response = await BinnacleService.getBinnacleMessages(body);
        commit("M_SET_MESSAGES", response);
        return response;
      } catch (error) {
        throw error;
      }
    },
    async A_GET_CURRENT_CHAT_HEADER({ commit }, body) {
      try {
        const response = await BinnacleService.getCurrentBinnacleHeader(body);
        if (response.length > 0) {
          commit("M_SET_CURRENT_CHAT_HEADER", response[0]);
        }
      } catch (error) {
        throw error;
      }
    },

    async A_GET_CHAT_MEMBERS({ commit }, body) {
      try {
        const response = await BinnacleService.getBinnacleMembers(body);
        commit("M_CURRENT_MEMBERS", response);
        return response;
      } catch (error) {
        throw error;
      }
    },

    async A_GET_EMOJIS({ commit }, body) {
      try {
        const response = await BinnacleService.getEmojis(body);
        commit("M_SET_EMOJIS", response);
        return response;
      } catch (error) {
        throw error;
      }
    },

    async A_GET_TOTAL_MESSAGES({ commit }, body) {
      try {
        const response = await BinnacleService.getCounterMessages({
          user_id: body.user_id,
        });
        commit("M_SET_TOTAL_MESSAGES", response);
        return response;
      } catch (error) {
        throw error;
      }
    },
  },
};
