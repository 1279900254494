<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-header-close
      hide-header
      :body-class="`rounded`"
      no-close-on-backdrop
      size="summary"
      @hidden="isActive = false"
      centered
      :ok-only="true"
      ok-variant="success"
      hide-footer
    >
      <div v-if="dataWp">
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
        >
          <div class="d-flex align-items-center" style="gap: 7px">
            <span class="title-alert font-strong">REQUEST WORK PLAN</span>
          </div>
          <div
            class="close-modal"
            @click="closeModal()"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
          >
            <feather-icon icon="XIcon" size="16" />
          </div>
        </div>
        <div class="d-flex align-items-center mt-1">
          <div class="icon-badge mr-1" :class="badgeColor">
            <feather-icon :icon="iconTitle" size="20"></feather-icon>
          </div>
          <span>
            <span :class="colorText" class="text-uppercase"> {{ title }} </span>
          </span>
        </div>
        <hr />
        <div class="d-flex flex-column" style="gap: 15px">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CLIENT
            </span>
            <span class="font-strong">{{ dataWp.client }}</span>
          </div>

          <div
            v-if="dataWp.status_account_id"
            class="d-flex justify-content-between align-items-center"
          >
            <span class="font-strong">
              <feather-icon
                icon="BookmarkIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              ACCOUNT
            </span>
            <div class="d-flex justify-content-center align-items-center">
              <IconStatusAccount
                class="perso"
                :status="dataWp.status_account_id"
                fromBc
              />
              <span class="font-strong">{{ dataWp.client_account }}</span>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="MapIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              DEPARTMENT
            </span>
            <span class="font-strong">{{
              nameModule(dataWp.module_name)
            }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserCheckIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              REQUESTED BY
            </span>
            <span class="font-strong">{{ dataWp.created_by_ncr_name }}</span>
          </div>

          <div class="d-flex align-items-start" v-if="displayedServices">
            <span class="font-strong col-4 justify-content-start p-0">
              <feather-icon
                icon="GridIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              SERVICES
            </span>
            <div
              class="d-flex justify-content-end align-items-start p-0 col-8"
              style="flex-wrap: wrap; gap: 5px"
            >
              <b-badge
                v-for="(item, index) in displayedServices"
                :key="index"
                variant="light-info"
              >
                {{ item.name }}
              </b-badge>
              <span
                @click="toggleShowAll"
                v-if="shouldShowToggle"
                class="text-primary cursor-pointer"
              >
                {{ showAll ? "see less..." : "see more..." }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <b-row>
        <b-col>
          <b-button
            class="w-100 block"
            target="_blank"
            variant="info"
            @click="goToWorkPLan"
            >GO TO WORK PLANS</b-button
          >
        </b-col>
        <b-col>
          <b-button class="w-100 block" variant="success" @click="closeModal()"
            >OK</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";

export default {
  name: "AlertWp",

  components: {
    IconStatusAccount,
  },

  data() {
    return {
      detailWp: null,
      showAll: false,
    };
  },
  computed: {
    ...mapGetters({
      G_IS_ACTIVE: "AutoAlertWorkPlan/G_IS_ACTIVE",
      G_DATA_WP: "AutoAlertWorkPlan/G_DATA_WP",
      title: "AutoAlertWorkPlan/G_TITLE",
      currentUser: "auth/currentUser",
    }),

    isSpecialists() {
      return this.moduleId == 11;
    },

    isActive: {
      get() {
        return this.G_IS_ACTIVE;
      },
      set(value) {
        this.$store.commit("AutoAlertWorkPlan/M_OPEN_ALERT", value);
        setTimeout(() => {
          this.dataWp = null;
        }, 500);
      },
    },

    dataWp: {
      get() {
        return this.G_DATA_WP;
      },
      set(value) {
        this.$store.commit("AutoAlertWorkPlan/M_DATA_WP", value);
      },
    },

    moduleId() {
      return (
        (this.$route.matched[0].meta.module == 28
          ? 11
          : this.$route.matched[0].meta.module) || this.currentUser.modul_id
      );
    },

    iconTitle() {
      const status = this.dataWp?.status_num;
      let icons = {
        1: "CheckIcon", // PENDING
        3: "XOctagonIcon", // RETURNED
        6: "ChevronsLeftIcon", // WAITING FOR APPROVAL
        7: "CheckIcon", // COMPLETED
      };
      return icons[status] || "AlertTriangleIcon";
    },

    colorText() {
      const status = this.dataWp?.status_num;
      let color = {
        1: "text-success font-strong", // PENDING
        3: "text-danger font-strong", // RETURNED
        6: "text-warning font-strong", // WAITING FOR APPROVAL
        7: "text-success font-strong", // COMPLETED
      };
      return color[status] || "text-warning font-strong";
    },

    badgeColor() {
      const status = this.dataWp?.status_num;
      let classes = {
        1: "bg-light-success", // PENDING
        3: "bg-light-danger", // RETURNED
        6: "bg-light-warning", // WAITING FOR APPROVAL
        7: "bg-light-success", // COMPLETED
      };
      return classes[status] || "bg-light-warning";
    },

    nameModuleRoute() {
      const routers = {
        2: "crm",
        3: "bu",
        5: "ds",
        6: "ce",
        7: "bc",
        8: "tr",
        10: "court-info",
        11: "sp",
        20: "cn",
        22: "csd",
        28: "sp",
        29: "sp",
      };
      return routers[this.moduleId] || null;
    },
    displayedServices() {
      if (this.dataWp.recommended_services) {
        const services = JSON.parse(this.dataWp.recommended_services);
        return this.showAll ? services : services.slice(0, 2);
      }
    },
    shouldShowToggle() {
      if (this.dataWp.recommended_services) {
        const services = JSON.parse(this.dataWp.recommended_services);
        return services.length > 2;
      }
    },
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "RequestWorkPlanStore/CHANGE_REFRESH_TABLE",
      A_REQUEST_WORKPLAN_COUNTERS:
        "RequestWorkPlanStore/A_REQUEST_WORKPLAN_COUNTERS",
    }),
    closeModal() {
      const channel = socket.channel("request-ncr-notifications", {});
      channel.trigger("client-close-all-ncr-notification", {
        message: "close all",
        user_id: this.currentUser.user_id,
      });
      this.isActive = false;
      this.A_REQUEST_WORKPLAN_COUNTERS(this.moduleId);
    },
    closeModalDetail() {
      this.detailWp = null;
      this.isActive = false;
    },
    goToWorkPLan() {
      this.closeModal();
      const subTabName = this.$route.name;
      const statusMap = {
        "IN PROCESS": "pending",
        "WAITING FOR APPROVAL": "to-revision",
        PENDING: "pending",
        COMPLETED: "completed",
        RETURNED: "pending",
      };
      const status = statusMap[this.dataWp.status];

      const targetTabName = `${this.nameModuleRoute}-wp-${status}`;
      if (subTabName == targetTabName) {
        this.CHANGE_REFRESH_TABLE(true);
      } else {
        this.$router.push({ name: targetTabName });
      }
    },

    nameModule(module) {
      if (module == "CUSTOMER SERVICE REGULAR") {
        return "CREDIT EXPERTS (REGULAR)";
      } else if (module == "CUSTOMER SERVICE DIGITAL") {
        return "CREDIT EXPERTS (DIGITAL)";
      } else {
        return module;
      }
    },

    toggleShowAll() {
      this.showAll = !this.showAll;
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-badge {
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
}
.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}

.perso {
  margin-right: 5px;
  padding-bottom: 3px;
}
</style>
