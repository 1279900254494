import { amgApi } from "@/service/axios";

class ClientsOptionsServices {
  async getListCharges(params) {
    const data = await amgApi.post("/pay/list-charges", params);
    return data;
  }

  async getCheckout(params) {
    const data = await amgApi.post("/pay/checkout", params);
    return data;
  }

  async registerAdvancePayment(params) {
    try {
      const data = await amgApi.post("/pay/register-advance-payment", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getMinAmountByProgramId(params) {
    try {
      const data = await amgApi.post(
        "/pay/get-min-amount-by-program-id",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCountExpirationCardTab(params) {
    const data = await amgApi.post(
      "/cards/get-count-expiration-card-tab",
      params
    );
    return data;
  }

  async getFileCountClient(params) {
    const data = await amgApi.post("/clients/files/file-count-client", params);
    return data;
  }

  async getListPayments(params) {
    const data = await amgApi.post("/pay/list-payment", params);
    return data;
  }

  async getChargeAccountsById(params) {
    const data = await amgApi.post("/pay/update-charge-view", params);
    return data;
  }

  async getVoidRefund(params) {
    const data = await amgApi.post("/crm/payment/get-void-refund", params);
    return data.data;
  }

  async getWithoutPayment(params) {
    const data = await amgApi.post("/pay/get-without-payment", params);
    return data.data;
  }

  async deleteWithoutPayment(params) {
    const data = await amgApi.post("/pay/delete-without-payment", params);
    return data;
  }

  async getTypeChargesDevSolutionClients(idClient, modul) {
    const data = await amgApi.get(
      `/pay/get-type-charges?idClient=${idClient}&modul=${modul}`
    );
    return data;
  }

  async getTypeChargesPost(params) {
    const data = await amgApi.post("/pay/get-type-charges-module", params);
    return data;
  }

  async getAllSubCharges(body) {
    const data = await amgApi.post(
      "business/settings/get-subcharges-list",
      body
    );
    return data;
  }

  async loadFeeLossSubCharge(body) {
    const data = await amgApi.post(
      "business/settings/get-feeloss-by-subcharge",
      body
    );
    return data;
  }

  // Void
  async voidTransaction(params) {
    const data = await amgApi.post("/authorize/void-transaction", params);
    return data;
  }
  //Program Partial Void

  async programPartialVoidTransaction(params) {
    const data = await amgApi.post(
      "/authorize/program-partial-void-transaction",
      params
    );
    return data;
  }
  //Refund
  async refundTransaction(params) {
    const data = await amgApi.post("/authorize/refund-transaction", params);
    return data;
  }
  //Partial Refund
  async refundPartialTransaction(params) {
    const data = await amgApi.post(
      "/authorize/refund-partial-transaction",
      params
    );
    return data;
  }

  async getEditDayPayment(params) {
    const data = await amgApi.post("/pay/edit-date-payment", params);
    return data;
  }

  async getAllNcrs(params) {
    const data = await amgApi.post("/dispute/get-all-ncrs", params);
    return data;
  }

  async searchPdfRounds(params) {
    const data = await amgApi.post("/dispute/search-pdf-round", params);
    return data;
  }

  async counterDispute(params) {
    const data = await amgApi.post("/dispute/dispute-count-client", params);
    return data;
  }

  async updateFromSpecialistEvaluated(params) {
    const data = await amgApi.post(
      "/dispute/update-from-specialist-evaluated",
      params
    );
    return data;
  }

  async proccessNcrSearch(params) {
    const data = await amgApi.post("/commons/process-ncr-search", params);
    return data;
  }

  async changeStatusNcr(params) {
    const data = await amgApi.post("/commons/change-status-ncr", params);
    return data;
  }

  async searchLettersAccount(params) {
    const data = await amgApi.post("/dispute/search-letters-account", params);
    return data;
  }

  async getAllLetterUpdate(params) {
    const data = await amgApi.post("/dispute/get-all-letter-update", params);
    return data;
  }

  async getCountUpdates(params) {
    const data = await amgApi.post(
      "/dispute/get-count-updates-pending",
      params
    );
    return data;
  }

  async getAllTrackingNcr(params) {
    const data = await amgApi.post("/dispute/all-trackings", params);
    return data;
  }

  async getAllTrackingLetters(params) {
    const data = await amgApi.post("/dispute/all-trackings-letters", params);
    return data;
  }

  async getCredentialEmail(params) {
    const data = await amgApi.post("/dispute/get-credential-email", params);
    return data;
  }

  async getValidateDirect(params) {
    const data = await amgApi.post("/dispute/validate-direct", params);
    return data;
  }

  async getDeleteAccountCnPi(params) {
    const data = await amgApi.post("/dispute/get-delete-account-cnpi", params);
    return data;
  }

  async getDeleteAccountCnIn(params) {
    const data = await amgApi.post("/dispute/get-delete-account-cnin", params);
    return data;
  }

  async getDeleteAccountCnPr(params) {
    const data = await amgApi.post("/dispute/get-delete-account-cnpr", params);
    return data;
  }

  async getDeleteAccountCnAc(params) {
    const data = await amgApi.post("/dispute/get-delete-account-cnac", params);
    return data;
  }

  async deleteDeleteAccountPinw(params) {
    const data = await amgApi.post(
      "/dispute/delete-delete-account-pinw",
      params
    );
    return data;
  }

  async deleteDeleteAccountAcnw(params) {
    const data = await amgApi.post(
      "/dispute/delete-delete-account-acnw",
      params
    );
    return data;
  }

  async deleteDeleteAccountInnw(params) {
    const data = await amgApi.post(
      "/dispute/delete-delete-account-innw",
      params
    );
    return data;
  }

  async deleteDeleteAccountPrnw(params) {
    const data = await amgApi.post(
      "/dispute/delete-delete-account-prnw",
      params
    );
    return data;
  }

  async deleteRoundLetter(params) {
    const data = await amgApi.post("/dispute/delete-round-letter", params);
    return data;
  }

  async getRoundId(params) {
    const data = await amgApi.post("/dispute/get-round-id", params);
    return data;
  }

  async editRound(params) {
    const data = await amgApi.post("/dispute/edit-round", params);
    return data;
  }

  async searchLetterUser(params) {
    const data = await amgApi.post("/dispute/search-letter-user", params);
    return data;
  }

  async saveNewLetterAccount(params) {
    const data = await amgApi.post("/dispute/save-new-letter-account", params);
    return data;
  }

  async searchRoundLetters(params) {
    const data = await amgApi.post("/dispute/search-round-letters", params);
    return data;
  }

  async getPaymentSalesMigrations(params) {
    const data = await amgApi.post("/pay/payment-sales-migrations", params);
    return data;
  }

  async updatePaymentDay(params) {
    const data = await amgApi.post("/pay/update-payment-date", params);
    return data;
  }

  async searchcards(params) {
    const data = await amgApi.post("/cards/search-cards", params);
    return data;
  }

  async deletesearchcards(params) {
    const data = await amgApi.post("/cards/search-cards-deleteds", params);
    return data;
  }

  async deleteCardsById(params) {
    const data = await amgApi.post("cards/delete-cards-by-id", params);
    return data;
  }

  async saveCharge(params) {
    const data = await amgApi.post("pay/save-charge", params);
    return data;
  }

  async checkout(params) {
    const data = await amgApi.post("pay/checkout", params);
    return data;
  }

  async getCreditors(params) {
    const data = await amgApi.post("pay/creditors-accounts", params);
    return data;
  }

  async savewithoupayment(params) {
    const data = await amgApi.post("pay/without-payment", params);
    return data;
  }
  async getNegotiationsActive(params) {
    const data = await amgApi.post("debt-solution/negotiations-active", params);
    return data;
  }

  async getAllTasksGrid(body) {
    try {
      const data = await amgApi.post(
        "/credit-expert/get-all-tasks-grilla",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async checkTaskCredit(body) {
    try {
      const data = await amgApi.post("/credit-expert/check-task-credit", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCreditorPhase(body) {
    try {
      const data = await amgApi.post("/sale/get-creditor-phase", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async insertAccountAction(body) {
    try {
      const data = await amgApi.post("/sale/insert-account-action", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async editTaskCredit(body) {
    try {
      const data = await amgApi.post("/credit-expert/edit-task-credit", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteTaskCredit(body) {
    try {
      const data = await amgApi.post("/credit-expert/delete-task-credit", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addTaskCredit(body) {
    try {
      const data = await amgApi.post("/credit-expert/add-task", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getQuickTasks(body) {
    try {
      const data = await amgApi.post("/messages/all-tasks-select", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteQuickTask(body) {
    try {
      const data = await amgApi.post("/messages/delete-task", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveQuickTask(body) {
    try {
      const data = await amgApi.post("/messages/save-task", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getClientsByModuleAndRole(id, body) {
    try {
      const data = await amgApi.post(`/commons/user-module/${id}`, body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getClientAccount(body) {
    try {
      const data = await amgApi.post("/commons/search-account", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCompletedTasks(body) {
    try {
      const data = await amgApi.post(
        "/credit-expert/all-tasks-completed",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteNoteAccount(body) {
    try {
      const data = await amgApi.post(
        "/credit-expert/delete-notes-account",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveWorkPlan(body) {
    try {
      const data = await amgApi.post("/credit-expert/save-work-plan", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async sendAnnulWorkPlan(body) {
    try {
      const data = await amgApi.post("/credit-expert/annul-work-plan", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllWorks(body) {
    try {
      const data = await amgApi.post("/credit-expert/all-works", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllWorksNew(body) {
    try {
      const data = await amgApi.post("/credit-expert/all-works-new", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllWorkplansOld(body) {
    try {
      const data = await amgApi.post("/credit-expert/all-works-old", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getNotesYear(body) {
    try {
      const data = await amgApi.post("/credit-expert/get-notes-grilla", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getQuickNotes(body) {
    try {
      const data = await amgApi.post("/clients/get-quick-notes-user", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addNotesCredit(body) {
    try {
      const data = await amgApi.post("/credit-expert/add-note-credit", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async sendMessageWelcomeCDE(body) {
    try {
      const data = await amgApi.post(
        "/credit-expert/send-message-welcome-cde",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteQuickNote(body) {
    try {
      const data = await amgApi.post("/clients/delete-quick-note-user", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveQuickNote(body) {
    try {
      const data = await amgApi.post("/clients/save-quick-note-user", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllNotes(body) {
    try {
      const data = await amgApi.post("/credit-expert/get-all-notes", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllNotesById(body) {
    try {
      const data = await amgApi.post("/credit-expert/get-all-notes-by-id", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getNoteToEdit(body) {
    try {
      const data = await amgApi.post("/credit-expert/edit-notes-credit", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async editNote(body) {
    try {
      const data = await amgApi.post("/credit-expert/edit-note", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async downloadNoteFile(body) {
    try {
      const data = await amgApi.post("/credit-expert/download-note-file", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchClientApplications(body) {
    try {
      const data = await amgApi.post("/credit-expert/search-client-app", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchBankInfo(body) {
    try {
      const data = await amgApi.post("/credit-expert/search-bank-info", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTrackingData(body) {
    try {
      const data = await amgApi.post("/credit-expert/search-tracking", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllWorkPlan(body) {
    try {
      const data = await amgApi.post("/credit-expert/all-work-plan", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllWorkPlanWhenNull(body) {
    try {
      const data = await amgApi.post("/credit-expert/all-work-plan-null", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  // welcome
  async getNotesWelcomeDs(body) {
    try {
      const data = await amgApi.post("/sales-made/notes-sales-welcome", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveUpdateWelcomeDs(body) {
    try {
      const data = await amgApi.post("/sales-made/update-welcome", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addContactSchedule(body) {
    try {
      const data = await amgApi.post(
        "/note/schedule-client/add-schedule-client",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async deleteContactSchedule(body) {
    try {
      const data = await amgApi.post(
        "/note/schedule-client/delete-schedule-client",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async confirmContactSchedule(body) {
    try {
      const data = await amgApi.post(
        "/note/schedule-client/confirm-schedule-client",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  // states eeuu
  async getStatesUSApi(body) {
    try {
      const data = await amgApi.get("/commons/get-states-eeuu", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllCredentials(body) {
    try {
      const data = await amgApi.post("/clients/all-credentials", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCredential(body) {
    try {
      const data = await amgApi.post(
        "/clients/acr/edit-credential-account",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteCredential(body) {
    try {
      const data = await amgApi.post("/clients/acr/delete-credential", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveCredential(body) {
    try {
      const data = await amgApi.post("/clients/acr/save-credential", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getFilesClient(body) {
    try {
      const data = await amgApi.post("/clients/files/list-files", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getStatusLetters(body) {
    try {
      const data = await amgApi.get(
        "/clients/reports/all-status-letters",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTrackingLetters(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/all-trackings-letter",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async renameFolder(body) {
    try {
      const data = await amgApi.post("/clients/files/rename-folder", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFolder(body) {
    try {
      const data = await amgApi.post("/clients/files/delete-folder", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async renameFile(body) {
    try {
      const data = await amgApi.post("/clients/files/rename-file", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFile(body) {
    try {
      const data = await amgApi.post("/clients/files/delete-file", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createFolder(body) {
    try {
      const data = await amgApi.post("/clients/files/create-folder", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveOneFileAccount(body) {
    try {
      const data = await amgApi.post("/clients/files/save-account-docu", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveMultipleFilesAccount(body) {
    try {
      const data = await amgApi.post("/clients/files/files-multiple-dd", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getFoldersRoutes(body) {
    try {
      const data = await amgApi.post("/clients/files/get-folder-routes", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async moveCopyFile(body) {
    try {
      const data = await amgApi.post("/clients/files/move-copy-folder", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getReasons(body) {
    try {
      const data = await amgApi.get("/clients/reports/get-reasons", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getInstructions(body) {
    try {
      const data = await amgApi.get("/clients/reports/get-instructions", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDataEditCreditor(body) {
    try {
      const data = await amgApi.post("/sale/edit-creditor", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCreditor(body) {
    try {
      const data = await amgApi.post("/sale/update-creditor-account-ds", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAccountPhase(body) {
    try {
      const data = await amgApi.post("/sale/account-phase", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteAccountsPi(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/delete-accounts-pi",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteAccountsIn(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/delete-accounts-in",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteAccountsPr(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/delete-accounts-pr",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteAccountsAc(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/delete-accounts-ac",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addAccountCreditor(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/add-account-creditor",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getStatusAccountsLetter(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/all-status-accounts-letter",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPaymentsStatus(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/all-cr-payment-status",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchAccountsCreditors(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/search-accounts-creditors",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteCreditsDebtSolution(body) {
    try {
      const data = await amgApi.post(
        "/sales-made/debt-solution/delete-credits-debt-solution",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDeleteAccountIn(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/get-delte-account-in",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDeleteAccountPi(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/get-delte-account-pi",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDeleteAccountPr(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/get-delte-account-pr",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDeleteAccountAc(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/get-delte-account-ac",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAccountsToDispute(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/get-accounts-to-dispute",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteDelteAccountAc(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/delete-delte-account-ac",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteDelteAccountIn(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/delete-delte-account-in",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteSingleOneAccountReportIn(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/delete-single-one-account-report-in",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteDelteAccountPr(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/delete-delte-account-pr",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteSingleOneAccountReportPr(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/delete-single-one-account-report-pr",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteDelteAccountPi(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/delete-delte-account-pi",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteForException(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/delete-for-exception",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async editPersonalInformation(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/edit-delte-account-pi",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async editInquiry(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/edit-delte-account-in",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async editPublicRecord(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/edit-delte-account-pr",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async editAccount(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/edit-delte-account-ac",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async callDelteAccount(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/call-delte-account-ac",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTrackingCreditorDs(body) {
    try {
      const data = await amgApi.post(
        "/clients/acc/get-tracking-creditor-ds",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchFilesAccountDs(body) {
    try {
      const data = await amgApi.post(
        "/clients/acc/search-files-account-ds",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async printPdfAccountAction(params) {
    try {
      const data = await amgApi.post(
        "/clients/acc/print-pdf-account-action",
        params,
        { responseType: "blob" }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveEditedFileAcc(body) {
    try {
      const data = await amgApi.post(
        "/clients/acc/edit-files-creditors-ds",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async uploadFilesCreditorsAccount(body) {
    try {
      const data = await amgApi.post(
        "/clients/acc/upload-files-creditors-account",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFilesCreditorsDs(body) {
    try {
      const data = await amgApi.post(
        "/clients/acc/delete-files-creditors-ds",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async printFileAcc(body) {
    try {
      const data = await amgApi.post("/clients/acc/print-file", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async allLettersDispute(body) {
    try {
      const data = await amgApi.post("/dispute/all-letters-dispute", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async allDataLettersDispute(body) {
    try {
      const data = await amgApi.post("/dispute/all-data-letters-dispute", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async processRoundLetters(body) {
    try {
      const data = await amgApi.post("/dispute/process-round-letters", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  // messages
  async sendMessage(body) {
    try {
      const data = await amgApi.post("/messages/send-message-welcome", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getFirstWorkPlan(params) {
    const data = await amgApi.post("/commons/get-first-workplan", params);
    return data;
  }

  async approveDisapproveClientFile(params) {
    const data = await amgApi.post(
      "/commons/approve-dissaprove-client-file",
      params
    );
    return data;
  }

  // Files App
  async getTrackingFilesAccount(body) {
    try {
      const data = await amgApi.post(
        "/clients/files/get-tracking-files-account",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeStatusFilesApp(body) {
    try {
      const data = await amgApi.post(
        "/clients/files/change-status-file-app",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getObservationsFiles(body) {
    try {
      const data = await amgApi.post(
        "/clients/files/get-observations-files",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async insertObservationFilesApp(body) {
    try {
      const data = await amgApi.post(
        "/clients/files/insert-observation-files-app",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  //Send Report pdf Correspondece

  async sendReportPdfCorrespondece(body) {
    try {
      const data = await amgApi.post("/clients/reports/send-report-pdf", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  //Send Report pdf Correspondece

  async generateStatusReport(body) {
    try {
      const data = await amgApi.post("/specialists/generate-send-report", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  // add commision for creditexperts digital
  async insertChargeCommision(type, amount, id, clientAccount) {
    let fixedType = -1;
    // if type is String include text NCR
    let containsNCR = String(type).includes("NCR");
    switch (type) {
      case "NCR":
      case "NCR-REALTOR":
      case containsNCR:
      case 1:
        fixedType = 4;
        break;
      case 2:
        fixedType = 6;
        break;
      case 19:
        fixedType = 5;
        break;
      case 20:
        fixedType = 7;
        break;
    }
    if (fixedType == -1) return 0;
    let payload = {
      commision_type: fixedType,
      base_amount: amount,
      agent_id: id,
      client_account: clientAccount,
    };
    try {
      await amgApi.post("/credit-experts-digital/create-commision", payload);
    } catch (error) {
      console.error(error);
    }
  }

  async getAllTitles() {
    const data = await amgApi.get("/lead/ncr/all-titles");
    return data.data;
  }

  async getAllFormats() {
    const data = await amgApi.get("/lead/ncr/all-formats");
    return data.data;
  }

  async getAllSecureSocial(params) {
    const data = await amgApi.post("/lead/ncr/all-secure-social", params);
    return data.data;
  }

  async getAllBureaus() {
    const data = await amgApi.get("/lead/ncr/all-bureaus");
    return data.data;
  }

  async callDeleteAccountIn(params) {
    const data = await amgApi.post("/dispute/call-delete-account-in", params);
    return data;
  }

  async editDelAccountInUni(params) {
    const data = await amgApi.post(
      "/dispute/edit-delete-account-in-uni",
      params
    );
    return data;
  }

  async editDelAccountPrUni(params) {
    const data = await amgApi.post(
      "/dispute/edit-delete-account-pr-uni",
      params
    );
    return data;
  }

  async getSpecialistCredentials(params) {
    const data = await amgApi.post(
      "/specialists-digital/get-specialist-credentials",
      params
    );
    return data;
  }

  async callDeleteAccountPr(params) {
    const data = await amgApi.post("/dispute/call-delete-account-pr", params);
    return data;
  }

  async saveSpecialistCredentials(params) {
    const data = await amgApi.post(
      "/specialists-digital/save-specialist-credentials",
      params
    );
    return data;
  }

  async deleteSpecialistCredentialsById(params) {
    const data = await amgApi.post(
      "/specialists-digital/delete-specialist-credentials-by-id",
      params
    );
    return data;
  }

  async callDeleteAccountPi(params) {
    const data = await amgApi.post("/dispute/call-delete-account-pi", params);
    return data;
  }

  async saveRound(params) {
    const data = await amgApi.post("/lead/ncr/save-round", params);
    return data;
  }

  async getTypeSender(id) {
    const data = await amgApi.get(`/clients/reports/get-type-sender/${id}`);
    return data;
  }

  async getDetailLetter(params) {
    const data = await amgApi.post("/clients/reports/detail-letter-pi", params);
    return data;
  }

  async saveLetterRound(params) {
    const response = await amgApi.post(
      "/clients/reports/save-letter-round",
      params
    );
    return response;
  }

  async generateComission(params) {
    try {
      const response = await amgApi.post(
        "/credit-expert/connection/generate-comission",
        params
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async editSingleAccountReportIn(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/edit-account-report-in",
        body
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async editSingleAccountReportPr(body) {
    try {
      const data = await amgApi.post(
        "/clients/reports/edit-account-report-pr",
        body
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async getSummary(params) {
    const data = await amgApi.post("/summary-account/get-summary", params);
    return data;
  }

  async countRequestNcrForUpdate(body) {
    try {
      const data = await amgApi.post(
        "commons/dispute-results/count-request-ncr-for-update",
        body
      );
      return { data };
    } catch (error) {
      console.log(error);
    }
  }

  async getMissingFiles(body) {
    try {
      const data = await amgApi.post(
        "commons/dispute-results/get-missing-files",
        body
      );
      return { data };
    } catch (error) {
      console.log(error);
    }
  }

  async getCreatesSale(params) {
    try {
      const data = await amgApi.post("/sale/get-creates-sale", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getChargesByClientAccount(params) {
    try {
      const data = await amgApi.post(
        "/pay/list-charges-by-client-account",
        params
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getRefundsByClientAccount(params) {
    try {
      const data = await amgApi.post(
        "/pay/list-refunds-by-client-account",
        params
      );
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getCreditsAndInitialPaymentsByClientAccount(params) {
    try {
      const data = await amgApi.post(
        "/commons/credits-and-initial-payments/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTotalCreditsAndInitialPaymentsByClientAccount(clientAccountId) {
    try {
      const data = await amgApi.get(
        `/commons/credits-and-initial-payments/show/${clientAccountId}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCreditoName(params) {
    const data = await amgApi.post("pay/update-creditor-name", params);
    return data;
  }

  async getClientData(params) {
    try {
      const data = await amgApi.post("commons/search-account", params);
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getCountEffectivity(params) {
    try {
      const data = await amgApi.post("commons/get-count-effectivity", params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async setItemAnalysis(params) {
    try {
      const data = await amgApi.post("request-ncr/set-item-analysis", params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  
  async generateTranscribe(params) {
    try {
      const data = await amgApi.post('/commons/aws/send-to-transcribe-client-call', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllNotesVersions(body) {
    try {
      const data = await amgApi.post("/credit-expert/get-all-notes-versions", body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async sendToTranscribe(params) {
    try {
      const data = await amgApi.post('/commons/aws/send-to-transcribe', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getNoteIds(params){
    try {
      const data = await amgApi.post('/crm/event/get-note-ids', params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new ClientsOptionsServices();
