<template>
  <div>
    <b-modal
      v-model="showModal"
      size="md"
      hide-footer
      header-bg-variant="primary"
      @hidden="closeModal"
    >
      <template #modal-title>
        <h5
          class="font-weight-bolder text-uppercase d-flex text-light"
          style="padding-top: 5px"
        >
          <feather-icon icon="ListIcon" size="15" style="margin-right: 8px" />
          List taks to ticket
          {{ ticketCustomer.code ? `#${ticketCustomer.code}` : "" }}
        </h5>
      </template>
      <b-container class="p-0">
        <filter-slot
          class="filter-slot-new-customization"
          :filter="filters"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="refreshTable()"
          :top-paginate="false"
        >
          <b-table
            class="position-relative table-new-customization"
            slot="table"
            ref="refListMeetings"
            :items="providerListTasks"
            :fields="fields"
            primary-key="id"
            sticky-header="45vh"
            show-empty
            :busy.sync="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(max_date)="{ item }">
              <span>{{ item.max_date | myGlobal }}</span
              ><br />
              <span v-if="item.hour_task">{{
                item.hour_task | myFullTime
              }}</span>
            </template>
            <template #cell(task)="{ item }">
              <strong>{{ item.title }}</strong>
              <br />
              <div class="d-block w-auto" style="max-width: 150px">
                {{
                  item.description.length > limitDescription
                    ? item.description.substring(0, limitDescription)
                    : item.description
                }}
                <strong
                  v-if="item.description.length > limitDescription"
                  class="text-primary cursor-pointer"
                  style="font-size: 13px"
                  @click="showDescription(item)"
                  >...show</strong
                >
              </div>
            </template>
            <template #cell(status)="{ item }">
              <b-badge :variant="color[item.status - 1]">
                {{ item.status_name }}
              </b-badge>
            </template>
            <template #cell(created_at)="{ item }">
              {{ item.created_at | myGlobalDay }}<br />
              {{ item.created_by_name }}
            </template>
            <template #cell(actions)="{ item }">
              <div class="d-flex" style="width: 75px">
                <template v-if="item.status === 1">
                  <feather-icon
                    v-b-tooltip.hover.v-success
                    v-b-tooltip.hover.top
                    title="COMPLETE"
                    icon="CheckCircleIcon"
                    size="18"
                    class="hover-me cursor-pointer text-success"
                    @click="updateTask(item.id, 2)"
                  />
                  <feather-icon
                    v-b-tooltip.hover.v-primary
                    v-b-tooltip.hover.top
                    title="EDIT"
                    icon="Edit2Icon"
                    size="18"
                    class="hover-me cursor-pointer mx-1 text-primary"
                    @click="updateTask(item.id, 1)"
                  />
                  <feather-icon
                    v-b-tooltip.hover.v-danger
                    v-b-tooltip.hover.top
                    title="DELETE"
                    icon="Trash2Icon"
                    size="20"
                    class="hover-me cursor-pointer text-danger"
                    @click="cancelTask(item.id)"
                  />
                </template>
              </div>
            </template>
          </b-table>
        </filter-slot>
      </b-container>
    </b-modal>
    <!--MODAL TASK-->
    <modal-task-component
      v-if="showModalTask"
      :ticket-customer="paramsModalTask"
      :task-id="taskId"
      @closeModal="closeModalTask"
      @successTask="successTask"
    />
    <!--MODAL DESCRIPTION-->
    <modal-description
      v-if="paramsModalDescription.description"
      :task="paramsModalDescription"
      @closeModal="paramsModalDescription = {}"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import fields from "@/views/commons/components/customer-tickets/components/modal/tasks/data/fields-list-taks";
import filters from "@/views/commons/components/customer-tickets/components/modal/tasks/data/filters-list-tasks";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import ModalTaskComponent from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalTask.vue";
import ModalDescription from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalDescription.vue";

export default {
  components: {
    ModalTaskComponent,
    ModalDescription,
  },
  props: {
    ticketCustomer: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      showModal: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: "id",
      sortDesc: false,
      fields,
      filters,
      color: ["warning", "success"],
      keyTable: 0,
      showModalTask: false,
      paramsModalTask: {},
      taskId: 0,
      paramsModalDescription: {},
      limitDescription: 50,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.showModal = true;
  },
  methods: {
    ...mapActions({
      A_TASKS_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/A_TASKS_PENDING_CUSTOMER_TICKETS",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    async refreshTable() {
      await this.$refs.refListMeetings.refresh();
    },
    async providerListTasks() {
      try {
        const params = {
          from: this.filters[0].model,
          to: this.filters[1].model,
          status: this.filters[2].model,
          search: this.filterPrincipal.model,
          perPage: parseInt(this.paginate.perPage, 10),
          nPage: this.paginate.currentPage,
          ticketCustomerId: this.ticketCustomer.id,
          user_id: this.currentUser.user_id,
        };
        const { data, status } =
          await CustomerTicketsService.getTicketCustomerTasks(params);
        if (status === 200) {
          this.totalRows = data.total || 0;
          this.startPage = data.from || 0;
          this.toPage = data.to || 0;
        }
        return data.data || [];
      } catch (error) {
        this.showErrorSwal();
        console.log("error in providerListTasks:", error);
      }
      return [];
    },
    async cancelTask(taskId) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        const params = {
          taskId,
          deletedBy: this.currentUser.user_id,
        };
        const { status } =
          await CustomerTicketsService.deletedTicketCustomerTask(params);
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal();
          await this.refreshTable();
          await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
            user_id: this.currentUser.user_id,
          });
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log("error in cancelTask:", error);
      }
    },
    async updateTask(taskId, status) {
      // change status to completed
      if (status === 2) {
        try {
          const { isConfirmed } = await this.showConfirmSwal();
          if (!isConfirmed) return;

          this.addPreloader();
          const params = {
            taskId,
            maxDate: null,
            title: null,
            description: null,
            createdBy: this.currentUser.user_id,
            status,
            allDay: null,
          };

          const { status: statusResult } =
            await CustomerTicketsService.updateTicketCustomerTask(params);
          this.removePreloader();
          if (statusResult === 200) {
            this.showSuccessSwal();
            await this.refreshTable();
            await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
              user_id: this.currentUser.user_id,
            });
          }
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal();
          console.log("Error update task:", error);
        }
      } else {
        // edit information of task
        this.showModalTask = true;
        this.paramsModalTask = this.ticketCustomer;
        this.taskId = taskId;
      }
    },
    closeModalTask() {
      this.showModalTask = false;
      this.paramsModalTask = {};
    },
    async successTask() {
      this.showModalTask = false;
      this.paramsModalTask = {};
      await this.refreshTable();
      await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
        user_id: this.currentUser.user_id,
      });
    },
    showDescription({ title, description }) {
      this.paramsModalDescription = {
        title,
        description,
      };
    },
  },
};
</script>
