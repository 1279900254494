export default {
    namespaced: true,
    state: {
        S_UPDATE_TABLE: false,
        S_UPDATE_CHARGE_TABLE: false,
        S_DATAGLOBAL:[], 
        S_ENTRANCES_TYPE_ID:0,
        S_ENTRANCES_CHARGE_ID:0,
        S_UPDATE_CHARGE_FILTER:false,
        S_ISVALID_PASSWORD: false
    },
    
    mutations: {
        M_UPDATE_TABLE(state, payload){
            state.S_UPDATE_TABLE = payload
        },
        M_UPDATE_CHARGE_TABLE(state, payload){
            state.S_UPDATE_CHARGE_TABLE = payload
        },
        M_UPDATE_DATAGLOBAL(state, payload){
            state.S_DATAGLOBAL = payload
        },
        M_UPDATE_ENTRANCES_TYPE_ID(state, payload){
            state.S_ENTRANCES_TYPE_ID = payload
        },
        M_UPDATE_ENTRANCES_CHARGE_ID(state, payload){
            state.S_ENTRANCES_CHARGE_ID = payload
        },
        M_UPDATE_CHARGE_FILTER(state, payload){
            state.S_UPDATE_CHARGE_FILTER = payload
        },
        M_UPDATE_VALID_PASSWORD(state, payload) {
            state.S_ISVALID_PASSWORD = payload
        }
    },
    
    actions: {
        A_UPDATE_TABLE({commit}, params) {
            commit('M_UPDATE_TABLE', params)
        },
        A_UPDATE_CHARGE_TABLE({commit}, params) {
            commit('M_UPDATE_CHARGE_TABLE', params)
        },
        A_UPDATE_DATAGLOBAL({commit}, params) {
            commit('M_UPDATE_DATAGLOBAL', params)
        },
        A_UPDATE_ENTRANCES_TYPE_ID({commit}, params) {
            commit('M_UPDATE_ENTRANCES_TYPE_ID', params)
        },
        A_UPDATE_ENTRANCES_CHARGE_ID({commit}, params) {
            commit('M_UPDATE_ENTRANCES_CHARGE_ID', params)
        },
        A_UPDATE_CHARGE_FILTER({commit}, params) {
            commit('M_UPDATE_CHARGE_FILTER', params)
        },
        A_UPDATE_VALID_PASSWORD({commit}, params) {
            commit('M_UPDATE_VALID_PASSWORD', params)
        }
    },
    
    getters: {
        G_UPDATE_TABLE(state){
            return state.S_UPDATE_TABLE
        },
        G_DATAGLOBAL(state){
            return state.S_DATAGLOBAL
        },
        G_ENTRANCES_TYPE_ID(state){
            return state.S_ENTRANCES_TYPE_ID
        },
        G_UPDATE_CHARGE_TABLE(state){
            return state.S_UPDATE_CHARGE_TABLE
        },
        G_CHARGE_ID(state){
            return state.S_ENTRANCES_CHARGE_ID
        },
        G_UPDATE_CHARGE_FILTER(state){
            return state.S_UPDATE_CHARGE_FILTER
        },
        G_ISVALID_PASSWORD(state){
            return state.S_ISVALID_PASSWORD
        }
    }
}