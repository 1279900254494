export default [
    {
        path: "product-request",
        name: "product-request-logistic",
        component: () => import(/* webpackChunkName: "LogisticProductRequest" */ "@/views/commons/components/product-request/ProductRequest.vue"),
        meta: {
            pageTitle: "Product Request",
            breadcrumb: [
                {
                    text: "Product Request",
                    active: true,
                },
            ],
        },
    },
    {
        path: "create-product-request",
        name: "create-request-product-logistic",
        component: () => import(/* webpackChunkName: "LogisticProductCreate" */ "@/views/commons/components/product-request/components/modals/NewProductRequest.vue"),
        meta: {
            pageTitle: "Create Product Request",
            breadcrumb: [
                {
                    text: "Create Product Request",
                    active: true,
                },
            ],
        },
    }
];