/* <template>
  <b-modal
    v-model="showModal"
    title="Add Charge"
    @hide="closeModal"
    title-tag="h3"
    size="lg"
    no-close-on-backdrop
    centered
    header-class="p-0"
    modal-class="custom-modal-amg"
    header-bg-variant="transparent"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">Add Charge</h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <template #default>
      <b-container>
        <template>
          <b-row class="my-1">
            <b-col>
              <div
                class="info-container"
                :class="isDarkSkin ? 'text-white' : 'text-dark'"
              >
                <p class="m-0" style="gap: 5px">
                  Client | {{ data.client_name }}
                </p>
              </div>
            </b-col>
            <b-col>
              <div
                class="info-container"
                :class="isDarkSkin ? 'text-white' : 'text-dark'"
              >
                <p class="m-0" style="gap: 5px">
                  Account | {{ data.client_account }}
                </p>
              </div>
            </b-col>
          </b-row>
        </template>
        <template>
          <ValidationObserver ref="form">
            <b-row>
              <b-col :cols="8">
                <b-form-group label="Concept">
                  <b-form-input v-model="data.type_description" disabled />
                </b-form-group>
              </b-col>
              <b-col :cols="4">
                <b-form-group label="Amount">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="fee"
                    :rules="{
                      required: true,
                      min_value: data.minimum_price,
                      'money-2': true,
                    }"
                  >
                    <money
                      v-bind="vMoney"
                      v-model="suggest_charge"
                      class="form-control"
                      :class="{ 'border-danger': errors[0] && validateMoney }"
                    />
                    <span v-if="errors[0]" class="text-danger">
                      Minimum amount: ${{
                        parseFloat(data.minimum_price).toFixed(2)
                      }}
                    </span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </ValidationObserver>
        </template>

        <add-charge-others-form
          ref="addChargeOthersForm"
          :data="data"
          :amount="parseFloat(suggest_charge)"
          :amountPartial="amountPartial"
          :hideOtherPayments="hideOtherPayments"
          @refresh="refresh"
          @refreshTable="refreshTable"
          @changeOptionSelected="changeOptionSelected"
        />
      </b-container>
    </template>

    <template #modal-footer>
      <b-button variant="success" @click="sendData"> Add Charge </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import VMoney from "v-money";
import moment from "moment";
import ModalCardCreate from "@/views/crm/views/payments/components/ModalCardCreate.vue";
import ApplicationsService from "@/views/commons/components/applications/services/applications.service.js";
import OtherServicesService from "@/views/commons/components/other-services/service/appointment.service.js";

import DeleteCard from "@/views/crm/views/sales-made/components/modals/DeleteCardModal.vue";
import OtherPaymentOptions from "@/views/commons/components/clients/dashboard/options/pay-module/modals/OtherPaymentOptions.vue";
import addChargeOthersForm from "@/views/commons/components/charge-form/AddChargeForm.vue";

export default {
  props: {
    data: { required: true, type: Object },
    action: { required: true, type: Number },
    allPaymentMethods: { type: Boolean, default: () => true },
    sendFrom: { type: String },
    hideOtherPayments: { type: Boolean, default: false },
    extraData: { type: Object, default: () => ({}) },
    typeProcess: { type: Number, default: 0 },
  },
  components: {
    OtherPaymentOptions,
    ModalCardCreate,
    DeleteCard,
    addChargeOthersForm,
    VMoney,
  },

  data() {
    return {
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
        maxlength: 11,
      },
      accountData: [],
      showModal: false,
      client_name: "",
      account: "",
      suggest_charge: 0,
      typePaymentSelected: null,
      paymentSelected: 1,
      optionsTypePayment: [
        { text: "Credit Card", value: 1, disabled: false },
        { text: "Others", value: 2, disabled: false },
      ],
      amountPartial: 0,
      validAmount: false,
      methodPaymentSelected: 1, // direct payment by default
      cardData: [],
      validateMoney: false,
      cardSelected: null,
      indexCardSelected: null,
      serialNumber: "",
      optionsCharges: [],
      date: "",
      hour: "",
      fimage: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    hasCardSelected() {
      return this.cardSelected ? true : false;
    },
    amountIsGreaterThanZero() {
      return this.suggest_charge > 0;
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    optionsTypePaymentData() {
      const dataPayments = this.optionsTypePayment.map((item) => ({
        ...item,
        disabled: item.value == 2 ? !this.allPaymentMethods : false,
      }));
      return dataPayments.filter((e) => !e.disabled);
    },
    amountIsGreaterThanZero() {
      return this.suggest_charge > 0;
    },
  },
  mounted() {
    this.account = this.data.account;
    this.client_name = this.data.name_client;
  },
  async created() {
    this.option = this.action;
    this.openModal();
    await this.start();
  },

  methods: {
    ...mapActions({
      updateEfectivity: "ReportStore/A_REFRESH_EFECTIVITY",
      updateRefreshReport: "ReportStore/A_REFRESH_REPORT",
    }),
    assignIndexSelected(value) {
      this.indexCardSelected = value;
    },
    assignCardData(arr) {
      this.cardData != [] ? (this.cardData = []) : "";
      arr.map((el) => {
        el.cardnumber ? this.cardData.push(el) : "";
      });
    },

    async start() {
      this.suggest_charge = this.data.minimum_price ?? 0;
      this.chargeSelected = this.data.type_id;
      let accountInfo = await ApplicationsService.searchAccount({
        id: this.data.account_id,
      });
      this.accountData = { ...accountInfo.data[0] };
      let cardInfo = ApplicationsService.searchCardsClient({
        id: this.accountData.lead_id,
      });
      let typesChargesInfo = ApplicationsService.getTypesChargesByClient({
        idClient: this.data.ca_id,
        modul: this.moduleId,
      });
      let types = ApplicationsService.getTypesCharges();

      let result = await Promise.all([cardInfo, typesChargesInfo, types]);

      this.assignCardData(result[0].data);
      this.typesChargesData = [...result[1].data];

      result[2].data.map((el) => {
        this.optionsCharges.push({
          id: el.id,
          charge: el.description,
        });
      });
    },

    async sendData() {
      this.validateMoney = true;
      const confirm = await this.$refs.form.validate();
      if (!confirm) return;

      if (this.paymentSelected == 1) {
        //
        const dataCharge = await this.$refs.addChargeOthersForm.savePayment();
        if (!dataCharge) return;

        const params = {
          id: this.data.account_id ?? this.data.ca_id,
          type_payment: this.paymentSelected,
          account: this.data.account,
          concept: this.data.type_id ?? this.data.type,
          ammount: this.suggest_charge,
          card_id: dataCharge.card_id,
          type: 0,
          method_payment: this.methodPaymentSelected,
          ammount_partial:
            this.methodPaymentSelected == 2
              ? this.amountPartial
              : this.suggest_charge,
          specify: this.serialNumber,
          user_id: this.currentUser.user_id,
          notcharges: this.chargeSelected == 5 ? "" : this.data.full_charge,
          programid: this.currentUser.program_id,
          type_process: this.typeProcess,
          idapp: this.data.id ?? this.data.app_id,
          settlementcost: null,
          idcreditor: null,
          other_services: true,
          sent_from: this.sendFrom,
          nameCharge: this.data.charge_description,
          extra_data: this.extraData,
        };
        try {
          this.addPreloader();
          const { data } = await ApplicationsService.saveCharge(params);
          if (data.status == "success") {
            this.showSuccessSwal();
            this.$emit("refreshTable");
            this.$emit("close");
          } else {
            if (data.transaction) {
              this.showErrorSwal("error");
            } else {
              this.showErrorSwal("Process failed");
            }
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      } else {
        const dataOthers =
          await this.$refs.addChargeOthersForm.saveOtherPayment();
        if (!dataOthers) return;

        let datetime = null;
        if (this.date != "" && this.hour != "") {
          datetime = moment(
            `${this.date} ${this.hour}`,
            "YYYY-MM-DD HH:mm:ss"
          ).format("YYYY-MM-DD HH:mm:ss");
        }
        const params = {
          client_account_id: this.data.account_id,
          lead_id: null,
          payment_type: 6, //segun otherpaymentoptions.vue
          payment_method: dataOthers.method_selected,
          payment_modality: 4, //segun otherpaymentoptions.vue
          settlement_date: datetime,
          amount: this.suggest_charge,
          program_id: this.currentUser.program_id,
          module_id: this.moduleId,
          created_by: this.currentUser.user_id,
          sale_id: null,
          charge_id: this.data.type_id,
          charge_description: null,
          paymentFinantial: null,
          sent_from: this.sendFrom,
          id_app: this.sendFrom == "app_main" ? null : this.data.id,
          id_app_char: this.sendFrom == "app_main" ? this.data.id : null,
          isClient: true,
          image: dataOthers.file,
        };

        try {
          this.addPreloader();
          const data = await OtherServicesService.saveOtherPayment(params);
          if (data) {
            this.showSuccessSwal();
            this.$emit("refreshTable");
          } else {
            this.showErrorSwal("Process failed");
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
      this.updateEfectivity(true);
      this.$store.dispatch("DebtSolutionClients/A_GET_CLIENTS", {
        id: this.$route.params.idClient,
      });
    },
    refreshTable() {
      this.$emit("refreshTable");
    },
    changeOptionSelected(value) {
      this.paymentSelected = value;
    },
    refresh() {
      this.$refs.refCards.refresh();
    },
    close(visible = false) {
      this.$emit("close");
      this.$emit("changePaidOption", null);
      this.visible = visible;
    },
    openModalCardCreate() {
      this.isActiveModalCreateCard = true;
    },
    closeModalCardCreate(visible = false) {
      this.isActiveModalCreateCard = visible;
    },
    ssignCardData(arr) {
      this.cardData != [] ? (this.cardData = []) : "";
      arr.map((el) => {
        el.cardnumber ? this.cardData.push(el) : "";
      });
    },
    refresCardTable(data) {
      this.assignCardData(data);
    },
    openModalDeleteCard(cardId) {
      this.cardSelected = cardId;
      this.isActiveModalDeleteCard = true;
    },
    closeModalDeleteCard(visible = false) {
      this.isActiveModalDeleteCard = visible;
    },
    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.py-1s {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>

 */
