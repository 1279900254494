export default {
  path: "folders",
  name: "file-mananger-claimdepartment",
  component: () => import(/* webpackChunkName: "ClaimDepartamentFolders" */ "@/views/crm/views/file-mananger/FileMananger.vue"),
  meta: {
    pageTitle: "File Manager",
    breadcrumb: [
      {
        text: "File Manager",
        active: true,
      },
    ],
  },
};
