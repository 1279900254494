import Vue from "vue"

Vue.filter("myMoney", (num) => {
    return num ? `$ ${num}` : '$ 0.00'
})

Vue.filter('addTwoDecimals', (num) => {
    return num ? `$ ${parseFloat(num).toFixed(2)}` : '$ 0.00'
})

Vue.filter("formatMoney", (num) => {
    if(typeof num === 'string'){
        num = Number(num.replace(/,/g, ''));
    }
     const formater = new Intl.NumberFormat("en-US", {
         currency: "USD",
         style: "currency",
     }).format(num);
    return formater;
})