<template>
  <div>
    <b-modal
      ref="modal-employee-activity-records"
      no-close-on-backdrop
      centered
      hide-footer
      size="lg"
      :title="title"
      @hidden="$emit('hidden')"
    >
      <b-container class="bv-example-row">
        <b-row class="my-1">
          <b-col class="text-center">
            <b-avatar
              v-b-tooltip.hover.top="employee.status_session_name"
              size="40"
              :src="employee.image"
              variant="light-primary"
              badge
              class="badge-minimal"
              :badge-variant="employee.badge"
            >
              <feather-icon
                v-if="!employee.image"
                icon="UserIcon"
                size="19"
              />
            </b-avatar>
            <div
              class="position-relative text-center mt-1"
            >
              <h2>
                <strong>
                  {{ employee.user_name }}
                </strong>
              </h2>
            </div>
          </b-col>
        </b-row>

        <b-row
          v-if="statusSelected == null"
          class="text-center"
        >
          <b-col>
            <b-button
              size="sm"
              variant="primary"
              pill
              @click="previusDay()"
            >
              <strong>
                <feather-icon
                  icon="SkipBackIcon"
                  size="13"
                />
              </strong>
            </b-button>
          </b-col>
          <b-col cols="6">
            <kendo-datepicker
              id="datepicker"
              v-model="dateSelected"
              v-mask="'##/##/####'"
              class="mb-1"
              :first-day-of-week="1"
              placeholder="MM/DD/YYYY"
              :format="'MM/dd/yyyy'"
              :max="todayDate"
            />
          </b-col>
          <b-col>
            <b-button
              size="sm"
              variant="primary"
              pill
              :disabled="dateSelected === todayDateString || dateSelected === todayDate"
              @click="nextDay()"
            >
              <strong>
                <feather-icon
                  icon="SkipForwardIcon"
                  size="13"
                />
              </strong>
            </b-button>
          </b-col>
        </b-row>

        <b-row
          v-if="statusSelected != null"
          class="text-center mb-1"
        >
          <b-col>
            <h3 class="text-warning">
              <strong>
                RECORDS OF '{{ statusSelected.type_status }}' STATUS
              </strong>
            </h3>
            <h4>
              <strong v-if="dateSelected === todayDateString || dateSelected === todayDate">
                TODAY
              </strong>
              <strong v-else>
                {{ formatDate }}
              </strong>
            </h4>
          </b-col>
        </b-row>

        <b-row
          v-if="statusSelected == null"
          class="px-3 pb-3 text-center"
        >
          <b-col>
            <b-table
              fixed
              hover
              :fields="fieldsCounters"
              :items="statussesCounters"
              :busy="isBusy"
            >
              <template #cell(type_status)="data">
                <b-badge
                  pill
                  :variant="badgeColor(data.item)"
                >
                  {{ data.item.type_status }}
                </b-badge>
              </template>
              <template #cell(total_time)="data">
                <span>
                  <feather-icon
                    icon="WatchIcon"
                    size="13"
                    class="mr-1"
                  />
                  <strong v-if="data.item.started_hour && data.item.total_time == 0">{{ timeDifference }}</strong>
                  <strong v-else>{{ data.item.total_time }}</strong>
                </span>
              </template>
              <template #cell(actions)="data">
                <span v-if="data.item.total_minutes > 0">
                  <strong class="text-info">
                    <b-button
                      variant="outline-info"
                      size="sm"
                      @click="selectStatus(data.item)"
                    >
                      <strong>
                        Details
                      </strong>
                    </b-button>
                  </strong>
                </span>
                <small
                  v-else
                  v-b-tooltip.hover.top="'User is currently in this status'"
                  class="ml-2 text-info"
                >
                  <strong>
                    CURRENT STATUS
                  </strong>
                </small>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle" />
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row
          v-if="statusSelected != null"
          class="px-3 pb-3 text-center"
        >
          <b-col>
            <b-table
              sticky-header
              :fields="fieldsActivity"
              :items="statusses"
              :busy="isBusy"
            >
              <template #cell(module_name)="data">
                <strong>
                  {{ data.item.module_name }}
                </strong>
              </template>
              <template #cell(status)="data">
                <b-badge
                  pill
                  :variant="badgeColor(data.item)"
                >
                  {{ data.item.status }}
                </b-badge>
              </template>
              <template #cell(started_hour)="data">
                <span class="text-primary">
                  <feather-icon
                    icon="WatchIcon"
                    size="13"
                    class="mr-1"
                  />
                  {{ data.item.started_hour }}
                </span>
              </template>
              <template #cell(finished_hour)="data">
                <span
                  v-if="data.item.finished_hour"
                  class="text-danger"
                >
                  <feather-icon
                    icon="WatchIcon"
                    size="13"
                    class="mr-1"
                  />
                  {{ data.item.finished_hour }}
                </span>
                <span
                  v-else
                  class="ml-2 text-info"
                >
                  NO TIME
                </span>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle" />
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row
          v-if="noData && isBusy == false"
          class="text-center  px-3 pb-3"
        >
          <b-col
            cols="12"
            class="text-center"
          >
            <strong
              v-b-tooltip.hover.top="'Employee hasn\'t any records for today yet'"
              class="text-warning"
            >
              <feather-icon
                icon="AlertCircleIcon"
                size="40"
                class="mb-1"
              />
              <br>
              NO AVIALABLE DATA
            </strong>
          </b-col>
        </b-row>

        <b-row
          v-if="statusSelected != null"
          class="text-center mb-1"
        >
          <b-col>
            <b-button
              variant="primary"
              @click="statusSelected = null"
            >
              <strong>
                Return
              </strong>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import CustomerTicketsService from '@/views/commons/components/customer-tickets/services/customer-tickets.service';
import moment from 'moment';

export default {
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeDifference: null,
      reactiveHour: null,
      isBusy: false,
      statusSelected: null,
      statusses: [],
      statussesCounters: [],
      dateSelected: null,
      fieldsCounters: [
        { key: 'type_status', sortable: false },
        { key: 'total_time', sortable: false },
        { key: 'actions', sortable: false },
      ],
      fieldsActivity: [
        { key: 'started_hour', sortable: false },
        { key: 'finished_hour', sortable: false },
      ],
    };
  },
  computed: {
    title() {
      const concatTitle = `Activity records of : ${this.employee.user_name}`;
      return concatTitle;
    },
    todayDate() {
      return moment().toDate();
    },
    todayDateString() {
      return moment().format('MM/DD/YYYY');
    },
    noData() {
      if ((this.statussesCounters.length === 0 && this.statusSelected == null)) {
        return true;
      }
      return false;
    },
    formatDate() {
      return moment(this.dateSelected).format('MM/DD/YYYY');
    },
  },
  watch: {
    dateSelected() {
      this.getStatussesCountersPerUser();
    },
  },
  mounted() {
    this.dateSelected = this.todayDate;
    this.toggleModal('modal-employee-activity-records');
  },
  beforeDestroy() {
    if (this.reactiveHour) this.stopTimer();
  },
  methods: {
    async listStatussesPerUser() {
      try {
        this.isBusy = true;
        const dateSelectedtoSend = moment(this.dateSelected, 'MM/DD/YYYY').format('YYYY/MM/DD');
        const obj = {
          created_by: this.employee.id_user,
          date_selected: dateSelectedtoSend,
          status_id: this.statusSelected.id,
        };
        const res = await CustomerTicketsService.listStatussesPerUser(obj);
        if (res.status === 200) {
          this.statusses = res.data;
          this.isBusy = false;
        }
      } catch (error) {
        console.log(error);
        this.isBusy = false;
      }
    },
    async getStatussesCountersPerUser() {
      try {
        this.isBusy = true;
        const dateSelectedtoSend = moment(this.dateSelected, 'MM/DD/YYYY').format('YYYY/MM/DD');
        const obj = {
          created_by: this.employee.id_user,
          date_selected: dateSelectedtoSend,
          status_id: null,
        };
        const res = await CustomerTicketsService.getStatussesCountersPerUser(obj);
        if (res.status === 200) {
          this.statussesCounters = await res.data.filter(element => element.total_minutes !== 0 || element.started_hour);
          const firstElementWithStartedHour = this.statussesCounters.find(element => element.started_hour);
          if (firstElementWithStartedHour) {
            this.reactiveHour = firstElementWithStartedHour.started_hour;
            this.startTimer();
          }
          this.isBusy = false;
        }
      } catch (error) {
        console.log(error);
        this.isBusy = false;
      }
    },
    nextDay() {
      this.dateSelected = moment(this.dateSelected).add(1, 'day').format('MM/DD/YYYY');
    },
    previusDay() {
      this.dateSelected = moment(this.dateSelected).subtract(1, 'day').format('MM/DD/YYYY');
    },
    badgeColor(item) {
      let tableStyle = 'secondary ';
      if (item.id === 1) tableStyle = 'success';
      if (item.id === 2) tableStyle = 'warning';
      if (item.id === 3) tableStyle = 'danger';
      if (item.id === 5) tableStyle = 'info';
      if (item.id === 7) tableStyle = 'info';
      if (item.id === 8) tableStyle = 'primary';
      return tableStyle;
    },
    selectStatus(status) {
      this.statusSelected = status;
      this.listStatussesPerUser();
    },
    startTimer() {
      this.timer = setInterval(() => {
        const startTime = this.createDateFromTime(this.reactiveHour);
        const currentTime = new Date();

        const timeDiffInMilliseconds = Math.abs(currentTime - startTime);
        const hours = Math.floor(timeDiffInMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

        this.timeDifference = hours > 0 ? `${hours} hours and ${minutes} minutes` : `${minutes} minutes`;
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    createDateFromTime(time) {
      const currentDate = new Date();
      const [hours, minutes, seconds] = time.split(':');
      currentDate.setHours(hours);
      currentDate.setMinutes(minutes);
      currentDate.setSeconds(seconds);
      return currentDate;
    },
  },
};
</script>
