<template>
  <div>
    <b-modal
      v-model="ownControl"
      modal
      centered
      title="CHANGE MONTHLY PAYMENT"
      size="lg"
      modal-class="custom-modal-amg"
      title-tag="h3"
      title-class="text-white font-weight-bolder"
      :no-close-on-backdrop="true"
      header-bg-variant="transparent"
      header-class="p-0"
      @hidden="closeModalMonthlyPayment"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>CHANGE MONTHLY PAYMENT</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModalMonthlyPayment"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <program-client-header
        :client="
          client.client_name ? client.client_name : clientAccount.client_name
        "
        :program="
          client.program_name ? client.program_name : clientAccount.program_name
        "
        no-title
      />
      <b-row
        class="border rounded p-1 m-0"
        :style="
          isDarkSkin
            ? { backgroundColor: '#2e2e2e' }
            : { backgroundColor: '#f8f9fa' }
        "
      >
        <b-col
          offset="2"
          cols="4"
          class="d-flex align-items-center justify-content-center gap-10"
          style="border-right: 1px solid #6c757d"
        >
          <span style="font-size: 16px">Fee</span>
          <div style="padding: 10px" class="border rounded">
            $ {{ fee | currency }}
          </div>
        </b-col>
        <b-col
          cols="4"
          class="d-flex align-items-center justify-content-center gap-10"
        >
          <span style="font-size: 16px">Balance</span>
          <div style="padding: 10px" class="border rounded">
            $ {{ balance | currency }}
          </div>
        </b-col>
      </b-row>
      <ValidationObserver ref="form">
        <div class="d-flex gap-15 mt-1 flex-wrap">
          <div class="d-flex align-items-center gap-10">
            <b-form-group label-for="type-payment">
              <template #label>
                <span style="font-size: 15px">Payment </span>
              </template>
              <div class="d-flex align-items-center justify-content-between">
                <b-form-radio-group
                  id="type-payment-radio-box"
                  v-model="typePayment"
                  @input="variantPayment"
                  :options="[
                    { text: 'Single', value: 0 },
                    { text: 'Multiple', value: 1 },
                  ]"
                  :disabled="client.program_id == 2"
                  class="p-1 border rounded"
                />
              </div>
            </b-form-group>
          </div>
          <div v-if="typePayment == 0">
            <b-form-group label-for="monthly-payment">
              <template #label>
                <span style="font-size: 15px">Amount</span>
              </template>
              <validation-provider
                v-slot="{ errors }"
                name="monthlyAmount"
                rules="required|validate-amount"
              >
                <div
                  class="d-flex align-items-center justify-content-between px-1 border rounded"
                  style="height: 48px !important; width: 165px !important"
                >
                  <span> $</span>
                  <money
                    v-model="monthly_amount"
                    class="form-control border-0 text-right"
                    style="font-size: 15px !important"
                    v-bind="{ precision: 2 }"
                    :class="{
                      'border-danger': errors[0],
                    }"
                    @change.native="monthlyPaymentController = true"
                  />
                </div>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="d-flex align-items-center gap-10">
            <b-form-group label-for="method-of-payment">
              <template #label>
                <span style="font-size: 15px">Method</span>
              </template>
              <div class="d-flex align-items-center justify-content-between">
                <b-form-radio-group
                  id="credit-card-radio-box"
                  v-model="method_payment"
                  :options="[
                    { text: 'Credit Card', value: 0 },
                    { text: 'Others', value: 1 },
                  ]"
                  :disabled="typePayment == 1"
                  class="p-1 border rounded"
                  :class="method_payment == null ? 'border-danger' : ''"
                />
              </div>
            </b-form-group>
          </div>

          <div
            v-if="method_payment == 0"
            class="d-flex align-items-center gap-10"
          >
            <b-form-group label-for="type">
              <template #label>
                <span style="font-size: 15px">Type</span>
              </template>
              <div class="d-flex align-items-center justify-content-between">
                <b-form-radio-group
                  id="type-card-radio-box"
                  v-model="cardType"
                  :options="[
                    { text: 'Automatic', value: 0 },
                    { text: 'Manual', value: 1 },
                  ]"
                  class="p-1 border rounded"
                  :disabled="typePayment == 1"
                  :class="cardType == null ? 'border-danger rounded' : ''"
                />
              </div>
            </b-form-group>
          </div>
          <div
            v-if="cardType == 0 && typePayment == 1"
            class="d-flex align-items-center gap-10"
          >
            <b-form-group label-for="type">
              <template #label>
                <span style="font-size: 15px">Quantity</span>
              </template>
              <div class="d-flex align-items-center justify-content-between">
                <b-form-select
                  id="select-date-contract-fee"
                  v-model="quantity"
                  @change="addItemToPaymentSchedule()"
                  style="
                    height: 48px !important;
                    width: 65px !important;
                    font-size: 14px;
                  "
                  size="sm"
                >
                  <b-form-select-option value="2"> 2 </b-form-select-option>
                  <b-form-select-option value="3"> 3 </b-form-select-option>
                </b-form-select>
              </div>
            </b-form-group>
          </div>
        </div>
        <div
          class="d-flex gap-15 my-1 flex-wrap"
          v-if="cardType == 0 && method_payment == 0 && !typePayment"
        >
          <div class="d-flex align-items-center gap-10">
            <b-form-group label-for="method-of-payment">
              <template #label>
                <span style="font-size: 15px">Start Date</span>
              </template>
              <div
                class="d-flex align-items-center justify-content-between gap-5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="dayCfee"
                  rules="required"
                >
                  <b-form-select
                    id="select-date-contract-fee"
                    v-model="dayCFee"
                    :options="daysOfPayment"
                    text-field="day"
                    :class="{ 'border-danger rounded': errors[0] }"
                    value-field="day"
                    style="
                      height: 36px !important;
                      width: 65px !important;
                      font-size: 14px;
                    "
                    size="sm"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="monthCfee"
                  style="margin-right: 2px; margin-left: 2px"
                  rules="required"
                >
                  <b-form-select
                    v-model="monthCFee"
                    size="sm"
                    :class="{ 'border-danger rounded': errors[0] }"
                    style="
                      height: 36px !important;
                      width: 75px !important;
                      font-size: 14px;
                    "
                  >
                    <b-form-select-option value="1"> Jan </b-form-select-option>
                    <b-form-select-option value="2"> Feb </b-form-select-option>
                    <b-form-select-option value="3"> Mar </b-form-select-option>
                    <b-form-select-option value="4"> Apr </b-form-select-option>
                    <b-form-select-option value="5"> May </b-form-select-option>
                    <b-form-select-option value="6"> Jun </b-form-select-option>
                    <b-form-select-option value="7"> Jul </b-form-select-option>
                    <b-form-select-option value="8"> Aug </b-form-select-option>
                    <b-form-select-option value="9"> Sep </b-form-select-option>
                    <b-form-select-option value="10">
                      Oct
                    </b-form-select-option>
                    <b-form-select-option value="11">
                      Nov
                    </b-form-select-option>
                    <b-form-select-option value="12">
                      Dec
                    </b-form-select-option>
                  </b-form-select>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="yearCfee"
                  rules="required"
                >
                  <b-form-select
                    v-model="yearCFee"
                    :options="years"
                    style="
                      height: 36px !important;
                      width: 75px !important;
                      font-size: 14px;
                    "
                    size="sm"
                    :class="{ 'border-danger rounded': errors[0] }"
                  />
                </validation-provider>
              </div>
            </b-form-group>
          </div>
        </div>
      </ValidationObserver>

      <b-row
        v-if="cardType == 0 && method_payment == 0 && !typePayment"
        class="mt-1"
      >
        <b-col>
          <b-row>
            <b-col>
              <b-table
                :items="cards"
                :fields="fieldsT1"
                size="sm"
                class="position-relative table-new-customization"
                table-class="wo-padding"
                sticky-header="30vh"
                show-empty
                responsive
              >
                <template v-slot:cell(select)="data">
                  <b-form-radio
                    id="select-card-radio-box"
                    v-model="cardId"
                    :value="data.item.id"
                    plain
                  />
                </template>
                <template v-slot:cell(type_card)="data">
                  <type-card :type_card="data.item.type_card" />
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div class="table-new-customization overflow-y-visible">
        <b-table-simple
          class="position-relative mb-2"
          style="
            padding-left: 0px !important;
            padding-right: 0px !important;
            table-layout: fixed;
            width: 100% !important;
          "
          v-if="cardType == 0 && method_payment == 0 && typePayment"
        >
          <b-thead>
            <b-tr>
              <b-th
                class="text-center"
                v-for="(field, index) in fields"
                :key="index"
                :style="`width: ${field.width}`"
              >
                {{ field.label }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="paymentSchedule.length > 0">
              <b-tr v-for="(item, index) in paymentSchedule" :key="index">
                <b-td>
                  <b-form-radio-group
                    :id="`current-payment-radio-box-${index}`"
                    v-model="item.current"
                    :options="[
                      { text: 'Current', value: 1 },
                      { text: 'Next', value: 0 },
                    ]"
                    class="p-1 border rounded"
                  />
                  <div
                    class="text-center text-warning"
                    style="margin-top: 5px; font-style: italic"
                  >
                    {{
                      $moment(
                        new Date().getMonth() + 1 + (item.current == 1 ? 0 : 1),
                        "MM"
                      ).format("MMM")
                    }}
                  </div>
                </b-td>
                <b-td>
                  <v-select
                    v-model="item.date"
                    :options="daysOfPayment"
                    :reduce="(option) => option.day"
                    label="day"
                    :clearable="false"
                    :selectable="daysSelectable(item.current)"
                    class="w-100 text-center"
                    :class="!item.date ? 'border-danger rounded' : ''"
                  />
                </b-td>
                <b-td>
                  <v-select
                    v-model="item.ocurrences"
                    :options="[
                      { text: 'Automatic', value: 9999 },
                      { text: '1', value: 1 },
                      { text: '2', value: 2 },
                      { text: '3', value: 3 },
                      { text: '4', value: 4 },
                      { text: '5', value: 5 },
                      { text: '6', value: 6 },
                      { text: '7', value: 7 },
                      { text: '8', value: 8 },
                      { text: '9', value: 9 },
                      { text: '10', value: 10 },
                    ]"
                    :clearable="false"
                    label="text"
                    class="w-100 text-center"
                    :reduce="(option) => option.value"
                  />
                </b-td>
                <b-td class="text-center">
                  <div
                    class="d-flex align-items-center justify-content-between rounded pl-1"
                    :class="
                      item.amount <= 0 ? 'border-danger' : 'border-success'
                    "
                  >
                    <span> $</span>
                    <money
                      v-model="item.amount"
                      v-bind="moneyConfig"
                      class="form-control border-0 text-right px-2"
                    />
                  </div>
                </b-td>
                <b-td class="text-center">
                  <v-select
                    :class="!item.card_id ? 'border-danger rounded' : ''"
                    label="cardholdername"
                    v-model="item.card_id"
                    :reduce="(card) => card.id"
                    :options="cards"
                    :selectable="isCardSelectable"
                    :clearable="false"
                    class="w-100"
                  >
                    <template
                      #option="{
                        cardholdername,
                        cardnumber,
                        card_expi_month,
                        card_expi_year,
                      }"
                    >
                      <b-row
                        class="d-flex align-items-center justify-content-between"
                      >
                        <b-col cols="6">
                          <span>Card:</span>
                        </b-col>
                        <b-col cols="6">
                          <span>{{ cardHolderName(cardholdername) }}</span>
                        </b-col>
                        <b-col cols="6">
                          <span>Number:</span>
                        </b-col>
                        <b-col cols="6">
                          <span>{{ "XXXX-" + cardnumber }}</span>
                        </b-col>
                        <b-col cols="6">
                          <span>Exp. Date:</span>
                        </b-col>
                        <b-col cols="6">
                          <span>{{
                            card_expi_month + "/" + card_expi_year
                          }}</span>
                        </b-col>
                      </b-row>
                    </template>

                    <template #selected-option="{ cardholdername, cardnumber }">
                      <div class="text-center">
                        <div
                          style="height: 26.88px !important"
                          class="d-flex align-items-center justify-content-between gap-10"
                        >
                          <div>
                            {{ cardHolderName(cardholdername) }}
                          </div>
                          |
                          <div>
                            {{ cardnumber }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-select>
                </b-td>
              </b-tr>
            </template>
            <b-tr class="b-table-bottom-row">
              <b-th class="fill-bottom-row" colspan="2" />
              <b-th>
                <b-badge
                  variant="primary"
                  class="w-100 text-center"
                  style="font-size: 14px; font-weight: 600"
                >
                  AMOUNT
                </b-badge>
              </b-th>
              <b-th>
                <b-badge
                  variant="primary"
                  class="w-100 text-center"
                  style="font-size: 14px; font-weight: 600"
                >
                  $ {{ newMonthlyAmount | currency }}
                </b-badge>
              </b-th>
              <b-th class="fill-bottom-row" colspan="1" />
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <template #modal-footer>
        <b-row class="w-100 p-0">
          <b-col
            class="d-flex align-items-center justify-content-start p-0"
            v-if="method_payment == 0 && cardType == 0"
          >
            <b-button
              class="mr-1 btn rounded float-right btn-orange background-ce"
              variant="success"
              size="sm"
              type="button"
              @click="openModalCreateCard"
            >
              ADD CREDIT CARD
            </b-button>
          </b-col>

          <b-col class="d-flex align-items-center justify-content-end p-0">
            <b-button
              class="background-ce"
              v-if="typePayment == 1"
              id="submit-contract-fee"
              size="sm"
              @click="saveUpdate"
              :variant="
                validationSubmitMultiple ? 'outline-custom-amg' : 'custom-amg'
              "
              :disabled="validationSubmitMultiple"
            >
              UPDATE
            </b-button>
            <b-button
              class="background-ce"
              v-else
              id="submit-contract-fee"
              size="sm"
              :variant="validationSubmit ? 'outline-custom-amg' : 'custom-amg'"
              :disabled="validationSubmit"
              @click="saveUpdate"
            >
              UPDATE
            </b-button>
            <!-- <b-button @click="saveUpdate" class="background-ce">
              <feather-icon icon="SaveIcon" class="text-white" size="15" />
              Save
            </b-button> -->
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <!-- Modal Create Card -->
    <modal-card-create
      v-if="modalCreateCard"
      :if-modal-card="modalCreateCard"
      :session="currentUser.user_id"
      :idlead="client.lead_id ? client.lead_id : clientAccount.lead_id"
      @click="closeModalCreateCard"
      @new="addCard"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import ClientDashboardService from "../../services/clients.dashboard.services";
import ModalCreditCard from "../modals/ModalCreditCard.vue";
import ModalCardCreate from "../modals/ModalCardCreate.vue";
import ProgramClientHeader from "@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue";
import TypeCard from "@/views/commons/card/TypeCard.vue";

export default {
  components: {
    vSelect,
    ModalCreditCard,
    ModalCardCreate,
    ProgramClientHeader,
    TypeCard,
  },
  props: {
    clientAccount: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      paymentSchedule: [],
      ownControl: false,
      submitedForm: false,
      modalCreateCard: false,
      rb_id: null,
      moneyConfig: {
        decimal: ",",
        thousands: ".",
        // prefix: "$",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      openModalMonthlyPayment: this.modalMonthlyPayment,
      fee: "",
      balance: "",
      monthly_amount: "",
      method_payment: 1,
      cardType: 1,
      month_payment: "",
      day_payment: "",
      daysOfPayment: [],
      cards: [],
      idAutorize: "",
      card_id: "",
      optionsMethodPay: [
        { value: 0, text: "Credit Card" },
        { value: 1, text: "Others" },
      ],
      optionsMethodType: [
        { value: 0, text: "Automatic" },
        { value: 1, text: "Manual" },
      ],
      startMonth: [
        { value: 1, text: "Jan" },
        { value: 2, text: "Feb" },
        { value: 3, text: "Mar" },
        { value: 4, text: "Apr" },
        { value: 5, text: "May" },
        { value: 6, text: "Jun" },
        { value: 7, text: "Jul" },
        { value: 8, text: "Aug" },
        { value: 9, text: "Sep" },
        { value: 10, text: "Oct" },
        { value: 11, text: "Nov" },
        { value: 12, text: "Dec" },
      ],
      fields: [
        { key: "month", label: "Month", width: "16%" },
        { key: "day", label: "Day", width: "15%" },
        { key: "quantity", label: "Duration", width: "22%" },
        { key: "amount", label: "Amount", width: "20%" },
        { key: "card_id", label: "Credit Card", width: "25%" },
      ],
      removedItems: [],
      typePayment: 0,
      yearCFee: null,
      dayCFee: 5,
      monthCFee: null,
      cardId: null,
      years: [],
      fieldsT1: [
        {
          label: "",
          key: "select",
        },
        {
          label: "Card Holder Name",
          key: "cardholdername",
          thStyle: { minWidth: "174px !important" },
        },
        {
          label: "Card Number",
          key: "cardnumber",
          thStyle: { minWidth: "195px !important" },
          formatter: (value) => `XXXX-XXXX-XXXX-${value}`,
        },
        {
          label: "Type",
          key: "type_card",
        },
        {
          label: "MM",
          key: "card_expi_month",
        },
        {
          label: "YY",
          key: "card_expi_year",
        },
        {
          label: "CVC",
          key: "cardsecuritycode",
          formatter: (value) => `XX${value}`,
        },
      ],
      quantity: 2,
      newMonthlyAmount: 0,
      dataExist: false,
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
    }),
    validationSubmit() {
      let hasCardId =
        !this.cardId && this.method_payment === 0 && this.cardType === 0;
      let hasMonthlyAmount = this.monthly_amount == 0;
      let noMethodPayment = this.method_payment == null;
      let noCreditCard = this.cardType == null && this.method_payment == 0;
      return hasCardId || hasMonthlyAmount || noMethodPayment || noCreditCard;
    },
    validationSubmitMultiple() {
      return !this.validNumber || !this.validDates || !this.validCards;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    validateNotRecurringBilling() {
      // Obtén el año actual
      const currentYear = moment().format("YYYY");
      // Combina el año actual con el mes y el día desde this.month_payment y this.day_payment
      const combinedDate = moment(
        `${currentYear}-${this.month_payment}-${this.day_payment}`,
        "YYYY-MM-DD"
      );

      // Compara si el mes y el día de combinedDate son iguales al mes y día actual
      const isSameMonthAndDay =
        moment(combinedDate).format("MMDD") === moment().format("MMDD");

      return isSameMonthAndDay;
    },
    paymentScheduleSum() {
      // Summing up the amounts in the paymentSchedule array
      let sum = this.paymentSchedule.reduce(
        (acc, item) => acc + parseFloat(item.amount),
        0
      );
      // Rounding the sum to two decimal places
      let roundedSum = sum.toFixed(2);
      return parseFloat(roundedSum);
    },
    validNumber() {
      return this.paymentSchedule.every((item) => item.amount > 0);
    },
    validDates() {
      return this.paymentSchedule.every((item) => item.date !== null);
    },
    validCards() {
      return this.paymentSchedule.every((item) => item.card_id !== null);
    },
    selectedDates() {
      return this.paymentSchedule
        .filter((item) => item.date !== null)
        .map((item) => item.date);
    },
    validateAllPassSevenDays() {
      return this.paymentSchedule.every(
        (item) => !this.mustPassSevenDays(item)
      );
    },
  },

  async created() {
    this.ownControl = true;
    await this.searchPayment();
    await this.paymentDays();
  },

  methods: {
    cardHolderName(cardholdername) {
      if (cardholdername != null) {
        return cardholdername?.length > 8
          ? cardholdername?.substring(0, 8) + "..."
          : cardholdername;
      } else {
        return "N/A";
      }
    },
    getDay(date) {
      return date.split("-")[2];
    },
    variantPayment() {
      this.method_payment = null;
      this.cardType = null;
      this.paymentSchedule = [];
      if (this.typePayment === 1) {
        setTimeout(() => {
          this.method_payment = 0;
          this.cardType = 0;
          this.addItemToPaymentSchedule();
        }, 100);
      }
    },
    daysSelectable(current) {
      return function (option) {
        if (current === 1) {
          return option.day >= new Date().getDate();
        }
        return true;
      };
    },
    mustPassSevenDays(item) {
      if (!item.date) return false;
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear();
      let additionalMonths = item.current == 1 ? 0 : 1;
      let newMonth = currentMonth + additionalMonths;
      if (newMonth > 12) {
        newMonth = 1;
        currentYear = currentYear + 1;
      } else {
        newMonth = newMonth;
      }

      let newDate = `${currentYear}-${
        newMonth < 10 ? "0" + newMonth : newMonth
      }-${item.date < 10 ? "0" + item.date : item.date}`;
      let date = new Date(newDate);
      let currentDate = new Date();
      let diff = date - currentDate;
      let days = Math.floor(diff / (1000 * 60 * 60 * 24));
      return days < 7;
    },
    removeItemFromPaymentSchedule(index, item) {
      this.removedItems.push(item);
      this.paymentSchedule.splice(index, 1);
      this.calculatePaymentSchedule(this.paymentSchedule);
    },
    addItemToPaymentSchedule() {
      if (this.dataExist) {
        this.dataExist = false;
        return;
      }
      this.newMonthlyAmount = 0;
      this.paymentSchedule = [];
      for (let i = 0; i < this.quantity; i++) {
        this.paymentSchedule.push({
          date: null,
          card_id: null,
          current: 0,
          month: new Date().getMonth() + 1,
          amount: 0,
          ocurrences: 9999,
        });
      }
    },

    calculatePaymentSchedule(existingData = []) {
      if (existingData.length > 0) {
        setTimeout(() => {
          this.paymentSchedule = existingData.map((item, index) => ({
            date: item.date,
            card_id: item.card_id,
            amount: item.amount,
            month: item.month,
            year: item.year,
            current: item.current,
            ocurrences: item.ocurrences,
            customer_profile_id: this.client.account,
          }));
        }, 100);
      }
    },
    openModalCreateCard() {
      this.modalCreateCard = true;
    },
    isCardSelectable(item) {
      let expMonth = item.card_expi_month;
      let expYear = item.card_expi_year;
      let currentDate = new Date();
      let currentMonth = currentDate.getMonth() + 1;
      let currentYear = currentDate.getFullYear();
      currentYear = currentYear.toString().substring(2, 4);
      if (currentYear > expYear) {
        return false;
      } else if (currentYear == expYear && currentMonth > expMonth) {
        return false;
      }
      return true;
    },
    closeModalCreateCard() {
      this.modalCreateCard = false;
    },
    addCard(cards) {
      this.cards = cards;
    },
    getCardId(Card) {
      this.card_id = Card;
    },
    closeModalMonthlyPayment() {
      this.$emit("close");
    },
    async paymentDays() {
      try {
        const response = await ClientDashboardService.paymentDays();
        this.daysOfPayment = response.data;
        const add = parseInt(this.$moment().format("MM"), 10) === 12 ? 1 : 0;
        this.years = this.range(2020, new Date().getFullYear() + add);
        let monthNow = new Date().getMonth() + 1;
        let yearNow = new Date().getFullYear();
        this.monthCFee = monthNow + 1;
        if (this.monthCFee > 12) {
          this.monthCFee = 1;
          this.yearCFee = yearNow + 1;
        } else {
          this.yearCFee = yearNow;
        }
      } catch (error) {
        console.error(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with payment days!"
        );
      }
    },
    async searchPayment() {
      try {
        this.addPreloader();
        const { data } = await ClientDashboardService.searchPayment({
          id: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.clientAccount.id,
        });
        this.montlydata = data[0];

        if (this.montlydata.payments == null) {
          this.paymentSchedule = [];
        } else {
          this.cards = JSON.parse(this.montlydata.cards);
          this.cards = this.cards.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });
          // SPLIT
          this.paymentSchedule = JSON.parse(this.montlydata.payments).map(
            (item) => {
              item.month = this.$moment(item.date).format("M");
              item.year = this.$moment(item.date).format("YYYY");
              item.date = this.getDay(item.date).replace(/^0+/, "");
              return item;
            }
          );
        }

        this.typePayment = this.paymentSchedule?.length > 1 ? 1 : 0;
        if (this.paymentSchedule?.length == 1) {
          this.yearCFee = this.paymentSchedule[0].year;
          this.monthCFee = this.paymentSchedule[0].month;
          this.dayCFee = this.paymentSchedule[0].date;
          this.cardId = this.paymentSchedule[0].card_id;
          this.paymentSchedule = [];
        } else if (this.paymentSchedule?.length > 1) {
          this.dataExist = true;
          this.newMonthlyAmount = this.montlydata.total;
        }
        // this.cards = JSON.parse(this.montlydata.cards);
        // this.cards = this.cards.sort((a, b) => {
        //   return new Date(b.created_at) - new Date(a.created_at);
        // });
        this.method_payment = this.montlydata.method_payment;
        this.monthly_amount = this.montlydata.total;
        this.cardType = this.montlydata.type_payment;
        this.fee = this.montlydata.fee;
        this.balance = this.montlydata.balance;
        this.rb_id = data[0].id;
      } catch (error) {
        console.error(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with  payment datas!"
        );
      } finally {
        this.removePreloader();
      }
    },
    calculatePaymentScheduleDates() {
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear();

      let paymentScheduleTest = [];

      if (this.paymentSchedule.length == 0) {
        let newDate = `${this.yearCFee}-${
          this.monthCFee < 10 ? "0" + this.monthCFee : this.monthCFee
        }-${this.dayCFee < 10 ? "0" + this.dayCFee : this.dayCFee}`;
        paymentScheduleTest.push({
          date: newDate,
          amount: this.monthly_amount,
          card_id: this.cardId,
          customer_profile_id: this.client.account,
        });
      } else {
        for (let i = 0; i < this.paymentSchedule.length; i++) {
          let item = this.paymentSchedule[i];
          let additionalMonths = item.current == 1 ? 0 : 1;
          let newMonth = currentMonth + additionalMonths;

          if (newMonth > 12) {
            newMonth = 1;
            currentYear = currentYear + 1;
          } else {
            newMonth = newMonth;
          }

          let newDate = `${currentYear}-${
            newMonth < 10 ? "0" + newMonth : newMonth
          }-${item.date < 10 ? "0" + item.date : item.date}`;

          paymentScheduleTest.push({
            date: newDate,
            amount: item.amount,
            card_id: item.card_id,
            current: item.current,
            ocurrences: item.ocurrences,
            customer_profile_id: this.client.account,
          });
        }
      }

      let paymentScheduleOrder = paymentScheduleTest.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      return paymentScheduleOrder;
    },
    async saveUpdate() {
      const validation = await this.$refs.form.validate();

      if (validation) {
        if (this.card_id === "") {
          this.submitedForm = true;
        }
        const confirm = await this.showConfirmSwal();

        if (!confirm.isConfirmed) return;

        let monthly = this.monthly_amount ? this.monthly_amount.toString() : "";
        try {
          let params = {
            id: this.$route.params.idClient
              ? this.$route.params.idClient
              : this.clientAccount.id,
            card_id: this.card_id,
            day_payment: this.day_payment,
            method_payment: this.method_payment,
            type_payment: this.method_payment == 1 ? null : this.cardType,
            monthly_amount:
              this.newMonthlyAmount > 0 ? this.newMonthlyAmount : monthly,
            transcationdid: this.idAutorize,
            user_id: this.currentUser.user_id,
            month_payment: this.month_payment,
            modul: this.moduleId,
            programid: this.client.program_id
              ? this.client.program_id
              : this.clientAccount.program_id,
            daneidcharge:
              this.client.charges != null
                ? this.client.charges
                : this.clientAccount.charges,

            removed_items: this.removedItems,
            rb_id: this.rb_id,
          };

          if (this.method_payment == 0 && this.cardType == 0) {
            params.payment_schedule = this.calculatePaymentScheduleDates();
            if (!params.payment_schedule) {
              return;
            }
          } else {
            params.payment_schedule = [];
          }

          this.addPreloader();

          const { data } = await ClientDashboardService.changeMonthlyPayment(
            params
          );

          this.$emit("updateClient");
          this.showSuccessSwal(
            "Monthly Payment",
            "Monthly Payment Updated",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
          this.closeModalMonthlyPayment();
          this.$emit("refresh", params);
        } catch (error) {
          console.error(error);
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Something went wrong with payment!"
          );
        } finally {
          this.removePreloader();
        }
      }
    },
  },
  watch: {
    monthly_amount(newVal, oldVal) {
      if (newVal > 0 && this.cardType == 0 && this.method_payment == 0) {
        this.calculatePaymentSchedule(this.paymentSchedule);
      }
    },
    day_payment(newVal, oldVal) {
      if (this.validateNotRecurringBilling) {
        this.month_payment = this.month_payment + 1;
        if (this.month_payment === 13) {
          this.month_payment = 1;
        }
      }
    },
    paymentSchedule: {
      handler() {
        this.newMonthlyAmount = this.paymentScheduleSum;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";

.b-table-bottom-row th:nth-child(2) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: 5px;
  background: #0090e7 !important;
}

.b-table-bottom-row th:nth-child(3) {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: -10px;
  background: #0090e7 !important;
}

.table-new-customization table.wo-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
