<template>
  <div class="smart-note-input">
    <b-button
      v-b-tooltip.hover.auto="`Optimize text`"
      variant="primary"
      class="d-flex align-items-center font-weight-bolder bot-icon-show"
      style="
        display: none;
        margin-left: 15px;
        width: 110px;
        height: 27px;
        margin-top: -5px;
        color: #f0f0f0 !important;
      "
      size="sm"
      @click="store"
      v-if="!showOver"
    >
      <BotIcon :size="'24'" style="margin: 0 2px 0 8px" />
      AI optimizer
    </b-button>
    <div
      class="d-flex justify-content-between align-items-center"
      style="margin-bottom: 8px"
    >
      <slot name="more-items" :hasItems="hasItems">
        <div v-if="!hasItems"></div>
      </slot>
      <b-button
        v-b-tooltip.hover.auto="`Optimize text`"
        variant="primary"
        class="d-flex align-items-center font-weight-bolder"
        size="sm"
        @click="store"
        v-if="showOptimize && showOver"
      >
        <BotIcon class="bot-icon" :size="'24'" />
        OPTIMIZE
      </b-button>
    </div>
    <!-- quill editor -->
    <quill-editor
      :disabled="disabled"
      ref="quillEditor"
      v-model="text"
      class="quill-br w-100 h-auto"
      :options="editorOptions"
    />
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import BotIcon from "@/assets/images/icons/smart-notes/BotIcon.vue";
import SmartRouteService from "@/commons/smart-input/smart-input.service.js";
import TypingText from "@/commons/TypingText.vue";

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 10,
    },
    placeholder: {
      type: String,
      default: "Type something...",
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    // if the optimization of text is required
    required: {
      type: Boolean,
      default: false,
    },
    // if text is required
    noEmpty: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showOver: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default: () => ({
        theme: "snow",
        placeholder: null,
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["link", "image"],
            ["clean"],
            ["bot"],
          ],
        },
      }),
    },
    showOptimize: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    quillEditor,
    BotIcon,
    TypingText,
  },
  data() {
    return {
      editorOptions: { ...this.options },
      attempts: 0,
      text: this.value,
      originalText: this.value,
      response: null,
      hasItems: false,
    };
  },
  mounted() {
    this.addIAButton();
    this.setContentHeight();
  },
  created() {
    this.replacePlaceholder();
    this.$emit("valid", this.attempts == 0 ? false : true);
  },
  watch: {
    value(myval) {
      this.text = myval;
    },
    text(newVal) {
      this.$emit("input", this.text);
      this.text = newVal;
    },
    attempts(newVal) {
      this.$emit("valid", newVal == 0 ? false : true);
    },
  },
  methods: {
    replacePlaceholder() {
      this.editorOptions.placeholder = this.placeholder;
    },
    setContentHeight() {
      const quill = this.$refs.quillEditor.quill;
      quill.container.style.height = "auto";
      quill.container.style.minHeight = this.rows * 20 + "px";
      quill.container.style.height = quill.container.scrollHeight + "px";
      quill.container.style.overflowX = "hidden";
    },
    addIAButton() {
      const customButton = document.querySelector(".ql-bot");
      const botIcon = document.querySelector(".bot-icon-show");
      customButton.appendChild(botIcon);
      botIcon.style.display = "block";
      customButton.addEventListener("click", () => {
        // this.store();
      });
    },
    replaceText(text) {
      this.attempts++;
      this.originalText = this.text;
      this.text = this.removeQuotes(text);
      this.$emit("original", this.originalText);
      this.close();
    },
    removeQuotes(text) {
      return text.replace(/['"]+/g, "");
    },
    copyToClipboard(text) {
      try {
        navigator.clipboard.writeText(text.trim());
        this.showGenericToast({
          position: "top-right",
          variant: "success",
          icon: "CheckIcon",
          title: "Copied!",
          text: "The text has been copied to the clipboard.",
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async store() {
      try {
        if (this.text === null || this.text === "") {
          this.showGenericToast({
            position: "top-right",
            variant: "warning",
            icon: "XIcon",
            title: "Warning!",
            text: "Please write something to get a response.",
          });
          return;
        }

        this.response = null;
        this.addPreloader();
        const { choices } = await SmartRouteService.store({
          prompt: this.text,
        });
        this.response = choices[0].message.content;
        this.response = this.response.replace(/\n/g, "<br>");
        this.response = this.response.replace(/^<br>/, "");
        this.response = this.response.replace(/^<br>/, "");
        this.replaceText(this.response);
      } catch (error) {
        const err = error.response.data;
        console.log(err);
        // this.showErrorSwal(error.data.message);
      } finally {
        this.removePreloader();
      }
    },
    close() {
      this.response = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.smart-note-input {
  height: max-content;
  .required {
    animation: growAndShrink 2s ease-in-out infinite;
  }
  @keyframes growAndShrink {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
  }
  .custom-button {
    border: none;
    cursor: pointer;
    padding: 0;
  }
  .custom-response {
    background-color: #fff;
    color: #656565;
    border: 1px solid #d8d6de;
    padding: 1em 0em 1em 0em;
    font-size: 1.1em;
  }

  .copy-button {
    display: flex;
    justify-content: flex-end;
  }
}

.dark-layout {
  .custom-response {
    background-color: #17171a;
    border-color: rgba(255, 255, 255, 0.2) !important;
    color: #ffffff;
  }
}

.quill-br {
  display: flex;
  flex-direction: column-reverse;
}
</style>

<style lang="scss">
.quill-br {
  .ql-container.ql-snow {
    border-top: 1px solid #ccc !important;
    border-radius: 5px 5px 0 0 !important;
  }

  .ql-toolbar.ql-snow {
    border-top: 0 !important;
    border-radius: 0 0 5px 5px !important;
  }
}
.bot-icon-show:hover {
  color: #f0f0f0;
}
.bot-icon-show:focus {
  color: #f0f0f0 !important;
}
</style>