export const headersManual = [
    {
        key: "a_name",
        label: "Name",
        visible: false,
        disabled: false,
        showDetail: true,
        type: "string",
        opcional: false,
        placeholder: "Enter the account name",
    },
    {
        key: "a_number",
        label: "Account Number",
        visible: true,
        showDetail: true,
        type: "string",
        opcional: false,
        placeholder: "Enter the account number (e.g., 1234567890)",
    },
    {
        key: "a_type",
        label: "Account Type",
        visible: true,
        showDetail: true,
        type: "string",
        input:"select",
        opcional: false,
        placeholder: "Search account type",
    },
    {
        key: "a_type_detail",
        label: "Account Type Detail",
        visible: true,
        showDetail: true,
        type: "string",
        input:"select",
        opcional: false,
        placeholder: "Search account type detail",
    },
    {
        key: "a_status",
        label: "Account Status",
        visible: true,
        showDetail: true,
        type: "string",
        input:"select",
        opcional: false,
        placeholder: "Enter the account status",
    },
    {
        key: "a_description",
        label: "Description",
        visible: false,
        showDetail: true,
        type: "string",
        opcional: true,
        placeholder: "Provide a brief description",
    },
    {
        key: "monthly",
        label: "Monthly",
        visible: false,
        showDetail: true,
        type: "money",
        opcional: true,
        placeholder: "Enter the monthly amount (e.g., 1,000.00)",
    },
    {
        key: "date",
        label: "Date Opened",
        visible: true,
        showDetail: true,
        type: "date",
        opcional: false,
        placeholder: "MM/DD/YYYY",
    },
    {
        key: "balance",
        label: "Balance",
        visible: true,
        showDetail: true,
        type: "money",
        opcional: true,
        placeholder: "Enter the current balance (e.g., 500.00)",
    },
    {
        key: "n_months",
        label: "Months",
        visible: false,
        showDetail: false,
        type: "string",
        opcional: true,
        placeholder: "",
    },
    {
        key: "h_credit",
        label: "High Credit",
        visible: true,
        showDetail: false,
        type: "money",
        opcional: true,
        placeholder: "Enter the credit limit (e.g., 10,000.00)",
    },
    {
        key: "c_limit",
        label: "Credit Limit",
        visible: true,
        showDetail: false,
        type: "money",
        opcional: true,
        placeholder: "Enter the spending limit (e.g., 2,000.00)",
    },
    {
        key: "p_status",
        label: "P. Status",
        visible: false,
        showDetail: false,
        type: "string",
        opcional: true,
        placeholder: "",
    },
    {
        key: "last_reported",
        label: "Last Reported",
        visible: false,
        showDetail: false,
        type: "string",
        opcional: true,
        placeholder: "MM/DD/YYYY",
    },
    {
        key: "comments",
        label: "Comments",
        visible: true,
        showDetail: false,
        type: "array",
        input:"select",
        opcional: false,
        placeholder: "Search comments",
    },
    {
        key: "date_last_active",
        label: "Date Last Active",
        visible: true,
        showDetail: false,
        type: "date",
        opcional: false,
        placeholder: "MM/DD/YYYY",
    },
    {
        key: "status30",
        label: "Status 30",
        visible: false,
        showDetail: false,
        type: "number",
        opcional: true,
        placeholder: "",
    },
    {
        key: "status60",
        label: "Status 60",
        visible: false,
        showDetail: false,
        type: "number",
        opcional: true,
        placeholder: "",
    },
    {
        key: "status90",
        label: "Status 90",
        visible: false,
        showDetail: false,
        type: "number",
        opcional: true,
        placeholder: "",
    },
    {
        key: "status120",
        label: "Status 120",
        visible: false,
        showDetail: false,
        type: "number",
        opcional: true,
        placeholder: "",
    },
    {
        key: "status150",
        label: "Status 150",
        visible: false,
        showDetail: false,
        type: "number",
        opcional: true,
        placeholder: "",
    },
    {
        key: "status180",
        label: "Status 180",
        visible: false,
        showDetail: false,
        type: "number",
        opcional: true,
        placeholder: "",
    },
    {
        key: "stateiq",
        label: "State IQ",
        visible: false,
        showDetail: false,
        type: "number",
        opcional: true,
        placeholder: "",
    },
    {
        key: "last_payment",
        label: "Last Payment",
        visible: true,
        showDetail: false,
        type: "date",
        opcional: false,
        placeholder: "MM/DD/YYYY",
    },
    {
        key: "show_detail",
        label: "Show Detail",
        visible: true,
        showDetail: true,
        type: 'boolean'
    },
]
const createAccountObject = (ac_name) => {
    let baseProperties = {
        required: false,
        value: "",
    };
    let basePropertiesSelect = {
        required: false,
        value: [],
    };
    return {
        a_name: { ...baseProperties },
        a_number: { ...baseProperties },
        a_type: { ...baseProperties },
        a_type_detail: { ...baseProperties },
        a_status: { ...baseProperties },
        a_description: { ...baseProperties },
        monthly: { ...baseProperties },
        date: { ...baseProperties },
        balance: { ...baseProperties },
        n_months: { ...baseProperties },
        h_credit: { ...baseProperties },
        c_limit: { ...baseProperties },
        p_status: { ...baseProperties },
        last_reported: { ...baseProperties },
        comments: { ...basePropertiesSelect },
        date_last_active: { ...baseProperties },
        status30: { ...baseProperties },
        status60: { ...baseProperties },
        status90: { ...baseProperties },
        status120: { ...baseProperties },
        status150: { ...baseProperties },
        status180: { ...baseProperties },
        stateiq: { ...baseProperties },
        last_payment: { ...baseProperties }
    };
}
export const arrayHeaders = () => {
    let arrayHeaders = [];
    headersManual.forEach((item) => {
        arrayHeaders.push({ ...item });
    });
    return arrayHeaders;
}
export const objectAccount = (ac_name) => {
    let arrayItem = [];
    for (let i = 0; i < 3; i++) {
        arrayItem.push(createAccountObject(ac_name));
    }
    return {
        headers: arrayHeaders(),
        account: arrayItem,
        showDetail: false,
        creditor_name: ac_name
    };
}

export const objectElement = (arrayItem, ac_name) => {
    return {
        headers: arrayHeaders(),
        account: arrayItem,
        showDetail: false,
        creditor_name: ac_name
    }
}



export const formatMoney = (value) => {
    value = value ? value.toString().replace(/,/g,"") : 0;
    return Intl.NumberFormat("en-US", {
        currencySign: "accounting",
        currency: "USD",
        minimumFractionDigits: 2,
    }).format(Number(value));
}

export const isEmpty = (value) => {
    try {
        return value.trim().length > 0;
    } catch (ex) {
        return false;
    }
}
export const inputsRequireds = headersManual.filter(item => item.type != 'boolean');
export const sanitizerBureau = (item, formatAccounts = false) => {
    let obj = {};
    inputsRequireds.forEach((ite) => {
        let value = formatAccounts ? item[ite.key].value : item[ite.key];
        if (ite.type === "money") {
            obj[ite.key] = formatMoney(value);
        } else if (ite.type === "number") {
            obj[ite.key] = isEmpty(value) ? value : 0;
        } else if (ite.type === "array") {
            if (formatAccounts) {
                obj[ite.key] = value.length > 0 ? [...value].join(",").trim() : "-";
            } else {
                obj[ite.key] = value;
            }
        } else {
            obj[ite.key] = isEmpty(value) ? value : "-";
        }
    });
    return obj;
}

export const validInputs = (item, index, tableAccountManual = false, verifyEmpty = false) => {
    let keys = Object.keys(item).filter((x) => x != "a_name");
    let total = 0;
    for (let t in keys) {
        let value = tableAccountManual ? item[keys[t]].value : item[keys[t]];
        let header = headersManual.find(x => x.key == keys[t]);

        if (value != "-") {
            if (header) {
                switch (header.type) {
                    case 'string':
                        if (value && value.length > 0) {
                            total++;
                        }
                        break;
                    case 'money':
                        if (tableAccountManual && verifyEmpty) {
                            if (Number(value) > 0) {
                                total++;
                            }
                        }
                        break;
                    case 'array':
                        if (tableAccountManual) {
                            if (Array.isArray(value) && value.length > 0) {
                                total++;
                            }
                        } else {
                            if (value.length > 0) {
                                total++;
                            }
                        }
                        break;
                    case 'number':

                        if (Number(value) > 0) {
                            total++;
                        }
                        break;
                    case 'date':
                        if (value.length > 0) {
                            total++;
                        }
                        break;
                }
            }
        }
    }
    return total;
}