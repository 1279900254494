export default [
  {
    path: "performance",
    name: "performance-boostcredit",
    component: () =>
      import(/* webpackChunkName: "BCAdvisorPerformance" */ "@/views/commons/components/advisor-performance/Performance.vue"),
    meta: {
      pageTitle: "Advisor Performance",
      breadcrumb: [
        {
          text: "Advisor Performance",
        },
      ],
      permittedRoles: [1, 2],
    },
  },
];
