

export default [
  {
    path: 'dashboard',
    name: 'ce-dashboard',
    component: () => import(/* webpackChunkName: "CEDFinancialDashboard" */ '@/views/ce-digital/sub-modules/financial/view/dashboards/DashboardRolesFinancial.vue'),
    meta: {
      parentModule: 21,
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
]
