export default {
	namespaced: true,
	state: {
		isAnsweringCallRound: false,
	},
	mutations: {
		setAnsweringCallRound(state, data) {
			state.isAnsweringCallRound = data
		},
	},
	actions: {
		A_ANSWER_CR({ commit }) {
			commit("setAnsweringCallRound", true)
		},
		A_END_CR({ commit }) {
			commit("setAnsweringCallRound", false)
		},
	},
	getters: {
		G_STORE(state) {
			return {
				answeringCR: state.isAnsweringCallRound,
			}
		},
	},
}
