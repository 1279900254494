// IMPORT LIBRARIES

import companiesService from '@/views/management/views/companies/companies.service'

const state = {
  S_MANAGEMENT_USERS: [],
  S_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER: [],

}
const getters = {
  G_MANAGEMENT_USERS() {
    const users = state.S_MANAGEMENT_USERS.map(el => ({ label: el.value, id: el.id }))
    return users
  },
  G_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER() {
    const users = state.S_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER
    return users
  },

}

const mutations = {
  SET_MANAGEMENT_USERS(state, states) {
    state.S_MANAGEMENT_USERS = states
  },
  SET_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER(state, states) {
    state.S_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER = states
  },

}
const actions = {
  async A_GET_MANAGEMENT_USERS({ commit }, params) {
    try {
      let users = await companiesService.getManagementUsers(params)

      users = users.data

      commit('SET_MANAGEMENT_USERS',

        users)
      return users
    } catch (error) {
      console.log('ERROR_MANAGEMENT_USERS [ACTION]', error)
      throw error
    }
  },

  async A_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER({ commit }, params) {
    try {
      let users = await companiesService.getCouterScheduleTransfer(params)
      let counter = 0
      users = users.data
      if (users.length > 0) {
        counter = users[0].counter
      }
      commit('SET_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER',
        counter)
      return users
    } catch (error) {
      console.log('ERROR_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER [ACTION]', error)
      throw error
    }
  },

}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}
