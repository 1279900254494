import store from "@/store"
import router from "@/router/index"
import Vue from "vue"

const openAlertsLetter = () => {
  window.socket.bind("alert-request-advisor-app", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id
    let user_id = data.to_id.find((element) => sessionId == element.user_id)
    if (user_id != undefined) {
      store.commit("CeDigitalCsAlerts/SET_ALERT_CHAT", true)
      store.commit("CeDigitalCsAlerts/SET_CHAT_INFO", data)
    }
  })
}
export default openAlertsLetter
