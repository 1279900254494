<template>
  <b-card header-class="border-0 my-0">
    <template #header>
      <div
        class="d-flex justify-content-between w-100 border-bottom-secondary border-2"
      >
        <b-card-title
          class="w-100 d-block d-sm-flex justify-content-between align-items-center pb-50"
        >
          <h4 class="ring-central-title">Ring Central</h4>
          <b-button-group class="btn-group-sm" v-if="!isTabActive">
            <b-button
              v-if="!onlyRead"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-success="'Answered'"
              variant="success"
              class="btn-icon p-1"
              @click="onCall('answered')"
              :disabled="isLoading || isResponsibleLead"
            >
              <span v-if="!isLoading">AN</span>
              <b-spinner v-else small />
            </b-button>
            <b-button
              v-if="!onlyRead"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-warning="'Pending'"
              variant="warning"
              class="btn-icon p-1"
              @click="onCall('pending')"
              :disabled="isLoading || isResponsibleLead"
            >
              <span v-if="!isLoading">PE</span>
              <b-spinner v-else small />
            </b-button>
            <b-button
              v-if="!onlyRead"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.v-danger="'Unanswered'"
              variant="danger"
              class="btn-icon p-1"
              @click="onCall('unanswered')"
              :disabled="isLoading || isResponsibleLead"
            >
              <span v-if="!isLoading">UN</span>
              <b-spinner v-else small />
            </b-button>
          </b-button-group>
        </b-card-title>
      </div>
    </template>
    <template #default>
      <b-tabs
        v-model="isTabActive"
        pills
        nav-class="my-0"
        active-nav-item-class="bg-primary box-shadow-info"
      >
        <div class="border-top-primary border-2">
          <b-tab :title-link-class="bgTabsNavs">
            <template #title>
              <span>Call Log</span>
            </template>
            <client-call-log
              class="px-1"
              :double-paginate="false"
              :max-height="'245px'"
              min-fields
              :tab-conversation="true"
            ></client-call-log>
          </b-tab>
          <b-tab :title-link-class="bgTabsNavs">
            <template #title>
              <span>SMS</span>
            </template>
            <lead-sms-history
              class="px-1"
              :lead-id="$route.params.id"
              :lead-data="S_LEAD"
            />
          </b-tab>
        </div>
      </b-tabs>
    </template>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ModalCallHistory from "./ModalCallHistory.vue";
import ModalAddCallVue from "../../lead-call/ModalAddCall.vue";
import callService from "@/views/crm/services/call/index.js";
import WaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/WaveSurferPlayer.vue";
import CallLogService from "@/views/commons/components/ring-central/call-log/service/call-log.service.js";
import ClientCallLog from "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue";
import LeadSmsHistory from "@/views/commons/components/ring-central/mass-messages/components/LeadHistorySms.vue";

export default {
  components: {
    ModalCallHistory,
    ModalAddCallVue,
    WaveSurferPlayer,
    ClientCallLog,
    LeadSmsHistory,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    ...mapState({
      S_CALLS: (state) => state.CrmCallStore.S_CALLS,
      S_LEAD: (state) => state.CrmLeadStore.S_LEAD,
    }),
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  async created() {
    await this.getCallLead();
    this.authUser = this.currentUser;
    this.getCallLogRecording();
  },
  data() {
    return {
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        width: 180,
        backend: "MediaElement",
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      cAudio: "",
      isTabActive: null,
      fieldsTask: [
        { key: "index", label: "#" },
        { key: "done_by" },
        { key: "status" },
      ],
      fieldsCall: [
        { key: "full_name", thClass: "text-center", label: "User" },
        { key: "url_file", thClass: "text-center", label: "Audio" },
        { key: "time_file", thClass: "text-center" },
        { key: "created_at", thClass: "text-center" },
      ],
      fieldsCallLog: [
        {
          key: "cc",
          thClass: "text-center",
          tdClass: "text-center  ",
          label: "Type",
        },
        { key: "original_id", thClass: "text-center", label: "Audio" },
        { key: "duration", thClass: "text-center", label: "Duration" },
        { key: "start_time", thClass: "text-center", label: "Date" },
      ],
      isLoading: false,
      showModalAddCall: false,
      callLogRecording: [],
    };
  },
  directives: { Ripple },
  methods: {
    ...mapActions({
      A_SET_CALL: "CrmCallStore/A_SET_CALL",
      A_GET_CALLS: "CrmCallStore/A_GET_CALLS",
    }),

    variantStatus(status) {
      if (status === "unanswered") return "danger";
      if (status === "pending") return "warning";
      if (status === "answered") return "success";
      return "default";
    },
    refreshTable() {
      this.$refs["tableCall"].refresh();
    },
    async getCallLead() {
      const params = {
        id_lead: this.$route.params.id,
      };
      const res = await callService.getFIlesCallLead(params);
      let item = res.data;
      return item || [];
    },
    onCall(call) {
      this.isLoading = true;
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            const response = await this.A_SET_CALL({
              lead_id: this.lead.id,
              user_id: this.authUser.user_id,
              call,
              typeTab: this.typeTab,
            });
            if (this.isResponseSuccess(response)) {
              this.$emit("onReloadCall", 10);
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                `Something went wrong. ${response.message}`
              );
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log("Something went wrong onCall", error);
          this.showErrorSwal();
          this.isLoading = false;
        });
    },

    async getCallLogRecording() {
      const params = {
        lead_id: this.$route.params.id,
        module_id:
          this.$route.matched[0].meta.module || this.isTicketCustomer(),
      };
      const { data } = await CallLogService.getLeadCallLog(params);
      this.callLogRecording = data.data;
    },

    isTicketCustomer() {
      return 1;
    },
  },
  props: {
    onlyRead: {
      type: Boolean,
      required: true,
    },
    lead: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.h-custom {
  max-height: 20vh !important;
}
.ring-central-title {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #111113;
}

.dark-layout {
  .ring-central-title {
    color: #fafafa;
  }
}
</style>
