export default [
    {
        path: 'dashboard-claims',
        name: 'dashboard-claims-logistic',
        component: () => import(/* webpackChunkName: "RrhhEmployeesMain" */ '@/views/logistic/views/dashboard-claims/MainDashboardClaims.vue'),
        meta: {
            pageTitle: "Dashboard Claims",
            permittedRoles: [1, 2, 17],
            breadcrumb: [{
                text: 'Dashboard Claims',
                active: true,
            }],
            tabOthersClaimsRoute: 'lg-employee-claims-others-claims',
            tabEmployeeClaimListRoute: 'lg-employee-claims-list',
        },
    }
]
