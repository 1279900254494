import { amgApi } from '@/service/axios'

class SettingsSnService {
  async getMotivesLossSn(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-motives-loss-sn', body)
      return data.data
    } catch (error) {
      throw error
    }
  }

  async getMotivesLossCrm(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-motives-loss-crm', body)
      return data.data
    } catch (error) {
      throw error
    }
  }
  async getAllUserTeamRank(params) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-all-user-team-rank',params)
      return data
    } catch (error) {
      throw error
    }
  }
    async getAllRoles() {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-all-roles')
      return data
    } catch (error) {
      throw error
    }
  }
  async updateRoleUser(params) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/update-role-user', params)
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  async enableOrDisableUser(params){
    try {
      const data = await amgApi.post('/lead/social-network/settings/update-status-user-team-rank',params)
      return data
    } catch (error) {
      throw error
    }
  }
  async getTrackingUserTeamRank(params){
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-tracking-user-team-rank',params)
      return data
    } catch (error) {
      throw error
    }
  }
  async getAllMotiveLossTypes() {
    try {
      const data = await amgApi.get('/lead/social-network/settings/get-all-motive-loss-types')
      return data
    } catch (error) {
      throw error
    }
  }

  async deleteMotiveLoss(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/delete-motive-loss', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getMotiveLossTypesByModule(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-motive-loss-types-by-module', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getMotiveLossByType(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-motive-loss-by-type', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getMotivesByModule(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-motive-by-module', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async saveMotiveLoss(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/save-motive-loss', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getSources(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-source', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getContactMethod(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-contact-methods', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveContactMethod(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/save-contact-method', body);
      return data;
    } catch (error) {
      throw error;
    }
  }


  async getAllSources() {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-all-sources')
      return data
    } catch (error) {
      throw error
    }
  }
  
  async getAllContactMethods(params) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-all-contact-methods',params)
      return data
    } catch (error) {
      throw error
    }
  }

  async getSourceMethodsByProgram() {
    try {
      const data = await amgApi.get('/social-network/get-methods-contact-by-source');
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveSource(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/save-source', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getContactMethodBySource(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/get-contact-methods-by-source', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateStatusContactMethod(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/update-status-contact-method', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateStatusSource(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/update-status-source', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteContactMethod(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/delete-contact-method', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteSource(body) {
    try {
      const data = await amgApi.post('/lead/social-network/settings/delete-source', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

}

export default new SettingsSnService()
