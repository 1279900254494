<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    hide-footer
    size="xmd"
    header-class="p-0"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    title="CREDIT CARDS"
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Credit Cards
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <div class="mb-2">
      <b-row>
        <b-col cols="12" md="4" lg="4">
          <!-- <span class="title-tag">CLIENT NAME</span> -->
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
              style="gap: 5px"
            >
              Client | {{ client.client_name }}
            </p>
          </div>
        </b-col>

        <b-col cols="12" md="4" lg="4">
          <!-- <span class="title-tag">CLIENT ACCOUNT</span> -->
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
              style="gap: 5px"
            >
              Client Account | {{ client.account }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row><b-col>
      <b-tabs
        pills
        content-class="mt-3"
      >
        <b-tab
          title="ACTIVES"
          active
        >
          <b-table

            ref="refCards"
            small
            show-empty
            :busy="isBusy"
            :fields="fields"
            :items="searchCards"
            class="table-new-customization font-small-3 text-start custom-table"
            sticky-header="50vh"
            responsive
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>

            <template v-slot:cell(card_holder_name)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ data.item.cardholdername }}
              </p>

            </template>
            <template v-slot:cell(card_number)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ 'XXXX-XXXX-XXXX-' + data.item.cardnumber }}
              </p>

            </template>
            <template v-slot:cell(type)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ data.item.type_card }}
              </p>

            </template>
            <template v-slot:cell(mm)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ data.item.card_expi_month }}
              </p>

            </template>
            <template v-slot:cell(yy)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ data.item.card_expi_year }}
              </p>

            </template>
            <template v-slot:cell(cvc)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ 'XX'+data.item.cardsecuritycode }}
              </p>

            </template>
            <template v-slot:cell(created_by)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                <span>{{ data.item.created_by }}</span>
                <br>
                <span>{{ data.item.created_at | myGlobalDay }}</span>
              </p>

            </template>
            <template v-slot:cell(actions)="data">
              <div style="margin-left: 15px">
                <feather-icon

                  size="16"
                  icon="TrashIcon"
                  class="cursor-pointer text-danger "
                  @click="openDeleteCreditCard(data.item.id)"
                />
              </div>

            </template>
          </b-table>
        </b-tab>
        <b-tab title="DELETEDS">
          <b-table
            ref="refCardsdelete"
            small
            show-empty
            :busy="isBusy"
            :fields="fieldsDelete"
            :items="deletesearchCards"
            class="table-new-customization font-small-3 text-start custom-table"
            sticky-header="50vh"
            responsive
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>

            <template v-slot:cell(card_holder_name)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ data.item.cardholdername }}
              </p>

            </template>
            <template v-slot:cell(card_number)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ 'XXXX-XXXX-XXXX-' + data.item.cardnumber }}
              </p>

            </template>
            <template v-slot:cell(type)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ data.item.type_card }}
              </p>

            </template>
            <template v-slot:cell(mm)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ data.item.card_expi_month }}
              </p>

            </template>
            <template v-slot:cell(yy)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ data.item.card_expi_year }}
              </p>

            </template>
            <template v-slot:cell(cvc)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ 'XX'+data.item.cardsecuritycode }}
              </p>

            </template>
            <template v-slot:cell(commentary)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                {{ data.item.commentary }}
              </p>

            </template>
            <template v-slot:cell(deleted_by)="data">
              <p
                class="mb-0 font-weight-bold "
              >
                <span>{{ data.item.deleted_by }}</span>
                <br>
                <span>{{ data.item.deleted_at | myGlobalDay }}</span>
              </p>

            </template>

          </b-table>
        </b-tab>
      </b-tabs>
    </b-col>

    </b-row>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-end">
          <b-button
            variant="primary"
            @click="openCreateCreditCard"
            class="bg-custom"
          >
            ADD</b-button>
        </div>
      </b-col>
    </b-row>
    <delete-credit-card

      v-if="deleteCreditOn"
      :card_id="card_id"
      :lead_id="client.lead_id"
      @refresh="refresh"
      @close="closeDeleteCreditCard"
    />
    <create-credit-card
      v-if="createCreditOn"
      :lead="client.lead_id"
      @reload="refresh"
      @close="closeCreateCreditCard"
    />
  </b-modal>

</template>
import dataFields
<script>
import dataFields
from '@/views/commons/components/clients/dashboard/options/pay-module/modals/credit-card-modal/fields.data.js'
import dataFieldsdelete
from '@/views/commons/components/clients/dashboard/options/pay-module/modals/credit-card-modal/fieldsdelete.data.js'
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services'
import DeleteCreditCard from '@/views/commons/components/clients/dashboard/options/pay-module/modals/DeleteCreditCard.vue'
import CreateCreditCard from '@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue'

export default {

  components: {
    DeleteCreditCard,
    CreateCreditCard,
  },

  props: {
    client: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      ownControl: false,
      fields: dataFields,
      fieldsDelete: dataFieldsdelete,
      cantcards: [],
      deletecantcards: [],
      card_id: null,
      deleteCreditOn: false,
      createCreditOn: false,
    }
  },
  created() {
    this.ownControl = true
  },
  methods: {
    closeCreateCreditCard() {
      this.createCreditOn = false
    },
    openCreateCreditCard() {
      this.createCreditOn = true
    },
    refresh() {
      this.$refs.refCards.refresh()
      this.$refs.refCardsdelete.refresh()
    },
    openDeleteCreditCard(id) {
      this.card_id = id
      this.deleteCreditOn = true
    },
    closeDeleteCreditCard() {
      this.deleteCreditOn = false
    },
    async closeModal() {
      this.$emit('close')
      await Promise.all([
        this.$store.dispatch('DebtSolutionClients/A_COUNT_CREDIT_CARD', {
          id: this.client.lead_id,
        }),

      ])
    },
    async searchCards() {
      try {
        const data = await ClientsOptionsServices.searchcards({
          id: this.client.lead_id,
        })
        this.cantcards = data.data
        return this.cantcards
      } catch (error) {
        console.log(error)
        this.removePreloader()
      }
    },

    async deletesearchCards() {
      try {
        const data = await ClientsOptionsServices.deletesearchcards({
          id: this.client.lead_id,
        })
        this.deletecantcards = data.data
        return this.deletecantcards
      } catch (error) {
        console.log(error)
        this.removePreloader()
      }
    },
  },
}
</script>

<style scoped>
*{
  --primary-color: #3f7afa;
}
.bg-custom {
  background-color: var(--primary-color) !important;
}
.custom-table >>> th {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child {
  border-top-right-radius: 10px !important;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}

</style>
