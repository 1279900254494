export default [
    {
      path: "report",
      name: "administration-report",
      redirect: { name: "administration-report-monthly-payments-tab" },
      component: () =>
        import(
          /* webpackChunkName: "AdministrationReport" */ "@/views/administration/views/report/Report.vue"
        ),
      meta: {
        permittedRoles: [1, 2, 6],
        pageTitle: "Reports",
        route: "administration",
        breadcrumb: [
          {
            text: "Report",
            active: true,
          },
        ],
      },
      children: [
        {
          path: "",
          name: "administration-report-monthly-payments-tab",
          component: () =>
            import(
              /* webpackChunkName: "AdministrationReportMonthlyPaymentsTab" */ "@/views/administration/views/report/components/MonthlyPaymentsTab.vue"
            ),
          meta: {
            permittedRoles: [1, 2, 6],
            pageTitle: "Reports",
            breadcrumb: [
              {
                text: "Monthly Payments",
                to: { name: "administration-report-monthly-payments-tab" },
              },
            ],
          },
        },
        {
          path: "",
          name: "administration-report-global-income-tab",
          component: () =>
            import(
              /* webpackChunkName: "AdministrationReportGlobalIncomeTab" */ "@/views/administration/views/report/components/GlobalIncomeTab.vue"
            ),
          meta: {
            permittedRoles: [1],
            pageTitle: "Reports",
            breadcrumb: [
              {
                text: "Global Income",
                to: { name: "administration-report-global-income-tab" },
              },
            ],
          },
        },
        {
          path: "",
          name: "administration-report-payments-status-tab",
          component: () =>
            import(
              /* webpackChunkName: "AdministrationReportPaymentStatusTab" */ "@/views/administration/views/report/components/PaymentStatusTab.vue"
            ),
          meta: {
            permittedRoles: [1],
            pageTitle: "Reports",
            breadcrumb: [
              {
                text: "Payment Status",
                to: { name: "administration-report-payments-status-tab" },
              },
            ],
          },
        },
        {
          path: "",
          name: "administration-report-vs-payment-tab",
          component: () =>
            import(
              /* webpackChunkName: "AdministrationReportVsPaymentTab" */ "@/views/administration/views/report/components/VsPayment.vue"
            ),
          meta: {
            permittedRoles: [1],
            pageTitle: "Reports",
            breadcrumb: [
              {
                text: "VS Payment",
                to: { name: "administration-report-vs-payment-tab" },
              },
            ],
          },
        },
        {
          path: "",
          name: "administration-report-general-balance-ds-tab",
          component: () =>
            import(
              /* webpackChunkName: "AdministrationReportGeneralBalanceDsTab" */ "@/views/administration/views/report/components/GeneralBalance.vue"
            ),
          meta: {
            permittedRoles: [1],
            pageTitle: "Reports",
            breadcrumb: [
              {
                text: "General Balance DS",
                to: { name: "administration-report-general-balance-ds-tab" },
              },
            ],
          },
        },
      ],
    },
  ];
  