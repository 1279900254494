import store from "@/store";
import Vue from "vue";

export const newClaimNotification = () => {
  window.socket.bind(
    "new-claim-notification",
    async ({ title, clientData, qualityUsers, modules }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];

      if (
        qualityUsers.some((user) => user.id == user_id) &&
        current_module_id == modules[0]
      ) {
        store.commit("AutoAlertClaim/M_OpenAlert", true);
        store.commit("AutoAlertClaim/M_DataClaim", clientData);
        store.commit("AutoAlertClaim/M_Title", title);
      }
    }
  );
};

export const SolvedClaimNotification = () => {
  window.socket.bind(
    "solved-claim-notification",
    async ({ usersToSend, title, clientData, loggedInUserId }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];
      if (
        usersToSend.some(
          (x) => x.module_id === current_module_id && x.id === user_id
        ) &&
        loggedInUserId != user_id
      ) {
        store.commit("AutoAlertClaim/M_OpenAlert", true);
        store.commit("AutoAlertClaim/M_DataClaim", clientData);
        store.commit("AutoAlertClaim/M_Title", title);
      }
    }
  );
};

export const ScaledClaimNotification = () => {
  window.socket.bind(
    "scaled-claim-notification",
    async ({ usersToSend, title, clientData, loggedInUserId }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];
      if (
        usersToSend.some(
          (x) => x.module_id === current_module_id && x.id === user_id
        ) &&
        loggedInUserId != user_id
      ) {
        store.commit("AutoAlertClaim/M_OpenAlert", true);
        store.commit("AutoAlertClaim/M_DataClaim", clientData);
        store.commit("AutoAlertClaim/M_Title", title);
      }
    }
  );
};

export const ClaimNotificationDepartaments = () => {
  window.socket.bind(
    "claim-notification-departament",
    async ({ usersToSend, title, clientData }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];
      if (
        usersToSend.some(
          (x) => x.module_id === current_module_id && x.id === user_id
        )
      ) {
        store.commit("AutoAlertClaim/M_OpenAlert", true);
        store.commit("AutoAlertClaim/M_DataClaim", clientData);
        store.commit("AutoAlertClaim/M_Title", title);
      }
    }
  );
};

export const CloseAllClaimNotification = () => {
  window.socket.bind("client-close-all-claim-notification", async (data) => {
    const { user_id } = store.getters["auth/currentUser"];
    if (data.user_id == user_id) {
      store.commit("AutoAlertClaim/M_OpenAlert", false);
      store.commit("AutoAlertClaim/M_DataClaim", null);
      store.commit("AutoAlertClaim/M_Title", null);
    }
  });
};

export const AnnulledClaimNotification = () => {
  window.socket.bind(
    "annulled-claim-notification",
    async ({ usersToSend, title, text, loggedInUserId }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];
      const hasValidUser = usersToSend.some((user) => {
        const isCurrentModuleUser =
          user.module_id === current_module_id && user.id === user_id;
        const isSupervisorQuality =
          user.module_id === 18 && user.role_id === 2 && user.id === user_id;
        return isCurrentModuleUser || isSupervisorQuality;
      });

      if (hasValidUser && loggedInUserId !== user_id) {
        const { value } = await Vue.swal.fire({
          icon: "success",
          title,
          text,
        });

        if (value) {
          await window.amgApi.post("/commons/close-all-swal", {
            to_id: [user_id],
          });
        }
      }
    }
  );
};

export const NewInquiryNotification = () => {
  window.socket.bind(
    "new-inquiry-notification",
    async ({ usersToSend, title, text, loggedInUserId }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];
      if (
        usersToSend.some(
          (x) => x.module_id === current_module_id && x.id === user_id
        ) &&
        loggedInUserId != user_id
      ) {
        const { value } = await Vue.swal.fire({
          icon: "success",
          title,
          text,
        });

        if (value)
          await window.amgApi.post("/commons/close-all-swal", {
            to_id: [user_id],
          });
      }
    }
  );
};

export const SolvedInquiryNotification = () => {
  window.socket.bind(
    "solved-inquiry-notification",
    async ({ usersToSend, title, text, loggedInUserId }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];
      if (
        usersToSend.some(
          (x) => x.module_id === current_module_id && x.id === user_id
        ) &&
        loggedInUserId != user_id
      ) {
        const { value } = await Vue.swal.fire({
          icon: "success",
          title,
          text,
        });

        if (value)
          await window.amgApi.post("/commons/close-all-swal", {
            to_id: [user_id],
          });
      }
    }
  );
};
