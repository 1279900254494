import { amgApi } from "@/service/axios";

class SettingsServiceSp {
  async getAllManualNcrTypes(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-all-manual-ncr-type",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllAutotmaticNcrTypes(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-all-automatic-ncr-type",
        params
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async getNcrFailedMotives(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-motives-failed-ncr",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateManualNcrType(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/update-manual-ncr-type",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async deleteManualNcrType(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/delete-manual-ncr-type",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async deleteNcrFailedMotives(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/delete-ncr-failed-motive",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async updateNcrFailedMotives(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/update-ncr-failed-motive",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async updateNcrStatusAutomatic(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/update-ncr-status-automatic",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async saveManualNcrTypes(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/save-manual-ncr-type",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async saveNcrFailedMotives(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/save-ncr-failed-motives",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async getManualNcrTypeTrack(params) {
    try {
      const { data } = await amgApi.post(
        "specialists/settings/get-tracking-manual-ncr-type",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateNcrPrices(params) {
    try {
      return await amgApi.post("/request-ncr/update-ncr-prices", params);
    } catch (error) {
      throw error;
    }
  }

  async getTrackingNcrPrices(params) {
    try {
      return await amgApi.post("/request-ncr/get-tracking-ncr-prices", params);
    } catch (error) {
      throw error;
    }
  }

  async getTrackingNcrFailedMotives(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-tracking-ncr-failed-motives",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async modifyPriority(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/classification-priority/modify-priority",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPriorityTracking(params) {
    try {
      const { data } = await amgApi.get(
        "/specialists/settings/classification-priority/get-priority-tracking"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getClassificationNcr(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-classification-ncr",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getKeywords(params) {
    try {
      const data = await amgApi.post(
        "/specialists/settings/get-keywords",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async saveKeywords(params) {
    try {
      return await amgApi.post("/specialists/settings/save-keywords", params);
    } catch (error) {
      throw error;
    }
  }
  async updateKeywords(params) {
    try {
      return await amgApi.post("/specialists/settings/update-keywords", params);
    } catch (error) {
      throw error;
    }
  }

  async deleteKeyword(params) {
    try {
      return await amgApi.post("/specialists/settings/delete-keyword", params);
    } catch (error) {
      throw error;
    }
  }

  async getClassificationNcrReasonsInstructions(params = null) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-classifications-ncr-reasons-instructions",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async saveClassificationReasonInstructionDetails(formData) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/save-classification-reasons-instructions-details",
        formData
      );
      return data;
    } catch (ex) {

    }
  }

  async saveReasonsOrInstructions(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/save-reasons-or-instructions", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteReasonsOrInstructions(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/delete-reasons-or-instructions", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async searchUsers(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/get-credit-analyst-actives", params)

      return data

    } catch (error) {
      console.log("Something went wrong on searchUsers", error);
      throw error
    }
  }

  async getZeroPaymentModules() {
    try {
      const data = await amgApi.get("/specialists/settings/get-zero-payment-modules")

      return data

    } catch (error) {
      console.log("Something went wrong on searchUsers", error);
      throw error
    }
  }

  async updateZeroPaymentModules(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/update-zero-payment-modules", params)

      return data

    } catch (error) {
      console.log("Something went wrong on searchUsers", error);
      throw error
    }
  }

  async searchServices() {
    try {
      const { data } = await amgApi.post("/specialists/settings/get-specialists-services")
      return data
    } catch (error) {
      console.log("Something went wrong on searchServices", error);
      throw error
    }
  }

  async saveService(params) {
    try {
      return await amgApi.post("/specialists/settings/save-service", params);
    } catch (error) {
      console.log("Something went wrong on saveService", error);
      throw error;
    }
  }

  async updateService(params) {
    try {
      return await amgApi.post("/specialists/settings/update-specialist-service", params);
    } catch (error) {
      console.log("Something went wrong on updateService", error);
      throw error;
    }
  }

  async getTrackingZeroPaymentsModules(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-tracking-zero-payment-modules",
        params
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getTrackingZeroPaymentsModules ", error);
      throw error;
    }
  }

  async getTrackingServices(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-tracking-specialists-services",
        params
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on get-tracking-specialists-services ", error);
      throw error;
    }
  }

  async deleteSpecialistServices(params) {
    try {
      return await amgApi.post("/specialists/settings/delete-specialists-services", params);
    } catch (error) {
      throw error;
    }
  }

  async getQuestionnaireCategories() {
    try {
      const { data } = await amgApi.post("/specialists/settings/get-categories");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getQuestionnaireItems(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/get-items", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveQuestionnaireItem(params) {
    try {
      return await amgApi.post("/specialists/settings/save-q-item", params);
    } catch (error) {
      console.log("Something went wrong on saveQuestionnaireItem", error);
      throw error;
    }
  }

  async updateQuestionnaireItem(params) {
    try {
      return await amgApi.post("/specialists/settings/update-q-item", params);
    } catch (error) {
      console.log("Something went wrong on updateQuestionnaireItem", error);
      throw error;
    }
  }

  async getTrackingQuestionnaireItem(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/get-tracking-q-item", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getTrackingQuestionnaireItem", error);
      throw error;
    }
  }

  async getAppointmentTypes(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/get-appointment-types", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getAppointmentTypes", error);
      throw error;
    }
  }

  async updateAppointmentType(params) {
    try {
      return await amgApi.post("/specialists/settings/update-appointment-type", params);
    } catch (error) {
      console.log("Something went wrong on updateAppointmentType", error);
      throw error;
    }
  }

  async getTrackingAppointmentType(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/get-tracking-appointment-type", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on updateAppointmentType", error);
      throw error;
    }
  }

  async saveAppCancelMotive(params) {
    try {
      return await amgApi.post("/specialists/settings/add-app-cancel-motive", params);
    } catch (error) {
      console.log("Something went wrong on saveAppCancelMotive", error);
      throw error;
    }
  }

  async getAppointmentCancellationMotives(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/get-app-cancel-motives", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getAppointmentCancellationMotives", error);
      throw error;
    }
  }

  async updateAppointmentCancellationMotive(params) {
    try {
      return await amgApi.post("/specialists/settings/update-app-cancel-motive", params);
    } catch (error) {
      console.log("Something went wrong on updateAppointmentCancellationMotive", error);
      throw error;
    }
  }

  async deleteCancellationMotive(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/delete-cancel-motive",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async getTrackingAppointmentCancelMotives(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/get-tracking-appointment-cancel-motives", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getTrackingAppointmentCancelMotives", error);
      throw error;
    }
  }

  async getAppointmentSchedule() {
    try {
      const data = await amgApi.post("/specialists/settings/get-appointment-attention-hours");
      return data;
    } catch (error) {
      console.log("Something went wrong on getAppointmentSchedule", error);
      throw error;
    }
  }

  async updateAppointmentDay(params) {
    try {
      return await amgApi.post("/specialists/settings/update-appointment-day", params);
    } catch (error) {
      console.log("Something went wrong on updateAppointmentDay", error);
      throw error;
    }
  }

  async updateAppointmentWeek(params) {
    try {
      return await amgApi.post("/specialists/settings/update-appointment-week", params);
    } catch (error) {
      console.log("Something went wrong on updateAppointmentWeek", error);
      throw error;
    }
  }

  async getScheduleTracking(params) {
    try {
      const data = await amgApi.post("/specialists/settings/get-schedule-tracking", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getScheduleTracking", error);
      throw error;
    }
  }

  async getTypesChargesFinancial(params) {
    try {
      const { data } = await amgApi.post("/specialists/settings/get-types-charges-financial", params);
      return data;
    } catch (error) {
      console.log("Something went wrong on getTypesChargesFinancial", error);
      throw error;
    }
  }

  async storeCommission(params) {
    try {
      const { data } = await amgApi.post("/specialists/financial-approval/settings/store", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCommissionTracking(params) {
    try {
      const { data } = await amgApi.post("/specialists/financial-approval/settings/services/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async cancelCommission(params) {
    try {
      const { data } = await amgApi.post("/specialists/financial-approval/settings/services/cancel-commission", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new SettingsServiceSp();
