export default [
  {
    path: "inquiries",
    name: "quality-inquiries",
    component: () =>
      import(/* webpackChunkName: "ManaCommissions" */ "@/views/commons/components/claims_v2/Inquiries.vue"),
    redirect: { name: "quality-claim-inquiry-pending" },
    children: [
      {
        path: "pendings",
        name: "quality-claim-inquiry-pending",
        component: () =>
          import(/* webpackChunkName: "CrmMyList" */ "@/views/commons/components/claims_v2/views/claim-inquiries/views/InquiriesPending.vue"),
        meta: {
          pageTitle: "Inquiries",
          route: "quality",
          status: "pending",
          breadcrumb: [
            {
              text: "Pendings",
              active: true,
            },
          ],
        },
      },
      {
        path: "solveds",
        name: "quality-claim-inquiry-solved",
        component: () => import(/* webpackChunkName: "CrmMyList" */ "@/views/commons/components/claims_v2/views/claim-inquiries/views/InquirySolveds.vue"),
        meta: {
          pageTitle: "Inquiries",
          route: "quality",
          status: "solved",
          breadcrumb: [
            {
              text: "Solveds",
              active: true,
            },
          ],
        },
      }
    ],
  }



]