<template>
  <b-row>
    <b-col>
      <b-card
        header-text-variant="white"
        header-bg-variant="info"
        header-class="p-0"
        body-class="pt-1 card-body-border"
      >
        <template #header>
          <div class="pl-1 pt-1">
            <p class="font-weight-bolder">Incomes</p>
          </div>
        </template>
        <validation-observer ref="formIncomes">
          <b-row>
            <b-col md="4">
              <validation-provider
                rules="validate-amount-zero"
                v-slot="{ errors }"
              >
                <label>Gross Income</label>
                <money
                  id="gross-income"
                  v-model="incomes.gross_income"
                  v-bind="vMoney"
                  :disabled="readOnly"
                  class="form-control"
                  maxlength="12"
                />
                <span style="color: red">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>

            <b-col md="4">
              <validation-provider
                rules="validate-amount-zero"
                v-slot="{ errors }"
              >
                <label>Tax Deduction</label>
                <money
                  id="additiona-m-net-income"
                  v-model="incomes.tax_deduction"
                  v-bind="vMoney"
                  :disabled="readOnly"
                  class="form-control"
                />
                <span style="color: red">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <label>Net Income</label>
              <money
                id="additiona-m-net-income"
                v-model="incomes.net_income"
                v-bind="vMoney"
                :disabled="true"
                class="form-control"
              />
            </b-col>

            <b-col cols="4" class="mt-1">
              <label>Additional monthly net income</label>
              <money
                id="additiona-m-net-income"
                v-model="incomes.adicional_monthly"
                v-bind="vMoney"
                :disabled="readOnly"
                class="form-control"
              />
            </b-col>
            <b-col md="4" class="mt-1">
              <label>Co-applicant's monthly net income</label>
              <money
                id="additiona-m-net-income"
                v-model="incomes.c_applicant_monthly"
                v-bind="vMoney"
                :disabled="readOnly"
                class="form-control"
              />
            </b-col>
            <b-col md="4" class="d-flex align-items-end">
              <b-input-group prepend="Monthly Net Income">
                <div
                  class="form-control d-flex align-items-center justify-content-center bg-transparent border"
                >
                  $ {{ totalMonthlyNetIncome | currencyZero }}
                </div>
              </b-input-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    incomes: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },

    totalMonthlyNetIncome() {
      return (
        this.incomes.c_applicant_monthly +
        this.incomes.adicional_monthly +
        this.incomes.net_income
      );
    },
  },

  data() {
    return {
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    async validateForm() {
      const isValidated = await this.$refs.formIncomes.validate();
      return isValidated;
    },
  },

  watch: {
    "incomes.gross_income"(val) {
      this.incomes.net_income = val - this.incomes.tax_deduction;
    },
    "incomes.tax_deduction"(val) {
      this.incomes.net_income = this.incomes.gross_income - val;
    },
  },
};
</script>