import { amgApi } from "@/service/axios"

class RegisterPaymentService {
  async getAdministrationAutomaticPayments(params) {
    try {
      const data = await amgApi.post(
        "/administration/register-payments/get-automatic-payments",
        params
      )
      return data
    } catch (error) {
      console.log(
        "Something went wrong on getAdministrationAutomaticPayments:",
        error
      )
      throw error
    }
  }
  async getHistoryImports(params) {
    try {
      const data = await amgApi.post(
        "/administration/register-payments/get-history-imports",
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getHistoryImports:", error)
      throw error
    }
  }
  async getHistoryImportDetail(params) {
    try {
      const data = await amgApi.post(
        "/administration/register-payments/get-history-import-detail",
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getHistoryImportDetail:", error)
      throw error
    }
  }
  async getCard(params) {
    try {
      const data = await amgApi.post(
        "/administration/register-payments/get-manual-payment-cards",
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getCard:", error)
      throw error
    }
  }
  async getAllPaymentMails(params) {
    try {
      const data = await amgApi.post(
        "/crm/payment/get-all-payment-mails",
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getAllPaymentMails:", error)
      throw error
    }
  }
  async getAllPaymentCashByUser(params) {
    try {
      const data = await amgApi.post(
        "/crm/payment/get-payment-cash-by-user",
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getAllPaymentCashByUser:", error)
      throw error
    }
  }
  async getTrackingRegisterById(params) {
    try {
      const data = await amgApi.post(
        "/crm/payment/get-tracking-register-by-id",
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getTrackingRegisterById:", error)
      throw error
    }
  }
  async getUsersModuleByRoleUsa(params) {
    try {
      const data = await amgApi.post(
        `/commons/administration/get-users-module-by-role-usa/${params.role_id}`,
        params.body
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getUsersModuleByRoleUsa:", error)
      throw error
    }
  }
  async getPaymentMailById(params) {
    try {
      const data = await amgApi.post(
        `/crm/payment/get-payment-mail-by-id`,
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getPaymentMailById:", error)
      throw error
    }
  }
  async getAllPaymentPending(params) {
    try {
      const data = await amgApi.post(
        `/crm/payment/getAll-payment-pending`,
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getAllPaymentPending:", error)
      throw error
    }
  }
  async updateRelation(params) {
    try {
      const data = await amgApi.post(
        `/crm/payment/update-lead-payment-match-payment`,
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on updateRelation:", error)
      throw error
    }
  }
  async upairRelation(params) {
    try {
      const data = await amgApi.post(
        `/crm/payment/update-lead-payment-unpair`,
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on upairRelation:", error)
      throw error
    }
  }
  async disapprovePaymentMailOfRegisterClient(params) {
    try {
      const data = await amgApi.post(
        `/crm/payment/disapprove-payment-mail-of-register-client`,
        params
      )
      return data
    } catch (error) {
      console.log(
        "Something went wrong on disapprovePaymentMailOfRegisterClient:",
        error
      )
      throw error
    }
  }
  async approveLeadPaymentMail(params) {
    try {
      const data = await amgApi.post(
        `/crm/payment/approve-lead-payment-mail`,
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on approveLeadPaymentMail:", error)
      throw error
    }
  }
  async approveClientPaymentMail(params) {
    try {
      const data = await amgApi.post(
        `/crm/payment/approve-payment-mail-of-register-client`,
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on approveClientPaymentMail:", error)
      throw error
    }
  }
  async getAllMerchants() {
    try {
      const data = await amgApi.get(`/commons/administration/get-all-merchants`)
      return data
    } catch (error) {
      console.log("Something went wrong on getAllMerchants:", error)
      throw error
    }
  }
  async importAutomaticPayments(params) {
    try {
      const data = await amgApi.post(
        `/administration/register-payment/import-automatic-payments`,
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      return data
    } catch (error) {
      console.log("Something went wrong on importAutomaticPayments:", error)
      throw error
    }
  }
  async getExcelDetail(params) {
    try {
      const data = await amgApi.post(
        `/administration/register-payment/get-excel-detail`,
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getExcelDetail:", error)
      throw error
    }
  }
  async processImport(params) {
    try {
      const data = await amgApi.post(
        `/administration/register-payment/process-import`,
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on processImport:", error)
      throw error
    }
  }
}

export default new RegisterPaymentService()
