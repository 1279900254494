export default {
  path: "commissions",
  name: "paragon-commissions",
  component: () => import(/* webpackChunkName: "PRCommissions" */ "./index.vue"),
  meta: {
    pageTitle: "Commissions",
    breadcrumb: [
      {
        text: "Commissions",
      },
    ],
  },
  /*children: [
        {
            path: "", 
            name: "paragon-payments", 
            component: () => import("@/views/commons/components/payments/views/components/PaymentsClientsGrid.vue"),
            meta: {
                pageTitle: "Payments",
                searchModule: 9,
                breadcrumb: [
                    {
                        text: "Paragon",
                    },
                ],
            }
        }
    ]*/
};
