var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"md","header-class":"p-0","header-bg-variant":"transparent","scrollable":"","modal-class":"custom-modal-amg"},on:{"hide":function($event){return _vm.hideModal(false)}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('header-modal-notes',{attrs:{"program":"Credit Experts","info":_vm.dataForHeader},on:{"close":function($event){return _vm.hideModal(false)}}})]},proxy:true},{key:"modal-footer",fn:function(){return [(_vm.showButtonSave)?_c('button-save',{on:{"click":_vm.saveNotesIncomplete}}):_vm._e(),(_vm.showButtonSave)?_c('button-save-and-complete',{on:{"click":_vm.saveNotesCompleted}}):_vm._e(),(_vm.showButtonUpdate  && _vm.noteInfo.editModal)?_c('button-update',{on:{"click":_vm.updateNotesCompleted}}):_vm._e()]},proxy:true}]),model:{value:(_vm.modalUp),callback:function ($$v) {_vm.modalUp=$$v},expression:"modalUp"}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"identificationNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type of Identification Number","label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.identificationNumber.options},model:{value:(_vm.note.identificationNumber.value),callback:function ($$v) {_vm.$set(_vm.note.identificationNumber, "value", $$v)},expression:"note.identificationNumber.value"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"typeOfAgreement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-class":"font-weight-bolder","label":"Type of Agreement"}},[_c('b-form-radio-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.typeOfAgreement.options},model:{value:(_vm.note.typeOfAgreement.value),callback:function ($$v) {_vm.$set(_vm.note.typeOfAgreement, "value", $$v)},expression:"note.typeOfAgreement.value"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"workStatus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Work Status","label-class":"font-weight-bolder"}},[_c('v-select',{class:{ 'border-danger': errors[0] },attrs:{"label":"label","reduce":function (value) { return value.value; },"options":_vm.note.workStatus.options},model:{value:(_vm.note.workStatus.value),callback:function ($$v) {_vm.$set(_vm.note.workStatus, "value", $$v)},expression:"note.workStatus.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"maritalStatus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Marital Status","label-class":"font-weight-bolder"}},[_c('v-select',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.maritalStatus.options,"label":"text","reduce":function (value) { return value.value; }},model:{value:(_vm.note.maritalStatus.value),callback:function ($$v) {_vm.$set(_vm.note.maritalStatus, "value", $$v)},expression:"note.maritalStatus.value"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"contactSchedule"}},[_c('b-form-group',{attrs:{"label":"Contact Schedule","label-class":"font-weight-bolder"}},[_c('b-form-input',{attrs:{"disabled":true},model:{value:(_vm.note.contactSchedule.value),callback:function ($$v) {_vm.$set(_vm.note.contactSchedule, "value", $$v)},expression:"note.contactSchedule.value"}})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-col',[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"typeOfClient","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-class":"font-weight-bolder","label":"Type of Client"}},[_c('b-form-radio-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.typeOfClients.options},model:{value:(_vm.note.typeOfClients.value),callback:function ($$v) {_vm.$set(_vm.note.typeOfClients, "value", $$v)},expression:"note.typeOfClients.value"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"typeOfDevice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-class":"font-weight-bolder","label":"Type of Device"}},[_c('b-form-radio-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.typeOfDevice.options},model:{value:(_vm.note.typeOfDevice.value),callback:function ($$v) {_vm.$set(_vm.note.typeOfDevice, "value", $$v)},expression:"note.typeOfDevice.value"}})],1)]}}])})],1),(_vm.note.typeOfClients.value == 2)?_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-class":"font-weight-bolder","label":"Is urgent?"}},[_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"switch":""},on:{"change":_vm.changeUrgent},model:{value:(_vm.note.isUrgent),callback:function ($$v) {_vm.$set(_vm.note, "isUrgent", $$v)},expression:"note.isUrgent"}})],1)],1):_vm._e()],1)],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"contactDate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-class":"font-weight-bolder","label-for":"input-horizontal","label":"Day:"}},[_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date",class:{ 'border-danger': errors[0] },attrs:{"id":"contactDate","format":'MM/dd/yyyy'},on:{"change":_vm.onChangeDate},model:{value:(_vm.note.contactDate.value),callback:function ($$v) {_vm.$set(_vm.note.contactDate, "value", $$v)},expression:"note.contactDate.value"}})],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"contactHour","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"id":"contactHour","label-class":"font-weight-bolder","label-for":"input-horizontal","label":"To:"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v("Hour:")]),_c('tabler-icon',{staticClass:"text-warning cursor-pointer",attrs:{"id":'hours-target',"size":"15","icon":"QuestionMarkIcon"}}),(_vm.hoursToSee)?_c('b-tooltip',{attrs:{"custom-class":"ga","target":'hours-target',"triggers":"hover","placement":"top"}},[_c('div',[_c('h5',{staticClass:"font-weight-bolder"},[_vm._v("Office Hours")]),_vm._l((_vm.hoursToSee),function(item,i){return _c('span',{key:i},[_c('span',{staticClass:"font-weight-bolder text-justify",staticStyle:{"max-width":"1px !important"}},[_vm._v(_vm._s(item.day)+" ")]),_c('span',{staticClass:"text-justify",staticStyle:{"max-width":"10px !important"}},[_vm._v(" "+_vm._s(": From: " + (item.from ? item.from : "00:00") + " To: " + (item.to ? item.to : "23:59")))]),_c('br')])})],2)]):_vm._e()],1)]},proxy:true}],null,true)},[_c('kendo-timepicker',{staticClass:"w-100 rounded bg-transparent k-picker-custom",class:{ 'border-danger': errors[0] },staticStyle:{"height":"2.73rem"},attrs:{"format":'HH:mm',"minutes-step":"30","placeholder":"Select hour"},on:{"change":_vm.onChangeHour},model:{value:(_vm.note.contactHour.value),callback:function ($$v) {_vm.$set(_vm.note.contactHour, "value", $$v)},expression:"note.contactHour.value"}})],1)]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"emergencyContact","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Emergency Contact","label-class":"font-weight-bolder"}},[_c('b-form-input',{class:{ 'border-danger': errors[0] },model:{value:(_vm.note.emergencyContact.value),callback:function ($$v) {_vm.$set(_vm.note.emergencyContact, "value", $$v)},expression:"note.emergencyContact.value"}})],1)]}}])})],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Origin Country","label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"clearable":false,"disabled":_vm.disabled,"label":"name","reduce":function (value) { return value.id; },"options":_vm.note.country.options},model:{value:(_vm.note.country.value),callback:function ($$v) {_vm.$set(_vm.note.country, "value", $$v)},expression:"note.country.value"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"goals","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Goals","label-class":"font-weight-bolder"}},[_c('b-form-checkbox-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.goals.options},model:{value:(_vm.note.goals.value),callback:function ($$v) {_vm.$set(_vm.note.goals, "value", $$v)},expression:"note.goals.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"pending","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pending","label-class":"font-weight-bolder"}},[_c('b-form-checkbox-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.pending.options},model:{value:(_vm.note.pending.value),callback:function ($$v) {_vm.$set(_vm.note.pending, "value", $$v)},expression:"note.pending.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-class":"font-weight-bolder","label":"Incoveniences"}},[_c('b-row',[_c('b-col',[_c('b-table',{staticClass:"font-small-2",attrs:{"small":"","items":_vm.note.incoveniences.mid1}})],1),_c('b-col',[_c('b-table',{staticClass:"font-small-2",attrs:{"small":"","items":_vm.note.incoveniences.mid2}})],1)],1),(_vm.note.fileName != null && _vm.note.fileName != '')?_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label-class":"font-weight-bolder","label":"Attach Call"}},[_c('b-input-group',[_c('b-input-group-prepend',[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"info","disabled":_vm.disabledFile,"title":"Delete File"},on:{"click":_vm.deleteAudio}},[_c('feather-icon',{staticClass:"text-white",attrs:{"icon":"Trash2Icon"}})],1)],1),(_vm.note.fileName)?_c('b-form-input',{staticClass:"bg-transparent text-info",attrs:{"type":"text","disabled":""},model:{value:(_vm.note.fileName),callback:function ($$v) {_vm.$set(_vm.note, "fileName", $$v)},expression:"note.fileName"}}):_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","browse-text":"Audio","accept":"audio/*","disabled":_vm.disabledFile},model:{value:(_vm.audioCall),callback:function ($$v) {_vm.audioCall=$$v},expression:"audioCall"}})],1)],1)],1),(_vm.note.fileAudio)?_c('b-col',{attrs:{"lg":"6"}},[_c('audio',{staticClass:"mt-1 w-100",attrs:{"src":_vm.note.fileAudio,"controls":"","type":"audio/mp3"}})]):_vm._e()],1):_vm._e(),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"rules":"required","name":"incoveniences"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.incoveniences.value),callback:function ($$v) {_vm.$set(_vm.note.incoveniences, "value", $$v)},expression:"note.incoveniences.value"}})]}}])})],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"information","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Information","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.information.value),callback:function ($$v) {_vm.$set(_vm.note.information, "value", $$v)},expression:"note.information.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Recomendations","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"recomendationCheckbox","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.recomendations.options,"plain":""},model:{value:(_vm.note.recomendations.selectedsOptions),callback:function ($$v) {_vm.$set(_vm.note.recomendations, "selectedsOptions", $$v)},expression:"note.recomendations.selectedsOptions"}})]}}])}),_c('validation-provider',{attrs:{"name":"recomendationTextArea","rules":_vm.isSelectedOthersOnRecomendations ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{staticClass:"mt-1",class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.recomendations.value),callback:function ($$v) {_vm.$set(_vm.note.recomendations, "value", $$v)},expression:"note.recomendations.value"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"suggestion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Suggestion","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.suggestion.value),callback:function ($$v) {_vm.$set(_vm.note.suggestion, "value", $$v)},expression:"note.suggestion.value"}})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }