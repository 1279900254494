export default {
    namespaced: true,
    state: {
      S_COUNTER_CLIENTS_MAIN: 0,
      S_COUNTER_CLIENTS_ARCHIVED: 0,
      S_COUNTER_CLIENTS_CALLS_ROUND: 0,
    },
    mutations: {
      M_SET_COUNTER_CLIENTS(state, payload = { Main: 0, Archived: 0, CallsRound: 0 }) {
        state.S_COUNTER_CLIENTS_MAIN = payload.Main
        state.S_COUNTER_CLIENTS_ARCHIVED = payload.Archived
        state.S_COUNTER_CLIENTS_CALLS_ROUND = payload.CallsRound
      },
    },
    actions: {
      A_SET_COUNTERS_TABS_CLIENTS({ commit }, payload = {
        Main: 0, Archived: 0, CallsRound: 0
      }) {
        commit('M_SET_COUNTER_CLIENTS', payload)
      },
    },
}