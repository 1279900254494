export default [
    {
      path: "mg-request-client-status",
      name: "mg-request-client-status",
      redirect : {name:'mg-request-client-status-pending'},
      component: () => import(/* webpackChunkName: "RequestClientStatus" */ "@/views/management/views/request-client-status/RequestClientStatus.vue"),
      meta: {
        pageTitle: "Request Client Status",
        breadcrumb: [
          {
            text: "Request Client Status",
            active: true,
          },
        ], 
      },
      children: [
        {
          path: "pending",
          name: "mg-request-client-status-pending",
          component: () => import(/* webpackChunkName: "RequestClientStatusList" */ "@/views/management/views/request-client-status/components/RequestClientStatusList.vue"),
          meta: { 
            pageTitle: "Request Client Status",
            breadcrumb: [
              {
                text: "Pending",
                active: true,
              },
            ], 
            type : 1,
            RequestClientStatusPending:'mg-request-client-status-pending',
            RequestClientStatusRejected:'mg-request-client-status-rejected',
            RequestClientStatusAccepted:'mg-request-client-status-accepted',
          },
        },
        {
            path: "rejected",
            name: "mg-request-client-status-rejected",
            component: () => import(/* webpackChunkName: "RequestClientStatusList" */ "@/views/management/views/request-client-status/components/RequestClientStatusList.vue"),
            meta: { 
              pageTitle: "Request Client Status",
              breadcrumb: [
                {
                  text: "Rejected",
                  active: true,
                },
              ], 
              type : 2,
              RequestClientStatusPending:'mg-request-client-status-pending',
              RequestClientStatusRejected:'mg-request-client-status-rejected',
              RequestClientStatusAccepted:'mg-request-client-status-accepted',
            },
          },
          {
            path: "accepted",
            name: "mg-request-client-status-accepted",
            component: () => import(/* webpackChunkName: "RequestClientStatusList" */ "@/views/management/views/request-client-status/components/RequestClientStatusList.vue"),
            meta: { 
              pageTitle: "Request Client Status",
              breadcrumb: [
                {
                  text: "Accepted",
                  active: true,
                },
              ], 
              type : 3,
              RequestClientStatusPending:'mg-request-client-status-pending',
              RequestClientStatusRejected:'mg-request-client-status-rejected',
              RequestClientStatusAccepted:'mg-request-client-status-accepted',
            },
          },
      ],
    },
  ]