import store from "@/store"

import Vue from "vue";
import router from "@/router/index";

const NewOtherPaymentsNotification = () => {
    window.socket.bind("create-other-payment", async (data) => {

        const { modul_id,user_id } = store.state.auth.currentUser
        
        const moduleId = modul_id
        // console.log("🚀 ~ file: other-payments.socket.js:8 ~ window.socket.bind ~ sessionId:", sessionId)
        // console.log('hola',store.state.auth.currentUser);
        if (data.module_id == moduleId) {
            const result = await Vue.swal.fire({
                icon: "success",
                html: data.message, 
                showConfirmButton: true,
            })
            if (result.value) {
                await window.amgApi.post('/commons/close-all-swal', {to_id : user_id })
            }
        }
    });
}
const OtherPaymentsVerifiedNotification = () => {
    window.socket.bind("other-payments-verified", async (data) => {
        
        const { modul_id,user_id } = store.state.auth.currentUser

        const moduleId = modul_id
        // console.log("🚀 ~ file: other-payments.socket.js:8 ~ window.socket.bind ~ sessionId:", sessionId)
        // console.log('hola',store.state.auth.currentUser);
        if (data.module_id == moduleId) {
            const result = await Vue.swal.fire({
                icon: "success",
                html: data.message, //`You have a new lead in the closed pending status!<br><h2 style="font-weight: 600;">${data.lead_name}</h2>`,
                showConfirmButton: true,
            })
            if (result.value) {
                await window.amgApi.post('/commons/close-all-swal', {to_id: user_id})
            }
        }
    });
}

const OtherPaymentsApproveOrDecline = () => {
    window.socket.bind("approve-or-decline-other-payment", async (data) => {
        
        const { user_id } = store.state.auth.currentUser

        if (data.to_id.some( user => user.id == user_id) ) {
            const result = await Vue.swal.fire({
                icon: data.icon,
                html: data.message, 
                showConfirmButton: true,
            })
            if (result.value) {
                await window.amgApi.post('/commons/close-all-swal', {to_id: user_id})
            }
        }
    });
}

export { NewOtherPaymentsNotification,OtherPaymentsVerifiedNotification,OtherPaymentsApproveOrDecline }