import leadsRoute from "@/views/credit-experts/views/leads/leads.router.js";
import clientRoute from "@/views/credit-experts/views/clients/clients.router.js";
import zeroPayment from "@/views/credit-experts/views/zero-payment/router/index.js";
import applicationsRouter from "@/views/credit-experts/views/applications/applications.router";
import saleMadeRouter from "@/views/credit-experts/views/sales-made/sales-made.router";
import ncrRealtor from "@/views/credit-experts/views/ncr-realtor/router/index";
import ncrProgram from "@/views/credit-experts/views/ncr-programs/ncr-programs.router";
import RequestNcrRouter from "@/views/credit-experts/views/request-ncr/request-ncr.router";
import RequestWorkplanRouter from "@/views/credit-experts/views/request-workplan/request-workplan.router.js";
import letterRoute from "@/views/credit-experts/views/letters/router/letters.router";
import directRoute from "@/views/credit-experts/views/direct/router/direct.router";
import disputeResults from "@/views/credit-experts/views/dispute-results/router/index";
import callRoundsRoute from "../views/call-rounds/call-rounds.router";
import reportsRoute from "@/views/credit-experts/views/reports/reports.router";
import paymentsRoute from "@/views/credit-experts/views/payments/payments.router";
import trackingListRoute from "@/views/credit-experts/views/tracking-list/router/tracking-list.router";
import commissionRoute from "@/views/credit-experts/views/commission/commission.router";
// import chargeBackRoute from "@/views/credit-experts/views/charge-back/charge-back.router"
import calendarRoute from "@/views/credit-experts/views/calendar/calendar.router";
import helpdeskRoute from "@/views/credit-experts/views/helpdesk/helpdesk.route";
import fileManagerRoute from "@/views/credit-experts/views/file-manager/file-manager.router";
import loansRoute from "@/views/credit-experts/views/loans/loans.router";
import schedulesRouter from "@/views/credit-experts/views/schedules/schedules.router";
import performanceRouter from "@/views/credit-experts/views/advisor-performance/performance.router";
import ProductRequestRouter from "@/views/credit-experts/views/request/request.router.js";
import claimsRouter from "@/views/credit-experts/views/claims-v2/claims.router";
import DashboardRouter from "@/views/credit-experts/views/dashboard/dashboard.router";
import StatusChangeRouter from "@/views/credit-experts/views/status-change/status-change.router";
import NotificationsRouter from "@/views/credit-experts/views/notification-app/router/index";
import migrationsRouter from "@/views/credit-experts/views/migrations/router/migrations.router";
import OtherPaymentsRouter from "@/views/credit-experts/views/other-payments/other-payments.router";
import CeAttendanceRouter from "@/views/credit-experts/views/attendance/ce-attendance.router";
import EmployeeClaimsRouter from "@/views/credit-experts/views/employee-claims/router/employee-claims.router";
import CreditExpertsMain from "@/views/credit-experts/views/CreditExpertsMain.vue";
//import CreditExpertsProjectsRouter from "@/views/credit-experts/views/projects/credit-experts-projects.router";
import CallLogRouter from "@/views/credit-experts/views/ring-central/call-log/call-log.router.js";
import CourtInfoRouter from "@/views/credit-experts/views/cour-info/router/ds-court-info.router";

import DashboardCalls from "@/views/credit-experts/views/ring-central/dashboard-calls/dashboard.router.js";

import MOFRouter from "@/views/credit-experts/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/credit-experts/views/recruitment-process/credit-experts-recruitment_process.router";
import CustomerTicketsRouter from "@/views/credit-experts/views/customer-tickets/credit-experts-customer-tickets.router";
import SendingReportRouter from "@/views/credit-experts/views/sending-report/sending-report.router.js";
import AppointmentRouter from "@/views/credit-experts/views/appointment/appointment.router";
import PotencialAppointmentRouter from "@/views/credit-experts/views/potencial-appointment/potencial-appointment.route";
import OtherServicesRouter from "@/views/credit-experts/views/other-services/other-services.router.js";
import CommissionsForSpecialistsRouter from "@/views/credit-experts/views/commission-specialists/commissions-specialists.router";

const routes = [
  {
    path: "/creditexperts",
    redirect: { name: "credit-experts-dash" },
    meta: {
      module: 6,
      program: 3,
    },
    component: CreditExpertsMain,
    children: [
      ...leadsRoute,
      ...clientRoute,
      ...zeroPayment,
      ...applicationsRouter,
      // ...saleMadeRouter,
      ...ncrRealtor,
      ...ncrProgram,
      ...RequestNcrRouter,
      ...RequestWorkplanRouter,
      ...letterRoute,
      ...directRoute,
      ...disputeResults,
      ...commissionRoute,
      ...callRoundsRoute,
      ...trackingListRoute,
      ...reportsRoute,
      ...paymentsRoute,
      // ...chargeBackRoute,
      fileManagerRoute,
      ...calendarRoute,
      ...performanceRouter,
      ...schedulesRouter,
      ...claimsRouter,
      ...loansRoute,
      helpdeskRoute,
      ...NotificationsRouter,
      ...ProductRequestRouter,
      ...DashboardRouter,
      ...StatusChangeRouter,
      ...migrationsRouter,
      ...CeAttendanceRouter,
      ...OtherPaymentsRouter,
      ...EmployeeClaimsRouter,
      //...CreditExpertsProjectsRouter,
      ...CallLogRouter,
      ...CourtInfoRouter,
      ...DashboardCalls,

      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...CustomerTicketsRouter,
      ...SendingReportRouter,
      ...AppointmentRouter,
      ...PotencialAppointmentRouter,
      ...OtherServicesRouter,
      ...CommissionsForSpecialistsRouter,
    ],
  },
];

export default routes;
