<template>
  <b-dropdown
    variant="link"
    no-caret
    :right="$store.state.appConfig.isRTL"
    boundary="viewport"
  >
    <template #button-content>
      <feather-icon
        icon="MoreVerticalIcon"
        size="16"
        class="align-middle text-body"
      />
    </template>
    <template v-if="!canDerivate">
      <!-- Add Service -->
      <b-dropdown-item
        v-if="options.includes('addService')"
        variant="warning"
        @click="onRowAddService(rowData)"
      >
        <feather-icon icon="FilePlusIcon" />
        <span class="align-middle ml-50">Add service</span>
      </b-dropdown-item>

      <!-- Validation sales / lead / tab success  -->
      <div v-if="rowData.program_ce">
        <b-dropdown-item
          v-if="options.includes('changeService')"
          :variant="$route.matched[0].meta.module == 26 ? 'warning' : 'success'"
          @click="onRowChangeService(rowData)"
        >
          <feather-icon icon="EditIcon" />
          <span class="align-middle ml-50">Change service</span>
        </b-dropdown-item>
      </div>
      <b-dropdown-item
        v-if="options.includes('tracing')"
        variant="warning"
        @click="onRowTracing(rowData)"
      >
        <feather-icon icon="TrendingUpIcon" />
        <span class="align-middle ml-50">Tracing</span>
      </b-dropdown-item>
      <!-- Sales Recycled -->
      <b-dropdown-item
        v-if="options.includes('recycled')"
        variant="danger"
        @click="onRowRecycled(rowData)"
      >
        <feather-icon icon="TrashIcon" />
        <span class="align-middle ml-50">Recycled </span>
      </b-dropdown-item>
    </template>
    <!-- sendSMS -->
    <b-dropdown-item
      v-if="options.includes('sendSMS')"
      v-b-tooltip.hover.left="'Send SMS'"
      v-b-modal.modal-primary
      @click="onRowSms(rowData)"
    >
      <feather-icon icon="MessageSquareIcon" />
      <span class="align-middle ml-50">SMS</span>
    </b-dropdown-item>

    <!-- historySMS -->
    <b-dropdown-item
      v-if="options.includes('historySMS')"
      v-b-tooltip.hover.left="'History of SMS'"
      v-b-modal.modal-primary
      @click="onRowHistorySms(rowData)"
    >
      <feather-icon icon="RotateCcwIcon" />
      <span class="align-middle ml-50">History of SMS</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    VueGoogleAutocomplete,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    rowData: {
      required: true,
    },
    rowIndex: {
      type: Number,
      default: null,
    },
    fromBoost: {
      type: Boolean,
      default: false,
    },
    statusCallRound: {
      type: Number,
    },
    canDerivate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
  },
  methods: {
    onRowSms(item) {
      this.$emit("modalSmsOpen", item);
    },
    onRowHistorySms(item) {
      this.$emit("modalHistorySmsOpen", item);
    },
    onRowAddService(item) {
      this.$emit("modalAddService", item);
    },
    onRowChangeService(item) {
      this.$emit("modalChangeService", item);
    },

    onRowTracing(item) {
      this.$emit("onTracing", item, 2); // 2 Tracing
    },
    onRowRecycled(item) {
      this.$emit("onRecycled", item, 4); // 4 Recycled
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.sidebar-xl {
  width: 90rem;
}
</style>
