<template>
  <div>
    <filter-slot
      :filter="filters"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :total-rows="totalRows"
      :filter-principal="filterPrincipal"
      :double-paginate="false"
      @reload="getCharges"
      @onChangeCurrentPage="getCharges"
    >
      <template #table>
        <div class="container-charge-back-table-ce-ing" v-if="!loading">
          <b-row class="row-item header" style="font-size: 15px">
            <b-col cols="1" class="row-header custom-cols">Transaction</b-col>
            <b-col cols="2" class="row-header custom-cols">
              <div class="text-center w-100">Merchant</div>
            </b-col>
            <b-col cols="3" class="row-header custom-cols">Reason Code</b-col>
            <b-col cols="2" class="row-header custom-cols">Created Date</b-col>
            <b-col cols="1" class="row-header custom-cols">Penalty</b-col>
            <b-col cols="2" class="row-header custom-cols">Solution</b-col>
          </b-row>

          <b-row
            class="row-item"
            v-for="(item, index) in items"
            :key="index + '_item'"
            :class="item.paid == 5 ? 'detail-2' : 'detail'"
          >
            <b-col cols="1" class="custom-cols">
              <div class="">
                <div>{{ item.t_transaction_id }}</div>
                <div
                  :class="item.paid == 5 ? 'text-positive' : 'text-negative'"
                >
                  $ {{ item.amount }}
                </div>
                <div>{{ item.transaction_date | myGlobal }}</div>
              </div>
            </b-col>
            <b-col cols="2" class="custom-cols">
              <div class="text-center w-100">
                <div class="text-primary">{{ item.merchant_name }}</div>
                <div class="">AUTH CODE: {{ item.auth_code }}</div>
              </div>
            </b-col>
            <b-col cols="3" class="custom-cols">
              <div class="">
                <span v-b-tooltip.hover :title="item.reason_code">
                  {{ item.reason_code }}</span
                >
                <feather-icon
                  icon="AlertCircleIcon"
                  class="text-primary cursor-pointer"
                  v-b-tooltip.hover
                  :title="item.reason_code_description"
                  style="margin: 0 0 4px 4px"
                ></feather-icon>
              </div>
            </b-col>
            <b-col cols="2" class="custom-cols">
              <div class="">
                <div>{{ item.user_name }}</div>
                <div>{{ item.created_at | myGlobal }}</div>
              </div>
            </b-col>
            <b-col cols="1" class="custom-cols">
              <div>
                <div>
                  <b-badge
                    :variant="
                      item.status_penalty == 1
                        ? 'light-success'
                        : item.status_penalty == 2
                        ? 'light-danger'
                        : 'light-warning'
                    "
                    >{{
                      item.status_penalty == 1
                        ? "ACCEPT"
                        : item.status_penalty == 2
                        ? "DENIED"
                        : "NOT ASSIGNED"
                    }}</b-badge
                  >
                </div>

                <div style="margin-top: 3px" v-if="item.status_penalty == 1" :class="showChargeDetail(2, item) ?  'text-negative' : 'text-positive'">
                  $ {{ item.amount_penalty | currencyZero }}
                </div>
              </div>
            </b-col>

            <b-col cols="2">
              <div class="d-flex align-items-center h-100">
                <b-badge variant="light-info" v-if="item.dispute">
                  DISPUTE
                </b-badge>
                <div v-else>
                  <b-badge
                    v-if="nameResponsable(item.responsable)"
                    :variant="colorResponsable(item.responsable)"
                  >
                    {{ nameResponsable(item.responsable) }}
                  </b-badge>
                </div>
              </div>
            </b-col>
            <b-col cols="1">
              <div
                class="h-100 d-flex align-items-center justify-content-between"
              >
                <feather-icon
                  icon="ListIcon"
                  class="text-primary cursor-pointer"
                  size="16"
                  v-b-tooltip
                  title="Tracking"
                  @click="modalShowFiles(item)"
                ></feather-icon>

                <feather-icon
                  :icon="item.show ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                  class="cursor-pointer"
                  size="23"
                  @click="openCollapse(index)"
                ></feather-icon>
              </div>
            </b-col>

            <b-col cols="12" class="pl-4">
              <b-collapse
                :visible="Boolean(item.show)"
                accordion="my-accordion"
                class="my-accordion"
              >
                <b-row
                  v-for="(detail, dindex) in item.detail_charges"
                  v-bind:key="dindex"
                  class="mt-1"
                >
                  <b-col
                    cols="12"
                    v-if="showChargeDetail(detail.type_charge_back_mod, item)"
                  >
                    <b-row class="header-accordion">
                      <b-col
                        cols="2"
                        :class="
                          detail.transaction_id
                            ? 'text-positive'
                            : 'text-negative'
                        "
                        >Transaction Detail</b-col
                      >
                      <!-- Datos -->
                      <b-col cols="3"></b-col>
                      <b-col cols="5"></b-col>
                      <b-col cols="2">
                        <div
                          v-if="
                            item.paid != 5 && detail.type_charge_back_mod != 4
                          "
                        >
                          Actions
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="12"
                    class="mt-1"
                    v-if="showChargeDetail(detail.type_charge_back_mod, item)"
                  >
                    <b-row>
                      <b-col cols="2">
                        <p>Type:</p>
                        <p>Amount:</p>
                      </b-col>
                      <b-col cols="4">
                        <p>{{ detail.charge }}</p>
                        <p>$ {{ detail.amount_penalty | currencyZero }}</p>
                      </b-col>
                      <b-col cols="4"></b-col>
                      <b-col cols="2">
                        <!-- Penalty -->
                        <div
                          v-if="detail.type_charge_back_mod == 2"
                          class="badge-container"
                          @click="modalPayment(item, detail, 2)"
                        >
                          <div class="badge-action">
                            <cash-icon
                              :height="17"
                              :width="17"
                              style="padding-right: 4px"
                            />
                            Pay
                          </div>
                        </div>

                        <div
                          v-if="detail.type_charge_back_mod == 1"
                          class="badge-container"
                          @click="modalPayment(item, detail, 1)"
                        >
                          <div
                            class="badge-action"
                            v-if="
                              (!readOnly &&
                                item.status_penalty == 1 &&
                                (item.paid == 4 || item.paid == 1)) ||
                              item.status_penalty == 2
                            "
                          >
                            <cash-icon
                              :height="17"
                              :width="17"
                              style="padding-right: 4px"
                            />
                            Pay
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>

          <div
            v-if="items.length == 0"
            class="text-center mt-2"
            style="font-size: 15px"
          >
            There are no records to show
          </div>
        </div>

        <div class="container-charge-table-ce-ing w-100" v-else>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
        </div>
      </template>
    </filter-slot>

    <charge-back-tracking-modal
      v-if="stateModalTracking"
      :chargeBackId="transaction_id"
      :leadName="leadName"
      @close="stateModalTracking = false"
    />

    <payment-modal
      v-if="statePaymentModal"
      @close="statePaymentModal = false"
      @reload="refreshComponents"
      :chargeBack="chargeBackData"
      :payment="typePayment"
      :type_responsible="typeResponsible"
    />
  </div>
</template>
  
  <script>
import { mapGetters, mapActions } from "vuex";
import DashboardService from "@/views/commons/components/clients/dashboard/options/pay-module/components/service/dashboard.service.js";
import ChargeBackTrackingModal from "@/views/commons/components/charge-back_v2/view/modal/ChargeBackTrackingModal.vue";
import CashIcon from "@/icons/Cash.vue";
import PaymentModal from "@/views/commons/components/charge-back_v2/view/modal/PaymentModal.vue";
export default {
  components: {
    ChargeBackTrackingModal,
    CashIcon,
    PaymentModal,
  },

  props: {
    clientAccountId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      items: [],
      filters: [],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Transaction ID",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: null,
      totalRows: 0,
      toPage: null,

      loading: true,

      transaction_id: null,
      leadName: "",
      stateModalTracking: false,
      chargeBackData: {},
      currentClient: "",
      typePayment: 1,
      statePaymentModal: false,
      typeResponsible: null,
    };
  },

  methods: {
    ...mapActions({
      SET_CURRENT_CLIENT: "DebtSolutionClients/A_GET_CLIENTS",
    }),

    async getCharges() {
      try {
        this.loading = true;

        const params = {
          client_account_id: this.clientAccountId,
          search_txt: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          current_page: this.paginate.currentPage,
        };

        const { data } = await DashboardService.getChargeBacksByClientAccount(
          params
        );

        this.startPage = data.from;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        this.items = data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },

    async refreshComponents() {
      this.$emit("refresh");
      await this.getCharges();
    },

    nameResponsable(name) {
      let refactor = {
        "remove chargeback": "REMOVE CHARGEBACK",
        client: "PAYMENT CLIENT",
        department: " DEPARTMENT",
      };
      return refactor[name];
    },
    colorResponsable(name) {
      let refactor = {
        "remove chargeback": "light-danger",
        client: "light-success",
        department: "light-primary",
      };
      return refactor[name];
    },

    modalShowFiles(item) {
      this.transaction_id = item.id;
      this.leadName = item.client_name;
      this.stateModalTracking = true;
    },

    openCollapse(index) {
      this.items.forEach((item, index2) => {
        if (index2 != index) {
          item.show = false;
        }
      });

      this.items[index].show = !this.items[index].show;
    },

    async modalPayment(item, detail, typePayment) {
      await this.addPreloader();
      let clientInfo = Object.assign({}, item, detail);
      this.chargeBackData = clientInfo;
      this.currentClient = clientInfo;
      this.typePayment = typePayment;
      this.typeResponsible = clientInfo.type_responsable;
      await this.SET_CURRENT_CLIENT({ id: this.clientAccountId });
      this.statePaymentModal = true;
      await this.removePreloader();
    },

    showChargeDetail(type, item) {
      if (type == 1) {
        const details = item.detail_charges;
        return !details.some((charge) => charge.type_charge_back_mod == 3);
      }

      if (type == 2) {
        return (
          (item.status_penalty == 1 && (item.paid == 3 || item.paid == 1)) ||
          item.cbdd_recovered == 2
        );
      }

      return true;
    },
  },
};
</script>
  
  <style lang="scss">
.container-charge-back-table-ce-ing {
  width: 100%;
  max-height: 640px;
  overflow-y: auto;
  padding: 0 20px 5px 20px;

  .text-positive {
    color: #04b400;
  }
  .partial-refund {
    color: #7751c0;
    margin-right: 3px;
  }

  .text-negative,
  .with-partial-refund {
    color: #e35403;
  }

  .with-partial-refund {
    text-decoration: line-through;
  }
  .header {
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #0090e7 !important;
    color: #ffffff !important;
  }

  .row-header {
    padding-left: 20px;
  }

  .row-item {
    min-height: 60px;
    margin-top: 10px !important;
    color: black;
    font-size: 13px;
    text-align: left;
    padding: 10px 0;

    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    background-color: #f5f5f5;

    .custom-cols {
      text-align: left;
      display: flex;
      justify-content: start;
      align-items: center;

      .type {
        color: #e35403;
        font-weight: 600;
      }

      .status {
        padding: 4px 15px;
        border-radius: 20px;
        font-weight: 600;
      }

      .status-null {
        background-color: #ffe0c3;
        color: #f17500;
      }

      .status-1 {
        color: #04b400;
        background-color: #c3ffe2;
      }

      .status-set {
        color: #d8d8d8;
      }

      .void {
        cursor: pointer;
        background-color: #ffd2d2;
        padding: 4px 15px;
        border-radius: 20px;
      }
    }

    .my-accordion {
      background-color: #f5f5f5;
      border-top: 1px solid black;
      margin: 10px 0;
      text-align: left;
      font-size: 16px;

      .header-accordion {
        font-weight: 600;
      }

      .badge-container {
        display: flex;
        // flex-direction: column;

        .badge-action {
          // margin-top: 5px;
          cursor: pointer;
          text-align: center;
          display: flex;
          align-items: center;
          margin-left: 5px;
          margin-right: 5px;
          border-radius: 20px;
          border: 1px solid #d9d9d9;
          padding: 8px 12px;
          color: black;
        }
      }
    }
  }

  .detail {
    border-left: 8px solid #e35403;
  }

  .detail-2 {
    border-left: 8px solid #23c577;
  }
}

.dark-layout {
  .container-charge-back-table-ce-ing {
    .row-item {
      color: #ffffff;

      background-color: #1d1d20;

      .my-accordion {
        background-color: #1d1d20;
        border-top: 1px solid #ffffff;

        .badge-container {
          .badge-action {
            border: 1px solid #d9d9d9;
            color: #ffffff;
          }
        }
      }
    }
  }
}

@media (max-width: 1450px) and (min-width: 407px) {
  .container-charge-table-ce-ing {
    padding: 0 15px;

    .row-item {
      min-height: 45px;
      margin-top: 10px !important;
      font-size: 13px;

      .custom-cols {
        .status,
        .pre-payment,
        .void {
          padding: 2px 10px;
        }
      }
    }

    .detail {
      border-left: 4px solid #e35403;
    }
  }
}

@media (max-width: 800px) and (min-width: 120px) {
  .container-charge-table-ce-ing {
    width: 800px;
    overflow-x: auto;
  }
}
</style>