var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{attrs:{"options":_vm.filteredOptions,"reduce":function (obj) { return obj.value; }},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
var icon = ref.icon;
var color = ref.color;
return [_c('feather-icon',{style:(_vm.styleIcon(color)),attrs:{"icon":icon,"size":"12"}}),_c('span',{staticStyle:{"margin-left":"0.8rem"}},[_vm._v(_vm._s(label))])]}},{key:"option",fn:function(ref){
var label = ref.label;
var icon = ref.icon;
var color = ref.color;
return [_c('feather-icon',{style:(_vm.styleIcon(color)),attrs:{"icon":icon,"size":"12"}}),_c('span',{staticStyle:{"margin-left":"0.8rem"}},[_vm._v(_vm._s(label))])]}}]),model:{value:(_vm.filters.model),callback:function ($$v) {_vm.$set(_vm.filters, "model", $$v)},expression:"filters.model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }