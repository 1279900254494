  <template>
  <div>
    <b-modal
      v-model="showModal"
      header-bg-variant="primary"
      size="xlg"
      no-close-on-backdrop
      @hidden="closeModal"
      :hide-footer="(!isPendingTab || moduleId !== 29)"
    >
      <template #modal-title>
        <h4 class="font-weight-bolder text-uppercase py-50 text-light">
          <feather-icon icon="FileIcon" class="mr-50" size="18" />
          RESULT OF ANALYSIS
        </h4>
      </template>
      <b-container no-fluid class="py-0">
        <b-row class="pt-2 m-0">
          <b-col cols="12" lg="6" class="my-50 px-0">
            <div
              class="p-1 bg-light-primary rounded d-flex justify-content-start align-items-center mr-0 mr-lg-1"
            >
              <strong
                class="d-flex justify-content-center align-items-center text-uppercase border-right-primary pr-1 mr-1"
              >
                <feather-icon icon="UserIcon" size="15" class="mr-50" />
                Lead Name
              </strong>
              <span class="text-uppercase"> {{ params.leadName }}</span>
            </div>
          </b-col>
          <b-col cols="12" lg="6" class="mt-50 px-0">
            <div
              class="p-1 bg-light-primary rounded d-flex justify-content-start align-items-center ml-0 ml-lg-1"
            >
              <strong
                class="d-inline-flex justify-content-center align-items-center w-auto text-uppercase border-right-primary pr-1 mr-1"
              >
                <feather-icon icon="UserIcon" size="15" class="mr-50" />
                CR Obtained by
              </strong>
              <span class="text-uppercase">
                {{ createdBy.name }} ({{ createdBy.date | myGlobalDay }})</span
              >
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3 mb-1 px-1">
          <!--DATA ACCOUNTS-->
          <accounts-remove-settlement
            ref="accounts-remove-settlement"
            :leadId="params.leadId"
            :auxIdClient="params.clientId"
            :ncrRequestId="params.ncrRequestId"
            :scoreId="params.scoreId"
            :isAnalyzed="params.is_analyzed"
            :editMode="isPendingTab"
            :openBySeller="openBySeller"
            :showTabs="showTabs"
            :typeView_="typeView"
            :withoutAppointment="withoutAppointment"
            :moduleIdNcr="params.moduleIdNcr"
            @disabledSaveButton="isDisabledButton"
            @responseData="responseDataNcr"
            @closeModal="closeModal"
            @refreshTable="refreshTable"
            @newEvent="newEvent"
            :inDahsboard="false"
          />
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button
          :disabled="disabledButton"
          variant="primary"
          @click="changeStatusNCR()"
        >
          <feather-icon icon="SaveIcon" size="18" />
          DONE
        </b-button>
        <b-button variant="secondary" @click="closeModal()">
          CANCEL
          <feather-icon icon="XCircleIcon" size="18" />
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import AccountsRemoveSettlement from "@/views/commons/components/request-ncr/views/components/AccountsRemoveSettlement.vue";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service";

export default {
  components: {
    AccountsRemoveSettlement,
  },
  props: {
    params: {
      type: Object,
      require: true,
      default: () => {},
    },
    openBySeller: {
      type: Boolean,
      default: false,
    },
    showTabs: {
      type: Boolean,
      default: true
    },
    typeView: {
      type: String,
      default: 'REMOVE'
    },
    withoutAppointment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: true,
      totalBalance: 0,
      totalHighCredit: 0,
      createdBy: {
        name: "",
        date: "",
      },
      disabledButton: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    today() {
      return moment().format("MM/DD/YYYY HH:mm");
    },
    isFinishTab() {
      return (this.$route.meta.subTabName == "finished");
    },
    isPendingTab() {
      return (this.$route.meta.subTabName == "pending");
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    isDisabledButton(bool){
      this.disabledButton = bool;
    },
    async changeStatusNCR() {
      const { isConfirmed } = await this.showConfirmSwal();
      if(!isConfirmed) return;
      this.$refs["accounts-remove-settlement"].changeStatusNcr();
    },
    closeModal() {
      this.$emit("closeModal");
    },
    responseDataNcr(response) {
      this.createdBy = response;
    },
    refreshTable() {
      this.$emit("refreshTable");
    },
    newEvent(data){
      this.$emit("newEvent", data);
    }
  },
};
</script>
<style scoped>
.label-value {
  width: 150px !important;
}
</style>
