

export default [
  {
    path: "letters",
    name: "administration-letters",
    redirect: { name: "administration-letters-in-process" },
    component: () => import(/* webpackChunkName: "CEDFinancialPendingPayments" */ "@/views/administration/views/letters/Letters.vue"),
    meta: {
      pageTitle: "Letters",
      breadcrumb: [
        {
          text: "Letters",
          active: true,
        },
      ],
      permittedRoles: [1, 2, 6],
      parentModule: 21,
    },
    children: [
      {
        path: "in-process",
        name: "administration-letters-in-process",
        component: () => import("@/views/administration/views/letters/components/Grid.vue"),
        meta: {
          pageTitle: "Letters",
          breadcrumb: [
            {
              text: "In process",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 6],
          inProcessTab: "administration-letters-in-process",
          pendingTab: "administration-letters-pending",
          returnedTab: "administration-letters-returned",
          completedTab: "administration-letters-completed",

        },
      },
      {
        path: "pending",
        component: () => import("@/views/administration/views/letters/components/Grid.vue"),
        name: "administration-letters-pending",
        meta: {
          pageTitle: "Letters",
          breadcrumb: [
            {
              text: "Pending",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 6],
          inProcessTab: "administration-letters-in-process",
          pendingTab: "administration-letters-pending",
          returnedTab: "administration-letters-returned",
          completedTab: "administration-letters-completed",

        },
      },
      {
        path: "returned",
        name: "administration-letters-returned",
        component: () => import("@/views/administration/views/letters/components/Grid.vue"),
        meta: {
          pageTitle: "Letters",
          breadcrumb: [
            {
              text: "Returned",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 6],
          inProcessTab: "administration-letters-in-process",
          pendingTab: "administration-letters-pending",
          returnedTab: "administration-letters-returned",
          completedTab: "administration-letters-completed",


        },
      },
      {
        path: "completed",
        component: () => import("@/views/administration/views/letters/components/Grid.vue"),
        name: "administration-letters-completed",
        meta: {
          pageTitle: "Letters",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 6],
          inProcessTab: "administration-letters-in-process",
          pendingTab: "administration-letters-pending",
          returnedTab: "administration-letters-returned",
          completedTab: "administration-letters-completed",

        },
      },

    ],
  },
  {
    path: "/dowloadfileround/:id",
    name: "document-route",
  },
];
