// import PaymentsClientsGrid from './components/PaymentsClientsGrid.vue'
export default [
  {
    path: "payments",
    name: "boost-credit-payment",
    redirect: { name: "boost-credit-payment-list-clients" },
    component: () =>
      import(
        /* webpackChunkName: "BCPayments" */ "@/views/commons/components/payments/views/components/PaymentsTab.vue"
      ),
    meta: {
      pageTitle: "Payments",
      permittedRoles: [1, 2, 3],
      breadcrumb: [
        {
          text: "Payments",
          active: true,
        },
      ],
      route: 'boost-credit',
    },
    children: [
      {
        path: "clients",
        component: () =>
          import(
            /* webpackChunkName: "BCPaymentsClients" */ "@/views/commons/components/payments/views/components/PaymentsClientsGrid.vue"
          ),
        name: "boost-credit-payment-list-clients",
        meta: {
          pageTitle: "Payments",
          permittedRoles: [1, 2, 3],
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
          route: 'boost-credit',
        },
      },
      {
        path: "leads",
        component: () =>
          import(
            /* webpackChunkName: "BCPaymentsLead" */ "@/views/commons/components/payments/views/components/PaymentsLeadsGrid.vue"
          ),
        name: "boost-credit-payment-list-leads",
        meta: {
          pageTitle: "Payments",
          permittedRoles: [1, 2, 3],
          breadcrumb: [
            {
              text: "Leads",
              active: true,
            },
          ],
          route: 'boost-credit',
        },
      },
    ],
  },
];
