<template>
  <div>
    <b-card-body class="p-0">
      <b-table
        show-empty
        sticky-header="17vh"
        striped
        responsive="sm"
        :fields="fieldsEvent"
        :items="S_CREDIT_REPORTS"
        :busy="busy"
        class="mb-0"
        small
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(provider)="data">
          <div
            v-b-tooltip.hover.top="data.item.plataform_name"
            style="
              width: 20px;
              height: 20px;
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            "
            :style="{
              backgroundImage: `url(${baseUrl + data.item.plataform_icon})`,
            }"
          />
        </template>

        <template #cell(date)="data">{{ data.item.date | myGlobal }}</template>

        <template #cell(cr)="data">
          <span
            v-if="
              currentUser.modul_id === 4 &&
              data.item.state == 0 &&
              data.item.plataform_type == 'Source'
            "
            class="text-danger"
            @click="
              /* *INTEGRATE* resources\js\components\lead\showlead\ContentCreditReport.vue - on: processhtml(data.item.id) */
            "
            >Process</span
          >

          <div v-if="moduleId == 5">
            <router-link
              v-if="data.item.state == 1"
              :to="{
                name: 'debtsolution-report-lead',
                params: {
                  idfile: data.item.id,
                  idlead: data.item.lead_id,
                  modul: 5,
                  global: {
                    idfile: data.item.score_id,
                    idlead: data.item.lead_id,
                  },
                },
              }"
              target="_blank"
            >
              <amg-icon icon="FileChartIcon" size="18" />
            </router-link>
          </div>

          <div v-else-if="moduleId == 20">
            <router-link
              v-if="data.item.state == 1"
              :to="{
                name: 'connection-report-lead',
                params: {
                  idfile: data.item.id,
                  idlead: data.item.lead_id,
                  modul: 20,
                  global: {
                    idfile: data.item.score_id,
                    idlead: data.item.lead_id,
                  },
                },
              }"
              target="_blank"
            >
              <amg-icon icon="FileChartIcon" size="18" />
            </router-link>
          </div>

          <div v-else-if="moduleId == 26">
            <router-link
              v-if="data.item.state == 1"
              :to="{
                name: 'sales-report-lead',
                params: {
                  idfile: data.item.id,
                  idlead: data.item.lead_id,
                  modul: 26,
                  global: {
                    idfile: data.item.score_id,
                    idlead: data.item.lead_id,
                  },
                },
              }"
              target="_blank"
            >
              <amg-icon icon="FileChartIcon" size="18" />
            </router-link>
          </div>

          <div v-else-if="moduleId == 22">
            <router-link
              v-if="data.item.state == 1"
              :to="{
                name: 'customerservice-report-lead',
                params: {
                  idfile: data.item.id,
                  idlead: data.item.lead_id,
                  modul: 26,
                  global: {
                    idfile: data.item.score_id,
                    idlead: data.item.lead_id,
                  },
                },
              }"
              target="_blank"
            >
              <amg-icon icon="FileChartIcon" size="18" />
            </router-link>
          </div>

          <div v-else>
            <router-link
              v-if="data.item.state == 1"
              :to="{
                name: 'report-lead',
                params: {
                  idfile: data.item.id,
                  idlead: data.item.lead_id,
                  modul: 2,
                  global: {
                    idfile: data.item.score_id,
                    idlead: data.item.lead_id,
                  },
                },
              }"
              target="_blank"
            >
              <amg-icon icon="FileChartIcon" size="18" />
            </router-link>
          </div>
        </template>
        <template #cell(pdf)="data">
          <a
            v-if="data.item.route_pdf"
            :href="data.item.route_pdf"
            class="button-little-size rounded-circle text-important"
            target="_blanck"
          >
            <amg-icon icon="FilePdfIcon" size="18" />
          </a>
        </template>
        <template #cell(ad)="data">
          <b-button
            variant="flat-info"
            class="button-little-size rounded-circle"
            @click="
              data.item
                .nlr; /* *INTEGRATE* resources\js\components\lead\showlead\ContentCreditReport.vue - on: openModalAD(data.item.nlr, data.item.lead_name) */
            "
          >
            <feather-icon icon="EyeIcon" size="18" />
          </b-button>
        </template>
        <template #cell(marked)="data">
          <a
            v-if="data.item.marked_pdf"
            :href="data.item.marked_pdf"
            class="button-little-size rounded-circle text-important"
            target="_blanck"
          >
            <amg-icon icon="FilePdfIcon" size="18" />
          </a>
        </template>
        <template #cell(is_collected)="data">
          <b-badge
            :id="'hours-target' + data.index"
            :variant="
              data.item.is_collected == 1 ? 'light-warning' : 'light-success'
            "
          >
            {{ data.item.is_collected == 1 ? "Not Collected" : "Collected" }}
            <b-tooltip
              v-if="data.item.is_collected == 2"
              custom-class="ga"
              :target="'hours-target' + data.index"
              triggers="hover"
              placement="left"
            >
              <div>
                <h6 class="font-weight-bolder">COLLECTED DATA</h6>
                <span
                  class="font-weight-bolder text-justify"
                  style="max-width: 1px !important"
                >
                  Created By:
                </span>
                <span class="text-justify" style="max-width: 10px !important">
                  {{ data.item.created_by }}
                </span>
                <br />
                <span
                  class="font-weight-bolder text-justify"
                  style="max-width: 1px !important"
                >
                  Amount:
                </span>
                <span class="text-justify" style="max-width: 10px !important">
                  $ {{ data.item.amount | currency }}
                </span>
                <br />
                <span
                  class="font-weight-bolder text-justify"
                  style="max-width: 1px !important"
                >
                  Created At:
                </span>
                <span class="text-justify" style="max-width: 10px !important">
                  {{ data.item.created_at | myGlobal }}
                </span>
                <br />
              </div>
            </b-tooltip>
          </b-badge>
        </template>
        <template #cell(id)="data">
          <tabler-icon
            v-if="
              data.item.date != null &&
              data.item.date != '' &&
              data.item.is_collected == 1
            "
            icon="CoinIcon"
            size="19"
            class="text-success cursor-pointer"
            v-b-tooltip.hover.left="'Collect Payment'"
            @click="collectPayment(data.item)"
          />
        </template>
      </b-table>
    </b-card-body>

    <ModalCollectCR
      :lead="lead"
      v-if="modalCollectCR"
      @hidden="modalCollectCR = false"
      @onCollectedPayment="refreshTable"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ModalCollectCR from "@/views/ce-digital/sub-modules/sales/views/leads/view/modals/ModalCollectCR.vue";

export default {
  components: {
    ModalCollectCR,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      /* G_TEMPLATES: 'CrmTemplateStore/G_TEMPLATES' */
    }),
    ...mapState({
      S_CREDIT_REPORTS: (event) => event.CrmCreditReportStore.S_CREDIT_REPORTS,
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.busy = true;
    await this.A_GET_CREDIT_REPORTS({
      id: this.lead.id,
    });
    this.busy = false;
  },
  directives: {},
  data() {
    return {
      fieldsEvent: new Array(),
      moduleName: "",
      busy: false,
      modalCollectCR: false,
    };
  },
  methods: {
    ...mapActions({
      /* A_GET_TEMPLATES: 'CrmTemplateStore/A_GET_TEMPLATES' */
    }),
    async refreshTable() {
      this.modalCollectCR = false;
      await this.A_GET_CREDIT_REPORTS({
        id: this.lead.id,
      });
    },
    ...mapActions({
      /* A_GET_TEMPLATES: 'CrmTemplateStore/A_GET_TEMPLATES' */
      A_GET_CREDIT_REPORTS: "CrmCreditReportStore/A_GET_CREDIT_REPORTS",
      A_COUNT_CREDIT_REPORT_PENDINGS:
        "CrmCreditReportStore/A_COUNT_CREDIT_REPORT_PENDINGS",
    }),
    collectPayment(item) {
      this.lead.score = item.id;
      this.modalCollectCR = true;
    },
    resolveTooltip(item) {
      return `
        <div>
          <h5 class="font-weight-bolder">Collect Data</h5>
          <span
            class="font-weight-bolder text-justify"
            style="max-width: 1px !important"
            >Holaa 1
          </span>
          <span
            class="text-justify"
            style="max-width: 10px !important"
          >
            Span
          </span
          >
        </div>
      `;
    },
  },
  mounted() {
    this.moduleName = this.getModuleName(this.currentUser.modul_id);
    this.fieldsEvent = [
      { key: "provider" },
      { key: "date" },
      { key: "transunion", label: "TU" },
      { key: "experian", label: "EX" },
      { key: "equifax", label: "EQ" },
      { key: "cr" },
      { key: "pdf" },
      this.currentUser.modul_id === 4 ? { key: "ad" } : null,
      { key: "marked" },
      [20, 22, 23, 26].includes(this.moduleId)
        ? {
            key: "is_collected",
            label: "Collected",
            thClass: "text-center",
            tdClass: "text-center",
          }
        : null,
      [20, 22, 23, 26].includes(this.moduleId)
        ? {
            key: "id",
            label: "Actions",
            thClass: "text-center",
            tdClass: "text-center",
          }
        : null,
    ];
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
  },
  setup() {},
};
</script>
