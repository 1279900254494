export default [
  {
    path: "creditors",
    name: "debtsolution-creditors",
    redirect: { name: "debtsolution-creditors-list" },
    component: () => import(/* webpackChunkName: "DSCreditors" */ "@/views/debt-solution/views/creditors/Creditors.vue"),
    meta: {
      pageTitle: "Creditors",
      breadcrumb: [
        {
          text: "Creditors",
        },
      ],
    },
    children: [
      {
        path: "",
        name: "debtsolution-creditors-list",
        component: () => import(/* webpackChunkName: "DSCreditorsTable" */ "@/views/debt-solution/views/creditors/components/table/CreditorsTable.vue"),
        meta: {
          route: "debtsolution",
          isClientsTab: true,
          pageTitle: "Creditors",
          breadcrumb: [
            {
              text: "Creditors",
              to: "/debtsolution/creditors",
              active: true,
            },
          ],
        },
      },
    ],
  },
];