export default [
    {
        path: "process-cr",
        name: "specialistdigital-process-cr",
        redirect: { name: 'specialistdigital-process-cr-pending' },
        component: () => import(/* webpackChunkName: "SpecialistDigitalProcessCr" */ '@/views/specialist-digital/views/process-cr/ProcessCR.vue'),
        meta: {
            pageTitle: 'Process CR',
            permittedRoles: [1, 2, 3, 8],
            breadcrumb: [
                {
                    text: 'Process CR',
                    active: true,
                },
            ],
        },
        children: [
            {
                path: "pending",
                component: () => import(/* webpackChunkName: "SpecialistDigitalProcessCrPending" */ '@/views/specialist-digital/views/process-cr/view/components/grids/GridProcessCR.vue'),
                name: "specialistdigital-process-cr-pending",
                meta: {
                    pageTitle: "Process CR",
                    breadcrumb: [
                        {
                            text: "Pending",
                            active: true
                        }
                    ],
                    tab: 1,
                    permittedRoles: [1, 2, 3, 8],

                }

            },
            {
                path: "completed",
                component: () => import(/* webpackChunkName: "SpecialistDigitalProcessCrCompleted" */ '@/views/specialist-digital/views/process-cr/view/components/grids/GridProcessCR.vue'),
                name: "specialistdigital-process-cr-completed",
                meta: {
                    pageTitle: "Process CR",
                    breadcrumb: [
                        {
                            text: "Completed",
                            active: true
                        }
                    ],
                    tab: 2,
                    permittedRoles: [1, 2, 3, 8],
                }
            },
            {
                path: "delete",
                component: () => import(/* webpackChunkName: "SpecialistDigitalProcessCrDelete" */ '@/views/specialist-digital/views/process-cr/view/components/grids/GridProcessCR.vue'),
                name: "specialistdigital-process-cr-delete",
                meta: {
                    pageTitle: "Process CR",
                    breadcrumb: [
                        {
                            text: "Completed",
                            active: true
                        }
                    ],
                    tab: 3,
                    permittedRoles: [1, 2, 3, 8],
                }
            }
        ]

    },

    {
        path: 'leads/report/:idlead/:idfile/:accountId',
        name: 'report-lead-specialistdigital-cr',
        component: () => import(/* webpackChunkName: "SpecialistDigitalLeadsReport" */ '@/views/commons/components/ncr/components/report/ReportLead.vue'),
        props: true,
        meta: {
            isClientsTab: true,
            typeTable: 2,
            permittedRoles: [1, 2, 3, 8],

        },
    },
]