export default [
    {
        path: "report",
        name: "analyst-department-payment-report",
        component: () =>
            import(
                "@/views/specialists/views/report/PaymentReport.vue"
            ),
        meta: {
            module: 11,
            pageTitle: "Payment Report",
            breadcrumb: [
                {
                    text: "Payment Report",
                    active: true,
                },
            ],
        },
    },
];
