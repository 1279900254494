import { amgApi,amgApiApp } from "@/service/axios"

class ConnectionClientDashboardService{

    BASE_URL = 'credit-expert/connection/'

    async getAdvisorsFromConnection(){
      try {
        const { data } =await amgApi.post(`${this.BASE_URL}get-advisors`)
        return data
      } catch (error) {
        throw new Error(error)
      }
    }
    async assignAdvisor(params){
      // params = [ client_account_id: string , advisor_id: int , user_id: int ]
      try {
        const { data } = await amgApi.post(`${this.BASE_URL}asign-advisor-in-connection`,params)
        const {has_error,msg} = data

        if(!has_error)
            return {success: true,msg: 'Success'}
        else
            return {success: false,msg: msg}

      } catch (error) {

        throw { success:false,msg: error}

      }

    }
    async sendClientToConnection(params){
      // params = {
              // client_id: string,
              // client_account_id: string,
              // in_connection: string ,
              // status_connection : string,
              // reason_connection : string, //unnecessary
              // advisor_id : int, //unnecessary
              // comment : string ,
              // user_id : int }
      try{
        const  {data}  = await amgApi.post(`${this.BASE_URL}sent-client-to-connection`,params)
        return data
      }catch(error){
        console.error(error)
      }
    }
    async getClientData(id) {
        try {
          const { data } = await amgApi.post("commons/search-account", { id });
          return data[0];
        } catch (err) {
          console.log(err);
        }
    }
    async changePassword(params) {
      const data = await amgApiApp.post("/client/alter-password", params);
      return data;
    }

    async getInformationApp(params) {
      const data = await amgApiApp.post("/client/account", params);
      return data;
    }
    async updateStatusTracking( params ){
      // params = { id: tracking_list_id string , type_status int, status int, newval string
      try {
        const data = await amgApi.post('/commons/boost-credit/update-status-tracking', params )
        return data;
      } catch (error) {
        console.error(error)
      }
    }
    async finishTrackingList( params ){
      // params = { id : tracking_list_id}
      try {
        const data = await amgApi.post('/commons/boost-credit/finish-tracking-list', params )
        return data;
      } catch (error) {
        console.error(error)
      }
    }
    async getEfectivity(params){
      try {
        const data = await amgApi.post('/credit-expert/connection/dashboard-get-Efectivity-client', params )
        return data;
      } catch (error) {
        console.error(error)
      }
    }
    async getEfectivityTracking(params){
      try {
        const data = await amgApi.post('/credit-expert/connection/dashboard-get-Efectivity-client-tacking', params )
        return data;
      } catch (error) {
        console.error(error)
      }
    }

    async getTableEffectivity(params){
      try {
        const data = await amgApi.post('/credit-expert/connection/get-tracking-modal-effectivity', params )
        return data;
      } catch (error) {
        console.error(error)
      }
    }

    async getCountEffectivity(params){
      try {
        const data = await amgApi.post('/credit-expert/connection/get-count-modal-effectivity', params )
        return data;
      } catch (error) {
        console.error(error)
      }
    }


}

export default new ConnectionClientDashboardService()
