import { amgApi } from '@/service/axios'

class SettingsService {
  async getAllUsuarios(params) {
    const data = await amgApi.post('/management/settings/get-All-management-settings-usuarios', params)
    return data
  }

  async updatePermissionManagementByIdUserModule(params) {
    const data = await amgApi.post('/management/settings/update-permission-management-by-id-user-module', params)
    return data
  }

  async getTrackingSettingById(params) {
    const data = await amgApi.post('/management/settings/get-tracking-setting-by-id', params)
    return data
  }

  async getCountPermissionPending() {
    const data = await amgApi.post('/management/settings/get-count-permission-pending')
    return data
  }
}

export default new SettingsService()
