export default [
  {
    path: "chargeback",
    name: "bu-charge-back",
    component: () =>
      import(
        /* webpackChunkName: "DebtChargeBack" */ "@/views/commons/components/charge-back_v2/ChargeBack.vue"
      ),
    redirect: { name: "bu-charge-back-pending" },
    meta: {
      permittedRoles: [1, 2],
      pageTitle: "Charge Back",
      breadcrumb: [
        {
          text: "Charge Back",
        },
      ],
      routeInPending: "bu-charge-back-pending",
      routeInProcess: "bu-charge-back-in-process",
      routeCompleted: "bu-charge-back-completed",
    },
    children: [
      {
        path: "pending",
        component: () =>
          import(
            /* webpackChunkName: "DebtChargeBackGeneralInProcess" */ "@/views/commons/components/charge-back_v2/view/components/ChargeBackTable.vue"
          ),
        name: "bu-charge-back-pending",
        meta: {
          permittedRoles: [1, 2],
          status: 1,
          tabTitle: "pending",
          pageTitle: "Charge Back",
          breadcrumb: [
            {
              text: "Pending",
              active: true,
            },
          ],
          module: 3,
        },
      },
      {
        path: "completed",
        component: () =>
          import(
            /* webpackChunkName: "DebtChargeBackGeneralInProcess" */ "@/views/commons/components/charge-back_v2/view/components/ChargeBackTable.vue"
          ),
        name: "bu-charge-back-completed",
        meta: {
          permittedRoles: [1, 2],
          status: 3,
          tabTitle: "completed",
          pageTitle: "Charge Back",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
          module: 3,
          completed: true,
        },
      },
      {
        path: "in-process",
        component: () =>
          import(
            /* webpackChunkName: "DebtChargeBackGeneralSolution" */ "@/views/management/views/charge-back/components/SolutionChargeBack.vue"
          ),
        redirect: { name: "bu-client-remove-charge-back" },
        name: "bu-charge-back-in-process",
        meta: {
          permittedRoles: [1, 2],
          status: 2,
          tabTitle: "in-process",
          pageTitle: "Charge Back",
          breadcrumb: [
            {
              text: "In Process",
              active: true,
            },
          ],
          module: 3,
          routeRemove: "bu-client-remove-charge-back",
          routePayment: "bu-payment-client",
          routeDepartment: "bu-departament",
          routeDispute: "bu-charge-back-dispute",
        },
        children: [
          {
            path: "remove",
            component: () =>
              import(
                /* webpackChunkName: "DebtChargeBackRemove" */ "@/views/commons/components/charge-back_v2/view/components/ChargeBackTable.vue"
              ),
            name: "bu-client-remove-charge-back",
            meta: {
              permittedRoles: [1, 2],
              status: 1,
              tabTitle: "remove",
              pageTitle: "Charge Back",
              module: 3,
              breadcrumb: [
                {
                  text: "Remove",
                  active: true,
                },
              ],
            },
          },
          {
            path: "bu-payment-client",
            component: () =>
              import(
                /* webpackChunkName: "DebtChargeBackPaymentClient" */ "@/views/commons/components/charge-back_v2/view/components/ChargeBackTable.vue"
              ),
            name: "bu-payment-client",
            meta: {
              permittedRoles: [1, 2],
              status: 2,
              tabTitle: "payment",
              pageTitle: "Charge Back",
              module: 3,
              breadcrumb: [
                {
                  text: "Payment Client",
                  active: true,
                },
              ],
            },
          },
          {
            path: "departament",
            component: () =>
              import(
                /* webpackChunkName: "DebtChargeBackDepartament" */ "@/views/commons/components/charge-back_v2/view/components/ChargeBackTable.vue"
              ),
            name: "bu-departament",
            meta: {
              permittedRoles: [1, 2],
              status: 3,
              tabTitle: "department",
              pageTitle: "Charge Back",
              module: 3,
              breadcrumb: [
                {
                  text: "Departament",
                  active: true,
                },
              ],
            },
          },
          {
            path: "dispute",
            component: () =>
              import(
                /* webpackChunkName: "DebtChargeBackGeneralDispute" */ "@/views/management/views/charge-back/components/DisputeChargeBack.vue"
              ),
            redirect: { name: "bu-pending-dispute-charge" },
            name: "bu-charge-back-dispute",
            meta: {
              permittedRoles: [1, 2],
              status: 3,
              tabTitle: "dispute",
              pageTitle: "Charge Back",
              breadcrumb: [
                {
                  text: "Dispute",
                  active: true,
                },
              ],
              routePending: "bu-pending-dispute-charge",
              routeObserved: "bu-observed-dispute-charge",
              routeSent: "bu-sent-dispute-charge",
            },
            children: [
              {
                path: "pending",
                component: () =>
                  import(
                    /* webpackChunkName: "DebtChargeBackPending" */ "@/views/commons/components/charge-back_v2/view/components/ChargeBackTable.vue"
                  ),
                name: "bu-pending-dispute-charge",
                meta: {
                  permittedRoles: [1, 2],
                  status: 1,
                  tabTitle: "pending",
                  pageTitle: "Charge Back",
                  module: 3,
                  breadcrumb: [
                    {
                      text: " Pending",
                      active: true,
                    },
                  ],
                },
              },
              {
                path: "observed",
                component: () =>
                  import(
                    /* webpackChunkName: "DebtChargeBackObserved" */ "@/views/commons/components/charge-back_v2/view/components/ChargeBackTable.vue"
                  ),
                name: "bu-observed-dispute-charge",
                meta: {
                  permittedRoles: [1, 2],
                  status: 2,
                  tabTitle: "observed",
                  pageTitle: "Charge Back",
                  module: 3,
                  breadcrumb: [
                    {
                      text: " Observed",
                      active: true,
                    },
                  ],
                },
              },
              {
                path: "sent",
                component: () =>
                  import(
                    /* webpackChunkName: "DebtChargeBackSentDispute" */ "@/views/commons/components/charge-back_v2/view/components/ChargeBackTable.vue"
                  ),
                name: "bu-sent-dispute-charge",
                meta: {
                  permittedRoles: [1, 2],
                  status: 3,
                  tabTitle: "sent",
                  pageTitle: "Charge Back",
                  module: 3,
                  breadcrumb: [
                    {
                      text: " Sent",
                      active: true,
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
]
