<template>
  <div>
    <b-modal
      v-model="onControl"
      modal-class="modal-primary"
      :title="`Payments Search`"
      title-tag="h3"
      size="xlg"
      no-close-on-backdrop
      @hidden="close"
    >
      <searchPayment />
    </b-modal>
  </div>
</template>

<script>
// service
import searchPayment from '@/views/commons/components/charge-back_v2/view/modal/SearchPayment.vue';

export default {
  components: {
    searchPayment,
  },
  data() {
    return {
      openModalTrackingPayment: false,
      errorMessage: false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 25, 50],

      startPage: null,
      toPage: null,
      items: [],
      sortBy: 'created_at',
      sortDesc: true,
      onControl: false,
      voidRefundModalOn: false,
      voidRefundData: {},
      typeVoidRefund: null,
      showModalSMS: false,
      clientsSms: [],
    };
  },
  computed: {
  },
  watch: {
  },
  async created() {
    this.onControl = true;
  },

  mounted() {
    this.errorMessage = true;
  },
  methods: {
    openModalTracking() {
      this.openModalTrackingPayment = true;
    },
    closeModalTracking() {
      this.openModalTrackingPayment = false;
    },
    close() {
      this.$emit('close');
    },
    preventNegativeInput(event) {
      const invalidKeys = ['-', '+', '*', '/', '=', '!', '@', '#', '$', '%', '^', '&', '(', ')'];
      if (invalidKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openVoidRefundModal(data, type) {
      this.typeVoidRefund = type;
      this.voidRefundData = {
        transaction_id: data.transaction_id,
        merchant: data.merchant,
        amount: data.amount,
        client_name: `${data.first_name} ${data.last_name}`,
        settlement_date: data.settlement_date,
      };
      this.voidRefundModalOn = true;
    },
    closeVoidRefundModal() {
      this.voidRefundModalOn = false;
    },
    async refresh() {
      await this.searchPayments(this.currentPage);
    },
  },
};
</script>
<style scope>
/* .k-widget{
  border: 1px solid !important;
  border-radius: 5px;
} */
.hover-me{
  transition: 0.3s ease-in-out;
}
.hover-me:hover{
  transform: scale(1.2);
}
</style>
