import store from "@/store";

import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";
import PotentialService from "@/views/commons/components/potencial-appointment/services/potencial-appointments.service";
import OtherServicesService from "@/views/commons/components/other-services/service/appointment.service";
import AppointmentService from "@/views/commons/components/appointments/service/appointment.service";
import ApplicationService from "@/views/commons/components/applications/services/applications.service";
import employeeClaims, {
  salesMadeCounter,
} from "@/views/commons/commons-counter";

const requestNcrCounter = async () => {
  const { pending_ncr_client, pending_ncr_lead } =
    await NcrRequestService.getCountersNcr({});

  const sideCounter = pending_ncr_client + pending_ncr_lead;

  const payload = {
    routeName: "request-ncr-financial-approval",
    tag: sideCounter > 99 ? "+99" : sideCounter,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const getPendingPotential = async () => {
  const { modul_id } = store.state.auth.currentUser;
  const data = await PotentialService.getCountPending({ module_id: modul_id });
  const payload = {
    routeName: "potencial-financial-approval",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const getPendingOtherServices = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  const data = await OtherServicesService.getCountPending({
    module_id: modul_id !== 29 ? modul_id : null,
    user_id,
    role_id,
  });
  const payload = {
    routeName: "other-services-financial-approval",
    tag: data > 99 ? "+ 99" : data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const getPendingAppointment = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  const { pending, in_process, completed } =
    await AppointmentService.getCountAppointmentsPending({
      module_id: modul_id !== 29 ? modul_id : null,
      user_id,
      role_id,
    });
  const payload = {
    routeName: "appointment-financial-approval",
    tag: pending + in_process + completed,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const getPendingApplications = async () => {
  const { modul_id, user_id, role_id, program_id } =
    store.state.auth.currentUser;
  // let specialist_id = [1,2].includes(role_id) || (role_id === 11 && modul_id === 6) ? null : user_id;
  let advisorId =
    [1, 2].includes(role_id) ||
    (role_id === 11 && modul_id === 6) ||
    [11, 25, 28, 29, 30].includes(modul_id)
      ? null
      : user_id;
  let programm = [11, 25, 28, 29, 30].includes(modul_id) ? null : program_id;
  const params = {
    user_id,
    role_id,
    program_id: programm,
    specialist_id: null,
    module_id: modul_id,
    advisor_id: advisorId,
  };
  const data = await ApplicationService.getCountPending(params);
  const payload = {
    routeName: "financial-approval-applications",
    tag: data > 99 ? "+99" : data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const allCounterFinancialApproval = async () => {
  Promise.all([
    salesMadeCounter("spfa-sales-made"),
    requestNcrCounter(),
    getPendingPotential(),
    getPendingOtherServices(),
    getPendingAppointment(),
    getPendingApplications(),
  ]);
};
export default allCounterFinancialApproval;
