export default [
  {
    path: 'employees',
    name: 'employees-rrhh',
    component: () => import(/* webpackChunkName: "RrhhEmployeesMain" */ '@/views/management/views/employees/Employees.vue'),
    redirect: { name: 'list-employees-rrhh' },
    children: [
      {
        path: '',
        redirect: { name: 'list-employees-rrhh' },
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "RrhhEmployeesList" */ '@/views/management/views/employees/employees-module/employees-list/EmployeesList'),
        name: 'list-employees-rrhh',
        meta: {
          pageTitle: 'Employees',
          breadcrumb: [{
            text: 'Employees',
            active: true,
          }],
          employeesRoute: 'employees-rrhh',
          employeeRoute: 'information-employee-rrhh',
          listEmployeesRoute: 'list-employees-rrhh',
          listDepartmentRoute: 'list-department-rrhh',
          basicInformationRoute: 'basic-information-rrhh',
          filesRoute: 'files-rrhh',
          jobDetailsRoute: 'job-details-rrhh',
          scheduleRoute: 'schedule-rrhh',
          PendingPensionFund: 'list-pending-pension-fund-rrhh',
        },
      },
      {
        path: 'department',
        component: () => import(/* webpackChunkName: "RrhhEmployeesDepartment" */ '@/views/management/views/employees/employees-module/departments-list/DepartmentsList'),
        name: 'list-department-rrhh',
        meta: {
          pageTitle: 'Department',
          breadcrumb: [{
            text: 'department',
            active: true,
          }],
          employeesRoute: 'employees-rrhh',
          employeeRoute: 'information-employee-rrhh',
          listEmployeesRoute: 'list-employees-rrhh',
          listDepartmentRoute: 'list-department-rrhh',
          basicInformationRoute: 'basic-information-rrhh',
          filesRoute: 'files-rrhh',
          jobDetailsRoute: 'job-details-rrhh',
          scheduleRoute: 'schedule-rrhh',
          PendingPensionFund: 'list-pending-pension-fund-rrhh',
        },
      },
      {
        path: 'information/:id',
        component: () => import(/* webpackChunkName: "RrhhEmployeesInfoMain" */ '@/views/management/views/employees/employees-module/employees-list/modals/information-employee/InformationEmployee.vue'),
        name: 'information-employee-rrhh',
        redirect: { name: 'basic-information-rrhh' },
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "RrhhEmployeesInformation" */ '@/views/management/views/employees/employees-module/employees-list/modals/options/basic-information/BasicInformation'),
            name: 'basic-information-rrhh',
            meta: {
              pageTitle: 'Employees',
              breadcrumb: [{
                text: 'Basic Information',
                active: true,
              }],
              employeesRoute: 'employees-rrhh',
              employeeRoute: 'information-employee-rrhh',
              listEmployeesRoute: 'list-employees-rrhh',
              listDepartmentRoute: 'list-department-rrhh',
              basicInformationRoute: 'basic-information-rrhh',
              filesRoute: 'files-rrhh',
              jobDetailsRoute: 'job-details-rrhh',
              scheduleRoute: 'schedule-rrhh',
              PendingPensionFund: 'list-pending-pension-fund-rrhh',
            },
          },
          {
            path: 'files',
            component: () => import(/* webpackChunkName: "RrhhEmployeesFiles" */ '@/views/management/views/employees/employees-module/employees-list/modals/options/files/Files'),
            name: 'files-rrhh',
            meta: {
              pageTitle: 'Employees',
              breadcrumb: [{
                text: 'Files',
                active: true,
              }],
              employeesRoute: 'employees-rrhh',
              employeeRoute: 'information-employee-rrhh',
              listEmployeesRoute: 'list-employees-rrhh',
              listDepartmentRoute: 'list-department-rrhh',
              basicInformationRoute: 'basic-information-rrhh',
              filesRoute: 'files-rrhh',
              jobDetailsRoute: 'job-details-rrhh',
              scheduleRoute: 'schedule-rrhh',
              PendingPensionFund: 'list-pending-pension-fund-rrhh',
            },
          },
          {
            path: 'job',
            component: () => import(/* webpackChunkName: "RrhhEmployeesJobDetails" */ '@/views/management/views/employees/employees-module/employees-list/modals/options/job-details/JobDetails'),
            name: 'job-details-rrhh',
            meta: {
              pageTitle: 'Employees',
              breadcrumb: [{
                text: 'Information',
                active: true,
              }],
              employeesRoute: 'employees-rrhh',
              employeeRoute: 'information-employee-rrhh',
              listEmployeesRoute: 'list-employees-rrhh',
              listDepartmentRoute: 'list-department-rrhh',
              basicInformationRoute: 'basic-information-rrhh',
              filesRoute: 'files-rrhh',
              jobDetailsRoute: 'job-details-rrhh',
              scheduleRoute: 'schedule-rrhh',
              PendingPensionFund: 'list-pending-pension-fund-rrhh',
            },
          },
          {
            path: 'schedule',
            component: () => import(/* webpackChunkName: "RrhhScheduleEmployee" */ '@/views/management/views/employees/employees-module/employees-list/modals/options/schedule/Schedule'),
            name: 'schedule-rrhh',
            meta: {
              pageTitle: 'Employees',
              breadcrumb: [{
                text: 'Schedule',
                active: true,
              }],
              employeesRoute: 'employees-rrhh',
              employeeRoute: 'information-employee-rrhh',
              listEmployeesRoute: 'list-employees-rrhh',
              listDepartmentRoute: 'list-department-rrhh',
              basicInformationRoute: 'basic-information-rrhh',
              filesRoute: 'files-rrhh',
              jobDetailsRoute: 'job-details-rrhh',
              scheduleRoute: 'schedule-rrhh',
              PendingPensionFund: 'list-pending-pension-fund-rrhh',
            },
          },

        ],
      },
      {
        path: 'pending-pension-fund',
        component: () => import(/* webpackChunkName: "RrhhRegisterPensionFund" */ '@/views/management/views/employees/employees-module/pending-pension-fund/pendingList.vue'),
        name: 'list-pending-pension-fund-rrhh',
        meta: {
          pageTitle: 'Pending Pension Fund',
          breadcrumb: [{
            text: 'Pending',
            active: true,
          }],
          employeesRoute: 'employees-rrhh',
          employeeRoute: 'information-employee-rrhh',
          listEmployeesRoute: 'list-employees-rrhh',
          listDepartmentRoute: 'list-department-rrhh',
          basicInformationRoute: 'basic-information-rrhh',
          filesRoute: 'files-rrhh',
          jobDetailsRoute: 'job-details-rrhh',
          scheduleRoute: 'schedule-rrhh',
          PendingPensionFund: 'list-pending-pension-fund-rrhh',
        },
      },
    ],
    meta: {
      pageTitle: 'Employees',
      breadcrumb: [{
        text: 'Employees',
        active: true,
      }],
      employeesRoute: 'employees-rrhh',
      employeeRoute: 'information-employee-rrhh',
      listEmployeesRoute: 'list-employees-rrhh',
      listDepartmentRoute: 'list-department-rrhh',
      basicInformationRoute: 'basic-information-rrhh',
      filesRoute: 'files-rrhh',
      jobDetailsRoute: 'job-details-rrhh',
      scheduleRoute: 'schedule-rrhh',
      PendingPensionFund: 'list-pending-pension-fund-rrhh',
    },
  },
];
