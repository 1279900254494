import store from "@/store";
import Vue from "vue";
import HelpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";

const ticketStatusNotification = () => {
  window.socket.bind("ticket-status", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id;
    if (data.to_id == sessionId) {
      const params = {
        modul_id: store.state.auth.currentUser.modul_id,
        user_id: store.state.auth.currentUser.user_id,
      };

      const { data: tab } = await HelpdeskService.getCountTab(params);
      store.commit("HelpdeskStore/SET_COUNTER_NOTIFICATIONS", tab.total);
      const timeLine = data.others.tracking.map((item) => {
        return {
          title: item.name,
          date: item.created_at,
          review: item.review,
        };
      });
      await Vue.prototype.$timeLine({
        title: data.message,
        icon: "CheckIcon",
        subtitle: data.ticket_id,
        typeData: !data.others.isError ? "status" : "error",
        timeLine: timeLine,
        ok: async () => {
          await window.amgApi.post("/commons/close-all-swal", data);
        },
      });
    }
  });
};
export default ticketStatusNotification;
