import { amgApi } from '@/service/axios'

class DirectsRoundService {
    async getPendingDirectRoundCount() {
        try {
            const { data } = await amgApi.post('specialists/get-pending-direct-round-count-specialists');
            return data

        } catch (error) {
            console.error("Something went wrong on getPendingDirectRoundCount", error);
        }
    }
}

export default new DirectsRoundService()