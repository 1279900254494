import Vue from 'vue'; // To get the 'component' of 'Notification';
import store from '@/store'; // To get user(s)

const RecruitmentProcessSocket = () => {

  window.socket.bind('send-recruitment-process-to-ceo', async data => {
    const userModule = store.state.auth.currentUser.main_module;
    // show alert to RRHH status SENT TO HT
    if (userModule === 1 && data.status === 2) {
      await Vue.prototype.$amgAlert({
        title: 'Recruitment Process Request, in revision by CEO',
        icon: 'BellIcon',
        subtitle: `From ${data.moduleName}`,
        data: [
          {
            icon: 'userIcon',
            title: `Sender by ${data.createdBy}`,
            description: `Commentary: ${data.commentary}`,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }

    // show alert to RRHH status approve by ceo
    if (userModule === 17 && data.status === 3) {
      await Vue.prototype.$amgAlert({
        title: 'Recruitment Process Request - APPROVED BY CEO',
        icon: 'BellIcon',
        subtitle: `From ${data.createdBy}`,
        data: [
          {
            icon: 'userIcon',
            title: `Sender by ${data.createdBy}`,
            description: 'Your recruitment process request was approved',
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }

    if (userModule === data.moduleId && data.status === 3) {
      await Vue.prototype.$amgAlert({
        title: 'Recruitment Process Request - APPROVED BY CEO',
        icon: 'BellIcon',
        subtitle: `From ${data.createdBy}`,
        data: [
          {
            icon: 'userIcon',
            title: `Sender by ${data.createdBy}`,
            description: 'Your recruitment process request was approved',
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }

    // show alert to RRHH status approve by ceo
    if (userModule === 17 && data.status === 4) {
      await Vue.prototype.$amgAlert({
        title: 'Recruitment Process Request - DISAPPROVED',
        icon: 'BellIcon',
        subtitle: `From ${data.createdBy}`,
        data: [
          {
            icon: 'userIcon',
            title: `Sender by ${data.createdBy}`,
            description: 'Your recruitment process request was disapproved',
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }

    if (userModule === data.moduleId && data.status === 4) {
      await Vue.prototype.$amgAlert({
        title: 'Recruitment Process Request - DISAPPROVED',
        icon: 'BellIcon',
        subtitle: `From ${data.createdBy}`,
        data: [
          {
            icon: 'userIcon',
            title: `Sender by ${data.createdBy}`,
            description: 'Your recruitment process request was disapproved',
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }
  });
};

export default RecruitmentProcessSocket;
