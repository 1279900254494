import store from "@/store";
import router from "@/router/index";
import Vue from "vue";
import moment from "moment";
import {
  swalErrorIcon,
  swalInfoIcon,
  swalSuccessIcon,
  swalWarningIcon,
} from "@/icons/statusIcons";

const UpdateExpenseByCeo = () => {
  window.socket.bind("update-expense-by-ceo", async (response) => {
    const { departmentExpenseInfo } = response;
    const user_id = store.state.auth.currentUser.user_id;
    const modul_id = store.state.auth.currentUser.modul_id;
    const is_dashboard = router.currentRoute.fullPath.includes("dashboard");
    let view_alert = false;
    if (
      user_id == departmentExpenseInfo.created_by &&
      user_id != departmentExpenseInfo.send_by
    ) {
      view_alert = true;
    }
    if (view_alert) {
      store.commit(
        "DepartmentExpensesAlertStore/M_DEAlertInfo",
        departmentExpenseInfo
      );
      store.commit("DepartmentExpensesAlertStore/M_IsActive", true);
    }
  });
};

const SendExpenseToCeo = () => {
  window.socket.bind("send-expense-to-ceo", async (response) => {
    store.commit(
      "ExpensesRequest/M_SET_REFRESH_EXPENSES_COUNTER",
      response.refreshExpensesCounter
    );
  });
};

export { UpdateExpenseByCeo, SendExpenseToCeo };
