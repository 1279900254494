import CategoriesRouter from "@/views/management/views/categories/categories.router";
import EmployeesRouter from "@/views/management/views/employees/employees.router";
import ChargeBackRouter from "@/views/management/views/charge-back/charge-back.router";
import PayrollRouter from "@/views/management/views/payroll/payroll.router";
import CompaniesRouter from "@/views/management/views/companies/companies.router";
import OldCompaniesRouter from "@/views/management/views/companies-old/companies.router";
import ReportsRouter from "@/views/commons/components/reports/reports.router";
import FileManagerRouter from "@/views/management/views/file-manager/file-manager.router";
import CommissionsRouter from "@/views/management/views/commissions/commissions.route";
import LoansRouter from "@/views/management/views/loans/loans.router";
import HelpdeskRouter from "@/views/management/views/helpdesk/helpdesk.route.js";
import RiskyClientsRouter from "@/views/management/views/risky-clients/risky.router";
import ProductRequestRouter from "@/views/management/views/request/request.router.js";
import ApprovalProcessRouter from "@/views/management/views/approval-process/approval-process.router";
import OtherPaymentsRouter from "@/views/management/views/other-payments/other-payments.router";
import ManagementMain from "@/views/management/views/ManagementMain.vue";
import SettingsRouter from "@/views/management/views/settings/router/index";
import SalaryRecordRouter from "@/views/management/views/salary-record/salary-record.router.js";
import EmployeeClaimsRouter from "@/views/management/views/employee-claims/router/employee-claims.router";
import ClaimsRouter from "@/views/management/views/claims/claims.router";
import ManagementAttendanceRouter from "@/views/management/views/attendance/management-attendance.router";
import ManagementPayrollPaymentsRouter from "@/views/management/views/payroll-payments/management-payroll-payments.router";
import DashboardClaimsRouter from "@/views/management/views/dashboard-claims/dashboard-claims.router.js";
import RequestTransactionsRouter from "@/views/management/views/requests-transactions/request-transactions.router";
import SearchPaymentRouter from "@/views/management/views/search-payments/search-payment.router.js";
import CallLogRouter from "@/views/management/views/ring-central/call-log/call-log.router.js";
import ParagonSoft from "@/views/management/views/paragon-soft/paragon-soft.router.js";
import SalesmadeRouter from "@/views/management/views/sales-made/salesmade.router.js"

import DashboardCalls from "@/views/management/views/ring-central/dashboard-calls/dashboard.router.js";
import MOFRouter from "@/views/management/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/management/views/recruitment-process/management-recruitment_process.router";
import OrganizationChart from "@/views/management/views/organization-chart/organization-chart.router.js";
import DepartmentsRouter from "@/views/management/views/departments/router/departments.router.js";
import RequestClientRouter from "@/views/management/views/requests-transactions/views/clients.router";
// import MassMessagesRouter from "@/views/management/views/ring-central/mass-messages/mass-messages.router.js";
import CustomerTicketsRouter from "@/views/management/views/customer-tickets/management-customer-tickets.router";
import requestClientStatusRouter from "@/views/management/views/request-client-status/router/request-client-status.router.js";
import InconsistentTransactionRouter from "@/views/management/views/inconsistent-transactions/router/inconsistent-transactions.router.js";
import UsersLogRouter from "@/views/management/views/users-log/router/users-log.router";
import CallImpressionRouter from "@/views/management/views/call-impression/router/call-impression.router.js";

// Clients
import ClientsRouter from "@/views/management/views/clients/clients.router";
import LeadsRouter from "@/views/management/views/leads/leads.router";

import DashboardRouter from "@/views/management/views/dashboard/router/dashboard.router.js";


const routes = [
  {
    path: "/management",
    redirect: { name: "management-clients" },
    meta: { module: 16 },
    component: ManagementMain,
    children: [
      ...ClientsRouter,
      ...CategoriesRouter,
      ...EmployeesRouter,
      ...ChargeBackRouter,
      ...PayrollRouter,
      ...CompaniesRouter,
      ...OldCompaniesRouter,
      ...ReportsRouter,
      ...FileManagerRouter,
      ...CommissionsRouter,
      ...LoansRouter,
      HelpdeskRouter,
      ...RiskyClientsRouter,
      ...SearchPaymentRouter,
      ...ProductRequestRouter,
      ...ApprovalProcessRouter,
      ...OtherPaymentsRouter,
      ...ClaimsRouter,
      ...SettingsRouter,
      ...SalaryRecordRouter,
      ...EmployeeClaimsRouter,
      ...ManagementAttendanceRouter,
      ...ManagementPayrollPaymentsRouter,
      ...DashboardClaimsRouter,
      ...RequestTransactionsRouter,
      ...CallLogRouter,
      ...DashboardCalls,
      ...DepartmentsRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...OrganizationChart,
      ...RequestClientRouter,
      // ...MassMessagesRouter,
      ...ParagonSoft,
      ...CustomerTicketsRouter,
      ...requestClientStatusRouter,
      ...InconsistentTransactionRouter,
      ...UsersLogRouter,
      ...CallImpressionRouter,
      ...LeadsRouter,
      SalesmadeRouter,
      ...DashboardRouter
    ],
  },
];

export default routes;
