import Vue from 'vue';
import store from '@/store';

const notificationUpdatedAccountEmployee = () => {

  window.socket.bind('notification-to-management-update-account-employees', async data => {
    const userModule = store.state.auth.currentUser.main_module;

    // show alert to management
    if (userModule === 16 || userModule === 17) {
      await Vue.prototype.$amgAlert({
        title: 'ACCOUNTS INFORMATION',
        icon: 'CreditCardIcon',
        subtitle: 'ACCOUNTS UPDATED',
        data: [
          {
            icon: 'UserIcon',
            title: 'EMPLOYEE',
            description: data.employee,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }
  });
};

export default notificationUpdatedAccountEmployee;
