const state = {

  S_UPLOAD_FLYERS: [],
  S_UPLOAD_FLYERS_INFO: [],
}
const getters = {
  G_UPLOAD_FLYERS() {
    return state.S_UPLOAD_FLYERS
  },
  G_UPLOAD_FLYERS_INFO() {
    return state.S_UPLOAD_FLYERS_INFO
  },

}
const mutations = {
  M_SET_UPLOAD_FLYERS(state, val) {
    state.S_UPLOAD_FLYERS = val
  },

  M_SET_UPLOAD_FLYERS_INFO(state, val) {
    state.S_UPLOAD_FLYERS_INFO = val
  },

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,

}
