import LettersRoundService from "@/views/specialists/sub-modules/analyst-department/views/letter-round/services/letters-rounds.service.js";
import store from "@/store"

export default {
  namespaced: true,
  state: {
    refreshTable: false,
    S_COUNTER_RETURNED_ROUND_LETTERS: 0,
    S_COUNTER_PENDING_ROUND_LETTERS: 0,
    S_COUNTER_DEPT_RETURNED_LETTERS: 0,
    S_BUREAU_LETTERS_DATA: {},
    S_DETAILS_MODAL_ON: false,
    S_IS_FOOTER_MODAL_ACTIVE: false,
    S_EDIT_LETTER_MODAL: false,
    S_CREATE_DETAIL_LETTERS_MODAL: false,
    S_REDIRECT_FROM_TABLE: false,
    S_UPDATE_LETTER_TABLE: false,
    S_SUM_TU: 0,
    S_SUM_EQ: 0,
    S_SUM_EX: 0,
  },
  mutations: {
    SET_REFRESH_TABLE(state, payload) {
      state.refreshTable = payload
    },
    M_SET_COUNTER_RETURNED_ROUND_LETTERS(state, payload = { pending: 0 }) {
      state.S_COUNTER_RETURNED_ROUND_LETTERS = payload.pending
    },
    M_COUNTER_PENDING_ROUND_LETTERS(state, payload) {
      state.S_COUNTER_PENDING_ROUND_LETTERS = payload
    },
    M_COUNTER_DEPT_RETURNED_LETTERS(state, payload) {
      state.S_COUNTER_DEPT_RETURNED_LETTERS = payload;
    },
    M_BUREAU_LETTERS_DATA(state, payload) {
      state.S_BUREAU_LETTERS_DATA = payload
    },
    M_IS_FOOTER_MODAL_ACTIVE(state, payload) {
      state.S_IS_FOOTER_MODAL_ACTIVE = payload
    },
    M_DETAILS_MODAL_ON(state, payload) {
      state.S_DETAILS_MODAL_ON = payload
    },
    M_EDIT_LETTER_MODAL(state, payload) {
      state.S_EDIT_LETTER_MODAL = payload
    },
    M_CREATE_DETAIL_LETTERS_MODAL(state, payload) {
      state.S_CREATE_DETAIL_LETTERS_MODAL = payload
    },
    M_REDIRECT_FROM_TABLE(state, payload) {
      state.S_REDIRECT_FROM_TABLE = payload
    },
    M_SET_UPDATE_LETTER_TABLE(state, payload) {
      state.S_UPDATE_LETTER_TABLE = payload
    },
    M_SET_SUM_TU(state, payload) {
      state.S_SUM_TU = payload;
    },
    M_SET_SUM_EX(state, payload) {
      state.S_SUM_EX = payload;
    },
    M_SET_SUM_EQ(state, payload) {
      state.S_SUM_EQ = payload;
    },
  },
  actions: {
    CHANGE_REFRESH_TABLE({ commit }, data) {
      commit("SET_REFRESH_TABLE", data);
    },
    A_SET_COUNTER_RETURNED_ROUND_LETTERS({ commit }, payload = { pending: 0 }) {
      commit('M_SET_COUNTER_RETURNED_ROUND_LETTERS', payload)
    },
    async A_COUNTER_PENDING_ROUND_LETTERS({ commit }) {
      const { data } = await LettersRoundService.getCounterPendingRoundLetters();

      commit("M_COUNTER_PENDING_ROUND_LETTERS", data);

      // Update sidebar
      if (data > 0) {
        const payload = {
          routeName: "analyst-department-letter-round",
          tag: data,
        };

        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
      }
    },
    async A_COUNTER_DEPT_RETURNED_LETTERS({ commit }, module) {
      const { data } = await LettersRoundService.getCounterReturnedLettersDepartment({
        moduleId: module,
        isConnection: module == 20,
      })

      commit("M_COUNTER_DEPT_RETURNED_LETTERS", data);

      // update sidebar
      const routes = {
        7: "bc-letters-dispute",
        8: "tr-letters-dispute",
        10: "cd-letters-dispute",
        12: "pr-letters-dispute",
        20: "cn-letters-dispute",
        21: "ce-letters-dispute",
        22: "csd-letters-dispute",
      }

      if(data > 0) {
        const payload = {
          routeName: routes[module],
          tag: data,
        }

        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
      }
    },
    A_BUREAU_LETTERS_DATA({ commit }, payload) {
      commit('M_BUREAU_LETTERS_DATA', payload)
    },
    A_IS_FOOTER_MODAL_ACTIVE({ commit }, payload) {
      commit('M_IS_FOOTER_MODAL_ACTIVE', payload)
    },
    A_DETAILS_MODAL_ON({ commit }, payload) {
      commit('M_DETAILS_MODAL_ON', payload)
    },
    A_EDIT_LETTER_MODAL({ commit }, payload) {
      commit('M_EDIT_LETTER_MODAL', payload)
    },
    A_CREATE_DETAIL_LETTERS_MODAL({ commit }, payload) {
      commit('M_CREATE_DETAIL_LETTERS_MODAL', payload)
    },
    A_REDIRECT_FROM_TABLE({ commit }, payload) {
      commit('M_REDIRECT_FROM_TABLE', payload)
    },
    A_S_UPDATE_LETTER_TABLE({ commit }, payload) {
      commit('M_SET_UPDATE_LETTER_TABLE', payload)
    }
  },
  getters: {
    getRefreshTable: (state) => state.refreshTable,
    G_COUNTER_RETURNED_ROUND_LETTERS: state => state.S_COUNTER_RETURNED_ROUND_LETTERS,
    G_COUNTER_PENDING_ROUND_LETTERS: state => state.S_COUNTER_PENDING_ROUND_LETTERS,
    G_COUNTER_DEPT_RETURNED_LETTERS: state => state.S_COUNTER_DEPT_RETURNED_LETTERS,
    G_BUREAU_LETTERS_DATA: state => state.S_BUREAU_LETTERS_DATA,
    G_IS_FOOTER_MODAL_ACTIVE: state => state.S_IS_FOOTER_MODAL_ACTIVE,
    G_DETAILS_MODAL_ON: state => state.S_DETAILS_MODAL_ON,
    G_EDIT_LETTER_MODAL: state => state.S_EDIT_LETTER_MODAL,
    G_CREATE_DETAIL_LETTERS_MODAL: state => state.S_CREATE_DETAIL_LETTERS_MODAL,
    G_REDIRECT_FROM_TABLE: state => state.S_REDIRECT_FROM_TABLE,
    G_UPDATE_LETTER_TABLE: state => state.S_UPDATE_LETTER_TABLE,
    G_SUM_TU: state => state.S_SUM_TU,
    G_SUM_EX: state => state.S_SUM_EX,
    G_SUM_EQ: state => state.S_SUM_EQ,
  },
}
