<template>
  <div class="px-2 upload-ci-document">
    <h4 class="text-primary" v-if="requiredFormats.length > 0">Formats</h4>
    <div>
      <b-row
        class="pb-2"
        v-if="requiredFormats.length > 0 || formats.length > 0"
      >
        <b-col
          xl="3"
          lg="6"
          md="12"
          sm="12"
          cols="12"
          class="px-2 pb-1"
          v-for="(format, index) in requiredFormats"
          :key="index"
        >
          <div class="container-upload-ci">
            <div class="download-icon-ci">
              <b-link
                :href="format.url"
                target="_blank"
                :disabled="!format.url"
                :id="'download' + index"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="icon-badge"
                  size="20"
                ></feather-icon>
              </b-link>
              <b-tooltip
                variant="primary"
                :target="'download' + index"
                noninteractive
                triggers="hover"
                >Download Format</b-tooltip
              >
            </div>
            <img
              src="@/assets/images/icons/document-ci.png"
              alt="flag-peru"
              class="flag"
            />

            <div
              style="font-size: 20px"
              class="mt-1 color-primary-dark-ci font-weight-bolder"
            >
              {{ format.format_name }}
            </div>
            <div style="font-size: 13px" class="px-3 text-center">
              Download, complete format and upload
            </div>
            <b-button
              variant="light"
              class="mt-1 color-primary-dark-ci"
              size="sm"
              @click="activeSearchFileInv(index, format.format_id)"
            >
              <feather-icon
                icon="UploadIcon"
                size="18"
                class="color-primary-dark-ci"
              ></feather-icon>
              <span
                style="
                  display: inline-block;
                  margin-bottom: 3px;
                  margin-left: 5px;
                "
              >
                Upload
              </span>
            </b-button>
            <input
              :id="`fileInputInv${index}`"
              type="file"
              :ref="`fileInputInv${index}`"
              style="display: none"
              accept="application/pdf"
              @change="handleFileChangeInv"
            />
          </div>
          <div
            v-if="wasUploadFormat(format.format_id)"
            class="container-file-upload-ci"
          >
            <div class="d-flex align-items-center color-primary-dark-ci">
              {{ getUploadFile(format.format_id).name | limitChars(20) }}
            </div>

            <div class="d-flex align-items-center">
              <feather-icon
                :id="`pre-${index}`"
                icon="EyeIcon"
                class="cursor-pointer color-primary-dark-ci"
                @click="openPreviewPdf(format.format_id)"
              ></feather-icon>
            </div>
          </div>
        </b-col>
      </b-row>

      <div v-else class="mt-1">
        <b-alert variant="info" show>
          <div class="alert-body">
            <span v-if="isSupervisor || isCeo || isChief">
              No formats found for this document. Please upload with the
              following button
            </span>
            <span v-else>
              No formats found for this document. Please contact your supervisor
            </span>
          </div>
        </b-alert>
        <download-formats
          :action-type="2"
          :type-document="documentType"
          v-if="isSupervisor || isCeo || isChief"
        />
      </div>
    </div>
    <h4 class="text-primary" v-if="formats.length > 0">Uploaded Formats</h4>
    <div>
      <pdf-list
        :pdf-list="formats"
        @format-deleted="reloadFormatsUploaded"
        :key="keyFormatsUploaded"
      ></pdf-list>
    </div>
    <preview-pdf
      v-if="showPreviewPdf"
      :pdf-file="pdfFile"
      :pdf-name="pdfName"
      @close="showPreviewPdf = false"
    ></preview-pdf>
  </div>
</template>

<script>
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";
import PdfList from "@/views/debt-solution/views/court-info/components/modals/ListPdf.vue";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service.js";
import DownloadFormats from "@/views/debt-solution/views/court-info/components/modals/DownloadTypeDocument.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PreviewPdf,
    PdfList,
    DownloadFormats,
  },

  props: {
    caseId: {
      type: [String, Number],
      required: true,
    },
    leadId: {
      type: [String, Number],
      required: true,
    },
    documentType: {
      type: [String, Number],
      required: true,
    },
    clientAccountId: {
      type: String,
      required: false,
    },
    documentName: {
      type: String,
      required: false,
    },
    caseTypeDocumentId: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      showPreviewPdf: false,
      pdfFile: null,
      pdfName: null,
      requiredFormats: [],
      lastIdAdd: null,
      formats: [],
      wasNotSaved: true,
      modalSendToCorrespondence: false,
      keyFormatsUploaded: 0,
      missingFormats: [],
    };
  },

  methods: {
    activeSearchFileInv(input, id) {
      const element = "fileInputInv" + input;
      this.lastIdAdd = id;
      this.$refs[element][0].click();
    },
    handleFileChangeInv(event) {
      const file = event.target.files[0];
      const element = {
        id: this.lastIdAdd,
        file: file,
        name: file.name,
      };

      const index = this.formats.findIndex(
        (item) => item.id === this.lastIdAdd
      );
      if (index !== -1) {
        this.formats.splice(index, 1);
      }
      this.formats.push(element);
    },

    deleteFile(array = [], index) {
      array.splice(index, 1);
    },
    async reloadFormatsUploaded() {
      await this.getUploadFormats();
      this.getFormats();
      this.keyFormatsUploaded++;
    },
    async openPreviewPdf(id) {
      const objFormat = this.getUploadFile(id);
      this.pdfName = objFormat.name;
      this.pdfFile = objFormat.file;
      this.showPreviewPdf = true;
    },

    async getFormats() {
      const { data } = await CourtInfoService.getRequiredFormatsByDocumentCase({
        case_type_document_id: this.caseTypeDocumentId,
      });
      // requiredFormats is missing formats uploaded
      this.requiredFormats = data;

      // console.log("requiredFormats", this.requiredFormats);
    },

    async saveDocuments() {
      if (this.requiredFormats.length === 0 && this.formats.length === 0) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          "This document does not have any formats"
        );
        return false;
      }

      // all required formats are uploaded
      const allRequiredFormatsAreUploaded = this.requiredFormats.every(
        (requiredFormat) => {
          return this.formats.some((format) => {
            return format.id === requiredFormat.format_id;
          });
        }
      );
      if (!allRequiredFormatsAreUploaded) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          "Upload all formats"
        );
        return false;
      }

      if (this.requiredFormats.length === 0 && this.formats.length !== 0) {
        this.$emit("nextStep", 2);
        return true;
      }
      const formData = new FormData();
      formData.append("case_type_document_id", this.caseTypeDocumentId);
      formData.append("user_id", this.currentUser.user_id);
      formData.append("case_id", this.caseId);
      this.formats.forEach((format) => {
        if (typeof format.url != "string") {
          formData.append("formats[]", format.file);
          formData.append("formats_id[]", format.id);
        }
      });

      let confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          this.addPreloader();
          const { status } = await CourtInfoService.saveDocuments(formData);
          if (status === 200) {
            this.showSuccessSwal();
            this.$emit("nextStep", 2);
            this.removePreloader();
            this.getUploadFormats();
            this.getFormats();
            return true;
          }
        } catch (e) {
          this.showErrorSwal(error);
        }
        return false;
      }
      return false;
    },

    async getUploadFormats() {
      try {
        this.addPreloader();
        const { data } = await CourtInfoService.getUploadFormatsStep({
          case_id: this.caseId,
        });
        if (data.body.length > 0) {
          this.formats = data.body;
          this.wasNotSaved = false;
        } else {
          this.formats = [];
          this.wasNotSaved = true;
        }
      } catch (e) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    wasUploadFormat(id) {
      return this.formats.some((item) => item.id == id);
    },

    getUploadFile(id) {
      return this.formats.find((item) => item.id === id);
    },
  },

  async mounted() {
    await this.getUploadFormats();
    this.getFormats();
  },
};
</script>

<style lang="scss">
.upload-ci-document {
  .container-upload-ci {
    font-family: "Rubick";
    position: relative;
    height: 250px;
    border: 1px solid #b5b5b8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .container-file-upload-ci {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #b5b5b8;
    border-left: 1px solid #b5b5b8;
    border-right: 1px solid #b5b5b8;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .color-primary-dark-ci {
    color: #234a77;
  }

  .download-icon-ci {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>