/* eslint-disable import/no-cycle */
/* eslint-disable indent */
import store from '@/store';

export default {
    namespaced: true,
    state: {
        S_PROJECT_SELECTED: null,
        S_LAST_MESSAGE: {},
        S_MESSAGES_VIEWED: [],
    },
    getters: {
        GET_PROJECT_SELECTED(state) {
            return state.S_PROJECT_SELECTED;
        },
        GET_LAST_MESSAGE(state) {
            return state.S_LAST_MESSAGE;
        },
        GET_MESSAGES_VIEWED(state) {
            return state.S_MESSAGES_VIEWED;
        }
    },
    mutations: {
        SET_LAST_MESSAGE(state, payload) {
            state.S_LAST_MESSAGE = payload;
        },
        SET_PROJECT_SELECTED(state, payload) {
            state.S_PROJECT_SELECTED = payload.projectId;
        },
        SET_MESSAGE_VIEWED(state, payload) {
          payload.forEach(element => {
            state.S_MESSAGES_VIEWED.push(element);
          });
        },
        SET_MESSAGE_REVIEWED(state, payload) {
            const index = state.S_MESSAGES_VIEWED.indexOf(payload);
            if (index !== -1) {
              state.S_MESSAGES_VIEWED.splice(index, 1);
            }
        },
        SET_PROJECT_NOT_SELECTED(state) {
            state.S_PROJECT_SELECTED = null;
        }
    },
    actions: {
        async A_SET_LAST_MESSAGE({ commit }, payload) {
            if (store.state.auth.currentUser.user_id !== payload.emisor_id) {
                if (Object.keys(payload).length !== 0) {
                    commit('SET_LAST_MESSAGE', {
                        emisor: payload.messageData.createdBy,
                        name: payload.messageData.name,
                        file: payload.messageData.file,
                        fileName: payload.messageData.fileName,
                        message: payload.messageData.message,
                        createdAt: payload.messageData.createdAt,
                        id: payload.messageData.id,
                        is_fixed: payload.messageData.is_fixed,
                        is_reviewed: payload.messageData.is_read,

                    });
                }
            }
        },
        A_SET_PROJECT_SELECTED({ commit }, payload) {
            commit('SET_PROJECT_SELECTED', payload);
        },
        A_SET_MESSAGE_VIEWED({ commit }, payload) {
            commit('SET_MESSAGE_VIEWED', payload);
        },
        A_SET_MESSAGE_REVIEWED({ commit }, payload) {
            commit('SET_MESSAGE_REVIEWED', payload);
        },
        A_RESET_PROJECT_SELECTED({ commit }) {
            commit('SET_PROJECT_NOT_SELECTED', null);
        }
    },
};
