export default [
  {
    path: 'organization-chart',
    name: 'organization-chart',
    component: () => import(/* webpackChunkName: "OrganizationChart" */ '@/views/commons/organization-chart/OrganizationChart.vue'),
    meta: {
      pageTitle: 'Organization Chart',
      breadcrumb: [{
        text: 'Organization Chart',
      }],
    },
  },
];
