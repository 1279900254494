<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 border-0">
      <filter-slot
        :filter="isAdvisor"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refListTable'].refresh()"
        @reset-all-filters="$refs.refListTable.refresh()"
      >
        <b-table
          slot="table"
          ref="refListTable"
          primary-key="id"
          empty-text="No matching records found"
          class="position-relative table-new-customization"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          sort-icon-left
          show-empty
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :fields="fields"
          :items="myProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy.sync="isBusy"
          @row-selected="onRowSelected"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <!-- Column: Name -->
          <template #cell(lead_name)="data">
            <div>
              <router-link
                :class="[textLink]"
                target="_blank"
                class
                :to="{
                  name: pathNameDashboard,
                  params: {
                    id: data.item.id,
                  },
                  props: { programsAll: programsAll },
                }"
                ><span>{{ data.item.lead_name }}</span>
              </router-link>
            </div>
            <div
              v-if="data.item.user_responsible_date"
              class="text-warning font-weight-bolder"
            >
              <feather-icon style="margin-right: 3px" icon="StarIcon" />
              CEO's client
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <div class="d-flex justify-content-center">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize w-75"
                >{{ data.item.status }}</b-badge
              >
              <p />
            </div>
          </template>

          <!-- Column: Credit Report -->
          <template #cell(credit_report)="data">
            <div>
              <strong
                :class="`text-${
                  data.item.credit_report == 1 ? 'danger' : 'success'
                }`"
                >{{ data.item.credit_report == 1 ? "NO" : "YES" }}</strong
              >
            </div>
          </template>

          <!-- Column: Programs -->
          <template #cell(programs)="data">
            <div
              v-if="data.item.programs"
              class="d-flex flex-column"
              style="gap: 0.5rem"
            >
              <template
                v-for="(program, key) in JSON.parse(data.item.programs)"
              >
                <b-img
                  :key="key"
                  thumbnail
                  fluid
                  :src="getProgramImageRoute(program.id)"
                  style="width: 40px"
                  v-b-tooltip.hover.top="program.value"
                />
              </template>
            </div>
          </template>

          <!-- Column: Created By -->
          <template #cell(created_at)="data">
            <div>
              <div>{{ data.item.owner }}</div>
              <small>{{ data.item.created_at | myGlobalDay }}</small>
            </div>
          </template>

          <!-- Column: Actions -->
          <template
            v-if="currentUser.role_id < 4 || isTeamLeader || isAgent || isChief"
            #cell(actions)="data"
          >
            <div
              v-if="
                data.item.user_responsible == currentUser.user_id ||
                data.item.user_responsible == null
              "
            >
              <!-- <actions-table
                :options="actionsOptions"
                :row-data="data.item"
                :row-index="data.index"
                :can-derivate="true"
                @onRowDelete="onRowDelete"
                @onRowProcess="onRowProcess"
                @modalAddService="modalAddService"
                @derivationActionModal="
                  openDerivationActionModal(data.item, data.index)
                "
                @collectCr="openModalListCR"
                @derivationTrackingModal="
                  openDerivationTrackingModal(data.item)
                "
              /> -->
              <feather-icon
                icon="TrashIcon"
                class="text-danger cursor-pointer"
                size="22"
                v-b-tooltip.hover.top="'Delete'"
                @click="onRowDelete(data.item)"
              />
            </div>
          </template>
          <!-- <template #cell(client_ceo)="data">
            <span class="text-warning">
              <template v-if="data.item.user_responsible_date">From </template>
              {{ data.item.user_responsible_date | myGlobal }}
            </span>
          </template> -->
        </b-table>
      </filter-slot>
    </b-card>
    <!-- Modal Add Services -->
    <modal-add-programs
      v-if="modalAddProgramsState"
      :sales-client="salesClient"
      :current-user="currentUser"
      :programs-all="programsAll"
      :users-services="servicesUsers"
      :newLead="true"
      @hideModal="closeModalAddPrograms"
      @changeProgram="openModalProgram"
      @leadEvent="leadEvent"
    />
    <!-- Dynamic Component -->
    <!-- <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :type-modal="modalData.programs.typeModal"
      :sales-client="modalData.programs.salesClient"
      :programs-all="programsAll"
      :users-services="servicesUsers"
      :header-s="headerS"
      @closeModal="hideModalProgram"
      @changeProgram="openModalProgram"
    /> -->

    <derivation-action-modal
      v-if="derivationActionModal"
      :derivation-data="derivationData"
      @send="updateRow"
      @closeModal="closeDerivationActionModal"
    />

    <derivation-tracking-modal
      v-if="derivationTrackingModal"
      :derivation-data="derivationData"
      @closeModal="closeDerivationTrackingModal"
    />

    <modal-list-c-r
      v-if="modalListCR"
      :lead="lead"
      @hidden="modalListCR = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ActionsTable from "@/views/crm/views/Lead/lead-table/ActionsTable.vue";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import DerivationActionModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationActionModal.vue";
import DerivationTrackingModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationTrackingModal.vue";
import ModalListCR from "@/views/commons/components/lead-programs/components/lead-table/ModalListCR.vue";
import ModalAddPrograms from "./ModalAddService.vue";
import LeadService from "../../service/lead.service";
import dataFilters from "./dataFilters.data";
import dataFields from "./fields.data";

export default {
  components: {
    DerivationTrackingModal,
    DerivationActionModal,
    FilterSlot,
    ActionsTable,
    BusinessModal,
    CreditExpertsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    ModalAddPrograms,
    ModalListCR,
  },
  data() {
    return {
      isOnlyLead: false,
      type: 0,
      actionsOptions: [],
      baseUrl: process.env.VUE_APP_BASE_URL_ASSETS,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      isBusy: false,
      fields: dataFields.leadFields,
      filter: dataFilters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      perPageOptions: [10, 25, 50, 100],
      sortBy: "",
      sortDesc: true,
      rowData: {},
      name_leads_arr: [],
      selectAll: false,
      leadsSelecteds: [],
      modal: {
        programs: false,
      },
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      dataModal: "",
      modalAddProgramsState: false,
      items: [],
      owner: [],
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      salesClient: {},
      programsAll: [],
      usersServices: [],
      servicesUsers: [],
      headerS: {},
      dataEvent: [],
      event: [],
      scoreAttend: null,
      event_id: 0,
      derivationActionModal: false,
      derivationData: null,
      derivationIndex: null,
      leadItems: [],
      derivationTrackingModal: false,
      modalListCR: false,
      lead: null,
    };
  },
  computed: {
    ...mapState({
      S_LEADS: (state) => state.CrmLeadStore.S_LEADS,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_SOURCE_NAMES: "CrmGlobalStore/G_SOURCE_NAMES",
      G_UPDATE_TABLE_LEAD: "CrmLeadStore/G_UPDATE_TABLE_LEAD",
      G_STATES: "CrmGlobalStore/G_STATES",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    pathNameDashboard() {
      switch (this.moduleId) {
        case 5:
          return "debtsolution-lead-show";
          break;
        case 22:
          return "customerservice-lead-show";
          break;
        case 7:
          return "boostcredit-lead-show";
          break;
        case 8:
          return "tax-research-lead-show";
          break;
        case 10:
          return "claimdepartment-lead-show";
          break;
        case 12:
          return "ti-lead-show";
          break;
        case 3:
          return "bussiness-lead-show";
          break;
        case 14:
          return "bookeeping-lead-show";
          break;
        case 6:
          return "creditexperts-lead-show";
          break;
        case 20:
          return "connection-lead-show";
          break;
        case 11:
          return "specialist-lead-show";
          break;
        case 23:
          return "financial-leads-lead-show";
          break;
      }
    },
    pathNameSaleMade() {
      switch (this.moduleId) {
        case 5:
          return "sales-made-debtsolution-lead";
          break;
        case 7:
          return "sales-made-boostcredit-lead";
          break;
        case 8:
          return "sales-made-tax-research-lead";
          break;
        case 10:
          return "sales-made-claimdepartment-lead";
          break;
        case 12:
          return "sales-made-paragon-lead";
          break;
        case 3:
          return "sales-made-bussiness-lead";
          break;
        case 14:
          return "sales-made-bookeeping-lead";
          break;
        case 6:
          return "sales-made-creditexperts-lead";
          break;
        case 20:
          return "sales-made-connection-lead";
          break;
        case 11:
          return "sales-made-specialists-add-change";
          break;
      }
    },
    isTeamLeader() {
      return this.currentUser.role_id === 11 && this.moduleId === 6;
    },
    isAdvisor() {
      let result = [];
      if (
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.role_id == 17 ||
        this.isTeamLeader
      ) {
        // CEO and Supervisor
        result = this.filter;
        return result;
      }
      if (
        this.currentUser.role_id == 3 ||
        this.currentUser.role_id == 15 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.role_id == 4
      ) {
        // Advisor and Operator
        result = [...this.filter];
        result.pop();
        return result;
      }
    },
  },
  async created() {
    this.addPaddingTd();
    await Promise.all([this.getUserAllPrograms()]);
  },
  // mounted() {},
  methods: {
    getFilterByKey(key) {
      const filter = this.filter.find((filter) => filter.key === `${key}`);
      return filter;
    },
    ...mapActions({
      A_SET_FILTERS_LEADS: "CrmLeadStore/A_SET_FILTERS_LEADS",
      A_SET_SELECTED_LEADS: "CrmLeadStore/A_SET_SELECTED_LEADS",
      A_DELETE_LEADS: "CrmLeadStore/A_DELETE_LEADS",
      A_PROCESS_LEADS: "CrmLeadStore/A_PROCESS_LEADS",
      A_ADD_SELLER_LIST: "CrmLeadStore/A_ADD_SELLER_LIST",
      A_SET_UPDATE_TABLE_LEAD: "CrmLeadStore/A_SET_UPDATE_TABLE_LEAD",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
    }),
    async getAllPrograms() {
      try {
        const data = await LeadService.getPrograms();
        if (data) {
          this.programsAll = data;
          const sper = this.currentUser.sale_permission;
          const spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getUserAllPrograms() {
      let resultUsers = await LeadService.getUsersModule(
        this.$route.matched[0].meta.module,
        {
          roles: "[]",
          type: "1",
        }
      );
      // Only for Business
      if (this.moduleId === 3) {
        resultUsers = await LeadService.getUsersAllPrograms({
          id: this.moduleId,
        });
      }
      // Data for filter
      const dataFilter = [...resultUsers];
      if (dataFilter) {
        const usersOwner = dataFilter.map((item) => ({
          id: item.id,
          label: item.user_name,
        }));
        usersOwner.unshift({
          id: "0",
          label: "All",
        });

        // this.getFilterByKey("owner").options = usersOwner;
      }

      // Data for Modal Add Service
      const dataModal = [...resultUsers];
      if (dataModal) {
        dataModal.forEach((item) => {
          if (item.id == 1) {
            dataModal.splice(dataModal.indexOf(item), 1);
          }
        });
        dataModal.unshift({
          id: 1,
          user_name: "CEO",
        });
        this.servicesUsers = dataModal;
      }

      this.getFilterByKey("source_name").options = this.G_SOURCE_NAMES;
      this.getFilterByKey("states_eeuu_slug").options = this.G_STATES;
    },
    resolveUserStatusVariant(status) {
      if (status === "Pending") return "warning";
      if (status === "Active") return "success";
      if (status === "Inactive") return "secondary";
      if (status === "Not Contacted") return "danger";
      return "primary";
    },
    selectedAll() {
      if (this.selectAll) {
        this.S_LEADS.items.forEach((item) => (item.selected = true));
      } else this.S_LEADS.items.forEach((item) => (item.selected = false));
      this.onRowSelected();
    },
    onSelectedRow(data) {
      const index = this.leadsSelecteds.findIndex(
        (select) => select.id === data.id
      );
      if (data.selected === true && index === -1) {
        this.leadsSelecteds.push(data);
      } else if (data.selected === false && index !== -1) {
        this.leadsSelecteds.splice(index, 1);
      }
      this.onRowSelected();
    },
    async myProvider(ctx) {
      try {
        this.setFilters();
        const resp = await LeadService.getLeads({
          name_text: this.filterPrincipal.model,
          lead_status: this.getFilterByKey("status_lead").model,
          cr: this.getFilterByKey("cr").model,
          date_from: this.getFilterByKey("date_from").model,
          date_to: this.getFilterByKey("date_to").model,
          // user_owner: this.getFilterByKey("owner").model,
          state_eeuu: this.getFilterByKey("states_eeuu_slug").model,
          sourcename: this.getFilterByKey("source_name").model,
          module_id: this.moduleId,
          userid: this.currentUser.user_id,
          roleid: this.isTeamLeader ? 2 : this.currentUser.role_id,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
        });
        this.startPage = resp.from;
        this.toPage = resp.to;
        this.totalRows = resp.total;
        this.paginate.currentPage = resp.current_page;
        this.paginate.perPage = resp.per_page;
        this.leadItems = resp.data;
        return this.leadItems;
      } catch (error) {
        console.log("Somtehing went wrong myProvider", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    setFilters() {
      this.A_SET_FILTERS_LEADS({
        from: this.getFilterByKey("date_from"),
        to: this.getFilterByKey("date_to"),
        statusLead: this.getFilterByKey("status_lead"),
        // owner: this.getFilterByKey("owner"),
        state: this.getFilterByKey("states_eeuu_slug"),
        owner: null,
        cr: this.getFilterByKey("cr"),
        sourceName: this.getFilterByKey("source_name"),
        perPage: this.paginate.perPage,
        currentPage: this.paginate.currentPage,
      });
    },
    onRowSelected() {
      this.A_SET_SELECTED_LEADS(this.leadsSelecteds);
    },
    async onRowDelete(item) {
      const confirm = await this.showConfirmSwal(
        `Are you sure to delete this lead: ${item.lead_name}?`
      );
      if (confirm.isConfirmed) {
        try {
          const { user_id } = this.currentUser;
          const response = await this.A_DELETE_LEADS({
            lead_id: item.id,
            user_id,
          });

          if (this.isResponseSuccess(response)) {
            this.$refs.refListTable.refresh();
            this.removePreloader();
            this.showToast(
              "success",
              "top-right",
              "Deleted!",
              "CheckIcon",
              "The Lead has been deleted."
            );
          }
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal(error);
        }
      }
    },
    leadEvent(data) {
      if (data) {
        this.dataEvent = [{ event_id: data.id }, { module: this.moduleId }];
      }
    },
    async modalAddService(data) {
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?",
        "You won't be able to revert this!"
      );
      if (result.isConfirmed) {
        this.getAllPrograms();
        this.modalAddProgramsState = true;
        this.salesClient = data;
      }
    },
    async openModalProgram(data) {
      if (data) {
        if (data.captured.id && data.program.id && data.seller.id) {
          // const response = await this.showConfirmSwal(
          //   "Are you sure?",
          //   "You won't be able to revert this!"
          // );
          // if (response.isConfirmed) {
          await this.addPreloader();
          try {
            const result = await LeadService.insertSaleDebt({
              id: this.dataEvent[0].event_id,
              program: data.program.id,
              done: 1,
              module: this.moduleId,
              captured: data.captured.id,
              seller: data.seller.id,
            });
            if (result.status == 200) {
              this.modalAddProgramsState = false;
              this.$router
                .push({ name: this.pathNameSaleMade })
                .catch((err) => {
                  console.log(err);
                });
              this.removePreloader();
            }
          } catch (error) {
            throw error;
          }
          // }
        }
      }
    },

    closeModalAddPrograms(stado) {
      this.modalAddProgramsState = stado;
    },
    async hideModalProgram(refresh) {
      // redirect to Sales Made
      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    onRowProcess(id) {
      this.showConfirmSwal(
        "Are you sure?",
        "You won't be able to revert this!",
        {
          input: "textarea",
          inputValidator: (value) => {
            if (!value) {
              return "You need to write something!";
            }
          },
        }
      )
        .then(async (result) => {
          if (result.value) {
            const { user_id, role_id } = this.currentUser;
            const response = await this.A_PROCESS_LEADS({
              lead_id: id,
              status: 3,
              user_id,
              description: result.value,
            });
            if (this.isResponseSuccess(response)) {
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else {
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                `Something went wrong.${response.message}`
              );
            }
          }
        })
        .catch((error) => {
          console.log("Something went wrong onRowProcess:", error);
          this.showErrorSwal(error);
        });
    },
    async addListSeller() {
      const confirm = await this.showConfirmSwal(
        "Are you sure?",
        "You are going to add this leads to your List"
      );
      if (confirm.isConfirmed) {
        this.addPreloader();
        // filter just the owner of the lead
        const leadList = this.leadsSelecteds.map((el) => el.id);
        try {
          const params = {
            user_id: this.currentUser.user_id,
            list_lead: leadList,
            module_id: this.moduleId,
          };
          const response = await this.A_ADD_SELLER_LIST(params);
          this.removePreloader();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Leads were added to your list"
          );
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal(error);
        }
      }
    },
    resetQuickData(item) {
      this.quickData = item;
    },
    openDerivationActionModal(item, index) {
      this.derivationData = item;
      this.derivationIndex = index;
      this.derivationActionModal = true;
    },
    closeDerivationActionModal() {
      this.derivationActionModal = false;
    },
    updateRow() {
      this.$set(this.leadItems[this.derivationIndex], "is_derivated", 1);
      this.$set(this.items[this.derivationIndex], "is_in_sale_process", 1);
    },
    openDerivationTrackingModal(item) {
      this.derivationData = item;
      this.derivationTrackingModal = true;
    },
    closeDerivationTrackingModal() {
      this.derivationTrackingModal = false;
    },
    openModalListCR(item) {
      this.lead = item;
      this.modalListCR = true;
    },
  },
  watch: {
    G_UPDATE_TABLE_LEAD(newVal) {
      if (newVal) {
        this.$refs.refListTable.refresh();
      }
    },
  },
  mounted() {
    this.leadEvent();
    if (![4].includes(this.currentUser.role_id) && !this.isOnlyLead) {
      this.fields.unshift({
        key: "selected",
        label: "",
        sortable: false,
      });
    }
    if ([1, 2, 11, 17].includes(this.currentUser.role_id) && this.type === 0)
      this.actionsOptions.push("delete");
    // this.actionsOptions.push("addService"); // Add Service
    this.actionsOptions.push("derivationActionModal"); // Derivation Action
    this.actionsOptions.push("derivationTrackingModal"); // Derivation Tracking

    if (
      this.$route.matched[0].meta.module === 22 ||
      this.$route.matched[0].meta.module === 23 ||
      this.$route.matched[0].meta.module === 6 ||
      this.$route.matched[0].meta.module === 20
    ) {
      this.actionsOptions.push("derivationActionModal"); // Derivation Action
      this.actionsOptions.push("derivationTrackingModal"); // Derivation Tracking
    }
  },
};
</script>

<style lang="scss" scoped>
.table-responsive > div {
  min-height: 15rem;
}
</style>
