export const LetterRoundCompletedSubTabs = (tab) => {
  return [
      {
          path: "in-correspondence",
          component: () =>
              import(
                  /* webpackChunkName: "inCorrespondenceRoundLettersTable" */
                  "@/views/specialists/sub-modules/analyst-department/views/letter-round/LetterRoundCompleted.vue"
              ),
          name: `ad-letter-round-completed-in-correspondence`,
          meta: {
              tabTitle: "In-correspondence",
              subTabName: "in-correspondence",
              pageTitle: "Letter Dispute",
              breadcrumb: [
                  {
                      text: "In Correspondence",
                      active: true,
                  },
              ],
              paramTab:tab
          },
      },
      {
          path: "sent",
          component: () =>
              import(
                  /* webpackChunkName: "sentRoundLettersTable" */
                  "@/views/specialists/sub-modules/analyst-department/views/letter-round/LetterRoundCompleted.vue"
              ),
          name: `ad-letter-round-to-send-completed-sent`,
          meta: {
              tabTitle: "Sent",
              subTabName: "sent",
              pageTitle: "Letter Dispute",
              breadcrumb: [
                  {
                      text: "Sent",
                      active: true,
                  },
              ],
              paramTab:tab
          },
      },
  ]
};
