export default [
  {
    path: "/specialists-dashboard-client/:idClient",
    name: "specialists-dashboard-general",
    redirect: { name: "personal-information-specialist-general" },
    component: () =>
      import(
        /* webpackChunkName: "accountSpecialists" */
        "@/views/specialists/views/clients/dashboard/AccountSpecialists.vue"
      ),
    meta: {
      moduleName: "specialists",
      general: true,
      module: 28,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Personal information",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay-general",
        name: "pay-specialist-general",
        component: () =>
          import(
            /* webpackChunkName: "paySpecialists" */
            "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "dis",
        name: "dis-specialist-general",
        redirect: { name: "report-detail-spad-general" },
        component: () =>
          import(
            /* webpackChunkName: "disSpecialists" */
            "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        meta: {
          isTicket: true,
          module: 28,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Dispute",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "dispute-detail",
            name: "dispute-detail-spad-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/DisputeDetail.vue"
              ),
            meta: {
              isTicket: true,
              pageTitle: "Dispute Detail",
              breadcrumb: [
                {
                  text: "Dispute Detail",
                  active: true,
                },
              ],
            },
          },
          {
            path: "report-detail",
            name: "report-detail-spad-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
              ),
            meta: {
              isTicket: true,
              pageTitle: "Report Detail",
              breadcrumb: [
                {
                  text: "Report Detail",
                  active: true,
                },
              ],
            },
          },
          {
            path: "updates-detail",
            name: "updates-detail-spad-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/UpdateDetail.vue"
              ),
            meta: {
              isTicket: true,
              pageTitle: "Updates Detail",
              breadcrumb: [
                {
                  text: "Updates Detail",
                  active: true,
                },
              ],
            },
          },
            {
            path: "workplan-detail",
            name: "workplan-detail-spad-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Workplan Detail",
              breadcrumb: [
                {
                  text: "Workplan Detail",
                  active: true,
                },
              ],
            },
          },
            {
            path: "recommendation-detail",
            name: "recommendation-detail-spad-general",
            component: () =>
              import(
                /* webpackChunkName: "recommendationDetailSpad" */   "@/views/commons/components/clients/dashboard/options/services/services.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Recommendations",
              breadcrumb: [
                {
                  text: "Recommendations",
                  active: true,
                },
              ],
            },
          },
        ],
      },

      {
        path: "acc-general",
        name: "acc-specialist-general",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientAccountAcc" */ "@/views/commons/components/clients/dashboard/options/acc-module/Acc.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acc",
              active: true,
            },
          ],
        },
      },
      {
        path: "task-notes-general",
        name: "task-notes-specialist-general",
        component: () =>
          import(
            /* webpackChunkName: "taskNotesSpecialists" */
            "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information-general",
        name: "personal-information-specialist-general",
        component: () =>
          import(
            /* webpackChunkName: "personalInformationSpecialists" */
            "@/views/commons/components/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files-general",
        name: "client-files-specialist-general",
        component: () =>
          import(
            /* webpackChunkName: "clientFilesSpecialists" */
            "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr-general",
        name: "acr-specialist-general",
        component: () =>
          import(
            /* webpackChunkName: "acrSpecialists" */
            "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "report-general",
        name: "report-specialist-general",
        component: () =>
          import(
            /* webpackChunkName: "reportSpecialists" */
            "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Report",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log-general",
        name: "call-log-client-specialist-general",
        // redirect: { name: 'specialists-client-general-call' },
        component: () =>
          import(
            /* webpackChunkName: "reportSpecialists" */
            "@/views/business/views/clients/dashboard/views/components/sections/CallLogSubtabs.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 11,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
        },
        // children: [
        //   {
        //     path: 'general-call',
        //     name: 'specialists-client-general-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'General',
        //       breadcrumb: [
        //         {
        //           text: 'General',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     path: 'ceo-call',
        //     name: 'specialists-client-ceo-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/CeoCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'CEO calls',
        //       breadcrumb: [
        //         {
        //           text: 'CEO calls',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        path: "tickets-general",
        name: "client-tickets-specialist-general",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
