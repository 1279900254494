import { amgApi } from "@/service/axios";

class predefinedAnswersService {
  async getPredefinedAnswers(params) {
    try {
      const data = await amgApi.post(
        "/business/settings/predefined-answers/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPredefinedAnswerById(id) {
    try {
      const data = await amgApi.get(
        `/business/settings/predefined-answers/show/${id}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updatePredefinedAnswer(id, params) {
    try {
      const data = await amgApi.post(
        `/business/settings/predefined-answers/update/${id}`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new predefinedAnswersService();
