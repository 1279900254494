<template>
  <div>
    <span v-if="!currency">{{ formattedNumber }}</span>
    <span v-else>$ {{ formattedNumber | currencyZero }}</span>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: [Number, String],
      default: 0,
    },
    animationDuration: {
      type: Number,
      default: 1500,
    },
    currency: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      displayNumber: 0,
      interval: null,
      steps: 60,
    };
  },
  computed: {
    formattedNumber() {
      // Ensure displayNumber always shows two decimal places
      return this.displayNumber.toFixed(2);
    },
  },
  mounted() {
    this.displayNumber = parseFloat(this.number);
  },
  watch: {
    number(newVal) {
      clearInterval(this.interval);

      const parsedNewVal = parseFloat(newVal);

      if (isNaN(parsedNewVal) || parsedNewVal === this.displayNumber) {
        return;
      }

      const change = parsedNewVal - this.displayNumber;
      const stepSize = change / this.steps;
      let stepsTaken = 0;

      this.interval = setInterval(() => {
        if (stepsTaken < this.steps) {
          this.displayNumber += stepSize;
          stepsTaken++;
        } else {
          this.displayNumber = parsedNewVal;
          clearInterval(this.interval);
        }
      }, this.animationDuration / this.steps);
    },
  },
};
</script>