export default {
  path: "folders",
  name: "file-mananger-crm",
  component: () => import(/* webpackChunkName: "CrmFileManager" */ "./FileMananger.vue"),
  meta: {
    pageTitle: "File Manager",
    breadcrumb: [
      {
        text: "File Manager",
        active: true,
      },
    ],
  },
};
