import clientsListNav from "@/views/specialists/sub-modules/analyst-department/views/clients/clients-list/navigation/clients-list.nav.js";
import customerTicketsNav from "@/views/specialists/sub-modules/analyst-department/views/clients/customer-tickets/navigation/customer-tickets.nav.js";
import paymentReportNav from "@/views/specialists/sub-modules/analyst-department/views/payment-report/navigation/payment-report.nav.js";
import claimsNav from "@/views/specialists/sub-modules/analyst-department/views/claims/navigation/claims.nav.js";
import RingCentralNav from "@/views/specialists/sub-modules/analyst-department/views/ring-central/ring-central.nav.js";

export default {
    title: "Clients",
    icon: "UsersIcon",
    children: [
        clientsListNav,
        customerTicketsNav,
        paymentReportNav,
        claimsNav,
        RingCentralNav
    ],
};