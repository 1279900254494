const { amgApi } = require("@/service/axios");


class ClientsService {
  async getClients(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/sales/get-clients",
        params
      );
      return data;
    } catch (error) {
      console.log("Error getClients");
    }
  }
  async trackingLeadsAndClients(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/sales/get-tracking-lead-clients",
        params
      );
      return data;
    } catch (error) {
      console.log("Error trackingLeadsAndClients");
    }
  }
  async usersPrograms(params) {
    try {
      const data = await amgApi.post("/commons/users-programs", params);
      return data;
    } catch (error) {
      console.log("Error usersPrograms");
    }
  }

  async programs() {
    try {
      const data = await amgApi.get("/commons/programs");
      return data;
    } catch (error) {
      console.log("Error programs");
    }
  }
  async usersAllPrograms(params) {
    try {
      const data = await amgApi.post("/commons/users-all-programs", params);
      return data;
    } catch (error) {
      console.log("Error usersAllPrograms");
    }
  }
  async searchClientPayment(params) {
    try {
      const data = await amgApi.post("/clients/search-client-payment", params);
      return data;
    } catch (error) {
      console.log("Error searchClientPayment");
    }
  }
  async getSellers(params) {

    let data = await amgApi.post(`credit-experts-digital/sales/get-sellers`, params);
    return data;
  }
}

export default new ClientsService();
