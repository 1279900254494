import store from "@/store"
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";

export default {
    namespaced: true,
    state: {
        refreshTable: false,
        S_PENDING_NCR_CLIENT: 0,
        S_PENDING_NCR_LEAD: 0,
        S_APPOINTMENT_EXIST: false,
        S_PENDING_BY_LEAD: 0,
        S_PENDING_BY_CLIENT: 0
    },

    getters: {
        getRefreshTable: (state) => state.refreshTable,
        G_PENDING_NCR_CLIENT(state) {
            return state.S_PENDING_NCR_CLIENT
        },
        G_PENDING_NCR_LEAD(state) {
            return state.S_PENDING_NCR_LEAD
        },
        G_APPOINTMENT_EXIST(state) {
            return state.S_APPOINTMENT_EXIST
        },
        G_PENDING_BY_LEAD(state) {
            return state.S_PENDING_BY_LEAD
        },
        G_PENDING_BY_CLIENT(state) {
            return state.S_PENDING_BY_CLIENT
        }
    },

    mutations: {
        SET_REFRESH_TABLE(state, payload) {
            state.refreshTable = payload
        },
        M_PENDING_NCR_CLIENT(state, payload) {
            state.S_PENDING_NCR_CLIENT = payload
        },
        M_PENDING_NCR_LEAD(state, payload) {
            state.S_PENDING_NCR_LEAD = payload
        },
        M_APPOINTMENT_EXIST(state, payload) {
            state.S_APPOINTMENT_EXIST = payload
        },
        M_PENDING_BY_LEAD(state, payload) {
            state.S_PENDING_BY_LEAD = payload
        },
        M_PENDING_BY_CLIENT(state, payload) {
            state.S_PENDING_BY_CLIENT = payload
        }
    },

    actions: {
        CHANGE_REFRESH_TABLE({ commit }, data) {
            commit("SET_REFRESH_TABLE", data);
        },
        async A_REQUEST_NCR_COUNTERS({ commit }, module) {
            const { pending_ncr_client, pending_ncr_lead } = await NcrRequestService.getCountersNcr({});

            commit("M_PENDING_NCR_CLIENT", pending_ncr_client);
            commit("M_PENDING_NCR_LEAD", pending_ncr_lead);

            // update sidebar
            const sideCounter = pending_ncr_client + pending_ncr_lead;

            if (sideCounter >= 0) {
                const name = {
                    29: "request-ncr-financial-approval",
                }

                const payload = {
                    routeName: name[module] || null,
                    tag: sideCounter > 99 ? "+99" : sideCounter,
                }

                store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
            }
        },
        async A_REQUEST_NCR_COUNTERS_BY_LEAD({ commit }, leadId) {
          const { pending_ncr_client, pending_ncr_lead } = await NcrRequestService.getCountersByLead({ leadId });

          commit("M_PENDING_BY_LEAD", pending_ncr_lead);
        },
        async A_GET_COUNTERS_BY_CLIENT({ commit }, clientId) {
            const { pending_ncr_client } = await NcrRequestService.getCountersByClient({ clientId });
            commit("M_PENDING_BY_CLIENT", pending_ncr_client);
        },

        async APPOINTMENT_EXIST({commit}, data2){
            const { data } = await NcrRequestService.verifyIfAppointmentExist(data2);
            commit('M_APPOINTMENT_EXIST', data.exists);
        }
    },
}
