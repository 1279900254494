import DashboardNavigation from "../views/dashboard/dashboard.nav";
import LeadsNavigation from "../views/leads/leads.nav";
import ClientsNavigation from "@/views/business/views/clients/clients-group.navigation";
import ClientCompanyNavigation from "../views/client-company/client-company.nav";
import CalendarNavigation from "../views/calendar/calendar.nav";
import SalesMadeNavigation from "../views/sales-made/sales-made.nav";
import CommissionsNavigation from "../views/commissions/commissions.nav";
import SchedulesNavigation from "../views/schedules/schedules.nav";
import LoansNavigation from "../views/loans/loans.nav";
import FileManagerNavigation from "../views/file-manager/file-manager.nav";
import ReportNavigation from "../views/report/report.nav";
import ZeroPaymentNavigation from "../views/zero-payment/zero-payment.nav";
import ClaimsNavigation from "../views/claims-v2/claims.nav";
import RequestNcrNav from "@/views/business/views/request-ncr/request-ncr.nav";
import RequestWorkplanNav from "@/views/business/views/request-workplan/request-workplan.nav.js";
import PaymentsNavigation from "../views/payments/navigation/payments.navigation";
import SettingsNavigation from "../views/settings/settings.nav";
import ChargeBackNavigation from "../views/charge-back/charge-back.nav";
import HelpdeskNavigation from "../views/helpdesk/helpdesk.nav";
import ExpensesNavigation from "../views/expenses/expenses.nav";
import ProductRequestNavigation from "@/views/business/views/request/request.nav.js";
import OtherPaymentsNav from "@/views/business/views/other-payments/other-payments.nav.js";
import EmployeeClaimsNavigation from "@/views/business/views/employee-claims/navigation/employee-claims.nav";

import RingCentralNav from "@/views/business/views/ring-central/ring-central.nav.js";
import CourInfoNavigation from "@/views/business/views/cour-info/navigation/ds-court-info.nav";
import MOFNavigation from "@/views/business/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/business/views/recruitment-process/business-recruitment_process.navigation";
import InconsistentTransactionNavigation from "@/views/business/views/inconsistent-transactions/navigation/inconsistent-transactions.nav.js";
import CallImpressionNavigation from "@/views/business/views/call-impression/navigation/call-impression.navigation.js";

const navigation = [
  {
    header: "BUSINESS",
  },
  DashboardNavigation,
  LeadsNavigation,
  ClientsNavigation,
  ClaimsNavigation,
  ClientCompanyNavigation,
  ZeroPaymentNavigation,
  SalesMadeNavigation,
  // CommissionsNavigation,
  ReportNavigation,
  PaymentsNavigation,
  FileManagerNavigation,
  CalendarNavigation,
  RequestNcrNav,
  RequestWorkplanNav,
  SettingsNavigation,
  SchedulesNavigation,
  LoansNavigation,
  ChargeBackNavigation,
  HelpdeskNavigation,
  ExpensesNavigation,
  ProductRequestNavigation,
  OtherPaymentsNav,
  EmployeeClaimsNavigation,
  ...CourInfoNavigation,
  RingCentralNav,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  ...InconsistentTransactionNavigation,
  ...CallImpressionNavigation,
];
export default navigation;
