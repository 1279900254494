export default [
  {
    path: "loans",
    name: "loans-bussiness",
    redirect: { name: "my-loans-bussiness" },
    component: () =>
      import(/* webpackChunkName: "BusinessLoans" */ "@/views/commons/components/loans/LoansComponent.vue"),
    children: [
      {
        path: "my-loans",
        name: "my-loans-bussiness",
        component: () =>
          import(/* webpackChunkName: "BusinessMyLoans" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 1,
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "My Loans",
              active: true,
            },
          ],
          route: "bussiness",
        },
      },
      {
        path: "loans-module",
        name: "loans-module-bussiness",
        component: () =>
          import(/* webpackChunkName: "BusinessModuleLoans" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 2,
          route: "bussiness",
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "Loans By Module",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      tab: 1,
      route: "bussiness",
    },
  },
];
