<template>
  <div>
    <validation-observer ref="formApp">
      <b-card
        header-text-variant="white"
        header-bg-variant="info"
        header-class="p-0"
        body-class="pt-1 card-body-border"
      >
        <template #header>
          <div class="pl-1 pt-1">
            <p class="font-weight-bolder">Applicant personal data</p>
          </div>
        </template>

        <b-row class="container-dependents">
          <b-col cols="3" class="cont-user-info">
            <label for="">First Name</label>
            <b-form-input v-model="leadInfo.first_name" :disabled="readOnly" />
          </b-col>
          <b-col cols="3">
            <label for="">Middle Name</label>
            <b-form-input v-model="leadInfo.middle_name" :disabled="readOnly" />
          </b-col>
          <b-col cols="3">
            <label for="">Last Name</label>
            <b-form-input v-model="leadInfo.last_name" :disabled="readOnly" />
          </b-col>
          <b-col cols="3">
            <label for="">Status</label>
            <v-select
              v-model="leadInfo.status"
              :disabled="readOnly"
              :options="states_leads"
              label="name"
              :reduce="(value) => value.id"
            />
          </b-col>
          <b-col cols="3" class="mt-1">
            <label for="">DOB</label>
            <b-form-datepicker
              id="date4"
              v-model="leadInfo.dob"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :disabled="readOnly"
            />
          </b-col>
          <b-col cols="3" class="mt-1">
            <label for="">SSN</label>
            <b-form-input
              id="SSN"
              v-model="leadInfo.ssn"
              v-mask="'###-##-####'"
              name="ssn"
              :disabled="readOnly"
              maxlength="11"
            />
          </b-col>
          <b-col cols="3" class="mt-1">
            <label for="">Civil Status</label>
            <v-select
              v-model="leadInfo.cstatus"
              :disabled="readOnly"
              :options="[
                'SINGLE',
                'ENGAGED',
                'MARRIED',
                'DIVORCED',
                'WIDOW/ER',
              ]"
            />
          </b-col>
          <b-col cols="3" class="mt-1">
            <label for="">Applicant's Employer</label>
            <v-select
              id="employer"
              v-model="leadInfo.employer"
              :options="optionsVSelect.optionsApplicantEmployer"
              :reduce="(opts) => opts.label"
              :disabled="readOnly"
            />
          </b-col>
        </b-row>

        <div v-if="leadInfo.employer == 'EMPLOYED'">
          <div class="mt-2">
            <b-button variant="primary" @click="addEmployer()">
              Add employer
              <feather-icon icon="PlusIcon" />
            </b-button>
          </div>

          <b-row v-for="(item, index) in leadInfo.employes" :key="index">
            <b-col class="mt-1" md="3">
              <b-form-group label="Job" label-for="job">
                <validation-provider
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <b-form-input
                    id="job"
                    v-model="item.job"
                    :state="errors[0] ? false : valid ? true : null"
                    :disabled="readOnly"
                  />
                </validation-provider>
              </b-form-group>

              <!-- <label for>Job</label>
              <b-form-input
                :disabled="readOnly"
                type="text"
                v-model="item.job"
              /> -->
            </b-col>

            <b-col class="mt-1" md="2">
              <div>
                <feather-icon
                  v-if="item.remunerated != 'MONTHLY'"
                  class="float-right text-danger mr-1 cursor-pointer"
                  @click="deleteEmployer(item.id)"
                  icon="XSquareIcon"
                />
              </div>
              <b-form-group label="Remunerated" label-for="remunerated">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    ref="remunerated"
                    v-model="item.remunerated"
                    :options="optionsVSelect.optionsApplicantRemunerated"
                    :reduce="(opts) => opts.label"
                    @input="clearInputsEmployee(item)"
                    :disabled="readOnly"
                    :class="errors[0] ? 'border-danger' : ''"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col class="mt-1" md="2" v-if="item.remunerated">
              <b-form-group label="Type Payment" label-for="type_payment">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="item.typePaymet"
                    :options="optionsVSelect.optionsApplicantTypeOfPayment"
                    :reduce="(opts) => opts.id"
                    :disabled="readOnly"
                    :class="errors[0] ? 'border-danger' : ''"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col class="mt-1" md="2" v-if="item.typePaymet == 4">
              <b-form-group label="Other type of payment" label-for="other_ty">
                <validation-provider
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <b-form-input
                    :disabled="readOnly"
                    type="text"
                    :state="errors[0] ? false : valid ? true : null"
                    v-model="item.otherTypePaymet"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col class="mt-1" v-if="item.remunerated == 'MONTHLY'" md="2">
              <label class="w-100">
                <div class="clearfix w-100">
                  <span> Payment Date </span>
                  <feather-icon
                    class="float-right text-danger mr-1 cursor-pointer"
                    @click="deleteEmployer(item.id)"
                    icon="XSquareIcon"
                  />
                </div>
              </label>
              <b-form-datepicker
                v-model="item.paymetDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="en-US"
                :format="'MM/dd/yyyy'"
                :disabled="readOnly"
              />
            </b-col>
          </b-row>
        </div>

        <div class="mt-2">
          <div class="d-flex justify-content-between">
            <div>Family Size: {{ familySize }} persons</div>
            <b-button v-if="!readOnly"
              variant="outline-success"
              v-b-tooltip.hover
              title="New Dependent"
              @click="addNewDependent"
              :disabled="readOnly"
            >
              <feather-icon icon="PlusIcon"></feather-icon>
              Add</b-button
            >
          </div>

          <b-row v-for="(item, index) in leadInfo.dependents" :key="index">
            <b-col xl="3" lg="4" md="4" sm="4" cols="6" class="min-mb">
              <b-form-group label="Full Name" label-for="title">
                <validation-provider
                  rules="required"
                  v-slot="{ errors, valid }"
                >
                  <b-form-input
                    id="name"
                    placeholder="Ernesto Example"
                    v-model="item.name"
                    :state="errors[0] ? false : valid ? true : null"
                    :class="errors[0] ? 'border-danger' : ''"
                    :disabled="readOnly"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xl="3" lg="4" md="4" sm="4" cols="6" class="min-mb">
              <b-form-group label="Relationship" label-for="kinship">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    id="kinship"
                    v-model="item.kinship"
                    class="flex-grow-1 email-to-selector"
                    :class="errors[0] ? 'select-custom border-danger' : ''"
                    :options="optionsRelationship"
                    :disabled="readOnly"
                    label="name"
                  >
                  </v-select>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xl="3" lg="4" md="4" sm="4" cols="6" class="min-mb">
              <b-form-group label="Years Old" label-for="yo">
                <validation-provider
                  rules="required"
                  v-slot="{ errors, valid }"
                  name="yo"
                  style="position: relative"
                >
                  <b-form-input
                    id="yo"
                    placeholder="18"
                    type="number"
                    v-model="item.yo"
                    :state="errors[0] ? false : valid ? true : null"
                    :disabled="readOnly"
                  />
                </validation-provider>

                <feather-icon
                  style="position: absolute; top: 0; right: 0"
                  icon="XCircleIcon"
                  class="text-danger cursor-pointer"
                  v-b-tooltip.hover
                  size="18"
                  title="Delete Dependent"
                  @click="leadInfo.dependents.splice(index, 1)"
                ></feather-icon>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card
        header-text-variant="white"
        header-bg-variant="info"
        header-class="p-0"
        body-class="pt-1 card-body-border"
      >
        <template #header>
          <div class="pl-1 pt-1">
            <p class="font-weight-bolder">Contact Data</p>
          </div>
        </template>

        <b-row>
          <b-col cols="4">
            <label for="">Phone M</label>
            <b-form-input
              v-model="leadInfo.mobile"
              v-mask="'(###) ###-####'"
              maxlength="14"
              :disabled="readOnly"
            />
          </b-col>
          <b-col cols="4">
            <label for="">Email</label>
            <b-form-input v-model="leadInfo.email" :disabled="readOnly" />
          </b-col>
          <b-col cols="4">
            <label for="address_principal">Address</label>
            <vue-google-autocomplete
              id="address_principal"
              ref="addressprincipal"
              v-model="leadInfo.address"
              class="form-control"
              placeholder="Please type your address"
              country="us"
              :disabled="readOnly"
              @placechanged="getAddressData"
            />
          </b-col>
          <b-col cols="4" class="mt-1">
            <label for="city">City</label>
            <b-form-input
              id="city"
              name="city"
              v-model="leadInfo.city"
              :disabled="readOnly"
            />
          </b-col>
          <b-col cols="4" class="mt-1">
            <label for="state">State</label>
            <v-select
              id="state"
              v-model="leadInfo.state"
              name="state"
              :disabled="readOnly"
              :options="states"
              label="state"
              :reduce="(value) => value.slug"
            />
          </b-col>

          <b-col cols="4" class="mt-1">
            <label for="zipcode">Zipcode</label>
            <b-form-input
              id="zipcode"
              name="zipcode"
              v-model="leadInfo.zipcode"
              :disabled="readOnly"
            />
          </b-col>
        </b-row>
      </b-card>
    </validation-observer>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import VSelectOptions from "@/views/crm/views/clients/components/data/options.modal-questionnaire";

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    value: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    familySize() {
      return this.leadInfo.dependents.length + 1;
    },

    leadInfo: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  data() {
    return {
      states: [],
      states_leads: [],
      optionsVSelect: VSelectOptions,
      optionsRelationship: [
        { id: 1, name: "Grandparent" },
        { id: 2, name: "Spouse" },
        { id: 3, name: "Siblings" },
        { id: 4, name: "Children" },
        { id: 5, name: "Cousins" },
      ],
    };
  },
  methods: {
    async validateForm() {
      const isValidated = await this.$refs.formApp.validate();
      return isValidated;
    },

    getAddressData(address_principal, placeResultData, id) {
      this.address_principal = `${address_principal.street_number} ${address_principal.route}`;
      this.leadInfo.address = address_principal;
      this.leadInfo.state = address_principal.administrative_area_level_1;
      this.leadInfo.city = address_principal.locality;
      this.leadInfo.zipcode = address_principal.postal_code;
    },

    async stateseeuu() {
      try {
        const response = await amgApi.get("/commons/get-eeuu-states");
        this.states = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async statesleads() {
      try {
        const response = await amgApi.get("/lead/get-state-leads");
        this.states_leads = response.data;
      } catch (error) {
        console.error(error);
      }
    },

    addNewDependent() {
      const object = { name: null, kinship: null, yo: null };
      this.leadInfo.dependents.push(object);
    },

    deleteEmployer(id) {
      const index = this.leadInfo.employes.findIndex((x) => x.id == id);
      if (index !== -1) {
        this.leadInfo.employes.splice(index, 1);
      }
    },

    clearInputsEmployee(param) {
      if (param.remunerated != "MONTHLY" || param.remunerated == null) {
        param.typePaymet = "";
        param.paymetDate = "";
        param.otherTypePaymet = "";
      }
    },

    addEmployer() {
      this.leadInfo.employes.push({
        id: this.leadInfo.employes.length + 1,
        job: "",
        remunerated: "",
        typePaymet: "",
        paymetDate: "",
        otherTypePaymet: "",
      });
    },
  },

  async mounted() {
    await this.statesleads();
    await this.stateseeuu();
  },
};
</script>

<style lang="scss">
.container-dependents {
  .p_desing2 {
    color: white;
    font-size: 13px;
    background-color: #b139ee;
    border-radius: 3px;
    padding: 8px 10px;
    text-align: center;
  }

  .select-custom {
    .vs__dropdown-toggle {
      border: 1px solid red;
    }
  }

  .min-mb {
    margin-bottom: 3px;
  }
}
</style>
