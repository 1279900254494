import socialNetworkCounters from "@/views/social-network/social.counters.js";
import managementCounters from "@/views/management/management.counters.js";
import qualityCounters from "@/views/quality/quality.counters";
import rrhhCounters from "@/views/rrhh/rrhh.counters.js";
import logisticCounters from "@/views/logistic/logistic.counters.js";
import debtSolutionCounters from "@/views/debt-solution/debt-solution.counters";
import boostCreditCounters from "@/views/boost-credit/boost-credit.counters";
import creditExpertsCounters from "@/views/credit-experts/credit-experts.counters";
import crmCounters from "@/views/crm/crm.counters";
import paragonCounter from "@/views/paragon/paragon.counters.js";
import businessCounter from "@/views/business/business.counters.js";
import taxResearchCounter from "@/views/tax-research/tax-research.counters.js";
import bookeepingCounter from "@/views/bookeeping/bookeeping.counters";
import courtInfoCounter from "@/views/court-info/court-info.counters";
import specialistsDigitalCounter from "@/views/specialist-digital/specialist-digital.counters";
import correspondenceCounter from "@/views/correspondence/correspondence.counter";
import cDigitalCustomServiceCounter from "@/views/ce-digital/sub-modules/customer-service/cedigital-customerservice.counter";
import cDigitalSettingsCounter from "@/views/ce-digital/sub-modules/settings/cedigital-settings";
import cDigitalFinancialCounter from "@/views/ce-digital/sub-modules/financial/cedigital-financial-counter";
import cDigitalSalesCounter from "@/views/ce-digital/sub-modules/sales/cedigital-sales.counters";
import cDigitalConnectionCounter from "@/views/ce-digital/sub-modules/connection/cedigital-connection-counter";
import administrationCounters from "@/views/administration/administration.counters";
import specialistsCounter from "@/views/specialists/specialists-counter";
import SpAnalystDepartmentCounter from "@/views/specialists/sub-modules/analyst-department/analyst-department-counter";
import SpFinancialApprovalCounter from "@/views/specialists/sub-modules/financial-approval/financial-approval-counter";
// import qualityCounter from  "@/views/quality/views/quality.counters" // duplicated
import creativeCounter from "@/views/creative/creative.counters";
export default {
  15: socialNetworkCounters,
  16: managementCounters,
  17: rrhhCounters,
  18: qualityCounters,
  19: logisticCounters,
  5: debtSolutionCounters,
  6: creditExpertsCounters,
  7: boostCreditCounters,
  2: crmCounters,
  12: paragonCounter,
  3: businessCounter,
  8: taxResearchCounter,
  9: correspondenceCounter,
  14: bookeepingCounter,
  10: courtInfoCounter,
  25: specialistsDigitalCounter,
  22: cDigitalCustomServiceCounter,
  23: cDigitalFinancialCounter,
  24: cDigitalSettingsCounter,
  26: cDigitalSalesCounter,
  20: cDigitalConnectionCounter,
  11: specialistsCounter,
  4: administrationCounters,
  // 18: qualityCounter,
  27: creativeCounter,
  28: SpAnalystDepartmentCounter,
  29: SpFinancialApprovalCounter,
};
