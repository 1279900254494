import BankOfFlyersNav from "@/views/creative/views/bank-of-flyers/bank-of-flyers.navigation"
import metaNav from "@/views/creative/views/meta/navigation/meta.navigation.js"
import helpdeskNav from "@/views/creative/views/helpdesk/helpdesk.nav.js"
import productRequestNav from "@/views/creative/views/request/request.nav.js"
import fileManagerNav from "@/views/creative/views/file-manager/file-manager.nav.js"
import CreativeNav from "@/views/creative/views/reports/reports.navigation.js";
import EmployeeClaimsNav from "@/views/creative/views/employee-claims/navigation/employee-claims.nav"
//import CreativeProjectsNavigation from '@/views/creative/views/projects/creative-projects.navigation';
import CallLogNavigation from "@/views/creative/views/call-log/call-log.nav.js";
import MOFNavigation from '@/views/creative/views/manual-of-functions/manual-of-functions.nav.js'
import RecruitmentProcessNavigation from '@/views/creative/views/recruitment-process/creative-recruitment_process.navigation';
import creativeRequestNav from "@/views/creative/views/creative-requests/nav/creative.request.js"
import MetaInvestmentNav from "@/views/creative/views/meta-investment/navigation/meta-investment.navigation.js"
import DashboardNav from "@/views/creative/views/dashboard/navigation/dashboard.nav.js"

const navigation = [
  {
    header: 'CREATIVE',
  },
  DashboardNav,
  BankOfFlyersNav,
  //metaNav,
  MetaInvestmentNav,
  //CreativeNav,
  helpdeskNav,
  productRequestNav,
  fileManagerNav,
  EmployeeClaimsNav,
  //CreativeProjectsNavigation,
  ...CallLogNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  creativeRequestNav,
]
export default navigation
