
export default {
    path: "folders/",
    name: "file-manager-financial-approval",
    component: () =>
        import(
            /* webpackChunkName: "file-mananger-specialists" */
            "@/views/specialists/views/file-mananger/FileMananger.vue"
        ),
    meta: {
        pageTitle: "File Manager",
        breadcrumb: [
            {
                text: "File Manager",
                active: true,
            },
        ],
    },
};
