<template>
  <div>
    <b-modal
      v-model="showModal"
      size="smd"
      header-bg-variant="transparent"
      no-close-no-backdrop
      centered
      @hidden="close"
    >
      <template #modal-title>
        <h4
          class="font-weight-bolder d-inline-flex mt-1"
          :class="isDarkSkin ? 'text-light' : 'text-my-dark'"
        >
          <feather-icon
            icon="CalendarIcon"
            size="15"
            class="p-0 m-0"
            style="margin-right: 10px !important;"
          />
          PERSONAL AVAILABILITY
        </h4>
      </template>
      <b-row class="pl-2">
        <b-col
          class="border-right"
          cols="12"
          lg="5"
          style="padding:2rem 1rem"
        >
          <header class="d-flex w-100 justify-content-start align-items-center mb-2">
            <strong
              style="font-size: 16px"
              class="mr-2"
            >
              Month:
            </strong>
            <div class="d-flex justify-content-center align-items-center border-primary rounded">
              <b-button
                variant="primary"
                class="p-0"
                style="padding: 5px !important;border-radius: 3px 0px 0px 3px !important;"
                size="sm"
                @click="nextMonth(-1)"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="17"
                  class="hover-me"
                />
              </b-button>
              <span
                class="text-center"
                style="font-size: 13px;padding: 3px 15px;min-width: 150px;"
              >{{ monthAndYear
              }}</span>
              <b-button
                variant="primary"
                class="p-0"
                style="padding: 5px !important;border-radius: 0px 3px 3px 0px !important;"
                size="sm"
                @click="nextMonth(1)"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  size="17"
                  class="hover-me"
                />
              </b-button>
            </div>
          </header>
          <body-calendar-component
            :key="keyCalendar"
            :month="month"
            :year="year"
            :disabled-old-days="true"
            :multiple="false"
            :show-today="true"
            :container-hours="arrayHasSchedule"
            @selectedDays="selectedDays"
          />
        </b-col>
        <b-col
          cols="12"
          lg="7"
          style="padding:0 2rem"
        >
          <div
            v-if="day"
            style="max-height: 48vh;overflow: auto; display:flex; flex-direction: column; gap: 0.5rem"
          >
            <div style="display: flex;justify-content: space-between;padding-right: 4rem;align-items: end;">
              <h5
                v-if="!day[0]"
                class="font-weight-bolder pt-1"
                :class="isDarkSkin ? 'text-light' : 'text-my-dark'"
              >
                Selected Date: {{ currentDateFormat | myDateGlobal }}
              </h5>
              <h5
                v-else
                class="font-weight-bolder pt-1"
                :class="isDarkSkin ? 'text-light' : 'text-my-dark'"
              >
                Selected Date: {{ day[0].date | myDateGlobal }}
              </h5>

            </div>
            <div
              v-if="day.length > 0 && day[0].date >= todayDate"
              class="rounded pb-1 pl-1 pr-1"
              :style="isDarkSkin ? 'border: 1px solid #17A589 ':'background-color: #D1F2EB !important'"
            >
              <h5
                class="font-weight-bolder pt-1"
                :class="isDarkSkin ? 'text-light' : 'text-my-dark'"
              >
                What hours are you available?
              <!-- <span>{{ filterByDay }}</span> -->

              </h5>

              <div
                style="display:flex;"
                :style="schedules.length === 0 ? '' : 'gap:1rem'"
              >

                <div style="display:flex; flex-direction: column; gap:1rem;">

                  <div
                    v-for="(schedule, index) in schedules"
                    :key="index"
                    style="display:flex; gap:0.6rem"
                  >
                    <div
                      style="display:flex; gap:1rem;margin-right: 5px;"
                    >
                      <kendo-timepicker
                        v-model="schedule.startHour"
                        :format="'HH:mm'"
                        :interval="30"
                        class="w-100 rounded"
                        :class="!isDarkSkin? 'bg-white':'bg-transparent'"
                        placeholder="Start Hour"
                        style="height: 2.73rem"
                        :min="index > 0 ? formatHour(schedules[index-1].endHour): min"
                        :max="max"
                      />
                      <kendo-timepicker
                        v-model="schedule.endHour"
                        :format="'HH:mm'"
                        :interval="30"
                        class="w-100 rounded"
                        :class="!isDarkSkin? 'bg-white':'bg-transparent'"
                        placeholder="End Hour"
                        style="height: 2.73rem"
                        :min="formatHour(schedules[index].startHour)"
                        :max="max"
                        :disabled="!schedule.startHour"
                      />
                    </div>
                    <span
                      v-if="index != 0"
                      :style="isDarkSkin ? 'border: solid 1px #2f3036' : ''"
                      :class="isDarkSkin ? 'text-white span-hover' : 'text-dark span-hover-light'"
                      class="hover-me cursor-pointer"
                      style="height: 100%;display: flex;align-items:center;"
                      @click="deleteScheduleItem(day, index, schedule)"
                    >
                      <feather-icon
                        icon="XIcon"
                        size="18"
                        class="text-danger"
                      />
                    </span>
                  </div>
                </div>
                <span
                  style="padding: 0.5rem 0rem; cursor:pointer; border-radius:5px;"
                  :class="isDarkSkin ? 'text-white span-hover' : 'text-dark span-hover-light'"
                  @click="addScheduleItem()"
                >
                  <feather-icon
                    icon="PlusSquareIcon"
                    size="18"
                    class="text-success hover-me"
                  />
                </span>
              </div>
            </div>
            <h5
              v-if="mergedRanges.length > 0"
              class="font-weight-bolder pt-1 text-primary"
            >
              Your Availability Hours
            <!-- <span>{{ filterByDay }}</span> -->

            </h5>
            <b-list-group>
              <b-list-group-item
                v-for="(item, index) in mergedRanges"
                :key="index"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span style="padding: 0 1.5rem;  ">Start Hour: {{ item.range_start }} </span>
                <span style="padding: 0 1.5rem; ">End Hour: {{ item.range_end }}</span>
                <span
                  v-if="day[0].date >= todayDate"
                  style="padding: 0.1rem; cursor:pointer; border-radius:1px"
                  :class="isDarkSkin ? 'text-white span-hover' : 'text-dark span-hover-light'"
                  @click="item.ticket_customer_id.length > 0 ? showConfirmDeleteRange(item):unselectselectRangeByHours(item.range_start, item.range_end, true)"
                >
                  <feather-icon
                    class="text-danger hover-me"
                    icon="XSquareIcon"
                    size="18"
                  />
                </span>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div
            v-else
            style="height: 100%;width: 100%;display: flex;flex-direction: column;align-items:center;justify-content: center;padding: 1rem; text-align: center;"
          >
            <feather-icon
              icon="ClockIcon"
              size="55"
              class="m-1 text-light"
            />
            <span
              style="font-size: 18px"
              class="text-light"
            >
              select a day to display the available hours
            </span>
          </div>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div class="m-0 mr-2">
          <div
            class="cursor-pointer text-white"
            style="display: flex; background-color: rgb(254, 47, 0); border-radius: 20px; padding: 0.6rem 2.5rem;"
            @click="close()"
          >
            Close
          </div>
        </div>
        <div class="m-0">
          <div
            class="cursor-pointer text-white"
            style="display: flex; background-color: #0068fe; border-radius: 20px; padding: 0.6rem 2.5rem;"
            @click="save(true)"
          >
            Save
          </div>
        </div>

      </template>
    </b-modal>
    <!--MODAL MEETINGS TO DELETE-->
    <modal-meetings-delete
      v-if="showModalMeetings"
      ref="modalMeetings"
      :ticket-meetings-id="ticketCustomerIds"
      @closeModal="closeModalMeetings"
      @deleteMeetings="deletedSuccess"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import BodyCalendarComponent from '@/views/commons/components/customer-tickets/components/modal/meetings/BodyCalendar.vue';
import CustomerServices from '@/views/commons/components/customer-tickets/services/customer-tickets.service';
import ModalMeetingsDelete from '@/views/commons/components/customer-tickets/components/modal/ModalMeetingsDelete.vue';

export default {
  components: {
    BodyCalendarComponent,
    ModalMeetingsDelete,
  },
  data() {
    return {
      min: '08:00:00',
      max: '20:00:00',

      schedules: [
        { startHour: '', endHour: '' },
      ],
      startHour: '',
      endHour: '',
      isChecked: false,
      showModal: false,
      currentDateFormat: moment().format('YYYY-MM-DD'),
      currentMonth: parseInt(moment().format('MM'), 10),
      currentYear: parseInt(moment().format('YYYY'), 10),
      month: parseInt(moment().format('MM'), 10),
      year: parseInt(moment().format('YYYY'), 10),
      keyCalendar: 1,
      day: [],
      currentDate: null,
      daysOfWeek: [],
      availabilityHours: [
        {
          range_start: '08:00',
          range_end: '09:00',
          selected: false,
        },
        {
          range_start: '09:00',
          range_end: '10:00',
          selected: false,
        },
        {
          range_start: '10:00',
          range_end: '11:00',
          selected: false,
        },
        {
          range_start: '11:00',
          range_end: '12:00',
          selected: false,
        },
        {
          range_start: '12:00',
          range_end: '13:00',
          selected: false,
        },
        {
          range_start: '13:00',
          range_end: '14:00',
          selected: false,
        },
        {
          range_start: '14:00',
          range_end: '15:00',
          selected: false,
        },
        {
          range_start: '15:00',
          range_end: '16:00',
          selected: false,
        },
        {
          range_start: '16:00',
          range_end: '17:00',
          selected: false,
        },
        {
          range_start: '17:00',
          range_end: '18:00',
          selected: false,
        },
        {
          range_start: '18:00',
          range_end: '19:00',
          selected: false,
        },
        {
          range_start: '19:00',
          range_end: '20:00',
          selected: false,
        },
      ],
      containerHours: [],

      allSelected: false,
      changesActive: null,
      today: parseInt(moment().format('DD'), 10),
      todayDate: moment().format('YYYY-MM-DD'),
      meetingDates: [],
      arrayHasSchedule: [],
      showModalMeetings: false,
      ticketCustomerIds: {},
    };
  },
  computed: {
    filterByDayMore() {
      const data = this.filterByDay ? this.filterByDay
        .filter(slot => slot.selected)
        .map(slot => ({ range_start: slot.range_start, range_end: slot.range_end, ticket_customer_id: slot.ticket_customer_id })) : [];
      return data;
    },
    mergedRanges() {
      if (this.filterByDayMore.length === 0) return [];

      let mergedRanges = [];
      let currentRange = this.filterByDayMore[0];
      const ticketIds = [];

      for (let i = 1; i < this.filterByDayMore.length; i++) {
        const nextRange = this.filterByDayMore[i];
        if (nextRange.ticket_customer_id) {
          ticketIds.push(nextRange.ticket_customer_id);
        }

        // Verifica si hay solapamiento de rangos
        if (currentRange.range_end >= nextRange.range_start) {
          // Hay solapamiento, actualiza el range_end del rango actual
          currentRange.range_end = nextRange.range_end;
        } else {
          // No hay solapamiento, agrega el rango actual a la lista combinada
          mergedRanges.push({
            range_start: currentRange.range_start,
            range_end: currentRange.range_end,
            ticket_customer_id: currentRange.ticket_customer_id ? [currentRange.ticket_customer_id] : [],
          });

          // Actualiza el rango actual al siguiente rango sin solapamiento
          currentRange = nextRange;
        }
      }
      if (currentRange.ticket_customer_id) {
        ticketIds.push(currentRange.ticket_customer_id);
      }

      // Agrega el último rango a la lista combinada
      mergedRanges.push({
        range_start: currentRange.range_start,
        range_end: currentRange.range_end,
        ticket_customer_id: Array.from(new Set(ticketIds)),
      });

      mergedRanges = mergedRanges.filter(range => range.range_start !== undefined && range.range_end !== undefined);
      return mergedRanges;
    },

    monthAndYear() {
      return moment(`${this.month}/${this.year}`, 'MM/YYYY').format('MMMM YYYY');
    },
    montAndYearAndDay() {
      return moment(`${this.month}/${this.year}`, 'MM/YYYY').format('YYYY-MM-DD');
    },

    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),

    filterByDay: {
      get() {
        const filteredDay = this.containerHours.find(item => item.date === this.currentDate);

        return filteredDay ? filteredDay.availabilityHours : null;
      },
      set(value) {

      },
    },
    filterByDayAllDates: {
      get() {
        const filteredDay = this.containerHours.find(item => item.date === this.currentDate);
        return filteredDay ? filteredDay.allDates : false;
      },
      set(value) {
        const filteredDayIndex = this.containerHours.findIndex(item => item.date === this.currentDate);

        if (filteredDayIndex !== -1) {
          // Si el día está en this.containerHours, actualiza su propiedad allDates
          this.$set(this.containerHours, filteredDayIndex, { ...this.containerHours[filteredDayIndex], allDates: value });
        }
      },
    },
  },
  watch: {
    schedules: {
      handler(newSchedules) {
        if (newSchedules.length === 0) {
          return;
        }
        newSchedules.forEach((schedule, index) => {
          if (schedule.startHour
            === '' || schedule.endHour
            === '') {
            return;
          }
          this.selectRangeByHours(schedule.startHour, schedule.endHour);
        });
      },
      deep: true,
    },
  },
  async mounted() {
    this.showModal = true;
    this.day = null;
    this.min = new Date(2019, 11, 24, 8, 0);
    this.max = new Date(2019, 11, 24, 20, 0);
    await this.getMeetingsHours();
  },
  methods: {

    selectRangeByHours(startHour, endHour) {
      const startMoment = moment(startHour, 'HH:mm');
      const endMoment = moment(endHour, 'HH:mm');

      // Encuentra el día correspondiente en containerHours
      const selectedDayIndex = this.containerHours.findIndex(item => item.date === this.currentDate);
      if (selectedDayIndex !== -1) {
        // Recorre las horas de disponibilidad del día y actualiza su estado seleccionado según el rango
        this.containerHours[selectedDayIndex].availabilityHours.forEach(hour => {
          const rangeStart = moment(hour.range_start, 'HH:mm');
          const rangeEnd = moment(hour.range_end, 'HH:mm');

          if (rangeStart.isSameOrAfter(startMoment) && rangeEnd.isSameOrBefore(endMoment)) {
            hour.meetingIdProvisional = -1;

            hour.selected = true;
          }
        });
      }
    },
    async unselectselectRangeByHours(startHour, endHour, confirm = false) {
      if (confirm) {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
      }

      const startMoment = moment(startHour, 'HH:mm');
      const endMoment = moment(endHour, 'HH:mm');

      // Encuentra el día correspondiente en containerHours
      const selectedDayIndex = this.containerHours.findIndex(item => item.date === this.currentDate);
      if (selectedDayIndex !== -1) {
        // Recorre las horas de disponibilidad del día y actualiza su estado seleccionado según el rango
        this.containerHours[selectedDayIndex].availabilityHours.forEach(hour => {
          const rangeStart = moment(hour.range_start, 'HH:mm');
          const rangeEnd = moment(hour.range_end, 'HH:mm');

          if (rangeStart.isSameOrAfter(startMoment) && rangeEnd.isSameOrBefore(endMoment)) {
            hour.meetingIdProvisional = -1;

            hour.selected = false;
          }
        });
      }
    },

    deselectAll() {
      this.filterByDay.forEach(hour => {
        hour.selected = false;
      });
    },
    addScheduleItem() {
      this.schedules.push({
        startHour: null,
        endHour: null,
      });
    },
    deleteScheduleItemIndexZero(day, index, schedule) {
      const selectedDayIndex = this.containerHours.findIndex(item => item.date === this.currentDate);
      if (selectedDayIndex !== -1) {
        // Recorre las horas de disponibilidad del día y actualiza su estado seleccionado según el rango
        this.containerHours[selectedDayIndex].availabilityHours.forEach(hour => {
          hour.meetingIdProvisional = -1;

          hour.selected = false;
        });
      }
      this.schedules = [
        { startHour: '', endHour: '' },
      ];
    },
    deleteScheduleItem(day, index, schedule) {
      this.unselectselectRangeByHours(schedule.startHour, schedule.endHour);
      if (index > 0) {
        this.schedules.splice(index, 1);
      }
    },

    putMeetingId(data) {
      data.meetingIdProvisional = -1;
      // const sinCambios = data.selected;
      this.changesActive = data.selected;
    },

    close() {
      this.$emit('close');
    },
    refreshTable() {
      this.$refs.tableListMeetings.refresh();
    },
    nextMonth(value) {
      if (this.changesActive === null || this.changesActive.length === 0) {
        const newDate = moment(`${this.year}-${this.month}`, 'YYYY-MM').add(value, 'month');
        this.year = parseInt(newDate.format('YYYY'), 10);
        this.month = parseInt(newDate.format('MM'), 10);
        this.keyCalendar += 1;
        this.arrayHasSchedule = [];
        this.getMeetingsHours();
      } else {
        // this.showInfoSwal('You have unsaved changes');
        this.save(false);
      }
    },
    selectedDays(days) {
      this.day = [...days];
      this.currentDate = this.day[0]?.date || null;
      this.schedules = [
        { startHour: '', endHour: '' },
      ];
    },
    async save(data) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        const containerHoursData = JSON.parse(JSON.stringify(this.containerHours));
        const filteredContainerHours = containerHoursData.filter(day => day.availabilityHours.some(hour => hour.meetingIdProvisional === -1));
        filteredContainerHours.forEach(day => {
          day.availabilityHours = day.availabilityHours.filter(hour => hour.meetingIdProvisional === -1);
        });

        const params = {
          containerHours: filteredContainerHours,
        };
        const response = await CustomerServices.saveMeetingsHours(params);

        this.removePreloader();
        if (response.status === 200) {
          this.showSuccessSwal('Availability changed correctly');
          if (data === true) {
            // this.close();
          }
          this.changesActive = null;
        }
      } catch (error) {
        this.removePreloader();
        console.log(error);
      }
    },
    async getMeetingsHours() {
      try {
        const ctx = {
          date: this.montAndYearAndDay,
        };
        const response = await CustomerServices.getMeetingsHours(ctx);

        if (response.status === 200) {
          this.daysOfWeek = response.data;
          // Agrupa las reuniones por fecha
          const meetingsByDate = {};
          this.daysOfWeek.forEach(meeting => {
            const meetingDate = moment(meeting.meeting_date).format('YYYY-MM-DD');
            if (!meetingsByDate[meetingDate]) {
              meetingsByDate[meetingDate] = [];
            }
            meetingsByDate[meetingDate].push(meeting);
          });

          // Inicializa containerHours
          const containerHours = [];

          // Obtén la lista de todas las fechas del mes
          const startDate = moment(this.montAndYearAndDay).startOf('month');
          const endDate = moment(this.montAndYearAndDay).endOf('month');
          const allDates = [];
          for (let date = startDate.clone(); date.isBefore(endDate); date.add(1, 'day')) {
            allDates.push(date.format('YYYY-MM-DD'));
          }

          // Itera sobre cada fecha y crea el objeto con la estructura requerida
          allDates.forEach(date => {
            const availabilityHours = [];
            let allDates = false;
            for (let i = 8; i < 20; i++) { // Cambiado de 20 a 19 para no exceder las 19:00 como última hora
              for (let j = 0; j < 2; j++) { // Iterar dos veces para cubrir las dos mitades de la hora
                const minutes = j * 30;
                const startTime = `${i < 10 ? '0' : ''}${i}:${minutes === 0 ? '00' : minutes}`;
                const endTime = `${(minutes === 0 ? i : i + 1) < 10 ? '0' : ''}${minutes === 0 ? i : i + 1}:${minutes === 0 ? '30' : '00'}`;

                const selectedMeeting = meetingsByDate[date]
                  && meetingsByDate[date].find(meeting => {
                    const meetingStartTime = moment(meeting.range_start, 'HH:mm:ss').format('HH:mm');
                    const meetingEndTime = moment(meeting.range_end, 'HH:mm:ss').format('HH:mm');
                    return startTime === meetingStartTime && endTime === meetingEndTime;
                  });

                const selected = selectedMeeting !== undefined;
                allDates = selected === true;

                availabilityHours.push({
                  range_start: startTime,
                  range_end: endTime,
                  selected: selected || false,
                  meetingId: selected ? selectedMeeting.id : null,
                  ticket_customer_id: selectedMeeting?.ticket_customer_id || null,
                });
              }
            }
            const day = moment(date).format('D');
            this.containerHours.push({
              date,
              availabilityHours,
              allDates,
            });
            if (availabilityHours.some(hour => hour.meetingId !== null)) {
              this.arrayHasSchedule.push({
                date,
                allDates,
                day,
              });
            }
          });

          this.containerHours.forEach(meeting => {
            this.meetingDates.push(meeting.date);
          });

          return containerHours;
        }
      } catch (error) {
        console.log(error);
      }

      return null;
    },

    toggleAllCheckboxes() {
      this.filterByDay.forEach(slot => {
        slot.selected = this.filterByDayAllDates;
        slot.meetingIdProvisional = -1;
      });

      this.changesActive = 1;
    },

    showConfirmDeleteRange(item) {
      this.showModalMeetings = true;
      this.ticketCustomerIds = {
        ids: item.ticket_customer_id,
        hour_start: item.range_start,
        hour_end: item.range_end,
        date: this.currentDate,
      };
    },

    closeModalMeetings(closeModalAvailability) {
      this.showModalMeetings = false;
      this.ticketCustomerIds = {};
      if (closeModalAvailability) this.close();
    },
    deletedSuccess() {
      this.showModalMeetings = false;
      this.daysOfWeek = [];
      this.unselectselectRangeByHours(this.ticketCustomerIds.hour_start, this.ticketCustomerIds.hour_end, false);
      this.ticketCustomerIds = {};
    },
    formatHour(hour) {
      if (!hour) return this.min;
      hour = hour.split(':');
      const newHour = new Date(this.currentYear, this.currentMonth, this.today, parseInt(hour[0], 10), parseInt(hour[1], 10));
      return newHour;
    },
  },
};
</script>
<style scoped>
* {
  --custom-blue: #2591E7;
}

.grid-calendar {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0px;
  margin: 0px auto;
  position: relative;
}

.grid-calendar .name-day,
.grid-calendar .number-day,
.grid-calendar .number-day-dark-skin {
  text-align: center;
  font-size: 10px;
  padding: 10px 0px;
  /* border-bottom: 1px solid #ccc; */
  text-transform: uppercase;
  transition: 0.3s ease-in-out all;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.grid-calendar .name-day {
  font-weight: 600;
}

.grid-calendar .number-day,
.grid-calendar .number-day-dark-skin {
  cursor: pointer;
  margin: 2px;
}

.grid-calendar .number-day:hover,
.grid-calendar .number-day-dark-skin:hover {
  background-color: var(--custom-blue);
  /* border-radius: 5px; */
}

.grid-calendar .number-day:hover span,
.grid-calendar .number-day-dark-skin:hover span {
  cursor: pointer;
  font-size: 15px;
  transform: traslateY(-5px);
  color: white;
}

.day-selected {
  background-color: var(--custom-blue) !important;
  color: white !important;
}

.day-selected span {
  font-size: 15px;
}

.day-selected .indicator {
  background-color: white !important;
}

.indicator,
.indicator-dark {
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  bottom: 3px;
  transition: 0.3s ease-in-out all;
}

.indicator {
  background-color: currentColor;
}

.grid-calendar .number-day:hover .indicator,
.grid-calendar .number-day-dark-skin:hover .indicator {
  background-color: white;
}

.rotate-me {
  transition: 0.3s ease-in-out all;
}

.rotate-me:hover {
  transform: rotate(180deg);
}

/*---ANIMATIONs----*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 0s !important;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.slide-fade-item {
  transition: transform 0.5s;
}

.text-my-dark {
  color: #5a5a5a !important;
}
</style>
