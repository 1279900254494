export default [
  {
    path: "dashboard-analyst",
    name: "analyst-department-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "SpecialistDigitalDashboardAnal" */ "@/views/specialists/sub-modules/analyst-department/views/dashboard/components/DashboardAnalyst.vue"
      ),
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
      permittedRoles: [1, 2, 17, 22],
    },
  },
];
