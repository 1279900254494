<template>
  <b-modal
    ref="derivationTrackingModal"
    title-class="h4 text-white"
    modal-class="modal-info "
    header-class="bg-info"
    centered
    :size="currentBreakPoint === 'md' ? 'xlg' : 'lg'"
    title="SALES PROCESS TRACKING"
    @hidden="closeModal"
    hide-footer
  >
    <b-row v-if="leadClientData.length > 0">
      <b-col>
        <b-tabs pills lazy nav-class="mb-0 mt-2">
          <b-tab
            title-item-class="sub-tab-nav-client-details mr-1"
            :title-link-class="'sub-tab-nav-client-details'"
            :title="leadClient.value"
            v-for="(leadClient, index) in leadClientData"
            :key="leadClient.program_id"
            @click="setLastStatusSale(index)"
          >
            <!--    REUSABLE COMPONENT  -->

            <step-progress-bar
              :step-data="stepData"
              :select-step="leadClientData.length"
              @selectProcess="getStepClientDerived"
              :selected-data="leadClient.sale_tracking"
            />
            <div class="my-2" style="display: flex; flex-direction: row">
              <b-row>
                <b-col
                  :cols="currentBreakPoint === 'sm' ? 10 : 5"
                  :class="`data-content my-2 mx-4 pt-1 ${
                    isDarkSkin ? 'bg-dark-sp' : 'bg-light-sp'
                  }`"
                >
                  <div>
                    <b-form-group label="NAME" label-class="text-bold">
                      <span>{{ stepLeadClientData.lead_client_name }}</span>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                    :cols="currentBreakPoint === 'sm' ? 10 : 5"
                  :class="`${currentBreakPoint === 'sm' ? 'data-content my-2 mx-4 pt-1' : 'data-content my-2 pt-1'} ${
                    isDarkSkin ? 'bg-dark-sp' : 'bg-light-sp'
                  }`"
                >
                  <div>
                    <b-form-group label="DERIVED FROM">
                      <span>{{ stepLeadClientData.derived_from }}</span>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                    :cols="currentBreakPoint === 'sm' ? 10 : 11"
                  :class="`data-content my-2 ml-3 pt-1 ${
                    isDarkSkin ? 'bg-dark-sp' : 'bg-light-sp'
                  }`"
                >
                  <div>
                    <b-form-group label="SALE PROCESS STATUS">
                      <span
                        :class="[
                          statusSelected == 'APPROVED' ? 'text-success' : '',
                        ]"
                        >{{ statusSelected ? statusSelected : "-" }}</span
                      >
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                  :cols="currentBreakPoint === 'sm' ? 10 : 5"
                  :class="`data-content my-2 mx-4 pt-1 ${
                    isDarkSkin ? 'bg-dark-sp' : 'bg-light-sp'
                  }`"
                >
                  <div>
                    <b-form-group label="CATCHER">
                      <span>{{ catcherSelected }}</span>
                    </b-form-group>
                  </div>
                </b-col>
                <b-col
                    :cols="currentBreakPoint === 'sm' ? 10 : 5"
                  :class="`${currentBreakPoint === 'sm' ? 'data-content my-2 mx-4 pt-1' : 'data-content my-2 pt-1'} ${
                    isDarkSkin ? 'bg-dark-sp' : 'bg-light-sp'
                  }`"
                >
                  <div>
                    <b-form-group label="SELLER">
                      <span>{{ sellerSelected }}</span>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <!-- No Data card text -->
    <div v-else class="text-center d-flex justify-content-center w-100">
      <div class="text-center text-primary my-2 w-100">
        <strong>There are no sales for this lead/client yet..</strong>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import StepProgressBar from "@/views/ce-digital/sub-modules/sales/views/derivations/components/StepProgressBar.vue";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";

export default {
  name: "DerivationTrackingModal",
  components: {
    StepProgressBar,
  },
  props: {
    derivationData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      stepData: [],
      leadClientData: [],
      stepLeadClientData: [],
      statusSelected: null,
      sellerSelected: null,
      catcherSelected: null,
    };
  },
  async mounted() {
    this.toggleModal("derivationTrackingModal");
    this.addPreloader();
    await this.getSaleProcessStatus();
    await this.getLeadClientDerivated();
    this.removePreloader();
  },
  computed: {
    ...mapGetters({
      currentBreakPoint: "app/currentBreakPoint",
    }),
  },
  methods: {
    // Get last step process sale
    getStepClientDerived(index) {
      this.leadClientData.forEach((e, i) => {
        if (i + 1 === index) {
          this.stepLeadClientData = this.leadClientData[i];
        }
      });
    },
    async getLeadClientDerivated() {
      try {
        
        const params = {
          lead_id: this.derivationData.lead_id
            ? this.derivationData.lead_id
            : null,
          client_id: this.derivationData.client_id
            ? this.derivationData.client_id
            : null,
        };
        const data = await DerivationsService.getLeadClientDerivated(params);
        if (data.status === 200) {
          this.leadClientData = data.data;
          if (this.leadClientData.length > 0) {
            this.setLastStatusSale(0);
          }
          const maxData = this.leadClientData.length - 1;
          this.stepLeadClientData = this.leadClientData[maxData];
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    setLastStatusSale(index) {
      this.statusSelected = this.stepData.find(
        (e) =>
          e.id ===
          this.leadClientData[index].sale_tracking[
            this.leadClientData[index].sale_tracking.length - 1
          ].sale_process_status_id
      ).sale_process_name;

      this.sellerSelected = this.leadClientData[index].seller_name;
      this.catcherSelected = this.leadClientData[index].catcher_name;
    },
    async getSaleProcessStatus() {
      const data = await DerivationsService.getSaleProcessStatus();
      if (data.status === 200) {
        this.stepData = data.data;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style lang="scss">
.bg-dark-sp {
  background-color: #111113;
}
.bg-light-sp {
  background-color: #e2e2e2;
}
.data-content {
  font-weight: bolder;
  border-radius: 15px;
  text-align: center;
}
.sub-tab-nav-client-details {
  .nav-link {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    background-color: #9060e4 !important;
    color: white !important;
  }
  .nav-link.active {
    background-color: #9060e4 !important;
    color: white !important;
  }
  a::after {
    content: "" !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 2px !important;
    background-color: #fff !important;
    transform: scaleX(0) !important;
    transition: transform 0.3s ease-in-out !important;
  }
}
</style>