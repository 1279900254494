import DashboardServive from "@/views/ceo/service-dashboard/dashboard.service";

export default {
  namespaced: true,
  state: {
    S_REFRESH_EXPENSES_COUNTER: false,
    S_REFRESH_AFTER_CREATE_EXPENSE: false,
    S_TOTALES_EXPENSES: null,
    S_METHOD_EXPENSE: null
  },
  getters: {
    G_REFRESH_EXPENSES_COUNTER(state) {
      return state.S_REFRESH_EXPENSES_COUNTER;
    },
    G_REFRESH_AFTER_CREATE_EXPENSE(state) {
      return state.S_REFRESH_AFTER_CREATE_EXPENSE;
    },
    G_TOTALES_EXPENSES(state) {
      return state.S_TOTALES_EXPENSES
    },
    G_METHOD_EXPENSE(state) {
      return state.S_METHOD_EXPENSE
    }
  },
  mutations: {
    M_SET_REFRESH_EXPENSES_COUNTER(state, payload) {
      state.S_REFRESH_EXPENSES_COUNTER = payload;
    },
    M_SET_REFRESH_AFTER_CREATE_EXPENSE(state, payload) {
      state.S_REFRESH_AFTER_CREATE_EXPENSE = payload;
    },
    M_SET_TOTALES_EXPENSES(state, payload) {
      state.S_TOTALES_EXPENSES = payload;
    },
    M_SET_METHOD_EXPENSE(state, payload) {
      state.S_METHOD_EXPENSE = payload;
    }
  },
  actions: {
    refreshExpensesCounter({ commit }, payload) {
      commit('M_SET_REFRESH_EXPENSES_COUNTER', payload);
    },
    refreshAfterCreateExpense({ commit }, payload) {
      commit('M_SET_REFRESH_AFTER_CREATE_EXPENSE', payload);
    },
    async getTotales({ commit }, payload) {
      try {
        const data = await DashboardServive.totalDepartmentExpenses(payload);
        commit("M_SET_TOTALES_EXPENSES", data);
      } catch (error) {

      }
    },
    A_METHOD_EXPENSE({ commit }, payload) {
      commit('M_SET_METHOD_EXPENSE', payload);
    }
  }
}
