<template>
  <b-modal
    v-model="showModal"
    size="lg"
    hide-footer
    title="TRACKING NCR"
    @hide="closeModal"
  >
    <div>
      <b-row>
        <b-col cols="12" md="6" v-if="isClient">
          <span class="title-tag">ACCOUNT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ dataNcr.client_account || "-" }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" :md="isClient ? 6 : 12">
          <span class="title-tag">{{ isClient ? "CLIENT" : "LEAD" }}</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ dataNcr.fullname || "-" }}
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="table-responsive">
      <b-table
        ref="trackingTable"
        :items="myProvider"
        :fields="visibleFields"
        primary-key="id"
        responsive="sm"
        show-empty
        sticky-header="50vh"
        class="blue-scrollbar mt-2"
        :busy="isBusy"
        no-provider-filtering
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(status)="data">
          <div class="text-info text-center">
            <b-badge
              style="width: 110px"
              :style="
                data.item.status_id == 1
                  ? 'background: #fad0141f; color:#fad014 !important'
                  : ''
              "
              :variant="badgeColor(data.item.status_id)"
            >
              {{ data.item.status }}
            </b-badge>
          </div>
        </template>

        <template #cell(created_by)="data">
          <div>
            {{ data.item.user_name }}
            <br />
            {{ data.item.created_at | myGlobalDay }}
          </div>
        </template>

        <template #cell(description)="data">
          <div>
            {{ data.item.description || "-" }}
          </div>
        </template>
        <template #cell(motive)="data">
          <div>
            {{ data.item.motive || "-" }}
          </div>
        </template>
        <template #cell(platform)="data">
          <div>
            {{ data.item.platform_name || "-" }}
          </div>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>
<script>
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";

export default {
  props: {
    dataNcr: {
      required: true,
    },
    isClient: {
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      isBusy: false,
      fields: [
        {
          key: "created_by",
          label: "Created by",
          thClass: "text-left",
          tdClass: "text-left",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          visible: true,
        },
        {
          key: "description",
          label: "Description",
          class: "text-left",
          visible: true,
        },
        {
          key: "motive",
          label: "motive failed",
          class: "text-left",
          visible: true,
        },
        {
          key: "platform",
          label: "Provider",
          class: "text-left",
          visible: true,
        },
      ],
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },

    isManual(){
      return this.dataNcr.type_ncr === 'MANUAL'
    }
  },
  created() {
    this.showModal = true
    const platformIndex = this.fields.findIndex(field => field.key === 'platform')
    this.fields[platformIndex].visible = !this.isManual
    
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },

    async myProvider(ctx) {
      try {
        const params = {
          idNcr: this.dataNcr.id,
        };
        const { data } = await NcrRequestService.getTrackingRequestNcr(params);
        return data;
      } catch (ex) {
        return [];
      }
    },

    badgeColor(badge) {
      let badgeClass = {
        1: "light-secondary",
        2: "light-info",
        3: "light-success",
        4: "light-danger",
        5: "light-secondary",
        6: "light-warning",
        7: "light-info"
      };
      return badgeClass[badge];
    },
  },
};
</script>
