export default [
    {
      path: "direct-round",
      name: "specialists-direct-round",
      component: () =>
        import(
          /* webpackChunkName: "directRoundSpecialist" */
          "@/views/specialists/sub-modules/analyst-department/views/direct-round/direct-round.vue"
        ),
      redirect: { name: "pending-directs-round" },
      meta: {
        module: 28,
        pageTitle: "Direct Dispute",
        breadcrumb: [
          {
            text: "Direct Dispute",
            active: true,
          },
        ],
        routeDirectPending: "pending-directs-round",
        routeDirectCompleted: "completed-directs-round",
        routeDirectToDeleted: "deleted-directs-round",
      },
      children: [
        {
          path: "pending",
          name: "pending-directs-round",
          component: () =>
            import(
              /* webpackChunkName: "pendingDirectsRound" */
              "@/views/specialists/sub-modules/analyst-department/views/direct-round/components/DirectRounds.vue"

             ),
          meta: {
            module: 28,
            typeTab: "pending",
            pageTitle: "Direct Dispute",
            breadcrumb: [
              {
                text: "Pending",
                active: true,
              },
            ],
          },
          props: { statusDirectType: "pending" },
        },
        {
          path: "completed",
          name: "completed-directs-round",
          component: () =>
            import(
              /* webpackChunkName: "completedDirectsRound" */
              "@/views/specialists/sub-modules/analyst-department/views/direct-round/components/DirectRounds.vue"
            ),
          meta: {
            module: 28,
            typeTab: "completed",
            pageTitle: "Direct Dispute",
            breadcrumb: [
              {
                text: "Completed",
                active: true,
              },
            ],
          },
          props: { statusDirectType: "completed" },
        },

        {
          path: "deleted",
          name: "deleted-directs-round",
          component: () =>
            import(
              /* webpackChunkName: "deletedDirectsRound" */
              "@/views/specialists/sub-modules/analyst-department/views/direct-round/components/DirectRounds.vue"
            ),
          meta: {
            module: 28,
            typeTab: "deleted",
            pageTitle: "Direct Dispute",
            breadcrumb: [
              {
                text: "Deleted",
                active: true,
              },
            ],
          },
          props: { statusDirectType: "deleted" },
        },
      ],
    },
    {
      path: "leads/report/:idlead/:idfile",
      name: "specialist-report-lead",
      component: () =>
        import(
          /* webpackChunkName: "reportLeadSpecialist" */
          "@/views/commons/components/ncr/components/report/ReportLead.vue"
        ),
      props: true,
      meta: {
        isClientsTab: true,
        module: 28,
        pendingRoute: "ncr-pending",
        returnedRoute: "ncr-returned",
        completedRoute: "ncr-completed",
        reportLeadRoute: "report-lead",
        leadRoute: "lead-show",
      },
    },
  ];
