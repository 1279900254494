export default
{
    path: "budget-yearly",
    name: "budget-logistic",
    component: () => import("./Budget.vue"),
    meta: {
        pageTitle: "Budget",
        breadcrumb: [
          {
            text: "Budget",
            active: true,
          },
        ],
      },
};