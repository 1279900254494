
export default [
  {
    path: "charges-made",
    name: "ce-digital-charges-made",
    component: () => import(/* webpackChunkName: "CEDFinancialChargesMade" */ "@/views/ce-digital/sub-modules/financial/view/charges-made/ChargesMadeMain.vue"),
    redirect: { name: "ce-digital-charges-made-successful" },
    children: [
      {
        path: "successful",
        component: () => import(/* webpackChunkName: "CEDFinancialChargesMadeSuccessful" */ "@/views/ce-digital/sub-modules/financial/view/charges-made/components/Table.vue"),
        name: "ce-digital-charges-made-successful",
        meta: {
          pageTitle: "Payments Made",
          parentModule: 21,
          pendingTab: true,
          breadcrumb: [
    
            {
              text: "Successful",
              active: true,
            },
          ],
          tab: 1,
          successfulRoute: "ce-digital-charges-made-successful",
          declinedRoute: "ce-digital-charges-made-declined",
          declinedManualRoute: "ce-digital-charges-made-declined-manual",
          declinedOthersRoute: "ce-digital-charges-made-declined-others",
        },
      },
      {
        path: "declined",
        component: () => import(/* webpackChunkName: "CEDFinancialChargesMadeDeclined" */ "@/views/ce-digital/sub-modules/financial/view/charges-made/components/DeclinedMain.vue"),
        name: "ce-digital-charges-made-declined",
        redirect: { name: "ce-digital-charges-made-declined-manual" },
        meta: {
          pageTitle: "Payments Made",
          breadcrumb: [
            {
              text: "Payments Made",
            },
            {
              text: "Declined",
              active: true,
            },
          ],
          
          successfulRoute: "ce-digital-charges-made-successful",
          declinedRoute: "ce-digital-charges-made-declined",
          declinedManualRoute: "ce-digital-charges-made-declined-manual",
          declinedOthersRoute: "ce-digital-charges-made-declined-others",
        },
        children: [
          {
            path: "manual",
            name: "ce-digital-charges-made-declined-manual",
            component: () => import(/* webpackChunkName: "CEDFinancialChargesMadeDeclinedManual" */ "@/views/ce-digital/sub-modules/financial/view/charges-made/components/Table.vue"),
            meta: {
              pageTitle: "Payments Made",
              breadcrumb: [
                {
                  text: "Declined",
                },
                {
                  text: "Manual",
                  active: true,
                },
              ],
              tab: 2,
              parentModule: 21,
              successfulRoute: "ce-digital-charges-made-successful",
              declinedRoute: "ce-digital-charges-made-declined",
              declinedManualRoute: "ce-digital-charges-made-declined-manual",
              declinedOthersRoute: "ce-digital-charges-made-declined-others",
    
            },
        
          },
          {
            path: "others",
            name: "ce-digital-charges-made-declined-others",
            component: () => import(/* webpackChunkName: "CEDFinancialChargesMadeDeclinedOthers" */ "@/views/ce-digital/sub-modules/financial/view/charges-made/components/Table.vue"),
            meta: {
              pageTitle: "Payments Made",
              breadcrumb: [
                {
                  text: "Declined",
                },
                {
                  text: "Others",
                  active: true,
                },
              ],
              tab: 3,
              parentModule: 21,
              successfulRoute: "ce-digital-charges-made-successful",
              declinedRoute: "ce-digital-charges-made-declined",
              declinedManualRoute: "ce-digital-charges-made-declined-manual",
              declinedOthersRoute: "ce-digital-charges-made-declined-others",
            },

          },
          
        ]
      },
    ],
   
  },
]
