import { amgApi } from '@/service/axios'

class NcrRealtorService {
    async getPendingNcrRealtorCount(body) {
        try {
            const { data } = await amgApi.post('commons/get-count-read-realtor', body);
            return data

        } catch (error) {
            console.error("Something went wrong on getPendingDirectRoundCount", error);
        }
    }
}

export default new NcrRealtorService()