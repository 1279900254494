export default [
  {
    path: "dashboard",
    name: "credit-experts-dash",
    component: () =>
      import(/* webpackChunkName: "CreditExpertsDashboard" */ "@/views/credit-experts/views/dashboard/Dashboard.vue"),
    meta: {
      program: 3,
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
        },
      ],
    },
  },
];
