

export default [
  {
    path: "schedules",
    name: "schedules-creditexperts",
    redirect: "/creditexperts/schedules/report",
    component: () => import(/* webpackChunkName: "CreditExpertsSchedules" */ "@/views/commons/components/schedules/SchedulesComponent.vue"),
    children: [
      {
        path: "report",
        name: "report-creditexperts",
        component: () => import(/* webpackChunkName: "CreditExpertsSchedulesReport" */ "@/views/commons/components/schedules/SchedulesReport.vue"),
        meta: {
          route: "creditexperts",
          permittedRoles: [1, 2, 11],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Reports By Module",
              active: true,
            },
          ],
        },
      },
      {
        path: "overtime",
        name: "overtime-creditexperts",
        component: () => import(/* webpackChunkName: "CreditExpertsSchedulesOvertime" */ "@/views/commons/components/schedules/SchedulesOvertime.vue"),
        meta: {
          tag: 1,
          route: "creditexperts",
          permittedRoles: [1, 2, 11],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Overtime",
              active: true,
            },
          ],
        },
      },
      {
        path: "justifications",
        name: "justifications-creditexperts",
        component: () => import(/* webpackChunkName: "CreditExpertsSchedulesJustifications" */ "@/views/commons/components/schedules/SchedulesJustifications.vue"),
        meta: {
          tag: 2,
          route: "creditexperts",
          permittedRoles: [1, 2, 11],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Justifications",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      route: "creditexperts",
      permittedRoles: [1, 2, 11],
    },
  },
];
