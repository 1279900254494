<template>
  <b-modal
    ref="modal-indications"
    size="xsm"
    title-tag="h3"
    modal-class="modal-primary"
    title="INDICATIONS"
    @hidden="$emit('close')"
    no-close-on-backdrop
    hide-footer
  >
    <InputIndication
      :caseInfo="caseInfo"
      :readOnly="readOnly"
      @saveCommentary="saveCommentary"
    />
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import InputIndication from "@/views/debt-solution/views/court-info/components/tabs/InputIndication.vue";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service.js";
export default {
  props: {
    caseId: {
      type: Number,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputIndication,
  },
  data() {
    return {
      commentary: "",
      caseInfo: {},
    };
  },
  async mounted() {
    await this.getCourtCase();
    this.toggleModal("modal-indications");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    saveCommentary(commentary) {
      this.commentary = commentary;
      this.onSubmit();
    },
    async getCourtCase() {
      try {
        const response = await CourtInfoService.getCourtCase({
          case_id: this.caseId,
        });
        this.caseInfo = response[0];
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          this.getInternalErrors(error) || "Something went wrong"
        );
      }
    },
    async onSubmit() {
      if (this.commentary != "") {
        await this.saveIndications();
      }
    },
    async saveIndications() {
      try {
        this.addPreloader();

        let params = {
          caseNumber: this.caseInfo.case_number,
          commentary: this.commentary,
          managedBy: this.caseInfo.managed_by,
          manageById: this.caseInfo.managed_by_id ?? this.currentUser.user_id,
          lead_id: this.caseInfo.lead_id,
          caseModule: this.caseInfo.case_module,
          clientAccountId: this.caseInfo.account_id,
          creditorName: this.caseInfo.creditor_name,
          isLead: this.caseInfo.is_lead,
          caseId: this.caseId,
          documentTypeName: this.caseInfo.document_type_name,
          document_type_id: this.caseInfo.document_type_id,
          currentUser: this.currentUser.user_id,
          lead_come_from_modul_id: this.caseInfo.modul_id,
        };

        const data = await CourtInfoService.storeGiveIndications(params);
        this.$emit("refresh");
        this.$emit("close");
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          `${data.message}`
        );
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          this.getInternalErrors(error) || "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>