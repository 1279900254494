<template>
  <transition name="bounce">
    <b-card v-if="show">
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col md="4">
              <b-form-group label="Title" label-for="title">
                <v-select
                  input-id="title"
                  v-model="event.title"
                  label="label"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  :options="G_EVENT_TITLES_OPTIONS"
                  :reduce="(val) => val.value"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Seller" label-for="seller">
                <v-select
                  input-id="seller"
                  v-model="data.item.seller_name"
                  label="label"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="G_OWNERS"
                  :clearable="false"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Location" label-for="location">
                <b-form-input
                  id="location"
                  v-model="data.item.location"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description" label-for="description">
                <b-form-textarea
                  id="description"
                  v-model="data.item.description"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-row>
            <b-col md="4">
              <b-form-group label="Date" label-for="date">
                <kendo-timepicker
                  disabled
                  :format="'HH:mm'"
                  v-model="data.item.date"
                  :interval="30"
                  class="w-100 rounded bg-transparent"
                  :class="isDarkSkin ? 'text-dark' : 'text-black-prov'"
                  style="height: 2.73rem"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="From" label-for="from">
                <kendo-timepicker
                  :format="'HH:mm'"
                  v-model="data.item.from"
                  :interval="30"
                  :class="[
                    'w-100 rounded bg-transparent',
                    { 'text-white': isDarkSkin },
                  ]"
                  placeholder="Hour"
                  :disabled="true"
                  style="height: 2.73rem"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="To" label-for="to">
                <kendo-timepicker
                  :disabled="true"
                  :format="'HH:mm'"
                  v-model="data.item.to"
                  :interval="30"
                  :class="[
                    'w-100 rounded bg-transparent',
                    { 'text-white': isDarkSkin },
                  ]"
                  placeholder="Hour"
                  style="height: 2.73rem"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6" class="text-left mt-1" v-if="isCrm">
          <div class="d-flex justify-content-between align-items-center mt-2">
            <span class="font-weight-bold mr-1"> Lead attend the call? </span>
            <b-form-checkbox
              v-model="isCheckedAnswer"
              value="no"
              class="mr-2 d-inline"
              disabled
              >No</b-form-checkbox
            >
            <b-form-checkbox
              v-model="isCheckedAnswer"
              disabled
              value="yes"
              class="mr-2 d-inline"
              >Yes</b-form-checkbox
            >
          </div>
        </b-col>

        <b-col
          md="6"
          class="text-left mt-1"
          v-if="data.item.call_answered && isCrm"
        >
          <div class="d-flex justify-content-between align-items-center mt-2">
            <span class="font-weight-bold mr-1"> Lead attend the call? </span>
            <b-form-checkbox
              v-model="isCheckedAttend"
              disabled
              value="no"
              class="mr-2 d-inline"
              >No</b-form-checkbox
            >
            <b-form-checkbox
              v-model="isCheckedAttend"
              value="yes"
              class="mr-2 d-inline"
              disabled
              >Yes</b-form-checkbox
            >
          </div>
        </b-col>
      </b-row>
    </b-card>
  </transition>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import VueToggles from "vue-toggles";

export default {
  name: "DetailRowTracking",
  props: {
    data: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
  },
  components: {
    flatPickr,
    vSelect,
    VueToggles,
  },
  async created() {
    this.$nextTick(() => (this.show = true));
    await this.getOwners();
    this.sellers = this.G_OWNERS;

    if (this.event.call_answered === 1) {
      this.isCheckedAnswer = "yes";
    } else if (this.event.call_answered === 0) {
      this.isCheckedAnswer = "no";
    } else {
      this.isCheckedAnswer = null;
    }

    if (this.event.call_attended === 1) {
      this.isCheckedAttend = "yes";
    } else if (this.event.call_attended === 0) this.isCheckedAttend = "no";
    else {
      this.isCheckedAttend = null;
    }
  },
  data() {
    return {
      isCheckedAnswer: false,
      isCheckedAttend: false,
      show: false,
      sellers: [],
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_EVENT_TITLES_OPTIONS: "CrmEventStore/G_EVENT_TITLES",
      G_OWNERS: "CrmGlobalStore/G_OWNERS",
    }),
    ...mapState({
      S_LEAD_APPOINTMENT_TRACKING: (event) =>
        event.CrmLeadStore.S_LEAD_APPOINTMENT_TRACKING,
    }),
    isCrm() {
      return this.$route.matched[0].meta.module === 2;
    },
  },
  methods: {
    ...mapActions({
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
    }),
    setInitialUser() {
      [11, 25, 26].indexOf(this.moduleId) === -1
        ? (this.event.user_id = this.currentUser.user_id)
        : null;
      this.moduleId !== 26
        ? (this.event.created_users = this.currentUser.user_id)
        : null;
    },

    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId)
          ? "[1,2,5]"
          : "[1,2,3,5,17]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
        this.sellers = this.G_OWNERS;
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
  },
};
</script>
<style>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
