import DashboardRouter from "@/views/logistic/views/dashboard/dashboard.router"
import ProductsInventoryNavigation from "@/views/logistic/views/products-inventory/products-inventory.router"
import BillsRouter from "@/views/logistic/views/bills/router/bills.router.js"
import HelpdeskRoute from "@/views/logistic/views/helpdesk/helpdesk.route"
import Merchandising from "@/views/logistic/views/merchandising/merchandising.router"
import Vendors from "@/views/logistic/views/vendors/vendors.router"
import BudgetRouter from "@/views/logistic/views/budget/budget.router.js"
import LoansRouter from "@/views/logistic/views/loans/loans.router"
import SettingsRouter from "@/views/logistic/views/settings/settings.router"
import ProductRequestRouter from "@/views/logistic/views/product-request/router/product-request.router"
import LogisticAttendanceRouter from "@/views/logistic/views/attendance/logistic-attendance.router"
import VendorRouter from "@/views/logistic/views/vendors/vendor.router.js"
import EmployeeClaimsRouter from "@/views/logistic/views/employee-claims/router/employee-claims.router"
import LogisticMain from "@/views/logistic/views/LogisticMain.vue"
import DashboardClaims from "@/views/logistic/views/dashboard-claims/dashboard.router.js";
import Inventory2 from "@/views/logistic/views/inventory2/inventory.router"
import RequestRouter from "@/views/logistic/views/request/request.router"
//import LogisticProjectsRouter from '@/views/logistic/views/projects/logistic-projects.router';
import CallLogRouter from "@/views/logistic/views/call-log/call-log.router.js";
import MOFRouter from '@/views/logistic/views/manual-of-functions/manual-of-functions.router.js'
import RecruitmentProcessRouter from '@/views/logistic/views/recruitment-process/logistic-recruitment_process.router';
import CustomerTicketsRouter from '@/views/logistic/views/customer-tickets/logistic-customer-tickets.router';

const routes = [
  {
    path: '/logistic',
    redirect: { name: 'dashboard-logistic' },
    component: LogisticMain,
    meta: { module: 19 },
    children: [
      DashboardRouter,
      // BillsRouter,
      ...ProductsInventoryNavigation,
      ...BillsRouter,
      ...Merchandising,
      Vendors,
      BudgetRouter,
      HelpdeskRoute,
      ...LoansRouter,
      ...ProductRequestRouter,
      ...LogisticAttendanceRouter,
      //...LogisticProjectsRouter,
      ...SettingsRouter,
      ...EmployeeClaimsRouter,
      ...DashboardClaims,

      ...SettingsRouter,
      ...VendorRouter,
      ...Inventory2,
      ...RequestRouter,
      ...CallLogRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...CustomerTicketsRouter,

    ],
  },
];
export default routes;
