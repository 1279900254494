var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"white-space":"break-spaces !important","overflow-wrap":"anywhere !important","text-align":"justify !important"}},[_c('div',{class:_vm.TextAlign ? 'text-' + _vm.TextAlign : '',domProps:{"innerHTML":_vm._s(
      _vm.SmsData.length >= (_vm.TextLength ? _vm.TextLength : 75)
        ? _vm.changeSms
          ? ((_vm.SmsData.replace(/\n/g, '<br \/>').substr(
              0,
              _vm.TextLength ? _vm.TextLength : 75
            )) + "...")
          : _vm.SmsData.replace(/\n/g, '<br \/>')
        : _vm.SmsData.replace(/\n/g, '<br \/>')
    )}}),(
      _vm.SmsData.length > (_vm.TextLength ? _vm.TextLength : 75) &&
      _vm.TypeShowMore == 'button'
    )?_c('b-button',{staticClass:"text-nowrap",attrs:{"variant":_vm.Variant ? _vm.Variant : 'flat-info',"size":"sm"},on:{"click":function($event){return _vm.returnDataSms()}}},[_vm._v(_vm._s(_vm.formatedBody))]):_vm._e(),(
      _vm.SmsData.length > (_vm.TextLength ? _vm.TextLength : 75) &&
      _vm.TypeShowMore == 'span'
    )?_c('small',{staticClass:"text-nowrap cursor-pointer",class:_vm.Variant ? 'text-' + _vm.Variant : 'text-info',on:{"click":function($event){return _vm.returnDataSms()}}},[_vm._v(_vm._s(_vm.formatedBody + "..."))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }