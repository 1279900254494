<template>
  <div>
    <span v-if="Boolean(item.type_payment_ce)">
      $
      {{
        typeof item.fee === "number"
          ? $options.filters.currency(item.fee)
          : item.fee
      }}
      <span class="text-muted">
        ({{ item.type_payment_ce === 1 ? "SP" : "MP" }})
      </span>
    </span>
    <span
      v-else
      class="d-flex align-items-center justify-content-center"
      style="gap: 10px"
    >
      <span v-if="!item.editFee">
        $
        {{
          typeof item.fee === "number"
            ? $options.filters.currency(item.fee)
            : item.fee
        }}
      </span>
      <span v-else>
        <b-input-group v-if="item.editFee" size="sm" style="width: 160px">
          <template #prepend>
            <b-button
              @click="
                item.editFee = false;
                item.feeNew = item.fee;
              "
              variant="outline-danger"
              ><feather-icon icon="XSquareIcon" class="cursor-pointer"
            /></b-button>
          </template>

          <money
            v-model="item.feeNew"
            class="form-control p-0"
            v-bind="{ prefix: ' $ ', precision: 2 }"
          />

          <template #append>
            <b-button
              variant="outline-primary"
              @click="saveNewFee(item.fee, item.feeNew, item.id, item)"
              ><feather-icon class="cursor-pointer" icon="SaveIcon"
            /></b-button>
          </template>
        </b-input-group>
      </span>

      <span
        v-if="
          (item.status === 1 || item.status === 3) &&
          (isCeo || isSupervisor || isChief || isCoordinator || isTeamLeader)
        "
        class="mt-07 text-right mr-1"
      >
        <feather-icon
          v-if="!item.editFee"
          icon="Edit3Icon"
          class="cursor-pointer"
          @click="!(item.haveRates !== 1) && (item.editFee = true)"
          size="18"
        />

        <feather-icon
          v-if="!item.editFee"
          icon="ListIcon"
          class="cursor-pointer ml-07"
          @click="openTrackingCapturedByModal(3)"
          size="18"
        />
      </span>
    </span>

    <tracking-captured-by-modal
      v-if="modal.captuerd_by_tracking"
      :modal="modal"
      :captured-by-tracking="capturedByTracking"
    />
  </div>
</template>

<script>
import CrmService from "@/views/crm/services/crm.service";
import TrackingCapturedByModal from "@/views/crm/views/sales-made/components/modals/TrackingCapturedByModal.vue";

export default {
  components: {
    TrackingCapturedByModal,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    currentUser: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      capturedByTracking: {},
      modal: {
        captuerd_by_tracking: false,
      },
    };
  },
  methods: {
    async saveNewFee(fee, feeNew, userId, user) {
      if (fee === feeNew) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "You can't select the same captured"
        );
      } else {
        this.$store.commit("app/SET_LOADING", true);
        try {
          const response = await CrmService.saveNewFee({
            fee: feeNew,
            id: userId,
            user: this.currentUser.user_id,
          });
          if (response.status === 200) {
            this.showSuccessSwal();
          } else return;
          user.fee = user.feeNew;
          user.feeNew = user.fee;
          user.editFee = false;
          this.$store.commit("app/SET_LOADING", false);
        } catch (error) {
          this.showToast("danger", "top-right", "Error", "XIcon", error);
          this.$store.commit("app/SET_LOADING", false);
        }
      }
    },
    openTrackingCapturedByModal(type) {
      this.capturedByTracking.client = this.item.client;
      this.capturedByTracking.program = this.item.program;
      this.capturedByTracking.id = this.item.id;
      this.capturedByTracking.type = type;
      if (type === 1) this.capturedByTracking.tittle = "CAPTURED BY";
      else if (type === 2) this.capturedByTracking.tittle = "SELLER";
      else if (type === 3) this.capturedByTracking.tittle = "FEE";
      this.modal.captuerd_by_tracking = true;
    },
  },
};
</script>

<style>
</style>