<template>
  <b-modal
    v-model="modalShow"
    :title="modal.title"
    title-tag="h3"
    centered
    @ok.prevent="validatePassword()"
    cancel-variant="outline-danger"
    size="lg"
    @hidden="$emit('hideModal')"
    id="modalSNEditTask"
    no-close-on-backdrop
  >
    <div>
      <ValidationObserver ref="form">
        <b-row>
          <b-col cols="12">
            <b-form-group :label="modal.label" label-for="current-password">
              <ValidationProvider
                v-slot="{ errors, valid }"
                vid="current"
                name="current"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="current"
                    v-model="userData.currentPassword"
                    :type="isWatch ? 'text' : 'password'"
                    placeholder="Current Password"
                    :state="
                      errors[0] || validPass ? false : valid ? true : null
                    "
                  />
                  <b-input-group-append class="border-style" is-text>
                    <feather-icon
                      :icon="!isWatch ? 'EyeIcon' : 'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="isWatch = !isWatch"
                    />
                  </b-input-group-append>
                  <b-form-invalid-feedback>
                    <span v-if="validPass">Password Incorrect</span>
                  </b-form-invalid-feedback>
                </b-input-group>
              </ValidationProvider>
            </b-form-group>
          </b-col>
        </b-row>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import EditService from "@/views/users/commons/users/services/edit-user.service.js";
export default {
  props: {
    validationType: {
      type: String,
      require: true,
    },
    action: {
      type: String,
    },
  },
  data() {
    return {
      modalShow: true,
      isWatch: false,
      userData: {
        id_user: this.userId,
        super_id: this.superId,
        currentPassword: "",
      },
      validPass: false,
      modal: {
        title: "Validate SELLER Password",
        label: "Current SELLER Password",
      },
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    switch (this.validationType) {
      case "ceo":
        this.modal.title = "Validate CEO Password";
        this.modal.label = "Current CEO Password";
        break;
      case "seller":
        this.modal.title = "Validate SELLER Password";
        this.modal.label = "Current SELLER Password";
        break;
      case "ds_supervisor":
        this.modal.title = "Validate DS SUPERVISOR Password";
        this.modal.label = "Current DS SUPERVISOR Password";
        break;
      case "crm_supervisor":
        this.modal.title = "Validate CRM SUPERVISOR Password";
        this.modal.label = "Current CRM SUPERVISOR Password";
        break;
      default:
        break;
    }
  },
  methods: {
    async validatePassword() {
      const result = await this.$refs.form.validate();
      if (result) {
        switch (this.validationType) {
          case "ceo":
            break;
          case "seller":
            await this.validateSellerPasword();
            break;
          case "ds_supervisor":
            await this.validateSupervisorPassword();
            break;
          case "crm_supervisor":
            await this.validateSupervisorPassword();
            break;
          default:
            break;
        }
      }
    },
    async validateSellerPasword() {
      try {
        // this.addPreloader();
        this.validPass = false;
        const { data } = await EditService.validateSellerPassword({
          user_id: this.currentUser.user_id,
          password: this.userData.currentPassword,
        });
        if (data) {
          this.$emit("validatedPass");
        }
        this.validPass = !data;
      } catch (error) {
        console.log(error);
      } finally {
        // this.removePreloader();
      }
    },
    async validateSupervisorPassword() {
      try {
        // this.addPreloader();
        this.validPass = false;
        const { data } = await EditService.validateSupervisorPassword({
          module_id: this.moduleId,
          role_id: 2,
          password: this.userData.currentPassword,
        });
        if (data) {
          this.$emit("validatedPass");
        }
        this.validPass = !data;
      } catch (error) {
        console.log(error);
      } finally {
        // this.removePreloader();
      }
    },
  },
};
</script>

<style scoped>
.border-style {
  border-right-style: solid;
  border-color: rgba(255, 255, 255, 0.4);
  border-width: 1px;
}
</style>