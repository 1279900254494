<template>
    <img
        style="max-width: 40px; max-height: 40px;"
        :src="getProgramImageRoute(programId)"
        alt="img_services"
        v-b-tooltip.bottom="programName"
        :class="showGrayImg ? 'img-gray' : ''"
    />
</template>
<script>
export default {
    props: {
        programName: {
            type: String,
            required: true
        },
        programId: {
            type: Number,
            required: true
        },
        showGrayImg: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style scoped>
.img-gray {
  filter: grayscale(100%);
  opacity: 0.8;
}
</style>