import { sumTag } from '@/@core/utils/utils';
import customerTickets from '@/views/ce-digital/sub-modules/connection/views/customer-tickets/connection-customer-tickets.navigation';
import clients from '@/views/ce-digital/sub-modules/connection/views/clients/navigation/clients.nav';

export default {
  title: 'Clients',
  icon: 'UsersIcon',
  children: [clients, customerTickets],
  tag: sumTag([clients, customerTickets]),
};
