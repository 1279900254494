<template>
  <div>
    <b-container class="m-0 w-100" fluid>
      <lead-list-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        :key="keyCreateList"
        @saveLead="keyCreateList = Math.random()"
      />
      <header-slot>
        <template #actions>
          <div>
            <b-button
              v-show="isModuleActualEqualTwo"
              variant="success"
              class="mr-1"
              @click="openModalSearch()"
            >
              <feather-icon
                icon="SearchIcon"
                size="15"
                class="mr-50 text-white"
              />Payments Search
            </b-button>
            <b-button
              v-if="!isOnlyLead"
              variant="success"
              class="mr-1"
              @click="isAddNewUserSidebarActive = true"
            >
              <feather-icon
                icon="PlusIcon"
                size="15"
                class="mr-50 text-white"
              />Create
            </b-button>
          </div>
        </template>
      </header-slot>

      <b-nav card-header pills class="m-0">
        <b-nav-item
          :to="{ name: $route.meta.newsTab }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          News
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.meta.tracingTab }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          Tracing
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.meta.successTab }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          Success
        </b-nav-item>
        <b-nav-item
          :to="{ name: $route.meta.recycledTab }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
        >
          Recycled
        </b-nav-item>
      </b-nav>
      <template>
        <b-card class="border-top-primary border-3 border-table-radius px-0">
          <router-view :key="$route.name" />
        </b-card>
      </template>
    </b-container>
    <modal-search-payment
      v-if="openModalSearchPayment"
      @close="closeModalSearch"
    />
  </div>
</template>

<script>
import chargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import ModalSearchPayment from "@/views/commons/components/charge-back_v2/view/modal/ModalSearchPayment.vue";
import { mapGetters, mapState, mapActions } from "vuex";
import LeadListAddNew from "./components/sidebar/LeadListAddNew.vue";
export default {
  components: {
    LeadListAddNew,
    ModalSearchPayment,
  },
  computed: {
    isModuleActualEqualTwo() {
      return this.permitStatus === 1;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      skin: "appConfig/skin",
      G_STATE_LEADS: "CrmLeadStore/G_STATE_LEADS",
    }),
    ...mapState({
      S_SELECTED_LEADS: (state) => state.CrmLeadStore.S_SELECTED_LEADS,
      S_FILTERS_LEADS: (state) => state.CrmLeadStore.S_FILTERS_LEADS,
    }),
    routeModule() {
      return this.$route.meta.route;
    },
    isLeadsRoute() {
      return this.$route.path === `/${this.routeModule}/leads/`;
    },
  },
  data() {
    return {
      openModalSearchPayment: false,
      moduleActual: null,
      permitStatus: null,
      isOnlyLead: false,
      isAddNewUserSidebarActive: false,
      preloading: true,
      dato1: "desc",
      dato2: 10,
      isLoading: false,
      keyCreateList: 0,
      filterDataLoaded: false,
    };
  },
  async created() {
    await Promise.all([
      this.getStateLeads(),
      this.getStatusLeads(),
      this.getSourceLeads(),
      this.getOwners(),
      this.getPrograms(),
      this.getSourceNames(),
      this.getStates(),
      this.getEeuuStates(),
      this.getCountries(),
      this.getSellers(),
      this.moduleId(),
    ]);
    this.$store.commit("CrmLeadStore/SET_DATA", {
      destination: "S_FILTER_DATA_LOADED",
      data: true,
    });
  },
  mounted() {
    this.getAccessModules();
  },
  methods: {
    async getAccessModules() {
      try {
        const params = {
          module_actual: this.moduleActual,
        };
        const result = await chargeBackService.getAccessModule(params);
        this.permitStatus = result[0]?.permit_status;
      } catch (error) {
        console.error(error);
      }
    },
    moduleId() {
      this.moduleActual = this.$route.matched[0].meta.module;
    },
    openModalSearch() {
      this.openModalSearchPayment = true;
    },
    closeModalSearch() {
      this.openModalSearchPayment = false;
    },
    ...mapActions({
      A_GET_STATE_LEADS: "CrmLeadStore/A_GET_STATE_LEADS",
      A_GET_STATUS_LEADS: "CrmLeadStore/A_GET_STATUS_LEADS",
      A_GET_SOURCE_LEADS: "CrmLeadStore/A_GET_SOURCE_LEADS",
      A_EXPORT_LEADS_TO_EXCEL: "CrmLeadStore/A_EXPORT_LEADS_TO_EXCEL",
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      A_GET_PROGRAMS: "CrmGlobalStore/A_GET_PROGRAMS",
      A_GET_SOURCE_NAMES: "CrmGlobalStore/A_GET_SOURCE_NAMES",
      A_GET_STATES: "CrmGlobalStore/A_GET_STATES",
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_COUNTRIES: "CrmGlobalStore/A_GET_COUNTRIES",
      A_GET_SELLERS: "CrmGlobalStore/A_GET_SELLERS",
    }),
    async getStateLeads() {
      try {
        await this.A_GET_STATE_LEADS();
      } catch (error) {
        console.log("Something went wrong getStateLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStatusLeads() {
      try {
        await this.A_GET_STATUS_LEADS();
      } catch (error) {
        console.log("Something went wrong getStatusLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceLeads() {
      try {
        await this.A_GET_SOURCE_LEADS();
      } catch (error) {
        console.log("Something went wrong getSourceLeads:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getOwners() {
      try {
        await this.A_GET_OWNERS({
          modul: this.currentUser.modul_id,
          body: { roles: "[1,2,5]", type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getOwners:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getPrograms() {
      try {
        await this.A_GET_PROGRAMS();
      } catch (error) {
        console.log("Something went wrong getPrograms:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSourceNames() {
      try {
        await this.A_GET_SOURCE_NAMES();
      } catch (error) {
        console.log("Something went wrong getSourceNames:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getStates() {
      try {
        await this.A_GET_STATES({ type: 1 });
      } catch (error) {
        console.log("Something went wrong getStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getEeuuStates() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getCountries() {
      try {
        await this.A_GET_COUNTRIES();
      } catch (error) {
        console.log("Something went wrong getCountries:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getSellers() {
      try {
        await this.A_GET_SELLERS({
          modul: this.currentUser.modul_id,
          body: { roles: "[]", type: "1" },
        });
      } catch (error) {
        console.log("Something went wrong getSellers:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async exportExcel(Export, TypeExport) {
      const id_leads = this.S_SELECTED_LEADS.map((el) => el.id);
      const name_text = this.S_FILTERS_LEADS.searchQuery
        ? this.S_FILTERS_LEADS.searchQuery
        : null;
      this.dato2 = this.S_FILTERS_LEADS.perPage;

      const date_from =
        this.S_FILTERS_LEADS.from == "" ? null : this.S_FILTERS_LEADS.from;
      const date_to =
        this.S_FILTERS_LEADS.to == "" ? null : this.S_FILTERS_LEADS.to;
      const orderby = this.dato2 == null ? 10 : this.dato2;
      const order = this.dato1 == null ? "desc" : this.dato1;

      let filterStatusLead =
        this.S_FILTERS_LEADS.statusLead != null
          ? this.S_FILTERS_LEADS.statusLead.toString()
          : "";
      let filterPrograms =
        this.S_FILTERS_LEADS.program != null
          ? this.S_FILTERS_LEADS.program.toString()
          : "";
      let filterSourceName =
        this.S_FILTERS_LEADS.sourceName != null
          ? this.S_FILTERS_LEADS.sourceName.toString()
          : "";

      const params = {
        type_export: TypeExport,
        current_page: this.S_FILTERS_LEADS.currentPage,
        id_leads,
        name_text,
        lead_status: filterStatusLead,
        cr: this.S_FILTERS_LEADS.cr,
        program: filterPrograms,
        date_from,
        date_to,
        orderby,
        order,
        per_page: this.dato2,
        user_owner: this.S_FILTERS_LEADS.owner,
        assign_to: this.S_FILTERS_LEADS.assignTo,
        sourcename: filterSourceName,
      };
      try {
        this.isLoading = true;
        const response = await this.A_EXPORT_LEADS_TO_EXCEL(params);
        await this.forceFileDownload(response, "leads.xlsx");
        this.isLoading = false;
      } catch (error) {
        this.showErrorSwal(error);
        this.isLoading = false;
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("CrmLeadStore/SET_DATA", {
      destination: "S_FILTER_DATA_LOADED",
      data: false,
    });
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
