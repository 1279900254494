import { saleMadeTabs } from "@/views/commons/components/sales-made/router/sale-made.router";

export default {
	path: "sales-made",
	name: "sales-made-paragon",
	redirect: { name: "sales-made-paragon-lead" },
	component: () => import(/* webpackChunkName: "PRSalesMade" */ "@/views/paragon/views/sales-made/index.vue"),
	meta: {
		program: 9, // ??
		pageTitle: "Sales Made",
		breadcrumb: [
			{
				text: "Sales Made",
			},
		],
	},
	// children: [
	// 	{
	// 		path: "add-change",
	// 		name: "sales-made-paragon-add-change",
	// 		component: () => import(/* webpackChunkName: "PRSalesMadeAddChange" */ "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue"),
	// 		meta: {
	// 			program: 9,
	// 			pageTitle: "Sales Made",
	// 			breadcrumb: [
	// 				{
	// 					text: "Sales Made",
	// 					active: true,
	// 				},
	// 			],
	// 		},
	// 	},
	// 	{
	// 		path: "add-sale",
	// 		name: "sales-made-paragon-add-sale",
	// 		component: () => import(/* webpackChunkName: "PRSalesMadeAddSale" */ "@/views/commons/components/sales-made/add-sale/components/SalesMadeAddSaleComponent.vue"),
	// 		meta: {
	// 			type: 0,
	// 			program: 9,
	// 			pageTitle: "Sales Made",
	// 			breadcrumb: [
	// 				{
	// 					text: "Sales Made",
	// 					active: true,
	// 				},
	// 			],
	// 		},
	// 	},
	// ],
  children: saleMadeTabs("paragon")
}
