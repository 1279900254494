<template>
  <b-modal
    v-model="control"
    size="xl"
    hide-footer
    header-bg-variant="light-primary"
    @hidden="closeModal"
  >

    <template #modal-title>
      <h4 style="font-family: Montserrat; margin: 0px;">
        <feather-icon
          icon="SettingsIcon"
          size="20"
        /> Ticket Customer Settings
      </h4>
    </template>
    <b-tabs
      active-nav-item-class="font-weight-bolder text-uppercase text-primary"
    >
      <b-tab title="MEETINGS">
        <availability />
      </b-tab>
      <b-tab title="USERS">
        <users-availability />
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>

import Availability from '@/views/management/views/settings/components/availability/Availability.vue';
import UsersAvailability from '@/views/management/views/settings/components/users_availability/UsersAvailability.vue';

export default {

  components: {
    Availability,
    UsersAvailability,
  },

  data() {
    return {
      control: false,
    };
  },

  created() {
    this.control = true;
  },

  methods: {

    closeModal() {
      this.$emit('closeModal');
    },

  },
};
</script>
