<template>
    <svg :width="width" :height="height" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="29.9299" cy="29.9299" r="29.9299" fill="url(#paint0_linear_154_682)" />
        <path
            d="M30.4276 15.1491C38.2871 15.0534 44.8426 21.6449 44.8306 29.5642C44.8187 37.4117 38.3469 44.0031 30.3319 43.9672C22.4963 43.9313 15.9288 37.4356 16.0006 29.4086C16.0724 21.5013 22.6279 15.0534 30.4276 15.1491ZM31.3248 26.6931C31.3248 26.0591 31.3248 25.4131 31.3248 24.7791C31.3248 24.5398 31.3248 24.3006 31.3248 24.0613C31.3008 23.3794 30.9539 22.9249 30.4276 22.9129C29.8893 22.9009 29.5184 23.3675 29.4945 24.0852C29.4945 24.2408 29.4945 24.4082 29.4945 24.5638C29.4945 25.9155 29.4825 27.2793 29.5064 28.6311C29.5184 29.0378 29.3868 29.3488 29.0878 29.6359C27.7599 30.9399 26.456 32.2438 25.152 33.5597C24.6496 34.0621 24.6137 34.5167 24.9965 34.9234C25.3913 35.3541 25.8818 35.3302 26.4081 34.8038C27.9034 33.3204 29.3988 31.8131 30.8941 30.3298C31.2171 30.0068 31.3487 29.6479 31.3367 29.2053C31.3248 28.3679 31.3248 27.5305 31.3248 26.6931Z"
            fill="white" />
        <defs>
            <linearGradient id="paint0_linear_154_682" x1="4.81016" y1="-1.06893" x2="59.8598" y2="59.8598"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.117499" stop-color="#67E1FF" />
                <stop offset="0.822018" stop-color="#27A6ED" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            required: false,
            default: "30"
        },
        height: {
            type: String,
            required: false,
            default: "30"
        }
    }
}
</script>
  