import { appointmentTabs } from "@/views/commons/components/appointments/router/appointment.tab";


export default [
  {
    path: "appointment",
    name: "credit-experts-appointment",
    redirect: { name: "ce-appointment-pending" },
    component: () => import(
      "@/views/commons/components/appointments/view/AppointmentMain.vue"
    ),
    meta: {
      router: "ce-appointment",
      pageTitle: "Appointment",
      breadcrumb: [
        {
          text: "Appointment",
          active: true,
        },
      ],
      routePending: `ce-appointment-pending`,
      routeInProcess: `ce-appointment-in-process`,
      routeCompleted: `ce-appointment-completed`,
      routeAccepted: `ce-appointment-accepted`,
      routeRejected: `ce-appointment-rejected`,
      routeExpired: `ce-appointment-expired`,
    },
    children: appointmentTabs("ce-appointment","Appointments"),
  },
];


