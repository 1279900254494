<template>
  <b-row>
    <b-col
      cols="12"
      class="d-flex align-items-center justify-content-end mt-1 pr-0 pl-0"
      :class="countCreditor != 0 ? '' : 'mr-1 mb-1 pt-1'"
    >
      <b-button
        variant="outline-success"
        class="rounded"
        style="border-width: 1px; margin-right: 7px"
        @click="openModalCreditor"
        :disabled="fdlead ? false : state == 0"
      >
        <span> Add </span>
        <feather-icon icon="PlusIcon" />
      </b-button>

      <ButtonExportAnalysis
        :leadId="leadId"
        :event-id="id_event"
        v-if="countCreditor != 0"
        :disabled="programPayment == 0"
        v-b-tooltip.hover
        :title="
          programPayment == 0
            ? 'Payment program and retainer fee must be completed to perform this action'
            : ''
        "
      />
    </b-col>
    <ValidationObserver ref="creditor">
      <b-table
        responsive
        fixed
        ref="tableCreditor"
        :fields="visibleFields"
        :items="myProvider"
        class="position-relative table-new-customization"
        show-empty
        empty-text="No matching records found"
        :busy.sync="isBusy"
        sticky-header="55vh"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(name_creditor)="data">
          <span>{{ data.item.credit || data.item.name_creditor }}</span>
        </template>
        <template v-slot:cell(balance)="data">
          $ {{ data.value | currency }}
        </template>
        <template v-slot:cell(monthly)="data">
          <div v-if="!data.item.editData">
            <span> $ {{ data.value | currency }} </span>
          </div>
          <div v-else>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <money
                v-model="data.item.monthly"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: '$',
                  precision: 2,
                }"
                class="form-control form-control-sm"
                style="width: 100px"
                :state="errors[0] ? false : null"
              />
            </ValidationProvider>
          </div>
        </template>
        <template v-slot:cell(months)="{ item }">
          <div v-if="!item.editData">
            <span>
              {{ item.months }}
            </span>
          </div>
          <div v-else>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-input
                type="number"
                v-model="item.months"
                class="form-control form-control-sm"
                style="width: 60px"
                :state="errors[0] ? false : null"
              ></b-input>
            </ValidationProvider>
          </div>
        </template>
        <template v-slot:cell(interest)="{ item }">
          <div v-if="!item.editData">
            <span>
              {{ item.interest }}
            </span>
          </div>
          <div v-else>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <money
                v-model="item.interest"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  prefix: '%',
                  precision: 2,
                }"
                class="form-control form-control-sm"
                style="width: 70px"
                :state="errors[0] ? false : null"
              />
            </ValidationProvider>
          </div>
        </template>
        <template v-slot:cell(type)="data">
          <div v-if="data.item.editData" style="min-width: 150px">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-model="data.item.ds_type_id"
                :options="types"
                :reduce="(value) => value.id"
                label="value"
                transition=""
                :clearable="false"
                :state="errors[0] ? false : null"
              />
            </ValidationProvider>
          </div>
          <div v-else>
            {{ data.item.type }}
          </div>
        </template>
        <template v-if="state == 1 || fdlead" #cell(actions)="data">
          <feather-icon
            v-if="!data.item.editData"
            class="cursor-pointer text-warning font-medium-4 mr-1"
            icon="EditIcon"
            @click="editData(data.item)"
          />
          <feather-icon
            v-if="data.item.editData"
            icon="CheckIcon"
            class="text-success cursor-pointer font-medium-4 mr-1"
            @click="saveData(data.item)"
          />
          <feather-icon
            v-if="data.item.editData"
            icon="XIcon"
            class="text-danger cursor-pointer font-medium-4 mr-1"
            @click="cancelEdit(data.item)"
          />
          <feather-icon
            title="DELETED"
            icon="Trash2Icon"
            size="15"
            class="cursor-pointer ml-1 text-danger"
            v-b-tooltip.hover.right="'Delete Debt'"
            @click="deleteDebt(data.item)"
          />
        </template>
        <template #bottom-row v-if="countCreditor != 0">
          <th></th>
          <th>
            <b-badge
              variant="primary"
              class="w-100 text-center"
              style="font-size: 14px; font-weight: 600"
            >
              TOTAL
            </b-badge>
          </th>
          <th class="bg-info">
            <b-badge
              variant="primary"
              class="w-100 text-center"
              style="font-size: 14px; font-weight: 600"
            >
              $ {{ total.total_balance | currency }}
            </b-badge>
          </th>
          <th class="bg-info">
            <b-badge
              variant="primary"
              class="w-100 text-center"
              style="font-size: 14px; font-weight: 600"
            >
              $ {{ total.total_monthly | currency }}
            </b-badge>
          </th>
          <th colspan="1" />
          <th>
            <b-badge
              variant="primary"
              class="w-100 text-center"
              style="font-size: 14px; font-weight: 600"
            >
              {{ total.total_interest + " %" }}
            </b-badge>
          </th>
          <th v-if="state == 1" colspan="2" />
        </template>
      </b-table>
    </ValidationObserver>
    <modal-add-creditor-new-ds
      v-if="showAddCreditor"
      ref="modalCreditorNewDs"
      :idevent="id_event"
      :idEventAccountApproved="idEventAccountApproved"
      :state="stateModal"
      :client_id="client_id"
      :type_in="0"
      :true-retainer="true"
      :from-appointment="true"
      :lead_id="leadId"
      :dataCred="dataCreditor"
      :isPreVent="true"
      @reload="emitReload"
      @close="closeModalCreditor"
    />
  </b-row>
</template>

<script>
import DebtSolutionService from "@/views/debt-solution/services/debt-solution.service.js";
import ModalAddCreditorNewDs from "@/views/crm/views/Lead/lead-module/dashboard/modal/ModalAddCreditorNewDs.vue";

import ButtonExportAnalysis from "@/views/crm/views/Lead/components/ButtonExportAnalysis.vue";
import Fields from "@/views/crm/views/Lead/lead-module/data/add-creditor.fields.js";
import SalesMadeServices from "@/views/crm/views/sales-made/services/sales-made.service.js";

export default {
  async created() {    
    this.getFieldsByKey("actions").visible = this.state == 1 || this.fdlead;
    await this.allTypes();
    if (this.ncrRequestId != null) {
      await this.validateIfSellIsComplete();
    }
  },
  components: {
    ModalAddCreditorNewDs,
    ButtonExportAnalysis,
  },
  props: {
    fdlead: {
      type: Boolean,
      default: false,
      required: false,
    },
    ncrRequestId: {
      required: true,
      default: null,
    },
    idEventAccountApproved: {
      type: Number,
      default: null,
    },
    analyzedBySoft: {
      type: Boolean,
      default: false,
    },
    id_event: {
      type: Number,
      required: true,
    },
    state: {
      required: true,
      default: null,
    },
    client_id: {
      default: null,
    },
    myProvider: {
      type: Function,
    },
    countCreditor: {
      type: Number,
      default: 0,
      required: true,
    },
    total: {
      type: Object,
      required: true,
      default: {},
    },
    programPayment: {
      type: Number,
      required: false,
      default: 0,
    },
    creditorList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: Fields,
      isBusy: false,
      stateModal: 0,
      showAddCreditor: false,
      dataCreditor: {},
    };
  },
  methods: {
    openModalCreditor() {
      this.stateModal = 1;
      this.showAddCreditor = true;
      this.dataCreditor = {};
    },
    emitReload() {
      if (this.$listeners["refreshCounter"]) {
        this.$emit("refreshCounter");
      }
      this.refreshTable();
      this.closeModalCreditor();
    },
    refreshTable() {
      this.$refs["tableCreditor"].refresh();
    },
    closeModalCreditor() {
      this.showAddCreditor = false;
    },
    async deleteDebt(item) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        this.addPreloader();
        try {
          const params = {
            id: item.ds_list_credits_id,
          };
          const { data } = await DebtSolutionService.deleteDebtPreview(params);
          if (data.success) {
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successfully process"
            );
            this.refreshTable();
            if (this.$listeners["refreshCounter"]) {
              this.$emit("refreshCounter");
            }
          } else {
            "error", "top-right", "Oops!", "XIcon", "Failed process";
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    async editDebt(item) {
      this.stateModal = 2;
      this.showAddCreditor = true;
      this.dataCreditor = {
        id: item.ds_list_credits_id,
        creditor: item.id_creditor,
        tempEntryCreditorName: item.name_creditor,
        type: item.ds_type_id,
        month: item.months,
        automaticPayments: item.has_automatic_payments,
        isAuctioned: item.was_auctioned,
        personalLoanMotive: item.motives,
        judgmentOption: item.judgment_type,
        tempEntryCollectionName: item.collection_name,
        newDebtType: item.new_debt_type,
        card: item.card_number,
        account: item.account,
        balance: item.balance,
        monthly: item.monthly,
        interes: item.interest,
        unsecured: item.unsecured,
        judgmentExists: item.judgment_exists,
        hasCar: item.has_car,
        hasWarranty: item.has_warranty,
        hasCollectionKnowledge: item.has_collection_knowledge,
      };
    },
    editData(item) {
      this.creditorList.map((val) => {
        if (val.id != item.ds_list_credits_id && val.editData) {
          this.cancelEdit(val);
        }
      });
      item.editData = true;
    },
    async allTypes() {
      try {
        const response = await amgApi.get(
          "/sales-made/debt-solution/get-debts-types"
        );
        if (response.status === 200) {
          this.types = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async validateIfSellIsComplete() {
      const params = {
        leadId: this.leadId,
      };
      const { data } = await SalesMadeServices.validateIfSellIsComplete(params);
      this.state = !data.is_complete ? 0 : 1;
    },
    cancelEdit(item) {
      item.monthly = parseFloat(item.monthlyCpy);
      item.months = item.monthsCpy;
      item.interest = item.interestCpy;
      item.typt = item.typeCpy;
      item.editData = false;
    },
    async saveData(item) {
      const validation = await this.$refs.creditor.validate();
      if (!validation) return;

      // const confirm = await this.showConfirmSwal();
      // if (!confirm.isConfirmed) return;

      const params = {
        id: item.ds_list_credits_id,
        monthly: item.monthly,
        months: item.months,
        interest: item.interest,
        ds_type_id: item.ds_type_id,
      };
      try {
        this.addPreloader();
        const { data } = await SalesMadeServices.editCreditorData(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success!!",
            "CheckIcon",
            "Successfully process"
          );
          this.refreshTable();
        } else {
          this.showToast(
            "error",
            "top-right",
            "Oops!",
            "XIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    validateAddCreditor() {
      if (this.countCreditor > 0) {
        return true;
      } else {
        this.showToast(
          "warning",
          "top-right",
          "Warning!",
          "AlertTriangleIcon",
          "You must add at least one creditor"
        );
      }
    },
    getFieldsByKey(key) {
      return this.fields.find((field) => field.key === key);
    },
  },
  computed: {
    leadId() {
      let id = this.$route.params.id ?? this.lead_id;
      return id;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
};
</script>

<style lang="scss" scoped>
.b-table-bottom-row th:nth-child(2) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: 5px;
  background: #0090e7 !important;
}

.b-table-bottom-row th:nth-child(3),
.b-table-bottom-row th:nth-child(4),
.b-table-bottom-row th:nth-child(5) {
  margin-left: -10px;
  background: #0090e7 !important;
}

.b-table-bottom-row th:nth-child(6) {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: -10px;
  background: #0090e7 !important;
}
</style>
