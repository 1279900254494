export default {
  namespaced: true,
  state: {
    S_TRACKING_ID: null,
  },
  mutations: {
    SET_TRACKING_ID(state, params) {
      state.S_TRACKING_ID = params
    },
  },
  actions: {
    A_SET_TRACKING_ID({ commit }, data) {
      commit('SET_TRACKING_ID', data)
    },
  },
  getters: {
    G_TRACKING_ID(state) {
      return state.S_TRACKING_ID
    },
  },
}
