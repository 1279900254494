import Vue from "vue";
import store from '@/store';
import router from "@/router";

const captureParagonSoftNewRequestEvent = () => {
  window.socket.bind(
    "paragon-soft-new-request-notification",
    async ({ content }) => {
      const currentUser = store.state.auth.currentUser;
      if (!(currentUser.modul_id == 12 && currentUser.role_id == 17)) return;
      console.log(content);
      await Vue.prototype.$amgAlert({
        title: `New Request for Soft Projects`,
        icon: "GitPullRequestIcon",
        subtitle: `A new request has been created`,
        data: [
          {
            icon: "CodeIcon",
            title: "Code",
            description: content.code,
          },
          {
            icon: "BookmarkIcon",
            title: "Title",
            description: content.title,
          },
          {
            icon: "UserIcon",
            title: "Created by",
            description: content.created_by,
          },
        ],
        
        onlyClose: true,
        okTitle: "Go to Soft Projects",
        ok: async () => {
          const goSection = "paragon-soft";
              if (router.currentRoute.name !== goSection) {                    
                  router.push({ name: goSection });
              }
          },
      });
    }
  );
};



const createMeetingParagonSoft = () => {
  window.socket.bind(
    "paragon-soft-new-meeting-notification",
    async ({ data }) => {
      const currentUser = store.state.auth.currentUser;
      if (!data.users.includes(currentUser.user_id) || currentUser.user_id == data.created_by) return;
      await Vue.prototype.$amgAlert({
        title: `New Meeting`,
        icon: "TypeIcon",
        subtitle: `Meeting has been created`,
        data: [
          {
            icon: "KeyIcon",
            title: "Motive",
            description: data.title,
          },
          {
            icon: "CalendarIcon",
            title: "Date",
            description: data.date,
          },
          {
            icon: "ClockIcon",
            title: "Hour",
            description: data.hour,
          },
        ],

        onlyClose: true,
        okTitle: "Go to Soft Projects",
        ok: async () => {
          const goSection = currentUser.role_id == 1 ? "managment-paragon-soft" : "paragon-soft";
              if (router.currentRoute.name !== goSection) {                    
                  router.push({ name: goSection });
              }
          },
      });
    }
  );
};

export { captureParagonSoftNewRequestEvent, createMeetingParagonSoft };
