<template>
  <div class="w-100">
    <!--TABS: show "remove" and "settlements" tabs to all programs-->
    <b-tabs
      v-if="showTabs"
      pills
      lazy
      active-nav-item-class="border-primary"
      class="w-100 m-0 p-0 mb-0"
    >
      <b-tab
        :active="typeView === 'REMOVE'"
        @click="typeView = 'REMOVE'"
        :title-link-class="[bgTabsNavs, 'px-3']"
        class="m-0 p-0"
      >
        <template #title>
          FOR REMOVE
          <span v-if="totalItemsRemove > 0" class="ml-4">
            <feather-icon
              icon
              :badge="
                totalItemsRemove + (totalItemsRemove == 1 ? ' item' : ' items')
              "
              badge-classes="badge-danger"
            />
          </span>
        </template>
      </b-tab>
      <b-tab
        :active="typeView === 'SETTLEMENTS'"
        @click="typeView = 'SETTLEMENTS'"
        :title-link-class="[bgTabsNavs, 'px-3']"
        class="m-0"
      >
        <template #title>
          FOR SETTLEMENT
          <span v-if="totalItemsNegotiate > 0" class="ml-4">
            <feather-icon
              icon
              :badge="
                totalItemsNegotiate +
                (totalItemsNegotiate == 1 ? ' acc' : ' accs')
              "
              badge-classes="badge-danger"
            />
          </span>
        </template>
      </b-tab>
    </b-tabs>
    <!--DATA -->
    <b-card
      no-body
      class="w-100 m-0 pb-0 pt-2 px-2"
      :class="{
        'bg-dark': isDarkSkin,
        'custom-bg': !isDarkSkin,
        'border-primary custom-card': showTabs,
      }"
    >
      <div v-if="openBySeller" class="d-flex justify-content-between mb-1">
        <div>
          <div v-show="!approvedAccounts">
            <div v-if="typeView == 'SETTLEMENTS'">
              <b-button
                variant="success"
                @click="addCreditors()"
                :disabled="
                  !(
                    verifyAccountsSelected &&
                    (withoutAppointment || appointmentExist)
                  )
                "
                class="text-uppercase"
              >
                APPROVE
              </b-button>
            </div>
            <div v-else>
              <b-button
                variant="success"
                @click="addAccountsRemoveSelected()"
                :disabled="
                  !(
                    verifyAccountsSelected &&
                    (withoutAppointment || appointmentExist)
                  )
                "
                class="text-uppercase"
              >
                APPROVE
              </b-button>
            </div>
          </div>
        </div>
        <div>
          <div>
            <b-button
              v-if="openBySeller && typeView == 'SETTLEMENTS'"
              variant="primary"
              :disabled="!approvedAccounts"
              @click="addCreditorModalController = true"
            >
              SELECTED TO SETTLE
              <span v-if="qtySettlement > 0" class="ml-1">
                <feather-icon
                  icon
                  :badge="qtySettlement"
                  badge-classes="badge-danger"
                />
              </span>
            </b-button>
          </div>
          <div v-if="openBySeller && typeView == 'REMOVE'">
            <export-analysis-for-remove
              :approvedAccounts="!!approvedAccounts"
              :ncrRequestId="ncrRequestId"
              :leadId="leadId"
              :qtyRemove="qtyRemove"
              :idClient="useNcrFirstProgram ? null : idClient"
              :programClient="client.program_id"
            />
          </div>
        </div>
      </div>
      <div
        v-if="!openBySeller && showPdfButton && typeView == 'REMOVE'"
        class="mb-1 d-flex justify-content-end"
      >
        <export-analysis-for-remove
          :approvedAccounts="true"
          :ncrRequestId="ncrRequestId"
          :leadId="leadId"
          :qtyRemove="qtyRemove"
          :idClient="useNcrFirstProgram ? null : idClient"
        />
      </div>
      <div
        v-if="isNcrGrid && !openBySeller"
        class="mb-2 mt-50 mx-50 d-flex justify-content-between"
      >
        <div class="rounded d-block d-md-flex justify-content-center">
          <div
            class="p-50 mr-1 text-uppercase"
            style="font-size: 14px"
            :class="
              analysisEvaluationForTab != 'APPROVED' && editMode
                ? 'border-right-primary'
                : null
            "
          >
            <span>Analysis result :</span>
            <strong
              class="mr-50"
              :class="
                analysisEvaluationForTab == 'APPROVED'
                  ? 'text-success'
                  : 'text-warning'
              "
            >
              <feather-icon
                icon="CheckCircleIcon"
                class="mx-50"
                size="20"
                style="margin-top: -5px"
                v-if="analysisEvaluationForTab == 'APPROVED'"
              />
              {{ analysisEvaluationForTab }}
            </strong>
          </div>
          <b-button-group v-if="editMode">
            <b-button
              v-if="['OBSERVED'].includes(analysisEvaluationForTab)"
              variant="primary"
              @click="accountsModal()"
              size="sm"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              Add account
            </b-button>
            <b-button
              v-if="['PENDING', 'APPROVED'].includes(analysisEvaluationForTab)"
              variant="warning"
              @click="changeStatusResult('OBSERVED')"
              size="sm"
            >
              <feather-icon icon="AlertCircleIcon" class="mr-50" />
              Observe
            </b-button>
            <b-button
              v-if="['PENDING', 'OBSERVED'].includes(analysisEvaluationForTab)"
              variant="success"
              @click="changeStatusResult('APPROVED')"
              size="sm"
            >
              Approve
              <feather-icon icon="CheckCircleIcon" class="ml-50" />
            </b-button>
          </b-button-group>
        </div>
        <div>
          <span>Show CR:</span>
          <amg-icon
            icon="FilePdfIcon"
            class="text-danger ml-1"
            size="20"
            style="cursor: pointer"
            @click="showPdfNcrRequest"
          />
        </div>
      </div>
      <b-table
        :items="typeView == 'REMOVE' ? removeData : settlementData"
        :fields="visibleFields"
        :sticky-header="'50vh'"
        :foot-clone="false"
        show-empty
        class="custom-table"
        :class="{ 'row-light': !isDarkSkin }"
        :head-row-variant="'grid-custom-tickets-header'"
        :foot-row-variant="'grid-custom-tickets-header'"
      >
        <template #empty>
          <div class="text-center text-primary my-2">
            <strong>{{ emptyTableMsg }}</strong>
          </div>
        </template>
        <template #cell(id)="{ index, item }">
          <div
            class="d-flex justify-content-center align-items-center font-size-md custom-height"
            style="min-width: 0px"
          >
            {{ index + 1 }}
          </div>
          <ul class="list-unstyled">
            <li
              v-for="(label, index) in bureauDetails(item)"
              :key="index"
              class="padding w-100 d-flex justify-content-center text-center text-capitalize"
            >
              <div
                style="width: 50px !important"
                class="d-flex justify-content-center align-items-center"
              >
                <span class="text-truncate w-100" style="opacity: 0">{{
                  label.text
                }}</span>
              </div>
            </li>
          </ul>
        </template>
        <template #head(transunion)>
          <b-img
            :src="require('@/assets/images/icons/transunion.png')"
            class="custom-img"
            v-b-tooltip.hover.top="'TRANSUNION'"
          />
        </template>
        <template #head(experian)>
          <b-img
            :src="require('@/assets/images/icons/experian.png')"
            class="custom-img"
            v-b-tooltip.hover.top="'EXPERIAN'"
          />
        </template>
        <template #head(equifax)>
          <b-img
            :src="require('@/assets/images/icons/equifax.png')"
            class="custom-img"
            v-b-tooltip.hover.top="'EQUIFAX'"
          />
        </template>

        <template #cell(accounts)="{ item }">
          <!--SETTLEMENTS-->
          <ul class="list-unstyled w-100" v-if="typeView != 'REMOVE'">
            <li class="w-100 font-weight-bolder h5">
              <div class="d-flex justify-content-between">
                <div
                  class="d-flex justify-content-between align-items-center w-100"
                >
                  <div class="mr-1 w-100 text-center">
                    <div class="d-flex w-100 text-center">
                      <span
                        class="text-center d-flex justify-content-center align-items-center w-100 font-size-xmd font-weight-bolder"
                      >
                        {{ item.creditor_name }}
                      </span>
                    </div>
                    <span
                      style="font-size: 13px"
                      class="my-1 text-capitalize font-size-xmd"
                      :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                      >Account #: {{ item.account_number }}</span
                    >
                    <!--IMPORTANT LABELS-->
                    <div
                      v-if="isDashboardLead"
                      class="d-flex justify-content-center align-items-center w-100 mt-50"
                    >
                      <b-badge
                        class="mx-50 rounded-pill"
                        variant="light-warning"
                        v-if="item.remonego && !item.selected_remove"
                        >Removable</b-badge
                      >
                      <b-badge
                        class="mx-50 rounded-pill"
                        variant="light-primary"
                        v-if="item.added_after_analysis == 1 && editMode"
                        >Add</b-badge
                      >
                      <b-badge
                        class="mx-50 rounded-pill"
                        variant="light-warning"
                        v-if="
                          item.selected_remove && item.selected_negotiate != 1
                        "
                        >Removed</b-badge
                      >
                      <b-badge
                        class="mx-50 rounded-pill"
                        variant="light-info"
                        v-else-if="item.selected_negotiate"
                        >Selected</b-badge
                      >
                    </div>
                  </div>
                  <b-form-checkbox
                    v-if="
                      openBySeller &&
                      !approvedAccounts &&
                      (withoutAppointment || appointmentExist) &&
                      !item.selected_remove
                    "
                    v-model="item.selected"
                    :state="true"
                    v-b-tooltip.hover.right="
                      item.priority == 'NEEDS CONFIRMATION'
                        ? 'Needs confirmation'
                        : 'Select account'
                    "
                    @change="verifySelecteds()"
                  />
                </div>
                <div>
                  <b-badge
                    v-if="editMode"
                    variant="light-danger"
                    class="hover-me cursor-pointer ml-1"
                    v-b-tooltip.hover.v-danger
                    v-b-tooltip.hover.right="'REMOVE ACCOUNT'"
                  >
                    <feather-icon
                      v-if="analysisEvaluationForTab == 'OBSERVED'"
                      icon="TrashIcon"
                      style="height: 15px; width: 15px"
                      @click="deleteAccount(item)"
                    />
                  </b-badge>
                </div>
              </div>
            </li>
          </ul>
          <!--REMOVE -->
          <ul
            v-else
            class="list-unstyled w-100 m-0"
            style="min-width: 200px !important"
          >
            <li
              class="w-100 d-flex align-items-center justify-content-between custom-height"
              style="font-weight: bolder"
            >
              <div class="w-100 position-relative">
                <div
                  v-for="(label, index) in generalInfo(item)"
                  :key="index"
                  class="w-100"
                >
                  <div
                    class="w-100 d-flex flex-column justify-content-start text-capitalize text-center"
                    v-if="label.key === 'see_more'"
                  >
                    <div
                      class="d-flex flex-column text-center justify-content-center w-100 font-size-xmd font-weight-bolder"
                    >
                      {{ getRowCreditorName(item) }}
                    </div>
                    <span
                      class="w-100 mt-50 text-capitalize px-1"
                      :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                      style="font-size: 12px"
                    >
                      Account #: {{ getRowCreditorAccount(item) }}
                    </span>
                  </div>
                </div>
                <!--IMPORTANT LABELS -->
                <div
                  v-if="isDashboardLead"
                  class="d-flex justify-content-center align-items-center w-100 mt-50"
                >
                  <b-badge
                    class="mx-50 rounded-pill"
                    variant="light-warning"
                    v-if="item.remonego"
                    >Negotiable</b-badge
                  >
                  <b-badge
                    class="mx-50 rounded-pill"
                    variant="light-primary"
                    v-if="item.added_after_analysis == 1 && editMode"
                    >Add</b-badge
                  >
                </div>
              </div>
              <div style="margin-left: auto">
                <b-badge
                  v-if="editMode"
                  variant="light-danger"
                  class="hover-me cursor-pointer ml-1"
                  v-b-tooltip.hover.v-danger
                  v-b-tooltip.hover.right="'REMOVE ACCOUNT'"
                >
                  <feather-icon
                    v-if="
                      analysisEvaluationForTab == 'OBSERVED' && !isOnlyLow(item)
                    "
                    icon="TrashIcon"
                    style="height: 15px; width: 15px"
                    @click="deleteAccount(item)"
                  />
                </b-badge>
              </div>
            </li>
            <template v-for="(label, index) in bureauDetails(item)">
              <li
                :key="index"
                class="padding w-100 d-flex justify-content-center text-center text-capitalize"
              >
                <div
                  style="width: 130px !important"
                  class="d-flex justify-content-center align-items-center"
                >
                  <span class="text-truncate d-flex w-100">{{
                    label.text
                  }}</span>
                </div>
              </li>
            </template>
          </ul>
        </template>
        <template #row-details="{ item }" v-if="typeView == 'SETTLEMENTS'">
          <b-row class="pt-2 pb-1 px-2 border-top">
            <b-col cols="4" class="px-2 border-right-primary">
              <ul class="list-unstyled px-2">
                <li
                  class="w-100 d-block d-xl-flex my-50 justify-content-between my-xl-0 align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon icon="CircleIcon" class="mr-50" size="5" />
                    Account # :
                  </span>
                  <span class="font-italic font-weight-bolder">{{
                    item.account_number
                  }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 justify-content-between my-xl-0 align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon icon="CircleIcon" class="mr-50" size="5" />
                    Type :
                  </span>
                  <span class="font-italic">{{ item.account_type }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 justify-content-between my-xl-0 align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                    ><feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Type detail :</span
                  >
                  <span class="font-italic">{{
                    item.account_type_detail || "-"
                  }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon icon="CircleIcon" class="mr-50" size="5" />
                    Bureau code:
                  </span>
                  <span class="font-italic">{{ item.bureau_code }}</span>
                </li>
              </ul>
            </b-col>
            <b-col cols="4" class="px-2 border-right-primary">
              <ul class="list-unstyled px-2">
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Account Status : </span
                  ><span class="font-italic">{{ item.account_status }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Monthly payment : </span
                  ><span class="font-italic"
                    >$ {{ item.monthly_payment | currency }}</span
                  >
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Date opened : </span
                  ><span class="font-italic">{{
                    item.date_opened | myGlobal
                  }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Comments :
                  </span>
                  <span class="font-italic">{{
                    item.comments.length > 35
                      ? item.comments.substring(0, 35) + "..."
                      : item.comments || "-"
                  }}</span>
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Last Payment Status :
                  </span>
                  <span class="font-italic">{{
                    item.last_payment | myGlobal
                  }}</span>
                </li>
              </ul>
            </b-col>
            <b-col cols="4" class="px-2">
              <ul class="list-unstyled px-2">
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon icon="CircleIcon" class="mr-50" size="5" />No.
                    of Months (terms) :
                  </span>
                  <span class="font-italic">{{ item.n_months || "-" }}</span>
                </li>
                <li
                  v-if="typeView == 'REMOVE'"
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Balance :
                  </span>
                  <span class="font-italic">
                    $ {{ item.balance | currency }}</span
                  >
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Credit Limit :
                  </span>
                  <span class="font-italic"
                    >${{ item.credit_limit | currency }}</span
                  >
                </li>
                <li
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Payment Status :
                  </span>
                  <span class="font-italic">{{
                    item.payment_status || "-"
                  }}</span>
                </li>
                <li
                  v-if="typeView == 'REMOVE'"
                  class="w-100 d-block d-xl-flex my-50 my-xl-0 justify-content-between align-items-center text-capitalize"
                >
                  <span
                    class="d-flex align-items-center ml-0 ml-xl-1 font-weight-bolder"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      class="mr-50"
                      size="5"
                    />Highest Credit :
                  </span>
                  <span class="font-italic">$ {{ item.h_credit || "-" }}</span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </template>
        <template #cell(state)="{ item }">
          <div class="custom-height">
            <div
              class="d-flex w-100 h-100 justify-content-center align-items-center text-center"
            >
              <div class="d-flex justify-content-center flex-column w-100">
                <div class="w-100" v-if="item.remonego">
                  <b-badge
                    variant="light-warning"
                    class="rounded-pill font-size-sm px-50 text-uppercase"
                  >
                    {{ typeView == "REMOVE" ? "NEGOTIABLE" : "REMOVABLE" }}
                  </b-badge>
                </div>
                <div
                  class="w-100 mt-50"
                  v-if="item.added_after_analysis == 1 && editMode"
                >
                  <b-badge
                    variant="light-primary"
                    class="rounded-pill font-size-sm text-uppercase"
                    >Added</b-badge
                  >
                </div>
                <template v-if="typeView == 'SETTLEMENTS'">
                  <div
                    v-if="item.selected_remove && item.selected_negotiate != 1"
                    class="d-flex justify-content-center align-items-center w-100"
                  >
                    <b-badge
                      variant="light-warning"
                      class="py-50 px-1 rounded-pill my-50"
                    >
                      REMOVED
                    </b-badge>
                  </div>
                  <div
                    v-else-if="item.selected_negotiate"
                    class="d-flex justify-content-center align-items-center w-100"
                  >
                    <b-badge
                      variant="light-info"
                      class="py-50 px-1 rounded-pill my-50"
                    >
                      SELECTED
                    </b-badge>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <ul class="list-unstyled">
            <li
              v-for="(label, index) in bureauDetails(item)"
              :key="index"
              class="padding w-100 d-flex justify-content-center text-center text-capitalize"
            >
              <div style="width: 50px !important">
                <span class="text-truncate w-100" style="opacity: 0">{{
                  label.text
                }}</span>
              </div>
            </li>
          </ul>
        </template>
        <template #cell(classification_ncr)="{ item }">
          <div
            :class="typeView == 'REMOVE' ? 'custom-height' : null"
            class="d-flex justify-content-center align-items-center h-100"
          >
            <h6
              class="text-uppercase font-weight-bolder text-center"
              :style="`color: ${textColor[item.classification_ncr_id]}`"
            >
              {{ item.classification_ncr }}
            </h6>
          </div>
          <ul class="list-unstyled">
            <li
              v-for="(label, index) in bureauDetails(item)"
              :key="index"
              class="padding w-100 d-flex justify-content-center text-center text-capitalize"
            >
              <div style="width: 50px !important">
                <span class="text-truncate w-100" style="opacity: 0">{{
                  label.text
                }}</span>
              </div>
            </li>
          </ul>
        </template>

        <template #cell(priority)="{ item }">
          <div
            class="w-100 d-flex justify-content-center align-items-center text-center"
          >
            <div
              v-if="
                item.edit_data == 0 || (!editMode && currentUser.modul_id != 2)
              "
              class="text-white d-flex justify-content-center align-items-center px-1 py-50 rounded-pill text-uppercase font-weight-bolder font-size-xmd"
              :style="`border: 1.2px solid ${
                priorityColor[item.priority]
              } !important;color: ${priorityColor[item.priority]} !important;`"
            >
              {{ item.priority }}
              <feather-icon
                v-if="
                  item.edit_data == 0 &&
                  (analysisEvaluationForTab == 'OBSERVED' ||
                    (analysisEvaluationForTab == 'APPROVED' &&
                      moduleId === 2 &&
                      !approvedAccounts &&
                      appointmentExist)) &&
                  (editMode ||
                    (openBySeller && item.priority == 'NEEDS CONFIRMATION'))
                "
                icon="ChevronDownIcon"
                size="20"
                class="cursor-pointer ml-1"
                @click="item.edit_data = 1"
              />
            </div>
          </div>
          <div
            v-if="
              item.edit_data == 1 &&
              (editMode ||
                (openBySeller && item.priority == 'NEEDS CONFIRMATION'))
            "
          >
            <b-form-group>
              <v-select
                class="text-uppercase font-size-xmd"
                v-model="item.priority"
                :clearable="true"
                label="label"
                :options="
                  typeView == 'REMOVE' ? removeOptions : settlementOptions
                "
                :reduce="(option) => option.value"
                @input="changeClassificationSettlement(item)"
                style="
                  width: 230px;
                  height: 50px;
                  transform: scale(0.9);
                  z-index: 999 !important;
                "
              />
            </b-form-group>
          </div>
        </template>
        <template #cell(transunion)="{ item }">
          <DataBureau
            v-if="idClient == null || !!idClient || toViewRates"
            :analysisEvaluationForTab="analysisEvaluationForTab"
            :editMode="editMode"
            :item="item.items[0]"
            :labelsRemove="filteredLabels(item)"
            :others="{
              priorityColor,
              currentUser,
              openBySeller,
              moduleId,
              approvedAccounts,
              appointmentExist,
              typeView,
              removeOptions,
            }"
            :typeView="typeView"
            @changeClassification="changeClassification"
            @changeBoureauResult="changeBoureauResult"
            @update:other="updateOthers(item)"
            @updateRemoveCounter="updateRemoveCounter()"
          />
          <div
            v-else
            class="w-100 d-flex justify-content-center align-items-center"
          >
            <img
              :src="require('@/assets/images/icons/transunion.png')"
              width="30px"
              height="30px"
              class="custom-bg-logo"
            />
          </div>
        </template>

        <template #cell(experian)="{ item }">
          <DataBureau
            v-if="idClient == null || !!idClient || toViewRates"
            :analysisEvaluationForTab="analysisEvaluationForTab"
            :editMode="editMode"
            :item="item.items[1]"
            :labelsRemove="filteredLabels(item)"
            :others="{
              priorityColor,
              currentUser,
              openBySeller,
              moduleId,
              approvedAccounts,
              appointmentExist,
              typeView,
              removeOptions,
            }"
            :typeView="typeView"
            @changeClassification="changeClassification"
            @changeBoureauResult="changeBoureauResult"
            @update:other="updateOthers(item)"
            @updateRemoveCounter="updateRemoveCounter()"
          />
          <div
            v-else
            class="w-100 d-flex justify-content-center align-items-center"
          >
            <img
              :src="require('@/assets/images/icons/experian.png')"
              width="30px"
              height="30px"
              class="custom-bg-logo"
            />
          </div>
        </template>

        <template #cell(equifax)="{ item }">
          <DataBureau
            v-if="idClient == null || !!idClient || toViewRates"
            :analysisEvaluationForTab="analysisEvaluationForTab"
            :editMode="editMode"
            :item="item.items[2]"
            :labelsRemove="filteredLabels(item)"
            :others="{
              priorityColor,
              currentUser,
              openBySeller,
              moduleId,
              approvedAccounts,
              appointmentExist,
              typeView,
              removeOptions,
            }"
            :typeView="typeView"
            @changeClassification="changeClassification"
            @changeBoureauResult="changeBoureauResult"
            @update:other="updateOthers(item)"
            @updateRemoveCounter="updateRemoveCounter()"
          />
          <div
            v-else
            class="w-100 d-flex justify-content-center align-items-center"
          >
            <img
              :src="require('@/assets/images/icons/equifax.png')"
              width="30px"
              height="30px"
              class="custom-bg-logo"
            />
          </div>
        </template>

        <template #cell(balance)="{ item, index }">
          <div
            class="d-flex justify-content-center align-items-center"
            :id="`balance_${index}`"
          >
            <template v-if="!!duplicyBalance[item.balance]">
              <span
                class="custom-squad rounded-sm mr-50"
                :style="`background-color: ${
                  duplicyBalance[item.balance].color
                } !important;`"
              />
              <b-tooltip :target="`balance_${index}`" placement="top">
                {{ duplicyBalance[item.balance].cant }} coincidences
              </b-tooltip>
            </template>
            $ {{ item.balance | currency }}
          </div>
        </template>

        <template #cell(high_credit)="{ item }">
          $ {{ showHighCredit(item) | currency }}
        </template>

        <template #cell(average)="{ item }">
          <div class="d-flex align-items-center justify-content-center">
            <span
              :class="
                !item.haveAverage ? 'font-weight-bolder text-warning' : null
              "
              >{{ showAverage(item) | currency }} %</span
            >
            <feather-icon
              v-if="!item.haveAverage"
              icon="AlertCircleIcon"
              class="text-warning ml-50"
              v-b-tooltip.hover.v-warning="'AVERAGE BY DEFAULT'"
            />
          </div>
        </template>

        <template #cell(detail)="{ toggleDetails, detailsShowing, item }">
          <div
            style="width: 40px !important"
            class="d-flex justify-content-center align-items-center custom-height"
          >
            <feather-icon
              v-if="typeView == 'SETTLEMENTS'"
              icon="ChevronDownIcon"
              class="hover-me cursor-pointer"
              size="22"
              :class="{ 'rotate-180': detailsShowing }"
              @click="toggleDetails"
            />
            <feather-icon
              v-else
              icon="ChevronDownIcon"
              class="hover-me cursor-pointer"
              size="22"
              :class="{ 'rotate-180': item.showDetails }"
              @click="item.showDetails = !item.showDetails"
            />
          </div>
          <ul class="list-unstyled overflow-hidden">
            <li
              v-for="(label, index) in bureauDetails(item)"
              :key="index"
              class="padding w-100 d-flex justify-content-center text-center text-capitalize"
            >
              <div style="width: 50px !important">
                <span class="text-truncate w-100 d-none">{{ label.text }}</span>
              </div>
            </li>
          </ul>
        </template>
        <template #custom-foot v-if="typeView == 'SETTLEMENTS'">
          <b-tr class="fixed-footer">
            <b-th :colspan="isNcrGrid ? 4 : 3"></b-th>
            <b-th class="text-center custom-font-size text-white">Total</b-th>
            <b-th
              class="text-center font-weight-bolder text-success custom-font-size px-0"
            >
              <span
                class="d-flex justify-content-center align-items-center font-weight-bolder text-white"
              >
                $ {{ totalBalance | currency }}
              </span>
            </b-th>
            <b-th
              class="text-center font-weight-bolder text-success custom-font-size px-0"
            >
              <span
                class="d-flex justify-content-center align-items-center font-weight-bolder text-white"
              >
                $ {{ totalHighCredit | currency }}
              </span>
            </b-th>
            <b-th
              class="text-center font-weight-bolder text-success custom-font-size px-0"
            >
              <span
                class="d-flex justify-content-center align-items-center font-weight-bolder text-white"
              >
                {{ promAverage | currency }} %
              </span>
            </b-th>
            <b-th />
          </b-tr>
        </template>
      </b-table>
    </b-card>
    <modal-accounts
      v-if="showAccountsModal"
      :accountResultId="accountResultData.id"
      :resultType="typeView"
      :scoreId="scoreId"
      :filterParams="filterParams"
      @refreshData="myProvider"
      @closeModal="showAccountsModal = false"
    />

    <add-creditor-ds-modal
      ref="addCreditorDsModal"
      v-if="addCreditorModalController"
      :state-modal="0"
      :fromDashboardLead="true"
      :title="'Creditors list'"
      :id_event="eventId"
      :ncrRequestId="ncrRequestId"
      :hideFooter="true"
      :openFromAnalysisResults="true"
      @close="addCreditorModalController = false"
      @hideOnly="addCreditorModalController = false"
      @refreshCounter="qtySettlementSelected()"
    />
    <!--custom preloader-->
    <custom-preloader
      v-if="showCustomPreloader"
      :message="customMessagePreloader"
    />
  </div>
</template>
<script>
import fields from "@/views/commons/components/request-ncr/data/result-analysis-ncr.fields";
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service";
import ModalAccounts from "@/views/commons/components/request-ncr/views/modals/ModalAccounts.vue";
import AddCreditorDsModal from "@/views/crm/views/Lead/lead-module/dashboard/modal/AddCreditorDsModal.vue";
import LabelsRemove from "@/views/commons/components/request-ncr/data/result-analysis-cr-labels-remove";
import { mapGetters, mapActions } from "vuex";
import CustomPreloader from "@/views/commons/components/request-ncr/views/others/CustomPreloader.vue";
import ExportAnalysisForRemove from "@/views/commons/components/request-ncr/views/components/ExportAnalysisForRemove.vue";

import DataBureau from "./DataBureau.vue";

export default {
  props: {
    leadId: {
      type: Number,
      require: true,
      default: 0,
    },
    ncrRequestId: {
      type: Number,
    },
    scoreId: {
      type: Number,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    isAnalyzed: {
      default: 0,
    },
    idClient: {
      default: null,
      require: false,
      type: String,
    },
    showTabs: {
      type: Boolean,
      default: true,
      require: false,
    },
    openBySeller: {
      type: Boolean,
      default: false,
    },
    typeView_: {
      type: String,
      default: "REMOVE",
    },
    withoutAppointment: {
      type: Boolean,
      default: false,
    },
    moduleIdNcr: {
      type: Number,
      default: 0,
      require: false,
    },
    toViewRates: {
      type: Boolean,
      default: false,
      require: false,
    },
    inDahsboard: {
      type: Boolean,
      default: true,
      require: false,
    },
    showPdfButton: {
      type: Boolean,
      default: false,
    },
    auxIdClient: {
      type: String,
      default: null,
    },
    useNcrFirstProgram: {
      // use ncr id of first program and select the accounts/items in sales made modal (credit experts)
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      default: null,
    },
  },
  components: {
    ModalAccounts,
    AddCreditorDsModal,
    CustomPreloader,
    DataBureau,
    ExportAnalysisForRemove,
  },
  data() {
    return {
      emptyTableMsg:
        "No items were found to be removed because all accounts were classified as positive",
      labelsRemove: LabelsRemove,
      typeView: null,
      showDetails: {},
      fields: fields,
      items: [],
      textColor: {
        1: "#3498DB",
        2: "#1ABC9C",
        3: "#8E44AD",
        4: "#E74C3C",
        5: "#F1C40F",
        6: "#F39C12",
        7: "#95A5A6",
        8: "#34495E",
        9: "#BA4A00",
        10: "#7F8C8D",
      },
      priorityColor: {
        high: "#00F380",
        medium: "#FFAF00",
        low: "#FE0000",
        SETTLE: "#00F380",
        "NEEDS CONFIRMATION": "#FFAF00",
        "NOT SETTLE": "#FE0000",
      },
      itemsByFilter: [],
      editBureaus: false,
      vMoney: {
        decimal: ".",
        thousands: ",",
        precision: 0,
        masked: false,
        prefix: "$ ",
        min: 0.0,
      },
      accountResultData: {},
      showEditNcrAccountModal: false,
      accountSelected: [],
      removeOptions: [
        {
          label: "MEDIUM",
          value: "MEDIUM",
        },
        {
          label: "HIGH",
          value: "HIGH",
        },
      ],
      settlementOptions: [
        {
          label: "SETTLE",
          value: "SETTLE",
        },
        {
          label: "NEEDS CONFIRMATION",
          value: "NEEDS CONFIRMATION",
        },
        {
          label: "NOT SETTLE",
          value: "NOT SETTLE",
        },
      ],
      boureauName: null,
      removeData: [],
      settlementData: [],
      showAccountsModal: false,
      approvedAccounts: false,
      filterParams: {
        typeAccount: "REMOVE",
        idAccounts: [],
      },
      appointmentExist: true,
      addCreditorModalController: false,
      eventId: null,
      typeView: null,
      showCustomPreloader: false,
      customMessagePreloader:
        "The analysis is being generated. This may take a few minutes...",
      isAnalizedCurrent: null,
      showLess: true,
      qtySettlement: 0,
      duplicyBalance: {},
      qtyRemove: 0,
      maxLetter: 30,
    };
  },
  watch: {
    typeView: function (value) {
      this.emptyTableMsg =
        value == "REMOVE"
          ? "No items were found to be removed because all accounts were classified as positive"
          : "No negotiable accounts were found";
      if (this.openBySeller) {
        this.approvedAccountsByClient();
      }
    },
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isNcrGrid() {
      return (
        this.$route.name == "sp-ncr-lead-pending" ||
        this.$route.name == "sp-ncr-lead-finished" ||
        this.$route.name == "sp-ncr-lead-annulled" ||
        this.$route.name == "sp-ncr-client-pending"
      );
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    visibleFields() {
      this.modifyVisibility(
        "state",
        !(this.isDashboardLead || this.isSalesMade || this.isDashboardClient)
      );
      this.modifyVisibility("transunion", this.typeView == "REMOVE");
      this.modifyVisibility("experian", this.typeView == "REMOVE");
      this.modifyVisibility("equifax", this.typeView == "REMOVE");
      this.modifyVisibility("balance", this.typeView == "SETTLEMENTS");
      this.modifyVisibility("high_credit", this.typeView == "SETTLEMENTS");
      this.modifyVisibility("average", this.typeView == "SETTLEMENTS");
      this.modifyVisibility("priority", this.typeView == "SETTLEMENTS");
      this.fields.forEach((x) => {
        if (x.key === "accounts") {
          if (this.typeView != "REMOVE") {
            x.tdClass = "pt-2 col-4";
          } else {
            x.tdClass = "p-0 col-2";
          }
        }
      });
      return this.fields.filter((item) => item.visible);
    },
    totalBalance() {
      return this.settlementData.reduce((sum, item) => {
        if (item.action === "negotiate") {
          sum += parseFloat(item.balance);
        }
        return sum;
      }, 0);
    },
    totalHighCredit() {
      return this.settlementData.reduce((sum, item) => {
        if (item.action.toLowerCase() === "negotiate") {
          sum += parseFloat(item.high_credit);
        }
        return sum;
      }, 0);
    },
    promAverage() {
      const sum = this.settlementData.reduce((sum, item) => {
        if (item.action.toLowerCase() === "negotiate") {
          if (item.average == null) item.average = 60;
          sum += parseFloat(item.average);
        }
        return sum;
      }, 0);
      const total = this.settlementData.length;
      return total > 0 ? sum / total : null;
    },
    totalItemsRemove() {
      let totalRemove = 0;
      let arrayType = ["HIGH", "MEDIUM"];
      this.removeData.forEach((e) => {
        e.items = e.items.map((item) => {
          let is_checked = false;
          if (arrayType.includes(item.priority)) {
            if (item.bureau_id == 1 && item.transunion_result == 1) {
              is_checked = true;
            }
            if (item.bureau_id == 2 && item.experian_result === 1) {
              is_checked = true;
            }
            if (item.bureau_id == 3 && item.equifax_result === 1) {
              is_checked = true;
            }
          }
          item.is_checked = is_checked;
          return item;
        });
        let total = e.items.filter((x) => x.is_checked).length;
        totalRemove += total;
      });
      return totalRemove;
    },
    totalItemsNegotiate() {
      return this.settlementData.filter(
        (item) => item.action.toLowerCase() === "negotiate"
      ).length;
    },
    analysisEvaluationForTab() {
      return this.typeView == "REMOVE"
        ? this.accountResultData.status_result_remove
        : this.accountResultData.status_result_negotiate;
    },
    verifyAccountsSelected() {
      let accounts;
      if (this.typeView == "SETTLEMENTS") {
        accounts = this.settlementData.filter(
          (item) => item.selected && !item.selected_remove
        );
        if (!this.approvedAccounts) {
          this.qtySettlement = accounts.length;
        }
      } else {
        accounts = this.removeData.flatMap((entry) =>
          entry.items.filter(
            (item) => item.is_remove && !item.selected_negotiate
          )
        );
      }
      return accounts.length > 0;
    },
    labelsRemoveVisible() {
      this.modifyVisibilityLabelRemove("select", this.openBySeller);
      return this.labelsRemove.filter((x) => x.visible);
    },
    isDashboardLead() {
      return !!this.$route.params.id;
    },
    isDashboardClient() {
      return !!this.$route.params.idClient;
    },
    isSalesMade() {
      const keywords = ["sales-made", "salesmade"];
      return keywords.some((keyword) => this.$route.path.includes(keyword));
    },
  },
  async mounted() {
    this.isAnalizedCurrent = this.isAnalyzed;
    this.typeView = this.typeView_; // de esta manera se setea
    if (!this.idClient && !this.toViewRates) {
      await this.getResultAnalysisAccount();
    } else {
      await this.myProvider();
      this.updateRemoveCounter();
    }
    if (this.openBySeller) {
      await this.approvedAccountsByClient();
      this.updateRemoveCounter();
    }
    this.settlementOptions = this.openBySeller
      ? this.settlementOptions.filter(
          (item) => item.label != "NEEDS CONFIRMATION"
        )
      : this.settlementOptions.filter((item) => item.label != "NOT SETTLE");
  },
  methods: {
    isOnlyLow(item) {
      return item.items.every(
        (bureau) => bureau.priority == "LOW" || bureau.priority == "-"
      );
    },
    updateRemoveCounter() {
      let aux = this.removeAccountDetailIdSelecteds();
      this.qtyRemove = aux.length;
    },
    generalInfo(item) {
      return this.filteredLabels(item).filter((label) => !label.info);
    },
    bureauDetails(item) {
      return this.filteredLabels(item).filter((label) => label.info);
    },
    filteredLabels(item) {
      return !item.showDetails
        ? this.labelsRemoveVisible.slice(
            0,
            this.checkKeyExists(this.labelsRemoveVisible, "select") ? 3 : 2
          )
        : this.labelsRemoveVisible;
    },
    ...mapActions({
      A_APPOINTMENT_EXIST: "RequestNcrStore/APPOINTMENT_EXIST",
    }),
    async qtySettlementSelected() {
      if (this.approvedAccounts) {
        const data = await NcrRequestService.getSettlementsQty({
          event: this.eventId,
        });
        this.qtySettlement = data[0].qty;
      } else {
        let accounts = this.settlementData.filter((item) => item.selected);
        this.qtySettlement = accounts.length;
      }
    },
    showingDetails(item) {
      this.$set(item, "showDetails", !item.showDetails);
    },
    getRowCreditorName({ items }) {
      const creditorObj = items.find((item) => item.creditor_name !== "-");
      return creditorObj ? creditorObj.creditor_name : null;
    },
    getRowCreditorAccount({ items }) {
      const creditorObj = items.find((item) => item.creditor_name !== "-");
      return creditorObj ? creditorObj.account_number : null;
    },
    async getResultAnalysisAccount() {
      try {
        if (this.isNcrGrid && !this.isAnalizedCurrent) {
          this.addCustomPreloader();
        } else {
          this.addPreloader();
        }
        const params = {
          ncrRequestId: this.ncrRequestId,
          scoreId: this.scoreId,
          userId: this.currentUser.user_id,
        };
        const { data } = await NcrRequestService.getResultAnalysisAccount(
          params
        );
        this.accountResultData = data[0];
        if (
          this.accountResultData.status_result_remove == "APPROVED" &&
          this.accountResultData.status_result_negotiate == "APPROVED"
        ) {
          this.$emit("disabledSaveButton", false);
        } else {
          this.$emit("disabledSaveButton", true);
        }
        if (!this.isAnalizedCurrent) this.$emit("refreshTable");
        if (this.isNcrGrid && !this.isAnalizedCurrent) {
          this.removeCustomPreloader();
        } else {
          this.removePreloader();
        }
        this.isAnalizedCurrent = 1;
        await this.myProvider();
      } catch (ex) {
        this.removePreloader();
        this.removeCustomPreloader();
      }
    },
    async myProvider() {
      try {
        this.addPreloader();
        const params = {
          ncrRequestId: this.ncrRequestId,
          idClient: this.idClient,
          typeView: this.typeView,
          leadId: this.leadId,
          toViewRates: this.toViewRates,
          inDahsboard: this.inDahsboard,
          useNcrFirstProgram: this.useNcrFirstProgram,
        };
        const { data } = await NcrRequestService.showAnalysisCR(params);
        const { result_analysis_account } = data;
        this.removeData = data.remove_data.map((item) => {
          item.items.map(
            (item) => (item.is_remove = item.is_remove == 1 ?? true)
          );
          return {
            ...item,
            showDetails: false,
          };
        });

        if (!this.isNcrGrid) {
          let resultado = this.removeData.filter((data) => {
            return data.items.some(
              (item) =>
                item.type_classification == "MEDIUM" ||
                item.type_classification == "HIGH"
            );
          });

          this.removeData = resultado;
        }

        this.settlementData = data.settlement_data.map((item) => {
          return {
            ...item,
            showDetails: false,
            haveAverage: !!item.average,
          };
        });

        if (this.settlementData.length > 0) {
          this.analyzeBalance(this.settlementData);
        }

        let created = {
          name: result_analysis_account.created_by_name,
          date: result_analysis_account.created_at,
        };
        // accounts get analysis and/or added accounts
        const idAccount = [...this.settlementData, ...this.removeData].map(
          (item) => item.cr_account_ac_id
        );
        this.filterParams.idAccounts = idAccount;

        this.$emit("responseData", created);
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log("Error in myProvider: ", error);
      }
    },
    analyzeBalance(array) {
      const colors = [
        "#EF5350",
        "#45B39D",
        "#F4D03F",
        "#F39C12",
        "#D35400",
        "#EC407A",
        "#AB47BC",
        "#9575CD",
        "#5C6BC0",
        "#42A5F5",
      ];

      const balances = array.map((item) => item.balance);
      const duplicyBalance = balances
        .filter((value, index, array) => {
          return index !== array.findIndex((el) => el === value);
        })
        .reduce((result, item, index) => {
          result[item] = {
            color: colors[index] ? colors[index] : null,
            cant: balances.filter((el) => el === item).length,
          };
          return result;
        }, {});

      this.duplicyBalance = { ...duplicyBalance };
    },
    async changeStatusResult(status) {
      // const confirm = await this.showConfirmSwal();
      // if (!confirm.isConfirmed) return;

      const params = {
        accountResultId: this.accountResultData.id,
        status: status,
        tab: this.typeView,
      };
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.changeStatusResult(params);
        if (data.success) {
          this.showSuccessToast();
        } else {
          this.showErrorToast();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
      await this.getResultAnalysisAccount();
      this.removePreloader();
    },
    changeClassificationSettlement(item) {
      if (item.priority) {
        this.changeClassification(item);
      } else {
        item.priority = item.priority_cpy;
        item.edit_data = false;
      }
    },
    async changeClassification(item, boureau = null) {
      let message = "";
      if (boureau == null) {
        message =
          "Are yoy sure you want to change the classification of account " +
          this.getCreditorName(item) +
          "?";
      } else {
        message =
          "Are you sure you want to change the " +
          this.capitalizeFirstLetter(boureau) +
          "'s classification of account " +
          this.getCreditorName(item) +
          "?";
      }
      const confirm = await this.showConfirmSwal(message);
      if (confirm.isConfirmed) {
        const params = {
          analysisAccountId: item.analysis_account_id,
          tab: this.typeView,
          value: item.priority,
          id_detail: item.id,
          id_user: this.currentUser.user_id,
        };
        try {
          this.addPreloader();
          const { data } = await NcrRequestService.changeClassification(params);
          if (data.success) {
            this.showSuccessToast();
            await this.myProvider();
          } else {
            this.showErrorToast();
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      } else {
        item.priority = item.priority_cpy;
        item.edit_data = 0;
      }
    },
    modifyVisibility(nameKey, isVisible) {
      let keyField = this.fields.findIndex((item) => item.key == nameKey);
      if (keyField != -1) {
        this.fields[keyField].visible = isVisible;
      }
    },
    modifyVisibilityLabelRemove(nameKey, isVisible) {
      let keyField = this.labelsRemove.findIndex((item) => item.key == nameKey);
      if (keyField != -1) {
        this.labelsRemove[keyField].visible = isVisible;
      }
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    async changeBoureauResult(item, boureau) {
      let boureauResult;
      switch (boureau) {
        case "transunion":
          boureauResult = "transunion_result";
          break;
        case "experian":
          boureauResult = "experian_result";
          break;
        case "equifax":
          boureauResult = "equifax_result";
          break;
      }
      let verifyResult = this.boureauChecksVerify(item, boureau);
      if (!verifyResult) return;

      const confirm = await this.showConfirmSwal(
        "Are you sure you want to change the " +
          this.capitalizeFirstLetter(boureau) +
          "'s result of account " +
          this.getCreditorName(item) +
          "?"
      );
      if (!confirm.isConfirmed) return;

      const params = {
        analysisAccountId: item.analysis_account_id,
        boureau: boureau,
        value: item[boureauResult] == 0 ? 1 : 0,
        id_detail: item.id,
        tab: this.typeView,
      };
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.changeResultBoureau(params);
        if (data.success) {
          this.showSuccessToast();
          this.myProvider();
        } else {
          this.showErrorToast();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    settlementAccountIdSelecteds() {
      let accountToSettlement = this.settlementData.filter(
        (item) => item.selected
      );
      return accountToSettlement;
    },
    removeAccountIdSelecteds() {
      let accountToRemove = this.removeData.filter((item) => item.selected);
      return accountToRemove;
    },
    verifySelecteds() {
      let aux = this.settlementData.filter((item) => item.selected);
      return aux.length > 0;
    },
    async addCreditors() {
      const confirm = await this.showConfirmSwal();
      if (!confirm.isConfirmed) return;

      let accountSelecteds = this.settlementAccountIdSelecteds();
      const needsConfirmationAccounts = this.settlementData
        .filter((item) => item.priority == "NEEDS CONFIRMATION")
        .map((item) => {
          return {
            analysis_account_id: item.analysis_account_id,
            selected: item.selected,
          };
        });
      const params = {
        // reference: lead-module/dashboard/modal/ModalServicesDS.vue
        needsConfirmationAccounts,
        accountSelecteds: accountSelecteds,
        lead_id: this.leadId,
        type_in: 0,
        user: this.currentUser.user_id,
        retainerFee: 0,
        ncrRequestId: this.ncrRequestId,
        createEvent: true,
        moduleId: this.currentUser.modul_id,
      };
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.addCreditors(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Congratulation!",
            "CheckIcon",
            "Successfully process"
          );
          if (!this.showTabs) {
            this.$emit("newEvent", data);
            this.$emit("closeModal");
          } else {
            this.approvedAccountsByClient();
          }
          this.myProvider();
        } else {
          this.showToast(
            "error",
            "top-right",
            "Oops!",
            "XIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async addAccountsRemoveSelected() {
      const confirm = await this.showConfirmSwal();
      if (!confirm.isConfirmed) return;
      let accountDetailSelected = this.removeAccountDetailIdSelecteds();
      let accountSelecteds = this.removeAccountIdSelecteds();
      const params = {
        accountSelecteds: accountSelecteds,
        lead_id: this.leadId,
        createEvent: true,
        moduleId: this.currentUser.modul_id,
        userId: this.currentUser.user_id,
        accountDetailSelected: accountDetailSelected,
        eventId: this.useNcrFirstProgram ? this.eventId : null,
      };
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.addAccountsRemoveSelected(
          params
        );
        if (data.success) {
          this.myProvider();
          this.showToast(
            "success",
            "top-right",
            "Congratulation!",
            "CheckIcon",
            "Successfully process"
          );
          if (!this.showTabs) {
            this.approvedAccountsByClient();
            this.$emit("newEvent", data);
            this.$emit("closeModal");
          } else {
            this.approvedAccountsByClient();
          }
        } else {
          this.showToast(
            "error",
            "top-right",
            "Oops!",
            "XIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async approvedAccountsByClient() {
      const service = this.useNcrFirstProgram
        ? "approvedAccountsByEventId"
        : "approvedAccountsByClient";
      const params = {
        typeAccounts: this.typeView, // REMOVE, SETTLEMENTS
        leadId: this.leadId,
        eventId: this.eventId, // used when add/change service to credit experts
      };
      try {
        const { data } = await NcrRequestService[service](params);
        this.approvedAccounts = data.exist_approved_accounts;
        this.eventId = data.event_id;
        if (this.approvedAccounts) {
          this.$emit("accountsSelected");
          await this.qtySettlementSelected();
        }
      } catch (error) {
        this.approvedAccounts = false;
      }
    },
    boureauChecksVerify(item, boureau) {
      let transResult = item.transunion_result;
      let expResult = item.experian_result;
      let eqResult = item.equifax_result;
      switch (boureau) {
        case "transunion":
          transResult = transResult == 0 ? 1 : 0;
          break;
        case "experian":
          expResult = expResult == 0 ? 1 : 0;
          break;
        case "equifax":
          eqResult = eqResult == 0 ? 1 : 0;
          break;
      }
      if (transResult == 0 && expResult == 0 && eqResult == 0) {
        this.showToast(
          "warning",
          "top-right",
          "Oops!",
          "AlertIcon",
          "There must be at least one bureau marked"
        );
        return false;
      } else {
        return true;
      }
    },
    getCreditorName(item) {
      if (this.typeView == "REMOVE") {
        if (item.items) {
          let credit = item.items.find((item) => item.creditor_name != "-");
          return credit["creditor_name"];
        } else {
          return item.creditor_name;
        }
      } else {
        return item.creditor_name;
      }
    },
    async deleteAccount(item) {
      const confirm = await this.showConfirmSwal(
        "Are you sure you want to delete the account: " +
          this.getCreditorName(item) +
          "?"
      );
      if (!confirm.isConfirmed) return;

      const params = {
        accountId: item.analysis_account_id,
        tab: this.typeView,
        userId: this.currentUser.user_id,
      };

      try {
        this.addPreloader();
        const { data } = await NcrRequestService.deleteAccount(params);
        if (data.success) {
          this.showSuccessToast();
          this.myProvider();
        } else {
          this.showErrorToast();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async changeStatusNcr() {
      const params = {
        ncrRequestId: this.ncrRequestId,
        userId: this.currentUser.user_id,
        roleUserId: this.currentUser.role_id,
        moduleIdNcr: this.moduleIdNcr,
        clientAccountId: this.auxIdClient,
        leadId: this.leadId,
      };
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.changeStatusNcr(params);
        if (data.success) {
          this.showSuccessSwal();
          this.$emit("refreshTable");
          this.$emit("closeModal");
        } else {
          this.showErrorToast();
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    accountsModal() {
      this.filterParams.typeAccount = this.typeView;
      this.showAccountsModal = true;
    },
    showHighCredit(item) {
      item.high_credit =
        item.high_credit == null || item.high_credit == "-"
          ? 0
          : item.high_credit;
      return parseFloat(item.high_credit).toFixed(2);
    },
    showAverage(item) {
      if (item.average == null) {
        return 60;
      } else {
        return item.average;
      }
    },
    addChekAfterSaveData() {
      switch (this.boureauName) {
        case "transunion":
          this.accountSelected.transunion = true;
          break;
        case "experian":
          this.accountSelected.experian = true;
          break;
        case "equifax":
          this.accountSelected.equifax = true;
          break;
      }
    },
    classResults(item, column) {
      return {
        "text-success": item[column] == 1,
        "text-success cursor-pointer icon":
          item[column] == 1 &&
          this.analysisEvaluationForTab == "OBSERVED" &&
          this.editMode,
        "text-warning cursor-pointer icon":
          (item[column] == 0 || item[column] == null) &&
          this.analysisEvaluationForTab == "OBSERVED" &&
          this.editMode,
        "text-secondary":
          (item[column] == 0 || item[column] == null) &&
          this.analysisEvaluationForTab != "OBSERVED",
      };
    },
    closeModal() {
      this.showEditNcrAccountModal = false;
      this.myProvider;
    },
    showSuccessToast() {
      this.showToast(
        "success",
        "top-right",
        "Success!",
        "CheckIcon",
        "Succesfully process"
      );
    },
    showErrorToast() {
      this.showToast("danger", "top-right", "Error", "XIcon", "Failed process");
    },
    addCustomPreloader(message = null) {
      this.showCustomPreloader = true;
      if (message) {
        this.customMessagePreloader = message;
      }
    },
    removeCustomPreloader() {
      this.showCustomPreloader = false;
    },
    async showPdfNcrRequest() {
      try {
        const { url } = await NcrRequestService.getPdfNcrRequest({
          ncrRequestId: this.ncrRequestId,
        });
        window.open(url, "_blank");
      } catch (ex) {}
    },
    removeAccountIdSelecteds() {
      const data = this.accDetailSelected.map((item) => ({
        cr_accounts_ac_id: item.cr_accounts_ac_id,
        analysis_account_id: item.analysis_account_id,
      }));
      const uniqueCrAccountsAcIds = new Set();
      const filteredData = data
        .filter((item) => {
          if (!uniqueCrAccountsAcIds.has(item.cr_accounts_ac_id)) {
            uniqueCrAccountsAcIds.add(item.cr_accounts_ac_id);
            return true;
          }
          return false;
        })
        .map((item) => ({
          cr_accounts_ac_id: item.cr_accounts_ac_id,
          analysis_account_id: item.analysis_account_id,
        }));
      return filteredData;
    },
    removeAccountDetailIdSelecteds() {
      const removeData = this.removeData.flatMap((entry) =>
        entry.items.filter((item) => item.is_remove && !item.selected_negotiate)
      );
      this.accDetailSelected = removeData;
      return removeData;
    },
    convertBureau(bureau) {
      let data = {
        1: "transunion",
        2: "experian",
        3: "equifax",
      };
      return data[bureau];
    },
    seeMore() {
      this.showLess = !this.showLess;
    },
    checkKeyExists(array, selectValue) {
      return array.some((field) => field.key === selectValue);
    },
    updateOthers(item) {
      item.approvedAccounts == 0 ? false : true;
    },
  },
};
</script>
<style scoped>
* {
  --customBgSuccess: #00c04f;
  --primary-color: #3f7afa;
}
.fixed-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
  z-index: 9;
}
.custom-card {
  transform: translateY(-15px);
  border-radius: 0px !important;
}
.custom-img {
  filter: brightness(0) invert(1);
  height: 20px !important;
  width: 20px !important;
}
.custom-font-size {
  font-size: 13px !important;
}
.custom-bg {
  background-color: #eff3fc !important;
  box-shadow: none !important;
}
.custom-table {
  margin: 0px 10px 25px 10px !important;
  border-radius: 10px !important;
}
.hidden-x {
  overflow-x: hidden !important;
}
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: var(--primary-color) !important;
  color: white;
}
.custom-table tbody tr td {
  border: 1px solid gray !important;
}
.custom-table .table tr td:first-child,
.custom-table-dark .table tr td:first-child {
  border-radius: 0px !important;
}
.row-light >>> tbody tr {
  background-color: white !important;
}
li.padding {
  height: 35px;
  border-top: 0.5px solid #e3e5ec !important;
}
.custom-height {
  height: 100px !important;
}
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.icon {
  animation: heartbeat 1s infinite;
}
.font-size-md {
  font-size: 13px;
}
.font-size-xmd {
  font-size: 12px;
}
.font-size-sm {
  font-size: 11px !important;
}
.rotate-180 {
  transform: rotate(180deg) scale(1.3);
  color: #007deb !important;
}
.custom-squad {
  width: 12px;
  height: 12px;
  display: flex;
}
.custom-bg-logo {
  opacity: 0.3 !important;
}
.table.b-table > tbody > tr {
  position: relative !important;
}
.table.b-table > tbody > tr.b-table-details > td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.table.b-table > tbody .b-table-row-selected.table-active td {
  background-color: #ebeefc !important;
}
.custom-icon {
  width: 15px;
  height: 15px;
}
.custom-icon-md {
  width: 19px;
  height: 19px;
}
.size-legends {
  width: 19px !important;
  height: 19px !important;
}
.font-size-legend {
  font-size: 15px !important;
}
ul {
  margin: 0;
}
</style>
