import store from "@/store"
import Vue from "vue"
import HelpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service"

const ticketCommentNotification = () => {
  let module_name = ""
  window.socket.bind("ticket-comment", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id

    if (store.state.auth.currentUser.eng_soft == 1) {
      const params = {
        modul_id: 12,
        user_id: sessionId,
      }
      const response = await HelpdeskService.getCounterNotifications(params)
      const tab = await HelpdeskService.getCountTab(params)
      store.commit("HelpdeskStore/SET_COUNTER_NOTIFICATIONS", tab.data.total)
      
      if (Object.keys(response.data).length > 0) {
        const payload = {
          routeName: "helpdesk-ti",
          tag: response.data.total,
        }
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
      }
    }

    if (data.to_id.includes(Number(sessionId))) {
      store.state.auth.currentUser.arrModuls.forEach((modul) => {
        if (modul.id_module == store.state.auth.currentUser.modul_id) {
          module_name = modul.module_route
        }
      })
      const params = {
        modul_id: store.state.auth.currentUser.modul_id,
        user_id: store.state.auth.currentUser.user_id,
      }
      const tab = await HelpdeskService.getCountTab(params)
      store.commit("HelpdeskStore/SET_COUNTER_NOTIFICATIONS", tab.data.total)
      const par = {
        user_id: 124,
        is_reviewer: 1,
      }
      const tab2 = await HelpdeskService.getCountTab(par)
      store.commit("HelpdeskStore/SET_REVIEWER_COUNTER_NOTIFICATIONS", tab2.data.total)
      const response = await HelpdeskService.getCounterNotifications(params)
      if (Object.keys(response.data).length > 0) {
        const payload = {
          routeName: `helpdesk-${module_name}`,
          tag: response.data.total,
        }
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
      }
      const isAlertOpen = store.state.StandarStore.S_SHOW_AMG_ALERT
      if (isAlertOpen) return
      await Vue.prototype.$amgAlert({
        title: data.others.reviewer || data.others.returned
          ? "Ticket Status Updated"
          : data.others.type == "reject"
          ? "Ticket Rejected"
          : "New Message on Ticket",
        icon: (data.others.returned || data.others.type == "reject") 
          ? "XCircleIcon"
          : "CheckIcon",
        subtitle: data.message,
        data: [
          {
            icon: "GridIcon",
            title: "Ticket ID",
            description: data.ticket_id,
          },
          {
            icon: "GridIcon",
            title: "Module",
            description: data.others.module_name,
          },
          {
            icon: "UserIcon",
            title: "Sender Name",
            description: data.others.name_sender,
          },
        ],
        okTitle: "Ok",
        okComponentTitle: "Open Ticket",
        dataComponent: {
          name: "ModalDetails",
          props: {
            ticket_id: data.ticket_id.toString(),
          },
        },
        ok: async () => {
          await window.amgApi.post("/commons/close-all-swal", data)
        },
      })
    }
  })
}
export default ticketCommentNotification
