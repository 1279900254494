<template>
  <b-card class="card-lead-payment" footer-class="border-0">
    <b-card-title class="border-bottom-secondary border-2">
      <h4 class="font-weight-bolder px-50 title-payments mb-1">Payments</h4>
    </b-card-title>

    <b-row>
      <b-col cols="12">
        <LeadTransactions
          ref="lead-transactions-table"
          :lead-id="lead.id"
          :lead-name="lead.lead_name"
          :only-read="onlyRead"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import CardLeadPaymentSection from "./CardLeadPaymentSection.vue";
import LeadTransactions from "@/views/commons/components/leads/transactions/LeadTransactions.vue";

export default {
  components: {
    CardLeadPaymentSection,
    LeadTransactions,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      updatedCards: "CrmCreditCardStore/LISTCARDS",
    }),
    updateCards() {
      this.lead.cards = this.updatedCards;
    },
  },
  created() {
    this.authUser = this.currentUser;
  },
  data() {
    return {
      authUser: {},
      court: new Object(),
      isBusyRealtor: false,
      isLoading: false,
      lien: new Object(),
      realtor: new Object(),
      other: new Object(),
      key: {
        court: 0,
        lien: 0,
        other: 0,
        realtor: 0,
      },
      showAddLeadTransaction: false,
    };
  },
  directives: { Ripple },
  props: {
    onlyRead: {
      type: Boolean,
      required: true,
    },
    lead: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.card-lead-payment {
  .title-payments {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #111113;
  }
}
.dark-layout {
  .card-lead-payment {
    .title-payments {
      color: #fafafa;
    }
  }
}
</style>