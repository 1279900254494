var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.titleModal,"size":"lg","scrollable":"","hide-footer":_vm.onlyView || _vm.saleData.notes_ids != null},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.disableButton || _vm.pendingProcess},on:{"click":function($event){return _vm.processCalls()}}},[_vm._v(" Process ")])],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[(_vm.page == 1)?_c('div',[_c('calls-from-lead',{ref:"callsFromLead",attrs:{"leadId":_vm.saleData.lead_id,"selectable":_vm.tab == 'clients' && _vm.saleData.notes_ids == null,"typeSale":_vm.tab,"eventId":_vm.saleData.event_id},on:{"defineDisabledButton":_vm.defineDisabledButton,"closeModal":_vm.closeModal,"refreshTable":_vm.refreshTable}})],1):_c('div',[_vm._l((_vm.notes),function(note,index){return _c('div',{key:note.id},[(note.sentiment != null)?_c('span',{staticClass:"badge-sentiment",style:({
          backgroundColor:
            note.sentiment === 'positive'
              ? '#00d25b'
              : note.sentiment === 'negative'
              ? '#f10000'
              : note.sentiment === 'neutral'
              ? '#0090e7'
              : '#6e3ccd',
        })},[_vm._v("Sentiment: "+_vm._s(note.sentiment))]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('span',{staticClass:"note-created-data"},[_c('b-avatar',{staticClass:"d-inline-block mr-1",attrs:{"variant":"info","src":note.image_thumb}}),_vm._v(" "+_vm._s(note.user)+" | "+_vm._s(_vm._f("myGlobalWithHour")(note.created_at)))],1)]),_c('div',{staticClass:"text-container"},[(note.text != null)?_c('change-sms',{staticClass:"pb-1 mb-1",attrs:{"SmsData":note.text,"TextLength":1200},on:{"isExpanded":function($event){note.isExpanded = !note.isExpanded}}}):_vm._e()],1)])}),_c('hr',{staticClass:"text-warning"}),_c('div',[_c('p',{staticClass:"text-warning",staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v("Calls list: ")]),_c('div',{staticClass:"pb-1"},[_c('b-table',{ref:"selectableTable",attrs:{"items":_vm.selectedCalls,"fields":_vm.fields,"show-empty":"","small":""},scopedSlots:_vm._u([{key:"cell(start_time)",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(item.start_time)))])]}},{key:"cell(audio_url)",fn:function(data){return [_c('new-wave-surfer-player',{attrs:{"url":data.item.audio_url,"options":_vm.audioOptions,"wave-surfer-id":("wave-surfer-" + (data.item.id)),"show-duration":true,"elapsed-time-width":95}})]}}])})],1)])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }