export default {
  path: "helpdesk",
  name: "helpdesk-ce-sales",
  component: () => import("./Helpdesk.vue"),
  meta: {
    pageTitle: "Help Desk",
    module: 26,
    breadcrumb: [
      {
        text: "Help Desk",
        active: true,
      },
    ],
    onlyChief: true,
  },
}
