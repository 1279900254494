import store from '@/store'
import Vue from 'vue'
import router from '@/router/index'

const ceDynamicCommissionsCreation = () => {
  window.socket.bind('ce-dynamic-commissions-creation', async data => {
    let temps = false
    const sessionId = store.state.auth.currentUser.user_id
    data.to_id.forEach(element => {
      if (element === sessionId) {
        temps = true
      }
    })
    if (temps) {
      const htmlAlert = `${`${'<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;" alt="alert-info-image">'
        + '<div><h2 style="font-weight: 600;">COMMISSION CREATED</h2></div>'
        + '<div style="text-align: center;">'
        + '<div style="font-weight: bolder;">Created By: '}${
        data.user_name
      } </div>`
        + ' </div>'
        + '<div style="font-weight: bolder; margin-bottom: 0.5rem; margin-top: 0.5rem;">Commission name: '}${
        data.description.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())
      } </div>`
        + `<div style="font-weight: bolder; margin-bottom: 0.5rem; margin-top: 0.5rem;"> Value: ${
          data.type_commission === 1 ? '$ ' : ''
        }${data.value
        }${data.type_commission === 2 ? ' %' : ''
        }</div>`
        + `<div style="font-weight: bolder; margin-bottom: 0.5rem; margin-top: 0.5rem;"> Charge: ${data.type_charge}</div>`
        + '</div>'
      const res = await Vue.swal.fire({
        html: htmlAlert,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Close",
      })
      if (res.value) {
        if (router.currentRoute.matched[0].name !== 'commissions-management') {
          window.open('/management/commissions', '_blank')
        }
        await window.amgApi.post('/commons/close-all-swal', data)
      }
    }
  })
}
export default ceDynamicCommissionsCreation
