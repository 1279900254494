import { amgApi } from '@/service/axios'

class ListClientCompanyService{
    async searchclientcompanydata(params, page) {
        try {
          const { data } = await amgApi.post(`/paragon/clientscompanydata?page=${page}`, params)
          return data
        } catch (error) {
          throw error
        }
    }

    async refreshcapsulecompany(params) {
        try {
          const data  = await amgApi.post('/paragon/refresh-capsule-company', params)
          return data
        } catch (error) {
          throw error
        }
    }

    async nonRepeatingPacksFilter() {
      try {
        const data  = await amgApi.post('/paragon/non-repeating-packs-filter')
        return data
      } catch (error) {
        throw error
      }
    }

    async usersPrograms(payload) {
      try {
        const data  = await amgApi.post('/commons/users-programs', payload)
        return data
      } catch (error) {
        throw error
      }
    }

}

export default new ListClientCompanyService()