export default [
  {
    path: "calendar",
    name: "calendar-tax-research",
    component: () => import(/* webpackChunkName: "TaxResearchCalendar" */ "@/views/tax-research/views/calendar/calendar.vue"),
    meta: {
      module: 8,
      pageTitle: "Calendar",
      breadcrumb: [
        {
          text: "Calendar",
        },
      ],
      permittedRoles: [1, 2],
    },
  },
];
