import { amgApi } from "@/service/axios";

class PGBinnacle {
  async getBinnacles(params) {
    try {
      const { data } = await amgApi.post("/paragon/binnacle/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getBinnacleMessages(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/messages/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCurrentBinnacleHeader(params) {
    try {
      const { data } = await amgApi.post("/paragon/binnacle/show", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getEmojis(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/get-emojis",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCounterMessages(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/get-counter-messages",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addMember(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/members/store",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getBinnacleMembers(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/members/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async storeBinnacleMessageReaction(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/messages/reaction/store",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async removeMember(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/members/destroy",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchMember(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/members/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async showBinnacleMessages(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/messages/show",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async storeBinnacleMessageSeen(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/messages/seen/store",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getBinnacleFiles(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/files/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async storeBinnacleMessage(params) {
    try {
      const { data } = await amgApi.post(
        "/paragon/binnacle/messages/store",
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new PGBinnacle();
