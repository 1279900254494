export default [
    {
      path: "permission-action",
      name: "permission-section-debtsolution",
      redirect: { name: "pending-permission-action-section-debtsolution" },
      component: () =>
        import(
          /* webpackChunkName: "Managment call-log" */
          "@/views/debt-solution/views/permission-action/PermissionAction.vue"
        ),
      meta: {
        pageTitle: "permission",
        permittedRoles: [1, 2, 17],
        breadcrumb: [
          {
            text: "permission",
            active: true,
          },
        ],
      },
      children: [
        {
          path: "pending",
          name: "pending-permission-action-section-debtsolution",
          component: () =>
            import(
              /* webpackChunkName: "call-log-all" */
              "@/views/debt-solution/views/permission-action/components/PermissionActionTable.vue"
            ),
          meta: {
            pageTitle: "Permission Action",
            permittedRoles: [1, 2, 17],
            breadcrumb: [
              {
                text: "Pending",
                active: true,
              },
            ],
            tab: 1,
          },
        },
        {
          path: "finished",
          name: "finished-permission-action-section-debtsolution",
          component: () =>
            import(
              /* webpackChunkName: "call-log-all" */
              "@/views/debt-solution/views/permission-action/components/PermissionActionTable.vue"
            ),
          meta: {
            pageTitle: "Permission Action",
            permittedRoles: [1, 2, 17],
            breadcrumb: [
              {
                text: "Finished",
                active: true,
              },
            ],
            tab: 2,
          },
        },
      ],
    },
  ]
  