import { amgApi } from "@/service/axios";
class SettlementsMadeService {
  async getSettlementsMade(body) {
    const data = await amgApi.post(
      "/debt-solution/settlements-made/get-settlements-made",
      body
    );
    return data;
  }
  async getSettlementsMadePaymentMethod(body) {
    const data = await amgApi.post(
      "/debt-solution/settlements-made/get-settlements-made-payment-method",
      body
    );
    return data;
  }
  async getSettlementsMadeMadeTrackingData(body) {
    const data = await amgApi.post(
      "/debt-solution/settlements-made/get-settlements-made-made-tracking",
      body
    );
    return data;
  }

  async generateOfferPaymentOrder(body) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/settlements-made/generate-offer-payment-order",
        body
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async saveTifFile(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/save-pif-document",
        params,
        { responseType: "blob" } // will receive the pdf generated in case of success
      );
      return data;
    } catch (error) {
      console.log("Error unexpected " + error);
      return error;
    }
  }

  async getNotPaidreasons(params) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/settlements-made/get-not-paid-easons-in-payment-orders",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async countSettlementsMade(payload) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/settlements-made/count-settlements-made",
        payload
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getCheck(payload) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/settlements-made/get-check",
        payload
      );
      return data[0];
    } catch (err) {
      console.error(err);
    }
  }

  async getOrderBinnacle(payload) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/searh_details_binnacle_order",
        payload
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getOptionMessages(payload) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/get-option-messages-binnacle",
        payload
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async insertBinnacleMessage(payload) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/insert-message-binnacle",
        payload
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getBankAccountAvailableBalance(payload) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/settlements-made/get-bank-account-available-balance",
        payload
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async getMessagesBinnacle() {
    try {
      const data = await amgApi.get(
        "/debt-solution/settlements-made/get-messages-binnacle"
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async inactiveMessageBinnacle(payload) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/inactive-message-binnacle",
        payload
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async generatePdfFinalizeProcess(offerPaymentOrderId) {
    try {
      const data = await amgApi.get(
        "/debt-solution/settlements-made/generate-pdf-on-finalize-process/" +
          offerPaymentOrderId,
        { responseType: "blob" }
      );
      return data;
    } catch (err) {
      console.error(
        "ERRORS ON SettlementsMadeService:generatePdfFinalizeProcess ",
        err
      );
    }
  }
  async getPaymentStatusTotal() {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/get-payment-status-total"
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getBankAccountDataByPaymentOrderId(payload) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/settlements-made/get-bank-account-data-by-payment-order-id",
        payload
      );
      return data[0];
    } catch (err) {
      console.error(err);
    }
  }
  async updateViewed(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/update-viewed",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async makePaymentOffer(body) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/make-payment-offer",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new SettlementsMadeService();
