export default [
  {
    path: "/cedigital/customerservice/dispute-results",
    name: "customer-service-dispute-results-origin",
    component: () =>
      import(
        "@/views/ce-digital/sub-modules/customer-service/views/dispute-results/views/DisputeResultsOrigin.vue"
      ),
    redirect: { name: "cs-dispute-results-in-process-origin" },
    meta: {
      module: 22,
      permittedRoles: [1, 2, 14, 17],
      pageTitle: "Update Request",
      /* breadcrumb: [
                        {
                            text: "NCR Realtor",
                            active: true,
                        },
                    ], */
    },
    children: [
      {
        path: "",
        name: "cs-dispute-results-in-process-origin",
        component: () =>
          import(
            /* webpackChunkName: "group-user" */ "@/views/commons/components/dispute-results/views/components/TableInProcess.vue"
          ),
        meta: {
          module: 22,
          status: 0,
          is_digital: true,
          permittedRoles: [1, 2, 14, 17],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "In Process",
              active: true,
            },
          ],
        },
      },
      {
        path: "",
        name: "cs-dispute-results-completed-origin",
        component: () =>
          import(
            /* webpackChunkName: "group-user" */ "@/views/commons/components/dispute-results/views/components/TableCompleted.vue"
          ),
        meta: {
          module: 22,
          status: 1,
          is_digital: true,
          permittedRoles: [1, 2, 14, 17],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "/cedigital/customerservice/dispute-results",
    name: "customer-service-dispute-results",
    component: () =>
      import(
        "@/views/ce-digital/sub-modules/customer-service/views/dispute-results/views/DisputeResults.vue"
      ),
    redirect: { name: "cs-dispute-results-in-process" },
    meta: {
      module: 22,
      permittedRoles: [1, 2, 14, 17],
      pageTitle: "Update Request"
    },
    children: [
      {
        path: "",
        name: "cs-dispute-results-in-process",
        component: () =>
          import(
            /* webpackChunkName: "group-user" */ "@/views/commons/components/dispute-results/views/components/tabs/TableInProcess.vue"
          ),
        meta: {
          module: 22,
          status: 0,
          is_digital: true,
          permittedRoles: [1, 2, 14, 17],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "In Process",
              active: true,
            },
          ],
          tab: 'IN PROCESS'
        },
      },
      {
        path: "",
        name: "cs-dispute-results-completed",
        component: () =>
          import(
            /* webpackChunkName: "group-user" */ "@/views/commons/components/dispute-results/views/components/tabs/TableCompleted.vue"
          ),
        meta: {
          module: 22,
          status: 1,
          is_digital: true,
          permittedRoles: [1, 2, 14, 17],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
          tab: 'COMPLETED'
        },
      },
    ],
  },
];
