<template>
  <div>
    <b-modal
      ref="modal_review_by_ceo"
      v-model="control"
      size="lg"
      :title="readonly?'MANUAL OF FUNCTIONS':'MANUAL OF FUNCTIONS - REVIEW'"
      header-bg-variant="primary"
      header-text-variant="dark"
      body-bg-variant="ligth"
      body-text-variant="dark"
      footer-bg-variant="ligth"
      footer-text-variant="dark"
      no-close-on-backdrop
      scrollable
      class="border-0 border-white"
      @hidden="closeModal"
    >
      <b-card
        title="GENERAL DATA"
        :style=" isDarkSkin ? 'background: #2d3239' : ''"
      >
        <b-form-group label="JOB TITLE">
          <b-form-select
            id="hrRolID"
            v-model="hr_role_id"
            :options="hr_roles"
            disabled
          />
        </b-form-group>
      </b-card>

      <b-card
        title="RELATIONSHIP WITH OTHER POSITIONS"
        :style=" isDarkSkin ? 'background: #191f25' : ''"
      >
        <b-row>
          <b-col>
            <b-form-group label="To whom it reports">
              <b-form-select
                id="toWhomReportsID"
                v-model="toWhomReports"
                :options="hr_roles"
                disabled
              /></b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Who Reports To">
              <b-form-select
                id="whoReportsToID"
                v-model="whoReportsTo"
                :options="hr_roles"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card
        title="MISSION OF THE POSITION"
        :style=" isDarkSkin ? 'background: #2d3239' : ''"
      >
        <b-form-textarea
          id="missionID"
          v-model="mission"
          rows="5"
          size="sm"
          placeholder="Small textarea"
          disabled
        />
      </b-card>

      <b-card
        title="FUNCTIONS OR ACTIVITIES"
        :style=" isDarkSkin ? 'background: #191f25' : ''"
      >
        <b-row
          v-for="(activity, index) in activities"
          :key="index"
          class="d-flex align-items-center justify-content-center"
        >
          <b-col cols="12">
            <b-form-group>

              <b-form-input
                :id="'mof_name_' + index"
                :value="activity"
                type="text"
                :placeholder="'ACTIVITY/FUNCTION ' + (index + 1)"
                required
                disabled
                @input="updateActivity(index, $event)"
              />

            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card
        title="AUTONOMY"
        :style=" isDarkSkin ? 'background: #2d3239' : ''"
      >
        <b-form-radio-group
          v-model="autonomy"
          :options="options"
          value-field="item"
          text-field="name"
          disabled
        />

        <b-button
          variant="outline-info"
          class="my-1"
          size="sm"
          @click="openModalAutonomy"
        >
          <feather-icon icon="InfoIcon" />
          Autonomy Legend
        </b-button>
      </b-card>

      <b-card
        title="RELATIONS WITH OTHER POSITIONS"
        :style=" isDarkSkin ? 'background: #191f25' : ''"
      >
        <b-row>
          <b-col cols="6">
            <b-card
              v-if="internalrel != 0"
              title="INTERNAL"
              :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
              :style=" isDarkSkin ? 'background: #2e3439' : ''"
              style="max-height: 300px; overflow-y: auto;"
            >
              <b-list-group flush>
                <!--  -->
                <b-list-group-item
                  v-for="(item, index) in internalrel"
                  :key="index"
                  class="rounded mb-1"
                  :style="isDarkSkin ? 'background: #8f9494; color: black;' : ''"
                >
                  <tabler-icon
                    icon="CircleIcon"
                    size="16"
                    style="color: #4caf50"
                  />
                  {{ item }}
                </b-list-group-item>
              </b-list-group>
            </b-card>

          </b-col>
          <b-col cols="6">
            <b-card
              v-if="externalrel != 0"
              title="EXTERNAL"
              :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
              :style=" isDarkSkin ? 'background: #2e3439' : ''"
              style="max-height: 300px; overflow-y: auto;"
            >
              <b-list-group flush>
                <!--  -->
                <b-list-group-item
                  v-for="(item, index) in externalrel"
                  :key="index"
                  class="rounded mb-1"
                  :style="isDarkSkin ? 'background: #8f9494; color: black;' : ''"
                >
                  <tabler-icon
                    icon="ArrowRightIcon"
                    size="16"
                    style="color: #0074e4"
                  />
                  {{ item }}
                </b-list-group-item>
              </b-list-group>
            </b-card>

          </b-col>
        </b-row>
      </b-card>

      <b-card
        title="COMPETENCY PROFILE (SKILLS)"
        :style=" isDarkSkin ? 'background: #2d3239' : ''"
        style="max-height: 500px; overflow-y: auto;"
      >

        <b-list-group class="mb-2">
          <b-list-group-item
            v-for="(skill, key) in skills"
            :key="key"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
            class="d-flex justify-content-between"
          >
            <h4>{{ skill.description }}</h4>
            <b-form-checkbox
              v-model="skill.marked"
              :value="true"
              :unchecked-value="false"
              disabled
            />
          </b-list-group-item>
        </b-list-group>
      </b-card>

      <b-card
        title="JOB REQUIREMENTS"
        :style=" isDarkSkin ? 'background: #0e131a' : ''"
      >
        <b-row>
          <b-col cols="12">
            <b-card
              title="EDUCATION"
              :style=" isDarkSkin ? 'background: #2d3239' : 'background: #edf3fd'"
            >

              <b-form-group label="MAXIMUM">
                <b-form-textarea
                  id="mof_name"
                  v-model="maximun_education"
                  type="text"
                  placeholder="MAXIMUM EDUCATION"
                  required
                  disabled
                />
              </b-form-group>
              <b-form-group label="MINIMUM">
                <b-form-textarea
                  id="mof_name"
                  v-model="minimun_education"
                  type="text"
                  placeholder="MINIMUM EDUCATION"
                  required
                  disabled
                />
              </b-form-group>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card
              title="FORMATION"
              :style=" isDarkSkin ? 'background: #2d3239' : 'background: #edf3fd'"
            >
              <b-form-group label="MAXIMUM">
                <b-form-textarea
                  id="mof_name"
                  v-model="maximum_formation"
                  type="text"
                  placeholder="MAXIMUM FORMATION"
                  required
                  disabled
                />
              </b-form-group>
              <b-form-group label="MINIMUM">
                <b-form-textarea
                  id="mof_name"
                  v-model="minimun_formation"
                  type="text"
                  placeholder="MINIMUM FORMATION"
                  required
                  disabled
                />
              </b-form-group>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card
              title="EXPERIENCE"
              :style=" isDarkSkin ? 'background: #2d3239' : 'background: #edf3fd'"
            >
              <b-form-group label="MAXIMUM">
                <b-form-textarea
                  id="mof_name"
                  v-model="maximum_experience"
                  type="text"
                  placeholder="MAXIMUM EXPERIENCE"
                  required
                  disabled
                />
              </b-form-group>
              <b-form-group label="MINIMUM">
                <b-form-textarea
                  id="mof_name"
                  v-model="minimum_experience"
                  type="text"
                  placeholder="MINIMUM EXPERIENCE"
                  required
                  disabled
                />
              </b-form-group>
            </b-card>
          </b-col>

        </b-row>
      </b-card>
      <template #modal-footer>
        <div v-if="!readonly && (currentUser.user_id === 1 && mofData.mof_status_id === 4)">
          <b-button
            v-if="!openObs"
            pill
            class="mr-1"
            variant="outline-primary"
            @click="openOBS()"
          >
            WRITE OBSERVATIONS
          </b-button>

          <b-button
            id="tooltip-send-to-review"
            pill
            class="mr-1"
            variant="warning"
            @click="shippingMethod(7)"
          >
            SEND TO REVIEW HT
          </b-button>

          <b-button
            pill
            class="mr-1"
            variant="danger"
            @click="shippingMethod(6)"
          >
            DISAPPROVE
          </b-button>

          <b-button
            id="tooltip-approve"
            pill
            variant="success"
            @click="shippingMethod(5)"
          >
            APPROVE
          </b-button>

          <b-tooltip
            target="tooltip-send-to-review"
            triggers="hover"
            placement="topleft"
            variant="danger"
          >
            Send to human resources to correct observations
          </b-tooltip>
          <b-tooltip
            target="tooltip-approve"
            placement="topright"
            triggers="hover"
            variant="success"
          >
            Approve Manual of functions
          </b-tooltip>
        </div>
        <div v-else />
      </template>

      <transition
        name="fade"
        mode="out-in"
      >
        <b-row
          v-if="openObs"
          class="d-flex align-items-center"
        >

          <b-col
            cols="3"
            class="fixed-top mt-2"
            :class="readonly?'d-none':''"
          >
            <b-card
              :style=" isDarkSkin ? 'background: #2d3239' : 'background: #eff4fd'"
            >

              <div class="text-right">
                <b-button
                  variant="danger"
                  pill
                  size="sm"
                  @click="closeOBS()"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </div>
              <b-form-group label="OBSERVATIONS">
                <b-form-textarea
                  id="mof_name"
                  v-model="observationsByCeo"
                  type="text"
                  rows="10"
                  placeholder="Write observations"
                  required
                />
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
      </transition>

    </b-modal>

    <modal-autonomy-legend
      v-if="showModalAutonomyLegend"
      @close="showModalAutonomyLegend = false"
    />

  </div>

</template>

<script>

import { mapGetters } from 'vuex';

import ManualsOfFunctionsService from '@/views/commons/components/manual-of-functions/services/manuals-of-functions.service';
import ModalAutonomyLegend from '@/views/commons/components/manual-of-functions/modals/ModalAutonomyLegend.vue';

export default {
  components: {
    ModalAutonomyLegend,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
      require: false,
    },
    mofData: {
      type: Object,
      default: () => {},
    },

  },
  data() {
    return {

      openObs: false,
      selectedMOF: {},

      observationsByCeo: '',

      // modal
      showModalAutonomyLegend: false,

      internalDes: '',
      externalDes: '',

      activities: [],

      job_title: '',

      mission: '',

      autonomy: '',

      maximun_education: '',
      minimun_education: '',

      maximum_formation: '',
      minimun_formation: '',

      maximum_experience: '',
      minimum_experience: '',

      externalrel: [],
      internalrel: [],
      skills: [],

      selectedExternalRel: [],
      selectedInternalRel: [],
      // selectedSkills: [],

      control: false,

      showValidationInternal: false,
      showValidationExternal: false,

      options: [
        { item: 'high', name: 'HIGH' },
        { item: 'medium', name: 'MEDIUM' },
        { item: 'low', name: 'LOW' },
      ],

      hr_roles: [],

      toWhomReports: null,
      whoReportsTo: null,
      hr_role_id: null,

    };
  },
  computed: {

    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

  },
  mounted() {
    this.control = true;

    this.job_title = this.mofData.job_name ? this.mofData.job_name : '';

    this.mission = this.mofData.mission ? this.mofData.mission : '';

    this.toWhomReports = this.mofData.toWhomReports ? this.mofData.toWhomReports : '';
    this.whoReportsTo = this.mofData.whoReportsTo ? this.mofData.whoReportsTo : '';
    this.hr_role_id = this.mofData.hr_role_id ? this.mofData.hr_role_id : '';

    this.activities = this.mofData.functions ? JSON.parse(this.mofData.functions) : [];

    this.validateMarked();

    this.externalrel = this.mofData.relations ? JSON.parse(this.mofData.relations).external : [];
    this.internalrel = this.mofData.relations ? JSON.parse(this.mofData.relations).internal : [];

    this.autonomy = this.mofData.autonomy ? this.mofData.autonomy : '';

    this.maximun_education = this.mofData.requirements ? JSON.parse(this.mofData.requirements).maximun_education : '';
    this.minimun_education = this.mofData.requirements ? JSON.parse(this.mofData.requirements).minimun_education : '';
    this.maximum_formation = this.mofData.requirements ? JSON.parse(this.mofData.requirements).maximum_formation : '';
    this.minimun_formation = this.mofData.requirements ? JSON.parse(this.mofData.requirements).minimun_formation : '';
    this.maximum_experience = this.mofData.requirements ? JSON.parse(this.mofData.requirements).maximum_experience : '';
    this.minimum_experience = this.mofData.requirements ? JSON.parse(this.mofData.requirements).minimum_experience : '';
  },

  created() {
    this.getHRRoles();
    this.getSkills();
  },

  methods: {

    openOBS() {
      this.openObs = true;
    },

    closeOBS() {
      this.openObs = false;
    },

    openModalAutonomy() {
      this.showModalAutonomyLegend = true;
    },

    validateMarked() {
      if (typeof this.mofData.skills === 'string') {
        this.mofData.skills = JSON.parse(this.mofData.skills);
      }

      if (Array.isArray(this.skills) && Array.isArray(this.mofData.skills)) {
        this.mofData.skills.forEach(skill2 => {
          const matchingSkill = this.skills.find(s => s.id === skill2.id);
          if (matchingSkill) {
            matchingSkill.marked = skill2.marked;
          }
        });
      }
    },

    closeModal() {
      this.control = false;
      this.$emit('hidden');
    },

    async shippingMethod(mode) {
      const params = {
        id: this.mofData.id,
        status: mode,
        user_id: this.currentUser.user_id,
        job_name: this.job_title,

        toWhomReports: this.toWhomReports,
        whoReportsTo: this.whoReportsTo,
        hr_role_id: this.hr_role_id,

        observations_by_ceo: this.observationsByCeo,

        mission: this.mission,
        autonomy: this.autonomy,
        functions: this.activities,
        skills: this.skills.filter(item => item.marked === true),
        relations: {
          internal: this.internalrel,
          external: this.externalrel,
        },
        requirements: {
          maximun_education: this.maximun_education,
          minimun_education: this.minimun_education,
          maximum_formation: this.maximum_formation,
          minimun_formation: this.minimun_formation,
          maximum_experience: this.maximum_experience,
          minimum_experience: this.minimum_experience,
        },

        created_by: this.mofData.created_by,

      };

      if (this.observationsByCeo.length === 0 && mode === 7) {
        this.showWarningSwal('You must write some observation', null);
        this.openOBS();
        return;
      }

      if (this.observationsByCeo.length > 0 && mode === 5) {
        this.showWarningSwal('To confirm the MOF you should not send any observation', null);
        return;
      }

      const swalValidation = await this.showConfirmSwal();

      try {
        if (swalValidation.isConfirmed) {
          this.addPreloader();
          const response = await ManualsOfFunctionsService.insertMOF(params);

          if (response.status === 200) {
            this.showSuccessSwal('Success', 'Approved successfully');
            this.$emit('hidden');
            this.$emit('reload');
          }

          this.removePreloader();
        }
      } catch (e) {
        this.removePreloader();
        console.log(e);
      }
    },

    // Dentro del método getSkills()
    async getSkills() {
      try {
        const response = await ManualsOfFunctionsService.getSkills();
        const allSkills = response.data;

        this.skills = allSkills.map(skill => ({
          id: skill.id,
          description: skill.description,
          marked: false, // Inicialmente, ninguno está marcado
        }));

        if (Array.isArray(this.mofData.skills)) {
          this.skills = this.skills.map(skill => {
            const matchingSkill = this.mofData.skills.find(s => s.id === skill.id);
            if (matchingSkill) {
              skill.marked = matchingSkill.marked;
            }
            return skill;
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getHRRoles() {
      const response = await ManualsOfFunctionsService.getHRRoles();
      this.hr_roles = response.data.map(role => ({
        value: role.id,
        text: role.name,
      }));
    },

  },
};
</script>

<style scoped>
.fixed-button-close {
  position: fixed;
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
  background-color: #007bff !important;
  color: #fff !important;
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}

.button-danger{
  background:#ff002b !important ;
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 50px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s !important;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0 !important;
}
</style>
