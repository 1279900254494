import Vue from 'vue';
import store from '@/store';

const notificationUpdatedPensionFundEmployee = () => {
  window.socket.bind('notification-updated-pension-fund-employee', async data => {
    const { main_module, user_id } = store.state.auth.currentUser;

    const status = parseInt(data.status, 10);
    const idUser = parseInt(data.idUser, 10);
    // show alert to management
    if ((status === 2 && (idUser === user_id)) || (status === 1 && (main_module === 16 || main_module === 17))) {
      await Vue.prototype.$amgAlert({
        title: 'PENSION FUND',
        icon: 'CreditCardIcon',
        subtitle: 'PENSION FUND UPDATED',
        data: [
          {
            icon: 'UserIcon',
            title: 'EMPLOYEE',
            description: data.employee,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }
  });
};

export default notificationUpdatedPensionFundEmployee;
