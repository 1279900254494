import CreativeRequestService from "@/views/commons/components/creative-requests/services/creative.request";
import store from "@/store";
import Notifications from "@/service/notification/index";
import chargeBackV2Service from "@/views/commons/components/charge-back_v2/service/charge.back.service.js";
import employeeClaims, {
  salesMadeCounter,
} from "@/views/commons/commons-counter";
import RequestService from "@/views/commons/components/request/services/request.service.js";
import RecruitmentService from "@/views/commons/components/recruitment-process/services/recruiment.service";
import HelpDeskService from "../commons/components/helpdesk/services/helpdesk.service";
import LettersRoundService from "@/views/specialists/sub-modules/analyst-department/views/letter-round/services/letters-rounds.service.js";
import PGRequestActionsService from "@/views/commons/components/paragon-soft/services/PGRequestActions.service.js";

const paragonCounter = async () => {
  const params = {
    modul_id: 12,
    user_id: store.state.auth.currentUser.user_id,
  };
  const { data } = await HelpDeskService.getCounterNotifications(params);
  if (Object.keys(data).length > 0) {
    const payload = {
      routeName: "helpdesk-ti",
      tag: data.total,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const designRequestCounterParagon = async () => {
  const params = {
    modul_id: 12,
    user_id: store.state.auth.currentUser.user_id,
    role_id: store.state.auth.currentUser.role_id,
  };
  const { data } =
    await CreativeRequestService.getdesignRequestCounterNotifications(params);
  const payload = {
    routeName: "creative-request-options-ti",
    // contador
    tag: data[0].total,
  };
  store.commit("CreativeDesignStore/SET_COUNTERS", data[0]);
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const chargebackCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;

  const params = {
    module_id: module,
  };

  const { in_process, remove_cb, payment_client, pending, observed } =
    await chargeBackV2Service.getChargebackCounters(params);

  const solution = remove_cb + payment_client;
  const dispute = pending + observed;
  const counterSide = in_process + solution + dispute;

  const payload = {
    routeName: "crediexperts-charge-back-in-process",
    tag: counterSide,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const role = store.state.auth.currentUser.role_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  const data2 = await Notifications.getProjectsPendingNotificationParagonChief(
    params
  );
  if (data.data.length > 0) {
    const payload = {
      routeName: "paragon-projects",
      tag: (role === 11 ? 0 : data.data[0].cn) + data2.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: "request-ti",
    tag: data[0].counter,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};
const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "paragon-recruitment-process",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const getPendingMailNewEmployees = async () => {
  const data = await HelpDeskService.getPendingMailNewEmployees();
  if (data.data > 0) {
    const payload = {
      routeName: "new-employees",
      tag: data.data,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({
    user_id,
    moduleId,
  });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: "paragon-customer-tickets",
      tag: data[0].v_total_open,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const returnedLettersCounter = async () => {
  const { data } =
    await LettersRoundService.getCounterReturnedLettersDepartment({
      moduleId: 10,
      isConnection: false,
    });

  // const value = data > 99 ? "99+" : data;

  const payload = {
    routeName: "pr-letters-dispute",
    tag: data,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const softProjects = async () => {
  const { user_id } = store.state.auth.currentUser;
  const { data } = await PGRequestActionsService.show(user_id);
  const payload = {
    routeName: "paragon-soft",
    tag: data[0].total,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const allCountersParagon = async () => {
  Promise.all([
    salesMadeCounter("sales-made-paragon"),
    projectsCounter(),
    paragonCounter(),
    chargebackCounter(),
    employeeClaims("paragon-employee-claims"),
    requestNotAsignedCounter(),
    applicantsCounter(),
    getPendingMailNewEmployees(),
    designRequestCounterParagon(),
    getPendinCustomerTickets(),
    returnedLettersCounter(),
    softProjects(),
  ]);
};

export default allCountersParagon;
