const { amgApi } = require("@/service/axios");

class NewItems {
  async getNewItemsCounter(params) {
    try {
      const data = await amgApi.post(
        `request-ncr/new-items/get-counter`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default new NewItems();
