export const saleMadeTabs = (abv, permitted_roles = null) => {
  return [
    {
      path: "leads",
      name: `sales-made-${abv}-lead`,
      redirect: { name: `sales-made-${abv}-lead-pending` },
      component: () =>
        import(
          /* webpackChunkName: "NewClientComponent" */ "@/views/crm/views/sales-made/components/NewClientComponentNew.vue"
        ),
      meta: {
        pageTitle: "Sales Made",
        breadcrumb: [
          {
            text: "Leads",
            active: true,
          },
        ],
        permittedRoles: permitted_roles,
        routeLeads: `sales-made-${abv}-lead`,
        routePendingLead: `sales-made-${abv}-lead-pending`,
        routeForApprovedLead: `sales-made-${abv}-lead-for-approved`,
        routeDoneLead: `sales-made-${abv}-lead-done`,
      },
      children: [
        {
          path: `pending`,
          component: () =>
            import(
              /* webpackChunkName: "SalesMadeAddChange" */ "@/views/crm/views/sales-made/components/new-client/SalesMadeNewClientComponent.vue"
            ),
          name: `sales-made-${abv}-lead-pending`,
          meta: {
            permittedRoles: permitted_roles,
            status: 1,
            tabTitle: "Pending",
            pageTitle: "Sales made",
            module: 2,
            breadcrumb: [
              {
                text: "Pending",
                active: true,
              },
            ],
          },
        },
        {
          path: `for-approved`,
          component: () =>
            import(
              /* webpackChunkName: "SalesMadeAddChange" */ "@/views/crm/views/sales-made/components/new-client/SalesMadeNewClientComponent.vue"
            ),
          name: `sales-made-${abv}-lead-for-approved`,
          meta: {
            permittedRoles: permitted_roles,
            status: 2,
            tabTitle: "For Approval",
            pageTitle: "Sales made",
            module: 2,
            breadcrumb: [
              {
                text: "For Approval",
                active: true,
              },
            ],
          },
        },
        {
          path: `done`,
          component: () =>
            import(
              /* webpackChunkName: "SalesMadeAddChange" */ "@/views/crm/views/sales-made/components/new-client/SalesMadeNewClientComponent.vue"
            ),
          name: `sales-made-${abv}-lead-done`,
          meta: {
            permittedRoles: permitted_roles,
            status: 3,
            tabTitle: "Done",
            pageTitle: "Sales made",
            module: 2,
            breadcrumb: [
              {
                text: "Done",
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: "client",
      name: `sales-made-${abv}-client`,
      redirect: { name: `sales-made-${abv}-client-pending` },
      component: () =>
        import(
          /* webpackChunkName: "NewClientComponent" */ "@/views/crm/views/sales-made/components/AddChangeComponent.vue"
        ),

      meta: {
        pageTitle: "Sales Made",
        breadcrumb: [
          {
            text: "Clients",
            active: true,
          },
        ],
        permittedRoles: permitted_roles,
        routeClient: `sales-made-${abv}-client`,
        routePendingClient: `sales-made-${abv}-client-pending`,
        routeForApprovedClient: `sales-made-${abv}-client-for-approved`,
        routeForApprovedClientInProcess: `sales-made-${abv}-client-for-approved-in-process`,
        routeForApprovedClientCompleted: `sales-made-${abv}-client-for-approved-completed`,
        routeDoneClient: `sales-made-${abv}-client-done`,
      },
      children: [
        {
          path: `pending`,
          component: () =>
            import(
              /* webpackChunkName: "SalesMadeAddChange" */ "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue"
            ),
          name: `sales-made-${abv}-client-pending`,
          meta: {
            permittedRoles: permitted_roles,
            status: 1,
            tabTitle: "Pending",
            pageTitle: "Sales made",
            breadcrumb: [
              {
                text: "Pending",
                active: true,
              },
            ],
            routeClient: `sales-made-${abv}-client`,
            routePendingClient: `sales-made-${abv}-client-pending`,
            routeForApprovedClient: `sales-made-${abv}-client-for-approved`,
            routeDoneClient: `sales-made-${abv}-client-done`,
          },
        },
        {
          path: `for-approved`,
          component: () =>
            import(
              /* webpackChunkName: "SalesMadeAddChange" */ "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue"
            ),
          name: `sales-made-${abv}-client-for-approved`,
          meta: {
            permittedRoles: permitted_roles,
            status: 2,
            tabTitle: "For Approval",
            pageTitle: "Sales made",
            breadcrumb: [
              {
                text: "For Approval",
                active: true,
              },
            ],
            routeClient: `sales-made-${abv}-client`,
            routePendingClient: `sales-made-${abv}-client-pending`,
            routeForApprovedClient: `sales-made-${abv}-client-for-approved`,
            routeDoneClient: `sales-made-${abv}-client-done`,
          },
        },
        {
          path: `done`,
          component: () =>
            import(
              /* webpackChunkName: "SalesMadeAddChange" */ "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue"
            ),
          name: `sales-made-${abv}-client-done`,
          meta: {
            permittedRoles: permitted_roles,
            status: 4,
            tabTitle: "Done",
            pageTitle: "Sales made",
            breadcrumb: [
              {
                text: "Done",
                active: true,
              },
            ],
            routeClient: `sales-made-${abv}-client`,
            routePendingClient: `sales-made-${abv}-client-pending`,
            routeForApprovedClient: `sales-made-${abv}-client-for-approved`,
            routeDoneClient: `sales-made-${abv}-client-done`,
          },
        },
      ],
    },
  ];
};
