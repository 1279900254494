export default [
  {
    path: "update-request-origin",
    name: "boost-credit-dispute-results-origin",
    component: () =>
      import(
        /* webpackChunkName: "BCDisputeResults" */ "@/views/boost-credit/views/dispute-results/views/DisputeResultsOrigin.vue"
      ),
    redirect: { name: "bc-dispute-results-in-process-origin" },
    meta: {
      permittedRoles: [1, 2, 3],
      pageTitle: "Update Request",
    },
    children: [
      {
        path: "in-process",
        name: "bc-dispute-results-in-process-origin",
        component: () =>
          import(
            /* webpackChunkName: "BCDisputeResultsInProcess" */ "@/views/commons/components/dispute-results/views/components/TableInProcess.vue"
          ),
        meta: {
          status: 0,
          permittedRoles: [1, 2, 3],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "In Process",
              active: true,
            },
          ],
        },
      },
      {
        path: "completed",
        name: "bc-dispute-results-completed-origin",
        component: () =>
          import(
            /* webpackChunkName: "BCDisputeResultsCompleted" */ "@/views/commons/components/dispute-results/views/components/TableCompleted.vue"
          ),
        meta: {
          status: 1,
          permittedRoles: [1, 2, 3],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "update-request",
    name: "boost-credit-dispute-results",
    component: () =>
      import(
        /* webpackChunkName: "BCDisputeResults" */ "@/views/boost-credit/views/dispute-results/views/DisputeResults.vue"
      ),
    redirect: { name: "bc-dispute-results-in-process" },
    meta: {
      permittedRoles: [1, 2, 3],
      pageTitle: "Update Request",
    },
    children: [
      {
        path: "in-process",
        name: "bc-dispute-results-in-process",
        component: () =>
          import(
            /* webpackChunkName: "BCDisputeResultsInProcess" */ "@/views/commons/components/dispute-results/views/components/tabs/TableInProcess.vue"
          ),
        meta: {
          status: 0,
          permittedRoles: [1, 2, 3],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "In Process",
              active: true,
            },
          ],
          tab: 'IN PROCESS'
        },
      },
      {
        path: "completed",
        name: "bc-dispute-results-completed",
        component: () =>
          import(
            /* webpackChunkName: "BCDisputeResultsCompleted" */ "@/views/commons/components/dispute-results/views/components/tabs/TableCompleted.vue"
          ),
        meta: {
          status: 1,
          permittedRoles: [1, 2, 3],
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
          tab: 'COMPLETED'
        },
      },
    ],
  },
]
