export default [
  {
    path: "schedules",
    name: "schedules-rrhh",
    redirect: { name: "schedules-tab-rrhh" },
    component: () => import(/* webpackChunkName: "RrhhSchedulesMain" */ "@/views/commons/components/schedules/SchedulesComponent.vue"),
    children: [
      {
        path: "schedules",
        name: "schedules-tab-rrhh",
        component: () => import(/* webpackChunkName: "RrhhSchedules" */ "@/views/commons/components/schedules/SchedulesTab.vue"),
        meta: {
          route: "rrhh",

          permittedRoles: [1, 2, 3],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Schedules",
              active: true,
            },
          ],
        },
      },
      {
        path: "report",
        name: "report-rrhh",
        component: () => import(/* webpackChunkName: "RrhhSchedulesReport" */ "@/views/commons/components/schedules/SchedulesReport.vue"),
        meta: {
          route: "rrhh",
          permittedRoles: [1, 2, 3],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Reports By Module",
              active: true,
            },
          ],
        },
      },
      {
        path: "report-by-user",
        name: "report-by-user-rrhh",
        component: () => import(/* webpackChunkName: "RrhhSchedulesReportByUser" */ "@/views/commons/components/schedules/SchedulesReportByUser.vue"),
        meta: {

          route: "rrhh",
          permittedRoles: [1, 2, 3],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Report by User",
              active: true,
            },
          ],
        },
      },
      {
        path: "overtime",
        name: "overtime-rrhh",
        component: () => import(/* webpackChunkName: "RrhhSchedulesOvertime" */ "@/views/commons/components/schedules/SchedulesOvertime.vue"),
        meta: {
          tag: 1,
          route: "rrhh",
          permittedRoles: [1, 2, 3],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Overtime",
              active: true,
            },
          ],
        },
      },
      {
        path: "justifications",
        name: "justifications-rrhh",
        component: () => import(/* webpackChunkName: "RrhhSchedulesJustifications" */ "@/views/commons/components/schedules/SchedulesJustifications.vue"),
        meta: {
          tag: 2,
          route: "rrhh",
          permittedRoles: [1, 2, 3],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Justifications",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      route: "rrhh",
      permittedRoles: [1, 2, 3],
    },
  },
];
