import Vue from "vue";
import Vuex from "vuex";

import SendingToCreditorsStore from "@/views/correspondence/views/sending-creditors/store/sending-to-creditors.store.js";
import LettersStore from "@/views/correspondence/views/letters/store/index.js";
import SendingReportStore from "@/views/correspondence/views/sending-reports/store/sending-report.store.js"

Vue.use(Vuex);

export default {
  SendingToCreditorsStore: SendingToCreditorsStore,
  CorrespondenceLettersStore: LettersStore,
  SendingReportStore:SendingReportStore
};
