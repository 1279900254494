<template>
  <b-modal
    v-model="showModal"
    size="xl"
    title-class="h3 font-weight-bolder text-white"
    title="Search Global Customer Tickets"
    hide-footer
    centered
    @hidden="closeModal"
  >
    <grid-customer-tickets
      ref="searchGlobalticket"
      :search-global="true"
      :search="search"
      @closeModal="closeModal"
    />
  </b-modal>
</template>
<script>
import GridCustomerTickets from "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue";

export default {
  components: {
    GridCustomerTickets,
  },
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {},
  mounted() {
    this.showModal = true;
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
