<template>
  <b-modal
    v-model="showModal"
    @hidden="$emit('hidden')"
    size="caro"
    title="Lead Information"
    hide-footer
  >
    <div v-if="leadInformation">
      <b-row>
        <b-col cols="4">
          <div class="pl-2">
            <h4
              class="font-weight-bolder pt-2 pb-1 px-1 mb-2 border-bottom-secondary border-2"
            >
              Relevant Information
            </h4>
            <detail-component class="px-2" :details="details" />
          </div>
        </b-col>

        <b-col cols="8">
          <card-lead-credit-report :lead="leadInformation" :isClientDashboard="true" />
        </b-col>

        <b-col cols="6">
          <card-lead-notes
            :modul="moduleId"
            :only-read="true"
            :lead="leadInformation"
          />
        </b-col>

        <b-col cols="6">
          <card-lead-appointment
            :modul="moduleId"
            :only-read="true"
            :lead="leadInformation"
            :isBusy="isBusyAppointment"
          />
        </b-col>

        <b-col cols="10">
          <div class="pl-2">
            <h4
              class="font-weight-bolder pt-2 pb-1 px-1 mb-2 border-bottom-secondary border-2"
            >
              Call Log
            </h4>
            <client-call-log
              class="px-1"
              :leadId="leadInformation.id"
              :double-paginate="false"
              :tab-conversation="true"
            ></client-call-log>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import LeadService from "@/views/crm/services/lead/index.js";

import DetailComponent from "@/views/crm/views/Lead/components/DetailComponent.vue";
import CardLeadCreditReport from "@/views/crm/views/Lead/lead-module/dashboard/CardLeadCreditReport.vue";
import CardLeadNotes from "@/views/crm/views/Lead/lead-module/dashboard/CardLeadNotes.vue";
import CardLeadAppointment from "@/views/crm/views/Lead/lead-module/dashboard/CardLeadAppointment.vue";
import ClientCallLog from "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue";

export default {
  components: {
    DetailComponent,
    CardLeadCreditReport,
    CardLeadNotes,
    CardLeadAppointment,
    ClientCallLog,
  },
  props: {
    leadId: {
      type: [String, Number],
      required: true,
    },

    moduleId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      showModal: true,

      leadInformation: null,
      details: [],
      isBusyAppointment: true,
    };
  },

  methods: {
    async getLeadInformation() {
      try {
        const data = await LeadService.getLead(this.leadId);
        if (data && data.length > 0) {
          this.leadInformation = data[0];
          this.setDetails();
          this.isBusyAppointment = false;
        } else {
          this.showInfoSwal("This lead not available");
          this.$emit("hidden");
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    setDetails() {
      this.details = [
        {
          label: "Phone:",
          value: this.leadInformation.mobile,
        },
        [
          this.leadInformation.ssn
            ? {
                label: "SSN:",
                value: `XXX-XX-${this.leadInformation.ssn}`,
              }
            : null,
          this.leadInformation.itin
            ? {
                label: "ITIN:",
                value: `XXX-XX-${this.leadInformation.itin}`,
              }
            : null,
          this.leadInformation.cpn
            ? {
                label: "CPN:",
                value: `XXX-XX-${this.leadInformation.cpn}`,
              }
            : null,
        ],
        this.moduleId !== 15
          ? {
              label: "DOB:",
              value: this.leadInformation.dob
                ? this.$moment(this.leadInformation.dob).format("MM/DD/YYYY")
                : "",
            }
          : null,
        {
          label: "Language:",
          value: this.leadInformation.language === "en" ? "English" : "Spanish",
        },
        {
          label: "Origin Country:",
          value: this.leadInformation.origin_country,
        },
        {
          label: "Programs:",
          value: this.leadInformation.name_programs,
        },

        {
          label: "Status:",
          value:
            this.moduleId !== 15
              ? this.leadInformation.lead_status
              : this.leadInformation.valuestatuslead,
        },
        {
          label: "Lead Owner:",
          value: this.leadInformation.user_owner,
        },
        this.moduleId !== 15
          ? {
              label: "Address:",
              value: this.longAddress,
            }
          : null,
        this.moduleId === 2
          ? {
              label: "Work Status:",
              value: this.leadInformation.work_status_text,
            }
          : null,
        this.moduleId === 15
          ? {
              label: "CATCHER:",
              value: this.leadInformation.user_catcher,
            }
          : null,
        this.moduleId === 15
          ? {
              label: "FAN PAGE:",
              value: this.leadInformation.fanpage,
            }
          : null,
        this.moduleId === 15
          ? {
              label: "CREATED DATE:",
              value: this.leadInformation.created_at,
            }
          : null,
        {
          label: "E-mail:",
          value: this.leadInformation.email,
        },
      ];
    },
  },

  created() {
    this.getLeadInformation();
  },
};
</script>

<style>
</style>