import store from "@/store";

import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js";
import RequestWorkplanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";
import DisputeResultsService from "@/views/commons/components/dispute-results/services/dispute-results.service";
import LettersRoundService from "@/views/specialists/sub-modules/analyst-department/views/letter-round/services/letters-rounds.service.js";

const requestNcrCounter = async () => {
  const { pending_ncr_client, pending_ncr_lead } = await NcrRequestService.getCountersNcr({});

  const sideCounter = pending_ncr_client + pending_ncr_lead;

  const payload = {
    routeName: "request-ncr-analyst-department",
    tag: sideCounter > 99 ? "+99" : sideCounter,
  }

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
}

const requestWpCounter = async () => {
  const { pending_wp, to_revision_wp } = await RequestWorkplanService.getCounters({
    user_id: store.state.auth.currentUser.user_id,
    role_id: store.state.auth.currentUser.role_id
  });

  const sideCounter = pending_wp + to_revision_wp;

  const payload = {
    routeName: "request-workplan-analyst-department",
    tag: sideCounter > 99 ? "+99" : sideCounter,
  }

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
}

const updateRequestCounter = async () => {
  const { in_process_ur } = await DisputeResultsService.getCounterUpdateRequest({
    user_id: store.state.auth.currentUser.user_id,
    role_id: store.state.auth.currentUser.role_id
  });
  const sideCounter = in_process_ur;

  const payload = {
    routeName: "update-request-analyst-department",
    tag: sideCounter > 99 ? "+99" : sideCounter,
  }

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
}

const DisputeCounter = async () => {
  // directs counter
  /* code here */
  const cLetter = 0;

  // letters counter
  const { data: cPending } = await LettersRoundService.getCounterPendingRoundLetters();

  const payloadDirect = {
    routeName: "specialists-direct-round",
    tag: cLetter > 99 ? "99+" : cLetter,
  };

  const payloadLetter = {
    routeName: "analyst-department-letter-round",
    tag: cPending > 99 ? "99+" : cPending,
  };

  // const payloadDispute = {
  //   routeName: "analyst-department-dispute",
  //   tag: (cPending + cLetter) > 99 ? "99+" : (cPending + cLetter),
  // };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payloadDirect);
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payloadLetter);
  // store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payloadDispute);
}

const allCounterAnalystDepartment = async () => {
  Promise.all([
    requestNcrCounter(),
    requestWpCounter(),
    DisputeCounter(),
    updateRequestCounter(),
  ])
}
export default allCounterAnalystDepartment
