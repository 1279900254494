import { amgApi } from '@/service/axios';

class ChatTransactions {
  async createTransactionMessages(params) {
    try {
      const data = await amgApi.post('/commons/transaction-binnacle/messages/store', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTransactionMessages(params) {
    try {
        const { data } = await amgApi.post('/commons/transaction-binnacle/messages/index', params);
        return data;
    } catch (err) {
        throw error;
    }
  }

  async searchMember(params) {
    try {
        const { data } = await amgApi.post('', params);
        return data;
    } catch (err) {
        throw error;
    }
  }

  async addMember(params) {
    try {
        const { data } = await amgApi.post('', params);
        return data;
    } catch (err) {
        throw error;
    }
  }

  async getCurrentBinnacleHeader(params) {
    try {
      const { data } = await amgApi.post("/commons/transaction-binnacle/show", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
 
  async getBinnacleMembers(params) {
    try {
      const { data } = await amgApi.post("/commons/transaction-binnacle/members/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createTransactionBinnacle(params) {
    try {
      const { data } = await amgApi.post("/commons/transaction-binnacle/index", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

}

export default new ChatTransactions();
