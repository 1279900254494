import { amgApi } from "@/service/axios";

class QuestionnnaireService {
  async questionnnairePdf(params) {
    try {
      const data = await amgApi.post(
        "/sales-made/debt-solution/questionnnaire-pdf",
        params,
        { responseType: "blob" }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async financialAnalysisPdf(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/get-financial-analysis",
        params,
        { responseType: "blob" }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getFinancialAnalysisData(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/get-financial-analysis-data",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async checkSMSExists(params) {
    try {
      const data = await amgApi.post("/debt-solution/check-sms-exists", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async printClientAnalysis(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/print-client-analysis",
        params,
        { responseType: "blob" }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async printEventAnalysis(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/print-event-analysis",
        params,
        { responseType: "blob" }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new QuestionnnaireService();
