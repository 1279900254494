<template>
  <b-button
    variant="primary"
    @click="$emit('click')"
    class="custom-button"
  >
    Save
  </b-button>
</template>

<script>
export default {
  name: 'ButtonSave',
}
</script>

<style scoped>
.custom-button {
  background-color: #3f7afa !important;
  color: white !important;
}
</style>
