<template>
  <validation-observer ref="form">
    <b-modal
      id="crm-sm-modal-contract-fee"
      v-model="ownControl"
      footer-class="px-50"
      title-class="h3 text-white font-weight-bolder"
      size="fmd"
      modal-class="custom-modal-amg"
      header-bg-variant="transparent"
      header-class="p-0"
      title="CONTRACT FEE"
      body-class="overflow-y-visible"
      scrollable
      :hide-footer="valorEdit"
      @hide="closeModal(false)"
      no-close-on-esc
      no-close-on-backdrop
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>Contract Fee</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModal(false)"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <b-container class="ml-1 pr-3">
        <b-row class="client-info mx-0 my-1">
          <b-col cols="6" class="p-0">
            <div
              class="mr-1"
              :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
            >
              <p>Client | {{ contractFee.clientName }}</p>
            </div>
          </b-col>
          <b-col cols="6" class="p-0">
            <div
              class="ml-1"
              :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
            >
              <p>Service | {{ contractFee.programName }}</p>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-row>
              <b-col class="d-flex align-items-center">
                <span>Fee:</span>
              </b-col>
              <b-col class="d-flex align-items-center">
                <span>$</span>
                <money
                  v-model="fee"
                  class="form-control border-0 text-right"
                  v-bind="{ precision: 2 }"
                  disabled
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row
          class="border rounded p-1 m-0"
          :style="
            isDarkSkin
              ? { backgroundColor: '#2e2e2e' }
              : { backgroundColor: '#f8f9fa' }
          "
        >
          <b-col
            cols="4"
            class="d-flex align-items-center justify-content-center gap-10"
            style="border-right: 1px solid #6c757d"
          >
            <span style="font-size: 16px">Fee</span>
            <div style="padding: 10px" class="border rounded">
              $ {{ fee | currency }}
            </div>
          </b-col>
          <b-col
            cols="4"
            class="d-flex align-items-center justify-content-center gap-10"
            style="border-right: 1px solid #6c757d"
          >
            <span style="font-size: 16px">Initial Payment</span>
            <div style="padding: 10px" class="border rounded">
              $ {{ initialPayment | currency }}
            </div>
          </b-col>
          <b-col
            cols="4"
            class="d-flex align-items-center justify-content-center gap-10"
          >
            <span style="font-size: 16px">Per pay</span>
            <div style="padding: 10px" class="border rounded">
              $ {{ perPay | currency }}
            </div>
          </b-col>
        </b-row>

        <div class="d-flex gap-15 mt-1 flex-wrap">
          <div class="d-flex align-items-center gap-10">
            <b-form-group label-for="type-payment">
              <template #label>
                <span style="font-size: 15px">Payment </span>
              </template>
              <div class="d-flex align-items-center justify-content-between">
                <b-form-radio-group
                  id="type-payment-radio-box"
                  v-model="typePayment"
                  @input="variantPayment"
                  :options="[
                    { text: 'Single', value: 0 },
                    { text: 'Multiple', value: 1 },
                  ]"
                  :disabled="valorEdit || contractSale.program_id == 2"
                  class="p-1 border rounded"
                />
              </div>
            </b-form-group>
          </div>
          <div v-if="typePayment == 0">
            <b-form-group label-for="monthly-payment">
              <template #label>
                <span style="font-size: 15px">Amount</span>
              </template>
              <validation-provider
                v-slot="{ errors }"
                name="monthlyAmount"
                rules="required|validate-amount"
              >
                <div
                  class="d-flex align-items-center justify-content-between px-1 border rounded"
                  style="height: 48px !important; width: 165px !important"
                  :class="{
                    'border-danger  text-danger':
                      ((errors[0] && monthlyPaymentController) ||
                        monthlyAmount == 0) &&
                      contractSale.program_id != 4,
                    'border-success  text-success':
                      monthlyAmount > 0 && contractSale.program_id != 4,
                  }"
                >
                  <span> $</span>
                  <money
                    v-model="monthlyAmount"
                    class="form-control border-0 text-right"
                    style="font-size: 15px !important"
                    :class="{
                      'text-danger':
                        monthlyAmount == 0 && contractSale.program_id != 4,
                      'text-success':
                        monthlyAmount > 0 && contractSale.program_id != 4,
                    }"
                    v-bind="{ precision: 2 }"
                    :disabled="
                      hasMembership || contractSale.program_id == 4 || valorEdit
                    "
                    @change.native="monthlyPaymentController = true"
                  />
                </div>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="d-flex align-items-center gap-10">
            <b-form-group label-for="method-of-payment">
              <template #label>
                <span style="font-size: 15px">Method</span>
              </template>
              <div class="d-flex align-items-center justify-content-between">
                <b-form-radio-group
                  id="credit-card-radio-box"
                  v-model="methodPayment"
                  :options="[
                    { text: 'Credit Card', value: 0 },
                    { text: 'Others', value: 1 },
                  ]"
                  :disabled="valorEdit || typePayment == 1"
                  class="p-1 border rounded"
                  :class="
                    methodPayment == null && contractSale.program_id != 4
                      ? 'border-danger'
                      : ''
                  "
                />
              </div>
            </b-form-group>
          </div>

          <div
            v-if="methodPayment == 0"
            class="d-flex align-items-center gap-10"
          >
            <b-form-group label-for="type">
              <template #label>
                <span style="font-size: 15px">Type</span>
              </template>
              <div class="d-flex align-items-center justify-content-between">
                <b-form-radio-group
                  id="type-card-radio-box"
                  v-model="cardType"
                  :options="[
                    { text: 'Automatic', value: 0 },
                    { text: 'Manual', value: 1 },
                  ]"
                  class="p-1 border rounded"
                  :disabled="valorEdit || typePayment == 1"
                  :class="cardType == null ? 'border-danger rounded' : ''"
                />
              </div>
            </b-form-group>
          </div>
          <div
            v-if="cardType == 0 && typePayment == 1"
            class="d-flex align-items-center gap-10"
          >
            <b-form-group label-for="type">
              <template #label>
                <span style="font-size: 15px">Quantity</span>
              </template>
              <div class="d-flex align-items-center justify-content-between">
                <b-form-select
                  id="select-date-contract-fee"
                  v-model="quantity"
                  :disabled="valorEdit"
                  @change="addItemToPaymentSchedule()"
                  style="
                    height: 48px !important;
                    width: 65px !important;
                    font-size: 14px;
                  "
                  size="sm"
                >
                  <b-form-select-option value="2"> 2 </b-form-select-option>
                  <b-form-select-option value="3"> 3 </b-form-select-option>
                </b-form-select>
              </div>
            </b-form-group>
          </div>
        </div>

        <div
          class="d-flex gap-15 my-1 flex-wrap"
          v-if="cardType == 0 && methodPayment == 0 && !typePayment"
        >
          <div class="d-flex align-items-center gap-10">
            <b-form-group label-for="method-of-payment">
              <template #label>
                <span style="font-size: 15px">Start Date</span>
              </template>
              <div
                class="d-flex align-items-center justify-content-between gap-5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="dayCfee"
                  rules="required"
                >
                  <b-form-select
                    id="select-date-contract-fee"
                    v-model="dayCFee"
                    :options="paymentDays"
                    :disabled="valorEdit"
                    text-field="day"
                    :class="{ 'border-danger rounded': errors[0] }"
                    value-field="day"
                    style="
                      height: 36px !important;
                      width: 65px !important;
                      font-size: 14px;
                    "
                    size="sm"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="monthCfee"
                  style="margin-right: 2px; margin-left: 2px"
                  rules="required"
                >
                  <b-form-select
                    v-model="monthCFee"
                    size="sm"
                    :class="{ 'border-danger rounded': errors[0] }"
                    style="
                      height: 36px !important;
                      width: 75px !important;
                      font-size: 14px;
                    "
                    :disabled="valorEdit"
                  >
                    <b-form-select-option value="1"> Jan </b-form-select-option>
                    <b-form-select-option value="2"> Feb </b-form-select-option>
                    <b-form-select-option value="3"> Mar </b-form-select-option>
                    <b-form-select-option value="4"> Apr </b-form-select-option>
                    <b-form-select-option value="5"> May </b-form-select-option>
                    <b-form-select-option value="6"> Jun </b-form-select-option>
                    <b-form-select-option value="7"> Jul </b-form-select-option>
                    <b-form-select-option value="8"> Aug </b-form-select-option>
                    <b-form-select-option value="9"> Sep </b-form-select-option>
                    <b-form-select-option value="10">
                      Oct
                    </b-form-select-option>
                    <b-form-select-option value="11">
                      Nov
                    </b-form-select-option>
                    <b-form-select-option value="12">
                      Dec
                    </b-form-select-option>
                  </b-form-select>
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  name="yearCfee"
                  rules="required"
                >
                  <b-form-select
                    v-model="yearCFee"
                    :options="years"
                    :disabled="valorEdit"
                    style="
                      height: 36px !important;
                      width: 75px !important;
                      font-size: 14px;
                    "
                    size="sm"
                    :class="{ 'border-danger rounded': errors[0] }"
                  />
                </validation-provider>
              </div>
            </b-form-group>
          </div>
        </div>
      </b-container>
      <div
        v-if="cardType == 0 && methodPayment == 0 && !typePayment"
        class="mt-1"
      >
        <b-table
          :items="cards"
          :fields="fieldsT1"
          size="sm"
          class="position-relative table-new-customization"
          sticky-header="30vh"
          show-empty
          responsive
        >
          <template v-slot:cell(select)="data">
            <b-form-radio
              id="select-card-radio-box"
              v-model="cardId"
              :value="data.item.id"
              :disabled="valorEdit"
              plain
            />
          </template>
          <template v-slot:cell(type_card)="data">
            <type-card :type_card="data.item.type_card" />
          </template>
        </b-table>
      </div>
      <div class="table-new-customization overflow-y-visible">
        <b-table-simple
          style="table-layout: fixed; width: 100% !important"
          class="position-relative mb-2 w-full"
          v-if="cardType == 0 && methodPayment == 0 && typePayment"
        >
          <b-thead>
            <b-tr>
              <b-th
                class="text-center"
                v-for="(field, index) in fields"
                :key="index"
                :style="`width: ${field.width}`"
              >
                {{ field.label }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="paymentSchedule.length > 0">
              <b-tr v-for="(item, index) in paymentSchedule" :key="index">
                <b-td>
                  <b-form-radio-group
                    :id="`current-payment-radio-box-${index}`"
                    v-if="!valorEdit"
                    v-model="item.current"
                    :options="[
                      { text: 'Current', value: 1 },
                      { text: 'Next', value: 0 },
                    ]"
                    class="p-1 border rounded"
                  />
                  <div
                    class="text-center text-warning"
                    style="margin-top: 5px; font-style: italic"
                  >
                    {{
                      $moment(
                        new Date().getMonth() + 1 + (item.current == 1 ? 0 : 1),
                        "MM"
                      ).format("MMM")
                    }}
                  </div>
                </b-td>
                <b-td>
                  <span class="text-danger" v-if="mustPassSevenDays(item)">
                    Must pass 7 days
                  </span>
                  <v-select
                    v-model="item.date"
                    :options="daysOfPayment"
                    :reduce="(option) => option.day"
                    label="day"
                    :disabled="valorEdit"
                    :clearable="false"
                    class="w-100 text-center"
                    :class="!item.date ? 'border-danger rounded' : ''"
                  />
                </b-td>
                <b-td>
                  <v-select
                    v-model="item.ocurrences"
                    :options="[
                      { text: 'Automatic', value: 9999 },
                      { text: '1', value: 1 },
                      { text: '2', value: 2 },
                      { text: '3', value: 3 },
                      { text: '4', value: 4 },
                      { text: '5', value: 5 },
                      { text: '6', value: 6 },
                      { text: '7', value: 7 },
                      { text: '8', value: 8 },
                      { text: '9', value: 9 },
                      { text: '10', value: 10 },
                    ]"
                    :clearable="false"
                    label="text"
                    class="w-100 text-center"
                    :reduce="(option) => option.value"
                    :disabled="valorEdit"
                  />
                </b-td>
                <b-td class="text-center">
                  <div
                    class="d-flex align-items-center justify-content-between rounded pl-1"
                    :class="
                      item.amount <= 0 ? 'border-danger' : 'border-success'
                    "
                  >
                    <span> $</span>
                    <money
                      v-model="item.amount"
                      :disabled="valorEdit"
                      v-bind="moneyConfig"
                      class="form-control border-0 text-right px-2"
                    />
                  </div>
                </b-td>
                <b-td class="text-center">
                  <v-select
                    :class="!item.card_id ? 'border-danger rounded' : ''"
                    label="cardholdername"
                    v-model="item.card_id"
                    :reduce="(card) => card.id"
                    :options="cards"
                    :selectable="isCardSelectable"
                    :clearable="false"
                    class="w-100"
                    :disabled="valorEdit"
                  >
                    <template
                      #option="{
                        cardholdername,
                        cardnumber,
                        card_expi_month,
                        card_expi_year,
                      }"
                    >
                      <b-row
                        class="d-flex align-items-center justify-content-between"
                      >
                        <b-col cols="6">
                          <span>Card:</span>
                        </b-col>
                        <b-col cols="6">
                          <span>{{ cardHolderName(cardholdername) }}</span>
                        </b-col>
                        <b-col cols="6">
                          <span>Number:</span>
                        </b-col>
                        <b-col cols="6">
                          <span>{{ "XXXX-" + cardnumber }}</span>
                        </b-col>
                        <b-col cols="6">
                          <span>Exp. Date:</span>
                        </b-col>
                        <b-col cols="6">
                          <span>{{
                            card_expi_month + "/" + card_expi_year
                          }}</span>
                        </b-col>
                      </b-row>
                    </template>

                    <template #selected-option="{ cardholdername, cardnumber }">
                      <div class="text-center">
                        <div
                          style="height: 26.88px !important"
                          class="d-flex align-items-center justify-content-between gap-10"
                        >
                          <div>
                            {{ cardHolderName(cardholdername) }}
                          </div>
                          |
                          <div>
                            {{ cardnumber }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </v-select>
                </b-td>
                <!-- <b-td class="text-center" v-if="!valorEdit">
                  <feather-icon
                    class="text-danger cursor-pointer"
                    icon="TrashIcon"
                    size="20"
                    @click="removeItemFromPaymentSchedule(index)"
                  />
                </b-td> -->
              </b-tr>
            </template>
            <b-tr class="b-table-bottom-row">
              <b-th class="fill-bottom-row" colspan="2" />
              <b-th>
                <b-badge
                  variant="primary"
                  class="w-100 text-center"
                  style="font-size: 14px; font-weight: 600"
                >
                  AMOUNT
                </b-badge>
              </b-th>
              <b-th>
                <b-badge
                  variant="primary"
                  class="w-100 text-center"
                  style="font-size: 14px; font-weight: 600"
                >
                  $ {{ newMonthlyAmount | currency }}
                  <span
                    :class="{
                      'text-danger': monthlyAmount != newMonthlyAmount,
                    }"
                    style="text-shadow: 0 0 2px #fff"
                    v-if="
                      contractSale.program_id == 4 &&
                      monthlyAmount != newMonthlyAmount
                    "
                  >
                    ({{ monthlyAmount | currency }})
                  </span>
                </b-badge>
              </b-th>
              <b-th class="fill-bottom-row" colspan="1" />
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <template #modal-footer>
        <b-row class="w-100 p-0">
          <b-col class="d-flex align-items-center justify-content-start p-0">
            <b-button
              v-if="!valorEdit"
              class="mr-1"
              variant="success"
              size="sm"
              @click="addCardModal = true"
            >
              ADD CREDIT CARD
            </b-button>
          </b-col>
          <!-- :disabled="!cardId && methodPayment === 0 && cardType === 0" -->
          <b-col class="d-flex align-items-center justify-content-end p-0">
            <b-button
              v-if="typePayment == 1"
              id="submit-contract-fee"
              size="sm"
              @click="saveContract"
              :variant="
                validationSubmitMultiple ? 'outline-primary' : 'primary'
              "
              :disabled="validationSubmitMultiple"
            >
              SAVE
            </b-button>
            <b-button
              v-else
              id="submit-contract-fee"
              size="sm"
              :variant="validationSubmit ? 'outline-primary' : 'primary'"
              :disabled="validationSubmit"
              @click="saveContract"
            >
              SAVE
            </b-button>
          </b-col>
        </b-row>
      </template>

      <modal-card-create
        v-if="addCardModal"
        :if-modal-card="addCardModal"
        :idlead="contractFee.id"
        :session="currentUser.user_id"
        @new="addCard"
        @click="closedModalCar"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import ModalCardCreate from "@/views/crm/views/payments/components/ModalCardCreate.vue";
import ProgramClientHeader from "@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import TypeCard from "@/views/commons/card/TypeCard.vue";

export default {
  name: "ContractFeeModal",
  components: {
    ProgramClientHeader,
    ModalCardCreate,
    FeatherIcon,
    TypeCard,
  },
  props: {
    modal: {
      type: Object,
      default: () => ({
        contract_fee: false,
      }),
    },
    contractFee: {
      type: Object,
      default: () => ({
        programName: "",
        clientName: "",
        saleId: null,
        id: null,
        initialAmount: null,
        editModal: false,
        statusSale: null,
        type_payment_ce: null,
        sales: null,
      }),
    },
  },
  data() {
    return {
      moneyConfig: {
        decimal: ",",
        thousands: ".",
        // prefix: "$",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      // fields: ["Date", "Amount", "Credit Card"],
      fields: [
        { key: "month", label: "Month", width: "16%" },
        { key: "day", label: "Day", width: "15%" },
        { key: "quantity", label: "Duration", width: "22%" },
        { key: "amount", label: "Amount", width: "20%" },
        { key: "card_id", label: "Credit Card", width: "25%" },
      ],
      paymentSchedule: [],
      ownControl: false,
      cards: [],
      methodPayment: "",
      cardType: "",
      charge: false,
      daysOfPayment: [],
      contractSale: {},
      fee: 0,
      initialPayment: 0,
      perPay: 0,
      monthlyAmount: 0,
      months: 0,
      addCardModal: false,
      monthlyPaymentController: false,
      typePayment: 0,
      yearCFee: null,
      dayCFee: 5,
      monthCFee: null,
      cardId: null,
      years: [],
      fieldsT1: [
        {
          label: "",
          key: "select",
        },
        {
          label: "Card Holder Name",
          key: "cardholdername",
          thStyle: { minWidth: "174px !important" },
        },
        {
          label: "Card Number",
          key: "cardnumber",
          thStyle: { minWidth: "195px !important" },
          formatter: (value) => `XXXX-XXXX-XXXX-${value}`,
        },
        {
          label: "Type",
          key: "type_card",
        },
        {
          label: "MM",
          key: "card_expi_month",
        },
        {
          label: "YY",
          key: "card_expi_year",
        },
        {
          label: "CVC",
          key: "cardsecuritycode",
          formatter: (value) => `XX${value}`,
        },
      ],
      quantity: 2,
      newMonthlyAmount: 0,
      monthly_ds: null,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    roleSales() {
      let rolId;
      if (this.moduleId != 20) {
        const { role_id } = this.currentUser;
        rolId = role_id;
      }else{
        const { role_id } = this.currentUser;
        rolId = role_id === 2 ? 3 : role_id;
      }
      return [1, 2, 17].includes(rolId);
    },
    sellerId() {
      return this.contractFee.seller_id === this.currentUser.user_id;
    },
    hasStatus() {
      let valid;
      const { statusSale } = this.contractFee;
      const hasModify = this.roleSales ? true : this.sellerId;
      if (this.moduleId != 2) {
        valid = [1, 3, 16].includes(statusSale) && hasModify;
      } else {
        valid = [1, 14, 15].includes(statusSale) && hasModify;
      }
      return !valid;
    },
    validationSubmit() {
      let hasCardId =
        !this.cardId && this.methodPayment === 0 && this.cardType === 0;
      let hasMonthlyAmount =
        this.contractSale.program_id == 4 && this.monthlyAmount == 0;
      let noMethodPayment = this.methodPayment == null;
      let noCreditCard = this.cardType == null && this.methodPayment == 0;
      return (
        this.hasStatus ||
        hasCardId ||
        hasMonthlyAmount ||
        noMethodPayment ||
        noCreditCard
      );
    },
    validationSubmitMultiple() {
      let isDisabled = this.disabled;
      return (
        this.hasStatus ||
        isDisabled ||
        !this.validNumber ||
        !this.validateAllPassSevenDays ||
        !this.validDates ||
        !this.validCards
      );
    },
    selectedDates() {
      return this.paymentSchedule
        .filter((item) => item.date !== null)
        .map((item) => item.date);
    },
    paymentScheduleSum() {
      let sum = this.paymentSchedule.reduce(
        (acc, item) => acc + parseFloat(item.amount),
        0
      );
      let roundedSum = sum.toFixed(2);
      return parseFloat(roundedSum);
    },
    validNumber() {
      return this.paymentSchedule.every((item) => item.amount > 0);
    },
    validDates() {
      return this.paymentSchedule.every((item) => item.date !== null);
    },
    validCards() {
      return this.paymentSchedule.every((item) => item.card_id !== null);
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    valorEdit() {
      return (
        this.moduleId == 16 ||
        this.contractFee.editModal == false ||
        this.contractFee.statusSale == 2 ||
        this.contractFee.statusSale == 4 ||
        this.contractSale.st == 1
      );
    },
    hasMembership() {
      return (
        this.contractSale.program_id == 2 ||
        [2, 3].includes(this.contractFee.type_payment_ce)
      );
    },
    disabled() {
      if (this.methodPayment == null) {
        return true;
      }

      if (this.methodPayment == 0 && this.cardType == null) {
        return true;
      }
      if (
        this.methodPayment == 0 &&
        this.cardType == 0 &&
        this.paymentSchedule.length == 0
      ) {
        return true;
      }

      if (
        this.methodPayment == 0 &&
        this.cardType == 0 &&
        this.paymentSchedule.length > 0
      ) {
        let isValid = this.paymentSchedule.some(
          (item) =>
            item.date === null ||
            item.card_id === null ||
            !item.date ||
            !item.card_id
        );
        return isValid;
      }

      return false;
    },
    paymentDays() {
      if (this.contractSale.program_id == 3) {
        this.daysOfPayment[0].disabled = true;
      }
      if (this.monthCFee == 2) {
        return this.daysOfPayment.filter((d) => d.id != 6);
      }
      return this.daysOfPayment;
    },
    validateAllPassSevenDays() {
      return this.paymentSchedule.every(
        (item) => !this.mustPassSevenDays(item)
      );
    },
  },
  watch: {
    monthlyAmount(val) {
      if (val > 0 && this.cardType === 0 && this.methodPayment === 0) {
        this.calculatePaymentSchedule(this.paymentSchedule);
        this.months = Math.ceil(this.perPay / this.monthlyAmount);
      }
    },
    methodPayment(val) {
      if (val === 0 && this.paymentSchedule.length == 0) {
        this.calculatePaymentSchedule(this.paymentSchedule);
      }
    },
    // item.amount
    paymentSchedule: {
      handler() {
        this.newMonthlyAmount = this.paymentScheduleSum;
      },
      deep: true,
    },
  },
  async created() {
    try {
      this.addPreloader();
      await this.getContractSales();
      await this.getCards();
      await this.getPaymentsDays();
      this.perPay = this.fee - this.initialPayment;
      this.ownControl = true;
    } catch (error) {
      this.showErrorSwal(error);
    } finally {
      this.removePreloader();
    }
  },
  methods: {
    isCardSelectable(item) {
      let expMonth = item.card_expi_month;
      let expYear = item.card_expi_year;
      let currentDate = new Date();
      let currentMonth = currentDate.getMonth() + 1;
      let currentYear = currentDate.getFullYear();
      currentYear = currentYear.toString().substring(2, 4);
      if (currentYear > expYear) {
        return false;
      } else if (currentYear == expYear && currentMonth > expMonth) {
        return false;
      }
      return true;
    },
    cardHolderName(cardholdername) {
      if (cardholdername != null) {
        return cardholdername?.length > 8
          ? cardholdername?.substring(0, 8) + "..."
          : cardholdername;
      } else {
        return "N/A";
      }
    },
    isDateSelectable(item) {
      return !this.selectedDates.includes(item.day);
    },
    removeItemFromPaymentSchedule(index) {
      this.paymentSchedule.splice(index, 1);
      this.calculatePaymentSchedule(this.paymentSchedule);
    },
    calculatePaymentSchedule(existingData = []) {
      if (existingData.length > 0) {
        this.paymentSchedule = existingData.map((item, index) => ({
          date: item.date,
          card_id: item.card_id,
          amount: item.amount,
          month: item.month,
          year: item.year,
          current: item.current,
          ocurrences: item.ocurrences,
        }));
      }
    },
    addItemToPaymentSchedule() {
      this.paymentSchedule = [];
      for (let i = 0; i < this.quantity; i++) {
        this.paymentSchedule.push({
          date: null,
          current: 0,
          month: new Date().getMonth() + 1,
          amount: 0,
          ocurrences: 9999,
        });
      }
    },
    async insertTrackingDerivation() {
      if (this.$route.matched[0].meta.module === 26) {
        const params = {
          lead_id: this.contractFee.id,
          sale_status_id: 3,
          seller_id: this.contractFee.seller_id,
          sale_id: this.contractFee.saleId,
        };
        await DerivationsService.insertTrackingSaleStatusDerivation(params);
      }
    },
    closeModal(reload = false) {
      this.ownControl = false;
      this.$emit("close");
      if (reload) {
        this.$emit("reload");
      }
    },
    getDay(date) {
      return date.split("-")[2];
    },
    calculateMonths() {
      let calcMonths = 0;
      if (this.hasMembership && this.contractSale.program_id == 2) {
        calcMonths = 0;
      } else if (this.monthlyAmount > 0) {
        calcMonths = Math.ceil(this.perPay / this.monthlyAmount);
        calcMonths = calcMonths < 1 ? 0 : calcMonths;
      } else {
        calcMonths = 0;
      }
      return calcMonths;
    },
    async saveContract() {
      this.monthlyPaymentController = true;
      const result = await this.$refs.form.validate();

      if (!result) return;
      let monthly = this.monthlyAmount ? this.monthlyAmount.toString() : "";
      let params = {
        charge: this.charge,
        initial_amount: this.contractSale.initial_amount
          ? this.contractSale.initial_amount.toString()
          : "",
        method_payment: this.methodPayment,
        type_payment: this.methodPayment == 1 ? null : this.cardType,
        monthly_amount:
          this.newMonthlyAmount > 0 ? this.newMonthlyAmount : monthly,
        sale_id: this.contractFee.saleId,
        months: this.calculateMonths(),
        monthly_ds: this.contractSale.program_id == 4 ? this.monthly_ds : null,
      };

      if (this.methodPayment == 0 && this.cardType == 0) {
        params.payment_schedule = this.calculatePaymentScheduleDates();
        if (!params.payment_schedule) {
          return;
        }
      } else {
        params.payment_schedule = [];
      }

      try {
        this.addPreloader();
        const { data, status } = await amgApi.post(
          "/sales-made/insert-contract",
          params
        );
        if (status === 200) {
          this.insertTrackingDerivation();
          this.showSuccessSwal(data.message);
          this.closeModal(true);
        } else {
          this.showErrorSwal();
          this.closeModal(false);
        }
      } catch (error) {
        this.showErrorSwal(error);
        this.closeModal(false);
      } finally {
        this.removePreloader();
      }
    },
    async getCards() {
      try {
        this.cards = await window.amgApi.post("/clients/search-cards-clients", {
          id: this.contractFee.id,
        });
        if (this.cards.status === 200) {
          this.cards = this.cards.data;
        } else {
          this.showErrorSwal();
        }
      } catch (error) {
        console.error(error);
      }
    },
    variantPayment() {
      this.methodPayment = null;
      this.cardType = null;
      this.paymentSchedule = [];
      if (this.typePayment === 1) {
        setTimeout(() => {
          this.newMonthlyAmount = 0;
          this.methodPayment = 0;
          this.cardType = 0;
          this.addItemToPaymentSchedule();
        }, 100);
      }
    },
    calculatePaymentScheduleDates() {
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear();

      let paymentScheduleTest = [];

      if (this.paymentSchedule.length == 0) {
        let newDate = `${this.yearCFee}-${
          this.monthCFee < 10 ? "0" + this.monthCFee : this.monthCFee
        }-${this.dayCFee < 10 ? "0" + this.dayCFee : this.dayCFee}`;
        paymentScheduleTest.push({
          date: newDate,
          amount: this.monthlyAmount,
          card_id: this.cardId,
        });
      } else {
        for (let i = 0; i < this.paymentSchedule.length; i++) {
          let item = this.paymentSchedule[i];
          let additionalMonths = item.current == 1 ? 0 : 1;
          let newMonth = currentMonth + additionalMonths;

          if (newMonth > 12) {
            newMonth = 1;
            currentYear = currentYear + 1;
          } else {
            newMonth = newMonth;
          }

          let newDate = `${currentYear}-${
            newMonth < 10 ? "0" + newMonth : newMonth
          }-${item.date < 10 ? "0" + item.date : item.date}`;

          paymentScheduleTest.push({
            date: newDate,
            amount: item.amount,
            card_id: item.card_id,
            current: item.current,
            ocurrences: item.ocurrences,
          });
        }
      }

      let paymentScheduleOrder = paymentScheduleTest.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );

      return paymentScheduleOrder;
    },

    async getPaymentsDays() {
      try {
        const { data } = await amgApi.get("/commons/get-payment-days");
        this.daysOfPayment = data;
        const add = parseInt(this.$moment().format("MM"), 10) === 12 ? 1 : 0;
        this.years = this.range(2020, new Date().getFullYear() + add);
        let monthNow = new Date().getMonth() + 1;
        let yearNow = new Date().getFullYear();
        this.monthCFee = monthNow + 1;
        if (this.monthCFee > 12) {
          this.monthCFee = 1;
          this.yearCFee = yearNow + 1;
        } else {
          this.yearCFee = yearNow;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    mustPassSevenDays(item) {
      if (!item.date) return false;
      let currentMonth = new Date().getMonth() + 1;
      let currentYear = new Date().getFullYear();
      let additionalMonths = item.current == 1 ? 0 : 1;
      let newMonth = currentMonth + additionalMonths;
      if (newMonth > 12) {
        newMonth = 1;
        currentYear = currentYear + 1;
      } else {
        newMonth = newMonth;
      }

      let newDate = `${currentYear}-${
        newMonth < 10 ? "0" + newMonth : newMonth
      }-${item.date < 10 ? "0" + item.date : item.date}`;
      let date = new Date(newDate);
      let currentDate = new Date();
      let diff = date - currentDate;
      let days = Math.floor(diff / (1000 * 60 * 60 * 24));
      return days < 7;
    },
    async getContractSales() {
      try {
        const { data, status } = await amgApi.post(
          "/sales-made/get-contract-sales",
          {
            id: this.contractFee.saleId,
          }
        );
        let info = data[0];

        let payments = info.payments.map((item) => {
          item.month = this.$moment(item.date).format("M");
          item.year = this.$moment(item.date).format("YYYY");
          item.date = this.getDay(item.date).replace(/^0+/, "");
          return item;
        });
        this.paymentSchedule = payments;

        this.typePayment = payments?.length > 1 ? 1 : 0;
        if (this.paymentSchedule?.length == 1) {
          this.yearCFee = this.paymentSchedule[0].year;
          this.monthCFee = this.paymentSchedule[0].month;
          this.dayCFee = this.paymentSchedule[0].date;
          this.cardId = this.paymentSchedule[0].card_id;
          this.paymentSchedule = [];
        }
        this.contractSale = info;
        this.cardType = info.type_payment;
        this.methodPayment = info.method_payment;
        this.months = info.months;
        this.charge = info.charge === 0 || info.charge === null ? true : false;
        this.fee = parseFloat(info.fee);
        this.initialPayment = parseFloat(info.initial_amount);

        if (this.hasMembership && info.program_id == 2) {
          this.monthlyAmount = this.fee;
        } else if (this.hasMembership) {
          this.monthlyAmount = info.value_payment_ce;
        } else if (info.monthly_ds) {
          this.monthlyAmount = info.monthly_ds;
          this.monthly_ds = info.monthly_ds;
        } else {
          this.monthlyAmount =
            info.monthly_amount == null ? 0 : info.monthly_amount;
          this.monthly_ds = JSON.parse(JSON.stringify(this.monthlyAmount));
        }
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      }
    },
    addCard(cards) {
      this.cards = cards;
    },
    closedModalCar() {
      this.addCardModal = false;
    },
    async changeCharge(checked) {
      if (checked === false) {
        const response = await this.showConfirmSwal(
          "Desactivate Charge",
          "Are you sure to desactivate the charge?"
        );
        if (!response.isConfirmed) this.charge = !this.charge;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
input:disabled {
  background-color: transparent;
}

.b-table-bottom-row th:nth-child(2) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: 5px;
  background: #0090e7 !important;
}

.b-table-bottom-row th:nth-child(3) {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: -10px;
  background: #0090e7 !important;
}
</style>
<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
</style>
