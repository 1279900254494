export default [
  {
    path: 'clients',
    name: 'bussiness-clients',
    component: () => import(/* webpackChunkName: "BusinessClients" */ '@/views/business/views/clients/Clients.vue'),
    redirect: { name: 'bussiness-s-clients' },
    meta: {
      // permittedRoles: [1, 2],
      isClientsTab: true,
      pageTitle: 'Clients',
      breadcrumb: [
        {
          text: 'Clients',
          active: true,
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'bussiness-s-clients',
        component: () => import(/* webpackChunkName: "BusinessSClients" */ "@/views/business/views/clients/components/ClientsGrid.vue"),
        meta: {
          isClientsTab: true,
          moduleParagon: false,
          program: 1,
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Clients',
              active: true,
            },
          ],
        },
      },
      {
        path: 'shared',
        name: 'bussines-s-shared',
        component: () => import(/* webpackChunkName: "BusinessShared" */ '@/views/commons/components/clients/components/Shared.vue'),
        meta: {
          isClientsTab: true,
          program: 1,
          pageTitle: 'Shared',
          breadcrumb: [
            {
              text: 'Shared',
              active: true,
            },
          ],
        },
      },
    ],
  },

  {
    path: 'clients/test',
    name: 'bussiness-clients',
    component: () => import(/* webpackChunkName: "BusinessClients" */ '@/views/business/views/clients/components/ClientsTab.vue'),
    redirect: { name: 'bussiness-s-clients-test' },
    meta: {
      // permittedRoles: [1, 2],
      isClientsTab: true,
      pageTitle: 'Clients',
      breadcrumb: [
        {
          text: 'Clients',
          active: true,
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'bussiness-s-clients-test',
        component: () => import(/* webpackChunkName: "BusinessSClients" */ "@/views/commons/components/digital/components/ClientsGrid.vue"),
        meta: {
          isClientsTab: true,
          moduleParagon: false,
          program: 1,
          pageTitle: 'Clients',
          breadcrumb: [
            {
              text: 'Clients',
              active: true,
            },
          ],
        },
      },
      {
        path: 'shared',
        name: 'bussines-s-shared-test',
        component: () => import(/* webpackChunkName: "BusinessShared" */ '@/views/commons/components/clients/components/Shared.vue'),
        meta: {
          isClientsTab: true,
          program: 1,
          pageTitle: 'Shared',
          breadcrumb: [
            {
              text: 'Shared',
              active: true,
            },
          ],
        },
      },
    ],
  },
]