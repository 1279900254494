import store from "@/store"

const changeUserStatusSession = () => {
  window.socket.bind("change-user-status-session", async (data) => {
    if (store.state.auth?.currentUser) {
      const sessionId = store.state.auth.currentUser.user_id
      if (data.to_id == sessionId) {
        await store.dispatch("UserStore/A_GET_USER_STATUS_SESSION", {
          id: sessionId,
        })
      }
    }
  })
}
export default changeUserStatusSession
