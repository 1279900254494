<template>
  <b-modal
    v-model="control"
    title="Ticket summary"
    title-class="h3 font-weight-bolder text-white"
    centered
    @hidden="closeModal"
  >
    <div class="d-flex justify-content-end align-items-center">
      <b-button
        @click="openModalTikcetSummaryTracking"
        variant="info"
        size="sm"
      >
        Summary history
        <b-icon
          v-b-tooltip.hover="'Summary history'"
          icon="clock-history"
          class="m-0 ml-1"
        />
      </b-button>
    </div>
    <validation-observer ref="summaryForm">
      <validation-provider v-slot="{ errors }" rules="required">
        <b-form-group label="Summary">
          <b-form-textarea
            v-model="form.summary"
            style="
              font-family: Montserrat;
              font-size: 1.2rem;
              border-radius: 10px;
            "
            placeholder="Write summary..."
            rows="10"
            :class="
              errors.length > 0
                ? 'is-invalid'
                : errors.length === 0 && form.summary
                ? 'is-valid'
                : ''
            "
          />
        </b-form-group>
        <span :class="errors[0] ? 'text-danger' : ''">{{ errors[0] }}</span>
      </validation-provider>
    </validation-observer>
    <template #modal-footer>
      <b-button
        variant="primary"
        style="font-family: Montserrat; font-weight: 500"
        @click="insertSummaryTicket"
      >
        Save
      </b-button>
    </template>

    <modal-ticket-customer-summary-track
      v-if="showModalTicketSummaryTracking"
      :customer-ticket="customerTicket"
      :summary-data="form"
      @hidden="refresh"
    />
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import ModalTicketCustomerSummaryTrack from "@/views/commons/components/customer-tickets/components/modal/ModalTicketCustomerSummaryTrack.vue";

export default {
  components: {
    ModalTicketCustomerSummaryTrack,
  },
  props: {
    customerTicket: {
      type: Object,
      default: () => {},
    },
    summaryData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      control: false,
      form: {
        id: null,
        summary: "",
      },

      showModalTicketSummaryTracking: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  mounted() {
    this.control = true;
  },

  created() {
    this.getSummaryTicket();
  },

  methods: {
    openModalTikcetSummaryTracking() {
      this.showModalTicketSummaryTracking = true;
    },

    closeModal() {
      this.$emit("hidden");
    },

    async insertSummaryTicket() {
      try {
        const params = {
          summary_id: this.form.id,
          summary: this.form.summary,
          ticket_customer_id: this.customerTicket.id,
          user_id: this.currentUser.user_id,
        };

        const validate = await this.$refs.summaryForm.validate();
        const { isConfirmed } = await this.showConfirmSwal();

        if (!validate) {
          return;
        }

        if (!isConfirmed) {
          return;
        }

        this.addPreloader();
        const { status } =
          await CustomerTicketsService.insertTicketCustomerSummary(params);

        if (status === 200) {
          this.getSummaryTicket();
          this.removePreloader();
          this.closeModal();
          this.showSuccessSwal();
        }
      } catch (error) {
        this.removePreloader();
        console.log(error);
      }
    },

    async getSummaryTicket() {
      try {
        const params = {
          ticket_customer_id: this.customerTicket.id,
        };
        const { data } = await CustomerTicketsService.getTicketCustomerSummary(
          params
        );
        if (data[0]) {
          this.form.id = data[0].id;
          this.form.summary = data[0].summary;
        }
      } catch (error) {
        console.log(error);
      }
    },
    refresh() {
      this.getSummaryTicket();
      this.showModalTicketSummaryTracking = false;
    },
  },
};
</script>
