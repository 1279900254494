export default [
  {
    key: 'number',
    label: 'Number',
  },
  {
    key: 'employee',
    label: 'Employee',
  },
  {
    key: 'department_name',
    label: 'Department Name',
  },
  {
    key: 'modules',
    label: 'Modules',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
