<template>
  <div class="header-rc-chat-sidebar">
    <div>
      <div>
        <span class="title" v-if="credential">
          {{ credential.rc_name }} {{ credential.number_format }}
        </span>
      </div>
    </div>

    <div class="title mt-1" v-if="!credential">SMS INBOX</div>

    <div class="mt-1 mb-1 d-flex" v-if="!credential">
      <v-select
        placeholder="Select Line"
        :options="numbers"
        v-model="currentLine"
        label="number_format"
        :clearable="false"
        style="min-width: 180px"
      >
        <template #option="{ type_line, number_format }">
          <tabler-icon
            v-if="type_line == 'BUSINESS'"
            size="15"
            icon="BuildingIcon"
          />
          <feather-icon v-else icon="UserIcon" />
          <span style="margin-left: 4px">{{ number_format }}</span>
        </template>

        <template #selected-option="{ type_line, number_format }">
          <tabler-icon
            v-if="type_line == 'BUSINESS'"
            size="15"
            icon="BuildingIcon"
          />
          <feather-icon v-else icon="UserIcon" />
          <span style="margin-left: 4px">{{ number_format }}</span>
        </template>
      </v-select>

      <b-button
        v-if="!G_LIST_ACTIVE"
        class="ml-1"
        variant="primary"
        @click="showModalCreateChat = true"
      >
        <feather-icon icon="MessageCircleIcon" /> NEW
      </b-button>
      <b-button
        v-if="G_LIST_ACTIVE && isCeo"
        variant="primary"
        class="ml-1"
        @click="openModalCreateChat()"
      >
        + NEW LIST
      </b-button>
    </div>

    <rc-create-chat
      v-if="showModalCreateChat"
      :credential="credential ? credential : value"
      @hidden="showModalCreateChat = false"
      @newChat="setNewChat"
    />

    <rc-create-list
      v-if="showModalCreateList"
      :credential="value"
      @onlyClose="showModalCreateList = false"
    />
  </div>
</template>
  
<script>
import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import RcCreateChat from "@/views/commons/components/ring-central/chat-sms/components/RcCreateChat.vue";
import RcCreateList from "@/views/commons/components/ring-central/chat-sms/components/RcChatCreateList.vue";

import { mapMutations, mapGetters, mapActions } from "vuex";
export default {
  components: {
    RcCreateChat,
    RcCreateList,
  },

  props: {
    value: {
      type: Object,
    },

    leadId: {
      type: [String, Number],
    },

    credential: {
      type: Object,
    },
  },
  data() {
    return {
      numbers: [],

      showModalCreateChat: false,
      showModalCreateList: false,
    };
  },

  computed: {
    ...mapGetters({
      G_CURRENT_TAB_CONTACTS: "SmsInboxStore/G_CURRENT_TAB_CONTACTS",
      G_LIST_ACTIVE: "SmsInboxStore/G_LIST_ACTIVE",
    }),

    currentLine: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  methods: {
    ...mapMutations({
      M_SET_CURRENT_CREDENTIAL: "SmsInboxStore/M_SET_CURRENT_CREDENTIAL",
      M_SET_CURRENT_CHAT: "SmsInboxStore/M_SET_CURRENT_CHAT",
      M_SET_CURRENT_TAB_CONTACTS: "SmsInboxStore/M_SET_CURRENT_TAB_CONTACTS",
      M_SET_LIST_ACTIVE: "SmsInboxStore/M_SET_LIST_ACTIVE",
    }),

    async getNumbersForModule() {
      if (!this.credential) {
        const { data } = await SmsService.getNewCredentials();
        if (data.length > 0) {
          this.numbers = data;
          this.currentLine = data[0];
        }
      }
    },

    setNewChat(chatInfo) {
      this.showModalCreateChat = false;
      this.$emit("setNewChat", chatInfo);
    },

    changeStatusTab(tab) {
      this.M_SET_LIST_ACTIVE(tab);
    },

    openModalCreateChat() {
      this.showModalCreateList = true;
    },
  },

  created() {
    // this.getNumbersForModule();
  },

  mounted() {
    this.getNumbersForModule();
  },
};
</script>
  
  <style lang="scss">
.header-rc-chat-sidebar {
  font-family: "Rubick" !important;
  width: 100%;
  background-color: #ebebeb;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
  padding: 0 2rem;
  margin-bottom: 1rem;

  .title {
    font-size: 20px;
    color: #1a1a1b;
  }

  .custom-tab-rc {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 0 5px;

    &.custom-tab-rc-active {
      border-bottom: 3px solid #0090e7;
    }
  }

  .container-info {
    max-width: 75%;
    display: flex;

    .v-select {
      width: 100% !important;
      min-width: 200px !important;
    }

    .line {
      border-right: 1px solid #3d3d3d99;
      padding-right: 10px;
    }
  }

  .container-icons {
    display: flex;
    justify-content: space-between;

    .b-input-custom {
      background-color: #f0f2f5;
    }
  }

  .container-filters {
    border-radius: 10px;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #0090e7;

    .filter-v2 {
      margin: 0 5px;
      cursor: pointer;
      padding: 5px 15px;
      border-radius: 10px;
      text-align: center;
    }

    .active-filter-v2 {
      color: #fff;
      background-color: #0090e7;
    }
  }
}

.dark-layout {
  .header-rc-chat-sidebar {
    background-color: #202c33;

    .container-icons {
      .b-input-custom {
        background-color: #202c33;
      }
    }

    .title {
      color: #ffffff;
    }
  }
}
</style>