<template>
  <b-modal
    no-close-on-backdrop
    no-close-on-esc
    modal
    v-model="show"
    size="xmd"
    modal-class="modal-primary "
    @hidden="$emit('close')"
    title-tag="h3"
    hide-footer
    scrollable
  >
    <template #modal-title>
      <span>
        TRACKING CASE:
        <strong>{{ caseInfo.case_number }}</strong></span
      >
    </template>

    <b-card v-if="caseInfo">
      <b-row>
        <b-col cols="12" md="6">
          <span>
            {{ caseInfo.is_lead == 1 ? "LEAD" : "CLIENT" }}
          </span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ caseInfo.name_lead }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <span>ACCOUNT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ caseInfo.is_lead == 1 ? "-" : caseInfo.account }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <GiveIndications
      ref="giveIndicationsForm"
      :caseId="currentCaseId"
      :readOnly="GiveIndicationsReadOnly"
      @refresh="refresh"
    />
  </b-modal>
</template>

<script>
import GiveIndications from "@/views/debt-solution/views/court-info/components/tabs/GiveIndications.vue";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service.js";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  components: {
    GiveIndications,
  },
  props: {
    caseId: {
      type: Number,
      required: false,
    },
    leadId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      show: true,
      caseInfo: {},
      GiveIndicationsReadOnly: false,
      cases: [],
      caseSelected: null,
    };
  },

  computed: {
    currentCaseId() {
      return this.caseId ?? this.caseSelected;
    },
  },

  created() {
    this.getCourtCase();
  },

  methods: {
    closeAll() {
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
      this.closeAll();
    },
    async getCourtCase() {
      try {
        this.addPreloader();
        const response = await CourtInfoService.getCourtCase({
          case_id: this.currentCaseId,
        });
        this.caseInfo = response[0];
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          this.getInternalErrors(error) || "Something went wrong"
        );
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>