export default {
  namespaced: true,
  state: {
    S_COUNTER_SENDING_TO_CREDITORS: 0,
  },
  mutations: {
    M_COUNTER_SENDING_TO_CREDITORS(state, payload) {
      state.S_COUNTER_SENDING_TO_CREDITORS = payload;
    },
  },
  getters: {
    G_COUNTER_SENDING_TO_CREDITORS(state) {
      return state.S_COUNTER_SENDING_TO_CREDITORS;
    },
  },

  actions: {
    A_SET_COUNTER_SENDING_TO_CREDITORS({ commit }, payload) {
      const tag = payload > 99 ? "99+" : payload;
      commit("M_COUNTER_SENDING_TO_CREDITORS", tag);
    },
  },
};
