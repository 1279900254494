<template>
  <div class="d-flex">
    <div>
      <b-button
        id="button-formats"
        variant="outline-primary"
        @click="actionFormats"
        :disabled="validateButton"
        v-if="!validateButton"
      >
        <feather-icon
          v-if="actionType == 2"
          icon="DownloadIcon"
          size="16"
        ></feather-icon>
        {{ actionType === 1 ? "View Formats" : "Download" }}
      </b-button>
      <b-button v-else @click="goToSettings" variant="outline-warning">
        <feather-icon icon="ShareIcon"></feather-icon>
        Upload Formats
      </b-button>
      <feather-icon
        v-if="validateButton"
        icon="AlertCircleIcon"
        class="ml-1 text-warning cursor-pointer"
        size="20"
        v-b-tooltip.hover
        title="No formats uploaded"
        @click="goToSettings"
      >
      </feather-icon>
    </div>
    <b-modal
      title="Document's Format"
      size="xmd"
      v-model="modalShow"
      hide-footer
      @hide="modalShow = false"
    >
      <b-row>
        <b-col cols="3">
          <label for>Document Type</label>
          <v-select
            v-model="documentsType"
            :options="listOfDocumentsType"
            label="name"
            :clearable="false"
          />
        </b-col>
      </b-row>

      <div class="mt-2 px-4 ci-container">
        <list-pdf :pdf-list="documentsType.document_formats"></list-pdf>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DocumentFormatService from "@/views/debt-solution/views/settings/views/documents-config/document-formats/service/document-format.service.js";
import ListPdf from "@/views/debt-solution/views/court-info/components/modals/ListPdf.vue";

export default {
  nanme: "DownloadTypeDocument",
  components: {
    ListPdf,
  },
  props: {
    actionType: {
      type: Number,
      required: false,
      default: 1,
    },
    typeDocument: {
      type: Number,
      required: false,
    },
  },
  computed: {
    validateButton() {
      if (this.actionType == 1) {
        return this.listOfDocumentsType.length === 0;
      }

      if (this.documentsType == null) return true;

      return this.documentsType.document_formats.length === 0;
    },
  },
  data() {
    return {
      modalShow: false,
      servicesType: null,
      documentsType: {
        document_formats: [],
      },
      listOfDocumentsType: [],
      currentPdf: null,
      pdfName: null,
      showPreviewPdf: false,
    };
  },
  methods: {
    async getAllDocumentsFormat() {
      const data = await DocumentFormatService.getDocumentsFormats();
      if (data.length > 0) {
        this.listOfDocumentsType = data;

        if (this.actionType == 2) {
          this.documentsType = this.listOfDocumentsType.find(
            (item) => item.id == this.typeDocument
          );
        } else {
          this.documentsType = data[0];
        }
      }
    },

    actionFormats() {
      if (this.actionType == 1) {
        this.modalShow = true;
      } else {
        this.downloadFormats(this.documentsType);
      }
    },

    downloadFormats() {
      this.documentsType.document_formats.forEach((archive) => {
        const elementA = document.createElement("a");
        elementA.style.display = "none";
        elementA.target = "_blank";
        elementA.href = archive.pdf;
        elementA.download = archive.name;
        document.body.appendChild(elementA);
        elementA.click();
        document.body.removeChild(elementA);
      });
    },

    goToSettings() {
      const routeName = "debt-solution-documents-config-document-formats";
      const route = this.$router.resolve({ name: routeName }).href;
      const newWindows = window.open("", "_blank");
      newWindows.location.href = route;
      newWindows.focus();
    },
  },

  async mounted() {
    await this.getAllDocumentsFormat();
  },
};
</script>

<style lang="scss">
.ci-container {
  font-family: "Rubick";

  .card-body {
    padding: 0.7rem 1.5rem;
  }

  .document-title {
    font-weight: 100px;
    font-size: 17px;
  }

  .container-icon {
    margin-top: 5px;
    border-radius: 5px;
    box-shadow: 0 4px 24px 0 rgba(107, 106, 106, 0.1);
    padding: 5px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;

    .icon-badge {
      height: 20px;
      width: 20px;
      margin-right: 4px;
    }
  }

  .container-icon:hover {
    background-color: rgba(107, 106, 106, 0.1);
  }
}
.dark-layout {
  .ci-container {
    .card {
      background-color: #1d1d20;
    }
  }
}
</style>