<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-footer
      hide-header-close
      hide-header
      :body-class="`rounded`"
      no-close-on-backdrop
      no-close-on-esc
      size="summary"
      @hidden="isActive = false"
      centered
    >
      <div v-if="dataAppointment">
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
        >
          <div class="d-flex align-items-center" style="gap: 7px">
            <span class="title-alert font-strong"> You will receive an appointment alert in 10 minutes </span>
          </div>

          <div
            class="close-modal"
            @click="closeModal"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
          >
            <feather-icon icon="XIcon" size="16" />
          </div>

        </div>

        <hr />
        <div class="d-flex flex-column" style="gap: 15px">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CLIENT
            </span>
            <span class="font-strong">{{ dataAppointment.lead_name }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              APPOINTMENT TYPE
            </span>
            <span class="font-strong">{{ dataAppointment.tc_description }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="WatchIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              SYSTEM HOUR
            </span>
            <span class="font-strong">{{ dataAppointment.system_hour | myGlobalDay}}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="WatchIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CLIENT HOUR
            </span>
            <span class="font-strong">{{ dataAppointment.original_hour | myGlobalDay}}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="PhoneIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              MOBILE
            </span>
            <span class="font-strong">{{ dataAppointment.mobile }}</span>
          </div>
        </div>
      </div>
      <hr />
      <b-row>
        <b-col>
          <b-button
            class="w-100 block mx-0 px-0"
            target="_blank"
            variant="info"
            @click="goToAppointments"
            >GO TO APPOINTMENTS</b-button
          >
        </b-col>
        <b-col>
          <b-button class="w-100 block" variant="success" @click="closeModal()"
            >OK</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters,mapActions } from "vuex"
export default {
  name: "AppointmentNotice",
  data() {
    return {
      alert_buttons: true,
      is_reschedule: false,
      is_cancel: false,
      detailAppointment: null,
    }
  },
  computed: {
    ...mapGetters({
      G_IsActive: "AlertAppointment/G_IsActiveNotice",
      G_DataAppointment: "AlertAppointment/G_DataAppointmentNotice",
      currentUser: "auth/currentUser",
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
    }),
    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },

    isActive: {
      get() {
        return this.G_IsActive
      },
      set(value) {
        this.$store.commit("AlertAppointment/M_OpenNotice", value)
        // setTimeout(() => {
        //   this.dataAppointment = null
        // }, 500)
      },
    },

    dataAppointment: {
      get() {
        return this.G_DataAppointment
      },
      set(value) {
        this.$store.commit("AlertAppointment/M_DataAppointmentNotice", value)
      },
    },

  },

  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "AlertAppointment/CHANGE_REFRESH_TABLE",
    }),
    closeModal() {
      const channel = socket.channel("appointments-notifications", {});
      channel.trigger("client-close-all-appointment-notice", {
        message: "close all",
        user_id: this.currentUser.user_id,
      });
      this.isActive = false;
    },
    goToAppointments() {
      this.closeModal();
      const subTabName = this.$route.name;
      const targetTabName = 'sp-appointment-in-process';
      if (subTabName == targetTabName) {
        this.CHANGE_REFRESH_TABLE(true);
      } else {
        const url = this.$router.resolve({
          name: targetTabName,
        }).href;
        window.open(url, '_blank');
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.icon-badge {
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &.completed {
    background-color: rgba(40, 199, 111, 0.1);
    color: #468844;
  }
  &.pending {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.reactivated {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.scaled {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
}

.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}
</style>
