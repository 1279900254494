import store from "@/store";
import RequestWorkplanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";

// store
export default {
  namespaced: true,
  state: {
    refreshTable: false,
    S_PENDING_WORKPLAN: 0,
    S_TOREVISION_WORKPLAN: 0,
    S_COMPLETE_WORKPLAN: 0,
    S_DISPUTE_DATA: [],
    S_ID_REQUEST_WORKPLAN: null,
    S_CHANGED_REPORT_ITEMS: 0,
    S_IS_EXECUTING_METHOD: false,
    S_STEP_WP: 0,
    S_ID_UPDATE_REQUEST: null,
  },
  getters: {
    getRefreshTable: (state) => state.refreshTable,
    G_PENDING_WORKPLAN(state) {
      return state.S_PENDING_WORKPLAN;
    },
    G_TOREVISION_WORKPLAN(state) {
      return state.S_TOREVISION_WORKPLAN;
    },
    G_COMPLETE_WORKPLAN(state) {
      return state.S_COMPLETE_WORKPLAN;
    },
    G_DISPUTE_DATA(state) {
      return state.S_DISPUTE_DATA;
    },
    G_ID_REQUEST_WORKPLAN(state) {
      return state.S_ID_REQUEST_WORKPLAN;
    },
    G_ID_UPDATE_REQUEST(state) {
      return state.S_ID_UPDATE_REQUEST;
    },
    G_CHANGED_REPORT_ITEMS(state) {
      return state.S_CHANGED_REPORT_ITEMS;
    },
    G_IS_EXECUTING_METHOD(state) {
      return state.S_IS_EXECUTING_METHOD;
    },
    G_STEP_WP(state) {
      return state.S_STEP_WP;
    },
  },

  mutations: {
    SET_REFRESH_TABLE(state, payload) {
      state.refreshTable = payload;
    },
    M_PENDING_WORKPLAN(state, payload) {
      state.S_PENDING_WORKPLAN = payload;
    },
    M_TOREVISION_WORKPLAN(state, payload) {
      state.S_TOREVISION_WORKPLAN = payload;
    },
    M_COMPLETE_WORKPLAN(state, payload) {
      state.S_COMPLETE_WORKPLAN = payload;
    },
    M_DISPUTE_DATA(state, payload) {
      payload.user_id = store.state.auth.currentUser.user_id;
      state.S_DISPUTE_DATA.push(payload);
    },
    M_DISPUTE_DATA_REMOVE(state, id) {
      const idx = state.S_DISPUTE_DATA.findIndex((x) => x.id == id);

      if (idx >= 0) {
        state.S_DISPUTE_DATA.splice(idx, 1);
      }
    },
    M_CLEAR_DISPUTE_DATA(state, payload) {
      state.S_DISPUTE_DATA = payload;
    },
    M_ID_REQUEST_WORKPLAN(state, payload) {
      state.S_ID_REQUEST_WORKPLAN = payload;
    },
    M_ID_UPDATE_REQUEST(state, payload) {
      state.S_ID_UPDATE_REQUEST = payload;
    },
    M_CHANGED_REPORT_ITEMS(state, payload) {
      state.S_CHANGED_REPORT_ITEMS += payload;
    },
    M_RESET_CHANGED_REPORT_ITEMS(state) {
      state.S_CHANGED_REPORT_ITEMS = 0;
    },
    M_IS_EXECUTING_METHOD(state, payload) {
      state.S_IS_EXECUTING_METHOD = payload;
    },
    SET_STEP_WP(state, step) {
      state.S_STEP_WP = step;
    },
  },
  actions: {
    CHANGE_REFRESH_TABLE({ commit }, data) {
      commit("SET_REFRESH_TABLE", data);
    },
    async A_REQUEST_WORKPLAN_COUNTERS({ commit }, module) {
      const user_id = store.state.auth.currentUser.user_id;
      const module_id = store.state.auth.currentUser.modul_id;
      const role_id = store.state.auth.currentUser.role_id;

      const { pending_wp, to_revision_wp, complete_wp } =
        await RequestWorkplanService.getCounters({
          user_id,
          role_id,
          module_id,
        });

      commit("M_PENDING_WORKPLAN", pending_wp);
      commit("M_TOREVISION_WORKPLAN", to_revision_wp);
      commit("M_COMPLETE_WORKPLAN", complete_wp);

      // update sidebar

      const sideCounter =
        module_id == 28
          ? pending_wp + to_revision_wp + complete_wp
          : complete_wp;

      if (sideCounter >= 0) {
        const name = {
          28: "request-workplan-analyst-department",
          14: "request-workplan-bk",
          7: "request-workplan-boost-credit",
          3: "request-workplan-business",
          20: "request-workplan-connection",
          22: "request-workplan-ce-digital",
          10: "request-workplan-court-info",
          6: "request-workplan-credit-experts",
          5: "request-workplan-debt-solution",
          12: "request-workplan-paragon",
          8: "request-workplan-tax-research",
        };

        const payload = {
          routeName: name[module] || null,
          tag: sideCounter > 99 ? "+99" : sideCounter,
        };

        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
      }
    },
    A_DISPUTE_ACCOUNTS({ commit }, data) {
      commit("M_DISPUTE_DATA", data);
    },
    A_REMOVE_ACCOUNT({ commit }, accId) {
      commit("M_DISPUTE_DATA_REMOVE", accId);
    },
    A_CLEAR_DISPUTE_DATA({ commit }, data) {
      commit("M_CLEAR_DISPUTE_DATA", data);
    },
    A_ID_REQUEST_WORKPLAN({ commit }, data) {
      commit("M_ID_REQUEST_WORKPLAN", data);
    },
    A_ID_UPDATE_REQUEST({commit}, data) {
      commit("M_ID_UPDATE_REQUEST", data);
    },
    A_IS_EXECUTING_METHOD({ commit }, data) {
      commit("M_IS_EXECUTING_METHOD", data);
    },
    async A_STEP_WP({ commit, state }) {
      try {
        const response = await RequestWorkplanService.getStepWorkPlan({
          id: state.S_ID_REQUEST_WORKPLAN,
        });
        commit("SET_STEP_WP", response.data);
      } catch (error) {
        console.error("Error fetching step work plan:", error);
      }
    },
    async A_UPDATE_STEP_WP({ commit }, data) {
      try {
        await RequestWorkplanService.updateStepWorkPlan(data);
        commit("SET_STEP_WP", data.step);
        commit("SET_REFRESH_TABLE", true);
      } catch (error) {
        console.error("Error updating step work plan:", error);
      }
    },
  },
};
