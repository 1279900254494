export default [
    {
        key: 'expenses_date',
        label: 'Date',
        visible: true
    },
    {
        key: "method",
        label: "Card",
        visible: true,
        class: "text-center"
    },
    {
        key: 'amount',
        label: 'Amount',
        visible: true,
        class: "text-center"
    },
    // {
    //     key: 'vendor',
    //     label: 'Vendor',
    //     visible: true
    // },
    // {
    //     key: 'concept',
    //     label: 'Concept',
    //     visible: true
    // },
    {
        key: "details",
        label: "Transaction details",
        visible: true,
    },
    {
        key: 'created_by',
        label: 'Registered by',
        visible: true
    },
    {
        key: 'updated_by',
        label: 'Updated by',
        visible: false
    },
]
