var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"lazy":"","centered":"","header-bg-variant":"primary","title-class":"h3 text-white font-weight-bolder","size":"lg","title":"Tracking","modal-class":"modal-primary","hide-footer":"","scrollable":""},model:{value:(_vm.modal.tracking),callback:function ($$v) {_vm.$set(_vm.modal, "tracking", $$v)},expression:"modal.tracking"}},[_c('b-container',{attrs:{"fluid":""}},[_c('program-client-header',{staticClass:"sticky-top",attrs:{"client":_vm.tracking.client,"program":_vm.tracking.program,"typeClient":_vm.typeClient}}),_c('b-row',{staticClass:"mt-2"},[_c('b-table',{staticClass:"w-100",attrs:{"fields":_vm.fields,"items":_vm.dataa,"small":"","sticky-header":""},scopedSlots:_vm._u([{key:"cell(date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(data.item.date))+" ")]}},{key:"cell(type)",fn:function(data){return [_c('p',{staticClass:"text-capitalize my-auto",class:data.item.type === 'send'
                ? 'text-info'
                : data.item.type === 'approved'
                ? 'text-success'
                : data.item.type === 'approved by supervisor'
                ? 'text-success'
                : data.item.type === 'approved by seller'
                ? 'text-success'
                : data.item.type === 'rejected by supervisor'
                ? 'text-danger'
                : data.item.type === 'rejected by seller'
                ? 'text-danger'
                : 'text-warning'},[_vm._v(" "+_vm._s(data.item.type)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }