export default [
  {
    path: "messages",
    name: "settings-messages",
    component: () =>
      import(
        /* webpackChunkName: "CEDSettingsMessages" */ "@/views/ce-digital/sub-modules/settings/views/messages/components/CeSetMessages.vue"
      ),
    redirect: { name: "ced-settings-app-message-list" },
    meta: {
      permittedRoles: [1, 2, 17],
      pageTitle: "Messages",
      breadcrumb: [
        {
          text: "Messages",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "app-message",
        name: "ced-settings-app-message-list",
        component: () =>
          import(
            /* webpackChunkName: "CEDSettingsMessagesList" */ "@/views/ce-digital/sub-modules/settings/views/app-message/components/AppMessageList.vue"
          ),
        meta: {
          route: "App Message",
          permittedRoles: [1, 2, 17],
          pageTitle: "App Message",
          breadcrumb: [
            {
              text: "App Message",
              active: true,
            },
          ],
        },
      },
      {
        path: "welcome-message",
        name: "ced-settings-welcome-message-list",
        component: () =>
          import(
            /* webpackChunkName: "CEDSettingsWelcomeMessage" */ "@/views/ce-digital/sub-modules/settings/views/messages/components/welcome-message/WelcomeMessageList.vue"
          ),
        meta: {
          route: "Welcome Message",
          permittedRoles: [1, 2, 17],
          pageTitle: "Welcome Message",
          breadcrumb: [
            {
              text: "Welcome Message",
              active: true,
            },
          ],
        },
      },
      {
        path: "quick-message",
        name: "ced-settings-quick-message-list",
        component: () =>
          import(
            /* webpackChunkName: "CEDSettingsQuickMessage" */ "@/views/ce-digital/sub-modules/settings/views/messages/components/quick-messages/QuickMessages.vue"
          ),
        meta: {
          route: "Quick Message",
          permittedRoles: [1, 2, 17],
          pageTitle: "Quick Message",
          breadcrumb: [
            {
              text: "Quick Message",
              active: true,
            },
          ],
        },
      },
      {
        path: "quick-email",
        name: "ced-settings-quick-email-list",
        component: () =>
          import(
            /* webpackChunkName: "CEDSettingsQuickEmailList" */ "@/views/ce-digital/sub-modules/settings/views/messages/components/quick-email/QuickEmail.vue"
          ),
        meta: {
          module: 24,
          route: "Quick Email",
          permittedRoles: [1, 2, 17],
          pageTitle: "Quick Email",
          breadcrumb: [
            {
              text: "Quick Email",
              active: true,
            },
          ],
        },
      },
      {
        path: "no-answer",
        name: "ced-settings-no-answer-list",
        component: () =>
          import(
            /* webpackChunkName: "CEDSettingsNoAnswerListList" */ "@/views/ce-digital/sub-modules/settings/views/messages/components/no-answer/NoAnswerList.vue"
          ),
        meta: {
          module: 24,
          route: "No Answer",
          permittedRoles: [1, 2, 17],
          pageTitle: "No Answer",
          breadcrumb: [
            {
              text: "No Answer",
              active: true,
            },
          ],
        },
      },
      {
        path: "dob-message",
        name: "ced-settings-dob-message-list",
        component: () =>
          import(
            /* webpackChunkName: "CEDSettingsDobMessage" */ "@/views/ce-digital/sub-modules/settings/views/messages/components/dob/DobMessageList.vue"
          ),
        meta: {
          route: "Birthday Message",
          permittedRoles: [1, 2, 17],
          pageTitle: "Birthday Message",
          breadcrumb: [
            {
              text: "Birthday Message",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
