export default [
  {
    path: "clients",
    name: "specialistdigital-clients",
    component: () =>
      import(
        /* webpackChunkName: "SpecialDigitalClients" */ "@/views/specialist-digital/views/clients/components/ClientTab.vue"
      ),
    redirect: { name: "pending-clients-specialistdigital" },
    meta: {
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "pending-clients-specialistdigital",
        component: () =>
          import(
            /* webpackChunkName: "SpecialDigitalClientsPending" */ "@/views/specialist-digital/views/clients/components/ClientsPending.vue"
          ),
        meta: {
          reportLeadRoute: "report-lead-specialistdigital",
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "New",
              active: true,
            },
          ],
        },
      },
      {
        path: "completed",
        name: "completed-clients-specialistdigital",
        component: () =>
          import(
            /* webpackChunkName: "SpecialDigitalClientsCompleted" */ "@/views/specialist-digital/views/clients/components/ClientsCompleted.vue"
          ),
        meta: {
          isClientsTab: true,
          reportLeadRoute: "report-lead-specialistdigital",
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Regular",
              active: true,
            },
          ],
        },
      },
    ],
  },

  {
    path: "lead/report/:idlead/:idfile/:accountId",
    name: "report-lead-specialistdigital",
    component: () =>
      import(
        /* webpackChunkName: "SpecialDigitalLeadReport" */ "@/views/commons/components/ncr/components/report/ReportLead.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      typeTable: 1,
    },
  },
  {
    path: "clients/account/:idClient",
    name: "specialist-digital-dashboard",
    redirect: { name: "personal-information-specialist-digital" },
    component: () =>
      import(
        /* webpackChunkName: "SpecialDigitalClientsAccount" */ "@/views/specialist-digital/views/clients/dashboard/AccountSpecialistsDigital"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay",
        name: "pay-specialist-digital",
        component: () =>
          import(
            /* webpackChunkName: "SpecialDigitalClientsAccountPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "dis",
        name: "dis-specialist-digital",
        component: () =>
          import(
            /* webpackChunkName: "SpecialDigitalClientsAccountDis" */ "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Dispute",
              active: true,
            },
          ],
        },
      },
      {
        path: "task-notes",
        name: "task-notes-specialist-digital",
        component: () =>
          import(
            /* webpackChunkName: "SpecialDigitalClientsAccountTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information",
        name: "personal-information-specialist-digital",
        component: () =>
          import(
            /* webpackChunkName: "SpecialDigitalClientsAccountPersonalInformation" */ "@/views/commons/components/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files",
        name: "client-files-specialist-digital",
        component: () =>
          import(
            /* webpackChunkName: "SpecialDigitalClientsAccountClientFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr",
        name: "acr-specialist-digital",
        component: () =>
          import(
            /* webpackChunkName: "SpecialDigitalClientsAccountAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "report",
        name: "report-specialist-digital",
        component: () =>
          import(
            /* webpackChunkName: "SpecialDigitalClientsAccountReport" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log",
        name: "call-log-client-specialist-digital",
        redirect: { name: "call-log-conversation-specialist-digital" },
        component: () =>
          import(
            "@/views/commons/components/ring-central/call-log/tabs/ClientTabs.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
          callConversationRoute: "call-log-conversation-specialist-digital",
          callNoConversationRoute:
            "call-log-not-conversation-specialist-digital",
        },
        children: [
          {
            path: "conversation",
            name: "call-log-conversation-specialist-digital",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Internal",
                  active: true,
                },
              ],
              isConversation: 1,
            },
          },
          {
            path: "no-conversation",
            name: "call-log-not-conversation-specialist-digital",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Internal",
                  active: true,
                },
              ],
              isConversation: 0,
            },
          },
        ],
      },
      {
        path: "tickets",
        name: "client-tickets-specialist-digital",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
