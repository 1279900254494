<template>
  <div></div>
</template>
  
  <script>
export default {
  data() {
    return {
      userInactivity: false,
      inactiveTime: 120000,
      timer: null,
    };
  },
  mounted() {
    this.startInactiveTimer();
    window.addEventListener("mousemove", this.handleActivity);
    window.addEventListener("keydown", this.handleActivity);
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.handleActivity);
    window.removeEventListener("keydown", this.handleActivity);
    this.clearTimers();
  },
  methods: {
    handleActivity() {
      this.startInactiveTimer();
      if (this.userInactivity) {
        this.userInactivity = false;
        this.$emit("activityDetected");
      }
    },

    handleInactiveActivity() {
      this.userInactivity = true;
      this.$emit("inactivityDetected");
    },

    startInactiveTimer() {
      this.clearTimers();
      this.timer = setTimeout(() => {
        this.handleInactiveActivity();
      }, this.inactiveTime);
    },

    clearTimers() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
  },
};
</script>
  