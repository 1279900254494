export default [
    {
        value: 21,
        text: "CUSTOMER SERVICE REGULAR",
        route: "credit-experts-dash",
    },
    {
        value: 22,
        text: "CUSTOMER SERVICE DIGITAL",
        // route: "creditexperts-digital-clients",
        route: "ce-dashboard-super",
    },
    {
        value: 20,
        text: "CONNECTION",
        route: "connection-dashboard",
    },
    {
        value: 23,
        text: "FINANCIAL",
        route: "ce-dashboard",
    },
    {
        value: 24,
        text: "SETTINGS",
        route: "ce-digital-users",
    },
    {
        value: 26,
        text: "SALES",
        route: "ce-dashboard-sales",
    },
]