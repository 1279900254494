<template>
  <div class="border-top-info border-3 box-shadow-3 rounded-bottom">
    <template>
      <div class="m-1 d-flex justify-content-end">
        <b-dropdown
          id="dropdown-6"
          variant="info"
          v-if="
            (isCeo || isSupervisor || isChief || isSellerC) && moduleId == 2
          "
        >
          <template #button-content>
            <template v-if="isLoading">
              <b-spinner small />
            </template>
            <template v-else>
              <feather-icon
                icon="DownloadIcon"
                size="16"
                class="align-middle"
              />
            </template>
            <span>Export To Excel</span>
          </template>
          <b-dropdown-item @click="exportExcel(1)"
            >Export Current Page</b-dropdown-item
          >
          <b-dropdown-item @click="exportExcel(2)"
            >Export All Page</b-dropdown-item
          >
          <!-- <b-dropdown-item @click="exportExcel(3)" :disabled="!selected.length"
            >Export Selection</b-dropdown-item
          > -->
        </b-dropdown>
        <FilterButtonByMonth
          v-if="statusTab === 3"
          @changeYear="changeYear"
          @loadByMonth="loadByMonth"
        />
        <b-button
          v-if="moduleId != 16"
          variant="primary"
          class="ml-1"
          @click="showNotSoldsModal = true"
        >
          Not solds
        </b-button>
        <b-button
          v-if="moduleId != 16"
          variant="warning"
          class="ml-1"
          @click="showAnnulledSolds()"
          style="padding: 10px; max-width: 150px"
        >
          <span> Annulleds </span>
        </b-button>
      </div>
    </template>
    <ProgramsCRMDone
      :isDone="statusTab === 3 && this.moduleId == 2"
      @changeTab="changeTab"
    >
      <template #content>
        <filter-slot
          :filter="filter"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="$refs['new-client-done-table'].refresh()"
          @onSelectChange="getSellers()"
        >
          <template #buttons>
            <!-- <b-button
          v-if="moduleId == 2"
          variant="primary"
          class="ml-1"
          size="sm"
          @click="statusPendingAppointments = true"
        >
          Rejecteds
        </b-button> -->
          </template>

          <template #table>
            <b-table
              ref="new-client-done-table"
              sticky-header="70vh"
              small
              show-empty
              no-provider-filtering
              :busy.sync="isBusy"
              responsive="sm"
              :items="getSaleMade"
              :fields="filteredFields"
              :per-page="paginate.perPage"
              :current-page="paginate.currentPage"
              :filter="filter"
              :tbody-tr-class="'tr-table'"
              class="position-relative table-new-customization"
              foot-clone
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #cell(client)="{ item }">
                <Client :item="item" :moduleId="moduleId" :toRoute="toRoute" />
              </template>
              <template #cell(program)="data">
                <div
                  class="d-flex flex-column align-items-center"
                  style="gap: 4px"
                >
                  <div
                    v-b-tooltip.bottom="data.item.program"
                    @click="openModalProgram(data.item, data.index)"
                    class="charge__programs text-center"
                    :class="
                      validateIconService(
                        data.item.program_id,
                        data.item.haveRates,
                        data.item.haveQuestionnaire,
                        data.item.ds_analysis_options_id
                      )
                        ? 'charge__programs--alert'
                        : ''
                    "
                  >
                    <img
                      :src="getProgramImageRoute(data.item.program_id)"
                      alt="img_services"
                    />
                  </div>
                  <!-- Sale from program Debt Solution -->
                  <analysis-option-badge
                    v-if="data.item.ds_analysis_options_id"
                    :data="data.item"
                  />
                </div>
              </template>
              <template #cell(captured)="{ item }">
                <Executives
                  :item="item"
                  :sellers="sellers"
                  :captured="captured"
                />
              </template>
              <template #cell(fee)="{ item }">
                <Fee :item="item" :currentUser="currentUser" />
              </template>
              <template #cell(initial_amount)="data">
                <div
                  :class="isSellerMethod(data.item) ? 'cursor-pointer' : ''"
                  @click="
                    isSellerMethod(data.item) &&
                      openInitialPaymentModal(data.item, data.index)
                  "
                >
                  <b-icon
                    v-if="
                      data.item.initial_payment_status === 1 &&
                      data.item.initial_amount == 0
                    "
                    icon="wallet2"
                    variant="muted"
                    class="font-medium-2"
                  />
                  <b-icon
                    v-else-if="data.item.initial_payment_status === 3"
                    icon="wallet2"
                    variant="warning"
                    class="font-medium-2"
                  />
                  <p
                    v-else-if="
                      data.item.initial_payment_status === 2 ||
                      (data.item.initial_payment_status === 1 &&
                        data.item.initial_amount > 0)
                    "
                    class="text-success font-weight-bold"
                  >
                    $ {{ data.item.initial_amount }}
                  </p>
                </div>
              </template>
              <template #cell(contract_fee_status)="data">
                <b-col
                  :style="
                    data.item.user_id != currentUser.user_id && isSellerC
                      ? 'pointer-events: none !important; opacity: 0.4 !important;'
                      : ''
                  "
                  class="d-flex justify-content-center p-0"
                >
                  <b-icon
                    v-if="
                      data.item.contract_fee_status == 0 ||
                      (data.item.contract_fee_status == 1 &&
                        data.item.initial_payment_status == 3)
                    "
                    @click="showContractFee(data)"
                    class="cursor-pointer font-medium-2"
                    icon="file-text"
                    variant="muted"
                    :style="
                      data.item.initial_amount > 0 ? '' : 'cursor: not-allowed;'
                    "
                    v-b-tooltip.hover.top="'Contract Fee'"
                  />
                  <b-icon
                    v-if="
                      data.item.contract_fee_status == 1 &&
                      data.item.initial_payment_status == 2
                    "
                    @click="showContractFee(data)"
                    variant="success"
                    icon="file-text"
                    class="cursor-pointer font-medium-2"
                    :style="
                      data.item.initial_payment_status == 2
                        ? ''
                        : 'cursor: not-allowed;'
                    "
                    v-b-tooltip.hover.top="'Contract Fee'"
                  />
                  <b-icon
                    v-if="data.item.contract_fee_status == 2"
                    @click="showContractFee(data)"
                    variant="danger"
                    icon="file-text"
                    class="cursor-pointer font-medium-2"
                    :style="
                      data.item.initial_payment_status == 2
                        ? ''
                        : 'cursor: not-allowed;'
                    "
                    v-b-tooltip.hover.top="'Contract Fee'"
                  />
                </b-col>
              </template>
              <template #cell(notes_status)="data">
                <b-col class="d-flex justify-content-center p-0">
                  <b-icon
                    v-if="data.item.creates > '2021-05-16 00:00:00'"
                    @click="notesModal(data.item, data.index)"
                    icon="chat-square-text-fill"
                    class="cursor-pointer font-medium-2"
                    :variant="
                      data.item.notes_status_new == null && moduleId != 4
                        ? 'muted'
                        : data.item.notes_status_new == 0 || moduleId == 4
                        ? 'success'
                        : 'warning'
                    "
                    v-b-tooltip.hover.top="'Notes'"
                  />
                  <b-icon
                    v-else
                    @click="notesModal(data.item, data.index)"
                    icon="chat-square-text-fill"
                    class="cursor-pointer font-medium-2"
                    :variant="
                      data.item.notes_status === 0 ? 'muted' : 'success'
                    "
                    v-b-tooltip.hover.top="'Notes'"
                  />
                </b-col>
              </template>
              <template #cell(summary)="data">
                <div>
                  <b-icon
                    @click="summaryModal(data.item, data.index)"
                    class="cursor-pointer font-medium-2"
                    icon="telephone-fill"
                    :variant="
                      data.item.notes_ids == null
                        ? 'muted'
                        : data.item.note_transcription_status == 'COMPLETED'
                        ? 'success'
                        : 'warning'
                    "
                    v-b-tooltip.hover.top="'Summary'"
                  />
                </div>
              </template>
              <template #cell(trackings)="data">
                <b-col class="d-flex justify-content-start p-0">
                  <b-icon
                    @click="
                      openTrackingModal(
                        data.item.program,
                        data.item.client,
                        data.item.trackings,
                        data.item.id
                      )
                    "
                    class="cursor-pointer font-medium-2"
                    icon="list-check"
                    :variant="data.item.trackings ? 'success' : 'muted'"
                    v-b-tooltip.hover.top="'Tracking'"
                  />
                </b-col>
              </template>
              <template #cell(files)="data">
                <b-col class="d-flex justify-content-center p-0">
                  <feather-icon
                    @click="
                      isSellerPermissions(data.item) &&
                        openFilesModal(data.item)
                    "
                    :class="
                      isSellerPermissions(data.item)
                        ? 'cursor-pointer text-warning'
                        : ''
                    "
                    :style="
                      isSellerPermissions(data.item)
                        ? 'fill: #ff9f43'
                        : 'fill: #D8D8D6'
                    "
                    icon="FolderIcon"
                    class="font-medium-2"
                    v-b-tooltip.hover.top="'Files'"
                  />
                </b-col>
              </template>

              <template #cell(creates)="data">
                <div
                  v-if="statusTab == 3"
                  class="font-weight-bold text-success mb-1"
                  v-b-tooltip.hover.top="'Approved'"
                >
                  {{ data.item.approved | myGlobalDay }}
                </div>
                <div v-b-tooltip.hover.top="'Created'" class="text-warning">
                  {{ data.item.creates | myGlobalDay }}
                </div>
              </template>
              <template #cell(actions)="data">
                <b-row
                  v-if="statusTab !== 3"
                  class="d-flex align-items-center justify-content-center flex-column mb-1"
                  :class="{
                    'not-pointer':
                      data.item.user_id != currentUser.user_id &&
                      isSellerC &&
                      !isCoordinator &&
                      !isTeamLeader,
                  }"
                  style="gap: 10px"
                >
                  <div class="d-flex" style="gap: 10px">
                    <feather-icon
                      v-if="
                        data.item.initial_payment_status !== 1 &&
                        validateInfoIcon(data.item)
                      "
                      size="22"
                      icon="InfoIcon"
                      :class="
                        [3, 14, 15, 16].includes(data.item.status)
                          ? 'text-warning'
                          : 'text-info'
                      "
                      v-b-tooltip.hover.top
                      :title="messageForCurrentStatus(data.item)"
                    />
                    <tabler-icon
                      v-if="
                        isCompleteProcessPending(data.item) &&
                        !validateInfoIcon(data.item)
                      "
                      :icon="
                        assignStatus(data.item) == 4
                          ? 'ThumbUpIcon'
                          : 'SendIcon'
                      "
                      class="clickable"
                      size="22"
                      v-b-tooltip.hover.top
                      :title="messageForCurrentStatus(data.item)"
                      :class="getApprovalIconColor(data.item)"
                      @click="
                        revisionSale(
                          assignStatus(data.item),
                          data.item,
                          data.index,
                          true
                        )
                      "
                    />
                    <tabler-icon
                      v-if="
                        isCompleteProcessPending(data.item) &&
                        ![1, 3, 15, 14, 16].includes(data.item.status) &&
                        !validateInfoIcon(data.item)
                      "
                      icon="ThumbDownIcon"
                      class="text-danger clickable"
                      size="22"
                      v-b-tooltip.hover.top="'Disapprove'"
                      @click="
                        revisionSale(
                          assignStatus(data.item),
                          data.item,
                          data.index,
                          false
                        )
                      "
                    />

                    <feather-icon
                      v-if="
                        data.item.initial_payment_status != 2 &&
                        (isCeo ||
                          isSupervisor ||
                          isCoordinator ||
                          isTeamLeader ||
                          isChief) &&
                        moduleId !== 4
                      "
                      icon="TrashIcon"
                      class="text-danger cursor-pointer"
                      size="22"
                      @click="annulSale(data.item)"
                    />
                  </div>
                  <!-- <b-badge
                v-if="[15, 3, 14, 16].includes(data.item.status)"
                variant="light-warning"
                >{{ messageForDisapproved(data.item) }}</b-badge
              > -->
                </b-row>
                <b-row class="justify-content-center">
                  <b-icon
                    @click="
                      openTrackingModal(
                        data.item.program,
                        data.item.client,
                        data.item.trackings,
                        data.item.id
                      )
                    "
                    class="cursor-pointer font-medium-2"
                    icon="list-check"
                    :variant="data.item.trackings ? 'success' : 'muted'"
                    v-b-tooltip.hover.top="'Tracking'"
                  />
                </b-row>
              </template>
              <template #cell(more)="data">
                <div class="text-center">
                  <div>
                    <feather-icon
                      icon="InfoIcon"
                      @click="openModalInfo(data.item)"
                      v-b-tooltip.hover="'Info Lead'"
                      class="clickable"
                      style="margin-top: 5px"
                    />
                  </div>
                  <div
                    @click="modalSmsOpen(data.item)"
                    class="clickable"
                    style="margin-top: 5px"
                  >
                    <feather-icon
                      icon="MessageSquareIcon"
                      v-b-tooltip.hover="'Send SMS'"
                    />
                  </div>
                  <div
                    @click="modalHistorySmsOpen(data.item)"
                    class="clickable"
                    style="margin-top: 5px"
                  >
                    <feather-icon
                      icon="AlignJustifyIcon"
                      v-b-tooltip.hover="'History SMS'"
                    />
                  </div>
                </div>
              </template>

              <template #foot(client)>
                <!-- <div>&nbsp;</div> -->
                <div class="d-flex flex-column mt-1" style="gap: 5px">
                  <b-badge variant="success">SUBTOTAL:</b-badge>

                  <b-badge variant="success">TOTAL:</b-badge>
                </div>
              </template>
              <template #foot(captured)>
                <div>&nbsp;</div>
                <!-- <div class="d-inline-flex w-100">
              <b-badge variant="light-info" class="col-6"> SELLER </b-badge>
              <b-badge variant="light-warning" class="col-6"> CATCHER </b-badge>
            </div>
            <div class="d-flex flex-column align-items-end mt-1">
              <div class="d-flex w-100">
                <span class="col-6">$ {{ subtotal.stcms | currency }}</span>
                <span class="col-6">$ {{ subtotal.stcmc | currency }} </span>
              </div>
              <div class="d-flex w-100">
                <span class="col-6">$ {{ total.tcms | currency }}</span>
                <span class="col-6">$ {{ total.tcmc | currency }}</span>
              </div>
            </div> -->
              </template>
              <template #foot(fee)>
                <!-- <div>&nbsp;</div> -->
                <div class="d-flex flex-column align-items-center mt-1">
                  <span>$ {{ subtotal.stfee | currency }}</span>

                  <span> $ {{ total.tfee | currency }}</span>
                </div>
              </template>
              <template #foot(initial_amount)>
                <!-- <div>&nbsp;</div> -->
                <div class="d-flex flex-column mt-1">
                  <span>$ {{ subtotal.stip | currency }}</span>

                  <span>$ {{ total.tip | currency }}</span>
                </div>
              </template>
              <!-- <template #foot(contract_fee_status)>
            <div class="d-flex flex-column mt-1">
              <span>$ {{ subtotal.stma | currency }}</span>

              <span>$ {{ total.tma | currency }}</span>
            </div>
          </template> -->
              <template #foot()> &nbsp;</template>
              <template #cell(status_enum)="{ item }">
                <p>{{ item.status }}</p>
                <p>{{ item.status_enum }}</p>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </template>
    </ProgramsCRMDone>

    <approve-supervisor-modal
      v-if="modal.approveSupervisorModal"
      :session-id="currentUser.user_id"
      :modul="modalData.approveSupervisorModal.modul"
      :event-id="modalData.approveSupervisorModal.eventId"
      :type-approve="modalData.approveSupervisorModal.typeApprove"
      @approveSupChar="returnDone"
      @click="closeModalApprove"
    />

    <ModalPendingAppointments
      v-if="statusPendingAppointments"
      @hiddenModal="statusPendingAppointments = false"
    />
    <!-- SMS History Modal -->
    <b-modal
      id="modal-history-sms"
      ok-only
      modal-class="modal-primary"
      centered
      size="lg"
      title="History Of Sms"
      hide-footer
    >
      <modal-history-sms
        :id="modalData.historySms.id"
        :modul="currentUser.modul_id"
        :lead-name="modalData.historySms.leadName"
      />
    </b-modal>

    <!-- Send SMS Modal -->
    <modal-send-sms
      v-if="modal.modalSms"
      :smss="modalData.sendSms.leads_sms"
      :modul="currentUser.modul_id"
      :typesms="modalData.sendSms.typesms"
      :sms="modalData.sendSms.leads_sms_o"
      :name-leads="modalData.sendSms.name_leads_arr[0]"
      @hide="modalSmsClose"
    />

    <!-- Modal Revission - Change Date and Hour -->
    <revission-modal
      v-if="modal.revission"
      :modal="modal"
      :revission="modalData.revission"
      :toProgramId="modalData.revission.idProgram"
      @click="
        updateRow(selectedIndex, statusSale);
        modal.revission = false;
      "
      @response="
        updateRow(selectedIndex, statusSale);
        modal.revission = false;
      "
      @removeFromTable="removeFromTable(selectedIndex)"
      @refreshTable="$refs['new-client-done-table'].refresh()"
      @close="modal.revission = false"
    />

    <validate-date-and-hour
      v-if="modal.modalValidateDateAndHour"
      :modal="modal"
      :validation-data="modalData.validateDateAndHourData"
      @revission="modalRevisionAndValidateDateAndHour"
    />

    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :modal-services="modal.programs"
      :sales-client="modalData.programs.salesClient"
      :type-modal="modalData.programs.typeModal"
      @closeModal="hideModalProgram"
    />

    <!-- Modal for register Initial Payment -->
    <initial-payment-modal
      v-if="modal.initial_payment"
      :modal="modal"
      :initial_payment="modalData.initial_payment"
      @close="hideInitialPaymentModal"
      @reload="updateRow(selectedIndex, statusSale)"
    />

    <!-- Modal for configuration Contract Fee -->
    <contract-fee-modal
      v-if="modal.contract_fee"
      :modal="modal"
      :contract-fee="modalData.contractFee"
      @close="modal.contract_fee = false"
      @reload="updateRow(selectedIndex, statusSale)"
    />

    <tracking-captured-by-modal
      v-if="modal.captuerd_by_tracking"
      :modal="modal"
      :captured-by-tracking="modalData.capturedByTracking"
    />

    <tracking-modal
      :modal="modal"
      :tracking="modalData.tracking"
      :typeClient="'lead'"
    />

    <!-- NOTES -->
    <component
      :is="modalData.notes.programSelected"
      v-if="modal.notes"
      :note-info="modalData.notes"
      @hide="closeModalNotes"
      @reload="updateRow(selectedIndex, statusSale)"
      @refreshNotes="keySalesMade = Math.random()"
    />

    <files-modal
      v-if="modal.files"
      :modal="modal"
      :files="modalData.files"
      @close="modal.files = false"
    />

    <not-solds-modal
      v-if="showNotSoldsModal"
      @close="showNotSoldsModal = false"
    />

    <!-- Modal for Generate Url Lead -->
    <url-modal v-if="modal.url" :modal="modal" :url="modalData.url" />

    <ModalInfo
      v-if="modalInfo"
      @closeModalInfo="modalInfo = false"
      :infoLead="infoLead"
    />

    <call-summary-modal
      v-if="showSummaryModal"
      :idNotes="itemSelected.notes_ids"
      :tab="'leads'"
      :saleData="itemSelected"
      @closeModal="showSummaryModal = false"
      @refreshTable="updateRow(selectedIndex, statusSale)"
    />

    <AnnulledSalesModalLeads
      v-if="showAnnulledModal"
      @close="showAnnulledModal = false"
    />
    <ModalReportSalesMade
      :dataReport="dataMonths"
      v-if="dataMonths.length > 0"
      type="MONTHS"
      @currentItem="currentItemReportSale"
      @close="dataMonths = []"
      :year="selectedYear"
    />
    <ModalReportSalesMade
      :dataReport="dataSalesMadeByPrograms"
      v-if="dataSalesMadeByPrograms.length > 0"
      type="PROGRAMS"
      @currentItem="currentItemReportSale"
      @close="dataSalesMadeByPrograms = []"
      :year="selectedYear"
    />
    <ModalReportSalesMadeDetailsLead
      :paramsGrid="paramsGridDetail"
      v-if="paramsGridDetail"
      @close="paramsGridDetail = null"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { salesMadeCounter } from "@/views/commons/commons-counter.js";
import CrmServices from "@/views/crm/services/crm.service";
import LeadService from "@/views/commons/components/leads/service/lead.service.js";
import SalesMadeService from "@/views/commons/components/sales-made/add-sale/service/sales-made-service.js";

import dataFields from "@/views/crm/views/sales-made/components/new-client/fields.data";
import dataFilters from "@/views/crm/views/sales-made/components/new-client/filters.data";
import CrmService from "@/views/crm/services/crm.service";
import { amgApi } from "@/service/axios";
import ApproveSupervisorModal from "@/views/crm/views/sales-made/components/modals/ApproveSupervisorModal.vue";

import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import Vue from "vue";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import AnalysisOptionBadge from "@/views/debt-solution/views/sales-made/components/AnalysisOptionBadge.vue";
import ModalPendingAppointments from "@/views/crm/views/Lead/components/modal/PendingAppointments.vue";

import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import ContractFeeModal from "@/views/crm/views/sales-made/components/modals/ContractFeeModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import FilesModal from "@/views/crm/views/sales-made/components/modals/FilesModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import InitialPaymentModal from "@/views/crm/views/sales-made/components/modals/InitialPaymentModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ModalHistorySms from "@/views/crm/views/Lead/lead-sms/ModalHistorySms.vue";
import ModalSendSms from "@/views/crm/views/Lead/lead-sms/ModalSendSms.vue";
import ModalNotesTax from "@/views/commons/components/first-notes/ModalNotesTax.vue";
import ModalNotesFirst from "@/views/commons/components/first-notes/ModalNotesFirst.vue";
import ModalNotesBoost from "@/views/commons/components/first-notes/ModalNotesBoost.vue";
import ModalNotesParagon from "@/views/commons/components/first-notes/ModalNotesParagon.vue";
import ModalNotesCredit from "@/views/commons/components/first-notes/ModalNotasCredit.vue";
import ModalNotesAll from "@/views/commons/components/first-notes/ModalNotesAll.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import RevissionModal from "@/views/crm/views/sales-made/components/modals/RevissionModal.vue";
import ModalNotesSpecialist from "@/views/commons/components/first-notes/ModalNotesSpecialist.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import TrackingCapturedByModal from "@/views/crm/views/sales-made/components/modals/TrackingCapturedByModal.vue";
import TrackingModal from "@/views/crm/views/sales-made/components/modals/TrackingModal.vue";
import UrlModal from "@/views/crm/views/sales-made/components/modals/UrlModal.vue";
import ValidateDateAndHour from "@/views/crm/views/sales-made/components/modals/ValidateDateAndHour.vue";
import RegionCreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/RegionCreditExpertsModal.vue";
import ModalInfo from "@/views/crm/views/sales-made/components/modals/ModalInfo.vue";
import Executives from "@/views/crm/views/sales-made/components/slots/Executives.vue";
import Client from "@/views/crm/views/sales-made/components/slots/Client.vue";
import Fee from "@/views/crm/views/sales-made/components/slots/Fee.vue";
import NotSoldsModal from "@/views/crm/views/sales-made/components/modals/NotSoldsModal.vue";
import CallSummaryModal from "@/views/crm/views/sales-made/components/modals/CallSummaryModal.vue";
import moment from "moment";
/* Services */
import NotesServices from "@/views/commons/components/first-notes/services/notes.service";
import AnnulledSalesModalLeads from "@/views/crm/views/sales-made/components/modals/AnnulledSalesModalLeads.vue";
import ProgramsCRMDone from "@/views/commons/sales-made/ProgramsCRMDone.vue";
import FilterButtonByMonth from "@/views/commons/sales-made/FilterButtonByMonth.vue";
import ModalReportSalesMade from "@/views/commons/sales-made/Modals/ModalReportSalesMade.vue";
import ModalReportSalesMadeDetailsLead from "@/views/commons/sales-made/Modals/ModalReportSalesMadeDetailsLead.vue";

export default {
  name: "SalesMadeNewComponent",
  components: {
    ApproveSupervisorModal,
    CoolLightBox,
    AnalysisOptionBadge,
    ModalPendingAppointments,
    BoostCreditModal,
    BusinessModal,
    ContractFeeModal,
    CreditExpertsModal,
    DebtSolutionModal,
    FilesModal,
    GeneralSupportModal,
    InitialPaymentModal,
    KeyBookModal,
    ModalHistorySms,
    ModalSendSms,
    ModalNotesBoost,
    ModalNotesParagon,
    ModalNotesFirst,
    ModalNotesTax,
    ModalNotesCredit,
    ModalNotesAll,
    ModalNotesSpecialist,
    ParagonModal,
    RevissionModal,
    SpecialistModal,
    TaxResearchModal,
    TrackingCapturedByModal,
    TrackingModal,
    UrlModal,
    ValidateDateAndHour,
    RegionCreditExpertsModal,
    ModalInfo,
    Executives,
    Client,
    Fee,
    NotSoldsModal,
    CallSummaryModal,
    AnnulledSalesModalLeads,
    ProgramsCRMDone,
    FilterButtonByMonth,
    ModalReportSalesMade,
    ModalReportSalesMadeDetailsLead,
  },
  props: {
    done: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      contactHourSecondsValidate48hours: null,
      items: [],
      selected: [],
      isBusy: false,
      fields: dataFields,
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filter: dataFilters,
      startPage: null,
      toPage: null,
      modal: {
        tracking: false,
        initial_payment: false,
        captuerd_by_tracking: false,
        files: false,
        programs: false,
        revission: false,
        url: false,
        contract_fee: false,
        notes: false,
        approveSupervisorModal: false,
        modalSms: false,
        modalValidateDateAndHour: false,
        binnacle: false,
      },
      modalData: {
        approveSupervisorModal: {
          modul: 2,
          typeApprove: 1,
          eventId: "",
        },
        binnacle: {},
        url: {
          client: "",
          program: "",
          amount: 0.0,
        },
        files: {
          id: null,
          program: "",
          client: "",
          sale_id: null,
          valorEdit: false,
        },
        historySms: {
          id: null,
          leadName: "",
        },
        tracking: {
          program: "",
          client: "",
          tabla: [],
        },
        initial_payment: {
          payments: null,
          nameProgram: null,
          nameClient: null,
          type: null,
          editmodal: null,
          statusSale: null,
          sessionId: null,
          valorInitalPayment: null,
          feeprops: null,
          modul: null,
          cfeestatus: null,
          idtransaction: null,
          programid: null,
          allcards: null,
          role_id: null,
          type_payment_ce: null,
        },
        capturedByTracking: {
          program: "",
          client: "",
          id: 0,
        },
        sendSms: {
          leads_sms: [],
          typesms: null,
          leads_sms_o: [],
          name_leads_arr: [],
        },
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
        revission: {},
        contractFee: {
          programName: "",
          clientName: "",
          saleId: null,
          id: null,
          initialPaymentStatus: null,
          editModal: false,
          statusSale: null,
          st: null,
          type_payment_ce: null,
          sales: null,
        },
        notes: {
          sales: null,
          programSelected: "",
          roleId: null,
          notesProgram: null,
          nameProgram: null,
          nameClient: null,
          salesMades: null,
          module: null,
          type: null,
          editModal: false,
          statusSale: null,
          sourcesName: null,
          pack: null,
          created: null,
          originCountry: null,
          idLead: null,
          notSeller: null,
          capturedName: null,
          sellerName: null,
          trackings: null,
          notes_status: null,
          notes_status_new: null,
        },
        validateDateAndHourData: {
          program: "",
          client: "",
          saleId: null,
          contactSchedule: null,
          contactHour: null,
        },
        isUrgent: false,
        showNotSoldsModal: false,
      },
      selectedIndex: null,
      newRowFromSelectedIndex: null,
      sellers: [],
      statusEmit: false,
      isActiveViewFlyer: false,
      isLoading: false,
      paramsToExport: null,
      modalInfo: false,
      infoLead: {
        leadName: "",
        docType: "-",
        country: "",
        workStatus: "",
        legalStatus: "",
        flyerCode: null,
        sourcesname: "",
      },
      showSecondStep: false,
      eventIdAnalysis: null,
      statusPendingAppointments: false,
      // done: 1,
      showNotSoldsModal: false,
      showSummaryModal: false,
      itemSelected: null,
      total: {},
      subtotal: {},
      isModuleHasChief: false,
      statusSale: null,
      showAnnulledModal: false,
      moduleIdTab: null,
      selectedYear: null,
      dataMonths: [],
      dataSalesMadeByPrograms: [],
      paramsGridDetail: null,
      monthName: null,
    };
  },
  computed: {
    ...mapState({
      captured: (state) => state["crm-store"].capturedCrm,
      programs: (state) => state["crm-store"].programs,
      sources: (state) => state["crm-store"].sources,
      sts: (state) => state["crm-store"].states,
      countries: (state) => state["crm-store"].countries,
      work_status: (state) => state["crm-store"].work_status,
      legal_status: (state) => state["crm-store"].legal_status,
      stip: (state) => state["crm-store"].statusip,
      status: (state) => state["crm-store"].statusNewClient,
      statusFilter: (state) => state["crm-store"].statusFilter,
    }),
    ...mapGetters({
      userCanSell: "auth/userCanSell",
      currentUser: "auth/currentUser",
      programsBySales: "CrmSaleMadeStore/G_PROGRAMS_CRM_DONE",
    }),
    filteredFields() {
      if (this.getFilterByKey("source_name").model != null) {
        if (this.getFilterByKey("source_name").model.length != 0) {
          this.getFilterByKey("source_name").model.forEach((element) => {
            if (element == 17) {
              this.getFilterByKey("sub_source").visible = true;
            }
          });
        } else {
          this.getFilterByKey("sub_source").model = null;
          this.getFilterByKey("sub_source").visible = false;
        }
      }
      if (this.statusTab === 1) {
        this.getFilterByKey("initial_payment").visible = true;
        this.getFilterByKey("st_ad").cols = 6;
      } else {
        this.getFilterByKey("initial_payment").visible = false;
        this.getFilterByKey("st_ad").cols = 12;
      }

      if (this.statusTab === 3) {
        this.getFilterByKey("year").visible = true;
      } else {
        this.getFilterByKey("year").visible = false;
      }
      if (this.done === 0) return this.fields.filter((field) => field.visible);
      // this.filterColumn("actions", this.statusTab !== 3);

      return this.fields.filter((field) => field.visible);
    },
    toRoute() {
      let route;
      switch (this.moduleId) {
        case 2:
          route = "lead-show";
          break;
        case 3:
          route = "bussiness-lead-show";
          break;
        case 4:
          route = "administration-sales-made-report";
          break;
        case 5:
          route = "debtsolution-lead-show";
          break;
        case 6:
          route = "creditexperts-lead-show";
          break;
        case 7:
          route = "boostcredit-lead-show";
          break;
        case 8:
          route = "tax-research-lead-show";
          break;
        case 10:
          route = "claimdepartment-lead-show";
          break;
        case 11:
          route = "specialist-lead-show";
          break;
        case 12:
          route = "ti-lead-show";
          break;
        case 14:
          route = "bookeeping-lead-show";
          break;
        case 20:
          route = "connection-lead-show";
          break;
        case 22:
          route = "customerservice-lead-show";
          break;
        case 23:
          route = "financial-leads-lead-show";
          break;
        case 26:
          route = "sales-lead-show";
          break;
        default:
          route = "";
          break;
      }
      return route;
    },
    isSalesmadeRoute() {
      return this.$route.path === "/crm/salesmade/leads/pending";
    },
    statusTab() {
      return this.$route.matched[3].meta.status;
    },
    // programId() {
    //   return this.$route.matched[1].meta.program;
    // },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
    isSellerRoles() {
      // return this.isSellerC || this.isAdvisorB || this.isFilter;
      if (this.isModuleHasChief) {
        return (
          this.isSellerC ||
          this.isAdvisorB ||
          this.isFilter ||
          this.isSupervisor
        );
      } else {
        return this.isSellerC || this.isAdvisorB || this.isFilter;
      }
    },
    isApprovingRoles() {
      if (this.isModuleHasChief) {
        return this.isChief || this.isCeo;
      } else {
        return this.isSupervisor || this.isCeo;
      }
    },
    filterYear() {
      return this.filter.length > 0 ? this.filter[0].model : null;
    },
    getParamsReport() {
      return {
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
        year: this.selectedYear,
        module_id: this.moduleId,
      };
    },
    getParamsGrid() {
      let filterPrograms =
        this.getFilterByKey("services").model != null
          ? this.getFilterByKey("services").model.toString()
          : "";
      let filterSourceName =
        this.getFilterByKey("source_name").model != null
          ? this.getFilterByKey("source_name").model.toString()
          : "";
      let filterStatusSeller =
        this.getFilterByKey("status_seller").model != null
          ? this.getFilterByKey("status_seller").model
          : null;

      return {
        text: this.filterPrincipal.model,
        status: this.statusTab,
        program: filterPrograms,
        state_h: this.getFilterByKey("st_ad").model,
        from: this.getFilterByKey("from").model,
        to: this.getFilterByKey("to").model,
        captured: this.getFilterByKey("captured_by").model,
        seller: this.getFilterByKey("seller").model,
        statusip: this.getFilterByKey("initial_payment").model,
        sourcesname_id: filterSourceName,
        subSource: this.getFilterByKey("sub_source").model,
        module_id:
          this.moduleId == 2
            ? this.moduleIdTab ?? this.moduleId
            : this.moduleId,
        origin_country: this.getFilterByKey("origin_country").model,
        work_status: this.getFilterByKey("work_status").model,
        legal_status: this.getFilterByKey("legal_status").model,
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
        year: this.selectedYear,
      };
    },
  },
  watch: {
    filterYear(newValue, oldValue) {
      const year = newValue;
      let min = null;
      let max = null;
      this.getFilterByKey("from").model = null;
      this.getFilterByKey("to").model = null;
      if (year) {
        min = `${year}-01-01`;
        max = `${year}-12-31`;
      }
      this.getFilterByKey("from").min = min;
      this.getFilterByKey("from").max = max;

      this.getFilterByKey("to").min = min;
      this.getFilterByKey("to").max = max;
    },
  },
  async mounted() {
    await this.destroySocket();
  },
  async created() {
    this.selectedYear = new Date().getFullYear();
    this.updateRowSocketSummary();
    await this.getValidateCallsMaxDate();
    // this.activeSocketBinnacleCont();
    await this.moduleHasChief();
    try {
      await Promise.all([
        this.moduleId === 26 ? this.getSalesSellers() : this.getSellers(),
        this.getAllCatchers(),
        this.$store.dispatch("crm-store/getPrograms"),
        this.$store.dispatch("crm-store/getSources"),
        this.$store.dispatch("crm-store/getStates"),
        this.$store.dispatch("crm-store/getCountries"),
      ]);
      const subSources = await LeadService.getSubSourcesSn();
      const subSourcesData = subSources.filter((data) => data.parent_id == 1);
      subSourcesData.unshift({
        id: "0",
        name: "ALL",
      });

      this.getFilterByKey("status_seller").options = [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
      ];

      this.getFilterByKey("source_name").options = this.sources;
      this.getFilterByKey("sub_source").options = subSourcesData;
      this.getFilterByKey("status").options = this.statusFilter;
      this.getFilterByKey("services").options = this.programs;
      this.getFilterByKey("initial_payment").options = this.stip;
      this.getFilterByKey("st_ad").options = this.sts;
      this.getFilterByKey("origin_country").options = this.countries;
      this.getFilterByKey("work_status").options = this.work_status;
      this.getFilterByKey("legal_status").options = this.legal_status;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async showAnnulledSolds() {
      this.showAnnulledModal = true;
    },
    async disapproveSaleMadeLeads(data) {
      try {
        const validateObservation = await this.$swal({
          title: "Your Observation",
          input: "textarea",
          showCancelButton: true,
          confirmButtonText: "Save",
          cancelButtonText: "Cancel",
          inputValidator: (observation) => {
            return !observation ? "Please enter your observation" : undefined;
          },
        });
        if (validateObservation.value) {
          const params = {
            status: this.assignStatusDisapprove(data),
            user_id: this.currentUser.user_id,
            sale_id: data.id,
            comments: validateObservation.value,
            status_tracking: this.setStatusTracking(data),
          };
          await SalesMadeService.disapproveSaleMadeLeads(params);
          this.$refs["new-client-done-table"].refresh();
          await salesMadeCounter();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    summaryModal(item, index) {
      this.selectedIndex = index;
      this.itemSelected = item;
      this.showSummaryModal = true;
    },
    openModalInfo(data) {
      this.infoLead.leadName = data.client;
      this.infoLead.docType = data.ssn
        ? "SSN"
        : data.itin
        ? "ITIN"
        : data.cpn
        ? "CPN"
        : "";
      this.infoLead.country = data.country_name;
      this.infoLead.workStatus = data.work_status;
      this.infoLead.legalStatus = data.legal_status;
      this.infoLead.flyerCode = data.flyer_code;
      this.infoLead.sourcesname = data.sourcesname;
      this.modalInfo = true;
    },
    async getAllCatchers() {
      if (this.moduleId === 26) {
        const data = await DerivationsService.getAllCeUsers();
        if (data.status === 200) {
          this.getFilterByKey("captured_by").options = data.data;
        }
      } else {
        await this.$store.dispatch("crm-store/getCaptured", {
          module: 2,
          body: {
            roles: "[]",
            type: "1",
          },
        }),
          (this.getFilterByKey("captured_by").options = this.captured);
      }
    },
    async getSellers() {
      const type =
        this.getFilterByKey("status_seller").model == null
          ? null
          : this.getFilterByKey("status_seller").model;

      const tempSellers = await CrmServices.getSellersCrm(2, {
        roles: "[1,5,2,3,13]",
        type: type,
      });

      const formatedSellers = tempSellers.map((seller) => ({
        id: seller.id,
        label: seller.user_name,
      }));

      this.sellers = [{ id: 0, label: "All" }];
      this.sellers.push(...formatedSellers);

      this.getFilterByKey("seller").options = this.sellers;
      this.getFilterByKey("seller").model = null;
    },
    async getSalesSellers() {
      const sellersItem = await DerivationsService.getSellerItem();
      if (sellersItem.status === 200) {
        const formatSeller = sellersItem.data.map((seller) => ({
          id: seller.id,
          label: seller.user_name,
        }));

        this.sellers = [];
        this.sellers.push(...formatSeller);

        this.getFilterByKey("seller").options = this.sellers;
        this.getFilterByKey("seller").model = null;
      }
    },
    async hideInitialPaymentModal(val) {
      this.modal.initial_payment = false;
      if (val) {
        await this.updateRow();
      }
    },
    closeModalApprove() {
      this.modal.approveSupervisorModal = false;
    },
    async returnDone(eventId, sendSupervisor) {
      this.modalData.approveSupervisorModal.eventId = Array.isArray(eventId)
        ? eventId[0]
        : eventId;
      if (this.isSellerC && sendSupervisor === null) {
        this.modal.approveSupervisorModal = true;
      } else {
        const result = await this.showConfirmSwal(
          "Are you sure?",
          "Are you sure set done this sale"
        );
        if (result.value) {
          try {
            const response = await SalesMadeService.updateSalesSetDone({
              eventId: this.modalData.approveSupervisorModal.eventId,
            });
            if (response.status === 200) {
              this.$refs["new-client-done-table"].refresh();
            }
          } catch (error) {
            this.showErrorSwal(error);
          }
        }
      }
    },
    async getValidateCallsMaxDate() {
      try {
        const data = await CrmService.getValidateCallsMaxDate();
        this.validateCalls = data.data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getSaleMade(ctx) {
      try {
        this.paramsToExport = ctx;
        const params = {
          ...this.getParamsGrid,
          per_page: ctx.perPage,
        };
        const { pagination, total, subtotal } =
          await CrmService.getSalesMadeLeads(params, ctx.currentPage);

        this.total = total;
        this.subtotal = subtotal;
        this.startPage = pagination.from ?? 0;
        this.toPage = pagination.to ?? 0;
        this.totalRows = pagination.total;
        this.items = pagination.data;
        this.items.forEach((item) => {
          item._rowVariant = this.paintRowDanger(item) ? "danger" : null;
        });
        return this.items;
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async exportExcel(typeExport) {
      let sortBy = 11;
      let sortDirection = "desc";
      if (this.paramsToExport.sortBy === "client") {
        sortBy = 0;
        if (this.paramsToExport.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (this.paramsToExport.sortBy === "creates") {
        if (this.paramsToExport.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      } else if (this.paramsToExport.sortBy === "approved") {
        sortBy = 12;
        if (this.paramsToExport.sortDesc) sortDirection = "desc";
        else sortDirection = "asc";
      }

      let filterPrograms =
        this.getFilterByKey("services").model != null
          ? this.getFilterByKey("services").model.toString()
          : "";
      let filterSourceName =
        this.getFilterByKey("source_name").model != null
          ? this.getFilterByKey("source_name").model.toString()
          : "";
      let filterStatusSeller =
        this.getFilterByKey("status_seller").model != null
          ? this.getFilterByKey("status_seller").model
          : null;

      const salesmade_ids = this.selected.map((sale) => sale.id);

      const params = {
        text: this.filterPrincipal.model,
        status: this.statusTab,
        program: filterPrograms,
        state_h: this.getFilterByKey("st_ad").model,
        from: this.getFilterByKey("from").model,
        to: this.getFilterByKey("to").model,
        captured: this.getFilterByKey("captured_by").model,
        seller: this.getFilterByKey("seller").model,
        statusip: this.getFilterByKey("initial_payment").model,
        sourcesname_id: filterSourceName,
        per_page: this.paramsToExport.perPage,
        page: this.paramsToExport.currentPage,
        subSource: this.getFilterByKey("sub_source").model,
        module_id: this.moduleId == 2
            ? this.moduleIdTab ?? this.moduleId
            : this.moduleId,
        origin_country: this.getFilterByKey("origin_country").model,
        work_status: this.getFilterByKey("work_status").model,
        legal_status: this.getFilterByKey("legal_status").model,
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
        type_export: typeExport,
        year: this.selectedYear,
      };

      try {
        this.isLoading = true;
        const response = await CrmService.exportSalesMadeToExcel(params);
        await this.forceFileDownload(response, "salesmade.xlsx");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isLoading = false;
      }
    },
    openContractFeeModal(data, index) {
      this.selectedIndex = index;

      this.statusSale = data.status;

      if (
        data.user_id == this.currentUser.user_id ||
        this.isCeo ||
        (this.isSupervisor && this.moduleId === 2 && data.from_module !== 26) ||
        (this.isSupervisor &&
          this.moduleId === 26 &&
          data.from_module === 26) ||
        this.isCoordinator ||
        this.isTeamLeader ||
        this.isAssistantSupervisor ||
        (this.isChief && this.moduleId === 2 && data.from_module !== 26) ||
        (this.isChief && this.moduleId === 26 && data.from_module === 26)
      ) {
        this.modalData.contractFee.editModal = true;
      } else {
        this.modalData.contractFee.editModal = false;
      }
      this.modalData.contractFee.type_payment_ce = data.type_payment_ce;
      this.modalData.contractFee.statusSale = data.status;
      this.modalData.contractFee.clientName = data.client;
      this.modalData.contractFee.programName = data.program;
      this.modalData.contractFee.id = data.lead_id;
      this.modalData.contractFee.saleId = data.id;
      this.modalData.contractFee.seller_id = data.seller_id;
      this.modalData.contractFee.initialPaymentStatus =
        data.initial_payment_status;
      this.modalData.contractFee.initialAmount = data.initial_amount;
      this.modal.contract_fee = true;
      this.modalData.contractFee.programId = data.program_id;
    },
    async notesModal(data, index) {
      this.addPreloader();
      this.modalData.notes.capturedName = data.captured;
      this.modalData.notes.sellerName = data.seller;
      this.modalData.notes.trackings = data.trackings;
      this.modalData.notes.nameProgram = data.program;
      this.modalData.notes.nameClient = data.client;
      this.modalData.notes.statusSale = data.status;
      this.modalData.notes.sourcesName = data.sourcesname;
      this.modalData.notes.pack = data.pack;
      this.modalData.notes.originCountry = data.origin_country;
      this.modalData.notes.idLead = data.lead_id;
      this.modalData.notes.created = data.creates;
      this.modalData.notes.saleId = data.id;
      this.modalData.notes.module = this.currentUser.modul_id;
      this.modalData.notes.rolId = this.currentUser.role_id;
      this.modalData.notes.notes_status = data.notes_status;
      this.modalData.notes.notes_status_new = data.notes_status_new;
      this.modalData.notes.seller_id = data.seller_id;
      this.modalData.notes.haveRates = data.haveRates;
      this.modalData.notes.programId = data.program_id;
      this.modalData.notes.event_id = data.event_id;
      this.modalData.notes.editModal =
        (data.from_module == this.moduleId ||
          (data.from_module == 0 && this.moduleId == 2)) &&
        (this.isChief || this.isSupervisor || this.isSeller || this.isAdvisorB);

      this.modalData.notes.notSeller =
        (data.user_id != this.currentUser.user_id && this.isSellerC) ||
        (this.isSupervisor && this.moduleId === 2 && data.from_module === 26) ||
        (this.isSupervisor &&
          this.moduleId === 26 &&
          data.from_module !== 26) ||
        (this.isChief && this.moduleId === 2 && data.from_module === 26) ||
        (this.isChief && this.moduleId === 26 && data.from_module !== 26);

      if (this.moduleId === 26) {
        this.modalData.notes.notSeller =
          data.seller_id != this.currentUser.user_id && this.isSellerC;
        this.modalData.notes.editModal =
          this.isCeo ||
          this.isSupervisor ||
          this.isAssistantSupervisor ||
          this.isCoordinator ||
          this.isTeamLeader ||
          this.currentUser.user_id == data.seller_id ||
          this.isChief;
      }

      this.openModalNotes(data.creates, data.program_id, index, data.status);
    },
    openModalNotes(created, program, index, status) {
      this.selectedIndex = index;

      this.statusSale = status;
      switch (true) {
        case created >= "2020-05-28" && program == 1:
          this.modalData.notes.programSelected = "ModalNotesFirst"; // ready
          break;
        case created >= "2020-10-29" && program == 2:
          this.modalData.notes.programSelected = "ModalNotesBoost"; // ready
          break;
        case created >= "2021-03-04" && program == 3:
          this.modalData.notes.programSelected = "ModalNotesCredit"; // ready
          break;
        case created >= "2020-09-24" && program == 5:
          this.modalData.notes.programSelected = "ModalNotesTax"; // ready
          break;
        case created >= "2020-10-23" && program == 7:
          this.modalData.notes.programSelected = "ModalNotesSpecialist"; // ready
          break;
        case program == 9:
          this.modalData.notes.programSelected = "ModalNotesParagon"; // ready
          break;
        default:
          // next
          this.modalData.notes.programSelected = "ModalNotesAll";
          break;
      }
      this.modal.notes = true;
    },
    async closeModalNotes(status) {
      this.modal.notes = false;
      if (status) {
        this.updateRow(this.selectedIndex);
        this.removePreloader();
        this.showSuccessSwal("OPERATION SUCCESSFULLY");
      }
    },
    async revisionSale(state, data, index, send = true) {
      this.selectedIndex = index;
      this.statusSale = this.assignStatus(data);
      this.modalData.revission.nameProgram = data.program;
      this.modalData.revission.idProgram = data.program_id;
      this.modalData.revission.client_type_id = data.client_type_id;
      this.modalData.revission.nameClient = data.client;
      this.modalData.revission.type = state;
      this.modalData.revission.idsales = data.id;
      this.modalData.revission.initialPayment = data.initial_payment_status;
      this.modalData.revission.account = data.account;
      this.modalData.revission.leadId = data.lead_id;
      this.modalData.revission.datevent = data.event_date;
      this.modalData.revission.sellerName = data.seller;
      this.modalData.revission.language = data.language;
      this.modalData.revission.user_id = this.currentUser.user_id;
      this.modalData.revission.last_name = data.last_name;
      this.modalData.revission.seller_id = data.seller_id;
      this.modalData.revission.comes_from = data?.comes_from;
      this.modalData.validateDateAndHourData.saleId = data.id;
      this.modalData.validateDateAndHourData.program = data.program;
      this.modalData.validateDateAndHourData.client = data.client;
      this.modalData.isUrgent = !!parseInt(data.is_urgent);
      this.modalData.revission.reject = !send;
      this.modalData.revission.status = data.status;
      this.modalData.revission.typeSale = data.type;

      if (!!parseInt(data.is_urgent)) {
        this.modal.revission = true;
      } else {
        await this.validateDateAndHourDataV();
        this.modal.revission = this.modal.modalValidateDateAndHour !== true;
      }

      if (!!parseInt(data.is_urgent)) {
        this.modal.revission = true;
      } else {
        await this.validateDateAndHourDataV();
        this.modal.revission = this.modal.modalValidateDateAndHour !== true;
      }
      Vue.set(this.items[index], "_rowVariant", null);
    },
    async validateDateAndHourDataV() {
      const data = await NotesServices.validateDateAndHourRevision({
        sale_id: this.modalData.revission.idsales,
      });
      if (data.status === 200) {
        if (data) {
          if (data.data.length === 2) {
            const now = moment();
            const exp = moment(`${data.data[0].answer} ${data.data[1].answer}`);

            const days = exp.diff(now, "hours");
            if (days <= 47) {
              this.modalData.validateDateAndHourData.contactDate =
                data.data[0].answer;
              this.modalData.validateDateAndHourData.contactHour =
                data.data[1].answer;
              this.modal.modalValidateDateAndHour = true;
              this.contactHourSecondsValidate48hours = false;
            } else {
              this.contactHourSecondsValidate48hours = true;
            }
          }
        }
      }
    },
    async updateRow(index = this.selectedIndex, status = this.statusSale) {
      //  item.type == 0
      //       ? this.isApprovingRoles
      //         ? 12
      //         : 13
      //       : this.isApprovingRoles
      //       ? 13
      //       : 2,
      if ([2, 12, 13].includes(status) && this.statusTab == 1) {
        this.$router.push({
          name: this.$route.matched[2].meta.routeForApprovedLead,
        });
      } else if (status == 4) {
        this.$router.push({
          name: this.$route.matched[2].meta.routeDoneLead,
        });
      } else {
        this.newRowFromSelectedIndex =
          await SalesMadeService.getSaleMadeBySaleId({
            saleId: this.items[index].id,
          });
        const newRow = this.newRowFromSelectedIndex[0];
        const keysNewRow = Object.keys(newRow);
        const keysOldRow = Object.keys(this.items[index]);
        keysNewRow.forEach((key) => {
          if (keysOldRow.includes(key)) {
            Vue.set(this.items[index], key, newRow[key]);
          }
        });
        const params = {
          text: this.filterPrincipal.model,
          status: this.statusTab,
          program:
            this.getFilterByKey("services").model != null
              ? this.getFilterByKey("services").model.toString()
              : "",
          state_h: this.getFilterByKey("st_ad").model,
          from: this.getFilterByKey("from").model,
          to: this.getFilterByKey("to").model,
          captured: this.getFilterByKey("captured_by").model,
          seller: this.getFilterByKey("seller").model,
          statusip: this.getFilterByKey("initial_payment").model,
          sourcesname_id:
            this.getFilterByKey("source_name").model != null
              ? this.getFilterByKey("source_name").model.toString()
              : "",
          per_page: this.paginate.perPage,
          subSource: this.getFilterByKey("sub_source").model,
          module_id: this.moduleId,
          origin_country: this.getFilterByKey("origin_country").model,
          work_status: this.getFilterByKey("work_status").model,
          legal_status: this.getFilterByKey("legal_status").model,
          user_id: this.currentUser.user_id,
          role_id: this.currentUser.role_id,
          year: this.getFilterByKey("year").model,
        };
        const { total, subtotal } = await CrmService.getSalesMadeLeads(
          params,
          this.paginate.currentPage
        );
        this.total = total;
        this.subtotal = subtotal;
      }
    },
    openTrackingModal(program, client, tabla, saleId) {
      this.modalData.tracking.program = program;
      this.modalData.tracking.client = client;
      this.modalData.tracking.saleId = saleId;
      if (tabla) {
        this.modalData.tracking.tabla = JSON.parse(tabla) || [];
        this.modal.tracking = true;
      }
    },
    openTrackingCapturedByModal(program, client, id, type) {
      this.modalData.capturedByTracking.client = client;
      this.modalData.capturedByTracking.program = program;
      this.modalData.capturedByTracking.id = id;
      this.modalData.capturedByTracking.type = type;
      if (type === 1) this.modalData.capturedByTracking.tittle = "CAPTURED BY";
      else if (type === 2) this.modalData.capturedByTracking.tittle = "SELLER";
      else if (type === 3) this.modalData.capturedByTracking.tittle = "FEE";
      this.modal.captuerd_by_tracking = true;
    },
    async openInitialPaymentModal(data, index) {
      const dataIP = {
        data,
        index,
      };
      try {
        this.statusSale = data.status;
        this.selectedIndex = index;
        this.addPreloader();
        this.modalData.initial_payment.other_method = data.other_method;
        this.modalData.initial_payment.programid = data.program_id;
        this.modalData.initial_payment.type_payment_ce = data.type_payment_ce;
        this.modalData.initial_payment.sessionId = this.currentUser.user_id;
        this.modalData.initial_payment.cfeestatus = data.contract_fee_status;
        this.modalData.initial_payment.id_transaction = data.transaction_id;
        this.modalData.initial_payment.editmodal =
          data.user_id == this.currentUser.user_id ||
          this.isCeo ||
          this.isSupervisor ||
          this.isCoordinator ||
          this.isTeamLeader ||
          (this.isChief && this.moduleId === 2 && data.from_module !== 26) ||
          (this.isChief && this.moduleId === 26 && data.from_module === 26) ||
          this.G_IS_ASSIST_SUPERVISOR ||
          (this.isSupervisor &&
            this.moduleId === 2 &&
            data.from_module !== 26) ||
          (this.isSupervisor &&
            this.moduleId === 26 &&
            data.from_module === 26);
        this.isChief;
        this.modalData.initial_payment.statusSale = data.status;
        this.modalData.initial_payment.nameProgram = data.program;
        this.modalData.initial_payment.nameClient = data.client;
        this.modalData.initial_payment.valorInitalPayment =
          data.initial_payment_status;
        this.modalData.initial_payment.feeprops = data.fee;
        const cards = await amgApi.post("/clients/search-cards-clients", {
          id: data.lead_id,
        });
        if (cards.status === 200) {
          this.modalData.initial_payment.allcards = cards.data;
        }
        const response = await amgApi.post(
          "/sales-made/get-payments-sales-made",
          { id: data.id }
        );
        if (response.status === 200) {
          [this.modalData.initial_payment.payments] = response.data;
        }
        this.modalData.initial_payment.modul = 2;
        this.modalData.initial_payment.role_id = this.currentUser.role_id;
        this.modal.initial_payment = true;
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },
    openModalProgram(data, index) {
      this.selectedIndex = index;
      this.statusSale = data.status;

      switch (data.program_id) {
        case 1:
          this.modalData.programs.programSelected = "business-modal";
          break;
        case 2:
          this.modalData.programs.programSelected = "boost-credit-modal";
          break;
        case 3:
          this.modalData.programs.programSelected = "credit-experts-modal";
          break;
        case 4:
          this.modalData.programs.programSelected = "debt-solution-modal";
          break;
        case 5:
          this.modalData.programs.programSelected = "tax-research-modal";
          break;
        case 6:
          this.modalData.programs.programSelected = "general-support-modal";
          break;
        case 7:
          this.modalData.programs.programSelected = "specialist-modal";
          break;
        case 8:
          this.modalData.programs.programSelected = "key-book-modal";
          break;
        case 9:
          this.modalData.programs.programSelected = "paragon-modal";
          break;
        default:
          break;
      }
      if (this.modalData.programs.programSelected !== "") {
        this.modalData.programs.typeModal =
          data.haveRates == 1 &&
          !(data.program_id == 4 && data.initial_payment_status == 1)
            ? 2
            : 1;
        this.modalData.programs.salesClient = data;
        this.modal.programs = true;
      }
    },
    openFilesModal(item) {
      this.modalData.files.id = item.lead_id;
      this.modalData.files.program = item.program;
      this.modalData.files.client = item.client;
      this.modalData.files.sale_id = item.id;
      this.modalData.files.programId = item.program_id;
      this.modalData.files.event_date = item.eventDate;

      const isCeoOrSupervisor =
        this.currentUser.role_id == "1" || this.currentUser.role_id == "2";
      if (
        (this.currentUser.user_id == item.sellerId ||
          isCeoOrSupervisor ||
          this.isChief ||
          this.isAssistantSupervisor) &&
        item.status != 4
      ) {
        this.modalData.files.valorEdit = this.moduleId != 16;
      }
      this.modal.files = true;
    },
    async saveNewSeller(seller, sellerNew, userId, user) {
      const [sellerNew2] = this.sellers.filter((val) => val.id === sellerNew);
      if (seller === sellerNew2.label) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "You can't select the same captured"
        );
      } else {
        this.addPreloader();
        try {
          const response = await CrmService.saveNewSeller({
            sel: sellerNew2.id,
            id: userId,
            user: this.currentUser.user_id,
            lead_client_derivation_id: user?.lead_client_derivation_id,
          });
          if (response.status === 200) {
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Se actualizo satisfactoriamente"
            );
          } else return;
          user.sellerNew = sellerNew2.id;
          user.seller_id = sellerNew2.id;
          user.seller = sellerNew2.label;
          user.editSeller = false;
          this.removePreloader();
        } catch (error) {
          this.showToast("danger", "top-right", "Error", "XIcon", error);
          this.removePreloader();
        }
      }
    },
    async saveNewFee(fee, feeNew, userId, user) {
      if (fee === feeNew) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "You can't select the same captured"
        );
      } else {
        this.$store.commit("app/SET_LOADING", true);
        try {
          const response = await CrmService.saveNewFee({
            fee: feeNew,
            id: userId,
            user: this.currentUser.user_id,
          });
          if (response.status === 200) {
            this.showSuccessSwal();
          } else return;
          user.fee = user.feeNew;
          user.feeNew = user.fee;
          user.editFee = false;
          this.$store.commit("app/SET_LOADING", false);
        } catch (error) {
          this.showToast("danger", "top-right", "Error", "XIcon", error);
          this.$store.commit("app/SET_LOADING", false);
        }
      }
    },
    async insertTrackingDerivation(information) {
      if (this.moduleId === 26) {
        const params = {
          lead_id: information.lead_id,
          sale_status_id: 7,
          seller_id: this.currentUser.user_id,
          sale_id: information.id,
        };
        await DerivationsService.insertTrackingSaleStatusDerivation(params);
      }
    },
    async annulSale(sale) {
      try {
        const swal = await this.showConfirmSwal(
          "Are you sure?",
          "Are you sure annuled this sale"
        );
        if (swal.isConfirmed) {
          const response = await amgApi.post("/sales-made/annul-sale", {
            id: sale.id,
            id_event: sale.event_id,
            user: this.currentUser.user_id,
          });
          if (response.status === 200) {
            await this.insertTrackingDerivation(sale);
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Your sale has been annulled successfully"
            );
            this.$refs["new-client-done-table"].refresh();
          } else {
            this.showErrorSwal();
          }
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "Error",
          "XIcon",
          "Some error, please try again or contact to support"
        );
      }
    },
    showContractFee(data) {
      if (
        data.item.initial_amount > 0 ||
        data.item.initial_payment_status != 1
      ) {
        this.openContractFeeModal(data.item, data.index);
      }
    },
    activeSocketBinnacleCont() {
      try {
        window.socket.subscribe("debtsolution-notifications");
        window.socket.bind(
          "binnacle-count-debtsolution-notifications",
          async (response) => {
            const { sale_id, origin } = response.message;
            const index = this.items.findIndex((item) => item.id == sale_id);
            if (index >= 0) {
              this.items[index].binnacle_origin = origin == 1 ? "DS" : "CRM";
              this.items[index].binnacle_cont++;
            }
          }
        );
      } catch (error) {
        console.log("Socket-binnacle: ", error);
      }
    },
    destroySocket() {
      return new Promise((resolve, reject) => {
        try {
          window.socket.unbind("binnacle-count-debtsolution-notifications");
          resolve(true);
        } catch (error) {
          reject(false);
          console.log(error);
        }
      });
    },
    validateIconService(program, haveFee, haveQuestionnaire, option) {
      switch (program) {
        case 4:
          if (option) {
            return haveQuestionnaire == "0";
          }
          return haveFee !== 1;

        default:
          return haveFee !== 1;
      }
    },
    getFilterByKey(key) {
      const filter = this.filter.find((filter) => filter.key === `${key}`);

      return filter;
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        this.updateRow();
      }
      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    modalSmsOpen(item) {
      this.modalData.sendSms.leads_sms = [];
      this.modalData.sendSms.typesms = 1;
      this.modalData.sendSms.leads_sms_o = [];
      this.modalData.sendSms.leads_sms_o.push(item.lead_id);
      this.modalData.sendSms.name_leads_arr = [
        { name: item.client, id: item.lead_id },
      ];
      this.modal.modalSms = true;
    },
    modalSmsClose() {
      this.modal.modalSms = false;
    },
    modalHistorySmsOpen(item) {
      this.modalData.historySms.leadName = item.client;
      this.modalData.historySms.id = item.lead_id;
      this.$bvModal.show("modal-history-sms");
    },
    openUrlModal(data) {
      this.modalData.url.client = data.client;
      this.modalData.url.program = data.program;
      this.modalData.url.selectedLead = data;
      this.modal.url = true;
    },
    isSellerPermissions(item) {
      return (
        (item.user_id == this.currentUser.user_id &&
          (this.isSellerC || this.userCanSell[0].can_sell == 1)) ||
        this.isCeo ||
        this.isSupervisor ||
        this.isChief ||
        this.isTeamLeader ||
        this.isCoordinator ||
        this.isAssistantSupervisor ||
        this.isAdministrator
      );
    },
    isSellerMethod(item) {
      return (
        item.user_id == this.currentUser.user_id ||
        this.isSellerC ||
        this.userCanSell[0].can_sell == 1 ||
        this.isCeo ||
        this.isSupervisor ||
        this.isChief ||
        this.isCoordinator ||
        this.isTeamLeader ||
        this.isAssistantSupervisor
      );
    },
    messageForCurrentStatus(item) {
      const statusMessages = {
        1:
          item.user_id == this.currentUser.user_id
            ? this.isApprovingRoles
              ? item.type == 0
                ? "Send to program"
                : "Send to revision"
              : "Send to revision"
            : "Pending by seller",
        3:
          this.isAdvisorB && this.moduleId !== 2
            ? "Send to program"
            : `Disapproved by supervisor (${item.initial_from_module})`,
        2:
          this.isApprovingRoles &&
          this.moduleId !== 2 &&
          this.programId !== item.program_id
            ? "Send to C.R.M"
            : `Pending approval supervisor (${item.initial_from_module})`,
        13:
          item.type == 1
            ? this.moduleId == 2
              ? `Send to program (${item.program_initials})`
              : "Pending approval from C.R.M"
            : this.isApprovingRoles
            ? "Send to program"
            : "Pending to be reviewed by supervisor (C.R.M)",
        16:
          this.isApprovingRoles && this.moduleId != 2
            ? "Send to CRM"
            : "Disapproved by C.R.M",
        12:
          item.program_id == this.programId && this.isApprovingRoles
            ? "Approve"
            : "Pending approval (supervisor-program)",
        14:
          this.isApprovingRoles && this.moduleId == 2
            ? "Send to program"
            : "Disapproved by program",
        15: this.isSellerRoles ? "Send to program" : "Pending by seller",
      };

      return statusMessages[item.status] || "Unknown status";
    },

    messageForDisapproved(data) {
      const statusSale = {
        3: "Disapproved by chief",
        14: "Disapproved by program",
        15: "Disapproved by C.R.M",
        16: "Disapproved by C.R.M",
      };
      return statusSale[data.status] || "unknown";
    },

    validateInfoIcon(item) {
      const statusValidate = {
        1: item.user_id !== this.currentUser.user_id && !this.isSellerRoles,
        // 2:
        //   this.isSellerRoles ||
        //   this.programId == item.program_id ||
        //   (this.moduleId === 2 && this.isApprovingRoles),
        2:
          this.isSellerRoles ||
          (this.programId == item.program_id &&
            item.module_id != this.moduleId) ||
          (this.moduleId === 2 && this.isApprovingRoles),
        3:
          this.moduleId === 2 || (this.moduleId !== 2 && this.isApprovingRoles),
        12:
          this.isSellerRoles ||
          (this.moduleId === 2 && this.isApprovingRoles) ||
          item.program_id !== this.programId,
        13:
          item.type == 1
            ? this.isSellerRoles ||
              (this.moduleId !== 2 && this.isApprovingRoles)
            : (this.moduleId !== 2 && this.isApprovingRoles) ||
              this.isSellerRoles,
        14:
          this.isSellerRoles || (this.moduleId !== 2 && this.isApprovingRoles),
        15: !(this.isSellerC || this.isFilter),
        16:
          this.isSellerRoles || (this.moduleId === 2 && this.isApprovingRoles),
      };

      return statusValidate[item.status];
    },
    assignStatus(item) {
      const statusSale = {
        1:
          item.type == 0
            ? this.isApprovingRoles
              ? 12
              : 13
            : this.isApprovingRoles
            ? 13
            : 2,
        2: 13,
        3: 2,
        12: 4,
        13: 12,
        14: 12,
        15: 13,
        16: 13,
      };
      return statusSale[item.status] || "Error";
    },

    assignStatusDisapprove(item) {
      const statusSale = {
        2: 3,
        13: item.type == 0 ? 15 : 16,
        12: 14,
      };
      return statusSale[item.status] || "Error";
    },
    isCompleteProcessPending(item) {
      return (
        (item.initial_payment_status === 2 ||
          (item.initial_payment_status === 1 && item.initial_amount > 0)) &&
        item.contract_fee_status == 1 &&
        item.notes_status_new == 0
        &&
        (item.note_transcription_status == "COMPLETED" ||
          this.compareDate(item.creates))
      );
    },
    compareDate(creates) {
      let diff = new Date(creates) < new Date(this.validateCalls);
      return diff;
    },
    getApprovalIconColor(item) {
      const statusClasses = {
        1: "text-success",
        2: "text-success",
        3: "text-warning",
        15: "text-warning",
        13: "text-success",
        12: "text-success",
        14: "text-warning",
        16: "text-warning",
      };

      return statusClasses[item.status] || "text-secondary";
    },
    filterColumn(key, tab) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = tab;
    },
    setStatusTracking(item) {
      const statusSale = {
        2: 3,
        13: item.type == 0 ? 10 : 11,
        12: 9,
      };
      return statusSale[item.status] || "Error";
    },
    async moduleHasChief() {
      const response = await CrmService.moduleHasChief({
        module_id: this.moduleId,
      });
      this.isModuleHasChief = response;
      return response;
    },
    paintRowDanger(item) {
      try {
        const { status, module_id } = item;
        const sellerRol = [3, 5];
        const chiefSupervisor = [2, 17];
        let rolId;
        if (this.moduleId != 20) {
          const { role_id } = this.currentUser;
          rolId = role_id;
        } else {
          const { role_id } = this.currentUser;
          rolId = role_id === 2 ? 3 : role_id;
        }
        const isModuleMatch = module_id === this.moduleId;

        const isSeller =
          status === 3 && isModuleMatch && sellerRol.includes(rolId);
        const isChiefOrigin =
          status === 16 && isModuleMatch && chiefSupervisor.includes(rolId);
        const isChiefCRM =
          status === 14 &&
          this.moduleId === 2 &&
          chiefSupervisor.includes(rolId);
        const isSellerCrm =
          status === 15 && this.moduleId === 2 && sellerRol.includes(rolId);
        return isSeller || isChiefOrigin || isChiefCRM || isSellerCrm;
      } catch (ex) {
        return false;
      }
    },
    async updateRowSocketSummary() {
      try {
        window.socket.bind("update-salemade-summary", async ({ data }) => {
          const found = this.items.find((note) => {
            if (note.notes_ids != null) {
              let parsedNote = JSON.parse(note.notes_ids);
              let noteId = parsedNote[0];

              return noteId == data.note_id;
            }
            return false;
          });

          if (found) {
            let index = this.items.findIndex((note) => {
              if (note.notes_ids != null) {
                let parsedNote = JSON.parse(note.notes_ids);
                let noteId = parsedNote[0];

                return noteId == data.note_id;
              }
            });

            if (index !== -1) {
              await this.updateRow(index);
            }
          }
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    changeTab(tab) {
      this.moduleIdTab = tab.module_id ?? 2;
    },
    changeYear(year) {
      this.selectedYear = year;
      this.$refs["new-client-done-table"].refresh();
    },
    async loadByMonth() {
      await this.getDataReportByMonths();
    },
    async getDataReportByMonths() {
      this.addPreloader();
      try {
        const months = await CrmService.getReportSalesMadeByMonths(
          this.getParamsReport
        );
        this.dataMonths = months;
        this.removePreloader();
      } catch (ex) {
        this.removePreloader();
      }
    },
    obtenerNumeroAleatorio(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    obtenerMontoAleatorio(min, max) {
      const numeroAleatorio = (Math.random() * (max - min) + min).toFixed(2);
      return parseFloat(numeroAleatorio);
    },
    async currentItemReportSale(item) {
      try {
        this.addPreloader();
        const { type } = item;
        if (type === "MONTHS") {
          this.monthName = item.name;
          const data = await CrmService.getReportSalesMadePrograms({
            ...this.getParamsReport,
            month: item.id,
          });
          this.dataSalesMadeByPrograms = data;
        } else {
          this.paramsGridDetail = {
            ...this.getParamsReport,
            page: 1,
            per_page: item.total + 1000,
            status: this.statusTab,
            ...item,
            month: item._month,
            year: item._year,
            monthName: this.monthName,
          };
        }
        this.removePreloader();
      } catch (ex) {
        this.removePreloader();
      }
    },
  },
  beforeDestroy() {
    try {
      window.socket.unbind("update-salemade-summary");
    } catch (error) {
      console.log("update-salemade-summary", error);
    }
  },
};
</script>

<style lang="scss" scoped>
.charge__programs {
  width: 47px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 35px;
    height: 35px;
  }
}

.charge__programs--alert {
  img {
    filter: grayscale(100%);
    opacity: 0.8;
  }
}

.table-new-customization::v-deep tfoot {
  position: sticky;
  bottom: 0;
  color: rgba(63, 122, 250, 0.882) !important;
  font-size: 14.5px;
  font-weight: bold !important;
}

.table-new-customization::v-deep tfoot tr th {
  font-size: 15px;
}

.table-new-customization::v-deep tfoot tr th:first-child {
  border-radius: 0 0 0 15px !important;
}

.table-new-customization::v-deep tfoot tr th:last-child {
  border-radius: 0 0 15px 0 !important;
}
</style>
