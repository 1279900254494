<template>
  <b-modal
    v-model="onControl"
    title-tag="h3"
    modal-class="new-add-note-v1"
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    scrollable
    no-close-on-backdrop
    @hidden="close"
    header-class="new-header-note-v1"
    content-class="new-content-note-v1"
  >
    <template #modal-header>
      <div class="header-content">
        <div>{{ title }}</div>
        <div class="close-icon" @click="close">
          <feather-icon icon="XIcon" size="20"></feather-icon>
        </div>
      </div>
    </template>

    <validation-observer ref="form">
      <b-container class="px-2">
        <b-row class="client-info">
          <b-col cols="6">
            <div class="new-input-v1">
              <div class="label">Client</div>
              <b-form-input :value="nameClient" disabled />
            </div>
          </b-col>
          <b-col cols="6">
            <div class="new-input-v1">
              <div class="label">Date</div>
              <div class="input">
                {{ note.date | myDateGlobal2 }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="content-form m-0 mb-1 align-items-center">
          <b-col
            cols="12"
            class="font-weight-bolder"
            style="font-size: 14px; margin-bottom: 5px"
          >
            1. Select any option
            <span
              class="text-danger"
              style="font-weight: 500; font-size: 13px"
              v-if="note.type == null"
            >
              (Required)
            </span>
          </b-col>
          <b-col>
            <validation-provider
              v-slot="{ errors, valid }"
              name="type"
              rules="required"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text style="min-width: 80px">
                    <div class="d-flex justify-content-center w-100">Type</div>
                  </b-input-group-text>
                </template>
                <b-form-select
                  v-if="!editing"
                  v-model="note.type"
                  :class="
                    errors[0] ? 'border-danger' : valid ? '' : 'border-danger'
                  "
                  :options="typesOptions"
                />
                <b-form-select
                  v-if="editing"
                  v-model="note.type"
                  :disabled="editing && !draftModal"
                  :class="
                    errors[0] ? 'border-danger' : valid ? '' : 'border-danger'
                  "
                  :options="typesOptions"
                />
              </b-input-group>
            </validation-provider>
          </b-col>
          <b-col v-if="!editing && !isConversation && !isNegotiation" cols="6">
            <b-row>
              <b-col cols="11" style="padding-right: 2rem">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text style="min-width: 120px">
                      <div class="d-flex justify-content-center w-100">
                        Quick Notes
                      </div>
                    </b-input-group-text>
                  </template>
                  <b-skeleton v-if="isLoading" class="ww-66" type="input" />
                  <b-form-input
                    v-if="quickNotesList.length === 0 && !isLoading"
                    value="No information"
                    disabled
                  />

                  <b-form-select
                    v-if="quickNotesList.length !== 0 && !isLoading"
                    v-model="strQN"
                    :options="quickNotesList"
                    text-field="title"
                    value-field="body"
                    @input="quickNoteOnChange"
                  />
                </b-input-group>
              </b-col>
              <b-col cols="1">
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="primary"
                    class="btn-icon"
                    @click="openMaintenanceQuickNotes"
                  >
                    <feather-icon icon="FileTextIcon" />
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row
          class="content-form m-0 mb-1"
          v-if="!isConversation && !isNegotiation"
        >
          <b-col
            cols="12"
            class="font-weight-bolder"
            style="font-size: 14px; margin-bottom: 5px"
          >
            2. Write your message and optimize it with our ai
            <span
              class="text-danger"
              style="font-weight: 500; font-size: 13px"
              v-if="!useOptimize"
            >
              (Required)
            </span>
          </b-col>
          <b-col>
            <SmartNoteInput
              ref="smartNoteInput"
              v-model="note.content"
              :rows="6"
              :placeholder="'Write new message'"
              required
              @valid="handleValid"
              @original="handleOriginalText"
              no-empty
              :show-over="false"
              :disabled="disableContentEdit"
            />
          </b-col>
        </b-row>

        <b-row
          v-if="!isConversation && !isNegotiation"
          class="content-form m-0 mb-1"
        >
          <b-col
            cols="12"
            class="font-weight-bolder"
            style="font-size: 14px; margin-bottom: 5px"
          >
            3. Select file
            <span class="text-muted" style="font-weight: 500; font-size: 13px">
              (Optional)
            </span>
          </b-col>
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="content"
              :rules="[1, 2].includes(typeAccess) ? 'required' : ''"
            >
              <b-input-group class="mt-2 mb-1">
                <template #prepend>
                  <b-input-group-text style="width: 126px">
                    Attach file
                  </b-input-group-text>
                </template>

                <b-form-file
                  v-model="file1"
                  :class="{ 'border-danger rounded': errors[0] }"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  :accept="[1, 2].includes(typeAccess) ? 'audio/*' : '*'"
                  :state="errors[0] ? false : null"
                  @input="fileFill"
                />
              </b-input-group>
            </validation-provider>
            <a v-if="note.url_file != null" class="mt-2 mb-2 ml-1">{{
              note.url_file
            }}</a>
          </b-col>
        </b-row>

        <b-row
          v-if="(isConversation || isNegotiation) && !editing && !draftModal"
          class="content-form m-0 mb-1"
        >
          <b-col
            cols="12"
            class="font-weight-bolder"
            style="font-size: 14px; margin-bottom: 5px"
          >
            2. Search a call
            <span
              class="text-danger"
              style="font-weight: 500; font-size: 13px"
              v-if="!hasSearch"
            >
              (Required)</span
            >
          </b-col>
          <b-col>
            <calls-in-rc
              ref="callRcComponent"
              :client-phone-original="client.mobile"
              @hasSearch="hasSearch = true"
              @countCalls="countCalls = $event"
              :saving="saving"
              :is-negotiation="isNegotiation"
              v-model="callNumbers"
            ></calls-in-rc>
          </b-col>
        </b-row>
      </b-container>
    </validation-observer>
    <template #modal-footer>
      <b-button
        variant="primary"
        @click="saveNote(false)"
        :disabled="
          [1, 10].includes(note.type)
            ? countCalls === 0
            : !(useOptimize || note.content)
        "
      >
        <feather-icon
          v-if="countDown == 0"
          icon="SaveIcon"
          style="margin-right: 0.3rem"
        />
        <span v-else>({{ countDown }})</span>
        Publish
      </b-button>
    </template>
    <list-manteinance-quick-notes
      v-if="maintenanceQuickNotesCont"
      :items-data="quickNotesList"
      :from-credit-experts-digital="fromCreditExpertsDigital"
      @close="maintenanceQuickNotesCont = false"
      @reload="getQuickNotes"
    />
  </b-modal>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import vSelect from "vue-select";
import ListManteinanceQuickNotes from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListManteinanceQuickNotes.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import ClientsDashboardServices from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";
import FinancialCommissionsService from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service";
import CallsInRc from "@/views/commons/components/ring-central/call-log/components/CallsInRC.vue";
import SmartNoteInput from "@/commons/smart-input/SmartNoteInput.vue";
export default {
  components: {
    quillEditor,
    vSelect,
    ListManteinanceQuickNotes,
    CallsInRc,
    SmartNoteInput,
  },
  props: {
    nameClient: null,
    track_id: null,
    date_sms: null,
    noteData: null,
    editing: null,
    fromCreditExpertsDigital: {
      type: Boolean,
      default: false,
    },
    typeAccess: {
      // type of access, welcome, letters, ncr, etc
      type: Number | Boolean,
      default: null,
    },
    isLastNote: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      countDown: 0,
      content: "",
      file1: null,
      onControl: false,
      disabled: false,
      note: {
        content: null,
        date: null,
        type: null,
        image: null,
        namedoc: null,
        size: null,
        status_note: null,
        account: null,
        user_id: null,
        modul: null,
        url_file: null,
        original_text: null,
      },
      editorOption: {
        modules: { toolbar: true },
      },
      typesOptions: [
        { value: 1, text: "CONVERSATION" },
        { value: 2, text: "SMS" },
        { value: 3, text: "COMMENTARY" },
        { value: 4, text: "EMAIL" },
      ],
      quickNotesList: [],
      isLoading: false,
      strQN: "",
      maintenanceQuickNotesCont: false,
      title: "Add Note",
      draftModal: false,
      disableContentEdit: false,
      typeAccesses: [1, 2, 3, 4, 5, 7, 11], // 2 search, 3 letters information, 4 letters answers and update request, 5 report recommendations
      callNumbers: {
        module_number: null,
        lead_number: null,
        transfer: false,
      },
      hasSearch: false,
      countCalls: 0,
      saving: false,
      smartNoteValid: null,
      useOptimize: false,
    };
  },
  async created() {
    this.onControl = true;
    this.note.account = this.$route.params.idClient;
    this.note.user_id = this.currentUser.user_id;
    this.note.modul = this.moduleId;
    this.note.date = moment().format("YYYY-MM-DD");
    if (this.editing) {
      await this.getEditNote(this.noteData.id);
      if (this.note.note_status === "DRAFT") this.draftModal = true;
      this.title = "EDIT NOTE";
    } else {
      this.note.type = 1;
    }
    await this.getQuickNotes();
  },
  mounted() {
    if (this.moduleId == 5) {
      this.typesOptions.push({ value: 10, text: "NEGOTIATION" });
    }
  },
  methods: {
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
      A_SET_FINISHED_DASHBOARD_ACTION:
        "DebtSolutionClients/A_SET_FINISHED_DASHBOARD_ACTION",
    }),

    startCountdown() {
      this.countDown = 60;
      setInterval(() => {
        if (this.countDown > 0) {
          this.countDown--;
        }
      }, 1000);
    },

    handleValid(state) {
      this.smartNoteValid = state;
    },
    handleOriginalText(text) {
      this.note.original_text = text;
      this.useOptimize = true;
    },
    close() {
      this.$emit("close");
    },
    async getEditNote(id) {
      try {
        const data = await ClientsOptionsServices.getNoteToEdit({
          id,
        });
        if (data.status === 200) {
          this.note = data.data[0];
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async fileFill() {
      this.note.namedoc = this.file1.name;
      this.note.size = this.file1.size;
      const reader = new FileReader();
      reader.readAsDataURL(this.file1);
      reader.onload = async (file) => {
        this.note.image = file.target.result;
      };
    },
    async getQuickNotes() {
      try {
        this.isLoading = true;
        const data = await ClientsOptionsServices.getQuickNotes({
          p_user_id: this.currentUser.user_id,
          p_module_id: this.moduleId,
        });
        if (data.status === 200) {
          this.quickNotesList = [];
          if (this.moduleId === 22) {
            this.quickNotesList.push({
              title: "Others",
              body: "",
            });
          }
          this.quickNotesList = [...this.quickNotesList, ...data.data];
          this.isLoading = false;
        }
      } catch (e) {
        this.isLoading = false;
        this.showErrorSwal(e);
      }
    },
    validarLongitudEnBytes(texto) {
      const maxBytes = 4078630;
      const bytes = Buffer.byteLength(texto, "utf8");

      return bytes <= maxBytes;
    },
    async saveNote(isDraft) {
      try {
        const res = await this.$refs.form.validate();

        if (!this.validarLongitudEnBytes(this.note.content)) {
          this.showInfoSwal("Important", "Note content is too long");
          return;
        }
        // isConversation
        if (
          (this.isConversation || this.isNegotiation) &&
          !this.editing &&
          !this.draftModal
        ) {
          const callRcComponent = this.$refs.callRcComponent;
          const isValidCallRcComponent = await callRcComponent.validateForm();
          if (!isValidCallRcComponent) {
            callRcComponent.$el.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            return false;
          }
        }
        this.saving = true;

        if (!this.hasSearch && (this.isConversation || this.isNegotiation)) {
          this.showToast(
            "warning",
            "top-right",
            "Oops!",
            "XIcon",
            "You must search a call before saving the note"
          );
          return;
        }

        if (res) {
          this.addPreloader();
          this.note.status_note = isDraft ? 1 : 2;
          let data = null;

          const params = {
            date: this.note.date,
            content: this.note.content,
            original_text: this.note.original_text,
            image: this.note.image,
            namedoc: this.note.namedoc,
            account: this.$route.params.idClient,
            size: this.note.size,
            user_id: this.currentUser.user_id,
            type: this.note.type,
            modul: this.moduleId,
            status_note: this.note.status_note,
            url: this.note.url_file,
            id: this.note.id,
            last_note: this.isLastNote,
            alert_id: this.G_ID_ALERT_ACCEPTED,
            call_id: this.callId,
            module_number: this.callNumbers.module_number,
            lead_number: this.callNumbers.lead_number,
            transfer: this.callNumbers.transfer,
            countCalls: this.countCalls || 0,
          };

          if (this.editing) {
            data = await ClientsOptionsServices.editNote(params);
          } else {
            data = await ClientsOptionsServices.addNotesCredit(params);
          }
          if (!this.editing && this.typeAccess != null) {
            if (
              this.typeAccesses.includes(this.typeAccess) &&
              (this.isAgent || this.isAssistantSupervisor || this.isSupervisor)
            ) {
              if (!isDraft) {
                const params = {
                  commision_type: 2,
                  agent_id: this.currentUser.user_id,
                  client_account: this.$route.params.idClient,
                  module_id: this.moduleId,
                  slug: [11].includes(this.typeAccess)
                    ? "customer-tracking"
                    : null,
                };
                await FinancialCommissionsService.createCommissionCed(params);
              }

              await this.A_SET_FINISHED_DASHBOARD_ACTION(1);
              await this.changeToSessionActive();
              await ClientsDashboardServices.updateTrackingDashboard({
                tracking_id: this.G_TRACKING_ID,
              });
            }
          }
          this.$emit("reload");
          this.showSuccessSwal();
          this.close();
          if (isDraft && !this.editing) {
            if (this.note.type === 2 && this.track_id != null) {
              this.$emit(
                "callCheckStatusSms",
                this.track_id,
                3,
                2,
                this.date_sms
              );
            }
          }
        }
      } catch (e) {
        console.log(e);
        if (e.response.data.message == "Request rate exceeded") {
          this.showWarningSwal(
            e.response.statusText,
            "try again in one minute"
          );
          this.startCountdown();
        } else {
          this.showErrorSwal(e);
        }
      } finally {
        this.removePreloader();
      }
    },
    openMaintenanceQuickNotes() {
      this.maintenanceQuickNotesCont = true;
    },
    quickNoteOnChange() {
      this.note.content = this.strQN.replace(/\n/g, "<br>");
    },
    async changeToSessionActive() {
      try {
        const params = {
          created_by: this.currentUser.user_id,
          sub_module_id: this.moduleId,
          status_sub_module_id: 1,
        };
        const data = await this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(params);
        if (data.status === 200) {
          await this.$router.push({ name: "ce-dashboard-agent" });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_TRACKING_ID: "DashboardCustomerServiceStore/G_TRACKING_ID",
      client: "DebtSolutionClients/G_CLIENTS",
      G_UPDATED_REQUEST: "DebtSolutionClients/G_UPDATED_REQUEST",
      G_ID_ALERT_ACCEPTED: "AutoAlertStore/G_ID_ALERT_ACCEPTED",
    }),
    minDate() {
      if ([5, 6, 7].includes(this.moduleId)) {
        if (moment().format("D") > 5) {
          return new Date(
            `${moment().format("YYYY")}/${moment().format("M")}/${6}`
          );
        }
        return new Date(
          `${
            moment().format("M") == 1
              ? Number(moment().format("YYYY")) - 1
              : moment().format("YYYY")
          }/${moment().add(-1, "month").format("M")}/${6}`
        );
      }
      return new Date(1000, 1, 1);
    },
    maxDate() {
      if ([5, 6, 7].includes(this.moduleId)) {
        if (moment().format("D") > 5) {
          return new Date(
            `${
              moment().format("M") == 12
                ? Number(moment().format("YYYY")) + 1
                : moment().format("YYYY")
            }/${moment().add(1, "month").format("M")}/${5}`
          );
        }
        return new Date(
          `${moment().format("YYYY")}/${moment().format("M")}/${5}`
        );
      }
      return new Date(2050, 9, 1);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isConversation() {
      return this.note.type == 1;
    },
    isNegotiation() {
      return this.note.type == 10;
    },
  },
};
</script>

<style lang="scss">
.new-add-note-v1 {
  font-family: "Montserrat";

  #quill-editor-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  #quill-editor-reverse .ql-container {
    border-top: 1px solid #ccc !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom: none !important;
  }

  #quill-editor-reverse .ql-toolbar {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .new-content-note-v1 {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .new-header-note-v1 {
    background-color: #3f7afa !important;
    border-radius: 15px;

    .header-content {
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 20.19px;
      color: #ffffff;

      width: 100%;
      display: flex;
      justify-content: space-between;

      .close-icon {
        background-color: #ffffff;
        color: #0090e7;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  .client-info {
    background-color: #fafafa;
    border-radius: 5px;
    padding: 15px;
    margin: 0 0.1rem 15px 0.1rem;
  }

  .new-input-v1 {
    border-radius: 5px;
    border: 1px solid #232a46;
    min-height: 40px;
    color: #232a46;
    display: flex;
    align-items: center;

    .label {
      padding: 0 10px;
      margin-right: 10px;
      background-color: #fafafa;
      border-right: 1.5px solid #232a46;
    }

    input {
      background-color: #fafafa;
      border: none;
      color: #232a46;
    }
  }

  .prepend {
    border-radius: 0.357rem 0 0 0.357rem;
    height: 35px;
    margin-bottom: 0;
    color: #6e6b7b;
    background-color: transparent;
    border: 1px solid #d8d6de;
    padding-right: 0 !important;
  }
  .prepend-content-light {
    padding: 0.438rem 1rem;
    color: #6e6b7b;
    background-color: transparent;
    border-top: 1px solid #d8d6de;
    border-left: 1px solid #d8d6de;
    border-right: 1px solid #d8d6de;
    border-radius: 0.357rem 0.357rem 0 0;
  }
  .prepend-content-dark {
    padding: 0.438rem 1rem;
    color: #d8d6de;
    background-color: transparent;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 0.357rem 0.357rem 0 0;
  }
  .ww-66 {
    width: 66% !important;
  }
  .quill-br .ql-container {
    height: 200px !important;
  }
}

.dark-layout {
  .client-info {
    background: #252525;
  }
  .new-add-note-v1 {
    .client-info {
      background-color: #17171a;
    }

    .new-input-v1 {
      color: #ffffff;

      .label {
        background-color: #17171a;
      }

      input {
        background-color: #17171a;
        color: #ffffff;
      }
    }
  }
}
.content-form {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(155, 155, 155, 1);
}
</style>
