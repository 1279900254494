import { amgApi } from "@/service/axios";

class RingCentralService {

    async getCredentials() {
        try {
            const data = await amgApi.get(`/ring-central/credentials/all`);
            return data;
        } catch (error) {
            console.log("Something went wrong on RingCentralService.getCredentials:", error);
            throw error;
        }
    }

    async getCredentialsByModuleId(id, params) {
        try {
            const data = await amgApi.post(`/ring-central/credentials/module/${id}`, params);
            return data;
        } catch (error) {
            console.log("Something went wrong on RingCentralService.getCredentialsByModuleId:", error);
            throw error;
        }
    }

    async getCredentialsSmsByModuleId(id) {
        try {
            const data = await amgApi.get(`/ring-central/credentials/module/sms/${id}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on RingCentralService.getCredentialsSmsByModuleId:", error);
            throw error;
        }
    }

    async getCredentialsSmsFileByModuleId(id) {
        try {
            const data = await amgApi.get(`/ring-central/credentials/module/sms-file/${id}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on RingCentralService.getCredentialsSmsFileByModuleId:", error);
            throw error;
        }
    }

    async updateCredential(id, fields) {
        try {
            const data = await amgApi.patch(`/ring-central/credentials/${id}`, fields);
            return data;
        } catch (error) {
            console.log("Something went wrong on RingCentralService.updateCredential:", error);
            throw error;
        }
    }
}

export default new RingCentralService();
