<template>
  <div>
    <div class="mb-2 mt-1">
      <span class="d-flex align-items-center">
        <feather-icon
          icon="HomeIcon"
          size="18"
          class="text-primary cursor-pointer mr-1"
          @click="getFiles(null)"
        />
        <span>
          <span v-if="selectedFolder.file_name">/ &nbsp;</span>
          <span>{{
            selectedFolder.file_name ? selectedFolder.file_name : ""
          }}</span>
        </span>
      </span>
    </div>
    <b-table-simple responsive sticky-header="50vh" no-border-collapse class="table-new-customization">
      <b-thead>
        <b-tr>
          <b-th
            class="cursor-pointer"
            @click="getFiles(selectedFolder.id, 3, 'asc')"
          >
            <div class="d-flex justify-content-start">
              <span style="margin-right: 0.5rem">NAME</span>
              <div>
                <feather-icon
                  class="d-block"
                  :style="firstSortName ? 'color: black' : ''"
                  icon="ChevronUpIcon"
                  size="9"
                /><feather-icon
                  class="d-block"
                  :style="!firstSortName ? 'color: black' : ''"
                  icon="ChevronDownIcon"
                  size="9"
                />
              </div>
            </div>
          </b-th>
          <b-th># FILES / SIZE</b-th>
          <b-th>DOE</b-th>
          <b-th
            class="cursor-pointer"
            @click="getFiles(selectedFolder.id, 7, 'asc')"
            ><div class="d-flex justify-content-start">
              <span style="margin-right: 0.5rem">UPLOAD BY</span>
              <div>
                <feather-icon
                  class="d-block"
                  icon="ChevronUpIcon"
                  size="9"
                  :style="firstUploadBy ? 'color: black' : ''"
                />
                <feather-icon
                  class="d-block"
                  icon="ChevronDownIcon"
                  size="9"
                  :style="!firstUploadBy ? 'color: black' : ''"
                />
              </div></div
          ></b-th>
          <b-th>MODIFIED BY</b-th>
          <b-th>Bureau</b-th>
          <b-th v-if="!onlyRead" class="text-center"> ACTIONS </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-if="isBusy">
          <b-th colspan="7">
            <div v-if="isBusy" class="w-100 text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </b-th>
        </b-tr>
        <template v-else>
          <b-tr
            v-for="(item, index) in files.filter(
              (file) => file.type == 'Folder'
            )"
            :key="index"
          >
            <b-td v-if="!onlyRead && lengthFilteredFiles > 0" />
            <b-td>
              <span
                v-if="selectedFile.id !== item.id"
                @click="searchFolder(item)"
                ><amg-icon
                  icon="CustomFolderIcon"
                  style="fill: #ff9f43"
                  class="mr-50 text-warning"
                  size="15"
                /><span class="cursor-pointer">{{ item.file_name }}</span></span
              >
              <span v-else>
                <b-form-group>
                  <b-form-input
                    v-model="selectedFile.file_name"
                    class="d-inline"
                    style="width: 70%"
                    @keyup.enter="updateFolder(selectedFile)"
                  />
                  <tabler-icon
                    icon="CheckIcon"
                    size="20"
                    class="text-success ml-1 cursor-pointer"
                    @click="updateFolder(selectedFile)"
                  />
                  <tabler-icon
                    icon="XIcon"
                    size="20"
                    class="text-danger ml-1 cursor-pointer"
                    @click="selectedFile = []"
                  />
                </b-form-group>
              </span>
            </b-td>
            <b-td
              ><span>{{ item.size }}</span></b-td
            >
            <b-td>
              <span
                v-if="item.expiration != null"
                :class="
                  item.valid == 0
                    ? 'text-dark'
                    : item.valid == 1
                    ? 'text-warning'
                    : item.valid == 2
                    ? 'text-danger'
                    : ''
                "
              >
                {{ item.expiration | myGlobal }}
              </span>
            </b-td>
            <b-td
              ><span
                >{{ item.user_name }} <br />
                {{ item.created_at | myGlobalDay }}
              </span></b-td
            >
            <b-td
              ><span v-if="item.update_name != null">{{
                item.update_name
              }}</span>
              <br />
              <span v-if="item.update_name != null">{{
                item.updated_at | myGlobalDay
              }}</span>
            </b-td>
            <b-td>-</b-td>
            <b-td v-if="!onlyRead">
              <div class="d-flex justify-content-center">
                <feather-icon
                  v-if="!(item.file_name == 'APP' || isFolderApp)"
                  size="18"
                  class="cursor-pointer text-warning mr-1"
                  icon="EditIcon"
                  @click="selectItemToEdit(item)"
                />
                <feather-icon
                  v-if="!(item.file_name == 'APP' || isFolderApp)"
                  size="18"
                  class="cursor-pointer text-danger"
                  icon="TrashIcon"
                  @click="deleteCompanyFile(item, true)"
                />
              </div>
            </b-td>
          </b-tr>
          <b-tr
            v-for="(item, index) in files.filter((file) => file.type == 'File')"
            :key="'n' + index"
          >
            <b-td>
              <a
                v-if="selectedFile.id !== item.id"
                :href="item.route_pdf"
                target="_blank"
              >
                <amg-icon
                  icon="CustomFileIcon"
                  :style="item.type === 'Folder' ? 'fill: #ff9f43' : ''"
                  class="mr-50"
                  :class="{ 'text-warning': item.type === 'Folder' }"
                  size="15"
                /><span>{{ item.file_name }}</span>
              </a>
              <span v-else>
                <b-form-group>
                  <b-form-input
                    v-model="selectedFile.file_name"
                    class="d-inline"
                    style="width: 70%"
                    @keyup.enter="updateFile(selectedFile)"
                  />
                  <tabler-icon
                    icon="CheckIcon"
                    size="20"
                    class="text-success ml-1 cursor-pointer"
                    @click="updateFile(selectedFile)"
                  />
                  <tabler-icon
                    icon="XIcon"
                    size="20"
                    class="text-danger ml-1 cursor-pointer"
                    @click="selectedFile = []"
                  />
                </b-form-group>
              </span>
            </b-td>
            <b-td
              ><span>{{ item.size }} KB</span></b-td
            >
            <b-td>
              <span
                v-if="item.expiration != null"
                :class="
                  item.valid == 0
                    ? 'text-dark'
                    : item.valid == 1
                    ? 'text-warning'
                    : item.valid == 2
                    ? 'text-danger'
                    : ''
                "
              >
                {{ item.expiration | myGlobal }}
              </span>
            </b-td>
            <b-td
              ><span
                >{{ item.user_name }} <br />
                {{ item.created_at | myGlobalDay }}
              </span></b-td
            >
            <b-td>
              <span v-if="item.update_name != null">{{
                item.update_name
              }}</span>
              <br v-if="item.update_name != null" />
              <span v-if="item.update_name != null">{{
                item.updated_at | myGlobalDay
              }}</span>
            </b-td>
            <b-td>
              <b-img
                v-if="item.bureau"
                :src="
                  baseImg + '/assets/images/bureaus/' + item.bureau + '.png'
                "
              />
            </b-td>
            <b-td v-if="!onlyRead">
              <div class="d-flex justify-content-center">
                <b-badge
                  v-if="item.status_file_client != null"
                  pill
                  variant="success"
                  class="cursor-pointer"
                  @click="getTracking(item)"
                >
                  APPROVED
                </b-badge>
              </div>
              <div class="d-flex justify-content-center">
                <feather-icon
                  v-if="
                    item.status_in_app != null &&
                    item.status_file_client == null
                  "
                  class="cursor-pointer text-primary mr-1"
                  icon="ThumbsUpIcon"
                  size="18"
                  @click="changeStatusFile(1, item)"
                />
                <feather-icon
                  v-if="
                    item.status_in_app != null &&
                    item.status_file_client == null
                  "
                  class="cursor-pointer text-danger"
                  icon="ThumbsDownIcon"
                  size="18"
                  @click="changeStatusFile(0, item)"
                />
                <feather-icon
                  v-if="!(item.file_name == 'APP' || isFolderApp)"
                  size="18"
                  class="cursor-pointer text-warning mr-1"
                  icon="EditIcon"
                  @click="selectItemToEdit(item)"
                />
                <feather-icon
                  v-if="!(item.file_name == 'APP' || isFolderApp)"
                  size="18"
                  class="cursor-pointer text-danger"
                  icon="TrashIcon"
                  @click="deleteCompanyFile(item, false)"
                />
              </div>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
    <tracking-approved-files
      v-if="modalTrackingApprovedFiles"
      :item="itemToSend"
      @close="modalTrackingApprovedFiles = false"
    />
    <modal-set-observation
      v-if="openObservationModal"
      @close="openObservationModal = false"
      @sendNotification="sendStatus"
    />
  </div>
</template>
<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import TrackingApprovedFiles from "@/views/commons/components/clients/dashboard/options/files-module/components/TrackingApprovedFiles.vue";
import ModalSetObservation from "@/views/commons/components/clients/dashboard/options/files-module/components/ModalSetObservation.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    TrackingApprovedFiles,
    ModalSetObservation,
  },
  data() {
    return {
      selectedFolder: {
        id: null,
        file_name: null,
      },
      show: {
        folder: false,
      },
      copyMoveSidebar: false,
      fileToUpload: [],
      uploadFiles: [],
      typesOptions: [
        {
          value: "ID",
          text: "ID",
        },
        {
          value: "UB",
          text: "UB",
        },
        {
          value: "Others",
          text: "Others",
        },
      ],
      form: {
        image: "",
        namedoc: "",
        account: "",
        size: "",
        user_id: "",
        type: "",
        datexp: "",
        folder_name: "",
        folder_id: "",
      },
      checked: false,
      files: [],
      newFolderController: false,
      selectFiles: [],
      selectedFile: {},
      isBusy: false,
      fields: [
        {
          key: "checked",
          label: "",
          thStyle: {
            width: "20px",
          },
        },
        {
          key: "file_name",
          label: "Name",
        },
        {
          key: "size",
          label: "# Files / Size",
        },
        {
          key: "expiration",
          label: "DOE",
        },
        {
          key: "user_name",
          label: "Upload By",
        },
        {
          key: "update_name",
          label: "Modified by",
        },
        {
          key: "status_in_dc",
          label: "Status",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      lastFileName: "",
      allSelected: false,
      isShownUpload: false,
      typeOfUpload: 1,
      dataToPass: [],
      lengthFilteredFiles: 0,
      firstSortName: true,
      firstUploadBy: true,
      itemToSend: {},
      modalTrackingApprovedFiles: false,
      openObservationModal: false,
    };
  },
  computed: {
    onlyRead() {
      return this.moduleId === 18;
    },
    isFolderApp() {
      return this.selectedFolder.file_name == "APP";
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    selectFiles() {
      if (this.lengthFilteredFiles === this.selectFiles.length) {
        this.allSelected = true;
      }
      if (this.lengthFilteredFiles > this.selectFiles.length) {
        this.allSelected = false;
      }
    },
  },
  async created() {
    if (this.onlyRead) {
      this.fields.pop();
    }
    this.form.account = this.$route.params.idClient;
    this.form.user_id = this.currentUser.user_id;
    await this.getFiles();
  },
  methods: {
    async getFiles(id = null, orderBy = null, by = "asc") {
      try {
        let b = by;
        if (orderBy === 3) {
          b = this.firstSortName ? "asc" : "desc";
          this.firstSortName = !this.firstSortName;
        }

        if (orderBy === 7) {
          b = this.firstUploadBy ? "asc" : "desc";
          this.firstUploadBy = !this.firstUploadBy;
        }

        this.form.folder_id = id;
        this.form.folder_name = this.selectedFolder.file_name;
        this.isBusy = true;
        const data = await ClientsOptionsServices.getFilesClient({
          id: this.$route.params.idClient,
          folder_id: id,
          orderby: orderBy === null ? 3 : orderBy,
          by: b,
        });
        if (data.status === 200) {
          this.files = data.data;
          const copyFiles = this.files.filter((file) => file.type === "File");
          this.lengthFilteredFiles = copyFiles.length;
          this.selectFiles = [];
          this.show.folder = false;
          this.isBusy = false;
          if (id === null) {
            this.selectedFolder = [];
            this.selectedFolder.id = null;
            this.selectedFolder.file_name = null;
          }
          this.files.forEach((element) => {
            this.$set(element, "check", false);
          });
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
    async updateFolder(item) {
      try {
        const data = await ClientsOptionsServices.renameFolder({
          folder: this.lastFileName,
          route: item.route,
          newfolder: this.selectedFile.file_name,
          id: item.id,
          user_id: this.currentUser.user_id,
          account: this.client.account,
          idaccount: this.$route.params.idClient,
          program_id: this.client.program_id,
        });
        if (data.status === 200) {
          this.selectedFile = [];
          await this.getFiles(this.selectedFolder.id);
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "Renamed successfully"
          );
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async searchFolder(item) {
      this.selectedFolder = item;
      await this.getFiles(item.id);
    },
    async deleteCompanyFile(file, isFolder) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          this.addPreloader();
          let data = [];
          if (isFolder) {
            data = await ClientsOptionsServices.deleteFolder({
              id: file.id,
              user_id: this.currentUser.user_id,
            });
          } else {
            data = await ClientsOptionsServices.deleteFile({
              id: file.id,
              user_id: this.currentUser.user_id,
            });
          }
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            await this.getFiles(this.selectedFolder.id);
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async selectItemToEdit(item) {
      this.lastFileName = item.file_name;
      this.selectedFile = item;
      const arraySplit = this.selectedFile.file_name.split(".");
      this.selectedFile.file_name = this.selectedFile.file_name.replace(
        `.${arraySplit[arraySplit.length - 1]}`,
        ""
      );
    },
    async changeStatusFile(status, item) {
      this.observationItem = item;
      if (status === 0) {
        this.openObservationModal = true;
      } else {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          await this.sendStatus({ status, observation: {} });
        }
      }
    },
    async sendStatus({ status, observation }) {
      const params = {
        status,
        title: observation.title,
        description: observation.description,
        user_id: this.currentUser.user_id,
        id: this.observationItem.id,
        account: this.$route.params.idClient,
      };
      const data = await ClientsOptionsServices.changeStatusFilesApp(params);
      if (data.status === 200) {
        this.openObservationModal = false;
        this.showSuccessSwal();
        await this.getFiles(this.selectedFolder.id);
      }
    },
    async getTracking(item) {
      this.itemToSend = item;
      this.modalTrackingApprovedFiles = true;
    },
    async updateFile(item) {
      try {
        const data = await ClientsOptionsServices.renameFile({
          file: this.lastFileName,
          route: item.route,
          newfile: this.selectedFile.file_name,
          id: item.id,
          user_id: this.currentUser.user_id,
          account: this.$route.params.idClient,
          extension: item.extension,
          folder_name: this.selectedFolder.file_name,
          folder_id: this.selectedFolder.id,
        });
        if (data.status === 200) {
          this.selectedFile = [];
          await this.getFiles(this.selectedFolder.id);
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "Renamed successfully"
          );
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped>
</style>
