<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
    :class="{ 'align-items-center': !detailBc }"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2 d-sm-block d-block"
      cols="10"
      :md="
        chargeBack
          ? '6'
          : clientsSearch == true
          ? globalSearch
            ? '6'
            : '3'
          : moduleId == 15
          ? '8'
          : '9'
      "
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2
            class="content-header-title pr-1 mb-0"
            :class="{ 'float-left': detailBc }"
          >
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper" v-if="detailBc">
            <b-breadcrumb>
              <b-breadcrumb-item :to="toModule">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none"
      :class="{ 'mb-2': !noBorderBottom }"
      :md="
        chargeBack
          ? '6'
          : clientsSearch == true
          ? globalSearch
            ? '6'
            : '9'
          : globalSearch
          ? '6'
          : moduleId == 15
          ? '4'
          : '3'
      "
      sm="12"
      cols="12"
    >
      <slot name="actions" />
      <slot name="indicators" />
    </b-col>

    <!-- Content Dropdown -->
    <b-col
      v-if="$slots.actionsDropdown"
      cols="2"
      class="d-sm-block content-header-right d-md-none d-lg-none d-xl-none d-block pl-0"
    >
      <b-dropdown
        variant="outline-success"
        dropleft
        text="asd"
        class="dropCustom"
      >
        <template #button-content>
          <feather-icon icon="ChevronLeftIcon" />
        </template>
        <slot name="actionsDropdown" />
      </b-dropdown>
    </b-col>
    <!-- Content Buttons -->
    <b-col
      v-if="$slots.actionsButtons"
      cols="2"
      class="d-sm-block content-header-right d-md-none d-lg-none d-xl-none d-block pl-0 mb-2"
    >
      <slot name="actionsButtons" />
    </b-col>
  </b-row>
</template>
<style>
.dropCustom .dropdown-toggle::before {
  display: none !important;
  background-image: none !important;
}
</style>
<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },

  props: {
    clientsSearch: {
      type: Boolean,
    },
    chargeBack: {
      type: Boolean,
    },
    globalSearch: {
      type: Boolean,
    },
    noBorderBottom: {
      type: Boolean,
      default: false,
    },
    detailBc: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      menuIcon: `url(${require("@/assets/images/icons/menu.svg")})`,
      subModules: [],
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currenUser: "auth/currentUser",
    }),
    toModule() {
      if (this.subModules.includes(this.moduleId)) {
        return "/cedigital";
      } else if (this.currenUser.user_id === 1) {
        return "/home";
      } else {
        return "/dashboard";
      }
    },
  },
  created() {
    this.subModules = [20, 22, 23, 24, 26];
  },
};
</script>
