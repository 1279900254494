<template>
  <b-modal
    modal
    v-model="isShow"
    size="xmd"
    modal-class="modal-primary "
    @hidden="$emit('onlyClose')"
    title-tag="h3"
    :no-close-on-backdrop="true"
    hide-footer
  >
    <template #modal-title>
      <span>
        PREPARE ANSWER FOR CASE:
        <strong>{{ caseNumber }}</strong></span
      >
    </template>
    <b-card v-if="caseInfo">
      <b-row>
        <b-col cols="12" md="6">
          <span>
            {{ caseInfo.is_lead == 1 ? "LEAD" : "CLIENT" }}
          </span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ caseInfo.name_lead }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <span>ACCOUNT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ caseInfo.is_lead == 1 ? "-" : caseInfo.account }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <form-wizard
      ref="wizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :start-index="currentStep"
      :finish-button-text="buttonNextText"
      :hide-buttons="readOnly"
      back-button-text="Previous"
      @on-complete="finishedAnser"
      v-if="uploadInfo"
      class="prepare-answer-modal"
    >
      <tab-content title="Information" :before-change="validateFirstStep">
        <questionnaire
          ref="questionnaire"
          :lead-id="leadId"
          :caseId="caseId"
          @nextStep="updateStep"
          :read-only="readOnly"
        >
        </questionnaire>
      </tab-content>

      <tab-content
        :title="documentName + ' Formats'"
        :before-change="validateSecondStep"
      >
        <upload-formats
          ref="uploadFormats"
          :case-id="caseId"
          :lead-id="leadId"
          :client-account-id="clientAccountId"
          :document-type="documentType"
          :document-name="documentName"
          :case-type-document-id="caseTypeDocumentId"
          @nextStep="updateStep"
        ></upload-formats>
      </tab-content>
      <tab-content title="Send Documents">
        <send-to-correspondence
          ref="sendToCorrespondence"
          :lead-id="leadId"
          :case-id="caseId"
          :case-type-document-id="caseTypeDocumentId"
          :client-account-id="clientAccountId"
          :documentType="documentType"
          :serviceId="service_id"
          @blockLastButton="blockLastButton"
        />
      </tab-content>
    </form-wizard>
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { mapGetters } from "vuex";
import Questionnaire from "@/views/debt-solution/views/court-info/components/modals/CiQuestionnaireModal.vue";
import UploadFormats from "@/views/debt-solution/views/court-info/components/modals/UploadCiDocument.vue";
import SendToCorrespondence from "@/views/debt-solution/views/court-info/components/modals/SendToCorrespondence.vue";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service.js";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
export default {
  components: {
    FormWizard,
    TabContent,
    Questionnaire,
    UploadFormats,
    SendToCorrespondence,
  },
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    caseId: {
      type: [String, Number],
      required: false,
    },
  },
  computed: {
    buttonNextText() {
      if (this.currentStep !== 0 || this.currentStep !== 1) {
        return "Send";
      }
      return "Next";
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      isShow: true,
      currentStep: 0,
      leadId: null,
      documentType: null,
      clientAccountId: null,
      caseTypeDocumentId: null,
      uploadInfo: null,
      caseNumber: null,
      documentName: null,
      caseInfo: null,
      service_id: null,

      lastButtonIsBlocked: false,
    };
  },
  methods: {
    blockLastButton() {
      this.lastButtonIsBlocked = true;
    },
    async validateFirstStep() {
      const compQuestionnaire = this.$refs.questionnaire;
      const isValidQuestionnaire = await compQuestionnaire.validateCards();
      return isValidQuestionnaire;
    },
    async validateSecondStep() {
      const compUploadFormats = this.$refs.uploadFormats;
      const isValidFormats = await compUploadFormats.saveDocuments();
      return isValidFormats;
    },

    async finishedAnser() {
      if (this.lastButtonIsBlocked) {
        this.showToast(
          "warning",
          "top-right",
          "Warning",
          "XIcon",
          "Create email and sms template for this document first"
        );
        return false;
      }
      const compSentToCorrespondence = this.$refs.sendToCorrespondence;
      const res = await compSentToCorrespondence.save();
      if (res) {
        this.$emit("completeAnswer");
      }
    },

    async getInfo() {
      const params = {
        case_id: this.caseId,
      };

      const { data } = await CourtInfoService.getInfoPrepareAnswer(params);

      this.dataxd = data;

      if (data[0]) {
        this.leadId = data[0].lead_id;
        this.caseTypeDocumentId = data[0].case_type_document_id;
        this.clientAccountId = data[0].client_account_id;
        this.documentType = data[0].document_id;
        this.currentStep = data[0].step;
        this.caseNumber = data[0].case_number;
        this.documentName = data[0].document_name;
        this.service_id = data[0].service_id;
        this.uploadInfo = true;
      }
    },

    async getCourtCase() {
      try {
        const response = await CourtInfoService.getCourtCase({
          case_id: this.caseId,
        });
        this.caseInfo = response[0];
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          this.getInternalErrors(error) || "Something went wrong"
        );
      }
    },

    async updateStep(step) {
      const params = {
        step: step,
        document_type: this.caseTypeDocumentId,
        user_id: this.currentUser.user_id,
        case_id: this.caseId,
      };

      const { status } = await CourtInfoService.updateCurrentStep(params);

      if (status == 200) {
        this.$emit("completeStep");
      }
    },
  },

  async mounted() {
    await this.getInfo();
    await this.getCourtCase();
  },
};
</script>