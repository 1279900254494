const { amgApi } = require("@/service/axios");

class NcrRequestService {
  constructor() {
    this.routeNcr = "request-ncr";
  }

  async search(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/search-client-and-lead`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async validateFileId(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/validate-file-id`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async create(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/create-ncr-request`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }

  async getNcrPrices(params) {
    try {
      const data = await amgApi.post(`${this.routeNcr}/get-ncr-prices`, params);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getRequestNcr(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/get-request-ncr`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getTrackingRequestNcr(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/get-tracking-request-ncr`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getAdvisorForModule(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/get-advisor-for-module`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getAvailableSources(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/get-available-sources`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async saveAttempt(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/save-attempt`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async annulledNcrRequest(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/annulled-ncr-request`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async addUrlTemporaryPdfNcr(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/add-url-temporary-pdf-ncr`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getCountersNcr(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/get-counters-ncr`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getCountersByLead(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/get-counters-by-lead`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getCountersByClient(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/get-counters-by-client`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getClassificationNcr(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/get-classification-ncr`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getSignatureAuth(params) {
    return await amgApi.post(
      "dispute/generate-borrower-signature-auth-pdf",
      params,
      {
        responseType: "blob",
      }
    );
  }
  async getAccountChargeForNcrRequestClient(params) {
    return await amgApi.post(
      `${this.routeNcr}/get-account-charge-for-nrc-request-client`,
      params
    );
  }
  async showAnalysisCR(params) {
    try {
      const { data, status } = await amgApi.post(
        `${this.routeNcr}/show-analisis-cr`,
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getAnalysisClientDashboard(params) {
    try {
      const { data, status } = await amgApi.post(
        `${this.routeNcr}/get-analysis-client-dashboard`,
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getReportInfoStep1(params) {
    try {
      const { data, status } = await amgApi.post(
        `${this.routeNcr}/get-report-info-step-1`,
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getResultAnalysisAccount(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/get-result-analysis`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async changeStatusResult(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/change-status-result`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async changeClassification(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/change-classification`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveBoureauResult(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/save-boureau-result`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeResultBoureau(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/change-result-boureau`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async changeStatusNcr(params) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/change-status-ncr`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAccounts(params) {
    try {
      const data = await amgApi.post(`${this.routeNcr}/get-accounts`, params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addAccounts(params) {
    try {
      const data = await amgApi.post(`${this.routeNcr}/add-accounts`, params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteAccount(params) {
    try {
      const data = await amgApi.post(`${this.routeNcr}/delete-account`, params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllStatusLetters() {
    try {
      const data = await amgApi.get("lead/get-all-status-letters");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getLastNcr(params) {
    try {
      const data = await amgApi.post(`${this.routeNcr}/get-last-ncr`, params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addCreditors(body) {
    try {
      const data = await amgApi.post(
        "/sales-made/debt-solution/add-creditors",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async verifyIfAppointmentExist(body) {
    try {
      const data = await amgApi.post(
        "/sales-made/debt-solution/verify-if-appointment-exist",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async approvedAccountsByClient(body) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/approved-accounts-by-client`,
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async approvedAccountsByEventId(body) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/approved-accounts-by-event-id`,
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addAccountsRemoveSelected(body) {
    try {
      const data = await amgApi.post(
        `${this.routeNcr}/add-account-remove-selected`,
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getPdfNcrRequest(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/get-pdf-ncr-request`,
        params
      );
      return data;
    } catch (ex) {
      throw ex;
    }
  }

  async getSettlementsQty(params) {
    try {
      const { data } = await amgApi.post(
        `/sales-made/debt-solution/qty-settlement-selected`,
        params
      );
      return data;
    } catch (ex) {
      throw ex;
    }
  }

  async exportAnalysisForRemove(params) {
    try {
      const data = await amgApi.post(
        `credit-experts/export-analysis-for-remove`,
        params,
        { responseType: "blob" }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async saveCrManualLead(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/save-cr-manual-lead`,
        params
      );
      return data;
    } catch (ex) {
      throw ex;
    }
  }

  async listCrManualLead(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/get-cr-manual-lead`,
        params
      );
      return data;
    } catch (ex) {
      throw ex;
    }
  }

  async saveAdditionalOption(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/manual/save-additional-option`,
        params
      );
      return data;
    } catch (ex) {
      throw ex;
    }
  }

  async getAccountStatuses(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/manual/get-account-statuses`,
        params
      );
      return data;
    } catch (ex) {
      throw ex;
    }
  }

  async getAccountDescriptions(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/manual/get-account-descriptions`,
        params
      );
      return data;
    } catch (ex) {
      throw ex;
    }
  }

  async getAccountTypes(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/manual/get-account-types`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getComments(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/manual/get-comments`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAccountTypeDetails(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeNcr}/manual/get-account-type-details`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveNewItems(params) {
    try {
      return await amgApi.post(`${this.routeNcr}/new-items/save`, params);
    } catch (error) {
      throw new Error(error);
    }
  }

  async showSpecialistNotification(params) {
    try {
      return await amgApi.post(
        `${this.routeWp}/show-specialist-notification`,
        params
      );
    } catch (error) {
      throw new Error(error);
    }
  }
  async updateStatusLetter(params) {
    try {
      return await amgApi.post(`${this.routeNcr}/update-status-letter`, params);
    } catch (error) {
      throw new Error(error);
    }
  }
  async getDataBySyncronizeItems(params) {
    const { data } = await amgApi.post(`${this.routeNcr}/get-data-by-syncronize-items`, params);
    return data;
  }
  async storeSyncronizeItems(params) {
    const data = await amgApi.post(`${this.routeNcr}/store-syncronize-items`, params);
    return data;
  }
  async deleteItem(params) {
    const data = await amgApi.post(`${this.routeNcr}/delete-item`, params);
    return data;
  }
  async returnActionToSyncronizeItems(params) {
    const data = await amgApi.post(`${this.routeNcr}/return-action-to-syncronize-items`, params);
    return data;
  }

  async getClassificationNcr(){
    const data = await amgApi.get(`${this.routeNcr}/get-classifications-ncr`);
    return data;
  }

  async saveNewClassification(params){
    const data = await amgApi.post(`${this.routeNcr}/save-new-classification`, params);
    return data;
  }
}
export default new NcrRequestService();