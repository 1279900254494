export default {
  path: "/quality/ticket/",
  name: "helpdesk-quality",
  component: () =>
    import(
      /* webpackChunkName: "QualityHelpdesk" */ "@/views/quality/views/helpdesk/Helpdesk.vue"
    ),
  meta: {
    pageTitle: "Help Desk",
    breadcrumb: [
      {
        text: "Help Desk",
        active: true,
      },
    ],
    onlyChief: true,
  },
}
