<template>
  <b-modal
    ref="modalMotives"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="Select Motive Rejected"
    size="sm"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
  >
    <ValidationObserver ref="form">
      <ValidationProvider
        v-slot="{ errors }"
        name="section-user"
        rules="required"
      >
        <b-form-group label="Select a user" label-for="input-1">
          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
            <template #prepend>
              <div class="input-group-text">
                <b-icon icon="person-circle" class="h4 m-0"> </b-icon>
              </div>
            </template>
            <b-form-select
              v-model="motive"
              :options="itemsMotives"
              :state="errors.length > 0 ? false : null"
              required
            />
          </b-input-group>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
        v-slot="{ errors }"
        name="newMotive"
        rules="required"
        v-if="motive == -1"
      >
        <b-form-group label="New Motive" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="insertNewMotive"
            type="text"
            required
            :class="{ 'border-danger': errors[0] }"
          />
        </b-form-group>
      </ValidationProvider>
    </ValidationObserver>
    <template #modal-footer>
      <div style="display: flex; justify-content: end; align-items: center">
        <b-button class="mr-2" @click="closeModal()">Close</b-button>
        <b-button @click="rejectedTicket()" variant="danger">Rejected</b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import ParagonSettingsService from "@/views/paragon/views/settings/service/paragon.service.js";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
export default {
  props: {
    ticket_id: String,
    isReviewer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      motive: null,
      insertNewMotive: null,
      itemsMotives: [{ value: null, text: "Please select a motive" }],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.toggleModal("modalMotives");
    this.getMotives();
  },
  created() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async getMotives() {
      const response = await ParagonSettingsService.getMotives();
      this.itemsMotives = response
        .filter((item) => (item.eng_soft == this.isReviewer ? 0 : 1))
        .map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      this.itemsMotives.unshift({
        value: null,
        text: "Please select a motive",
      });
      if (this.isReviewer) {
        this.itemsMotives.push({
          value: -1,
          text: "New Motive",
        });
      }
    },
    async rejectedTicket() {
      const validate = await this.$refs.form.validate();
      if (!validate) return;
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        const params = {
          user_id: this.currentUser.user_id,
          ticket_id: this.ticket_id,
          motive_id: this.motive,
          new_motive: this.insertNewMotive,
          add_new_motive: this.motive == -1 ? 1 : 0,
          user_id: this.currentUser.user_id,
        };
        try {
          this.addPreloader();
          await helpdeskService.removeTicket(params);
          this.$emit("rejected");
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
  },
};
</script>