export default {
    namespaced: true,
    state: {
        S_COUNTER_ZERO_PAYMENT_SPECIALISTS: 0
    },
    mutations: {
        M_SET_COUNTER_ZERO_PAYMENT_SPECIALISTS(state, payload = { pending: 0 }) {
            state.S_COUNTER_ZERO_PAYMENT_SPECIALISTS = payload.pending
        }
    },
    actions: {
        A_SET_COUNTER_ZERO_PAYMENT_SPECIALISTS({ commit }, payload = {pending: 0}) {
            commit('M_SET_COUNTER_ZERO_PAYMENT_SPECIALISTS', payload)
        }
    },
    getters: {
        G_COUNTER_ZERO_PAYMENT_SPECIALISTS: state => state.S_COUNTER_ZERO_PAYMENT_SPECIALISTS,
    }
}