export default [
  {
    // 0
    key: "priority",
    label: "priority",
    visible: true,
  },
  { // 0
    key: 'code',
    label: 'Code',
    visible: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  { // 1
    key: 'name_client',
    label: 'Client',
    visible: true,
  },
  { // 2
    key: 'motive',
    label: 'Motive',
    visible: true,
  },
  { // 3
    key: 'description',
    label: 'Description',
    visible: false,
  },
  { // 4
    key: 'status',
    label: 'Status',
    visible: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  { // 5
    key: 'priority',
    label: 'Priority',
    visible: true,
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  { // 6
    key: 'created_at',
    label: 'Created At',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: false,
  },
  { // 7
    key: 'updated_at',
    label: 'Date',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
  { // 8
    key: 'participants',
    label: 'Participants',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
  { // 9
    key: 'chat',
    label: 'chat',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
  { // 10
    key: 'timer',
    label: 'Time',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: false,
  },
  { // 11
    key: 'actions',
    label: 'Actions',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
  { // 12
    key: 'meetings',
    label: 'meetings',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: false,
  },
  { // 13
    ke: 'redirect',
    label: 'Redirect',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: false,
  },
  {
    key: "tracking",
    label: "tracking",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
];
