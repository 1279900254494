import { amgApi } from "@/service/axios"

class GlobalService {
  async getPrograms(params) {
    try {
      const { data } = await amgApi.get("/program/get-programs")
      return data
    } catch (error) {
      throw error
    }
  }

  async getCountrys(params) {
    try {
      const { data } = await amgApi.post("/commons/get-world-countrys")
      return data
    } catch (error) {
      throw error
    }
  }

  async getHourSystem(params) {
    try {
      const { data } = await amgApi.post(
        `/commons/get-hour-system?timezone=${params}`
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getUsersByModuleAndRoles(payload) {
    try {
      const { data } = await amgApi.post(
        `/commons/user-module/${payload.moduleId}`,
        { roles: payload.roles, type: "1" }
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getAllUsers() {
    try {
      const { data } = await amgApi.get(`/commons/get-all-users`)
      return data
    } catch (error) {
      throw error
    }
  }

  async getAccountNotifications(params) {
    try {
      const { data } = await amgApi.post(
        `/notification/get-account-notifications`,
        params
      )
      return data
    } catch (error) {
      throw error
    }
  }
  async getDataUser(params) {
    try {
      const data = await amgApi.post("commons/get-data-user", params)
      return data
    } catch (error) {
      console.log("Something went wrong on getDataUser:", error)
      throw error
    }
  }

  async doneModalImport(userId) {
    const data = await amgApi.post("/administration/done-modal-import", {
      id: userId,
    })
    return data.data
  }
  async getAutomaticPayments(params) {
    const data = await amgApi.post(
      "/administration/search-import-payments",
      params
    )
    return data.data
  }
  async getCounterStatusTransactions(params) {
    const data = await amgApi.post(
      "/administration/get-counter-search-import-payments",
      params
    )
    return data.data
  }
  async getDetailsClient(params) {
    const data = await amgApi.post(
      "/administration/get-details-client",
      params
    )
    return data.data
  }
  async getDeployedNewPaymentsPrograms() {
    try {
      const { data } = await amgApi.post(
        "/commons/get-deployed-new-payments-modules"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new GlobalService()
