export default [
  {
    path: "commissions",
    name: "creditexperts-commissions",
    component: () => import(/* webpackChunkName: "CreditExpertsCommissions" */ "@/views/credit-experts/views/commission/Commissions.vue"),
    meta: {
      program: 2,
      pageTitle: "Commissions",
      breadcrumb: [
        {
          text: "Commissions",
        },
      ],
    },
  },
];
