export default {
  namespaced: true,
  state: {
    S_REFRESH_REPORT: 0,
    S_UPDATES_DISPUTE: 0,
    S_MISSING_FILES: 0,
    S_COUNT_UB: 0,
    S_COUNT_ID: 0,
    S_PENDING_DISPUTES: 0,
    S_REFRESH_EFECTIVITY: false,
  },

  getters: {
    G_REFRESH_REPORT(state) {
      return state.S_REFRESH_REPORT;
    },
    G_UPDATES_DISPUTE(state) {
      return state.S_UPDATES_DISPUTE;
    },
    G_MISSING_FILES(state) {

      return state.S_MISSING_FILES;
    },
    G_COUNT_ID(state) {
      return state.S_COUNT_ID;
    },
    G_COUNT_UB(state) {
      return state.S_COUNT_UB;
    },
    G_PENDING_DISPUTES(state) {
      return state.S_PENDING_DISPUTES;
    },
    G_REFRESH_EFECTIVITY(state) {
      return state.S_REFRESH_EFECTIVITY;
    }
  },

  mutations: {
    M_REFRESH_REPORT(state, payload) {
      state.S_REFRESH_REPORT = payload;
    },
    M_UPDATES_DISPUTE(state, payload) {
      state.S_UPDATES_DISPUTE = payload;
    },
    M_MISSING_FILES(state, payload) {
      const { data } = payload;
      state.S_PENDING_DISPUTES = data?.[0]?.pending_disputes;
      state.S_MISSING_FILES = data?.[0]?.pending_disputes == 0 ? 0 : data?.[0]?.missing_files;
      state.S_COUNT_ID = data?.[0]?.cid;
      state.S_COUNT_UB = data?.[0]?.cub;
    },
    M_COUNT_ID(state, payload) {
      state.S_COUNT_ID = payload;
    },
    M_COUNT_UB(state, payload) {
      state.S_COUNT_UB = payload;
    },
    M_PENDING_DISPUTES(state, payload) {
      state.S_PENDING_DISPUTES = payload;
    },
    M_REFRESH_EFECTIVITY(state, payload) {
      state.S_REFRESH_EFECTIVITY = payload;
    }
  },

  actions: {
    A_REFRESH_REPORT({ commit }, params) {
      commit("M_REFRESH_REPORT", params);
    },
    A_UPDATES_DISPUTE({ commit }, params) {
      commit("M_UPDATES_DISPUTE", params);
    },
    A_MISSING_FILES({ commit }, params) {
      commit("M_MISSING_FILES", params);
    },
    A_REFRESH_EFECTIVITY({ commit }, params) {
      commit("M_REFRESH_EFECTIVITY", params);
    }
  },
};
