import ProductRequestNavigation from "@/views/paragon/views/request/request.nav.js";
import HelpdeskNavigation from "@/views/paragon/views/helpdesk/helpdesk.nav";
import ProcessSale from "@/views/paragon/views/process-sale/process-sale.navigation";
import RoiReportsNavigation from "@/views/paragon/views/roi-reports/roi-reports.navigation";
import UsersNavigation from "@/views/paragon/views/users/users.nav";
import ClientsNavigation from "@/views/paragon/views/clients/clients-group.navigation";
import ClientCompanyNavigation from "../views/client-company/client-company.nav";
// import SchedulesNavigation from "../views/schedules/schedules.nav"
import LoansNavigation from "../views/loans/loans.nav";
import FileManagerNav from "../views/file-manager/file-manager.nav";
import CommissionsNav from "../views/commissions/commissions.nav";
import CalendarNavigation from "../views/calendar/calendar.nav";
import BankOfFlyers from "../views/bank-of-flyers/bank-of-flyers.navigation";
import SalesMadeNavigation from "../views/sales-made/sales-made.nav";
import LeadsNavigation from "../views/leads/leads.nav";
import PaymentsNavigation from "../views/payments/payments.nav";
import ChargeBackNavigation from "@/views/paragon/views/charge-back/charge-back.nav";
import OtherPaymentsNavigation from "@/views/paragon/views/other-payments/other-payments.nav";
import EmployeeClaimsNavigation from "@/views/paragon/views/employee-claims/navigation/employee-claims.nav";
import ClaimNavigation from "@/views/paragon/views/claims/claims.nav";
//import ParagonProjectsNavigation from "@/views/paragon/views/projects/paragon-projects.navigation";
import ParagonSettingsNavigation from "@/views/paragon/views/settings/nav/settings.nav.js";
import CallLogNavigation from "@/views/paragon/views/call-log/call-log.nav.js";
import NewEmployeesNavigation from "@/views/paragon/views/new-employees/new-employees.nav.js";
import MOFNavigation from "@/views/paragon/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/paragon/views/recruitment-process/paragon-recruitment_process.navigation";
import RequestWorkPlanNav from "@/views/paragon/views/request-workplan/request-workplan.nav.js";
import DisputeNav from "@/views/paragon/views/dispute/navigation/dispute.nav";
import CreativeRequestNavigation from "@/views/paragon/views/creative-request/nav/creative.request";
import ParagonSoftNavigation from "@/views/paragon/views/paragon-soft/paragon-soft.nav.js";
import InconsistentTransactionNavigation from "@/views/paragon/views/inconsistent-transactions/navigation/inconsistent-transactions.nav.js";
import CallImpressionNavigation from "@/views/paragon/views/call-impression/navigation/call-impression.navigation.js";
import DashboardNavigation from "@/views/paragon/views/dashboard/navigation/dashboard.nav.js"


const navigation = [
  {
    header: "PARAGON",
  },
  DashboardNavigation,
  LeadsNavigation,
  ClientsNavigation,
  RequestWorkPlanNav,
  DisputeNav,
  ClaimNavigation,
  ClientCompanyNavigation,
  SalesMadeNavigation,
  FileManagerNav,
  CalendarNavigation,
  BankOfFlyers,
  // CommissionsNav,
  LoansNavigation,
  ProcessSale,
  ChargeBackNavigation,
  RoiReportsNavigation,
  HelpdeskNavigation,
  PaymentsNavigation,
  UsersNavigation,
  ProductRequestNavigation,
  OtherPaymentsNavigation,
  EmployeeClaimsNavigation,
  ParagonSoftNavigation,
  //ParagonProjectsNavigation,
  ParagonSettingsNavigation,
  ...CallLogNavigation,
  NewEmployeesNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  CreativeRequestNavigation,
  ...InconsistentTransactionNavigation,
  ...CallImpressionNavigation,
];
export default navigation;
