<template>
  <b-modal
    id="moreInfo"
    ref="moreInfo"
    :title="`Info Lead: ${infoLead.leadName}`"
    size="sm"
    title-class="h3 text-white"
    hide-footer
    v-model="modalInfo"
    @hidden="closeModalInfo"
  >
    <div>
      <b-form-group>
        <b-input-group>
          <b-input-group-prepend class="w-35">
            <b-input-group-text class="w-100 badge-name-group">
              <span>DOC TYPE</span>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input v-model="infoLead.docType" type="text" readonly />
        </b-input-group>
      </b-form-group>
      <b-form-group>
        <b-input-group>
          <b-input-group-prepend class="w-35">
            <b-input-group-text class="w-100 badge-name-group">
              <span>O. COUNTRY</span>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input v-model="infoLead.country" type="text" readonly />
        </b-input-group>
      </b-form-group>
      <b-form-group>
        <b-input-group>
          <b-input-group-prepend class="w-35">
            <b-input-group-text class="w-100 badge-name-group">
              <span>WORK STATUS</span>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input v-model="infoLead.workStatus" type="text" readonly />
        </b-input-group>
      </b-form-group>
      <b-form-group>
        <b-input-group>
          <b-input-group-prepend class="w-35">
            <b-input-group-text class="w-100 badge-name-group">
              <span>LEGAL STATUS</span>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input v-model="infoLead.legalStatus" type="text" readonly />
        </b-input-group>
      </b-form-group>
      <b-form-group
        v-if="infoLead.sourcesname == 'Facebook' && infoLead.flyerCode"
      >
        <b-input-group>
          <b-input-group-prepend class="w-35">
            <b-input-group-text class="w-100 badge-name-group">
              <span>FLYER CODE</span>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input v-model="infoLead.flyerCode" type="text" readonly />
        </b-input-group>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    infoLead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalInfo: false,
    };
  },
  created() {
    this.modalInfo = true;
  },
  methods:{
    closeModalInfo(){
      this.modalInfo = false;
      this.$emit('closeModalInfo');
    }
  }
};
</script>

<style>
</style>
