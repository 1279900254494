export default {
  namespaced: true,
  state: {
    refreshTable: false,
    S_IsActive: false,
    S_Title: null,
    S_SalesMadeInfo: null,
  },
  getters: {
    getRefreshTable: (state) => state.refreshTable,
    G_IsActive(state) {
      return state.S_IsActive;
    },
    G_Title(state) {
      return state.S_Title;
    },
    G_SalesMadeInfo(state) {
      return state.S_SalesMadeInfo;
    },
  },
  mutations: {
    SET_REFRESH_TABLE(state, payload) {
      state.refreshTable = payload;
    },
    M_IsActive(state, payload) {
      state.S_IsActive = payload;
    },
    M_Title(state, payload) {
      state.S_Title = payload;
    },
    M_SalesMadeInfo(state, payload) {
      state.S_SalesMadeInfo = payload;
    },
  },
  actions: {
    // CHANGE_REFRESH_TABLE({ commit }, data) {
    //   commit("SET_REFRESH_TABLE", data);
    // },
  },
};
