/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class ParticipantsServices {
  async updateParticipants(body) {
    try {
      const data = await amgApi.post('customer-tickets/update-participants-ticket', body);
      return data;
    } catch (error) {
      console.log('error in services updateParticipants: ', error);
    }
  }

  async getUserByRoles(body) {
    try {
      const data = await amgApi.post('customer-tickets/get-user-by-roles', body);
      return data;
    } catch (error) {
      console.log('error in services getUserByRoles: ', error);
    }
  }

  async getParticipantsTickect(body) {
    try {
      const data = await amgApi.post('customer-tickets/get-participants-ticket-customer', body);
      return data;
    } catch (error) {
      console.log('error in services getParticipantsTicket: ', error);
    }
  }

  async getLeadCallLog(params) {
    try {
      const data = await amgApi.post('ring-central/get-leads-call-recording', params);
      return data;
    } catch (error) {
      console.log('Error getLeadCallLog');
      throw error;
    }
  }

  async getFilesTicketsCustomer(params) {
    try {
      const data = await amgApi.post('customer-tickets/get-files-ticket-customer', params);
      return data;
    } catch (error) {
      console.log('Error getLeadCallLog');
      throw error;
    }
  }

  async getTotalPaymentsByClientAccountId(params) {
    try {
      const data = await amgApi.post('customer-tickets/get-total-payments-by-client-account-id', params);
      return data;
    } catch (error) {
      console.log('Error getLeadCallLog');
      throw error;
    }
  }
}
export default new ParticipantsServices();
