import { amgApi } from "@/service/axios";

class SalesMadeService {
  async getSalesMadeClients(body) {
    try {
      const request = await amgApi.post(
        `/sales-made/get-sales-made-clients`,
        body
      );
      return request;
    } catch (error) {
      console.log("Something went wrong on getSalesMadeClients:", error);
      throw error;
    }
  }

  async getAnnulledSalesClients(body) {
    try {
      const { data } = await amgApi.post(
        `/sales-made/get-annulled-sales-clients`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getAnnulledSalesClients:", error);
      throw error;
    }
  }

  async getAnnulledSalesLeads(body) {
    try {
      const { data } = await amgApi.post(
        `/sales-made/get-annulled-sales-leads`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getAnnulledSalesLeads:", error);
      throw error;
    }
  }

  async getClientCounters(body) {
    try {
      const { data } = await amgApi.post(
        `/sales-made/get-client-counters`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getClientCounters:", error);
      throw error;
    }
  }

  async getSalesMadeLeads(body, page) {
    try {
      const { data } = await amgApi.post(`/sales-made/get-sales-made-leads`, {
        ...body,
        page,
      });
      return data;
    } catch (error) {
      console.log("Something went wrong on getSalesMadeLeads:", error);
      throw error;
    }
  }

  async moduleHasChief(body) {
    try {
      const { data } = await amgApi.post(`/sales-made/module-has-chief`, body);
      return data;
    } catch (error) {
      console.log("Something went wrong on moduleHasChief:", error);
      throw error;
    }
  }
  async getAccountsByClient(body) {
    try {
      const { data } = await amgApi.post(
        `/sales-made/get-accounts-by-client`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getAccountsByClient:", error);
      throw error;
    }
  }
  async countSalesMadeByLead(body) {
    try {
      const { data } = await amgApi.post(
        `/sales-made/get-sales-made-by-leads-counter`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getSalesMadeLeads:", error);
      throw error;
    }
  }

  async getValidateCallsMaxDate() {
    try {
      const data = await amgApi.get("/new-commons/validate-call/index");
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getNotSoldsClients(body) {
    try {
      const { data } = await amgApi.post(
        `/sales-made/get-not-solds-clients`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getNotSoldsClients:", error);
      throw error;
    }
  }
  async getReportSalesMadeByMonthsClients(params) {
    try {
      const { data } = await amgApi.post(
        "/sales-made/get-report-sales-made-by-months-clients",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getReportSalesMadeByProgramClients(params) {
    try {
      const { data } = await amgApi.post(
        "/sales-made/get-report-sales-made-by-program-clients",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new SalesMadeService();
