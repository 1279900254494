export default [
    {
      key: "lead_name",
      label: "Name",
      sortable: true,
      thClass: "text-left",
      tdClass: "text-wrap"
    },
    {
      key: "status",
      thClass: "text-center",
    },
    {
      key: "mobile",
      thClass: "text-left",
    },
    {
      key: "credit_report",
      label: "CR",
      thClass: "text-left",
    },
    {
      key: "programs",
      thClass: "text-left",
    },
    {
      label: "Created By",
      thClass: "text-left",
      key: "created_at",
      sortable: true,
    },
    {
      key: "actions",
      thClass: "text-left",
    },
  ];
  
