import WebsiteLeadsServices from '@/views/crm/views/Lead/lead-module/services/website-leads.services';

export default {
  namespaced: true,
  state: {
    S_WEBSITE_LEADS_UNSEEN: 0,
  },
  getters: {
    G_WEBSITE_LEADS_UNSEEN(state) {
      return state.S_WEBSITE_LEADS_UNSEEN;
    },
  },
  mutations: {
    M_SET_WEBSITE_LEADS_UNSEED(state, payload) {
      state.S_WEBSITE_LEADS_UNSEEN = payload;
    },
  },
  actions: {
    async A_GET_WEBSITE_LEADS_UNSEED({ commit }) {
      const data = await WebsiteLeadsServices.getWebsiteLeadsUnseen();
      commit('M_SET_WEBSITE_LEADS_UNSEED', data.leads_unseen);
    },
  },
};
