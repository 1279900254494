import { serverlessApi, nestApi } from "@/service/axios";
class MessengerMetaService {
    async sendMessage(payload) {
        try {
            const data = await nestApi.post(`/facebook/messages/store`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async sendAttachments(payload) {
        try {
            const data = await nestApi.post(`/facebook/messages/send-attachments`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getMessengerChats(payload) {
        try {
            const data = await nestApi.post(`/facebook/chats`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getMessengerChatMessages(payload) {
        try {
            const data = await nestApi.post(`/facebook/messages`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async updateMessengerChat(id, payload) {
        try {
            const data = await nestApi.put(`/facebook/chats/${id}`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async markMessageAsSeen(payload) {
        try {
            const data = await nestApi.post(`/facebook/messages/mark-seen`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getSentimentMessage(payload) {
        try {
            const data = await nestApi.post(`/facebook/chats/sentiment`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getChatSentimentAnalysis(payload) {
        try {
            const data = await nestApi.post(`/facebook/chats/sentiment/details`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default new MessengerMetaService();