import Vue from "vue";
import {
    ToastPlugin,
    ModalPlugin,
    BootstrapVue,
    BootstrapVueIcons,
} from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import router from "./router";
import store from "./store";
import App from "./Instance.vue";
import { amgApi } from "./service/axios";
import { initialize } from "./service/general";
import AmgAlertPlugin from "@/plugins/amgAlertPlugin";
import AmgTimelinePlugin from "@/plugins/amgTimelinePlugin";
import generalMixins from "./mixins/general";
import modalMixin from "./mixins/modal";
import "./global-components";
import "@axios";
import "@/libs/acl";
import "@/libs/portal-vue";
import "@/libs/clipboard";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";

window.amgApi = amgApi;

Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(AmgAlertPlugin);
Vue.use(AmgTimelinePlugin);
Vue.use(BootstrapVue, {
    BModal: { headerBgVariant: "primary", titleClass: "text-light" },
    breakpoints: ["xs", "sm", "md", "lg", "xl", "xxl"],
});
Vue.use(BootstrapVueIcons);
Vue.use(VueCompositionAPI);



Vue.mixin(generalMixins);
Vue.mixin(modalMixin);
Vue.config.productionTip = false;
initialize(router);
export const globalVueInstance = new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#instance");
