<template>
  <b-modal
    ref="modal-timeline-tracking"
    title-tag="h3"
    size="xmd"
    modal-class="modal-primary modal-timeline-tracking-ci"
    hide-footer
    @hidden="$emit('close')"
    scrollable
  >
    <template #modal-title>
      <span>
        TIMELINE DETAIL - CASE:
        <strong>{{ case_number }}</strong></span
      >
    </template>
    <b-card v-if="caseInfo" class="mb-0">
      <b-row>
        <b-col cols="12" md="6">
          <span>
            {{ caseInfo.is_lead == 1 ? "LEAD" : "CLIENT" }}
          </span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ caseInfo.name_lead }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <span>ACCOUNT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ caseInfo.is_lead == 1 ? "-" : caseInfo.account }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="caseInfo">
      <b-row>
        <b-col cols="12" :lg="caseInfo.modul_id == 5 ? 6 : 3" class="mb-2">
          <b-input-group size="md" prepend="SERVICE TYPE">
            <b-form-input v-model="service_type" disabled />
          </b-input-group>
        </b-col>
        <b-col cols="12" :lg="caseInfo.modul_id == 5 ? 6 : 3" class="mb-2">
          <b-input-group size="md" prepend="DOCUMENT TYPE">
            <b-form-input v-model="documentType" disabled />
          </b-input-group>
        </b-col>
        <b-col cols="12" lg="3" class="mb-2" v-if="caseInfo.modul_id != 5">
          <b-input-group size="md" prepend="COST">
            <b-form-input v-if="amount == null" :value="'-'" disabled />
            <b-form-input v-else :value="'$ ' + amount" disabled />
          </b-input-group>
        </b-col>
        <b-col cols="12" lg="3" class="mb-2" v-if="caseInfo.modul_id != 5">
          <b-input-group size="md" prepend="PENDING COST">
            <b-form-input v-if="pending_amount == null" :value="'-'" disabled />
            <b-form-input
              v-else
              :value="'$ ' + pending_amount"
              disabled
              :class="pending_amount > 0 ? 'text-warning' : ''"
            />
          </b-input-group>
        </b-col>
        <b-col
          cols="12"
          lg="3"
          class="mb-2"
          v-if="caseInfo.modul_id != 5 && motive != null"
        >
          <b-input-group size="md" prepend="NO PAYMENT MOTIVE">
            <b-form-input :value="motive" disabled />
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="font-weight-bolder">
            <b-badge variant="light-primary text-uppercase">Timeline</b-badge>
          </div>
          <div class="container-files timeline-court-info border">
            <div class="timeline-steps mt-5">
              <div
                class="timeline-step mb-5"
                v-for="(event, index) in timelineEvents"
                :key="index"
              >
                <div class="timeline-content">
                  <b-badge
                    :variant="'light-' + getVariantStep(event.current_step_id)"
                    class="icon-badge cursor-pointer"
                    v-b-tooltip.hover.top="event.name"
                  >
                    <feather-icon
                      :icon="getIconStep(event.current_step_id)"
                      size="16"
                    />
                  </b-badge>
                  <p
                    class="h6 mt-2 mb-1"
                    :class="isDarkSkin ? 'text-white' : ''"
                  >
                    {{ event.created_at | myGlobalDay }}
                  </p>
                  <p
                    class="h6 text-primary mb-1 mb-lg-0 text-uppercase text-truncate"
                    v-b-tooltip.hover.top="event.name"
                  >
                    <strong>{{ event.name }}</strong>
                  </p>
                  <p
                    class="h6 mb-0 mb-lg-0"
                    :class="isDarkSkin ? 'text-white' : 'text-muted'"
                  >
                    {{ event.created_by }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" v-if="service_type == 'ANSWER'">
          <div class="font-weight-bolder">
            <b-badge variant="light-primary">CASE FORMATS</b-badge>
          </div>
          <div class="container-files border p-1">
            <CaseFormats :caseId="caseId" :step="step" />
          </div>
        </b-col>

        <b-col cols="12">
          <div class="font-weight-bolder">
            <b-badge variant="light-primary">FILES</b-badge>
          </div>
          <div class="container-files border p-1">
            <caseFiles :caseId="caseId" :step="step" />
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-modal>
</template>
<script>
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import CaseFormats from "@/views/debt-solution/views/court-info/components/ListCaseFormats.vue";
import caseFiles from "@/views/debt-solution/views/court-info/components/ListCaseFiles.vue";
export default {
  components: { CaseFormats, caseFiles },
  props: {
    caseId: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      case_number: "-",
      service_type: null,
      documentType: null,
      amount: 0,
      pending_amount: 0,
      timelineEvents: [],
      steps: [
        {
          id: 1,
          icon: "FilePlusIcon",
          color: "primary",
        },
        {
          id: 2,
          icon: "CheckIcon",
          color: "success",
        },
        {
          id: 3,
          icon: "CalendarIcon",
          color: "warning",
        },
        {
          id: 4,
          icon: "ArchiveIcon",
          color: "primary",
        },
        {
          id: 5,
          icon: "FlagIcon",
          color: "success",
        },
        {
          id: 6,
          icon: "TrelloIcon",
          color: "primary",
        },
        {
          id: 7,
          icon: "ThumbsUpIcon",
          color: "success",
        },
        {
          id: 100,
          icon: "FileTextIcon",
          color: "info",
        },
        {
          id: 101,
          icon: "UploadIcon",
          color: "info",
        },
        {
          id: 102,
          icon: "AtSignIcon",
          color: "info",
        },
        {
          id: 103,
          icon: "MailIcon",
          color: "warning",
        },
        {
          id: 104,
          icon: "InboxIcon",
          color: "warning",
        },
      ],
      caseInfo: null,
      motive: null,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {
    this.toggleModal("modal-timeline-tracking");
    this.getInfoTimelineByCase();
    this.getCourtCase();
  },
  methods: {
    async getCourtCase() {
      try {
        this.addPreloader();
        const data = await CourtInfoService.getCourtCase({
          case_id: this.caseId,
        });
        this.case_number = data[0].case_number.toUpperCase();
        this.caseInfo = data[0];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getInfoTimelineByCase() {
      try {
        this.addPreloader();
        const { data } = await CourtInfoService.getInfoTimelineByCase({
          caseId: this.caseId,
          case_type_document_id: this.step,
        });
        this.service_type = data[data.length - 1].service_type;
        this.documentType = data[data.length - 1].document_type;
        this.amount = data[data.length - 1].amount;
        this.pending_amount = data[data.length - 1].pending_amount;
        this.motive = data[data.length - 1].motive;
        this.timelineEvents = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    getIconStep(step) {
      return this.steps.find((item) => item.id == step).icon;
    },
    getVariantStep(step) {
      return this.steps.find((item) => item.id == step).color;
    },
  },
};
</script>
<style lang="scss">
.modal-timeline-tracking-ci {
  .modal-header {
    text-transform: uppercase;
  }
  .container-files {
    margin-bottom: 15px;
    border-radius: 10px;
    .row.container-upload-ci-cf .ci-botton {
      margin-bottom: 0 !important;

      .new-border {
        height: unset !important;
      }
    }
    .no-files-timeline {
      margin: 0 !important;
      padding: 1rem;
    }
  }
}
</style>