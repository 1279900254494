import {
  newRequestNcrNotification,
  annulledNcrRequestNotification,
  attemptRequestNcrNotification,
  CloseAllNcrNotification,
  specialistNewItemEvent,
} from "@/views/commons/components/request-ncr/sockets/RequestNcr.socket";

const subscribeNcrSocket = (socket) => {
  try {
    socket.subscribe("request-ncr-notifications");
    newRequestNcrNotification();
    attemptRequestNcrNotification();
    annulledNcrRequestNotification();
    CloseAllNcrNotification();
    specialistNewItemEvent();
  } catch (error) {
    console.error(error, "error");
  }
};

export default subscribeNcrSocket;
