export default {
  path: "helpdesk",
  name: "helpdesk-bookeeping",
  component: () =>
    import(
      /* webpackChunkName: "BookeepingHelpdesk" */ "@/views/bookeeping/views/helpdesk/Helpdesk.vue"
    ),
  meta: {
    pageTitle: "Help Desk",
    breadcrumb: [
      {
        text: "Help Desk",
        active: true,
      },
    ],
    onlyChief: true,
  },
}
