import store from "@/store"
import router from "@/router/index"
import Vue from "vue"
import { amgApi } from "@/service/axios"
const requestNcrToAdm = () => {
  window.socket.bind("request-crm-to-adm", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id

    let timerInterval
    var temps = true
    for (let index = 0; index < data.no_accept.length; index++) {
      if (data.no_accept[index] == sessionId) {
        temps = false
      }
    }
    if (
      sessionId == data.to_id &&
      sessionId != data.from_id &&
      temps &&
      router.currentRoute.matched[0]?.meta.module == 4
    ) {
      const { value, dismiss } = await Vue.swal.fire({
        html:
          '<img src="/assets/images/icons/swal/bell-light.svg" style="margin-bottom: 10px;">' +
          '<div><h2 style="font-weight: 600;">NEW REQUEST OF CREDIT REPORT!</h2></div>' +
          '<div><span style="font-weight: bold;">LEAD:</span> ' +
          data.lead_name +
          " </div>" +
          '<div><span style="font-weight: bold;">SELLER:</span> ' +
          data.seller_name +
          " </div>" +
          "<br>I will close in <strong>30</strong> seconds.",
        icon: "/images/icons/alert-info.png",
        timer: 30000,
        showCancelButton: true,
        confirmButtonColor: "#EFB911",
        cancelButtonColor: "#FF4F4F",
        confirmButtonText: "Yes",
        onBeforeOpen: () => {
          timerInterval = setInterval(() => {
            const content = Vue.swal.getContent()
            if (content) {
              const b = content.querySelector("strong")
              if (b) {
                b.textContent = Math.ceil(Vue.swal.getTimerLeft() / 1000)
              }
            }
          }, 100)
        },
        onClose: () => {
          clearInterval(timerInterval)
        },
      })
      if (value) {
        store.commit("global-store/SET_REQUEST_CREDIT_DATA", {
          account: "",
          client_name: "",
          id_account: "",
          created_at: "",
          nc_id: "",
          request_by: "",
          request_from: "",
          score_id: data.score_id,
        })
        store.commit("global-store/SET_MODAL_REQUEST_CREDIT", true)
        // eventBus.$emit("update-score-id", data.score_id)
        await amgApi.post("/lead/credit-report/set-admin-score", data)
      } else if (dismiss == "cancel" || dismiss == "backdrop") {
        data.no_accept.push(data.to_id)
        await amgApi.post("/lead/credit-report/find-new-administration", data)
      } else if (dismiss == "timer") {
        Vue.swal.fire({
          title: "Time out!",
          text: "I will close in 2 seconds.",
          timer: 2000,
          showConfirmButton: false,
        })
        data.no_accept.push(data.to_id)
        await amgApi.post("/lead/credit-report/find-new-administration", data)
      }
      if (window.Notification) {
        Notification.requestPermission((permission) => {
          let notification = new Notification(data.lead_name, {
            body: "NEW REQUEST OF CREDIT REPORT!",
            icon: "/images/icons/alert-info.png",
          })
          notification.onclick = () => {
            window.focus()
          }
        })
      } else {
        alert("Notifications aren't supported on your browser! :s")
      }
    }
  })
}
export default requestNcrToAdm
