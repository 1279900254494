var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('b-card',{staticClass:"w-100 m-0 pb-0 pt-2 px-2 mt-2",class:{
      'bg-dark': _vm.isDarkSkin,
      'custom-bg': !_vm.isDarkSkin,
      'border-primary custom-card': _vm.showTabs,
    },attrs:{"no-body":""}},[_c('b-table',{staticClass:"custom-table",class:{ 'row-light': !_vm.isDarkSkin },attrs:{"items":_vm.removeData,"fields":_vm.visibleFields,"sticky-header":'50vh',"foot-clone":false,"show-empty":"","head-row-variant":'grid-custom-tickets-header',"foot-row-variant":'grid-custom-tickets-header'},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
    var index = ref.index;
    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center font-size-md custom-height",staticStyle:{"min-width":"0px"}},[_vm._v(_vm._s(index + 1))]),_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.bureauDetails(item)),function(label,index){return _c('li',{key:index,staticClass:"padding w-100 d-flex justify-content-center text-center text-capitalize"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"width":"50px !important"}},[_c('span',{staticClass:"text-truncate w-100",staticStyle:{"opacity":"0"}},[_vm._v(_vm._s(label.text))])])])}),0)]}},{key:"head(transunion)",fn:function(){return [_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('TRANSUNION'),expression:"'TRANSUNION'",modifiers:{"hover":true,"top":true}}],staticClass:"custom-img",attrs:{"src":require('@/assets/images/icons/transunion.png')}})]},proxy:true},{key:"head(experian)",fn:function(){return [_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('EXPERIAN'),expression:"'EXPERIAN'",modifiers:{"hover":true,"top":true}}],staticClass:"custom-img",attrs:{"src":require('@/assets/images/icons/experian.png')}})]},proxy:true},{key:"head(equifax)",fn:function(){return [_c('b-img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('EQUIFAX'),expression:"'EQUIFAX'",modifiers:{"hover":true,"top":true}}],staticClass:"custom-img",attrs:{"src":require('@/assets/images/icons/equifax.png')}})]},proxy:true},{key:"cell(accounts)",fn:function(ref){
    var item = ref.item;
return [_c('ul',{staticClass:"list-unstyled w-100 m-0",staticStyle:{"min-width":"200px !important"}},[_c('li',{staticClass:"w-100 d-flex align-items-center justify-content-between custom-height",staticStyle:{"font-weight":"bolder"}},[_c('div',{staticClass:"w-100 position-relative"},_vm._l((_vm.generalInfo(item)),function(label,index){return _c('div',{key:index,staticClass:"w-100"},[(label.key === 'see_more')?_c('div',{staticClass:"w-100 d-flex flex-column justify-content-start text-capitalize text-center"},[_c('div',{staticClass:"d-flex flex-column text-center justify-content-center w-100 font-size-xmd font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.getRowCreditorName(item))+" ")]),_c('span',{staticClass:"w-100 mt-50 text-capitalize px-1",class:_vm.isDarkSkin ? 'text-white' : 'text-secondary',staticStyle:{"font-size":"12px"}},[_vm._v(" Account #: "+_vm._s(_vm.getRowCreditorAccount(item))+" ")])]):_vm._e()])}),0)]),_vm._l((_vm.bureauDetails(item)),function(label,index){return [_c('li',{key:index,staticClass:"padding w-100 d-flex justify-content-center text-center text-capitalize"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"width":"130px !important"}},[_c('span',{staticClass:"text-truncate d-flex w-100"},[_vm._v(_vm._s(label.text))])])])]})],2)]}},{key:"cell(classification_ncr)",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"custom-height d-flex justify-content-center align-items-center h-100"},[_c('h6',{staticClass:"text-uppercase font-weight-bolder text-center",style:(("color: " + (_vm.textColor[item.classification_ncr_id])))},[_vm._v(" "+_vm._s(item.classification_ncr)+" ")])]),_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.bureauDetails(item)),function(label,index){return _c('li',{key:index,staticClass:"padding w-100 d-flex justify-content-center text-center text-capitalize"},[_c('div',{staticStyle:{"width":"50px !important"}},[_c('span',{staticClass:"text-truncate w-100",staticStyle:{"opacity":"0"}},[_vm._v(_vm._s(label.text))])])])}),0)]}},{key:"cell(transunion)",fn:function(ref){
    var item = ref.item;
return [(_vm.idClient == null || (!!_vm.idClient || _vm.toViewRates))?_c('ItemBureau',{attrs:{"analysisEvaluationForTab":_vm.analysisEvaluationForTab,"editMode":_vm.editMode,"item":item.items[0],"labelsRemove":_vm.filteredLabels(item),"others":{
            priorityColor: _vm.priorityColor,
            currentUser: _vm.currentUser,
            openBySeller: _vm.openBySeller,
            moduleId: _vm.moduleId,
            approvedAccounts: _vm.approvedAccounts,
            appointmentExist: _vm.appointmentExist,
            typeView: _vm.typeView,
            removeOptions: _vm.removeOptions,
          },"typeView":_vm.typeView},on:{"selectBureauToNewItem":_vm.selectBureauToNewItem,"changeBoureauResult":_vm.changeBoureauResult,"update:other":function($event){return _vm.updateOthers(item)},"updateRemoveCounter":function($event){return _vm.updateRemoveCounter()}}}):_c('div',{staticClass:"w-100 d-flex justify-content-center align-items-center"},[_c('img',{staticClass:"custom-bg-logo",attrs:{"src":require('@/assets/images/icons/transunion.png'),"width":"30px","height":"30px"}})])]}},{key:"cell(experian)",fn:function(ref){
          var item = ref.item;
return [(_vm.idClient == null || (!!_vm.idClient || _vm.toViewRates))?_c('ItemBureau',{attrs:{"analysisEvaluationForTab":_vm.analysisEvaluationForTab,"editMode":_vm.editMode,"item":item.items[1],"labelsRemove":_vm.filteredLabels(item),"others":{
            priorityColor: _vm.priorityColor,
            currentUser: _vm.currentUser,
            openBySeller: _vm.openBySeller,
            moduleId: _vm.moduleId,
            approvedAccounts: _vm.approvedAccounts,
            appointmentExist: _vm.appointmentExist,
            typeView: _vm.typeView,
            removeOptions: _vm.removeOptions,
          },"typeView":_vm.typeView},on:{"selectBureauToNewItem":_vm.selectBureauToNewItem,"changeBoureauResult":_vm.changeBoureauResult,"update:other":function($event){return _vm.updateOthers(item)},"updateRemoveCounter":function($event){return _vm.updateRemoveCounter()}}}):_c('div',{staticClass:"w-100 d-flex justify-content-center align-items-center"},[_c('img',{staticClass:"custom-bg-logo",attrs:{"src":require('@/assets/images/icons/experian.png'),"width":"30px","height":"30px"}})])]}},{key:"cell(equifax)",fn:function(ref){
          var item = ref.item;
return [(_vm.idClient == null ||(!!_vm.idClient || _vm.toViewRates))?_c('ItemBureau',{attrs:{"analysisEvaluationForTab":_vm.analysisEvaluationForTab,"editMode":_vm.editMode,"item":item.items[2],"labelsRemove":_vm.filteredLabels(item),"others":{
            priorityColor: _vm.priorityColor,
            currentUser: _vm.currentUser,
            openBySeller: _vm.openBySeller,
            moduleId: _vm.moduleId,
            approvedAccounts: _vm.approvedAccounts,
            appointmentExist: _vm.appointmentExist,
            typeView: _vm.typeView,
            removeOptions: _vm.removeOptions,
          },"typeView":_vm.typeView},on:{"selectBureauToNewItem":_vm.selectBureauToNewItem,"changeBoureauResult":_vm.changeBoureauResult,"update:other":function($event){return _vm.updateOthers(item)},"updateRemoveCounter":function($event){return _vm.updateRemoveCounter()}}}):_c('div',{staticClass:"w-100 d-flex justify-content-center align-items-center"},[_c('img',{staticClass:"custom-bg-logo",attrs:{"src":require('@/assets/images/icons/equifax.png'),"width":"30px","height":"30px"}})])]}},{key:"cell(detail)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-center align-items-center custom-height",staticStyle:{"width":"40px !important"}},[_c('feather-icon',{staticClass:"hover-me cursor-pointer",class:{'rotate-180': item.showDetails},attrs:{"icon":"ChevronDownIcon","size":"22"},on:{"click":function($event){item.showDetails = !item.showDetails}}})],1),_c('ul',{staticClass:"list-unstyled overflow-hidden"},_vm._l((_vm.bureauDetails(item)),function(label,index){return _c('li',{key:index,staticClass:"padding w-100 d-flex justify-content-center text-center text-capitalize"},[_c('div',{staticStyle:{"width":"50px !important"}},[_c('span',{staticClass:"text-truncate w-100 d-none"},[_vm._v(_vm._s(label.text))])])])}),0)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }