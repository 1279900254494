

export default [
    {
      path: "vendors",
      name: "logistic/vendors",
      redirect: {name: "vendors-peru"},
      component: () => import(/* webpackChunkName: "logisticvendors" */ "@/views/logistic/views/vendors/Vendors.vue"),
      children: [
        {
          path: "peru",
          name: "vendors-peru",
          component: () => import(/* webpackChunkName: "logisticvendorsMyvendors" */ "@/views/logistic/views/vendors/views/VendorTabPeru.vue"),
          meta: {
            tab: 1,
            pageTitle: "Vendors",
            breadcrumb: [
              {
                text: "PERÚ",
                active: true,
              },
            ],
            route: "logistic",
          },
        },
        {
          path: "usa",
          name: "vendors-usa",
          component: () => import(/* webpackChunkName: "logisticvendorsModule" */ "@/views/logistic/views/vendors/views/VendorTabUsa.vue"),
          meta: {
            tab: 2,
            route: "usa",
            pageTitle: "Vendors",
            breadcrumb: [
              {
                text: "USA",
                active: true,
              },
            ],
          },
        },
      ],
    },
  ];
  