import store from '@/store';
import { globalVueInstance } from "@/vueInstance.js"

const checkTransactionStatus = () => {
    window.socket.bind("check-transaction-status", async ({ user_id, transaction }) => {

        if (store.state.auth.currentUser.user_id !== user_id) return;

        globalVueInstance.showTimedToast({
            variant: transaction.variant,
            icon: transaction.icon,
            timeout: transaction.timeout,
            title: transaction.title,
            text: transaction.text,
        });

    });
}
export {
    checkTransactionStatus
}