<template>
  <div>
    <b-modal no-close-on-backdrop @hidden="$emit('hidden')" ref="modal-add-call" size="smd" title="Attach Call">
      <div class="m-6">
        <div class="form-row">
          <div class="form-group col-md-6">
            <b-form-group label="Lead">
              <b-form-input autofocus v-model="lead" disabled type="text" class="form-control">
              </b-form-input>
            </b-form-group>
          </div>
          <div class="form-group col-md-6">
            <b-form-group label="Attach call">
              <b-form-file   @change="handleFileSelect"  accept="audio/*"/>
              
            </b-form-group>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div>
          <b-button @click="cancel()" class="">CANCEL</b-button>
        </div>
        <div>
          <b-button variant="primary" @click="saveCall"><feather-icon icon="SaveIcon" class="mr-1" />
            Save</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import callService from "@/views/crm/services/call/index.js";
import { getTime } from 'date-fns';

export default {
    computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token"
    }),
  },
  props: {
    dataLead: {
      type: Object,
    }
  },
  data() {
    return {      
      audio_file: null,
      time_file: null,
      lead: "",
    };
  },
  mounted() {
    this.toggleModal("modal-add-call");
  },
  created() {
   this.lead = this.dataLead.lead_name;
  },

  methods: {
      handleFileSelect(event) {
            const files = event.target.files;
            if (files.length > 0) {
              this.audio_file = files[0];
            }
          },
          
      async  getTime(audio) {
        return new Promise((resolve, reject) => {
          let audioElement = new Audio();
          audioElement.src = URL.createObjectURL(audio);

          audioElement.addEventListener('loadedmetadata', () => {
            let duration = audioElement.duration;
            let minutes = Math.floor(duration / 60);
            let seconds = Math.floor(duration % 60);

            let minutesFormatted = minutes < 10 ? '0' + minutes : minutes;
            let secondsFormatted = seconds < 10 ? '0' + seconds : seconds;
            let time = minutesFormatted + ':' + secondsFormatted;
            resolve(time);
          });

          audioElement.addEventListener('error', () => {
            reject('Error');
          });
        });
      },

    async saveCall() {
       
        try {
          
          let file = this.audio_file.name;
          let extension = file.split('.').pop().toLowerCase();
          if (extension === 'mp3') {
              const res = await this.getTime(this.audio_file)
              this.time_file = res;

            const form = new FormData();
            form.append("file", this.audio_file);
            form.append("file_name", this.audio_file.name);
            form.append("type_file", 'MP3');
            form.append("time_file", this.time_file);
            form.append("created_user", this.currentUser.user_id);
            form.append("id_lead", this.$route.params.id);

            const resultState = await this.showConfirmSwal();
              if (resultState.isConfirmed) { 
                this.addPreloader();
                const response = await callService.saveCall(form);
                if (response.status == 200) {
                  this.showSuccessSwal();
                  this.cancel();
                  this.$emit("onSaveFile");
                }
              }           

          } else {
            this.showInfoSwal('The file is not in MP3 format');              
          }

        } catch (error) {
          this.showInfoSwal(error);
        }finally{
          this.removePreloader()
        }
     },    
     cancel() {
      this.$emit("closeModal");
    },
  },
};
</script>
