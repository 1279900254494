import { amgApi } from "@/service/axios";

class CallRoundsService {
  //  Modal User
  async searchCallRounds(params) {
    const data = await amgApi.post(
      "/commons/call-rounds/search-call-rounds",
      params
    );
    return data;
  }

  async dowloadfilecallround(params) {
    const data = await amgApi.post(
      "/commons/call-rounds/download-file-callrounds",
      params,
      {
        responseType: "blob",
      }
    );
    return data;
  }

  async alltrakingcallround(params) {
    const data = await amgApi.post(
      "/commons/call-rounds/all-traking-call-rounds",
      params
    );
    return data;
  }

  async revisioncallround(params) {
    const data = await amgApi.post(
      "/commons/call-rounds/revision-call-rounds",
      params
    );
    return data;
  }

  async contCallsRoundsUsers(params) {
    const data = await amgApi.post(
      "/commons/call-rounds/cont-calls-rounds-user",
      params
    );
    return data;
  }

  async saveComment(params) {
    const data = await amgApi.post("/commons/call-rounds/save-comment", params);
    return data;
  }

  async sendToStandBy(params) {
    const data = await amgApi.post(
      "/commons/call-rounds/send-to-stand-by",
      params
    );
    return data;
  }

  async updateCounterCallRound(params) {
    const data = await amgApi.post(
      "/commons/call-rounds/update-counter-call-round",
      params
    );
    return data;
  }

  async getAdvisors(params, modul) {
    const data = await amgApi.post(`/commons/user-module/${modul}`, params);
    return data;
  }
}
export default new CallRoundsService();
