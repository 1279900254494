import ClientDashboardService from '@/views/commons/components/clients/dashboard/services/clients.dashboard.services'
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services'

const state = {
  S_CLIENTS: [],
  S_UPLOAD_FILES_CLAIM: [],
  S_COUNT_CREDIT_CARD: [],
  S_COUNT_FILES: [],
  S_COUNT_DISPUTE: [],
  S_ADVISOR_HAS_CHANGED: false,

}
const getters = {
  G_CLIENTS() {
    const clients = state.S_CLIENTS
    return clients
  },

  G_UPLOAD_FILES_CLAIM() {
    const uploadFilesClaim = state.S_UPLOAD_FILES_CLAIM
    return uploadFilesClaim
  },

  G_COUNT_CREDIT_CARD() {
    const countCreditCard = state.S_COUNT_CREDIT_CARD
    return countCreditCard
  },

  G_COUNT_FILES() {
    const countFiles = state.S_COUNT_FILES
    return countFiles
  },

  G_COUNT_DISPUTE() {
    const countDispute = state.S_COUNT_DISPUTE
    return countDispute
  },
  G_ADVISOR_HAS_CHANGED() {
    const advisorHasChanged = state.S_ADVISOR_HAS_CHANGED
    return advisorHasChanged
  }
}

const mutations = {
  SET_CLIENTS(state, payload, response) {
    state.S_CLIENTS = payload
    state.S_ClIENTS_RESPONSE = response
  },

  SET_UPLOAD_FILES_CLAIM(state, payload) {
    state.S_UPLOAD_FILES_CLAIM = payload
  },

  SET_COUNT_CREDIT_CARD(state, payload) {
    state.S_COUNT_CREDIT_CARD = payload
  },
  SET_COUNT_FILES(state, payload) {
    state.S_COUNT_FILES = payload
  },
  SET_COUNT_DISPUTE(state, payload) {
    state.S_COUNT_DISPUTE = payload
  },
  SET_ADVISOR_HAS_CHANGED(state, payload) {
    state.S_ADVISOR_HAS_CHANGED = payload
  }

}
const actions = {
  async A_GET_CLIENTS({ commit }, params) {
    try {
      let clients = await ClientDashboardService.search(params)
      const response = clients
      clients = clients.data[0]
      commit('SET_CLIENTS', clients)
      return response
    } catch (error) {
      console.log('ERROR_GET_CLIENTS [ACTION]', error)
      throw error
    }
  },

  async A_COUNT_CREDIT_CARD({ commit }, params) {
    try {
      let countData = []
      const data = await ClientsOptionsServices.getCountExpirationCardTab(params)
      if (data.status == 200) {
        countData = data.data[0].countExpiration > 99
          ? '+99'
          : data.data[0].countExpiration
      }

      commit('SET_COUNT_CREDIT_CARD', countData)
      return countData
    } catch (error) {
      console.log('ERROR_COUNT_CREDIT_CARD [ACTION]', error)
      throw error
    }
  },

  async A_COUNT_DISPUTE({ commit }, params) {
    try {
      let countDisputeClient = []
      const response = await ClientsOptionsServices.counterDispute(params)
      countDisputeClient = response.data.length
      commit('SET_COUNT_DISPUTE', countDisputeClient)
      return countDisputeClient
    } catch (error) {
      console.log('ERROR_COUNT_DISPUTE [ACTION]', error)
      throw error
    }
  },

  async A_COUNT_FILES({ commit }, params) {
    try {
      let countfileclient = []
      const response = await ClientsOptionsServices.getFileCountClient(params)
      countfileclient = response.data[0].countfile
      commit('SET_COUNT_FILES', countfileclient)
      return countfileclient
    } catch (error) {
      console.log('ERROR_COUNT_DISPUTE [ACTION]', error)
      throw error
    }
  },

}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}
