<template>
  <div>
    <div
      class="p-2"
      :class="isDarkTheme ? 'my-bg-white-dark' : 'my-bg-white'"
    >
      <b-row
        v-if="isManagement || isAdministracion"
        class=""
      >
        <b-col
          class="mb-2 d-flex justify-content-end"
        >
          <b-button
            variant="success"
            @click="openModalTracking()"
          >
            See tracking search payment
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="4"
          sm="12"
          class="mb-2"
        >
          <b-form-group label="Last 4 Digits of Card">
            <b-form-input
              id="searchDigits"
              v-model="searchDigits"
              v-mask="'####'"
              type="text"
            />
            <span
              v-show="errorMessage"
              class="text-danger"
            >Please, enter the last 4 digits of the card correctly
            </span>
          </b-form-group>
        </b-col> <!-- searchDigits -->
        <b-col
          md="4"
          sm="12"
          class="mb-2"
        >
          <b-form-group
            label="Date"
          >
            <kendo-datepicker
              id="date"
              v-model="searchDate"
              v-mask="'##/##/####'"
              :first-day-of-week="1"
              placeholder="MM/DD/YYYY"
              :format="'MM/dd/yyyy'"
              class="w-100"
              @input="searchPayments()"
            />
          </b-form-group>
        </b-col> <!-- searchDate -->
        <b-col
          md="4"
          sm="12"
          class="mb-2"
        >
          <b-form-group label="Amount">
            <b-form-input
              id="searchAmount"
              v-model="searchAmount"
              type="number"
              @keyup.enter="searchPayments()"
              @keydown="preventNegativeInput"
            />
          </b-form-group>
        </b-col> <!-- searchAmount -->
        <b-col
          md="4"
          sm="12"
          class="mb-2"
        >
          <b-form-group label="Client">
            <b-form-input
              id="searchClient"
              v-model="searchClient"
              type="text"
              @keyup.enter="searchPayments()"
            />
          </b-form-group>
        </b-col> <!-- searchClient -->
        <b-col
          md="4"
          sm="12"
          class="mb-2"
        >
          <b-form-group label="Merchants">
            <v-select
              id="searchMerchant"
              v-model="searchMerchant"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :options="merchants"
              :reduce="(option) => option.id"
              :disabled="!isDisableProgram"
            />
          </b-form-group>
        </b-col> <!-- searchMerchant -->
        <b-col
          md="4"
          sm="12"
          class="mb-2"
        >
          <b-form-group label="Programs">
            <v-select
              id="searchProgram"
              v-model="searchProgram"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="text"
              :options="programs"
              :reduce="(option) => option.id"
              :disabled="!isDisableProgram"
            />
          </b-form-group>
        </b-col> <!-- searchPrograms -->
        <b-col
          cols="12"
          class="mb-2 text-center"
        >
          <b-button
            variant="primary"
            :disabled="isSearchButtonDisabled"
            @click="searchPayments()"
          >
            Search
          </b-button>
        </b-col> <!-- btn-search -->
      </b-row>
      <b-row>
        <b-col
          class="d-flex align-items-center justify-content-start"
          style="gap: .5rem;"
        >
          <span>Show</span>
          <b-form-group
            label=""
            label-for="per-page-select"
            label-size="sm"
            label-align-sm="right"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="md"
              style="width:90px;"
            />
          </b-form-group>
          <span>entries</span>
          <span
            class="pl-1"
          >
            <feather-icon
              class="cursor-pointer"
              icon="RefreshCcwIcon"
              size="20"
              @click="refreshTable()"
            />
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            id="paymentsList"
            ref="paymentsTable"
            :items="items"
            :fields="fields"
            no-border-collapse
            class="position-relative mt-1"
            show-empty
            no-provider-filtering
            sticky-header="45vh"
            primary-key="id"
            table-class="text-nowrap"
            responsive="sm"
            :busy="isBusy"
            @filtered="onFiltered"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
              </div>
            </template> <!-- loading spinner -->
            <template #cell(client)="data">
              <div>
                <div
                  v-if="data.item.lead_first"
                  class="text-primary"
                >
                  {{ data.item.lead_first }} {{ data.item.lead_last }}
                </div>
                <div
                  v-else
                  class="text-success"
                >
                  {{ data.item.lead_first_client }} {{ data.item.lead_last_client }}
                </div>
              </div>
              <div>
                <div
                  v-if="data.item.account"
                  class="text-primary"
                >
                  {{ data.item.account }}
                </div>
                <div
                  v-else
                  class="text-success"
                >
                  Lead
                </div>
              </div>
            </template> <!-- transaction id cell -->
            <template #cell(transaction_id)="data">
              <div>
              <span>  {{ data.item.transaction_id }}</span>
                <br>
                <span> {{ data.item.ref_transaction ? `ref-transaction: ${data.item.ref_transaction}` : ''}}  </span>

              </div>
            </template> <!-- client cell -->
            <template #cell(settlement_date)="data">
              <div class="text-primary">
                {{ data.item.first_name }} {{ data.item.last_name }}
              </div>
              <div>{{ data.item.settlement_date | myGlobalDay }}</div>
              <div>{{ data.item.value }}</div>
            </template> <!-- date cell -->
            <template #cell(merchant_description)="data">
              <span :class="defineColorMerchant(data.item.merchant)">
                {{ data.item.merchant_description }}
              </span>
              <div>AUTH CODE: {{ data.item.auth_code }}</div>
              <div>
                CARD: {{ data.item.card_number.replace(/(.{4})/, "$1-") }}
              </div>
            </template> <!-- merchant cell -->
            <template #cell(amount)="data">
              <span
                :class="[10,11,16,17].includes(data.item.type_transaction_id) ? 'text-danger' : ''"
              >

                $ {{ [10,11,16,17].includes(data.item.type_transaction_id) ? `-(${data.item.amount})` : data.item.amount }}

              </span>
              <br>
              <span>{{ data.item.type_transaction_name }}</span>
            </template> <!-- amount cell -->
            <!--created at-->
            <template #cell(created_at)="data">
              {{ data.item.created_at | myGlobalDay }}
            </template>
            <!--actions-->
            <template #cell(actions)="data">
              <div
                style="width: 100%;height: 50px;"
                class="d-flex justify-content-center align-items-center"
              >
                <!--void, partial void, refund or partial refund executed-->
                <!-- <template v-if="[10,11,16,17].includes(data.item.type_transaction_id)">
                  <tabler-icon
                    v-if="[10,16].includes(data.item.type_transaction_id)"
                    :id="`t-${data.index}`"
                    :icon="data.item.type_transaction_id === 10? 'ChecksIcon':'CheckIcon'"
                    class="mr-1 text-success box-shadow"
                    size="20"
                  />
                  <tabler-icon
                    v-else
                    :id="`t-${data.index}`"
                    :icon="data.item.type_transaction_id=== 11 ? 'CheckboxIcon' : 'CircleCheckIcon'"
                    class="mr-1 text-success"
                    size="19"
                  />
                  <b-tooltip
                    :target="`t-${data.index}`"
                    placement="top"
                    variant="success"
                  >
                    <strong v-if="data.item.type_transaction_id === 10">
                      success void
                    </strong>
                    <strong v-else-if="data.item.type_transaction_id === 16">
                      success partial void
                    </strong>
                    <strong v-else-if="data.item.type_transaction_id === 11">
                      success refund
                    </strong>
                    <strong v-else-if="data.item.type_transaction_id === 17">
                      success partial refund
                    </strong>
                  </b-tooltip>
                </template> -->
                <!--management: execute void, execute refund or cancel request of users-->
                <template v-if="isManagementOrCEO">
                  <div
                    v-if="data.item.pending_partial_void "
                    class="position-relative"
                  >
                    <tabler-icon
                      :id="`programent_void_${data.index}`"
                      icon="CoinIcon"
                      size="22"
                      class="text-warning mr-1"
                    />

                    <b-tooltip
                      :target="`programent_void_${data.index}`"
                      placement="top"
                      variant="warning"
                    >
                      <strong>programmed void partial on <br>{{ data.item.pending_partial_void | myGlobal }}</strong>
                    </b-tooltip>
                  </div>
                  <tabler-icon
                    v-if="data.item.void === 1 && ![10,11,16,17].includes(data.item.type_transaction_id)
                      && data.item.pending_void_transactions<1
                      && parseFloat(data.item.amount)>parseFloat(data.item.sumref)"

                    :id="`t-v-${data.index}`"
                    v-b-tooltip.hover.v-danger
                    v-b-tooltip.hover.top
                    title="VOID"
                    icon="CoinIcon"
                    class="mr-1 hover-me cursor-pointer text-danger"
                    size="20"
                    @click="openVoidRefundModal(data.item, 1)"
                  />
                  <tabler-icon
                    v-else-if="data.item.refund === 1 && ![10,11,16,17].includes(data.item.type_transaction_id)
                      && parseFloat(data.item.amount)>parseFloat(data.item.sumref)"

                    :id="`t-v-${data.index}`"
                    v-b-tooltip.hover.v-success
                    v-b-tooltip.hover.top
                    title="REFUND"
                    icon="ClockIcon"
                    class="mr-1 hover-me cursor-pointer text-success"
                    size="20"
                    @click="openVoidRefundModal(data.item, 2)"
                  />
                </template>
                <!--users from others modules: request refund o void to management-->
                <template v-else>
                  <!--'There aren't request' or the request was 'disapproved'-->
                  <template
                    v-if="data.item.pending_partial_void"
                    class="position-relative"
                  >
                    <tabler-icon
                      :id="`programent_void_${data.index}`"
                      icon="CoinIcon"
                      size="22"
                      class="text-warning mr-1"
                    />

                    <b-tooltip
                      :target="`programent_void_${data.index}`"
                      placement="top"
                      variant="warning"
                    >
                      <strong>programmed void partial on <br>{{ data.item.pending_partial_void | myGlobal }}</strong>
                    </b-tooltip>
                  </template>
                  <template v-else-if="!data.item.status_request_transaction || data.item.status_request_transaction === 3 ">

                    <feather-icon
                      v-if="data.item.void === 1"
                      v-b-tooltip.hover.v-danger
                      v-b-tooltip.hover.top
                      icon="ArrowRightCircleIcon"
                      size="20"
                      class="text-danger cursor-pointer hover-me mr-1"
                      title="REQUEST VOID"
                      @click="saveRequestTransaction(data.item.uuid_transaction, 'VOID',`${data.item.first_name} ${data.item.last_name}`)"
                    />
                    <feather-icon
                      v-else-if="data.item.refund === 1"
                      v-b-tooltip.hover.v-success
                      v-b-tooltip.hover.top
                      icon="ArrowRightCircleIcon"
                      size="20"
                      class="text-success cursor-pointer hover-me mr-1"
                      title="REQUEST REFUND"
                      @click="saveRequestTransaction(data.item.uuid_transaction, 'REFUND',`${data.item.first_name} ${data.item.last_name}`)"
                    />
                  </template>
                  <template v-else-if="(data.item.void || data.item.refund) && !data.item.amount_total_ref ">
                    <feather-icon
                      v-b-tooltip.hover.v-warning
                      v-b-tooltip.hover.top
                      icon="AlertCircleIcon"
                      size="20"
                      class="text-warning mr-1"
                      :title="data.item.void == 1? 'PENDING VOID':'PENDING REFUND'"
                    />
                  </template>
                </template>
                <!--send invoice-->
                <template>
                  <feather-icon
                    v-if="data.item.mobile || data.item.phone"
                    :id="`t-m-${data.index}`"
                    v-b-tooltip.hover.v-primary
                    v-b-tooltip.hover.top
                    icon="MessageCircleIcon"
                    class="text-primary mr-1 hover-me cursor-pointer"
                    size="20"
                    title="SEND INVOICE"
                    @click="sendSmsInvoice(data.item.transaction_id)"
                  />
                  <feather-icon
                    v-else
                    :id="`t-m-${data.index}`"
                    v-b-tooltip.hover.v-secondary
                    v-b-tooltip.hover.top
                    icon="MessageCircleIcon"
                    class="text-secondary mr-1"
                    size="20"
                    title="No phone number"
                  />
                </template>
              </div>
            </template> <!-- actions cell -->
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ result.from && result.to && totalRows ? `Showing ${result.from} to ${result.to} of ${totalRows} entries` :
            'Showing 0 to 0 of 0 entries' }}
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="md"
            class="my-0"
          />
        </b-col>

      </b-row>

      <modal-tracking-payment
        v-if="openModalTrackingPayment"
        @close="closeModalTracking"
      />
    </div>
    <!-- MODAL REFUND O VOID-->
    <void-refund-modal
      v-if="voidRefundModalOn"
      :void-refund-data="voidRefundData"
      :type-void-refund="typeVoidRefund"
      @refresh="refresh"
      @close="closeVoidRefundModal"
    />
    <!-- Modal send sms -->
    <modal-send-sms
      v-if="showModalSMS"
      :modal-send-sms="showModalSMS"
      :name-leads="clientsSms[0]"
      :typesms="0"
      @closeModal="closeSendSms"
    />
    <!--Modal Comment to request void o refund-->
    <modal-comment
      v-if="showModalComment"
      :request="paramsComment"
      @closeModal="closeModalComment"
      @refresh="successRequest"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// service
import chargeBackService from '@/views/commons/components/charge-back_v2/service/charge.back.service';
import RegisterPaymentService from '@/views/administration/views/register-payment/register-payment.service';
import ModalTrackingPayment from '@/views/commons/components/charge-back_v2/view/modal/ModalTrackingPayments.vue';
import VoidRefundModal from '@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundModal.vue';
import modalCreateChargeFields from '@/views/commons/components/charge-back_v2/data/fields-modal-search-payments';
import ModalSendSms from '@/views/commons/components/clients/modals/ModalSendSms.vue';
import ModalComment from '@/views/commons/components/charge-back_v2/view/modal/ModalComment.vue';

export default {
  components: {
    ModalTrackingPayment,
    VoidRefundModal,
    ModalSendSms,
    ModalComment,
  },
  props: {
  },

  data() {
    return {
      result: [],
      openModalTrackingPayment: false,
      errorMessage: false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 25, 50],

      startPage: null,
      toPage: null,
      items: [],
      fields: modalCreateChargeFields,
      sortBy: 'created_at',
      sortDesc: true,
      onControl: false,
      searchCode: '',

      searchDigits: null,
      searchDate: null,
      searchAmount: null,
      searchClient: null,
      searchMerchant: null,
      searchProgram: null,

      auth_code: '',

      showInfoMessage: false,
      merchants: [],
      programs: [
        { id: 0, text: 'CRM' },
        { id: 1, text: 'Business' },
        { id: 2, text: 'Boost Credit' },
        { id: 3, text: 'Credit Experts' },
        { id: 4, text: 'Debt Solution' },
        { id: 5, text: 'Tax Research' },
        { id: 6, text: 'Court Info' },
        { id: 7, text: 'Specialist' },
        { id: 8, text: 'KeyBook' },
        { id: 9, text: 'Paragon' },
      ],
      voidRefundModalOn: false,
      voidRefundData: {},
      typeVoidRefund: null,
      showModalSMS: false,
      clientsSms: [],
      showModalComment: false,
      paramsComment: {
        uuid_transaction: null,
        type_request: null,
        client: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      CounterEmployee: 'EmployeeClaimsStore/G_COUNTER_EMPLOYEE_CLAIMS',
    }),
    isDisableProgram() {
      return this.searchDigits !== '' || this.searchClient !== null;
    },
    isSearchButtonDisabled() {
      return !this.searchDigits
      || this.searchDigits.length !== 4;
    },
    isManagementOrCEO() {
      return this.currentUser.main_module === 16 || this.currentUser.user_id === 1;
    },
    currentRoleId() {
      return this.currentUser.role_id;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isAdministracion() {
      return this.moduleId === 4;
    },
    isManagement() {
      return this.moduleId === 16;
    },
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === 'dark';
    },
  },

  watch: {
    searchDigits(newVal, oldval) {
      // console.log(!Number.isNaN(parseInt(newVal, 10)));
      // if (newVal.length === 4 && !Number.isNaN(parseInt(newVal, 10))) {
      //   console.log((oldval.toString()).substring((oldval.toString()).length-4),newVal)
      //   if ((oldval.toString()).substring((oldval.toString()).length-4) != newVal) {
      //     this.searchPayments();
      //   }
      // console.log(newVal, oldval, newVal.length, oldval.length);
      if (newVal.length === 4 && !Number.isNaN(parseInt(newVal, 10)) && ((oldval.length === 4
      && oldval !== newVal) || (oldval === newVal && oldval !== 4) || (oldval.length < 4 && newVal.length === 4))) {
        this.searchPayments();
      }
    },
    currentPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.searchPayments(newVal);
      }
    },
    perPage(newPerPage, oldPerPage) {
      if (newPerPage !== oldPerPage) {
        this.currentPage = 1;
        this.searchPayments();
      }
    },
  },
  async created() {
    this.onControl = true;
    this.getAllMerchants();
  },
  mounted() {
    this.errorMessage = true;
  },

  methods: {
    async refreshTable() {
      await this.searchPayments(this.currentPage);
    },

    openModalTracking() {
      this.openModalTrackingPayment = true;
    },
    closeModalTracking() {
      this.openModalTrackingPayment = false;
    },
    preventNegativeInput(event) {
      const invalidKeys = ['-', '+', '*', '/', '=', '!', '@', '#', '$', '%', '^', '&', '(', ')'];
      if (invalidKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    async searchPayments(current_page = 1) {
      const searchDigits = this.searchDigits.trim();
      if (searchDigits.length !== 4 || isNaN(searchDigits)) {
        this.errorMessage = true;
        return;
      }
      try {
        let formattedDate = null;
        this.isBusy = true;
        if (this.searchDate) {
          const dateObject = new Date(this.searchDate);
          formattedDate = dateObject.toISOString().slice(0, 10);
        }
        const params = {
          search_digits: this.searchDigits,
          search_date: formattedDate,
          search_amount: this.searchAmount,
          search_client: this.searchClient,
          search_merchant: this.searchMerchant,
          search_program: this.searchProgram,
          user_id: this.currentUser.user_id,
          per_page: this.perPage,
          current_page,
        };
        const result = await chargeBackService.searchPaymentsV(params);
        this.errorMessage = false;
        this.result = result;
        this.items = result.data;
        this.totalRows = result.total;
        this.currentPage = result.current_page;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    defineColorMerchant(merchant) {
      switch (merchant) {
        case 1:
          return 'text-primary';
        case 2:
          return 'text-info';
        case 3:
          return 'text-warning';
        default:
          return 'text-success';
      }
    },
    async getAllMerchants() {
      try {
        const { data } = await RegisterPaymentService.getAllMerchants();
        this.merchants = data.map(merchant => ({
          id: merchant.id,
          text: merchant.description.replace(/ *\([^)]*\) */g, ''),
        }));
      } catch (error) {
        console.error(error, 'error');
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    openVoidRefundModal(data, type) {
      this.typeVoidRefund = type;
      this.voidRefundData = {
        transaction_id: data.transaction_id,
        merchant: data.merchant,
        amount: data.amount,
        client_name: `${data.first_name} ${data.last_name}`,
        settlement_date: data.settlement_date,
      };
      this.voidRefundModalOn = true;
    },
    closeVoidRefundModal() {
      this.voidRefundModalOn = false;
    },
    async refresh() {
      await this.searchPayments(this.currentPage);
    },
    saveRequestTransaction(uuidTransaction, typeRequest, client) {
      this.paramsComment.uuid_transaction = uuidTransaction;
      this.paramsComment.type_request = typeRequest;
      this.paramsComment.client = client;
      this.showModalComment = true;
    },
    closeModalComment() {
      this.showModalComment = false;
    },
    async successRequest() {
      this.showModalComment = false;
      await this.refresh();
    },
    async sendSmsInvoice(transactionId) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }
        this.addPreloader();
        const params = {
          transactionId,
        };

        const { status } = await chargeBackService.sendSmsInvoice(params);
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal('Send Invoice', 'Correct invoice sending');
        }
      } catch (error) {
        console.log('error to sendInvoice ', error);
        this.removePreloader();
        this.showErrorSwal();
      }
    },
  },
};
</script >
<style scoped>
.my-bg-white {
  background-color: #ffffff;
}
.my-bg-white-dark {
  background-color: #17171a;
}
.border-white{
  border: 1px solid red;
}
.border-dark{
  border: 1px solid blue;
}
</style>
