import { ref } from "@vue/composition-api"
import VueCompositionApi from "@vue/composition-api"
import Vue from "vue"

// This helps to manage the logged state across diferent router views
Vue.use(VueCompositionApi);
let isLogged = ref(true) // default false

export default function () {
	return { isLogged }
}
