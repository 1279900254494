export default [
  {
    path: "settings",
    name: "rrhh-settings",
    redirect: { name: 'payments-settings' },
    component: () => import(/* webpackChunkName: "PRPayments" */ '@/views/rrhh/views/settings/index.vue'),
    meta: {
      module: 17,
      pageTitle: "Settings",
      breadcrumb: [
        {
          text: "Settings",
          active: true
        },
      ]
    },
    children: [
      {
        path: "payments",
        component: () => import(/* webpackChunkName: "PRPaymentsParagon" */ '@/views/commons/setting/components/payment-setting-modules/PaymentSetting.vue'),
        name: "payments-settings",
        meta: {
          pageTitle: "Settings",
          breadcrumb: [{
            text: "payments",
            active: true
          }],
          moduleTab: 12
        }
      },
      {
        path: "type-request",
        name: "human-talent-s-type-request",
        component: () => import("@/commons/employee-claims/components/settings/components/TypeRequest.vue"),
        meta: {
          pageTitle: 'Settings',
          module: 19,
          breadcrumb: [
            {
              text: "Type Request",
              active: true,
            },
          ],
        },
      }
    ]
  }
]