<template>
  <div class="w-100 p-0">
    <div class="header-modal d-flex justify-content-between">
      <h3 class="font-weight-bolder text-white">
        {{ titleModal }}
      </h3>
      <div>
        <b-button-close
          style="transform: translate(-12px, 4px)"
          @click="close"
        />
      </div>
    </div>
    <b-container class="p-0 w-100">
      <b-row
        class="py-2 pl-1 w-100 m-0"
        style="
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        "
        :style="{ backgroundColor: isDarkTheme ? '#17171A' : '#FFFFFF' }"
      >
        <b-col v-if="!modalAddShow && !modalAddLead" cols="4" class="mb-1">
          <div
            class="info-container"
            :class="isDarkSkin ? 'text-white' : 'text-dark'"
          >
            <p class="m-0" style="gap: 5px">
              Program |
              {{
                typeof sales.program == "object"
                  ? sales.program.value
                  : sales.program
              }}
            </p>
          </div>
        </b-col>
        <b-col cols="4" class="mb-1">
          <div
            class="info-container"
            :class="isDarkSkin ? 'text-white' : 'text-dark'"
          >
            <p class="m-0" style="gap: 5px">Client | {{ nameClient }}</p>
          </div>
        </b-col>
        <b-col v-if="typeModal === 7" cols="4" class="mb-1">
          <div
            class="info-container"
            :class="isDarkSkin ? 'text-white' : 'text-dark'"
          >
            <p class="m-0" style="gap: 5px">
              Catcher | {{ addServiceCeSales.catcher_name }}
            </p>
          </div>
        </b-col>
        <b-col v-if="typeModal === 7" cols="4" class="mb-1">
          <div
            class="info-container"
            :class="isDarkSkin ? 'text-white' : 'text-dark'"
          >
            <p class="m-0" style="gap: 5px">
              Seller | {{ addServiceCeSales.seller_name }}
            </p>
          </div>
        </b-col>
        <b-col v-if="modalAddShow" cols="4" class="mb-1">
          <div
            class="info-container"
            :class="isDarkSkin ? 'text-white' : 'text-dark'"
          >
            <p class="m-0" style="gap: 5px">
              type | {{ typeAddChange }} SERVICES
            </p>
          </div>
        </b-col>
        <b-col v-if="modalAddShow" cols="4" class="mb-1">
          <div
            class="info-container"
            :class="isDarkSkin ? 'text-white' : 'text-dark'"
          >
            <p class="m-0" style="gap: 5px">
              Origin | {{ sales.program_first }}
            </p>
          </div>
        </b-col>
        <b-col v-if="modalAddShow" cols="4" class="mb-1">
          <div
            class="info-container"
            :class="isDarkSkin ? 'text-white' : 'text-dark'"
          >
            <p class="m-0" style="gap: 5px">
              Destination | {{ sales.program }}
            </p>
          </div>
        </b-col>
      </b-row>
      <b-row class="mx-1 mb-1">
        <b-col
          v-if="modalAddService || modalAddLead"
          :cols="
            twoPerRow && (typeModal != 3 || typeModal != 4) && typeModal != 6
              ? 6
              : typeModal === 7
              ? 12
              : 4
          "
        >
          <b-form-group label="Select Program" label-for="select-program">
            <v-select
              id="select-program"
              v-model="program"
              :options="programsAll"
              label="name"
              @input="insertProgram"
              :clearable="false"
            ></v-select>
          </b-form-group>
        </b-col>
        <b-col
          v-if="modalAddService || (modalAddLead && moduleId !== 26)"
          :cols="
            twoPerRow && (typeModal != 3 || typeModal != 4) && typeModal != 6
              ? 6
              : 4
          "
        >
          <b-form-group label="Catcher" label-for="catcher">
            <v-select
              id="catcher"
              v-model="captured"
              @input="insertCatcher"
              :clearable="false"
              :options="usersServices"
              label="user_name"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          v-if="modalAddService || (modalAddLead && moduleId !== 26)"
          :cols="
            twoPerRow && (typeModal != 3 || typeModal != 4) && typeModal != 6
              ? 6
              : 4
          "
        >
          <!-- :reduce="(user_name) => user_name.id" -->
          <b-form-group label="Seller" label-for="seller">
            <v-select
              id="seller"
              v-model="seller"
              :options="usersServices"
              label="user_name"
              @input="insertSeller"
              :clearable="false"
              @change="seller = null"
              :disabled="rolAdvisor ? true : false"
            ></v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <modal-result-analysis-NCR
      v-if="showResultAnalisys"
      :params="params"
      :openBySeller="true"
      :showTabs="false"
      :showSettlements="false"
      :typeView="typeView"
      :withoutAppointment="true"
      @closeModal="closeAnalysisNCR"
      @refreshTable="$refs['RequestNcrTable'].refresh()"
      @newEvent="newEvent"
    ></modal-result-analysis-NCR>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import vSelect from "vue-select";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import ModalResultAnalysisNCR from "@/views/commons/components/request-ncr/views/modals/ModalResultAnalysisNCR.vue";

export default {
  components: {
    vSelect,
    ModalResultAnalysisNCR,
  },
  props: {
    typeModal: {
      type: Number,
      default: 1,
      // 1: complete rates crm, 2: detail of sale, 3: add Services
      // 4: change Services, 5 show add change Services, 6  add  services leads programs
    },
    usersServices: {
      type: Array,
      default: () => [],
    },
    programsAll: {
      type: Array,
      default: () => [],
    },
    headerS: {
      type: Object,
      default: () => ({
        program: "",
        seller: { id: null, user_name: null },
        captured: { id: null, user_name: null },
      }),
    },
    sales: {
      type: Object,
      default: () => ({
        program: "",
        client: "",
        program_first: "",
        typeAdd: "",
        lead_name: "",
      }),
    },
    twoPerRow: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFromLeadDashboard: {
      type: Boolean,
      default: false,
    },
    newLead: {
      type: Boolean,
      default: false,
    },
    typeService: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      program: "",
      sellers: [],
      seller: { id: null, user_name: null },
      captureds: [],
      captured: "",
      capturedConfirm: true,
      sellerConfirm: true,
      addServiceCeSales: [],
      showResultAnalisys: false,
      params: {},
      typeView: null,
      successAdditionalProcess: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    nameClient() {
      let name = this.sales.client || this.sales.lead_name;
      return name.length > 20 ? name.substring(0, 20) + "..." : name;
    },
    ...mapState("crm-store", ["Program", "Catcher", "Seller", "ProgramHeader"]),
    titleModal() {
      if (this.typeModal === 1) {
        return "Complete Rates";
      }
      if (this.typeModal === 2 || this.typeModal === 5) {
        return "Detail Of Sale";
      }
      if (this.typeModal === 3) {
        return "Add Service";
      }
      if (this.typeModal === 4) {
        return "Change Services";
      }
      if (this.typeModal === 6) {
        return "Add Sale";
      }
      if (this.typeModal === 7) {
        return "Add Service - CE Sales";
      }
      return "";
    },
    modalAddService() {
      return (
        this.typeModal === 3 || this.typeModal === 4 || this.typeModal === 6
      );
    },
    modalAddLead() {
      return this.typeModal === 6 || this.typeModal === 7;
    },
    modalAddShow() {
      return (
        this.typeModal === 5 ||
        (this.typeModal === 2 && this.isAddOrChangeService)
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    rolAdvisor() {
      return this.currentUser.role_id === 3;
    },
    typeAddChange() {
      if (this.sales.typeAdd === 1) {
        return "ADD";
      }
      return "CHANGE";
    },
    isAddOrChangeService() {
      return this.sales.type;
    },
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin == "dark";
    },
    inDashboardLead() {
      return !!this.$route.params.id;
    },
  },
  // Note the changes in programs, captured, seller
  watch: {
    captured(newValue) {
      if (newValue) {
        this.changeProgram();
      } else {
        this.captured = null;
      }
    },
    seller(newValue) {
      if (newValue) {
        this.changeProgram();
      } else {
        this.seller = null;
      }
    },
    program(newValue) {
      if (newValue) {
        if (this.program.id == 3) {
          this.validateCompleteCrProcess();
          // } else if (this.program.id == 3) {
          //   this.hasAnalyzedCR();
        } else {
          this.changeProgram();
        }
      } else {
        this.program = null;
      }
    },
  },
  async created() {
    if (this.moduleId === 26) {
      await this.getCatcherSeller();
    }
    if (!this.sales.program_first) {
      this.sales.program_first = this.sales.first_program;
    }
  },
  mounted() {
    // Check if Headers has data and assign
    if (this.modalAddService || this.modalAddLead) {
      this.program = this.headerS.program;
      this.seller = this.headerS.seller ?? { id: null, user_name: null };
      this.captured = this.headerS.captured;
    }
    // validate the role advisor and module for seller
    if (
      (this.rolAdvisor &&
        (this.moduleId === 3 ||
          this.moduleId === 5 ||
          this.moduleId === 6 ||
          this.moduleId === 7 ||
          this.moduleId === 8 ||
          this.moduleId === 11 ||
          this.moduleId === 18 ||
          this.moduleId === 11)) ||
      (this.isAgent && (this.moduleId === 22 || this.moduleId === 20))
    ) {
      this.seller.id = this.currentUser.user_id;
      this.seller.user_name = this.currentUser.fullName;
    }
  },
  methods: {
    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    async hasAnalyzedCR() {
      const params = {
        lead_id: this.sales.lead_id,
        account_id: this.sales.account_id,
      };
      const data = await DerivationsService.hasAnalyzedCR(params);
      if (data.has_analyzed_cr) {
        this.changeProgram();
      } else {
        this.showWarningSwal(
          `${this.sales.lead_name} doesn't have an analyzed credit report.`,
          "Please generate an analysis first."
        );
        this.program = "";
      }
    },
    async getCatcherSeller() {
      const params = {
        lead_id:
          this.sales.id && !this.sales.lead_id
            ? this.sales.id
            : this.sales.lead_id,
      };
      const data = await DerivationsService.getCatcherSeller(params);
      if (data.status === 200) {
        this.addServiceCeSales = data.data[0];
        this.captured = { id: this.addServiceCeSales.catcher_id };
        this.seller = { id: this.addServiceCeSales.seller_id };
      }
    },
    async insertProgram() {
      this.SET_PROGRAM(this.program);
    },
    async validateCompleteCrProcess() {
      try {
        this.addPreloader();
        const params = {
          eventId: this.sales.event_id,
          leadId: this.sales.lead_id ?? this.sales.id,
          programId: this.program.id,
          caId: this.sales.account_id,
        };
        const { data } = await DerivationsService.validateCompleteCrProcess(
          params
        );
        if (data[0].resp != "success") {
          this.showToast(
            "warning",
            "top-right",
            "Oops!",
            "AlertIcon",
            data[0].resp
          );
          // if (this.isFromLeadDashboard) {
          this.program = "";
          // }
        }
        return data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    insertCatcher() {
      this.SET_CATCHER(this.captured);
    },
    insertSeller() {
      this.SET_SELLER(this.seller);
    },
    close() {
      this.$emit("close");
    },
    closeAnalysisNCR() {
      this.showResultAnalisys = false;
    },
    successProcess() {
      this.changeProgram();
    },
    newEvent(data) {
      this.sales.event_id = data.data[0].id;
      this.$emit("sendLeadEvent", data.data[0]);
      this.changeProgram();
    },
    async prevChangeProgram() {
      const data = await this.validateCompleteCrProcess();
      if (data[0].code == 3) {
        this.program = "";
      } else if (data[0].code == 2) {
        const { data } = await DerivationsService.getLeadNcrData({
          leadId: this.sales.id,
        });
        this.params = {
          is_analyzed: 1,
          leadId: this.sales.id,
          leadName: this.sales.lead_name,
          ncrRequestId: data[0].ncr_request_id,
          scoreId: data[0].score_id,
        };
        this.showResultAnalisys = true;
        this.typeView = this.program.id == 3 ? "REMOVE" : "SETTLEMENTS";
        // this.program = "";
      } else {
        this.changeProgram();
      }
    },
    async changeProgram() {
      // Validate capture seller programs
      if (this.program.id && this.captured.id && this.seller.id) {
        if (
          this.newLead &&
          [3, 4].includes(this.program.id) &&
          this.sales.event_id == undefined
        ) {
          this.prevChangeProgram();
          return;
        }

        // only show to dashboard lead
        if (this.inDashboardLead) {
          const { isConfirmed } = await this.showConfirmSwal();
          if (!isConfirmed) return;
        }
        const headerS = {
          program: this.program,
          seller: this.seller,
          captured: this.captured,
          typeService: this.typeModal === 4 ? 2 : 1,
        };
        const { isConfirmed } = await this.showConfirmSwal();
        if(isConfirmed) this.$emit("changeProgram", headerS);
      }
      if (this.program.id && this.moduleId === 26) {
        const headerS = {
          program: this.program,
          seller: this.seller,
          captured: this.captured,
        };
        const { isConfirmed } = await this.showConfirmSwal();
        if(isConfirmed) this.$emit("changeProgram", headerS);
      }
    },
  },
};
</script>

<style>
* {
  --primary-color: #3f7afa;
}
.info-container {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white !important;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.py10 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
