export default [
  {
    path: "/credit-experts-dashboard-client/:idClient",
    name: "credit-experts-dashboard-general",
    redirect: { name: "personal-information-credit-experts-general" },
    component: () =>
      import(
        /* webpackChunkName: "CreditExpertsClientsAccount" */ "@/views/credit-experts/views/clients/dashboard/AccountCreditExperts.vue"
      ),
    props: true,
    meta: {
      moduleName: "credit-experts",
      general: true,
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay-general",
        name: "pay-credit-experts-general",
        component: () =>
          import(
            /* webpackChunkName: "CreditExpertsClientsAccountPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "dis",
        name: "dis-credit-experts-general",
        redirect: { name: "analysis-cr-general" },
        component: () =>
          import(
            /* webpackChunkName: "CreditExpertsClientsAccountDis" */ "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        meta: {
          isTicket: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Dispute",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "dispute-detail",
            name: "dispute-detail-ce-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/DisputeDetail.vue"
              ),
            meta: {
              isTicket: true,
              pageTitle: "Dispute Detail",
              breadcrumb: [
                {
                  text: "Dispute Detail",
                  active: true,
                },
              ],
            },
          },
          {
            path: "report-detail",
            name: "report-detail-ce-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
              ),
            meta: {
              isTicket: true,
              pageTitle: "Report Detail",
              breadcrumb: [
                {
                  text: "Report Detail",
                  active: true,
                },
              ],
            },
          },
          {
            path: "updates-detail",
            name: "updates-detail-ce-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/UpdateDetail.vue"
              ),
            meta: {
              isTicket: true,
              pageTitle: "Updates Detail",
              breadcrumb: [
                {
                  text: "Updates Detail",
                  active: true,
                },
              ],
            },
          },
            {
            path: "workplan-detail",
            name: "workplan-detail-ce-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Workplan Detail",
              breadcrumb: [
                {
                  text: "Workplan Detail",
                  active: true,
                },
              ],
            },
          },
            {
            path: "recommendation-detail",
            name: "recommendation-detail-ce-general",
            component: () =>
              import(
                /* webpackChunkName: "recommendationDetailCe" */   "@/views/commons/components/clients/dashboard/options/services/services.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Recommendations",
              breadcrumb: [
                {
                  text: "Recommendations",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "task-notes-general",
        name: "task-notes-credit-experts-general",
        component: () =>
          import(
            /* webpackChunkName: "CreditExpertsClientsAccountTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information-credit-experts-general",
        name: "personal-information-credit-experts-general",
        component: () =>
          import(
            /* webpackChunkName: "CreditExpertsClientsAccountPersonalInformation" */ "@/views/credit-experts/views/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files-general",
        name: "client-files-credit-experts-general",
        component: () =>
          import(
            /* webpackChunkName: "CreditExpertsClientsAccountClientsFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr-general",
        name: "acr-credit-experts-general",
        component: () =>
          import(
            /* webpackChunkName: "CreditExpertsClientsAccountAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "report-general",
        name: "report-credit-experts-general",
        component: () =>
          import(
            /* webpackChunkName: "CreditExpertsClientsAccountReport" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Report",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log-general",
        name: "call-log-client-credit-experts-general",
        component: () =>
          import(
            /* webpackChunkName: "CreditExpertsClientsAccountReport" */ "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
        },
        // children: [
        //   {
        //     path: 'general-call',
        //     name: 'credit-experts-client-general-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'General',
        //       breadcrumb: [
        //         {
        //           text: 'General',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     path: 'ceo-call',
        //     name: 'credit-experts-client-ceo-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/CeoCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'CEO calls',
        //       breadcrumb: [
        //         {
        //           text: 'CEO calls',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        path: "tickets",
        name: "client-tickets-credit-experts-general",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
      {
        path: "analysis",
        name: "analysis-cr-general",
        component: () =>
          import(
            /* webpackChunkName: "CreditExpertsAccountsGeneral" */ "@/views/commons/components/clients/dashboard/analysis/AccountsClients.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Analysis CR",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
