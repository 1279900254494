export default [
  {
    path: "ncr",
    name: "ncr-boost-credit",
    redirect: { name: "ncr-pending-boost-credit" },
    component: () => import(/* webpackChunkName: "BcNcrBoostCredit" */ "@/views/commons/components/ncr/NcrMain.vue"),
    meta: {
      isClientsTab: true,
      pendingRoute: "ncr-pending-boost-credit",
      returnedRoute: "ncr-returned-boost-credit",
      completedRoute: "ncr-completed-boost-credit",
      reportLeadRoute: "report-lead-boost-credit",
      leadRoute: "boostcredit-lead-show",
      permittedRoles: [1, 2, 3, 4],
    },
    children: [
      {
        path: "pending",
        name: "ncr-pending-boost-credit",
        component: () =>
          import(
            /* webpackChunkName: "BCNcrPending" */ "@/views/commons/components/ncr/components/ContentPending.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "NCR",
          breadcrumb: [
            {
              text: "Pending",
              active: true,
            },
          ],
          pendingRoute: "ncr-pending-boost-credit",
          returnedRoute: "ncr-returned-boost-credit",
          completedRoute: "ncr-completed-boost-credit",
          reportLeadRoute: "report-lead-boost-credit",
          leadRoute: "boostcredit-lead-show",
          permittedRoles: [1, 2, 3, 4],
        },
      },
      {
        path: "returned",
        name: "ncr-returned-boost-credit",
        component: () =>
          import(
            /* webpackChunkName: "BCNcrReturned" */ "@/views/commons/components/ncr/components/ContentReturned.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "NCR",
          breadcrumb: [
            {
              text: "Returned",
              active: true,
            },
          ],
          pendingRoute: "ncr-pending-boost-credit",
          returnedRoute: "ncr-returned-boost-credit",
          completedRoute: "ncr-completed-boost-credit",
          reportLeadRoute: "report-lead-boost-credit",
          leadRoute: "boostcredit-lead-show",
          permittedRoles: [1, 2, 3, 4],
        },
      },
      {
        path: "completed",
        name: "ncr-completed-boost-credit",
        component: () =>
          import(
            /* webpackChunkName: "BCNcrCompleted" */ "@/views/commons/components/ncr/components/ContentCompleted.vue"
          ),

        meta: {
          isClientsTab: true,
          pageTitle: "NCR",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
          pendingRoute: "ncr-pending-boost-credit",
          returnedRoute: "ncr-returned-boost-credit",
          completedRoute: "ncr-completed-boost-credit",
          reportLeadRoute: "report-lead-boost-credit",
          leadRoute: "boostcredit-lead-show",
          permittedRoles: [1, 2, 3, 4],
        },
      },
      {
        path: "ncr/reportLead/:idlead/:idfile",
        name: "report-lead-boost-credit",
        component: () =>
          import(/* webpackChunkName: "BCReportLead" */ "@/views/commons/components/ncr/components/report/ReportLead.vue"),
        props: true,
        meta: {
          isClientsTab: true,
          pendingRoute: "ncr-pending-boost-credit",
          returnedRoute: "ncr-returned-boost-credit",
          completedRoute: "ncr-completed-boost-credit",
          reportLeadRoute: "report-lead-boost-credit",
          leadRoute: "boostcredit-lead-show",
          permittedRoles: [1, 2, 3, 4],
        },
      },
    ],
  },
];
