export default [
  {
    path: "ncr-programs",
    name: "boostcredit-ncr-programs",
    redirect: { name: "bc-ncr-programs" },
    component: () =>
      import(
        /* webpackChunkName: "BCNcrPrograms" */ "@/views/commons/components/ncr-programs/NcrPrograms.vue"
      ),
    meta: {
      pageTitle: "NCR Programs",
      permittedRoles: [1, 2, 3, 4],
      breadcrumb: [
        {
          text: "NCR Programs",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "bc-ncr-programs",
        component: () =>
          import(
            /* webpackChunkName: "BCNcrProgramsList" */ "@/views/commons/components/ncr-programs/components/table/ncr-programs/NcrProgramsTable.vue"
          ),
        meta: {
          status: null,
          module: 7,
          moduleId: 7,
          pageTitle: "NCR Programs",
          permittedRoles: [1, 2, 3, 4],
          breadcrumb: [
            {
              text: "NCR Programs",
              to: "/boostcredit/ncr-programs",
              active: true,
            },
          ],
        },
      },
      {
        path: "return",
        name: "bc-ncr-programs-return",
        component: () =>
          import(
            "@/views/commons/components/ncr-programs/components/table/ncr-programs/NcrProgramsTable.vue"
          ),
        meta: {
          status: 4,
          module: 7,
          moduleId: 7,
          pageTitle: "NCR Programs",
          permittedRoles: [1, 2, 3, 4],
          breadcrumb: [
            {
              text: "NCR Programs",
              to: "/boostcredit/ncr-programs",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
