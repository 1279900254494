const state={
    GENERATED_BUTTTON_PRESS:false,
}
const getters={
    G_GENERATED_BUTTTON_PRESS(){
        return state.GENERATED_BUTTTON_PRESS
    }
}
const mutations={
    SET_GENERATED_BUTTTON_PRESS(state,payload){
        state.GENERATED_BUTTTON_PRESS=payload
    }
}
const actions={
    A_GENERATED_BUTTTON_PRESS({commit},payload){
        commit('SET_GENERATED_BUTTTON_PRESS',payload)
    }
}
export default{
    namespaced:true,
    state,
    getters,
    mutations,
    actions
    
}