export default
    [
        {
            path: "request",
            name: "request-rrhh",
            component: () => import(/* webpackChunkName: "rrhhRequest" */ "@/views/commons/components/request/Request.vue"),
            redirect: { name: "rrhh-request-pending" },
            meta: {
                isClientsTab: true,
                pageTitle: "Request",
                permittedRoles: [1, 2, 17],
                breadcrumb: [
                    {
                        text: "Request",
                        active: true,
                    },
                ]
            },
            children: [
                {
                    path: "pending",
                    component: () => import(/* webpackChunkName: "rrhhRequestPending" */ "@/views/commons/components/request/components/RequestGrid.vue"),
                    name: "rrhh-request-pending",
                    meta: {
                        status: 1,
                        pageTitle: "Request",
                        moduleName: "rrhh",
                        permittedRoles: [1, 2, 17],
                        breadcrumb: [
                            {
                                text: "Pending",
                                active: true,
                            },
                        ]
                    },
                    props: { status:'pending' }
                },
                {
                    path: "in-process",
                    component: () => import(/* webpackChunkName: "rrhhRequestInProcess" */ "@/views/commons/components/request/components/RequestGrid.vue"),
                    name: "rrhh-request-in-process",
                    meta: {
                        status: 2,
                        pageTitle: "Request",
                        moduleName: "rrhh",
                        permittedRoles: [1, 2, 17],
                        breadcrumb: [
                            {
                                text: "In Process",
                                active: true,
                            },
                        ]
                    },
                    props: { status:'in-process' }
                },
                {
                    path: "delivered",
                    component: () => import(/* webpackChunkName: "rrhhRequestDelivered" */ "@/views/commons/components/request/components/RequestGrid.vue"),
                    name: "rrhh-request-delivered",
                    meta: {
                        status: 3,
                        pageTitle: "Request",
                        moduleName: "rrhh",
                        permittedRoles: [1, 2, 17],
                        breadcrumb: [
                            {
                                text: "Delivered",
                                active: true,
                            },
                        ]
                    },
                    props: { status:'delivered' }
                },
                {
                    path: "rejected",
                    component: () => import(/* webpackChunkName: "rrhhRequestRejected" */ "@/views/commons/components/request/components/RequestGrid.vue"),
                    name: "rrhh-request-rejected",
                    meta: {
                        status: 4,
                        pageTitle: "Request",
                        moduleName: "rrhh",
                        permittedRoles: [1, 2, 17],
                        breadcrumb: [
                            {
                                text: "Rejected",
                                active: true,
                            },
                        ]
                    },
                    props: { status:'rejected' }
                },
            ]
        },
        {
            path: "request-products",
            name: "product-request-rrhh2",
            component: () => import(/* webpackChunkName: "rrhhProductCreate" */ "@/views/commons/components/request/components/ProductsSelector.vue"),
            meta: {
                pageTitle: "Request",      
                permittedRoles: [1, 2, 17],          
                breadcrumb: [
                    {
                        text: "product",
                        active: true,
                    },
                ],
            },
        }
    ];
