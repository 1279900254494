export default {
    namespaced: true,
    state: {
      S_COUNTER_CHARGE_BACK: 0,
    },
    mutations: {
      M_SET_COUNTER_CHARGE_BACK(state, payload) {
        state.S_COUNTER_CHARGE_BACK = payload
      },
  
    },
    actions: {
      A_SET_COUNTERS_CHARGE_BACK({ commit }, payload) {
        commit('M_SET_COUNTER_CHARGE_BACK', payload)
      },
    },
  }
  