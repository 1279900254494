export default [
  {
    key: 'date',
    sortable: false,
    label: 'Date',
    visible: true,
  },
  {
    key: 'type',
    sortable: false,
    label: 'Type',
    visible: true,
  },
  {
    key: 'transaction_id',
    sortable: false,
    label: 'Transaction Id',
    visible: true,
  },

  {
    key: 'amount',
    sortable: false,
    label: 'Amount',
    visible: true,
  },
  {
    key: 'method',
    sortable: false,
    label: 'Method',
    visible: true,
  },
  {
    key: 'status',
    sortable: false,
    label: 'Status',
    visible: true,
  },
  {
    key: 'ref_transaction',
    sortable: false,
    label: 'Ref Transaction',
    visible: true,
  },
];
