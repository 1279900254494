export default [
  {
    path: "settings",
    name: "crm-settings",
    redirect: { name: "crm-settings-permissions" },
    component: () => import(/* webpackChunkName: "CrmSettingsTab" */ "../view/components/SettingsTab.vue"),
    meta: {
      pageTitle: "Settings",
      permittedRoles: [1, 2, 17],
      breadcrumb: [
        {
          text: "Settings",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "permissions",
        component: () => import(/* webpackChunkName: "CrmSettingsPermissions" */ "../view/components/SettingsPermissions.vue"),
        name: "crm-settings-permissions",
        meta: {
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Permissions",
              active: true,
            },
          ],
          tab: 1,
          permittedRoles: [1, 2, 17],
        },
      },
      {
        path: "motives",
        component: () => import(/* webpackChunkName: "CrmSettingsMotives" */ "../view/components/SettingsMotives.vue"),
        name: "crm-settings-motives",
        meta: {
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Motives",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 17],
          tab: 2,
        },
      },
      {
        path: "",
        redirect: { name: "crm-settings-sms" },
        component: () => import(/* webpackChunkName: "CrmSettingsMotives" */ "../view/components/SettingsFinancialMessages.vue"),
        name: "crm-settings-financial-messages",
        meta: {
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Financial Messages",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 17],
          tab: 3,
        },
        children: [
          {
            path: "financial_messages_sms",
            component: () => import(/* webpackChunkName: "CrmSettingsPermissions" */ "../view/components/SubTabFinancialMessages.vue"),
            name: "crm-settings-sms",
            meta: {
              pageTitle: "Settings",
              breadcrumb: [
                {
                  text: "Permissions",
                  active: true,
                },
              ],
              tab: 1,
              permittedRoles: [1, 2, 17],
            },
          },
        ],
      },
    ],
  },
];
