import Pusher from "pusher-js"

import {
  newClaimNotification,
  SolvedClaimNotification,
  ScaledClaimNotification,
  ClaimNotificationDepartaments,
  NewInquiryNotification,
  SolvedInquiryNotification,
  AnnulledClaimNotification,
  CloseAllClaimNotification,
} from "@/views/commons/components/claims_v2/sockets/Claims.socket"

const subscribeClaimsSocket = (socket) => {
  try {
    socket.subscribe("claims-notifications")
    newClaimNotification()
    SolvedClaimNotification()
    ScaledClaimNotification()
    ClaimNotificationDepartaments()
    NewInquiryNotification()
    SolvedInquiryNotification()
    AnnulledClaimNotification()
    CloseAllClaimNotification()
  } catch (error) {
    console.error(error, "error")
  }
}

export default subscribeClaimsSocket
