import { amgApi } from "@/service/axios";
import { ca } from "date-fns/locale";

class CourtInfoService {
  async searchCourtInfo(params) {
    try {
      const { data, status } = await amgApi.post(
        "debt-solution/court-info/search-court-info",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getDataClientById(params) {
    try {
      const { data } = await amgApi.post(
        "debt-solution/court-info/get-data-client-by-id",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllServiceTypeCi() {
    try {
      const { data } = await amgApi.get(
        "debt-solution/court-info/get-all-service-type-ci"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllDocumentTypeCi(params) {
    try {
      const { data } = await amgApi.post(
        "debt-solution/court-info/get-all-document-type-ci",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCourtCase(params) {
    try {
      const { data } = await amgApi.post(
        "debt-solution/court-info/get-court-case",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getLeadCourtCasesPendingToUpdate(params) {
    try {
      const { data } = await amgApi.post(
        "debt-solution/court-info/get-lead-court-cases-pending-to-update",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async storeGiveIndications(params) {
    try {
      const { data } = await amgApi.post(
        "debt-solution/court-info/store-give-indications",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async insertDocument(params) {
    try {
      const { data } = await amgApi.post(
        "debt-solution/court-info/insert-document",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveInformationOfQuestionnaire(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/save-new-questionnaire",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async createClientRecurrencyTaskForTracking(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/create-client-recurrency-task-for-tracking",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getformationOfQuestionnaire(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-new-questionnaire",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getRecurrencyTaskPerLeadOrClient(params) {
    try {
      const { data } = await amgApi.post(
        "debt-solution/court-info/get-recurrency-task-per-lead-or-client",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createCaseCourtInfo(params) {
    try {
      const { data } = await amgApi.post(
        "debt-solution/court-info/create-case-court-info",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async assingCaseCourtInfo(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/assing-case-court-info",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTrackingAssingCaseCourtInfo(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-tracking-assing-case-to-user",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllCreditorsAccountsCi(params) {
    try {
      const { data } = await amgApi.post(
        "debt-solution/court-info/get-all-creditors-accounts-ci",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllCourtTypeCi() {
    try {
      const { data } = await amgApi.get(
        "debt-solution/court-info/get-all-court-type-ci"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllCasesByClientIdCi(params) {
    try {
      const { data, status } = await amgApi.post(
        "debt-solution/court-info/get-all-cases-by-client-id-ci",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async saveDocuments(params) {
    try {
      const { data, status } = await amgApi.post(
        "debt-solution/court-info/upload-formats-completed",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getAllFilesByCase(params) {
    try {
      const { data, status } = await amgApi.post(
        "debt-solution/court-info/get-all-files-by-case",
        params
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getAllCaseFormatsByCase(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-all-case-formats-by-case",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async reconfirmCaseCourtInfo(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/reconfirm-case-court-info",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRequiredFormats(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-required-formats",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getRequiredFormatsByDocumentCase(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-required-formats-by-document-case",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getDataCourtCase(params) {
    try {
      const { data } = await amgApi.post(
        "debt-solution/court-info/get-data-ci-case",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getUploadFormatsStep(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-formats-upload-step",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getInfoPrepareAnswer(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-info-prepare-answer",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCurrentStep(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/update-current-step-answer",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async DeleteFilesCase(id) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/delete-files-case",
        id
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCaseTracking(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-case-tracking",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCurrentRecurrencyTask(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-current-recurrency-task",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getInfoTimelineByCase(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-info-timeline-by-case",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTotalAmountCi(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-total-amount-ci",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getTotalAmountCiDetail(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-total-amount-ci-detail",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCiGeneralSettings() {
    try {
      const data = await amgApi.get(
        "debt-solution/court-info/get-ci-general-settings"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCiGeneralSettings(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/insert-or-update-general-settings",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getIndications(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-indications",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllCaseSteps() {
    try {
      const data = await amgApi.get(
        "debt-solution/court-info/get-all-case-steps"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDsClientSchedule(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-ds-client-schedule",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCiServiceTypes() {
    try {
      const data = await amgApi.get(
        "debt-solution/court-info/get-ci-service-types"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCiDocuments() {
    try {
      const data = await amgApi.get(
        "debt-solution/court-info/get-ci-documents"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async courtIsNotFromCA(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/court-is-not-from-ca",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCiDocument(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-ci-document",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCiDocumentsByServiceType(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-ci-documents-by-service-type",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCasePendingPayments(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-case-pending-payments",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async CiPayPendingPayment(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/ci-pay-pending-payment",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllCourtTypeCiByState(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-all-court-type-ci-by-state",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCiMotives(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-ci-motives",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateCiMotive(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/update-ci-motive",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addCiMotive(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/add-ci-motive",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteCiMotive(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/delete-ci-motive",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteCiCaseFormat(caseFormatId) {
    try {
      const data = await amgApi.put(
        `debt-solution/court-info/delete-ci-case-document-format/${caseFormatId}`,
        {}
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new CourtInfoService();
