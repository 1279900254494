<template>
  <div>
    <div class="d-flex">
      <b-button
        variant="primary"
        :to="currentUser.user_id == 1 ? '/home' : '/dashboard'"
      >
        <feather-icon
          icon="ChevronLeftIcon"
          style="margin-right: 0.6rem"
        />RETURN
      </b-button>
    </div>
    <div class="bg-home">
      <div class="pt-5 center-card-home">
        <b-row class="justify-content-center">
          <b-col
            v-for="(item, index) in userChildModules"
            :key="index"
            md="2"
            sm="4"
            cols="6"
            class="text-center mb-5 center-fix"
          >
            <div
              class="card-logo cursor-pointer"
              @click="changeSubModule(item)"
            >
              <img
                :src="`/assets/${item.module_icon}`"
                alt="Logo"
                style="width: auto"
                height="65"
                class="mt-13"
                :class="{ 'gray-home': [20].includes(item.module_id) }"
              />
              <p
                class="font-name-home mt-1"
                :class="[skin == 'dark' ? 'text-light' : 'text-dark']"
              >
                {{ item.module_name }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import userService from "@/service/user/user.service";
import AlertActionService from "@/views/ce-digital/sub-modules/customer-service/views/auto-alerts/services";

export default {
  name: "MenuCe",
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      navbarConfig: this.$store.state.appConfig.layout.navbar.type,
      enviroment: process.env.VUE_APP_ENVIROMENT,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/G_USER_STATUS_SESSION_SUB_MODULE",
      alertActive: "CeDigitalCsAlerts/getStatusAlert",
    }),
    userChildModules() {
      let modules = [];
      if (Array.isArray(this.currentUser.arrRoles)) {
        modules = this.currentUser.arrRoles.filter(
          (item) => item.parent_id === 21 || item.module_id === 6
        );
      } else {
        modules = JSON.parse(this.currentUser.arrRoles).filter(
          (item) => item.parent_id === 21 || item.module_id === 6
        );
      }
      const firstModule = modules.filter((item) => item.module_id === 6);
      const secondModule = modules.filter((item) => item.module_id === 22);
      const otherModules = modules.filter(
        (item) => item.module_id !== 6 && item.module_id !== 22
      );
      return [...firstModule, ...secondModule, ...otherModules];
    },
    skin() {
      return this.$store.getters["appConfig/skin"];
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
  },
  methods: {
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
      A_GET_USER_STATUS_SUBMODULE_SESSION:
        "UserStore/A_GET_USER_STATUS_SUBMODULE_SESSION",
      A_OPEN_MODAL_ACTIONS: "CeDigitalWelcomeActions/A_OPEN_MODAL_ACTIONS",
    }),
    ...mapMutations({
      SET_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/SET_USER_STATUS_SESSION_SUB_MODULE",
    }),
    async moveToSubModule(item) {
      await this.$router.push({ path: item.module_route });
      const { data } = await AlertActionService.getAlertAccepted({
        user_id: this.currentUser.user_id,
      });
      if (data?.length > 0 && this.moduleId === 22) {
        this.$store.commit("AutoAlertStore/OPEN_ALERT_WITH_ID", {
          bool: true,
          id_alert: data[0].id,
          client_data: data[0],
        });
        // alert
      } else if (this.moduleId === 22) {
        await this.$store.dispatch(
          "UserStore/A_GET_USER_STATUS_SUBMODULE_SESSION",
          {
            user_id: this.currentUser.user_id,
            module_id: this.moduleId,
          }
        );
      } else {
        const params = {
          created_by: this.currentUser.user_id,
          sub_module_id: item.module_id,
          status_sub_module_id: 1,
        };
        this.SET_USER_STATUS_SESSION_SUB_MODULE([{ status_session: 1 }]);
        await this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(params);
      }
    },
    async changeSubModule(item) {
      if (item.module_id !== 24 && item.module_id !== 6) {
        const moduleData = await userService.getCurrentActiveModule({
          user_id: this.currentUser.user_id,
        });
        if (moduleData.status === 200) {
          await this.moveToSubModule(item);
        }
      } else {
        await this.$router.push({ path: item.module_route });
      }
    },
  },
};
</script>

<style scoped>
.bg-home {
  background-color: transparent;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* center */
  display: table;
  width: 100%;
}
.center-card-home {
  /* center */
  display: table-cell;
  vertical-align: middle;
}
.card-logo {
  transition: all 0.2s ease-in-out;
  width: 120px;
  height: 120px;
  border-radius: 10px;
  text-decoration: none !important;
  border: 1px solid #dbdbdb;
}
.card-logo:hover {
  border: 1.5px solid #3764ff;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.center-fix {
  display: flex;
  justify-content: center;
}
.font-name-home {
  font-family: "Rubik", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
}
.gray-home {
  transition: all 0.1s ease;
  filter: invert(93%) sepia(12%) saturate(0%) hue-rotate(127deg) brightness(83%)
    contrast(80%);
}
.card-logo:hover .gray-home {
  filter: invert(59%) sepia(7%) saturate(18%) hue-rotate(11deg) brightness(97%)
    contrast(89%);
}
.mt-13 {
  margin-top: 13px !important;
}
</style>
