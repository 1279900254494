export default {
  path: "commissions",
  name: "connection-commissions",
  component: () =>
    import(
      /* webpackChunkName: "ConnectionCommissions" */
      "@/views/ce-digital/sub-modules/connection/views/commissions/Commissions.vue"
    ),
  meta: {
    pageTitle: "Commissions",
    parentModule: 21,
    module: 20,
    moduleId: 20,
    permittedRoles: [1, 2, 14, 15, 17],
    breadcrumb: [
      {
        text: "Commissions",
        active: true,
      },
    ],
  },
};
