<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      change-columns-by-client
      :start-page="startPage"
      :to-page="toPage"
      :double-paginate="false"
      @reload="$refs['smsHistory'].refresh()"
      @onChangeCurrentPage="$refs['smsHistory'].refresh()"
      @reset-all-filters="$refs['smsHistory'].refresh()"
    >
      <template #buttons>
        <div class="d-flex ml-2">
          <b-button
            :disabled="isResponsibleLead"
            variant="success"
            @click="openModalSendSms"
            >Send SMS</b-button
          >
        </div>
      </template>

      <b-table
        id="smsHistory"
        slot="table"
        ref="smsHistory"
        :items="myProvider"
        :fields="fields"
        :filter="filters"
        table-class="text-nowrap"
        responsive="sm"
        no-border-collapse
        sticky-header="245px"
        show-empty
        :busy.sync="isBusy"
        no-provider-filtering
        :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(sent_by)="data">
          <div
            class="w-100 custom-space d-flex justify-content-left align-items-center"
            :class="
              isDarkSkin
                ? 'bg-dark'
                : 'bg-white border-top-light border-bottom-light border-left-light'
            "
          >
            <div class="w-100 m-0">
              <span>{{ data.item.sent_by }}</span>
              <br />
              <strong>{{ data.item.created_at | myGlobal }}</strong>
            </div>
          </div>
        </template>

        <template #cell(type)="data">
          <div
            class="w-100 d-flex justify-content-left align-items-center custom-space"
            :class="
              isDarkSkin
                ? 'bg-dark'
                : 'bg-white border-top-light border-bottom-light'
            "
          >
            {{ data.item.mass ? data.item.mass : "---" }}
          </div>
        </template>

        <template #cell(line)="data">
          <div
            class="w-100 d-flex justify-content-left align-items-center custom-space text-primary"
            :class="
              isDarkSkin
                ? 'bg-dark'
                : 'bg-white border-top-light border-bottom-light'
            "
          >
            <feather-icon icon="PhoneCallIcon" class="mr-50" />
            {{ data.item.line ? data.item.line : "---" }}
          </div>
        </template>

        <template #cell(actions)="data">
          <div
            class="w-100 d-flex justify-content-center align-items-center custom-space"
            :class="
              isDarkSkin
                ? 'bg-dark'
                : 'bg-white border-top-light border-bottom-light border-right-light'
            "
          >
            <feather-icon
              v-b-tooltip.hover
              icon="SearchIcon"
              class="text-primary cursor-pointer"
              title="Show Content"
              size="17"
              @click="
                openModalContent(
                  data.item.mass,
                  data.item.content,
                  data.item.created_at,
                  data.item.sent_by
                )
              "
            />
          </div>
        </template>
      </b-table>
    </filter-slot>
    <modal-content
      v-if="modalShow"
      :list-content="listContent"
      :list-name="listName"
      :list-author="listAuthor"
      :list-date="listDate"
      :list-type="listType"
      @close="modalShow = false"
    />

    <modal-send-sms
      v-if="modalSendSms"
      :smss="[]"
      :typesms="1"
      :sms="[leadId]"
      :name-leads="[
        {
          id: leadId,
          nick: leadData.nickname,
          name: leadData.lead_name,
        },
      ]"
      @hide="modalSendSms = false"
    />
  </div>
</template>

<script>
import Filters from "@/views/commons/components/ring-central/mass-messages/data/FiltersHistory.data.js";
import Fields from "@/views/commons/components/ring-central/mass-messages/data/FieldsHistory.data.js";
import MassMessageService from "@/views/commons/components/ring-central/mass-messages/service.js";
import ModalContent from "@/views/commons/components/ring-central/mass-messages/components/ModalContent.vue";
import ModalSendSms from "@/views/crm/views/Lead/lead-sms/ModalSendSms.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalContent,
    ModalSendSms,
  },
  props: {
    leadId: {
      type: [Number, String],
      required: true,
    },
    leadData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  data() {
    return {
      modalShow: false,
      modalSendSms: false,
      listName: "",
      listContent: "",
      listDate: "",
      listAuthor: "",
      listType: "",

      listId: [],

      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by List Name, Line Number",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      filters: Filters,
      startPage: null,
      toPage: null,
      sortBy: "1",
      sortDesc: true,
      fields: Fields,
      isBusy: false,
    };
  },

  methods: {
    async myProvider(ctx) {
      try {
        this.addPreloader();

        const params = {
          module_id: this.moduleId,
          search_txt: this.filterPrincipal.model,
          from_date: this.filters[0].model,
          to_date: this.filters[1].model,
          lead_id: this.leadId,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
        };
        const { data } = await MassMessageService.leadHistorySms(params);
        this.startPage = data.from ? data.from : 0;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
        return data.data || [];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    openModalContent(name, content, date, author) {
      this.listName = name || name != "" ? name : "Message";
      this.listContent = content || "Without Content";
      this.listDate = date;
      this.listAuthor = author;
      this.listType = name ? "From List" : "Other";
      this.modalShow = true;
    },

    openModalSendSms() {
      this.modalSendSms = true;
    },
  },
};
</script>

<style scoped>
.mh-5 {
  min-height: 500px;
}
.custom-table
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th {
  background-color: #0090e7 !important;
  color: white;
  padding: 12px 10px !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child {
  border-top-right-radius: 10px !important;
}
.custom-table >>> tbody tr td {
  border-style: none !important;
}
.custom-table >>> .table tr td .custom-space,
.custom-table-dark >>> .table tr td .custom-space {
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.custom-table >>> .table tr td:first-child .custom-space,
.custom-table-dark >>> .table tr td:first-child .custom-space {
  border-radius: 10px 0px 0px 10px !important;
  padding-left: 20px !important;
}
.custom-table >>> .table tr td:last-child .custom-space,
.custom-table-dark >>> .table tr td:last-child .custom-space {
  border-radius: 0px 10px 10px 0px !important;
}
.b-table > tbody > tr {
  border: none !important;
}
.custom-img {
  width: 28px !important;
  height: 28px !important;
  border-radius: 50% !important;
}
.font-size-md {
  font-size: 13px;
}
</style>
