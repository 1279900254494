export default [
  {
    path: "loans",
    name: "loans-management",
    redirect: { name: "my-loans-management" },
    component: () =>
      import(
        /* webpackChunkName: "ManaLoansComponent" */ "@/views/commons/components/loans/LoansComponent.vue"
      ),
    children: [
      {
        path: "general",
        name: "general-loans-management",
        component: () =>
          import(
            /* webpackChunkName: "ManaLoansView" */ "@/views/commons/components/loans/LoansView.vue"
          ),
        meta: {
          tab: 3,
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "Loans",
              active: true,
            },
          ],
          route: "management",
        },
      },
      {
        path: "external",
        name: "external-loans-management",
        component: () =>
          import(
            /* webpackChunkName: "ManaLoansViewManagementExternal" */ "@/views/commons/components/loans/LoansViewManagementExternal.vue"
          ),
        meta: {
          tab: 3,
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "External Loans",
              active: true,
            },
          ],
          route: "management",
          type_tab: 2,
        },
      },
      {
        path: "my-loans",
        name: "my-loans-management",
        component: () =>
          import(
            /* webpackChunkName: "ManaLoansMyLoans" */ "@/views/commons/components/loans/LoansView.vue"
          ),
        meta: {
          tab: 1,
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "My Loans",
              active: true,
            },
          ],
          route: "management",
        },
      },
      {
        path: "loans-module",
        name: "loans-module-management",
        component: () =>
          import(
            /* webpackChunkName: "ManaLoansModule" */ "@/views/commons/components/loans/LoansView.vue"
          ),
        meta: {
          tab: 2,
          route: "management",
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "Loans By Module",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      tab: 1,
      route: "management",
    },
  },
]
