/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class RecruitmentPService {
  async getRecruitmentProcess(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/get-recruitment-process', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getRecruitmentStatus() {
    try {
      const { data, status } = await amgApi.post('/recruitment/get-recruitment-status');
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getMofsApproved(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/get-mofs-approved', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async registerRequestPersonnel(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/register-request-personnel', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async approveRequestRecruitment(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/approve-request-recruitment', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getCeoApprovedApplications(params) {
    try {
      const response = await amgApi.post('/recruitment/get-ceo-approved-applications', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getJobVacancies() {
    try {
      const data = await amgApi.get('/recruitment/get-job-vacancies');
      return data;
    } catch (error) {
      console.log('Something went wrong on getAllMerchants:', error);
      throw error;
    }
  }

  async getRecruitmentApplicants(params) {
    try {
      const response = await amgApi.post('/recruitment/get-recruitment-applicants', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getStatusApplicant(params) {
    try {
      const response = await amgApi.post('/recruitment/get-status-applicant', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async editStatusApplicant(params) {
    try {
      const response = await amgApi.post('/recruitment/edit-status-applicant', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getRecruitmentTimeline(params) {
    try {
      const response = await amgApi.post('/recruitment/get-recruitment-timeline', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteProcess(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/delete-recruitment-process', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async saveNewStatusApplicant(params) {
    try {
      const response = await amgApi.post('/recruitment/save-new-status-applicant', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteStatusApplicant(params) {
    try {
      const response = await amgApi.post('/recruitment/delete-status-applicant', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async changeStatusApplicant(params) {
    try {
      const response = await amgApi.post('/recruitment/change-status-applicant', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async registerNewApplicant(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/register-new-applicant', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getPostulantData(params) {
    try {
      const response = await amgApi.post('/recruitment/get-postulant-data', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCurrentStatus(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/get-current-status', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async addNewJobPortal(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/add-new-job-portal', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async addNewRecruitmentProcess(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/add-new-recruitment-process', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async addNewEmployeePassword(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/add-new-employee-password', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getTrackingPassword(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/get-tracking-password', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getRecruitmentJobVacanciesPivot(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/get-recruitment-job-vacancies-pivot', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getApplicantFiles(body) {
    try {
      const { data, status } = await amgApi.post('/recruitment/get-applicant-files', body);

      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getSingleApplicantReport(params) {
    try {
      const response = await amgApi.post('/rrhh-extra/get-single-applicants-report', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // get-applicant-status-tracking
  async getApplicantStatusTracking(params) {
    try {
      const response = await amgApi.post('/recruitment/get-applicant-status-tracking', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getRecruitmentStatusTracking(params) {
    try {
      const response = await amgApi.post('/recruitment/get-recruitment-status-tracking', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getApplicantsPendingCounter(params) {
    try {
      const response = await amgApi.post('/recruitment/get-applicants-pending-counter', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async createMeetingToRecruitment(params) {
    try {
      const response = await amgApi.post('/recruitment/create-meeting-to-recruitment', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getMeetingsToRecruitment(params) {
    try {
      const response = await amgApi.post('/recruitment/get-meetings-to-recruitment', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getApplicantStageFiles(params) {
    try {
      const response = await amgApi.post('/recruitment/get-applicant-stage-files', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getRejectedMotivesApplicant(params) {
    try {
      const response = await amgApi.post('/recruitment/get-rejected-motives-applicant', params);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new RecruitmentPService();
