export default [
    {
      path: "sms-inbox",
      name: "crm-chat-sms",
      component: () =>
        import(
          /* webpackChunkName: "CRM rc-chat-sms" */
          "@/views/commons/components/ring-central/chat-sms/RcChatMain.vue"
        ),
      meta: {
        pageTitle: "Messages",
        // permittedRoles: [1, 2, 17],
        breadcrumb: [
          {
            text: "Inbox",
            active: true,
          },
        ],
  
      },
    },
  ]
  