import store from '@/store';
import Vue from 'vue';
import router from "@/router";

const RequestNotificationSocket = () => {
    window.socket.bind('request-logistic-notification', async data => {
        const { user_id } = store.getters["auth/currentUser"];
        if (data.user_ids.filter((id) => id != data.loggedInUserId).includes(user_id)) {
            let modulename = data.modulename;
            const icon = "InfoIcon";
            let title = "New request";
            let description = "New request made by " + data.fullname;

            Vue.prototype.$amgAlert({
                title: title,
                subtitle: description,
                data: [
                    { icon: 'BriefcaseIcon', title: 'DEPARTMENT', description: modulename },
                ],
                okTitle: 'Go to requests',
                icon,
                ok: () => {
                    const goSection = "request-logistic";
                    if (router.currentRoute.name !== goSection) {
                        router.push({ name: goSection });
                    }
                    const param = {
                        to_id: data.user_ids
                    }
                    window.amgApi.post('/commons/close-all-swal', param);
                },
            });
        }
    })
};

const AssignedToNotificationSocket = () => {
    window.socket.bind('assigned-logistic-notification', async data => {
        let moduleId = store.state.auth.currentUser.modul_id;
        store.dispatch("RequestStore/A_SET_COUNTERS_DELIVERED", { moduleId: moduleId });
    })
};


export { RequestNotificationSocket, AssignedToNotificationSocket };
