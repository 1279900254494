export default {
  path: "commissions",
  name: "tax-research-commissions",
  component: () => import(/* webpackChunkName: "TaxResearchCommissions" */ "@/views/tax-research/views/commissions/index.vue"),
  meta: {
    pageTitle: "Commissions",
    breadcrumb: [
      {
        text: "Commissions",
      },
    ],
  },
};
