<template>
  <div>
    <b-alert fade v-if="showAlertReschedule" class="p-1" show variant="warning">
      <feather-icon icon="AlertTriangleIcon" size="20" class="mr-1" />
      Important: please reschedule this appointment
    </b-alert>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <!-- Form -->
      <b-form
        class="pt-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="Title"
                  rules="required"
                >
                  <b-form-group
                    label="Title"
                    label-for="title"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      input-id="title"
                      v-model="event.title"
                      label="label"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      :options="G_EVENT_TITLES_OPTIONS"
                      :reduce="(val) => val.value"
                      :disabled="isDisabled"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="validationContext"
                  name="Seller"
                  rules="required"
                >
                  <b-form-group
                    label="Seller"
                    label-for="seller"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      input-id="seller"
                      v-model="event.user_id"
                      label="label"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sellers"
                      :disabled="isDisabled"
                      :clearable="false"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Location"
                  rules="required"
                >
                  <b-form-group label="Location" label-for="location">
                    <b-form-input
                      id="location"
                      v-model="event.location"
                      :state="errors[0] ? false : null"
                      :disabled="isDisabled"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description" label-for="description">
                  <b-form-textarea
                    id="description"
                    v-model="event.description"
                    :disabled="isDisabled"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="event.type !== 'task' && event.attend === 2"
                cols="12"
              >
                <validation-provider>
                  <b-form-group label="Comment" label-for="comment">
                    <b-form-textarea :value="event.comment" :disabled="true" />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col md="4">
                <b-form-group label="Date" label-for="date">
                  <flat-pickr
                    v-model="event.date"
                    id="date"
                    placeholder="Date"
                    class="form-control"
                    :config="configFlatPickr"
                    :disabled="isDisabled"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="From" label-for="from">
                  <kendo-timepicker
                    :format="'HH:mm'"
                    v-model="event.from"
                    :interval="30"
                    class="w-100 rounded bg-transparent"
                    :disabled="isDisabled"
                    style="height: 2.73rem"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="To" label-for="to">
                  <kendo-timepicker
                    :format="'HH:mm'"
                    v-model="event.to"
                    :interval="30"
                    class="w-100 rounded bg-transparent"
                    placeholder="Hour"
                    :disabled="isDisabled"
                    style="height: 2.73rem"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group label="Lead" label-for="lead">
                  <b-form-input
                    id="lead"
                    :value="event.lead_name"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Created by" label-for="creadtedby">
                  <b-form-input :value="event.creator_name" :disabled="true" />
                </b-form-group>
              </b-col>
              <b-col v-if="event.userupdate" md="4">
                <validation-provider label-cols="2">
                  <b-form-group label="Modified by">
                    <b-form-input
                      :value="event.updater_name"
                      :disabled="true"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col v-if="event.type !== 'task'" md="4">
                <validation-provider label-cols="2">
                  <b-form-group label="Attended" label-for="attended">
                    <b-form-input
                      :class="
                        event.attend
                          ? event.attend === 5
                            ? 'text-danger'
                            : 'text-success'
                          : 'text-danger'
                      "
                      :value="
                        event.attend
                          ? event.attend === 5
                            ? 'NO'
                            : 'YES'
                          : 'NO'
                      "
                      :disabled="true"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="
                  event.type !== 'task' &&
                  (event.attend === 2 || event.attend === 5)
                "
                md="4"
              >
                <b-form-group label="Sale Made" label-for="saleMade">
                  <b-form-input
                    :class="
                      event.sale_made === 'YES' ? 'text-success' : 'text-danger'
                    "
                    :value="event.sale_made"
                    :disabled="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="5" class="text-left mt-1 mb-1" v-if="isCrm && !attendBySeller">
            <div class="d-flex justify-content-between align-items-center mt-2">
              <span class="font-weight-bold mr-1"> Lead answer the call? </span>
              <b-form-checkbox
                v-model="isCheckedAnswer"
                value="no"
                @change="changeAnswer"
                :disabled="event.attend === 2 || event.attend === 1"
                class="mr-2 d-inline"
                >No</b-form-checkbox
              >
              <b-form-checkbox
                v-model="isCheckedAnswer"
                value="yes"
                :disabled="event.attend === 2 || event.attend === 1"
                @change="changeAnswer"
                class="mr-2 d-inline"
                >Yes</b-form-checkbox
              >
            </div>
          </b-col>

          <b-col
            offset-md="1"
            md="5"
            class="text-left mt-1 mb-1"
            v-if="event.call_answered && isCrm"
          >
            <div class="d-flex justify-content-between align-items-center mt-2">
              <span class="font-weight-bold mr-1"> Lead attend the call? </span>
              <b-form-checkbox
                v-model="isCheckedAttend"
                @change="changeAttend"
                :disabled="event.attend === 2 || event.attend === 1"
                value="no"
                class="mr-2 d-inline"
                >No</b-form-checkbox
              >
              <b-form-checkbox
                v-model="isCheckedAttend"
                value="yes"
                class="mr-2 d-inline"
                :disabled="event.attend === 2 || event.attend === 1"
                @change="changeAttend"
                >Yes</b-form-checkbox
              >
            </div>
          </b-col>
        </b-row>

        <!-- Form Actions -->
        <div class="d-flex float-right mt-2">
          <template>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              class="mr-2"
              type="button"
              :disabled="isLoading"
              v-if="
                !(
                  event.type !== 'task' &&
                  (event.attend === 2 || event.attend === 5)
                ) && !flagVerDetalle
              "
              @click="onDeleteEvent"
            >
              <template v-if="isLoading">
                <b-spinner small />
                <span>Loading...</span>
              </template>
              <template v-else>
                <feather-icon icon="Trash2Icon" class="mr-50" />
                <span>Delete</span>
              </template>
            </b-button>
          </template>

          <template
            v-if="
              isCheckedAnswer &&
              isCheckedAnswer != 'no' &&
              isCheckedAttend != 'yes' &&
              isCheckedAttend
            "
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="submit"
              variant="outline-success"
              :disabled="!isCheckedAttend"
              v-if="
                !(
                  event.type !== 'task' &&
                  (event.attend === 2 || event.attend === 5)
                ) && !flagVerDetalle
              "
            >
              <feather-icon icon="CalendarIcon" class="mr-50" />
              <span>Reschedule</span>
            </b-button>
          </template>

          <!-- <b-button
            v-if="isCheckedAnswer === 'no'"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="submit"
            variant="outline-danger"
            class="ml-2"
            :disabled="isLoading"
          >
            <feather-icon icon="XIcon" class="mr-50" />
            <span>Cancel</span>
          </b-button> -->

          <b-button
            v-if="isCheckedAnswer === 'no' && event.attend !== 2"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="submit"
            variant="outline-success"
            class="ml-2"
            :disabled="isLoading"
          >
            <feather-icon icon="SaveIcon" class="mr-50" />
            <span>Reschedule</span>
          </b-button>

          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="noAnswered"
            variant="danger"
            class="ml-2"
            :disabled="isLoading || event.counter === 1"
            v-if="isFromLeadDashboard && !event.attend && moduleId == 2"
          >
            <template>
              <feather-icon icon="XIcon" class="mr-50" />
              <span v-if="event.counter === 1">No Answered</span>
              <span v-else>No Answer</span>
            </template>
          </b-button> -->

          <b-button
            v-if="!isCrm"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="primary"
            class="ml-2"
            :disabled="
              attendBySeller ||
              isLoading ||
              event.attend != 0 ||
              event.attend === 5 ||
              moduleId === 4
            "
            @click="onAttend"
          >
            <template>
              <feather-icon v-if="event.attend" icon="SaveIcon" class="mr-50" />
              <feather-icon v-else icon="CalendarIcon" class="mr-50" />
              <span v-if="event.attend">Attended</span>
              <span v-else>Attend</span>
            </template>
          </b-button>
        </div>
      </b-form>
    </validation-observer>

    <!-- modal SALE MADE -->
    <b-modal
      id="modal-sale-made"
      title-class="h3 text-white"
      modal-class="modal-primary"
      centered
      size="sm"
      no-close-on-backdrop
      title="Sales Made"
      hide-footer
      @close="(event.call_attended = false), (isCheckedAttend = false)"
    >
      <modal-sale-made
        :idProcessSale="idProcessSale"
        ref="modalSaleMade"
        :only-read="onlyRead"
        :event="event"
        :lead="lead"
        @closeModal="closeModal"
        @updateAppointment="updateAppointment($event)"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import appointmentsService from "@/views/crm/views/Lead/lead-event/services/appointments.services.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import moment from "moment";
import ModalSaleMade from "./ModalSaleMade.vue";
import VueToggles from "vue-toggles";

export default {
  components: {
    vSelect,
    ModalSaleMade,
    VueToggles,
    flatPickr,
  },
  props: {
    isSocialNetwork: {
      type: Number,
      required: false,
    },
    idProcessSale: {
      type: String,
      default: null,
    },
    onlyRead: {
      type: Boolean,
      required: true,
    },
    lead: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    flagVerDetalle: {
      type: Boolean,
      default: false,
    },
    reply_id: {
      type: Number,
      default: null,
    },
    isFromLeadDashboard: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { refFormObserver, getValidationState } = formValidation(() => {});

    return {
      refFormObserver,
      getValidationState,
    };
  },
  data() {
    return {
      isCheckedAnswer: false,
      isCheckedAttend: false,
      isDisabled: true,
      isLoading: false,
      maxDate: new Date(2050, 9, 1),
      maxTime: new Date(2049, 11, 31, 24, 0, 0),
      minDate: new Date(),
      minTime: new Date(1950, 0, 1, 0, 0, 0),
      valueTime: "00:00:00",
      sellers: [],
      configFlatPickr: {
        dateFormat: "m/d/Y",
        locale: "en",
        minDate:
          moment(this.event.date).format("MM/DD/YYYY") >
          moment().format("MM/DD/YYYY")
            ? moment().format("MM/DD/YYYY")
            : moment(this.event.date).format("MM/DD/YYYY"),
      },
      showAlertReschedule: false,
    };
  },
  async created() {
    await this.getOwners();
    this.sellers = this.G_OWNERS;
    this.event.date = moment(this.event.date).format("MM/DD/YYYY");
    this.setDataBlank("event");

    // ANSWER CHECK
    if (this.event.call_answered === 1) {
      this.isCheckedAnswer = "yes";
    } else if (this.event.call_answered === 0) {
      this.isCheckedAnswer = "no";
    } else {
      this.isCheckedAnswer = null;
    }
    // ATTEND CHECK
    if (this.event.call_attended === 1) {
      this.isCheckedAttend = "yes";
    } else if (this.event.call_attended === 0) {
      this.isCheckedAttend = "no";
    } else {
      this.isCheckedAttend = null;
    }

    this.changeAnswer();
    this.changeAttend();
    // if (
    //   this.isCheckedAnswer === "yes" &&
    //   this.isCheckedAttend === "yes" &&
    //   (this.event.attend == 1 || this.event.attend == 2)
    // ) {
    //   this.isDisabled = true;
    // }
  },
  computed: {
    isCrm() {
      return this.$route.matched[0].meta.module === 2;
    },
    modalClose() {
      this.$emit.close();
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_EVENT_TITLES_OPTIONS: "CrmEventStore/G_EVENT_TITLES",
      G_OWNERS: "CrmGlobalStore/G_OWNERS",
    }),
    dateSp() {
      return new Date(this.event.date.replace(/-/g, "/")).toLocaleDateString(
        "es-ES",
        {
          weekday: "long",
          month: "long",
          day: "numeric",
        }
      );
    },
    dateEn() {
      return new Date(this.event.date.replace(/-/g, "/")).toLocaleDateString(
        "en-EN",
        {
          weekday: "long",
          month: "long",
          day: "numeric",
        }
      );
    },
    hourMsn() {
      return this.$moment(`${this.event.date} ${this.event.from}`).format(
        "h:mm A"
      );
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    attendBySeller() {
      const { can_attend_to } = this.event;
      if (can_attend_to === null || can_attend_to === undefined) return false;
      return can_attend_to === 0;
    },
  },
  watch: {
    "event.call_attended"(newVal, oldVal) {
      if (newVal) {
        if (this.event.attend != 1 && this.event.attend != 2) {
          this.$bvModal.show("modal-sale-made");
        }
      }
    },
    "event.call_answered"(newVal, oldVal) {
      if (newVal) {
        // this.event.call_attended = false;
        if (this.event.call_answered) {
          this.event.call_answered = true;
        } else {
          this.event.call_answered = false;
        }
      }
    },
  },
  methods: {
    async changeAnswer() {
      if (this.isCheckedAnswer == "no") {
        this.showAlertReschedule = true;
        this.isDisabled = false;
      } else {
        this.showAlertReschedule = false;
        this.isDisabled = true;
      }
      let value = this.isCheckedAnswer;
      if (value === "yes") {
        this.event.call_answered = true;
        this.isDisabled = true;
      } else if (value === "no") {
        this.event.call_answered = false;
        this.isDisabled = false;
        this.isCheckedAttend = null;
      } else {
        this.event.call_answered = false;
        this.isCheckedAttend = null;
        this.isDisabled = true;
      }
    },

    async changeAttend() {
      let value = this.isCheckedAttend;
      if (value === "yes") {
        this.event.call_attended = true;
        this.showAlertReschedule = false;
        this.isDisabled = true;
      } else if (value === "no") {
        this.event.call_attended = false;
        this.isDisabled = false;
        this.showAlertReschedule = true;
      } else {
        this.event.call_attended = false;
        this.showAlertReschedule = false;
        // if (this.isCheckedAnswer === "no" || this.isCheckedAnswer === "yes") {
        //   this.isDisabled = false;
        // } else {
        //   this.isDisabled = false;
        // }
        this.isDisabled = true;
      }
    },

    async updateAppointment(emitData = null) {
      try {
        const responseFirst = await this.A_GET_DATE_EVENTS_TASKS({
          idLead: this.event.lead_id,
          id: this.event.id,
        });
        if (this.isResponseSuccess(responseFirst)) {
          const dateFormat = this.$moment(
            `${this.event.date} ${this.event.from}`
          ).format("YYYY-MM-DD HH:mm:ss");
          let repeat = 0;
          responseFirst.data.forEach((el) => {
            if (el.dates == dateFormat) repeat++;
          });
          if (repeat === 0) {
            const body = {
              ...this.event,
              user_id: this.event.user_id.value,
              id: this.event.id,
              title: this.event.title,
              date: this.event.date,
              from: this.event.from,
              to: this.event.to,
              host: this.event.user_id.value,
              location: this.event.location,
              description: this.event.description,
              seller: this.event.user_id.label,
              current_user: this.currentUser.user_id,
              month: this.getCurrentMonth(),
              module: this.moduleId,
            };
            await appointmentsService.accountsRemoveToEvent({
              leadId: this.event.lead_id,
              eventId: this.event.id,
            });
            const response = await this.A_UPDATE_EVENT(body);
            if (this.isResponseSuccess(response)) {
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Appointment updated successfully"
              );
              this.setDataBlank("event");
              this.onToggleEdit();
              this.isLoading = false;
              this.$emit("updated", body);
            }
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.$refs.modalSaleMade.emitReloadAmountSaved2(emitData);
      }
    },

    onClickToggleDialogue(prop) {
      this.event[prop] = !this.event[prop];
    },
    ...mapActions({
      A_GET_OWNERS: "CrmGlobalStore/A_GET_OWNERS",
      A_SET_EVENT: "CrmEventStore/A_SET_EVENT",
      A_DELETE_EVENT: "CrmEventStore/A_DELETE_EVENT",
      A_GET_DATE_EVENTS_TASKS: "CrmEventStore/A_GET_DATE_EVENTS_TASKS",
      A_UPDATE_EVENT: "CrmEventStore/A_UPDATE_EVENT",
      A_SAVE_NO_ANSWER_EVENT_MOTIVE:
        "CrmGlobalStore/A_SAVE_NO_ANSWER_EVENT_MOTIVE",
    }),
    async noAnswered() {
      const data = await this.A_SAVE_NO_ANSWER_EVENT_MOTIVE({
        idEvent: this.event.id,
        createdBy: this.currentUser.user_id,
      });
      if (data.status === 200) {
        this.closeModal();
      }
    },
    async getOwners() {
      try {
        const roles = [2, 4].includes(this.moduleId)
          ? "[1,2,5]"
          : "[1,2,3,5,17]";
        await this.A_GET_OWNERS({
          modul: this.moduleId,
          body: { roles, type: "1" },
        });
        this.sellers = this.G_OWNERS;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    setDataBlank(key) {
      this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`] =
        Object.assign({}, this[key]);
    },
    resetData(key) {
      const object = this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`];
      for (let subkey in object) {
        this[key][subkey] = object[subkey];
      }
    },
    onToggleEdit() {
      this.isDisabled = !this.isDisabled;
      if (this.isDisabled) {
        this.resetData("event");
        // this.$refs.refFormObserver.reset();
      }
    },
    async onSubmit() {
      try {
        this.isLoading = true;
        const responseFirst = await this.A_GET_DATE_EVENTS_TASKS({
          idLead: this.event.lead_id,
          id: this.event.id,
        });
        if (this.isResponseSuccess(responseFirst)) {
          const dateFormat = this.$moment(
            `${this.event.date} ${this.event.from}`
          ).format("YYYY-MM-DD HH:mm:ss");
          let repeat = 0;
          responseFirst.data.forEach((el) => {
            if (el.dates == dateFormat) repeat++;
          });
          if (repeat === 0) {
            const body = {
              ...this.event,
              user_id: this.event.user_id.value,
              id: this.event.id,
              title: this.event.title,
              date: this.event.date,
              from: this.event.from,
              to: this.event.to,
              host: this.event.user_id.value,
              location: this.event.location,
              description: this.event.description,
              seller: this.event.user_id.label,
              current_user: this.currentUser.user_id,
              month: this.getCurrentMonth(),
              module: this.moduleId,
            };
            const response = await this.A_UPDATE_EVENT(body);
            if (this.isResponseSuccess(response)) {
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Appointment rescheduled successfully"
              );
              this.setDataBlank("event");
              this.onToggleEdit();
              this.isLoading = false;
              this.$emit("updated", body);
            } else {
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                "Something went wrong." + response.message
              );
            }
          } else {
            this.showToast(
              "warning",
              "top-right",
              "Warning!",
              "AlertTriangleIcon",
              "At this time and date, you already have an appointment or a task"
            );
          }
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            "Something went wrong." + responseFirst.message
          );
        }
        this.isLoading = false;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      } finally {
        this.$emit("updated");
      }
    },
    async closeModal(data) {
      try {
        this.addPreloader();

        let params = {
          id: data.id_event,
          status: data.lead_pending_appointment,
        };
        const { status } = await appointmentsService.updatePendingAppointment(
          params
        );
        if (status == 200) {
          this.$bvModal.hide("modal-sale-made");
          this.$emit("closeModal");
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    onDeleteEvent() {
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            this.isLoading = true;
            const month = this.getCurrentMonth();
            const response = await this.A_DELETE_EVENT({
              id: this.event.id,
              month,
            });
            if (this.isResponseSuccess(response)) {
              this.showToast(
                "success",
                "top-right",
                "Deleted!",
                "CheckIcon",
                "Your file has been deleted."
              );
              this.$bvModal.hide("modal-event-edit");
            } else {
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                "Something went wrong." + response.message
              );
            }
            this.isLoading = false;
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
          );
        });
    },
    onAttend() {
      if (this.attendBySeller) return;
      if (this.moduleId === 2 || this.moduleId === 26) {
        this.attend_id = this.event.id;
        this.$bvModal.show("modal-sale-made");
      } else if (
        this.moduleId === 5 ||
        this.moduleId === 7 ||
        this.moduleId === 6 ||
        this.moduleId === 14 ||
        this.moduleId === 10 ||
        this.moduleId === 20 ||
        this.moduleId === 8 ||
        this.moduleId === 22 ||
        this.moduleId === 11 ||
        this.moduleId === 3 ||
        this.moduleId === 0 ||
        this.moduleId === 18
      ) {
        this.attend_id = this.event.id;
        this.$emit("modal-add-service", this.attend_id, this.event);
      } else {
        /* *INTEGRATE* resources\js\components\modal\ModaEventEdit.vue - method: attendOtheModule */
        /* *INTEGRATE* resources\js\components\lead\showlead\ShowLead.vue - method: attendOtherModule */
      }
    },
  },
  directives: {
    Ripple,
  },
};
</script>
