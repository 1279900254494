

export default {
  path: "commission-status",
  name: "ce-commission-status",
  component: () => import(/* webpackChunkName: "CEDCustomerServiceCommissionStatus" */ "@/views/ce-digital/sub-modules/customer-service/views/user-commission/user-commission.vue"),
  meta: {
    childModule: 6,
    programParent: 3,
    program: 22,
    permittedRoles: [1, 2, 17],
    pageTitle: "Commission Status",
    breadcrumb: [
      {
        text: "Table",
      },
    ],
  },
};
