export default {
    path: "help-desk",
    name: "helpdesk-logistic",
    component: () =>
        import(/* webpackChunkName: "LogisticHelpdesk" */ "./Helpdesk.vue"),
    meta: {
        pageTitle: "Help Desk",
        breadcrumb: [
            {
                text: "Help Desk",
                active: true,
            },
        ],
    },
};
