export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "CR",
    model: null,
    options: [
      { id: "0", label: "ALL" },
      { id: "2", label: "YES" },
      { id: "1", label: "NO" },
    ],
    reduce: "id",
    seletText: "label",
    cols: 4,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Source Name",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 8,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Seller",
    model: null,
    options: [],
    reduce: "id",
    selectText: "user_name",
    cols: 12,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status Lead",
    model: null,
    options: [
      { label: "Contact in Future", id: 2 },
      { label: "Not Contacted", id: 5 },
      { label: "Pending", id: 6 },
      { label: "Inactive", id: 8 },
    ],
    reduce: "id",
    seletText: "label",
    cols: 12,
  },

];
