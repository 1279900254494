import paymentsRoute from "@/views/court-info/views/payments/payments.router";
import fileManagerRoute from "@/views/court-info/views/file-manager/file-manager.router";
import helpdeskRoute from "@/views/court-info/views/helpdesk/helpdesk.route";
import leadRoute from "@/views/court-info/views/leads/leads.router";
import clientsRoute from "@/views/court-info/views/clients/clients.router";
import saleMadeRoute from "@/views/court-info/views/sales-made/sales-made.router";
import reportsRoute from "@/views/court-info/views/reports/reports.router";
import calendarRoute from "@/views/court-info/views/calendar/calendar.router";
import loansRoute from "@/views/court-info/views/loans/loans.router";
import schedulesRoute from "@/views/court-info/views/schedules/schedules.router";
import chargeBackRouter from "@/views/court-info/views/charge-back/charge-back.router";
import courtInfoRoute from "@/views/court-info/views/zero-payment/router/index";
import ProductRequestRoute from "@/views/court-info/views/request/request.router.js";
import OtherPaymentsRouter from "@/views/court-info/views/other-payments/other-payments.router.js";
import CourtInfoAttendanceRouter from "@/views/court-info/views/attendance/court-info-attendance.router";
import EmployeeClaimsRouter from "@/views/court-info/views/employee-claims/router/employee-claims.router";
import CourtInfoMain from "@/views/court-info/views/CourtInfoMain.vue";
import ClaimsRoute from "@/views/court-info/views/claims/claims.router.js";
//import CourtInfoProjectsRouter from "@/views/court-info/views/projects/court-info-projects.router";
import CallLogRouter from "@/views/court-info/views/ring-central/call-log/call-log.router.js";
import MOFRouter from "@/views/court-info/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/court-info/views/recruitment-process/court-info-recruitment_process.router";
import RequestNcr from "@/views/court-info/views/request-ncr/request-ncr.router";
import DashboardCalls from "@/views/court-info/views/ring-central/dashboard-calls/dashboard.router.js";
import DirectRouter from "@/views/court-info/views/dispute/direct/router/direct.router";
import LettersRouter from "@/views/court-info/views/dispute/letters/router/letters.router";

import CustomerTicketsRouter from "@/views/court-info/views/customer-tickets/court-info-customer-tickets.router";

import RequestWorkPlanRouter from "@/views/court-info/views/request-workplan/request-ncr.router.js";
const routes = [
  {
    path: "/claimdepartment",
    redirect: { name: "claimdepartment-leads" },
    meta: {
      module: 10,
      program: 6,
    },
    component: CourtInfoMain,
    children: [
      ...leadRoute,
      ...clientsRoute,
      ...saleMadeRoute,
      ...paymentsRoute,
      ...OtherPaymentsRouter,
      ...reportsRoute,
      fileManagerRoute,
      helpdeskRoute,
      calendarRoute,
      loansRoute,
      ...schedulesRoute,
      ...chargeBackRouter,
      ...courtInfoRoute,
      ...ProductRequestRoute,
      ...CourtInfoAttendanceRouter,
      ...EmployeeClaimsRouter,
      ...ClaimsRoute,
      //...CourtInfoProjectsRouter,
      ...CallLogRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...RequestNcr,
      ...DashboardCalls,
      ...CustomerTicketsRouter,
      ...RequestWorkPlanRouter,
      ...DirectRouter,
      ...LettersRouter,
    ],
  },
];

export default routes;
