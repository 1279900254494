export default {
  path: "reports",
  name: "crm-reports",
  component: () => import(/* webpackChunkName: "CrmReports" */ "@/views/crm/views/reports/Reports.vue"),
  redirect: { name: "crm-reports-daily" },
  children: [
    {
      path: "daily",
      component: () =>
        import(/* webpackChunkName: "CrmReportsDaily" */ "@/views/commons/components/roi-reports/ReportsCrmDaily.vue"),
      name: "crm-reports-daily",
      meta: {
        permittedRoles: [1, 2, 17],
        pageTitle: "Reports",
        route: "crm/leads",
        breadcrumb: [
          {
            text: "Reports",
          },
        ],
      },
    },

    {
      path: "general",
      component: () =>
        import(/* webpackChunkName: "CrmReportsCrmGeneral" */ "@/views/commons/components/roi-reports/ReportsCrmGeneral.vue"),
      name: "crm-reports-general",
      meta: {
        permittedRoles: [1, 2, 17],
        pageTitle: "Reports",
        route: "crm/leads",
        breadcrumb: [
          {
            text: "Reports",
          },
        ],
      },
    },
  ],
  meta: {
    permittedRoles: [1, 2, 17],
    pageTitle: "Reports",
    route: "crm/leads",
    breadcrumb: [
      {
        text: "Reports",
      },
    ],
  },
};
