<template>
  <b-modal v-model="showModal" size="lg" header-bg-variant="transparent" centered hide-footer @hidden="closeModal">
    <template #modal-title>
      <div class="d-flex justify-content-center align-items-center text-uppercase" style="padding: 10px 10px 5px 10px"
        :class="isDarkSkin ? 'text-light' : 'text-dark'">
        <feather-icon icon="ClockIcon" size="15" class="mr-1" />
        <span class="font-weight-bold">Meetings to ticket <b v-if="ticketCustomer.code">{{ `#${ticketCustomer.code}`
        }}</b> of client <b>{{ ticketCustomer.name_client }}</b></span>
      </div>
    </template>
    <b-container fluid class="pl-2 pr-2 pb-1">
      <b-row>
        <b-col cols="12">
          <body-meeting-ticket :ticket-customer="ticketCustomer" @closeModal="closeModal" @success="success" />
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import CustomerTicketsServices from '@/views/commons/components/customer-tickets/services/customer-tickets.service';
import BodyMeetingTicket from '@/views/commons/components/customer-tickets/components/modal/meetings/BodyMeetingTicket.vue';

export default {
  components: {
    BodyMeetingTicket,
  },
  props: {
    ticketCustomer: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      currentMonth: parseInt(moment().format('MM'), 10),
      currentYear: parseInt(moment().format('YYYY'), 10),
      today: parseInt(moment().format('DD'), 10),
      todayDate: moment().format('YYYY-MM-DD'),
      month: parseInt(moment().format('MM'), 10),
      year: parseInt(moment().format('YYYY'), 10),
      day: null,
      hoursAvailables: [],
      loadHours: false,
      hoursMeetings: [],
      bgColor: {
        0: 'bg-primary',
        1: 'bg-warning',
        2: 'bg-info',
        3: 'bg-success',
        4: 'bg-success',
        5: 'bg-secondary',
        6: 'bg-danger',
      },
      hoursSelectedDay: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    monthAndYear() {
      return moment(`${this.month}/${this.year}`, 'MM/YYYY').format('MMMM YYYY');
    },
    spaceEmptyStart() {
      return parseInt(
        moment(`${this.year}-${this.month}`, 'YYYY-MM').startOf('month').day(),
        10,
      );
    },
    haveHoursSelected() {
      return this.hoursSelectedDay.filter(item => item.selected && !item.id).length;
    },
  },
  watch: {
    // async day(newVal) {
    //   this.hoursSelectedDay = [];
    //   if (newVal) {
    //     await this.getMeetingsHoursTicketCustomer();
    //   }
    // },
  },
  async mounted() {
    this.showModal = true;
    this.day = this.today;
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    success() {
      this.$emit('success');
    },
    async getMeetingsHoursCEO() {
      try {
        this.loadHours = true;
        const params = {
          month: this.month,
          year: this.year,
        };
        const { data } = await CustomerTicketsServices.getMeetingsHoursCeo(params);
        if (!data.data.length) return;

        this.hoursAvailables = data.data.map(item => ({
          ...item,
          day: parseInt(moment(item.meeting_date).format('DD'), 10),
        }));
        this.loadHours = false;
      } catch (error) {
        this.loadHours = false;
        console.log('Ocurred error in getMeetingsHoursCEO: ', error);
      }
    },
    toggleSelectedHours(index) {
      this.hoursSelectedDay.forEach((item, i) => {
        if (i === index) {
          item.selected = !item.selected;
        } else {
          item.selected = !!item.id;
        }
        if (!item.status) {
          item.status = item.selected ? 1 : 0;
        }
      });
    },
    async saveMeeting() {
      try {
        // validations
        if (!this.haveHoursSelected) return;
        const { isConfirmed } = await this.showConfirmSwal('VERY IMPORTANT !', 'This action will delete all previous meetings that are pending.');
        if (!isConfirmed) return;

        this.addPreloader();
        let hoursSelected = [];
        // only new hours selected
        hoursSelected = this.hoursSelectedDay.filter(item => item.selected && !item.id);

        // params to save
        const params = {
          ticketCustomerId: this.ticketCustomer.id,
          meetings: hoursSelected,
          createdBy: this.currentUser.user_id,
        };

        const { status } = await CustomerTicketsServices.insertMeetingTicket(params);
        if (status === 200) {
          this.showSuccessSwal();
          await this.getMeetingsHoursTicketCustomer();
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal();
        this.removePreloader();
        console.log('error', error);
      }
    },
    async getMeetingsHoursTicketCustomer() {
      this.day = !this.day ? this.today : this.day;
      try {
        // WE FIND hours selected of CEO of according a day
        const data = this.hoursAvailables.find(item => item.day === this.day);
        if (!data?.range_hours) {
          this.day = null;
          this.hoursSelectedDay = [];
          return;
        }

        // GET HOURS OF SPECIFY DAY OF TICKET CUSTOMER
        const params = {
          ticketCustomerId: this.ticketCustomer.id,
          meetingDate: `${this.year}-${this.month}-${this.day}`,
          createdBy: this.currentUser.user_id,
        };
        // RENAME
        const { data: meeting } = await CustomerTicketsServices.getMeetingTicketCustomer(params);
        if (!meeting) return;

        // ordering hours and verifying if there are meetings
        this.hoursSelectedDay = JSON.parse(data.range_hours).map(item => {
          const rangeStart = item.range_start.split('.')[0];
          const rangeEnd = item.range_end.split('.')[0];

          const meetingFind = meeting.find(element => element.meeting_start === rangeStart && element.meeting_end === rangeEnd);

          return {
            meeting_date: data.meeting_date,
            range_start: rangeStart,
            range_end: rangeEnd,
            selected: !!meetingFind,
            status: meetingFind?.meeting_status_id || 0,
            status_description: meetingFind?.meeting_status_description || '',
            id: meetingFind?.id || null,
            reprogramming: false,
          };
        }).sort((a, b) => (a.range_start < b.range_start ? -1 : 1));
      } catch (error) {
        this.showErrorSwal();
        console.log('error to getMeetingsHoursTicketCustomer: ', error);
      }
    },
    async cancelMeeting(meetingId) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        const params = {
          meetingId,
          deletedBy: this.currentUser.user_id,
        };
        const { status } = await CustomerTicketsServices.cancelMeetingTicketCustomer(params);
        if (status === 200) {
          this.showSuccessSwal();
          await this.getMeetingsHoursTicketCustomer();
        }
        this.removePreloader();
      } catch (error) {
        this.showErrorSwal();
        console.log('error to cancelMeeting: ', error);
      }
    },
  },
};
</script>
<style scoped>
* {
  --custom-blue: #2591E7;
}

.grid-calendar {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0px;
  margin: 0px auto;
  position: relative;
}

.grid-calendar .name-day,
.grid-calendar .number-day,
.grid-calendar .number-day-dark-skin {
  text-align: center;
  font-size: 10px;
  padding: 10px 0px;
  /* border-bottom: 1px solid #ccc; */
  text-transform: uppercase;
  transition: 0.3s ease-in-out all;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.grid-calendar .name-day {
  font-weight: 600;
}

.grid-calendar .number-day,
.grid-calendar .number-day-dark-skin {
  cursor: pointer;
  margin: 2px;
}

.grid-calendar .number-day:hover,
.grid-calendar .number-day-dark-skin:hover {
  background-color: var(--custom-blue);
  /* border-radius: 5px; */
}

/*
.grid-calendar .number-day span,
.grid-calendar .number-day-dark-skin span {
  transition: 0.3s ease-in-out all;
} */

.grid-calendar .number-day:hover span,
.grid-calendar .number-day-dark-skin:hover span {
  cursor: pointer;
  font-size: 15px;
  transform: traslateY(-5px);
  color: white;
}

/* .number-day:nth-child(7n+1),
.number-day:nth-child(7n+3),
.number-day:nth-child(7n+5),
.number-day:nth-child(7n+7)
{
  background-color: #F8F9F9;
}
.number-day-dark-skin:nth-child(7n+1),
.number-day-dark-skin:nth-child(7n+3),
.number-day-dark-skin:nth-child(7n+5),
.number-day-dark-skin:nth-child(7n+7){
  background-color: #f8f9f917;
} */

.day-selected {
  background-color: var(--custom-blue) !important;
  color: white !important;
}

.day-selected span {
  font-size: 15px;
}

.day-selected .indicator {
  background-color: white !important;
}

.indicator,
.indicator-dark {
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  bottom: 3px;
  transition: 0.3s ease-in-out all;
}

.indicator {
  background-color: currentColor;
}

.grid-calendar .number-day:hover .indicator,
.grid-calendar .number-day-dark-skin:hover .indicator {
  background-color: white;
}

.rotate-me {
  transition: 0.3s ease-in-out all;
}

.rotate-me:hover {
  transform: rotate(180deg);
}

/*---ANIMATIONs----*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 0s !important;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.slide-fade-item {
  transition: transform 0.5s;
}
</style>
