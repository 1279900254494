import { sumTag } from '@/@core/utils/utils.js';
import customerTickets from '@/views/ce-digital/sub-modules/sales/views/customer-tickets/sales-customer-tickets.navigation';
import clients from '@/views/ce-digital/sub-modules/sales/views/clients/navigation/clients.navigation';

export default {
  title: 'Clients',
  icon: 'UsersIcon',
  children: [clients, customerTickets],
  tag: sumTag([clients, customerTickets]),
};
