<template>
  <b-card class="card-lead-notes" footer-class="border-0">
    <template>
      <b-card-title>
        <h4
          class="font-weight-bolder pb-1 border-bottom-secondary border-2 title-notes"
        >
          Notes
        </h4>
      </b-card-title>
    </template>

    <div class="tabs-container d-flex justify-content-between mb-1">
      <b-nav card-header pills class="m-0">
        <b-nav-item
          v-for="tab in tabs"
          :key="tab.id"
          exact
          :link-classes="['px-3']"
          :active="tab.id === selectedTab"
          @click="handleChangeTab(tab)"
        >
          <div class="d-flex align-items-center justify-content-center">
            <feather-icon v-if="tab.id == 1" icon="PhoneIcon" size="15" />
            <span>{{ tab.name }}</span>
          </div>

          <span class="ml-2" v-if="tab.counter > 0">
            <feather-icon
              icon
              :badge="tab.counter"
              :badge-classes="'badge-danger'"
            />
          </span>
        </b-nav-item>
      </b-nav>

      <b-button-group>
        <!-- <b-button
          variant="transparent"
          @click="changeMonth(-1)"
          class="custom-filter-date"
        >
          <span>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </span>
        </b-button>
        <b-button
          variant="transparent"
          class="custom-filter-date pointer-events-none font-weight-bolder"
        >
          <span>{{ textMonth }}</span>
        </b-button>
        <b-button
          variant="transparent"
          @click="changeMonth(1)"
          class="custom-filter-date mr-1"
        >
          <span>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </span>
        </b-button> -->
        <b-button
          variant="transparent"
          @click="changeDate(-1)"
          :disabled="minYear == year"
          class="custom-filter-date"
        >
          <span>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </span>
        </b-button>
        <b-button
          variant="transparent"
          class="custom-filter-date pointer-events-none font-weight-bolder"
        >
          <span>{{ year }}</span>
        </b-button>
        <b-button
          variant="transparent"
          @click="changeDate(1)"
          :disabled="maxYear == year"
          class="custom-filter-date"
        >
          <span>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </span>
        </b-button>
      </b-button-group>
    </div>

    <div class="notes-container diego-scrollbar">
      <template v-if="leadNotes.length > 0">
        <div
          class="note-item"
          v-for="(note, index) in leadNotes"
          :key="index"
          :class="{
            expanded: note.isExpanded,
            important: note.important == 1,
          }"
        >
          <span
            v-if="note.sentiment != null"
            class="badge-sentiment"
            :style="{
              backgroundColor:
                note.sentiment === 'positive'
                  ? '#00d25b'
                  : note.sentiment === 'negative'
                  ? '#f10000'
                  : note.sentiment === 'neutral'
                  ? '#0090e7'
                  : '#6e3ccd',
            }"
            >Sentiment: {{ note.sentiment }}</span
          >

          <div class="d-flex align-items-center justify-content-end">
            <span class="note-created-data">
              <b-avatar
                class="d-inline-block mr-1"
                variant="info"
                :src="note.image_thumb"
              ></b-avatar>
              {{ note.user }} | {{ note.created_at | myGlobalWithHour }}</span
            >
            <b-dropdown
              id="dropdown-1"
              toggle-class="text-decoration-none"
              no-caret
              variant="transparent"
              :menu-class="'custom-dropdown-menu'"
            >
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" />
              </template>
              <b-dropdown-item href="#" @click="importantNote(note)"
                >Important</b-dropdown-item
              >
              <b-dropdown-item
                v-if="
                  (isCeo || isChief) &&
                  note.transcription_status != null &&
                  selectedTab == 1
                "
                href="#"
                @click="sendToTranscribe(note)"
                :disabled="note.transcription_status == 'IN_PROGRESS'"
                >Regenerate Summary</b-dropdown-item
              >
              <b-dropdown-item
                href="#"
                v-if="note.versions > 0 && selectedTab == 1"
                @click="showAllNotesVersionModal(note)"
                >Note Versions</b-dropdown-item
              >
            </b-dropdown>
          </div>

          <div class="text-container">
            <change-sms
              v-if="note.text != null"
              class="pb-1 mb-1"
              :SmsData="note.text"
              :TextLength="200"
              @isExpanded="note.isExpanded = !note.isExpanded"
            />
          </div>
          <div
            v-if="note.record_url"
            class="border border-secondary rounded-lg my-1 mx-2"
          ></div>

          <div
            v-if="note.record_url"
            class="call-container d-flex align-items-center justify-content-between px-2"
          >
            <div class="call-container-left">
              <span>
                <feather-icon icon="PhoneIcon" class="mr-1" /> Call Record</span
              >
            </div>
            <div class="call-container-right">
              <new-wave-surfer-player
                :url="note.record_url"
                :options="audioOptions"
                :wave-surfer-id="`wave-surfer-${index}${note.id}`"
                :show-duration="true"
                :elapsed-time-width="95"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="w-100 d-flex justify-content-center align-items-center position-relative"
        >
          <img
            :src="require('@/assets/images/icons/no-notes.svg')"
            width="15%"
            height="100%"
            class="custom-empty-img"
          />
          <span class="custom-message">No Notes</span>
        </div>
      </template>
    </div>

    <template #footer>
      <b-row class="mt-2">
        <b-col class="d-flex align-items-center justify-content-end">
          <!-- <b-button @click="openHistoryModal" class="mr-1 notes-history-button">
            <feather-icon icon="ListIcon" />
            Notes History
          </b-button> -->
          <b-button
            class="new-note-button"
            @click="openAddNewNote"
            v-if="!onlyRead"
          >
            <feather-icon icon="PlusIcon" />
            New Note
          </b-button>
        </b-col>
      </b-row>
    </template>

    <history-notes-modal
      v-if="historyModal"
      :lead="lead"
      @close="closeHistoryModal"
      @newImportant="setNewImportantNote"
    />
    <add-notes-modal
      v-if="addNoteModal"
      :lead="lead"
      :first-note="dontHaveNote"
      @close="closeAddNewNote"
      @newImportant="setNewImportantNote"
    />
    <notes-version-modal
      v-if="showNotesVersionModal"
      :note-id="noteId"
      :reload="reload"
      @close="showNotesVersionModal = false"
    />
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import HistoryNotesModal from "@/views/crm/views/Lead/lead-module/dashboard/modal/HistoryNotesModal.vue";
import AddNotesModal from "@/views/crm/views/Lead/lead-module/dashboard/modal/AddNotesModal.vue";
import moment from "moment";
import NotesService from "@/views/commons/components/first-notes/services/notes.service.js";
import NewWaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/NewWaveSurferPlayer.vue";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
import ServiceNotes from "@/views/crm/services/notes/index.js";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
import NotesVersionModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/NotesVersionModal.vue";

export default {
  components: {
    AddNotesModal,
    HistoryNotesModal,
    NewWaveSurferPlayer,
    ChangeSms,
    NotesVersionModal,
  },
  props: {
    onlyRead: {
      type: Boolean,
      required: true,
    },
    lead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showNotesVersionModal: false,
      noteId: null,
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 2,
        backend: "MediaElement",
        minPxPerSec: 0,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      leadNotes: [],
      note: {
        id: "",
        text: "",
        created: "",
        user_id: "",
        created_by: "",
      },
      textAreaDisabled: true,
      historyModal: false,
      addNoteModal: false,
      tabs: [
        {
          id: 1,
          name: "Calls",
          key: "calls",
        },
        {
          id: 2,
          name: "Metamedia",
          key: "metamedia",
        },
      ],
      selectedTab: 1,
      minYear: 2015,
      maxYear: parseInt(moment().format("YYYY")),
      year: parseInt(moment().format("YYYY")),
      month: parseInt(moment().format("M")),
      textMonth: moment().format("MMMM"),
      reload: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    dontHaveNote() {
      return this.note.id === "";
    },
    isResponsible() {
      return !(
        this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
          .is_responsible === this.currentUser.user_id ||
        this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
          .is_responsible === null
      );
    },
  },
  async created() {
    this.bindUpdateManualNotes();

    if (this.lead.notes) {
      [this.note] = this.lead.notes;
      this.note.created_by_name = this.note.created_by;
      this.note.created_at = this.note.created;
    }
    if (this.dontHaveNote) this.textAreaDisabled = false;
    await this.getLeadNotes();
  },
  methods: {
    async showAllNotesVersionModal(note) {
      try {
        this.showNotesVersionModal = true;
        this.noteId = note.note_id.toString();
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async bindUpdateManualNotes() {
      try {
        window.socket.bind("update-manual-notes", async ({ data }) => {
          const found = this.leadNotes.find(
            (note) => note.note_id == data.note_id
          );

          if (found) {
            let index = this.leadNotes.findIndex(
              (note) => note.note_id == data.note_id
            );

            if (index !== -1) {
              let res = await this.getLeadNoteById(data.note_id);

              if (res.data.length == 0) return;

              let new_data = res.data[0];

              this.$set(this.leadNotes, index, new_data);
              this.reload = this.reload + 1;
            }
          }
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getLeadNoteById(id) {
      try {
        const response = await ServiceNotes.getLeadNotesById({ note_id: id });
        return response;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async importantNote(note) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        let params = {
          id: note.note_id,
          lead_id: note.lead_id,
          user_id: parseInt(note.created_by, 10),
        };
        if (this.selectedTab != 1) {
          params.reply_id = note.replie_id;
        }
        const { status } = await ServiceNotes.postMakeImportantNote(params);
        if (status === 200) {
          this.setNewImportantNote();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async sendToTranscribe(note) {
      try {
        const { isConfirmed, value } = await this.showSimpleInputSwal({
          input: "text",
          title: "Why do you want to regenerate the summary?",
          inputPlaceholder: "Ex: The summary is not correct",
        });

        if (!isConfirmed) return;

        this.addPreloader();

        const { data } = await ClientsOptionsServices.sendToTranscribe({
          note_id: note.note_id,
          reason: value,
          created_by: this.currentUser.user_id,
        });

        this.showTimedToast({
          title: data.message,
          timeout: 5000,
        });
      } catch (e) {
        this.showErrorSwal(e.response.data.message);
      } finally {
        this.removePreloader();
      }
    },

    changeMonth(value) {
      const newValue = parseInt(this.month) + value;
      if (newValue < 1 || newValue > 12) return;
      this.month = newValue;
      this.textMonth = moment()
        .month(newValue - 1)
        .format("MMMM");
      this.getLeadNotes();
    },

    async getLeadNotes() {
      try {
        this.addPreloader();
        const { data } = await NotesService.getLeadNotes({
          lead_id: this.lead.id,
          tab: this.selectedTab,
          year: this.year,
          month: this.month,
        });

        // if (data.length > 0) {
        //   // move the items that has important propertie equal 1 at the start of the array
        //   data.sort((a, b) => (a.important === 1 ? -1 : 1));
        // }
        this.leadNotes = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    changeDate(value) {
      const newValue = this.year + value;
      if (newValue < this.minYear || newValue > this.maxYear) return;
      this.year = newValue;
      this.getLeadNotes();
    },
    enableTextArea() {
      this.textAreaDisabled = false;
    },
    disableTextArea() {
      this.textAreaDisabled = true;
    },
    clickXIcon() {
      if (this.dontHaveNote) this.note.text = "";
      else this.disableTextArea();
    },
    openAddNewNote() {
      this.addNoteModal = true;
    },
    closeAddNewNote() {
      this.addNoteModal = false;
    },
    openHistoryModal() {
      this.historyModal = true;
    },
    closeHistoryModal() {
      this.historyModal = false;
    },
    async clickCheckIcon() {
      const resolve = await this.$refs.form.validate();
      if (resolve) {
        const result = await this.showConfirmSwal("fino");
        if (result.value) {
          if (this.dontHaveNote) {
            const response = await amgApi.post("/note/note/update-notes", {
              created_at: "",
              created_by: "",
              created_by_name: "",
              id: "",
              lead_id: this.lead.id,
              text: this.note.text,
              user_id: this.currentUser.user_id,
            });
            if (response.status === 200) {
              [this.note] = response.data;
              this.note.user_id = this.note.created_by;
              this.disableTextArea();
            }
          } else {
            const response = await amgApi.post("/note/note/update-notes", {
              created_at: this.note.created_at,
              created_by: this.note.user_id,
              created_by_name: this.note.created_by_name,
              id: this.note.id,
              important: 1,
              lead_id: this.lead.id,
              text: this.note.text,
            });
            if (response.status === 200) {
              [this.note] = response.data;
              this.note.user_id = this.note.created_by;
              this.disableTextArea();
            }
          }
        }
      }
    },
    setNewImportantNote(newImportant) {
      this.getLeadNotes();
      // this.note = newImportant;
      // this.note.user_id = this.note.created_by;
      // this.onlyRead = true;
    },
    handleChangeTab(tab) {
      this.selectedTab = tab.id;
      this.getLeadNotes();
    },
  },
};
</script>

<style>
.memo-class {
  height: 150px;
  width: 100%;
  border-radius: 0.357rem;
  padding: 0.438rem 1rem;
  overflow: auto;
  white-space: pre-wrap;
}
.custom-height {
  height: 60%;
}
.custom-empty-img {
  opacity: 0.3;
}
.custom-message {
  font-size: 23px;
  font-weight: bolder;
  position: absolute;
}
</style>

<style lang="scss" scoped>
.card-lead-notes {
  .title-notes {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #111113;
  }
  .tabs-container {
    background: #fff !important;
    padding: 1rem;
    border-bottom: 5px solid #0090e7;
    border-bottom-left-radius: 0.357rem;
    border-bottom-right-radius: 0.357rem;

    .nav-pills .nav-link.active {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      background: #0090e7 !important;
      color: #fff !important;
    }
    .nav-pills .nav-link {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      background: #efefef !important;
      color: #5e5873 !important;
    }
    .custom-filter-date {
      font-family: "Montserrat", sans-serif;
      font-weight: 600;
      background-color: #efefef;
      color: #5e5873;
      text-decoration: underline;
      border: none !important;
    }
  }

  .new-note-button {
    background-color: #3f7afa !important;
    color: #111113 !important;
    border-color: #3f7afa !important;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
  .notes-history-button {
    background-color: #3f7afa !important;
    color: #111113 !important;
    border-color: #3f7afa !important;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
  .note-item {
    position: relative;
    background: #efefef;
    height: 60%;
    // overflow: hidden;
    transition: height 0.3s ease;
    margin-bottom: 1rem;
    padding-right: 10px;
    margin-right: 10px;
    border-radius: 5px;
  }

  .note-item.expanded {
    height: auto;
  }

  .note-created-data {
    font-size: 1rem;
    color: #111113;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    padding: 1rem 2rem;
  }
  .badge-sentiment {
    position: absolute !important;
    font-size: 1rem;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    padding: 0.7rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-transform: capitalize;
  }
  .text-container {
    padding: 0rem 2rem;
  }

  .call-container {
    // padding: 1rem 2rem 0rem 2rem;
    margin-bottom: 1rem;
  }
  .notes-container {
    // min-height: 30% !important;
    min-height: auto !important;
    max-height: 85% !important;
    overflow-y: auto;
  }

  .call-container-left {
    margin-left: 2rem;
    font-size: 1rem;
    color: #111113;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
  .important {
    border: 1px solid #ff9f43 !important;
  }
  .custom-dropdown-menu {
    position: absolute !important;
    top: auto !important;
    bottom: 100%;
    left: 0;
    transform: translateY(-5px);
    z-index: 1000; /* Ensure it appears above other elements */
  }
}
.dark-layout {
  .card-lead-notes {
    .title-notes {
      color: #fafafa;
    }
    .tabs-container {
      background: #111113 !important;
    }
    .nav-pills .nav-link.active {
      background: #3a72ea !important;
      color: #0e1932 !important;
    }
    .nav-pills .nav-link {
      background: #0e1932 !important;
      color: #3f7afa !important;
    }
    .custom-filter-date {
      background-color: #131323;
      color: #b4b4b4;
    }
    .note-item {
      background: #111113;
    }
    .note-created-data {
      color: #ffffff;
    }
    .call-container-left {
      color: #ffffff;
    }
  }
}
</style>