// new
import router from "@/router";
import store from "@/store";
import Vue from "vue";
import { amgApi } from "@/service/axios";
import CallRoundsService from "@/views/commons/call-rounds/service/call-rounds.service";

const notificationActCallRound = () => {
  window.socket.bind("callround-adv-to-operator", async (data) => {
    let temps = false;
    let session_id = store.state.auth.currentUser.user_id;
    for (let index = 0; index < data.to_operators.length; index++) {
      if (data.to_operators[index] == session_id) {
        temps = true;
      }
    }
    if (temps) {
      let params = {
        id_user: store.state.auth.currentUser.user_id,
        id_role: store.state.auth.currentUser.role_id,
        id_program: 2,
      };
      let counterData = await CallRoundsService.contCallsRoundsUsers(params);
      if (counterData.status==200) {
        let payload = {
          routeName: "call-rounds-boostcredit",
          tag: counterData.data[0].count,
        };
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
      }

      const htmlSwal =
        '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
        '<div><h2 style="font-weight: 600;">NEW CALL ROUND</h2></div>' +
        '<div style="text-align: left;margin-left: 50px;">' +
        '<div style="font-weight: bolder;">CLIENT: ' +
        data.lead_name +
        " </div>" +
        '<div style="font-weight: bolder;">ADVISOR: ' +
        data.advisor_name +
        " </div>" +
        "<div>TASK: " +
        data.task_name +
        " </div>" +
        "</div>";
      const res = await Vue.swal.fire({
        html: htmlSwal,
        showConfirmButton: true,
        confirmButtonText: "OK",
      });
      if (res) {
        //data.to_id = session_id;
        let response = await amgApi.post("commons/close-all-swal", data);
        if (response.status == 200) {
          //
        }
      }
      if (data.module_id == 6) {
        // window.open('/creditexperts/callrounds', '_blank');
      } else {
        window.open('/boostcredit/call-rounds');
      }
    }
  });
};
export default notificationActCallRound;
