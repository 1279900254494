<template>
  <div>
    <filter-slot
      :top-paginate="false"
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="reloadTable()"
      :paginate-left="false"
      :filters-selector-class="'filters-selector'"
      :buttons-theme="'custom-buttons-theme'"
      :left-col-value="6"
      :right-col-value="6"
      :square-pagination="true"
      :arrayPagination="[5, 10, 15, 20, 25]"
    >
      <template #buttons-start>
        <b-button
          class="add-payment-button mr-2"
          v-if="!onlyRead"
          variant="primary"
          @click="openChooseLeadTransactionModal()"
        >
          <b-icon icon="plus"></b-icon>
          Add Payment
        </b-button>
      </template>

      <b-table
        slot="table"
        ref="lead-transactions-table"
        class="lead-transactions-table diego-scrollbar"
        :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
        sticky-header="50vh"
        no-provider-filtering
        :busy.sync="isBusy"
        :sort-desc.sync="sortDesc"
        :items="getTransactionsByLead"
        :fields="visibleFields"
        :per-page="paginate.perPage"
        :current-page="paginate.currentPage"
        :sort-by.sync="orderBy"
        show-empty
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(settlement_date)="data">
          <div
            class="justify-content-center custom-space w-100"
            :class="
              isDarkSkin
                ? 'bg-custom-dark'
                : 'bg-white border-top-light border-bottom-light border-left-light'
            "
          >
            <div class="w-auto h-auto d-flex flex-column">
              <span class="w-100 text-center">
                {{ data.item.settlement_date | myGlobalDay }}
              </span>
            </div>
          </div>
        </template>

        <template #cell(transaction_id)="data">
          <div
            class="d-flex justify-content-center w-100 custom-space"
            :class="
              isDarkSkin
                ? 'bg-custom-dark'
                : 'bg-white border-top-light border-bottom-light'
            "
          >
            <span v-if="data.item.transaction_id != null">
              {{ data.item.transaction_id }}
            </span>
            <span v-else> - </span>
          </div>
        </template>

        <template #cell(type_transaction)="data">
          <div
            class="justify-content-center w-100 custom-space"
            :class="
              isDarkSkin
                ? 'bg-custom-dark'
                : 'bg-white border-top-light border-bottom-light'
            "
          >
            <span v-if="data.item.type_transaction != null">
              {{ data.item.type_transaction }}
            </span>
            <span v-else> - </span>
          </div>
        </template>

        <template #cell(amount)="data">
          <div
            class="d-flex justify-content-center w-100 custom-space"
            :class="
              isDarkSkin
                ? 'bg-primary-dark'
                : 'bg-primary-light border-top-light border-bottom-light'
            "
          >
            <strong class="text-purple">
              $ {{ data.item.amount | currencyZero }}
            </strong>
          </div>
        </template>

        <template #cell(user_name)="data">
          <div
            class="d-flex w-100 custom-space"
            style="min-width: 200px !important"
            :class="
              isDarkSkin
                ? 'bg-custom-dark'
                : 'bg-white border-top-light border-bottom-light'
            "
          >
            <img
              :src="
                data.item.user_image
                  ? data.item.user_image
                  : require('@/assets/images/logo/amg_logo.svg')
              "
              class="custom-img mr-50"
            />
            {{ data.item.user_name == "-" ? "UNKNOWN" : data.item.user_name }}
          </div>
        </template>

        <template #cell(status_transaction)="data">
          <div
            class="d-flex justify-content-center w-100 custom-space"
            :class="
              isDarkSkin
                ? 'bg-custom-dark'
                : 'bg-white border-top-light border-bottom-light'
            "
          >
            <b-badge
              :variant="getStatus(data.item.status_transaction)"
              class="text-uppercase rounded-pill"
              style="padding: 8px 12px !important"
            >
              {{ data.item.status_transaction }}</b-badge
            >
          </div>
        </template>

        <template #cell(method_transaction)="data">
          <div
            class="d-flex justify-content-center w-100 custom-space"
            :class="
              isDarkSkin
                ? 'bg-custom-dark'
                : 'bg-white border-top-light border-bottom-light'
            "
          >
            {{ data.item.method_transaction }} ({{ data.item.card_number }})
          </div>
        </template>

        <template #cell(actions)="data">
          <div
            class="d-flex justify-content-center w-100 custom-space"
            :class="
              isDarkSkin
                ? 'bg-custom-dark'
                : 'bg-white border-top-light border-bottom-light'
            "
          >
            <b-button
              size="sm"
              variant="danger"
              v-if="
                canVoid(
                  data.item.type_transaction_id,
                  data.item.void,
                  data.item.w_card,
                  data.item.status_transaction,
                  data.item.pending_void_transactions
                )
              "
              @click="
                voidAuthorize(
                  data.item.transaction_id,
                  data.item.merchant_id,
                  data.item.amount,
                  leadName,
                  data.item.settlement_date,
                  1,
                  data.item.sumref
                )
              "
            >
              <void-icon
                :height="15"
                :width="15"
                style="margin-right: 3px; margin-bottom: 3px"
                color="#FFF"
                darkColor="#FFF"
              />
              Void
            </b-button>

            <b-button
              size="sm"
              variant="primary"
              v-if="
                canRefund(
                  data.item.type_transaction_id,
                  data.item.void,
                  data.item.refund,
                  data.item.w_card,
                  data.item.status_transaction,
                  data.item.pending_void_transactions
                )
              "
              @click="
                voidAuthorize(
                  data.item.transaction_id,
                  data.item.merchant_id,
                  data.item.amount,
                  leadName,
                  data.item.settlement_date,
                  2,
                  data.item.sumref
                )
              "
            >
              <refund-icon
                :height="17"
                :width="17"
                style="margin-right: 3px; margin-bottom: 3px"
                color="#FFF"
                darkColor="#FFF"
              />
              Refund
            </b-button>
            <b-button
              size="sm"
              v-if="
                canSeeVoidRefund(
                  data.item.type_transaction_id,
                  data.item.pending_void_transactions
                )
              "
              @click="openModalVoidRefundInfo(data.item.transaction_id)"
            >
              <feather-icon
                icon="EyeIcon"
                size="15"
                style="margin-right: 3px"
              />
              Show
            </b-button>
          </div>
        </template>

        <template v-if="items" #custom-foot>
          <tr v-if="items.length > 0" class="sticky-data-paginated text-left">
            <td colspan="3"></td>
            <td>
              <span class="footer-title font-size-md">Subtotal</span>
            </td>
            <td class="text-center p-0">
              <span class="footer-amount font-size-md">
                $ {{ subTotalAmount | currencyZero }}
              </span>
            </td>
            <td colspan="10"></td>
          </tr>

          <div v-if="items.length > 0" class="my-05"></div>

          <tr v-if="items.length > 0" class="sticky-data-total text-left">
            <td colspan="3"></td>
            <td>
              <span class="footer-title font-size-md">Total</span>
            </td>
            <td class="text-center p-0">
              <span class="footer-amount font-size-md">
                $ {{ totalAmount | currencyZero }}
              </span>
            </td>
            <td colspan="10"></td>
          </tr>
        </template>
      </b-table>
    </filter-slot>
    <modal-refund
      v-if="modalRefund"
      :modalRefund="modalRefund"
      :dataVoid="dataVoid"
      :global="currentUser"
      @close="closeModalRefund"
      @updateGrid="reloadTable"
    ></modal-refund>
    <modal-void-refund-info
      v-if="modalVoidRefund"
      :modalVoidRefund="modalVoidRefund"
      :idtransaction="transactionId"
      @closeInfo="closeModalVoidRefundInfo"
    ></modal-void-refund-info>

    <LeadChooseTransaction
      v-if="showChooseLeadTransaction"
      @hidden="showChooseLeadTransaction = false"
      :lead-name="leadName"
      :lead-id="leadId"
      @on-transaction-registered="reloadTransactions()"
    />
    <LeadAddTransaction
      :lead-id="leadId"
      :lead-name="leadName"
      v-if="showAddLeadTransaction"
      @hidden="showAddLeadTransaction = false"
      @on-collect="reloadTransactions()"
    />
  </div>
</template>
<script>
import LeadTransactionService from "@/views/commons/components/leads/transactions/lead-transaction.service";
import ModalVoidRefundInfo from "@/views/crm/views/payments/components/ModalVoidRefundInfo.vue";
import ModalRefund from "@/views/crm/views/payments/components/ModalRefund.vue";
import { mapGetters } from "vuex";
import VoidIcon from "@/icons/Void.vue";
import RefundIcon from "@/icons/Refund.vue";
import Filter from "@/views/commons/components/leads/data/lead-transaction.filters";
import LeadAddTransaction from "@/views/commons/components/leads/transactions/LeadAddTransaction.vue";
import LeadChooseTransaction from "@/views/commons/components/leads/transactions/LeadChooseTransaction.vue";

export default {
  components: {
    ModalVoidRefundInfo,
    ModalRefund,
    VoidIcon,
    RefundIcon,
    LeadAddTransaction,
    LeadChooseTransaction,
  },
  props: {
    leadId: {
      type: Number,
      required: true,
    },
    leadName: {
      type: String,
      required: true,
    },
    onlyRead: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isBusy: false,
      sortDesc: true,
      orderBy: "settlement_date",
      items: [],
      paginate: {
        currentPage: 1,
        perPage: 5,
      },
      filter: Filter,
      totalRows: 0,
      startPage: 1,
      nextPage: 1,
      endPage: 1,
      totalData: 0,
      toPage: 0,
      totalAmount: 0,
      visibleFields: [
        {
          key: "settlement_date",
          label: "Date",
          thClass: "text-center",
          tdClass: "px-0 py-50",
          sortable: true,
          visible: true,
          thStyle: { width: "10%" },
        },
        {
          key: "user_name",
          label: "Done by",
          tdClass: "px-0 py-50",
          sortable: true,
          visible: true,
          thStyle: { width: "10%" },
        },
        {
          key: "transaction_id",
          // label: "Transaction ID",
          label: "Trans. ID",
          thClass: "text-center",
          tdClass: "px-0 py-50",
          sortable: true,
          visible: true,
          thStyle: { width: "10%" },
        },
        {
          key: "type_transaction",
          label: "Type",
          tdClass: "px-0 py-50",
          thClass: "text-center",
          sortable: true,
          visible: true,
          thStyle: { width: "10%" },
        },
        {
          key: "amount",
          label: "Amount",
          thClass: "text-center",
          tdClass: "px-0 py-50",
          sortable: true,
          visible: true,
          thStyle: { width: "10%" },
        },
        {
          key: "status_transaction",
          label: "Status",
          tdClass: "px-0 py-50",
          thClass: "text-center",
          sortable: true,
          visible: true,
          thStyle: { width: "10%" },
        },
        {
          key: "method_transaction",
          label: "Method",
          tdClass: "px-0 py-50",
          thClass: "text-center",
          sortable: true,
          visible: true,
          thStyle: { width: "10%" },
        },
        {
          key: "actions",
          label: "Actions",
          tdClass: "px-0 py-50",
          thClass: "text-center",
          sortable: false,
          visible: true,
          thStyle: { width: "10%" },
        },
      ],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Transaction ID...",
        model: "",
      },
      modalVoidRefund: false,
      transactionId: null,
      modalRefund: false,
      dataVoid: {},
      showChooseLeadTransaction: false,
      showAddLeadTransaction: false,
    };
  },
  computed: {
    subTotalAmount() {
      const amount = this.items.reduce((acc, item) => {
        return (
          acc +
          (![10, 11].includes(item.type_transaction_id)
            ? Number(item.amount)
            : 0)
        );
      }, 0);

      return amount;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.getTypesTransaction();
    await this.getStatusTransaction();
    await this.getMethodsTransaction();
  },
  methods: {
    reloadTable() {
      this.$refs["lead-transactions-table"].refresh();
    },
    canVoid(
      transactionTypeId,
      voidTransaction,
      wCard,
      statusTransaction,
      pendingVoidTransactions
    ) {
      return (
        transactionTypeId != 10 &&
        transactionTypeId != 11 &&
        voidTransaction == 1 &&
        wCard == 1 &&
        (this.currentUser.role_id == 1 ||
          this.currentUser.role_id == 2 ||
          this.isChief) &&
        statusTransaction == "Approved" &&
        parseInt(pendingVoidTransactions) < 1
      );
    },
    canRefund(
      transactionTypeId,
      voidTransaction,
      refundTransaction,
      wCard,
      statusTransaction,
      pendingVoidTransactions
    ) {
      return (
        transactionTypeId != 10 &&
        transactionTypeId != 11 &&
        voidTransaction == 0 &&
        refundTransaction == 1 &&
        wCard == 1 &&
        (this.currentUser.role_id == 1 ||
          this.currentUser.role_id == 2 ||
          this.isChief) &&
        statusTransaction == "Approved" &&
        parseInt(pendingVoidTransactions) < 1
      );
    },
    canSeeVoidRefund(transactionTypeId, pendingVoidTransactions) {
      return (
        transactionTypeId == 10 ||
        (transactionTypeId == 11 && parseInt(pendingVoidTransactions) < 1)
      );
    },
    voidAuthorize(
      transactionId,
      merchantId,
      amount,
      clientName,
      settlementDate,
      type,
      sumRef
    ) {
      this.dataVoid = {
        idtransaction: transactionId,
        idmerchant: merchantId,
        amount: sumRef > 0 ? amount - sumRef : amount,
        client_name: clientName,
        settlement_date: settlementDate,
        type,
      };
      this.modalRefund = true;
    },
    closeModalRefund() {
      this.modalRefund = false;
    },
    async getTransactionsByLead(ctx) {
      try {
        const params = {
          searchText: this.filterPrincipal.model
            ? this.filterPrincipal.model
            : "",
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          order: ctx.sortDesc ? "desc" : "asc",
          orderBy: ctx.sortBy,
          from: this.filter[0].model || null,
          to: this.filter[1].model || null,
          leadId: this.leadId,
          typeTransactionId: this.filter[2].model || null,
          statusTransactionId: this.filter[3].model || null,
          methodTransactionId: this.filter[4].model || null,
        };
        const { data } = await LeadTransactionService.getTransactionsByLead(
          params
        );
        this.items = data.data;

        if (data.data.length > 0) {
          this.totalAmount = this.items[0].total_amount;
        } else {
          this.totalAmount = 0;
        }

        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalData = data.total;
        this.totalRows = data.total;
        this.toPage = data.to;
        return this.items || [];
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    getStatus(status) {
      if (
        status === "Settled Successfully" ||
        status === "Approved" ||
        status === "Returned Item" ||
        status === "Refund Settled Successfully"
      ) {
        return "light-success";
      } else if (
        status === "Error" ||
        status === "Declined" ||
        status === "General Error" ||
        status === "Comm Error" ||
        status === "Could Not Void" ||
        status === "Failed Review" ||
        status === "Voided" ||
        status === "Communication Error" ||
        status == "Settlement Error"
      ) {
        return "light-danger";
      } else if (
        status === "Pending" ||
        status === "Held for Review" ||
        status === "Expired" ||
        status === "FDS - Pending Review" ||
        status === "FDS - Authorized/Pending Review"
      ) {
        return "light-warning";
      } else if (
        status === "Captured/Pending Settlement" ||
        status === "ChargeBack" ||
        status === "Authorized/Pending Capture" ||
        status === "Refund/Pending Settlement'" ||
        status === "Approved Review" ||
        status === "Under Review"
      ) {
        return "light-info";
      } else {
        return "light-info";
      }
    },
    closeModalVoidRefundInfo() {
      this.modalVoidRefund = false;
    },
    openModalVoidRefundInfo(transactionId) {
      this.transactionId = transactionId;
      this.modalVoidRefund = true;
    },
    async getTypesTransaction() {
      try {
        const { data } = await LeadTransactionService.getTypesTransaction();
        this.filter[2].options = data;
      } catch (error) {
        console.log("Error in getTypesTransaction: ", error);
      }
    },
    async getStatusTransaction() {
      try {
        const { data } = await LeadTransactionService.getStatusTransaction();
        this.filter[3].options = data;
      } catch (error) {
        console.log("Error in getStatusTransaction: ", error);
      }
    },
    async getMethodsTransaction() {
      try {
        const { data } = await LeadTransactionService.getMethodsTransaction();
        this.filter[4].options = data;
      } catch (error) {
        console.log("Error in getMethodsTransaction: ", error);
      }
    },
    openChooseLeadTransactionModal() {
      this.showChooseLeadTransaction = true;
    },
    reloadTransactions() {
      this.showChooseLeadTransaction = false;
      this.reloadTable();
    },
    openAddLeadTransactionModal() {
      this.showAddLeadTransaction = true;
    },
  },
};
</script>
<style lang="scss">
.lead-transactions-table {
  max-height: 50vh !important;
  overflow-y: auto;
  overflow-x: auto;
  padding-right: 10px;

  .table.b-table {
    thead,
    tfoot {
      > tr {
        > [aria-sort="none"] {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-arrows-vertical' width='100' height='100' viewBox='0 0 24 24' stroke-width='3' stroke='%23000000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M8 7l4 -4l4 4' /%3E%3Cpath d='M8 17l4 4l4 -4' /%3E%3Cpath d='M12 3l0 18' /%3E%3C/svg%3E");
        }

        > [aria-sort="ascending"] {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-arrow-badge-down-filled' width='100' height='100' viewBox='0 0 24 24' stroke-width='3' stroke='%23000000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M16.375 6.22l-4.375 3.498l-4.375 -3.5a1 1 0 0 0 -1.625 .782v6a1 1 0 0 0 .375 .78l5 4a1 1 0 0 0 1.25 0l5 -4a1 1 0 0 0 .375 -.78v-6a1 1 0 0 0 -1.625 -.78z' stroke-width='0' fill='currentColor' /%3E%3C/svg%3E");
        }

        > [aria-sort="descending"] {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-arrow-badge-up-filled' width='100' height='100' viewBox='0 0 24 24' stroke-width='3' stroke='%23000000' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M11.375 6.22l-5 4a1 1 0 0 0 -.375 .78v6l.006 .112a1 1 0 0 0 1.619 .669l4.375 -3.501l4.375 3.5a1 1 0 0 0 1.625 -.78v-6a1 1 0 0 0 -.375 -.78l-5 -4a1 1 0 0 0 -1.25 0z' stroke-width='0' fill='currentColor' /%3E%3C/svg%3E");
        }
      }
    }
  }

  .border-tab {
    border-bottom: 3px solid #3a72ea !important;
  }
  .sticky-data-paginated {
    // position: sticky;
    // bottom: 150;
    // z-index: 1000;
    background-color: #7ea7ff66 !important;
    color: #000000 !important;
    font-weight: 700 !important;
    font-family: "Montserrat", sans-serif !important;
  }
  .sticky-data-total {
    // position: sticky;
    // bottom: 0;
    // z-index: 1000;
    background-color: #7ea7ff66 !important;
    color: #000000 !important;
    font-weight: 700 !important;
    font-family: "Montserrat", sans-serif !important;
  }
  .footer-title {
    color: #000000 !important;
    font-weight: 700 !important;
    font-family: "Montserrat", sans-serif !important;
  }

  .footer-amount {
    color: #000000 !important;
    font-weight: 700 !important;
    font-family: "Montserrat", sans-serif !important;
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
  }
  .th-class-title {
    // text-align: center !important;
    background-color: #3a72ea !important;
    color: #fff !important;
    font-family: "Montserrat", sans-serif !important;
    font-weight: 700 !important;
  }

  .px-255 {
    padding-left: 2.55rem !important;
    padding-right: 2.55rem !important;
  }
  .b-table {
    .text-black {
      td {
        color: black;
      }
    }
  }
}
.custom-img {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
}
</style>
<style scoped>
.custom-table
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th {
  background-color: #3a72ea !important;
  color: rgba(17, 17, 19, 1);
  padding: 12px 10px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: none !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child {
  border-top-right-radius: 10px !important;
}
thead {
  border-style: none !important;
}
.custom-table >>> .table tr td:first-child,
.custom-table-dark >>> .table tr td:first-child {
  border-radius: 10px 0px 0px 10px !important;
}

.custom-table >>> .table tr td,
.custom-table-dark >>> .table tr td {
  border: none !important;
}

.custom-table >>> .table tr td:last-child,
.custom-table-dark >>> .table tr td:last-child {
  border-radius: 0px 10px 10px 0px !important;
}
.custom-table >>> .table tr td .custom-space,
.custom-table-dark >>> .table tr td .custom-space {
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.custom-table >>> .table tr td:first-child .custom-space,
.custom-table-dark >>> .table tr td:first-child .custom-space {
  border-radius: 10px 0px 0px 10px !important;
  padding-left: 20px !important;
}
.custom-table >>> .table tr td:last-child .custom-space,
.custom-table-dark >>> .table tr td:last-child .custom-space {
  border-radius: 0px 10px 10px 0px !important;
}
.b-table > tbody > tr td {
  border: none !important;
}
.bg-primary-dark {
  background-color: rgba(7, 85, 255, 0.2) !important;
}
.bg-primary-light {
  background-color: #f2f6ff !important;
}
.font-size-md {
  font-size: 14px !important;
}
.custom-empty-content {
  height: 450px !important;
}
.custom-img-empty {
  opacity: 0.4;
}
.custom-message-span {
  position: absolute;
  font-size: 25px;
  font-weight: bolder;
}
.bg-custom-dark {
  background: #111113;
}
.add-payment-button {
  background-color: #3f7afa !important;
  background: #3f7afa !important;
  color: #111113 !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
}
.my-05 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.text-purple {
  color: #9090ff !important;
}
</style>
