export default [
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
    },
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Derived from",
        model: null,
        options: [],
        reduce: "id",
        selectText: "module_name",
        cols: 12,
    },
    {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Process Sale status",
        model: null,
        options: [],
        reduce: "id",
        selectText: "sale_process_name",
        cols: 12,
    },
]