/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class ProfileService {
  async updateProfile(body) {
    try {
      const { data, status } = await amgApi.post('/profile/update-profile', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async initializeEssentialDocumments(body) {
    try {
      const { data, status } = await amgApi.post('/profile/initialize-essential-documments', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async updateEmergencyContact(body) {
    try {
      const { data, status } = await amgApi.post('/profile/update-emergency-contact', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async updateAcademicInformation(body) {
    try {
      const { data, status } = await amgApi.post('/profile/update-academic-information', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async updateAccountInformation(body) {
    try {
      const { data, status } = await amgApi.post('/profile/update-account-information', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async updateWorkExperiencie(body) {
    try {
      const { data, status } = await amgApi.post('/profile/update-work-experiencie', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getOtherInformation(body) {
    try {
      const { data, status } = await amgApi.post('/profile/get-other-information', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getWorkInformation(body) {
    try {
      const { data, status } = await amgApi.post('/profile/get-work-experiencie', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getAccountInformation(body) {
    try {
      const { data, status } = await amgApi.post('/profile/get-account-information', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async updatePensionFund(body) {
    try {
      const { data, status } = await amgApi.post('/profile/update-pension-fund', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async updateApplicant(body) {
    try {
      const { data, status } = await amgApi.post('/profile/update-applicant', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
}

export default new ProfileService();
