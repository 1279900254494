export default [
  {
    path: '/ticket-customer-lead/:id',
    name: 'ticket-customer-lead-show',
    component: () => import(/* webpackChunkName: "customer-ticket" */ '@/views/crm/views/Lead/lead-module/dashboard/default.vue'),
    props: true,
    meta: {
      isClientsTab: true,
      route: 'ticketcustomer',
      contentClass: 'leads-app',
      module: 0,
      general: true,
    },
  },
];
