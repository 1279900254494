<template>
  <div>
   <super-assist-dashboard v-if="isSupervisor || isAssistantSupervisor || isCeo || isChief"></super-assist-dashboard>
    <seller-dashboard v-if="isSellerC"></seller-dashboard>

  </div>
</template>

<script>
import SellerDashboard from '@/views/ce-digital/sub-modules/sales/views/dashboards/seller/SellerDashboard.vue'
import SuperAssistDashboard from '@/views/ce-digital/sub-modules/sales/views/dashboards/super-assist/SupAssistDashboard.vue'
import { mapGetters } from 'vuex'


export default {
  components: {
    SellerDashboard,
    SuperAssistDashboard,

  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
}
</script>

<style scoped>

</style>
