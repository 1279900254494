export default [
    {
        path: 'sale-process',
        name: 'sale-process-ti',
        component: () => import(/* webpackChunkName: "PRSaleProcess" */'@/views/paragon/views/process-sale/procces-sale-module/ProcessSale.vue'),
        meta: {
            permittedRoles: [1,7,17],
            pageTitle: 'Sale Process',
            breadcrumb: [{
                text: 'Sale Process',
            }],

        },
    },
]