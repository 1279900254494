import { amgApi } from "@/service/axios";

class SalesMadeService {
  constructor() {
    this.url = "/sales-made";
  }
  async GET_SALE_PROGRAM_LEAD(params) {
    try {
      const data = await amgApi.post(
        `${this.url}/search-sale-program-lead`,
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error GET_SALE_PROGRAM_LEAD");
    }
  }

  async getSalesMadeAdmCounter() {
    try {
      const data = await amgApi.get(`${this.url}/sales-made-adm-counter`);
      return data;
    } catch (error) {
      console.log("Error getSalesMadeAdmCounter");
    }
  }

  async annulSale(params) {
    try {
      const { data } = await amgApi.post(`${this.url}/annul-sale`, params);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateSalesSetDone(params) {
    try {
      const { data } = await amgApi.post("/sale/update-sale-set-done", params);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getSaleMadeBySaleId(params) {
    try {
      const { data } = await amgApi.post(
        `${this.url}/get-sale-made-by-sale-id`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async disapproveSaleMadeLeads(body) {
    try {
      const request = await amgApi.post(
        `${this.url}/disapprove-sale-made-by-leads`,
        body
      );
      return request;
    } catch (error) {
      console.log("Something went wrong on getSalesMadeLeads:", error);
      throw error;
    }
  }

  async getDetailsOfPayment(body) {
    try {
      const request = await amgApi.post(
        `${this.url}/get-details-of-payment`,
        body
      );
      return request;
    } catch (error) {
      console.log("Something went wrong on getSalesMadeLeads:", error);
      throw error;
    }
  }
}

export default new SalesMadeService();
