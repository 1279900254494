import DocumentService from "@/views/debt-solution/views/settings/views/documents-config/documents/service/document.service.js";

export default {
  namespaced: true,
  state: {
    S_DOCUMENT_ACTIVES: [],
  },
  getters: {
    G_DOCUMENT_ACTIVES(state) {
      return state.S_DOCUMENT_ACTIVES;
    },
  },
  mutations: {
    M_DOCUMENT_ACTIVES(state, params) {
      state.S_DOCUMENT_ACTIVES = params;
    },
  },
  actions: {
    async A_GET_DOCUMENT_ACTIVES({ commit }, params) {
      try {
        const response = await DocumentService.getDocumentActives(params);
        commit("M_DOCUMENT_ACTIVES", response);
      } catch (error) {
        throw error;
      }
    },
  },
};

