<template>
  <div>
    <b-modal
      v-model="showModal"
      size="smd"
      no-close-no-backdrop
      hide-footer
      header-bg-variant="transparent"
      @hidden="closeModal"
    >
      <template #modal-title>
        <h5
          class="text-uppercase font-weight-bolder p-0 m-0 d-flex justify-content-start align-items-center"
          style="margin-top: 3px !important"
        >
          <feather-icon icon="ClockIcon" size="15" class="mr-1" />
          Meetings of customer tickets
        </h5>
      </template>
      <b-container fluid class="p-2">
        <!--MEETINGS-->
        <b-row>
          <b-col cols="12" lg="6">
            <header
              class="d-flex w-100 justify-content-start align-items-center mb-1 pl-1"
            >
              <strong style="font-size: 16px" class="mr-2"> Month: </strong>
              <div
                class="d-flex justify-content-center align-items-center border-primary rounded"
              >
                <b-button
                  variant="primary"
                  class="p-0"
                  style="
                    padding: 5px !important;
                    border-radius: 3px 0px 0px 3px !important;
                  "
                  size="sm"
                  @click="nextMonth(-1)"
                >
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="17"
                    class="hover-me"
                  />
                </b-button>
                <span
                  class="text-center"
                  style="font-size: 13px; padding: 3px 15px; min-width: 150px"
                  >{{ monthAndYear }}</span
                >
                <b-button
                  variant="primary"
                  class="p-0"
                  style="
                    padding: 5px !important;
                    border-radius: 0px 3px 3px 0px !important;
                  "
                  size="sm"
                  @click="nextMonth(1)"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="17"
                    class="hover-me"
                  />
                </b-button>
              </div>
            </header>
            <body-calendar-component
              v-if="!loadCalendar"
              :key="keyCalendar"
              :month="month"
              :year="year"
              :disabled-old-days="true"
              :multiple="false"
              :hours-availables="meetingsToAnswer"
              @selectedDays="selectedDays"
            />
            <b-skeleton-table
              v-else
              :rows="4"
              :columns="5"
              :table-props="{ bordered: true, striped: true }"
            />
          </b-col>
          <!--LIST MEETINGS-->
          <b-col cols="12" lg="6" class="px-2 border-left">
            <div class="d-flex justify-content-between align-items-center px-1">
              <h5
                class="d-inline-flex text-uppercase text-center font-weight-bolder mt-1 mt-lg-0"
                style="font-size: 15px; text-stroke: 0.5px currentColor"
              >
                Meetings
              </h5>
              <span class="ml-1">
                <feather-icon icon="CalendarIcon" style="margin-right: 3px" />
                <span
                  class="text-uppercase"
                  style="margin-right: 3px; font-size: 12px"
                  >Selected day:</span
                >
                {{ dateSelected | myGlobal }}
              </span>
            </div>
            <b-list-group
              style="
                max-height: 280px;
                min-height: 280px;
                overflow-y: auto;
                overflow-x: hidden;
                border-radius: 0px !important;
              "
              class="p-0"
            >
              <!--MEETINGS-->
              <template v-if="hoursMeetings.length > 0">
                <div
                  v-for="(item, index) in hoursMeetings"
                  :key="index"
                  class="d-flex align-items-center"
                >
                  <b-list-group-item
                    :id="'id_' + index"
                    class="d-block align-items-center rounded w-100 font-weight-bolder bg-transparent"
                    style="margin: 4px 0px"
                    :class="
                      [1, 2].includes(item.status_id)
                        ? color.border[item.status_id]
                        : null
                    "
                    :style="
                      ![1, 2].includes(item.status_id)
                        ? `background-color: ${
                            color.background[item.status_id]
                          } !important;`
                        : null
                    "
                  >
                    <small
                      :class="
                        ![1, 2].includes(item.status_id)
                          ? 'text-light'
                          : isDarkSkin
                          ? 'text-white'
                          : 'text-secondary'
                      "
                      class="d-flex font-weight-bolder"
                      style="margin-bottom: 5px"
                    >
                      Created by {{ item.creator_name }}
                    </small>
                    <div class="d-flex justify-content-between">
                      <strong
                        v-b-tooltip.hover="'View in the grid'"
                        class="text-primary pointer"
                        style="font-size: 14px"
                        :style="
                          item.status_id === 6
                            ? 'color: orange !important;'
                            : null
                        "
                        @click="redirect(item)"
                      >
                        <feather-icon
                          :icon="
                            item.type == 'expenses' ? 'MapIcon' : 'UserIcon'
                          "
                          size="15"
                          style="margin-right: 5px"
                        />
                        {{
                          item.type == "expenses"
                            ? item.code_ticket
                            : item.name_client
                        }}
                        <feather-icon icon="ArrowUpRightIcon" size="15" />
                      </strong>
                      <strong
                        v-if="item.phone"
                        class="ml-1"
                        style="margin-top: 4px; font-size: 12px"
                        :class="
                          ![1, 2].includes(item.status_id)
                            ? 'text-light'
                            : isDarkSkin
                            ? 'text-white'
                            : 'text-secondary'
                        "
                      >
                        <feather-icon
                          icon="PhoneIcon"
                          size="15"
                          style="margin-right: 5px"
                        />
                        {{ item.phone }}
                      </strong>
                    </div>
                    <strong
                      v-if="item.account"
                      style="margin-top: 4px; font-size: 12px"
                      :class="
                        ![1, 2].includes(item.status_id) ? 'text-light' : null
                      "
                    >
                      <feather-icon
                        icon="BriefcaseIcon"
                        size="15"
                        style="margin-right: 5px"
                      />
                      {{ item.account }}
                    </strong>
                    <!--ACTIONS-->
                    <div
                      class="w-100 d-flex justify-content-between align-items-center"
                      :class="
                        ![1, 2].includes(item.status_id) ? 'text-light' : null
                      "
                      style="margin: 4px 0px"
                    >
                      <span style="font-size: 13px">
                        <feather-icon
                          icon="ClockIcon"
                          size="15"
                          style="margin-right: 5px"
                        />
                        {{ item.meeting_start | myFullTime }} -
                        {{ item.meeting_end | myFullTime }}
                      </span>
                      <span class="d-flex w-auto">
                        <template v-if="[1, 2].includes(item.status_id)">
                          <feather-icon
                            v-b-tooltip.hover.v-success
                            v-b-tooltip.hover.bottom
                            title="ATTENDED"
                            icon="UserCheckIcon"
                            class="cursor-pointer text-success mr-2 hover-me"
                            size="20"
                            @click="AttendMeeting(item)"
                          />
                          <feather-icon
                            v-b-tooltip.hover.v-danger
                            v-b-tooltip.hover.bottom
                            title="NOT ANSWERED"
                            icon="UserXIcon"
                            class="cursor-pointer text-danger hover-me"
                            size="20"
                            @click="notAnswerMeeting(item, 6)"
                          />
                        </template>
                        <template v-else-if="![1, 2].includes(item.status_id)">
                          <b-badge
                            variant="light"
                            style="font-size: 12px; padding: 5px 10px"
                            class="text-black"
                          >
                            {{ item.status_name }}
                          </b-badge>
                        </template>
                      </span>
                    </div>
                  </b-list-group-item>
                </div>
              </template>
              <!--WE THERE AREN'T DATA-->
              <template v-else>
                <b-list-group-item
                  class="d-flex justify-content-center align-items-center pointer-events-none w-100"
                  style="
                    max-height: 280px;
                    min-height: 280px;
                    border: none !important;
                  "
                >
                  <div
                    class="d-flex justify-content-center align-items-center text-center flex-wrap"
                  >
                    <feather-icon
                      icon="ClockIcon"
                      size="80"
                      class="m-1 text-secondary"
                      :class="loadCalendar ? 'rotate-element' : null"
                    />
                    <span
                      v-if="!loadCalendar"
                      class="text-secondary mt-2 w-100"
                      style="font-size: 18px"
                    >
                      Select a day to display the meetings
                    </span>
                    <span
                      v-else
                      class="text-primary mt-2 w-100 ml-1"
                      style="font-size: 18px"
                    >
                      loading ...
                    </span>
                  </div>
                </b-list-group-item>
              </template>
            </b-list-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <strong
              v-if="month < currentMonth && year <= currentYear"
              class="d-inline-flex justify-content-center align-content-center w-100 text-warning text-uppercase"
              >you are looking the meetings of the previous month</strong
            >
          </b-col>
        </b-row>
        <!--REPROGRAMM MEETING-->
        <!-- <div v-if="showMeetingsTicket">
          <b-row
            class="pt-2"
          >

            <h5 class="position-relative text-info text-uppercase text-center w-100 d-flex justify-content-around align-items-center pt-1 mb-2 font-weight-bolder border-top">
              REPROGRAMM MEETING ?
              <feather-icon
                v-b-tooltip.hover.v-danger
                v-b-tooltip.hover.rigth
                title="CANCEL"
                icon="XSquareIcon"
                size="20"
                class="cursor-pointer hover-me text-danger"
                @click="closeBodyMeeting"
              />
            </h5>
            <b-col
              cols="12"
            >
              <body-meeting-ticket
                :ticket-customer="ticketCustomerParams"
                :return-hours="true"
                :default-today="false"
                :disabled-previous-month="true"
                @closeModal="showMeetingsTicket=false"
                @selectedHours="selectedHoursToReprogramm"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <b-button
                variant="danger"
                class="float-right"
                @click="saveNotAnswerMeeting"
              >
                <feather-icon
                  icon="UserXIcon"
                  style="margin-right: 5px"
                />
                {{ !hoursReprogram ? 'NOT ANSWERED' : 'NOT ANSWERED (REPROGRAMMED)' }}
              </b-button>
              <b-button
                variant="secondary"
                class="mr-1 float-right"
                @click="closeBodyMeeting"
              >
                CANCEL
              </b-button>
            </b-col>
          </b-row>
        </div> -->
      </b-container>
    </b-modal>
    <!--MODAL ATTENDED-->
    <modal-attended
      v-if="showModalAttended"
      :ticket="paramsticketAttended"
      @closeModal="showModalAttended = false"
      @success="successAttended"
    />
  </div>
</template>
<script>
import moment from "moment";
import BodyCalendarComponent from "@/views/commons/components/customer-tickets/components/modal/meetings/BodyCalendar.vue";
import CustomerTicketsServices from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import ModalAttended from "@/views/commons/components/customer-tickets/components/modal/meetings/ModalAttended.vue";
import BodyMeetingTicket from "@/views/commons/components/customer-tickets/components/modal/meetings/BodyMeetingTicket.vue";
import { mapGetters } from "vuex";
import store from "@/store";
import { getModuleText } from "@/utils/convertModule.js";
export default {
  components: {
    BodyCalendarComponent,
    ModalAttended,
    BodyMeetingTicket,
  },
  data() {
    return {
      showModal: false,
      currentMonth: parseInt(moment().format("MM"), 10),
      currentYear: parseInt(moment().format("YYYY"), 10),
      month: parseInt(moment().format("MM"), 10),
      year: parseInt(moment().format("YYYY"), 10),
      keyCalendar: 1,
      meetingsToAnswer: [],
      hoursMeetings: [],
      loadHours: false,
      color: {
        background: {
          3: "#52BE80",
          4: "#52BE80",
          5: "#ABB2B9",
          6: "#EC7063",
        },
        border: {
          1: "border-warning",
          2: "border-info",
        },
      },
      showModalAttended: false,
      paramsticketAttended: {},
      showMeetingsTicket: false,
      paramsTicketReprogram: {},
      hoursReprogram: null,
      meetingAnswered: false,
      loadCalendar: true,
      ticketCustomerParams: {},
    };
  },
  computed: {
    monthAndYear() {
      return moment(`${this.month}/${this.year}`, "MM/YYYY").format(
        "MMMM YYYY"
      );
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    dateSelected() {
      if (this.hoursMeetings.length > 0) {
        return moment(this.hoursMeetings[0].meeting_date).format("YYYY-MM-DD");
      }
      return null;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    await this.getMeetingsToAnswer();
    this.convertModule();
  },
  mounted() {
    this.showModal = true;
  },
  methods: {
    redirect(item) {
      const routeParams = {
        code: item.code_ticket,
        searchByCode: true,
        module: item.module_id,
      };

      let routeName = "";
      let type = "";
      if (item.type == "payments") {
        type = "payment";
      } else if (item.type == "expenses") {
        type = "expense";
      } else {
        type = item.type;
      }
      if (item.id_status === 5 || item.id_status === 6) {
        routeName = `${type}-ticket-${this.moduleText}-in-progress`;
      } else if (item.id_status === 4) {
        routeName = `${type}-ticket-${this.moduleText}-rejected`;
      } else if (item.id_status === 3) {
        routeName = `${type}-ticket-${this.moduleText}-in-progress`;
      } else if (
        item.id_status === 2 ||
        (item.created_by === 1 && item.id_status === 1)
      ) {
        routeName = `${type}-ticket-${this.moduleText}-new`;
      } else if (item.id_status === 7) {
        routeName = `${type}-ticket-${this.moduleText}-not-solved`;
      }
      this.$store.dispatch("TicketCustomerStore/A_SET_CODE", item.code_ticket);
      // Realizar la navegación utilizando 'replace' en lugar de 'push'
      if (routeName) {
        this.$nextTick(() => {
          this.$router
            .replace({
              name: routeName,
              params: routeParams,
            })
            .catch((error) => {
              // Manejar específicamente la excepción NavigationDuplicated
              if (
                error.name === "NavigationDuplicated" &&
                error.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                if (
                  routeName ===
                  `${item.type}-ticket-${this.moduleText}-in-progress`
                ) {
                  this.$root.$emit("redirect-to-module", routeParams);
                }
                // Puedes omitir la excepción ya que se trata de una navegación redundante
              } else {
                // Lanzar otras excepciones
                throw error;
              }
            });
        });
      }
      // Emitir un evento personalizado con la variable
      this.$emit("closeModal");
    },
    closeModal() {
      this.$emit("closeModal", this.meetingAnswered);
    },
    refreshTable() {
      this.$refs.tableListMeetings.refresh();
    },
    async nextMonth(value) {
      const newDate = moment(`${this.year}-${this.month}`, "YYYY-MM").add(
        value,
        "month"
      );
      this.year = parseInt(newDate.format("YYYY"), 10);
      this.month = parseInt(newDate.format("MM"), 10);
      await this.getMeetingsToAnswer();
    },
    async getMeetingsToAnswer() {
      try {
        this.loadCalendar = true;
        this.hoursMeetings = [];
        const params = {
          month: this.month,
          year: this.year,
        };
        const { data } =
          await CustomerTicketsServices.getTicketCustomerMeetingsToAnswer(
            params
          );
        if (!data) return;

        // WE GROUP HOURS OF MEETING BY DAY AND WE ORDER BY NRO CORRELATIVE
        this.meetingsToAnswer = data.map((item) => {
          const meetingDate = {
            ...item,
            day: moment(item.meeting_date).format("D"),
            date: moment(item.meeting_date).format("YYYY-MM-DD"),
            disabled: !item.meetings,
            highlight: !!item.meetings,
          };

          const meetingOrdened = item.meetings
            ? JSON.parse(item.meetings).sort((a, b) => {
                if (
                  a.meeting_start < b.meeting_start ||
                  a.meeting_end < b.meeting_end
                )
                  return -1;
                if (
                  a.meeting_start > b.meeting_start ||
                  a.meeting_end > b.meeting_end
                )
                  return 1;
                return 0;
              })
            : null;

          meetingDate.meetings = JSON.stringify(meetingOrdened);
          return meetingDate;
        });

        this.keyCalendar += 1;
        this.loadCalendar = false;
      } catch (error) {
        this.showErrorSwal();
        console.log("Ocurred un error in getMeetingsToAnswer : ", error);
        this.loadCalendar = false;
      }
    },
    selectedDays(days) {
      this.showMeetingsTicket = false;
      this.closeBodyMeeting();
      if (days.length === 0) {
        this.hoursMeetings = [];
      } else {
        this.hoursMeetings = JSON.parse(days[0].meetings);
      }
    },
    AttendMeeting({
      ticket_customer_id,
      name_client,
      code_ticket,
      meeting_start,
      meeting_end,
      meeting_date,
      created_by,
      module_id_client,
      phone,
      account,
    }) {
      this.paramsticketAttended = {
        id: ticket_customer_id,
        name_client,
        code: code_ticket,
        meeting: {
          date: meeting_date,
          hour_start: meeting_start,
          hour_end: meeting_end,
          created_by,
        },
        module_id: module_id_client,
        number_phone: phone,
        account,
      };
      this.showModalAttended = true;
      this.closeBodyMeeting(); // in case before action was reprogramm meeting
    },
    async notAnswerMeeting(item, newStatus) {
      this.showMeetingsTicket = true;
      this.paramsTicketReprogram = {
        ticket_customer_id: item.ticket_customer_id,
        meeting_date: item.meeting_date,
        meeting_start: item.meeting_start,
        meeting_end: item.meeting_end,
        new_status: newStatus,
        created_by: item.created_by,
        code: item.code_ticket,
        name_client: item.name_client,
        meetingCreator: item.hasOwnProperty("old_meeting")
          ? item.old_meeting.created_by
          : item.created_by,
      };
      this.ticketCustomerParams = {
        module_id: item.module_id_client,
      };
      const result = await this.showConfirmSwal(
        "NOT ANSWERED",
        "Are you sure you want to save the meeting without rescheduling?"
      );
      if (result.isConfirmed) {
        this.saveNotAnswerMeeting();
      }
    },
    selectedHoursToReprogramm(hours) {
      this.hoursReprogram = hours.length > 0 ? hours : null;
    },
    closeBodyMeeting() {
      this.showMeetingsTicket = false;
      this.paramsTicketReprogram = {};
      this.hoursReprogram = null;
    },
    async saveNotAnswerMeeting() {
      try {
        // let save = false;

        // no haven't hours to reprogramm
        // if (!this.hoursReprogram) {
        //   const { isConfirmed } = await this.showConfirmSwal('NO DATE SELECTED', 'Are you sure you want to save the meeting without rescheduling?');
        //   if (!isConfirmed) return;
        //   save = true;
        // }

        if (!this.validHours()) return;

        // confirm save
        // if (!save) {
        //   const { isConfirmed } = await this.showConfirmSwal();
        //   if (!isConfirmed) return;
        // }

        this.addPreloader();

        let newMeeting = null;
        if (this.hoursReprogram) {
          newMeeting = this.hoursReprogram.map((item) => ({
            meetingDate: item.meeting_date || null,
            meetingStart: item.range_start || null,
            meetingEnd: item.range_end || null,
            status: 2,
          }));
        }
        let isCorrelative = 0;
        if (this.hoursReprogram && this.hoursReprogram.length > 1) {
          isCorrelative = 1;
        }
        const params = {
          ticketCustomerId: this.paramsTicketReprogram.ticket_customer_id,
          createdBy: this.currentUser.user_id,
          descriptionNote: null,
          status: this.paramsTicketReprogram.new_status,
          isCorrelative,
          ticketCode: this.paramsTicketReprogram.code,
          clientName: this.paramsTicketReprogram.name_client,
          meetingCreator: this.paramsTicketReprogram.meetingCreator,
          newMeeting,
        };

        const { status } =
          await CustomerTicketsServices.updateMeetingTicketCustomer(params);
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal();
          this.showModalAttended = false;
          this.showMeetingsTicket = false;
          await this.getMeetingsToAnswer();
          await store.dispatch(
            "NotificationStore/A_MEETINGS_PENDING_CUSTOMER_TICKETS"
          );
          this.meetingAnswered = true;
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
        console.log("error in saveNotAnswerMeeting: ", error);
      }
    },
    successAttended() {
      this.showModalAttended = false;
      this.showMeetingsTicket = false;
      this.meetingAnswered = true;
      this.getMeetingsToAnswer();
    },
    validHours() {
      if (!this.hoursReprogram || this.hoursReprogram.length === 1) {
        return true;
      }

      let isValid = true;
      this.hoursReprogram.forEach((item, index) => {
        const beforeHourEnd =
          index > 0 ? this.hoursReprogram[index - 1].range_end : null;

        if (beforeHourEnd && beforeHourEnd !== item.range_start) {
          isValid = false;
        }
      });

      if (!isValid) {
        this.showWarningSwal(
          "VERY IMPORTANT",
          "You cannot select more than one range of hours that are not correlative."
        );
      }

      return isValid;
    },
    convertModule() {
      this.moduleText = getModuleText(this.moduleId);
    },
  },
};
</script>
<style scoped>
* {
  --custom-blue: #2591e7;
}
.grid-calendar {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0px;
  margin: 0px auto;
  position: relative;
}

.grid-calendar .name-day,
.grid-calendar .number-day,
.grid-calendar .number-day-dark-skin {
  text-align: center;
  font-size: 10px;
  padding: 10px 0px;
  /* border-bottom: 1px solid #ccc; */
  text-transform: uppercase;
  transition: 0.3s ease-in-out all;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.grid-calendar .name-day {
  font-weight: 600;
}

.grid-calendar .number-day,
.grid-calendar .number-day-dark-skin {
  cursor: pointer;
  margin: 2px;
}

.grid-calendar .number-day:hover,
.grid-calendar .number-day-dark-skin:hover {
  background-color: var(--custom-blue);
  /* border-radius: 5px; */
}
/*
.grid-calendar .number-day span,
.grid-calendar .number-day-dark-skin span {
  transition: 0.3s ease-in-out all;
} */

.grid-calendar .number-day:hover span,
.grid-calendar .number-day-dark-skin:hover span {
  cursor: pointer;
  font-size: 15px;
  transform: traslateY(-5px);
  color: white;
}

/* .number-day:nth-child(7n+1),
.number-day:nth-child(7n+3),
.number-day:nth-child(7n+5),
.number-day:nth-child(7n+7)
{
  background-color: #F8F9F9;
}
.number-day-dark-skin:nth-child(7n+1),
.number-day-dark-skin:nth-child(7n+3),
.number-day-dark-skin:nth-child(7n+5),
.number-day-dark-skin:nth-child(7n+7){
  background-color: #f8f9f917;
} */

.day-selected {
  background-color: var(--custom-blue) !important;
  color: white !important;
}
.day-selected span {
  font-size: 15px;
}
.day-selected .indicator {
  background-color: white !important;
}

.indicator,
.indicator-dark {
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  bottom: 3px;
  transition: 0.3s ease-in-out all;
}
.indicator {
  background-color: currentColor;
}

.grid-calendar .number-day:hover .indicator,
.grid-calendar .number-day-dark-skin:hover .indicator {
  background-color: white;
}

.rotate-me {
  transition: 0.3s ease-in-out all;
}
.rotate-me:hover {
  transform: rotate(180deg);
}

/*---ANIMATIONs----*/
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 0s !important;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
.slide-fade-item {
  transition: transform 0.5s;
}
.rotate-element {
  transition: 0.3s ease-in-out all;
  color: var(--custom-blue) !important;
  animation: rotate-element 1s infinite;
}
@keyframes rotate-element {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
