import RequestClientStatusServices from '@/views/management/views/request-client-status/services/RequestClientStatus.services.js';
import store from '@/store'
import Vue from "vue";
import router from "@/router";

const ChangeStatusNotification = () => {
  window.socket.bind('change-status-notification', async (response) => {
    const { account, client_name, user_name, module_id  } = response; 
    const current_user_id = store.state.auth.currentUser.user_id;
    const current_module_id = store.state.auth.currentUser.modul_id; 
    const current_role_id = store.state.auth.currentUser.role_id; 
    const route = module_id ==16 ?'mg-request-client-status-pending': 'quality-request-client-status-pending';
    if(current_module_id == module_id){ 
        Vue.prototype.$amgAlert({
            title: 'Request for Change of Status',
            subtitle: 'Change Status for '+account,
            data: [
                { icon: 'UserIcon', title: 'CLIENT', description: client_name }, 
                { icon: 'UserIcon', title: 'REQUESTER', description: user_name }, 
                
            ], 
            okTitle: 'Go to Accounts Actions',
            icon:"XIcon",
            ok: () => {
            const goSection = route;
                if (router.currentRoute.name !== goSection) {                    
                    router.push({ name: goSection });
                }
            },
        }); 
        const data = await  RequestClientStatusServices.getCountPendingChangeStatus({module_id: module_id}); 
        const payload = {
          routeName: route,
          tag: data.data,
        };
        store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload); 
    }
  })
}
 


export {ChangeStatusNotification}

