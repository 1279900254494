import AssistanceMain from '@/views/assistance/views/assistanceMain.vue';

export default [
    {
        path: 'attendance',
        name: 'sp-accounts-resolution-attendance',
        redirect: { name: 'sp-accounts-resolution-chief-attendance-dashboard' },
        component: AssistanceMain,
        children: [
            {
                path: 'chief-attendance-dashboard',
                name: 'sp-accounts-resolution-chief-attendance-dashboard',
                component: () => import('@/views/assistance/views/dashboard/views/GraphicReport.vue'),
                meta: {
                    pageTitle: 'Attendance',
                    breadcrumb: [
                        {
                            text: 'My Department Attendance',
                        },
                    ],
                    permittedRoles: [1, 2, 17],
                    attendanceTolerance: 'sp-accounts-resolution-attendance-tolerance',
                    chiefDashboardRoute: 'sp-accounts-resolution-chief-attendance-dashboard',
                    attendanceDashboard: 'sp-accounts-resolution-attendance-dashboard',
                    attendanceHolidays: 'sp-accounts-resolution-attendance-holidays',
                    attendanceVacations: 'sp-accounts-resolution-attendance-vacations',
                    attendanceVacationsExistent: 'sp-accounts-resolution-attendance-vacations-existent',
                    attendanceVacationsDeleted: 'sp-accounts-resolution-attendance-vacations-deleted',
                    attendanceQrGenerator: 'sp-accounts-resolution-attendance-qr-generator',
                    attendanceEmployeesSchedule: 'sp-accounts-resolution-attendance-employees-schedule',
                    departmentalSchedules: 'sp-accounts-resolution-attendance-department',
                    tabRole: 'chief',
                },
            },
            {
                path: 'schedule',
                name: 'sp-accounts-resolution-attendance-employees-schedule',
                component: () => import(/* webpackChunkName: "ParagonEmployeesSchedule" */ '@/views/assistance/views/employees-schedule/EmployeesSchedule.vue'),
                meta: {
                    pageTitle: 'Attendance',
                    breadcrumb: [
                        {
                            text: 'Schedule',
                        },
                    ],
                    permittedRoles: [1, 2, 17],
                    attendanceTolerance: 'sp-accounts-resolution-attendance-tolerance',
                    chiefDashboardRoute: 'sp-accounts-resolution-chief-attendance-dashboard',
                    attendanceDashboard: 'sp-accounts-resolution-attendance-dashboard',
                    attendanceHolidays: 'sp-accounts-resolution-attendance-holidays',
                    attendanceVacations: 'sp-accounts-resolution-attendance-vacations',
                    attendanceVacationsExistent: 'sp-accounts-resolution-attendance-vacations-existent',
                    attendanceVacationsDeleted: 'sp-accounts-resolution-attendance-vacations-deleted',
                    attendanceQrGenerator: 'sp-accounts-resolution-attendance-qr-generator',
                    attendanceEmployeesSchedule: 'sp-accounts-resolution-attendance-employees-schedule',
                    departmentalSchedules: 'sp-accounts-resolution-attendance-department',
                },
            },
            {
                path: 'department',
                name: 'sp-accounts-resolution-attendance-department',
                component: () => import('@/views/assistance/views/dashboard/components/departamentalSchedules/views/components/DepartamentalSchedulesComponent.vue'),
                meta: {
                    pageTitle: 'Attendance',
                    breadcrumb: [
                        {
                            text: 'Schedule',
                        },
                    ],
                    permittedRoles: [1, 2, 17],
                    attendanceTolerance: 'sp-accounts-resolution-attendance-tolerance',
                    chiefDashboardRoute: 'sp-accounts-resolution-chief-attendance-dashboard',
                    attendanceDashboard: 'sp-accounts-resolution-attendance-dashboard',
                    attendanceHolidays: 'sp-accounts-resolution-attendance-holidays',
                    attendanceVacations: 'sp-accounts-resolution-attendance-vacations',
                    attendanceVacationsExistent: 'sp-accounts-resolution-attendance-vacations-existent',
                    attendanceVacationsDeleted: 'sp-accounts-resolution-attendance-vacations-deleted',
                    attendanceQrGenerator: 'sp-accounts-resolution-attendance-qr-generator',
                    attendanceEmployeesSchedule: 'sp-accounts-resolution-attendance-employees-schedule',
                    departmentalSchedules: 'sp-accounts-resolution-attendance-department',
                },
            },
        ]
    },
]