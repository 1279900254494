import { amgApi } from "@/service/axios";

class SettingsServiceSp {
  async getAllManualNcrTypes(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-all-manual-ncr-type",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllAutotmaticNcrTypes(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-all-automatic-ncr-type",
        params
      );

      return data;
    } catch (error) {
      throw error;
    }
  }

  async getNcrFailedMotives(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-motives-failed-ncr",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateManualNcrType(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/update-manual-ncr-type",
        params
      );
    } catch (error) {
      throw error;
    }
  }
  
  async deleteManualNcrType(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/delete-manual-ncr-type",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async deleteNcrFailedMotives(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/delete-ncr-failed-motive",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async updateNcrFailedMotives(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/update-ncr-failed-motive",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async updateNcrStatusAutomatic(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/update-ncr-status-automatic",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async saveManualNcrTypes(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/save-manual-ncr-type",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async saveNcrFailedMotives(params) {
    try {
      return await amgApi.post(
        "/specialists/settings/save-ncr-failed-motives",
        params
      );
    } catch (error) {
      throw error;
    }
  }

  async getManualNcrTypeTrack(params) {
    try {
      const { data } = await amgApi.post(
        "specialists/settings/get-tracking-manual-ncr-type",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateNcrPrices(params) {
    try {
      return await amgApi.post("/request-ncr/update-ncr-prices", params);
    } catch (error) {
      throw error;
    }
  }

  async getTrackingNcrPrices(params) {
    try {
      return await amgApi.post("/request-ncr/get-tracking-ncr-prices", params);
    } catch (error) {
      throw error;
    }
  }

  async getTrackingNcrFailedMotives(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-tracking-ncr-failed-motives",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getClassificationNcr(params) {
    try {
      const { data } = await amgApi.post(
        "/specialists/settings/get-classification-ncr",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getKeywords(params) {
    try {
      const data = await amgApi.post(
        "/specialists/settings/get-keywords",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async saveKeywords(params) {
    try {
      return await amgApi.post("/specialists/settings/save-keywords", params);
    } catch (error) {
      throw error;
    }
  }
  async updateKeywords(params) {
    try {
      return await amgApi.post("/specialists/settings/update-keywords", params);
    } catch (error) {
      throw error;
    }
  }

  async deleteKeyword(params) {
    try {
      return await amgApi.post("/specialists/settings/delete-keyword", params);
    } catch (error) {
      throw error;
    }
  }
}

export default new SettingsServiceSp();
