var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type"}},[_c('v-select',_vm._b({class:{ 'invalid-select': errors.length > 0 },attrs:{"disabled":_vm.disabledType},model:{value:(_vm.formExpense.type),callback:function ($$v) {_vm.$set(_vm.formExpense, "type", $$v)},expression:"formExpense.type"}},'v-select',_vm.typeScope.attr,false))],1)]}}])})],1),(_vm.openFromDashboardCeo)?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Check number"}},[_c('b-form-input',{class:{
              'border-danger': errors.length > 0,
            },model:{value:(_vm.formExpense.check_number),callback:function ($$v) {_vm.$set(_vm.formExpense, "check_number", $$v)},expression:"formExpense.check_number"}})],1)]}}],null,false,111981351)})],1):_vm._e(),(_vm.openFromDashboardCeo)?_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Check file"},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.formExpense.check_file)?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Preview'),expression:"'Preview'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer text-danger ml-1",attrs:{"icon":"FileIcon","size":"20"},on:{"click":function($event){return _vm.previewFile(_vm.formExpense.check_file)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Remove'),expression:"'Remove'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer text-danger ml-1",attrs:{"icon":"TrashIcon","size":"20"},on:{"click":_vm.removeFile}})],1):_vm._e()]},proxy:true}],null,true)},[_c('b-form-file',{class:{
              'border-danger': errors.length > 0,
            },model:{value:(_vm.formExpense.check_file),callback:function ($$v) {_vm.$set(_vm.formExpense, "check_file", $$v)},expression:"formExpense.check_file"}})],1)]}}],null,false,2999087648)})],1):_vm._e()],1),_vm._t("selectors-general"),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Attach File"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.formExpense.file)?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('amg-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Preview'),expression:"'Preview'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer text-danger ml-1",attrs:{"icon":"FilePdfIcon","size":"20"},on:{"click":function($event){return _vm.previewPdf(_vm.formExpense.file)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Remove'),expression:"'Remove'",modifiers:{"hover":true,"top":true}}],staticClass:"cursor-pointer text-danger ml-1",attrs:{"icon":"TrashIcon","size":"20"},on:{"click":_vm.removeFile}})],1):_vm._e()]},proxy:true}])},[_c('b-form-file',{attrs:{"accept":".pdf"},model:{value:(_vm.formExpense.file),callback:function ($$v) {_vm.$set(_vm.formExpense, "file", $$v)},expression:"formExpense.file"}})],1)],1)],1)],1),_vm._t("transaction-inputs")],2)}
var staticRenderFns = []

export { render, staticRenderFns }