<template>
  <div style="margin-right: 3px">
    <feather-icon
      :icon="status == 2 ? 'TriangleIcon' : 'CircleIcon'"
      size="13"
      :fill="colorStatus"
      :style="{ color: colorStatus }"
      v-b-tooltip.hover.top="tooltipStatus"
    />
    <span v-if="!fromBc">
      <span v-if="status == 1" class="space-status-icon">Active</span>
      <span v-if="status == 2" class="space-status-icon">Hold</span>
      <span v-if="status == 3" class="space-status-icon">Transition</span>
      <span v-if="status == 4" class="space-status-icon">Canceled</span>
      <span v-if="status == 5" class="space-status-icon">Loyal</span>
      <span v-if="status == 6" class="space-status-icon">Closed</span>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    status: { required: true, type: [Number, String] },
    fromBc: { type: Boolean, default: false },
  },
  data() {
    return {
      //
    };
  },
  computed: {
    colorStatus() {
      let selected;
      switch (this.status) {
        case 1:
        case "1":
          selected = "#00CC00";
          break;
        case 2:
        case "2":
          selected = "yellow";
          break;
        case 3:
        case "3":
          selected = "blue";
          break;
        case 4:
        case "4":
          selected = "red";
          break;
        case 5:
        case "5":
          selected = "red";
          break;
        case 6:
        case "6":
          selected = "red";
          break;
      }
      return selected;
    },

    tooltipStatus() {
      let selected;
      switch (this.status) {
        case 1:
        case "1":
          selected = "Active";
          break;
        case 2:
        case "2":
          selected = "Hold";
          break;
        case 3:
        case "3":
          selected = "Transition";
          break;
        case 4:
        case "4":
          selected = "Canceled";
          break;
        case 5:
        case "5":
          selected = "Loyal";
          break;
        case 6:
        case "6":
          selected = "Closed";
          break;
      }
      return selected;
    },
  },
  methods: {
    //
  },
  created() {
    //
  },
};
</script>
<style lang="scss" scoped>
.mr-05 {
  margin-right: 0.5rem;
}
</style>