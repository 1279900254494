<template>
  <b-container>
    <ValidationObserver ref="cform">
      <b-row>
        <b-col md="8" class="border-right-separator">
          <b-row>
            <b-col>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-form-group label="Original Creditor:">
                  <vue-autosuggest
                    ref="autocomplete"
                    :class="{
                      'select__creditor--error': errors[0] || !creditor.id,
                    }"
                    :suggestions="filteredOptions"
                    :get-suggestion-value="getSuggestionValue"
                    :input-props="{
                      id: 'autosuggest__input',
                      class: 'form-control',
                      disabled: disableInputs,
                    }"
                    @input="onInputChange"
                    @selected="selectHandler"
                    v-model="nameCreditor"
                  >
                    <template slot-scope="{ suggestion }">
                      <span class="my-suggestion-item cursor-pointer">{{
                        suggestion.item.value
                      }}</span>
                    </template>
                  </vue-autosuggest>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col>
              <b-form-group label="Account Number:">
                <b-form-input
                  v-model="creditor.account"
                  :disabled="disableInputs"
                  v-if="creditor.type_id != 1"
                />
                <b-form-input
                  v-model="creditor.account"
                  :disabled="disableInputs"
                  v-else
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Current Creditor:">
                <vue-autosuggest
                  ref="current_creditor"
                  :suggestions="filteredOptionsCurrenCreditor"
                  :get-suggestion-value="getSuggestionValueCurrentCreditor"
                  :input-props="{
                    id: 'autosuggest__input2',
                    class: 'form-control',
                    disabled: disableInputs,
                  }"
                  @input="onInputChangeCurrentCreditor"
                  @selected="selectHandlerCurrenCreditor"
                  v-model="currentCreditorName"
                >
                  <template slot-scope="{ suggestion }">
                    <span class="my-suggestion-item cursor-pointer">{{
                      suggestion.item.value
                    }}</span>
                  </template>
                </vue-autosuggest>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Account Number:">
                <b-form-input
                  :disabled="disableInputs"
                  v-model="creditor.current_creditor_account_number"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Monthly payment:">
                <money
                  id="amount"
                  v-model="creditor.monthly"
                  v-bind="vMoney"
                  class="form-control"
                  :disabled="disable.all"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Initial Balance:">
                <money
                  v-model="creditor.balance"
                  v-bind="vMoney"
                  class="form-control"
                  :disabled="disable.all"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Monthly behind:">
                <b-form-input
                  v-model="creditor.months"
                  :disabled="!in30Days || disableInputs"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Actual Balance:">
                <money
                  id="amount"
                  v-model="creditor.actual_balance"
                  v-bind="vMoney"
                  class="form-control"
                  :disabled="disableInputs"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col />
            <b-col>
              <b-form-checkbox
                v-model="creditor.collection_ds"
                value="1"
                unchecked-value="0"
                :disabled="disableInputs"
              >
                <span class="text-primary">Collection</span>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col cols="3">
              <b-form-group label="Interest rate:">
                <b-form-input
                  :value="creditor.interest + ' %'"
                  :disabled="disable.all"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Phase:">
                <b-form-select
                  v-model="creditor.account_phase_id"
                  :options="phases"
                  value-field="id"
                  text-field="description"
                  :disabled="
                    disabledPhase === 6 || disabledPhase === 9 || disableInputs
                  "
                  @change="modalPhase(creditor.account_phase_id)"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Type:">
                <b-form-select
                  v-model="creditor.type_id"
                  :options="types"
                  value-field="id"
                  text-field="value"
                  :disabled="disableInputs"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="4">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Recovery:">
                <b-input-group>
                  <v-select
                    v-if="
                      currentCreditorIdIsValid && lastOfferCreditorIsEditable
                    "
                    class="select__creditor"
                    placeholder="Select a number..."
                    v-model="currentCreditorPhoneNumber"
                    :disabled="disableInputs"
                    :clearable="false"
                    label="phone"
                    :options="currentCreditorPhoneNumbers"
                    :reduce="(option) => option.phone_id"
                  />
                  <feather-icon
                    v-if="
                      currentCreditorIdIsValid && lastOfferCreditorIsEditable
                    "
                    class="text-primary cursor-pointer my-auto"
                    icon="PlusIcon"
                    size="22"
                    @click="statusPhone = true"
                  />
                  <b-form-input
                    v-else
                    v-model="currentCreditor.phone"
                    :disabled="true"
                  />
                </b-input-group>
                <!-- <div v-for="(item, index) in creditor.recovery" :key="index">
                  {{
                    "- " + typeof item.phone === "undefined" ? "" : item.phone
                  }}
                </div>
                <span v-if="creditor.recovery.length === 0"> - </span> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Address:">
                <b-form-input
                  :value="
                    creditor.mailing_address === null
                      ? '-'
                      : creditor.mailing_address
                  "
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Settlement:">
                <b-form-input
                  :value="
                    creditor.settlement === null
                      ? '-'
                      : creditor.settlement + ' %'
                  "
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Memo:">
                <b-form-textarea
                  v-model="creditor.memo"
                  :disabled="disableInputs"
                />
              </b-form-group>
              <span v-if="creditor.updated_memo !== ''"
                ><span>Updated by:</span> <br />
                <span>{{ creditor.updated_memo }}</span>
              </span>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col cols="4" class="mt-1" v-if="isCreditCard && isMonthsZero">
              <b-form-group label="Turn off automatic payments:">
                <div>
                  <label style="color: #e26969; margin-right: 1rem">OFF</label>
                  <label class="switch">
                    <b-form-checkbox
                      v-model="creditor.has_automatic_payments"
                      value="1"
                      switch
                      type="checkbox"
                      :disabled="disableInputs"
                    />
                  </label>
                  <label style="color: #56bec4; margin-left: 0.6rem">ON</label>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="mt-1" cols="4" v-if="isReposition">
              <b-form-group label="Has Car:" class="text-left">
                <div>
                  <label style="color: #e26969; margin-right: 1rem">NO</label>
                  <label class="switch">
                    <b-form-checkbox
                      v-model="creditor.has_car"
                      value="1"
                      switch
                      type="checkbox"
                      :disabled="disableInputs"
                    />
                  </label>
                  <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="mt-1" cols="4" v-if="isReposition && !hasCar">
              <b-form-group label="Was Auctioned:" class="text-left">
                <div>
                  <label style="color: #e26969; margin-right: 1rem">NO</label>
                  <label class="switch">
                    <b-form-checkbox
                      v-model="creditor.was_auctioned"
                      value="1"
                      switch
                      type="checkbox"
                      :disabled="disableInputs"
                    />
                  </label>
                  <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="mt-1" cols="4" v-if="isPersonalLoan">
              <b-form-group label="Has Warranty:">
                <div>
                  <label style="color: #e26969; margin-right: 1rem">NO</label>
                  <label class="switch">
                    <b-form-checkbox
                      v-model="creditor.has_warranty"
                      switch
                      value="1"
                      type="checkbox"
                      :disabled="disableInputs"
                    />
                  </label>
                  <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="mt-1" cols="4" v-if="isPersonalLoan && hasWarranty">
              <b-form-group label="Select Motives:">
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <v-select
                    v-model="creditor.motives"
                    :class="{ 'bri-select': errors[0] }"
                    :options="personalLoanMotives"
                    :reduce="(value) => value.id"
                    label="value"
                    :disabled="disableInputs"
                  />
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col class="mt-1" cols="4" v-if="isCT">
              <b-form-group label="Judgment Exist:">
                <div>
                  <label style="color: #e26969; margin-right: 1rem">NO</label>
                  <label class="switch">
                    <b-form-checkbox
                      v-model="creditor.judgment_exists"
                      switch
                      value="1"
                      type="checkbox"
                      :disabled="disableInputs"
                    />
                  </label>
                  <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
                </div>
              </b-form-group>
            </b-col>

            <b-col class="mt-1" cols="4" v-if="isCT && judgmentExists">
              <b-form-group label="Judgment Type:">
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <v-select
                    v-model="creditor.judgment_type"
                    :class="{ 'bri-select': errors[0] }"
                    :options="judgmentOptions"
                    :reduce="(value) => value.id"
                    label="value"
                    :disabled="disableInputs"
                  />
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="4" class="mt-1" v-if="isCollection">
              <b-form-group label="Do you know which Collection it is in? :">
                <div>
                  <label style="color: #e26969; margin-right: 1rem">NO</label>
                  <label class="switch">
                    <b-form-checkbox
                      v-model="creditor.has_collection_knowledge"
                      switch
                      type="checkbox"
                      value="1"
                      :disabled="disableInputs"
                    />
                  </label>
                  <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="4" v-if="isCollection && hasCollectionKnowledge">
              <b-form-group label="Collection Name:">
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <vue-autosuggest
                    ref="autocomplete2"
                    id="collectionName"
                    v-model="creditor.collection_name"
                    :suggestions="filteredOptions2"
                    :get-suggestion-value="getSuggestionValue2"
                    :input-props="{
                      id: 'autosuggest__input',
                      class: ['form-control ', errors[0]],
                      placeholder: 'Select',
                    }"
                    :disabled="disableInputs"
                    @input="onInputChange2"
                    @selected="selectHandler2"
                  >
                    <template slot="before-input">
                      <div
                        class="lds-dual-ring"
                        v-show="creditorsLoading2"
                      ></div>
                    </template>
                    <template slot-scope="{ suggestion }">
                      <span class="my-suggestion-item">{{
                        suggestion.item.value
                      }}</span>
                    </template>
                  </vue-autosuggest>
                  <!-- 
                  <b-form-input
                    v-model="creditor.collection_name"
                    :style="errors[0] ? 'border-color:red' : ''"
                  />
                  <div v-if="errors[0]" class="invalid-feedback">
                    The field {{ errors[0] }}
                  </div> -->
                </ValidationProvider>
              </b-form-group>
            </b-col>

            <b-col cols="4" v-if="isOthers">
              <b-form-group label="New Debt Type :">
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <b-form-input
                    v-model="creditor.new_debt_type"
                    :style="errors[0] ? 'border-color:red' : ''"
                    :disabled="disableInputs"
                  />
                  <div v-if="errors[0]" class="invalid-feedback">
                    The field {{ errors[0] }}
                  </div>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="4" class="mt-1">
              <b-form-group label="Unsecured Type">
                <div>
                  <label style="color: #e26969; margin-right: 1rem">OFF</label>
                  <label class="switch">
                    <b-form-checkbox
                      v-model="creditor.unsecured"
                      switch
                      type="checkbox"
                      value="1"
                      unchecked-value="0"
                      :disabled="disable.all"
                    />
                  </label>
                  <label style="color: #56bec4; margin-left: 0.6rem">ON</label>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              cols="4"
              class="mt-1"
              v-if="isCeo || isSupervisor || isAdvisor"
            >
              <b-form-group label="POA SENT">
                <div>
                  <label style="color: #e26969; margin-right: 1rem">NO</label>
                  <label class="switch">
                    <b-form-checkbox
                      v-model="creditor.poa"
                      :disabled="!(isSupervisor || isAdvisor)"
                      switch
                      type="checkbox"
                      value="1"
                      unchecked-value="0"
                    />
                  </label>
                  <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="!loading">
              <bills-select
                @bills="creditor.bills = $event"
                :currentBills="creditor.bills"
                :disabled="disableInputs"
              ></bills-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </ValidationObserver>
    <modal-edit-phase-creditor
      v-if="modalPhaseCreditorOn"
      :title="nameCreditor"
      :id-creditor="idcreditor"
      :id-phase="phaseToSend"
      @actFunEmitEditCreator="saveEditCreditor"
      @close="closeModalEditPhaseCreditor"
      @reloadAmountSaved="reloadAmountSaved"
    />
    <new-phone-modal
      v-if="statusPhone"
      :creditors_id="currentCreditor.id"
      @closing="closeModalPhone"
      @close="closeModalPhone"
    />
  </b-container>
</template>

<script>
import moment from "moment";
import { VueAutosuggest } from "vue-autosuggest";
import ModalEditPhaseCreditor from "@/views/commons/components/clients/dashboard/options/acc-module/modals/ModalEditPhaseCreditor.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import NewPhoneModal from "@/views/debt-solution/views/settlement-offer/NewPhoneModal.vue";
import { mapGetters } from "vuex";
import BillsSelect from "@/views/crm/views/sales-made/components/modals/services/debt-solution/components/BillsSelect.vue";
export default {
  components: {
    VueAutosuggest,
    ModalEditPhaseCreditor,
    NewPhoneModal,
    BillsSelect,
  },
  props: {
    idcreditor: null,
    disableInputs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    in30Days() {
      let day = this.dateOfCreation;
      // day.setDate(day.getDate() + 30);
      day = moment(day).add(30, "days");
      return day >= new Date();
    },
    isMonthsZero() {
      return (
        this.creditor.months == 0 &&
        this.creditor.months !== null &&
        this.creditor.months !== ""
      );
    },
    isCreditCard() {
      return this.creditor.type_id == 1;
    },
    isReposition() {
      return this.creditor.type_id == 2;
    },
    isPersonalLoan() {
      return this.creditor.type_id == 3;
    },
    isCT() {
      return this.creditor.type_id == 6;
    },
    isCollection() {
      return this.creditor.type_id == 8;
    },
    isOthers() {
      return this.creditor.type_id == 9;
    },

    hasCar() {
      return this.creditor.has_car == 1;
    },

    hasWarranty() {
      return this.creditor.has_warranty == 1;
    },

    judgmentExists() {
      return this.creditor.judgment_exists == 1;
    },
    hasCollectionKnowledge() {
      return this.creditor.has_collection_knowledge == 1;
    },
    isAdvisor() {
      return this.currentUser.role_id == 3;
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isInThisMonth() {
      return (
        this.dateOfCreation >= this.startOfMonth &&
        this.creditor.created_at <= this.endOfMonth
      );
    },
    existingOfferCreditorIdIsValid() {
      return !(
        this.currentCreditorIdIsValid && !this.newCurrentCreditorIdIsValid
      );
    },
    originalCreditorIdIsValid() {
      return !!this.creditor.id;
    },
    lastOfferCreditorIsEditable() {
      return this.lastOfferStatus == "PENDING";
    },
    currentCreditorIdIsValid() {
      return !!this.creditor.current_credit_id;
    },
    newCurrentCreditorIdIsValid() {
      return !!this.currentCreditor.id;
    },
    currentCreditorAccoutNumberIsValid() {
      return !!this.creditor.current_creditor_account_number;
    },
    thereIsCreditorNumbers() {
      return this.creditorNumbers.length > 0;
    },
  },
  data() {
    return {
      maxCardLength: 1,
      personalLoanMotives: [
        { id: 1, value: "Home" },
        { id: 2, value: "Car" },
        { id: 3, value: "Others" },
      ],
      judgmentOptions: [
        { id: 1, value: "WG" },
        { id: 2, value: "Lien" },
        { id: 3, value: "Bank Levy" },
        { id: 4, value: "None" },
      ],
      currentCreditorPhoneNumber: {},
      currentCreditorPhoneNumbers: [],
      modalPhaseCreditorOn: false,
      filteredOptions: [],
      filteredOptions2: [],
      creditorsLoading2: false,
      filteredOptionsCurrenCreditor: [],
      disabledPhase: "",
      creditor: {
        id: "",
        recovery: "",
        has_automatic_payments: "",
        has_car: "",
        was_auctioned: "",
        has_warranty: "",
        motives: "",
        judgment_exists: "",
        judgment_type: "",
        has_collection_knowledge: "",
        has_collection_knowledge: "",
        collection_name: "",
        new_debt_type: "",
        poa: 0,
        bills: [],
      },
      loading: true,
      currentCreditor: {
        id: null,
        phone: null,
      },
      lastOfferStatus: null,
      nameCreditor: "",
      currentCreditorName: "",
      disable: {
        all: true,
        save: false,
      },
      types: [],
      phases: [],
      idCreditor: "",
      dateOfCreation: "",
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
      startOfMonth: "",
      endOfMonth: "",
      statusesOptions: [
        {
          value: 1,
          text: "LATE PAYMENT(LP)",
        },
        {
          value: 2,
          text: "CHARGE OFF (CO)",
        },
        {
          value: 3,
          text: "READY TO SETTLE (RTS)",
        },
      ],
      phaseToSend: "",
      phaseSaved: "",
      statusPhone: false,
      card: {
        number1: "",
        number2: "",
        number3: "",
        number4: "",
      },
      collectionName: null,
      collectionNameId: null,
    };
  },
  watch: {
    "creditor.type_id": {
      handler(value) {
        if (this.creditor.type_id != 1) {
          this.creditor.account = this.creditor.account.replace(/-/g, "");
        }
      },
    },
  },

  async created() {
    this.addPreloader();
    await Promise.all([
      this.allTypes(),
      this.getDataCreditor(),
      this.getAccountPhases(),
    ]);
    const date = new Date();
    this.startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    this.endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.dateOfCreation = new Date(this.creditor.created_at);
    this.removePreloader();
  },
  methods: {
    fillWithAstreisks(index) {
      if (index === 1) {
        this.card.number1 = this.card.number1.padEnd(4, "*");
      }
      if (index === 2) {
        this.card.number2 = this.card.number2.padEnd(4, "*");
      }
      if (index === 3) {
        this.card.number3 = this.card.number3.padEnd(4, "*");
      }
      if (index === 4) {
        this.card.number4 = this.card.number4.padEnd(this.maxCardLength, "*");
        this.card.number1 = this.card.number1.padEnd(4, "*");
        this.card.number2 = this.card.number2.padEnd(4, "*");
        this.card.number3 = this.card.number3.padEnd(4, "*");
      }
    },

    reloadAmountSaved() {
      this.$emit("reloadAmountSaved");
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.value;
    },
    getSuggestionValue2(suggestion) {
      return suggestion.item.value;
    },
    getSuggestionValueCurrentCreditor(suggestion) {
      return suggestion.item.value;
    },
    closeModalEditPhaseCreditor(num) {
      this.modalPhaseCreditorOn = false;
      if (num === 1) this.$emit("close");
      this.creditor.account_phase_id = this.phaseSaved;
    },
    async modalPhase(phase) {
      if ([6, 9].includes(phase)) {
        const providerFieldsAreValid = await this.$refs.cform.validate();
        if (!providerFieldsAreValid) {
          this.modalPhaseCreditorOn = false;
          this.creditor.account_phase_id = this.phaseSaved;
          this.showWarningSwal("PLEASE", " fill in all fields");
        } else {
          this.modalPhaseCreditorOn = phase === 6 || phase === 9;
        }
        this.phaseToSend = phase;
      }
    },
    onInputChange(text) {
      this.creditor.id = null;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        return;
      }
      amgApi
        .post(`/sales-made/debt-solution/search-creditors?q=${text}`)
        .then((response) => {
          if (response.status === 200) {
            this.filteredOptions = [{ data: [...response.data] }];
            if (this.filteredOptions.length === 0) {
              this.creditor.id = null;
            }
          }
        });
    },
    onInputChange2(text) {
      this.collectionName = null;
      this.creditorsLoading2 = true;
      if (text === "" || text === undefined) {
        this.filteredOptions2 = [];
        this.creditorsLoading2 = false;
        return;
      }
      amgApi
        .post(`/sales-made/debt-solution/search-creditors?q=${text}`)
        .then((response) => {
          if (response.status === 200) {
            this.filteredOptions2 = [{ data: [...response.data] }];
            this.creditorsLoading2 = false;
          }
        });
    },
    onInputChangeCurrentCreditor(text) {
      this.currentCreditor.id = null;
      if (text === "" || text === undefined) {
        this.filteredOptionsCurrenCreditor = [];
        return;
      }
      amgApi
        .post(`/sales-made/debt-solution/search-creditors?q=${text}`)
        .then((response) => {
          if (response.status === 200) {
            this.filteredOptionsCurrenCreditor = [{ data: [...response.data] }];
          }
        });
    },
    selectHandler(value) {
      this.creditor.id = value.item.id;
      this.nameCreditor = value.item.value;
      this.creditor.poa = 0;
    },
    selectHandler2(value) {
      this.collectionName = value.item.value;
      this.collectionNameId = value.item.id;
    },
    async selectHandlerCurrenCreditor(value) {
      this.currentCreditor.id = value.item.id;
      this.currentCreditorName = value.item.value;
      await this.getCreditorPhoneNumbers(value.item.id);
      this.showInfoSwal(
        "You have updated the current creditor, please consider update its phone number",
        "Info"
      );
    },
    async allTypes() {
      try {
        const response = await amgApi.get(
          "/sales-made/debt-solution/get-debts-types"
        );
        if (response.status === 200) {
          this.types = response.data;
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    async getDataCreditor() {
      try {
        const response = await ClientsOptionsServices.getDataEditCreditor({
          id: this.idcreditor,
        });
        if (response.status === 200) {
          this.creditor = response.data[0];
          this.creditor.id = response.data[0].credit_id;
          this.creditor.current_creditor_account_number =
            this.creditor.current_creditor_account_number == "null"
              ? null
              : this.creditor.current_creditor_account_number;

          this.currentCreditor.id = response.data[0].current_credit_id;
          this.currentCreditor.phone =
            response.data[0].current_creditor_phone_number;

          this.putPhonesInArray(response.data[0].recovery);

          this.nameCreditor = this.creditor.credit_name;
          this.currentCreditorName = this.creditor.current_credit_name;
          this.disabledPhase = this.creditor.account_phase_id;
          this.phaseSaved = this.creditor.account_phase_id;
          this.lastOfferStatus = this.creditor.last_offer_status;
          this.creditor.has_automatic_payments =
            response.data[0].has_automatic_payments;
          this.creditor.has_car = response.data[0].has_car;
          this.creditor.was_auctioned = response.data[0].was_auctioned;
          this.creditor.has_warranty = response.data[0].has_warranty;
          this.creditor.motives = response.data[0].motives;
          this.creditor.judgment_exists = response.data[0].judgment_exists;
          this.creditor.judgment_type = response.data[0].judgment_type;
          this.creditor.has_collection_knowledge =
            response.data[0].has_collection_knowledge;
          this.creditor.collection_name = response.data[0].collection_name;
          this.creditor.new_debt_type = response.data[0].new_debt_type;
          this.creditor.poa = response.data[0].poa;
          this.creditor.bills = response.data[0].bills;
          this.creditor.type_id = response.data[0].type_id;
          if (response.data[0].card_number) {
            this.card.number1 = response.data[0].card_number.slice(0, 4);
            this.card.number2 = response.data[0].card_number.slice(4, 8);
            this.card.number3 = response.data[0].card_number.slice(8, 12);
            this.card.number4 = response.data[0].card_number.slice(12, 16);
          }

          this.loading = false;
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    async saveEditCreditor(num = 0) {
      const providerFieldsAreValid = await this.$refs.cform.validate();
      if (num == 1 && !providerFieldsAreValid) {
        this.modalPhaseCreditorOn = false;
        this.creditor.account_phase_id = this.phaseSaved;
        this.removePreloader();
        this.showWarningSwal("PLEASE", " fill in all fields");
      }
      if (!providerFieldsAreValid || !this.originalCreditorIdIsValid) return;

      try {
        let confirmed = "";
        if (num !== 1) confirmed = await this.showConfirmSwal();
        if (confirmed.isConfirmed || num === 1) {
          this.addPreloader();
          const response = await ClientsOptionsServices.updateCreditor({
            id_user: this.currentUser.user_id,
            creditorSelectId: this.creditor.id,
            creditor_id: this.idcreditor,
            current_creditor_id: this.currentCreditor.id,
            current_creditor_account_number:
              this.creditor.current_creditor_account_number,
            current_creditor_phone_number:
              this.currentCreditorPhoneNumbers[this.currentCreditorPhoneNumber]
                ?.phone || this.currentCreditorPhoneNumber.phone,
            original_creditor: this.creditor.credit_id,
            accountnumber: this.creditor.account,
            phase: this.creditor.account_phase_id,
            collection: this.creditor.collection_ds,
            actualbalance: this.creditor.actual_balance,
            memo: this.creditor.memo,
            poa: this.creditor.poa,
            type: this.creditor.type_id,
            has_automatic_payments:
              this.isCreditCard && this.isMonthsZero
                ? this.creditor.has_automatic_payments
                : null,
            has_car: this.isReposition ? this.creditor.has_car : null,
            was_auctioned:
              this.isReposition && !this.creditor.has_car
                ? this.creditor.was_auctioned
                : null,
            judgment_exists: this.isCT ? this.creditor.judgment_exists : null,
            has_collection_knowledge: this.isCollection
              ? this.creditor.has_collection_knowledge
              : null,
            new_debt_type: this.isOthers ? this.creditor.new_debt_type : null,
            judgment_type:
              this.isCT && this.creditor.judgment_exists
                ? this.creditor.judgment_type
                : null,
            has_warranty: this.isPersonalLoan
              ? this.creditor.has_warranty
              : null,
            collection_name:
              this.isCollection && this.creditor.has_collection_knowledge
                ? this.collectionName
                : null,
            collection_name_id:
              this.isCollection && this.creditor.has_collection_knowledge
                ? this.collectionNameId
                : null,
            card_number: this.isCreditCard
              ? this.card.number1 +
                this.card.number2 +
                this.card.number3 +
                this.card.number4
              : null,
            month: this.creditor.months,
            motives:
              this.isPersonalLoan && this.creditor.has_warranty
                ? this.creditor.motives
                : null,
            bills: this.creditor.bills,
          });
          if (response.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            this.$emit("reload");
            this.$emit("close");
            this.$emit("reloadAmountSaved");
          }
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
        console.log(error);
      }
    },
    async getAccountPhases() {
      try {
        const response = await ClientsOptionsServices.getAccountPhase({});
        if (response.status === 200) {
          this.phases = response.data;
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    closeModalPhone(phone) {
      this.statusPhone = false;
      if (!phone) return;
      const currentCreditorPhoneNumbersLength =
        this.currentCreditorPhoneNumbers.length;
      this.currentCreditorPhoneNumber = {
        phone_id: currentCreditorPhoneNumbersLength,
        phone,
      };
      this.currentCreditorPhoneNumbers = [
        ...this.currentCreditorPhoneNumbers,
        this.currentCreditorPhoneNumber,
      ];
    },
    async getCreditorPhoneNumbers(id_creditor) {
      const res = await amgApi.post(
        "sales-made/debt-solution/search-creditor",
        {
          q: id_creditor,
        }
      );
      this.putPhonesInArray(res.data[0].contact_number);
    },
    putPhonesInArray(creditorPhoneNumbersList) {
      this.currentCreditorPhoneNumbers = !!creditorPhoneNumbersList
        ? JSON.parse(creditorPhoneNumbersList).map(({ phone }, index) => {
            return {
              phone_id: index,
              phone,
            };
          })
        : [];

      const currentCreditorIndexPhoneNumber =
        this.currentCreditorPhoneNumbers.findIndex(
          ({ phone }) => phone == this.currentCreditor?.phone
        );

      this.currentCreditorPhoneNumber =
        currentCreditorIndexPhoneNumber >= 0
          ? {
              phone_id: currentCreditorIndexPhoneNumber,
              phone: this.currentCreditor?.phone,
            }
          : {
              phone_id: !!this.currentCreditorPhoneNumbers[0]?.phone ? 0 : null,
              phone: this.currentCreditorPhoneNumbers[0]?.phone,
            };

      if (this.currentCreditorPhoneNumbers.length === 0) {
        // this.currentCreditorPhoneNumber = { phone_id: null, phone: null };
      }
    },
    activeFocus: function (index, max) {
      let inputValue = this.$refs?.[`input-${index}`];
      if (inputValue.value.length === max - 1) {
        const nextElement = this.$refs?.[`input-${index + 1}`];
        if (nextElement) nextElement.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select__creditor--error {
  border: 1px solid #fc424a;
  border-radius: 0 5px 5px 0;
}

.select__creditor--error {
  border: 1px solid #fc424a;
  border-radius: 0 5px 5px 0;
}

.select__creditor {
  flex: 1;
}
.dark-layout {
  .border-right-separator {
    border-right: 1px solid #747476;
  }
}
.border-right-separator {
  border-right: 1px solid #e0e0e0;
}
</style>
