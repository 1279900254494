import store from "@/store";
import router from "@/router/index";
import Vue from "vue";
import moment from "moment";

const RecurrencyTaskForTracking = () => {
    window.socket.bind("court-info-recurrency-tasks-tracking", async (data) => {
        const currentUserId = store.state.auth.currentUser.user_id;
        const tasks = data.tasks;

        tasks.map(async (task) => {
            if (currentUserId !== task.manage_by_id) return;
            if (router.currentRoute.matched[0]?.meta?.module !== 5) return;

            const datetime = moment(task.date + " " + task.hour).format("MM/DD/YYYY HH:mm");
            const original_hour = task.original_hour ? moment(task.original_hour).format("MM/DD/YYYY HH:mm") : time;
            const word = task._is_client ? 'Client' : 'Lead';

            const alert = `
                <div style="background-color: transparent;">
                    <img src="/assets/images/icons/swal/200w.webp" style="margin-bottom: 10px;" alt="alert-info-image">
                </div>
                <div><h2 style="font-weight: 600;">REMINDER:</h2></div>
                <div style="text-align: center;">
                    <br>
                    <div class="text-warning" style="font-size: 16px; font-weight: 600;">Date Original (CA): ${original_hour}</div>
                    <div class="text-success" style="font-size: 16px; font-weight: 600;">Date Alert: ${datetime}</div>
                    <br>
                    <div style="margin-bottom: 5px"><span>Review the following task!</span></div>
                    <div style="font-weight: bolder;">Case #: ${task.case_number}</div>
                    <div style="font-weight: bolder;">${word}: ${task.lead_or_client_name}</div>
                    <div style="font-weight: bolder;">Commentary: ${task.commentary}</div>
            `;

            const response = await Vue.swal.fire({
                html: alert,
                timer: 290000,
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                showConfirmButton: true,
            })

            if (response.value) {
                let route = "/debt-solution/court-info/success-reminder-tracking-case";

                let params = {
                    is_client: task._is_client,
                    case_id: task.case_id,
                    manage_by_id: task.manage_by_id,
                }

                if (task._is_client) {
                    params.client_account_id = task.lead_or_client_id;
                } else {
                    params.lead_id = task.lead_or_client_id;
                }

                const { data, status } = await window.amgApi.post(route, params)

                if (status === 200) {
                    await window.amgApi.post("/commons/close-all-swal", {
                        to_id: currentUserId,
                    })
                }
            }
        })
    })
}



export { RecurrencyTaskForTracking };
