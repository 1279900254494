import store from "@/store"
import Vue from "vue"

const updateSalePermission = () => {
  window.socket.bind("update-sale-permission", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id
    if (sessionId == data.to_id) {
      store.dispatch("auth/updateCurrentUserData", {
        email: store.state.auth.currentUser.email,
      })

      if (data.level == 1) {
        Vue.swal.fire({
          icon: "success",
          html: `<h2 style="font-weight: 600;">Se te ha asignado permiso para vender con el nivel Begginer</h2>`,
        })
      }
    }
  })
}
export default updateSalePermission
