// "/management/companies/"
export default [
  {
    path: "companies",
    name: "companies-main-management",
    redirect: { name: "companies-management" },
    component: () =>
      import(
        /* webpackChunkName: "ManaCompaniesMain" */ "@/views/management/views/companies/CompaniesMain.vue"
      ),

    meta: {
      pageTitle: "Companies",
      breadcrumb: [
        {
          text: "Companies",
        },
      ],
    },
    children: [
      {
        path: "list",
        component: () =>
          import(
            /* webpackChunkName: "ManaCompaniesList" */ "@/views/management/views/companies/companies-module/CompaniesList.vue"
          ),
        name: "companies-management",
        redirect: { name: "companies-management-us" },
        meta: {
          pageTitle: "Companies",
          breadcrumb: [
            {
              text: "list",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "usa",
            component: () =>
              import(
                /* webpackChunkName: "ManaCompanyCreate" */ "@/views/management/views/companies/companies-module/create-company/ListCompanies/CompaniesTable.vue"
              ),
              name: "companies-management-us",
              meta: {
              pageTitle: "Companies",
              breadcrumb: [
                {
                  text: "US",
                  active: true,
                },
              ],
              countryCode: "US",
            },
          },
          {
            path: "pe",
            component: () =>
              import(
                /* webpackChunkName: "ManaCompanyCreate" */ "@/views/management/views/companies/companies-module/create-company/ListCompanies/CompaniesTable.vue"
              ),
              name: "companies-management-pe",
              meta: {
              pageTitle: "Companies",
              breadcrumb: [
                {
                  text: "PE",
                  active: true,
                },
              ],
              countryCode: "PE",
            },
          },
        ]
      },
      {
        path: "create",
        component: () =>
          import(
            /* webpackChunkName: "ManaCompanyCreate" */ "@/views/management/views/companies/companies-module/create-company/CreateCompany.vue"
          ),
        name: "create-company",
        meta: {
          pageTitle: "Create",
          breadcrumb: [
            {
              text: "create",
              active: true,
            },
          ],
        },
      },
      {
        path: "edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "ManaCompanyEdit" */ "@/views/management/views/companies/companies-module/create-company/CreateCompany.vue"
          ),
        name: "edit-company",
        meta: {
          pageTitle: "Edit",
          breadcrumb: [
            {
              text: "edit",
              active: true,
            },
          ],
        },
      },
      {
        path: "dashboard/:id",
        component: () =>
          import(
            /* webpackChunkName: "ManaDashboard" */ "@/views/management/views/companies/dashboard/DashboardGeneral.vue"
          ),
        redirect: { name: "dashboard-company" },
        name: "dashboard",
        meta: {
          pageTitle: "Dashboard",
          breadcrumb: [
            {
              text: "dashboard",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "ManaDashboardMain" */ "@/views/management/views/companies/dashboard/dashboard-module/Dashboard.vue"
              ),
            name: "dashboard-company",
            meta: {
              pageTitle: "Dashboard",
              breadcrumb: [
                {
                  text: "dashboard",
                  active: true,
                },
              ],
            },
          },
          {
            path: "bank-accounts/:id",
            component: () =>
              import(
                /* webpackChunkName: "ManaDashBankAccountList" */ "@/views/management/views/companies/dashboard/bank-accounts-module/BankAccountList.vue"
              ),
            name: "bankAccount-company",
            meta: {
              pageTitle: "Bank Account",
              breadcrumb: [
                {
                  text: "Bank Account",
                  active: true,
                },
              ],
            },
          },
          {
            path: "categories/:id",
            component: () =>
              import(
                /* webpackChunkName: "ManaDashCategoriesCom" */ "@/views/management/views/companies/dashboard/categories-module/CategoriesCom.vue"
              ),
            name: "category-company",
            meta: {
              pageTitle: "Categories",
              breadcrumb: [
                {
                  text: "Categories",
                  active: true,
                },
              ],
            },
          },
          {
            path: "incomes/:id",
            component: () =>
              import(
                /* webpackChunkName: "ManaDashIncomes" */ "@/views/management/views/companies/dashboard/incomes-module/Incomes.vue"
              ),
            name: "incomes-company",
            meta: {
              pageTitle: "Incomes",
              breadcrumb: [
                {
                  text: "Incomes",
                  active: true,
                },
              ],
            },
          },
          {
            path: "schedule-transfer/:id",
            name: "schedule-transfer-company",
            component: () =>
              import(
                /* webpackChunkName: "ManaDashScheduleTransfer" */ "@/views/management/views/companies/dashboard/schedule-transfer-module/ScheduleTransfer.vue"
              ),
            meta: {
              pageTitle: "Schedule Transfer",
              breadcrumb: [
                {
                  text: "Schedule Transfer",
                  active: true,
                },
              ],
            },
          },
          {
            path: "expenses/:id",
            component: () =>
              import(
                /* webpackChunkName: "ManaDashExpenses" */ "@/views/management/views/companies/dashboard/expenses-module/Expenses.vue"
              ),
            name: "category-expenses",
            meta: {
              pageTitle: "Expenses",
              breadcrumb: [
                {
                  text: "Expenses",
                  active: true,
                },
              ],
            },
          },
          {
            path: "files/:id",
            component: () =>
              import(
                /* webpackChunkName: "ManaDashCompaniesFiles" */ "@/views/management/views/companies/dashboard/files-module/CompaniesFiles"
              ),
            name: "companies-files",
            meta: {
              pageTitle: "Files",
              breadcrumb: [
                {
                  text: "Files",
                  active: true,
                },
              ],
            },
          },
          {
            path: "profit-loss/:id",
            component: () =>
              import(
                /* webpackChunkName: "ManaDashProfitAndLoss" */ "@/views/management/views/companies/dashboard/profite-loss-module/ProfitAndLoss.vue"
              ),
            name: "profit-loss",
            meta: {
              pageTitle: "Profit & Loss",
              breadcrumb: [
                {
                  text: "Profit & Loss",
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },
]
