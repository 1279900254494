<template>
  <div>
    <div class="list-pdf-container">
      <b-row class="mt-2">
        <template v-for="(item, index) in pdfList">
        <b-col
          class="mb-2"
          xxl="3"
          xl="4"
          lg="6"
          md="12"
          sm="12"
          cols="12"
          v-if="typeof item.url == 'string'"
          :key="index"
        >
          <div class="ci-container">
            <div v-show="item.status === 1" class="container-pdf">
              <v-pdf
                :src="item.url"
                @progress="item.status = $event"
                class="pdf-preview"
              />
            </div>
            <b-skeleton-img
              v-if="item.status != 1"
              height="300px"
            ></b-skeleton-img>
            <div class="document-title">{{ item.name }}</div>
            <div class="ci-pdf-list-footer">
              <div class="ci-footer-content">
                <div
                  class="ci-container-button-icon"
                  @click="openPreview(item.url, item.name)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="ci-button-icon cursor-pointer"
                    v-b-tooltip.hover
                    title="Preview"
                  ></feather-icon>
                </div>
                <div class="ci-container-button-icon">
                  <b-link
                    size="sm"
                    :href="item.url"
                    target="_blank"
                    :disabled="!item.url"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="ci-button-icon"
                      v-b-tooltip.hover
                      title="Download"
                    ></feather-icon>
                  </b-link>
                </div>
                <div
                  class="ci-container-button-icon"
                  @click="deleteCiCaseFormat(item.case_format_id, item.name)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="ci-button-icon cursor-pointer"
                    v-b-tooltip.hover
                    title="Delete"
                  ></feather-icon>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        </template>
      </b-row>
      <preview-pdf
        v-if="showPreviewPdf"
        :pdf-url="currentPdf"
        :pdf-name="pdfName"
        @close="showPreviewPdf = false"
      ></preview-pdf>
    </div>
  </div>
</template>

<script>
import VPdf from "vue-pdf";
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service.js";
export default {
  components: {
    VPdf,
    PreviewPdf,
  },
  props: {
    pdfList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modalShow: true,
      currentPdf: null,
      pdfName: null,
      showPreviewPdf: false,
    };
  },
  methods: {
    openPreview(url, name) {
      this.currentPdf = url;
      this.pdfName = name;
      this.showPreviewPdf = true;
    },
    async deleteCiCaseFormat(caseFormatId, name) {
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          `Are you sure you want to delete ${name} format?`
        );
        if (isConfirmed) {
          await CourtInfoService.deleteCiCaseFormat(caseFormatId);
          this.showSuccessSwal(`Case format ${name} deleted successfully`);
          this.$emit("format-deleted");
        }
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style lang="scss">
.list-pdf-container {
  font-family: "Rubick";

  .ci-container {
    position: relative;
    height: 100%;
    box-shadow: 0 4px 24px 0 rgba(107, 106, 106, 0.1);
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;

    .pdf-container {
      height: 100%;
      display: inline !important;

      .pdf-preview {
        height: 100% !important;
        display: inline !important;
        z-index: 1;
      }
    }

    canvas {
      border-radius: 10px;
      height: 300px !important;
    }

    .document-title {
      position: absolute;
      bottom: 25%;
      z-index: 10;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      background-color: rgba(0, 0, 0, 0.9);
      padding: 5px 10px;
      color: #e7e7e7;
    }

    .ci-pdf-list-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;

      .ci-footer-content {
        display: flex;
        justify-content: space-around;
        padding: 10px;
        z-index: 10;

        .ci-container-button-icon {
          padding: 10px;
          border: 1px solid #e7e7e7;
          border-radius: 100%;

          .ci-button-icon {
            height: 20px;
            width: 20px;
            color: #e7e7e7;
          }
        }
      }
    }
  }
}
</style>