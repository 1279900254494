<template>
  <b-card
    header-text-variant="white"
    header-bg-variant="info"
    header-class="p-0"
    body-class="pt-1 card-body-border"
  >
    <template #header>
      <div class="pl-1 pt-1">
        <p class="font-weight-bolder">Housing Expenses Information</p>
      </div>
    </template>
    <validation-observer ref="formHouse">
      <b-row class="mb-1">
        <b-col class="d-flex align-items-center justify-content-start">
          <span>House Owner</span>
          <div class="d-flex ml-1 align-items-center justify-content-center">
            <b-form-checkbox
              id="housing"
              v-model="houseOwner"
              name="housing"
              :disabled="readOnly"
              switch
              @change="changeHouse($event)"
            />
            <span v-if="houseOwner">YES</span>
            <span v-else>NO</span>
          </div>
          <b-button
            variant="primary"
            :disabled="readOnly || houses.length === 5"
            class="ml-2"
            size="sm"
            @click="addHouse()"
            v-if="!readOnly"
          >
            Add House
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
      <b-row v-for="(item, index) in houses" :key="index">
        <b-col
          cols="1"
          class="d-flex align-items-center justify-content-center"
        >
          <span class="my-2 p_desing2"> <feather-icon icon="HomeIcon" /></span>
        </b-col>
        <b-col xl="3" lg="3" md="4" sm="5" cols="4" v-if="houseOwner">
          <label for>Housing status</label>
          <v-select
            :disabled="readOnly"
            :options="houseStatusOptions"
            :reduce="(opts) => opts.id"
            label="text"
            v-model="item.statusHouse"
            @input="changeHousing(item, index)"
            :clearable="false"
          />
        </b-col>
        <b-col
          xl="3"
          lg="3"
          md="4"
          sm="3"
          cols="3"
          v-if="
            item.statusHouse == 2 ||
            item.statusHouse == null ||
            item.statusHouse == '' ||
            (item.statusHouse == null && item.cost > 0)
          "
        >
          <validation-provider rules="validate-amount-zero" v-slot="{ errors }">
            <label for> Cost </label>
            <money
              id="item.id"
              v-model="item.cost"
              v-bind="vMoney"
              :disabled="readOnly"
              class="form-control"
            />
            <span style="color: red">{{ errors[0] }}</span>
          </validation-provider>
        </b-col>
        <b-col
          xl="3"
          lg="4"
          md="3"
          sm="3"
          cols="4"
          v-if="houseOwner"
          style="margin-top: 3px"
        >
          <b-row>
            <b-col
              xl="7"
              lg="7"
              md="10"
              cols="11"
              class="d-flex justify-content-between"
            >
              <label :for="'house' + item.id">Main house</label>
              <feather-icon
                v-if="houses.length > 1"
                class="text-danger cursor-pointer"
                @click="deleteHouse(index)"
                icon="XSquareIcon"
              />
            </b-col>
            <b-col xl="7" lg="7" md="10" cols="11">
              <div class="d-flex justify-content-between">
                <b-form-checkbox
                  :disabled="readOnly"
                  :id="'house' + index"
                  v-model="item.mainHouse"
                  switch
                  @change="setMainHouse($event, index)"
                />
                <span class="d-flex">
                  {{
                    item.mainHouse != "" && item.mainHouse != null
                      ? "YES"
                      : "NO"
                  }}</span
                >
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-else>
          <label :for="'house' + item.id"></label>
          <feather-icon
            class="text-danger cursor-pointer"
            v-if="houses.length > 1"
            @click="deleteHouse(index)"
            icon="XSquareIcon"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="4">
          <label>Total monthly housing expenses</label>
          <span> $ {{ allCosts | currency }}</span>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    },
    houseOwner: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    allCosts() {
      let sum = 0;
      this.houses.forEach((val) => {
        sum += val.cost;
      });
      return sum;
    },
    houses: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  data() {
    return {
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
      // houseOwner: null,
      houseStatusOptions: [
        { id: 1, text: "Paid", mainHouse: 0 },
        { id: 2, text: "Paying", mainHouse: 0 },
      ],
    };
  },
  methods: {
    addHouse() {
      if (this.houses.length === 1 && this.houseOwner == false) {
        this.showInfoSwal(
          "To add more houses you have to activate the House Owner option"
        );
      } else {
        this.houses.push({
          id: this.houses.length + 1,
          house: "",
          statusHouse: 2,
          cost: 0,
          mainHouse: "",
        });
      }
    },
    changeHousing(item, index) {
      if (item.statusHouse === 1) {
        this.houses[index].cost = 0;
      }
    },
    changeHouse(evt) {
      if (this.houses.length === 0) {
        this.houses.push({
          id: 1,
          house: "",
          statusHouse: "",
          cost: 0,
          mainHouse: "",
        });
      }

      const [firstObject] = this.houses;
      const newFirst = { ...firstObject, statusHouse: 2 };
      this.houses = [newFirst];
    },

    setMainHouse(evt, index) {
      this.houses.forEach((val, idx) => {
        if (idx !== index) {
          val.mainHouse = false;
          val.isActive = false;
        }
      });

      const selectedHouse = this.houses.find((val, idx) => idx === index);
      if (selectedHouse) {
        selectedHouse.mainHouse = evt;
        selectedHouse.isActive = evt;
      }

      const activeCount = this.houses.reduce(
        (count, val) => count + (val.isActive ? 1 : 0),
        0
      );
      if (activeCount > 1) {
        this.houses.forEach((val, idx) => {
          if (idx !== index) {
            val.isActive = false;
          }
        });
      }
    },

    deleteHouse(index) {
      if (this.houses.length === 1) return;

      this.houses.splice(index, 1);
    },

    async validateForm() {
      const isValidated = await this.$refs.formHouse.validate();
      return isValidated;
    },
  },

 
  watch: {
    houseOwner() {
      this.$emit("updateOwner", this.houseOwner);
    },
  },
};
</script>

<style lang="scss">
.p_desing2 {
  color: white;
  font-size: 13px;
  background-color: #b139ee;
  border-radius: 3px;
  padding: 8px 10px;
  text-align: center;
}
</style>>
