<template>
  <div>
    <b-table
      slot="table"
      ref="refClientsList"
      no-provider-filtering
      primary-key="id"
      table-class="text-nowrap"
      responsive="sm"
      show-empty
      sticky-header="30vh"
      class="table-new-customization custom-table"
      :items="cards"
      :fields="fieldsFiltered"
    >
      <template #cell(Select)="data">
        <ValidationProvider
          v-slot="{ errors }"
          name="select"
          :rules="amountOption == 0 ? '' : 'required'"
        >
          <b-form-radio
            v-model="selected"
            name="select"
            class="vs-checkbox-con"
            :class="{ 'border-required': errors[0] }"
            :value="data.item.id"
            plain
            @change="$emit('CardId', data.item.id)"
          />
        </ValidationProvider>
      </template>
      <template #cell(cardnumber)="data">
        <div :class="classByColumn">
          <span> {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }} </span>
        </div>
      </template>
      <template #cell(cardsecuritycode)="data">
        <div :class="classByColumn">
          <span>
            {{
              data.item.cardsecuritycode.length == 3
                ? "XX" + data.item.cardsecuritycode.substr(2)
                : "XXX" + data.item.cardsecuritycode.substr(3)
            }}
          </span>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
// Import Components
import fields from "../../../data/fields.credit.card";

export default {
  props: {
    cards: {
      type: Array,
    },
    amountOption: {
      type: Array | String,
    },

    selectedFirst: null,
  },
  data() {
    return {
      fields,
      selected: "a",
    };
  },
  computed: {
    fieldsFiltered() {
      if (this.moduleId === 4) {
        return this.fields.filter((field) => field.key !== "type_card");
      }
      return this.fields;
    },
    classByColumn() {
      return "d-flex flex-column justify-content-start align-items-start";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  created() {
    this.selected = this.selectedFirst;
  },
  methods: {},
};
</script>
<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
.custom-table >>> table {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
