import { amgApi } from "@/service/axios"

class AlertActionService {
  async closeAllAlerts(params) {
    await amgApi.post(
      "credit-experts-digital/auto-alerts/close-all-alerts",
      params
    )
  }
  async acceptAlert(params) {
    try {
      await amgApi.post(
        "credit-experts-digital/auto-alerts/accept-alert",
        params
      )
    } catch (error) {
      console.log("Something went wrong on acceptAlert:", error)
      throw error
    }
  }
  async getAlertAccepted(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/auto-alerts/get-alert-accepted",
        params
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getAlertAccepted:", error)
      throw error
    }
  }
  async setAlertToQueue(params) {
    try {
      await amgApi.post("credit-experts-digital/auto-alerts/set-queue", params)
    } catch (error) {
      console.log("Something went wrong on setAlertToQueue:", error)
      throw error
    }
  }
}

export default new AlertActionService()
