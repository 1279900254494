export default [
  {
    path: "other-payments",
    name: "other-payments-correspondence",
    component: () =>
      import(
        "@/views/commons/components/other-payments/view/component/OtherPaymentsTabs.vue"
      ),
    redirect: { name: "other-payments-cash-correspondence" },
    meta: {
      pageTitle: "Other Payments",
      breadcrumb: [
        {
          text: "Other Payments",
        },
      ],
      routeDepositZelle: "other-payments-deposit-zelle",
      routeCash: "other-payments-cash-correspondence",
      routeMoneyOrder: "other-payments-money-order-correspondence",
      module: 9,
    },
    children: [
      {
        path: "cash",
        name: "other-payments-cash-correspondence",
        component: () =>
          import(
            "@/views/commons/components/other-payments/view/component/OtherPaymentsSubTabs.vue"
          ),
        redirect: { name: "other-payments-cash-pending-correspondence" },
        meta: {
          status: 2,
          tabTitle: "",
          pageTitle: "Other Payments",
          breadcrumb: [
            {
              text: "Cash",
              active: true,
            },
          ],
          routePending: "other-payments-cash-pending-correspondence",
          routeCompleted: "other-payments-cash-completed-correspondence",
          module: 9,
        },
        children: [
          {
            path: "pending",
            component: () =>
              import(
                "@/views/commons/components/other-payments/view/component/OtherPaymentsTable.vue"
              ),
            name: "other-payments-cash-pending-correspondence",
            meta: {
              status: 1,
              tabTitle: "Pending",
              pageTitle: "Other Payments",
              breadcrumb: [
                {
                  text: "Cash",
                  active: true,
                },
              ],
            },
          },
          {
            path: "completed",
            component: () =>
              import(
                "@/views/commons/components/other-payments/view/component/OtherPaymentsTable.vue"
              ),
            name: "other-payments-cash-completed-correspondence",
            meta: {
              status: 2,
              tabTitle: "Completed",
              pageTitle: "Other Payments",
              breadcrumb: [
                {
                  text: "Cash",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "money-order",
        component: () =>
          import(
            "@/views/commons/components/other-payments/view/component/OtherPaymentsSubTabs.vue"
          ),
        redirect: { name: "other-payments-money-order-pending-correspondence" },
        name: "other-payments-money-order-correspondence",
        meta: {
          status: 3,
          tabTitle: "Money Order",
          pageTitle: "Other Payments",
          breadcrumb: [
            {
              text: "Money Order",
              active: true,
            },
          ],
          routePending: "other-payments-money-order-pending-correspondence",
          routeCompleted: "other-payments-money-order-completed-correspondence",
          module: 9,
        },
        children: [
          {
            path: "pending",
            component: () =>
              import(
                "@/views/commons/components/other-payments/view/component/OtherPaymentsTable.vue"
              ),
            name: "other-payments-money-order-pending-correspondence",
            meta: {
              status: 1,
              tabTitle: "Pending",
              pageTitle: "Other Payments",
              breadcrumb: [
                {
                  text: "Money order",
                  active: true,
                },
              ],
              module: 9,
            },
          },
          {
            path: "completed",
            component: () =>
              import(
                "@/views/commons/components/other-payments/view/component/OtherPaymentsTable.vue"
              ),
            name: "other-payments-money-order-completed-correspondence",
            meta: {
              status: 2,
              tabTitle: "completed",
              pageTitle: "Other Payments",
              breadcrumb: [
                {
                  text: "Money order",
                  active: true,
                },
              ],
              module: 9,
            },
          },
        ],
      },
    ],
  },
]
