<template>
  <div>
    <div class="container-tabs-payments" v-if="withTabs">
      <div class="tab-container">
        <div
          class="tab tab-pending"
          @click="tabIndex = 0"
          :class="tabIndex == 0 ? 'active-cu' : ''"
        >
          Pending
          <div
            v-if="countTabPending > 0"
            v-b-tooltip.hover
            class="cont"
            title="Total Pending"
          >
            {{ countTabPending }}
          </div>
        </div>
        <div
          class="tab tab-paid"
          @click="tabIndex = 1"
          :class="tabIndex == 1 ? 'active-cu' : ''"
        >
          Paid
          <div
            v-if="countTabPaid > 0"
            v-b-tooltip.hover
            class="cont"
            title="Total Paid"
          >
            {{ countTabPaid }}
          </div>
        </div>
        <div
          class="tab tab-not-paid"
          @click="tabIndex = 2"
          :class="tabIndex == 2 ? 'active-cu' : ''"
        >
          Not Paid
          <div
            v-if="countTabNotPaid > 0"
            v-b-tooltip.hover
            class="cont"
            title="Total Not Paid"
          >
            {{ countTabNotPaid }}
          </div>
        </div>
      </div>
    </div>

    <filter-slot
      :filter="filters"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :total-rows="totalRows"
      :filter-principal="filterPrincipal"
      @reload="getPaymentScheduleByClient"
      @onChangeCurrentPage="getPaymentScheduleByClient"
      :class="tabIndex == 0 ? 'hidden-search' : ''"
      :show-input-search="tabIndex == 1 ? true : false"
      :no-visible-principal-filter="tabIndex == 1 ? false : true"
    >
      <template #buttons>
        <div class="ml-1 d-flex align-items-center" v-if="!readOnly">
          <slot name="payment">
            <div
              class="d-flex"
              v-if="
                (parseFloat(totalAmountFeePending) > 0 ||
                  [5, 7].some((module) => module == moduleId)) &&
                moduleId !== 6 &&
                moduleId !== 22 &&
                tabIndex == 0
              "
            >
              <b-button
                @click="startMonthlyPaymentProcess"
                variant="success"
                style="color: black !important"
                v-if="!agentConnection"
              >
                <feather-icon icon="PlusIcon"></feather-icon>
                PAY
              </b-button>
            </div>
          </slot>

          <b-button-group v-if="!isResponsibleAccount && tabIndex == 0">
            <b-button
              variant="outline-primary"
              class="ml-1"
              @click="showModalPaymentMethod = true"
              style="padding: 0.64rem 1rem !important"
            >
              <div
                class="d-flex justify-content-center align-items-center"
                style="gap: 1rem"
              >
                <feather-icon icon="SettingsIcon"></feather-icon>
                <span v-if="client.monthly_amount">
                  $ {{ client.monthly_amount }}
                </span>
                <status-monthly-pay />
              </div>
            </b-button>
            <b-button
              variant="outline-primary"
              class="px-1"
              @click="openModalHistoryPayment"
            >
              <feather-icon
                style="margin-bottom: 2.5px"
                icon="ListIcon"
                class="text-primary font-weight-bolder clickable"
                size="16"
                id="info-icon"
                v-b-tooltip.hover="`History Monthly Payment`"
              />
            </b-button>
          </b-button-group>
          <b-button
            variant="primary"
            class="ml-1"
            @click="showModalTracking = true"
          >
            <feather-icon icon="AlignJustifyIcon"></feather-icon>
            Tracking
          </b-button>

          <feather-icon
            id="tooltip-leyend"
            icon="MoreVerticalIcon"
            size="24"
            class="ml-1 cursor-pointer"
          ></feather-icon>

          <b-tooltip target="tooltip-leyend">
            <div>--- Legend ---</div>
            <div class="custom-buttons-23">
              <div class="container-circle-23">
                <div
                  class="payment-year d-flex align-items-center justify-content-center"
                >
                  Y
                </div>
                Payment for Year
              </div>
              <div class="container-circle-23">
                <div
                  class="zero-payment d-flex align-items-center justify-content-center"
                >
                  Z
                </div>
                Zero Payment
              </div>
              <!-- <div class="container-circle-23">
                <div
                  class="partial d-flex align-items-center justify-content-center"
                >
                  P
                </div>
                Partial
              </div> -->
              <div class="container-circle-23">
                <div
                  class="charge d-flex align-items-center justify-content-center"
                >
                  C
                </div>
                Charges
              </div>
            </div>
          </b-tooltip>
        </div>
      </template>
      <template #table>
        <div class="container-custom-table-ce-ing" v-if="!loading">
          <b-row class="row-item header">
            <b-col cols="1" class="custom-cols">#</b-col>
            <b-col cols="2" class="custom-cols">Due Date</b-col>
            <b-col cols="2" class="custom-cols">Monthly Payment</b-col>
            <b-col cols="2" class="custom-cols">Amount Paid</b-col>
            <b-col cols="2" class="custom-cols">Pre-payment</b-col>
            <b-col cols="2" class="custom-cols">Status</b-col>
            <b-col cols="1" class="custom-cols"></b-col>
          </b-row>

          <b-row
            class="row-item detail"
            v-for="(item, index) in items"
            v-bind:key="index + '_item'"
            :id="'item_' + index"
            :class="item.show ? 'active-row' : ''"
          >
            <b-col cols="1" class="custom-cols">{{
              enumItem(index + 1)
            }}</b-col>
            <b-col cols="2" class="custom-cols">{{
              formatPADate(item.due_date, item.type_payment)
            }}</b-col>
            <b-col
              cols="2"
              class="custom-cols"
              :class="!item.payment_details ? 'status-not-paid' : ''"
              >$ {{ item.monthly_payment | currencyZero }}</b-col
            >
            <b-col cols="2" class="custom-cols">
              <div class="d-flex">
                <span
                  :class="
                    !item.payment_details
                      ? 'status-not-paid'
                      : getTotalRowAmount(item).class
                  "
                  >{{ getTotalRowAmount(item).total_amount }}</span
                >

                <span
                  class="partial-refund ml-1"
                  v-if="getTotalRowAmount(item).total_partial > 0"
                  >(${{
                    getTotalRowAmount(item).total_with_partial_refund
                      | currencyZero
                  }})</span
                >

                <!-- <b-badge
                  v-if="getTotalValidPayments(item.payment_details)[1] > 2"
                  v-b-tooltip.hover
                  :title="getTotalValidPayments(item.payment_details)[0]"
                  :variant="getTotalValidPayments(item.payment_details)[2]"
                  class="ml-1"
                  >{{ getTotalValidPayments(item.payment_details)[1] }}
                </b-badge> -->

                <b-badge
                  variant="info"
                  v-if="getTotalRowAmount(item).count_charges > 0"
                  class="ml-1 b-badge-payment"
                  v-b-tooltip.hover
                  :title="getTotalRowAmount(item).count_charges + ' Charges'"
                >
                  C
                  <div class="counter">
                    {{ getTotalRowAmount(item).count_charges }}
                  </div>
                </b-badge>

                <b-badge
                  v-if="getTotalRowAmount(item).count_zero_payment > 0"
                  class="ml-1 b-badge-payment"
                  v-b-tooltip.hover
                  :title="
                    getTotalRowAmount(item).count_zero_payment + ' Zero payment'
                  "
                >
                  Z
                  <div class="counter">
                    {{ getTotalRowAmount(item).count_zero_payment }}
                  </div>
                </b-badge>

                <b-badge
                  v-if="getTotalRowAmount(item).count_payment_year > 0"
                  class="ml-1 b-badge-payment"
                  variant="warning"
                  v-b-tooltip.hover
                  :title="
                    getTotalRowAmount(item).count_payment_year +
                    ' Payments of year'
                  "
                >
                  Y
                  <div class="counter">
                    {{ getTotalRowAmount(item).count_payment_year }}
                  </div>
                </b-badge>
              </div>
            </b-col>
            <b-col cols="2" class="custom-cols">
              <div
                class="pre-payment"
                v-if="isPrePayment(item.payment_details)"
              >
                <feather-icon icon="CheckIcon" size="20"></feather-icon>
              </div>
            </b-col>
            <b-col cols="2" class="custom-cols">
              <div
                class="status-container"
                :class="{
                  'ml-3':
                    item.other_payment_pending &&
                    getStatusRow(item.payment_details)[1] != 'Paid',
                }"
              >
                <span
                  class="status"
                  v-b-tooltip.hover
                  :title="getStatusRow(item.payment_details)[1]"
                  :class="getStatusRow(item.payment_details)[0]"
                  >{{
                    getStatusRow(item.payment_details)[1] | limitChars(9)
                  }}</span
                >
                <feather-icon
                  icon="ClockIcon"
                  size="20"
                  class="ml-1"
                  v-b-tooltip.hover.auto="item.other_payment_pending"
                  :class="
                    item.other_payment_pending == 'DENIED'
                      ? 'text-danger'
                      : 'text-warning'
                  "
                  v-if="
                    item.other_payment_pending &&
                    getStatusRow(item.payment_details)[1] != 'Paid'
                  "
                ></feather-icon>
              </div>
            </b-col>
            <b-col cols="1" class="custom-cols">
              <feather-icon
                v-if="item.payment_details"
                :icon="item.show ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                class="cursor-pointer"
                size="23"
                @click="openCollapse(index)"
              ></feather-icon>
            </b-col>

            <b-col cols="12" class="pl-4" v-if="item.payment_details">
              <b-collapse
                :visible="Boolean(item.show)"
                accordion="my-accordion"
                class="my-accordion"
              >
                <b-row
                  v-for="(detail, dindex) in item.payment_details"
                  v-bind:key="dindex"
                >
                  <b-col cols="12" v-if="validateNotShowMP(detail)">
                    <b-row
                      v-if="detail.transaction_id && detail.is_from_charge == 0"
                    >
                      <b-col cols="12" class="my-1">
                        <b-row class="header-accordion">
                          <b-col
                            cols="2"
                            :class="
                              detail.transaction_id
                                ? 'text-positive'
                                : 'text-negative'
                            "
                            >Transaction Detail</b-col
                          >
                          <b-col cols="3"></b-col>
                          <b-col cols="5"></b-col>
                          <b-col cols="2">
                            <div class="">Actions</div>
                          </b-col>
                        </b-row>
                      </b-col>

                      <b-col cols="2">
                        <p>Transaction ID:</p>
                        <p
                          v-if="
                            detail.remaining_amount ||
                            detail.type_transaction_id == 9
                          "
                        >
                          Type:
                        </p>
                        <p>Method:</p>
                        <p>Date:</p>
                        <p>Created By:</p>
                        <p>Amount:</p>
                      </b-col>
                      <b-col cols="3">
                        <p>{{ detail.transaction_id }}</p>
                        <p
                          v-if="
                            detail.remaining_amount ||
                            detail.type_transaction_id == 9
                          "
                        >
                          {{
                            detail.remaining_amount
                              ? "Monthly Payment"
                              : detail.type_transaction
                          }}
                        </p>
                        <p v-if="detail.type_transaction_id != 14">
                          {{ detail.method ? detail.method : "-" }}
                        </p>
                        <p v-if="detail.type_transaction_id == 14">
                          {{ detail.type_transaction }}
                        </p>
                        <p>{{ detail.created_at | myGlobalWithHour }}</p>
                        <p>
                          {{ detail.t_created_by ? detail.t_created_by : "-" }}
                        </p>
                        <span :class="getTotalRowAmount(detail, false).class">
                          $
                          {{
                            detail.amount_paid
                              ? detail.amount_paid
                              : detail.pending_amount | currencyZero
                          }}
                        </span>
                        <span
                          class="partial-refund ml-1"
                          v-if="
                            getTotalRowAmount(detail, false).total_partial > 0
                          "
                          >(${{
                            getTotalRowAmount(detail, false)
                              .total_with_partial_refund | currencyZero
                          }})</span
                        >
                      </b-col>
                      <b-col cols="5">
                        <b-row
                          v-if="detail.partial_refunds || detail.partial_void"
                          style="margin-bottom: 4px; font-size: 12px"
                        >
                          <b-col class="border-bottom" cols="3"
                            >Transanction ID</b-col
                          >
                          <b-col class="border-bottom" cols="4">Type</b-col>
                          <b-col class="border-bottom" cols="2">Amount</b-col>
                          <b-col class="border-bottom" cols="3">Date</b-col>
                        </b-row>
                        <b-row
                          v-for="(refund, rindex) in detail.partial_refunds"
                          :key="`${rindex}-refund-${refund.transanction_id}`"
                          style="font-size: 12px"
                        >
                          <b-col cols="3">
                            <div class="text-truncate">
                              {{ refund.transanction_id }}
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <div class="text-truncate">Partial Refund</div>
                          </b-col>
                          <b-col cols="2">
                            <div class="text-negative">
                              -${{ refund.amount | currencyZero }}
                            </div>
                          </b-col>
                          <b-col cols="3">{{
                            refund.created_at | myGlobal
                          }}</b-col>
                        </b-row>
                        <b-row
                          v-for="(pvoid, rindex) in detail.partial_void"
                          :key="`${rindex}-void-${pvoid.transanction_id}`"
                          style="font-size: 12px"
                        >
                          <b-col cols="3">
                            <div class="text-truncate">
                              {{
                                pvoid.transanction_id
                                  ? pvoid.transanction_id
                                  : "Pending"
                              }}
                            </div>
                          </b-col>
                          <b-col cols="4">
                            <div class="text-truncate">Partial Void</div>
                          </b-col>
                          <b-col cols="2">
                            <div class="text-negative">
                              -${{ pvoid.amount | currencyZero }}
                            </div>
                          </b-col>
                          <b-col cols="3">{{
                            pvoid.created_at | myGlobal
                          }}</b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="2">
                        <div
                          class="badge-container"
                          v-if="detail.status_transaction_id"
                        >
                          <div
                            class="badge-action"
                            v-if="
                              isCeo &&
                              detail.void && // same day
                              !readOnly &&
                              [1, 2].includes(detail.type_transaction_id) &&
                              notPartialVoidPending(detail.partial_void)
                            "
                            @click="openRefundModal(detail, 1)"
                          >
                            <void-icon
                              :height="15"
                              :width="15"
                              style="margin-left: 3px; margin-bottom: 3px"
                            />
                            Void
                          </div>
                          <div
                            class="badge-action"
                            v-if="
                              detail.refund && // 120 days after
                              !readOnly &&
                              [1, 2].includes(detail.type_transaction_id)
                            "
                            @click="
                              openRefundModal(
                                detail,
                                2,
                                getTotalRowAmount(detail, false)
                                  .total_with_partial_refund
                              )
                            "
                          >
                            <refund-icon
                              :height="17"
                              :width="17"
                              style="margin-left: 3px; margin-bottom: 3px"
                            />
                            Refund
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="
                        detail.transaction_id == null &&
                        detail.is_from_charge == 0
                      "
                    >
                      <b-col cols="12" class="my-1">
                        <b-row class="header-accordion">
                          <b-col
                            cols="2"
                            :class="
                              detail.transaction_id
                                ? 'text-positive'
                                : 'text-negative'
                            "
                            >Transaction Detail</b-col
                          >
                          <b-col cols="3"></b-col>
                          <b-col cols="5"></b-col>
                          <b-col cols="2"> </b-col>
                        </b-row>
                      </b-col>

                      <b-col cols="2">
                        <p>
                          {{ detail.remaining_amount ? "Type" : "Status" }}:
                        </p>
                        <p>Amount:</p>
                      </b-col>
                      <b-col cols="5"
                        ><p>
                          {{
                            detail.remaining_amount
                              ? "Monthly Payment"
                              : "Pending"
                          }}
                        </p>
                        <p>
                          $
                          {{
                            detail.amount_paid
                              ? detail.amount_paid
                              : detail.pending_amount | currencyZero
                          }}
                        </p>
                      </b-col>
                    </b-row>
                    <b-row v-if="detail.is_from_charge == 1">
                      <b-col cols="12" class="my-1">
                        <b-row class="header-accordion">
                          <b-col
                            cols="2"
                            :class="
                              detail.transaction_id
                                ? 'text-positive'
                                : 'text-negative'
                            "
                            >Transaction Detail</b-col
                          >
                          <b-col cols="3"></b-col>
                          <b-col cols="5"></b-col>
                          <b-col cols="2">
                            <div>Actions</div>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="2">
                        <p v-if="detail.type_transaction_id != 14">Charge:</p>
                        <p v-if="detail.type_transaction_id == 14">Type:</p>
                        <p>Method:</p>
                        <p>Date:</p>
                        <p>Created By:</p>
                        <p>Amount:</p>
                        <p>Status:</p>
                      </b-col>

                      <b-col cols="3">
                        <p>
                          {{
                            detail.type_transaction_id != 14
                              ? detail.charge_name
                              : detail.type_transaction
                          }}
                        </p>
                        <p v-if="detail.type_transaction_id != 14">
                          {{ detail.method ? detail.method : "-" }}
                        </p>
                        <p v-if="detail.type_transaction_id == 14">
                          {{ detail.type_transaction }}
                        </p>
                        <p>{{ detail.created_at | myGlobalWithHour }}</p>
                        <p>
                          {{ detail.t_created_by ? detail.t_created_by : "-" }}
                        </p>
                        <p
                          :class="
                            detail.transaction_id
                              ? 'text-positive'
                              : 'text-negative'
                          "
                        >
                          $
                          {{ detail.charge_amount | currencyZero }}
                        </p>
                        <p>
                          {{ detail.status }}
                          <span
                            class="text-positive"
                            v-if="
                              detail.amount_paid != detail.charge_amount &&
                              getStatusRow(item.payment_details)[1] ==
                                'Partially Paid' &&
                              mpNotPaid(item.payment_details)
                            "
                          >
                            (${{ detail.amount_paid }})
                          </span>
                        </p>
                      </b-col>
                      <b-col cols="5"></b-col>
                      <b-col cols="2">
                        <div
                          class="badge-container"
                          v-if="detail.status_transaction_id"
                        >
                          <div
                            class="badge-action"
                            v-if="
                              isCeo &&
                              detail.void &&
                              !readOnly &&
                              [1, 2].includes(detail.type_transaction_id)
                            "
                            @click="openRefundModal(detail, 1)"
                          >
                            <void-icon
                              :height="15"
                              :width="15"
                              style="margin-left: 3px; margin-bottom: 3px"
                            />
                            Void
                          </div>
                          <div
                            class="badge-action"
                            v-if="
                              detail.refund && // 120 days after
                              !readOnly &&
                              [1, 2].includes(detail.type_transaction_id)
                            "
                            @click="openRefundModal(detail, 2)"
                          >
                            <refund-icon
                              :height="17"
                              :width="17"
                              style="margin-left: 3px; margin-bottom: 3px"
                            />
                            Refund
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>

          <div
            v-if="items.length == 0"
            class="text-center mt-2"
            style="font-size: 15px"
          >
            There are no records to show
          </div>
        </div>
        <div class="container-charge-table-ce-ing w-100" v-else>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
        </div>
      </template>
    </filter-slot>

    <payment-schedule-tracking-modal
      v-if="showModalTracking"
      :key="keyPaymentSchedule"
      :clientAccountId="clientAccountId"
      @close="showModalTracking = false"
    />

    <monthly-payment-modal
      v-if="showModalMonthlyPayment"
      @refreshTable="refreshComponents"
      @close="showModalMonthlyPayment = false"
      @paymentSaveSuccessfully="
        connectionProcessWhenHoldPayMonthlyPayment($event)
      "
      @refreshPaymentScheduleGrid="refreshPaymentSchedule"
    />

    <new-relationship-modal
      v-if="activeNewRelationshipModal"
      :active="activeNewRelationshipModal"
      :pre-selected-agent-id="preSelectedAgentId"
      @close="closeNewRelationshipModal($event)"
    />

    <void-refund-modal
      v-if="showModalVoidRefund"
      :void-refund-data="voidRefundData"
      :type-void-refund="typeModalVoidRefund"
      @close="showModalVoidRefund = false"
      @refresh="refreshComponents"
    />

    <modal-monthly-edit
      v-if="showModalPaymentMethod"
      :client-account="client"
      @updateClient="updateClientMonthlyAmount()"
      @close="showModalPaymentMethod = false"
    />

    <modal-history-monthly-payment
      v-if="modalHistoryPayment"
      @close="modalHistoryPayment = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VoidIcon from "@/icons/Void.vue";
import RefundIcon from "@/icons/Refund.vue";
import CashIcon from "@/icons/Cash.vue";

import PaymentScheduleTrackingModal from "@/views/commons/components/clients/components/paymentSchedule/modals/PaymentScheduleTrackingModal.vue";
import NewRelationshipModal from "@/views/ce-digital/sub-modules/settings/views/users-relationship/components/NewRelationshipModal.vue";
import MonthlyPaymentModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/monthly-payment-modal/MonthlyPaymentModal.vue";
import VoidRefundModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundModal.vue";

import ConnectionUsersRelationshipService from "@/views/ce-digital/sub-modules/settings/views/users-relationship/service/users-relationship-connecion.service";
import DashboardService from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/service/dashborard.service.js";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import PaymentScheduleService from "@/views/commons/components/clients/components/paymentSchedule/service/payment-schedule.service.js";
import moment from "moment";
import ModalMonthlyEdit from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalMonthlyEdit.vue";
import StatusMonthlyPay from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/StatusMonthlyPay.vue";
import ModalHistoryMonthlyPayment from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryMonthlyPayment.vue";

export default {
  name: "CustomTable",
  components: {
    VoidIcon,
    RefundIcon,
    CashIcon,
    PaymentScheduleTrackingModal,
    MonthlyPaymentModal,
    NewRelationshipModal,
    VoidRefundModal,
    ModalMonthlyEdit,
    StatusMonthlyPay,
    ModalHistoryMonthlyPayment,
  },

  props: {
    clientAccountId: {
      type: String,
      required: true,
    },

    paymentScheduleId: {
      type: [Number, String],
      required: false,
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },

    withTabs: {
      type: Boolean,
      required: false,
      default: false,
    },
    tabIndexSelected: {
      type: Number,
      required: false,
      default: null,
    },

    totalAmountFeePending: {
      type: [Number, String],
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      showModalTracking: false,
      showModalMonthlyPayment: false,
      activeNewRelationshipModal: false,

      preSelectedAgentId: null,
      tabIndex: 0,

      currentCharge: {},
      items: [],
      filters: [],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Transaction ID",
        model: "",
      },
      openModalChargeEdit: false,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: null,
      totalRows: 0,
      toPage: null,
      statusActive: false,
      loading: true,

      keyPaymentSchedule: Math.random(),

      showModalVoidRefund: false,
      typeModalVoidRefund: 2,
      voidRefundData: {},
      currentTransactionId: null,
      countTabPending: 0,
      countTabNotPaid: 0,
      countTabPaid: 0,
      showModalPaymentMethod: false,
      modalHistoryPayment: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    currentRoleId() {
      return this.currentUser.role_id;
    },

    isCeo() {
      return this.currentRoleId === 1;
    },
    isSupervisorOrChief() {
      return this.currentRoleId === 2 || this.currentRoleId === 17;
    },

    ncrAndMonthlyPaymentComissionUsers() {
      return !this.isCeo && !this.isSupervisorOrChief;
    },

    agentConnection() {
      return (
        this.moduleId == 22 && [14, 15].some((rol) => rol == this.currentRoleId)
      );
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      A_GET_CLIENTS: "DebtSolutionClients/A_GET_CLIENTS",
    }),

    openModalHistoryPayment() {
      this.modalHistoryPayment = true;
    },

    async updateClientMonthlyAmount() {
      this.showModalPaymentMethod = false;
      await this.A_GET_CLIENTS({
        id: this.client.id,
      });
      this.refreshComponents();
    },

    enumItem(position) {
      const perPage = parseInt(this.paginate.perPage);
      const page = parseInt(this.paginate.currentPage);

      const numberItem = perPage * (page - 1);
      return numberItem + position;
    },

    refreshComponents() {
      this.$emit("refresh");
      this.showModalMonthlyPayment = false;
      this.getPaymentScheduleByClient();
      this.refreshPaymentSchedule();
    },

    refreshPaymentSchedule() {
      this.keyPaymentSchedule = Math.random();
    },

    getTotalRowAmount(items, general = true) {
      let totalCharge = 0;
      let totalPaid = general ? 0 : items.amount_paid;
      let countCharge = 0;
      let countZeroPayment = 0;
      let countPaymentYear = 0;
      let totalPartialRefunds = 0;
      let diffAmountRefund = 0;
      let classAmount = "text-positive";
      let resp = "0.00";
      let isMonthlyPaid = false;

      let totalPartialVoids = 0;

      if (general) {
        const payment_details = items.payment_details;

        if (payment_details) {
          payment_details.forEach((element) => {
            let partial_refunds = element.partial_refunds;
            let partial_voids = element.partial_void;

            if (partial_refunds) {
              partial_refunds.forEach((refund) => {
                if (refund.transanction_id != "" && refund.transanction_id) {
                  totalPartialRefunds += Number(refund.amount);
                }
              });
            }

            if (partial_voids) {
              partial_voids.forEach((voids) => {
                if (voids.transanction_id != "" && voids.transanction_id) {
                  totalPartialVoids += Number(voids.amount);
                }
              });
            }

            if (element.is_from_charge && element.type_transaction_id != 14) {
              totalCharge += Number(element.amount_paid);
              countCharge++;
            }

            if (
              element.transaction_id &&
              !element.is_from_charge &&
              element.type_transaction_id != 14
            ) {
              totalPaid += Number(element.amount_paid);
            }

            if (element.type_transaction_id == 14) {
              countZeroPayment++;
            }

            if (element.type_transaction_id == 9) {
              countPaymentYear++;
            }
          });
        }
      } else {
        if (items.partial_refunds) {
          items.partial_refunds.forEach((refund) => {
            if (refund.transanction_id != "" && refund.transanction_id) {
              totalPartialRefunds += Number(refund.amount);
            }
          });
        }

        if (items.partial_void) {
          items.partial_void.forEach((voids) => {
            if (voids.transanction_id != "" && voids.transanction_id) {
              totalPartialVoids += Number(voids.amount);
            }
          });
        }

        if (items.transaction_id && items.remaining_amount) {
          isMonthlyPaid = true;
        }
      }

      const totalAmount = totalCharge + totalPaid;

      resp =
        totalAmount > 0
          ? this.$options.filters.currencyZero(totalAmount)
          : "0.00";

      if (totalPartialRefunds > 0 || totalPartialVoids > 0) {
        classAmount = "with-partial-refund";
        diffAmountRefund =
          totalAmount - totalPartialRefunds - totalPartialVoids;
      }

      if (totalAmount <= 0 && !isMonthlyPaid) {
        classAmount = "text-negative";
      }

      const result = {
        total_amount: "$ " + resp,
        class: classAmount,
        count_charges: countCharge,
        count_zero_payment: countZeroPayment,
        count_payment_year: countPaymentYear,
        total_with_partial_refund: diffAmountRefund,
        total_partial: totalPartialRefunds + totalPartialVoids,
      };
      return result;
    },

    isPrePayment(detail) {
      if (detail) {
        return detail.some((element) => element.is_advance);
      }
      return false;
    },

    getStatusRow(items) {
      if (items) {
        const lastPayment = items[items.length - 1];

        if (lastPayment.charge_back) {
          return ["status-charge-back", "Charge Back"];
        }

        if (lastPayment.status == "Pending") {
          return ["status-pending", lastPayment.status];
        }

        if (lastPayment.status == "Partially Paid") {
          return ["status-partial", lastPayment.status];
        }

        return ["status-success", "Paid"];
      }

      return ["status-not-paid", "Not Paid"];
    },

    async getPaymentScheduleByClient() {
      try {
        this.loading = true;
        const params = {
          client_account_id: this.clientAccountId,
          payment_schedule_id: this.paymentScheduleId,
          search_txt: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          current_page: this.paginate.currentPage,
          tab: this.withTabs ? this.tabIndex + 1 : this.tabIndexSelected,
        };

        const { data } =
          await PaymentScheduleService.getPaymentScheduleByClient(params);

        this.startPage = data.payment_schedule.from;
        this.paginate.perPage = data.payment_schedule.per_page;
        this.totalRows = data.payment_schedule.total;
        this.toPage = data.payment_schedule.to;

        this.items = data.payment_schedule.data;
        if (data.store.refresh) {
          this.$emit("refresh"); //generate new payment schedule
        }
        await this.processCounterTabsPayments(data.counter_tabs);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },

    async connectionProcessWhenHoldPayMonthlyPayment({ amount }) {
      if (
        !(
          this.moduleId == 20 &&
          this.client.in_connection == "CONNECTION" &&
          this.client.status_connection == "HOLD"
        )
      ) {
        return;
      }
      try {
        if (this.ncrAndMonthlyPaymentComissionUsers && this.moduleId == 20) {
          await ClientsOptionsServices.generateComission({
            client_account_id: this.client.id,
            amount,
            type_commission: "PERCENTAGE",
            slug: "con-hold",
          });
        }

        await DashboardService.sendClientToConnection({
          client_id: this.client.client_id,
          client_account_id: this.client.id,
          in_connection: "CONNECTION",
          status_connection: "RECOVERED",
          comment: "",
          user_id: this.currentUserId,
        });
      } catch (err) {
        console.error(err);
      }
    },

    async startMonthlyPaymentProcess() {
      if (
        this.moduleId == 20 &&
        /* 20: Connection */ this.client.status_connection == "HOLD"
      ) {
        await this.addPreloader();
        const params = { agent_id: this.client.advisor_connection_id };
        const { status: hasAdministratorSpecialistRelationship } =
          await ConnectionUsersRelationshipService.checkIfAgentHasRelationShip(
            params
          );
        if (!hasAdministratorSpecialistRelationship) {
          this.preSelectedAgentId = this.client.advisor_connection_id;
          this.activeNewRelationshipModal = true;
          await this.removePreloader();
        } else {
          this.showModalMonthlyPayment = true;
          await this.removePreloader();
        }
      } else {
        this.showModalMonthlyPayment = true;
      }
    },

    async closeNewRelationshipModal(success = false) {
      this.activeNewRelationshipModal = false;
      if (success) this.showModalMonthlyPayment = true;
    },

    openRefundModal(data, type, new_amount = null) {
      this.currentTransactionId = data.transaction_id;
      this.typeModalVoidRefund = type; // 1 Void, 2 Refund
      this.voidRefundData = {
        transaction_id: data.transaction_id,
        merchant: data.merchant,
        client_name: data.client_name,
        amount: new_amount ? new_amount : data.amount_paid,
        settlement_date: data.settlement_date,
      };

      this.showModalVoidRefund = true;
    },

    getTotalValidPayments(items) {
      let monthlyPaid = 0;
      let monthlyPending = 0;
      let message = "";
      let variant = "success";

      if (items) {
        items.forEach((element) => {
          if (
            element.transaction_id != null &&
            element.is_from_charge == 0 &&
            element.type_transaction_id != 14
          ) {
            monthlyPaid++;
          }
        });

        items.forEach((element) => {
          if (
            element.transaction_id == null &&
            element.is_from_charge == 0 &&
            element.type_transaction_id != 14
          ) {
            monthlyPending++;
          }
        });

        if (monthlyPaid > 0) {
          message += ` ${monthlyPaid} Paid`;
        }

        if (monthlyPending > 0) {
          message += `, ${monthlyPending} Pending`;
          variant = "warning";
        }
      }

      const total = monthlyPaid + monthlyPending;
      return [message, total, variant];
    },

    notPartialVoidPending(partial_voids) {
      if (!partial_voids) return true;
      return !partial_voids.some(
        (partial) => partial.transaction_id == "" || !partial.transaction_id
      );
    },

    mpNotPaid(monthly_payments) {
      if (monthly_payments) {
        return monthly_payments.some(
          (mp) => mp.remaining_amount && !mp.transaction_id
        );
      }
      return false;
    },

    openCollapse(index) {
      this.items.forEach((item, index2) => {
        if (index2 != index) {
          item.show = false;
        }
      });

      this.items[index].show = !this.items[index].show;
    },

    validateNotShowMP(detail) {
      if (detail.remaining_amount && detail.pending_amount == 0) {
        return false;
      }

      return true;
    },

    formatPADate(date, type_payment) {
      if (type_payment == 1) {
        const dateMoment = moment(date, "YYYY-MM-DD", true);

        if (!dateMoment.isValid()) {
          const arrayDate = date.split("-");
          const newDate = moment(
            `${arrayDate[0]}-${Number(arrayDate[1]) + 1}-01`,
            "YYYY-MM-DD"
          );

          const lastDay = newDate.subtract(1, "day").format("YYYY-MM-DD");
          return this.$options.filters.myGlobal(lastDay);
        }

        const newDate = this.$options.filters.myGlobal(date);
        return newDate;
      }
      const newDate = this.$options.filters.myMonthYear(date);
      return newDate;
    },
    async processCounterTabsPayments(data) {
      this.countTabPending = data.countTabPending;
      this.countTabPaid = data.countTabPaid;
      this.countTabNotPaid = data.countTabNotPaid;
    },
  },
  watch: {
    paymentScheduleId() {
      this.getPaymentScheduleByClient();
    },

    tabIndex() {
      this.getPaymentScheduleByClient();
    },
  },
};
</script>

<style lang="scss">
.container-tabs-payments {
  .tab-container {
    border-bottom: 3px solid #0090e7;

    .tab-pending {
      color: #ff9900 !important;
    }

    .tab-paid {
      color: #93c47d !important;
    }

    .tab-not-paid {
      color: #ff0000 !important;
    }

    .tab-pending,
    .tab-paid,
    .tab-not-paid {
      min-width: 80px;
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-bottom: none !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .active-cu {
      border: 2px solid #0090e7 !important;
      background-color: #0090e7 !important;
      color: #ffffff !important;
    }
  }
}

.custom-buttons-23 {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;

  .container-circle-23 {
    display: flex;
    align-items: center;
    font-weight: 600;

    .zero-payment,
    .charge,
    .partial,
    .payment-year {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin: 3px 10px 3px 0;
    }

    .zero-payment {
      background-color: #82868b;
    }

    .charge {
      background-color: #8f5fe8;
    }

    .partial {
      background-color: #00d25b;
    }

    .payment-year {
      background-color: #e28e3e;
    }
  }
}

.container-custom-table-ce-ing {
  width: 100%;
  max-height: 640px;
  overflow-y: auto;
  padding: 0 20px 5px 20px;

  .b-badge-payment {
    position: relative;

    .counter {
      position: absolute;
      top: -12px;
      right: -12px;
      height: 20px;
      width: 20px;
      background-color: #bd824b;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .text-positive {
    color: #04b400;
  }

  .text-negative,
  .with-partial-refund {
    color: #e35403;
  }

  .with-partial-refund {
    text-decoration: line-through;
  }

  .partial-refund {
    color: #7751c0;
    margin-right: 3px;
  }

  .status-not-paid {
    color: #82868b;
  }

  .header {
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #0090e7 !important;
    color: #ffffff !important;
  }

  .row-item {
    min-height: 60px;
    margin-top: 10px !important;
    color: black;
    font-size: 16px;
    text-align: center;

    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    background-color: #f5f5f5;

    .custom-cols {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .pre-payment {
        color: #ff5c00;
        background-color: #ffe2c0;
        padding: 5px 15px;
        border-radius: 20px;
      }
    }

    .status-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      .status {
        padding: 5px 20px;
        border-radius: 20px;
        font-weight: 600;
        letter-spacing: 0.8px;
      }

      .status-charge-back {
        color: rgb(47, 43, 43);
        background-color: #ff4c42;
      }

      .status-success {
        background-color: #c3ffe2;
        color: #04b400;
      }

      .status-pending {
        background-color: #ffe0c3;
        color: #f17500;
      }

      .status-partial {
        background-color: #eaadfa;
        color: #8a15f8;
      }
    }

    .my-accordion {
      background-color: #f5f5f5;
      border-top: 1px solid black;
      margin: 10px 0;
      text-align: left;
      font-size: 16px;

      .header-accordion {
        font-weight: 600;
      }

      .badge-container {
        display: flex;
        // flex-direction: column;

        .badge-action {
          // margin-top: 5px;
          cursor: pointer;
          text-align: center;
          border-radius: 20px;
          border: 1px solid #d9d9d9;
          padding: 8px 12px;
          color: black;
        }
      }
    }
  }

  .detail {
    padding-top: 8px;
  }

  .active-row {
    border: 1px solid #0090e7;
  }
}

.dark-layout {
  .container-custom-table-ce-ing {
    // .status-not-paid {
    //   color: #82868b;
    // }

    .row-item {
      color: #ffffff;
      background-color: #1d1d20;

      .my-accordion {
        background-color: #1d1d20;
        border-top: 1px solid #ffffff;

        .badge-container {
          .badge-action {
            border: 1px solid #d9d9d9;
            color: #ffffff;
          }
        }
      }
    }

    .active-row {
      border: 1px solid #0090e7;
    }
  }
}

@media (max-width: 1450px) and (min-width: 407px) {
  .container-custom-table-ce-ing {
    .row-item {
      min-height: 45px;
      margin-top: 10px !important;
      font-size: 14px;

      .status-container {
        .status {
          padding: 4px 15px;
          letter-spacing: 0.8px;
        }
      }

      .my-accordion {
        margin: 10px 0;
        text-align: left;
        font-size: 14px;

        .badge-container {
          display: flex;

          .badge-action {
            text-align: center;
            margin-left: 5px;
            margin-right: 5px;
            border-radius: 20px;
            border: 1px solid #d9d9d9;
            padding: 4px 8px;
          }
        }
      }
    }
  }
}
</style>