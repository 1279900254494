

export default [
	{
		path: "leads",
		name: "customerservice-leads",
		redirect: { name: "customerservice-leads-grid" },
		component: () => import(/* webpackChunkName: "CEDCustomerServiceLeads" */ "@/views/commons/components/lead-programs/Lead.vue"),
		meta: {
			childModule: 6,
			programParent: 3,
			program: 22,
		},
		children: [
			{
				path: "",
				name: "customerservice-leads-grid",
				component: () => import(/* webpackChunkName: "CEDCustomerServiceLeadsGrid" */ "@/views/commons/components/lead-programs/components/lead-table/LeadTable.vue"),
				meta: {
					isClientsTab: true,
					pageTitle: "Leads",
					route: "cedigital/customerservice",
					breadcrumb: [
						{
							text: "Leads",
						},
					],
				},
			},
			// Leads with sales
			{
				path: "sales",
				name: "customerservice-leads-grid-sales",
				component: () => import(/* webpackChunkName: "CEDCustomerServiceLeadsSales" */ "@/views/commons/components/lead-programs/components/lead-table/LeadTableSales.vue"),
				meta: {
					isClientsTab: true,
					pageTitle: "Leads",
					route: "cedigital/customerservice",
					breadcrumb: [
						{
							text: "Sales",
						},
					],
				},
			},
		],
	},
	{
		path: "leads/show/:id",
		name: "customerservice-lead-show",
		component: () => import(/* webpackChunkName: "CEDCustomerServiceLeadsShow" */ "@/views/crm/views/Lead/lead-module/dashboard/default.vue"),
		props: true,
		meta: {
			isClientsTab: true,
		},
	},

	{
		path: "leads/report/:idlead/:idfile",
		name: "customerservice-report-lead",
		component: () =>
			import("@/views/commons/components/ncr/components/report/ReportLead.vue"),
		props: true,
		meta: {
			route: "sales",
			isClientsTab: true,
			parentModule: 21,
			module: 22,
			pendingRoute: "ncr-pending",
			returnedRoute: "ncr-returned",
			completedRoute: "ncr-completed",
			reportLeadRoute: "report-lead",
			leadRoute: "lead-show",
		},
	},
]
