import { amgApi } from "@/service/axios";

class LettersService {

    async getPending(params) {
        try {
            const data = await amgApi.post("/administration/letters/get-pending", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on getPending:", error);
            throw error;
        }
    }
    async getCompleted(params) {
        try {
            const data = await amgApi.post("/administration/letters/get-completed", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on getCompleted:", error);
            throw error;
        }
    }
    async getFilesRound(params) {
        try {
            const data = await amgApi.post("/administration/letters/get-files-round", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on getFilesRound:", error);
            throw error;
        }
    }
    async uploadFileDragAndDropRound(params) {
        try {
            const data = await amgApi.post("/administration/letters/file-drag-drop-round", params, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
            return data;
        } catch (error) {
            console.log("Something went wrong on uploadFileDragAndDropRound:", error);
            throw error;
        }
    }
    async deleteFileRound(params) {
        try {
            const data = await amgApi.post("/administration/letters/delete-file-round", params);
            return data;
        } catch (error) {
            console.log("Something went wrong on deleteFileRound:", error);
            throw error;
        }
    }
      async getAllAdvisors(params) {
        try {
          const data = await amgApi.post('/program/get-users-program-ncr', params)
          return data.data;
        } catch (error) {
          console.log('Something went wrong on getAllAdvisors:', error)
          throw error
        }
      }
      async getAllAdministrators(params) {
        try {
            const data = await amgApi.post('/commons/user-module/4', params)
            return data.data;
          } catch (error) {
            console.log('Something went wrong on getAllAdministrators:', error)
            throw error
          } 
    }
    async getCounterTabLetter(params) {
        try {
            const data = await amgApi.post('/administration/letters/get-counter-tab-letters', params)
            return data;
          } catch (error) {
            console.log('Something went wrong on getCounterTabLetter:', error)
            throw error
          } 
    }
    async getCounterLetter(params) {
        try {
            const data = await amgApi.post('/administration/letters/get-counter-letters', params)
            return data;
          } catch (error) {
            console.log('Something went wrong on getCounterLetter:', error)
            throw error
          } 
    }
    async saveFilesRound(params) {
      try {
          const data = await amgApi.post('/administration/letters/save-files-round', params)
          return data;
        } catch (error) {
          console.log('Something went wrong on saveFilesRound:', error)
          throw error
        } 
  }
}

export default new LettersService();