// import PaymentsClientsGrid from './components/PaymentsClientsGrid.vue'
export default [
  {
    path: "payments",
    name: "claimdepartment-payment",
    redirect: { name: "claimdepartment-payment-list-clients" },
    component: () => import(/* webpackChunkName: "ClaimDepartamentPayments" */ "@/views/commons/components/payments/views/components/PaymentsTab.vue"),
    meta: {
      pageTitle: "Payments",
      permittedRoles: [1, 2, 3],
      breadcrumb: [
        {
          text: "Payments",
          active: true,
        },
      ],
      route: 'claimdepartment',
    },
    children: [
      {
        path: "clients",
        component: () => import(/* webpackChunkName: "ClaimDepartamentPaymentsClients" */ "@/views/commons/components/payments/views/components/PaymentsClientsGrid.vue"),
        name: "claimdepartment-payment-list-clients",
        meta: {
          pageTitle: "Payments",
          permittedRoles: [1, 2, 3],
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
          route: 'claimdepartment',
        },
      },
      {
        path: "leads",
        component: () => import(/* webpackChunkName: "ClaimDepartamentPaymentsLeads" */ "@/views/commons/components/payments/views/components/PaymentsLeadsGrid.vue"),
        name: "claimdepartment-payment-list-leads",
        meta: {
          pageTitle: "Payments",
          permittedRoles: [1, 2, 3],
          breadcrumb: [
            {
              text: "Leads",
              active: true,
            },
          ],
          route: 'claimdepartment',
        },
      },
    ],
  },
];
