import AssistanceMain from '@/views/assistance/views/assistanceMain.vue';

export default [
  {
    path: '/bookeeping/attendance',
    name: 'bookeeping-dashboard-attendance',
    redirect: { name: 'bookeeping-chief-attendance-dashboard' },
    component: AssistanceMain,
    children: [
      {
        path: 'chief-attendance-dashboard',
        name: 'bookeeping-chief-attendance-dashboard',
        component: () => import('@/views/assistance/views/dashboard/views/GraphicReport.vue'),
        meta: {
          pageTitle: 'Attendance',
          breadcrumb: [
            {
              text: 'My Department Attendance',
            },
          ],
          permittedRoles: [1, 2, 17],
          attendanceTolerance: 'bookeeping-attendance-tolerance',
          chiefDashboardRoute: 'bookeeping-chief-attendance-dashboard',
          attendanceDashboard: 'bookeeping-attendance-dashboard',
          attendanceHolidays: 'bookeeping-attendance-holidays',
          attendanceVacations: 'bookeeping-attendance-vacations',
          attendanceVacationsExistent: 'bookeeping-attendance-vacations-existent',
          attendanceVacationsDeleted: 'bookeeping-attendance-vacations-deleted',
          attendanceQrGenerator: 'bookeeping-attendance-qr-generator',
          attendanceEmployeesSchedule: 'bookeeping-attendance-employees-schedule',
          departmentalSchedules: 'bookeeping-attendance-department',
          tabRole: 'chief',
        },
      },
      {
        path: 'schedule',
        name: 'bookeeping-attendance-employees-schedule',
        component: () => import(/* webpackChunkName: "ParagonEmployeesSchedule" */ '@/views/assistance/views/employees-schedule/EmployeesSchedule.vue'),
        meta: {
          pageTitle: 'Attendance',
          breadcrumb: [
            {
              text: 'Schedule',
            },
          ],
          permittedRoles: [1, 2, 17],
          attendanceTolerance: 'bookeeping-attendance-tolerance',
          chiefDashboardRoute: 'bookeeping-chief-attendance-dashboard',
          attendanceDashboard: 'bookeeping-attendance-dashboard',
          attendanceHolidays: 'bookeeping-attendance-holidays',
          attendanceVacations: 'bookeeping-attendance-vacations',
          attendanceVacationsExistent: 'bookeeping-attendance-vacations-existent',
          attendanceVacationsDeleted: 'bookeeping-attendance-vacations-deleted',
          attendanceQrGenerator: 'bookeeping-attendance-qr-generator',
          attendanceEmployeesSchedule: 'bookeeping-attendance-employees-schedule',
          departmentalSchedules: 'bookeeping-attendance-department',
        },
      },
      {
        path: 'department',
        name: 'bookeeping-attendance-department',
        component: () => import('@/views/assistance/views/dashboard/components/departamentalSchedules/views/components/DepartamentalSchedulesComponent.vue'),
        meta: {
          pageTitle: 'Attendance',
          breadcrumb: [
            {
              text: 'Schedule',
            },
          ],
          permittedRoles: [1, 2, 17],
          attendanceTolerance: 'bookeeping-attendance-tolerance',
          chiefDashboardRoute: 'bookeeping-chief-attendance-dashboard',
          attendanceDashboard: 'bookeeping-attendance-dashboard',
          attendanceHolidays: 'bookeeping-attendance-holidays',
          attendanceVacations: 'bookeeping-attendance-vacations',
          attendanceVacationsExistent: 'bookeeping-attendance-vacations-existent',
          attendanceVacationsDeleted: 'bookeeping-attendance-vacations-deleted',
          attendanceQrGenerator: 'bookeeping-attendance-qr-generator',
          attendanceEmployeesSchedule: 'bookeeping-attendance-employees-schedule',
          departmentalSchedules: 'bookeeping-attendance-department',
        },
      },
    ]
  },
]
