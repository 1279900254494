import {
  newRiskClientNotification,
  CloseAllRcNotification,
} from "@/views/commons/components/risky-clients/socket/alert-risk-client.socket";

const subscribeRiskClientSocket = (socket) => {
  try {
    socket.subscribe("client-risk-notifications");
    newRiskClientNotification();
    CloseAllRcNotification();
  } catch (error) {
    console.error(error, "error");
  }
};

export default subscribeRiskClientSocket;
