import store from "@/store";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useWindowSize } from "@vueuse/core";
import {
  swalErrorIcon,
  swalInfoIcon,
  swalSuccessIcon,
  swalWarningIcon,
} from "@/icons/statusIcons";
import businessImage from "@/assets/images/icons/dashboard-ceo/logo-business.svg";
import boostCreditImage from "@/assets/images/icons/dashboard-ceo/logo-boost-credit.svg";
import creditExpertsImage from "@/assets/images/icons/dashboard-ceo/logo-credit-experts.svg";
import debtSolutionImage from "@/assets/images/icons/dashboard-ceo/logo-debt-solution.svg";
import taxResearchImage from "@/assets/images/icons/dashboard-ceo/logo-tax-research.svg";
import specialistImage from "@/assets/images/icons/dashboard-ceo/logo-specialist.svg";
import keybookImage from "@/assets/images/icons/dashboard-ceo/logo-keybook.svg";
import crmImage from "@/assets/images/icons/dashboard-ceo/crm-logo.svg";
import paragonImage from "@/assets/images/icons/dashboard-ceo/paragon.png";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL_ASSETS,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
    };
  },
  computed: {
    screenWidth() {
      const { width } = useWindowSize();
      return width.value;
    },

    screenHeight() {
      const { height } = useWindowSize();
      return height;
    },

    bgTabsNavs() {
      //getters
      return store.getters["appConfig/skin"] === "dark"
        ? "nav-dark-tabs"
        : "nav-light-tabs";
    },
    textLink() {
      return store.getters["appConfig/skin"] === "dark"
        ? "text-warning font-weight-bolder"
        : "text-primary font-weight-bolder";
    },
    bgLightDark() {
      return store.getters["appConfig/skin"] === "dark"
        ? "bg-dark text-white"
        : "bg-white";
    },
    isDarkSkin() {
      return store.getters["appConfig/skin"] === "dark";
    },
    isLightSkin() {
      return store.getters["appConfig/skin"] === "light";
    },
    isBigWindow() {
      return store.getters["app/bigWindow"];
    },
    isSupervisor() {
      return store.getters["auth/isSupervisor"];
    },
    isAssistantSupervisor() {
      return store.getters["auth/isAssistantSupervisor"];
    },
    isTechnicalAssistant() {
      return store.getters["auth/isTechnicalAssistant"];
    },
    isAdvisorB() {
      return store.getters["auth/isAdvisor"];
    },
    isFilter() {
      return store.getters["auth/isFilter"];
    },
    isCeo() {
      return store.getters["auth/isCeo"];
    },
    isAgent() {
      return store.getters["auth/isAgent"];
    },
    isSellerC() {
      return store.getters["auth/isSeller"];
    },
    isChief() {
      return store.getters["auth/isChief"];
    },
    isAdministrator() {
      return store.getters["auth/isAdministrator"];
    },
    isCoordinator() {
      return store.getters["auth/isCoordinator"];
    },
    isTeamLeader() {
      return store.getters["auth/isTeamLeader"];
    },
    isTeamLeaderDesign() {
      return store.getters["auth/isTeamLeaderDesign"];
    },
    isAdminSpecialist() {
      return store.getters["auth/isAdminSpecialist"];
    },
    isParagonDesigner() {
      return store.getters["auth/isParagonDesigner"];
    },
  },
  methods: {
    showSimpleInputSwal({
      input = "text",
      title = "Message",
      inputPlaceholder = "Type your message here...",
      showCancelButton = true,
    }) {
      return this.$swal({
        input,
        title,
        inputPlaceholder,
        showCancelButton,
        inputValidator: (value) => {
          return !value && "You need to write something!";
        },
      });
    },

    scrollToDiv(refContext) {
      const element = refContext;
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    },
    convertProgramToModule(program, moduleChild = 0) {
      let module = 0;
      switch (program) {
        case 0:
          module = 0;
          break;
        case 1:
          module = 3;
          break;
        case 2:
          module = 7;
          break;
        case 3:
          module = 6;
          if (moduleChild === 22) module = 22;
          break;
        case 4:
          module = 5;
          break;
        case 5:
          module = 8;
          break;
        case 6:
          module = 10;
          break;
        case 7:
          module = 11;
          break;
        case 8:
          module = 14;
          break;
        case 9:
          module = 12;
          break;
        case 15:
          module = 15;
          break;
        case 22:
          module = 22;
          break;
        case null:
          module = null;
      }

      return module;
    },
    convertModuleToProgramString(program) {
      let module = 0;
      if (program == 0) {
        module = 0;
      } else {
        // eslint-disable-next-line default-case
        switch (program) {
          case "1":
            module = 3;
            break;
          case "2":
            module = 7;
            break;
          case "3":
            module = 6;
            break;
          case "4":
            module = 5;
            break;
          case "5":
            module = 8;
            break;
          case "6":
            module = 10;
            break;
          case "7":
            module = 11;
            break;
          case "8":
            module = 14;
            break;
          case "9":
            module = 12;
            break;
          case "15":
            module = 15;
            break;
          case "sn":
            module = 15;
            break;
          case "crm":
            module = 2;
            break;
          case "adm":
            module = 4;
            break;
          case "connection":
            module = 20;
            break;
          case "supervisorCrm":
            module = 2;
            break;
        }
      }
      return module;
    },

    convertoModuleToProgramC(module) {
      switch (module) {
        case 3:
          module = 1;
          break;
        case 5:
          module = 4;
          break;
        case 6:
          module = 3;
          break;
        case 7:
          module = 2;
          break;
        case 8:
          module = 5;
          break;
        case 10:
          module = 6;
          break;
        case 11:
          module = 7;
          break;
        case 12:
          module = 9;
          break;
        case 14:
          module = 8;
          break;
        case 23:
          module = 3;
          break;
      }

      return module;
    },

    getModuleName(module) {
      switch (module) {
        case 2:
          return "crm";
        case 3:
          return "bussiness";
        case 4:
          return "administration";
        case 5:
          return "debtsolution";
        case 6:
          return "creditexperts";
        case 7:
          return "boostcredit";
        case 8:
          return "taxresearch";
        case 10:
          return "claimdepartment";
        case 11:
          return "specialists";
        case 12:
          return "ti";
        case 14:
          return "bookeeping";
        case 15:
          return "socialnetwork";
        case 17:
          return "human-talent";
        case 18:
          return "quality";
        case 19:
          return "logistic";
      }
    },

    getModuleNameString(module) {
      switch (module) {
        case 17:
          return "HUMAN TALENT";
        case 19:
          return "LOGISTIC";
      }
    },

    getProgramLogo(module) {
      switch (module) {
        case "Debt Solution":
          return `${this.baseImg}/assets/images/logos/logo-ds.png`;
        case "Credit Experts":
          return `${this.baseImg}/assets/images/logos/logo-ce.png`;
        case "Boost Credit":
          return `${this.baseImg}/assets/images/logos/logo-bc.png`;
        case "Paragon":
          return `${this.baseImg}/assets/images/logos/paragon.png`;
        case "Tax Research":
          return `${this.baseImg}/assets/images/logos/logo-tr.png`;
        case "Business":
          return `${this.baseImg}/assets/images/logos/logo-bu.png`;
        case "Quality":
          return `/assets/images/logos/logo-qu.png`;
        case "Specialist":
          return `${this.baseImg}/assets/images/logos/logo-specialists.png`;
        case "Logistic":
          return `${this.baseImg}/assets/images/logos/logo-logistic.png`;
        case "Human Talent":
          return `${this.baseImg}/assets/images/logos/logo-rrhh.png`;
      }
    },

    /* GENERIC TOAST */
    showGenericToast({
      variant = "success",
      position = "top-right",
      title = "Congratulations",
      icon = "CheckIcon",
      text = "You've successfully done it!",
    }) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        }
      );
    },

    showTimedToast({
      variant = "success",
      position = "top-right",
      icon = "CheckIcon",
      text = "You've successfully done it!",
      timeout = 10000,
      closeOnClick = false,
      pauseOnFocusLoss = false,
      pauseOnHover = false,
      showCloseButtonOnHover = false,
      hideProgressBar = false,
      // closeButton = "button",
      title = "I'm a toast!",
    }) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
          timeout,
          closeOnClick,
          pauseOnFocusLoss,
          pauseOnHover,
          showCloseButtonOnHover,
          hideProgressBar,
          // closeButton,
          title,
        }
      );
    },

    /* TOAST */
    showToast(
      variant = "success",
      position = "top-right",
      title = "Congratulations",
      icon = "CheckIcon",
      text = "You've successfully done it!"
    ) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        }
      );
    },

    /** *** SWALS **** */
    showGenericConfirmSwal({
      title = "Are you sure?",
      text = "You won't be able to revert this!",
      config = {},
    }) {
      return this.$swal({
        title,
        text,
        imageUrl: swalWarningIcon,
        imageWidth: 70,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-outline-danger",
        },
        ...config,
      });
    },
    showAmgAlert(
      title = "Are you sure?",
      subtitle = "You won't be able to revert this!",
      data = [],
      ok = () => {
        console.log("ok");
      },
      okTitle = "OK"
    ) {
      this.$amgAlert({
        title,
        subtitle,
        data,
        okTitle,
        ok,
      });
    },

    showConfirmSwal(
      title = "Are you sure?",
      text = "You won't be able to revert this!",
      config = {}
    ) {
      return this.$swal({
        title,
        text,
        imageUrl: swalWarningIcon,
        imageWidth: 70,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-outline-danger  ",
        },
        ...config,
      });
    },

    showGenericSuccessSwal({
      title = "Success!",
      text = "Successful Process",
      html = "",
    }) {
      return this.$swal({
        title,
        text,
        imageUrl: swalSuccessIcon,
        imageWidth: 70,
        html,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    showSuccessSwal(
      title = "Success!",
      text = "Successful Process",
      html = ""
    ) {
      return this.$swal({
        title,
        text,
        imageUrl: swalSuccessIcon,
        imageWidth: 70,
        html,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    showInfoSwal(text = "Important Information", title = "Very Important!") {
      this.$swal({
        title,
        text,
        imageUrl: swalInfoIcon,
        imageWidth: 70,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    showInfoSwalCaro(
      text = "Important Information",
      title = "Very Important!"
    ) {
      return this.$swal({
        title,
        text,
        imageUrl: swalInfoIcon,
        imageWidth: 70,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    showQuestionSwal(
      title = "Do you want to perform this action?",
      text = "You won't be able to revert this!",
      confirm_button = "Yes",
      cancel_button = "No",
      icon = "question",
      config = {}
    ) {
      return this.$swal({
        title,
        text,
        icon: icon,
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: confirm_button,
        cancelButtonText: cancel_button,
        customClass: {
          confirmButton: "btn btn-outline-primary mr-1",
          cancelButton: "btn btn-outline-danger  ",
        },
        ...config,
      });
    },

    showWarningSwal(
      title = "Very Important!",
      text = "Important Information",
      html = ""
    ) {
      this.$swal({
        title,
        text,
        imageUrl: swalWarningIcon,
        imageWidth: 70,
        html,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    showErrorSwal(
      error,
      title = "Sorry, there was an error... try again or contact support !!!",
      icon = "error"
    ) {
      this.$swal({
        html: `<h4><b>${title}</b></h4> <br/> <span class="font-small-3 text-danger">${error}</span>`,
        imageUrl: icon === "warning" ? swalWarningIcon : swalErrorIcon,
        imageWidth: 70,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
    },

    isResponseSuccess(response) {
      return (
        response &&
        response.status &&
        String(response.status).substr(0, 2) === "20"
      );
    },

    getAuthorizeErrors(errors) {
      const errorsAuthorize = [];
      for (const key in errors) {
        errorsAuthorize.push(errors[key].errorText);
      }
      return `${errorsAuthorize.join("<br>")}`;
    },
    getInternalErrors(error, split = "\n") {
      try {
        if (error.response && error.response.data) {
          const message =
            error.response.data.message || "Something went wrong.";
          const errors = [];
          for (const key in error.response.data.errors) {
            errors.push(error.response.data.errors[key]);
          }
          return `${message + split}${errors.join(`.${split}`)}`;
        }
        return "Something went wrong";
      } catch (error) {
        console.log("Something went wrong getInternalErrors:", error);
        return "Something went wrong";
      }
    },
    toggleModal(tagId, state = true) {
      if (tagId && state) this.$bvModal.show(tagId);
      if (tagId && !state) this.$bvModal.hide(tagId);
    },
    getCurrentMonth() {
      return this.$moment().format("MMM");
    },
    addPreloader() {
      store.commit("app/SET_LOADING", true);
    },
    removePreloader() {
      store.commit("app/SET_LOADING", false);
    },
    range(start, end) {
      const arr = [];
      for (let i = start; i <= end; i += 1) {
        arr.push(i);
      }
      return arr;
    },
    isPreloading(state = true) {
      store.commit("app/SET_LOADING", state);
    },
    returnThis() {
      return this;
    },
    //Add Padding To   td en small tables
    addPaddingTd() {
      this.fields.map((field) => {
        field.tdClass = "py-1";
      });
    },
    async forceFileDownloadPdf(response) {
      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: "application/pdf",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      // document.body.removeChild(a);
      // URL.revokeObjectURL(url);
    },
    async getUrlBlobPdf(blob) {
      const url = window.URL.createObjectURL(
        new Blob([blob], {
          type: "application/pdf",
        })
      );
      return url;
    },
    //Export to Excel General
    async forceFileDownload(response, nameExcel, type = null) {
      const url = window.URL.createObjectURL(
        new Blob([response], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      if (type == "blank") {
        link.setAttribute("target", "_blank");
      } else {
        link.setAttribute("download", nameExcel);
      }
      document.body.appendChild(link);
      link.click();
    },
    $evaluate: (param) => eval("this." + param),

    validateIfIsJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },

    hexToRgb(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
          result[3],
          16
        )}`
        : "0, 0, 0";
    },

    convertMSeconds(seconds) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      seconds = Math.floor(seconds % 60);
      let result = "";

      if (hours > 0) {
        result += hours + (hours == 1 ? " hour, " : " hours, ");
      }

      if (minutes > 0) {
        result += minutes + (minutes == 1 ? " minute, " : " minutes, ");
      }

      if (seconds > 0) {
        result += seconds + " seconds";
      }

      if (hours === 0 && minutes === 0 && seconds != 0) {
        result = seconds + " seconds";
      }

      return result;
    },

    convertMSecondsMin(seconds) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      seconds = Math.floor(seconds % 60);
      let result = "";

      if (hours > 0) {
        result += `${hours}:`;
      }

      if (minutes > 0) {
        result += `${minutes}:`;
      }

      result += seconds;

      if (hours === 0 && minutes === 0) {
        result = seconds + "s";
      }

      let time = "s";
      if (hours > 0) {
        time = "h";
      } else if (minutes > 0) {
        time = "m";
      }
      return result + time;
    },

    jsonParse(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return [];
      }
      return JSON.parse(str) || [];
    },

    generateTimeOptions(start, end, interval) {
      let options = [];
      const startTime =
        start == null ? moment().startOf("day") : moment(start, "HH:mm:ss");
      const endTime =
        end == null ? moment().endOf("day") : moment(end, "HH:mm:ss");

      while (startTime.isBefore(endTime)) {
        const time = {
          value: startTime.format("HH:mm:ss"),
          text: startTime.format("HH:mm"),
        };
        options.push(time);

        startTime.add(interval, "minutes");
      }

      return options;
    },

    formatAddress(item) {
      if (item.route) {
        const streetNumber = item.street_number || "";
        const route = item.route || "";
        const city = item.city || "";
        const stateSlug = item.state_slug || "";
        const postalCode = item.postal_code || "";
        return `${streetNumber} ${route}<br>${city}, ${stateSlug} ${postalCode}`;
      } else {
        const address = item.address || "";
        const city = item.city || "";
        const stateSlug2 = item.state_slug_2 || "";
        const postalCode = item.postal_code || "";
        return `${address}<br>${city}, ${stateSlug2} ${postalCode}`;
      }
    },

    generateUniqueId() {
      const crypto = window.crypto || window.msCrypto;
      if (!crypto || !crypto.getRandomValues) {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            const r = (Math.random() * 16) | 0;
            const v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          }
        );
      } else {
        return crypto.randomUUID();
      }
    },
    /*
      It takes an array of fields and returns the same array of fields
      with the visibility option set for the fieldKey given
      Note: combine it with a computed property on the fields array with
      visiblity in true and then inject that computed property in the prop
      :filter to make it work properly
      An example can be found in: @/views/crm/views/dashboard/modals/DetailedSales.vue
      Computed property: visibleFields
    */
    setFieldVisibility(fieldsArray, fieldKey, visibilityOption = false) {
      return fieldsArray.map((field) => {
        if (field.key === fieldKey) {
          return {
            ...field,
            visible: visibilityOption,
          };
        }
        return field;
      });
    },
    routerDashboardRedirectioner(programId, clientAccountId) {
      let route = "";
      // eslint-disable-next-line default-case
      switch (programId) {
        case 1:
          route = `/bussiness/clients/account/${clientAccountId}`;
          break;
        case 2:
          route = `/boostcredit/clients/account/${clientAccountId}`;
          break;
        case 3:
          route = `/cedigital/customerservice/clients/account/${clientAccountId}`;
          break;
        case 4:
          route = `/debtsolution/clients/account/${clientAccountId}`;
          break;
        case 5:
          route = `/taxresearch/clients/account/${clientAccountId}`;
          break;
        case 7:
          route = `/specialists/analyst-department/clients/account/${clientAccountId}`;
          break;
        case 8:
          route = `/bookeeping/clients/account/${clientAccountId}`;
          break;
        case 9:
          route = `/ti/clients/account/${clientAccountId}`;
          break;
      }
      return route;
    },

    routerDashboardLeadRedirectioner(moduleId, leadId) {
      let route = "";
      switch (moduleId) {
        case 2:
          route = `/crm/leads/${leadId}`;
          break;
        case 15:
          route = `/socialnetwork/leads/new/dashboard/${leadId}`;
          break;
      }
      return route;
    },

    getProgramImageRoute(programId) {
      // imgServices: require('@/assets/images/icons/dashboard-ceo/services.svg'),
      switch (programId) {
        case 0:
          return crmImage;
        case 1:
          return businessImage;
        case 2:
          return boostCreditImage;
        case 3:
          return creditExpertsImage;
        case 4:
          return debtSolutionImage;
        case 5:
          return taxResearchImage;
        case 7:
          return specialistImage;
        case 8:
          return keybookImage;
        case 9:
          return paragonImage;
        default:
          return crmImage;
      }
    },
    geModuleImageRoute(moduleId) {
      // imgServices: require('@/assets/images/icons/dashboard-ceo/services.svg'),
      switch (moduleId) {
        case 2:
          return "/assets/images/modules-icons/crm.png";
        case 3:
          return "/assets/images/modules-icons/business.png";
        case 4:
          return "/assets/images/modules-icons/administration.png";
        case 5:
          return "/assets/images/modules-icons/debtsolution.png";
        case 6:
          return "/assets/images/modules-icons/creditexperts.png";
        case 7:
          return "/assets/images/modules-icons/bootscredit.png";
        case 8:
          return "/assets/images/modules-icons/taxresearch.png";
        case 9:
          return "/assets/images/modules-icons/correspondence.png";
        case 11:
          return "/assets/images/modules-icons/specialists.png";
        case 12:
          return "/assets/images/modules-icons/paragon.png";
        case 14:
          return "/assets/images/modules-icons/bookeeping.png";
        default:
          return "/assets/images/modules-icons/crm.png";
      }
    },

    setCurrencyZero(number) {
      function formatPrice(value) {
        const val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (number) {
        return formatPrice(number);
      } else {
        return "0.00";
      }
    },

    convertSecondsShort(seconds) {
      var minutes = Math.floor(seconds / 60);
      var secondsLeft = seconds % 60;

      if (secondsLeft < 10) {
        secondsLeft = "0" + secondsLeft;
      }

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      return minutes + ":" + secondsLeft;
    },

    naturalDate(fecha) {
      const currentDate = new Date();
      const providedDate = new Date(fecha);

      if (isNaN(providedDate.getTime())) {
        return "";
      }

      const currentDateWithoutTime = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      const providedDateWithoutTime = new Date(
        providedDate.getFullYear(),
        providedDate.getMonth(),
        providedDate.getDate()
      );
      const differenceInMilliseconds =
        currentDateWithoutTime.getTime() - providedDateWithoutTime.getTime();
      const differenceInDays = Math.floor(
        differenceInMilliseconds / (1000 * 60 * 60 * 24)
      );

      if (differenceInDays === 0) {
        return providedDate.toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "America/Los_Angeles",
        });
      } else if (differenceInDays === 1) {
        return "Yesterday";
      } else if (differenceInDays < 7) {
        return providedDate.toLocaleDateString("en-US", { weekday: "long" });
      } else {
        return providedDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      }
    },

    forceDownloadAnyFile(url, filename, type = "blank") {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);

      if (type === "inline") {
        link.setAttribute("target", "_self");
      } else if (type === "blank") {
        link.setAttribute("target", "_blank");
        link.style.display = "none";
      } else {
        link.setAttribute("target", "_blank");
      }

      link.addEventListener("click", function (event) {
        event.preventDefault();
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";

        xhr.onload = function () {
          const blob = new Blob([xhr.response], {
            type: "application/octet-stream",
          });
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = blobUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(blobUrl);
        };

        xhr.send();
      });

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    dashboardRouteName(module_id) {
      const routers = {
        2: "dashboard-crm",
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        12: "paragon-dashboard",
        14: "bookeeping-dashboard",
        16: "management-dashboard",
        18: "quality-client-dashboard",
        20: "connection-clients-account",
        21: "ce-customer-service-dashboard",
        22: "ce-customer-service-dashboard",
        23: "financial-clients-account",
        25: "specialist-digital-dashboard",
        29: "specialists-client-dashboard-fa",
        16: "management-client-dashboard",
        99: "management-client-dashboard",
      };
      return routers[module_id] || routers[99];
    },
    dashboardLeadRouteName(module_id) {
      const routers = {
        2: "lead-show",
        3: "bussiness-lead-show",
        4: "administration-lead-show",
        5: "debtsolution-lead-show",
        6: "creditexperts-lead-show",
        7: "boostcredit-lead-show",
        8: "tax-research-lead-show",
        10: "claimdepartment-lead-show",
        11: "specialists-leads-show",
        12: "ti-lead-show",
        14: "bookeeping-lead-show",
        20: "connection-lead-show",
        22: "customerservice-lead-show",
        23: "financial-leads-lead-show",
        25: "specialist-dg-lead-show",
        26: "sales-lead-show",
        99: "lead-show",
      };
      return routers[module_id] || routers[99];
    },
    numberFormat(value, locale = 'en-US', currency = 'USD') {
      let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency// Cambia a 'MXN', 'EUR', u otra moneda según necesites
      });
      // Formatear el monto utilizando el objeto formatter
      return formatter.format(value);
    },
    getProgramIdByName(name) {
      const programs = {
        BUSINESS: 1,
        "BOOST CREDIT": 2,
        "CREDIT EXPERTS": 3,
        "DEBT SOLUTION": 4,
        "TAX RESEARCH": 5,
        "COURT INFO": 6,
        SPECIALIST: 7,
        KEYBOOK: 8,
        PARAGON: 9,
      };
      name = name.trim().toUpperCase();
      const programId = programs[name] ?? null;
      return programId;
    },
  },
};
