import { amgApi } from "@/service/axios"

class OtherPaymentsService {
  async getOtherPayment(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/get-other-payments",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getOtherChecksPayment(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/get-other-checks-payment",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getClientTransactionMethods() {
    try {
      const data = await amgApi.get(
        "/management/other-payments/get-client-transaction-methods"
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async saveOtherPayment(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/save-other-payment",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async changeStatusOtherPayment(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/change-status-other-payment",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async changeStatusCheckPayment(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/change-status-check-payment",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async generateVoucher(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/generate-voucher",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async saveDeposit(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/save-deposit",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getTrackingOtherPayment(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/get-tracking-other-payment",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getCashAmount(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/get-cash-amount",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async payCashSelected(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/pay-cash-selected",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getCountersOP(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/get-counters-op",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async searchClient(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/search-client",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getOtherPaymentByCharge(params) {
    try {
      const data = await amgApi.post(
        "/management/other-payments/get-other-payments-by-charge",
        params
      )
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}

export default new OtherPaymentsService()
