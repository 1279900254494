export default [
  {
    path: "users",
    name: "ce-digital-users",
    redirect: { name: "ce-digital-users-list" },
    component: () => import(/* webpackChunkName: "CEDSettingsUsers" */ "@/views/ce-digital/sub-modules/settings/views/users/view/components/MainUser.vue"),
    meta: {
      pageTitle: "Users",
      route: "settings",
      permittedRoles: [1, 2, 17],
      breadcrumb: [
        {
          text: "Users",
        },
      ]
    },
    children: [
      {
        path: "credit-experts",
        name: "ce-digital-users-list",
        component: () => import(/* webpackChunkName: "CEDSettingsUsersCreditExperts" */ "@/views/ce-digital/sub-modules/settings/views/users/view/components/UserList.vue"),
        meta: {
          module_c: 6,
          route: "settings",
          isClientsTab: true,
          pageTitle: "Users",
          permittedRoles: [1, 2, 17],
          breadcrumb: [
            {
              text: "Credit Experts",
              active: true,
            },
          ],
        },
      },
      {
        path: "credit-experts-digital",
        name: "ce-digital-users-list-credit-expert-digital",
        component: () => import(/* webpackChunkName: "CEDSettingsUsersCreditExpertsDigital" */ "@/views/ce-digital/sub-modules/settings/views/users/view/components/UserList.vue"),
        meta: {
          module_c: 21,
          route: "settings",
          isClientsTab: true,
          pageTitle: "Users",
          permittedRoles: [1, 2, 17],
          breadcrumb: [
            {
              text: "Credit Experts Digital",
              active: true,
            },
          ],
        },
      },
      {
        path: "connection",
        name: "ce-digital-users-list-connection",
        component: () =>
          import(
            "../view/components/UserList.vue"
          ),
        meta: {
          module: 24,
          route: "settings",
          isClientsTab: true,
          pageTitle: "Users",
          permittedRoles: [1, 2, 17],
          breadcrumb: [
            {
              text: "Connection",
              active: true,
            },
          ],
          module_c:20
        },
      },
    ],
  },
];
