 <template>
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.68387L9.8927 7.01949L1 12.3551V1.68387Z"
      :fill="color"
      :stroke="color"
      stroke-width="1.77854"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

  
<script>
export default{
  props: {
    color: {
      type: String,
      default: '#0077E6'
    }
  }
}
</script>