<template>
  <div>
    <filter-slot
      paginate-left
      class="filter-slot-new-customization"
      :filter="searchGlobal ? [] : filteredFilters"
      :no-visible-principal-filter="searchGlobal ? true : false"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTableReload()"
      :changeColumnsByClient="true"
      :top-paginate="false"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refCustomerTicketsList"
          :items="realTime ? dataGrid : getCustomerTicketsProvider"
          :fields="searchGlobal ? filteredFieldsSearch : filteredFields"
          primary-key="id"
          table-class="text-nowrap"
          sticky-header="65vh"
          show-empty
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :head-row-variant="'grid-custom-tickets-header'"
          :tbody-tr-class="'rowClass'"
          class="table-scroll-per position-relative table-new-customization custom-table"
          style="border-radius: 7px"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(priority)="{ item }">
            <b-badge :variant="colorPriority(item)">
              {{ item.priority.toUpperCase() }}
            </b-badge>
          </template>
          <template #cell(code)="data">
            <template v-if="data.item.status === 'draft'">
              <div class="d-flex align-items-center">
                <b-badge
                  variant="light-warning"
                  v-b-tooltip.hover.top="'Edit Ticket'"
                  class="cursor-pointer"
                  style="font-size: 13px"
                  @click="openModalCreateCustomerTicket(data.item)"
                >
                  PENDING
                  <feather-icon icon="EditIcon" size="15" />
                </b-badge>
              </div>
            </template>
            <template v-else>
              <b-badge
                @click="openModalViewTicketCustomerDetails(data.item)"
                variant="light-primary"
                class="cursor-pointer"
                style="font-size: 14px"
                v-b-tooltip.hover.top="'See Details'"
              >
                {{ data.item.code }}
                <feather-icon icon="ArrowUpRightIcon" size="15" />
              </b-badge>
              <timer
                style="margin: 5px 0 0 0"
                :created-at="data.item.created_at"
                :status="data.item.id_status"
                :updated-at="data.item.updated_at"
                :due-date="data.item.due_date"
              />
            </template>
          </template>
          <template #cell(detail_expense)="{ item }">
            <div>
              <template v-if="item.detail_expense">
                <div>
                  <p class="m-0">
                    <strong>Card:</strong>
                    {{ item.detail_expense.cardnumber }}
                  </p>
                </div>
                <div>
                  <p class="m-0">
                    <strong>Vendor:</strong> {{ item.detail_expense.vendor }}
                  </p>
                </div>
                <div>
                  <p class="m-0">
                    <strong>Concept:</strong>
                    {{ item.detail_expense.concept }}
                  </p>
                </div>
                <div>
                  <p class="m-0">
                    <strong>Date:</strong>
                    {{ item.detail_expense.date_expense | myGlobal }}
                  </p>
                </div>
                <div>
                  <p class="m-0">
                    <strong>Amount:</strong>
                    {{ item.detail_expense.amount | currencyTwo | myMoney }}
                  </p>
                </div>
              </template>
              <template v-if="item.detail_expense_transaction">
                <div>
                  <p class="m-0">
                    <strong>Card:</strong>
                    {{ item.detail_expense_transaction.cardnumber }}
                  </p>
                </div>
                <div>
                  <p class="m-0">
                    <strong>Description:</strong>
                    {{ item.detail_expense_transaction.description }}
                  </p>
                </div>

                <div>
                  <p class="m-0">
                    <strong>Date:</strong>
                    {{
                      item.detail_expense_transaction.transaction_date
                        | myGlobal
                    }}
                  </p>
                </div>
                <div>
                  <p class="m-0">
                    <strong>Amount:</strong>
                    {{
                      item.detail_expense_transaction.amount
                        | currencyTwo
                        | myMoney
                    }}
                  </p>
                </div>
              </template>
            </div>
          </template>
          <template #cell(name_client)="data">
            <strong class="m-0" @click="openModalClientDetail(data.item)">
              <div>
                <router-link
                  v-if="isCeo"
                  v-b-tooltip.hover.top="'Lead dashboard'"
                  class="customLink"
                  :to="{
                    name: 'ticket-customer-lead-show',
                    params: {
                      id: data.item.lead_id_client,
                    },
                  }"
                  target="_blank"
                  >{{ data.item.name_client }}</router-link
                >
                <router-link
                  v-else
                  v-b-tooltip.hover.top="'Lead dashboard'"
                  class="text-capitalize customLink"
                  :to="`/${routeModule(currentUser.modul_id)}/leads/${
                    currentUser.modul_id === 2 || currentUser.modul_id === 4
                      ? ''
                      : currentUser.modul_id === 15
                      ? 'new/dashboard/'
                      : 'show/'
                  }${data.item.lead_id_client}`"
                  target="_blank"
                  >{{ data.item.name_client }}</router-link
                >
                <feather-icon
                  icon="AlertCircleIcon"
                  size="20"
                  @click="openSeeClientRelevantInformation(data.item)"
                  class="ml-1 cursor-pointer"
                  v-b-tooltip:hover
                  title="See more information"
                />
              </div>
              <div v-if="data.item.number_phone">
                {{ data.item.number_phone }}
              </div>
            </strong>
            <div v-if="data.item.accounts">
              <ul class="p-0">
                <li
                  v-for="account in uniqueAccounts(data.item)"
                  :key="account.id"
                  class="d-flex"
                  style="align-items: center; gap: 1rem"
                >
                  <status-account :account="account" :text="false" />

                  <router-link
                    :class="
                      account.id == data.item.client_account_id
                        ? 'text-success'
                        : 'text-primary'
                    "
                    :to="{
                      name: dashboardRouteNameByClient(account),
                      params: {
                        idClient: account.id,
                      },
                    }"
                    target="_blank"
                  >
                    {{ account.account }}
                  </router-link>
                </li>
              </ul>
            </div>
          </template>
          <template #cell(motive)="data">
            <div class="d-flex flex-column align-items-center">
              <h5>
                <b-badge size="sm" variant="light-info">
                  {{ data.item.motive }}
                </b-badge>
              </h5>
              <b-button
                size="sm"
                variant="outline-info"
                style="margin-top: 10px"
                @click="openModalShow(data.item)"
              >
                Show description
                <b-icon icon="info-circle" />
              </b-button>
              <div
                style="margin-top: 10px"
                v-if="
                  data.item.request_fidelized &&
                  isCeo &&
                  data.item.id_status == 3
                "
              >
                <div class="d-flex justify-content-between">
                  <strong class="text-warning">
                    {{ JSON.parse(data.item.request_fidelized).status }}:
                  </strong>
                  <feather-icon
                    icon="ListIcon"
                    size="15"
                    class="cursor-pointer text-primary font-weight-bolder"
                    @click="openDetailRequestFidelized(data.item)"
                    v-b-tooltip="`Show History`"
                  />
                </div>
                <div>
                  <b-form-textarea
                    v-model="
                      JSON.parse(data.item.request_fidelized).observations
                    "
                    plaintext
                    disabled
                    style="padding: 0.5rem"
                    :style="
                      isDarkSkin
                        ? 'color: #d8d8d8 !important; background-color: rgb(203 202 202 / 21%) !important;border-radius:5px'
                        : 'color: #6e6b7b !important;background-color: rgb(203 202 202 / 21%) !important;border-radius:5px'
                    "
                  />

                  <b-button-group
                    v-if="
                      isCeo &&
                      JSON.parse(data.item.request_fidelized).status ==
                        'REQUEST'
                    "
                    class="w-100"
                    style="margin-top: 10px"
                  >
                    <b-button
                      size="sm"
                      variant="outline-success"
                      @click="openSolveTicketModal('fidelized', data.item)"
                    >
                      SOLVED
                    </b-button>
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      @click="requestTicketFidelized(data.item, 2)"
                    >
                      REJECT
                    </b-button>
                  </b-button-group>
                </div>
              </div>
            </div>
          </template>
          <template #cell(status)="data">
            <div>
              <div class="text-center" style="margin-bottom: 0.5rem">
                <span
                  style="
                    border-radius: 5px;
                    padding: 5px 15px !important;
                    color: white !important;
                    font-weight: bold !important;
                    width: 100%;
                  "
                  :class="colorStatus(data.item)"
                >
                  {{ data.item.status.toUpperCase() }}
                </span>
                <tabler-icon
                  :id="`status_${data.item.id}`"
                  icon="InfoCircleIcon"
                  size="20"
                  class="ml-1"
                  :class="textColor(data.item)"
                />
                <b-tooltip
                  :variant="variantText(data.item)"
                  placement="right"
                  :target="`status_${data.item.id}`"
                >
                  {{ data.item.description_status }}
                </b-tooltip>
                <tabler-icon
                  v-if="showSendIcon(data.item)"
                  icon="SendIcon"
                  size="20"
                  style="margin-left: 5px"
                  @click="requestTicketFidelized(data.item)"
                  class="text-warning clickable"
                  v-b-tooltip="`Request Fidelized`"
                />
                <div
                  style="margin-top: 10px"
                  v-if="data.item.request_fidelized && data.item.id_status == 3"
                >
                  <div class="d-flex justify-content-between">
                    <strong class="text-warning">
                      {{ JSON.parse(data.item.request_fidelized).status }}:
                    </strong>
                    <feather-icon
                      icon="ListIcon"
                      size="15"
                      class="cursor-pointer text-primary font-weight-bolder"
                      @click="openDetailRequestFidelized(data.item)"
                      v-b-tooltip="`Show History`"
                    />
                  </div>
                  <b-form-textarea
                    v-model="
                      JSON.parse(data.item.request_fidelized).observations
                    "
                    plaintext
                    disabled
                    style="padding: 0.5rem"
                    :style="
                      isDarkSkin
                        ? 'color: #d8d8d8 !important; background-color: rgb(203 202 202 / 21%) !important;border-radius:5px'
                        : 'color: #6e6b7b !important;background-color: rgb(203 202 202 / 21%) !important;border-radius:5px'
                    "
                  />
                </div>
              </div>
            </div>
            <b-row v-if="data.item.observations">
              <b-col cols="12">
                <div class="d-flex justify-content-between">
                  <strong class="text-warning">OBSERVATIONS:</strong>
                  <feather-icon
                    v-if="data.item.request_fidelized"
                    icon="ListIcon"
                    size="15"
                    class="cursor-pointer text-primary font-weight-bolder"
                    @click="openDetailRequestFidelized(data.item)"
                    v-b-tooltip="`Show History`"
                  />
                </div>
                <b-form-textarea
                  v-model="data.item.observations"
                  plaintext
                  disabled
                  style="padding: 0.5rem"
                  :style="
                    isDarkSkin
                      ? 'color: #d8d8d8 !important; background-color: rgb(203 202 202 / 21%) !important;border-radius:5px'
                      : 'color: #6e6b7b !important;background-color: rgb(203 202 202 / 21%) !important;border-radius:5px'
                  "
                />
              </b-col>
            </b-row>
          </template>
          <template #cell(last_payment)="data">
            <div
              v-for="(account, index) in JSON.parse(data.item.programs)"
              :key="index"
            >
              <b-badge
                :variant="
                  account.transactions.state_lp == 0
                    ? 'light-success'
                    : 'light-danger'
                "
                style="font-size: 13px; margin-bottom: 5px"
                class="font-weight-bolder cursor-pointer"
                @click="openPaymentDetailCustomer(account, data.item.id)"
              >
                {{ account.account }}
              </b-badge>
            </div>
          </template>
          <template #cell(created_at)="data">
            <span>
              {{ data.item.created_by }}
            </span>
            <br />
            <span>
              {{ data.item.created_at | myGlobalDay }}
            </span>
          </template>
          <template #cell(updated_at)="data">
            <div class="row align-items-center">
              <div class="col-5 p-0">
                <b-avatar
                  variant="info"
                  :src="
                    data.item.user_image
                      ? data.item.user_image
                      : require('@/assets/images/logo/amg_logo.svg')
                  "
                ></b-avatar>
              </div>
              <div class="col-7 p-0">
                <div
                  v-if="isChiefTab || !$route.meta.tab || searchGlobal"
                  v-html="validateTextByStatus(data.item)"
                ></div>

                <span>
                  {{
                    isChiefTab || !$route.meta.tab || searchGlobal
                      ? validateDateByStatus(data.item)
                      : formatDate(data.item.updated_at)
                  }}
                </span>
              </div>
            </div>
          </template>
          <template #cell(participants)="data">
            <strong class="mr-1">{{ data.item.participants }}</strong>
            <template>
              <b-button
                :id="`participant_${data.item.title}`"
                v-b-tooltip.hover.v-info.up
                :title="
                  currentUser.user_id === 1 && data.item.id_status !== 4
                    ? 'Add'
                    : 'View'
                "
                variant="info"
                size="sm"
                @click="openModalParticipants(data.item)"
              >
                <tabler-icon
                  :icon="
                    currentUser.user_id === 1 && data.item.id_status !== 4
                      ? 'UserPlusIcon'
                      : 'UsersIcon'
                  "
                  size="15"
                />
              </b-button>
            </template>
          </template>
          <template #cell(chat)="data">
            <div class="d-flex flex-column justify-content-center">
              <div class="d-flex justify-content-center">
                <div
                  class="bg-info rounded-circle cursor-pointer"
                  v-if="
                    !(
                      [1].includes(data.item.id_status) &&
                      (isChiefTab || (customerAccount && !isCeo))
                    )
                  "
                  :id="'tooltip-target-' + data.index"
                  @click="openCustomerChat(data.item)"
                >
                  <img
                    variant="info"
                    :src="require('@/assets/images/chat/chat.svg')"
                    class="p-1"
                  />
                  <b-badge
                    v-if="data.item.pending_messages > 0"
                    variant="danger"
                    pill
                    class="position-absolute top-0 start-100 translate-middle"
                  >
                    {{ data.item.pending_messages }}
                  </b-badge>
                </div>
              </div>

              <b-tooltip
                :target="'tooltip-target-' + data.index"
                placement="right"
                variant="success"
                triggers="hover"
                class="custom-tooltip"
              >
                <strong style="color: #585858 !important">{{
                  getTooltipContent(data.item)
                }}</strong>
                <br />
                <div
                  v-if="
                    JSON.parse(data.item.object_chat) &&
                    ![1, 8].includes(JSON.parse(data.item.object_chat).type_id)
                  "
                >
                  <strong style="color: #585858 !important">
                    <feather-icon
                      v-if="JSON.parse(data.item.object_chat).type_id === 4"
                      icon="ImageIcon"
                      size="15"
                    />
                    <feather-icon
                      v-else-if="
                        JSON.parse(data.item.object_chat).type_id === 2
                      "
                      icon="FileIcon"
                      size="15"
                    />
                    <feather-icon
                      v-else-if="
                        JSON.parse(data.item.object_chat).type_id === 3
                      "
                      icon="MicIcon"
                      size="15"
                    />
                    <feather-icon
                      v-else-if="
                        JSON.parse(data.item.object_chat).type_id === 5
                      "
                      icon="FileIcon"
                      size="15"
                    />
                    {{ JSON.parse(data.item.object_chat).type }}
                  </strong>
                </div>
                <strong v-else style="color: #585858 !important">
                  {{ getTooltipMessage(data.item) }}
                </strong>
              </b-tooltip>
              <div v-if="JSON.parse(data.item.message_tag_details)">
                <ul
                  style="
                    list-style-type: none;
                    padding: 0 1px 0 0;
                    margin-top: 5px;
                  "
                >
                  <li
                    v-for="(message, index) in deleteLastInArray(
                      JSON.parse(data.item.message_tag_details)
                    )"
                    :key="index"
                    style="margin-bottom: 5px"
                  >
                    <div class="message-container">
                      <span class="badge bg-primary rounded-pill">@</span>
                      <strong class="text-primary" style="margin-left: 3px">{{
                        truncateText(message.message_tag, 30)
                      }}</strong>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <template #cell(actions)="data">
            <div v-if="!searchGlobal">
              <!-- {{  }} -->
              <b-button-group
                v-if="isDisabledStatus(data.item.id_status)"
                size="sm"
                vertical
              >
                <b-button
                  v-if="data.item.id_status === 3"
                  v-b-tooltip.hover.v-primary
                  variant="outline-primary"
                  size="sm"
                  @click="openSolveTicketModal(null, data.item)"
                >
                  <strong>SOLVED</strong>
                </b-button>

                <b-button
                  v-if="
                    data.item.id_status === 3 && currentTabType !== 'expense'
                  "
                  variant="outline-warning"
                  size="sm"
                  @click="openSolveTicketModal(7, data.item)"
                >
                  <strong>NOT SOLVED</strong>
                </b-button>

                <b-button
                  v-if="data.item.id_status === 2"
                  variant="outline-warning"
                  size="sm"
                  @click="sendToInProgress(data.item)"
                >
                  <strong>SEND IN PROGRESS</strong>
                </b-button>

                <b-button
                  v-if="data.item.id_status === 2"
                  variant="outline-danger"
                  no-caret
                  size="sm"
                  @click="openSolveTicketModal(4, data.item)"
                >
                  <strong>REJECTED</strong>
                </b-button>

                <b-badge
                  v-if="data.item.id_status === 1"
                  variant="light-secondary"
                  pill
                >
                  DRAFT
                </b-badge>
              </b-button-group>
              <strong v-else class="text-info">NO ACTIONS</strong>
            </div>
            <div v-else>
              <b-button
                v-b-tooltip.hover.v-primary
                variant="outline-primary"
                size="sm"
                @click="redirect(data.item)"
              >
                <strong
                  >View Ticket
                  <feather-icon icon="SearchIcon" size="15" />
                </strong>
              </b-button>
            </div>
          </template>
          <template #cell(meetings)="data">
            <div v-if="data.item.id_status === 3" class="text-center">
              <!--ACTIONS-->
              <b-button-group size="sm">
                <b-button
                  v-if="isCeo || isChiefTab"
                  v-b-tooltip.hover.v-primary
                  v-b-tooltip.hover.bottom
                  variant="primary"
                  size="sm"
                  title="ADD MEETINGS"
                  @click="modalMeetings(data.item)"
                >
                  <feather-icon icon="ClockIcon" size="15" />
                </b-button>
                <b-button
                  v-b-tooltip.hover.v-success
                  v-b-tooltip.hover.bottom
                  variant="success"
                  size="sm"
                  title="LIST MEETINGS"
                  @click="showModalMeetingTracking(data.item)"
                >
                  <feather-icon icon="ListIcon" size="15" />
                  <b-badge
                    v-if="data.item.pending_see_meetings > 0"
                    variant="danger"
                    pill
                    class="position-absolute top-0 start-100 translate-middle"
                  >
                    {{ data.item.pending_see_meetings }}
                  </b-badge>
                </b-button>
              </b-button-group>
              <br />
              <!--DATE-->
              <b-dropdown
                v-if="!!data.item.next_meeting && isCeo && isInProgressTab"
                id="meetings-dropdown"
                text="Meetings"
                variant="warning"
                split
                split-variant="outline-warning"
                class="mt-1"
              >
                <template #button-content>
                  <strong class="align-middle">
                    {{ JSON.parse(data.item.next_meeting).date | myGlobal
                    }}<br />
                    {{
                      JSON.parse(data.item.next_meeting).hour_start | myFullTime
                    }}
                    -
                    {{
                      JSON.parse(data.item.next_meeting).hour_end | myFullTime
                    }}
                  </strong>
                </template>
                <b-dropdown-item @click="attendTicked(data.item)">
                  <span class="text-success d-flex w-100">
                    <feather-icon icon="UserCheckIcon" size="15" class="mr-1" />
                    Attended
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="notAnswerTicket(data.item)">
                  <span class="text-danger d-flex w-100">
                    <feather-icon icon="UserXIcon" size="15" class="mr-1" />
                    Not Answer
                  </span>
                </b-dropdown-item>
              </b-dropdown>
              <div
                v-else-if="!!data.item.next_meeting"
                class="border-warning text-warning rounded"
                style="padding: 5px 10px; margin-top: 10px"
              >
                <strong class="align-middle">
                  {{ JSON.parse(data.item.next_meeting).date | myGlobal }}<br />
                  {{
                    JSON.parse(data.item.next_meeting).hour_start | myFullTime
                  }}
                  -
                  {{ JSON.parse(data.item.next_meeting).hour_end | myFullTime }}
                </strong>
              </div>
            </div>
          </template>
          <template #cell(task)="data">
            <b-button-group>
              <b-button
                v-b-tooltip.hover.v-info
                v-b-tooltip.hover.left
                size="sm"
                variant="info"
                title="ADD TASK"
                @click="addTask(data.item)"
              >
                <feather-icon icon="PlusSquareIcon" size="15" />
              </b-button>
              <b-button
                v-b-tooltip.hover.v-success
                v-b-tooltip.hover.bottom
                size="sm"
                :variant="
                  data.item.tasks
                    ? getColorByPendingTasks(JSON.parse(data.item.tasks))
                    : 'secondary'
                "
                title="LIST TASKS"
                @click="viewListTasks(data.item)"
              >
                <feather-icon icon="ClipboardIcon" size="15" />
                <b-badge
                  v-if="data.item.tasks"
                  pill
                  variant="danger"
                  style="position: absolute; top: -10px; right: -10px"
                >
                  {{ data.item.tasks ? JSON.parse(data.item.tasks).length : 0 }}
                </b-badge>
              </b-button>
            </b-button-group>
          </template>
          <template #head(client_ceo)>
            {{ isCeo ? "My client" : "client of ceo" }}
          </template>
          <template #cell(client_ceo)="data">
            <div v-if="isCeo">
              <div class="custom-switch d-flex align-items-center">
                <strong
                  :class="
                    data.item.user_responsible === 1 ? 'text-primary' : ''
                  "
                >
                  It's my client
                </strong>
                <b-form-checkbox
                  :id="`responsible_${data.item.id}`"
                  v-model="data.item.user_responsible"
                  switch
                  :value="1"
                  style="margin-left: 5px"
                  @change="handleUserResponsibleChange(data.item, $event)"
                />
              </div>
            </div>
          </template>
          <template #cell(tracking)="data">
            <strong
              style="font-size: 13px; max-width: 100px"
              class="p-1 text-info"
            >
              <template v-if="data.item.user_responsible_date"> From </template>
              {{ data.item.user_responsible_date | myGlobal }}
            </strong>
            <TrackingIcon
              class="clickable"
              @click="openModalTrackingClientOfCeo(data.item)"
              :width="20"
              :height="20"
            />
          </template>
        </b-table>
      </template>
      <template #buttons>
        <div class="d-flex mr-1" style="gap: 10px" v-if="isDashboardClient">
          <b-button
            variant="outline-custom-amg"
            @click="openModalCreateCustomerTicket({})"
            ><feather-icon class="mr-1" icon="PlusIcon" /><strong
              >New Ticket</strong
            >
          </b-button>
          <b-button
            variant="custom-amg"
            :to="{
              name: redirectTicketGrid(),
            }"
            @click="redirectTicketGrid()"
            ><feather-icon class="mr-1" icon="HomeIcon" /><strong>
              Go to Ticket Grid</strong
            >
          </b-button>
        </div>
      </template>
    </filter-slot>

    <solve-ticket-modal
      v-if="solveTicketModalBoolean"
      :customer-ticket="customerTicketSelected"
      :new-status="statusF ? null : newStatus"
      :status-fidelization="statusF"
      :isTypeExpense="isTypeExpense"
      @hidden="solveTicketModalBoolean = false"
      @closeModal="solveTicketModalBoolean = false"
      @refreshTable="refreshTableReload"
    />
    <modal-view-ticket-customer-details
      v-if="showModalViewTicketCustomerDetails"
      :customer-ticket="customerTicketSelected"
      @hidden="showModalViewTicketCustomerDetails = false"
    />
    <list-participants
      v-if="showModalParticipants"
      :params="paramsModalParticipants"
      @hidden="showModalParticipants = false"
      @saved="savedParticipants"
    />
    <modal-create-customer-ticket
      v-if="showModalCreateCustomerTicket"
      :customer-ticket="customerTicketSelected"
      @hidden="showModalCreateCustomerTicket = false"
      @saved="refreshTableReload()"
    />
    <ticket-costumer-chat
      v-if="openchat == true"
      :customer-ticket-chat="customerTicketChat"
      :params="paramsTicket"
      @close="closeCustomerChat()"
      @refreshTable="refreshTable()"
    />
    <modal-see-client-relevant-information
      v-if="modalSeeClientRelevantInformationControl"
      :customer-ticket="clientInformation"
      @close="modalSeeClientRelevantInformationControl = false"
    />
    <modal-show-description
      v-if="openModalShowDescription"
      :ticket="motiveDescription"
      :details="motiveDescription"
      @close="closeModalShow"
    />
    <modal-meetings-ticket
      v-if="showModalMeetings"
      :ticket-customer="paramsMeetings"
      @closeModal="showModalMeetings = false"
      @success="successMeeting"
    />
    <modal-meetings-ticket-tracking
      v-if="showMeetingTracking"
      :ticket-customer="paramsMeetingTracking"
      @closeModal="closeModalMeetingTracking"
    />
    <modal-attended
      v-if="showModalAttended"
      :ticket="paramsMeetingAttend"
      @closeModal="showModalAttended = false"
      @success="successAttended"
    />
    <modal-not-answer-meeting
      v-if="showModalNotAnswerMeeting"
      :ticket="paramsMeetingNotAnswer"
      @closeModal="showModalNotAnswerMeeting = false"
      @success="successNotAnswerMeeting"
    />
    <modal-task-component
      v-if="showModalAddTask"
      :ticket-customer="paramsAddTask"
      @successTask="successTask"
      @closeModal="closeAddTask"
    />
    <modal-list-tasks
      v-if="showModalListTaks"
      :ticket-customer="paramsListTaks"
      @closeModal="closeListTasks"
    />
    <modal-tracking-client-of-ceo
      v-if="showModalTrackingClientOfCeo"
      :lead="lead"
      @closeModal="showModalTrackingClientOfCeo = false"
    />
    <modal-details-payment-customer
      v-if="openPaymentDetailCustomerOn"
      :customer_ticket_id="customerTicketId"
      :accountData="accountData"
      @closeModal="openPaymentDetailCustomerOn = false"
    />
    <modal-create-customer-ticket
      v-if="showModalCreateCustomerTicket"
      @hidden="showModalCreateCustomerTicket = false"
      @saved="refreshTableReload"
      :customer-ticket="customerTicket"
      :customer-id="this.$route.params.idClient"
    />

    <RequestFidelized
      v-if="openRequestTicket"
      :customer-ticket="customerTicket"
      :status="selectStatus"
      @closemodal="openRequestTicket = false"
      @save="
        openRequestTicket = false;
        refreshTable();
      "
    />
    <DetailRequestFidelized
      v-if="openDetailRequestTicket"
      :ticket-id="customerTicket.id"
      @close="openDetailRequestTicket = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import fieldsCustomerTickets from "@/views/commons/components/customer-tickets/data/fields-customer-tickets";
import fieldsSearchCustomerTickets from "@/views/commons/components/customer-tickets/data/fields-search-customer-tickets";
import filtersCustomerTickets from "@/views/commons/components/customer-tickets/data/filters-customer-tickets";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import SolveTicketModal from "@/views/commons/components/customer-tickets/grid-components/SolveTicketModal.vue";
import GlobalService from "@/views/services/global.service";
import ModalViewTicketCustomerDetails from "@/views/commons/components/customer-tickets/components/modal/ModalViewTicketCustomerDetails.vue";
import ModalCreateCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalCreateCustomerTicket.vue";
import ListParticipants from "@/views/commons/participants-tickets/ListParticipantsTickets.vue";
import Timer from "@/views/commons/components/customer-tickets/components/Timer.vue";
import TicketCostumerChat from "@/views/commons/components/customer-tickets/chat-components/TicketCostumerChat.vue";
import ModalSeeClientRelevantInformation from "@/views/commons/components/customer-tickets/components/modal/ModalSeeClientRelevantInformation.vue";
import ModalShowDescription from "@/views/commons/components/customer-tickets/components/modal/ModalShowDescription.vue";
import ModalMeetingsTicket from "@/views/commons/components/customer-tickets/components/modal/meetings/ModalMeetingsTicket.vue";
import ModalMeetingsTicketTracking from "@/views/commons/components/customer-tickets/components/modal/meetings/ModalMeetingsTicketTracking.vue";
import ModalAttended from "@/views/commons/components/customer-tickets/components/modal/meetings/ModalAttended.vue";
import ModalNotAnswerMeeting from "@/views/commons/components/customer-tickets/components/modal/meetings/ModalNotAnswerMeeting.vue";
import ModalTaskComponent from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalTask.vue";
import ModalListTasks from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalListTasks.vue";
import ModalTrackingClientOfCeo from "@/views/commons/components/customer-tickets/components/modal/ModalTrackingMyClient.vue";
import ModalDetailsPaymentCustomer from "@/views/commons/components/customer-tickets/components/modal/ModalDetailsPaymentCustomer.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import FilterSlot from "@/views/commons/components/customer-tickets/components/FilterSlot.vue";
import ClientDashboardService from "@/views/paragon/views/clients/dashboard/services/dashboard.service";
import TrackingIcon from "@/icons/TrackingIcon.vue";

import RequestFidelized from "@/views/commons/components/customer-tickets/views/request/RequestFidelized.vue";
import DetailRequestFidelized from "@/views/commons/components/customer-tickets/views/request/DetailRequestFidelized.vue";
import { getModuleText } from "@/utils/convertModule.js";
export default {
  name: "GridCustomerTickets",
  components: {
    ModalDetailsPaymentCustomer,
    SolveTicketModal,
    ModalViewTicketCustomerDetails,
    ListParticipants,
    ModalCreateCustomerTicket,
    TicketCostumerChat,
    ModalSeeClientRelevantInformation,
    Timer,
    ModalShowDescription,
    ModalMeetingsTicket,
    ModalMeetingsTicketTracking,
    ModalAttended,
    ModalNotAnswerMeeting,
    ModalTaskComponent,
    ModalListTasks,
    ModalTrackingClientOfCeo,
    StatusAccount,
    FilterSlot,
    TrackingIcon,
    RequestFidelized,
    DetailRequestFidelized,
  },
  props: {
    customerAccount: {
      type: String,
      default: null,
    },
    module: {
      type: Number,
      default: null,
    },
    inOut: {
      type: Number,
      default: null,
    },
    searchGlobal: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: "",
    },
    codeSearch: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      openModalShowDescription: false,
      motiveDescription: null,
      solveTicketModalBoolean: false,
      newStatus: null,
      showModalViewTicketCustomerDetails: false,
      showModalCreateCustomerTicket: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      isBusy: false,
      totalRows: 0,
      startPage: null,
      toPage: null,
      sortBy: "",
      sortDesc: true,
      dataGrid: [],
      fields: fieldsCustomerTickets,
      fields2: fieldsSearchCustomerTickets,
      filters: filtersCustomerTickets,
      customerTicketSelected: {},
      customerTicketChat: {},
      paramsModalParticipants: {
        title: null,
        ticketCustomerId: null,
        readonly: false,
      },
      paramsTicket: {
        ticketCustomerId: null,
      },
      showModalParticipants: false,
      openchat: false,
      state_ticket: null,
      modalSeeClientRelevantInformationControl: false,
      realTime: null,
      showModalMeetings: false,
      paramsMeetings: {},
      searchByCode: false,
      textCodeSearch: "",
      paramsMeetingTracking: {},
      showMeetingTracking: false,
      showModalAttended: false,
      paramsMeetingAttend: {},
      showModalNotAnswerMeeting: false,
      moduleIdToRouteMap: {
        0: "customer-tickets-ceo",
        2: "crm-customer-tickets",
        3: "business-customer-tickets",
        4: "administration-customer-tickets",
        5: "debt-solution-customer-tickets",
        6: "credit-experts-customer-tickets",
        7: "boost-credit-customer-tickets",
        8: "tax-research-customer-tickets",
        9: "correspondence-customer-tickets",
        10: "court-info-customer-tickets",
        11: "analyst-department-customer-tickets",
        12: "paragon-customer-tickets",
        14: "bookeeping-customer-tickets",
        15: "social-network-customer-tickets",
        16: "management-customer-tickets",
        17: "rrhh-customer-tickets",
        18: "quality-customer-tickets",
        19: "logistic-customer-tickets",
        20: "connection-customer-tickets",
        22: "customer-service-customer-tickets",
        23: "financial-customer-tickets",
        25: "specialist-digital-customer-tickets",
        26: "sales-customer-tickets",
        27: "creative-customer-tickets",
      },
      showModalAddTask: false,
      paramsAddTask: {},
      showModalListTaks: false,
      paramsListTaks: {},
      lead: {},
      showModalTrackingClientOfCeo: false,
      openPaymentDetailCustomerOn: false,
      customerTicketId: null,
      accountData: null,
      feesAndChargesTotal: 0,
      feesAndChargesSubTotal: 0,
      paymentsSubTotal: 0,
      paymentsTotal: 0,
      showModalCreateCustomerTicket: false,
      ticketArray: [],
      type_ticket: "customer",
      statusF: null,
      openRequestTicket: false,
      customerTicket: {},
      selectStatus: null,
      openDetailRequestTicket: false,
      isTypeExpense: null,
      moduleText: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      currentIndicatorsStatus: "TicketCustomerStore/G_INDICATORS_STATUS",
      currentType: "TicketCustomerStore/G_TYPE_TICKET",
      countPendingTickets: "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS",
    }),
    isInProgressTab() {
      return this.$route.meta.tab === 1;
    },
    isSolvedTab() {
      return this.$route.meta.tab === 2;
    },
    isRejectedTab() {
      return this.$route.meta.tab === 3;
    },
    isChiefTab() {
      return this.$route.meta.tab === 4;
    },
    isCeo() {
      return this.currentUser.role_id === 1;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    filteredFields() {
      const fields = this.fields.filter((field) => field.visible);
      return fields;
    },
    filteredFieldsSearch() {
      const fieldsSearch = this.fields2.filter((field) => field.visible);
      return fieldsSearch;
    },
    filteredFilters() {
      const filters = this.filters.filter((filter) => filter.visible);
      return filters;
    },
    isDashboardClient() {
      const path = this.$route.matched[this.orderMatched].path;
      const parts = path.split("/");
      return parts.includes("account");
    },
    isTabExpense() {
      return this.$route.matched[this.orderMatched].meta.type == "expense";
    },
    currentTabType() {
      return this.$route.matched[this.orderMatched].meta.type;
    },
    orderMatched() {
      const sizeMatched = {
        5: 3,
        4: 2,
        3: 1,
      };
      return sizeMatched[this.$route.matched.length] || "Unknown";
    },
  },
  watch: {
    currentIndicatorsStatus(oldval) {
      if (this.isCeo) {
        this.realTime = false;
        this.updateTicket(oldval);
      }
    },
    currentType(oldval) {
      this.addPreloader();
      this.realTime = false;
      this.showByType(oldval);
    },
    countPendingTickets(oldval) {
      this.realTime = true;
      // this.updateTable(oldval);
    },
    "paginate.currentPage": "refreshTableReload",
    "paginate.perPage": "refreshTableReload",
  },
  mounted() {
    if (this.isCeo) {
      this.fields = this.fields.map((field) => {
        if (field.key === "updated_at") {
          field.visible = true;
        }
        return field;
      });
    }
    this.convertModule();
    this.changesFields();
    this.textCodeSearch = this.codeSearch;
  },
  methods: {
    showSendIcon(item) {
      const fidelized = item.request_fidelized || {};
      const returned = fidelized
        ? JSON.parse(item.request_fidelized)?.status == "RETURN"
        : false;
      return (
        this.currentType == "customer" &&
        !this.Ceo &&
        item.id_status == 3 &&
        (!item.request_fidelized || returned)
      );
    },
    requestTicketFidelized(item, status) {
      this.customerTicket = item;
      this.selectStatus = status;
      this.openRequestTicket = true;
    },
    openPaymentDetailCustomer(item, customerTicketId) {
      this.customerTicketId = customerTicketId;
      this.accountData = item;
      this.openPaymentDetailCustomerOn = true;
    },
    async refreshLastPayment() {
      try {
        const data =
          await CustomerTicketsService.refreshTicketCustomerPayments();
        if (data.status === 200) {
          this.refreshTable();
        }
      } catch (error) {
        console.error(error);
      }
    },
    openModalTrackingClientOfCeo(item) {
      this.lead = {
        id: item.lead_id ?? item.lead_id_client,
        name: item.name_client,
      };
      this.showModalTrackingClientOfCeo = true;
    },
    getColorByPendingTasks(tasks) {
      const pastTask = tasks.find((task) => task.status === "past");
      const pendingTask = tasks.find((task) => task.status === "pending");
      let color = "";
      if (pendingTask && pastTask) {
        color = "danger";
      } else if (pastTask) {
        color = "danger";
      } else if (pendingTask) {
        color = "warning";
      } else {
        color = "secondary";
      }
      return color;
    },
    async handleUserResponsibleChange(ticket, value) {
      try {
        const params = {
          ticket_id: ticket.id,
          user_id: value === 1 ? this.currentUser.user_id : null,
        };
        const data = await CustomerTicketsService.setUserResposible(params);
        if (data.status === 200) {
          this.refreshTable();
        }
      } catch (error) {
        console.error(error);
      }
    },
    deleteLastInArray(array) {
      // borrar todos y solo dejar el ultimo
      const newArray = [];
      if (array.length > 0) {
        newArray.push(array[array.length - 1]);
      }
      return newArray;
    },
    truncateText(text, maxLength) {
      return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    },
    getTooltipContent(item) {
      try {
        const objectChat = item.object_chat;
        if (!objectChat) {
          return "No chat data available";
        }
        const lastChat = JSON.parse(objectChat);
        if (lastChat) {
          const employeeName = lastChat.employee || "Roger Segura";
          return `
              ${employeeName}  ${moment(lastChat.created_at).format(
            "MM/DD/YYYY hh:mm A"
          )}
          `;
        }
        return "Invalid chat data";
      } catch (error) {
        console.error("Error parsing object_chat:", error);
        return "Error parsing chat data";
      }
    },
    getTooltipMessage(item) {
      const objectChat = item.object_chat;
      const lastChat = JSON.parse(objectChat);
      if (lastChat) {
        const message = lastChat.message || "";
        const truncatedMessage =
          message.length > 50 ? `${message.substring(0, 50)}...` : message;
        return `
              "${truncatedMessage}"
          `;
      }
      return "";
    },
    getEmployeeName(objectChat) {
      try {
        const chatObject = JSON.parse(objectChat);
        return chatObject.employee || "Unknown Employee";
      } catch (error) {
        console.error("Error parsing object_chat:", error);
        return "Unknown Employee";
      }
    },
    openModalShow(ticket) {
      this.openModalShowDescription = true;
      this.motiveDescription = ticket;
    },
    closeModalShow() {
      // this.refreshTable();
      this.openModalShowDescription = false;
    },
    showByType(type) {
      this.realTime = true;
      // this.type_ticket = type;
      // if (this.type_ticket !== null) {
      //   this.getCustomerTicketsProvider();
      // }
    },
    updateTicket(state) {
      this.realTime = true;
      this.state_ticket = state;
      if (this.state_ticket !== null) {
        this.getCustomerTicketsProvider();
      }
    },
    async getCustomerTicketsProvider() {
      try {
        // this.searchGlobal es para el modal del buscador global
        const params = {
          name_text: this.searchGlobal
            ? this.search
            : this.filterPrincipal.model,
          perPage: this.searchGlobal ? 100 : this.paginate.perPage,
          page: this.searchGlobal ? 1 : this.paginate.currentPage,
          sortBy: null,
          sortOrder: this.searchGlobal ? null : "DESC",
          orderBy: this.searchGlobal ? null : 10,
          order: this.searchGlobal ? null : "DESC",
          module_id: this.searchGlobal ? null : this.module,
          tab: this.searchGlobal ? null : this.$route.meta.tab,
          user_id: this.currentUser.user_id,
          program_id: this.searchGlobal
            ? null
            : this.getFilterByKey("program_id").model,
          priority: this.searchGlobal
            ? null
            : this.getFilterByKey("priority_id").model,
          status: this.searchGlobal
            ? null
            : this.getFilterByKey("status_id").model,
          from: this.searchGlobal ? null : this.getFilterByKey("from").model,
          to: this.searchGlobal ? null : this.getFilterByKey("to").model,
          state_ticket: this.searchGlobal ? null : this.state_ticket,
          type_ticket: this.searchGlobal ? null : this.currentTabType,
          client_account: this.searchGlobal ? null : this.customerAccount,
          in_out: this.searchGlobal ? null : this.inOut,
          p_pay_or_not: this.searchGlobal
            ? null
            : this.getFilterByKey("paid").model,
        };

        const data = await CustomerTicketsService.getCustomerTickets(params);
        if (data.status === 200) {
          this.dataGrid = data.data.data;
          this.dataGrid.forEach((item) => {
            item.showAllParticipants = false;
            item.detail_expense = JSON.parse(item.detail_expense);
            item.detail_expense_transaction = JSON.parse(
              item.detail_expense_transaction
            );
          });
          this.totalRows = data.data.total;
          this.startPage = data.data.from || 0;
          this.toPage = data.data.to || 0;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          if (this.totalRows != 0) {
            this.paymentsTotal = data.data.data[0].totalPayment;
            this.feesAndChargesTotal = data.data.data[0].totalCharges;
            this.paymentsSubTotal = data.data.data[0].sub_total_payment;
            this.feesAndChargesSubTotal = data.data.data[0].sub_total_charge;
          }

          this.removePreloader();
          this.$store.dispatch("TicketCustomerStore/A_SET_CODE", null);
          this.textCodeSearch = "";
          return this.dataGrid;
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
      return [];
    },
    changesFields() {
      if (
        this.$route.meta.hasOwnProperty("tab") &&
        this.$route.meta.tab === 2
      ) {
        this.getFilterByKey("status_id").visible = false;
        this.getFilterByKey("priority_id").visible = false;
        this.getFilterByKey("paid").visible = true;
      } else {
        this.getFilterByKey("status_id").visible = true;
        this.getFilterByKey("priority_id").visible = true;
        this.getFilterByKey("paid").visible = false;
      }
      if (this.$route.meta.tab === 1) {
        this.fields = this.fields.map((field) => {
          if (field.key === "actions") {
            field.visible = true;
          }
          if (field.key === "created_at") {
            field.visible = true;
          }
          if (field.key === "updated_at") {
            field.visible = false;
          }
          if (field.key === "meetings") {
            field.visible = true;
          }
          if (field.key === "status") {
            field.visible = false;
          }
          if (field.key === "task") {
            field.visible = true;
          }
          if (field.key === "last_payment") {
            field.visible = false;
          }
          return field;
        });
      } else if (this.$route.meta.tab === 2) {
        this.fields = this.fields.map((field) => {
          if (field.key === "actions") {
            field.visible = false;
          }
          if (field.key === "created_at") {
            field.visible = false;
          }
          if (field.key === "updated_at") {
            field.visible = true;
            field.label = "Solved";
          }
          if (field.key === "meetings") {
            field.visible = true;
          }
          if (field.key === "status") {
            field.visible = true;
          }
          if (field.key === "task") {
            field.visible = true;
          }
          if (field.key === "last_payment") {
            field.visible = true;
          }
          return field;
        });
      } else if (this.$route.meta.tab === 3) {
        this.fields = this.fields.map((field) => {
          if (field.key === "actions") {
            field.visible = false;
          }
          if (field.key === "created_at") {
            field.visible = false;
          }
          if (field.key === "updated_at") {
            field.visible = true;
            field.label = "Rejected";
          }
          if (field.key === "meetings") {
            field.visible = true;
          }
          if (field.key === "status") {
            field.visible = true;
          }
          if (field.key === "task") {
            field.visible = true;
          }
          if (field.key === "last_payment") {
            field.visible = false;
          }
          return field;
        });
      } else if (this.$route.meta.tab === 4 || this.searchGlobal) {
        this.fields = this.fields.map((field) => {
          if (field.key === "actions") {
            field.visible = false;
          }
          if (field.key === "created_at") {
            field.visible = false;
          }
          if (field.key === "updated_at") {
            field.visible = true;
            field.label = "Date";
          }
          if (field.key === "meetings") {
            field.visible = true;
          }
          if (field.key === "status") {
            field.visible = true;
          }
          if (field.key === "task") {
            field.visible = true;
          }
          if (field.key === "last_payment") {
            field.visible = false;
          }
          return field;
        });
      } else if (this.$route.meta.tab === 5) {
        this.fields = this.fields.map((field) => {
          if (field.key === "actions") {
            field.visible = false;
          }
          if (field.key === "created_at") {
            field.visible = false;
          }
          if (field.key === "updated_at") {
            field.visible = true;
            field.label = "Not Solved";
          }
          if (field.key === "meetings") {
            field.visible = true;
          }
          if (field.key === "status") {
            field.visible = true;
          }
          if (field.key === "task") {
            field.visible = true;
          }
          if (field.key === "last_payment") {
            field.visible = false;
          }
          return field;
        });
      } else if (this.$route.meta.tab === 6) {
        this.fields = this.fields.map((field) => {
          if (field.key === "actions") {
            field.visible = true;
          }
          if (field.key === "created_at") {
            field.visible = true;
          }
          if (field.key === "updated_at") {
            field.visible = false;
          }
          if (field.key === "meetings") {
            field.visible = true;
          }
          if (field.key === "status") {
            field.visible = false;
          }
          if (field.key === "task") {
            field.visible = false;
          }
          if (field.key === "last_payment") {
            field.visible = false;
          }
          return field;
        });
      } else if (this.currentUser.role_id !== 1 && !this.$route.meta.tab) {
        this.fields = this.fields.map((field) => {
          if (field.key === "actions") {
            field.visible = false;
          }
          if (field.key === "created_at") {
            field.visible = false;
          }
          if (field.key === "updated_at") {
            field.visible = true;
            field.label = "Date";
          }
          if (field.key === "meetings") {
            field.visible = true;
          }
          if (field.key === "status") {
            field.visible = false;
          }
          if (field.key === "task") {
            field.visible = false;
          }
          if (field.key === "last_payment") {
            field.visible = false;
          }
          return field;
        });
      } else {
        this.fields = this.fields.map((field) => {
          if (field.key === "actions") {
            field.visible = false;
          }
          if (field.key === "created_at") {
            field.visible = false;
          }
          if (field.key === "updated_at") {
            field.visible = true;
            field.label = "Date";
          }
          if (field.key === "meetings") {
            field.visible = true;
          }
          if (field.key === "status") {
            field.visible = false;
          }
          if (field.key === "task") {
            field.visible = false;
          }
          if (field.key === "last_payment") {
            field.visible = false;
          }
          return field;
        });
      }

      if (this.$route.meta.tab !== 1 && this.$route.meta.tab !== 4) {
        this.fields = this.fields.map((field) => {
          if (field.key === "meetings") {
            field.visible = false;
          }
          return field;
        });
      }
      this.filterColumn("detail_expense", this.isTabExpense);
      this.filterColumn("client_ceo", !this.isTabExpense);
      this.filterColumn("last_payment", !this.isTabExpense);
      this.filterColumn("name_client", !this.isTabExpense);
      this.filterColumn("tracking", !this.isTabExpense);
    },
    validateTextByStatus(item) {
      if (item.id_status === 4) {
        return `Rejected by ${item.updated_by || "Unknown"}`;
      }
      if ([5, 6].includes(item.id_status)) {
        return `Solved by ${item.updated_by || "Unknown"}`;
      }
      if (item.id_status === 7) {
        return `Not Solved by ${item.updated_by || "Unknown"}`;
      }
      return `Created by <br /> ${item.created_by || "Unknown"}`;
    },
    validateDateByStatus(item) {
      const rawDate = [1, 2, 3].includes(item.id_status)
        ? item.created_at
        : item.updated_at;
      return moment(rawDate).format("MM/DD/YYYY");
    },
    formatDate(date) {
      return moment(date).format("MM/DD/YYYY hh:mm A");
    },
    openSeeClientRelevantInformation(data) {
      this.modalSeeClientRelevantInformationControl = true;
      this.clientInformation = data;
    },
    uniqueAccounts(item) {
      let clientAccountId = item.client_account_id;
      let accounts = JSON.parse(item.accounts);
      accounts.sort((a, b) => {
        if (a.id === clientAccountId) {
          return -1;
        }
        return 1;
      });
      return accounts;
    },
    dashboardRouteNameByClient(account) {
      let route = null;
      if (account.program_id !== 3) {
        const routers = {
          1: "bussiness-dashboard-general",
          2: "boost-credit-dashboard-general",
          // 3: 'credit-experts-dashboard-general',
          4: "debt-solution-dashboard-general",
          5: "tax-research-dashboard-general",
          6: "court-info-dashboard-general",
          7: "specialists-dashboard-general",
          8: "bookeeping-dashboard-general",
          9: "paragon-dashboard-general",
          // 18: 'quality-client-dashboard',
          // 22: 'ce-customer-service-dashboard',
        };
        route = routers[account.program_id] || null;
      }
      if (account.program_id === 3) {
        if (account.client_type_id === 1)
          route = "credit-experts-dashboard-general";
        if (account.client_type_id === 2)
          route = "ce-customer-service-dashboard-general";
      }
      return route;
    },
    openModalCreateCustomerTicket(item) {
      this.showModalCreateCustomerTicket = true;
      this.customerTicketSelected = item;
    },
    ...mapActions({
      A_COUNT_INDICATORS_TICKETS:
        "NotificationStore/A_COUNT_INDICATORS_TICKETS",
      A_COUNT_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
      setCurrentTicketChat: "TicketCustomerStore/A_SET_OPEN_TICKET_CUSTOMER_ID",
      A_SET_TYPE_TICKET: "TicketCustomerStore/A_SET_TYPE_TICKET",
    }),
    isDisabledStatus(status) {
      if (status > 3) return false;
      if (!this.isCeo) return false;
      return true;
    },
    openModalViewTicketCustomerDetails(item) {
      this.showModalViewTicketCustomerDetails = true;
      this.customerTicketSelected = item;
    },
    openModalParticipants(item) {
      this.paramsModalParticipants = {
        ...item,
        readonly: this.currentUser.user_id !== 1 || item.id_status === 4,
      };
      this.showModalParticipants = true;
    },
    openSolveTicketModal(status, item) {
      this.statusF =
        status == "fidelized" ? 6 : status == "no-fidelized" ? 5 : null;
      this.newStatus = status;
      this.customerTicketSelected = item;
      this.solveTicketModalBoolean = true;
      this.isTypeExpense = item.type_ticket == "EXPENSES";
    },
    colorStatus(item) {
      const color = {
        1: "bg-secondary",
        2: "bg-primary",
        3: "bg-warning",
        4: "bg-danger",
        5: "bg-info",
        6: "bg-solved-fidelized",
        7: "bg-secondary",
      };
      return color[item.id_status] || "bg-solved-fidelized";
    },
    variantText(item) {
      const color = {
        1: "secondary",
        2: "primary",
        3: "warning",
        4: "danger",
        5: "info",
        6: "solved-fidelized",
        7: "secondary",
      };
      return color[item.id_status];
    },
    textColor(item) {
      const color = {
        1: "text-secondary",
        2: "text-primary",
        3: "text-warning",
        4: "text-danger",
        5: "text-info",
        6: "text-solved-fidelized",
        7: "text-secondary",
      };
      return color[item.id_status];
    },
    colorPriority(item) {
      const color = {
        low: "primary",
        medium: "warning",
        high: "danger",
      };
      return color[item.priority];
    },
    async openModalClientDetail(item) {
      const params = {
        client_account_id: item.client_account,
      };
      const [clientInfo] = await GlobalService.getDetailsClient(params);
      this.clientInformation = clientInfo;
      this.modalClientDetailC = true;
    },
    updateTable() {
      this.realTime = true;
      // this.$refs.refCustomerTicketsList.refresh();
      this.getCustomerTicketsProvider();
    },
    async refreshTable() {
      this.realTime = true;
      this.showModalCreateCustomerTicket = false;
      this.customerTicketSelected = {};
      this.state_ticket = null;
      this.getCustomerTicketsProvider();
    },
    async refreshTableReload() {
      this.realTime = false;
      this.showModalCreateCustomerTicket = false;
      this.customerTicketSelected = {};
      this.state_ticket = null;
      if (this.$store.getters["TicketCustomerStore/G_CODE"] != null) {
        if (this.isInProgressTab && this.module === this.$route.params.module) {
          this.filterPrincipal.model =
            this.$store.getters["TicketCustomerStore/G_CODE"];
        } else {
          this.filterPrincipal.model = this.isInProgressTab
            ? ""
            : this.$store.getters["TicketCustomerStore/G_CODE"];
        }
      }
      if (this.textCodeSearch !== "") {
        this.filterPrincipal.model = this.textCodeSearch;
        this.$emit("refreshTable");
      }
      this.$refs.refCustomerTicketsList.refresh();
    },
    async openCustomerChat(ticket) {
      this.customerTicketChat = ticket;
      this.paramsTicket.ticketCustomerId = ticket.id;
      this.addPreloader();
      this.openchat = true;
      this.removePreloader();
      return [];
    },
    async sendToInProgress(ticket) {
      if (ticket.id_status === 2) {
        try {
          const params = {
            user_id: this.currentUser.user_id,
            current_ticket: ticket.id,
            current_status_ticket: ticket.id_status,
            customerTicketChat: ticket,
          };

          const response = await this.showConfirmSwal();

          if (!response.isConfirmed) {
            return;
          }

          this.addPreloader();
          const data = await CustomerTicketsService.ChangeOpenStatusTicket(
            params
          );
          if (data.status === 200) {
            this.refreshTable();
            await this.A_COUNT_INDICATORS_TICKETS({
              user_id: this.currentUser.user_id,
              type_ticket: this.currentTabType,
              module_id: this.currentUser.main_module,
            });
            await this.A_COUNT_PENDING_CUSTOMER_TICKETS({
              user_id: this.currentUser.user_id,
              type_ticket: this.currentTabType,
              client_account_id: null,
            });
            this.removePreloader();
            this.showSuccessSwal();
          }
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal(error);
          console.error(error);
        }
      }
    },
    redirectTicket() {
      const { user_id, modul_id, main_module, arrRoles } = this.currentUser;
      const resultado = arrRoles.find(
        (rol) => rol.role_id === 2 && rol.module_id !== 24 && rol.user_id !== 38
      );
      return this.moduleIdToRouteMap[resultado?.module_id];
    },
    async closeCustomerChat() {
      this.setCurrentTicketChat(null);
      this.openchat = false;
      const tagToUpdate = this.redirectTicket();
      await this.A_COUNT_INDICATORS_TICKETS({
        user_id: this.currentUser.user_id,
        module_id: this.currentUser.main_module,
        tag: tagToUpdate,
        type_ticket: this.currentTabType,
      });

      await this.A_COUNT_PENDING_CUSTOMER_TICKETS({
        user_id: this.currentUser.user_id,
        client_account_id: null,
        type_ticket: this.currentTabType,
      });
    },
    async savedParticipants() {
      this.showModalParticipants = false;
      await this.refreshTableReload();
    },
    routeModule(module) {
      const routes = {
        6: "creditexperts",
        21: "creditexperts",
        22: "cedigital/customerservice",
        23: "cedigital/financial",
        26: "cedigital/sales",
        20: "cedigital/connection",
        4: "administration",
        2: "crm",
        3: "bussiness/leads",
        7: "boostcredit",
        18: "quality",
        8: "taxresearch",
        11: "specialists",
        25: "specialistdigital",
        12: "ti", // paragon
        17: "rrhh",
        5: "debtsolution",
        10: "claimdepartment", // court info
        19: "crm",
        14: "bookeeping",
        15: "socialnetwork",
      };
      return routes[module];
    },
    modalMeetings({
      id,
      name_client,
      code,
      module_id_client,
      account,
      number_phone,
    }) {
      this.paramsMeetings = {
        id,
        name_client,
        code,
        module_id: module_id_client,
        account,
        number_phone,
      };
      this.showModalMeetings = true;
    },
    redirect(item) {
      const routeParams = {
        code: item.code,
        searchByCode: true,
        module: item.module_id,
      };

      let routeName = "";
      const type = item.type == "payments" ? "payment" : item.type;
      // Establecer el nombre de la ruta según las condiciones
      if (item.id_status === 5 || item.id_status === 6) {
        routeName = `${type}-ticket-${this.moduleText}-in-progress`;
      } else if (item.id_status === 4) {
        routeName = `${type}-ticket-${this.moduleText}-rejected`;
      } else if (item.id_status === 3) {
        routeName = `${type}-ticket-${this.moduleText}-in-progress`;
      } else if (
        item.id_status === 2 ||
        (item.created_by === 1 && item.id_status === 1)
      ) {
        routeName = `${type}-ticket-${this.moduleText}-new`;
      } else if (item.id_status === 7) {
        routeName = `${type}-ticket-${this.moduleText}-not-solved`;
      }
      this.$store.dispatch("TicketCustomerStore/A_SET_CODE", item.code);
      // Realizar la navegación utilizando 'replace' en lugar de 'push'
      if (routeName) {
        this.$nextTick(() => {
          this.$router
            .replace({
              name: routeName,
              params: routeParams,
            })
            .catch((error) => {
              // Manejar específicamente la excepción NavigationDuplicated
              if (
                error.name === "NavigationDuplicated" &&
                error.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                if (
                  routeName ===
                  `${item.type}-ticket-${this.moduleText}-in-progress`
                ) {
                  this.$root.$emit("redirect-to-module", routeParams);
                }
                // Puedes omitir la excepción ya que se trata de una navegación redundante
              } else {
                // Lanzar otras excepciones
                throw error;
              }
            });
        });
      }
      // Emitir un evento personalizado con la variable
      this.$emit("closeModal");
    },
    async showModalMeetingTracking({
      id,
      code,
      name_client,
      pending_see_meetings,
    }) {
      this.paramsMeetingTracking = {
        id,
        code,
        name_client,
      };
      this.showMeetingTracking = true;

      if (pending_see_meetings > 0) {
        await this.refreshTableReload();
      }
    },
    closeModalMeetingTracking() {
      this.showMeetingTracking = false;
      this.paramsMeetingTracking = {};
    },
    async successMeeting() {
      await this.refreshTableReload();
      this.showModalMeetings = false;
    },
    attendTicked({ code, name_client, id, next_meeting, module_id_client }) {
      this.paramsMeetingAttend = {
        id,
        name_client,
        code,
        meeting: JSON.parse(next_meeting),
        module_id: module_id_client,
      };
      this.showModalAttended = true;
    },
    async successAttended() {
      this.paramsMeetingAttend = {};
      this.showModalAttended = false;
      await this.refreshTableReload();
    },
    notAnswerTicket(item) {
      this.paramsMeetingNotAnswer = {
        id: item.id,
        name_client: item.name_client,
        code: item.code,
        old_meeting: JSON.parse(item.next_meeting),
        module_id: item.module_id_client,
        created_by_id: item.created_by_id,
      };
      this.showModalNotAnswerMeeting = true;
    },
    async successNotAnswerMeeting(meetingAnswered) {
      this.paramsMeetingNotAnswer = {};
      this.showModalNotAnswerMeeting = false;
      if (meetingAnswered) {
        await this.refreshTableReload();
      }
    },
    closeAddTask() {
      this.paramsAddTask = {};
      this.refreshTable();
      this.showModalAddTask = false;
    },
    addTask({ code, id }) {
      this.showModalAddTask = true;
      this.paramsAddTask = {
        code,
        id,
      };
    },
    successTask() {
      this.paramsAddTask = {};
      this.refreshTable();
      this.showModalAddTask = false;
    },
    async viewListTasks({ code, id }) {
      this.paramsListTaks = {
        code,
        id,
      };
      this.showModalListTaks = true;
    },
    closeListTasks() {
      this.paramsListTaks = {};
      this.refreshTable();
      this.showModalListTaks = false;
    },
    convertToMonth(date) {
      const diff = moment().diff(moment(date), "months");
      return diff;
    },
    getFilterByKey(key) {
      return this.filters.find((filter) => filter.key === `${key}`);
    },
    openModalCreateCustomerTicket() {
      this.showModalCreateCustomerTicket = true;
    },
    redirectTicketGrid() {
      if (!this.isCeo) {
        return this.moduleIdToRouteMap[this.currentUser.main_module];
      }
      return this.moduleIdToRouteMap[0];
    },
    refresh() {
      this.showModalCreateCustomerTicket = false;
      this.listTicketPerCustomer();
      this.refreshTableReload();
    },
    async listTicketPerCustomer() {
      try {
        this.addPreloader();

        const obj = {
          client_account: this.$route.params.idClient,
        };
        const res = await ClientDashboardService.listTicketPerCustomer(obj);
        if (res.status === 200) {
          this.ticketArray = res.data;
          this.removePreloader();
        }
      } catch (error) {
        console.error(error);
        this.showWarningSwal();
        this.removePreloader();
      }
    },
    openDetailRequestFidelized(item) {
      this.customerTicket = item;
      this.openDetailRequestTicket = true;
    },
    filterColumn(key, bool) {
      const field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    convertModule() {
      this.moduleText = getModuleText(this.moduleId);
    },
    seeMore(item) {
      this.$set(item, "view_more", !item.view_more);
    },
  },
};
</script>
<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th,
.btn-custom-amg:hover {
  color: #fff !important;
}
.table-grid-custom-tickets-header {
  background-color: #3a72ea !important;
  color: white !important;
  font-weight: bold !important;
}
.bg-solved-fidelized {
  background-color: #00853a !important;
}
.text-solved-fidelized,
.variant-solved-fidelized {
  color: #00853a !important;
}
.bg-in-progress {
  background-color: #c17c3a !important;
}
.text-in-progress,
.variant-in-progress {
  color: #c17c3a !important;
}
.message-container {
  background-color: #eeeeee; /* Color de fondo */
  border-radius: 10px; /* Borde redondeado */
  padding: 5px; /* Espaciado interno */
  display: flex; /* Alinear elementos en línea */
  align-items: center; /* Alinear verticalmente al centro */
}
.table-new-customization >>> thead tr th {
  background: rgba(63, 122, 250, 1) !important;
}
</style>
<style scoped>
.custom-switch {
  display: inline-block;
  margin-right: 2px; /* Ajusta el espaciado según tus necesidades */
}
.customLink {
  color: #b4b4b4 !important;
  text-decoration: underline;
}
</style>
