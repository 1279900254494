var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative w-100"},[_c('div',{staticClass:"container-calendar d-block"},[_vm._m(0),_c('div',{staticClass:"grid-calendar"},[_vm._l((_vm.spaceEmptyStart),function(space,index){return _c('div',{key:((index + 1) * -1),staticClass:"number-day",staticStyle:{"background-color":"transparent !important","pointer-events":"none"}})}),_vm._l((_vm.optionDates),function(item,index){return _c('div',{key:index,staticClass:"position-relative number-day",class:[
          'position-relative',
          'number-day',
          {
            'hasSchedule-dark': _vm.isDarkSkin && _vm.isDayInContainerHours(item.day),
            'hasSchedule-light': !_vm.isDarkSkin && _vm.isDayInContainerHours(item.day)
          },
          {
            'day-selected': item.selected,
            'highlight': item.highlight,
            'day-primary': _vm.variant === 'primary',
            'day-warning': _vm.variant === 'warning',
            'day-info': _vm.variant === 'info',
          }
        ],style:({
          'pointer-events': item.disabled ? 'none' : 'auto',
          'color': item.disabled ? '#82868B' : '',
          'background-color': item.hasSchedule ? '#7cbdf0 !important; color:black !important' : '',
        }),on:{"click":function($event){return _vm.selectedDays(item.day)}}},[_c('span',[_vm._v(_vm._s(item.day))]),(item.notification && item.notification > 0)?_c('div',[_c('span',{staticClass:"pending-meetings-count"},[_vm._v(_vm._s(item.notification))])]):(item.meetings && _vm.countMeetingsPerday(JSON.parse(item.meetings)) > 0)?_c('div',{staticClass:"pending-meetings-count"},[_c('span',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(_vm.countMeetingsPerday(JSON.parse(item.meetings))))])]):_vm._e(),(item.date && item.date === _vm.todayDate)?_c('small',{staticClass:"indicator"}):_vm._e()])})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid-calendar border-bottom"},[_c('span',{staticClass:"name-day"},[_vm._v("sun")]),_c('span',{staticClass:"name-day"},[_vm._v("mon")]),_c('span',{staticClass:"name-day"},[_vm._v("tue")]),_c('span',{staticClass:"name-day"},[_vm._v("wed")]),_c('span',{staticClass:"name-day"},[_vm._v("thu")]),_c('span',{staticClass:"name-day"},[_vm._v("fri")]),_c('span',{staticClass:"name-day"},[_vm._v("sat")])])}]

export { render, staticRenderFns }