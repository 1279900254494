export default [
    {
      path: "zero-payment",
      name: "zero-payment-boost-credit",
      component: () => import(/* webpackChunkName: "BCZeroPayment" */ "@/views/boost-credit/views/zero-payment/TemplateZeroPayment.vue"),
      meta: {        
        pageTitle: "Zero Payment",
        breadcrumb: [
          {
            text: "ZERO PAYMENT",
            active: true,
          },
        ],
        permittedRoles: [1, 2]
      },
    },
  ];