import { saleMadeTabs } from "@/views/commons/components/sales-made/router/sale-made.router";

export default [
  {
    path: "sales-made",
    name: "connection-sales-made",
    redirect: { name: "sales-made-connection-lead" },
    component: () =>
      import(
        /* webpackChunkName: "ConnectionSalesMade" */
        "@/views/ce-digital/sub-modules/connection/views/sales-made/SalesMade.vue"
      ),
    meta: {
      module: 20,
      parentModule: 21,
      modulUrl: "cedigital/connection",
      program: 3,
      permittedRoles: [1, 2, 14, 15, 17],
      pageTitle: "Sales Made",
      breadcrumb: [
        {
          text: "Sales Made",
        },
      ],
    },
    // children: [
    //   {
    //     path: "add-change",
    //     name: "sales-made-connection-add-change",
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "ConnectionSalesMadeAddChange" */
    //         "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue"
    //       ),
    //     meta: {
    //       module: 20,
    //       parentModule: 21,
    //       modulUrl: "cedigital/connection",
    //       program: 3,
    //       permittedRoles: [1, 2, 14, 15, 17],
    //       pageTitle: "Sales Made",
    //       breadcrumb: [
    //         {
    //           text: "Sales Made",
    //           active: true,
    //         },
    //       ],
    //       programParent: 3,
    //     },
    //   },
    //   {
    //     path: "add-sale",
    //     name: "sales-made-connection-add-sale",
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "ConnectionSalesMadeAddSale" */
    //         "@/views/commons/components/sales-made/add-sale/components/SalesMadeAddSaleComponent.vue"
    //       ),
    //     meta: {
    //       module: 20,
    //       parentModule: 21,
    //       modulUrl: "cedigital/connection",
    //       type: 0,
    //       program: 3,
    //       permittedRoles: [1, 2, 14, 15, 17],
    //       pageTitle: "Sales Made",
    //       breadcrumb: [
    //         {
    //           text: "Sales Made",
    //           active: true,
    //         },
    //       ],
    //     },
    //   },
    // ],
    children: saleMadeTabs("connection")

  },
];
