import { saleMadeTabs } from "@/views/commons/components/sales-made/router/sale-made.router";
export default [
  {
    path: "sales-made",
    name: "bookeeping-sales-made",
    redirect: { name: "sales-made-bookeeping-lead" },
    component: () =>
      import(
        /* webpackChunkName: "BookeepingSalesMade" */ "@/views/bookeeping/views/sales-made/SalesMade.vue"
      ),
    meta: {
      program: 8,
      permittedRoles: [1, 2, 3],
      pageTitle: "Sales Made",
      breadcrumb: [
        {
          text: "Sales Made",
        },
      ],
    },
    // children: [
    //   {
    //     path: "add-change",
    //     name: "sales-made-bookeeping-add-change",
    //     component: () => import(/* webpackChunkName: "BookeepingSalesMadeAddChange" */ "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue"),
    //     meta: {
    //       program: 8,
    //       permittedRoles: [1, 2, 3],
    //       pageTitle: "Sales Made",
    //       breadcrumb: [
    //         {
    //           text: "Sales Made",
    //           active: true,
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     path: "add-sale",
    //     name: "sales-made-bookeeping-add-sale",
    //     component: () => import(/* webpackChunkName: "BookeepingSalesMadeAddSale" */ "@/views/commons/components/sales-made/add-sale/components/SalesMadeAddSaleComponent.vue"),
    //     meta: {
    //       type: 0,
    //       program: 8,
    //       permittedRoles: [1, 2, 3],
    //       pageTitle: "Sales Made",
    //       breadcrumb: [
    //         {
    //           text: "Sales Made",
    //           active: true,
    //         },
    //       ],
    //     },
    //   },
    // ],
    children: saleMadeTabs("bookeeping")
  },
];
