<template>
  <b-modal
    :title="titleModal"
    size="lg"
    v-model="showModal"
    @hidden="closeModal"
    scrollable
    :hide-footer="onlyView || saleData.notes_ids != null"
  >
    <div v-if="page == 1">
      <calls-from-lead
        ref="callsFromLead"
        :leadId="saleData.lead_id"
        :selectable="tab == 'clients' && saleData.notes_ids == null"
        :typeSale="tab"
        :eventId="saleData.event_id"
        @defineDisabledButton="defineDisabledButton"
        @closeModal="closeModal"
        @refreshTable="refreshTable"
      />
    </div>
    <div v-else>
      <div v-for="(note, index) in notes" :key="note.id">
        <span
          v-if="note.sentiment != null"
          class="badge-sentiment"
          :style="{
            backgroundColor:
              note.sentiment === 'positive'
                ? '#00d25b'
                : note.sentiment === 'negative'
                ? '#f10000'
                : note.sentiment === 'neutral'
                ? '#0090e7'
                : '#6e3ccd',
          }"
          >Sentiment: {{ note.sentiment }}</span
        >
        <div class="d-flex align-items-center justify-content-end">
          <span class="note-created-data">
            <b-avatar
              class="d-inline-block mr-1"
              variant="info"
              :src="note.image_thumb"
            ></b-avatar>
            {{ note.user }} | {{ note.created_at | myGlobalWithHour }}</span
          >
        </div>

        <div class="text-container">
          <change-sms
            v-if="note.text != null"
            class="pb-1 mb-1"
            :SmsData="note.text"
            :TextLength="1200"
            @isExpanded="note.isExpanded = !note.isExpanded"
          />
        </div>
      </div>
      <hr class="text-warning" style="">
      <div>
        <p class="text-warning" style="font-size: 18px; font-weight: bold">Calls list: </p>
        <div class="pb-1">
          <b-table
            ref="selectableTable"
            :items="selectedCalls"
            :fields="fields"
            show-empty
            small
          >
            <template #cell(start_time)="{ item }">
              <div>{{ item.start_time | myGlobalWithHour }}</div>
            </template>
            <template #cell(audio_url)="data">
              <new-wave-surfer-player
                :url="data.item.audio_url"
                :options="audioOptions"
                :wave-surfer-id="`wave-surfer-${data.item.id}`"
                :show-duration="true"
                :elapsed-time-width="95"
              />
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          @click="processCalls()"
          :disabled="disableButton || pendingProcess"
        >
          Process
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import NewWaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/NewWaveSurferPlayer.vue";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms.vue";
import appointmentsService from "@/views/crm/views/Lead/lead-event/services/appointments.services.js";
import CallsFromLead from "@/views/commons/components/ring-central/call-log/components/CallsFromLead.vue";
import CallLogService from "@/views/commons/components/ring-central/call-log/service/call-log.service.js";
import Fields from "@/views/crm/views/sales-made/components/new-client/calls-selected.fields.js"
export default {
  components: {
    NewWaveSurferPlayer,
    ChangeSms,
    CallsFromLead,
  },
  props: {
    idNotes: {
      type: String,
      required: false,
    },
    tab: {
      type: String,
      default: 'leads'
    },
    saleData: {
      type: Object,
      default: () => ({
        id: null
      }),
    },
    onlyView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      note: {},
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 2,
        backend: "MediaElement",
        minPxPerSec: 0,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      disableButton: true,
      page: 1,
      pendingProcess: false,
      selectedCalls: [],
      fields: Fields,
    };
  },
  async created() {
    if((this.idNotes != null && this.saleData.id == null) || this.saleData.note_transcription_status == "COMPLETED") {
      this.page = 2;
      await this.getSummary();
      await this.getSelectedCalls();
    }else{
      this.page = 1;
    }

    this.disableButton = this.tab != "leads";
    this.showModal = true;
  },
  computed: {
    titleModal() {
      if(this.saleData.id != null){
        return "Call summary: " + this.saleData.client;
      } else {
        return "Call summary";
      }
    },
  },
  methods: {
    async getSelectedCalls(){
      let noteId = JSON.parse(this.idNotes)[0];
      const { data } = await CallLogService.getSelectedCalls({
        noteId: noteId
      });
      this.selectedCalls = data;
    },
    async getSummary() {
      const params = {
        idNotes: this.idNotes
      };
      try {
        this.addPreloader();
        const { data } = await appointmentsService.getSummary(params);
        this.notes = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async processCalls() {
      this.pendingProcess = true;
      this.$refs.callsFromLead.processCalls();
    },
    refreshTable() {
      this.$emit("refreshTable");
    },
    closeModal() {
      this.showModal = false;
      this.$emit("closeModal");
    },
    defineDisabledButton(val) {
      console.log("disableButton", val);
      this.disableButton = val;
    },
  },
};
</script>
<style>
.memo-class {
  height: 150px;
  width: 100%;
  border-radius: 0.357rem;
  padding: 0.438rem 1rem;
  overflow: auto;
  white-space: pre-wrap;
}
.custom-height {
  height: 60%;
}
.custom-empty-img {
  opacity: 0.3;
}
.custom-message {
  font-size: 23px;
  font-weight: bolder;
  position: absolute;
}
</style>

<style lang="scss" scoped>
.tabs-container {
  background: #fff !important;
  padding: 1rem;
  border-bottom: 5px solid #0090e7;
  border-bottom-left-radius: 0.357rem;
  border-bottom-right-radius: 0.357rem;

  .nav-pills .nav-link.active {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    background: #0090e7 !important;
    color: #fff !important;
  }
  .nav-pills .nav-link {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    background: #efefef !important;
    color: #5e5873 !important;
  }
  .custom-filter-date {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    background-color: #efefef;
    color: #5e5873;
    text-decoration: underline;
    border: none !important;
  }
}

.new-note-button {
  background-color: #3f7afa !important;
  color: #111113 !important;
  border-color: #3f7afa !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.notes-history-button {
  background-color: #3f7afa !important;
  color: #111113 !important;
  border-color: #3f7afa !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.note-item {
  position: relative;
  background: #efefef;
  height: 60%;
  // overflow: hidden;
  transition: height 0.3s ease;
  margin-bottom: 1rem;
  padding-right: 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.note-item.expanded {
  height: auto;
}

.note-created-data {
  font-size: 1rem;
  color: #111113;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  padding: 1rem 2rem;
}
.badge-sentiment {
  position: absolute !important;
  font-size: 1rem;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  padding: 0.7rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  text-transform: capitalize;
}
.text-container {
  padding: 0rem 2rem;
}

.call-container {
  // padding: 1rem 2rem 0rem 2rem;
  margin-bottom: 1rem;
}
.notes-container {
  // min-height: 30% !important;
  min-height: auto !important;
  max-height: 85% !important;
  overflow-y: auto;
}

.call-container-left {
  margin-left: 2rem;
  font-size: 1rem;
  color: #111113;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.important {
  border: 1px solid #ff9f43 !important;
}
.custom-dropdown-menu {
  position: absolute !important;
  top: auto !important;
  bottom: 100%;
  left: 0;
  transform: translateY(-5px);
  z-index: 1000; /* Ensure it appears above other elements */
}

.dark-layout {
  .title-notes {
    color: #fafafa;
  }
  .tabs-container {
    background: #111113 !important;
  }
  .nav-pills .nav-link.active {
    background: #3a72ea !important;
    color: #0e1932 !important;
  }
  .nav-pills .nav-link {
    background: #0e1932 !important;
    color: #3f7afa !important;
  }
  .custom-filter-date {
    background-color: #131323;
    color: #b4b4b4;
  }
  .note-item {
    background: #111113;
  }
  .note-created-data {
    color: #ffffff;
  }
  .call-container-left {
    color: #ffffff;
  }
}
</style>