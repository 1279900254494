import Leads from "@/views/ce-digital/sub-modules/sales/views/leads/view/Leads.vue";
import Grid from "../view/components/Grid.vue";

export default [
  {
    path: "leads",
    name: "sales-leads",
    redirect: { name: "sales-leads-news" },
    component: Leads,
    meta: {

      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Leads",
          active: true,
        },
      ],
      parentModule: 21,
      module: 26,
    },
    children: [
      {
        path: "news",
        component: Grid,
        name: "sales-leads-news",
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "News",
              active: true,
            },
          ],
          parentModule: 21,
          module: 26,
          // Tab
          newsTab: "sales-leads-news",
          tracingTab: "sales-leads-tracing",
          successTab: "sales-leads-success",
          recycledTab: "sales-leads-recycled",
          section: "lead"
        },
      },
      {
        path: "tracing",
        component: Grid,
        name: "sales-leads-tracing",
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Tracing",
              active: true,
            },
          ],
          parentModule: 21,
          module: 26,
          // Tab
           newsTab: "sales-leads-news",
          tracingTab: "sales-leads-tracing",
          successTab: "sales-leads-success",
          recycledTab: "sales-leads-recycled",
          section: "lead"
        },
      },
      {
        path: "success",
        component: Grid,
        name: "sales-leads-success",
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Success",
              active: true,
            },
          ],
          parentModule: 21,
          module: 26,
          // Tab
           newsTab: "sales-leads-news",
          tracingTab: "sales-leads-tracing",
          successTab: "sales-leads-success",
          recycledTab: "sales-leads-recycled",
          section: "lead"
        },
      },
      {
        path: "recycled",
        component: Grid,
        name: "sales-leads-recycled",
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Recycled",
              active: true,
            },
          ],
          parentModule: 21,
          module: 26,
          // Tab
           newsTab: "sales-leads-news",
          tracingTab: "sales-leads-tracing",
          successTab: "sales-leads-success",
          recycledTab: "sales-leads-recycled",
          section: "lead"
        },
      },
    ],
  },
  {
    path: "leads/show/:id",
    name: "sales-lead-show-suceess",
    component: () =>
      import("@/views/ce-digital/sub-modules/sales/views/leads/view/dashboard/DashboardLeadCeSales.vue"),
    props: true,
    meta: {
      isClientsTab: true,
      parentModule: 21,
      module: 26,
      route: "sales",
      contentClass: "leads-app",
      origin:"lead",
      Tab:3,

    },
  },
  {
    path: "leads/show/:id",
    name: "sales-lead-show",
    component: () =>
      import("@/views/ce-digital/sub-modules/sales/views/leads/view/dashboard/DashboardLeadCeSales.vue"),
    props: true,
    meta: {
      isClientsTab: true,
      parentModule: 21,
      module: 26,
      route: "sales",
      contentClass: "leads-app",
      origin:"lead",

    },
  },
  {
    path: "leads/report/:idlead/:idfile",
    name: "sales-report-lead",
    component: () =>
      import("@/views/commons/components/ncr/components/report/ReportLead.vue"),
    props: true,
    meta: {
      route: "sales",
      isClientsTab: true,
      parentModule: 21,
      module: 26,
      pendingRoute: "ncr-pending",
      returnedRoute: "ncr-returned",
      completedRoute: "ncr-completed",
      reportLeadRoute: "report-lead",
      leadRoute: "lead-show",
    },
  },
];
