<template>
  <b-modal
    :title="listName"
    v-model="modalShow"
    @hidden="$emit('close')"
    hide-footer
    centered
  >
    <div class="p-2 rounded card-note">
      <div class="clearfix mb-2">
        <div class="float-left font-weight-bolder">
          {{ listDate | myDateText }}
        </div>
        <div class="float-right">
          <span class="text-info"> {{ listType }} </span>
        </div>
      </div>
      <span>
        {{ regexNoHTML(listContent) }}
      </span>
      <div class="clearfix mt-2">
        <span class="float-right text-agent">
          {{
            "By " +
            listAuthor +
            ", " +
            $options.filters.myGlobal(listDate)
          }}
        </span>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    listName: {
      type: String,
      required: false,
      default: "",
    },
    listContent: {
      type: String,
      required: true,
    },
    listDate: {
      type: String,
      required: true,
    },
    listAuthor: {
      type: String,
      required: true,
    },
    listType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      modalShow: true,
    };
  },

  methods: {
    regexNoHTML(text) {
      let newText = text.replace(/<[^>]*>/g, "");
      return newText;
    },
  },
};
</script>

<style>
</style>