<template>
  <b-modal
    ref="modalName"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="REGISTER ADDRESS BY PAY TO"
    size="lg"
    @hidden="closeModal()"
    centered
    no-close-on-backdrop
    ok-title="Register Address"
    @ok="handleOk"
  >
    <ValidationObserver ref="formValidateAddress">
      <!-- <b-form-group label="Address">
          <vue-google-autocomplete
            id="address_street"
            ref="address_street"
            v-model="form.address"
            class="form-control input-form fond-white border-hover font-ce"
            placeholder="Please type your address"
            country="us"
            @keyup="(e) => onChangeMaillingAddress(e, form)"
            @change="getAddress"
            :class="{ 'border-danger': errors.length > 0 }"
            @placechanged="setAddress"
          />
        </b-form-group> -->

      <address-component :address-data="userData.address" :isRequired="true" />
    </ValidationObserver>
  </b-modal>
</template>
<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";
import AddressComponent from "@/views/commons/components/leads/leads-form-components/AddressComponent.vue";
import { mapActions } from "vuex";

export default {
  name: "ModalCreatePayableExpenseAddresses",
  props: {
    payable_expense_id: {
      type: Number,
      required: true,
    },
  },
  components: {
    VueGoogleAutocomplete,
    AddressComponent,
  },
  data() {
    return {
      form: {
        address: null,
        street: null,
        country: null,
        route: null,
        street_address: null,
        postal_code: null,
        state: null,
        city: null,
        street_number: null,
        state_slug: null,
      },
      place: null,
      userData: {
        address: {
          prename: "main",
          streetReal: "",
          street: "",
          city: "",
          state: "",
          zipcode: "",
          country: "United States",
        },
      },
    };
  },
  async mounted() {
    this.toggleModal("modalName");
    await this.A_GET_EEUU_STATES();
  },
  methods: {
    ...mapActions({
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
    }),
    closeModal() {
      this.$emit("close");
    },
    onChangeMaillingAddress(event, data) {
      this.$set(data, "street", event.target.value);
    },
    getAddress(data) {
      this.form.address = data;
      if (this.place) {
        const {
          country,
          route,
          locality,
          postal_code,
          street_number,
          administrative_area_level_1,
        } = this.place;
        this.form.country = country;
        this.form.route = route;
        this.form.address = data;
        this.form.postal_code = postal_code;
        this.form.city = locality;
        const state = data.split(",");
        this.form.state = state[state.length - 2];
        this.form.street_number = street_number;
        this.form.state_slug = administrative_area_level_1;
      }
    },
    setAddress(address) {
      this.place = address;
    },
    async handleOk(event) {
      event.preventDefault();
      try {
        let isValid = await this.$refs["formValidateAddress"].validate();
        if (!isValid) {
          return;
        } else {
          const res = await DepartmentExpenses.insertPayableExpensesAddresses({
            // ...this.form,
            address: this.userData.address.street,
            city: this.userData.address.city,
            state: this.userData.address.state,
            postal_code: this.userData.address.zipcode,
            country: this.userData.address.country,
            route: this.userData.address.street,
            street_number: null,
            state_slug: this.userData.address.state,
            vendor_id: this.payable_expense_id,
          });
          this.$emit("refresh", res);
          this.closeModal();
        }
      } catch (e) {
        console.log(e);
        this.showAlertWarning(e);
      }
    },
    showAlertWarning(message) {
      this.showToast(
        "warning",
        "top-right",
        "Warning",
        "AlertTriangleIcon",
        message
      );
    },
  },
};
</script>
