

export default [
  {
    path: 'dashboard',
    name: 'specialists-digital-dashboard', // specialist-digital-cr-realtor
    component: () => import(/* webpackChunkName: "SpecialistDigitalDashboard" */ "@/views/specialist-digital/views/dashboard/SpecialistsDashboard.vue"),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
]
