import ClientService from "@/views/commons/components/clients/services/clients.services.js";
import ApplicationsService from "@/views/commons/components/applications/services/applications.service.js";
import RequestWorkPlanService from "@/views/commons/components/request-workplan/services/request-workplan.service.js";

export default {
  namespaced: true,
  state: {
    S_COUNTER_CREDIT_CARDS: 0,
    S_COUNTER_APPOINTMENTS: 0,
    S_COUNTER_POTENTIAL: 0,
    S_COUNTER_OTHER_SERVICES: 0,
    S_COUNTER_ALL_SERVICES: 0,
    S_COUNT_PENDING_WP_CLIENT: 0,
    refreshServicesTable: false,
    advisors: null,
    shareTo: null,
    programs: null,
    quicks: [],
    client: null,
    clientDashboardRoutes: {
      2: "dashboard-crm",
      3: "bussiness-dashboard-clients",
      5: "debt-solution-dashboard",
      6: "credit-experts-dashboard",
      7: "boost-credit-dashboard",
      8: "tax-research-client-dashboard",
      10: "claim-department-dashboard",
      11: "specialists-client-dashboard",
      12: "paragon-dashboard",
      14: "bookeeping-dashboard",
      18: "quality-client-dashboard",
      20: "connection-clients-account",
      22: "ce-customer-service-dashboard",
      23: "financial-clients-account",
      25: "specialist-digital-dashboard",
      28: "specialists-client-dashboard",
      29: "specialists-client-dashboard-fa",
    },
    leadDashboardRoutes: {
      2: "lead-show",
      3: "bussiness-lead-show",
      5: "debtsolution-lead-show",
      6: "creditexperts-lead-show",
      7: "boostcredit-lead-show",
      8: "tax-research-lead-show",
      10: "claimdepartment-lead-show",
      11: "specialist-lead-show",
      12: "ti-lead-show",
      14: "bookeeping-lead-show",
      20: "connection-lead-show",
      22: "customerservice-lead-show",
      28: "specialist-lead-show",
      29: "specialist-fa-lead-show",
    },
  },
  getters: {
    getRefreshTable: (state) => state.refreshServicesTable,
    Advisors: (state) => state.advisors,
    ShareTo: (state) => state.shareTo,
    Programs: (state) => state.programs,
    Quicks: (state) => state.quicks,
    client: (state) => state.client,
    clientDashboardRoutes: (state) => state.clientDashboardRoutes,
    leadDashboardRoutes: (state) => state.leadDashboardRoutes,
    G_COUNTER_CREDIT_CARDS: (state) => state.S_COUNTER_CREDIT_CARDS,
    G_COUNTER_APPOINTMENTS: (state) => state.S_COUNTER_APPOINTMENTS,
    G_COUNTER_POTENTIAL: (state) => state.S_COUNTER_POTENTIAL,
    G_COUNTER_OTHER_SERVICES: (state) => state.S_COUNTER_OTHER_SERVICES,
    G_COUNTER_ALL_SERVICES: (state) => state.S_COUNTER_ALL_SERVICES,
    G_COUNT_PENDING_WP_CLIENT(state) {
      return state.S_COUNT_PENDING_WP_CLIENT;
    },
  },
  mutations: {
    SET_REFRESH_TABLE(state, payload) {
      state.refreshServicesTable = payload;
    },
    SET_INFORMATION(state, payload) {
      state.client = payload;
    },
    SET_ADVISORS(state, payload) {
      state.advisors = payload;
    },
    SET_SHARETO(state, payload) {
      state.shareTo = payload;
    },
    SET_PROGRAMS(state, payload) {
      state.programs = payload;
    },
    SET_QUICKS(state, payload) {
      state.quicks = payload;
    },
    REMOVE_QUICK(state, id) {
      const index = state.quicks.map((el) => el.id).indexOf(id);
      if (index !== -1) {
        state.quicks.splice(index, 1);
      }
    },
    M_COUNTER_CREDIT_CARDS(state, payload) {
      state.S_COUNTER_CREDIT_CARDS = payload;
    },
    M_COUNTER_APPOINTMENTS(state, payload) {
      state.S_COUNTER_APPOINTMENTS = payload;
    },
    M_COUNTER_POTENTIAL(state, payload) {
      state.S_COUNTER_POTENTIAL = payload;
    },
    M_COUNTER_OTHER_SERVICES(state, payload) {
      state.S_COUNTER_OTHER_SERVICES = payload;
    },
    M_COUNTER_ALL_SERVICES(state, payload) {
      state.S_COUNTER_ALL_SERVICES = payload;
    },
    M_COUNT_PENDING_WP_CLIENT(state, payload) {
      state.S_COUNT_PENDING_WP_CLIENT = payload;
    },
  },
  actions: {
    CHANGE_REFRESH_TABLE({ commit }, data) {
      commit("SET_REFRESH_TABLE", data);
    },
    SET_INFORMATION_CLIENT({ commit }, body) {
      commit("SET_INFORMATION", body);
    },
    SET_ADVISORS({ commit }, data) {
      commit("SET_ADVISORS", data);
    },
    SET_SHARETO({ commit }, data) {
      commit("SET_SHARETO", data);
    },
    SET_PROGRAMS({ commit }, data) {
      commit("SET_PROGRAMS", data);
    },
    async SET_QUICKS({ commit }, params) {
      const data = await ClientService.allQuicksEmail(params);
      commit("SET_QUICKS", data.data);
    },
    REMOVE_QUICK({ commit }, id) {
      commit("REMOVE_QUICK", id);
    },
    async A_GLOBAL_SEARCH_CLIENTS({ commit }, params) {
      const { data } = await ClientService.search(params);
      return data.data;
    },
    async A_GET_ALL_SERVICES_COUNTER({ commit }, params) {
      try {
        const {
          counter_cc, // credit cards
          counter_a, // appointments
          counter_p, // potential
          counter_os, // other services
        } = await ApplicationsService.getCounterAllServicesDashboardClient(
          params
        );

        commit("M_COUNTER_CREDIT_CARDS", counter_cc);
        commit("M_COUNTER_APPOINTMENTS", counter_a);
        commit("M_COUNTER_POTENTIAL", counter_p);
        commit("M_COUNTER_OTHER_SERVICES", counter_os);
        commit(
          "M_COUNTER_ALL_SERVICES",
          counter_cc + counter_a + counter_p + counter_os
        );
      } catch (error) {
        console.error(error);
      }
    },

    async A_COUNT_PENDING_WP_CLIENT({ commit }, params) {
      const data = await RequestWorkPlanService.getPendingWPCounterClient(
        params
      );
      commit("M_COUNT_PENDING_WP_CLIENT", data.pending + data.completed);
    },
  },
};
