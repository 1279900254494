<template>
  <div>
    <b-modal
      ref="modalDataByMonths"
      modal-class="custom-modal-amg"
      title-class="h3 text-white"
      :title="title"
      size="lg"
      @hidden="closeModal()"
      header-bg-variant="transparent"
      header-class="p-0"
      centered
      hide-footer
      footer-class="p-0"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>{{ title }}</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModal()"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <div>
        <b-table
          id="details-table-report-sales-made"
          :fields="filterField"
          :items="dataReport"
          class="table-new-customization position-relative"
          sticky-header="70vh"
        >
          <template #cell(total)="{ item }">
            <b-badge
              :variant="
                Number(item.total) > 0 ? 'light-primary' : 'light-secondary'
              "
              class="p-1"
              :class="Number(item.total) > 0 ? 'cursor-pointer' : 'not-cursor'"
              @click="currentItem(item)"
              >{{ item.total }}</b-badge
            >
          </template>
          <template #cell(fee)="{ item }">
            {{ item.fee | formatMoney }}
          </template>
          <template #cell(initial_payment)="{ item }">
            {{ item.initial_payment | formatMoney }}
          </template>
          <!-- A custom formatted footer cell for field 'name' -->
          <!-- <template #foot()="data">
            <span>{{
              data.column === "name" ? "Total" : calcularTotales(data.column)
            }}</span>
          </template> -->
          <template #bottom-row>
            <b-th>
              <b-badge
                variant="primary"
                class="w-100 text-center"
                style="
                  font-size: 14px;
                  font-weight: 600;
                  padding-bottom: 7px;
                  padding-top: 7px;
                "
              >
                TOTAL
              </b-badge>
            </b-th>
            <b-th>
              <b-badge
                variant="success"
                class="w-100 text-center text-black"
                style="
                  font-size: 14px;
                  font-weight: 600;
                  padding-bottom: 7px;
                  padding-top: 7px;
                "
              >
                {{ calcularTotales("total") }}
              </b-badge>
            </b-th>
            <b-th>
              <b-badge
                variant="success"
                class="w-100 text-center text-black"
                style="
                  font-size: 14px;
                  font-weight: 600;
                  padding-bottom: 7px;
                  padding-top: 7px;
                "
              >
                {{ calcularTotales("fee") }}
              </b-badge>
            </b-th>
            <b-th>
              <b-badge
                variant="success"
                class="w-100 text-center text-black"
                style="
                  font-size: 14px;
                  font-weight: 600;
                  padding-bottom: 7px;
                  padding-top: 7px;
                "
              >
                {{ calcularTotales("initial_payment") }}
              </b-badge>
            </b-th>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ModalReportSalesMade",
  props: {
    dataReport: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return ["MONTHS", "PROGRAMS"].includes(value);
      },
    },
    year: {
      type: Number,
      required: true,
    },
  },
  computed: {
    title() {
      let titlePrograms = `Monthly Program Details for ${this.item?.name}, ${this.year}`;
      const titleMonths = `Monthly Reports of ${this.year}`;
      return this.type === "MONTHS" ? titleMonths : titlePrograms;
    },
    filterField() {
      this.fields[0].label = this.type === "MONTHS" ? "Month" : "Program";
      return this.fields;
    },
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          sortable: false,
          label: "Month",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "total",
          sortable: false,
          label: "TOTAL",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "fee",
          sortable: false,
          label: "FEE",
          visible: true,
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "initial_payment",
          sortable: false,
          label: "IP",
          visible: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      item: null,
    };
  },
  methods: {
    currentItem(item) {
      if (Number(item.total) == 0) return;
      this.setlocalStorage(item);
      this.$emit("currentItem", {
        ...item,
        type: this.type,
      });
    },
    closeModal() {
      this.$emit("close");
    },
    setlocalStorage(item) {
      localStorage.setItem("itemReport", JSON.stringify(item));
    },
    getLocalStorage() {
      if (localStorage.getItem("itemReport")) {
        const item = JSON.parse(localStorage.getItem("itemReport"));
        this.item = item;
      }
    },
    calcularTotales(column) {
      const total = this.getTotales("total");
      const fee = this.getTotales("fee");
      const initial_payment = this.getTotales("initial_payment");
      const item = {
        total,
        fee: this.numberFormat(fee),
        initial_payment: this.numberFormat(initial_payment),
      };
      return item[column];
    },
    getTotales(key) {
      return this.dataReport.reduce((a, b) => a + Number(b[key]), 0);
    },
  },
  mounted() {
    this.getLocalStorage();
    this.toggleModal("modalDataByMonths");
  },
};
</script>
<style lang="scss">
.not-cursor {
  cursor: not-allowed;
}
#details-table-report-sales-made {
  .b-table-bottom-row {
    position: sticky;
    bottom: 0;
    z-index: 1;

    th:nth-child(1) {
      border-radius: 0px 0px 0px 15px !important;
      margin-left: 5px !important;
      background: #0090e7 !important;
    }

    th:nth-child(2),
    th:nth-child(3) {
      font-size: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      background: #0090e7 !important;
    }

    th:nth-child(4) {
      border-radius: 0px 0px 15px 0px !important;
      margin-left: 10px !important;
      background: #0090e7 !important;
    }
  }
  .fill-bottom-row {
    background-color: #ffffff !important;
  }
}
</style>
