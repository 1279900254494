import Pusher from "pusher-js"

import {
  NewOtherPaymentsNotification,
  OtherPaymentsVerifiedNotification,
  OtherPaymentsApproveOrDecline,
} from "@/views/commons/components/clients/dashboard/options/pay-module/other-payments.socket.js"

const subscribeSocketOtherPayments = (socket) => {
  try {
    socket.subscribe("other-payments-channel")
    NewOtherPaymentsNotification()
    OtherPaymentsVerifiedNotification()
    OtherPaymentsApproveOrDecline()
  } catch (error) {
    console.error(error, "error")
  }
}

export default subscribeSocketOtherPayments
