<template>
  <div class="view-files-component-v1">
    <img :src="getImageFile(extension)" class="img-file" />
    <div class="text text-truncate">{{ filename }}</div>
    <feather-icon
      icon="DownloadIcon"
      size="20"
      class="cursor-pointer"
      @click="downloadFile()"
    />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },

    filename: {
      type: [String, Number],
      required: true,
    },

    extension: {
      type: String,
      default: "",
    },
  },

  methods: {
    getImageFile(extension) {
      const extensionMappings = {
        jpg: "jpg.png",
        png: "jpg.png",
        jpeg: "jpg.png",
        gif: "jpg.png",
        xls: "excel.png",
        xlsx: "excel.png",
        html: "html",
        pdf: "pdf.png",
        docx: "doc.png",
        pptx: "ppt.jpg",
        ppt: "ppt.jpg",
        vcard: "vcard.png",
        default: "archivodesc.png",
      };

      const getImageFile =
        extensionMappings[extension] || extensionMappings.default;
      return `/assets/images/drag-drop/ext_files/${getImageFile}`;
    },

    downloadFile() {
      this.forceDownloadAnyFile(this.url, this.filename);
    },
  },
};
</script>

<style lang="scss">
.view-files-component-v1 {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 250px;

  .text {
    max-width: 70%;
    margin: 0 5px;
  }

  .img-file {
    width: 35px;
    height: 35px;
  }
}
</style>