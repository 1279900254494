<template>
  <div class="w-100">
    <div class="header-modal">
      <div class="d-flex align-items-center">
        <span>{{ program }} Note</span>
      </div>
      <div
        class="container-icons"
        :class="isDarkSkin ? 'container-icons--dark' : ''"
        @click="closeModal()"
      >
        <feather-icon
          icon="XIcon"
          size="18"
          class="pointer"
          :class="isDarkSkin ? 'text-light' : 'text-primary'"
        />
      </div>
    </div>
    <!-- <b-button-close class="x-close-position" @click="closeModal()" /> -->
    <b-container fluid>
      <!-- <b-row class="bg-primary px-1 py-1 rounded-top">
        <h3 class="text-white mb-0 ml-1">{{ program }} Note</h3>
      </b-row> -->
      <b-row
        class="pt-1 px-1 border-bottom header-modal-notes"
        :class="isDarkSkin ? 'bg-dark-1' : 'bg-light-1'"
      >
        <b-col cols="12" :md="info.pack ? 4 : 6">
          <TitlesModals :title="info.nameClient" label-title="Client" />
        </b-col>
        <b-col cols="12" :md="info.pack ? 4 : 6">
          <TitlesModals :title="info.nameProgram" label-title="Program" />
        </b-col>
        <b-col v-if="info.pack" :md="info.pack ? 4 : 6">
          <TitlesModals :title="info.pack" label-title="Kind of Pack" />
        </b-col>
        <b-col cols="12" md="6">
          <TitlesModals :title="info.sourcesName" label-title="Source" />
        </b-col>
        <b-col cols="12" md="6">
          <TitlesModals
            v-if="fromDebt"
            :title="`${showContactSchedule.captured} / ${showContactSchedule.seller}`"
            label-title="Catcher / Seller"
          />
          <TitlesModals
            v-else
            :title="`${info.capturedName} / ${info.sellerName}`"
            label-title="Catcher / Seller"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TitlesModals from "@/views/commons/utilities/TitlesModalsNew.vue";

export default {
  name: "HeaderModalNotes",
  components: { TitlesModals },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    program: {
      type: String,
      default: "",
    },
    showContactSchedule: null,
    fromDebt: null,
  },
  computed: {
    isCreditExperts() {
      return this.moduleId === 6;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin == "dark";
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.bg-dark-1 {
  background-color: rgba(23, 22, 26, 255) !important;
}
.bg-light-1 {
  background-color: rgba(255, 255, 255, 255) !important;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
