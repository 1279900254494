<template>
  <b-card class="border border-secondary p-0 m-0" no-body>
    <b-card-header class="mb-2" header-bg-variant="info">
      <span class="text-white font-weigth-bolder"> Contact Schedule </span>
    </b-card-header>
    <b-card-body class="pl-1">
      <b-container>
        <b-row>
          <!-- INPUTS COL -->
          <b-col xs="12" sm="12" md="12" lg="2" class="pl-0">
            <div>
              <b-form-group label="Day">
                <v-select
                  v-model="contactScheduleObject.date"
                  :options="daysOfTheWeek"
                  :reduce="(days) => days.id"
                  :disabled="
                    switchAvailableLocal == 1 ? true : false || valorEdit
                  "
                  multiple
                  :closeOnSelect="false"
                />
              </b-form-group>
              <b-form-group label="Priority">
                <v-select
                  v-model="contactScheduleObject.priority"
                  :options="['High', 'Medium', 'Low']"
                  :disabled="
                    switchAvailableLocal == 1 ? true : false || valorEdit
                  "
                />
              </b-form-group>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="From">
                    <kendo-timepicker
                      :format="'HH:mm'"
                      v-model="contactScheduleObject.time_from"
                      :interval="15"
                      class="w-100 rounded bg-transparent"
                      placeholder="Time from"
                      style="height: 2.73rem"
                      :disabled="
                        switchAvailableLocal == 1 ? true : false || valorEdit
                      "
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="To">
                    <template #label>
                      <span>To</span>
                    </template>
                    <kendo-timepicker
                      :format="'HH:mm'"
                      v-model="contactScheduleObject.time_to"
                      :interval="15"
                      class="w-100 rounded bg-transparent"
                      placeholder="Time from"
                      style="height: 2.73rem"
                      :disabled="
                        switchAvailableLocal == 1 ? true : false || valorEdit
                      "
                      :min="minTime"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-row class="mt-2">
              <b-col
                v-if="
                  contactScheduleObject.date &&
                  contactScheduleObject.time_from &&
                  contactScheduleObject.priority &&
                  switchAvailableLocal == 0
                "
                cols="6"
                class="justify-content-end col"
              >
                <b-button
                  variant="primary"
                  class="text-nowrap"
                  @click="addContactSchedule()"
                >
                  <feather-icon icon="SuccessIcon" /> ADD
                </b-button>
              </b-col>
              <b-col cols="6">
                <label> Always Available</label>
                <b-form-group>
                  <b-form-checkbox
                    ref="asd"
                    v-model="switchAvailableLocal"
                    value="1"
                    :unchecked-value="0"
                    :disabled="valorEdit"
                    switch
                    @change="changeSwitchAlwaysAvailable"
                  >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="w-100" scrollable xs="12" sm="12" md="12" lg="10">
            <span
              v-if="switchAvailableLocal == 0 && ds_contact_schedule.length > 0"
            >
              Schedule
            </span>
            <!-- NEW COMPONENTS FOR CONTACT SCHEDULE ADDED: 18/03/2023-->
            <b-badge
              v-if="switchAvailableLocal == 0 && ds_contact_schedule.length > 0"
              class="cursor-pointer ml-1"
              variant="info"
              pill
              v-b-tooltip.hover.left="
                'This client has ' +
                ds_contact_schedule.filter((contactSched) => contactSched.date)
                  .length +
                ' schedules.'
              "
            >
              {{
                ds_contact_schedule.filter((contactSched) => contactSched.date)
                  .length
              }}
            </b-badge>
            <b-row
              v-if="showLoaderProp && switchAvailableLocal == 0"
              class="w-100 h-100 pt-1 d-flex justify-content-center"
            >
              <div class="m-auto text-center text-primary">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </b-row>

            <!-- NOTICES ABOUT THE EXISTENCE OF CONTACT SCHEDULES -->
            <!-- NO RECORDS -->
            <b-row
              v-if="
                ds_contact_schedule.filter((contactSched) => contactSched.date)
                  .length == 0 &&
                switchAvailableLocal == 0 &&
                !showLoaderProp
              "
              class="h-100"
            >
              <b-col
                cols="12"
                class="text-center justify-content-center m-auto"
              >
                <feather-icon size="50" class="text-info" icon="InfoIcon" />
                <h5 class="mt-2">There are no schedules to see...</h5>
              </b-col>
            </b-row>

            <!-- ALWAYS AVAILABLE -->
            <b-row v-if="switchAvailableLocal == 1" class="h-100">
              <b-col
                cols="12"
                class="text-center justify-content-center m-auto"
              >
                <feather-icon size="50" class="text-primary" icon="InfoIcon" />
                <h5 class="mt-2">This client is always available.</h5>
              </b-col>
            </b-row>
            <b-row v-if="schedulesToDuplicate.length > 0">
              <b-col cols="12">
                <b-form-group class="w-100 ml-1" label="Days to duplicate">
                  <v-select
                    v-model="daysToDuplicate"
                    :options="daysOfTheWeek"
                    :reduce="(opts) => opts.id"
                    multiple
                    :closeOnSelect="false"
                  />
                </b-form-group>
              </b-col>
              <b-col class="mt-1 ml-1" cols="12">
                <b-button
                  v-if="daysToDuplicate.length != 0"
                  variant="primary"
                  @click="duplicateSchedules"
                >
                  Duplicate Selected
                </b-button>
              </b-col>
            </b-row>
            <b-row
              ref="schedules"
              v-if="switchAvailableLocal == 0"
              class="pt-1 m-0 w-100"
            >
              <!-- SCHEDULES BY DAY -->
              <template v-for="(days, index) in daysOfTheWeek">
                <b-col
                  xs="6"
                  sm="6"
                  md="6"
                  lg="4"
                  xl="3"
                  class="pt-1"
                  :key="index"
                  v-if="getScheduleByDays(days.label).length"
                >
                  <b-card
                    header-bg-variant="info"
                    class="border h-100"
                    header-class="p-0"
                    no-body
                  >
                    <template #header>
                      <span style="margin: 5px; margin-left: 10px" class="font-weigth-bolder text-white">
                        {{ days.label }}
                      </span>

                        <div class="d-flex align-items-center">
                          <feather-icon
                          v-if="!valorEdit"
                          icon="TrashIcon"
                          class="text-white cursor-pointer mr-1"
                          v-b-tooltip.hover.top="
                            'Delete all schedules of ' + days.label
                          "
                          @click="deleteSchedulesByDay(days.id, days.label)"
                          size="15"
                        />

                        <b-checkbox
                          v-model="days.selected"
                          @change="setNewArray(days.label, days.selected)"
                          v-b-tooltip.hover.top="'Duplicate'"
                        />
                        </div>
                    </template>
                    <div
                      class="mt-1"
                      v-for="(schedule, index) in getScheduleByDays(days.label)"
                      :key="index"
                    >
                      <!-- SCHEDULES -->
                      <b-row class="icon-min">
                        <b-col cols="1">
                          <feather-icon
                            v-if="schedule.priority == 'Low'"
                            icon="InfoIcon"
                            v-b-tooltip.hover.top="
                              schedule.priority == ' priority'
                            "
                            class="text-primary"
                          />

                          <feather-icon
                            v-if="schedule.priority !== 'Low'"
                            :icon="
                              schedule.priority == 'High'
                                ? 'AlertTriangleIcon'
                                : 'AlertCircleIcon'
                            "
                            :class="
                              schedule.priority == 'High'
                                ? 'text-danger'
                                : 'text-warning'
                            "
                            v-b-tooltip.hover.top="
                              schedule.priority + ' priority'
                            "
                          />
                        </b-col>
                        <b-col sm="11" md="8" lg="7" xl="7" class="m-0 pr-0">
                          <small>{{ schedule.time_from | myTime }}</small>
                          <small v-if="schedule.time_to"> - </small>
                          <small>{{
                            schedule.time_to | myTimeOrNothing
                          }}</small>
                        </b-col>
                        <b-col v-if="!valorEdit" sm="12" md="12" lg="12" xl="3">
                          <feather-icon
                            size="12"
                            v-b-tooltip.hover.top="'Edit'"
                            icon="EditIcon"
                            class="cursor-pointer text-warning"
                            :style="{
                              marginRight: '5px'
                            }"
                            @click="editSchedule(schedule)"
                          />
                          <feather-icon
                            size="12"
                            v-b-tooltip.hover.top="'Delete'"
                            icon="TrashIcon"
                            class="cursor-pointer text-danger"
                            @click="
                              deletionLogicalSchedule(schedule.schedule_id)
                            "
                          />
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </b-col>
              </template>
            </b-row>
          </b-col>
        </b-row>

        <!-- THIS COMPONENT IS BEING LEFT TO SEE THE SCHEDULES OF OLD DATA -->
        <span class="text-warning" v-if="contact_schedule">
          * NOTE THAT THE FOLLOWING FIELD WILL ONLY BE VISIBLE WITH OLD
          DATA.</span
        >
        <b-input-group class="mt-2" v-if="contact_schedule">
          <b-input-group-prepend
            class="border-info rounded-left px-1 d-flex align-items-center justify-content-center bg-info text-white font-weight-bolder"
            >Contact Schedule</b-input-group-prepend
          >
          <b-form-input v-model="contact_schedule" :disabled="true" />
        </b-input-group>
      </b-container>
    </b-card-body>
    <edit-schedule-modal
      :schedule="scheduleToEdit"
      v-if="modalSchedule"
      @hidden="modalSchedule = false"
      @edit="reloadSchedules"
    />
  </b-card>
</template>
<style>
.icon-min {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
<script>
import { mapGetters } from "vuex";
import NotesServices from "@/views/commons/components/first-notes/services/notes.service";
import EditScheduleModal from "@/views/commons/components/first-notes/schedule-components/EditSchedule.vue";
import { amgApi } from "@/service/axios";
export default {
  props: {
    valorEdit: {
      type: Boolean,
      default: false,
    },
    ds_contact_schedule: {
      type: Array,
      default: [],
    },
    contact_schedule: {
      type: String,
      required: false,
    },
    noteInfo: {
      type: Object,
      default: [],
      required: false,
    },
    switchAvailable: 0,
    showLoaderProp: {
      type: Boolean,
      default: true,
    },
  },
  components: { EditScheduleModal },
  data() {
    return {
      switchAvailableLocal: 0,
      contactScheduleObject: {
        date: null,
        time_from: null,
        time_to: null,
        priority: null,
      },
      daysOfTheWeek: [
        { id: 1, label: "MONDAY" },
        { id: 2, label: "TUESDAY" },
        { id: 3, label: "WEDNESDAY" },
        { id: 4, label: "THURSDAY" },
        { id: 5, label: "FRIDAY" },
        { id: 6, label: "SATURDAY" },
        { id: 7, label: "SUNDAY" },
      ],
      schedulesToDuplicate: [],
      daysToDuplicate: [],
      posX: 0,
      posY: 0,
      modalSchedule: false,
      scheduleToEdit: [],
      showLoader: true,
    };
  },
  mounted() {
    this.switchAvailableLocal = this.switchAvailable;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    minTime() {
      let dTime = this.contactScheduleObject.time_from || "00:00";
      let contactDtime = "01/01/1970 " + dTime;
      var date = new Date(contactDtime);
      return date;
    },
  },
  methods: {
    // DUPLICATE SCHEDULES
    async duplicateSchedules() {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        this.addPreloader();
        try {
          let params = {
            // LIST OF SCHEDULES TO DUPLICATE
            schedules: this.schedulesToDuplicate,
            sales_id: this.noteInfo.saleId,
            // LIST OF DAYS TO DUPLICATE
            days_to_duplicate: this.daysToDuplicate,
          };
          const { status } = await NotesServices.duplicateContactSchedule(
            params
          );
          if (status == 200) {
            // SET CHECKBOX OF SCHEDULES IN FALSE
            this.daysOfTheWeek.filter((day) => {
              day.selected = false;
              this.schedulesToDuplicate = [];
              this.daysToDuplicate = [];
            });
            // REFRESH SCHEDULES
            this.$emit("getContactSchedule");
            this.showSuccessSwal("Contact schedule duplicated successfully!");
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    setNewArray(day, state) {
      if (state) {
        this.schedulesToDuplicate.push(...this.getScheduleByDays(day));
      } else {
        let indexToDelete = [];
        this.schedulesToDuplicate.map((val, index) => {
          if (val.date == day) {
            indexToDelete.push(index);
          }
        });
        this.schedulesToDuplicate.splice(
          indexToDelete[0],
          indexToDelete.length
        );
      }
    },
    getTooltip(priority) {
      switch (priority) {
        case "Low":
          return "Low priority";
          break;
        case "Medium":
          return "Medium priority";
          break;
        case "High":
          return "High priority";
          break;
      }
    },
    getScheduleByDays(nameDay) {
      return this.ds_contact_schedule.filter((val) => {
        return val.date == nameDay;
      });
    },
    getScheduleByDaysDuplicate(nameDay) {
      let params = this.ds_contact_schedule.filter((val) => {
        return val.date == nameDay;
      });
      params.filter((val) => {
        this.daysOfTheWeek.forEach((element) => {
          if (val.date != nameDay) {
            params.push();
          }
        });
      });
      return params;
    },
    getDayName(numberDay) {
      switch (numberDay) {
        case 1:
          return "MONDAY";
          break;
        case 2:
          return "TUESDAY";
          break;
        case 3:
          return "WEDNESDAY";
          break;
        case 4:
          return "THURSDAY";
          break;
        case 5:
          return "FRIDAY";
          break;
        case 6:
          return "SATURDAY";
          break;
        case 7:
          return "SUNDAY";
          break;
      }
    },
    clearFormContactSchedule() {
      this.contactScheduleObject.date = null;
      this.contactScheduleObject.priority = null;
      this.contactScheduleObject.time_from = null;
      this.contactScheduleObject.time_to = null;
    },
    async addContactSchedule() {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        this.addPreloader();
        try {
          let params = {
            sales_id: this.noteInfo.saleId,
            date: this.contactScheduleObject.date,
            time_from: this.contactScheduleObject.time_from,
            time_to: this.contactScheduleObject.time_to,
            priority: this.contactScheduleObject.priority,
            user_id: this.currentUser.user_id,
          };
          const { status } = await NotesServices.addContactSchedule(params);
          if (status == 200) {
            this.reloadSchedules(false);
            this.showSuccessSwal("Contact schedule added successfully!");
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    async deletionLogicalSchedule(scheduleId) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        this.addPreloader();
        try {
          let params = {
            schedule_client_id: scheduleId,
            user_id: this.currentUser.user_id,
          };
          const { status } = await NotesServices.deletionLogicalSchedule(
            params
          );
          if (status == 200) {
            this.reloadSchedules(false);
            this.showSuccessSwal("Contact schedule deleted successfully!");
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    async editSchedule(schedule) {
      this.modalSchedule = true;
      this.scheduleToEdit = schedule;
    },
    async deleteSchedulesByDay(day, label) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          let params = {
            schedule_day: day,
            sale_id: this.noteInfo.saleId,
          };
          await NotesServices.deletionScheduleByDay(params);
          this.reloadSchedules(false);
          this.showSuccessSwal(
            "All contact schedules of the day " +
              label +
              " have been deleted successfully!"
          );
        } catch (error) {
          throw error;
        } finally {
          this.removePreloader();
        }
      }
    },
    changeSwitchAlwaysAvailable(){
      this.schedulesToDuplicate = []
      this.$emit('changeSwitch', this.switchAvailableLocal)
    },
    reloadSchedules(val) {
      this.modalSchedule = false;
      this.clearFormContactSchedule();
      this.$emit("getContactSchedule");
      if (val) {
        this.showSuccessSwal("Contact schedule edited successfully");
      }
    },
    hideLoader() {
      this.showLoader = false;
    },
  },
};
</script>
