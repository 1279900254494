var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"checks-tracking-modal",attrs:{"modal-class":"custom-modal-amg","title-class":"h3 text-white","title":_vm.title,"size":"lg","header-bg-variant":"transparent","header-class":"p-0","centered":"","hide-footer":""},on:{"hidden":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"header-modal"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"container-icons",class:_vm.isDarkSkin ? 'container-icons--dark' : '',on:{"click":function($event){return _vm.closeModal()}}},[_c('feather-icon',{staticClass:"pointer",class:_vm.isDarkSkin ? 'text-light' : 'text-primary',attrs:{"icon":"XIcon","size":"18"}})],1)])]},proxy:true}])},[_c('b-row',{staticClass:"client-info"},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('span',{staticClass:"mr-1 mb-1"},[_vm._v("Pay to")]),_c('div',{staticClass:"mr-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v(_vm._s(_vm.getPayTo))])])]),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('span',{staticClass:"ml-1 mb-1"},[_vm._v("Memo")]),_c('div',{staticClass:"ml-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v(_vm._s(_vm.getMemo))])])])],1),_c('div',[_c('b-table',{ref:"checks-tracking-table",staticClass:"position-relative table-new-customization",class:['text-center'],attrs:{"id":"checks-tracking-table","sticky-header":"70vh","small":"","show-empty":"","responsive":"sm","items":_vm.items,"fields":_vm.filteredFields},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobalDay")(item.created_at)))])]}},{key:"cell(status_name)",fn:function(ref){
var item = ref.item;
return [(item.type_tracking == 1 || item.type_tracking == 3)?_c('span',{class:_vm.getProcess(item, 'color'),staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.getProcess(item, "data")))]):_vm._e(),(item.type_tracking == 2)?_c('span',{class:_vm.colorTextStatusCheck(item),staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.statusCheckText(item))+" ")]):_vm._e()]}},{key:"cell(created_by_name)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.created_by_name == "Paragon " ? "System" : item.created_by_name))])]}},{key:"cell(details)",fn:function(ref){
var item = ref.item;
return [(item.status_id == 9)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("#: ")]),_vm._v(_vm._s(item.check_number))])],1),(_vm.dataRow.check_path)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Show: ")]),_c('a',{attrs:{"href":_vm.dataRow.check_path,"target":"_blank"}},[_c('amg-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"FilePdfIcon","size":"18"}})],1)])],1):_vm._e()],1):(item.status_id == 6)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Type shipping: ")]),_c('span',{class:item.type_shipping == 'regular' ? 'text-info' : 'text-warning'},[_vm._v(" "+_vm._s(item.type_shipping.toUpperCase())+" ")])])],1),(item.type_shipping == 'overnight')?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Tracking #: ")]),_vm._v(_vm._s(item.tracking_number))])],1):_vm._e(),(
                _vm.dataRow.evidence_path && item.type_shipping == 'overnight'
              )?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Evidence: ")]),_c('a',{attrs:{"href":_vm.dataRow.evidence_path,"target":"_blank"}},[_c('feather-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"FileIcon","size":"18"}})],1)])],1):_vm._e()],1):(item.status_id == 7)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Collect date: ")]),_vm._v(_vm._s(_vm._f("myGlobal")(_vm.dataRow.collect_date)))])],1),(_vm.dataRow.collect_path)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Evidence: ")]),_c('a',{attrs:{"href":_vm.dataRow.collect_path,"target":"_blank"}},[_c('feather-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"FileIcon","size":"18"}})],1)])],1):_vm._e()],1):([3, 8, 10].includes(item.status_id))?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("Observation: ")]),_vm._v(_vm._s(item.observation))])],1)],1):(item.type_tracking == 1)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_vm._v(_vm._s(item.comment))])],1)],1):(item.type_tracking == 3)?_c('div',[(item.tif)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('strong',[_vm._v("PIF: ")]),_c('a',{attrs:{"href":item.tif,"target":"_blank"}},[_c('feather-icon',{staticClass:"cursor-pointer text-danger",attrs:{"icon":"FileIcon","size":"18"}})],1)])],1):_vm._e()],1):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }