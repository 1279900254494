import { amgApi } from "@/service/axios";

class PaymentsService {
  async getAllPaymentsClients(body) {
    const data = await amgApi.post("/pay/get-search-client-moduls", body);
    return data;
  }

  async getAllPaymentsLeads(body) {
    const data = await amgApi.post("/pay/get-search-lead-client-moduls", body);
    return data;
  }

  async createPayment(body) {
    const data = await amgApi.post("/authorize/create-payment", body);
    return data;
  }
}

export default new PaymentsService();
