import { amgApi } from '@/service/axios'

class DashboardSalesServices {
  async getDashboardSellerCounter(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-seller-counter', params)
      return data
    } catch (error) {
      console.log('Error getDashboardSellerCounter')
    }
  }

  async getDashboardSellerProductivity(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-seller-productivity', params)
      return data
    } catch (error) {
      console.log('Error getDashboardSellerCounter')
    }
  }

  async getDashboardSellerAppointments(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-seller-appointments', params)
      return data
    } catch (error) {
      console.log('Error getDashboardSellerAppointments')
    }
  }
  async getDashboardSellerTasks(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-seller-tasks', params)
      return data
    } catch (error) {
      console.log('Error getDashboardSellerTasks')
    }
  }

  async getDashboardSupCounter(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-sup-counter', params)
      return data
    } catch (error) {
      console.log('Error getDashboardSellerCounter')
    }
  }

  async getDashboardSupProductivity(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-sup-productivity', params)
      return data
    } catch (error) {
      console.log('Error getDashboardSellerCounter')
    }
  }

  async getDashboardSupProductivitySeller(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-sup-productivity-seller', params)
      return data
    } catch (error) {
      console.log('Error getDashboardSellerCounter')
    }
  }

  async getDashboardDetailsSalesSup(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-detail-sales-sup', params)
      return data
    } catch (error) {
      console.log('Error getDashboardDetailsSales')
    }
  }

  async getDashboardDetailsSalesSeller(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-detail-sales-seller', params)
      return data
    } catch (error) {
      console.log('Error getDashboardDetailsSales')
    }
  }

  async getDashboardDetailsLeadSeller(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-detail-lead-seller', params)
      return data
    } catch (error) {
      console.log('Error getDashboardDetailsSales')
    }
  }
  async getDashboardDetailsNcrSeller(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-detail-ncr-seller', params)
      return data
    } catch (error) {
      console.log('Error getDashboardDetailsSales')
    }
  }
  async getDashboardDetailsRealtorSeller(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/sales/get-dashboard-detail-realtor-seller', params)
      return data
    } catch (error) {
      console.log('Error getDashboardDetailsSales')
    }
  }


  async getSalesCommissionByUserId(params) {
    try {
      const data = await amgApi.post(
        '/commissions-new/get-sales-commission-by-user-id',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getPiggyLevelByUserId(params) {
    try {
      const data = await amgApi.post(
        '/commissions-new/get-piggy-level-by-user-id',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }
}
export default new DashboardSalesServices()
