import { render, staticRenderFns } from "./AddChargeForm.vue?vue&type=template&id=0b0d0a08&scoped=true"
import script from "./AddChargeForm.vue?vue&type=script&lang=js"
export * from "./AddChargeForm.vue?vue&type=script&lang=js"
import style0 from "./AddChargeForm.vue?vue&type=style&index=0&id=0b0d0a08&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b0d0a08",
  null
  
)

export default component.exports