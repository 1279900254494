import { amgApi } from "@/service/axios";

class UpdatesService {
  async getPendingUpdates(params) {
    try {
      const { data } = await amgApi.post("dispute/searchletterdispute", params);
      return data;
    } catch (error) {
      console.error("Error in getPendingUpdate", error);
    }
  }

  async deleteRoundLetterDispute(params) {
    try {
      const data = await amgApi.post(
        `dispute/delete-round-letter-dispute`,
        params
      );
      return data;
    } catch (error) {
      console.error("Error in getDeletedUpdate", error);
    }
  }

  async getUsersProgramsspNcr(params) {
    try {
      const data = await amgApi.post(`program/get-users-program-ncr`, params);
      return data;
    } catch (error) {
      console.error("Error in getUsersUpdate", error);
    }
  }

  async getCredentialEmail(params) {
    try {
      const data = await amgApi.post(`dispute/get-credential-email`, params);
      return data;
    } catch (error) {
      console.error("Error in getUsersUpdate", error);
    }
  }

  async getAllLettersDispute(params) {
    try {
      const data = await amgApi.post(`dispute/all-letters-dispute`, params);
      return data;
    } catch (error) {
      console.error("Error in getUsersUpdate", error);
    }
  }

  async getTypeCharges(params) {
    try {
      const data = await amgApi.get(`applications/get-types-charges`, params);
      return data;
    } catch (error) {
      console.error("Error in getUsersUpdate", error);
    }
  }

  async updateLettersDispute(params) {
    try {
      const data = await amgApi.post(`dispute/updateLettersDispute`, params);
      return data;
    } catch (error) {
      console.error("Error in getUsersUpdate", error);
    }
  }

  async getDeleteAcountCnpi(params) {
    try {
      const data = await amgApi.post(`dispute/get-delete-account-cnpi`, params);
      return data;
    } catch (error) {
      console.error("Error in getUsersUpdate", error);
    }
  }

  async getDeleteAcountCnin(params) {
    try {
      const data = await amgApi.post(`dispute/get-delete-account-cnin`, params);
      return data;
    } catch (error) {
      console.error("Error in getUsersUpdate", error);
    }
  }

  async getCountUpdates(params) {
    try {
      const data = await amgApi.post("dispute/get-count-updates", params);
      return data;
    } catch (error) {
      console.log("Error getCountProcessCE");
    }
  }

  async assignWorkplan() {
    try {
      return await amgApi.post("dispute/get-count-updates", params);
    } catch (error) {
      throw new Error(error);
    }
  }

  async getCreditAnalyst() {
    try {
      return await amgApi.post("dispute/get-credit-analyst");
    } catch (error) {
      throw new Error(error);
    }
  }

  async getCompletedUrInfo(params) {
    try {
      const { data } = await amgApi.post(
        `dispute/get-completed-update-request-information`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getUpdates(params) {
    try {
      const { data } = await amgApi.post(`dispute/get-updates`, params);

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async saveView(params) {
    try {
      const { data } = await amgApi.post(`dispute/save-view`, params);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}
export default new UpdatesService();
