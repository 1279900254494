import ClientNavigation from '@/views/specialist-digital/views/clients/clients-group.navigation';
import ApplicationNavigation from "@/views/specialist-digital/views/applications/applications.nav";
import LettersNavigation from "@/views/specialist-digital/views/letters-round/letters-round.nav";
import DirectsRoundNavigation from "@/views/specialist-digital/views/directs-round/directs-round.nav";
import PreocessCRNavigation from "@/views/specialist-digital/views/process-cr/process-cr.nav";
import SpecialistsDashboardNav from "@/views/specialist-digital/views/dashboard/specialists-dashboard.nav";
import ConnectionClientsNav from "@/views/specialist-digital/views/connection-clients/navigation/connection-clients.nav.js";
import UpdatesNavigation from "@/views/specialist-digital/views/updates/updates.nav";
import EmployeeClaimsNavigation from "@/views/specialist-digital/views/employee-claims/navigation/employee-claims.nav";
import RequestNavigation from "@/views/specialist-digital/views/request/request.nav.js";
import RingCentralNavigation from "@/views/specialist-digital/views/ring-central/ring-central.nav.js";
import requestNcrNav from "@/views/specialist-digital/views/request-ncr/request-ncr.nav.js";
import specialistDigitalProjectsNavigation from '@/views/specialist-digital/views/projects/specialist-digital-projects.navigation';
import MOFNavigation from '@/views/specialist-digital/views/manual-of-functions/manual-of-functions.nav.js';
import RecruitmentProcessNavigation from '@/views/specialist-digital/views/recruitment-process/specialist-digital-recruitment_process.navigation';

import CourtInfoNavigation from "@/views/specialist-digital/views/cour-info/navigation/ds-court-info.nav";

const navigation = [
  {
    header: "SPECIALISTS DIGITAL",
  },
  SpecialistsDashboardNav,
  ClientNavigation,
  ConnectionClientsNav,
  ...ApplicationNavigation,
  ...PreocessCRNavigation,
  requestNcrNav,
  LettersNavigation,
  DirectsRoundNavigation,
  EmployeeClaimsNavigation,
  UpdatesNavigation,
  RequestNavigation,
  specialistDigitalProjectsNavigation,
  RingCentralNavigation,
  ...CourtInfoNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
];

export default navigation;
