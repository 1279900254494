import Vue from "vue"; // To get the 'component' of 'Notification';
import store from "@/store"; // To get user(s)
import router from "@/router";

const notificationParticipantsToTicketCustomer = () => {
  window.socket.bind(
    "notification-participants-tickets-customer",
    async (data) => {
      const { user_id, arrRoles } = store.state.auth.currentUser;
      const resultado = arrRoles.find(
        (rol) => rol.role_id === 2 && rol.module_id !== 24 && rol.user_id !== 38
      );

      const {
        motive,
        description,
        name_client,
        account,
        number_phone,
        expense_transaction_id,
        expense_id,
      } = data.otherData;

      const routesCustomerTicket = {
        2: "crm-customer-tickets",
        3: "business-customer-tickets",
        4: "administration-customer-tickets",
        5: "debt-solution-customer-tickets",
        6: "credit-experts-customer-tickets",
        7: "boost-credit-customer-tickets",
        8: "tax-research-customer-tickets",
        9: "correspondence-customer-tickets",
        10: "court-info-customer-tickets",
        11: "analyst-department-customer-tickets",
        12: "paragon-customer-tickets",
        14: "bookeeping-customer-tickets",
        15: "social-network-customer-tickets",
        16: "management-customer-tickets",
        17: "rrhh-customer-tickets",
        18: "quality-customer-tickets",
        19: "logistic-customer-tickets",
        20: "connection-customer-tickets",
        22: "customer-service-customer-tickets",
        23: "financial-customer-tickets",
        25: "specialist-digital-customer-tickets",
        26: "sales-customer-tickets",
        27: "creative-customer-tickets",
      };

      // show notification to users participants of ticket
      if (data.arrayUsersId.includes(user_id)) {
        const objectChat = {
          name: "ModalTicketCustomer",
          props: {
            customerTicketChat: data.otherData,
            fileType: {},
          },
        };

        // if the 'current user' is in the 'customer ticket view', the notifications are 'not displayed'.
        let currentView = store.state.TicketCustomerStore.S_CURRENT_VIEW;
        currentView = currentView ? currentView.toLowerCase() : null;

        if (currentView !== "modalticketcustomer") {
          let dataArray = [
            {
              icon: "UserIcon",
              title: data.status === 1 ? "Invited By" : "Remove By",
              description: data.createdByName,
            },
            {
              icon: "BookmarkIcon",
              title: "Motive",
              description: motive,
            },
            {
              icon: "BookOpenIcon",
              title: "Description",
              description,
            },
            {
              icon: "UserIcon",
              title: "Client",
              description: name_client,
            },
            {
              icon: account ? "BookmarkIcon" : null,
              title: account ? "Client Account" : null,
              description: account || null,
            },
            {
              icon: !account ? "PhoneIcon" : null,
              title: !account ? "Mobile" : null,
              description: !account ? number_phone : null,
            },
          ];
          if (expense_id || expense_transaction_id) {
            dataArray = dataArray.slice(0, 3);
          }
          await Vue.prototype.$amgAlert({
            title:
              data.status === 1
                ? "NEW TICKET PARTICIPATION"
                : "REMOVE PARTICIPATION",
            icon: "BellIcon",
            subtitle: "IMPORTANT",
            data: dataArray,
            okComponentTitle: "Open Chat",
            dataComponent: data.status === 1 ? objectChat : null,
            okTitle: data.status === 1 ? "Go Ticket" : "Ok",
            // ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
            ok: async () => {
              if (data.status === 1) {
                router.push({
                  name: routesCustomerTicket[resultado.module_id],
                });
              } else {
                await window.amgApi.post("/commons/close-all-swal", {
                  to_id: user_id,
                });
              }
            },
          });
        }
        // Recargar los contadores de customer tickets
        const currentUserId = store.state.auth.currentUser.user_id;
        const moduleId = store.state.auth.currentUser.modul_id;
        const mainModule = store.state.auth.currentUser.modul_id;
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
          { user_id: currentUserId }
        );
        await store.dispatch("NotificationStore/A_COUNT_INDICATORS_TICKETS", {
          user_id: currentUserId,
          module_id: moduleId,
        });
        const countPendingTickets = await store.getters[
          "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS"
        ];
        const payload = {
          routeName: routesCustomerTicket[resultado.module_id],
          tag: countPendingTickets.v_total_open,
        };
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
      }
    }
  );
};

export default notificationParticipantsToTicketCustomer;
