import RequestService from "@/views/commons/components/request/services/request.service.js";
import store from "@/store";

export default {
    namespaced: true,
    state: {
        S_COUNTER_PENDING: 0,
        S_COUNTER_IN_PROCESS: 0,
        S_COUNTER_DELIVERED: 0,
    },
    mutations: {
        M_SET_COUNTER_PENDING(state, payload) {
            state.S_COUNTER_PENDING = payload
        },

        M_SET_COUNTER_IN_PROCESS(state, payload) {
            state.S_COUNTER_IN_PROCESS = payload
        },

        M_SET_COUNTER_DELIVERED(state, payload) {
            state.S_COUNTER_DELIVERED = payload
        },

    },
    actions: {
        async A_SET_COUNTERS_PENDING({ commit, getters }, payload) {
            commit('M_SET_COUNTER_PENDING', payload)
        },

        A_SET_COUNTERS_IN_PROCESS({ commit }, payload) {
            commit('M_SET_COUNTER_IN_PROCESS', payload)
        },

        async A_SET_COUNTERS_DELIVERED({ commit }, payload) {
            try {
                const data = await RequestService.getCounterNotAsigned(payload);
                commit('M_SET_COUNTER_DELIVERED', data[0].counter);
                const payload2 = {
                    routeName: 'request-logistic',
                    tag: data[0].counter
                }
                store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload2)
            } catch (error) {
                throw error;
            }
        },
    }
}