<template>
  <div>
    <div class="container">
      <ul class="progressbar">
        <!--        li with activated deactivated and disable class-->
        <template v-for="item in stepData">
          <li
            style="margin-left: 10px"
            :key="item.id"
            :class="[
              selectedData.some(
                (data) => data.sale_process_status_id == item.id
              )
                ? 'active-1'
                : 'disabled deactivated-4',
            ]"
            v-b-tooltip.hover="item.sale_process_name"
            @click="getStep(item)"
          >
            {{
              item.sale_process_name.length > 8
                ? item.sale_process_name.slice(0, 8) + "..."
                : item.sale_process_name
            }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepProgressBar",
  props: {
    stepData: {
      type: Array,
      default: null,
    },
    selectStep: {
      type: Number,
      default: null,
    },
    selectedData: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      changeStep: null,
    };
  },
  computed: {
    lastElementinSelectedData() {
      return this.selectedData[this.selectedData.length - 1];
    },
  },
  watch: {
    // get current step
    selectStep(newVal) {
      this.changeStep = newVal;
    },
  },
  methods: {
    getStep(item) {
      this.changeStep = item.id;
      this.$emit("selectProcess", this.changeStep);
    },
  },
};
</script>

<style lang="scss" scoped>
/*styles progress bar*/
.container {
  margin-top: 20px;
  position: relative;
  right: 20px;
  z-index: 3;
  width: 100%;
}
.progressbar {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  counter-reset: step;
}
.progressbar li {
  text-align: center;
  position: relative;
  width: 100px;
  white-space: nowrap;
  /*white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;*/
}
.progressbar li:before {
  cursor: pointer;
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  line-height: 27px;
  text-align: center;
  font-weight: bold;
  position: relative;
  z-index: 2;
}
.progressbar li:after {
  content: "";
  display: flex;
  align-items: center;
  width: 70px;
  height: 3px;
  background: #979797;
  z-index: 1;
  position: relative;
  bottom: 45px;
  right: 40px;
}
@media (max-width: 991.98px) {
  .progressbar li:after {
    display: flex;
    align-items: center;
    width: 66px;
    height: 3px;
    background: #979797;
    z-index: 1;
    position: relative;
    bottom: 45px;
    right: 38px;
  }
}
/*degrade colors step*/
.progressbar li:first-child:after {
  content: none;
}
.progressbar li:first-child:before {
  border-color: #ff597c;
  background: #ff597c;
  color: white;
}
.progressbar li.active-1 + li:after {
  background: #ff597c;
}
.progressbar li.active-1 + li:before {
  border-color: #ff597c;
  background: #ff597c;
  color: white;
}
/* disabled steps */
.progressbar li.disabled + li:after {
  background: #717977; /* success */
}
.progressbar li.disabled + li:before {
  border-color: #717977; /* success */
  background: #717977;
  color: white;
}
/* deactivated steps */

.progressbar{
  li.deactivated-4::after{
    background: #642157 !important;
    opacity: 0.5 !important;
  }
  li.deactivated-4::before{
    border-color: #642157 !important;
    background: #642157 !important;
    opacity: 0.5 !important;
    color: white !important;
  }
}
</style>