<template>
  <div>
    <b-modal
      ref="lead-choose-transaction-modal"
      @hidden="$emit('hidden')"
      size="payment"
      scrollable
      header-class="custom-modal-header"
      content-class="custom-modal-content"
      modal-class="lead-choose-transaction-modal"
      no-enforce-focus
      centered
      no-close-on-backdrop
    >
      <!-- hide-footer -->
      <!-- TITLE MODAL -->
      <template #modal-header="{ close }">
        <div>Choose Payment Type</div>
        <feather-icon
          icon="XCircleIcon"
          class="close-icon cursor-pointer"
          size="23"
          @click="close()"
        />
      </template>
      <!-- BODY MODAL -->
      <template #default>
        <ValidationObserver ref="form">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                class="lead-input"
                id="lead"
                label="Lead"
                label-for="lead"
              >
                <div class="lead-name">
                  {{ leadName }}
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                class="lead-input"
                id="amount-input"
                label="Amount"
                label-for="amount-input"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="amount-input"
                  rules="required|validate-amount"
                >
                  <money
                    v-model="amount"
                    v-bind="{
                      decimal: '.',
                      thousands: ',',
                      prefix: '$ ',
                      precision: 2,
                    }"
                    class="amount-input"
                    :class="{ 'border-danger': errors[0] && validateMoney }"
                  />
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class="lead-input"
                id="choose-type-container"
                label="Type"
                label-for="choose-type-container"
              >
                <div class="choose-type-container">
                  <div
                    class="choose-type-card"
                    :class="{ active: type.value == selectedType.value }"
                    v-for="type in types"
                    :key="type.value"
                    @click="selectType(type)"
                  >
                    <feather-icon icon="CreditCardIcon" size="15" />
                    <span> {{ type.label }} </span>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="selectedType.value == 6">
              <b-form-group
                class="lead-input"
                id="observation"
                label="Observation"
                label-for="observation"
              >
                <b-form-input
                  v-model="observation"
                  class="amount-input"
                  placeholder="Enter an observation"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </ValidationObserver>
      </template>
      <template #modal-footer="{ close }">
        <b-button variant="outline-secondary" class="rounded" @click="close()">
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="rounded button-collect"
          @click="openAddLeadTransactionModal()"
        >
          Pay
        </b-button>
      </template>
    </b-modal>
    <LeadAddTransaction
      :lead-id="leadId"
      :lead-name="leadName"
      :concept="selectedType.label"
      :amount="amount"
      v-if="showAddLeadTransaction"
      @hidden="showAddLeadTransaction = false"
      @on-collect="registerTransaction"
    />
  </div>
</template>
<script>
import ModalCardNewCreate from "@/views/crm/views/Lead/lead-card/ModalCardNewCreate.vue";
import LeadAddTransaction from "@/views/commons/components/leads/transactions/LeadAddTransaction.vue";
import CrmLead from "@/views/crm/services/lead/index.js";
import { mapGetters } from "vuex";
export default {
  components: {
    ModalCardNewCreate,
    LeadAddTransaction,
  },
  props: {
    leadName: {
      type: String,
      default: "",
    },
    leadId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      types: [
        {
          value: 4,
          label: "Realtor",
        },
        {
          value: 12,
          label: "Lien",
        },
        {
          value: 5,
          label: "Appointment",
        },
        {
          value: 6,
          label: "Other",
        },
      ],
      validateMoney: false,
      fistValidateCard: false,
      selectedType: {
        value: 4,
        label: "Realtor",
      },
      showAddLeadTransaction: false,
      amount: 0,
      observation: "",
    };
  },
  mounted() {
    this.toggleModal("lead-choose-transaction-modal");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    selectType(type) {
      this.selectedType = { ...type };
      this.observation = "";
    },
    onReloadCards() {
      this.$emit("reloadCards");
    },
    async openAddLeadTransactionModal() {
      this.validateMoney = true;
      const isValid = await this.$refs.form.validate();
      if (isValid) {
        this.showAddLeadTransaction = true;
      }
    },
    async registerTransaction(authorizePaymentInformation) {
      try {
        this.addPreloader();
        await CrmLead.postLeadPayment({
          ...authorizePaymentInformation,
          lead_id: this.leadId,
          type: this.selectedType.value,
          observation: this.observation,
          user_id: this.currentUser.user_id,
          amount: this.amount,
        });
        this.$emit('on-transaction-registered');
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss">
.lead-choose-transaction-modal {
  .custom-modal-header {
    background: #fff !important;
    color: #000;
    padding: 1.9rem 1.4rem 0.8rem 1.4rem;
    border-radius: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close-icon {
      color: #82868b;
    }
  }
  .custom-modal-content {
    border-radius: 20px;
  }
  .lead-input {
    label {
      font-size: 1rem;
      font-weight: 700;
      color: #111113;
    }

    .choose-type-container {
      display: flex;
      gap: 10px;
      .choose-type-card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 18px;
        padding-bottom: 18px;
        align-items: center;
        background: #f3f3f7;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 10px;
        font-size: 1rem;
        color: #111113;
        text-align: center;
        font-weight: 600;
        width: 100%;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        &.active {
          padding: 16px 8px;
          border: 2px solid #0090e7;
        }
      }
    }

    .lead-name {
      background: #f3f3f7;
      padding: 10px;
      border-radius: 5px;
      font-size: 1rem;
      color: #111113;
      font-weight: 600;
    }
    .pay-type-select {
      background: #f3f3f7;
      border-radius: 5px;
      border: none;
      .vs__dropdown-toggle {
        padding: 4px;
        border: none;
        .vs__selected-options {
          .vs__selected {
            color: #111113;
            font-weight: 600;
          }
        }
      }
    }
    .amount-input {
      background: #f3f3f7;
      border-radius: 5px;
      border: none;
      padding: 10px 13px;
      color: #111113;
      font-weight: 600;
      &:focus-visible {
        outline: none;
      }
      &.border-danger {
        padding: 9px 12px;
      }
    }
  }
  .payment-card-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    .payment-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f3f3f7;
      padding: 10px 13px;
      border-radius: 5px;
      font-size: 1.1rem;
      color: #111113;
      font-weight: 600;
      .payment-card-customer-information {
        display: flex;
        flex-direction: column;
        .cu-name {
          font-size: 1rem;
          color: #111113;
          font-weight: 700;
        }
        .cu-card {
          font-size: 1rem;
          color: #82868b;
          font-weight: 600;
        }
      }
      &.active {
        padding: 8px 11px;
        border: 2px solid #0090e7;
      }
    }
  }
  .button-collect {
    font-weight: 700;
  }
}

.dark-layout {
  .lead-choose-transaction-modal {
    .custom-modal-header {
      background: #17171a !important;
      color: #fff;
      .close-icon {
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .lead-input {
      label {
        color: #fff;
      }

      .choose-type-container {
        .choose-type-card {
          background: #323241;
          color: #fff;
        }
      }

      .lead-name {
        background: #323241;
        color: #fff;
      }
      .pay-type-select {
        background: #323241;
        .vs__dropdown-toggle {
          background: #323241;
          .vs__selected-options {
            .vs__selected {
              color: #fff;
            }
          }
        }
      }
      .amount-input {
        background: #323241;
        color: #fff;
      }
    }
    .payment-card-container {
      .payment-card {
        background: #323241;
        color: #f3f3f7;
        .payment-card-customer-information {
          .cu-name {
            color: #f3f3f7;
          }
          .cu-card {
            color: #82868b;
          }
        }
      }
    }
  }
}
</style>