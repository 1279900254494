export default {
  path: "settings",
  name: "settings",
  component: () => import("@/views/administration/views/settings/Settings.vue"),
  redirect: { name: "administration-settings" },
  meta: {
    pageTitle: "Settings",
    breadcrumb: [
      {
        text: "Settings",
      },
    ],
    permittedRoles: [1, 2],
  },

  children: [
    {
      path: "",
      name: "administration-settings",
      component: () =>
        import(
          "@/views/administration/views/settings/components/GridTable.vue"
        ),
      meta: {
        permittedRoles: [1, 2],
        pageTitle: "Settings",
        breadcrumb: [
          {
            text: "Settings",
            // to: { name: "administration-sales-made-new-client" }
          },
        ],
      },
    },
  ],
};
