<template>
  <div>
    <b-img
      :title="statusPayment.title"
      :src="assetsImg + statusPayment.image"
      :alt="client.type_payment"
      style="margin-right: 5px"
    />
    <span v-if="client.day_payment != null" style="margin-bottom: 5px">
      [{{ paymentsDay }}]
    </span>
    <modal-history-monthly-payment
      v-if="modalHistoryPayment"
      @close="modalHistoryPayment = false"
    />
  </div>
</template>

<script>
import Feather from "@/views/ui/feather/Feather.vue";
import { mapGetters } from "vuex";
import ModalHistoryMonthlyPayment from "@/views/commons/components/clients/dashboard/information-client/modals-client-information/ModalHistoryMonthlyPayment.vue";

export default {
  components: { Feather, ModalHistoryMonthlyPayment },
  data() {
    return {
      assetsImg: "/assets",
      modalHistoryPayment: false,
    };
  },

  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
    }),
    programId() {
      return this.$route.matched[0].meta.program;
    },
    rbDetails() {
      return this.convertJsonToArr(this.client.rb_detail);
    },
    paymentsDay() {
      let days = this.rbDetails?.map((rb) => rb.day_payment) || [];
      return days.join(", ");
    },
    statusPayment() {
      let image = "";
      let title = "";
      if (this.client.type_payment == "PA" && this.client.rbp == "ACTIVE") {
        image = "/images/icons/paymenttype-auto.ico";
        title = "Automatic";
      }
      if (this.client.type_payment == "PA" && this.client.rbp != "ACTIVE") {
        image = "/images/icons/paymenttype-auto-no.ico";
        title = "Automatic";
      }
      if (this.client.type_payment == "MA") {
        image = "/images/icons/paymenttype-manual.ico";
        title = "Manual";
      }
      if (this.client.type_payment == "OT") {
        image = "/images/icons/paymenttype-others.ico";
        title = "Others";
      }
      return { image, title };
    },
  },
  methods: {
    convertJsonToArr(json) {
      try {
        return JSON.parse(json);
      } catch (e) {
        return [];
      }
    },
    openModalHistoryPayment() {
      this.modalHistoryPayment = true;
    },
  },
};
</script>
