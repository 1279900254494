export const LetterDirectCompletedSubTabs = (tab, abrModule) => {
  return [
      {
          path: "in-correspondence",
          component: () =>
              import(
                  /* webpackChunkName: "inCorrespondenceDisputeLettersTable" */
                  "@/views/commons/components/letters-v2/LetterDisputeCompleted.vue"
              ),
          name: `${abrModule}-letters-dispute-completed-in-correspondence`,
          meta: {
              tabTitle: "In-correspondence",
              subTabName: "in-correspondence",
              pageTitle: "Letter Dispute",
              breadcrumb: [
                  {
                      text: "In Correspondence",
                      active: true,
                  },
              ],
              paramTab:tab
          },
      },
      {
          path: "sent",
          component: () =>
              import(
                  /* webpackChunkName: "sentDisputeLettersTable" */
                  "@/views/commons/components/letters-v2/LetterDisputeCompleted.vue"
              ),
          name: `${abrModule}-letters-dispute-completed-sent`,
          meta: {
              tabTitle: "Sent",
              subTabName: "sent",
              pageTitle: "Letter Dispute",
              breadcrumb: [
                  {
                      text: "Sent",
                      active: true,
                  },
              ],
              paramTab:tab
          },
      },
  ]
};


export const LetterDirectToSendSubTabs = (tab, abrModule) => {
  return [
      {
          path: "pending",
          component: () =>
              import(
                  /* webpackChunkName: "pendingDisputeLettersTable" */
                  "@/views/commons/components/letters-v2/LetterDisputeToSend.vue"
              ),
          name: `${abrModule}-letters-dispute-to-send-pending`,
          meta: {
              tabTitle: "Pending",
              subTabName: "pending",
              pageTitle: "Letter Dispute",
              breadcrumb: [
                  {
                      text: "Pending",
                      active: true,
                  },
              ],
              paramTab:tab
          },
      },
      {
          path: "in-queue",
          component: () =>
              import(
                  /* webpackChunkName: "inQueueDisputeLettersTable" */
                  "@/views/commons/components/letters-v2/LetterDisputeToSend.vue"
              ),
          name: `${abrModule}-letters-dispute-to-send-in-queue`,
          meta: {
              tabTitle: "In-queue",
              subTabName: "in-queue",
              pageTitle: "Letter Dispute",
              breadcrumb: [
                  {
                      text: "In Queue",
                      active: true,
                  },
              ],
              paramTab:tab
          },
      },
      {
        path: "returned",
        component: () =>
            import(
                /* webpackChunkName: "ReturnedDisputeLettersTable" */
                "@/views/commons/components/letters-v2/LetterDisputeToSend.vue"
            ),
        name: `${abrModule}-letters-dispute-to-send-returned`,
        meta: {
            tabTitle: "Returned",
            subTabName: "returned",
            pageTitle: "Letter Dispute",
            breadcrumb: [
                {
                    text: "Returned",
                    active: true,
                },
            ],
            paramTab:tab
        },
    },
  ]
};
