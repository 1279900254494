<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-footer
      hide-header-close
      hide-header
      :body-class="`rounded`"
      no-close-on-backdrop
      size="summary"
      @hidden="isActive = false"
      centered
    >
      <div v-if="dataClaim">
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
        >
          <div class="d-flex align-items-center" style="gap: 7px">
            <span class="title-alert font-strong"> CLAIM </span>
            <b-badge :variant="priorityColor">
              {{ dataClaim.priority }}
            </b-badge>
          </div>
          <div
            class="close-modal"
            @click="closeModal()"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
          >
            <feather-icon icon="XIcon" size="16" />
          </div>
        </div>
        <div class="d-flex align-items-center mt-1">
          <div class="icon-badge mr-1" :class="badgeColor">
            <feather-icon :icon="iconTitle" size="20"></feather-icon>
          </div>
          <span>
            <span :class="colorText" class="text-uppercase"> {{ title }} </span>
            <b-badge v-if="dataClaim.has_refund === 1" variant="warning">
              REFUND
            </b-badge>
          </span>
        </div>
        <hr />
        <div class="d-flex flex-column" style="gap: 15px">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CLIENT
            </span>
            <span class="font-strong">{{ dataClaim.client }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="MapIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              DEPARTMENT
            </span>
            <span class="font-strong">{{ dataClaim.program_name }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="ListIcon"
                class="mr-1"
                size="14"
                svgClasses="font-small-4"
              />
              REASON
            </span>
            <span class="font-strong"
              >{{ reasonsLabel }}
              <template v-if="reasonsLabel.length > 30">
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="text-primary"
                  id="tooltip-reasons"
                />
                <b-tooltip
                  :target="'tooltip-reasons'"
                  triggers="hover"
                  placement="top"
                >
                  <template v-for="(item, index) in dataClaim.reasons">
                    <b-badge
                      :key="index"
                      variant="light-primary"
                      class="d-block mb-1"
                    >
                      {{ item.reason }}
                    </b-badge>
                  </template>
                </b-tooltip>
              </template>
            </span>
          </div>
        </div>
        <hr />
        <b-row>
          <b-col v-if="moduleId != 16">
            <b-button
              class="w-100 block"
              variant="primary"
              target="_blank"
              @click="goToDashboard"
              >OPEN DASHBOARD</b-button
            ></b-col
          >
          <b-col>
            <b-button
              class="w-100 block"
              variant="success"
              @click.prevent="openModalDetail"
              >OPEN DETAIL</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex"

export default {
  name: "AlertClaim",
  data() {
    return {
      detailClaim: null,
    }
  },
  computed: {
    ...mapGetters({
      G_IsActive: "AutoAlertClaim/G_IsActive",
      G_DataClaim: "AutoAlertClaim/G_DataClaim",
      title: "AutoAlertClaim/G_Title",
      currentUser: "auth/currentUser",
    }),

    isActive: {
      get() {
        return this.G_IsActive
      },
      set(value) {
        this.$store.commit("AutoAlertClaim/M_OpenAlert", value)
        setTimeout(() => {
          this.dataClaim = null
        }, 500)
      },
    },

    dataClaim: {
      get() {
        return this.G_DataClaim
      },
      set(value) {
        this.$store.commit("AutoAlertClaim/M_DataClaim", value)
      },
    },

    reasonsLabel() {
      let reasons = this.dataClaim?.reasons
        .map((item) => item.reason)
        .join(", ")
      return reasons.length > 30 ? reasons.substring(0, 30) + "..." : reasons
    },

    badgeColor() {
      const status = this.dataClaim?.status
      let classes = {
        COMPLETED: "completed",
        SCALED: "scaled",
        REACTIVATED: "reactivated",
        PENDING: "pending",
      }
      return classes[status] || "pending"
    },

    iconTitle() {
      const status = this.dataClaim?.status
      let icons = {
        COMPLETED: "CheckIcon",
        SCALED: "TrendingUpIcon",
        REACTIVATED: "RotateCcwIcon",
        ANNULLED: "XOctagonIcon",
        PENDING: "AlertTriangleIcon",
      }

      return icons[status] || "AlertTriangleIcon"
    },

    colorText() {
      const status = this.dataClaim?.status
      let color = {
        COMPLETED: "text-success font-strong",
        REACTIVATED: "text-warning font-strong",
        PENDING: "text-warning font-strong",
        SCALED: "text-warning font-strong",
      }
      return color[status]
    },

    priorityColor() {
      const priority = this.dataClaim?.priority
      let color = {
        HIGH: "light-danger",
        HALF: "light-warning",
        LOW: "light-primary",
      }
      return color[priority]
    },

    moduleId() {
      return this.$route.matched[0].meta.module
    },

    dashboardRouteName() {
      const routers = {
        3: "bussiness-dashboard-clients",
        4: "administration-dashboard",
        5: "debt-solution-dashboard",
        6: "credit-experts-dashboard",
        7: "boost-credit-dashboard",
        8: "tax-research-client-dashboard",
        10: "claim-department-dashboard",
        11: "specialists-client-dashboard",
        14: "bookeeping-dashboard",
        18: "quality-client-dashboard",
        22: "ce-customer-service-dashboard",
      }
      return routers[this.moduleId] || null
    },
  },
  methods: {
    closeModal() {
      const channel = socket.channel("claims-notifications", {})
      channel.trigger("client-close-all-claim-notification", {
        message: "close all",
        user_id: this.currentUser.user_id,
      })
      this.isActive = false
    },
    closeModalDetail() {
      this.detailClaim = null
      this.isActive = false
    },
    openModalDetail() {
      const { detail } = this.G_DataClaim
      this.$store.commit("AutoAlertClaim/M_DetailClaim", detail)
      this.closeModal()
    },
    goToDashboard() {
      this.$router.push({
        name: this.dashboardRouteName,
        params: {
          idClient: this.dataClaim.client_account_id,
        },
      })

      this.closeModal()
    },
  },
}
</script>
<style lang="scss" scoped>
.icon-badge {
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &.completed {
    background-color: rgba(40, 199, 111, 0.1);
    color: #468844;
  }
  &.pending {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.reactivated {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.scaled {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
}

.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}
</style>