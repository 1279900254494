import { amgApi } from "@/service/axios";
class LettersService {
    async getLetterPending(params) {
        try {
            const data = await amgApi.post("correspondence/get-letter-pending", params);
            return data.data;
        } catch (error) {
            console.log("Error getLetterPending");
        }
    }
    async getCorrespondenceLettersPdf(params) {
        try {
            const data = await amgApi.post("correspondence/get-correspondence-letters-pdf", params, {
                responseType: 'blob'
            });
            return data.data;
        } catch (error) {
            console.log("Error getCorrespondenceLettersPdf");
        }
    }
    async getLettersTrackings(params) {
        try {
            const data = await amgApi.post("correspondence/get-letters-trackings", params);
            return data.data;
        } catch (error) {
            console.log("Error getLettersTrackings");
        }
    }
    async sendLetters(params) {
        try {
            const data = await amgApi.post("correspondence/send-letters", params);
            return data.data;
        } catch (error) {
            console.log("Error sendLetters");
        }
    }
    async returnLetters(params) {
        try {
            const data = await amgApi.post("correspondence/return-letters", params);
            return data.data;
        } catch (error) {
            console.log("Error returnLetters");
        }
    }
    async refreshStateFile(params) {
        try {
            const data = await amgApi.post("correspondence/refresh-state-file", params);
            return data.data;
        } catch (error) {
            console.log("Error refreshStateFile");
        }
    }

    async refreshStateFileMerge(params) {
        try {
            const data = await amgApi.post("correspondence/refresh-state-file-merge", params);
            return data.data;
        } catch (error) {
            console.log("Error refreshStateFileMerge");
        }
    }

    async getLetterSent(params) {
        try {
            const data = await amgApi.post("correspondence/get-letter-sent", params);
            return data.data;
        } catch (error) {
            console.log("Error getLetterSent");
        }
    }
    async counterLetter(params) {
        try {
            const data = await amgApi.post("correspondence/counter-letter", params);
            return data.data;
        } catch (error) {
            console.log("Error counterLetter");
        }
    }
    async setTrackingNumber(params) {
        try {
            const data = await amgApi.post("correspondence/set-track-number", params);
            return data.data;
        } catch (error) {
            console.error("Error setTrackingNumber");
        }
    }
}
export default new LettersService();