<template>
  <div
    style="
      white-space: break-spaces !important;
      overflow-wrap: anywhere !important;
      text-align: justify !important;
    "
  >
    <div
      :class="TextAlign ? 'text-' + TextAlign : ''"
      v-html="
        SmsData.length >= (TextLength ? TextLength : 75)
          ? changeSms
            ? `${SmsData.replace(/\n/g, '<br \/>').substr(
                0,
                TextLength ? TextLength : 75
              )}...`
            : SmsData.replace(/\n/g, '<br \/>')
          : SmsData.replace(/\n/g, '<br \/>')
      "
    />
    <b-button
      v-if="
        SmsData.length > (TextLength ? TextLength : 75) &&
        TypeShowMore == 'button'
      "
      :variant="Variant ? Variant : 'flat-info'"
      size="sm"
      class="text-nowrap"
      @click="returnDataSms()"
      >{{ formatedBody }}</b-button
    >

    <small
      v-if="
        SmsData.length > (TextLength ? TextLength : 75) &&
        TypeShowMore == 'span'
      "
      :class="Variant ? 'text-' + Variant : 'text-info'"
      class="text-nowrap cursor-pointer"
      @click="returnDataSms()"
      >{{ formatedBody + "..." }}</small
    >
  </div>
</template>

<script>
export default {
  props: {
    SmsData: {
      type: String,
    },
    TextLength: {
      type: Number,
      required: false,
    },
    Variant: {
      type: String,
      required: false,
    },
    TypeShowMore: {
      type: String,
      default: "button",
    },
    TextAlign: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      changeSms: true,
    };
  },
  computed: {
    formatedBody() {
      return this.changeSms == true ? "Show more" : "Show less";
    },
  },
  methods: {
    returnDataSms() {
      this.changeSms = !this.changeSms;
      this.$emit("isExpanded", this.changeSms == true ? 0 : 1);
    },
  },
};
</script>