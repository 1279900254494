import { amgApi } from '@/service/axios'

class bankAccountsService {

  //REGISTER NEW BANK ACCOUNT

  async createBankAccounts(params) {
    try {
      const data = await amgApi.post('/debt-solution/checks-bank-account/set-bank-account', params)
      return data
    } catch (error) {
      console.log('Something went wrong on getUserModule:', error)
      throw error
    }
  }

  async createCheckbook(params) {
    try {
      const data = await amgApi.post('/debt-solution/checks-bank-account/set-checkbook', params)
      return data
    } catch (error) {
      console.log('Something went wrong on getUserModule:', error)
      throw error
    }
  }

  async updateBankAccount(params) {
    try {
      const data = await amgApi.post('/debt-solution/checks-bank-account/update-bank-account', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async trackingBalanceBankAccount(params) {
    try {
      const data = await amgApi.post('/debt-solution/checks-bank-account/tracking-balance-bank-account', params)
      return data
    } catch (error) {
      console.log('Something went wrong on getUserModule:', error)
      throw error
    }
  }

  async updateBalanceBankAccount(params) {
    try {
      const data = await amgApi.post('/debt-solution/checks-bank-account/update-balance-bank-account', params)
      return data
    } catch (error) {
      console.log('Something went wrong on getUserModule:', error)
      throw error
    }
  }

  //GET LIST OF PAGED BANK ACCOUNTS

  async getBankAccountsPaginated(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/get-bank-accounts", params)
      return data
    } catch (error) {
      console.log('Something went wrong on getBankAccounts:', error)
      throw error
    }
  }
  //CHANGE STATE OF BANK ACCOUNTS (ACTIVE/INACTIVE)
  async changeStatusBankAccount(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/change-status-bank-account", params)
      return data
    } catch (error) {
      console.log('Something went wrong on changeStatusBankAccount:', error)
      throw error
    }
  }

  async getCheckbooksByBankAccount(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/get-checkbooks-by-bank-account", params)
      console.log(data)
      return data
    } catch (error) {
      console.log('Something went wrong on getCheckbooksByBankAccount:', error)
      throw error
    }
  }


  //GET LIST OF PAGED BANK ACCOUNTS
  async getBankAccountsPaginated(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/get-bank-accounts", params)
      return data
    } catch (error) {
      console.log('Something went wrong on getBankAccounts:', error)
      throw error
    }
  }
  //CHANGE STATE OF BANK ACCOUNTS (ACTIVE/INACTIVE)
  async changeStatusBankAccount(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/change-status-bank-account", params)
      return data
    } catch (error) {
      console.log('Something went wrong on changeStatusBankAccount:', error)
      throw error
    }
  }

  async getCheckbooksByBankAccount(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/get-checkbooks-by-bank-account", params)
      return data
    } catch (error) {
      console.log('Something went wrong on getCheckbooksByBankAccount:', error)
      throw error
    }
  }

  async getTrackingsBankAccount(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/get-trackings-bank-account", params)
      return data
    } catch (error) {
      console.log('Something went wrong on getTrackingsBankAccount:', error)
      throw error
    }
  }

  async changeStatusCheckbook(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/change-status-checkbook", params)
      return data
    } catch (error) {
      console.log('Something went wrong on changeStatusCheckbook:', error)
      throw error
    }
  }

  async deletionLogicalBankAccount(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/deletion-logical-bank-account", params)
      return data
    } catch (error) {
      console.log('Something went wrong on deletionLogicalBankAccount:', error)
      throw error
    }
  }

  async getFrezeBalance(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/get-freze-balance", params)
      return data
    } catch (error) {
      console.log('Something went wrong on getFrezeBalance:', error)
      throw error
    }
  }
  // Validation payments pending bank account
  async getPaymentsPendingBankAccount(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/get-payments-pending-bank-account", params)
      return data
    } catch (error) {
      console.log('Something went wrong on getPaymentsPendingBankAccount:', error)
      throw error
    }
  }
  // Validation payments pending bank account
  async getIsuficiencyFunds() {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/get-isuficiency-funds")
      return data
    } catch (error) {
      console.log('Something went wrong on getIsuficiencyFunds:', error)
      throw error
    }
  }

  // GET CHECKS BY CHECKBOOK_ID
  async getChecksByCheckbookId(params) {
    try {
      const data = await amgApi.post('/debt-solution/checks-bank-account/get-checks-by-checkbook-id', params)
      return data
    } catch (error) {
      console.log('Something went wrong on getChecks:', error)
      throw error
    }
  }

  async getCheckbooksWithFewChecks() {
    try {
      const data = await amgApi.post('/debt-solution/checks-bank-account/get-checkbooks-with-few-checks')
      return data
    } catch (error) {
      console.log('Something went wrong on getCheckbooksWithFewChecks')
      throw error
    }
  }

  async updateCheckbook(params) {
    try {
      const data = await amgApi.post('/debt-solution/checks-bank-account/update-checkbook', params)
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getCheckbookById(params) {
    try {
      const data = await amgApi.post('/debt-solution/checks-bank-account/get-checkbook-by-id', params)
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async deletionLogicalCheckbook(params) {
    try {
      const data = await amgApi.post('/debt-solution/checks-bank-account/deletion-logical-checkbook', params)
      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  async getPurchaseOrders(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/get-purchase_orders");
      return data;
    } catch (error) {
      console.log(error)
    }
  }

  async payPurchaseOrders(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/pay-purchase-orders", params);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getBankAccountAmgActive(params) {
    try {
      const data = await amgApi.post("/debt-solution/checks-bank-account/get-bank-account-amg-active", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new bankAccountsService()
