import router from "@/router"
import store from "@/store"
import Vue from "vue"
import { amgApi } from "@/service/axios"

const notificationManChargeBack = () => {
  window.socket.bind("notification-man-charge-back", async (data) => {
    // console.log(data)
    let temps = false
    let session_id = store.state.auth.currentUser.user_id
    data.to_id.forEach((element) => {
      if (element === session_id) temps = true
    })
    if (temps) {
      if (data.send_to === 0) {
        //eventBus.$emit("count-charge-back", null);
        const htmlSwal =
          '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;" alt="alert-info-image">' +
          '<div><h2 style="font-weight: 600;">NEW CHARGE BACK</h2></div>' +
          '<div style="text-align: left;margin-left: 105px;">' +
          '<div style="font-weight: bolder;">CLIENT: ' +
          data.lead_name +
          " </div>" +
          '<div style="font-weight: bolder;">ACCOUNT: ' +
          data.account +
          " </div>" +
          "</div>"
        const res = await Vue.swal.fire({
          html: htmlSwal,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          showConfirmButton: true,
        })
        if (res) {
          data.to_id = session_id
          let response = await amgApi.post("commons/close-all-swal", data)
          // eventBus.$emit("refresh-search-charge-back"); falta
          window.open(data.redirect)
        }
      } else if (data.send_to === 1) {
        const htmlSwal =
          '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
          '<div><h2 style="font-weight: 600;">CONFIRM CHARGEBACK</h2></div>' +
          '<div style="text-align: left;margin-left: 105px;">' +
          '<div style="font-weight: bolder;">CLIENT: ' +
          data.lead_name +
          " </div>" +
          '<div style="font-weight: bolder;">ACCOUNT: ' +
          data.account +
          " </div>" +
          "</div>"
        const res = await Vue.swal.fire({
          html: htmlSwal,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          showConfirmButton: true,
        })
        if (res) {
          data.to_id = session_id
          const response = amgApi.post("commons/close-all-swal", data)
          // eventBus.$emit("refresh-search-charge-back");
          window.open(data.redirect)
        }
      } else if (data.send_to === 2) {
        if (data.module_id === 16 && data.result === 3) {
          const htmlSwal =
            '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
            '<div><h2 style="font-weight: 600;">CHARGEBACK REJECTED</h2></div>' +
            '<div style="text-align: left;margin-left: 105px;">' +
            '<div style="font-weight: bolder;">CLIENT: ' +
            data.lead_name +
            " </div>" +
            '<div style="font-weight: bolder;">ACCOUNT: ' +
            data.account +
            " </div>" +
            "</div>"
          const res = await Vue.swal.fire({
            html: htmlSwal,
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            showConfirmButton: true,
          })
          if (res) {
            data.to_id = session_id
            const response = amgApi.post("commons/close-all-swal", data)
            // eventBus.$emit("refresh-search-charge-back");
            window.open(data.redirect)
          }
        } else if (data.module_id === 16 && data.result === 2) {
          const htmlSwal =
            '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
            '<div><h2 style="font-weight: 600;">CHARGEBACK APPROVED</h2></div>' +
            '<div style="text-align: left;margin-left: 105px;">' +
            '<div style="font-weight: bolder;">CLIENT: ' +
            data.lead_name +
            " </div>" +
            '<div style="font-weight: bolder;">ACCOUNT: ' +
            data.account +
            " </div>" +
            "</div>"
          const res = await Vue.swal.fire({
            html: htmlSwal,
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            showConfirmButton: true,
          })
          if (res) {
            data.to_id = session_id
            const response = amgApi.post("commons/close-all-swal", data)
            // eventBus.$emit("refresh-search-charge-back");
            // eventBus.$emit("refresh-search-charge-back-approved");
            if (data.to_id === 17) {
              window.open("/administration" + data.redirect)
            } else {
              window.open("/" + data.route + data.redirect)
            }
          }
        } else if (
          data.module_id !== 16 &&
          data.module_id !== 4 &&
          data.result === 1
        ) {
          const htmlSwal =
            '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
            '<div><h2 style="font-weight: 600;">PROGRAM EVIDENCES</h2></div>' +
            '<div style="text-align: left;margin-left: 105px;">' +
            '<div style="font-weight: bolder;">CLIENT: ' +
            data.lead_name +
            " </div>" +
            '<div style="font-weight: bolder;">ACCOUNT: ' +
            data.account +
            " </div>" +
            "</div>"
          const res = await Vue.swal.fire({
            html: htmlSwal,
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            showConfirmButton: true,
          })
          if (res) {
            data.to_id = session_id
            const response = amgApi.post("commons/close-all-swal", data)
            // eventBus.$emit("refresh-search-charge-back");
            window.open("/administration" + data.redirect)
          }
        }
      } else if (data.send_to === 3) {
        const htmlSwal =
          '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
          '<div><h2 style="font-weight: 600;">CHARGEBACK</h2></div>' +
          '<div style="text-align: left;margin-left: 105px;">' +
          '<div style="font-weight: bolder;">CLIENT: ' +
          data.lead_name +
          " </div>" +
          '<div style="font-weight: bolder;">ACCOUNT: ' +
          data.account +
          " </div>" +
          "</div>"
        const res = await Vue.swal.fire({
          html: htmlSwal,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          showConfirmButton: true,
        })
        if (res) {
          data.to_id = session_id
          const response = amgApi.post("/commons/close-all-swal", data)
          // eventBus.$emit("refresh-search-charge-back");
          window.open(data.redirect)
        }
      }
    }
  })

  window.socket.bind(
    "notifications-departament",
    async ({ module_id, send_to, text, title }) => {
      let temps = false
      let session_id = store.state.auth.currentUser.user_id
      send_to.forEach((element) => {
        if (element === session_id) temps = true
      })
      if (temps && module_id === 16) {
        Vue.swal.fire({
          icon: "success",
          title: title,
          text: text,
        })
      }
    }
  )
}
export default notificationManChargeBack
