import {
	applicationTabs
} from "@/views/commons/components/applications/router/application.tabs";
export default [{
	path: "applications",
	name: "customerservice-applications",
	redirect: {
		name: "cs-application-pending"
	},
	component: () =>
		import ( /* webpackChunkName: "FinancialApprovalApplications" */ "@/views/commons/components/applications/Applications.vue"),
	meta: {
		is_digital: 2,
		router: "cs",
		pageTitle: "Credit Card",
		breadcrumb: [{
			text: "CREDIT CARD",
			active: true,
		}, ],
    program: 3,
	module: 22,
	routePending: "cs-application-pending",
	routeCompleted: "cs-application-completed",
	routeExpired: "cs-application-expired",
	routeSuccessfull: "cs-application-successfull",
	routeUnsuccessful: "cs-application-unsuccessful",
	},
	children: applicationTabs("cs"),
}, ];