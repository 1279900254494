<template>
  <div class="coverlay">
    <b-card class="p-2 text-center">
      <h2>Inactive time:</h2>
      <span
        class="d-inline-block h1 mb-4"
        :class="[skin == 'dark' ? 'text-white' : 'text-bladk']"
        >{{ formatedElapsedTime }}</span
      >
      <form @submit.prevent="unlock">
        <b-form-group class="mb-2">
          <label for="basic-password" style="float: left">Password</label>
          <b-input-group>
            <b-form-input
              id="basic-password"
              :type="passwordFieldType"
              placeholder="Your Password"
              v-model="password"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon"
                class="cursor-pointer"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-button
          class="d-block mx-auto"
          :variant="skin == 'dark' ? 'outline-light' : 'outline-dark'"
          @click="unlock"
          >Unlock</b-button
        >
      </form>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import useAgentAway from "@/views/ce-digital/sub-modules/customer-service/views/agent-away/components/useAgentAway.js";
import agentAwayService from "../agent-away.service";

export default {
  data() {
    return {
      deadTime: 0,
      start: null,
      passwordFieldType: "password",
      password: "",
    };
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
    formatedElapsedTime() {
      let seconds = this.deadTime % 60;
      if (seconds < 10) seconds = "0" + seconds;
      let minutes = Math.floor(this.deadTime / 60);
      if (minutes < 10) minutes = "0" + minutes;

      return `${minutes}:${seconds}`;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    ...mapActions({
      A_CHANGE_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
    }),
    ...mapMutations({
      SET_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/SET_USER_STATUS_SESSION_SUB_MODULE",
    }),
    togglePasswordVisibility() {
      if (this.passwordFieldType == "password") {
        this.passwordFieldType = "text";
      } else {
        this.passwordFieldType = "password";
      }
    },
    async unlock() {
      let body = {
        agent_id: this.currentUser.user_id,
        password: this.password,
      };
      try {
        await agentAwayService.checkPassword(body);
        this.toogleBusyState();
        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Pause registered"
        );
        this.SET_USER_STATUS_SESSION_SUB_MODULE([{ status_session: 1 }]);
        const params = {
          created_by: this.currentUser.user_id,
          sub_module_id: this.moduleId,
          status_sub_module_id: 1,
        };
        this.A_CHANGE_USER_STATUS_SESSION_SUB_MODULE(params);
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Incorrect Password"
        );
      }
    },
  },
  mounted() {
    setInterval(() => {
      this.deadTime++;
    }, 1000);
  },
  created() {
    // storage settled in composable `useAgentAway`
    let storedStart = localStorage.getItem("cs_agentAwayStorage");
    if (storedStart != null) {
      storedStart = JSON.parse(storedStart);
      let before = new Date(storedStart.pauseStarted);
      let now = new Date();
      let secDiff = Math.floor((now.getTime() - before.getTime()) / 1000);
      this.deadTime = secDiff;
      this.start = before;
    } else {
      this.start = new Date();
    }
  },
  setup() {
    const { toogleBusyState } = useAgentAway();

    return {
      toogleBusyState,
    };
  },
};
</script>

<style scoped>
.coverlay {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
}

.coverlay--small {
  top: auto;
  left: auto;
  right: 2rem;
  bottom: 2rem;
  width: 20vw;
  height: 20vh;
  background: none;
  border: 1px solid red;
}
</style>