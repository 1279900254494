import { otherServicesTab } from "@/views/commons/components/other-services/router/appointment.tab.js";

export default [
  {
    path: "other-services",
    name: "other-services-financial-approval",
    redirect: { name: "sp-other-services-pending" },
    component: () => import(
      "@/views/commons/components/other-services/view/OtherServicesMain.vue"
    ),
    meta: {
      router: "sp-other-services",
      pageTitle: "Other Services",
      breadcrumb: [
        {
          text: "Other Services",
          active: true,
        },
      ],
      module: 29,
      moduleId: 29,
      permittedRoles: [1, 2, 17, 25],
      routePending: "sp-other-services-pending",
      routeCompleted: "sp-other-services-completed",
      routeExpired: "sp-other-services-expired",
      routeSuccessfull: "sp-other-services-successfull",
      routeUnsuccessful: "sp-other-services-unsuccessful",
    },
    children: otherServicesTab("sp-other-services","Other Services",[1, 2, 17, 25]),
  },
];
