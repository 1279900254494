import DashboardNav from "@/views/ce-digital/sub-modules/connection/views/dashboard/dashboard.nav";
import CommissionsNav from "@/views/ce-digital/sub-modules/connection/views/commissions/commissions.nav";
import LeadsNav from "@/views/ce-digital/sub-modules/connection/views/leads/leads.nav";
import SalesMadeNav from "@/views/ce-digital/sub-modules/connection/views/sales-made/sales-made.nav";
import HelpDeskNav from "@/views/ce-digital/sub-modules/connection/views/helpdesk/helpdesk.nav";
import ClientsNav from "@/views/ce-digital/sub-modules/connection/views/clients/clients-group.navigation";
import SucccessNav from "@/views/ce-digital/sub-modules/connection/views/success/navigation/success.nav";
import ApplicationsNav from "@/views/ce-digital/sub-modules/connection/views/applications/navigation";
import DisputeNav from "@/views/ce-digital/sub-modules/connection/views/dispute/navigation/dispute.nav";
import DisputeResultsNav from "@/views/ce-digital/sub-modules/connection/views/dispute-results/navigation";
import OtherPaymentsNavigation from "@/views/ce-digital/sub-modules/connection/views/other-payments/other-payments.nav";
import EmployeeClaimsNavigation from "@/views/ce-digital/sub-modules/connection/views/employee-claims/navigation/employee-claims.nav";
import RequestNav from "@/views/ce-digital/sub-modules/connection/views/request/request.nav.js";
//import ConnectionProjectsNavigation from "@/views/ce-digital/sub-modules/connection/views/projects/connection-projects.navigation";
import RingCentralNavigation from "@/views/ce-digital/sub-modules/connection/views/ring-central/ring-central.nav.js";
import MOFNavigation from "@/views/ce-digital/sub-modules/connection/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/ce-digital/sub-modules/connection/views/recruitment-process/connection-recruitment_process.navigation";
import RequestNcrNav from "@/views/ce-digital/sub-modules/connection/views/request-ncr/request-ncr.nav";
import RequestWorkPlanNav from "@/views/ce-digital/sub-modules/connection/views/request-workplan/request-workplan.nav.js";
import ClaimsNav from "@/views/ce-digital/sub-modules/connection/views/claims/claims.nav.js";
import CourtInfoNav from "@/views/ce-digital/sub-modules/connection/views/cour-info/navigation/ds-court-info.nav";
import AppointmentNav from "@/views/ce-digital/sub-modules/connection/views/appointment/appointment.navigation";
import PotencialAppointmentNav from "@/views/ce-digital/sub-modules/connection/views/potencial-appointment/potencial.nav";
import OtherServicesNav from "@/views/ce-digital/sub-modules/connection/views/other-services/other-services.navigation";
import CommissionForSpecialistsNavigation from "@/views/ce-digital/sub-modules/connection/views/commission-specialists/commissions-specialists.navigation";

// data
import sidebarItems from "@/views/credit-experts/data/sidebarItems";
import { sumTag } from "@/@core/utils/utils";

const navigation = [
  {
    header: "CONNECTION",
    module: 20,
    modules: sidebarItems,
  },
  DashboardNav,
  LeadsNav,
  ClientsNav,
  SalesMadeNav,
  ClaimsNav,
  RequestNcrNav,
  RequestWorkPlanNav,
  DisputeNav,
  ...DisputeResultsNav,
  // CommissionsNav,
  {
    title: "Services",
    icon: "CreditCardIcon",
    tag: sumTag([
      ...AppointmentNav,
      ApplicationsNav,
      ...OtherServicesNav,
      CommissionForSpecialistsNavigation,
      // ...PotencialAppointmentNav
    ]),
    children: [
      ...AppointmentNav,
      ApplicationsNav,
      ...OtherServicesNav,
      CommissionForSpecialistsNavigation,
      // ...PotencialAppointmentNav
    ],
  },
  SucccessNav,
  HelpDeskNav,
  OtherPaymentsNavigation,
  EmployeeClaimsNavigation,
  RequestNav,
  ...CourtInfoNav,
  RingCentralNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
];

export default navigation;
