import { applicationTabs } from "@/views/commons/components/applications/router/application.tabs";

export default [
    {
        path: "applications",
        name: "analyst-department-applications",
        redirect: { name: "ad-application-pending" },
        component: () => import(/* webpackChunkName: "FinancialApprovalApplications" */ "@/views/commons/components/applications/Applications.vue"),
        meta: {
            router: "ad",
            pageTitle: "Credit Cards",
            breadcrumb: [
                {
                    text: "CREDIT CARDS",
                    active: true,
                },
            ],
            routePending: "ad-application-pending",
            routeCompleted: "ad-application-completed",
            routeExpired: "ad-application-expired",
            routeSuccessfull: "ad-application-successfull",
            routeUnsuccessful: "ad-application-unsuccessful",
            program: 7,
            module: 29
        },
        children: applicationTabs("ad"),
    },
];
