import CreativeMain from "@/views/creative/views/CreativeMain.vue";

import BankOfFlyersRouter from "@/views/creative/views/bank-of-flyers/bank-of-flyers.router";
import metaRouter from "@/views/creative/views/meta/router/meta.router.js";
import CreativeRouter from "@/views/creative/views/reports/reports.router.js";
import helpdeskRouter from "@/views/creative/views/helpdesk/helpdesk.route.js";
import productRequestRouter from "@/views/creative/views/request/request.router.js";
import fileManagerRouter from "@/views/creative/views/file-manager/file-manager.router.js";
import attendanceRouter from "@/views/creative/views/attendance/creative-attendance.router.js";
import employeeClaimsRouter from "@/views/creative/views/employee-claims/router/employee-claims.router";
//import CreativeProjectsRouter from '@/views/creative/views/projects/creative-projects.router';
import CallLogRouter from "@/views/creative/views/call-log/call-log.router.js";
import MOFRouter from "@/views/creative/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/creative/views/recruitment-process/creative-recruitment_process.router";
import creativeRequestRouter from "@/views/creative/views/creative-requests/router/creative.request.router";
import CustomerTicketsRouter from "@/views/creative/views/customer-tickets/creative-customer-tickets.router";
import metaInvestmentRouter from "@/views/creative/views/meta-investment/router/meta-investment.router.js";
import DashboardRouter from "@/views/creative/views/dashboard/router/dashboard.router.js";

const routes = [
  {
    path: "/creative",
    redirect: { name: "creative-dashboard" },
    meta: {
      module: 27,
    },
    component: CreativeMain,
    children: [
      ...BankOfFlyersRouter,
      //...metaRouter,
      ...metaInvestmentRouter,
      //...CreativeRouter,
      helpdeskRouter,
      ...productRequestRouter,
      fileManagerRouter,
      ...attendanceRouter,
      ...employeeClaimsRouter,
      //...CreativeProjectsRouter,
      ...CallLogRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      creativeRequestRouter,
      ...CustomerTicketsRouter,
      ...DashboardRouter
    ],
  },
];
export default routes;
