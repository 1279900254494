export default [
  {
    path: "leads",
    name: "quality-leads",
    redirect: { name: "quality-leads-list" },
    component: () => import(/* webpackChunkName: "QualityLeads" */ "@/views/quality/views/leads/Lead.vue"),
    meta: {
      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Leads",
        },
      ],
    },
    children: [
      {
        path: "",
        name: "quality-leads-list",
        component: () => import(/* webpackChunkName: "QualityLeadsList" */ "@/views/quality/views/leads/lead-module/list/LeadsList.vue"),
        meta: {
          route: "quality",
          isClientsTab: true,
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
              to: "/quality/leads",
              active: true,
            },
          ],
        },
      },
      {
        path: "sn",
        name: "quality-leads-sn-list",
        component: () => import(/* webpackChunkName: "QualityLeadsSnList" */ "@/views/quality/views/leads/lead-module/list/LeadsSnList.vue"),
        meta: {
          route: "quality",
          isClientsTab: true,
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads Sn",
              to: "/quality/leads/sn",
              active: true,
            },
          ],
        },
      },
      {
        path: "w-potential",
        name: "quality-leads-w-potential-list",
        component: () => import(/* webpackChunkName: "QualityLeadsWPotential" */ "@/views/quality/views/leads/lead-module/list/LeadsWPotentialList.vue"),
        meta: {
          route: "quality",
          isClientsTab: true,
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads W Potential",
              to: "/quality/leads/w-potential",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "leads/show/:id",
    name: "quality-lead-show",
    component: () => import(/* webpackChunkName: "QualityLeadsShow" */ "@/views/crm/views/Lead/lead-module/dashboard/default.vue"),
    props: true,
    meta: {
      isClientsTab: true,
      route: "quality",
      contentClass: "leads-app",
    },
  },
];
