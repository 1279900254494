import store from '@/store';
import Notifications from '@/service/notification/index';
import employeeClaims from '@/views/commons/commons-counter';
import RequestService from '@/views/commons/components/request/services/request.service.js';
import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';

const countNotification = async () => {
  const params = { user_id: store.state.auth.currentUser.user_id };
  const data = await window.amgApi.post('social-network/bank-of-flyers/count-notification', params);

  const payload = {
    routeName: 'bank-of-flyers',
    tag: data.data,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'social-network-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: 'request-socialnetwork',
    tag: data[0].counter,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'social-network-recruitment-process',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({ user_id, moduleId });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: 'social-network-customer-tickets',
      tag: data[0].v_total_open,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const allCountersSocialNetwork = async () => {
  await countNotification();
  await projectsCounter();
  await employeeClaims('sn-employee-claims');
  requestNotAsignedCounter(),
  await applicantsCounter();
  await getPendinCustomerTickets();
};

export default allCountersSocialNetwork;
