import { amgApi } from '@/service/axios';

class Helpdesk {
  async getOwners(params) {
    try {
      const { data } = await amgApi.post('/helpdesk/get-owners', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTicket(params) {
    try {
      const { data } = await amgApi.post('/helpdesk/get-ticket', params);
      return data.data;
    } catch (error) {
      throw error;
    }
  }

  async getTickets(params) {
    try {
      const response = await amgApi.post('/helpdesk/get-tickets', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getTicketsOnReview(params) {
    try {
      const response = await amgApi.post('/helpdesk/obs-get-tickets', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getPriorities() {
    try {
      const response = await amgApi.post('/helpdesk/get-priorities');
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getUsers(modul_id, params) {
    try {
      const response = await amgApi.post(
        `/commons/user-module/${modul_id}`,
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getModules() {
    try {
      const response = await amgApi.get('/module/get-modules-for-helpdesk');
      return response;
    } catch (error) {
      throw error;
    }
  }

  async registerTicket(formData) {
    try {
      const response = await amgApi.post('/helpdesk/register-ticket', formData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getSections(modul_id) {
    try {
      const response = await amgApi.get(
        `/sections-soft/get-sections/${modul_id}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async insertMessages(params) {
    try {
      const response = await amgApi.post(
        '/helpdesk/insert-default-messages',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getDefaultDescription(params) {
    try {
      const response = await amgApi.post(
        '/helpdesk/get-default-messages',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async inserCommentResponse(params) {
    try {
      const response = await amgApi.post(
        '/helpdesk/insert-comment-response',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getComments(params) {
    try {
      const response = await amgApi.post(
        '/helpdesk/get-comments-ticket',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async insertNewsFiles(formData) {
    try {
      const response = await amgApi.post(
        '/helpdesk/insert-news-files',
        formData,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getStatuses() {
    try {
      const response = await amgApi.get('/helpdesk/get-statuses');
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getNewEmployees(params) {
    try {
      const response = await amgApi.post('/helpdesk/get-new-employees', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getPendingMailNewEmployees(params) {
    try {
      const response = await amgApi.post('/helpdesk/get-pending-mail-employees', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateNewEmployees(params) {
    try {
      const response = await amgApi.post('/helpdesk/update-new-employees', params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getPassword(params) {
    try {
      const { data } = await amgApi.post('/helpdesk/get-password', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateStatusViewMessages(params) {
    try {
      const response = await amgApi.post(
        '/helpdesk/update-status-view-messages-client',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCounterNotifications(params) {
    try {
      const response = await amgApi.post(
        '/helpdesk/get-counter-notifications',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getPgUsers() {
    try {
      const response = await amgApi.get('/helpdesk/getTeamsActive');
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getCountTab(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/get-counter-notifications',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getDataKanban(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/get-kanban-tickets',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateStatusTicket(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/update-status-tickets',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  async updateErrorTicket(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/update-error-tickets',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateTeamDeveloperTicket(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/update-team-developer-tickets',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getDetailsTicket(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/search-details-ticket',
        params,
      );
      const { data } = await window.amgApi.post(
        '/helpdesk/get-timeline-error-status',
        {
          ticket_id: params.ticket_id
        },
      );

      const timelist = data.map(item => {
        const reformItm = {
          title: item.name,
          date: item.created_at,
        }
        return reformItm;
      })
      return {
        data: {
          ...response.data,
          timelist
        }
      };
    } catch (error) {
      throw error;
    }
  }

  async updateDueDate(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/update-due-date',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updatePriority(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/update-priority',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateOwner(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/update-owner',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async insertCommentFromParagon(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/insert-comment',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async uploadFilesCommentFromParagon(formData) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/upload-files-comments-paragon',
        formData,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateStatusHide(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/update-status-hide-ticket',
        params,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getTrackingOwner(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/get-tracking-owner',
        params,
      );
      return response;
    } catch (e) {
      throw error;
    }
  }

  async getCommentsIssue(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/get-comments-issue',
        params,
      );
      return response;
    } catch (e) {
      throw error;
    }
  }

  async getLastComment(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/get-last-comment',
        params,
      );
      return response;
    } catch (e) {
      throw error;
    }
  }

  async removeTicket(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/remove-ticket',
        params,
      );
      return response;
    } catch (e) {
      throw error;
    }
  }

  async updateSpecial(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/update-special',
        params,
      );
      return response;
    } catch (e) {
      throw error;
    }
  }

  async getTrackingTicket(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/get-tracking-ticket',
        params,
      );
      return response.data;
    } catch (e) {
      throw error;
    }
  }

  async completeTicket(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/complete-ticket',
        params,
      );
      return response.data;
    } catch (e) {
      throw error;
    }
  }

  async returnTicket(params) {
    try {
      const response = await window.amgApi.post(
        '/helpdesk/return-ticket',
        params,
      );
      return response.data;
    } catch (e) {
      throw error;
    }
  }

  async getModulesV2() {
    try {
      const response = await amgApi.get('/new-commons/modules/index');
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default new Helpdesk();
