import { amgApi } from '@/service/axios';

class EditUserService {
  async getUserEdit(param) {
    try {
      const data = await amgApi.post('/commons/edit-user', param);
      return data;
    } catch (error) {
      console.log('Something went wrong on getUserEdit ', error);
      throw error;
    }
  }

  async allModules() {
    try {
      const { data } = await amgApi.get('/module/get-modules');
      return data;
    } catch (error) {
      console.log('Something went wrong on allModules ', error);
      throw error;
    }
  }

  async loadRolesByModule(param) {
    try {
      const { data } = await amgApi.post('/module/modul-filter', param);
      return data;
    } catch (error) {
      console.log('Something went wrong on loadRolesByModule ', error);
      throw error;
    }
  }

  async getSubModulesByModule(param) {
    try {
      const { data } = await amgApi.post('/module/get-submodules-by-module', param);
      return data;
    } catch (error) {
      console.log('Something went wrong on getSubModulesByModule ', error);
      throw error;
    }
  }

  async getModuleNumberChildren(param) {
    try {
      const { data } = await amgApi.post('/module/get-module-number-children', param);
      return data;
    } catch (error) {
      console.log('Something went wrong on getModuleNumberChildren ', error);
      throw error;
    }
  }

  async getAllPrograms() {
    try {
      const { data } = await amgApi.get('/commons/programs');
      return data;
    } catch (error) {
      console.log('Something went wrong on loadNewRoles ', error);
      throw error;
    }
  }

  async userUpdate(dataForm) {
    try {
      const data = await amgApi.post('/commons/update-user', dataForm);
      return data;
    } catch (error) {
      console.log('Something went wrong on userUpdate ', error);
      throw error;
    }
  }

  async userCreate(dataForm) {
    try {
      const data = await amgApi.post('/commons/create-user', dataForm);
      return data;
    } catch (error) {
      console.log('Something went wrong on userCreate ', error);
      throw error;
    }
  }

  async changePasswordUser(body) {
    try {
      const data = await amgApi.post('/commons/change-password-user', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async validatePasswordUser(body) {
    try {
      const data = await amgApi.post(
        '/profile/validate-if-password-is-correct',
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPasswordUser(body) {
    try {
      const data = await amgApi.post(
        '/commons/get-password-user',
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllCeDigitalSubmodules(body) {
    try {
      const data = await amgApi.post(
        '/commons/credit-experts-digital/get-all-cedigital-submodules',
        body,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async validateSellerPassword(body) {
    try {
      const data = await amgApi.post('/profile/validate-seller-password', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async validateSupervisorPassword(body) {
    try {
      const data = await amgApi.post('/profile/validate-supervisor-password', body);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new EditUserService();
