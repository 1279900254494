

export default [
  {
    path: "file-app",
    name: "cdigital-file-app",
    component: () => import(/* webpackChunkName: "CEDCustomerServiceFileApp" */ "@/views/ce-digital/sub-modules/customer-service/views/file-app/views/FileApp.vue"),
    redirect: { name: "file-app-in-process-cdigital" },
    meta: {
      pageTitle: "App Files",
      breadcrumb: [
        {
          text: "App Files",
          active: true,
        },
      ],
      is_digital: 2,
      permittedRoles: [1, 2, 14, 16, 17],
    },
    children: [
      {
        path: "in-process",
        name: "file-app-in-process-cdigital",
        component: () => import(/* webpackChunkName: "CEDCustomerServiceFileAppInProcess" */ "@/views/ce-digital/sub-modules/customer-service/views/file-app/views/FileAppGrid.vue"),
        meta: {
          type: 0,
          pageTitle: "App Files",
          is_digital: 2,
          is_commission: 0,
          breadcrumb: [
            {
              text: "App Files",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 14, 16, 17],
        },
      },
      {
        path: "revision",
        name: "file-app-revision-cdigital",
        component: () => import(/* webpackChunkName: "CEDCustomerServiceFileAppRevision" */ "@/views/ce-digital/sub-modules/customer-service/views/file-app/views/FileAppGrid.vue"),
        meta: {
          type: 1,
          is_digital: 2,
          is_commission: 1,
          finish: 0,
          pageTitle: "App Files",
          breadcrumb: [
            {
              text: "App Files",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 14, 16, 17],
        },
      },
      {
        path: "completed",
        name: "file-app-completed-cdigital",
        component: () => import(/* webpackChunkName: "CEDCustomerServiceFileAppCompleted" */ "@/views/ce-digital/sub-modules/customer-service/views/file-app/views/FileAppGrid.vue"),
        meta: {
          type: 1,
          is_digital: 2,
          is_commission: 1,
          finish: 1,
          pageTitle: "App Files",
          breadcrumb: [
            {
              text: "App Files",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 14, 16, 17],
        },
      },
    ],
  },
];
