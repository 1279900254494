export default [
  {
    permission: "revission",
    icon: "BrandTelegramIcon",
    class: "text-success clickable",
    tooltip: (item) => "Send to revission",
    code: 2,
  },
  {
    permission: "revissionByChief",
    icon: "BrandTelegramIcon",
    class: "text-success clickable",
    tooltip: (item) => `Send to ${item.program_initials}`,
    code: 12,
  },
  {
    permission: "approveForOR",
    icon: "BrandTelegramIcon",
    class: "text-success clickable",
    tooltip: (item) => `Send to ${item.program_initials}`,
    code: 12,
  },
  {
    permission: "approveForDE",
    icon: "ThumbUpIcon",
    class: "text-success clickable",
    tooltip: (item) => "Approve",
    code: 4,
  },
  {
    permission: "revissionDanger",
    icon: "BrandTelegramIcon",
    class: "text-success clickable",
    tooltip: (item) => "Send to revission",
    code: 2,
  },
  // {
  //   permission: "revissionDangerByChief",
  //   icon: "BrandTelegramIcon",
  //   class: "text-success clickable",
  //   tooltip: (item) => `Send to ${item.program_initials}`,
  //   code: 12,
  // },
  {
    permission: "disapproveForOR",
    icon: "ThumbDownIcon",
    class: "text-danger clickable ml-1",
    tooltip: (item) => "Disapprove",
    code: 3,
  },
  {
    permission: "disapproveForDE",
    icon: "ThumbDownIcon",
    class: "text-danger clickable ml-1",
    tooltip: (item) => "Disapprove",
    code: 14,
  },
];
