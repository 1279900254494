export default [
    {
        path: "dashboard",
        name: "debt-solution-dashboard-main",
        component: () => import("@/views/debt-solution/views/dashboard/NewDsDashboard.vue"),
        meta: {
          permittedRoles: [1,2],
          module: 5,
          pageTitle: "Dashboard",
          breadcrumb: [
            {
              text: "Dashboard",
              active: true,
            },
          ],
        },
      },
  ]
  