export default [
    {
        id: 2,
        name: "Contact in Future"
    },
    {
        id: 5,
        name: "Not Contacted"
    },
    {
        id: 6,
        name: "Pending"
    },
    {
        id: 8,
        name: "Inactive"
    },
]