<template>
  <div>
    <b-modal
      v-model="modalUp"
      size="sm"
      hide-header
      hide-footer
      centered
      modal-class="modal-primary"
      @hidden="hideModal()"
    >
      <div class="mt-2 d-flex justify-content-center">
        <div class="text-center">
          <slot name="header"> </slot>
        </div>
      </div>
      <slot name="body"> </slot>
      <b-row class="my-2 d-flex justify-content-center">
        <slot name="footer">
          <div>
            <b-button
              variant="primary"
              class="ml-2"
              @click="modalUp = false"
              >Ok</b-button
            >
          </div>
        </slot>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      modalUp: false,
    };
  },
  async created() {
    this.modalUp = true;
  },
  methods: {
    hideModal() {
      this.modalUp = false;
      this.$emit("hide");
    },
  
  },
  // beforeDestroy() {
  //   try {
  //     window.socket.unbind("generate-offer-payment-order-notifications-management")
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
};
</script>

<style scoped>

</style>
