export default [
    {
      path: "calendar",
      name: "calendar-paragon",
      component: () => import(/* webpackChunkName: "PRCalendar" */ "@/views/paragon/views/calendar/calendar.vue"),
      meta: {
        pageTitle: "Calendar",
        breadcrumb: [
          {
            text: "Calendar",
          },
        ],
        permittedRoles: [1,17],
      },
    },
  ];
  