<template>
  <b-modal
    ref="modal-request-personnel"
    size="md"
    title="Request new personnel"
    :no-close-on-backdrop="true"
    @hide="closeModalRequestPersonnel"
  >
    <template #modal-title>
      <div class="d-flex justify-content-start align-items-center text-white">
        <tabler-icon
          icon="UserPlusIcon"
          size="20"
          class="mr-1"
        />
        <h4 class="m-0 p-0 text-white">
          Request new personnel
        </h4>
      </div>
    </template>
    <b-container class="p-1">
      <validation-observer ref="formRequestPersonnel">
        <b-row>
          <!-- start date -->
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="start_date"
              rules="required"
            >
              <b-form-group label="Start Date">
                <kendo-datepicker
                  id="start_date"
                  v-model="recruitmentProcess.date_start"
                  v-mask="'##/##/####'"
                  :min="minDate"
                  placeholder="MM/DD/YYYY"
                  :format="'MM/dd/yyyy'"
                  :state="
                    errors[0]
                      ? false
                      : null
                  "
                  :class="
                    errors[0]
                      ? 'border-danger'
                      : ''
                  "
                  class="w-100 rounded bg-transparent k-picker-custom picker-select-date"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- start date -->
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="quantity-employees"
              rules="required|max:2"
            >
              <b-form-group label="Number of applicants (maximum 2 digits)">
                <b-form-input
                  id="quantity-employees"
                  v-model="recruitmentProcess.new_employee_quantity"
                  type="number"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : ''"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              name="minimum-amount"
              rules="required"
            >
              <b-form-group label="Minimum Salary">
                <money
                  id="minimumAmount"
                  v-model="recruitmentProcess.min_amount"
                  v-bind="vMoney"
                  class="form-control input-form"
                  :class="{ 'border-danger': vmoneyValidate }"
                />
                <small
                  v-if="vmoneyValidate"
                  class="text-danger"
                >
                  Is required
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="6">
            <validation-provider
              name="maximum-amount"
              rules="required"
            >
              <b-form-group label="Maximum Salary">
                <money
                  id="maximumAmount"
                  v-model="recruitmentProcess.max_amount"
                  v-bind="vMoney"
                  class="form-control input-form"
                  :class="{ 'border-danger': vmoneyValidate2 }"
                />
                <small
                  v-if="vmoneyValidate2"
                  class="text-danger"
                >
                  Is required
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- MOF -->
          <b-col :cols="recruitmentProcess.mof_id?'11':12">
            <validation-provider
              v-slot="{ errors }"
              name="mof"
              rules="required"
            >
              <b-form-group label="MOF (Manual of functions)">
                <v-select
                  id="select-mof"
                  v-model="recruitmentProcess.mof_id"
                  placeholder="Please select MOF"
                  label="name"
                  :options="optionsMof"
                  :reduce="(option) => option.id"
                  width="100%"
                  :style="errors[0] ? 'border: 1px solid red;':''"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Select an option
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            v-if="recruitmentProcess.mof_id"
            cols="1"
          >
            <tabler-icon
              v-b-tooltip.hover.v-primary
              v-b-tooltip.hover.bottom
              title="View MOF"
              icon="FileInvoiceIcon"
              size="25"
              class="mr-2 cursor-pointer text-primary hover-me"
              style="margin-top:30px"
              @click="getMOF(recruitmentProcess.mof_id)"
            />
          </b-col>
          <!-- Reason -->
          <b-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="textarea-reason"
              rules="required"
            >
              <b-form-group label="Reason for request">
                <b-form-textarea
                  id="textarea-reason"
                  v-model="recruitmentProcess.reason"
                  placeholder="Enter Reason..."
                  rows="3"
                  :state="errors[0] ? false : null"
                  max-rows="6"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- shift -->
          <b-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              name="shift"
              rules="required"
            >
              <b-form-group label="Shift">
                <v-select
                  id="select-shift"
                  v-model="recruitmentProcess.shift"
                  placeholder="Please select shift"
                  label="text"
                  :options="optionsShift"
                  value-field="id"
                  :reduce="(option) => option.id"
                  width="100%"
                  :style="errors[0] ? 'border: 1px solid red;':''"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Select an option
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- deatails -->
          <b-col cols="6">

            <b-form-group label="Shift details">
              <b-form-textarea
                id="textarea-details"
                v-model="recruitmentProcess.details"
                placeholder="Enter details..."
                rows="3"
                max-rows="6"
              />
            </b-form-group>
          </b-col>
          <!-- languages english -->
          <b-col cols="6">
            <validation-provider
              name="languages"
              rules="required"
            >
              <b-form-group label="Languages">
                Are English skills required?
                <b-form-checkbox
                  id="languages-status"
                  v-model="recruitmentProcess.need_english"
                  :checked="recruitmentProcess.need_english"
                  :value="1"
                  :unchecked-value="2"
                  class="custom-control-primary pt-50"
                  name="check-button"
                  switch
                >YES</b-form-checkbox
                >
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- language -->
          <b-col cols="6" v-if="recruitmentProcess.need_english==1">
            <validation-provider
              v-slot="{ errors }"
              name="level_of_english"
              rules="required"
            >
              <b-form-group label="Languages">
                <v-select
                  id="select-languages"
                  v-model="recruitmentProcess.level_of_english"
                  placeholder="Please select languages"
                  label="text"
                  :options="optionsLanguages"
                  value-field="id"
                  :reduce="(option) => option.id"
                  width="100%"
                  :style="errors[0] ? 'border: 1px solid red;':''"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  Select an option
                </small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Commentary -->
          <b-col cols="12">
            <b-form-group label="Additional comments">
              <b-form-textarea
                id="textarea-commentary"
                v-model="recruitmentProcess.commentary"
                placeholder="Enter Commentary..."
                rows="3"
                max-rows="6"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <!-- <pre>{{ selectedRecruitmentProcess }}</pre> -->
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
          variant="primary"
          @click="registerRequestPersonnel(1)"
        >
          <feather-icon
            icon="SaveIcon"
            size="15"
          />
          <strong> Save Draft</strong>
        </b-button>
        <b-button
          variant="success"
          class="ml-1"
          @click="registerRequestPersonnel(2)"
        >
          <feather-icon
            icon="SendIcon"
            size="15"
          />
          <strong> Send to CEO</strong>
        </b-button>
      </div>

    </template>
    <show-modal-review
      v-if="showModalReview"
      :mof-data="selectedMOF"
      :readonly="true"
      @hidden="showModalReview = false"
      @reload="refreshTable()"
    />
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { VMoney } from 'v-money';
import moment from 'moment';
import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';
import ShowModalReview from '@/views/commons/components/manual-of-functions/modals/ModalReviewByCEO.vue';
import WorkAreasService from '@/views/commons/organization-chart/services/work_areas.service';

export default {
  name: 'ModalRequestPersonnel',
  components: {
    ShowModalReview,
  },
  directives: { money: VMoney },
  props: {
    selectedRecruitmentProcess: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      optionsLanguages: [
        { id: 1, text: 'A1' },
        { id: 2, text: 'A2' },
        { id: 3, text: 'B1' },
        { id: 4, text: 'B2' },
        { id: 5, text: 'C1' },
        { id: 6, text: 'C2' },
      ],
      formattedDate: '',

      optionsShift: [
        { id: 1, text: 'Morning' },
        { id: 2, text: 'Afternoon' },
        { id: 3, text: 'Rotating' },
      ],
      optionsMof: [],
      functions: [],
      recruitmentProcess: {
        date_start: null,
        new_employee_quantity: null,
        min_amount: 0,
        max_amount: 0,
        shift: null,
        mof_id: null,
        reason: null,
        details: null,
        commentary: null,
        id: null,
      },
      minDate: new Date(),
      showModalReview: false,
      selectedMOF: {},
      vMoney: {
        decimal: '.',
        thousand: ',',
        prefix: 'S/ ',
        precision: 2,
        min: 0.0,
      },

      vmoneyValidate: false,
      vmoneyValidate2: false,
      min_amount: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module == 28 ? 11 : this.$route.matched[0]?.meta?.module;
    },
  },
  watch: {
    'recruitmentProcess.min_amount': function (newMinAmount) {
      if (newMinAmount > 0) {
        this.vmoneyValidate = false;
      }
    },
    'recruitmentProcess.max_amount': function (newMaxAmount) {
      if (newMaxAmount > 0) {
        this.vmoneyValidate2 = false;
      }
    },
  },

  created() {
    this.getMofs();
  },
  mounted() {
    this.toggleModal('modal-request-personnel');
    if (this.selectedRecruitmentProcess) this.recruitmentProcess = this.selectedRecruitmentProcess;
    if (this.selectedRecruitmentProcess) {
      if (this.selectedRecruitmentProcess.shift === 'morning') {
        this.recruitmentProcess.shift = 1;
      }

      if (this.selectedRecruitmentProcess.shift === 'afternoon') {
        this.recruitmentProcess.shift = 2;
      }

      if (this.selectedRecruitmentProcess.shift === 'rotating') {
        this.recruitmentProcess.shift = 3;
      }
    }

    // Obtener la fecha actual
    const currentDate = new Date();

    // Obtener el día, mes y año
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // ¡Recuerda sumar 1 al mes porque los meses en JavaScript van de 0 a 11!
    const year = currentDate.getFullYear();

    // Crear la cadena en formato MM/DD/YYYY
    this.formattedDate = `${month}/${day}/${year}`;
    if (this.recruitmentProcess.date_start === null) {
      this.recruitmentProcess.date_start = this.formattedDate;
    }
  },
  methods: {
    async getMOF(mofId) {
      // de this.optionsMof encontrar el que id sea igual a mofId
      const mof = this.optionsMof.find(item => item.id === mofId);
      try {
        const params = {
          page: 1,
          perpage: 100,
          campo: null,
          tab: 2,
          user_id: null,
          rol_id: mof.hr_role_id,
        };
        const { data } = await WorkAreasService.getMof(params);
        this.selectedMOF = data?.data[0] || {};
        this.showModalReview = true;
        console.log(this.selectedMOF);
      } catch (error) {
        this.showErrorSwal();
        console.log('error', error);
      }
    },
    closeModalRequestPersonnel() {
      this.$emit('closeModalRequestPersonnel');
    },
    clearForm() {
      this.recruitmentProcess = [...this.recruitmentProcessTemplate];
    },
    handleSelectInputMof(selected) {
      const mof = this.optionsMof.find(item => item.id === selected);
      this.functions = JSON.parse(mof.functions);
    },
    async getMofs() {
      try {
        const data = await RecruitmentService.getMofsApproved();
        if (data.status === 200) {
          this.optionsMof = data.data;
        }
      } catch (error) {
        console.log(error);
        this.showErrorSwal();
      }
    },
    async registerRequestPersonnel(statusId) {
      if (statusId === 2) {
        const validation = await this.$refs.formRequestPersonnel.validate();
        this.vmoneyValidate = this.recruitmentProcess.min_amount === 0;
        this.vmoneyValidate2 = this.recruitmentProcess.max_amount === 0;
        if (!validation) return;
      }
      if (statusId === 1 && !this.recruitmentProcess.date_start) {
        this.showWarningSwal('Start Date is required');
        return;
      }

      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
      try {
        this.addPreloader();
        const params = {
          id: this.recruitmentProcess.id,
          date_start: moment(this.recruitmentProcess.date_start).format('YYYY-MM-DD'),
          new_employee_quantity: this.recruitmentProcess.new_employee_quantity,
          min_amount: this.recruitmentProcess.min_amount,
          max_amount: this.recruitmentProcess.max_amount,
          mof_id: this.recruitmentProcess.mof_id,
          reason: this.recruitmentProcess.reason,
          shift: this.recruitmentProcess.shift,
          details: this.recruitmentProcess.details,
          commentary: this.recruitmentProcess.commentary,
          module_id: this.moduleId,
          user_id: this.currentUser.user_id,
          status_id: statusId,
          level_of_english: this.recruitmentProcess.level_of_english,
          need_english: this.recruitmentProcess.need_english
        };
        const data = await RecruitmentService.registerRequestPersonnel(params);
        if (data.status === 200) {
          this.showSuccessSwal('Success', 'Request Personnel Registered');
          this.$emit('closeModalRequestPersonnel');
          this.$emit('refreshTable');
          if (!this.selectedRecruitmentProcess) this.$emit('refreshTableOnCreate');
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
  },
};
</script>
