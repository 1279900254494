import store from '@/store';
import Vue from 'vue';

const markAttendanceSocket = () => {
  window.socket.bind('mark-attendance', async data => {
    // console.log('mark-attendance ', data)
    // eslint-disable-next-line space-infix-ops, prefer-destructuring
    const user_id=store.state.auth.currentUser.user_id;
    let role_id;

    let temps = false;
    let chief = false;
    const message = {
      101: 'YOUR ATTENDANCE WAS REGISTERED CORRECTLY!',
      102: 'YOUR ATTENDANCE WAS REGISTERED LATENESS!',
      103: `AN ATTENDANCE WAS REGISTERED LATENESS FROM ${data.employeeName}!`,
    };
    const messageIcon = {
      101: 'info.svg',
      102: 'warning.svg',
      103: 'warning.svg',
    };
    let htmlAlert;
    const sessionId = store.state.auth.currentUser.user_id;

    let userExists;
    if (data.chiefsId) {
      userExists = data.chiefsId.some(item => item.user_id === sessionId);
    }

    if (userExists) {
      chief = true;
      role_id = 2;
    } else {
      role_id = 3;
    }

    if (data.userId == sessionId || userExists) {
      temps = true;
    }
    const params = {
      role_id,
      user_id,
      perpage: 100,
      npage: 0,

    };
    if (temps && chief) {
      if (data.typeNotification == 103) {
        localStorage.setItem('lateAttendanceNotificationsUpdated', 'false');
        await store.dispatch('NotificationStore/A_SET_LATE_ATTENDANCE_NOTIFICATIONS', params);
        await store.dispatch('NotificationStore/A_SET_NOTIFICATIONS_COUNTER');
        // console.log('alert for user and chief')
        htmlAlert = `${`${`<img src="/assets/images/icons/swal/${messageIcon[data.typeNotification]}" style="margin-bottom: 10px;" alt="alert-info-image">`
                + `<div><h2 style="font-weight: 600;">${message[data.typeNotification]}</h2></div>`
                + '<div style="text-align: center;">'
                + '<div style="font-weight: bolder;">'}at ${
          data.notificationDescription
        } </div>`
        } </div>`;
        const res = await Vue.swal.fire({
          html: htmlAlert,
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          showConfirmButton: true,
        });
        if (res.value) {
          await window.amgApi.post('/commons/close-all-swal', data);
        }
      }
    }
    if (data.userId == sessionId) {
      // console.log("alert for user")
      localStorage.setItem('lateAttendanceNotificationsUpdated', 'false');
      await store.dispatch('NotificationStore/A_SET_LATE_ATTENDANCE_NOTIFICATIONS', params);
      await store.dispatch('NotificationStore/A_SET_NOTIFICATIONS_COUNTER');

      if (data.typeNotification != 103) {
        htmlAlert = `${`${'<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;" alt="alert-info-image">'
                + `<div><h2 style="font-weight: 600;">${message[data.typeNotification]}</h2></div>`
                + '<div style="text-align: center;">'
                + '<div style="font-weight: bolder;">'}at ${
          data.notificationDescription
        } </div>`
        } </div>`;
        const res = await Vue.swal.fire({
          html: htmlAlert,
          confirmButtonText: 'Ok',
          allowOutsideClick: true,
          showConfirmButton: true,
        });
        if (res.value) {
          await window.amgApi.post('/commons/close-all-swal', data);
        }
      }
    }

    // if (data.userId === sessionId) {
    //     temps = true
    // }
    // if (temps) {
    //     const htmlAlert = `${`${'<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;" alt="alert-info-image">'
    //         + `<div><h2 style="font-weight: 600;">YOUR ATTENDANCE WAS REGISTERED CORRECTLY!</h2></div>`
    //         + '<div style="text-align: center;">'
    //         + '<div style="font-weight: bolder;">'}${
    //             data.notificationDescription
    //         } </div>`
    //         } </div>`
    //     const res = await Vue.swal.fire({
    //         html: htmlAlert,
    //         confirmButtonText: 'Ok',
    //         allowOutsideClick: true,
    //         showConfirmButton: true,
    //     })
    //     if (res.value) {
    //         await window.amgApi.post('/commons/close-all-swal', data)
    //     }
    // }
    // if(data.chief){
    //     chief=true
    // }if(){

    // }
  });
};
export default markAttendanceSocket;
