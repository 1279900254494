<template>
  <b-modal
    ref="modal-assing-case"
    title="ASSIGN CASE"
    title-tag="h3"
    size="sm"
    modal-class="modal-primary"
    hide-footer
    no-close-on-backdrop
    @hidden="close"
  >
    <validation-observer ref="form" #default="{ invalid }">
      <b-row>
        <b-col md="12">
          <b-form-group label="Assign To:">
            <validation-provider v-slot="{ errors }" rules="required">
              <v-select
                clearable
                label="name"
                placeholder="Select a user..."
                v-model="form.assignTo"
                :options="users"
                :reduce="(option) => option.id"
                :state="errors.length > 0 ? false : null"
                :class="errors[0] ? 'select__error' : ''"
              />
              <span v-if="errors[0]" class="text-danger mt-1"
                >Assign To {{ errors[0] }}</span
              >
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col class="d-flex justify-content-end">
          <b-button variant="primary" @click="save" :disabled="invalid">
            Save
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
export default {
  props: {
    caseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      form: {
        caseId: "",
        assignTo: null,
        currentUser: null,
      },
      users: [],
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async mounted() {
    this.toggleModal("modal-assing-case");
    this.usersAllPrograms();
    this.form.caseId = this.caseId;
    this.form.currentUser = this.currentUser.user_id;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async usersAllPrograms() {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });

        this.users = data.data.map((val) => {
          return {
            id: val.id,
            name: val.user_name,
          };
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async save() {
      try {
        const isValid = await this.$refs.form.validate();
        this.addPreloader();
        if (isValid) {
          const { data } = await CourtInfoService.assingCaseCourtInfo(
            this.form
          );
          this.showSuccessSwal(`${data.message}`);
          this.close();
          this.$emit("closing");
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style scoped>
.select__error {
  border: 1px solid #f1556c !important;
  border-radius: 5px;
}
</style>