export default [
    {
        path: "products-inventory",
        name: "products-inventory-logistic",
        component: () =>
            import(
                /* webpackChunkName: "LogisticsProductsInventory" */ "@/views/logistic/views/products-inventory/ProductsInventory.vue"
            ),
        redirect: { name: "lo-list-products" },
        children: [
            {
                path: "",
                redirect: { name: "lo-list-products" },
                permittedRoles: [2, 1, 3],
            },
            {
                path: "products",
                component: () =>
                    import(
                        /* webpackChunkName: "LogisticsProducts" */ "@/views/logistic/views/products-inventory/components/ProductsGrillas.vue"
                    ),
                name: "lo-list-products",
                meta: {
                    pageTitle: "Products Inventory",
                    breadcrumb: [
                        {
                            text: "Products Inventory",
                        },
                        {
                            text: "Products",
                            active: true,
                        },
                    ],
                },
            },
            {
                path: "inputs",
                component: () =>
                    import(
                        /* webpackChunkName: "LogisticsProductInputs" */ "@/views/logistic/views/products-inventory/components/ProductActions.vue"
                    ),
                name: "lo-list-inputs",
                meta: {
                    pageTitle: "Product Inputs",
                    breadcrumb: [
                        {
                            text: "Product Inputs",
                        },
                        {
                            text: "Inputs",
                            active: true,
                        },
                    ],
                    tab: 1,
                },
            },
            {
                path: "outputs",
                component: () =>
                    import(
                        /* webpackChunkName: "LogisticsProductOutputs" */ "@/views/logistic/views/products-inventory/components/ProductActions.vue"
                    ),
                name: "lo-list-outputs",
                meta: {
                    pageTitle: "Product Outputs",
                    breadcrumb: [
                        {
                            text: "Product Outputs",
                        },
                        {
                            text: "Outputs",
                            active: true,
                        },
                    ],
                    tab: 2,
                },
            },
        ],
    },
];
