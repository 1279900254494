import store from "@/store";
import ClaimsV2Service from "@/views/commons/components/claims_v2/services/claims-v2.service.js";
import InquiriesClaimService from "@/views/commons/components/claims_v2/views/claim-inquiries/services/inquiry-claim.service.js";
import Notifications from "@/service/notification/index";
import RecruitmentService from "@/views/commons/components/recruitment-process/services/recruiment.service";
import employeeClaims from "@/views/commons/commons-counter";
import RequestService from "@/views/commons/components/request/services/request.service.js";
import RequestClientStatusServices from '@/views/management/views/request-client-status/services/RequestClientStatus.services.js';

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "quality-projects",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const claimsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;

  const params = {
    module_id: module,
  };

  const { pending_claims_1, pending_claims_2, pending_claims_3 } =
    await ClaimsV2Service.getClaimsCounters(params);

  const sideCounter = pending_claims_1 + pending_claims_2;

  const payload = {
    routeName: "claims-quality",
    tag: sideCounter,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const inquiriesCounter = async () => {
  const { pending_inquiries, solved_inquiries } =
    await InquiriesClaimService.getInquiriesCounter({
      user_id: store.state.auth.currentUser.user_id,
    });

  if (solved_inquiries > 0) {
    const payload = {
      routeName: "quality-inquiries",
      tag: solved_inquiries > 99 ? "+99" : solved_inquiries,
    };

    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "quality-recruitment-process",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: "request-quality",
    tag: data[0].counter,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({
    user_id,
    moduleId,
  });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: "quality-customer-tickets",
      tag: data[0].v_total_open,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const RequestClientStatusCounter = async () => { 
  const module_id = store.state.auth.currentUser.modul_id;
  const data = await  RequestClientStatusServices.getCountPendingChangeStatus({module_id : module_id});  
    const payload = {
      routeName: 'quality-request-client-status',
      tag: data.data,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload); 
};

const allCountersQuality = async () => {
  Promise.all([
    claimsCounter(),
    inquiriesCounter(),
    projectsCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
    employeeClaims("quality-employee-claims"),
    requestNotAsignedCounter(),
    RequestClientStatusCounter(),
  ]);
};

export default allCountersQuality;
