export default [
    {
        path: "settings",
        name: "settings",
        component: () => import("@/views/quality/views/settings/Settings.vue"),
        redirect: {name: "source-claims"},
        meta: {
            pageTitle: "Settings",
            permittedRoles: [17, 2, 3], 
            breadcrumb: [
                {
                    text: "Settings",
                    active: true,
                }
            ]
        },
        children: [
            {
                path: "source",
                name: "source-claims",
                component: () => import("@/views/quality/views/settings/views/SourceClaims.vue"),
                meta: {
                    type: "settings",
                    tab: 1,
                    pageTitle: "Settings",
                    breadcrumb: [
                        {
                            text: "Source claims",
                            active: true,
                        }
                    ]
                }
            },
            {
                path: "solution",
                name: "solution-claims",
                component: () => import("@/views/quality/views/settings/views/SolutionClaims.vue"),
                meta: {
                    type: "settings",
                    tab: 2,
                    pageTitle: "Settings",
                    breadcrumb: [  
                        {
                            text: "Solution claims",
                            active: true,
                        }
                    ]
                }
            },
            {
                path: "reason",
                name: "reason-claims",

                component: () => import("@/views/quality/views/settings/views/ReasonClaims.vue"),
                meta: {
                    type: "settings",
                    tab: 3,
                    pageTitle: "Settings",
                    breadcrumb: [
                        {
                            text: "Reason claims",
                            active: true,
                        }
                    ]
                }
            },
            {
                path: "sanction",
                name: "sanction-claims",
                component: () => import("@/views/quality/views/settings/views/SanctionClaims.vue"),
                meta: {
                    type: "settings",
                    tab: 4,
                    pageTitle: "Settings",
                    breadcrumb: [
                        {
                            text: "Sanction claims",
                            active: true,
                        }
                    ]
                }
            },
            
            {
                path: "type",
                name: "type-claims",
                component: () => import("@/views/quality/views/settings/views/TypeClaims.vue"),
                meta: {
                    type: "settings",
                    tab: 5,
                    pageTitle: "Settings",
                    breadcrumb: [
                        {
                            text: "Type claims",
                            active: true,
                        }
                    ]
                }
            },
            {
                path: "follow-up",
                name: "follow-up",
                component: () => import("@/views/quality/views/settings/views/FollowUpDays.vue"),
                meta: {
                    type: "settings",
                    tab: 6,
                    pageTitle: "Settings",
                    breadcrumb: [
                        {
                            text: "Folow-up",
                            active: true,
                        }
                    ]
                }
            },
            {
                path: "",
                name: "hours-claims",
                component: () => import("@/views/commons/components/module-office-hour/components/OfficeHoursList.vue"),
                meta: {
                    type: "settings",
                    tab: 7,
                    pageTitle: "Settings",
                    breadcrumb: [
                        {
                            text: "Hours claims",
                            active: true,
                        }
                    ]
                }
            },
            {
                path: "survey",
                name: "surveys",
                component: () => import("@/views/quality/views/settings/views/Survey.vue"),
                meta: {
                    type: "settings",
                    tab: 8,
                    pageTitle: "Settings",
                    breadcrumb: [
                        {
                            text: "survey",
                            active: true,
                        }
                    ]
                }
            },
        ]
    }
]