export default [
  { key:'select', label:'' },
  { key:'cardholdername', label:'Card Holder Name' },
  { key:'cardnumber', label:'Card Number', thClass: 'text-center' },
  { key:'type_card', label:'Type', thClass: 'text-center' },
  { key:'card_expi_month', label:'MM' },
  { key:'card_expi_year', label:'YY' },
  { key:'cardsecuritycode', label:'CVC', thClass: 'text-center' },
  { key:'created_by', label:'Created by' },
  //{ key:'action', label:'Action'}
]