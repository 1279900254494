<template>
  <b-modal
    v-model="onControl"
    title="Lead's Details"
    size="lg"
    modal-class="modal-primary text-center"
    hide-footer
    title-tag="h3"
    @hidden="closeModal"
  >
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refDashboardLead'].refresh()"
    >
      <template #table>
        <b-table
          ref="refDashboardLead"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="detailSalesProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template v-slot:cell(client_name)="data">
            <router-link
              :class="[textLink]"
              target="_blank"
              class
              :to="{
                name: 'sales-lead-show',
                params: {
                  id: data.item.id,
                },
              }"
            ><span>{{ data.item.client_name }}</span>
            </router-link>
          </template>
          <template v-slot:cell(origin_module)="data">
            <span>{{ data.item.origin_module }}</span>
          </template>
          <template v-slot:cell(created_at)="data">
            <span>{{ data.item.created_at | myGlobalDay }}</span>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import DashboardSalesServices from '@/views/ce-digital/sub-modules/sales/views/dashboards/dashboard-sales-services'

export default {
  props: {
    dateMonth: {
      type: Number,
      default: null,
    },
    dateYear: {
      type: Number,
      default: null,
    },

  },
  data() {
    return {
      onControl: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      sortBy: 'created_at',
      sortDesc: true,
      fields: [
        {
          key: 'client_name',
          label: 'Lead Name',
        },
        {
          key: 'origin_module',
          label: 'Origin Module',
        },
        {
          key: 'created_at',
          label: 'Created At',

        },

      ],
      isBusy: false,
      items: [],
      date: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  created() {
    this.onControl = true
  },
  methods: {
    closeModal() {
      this.onControl = false
      this.$emit('close')
    },
    async detailSalesProvider(ctx) {
      const params = {
        per_page: ctx.perPage,
        order_by: ctx.sortBy,
        page: ctx.currentPage,
        order: ctx.sortDesc ? 'desc' : 'asc',
        campo: this.filterPrincipal.model,
        month: this.dateMonth,
        year: this.dateYear,
        seller_id: this.currentUser.user_id,
      }

      const data = await DashboardSalesServices.getDashboardDetailsLeadSeller(params)
      this.items = data.data.data
      this.startPage = data.data.from
      this.paginate.currentPage = data.data.current_page
      this.paginate.perPage = data.data.per_page
      this.totalRows = data.data.total
      this.toPage = data.data.to
      return this.items || []
    },
  },
}
</script>

<style scoped>

</style>
