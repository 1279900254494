

export default [
  {
    path: "clients",
    name: "creditexperts-digital-clients",
    redirect: { name: "creditexperts-digital-clients-list" },
    component: () => import(/* webpackChunkName: "CEDCustomerServiceClients" */ "@/views/ce-digital/sub-modules/customer-service/views/clients-table/Clients.vue"),
    meta: {
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
        },
      ],
      permittedRoles: [1, 2, 15, 17],
    },
    children: [
      {
        path: "",
        name: "creditexperts-digital-clients-list",
        component: () => import(/* webpackChunkName: "CEDCustomerServiceClientsCreditExperts" */ "@/views/ce-digital/sub-modules/customer-service/views/clients-table/components/ClientsGrid.vue"),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 15, 17],
        },
      }
    ],
  },
];
