export default [
    {
      key: "Select",
      label: "",
    },
    {
      key: "cardholdername",
      label: "Card Holder Name",
    },
    {
      key: "cardnumber",
      label: "Card Number",
    },
    {
      key: "type_card",
      label: "Type",
    },
    {
      key: "card_expi_month",
      label: "MM",
    },
    {
      key: "card_expi_year",
      label: "YY",
    },
    {
      key: "cardsecuritycode",
      label: "CVC",
    },
]