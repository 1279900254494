<template>
  <b-modal
    ref="pending-appointments-modal"
    title="Rejected DS sales"
    title-class="h3 text-white font-weight-bolder"
    size="md"
    no-close-on-backdrop
    modal-class="modal-primary"
    @hidden="hiddenModal"
    hide-footer
  >
    <b-container fluid>
      <filter-slot
        :filter="[]"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refListTable'].refresh()"
      >
        <!-- table -->
        <b-table
          slot="table"
          ref="refListTable"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          sort-icon-left
          show-empty
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :fields="fields"
          :items="myProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <!-- Column: Name -->
          <template #cell(lead_name)="data">
            <div>
              <!-- <router-link

                :class="[textLink]"
                target="_blank"
                class
                :to="{
                  name: pathNameDashboard,
                  params: {
                    id: data.item.id,
                  },
                  props: { programsAll: programsAll },
                }"
                > -->
              <span>{{ data.item.lead_name }}</span>
              <!-- </router-link> -->
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(status)="data">
            <div class="d-flex justify-content-center">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize w-75"
                >{{ data.item.status }}</b-badge
              >
              <p></p>
            </div>
          </template>

          <!-- Column: Credit Report -->
          <template #cell(credit_report)="data">
            <div>
              <strong
                :class="`text-${
                  data.item.credit_report == 1 ? 'danger' : 'success'
                }`"
                >{{ data.item.credit_report == 1 ? "NO" : "YES" }}</strong
              >
            </div>
          </template>

          <!-- Column: Programs -->
          <template #cell(programs)="data">
            <div
              v-if="data.item.programs"
              class="d-flex flex-column"
              style="gap: 0.5rem"
            >
              <template v-for="(item, key) in JSON.parse(data.item.programs)">
                <span :key="key">{{ item }}</span>
              </template>
            </div>
          </template>

          <!-- Column: Created By -->
          <template #cell(created_at)="data">
            <div>
              <div>{{ data.item.owner }}</div>
              <small>{{ data.item.created_at | myDate }}</small>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div>
              <b-button
                variant="outline"
                size="sm"
                title="Continue with sale"
                @click="
                  openModalRescheduleEvent(data.item.event_id, data.item.id, data.item.validate_account_selected)
                "
              >
                <feather-icon class="text-success" icon="ChevronsRightIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-container>
    <!-- Reschedule event -->
    <add-creditor-ds-modal
      v-if="addCreditorModalStatus"
      :state-modal="1"
      :rejected-appointments="true"
      :title="'Add creditors'"
      :id_event="event_id"
      :id_lead="lead_id"
      :ncrRequestId="ncrRequestId"
      :analyzedBySoft="analyzedBySoft"
      @reload="false"
      @close="addCreditorModalStatus = false"
      @reloadAmountSaved="updateAppointment($event)"
      ref="addCreditorDsModal"
    />
  </b-modal>
</template>
  
  <script>
import LeadsService from "@/views/social-network/services/leads";

import fieldsData from "@/views/crm/views/Lead/components/modal/PendingAppointments.fields.js";
import { BModal } from "bootstrap-vue";
import ModalEventReschedule from "@/views/crm/views/Lead/lead-event/ModalRescheduleEvent.vue";
import AddCreditorDsModal from "@/views/crm/views/Lead/lead-module/dashboard/modal/AddCreditorDsModal.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import ServiceLeads from "@/views/commons/components/lead-programs/service/lead.service.js";
export default {
  name: "PendingAppointments",
  components: {
    BModal,
    ModalEventReschedule,
    AddCreditorDsModal
  },
  props: {},
  data() {
    return {
      data: {
        program: [
          4
        ],
        motive: null,
        counter: null,
        done: null,
        parent_id: null,
      },
      fields: fieldsData,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      perPageOptions: [10, 25, 50, 100],
      sortBy: "",
      sortDesc: true,
      rowData: {},
      isBusy: false,
      perPage: 50,
      totalUsers: 0,
      totalRows: 0,
      toPage: 0,
      startPage: 0,
      event: {
        attend: null,
        date: "",
        from: "",
        id: null,
        location: "",
        real_time: "",
        title: "",
        to: "",
        call_attended: false,
        call_answered: false,
        programs: [],
      },
      addCreditorModalStatus: false,
      lead_id:0,
      event_id: 0,
      ncrRequestId: null,
      analyzedBySoft: false,
    };
  },
  computed: {
    // ...mapActions({
    // A_GET_OWNERS: "CrmGlobalStoreA_GET_OWNERS",
    //   A_SET_EVENT: "CrmEventStore/A_SET_EVENT",
    //   A_DELETE_EVENT: "CrmEventStore/A_DELETE_EVENT",
    //   A_GET_DATE_EVENTS_TASKS: "CrmEventStore/A_GET_DATE_EVENTS_TASKS",
    //   A_UPDATE_EVENT: "CrmEventStore/A_UPDATE_EVENT",
    //   A_SAVE_NO_ANSWER_EVENT_MOTIVE:
    //     "CrmGlobalStore/A_SAVE_NO_ANSWER_EVENT_MOTIVE",
    // }),
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    ...mapState({
      S_LEAD: (state) => state.CrmLeadStore.S_LEAD,
    }),
  },
  methods: {
    async updateAppointment(emitData = null) {
      try {
        const responseFirst = await this.A_GET_DATE_EVENTS_TASKS({
          idLead: this.event.lead_id,
          id: this.event.id,
        });
        if (this.isResponseSuccess(responseFirst)) {
          const dateFormat = this.$moment(
            `${this.event.date} ${this.event.from}`
          ).format("YYYY-MM-DD HH:mm:ss");
          let repeat = 0;
          responseFirst.data.forEach((el) => {
            if (el.dates == dateFormat) repeat++;
          });
          if (repeat === 0) {
            const body = {
              ...this.event,
              user_id: this.event.user_id.value,
              id: this.event.id,
              title: this.event.title,
              date: this.event.date,
              from: this.event.from,
              to: this.event.to,
              host: this.event.user_id.value,
              location: this.event.location,
              description: this.event.description,
              seller: this.event.user_id.label,
              current_user: this.currentUser.user_id,
              month: this.getCurrentMonth(),
              module: this.moduleId,
              lead_pending_appointment: emitData.lead_pending_appointment,
            };
            const response = await this.A_UPDATE_EVENT(body);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.emitReloadAmountSaved2(emitData)
      }
    },

    async emitReloadAmountSaved2(emmitedData = null) {
      if (this.data.counter == null || this.data.counter == 1) {
        await Promise.all(
          this.data.program.map((item) =>
            this.A_ATTEND_EVENT({
              id: this.event.id,
              program: item,
              done: 1,
              module: this.moduleId,
              recovery_id: this.idProcessSale,
              derivation_id: null,
            })
          )
        );
        this.updateOptionSelected(emmitedData);
        if (this.data.counter == 1) {
          await this.A_NOTSALE_EVENTS_MOTIVES(data);
          this.saveNote();
        }
      } else {
        const response = await this.A_NOTSALE_EVENTS_MOTIVES(data);
        this.saveNote();

        if (response.status === 200) {
          this.$emit("closeModal");
          this.showSuccessSwal();
        }

        await this.A_GET_EVENTS({ idLead: this.$route.params.id });
      }
      this.removePreloader();
      if (this.data.counter == 1 || this.data.counter == null) {
        this.addCreditorModalStatus = false
        this.$refs.refListTable.refresh()
      }
    },

    ...mapActions({
      A_GET_EVENT: "CrmEventStore/A_GET_EVENT",
      A_GET_EVENTS: "CrmEventStore/A_GET_EVENTS",
      A_GET_LEAD: "CrmLeadStore/A_GET_LEAD",
      A_SET_EVENT: "CrmEventStore/A_SET_EVENT",
      A_DELETE_EVENT: "CrmEventStore/A_DELETE_EVENT",
      A_ATTEND_EVENT: "CrmEventStore/A_ATTEND_EVENT",
      A_GET_DATE_EVENTS_TASKS: "CrmEventStore/A_GET_DATE_EVENTS_TASKS",
      A_UPDATE_EVENT: "CrmEventStore/A_UPDATE_EVENT",
      A_SAVE_NO_ANSWER_EVENT_MOTIVE:
        "CrmGlobalStore/A_SAVE_NO_ANSWER_EVENT_MOTIVE",
    }),
    async getLead(lead_id) {
      try {
        this.isPreloading(true);
        await this.A_GET_LEAD({ id: lead_id });
        this.isPreloading(false);
      } catch (error) {
        console.log("Something went wrong getLead", error);
        this.showErrorSwal();
        this.isPreloading(false);
      }
    },
    openCreditorsModal() {
      this.addCreditorModalStatus = true;
    },
    async myProvider(ctx) {
      let sort_By = 10;
      let sortDirection = "desc";
      if (ctx) {
        if (ctx.sortBy === "lead_name") {
          sort_By = 2;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        } else if (ctx.sortBy === "created_at") {
          sort_By = 10;
          if (ctx.sortDesc) sortDirection = "desc";
          else sortDirection = "asc";
        }
      }

      try {
        const resp = await LeadsService.getLeadsPendingAppointments({
          name_text: this.filterPrincipal.model,
          orderby: sort_By,
          order: sortDirection,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
        });
        this.startPage = resp.data.from;
        this.toPage = resp.data.to;
        this.totalRows = resp.data.total;
        this.paginate.currentPage = resp.data.current_page;
        this.paginate.perPage = resp.data.per_page;
        return resp.data.data;
      } catch (error) {
        console.log("Something went wrong myProvider", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    hiddenModal() {
      this.$emit("hiddenModal");
    },
    resolveUserStatusVariant(status) {
      if (status === "Pending") return "warning";
      if (status === "Active") return "success";
      if (status === "Inactive") return "secondary";
      if (status === "Not Contacted") return "danger";
      return "primary";
    },
    async openModalRescheduleEvent(eventId, leadId, validateAccountSelected) {
      this.lead_id = leadId;
      this.event_id = eventId;
      this.getLead(leadId);
      this.analyzedBySoft = validateAccountSelected > 0; //validate_account_selected > 0 => analisis automatico
      this.addCreditorModalStatus = true;
    },

    async updateOptionSelected(emitData) {
      const { status } = await ServiceLeads.financialAnalysisOption({
        option: emitData.option,
        id_event: emitData.id_event,
      });
    },
  },
  mounted() {
    this.toggleModal("pending-appointments-modal");
  },
};
</script>
  
  