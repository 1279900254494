<template>
  <b-row>
    <b-col sm="12">
      <step-progress
        :steps="steps"
        :icons="icons"
        :currentStep="currentStep"
        :validationNext="validateStepNext"
        :validationPrev="true"
        @step-changed="handleStepNext"
        @step-prev="handleStepPrev"
      >
        <template v-slot:default="{ step }">
          <div v-if="step == 1">
            <Billing
              :lengthTable="length"
              ref="billing"
              :state="state"
              :fdlead="fromDashboardLead"
              :retainerFee="retainerFee"
              :idEventAccountApproved="idEventAccountApproved"
              :id_event="id_event"
              @close="close"
            />
          </div>
          <div v-else-if="step == 2">
            <AddCreditor
              :id_event="id_event"
              :ncrRequestId="ncrRequestId"
              :analyzedBySoft="analyzedBySoft"
              :idEventAccountApproved="idEventAccountApproved"
              :state="state"
              ref="addCreditor"
              :client_id="client_id"
              :myProvider="myProvider"
              :countCreditor="length"
              :total="total"
              :programPayment="programPayment"
              :creditorList="creditorList"
              :fdlead="fromDashboardLead"
            />
          </div>
          <div v-else-if="step == 3">
            <AnalysisOptionsSale
              ref="analysisOptionsSale"
              :eventId="id_event"
            />
          </div>
        </template>
      </step-progress>
    </b-col>
  </b-row>
</template>

<script>
import StepProgress from "@/views/commons/steps/StepProgress.vue";
import AddCreditor from "@/views/crm/views/Lead/lead-module/dashboard/sale/debt-solution/AddCreditor.vue";
import Billing from "@/views/crm/views/Lead/lead-module/dashboard/sale/debt-solution/Billing.vue";
import AnalysisOptionsSale from "@/views/crm/views/Lead/lead-module/dashboard/sale/debt-solution/AnalysisOptionsSale.vue";
import ModalAddCreditorNewDs from "@/views/crm/views/Lead/lead-module/dashboard/modal/ModalAddCreditorNewDs.vue";
import DebtSolutionService from "@/views/debt-solution/services/debt-solution.service.js";

export default {
  components: {
    StepProgress,
    ModalAddCreditorNewDs,
    AddCreditor,
    Billing,
    AnalysisOptionsSale,
  },
  props: {
    fromDashboardLead: {
      type: Boolean,
      required: false,
      default: false,
    },
    id_event: {
      type: Number,
      required: true,
    },
    ncrRequestId: {
      default: null,
    },
    idEventAccountApproved: {
      type: Number,
      default: null,
    },
    analyzedBySoft: {
      type: Boolean,
      default: false,
    },
    state: {
      default: null,
    },
    client_id: {
      default: null,
    },
    idEventAccountApproved: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      steps: ["RATES", "CREDITORS", "ANALYSIS OPTIONS"],
      icons: ["MoneyBagIcon", "UsersAmgIcon", "robotIcon"],
      onControl: false,
      currentStep: 1,
      retainerFee: null,
      length: 0,
      total: {},
      programPayment: 0,
      creditorList: [],
    };
  },
  created() {
    this.onControl = true;
    if (!this.isModuleCRM || this.state == 0) {
      this.steps.pop();
      this.icons.pop();
    }
  },
  methods: {
    async handleChildStepChange(step) {
      switch (step) {
        case 2:
          return await this.$refs.addCreditor.validateAddCreditor();
        case 1:
          return this.fromDashboardLead
            ? await this.$refs.billing.addProgramPayment()
            : !this.isModuleCRM || this.state == 0
            ? true
            : await this.$refs.billing.addProgramPayment();
        case 3:
          return await this.$refs.analysisOptionsSale.sell();
        default:
          return true;
      }
    },
    async handleStepNext(step) {
      try {
        const adjustedStep = step - 1;
        const result = await this.handleChildStepChange(adjustedStep);
        if (result) {
          this.currentStep = step;
        } else {
          console.error("Error occurred, unable to proceed to next step");
        }
        this.$emit("reload");
      } catch (error) {
        console.error("Error occurred:", error);
      }
    },
    async handleStepPrev(step) {
      this.currentStep = step;
      switch (step) {
        case 1:
          return true;
        case 2:
          return true;
        case 3:
          return true;
        default:
          return true;
      }
    },
    async myProvider() {
      try {
        let response;
        if (
          this.ncrRequestId == null &&
          this.idEventAccountApproved == null &&
          !this.analyzedBySoft
        ) {
          response = await DebtSolutionService.getCreditorPreview({
            id: this.id_event,
          });
        } else {
          response = await amgApi.post(
            "/sales-made/debt-solution/get-credits-debt-solution",
            {
              event:
                this.idEventAccountApproved == null
                  ? this.id_event
                  : this.idEventAccountApproved,
            }
          );
        }

        if (response.status == 200 && response.data.length > 0) {
          this.addPreloader();
          this.length = response.data.length;
          this.total_balance = response.data[0].total_balance;
          this.total_monthly = response.data[0].total_monthly;
          this.possible_monthly = response.data[0].total_possible_monthly;
          this.total_interest = response.data[0].total_interest;
          this.total = {
            total_balance: response.data[0].total_balance,
            total_monthly: response.data[0].total_monthly,
            total_interest: response.data[0].total_interest,
            possible_monthly: response.data[0].total_possible_monthly,
          };
          this.programPayment = Number(response.data[0].program_payment) ?? 0;
          this.settlementCost =
            response.data[0].settlement_cost ?? this.settlement_cost;
          this.disableEdit = response.data[0].disable_edit ?? 0;
          this.retainerFee = parseFloat(response.data[0].retainer_fee ?? 0.1);
          this.stateCreditor = response.data[0].state1;
        } else {
          this.length = 0;
          this.total_balance = "";
          this.total_monthly = "";
          this.possible_monthly = "";
          this.total_interest = "";
        }
        response.data = response.data.map((item) => {
          return {
            ...item,
            credit: item.name_creditor ?? item.credit,
            account: item.credit,
            account_number: item.account,
            editData: false,
            monthsCpy: item.months,
            monthlyCpy: item.monthly,
            interestCpy: item.interest,
            typeCpy: item.typt,
          };
        });
        this.creditorList = response.data || [];
        return this.creditorList;
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("getDataProgram", this.programPayment);
        this.$emit("lengthTable", this.length);
        this.$emit("stateCreditor", this.stateCreditor);
        this.removePreloader();
      }
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    validateStepNext() {
      switch (this.currentStep) {
        case 1:
          return true;
        case 2:
          return !this.isModuleCRM || this.state == 0 ? false : true;
        case 3:
          return true;
        default:
          return true;
      }
    },
    creditorsIsNotNull() {
      return this.length == 0;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isModuleCRM() {
      return this.moduleId === 2;
    },
  },
};
</script>
