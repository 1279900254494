export default [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    key: "from",
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    key: "to",
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Origin",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    disabled: false,
    key: "origin",
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Captured By",
    model: 0,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    visible: false,
    key: "captured_by",
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Seller",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    visible: false,
    key: "seller",
  },
  // {
  //   type: 'select',
  //   margin: true,
  //   showLabel: true,
  //   label: 'Status',
  //   model: null,
  //   options: [],
  //   reduce: 'id',
  //   selectText: 'label',
  //   cols: 12,
  //   key: "status"
  // },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Destination",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    key: "destination",
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Type",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 6,
    key: "type",
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "IP",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 6,
    key: "ip",
  },
];
