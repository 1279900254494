export default [
  {
    key: 'from',
    label: 'From',
    thClass: 'text-left',
    tdClass: 'text-left',
    sortable: false,
    visible: true,
  },
  {
    key: 'to',
    label: 'To',
    thClass: 'text-left',
    tdClass: 'text-left',
    sortable: false,
    visible: true,
  },
  {
    key: 'status',
    label: 'RC Status',
    thClass: 'text-left',
    tdClass: 'text-left',
    sortable: false,
    visible: true,
  },
  {
    key: 'details',
    label: 'Details',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
    visible: true,
    // thStyle: { width: "250px" },
  },

];
