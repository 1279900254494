import store from '@/store'
import Vue from "vue";

const UnavailableBalanceNotification = () => {
  window.socket.bind('report-unavailable-balance-debtsolution', async ({ to_id, message, order_payment_status, client_name, creditor_name }) => {
    const currentSession = store.state.auth.currentUser.user_id
    if (to_id.includes(currentSession)) {
      Vue.swal.fire({
        icon: "info",
        title: "Payment Order",
        html: `<div>
                <h4>${message}</h2>
                <h3>Status: ${order_payment_status}</h3>
                <h5>Client: ${client_name}</h5>
                <h5>Creditor: ${creditor_name}</h5>
               </div>`,
        showConfirmButton: true,
        confirmButtonText: "Accept",
      });
    }
  })
}

export default UnavailableBalanceNotification

