import CategoriesNavigation from "@/views/management/views/categories/categories.navigation";
import EmployeesNavigation from "@/views/management/views/employees/employees.navigation";
import ChargeBackNavigation from "@/views/management/views/charge-back/charge-back.navigation";
import PayrollNavigation from "@/views/management/views/payroll/payroll.navigation";
import CompaniesNavigation from "@/views/management/views/companies/companies.navigation";
import ReportsNavigation from "@/views/commons/components/reports/reports.navigation";
import FileManagerNavigation from "@/views/management/views/file-manager/file-manager.nav";
import CommissionsNavigation from "@/views/management/views/commissions/commissions.navigation";
import LoansNavigation from "@/views/management/views/loans/loans.nav";
import SchedulesNavigation from "@/views/management/views/schedules/schedules.nav";
import HelpdeskNavigation from "@/views/management/views/helpdesk/helpdesk.nav";
import ProductRequestNavigation from "@/views/management/views/request/request.nav.js";
import RiskyClientsNavigation from "@/views/management/views/risky-clients/risky.navigation";
import ApprovalProcessNavigation from "@/views/management/views/approval-process/approval-process.navigation";
import SalaryRecordNavigation from "@/views/management/views/salary-record/salary-record.navigation.js";
import SettingsNavigation from "@/views/management/views/settings/navigation/index";
import OtherPaymentsNavigation from "@/views/management/views/other-payments/other-payments.nav";
import ClaimsNavigation from "@/views/management/views/claims/claims.nav";
import ManagementAttendanceNavigation from "@/views/management/views/attendance/management-attendance.navigation";
import ManagementPayrollPaymentsNavigation from "@/views/management/views/payroll-payments/management-payroll-payments.navigation";
import EmployeeClaimsNavigation from "@/views/management/views/employee-claims/navigation/employee-claims.nav";
import DashboardClaimsNavigation from "@/views/management/views/dashboard-claims/dashboard-claims.navigation.js";
import SearchPaymentNavigation from "@/views/management/views/search-payments/search-payments.navigation";
import requestTransactionNavigation from "@/views/management/views/requests-transactions/request-transactions.nav";
import RingCentralNav from "@/views/management/views/ring-central/ring-central.nav.js";
import MOFNavigation from "@/views/management/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/management/views/recruitment-process/management-recruitment_process.navigation";
import OrganizationChart from "@/views/management/views/organization-chart/organization-chart.navigation.js";
import DepartmentsNavigation from "@/views/management/views/departments/navigation/departments.nav.js";
import CustomerTicketsNavigation from "@/views/management/views/customer-tickets/management-customer-tickets.navigation";
import requestClientStatusNavigation from "@/views/management/views/request-client-status/navigation/request-client-status.nav.js";
import ParagonSoftNavigation from "@/views/management/views/paragon-soft/paragon-soft.nav.js";
import InconsistentTransactionNavigation from '@/views/management/views/inconsistent-transactions/navigation/inconsistent-transactions.nav.js';
import UsersLogNavigation from '@/views/management/views/users-log/navigation/users-log.navigation';
import CallImpressionNavigation from "@/views/management/views/call-impression/navigation/call-impression.navigation.js";
import SalesmadeNavigation from "@/views/management/views/sales-made/salesmade.nav.js"
import DashboardNavigation from "@/views/management/views/dashboard/navigation/dashboard.nav.js"

// Clients
import ClientsNavigation from "@/views/management/views/clients/clients-group.navigation";

const navigation = [
  {
    header: "MANAGEMENT",
  },
  DashboardNavigation,
  ClientsNavigation,
  requestTransactionNavigation,
  ...CompaniesNavigation,
  ...DepartmentsNavigation,
  ...CategoriesNavigation,
  ...SalesmadeNavigation,
  ...ChargeBackNavigation,
  ...ClaimsNavigation,
  ...FileManagerNavigation,
  ...ReportsNavigation,
  ...EmployeesNavigation,
  ...PayrollNavigation,
  ...CommissionsNavigation,
  ...LoansNavigation,
  HelpdeskNavigation,
  ...RiskyClientsNavigation,
  ...SearchPaymentNavigation,
  ProductRequestNavigation,
  ApprovalProcessNavigation,
  ...OtherPaymentsNavigation,
  ManagementAttendanceNavigation,
  ...SalaryRecordNavigation,
  ParagonSoftNavigation,
  ...SettingsNavigation,
  ManagementPayrollPaymentsNavigation,
  ...DashboardClaimsNavigation,
  RingCentralNav,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  OrganizationChart,
  CustomerTicketsNavigation,
  ...requestClientStatusNavigation,
  ...InconsistentTransactionNavigation,
  UsersLogNavigation,
  ...CallImpressionNavigation,
];

export default navigation;
