import { amgApi } from "@/service/axios";

class AppointmentsService{

    async getAllAppointmentsToday(params){
        const data = await amgApi.post("/crm/appointments-list/get-all-appointments-for-crm",params)
        return data;
    }

    async getAllAppointmentsRecovery(params){
        const data = await amgApi.post("/crm/appointments-list/get-all-appointments-for-crm-recovery",params)
        return data;
    }

    async getAllAppointmentsDone(params){
        const data = await amgApi.post("/crm/appointments-list/get-all-appointments-for-crm-done",params)
        return data;
    }

    async getAllMotives(params){
        const data = await amgApi.post("/lead/social-network/settings/get-motive-loss-by-type",params)
        return data;
    }

    async saveTaskAppointments(params){
        const data = await amgApi.post("/tasks/create-task-lead",params)
        return data
    }

    async saveMotiveAppointments(params){
        const data = await amgApi.post("/crm/appointments-list/save-all-events-motives",params)
        return data
    }

    async updateStatusAppointments(params){
        const data = await amgApi.post("/crm/appointments-list/update-status-appointments-for-crm",params)
        return data
    }

    async getAllPrograms() {
        const data = await amgApi.get("/program/get-programs");
        return data.data;
    }

    async getCountAppointmentPending(params){
        const data = await amgApi.post("/crm/appointments-list/get-count-appointment-pending",params);
        return data;
    }

    async getCountAppointmentTodayAndRecovery(params){
        const data = await amgApi.post("/crm/appointments-list/get-count-appointment-today-recovery",params);
        return data;
    }

    async updateRecoveryTodayMotive(params){
        const data = await amgApi.post("/crm/appointments-list/update-recovery-today-motive",params);
        return data;
    }
}

export default new AppointmentsService()