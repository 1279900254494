<template>
  <div>
    <b-table
      id="CreditCardExpensesTable"
      ref="CreditCardExpensesTable"
      small
      show-empty
      sticky-header="50vh"
      class="table-new-customization custom-table font-small-3 justify-content-start blue-scrollbar"
      :items="expensesData"
      :fields="visibleFields"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(expenses_date)="{ item }">
        <div class="text-info">
          <feather-icon icon="CalendarIcon" style="margin-right: 5px" />
          {{ item.expenses_date | myGlobal }}
        </div>
      </template>
      <template #cell(vendor)="{ item }">
        <div class="text-warning">
          {{ item.vendor }}
        </div>
      </template>
      <template #cell(method)="{ item }">
        <template v-if="item.method == 'CREDIT_CARD'">
          <div class="row">
            <div class="col-4 p-0 justify-content-end d-flex">
              <type-card :type_card="item.type_card" />
            </div>
            <div
              class="col-8 p-0 d-flex justify-content-center align-items-center"
            >
              <strong>XXXX-{{ item.cardnumber.slice(-4) }}</strong>
            </div>
          </div>
        </template>
        <template v-else>
          <b-badge variant="primary">{{ item.method }}</b-badge>
        </template>
      </template>
      <template #cell(amount)="{ item }"> $ {{ item.amount }}</template>
      <template #cell(details)="{ item }">
        <b-row>
          <b-col cols="2"><strong>Pay to</strong></b-col>
          <b-col>{{ item.payable }}</b-col>
          <div class="w-100" />
          <b-col cols="2"><strong>Memo</strong></b-col>
          <b-col>{{ item.description }}</b-col>
          <div class="w-100" />
          <b-col cols="2" v-if="method == 'CHECK'"><strong>Address</strong></b-col>
          <b-col v-if="method == 'CHECK'">
            <span v-html="formatAddress(item)"></span>
          </b-col>
          <div class="w-100" />
          <b-col cols="2"><strong>Date</strong></b-col>
          <b-col>{{ item.payment_date | myGlobal }}</b-col>
          <div class="w-100" />
          <b-col v-if="item.url_file" cols="2">
            <strong>File</strong>
          </b-col>
          <b-col v-if="item.url_file">
            <a :href="item.url_file" target="_blank" :title="item.file">
              <amg-icon
                class="cursor-pointer text-danger"
                icon="FilePdfIcon"
                size="18"
              />
            </a>
          </b-col>
        </b-row>
      </template>
      <template #cell(requested_by)="{ item }">
        <span class="d-block">{{ item.requested_by }}</span>
        <span class="d-block">{{ item.requested_at | myGlobalWithHour }} </span>
      </template>
      <template #cell(updated_by)="{ item }">
        <span class="d-block">{{ item.updated_by }} </span>
        <span class="d-block">{{ item.updated_at | myGlobalWithHour }} </span>
        <feather-icon
          v-if="item.observation && tab == 3"
          icon="InfoIcon"
          v-b-tooltip.hover.top="item.observation"
          variant="info"
          class="text-info"
          style="margin-left: 3px"
        />
      </template>
      <template #cell(status_check)="{ item }">
        <span :class="colorTextStatusCheck(item)" style="font-weight: bold">
          {{ statusCheckText(item) }}
        </span>
      </template>
      <template #cell(tracking)="{ item }">
        <div>
          <feather-icon
            class="text-info cursor-pointer"
            v-b-tooltip.hover.right="'Tracking'"
            @click="openChecksTrackingModal(item)"
            size="16"
            icon="ListIcon"
          />
        </div>
      </template>
      <template v-if="tab === 2" #bottom-row>
        <b-th> </b-th>
        <b-th class="text-center py-0">
          <div class="m-0 p-1" style="font-weight: 600">TOTAL</div>
        </b-th>
        <b-th class="text-center py-0">
          <div class="m-0 p-1" style="font-weight: 600">
            {{ total | formatMoney }}
          </div>
        </b-th>
        <b-th :colspan="4"> </b-th>
      </template>
    </b-table>
    <ChecksTrackingModal
      v-if="showChecksTrackingModal"
      :dataRow="selectedCheck"
      @close="showChecksTrackingModal = false"
    />
  </div>
</template>
<script>
import DashboardServices from "@/views/commons/components/clients/dashboard/options/pay-module/components/service/dashboard.service.js";
import Fields from "@/views/commons/components/clients/dashboard/options/pay-module/components/checks-expenses.fields.js";
import { mapGetters, mapActions } from "vuex";
import TypeCard from "@/views/commons/card/TypeCard.vue";
import ChecksTrackingModal from "@/views/commons/expenses/components/Modal/ChecksTrackingModal.vue";
import { modifyVisibility } from "@/helpers/fields-table";

export default {
  components: {
    TypeCard,
    ChecksTrackingModal,
  },
  props: {
    tab: {
      type: Number,
      required: true,
    },
    caId: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fields: Fields,
      expensesData: [],
      isBusy: false,
      showChecksTrackingModal: false,
      selectedCheck: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_REFRESH_AFTER_CREATE_EXPENSE:
        "ExpensesRequest/G_REFRESH_AFTER_CREATE_EXPENSE",
    }),
    visibleFields() {
      modifyVisibility(this.fields, "rejected_by", this.tab == 3);
      modifyVisibility(this.fields, "updated_at", this.tab == 2);
      if(this.method == 'E-CHECK'){
        modifyVisibility(this.fields, "check_number", this.tab == 2);
      } else {
        modifyVisibility(this.fields, "check_number", true);
      }
      return this.fields.filter((field) => field.visible);
    },
    total() {
      return this.expensesData.length > 0 ? this.expensesData[0].total : 0;
    },
  },
  async created() {
    await this.getExpenses();
  },
  watch: {
    tab: function (value) {
      let field = this.fields.find((field) => field.key == "updated_by");
      if (value == 2) {
        field.visible = true;
        field.label = "Confirmed at";
      } else if (value == 3) {
        field.visible = true;
        field.label = "Rejected at";
      } else {
        field.visible = false;
      }
      this.getExpenses();
    },
    G_REFRESH_AFTER_CREATE_EXPENSE(newVal) {
      if (newVal) {
        this.getExpenses();
        this.refreshAfterCreateExpense(false);
      }
    },
    method: function (value) {
      this.getExpenses();
    }
  },
  methods: {
    colorTextStatusCheck(item) {
      switch (item.status_check) {
        case "PENDING":
          return "text-warning";
        case "SEND TO COURIER":
          return "text-primary";
        case "SEND TO CORRESPONDENCE":
          return "text-info";
        case "REJECTED":
          return "text-danger";
        case "COLLECTED":
          return "text-success";
        case "PENDING COLLECT":
          return "text-warning";
        default:
          return "text-warning";
      }
    },
    statusCheckText(item) {
      switch (item.status_check) {
        case "PENDING":
          return "IN CEO";
        case "SEND TO CORRESPONDENCE":
          return "IN CORRESPONDENCE";
        case "SEND TO COURIER":
          return "IN COURIER";
        case "PENDING COLLECTION":
          return "PENDING COLLECT";
        default:
          return item.status_check;
      }
    },
    openChecksTrackingModal(item) {
      this.showChecksTrackingModal = true;
      this.selectedCheck = item;
    },
    ...mapActions({
      refreshAfterCreateExpense: "ExpensesRequest/refreshAfterCreateExpense",
    }),
    async getExpenses() {
      const params = {
        tab: this.tab,
        module_id: this.currentUser.modul_id,
        caId: this.caId,
        method: this.method
      };
      try {
        this.addPreloader();
        const { data } = await DashboardServices.getCheckExpenses(params);
        this.expensesData = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.b-table-bottom-row th:nth-child(2) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: 5px;
  background: #0090e7 !important;
  color: #ffffff !important;
}

.b-table-bottom-row th:nth-child(3) {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: -10px;
  background: #0090e7 !important;
  color: #ffffff !important;
}
</style>
