export default [
  {
    path: "/credit-experts-digital-dashboard-client/:idClient",
    name: "ce-customer-service-dashboard-general",
    redirect: { name: "personal-information-ce-customer-service-general" },
    component: () =>
      import(
        /* webpackChunkName: "CEDCustomerServiceClientsAccount" */ "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/AccountCustomerService.vue"
      ),
    props: true,
    meta: {
      moduleName: "credit-experts-digital",
      general: true,
      module: 22,
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "dis",
        name: "dis-ce-customer-service-general",
        redirect: { name: "report-detail-cs-general" },
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountDis" */ "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        props: true,
        meta: {
          module: 22,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Update",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "dispute-detail",
            name: "dispute-detail-cs-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/DisputeDetail.vue"
              ),
            meta: {
              pageTitle: "Dispute Detail",
              breadcrumb: [
                {
                  text: "Dispute Detail",
                  active: true,
                },
              ],
            },
          },
          {
            path: "report-detail",
            name: "report-detail-cs-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
              ),
            meta: {
              pageTitle: "Report Detail",
              breadcrumb: [
                {
                  text: "Report Detail",
                  active: true,
                },
              ],
            },
          },
          {
            path: "updates-detail",
            name: "updates-detail-cs-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/UpdateDetail.vue"
              ),
            meta: {
              pageTitle: "Updates Detail",
              breadcrumb: [
                {
                  text: "Updates Detail",
                  active: true,
                },
              ],
            },
          },
            {
            path: "workplan-detail",
            name: "workplan-detail-cs-general",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Workplan Detail",
              breadcrumb: [
                {
                  text: "Workplan Detail",
                  active: true,
                },
              ],
            },
          },
            {
            path: "recommendation-detail",
            name: "recommendation-detail-cs-general",
            component: () =>
              import(
                /* webpackChunkName: "recommendationDetail" */   "@/views/commons/components/clients/dashboard/options/services/services.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Recommendations",
              breadcrumb: [
                {
                  text: "Recommendations",
                  active: true,
                },
              ],
            },
          },
          {
            path: "analysis",
            name: "analysis-cr-general",
            component: () =>
              import(
                /* webpackChunkName: "AnalysisCr" */ "@/views/commons/components/clients/dashboard/analysis/AccountsClients.vue"
              ),
            meta: {
              module: 22,
              pageTitle: "Client",
              breadcrumb: [
                {
                  text: "Analysis CR",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "acr",
        name: "acr-ce-customer-service-general",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "pay",
        name: "pay-ce-customer-service-general",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "task-notes",
        name: "task-notes-ce-customer-service-general",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotesNew.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information",
        name: "personal-information-ce-customer-service-general",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountPersonalInformation" */ "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/information-client/CustomerServiceClientInformation.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files",
        name: "client-files-ce-customer-service-general",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "report",
        name: "report-ce-customer-service-general",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountReport" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Report",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log",
        name: "call-log-client-ce-customer-service-general",
        // redirect: { name: 'credit-experts-digital-client-general-call' },
        component: () =>
          import(
            "@/views/business/views/clients/dashboard/views/components/sections/CallLogSubtabs.vue"
          ),
        props: true,
        meta: {
          module: 22,
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
        },
        // children: [
        //   {
        //     path: 'general-call',
        //     name: 'credit-experts-digital-client-general-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'General',
        //       breadcrumb: [
        //         {
        //           text: 'General',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     path: 'ceo-call',
        //     name: 'credit-experts-digital-client-ceo-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/CeoCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'CEO calls',
        //       breadcrumb: [
        //         {
        //           text: 'CEO calls',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        path: "tickets",
        name: "client-tickets-ce-customer-service-general",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          module: 22,
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "leads/report/:idlead/:idfile",
    name: "ce-customer-service-report-lead-general",
    component: () =>
      import(
        /* webpackChunkName: "CreditExpertsLeadsReport" */ "@/views/commons/components/ncr/components/report/ReportLead.vue"
      ),
    props: true,
    meta: {
      module: 22,
      isClientsTab: true,
      pendingRoute: "ncr-pending",
      returnedRoute: "ncr-returned",
      completedRoute: "ncr-completed",
      reportLeadRoute: "report-lead",
      leadRoute: "lead-show",
    },
  },
];
