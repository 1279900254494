import store from "@/store";
import ConnectionService from "@/views/ce-digital/sub-modules/connection/views/clients/services/connection.service.js"
export default {
    namespaced: true,
    state: {
        S_UPDATE_MODAL: false,
        S_COUNTER_RECYCLE: null,
        S_COUNTER_HOLD: null,
        S_COUNTER_LOYAL: null,
        S_COUNTER_TOTAL: null,
        S_COUNTER_LOYAL_WITHOUT_READING: null,
        S_COUNTER_HOLD_WITHOUT_READING: null,
        S_COUNTER_LOYAL_WITHOUT_READING_RESULT: null,
        S_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT: null,

        S_CE_GRID_COUNTERS: [],
    },

    mutations: {
        M_UPDATE_MODAL(state, payload) {
            state.S_UPDATE_MODAL = payload
        },
        M_COUNTER_RECYCLE(state, params) {
            state.S_COUNTER_RECYCLE = params
        },
        M_COUNTER_HOLD(state, params) {
            state.S_COUNTER_HOLD = params
        },
        M_COUNTER_LOYAL(state, params) {
            state.S_COUNTER_LOYAL = params
        },
        M_COUNTER_TOTAL(state, params) {
            state.S_COUNTER_TOTAL = params
        },
        M_COUNTER_LOYAL_WITHOUT_READING(state, params) {
            state.S_COUNTER_LOYAL_WITHOUT_READING = params
        },
        M_COUNTER_HOLD_WITHOUT_READING(state, params) {
            state.S_COUNTER_HOLD_WITHOUT_READING = params
        },
        M_COUNTER_LOYAL_WITHOUT_READING_RESULT(state, params) {
            state.S_COUNTER_LOYAL_WITHOUT_READING_RESULT = params
        },
        M_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT(state, params) {
            state.S_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT = params
        },
        M_CE_GRID_COUNTERS(state, params) {
            state.S_CE_GRID_COUNTERS = params
        }
    },

    actions: {
        A_UPDATE_MODAL({ commit }, params) {
            commit('M_UPDATE_MODAL', params)
        },
        A_SET_COUNTER_RECYCLE({ commit }, params) {
            commit('M_COUNTER_RECYCLE', params)
        },
        A_SET_COUNTER_HOLD({ commit }, params) {
            commit('M_COUNTER_HOLD', params)
        },
        A_SET_COUNTER_LOYAL({ commit }, params) {
            commit('M_COUNTER_LOYAL', params)
        },
        A_SET_COUNTER_TOTAL({ commit }, params) {
            commit('M_COUNTER_TOTAL', params)
        },
        A_SET_COUNTER_LOYAL_WITHOUT_READING({ commit }, params) {
            commit('M_COUNTER_LOYAL_WITHOUT_READING', params)
        },
        A_SET_COUNTER_HOLD_WITHOUT_READING({ commit }, params) {
            commit('M_COUNTER_HOLD_WITHOUT_READING', params)
        },
        A_SET_COUNTER_LOYAL_WITHOUT_READING_RESULT({ commit }, params) {
            commit('M_COUNTER_LOYAL_WITHOUT_READING_RESULT', params)
        },
        A_SET_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT({ commit }, params) {
            commit('M_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT', params)
        },
        async A_GET_CE_GRID_COUNTERS({ commit }, params) {
            try {
                const { data } = await ConnectionService.getConnectionClientsCounter(params)
                commit('M_CE_GRID_COUNTERS', data)
                return data
            } catch (error) {
                console.error(error)
            }
        }
    },

    getters: {
        G_COUNTER_RECYCLE_COUNTER(state) {
            return state.S_COUNTER_RECYCLE
        },
        G_UPDATE_MODAL(state) {
            return state.S_UPDATE_MODAL
        },
        G_COUNTER_HOLD_COUNTER(state) {
            return state.S_COUNTER_HOLD
        },
        G_COUNTER_LOYAL_COUNTER(state) {
            return state.S_COUNTER_LOYAL
        },
        G_COUNTER_TOTAL_COUNTER(state) {
            return state.S_COUNTER_TOTAL
        },
        G_COUNTER_LOYAL_WITHOUT_READING(state) {
            return state.S_COUNTER_LOYAL_WITHOUT_READING
        },
        G_COUNTER_HOLD_WITHOUT_READING(state) {
            return state.S_COUNTER_HOLD_WITHOUT_READING
        },
        G_COUNTER_LOYAL_WITHOUT_READING_RESULT(state) {
            return state.S_COUNTER_LOYAL_WITHOUT_READING_RESULT
        },
        G_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT(state) {
            return state.S_COUNTER_LOYAL_WITHOUT_READING_NO_RESULT
        },
        G_CE_GRID_COUNTERS(state) {
            return state.S_CE_GRID_COUNTERS
        }

    }
}