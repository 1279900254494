export default [
  {
    key: "pay_period",
    label: "#",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "due_date",
    label: "Due Date",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "monthly_payment",
    label: "Monthly Payment",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "amount_paid",
    label: "Amount Paid",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "settlement_date_authorize",
    label: "Settlement Date",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "status",
    label: "Status",
    sortable: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
];
