export default [
  {
    path: "register-payment",
    name: "adm-register-payments",
    redirect: { name: "adm-register-payments-automatic" },
    component: () =>
      import(
        /* webpackChunkName: "AdministrationRegisterPaymentsMain" */ "@/views/administration/views/register-payment/RegisterPayment.vue"
      ),
    meta: {
      pageTitle: "Register Payments",
      permittedRoles: [1, 2, 6],
      breadcrumb: [
        {
          text: "Register Payments",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "automatic",
        name: "adm-register-payments-automatic",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationAutomaticTab" */ "@/views/administration/views/register-payment/tabs/Automatic.vue"
          ),
        meta: {
          pageTitle: "Register Payments",
          permittedRoles: [1, 2, 6],
          breadcrumb: [
            {
              text: "Automatic",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "history-imports",
            name: "adm-history-imports",
            component: () =>
              import(
                /* webpackChunkName: "AdministrationHistoryImports" */ "@/views/administration/views/register-payment/components/HistoryImport.vue"
              ),
            meta: {
              pageTitle: "Register Payments",
              permittedRoles: [1, 2, 6],
              breadcrumb: [
                {
                  text: "Automatic",
                  active: false,
                  to: { name: "adm-register-payments-automatic" },
                },
                {
                  text: "History Imports",
                  active: true,
                },
              ],
            },
          },
          {
            path: "import",
            name: "adm-history-automatic-import",
            component: () =>
              import(
                /* webpackChunkName: "AdministrationAutomaticImport" */ "@/views/administration/views/register-payment/components/automatic/AutomaticImport.vue"
              ),
            meta: {
              pageTitle: "Register Payments",
              permittedRoles: [1, 2, 6],
              breadcrumb: [
                {
                  text: "Automatic",
                  active: false,
                  to: { name: "adm-register-payments-automatic" },
                },
                {
                  text: "Import",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "manual",
        name: "adm-register-payments-manual",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationRegisterPaymentManual" */ "@/views/administration/views/register-payment/tabs/Manual.vue"
          ),
        children: [
          {
            path: "process",
            name: "adm-manual-process-payment",
            component: () =>
              import(
                /* webpackChunkName: "AdministrationProcessManualPayment" */ "@/views/administration/views/register-payment/components/ProcessManualPayment.vue"
              ),
            meta: {
              pageTitle: "Register Payments",
              permittedRoles: [1, 2, 6],
              breadcrumb: [
                {
                  text: "Manual",
                  active: false,
                  to: { name: "adm-register-payments-manual" },
                },
                {
                  text: "Process",
                  active: true,
                },
              ],
            },
          },
        ],
        meta: {
          pageTitle: "Register Payments",
          permittedRoles: [1, 2, 6],
          breadcrumb: [
            {
              text: "Manual",
              active: true,
            },
          ],
        },
      },
      {
        path: "others",
        name: "adm-register-payments-others",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationOthersTab" */ "@/views/administration/views/register-payment/tabs/Others.vue"
          ),
        redirect: { name: "adm-history-imports-others-by-mail" },
        meta: {
          pageTitle: "Register Payments",
          permittedRoles: [1, 2, 6],
          breadcrumb: [
            {
              text: "Others",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "by-mail",
            name: "adm-history-imports-others-by-mail",
            component: () =>
              import(
                /* webpackChunkName: "AdministrationOthersByMail" */ "@/views/administration/views/register-payment/components/others/RegisterPaymentOtherByMail.vue"
              ),
            meta: {
              pageTitle: "Register Payments",
              permittedRoles: [1, 2, 6],
              breadcrumb: [
                {
                  text: "Others",
                  active: false,
                  to: { name: "adm-register-payments-others" },
                },
                {
                  text: "By Mail",
                  active: true,
                },
              ],
            },
          },
          {
            path: "by-cash",
            name: "adm-history-imports-others-by-cash",
            component: () =>
              import(
                /* webpackChunkName: "AdministrationOthersByCash" */ "@/views/administration/views/register-payment/components/others/RegisterPaymentOtherByCash.vue"
              ),
            meta: {
              pageTitle: "Register Payments",
              permittedRoles: [1, 2, 6],
              breadcrumb: [
                {
                  text: "Others",
                  active: false,
                  to: { name: "adm-register-payments-others" },
                },
                {
                  text: "By Cash",
                  active: true,
                },
              ],
            },
          },
          {
            path: "old",
            name: "adm-history-imports-others-old",
            component: () =>
              import(
                /* webpackChunkName: "AdministrationOthersOld" */ "@/views/administration/views/register-payment/components/others/RegisterPaymentOtherOld.vue"
              ),
            meta: {
              pageTitle: "Register Payments",
              permittedRoles: [1, 2, 6],
              breadcrumb: [
                {
                  text: "Others",
                  active: false,
                  to: { name: "adm-register-payments-others" },
                },
                {
                  text: "Old",
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },
]
