<template>
    <svg :width="width" :height="height" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="29.9299" cy="29.9299" r="29.9299" fill="url(#paint0_linear_205_3)" />
        <path
            d="M38.0113 46.04C37.4042 46.04 36.7845 46.04 36.1774 46.04C35.4059 46.0273 35.1403 45.8492 35.1276 45.0991C35.115 44.4379 34.8115 44.2981 34.255 44.4125C33.6858 44.5269 33.1167 44.3871 32.5475 44.3743C32.1428 44.3743 31.9405 44.4633 31.8899 44.9211C31.776 45.9637 31.6749 46.04 30.6251 46.04C29.4236 46.04 28.2221 46.0527 27.0206 46.04C26.3503 46.04 26.0973 45.7857 26.072 45.1372C26.0088 43.5225 25.389 42.1874 24.314 40.9668C22.5433 38.9452 21.443 36.5802 21.3039 33.8593C21.228 32.5115 21.5062 31.2146 21.9868 29.9432C22.1513 29.5109 22.341 29.46 22.7204 29.727C24.3014 30.8459 26.0847 31.4435 27.9944 31.4435C31.7381 31.4435 34.6344 29.816 36.6201 26.5992C37.1133 25.7982 37.4169 24.9209 37.6825 24.0181C38.1504 22.3525 40.3005 20.8522 41.9953 21.0047C42.615 21.0556 42.9565 21.6659 42.6656 22.2762C42.4 22.8356 42.1217 23.3697 42.0206 23.9927C41.8941 24.7937 42.0206 25.4549 42.6909 26.027C43.8924 27.0824 44.803 28.3793 45.3848 29.8923C45.5492 30.3119 45.7769 30.4645 46.2322 30.4645C47.3831 30.4645 47.889 30.9985 47.9017 32.1301C47.9143 33.0201 47.9143 33.9101 47.9017 34.8002C47.889 35.8555 47.3958 36.3895 46.3587 36.3768C45.8907 36.3768 45.6504 36.5167 45.4733 36.9744C44.8663 38.64 43.8544 40.0386 42.5265 41.1957C41.4641 42.1238 40.9076 43.2809 40.9329 44.7176C40.9582 45.9255 40.8064 46.0527 39.5796 46.0527C39.0737 46.0527 38.5425 46.04 38.0113 46.04ZM41.1732 30.6933C41.6791 30.6933 42.1217 30.2483 42.1344 29.727C42.147 29.1549 41.717 28.7353 41.1352 28.748C40.5914 28.7607 40.174 29.193 40.1993 29.7143C40.212 30.2102 40.6799 30.6933 41.1732 30.6933Z"
            fill="white" />
        <path
            d="M28.2348 13.4014C32.2441 13.2615 36.4431 16.58 36.4684 21.6786C36.481 26.2432 32.7753 29.8923 28.2475 29.8923C23.6943 29.8923 20.0139 26.1923 20.0139 21.615C20.0139 17.0632 23.6817 13.4014 28.2348 13.4014ZM28.1716 27.1459C28.6016 27.2095 28.7913 27.0061 28.7787 26.5738C28.766 26.1542 28.8419 25.7982 29.3225 25.6202C30.3217 25.2642 30.9414 24.5776 31.0046 23.4714C31.0679 22.2762 30.4481 21.526 29.4237 21.0302C28.8925 20.7759 28.3487 20.547 27.8301 20.28C27.4886 20.102 27.261 19.8096 27.3495 19.4027C27.438 18.9577 27.7921 18.8051 28.1842 18.7542C28.7407 18.6907 29.2846 18.7797 29.7905 18.9958C30.132 19.1484 30.3343 19.0594 30.4608 18.7034C30.7517 17.8769 30.6379 17.6353 29.7272 17.4446C29.2087 17.3429 28.7913 17.2158 28.8419 16.5419C28.8672 16.0969 28.5257 16.1223 28.2222 16.1223C27.9313 16.1223 27.6277 16.1477 27.653 16.5419C27.7036 17.1522 27.3874 17.4446 26.8562 17.6735C26.0341 18.0168 25.5788 18.6525 25.503 19.568C25.4144 20.5343 25.8824 21.1827 26.6412 21.6659C27.1598 21.9965 27.7542 22.1618 28.3107 22.4288C28.9937 22.7594 29.3099 23.1917 29.1454 23.6748C28.9304 24.3106 28.3866 24.425 27.8175 24.4123C27.261 24.3996 26.7298 24.247 26.2365 24.0181C25.9077 23.8655 25.7433 23.9545 25.6294 24.2978C25.3006 25.2006 25.3765 25.3659 26.3124 25.6329C26.4768 25.6837 26.6412 25.7219 26.8057 25.7473C27.2989 25.8109 27.5771 26.0525 27.5392 26.5865C27.5013 27.0442 27.7542 27.1968 28.1716 27.1459Z"
            fill="white" />
        <defs>
            <linearGradient id="paint0_linear_205_3" x1="4.81016" y1="-1.06893" x2="59.8598" y2="59.8598"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.117499" stop-color="#67E1FF" />
                <stop offset="0.822018" stop-color="#27A6ED" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            required: false,
            default: "30"
        },
        height: {
            type: String,
            required: false,
            default: "30"
        }
    }
}
</script>
  