import { sumTag } from '@/@core/utils/utils.js';
import customerTickets from '@/views/quality/views/customer-tickets/quality-customer-tickets.navigation';
import clients from '@/views/quality/views/clients/clients.nav';

export default {
  title: 'Clients',
  icon: 'UsersIcon',
  children: [clients, customerTickets],
  tag: sumTag([clients, customerTickets]),
};
