export default [
  {
    path: "leads",
    name: "leads-bussiness",
    component: () =>
      import(/* webpackChunkName: "BusinessLead" */ "@/views/commons/components/lead-programs/Lead.vue"),
    redirect: { name: "bussiness-leads-list" },
    meta: {
      route: "bussiness",
      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Leads",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "bussiness-leads-list",
        component: () =>
          import(
            /* webpackChunkName: "BusinessLeadsList" */ "@/views/commons/components/lead-programs/components/lead-table/LeadTable.vue"
          ),
        meta: {
          route: "bussiness",
          isClientsTab: true,
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
              to: "/bussiness/leads",
              active: true,
            },
          ],
        },
      },
      {
        path: "leads/show/:id",
        name: "bussiness-lead-show",
        component: () =>
          import(/* webpackChunkName: "BusinessLeadShow" */ "@/views/crm/views/Lead/lead-module/dashboard/default.vue"),
        props: true,
        meta: {
          isClientsTab: true,
          route: "bussiness",
          contentClass: "leads-app",
        },
      },
    ],
  },
]
