import UserService from "@/service/user/user.service"
export default {
  namespaced: true,
  state: {
    S_USER_SESSIONS: [],
    S_USER_STATUS_SESSION: null,
    S_USER_STATUS_SESSION_SUB_MODULE: null,
    S_USER_STATUS_BUSY_ARGUMENT: null,
    S_USER_STATUS_ESSENTIAL_DOCS: null,
  },
  getters: {
    G_USER_STATUS_SESSION(state) {
      return state.S_USER_STATUS_SESSION
    },
    G_USER_STATUS_SESSION_SUB_MODULE(state) {
      return state.S_USER_STATUS_SESSION_SUB_MODULE
    },
    G_USER_STATUS_ESSENTIAL_DOCS(state) {
      return state.S_USER_STATUS_ESSENTIAL_DOCS
    },
  },
  mutations: {
    SET_USER_SESSIONS(state, payload) {
      state.S_USER_SESSIONS = payload
    },
    SET_USER_STATUS_SESSION(state, payload) {
      state.S_USER_STATUS_SESSION = payload[0].status_session
    },
    SET_USER_STATUS_SESSION_SUB_MODULE(state, payload) {
      state.S_USER_STATUS_SESSION_SUB_MODULE = payload[0]?.status_session
    },
    SET_USER_STATUS_ESSENTIAL_DOCS(state, payload) {
      state.S_USER_STATUS_ESSENTIAL_DOCS = payload
    },
  },
  actions: {
    async A_GET_USER_SESSIONS({ commit }, body) {
      try {
        const response = await UserService.getUserSessions(body)
        commit("SET_USER_SESSIONS", response.data.data)
        return response
      } catch (error) {
        throw error
      }
    },
    async A_GET_USER_STATUS_SESSION({ commit }, body) {
      try {
        const response = await UserService.getUserStatusSession(body)
        commit("SET_USER_STATUS_SESSION", response.data)
        return response
      } catch (error) {
        throw error
      }
    },
    async A_GET_USER_STATUS_SUBMODULE_SESSION({ commit }, body) {
      try {
        const response = await UserService.getUserStatusSubModuleSession(body)
        commit("SET_USER_STATUS_SESSION_SUB_MODULE", response.data)
        return response
      } catch (error) {
        throw error
      }
    },
    async A_CHANGE_USER_STATUS_SESSION({ commit }, body) {
      try {
        const response = await UserService.changeUserStatusSession(body)
        return response
      } catch (error) {
        throw error
      }
    },
    async A_CHANGE_USER_STATUS_SESSION_SUB_MODULE({ commit }, body) {
      try {
        const response = await UserService.changeUserStatusSessionSubModule(
          body
        )
        return response
      } catch (error) {
        throw error
      }
    },
    async A_SAVE_ARGUMENT_MOTIVE_BUSY({ commit }, body) {
      try {
        const response = await UserService.saveArgumentMotiveOther(body)
        return response
      } catch (error) {
        throw error
      }
    },
    async A_SAVE_USER_STATUS_ESSENTIAL_DOCS({ commit }, body) {
      commit('SET_USER_STATUS_ESSENTIAL_DOCS', body);
    },
  },
}
