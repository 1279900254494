<template>
    <svg :width="width" :height="height" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="29.9299" cy="29.9299" r="29.9299" fill="url(#paint0_linear_201_2)" />
        <path
            d="M29.8652 41.3658C28.1601 41.3658 26.467 41.3538 24.7619 41.3779C24.3416 41.3779 24.1135 41.2331 23.9214 40.8588C22.3844 37.7203 20.8354 34.5938 19.2744 31.4552C19.1543 31.2138 19.0342 30.9965 19.2984 30.755C20.8234 29.3185 22.5165 28.2321 24.7139 28.2563C26.2749 28.2683 27.6678 28.7633 28.8325 29.8135C29.3248 30.2601 29.8532 30.4533 30.5136 30.4291C31.8344 30.3929 33.1553 30.417 34.4761 30.417C35.6169 30.4291 36.3974 31.1896 36.3974 32.276C36.3974 33.3504 35.6048 34.1109 34.4521 34.123C33.3714 34.135 32.2907 34.123 31.21 34.123C30.7657 34.123 30.3695 34.2316 30.1894 34.7024C29.9972 35.2215 30.3335 35.7888 30.9459 35.813C32.3628 35.8492 33.7797 35.8492 35.2086 35.8009C35.857 35.7768 36.3493 35.3905 36.7576 34.8714C38.2105 33.0486 39.6875 31.25 41.1404 29.4393C41.6927 28.7391 42.3892 28.4253 43.2537 28.6788C44.4785 29.0168 44.9588 30.4412 44.2143 31.5638C43.3738 32.8434 42.4852 34.0747 41.6207 35.3301C40.8042 36.501 39.9876 37.672 39.1831 38.8429C38.0184 40.5329 36.4334 41.4141 34.356 41.3779C32.8671 41.3417 31.3661 41.3658 29.8652 41.3658Z"
            fill="white" />
        <path
            d="M22.7086 42.5973C22.7326 43.2009 22.5165 43.6958 22.0722 44.058C21.5679 44.4684 20.9795 44.7219 20.3791 44.9392C19.8507 45.1323 19.4425 44.8909 19.1303 44.2753C18.1697 42.3559 17.2091 40.4245 16.2605 38.5051C15.504 36.9962 14.7595 35.4872 14.003 33.9783C13.5347 33.0247 13.6668 32.6022 14.5914 32.1435C16.6807 31.0812 17.3411 31.3105 18.3738 33.3868C19.6947 36.0546 21.0155 38.7103 22.3363 41.3781C22.5405 41.7644 22.7446 42.1507 22.7086 42.5973Z"
            fill="white" />
        <path
            d="M30.0215 26.8803C28.5806 26.8803 27.1397 26.8803 25.7107 26.8803C24.8582 26.8803 24.594 26.5664 24.546 25.7093C24.4739 24.297 24.7381 22.9933 25.5666 21.8344C25.8428 21.4481 26.0349 21.3515 26.4432 21.7257C28.5445 23.621 31.4624 23.633 33.5878 21.762C34.008 21.3998 34.2122 21.424 34.5124 21.8585C35.3769 23.1019 35.6531 24.4901 35.533 25.9749C35.485 26.5664 35.1007 26.8803 34.4643 26.8803C32.9754 26.8803 31.4984 26.8803 30.0215 26.8803Z"
            fill="white" />
        <path
            d="M30.0693 21.448C28.016 21.448 26.3589 19.8062 26.3469 17.7541C26.3469 15.6657 27.992 13.9999 30.0693 13.9999C32.0746 13.9999 33.7197 15.6537 33.7437 17.6937C33.7677 19.7338 32.0986 21.448 30.0693 21.448Z"
            fill="white" />
        <defs>
            <linearGradient id="paint0_linear_201_2" x1="4.81016" y1="-1.06893" x2="59.8598" y2="59.8598"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.117499" stop-color="#67E1FF" />
                <stop offset="0.822018" stop-color="#27A6ED" />
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            required: false,
            default: "30"
        },
        height: {
            type: String,
            required: false,
            default: "30"
        }
    }
}
</script>
  