import { applicationTabs } from "@/views/commons/components/applications/router/application.tabs";

export default [
  {
    path: "applications",
    name: "connection-applications",
    redirect: { name: "cn-application-pending" },
    component: () => import(/* webpackChunkName: "FinancialApprovalApplications" */ "@/views/commons/components/applications/Applications.vue"),
    meta: {
      is_digital: 1,
      program: 3,
      module: 20,
      router: "ds",
      pageTitle: "Credit Card",
      breadcrumb: [
        {
          text: "CREDIT CARD",
          active: true,
        },
      ],
      routePending: "cn-application-pending",
      routeCompleted: "cn-application-completed",
      routeExpired: "cn-application-expired",
      routeSuccessfull: "cn-application-successfull",
      routeUnsuccessful: "cn-application-unsuccessful",
    },
    children: applicationTabs("cn"),
  },
];
