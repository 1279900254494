<template>
  <b-card>
    <b-row class="px-1 mb-2">
      <h3
        class="font-weight-bolder px-1 border-bottom-secondary border-2 pb-1 w-100"
      >
        Lead Information
      </h3>
    </b-row>
    <b-row>
      <!-- Services -->
      <b-col
        md="6"
        lg="3"
        v-if="moduleId != 5 && moduleId != 10 && moduleId != 14"
      >
        <validation-provider name="Services">
          <b-form-group label-for="services">
            <template #label>
              <span class="font-ce"> Services </span>
            </template>
            <v-select
              v-if="!disabledForm"
              v-model="programsList"
              multiple
              label="label"
              input-id="services"
              :selectable="(option) => option.selectable === true"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="G_PROGRAMS"
              :disabled="disabledForm"
              class="font-ce"
            />
            <div
              v-else
              class="form-group-input2"
              style="background-color: transparent; min-height: 38.34px"
            >
              <b-badge
                v-for="(program, index) in programsList"
                :key="index"
                variant="primary"
                class="mr-1"
                style="font-size: 12px; padding: 7px"
              >
                {{ program.label }}
              </b-badge>
            </div>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Lead Owner -->
      <b-col md="6" lg="3" v-if="userData.id">
        <b-form-group label="Lead Owner" label-for="userId">
          <template #label>
            <span class="font-ce">Lead Owner</span>
          </template>
          <b-form-input
            id="userId"
            v-model="userData.creator_name"
            name="userId"
            autofocus
            trim
            disabled
            class="font-ce"
          />
        </b-form-group>
      </b-col>
      <!-- Lead Status -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Lead Status"
          rules="required"
        >
          <b-form-group
            label="Lead Status"
            label-for="leadStatusId"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span
                class="d-flex justify-content-between align-items-center font-ce"
              >
                Lead Status
                <span
                  v-if="userData.id"
                  class="see-tracking"
                  @click="onModalTrackingChangeOpen(9, 'STATUS(LEAD)')"
                  >See Tracking</span
                >
              </span>
            </template>
            <b-input-group>
              <v-select
                v-model="userData.leadstatus_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :options="status_lead"
                style="width: 1%; flex: 1 1 auto"
                :clearable="false"
                :reduce="(el) => el.id"
                :disabled="
                  (userData.id && disabled.leadstatus_id) || disabledForm
                "
              />
              <template v-if="userData.id && !disabledForm">
                <b-input-group-append
                  v-if="!disabled.leadstatus_id"
                  class="border-right"
                >
                  <b-button
                    variant="outline-primary"
                    class="btn-sm"
                    @click="onSubmitFields"
                  >
                    <feather-icon icon="SaveIcon" class="cursor-pointer" />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append class="border-right">
                  <b-button
                    variant="outline-warning"
                    class="btn-sm"
                    @click="toggleElement('leadstatus_id')"
                    :disabled="
                      (userData.leadstatus_id == 7 ||
                        $route.matched[0].meta.module == 22 ||
                        $route.matched[0].meta.module == 23) &&
                      $route.matched[0].meta.module == 26
                    "
                  >
                    <feather-icon
                      v-if="disabled.leadstatus_id"
                      icon="Edit2Icon"
                      class="cursor-pointer"
                    />
                    <amg-icon
                      v-else
                      icon="Edit2SlashIcon"
                      class="cursor-pointer"
                    />
                  </b-button>
                </b-input-group-append>
              </template>
            </b-input-group>
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Lead Status ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Lead Source -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Lead Source"
          rules="required"
        >
          <b-form-group
            label="Lead Source"
            label-for="sourceId"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce">Lead Source</span>
            </template>
            <v-select
              v-model="userData.source_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :options="G_SOURCE_LEADS"
              :reduce="(el) => el.id"
              :clearable="false"
              :disabled="isClient || disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Lead Source ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Source Name -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Source Name"
          rules="required"
        >
          <b-form-group
            label="Source Name"
            label-for="sourceNameId"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce">Source Name</span>
            </template>
            <v-select
              v-model="userData.sourcesname_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :options="G_SOURCE_NAMES"
              :reduce="(el) => el.id"
              :clearable="false"
              :disabled="isClient || disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Source Name ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import CRMService from "@/views/crm/services/crm.service.js";

export default {
  components: {},
  props: {
    disabledForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    userData: {
      required: true,
    },
    typeEdit: {
      type: String,
      default: "lead",
    },
  },
  data() {
    return {
      programsList: [],
      blankUserData: new Object(),
      disabled: {
        leadstatus_id: true,
      },
      status_lead: [],
      user_creator_owner: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_STATUS_LEADS: "CrmLeadStore/G_STATUS_LEADS",
      G_SELLERS: "CrmGlobalStore/G_SELLERS",
      G_SOURCE_LEADS: "CrmLeadStore/G_SOURCE_LEADS",
      G_SOURCE_NAMES: "CrmGlobalStore/G_SOURCE_NAMES",
      G_PROGRAMS: "CrmGlobalStore/G_PROGRAMS",
    }),
    isClient() {
      return this.typeEdit === "client";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {
    this.getStatusLead();
    this.getUserCreatorOwner();
    this.setDataBlank("userData");
    this.programsList = this.userData.programs;
  },
  methods: {
    returnProgramlist() {
      return this.programsList;
    },
    async getStatusLead() {
      if (this.moduleId === 4) {
        const data = await CRMService.getStatusLeadById(7);
        data.forEach((element) => {
          this.status_lead.push({
            label: element.value,
            id: element.id,
          });
        });
      } else {
        this.status_lead = this.G_STATUS_LEADS;
      }
      if (
        this.userData.id &&
        !this.status_lead.some((e) => e.id == this.userData.leadstatus_id)
      ) {
        this.status_lead.push({
          label: this.userData.lead_status_name,
          id: this.userData.leadstatus_id,
        });
      }
    },
    async getUserCreatorOwner() {
      if (this.moduleId === 4) {
        const data = await CRMService.getUserCreatorOwner({
          modul: 15,
        });
        data.forEach((element) => {
          this.user_creator_owner.push({
            label: element.user_name,
            id: element.id,
          });
        });
      } else {
        this.user_creator_owner = this.G_SELLERS;
      }
    },
    ...mapActions({
      A_UPDATE_FIELDS_LEAD: "CrmLeadStore/A_UPDATE_FIELDS_LEAD",
      A_GET_SELLERS: "CrmGlobalStore/A_GET_SELLERS",
    }),
    ...mapMutations({
      M_STATUS_LEADS_CLIENT: "CrmLeadStore/M_STATUS_LEADS_CLIENT",
    }),
    setDataBlank(key) {
      this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`] = {
        ...this[key],
      };
    },
    resetElement(key, subkey) {
      const object = this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`];
      this[key][subkey] = object[subkey];
    },
    toggleElement(key) {
      this.disabled[key] = !this.disabled[key];
      if (this.disabled[key]) this.resetElement("userData", key);
    },
    async onSubmitFields() {
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            this.isPreloading(true);
            const response = await this.A_UPDATE_FIELDS_LEAD({
              id: this.currentUser.user_id,
              id_lead: this.userData.id,
              id_user: this.currentUser.user_id,
              typee: 9,
              street: null,
              city: null,
              state: null,
              zipcode: null,
              country: null,
              other_street: null,
              other_city: null,
              other_state: null,
              other_zipcode: null,
              other_country: null,
              phoneh: null,
              phonem: null,
              ssn: null,
              itin: null,
              other: null,
              // statusLead: this.userData.leadstatus_id,
              statusLead: this.userData.leadstatus_id,
            });
            this.isPreloading(false);
            if (this.isResponseSuccess(response)) {
              this.blankUserData.leadstatus_id = this.userData.leadstatus_id;
              this.toggleElement("leadstatus_id");
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else {
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                `Something went wrong.${response.message}`
              );
            }
          }
        })
        .catch((error) => {
          console.log("Something went wrong onSubmitFields: ", error);
          this.isPreloading(false);
          this.showErrorSwal();
        });
    },
    onModalTrackingChangeOpen(type, name) {
      this.$emit("onModalTrackingChangeOpen", {
        type,
        name,
        mapFunction: (el) => ({
          ...el,
          main_row: el.fields,
          main_row_hide: el.fields_secret,
          seeHideCell: false,
        }),
      });
    },
  },
  watch: {
    G_STATUS_LEADS(newVal, oldVal) {
      if (this.moduleId != 4) {
        this.status_lead = newVal;
      }
    },
    G_SELLERS(newVal, oldVal) {
      if (this.moduleId != 4) {
        this.user_creator_owner = newVal;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.sidebar-xl {
  width: 90rem;
}
.see-tracking {
  cursor: pointer;
  color: rgb(0, 183, 255);
  transition: text-decoration 0.1s, font-weight 0.1s;
}
.see-tracking:hover {
  text-decoration: underline;
  font-weight: bold;
}
</style>
<style scoped>
* {
  font-family: "Montserrat", sans-serif !important;
}
.form-group {
  font-size: 16px;
}
.font-ce {
  font-size: 16px;
}
.card-title h4 {
  font-size: 22px;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}
</style>
