import LeadsRouter from "../views/leads/leads.router";
import bankOfFlyersRouter from "@/views/social-network/views/bank-of-flyers/bank-of-flyers.router";
import ClientsRouter from "@/views/social-network/views/clients/client.router";
import MigrationsRouter from "@/views/social-network/views/migrations/migrations.router";
import ActivitiesRouter from "@/views/social-network/views/activities/activities.router";
import DashboardRouter from "@/views/social-network/views/dashboard/dashboard.router";
import DashboardRouter2 from "@/views/social-network/views/dashboard2/dashboard.router";
import NcrRouter from "@/views/social-network/views/ncr/ncr-social.router";
import FileManagerRouter from "@/views/social-network/views/file-manager/file-manager.router";
import LoansRouter from "@/views/social-network/views/loans/loans.router";
import SchedulesRouter from "@/views/social-network/views/schedules/schedules.router";
import RecoveryListRoute from "@/views/social-network/views/recovery-list/recovery-list.router";
import RecoveryRoute from "@/views/social-network/views/recovery/recovery.router";
import CommissionsRoute from "@/views/social-network/views/commissions/commissions.route";
import HelpdeskRoute from "@/views/social-network/views/helpdesk/helpdesk.route";
import SettingsRouter from "@/views/social-network/views/settings/settings.router";
import CalendarRouter from "@/views/social-network/views/calendar/calendar.router";
import ProductRequestRouter from "@/views/social-network/views/request/request.router.js";
import SocialNetworkMain from "@/views/social-network/views/SocialNetworkMain.vue";
import DashboardRouter3 from "@/views/social-network/views/dashboard3/dashboard.router";
import MmAttendanceRouter from "@/views/social-network/views/attendance/mm-attendance.router";
import EmployeeClaimsRouter from "@/views/social-network/views/employee-claims/router/employee-claims.router";
import StatisticsRouter from "@/views/social-network/views/statistics/router/statistics.router";
//import socialNetworkProjectsRouter from "@/views/social-network/views/projects/social-network-projects.router";
import CallLogRouter from "@/views/social-network/views/ring-central/call-log/call-log.router.js";
import MOFRouter from "@/views/social-network/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/social-network/views/recruitment-process/social-network-recruitment_process.router";
import requestNcrRouter from "@/views/social-network/views/request-ncr/request-ncr.router.js";
import CourtInfoRouter from "@/views/social-network/views/cour-info/router/ds-court-info.router";
import DashboardCalls from "@/views/social-network/views/ring-central/dashboard-calls/dashboard.router.js";
import CustomerTicketsRouter from "@/views/social-network/views/customer-tickets/social-network-customer-tickets.router";
import NoAnswerRouter from "@/views/social-network/views/no-answer/no-answer.router.js";
import SMSInboxRouter from "@/views/social-network/views/ring-central/sms-inbox/chat-sms.router.js";
import MessengerRouter from "@/views/social-network/views/messenger/router/messenger.router.js";
const routes = [
  {
    path: "/socialnetwork",
    meta: { module: 15 },
    component: SocialNetworkMain,
    redirect: { name: "dashboard3-social-network" },
    children: [
      ...LeadsRouter,
      ...bankOfFlyersRouter,
      ...ClientsRouter,
      NoAnswerRouter,
      ...MigrationsRouter,
      ...ActivitiesRouter,
      ...DashboardRouter,
      ...DashboardRouter2,
      ...NcrRouter,
      FileManagerRouter,
      ...LoansRouter,
      ...SchedulesRouter,
      ...RecoveryListRoute,
      ...RecoveryRoute,
      ...CommissionsRoute,
      HelpdeskRoute,
      SettingsRouter,
      CalendarRouter,
      ...ProductRequestRouter,
      ...StatisticsRouter,
      ...DashboardRouter3,
      ...MmAttendanceRouter,
      //...socialNetworkProjectsRouter,
      ...MOFRouter,
      ...EmployeeClaimsRouter,
      ...CallLogRouter,
      ...requestNcrRouter,
      ...CourtInfoRouter,
      ...DashboardCalls,

      ...RecruitmentProcessRouter,
      ...CustomerTicketsRouter,
      ...SMSInboxRouter,
      ...MessengerRouter,
    ],
  },
];

export default routes;
