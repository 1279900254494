<template>
  <validation-observer ref="form" class="w-100">
    <b-modal
      body-class="px-0"
      v-model="ownControl"
      lazy
      title-class="h3 text-white font-weight-bolder"
      :size="modalSize"
      modal-class="modal-primary"
      title="Files"
      hide-footer
      scrollable
      @hidden="hideModal"
    >
      <b-container fluid>
        <program-client-header
          class="mt-1"
          :client="files.client"
          :program="files.program"
        />
        <div>
          <b-row
            v-if="files.valorEdit"
            class="mt-2 d-flex align-items-center justify-content-end mr-1"
          >
            <b-btn variant="primary" size="sm" @click="loadFile = !loadFile">
              <feather-icon icon="PlusIcon" />Upload File
            </b-btn>
          </b-row>
        </div>
        <b-row v-if="loadFile">
          <b-container class="pl-4 pr-4">
            <b-row class="my-2">
              <div style="width: 150px">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="a"
                  class="w-100"
                >
                  <b-form-group label="Type">
                    <v-select
                      v-model="selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="options"
                      :clearable="false"
                      :class="{ 'border-danger rounded': errors[0] }"
                    />
                  </b-form-group>
                </validation-provider>
              </div>
              <div v-if="selected !== 'Others'" class="mx-4">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="doe"
                  class="w-100"
                >
                  <b-form-group label="DOE">
                    <kendo-datepicker
                      :class="[
                        'w-100 rounded bg-transparent k-picker-custom',
                        { 'text-white': isDarkSkin },
                        { 'border-danger rounded': errors[0] },
                      ]"
                      :format="'MM/dd/yyyy'"
                      v-model="doe"
                      v-mask="'##/##/####'"
                      class="leads-datepicker"
                    />
                  </b-form-group>
                </validation-provider>
              </div>
            </b-row>
            <b-row>
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="file"
                class="w-100"
              >
                <b-form-file
                  v-model="file"
                  drop-placeholder="Drop file here..."
                  placeholder="Choose a file or drop it here..."
                  size="lg"
                  :class="{ 'border-danger rounded': errors[0] }"
                />
              </validation-provider>
            </b-row>
            <b-row class="mt-2">
              <b-button variant="success" @click="uploadFile">
                <feather-icon icon="UploadIcon" />Upload
              </b-button>
            </b-row>
          </b-container>
        </b-row>
        <b-row class="mt-2 px-1">
          <b-nav pills card-header class="m-0 custom-tab-amg">
            <b-nav-item
              :active="tabs[0].id == actualTab"
              :link-classes="['h-full px-3', bgTabsNavs]"
              @click="actualTab = tabs[0].id"
            >
              {{ tabs[0].name }}
            </b-nav-item>
            <b-nav-item
              v-if="mode !== 1"
              :active="tabs[1].id == actualTab"
              :link-classes="['h-full px-3', bgTabsNavs]"
              @click="actualTab = tabs[1].id"
            >
              {{ tabs[1].name }}
            </b-nav-item>
          </b-nav>
          <b-table
            ref="fileLeadsTable"
            :items="itemTable"
            small
            show-empty
            class="font-small-3"
            sticky-header="50vh"
            thead-class="text-center"
            :fields="fields"
            responsive
          >
            <template #cell(status)="data">
              <b-form-checkbox
                v-if="data.item.type_file == 0"
                v-model="data.item.status"
                unchecked-value="0"
                value="1"
                @change="checkFile(data.item.id, data.item.status)"
              />
            </template>
            <template v-slot:cell(file_name)="data">
              <div>
                <feather-icon icon="FileTextIcon" />
                {{ data.item.file_name.replace("UNSIGNED", files.client) }}
              </div>
            </template>
            <template v-slot:cell(size)="data">
              <div>{{ data.item.size }} KB</div>
            </template>
            <template v-slot:cell(expiration)="data">
              <div v-if="data.item.expiration">
                {{ data.item.expiration | myGlobal }}
              </div>
            </template>
            <template v-slot:cell(updated_at)="data">
              {{ data.item.updated_at | myGlobalWithHour }}
            </template>
            <!-- <template v-slot:cell(is_signed)="data">
              <div class="d-flex align-items-center justify-content-center">
                <b-badge
                  v-if="data.item.document_status"
                  :variant="getBadgeVariant(data.item.document_status)"
                  class="ml-1"
                >
                  {{ data.item.document_status }}</b-badge
                >
                <span v-else>-</span>

                <a
                  v-if="data.item.url_signed_document != null"
                  :href="data.item.url_signed_document"
                  target="_blank"
                  class="ml-1 cursor-pointer"
                  v-b-tooltip.hover.top="'View Signed Document'"
                >
                  <feather-icon icon="FileTextIcon" class="text-primary" />
                </a>
              </div>
            </template> -->

            <!-- <template v-slot:cell(docusign_file_id)="data">
              <div class="d-flex align-items-center justify-content-center">
                <feather-icon
                  class="text-center cursor-pointer"
                  v-if="data.item.docusign_file_id"
                  icon="ListIcon"
                  @click="
                    openDocusignFileTracking(data.item.docusign_file_id)
                  "
                />
                <span v-else>-</span>
              </div>
            </template> -->
            <template v-slot:cell(actions)="data">
              <b-container class="text-center" style="margin: 5px 0px">
                <b-row v-if="data.item.is_ag">
                  <b-col cols="10" class="pr-0">
                    <b-row
                      v-if="data.item.route"
                      class="pb-50"
                      :class="{ hidden: !data.item.url }"
                    >
                      <b-col
                        cols="1"
                        class="text-info text-center d-flex align-items-center justify-content-center"
                        >EN</b-col
                      >
                      <b-col class="d-flex">
                        <b-btn
                          class="btn-icon rounded-circle"
                          variant="outline-info"
                          size="sm"
                          :href="data.item.url"
                          target="_blank"
                          :disabled="!data.item.url"
                          v-b-tooltip.hover.topright="'Download'"
                        >
                          <feather-icon icon="DownloadIcon" />
                        </b-btn>

                        <b-btn
                          variant="outline-success"
                          class="ml-1 btn-icon rounded-circle"
                          size="sm"
                          :disabled="!data.item.url"
                          v-b-tooltip.hover.topright="'Send SMS'"
                          @click="openSmsUrlPdfModal(data.item.url)"
                        >
                          <feather-icon icon="Share2Icon" />
                        </b-btn>

                        <!-- <b-btn
                          v-if="
                            data.item.extension === 'pdf' &&
                            (isCreditExperts ||
                              isDebtSolution ||
                              isBusiness ||
                              isBoostCredit) &&
                            data.item.document_status !== 'Signed' &&
                            data.item.document_status !== 'Declined'
                          "
                          variant="outline-primary"
                          class="ml-1 btn-icon rounded-circle"
                          size="sm"
                          :disabled="!data.item.url"
                          @click="sendToSign(data.item, 'en')"
                          v-b-tooltip.hover.topright="'Send to sign'"
                        >
                          <feather-icon icon="InboxIcon" />
                        </b-btn> -->
                      </b-col>
                    </b-row>
                    <b-row v-if="data.item.route_es" class="pt-50 border-top">
                      <b-col
                        cols="1"
                        class="text-info text-center d-flex align-items-center justify-content-center"
                        >ES</b-col
                      >
                      <b-col class="d-flex">
                        <b-btn
                          variant="outline-info"
                          size="sm"
                          :href="data.item.route_es"
                          target="_blank"
                          :disabled="!data.item.route_es"
                          class="btn-icon rounded-circle"
                        >
                          <feather-icon icon="DownloadIcon" />
                        </b-btn>

                        <b-btn
                          variant="outline-success"
                          class="ml-1 btn-icon rounded-circle"
                          size="sm"
                          :disabled="!data.item.route_es"
                          @click="openSmsUrlPdfModal(data.item.url)"
                        >
                          <feather-icon icon="Share2Icon" />
                        </b-btn>

                        <!-- <b-btn
                          v-if="
                            data.item.extension === 'pdf' &&
                            (isCreditExperts ||
                              isDebtSolution ||
                              isBusiness ||
                              isBoostCredit) &&
                            data.item.document_status !== 'Signed' &&
                            data.item.document_status !== 'Declined'
                          "
                          variant="outline-primary"
                          class="ml-1 btn-icon rounded-circle"
                          size="sm"
                          :disabled="!data.item.url"
                          @click="sendToSign(data.item, 'es')"
                          v-b-tooltip.hover.topright="'Send to sign'"
                        >
                          <feather-icon icon="InboxIcon" />
                        </b-btn> -->
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col
                    cols="1"
                    class="d-flex align-items-center justify-content-center pl-0"
                    style="margin-left: 10px"
                  >
                    <b-btn
                      variant="outline-warning"
                      size="sm"
                      class="btn-icon rounded-circle"
                      @click="
                        generatePdf(
                          files.id,
                          files.program,
                          files.sale_id,
                          data.item.is_ag
                        )
                      "
                    >
                      <feather-icon icon="RefreshCcwIcon" />
                    </b-btn>
                    <!-- <b-button @click="test">xwa</b-button> -->
                  </b-col>
                </b-row>
                <b-row v-else>
                  <b-col cols="1" />
                  <b-col cols="1">
                    <b-btn
                      variant="outline-info"
                      size="sm"
                      target="_blank"
                      :href="data.item.url"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="DownloadIcon" />
                    </b-btn>

                    <b-btn
                      v-if="mode === 1"
                      class="btn-icon rounded-circle mt-1"
                      variant="outline-danger"
                      size="sm"
                      @click="
                        deleteFile(
                          data.item.id,
                          data.item.route + '/' + data.item.file_name
                        )
                      "
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-btn>
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </b-table>
        </b-row>
      </b-container>
      <sms-url-pdf-modal
        v-if="openModalSmsPdf"
        :user_id="currentUser.user_id"
        :modul="currentUser.modul_id"
        :sms="files.id"
        :url-pdf="urlpdf"
        :nameleads="files.client"
        @closeModal="openModalSmsPdf = false"
      />
      <!--
      <DocusignFileTracking
        v-if="showDocusignFileTracking"
        :docusign_file_id="docusign_file_id"
        @close="closeDocuSignFileTracking"
      /> -->
    </b-modal>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import CrmService from "@/views/crm/services/crm.service";
import ProgramClientHeader from "@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue";
import SmsUrlPdfModal from "@/views/crm/views/sales-made/components/modals/SmsUrlPdfModal.vue";
// import DocusignService from "@/views/management/views/settings/views/docusign-credentials/service/docusign.service.js";
// import DocusignFileTracking from "@/views/crm/views/sales-made/components/modals/DocusignFileTracking.vue";
export default {
  name: "FilesModal",
  components: {
    SmsUrlPdfModal,
    ProgramClientHeader,
    vSelect,
    // DocusignFileTracking,
  },
  props: {
    modal: {
      type: Object,
      required: true,
    },
    files: {
      type: Object,
      required: true,
    },
    mode: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      // docusign_file_id: "",
      actualTab: 1,
      tabs: [
        {
          id: 1,
          name: "DEFAULT",
        },
        {
          id: 2,
          name: "ADDITIONALS",
        },
      ],
      urlpdf: "",
      item: [],
      openModalSmsPdf: false,
      fields: [
        {
          key: "file_name",
          sortable: false,
          label: "Name",
        },
        {
          key: "size",
          sortable: false,
          label: "Size",
        },
        {
          key: "expiration",
          sortable: false,
          label: "DOE",
        },
        {
          key: "updated_at",
          sortable: false,
          label: "Upload",
        },
        {
          key: "updater_name",
          sortable: false,
          label: "Updated By",
        },
        // {
        //   key: "is_signed",
        //   sortable: false,
        //   label: "Status",
        // },
        // {
        //   key: "docusign_file_id",
        //   sortable: false,
        //   label: "Docusign Tracking",
        // },
        {
          key: "actions",
          sortable: false,
          label: "Actions",
        },
      ],
      loadFile: false,
      file: null,
      options: ["ID", "UB", "Others"],
      selected: "",
      doe: "",
      base_url: process.env.VUE_APP_BASE_URL_ASSETS,
      ownControl: false,
      // showDocusignFileTracking: false,
      // dsFilesModalData: {
      //   ds_analysis_id_modal: null,
      //   hasCoApplicant: false,
      // },
    };
  },
  watch: {
    // "$store.state.DocusignStore.S_REFRESH": async function (val) {
    //   // if (val == true) {
    //   console.log("refresh");
    //   await this.loadTable();
    //   // }
    // },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    modalSize() {
      if (this.screenWidth > 992) return "lg";
      return "xlg";
      // return "xmd";
    },
    itemTable() {
      const programid = this.files.programId;
      if (this.mode === 1) {
        return this.item.filter(
          (item) =>
            item.is_ag == 0 ||
            (item.is_ag == 1 &&
              (programid == 1 ||
                programid == 2 ||
                programid == 3 ||
                programid == 4 ||
                programid == 7)) ||
            (item.is_ag == 2 && (programid == 3 || programid == 4)) ||
            (item.is_ag == 3 && (programid == 3 || programid == 4))
        );
      }
      if (this.actualTab == 1) {
        return this.item.filter((item) => item.type_file == 1);
      }
      if (this.actualTab == 2) {
        return this.item.filter((item) => item.type_file == 0);
      }
      return this.item;
    },

    isCreditExperts() {
      return this.files.programId === 3 || this.files.program_id === 3;
    },
    isDebtSolution() {
      return this.files.programId === 4 || this.files.program_id === 4;
    },

    isBusiness() {
      return this.files.programId === 1 || this.files.program_id === 1;
    },

    isBoostCredit() {
      return this.files.programId === 2 || this.files.program_id === 2;
    },
  },
  async created() {
    this.addPreloader();
    await this.loadTable();
    this.ownControl = true;
    // if (this.files.program === "Debt Solution") {
    //   this.dsFilesModalGetData();
    // }
    this.removePreloader();
  },
  methods: {
    // getBadgeVariant(status) {
    //   let color;
    //   switch (status) {
    //     case "Pending To Sign":
    //       color = "light-warning";
    //       break;
    //     case "Signed":
    //       color = "light-success";
    //       break;
    //     case "Declined":
    //       color = "light-danger";
    //       break;
    //     default:
    //       color = "light-info";
    //       break;
    //   }
    //   return color;
    // },
    // async sendToSign(item, language) {
    //   try {
    //     let confirmation = await this.showConfirmSwal(
    //       "Are you sure you want to send this file to sign?"
    //     );
    //     if (confirmation.isConfirmed) {
    //       this.addPreloader();

    //       if (this.files.program === "Credit Experts") {
    //         await this.sendCEFile(item, language);
    //       } else if (this.files.program === "Debt Solution") {
    //         await this.sendDSFile(item, language);
    //       } else if (this.files.program === "Business") {
    //         await this.sendBUFile(item, language);
    //       } else if (this.files.program === "Boost Credit") {
    //         await this.sendBCFile(item, language);
    //       } else {
    //         this.showErrorSwal("Program not found");
    //         this.removePreloader();
    //       }
    //     }
    //   } catch (error) {
    //     if (error.response.status === 400) {
    //       this.showErrorSwal(
    //         error.response.data.error,
    //         error.response.data.message
    //       );
    //     } else {
    //       this.showErrorSwal(error);
    //     }
    //   } finally {
    //     this.removePreloader();
    //   }
    // },

    // async sendBCFile(item, language) {
    //   let parseFilename = item.file_name.replace("UNSIGNED", this.files.client);

    //   let form = {
    //     document_type: "bc-agreement",
    //     files_format: "urls",
    //     files: language == "en" ? [item.url] : [item.route_es],
    //     language: language,
    //     filenames: [parseFilename],
    //     signer_id: this.files.id,
    //     current_user: this.currentUser.user_id,
    //     file_ids: [item.id],
    //     sender_id: 2, // program Id, Boost Credit
    //   };
    //   const { data } = await DocusignService.createEnvelope(form);
    //   if (data[0].result == "success") {
    //     this.showSuccessSwal("File sent to sign");
    //     this.loadTable();
    //   } else {
    //     this.showErrorSwal("Error sending file to sign");
    //   }
    // },

    // async sendBUFile(item, language) {
    //   let parseFilename = item.file_name.replace("UNSIGNED", this.files.client);

    //   let form = {
    //     document_type: "bu-agreement",
    //     files_format: "urls",
    //     files: language == "en" ? [item.url] : [item.route_es],
    //     language: language,
    //     filenames: [parseFilename],
    //     signer_id: this.files.id,
    //     current_user: this.currentUser.user_id,
    //     file_ids: [item.id],
    //     sender_id: 1, // program Id, Business
    //   };
    //   const { data } = await DocusignService.createEnvelope(form);
    //   if (data[0].result == "success") {
    //     this.showSuccessSwal("File sent to sign");
    //     this.loadTable();
    //   } else {
    //     this.showErrorSwal("Error sending file to sign");
    //   }
    // },

    // setDocumentTypeDS(string) {
    //   let type;
    //   if (/QU/.test(string)) {
    //     type = "ds-confidential-questionnaire";
    //   } else if (/AG/.test(string)) {
    //     type = "ds-negotiator-services-agreement";
    //   } else if (/PoA/.test(string)) {
    //     type = "ds-limited-power-of-attorney";
    //   } else {
    //     type = "unknown";
    //   }
    //   return type;
    // },

    // async dsFilesModalGetData() {
    //   try {
    //     const { data } = await DocusignService.dsFilesModalGetData({
    //       lead_id: this.files.id,
    //     });
    //     this.dsFilesModalData = data.data;
    //   } catch (error) {
    //     this.showErrorSwal(error);
    //   }
    // },

    // async sendCEFile(item, language) {
    //   let parseFilename = item.file_name.replace("UNSIGNED", this.files.client);

    //   let form = {
    //     document_type: "ce-agreement",
    //     files_format: "urls",
    //     files: language == "en" ? [item.url] : [item.route_es],
    //     language: language,
    //     filenames: [parseFilename],
    //     signer_id: this.files.id,
    //     current_user: this.currentUser.user_id,
    //     file_ids: [item.id],
    //     sender_id: 3, // program Id, Credit Experts
    //   };
    //   const { data } = await DocusignService.createEnvelope(form);
    //   if (data[0].result == "success") {
    //     this.showSuccessSwal("File sent to sign");
    //     this.loadTable();
    //   } else {
    //     this.showErrorSwal("Error sending file to sign");
    //   }
    // },

    // async sendDSFile(item, language) {
    //   let parseFilename = item.file_name.replace("UNSIGNED", this.files.client);

    //   let form = {
    //     document_type: this.setDocumentTypeDS(item.file_name),
    //     files_format: "urls",
    //     files: language == "en" ? [item.url] : [item.route_es],
    //     filenames: [parseFilename],
    //     language: language,
    //     signer_id: this.files.id,
    //     file_ids: [item.id],
    //     sender_id: 4, // program Id, Debt Solution
    //     ds_analysis_id_modal: this.dsFilesModalData.ds_analysis_id_modal,
    //     current_user: this.currentUser.user_id,
    //     hasCoApplicant: this.dsFilesModalData.hasCoApplicant,
    //   };
    //   const { data } = await DocusignService.createEnvelope(form);
    //   if (data[0].result == "success") {
    //     this.showSuccessSwal("File sent to sign");
    //     this.loadTable();
    //   } else {
    //     this.showErrorSwal("Error sending file to sign");
    //   }
    // },

    // openDocusignFileTracking(id) {
    //   this.docusign_file_id = id;
    //   this.showDocusignFileTracking = true;
    // },

    // closeDocuSignFileTracking() {
    //   this.docusign_file_id = null;
    //   this.showDocusignFileTracking = false;
    // },
    async checkFile(id, status) {
      try {
        this.addPreloader();
        const response = await amgApi.post("/sales-made/payment/check-file", {
          id,
          saleid: this.files.sale_id,
        });
        if (response.status === 200) {
          if (status == 1 || status == true) this.showSuccessSwal("File Added");
          else if (status == false) this.showSuccessSwal("Retired File");
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    hideModal() {
      this.$emit("close");
      this.ownControl = false;
    },
    async uploadFile() {
      const result = await this.$refs.form.validate();

      if (result) {
        const body = {
          image: "",
          namedoc: this.file.name,
          lead_id: this.files.id,
          size: this.file.size,
          user_id: this.currentUser.user_id,
          type: this.selected,
          program: this.files.program,
          date: this.files.event_date,
          datexp: this.doe,
        };
        const reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = async (file) => {
          body.image = file.target.result;

          this.addPreloader();
          const response = await amgApi.post(
            "/sales-made/save-document-file",
            body
          );
          if (response.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            this.doe = "";
            this.file = "";
            this.selected = "";
            this.loadFile = false;
            await this.loadTable();
          }
        };
      }

      this.removePreloader();
    },
    openSmsUrlPdfModal(urlPdf) {
      this.urlpdf = urlPdf;
      this.openModalSmsPdf = true;
    },
    async loadTable() {
      try {
        this.addPreloader();
        if (this.mode === 1) {
          this.item = await CrmService.getLeadsFiles({
            program: this.files.program,
            id: this.files.id,
          });
        }
        if (this.mode === 2 || this.mode === 4) {
          this.item = await CrmService.getLeadsFilesAccount({
            id: this.files.client_account_id,
            programid: this.files.program_id,
            saleid: this.files.sale_id,
          });
          this.fields = [
            {
              key: "status",
              sortable: false,
              label: "",
            },
            {
              key: "file_name",
              sortable: false,
              label: "Name",
            },
            {
              key: "size",
              sortable: false,
              label: "Size",
            },
            {
              key: "expiration",
              sortable: false,
              label: "DOE",
            },
            {
              key: "updated_at",
              sortable: false,
              label: "Upload",
            },
            {
              key: "actions",
              sortable: false,
              label: "Actions",
            },
          ];
        }
      } catch (error) {
        this.showToast("danger", "top-right", "Error", "XIcon", error);
      } finally {
        this.removePreloader();
      }
    },
    // eslint-disable-next-line camelcase
    async generatePdf(lead_id, program, sale_id, typee) {
      const result = await this.showConfirmSwal();
      try {
        if (result.isConfirmed) {
          this.addPreloader();
          const response = await CrmService.generatePdf({
            lead_id,
            program,
            sale_id,
            typee,
          });
          this.removePreloader();
          if (response === "ok") {
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Your file was generated successfully"
            );
          }
          await this.loadTable();
        }
      } catch (error) {
        this.removePreloader();
        this.showToast("danger", "top-right", "Error", "XIcon", error);
        await this.loadTable();
      }
    },
    async deleteFile(id, url) {
      const result = await this.showConfirmSwal();
      try {
        if (result.isConfirmed) {
          this.addPreloader();
          const response = await CrmService.deleteFile({
            id,
            url,
          });
          this.removePreloader();
          if (response === "ok") {
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Tu archivo se elimino correctamente"
            );
          }
          await this.loadTable();
        }
      } catch (error) {
        this.removePreloader();
        this.showToast("danger", "top-right", "Error", "XIcon", error);
        await this.loadTable();
      }
    },
  },
};
</script>

<style></style>
