export default [
  {
    path: 'tracking-list',
    name: 'bookeeping-tracking',
    redirect: { name: 'bookeeping-tracking-list' },
    component: () => import(/* webpackChunkName: "BookeepingTrackingList" */ '@/views/commons/components/tracking-list/views/components/TrackingListTab.vue'),
    meta: {
      pageTitle: 'Tracking List',
      permittedRoles: [1, 2, 3],
      breadcrumb: [
        {
          text: 'Tracking List',
        },
      ],
    },
    children: [
      {
        path: 'in-course',
        component: () => import(/* webpackChunkName: "BookeepingTrackingListInCourse" */ '@/views/commons/components/tracking-list/views/components/TrackingInCourseGrid.vue'),
        name: 'bookeeping-tracking-list',
        meta: {
          status: 1,
          pageTitle: 'Tracking List',
          permittedRoles: [1, 2, 3],
          breadcrumb: [
            {
              text: 'In course',
              active: true,
            },
          ],
        },
      },
      {
        path: 'completed',
        component: () => import(/* webpackChunkName: "BookeepingTrackingListCompleted" */ '@/views/commons/components/tracking-list/views/components/TrackingCompletedGrid.vue'),
        name: 'bookeeping-tracking-list-completed',
        meta: {
          status: 2,
          permittedRoles: [1, 2, 3],
          pageTitle: 'Tracking List',
          breadcrumb: [
            {
              text: 'Completed',
              active: true,
            },
          ],
        },
      },
      {
        path: 'pending',
        component: () => import(/* webpackChunkName: "BookeepingTrackingListPending" */ '@/views/commons/components/tracking-list/views/components/TrackingPending.vue'),
        name: 'bookeeping-tracking-list-pending',
        meta: {
          status: 3,
          permittedRoles: [1, 2, 3],
          pageTitle: 'Tracking List',
          breadcrumb: [
            {
              text: 'Pending',
              active: true,
            },
          ],
        },
      },
    ],
  },
]
