const steps = {
    CASE_CREATED: 1,
    CASE_RECONFIRMED: 2,
    TASK_SCHEDULED: 3,
    DOCUMENTS_UPDATED: 4,
    CASE_FINISHED: 5,
    ANSWER_PREPARED: 6,
    INDICATIONS_GIVEN: 7,
    CORRESPONDENCE_SENT: 8,
};
export default steps;