import { ncrNotificationsPusher } from "@/views/ce-digital/sub-modules/connection/pusher/ncr-notifications.pusher.js"
import WpRequestNotification from "@/views/ce-digital/sub-modules/connection/pusher/work-plan.pusher.js"
import { RecycleRequestNotification } from "@/views/ce-digital/sub-modules/connection/pusher/recycle-request-nofitications.pusher"
import NewClientsToAgentNotification from "@/views/ce-digital/sub-modules/connection/pusher/new-clients-to-agent.pusher.js"
import { SaleRequestNotification } from "@/views/ce-digital/sub-modules/connection/pusher/sale-notification.pusher"
import { SaleRequestNotificationSupervisorAgent } from "@/views/ce-digital/sub-modules/connection/pusher/sale-notification-supervisor-agent"

const subscribeConnection = (socket) => {
  try {
    socket.subscribe("connection-notifications")
    ncrNotificationsPusher()
    WpRequestNotification()
    RecycleRequestNotification()
    NewClientsToAgentNotification()
    SaleRequestNotification()
    SaleRequestNotificationSupervisorAgent()
  } catch (error) {
    console.error(error, "error")
  }
}

export default subscribeConnection
