import ZeroPayment from "@/views/debt-solution/views/zero-payment/navigation/zero-payment.navigation";
import Applications from "@/views/debt-solution/views/applications/navigation/applications.navigation";
import Appointment from "@/views/debt-solution/views/appointment/appointment.navigation.js";
import OtherServices from "@/views/debt-solution/views/other-services/other-services.navigation.js";
import ReportsNav from "@/views/debt-solution/views/reports/reports.nav";
import HelpdeskNavigation from "@/views/debt-solution/views/help-desk/helpdesk.nav";
import leadsNav from "../views/leads/leads.nav";
import CreditorsNav from "../views/creditors/creditors.nav";
import clientsNavigation from "@/views/debt-solution/views/clients/clients-group.navigation";
import PaymentsNavigation from "../views/payments/navigation/payments.navigation";
import ChargeBackNav from "../views/charge-back/charge-back.nav";
import SalesMade from "../views/sales-made/sales-made.nav";
import ClaimsNav from "../views/claims-v2/claims.nav";
import SettingsNavigation from "../views/settings/navigation/settings.navigation";
import Commissions from "../views/commissions/commissions.nav";
import FileManager from "../views/file-manager/file-manager.nav";
import PerformanceNavigation from "../views/performance/performance.nav";
import ProductRequestNavigation from "@/views/debt-solution/views/request/request.nav.js";
import SettlementsNavigations from "@/views/debt-solution/views/settlements/navigation/pay-negotiations.nav";
import OtherPaymentNavigation from "@/views/debt-solution/views/other-payments/other-payments.nav";
import EmployeeClaimsNavigation from "@/views/debt-solution/views/employee-claims/navigation/employee-claims.nav";
import RingCentralNav from "@/views/debt-solution/views/ring-central/ring-central.nav.js";
import PermissionActionNavigation from "@/views/debt-solution/views/permission-action/navigation/permission.nav.js";
import DashboardNavigation from "@/views/debt-solution/views/dashboard/navigation/dashboard.nav";

import MOFNavigation from "@/views/debt-solution/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/debt-solution/views/recruitment-process/debt-solution-recruitment_process.navigation";
import SpecialistNavigation from "@/views/debt-solution/views/specialist/navigation/specialist.navigations.js";
import SendingReportNavigation from "@/views/debt-solution/views/sending-report/sending-report.nav.js";
import CourtInfoNavigation from "@/views/debt-solution/views/court-info/navigation/ds-court-info.nav.js";
import CommissionForSpecialistsNavigation from "@/views/debt-solution/views/commission-specialists/commissions-specialists.navigation";
import { sumTag } from "@/@core/utils/utils";
import RequestClientStatusNavigation from "@/views/debt-solution/views/request-client-status/navigation/request-client-status.nav";
import InconsistentTransactionNavigation from "@/views/debt-solution/views/inconsistent-transactions/navigation/inconsistent-transactions.nav.js";
// import PendingPaymentNavigation from "@/views/debt-solution/views/pending-payments/navigation/pending-payments.navigation.js"
import CallImpressionNavigation from "@/views/debt-solution/views/call-impression/navigation/call-impression.navigation.js";


const navigation = [
  {
    header: "DEBT SOLUTION",
  },
  ...DashboardNavigation,
  ...leadsNav,
  ...clientsNavigation,
  ...SettlementsNavigations,
  ...CourtInfoNavigation,
  {
    title: "Services",
    icon: "CreditCardIcon",
    tag: sumTag([
      ...Appointment,
      ...Applications,
      ...OtherServices,
      CommissionForSpecialistsNavigation,
    ]),
    children: [
      ...Appointment,
      ...Applications,
      ...OtherServices,
      CommissionForSpecialistsNavigation,
    ],
  },
  ...SalesMade,
  {
    title: "Transactions",
    icon: "CreditCardIcon",
    tag: 0,
    children: [
      ...PaymentsNavigation,
      ...ZeroPayment,
      ...OtherPaymentNavigation,
      ...ChargeBackNav,
      // ...PendingPaymentNavigation,
    ],
  },
  {
    title: "Advisor Metrics",
    icon: "UserIcon",
    tag: 0,
    children: [...Commissions, ...PerformanceNavigation],
  },
  ...SpecialistNavigation,
  ...ReportsNav,
  ...CreditorsNav,
  {
    title: "General",
    icon: "ListIcon",
    tag: 0,
    children: [
      ClaimsNav,
      FileManager,
      HelpdeskNavigation,
      EmployeeClaimsNavigation,
      ProductRequestNavigation,
    ],
  },
  ...SettingsNavigation,
  RingCentralNav,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  ...SendingReportNavigation,
  ...PermissionActionNavigation,
  ...RequestClientStatusNavigation,
  ...InconsistentTransactionNavigation,
  ...CallImpressionNavigation,

];
export default navigation;
