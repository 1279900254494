import store from '@/store'
import Vue from "vue";
/**
 * ? This function is responsible for listening to the socket event of the timeline generated when job is completed 
 * ?change the state of the timeline to 1 to update the message in button and activates the refresh when an user open 
 * ?debt solution client information tab in dashboard
 */
const timelineGeneratedNotification = () => {
    window.socket.bind('timeline-generated', async ({ to_id, message,clientAccountId }) => {
        const currentSession = store.state.auth.currentUser.user_id
        if(store.state.DebtSolutionClients.S_CLIENTS.id===clientAccountId){
            await store.dispatch("DebtSolutionClients/A_SET_TIME_LINE", 1);
        if (to_id === currentSession) {
            Vue.swal.fire({
                icon: "info",
                title: "Timeline",
                html: `<div>
                <h2>${message}</h2>
               </div>`,
                    showConfirmButton: true,
                    confirmButtonText: "Accept",
                });
        }
        }
        
    })
}

export default timelineGeneratedNotification
