import { amgApi } from "@/service/axios";

class LeadService {
  constructor() {
    this.url = "/lead";
  }
  async getSubSourcesSn(params) {
    const { data } = await amgApi.get(`${this.url}/get-sub-sources-sn`, params);
    return data;
  }
}

export default new LeadService();
