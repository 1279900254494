<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 border-0">
      <filter-slot
        :filter="tabFilters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs[`refSaleLeads${typeTab}`].refresh()"
      >
        <!-- :disabled="!leadsSelecteds.length"
            @click="modalSmssOpen" -->
        <!-- <template #buttons>
          <b-button
            variant="success"
            class="ml-1"
            :disabled="!leadsSelecteds.length"
            @click="modalSmssOpen"
          >
            <feather-icon icon="MessageCircleIcon" class="mr-50" />Send SMS
          </b-button>
        </template> -->
        <b-table
          slot="table"
          :ref="`refSaleLeads${typeTab}`"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          no-provider-filtering
          show-empty
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :fields="tabFields"
          :items="myProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy.sync="isBusy"
        >
          <!-- <template #head(selected)>
            <b-form-checkbox v-model="selectAll" @input="selectedAll" />
          </template> -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <!-- Column: Selected -->
          <template #cell(selected)="data">
            <!-- <b-form-group>
              <b-form-checkbox
                v-model="data.item.selected"
                @input="onSelectedRow(data.item)"
              />
            </b-form-group> -->
          </template>
          <!-- Column: Name -->
          <template #cell(lead_name)="data">
            <div>
              <router-link
                :class="[textLink]"
                target="_blank"
                class
                :to="{
                  name: 'sales-lead-show',
                  params: {
                    id: data.item.id,
                  },
                  props: { programsAll: programsAll },
                }"
                ><span>{{ data.item.lead_name }}</span>
              </router-link>
              <p>
                {{ data.item.source_name }}
              </p>
            </div>
            <div v-if="data.item.user_responsible_date" class="text-warning font-weight-bolder">
              <feather-icon
                style="margin-right: 3px;"
                icon="StarIcon"
              />
              CEO's client
            </div>
          </template>
          <!-- Column: Status -->
          <template #cell(status)="data">
            <div class="d-flex justify-content-center pt-1">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize w-75"
                >{{ data.item.status }}</b-badge
              >
              <p></p>
            </div>
          </template>
          <!-- Column: Cather -->
          <template #cell(catcher_by)="data">
            <div class="pt-1">
              <small>{{ data.item.catcher_by }}</small>
            </div>
          </template>

          <!-- Column: Seller -->
          <template #cell(seller_by)="data">
            <div class="pt-1">
              <small>{{ data.item.seller_by }}</small>
            </div>
          </template>

          <!-- Column: Status -->
          <template #cell(mobile)="data">
            <div class="pt-1">
              <small>{{ data.item.mobile }}</small>
            </div>
          </template>
          <!-- Column: Credit Report -->
          <template #cell(credit_report)="data">
            <div class="pt-1">
              <strong
                :class="`text-${
                  data.item.credit_report == 1 ? 'danger' : 'success'
                }`"
                >{{ data.item.credit_report == 1 ? "NO" : "YES" }}</strong
              >
            </div>
          </template>

          <!-- Column: Programs -->
          <template #cell(programs)="data">
            <div class="pt-1">
              {{ data.item.programs }}
            </div>
          </template>
          <!-- Column: Module -->
          <template #cell(module)="data">
            <div class="pt-1">
              <small>{{ data.item.module }}</small>
            </div>
          </template>

          <!-- Column: Created By -->
          <template #cell(created_at)="data">
            <div class="pt-1">
              <small>{{ data.item.created_at | myDate }}</small>
            </div>
          </template>
          <!-- Column tracking -->
          <template #cell(tracking)="data">
            <div class="pt-1">
              <feather-icon
                icon="MenuIcon"
                class="cursor-pointer"
                size="15"
                @click="openTracking(data.item)"
              />
            </div>
          </template>

          <!-- Column: Actions -->
          <template
            #cell(actions)="data"
            v-if="
              currentUser.role_id == 1 ||
              currentUser.role_id == 2 ||
              currentUser.role_id == 5 ||
              currentUser.role_id == 14 ||
              currentUser.role_id == 17
            "
          >
            <div>
              <actions-table
                :options="actionsOptions"
                :row-data="data.item"
                @modalAddService="modalAddService"
                @modalChangeService="modalChangeService"
                @onTracing="openActions"
                @onRecycled="openActions"
                @modalSmsOpen="modalSmsOpen"
                @modalHistorySmsOpen="modalHistorySmsOpen"
              />
            </div>
          </template>

          <template #cell(client_ceo)="data">
            <span class="text-warning">
              <template
                v-if="data.item.user_responsible_date"
                class="mr-50"
              >From</template> {{ data.item.user_responsible_date | myGlobal }}
            </span>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <!-- Modal change tab -->
    <actions-type-tab-modal
      v-if="stateTracing"
      :client-data="clientData"
      @hideModal="closeModal(1)"
    />
    <!-- Modal Tracking -->
    <tracking-modal
      v-if="stateTracking"
      :client-data="clientData"
      @hideModal="closeModal(2)"
    />
    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
      @leadEvent="leadEvent"
    />

    <!-- modal SEND SMS -->

    <modal-send-sms
      v-if="modalSms"
      :smss="leads_sms"
      :modul="currentUser.modul_id"
      :typesms="typesms"
      :sms="leads_sms_o"
      :name-leads="name_leads_arr"
      @hide="modalSms = false"
    >
      <template #manage-quick-sms>
        <div></div>
      </template>
    </modal-send-sms>
    <!-- modal HISTORY SMS -->
    <b-modal
      id="modal-history-sms"
      ok-only
      modal-class="modal-primary"
      title-class="text-white h4"
      centered
      size="lg"
      title="History of Sms"
      hide-footer
    >
      <modal-history-sms
        :id="historySms.id"
        :modul="currentUser.modul_id"
        :lead-name="historySms.leadName"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ActionsTable from "./ActionsTable.vue";
import dataFields from "../data/fields.data";
import dataFilters from "../data/dataFilters.data";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
// import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import LeadService from "../../service/leads.service";
import LeadSaleService from "@/views/commons/components/lead-programs/service/lead.service";
import ActionsTypeTabModal from "./modal/ActionsType.vue";
import TrackingModal from "@/views/ce-digital/sub-modules/sales/views/clients/view/components/modal/Tracking.vue";
import ClientService from "@/views/ce-digital/sub-modules/sales/views/clients/service/clients.service";
import ModalAddService from "@/views/commons/components/clients/modals/ModalAddService.vue";
import ModalHistorySms from "@/views/crm/views/Lead/lead-sms/ModalHistorySms.vue";
import ModalSendSms from "@/views/crm/views/Lead/lead-sms/ModalSendSms.vue";
export default {
  components: {
    FilterSlot,
    ActionsTable,
    BusinessModal,
    // CreditExpertsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    ActionsTypeTabModal,
    TrackingModal,
    ModalAddService,
    ModalHistorySms,
    ModalSendSms,
  },
  data() {
    return {
      type: 0,
      actionsOptions: ["sendSMS", "historySMS"],
      baseUrl: process.env.VUE_APP_BASE_URL_ASSETS,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      isBusy: false,
      fields: dataFields.leadFields,
      filter: dataFilters,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      perPageOptions: [10, 25, 50, 100],
      sortBy: "",
      sortDesc: true,
      rowData: {},
      modal: {
        programs: false,
      },
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      dataModal: "",
      modalAddProgramsState: false,
      items: [],
      owner: [],
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      salesClient: {},
      programsAll: [],
      usersServices: [],
      servicesUsers: [],
      headerS: {},
      dataEvent: [],
      event: [],
      event_id: 0,
      stateTracing: false,
      stateTracking: false,
      clientData: {},
      typeAddChange: null,
      detailsClient: {},
      typeModal: null,
      modalOpenAddService: false,
      leadsSelecteds: [],
      leadsArray: [],
      selectAll: false,
      modalSms: false,
      typesms: null,
      leads_sms_o: [],
      leads_sms: [],
      historySms: {
        leadName: "",
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_SOURCE_NAMES: "CrmGlobalStore/G_SOURCE_NAMES",
      G_UPDATE_TABLE_LEAD: "CeDigitalSalesLeads/G_UPDATED_LEADS_TABLE",
    }),
    // selectedAll() {
    //   if (this.selectAll)
    //     this.leadsArray.forEach((item) => (item.selected = true));
    //   else this.leadsArray.forEach((item) => (item.selected = false));
    //   this.onRowSelected();
    // },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    typeTab() {
      switch (this.$route.name) {
        case "sales-leads-news":
          return 1; // New
          break;
        case "sales-leads-tracing":
          return 2; // Tracing
          break;
        case "sales-leads-success":
          return 3; // Success
          break;
        case "sales-leads-recycled":
          return 4; // One Recycled
          break;
      }
    },
    // Validation filter by tab and type users
    tabFields() {
      if (this.currentUser.role_id == 5) {
        if (this.typeTab == 3) {
          return this.fields.filter(
            (item) => item.key != "seller_by" && item.key != "status"
          );
        } else {
          return this.fields.filter((item) => item.key != "seller_by");
        }
      } else {
        if (this.typeTab == 3) {
          return this.fields.filter((item) => item.key != "status");
        } else {
          return this.fields;
        }
      }
    },
    tabFilters() {
      if (this.typeTab == 3) {
        return this.filter.filter((item) => item.label != "Status Lead");
      } else {
        return this.filter;
      }
    },
  },
  async created() {
    this.addPaddingTd();
    await Promise.all([this.getUserAllPrograms(), this.getSellers()]);
  },
  methods: {
    ...mapActions({
      A_SET_SELECTED_LEADS: "CrmLeadStore/A_SET_SELECTED_LEADS",
    }),
    // modalSmssOpen() {
    //   this.typesms = 0;
    //   this.name_leads_arr = this.leadsSelecteds.map((el) => ({
    //     name: el.lead_name,
    //     id: el.id,
    //   }));
    //   this.leads_sms = this.leadsSelecteds.map((el) => el.id);
    //   this.modalSms = true;
    // },
    onSelectedRow(data) {
      const index = this.leadsSelecteds.findIndex(
        (select) => select.id === data.id
      );
      if (data.selected === true && index === -1)
        this.leadsSelecteds.push(data);
      else if (data.selected === false && index !== -1)
        this.leadsSelecteds.splice(index, 1);
      this.onRowSelected();
    },
    onRowSelected() {
      this.A_SET_SELECTED_LEADS(this.leadsSelecteds);
    },
    modalSmsOpen(item) {
      this.rowData = item;
      this.leads_sms = [];
      this.typesms = 1;
      this.leads_sms_o = [];
      this.leads_sms_o.push(item.id);
      this.name_leads_arr = [{ name: item.lead_name, id: item.id }];
      this.modalSms = true;
    },
    modalHistorySmsOpen(item) {
      this.historySms.id = item.id;
      this.historySms.leadName = item.lead_name;
      this.$bvModal.show("modal-history-sms");
    },
    async searchPrograms(lead_id) {
      try {
        const response = await ClientService.searchClientPayment({
          id: lead_id,
        });
        if (response.status == 200) {
          const programs = response.data;
          const data = await ClientService.programs();
          this.programsAll = data.data;
          programs.sort((a, b) => a.program_id - b.program_id);
          for (let i = 0; i < this.programsAll.length; i++) {
            for (let y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == programs[y].program_id) {
                this.programsAll.splice(i, 1);
              }
            }
          }
          const sper = this.currentUser.sale_permission;
          const spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async usersAllPrograms() {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.$route.matched[0].meta.module,
        });
        this.usersServices = data.data; // .filter(item => item.id != 1);
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },

    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    modalGeneralService(data, type) {
      let info = { ...data, ...{ lead_id: data.id } };
      this.detailsClient = info;
      this.detailsClient.accounts = JSON.stringify([
        { program: "Credit Experts" },
      ]);
      this.typeModal = type;
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    // Creation event
    async createEvent(data) {
      const response = await LeadSaleService.createLeadEvent({
        user_id: this.currentUser.user_id,
        lead_id: data.id,
      });
      this.event = response.id;
    },
    async openModalProgram(data) {
      if (data.captured.id && data.program.id && data.seller.id) {
        const response = await this.showConfirmSwal(
          "Are you sure?",
          "You won't be able to revert this!"
        );
        if (response.isConfirmed) {
          await this.addPreloader();
          try {
            // Add service
            if (this.typeAddChange == 1) {
              let pram2 = {
                id: this.event,
                program: data.program.id,
                done: 1,
                module: this.moduleId,
                captured: data.captured.id,
                seller: data.seller.id,
                derivation_id: this.detailsClient.derivation_id,
              };
              let result = await LeadSaleService.insertSaleDebt(pram2);
              if (result.status == 200) {
                this.modalAddProgramsState = false;
                this.$router
                  .push({
                    name: "sales-made-cedigital-new-client",
                    query: { done: this.moduleId === 26 ? "2" : null },
                  })
                  .catch((err) => {
                    console.log(err);
                  });
                this.removePreloader();
              }
            } else {
              // Change service

              const param = {
                prices: null,
                observation: "Service",
                contract: 1,
                program: data.program.id,
                fee: null,
                suggested: null,
                otherpricesp: null,
                event: this.event,
                json_noce: null,
                stateid: 0,

                // Diferents to add change Services
                account: this.detailsClient.program_ce,
                captured: data.captured.id,
                seller: data.seller.id,
                type: this.typeAddChange,
                user_id: this.currentUser.user_id,
                module: this.moduleId,
                id_score: null,
                json_ce: null,
                derivation_id: this.detailsClient.derivation_id,
              };
              try {
                const result = await LeadSaleService.insertSaleMade(param);
                if (result.status === 200) {
                  this.modalOpenAddService = false;
                  await this.$router
                    .push({ name: "sales-made-cedigital-lead" })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              } catch (error) {
                this.showErrorSwal(
                  "Please, verify if the client already has the service, otherwise contact support.",
                  "Error"
                );
              } finally {
                this.removePreloader();
              }
            }
          } catch (error) {
            throw error;
          }
        }
      }
    },
    // Change service
    async modalChangeService(data) {
      this.typeAddChange = 2;
      const result = await this.showConfirmSwal("Are you sure change service?");
      if (result.value) {
        await this.createEvent(data);
        this.modalGeneralService(data, 7);
      }
    },
    // Add service
    async modalAddService(data) {
      this.typeAddChange = 1;
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      if (result.value) {
        this.modalGeneralService(data, 7);
        await this.createEvent(data);
      }
    },

    //Seller filter
    async getSellers() {
      try {
        if (
          this.currentUser.role_id == 1 ||
          this.currentUser.role_id == 2 ||
          this.currentUser.role_id == 14 ||
          this.currentUser.role_id == 17
        ) {
          const { data, status } = await ClientService.getSellers({
            module_id: this.moduleId,
            role_id: 5,
          });
          if (status == 200) {
            this.allSellers = data;

            this.filter[4].options = this.allSellers;
            this.filter[3].options = this.G_SOURCE_NAMES;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    openTracking(item) {
      let data = {
        lead_name: item.lead_name,
        account: item.account,
        derivation_id: item.derivation_id,
        module: item.module,
        type: 1, // 1 Lead
      };
      this.stateTracking = true;
      this.clientData = data;
    },
    // close modal Tracking(2) and ActionsTypeTab(1)
    closeModal(type) {
      if (type == 1) {
        this.stateTracing = false;
        this.$refs[`refSaleLeads${this.typeTab}`].refresh();
      }
      if (type == 2) {
        this.stateTracking = false;
      }
    },
    // Data by action modal
    openActions(item, type) {
      let data = {
        lead_name: item.lead_name,
        catcher_by: item.catcher_by,
        actionType: type,
        derivation_id: item.derivation_id,
        type: 1, // 1 Lead
      };
      this.stateTracing = true;
      this.clientData = data;
    },
    async getAllPrograms() {
      try {
        const data = await LeadService.getPrograms();
        if (data) {
          this.programsAll = data;
          var sper = this.currentUser.sale_permission;
          var spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async getUserAllPrograms() {
      let resultUsers = await LeadService.getUsersModule(
        this.$route.matched[0].meta.module,
        {
          roles: "[]",
          type: "0",
        }
      );
      // Only for Business
      if (this.$route.matched[0].meta.module === 3) {
        resultUsers = await LeadService.getUsersAllPrograms({
          id: this.$route.matched[0].meta.module,
        });
      }

      // Data for Modal Add Service
      const dataModal = [...resultUsers];
      if (dataModal) {
        dataModal.forEach((item) => {
          if (item.id == 1) {
            dataModal.splice(dataModal.indexOf(item), 1);
          }
        });
        dataModal.unshift({
          id: 1,
          user_name: "CEO",
        });
        this.servicesUsers = dataModal;
      }
    },
    // Column status color
    resolveUserStatusVariant(status) {
      if (status === "Pending") return "warning";
      if (status === "Active") return "success";
      if (status === "Inactive") return "secondary";
      if (status === "Not Contacted") return "danger";
      return "primary";
    },
    // Data by grid
    async myProvider() {
      try {
        const resp = await LeadService.getLeads({
          name_text: this.filterPrincipal.model,
          lead_status: this.filter[5].model,
          cr: this.filter[2].model,
          date_from: this.filter[0].model,
          date_to: this.filter[1].model,
          orderby: "slc.created_at",
          order: "desc",
          user_seller: this.filter[4].model,
          sourcename: this.filter[3].model,
          module_id: this.moduleId,
          userid: this.currentUser.user_id,
          roleid: this.currentUser.role_id,
          tabType: this.typeTab,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
        });
        this.leadsArray = resp.data;
        this.startPage = resp.from;
        this.toPage = resp.to;
        this.totalRows = resp.total;
        this.paginate.currentPage = resp.current_page;
        this.paginate.perPage = resp.per_page;
        return this.leadsArray || [];
      } catch (error) {
        console.log("Something went wrong myProvider", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    leadEvent(data) {
      if (data) {
        this.dataEvent = [{ event_id: data.id }, { module: this.moduleId }];
      }
    },

    closeModalAddPrograms(stado) {
      this.modalAddProgramsState = stado;
    },
  },
  mounted() {
    // List of actions
    if (this.typeTab == 1) {
      this.actionsOptions.push("addService"); // Add Service
      this.actionsOptions.push("tracing"); // Tracing
      this.actionsOptions.push("recycled"); // Recycled
    }
    if (this.typeTab == 2) {
      this.actionsOptions.push("addService"); // Add Service
      this.actionsOptions.push("recycled"); // Recycled
    }
    if (this.typeTab == 3) {
      this.actionsOptions.push("addService"); // Add Service
      this.actionsOptions.push("changeService");
    }
    if (this.typeTab == 4) {
      this.actionsOptions.push("tracing"); // Tracing
    }
  },
  watch: {
    //Grid updated for new lead
    G_UPDATE_TABLE_LEAD(newVal) {
      if (newVal) {
        this.$refs[`refSaleLeads${this.typeTab}`].refresh();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-responsive > div {
  min-height: 15rem;
}
</style>
