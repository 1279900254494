<template>
  <b-card
    header-text-variant="white"
    header-bg-variant="info"
    header-class="p-0"
    body-class="pt-1 card-body-border"
  >
    <template #header>
      <div class="pl-1 pt-1">
        <p class="font-weight-bolder">Cost of living</p>
      </div>
    </template>
    <validation-observer ref="formServices">
      <b-row>
        <b-col cols="12">
          <v-select
            v-model="hardship.utilities"
            :options="optionsUtilities"
            label="text"
            multiple
            placeholder="Utilities"
            :disabled="readOnly"
            :close-on-select="false"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(service, index) in hardship.utilities"
          :key="service.id"
          xl="2"
          lg="2"
          md="3"
          sm="3"
          cols="4"
          class="mt-1"
        >
          <div>
            <validation-provider
              rules="validate-amount-zero"
              v-slot="{ errors }"
            >
              <label>{{ service.text }}</label>
              <money
                :id="`inp-${index}`"
                v-model="service.mont"
                v-bind="vMoney"
                :disabled="readOnly"
                class="form-control"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </b-col>
        <b-col class="mt-1" xl="2" lg="2" md="3" sm="3" cols="6">
          <label>Total Utilities</label>
          <div
            class="form-control d-flex align-items-center justify-content-start bg-transparent border"
          >
            $ {{ totalUtilities | currencyZero }}
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="4" lg="4" md="4" sm="4" cols="4" class="mt-1">
          <div>
            <validation-provider
              rules="validate-amount-zero"
              v-slot="{ errors }"
            >
              <label>Food(expenses in store or eating outside)</label>
              <money
                id="food"
                v-model="hardship.food"
                v-bind="vMoney"
                :disabled="readOnly"
                class="form-control"
                :class="errors[0] ? 'border-danger' : ''"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </b-col>
        <b-col xl="2" lg="2" md="3" sm="3" cols="4" class="mt-1">
          <div>
            <validation-provider
              rules="validate-amount-zero"
              v-slot="{ errors }"
            >
              <label>Insurance</label>
              <money
                id="insurance"
                v-model="hardship.insurance"
                v-bind="vMoney"
                :disabled="readOnly"
                class="form-control"
                :class="errors[0] ? 'border-danger' : ''"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12">
          <b-button v-if="!readOnly"
            :disabled="readOnly"
            variant="success"
            @click="addCards()"
            size="sm"
          >
            ADD CARDS
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="2" v-for="(item, index) in hardship.cards" :key="index">
          <b-row>
            <b-col cols="3" style="margin-top: 30px">
              <span class="p_desing">
                <feather-icon icon="CreditCardIcon" />
              </span>
            </b-col>
            <b-col cols="9">
              <feather-icon
                class="float-right text-danger cursor-pointer"
                @click="deleteCards(item.id)"
                icon="XSquareIcon"
              />
              <validation-provider
                rules="validate-amount-zero"
                v-slot="{ errors }"
              >
                <label for>Pay</label>
                <money
                  id="item.id"
                  v-model="item.pay"
                  v-bind="vMoney"
                  :disabled="readOnly"
                  class="form-control"
                />
                <span style="color: red">{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12">
          <b-button v-if="!readOnly"
            :disabled="hardship.cars.length >= 5 || readOnly"
            variant="primary"
            @click="addCar()"
            size="sm"
          >
            CAR PAYMENTS
            <feather-icon
              v-if="hardship.cars.length < 5 && !readOnly"
              icon="PlusIcon"
            />
          </b-button>
        </b-col>

        <b-col
          v-for="(car, index) in hardship.cars"
          :key="index"
          class="mt-1"
          xl="2"
          lg="2"
          md="3"
          sm="3"
          cols="4"
        >
          <div class="d-flex align-items-center justify-content-between">
            <label>{{ `Car ${index + 1}` }}</label>
            <label v-if="index != 0 && index == hardship.cars.length - 1">
              <feather-icon
                v-if="!readOnly"
                icon="XCircleIcon"
                class="cursor-pointer text-danger font-medium-2"
                @click="hardship.cars.splice(index, 1)"
              />
            </label>
          </div>
          <validation-provider rules="validate-amount-zero" v-slot="{ errors }">
            <money
              :id="`car-${index}`"
              v-model="car.amount"
              v-bind="vMoney"
              :disabled="readOnly"
              class="form-control"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-col>
        <b-col class="mt-1" xl="2" lg="2" md="3" sm="3" cols="4">
          <validation-provider rules="validate-amount-zero" v-slot="{ errors }">
            <label>Gasoline</label>
            <money
              id="gasoline"
              v-model="hardship.gasoline"
              v-bind="vMoney"
              :disabled="readOnly"
              class="form-control"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col cols="12">
          <v-select
            v-model="hardship.others"
            :options="optionsHard"
            label="text"
            multiple
            placeholder="Others"
            :disabled="readOnly"
            :close-on-select="false"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(service, index) in hardship.others"
          :key="service.id"
          xl="2"
          lg="2"
          md="3"
          sm="3"
          cols="4"
          class="mt-1"
        >
          <div>
            <validation-provider
              rules="validate-amount-zero"
              v-slot="{ errors }"
            >
              <label>{{ service.text }}</label>
              <money
                :id="`inp-${index}`"
                v-model="service.mont"
                v-bind="vMoney"
                :disabled="readOnly"
                class="form-control"
                :class="errors[0] ? 'border-danger' : ''"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </b-col>
        <b-col class="mt-1" xl="2" lg="2" md="3" sm="3" cols="6">
          <label>Total Others</label>
          <div
            class="form-control d-flex align-items-center justify-content-start bg-transparent border"
          >
            $ {{ totalHardship | currencyZero }}
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col xl="4" lg="4" md="4" sm="4" cols="4" class="mt-1">
          <div>
            <label>Total monthly living cost</label>
            <div
              class="form-control d-flex align-items-center justify-content-start bg-transparent border"
            >
              <slot name="total-month"></slot>
            </div>
          </div>
        </b-col>
        <b-col xl="2" lg="2" md="3" sm="3" cols="4" class="mt-1">
          <div>
           
              <label>Amount available</label>
              <slot name="amount-available"></slot>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    totalUtilities() {
      const total = this.hardship.utilities.reduce(
        (sum, service) => sum + Number(service.mont),
        0
      );
      return total;
    },

    totalHardship() {
      const total = this.hardship.others.reduce(
        (sum, service) => sum + Number(service.mont),
        0
      );
      return total;
    },

    hardship: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  data() {
    return {
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },

      optionsUtilities: [
        { id: 1, text: "Electricity", mont: 0 },
        { id: 2, text: "Gas", mont: 0 },
        { id: 3, text: "Water", mont: 0 },
        { id: 4, text: "Telephone", mont: 0 },
        { id: 5, text: "Cable", mont: 0 },
        { id: 6, text: "Internet", mont: 0 },
        { id: 7, text: "Trash", mont: 0 },
      ],

      optionsHard: [
        { id: 1, text: "Child Support", mont: 0 },
        { id: 2, text: "Spouse Suppport", mont: 0 },
        { id: 3, text: "Clothes", mont: 0 },
        { id: 4, text: "Education", mont: 0 },
        { id: 5, text: "Health & SelfCare", mont: 0 },
        { id: 6, text: "Transportation", mont: 0 },
        { id: 7, text: "Laundry", mont: 0 },
        { id: 8, text: "Donations", mont: 0 },
        { id: 9, text: "Family Support", mont: 0 },
        { id: 10, text: "Recreation", mont: 0 },
        { id: 11, text: "Others", mont: 0 },
      ],
    };
  },
  methods: {
    async validateForm() {
      const isValidated = await this.$refs.formServices.validate();
      return isValidated;
    },

    addCards() {
      this.hardship.cards.push({
        id: this.hardship.cards.length + 1,
        card: "",
        pay: 0,
      });
    },
    deleteCards(id) {
      const index = this.hardship.cards.findIndex((x) => x.id == id);
      if (index !== -1) {
        this.hardship.cards.splice(index, 1);
      }
    },

    addCar() {
      const index = this.hardship.cars.length;
      const car = {
        id: index + 1,
        amount: 0,
      };
      this.hardship.cars.push(car);
    },
  },
};
</script>