import { amgApi } from "@/service/axios";
class LeadCardService {
  async getLeadCards(params) {
    const data = await amgApi.post(
      "/commons/lead/get-lead-cards",
      params
    );
    return data;
  }
}
export default new LeadCardService();
