<template>
  <router-view />
</template>

<script>
// Store
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isSectionClient() {
      return this.$route.name == "debt-s-clients";
    },
  },
  async created() {
    await this.notificationOldQquestionnaire();
  },
  methods: {
    ...mapActions({
      getOldQuestionnaire: "modal-actions/A_VALIDATE_OLD_QUESTIONNAIRE",
    }),
    async notificationOldQquestionnaire() {
      if (this.currentUser.role_id == 3 && this.isSectionClient) {
        this.getOldQuestionnaire({ advisor_id: this.currentUser.user_id });
      }
    },
  },
};
</script>




