export default [
  {
    key: "lead_name",
    label: "Client",
    thStyle: { width: "230px" },
  },
  {
    key: "mobile",
    tdClass: "pt-2",
  },
  {
    key: "type_payment",
    label: "PT",
    class: "text-center",
    tdClass: "pt-2",
  },
  {
    key: "last_payment",
    label: "LP",
    class: "text-center",
    tdClass: "pt-2",
  },
  {
    key: "catcher_by",
    label: "catcher",
    tdClass: "pt-2",
  },
  {
    key: "seller_by",
    label: "seller",
    tdClass: "pt-2",
  },
  {
    key: "module",
    label: "origin",
    thClass: "text-center",
    tdClass: "pt-2 text-center",
  },
  {
    key: "program_name",
    label: "program",
    thClass: "text-center",
    tdClass: "pt-2 text-center",
  },
  {
    key: "charges",
    label: "Fee & Charges",
    tdClass: "pt-2",
  },
  {
    key: "monthly_amount",
    label: "MP",
    class: "text-center",
    tdClass: "pt-2",
  },
  {
    key: "created_at",
    label: "Creation Date",
    class: "text-center",
    tdClass: "pt-2",
  },
  {
    key: "tracking",
    class: "text-center",
  },
  {
    key: "actions",
  },
];
