var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-nav',{staticClass:"mt-1 ml-1 mb-0",attrs:{"card-header":"","pills":""}},_vm._l((_vm.tabs),function(tab,index){return _c('b-nav-item',{key:index,attrs:{"to":{ name: tab.route },"exact":"","exact-active-class":"active","link-classes":[
        _vm.$route.matched[3].name ==
          _vm.$route.matched[2].meta.routeForApprovedClient &&
        tab.title == 'For Approval'
          ? 'router-link-active'
          : '',
        'sub-tab-nav',
        'px-3 h-full',
        _vm.bgTabsNavs ]}},[_vm._v(" "+_vm._s(tab.title)+" "),(_vm.G_ADDCHANGE_COUNTERS > 0 && tab.title == 'For Approval')?_c('span',{staticClass:"ml-1"},[_c('b-badge',{attrs:{"pill":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.G_ADDCHANGE_COUNTERS > 99 ? "99+" : _vm.G_ADDCHANGE_COUNTERS)+" ")])],1):_vm._e()])}),1),_c('b-card',{staticClass:"border-3 border-top-info border-table-radius px-0",attrs:{"no-body":""}},[_c('router-view',{key:_vm.$route.name})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }