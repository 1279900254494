<template>
  <b-modal
    id="image-modal"
    v-model="control"
    header-bg-variant="transparent"
    centered
    class="custom-modal"
    size="md"
    hide-footer
    hide-header
    @hidden="close"
  >
    <!-- {{ urlImage }} -->
    <div class="d-flex justify-content-center align-items-center">
      <div
        ref="zoomContainer"
        class="zoom-container"
        :style="{ cursor: isDragging ? 'grabbing' : 'grab' }"
        @mousedown="startDragging"
        @mousemove="handleDragging"
        @mouseup="stopDragging"
        @dblclick="resetZoom"
      >
        <b-img
          ref="image"
          :src="urlImage"
          fluid
          class="initial-image-size zoomable-image"
          @wheel="handleWheel"
        />
      </div>
    </div>

    <div class="fixed-top">
      <div style="margin: 2rem;">
        <!-- <b-button-group> -->
        <button
          v-b-tooltip.hover="'Zoom in.'"
          class="btn-option mr-1"
          @click="zoomIn"
        >
          <feather-icon
            icon="ZoomInIcon"
            size="25"
          />
        </button>
        <button
          v-b-tooltip.hover="'Zoom out.'"
          class="btn-option mr-1"
          @click="zoomOut"
        >
          <feather-icon
            icon="ZoomOutIcon"
            size="25"
          />
        </button>
        <button
          v-b-tooltip.hover="'Rotate left.'"
          class="btn-option mr-1"
          @click="rotateLeft"
        >
          <feather-icon
            icon="RotateCcwIcon"
            size="25"
          />
        </button>
        <button
          v-b-tooltip.hover="'Rotate right.'"
          class="btn-option mr-1"
          @click="rotateRight"
        >
          <feather-icon
            icon="RotateCwIcon"
            size="25"
          />
        </button>
        <button
          v-b-tooltip.hover="'Download.'"
          class="btn-option mr-1"
          style="background: #cddc39"
          @click="downloadFile()"
        >
          <feather-icon
            icon="DownloadIcon"
            size="25"
          />
        </button>
        <button
          v-b-tooltip.hover="'Close.'"
          class="btn-option mr-1"
          style="background: #e91e63"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="25"
          />
        </button>
        <!-- </b-button-group> -->
      </div>
    </div>

  </b-modal>
</template>

<script>
export default {
  props: {
    urlImage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      control: false,
      zoomLevel: 1,
      rotation: 0,
      isDragging: false,

      initialMouseX: 0,
      initialMouseY: 0,
      initialTranslateX: 0,
      initialTranslateY: 0,
    };
  },

  mounted() {
    this.control = true;
  },

  methods: {
    close() {
      this.$emit('hidden');
    },
    downloadFile() {
      const link = document.createElement('a');
      link.href = this.urlImage;
      link.target = '__blank';
      link.download = 'image.jpg'; // Set a default filename or customize based on your needs
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    zoomIn() {
      if (this.zoomLevel < 2) {
        this.zoomLevel += 0.2; // Puedes ajustar la cantidad de zoom que desees
        this.updateImageSize();
      }
    },
    zoomOut() {
      if (this.zoomLevel > 1) {
        this.zoomLevel -= 0.2; // Puedes ajustar la cantidad de zoom que desees
        this.updateImageSize();
      }
    },

    rotateLeft() {
      this.rotation -= 90;
      this.updateImageTransform();
    },

    rotateRight() {
      this.rotation += 90;
      this.updateImageTransform();
    },

    updateImageTransform() {
      this.$refs.image.style.transform = `scale(${this.zoomLevel}) rotate(${this.rotation}deg)`;
    },

    updateImageSize() {
      this.$refs.image.style.transform = `scale(${this.zoomLevel})`;
    },

    handleWheel(event) {
      event.preventDefault();
      const { zoomContainer } = this.$refs;
      const { image } = this.$refs;
      const zoomFactor = 0.1; // Puedes ajustar la cantidad de zoom que desees

      let { zoomLevel } = this;
      zoomLevel += event.deltaY > 0 ? -zoomFactor : zoomFactor;
      zoomLevel = Math.min(Math.max(zoomLevel, 1), 2); // Limita el zoom entre 1x y 4x

      this.zoomLevel = zoomLevel;

      // Ajusta la posición para centrarse en el cursor del mouse
      const { clientX, clientY } = event;
      const containerRect = zoomContainer.getBoundingClientRect();
      const offsetX = clientX - containerRect.left;
      const offsetY = clientY - containerRect.top;
      const deltaX = (offsetX - zoomContainer.clientWidth / 2) / zoomContainer.clientWidth;
      const deltaY = (offsetY - zoomContainer.clientHeight / 2) / zoomContainer.clientHeight;

      const newTranslateX = -deltaX * (image.clientWidth * zoomLevel - zoomContainer.clientWidth);
      const newTranslateY = -deltaY * (image.clientHeight * zoomLevel - zoomContainer.clientHeight);

      image.style.transform = `scale(${zoomLevel}) translate(${newTranslateX}px, ${newTranslateY}px)`;
    },

    startDragging(event) {
      this.isDragging = true;
      this.initialMouseX = event.clientX;
      this.initialMouseY = event.clientY;
      const { transform } = this.$refs.image.style;
      const match = transform.match(/translate\(([^,]+)px,\s([^,]+)px\)/);
      if (match) {
        this.initialTranslateX = parseFloat(match[1]);
        this.initialTranslateY = parseFloat(match[2]);
      } else {
        this.initialTranslateX = 0;
        this.initialTranslateY = 0;
      }
    },

    handleDragging(event) {
      if (this.isDragging) {
        const currentMouseX = event.clientX;
        const currentMouseY = event.clientY;
        const deltaX = currentMouseX - this.initialMouseX;
        const deltaY = currentMouseY - this.initialMouseY;
        const newTranslateX = this.initialTranslateX + deltaX;
        const newTranslateY = this.initialTranslateY + deltaY;
        this.$refs.image.style.transform = `scale(${this.zoomLevel}) translate(${newTranslateX}px, ${newTranslateY}px)`;
      }
    },

    stopDragging() {
      this.isDragging = false;
    },

    resetZoom() {
      this.zoomLevel = 1;
      this.updateImageSize();
    },

  },
};
</script>

<style scoped>
.custom-modal {
  transition: none !important;
  display: flex;
  align-items: center;
  justify-content: center;

}

.custom-modal.fade.show {
  opacity: 1;
  transform: scale(1) !important;
  transition: opacity 0.3s, transform 0.3s;
}

.custom-modal.fade:not(.show) {
  opacity: 0;
  transform: scale(0.9) !important;
  transition: opacity 0.3s, transform 0.3s;
}

.zoomable-image {
  transition: transform 0.3s; /* Add a smooth transition when zooming */
  max-width: 100%; /* Ensure the image doesn't exceed the container width */
  max-height: 100vh; /* Ensure the image doesn't exceed the container height */
  width: auto; /* Allow the image to scale proportionally */
  height: auto; /* Allow the image to scale proportionally */
}

.zoom-container {
  overflow: hidden;
  position: relative;
  max-width: 80vw;
  max-height: 80vh;
  cursor: grab;
}

.zoom-container:active {
  cursor: grabbing;
}

.fixed-top {
    /* position: fixed; */
    /* top: 10px; Puedes ajustar la distancia deseada desde la parte superior */
    /* margin: 0px 45%; */
    /* transform: translateX(-50%); Centrar horizontalmente */
    /* margin: 50px 0; */
}

.initial-image-size {
  width: 990px; /* Establece el ancho inicial */
}

.btn-option{
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: #9bcefa;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-option:hover{
  background: #2980b9;

}

.hover-btn:active {
  background-color: #216a94;
}

</style>
