import CallLogNav from "@/views/specialists/sub-modules/analyst-department/views/ring-central/call-log/navigation/call-log.nav.js";
import DashboardCallsNav from "@/views/specialists/sub-modules/analyst-department/views/ring-central/dashboard-calls/navigation/dashboard-calls.nav.js";

export default {
    title: "Ring Central",
    icon: "PhoneCallIcon",
    children: [
        CallLogNav,
        DashboardCallsNav,
    ],
}