export default {
	path: "report",
	name: "preport-tax-research", // Corregir conflicto, ruta report siendo usada por schedules
	component: () => import(/* webpackChunkName: "TaxResearchChargeback" */ "@/views/tax-research/views/report/PaymentReport.vue"),
	meta: {
		pageTitle: "Report",
		breadcrumb: [
			{
				text: "Report",
				active: true,
			},
		],
	},
}
