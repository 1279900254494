import { amgApi } from "@/service/axios";

class ClientDashboardService {
  // Client Innformation
  async search(params) {
    const data = await amgApi.post("/commons/search-account", params);
    return data;
  }

  async getFeeAndCharges(params) {
    const data = await amgApi.post("/commons/get-fee-and-charges", params);
    return data;
  }

  // async updateDrb(params) {
  //   const data = await amgApi.post("/commons/update-drb", params)
  //   return data
  // }

  async userModule(id, params) {
    const data = await amgApi.post(`/commons/user-module/${id}`, params);
    return data;
  }

  async allScore(params) {
    const data = await amgApi.post("/clients/all-scores", params);
    return data;
  }

  async saveScore(params) {
    const data = await amgApi.post("/clients/save-score", params);
    return data;
  }

  async editScore(params) {
    const data = await amgApi.post("/clients/edit-score", params);
    return data;
  }

  async deleteScore(params) {
    const data = await amgApi.post("/clients/delete-score", params);
    return data;
  }

  async getLastScore(params) {
    const data = await amgApi.post("/clients/get-last-score", params);
    return data;
  }

  // Modal History
  async historyStatus(params) {
    const data = await amgApi.post("/commons/history-status", params);
    return data;
  }

  async stateLeads() {
    const data = await amgApi.get("/commons/state-leads");
    return data;
  }

  async stateEEUU() {
    const data = await amgApi.get("/commons/get-eeuu-states");
    return data;
  }

  async setCoApplicant(params) {
    const data = await amgApi.post("/commons/set-coapplicant", params);
    return data;
  }

  async insertCreditPayment(params) {
    const data = await amgApi.post("/commons/insert-credit-payment", params);
    return data;
  }
  async getCoApplicant(params) {
    const data = await amgApi.post("/commons/get-coapplicant", params);
    return data;
  }

  async getHistoryCoApplicant(params) {
    const data = await amgApi.post("/commons/get-history-coapplicant", params);
    return data;
  }

  async getContractSales(params) {
    const data = await amgApi.post("/sales-made/get-contract-sales", params);
    return data;
  }

  // Modal Claims
  // async getLastClaim(params) {
  //   const data = await amgApi.post(
  //     "/sales-made/debt-solution/claims/get-last-claim",
  //     params
  //   );
  //   return data;
  // }

  async getLastClaim(params) {
    const data = await amgApi.get("/commons/claims-v2/get-last-claim", {
      params,
    });
    return data;
  }

  async getHistoryClaims(params) {
    const data = await amgApi.post(
      "/sales-made/debt-solution/claims/get-history",
      params
    );
    return data;
  }

  async editMemo(params) {
    const data = await amgApi.post("/debt-solution/edit-memo", params);
    return data;
  }

  // Modal Share
  async allUsersSuperviser(params) {
    const data = await amgApi.post("/commons/all-users-superviser", params);
    return data;
  }

  async shareAccount(params) {
    const data = await amgApi.post("/commons/share-account", params);
    return data;
  }

  async searchAccountShare(params) {
    const data = await amgApi.post("/commons/search-account-share", params);
    return data;
  }

  async changeStateShare(params) {
    const data = await amgApi.post("/commons/change-state-share", params);
    return data;
  }

  // Modal Program List
  async searchProgramList(params) {
    const data = await amgApi.post("/clients/search-program-list", params);
    return data;
  }

  // Modal Program List
  async getOthersProgramsClients(params) {
    const data = await amgApi.post(
      "/clients/get-others-programs-clients",
      params
    );
    return data;
  }

  //  Ncr Realtor
  async searchAccountNcr(params) {
    const data = await amgApi.post("/commons/search-account-ncr", params);
    return data;
  }

  async insertNcrRealtor(params, header) {
    const data = await amgApi.post(
      "/commons/insert-ncr-realtor",
      params,
      header
    );
    return data;
  }

  // Modal Create NCR
  async insertNcr(params) {
    const data = await amgApi.post("/commons/insert-ncr", params);
    return data;
  }

  // Modal Approve Superviser
  async approveSuperviser(params) {
    const data = await amgApi.post(
      "/commons/approved-superviser-program",
      params
    );
    return data;
  }

  // Modal Status Report
  async savePdfStatement(params) {
    const data = await amgApi.post("/commons/save-pdf-statement", params);
    return data;
  }

  async sendEmailStatus(params) {
    const data = await amgApi.post("/commons/send-statement-client", params);
    return data;
  }

  // Modal Tracking Change Mobile
  async allTrackingFields(params) {
    const data = await amgApi.post("/clients/all-tracking-fields-lead", params);
    return data;
  }

  // Modal Change Advisor
  async advisorList(id, params) {
    const data = await amgApi.post(`/commons/user-module/${id}`, params);
    return data;
  }

  async changeAdvisor(params) {
    const data = await amgApi.post("/commons/change-advisor", params);
    return data;
  }

  async historyAdvisor(params) {
    const data = await amgApi.post("/commons/history-advisor", params);
    return data;
  }

  // Modal Monthly Edit
  async searchPayment(params) {
    const data = await amgApi.post("/commons/search-payment", params);
    return data;
  }

  async paymentDays() {
    const data = await amgApi.get("/commons/payment-days");
    return data;
  }

  async changeMonthlyPayment(params) {
    const data = await amgApi.post("/commons/updated-autorize", params);
    return data;
  }

  // Modal History Monthly
  async searchHistoryMonthly(params) {
    const data = await amgApi.post("/commons/history-monthly", params);
    return data;
  }

  async sendLetterclient(params) {
    const data = await amgApi.post("/commons/send-letter-client", params);
    return data;
  }

  async checkoutPayment(params) {
    const response = await amgApi.post(
      "/authorize/checkout-payment-status",
      params
    );
    return response;
  }

  // get-history-statement-client
  async getHistoryStatementClient(params) {
    const data = await amgApi.post(
      "/commons/get-history-statement-client",
      params
    );
    return data;
  }
  async sendLetterclient(params) {
    const data = await amgApi.post("/commons/send-letter-client", params);
    return data;
  }

  async createTrackingDashboard(params) {
    const data = await amgApi.post(
      "credit-experts-digital/dashboard/create-tracking",
      params
    );
    return data;
  }

  async updateTrackingDashboard(params) {
    const data = await amgApi.post(
      "credit-experts-digital/dashboard/update-tracking",
      params
    );
    return data;
  }

  async searchDashboardTrackingCed(params) {
    const data = await amgApi.post(
      "credit-experts-digital/dashboard/search-dashboard-tracking-ced",
      params
    );
    return data;
  }

  async insertContactCallroundCommision(params) {
    const data = await amgApi.post(
      "/credit-experts-digital/create-commision",
      params
    );
    return data;
  }

  async insertAnsweredCallCommision(params) {
    const data = await amgApi.post(
      "/credit-experts-digital/create-commision",
      params
    );
    return data;
  }

  async updateMotiveTypeClient(params) {
    const data = await amgApi.post(
      "/commons/credit-experts-digital/update-motive-type-client",
      params
    );
    return data;
  }

  async updateClientSchedule(params) {
    try {
      const data = await amgApi.post(
        "/credit-experts-digital/dashboard/update-client-schedule",
        params
      );
      return data;
    } catch (err) {
      console.error("Error in updateClientSchedule", err);
    }
  }

  async getClientScheduleHistory(params) {
    try {
      const { data } = await amgApi.post(
        "/credit-experts-digital/dashboard/get-client-schedule-history",
        params
      );
      return data;
    } catch (err) {
      console.error("Error in getClientScheduleHistory", err);
    }
  }

  async advisorChangeWoContent(params) {
    const data = await amgApi.post(
      "/commons/advisor-change-wo-content",
      params
    );
    return data;
  }

  async getStatusClientAccount(params) {
    const data = await amgApi.post(
      "/commons/get-status-client-account",
      params
    );
    return data;
  }
  async countAnalysisCr(params) {
    const data = await amgApi.post("request-ncr/count-analysis-cr", params);
    return data;
  }

  async getNcrDataByClient(params) {
    try {
      const data = await amgApi.post(
        "request-ncr/get-ncr-data-by-client",
        params
      );
      return data;
    } catch (err) {
      throw err;
    }
  }

  async ApproveOrDeclineHeldTransaction(params) {
    try {
      const data = await amgApi.post(
        "/authorize/approve-or-decline-held-transaction",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDeployedNewPaymentsPrograms() {
    try {
      const { data } = await amgApi.post(
        "/commons/get-deployed-new-payments-modules"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCreditReportData(params) {
    try {
      const data = await amgApi.post("/specialists/get-credit-report", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new ClientDashboardService();
