<template>
  <div class="timeline-court-info">
    <div class="container">
      <b-row>
        <b-col>
          <div class="timeline-steps">
            <div
              class="timeline-step mb-5"
              v-for="(event, index) in timelineEvents"
              :key="index"
            >
              <div class="timeline-content">
                <b-badge
                  v-if="
                    event.step_id != 1 &&
                    event.step_id != 2 &&
                    event.step_id != 5
                  "
                  :variant="
                    'light-' +
                    getVariantStep(
                      event.step_id,
                      event.created_at,
                      event.validated_at
                    )
                  "
                  class="icon-badge cursor-pointer"
                  v-b-tooltip.hover.top="`Open Timeline ${event.document_type}`"
                  @click="
                    openModalTracking(
                      event.case_id,
                      event.case_type_document_id
                    )
                  "
                >
                  <feather-icon :icon="getIconStep(event.step_id)" size="16" />
                </b-badge>

                <b-badge
                  v-else
                  :variant="
                    'light-' +
                    getVariantStep(
                      event.step_id,
                      event.created_at,
                      event.validated_at
                    )
                  "
                  class="icon-badge"
                >
                  <feather-icon :icon="getIconStep(event.step_id)" size="16" />
                </b-badge>

                <p class="h6 mt-2 mb-1" :class="isDarkSkin ? 'text-white' : ''">
                  {{ event.created_at | myGlobalDay }}
                </p>
                <p
                  class="h6 text-primary mb-1 mb-lg-0 text-uppercase text-truncate"
                  v-b-tooltip.hover.top="event.steps"
                >
                  <strong>{{ event.steps }}</strong>
                </p>
                <p
                  class="h6 text-success mb-1 mb-lg-0 text-uppercase"
                  v-if="
                    event.step_id != 1 &&
                    event.step_id != 2 &&
                    event.step_id != 5
                  "
                >
                  <strong>{{ event.service_type }}</strong>
                </p>
                <p
                  class="h6 mb-0 mb-lg-0"
                  :class="isDarkSkin ? 'text-white' : 'text-muted'"
                >
                  {{ event.created_by }}
                </p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="container"></div>
    <modal-tracking
      v-if="showModalTracking"
      :caseId="caseId"
      :step="step"
      @close="showModalTracking = false"
    />
  </div>
</template>
<script>
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import modalTracking from "@/views/debt-solution/views/court-info/components/modals/TimelineTrackingModal.vue";
import moment from "moment";
export default {
  components: {
    modalTracking,
  },
  props: {
    caseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {

      timelineEvents: [],
      showModalTracking: false,
    };
  },
  mounted() {
    this.getInfoTimelineByCase();
  },
  methods: {
    async getInfoTimelineByCase() {
      try {
        this.addPreloader();
        const { data } = await CourtInfoService.getInfoTimelineByCase({
          caseId: this.caseId,
        });
        this.timelineEvents = data;
        this.$emit("total_amount", data[0].total_amount);
        this.$emit("pending_total_amount", data[0].pending_total_amount);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    getExpireColor(created, validated) {
      let created_at = moment(created, "YYYY-MM-DD HH:mm:ss");
      let validated_at = moment(validated, "YYYY-MM-DD HH:mm:ss");
      // if the difference in hours between created and validated is greather than 24 hours, return red else return green

      let diff = validated_at.diff(created_at, "hours");

      if (diff > 24) {
        return "danger";
      } else {
        return "success";
      }
    },

    openModalTracking(case_id, step) {
      this.caseId = case_id;
      this.step = step;
      this.showModalTracking = true;
    },
    getIconStep(step) {
      switch (step) {
        case 1:
          return "FilePlusIcon";
        case 2:
          return "CheckIcon";
        case 5:
          return "FlagIcon";
        default:
          return "FileTextIcon";
      }
    },
    getVariantStep(step, created, expired) {
      switch (step) {
        case 1:
          return "primary";
        case 2:
          return this.getExpireColor(created, expired);
        // return "success";
        case 5:
          return "success";
        default:
          return "info";
      }
    },
  },
};
</script>
<style lang="scss">
.timeline-court-info {
  .timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem;
  }

  @media (min-width: 768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
      content: "";
      display: block;
      border-top: 0.25rem dotted #3b82f6;
      width: 3.46rem;
      position: absolute;
      left: 7.5rem;
      top: 0.3125rem;
    }
    .timeline-steps .timeline-step:not(:first-child):before {
      content: "";
      display: block;
      border-top: 0.25rem dotted #3b82f6;
      width: 3.8125rem;
      position: absolute;
      right: 7.5rem;
      top: 0.3125rem;
    }
  }

  .timeline-steps .timeline-content {
    width: 10rem;
    text-align: center;
  }

  .timeline-steps .timeline-content {
    .icon-badge {
      margin-top: -1rem;
      border-radius: 1.5rem;
      height: 3rem;
      width: 3rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in-out;
      &:hover {
        transform: scale(1.5);
      }
    }
  }
}
</style>