import SettingsPermissions from '@/views/ce-digital/sub-modules/settings/views/permissions/SettingsPermissions.vue'
import GridTable from '@/views/ce-digital/sub-modules/settings/views/permissions/components/GridTable.vue'
export default [
  {
    path: '/cedigital/settings/permissions',
    name: 'settings-permissions',
    component: SettingsPermissions,
    redirect: { name: "settings-permissions-seller" },
    meta: {
      permittedRoles: [1, 2, 17],
      pageTitle: 'Permissions',
      breadcrumb: [
        {
          text: 'Permissions',
          active: true,
        },
      ],
      module: 26,
    },
    children: [
      {
        path: "",
        name: "settings-permissions-seller",
        component: GridTable,
        meta: {
          module: 26,
          route: "settings",
          permittedRoles: [1, 17],
          pageTitle: "Permissions",
          breadcrumb: [
            {
              text: "Seller Permissions",
              active: true,
            },
          ],
        },
      },
    ],
  },
]
