<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    title="MONTHLY PAYMENT"
    header-class="p-0"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    @hidden="closeModal"
    no-close-on-backdrop
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bolder">
          Monthly Payment
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="form">
      <b-row>
        <b-col cols="12" lg="5">
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
              style="gap: 5px"
            >
              Client | {{ client.client_name }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" lg="4">
          <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
            <p
              class="m-0"
              style="gap: 5px"
            >
              Service | {{ client.program_name }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" lg="3">
          <template v-if="!inConnection">
            <validation-provider
              v-slot="{ errors }"
              :rules="![5, 7, 18].includes(moduleId) && rule"
            >
              <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
                <div
                  class="m-0 d-flex"
                  style="gap: 5px"
                >
                  <p class="m-0" style="white-space: nowrap;">Amount | </p>
                  <money
                    id="input-2"
                    v-model="ammount"
                    v-bind="maskMoney"
                    class="form-control"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'is-invalid' : ''"
                    :disabled="client.monthly_amount == 0"
                    style="border: none; border-radius: 5px; height: 20px; margin-left: 4px;"
                    :style="isDarkSkin ? 'background-color: #615b5b; color: white;' : 'background-color: #e3e1e1; color: black;'"
                  />
                </div>
              </div>
              <span
                v-if="errors[0] && client.monthly_amount != 0"
                class="text-danger"
                >{{ errors[0] }}</span
              >
              <span
                v-if="client.monthly_amount === '0.00'"
                class="text-danger"
              >
                The monthly amount must be greater than zero
              </span>
            </validation-provider>
          </template>
          <template v-else>
            <b-form-radio-group
              id="radio-group-1"
              v-model="membership"
              :options="options"
              name="radio-options"
              class="mb-1"
            ></b-form-radio-group>
            <b-form-select
              v-if="membership == 1"
              v-model="ammount"
              :options="monthlyOptions"
              class="text-center"
            />
            <template v-else>
              <validation-provider v-slot="{ errors }" :rules="rule">
                <money
                  id="input-2"
                  v-model="ammount"
                  v-bind="maskMoney"
                  class="form-control"
                />
                <span v-if="errors[0]" class="text-danger">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </template>
          </template>
      </b-col>
      </b-row>
      <div
        v-if="
          monthlyPayments.length > 0 &&
          G_PROGRAMS_IN_NEW_PAYMENT.includes(programId)
        "
      >
        <div
          v-if="!notPaidAt"
          class="border rounded p-1 mb-1 text-center"
          style="width: 18rem; margin: 0 0 0 auto"
        >
          <strong>PAID AT: </strong>
          <span class="ml-1 text-uppercase">{{ currentDatetime }}</span>
        </div>
        <b-table
          hover
          responsive
          :items="monthlyPayments"
          :fields="visibleFields"
          class="table-new-customization custom-table"
        >
          <template #cell(date)="data">
            {{ convertDate(data.item.date) }}
          </template>
          <template #cell(pay_at)="data">
            {{ convertDate(data.item.pay_at) }}
          </template>
          <template #cell(amount)="data"> ${{ data.item.amount }} </template>
          <template #cell(status)="data">
            <b-badge class="text-uppercase" :variant="data.item.color">{{
              data.item.status
            }}</b-badge>
          </template>
        </b-table>
      </div>
      <div class="mt-2">
        <b-form-group label="Type Payment">
          <div class="button-group">
            <button
              v-for="paymentType in paymentTypes"
              :key="paymentType.value"
              @click="changeOptionSelected(paymentType.value)"
              :class="{
                'first-button': paymentSelected === 0,
                'last-button': paymentSelected === paymentTypes.length - 1,
                selected: paymentType.value === paymentSelected,
                unselected: paymentType.value !== paymentSelected,
              }"
            >
              {{ paymentType.text }}
            </button>
          </div>
        </b-form-group>
      </div>
      <div class="py-1">
        <b-row v-if="paymentSelected == 1">
          <b-col>
            <ValidationObserver ref="form">
              <b-table
                ref="refCards"
                small
                show-empty
                :fields="fields"
                :items="searchCards"
                class="table-new-customization font-small-3 text-center custom-table"
                sticky-header="50vh"
                :busy.sync="isBusy"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template v-slot:cell(radio)="data">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :name="`card${data.item.id}`"
                    rules="required"
                  >
                    <b-form-radio
                      v-model="card_id"
                      :disabled="data.item.count_declined >= 2"
                      :value="data.item.id"
                      :name="'card' + data.item.id"
                      :state="errors[0] ? false : null"
                      @change="changeCard(data.item.type_card)"
                    />
                  </ValidationProvider>
                </template>

                <template v-slot:cell(card_holder_name)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.cardholdername }}
                  </p>
                </template>
                <template v-slot:cell(card_number)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }}
                  </p>
                </template>
                <template v-slot:cell(type)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.type_card }}
                  </p>
                </template>
                <template v-slot:cell(mm)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.card_expi_month }}
                  </p>
                </template>
                <template v-slot:cell(yy)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.card_expi_year }}
                  </p>
                </template>
                <template v-slot:cell(cvc)="data">
                  <p class="mb-0 font-weight-bold">
                    {{
                      data.item.cardsecuritycode != null
                        ? data.item.cardsecuritycode.length == 3
                          ? "XX" + data.item.cardsecuritycode.substr(2)
                          : "XXX" + data.item.cardsecuritycode.substr(3)
                        : ""
                    }}
                  </p>
                </template>
                <template v-slot:cell(created_by)="data">
                  <p class="mb-0 font-weight-bold">
                    <span>{{ data.item.created_by }}</span>
                    <br />
                    <span>{{ data.item.created_at | myGlobalDay }}</span>
                  </p>
                </template>
                <template v-slot:cell(actions)="data">
                  <feather-icon
                    size="16"
                    icon="TrashIcon"
                    class="cursor-pointer text-danger"
                    @click="openDeleteCreditCard(data.item.id)"
                  />
                </template>
              </b-table>
            </ValidationObserver>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-end">
              <b-button variant="success" @click="createCreditOn = true">
                ADD</b-button
              >
            </div>
          </b-col>
        </b-row>
        <other-payment-options
          v-else
          ref="otherPaymentOptions"
          :amount="ammount"
          :isClient="true"
          :idClientOrLead="client.id"
          :idProgram="this.client.program_id"
          :idModule="this.client.module"
          :idCurrentUser="this.currentUser.user_id"
          :modalityPayment="monthlyPayment"
          :monthlyPayments="monthlyPayments"
          @close="
            closeModal();
            $emit('refreshTable');
          "
        ></other-payment-options>
      </div>
      <delete-credit-card
        v-if="deleteCreditOn"
        :card_id="card_id"
        :lead_id="client.lead_id"
        @refresh="refresh"
        @close="deleteCreditOn = false"
      />
      <create-credit-card
        v-if="createCreditOn"
        :lead="client.lead_id"
        @reload="refresh"
        @close="createCreditOn = false"
      />
    </validation-observer>
    <template #modal-footer>
      <b-button
        v-if="paymentSelected == 1"
        class="d-flex justify-content-center"
        variant="primary"
        @click="insertPayment"
        :disabled="isMultiplePayments"
        :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
      >
        <b-spinner v-if="spinnerOn" small />
        PAY
      </b-button>
      <b-button
        v-else-if="paymentSelected == 2"
        class="d-flex justify-content-center"
        variant="primary"
        @click="saveOtherPayment"
        :disabled="isMultiplePayments"
        :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
      >
        <b-spinner v-if="spinnerOn" small />
        PAY
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import dataFields from "@/views/commons/components/clients/dashboard/options/pay-module/modals/monthly-payment-modal/fields.data.js";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import DeleteCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/DeleteCreditCard.vue";
import CreateCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue";
import { mapGetters } from "vuex";
import OtherPaymentOptions from "@/views/commons/components/clients/dashboard/options/pay-module/modals/OtherPaymentOptions.vue";
import PaymentScheduleService from "@/views/commons/components/clients/components/paymentSchedule/service/payment-schedule.service.js";
import moment from "moment";
export default {
  props: {
    notPaidAt: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DeleteCreditCard,
    CreateCreditCard,
    OtherPaymentOptions,
  },

  data() {
    return {
      membership: 2,
      ammount: null,
      spinnerOn: false,
      type_card: "",
      isBusy: false,
      fields: dataFields,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
      },
      ownControl: false,
      card_id: null,
      deleteCreditOn: false,
      createCreditOn: false,
      monthlyOptions: ["29.99", "39.99", "49.99"],
      options: [
        { text: "Membership", value: 1 },
        { text: "Single", value: 2 },
      ],
      paymentSelected: 1,
      optionTypeSelected: "",
      paymentTypes: [
        { text: "Credit Card", value: 1 },
        { text: "Others", value: 2 },
      ],
      optionsType: [
        { name: "Cash", value: 1 },
        { name: "Check", value: 2 },
        { name: "Money Order", value: 3 },
        { name: "Cashier Check", value: 4 },
        { name: "Deposit", value: 5 },
        { name: "Zelle", value: 6 },
      ],
      fileToUpload: [],
      date: "",
      hour: "",
      fnamedoc: "",
      fsize: "",
      fimage: "",
      serialNumber: "",
      disabledButton: false,
      monthlyPayment: 1,
      monthlyPayments: [],
      fieldsMonthlyPayments: [
        { key: "date", label: "Old Payment Date", visible: true },
        { key: "pay_at", label: "New Payment Date", visible: false },
        { key: "amount", label: "Amount", visible: true },
        { key: "status", label: "Status", visible: true },
      ],
      isMultiplePayments: false,
      isAdvancePayment: null,
      minAmount: 0,
      isPendingAmount: 0,
      currentDatetime: moment().format("MM/DD/YYYY hh:mm a"),
      totalAmountFeePending: 0,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
    }),
    inConnection() {
      return this.moduleId == 20;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    rule() {
      return `validate-amount-multiple:${this.balance},${this.minAmount},${this.isPendingAmount}`;
    },
    visibleFields() {
      return this.fieldsMonthlyPayments.filter((item) => item.visible);
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
    balance() {
      if (this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)) {
        return this.totalAmountFeePending;
      }
      return Math.abs(this.client.balance);
    },
  },
  created() {
    if (!this.inConnection) {
      this.ammount = this.client.monthly_amount;
    }
    if (!this.membership != 1) {
      this.ammount = this.client.monthly_amount;
    }
    this.ownControl = true;
  },
  async mounted() {
    await this.getMinAmountByProgramId();
    if (this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)) {
      await this.getTotalAmountFee();
      await this.getLastPaymentDate();
      this.getDateNow();
    }
  },
  methods: {
    async getTotalAmountFee() {
      try {
        this.addPreloader();
        const params = {
          client_account_id: this.client.id,
        };
        const { data } = await PaymentScheduleService.getTotalAmountFee(params);
        if (data.length === 0) return;
        this.totalAmountFeePending = data.total[0].pending;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    refresh() {
      this.$refs.refCards.refresh();
    },

    openDeleteCreditCard(id) {
      this.card_id = id;
      this.deleteCreditOn = true;
    },

    changeCard(id) {
      this.type_card = id;
    },

    closeModal() {
      this.$emit("close");
    },

    changeOptionSelected(value) {
      this.paymentSelected = value;
    },
    async getLastPaymentDate() {
      try {
        this.addPreloader();
        const { data } = await PaymentScheduleService.getLastPaymentDate({
          client_account_id: this.client.id,
        });
        this.isAdvancePayment =
          data.length > 0 ? moment(data[0].due_date) : moment();
        this.isPendingAmount =
          data.length > 0 ? parseFloat(data[0].pending_amount) : 0;
        this.changeAmount(this.ammount);
      } catch (error) {
        this.showErrorSwal(error);
      }finally {
        this.removePreloader();
      }
    },
    async saveOtherPayment() {
      try {
        const isValid = await this.$refs.form.validate();
        if (!isValid) return;
        this.addPreloader();
        await this.$refs.otherPaymentOptions.saveOtherPayment();
      } catch (error) {
        if (
          this.membership == 2 &&
          this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)
        ) {
          this.$emit("refreshTable");
          this.closeModal();
        }
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
      null;
    },
    async getMinAmountByProgramId() {
      try {
        const { data } = await ClientsOptionsServices.getMinAmountByProgramId({
          program_id: this.client.program_id,
        });
        this.minAmount = data.min;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    // Get the method payment based on conditions
    getMethodPayment() {
      if (this.method_payment === 0) {
        return this.newType === 0 ? 4 : 0;
      }
      return this.types;
    },

    // Get the transaction based on conditions
    getTransaction() {
      return this.method_payment === 0 ? this.transaction : this.specify;
    },

    // Handle response code 1
    handleResponseSuccess(description) {
      if (!this.inConnection) {
        this.isMultiplePayments || this.showSuccessSwal(description);
      }
      this.$emit("paymentSaveSuccessfully", {
        idcard: this.card_id,
        amount: this.ammount,
      });
      this.isMultiplePayments || this.closeModal();
    },

    // Insert the monthly payment
    async insertPayment() {
      try {
        const balance = this.balance;
        const months = Math.ceil(balance / this.ammount);
        const isValid = await this.$refs.form.validate();

        if (!isValid) return;

        this.addPreloader();
        let params = {
          amount: this.ammount,
          idcard: this.card_id,
          method_payment: this.getMethodPayment(),
          idsession: this.currentUser.user_id,
          merchant: 1,
          transaction: this.getTransaction(),
          account: this.client.account,
          date: this.date,
          programid: this.client.program_id,
          typeaction: "0",
          modul: this.moduleId,
          balance: balance,
          months: months,
          sale_id: this.client.sale_id,
          membership: this.membership,
        };
        // only credit experts
        if (
          this.membership == 2 &&
          this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)
        ) {
          //verificar que el ultimo pago no sea mayor al monto minimo por programa (minAmount)
          const length = this.monthlyPayments.length;
          const lastPayment = this.monthlyPayments[length - 1];
          const havePendingAmount = this.isPendingAmount;

          if (
            (havePendingAmount == 0 &&
              parseFloat(lastPayment.amount) < parseFloat(this.minAmount) &&
              parseFloat(this.balance) !== parseFloat(this.ammount)) ||
            (havePendingAmount > 0 &&
              length > 1 &&
              parseFloat(lastPayment.amount) < parseFloat(this.minAmount) &&
              parseFloat(this.balance) !== parseFloat(this.ammount))
          ) {
            const date = lastPayment.date;
            const html = `The  amount for the month <b> ${moment(date).format(
              "MMMM-YYYY"
            )} </b>
                        must be equal or greater than Minimal program Amount <b> $ ${
                          this.minAmount
                        } </b>`;
            this.showWarningSwal("Warning", null, html);
            return;
          }

          this.isMultiplePayments = true;

          for (let i = 0; i < this.monthlyPayments.length; i++) {
            const payment = this.monthlyPayments[i];

            this.showToast(
              "info",
              "top-center",
              `Processing payment (${moment(payment.pay_at).format(
                "MMMM-YYYY"
              )}) ${i + 1} of ${this.monthlyPayments.length}`,
              "CheckIcon",
              "Please wait"
            );

            params.amount = payment.amount;
            params.invoiceNumber =
              `INV${i}-` + Math.floor(Math.random() * Math.pow(10, 9));
            params.status = payment.status;
            params.dateMonthlyPayment = payment.date;
            params.pay_at = payment.pay_at;
            params.isOtherPayment = 0;

            if (i == this.monthlyPayments.length - 1) {
              this.isMultiplePayments = false;
            }

            const { data } = await ClientsOptionsServices.getCheckout(params);

            let responseCode;

            if (data.status == "error") {
              responseCode = data.response.responseCode;
            } else {
              responseCode = data.transaction.responseCode;
            }

            this.showGenericToast({
              position: "top-right",
              variant: data.info.variant,
              icon: data.info.icon,
              title: data.info.title,
              text: data.message,
            });

            if (responseCode == 1) {
              this.isMultiplePayments ||
              this.$emit("refreshPaymentScheduleGrid");
              this.isMultiplePayments || this.$emit("refreshTable");
              this.handleResponseSuccess(data.message);
            } else if (responseCode == 2 || responseCode == 3) {
              this.showErrorSwal("", data.message ?? "Transaction declined");
              this.$emit("refreshPaymentScheduleGrid");
              this.$emit("refreshTable");
              this.closeModal();
              break;
            }
          }
          return;
        }

        // other programs
        const { data } = await ClientsOptionsServices.getCheckout(params);

        this.showGenericToast({
          position: "top-right",
          variant: data.info.variant,
          icon: data.info.icon,
          title: data.info.title,
          text: data.message,
        });

        let responseCode;
        if (data.status == "error") {
          responseCode = data.response.responseCode;
        } else {
          responseCode = data.transaction.responseCode;
        }

        if (responseCode == 1) {
          this.$emit("refreshTable");
          this.handleResponseSuccess(data.message);
        } else if (responseCode == 2 || responseCode == 3) {
          this.$emit("refreshTable");
          this.showErrorSwal("", data.message ?? "Transaction declined");
          this.closeModal();
        }
      } catch (error) {
        if (
          this.membership == 2 &&
          this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)
        ) {
          this.$emit("refreshPaymentScheduleGrid");
          this.$emit("refreshTable");
          this.closeModal();
        }
        this.showErrorSwal(error.response.data.result);
      } finally {
        this.removePreloader();
      }
    },

    async searchCards() {
      try {
        const data = await ClientsOptionsServices.searchcards({
          id: this.client.lead_id,
        });
        return data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    //advance payment preview
    changeAmount(value) {
      const valueBalance = this.balance;
      this.monthlyPayments = [];
      if (
        !(
          (value <= valueBalance && value >= this.minAmount) ||
          value == valueBalance ||
          (this.isPendingAmount > 0 &&
            value < this.minAmount &&
            value >= this.isPendingAmount)
        )
      ) {
        this.isMultiplePayments = true;
        return;
      }
      this.isMultiplePayments = false;
      const Month = this.isAdvancePayment.clone();
      const CurrentMonth = moment();
      const multiple = this.client.monthly_amount;
      const remainingAmount = value - this.isPendingAmount;
      let months = multiple > 0 ? Math.ceil(remainingAmount / multiple) : 0;
      this.isPendingAmount > 0 ? (months += 1) : ""; //verificar si tiene un pago parcial pendiente del mes anterior sumar 1 mes mas
      let amount = value;
      let verifyPayAt = false;
      const currentMonthPayAt = moment();

      for (let i = 0; i < months; i++) {
        const isFirstPayment = i == 0;
        const paymentDate = isFirstPayment
          ? Month.format("YYYY-MM-DD") + " " + moment().format("HH:mm:ss")
          : Month.add(1, "M").format("YYYY-MM-DD") +
            " " +
            moment().format("HH:mm:ss");
        const pendingAmount = isFirstPayment
          ? this.isPendingAmount
          : Math.min(multiple, amount); //verificar si tiene un pago parcial pendiente del mes anterior
        const payment =
          this.isPendingAmount > 0 && value >= this.isPendingAmount
            ? pendingAmount
            : Math.min(multiple, amount);
        const payAt = isFirstPayment
          ? currentMonthPayAt.format("YYYY-MM-DD HH:mm:ss")
          : currentMonthPayAt.add(1, "M").format("YYYY-MM-DD HH:mm:ss");
        const isCurrentMonth =
          Month.format("MM-YY") === CurrentMonth.format("MM-YY");
        const isAdvancePayment = Month.isAfter(CurrentMonth, "month");
        const isLatePayment = Month.isBefore(CurrentMonth, "month");

        if (isFirstPayment && isLatePayment) {
          verifyPayAt = true;
        }

        this.fieldsMonthlyPayments[0].label =
          verifyPayAt == true ? "Old Payment Date" : "Payment Date";
        this.fieldsMonthlyPayments[1].visible =
          verifyPayAt == true ? true : false;

        this.monthlyPayments.push({
          date: paymentDate,
          pay_at: verifyPayAt == true ? payAt : paymentDate,
          amount: payment.toFixed(2),
          color: isCurrentMonth
            ? "light-primary"
            : isAdvancePayment
            ? "light-info"
            : "light-danger",
          status: isCurrentMonth
            ? "CURRENT PAYMENT"
            : isAdvancePayment
            ? "ADVANCE PAYMENT"
            : "LATE PAYMENT",
        });
        amount -= payment;
      }
    },
    convertDate(date) {
      return moment(date).format("MMMM-YYYY");
    },
    getDateNow() {
      setInterval(() => {
        this.currentDatetime = moment().format("MM/DD/YYYY hh:mm a");
      }, 1000);
    },
  },
  watch: {
    membership(newVal) {
      if (newVal == 1) {
        this.ammount = "29.99";
        this.monthlyPayments = [];
      } else {
        this.ammount = this.client.monthly_amount;
      }
    },
    ammount(newVal) {
      if (
        newVal != null &&
        this.isAdvancePayment != null &&
        this.membership == 2 &&
        this.G_PROGRAMS_IN_NEW_PAYMENT.includes(this.programId)
      ) {
        this.changeAmount(newVal);
      }
    },
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.button-group button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.button-group button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.button-group button {
  background-color: gray;
  color: white;
  border: none;
  padding: 5px 8px;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.button-group button.selected {
  background-color: #0077e6;
  transition: background-color 0.3s ease-in-out;
}

.button-group button.unselected {
  background-color: gray;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
</style>
