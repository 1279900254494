<template>
  <div>
    <b-modal
      v-model="mutableIfModal"
      modal
      :title="statusTitle"
      size="lg"
      modal-class="modal-primary"
      title-tag="h3"
      hide-footer
      body-class="mb-2"
      :no-close-on-backdrop="true"
      centered
      @hidden="closeModal"
    >
      <b-row style="margin: 0">
        <base-refund>
          <!-- Row Client -->
          <template #client>
            <b-form-input
              v-model="dataVoidRefund.client_name"
              disabled
            />
          </template>
          <!-- Row Transaction ID -->
          <template #transaction>
            <b-form-input
              v-model="dataVoidRefund.transaction_id"
              disabled
            />
          </template>
          <!-- Row Amount -->
          <template #amount>
            <b-form-input
              v-model="statusAmount"
              disabled
            />
          </template>
          <!-- Row Date -->
          <template #date>
            <b-form-input
              v-model="statusDate"
              disabled
            />
          </template>
        </base-refund>
        <!-- Row Done by -->
        <div class="col-lg-8 col-12 col-xl-8 col-md-8 pb-1">
          <div class="input-group">
            <div class="input-group-prepend">
              <span
                id="basic-addon3"
                class="input-group-text"
              >Done by</span>
            </div>
            <b-form-input
              v-model="statusDone"
              disabled
            />
          </div>
        </div>
        <!-- Text Tarea Comment -->
        <div class="col-lg-12 mt-1">
          <div class="form-group row">
            <span>Comment</span>
            <b-textarea
              v-model="dataVoidRefund.comment"
              disabled
              class="input-form"
              style="height: 140px"
            />
          </div>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment';
// Import Base Refund
import BaseRefund from '../BaseComponent/BaseRefund.vue';
// Import Services
import PaymentService from '../service/payments.service';

export default {
  components: {
    BaseRefund,
  },
  props: {
    modalVoidRefund: {
      type: Boolean,
    },
    idtransaction: {
      type: [Number, String],
    },
  },
  data() {
    return {
      mutableIfModal: this.modalVoidRefund,
      dataVoidRefund: [],
    };
  },
  computed: {
    statusAmount() {
      return `$ ${this.dataVoidRefund.amount}`;
    },
    statusDate() {
      return moment(this.dataVoidRefund.settlement_date).format('MM/DD/YYYY');
    },
    statusDone() {
      return (
        `${this.dataVoidRefund.updated_by
        } | ${
          moment(this.dataVoidRefund.updated_at).format('MM/DD/YYYY hh:mm A')}`
      );
    },
    statusTitle() {
      return this.dataVoidRefund.type_transaction_id == 10
        ? 'VOID'
        : this.dataVoidRefund.type_transaction_id == 11
          ? 'REFUND'
          : '';
    },
  },
  created() {
    this.getVoidRefund(this.idtransaction);
  },
  methods: {
    closeModal() {
      this.$emit('closeInfo', false);
    },
    // Getting Information of Refund
    async getVoidRefund(idtransaction) {
      try {
        this.addPreloader();
        const data = await PaymentService.getVoidRefund({
          idtransaction,
        });
        this.dataVoidRefund = data[0];
        this.removePreloader();
      } catch (error) {
        console.error(error);
        this.showToast(
          'success',
          'top-right',
          'Success',
          'CheckIcon',
          'Glossary Deleted',
        );
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped>
.input-group > .input-group-prepend {
  flex: 0 0 40%;
}
.input-group .input-group-text {
  width: 100%;
}
</style>
