export default [
    {
        key: 'id',
        label: '#',
        thClass: 'text-center',
        tdClass: 'text-center position-relative p-0',
        visible: true
    },
    {
        key: 'accounts',
        label: 'Accounts',
        thClass: 'text-center',
        tdClass: 'p-0 col-2 text-center',
        visible: true
    },
    {
        key: 'classification_ncr',
        label: 'Type',
        thClass: 'text-center',
        tdClass: 'p-0 text-center',
        visible: true
    },
    {
        key: 'transunion',
        label: 'TU',
        thClass: 'text-center',
        tdClass: 'p-0 text-center',
        visible: true
    },
    {
        key: 'experian',
        label: 'EX',
        thClass: 'text-center',
        tdClass: 'p-0 text-center',
        visible: true
    },
    {
        key: 'equifax',
        label: 'EQ',
        thClass: 'text-center',
        tdClass: 'p-0 text-center',
        visible: true
    },
    {
        key: 'detail',
        label: '',
        thClass: 'text-center',
        tdClass: 'p-0 text-center',
        visible: true
    }
]