import store from '@/store';
import Vue from 'vue';

const canCloseProject = () => {
  window.socket.bind('notify-can-close-project', async data => {
    if (data.paragonUserId === store.state.auth.currentUser.user_id) {
      await Vue.prototype.$amgAlert({
        title: 'You can Close this Project',
        icon: 'CheckIcon',
        subtitle: `Project ${data.projectName} has been able to close`,
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }
  });
};
export default canCloseProject;
