export default {
  path: "file-manager",
  name: "quality-file-manager",
  component: () => import(/* webpackChunkName: "QualityFileManager" */ "@/views/crm/views/file-mananger/FileMananger.vue"),
  meta: {
    pageTitle: "File Manager",
    breadcrumb: [
      {
        text: "File Manager",
      },
    ],
  },
};
