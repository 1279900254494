var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"size":"md","hide-footer":"","header-bg-variant":"primary"},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h5',{staticClass:"font-weight-bolder text-uppercase d-flex text-light",staticStyle:{"padding-top":"5px"}},[_c('feather-icon',{staticStyle:{"margin-right":"8px"},attrs:{"icon":"ListIcon","size":"15"}}),_vm._v(" List taks to ticket "+_vm._s(_vm.ticketCustomer.code ? ("#" + (_vm.ticketCustomer.code)) : "")+" ")],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-container',{staticClass:"p-0"},[_c('filter-slot',{staticClass:"filter-slot-new-customization",attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage,"top-paginate":false},on:{"reload":function($event){return _vm.refreshTable()}}},[_c('b-table',{ref:"refListMeetings",staticClass:"position-relative table-new-customization",attrs:{"slot":"table","items":_vm.providerListTasks,"fields":_vm.fields,"primary-key":"id","sticky-header":"45vh","show-empty":"","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(max_date)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(item.max_date)))]),_c('br'),(item.hour_task)?_c('span',[_vm._v(_vm._s(_vm._f("myFullTime")(item.hour_task)))]):_vm._e()]}},{key:"cell(task)",fn:function(ref){
var item = ref.item;
return [_c('strong',[_vm._v(_vm._s(item.title))]),_c('br'),_c('div',{staticClass:"d-block w-auto",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(item.description.length > _vm.limitDescription ? item.description.substring(0, _vm.limitDescription) : item.description)+" "),(item.description.length > _vm.limitDescription)?_c('strong',{staticClass:"text-primary cursor-pointer",staticStyle:{"font-size":"13px"},on:{"click":function($event){return _vm.showDescription(item)}}},[_vm._v("...show")]):_vm._e()])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"variant":_vm.color[item.status - 1]}},[_vm._v(" "+_vm._s(item.status_name)+" ")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.created_at))),_c('br'),_vm._v(" "+_vm._s(item.created_by_name)+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"75px"}},[(item.status === 1)?[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-success",modifiers:{"hover":true,"v-success":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"hover-me cursor-pointer text-success",attrs:{"title":"COMPLETE","icon":"CheckCircleIcon","size":"18"},on:{"click":function($event){return _vm.updateTask(item.id, 2)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"hover-me cursor-pointer mx-1 text-primary",attrs:{"title":"EDIT","icon":"Edit2Icon","size":"18"},on:{"click":function($event){return _vm.updateTask(item.id, 1)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-danger",modifiers:{"hover":true,"v-danger":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",modifiers:{"hover":true,"top":true}}],staticClass:"hover-me cursor-pointer text-danger",attrs:{"title":"DELETE","icon":"Trash2Icon","size":"20"},on:{"click":function($event){return _vm.cancelTask(item.id)}}})]:_vm._e()],2)]}}])})],1)],1)],1),(_vm.showModalTask)?_c('modal-task-component',{attrs:{"ticket-customer":_vm.paramsModalTask,"task-id":_vm.taskId},on:{"closeModal":_vm.closeModalTask,"successTask":_vm.successTask}}):_vm._e(),(_vm.paramsModalDescription.description)?_c('modal-description',{attrs:{"task":_vm.paramsModalDescription},on:{"closeModal":function($event){_vm.paramsModalDescription = {}}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }