<template>
  <div>
    <header-slot> </header-slot>
    <b-row class="mb-2">
      <b-col md="6"
        ><div class="text-center">
          <b-form-select
            v-model="paymentMonth"
            :options="optionMonthsTemporal"
            @input="getDashboardSupCounterInfo"
          /></div
      ></b-col>
      <b-col
        ><div class="text-center">
          <b-form-select
            v-model="paymentYear"
            :options="optionYears"
            @input="getDashboardSupCounterInfo(), getDashboardSupProductivity()"
          /></div
      ></b-col>
    </b-row>
    <b-row>
      <b-col md="2">
        <b-card
          style="height: 495px; display: flex; align-items: center"
          class="p-2"
          no-body
        >
          <b-overlay
            style="height: 495px"
            class="w-100"
            :show="loadingData"
            :variant="skin"
          >
            <h2 class="text-center">
              {{ currentUser.fullName.toUpperCase() }} <br />
              <span class="font-medium-2">{{
                currentUser.role_name.toUpperCase()
              }}</span>
            </h2>

            <hr />
            <b-row>
              <b-col class="mb-1 carolina">
                <div
                  class="p-1 rounded text-center carolina-content carolina-back cursor-pointer"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailSales(1)"
                >
                  <h4>Sales</h4>
                  <hr />
                  <div>
                    <span style="font-size: 2rem">
                      {{
                        counterData.finished_sale
                          ? counterData.finished_sale
                          : 0
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="p-1 rounded text-center carolina-content carolina-front"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailSales(1)"
                >
                  <h4>Sales</h4>
                  <hr />
                  <div class="cursor-pointer">
                    <span style="font-size: 2rem">
                      {{
                        counterData.finished_sale
                          ? counterData.finished_sale
                          : 0
                      }}</span
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mb-1 carolina">
                <div
                  class="p-1 rounded text-center bri-content carolina-back"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailSales(2)"
                >
                  <h4>Unfinished Sales</h4>
                  <hr />
                  <div class="cursor-pointer">
                    <span style="font-size: 2rem">
                      {{
                        counterData.unfinished_sale
                          ? counterData.unfinished_sale
                          : 0
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="p-1 rounded text-center bri-content carolina-front"
                  :class="skin == 'dark' ? 'bg-dark' : 'bg-light'"
                  @click="openModalDetailSales(2)"
                >
                  <h4>Unfinished Sales</h4>
                  <hr />
                  <div class="cursor-pointer">
                    <span style="font-size: 2rem">
                      {{
                        counterData.unfinished_sale
                          ? counterData.unfinished_sale
                          : 0
                      }}</span
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col v-if="lengthAgentValidate > 0" md="8">
        <b-card class="p-2" no-body>
          <b-overlay :show="loadingProductivity" :variant="skin">
            <h2 class="text-center">Productivity</h2>
            <hr />

            <!-- echart -->
            <app-echart-bar
              :key="sellerKey"
              theme="theme-colora"
              :option-data="option"
              :legend="option.legend"
            />
          </b-overlay>
        </b-card>
      </b-col>
      <b-col v-if="lengthAgentValidate === 0" md="8">
        <b-card style="height: 495px" class="p-2" no-body>
          <b-overlay
            :show="loadingProductivity"
            :variant="skin"
            style="height: 495px"
          >
            <h2 class="text-center">Productivity</h2>
            <hr />

            <!-- echart -->
            <h2
              class="d-flex justify-content-center align-content-center align-items-center"
              style="height: 300px"
            >
              No records found
            </h2>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col md="2">
        <b-card style="height: 495px" class="p-2" no-body>
          <b-overlay style="height: 495px" :show="loadingData" :variant="skin">
            <h2 class="text-center">Commissions</h2>

            <hr />
            <sales-commission-component
              :payment-month="paymentMonth"
              :payment-year="paymentYear"
            />
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-card class="p-2" no-body>
          <b-overlay :show="loadingProductivity" :variant="skin">
            <b-row>
              <b-col>
                <h2 class="text-center">Daily Productivity</h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <b-form-checkbox
                    v-model="daylyChange"
                    name="check-button"
                    switch
                    @click="changeDaylyProductivity()"
                  >
                    Change view</b-form-checkbox
                  >
                </div></b-col
              >
            </b-row>

            <hr />

            <!-- echart -->
            <app-echart-doughnut
              v-if="daylyChange"
              :key="weekKey"
              :legend="weeklyConfig.legend"
              :series="weeklyConfig"
            />
            <app-echart-bar
              v-else
              :key="weekKeyBar"
              :option-data="weeklyBar"
              theme="theme-color"
            />
          </b-overlay>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card class="p-2" no-body>
          <b-overlay :show="loadingProductivity" :variant="skin">
            <b-row>
              <b-col>
                <h2 class="text-center">Monthly Productivity</h2>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <b-form-checkbox
                    v-model="monthlyChange"
                    name="check-button"
                    switch
                    @click="changeDaylyProductivityMonth()"
                  >
                    Change view
                  </b-form-checkbox>
                </div></b-col
              >
            </b-row>
            <hr />

            <!-- echart -->
            <app-echart-doughnut
              v-if="monthlyChange"
              :key="monthKey"
              :series="monthlyConfig"
            />

            <app-echart-bar
              v-else
              :option-data="monthlyBar"
              theme="theme-color"
            />
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <modal-detail-sales
      v-if="modalDetailSalesOn"
      :sale-type="saleType"
      :date-month="paymentMonth"
      :date-year="paymentYear"
      @close="modalDetailSalesOn = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import moment from "moment";
import DashboardSalesServices from "@/views/ce-digital/sub-modules/sales/views/dashboards/dashboard-sales-services";
import AppEchartDoughnut from "@core/components/charts/echart/AppEchartDoughnut";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar";
import SalesCommissionComponent from "@/views/ce-digital/sub-modules/sales/views/dashboards/components/SalesCommissionComponent.vue";
import ModalDetailSales from "@/views/ce-digital/sub-modules/sales/views/dashboards/components/ModalDetailSales.vue";

export default {
  components: {
    AppEchartDoughnut,
    AppEchartBar,
    SalesCommissionComponent,
    ModalDetailSales,
  },
  filters: {
    getTime(value) {
      const dateFormat = "h:mm A";
      return moment(value).format(dateFormat);
    },
  },
  data() {
    return {
      modalDetailSalesOn: false,
      saleType: "",
      paymentMonth: parseInt(moment().format("MM")),
      paymentYear: parseInt(moment().format("YYYY")),
      optionMonths: [
        { text: "January", value: 1 },
        { text: "February", value: 2 },
        { text: "March", value: 3 },
        { text: "April", value: 4 },
        { text: "May", value: 5 },
        { text: "June", value: 6 },
        { text: "July", value: 7 },
        { text: "August", value: 8 },
        { text: "September", value: 9 },
        { text: "October", value: 10 },
        { text: "November", value: 11 },
        { text: "December", value: 12 },
      ],
      optionYears: [],

      option: {
        legend: {
          left: 0,
          selected: {
            January: false,
            February: false,
            March: false,
            April: false,
            May: false,
            June: false,
            July: false,
            August: false,
            September: false,
            October: false,
            November: false,
            December: false,
          },
        },
        xAxis: [
          {
            type: "value",
          },
        ],
        yAxis: [
          {
            type: "category",
            data: [],
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "100px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            name: "January",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "February",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "March",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "April",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "May",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "June",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "July",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "August",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "September",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "October",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },
          {
            name: "November",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
          },

          {
            name: "December",
            type: "bar",
            stack: "advertising",
            data: [],
            barWidth: "30%",
            itemStyle: {
              barBorderRadius: [0, 10, 10, 0],
            },
          },
        ],
      },
      lengthAgentValidate: 0,
      sellerKey: 0,
      weeklyBar: {
        xAxis: [
          {
            type: "category",
            data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            barGap: "70%",
            barCategoryGap: "70%",
            type: "bar",
            barWidth: "70%",
            data: [],
          },
        ],
      },
      monthlyBar: {
        xAxis: [
          {
            type: "category",
            data: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            barGap: "70%",
            barCategoryGap: "70%",
            type: "bar",
            barWidth: "70%",
            data: [],
          },
        ],
      },
      monthlyConfig: [
        {
          top: "15%",
          left: "center",
          width: "76%",

          emphasis: {
            focus: "self",
            label: {
              show: true,
              fontSize: "15",
              fontWeight: "bold",
            },
          },
          minShowLabelAngle: 5,
          minAngle: 5,
          color: [
            "#FF8A80",
            "#FF80AB",
            "#EA80FC",
            "#B388FF",
            "#8C9EFF",
            "#82B1FF",
            "#80D8FF",
            "#FF9E80",
            "#00BCD4",
            "#64FFDA",
            "#43A047",
            "#8D6E63",
          ],
          name: "Monthly Productivity",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: "outside",
            formatter: "{b} : {c}",

            fontSize: 12,
          },
          labelLine: {
            show: true,
          },
          data: [],
        },
      ],
      weeklyConfig: [
        {
          top: "15%",
          left: "center",
          width: "76%",

          emphasis: {
            focus: "self",
            label: {
              show: true,
              fontSize: "15",
              fontWeight: "bold",
            },
          },
          minShowLabelAngle: 10,
          minAngle: 5,
          color: [
            "#FF8A80",
            "#FF80AB",
            "#EA80FC",
            "#B388FF",
            "#8C9EFF",
            "#82B1FF",
            "#80D8FF",
            "#FF9E80",
            "#00BCD4",
            "#64FFDA",
            "#43A047",
            "#8D6E63",
          ],
          name: "Monthly Productivity",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: "outside",
            formatter: "{b} : {c}",

            fontSize: 12,
          },
          labelLine: {
            show: true,
          },
          data: [],
        },
      ],
      monthlyChange: false,
      daylyChange: false,
      weekKey: 0,
      weekKeyBar: 0,

      another: 0,
      currentMonth: new Date(),
      selectedDate: new Date(),
      events: [],
      transitionDirection: "",
      currentPage: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },

      //
      optionCaro: {
        backgroundColor: "rgba(0,0,0,0)",
        textStyle: {
          color: "#999999",
        },
        title: {
          textStyle: {
            color: "#666666",
          },
          subtextStyle: {
            color: "#999999",
          },
        },
        color: [],

        tooltip: {
          trigger: "axis",
        },
        legend: {
          textStyle: {
            color: "#999999",
          },
          data: ["Sales", "Add Service", "Change Service"],
        },
        label: {
          normal: {
            textStyle: {
              color: "#349e8e",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },

        xAxis: {
          color: "#801414",
          categoryAxis: {
            axisLabel: {
              show: true,
              textStyle: {
                color: "#801414",
              },
            },
          },
          type: "category",
          textStyle: {
            color: "#ca1b1b",
          },

          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Sales",
            type: "line",

            data: [],
          },
          {
            name: "Add Service",
            type: "line",

            data: [],
          },
          {
            name: "Change Service",
            type: "line",

            data: [],
          },
        ],
      },

      monthKey: 0,

      loadingData: true,
      loadingProductivity: true,
      rowss: [],
      selectDate: "",
      sellerData: [],
      counterData: [],
    };
  },

  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),

    optionMonthsTemporal() {
      return this.paymentYear == moment().format("YYYY")
        ? this.optionMonths.slice(0, moment().format("MM"))
        : this.optionMonths;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  watch: {
    paymentMonth(oldVal, val) {
      this.inicializeProductivity(oldVal);

      this.decreaseProductivity(val);
    },
    skin(val) {
      if (val === "light") {
        this.optionCaro.color = ["#4DD0E1", "#00ACC1", "#006064", "#00695C"];
      } else {
        this.optionCaro.color = ["#039BE5", "#0288D1", "#01579B", "#80D8FF"];
      }
    },
  },

  async created() {
    await this.getDashboardSupCounterInfo();
    await this.getDashboardSupProductivity();

    this.rows = this.currentMonth;
    if (this.isDarkSkin) {
      this.optionCaro.color = ["#039BE5", "#0288D1", "#01579B", "#80D8FF"];
    } else {
      this.optionCaro.color = ["#4DD0E1", "#00ACC1", "#006064", "#00695C"];
    }
    this.optionYears = [];
    // years
    for (let x = 2022; x <= moment().format("YYYY"); x++) {
      this.optionYears.push({ text: x, value: x });
    }
    this.inicializeProductivity(this.paymentMonth);
  },
  methods: {
    openModalDetailSales(saleType) {
      this.saleType = saleType;
      this.modalDetailSalesOn = true;
    },

    // inicialize productivity of sellers by the current month
    inicializeProductivity(val) {
      const date = moment(
        `${val}-${moment().format("DD")}-${this.paymentYear}`
      ).format("MM");

      if (date === "1") {
        this.option.legend.selected.January = true;
      } else if (date === "2") {
        this.option.legend.selected.February = true;
      } else if (date === "3") {
        this.option.legend.selected.March = true;
      } else if (date === "4") {
        this.option.legend.selected.April = true;
      } else if (date === "5") {
        this.option.legend.selected.May = true;
      } else if (date === "6") {
        this.option.legend.selected.June = true;
      } else if (date === "7") {
        this.option.legend.selected.July = true;
      } else if (date === "8") {
        this.option.legend.selected.August = true;
      } else if (date === "9") {
        this.option.legend.selected.September = true;
      } else if (date === "10") {
        this.option.legend.selected.October = true;
      } else if (date === "11") {
        this.option.legend.selected.November = true;
      } else if (date === "12") {
        this.option.legend.selected.December = true;
      }
    },
    decreaseProductivity(val) {
      const date = moment(
        `${val}-${moment().format("DD")}-${this.paymentYear}`
      ).format("MM");

      if (date === "1") {
        this.option.legend.selected.January = false;
      } else if (date === "2") {
        this.option.legend.selected.February = false;
      } else if (date === "3") {
        this.option.legend.selected.March = false;
      } else if (date === "4") {
        this.option.legend.selected.April = false;
      } else if (date === "5") {
        this.option.legend.selected.May = false;
      } else if (date === "6") {
        this.option.legend.selected.June = false;
      } else if (date === "7") {
        this.option.legend.selected.July = false;
      } else if (date === "8") {
        this.option.legend.selected.August = false;
      } else if (date === "9") {
        this.option.legend.selected.September = false;
      } else if (date === "10") {
        this.option.legend.selected.October = false;
      } else if (date === "11") {
        this.option.legend.selected.November = false;
      } else if (date === "12") {
        this.option.legend.selected.December = false;
      }
    },
    // get the productivity of sellers of the year
    async getDashboardSupProductivity() {
      try {
        this.loadingProductivity = true;
        const params = {
          user_id: this.currentUser.user_id,
          date: moment(
            `${this.paymentYear}-${this.paymentMonth
              .toString()
              .padStart(2, "0")}-${moment().format("DD")}`
          ).format("YYYY-MM-DD"),
          // date: moment(`${this.paymentMonth}-${moment().format('DD')}-${this.paymentYear}`).format('YYYY-MM-DD'),
        };
        const response =
          await DashboardSalesServices.getDashboardSupProductivitySeller(
            params
          );
        if (response.status === 200) {
          this.sellerData = response.data[0];
          this.lengthAgentValidate = this.sellerData.seller_data
            ? JSON.parse(this.sellerData.seller_data).length
            : 0;
          if (this.sellerData.seller_data) {
            this.option.yAxis[0].data = [];
            this.option.series[0].data = [];
            this.option.series[1].data = [];
            this.option.series[2].data = [];
            this.option.series[3].data = [];
            this.option.series[4].data = [];
            this.option.series[5].data = [];
            this.option.series[6].data = [];
            this.option.series[7].data = [];
            this.option.series[8].data = [];
            this.option.series[9].data = [];
            this.option.series[10].data = [];
            this.option.series[11].data = [];
            // recorrer el array de selleres
            for (
              let i = 0;
              i < JSON.parse(this.sellerData.seller_data).length;
              i++
            ) {
              if (JSON.parse(this.sellerData.seller_data)[i].date == 1) {
                this.option.series[0].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
              if (JSON.parse(this.sellerData.seller_data)[i].date == 2) {
                this.option.series[1].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
              if (JSON.parse(this.sellerData.seller_data)[i].date == 3) {
                this.option.series[2].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
              if (JSON.parse(this.sellerData.seller_data)[i].date == 4) {
                this.option.series[3].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
              if (JSON.parse(this.sellerData.seller_data)[i].date == 5) {
                this.option.series[4].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
              if (JSON.parse(this.sellerData.seller_data)[i].date == 6) {
                this.option.series[5].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
              if (JSON.parse(this.sellerData.seller_data)[i].date == 7) {
                this.option.series[6].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
              if (JSON.parse(this.sellerData.seller_data)[i].date == 8) {
                this.option.series[7].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
              if (JSON.parse(this.sellerData.seller_data)[i].date == 9) {
                this.option.series[8].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
              if (JSON.parse(this.sellerData.seller_data)[i].date == 10) {
                this.option.series[9].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
              if (JSON.parse(this.sellerData.seller_data)[i].date == 11) {
                this.option.series[10].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
              if (JSON.parse(this.sellerData.seller_data)[i].date == 12) {
                this.option.series[11].data.push(
                  JSON.parse(this.sellerData.seller_data)[i].count
                );
              }
            }

            // map to push
            const dataga = JSON.parse(this.sellerData.seller_names);
            dataga.forEach((item) => {
              this.option.yAxis[0].data.push(item.seller_name);
            });
            this.sellerKey++;
          }

          const color = [
            "#43A047",
            "#FF8A80",
            "#FF80AB",
            "#EA80FC",
            "#B388FF",
            "#8C9EFF",
            "#82B1FF",
            "#80D8FF",
            "#FF9E80",
            "#00BCD4",
            "#64FFDA",
            "#8D6E63",
          ];
          this.weeklyConfig[0].data = JSON.parse(this.sellerData.week_data);
          this.monthlyConfig[0].data = JSON.parse(this.sellerData.month_data);
          this.weeklyConfig[0].data.forEach((element, index) => {
            this.weeklyBar.series[0].data.push({
              value: element.value,
              // Specify the style for single bar
              itemStyle: {
                color: color[index],
                shadowColor: "#315190",
                borderType: "dashed",
              },
            });
          });

          // borrar todos los elementos de this.monthlyBar.series[0].data
          this.monthlyBar.series[0].data = [];
          // recorrer con foreach
          this.monthlyConfig[0].data.forEach((element, index) => {
            this.monthlyBar.series[0].data.push({
              value: element.value,
              // Specify the style for single bar
              itemStyle: {
                color: color[index],
                shadowColor: "#315190",
                borderType: "dashed",
              },
            });
          });
          this.loadingProductivity = false;
        }
      } catch (e) {
        this.loadingProductivity = false;
        console.log(e);
      }
    },

    changeDaylyProductivityMonth() {
      this.monthlyChange = !this.monthlyChange;
      this.monthKey++;
      this.monthKeyBar++;
    },
    changeDaylyProductivity() {
      this.daylyChange = !this.daylyChange;
      this.weekKey++;
      this.weekKeyBar++;
    },

    async getDashboardSupCounterInfo() {
      try {
        this.loadingData = true;
        const params = {
          user_id: this.currentUser.user_id,
          date: moment(
            `${this.paymentYear}-${this.paymentMonth
              .toString()
              .padStart(2, "0")}-${moment().format("DD")}`
          ).format("YYYY-MM-DD"),
          // date: moment(`${this.paymentMonth}-${moment().format('DD')}-${this.paymentYear}`).format('YYYY-MM-DD'),
        };
        const responseCounter =
          await DashboardSalesServices.getDashboardSupCounter(params);

        if (responseCounter.status === 200) {
          this.counterData = responseCounter.data[0];

          this.loadingData = false;
        }
      } catch (e) {
        this.loadingData = false;
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.carolina {
  perspective: 1000px;

  position: relative;
}

.carolina-content {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
  transition: all 1s;

  position: absolute;
  top: 22px;

  width: 89%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.bri-content {
  box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.3);
  transition: all 1s;

  position: absolute;
  top: 170px;

  width: 89%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.carolina-back {
  transform: rotateY(180deg);
}

.carolina:hover .carolina-front {
  transform: rotateY(-180deg);
}

.carolina:hover .carolina-back {
  transform: rotateY(0);
}
</style>
