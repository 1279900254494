export default [
  {
    path: "leads",
    name: "paragon-leads",
    redirect: { name: "paragon-leads-list" },
    component: () => import("@/views/commons/components/lead-programs/Lead.vue"),
    meta: {
      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Leads",
        },
      ],
    },
    children: [
      {
        path: "",
        name: "paragon-leads-list",
        component: () =>
          import(/* webpackChunkName: "PRLeadsList" */ "@/views/commons/components/lead-programs/components/lead-table/LeadTable.vue"
          ),
        meta: {
          route: "ti",
          isClientsTab: true,
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
              to: "/ti/leads",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "leads/show/:id",
    name: "ti-lead-show",
    component: () =>
      import(/* webpackChunkName: "PRLeadsShow" */ "@/views/crm/views/Lead/lead-module/dashboard/default.vue"),
    props: true,
    meta: {
      isClientsTab: true,
      route: "ti",
      contentClass: "leads-app",
    },
  },

];
