export default [
    {
        path: "/users/session/:id",
        name: 'users-session',
        component: () => import(/* webpackChunkName: "UsersSession" */ "@/views/users/commons/users/table-sessions/TableSessions.vue"),
        meta: {
            pageTitle: "Access List",
            route: 'users',
            breadcrumb: [
              {
                text: "Index",
                active: true,
              },
            ],
        },
    },
]