import store from '@/store';
import Vue from 'vue';

const closeProject = () => {
  window.socket.bind('project-notification', async data => {
    let temps = false;
    const modules = data.arrayMembers;
    const modules_id = store.state.auth.currentUser.arrRoles;
    const modules_roles = modules_id.map(obj => ({ module_id: obj.module_id, role_id: obj.role_id }));
    const exists = modules.some(obj => modules_roles.some(roleObj => (roleObj.module_id === obj.module_id && [2, 17].includes(roleObj.role_id)) || roleObj.role_id === 1));
    if (exists == true) {
      temps = true;
    }
    // cambiar esto por un modal de notificaciones de finalizar projecto
    if (temps) {
      await Vue.prototype.$amgAlert({
        title: 'Project Finished!',
        icon: 'CheckIcon',
        subtitle: `Project ${data.projectName} has been finished`,
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }
  });
};
export default closeProject;
