<template>
  <div>
    <b-modal
      v-model="onControl"
      size="md"
      :header-bg-variant="request.type_request === 'VOID' ? 'danger':'success'"
      @hidden="closeModal"
    >
      <template #modal-title>
        <strong class="text-uppercase">
          Request {{ request.type_request }} to client '{{ request.client }}'
        </strong>
      </template>
      <b-card class="shadow-none mb-0 pb-0">
        <validation-observer ref="formComment">
          <b-row>
            <b-col cols="12">
              <validation-provider
                v-slot="{errors}"
                name="comment"
                rules="required|max:255|min:5"
              >
                <b-form-group
                  label="Comment"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    v-model="comment"
                    class="text-area-box roboto-class"
                    name="text"
                    cols="30"
                    rows="5"
                    max-length="255"
                    placeholder="Write a comment here"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger' : ''"
                  />
                </b-form-group>
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >{{ errors[0] }}</span>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
      <template #modal-footer>
        <div>
          <b-button
            :variant="request.type_request === 'VOID' ? 'danger':'success'"
            class="text-uppercase"
            @click="saveRequestTransaction"
          >
            <feather-icon
              icon="ArrowRightCircleIcon"
              class="text-white mr-1"
            />
            request {{ request.type_request }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import chargeBackService from '@/views/commons/components/charge-back_v2/service/charge.back.service';
import { mapGetters } from 'vuex';

export default {
  props: {
    request: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      onControl: false,
      comment: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.onControl = true;
  },
  methods: {
    async saveRequestTransaction() {
      try {
        const validate = await this.$refs.formComment.validate();
        if (!validate) {
          return;
        }
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }
        this.addPreloader();
        const params = {
          uuid_transaction: this.request.uuid_transaction,
          user_id: this.currentUser.user_id,
          status: 1, // request pending
          observations: null,
          typeRequest: this.request.type_request,
          from_department: this.currentUser.module_name,
          user_name: this.currentUser.fullName,
          comment: this.comment,
        };
        const { result, message } = await chargeBackService.insertRequestToTransaction(params);
        this.removePreloader();
        if (result === 1) {
          this.onControl = false;
          this.showSuccessSwal();
          this.$emit('refresh');
        } else {
          this.showWarningSwal('Very Important', message);
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log('error in saveRequestTransaction', error);
      }
    },
    closeModal() {
      this.onControl = false;
      this.$emit('closeModal');
    },
  },
};
</script>
