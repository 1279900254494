import { amgApi } from "@/service/axios"

class SalaryRecordService {
  async getEmployees(body) {
    try {
      const data = await amgApi.post(`salary-record/search-employees`, body)
      return data
    } catch (error) {
      console.log("Something went wrong on getEmployees:", error)
      throw error
    }
  }
  async insertOrUpdateSalary(body) {
    try {
      const data = await amgApi.post(
        `salary-record/insert-or-update-salary`,
        body
      )
      return data
    } catch (error) {
      console.log("Something went wrong on updateSalary:", error)
      throw error
    }
  }
  async getSalaryRecords(body) {
    try {
      const data = await amgApi.post(`salary-record/get-salary-records`, body)
      return data
    } catch (error) {
      console.log(
        "Something went wrong on getSalargetSalaryRecordsyRecord:",
        error
      )
      throw error
    }
  }
  async getTrackingSalaryRecord(body) {
    try {
      const data = await amgApi.post(
        `salary-record/get-tracking-salary-record`,
        body
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getTrackingSalaryRecord:", error)
      throw error
    }
  }
  async sendObservation(body) {
    try {
      const data = await amgApi.post(`salary-record/send-observation`, body)
      return data
    } catch (error) {
      console.log("Something went wrong on sendObservation:", error)
      throw error
    }
  }
  async acceptSalary(body) {
    try {
      const data = await amgApi.post(`salary-record/accept-salary`, body)
      return data
    } catch (error) {
      console.log("Something went wrong on acceptSalary:", error)
      throw error
    }
  }
  async rejectSalary(body) {
    try {
      const data = await amgApi.post(`salary-record/reject-salary`, body)
      return data
    } catch (error) {
      console.log("Something went wrong on rejectSalary:", error)
      throw error
    }
  }
  async getSalariesChart(body) {
    try {
      const data = await amgApi.post(`salary-record/get-salaries-chart`, body)
      return data
    } catch (error) {
      console.log("Something went wrong on getSalariesChart:", error)
      throw error
    }
  }
  async getCounterRecord(body) {
    try {
      const data = await amgApi.post(`salary-record/get-counter-record`, body)
      return data
    } catch (error) {
      console.log("Something went wrong on getCounterRecord:", error)
      throw error
    }
  }
  async migrateNewSalaryRecord(body) {
    try {
      const data = await amgApi.post(
        `salary-record/migrate-new-salary-record`,
        body
      )
      return data
    } catch (error) {
      console.log("Something went wrong on migrateNewSalaryRecord:", error)
      throw error
    }
  }
  async getObsSalaryRecord(body) {
    try {
      const data = await amgApi.post(
        `salary-record/get-obs-salary-record`,
        body
      )
      return data
    } catch (error) {
      console.log("Something went wrong on getObsSalaryRecord:", error)
      throw error
    }
  }
  async createObservation(body) {
    try {
      const data = await amgApi.post(`salary-record/create-observation`, body)
      return data
    } catch (error) {
      console.log("Something went wrong on createObservation:", error)
      throw error
    }
  }
  async removeSalaryRecord(body) {
    try {
      const data = await amgApi.post(`salary-record/remove-salary-record`, body)
      return data
    } catch (error) {
      console.log("Something went wrong on removeSalaryRecord:", error)
      throw error
    }
  }
  async removeObservation(body) {
    try {
      const data = await amgApi.post(`salary-record/remove-observation`, body)
      return data
    } catch (error) {
      console.log("Something went wrong on removeObservation:", error)
      throw error
    }
  }
}

export default new SalaryRecordService()
