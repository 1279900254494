export default [
  {
    path: "leads",
    name: "lead-administration",
    component: () => import(/* webpackChunkName: "AdministrationLeads" */ "@/views/crm/views/Lead/Lead.vue"),
    redirect: { name: "lead-administration-lead-list" },
    children: [
      {
        path: "",
        redirect: { name: "lead-administration-lead-list" },
        meta: {
          route: "administration",
        },
        contentClass: "leads-app",
      },
      {
        path: "",
        component: () => import(/* webpackChunkName: "AdministrationLeadsList" */ "@/views/crm/views/Lead/lead-module/list/LeadsList.vue"),
        name: "lead-administration-lead-list",
        meta: {
          route: "administration",
          pageTitle: "Leads",
          tab: 1,
          breadcrumb: [
            {
              text: "Leads",
              to: "/administration/leads",
              active: true,
            },
          ],
          contentClass: "leads-app",
        },
      },
      {
        path: "sn",
        component: () => import(/* webpackChunkName: "AdministrationLeadsListSn" */ "@/views/crm/views/Lead/lead-module/list/LeadsSnList.vue"),
        name: "lead-administration-lead-sn-list",
        meta: {
          route: "administration",
          pageTitle: "Leads",
          tab: 2,
          breadcrumb: [
            {
              text: "Leads Sn",
              to: "/administration/leads/sn",
              active: true,
            },
          ],
          contentClass: "leads-app",
        },
      },
      {
        path: "w-potential",
        component: () => import(/* webpackChunkName: "AdministrationLeadsWPotential" */ "@/views/crm/views/Lead/lead-module/list/LeadsWPotentialList.vue"),
        name: "lead-administration-lead-w-potential-list",
        meta: {
          route: "administration",
          permittedRoles: [1, 2, 17],
          pageTitle: "Leads",
          tab: 3,
          breadcrumb: [
            {
              text: "Leads W Potential",
              to: "/administration/leads/w-potential",
              active: true,
            },
          ],
          contentClass: "leads-app",
        },
      },
    ],
    meta: {
      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Leads",
        },
      ],
    },
  },
  {
    path: "leads/:id",
    name: "administration-lead-show",
    component: () => import(/* webpackChunkName: "AdministrationLeadsShow" */ "@/views/crm/views/Lead/lead-module/dashboard/default.vue"),
    meta: {
      route: "administration",
      contentClass: "leads-app",
    },
  },
];
