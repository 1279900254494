export const otherServicesTab = (abv, title,permitted_roles = null) => {
    return [
        {
            path: "pending",
            name: `${abv}-pending`,

            component: () => import(/* webpackChunkName: "FinancialApprovalApplicationsInProcess" */ "@/views/commons/components/other-services/view/component/TableOtherServices.vue"),
            meta: {
                router: "financial-approval",
                statusApp: 1,
                pageTitle: `${title}`,
                tabName: "pending",
                breadcrumb: [
                    {
                        text: "Pending",
                        active: true,
                    },
                ],
                routePending: `${abv}-pending`,
                routeCompleted: `${abv}-completed`,
                routeExpired: `${abv}-expired`,
                routeSuccessfull: `${abv}-successfull`,
                routeUnsuccessful: `${abv}-unsuccessful`,
                permittedRoles: permitted_roles,
            },
        },
        {
            path: "completed",
            name: `${abv}-completed`,
            redirect: { name: `${abv}-successfull` },
            component: () => import(/* webpackChunkName: "FinancialApprovalApplicationsCompleted" */ "@/views/commons/components/other-services/view/CompletedSubTab.vue"),
            meta: {
                router: "financial-approval",
                statusApp: 2,
                pageTitle: `${title}`,
                tabName: "completed",
                breadcrumb: [
                    {
                        text: "COMPLETED",
                        active: true,
                    },
                ],
                routePending: `${abv}-pending`,
                routeCompleted: `${abv}-completed`,
                routeExpired: `${abv}-expired`,
                routeSuccessfull: `${abv}-successfull`,
                routeUnsuccessful: `${abv}-unsuccessful`,
                permittedRoles: permitted_roles,
            },
            children: [{
                path: "successful",
                name: `${abv}-successfull`,
                component: () =>
                    import(
                        "@/views/commons/components/other-services/view/component/TableOtherServices.vue"
                    ),
                meta: {
                    pageTitle: `${title}`,
                    statusApp: 2,
                    tabName: "successfull",
                    breadcrumb: [
                        {
                            text: "Successfull",
                            active: true,
                        },
                    ],
                    permittedRoles: permitted_roles,
                },
                routePending: `${abv}-pending`,
                routeCompleted: `${abv}-completed`,
                routeExpired: `${abv}-expired`,
                routeSuccessfull: `${abv}-successfull`,
                routeUnsuccessful: `${abv}-unsuccessful`,
            },
            {
                path: "unsuccessful",
                name: `${abv}-unsuccessful`,
                component: () =>
                    import(
                        "@/views/commons/components/other-services/view/component/TableOtherServices.vue"
                    ),
                meta: {
                    pageTitle: `${title}`,
                    tabName: "unsuccessful",
                    statusApp: 3,
                    breadcrumb: [
                        {
                            text: "Unsuccessful",
                            active: true,
                        },
                    ],
                    permittedRoles: permitted_roles,
                    routePending: `${abv}-pending`,
                    routeCompleted: `${abv}-completed`,
                    routeExpired: `${abv}-expired`,
                    routeSuccessfull: `${abv}-successfull`,
                    routeUnsuccessful: `${abv}-unsuccessful`,
                },

            }]

        },
        {
            path: "expired",
            name: `${abv}-expired`,
            component: () =>
                import(
                    "@/views/commons/components/other-services/view/component/TableOtherServices.vue"
                ),
            meta: {
                pageTitle: `${title}`,
                tabName: "expired",
                statusApp: 4,
                breadcrumb: [
                    {
                        text: "Expired",
                        active: true,
                    },
                ],
                permittedRoles: permitted_roles,
            },
            routePending: `${abv}-pending`,
            routeCompleted: `${abv}-completed`,
            routeExpired: `${abv}-expired`,
            routeSuccessfull: `${abv}-successfull`,
            routeUnsuccessful: `${abv}-unsuccessful`,
        }
    ]
}
