export default [
  {
    path: 'search-payment',
    name: 'search-payment',
    component: () => import(
      /* webpackChunkName: "ManaRiskyClients" */ '@/views/management/views/search-payments/searchPayments.vue'
    ),
    meta: {
      pageTitle: 'Search Payment',
      breadcrumb: [
        {
          text: 'Search Payment',
        },
      ],
    },
  },
];
