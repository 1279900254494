import store from "@/store"
import Vue from "vue"
import moment from "moment"
import router from "@/router/index"
import SendingToCreditorService from "@/views/correspondence/views/sending-creditors/service/sending-creditors.service.js"
import { swalInfoIcon } from "@/icons/statusIcons"

const ApprovalProcessConfirmDebit = () => {
  window.socket.bind(
    "confirm-debit-notifications-management",
    async (response) => {
      await store.dispatch(
        "SettlementApprovalStore/GET_COUNTER_SETTLEMENTS_MADE_PENDING",
        { payment_order_status_id: 1 }
      )

      const user_id = store.state.auth.currentUser.user_id
      const rol_id = store.state.auth.currentUser.role_id
      //The user_id is only sent when is an advisor
      await store.dispatch("DebtSolutionSettlementsMadeStore/A_COUNT_NOT_TIF", {
        user_id: rol_id === 3 ? user_id : 0,
      })

      if (store.state.auth.currentUser.user_id == response.datos.id_creator) {
        let img_icon = "success.svg"
        let htmlAlert = ""
        let amount = (response.datos.amount / 1).toFixed(2).replace(",", ".")
        amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

        switch (response.status) {
          case 4:
            const operationDate = moment(response.datos.operationDate).format(
              "MM/DD/YYYY"
            )
            img_icon = "success"
            htmlAlert = `Negotiation Completed on <span style="font-weight: bold;">${operationDate}</span> 
                            for the <span style="font-weight: bold;">Client: ${response.datos.client}</span>, in the amount 
                            of <span style="font-weight: bold;">$ ${amount} </span>. Waiting for review.`
            break
          case 3:
            img_icon = "error"
            htmlAlert = `Negotiation not Completed, for the <span style="font-weight: bold;">Client: ${response.datos.client}</span>, in the amount 
                            of <span style="font-weight: bold;">$ ${amount} </span>. Waiting for review.`
            break
        }

        await Vue.swal.fire({
          icon: img_icon,
          title: response.message,
          html: htmlAlert,
        })
      }
    }
  )
}

const ApprovalProcessGenerateOrdenPending = () => {
  window.socket.bind(
    "generate-offer-payment-order-notifications-management",
    async (response) => {
      await store.dispatch(
        "SettlementApprovalStore/GET_COUNTER_SETTLEMENTS_MADE_PENDING",
        { payment_order_status_id: 1 }
      )
      if (router.currentRoute.matched[0]?.meta.module == 16) {
        await store.dispatch("modal-actions/A_VALIDATE_BALANCE_BANK_ACCOUNT")
      }
    }
  )
}

const BankAccountInputBlanace = () => {
  window.socket.bind(
    "input-balance-notifications-management",
    async (response) => {
      let amount = (response.balance.amount / 1).toFixed(2).replace(",", ".")
      amount = amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      const userss = response.balance.users
      const type = response.balance.type
      let user_id = store.state.auth.currentUser.user_id

      if (userss.includes(user_id)) {
        let htmlAlert = `Bank Account of type <span style="font-weight: bold;"> ${type} </span> 
                            , Balance: <span style="font-weight: bold;"> $ ${amount}</span>. It has payment orders available to execute payment.`

        await Vue.swal.fire({
          icon: "success",
          title: "Update Balance Bank Account",
          html: htmlAlert,
        })
      }
    }
  )
}

const CounterSendingToCreditors = () => {
  window.socket.bind(
    "generate-offer-payment-order-notifications-management",
    async (response) => {
      const { data } =
        await SendingToCreditorService.counterSendingToCreditors()

      let tag = data > 99 ? "99+" : data

      await store.dispatch(
        "SendingToCreditorsStore/A_SET_COUNTER_SENDING_TO_CREDITORS",
        tag
      )

      await store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", {
        routeName: "correspondence-sending-creditors",
        tag: tag,
      })
    }
  )
}

const ConfirmOtherPayment = () => {
  window.socket.bind(
    "confirm-other-payment",
    async ({ title, message, module }) => {
      await Vue.swal.fire({
        icon: "success",
        title: message,
      })
    }
  )
}

const ConfirmZelleOtherPayments = () => {
  window.socket.bind("management-op-confirm-payment", async (response) => {
    const user = response.userId
    const user_id = store.state.auth.currentUser.user_id
    const type = response.type
    if (user == user_id) {
      await Vue.swal.fire({
        title: response.message,
        icon: type == 2 ? "success" : "warning",
      })
    }
  })
}

const AssignChargebackResponsible = () => {
  window.socket.bind(
    "chargeback-assign-responsible",
    async ({ module_id, send_to, text, title }) => {
      let temps = false
      let role_id = store.getters["auth/roleId"]
      let current_module = store.getters["auth/moduleId"] //module actual
      let session_id = store.state.auth.currentUser.user_id

      //get id of the modules to which the user belongs
      let get_modules_of_user = store.getters["auth/currentUser"].arrModuls
      let state_user_in_module = get_modules_of_user
        .map((elemento) => elemento.id_module)
        .includes(module_id) //true - false

      //roles
      send_to.forEach((element) => {
        if (element === role_id) temps = true
      })
      if (temps && state_user_in_module && current_module !== undefined) {
        const res = await Vue.swal.fire({
          icon: "success",
          title: title,
          text: text,
          showConfirmButton: true,
        })

        if (res.value) {
          await window.amgApi.post("/commons/close-all-swal", {
            to_id: session_id,
          })
        }
      }
    }
  )
}

const CreateOtherPayment = () => {
  window.socket.bind(
    "management-create-other-payment",
    async ({ title, message, module }) => {
      console.log(message)
      await Vue.swal.fire({
        icon: "success",
        title: message,
      })
    }
  )
}

const UpdateColumnTimeExpired = () => {
  window.socket.bind(
    "chargeback-update-column-time-expired",
    async ({ module_id, send_to, text }) => {
      let temps = false
      let session_id = store.state.auth.currentUser.user_id
      let role_id = store.getters["auth/roleId"]
      let current_module = store.getters["auth/moduleId"] //module actual

      let get_modules_of_user = store.getters["auth/currentUser"].arrModuls
      let state_user_in_module = get_modules_of_user
        .map((elemento) => elemento.id_module)
        .includes(module_id) //true - false

      send_to.forEach((element) => {
        if (element === role_id) temps = true
      })
      if (temps && state_user_in_module && current_module !== undefined) {
        const res = await Vue.swal.fire({
          icon: "info",
          title: "CHARGE BACK",
          text: text,
          showConfirmButton: true,
        })
        if (res.value) {
          await window.amgApi.post("/commons/close-all-swal", {
            to_id: session_id,
          })
        }
      }
    }
  )
}

const NotifyEvidenceFileUploadToManagement = () => {
  window.socket.bind(
    "notify-evidence-file-upload-to-management",
    async ({ title, module_id, send_to, text }) => {
      let temps = false
      let session_id = store.state.auth.currentUser.user_id
      let role_id = store.getters["auth/roleId"]
      let current_module = store.getters["auth/moduleId"] //module actual

      let get_modules_of_user = store.getters["auth/currentUser"].arrModuls
      let state_user_in_module = get_modules_of_user
        .map((elemento) => elemento.id_module)
        .includes(module_id) //true - false

      send_to.forEach((element) => {
        if (element === role_id) temps = true
      })
      if (temps && state_user_in_module && current_module !== undefined) {
        const res = await Vue.swal.fire({
          icon: "info",
          title: title,
          text: text,
          showConfirmButton: true,
        })
        if (res.value) {
          await window.amgApi.post("/commons/close-all-swal", {
            to_id: session_id,
          })
        }
      }
    }
  )
}

const NotifyManagementOfPaymentInsert = () => {
  window.socket.bind(
    "notify-management-of-payment-insert",
    async ({ title, text, module_send, send_to_rol }) => {
      let temps = false
      let roles_access = false
      let role_id = store.getters["auth/roleId"] //rol actual
      let current_module = store.getters["auth/moduleId"] //module actual
      let session_id = store.state.auth.currentUser.user_id
      //get id of the modules to which the user belongs
      let get_modules_of_user = store.getters["auth/currentUser"].arrModuls.map(
        (elemento) => elemento.id_module
      )

      module_send.forEach((element) => {
        let module_acceso = get_modules_of_user.includes(parseInt(element))
        if (module_acceso) {
          temps = true
        }
      })

      //roles
      send_to_rol.forEach((element) => {
        if (element === role_id) roles_access = true
      })

      if (temps && current_module !== undefined && roles_access) {
        const res = await Vue.swal.fire({
          icon: "success",
          title: title,
          text: text,
          showConfirmButton: true,
        })

        if (res.value) {
          await window.amgApi.post("/commons/close-all-swal", {
            to_id: session_id,
          })
        }
      }
    }
  )
}


const NewClaimRequestRegistered = () => {
  window.socket.bind("new-claim-request-registered", async (response) => {
    let current_role = store.getters["auth/roleId"];
    let current_module = store.getters["auth/moduleId"];
    const { username, module, title, description } = response.claimRequest[0];
    // if is ceo and module is management
    if (current_role === 1 && current_module === 16) {
      Vue.prototype.$amgAlert({
        title: "New Employee Claim Registered",
        icon: "UserIcon",
        subtitle: `${username}`,
        data: [
          {
            icon: "HomeIcon",
            title: "DEPARTMENT",
            description: module,
          },
          {
            icon: "TypeIcon",
            title: "TITLE",
            description: title,
          },
          {
            icon: "ListIcon",
            title: "DESCRIPTION",
            description: description,
          },
        ],
      });
    }
  })
}

const ObserveSaleNotification = () => {
  window.socket.bind(
    "observe-sale-management-notification",
    async (response) => {
      let current_module = store.getters["auth/moduleId"] //module actual
      if (current_module == 2) {
        let htmlAlert = `<h2> ${response.state == 8 ? "Observed" : "Rejected"
          } Sale Made </h2>
            The lead <span style="font-weight: bold;">${response.lead
          }</span> was <span style="font-weight: bold;">${response.state == 8 ? "Observed" : "Rejected"
          }</span> by Debt Solutions <br/> 
            </span>`

        const result = await Vue.swal.fire({
          imageUrl: swalInfoIcon,
          imageWidth: 70,
          html: htmlAlert,
        })
      }
    }
  )
}

const NotificationSaleMade = () => {
  window.socket.bind("notification-sale-made", async (response) => {
    const { lead_name, seller_id, supervisor_id, status, origin } =
      response.data
    const user_id = store.state.auth.currentUser.user_id
    if (
      (origin == 16 && [...seller_id, supervisor_id].includes(user_id)) ||
      (origin == 5 && [...seller_id].includes(user_id))
    ) {
      const htmlAlert = `
                        <h2> ${origin == 5
          ? "Rejected Sale Made"
          : "Sales resolution"
        }  </h2>
                        The lead <span style="font-weight: bold;">${lead_name}</span> was <span style="font-weight: bold;">${status}</span> by ${origin == 16 ? "Management" : "Debt Solution"
        } <br/> 
                        </span>`

      const result = await Vue.swal.fire({
        imageUrl: swalInfoIcon,
        imageWidth: 70,
        html: htmlAlert,
      })
    }
  })
}

export {
  ApprovalProcessConfirmDebit,
  ApprovalProcessGenerateOrdenPending,
  BankAccountInputBlanace,
  AssignChargebackResponsible,
  UpdateColumnTimeExpired,
  NotifyEvidenceFileUploadToManagement,
  NotifyManagementOfPaymentInsert,
  ConfirmOtherPayment,
  CreateOtherPayment,
  ConfirmZelleOtherPayments,
  NewClaimRequestRegistered,
  ObserveSaleNotification,
  NotificationSaleMade,
}
