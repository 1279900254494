import Lead from "@/views/commons/components/lead-programs/Lead.vue";
import LeadTable from "@/views/commons/components/lead-programs/components/lead-table/LeadTable.vue";
import LeadTableSales from "@/views/commons/components/lead-programs/components/lead-table/LeadTableSales.vue";
import Default from "@/views/crm/views/Lead/lead-module/dashboard/default.vue";

export default [
  {
    path: "/cedigital/financial/leads",
    name: "financial-leads",
    redirect: { name: "financial-leads-grid" },
    component: Lead,
    meta: {
      module: 23,
      childModule: 6,
      programParent: 3,
    },
    children: [
      {
        path: "",
        name: "financial-leads-grid",
        component: LeadTable,
        meta: {
          module: 23,
          isClientsTab: true,
          pageTitle: "Leads",
          route: "cedigital/financial",
          breadcrumb: [
            {
              text: "Leads",
            },
          ],
        },
      },
      // Leads with sales
      {
        path: "sales",
        name: "financial-leads-grid-sales",
        component: LeadTableSales,
        meta: {
          module: 23,
          isClientsTab: true,
          pageTitle: "Leads",
          route: "cedigital/financial",
          breadcrumb: [
            {
              text: "Sales",
            },
          ],
        },
      },

    ],
  },
  {
    path: "leads/show/:id",
    name: "financial-leads-lead-show",
    component: () =>
        import(/* webpackChunkName: "CreditExpertsLeadsShow" */ "@/views/crm/views/Lead/lead-module/dashboard/default.vue"),
    props: true,
    meta: {
      module: 23,
      isClientsTab: true,
      route: "cedigital/financial",
      contentClass: "leads-app",
    },
  },
];
