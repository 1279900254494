export default [
  {
    path: 'reports',
    name: 'debtsolution-reports',
    redirect: { name: 'debtsolution-reports-monthly-payment' },
    component: () => import(/* webpackChunkName: "DSReport" */ '@/views/commons/components/reports/Reports.vue'),
    meta: {
      pageTitle: 'Reports',
      breadcrumb: [
        {
          text: 'Reports',
        },
      ],
    },
    children: [
      {
        path: 'monthly-payment',
        name: 'debtsolution-reports-monthly-payment',
        component: () => import(/* webpackChunkName: "DSMonthlyPayment" */ '@/views/commons/components/reports/tabs/monthly-payment/MonthlyPayment.vue'),
        meta: {
          pageTitle: 'Reports',
          breadcrumb: [
            {
              text: 'Reports',
            },
            {
              text: 'Monthly Payment',
            },
          ],
        },
      },
      {
        path: 'payment_status',
        name: 'debtsolution-reports-payment-status',
        component: () => import(/* webpackChunkName: "DSPaymentStatus" */ '@/views/commons/components/reports/tabs/payment-status/PaymentStatus.vue'),
        meta: {
          pageTitle: 'Reports',
          breadcrumb: [
            {
              text: 'Reports',
            },
            {
              text: 'Payment Status',
            },
          ],
        },
      },
      {
        path: 'general_balance',
        name: 'debtsolution-reports-general-balance',
        component: () => import(/* webpackChunkName: "DSGeneralBalance" */ '@/views/commons/components/reports/tabs/general-balance/GeneralBalance.vue'),
        meta: {
          pageTitle: 'Reports',
          breadcrumb: [
            {
              text: 'Reports',
            },
            {
              text: 'General Balance',
            },
          ],
        },
      },
    ],
  },
]
