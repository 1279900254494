const state = {
  S_GENERATED_PAYROLL: 0,
};
const getters = {
  G_GENERATED() {
    const generated = state.S_GENERATED_PAYROLL;
    return generated;
  },
};
const mutations = {
  // eslint-disable-next-line no-shadow
  SET_GENERATED_PAYROLL(state, payload) {
    state.S_GENERATED_PAYROLL = payload;
  },
};
const actions = {
  async A_SET_GENERATED_PAYROLL({ commit }, params) {
    commit("SET_GENERATED_PAYROLL", params);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
