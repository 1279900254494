import Vue from "vue";
import NotificationMixin from "@/mixins/notification";
import store from "@/store";

const acceptFiles = () => {
  window.socket.bind("client-new-file", async (data) => {
    const roleId = store.state.auth.currentUser.role_id;
    const moduleId = store.state.auth.currentUser.modul_id;
    if ([1, 2, 16, 17].includes(roleId) && moduleId == 22) {
      const result = await Vue.swal.fire({
        icon: "success",
        html: `<h2 style="font-weight: 600;">${data.content}</h2>`,
        showConfirmButton: true,
      });
      if (result.value) {
        await window.amgApi.post("/commons/close-all-swal", data);
        window.open(
          `${process.env.VUE_APP_BASE_URL_FRONT}/cedigital/file-app/in-process`,
          "_blank"
        );
      }
      NotificationMixin.methods.showWindowNotification(
        "New File Uploaded",
        data.content,
        undefined,
        undefined,
        () => {
          window.focus();
        }
      );
    }
  });
};
export default acceptFiles;
