import paymentsNav from "@/views/court-info/views/payments/payments.nav";
import FileManagerNav from "@/views/court-info/views/file-manager/file-manager.nav";
import helpdeskNav from "@/views/court-info/views/helpdesk/helpdesk.nav";
import leadNav from "@/views/court-info/views/leads/leads.nav";
import clientsNav from "@/views/court-info/views/clients/clients-group.navigation";
import saleMadeNav from "@/views/court-info/views/sales-made/sales-made.nav";
import reportsNav from "@/views/court-info/views/reports/reports.nav";
import calendarNav from "@/views/court-info/views/calendar/calendar.nav";
import loansNav from "@/views/court-info/views/loans/loans.nav";
import schedulesNav from "@/views/court-info/views/schedules/schedules.nav";
import chargeBackNav from "@/views/court-info/views/charge-back/charge-back.nav";
import zeroPaymentNav from "@/views/court-info/views/zero-payment/navigation/zero-payment.navigation";
import ProductRequestNav from "@/views/court-info/views/request/request.nav.js";
import OtherPaymentsNavigation from "@/views/court-info/views/other-payments/other-payments.nav";
import EmployeeClaimsNavigation from "@/views/court-info/views/employee-claims/navigation/employee-claims.nav";
import ClaimsNav from "@/views/court-info/views/claims/claims.nav.js";
//import CourtInfoProjectsNavigation from "@/views/court-info/views/projects/court-info-projects.navigation";
import MOFNavigation from "@/views/court-info/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/court-info/views/recruitment-process/court-info-recruitment_process.navigation";

import RequestNcrNav from "@/views/court-info/views/request-ncr/request-ncr.nav";
import RingCentralNav from "@/views/court-info/views/ring-central/ring-central.nav.js";
import RequestWorkPlanNav from "@/views/court-info/views/request-workplan/request-ncr.nav.js";
import DisputeNav from "@/views/court-info/views/dispute/navigation/dispute.nav";
const navigation = [
  {
    header: "GENERAL SUPPORT",
  },
  ...leadNav,
  clientsNav,
  ClaimsNav,
  ...zeroPaymentNav,
  ...saleMadeNav,
  ...reportsNav,
  RequestNcrNav,
  RequestWorkPlanNav,
  DisputeNav,
  ...paymentsNav,
  OtherPaymentsNavigation,
  chargeBackNav,
  FileManagerNav,
  calendarNav,
  schedulesNav,
  loansNav,
  helpdeskNav,
  ProductRequestNav,
  EmployeeClaimsNavigation,
  //CourtInfoProjectsNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  RingCentralNav,
];
export default navigation;
