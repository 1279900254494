<template>
  <div v-if="!loadingComponents">
    <div v-if="!showNewPayments">
      <b-row :class="withDetails ? 'm-0 p-0 mt-1 ' : 'pt-4 ml-2 mr-2 p-2'">
        <b-col cols="5">
          <div class="my-table">
            <h4 class="pb-1">FEES AND CHARGES</h4>
            <b-table
              ref="listCharges"
              small
              responsive
              :items="searchListCharges"
              :fields="fields1"
              show-empty
              sticky-header="50vh"
              :busy.sync="isBusy"
              class="font-small-3 text-justify blue-scrollbar table-new-customization custom-table"
              :head-row-variant="isDarkSkin ? 'bg-fig' : 'my-blue'"
              :tbody-tr-class="'rowClass'"
              style="border-radius: 7px"
              striped
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template v-slot:cell(date)="data">
                <p class="mb-0 font-weight-bold">
                  {{ data.item.created_at | myGlobal }}
                </p>
              </template>
              <template v-slot:cell(fee_or_charge)="data">
                <p class="mb-0 font-weight-bold">
                  {{ data.item.fee }}
                </p>
              </template>
              <template v-slot:cell(amount)="data">
                <div class="d-flex align-items-center">
                  <span style="width: 70px" class="mb-0 font-weight-bold">
                    $ {{ data.item.amount }}
                  </span>
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col cols="7">
          <div class="my-table">
            <h4 class="pb-1">PAYMENTS</h4>
            <b-table
              ref="listPayments"
              small
              responsive
              :items="searchListPayments"
              :fields="fieldsTransaction"
              show-empty
              sticky-header="50vh"
              :busy.sync="isBusy"
              class="font-small-3 justify-content-start blue-scrollbar table-new-customization custom-table"
              :head-row-variant="isDarkSkin ? 'bg-fig' : 'my-blue'"
              :tbody-tr-class="'rowClass'"
              style="border-radius: 7px"
              striped
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template v-slot:cell(date)="data">
                <div class="d-flex">
                  <div class="mr-1">
                    <div class="d-flex">
                      <div v-if="data.item.update_payment_date_t == 0">
                        <feather-icon
                          v-if="!data.item.editSettlementDate"
                          class="ml-1 cursor-pointer text-success"
                          icon="Edit3Icon"
                          size="20"
                        />
                      </div>
                      <div v-else>
                        <feather-icon
                          class="ml-1 cursor-pointer"
                          icon="AlignRightIcon"
                          size="20"
                          @click="openTracking(data.item)"
                        />
                      </div>
                    </div>
                    <div v-if="data.item.editSettlementDate">
                      <validation-observer :ref="`reschedule${data.item.t_id}`">
                        <validation-provider
                          v-slot="{ errors }"
                          name="date"
                          rules="required"
                        >
                          <kendo-datepicker
                            :id="`contactDate-${data.item.t_id}`"
                            v-model="data.item.newSettlementDate"
                            v-mask="'##/##/####'"
                            :min="minDate"
                            :format="'MM/dd/yyyy'"
                            class="leads-datepicker rounded bg-transparent k-picker-custom picker-select-date"
                            :class="{
                              'border-danger': errors[0],
                            }"
                          />

                          <feather-icon
                            class="ml-1 cursor-pointer text-success"
                            icon="CheckIcon"
                            size="20"
                          />
                          <feather-icon
                            class="ml-1 cursor-pointer text-danger"
                            icon="XIcon"
                            size="20"
                          />
                        </validation-provider>
                      </validation-observer>
                    </div>
                  </div>
                  <div class="d-flex justify-content-start">
                    <p style="width: 100px" class="mb-0 font-weight-bold mr-1">
                      {{ data.item.settlement_date | myGlobal }}
                    </p>
                  </div>
                  <feather-icon
                    v-if="
                      data.item.status_transaction_id != 1 &&
                      data.item.amount == 0 &&
                      data.item.type_transaction_id != 10 &&
                      data.item.type_transaction_id != 11 &&
                      (isChief ||
                        isSupervisor ||
                        isAssistantSupervisor ||
                        isCeo)
                    "
                    class="text-danger cursor-pointer"
                    size="18"
                    icon="XCircleIcon"
                  />
                </div>
              </template>
              <template v-slot:cell(type)="data">
                <p
                  class="mb-0 font-weight-bold mr-1"
                  :style="
                    data.item.type_transaction_id == 14 &&
                    data.item.status_transaction_id != 1
                      ? 'color: red'
                      : ''
                  "
                >
                  {{ data.item.type }}
                </p>
              </template>
              <template v-slot:cell(transaction_id)="data">
                <p class="mb-0 font-weight-bold">
                  {{ data.item.transaction_id }}
                </p>
              </template>

              <template v-slot:cell(amount)="data">
                <span
                  v-if="
                    data.item.type_transaction_id !== 10 &&
                    data.item.type_transaction_id !== 11 &&
                    data.item.type_transaction_id !== 15 &&
                    data.item.method_transaction_id !== null &&
                    data.item.modality_transaction_id !== null &&
                    data.item.type_transaction_id !== 15 &&
                    data.item.method_transaction_id !== null &&
                    data.item.modality_transaction_id !== 5 &&
                    data.item.type_transaction_id !== 15 &&
                    data.item.method_transaction_id !== null &&
                    data.item.modality_transaction_id !== 6
                  "
                  >$ {{ data.item.amount }}</span
                >
                <span
                  v-if="
                    data.item.type_transaction_id === 10 ||
                    data.item.type_transaction_id === 11
                  "
                  >$ ({{ data.item.amount }})</span
                >
                <!--44 - 45-->
                <span
                  v-if="
                    (data.item.type_transaction_id === 15 &&
                      data.item.method_transaction_id === null &&
                      data.item.modality_transaction_id === null) ||
                    (data.item.type_transaction_id === 15 &&
                      data.item.method_transaction_id === null &&
                      data.item.modality_transaction_id === 5)
                  "
                  style="color: red"
                  >$ {{ "-" + data.item.amount }}</span
                >
                <span
                  v-if="
                    data.item.type_transaction_id === 15 &&
                    data.item.method_transaction_id === null &&
                    data.item.modality_transaction_id === 6
                  "
                  style="color: green"
                  >$ {{ "-" + data.item.amount }}</span
                >
              </template>
              <template v-slot:cell(method)="data">
                <div class="d-flex justify-content-start">
                  <span style="min-width: 100px" class="mb-0 font-weight-bold">
                    {{ data.item.method_payment }}
                  </span>
                  <feather-icon
                    v-if="
                      data.item.type_transaction_id != 10 &&
                      data.item.type_transaction_id != 11 &&
                      data.item.amount == 0 &&
                      (isChief ||
                        isSupervisor ||
                        isAssistantSupervisor ||
                        isCeo)
                    "
                    :id="`EyeIcon_1-${data.index}`"
                    v-b-tooltip.hover.bottom
                    class="text-primary cursor-pointer"
                    size="18"
                    icon="EyeIcon"
                    title="SHOW"
                    @click="openZeroPaymentTableModal(data.item.t_id)"
                  />
                </div>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>

      <b-row
        class="pt-3 p-2 ml-2 mr-2 mb-2"
        :class="isDarkSkin ? 'my-dark' : ''"
        v-if="!withDetails"
      >
        <b-col style="display: flex; gap: 3rem; justify-content: center">
          <div
            style="
              border-radius: 10px;
              padding: 1rem 2rem;
              width: fit-content;
              height: fit-content;
              border: solid 1px #0090e7;
            "
            :class="isDarkSkin ? 'my-black' : ''"
          >
            <span> FEES AND CHANGES </span>
            <b-skeleton v-if="!finishedLoading" class="space" type="input" />
            <div class="text-center font-weight-bolder" v-if="finishedLoading">
              $ {{ totalFeeAndCharges | currency }}
            </div>
          </div>
          <div
            style="
              border-radius: 10px;
              padding: 1rem 2rem;
              width: fit-content;
              height: fit-content;
              border: solid 1px #0090e7;
            "
            :class="isDarkSkin ? 'my-black' : ''"
          >
            <span> PAYMENTS </span>
            <b-skeleton v-if="!finishedLoading" class="space" type="input" />
            <div class="text-center font-weight-bolder" v-if="finishedLoading">
              $ {{ total_payment | currency }}
            </div>
          </div>
          <div
            style="
              border-radius: 10px;
              padding: 1rem 2rem;
              width: fit-content;
              height: fit-content;
              border: solid 1px red;
            "
            :class="isDarkSkin ? 'my-black' : ''"
          >
            <span> ACCOUNT BALANCE </span>
            <span>
              <b-skeleton v-if="!finishedLoading" class="space" type="input" />
              <div
                class="text-center font-weight-bolder"
                v-if="finishedLoading"
              >
                $ {{ balance | currency }}
              </div>
            </span>
          </div>
        </b-col>
      </b-row>
      <tracking-settlement-date
        v-if="stateTrackingSettlementDate"
        :client-data="{ transaction_id: transactionId }"
        @hideModal="stateTrackingSettlementDate = false"
      />
    </div>
    <dashboard-payments
      v-else
      :client-account-id="client_account_id"
      read-only
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dataFields from "@/views/commons/components/clients/dashboard/options/pay-module/fields1.data";
import dataFields2 from "@/views/commons/components/clients/dashboard/options/pay-module/fields2.data";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import ChargesMadeService from "@/views/ce-digital/sub-modules/financial/view/charges-made/charges-mage.service";
import moment from "moment";
import TrackingSettlementDate from "@/views/ce-digital/sub-modules/financial/view/charges-made/components/modal/TrackingSettlementDate.vue";
import ParticipantsServices from "@/views/commons/participants-tickets/services/participants.services";
import ClientStatusService from "@/views/ceo/service-dashboard/dashboard.service.js";
import DashboardPayments from "@/views/commons/components/clients/dashboard/options/pay-module/components/DashboardPayments.vue";

export default {
  components: {
    TrackingSettlementDate,
    DashboardPayments,
  },
  props: {
    client_account_id: {
      type: String,
    },
    client_name: {
      type: String,
      default: "",
    },
    account: {
      type: String,
      default: "",
    },
    withDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields1: dataFields,
      fields2: dataFields2,
      isBusy: false,
      finishedLoading: true,
      loadingComponents: true,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      totalFeeAndCharges: 0,
      stateTrackingSettlementDate: false,
      clientData: {},
      minDate: new Date(
        `${new Date().getFullYear()}, ${new Date().getMonth()}, 06`
      ),
      maxDate: new Date(
        `${new Date().getFullYear()}, ${
          new Date().getMonth() + 1
        }, ${new Date().getDate()}`
      ),
      transactionId: null,
      zeroPaymentTransactionId: null,
      zeroPaymentOn: false,
      total_payment: 0,
      showNewPayments: false,
    };
  },
  async created() {
    await this.validateNewStatus();
  },
  computed: {
    balance: {
      get() {
        return this.total_payment - this.totalFeeAndCharges;
      },
      set(value) {
        // You can implement custom logic here to handle the setting of 'balance'
      },
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    fieldsTransaction() {
      return this.fields2.filter((item) => item.key != "status");
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  methods: {
    async validateNewStatus() {
      const { data } = await ClientStatusService.inNewStatusClientAccount({
        client_account: this.client_account_id,
      });
      this.showNewPayments = data;
      if (!data) {
        await this.getTotalPaymentsByClientAccountId();
      }
      this.loadingComponents = false;
    },

    async getTotalPaymentsByClientAccountId() {
      try {
        const params = {
          client_account_id: this.client_account_id,
        };
        const response =
          await ParticipantsServices.getTotalPaymentsByClientAccountId(params);
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.total_payment = response.data[0].total_payment;
          }
        }
      } catch (error) {
        console.error(error);
        this.showErrorSwal(error);
      }
    },
    hideModal() {
      this.$emit("close");
      this.$emit("hideModal");
    },
    editDate(item) {
      this.$set(item, "editSettlementDate", true);
    },
    openTracking(item) {
      this.transactionId = item.t_id;
      this.stateTrackingSettlementDate = true;
    },
    openZeroPaymentTableModal(id) {
      this.zeroPaymentTransactionId = id;
      this.zeroPaymentOn = true;
    },
    closeZeroPaymentModal() {
      this.zeroPaymentOn = false;
    },
    async saveDate(item) {
      const validate = await this.$refs[`reschedule${item.t_id}`].validate();
      if (validate) {
        try {
          const result = await this.showConfirmSwal();
          if (result.isConfirmed) {
            const params = {
              user_id: this.currentUser.user_id,
              oldSettlementDate: item.settlement_date,
              newSettlementDate: item.newSettlementDate,
              transaction_id: item.t_id,
            };
            const { status } =
              await ChargesMadeService.updateSettlementDateTransaction(params);

            if (status == 200) {
              this.$set(item, "editSettlementDate", false);
              this.$refs.listPayments.refresh();
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Date updated successfully"
              );
            }
          }
        } catch (error) {
          console.log(error);
          ("  ");
        }
      }
    },
    hiddenDate(item) {
      this.$set(item, "editSettlementDate", false);
      this.$set(item, "newSettlementDate", null);
    },

    async searchListCharges() {
      try {
        const params = {
          id: this.client_account_id,
        };
        const data = await ClientsOptionsServices.getListCharges(params);
        this.totalFeeAndCharges = data.data;
        const listCharges = data.data;

        this.totalFeeAndCharges = this.totalFeeAndCharges.reduce(
          (accumulator, object) => accumulator + Number(object.amount),
          0
        );

        listCharges.map((index) => {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });
          if (index.amount != null) {
            const data = formatter.format(index.amount);
            index.amount = data.replace("$", "");
          }
        });

        return listCharges || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async searchListPayments() {
      try {
        const params = {
          id: this.client_account_id,
        };
        const data = await ClientsOptionsServices.getListPayments(params);

        const listPayments = data.data;

        listPayments.map((item) => {
          const formatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });
          if (item.amount != null) {
            const data = formatter.format(item.amount);
            item.amount = data.replace("$", "");
          }

          this.$set(item, "editSettlementDate", false);
          this.$set(item, "newSettlementDate", null);

          const oldMonth = moment(new Date()).month();
          const newMonth = moment(item.settlement_date).month();

          if (newMonth - oldMonth == 0) {
            this.$set(item, "stateDate", true);
          } else {
            this.$set(item, "stateDate", false);
          }
        });
        return listPayments || [];
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
  },
};
</script>
<style>
.table-bg-fig {
  background-color: #3d3c40 !important;
  padding: 1rem;
}
.table-my-blue {
  background-color: #0993e3 !important;
  color: white;
  padding: 1rem;
}
</style>
<style scoped>
h1,
h2,
h3,
h4,
h5,
span,
p,
small {
  font-family: montserrat !important;
}
.bg-dark {
  background: #222325;
}
.balance {
  border: 0px solid transparent;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  width: 90%;
  text-align: center;
}
.my-table {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
}

.my-table thead th {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}
.rowClass {
  background-color: #222325 !important;
}
.my-black {
  background-color: black !important;
}
.my-light {
  background-color: white !important;
}

.my-light-blue {
  background-color: #e0f5ff !important;
}
.my-dark {
  background-color: #2d2d2d !important;
}
.bg-my-dark {
  background-color: #2d2d2d !important;
}
.text-my-dark {
  color: #5a5a5a !important;
}
.table-new-customization >>> thead tr th {
  background: rgba(63, 122, 250, 1) !important;
}
</style>
