export default [
  {
    path: "risky-clients",
    name: "risky-clients",
    component: () =>
      import(
        /* webpackChunkName: "ManaRiskyClients" */ "@/views/management/views/risky-clients/RiskyClients.vue"
      ),
    meta: {
      pageTitle: "Risky Clients",
      breadcrumb: [
        {
          text: "Risky Clients",
        },
      ],
    },
  },
]
