<template>
  <div>
    <b-modal
      modal
      size="lg"
      modal-class="modal-primary "
      title-tag="h3"
      :no-close-on-backdrop="true"
      @hidden="$emit('close')"
      ref="modalReconfirmarCase"
      scrollable
    >
      <template #modal-title>
        <span>
          {{ isOnlyView == true ? "VIEW" : "VALIDATE" }} CASE:
          <strong>{{ ReconfirmData.courtCaseNumber }}</strong></span
        >
      </template>

      <b-row>
        <b-col cols="12" md="6" xl="3">
          <span class="title-tag"
            >{{ dataClient.account == "-" ? "LEAD" : "CLIENT" }} NAME</span
          >
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s text-truncate"
              style="gap: 5px"
            >
              <status-account
                    v-if="dataClient.account != '-'"
                    :account="{
                      icon_status: clientStatus.icon_status,
                      color_status: clientStatus.color_status,
                      parent_name: clientStatus.parent_name,
                      name_status: clientStatus.name_status,
                      program_id: clientStatus.program_id,
                      status: clientStatus.status,
                    }"
                    :text="false"
                  ></status-account>
              {{ dataClient.lead_name }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6" xl="3">
          <span class="title-tag">ACCOUNT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
            >
              {{ dataClient.account }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6" xl="3">
          <span class="title-tag">START DATE</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
            >
              {{ ReconfirmData.start_date | myGlobal }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6" xl="3">
          <span class="title-tag">STATUS</span>
          <div class="border border-primary rounded">
            <p
              class="text-warning d-flex align-items-center justify-content-center my-1s"
            >
              {{ ReconfirmData.status }}
            </p>
          </div>
        </b-col>
      </b-row>
      <hr />
      <!-- Form without wizard -->
      <ValidationObserver
        ref="ReconfirmDataValidation"
        lazy
        v-bind:vid="'form-cliente-2'"
      >
        <b-row class="mt-2">
          <!-- switch -->
          <b-col
            offset-xl="5"
            offset-lg="5"
            offset-md="0"
            offset-sm="0"
            xl="3"
            lg="3"
            md="6"
            sm="6"
            v-if="clientAccountId && modulId == 5"
            class="text-right p-0"
          >
            <b-form-group>
              <label style="color: #e26969; margin-right: 1rem">CREDITOR</label>
              <label class="switch">
                <b-form-checkbox
                  v-model="checked"
                  switch
                  type="checkbox"
                  :disabled="isOnlyView"
                />
              </label>
              <label style="color: #56bec4; margin-left: 0.6rem">DEBT</label>
            </b-form-group>
          </b-col>

          <!-- Always Available -->
          <b-col
            xl="4"
            lg="4"
            md="6"
            sm="6"
            class="text-right p-0"
            :offset-xl="
              clientAccountId == null && (modulId == 5 || modulId != 5)
                ? 8
                : clientAccountId && modulId != 5
                ? 8
                : 0
            "
            :offset-lg="
              clientAccountId == null && modulId == 5
                ? 8
                : clientAccountId && modulId != 5
                ? 8
                : 0
            "
            :offset-md="
              clientAccountId == null && modulId == 5
                ? 8
                : clientAccountId && modulId != 5
                ? 6
                : 0
            "
            :offset-sm="
              clientAccountId == null && modulId == 5
                ? 8
                : clientAccountId && modulId != 5
                ? 6
                : 0
            "
          >
            <b-form-group>
              <label class="font-weight-bolder">
                {{ clientAccountId == null ? "Lead" : "Client" }}
                Always Available
              </label>
              <label class="switch ml-1">
                <b-form-checkbox
                  v-model="checkedAlways"
                  switch
                  type="checkbox"
                  :disabled="isOnlyView"
                />
              </label>
            </b-form-group>
          </b-col>

          <!-- Types Service -->
          <b-col xl="6" lg="6" cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="serviceSelect"
              rules="required"
            >
              <b-form-group
                id="service-select"
                label="Types Service"
                label-for="service-select"
              >
                <v-select
                  :options="allTypesService"
                  label="name"
                  v-model="ReconfirmData.typeService"
                  :reduce="(x) => x.id"
                  :disabled="
                    ReconfirmData.status !== 'PENDING' || isOnlyView == true
                  "
                  :searchable="true"
                  placeholder="Select type service"
                  :class="errors[0] ? 'border-danger' : null"
                  :clearable="false"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- Types Document -->
          <b-col xl="6" lg="6" cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="documentSelect"
              rules="required"
            >
              <b-form-group
                id="document-select"
                label="Types Document"
                label-for="service-select"
              >
                <v-select
                  :options="allTypesDocument"
                  label="name"
                  :disabled="ReconfirmData.status !== 'PENDING' || isOnlyView"
                  v-model="ReconfirmData.typeDocument"
                  :reduce="(x) => x.id"
                  :searchable="true"
                  placeholder="Select document"
                  :class="errors[0] ? 'border-danger' : null"
                  :clearable="false"
                >
                  <template #selected-option="{ name }">
                    <span v-b-tooltip.hover.bottom="name">{{ name }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- Court Case Number -->
          <b-col xl="6" lg="6" cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="courtNumberInput"
              rules="required"
            >
              <b-form-group
                id="courtNumber-input"
                label="Court Case Number"
                label-for="courtNumber-input"
              >
                <b-form-input
                  v-model="ReconfirmData.courtCaseNumber"
                  aria-describedby="input-live-help input-live-feedback"
                  :disabled="ReconfirmData.status !== 'PENDING' || isOnlyView"
                  placeholder="Enter court case number"
                  :class="errors[0] || errorCaseNumber ? 'border-danger' : null"
                ></b-form-input>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Document Date -->
          <b-col xl="6" lg="6" cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="documentDateInput"
              rules="required"
            >
              <b-form-group
                id="documentDate-input"
                label="Document Date"
                label-for="documentDate-input"
              >
                <b-form-datepicker
                  v-model="ReconfirmData.documentDate"
                  :disabled="ReconfirmData.status !== 'PENDING' || isOnlyView"
                  class="mb-2"
                  placeholder="Enter document date"
                  :class="errors[0] ? 'border-danger' : null"
                >
                </b-form-datepicker>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            xl="6"
            lg="6"
            cols="12"
            v-if="clientAccountId && modulId == 5 && checked"
          >
            <!-- Accounts -->
            <ValidationProvider
              v-slot="{ errors }"
              name="accountsSelect"
              rules="required"
            >
              <b-form-group
                id="accounts-type-select"
                label="Accounts"
                label-for="accounts-type-select"
              >
                <v-select
                  :options="allAccountsType"
                  label="name"
                  :disabled="ReconfirmData.status !== 'PENDING' || isOnlyView"
                  v-model="ReconfirmData.accountTypeId"
                  :reduce="(x) => x.id"
                  :searchable="true"
                  placeholder="Select account"
                  :class="errors[0] ? 'border-danger' : null"
                  :clearable="false"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Creditors -->
          <b-col xl="6" lg="6" cols="12" v-if="!checked">
            <ValidationProvider v-slot="{ errors }" rules="customRequired">
              <b-form-group label="Creditors" label-for="creditors">
                <vue-autosuggest
                  ref="autocomplete"
                  :suggestions="filteredOptions"
                  :get-suggestion-value="getSuggestionValue"
                  :input-props="{
                    id: 'autosuggest__input',
                    class: ['form-control ', errors[0]],
                    disabled: ReconfirmData.status !== 'PENDING' || isOnlyView,
                  }"
                  @closed="
                    creditor
                      ? (nameCreditor = nameCreditor)
                      : (nameCreditor = null)
                  "
                  @input="onInputChange"
                  @selected="selectHandler"
                  v-model="nameCreditor"
                >
                  <template slot="before-input">
                    <div class="lds-dual-ring" v-show="creditorsLoading"></div>
                  </template>
                  <template slot-scope="{ suggestion }">
                    <span class="my-suggestion-item cursor-pointer">
                      {{ suggestion.item.value }}
                    </span>
                  </template>
                </vue-autosuggest>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Attorney -->
          <b-col xl="6" lg="6" cols="12">
            <b-form-group id="attorney-input" label-for="attorney-input">
              <template #label>
                <div class="d-flex justify-content-between">
                  <span>Attorney</span>
                  <feather-icon
                    v-if="!isOnlyView"
                    icon="PlusCircleIcon"
                    size="15"
                    class="cursor-pointer text-primary"
                    v-b-tooltip.hover.topright="'ADD ATTORNEY'"
                    @click="openModalAttorneyManagment()"
                  />
                </div>
              </template>

              <v-select
                label="name"
                v-model="ReconfirmData.attorney"
                :disabled="ReconfirmData.status !== 'PENDING' || isOnlyView"
                :searchable="true"
                aria-describedby="input-live-help input-live-feedback"
                placeholder="Enter attorney"
                :options="allAttorneys"
                :reduce="(x) => x.id"
              ></v-select>
            </b-form-group>
          </b-col>

          <!-- State of case (court) -->
          <b-col xl="6" lg="6" md="12" sm="12" cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="stateSelect"
              rules="required"
            >
              <b-form-group
                id="state-select"
                label-for="state-select"
                label="State"
              >
                <v-select
                  v-model="ReconfirmData.state_slug"
                  :options="G_STATES_EEUU"
                  :reduce="(el) => el.id"
                  :class="errors[0] ? 'border-danger' : null"
                  :disabled="isOnlyView"
                  @input="onChangeState()"
                ></v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Court -->
          <b-col xl="6" lg="6" cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="courtSelect"
              :rules="ReconfirmData.state_slug == 'CA' ? 'required' : ''"
            >
              <b-form-group
                id="court-select"
                label="Court"
                label-for="court-select"
              >
                <template #label>
                  <div class="d-flex justify-content-between">
                    <span>Court</span>
                    <feather-icon
                      v-if="!isOnlyView"
                      icon="PlusCircleIcon"
                      size="15"
                      class="cursor-pointer text-primary"
                      v-b-tooltip.hover.topright="'ADD COURT'"
                      @click="openModalCourtManagment()"
                    />
                  </div>
                </template>

                <v-select
                  :options="allCourt"
                  label="name"
                  :disabled="ReconfirmData.status !== 'PENDING' || isOnlyView"
                  v-model="ReconfirmData.courtId"
                  :reduce="(x) => x.id"
                  :searchable="true"
                  placeholder="Select Court"
                  :class="errors[0] ? 'border-danger' : null"
                  :clearable="false"
                ></v-select>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col xl="6" lg="6" md="12" sm="12" cols="12">
            <b-form-group label="Assign To:" label-for="assign-to-input">
              <validation-provider v-slot="{ errors }" rules="required">
                <v-select
                  clearable
                  label="name"
                  placeholder="Select a user..."
                  v-model="ReconfirmData.assignTo"
                  :options="users"
                  :reduce="(option) => option.id"
                  :state="errors.length > 0 ? false : null"
                  :class="errors[0] ? 'border-danger' : null"
                  :disabled="isOnlyView"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6" v-if="ReconfirmData.motive_id">
            <ValidationProvider
              v-slot="{ errors }"
              name="motive_id"
              rules="required"
            >
              <b-form-group
                id="motive-input"
                label="No Payment Motive"
                label-for="motive-input"
              >
                <v-select
                  :options="motives"
                  label="description"
                  v-model="ReconfirmData.motive_id"
                  :reduce="(x) => x.id"
                  :searchable="true"
                  placeholder="Select a motive"
                  :class="errors[0] ? 'border-danger' : null"
                  :clearable="false"
                  :disabled="isOnlyView"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- Available Days -->
          <transition name="fade" mode="out-in">
            <b-col cols="6" v-if="!availableEdit">
              <ValidationProvider
                v-slot="{ errors }"
                name="AvailableDaysSelect"
                :rules="!availableEdit ? 'required' : ''"
              >
                <b-form-group
                  label-class="font-weight-bolder"
                  label="Available Days"
                  label-for="TypeDays"
                >
                  <v-select
                    id="available-days-select"
                    v-model="ReconfirmData.availableDays"
                    :dir="'ltr'"
                    :multiple="true"
                    transition="multiple"
                    label="name"
                    :options="dayOptions"
                    :class="[
                      { 'disable-class': !availableEdit },
                      {
                        'border-danger rounded': errors[0] && !availableEdit,
                      },
                    ]"
                    :closeOnSelect="false"
                    :disabled="availableEdit || isOnlyView"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </transition>

          <!-- Available Hour From -->
          <transition name="fade" mode="out-in">
            <b-col xl="3" lg="3" md="3" sm="12" cols="12" v-if="!availableEdit">
              <ValidationProvider
                v-slot="{ errors }"
                name="AvailableHourFromSelect"
                :rules="!availableEdit ? 'required' : ''"
              >
                <b-form-group
                  label-class="font-weight-bolder"
                  label="Available Hour From"
                  label-for="TypeHourFrom"
                >
                  <kendo-timepicker
                    :format="'HH:mm'"
                    v-model="ReconfirmData.availableHourFrom"
                    :interval="15"
                    class="rounded bg-transparent"
                    placeholder="Hour"
                    :disabled="availableEdit || isOnlyView"
                    :min="minTime"
                    style="height: 2.73rem; width: 100%"
                    :class="
                      errors[0] && !availableEdit
                        ? 'rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                        : 'rounded bg-transparent k-picker-custom picker-select-date'
                    "
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </transition>

          <!-- Available Hour To -->
          <transition name="fade" mode="out-in">
            <b-col xl="3" lg="3" md="3" sm="12" cols="12" v-if="!availableEdit">
              <ValidationProvider
                v-slot="{ errors }"
                name="AvailableHourToSelect"
                :rules="!availableEdit ? 'required' : ''"
              >
                <b-form-group
                  label-class="font-weight-bolder"
                  label="Available Hour To"
                  label-for="TypeHourTo"
                >
                  <kendo-timepicker
                    :format="'HH:mm'"
                    v-model="ReconfirmData.availableHourTo"
                    :interval="15"
                    class="rounded bg-transparent"
                    placeholder="Hour"
                    :disabled="availableEdit || isOnlyView"
                    :min="minTime"
                    style="height: 2.73rem; width: 100%"
                    :class="
                      errors[0] && !availableEdit
                        ? 'rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                        : 'rounded bg-transparent k-picker-custom picker-select-date'
                    "
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </transition>
          <b-col cols="12"></b-col>

          <b-col
            class="mt-1"
            xl="12"
            lg="12"
            cols="12"
            v-if="validateEmptyJson(ReconfirmData.extra_information)"
          >
            <span class="text-primary font-weight-bolder">
              Extra Information
            </span>
            <b-row class="mt-1">
              <b-col xl="6" lg="6" cols="6">
                <ValidationProvider v-slot="{ errors }">
                  <b-form-group
                    id="documentDate-input"
                    label="Discount Date"
                    label-for="documentDate-input"
                  >
                    <b-form-datepicker
                      :value="
                        JSON.parse(ReconfirmData.extra_information).discountDate
                      "
                      disabled
                      class="mb-2"
                      :class="errors[0] ? 'border-danger' : null"
                    >
                    </b-form-datepicker>
                    <label v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </label>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xl="6" lg="6" cols="6">
                <ValidationProvider name="discountAmount" v-slot="{ errors }">
                  <b-form-group
                    id="discounAmount-input"
                    label="Discount Amount"
                    label-for="discount-input"
                  >
                    <money
                      v-bind="vMoney"
                      v-model="
                        JSON.parse(ReconfirmData.extra_information)
                          .discountAmount
                      "
                      class="form-control mb-2"
                      :class="{
                        'border-danger': errors[0],
                      }"
                      disabled
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-col>

          <!-- files -->
          <b-col xl="6" lg="6" md="6" sm="12" cols="12" v-if="!isOnlyView">
            <ValidationProvider
              v-slot="{ errors }"
              name="FileRequired"
              :rules="!hasRecords ? 'required' : ''"
            >
              <b-form-group
                id="files-drag-and-drop"
                label="Files"
                label-for="files-drag-and-drop"
              >
                <drag-and-drop
                  ref="dyd"
                  class="w-100 mb-1"
                  v-model="files"
                  :filesArray="files"
                  :class="errors[0] ? 'border-danger' : null"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="6">
            <b-table
              ref="trackingTable"
              class="blue-scrollbar mt-2"
              primary-key="id"
              empty-text="No matching records found"
              responsive="md"
              sticky-header="30vh"
              small
              show-empty
              no-provider-filtering
              :fields="fields"
              :items="items"
              :busy.sync="isBusy"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #cell(actions)="data">
                <div class="d-flex align-items-center justify-content-center">
                  <feather-icon
                    icon="EyeIcon"
                    class="cursor-pointer mr-1 text-primary"
                    size="17"
                    v-b-tooltip.hover.left="'View File'"
                    @click="viewFile(data.item.url)"
                  />

                  <feather-icon
                    v-if="!isOnlyView"
                    icon="TrashIcon"
                    class="cursor-pointer text-danger"
                    size="17"
                    @click="deleteDocument(data.item.id)"
                  />
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </ValidationObserver>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-button
            v-if="!isOnlyView"
            variant="primary"
            @click="validateCase()"
            class="float-right"
          >
            <feather-icon icon="CheckCircleIcon" /> VALIDATE
          </b-button>
        </div>
      </template>
    </b-modal>

    <PreparedAnswerModal
      v-if="modalQuestionnaireShow"
      @completeAnswer="closeAllModals"
      :caseId="caseId"
      @onlyClose="onlyClose"
    />

    <modal-attorney-management
      v-if="showAttorneyModal"
      :attorneyId="null"
      @close="(showAttorneyModal = false), getAllAttorneys()"
    />

    <modal-court-management
      v-if="showCourtModal"
      :courtId="null"
      @close="closeAddCourtModal()"
      @closing="getAllCourtTypeCiByState()"
    />
  </div>
</template>

  <script>
import { mapGetters, mapActions } from "vuex";
// utilities
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
// components
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import PreparedAnswerModal from "@/views/debt-solution/views/court-info/components/modals/PreparedAnswerModal.vue";
import ClientService from "@/views/commons/components/clients/services/clients.services";
import CreditorsService from "@/views/debt-solution/views/creditors/service/creditors.service.js";
import SettingsService from "@/views/debt-solution/views/settings/views/manageCI/services/Settings.service.js";
import ModalAttorneyManagement from "@/views/debt-solution/views/settings/views/manageCI/components/modal/ModalAttorneyManagement.vue";
import ModalCourtManagement from "@/views/debt-solution/views/settings/views/manageCI/components/modal/ModalCourtManagement.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import VMoney from "v-money";

export default {
  components: {
    PreparedAnswerModal,
    VueAutosuggest,
    vSelect,
    DragAndDrop,
    ModalAttorneyManagement,
    ModalCourtManagement,
    VMoney,
    StatusAccount
  },
  directives: { money: VMoney },
  props: {
    modalCourtInfo: {
      type: Boolean,
    },
    caseId: {
      type: Number,
      default: null,
    },
    isOnlyView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_STATES_EEUU: "StandarStore/G_STATES_EEUU",
    }),
    minTime() {
      let dTime = this.ReconfirmData.availableHour || "00:00";
      let contactDtime = "01/01/1970 " + dTime;
      return new Date(contactDtime);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isDebtSolution() {
      return this.$route.matched[0].meta.module == 5;
    },
    buttonNextText() {
      if (this.currentStep == 1) {
        return "Validate";
      }
      return "Next";
    },
  },
  data() {
    return {
      // currentStep: 0,
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      // cardsToDisable: [],
      // amount: null,
      // createCreditOn: false,
      // vmoneyValidate: false,

      isBusy: false,
      fields: [
        { key: "name", label: "Name File", sortable: false },
        { key: "actions", label: "actions", sortable: false },
      ],
      items: [],
      users: [],
      ReconfirmData: {
        typeService: null,
        typeDocument: null,
        courtCaseNumber: "",
        attorney: null,
        documentDate: null,
        courtId: null,
        accountTypeId: null,
        idCreditor: "",
        availableDays: [],
        availableHourFrom: "",
        availableHourTo: "",
        switchAvailableAlways: 0,
        assignTo: "",
        state_slug: "",
        amount_paid: null,
        // pending_amount: null,
        // fraction_type: null,
        extra_information: null,
        motive_id: null,
      },
      dataClient: [],
      allTypesService: [],
      allTypesDocument: [],
      allCourt: [],
      allAttorneys: [],
      allAccountsType: [],
      filteredOptions: [],
      creditor: null,
      nameCreditor: "",
      files: [],
      dayOptions: [
        { name: "Sunday", id: 1 },
        { name: "Monday", id: 2 },
        { name: "Tuesday", id: 3 },
        { name: "Wednesday", id: 4 },
        { name: "Thursday", id: 5 },
        { name: "Friday", id: 6 },
        { name: "Saturday", id: 7 },
      ],
      minDate: new Date(1000, 1, 1),
      maxDate: new Date(2050, 9, 1),
      value: "00:00 AM",
      disabled: false,
      modulId: null,
      clientAccountId: null,
      modalQuestionnaireShow: false,
      lead_id: null,
      creditorsLoading: false,
      errorCaseNumber: null,
      advisor_id: "",
      checked: false,
      checkedAlways: false,
      availableEdit: false,
      hasRecords: [],
      showAttorneyModal: false,
      showCourtModal: false,
      cards: [],
      optionsAmount: [],
      selectedAmountOption: {},
      usedCard: null,
      validationMinAmount: 0,
      otherAmount: 0,
      motives: [],
      clientStatus:{},
    };
  },
  watch: {
    async "ReconfirmData.typeService"(newVal, oldVal) {
      this.allTypesDocument = [];
      if (oldVal != null) {
        this.ReconfirmData.typeDocument = null;
      }

      await this.getAllDocumentTypeCi(newVal);
    },
    checked(newVal) {
      if (newVal) {
        this.ReconfirmData.idCreditor = null;
      } else {
        this.ReconfirmData.accountTypeId = null;
      }

      this.nameCreditor = null;
      this.filteredOptions = [];
    },
    checkedAlways(newVal) {
      if (newVal) {
        this.availableEdit = true;
        this.ReconfirmData.availableDays = [];
        this.ReconfirmData.availableHourFrom = "";
        this.ReconfirmData.availableHourTo = "";
      } else {
        this.availableEdit = false;
      }
    },
  },
  async mounted() {
    this.addPreloader();
    this.toggleModal("modalReconfirmarCase");
    await this.getDataReconfirmModal();
    await this.usersAllPrograms();
    await this.getAllServiceTypeCi();
    await this.getAllAccountsTypeCi();
    await this.getClientOrLeadData();
    await this.getAllCourtTypeCiByState();
    await this.getAllAttorneys();
    await this.getStatesEEUU();
    await this.getCiMotives();
    this.removePreloader();
  },
  methods: {
    async getCiMotives() {
      try {
        this.addPreloader();
        const { data } = await CourtInfoService.getCiMotives();
        this.motives = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    validateEmptyJson(value) {
      if (value == null || value == "[]") {
        return false;
      } else {
        return true;
      }
    },
    ...mapActions({
      A_GET_STATES_EEUU: "StandarStore/A_GET_STATES_EEUU",
    }),
    cardId(value) {
      this.card = value.id;
    },
    async getStatesEEUU() {
      try {
        await this.A_GET_STATES_EEUU({});
      } catch (error) {
        console.log("Something went wrong on getStatesEEUU", error);
        throw error;
      }
    },
    async usersAllPrograms() {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });

        this.users = data.data.map((val) => {
          return {
            id: val.id,
            name: val.user_name,
          };
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getAllServiceTypeCi() {
      try {
        const response = await CourtInfoService.getAllServiceTypeCi();
        this.allTypesService = response;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong searching all Supervisors!"
        );
      }
    },
    async getAllDocumentTypeCi(type) {
      try {
        const response = await CourtInfoService.getAllDocumentTypeCi({
          service_type_id: type == 1 ? type : null,
        });
        this.allTypesDocument = response;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong searching all Supervisors!"
        );
      }
    },
    async refreshCards() {
      this.getClientOrLeadData();
    },
    async getClientOrLeadData() {
      try {
        const response = await CourtInfoService.getDataClientById({
          lead_id: this.lead_id,
          module_id: this.modulId,
        });
        if (response.length > 0) {
          this.dataClient = response[0];
          this.cards = JSON.parse(response[0].cards);
          this.clientStatus = JSON.parse(response[0].client_status);
        } else {
          this.dataClient = null;
        }
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with search!"
        );
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.value;
    },
    async onInputChange(text) {
      this.creditor = null;
      this.creditorsLoading = true;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        this.creditorsLoading = false;
        return;
      }
      const { data } = await CreditorsService.searchCreditors(text);
      this.filteredOptions = [{ data: [...data] }];
      this.creditorsLoading = false;
    },
    selectHandler(value) {
      this.creditor = value.item.id;
    },
    async finishAnswer() {
      this.vmoneyValidate = true;
      const compPay = this.$refs.formPayValidation;
      const isValidPay = await compPay.validate();

      if (isValidPay) {
        this.validateCase();
      }
      return isValidPay;
    },
    closeAddCourtModal() {
      this.showCourtModal = false;
    },
    async validateFirstStep() {
      const validation = await this.$refs.ReconfirmDataValidation.validate();
      if (validation) {
        let isValidCase = await this.validateCaseNumber();

        if (isValidCase) {
          return false;
        } else {
          this.currentStep = 1;
          return true;
        }
      }
    },
    async validateCase() {
      try {
        const validation = await this.$refs.ReconfirmDataValidation.validate();
        if (!validation) return;
        let formData = new FormData();
        this.files.forEach((file) => {
          formData.append("files[]", file, file.name);
        });

        formData.append(
          "document_name",
          this.allTypesDocument.find(
            (option) => option.id === this.ReconfirmData.typeDocument
          ).name
        );

        formData.append("typeService", this.ReconfirmData.typeService);
        formData.append("typeDocument", this.ReconfirmData.typeDocument);
        formData.append("courtCaseNumber", this.ReconfirmData.courtCaseNumber);
        formData.append("attorney", this.ReconfirmData.attorney);
        formData.append("documentDate", this.ReconfirmData.documentDate);
        formData.append("courtId", this.ReconfirmData.courtId);
        formData.append("accountTypeId", this.ReconfirmData.accountTypeId);
        formData.append("idCreditor", this.creditor);
        formData.append(
          "availableDays",
          JSON.stringify(this.ReconfirmData.availableDays)
        );
        formData.append(
          "availableHourFrom",
          this.ReconfirmData.availableHourFrom
        );
        formData.append("availableHourTo", this.ReconfirmData.availableHourTo);
        formData.append(
          "switchAvailableAlways",
          this.checkedAlways == true ? 1 : 0
        );
        formData.append("user_id", this.currentUser.user_id);
        formData.append("role_id", this.currentUser.role_id);
        formData.append("id", this.caseId);
        let val = this.ReconfirmData.typeService == 1 ? 1 : 2;
        formData.append("hasAnswer", val);
        formData.append("advisorId", this.ReconfirmData.assignTo);
        formData.append("client_account_id", this.clientAccountId);
        formData.append("state_slug", this.ReconfirmData.state_slug);
        formData.append("module_id", this.moduleId);
        formData.append("motive_id", this.ReconfirmData.motive_id);

        let confirm = await this.showConfirmSwal(); // confirm save form
        if (confirm.isConfirmed) {
          this.addPreloader();

          await CourtInfoService.reconfirmCaseCourtInfo(formData);
          this.files = [];
          this.$emit("reloadTable");
          this.$emit("close");
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Case validated successfully!"
          );
        }
      } catch (error) {
        console.log(error);
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async getAllAccountsTypeCi() {
      try {
        const response = await CourtInfoService.getAllCreditorsAccountsCi({
          client_account_id: this.clientAccountId,
        });
        this.allAccountsType = response;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong searching accounts!"
        );
      }
    },
    onChangeState() {
      this.ReconfirmData.courtId = null;
      this.getAllCourtTypeCiByState();
    },

    async getAllCourtTypeCiByState() {
      try {
        let params = {
          stateSlug: this.ReconfirmData.state_slug,
        };
        const { data } = await CourtInfoService.getAllCourtTypeCiByState(
          params
        );
        this.allCourt = data;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong searching all Supervisors!"
        );
      }
    },
    async getDataReconfirmModal() {
      try {
        const response = await CourtInfoService.getDataCourtCase({
          case_id: this.caseId,
        });
        this.checked = response[0].list_credit_id ? true : false;
        this.clientAccountId = response[0].client_account_id;
        this.ReconfirmData.status = response[0].status;
        this.ReconfirmData.lead_name = response[0].name_lead;
        this.ReconfirmData.account = response[0].account;
        this.ReconfirmData.start_date = response[0].date_entry;
        this.ReconfirmData.typeService = response[0].type_service_id;
        this.ReconfirmData.typeDocument = response[0].type_document;
        this.ReconfirmData.courtCaseNumber = response[0].case_number;
        this.ReconfirmData.attorney = response[0].attorney;
        this.ReconfirmData.documentDate = response[0].date_entry;
        this.ReconfirmData.courtId = response[0].court_id;
        this.ReconfirmData.accountTypeId = response[0].list_credit_id;
        this.ReconfirmData.state_slug = response[0].state_slug;
        this.ReconfirmData.motive_id = response[0].motive_id;

        this.creditor = response[0].credit_id;
        this.modulId = response[0].modul_id;
        this.nameCreditor = response[0].creditor_name;
        this.ReconfirmData.availableDays = JSON.parse(
          response[0].available_days
        );
        this.ReconfirmData.availableHourFrom = response[0].available_hour_from;
        this.ReconfirmData.availableHourTo = response[0].available_hour_to;
        this.checkedAlways = response[0].available_always == 1 ? true : false;
        this.lead_id = response[0].lead_id;
        this.advisor_id = response[0].advisor_id;
        this.ReconfirmData.assignTo = response[0].advisor_id;
        this.items = JSON.parse(response[0].case_files);
        this.hasRecords = this.items != null ? this.items.length > 0 : false;
        this.ReconfirmData.amount_paid = response[0].amount_paid;
        this.ReconfirmData.extra_information = response[0].extra_information;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          this.getInternalErrors(error) || "Something went wrong"
        );
      }
    },
    async validateCaseNumber() {
      const params = {
        case_number: this.ReconfirmData.courtCaseNumber,
        caseId: this.caseId,
      };
      const resp = await amgApi.post(
        "debt-solution/court-info/get-validate-case-number",
        params
      );
      if (resp.data.status == 200) {
        if (resp.data.state) {
          this.errorCaseNumber = "the case number is not available";
          return true;
        } else {
          this.errorCaseNumber = null;
          return false;
        }
      }
    },

    closeAllModals() {
      this.modalQuestionnaireShow = false;
      this.$emit("closing");
    },
    onlyClose() {
      this.modalQuestionnaireShow = false;
      this.$emit("close");
    },
    viewFile(url) {
      window.open(url, "_blank");
    },
    async deleteDocument(idcas) {
      let confirm = await this.showConfirmSwal(); // confirm save form
      if (confirm.isConfirmed) {
        this.addPreloader();
        await CourtInfoService.DeleteFilesCase({ id: idcas });
        this.getDataReconfirmModal();
        const index = this.items.findIndex((item) => item.id === idcas);
        this.items.splice(index, 1);
        this.removePreloader();
      }
    },
    async getAllAttorneys() {
      try {
        const { data } = await SettingsService.listAttorneys();
        this.allAttorneys = data;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong searching all Supervisors!"
        );
      }
    },

    openModalAttorneyManagment() {
      this.showAttorneyModal = true;
    },
    openModalCourtManagment() {
      this.showCourtModal = true;
    },
    // changeSwitchAvailableAlways() {
    //   if (this.checkedAlways == 1) {
    //     this.availableEdit = true;
    //   } else {
    //     this.availableEdit = false;
    //   }
    //   this.ReconfirmData.availableDays = [];
    //   this.ReconfirmData.availableHourFrom = "";
    //   this.ReconfirmData.availableHourTo = "";
    // },
  },
};
</script>
  <style lang="scss" scoped>
.select__creditor--error {
  border: 1px solid #fc424a;
  border-radius: 0 5px 5px 0;
}
.select__creditor {
  flex: 1;
}
.dark-layout {
  .border-right-separator {
    border-right: 1px solid #747476;
  }
}
.border-right-separator {
  border-right: 1px solid #e0e0e0;
}
</style>

  <style>
.kk-picker-wrapp .k-timepicker .k-picker-wrap {
  border: 1px solid red !important;
}
.pac-container.pac-logo:after {
  display: none !important;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>

