export default [
  {
    path: "direct-round",
    name: "bc-direct-round",
    component: () =>
      import(
        /* webpackChunkName: "directRound" */
        "@/views/ce-digital/sub-modules/customer-service/views/direct-round/direct-round.vue"
      ),
    redirect: { name: "bc-pending-directs-round" },
    meta: {
      pageTitle: "Direct",
      breadcrumb: [
        {
          text: "Direct",
          active: true,
        },
      ],
      permittedRoles: [1, 2, 16, 17],
      routeDirectPending: "bc-pending-directs-round",
      routeDirectCompleted: "bc-completed-directs-round",
      routeDirectToDeleted: "bc-deleted-directs-round",
    },

    children: [
      {
        path: "pending",
        name: "bc-pending-directs-round",
        component: () =>
          import(
            /* webpackChunkName:  "pendingDirectsRound" */
            "@/views/specialists/sub-modules/analyst-department/views/direct-round/components/DirectRounds.vue"
          ),
        meta: {
          typeTab: "pending",
          pageTitle: "Direct Dispute",
          breadcrumb: [
            {
              text: "Pending",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 16, 17],
        },
        props: { statusDirectType: "pending" },
      },
      {
        path: "completed",
        name: "bc-completed-directs-round",
        component: () =>
          import(
            /* webpackChunkName: "completedDirectsRound" */
            "@/views/specialists/sub-modules/analyst-department/views/direct-round/components/DirectRounds.vue"
          ),
        meta: {
          typeTab: "completed",
          pageTitle: "Direct Dispute",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 16, 17],
        },
        props: { statusDirectType: "completed" },
      },
      {
        path: "deleted",
        name: "bc-deleted-directs-round",
        component: () =>
          import(
            /* webpackChunkName: "deletedDirectsRound" */
            "@/views/specialists/sub-modules/analyst-department/views/direct-round/components/DirectRounds.vue"
          ),
        meta: {
          typeTab: "deleted",
          pageTitle: "Direct Dispute",
          breadcrumb: [
            {
              text: "Deleted",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 16, 17],
        },
        props: { statusDirectType: "deleted" },
      },
    ],
  },
]
