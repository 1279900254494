export default [
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Year",
    model: null,
    options: getYears(),
    reduce: "value",
    selectText: "text",
    cols: 12,
    key: "year",
    visible: false
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    key: "from"
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    key: "to"
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Captured By",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    key: "captured_by"
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Seller",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 7,
    key: "seller"
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status Seller",
    model: null,
    reduce: "value",
    selectText: "label",
    cols: 5,
    emitEvent: true,
    typeEvent: "status_seller",
    key: "status_seller"
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Source Name",
    multiple: true,
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    key: "source_name"
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "SUB SOURCE",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name",
    cols: 12,
    visible: false,
    key: "sub_source"
  }, // 6
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    key: "status",
    visible: false
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Services",
    model: null,
    multiple: true,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    key: "services"
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "IP",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 6,
    key: "initial_payment",
    visible: true
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "ST/AD",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 6,
    key: "st_ad"
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Origin Country",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 12,
    key: "origin_country"
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Work Status",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 6,
    key: "work_status"
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Legal Status",
    model: null,
    options: [],
    reduce: "id",
    selectText: "label",
    cols: 6,
    key: "legal_status"
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Type Doc",
    model: null,
    options: [
      { text: "All", value: 0 },
      { text: "SSN", value: "ssn" },
      { text: "ITIN", value: "itin" },
      { text: "CPN", value: "other" },
    ],
    reduce: "value",
    selectText: "text",
    cols: 6,
    key: "type_doc"
  },
  // {
  //   type: "input",
  //   margin: true,
  //   showLabel: true,
  //   label: "Flyer Code",
  //   model: null,
  //   selectText: "text",
  //   cols: 6,
  // },
];

function getYears() {
  const date = new Date();
  const start = date.getFullYear();
  const end = 2018;
  let years = [];
  for (let i = start; i > end; i--) {
    years.push({
      text: i,
      value: i
    });
  }
  return years;
}