export default [
  {
    label: "Creditors Name",
    key: "name_creditor",
    visible: true
  },
  {
    label: "# Account",
    key: "account_number",
    visible: true
  },
  {
    label: "Total Balance",
    key: "balance",
    tdClass: "text-center",
    thClass: "text-center",
    visible: true
  },
  {
    label: "Monthly Current Payment",
    key: "monthly",
    tdClass: "text-center",
    thClass: "text-center",
    visible: true
  },
  {
    label: "Months Behind",
    key: "months",
    tdClass: "text-center",
    thClass: "text-center",
    visible: true
  },
  {
    label: "Interest Rate (%)",
    key: "interest",
    tdClass: "text-center",
    thClass: "text-center",
    formatter: (value) => `${value} %`,
    visible: true
  },
  {
    label: "Type",
    key: "type",
    tdClass: "text-center",
    thClass: "text-center",
    show: true,
    visible: true
  },
  {
    label: "Actions",
    key: "actions",
    tdClass: "text-center",
    thClass: "text-center",
    visible: false
  },
]
