import PendingPaymentsService from "@/views/ce-digital/sub-modules/financial/view/pending-payments/service/pending-payments.service.js"
import ClientsService from '@/views/business/views/clients/services/clients.service';

export default {
    namespaced: true,
    state: {
        S_PENDING_PAYMENTS_MONTH: new Date().getMonth() + 1,
        S_PENDING_PAYMENTS_YEAR: null,
        S_PENDING_PAYMENTS_COUNTER: [],
        S_PENDING_PAYMENTS_REFRESH: true,
        S_CLIENTS_COUNTER: [], 
    },
    mutations: {
        M_SET_PENDING_PAYMENTS_MONTH(state, params) {
            state.S_PENDING_PAYMENTS_MONTH = params;
        },
        M_SET_PENDING_PAYMENTS_YEAR(state, params) {
            state.S_PENDING_PAYMENTS_YEAR = params;
        },
        M_SET_PENDING_PAYMENTS_COUNTER(state, params) {
            state.S_PENDING_PAYMENTS_COUNTER = params;
        },
        M_SET_PENDING_PAYMENTS_REFRESH(state, params) {
            state.S_PENDING_PAYMENTS_REFRESH = params;
        },
        M_SET_CLIENTS_COUNTER(state, params) {
            state.S_CLIENTS_COUNTER = params;
        },
    },
    getters: {
        G_PENDING_PAYMENTS_MONTH(state) {
            return state.S_PENDING_PAYMENTS_MONTH;
        },
        G_PENDING_PAYMENTS_YEAR(state) {
            return state.S_PENDING_PAYMENTS_YEAR;
        },
        G_PENDING_PAYMENTS_COUNTER(state) {
            return state.S_PENDING_PAYMENTS_COUNTER;
        },
        G_PENDING_PAYMENTS_REFRESH(state) {
            return state.S_PENDING_PAYMENTS_REFRESH;
        },
        G_CLIENTS_COUNTER(state) {
            return state.S_CLIENTS_COUNTER;
        },
        G_CLIENTS_COUNTER_CURRENT(state) {
            const counter = state.S_CLIENTS_COUNTER.find( counter => counter.status === 8);
            return counter ? counter.count : 0;
        },
        G_CLIENTS_COUNTER_ONE_MONTH_LATE(state) {
            const counter = state.S_CLIENTS_COUNTER.find( counter => counter.status === 9);
            return counter ? counter.count : 0;
        },
        G_CLIENTS_COUNTER_TWO_MONTH_LATE(state) {
            const counter = state.S_CLIENTS_COUNTER.find( counter => counter.status === 10);
            return counter ? counter.count : 0;
        },
        G_CLIENTS_COUNTER_TOTAL_ACTIVE(state, getters) {
            const currentCounter = getters.G_CLIENTS_COUNTER_CURRENT;
            const oneMonthLateCounter = getters.G_CLIENTS_COUNTER_ONE_MONTH_LATE;
            const twoMonthsLateCounter = getters.G_CLIENTS_COUNTER_TWO_MONTH_LATE;
            return currentCounter + oneMonthLateCounter + twoMonthsLateCounter;
        },
        G_CLIENTS_COUNTER_HOLD(state) {
            const counter = state.S_CLIENTS_COUNTER.find( counter => counter.status === 2);
            return counter ? counter.count : 0;
        },
        G_CLIENTS_COUNTER_CANCELED(state) {
            const counter = state.S_CLIENTS_COUNTER.find( counter => counter.status === 4);
            return counter ? counter.count : 0;
        },
        G_CLIENTS_COUNTER_CLOSED(state) {
            const counter = state.S_CLIENTS_COUNTER.find( counter => counter.status === 6);
            return counter ? counter.count : 0;
        },
        G_CLIENTS_COUNTER_TOTAL_OUTSTANDING(state, getters) {
            const holdCounter = getters.G_CLIENTS_COUNTER_HOLD;
            const canceledCounter = getters.G_CLIENTS_COUNTER_CANCELED;
            const closedCounter = getters.G_CLIENTS_COUNTER_CLOSED;
            return holdCounter + canceledCounter + closedCounter;
        },
        G_CLIENTS_COUNTER_IN_PROGRESS(state) {
            const counter = state.S_CLIENTS_COUNTER.find( counter => counter.status === 11);
            return counter ? counter.count : 0;
        },
        G_CLIENTS_COUNTER_POTENTIAL(state) {
            const counter = state.S_CLIENTS_COUNTER.find( counter => counter.status === 12);
            return counter ? counter.count : 0;
        },
        G_CLIENTS_COUNTER_STAND_BY(state) {
            const counter = state.S_CLIENTS_COUNTER.find( counter => counter.status === 13);
            return counter ? counter.count : 0;
        },
        G_CLIENTS_COUNTER_TOTAL_LOYAL(state, getters) {
            const inProgressCounter = getters.G_CLIENTS_COUNTER_IN_PROGRESS;
            const potentialCounter = getters.G_CLIENTS_COUNTER_POTENTIAL;
            const standByCounter = getters.G_CLIENTS_COUNTER_STAND_BY;
            return inProgressCounter + potentialCounter + standByCounter;
        }
    },
    actions:{
        async A_PENDING_PAYMENTS_COUNTER({ commit },params) {
            try {
                const { data } = await PendingPaymentsService.getPendingPaymentsCounters(params); 
                commit('M_SET_PENDING_PAYMENTS_COUNTER', data)
                return data
            } catch (error) {
                console.error(error);
            }
        },
        async A_PENDING_PAYMENTS_REFRESH({ commit ,getters}) {
            try {  
                commit('M_SET_PENDING_PAYMENTS_REFRESH', !getters.G_PENDING_PAYMENTS_REFRESH)
            } catch (error) {
                console.error(error);
            }
        },
        async A_SET_CLIENTS_COUNTER({ commit }) {
            try {
              const data = await ClientsService.counterClientsFinancial({program_id: 3});
              commit('M_SET_CLIENTS_COUNTER', data);
            } catch (error) {
              console.error(error, 'error on A_SET_TRACKING_ID');
            }
          },
    }
}; 
