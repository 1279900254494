<template>
  <div>
    <b-card no-body :class="cardClass">
      <!-- id="sidebar-right" -->
      <b-sidebar
        v-if="filter.length"
        right
        backdrop
        bg-variant="white"
        sidebar-class="sidebar-lg"
        header-class="pt-1"
        :no-close-on-backdrop="backdropClose"
        :no-close-on-esc="backdropClose"
        lazy
        v-model="showSidebar"
        @hidden="closeSidebar"
      >
        <template #header>
          <div class="d-flex justify-content-between align-items-center w-100">
            <span>
              <h3>Advanced Search</h3>
            </span>
            <span class="cursor-pointer" @click="closeSidebar">
              <tabler-icon icon="XIcon" size="20" />
            </span>
          </div>
        </template>
        <b-container>
          <filters-component :filters="filter" @onSelectChange="emitEvent">
            <template #custom-select>
              <slot name="custom-vselect" />
            </template>
          </filters-component>
        </b-container>
        <template #footer>
          <b-container>
            <b-row class="d-flex p-1 float-right">
              <b-button variant="info" @click="resetFiltersButtons"
                >Reset</b-button
              >
              <!-- v-b-toggle.sidebar-right -->
              <b-button variant="custom-amg" class="ml-1" @click="sideBarSearch"
                >Search
              </b-button>
            </b-row>
          </b-container>
        </template>
      </b-sidebar>
      <!-- sidebar columns filter -->
      <b-sidebar
        id="sidebar-right-custom-filtered-columns"
        right
        backdrop
        bg-variant="white"
        sidebar-class="sidebar-lg"
        header-class="pt-1"
        :no-close-on-backdrop="backdropClose"
        :no-close-on-esc="backdropClose"
        lazy
      >
        <template #header>
          <div class="d-flex justify-content-between align-items-center w-100">
            <h2 class="">CUSTOM COLUMN FILTERING</h2>
            <span
              v-b-toggle.sidebar-right-custom-filtered-columns
              class="cursor-pointer"
            >
              <tabler-icon icon="XIcon" size="20" />
            </span>
          </div>
        </template>
        <b-container>
          <filters-columns
            :custom-filtering="customFiltering"
            @filter-updated="handleFilterUpdated"
          >
          </filters-columns>
        </b-container>
        <template #footer>
          <b-container>
            <b-row class="d-flex p-1 float-right">
              <b-button
                variant="info"
                @click="resetFiltersColumnsButton"
                v-b-toggle.sidebar-right-custom-filtered-columns
                >Close</b-button
              >
              <b-button
                v-b-toggle.sidebar-right-custom-filtered-columns
                variant="custom-amg"
                class="ml-1"
                @click="sideBarFilterColumnsSearch"
                >Filter</b-button
              >
            </b-row>
          </b-container>
        </template>
      </b-sidebar>
      <div v-if="topPaginate" class="mx-2 mb-2 mt-2">
        <b-row>
          <b-col
            cols="12"
            sm="7"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ startPage }} to {{ toPage }} of {{ totalRows }} entries
              <!-- este es el global -->
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="5"
            class="d-flex align-items-center justify-content-center justify-content-sm-end pagination_1"
          >
            <slot name="pagination">
              <b-pagination
                v-model="paginate.currentPage"
                :total-rows="totalRows"
                :per-page="paginate.perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="$emit('onChangeCurrentPage', $event)"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </slot>
          </b-col>
        </b-row>
      </div>
      <slot name="recovery-list" />
      <div id="" class="m-2">
        <!-- Table Top -->
        <b-row id="">
          <!-- Per Page -->
          <b-col
            id="filters-selector__pages"
            cols="12"
            md="6"
            class="mb-1 mb-md-0 d-flex justify-content-start align-items-center"
          >
            <label>Show</label>
            <v-select
              v-model="paginate.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="arrayPagination"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="$emit('reload', false)"
            />
            <label class="mr-2">entries</label>
            <feather-icon
              :badge="codeIcon ? counterNewClaim : 0"
              badge-classes="bg-danger blink"
              class="cursor-pointer mr-2"
              icon="RefreshCcwIcon"
              size="20"
              @click="resetFilter"
            />
            <slot name="buttons" class="" />

          </b-col>
          <!-- Search -->
          <b-col cols="0" md="2"></b-col>
          <b-col id="" cols="12" md="4">
            <div
              id=""
              class="d-flex align-items-center justify-content-end"
            >
              <slot name="buttons-filter" />
              <b-input-group v-if="!noVisiblePrincipalFilter" class="mr-1">
                <!-- filter principal -->
                <b-form-input
                  id="filter-slot-seach-input"
                  v-if="filterPrincipal.type === 'input'"
                  v-model="filterPrincipal.model"
                  :type="filterPrincipal.inputType"
                  :placeholder="filterPrincipal.placeholder"
                  :class="filterPrincipal.class"
                  @keyup.enter="$emit('reload')"
                />
                <b-input-group-append>
                  <b-button
                    id="filter-slot-search-icon"
                    variant="custom-amg"
                    @click="$emit('reload')"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-button
                v-if="hasFilters"
                @click="openSidebar"
                v-b-tooltip.bottom="'Advanced Search'"
                variant="custom-amg"
              >
                <div class="d-flex justify-content-between">
                  <feather-icon icon="FilterIcon" size="15" />
                </div>
              </b-button>
              <b-button
                v-if="customFiltering"
                v-b-toggle.sidebar-right-custom-filtered-columns
                v-b-tooltip.bottom="'Filter columns'"
                variant="info"
                class="ml-1"
              >
                <div class="d-flex justify-content-between">
                  <feather-icon icon="ColumnsIcon" size="15" />
                </div>
              </b-button>
              <slot name="buttons-filter-end" />
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="table-responsive">
        <slot name="table" />
      </div>
      <div id="bottomPaginate" v-if="doublePaginate" class="mx-2 mb-2 mt-2">
        <b-row>
          <b-col
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ startPage }} to {{ toPage }} of {{ totalRows }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <slot name="footer" />
          <b-col
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <slot name="pagination">
              <b-pagination
                v-model="paginate.currentPage"
                :total-rows="totalRows"
                :per-page="paginate.perPage"
                @input="$emit('onChangeCurrentPage', $event)"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </slot>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapGetters, mapState } from "vuex";
import FiltersColumns from "@/views/crm/views/sales-made/components/filter-columns/FilterColumns.vue";
export default {
  name: "SalesMadeNewClientComponent",
  components: {
    vSelect,
    FiltersColumns,
  },
  props: {
    changeColumnsByClient: { required: false, type: Boolean },
    filter: { required: true, type: Array, default: () => [] },
    totalRows: { required: false, type: Number },
    paginate: { required: true, type: Object },
    startPage: { required: false, type: Number },
    toPage: { required: false, type: Number },
    filterPrincipal: { required: false, type: Object },
    noVisiblePrincipalFilter: { required: false, default: false },
    cardClass: { required: false, type: String, default: "mb-1 border-0 p-0" },
    backdropClose: { required: false, default: false },
    doublePaginate: { required: false, type: Boolean, default: true },
    topPaginate: { required: false, type: Boolean, default: true },
    showMinimal: { required: false, type: Boolean, default: false },
    arrayPagination: {
      required: false,
      type: Array,
      default: () => [10, 25, 50, 100],
    },
    customFiltering: { required: false, type: Array },
    showInputSearch: { required: false, type: Boolean, default: true },
  },
  data() {
    return {
      counterNewClaim: 0,
      ColumnFilters: [],
      ColumnsOriginal: null,
      showSidebar: false,
    };
  },
  computed: {
    leftCol() {
      if (this.showMinimal) {
        return 8;
      }
      if (this.showInputSearch) {
        return this.changeColumnsByClient ? 6 : 8;
      }
      return 12;
    },
    rightCol() {
      if (this.showMinimal) {
        return 4;
      }
      if (this.showInputSearch) {
        return this.changeColumnsByClient ? 6 : 4;
      }
      return 12;
    },
    ...mapGetters({
      skin: "appConfig/skin",
      S_DATA_UPDATE_CLAIM: "global-store/G_DATA_UPDATE_CLAIM",
      currentUser: "auth/currentUser",
      CounterEmployee: "EmployeeClaimsStore/G_COUNTER_EMPLOYEE_CLAIMS",
    }),
    ...mapState({
      perPage: (state) => state.appConfig.perPage,
    }),
    hasFilters() {
      return this.filter ? Object.keys(this.filter).length !== 0 : false;
    },
    codeIcon() {
      return this.$route.meta.pageCodeIcon == "workers_claim";
    },
  },
  created() {
    if (this.filter) {
      this.filter.map((fil) => {
        if (fil.type === "input-money") {
          fil.model = 0.0;
        } else {
          fil.model = null;
        }
        fil.savedValue = null;
        fil.showText = "Select";
      });
    }
    this.filterPrincipal.model = "";
  },
  mounted() {
    this.paginate.perPage = this.perPage;
    this.$emit("reload");
  },
  methods: {
    resetFiltersButtons() {
      if (this.filter) {
        this.filter.map((fil) => {
          if (fil.type === "input-money") {
            fil.model = 0.0;
          } else {
            fil.model = null;
          }
          fil.savedValue = null;
          fil.showText = "Select";
        });
      }
      this.$emit("reset-all-filters");
    },
    resetFilter() {
      if (this.filter) {
        this.filter.map((fil) => {
          if (fil.type === "input-money") {
            fil.model = 0.0;
          } else {
            fil.model = null;
          }
        });
        this.counterNewClaim = 0;
      }
      this.filterPrincipal.model = "";
      this.$emit("reload");
    },
    sideBarSearch() {
      this.filterPrincipal.model = "";
      this.showSidebar = false;
      this.$emit("reload");
    },
    emitEvent(type) {
      this.$emit("onSelectChange", type);
    },
    /** Filter columns methods */
    resetFiltersColumnsButton() {
      this.ColumnFilters = this.customFiltering;
    },

    async sideBarFilterColumnsSearch() {
      const visibleColumns = this.ColumnFilters.filter(
        (item) => item.visible === true
      );
      if (visibleColumns.length > 5) {
        this.$emit("reloadTable", this.ColumnFilters);
      } else {
        this.showToast(
          "danger",
          "top-right",
          "Oops!",
          "XIcon",
          "You must select at least 5 columns visible"
        );
      }
    },
    handleFilterUpdated(ColumnFilters) {
      this.ColumnFilters = ColumnFilters;
    },
    openSidebar() {
      this.showSidebar = true;
    },
    closeSidebar() {
      this.showSidebar = false;
    },
  },
  watch: {
    perPage(newVal) {
      this.paginate.perPage = newVal;
      this.$emit("reload");
    },
    "S_DATA_UPDATE_CLAIM.status": {
      handler() {
        if (this.S_DATA_UPDATE_CLAIM.idModule == this.currentUser.modul_id) {
          if (this.$route.meta.status == 1) {
            this.counterNewClaim++;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.flexible {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
}
.per-page-selector {
  width: 90px;
}
td.div {
  width: 100% !important;
}
.hover-close {
  transition: 300ms;
}
.hover-close:hover {
  background-color: #ff3b19 !important;
  color: white !important;
}
@media (max-width: 960px) {
  .column-table {
    display: flex;
    flex-direction: column;
  }
}
.blink {
  animation: animationBlink 1s ease-in-out infinite;
}
.blink:hover {
  animation: none;
}
@keyframes animationBlink {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
</style>
