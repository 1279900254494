import ProductRequestNavigation from "@/views/quality/views/request/request.nav.js";
import LeadsNavigation from "@/views/quality/views/leads/leads.navigation";
import HelpDeskNavigation from "@/views/quality/views/helpdesk/helpdesk.nav";
import LoansNavigation from "@/views/quality/views/loans/loans.nav";
import FileManagerNavigation from "@/views/quality/views/file-manager/file-manager.nav";
import ClientsNavigation from "@/views/quality/views/clients/clients-group.navigation";
import SettingsNavigation from "@/views/quality/views/settings/settings.nav";
import ClaimNavigation from "@/views/quality/views/claims/claims.nav";
import InquiriesNavigation from "@/views/quality/views/inquiries/inquiries.nav.js";
import DashboardNavigation from "@/views/quality/views/dashboard/navigation/dashboard.nav";
import EmployeeClaimsNavigation from "@/views/quality/views/employee-claims/navigation/employee-claims.nav";
//import QualityProjectsNavigation from "@/views/quality/views/projects/quality-projects.navigation";
import RingCentralNavigation from "@/views/quality/views/ring-central/ring-central.nav.js";
import CourtInfoNavigation from "@/views/quality/views/cour-info/navigation/ds-court-info.nav";
import RiskyClientsNavigation from "@/views/quality/views/risky-clients/navigation/risky.navigation";
import MOFNavigation from "@/views/quality/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/quality/views/recruitment-process/quality-recruitment_process.navigation";
import CallsReviewNavigation from "@/views/quality/views/calls-review/calls-review.nav.js";
import RequestNcrNav from "@/views/quality/views/request-ncr/request-ncr.nav";
import NotesWCNavigation from "@/views/quality/views/notes-without-calls/notes-wc.nav.js";
import RequestClientStatusNavigation from "@/views/quality/views/request-client-status/navigation/request-client-status.nav.js";
import SalesMadeNavigation from "@/views/quality/views/sales-made/navigation/sales-made.nav.js";
import CallImpressionNavigation from "@/views/quality/views/call-impression/navigation/call-impression.navigation.js";

const navigation = [
  {
    header: "QUALITY",
  },
  DashboardNavigation,
  ClientsNavigation,
  ...ClaimNavigation,
  LeadsNavigation,
  // SalesMadeNavigation,
  RequestNcrNav,
  FileManagerNavigation,
  LoansNavigation,
  HelpDeskNavigation,
  ProductRequestNavigation,
  EmployeeClaimsNavigation,
  SettingsNavigation,
  ...InquiriesNavigation,
  //QualityProjectsNavigation,
  RingCentralNavigation,
  ...CourtInfoNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  ...RequestClientStatusNavigation,
  ...RiskyClientsNavigation,
  ...CallsReviewNavigation,
  ...NotesWCNavigation,
  ...CallImpressionNavigation,
];
export default navigation;
