export default {
  namespaced: true,
  state: {
    S_MONTH_SELECTED: '',
    S_YEAR_SELECTED: '',
  },
  mutations: {
    SET_MONTH_SELECTED(state, params) {
      state.S_MONTH_SELECTED = params;
    },
    SET_YEAR_SELECTED(state, params) {
      state.S_YEAR_SELECTED = params;
    },
  },
  actions: {
    A_SET_MONTH_SELECTED({ commit }, data) {
      commit('SET_MONTH_SELECTED', data);
    },
    A_SET_YEAR_SELECTED({ commit }, data) {
      commit('SET_YEAR_SELECTED', data);
    },
  },
  getters: {
    G_MONTH_SELECTED(state) {
      return state.S_MONTH_SELECTED;
    },
    G_YEAR_SELECTED(state) {
      return state.S_YEAR_SELECTED;
    },
    G_TITLE_MODAL(state) {
      const date = new Date();
      date.setMonth(state.S_MONTH_SELECTED - 1);

      // Use toLocaleString with options to get the month name
      const monthName = date.toLocaleString('en-US', { month: 'long' });

      return `${monthName} ${state.S_YEAR_SELECTED}`;
    },
  },
};
