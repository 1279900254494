export default [
    {
      path: "commissions",
      name: "debtsolution-commissions",
      component: () => import(/* webpackChunkName: "DSCommissions" */ "@/views/debt-solution/views/commissions/commissions.vue"),
      meta: {
        program: 4,
        pageTitle: "Commissions",
        breadcrumb: [
          {
            text: "Commissions",
          },
        ],
      },
    },
  ];
  