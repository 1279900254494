import OtherServicesService from '@/views/commons/components/other-services/service/appointment.service'
import store from "@/store";

export default {
    namespaced: true,
    state: {
        S_PENDING_OTHER_SERVICES: 0,
    },
    getters: {
        G_PENDING_OTHER_SERVICES(state) {
            return state.S_PENDING_OTHER_SERVICES;
        }
    },
    mutations: {
        M_PENDING_OTHER_SERVICES(state, payload) {
            state.S_PENDING_OTHER_SERVICES = payload;
        }
    },
    actions: {
        async A_GET_PENDING_OTHER_SERVICES({ commit }) {
            const { modul_id, user_id, role_id } = store.state.auth.currentUser;
            const data = await OtherServicesService.getCountPending({ module_id: modul_id !== 29 ? modul_id : null, user_id, role_id });
            commit("M_PENDING_OTHER_SERVICES", data);
            const routesModules = {
                5: "debt-solution-other-services",
                6: "credit-experts-other-services",
                7: "boost-credit-other-services",
                20: "connection-other-services",
                22: "customerservice-other-services",
                29: "other-services-financial-approval",
            }
            const payload = {
                routeName: routesModules[modul_id],
                tag: data,
            };
            store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
        }
    }
}