import modulesCounters from "@/views/modulesCounters"
import modulesActions from "@/views/modulesActions"

const updateCountersByModule = (moduleId) => {
  const keys = Object.keys(modulesCounters)
  if (!(moduleId === undefined)) {
    if (keys.includes(moduleId.toString())) {
      const callback = modulesCounters[moduleId]
      callback()
    }
  }
}

const dispatchActionsByModule = (moduleId) => {
    const keys = Object.keys(modulesActions)
    if (!(moduleId === undefined)) {
        if (keys.includes(moduleId.toString())) {
        const callback = modulesActions[moduleId]
        callback()
        }
    }
}

export { updateCountersByModule, dispatchActionsByModule }
