import NotificationService from "@/service/notification/index";
import NotificationAutomaticPaymentsService from "@/layouts/components/navbar/service/navbar.service.js";
import store from "@/store";

export default {
  namespaced: true,
  state: {
    S_NOTIFICATIONS: [],
    S_COUNT_LATE_ATTENDANCE_NOTIFICATIONS: null,
    S_LATE_ATTENDANCE_NOTIFICATIONS: [],
    S_ALL_USER_NOTIFICATIONS: [],
    S_LETTER_PUSHER: false,
    S_LETTER_DATA: [],
    S_LETTER_CLIENT_ALERT: {},
    S_PROJECTS: [],
    S_PROJECTS_CEO: [],
    S_PROJECTS_PARAGON_CHIEF: [],
    S_PROJECT_MESSAGE_PENDING: [],
    S_COUNT_MEETINGS: 0,
    S_VIEW_MEETING: false,
    S_COUNT_EMPLOYEES_WITHOUT_SCHEDULE: 1,
    S_COUNT_MEETING_RECRUITMENT: 0,
    S_COUNT_INDICATORS_TICKETS: {},
    S_COUNT_PENDING_CUSTOMER_TICKETS: {},
    S_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD: {},
    S_PENDING_CUSTOMER_TICKETS: [],
    S_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE: {},
    S_GET_MODULES_WITH_TICKETS_IN_PROGRESS: [],
    S_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT: {},
    S_MEETINGS_PENDING_CUSTOMER_TICKETS: [],
    S_IS_RESPONSIBLE_CEO: false,
    S_ACCOUNT_IS_RESPONSIBLE_CEO: false,
    S_TASKS_PENDING_CUSTOMER_TICKETS: [],
    S_USERS_AVAILABILITY_CUSTOMER_TICKETS: [],
    S_NOTIFICATIONS_AUTOMATIC_PAYMENTS: [],
  },
  getters: {
    G_NOTIFICATION_COUNTER(state) {
      if (state.S_NOTIFICATIONS.length > 0) {
        return state.S_NOTIFICATIONS[0].cn;
      }
      return 0;
    },
    G_LETTER_PUSHER(state) {
      return state.S_LETTER_PUSHER;
    },
    G_LETTER_DATA(state) {
      return state.S_LETTER_DATA;
    },
    G_LETTER_CLIENT_ALERT(state) {
      return state.S_LETTER_CLIENT_ALERT;
    },
    G_COUNT_LATE_ATTENDANCE_NOTIFICATIONS(state) {
      return state.S_COUNT_LATE_ATTENDANCE_NOTIFICATIONS;
    },
    G_LATE_ATTENDANCE_NOTIFICATIONS(state) {
      return state.S_LATE_ATTENDANCE_NOTIFICATIONS;
    },
    G_PROJECTS_COUNTER(state) {
      if (state.S_PROJECTS.length > 0) {
        return state.S_PROJECTS[0].cn;
      }
      return 0;
    },
    G_PROJECTS_COUNTER_CEO(state) {
      if (state.S_PROJECTS_CEO.length > 0) {
        return state.S_PROJECTS_CEO[0].cn;
      }
      return 0;
    },
    G_PROJECTS_COUNTER_PARAGON_CHIEF(state) {
      if (state.S_PROJECTS_PARAGON_CHIEF.length > 0) {
        return state.S_PROJECTS_PARAGON_CHIEF[0].cn;
      }
      return 0;
    },
    G_PROJECT_MESSAGE_PENDING(state) {
      return state.S_PROJECT_MESSAGE_PENDING;
    },
    G_COUNT_MEETINGS(state) {
      return state.S_COUNT_MEETINGS;
    },
    G_VIEW_MEETINGS(state) {
      return state.S_VIEW_MEETING;
    },

    G_COUNT_EMPLOYEES_WITHOUT_SCHEDULE(state) {
      return state.S_COUNT_EMPLOYEES_WITHOUT_SCHEDULE;
    },
    G_COUNT_MEETING_RECRUITMENT(state) {
      return state.S_COUNT_MEETING_RECRUITMENT;
    },
    G_COUNT_INDICATORS_TICKETS(state) {
      return state.S_COUNT_INDICATORS_TICKETS;
    },
    G_COUNT_PENDING_CUSTOMER_TICKETS(state) {
      return state.S_COUNT_PENDING_CUSTOMER_TICKETS;
    },
    G_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD(state) {
      return state.S_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD;
    },
    G_PENDING_CUSTOMER_TICKETS(state) {
      return state.S_PENDING_CUSTOMER_TICKETS;
    },
    G_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE(state) {
      return state.S_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE;
    },
    G_GET_MODULES_WITH_TICKETS_IN_PROGRESS(state) {
      return state.S_GET_MODULES_WITH_TICKETS_IN_PROGRESS;
    },
    G_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT(state) {
      return state.S_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT;
    },
    G_MEETINGS_PENDING_CUSTOMER_TICKETS(state) {
      return state.S_MEETINGS_PENDING_CUSTOMER_TICKETS;
    },
    G_IS_RESPONSIBLE_CEO(state) {
      return state.S_IS_RESPONSIBLE_CEO;
    },
    G_ACCOUNT_IS_RESPONSIBLE_CEO(state) {
      return state.S_ACCOUNT_IS_RESPONSIBLE_CEO;
    },
    G_TASKS_PENDING_CUSTOMER_TICKETS(state) {
      return state.S_TASKS_PENDING_CUSTOMER_TICKETS;
    },
    G_USERS_AVAILABILITY_CUSTOMER_TICKETS(state) {
      return state.S_USERS_AVAILABILITY_CUSTOMER_TICKETS;
    },
    G_NOTIFICATION_AUTOMATIC_PAYMENTS_COUNTER(state) {
      if (state.S_NOTIFICATIONS_AUTOMATIC_PAYMENTS.length > 0) {
        return state.S_NOTIFICATIONS_AUTOMATIC_PAYMENTS[0].cn;
      }
      return 0;
    },
  },

  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.S_NOTIFICATIONS = payload;
    },
    SET_ALL_USER_NOTIFICATIONS(state, payload) {
      state.S_ALL_USER_NOTIFICATIONS = payload;
    },
    DECREASE_NOTIFICATION_COUNTER(state) {
      state.S_NOTIFICATIONS[0].cn = Number(state.S_NOTIFICATIONS[0].cn) - 1;
    },
    DECREASE_ALL_NOTIFICATION_COUNTER(state) {
      state.S_NOTIFICATIONS[0].cn =
        Number(state.S_NOTIFICATIONS[0].cn) -
        Number(state.S_NOTIFICATIONS[0].cn);
    },
    INCREASE_LATE_NOTIFICATION_COUNTER(state) {
      state.S_COUNT_LATE_ATTENDANCE_NOTIFICATIONS += 1;
    },
    DECREASE_LATE_NOTIFICATIONS_COUNTER(state) {
      state.S_COUNT_LATE_ATTENDANCE_NOTIFICATIONS -= 1;
    },
    SET_LETTER_PUSHER(state, payload) {
      state.S_LETTER_PUSHER = payload;
    },
    SET_LETTER_DATA(state, payload) {
      state.S_LETTER_DATA = payload;
    },
    SET_LETTER_CLIENT_ALERT(state, payload) {
      state.S_LETTER_CLIENT_ALERT = payload;
    },
    SET_COUNT_LATE_ATTENDANCE_NOTIFICATION(state, payload) {
      state.S_COUNT_LATE_ATTENDANCE_NOTIFICATIONS = payload;
    },
    SET_LATE_ATTENDANCE_NOTIFICATION(state, payload) {
      state.S_LATE_ATTENDANCE_NOTIFICATIONS = payload;
    },
    SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE(state, payload) {
      state.S_COUNT_EMPLOYEES_WITHOUT_SCHEDULE = payload;
    },
    SET_COUNT_ATTENDANCE_TO_ZERO(state) {
      state.S_COUNT_LATE_ATTENDANCE_NOTIFICATIONS = 0;
    },

    SET_PROJECTS(state, payload) {
      state.S_PROJECTS = payload;
    },
    SET_PROJECTS_CEO(state, payload) {
      state.S_PROJECTS_CEO = payload;
    },
    SET_PROJECTS_PARAGON_CHIEF(state, payload) {
      state.S_PROJECTS_PARAGON_CHIEF = payload;
    },
    SET_PROJECT_MESSAGE_PENDING(state, payload) {
      state.S_PROJECT_MESSAGE_PENDING = payload;
    },
    SET_COUNT_MEETINGS(state, payload) {
      state.S_COUNT_MEETINGS = payload;
    },
    SET_VIEW_MEETINGS(state, payload) {
      state.S_VIEW_MEETING = payload;
    },
    SET_COUNT_MEETING_RECRUITMENT(state, payload) {
      state.S_COUNT_MEETING_RECRUITMENT = payload;
    },
    SET_COUNT_INDICATORS_TICKETS(state, payload) {
      state.S_COUNT_INDICATORS_TICKETS = payload;
    },
    SET_COUNT_PENDING_CUSTOMER_TICKETS(state, payload) {
      state.S_COUNT_PENDING_CUSTOMER_TICKETS = payload;
    },
    SET_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD(state, payload) {
      state.S_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD = payload;
    },
    SET_PENDING_CUSTOMER_TICKETS(state, payload) {
      state.S_PENDING_CUSTOMER_TICKETS = payload;
    },
    SET_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE(state, payload) {
      state.S_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE = payload;
    },
    SET_GET_MODULES_WITH_TICKETS_IN_PROGRESS(state, payload) {
      state.S_GET_MODULES_WITH_TICKETS_IN_PROGRESS = payload;
    },
    SET_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT(state, payload) {
      state.S_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT = payload;
    },
    SET_MEETINGS_PENDING_CUSTOMER_TICKETS(state, payload) {
      state.S_MEETINGS_PENDING_CUSTOMER_TICKETS = payload;
    },
    SET_IS_RESPONSIBLE_CEO(state, payload) {
      state.S_IS_RESPONSIBLE_CEO = payload;
    },
    SET_ACCOUNT_IS_RESPONSIBLE_CEO(state, payload) {
      state.S_ACCOUNT_IS_RESPONSIBLE_CEO = payload;
    },
    SET_TASKS_PENDING_CUSTOMER_TICKETS(state, payload) {
      state.S_TASKS_PENDING_CUSTOMER_TICKETS = payload;
    },
    SET_USERS_AVAILABILITY_CUSTOMER_TICKETS(state, payload) {
      state.S_USERS_AVAILABILITY_CUSTOMER_TICKETS = payload;
    },
    SET_NOTIFICATIONS_AUTOMATIC_PAYMENTS(state, payload) {
      state.S_NOTIFICATIONS_AUTOMATIC_PAYMENTS = payload;
    },
  },

  actions: {
    async A_SET_COUNT_LATE_ATTENDANCE_NOTIFICATIONS_TO_ZERO({ commit }) {
      commit("SET_COUNT_ATTENDANCE_TO_ZERO");
    },
    async A_SET_COUNT_LATE_ATTENDANCE_NOTIFICATIONS({ commit }, body) {
      try {
        const response =
          await NotificationService.getCountLateAttendanceNotifications(body);
        commit("SET_COUNT_LATE_ATTENDANCE_NOTIFICATION", response.data);
        return response;
      } catch (err) {
        console.error(
          "Error in A_SET_COUNT_LATE_ATTENDANCE_NOTIFICATIONS",
          err
        );
      }
      return [];
    },

    async A_SET_LATE_ATTENDANCE_NOTIFICATIONS({ commit }, body) {
      try {
        let response;
        if (
          localStorage.getItem("lateAttendanceNotificationsUpdated") ===
          "false" ||
          localStorage.getItem("lateAttendanceNotificationsUpdated") === null ||
          typeof localStorage.getItem("lateAttendanceNotificationsUpdated") ===
          "undefined" ||
          localStorage.getItem("userId") !== body.user_id.toString()
        ) {
          response = await NotificationService.getLateAttendanceNotifications(
            body
          );
          commit("SET_LATE_ATTENDANCE_NOTIFICATION", response.data.data);
          localStorage.setItem(
            "lateAttendanceNotifications",
            JSON.stringify(response.data)
          );
          localStorage.setItem("lateAttendanceNotificationsUpdated", "true");
          localStorage.setItem("userId", body.user_id);
          return response.data;
        }

        commit(
          "SET_LATE_ATTENDANCE_NOTIFICATION",
          JSON.parse(localStorage.getItem("lateAttendanceNotifications")).data
        );
        return JSON.parse(localStorage.getItem("lateAttendanceNotifications"));
      } catch (err) {
        console.error("Error in A_SET_LATE_ATTENDANCE_NOTIFICATIONS", err);
      }

      return [];
    },

    async A_SET_NOTIFICATIONS_COUNTER({ commit }) {
      commit("INCREASE_LATE_NOTIFICATION_COUNTER");
    },
    async A_GET_NOTIFICATIONS({ commit }, body) {
      try {
        const response = await NotificationService.getUserNotifications(body);
        commit("SET_NOTIFICATIONS", response.data);
        return response;
      } catch (error) {
        throw error;
      }
    },
    async A_GET_ALL_USER_NOTIFICATIONS({ commit }, body) {
      try {
        const response = await NotificationService.getAllUserNotifications(
          body
        );
        commit("SET_ALL_USER_NOTIFICATIONS", response.data);
        return response;
      } catch (error) {
        throw error;
      }
    },
    async A_UPDATE_NOTIFICATION({ commit }, body) {
      try {
        const response = await NotificationService.updateNotification(body);
        return response;
      } catch (error) {
        throw error;
      }
    },
    async A_UPDATE_ALL_NOTIFICATIONS({ commit }, body) {
      try {
        const response = await NotificationService.updateAllNotifications(body);
        localStorage.setItem("lateAttendanceNotificationsUpdated", "false");
        await store.dispatch(
          "NotificationStore/A_SET_COUNT_LATE_ATTENDANCE_NOTIFICATIONS_TO_ZERO"
        );
        await store.dispatch(
          "NotificationStore/A_SET_LATE_ATTENDANCE_NOTIFICATIONS",
          body
        );
        localStorage.setItem("lateAttendanceNotificationsUpdated", "true");
        return response;
      } catch (error) {
        throw error;
      }
    },
    A_UPDATE_LETTER_PUSHER({ commit }, payload) {
      commit("SET_LETTER_PUSHER", payload);
    },
    A_UPDATE_LETTER_DATA({ commit }, payload) {
      commit("SET_LETTER_DATA", payload);
    },
    A_LETTER_CLIENT_ALERT({ commit }, payload) {
      commit("SET_LETTER_CLIENT_ALERT", payload);
    },
    async A_GET_PROJECTS({ commit }, body) {
      try {
        const response =
          await NotificationService.getProjectsPendingNotification(body);
        commit("SET_PROJECTS", response.data);
        return response;
      } catch (error) {
        throw error;
      }
    },

    async A_GET_PROJECTS_CEO({ commit }, body) {
      try {
        const response =
          await NotificationService.getProjectsPendingNotificationCeo(body);
        commit("SET_PROJECTS_CEO", response.data);
        return response;
      } catch (error) {
        throw error;
      }
    },

    async A_GET_PROJECTS_PARAGON_CHIEF({ commit }, body) {
      try {
        const response =
          await NotificationService.getProjectsPendingNotificationParagonChief(
            body
          );
        commit("SET_PROJECTS_PARAGON_CHIEF", response.data);
        return response;
      } catch (error) {
        throw error;
      }
    },
    async A_GET_PROJECT_MESSAGE_PENDING({ commit }, body) {
      try {
        const response =
          await NotificationService.getProjectsPendingNotificationParagonChief(
            body
          );
        commit("SET_PROJECT_MESSAGE_PENDING", response.data);
        return response;
      } catch (error) {
        throw error;
      }
    },

    async A_COUNT_MEETINGS({ commit }, body) {
      try {
        const response = await NotificationService.getCountMeetings(body);
        commit("SET_COUNT_MEETINGS", response.data.data);
      } catch (error) {
        throw error;
      }
    },
    async A_SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE({ commit }, body) {
      try {
        const response =
          await NotificationService.getAllEmployeesWithoutSchedule(body);
        commit("SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE", response.data);
        return response.data;
      } catch (err) {
        console.error("Error in A_SET_COUNT_EMPLOYEES_WITHOUT_SCHEDULE", err);
      }
      return [];
    },

    async A_COUNT_MEETINGS_RECRUITMENT({ commit }, body) {
      try {
        const { data } = await NotificationService.getCountMeetingsRecruitment(
          body
        );
        commit("SET_COUNT_MEETING_RECRUITMENT", data.data);
        return data.data;
      } catch (err) {
        console.error("Error in A_COUNT_MEETINGS_RECRUITMENT", err);
      }
      return [];
    },
    async A_COUNT_INDICATORS_TICKETS({ commit }, body) {
      commit("SET_COUNT_INDICATORS_TICKETS", {});
      try {
        const { data } = await NotificationService.getCountIndicatorsTickets(
          body
        );
        commit("SET_COUNT_INDICATORS_TICKETS", data[0]);
      } catch (err) {
        console.error("Error in A_COUNT_INDICATORS_TICKETS", err);
      }
      return [];
    },
    async A_COUNT_PENDING_CUSTOMER_TICKETS({ commit }, body) {
      try {
        commit("SET_COUNT_PENDING_CUSTOMER_TICKETS", {});
        const data = await NotificationService.getCountPendingCustomerTickets(
          body
        );
        if (!data) return;
        commit("SET_COUNT_PENDING_CUSTOMER_TICKETS", data.data[0]);
      } catch (err) {
        console.error("Error in A_COUNT_PENDING_CUSTOMER_TICKETS", err);
      }
      return [];
    },
    async A_PENDING_CUSTOMER_TICKETS({ commit }) {
      try {
        const currentUser = store.getters["auth/currentUser"].user_id;
        const data = await NotificationService.getPendingCustomerTickets({
          userId: currentUser,
        });
        commit("SET_PENDING_CUSTOMER_TICKETS", data.data);
      } catch (err) {
        console.error("Error in A_PENDING_CUSTOMER_TICKETS", err);
      }
    },
    async A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD({ commit }, body) {
      try {
        const { data } =
          await NotificationService.getCountPendingCustomerTickets(body);
        commit("SET_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD", data[0]);
      } catch (err) {
        console.error(
          "Error in A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
          err
        );
      }
      return [];
    },
    async A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE({ commit }, body) {
      try {
        const { data } =
          await NotificationService.getCountPendingCustomerTicketsByModule(
            body
          );
        commit("SET_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE", data);
      } catch (err) {
        console.error(
          "Error in A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE",
          err
        );
      }
      return [];
    },
    async A_GET_MODULES_WITH_TICKETS_IN_PROGRESS({ commit }, body) {
      try {
        const { data } =
          await NotificationService.getModulesWithTicketsInProgress(body);
        commit("SET_GET_MODULES_WITH_TICKETS_IN_PROGRESS", data);
      } catch (err) {
        console.error("Error in A_GET_MODULES_WITH_TICKETS_IN_PROGRESS", err);
      }
      return [];
    },
    async A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT({ commit }, body) {
      try {
        const { data } =
          await NotificationService.getCountPendingCustomerTicketsByInOut(body);
        commit("SET_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT", data[0]);
      } catch (err) {
        console.error(
          "Error in A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT",
          err
        );
      }
      return [];
    },
    async A_MEETINGS_PENDING_CUSTOMER_TICKETS({ commit }, body) {
      try {
        const { data } =
          await NotificationService.getMeetingsPendingCustomerTickets(body);
        commit("SET_MEETINGS_PENDING_CUSTOMER_TICKETS", data[0]);
      } catch (err) {
        console.error("Error in A_MEETINGS_PENDING_CUSTOMER_TICKETS", err);
      }
    },
    async A_TASKS_PENDING_CUSTOMER_TICKETS({ commit }, body) {
      try {
        const { data } =
          await NotificationService.countTicketCustomerTaskCalendar(body);
        commit("SET_TASKS_PENDING_CUSTOMER_TICKETS", data);
      } catch (err) {
        console.error("Error in A_TASKS_PENDING_CUSTOMER_TICKETS", err);
      }
    },
    async A_USERS_AVAILABILITY_CUSTOMER_TICKETS({ commit }) {
      try {
        const { data } =
          await NotificationService.getUsersAvailibilityCustomerTickets();
        commit("SET_USERS_AVAILABILITY_CUSTOMER_TICKETS", data);
      } catch (err) {
        console.error("Error in A_USERS_AVAILABILITY_CUSTOMER_TICKETS", err);
      }
    },
    async A_IS_RESPONSIBLE_CEO({ commit }, body) {
      try {
        const { data } = await NotificationService.isResponsibleCeo(body);
        commit("SET_IS_RESPONSIBLE_CEO", data[0]);
      } catch (err) {
        console.error("Error in A_IS_RESPONSIBLE_CEO", err);
      }
    },
    async A_ACCOUNT_IS_RESPONSIBLE_CEO({ commit }, body) {
      try {
        const { data } = await NotificationService.isAccountResponsibleCeo(
          body
        );
        commit("SET_ACCOUNT_IS_RESPONSIBLE_CEO", data[0]);
      } catch (err) {
        console.error("Error in A_ACCOUNT_IS_RESPONSIBLE_CEO", err);
      }
    },
    async A_GET_NOTIFICATIONS_AUTOMATIC_PAYMENTS({ commit }, body) {
      try {
        const response =
          await NotificationAutomaticPaymentsService.getAutomaticPaymentsNotifications(
            body
          );
        commit("SET_NOTIFICATIONS_AUTOMATIC_PAYMENTS", response.data);
        return response;
      } catch (error) {
        throw error;
      }
    },
  },
};
