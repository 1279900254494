export default [
  { // 0
    key: 'name_client',
    label: 'Client',
    visible: true,
  },
  { // 1
    key: 'accounts',
    label: 'Accounts',
    visible: true,
  },
  { // 2
    key: 'created_at',
    label: 'Registered At',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
  { // 3
    key: 'tickets',
    label: 'Tickets',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
  { // 4
    key: 'client_ceo',
    label: 'my client',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
  { // 4
    key: 'actions',
    label: 'Actions',
    thClass: 'text-center',
    tdClass: 'text-center',
    visible: true,
  },
];
