var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":"LEAD","label-cols-md":"2"}},[_c('b-form-input',{attrs:{"readonly":""},model:{value:(_vm.lead.lead_name),callback:function ($$v) {_vm.$set(_vm.lead, "lead_name", $$v)},expression:"lead.lead_name"}})],1),_c('b-table',{attrs:{"show-empty":"","sticky-header":"","responsive":"","fields":[
      { key: 'status' },
      {
        key: 'plataform',
        label: 'provider',
        thClass: 'text-center',
        tdClass: 'text-center',
      },
      { key: 'created_by' },
      { key: 'note' } ],"items":_vm.S_TRAKING_STATUS_LEADS,"busy":_vm.isBusy},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(plataform)",fn:function(data){return [(data.item.plataform_ico)?_c('img',{attrs:{"src":_vm.baseUrl + data.item.plataform_ico,"title":data.item.plataform_name}}):_vm._e()]}},{key:"cell(created_by)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.created_name)+" "),(data.item.created_at != null)?_c('div',[_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(data.item.created_at))+" ")]):_vm._e()]}}])}),_c('paginate-table',{attrs:{"currentPage":_vm.paginate.currentPage,"total-rows":_vm.paginate.totalRows,"per-page":_vm.paginate.perPage,"from-page":_vm.paginate.fromPage,"to-page":_vm.paginate.toPage},on:{"onChangeCurrentPage":_vm.onChangeCurrentPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }