export default [
  {
    path: "calendar",
    name: "calendar-connection",
    component: () =>
      import(
        /* webpackChunkName: "ConnectionCalendar" */
        "@/views/ce-digital/sub-modules/connection/views/calendar/default.vue"
      ),
    redirect: { name: "connection-calendar-appointment" },
    children: [
      {
        path: "",
        redirect: { name: "connection-calendar-appointment" },
      },
      {
        path: "appointment",
        component: () =>
          import(
            /* webpackChunkName: "ConnectionCalendarAppointment" */
            "@/views/crm/views/calendar/components/appointment/default.vue"
          ),
        name: "connection-calendar-appointment",
        meta: {
          module: 20,
          moduleId: 20,
          permittedRoles: [1, 2, 14, 15, 17],
          pageTitle: "Calendar",
          breadcrumb: [
            {
              text: "Appointments",
              active: true,
            },
          ],
        },
      },
      {
        path: "task",
        component: () =>
          import(
            /* webpackChunkName: "ConnectionCalendarTask" */
            "@/views/crm/views/calendar/components/task/default.vue"
          ),
        name: "connection-calendar-task",
        meta: {
          module: 20,
          moduleId: 20,
          permittedRoles: [1, 2, 14, 15, 17],
          pageTitle: "Calendar",
          breadcrumb: [
            {
              text: "Tasks",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      module: 20,
      moduleId: 20,
      permittedRoles: [1, 2, 14, 15, 17],
      pageTitle: "Calendar",
      breadcrumb: [
        {
          text: "Calendar",
        },
      ],
    },
  },
];
