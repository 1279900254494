import store from "@/store"
import Vue from "vue"
import router from "@/router/index"

const alertManagementAboutCommissionChange = () => {
  window.socket.bind(
    "alert-management-about-commission-change",
    async (data) => {
      let temps = false
      const sessionId = store.state.auth.currentUser.user_id
      data.to_id.forEach((element) => {
        if (element === sessionId) {
          temps = true
        }
      })
      if (temps) {
        const htmlAlert =
          `${
            '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;" alt="alert-info-image">' +
            '<div><h2 style="font-weight: 600;">NEW CHANGE IN COMMISSION</h2></div>' +
            '<div style="text-align: center;">' +
            '<div style="font-weight: bolder;">Change Made By: '
          }${data.user_name} </div>` +
          " </div>" +
          '<div style="font-weight: bolder; margin-bottom: 0.5rem; margin-top: 0.5rem;">Type Commission: ' +
          data.type_commission_description
            .toLowerCase()
            .replace(/\b\w/g, (l) => l.toUpperCase()) +
          " </div>" +
          "<div>Old Value: " +
          (data.type_data_commission === 1 ? "$ " : "") +
          data.old_value +
          (data.type_data_commission === 2 ? " %" : "") +
          "</div>" +
          "<div>New Value: " +
          (data.type_data_commission === 1 ? "$ " : "") +
          data.new_value +
          (data.type_data_commission === 2 ? " %" : "") +
          "</div>" +
          "</div>"
        const res = await Vue.swal.fire({
          html: htmlAlert,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: "Close",
        })
        if (res.value) {
          if (
            router.currentRoute.matched[0].name !== "commissions-management"
          ) {
            window.open("/management/commissions", "_blank")
          }
          await window.amgApi.post("/commons/close-all-swal", data)
        }
      }
    }
  )
}
export default alertManagementAboutCommissionChange
