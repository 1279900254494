import FileManangerNavigation from "@/views/crm/views/file-mananger/file-mananger.nav";
import HelpdeskNavigation from "@/views/crm/views/helpdesk/helpdesk.nav";
import ReportsNavigation from "@/views/crm/views/reports/reports.navigation";
import ProductRequestNavigation from "@/views/crm/views/request/request.nav.js";
import calendarNav from "../views/calendar/calendar.nav";
import SalesMadeNavigation from "../views/sales-made/sales-made.nav";
import CommissionsNavigation from "../views/commissions/commissions.nav";
import DashboardNavigation from "../views/dashboard/dashboard.nav";
import PaymentsNavigation from "../views/payments/payments.nav";
import ClientsNavigation from "@/views/crm/views/clients/clients-group.navigation";
import leadNav from "../views/Lead/lead.nav";
import ListsNavigation from "../views/lists/lists.nav";
import LoansNavigation from "../views/loans/loans.nav";
import RequestNcrNav from "../views/request-ncr/request-ncr.nav";
import SettingsNavigation from "../views/settings/navigation/settings.navigation";
import ManageCommissionsNavigation from "@/views/crm/views/manage-commissions/manage-commissions.navigation";
import OtherPaymentsNavigation from "@/views/crm/views/other-payments/other-payments.nav";
import EmployeeClaimsNavigation from "@/views/crm/views/employee-claims/navigation/employee-claims.nav";
import RingCentralNav from "@/views/crm/views/ring-central/ring-central.nav.js";
import CourtInfoNavigation from "@/views/crm/views/cour-info/navigation/ds-court-info.nav";
import MOFNavigation from "@/views/crm/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/crm/views/recruitment-process/crm-recruitment_process.navigation";
import SmsInbox from "@/views/crm/views/ring-central/sms-inbox/chat-sms.nav.js";
import CallImpressionNavigation from "@/views/crm/views/call-impression/navigation/call-impression.navigation.js";

const navigation = [
  {
    header: "C.R.M.",
  },
  DashboardNavigation,
  ...leadNav,
  ClientsNavigation,
  ...calendarNav,
  ...SalesMadeNavigation,
  RequestNcrNav,
  // ...CommissionsNavigation,
  ...PaymentsNavigation,
  FileManangerNavigation,
  ...ListsNavigation,
  LoansNavigation,
  HelpdeskNavigation,
  ...SettingsNavigation,
  ReportsNavigation,
  ProductRequestNavigation,
  ManageCommissionsNavigation,
  OtherPaymentsNavigation,
  EmployeeClaimsNavigation,
  RingCentralNav,
  ...CourtInfoNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  ...CallImpressionNavigation,
];
export default navigation;
