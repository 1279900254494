var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"step-progress__wrapper"},[_c('div',{staticClass:"step-progress__wrapper-before",style:({
        'background-color': _vm.passiveColor,
        height: _vm.lineThickness + 'px',
      })}),_c('div',{staticClass:"step-progress__bar"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,staticClass:"step-progress__step",class:{
          'step-progress__step--active': index === _vm.currentStep - 1,
          'step-progress__step--valid': index < _vm.currentStep - 1,
        },style:({
          '--activeColor': _vm.activeColor,
          '--passiveColor': _vm.passiveColor,
          '--activeBorder': _vm.activeThickness + 'px',
          '--passiveBorder': _vm.passiveThickness + 'px',
        })},[_c('span',[_vm._v(_vm._s(index + 1))]),_c('amg-icon',{staticClass:"step-progress__step-icon",attrs:{"size":"38","icon":_vm.icons[index]}}),_c('div',{staticClass:"step-progress__step-label"},[_vm._v(" "+_vm._s(step)+" ")])],1)}),0),_c('div',{staticClass:"step-progress__wrapper-after",style:({
        transform:
          'scaleX(' + _vm.scaleX + ') translateY(-50%) perspective(1000px)',
        'background-color': _vm.activeColor,
        height: _vm.lineThickness + 'px',
      })})]),(!_vm.viewOnly)?_c('div',{staticClass:"mt-5"},[_vm._t("default",null,{"step":_vm.currentStep}),(_vm.finalStep)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticClass:"img-fluid",staticStyle:{"height":"40vh"},attrs:{"src":require("@/assets/images/icons/completed.svg"),"alt":"scope"}})]):_vm._e()],2):_vm._e(),(!_vm.viewOnly)?_c('div',{staticClass:"buttons"},[(_vm.canGoBack)?_c('b-button',{attrs:{"variant":"custom-amg","disabled":!_vm.canGoBack,"size":"md"},on:{"click":function($event){return _vm.prev(_vm.currentStep)}}},[_vm._v(" "+_vm._s(_vm.prevButtonLabel))]):_vm._e(),(_vm.canProceed)?_c('b-button',{attrs:{"variant":"custom-amg","size":"md","disabled":!_vm.canProceed},on:{"click":function($event){return _vm.next(_vm.currentStep)}}},[_vm._v(" "+_vm._s(_vm.nextButtonLabel))]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }