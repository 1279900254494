import Vue from "vue";

Vue.filter("numberPrecision2", (number) => {
  if (number) return (Math.round(number * 100) / 100).toFixed(2).toString();
  return "0.00";
});

Vue.filter("currency", (number) => {
  function formatPrice(value) {
    const val = (value / 1).toFixed(2).replace(",", ".");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (number) {
    return formatPrice(number);
  } else {
    return "-";
  }
});
Vue.filter("currencyZero", (number) => {
  function formatPrice(value) {
    const val = (value / 1).toFixed(2).replace(",", ".");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (number) {
    return formatPrice(number);
  } else {
    return "0.00";
  }
});

Vue.filter("currencyTwo", (number) => {
  function formatPrice(value) {
    const val = (value / 1).toFixed(2).replace(",", ".");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (number) {
    return formatPrice(number);
  } else {
    return "0.00";
  }
});

Vue.filter("numberFormat", function (number, decimals = 0) {
  if (number < 100) {
    return number;
  } else if (number < 1000) {
    return "+99";
  } else if (number < 10000) {
    return "+" + (number / 1000).toFixed(decimals) + "k";
  } else if (number < 100000) {
    return "+" + (number / 1000).toFixed(decimals) + "k";
  } else {
    return "+" + (number / 1000).toFixed(decimals) + "k";
  }
});

Vue.filter("formatNumber", function (value) {
  if (typeof value !== "number") {
    return value;
  }

  const formatter = new Intl.NumberFormat("en-US");
  return formatter.format(value);
});

Vue.filter("phoneNumberMask", function (value) {
  if (value.length == 0 || value == null || value == "") {
    return "-";
  }
  const phoneNumber = value.replace("+1", "");
  return `(${phoneNumber.substring(
    0,
    3
  )}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`;
});

Vue.filter("SsnMask", function (value) {
  if (value.length == 0 || value == null || value == "" || value.length < 9) {
    return "-";
  }
  const first = value.substring(0, 3);
  const second = value.substring(3, 5);
  const third = value.substring(5);
  return `${first}-${second}-${third}`;
});

Vue.filter("formatCurrency", function (value) {
  if (typeof value === "string") {
    value = value.replace(/,/g, "");
  }

  value = parseFloat(value);

  if (!value || value === 0 || isNaN(value)) {
    return "$0.00";
  }
  return formatCurrency("USD", value);
});

Vue.filter("formatCurrencyPEN", function (value) {
  if (typeof value === "string") {
    value = value.replace(/,/g, "");
  }

  value = parseFloat(value);

  if (!value || value === 0 || isNaN(value)) {
    return "S/0.00";
  }
  return "S/." + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
});

const formatCurrency = (type, value) => {
  return value.toLocaleString(
    "en-US",
    {
      style: "currency",
      currency: type,
    },
    { minimumFractionDigits: 2 },
    { maximumFractionDigits: 2 }
  );
};
