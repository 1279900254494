import CeDigitalNotes from "@/views/ce-digital/sub-modules/settings/views/notes/store/notes.store";
import CeDigitalMigrations from "@/views/ce-digital/sub-modules/settings/views/migrations/store/index";
import CeDigitalWelcomeActions from "@/views/ce-digital/sub-modules/customer-service/views/clients/welcome-actions/store/welcome-actions.store";
import DashboardCustomerServiceStore from "@/views/ce-digital/sub-modules/customer-service/views/clients/store/dashboard-customer-service.store";
import DbAgentStore from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-agent/store/index";
import AlertCustomerServiceStore from "@/views/ce-digital/sub-modules/customer-service/views/alerts/store";
import CeDigitalSalesLeads from "@/views/ce-digital/sub-modules/sales/views/leads/store/leads.store";
import ceDigitalSalesDerivations from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.store";
import connectionStore from "@/views/ce-digital/sub-modules/connection/views/clients/clients.store";
import AutoAlertStore from "@/views/ce-digital/sub-modules/customer-service/store/alerts/auto-alert.store";
// import AnalysisCrStore from '@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/analysis/store/analysis.store';
import FinancialStore from "@/views/ce-digital/sub-modules/financial/store/index.js";

export default {
  CeDigitalNotes,
  CeDigitalWelcomeActions,
  DashboardCustomerServiceStore,
  CeDigitalCsAlerts: AlertCustomerServiceStore,
  CeDigitalMigrations: CeDigitalMigrations,
  DbAgentStore,
  CeDigitalSalesLeads: CeDigitalSalesLeads,
  ceDigitalSalesDerivations: ceDigitalSalesDerivations,
  connectionStore,
  AutoAlertStore,
  FinancialStore,
  // CeAnalysisCrStore: AnalysisCrStore
};
