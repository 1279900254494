<template>
  <b-col>
    <b-form-group :label="title" label-for="select-1">
      <b-form-input :value="text" disabled />
      <slot name="append" />
    </b-form-group>
  </b-col>
</template>
<script>
export default {
  name: "itemValue",
  props: {
    text: {
      type: String,
      required: false,
      default: () => "",
    },
    capitalize: {
      type: Boolean,
      default: () => true,
    },
    title: {
      type: String,
      required: true,
      default: () => "",
    },
  },
  filters: {
    convertCapitalize: function (value, capitalize) {
      if (!value) return "";
      if (!capitalize) return value;
      let words = value.toLowerCase().split(" ");

      // Iterar sobre cada palabra y convertir la primera letra a mayúscula
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
      }

      // Unir las palabras en una sola cadena nuevamente
      let capitalizedStr = words.join(" ");

      return capitalizedStr;
    },
  },
};
</script>
<style scoped>
.py-1s {
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>