import Vue from "vue"
import store from "@/store"

const closedAllSwal = () => {
  window.socket.bind("closed-all-swal", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id
    if (Array.isArray(data.to_id)) {
      data.to_id.forEach((element) => {
        if (element === sessionId) {
          Vue.swal.close()
          Vue.prototype.$amgAlert.closeAll()
          Vue.prototype.$timeLine.closeAll()
          Vue.prototype.$timeLineCreative.closeAll()
        }
      })
    } else if (data.to_id == sessionId) {
      Vue.swal.close()
      Vue.prototype.$amgAlert.closeAll()
      Vue.prototype.$timeLine.closeAll()
      Vue.prototype.$timeLineCreative.closeAll()
    }
  })
}
export default closedAllSwal
