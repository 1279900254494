<template>
  <b-modal
    v-model="onControl"
    title="MAINTENANCE QUICK NOTES"
    title-tag="h3"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    no-close-on-backdrop
    hide-footer
    @hidden="close"
  >
    <div class="d-flex justify-content-between">
      <div />
      <b-button
        variant="success"
        @click="openCreateQuickNoteMan({}, 1)"
        v-if="!fromCreditExpertsDigital"
      >
        <feather-icon icon="PlusIcon" />CREATE
      </b-button>
    </div>
    <b-table
      no-border-collapse
      class="mt-1 position-relative"
      :fields="fields"
      show-empty
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="items"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(body)="data">
        <div style="word-wrap: break-word; width: 150px !important">
          <span v-html="data.item.body" />
        </div>
      </template>
      <template #cell(source)="data">
        <b-badge v-if="data.item.source === 1" pill variant="light-primary"
          >By Chief</b-badge
        >
      </template>
      <template #cell(created_at)="data">
        {{ data.item.created_at | myGlobal }}
      </template>
      <template #cell(actions)="data">
        <feather-icon
          v-if="data.item.source === 0"
          class="cursor-pointer text-warning"
          icon="EditIcon"
          size="15"
          @click="openCreateQuickNoteMan(data.item, 2)"
        />
        <feather-icon
          v-if="data.item.source === 0"
          class="cursor-pointer text-danger"
          icon="TrashIcon"
          style="margin-left: 0.5rem"
          size="15"
          @click="deleteQuickNote(data.item.id)"
        />
        <feather-icon
          v-if="data.item.source === 1"
          class="cursor-pointer text-info"
          icon="EyeIcon"
          style="margin-left: 0.5rem"
          size="15"
          @click="showQuickNote(data.item, 3)"
        />
      </template>
    </b-table>
    <add-quick-note-modal
      v-if="openCreateQuickNoteCont"
      :editing="isEditingMood"
      :data-quick="quickToSend"
      @close="closeCreateQuickNoteMan"
      @reload="getQuickNotes"
    />
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import AddQuickNoteModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/AddQuickNoteModal.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    AddQuickNoteModal,
  },
  props: {
    itemsData: null,
    fromCreditExpertsDigital: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onControl: false,
      fields: [
        {
          key: "title",
          label: "Title",
          tdClass: "wrap",
          thStyle: {
            width: "50px",
          },
        },
        {
          key: "body",
          label: "Body",
          tdClass: "wrap",
        },
        {
          key: "created_at",
          label: "Date Creation",
        },
        {
          key: "source",
          label: "Source",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      items: [],
      isBusy: false,
      quickToSend: {},
      openCreateQuickNoteCont: false,
      isEditingMood: 1, // 1 create, 2 edit, 3 show
    };
  },
  async created() {
    this.onControl = true;
    await this.getQuickNotes();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    close() {
      this.$emit("close");
      this.$emit("reload");
    },
    openCreateQuickNoteMan(item, mood) {
      this.quickToSend = item;
      this.openCreateQuickNoteCont = true;
      this.isEditingMood = mood;
    },
    showQuickNote(item, mood) {
      this.quickToSend = item;
      this.openCreateQuickNoteCont = true;
      this.isEditingMood = mood;
    },
    closeCreateQuickNoteMan() {
      this.quickToSend = {};
      this.openCreateQuickNoteCont = false;
      this.isEditingMood = false;
    },
    async getQuickNotes() {
      try {
        this.isBusy = true;
        const data = await ClientsOptionsServices.getQuickNotes({
          p_user_id: this.currentUser.user_id,
          p_module_id: this.moduleId,
        });
        if (data.status === 200) {
          this.items = data.data;
          this.isBusy = false;
        }
      } catch (e) {
        this.isBusy = false;
        this.showErrorSwal(e);
      }
    },
    async deleteQuickNote(id) {
      try {
        const result = await this.showConfirmSwal();
        if (result.isConfirmed) {
          const data = await ClientsOptionsServices.deleteQuickNote({
            p_user_id: id,
          });
          if (data.status === 200) {
            this.showSuccessSwal();
            this.$emit("reload");
            await this.getQuickNotes();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped>
.wrap {
  word-wrap: break-word !important;
  overflow-wrap: break-word;
}
</style>
