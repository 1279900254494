<template>
  <validation-observer ref="form">
    <b-modal
      ref="myModal"
      v-model="ownControl"
      modal-class="modal-primary"
      modal
      size="xlg"
      scrollable
      header-class="p-0"
      header-bg-variant="transparent border-bottom border-bottom-2"
      @hidden="hideModal(false, 0)"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <modal-service-header
          :type-modal="typeModal"
          :users-services="usersServices"
          :programs-all="programsAll"
          :header-s="headerS"
          :sales="salesClient"
          @changeProgram="changeProgram"
          @close="hideModal(false, 0)"
        />
      </template>
      <!-- HEADER END -->
      <b-card
        header="Single payment"
        header-bg-variant="info"
        header-class="font-weight-bolder text-white py-1"
      >
        <b-row class="mt-2">
          <template v-for="(rate, index) in rates">
            <b-col v-if="rate.type === '5'" :key="index" lg="3" md="6" xs="12">
              <b-card
                class="font-weight-bolder"
                :class="{
                  'cursor-pointer hover-card': !isModalShow,
                  'border border-2': true,
                  'text-white': selectedRate.id === rate.id && isLightSkin,
                  'bg-click': selectedRate.id === rate.id,
                }"
                bg-variant="transparent"
                @click="!isModalShow && changeSelectedRate(rate)"
              >
                <p class="text-center m-0">
                  {{ rate.description }}
                </p>
                <p v-if="selectedRate !== rate" class="text-center my-1">
                  $ {{ rate.price|currency }}
                </p>
                <p v-else class="text-center my-1">
                  $ {{ suggestedAmount|currency }}
                </p>
              </b-card>
            </b-col>
          </template>
        </b-row>
        <!-- Others Single Payments -->
        <transition name="fade">
          <div
            v-if="
              ['Silver', 'Gold', 'Platinium'].includes(
                selectedRate.description
              ) && selectedRate.type === '5'
            "
          >
            <b-row class="py-1">
              <b-col
                v-for="(website, index) in websiteTypes"
                :key="index"
                cols="4"
              >
                <b-form-radio
                  v-model="websiteType"
                  :disabled="isModalShow"
                  :value="website"
                >
                  <p class="mb-0">
                    {{ website.description }}
                  </p>
                  <p class="mb-0">$ {{ website.price|currency }}</p>
                </b-form-radio>
              </b-col>
            </b-row>
            <b-row class="py-1">
              <b-col
                v-for="(socialNetwork, index) in socialNetworkTypes"
                :key="index"
                cols="4"
              >
                <b-form-radio
                  v-model="socialNetworkType"
                  :disabled="isModalShow"
                  :value="socialNetwork"
                >
                  <p class="mb-0">
                    {{ socialNetwork.description }}
                  </p>
                  <p class="mb-0">$ {{ socialNetwork.price|currency }}</p>
                </b-form-radio>
              </b-col>
            </b-row>
          </div>
          <div
            v-else-if="
              ['Register'].includes(selectedRate.description) &&
              selectedRate.type === '5'
            "
          >
            <v-select
              v-model="registerStateType"
              :disabled="isModalShow"
              :options="registerStatesTypes"
              label="description"
              :clearable="false"
            >
              <template #option="{ description, price }">
                <p>{{ description }} - $ {{ (price - 100)|currency }}</p>
              </template>
            </v-select>
          </div>
        </transition>
      </b-card>
      <b-card
        header="Monthly payment"
        header-bg-variant="info"
        header-class="font-weight-bolder text-white py-1"
      >
        <b-row class="mt-2">
          <template v-for="(rate, index) in rates">
            <b-col v-if="rate.type === '6'" :key="index" lg="3" md="6" xs="12">
              <b-card
                class="font-weight-bolder"
                :class="{
                  'cursor-pointer hover-card': !isModalShow,
                  'border border-2': true,
                  'text-white': selectedRate.id === rate.id && isLightSkin,
                  'bg-click': selectedRate.id === rate.id,
                }"
                bg-variant="transparent"
                @click="!isModalShow && changeSelectedRate(rate)"
              >
                <p class="text-center m-0">
                  {{ rate.description }}
                </p>
                <p v-if="selectedRate.id !== rate.id" class="text-center my-1">
                  $ {{ rate.price|currency}}
                </p>
                <p v-else class="text-center my-1">
                  $ {{ suggestedAmount|currency }}
                </p>
              </b-card>
            </b-col>
          </template>
        </b-row>
        <!-- Others Single Payments -->
        <transition name="fade">
          <div
            v-if="
              ['Silver', 'Gold', 'Platinium'].includes(
                selectedRate.description
              ) && selectedRate.type === '6'
            "
          >
            <b-row class="py-1">
              <b-col
                v-for="(website, index) in websiteTypes"
                :key="index"
                cols="4"
              >
                <b-form-radio
                  v-model="websiteType"
                  :value="website"
                  :disabled="isModalShow"
                >
                  <p class="mb-0">
                    {{ website.description }}
                  </p>
                  <p
                    v-if="website.description !== 'Standard Website'"
                    class="mb-0"
                  >
                    $ {{ website.price|currency}}
                  </p>
                  <p v-else class="mb-0">
                    $ {{ (website.price + 100)|currency }}
                  </p>
                </b-form-radio>
              </b-col>
            </b-row>
            <b-row class="py-1">
              <b-col
                v-for="(socialNetwork, index) in socialNetworkTypes"
                :key="index"
                cols="4"
              >
                <b-form-radio
                  v-model="socialNetworkType"
                  :value="socialNetwork"
                  :disabled="isModalShow"
                >
                  <p class="mb-0">
                    {{ socialNetwork.description }}
                  </p>
                  <p class="mb-0">$ {{ socialNetwork.price|currency }}</p>
                </b-form-radio>
              </b-col>
            </b-row>
          </div>
          <div
            v-else-if="
              ['Register'].includes(selectedRate.description) &&
              selectedRate.type === '6'
            "
          >
            <v-select
              v-model="registerStateType"
              :options="registerStatesTypes"
              label="description"
              :disabled="isModalShow"
              :clearable="false"
            >
              <template #option="{ description, price }">
                <p>{{ description }} - $ {{ price|currency }}</p>
              </template>
            </v-select>
          </div>
        </transition>
      </b-card>
      <b-card
        header="Digital"
        header-bg-variant="info"
        header-class="font-weight-bolder text-white py-1"
      >
        <b-row class="mt-2">
          <template v-for="(name, index) in packsName">
            <b-col :key="index">
              <b-card
                :header="name"
                header-class="border-bottom py-1"
                :header-bg-variant="isDarkSkin ? 'dark' : 'light'"
                body-class="p-0"
                class="border"
              >
                <div class="d-flex flex-column">
                  <template
                    v-for="(row, index) in ratesBusinessFromParagon.filter(
                      (rate) => rate.type === (index + 1).toString()
                    )"
                  >
                    <div
                      :key="index"
                      class="d-flex w-100 px-1 py-1 cursor-pointer"
                      :class="{
                        'bg-info text-white bg-click font-weight-bolder':
                          selectedRate.id === row.id,
                      }"
                      @click="!isModalShow && changeSelectedRate(row, true)"
                    >
                      <span
                        >{{ row.description }} - ${{
                          row.price|currency
                        }}</span
                      >
                    </div>
                  </template>
                </div>
              </b-card>
            </b-col>
          </template>
        </b-row>
      </b-card>
      <b-card
        header="Others"
        header-bg-variant="info"
        header-class="font-weight-bolder text-white py-1"
      >
        <!-- template header -->
        <template #header>
          <div class="d-flex w-100">
            <span>Others</span>
            <span class="ml-auto">
              <b-form-checkbox v-model="checkOthers" :disabled="isModalShow" />
            </span>
          </div>
        </template>

        <b-row class="mt-2">
          <b-row class="mt-1" v-if="checkOthers">
            <b-col
              cols="1"
              class="
                d-flex
                align-items-center
                justify-content-center
                text-success
                font-medium-5
                ml-2
              "
              >$</b-col
            >
            <b-col>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <money
                  v-model="fee"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: '',
                    precision: 2,
                    masked: false,
                  }"
                  class="form-control text-center"
                  :class="{ 'border-danger': errors[0] && validateMoney }"
                  :disabled="isModalShow"
                />
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-row>
      </b-card>
      <template #modal-footer>
        <!-- total -->
        <b-row class="w-100 d-flex align-items-center justify-content-between">
          <b-col lg="4" md="12">
            <ValidationProvider v-slot="{ errors }" rules="required|money-1">
              <b-col class="px-0">
                <b-row
                  class="w-100 d-flex align-items-center justify-content-end"
                >
                  <b-col
                    cols="4"
                    class="text-right font-weight-bold font-medium-2"
                  >
                    <div>FEE $</div>
                  </b-col>
                  <b-col class="p-0" cols="8">
                    <money
                      v-model="fee"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '',
                        precision: 2,
                      }"
                      class="
                        text-center
                        font-weight-bolder
                        form-control form-control-sm
                      "
                      :style="
                        errors[0] && validateMoney ? 'color:red !important' : ''
                      "
                      :class="{
                        'border border-danger': errors[0] && validateMoney,
                      }"
                      :disabled="isModalShow"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                v-if="errors[0] && validateMoney"
                class="invalid-feedback ml-4"
                >Fee is {{ errors[0] }}</b-col
              >
            </ValidationProvider>
          </b-col>
          <b-col v-if="needValidationPassword && !isModalShow" lg="4" md="12">
            <div v-if="needSuppervisorCurrentModulePassword">
              <b-input-group>
                <b-input
                  v-model="suppervisorPassword"
                  placeholder="Supervisor password"
                  type="password"
                  size="sm"
                />
                <b-input-group-append>
                  <b-button
                    variant="warning"
                    size="sm"
                    @click="approveSupervisorModule"
                  >
                    <feather-icon icon="CheckIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div v-else-if="needSuppervisorBusinessPassword">
              <b-input-group>
                <b-input
                  v-model="businessSupervisorPassword"
                  type="password"
                  placeholder="Business supervisor password"
                  size="sm"
                />
                <b-input-group-append>
                  <b-button
                    variant="warning"
                    size="sm"
                    @click="approveSupervisorBusiness"
                  >
                    <feather-icon icon="CheckIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
          <b-col v-if="!isModalShow" lg="4" md="12">
            <b-col>
              <b-row>
                <b-col
                  v-if="!isModalAdd"
                  class="d-flex justify-content-end align-items-center"
                >
                  <button-save
                    :disabled="
                      (!selectedRate.description || needConfirmPassword) &&
                      !checkOthers
                    "
                    class="mr-1"
                    @click="saveRates()"
                  />
                  <button-cancel @click="hideModal(false, 0)" />
                </b-col>
                <b-col v-else class="d-flex justify-content-end">
                  <b-button
                    :disabled="needConfirmPassword"
                    class="rounded ml-1"
                    variant="success"
                    @click="saveRates()"
                  >
                    Continue
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import vSelect from "vue-select";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import ModalServiceHeader from "@/views/crm/views/sales-made/components/modals/services/ModalServiceHeader.vue";
import minIp from "./minIp";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";

export default {
  name: "NewBusinessModal",
  components: {
    ButtonSave,
    ButtonCancel,
    ModalServiceHeader,
    vSelect,
  },
  props: {
    modalServices: {
      type: Boolean,
      default: false,
    },
    salesClient: {
      type: Object,
      default: () => ({
        event_id: "",
        account_id: "",
        id: "",
        lead_id: "",
      }),

      // 1: complete rates crm, 2: detail of sale crm, 3: add Services
      // 4: change Services, 5 show add change Services, 6  add  services to lead from programs
    },
    typeModal: {
      type: Number,
      default: 1,
      // 1: complete rates, 2: detail of sale
    },
    usersServices: {
      type: Array,
      default: () => [],
    },
    programsAll: {
      type: Array,
      default: () => [],
    },
    headerS: {
      type: Object,
      default: () => ({ program: "", seller: "", captured: "" }),
    },
  },
  data() {
    return {
      ownControl: false,
      program: 1,
      observation: "Services",
      websiteTypes: [],
      socialNetworkTypes: [],
      registerStatesTypes: [],
      rates: [],
      selectedRate: { description: "" },
      websiteType: null,
      socialNetworkType: null,
      registerStateType: null,
      fee: 0,
      validateMoney: false,
      othersPayments: [],
      scoreId: null,
      suppervisorPassword: "",
      isSuppervisorPasswordApproved: false,
      businessSupervisorPassword: "",
      isBusinessSuppervisorPasswordApproved: false,
      packsName: [
        "WEBSITE",
        "WEBSITE + LOGO",
        "SOCIAL NETWORK",
        "SOCIAL NETWORK + LOGO",
        "PACKS",
      ],
      ratesBusinessFromParagon: [],
      checkOthers: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    min_ip() {
      if (!this.selectedRate) return null;
      if (this.selectedRate.description === "Register") {
        return minIp.filter(
          (item) => item.STATE === this.registerStateType.description
        )[0].IP;
      }
      return null;
    },
    needValidationPassword() {
      return this.discount >= 300;
    },

    needConfirmPassword() {
      if (this.discount < 300) return false;
      if (this.discount < 500) return this.needSuppervisorCurrentModulePassword;
      return this.needSuppervisorBusinessPassword;
    },

    needSuppervisorCurrentModulePassword() {
      return (
        this.discount < 500 &&
        this.discount >= 300 &&
        !this.isSuppervisorPasswordApproved
      );
    },

    needSuppervisorBusinessPassword() {
      return (
        this.discount >= 500 && !this.isBusinessSuppervisorPasswordApproved
      );
    },

    discount() {
      return this.suggestedAmount - this.fee;
    },
    moduleId(){
      console.log("mod",this.$route.matched[0].meta.module);
      return this.$route.matched[0].meta.module;
    },
    isModalShow() {
      return (
        this.moduleId == 16 || ((this.$route.matched[0].meta.module === 2 &&
          this.salesClient.from_module === 26 &&
          (this.typeModal === 1 ||
            this.typeModal === 2 ||
            this.typeModal === 5)) ||
        (this.salesClient.from_module !== 26 &&
          (this.typeModal === 2 || this.typeModal === 5)))
      );
    },
    isModalAdd() {
      return (
        this.typeModal === 3 || this.typeModal === 4 || this.typeModal === 6
      );
    },
    suggestedAmount() {
      if (this.checkOthers) return 0;
      const ratePrice = this.selectedRate ? this.selectedRate.price : 0;
      let websitePrice = this.websiteType ? this.websiteType.price : 0;
      let registerPrice = this.registerStateType
        ? this.registerStateType.price
        : 0;
      if (
        this.selectedRate.type === "6" &&
        this.websiteType === this.websiteTypes[0]
      ) {
        websitePrice += 100;
      }
      const socialNetworkPrice = this.socialNetworkType
        ? this.socialNetworkType.price
        : 0;
      if (
        this.selectedRate.type === "5" &&
        this.selectedRate.description === "Register"
      ) {
        registerPrice -= 100;
      }
      return ratePrice + websitePrice + socialNetworkPrice + registerPrice;
    },
  },
  watch: {
    suggestedAmount(newSuggestedAmount) {
      if (!this.isModalShow) this.fee = newSuggestedAmount;
    },
    checkOthers(newCheckOthers) {
      if (newCheckOthers) {
        this.selectedRate = { description: "" };
      }
    },
  },
  async mounted() {
    this.addPreloader();
    if (this.program) {
      await this.searchRate();
    }

    if (this.isModalAdd) {
      await this.getScore();
    }
    this.ownControl = true;
    this.removePreloader();
  },
  created() {},
  methods: {
    async insertTrackingDerivation() {
      if (this.$route.matched[0].meta.module === 26) {
        const params = {
          lead_id: this.salesClient.lead_id,
          sale_status_id: 1,
          seller_id: this.salesClient.seller_id,
          sale_id: this.salesClient.id,
        };
        await DerivationsService.insertTrackingSaleStatusDerivation(params);
      }
    },
    async approveSupervisorModule() {
      try {
        this.addPreloader();
        const response = await amgApi.post("/commons/get-password-supervisor", {
          module_id: this.currentUser.modul_id,
          field_pass: this.suppervisorPassword,
          type: 1,
        });
        if (this.isResponseSuccess(response)) {
          const passwordValidate = response.data;
          if (passwordValidate === "ok") {
            this.isSuppervisorPasswordApproved = true;
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Password correct"
            );
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Danger",
              "XIcon",
              "Incorrect password"
            );
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    async approveSupervisorBusiness() {
      try {
        this.addPreloader();
        const response = await amgApi.post("/commons/get-password-supervisor", {
          module_id: 3,
          field_pass: this.businessSupervisorPassword,
          type: 1,
        });
        if (this.isResponseSuccess(response)) {
          const passwordValidate = response.data;
          if (passwordValidate === "ok") {
            this.isBusinessSuppervisorPasswordApproved = true;
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Password correct"
            );
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Danger",
              "XIcon",
              "Incorrect password"
            );
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    async getScore() {
      try {
        const response = await amgApi.post("/attend/get-score-attend", {
          lead_id: this.salesClient.lead_id,
        });
        if (response.status === 200) {
          this.scoreId = response.data.score_id;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async saveRates() {
      this.validateMoney = true;
      // Validate Money
      const success = await this.$refs.form.validate();
      if (success) {
        let message = "";
        let route = "";
        let typeADD = "";
        const prices = [];
        if (!this.checkOthers) {
          prices.push({ item: this.selectedRate.id.toString(), cant: 1 });

          // For others or business credit
          this.othersPayments = [];
          if (this.registerStateType) {
            this.othersPayments.push(this.registerStateType.id);
          }
          if (this.websiteType) {
            this.othersPayments.push(this.websiteType.id);
          }
          if (this.socialNetworkType) {
            this.othersPayments.push(this.socialNetworkType.id);
          }
        }

        // Depends of the Modal type
        switch (this.typeModal) {
          case 1:
            message = "complete Rates";
            route = "/sales-made/attendend-sale";
            break;
          case 3:
            message = "add new service";
            route = "/commons/attendend-program";
            typeADD = 1;
            break;
          case 4:
            message = "change service";
            route = "/commons/attendend-program";
            typeADD = 2;
            break;
          case 6:
            message = "add new service";
            route = "/sale/insert-lead-attendance";
            break;
          default:
            break;
        }
        const param = {
          prices,
          param: "param",
          observation: this.observation,
          contract: 1,
          program: this.program,
          fee: this.fee,
          suggested: this.suggestedAmount.toFixed(2),
          otherpricesp: this.othersPayments,
          event: this.salesClient.event_id,
          json_noce: [],
          stateid: 0,
          sale_id: this.salesClient.id,
          min_ip: this.min_ip,
          account: this.salesClient.account_id
            ? this.salesClient.account_id
            : "",
          id_score: this.scoreId,
          json_ce: null,
          user_id: this.currentUser.user_id,
          type: typeADD,
          // debt
          seller: this.salesClient.seller.id,
          captured: this.salesClient.captured.id,
          module: this.salesClient.module,
        };

        const param2 = {
          prices,
          observation: this.observation,
          contract: 1,
          program: this.program,
          fee: this.fee,
          suggested: this.suggestedAmount.toFixed(2),
          otherpricesp: this.otherspayments,
          event: this.salesClient.event_id,
          json_noce: this.add_json_ce,
          stateid: 0,

          // Diferents to add change Services
          account: this.salesClient.account_id
            ? this.salesClient.account_id
            : "",
          captured:
            typeADD == 1 || typeADD == 2
              ? this.salesClient.captured.id
              : this.headerS.captured.id,
          seller:
            typeADD == 1 || typeADD == 2
              ? this.salesClient.seller.id
              : this.headerS.seller.id,
          type: typeADD,
          bbb: "param2",
          user_id: this.currentUser.user_id,
          module: this.currentUser.modul_id,
          id_score: this.score_id,
          json_ce: this.json_ce,
        };

        const result = await this.showConfirmSwal(
          `Are you sure you want to ${message}`
        );
        if (result.isConfirmed) {
          this.addPreloader();
          await this.insertTrackingDerivation();
          const response = await amgApi.post(
            `${route}`,
            typeADD == 1 || typeADD == 2 ? param2 : param
          );

          if (response.status === 200) {
            this.removePreloader();
            this.hideModal(true, this.program);
            this.removePreloader();
          }
        }
      }
    },
    changeProgram(headerS) {
      this.$emit("changeProgram", headerS);
    },
    hideModal(refresh, programSelect) {
      this.$refs['myModal'].hide();
      this.$emit("closeModal", refresh, programSelect);
    },
    async showRates() {
      try {
        const response = await amgApi.post(
          "/sales-made/get-details-sales-made-new",
          { id: this.salesClient.id }
        );
        if (response.status === 200) {
          this.fee = response.data[0].fee;
          if (!Boolean(response.data[0].rate_selected)) {
            this.checkOthers = true;
            this.removePreloader();
            return;
          }
          const rateId = response.data[0].rate_selected[0].rate_id;
          const isFromParagon = this.ratesBusinessFromParagon
            .map(({ id }) => id)
            .includes(rateId);
          if (isFromParagon) {
            [this.selectedRate] = this.ratesBusinessFromParagon.filter(
              (rate) => rate.id === rateId
            );
          } else {
            [this.selectedRate] = this.rates.filter(
              (rate) => rate.id === rateId
            );
          }
          const otherPrices = JSON.parse(response.data[0].others_prices);
          if (this.selectedRate.description === "Register") {
            this.selectedRate.price = 0;
            this.registerStateType = this.registerStatesTypes.filter(
              (type) => type.id === otherPrices[0]
            )[0];
          } else {
            if (
              this.selectedRate.description === "Silver" &&
              this.selectedRate.type === "5"
            ) {
              this.selectedRate.price -= this.websiteTypes[0].price;
            } else if (
              this.selectedRate.description === "Silver" &&
              this.selectedRate.type === "6"
            ) {
              this.selectedRate.price -= this.websiteTypes[0].price + 100;
            } else if (this.selectedRate.description === "Gold") {
              this.selectedRate.price -= this.websiteTypes[1].price;
              this.selectedRate.price -= this.socialNetworkTypes[1].price;
            } else if (this.selectedRate.description === "Platinium") {
              this.selectedRate.price -= this.websiteTypes[2].price;
              this.selectedRate.price -= this.socialNetworkTypes[2].price;
            }
            this.websiteType = this.websiteTypes.filter(
              (type) => type.id === otherPrices[0]
            )[0];
            if (otherPrices.length > 1) {
              this.socialNetworkType = this.socialNetworkTypes.filter(
                (type) => type.id === otherPrices[1]
              )[0];
            }
          }
          this.removePreloader();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async searchRate() {
      try {
        const response = await amgApi.post("/rates/get-rates-by-programs", {
          id: this.program,
        });
        if (response.status === 200) {
          const rates = response.data;
          const ratesBusiness = rates.filter((r) =>
            ["5", "6", "7", "8"].includes(r.type)
          );
          this.rates = ratesBusiness.filter(
            (rate) =>
              ["5", "6"].includes(rate.type) && rate.old_program_id !== 9
          );
          this.websiteTypes = ratesBusiness.filter((rate) =>
            rate.description.includes("Website")
          );
          this.socialNetworkTypes = ratesBusiness.filter((rate) =>
            rate.description.includes("Social Network")
          );
          this.registerStatesTypes = ratesBusiness.filter(
            (rate) => rate.type === "8"
          );
          this.ratesBusinessFromParagon = rates.filter(
            (rate) => rate.old_program_id === 9
          );
          if (!this.isModalShow) {
            this.removePreloader();
          }
          if (this.isModalShow) {
            await this.showRates();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    changeSelectedRate(rate, fromParagon = false) {
      this.checkOthers = false;
      if (!fromParagon) {
        if (
          this.selectedRate.description === "Register" &&
          this.selectedRate.type === "5"
        ) {
          this.selectedRate.price = 799;
        }
        if (
          this.selectedRate.description === "Register" &&
          this.selectedRate.type === "6"
        ) {
          this.selectedRate.price = 899;
        } else if (
          this.selectedRate.description === "Silver" &&
          this.selectedRate.type === "5"
        ) {
          this.selectedRate.price += this.websiteTypes[0].price;
        } else if (
          this.selectedRate.description === "Silver" &&
          this.selectedRate.type === "6"
        ) {
          this.selectedRate.price += this.websiteTypes[0].price + 100;
        } else if (this.selectedRate.description === "Gold") {
          this.selectedRate.price += this.websiteTypes[1].price;
          this.selectedRate.price += this.socialNetworkTypes[1].price;
        } else if (this.selectedRate.description === "Platinium") {
          this.selectedRate.price += this.websiteTypes[2].price;
          this.selectedRate.price += this.socialNetworkTypes[2].price;
        }

        if (rate.description === "Register") {
          this.websiteType = null;
          this.socialNetworkType = null;
          this.registerStateType = this.registerStatesTypes.filter(
            (state) => state.description === "California"
          )[0];
          rate.price = 0;
        } else if (rate.description === "Silver" && rate.type === "5") {
          this.socialNetworkType = null;
          this.registerStateType = null;
          this.websiteType = this.websiteTypes[0];
          rate.price -= this.websiteTypes[0].price;
        } else if (rate.description === "Silver" && rate.type === "6") {
          this.socialNetworkType = null;
          this.registerStateType = null;
          this.websiteType = this.websiteTypes[0];
          rate.price -= this.websiteTypes[0].price + 100;
        } else if (rate.description === "Gold") {
          this.registerStateType = null;
          this.websiteType = this.websiteTypes[1];
          this.socialNetworkType = this.socialNetworkTypes[1];
          rate.price -= this.websiteTypes[1].price;
          rate.price -= this.socialNetworkTypes[1].price;
        } else if (rate.description === "Platinium") {
          this.registerStateType = null;
          this.websiteType = this.websiteTypes[2];
          this.socialNetworkType = this.socialNetworkTypes[2];
          rate.price -= this.websiteTypes[2].price;
          rate.price -= this.socialNetworkTypes[2].price;
        }
      } else {
        this.fee = rate.price;
        this.websiteType = null;
        this.socialNetworkType = null;
        this.registerStateType = null;
      }
      this.selectedRate = rate;
    },
  },
};
</script>

<style lang="scss" scoped>
.dark-layout {
  .bg-click {
    background-color: #6100ff !important;
  }
}
.bg-click {
  background-color: #6100ff !important;
}
.hover-card,
.cancel {
  transition: 300ms;
}
.hover-card:hover {
  background-color: #6100ff !important;
  color: white;
  border: none !important;
}
.cancel:hover {
  background-color: #ff3b19 !important;
  color: white !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
