<template>
  <div>
    <filter-slot
      :filter="filters"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :total-rows="totalRows"
      :filter-principal="filterPrincipal"
      :double-paginate="false"
      @reload="getReturns"
      @onChangeCurrentPage="getReturns"
    >
      <template #table>
        <div class="container-returns-table-ce-ing" v-if="!loading">
          <b-row class="row-item header">
            <b-col cols="1" class="custom-cols row-header">Date</b-col>
            <b-col cols="3" class="custom-cols row-header">Type</b-col>
            <b-col cols="2" class="custom-cols row-header"
              >Transaction ID</b-col
            >
            <b-col cols="1" class="custom-cols row-header">Amount</b-col>
            <b-col cols="2" class="custom-cols row-header">Method</b-col>
            <b-col cols="2" class="custom-cols row-header">Status</b-col>
            <b-col cols="1" class="custom-cols row-header"></b-col>
          </b-row>

          <b-row
            class="row-item detail"
            v-for="(item, index) in items"
            v-bind:key="index + '_item'"
            :class="{
              'active-row': item.show,
              'border-positive': item.positive,
              'border-negative': !item.positive,
            }"
          >
            <b-col cols="1" class="custom-cols">
              <div class="text-center">{{ item.created_at | myGlobal }}</div>
            </b-col>
            <b-col cols="3" class="custom-cols">
              <div
                class="type"
                :class="item.positive ? 'text-positive' : 'text-negative'"
              >
                {{ item.type }}
              </div>
            </b-col>
            <b-col cols="2" class="custom-cols"
              >{{ item.transaction_id }}
            </b-col>
            <b-col
              cols="1"
              class="custom-cols amount"
              :class="item.positive ? 'text-positive' : 'text-negative'"
            >
              $ {{ item.positive ? "" : "-" }}{{ item.amount }}</b-col
            >
            <b-col cols="2" class="custom-cols">{{
              item.method_payment
            }}</b-col>
            <b-col cols="2" class="custom-cols">
              <div :class="item.positive ? 'status' : 'status-negative'">
                {{ item.status_transaction }}
              </div>

              <feather-icon
                v-if="item.advance_payment"
                icon="AlertCircleIcon"
                class="text-warning ml-1 cursor-info"
                size="20"
                v-b-tooltip.hover
                :title="item.advance_payment"
              ></feather-icon>
            </b-col>
            <b-col cols="1" class="custom-cols">
              <feather-icon
                :icon="item.show ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                class="cursor-pointer"
                size="23"
                @click="openCollapse(index)"
              ></feather-icon>
            </b-col>

            <b-col cols="12" class="pl-4">
              <b-collapse
                :visible="Boolean(item.show)"
                accordion="my-accordion"
                class="my-accordion"
              >
                <b-row>
                  <b-col cols="12" class="mt-1">
                    <b-row class="header-accordion"
                      ><b-col cols="2">Transaction Detail</b-col>
                      <b-col cols="4"></b-col>
                      <b-col cols="6">
                        <span class="p-1">Reason:</span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" class="my-1">
                    <b-row>
                      <b-col cols="2">
                        <p>Pay method:</p>
                        <p>Date:</p>
                      </b-col>
                      <b-col cols="4"
                        ><p>{{ item.method_payment }}</p>
                        <p>{{ item.created_at | myGlobal }}</p>
                      </b-col>
                      <b-col cols="6">
                        <p class="pl-1">{{ item.comment }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>

          <div
            v-if="items.length == 0"
            class="text-center mt-2"
            style="font-size: 15px"
          >
            There are no records to show
          </div>
        </div>

        <div class="container-charge-table-ce-ing w-100" v-else>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
        </div>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import VoidIcon from "@/icons/Void.vue";
import RefundIcon from "@/icons/Refund.vue";
import CashIcon from "@/icons/Cash.vue";
import ClientService from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";

export default {
  components: {
    VoidIcon,
    RefundIcon,
    CashIcon,
  },

  props: {
    clientAccountId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      items: [],
      filters: [],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Transaction ID",
        model: "",
      },
      openModalChargeEdit: false,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: null,
      totalRows: 0,
      toPage: null,
      statusActive: false,

      loading: true,
    };
  },

  methods: {
    async getReturns() {
      try {
        this.loading = true;

        const params = {
          client_account_id: this.clientAccountId,
          search_txt: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          current_page: this.paginate.currentPage,
        };

        const { data } = await ClientService.getRefundsByClientAccount(params);

        this.startPage = data.from;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        this.toPage = data.to;

        this.items = data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },

    openCollapse(index) {
      this.items.forEach((item, index2) => {
        if (index2 != index) {
          item.show = false;
        }
      });

      this.items[index].show = !this.items[index].show;
    },
  },
};
</script>

<style lang="scss">
.container-returns-table-ce-ing {
  width: 100%;
  max-height: 640px;
  overflow-y: auto;
  padding: 0 20px 5px 20px;

  .text-positive {
    color: #04b400;
    font-weight: 600;
  }

  .text-negative {
    color: #e35403;
    font-weight: 600;
  }

  .header {
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #0090e7 !important;
    color: #ffffff !important;
  }

  .row-header {
    padding-left: 20px;
  }
  .row-item {
    min-height: 60px;
    margin-top: 10px !important;
    color: black;
    font-size: 16px;
    text-align: left;

    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    background-color: #f5f5f5;

    .custom-cols {
      text-align: left;
      display: flex;
      justify-content: start;
      align-items: center;

      .status,
      .status-negative {
        padding: 4px 15px;
        border-radius: 20px;
        font-weight: 600;
      }
      .status {
        color: #04b400;
        background-color: #c3ffe2;
      }

      .status-negative {
        color: #e35403;
        background-color: #ffe0c3;
      }
    }

    .amount {
      font-family: "Oxygen";
    }

    .my-accordion {
      background-color: #f5f5f5;
      border-top: 1px solid black;
      margin: 10px 0;
      text-align: left;
      font-size: 16px;

      .header-accordion {
        font-weight: 600;
      }
    }
  }

  .detail {
    padding-top: 9px;
    border-left-width: 8px;
    border-left-style: solid;
  }

  .border-positive {
    border-left-color: #04b400;
  }

  .border-negative {
    border-left-color: #e35403;
  }

  .active-row {
    border: 1px solid #0090e7;
  }
}

.dark-layout {
  .container-returns-table-ce-ing {
    .row-item {
      color: #ffffff;
      background-color: #1d1d20;

      .status-container {
        .status-success {
          background-color: #c3ffe2;
          color: #04b400;
        }

        .status-pending {
          background-color: #ffe0c3;
          color: #f17500;
        }
      }

      .my-accordion {
        background-color: #1d1d20;
        border-top: 1px solid #ffffff;
      }
    }

    .active-row {
      border: 1px solid #0090e7;
    }
  }
}

@media (max-width: 1450px) and (min-width: 407px) {
  .container-returns-table-ce-ing {
    .row-item {
      min-height: 45px;
      margin-top: 10px !important;
      font-size: 14px;

      .status-container {
        .status {
          padding: 4px 15px;
          letter-spacing: 0.8px;
        }
      }

      .my-accordion {
        margin: 10px 0;
        text-align: left;
        font-size: 14px;
      }
    }

    .detail {
      border-left-width: 4px;
    }
  }
}
</style>