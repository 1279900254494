<template>
  <div>
    <b-modal
      v-model="openModal"
      modal
      :title="`List Court Cases of: ${leadName}`"
      size="xl"
      modal-class="custom-modal-amg"
      hide-footer
      title-tag="h3"
      header-bg-variant="transparent"
      header-class="p-0 custom-header"
      :no-close-on-backdrop="true"
      @hidden="$emit('closeModalListCourtInfo')"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            List Court Cases of: {{ leadName }}
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="$emit('closeModalListCourtInfo')"
            />
          </div>
        </div>
      </template>
      <b-table
        ref="refListCasesCI"
        no-border-collapse
        class="table-new-customization position-relative custom-table"
        :fields="visibleFields"
        show-empty
        no-provider-filtering
        sticky-header="50vh"
        primary-key="id"
        responsive="sm"
        :items="CiProvider"
        :busy.sync="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(attorney)="data">
          <div
            v-b-tooltip.hover="'Case Number: ' + data.item.case_number"
            style="margin-bottom: 5px"
          >
            <strong> # {{ data.item.case_number }}</strong>
          </div>

          <div style="margin-bottom: 5px">
            <feather-icon
              icon="UserIcon"
              size="15"
              v-b-tooltip.hover="'Attorney'"
            />
            {{ data.item.attorney ? data.item.attorney : "-" }}
          </div>
          <!-- <div style="margin-bottom: 5px">
            <feather-icon
              icon="HomeIcon"
              size="15"
              v-b-tooltip.hover="'Court'"
            />
            {{ data.item.court_name ? data.item.court_name : data.item.state }}
          </div> -->

          <div
            v-b-tooltip.hover
            :title="data.item.court_name == null ? 'State' : 'Court'"
            style="margin-bottom: 5px"
          >
            <feather-icon icon="HomeIcon" size="15" />
            {{
              data.item.court_name == null
                ? data.item.state
                : data.item.court_name
            }}
          </div>

          <div v-b-tooltip.hover="'Creditor'">
            <feather-icon icon="DollarSignIcon" size="15" />
            {{ data.item.credit_name }}
          </div>
        </template>

        <template #cell(document_date)="data">
          <div class="d-flex">
            <div class="d-flex flex-column" style="gap: 5px">
              <span>{{ data.item.document_name }}</span>
              <div class="d-flex">
                <b-badge
                  :variant="
                    data.item.service_id == 1
                      ? 'light-primary'
                      : 'light-warning'
                  "
                  class="mr-1"
                  >{{ data.item.service_name }}</b-badge
                >
              </div>
              <span>{{ data.item.created_at | myGlobal }} </span>
              <span
                class="text-success"
                v-if="data.item.modul_id != 5 && data.item.amount_pay != 0"
              >
                $ {{ data.item.amount_pay | currencyZero }}</span
              >
            </div>

            <div
              class="d-flex flex-column align-items-center justify-content-around ml-2"
              style="gap: 5px"
            >
              <feather-icon
                v-b-tooltip.hover.top="'View Files'"
                class="pointer text-warning"
                icon="FileIcon"
                size="15"
                @click="openModalViewDocuments(data.item.id)"
              />
            </div>
          </div>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="getVariant(data.item.status)"
            >STATUS: {{ data.item.status }}</b-badge
          >
          <br />
          <b-badge
            :variant="'light-' + getVariantStep(data.item.last_cur_step_id)"
            class="mt-1"
            v-b-tooltip.hover.left
            :title="data.item.last_cur_step"
          >
            STEP: {{ data.item.last_cur_step | limitChars(15) }}
            <feather-icon
              class="ml-25"
              :icon="getIconStep(data.item.last_cur_step_id)"
              size="16"
            />
          </b-badge>
          <br />
          <b-badge
            v-if="data.item.last_cur_step_id == 2"
            variant="info"
            class="mt-1"
            >NEW CASE</b-badge
          >
        </template>

        <template #cell(id)="data">
          <div>
            <b-button
              size="sm"
              variant="outline-primary"
              class="mt-1 w-100 d-flex justify-content-center align-items-center gap-2"
              v-b-tooltip.hover.top="'Open Timeline'"
              @click="openModalTimeline(data.item.id)"
            >
              <feather-icon
                icon="GitCommitIcon"
                size="17"
                style="margin-right: 5px"
              />
              Timeline
            </b-button>
          </div>
        </template>

        <template #cell(last_note)="data">
          <span>
            <template v-if="data.item.last_note == null"> None </template>
            <template v-else>
              <strong class="mr-1">LT</strong>
              <b-badge
                v-b-tooltip.hover.topright="'Last Tracking'"
                variant="light-info"
                >{{ data.item.last_note | myGlobalDay }}
              </b-badge>
            </template>

            <br />

            <template v-if="data.item.next_task != '-'">
              <strong class="mr-1">NT</strong>
              <b-badge
                v-b-tooltip.hover.topright="
                  'Next Task Due Date' +
                  (data.item.next_task_is_done == 1 ? ' (Done)' : ' (Undone)')
                "
                :variant="getVariantNT(data.item.next_task)"
              >
                <span
                  v-if="getVariantNT(data.item.next_task) == 'light-primary'"
                >
                  Today
                </span>
                <span v-else>
                  {{ data.item.next_task | myGlobalDay }}
                </span>
              </b-badge>
            </template>
          </span>

          <div
            class="d-flex align-items-center justify-content-center"
            style="margin: 4px 0px"
          >
            <feather-icon
              v-if="
                data.item.status !== 'PENDING' &&
                data.item.advisor_id !== null &&
                moduleId == 5 &&
                data.item.status !== 'DONE'
              "
              class="cursor-pointer text-primary mr-1"
              icon="PlusCircleIcon"
              size="16"
              @click="openAddTrackingCaseModal(data.item.id)"
            />
            <feather-icon
              v-if="
                data.item.status !== 'PENDING' &&
                data.item.advisor_id !== null &&
                data.item.last_note !== null
              "
              class="cursor-pointer text-info mr-1"
              icon="ListIcon"
              size="16"
              @click="openTrackingCaseModal(data.item.id)"
            />
            <b-badge
              v-if="data.item.pending_tasks > 0"
              variant="light-warning"
              v-b-tooltip.hover.topright="'Pending Tasks'"
            >
              {{ data.item.pending_tasks }}
            </b-badge>
          </div>
        </template>

        <template #cell(assigned_to)="data">
          <div v-if="data.item.advisor_id !== null">
            {{ data.item.assigned_to }}
            <br />
            <feather-icon
              v-if="
                [1, 2, 17].includes(currentUser.role_id) &&
                moduleId == 5 &&
                data.item.status == 'REVISION'
              "
              v-b-tooltip.hover.left="'Reassign case'"
              class="pointer text-primary"
              icon="EditIcon"
              size="15"
              @click="openModalAssignCase(data.item.id)"
            />
            <feather-icon
              icon="ListIcon"
              class="cursor-pointer ml-1 text-info"
              size="15"
              v-b-tooltip.hover.left="'Open Tracking'"
              @click="openModalTrackingAdvisor(data.item.id)"
            />
          </div>
          <div v-else>
            <b-badge variant="light-warning"> PENDING</b-badge>
          </div>
        </template>

        <template #cell(created_by)="data">
          {{ data.item.created_by }}
          <br />
          {{ data.item.created_at | myGlobalDay }}
          <span
            v-b-tooltip.hover.top="
              `Case expires in ${data.item.time_to_expire} hours`
            "
            v-if="
              data.item.service_type === 'INVESTIGATION' &&
              data.item.status === 'PENDING' &&
              parseInt(data.item.time_to_expire) > 0
            "
            class="font-weight-bold d-block text-left text-danger"
          >
            <feather-icon icon="ClockIcon" size="15" />
            {{ data.item.time_to_expire }}h</span
          >
        </template>

        <template #cell(actions)="data">
          <div>
            <div class="">
              <b-button
                size="sm"
                variant="outline-primary"
                class="mt-1 w-100"
                @click="openValidateCaseModal(data.item.id, true)"
              >
                View Case
              </b-button>

              <b-button
                v-if="
                  (data.item.sent_status === 'SENT' ||
                    data.item.service_type === 'INVESTIGATION') &&
                  data.item.status == 'REVISION' &&
                  moduleId == 5
                "
                size="sm"
                variant="outline-success"
                class="mt-1 w-100"
                @click="openUpdateCaseDocuments(data.item.id)"
              >
                Update Case.
              </b-button>

              <b-button
                v-if="
                  data.item.status === 'PENDING' &&
                  [1, 2, 17].includes(currentUser.role_id) &&
                  moduleId == 5
                "
                size="sm"
                variant="outline-warning"
                class="mt-1 w-100"
                @click="openValidateCaseModal(data.item.id, false)"
              >
                Validate Case
              </b-button>
            </div>
            <div class="mt-1 w-100">
              <b-button
                v-if="
                  data.item.advisor_id !== null &&
                  data.item.service_id === 1 &&
                  moduleId == 5 &&
                  data.item.status !== 'DONE' &&
                  data.item.status !== 'PENDING' &&
                  data.item.sent_status == 'PREPARE ANSWER'
                "
                variant="outline-info"
                class="w-100"
                size="sm"
                @click="
                  openModalPreparedAnswer(data.item.id, data.item.sent_status)
                "
                >Prepare Answer</b-button
              >
            </div>
          </div>
        </template>
      </b-table>
    </b-modal>
    <!--  -->

    <view-documents-show-modal
      v-if="modalViewDocumentsShow"
      :caseId="caseId"
      @close="modalViewDocumentsShow = false"
    />
    <CaseTrackingModal
      v-if="showTrackingCaseModal"
      :caseId="caseId"
      @close="showTrackingCaseModal = false"
      @closewithrefresh="closeWithRefreshTrackingCaseModal()"
      @refresh="$refs['refListCasesCI'].refresh()"
    />

    <prepared-answer-modal
      v-if="modalPreparedAnswer"
      @onlyClose="modalPreparedAnswer = false"
      :case-id="caseId"
      :read-only="readOnly"
      @completeAnswer="
        (modalPreparedAnswer = false), $refs['refListCasesCI'].refresh()
      "
    ></prepared-answer-modal>
    <timelineByCase
      v-if="timelineShow"
      :caseId="caseId"
      @close="timelineShow = false"
    />
    <ValidateCaseModal
      v-if="modalValidateCaseShow"
      :caseId="caseId"
      :isOnlyView="isOnlyView"
      @close="modalValidateCaseShow = false"
      @reloadTable="$refs['refListCasesCI'].refresh()"
    />

    <assign-case-modal
      v-if="modalAssignCaseShow"
      :caseId="caseId"
      @close="modalAssignCaseShow = false"
      @closing="$refs['refListCasesCI'].refresh()"
    />

    <TrackingAssingCaseModal
      v-if="modalTrackingAssingCaseShow"
      :caseId="caseId"
      @close="modalTrackingAssingCaseShow = false"
    />

    <ModalUpdateCaseDocuments
      v-if="showUpdateCaseDocuments"
      :caseId="caseId"
      @close="showUpdateCaseDocuments = false"
      @closewithrefresh="
        (showUpdateCaseDocuments = false), $refs['refListCasesCI'].refresh()
      "
    />

    <modal-tracking-case
      v-if="modalTrackingCaseModal"
      :caseId="caseId"
      @close="modalTrackingCaseModal = false"
    />
    <InputIndicationModal
      v-if="showAddTrackingCaseModal"
      :caseId="caseId"
      @close="showAddTrackingCaseModal = false"
      @refresh="$refs['refListCasesCI'].refresh()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// components
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import Steps from "@/views/debt-solution/views/court-info/components/data/steps.js";
import ViewDocumentsShowModal from "@/views/debt-solution/views/court-info/components/modals/ViewDocumentsShowModal.vue";
import ModalTrackingCase from "@/views/debt-solution/views/court-info/components/modals/modalTrackingCase.vue";
import PreparedAnswerModal from "@/views/debt-solution/views/court-info/components/modals/PreparedAnswerModal.vue";
import CaseTrackingModal from "@/views/debt-solution/views/court-info/components/modals/TrackingCaseModal.vue";
import timelineByCase from "@/views/debt-solution/views/court-info/components/modals/TimelineShowModal.vue";
import ValidateCaseModal from "@/views/debt-solution/views/court-info/components/modals/ValidateCaseModal.vue";
import AssignCaseModal from "@/views/debt-solution/views/court-info/components/modals/AssignCaseModal.vue";
import TrackingAssingCaseModal from "@/views/debt-solution/views/court-info/components/modals/TrackingAssingCaseModal.vue";
import ModalUpdateCaseDocuments from "@/views/debt-solution/views/court-info/components/modals/ModalUpdateCaseDocuments.vue";
import InputIndicationModal from "@/views/debt-solution/views/court-info/components/modals/GiveIndicationsModal.vue";
import moment from "moment";

export default {
  components: {
    ValidateCaseModal,
    ViewDocumentsShowModal,
    ModalTrackingCase,
    PreparedAnswerModal,
    CaseTrackingModal,
    timelineByCase,
    AssignCaseModal,
    TrackingAssingCaseModal,
    ModalUpdateCaseDocuments,
    InputIndicationModal,
  },
  props: {
    modalListCourtInfo: {
      type: Boolean,
    },
    leadId: {
      type: Number,
    },
    modulId: {
      type: Number,
    },
    leadName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showUpdateCaseDocuments: false,
      openModal: true,
      isBusy: false,
      modalCourtInfo: false,
      fields: [
        {
          key: "attorney",
          label: "Case",
          class: "text-left",
          visible: true,
        },
        {
          key: "document_date",
          label: "document",
          class: "text-left",
          visible: true,
        },
        {
          key: "status",
          label: "status",
          class: "text-left",
          visible: true,
        },
        {
          key: "id",
          label: "Timeline",
          class: "text-left",
          visible: true,
        },
        {
          key: "assigned_to",
          label: "assigned",
          class: "text-left",
          visible: true,
        },
        {
          key: "last_note",
          label: "Last Tracking",
          class: "text-left",
          visible: true,
          tdClass: "text-center",
          thStyle: { width: "250px" },
          thClass: "text-center",
        },
        {
          key: "created_by",
          label: "created by",
          class: "text-left",
          visible: true,
        },
        {
          key: "actions",
          label: "actions",
          visible: true,
        },
      ],
      items: [],
      modalViewDocumentsShow: false,
      caseId: null,
      modalPreparedAnswer: false,
      showTrackingCaseModal: false,
      timelineShow: false,
      STEPS: Steps,
      modalValidateCaseShow: false,
      showAddTrackingCaseModal: false,
      stepsGrid: [
        {
          id: 1,
          icon: "FilePlusIcon",
          color: "primary",
        },
        {
          id: 2,
          icon: "CheckIcon",
          color: "success",
        },
        {
          id: 3,
          icon: "CalendarIcon",
          color: "warning",
        },
        {
          id: 4,
          icon: "ArchiveIcon",
          color: "primary",
        },
        {
          id: 5,
          icon: "FlagIcon",
          color: "success",
        },
        {
          id: 6,
          icon: "TrelloIcon",
          color: "primary",
        },
        {
          id: 7,
          icon: "ThumbsUpIcon",
          color: "success",
        },
        {
          id: 100,
          icon: "FileTextIcon",
          color: "info",
        },
        {
          id: 101,
          icon: "UploadIcon",
          color: "info",
        },
        {
          id: 102,
          icon: "MailIcon",
          color: "info",
        },
        {
          id: 103,
          icon: "MailIcon",
          color: "warning",
        },
        {
          id: 104,
          icon: "MailIcon",
          color: "warning",
        },
      ],
      modalAssignCaseShow: false,
      modalTrackingAssingCaseShow: false,
      modalTrackingCaseModal: false,
      isOnlyView: false,
    };
  },
  mounted() {
    // this.fields[this.fields.length - 1].visible = this.moduleId == 5;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    openUpdateCaseDocuments(caseId) {
      this.caseId = caseId;
      this.showUpdateCaseDocuments = true;
    },
    isButtonDisabled(item) {
      return item.count_files === 0;
    },
    getVariantNT(nextTracking) {
      let nextTrackingDate = moment(nextTracking).format("YYYY-MM-DD");
      let today = moment().format("YYYY-MM-DD");
      if (today == nextTrackingDate) {
        return "light-primary";
      } else if (today > nextTrackingDate) {
        return "light-danger";
      } else {
        return "light-secondary";
      }
    },
    openModalCourtInfo() {
      this.modalCourtInfo = true;
    },
    async CiProvider() {
      this.isBusy = true;
      const params = {
        lead_id: this.leadId,
        module_id: this.modulId,
      };
      const { data, status } = await CourtInfoService.getAllCasesByClientIdCi(
        params
      );

      data.forEach((row) => {
        let created_at = moment(row.created_at).format("YYYY-MM-DD HH:mm:ss");
        let validated_at = moment(row.validated_at).format(
          "YYYY-MM-DD HH:mm:ss"
        );

        let differenceInHours = moment(validated_at).diff(created_at, "hours");
        let expired = differenceInHours > 24 ? true : false;

        if (
          (parseInt(row.time_to_expire) < 24 &&
            row.service_type == "INVESTIGATION" &&
            row.status == "PENDING") ||
          (expired == true &&
            row.status != "PENDING" &&
            row.service_type == "INVESTIGATION")
        ) {
          {
            row._cellVariants = { attorney: "expired" };
          }
        }
      });

      if (status === 200) {
        this.items = data;
        return this.items || [];
      }
      this.isBusy = false;
    },
    async openModalFilesCases(caseId) {
      this.caseId = caseId;
      this.modalViewDocumentsShow = true;
    },
    getVariant(status) {
      switch (status) {
        case "PENDING":
          return "light-warning";
        case "REVISION":
          return "light-secondary";
        case "APPROVED":
          return "light-primary";
        case "CANCELLED":
        case "REJECTED":
          return "light-danger";
        case "DONE":
          return "light-success";
        case "CLOSED":
          return "light-info";
      }
    },
    openTrackingCaseModal(caseId) {
      this.caseId = caseId;
      this.showTrackingCaseModal = true;
    },
    async openModalViewDocuments(id) {
      this.caseId = id;
      this.modalViewDocumentsShow = true;
    },

    openModalPreparedAnswer(id, sent) {
      this.caseId = id;
      this.readOnly = sent == "SENT" || sent == "IN CORRESPONDENCE";
      this.modalPreparedAnswer = true;
    },
    openAddTrackingCaseModal(id) {
      this.caseId = id;
      this.showAddTrackingCaseModal = true;
    },
    getIconStatus(status) {
      switch (status) {
        case "IN CORRESPONDENCE":
          return "GitCommitIcon";
        case "SENT":
          return "SendIcon";
        default:
          return "AlertTriangleIcon";
      }
    },
    openModalTimeline(id) {
      this.caseId = id;
      this.timelineShow = true;
    },
    closeWithRefreshTrackingCaseModal() {
      this.showTrackingCaseModal = false;
      this.$refs["refListCasesCI"].refresh();
    },
    openValidateCaseModal(id, isOnlyView) {
      this.modalValidateCaseShow = true;
      this.caseId = id;
      this.isOnlyView = isOnlyView;
    },
    getIconStep(step) {
      return this.stepsGrid.find((item) => item.id == step).icon;
    },
    getVariantStep(step) {
      return this.stepsGrid.find((item) => item.id == step).color;
    },
    openModalAssignCase(id) {
      this.caseId = id;
      this.modalAssignCaseShow = true;
    },
    openModalTrackingAdvisor(id) {
      this.caseId = id;
      this.modalTrackingAssingCaseShow = true;
    },
    openModalTracking(id) {
      this.caseId = id;
      this.modalTrackingCaseModal = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.disabled-icon {
  opacity: 0.5;
  color: gray !important;
  pointer-events: none;
}

.yellow-button {
  color: rgb(255, 255, 0);
  cursor: pointer;
}
</style>
<style scoped>
.table-expired,
.table-expired > td {
  border-left: 5px solid #f44336 !important;
}
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
</style>
