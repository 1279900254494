import Pusher from "pusher-js";
import { DisapprovedNotification } from "@/views/commons/sales-made/sales-made.socket.js";

const subscribeSocketSalesMade = (socket) => {
  try {
    socket.subscribe("sales-made-notifications");
    DisapprovedNotification();
  } catch (error) {
    console.error(error, "error");
  }
};

export default subscribeSocketSalesMade;
