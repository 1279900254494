<template>
  <div style="width: 200px">
    <span
      :class="timerClass(status)"
      :style="{ fontSize: `${size}px` }"
      style="margin: 0px; font-family: Montserrat"
    >
      <feather-icon icon="ClockIcon" size="12" /> {{ getStatusText() }}
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    createdAt: {
      type: String | null,
      required: true,
    },
    updatedAt: {
      type: String | null,
      required: true,
    },
    size: {
      type: Number,
      default: 14,
    },
    status: {
      type: Number,
      default: 0,
    },
    dueDate: {
      type: String | null,
      required: true,
    },
  },
  data() {
    return {
      time: 0,
      interval: null,
      isExpired: false,
      dynamicDueDate: moment(this.dueDate),
    };
  },
  computed: {
    textTimer() {
      return this.time;
    },
  },
  created() {
    if (this.status === 1) {
      this.time = "Pending";
    } else {
      this.startCountDown();
    }
  },
  beforeDestroy() {
    this.stopCountDown();
  },
  methods: {
    timerClass(status) {
      const color = {
        1: "text-secondary",
        2: "text-primary",
        3: "text-warning",
        4: "text-danger",
        5: "text-info",
        6: "text-success",
        7: "text-secondary",
      };
      return color[status];
    },
    getStatusText() {
      if (this.status === 1) {
        return "Pending";
      }
      if (this.status === 2 || this.status === 3) {
        return `${this.textTimer}`;
      }
      if (this.status === 4) {
        return `${this.getElapsedTime()}`;
      }
      if (this.status === 5 || this.status === 6) {
        return `${this.getElapsedTime()}`;
      }
      if (this.status === 7) {
        return `${this.getElapsedTime()}`;
      }
      return "";
    },
    getElapsedTime() {
      const createdAt = moment(this.createdAt);
      const updatedAt = moment(this.updatedAt);
      const diffDuration = moment.duration(updatedAt.diff(createdAt));

      if (diffDuration.asHours() >= 24) {
        const diffDays = Math.floor(diffDuration.asDays());
        this.time = `${diffDays} day${diffDays > 1 ? "s" : ""} `;
      } else {
        const diffHours = Math.floor(diffDuration.asHours());
        const totalMinutes = Math.floor(diffDuration.asMinutes());
        const diffMinutes = totalMinutes - diffHours * 60;
        const diffSeconds =
          Math.floor(diffDuration.asSeconds()) -
          diffHours * 3600 -
          diffMinutes * 60;
        this.time = `${String(diffHours).padStart(2, "0")}:${String(
          diffMinutes
        ).padStart(2, "0")}:${String(diffSeconds).padStart(2, "0")} hours`;
      }
      return this.time;
    },
    startCountDown() {
      this.updateCountDown();
      this.interval = setInterval(() => {
        this.updateCountDown();
      }, 1000);
    },
    stopCountDown() {
      clearInterval(this.interval);
    },
    updateCountDown() {
      this.dynamicDueDate = this.dynamicDueDate.add(1, "seconds");
      const firstTime = moment(this.createdAt);

      if (firstTime.isAfter(this.dynamicDueDate)) {
        this.isExpired = true;
        this.time = `Expired ${firstTime.diff(
          this.dynamicDueDate,
          "days"
        )} days ago`;
        this.stopCountDown();
      } else {
        this.isExpired = false;
        const diffDuration = moment.duration(
          this.dynamicDueDate.diff(firstTime)
        );

        // Nueva condición para mostrar en días si han pasado más de 24 horas
        if (diffDuration.asHours() >= 24) {
          const diffDays = Math.floor(diffDuration.asDays());
          this.time = `${diffDays} day${diffDays > 1 ? "s" : ""} `;
        } else {
          const diffHours = Math.floor(diffDuration.asHours());
          const totalMinutes = Math.floor(diffDuration.asMinutes());
          const diffMinutes = totalMinutes - diffHours * 60;
          const diffSeconds =
            Math.floor(diffDuration.asSeconds()) -
            diffHours * 3600 -
            diffMinutes * 60;
          this.time = `${String(diffHours).padStart(2, "0")}:${String(
            diffMinutes
          ).padStart(2, "0")}:${String(diffSeconds).padStart(2, "0")} hours`;
        }
      }
    },
  },
};
</script>

  <style lang="scss" scoped>
.timer-text-success {
  color: #00d25b !important;
  font-weight: 700;
}

.timer-text-danger {
  color: #ff0000 !important;
  font-weight: 700;
}
</style>
