<template>
  <b-modal
    ref="modalPaymentScheduleTracking"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    hide-footer
    size="xmd"
    title="PAYMENT SCHEDULE HISTORY"
    @hidden="$emit('close')"
  >
    <div class="mt-2">
      <payment-schedule-timeline
        v-if="timelineEvents.length > 0"
        :timeline-events="timelineEvents"
        :client-account-id="clientAccountId"
      />

      <div v-else class="text-center my-2" style="font-size: 15px">
        There are no records to show
      </div>
    </div>
  </b-modal>
</template>
<script>
import PaymentScheduleTimeline from "@/views/commons/components/clients/components/paymentSchedule/components/PaymentScheduleTimeline.vue";
import PaymentScheduleService from "@/views/commons/components/clients/components/paymentSchedule/service/payment-schedule.service.js";
export default {
  components: {
    PaymentScheduleTimeline,
  },
  props: {
    clientAccountId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timelineEvents: [],
    };
  },
  mounted() {
    this.toggleModal("modalPaymentScheduleTracking");
    this.getTrackingPaymentScheduleByClient();
  },
  methods: {
    async getTrackingPaymentScheduleByClient() {
      try {
        this.addPreloader();
        const { data } =
          await PaymentScheduleService.getTrackingPaymentScheduleByClient({
            clientAccountId: this.clientAccountId,
          });
        this.timelineEvents = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
