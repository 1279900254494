<template>
  <b-modal
    scrollable
    title="List CR"
    ref="modal-list-cr"
    size="lg"
    modal-class="modal-primary"
    @hidden="$emit('hidden')"
    title-tag="h3"
    :no-close-on-backdrop="true"
  >
    <b-row>
      <b-col cols="6">
        <h5 class="text-left" :style="{ marginTop: '4px' }">Lead</h5>
        <p
          class="rounded text-primary border-primary font-medium-1 text-center py10"
        >
          {{ lead.lead_name || lead.nickname }}
        </p>
      </b-col>
    </b-row>

    <CardLeadCreditReportObtained :lead="lead" :isBusy="true" />
  </b-modal>
</template>
<script>
// store
import { mapGetters, mapMutations } from "vuex";
//components
import CardLeadCreditReportObtained from "@/views/crm/views/Lead/lead-module/dashboard/CardLeadCreditReportObtained.vue";
//services
import cardsFields from "@/views/commons/components/ncr-realtor/views/data/fields-cards.fields";
import ModalMixin from "@/mixins/modal";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
export default {
  props: {
    lead: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [ModalMixin],
  components: {
    DragAndDrop,
    CardLeadCreditReportObtained,
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    hasCardSelected() {
      return this.cardSelected ? true : false;
    },
  },
  mounted() {
    //toggle modal if mounted
    this.toggleModal("modal-list-cr");
  },
  data() {
    return {
      cardData: [],
      cardSelected: null,
      amountPartial: 0,
      fieldsCards: cardsFields,
      isVisible: false,
      client: {
        account: "",
        name: "",
        card: 0,
        obs: "",
        option: "",
        typencr: null,
        others: "",
      },
      stateOthers: false,
      hasClient: false,
      indexCardSelected: null,
      isLoading: false,
      lead_id: 0,
      isActiveModalCreateCard: false,
      isActiveModalDeleteCard: false,
      realtorIsBeenPaid: false,
      vMoney: {
        decimal: ".",
        thousands: ",",
        precision: 2,
        masked: false,
        prefix: "$ ",
        min: 0.0,
      },
      methodPaymentSelected: null,
      onSaveAmount: false,
      optionsMethodsPayment: [{ text: "Direct Payment", value: 1 }],
      optionsTypePayment: [
        { text: "Credit Card", value: 1 } /*,{ text:'Others',value:2 } */,
      ],
      optionsType: [
        { id: 1, text: "Cash" },
        { id: 2, text: "Check" },
        { id: 3, text: "Money Order" },
        { id: 4, text: "Cashier Check " },
      ],
      serialNumber: "",
      showCards: false,
      typeSelect: 0,
      ModalApproveSupervisor: false,
      typePaymentSelected: null,
      cards: [],
      validador: true,
      validCard: false,
      validAmount: false,
      idDocument: [],
    };
  },
  methods: {},
};
</script>
<style scoped>
.paddingY {
  padding-bottom: 8px;
  padding-top: 8px;
}
.paddingX {
  padding-left: 45px;
  padding-right: 45px;
}
.marginR {
  margin-right: 10px;
}
.bordeR {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.bordeL {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.fontW {
  font-weight: bold;
}
.inputOthers {
  height: 36px;
}

.title_lable {
  width: 150px;
}
</style>
