<template>
  <b-modal
    v-model="isShow"
    ref="modal-case-tracking"
    title-tag="h3"
    size="lg"
    modal-class="modal-primary"
    hide-footer
    no-close-on-backdrop
    @hidden="close"
  >
    <template #modal-title>
      <span>
        UPDATE DOCUMENTS - CASE:
        <strong>{{ caseInfo.case_number }}</strong></span
      >
    </template>
    <b-card v-if="caseInfo" no-body class="mb-1">
      <b-row>
        <b-col lg="4" xl="4" sm="4" md="4" cols="4">
          <span>
            {{ caseInfo.is_lead == 1 ? "LEAD" : "CLIENT" }}
          </span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ caseInfo.name_lead }}
            </p>
          </div>
        </b-col>
        <b-col lg="4" xl="4" sm="4" md="4" cols="4">
          <span>ACCOUNT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ caseInfo.is_lead == 1 ? "-" : caseInfo.account }}
            </p>
          </div>
        </b-col>
        <b-col lg="4" xl="4" sm="4" md="4" cols="4">
          <span class="title-tag">SERVICE TYPE</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{
                document.typeService == 1
                  ? "ANSWER"
                  : document.typeService == 2
                  ? "INVESTIGATION"
                  : "-"
              }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <validation-observer
      v-if="caseInfo.case_module_id == 5"
      ref="updateCaseDocuments"
      #default="{ invalid }"
    >
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <!-- Types Document -->
          <b-col xl="6" lg="6" cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="documentSelect"
              rules="required"
            >
              <!-- businessRules.documentSelected == WG || -->
              <b-form-group
                id="document-select"
                label="Document Type"
                label-for="service-select"
                :description="
                  (businessRules.documentSelected == SM ||
                    businessRules.documentSelected == BL) &&
                  businessRules.responseTime
                    ? `Response Time ${businessRules.responseTime} days`
                    : null
                "
              >
                <v-select
                  :options="allTypesDocument"
                  label="name"
                  v-model="document.document_type_id"
                  :reduce="(x) => x.id"
                  :searchable="true"
                  placeholder="Select document"
                  :class="errors[0] ? 'border-danger' : null"
                  :clearable="false"
                  :selectable="(option) => !option.disabled"
                  @input="changeDocument"
                >
                  <template #selected-option="{ name, code }">
                    <span
                      >{{ name }}
                      <span v-if="code != null" class="text-primary"
                        >({{ code }})
                      </span></span
                    >
                  </template>
                  <template #option="{ name, code }">
                    <span
                      >{{ name }}
                      <span v-if="code != null" class="text-primary"
                        >({{ code }})
                      </span></span
                    >
                  </template>
                </v-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col lg="6" xl="6" sm="6" md="6" cols="6">
            <validation-provider
              #default="{ errors }"
              name="date"
              vid="date"
              rules="required"
            >
              <b-form-group
                id="documentDate-input"
                label="Document Date"
                label-for="documentDate-input"
              >
                <b-form-datepicker
                  :disabled="document.document_type_id == null"
                  v-model="document.date"
                  placeholder="Enter document date"
                  :class="errors[0] ? 'border-danger' : null"
                  @input="changeDocumentDate"
                >
                </b-form-datepicker>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            xl="6"
            lg="6"
            md="12"
            sm="12"
            cols="12"
            v-if="showDiscountDate"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="discountDateInput"
              rules="required"
            >
              <b-form-group
                id="discountDate-input"
                label="Discount Date"
                label-for="documentDate-input"
              >
                <b-form-datepicker
                  v-model="document.discountDate"
                  placeholder="Enter discount date"
                  :class="errors[0] ? 'border-danger' : null"
                  @input="changeDiscountDate"
                >
                </b-form-datepicker>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col
            xl="6"
            lg="6"
            md="12"
            sm="12"
            cols="12"
            v-if="showDiscountAmount"
          >
            <ValidationProvider
              name="discountAmount"
              v-slot="{ errors }"
              :rules="
                requiredCost ? `required|min_value:${validationMinAmount}` : ''
              "
            >
              <b-form-group
                id="discounAmount-input"
                label="Discount Amount"
                label-for="documentDate-input"
              >
                <money
                  v-bind="vMoney"
                  v-model="document.discountAmount"
                  class="form-control"
                  :class="{
                    'border-danger': errors[0],
                  }"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="12" sm="12">
            <ValidationProvider
              v-slot="{ errors }"
              name="FileRequired"
              rules="required"
            >
              <b-form-group id="files-drag-and-drop" label="Files">
                <drag-and-drop
                  class="w-100"
                  v-model="document.files"
                  :filesArray="document.files"
                  :state="errors[0] ? false : null"
                  :class="errors[0] ? 'border-danger' : ''"
                />
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col cols="12" class="d-flex justify-content-end mt-2">
            <b-button
              type="submit"
              class="w-25"
              variant="outline-success"
              :disabled="invalid"
            >
              <feather-icon icon="CheckIcon" size="16" class="mr-50" />
              UPDATE
            </b-button></b-col
          >
        </b-row>
      </b-form>
    </validation-observer>

    <form-wizard
      v-else
      ref="wizardCase"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :start-index="currentStep"
      :finish-button-text="buttonNextText"
      :hide-buttons="false"
      back-button-text="Previous"
      @on-complete="onSubmit"
    >
      <tab-content title="Information" :before-change="validateFirstStep">
        <validation-observer ref="updateCaseDocuments">
          <b-row>
            <!-- Types Document -->
            <b-col xl="6" lg="6" cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="documentSelect"
                rules="required"
              >
                <!-- businessRules.documentSelected == WG || -->
                <b-form-group
                  id="document-select"
                  label="Document Type"
                  label-for="service-select"
                  :description="
                    (businessRules.documentSelected == SM ||
                      businessRules.documentSelected == BL) &&
                    businessRules.responseTime
                      ? `Response Time ${businessRules.responseTime} days`
                      : null
                  "
                >
                  <v-select
                    :options="allTypesDocument"
                    label="name"
                    v-model="document.document_type_id"
                    :reduce="(x) => x.id"
                    :searchable="true"
                    placeholder="Select document"
                    :class="errors[0] ? 'border-danger' : null"
                    :clearable="false"
                    :selectable="(option) => !option.disabled"
                    @input="changeDocument"
                  >
                    <template #selected-option="{ name, code }">
                      <span v-b-tooltip.hover.bottom="name"
                        >{{ name }}
                        <span v-if="code != null" class="text-primary"
                          >({{ code }})
                        </span></span
                      >
                    </template>
                    <template #option="{ name, code }">
                      <span v-b-tooltip.hover.bottom="name"
                        >{{ name }}
                        <span v-if="code != null" class="text-primary"
                          >({{ code }})
                        </span></span
                      >
                    </template>
                  </v-select>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col lg="6" xl="6" sm="6" md="6" cols="6">
              <validation-provider
                #default="{ errors }"
                name="date"
                vid="date"
                rules="required"
              >
                <b-form-group
                  id="documentDate-input"
                  label="Document Date"
                  label-for="documentDate-input"
                >
                  <b-form-datepicker
                    :disabled="document.document_type_id == null"
                    v-model="document.date"
                    placeholder="Enter document date"
                    :class="errors[0] ? 'border-danger' : null"
                    @input="changeDocumentDate"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              xl="6"
              lg="6"
              md="12"
              sm="12"
              cols="12"
              v-if="showDiscountDate"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="discountDateInput"
                rules="required"
              >
                <b-form-group
                  id="discountDate-input"
                  label="Discount Date"
                  label-for="documentDate-input"
                >
                  <b-form-datepicker
                    v-model="document.discountDate"
                    placeholder="Enter discount date"
                    :class="errors[0] ? 'border-danger' : null"
                    @input="changeDiscountDate"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              xl="6"
              lg="6"
              md="12"
              sm="12"
              cols="12"
              v-if="showDiscountAmount"
            >
              <ValidationProvider
                name="discountAmount"
                v-slot="{ errors }"
                :rules="
                  requiredCost
                    ? `required|min_value:${validationMinAmount}`
                    : ''
                "
              >
                <b-form-group
                  id="discounAmount-input"
                  label="Discount Amount"
                  label-for="documentDate-input"
                >
                  <money
                    v-bind="vMoney"
                    v-model="document.discountAmount"
                    class="form-control"
                    :class="{
                      'border-danger': errors[0],
                    }"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col cols="12" sm="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="FileRequired"
                rules="required"
              >
                <b-form-group id="files-drag-and-drop" label="Files">
                  <drag-and-drop
                    class="w-100"
                    v-model="document.files"
                    :filesArray="document.files"
                    :state="errors[0] ? false : null"
                    :class="errors[0] ? 'border-danger' : ''"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
      <tab-content title="Payment">
        <ValidationObserver
          ref="formPayValidation"
          lazy
          v-bind:vid="'form-payment'"
        >
          <b-row>
            <b-col lg="5" md="5" sm="5" xl="5">
              <div>
                <ValidationProvider
                  name="price"
                  :rules="`required`"
                  v-slot="{ errors }"
                >
                  <h5 class="text-left">Cost</h5>
                  <div
                    class="rounded border-secondary font-medium-1 text-center pricesdiv"
                    :class="{ 'border-danger': errors[0] }"
                  >
                    <b-form-radio-group
                      v-model="amount"
                      :options="optionsAmount"
                      name="radio-validation"
                      style="padding: 6px 0px"
                      @input="changeAmount"
                    />
                  </div>
                </ValidationProvider>
              </div>
            </b-col>

            <b-col xl="4" lg="4" md="4" sm="4" v-if="amount === 'other'">
              <ValidationProvider
                name="otherAmount"
                :rules="
                  'required|validate-amount' +
                  `|min_value:${validationMinAmount}`
                "
                v-slot="{ errors }"
              >
                <money
                  v-bind="vMoney"
                  v-model="otherAmount"
                  class="form-control"
                  style="margin-top: 22px"
                  :class="{
                    'border-danger': errors[0] && vmoneyValidate,
                  }"
                />
                <label v-if="errors[0] && vmoneyValidate" class="text-danger">
                  {{
                    errors[0] == "is min"
                      ? `Min value is ${validationMinAmount}`
                      : errors[0]
                  }}
                </label>
              </ValidationProvider>
            </b-col>

            <b-col :offset="amount === 'other' ? 1 : 5" cols="2">
              <b-button
                class="mt-2 float-right"
                variant="outline-success"
                v-b-tooltip.hover.left="'ADD CARD'"
                @click="openCreateCreditCard()"
              >
                <feather-icon
                  class="text-success"
                  icon="CreditCardIcon"
                  size="15"
                />
                <!-- ADD CARD -->
              </b-button>
            </b-col>
          </b-row>
          <!-- dynamic costs -->
          <template v-if="amount != 'No Payment' && amount != null">
            <b-row class="mt-1 px-1">
              <ValidationProvider
                name="otherAmount"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group label="Options">
                  <b-form-radio-group
                    id="radio-slots"
                    v-model="optionCostSelected"
                    :options="costOptions"
                    name="radio-options-slots"
                    :class="{ 'border-danger': errors[0] }"
                  >
                  </b-form-radio-group>
                </b-form-group>
              </ValidationProvider>
            </b-row>
          </template>

          <b-row>
            <b-col cols="12" v-if="amount == 'No Payment'" class="mt-1">
              <ValidationProvider
                v-slot="{ errors }"
                name="motive_id"
                rules="required"
              >
                <b-form-group
                  id="motive-input"
                  label="No Payment Motive"
                  label-for="motive-input"
                >
                  <v-select
                    :options="motives"
                    label="description"
                    v-model="document.motive_id"
                    :reduce="(x) => x.id"
                    :searchable="true"
                    placeholder="Select a motive"
                    :class="errors[0] ? 'border-danger' : null"
                    :clearable="false"
                    :selectable="(option) => !option.disabled"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col cols="12" class="mt-1" v-if="amount != 'No Payment'">
              <h5 class="text-left mb-0">Cards</h5>
              <credit-card-table
                :cards="cards"
                :optionName="amount"
                @cardId="cardId"
                :usedCard="usedCard"
                :disabledCards="cardsToDisable"
                ref="creditCardTable"
              />
            </b-col>
          </b-row>
        </ValidationObserver>
      </tab-content>
    </form-wizard>

    <CreateCreditCard
      v-if="createCreditOn"
      :lead="caseInfo.lead_id"
      @reload="refreshCards"
      @close="closeCreateCreditCard"
    />
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service.js";
import DocumentService from "@/views/debt-solution/views/settings/views/documents-config/documents/service/document.service.js";
import Steps from "@/views/debt-solution/views/court-info/components/data/steps.js";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import CreditCardTable from "@/views/commons/components/ncr-programs/components/table/card/CreditCardTable.vue";
import CreateCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue";
import moment from "moment";
import VMoney from "v-money";
import { FormWizard, TabContent } from "vue-form-wizard";

export default {
  props: {
    caseId: {
      type: Number,
      required: true,
    },
  },
  components: {
    DragAndDrop,
    CreditCardTable,
    CreateCreditCard,
    VMoney,
    FormWizard,
    TabContent,
  },
  directives: { money: VMoney },

  data() {
    return {
      optionCostSelected: null,
      vmoneyValidate: false,
      allTypesDocument: [],
      businessRules: {
        responseTime: 0,
        courtIsNotFromCA: false,
        caseIsFromCA: false,
        documentSelected: null,
        documentMinCost: null,
        documentDiscountPercent: null,
        documentSelectedName: null,
      },
      currentDocumentServices: [],

      isShow: true,
      caseInfo: {},
      show: true,
      document: {
        document_type_id: null,
        date: "",
        files: [],
        service_type_id: null,
        document_format_id: null,
        typeService: null,
        discountDate: null,
        discountAmount: 0,
        motive_id: null,
      },
      documentTypes: [],
      documentFormats: [],
      showGiveIndicationsModal: false,

      optionsAmount: [],
      amount: null,
      otherAmount: 0,
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      cards: [],
      usedCard: null,
      cardsToDisable: [],
      clientData: [],
      createCreditOn: false,
      minAmount: 0,
      validateMoney: false,
      currentStep: 0,
      WG: 6,
      BL: 7,
      SM: 1,
      selectedAmountOption: {},
      costOptions: [],
      settings: [],
      finallyCost: {},
      showDiscountDate: false,
      showDiscountAmount: false,
      validationMinAmount: 0,
      requiredCost: false,
      motives: [],
    };
  },

  watch: {
    "document.service_type_id"(newVal) {
      this.document.document_type_id = null;
    },

    "businessRules.documentSelected": function (val) {
      // fields
      if (val == this.WG || val == this.BL) {
        this.showDiscountDate = true;
        this.showDiscountAmount = true;
      } else {
        this.showDiscountDate = false;
        this.showDiscountAmount = false;
      }
      // set required amount discounted
      if (val == this.BL) {
        this.requiredCost = true;
      } else {
        this.requiredCost = false;
      }
    },

    amount: function (val) {
      if (val != "other") {
        this.buildOptions(val);
      } else {
        this.costOptions = [];
      }
    },
    otherAmount: function (val) {
      this.buildOptions(val);
    },
    optionCostSelected: function (val) {
      const selectedOption = this.costOptions.find(
        (option) => option.value === val
      );
      this.finallyCost = selectedOption;
    },
  },

  async created() {
    this.addPreloader();
    await this.getCiDocuments();
    await this.getCourtCase();
    await this.getDataInfoClient();
    await this.getCiMotives();
    this.removePreloader();
  },

  computed: {
    buttonNextText() {
      if (this.currentStep == 1) {
        return "Create";
      }
      return "Next";
    },
    isRequestForDismissal() {
      if (this.document.document_type_id != null) {
        let id = this.document.document_type_id;
        let item = this.allTypesDocument.find((item) => item.id === id);
        return item.is_end_of_process == 1;
      }
    },

    documentTypeSelected() {
      if (this.document.document_type_id != null) {
        let id = this.document.document_type_id;
        return this.G_DOCUMENT_ACTIVES.find((item) => item.id === id);
      }
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
      G_DOCUMENT_SERVICE_TYPE_ACTIVES:
        "DSCourtInfoDocumentServiceTypeSettingsStore/G_DOCUMENT_SERVICE_TYPE_ACTIVES",
      G_DOCUMENT_ACTIVES: "DSCourtInfoDocumentSettingsStore/G_DOCUMENT_ACTIVES",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async getCiMotives() {
      try {
        this.addPreloader();
        const { data } = await CourtInfoService.getCiMotives();
        this.motives = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    async buildOptions(amount) {
      this.costOptions = [];
      let documentMinCost = amount;
      this.validationMinAmount = this.businessRules.documentMinCost;

      // 1 payment
      let completeAmount = {
        value: documentMinCost,
        text: `${this.formatAmount(documentMinCost)} (1 installement)`, //  (1 Payment)
        fractionType: "single",
        totalCost: documentMinCost,
      };

      // 2 payments
      let percentConfigurated = this.settings[0].initial_cost_percent;
      let minCost = (documentMinCost * percentConfigurated) / 100;
      let newAmount = {
        value: minCost,
        text: `${this.formatAmount(minCost)} (2 installements)`, // funca
        fractionType: "multiple",
        totalCost: documentMinCost,
      };

      this.costOptions.push(completeAmount);
      this.costOptions.push(newAmount);
    },
    changeAmount() {
      const selectedOption = this.optionsAmount.find(
        (option) => option.value === this.amount
      );
      this.selectedAmountOption = selectedOption;
    },

    async validateFirstStep() {
      const compInformation = this.$refs.updateCaseDocuments;
      const isValidInformation = await compInformation.validate();
      await this.setCost();
      if (isValidInformation) {
        this.currentStep = 1;
      }

      return isValidInformation;
    },
    addZeroPaymentOption() {
      this.optionsAmount.push({
        value: "No Payment",
        text: "No Payment",
        fractionType: "single",
        totalCost: 0,
      });
    },
    async setCost() {
      try {
        this.optionsAmount = [];
        const { data: settings } =
          await CourtInfoService.getCiGeneralSettings();
        this.settings = settings;

        // set price to default configurated if service is investigation
        if (this.document.typeService == 2) {
          this.businessRules.documentMinCost = settings[0].investigation_cost;
        }

        // is DS
        if (this.caseInfo.case_module_id == 5) {
          this.validationMinAmount = this.businessRules.documentMinCost;
          this.amount = this.validationMinAmount;
          let newAmount = {
            value: this.validationMinAmount,
            text: `${this.formatAmount(this.validationMinAmount)}`,
            fractionType: "single",
          };
          this.optionsAmount.push(newAmount);
          this.optionsAmount.push({
            value: "other",
            text: "Other",
            fractionType: "single",
          });
          this.addZeroPaymentOption();
        } else {
          // For other programs, set initial cost
          let documentMinCost = this.businessRules.documentMinCost;
          let completeAmount = {
            value: documentMinCost,
            text: `${this.formatAmount(documentMinCost)}`, //  (1 Payment)
            fractionType: "single",
          };
          this.optionsAmount.push(completeAmount);
          this.optionsAmount.push({
            value: "other",
            text: "Other",
            fractionType: "single",
          });
          this.addZeroPaymentOption();
        }

        // set cost rules when is BL
        if (this.businessRules.documentSelected == this.BL) {
          await this.applyBusinessRulesCostBL();
        }
      } catch (error) {
        console.log(error);
      }
    },
    formatAmount(amount) {
      const formattedAmount = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount);
      return formattedAmount;
    },
    async applyBusinessRulesCostBL() {
      this.optionsAmount = [];

      const discountedAmount = this.document.discountAmount; // $ amount discounted of client or lead
      const minCost = this.businessRules.documentMinCost; // $ min cost of the service
      const discountPercent = this.businessRules.documentDiscountPercent; // % document discount percent
      // apply discountPercent to discountedAmount
      const possibleMinCost = (discountedAmount * discountPercent) / 100; // possible min cost
      this.validationMinAmount =
        possibleMinCost < minCost ? minCost : possibleMinCost;
      this.amount = this.validationMinAmount;

      let newAmount = {
        value: this.validationMinAmount,
        text: `${this.formatAmount(this.validationMinAmount)}`,
        fractionType: "single",
      };
      this.optionsAmount = [];
      this.optionsAmount.push(newAmount);
      this.optionsAmount.push({
        value: "other",
        text: "Other",
        fractionType: "single",
      });
      this.addZeroPaymentOption();
    },
    changeDiscountDate(dateSelected) {
      //  ||
      //   this.businessRules.documentSelected == this.WG
      if (this.businessRules.documentSelected == this.BL) {
        let today = moment();
        let discountDate = moment(dateSelected);
        // subtract today days with discount date
        let diff = today.diff(discountDate, "days");
        let responseTime = this.businessRules.responseTime;
        // compare if diff is greather than response time
        if (diff > responseTime) {
          this.document.typeService = 2;
        } else {
          this.document.typeService = 1;
        }
      }
    },

    changeDocumentDate(dateSelected) {
      // if is summons
      if (this.businessRules.documentSelected == this.SM) {
        let today = moment();
        let documentDate = moment(dateSelected);
        // subtract today days with document date
        let diff = today.diff(documentDate, "days");
        let responseTime = this.businessRules.responseTime;
        // compare if diff is greather than response time
        if (diff > responseTime) {
          this.document.typeService = 2;
        } else {
          this.document.typeService = 1;
        }
      }
    },

    async getCiDocument(documentId) {
      try {
        const { data } = await CourtInfoService.getCiDocument({
          document_id: documentId,
        });
        return data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong getCiDocument!"
        );
      }
    },

    async changeDocument($event) {
      let rows = await this.getCiDocument($event);
      rows = JSON.parse(rows[0].services);
      const hasAnswer = rows.some((item) => item.service === 1);
      this.currentDocumentServices = rows;

      if (hasAnswer) {
        this.document.typeService = 1;
      } else {
        this.document.typeService = 2;
      }

      let documentSelect = rows.find(
        (item) => item.service === this.document.typeService
      );
      this.businessRules.responseTime = documentSelect.response_time;
      this.businessRules.documentSelected = documentSelect.id;
      this.businessRules.documentMinCost = documentSelect.cost;
      this.businessRules.documentDiscountPercent =
        documentSelect.discount_percent;
      this.businessRules.documentSelectedName = documentSelect.name;
    },

    async getCiDocuments() {
      try {
        const { data } = await CourtInfoService.getCiDocuments();
        this.allTypesDocument = data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong getCiDocuments!"
        );
      }
    },

    cardId(value) {
      this.card = value.id;
    },
    openCreateCreditCard() {
      this.createCreditOn = true;
    },
    async refreshCards() {
      this.getDataInfoClient();
    },
    closeCreateCreditCard() {
      this.createCreditOn = false;
    },

    async getDataInfoClient() {
      try {
        const response = await CourtInfoService.getDataClientById({
          lead_id: this.caseInfo.lead_id,
          module_id: this.moduleId,
        });
        if (response.length > 0) {
          this.clientData = response[0];
          this.cards = JSON.parse(response[0].cards);
          const optionsAmount = JSON.parse(response[0].amounts);

          this.optionsAmount = optionsAmount.filter(
            (element) => element.value !== null
          );

          if (this.optionsAmount.length > 0) {
            this.minAmount = this.optionsAmount[0].value;
          }
          // modificar cuando venga de un programa
          if (this.moduleId == 5) {
            let newAmount = {
              value: 0,
              text: "$ 0.00",
            };
            this.optionsAmount.unshift(newAmount);
          }
          this.optionsAmount.push({ value: "other", text: "Other" });

          this.uploadInfo = true;
        } else {
          this.clientData = null;
        }
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with search!"
        );
      }
    },

    close() {
      this.$emit("close");
    },

    ...mapActions({
      A_GET_DOCUMENT_SERVICE_TYPE_ACTIVES:
        "DSCourtInfoDocumentServiceTypeSettingsStore/A_GET_DOCUMENT_SERVICE_TYPE_ACTIVES",
      A_GET_DOCUMENT_ACTIVES:
        "DSCourtInfoDocumentSettingsStore/A_GET_DOCUMENT_ACTIVES",
    }),

    async onServiceTypeChange(val) {
      await this.A_GET_DOCUMENT_ACTIVES({ type: val });
    },
    async onSubmit() {
      try {
        const isValid = await this.$refs.updateCaseDocuments.validate();
        if (isValid) {
          if (this.caseInfo.case_module_id != 5) {
            this.vmoneyValidate = true;
            const compPay = this.$refs.formPayValidation;
            const isValidPay = await compPay.validate();

            if (isValidPay) {
              this.isRequestForDismissal === true
                ? await this.completeCase() // End of process
                : await this.continueProcess(); // Start loop
              return true;
            }
            return isValidPay;
          }

          this.isRequestForDismissal === true
            ? await this.completeCase() // End of process
            : await this.continueProcess(); // Start loop
          return true;
        }
        return false;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          this.getInternalErrors(error) || "Something went wrong"
        );
      }
    },

    async continueProcess() {
      try {
        this.addPreloader();
        let form = new FormData();
        this.document.files.forEach((file) => {
          form.append("files[]", file, file.name);
        });
        form.append("case_id", this.caseInfo.id);
        form.append("created_by", this.currentUser.user_id);
        form.append("is_end_of_proccess", 0);
        form.append("case_number", this.caseInfo.case_number);
        form.append("has_answer", document.service_type_id == 1 ? 1 : 2);
        form.append("date", this.document.date);
        form.append("document_type", this.document.document_type_id);
        form.append(
          "document_name",
          this.allTypesDocument.find(
            (option) => option.id === this.document.document_type_id
          ).name
        );

        form.append("service_id", this.document.typeService);

        if (this.caseInfo.case_module_id != 5) {
          // params for payment
          form.append("card_id", this.usedCard ?? this.card);
          // form.append("amount", this.finallyCost.value);
          // form.append("fraction_type", this.finallyCost.fractionType);
          // form.append("total_cost", this.finallyCost.totalCost);

          form.append(
            "amount",
            this.amount == "No Payment" ? null : this.finallyCost.value
          );
          form.append(
            "fraction_type",
            this.amount == "No Payment" ? null : this.finallyCost.fractionType
          );
          form.append(
            "total_cost",
            this.amount == "No Payment" ? null : this.finallyCost.totalCost
          );

          if (this.amount == "No Payment") {
            form.append("motive_id", this.document.motive_id);
          }
        }

        form.append("modul_id", this.moduleId);
        form.append("case_module_id", this.caseInfo.case_module_id);
        form.append("user_id", this.currentUser.user_id);
        form.append("client_account_id", this.caseInfo.account_id);
        form.append("case_type_document_id", this.caseInfo.document_type_id);
        form.append("lead_id", this.caseInfo.lead_id);
        form.append("is_lead", this.caseInfo.is_lead);
        form.append("discount_date", this.document.discountDate);
        form.append("discount_amount", this.document.discountAmount);

        const response = await CourtInfoService.insertDocument(form);
        const nextStep = response[0].next_step;

        if (nextStep == Steps.ANSWER_PREPARED) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Case updated successfully, now you can fill the questionnaire"
          );
        } else if (nextStep == Steps.INDICATIONS_GIVEN) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Case updated successfully, now you can give indications"
          );
          this.$emit("closewithrefresh");
          this.$emit("completeTracking");
          return true;
        }
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        this.removePreloader();
      }
    },

    async completeCase() {
      try {
        this.addPreloader();
        let form = new FormData();
        this.document.files.forEach((file) => {
          form.append("files[]", file, file.name);
        });
        form.append("case_id", this.caseInfo.id);
        form.append("created_by", this.currentUser.user_id);
        form.append("is_end_of_proccess", 1);
        form.append("case_number", this.caseInfo.case_number);
        form.append("case_type_document_id", this.caseInfo.document_type_id);
        form.append("lead_id", this.caseInfo.lead_id);
        form.append("document_type", this.document.document_type_id);
        form.append("date", this.document.date);

        if (this.caseInfo.case_module_id != 5) {
          form.append("card_id", this.usedCard ?? this.card);
          form.append("amount", this.finallyCost.value);
          form.append("fraction_type", this.finallyCost.fractionType);
          form.append("total_cost", this.finallyCost.totalCost);

          if (this.amount == "No Payment") {
            form.append("amount", 0);
            form.append("fraction_type", "single");
            form.append("total_cost", 0);
            form.append("motive_id", this.document.motive_id);
          }
        }
        form.append("case_module_id", this.caseInfo.case_module_id);
        form.append("modul_id", this.moduleId);
        form.append("user_id", this.currentUser.user_id);
        form.append("client_account_id", this.caseInfo.account_id);
        form.append("case_type_document_id", this.caseInfo.document_type_id);
        form.append("is_lead", this.caseInfo.is_lead);

        form.append(
          "service_id",
          this.document.service_type_id == "ANSWER" ? 1 : 2
        );

        await CourtInfoService.insertDocument(form);
        this.$emit("closewithrefresh");

        this.showToast(
          "success",
          "top-right",
          "Success",
          "CheckIcon",
          "Case completed successfully"
        );
      } catch (error) {
        console.log(error);

        throw error;
      } finally {
        this.removePreloader();
      }
    },

    async getCourtCase() {
      try {
        const response = await CourtInfoService.getCourtCase({
          case_id: this.caseId,
        });
        this.caseInfo = response[0];
      } catch (error) {
        console.log(error);

        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          this.getInternalErrors(error) || "Something went wrong"
        );
      }
    },

    async getDocumentFormats(documentId) {
      try {
        let params = {
          document_id: documentId,
        };
        const response = await DocumentService.getDocumentFormats(params);
        this.documentFormats = response;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
};
</script>
