export default [
    {
        path: "bills",
        name: "logistic-bills",
        redirect: { name: "logistic-bills-purchase-order" },
        component: () =>
            import(
               /* webpackChunkName: "LogisticTransactions" */ "@/views/logistic/views/bills/Bills.vue"
            ),
        meta: {
            pageTitle: "Bills",
            breadcrumb: [
                {
                    text: "Purchase Order",
                    active: true,
                },
            ],
        },
        children: [
            {
                path: "purchase-order",
                component: () =>
                    import(/* webpackChunkName: "LogisticPurchaseOrder" */ "@/views/logistic/views/bills/components/PurchaseOrderComponent.vue"),
                name: "logistic-bills-purchase-order",
                meta: {
                    pageTitle: "Bills",
                    breadcrumb: [
                        {
                            text: "Purchase Order",
                            active: true,
                        },
                    ],
                },
            },
            // {
            //     path: "expenses",
            //     component: () =>
            //         import(/* webpackChunkName: "LogisticExpenses" */ "../views/components/TransactionsExpensesGrid.vue"),
            //     name: "logistic-transactions-expenses",
            //     meta: {
            //         pageTitle: "Transactions",
            //         breadcrumb: [
            //             {
            //                 text: "Expenses",
            //                 active: true,
            //             },
            //         ],

            //     },
            //     props: true
            // },
            // {
            //     path: "incomes",
            //     component: () =>
            //         import(/* webpackChunkName: "LogisticIncomes" */ "../views/components/TransactionsIncomesGrid.vue"),
            //     name: "logistic-transactions-incomes",
            //     meta: {
            //         pageTitle: "Transactions",
            //         breadcrumb: [
            //             {
            //                 text: "Incomes",
            //                 active: true,
            //             },
            //         ],
            //     },
            //     props: true
            // },
        ],
    },
];
