import store from '@/store';
import Notifications from '@/service/notification/index';
import employeeClaims, { salesMadeCounter } from '@/views/commons/commons-counter';
import RequestService from '@/views/commons/components/request/services/request.service.js';
import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';
import HelpDeskService from '../commons/components/helpdesk/services/helpdesk.service';
import SchedulesService from '../commons/components/schedules/services/schedules.service';
import LoansService from '../commons/components/loans/services/loans.service';
import AppointmentsService from './views/appoiments/service/appointments.service';
import WebsiteLeads from '@/views/crm/views/Lead/lead-module/services/website-leads.services';

const loanCounter = async () => {
  const statusShow = store.state.auth.currentUser.modul_id === 16
    ? 4
    : store.state.auth.currentUser.modul_id === 17
      ? 3
      : store.state.auth.currentUser.role_id === 2
          || store.state.auth.currentUser.role_id === 1
        ? 2
        : 0;

  const params = {
    id_module: store.state.auth.currentUser.modul_id,
    page: 1,
    status: 1,
    type: statusShow,
  };
  const data = await LoansService.getCounterLoan(params);
  const payload = {
    routeName: 'loans-management',
    tag: data[0].counter,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};
const schedulesCounter = async () => {
  const params = {
    moduleid: 2,
  };
  const { data } = await SchedulesService.getSchedulesCounter(params);
  if (data) {
    if (Object.keys(data).length > 0) {
      const payload = {
        routeName: 'schedules-crm',
        tag: data.grandTotal,
      };
      store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
    }
  }
};
const helpDeskCounter = async () => {
  const params = {
    modul_id: 2,
    user_id: store.state.auth.currentUser.user_id,
  };
  const { data } = await HelpDeskService.getCounterNotifications(params);
  if (Object.keys(data).length > 0) {
    const payload = {
      routeName: 'helpdesk-crm',
      tag: data.total,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const leadsCounter = async () => {
  const { data } = await WebsiteLeads.getWebsiteLeadsUnseen();
  const payload = {
    routeName: 'lead-crm',
    tag: data.leads_unseen,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  store.commit('LeadsStore/M_SET_WEBSITE_LEADS_UNSEED', data.leads_unseen);
};
const appoimentListPending = async () => {
  const params = {
    user_id: store.state.auth.currentUser.user_id,
    role_id: store.state.auth.currentUser.role_id,
  };

  const response = await AppointmentsService.getCountAppointmentPending(params);
  const item = response.data[0];
  if (Object.keys(item).length > 0) {
    const payload = {
      routeName: 'crm-appointments',
      tag: item.pending,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};
const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'crm-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: 'request-crm',
    tag: data[0].counter,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'crm-recruitment-process',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({ user_id, moduleId });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: 'crm-customer-tickets',
      tag: data[0].v_total_open,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const allCountersDebtSolution = async () => {
  Promise.all([
    salesMadeCounter('sales-made-crm'),
    loanCounter(),
    projectsCounter(),
    schedulesCounter(),
    helpDeskCounter(),
    appoimentListPending(),
    requestNotAsignedCounter(),
    employeeClaims('ds-employee-claims'),
    applicantsCounter(),
    getPendinCustomerTickets(),
    leadsCounter(),
  ]);
};
export default allCountersDebtSolution;
