export default [
  {
    path: "reports",
    name: "creditexperts-reports",
    redirect: { name: "creditexperts-reports-payment-reports" },
    component: () => import(/* webpackChunkName: "CreditExpertsReports" */ "@/views/commons/components/reports/Reports.vue"),
    meta: {
      pageTitle: "Reports",
      permittedRoles: [1, 2, 11, 3],
      breadcrumb: [
        {
          text: "Reports",
        },
      ],
    },
    children: [
      {
        path: "payment-reports",
        name: "creditexperts-reports-payment-reports",
        component: () => import(/* webpackChunkName: "CreditExpertsPaymentReports" */ "@/views/commons/components/reports/tabs/monthly-payment/MonthlyPayment.vue"),
        meta: {
          pageTitle: "Reports",
          permittedRoles: [1, 2, 11, 3],
          breadcrumb: [
            {
              text: "Reports",
            },
            {
              text: "Payment Reports",
            },
          ],
        },
      },
      {
        path: "debtor-clients",
        name: "creditexperts-reports-debtor-clients",
        component: () => import(/* webpackChunkName: "CreditExpertsReportsDebtorClients" */ "@/views/commons/components/reports/tabs/debtor-clients/DebtorClients.vue"),
        meta: {
          pageTitle: "Reports",
          permittedRoles: [1, 2, 3],
          breadcrumb: [
            {
              text: "Reports",
            },
            {
              text: "Debtor Clients Report",
            },
          ],
        },
      },
    ],
  },
];
