export default [
  {
    key: 'max_date',
    label: 'Max Date',
  },
  {
    key: 'task',
    label: 'TASK',
  },
  {
    key: 'status',
    label: 'status',
  },
  {
    key: 'created_at',
    label: 'created at',
  },
  {
    key: 'actions',
    label: 'actions',
  },
];
