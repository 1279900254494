<template>
  <!-- <div class="d-flex col-12 justify-content-center">
    <div class="my-2">
      <ValidationObserver ref="form">
        <ValidationProvider
          v-slot="{ errors }"
          name="option"
          rules="money-required"
        >
          <b-input-group
            label="To continue select an option:"
            v-slot="{ ariaDescribedby }"
            v-for="(option, key) in options"
            :key="key"
          >
            <b-input-group-prepend>
              <b-form-checkbox
                v-model="selected"
                :value="option.id"
                :aria-describedby="ariaDescribedby"
                name="flavour-3a"
                button
                class="mb-1"
                :button-variant="
                  errors[0]
                    ? 'outline-danger borde-right-029 border-button-029  opts-029'
                    : 'outline-primary borde-right-029 border-button-029'
                "
              >
                <span :class="errors[0] ? 'color-029' : ''">{{
                  option.value
                }}</span>
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input
              class="mb-1"
              v-model="option.description"
              :class="errors[0] ? 'input-border-029' : ''"
              disabled
            />
          </b-input-group>
        </ValidationProvider>
      </ValidationObserver>
    </div>
    <div class="d-flex justify-content-center" style="width: 100%">
      <div>
        <b-button variant="success" class="rounded" @click="sell"
          >Sell</b-button
        >
        <b-button variant="secondary" class="rounded ml-1" @click="closeModal">
          Cancel
        </b-button>
      </div>
    </div>
  </div> -->
  <b-container fluid style="margin-top: 100px">
    <validation-observer ref="formOptions">
      <validation-provider v-slot="{ errors }" name="option" rules="required">
        <b-row class="mb-4" align-h="center">
          <b-col md="3" v-for="(item, index) in options" :key="index">
            <b-card
              :border-variant="errors[0] ? 'danger' : 'light'"
              class="h-100 box clickable"
              @click="toggleSelection(item.id)"
            >
              <b-card-header class="d-flex align-items-center">
                <tabler-icon :icon="item.icon" size="30" class="" />
                <div class="d-flex align-item-center" style="gap: 15px">
                  <h3 class="font-bold" style="font-family: MONOSPACE">
                    {{ item.value }}
                  </h3>

                  <b-form-checkbox
                    v-model="selected"
                    :value="item.id"
                    @change="toggleSelection(item.id)"
                  >
                  </b-form-checkbox>
                </div>
              </b-card-header>
              <hr class="p-0 m-0" />
              <b-card-body>
                <div class="grid gap-2 mt-1">
                  <p>{{ item.description }}</p>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </validation-provider>
    </validation-observer>
  </b-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import ServiceLeads from "@/views/commons/components/lead-programs/service/lead.service.js";
import EventService from "@/views/crm/services/event/index.js";
export default {
  components: {},
  props: {
    searchData: {
      type: Object,
    },
    eventId: {
      type: Number,
    },
  },
  data() {
    return {
      selected: [],
      options: [],
      event: null,
    };
  },
  computed: {
    ...mapState({
      refreshSalesMade: (state) => state.CrmSaleMadeStore.refreshSalesMade,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    pathNameSaleMade() {
      switch (this.moduleId) {
        case 2:
          return "sales-made-crm";
        case 26:
          return "sales-made-cedigital-new-client";
        default:
          return "sales-made-crm";
      }
    },
  },
  async created() {
    this.addPreloader();
    await this.getAnalysisOptions();
    this.removePreloader();
  },
  mounted() {
    this.getEvent();
  },
  methods: {
    ...mapMutations({
      SET_REFRESH_SALES_MADE: "CrmSaleMadeStore/SET_REFRESH_SALES_MADE",
    }),
    ...mapActions({
      A_GET_EVENTS: "CrmEventStore/A_GET_EVENTS",
      A_GET_MOTIVES: "CrmGlobalStore/A_GET_MOTIVES",
      A_NOTSALE_EVENTS_MOTIVES: "CrmGlobalStore/A_NOTSALE_EVENTS_MOTIVES",
      A_GET_EVENT: "CrmEventStore/A_GET_EVENT",
      A_GET_LEAD: "CrmLeadStore/A_GET_LEAD",
      A_SET_EVENT: "CrmEventStore/A_SET_EVENT",
      A_DELETE_EVENT: "CrmEventStore/A_DELETE_EVENT",
      A_ATTEND_EVENT: "CrmEventStore/A_ATTEND_EVENT",
      A_GET_DATE_EVENTS_TASKS: "CrmEventStore/A_GET_DATE_EVENTS_TASKS",
      A_UPDATE_EVENT: "CrmEventStore/A_UPDATE_EVENT",
      A_SAVE_NO_ANSWER_EVENT_MOTIVE:
        "CrmGlobalStore/A_SAVE_NO_ANSWER_EVENT_MOTIVE",
    }),
    closeModal() {
      this.$emit("closeModal");
    },
    refreshSalesMadeTable() {
      this.SET_REFRESH_SALES_MADE(this.refreshSalesMade + 1);
    },
    async getAnalysisOptions() {
      const { data, status } = await ServiceLeads.getFinancialAnalysisOptions();
      if (status == 200) {
        this.options = data;
        let iconOption = [
          "UserCheckIcon",
          "DeviceDesktopIcon",
          "CurrencyDollarIcon",
        ];
        this.options.map((item, index) => (item.icon = iconOption[index]));
      }
    },

    async getEvent() {
      try {
        const { data: event } = await EventService.getEvent({
          id: this.eventId,
        });
        if (event.length > 0) {
          this.event = event[0];
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async sell() {
      try {
        const validate = await this.$refs.formOptions.validate();
        if (validate) {
          this.addPreloader();
          let dataEmit = {
            id_event: this.event.id,
            option: this.selected,
            lead_pending_appointment: 2,
          };
          const responseFirst = await this.A_GET_DATE_EVENTS_TASKS({
            idLead: this.event.lead_id,
            id: this.event.id,
          });
          if (this.isResponseSuccess(responseFirst)) {
            const dateFormat = this.$moment(
              `${this.event.date} ${this.event.from}`
            ).format("YYYY-MM-DD HH:mm:ss");
            let repeat = 0;
            responseFirst.data.forEach((el) => {
              if (el.dates == dateFormat) repeat++;
            });
            if (repeat === 0) {
              const body = {
                ...this.event,
                user_id: this.event.seller_id,
                id: this.event.id,
                title: this.event.title,
                date: this.event.date,
                from: this.event.from,
                to: this.event.to,
                host: this.event.seller_id,
                location: this.event.location,
                description: this.event.description,
                seller: this.event.seller_name,
                current_user: this.currentUser.user_id,
                month: this.getCurrentMonth(),
                module: this.moduleId,
                lead_pending_appointment: 2,
                call_answered: this.selected !== null ? 1 : 0,
                call_attended: this.selected !== null ? 1 : 0,
              };
              const response = await this.A_UPDATE_EVENT(body);
              if (this.isResponseSuccess(response)) {
                this.showToast(
                  "success",
                  "top-right",
                  "Success!",
                  "CheckIcon",
                  "Appointment updated successfully"
                );
                this.isLoading = false;
              }
              await this.listCreditorsToEvent();
              await Promise.all([
                this.A_ATTEND_EVENT({
                  id: this.event.id,
                  program: 4,
                  done: 1,
                  module: this.moduleId,
                  recovery_id: null,
                  derivation_id: null,
                  type_client: "LEAD",
                }),
              ]);
              await this.updateOptionSelected(dataEmit);
              if (this.$route.name == "sales-made-crm-lead-pending") {
                this.refreshSalesMadeTable();
                return true;
              } else {
                this.$router.push({
                  name: this.pathNameSaleMade,
                  // query: { done: 1 },
                });
              }
            }
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async listCreditorsToEvent() {
      await EventService.listCreditorsToEvent({
        leadId: this.event.lead_id,
        eventId: this.event.id,
      });
    },
    setDataBlank(key) {
      this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`] =
        Object.assign({}, this[key]);
    },
    async updateOptionSelected(emitData) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await ServiceLeads.financialAnalysisOption({
            option: emitData.option,
            id_event: emitData.id_event,
          });

          await this.getHistory(emitData, data[0]);
          resolve(true);
        } catch (error) {
          reject(false);
        }
      });
    },
    async getHistory(emitData, dsSituation) {
      try {
        const { data } = await amgApi.post(
          "/sales-made/debt-solution/get-credits-debt-solution",
          {
            event: this.event.id,
            account: null,
          }
        );

        if (data.length > 0) {
          dsSituation.id_history = data[0].id_history;
          dsSituation.id_analisis = data[0].id_analisis;

          Promise.all([this.setSecondStep(emitData, dsSituation)]);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async setSecondStep(emiteData, optionDS) {
      const response = await amgApi.post(
        "/sales-made/debt-solution/save-first-debt-solution",
        {
          type: 2,
          id: "",
          event: emiteData.id_event,
          account: null,
          total_due: optionDS.total_amount_due,
          cost: this.event.settlement_cost,
          monthly: optionDS.monthly_payment,
          porctrf: 0.1,
          retainer: optionDS.service,
          months: optionDS.months,
          years: Math.ceil(parseFloat(optionDS.months / 12).toFixed(1)),
          estimated: optionDS.saving,
          id_history: optionDS.id_history,
          id_analisis: optionDS.id_analisis,
        }
      );
    },
    toggleSelection(id) {
      if (this.selected === id) {
        this.selected = null; // Desmarcar si ya está seleccionado
      } else {
        this.selected = id; // Marcar el nuevo id
      }
    },
  },
};
</script>
<style lang="css" scoped>
.box:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}
</style>>


