export default [
  {
    path: "dashboard",
    name: "dashboard-bussiness",
    component: () => import(/* webpackChunkName: "DashboardBusiness" */ "@/views/business/views/dashboard/Dashboard.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
    },
  },
];