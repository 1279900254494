export default [
  {
    path: "calendar",
    name: "calendar-creditexperts",
    component: () => import(/* webpackChunkName: "CreditExpertsCalendar" */ "@/views/boost-credit/views/calendar/calendar.vue"),
    meta: {
      pageTitle: "Calendar",
      breadcrumb: [
        {
          text: "Calendar",
        },
      ],
      permittedRoles: [1, 2, 11],
    },
  },
];
