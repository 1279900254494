var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"variant":"primary","size":"lg","hide-footer":"","no-close-on-backdrop":""},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('div',{staticClass:"text-uppercase text-white font-weight-bolder",staticStyle:{"padding":"5px"}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ClockIcon","size":"15"}}),_vm._v(" Clients of the CEO ")],1)]},proxy:true}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('b-container',{staticClass:"pb-2 pt-1",attrs:{"fluid":""}},[_c('b-table',{attrs:{"show-empty":"","fields":_vm.fields,"items":_vm.myProvider,"table-class":"text-nowrap","responsive":"","small":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(lead_name)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[_vm._v(" "+_vm._s(item.lead_name)+" ")])]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.created_at))+" ")])]}},{key:"cell(updated_at)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("myGlobalDay")(item.updated_at))+" ")])]}},{key:"cell(ticket)",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[_vm._v(" "+_vm._s(item.ticket)+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }