export default {
    namespaced: true,
    state: {
        S_COUNTER_STATUS_CHANGE: 0,
    },
    mutations: {
        M_SET_COUNTER_STATUS_CHANGE(state, payload) {
            state.S_COUNTER_STATUS_CHANGE = payload
        },

    },
    actions: {
        A_SET_COUNTERS_STATUS_CHANGE({ commit }, payload) {
            commit('M_SET_COUNTER_STATUS_CHANGE', payload)
        },
    }
}
