export default [
  {
    path: 'payments',
    name: 'paragon-payments',
    redirect: { name: 'paragon-payment-list-paragon' },
    component: () => import(/* webpackChunkName: "PRPayments" */ '@/views/paragon/views/payments/Payments.vue'),
    meta: {
      pageTitle: 'Payments',
      breadcrumb: [
        {
          text: 'Payments',
          active: true,
        },
      ],
      route: 'paragon',
    },
    children: [
      {
        path: 'paragon',
        component: () => import(/* webpackChunkName: "PRPaymentsParagon" */ '@/views/commons/components/payments/views/components/PaymentsClientsGrid.vue'),
        name: 'paragon-payment-list-paragon',
        meta: {
          pageTitle: 'Payments',
          breadcrumb: [{
            text: 'Paragon',
            active: true,
          }],
          moduleTab: 12,
          route: 'ti',
        },
      },
      {
        path: 'business',
        component: () => import(/* webpackChunkName: "PRPaymentsBusiness" */ '@/views/commons/components/payments/views/components/PaymentsClientsGrid.vue'),
        name: 'business-payment-list-bussiness',
        meta: {
          pageTitle: 'Payments',
          breadcrumb: [{
            text: 'Business',
            active: true,
          }],
          moduleTab: 3,
          route: 'business',
        },
      },
    ],
  },
];
