import { amgApi } from "@/service/axios";

class SalesMadeService {

    async insertBinnacleMessage(params) {
        try {
            const data = await amgApi.post("sales-made/insert-message-binnacle", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getMessageBinnacle(params) {
        try {
            const data = await amgApi.post("sales-made/get-message-sales-made", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async approveBySeller(params) {
        try {
            const data = await amgApi.post("sales-made/approve-by-seller", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async approveBySupervisor(params) {
        try {
            const data = await amgApi.post("sales-made/approve-by-supervisor", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getSaleMadeTracking(params) {
        try {
            const data = await amgApi.post("sales-made/get-sale-made-tracking", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getCounterSalesMadePending(params) {
        try {
            const data = await amgApi.post("sales-made/get-counter-new-search-sales-made", params);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default new SalesMadeService();