import { amgApi } from "@/service/axios";

class DerivationsService {
  async applyLeadClientDerivation(params) {
    const response = await amgApi.post(
      "credit-experts-digital/sales/apply-lead-client-derivation",
      params
    );
    return response;
  }

  async getSaleProcessStatus() {
    const response = await amgApi.get(
      "credit-experts-digital/sales/get-sale-process-status"
    );
    return response;
  }

  async getLeadClientDerivated(params) {
    const response = await amgApi.post(
      "credit-experts-digital/sales/get-lead-client-derivated",
      params
    );
    return response;
  }
  async getLeadClientDerivedAdvanced(params) {
    const data = await amgApi.post(
      "credit-experts-digital/sales/get-lead-client-derived-advanced",
      params
    );
    return data;
  }
  async getSellerItem() {
    const data = await amgApi.get(
      "credit-experts-digital/sales/get-seller-item"
    );
    return data;
  }
  async addSellerCDS(params) {
    const response = await amgApi.post(
      "credit-experts-digital/sales/add-seller-cds",
      params
    );
    return response;
  }
  async getLeadClientDerivationCounters(params) {
    const response = await amgApi.post(
      "credit-experts-digital/sales/get-lead-client-derivation-counters",
      params
    );
    return response;
  }
  async getCatcherSeller(params) {
    const response = await amgApi.post(
      "credit-experts-digital/sales/get-catcher-seller",
      params
    );
    return response;
  }
  async getAllCeModulesExceptSales() {
    const data = await amgApi.get(
      "credit-experts-digital/sales/get-all-ce-modules-except-sales"
    );
    return data;
  }

  async insertTrackingSaleStatusDerivation(params) {
    const response = await amgApi.post(
      "credit-experts-digital/sales/insert-tracking-sale-status-derivation",
      params
    );
    return response;
  }

  async getAllCeUsers() {
    const data = await amgApi.get(
      "credit-experts-digital/sales/get-all-ce-users"
    );
    return data;
  }

  async validateCompleteCrProcess(body) {
    try {
      const data = await amgApi.post(
        "/sales-made/debt-solution/validate-complete-cr-process",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getLeadNcrData(body) {
    try {
      const data = await amgApi.post(
        "/sales-made/debt-solution/get-lead-ncr-data",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async hasAnalyzedCR(body) {
    try {
      const { data } = await amgApi.post("/sales-made/has-analyzed-cr", body);
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new DerivationsService();
