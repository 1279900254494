<template>
  <b-card class="lead-client px-1 position-relative">
    <!-- <template #header> -->
    <b-card-title class="d-block d-md-flex align-items-center w-100">
      <h4
        class="font-weight-bolder font-size-lg m-0 w-100 mr-1 border-2 border-bottom-secondary pb-1"
      >
        <span>Client&nbsp;</span>
        <span>
          <a
            v-if="currentUser.modul_id === 15"
            class="text-important"
            @click="$emit('onEditLead', false)"
            >{{ leadName.name }}</a
          >
          <span v-else-if="onlyRead || isResponsibleLead">{{
            leadName.name
          }}</span>
          <a v-else class="text-primary" @click="$emit('onEditLead', false)">
            {{ leadName.name }}
          </a>
          <span
            v-if="
              $store.getters['NotificationStore/G_IS_RESPONSIBLE_CEO']
                .is_responsible
            "
            class="text-warning"
          >
            <tabler-icon
              :icon="
                $store.getters['NotificationStore/G_IS_RESPONSIBLE_CEO']
                  .is_responsible === currentUser.user_id
                  ? 'StarIcon'
                  : 'LockIcon'
              "
              size="15"
              style="margin-left: 5px"
            />
            <strong>CEO's client</strong>
          </span>
        </span>
      </h4>
      <div class="d-flex">
        <div class="mr-2" style="height: 40px" >
          <rc-chat-icon :lead-phone-number="lead.mobile" :lead-id="lead.id" :lead-name="lead.lead_name"/>
        </div>

        <court-info-action :lead-id="lead.id" />
        <LeadEmailDropdown :lead="lead" v-if="moduleId === 2" />
      </div>
      <!--MORE OPTIONS-->
      <b-dropdown
        size="lg"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
        class="custom-dropdown"
        v-b-tooltip.hover.top="'More options'"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="20"
            class="hover-me"
            :class="isDarkSkin ? 'text-white' : 'text-dark'"
          ></feather-icon>
        </template>
        <b-dropdown-item
          href="#"
          :disabled="isResponsibleLead"
          v-if="lead.mobile != null"
          @click="onAddMyList"
        >
          <feather-icon icon="ClipboardIcon" size="15" class="mr-1" />
          Add To My List
        </b-dropdown-item>
        <b-dropdown-item
          href="#"
          v-if="
            (lead.status_sn_id && moduleId === 2 && !lead.potential) || 1 == 1
          "
          @click="onSetPotential"
        >
          <feather-icon icon="UserXIcon" class="mr-1" size="15"></feather-icon>
          Non Potential
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-group id="dropdown-group-1">
          <b-dropdown-item
            href="#"
            v-if="(lead.status_sn_id && moduleId === 2) || 1 == 1"
            :disabled="isResponsibleLead"
            @click="onNotCall"
          >
            <feather-icon
              icon="PhoneMissedIcon"
              class="mr-1"
              size="15"
            ></feather-icon>
            No Call
          </b-dropdown-item>
          <b-dropdown-item
            href="#"
            v-if="
              (lead.status_sn_id &&
                moduleId === 2 &&
                lead.not_call &&
                lead.not_call.length) ||
              1 == 1
            "
            @click="$bvModal.show('modal-trackin-not-call')"
          >
            <feather-icon icon="ListIcon" class="mr-1" size="15"></feather-icon>
            Tracking Not Call
          </b-dropdown-item>
        </b-dropdown-group>
      </b-dropdown>
    </b-card-title>
    <!-- </template> -->
    <b-card-body
      class="border rounded mt-2"
      :class="!isDarkSkin ? 'custom-bg-gray' : null"
    >
      <h4
        class="font-weight-bolder pb-1 px-1 mb-2 border-bottom-secondary border-2"
      >
        Relevant Information
      </h4>
      <detail-component class="px-2" :details="details" />
    </b-card-body>

    <!-- modal NOT CALL -->
    <b-modal
      id="modal-trackin-not-call"
      ok-only
      modal-class="modal-primary"
      centered
      size="lg"
      :title="`TRACKING NOT CALL - ${lead.lead_name}`"
      hide-footer
      no-close-on-backdrop
    >
      <modal-trackin-not-call :lead="lead" />
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import DetailComponent from "@/views/crm/views/Lead/components/DetailComponent.vue";
import ModalTrackinNotCall from "./ModalTrackinNotCall.vue";
import AmgIcon from "@/icons/AmgIcon.vue";
import CourtInfoAction from "@/views/debt-solution/views/court-info/components/CourtInfoAction.vue";
import LeadEmailDropdown from "@/views/crm/views/Lead/lead-module/dashboard/simple-lead-email/LeadEmailDropdown.vue";
import RcChatIcon from "@/views/commons/components/ring-central/chat-sms/RcChatIcon.vue"

export default {
  name: "CardLeadClient",
  components: {
    DetailComponent,
    ModalTrackinNotCall,
    AmgIcon,
    CourtInfoAction,
    LeadEmailDropdown,
    RcChatIcon
  },
  props: {
    onlyRead: {
      type: Boolean,
      required: true,
    },
    lead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      blankLead: {},
      isLoading: false,
      showTrackingCaseModal: false,
      modalListCourtInfo: false,
    };
  },
  created() {
    this.blankLead = { ...this.lead };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    leadName() {
      if (this.currentUser.modul_id === 15) {
        return {
          name: `${this.lead.nickname} (${this.lead.lead_name})`,
          url: true,
        };
      }
      if (this.onlyRead) {
        return { name: this.lead.lead_name };
      }
      return { name: this.lead.lead_name, url: true };
    },
    longAddress() {
      const names = [];
      if (this.lead.street) names.push(this.lead.street);
      if (this.lead.city) names.push(`<br>${this.lead.city}`);
      if (this.lead.states_eeuu_slug && this.lead.zipcode)
        names.push(`${this.lead.states_eeuu_slug} ${this.lead.zipcode}`);
      return names.join(", ");
    },
    details() {
      return [
        {
          label: "Phone:",
          value: this.lead.mobile,
        },
        [
          this.lead.ssn
            ? {
                label: "SSN:",
                value: `XXX-XX-${this.lead.ssn}`,
              }
            : null,
          this.lead.itin
            ? {
                label: "ITIN:",
                value: `XXX-XX-${this.lead.itin}`,
              }
            : null,
          this.lead.cpn
            ? {
                label: "CPN:",
                value: `XXX-XX-${this.lead.cpn}`,
              }
            : null,
        ],
        this.moduleId !== 15
          ? {
              label: "DOB:",
              value: this.lead.dob
                ? this.$moment(this.lead.dob).format("MM/DD/YYYY")
                : "",
            }
          : null,
        {
          label: "Language:",
          value: this.lead.language === "en" ? "English" : "Spanish",
        },
        {
          label: "Origin Country:",
          value: this.lead.origin_country,
        },
        {
          label: "Services:",
          value: this.lead.name_programs,
        },

        {
          label: "Status:",
          value:
            this.moduleId !== 15
              ? this.lead.lead_status
              : this.lead.valuestatuslead,
        },
        {
          label: "Lead Owner:",
          value: this.lead.user_owner,
        },
        this.moduleId !== 15
          ? {
              label: "Address:",
              value: this.longAddress,
            }
          : null,
        this.moduleId === 2
          ? {
              label: "Work Status:",
              value: this.lead.work_status_text,
            }
          : null,
        this.moduleId === 15
          ? {
              label: "CATCHER:",
              value: this.lead.user_catcher,
            }
          : null,
        this.moduleId === 15
          ? {
              label: "FAN PAGE:",
              value: this.lead.fanpage,
            }
          : null,
        this.moduleId === 15
          ? {
              label: "CREATED DATE:",
              value: this.lead.created_at,
            }
          : null,
        {
          label: "E-mail:",
          value: this.lead.email,
        },
      ];
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      A_MY_LIST_CREATE: "CrmLeadStore/A_MY_LIST_CREATE",
      A_SET_POTENTIAL: "CrmLeadStore/A_SET_POTENTIAL",
      A_CHANGE_STATUS_SN: "CrmLeadStore/A_CHANGE_STATUS_SN",
    }),
    onAddMyList() {
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            this.isLoading = true;
            const response = await this.A_MY_LIST_CREATE({
              module_id: this.moduleId,
              user_id: this.currentUser.user_id,
              lead_id: this.lead.id,
            });
            if (this.isResponseSuccess(response))
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            else
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                `Something went wrong. ${response.message}`
              );
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log("spmething went wrong onAddMyList: ", error);
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
          );
        });
    },
    onSetPotential() {
      this.showConfirmSwal("Without Potential?")
        .then(async (result) => {
          if (result.value) {
            this.isLoading = true;
            const response = await this.A_SET_POTENTIAL({
              lead_id: this.lead.id,
              potential: 1,
            });
            if (this.isResponseSuccess(response)) {
              this.lead.potential = 1;
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                `Something went wrong. ${response.message}`
              );
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log("spmething went wrong onSetPotential: ", error);
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
          );
        });
    },
    onNotCall() {
      this.showConfirmSwal("Your lead not responding to you?")
        .then(async (result) => {
          if (result.value) {
            this.isLoading = true;
            const specialist = `${this.currentUser.first_name} ${this.currentUser.last_name}`;

            // Have just the first program of a string
            let program = "";
            if (this.lead.lead_programs) {
              const programs = this.lead.name_programs;
              program = programs.split(",")[0];
            }
            const response = await this.A_CHANGE_STATUS_SN({
              lead_id: this.lead.id,
              status_id: 4,
              specialist,
              user_id: this.currentUser.user_id,
              language: this.lead.language,
              lead_name: this.lead.last_name,
              modul_id: this.currentUser.modul_id,
              program,
            });
            if (this.isResponseSuccess(response)) {
              this.lead.not_call = JSON.stringify(response.data);
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
              this.$emit("reloadLead");
            } else
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                `Something went wrong. ${response.message}`
              );
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log("spmething went wrong onNotCall: ", error);
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
          );
        });
    },
    openUpdateCaseModal() {
      this.showTrackingCaseModal = true;
    },
    closeTrackingCaseModal() {
      this.showTrackingCaseModal = false;
    },
    openModalListCourtInfo() {
      this.modalListCourtInfo = true;
    },
    closeModalListCourtInfo() {
      this.modalListCourtInfo = false;
    },
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "lead",
    event: "update:lead",
  },
  mounted() {
    if (this.lead.nickname && this.currentUser.modul_id === 15) {
      this.details.unshift({
        label: "Nickname:",
        value: this.lead.nickname,
      });
    }
  },
};
</script>

<style lang="scss">
.lead-client .btn-group .btn {
  padding-top: 0;
  padding-bottom: 0;
}
.custom-bg-gray {
  background-color: #fafafa !important;
}
.font-size-lg {
  font-size: 18px !important;
}
.custom-dropdown {
  position: absolute !important;
  top: 10px !important;
  right: -25px !important;
}
</style>
