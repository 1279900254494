export default {
  path: "paymentreport",
  name: "paragon-paymentreport",
  component: () => import(/* webpackChunkName: "PRPaymentReport" */ "@/views/paragon/views/payment-report/index.vue"),
  meta: {
    pageTitle: "Payment Report",
    breadcrumb: [
      {
        text: "Payment Report",
        active: true,
      },
    ],
  }
}