export const requestNcrSubTab = (name,tab,abv,permitted_roles = null) => {
    return [
        {
            path: "pending",
            component: () =>
                import(
                    /* webpackChunkName: "pendingRequestNcrTable" */
                    "@/views/commons/components/request-ncr/views/components/RequestNcrTable.vue"
                ),
            name: `${abv}-ncr-${name}-pending`,
            meta: {
                permittedRoles: permitted_roles,
                tabTitle: "Pending",
                subTabName: "pending",
                pageTitle: "Request NCR",
                breadcrumb: [
                    {
                        text: "Pending",
                        active: true,
                    },
                ],
                paramStatus:"PENDING",
                paramTab:tab
            },
        },
        {
            path: "finished",
            component: () =>
                import(
                    /* webpackChunkName: "finishedRequestNcrTable" */
                    "@/views/commons/components/request-ncr/views/components/RequestNcrTable.vue"
                ),
            name: `${abv}-ncr-${name}-finished`,
            meta: {
                permittedRoles: permitted_roles,
                tabTitle: "Finished",
                subTabName: "finished",
                pageTitle: "Request NCR",
                breadcrumb: [
                    {
                        text: "Finished",
                        active: true,
                    },
                ],
                paramStatus:"FINISHED",
                paramTab:tab
            },
        },
        {
            path: "annulled",
            component: () =>
                import(
                    /* webpackChunkName: "annulledRequestNcrTable" */
                    "@/views/commons/components/request-ncr/views/components/RequestNcrTable.vue"
                ),
            name: `${abv}-ncr-${name}-annulled`,
            meta: {
                permittedRoles: permitted_roles,
                tabTitle: "Annulled",
                subTabName: "annulled",
                pageTitle: "Request NCR",
                breadcrumb: [
                    {
                        text: "Annulled",
                        active: true,
                    },
                ],
                paramStatus:"ANNULLED",
                paramTab:tab
            },
        },
    ]
};
