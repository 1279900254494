export default [
  {
    path: "leads",
    name: "tax-research-leads",
    redirect: { name: "tax-research-leads-list" },
    component: () => import(/* webpackChunkName: "TaxResearchLeads" */ "@/views/commons/components/lead-programs/Lead.vue"),
    meta: {
      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Leads",
        },
      ],
    },
    children: [
      {
        path: "",
        name: "tax-research-leads-list",
        component: () => import(/* webpackChunkName: "TaxResearchLeadsList" */ "@/views/commons/components/lead-programs/components/lead-table/LeadTable.vue"),
        meta: {
          route: "taxresearch",
          isClientsTab: true,
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
              to: "/taxresearch/leads",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "leads/show/:id",
    name: "tax-research-lead-show",
    component: () => import(/* webpackChunkName: "TaxResearchLeadsShow" */ "@/views/crm/views/Lead/lead-module/dashboard/default.vue"),
    props: true,
    meta: {
      isClientsTab: true,
      route: "taxresearch",
      contentClass: "leads-app",
    },
  }
];
