export default [
  {
    path: "loans",
    name: "loans-administration",

    redirect: { name: 'my-loans-administration' },
    component: () => import(/* webpackChunkName: "CrmLoansComponent" */ "@/views/commons/components/loans/LoansComponent.vue"),
    children: [
      {
        path: "my-loans",
        name: "my-loans-administration",
        component: () => import(/* webpackChunkName: "CrmMyLoansView" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 1,
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "My Loans",
              active: true,
            },
          ],
          route: "administration",
          permittedRoles: [1, 2, 6],
        },
      },
      {
        path: "loans-module",
        name: "loans-module-administration",
        component: () => import(/* webpackChunkName: "CrmLoansCrmView" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 2,
          route: "administration",
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "Loans By Module",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 6],
        },
      },
    ],
    meta: {
      tab: 1,
      route: "administration",
      permittedRoles: [1, 2, 6],
    },
  },
];
