import store from "@/store"
export default [
  {
    path: "employee-claims",
    name: "rrhh-employee-claims",
    redirect: {
      name: "rrhh-employee-claims-others-claims"

        // store.state.auth.currentUser ?
        //   [1, 2, 17].includes(store.state.auth.currentUser.role_id)
        //     ? "rrhh-employee-claims-others-claims"
        //     : "rrhh-employee-claims-list"
        //   : "auth-login"
    },
    component: () =>
      import(
        /* webpackChunkName: "rrhh-Employee-claims" */ "@/views/commons/employee-claims/EmployeeClaims.vue"
      ),
    children: [
      {
        path: "others-claims",
        name: "rrhh-employee-claims-others-claims",
        component: () =>
          import(
          /* webpackChunkName: "rrhh-Employee-claims-grid" */ "@/views/commons/employee-claims/Claims.vue"
          ),
        redirect: { name: 'list-claims-pending-rrhh' },
        meta: {
          pageTitle: "Staff Claims",
          // permittedRoles: [1, 2, 17],
          breadcrumb: [
            {
              text: "Others Claims",
              active: true,
            },
          ],
          tabOthersClaimsRoute: 'rrhh-employee-claims-others-claims',
          tabEmployeeClaimListRoute: 'rrhh-employee-claims-list',
          listClaimsPendingRoute: 'list-claims-pending-rrhh',
          listClaimsResolvedRoute: 'list-claims-resolved-rrhh',
          listClaimsNotResolvedRoute: 'list-claims-not-resolved-rrhh',
          listClaimsRejectedRoute: 'list-claims-rejected-rrhh',
          employeeClaimsDashboardRoute: 'dashboard-employee-claims-rrhh',
        },
        children: [
          {
            path: 'pending',
            component: () => import(/* webpackChunkName: "RrhhClaimsMain" */ "@/views/commons/employee-claims/components/ListClaims.vue"),
            name: 'list-claims-pending-rrhh',
            meta: {
              pageTitle: "Staff Claims",
              // permittedRoles: [1, 2, 17],
              pageCodeIcon: "workers_claim",
              breadcrumb: [{
                text: 'Pending',
                active: true,
              }],
              status: 1,
              tabOthersClaimsRoute: 'rrhh-employee-claims-others-claims',
              tabEmployeeClaimListRoute: 'rrhh-employee-claims-list',
              listClaimsPendingRoute: 'list-claims-pending-rrhh',
              listClaimsResolvedRoute: 'list-claims-resolved-rrhh',
              listClaimsNotResolvedRoute: 'list-claims-not-resolved-rrhh',
              listClaimsRejectedRoute: 'list-claims-rejected-rrhh',
              employeeClaimsDashboardRoute: 'dashboard-employee-claims-rrhh',
            },
          },
          {
            path: 'resolved',
            component: () => import(/* webpackChunkName: "RrhhClaimsMain" */ "@/views/commons/employee-claims/components/ListClaims.vue"),
            name: 'list-claims-resolved-rrhh',
            meta: {
              pageTitle: "Staff Claims",
              // permittedRoles: [1, 2, 17],
              pageCodeIcon: "workers_claim",
              breadcrumb: [{
                text: 'Resolved',
                active: true,
              }],
              status: 2,
              tabOthersClaimsRoute: 'rrhh-employee-claims-others-claims',
              tabEmployeeClaimListRoute: 'rrhh-employee-claims-list',
              listClaimsPendingRoute: 'list-claims-pending-rrhh',
              listClaimsResolvedRoute: 'list-claims-resolved-rrhh',
              listClaimsNotResolvedRoute: 'list-claims-not-resolved-rrhh',
              listClaimsRejectedRoute: 'list-claims-rejected-rrhh',
              employeeClaimsDashboardRoute: 'dashboard-employee-claims-rrhh',
            },
          },
          {
            path: 'rejected',
            component: () => import(/* webpackChunkName: "RrhhClaimsMain" */ "@/views/commons/employee-claims/components/ListClaims.vue"),
            name: 'list-claims-rejected-rrhh',
            meta: {
              pageTitle: "Staff Claims",
              // permittedRoles: [1, 2, 17],
              pageCodeIcon: "workers_claim",
              breadcrumb: [{
                text: 'Rejected',
                active: true,
              }],
              status: 3,
              tabOthersClaimsRoute: 'rrhh-employee-claims-others-claims',
              tabEmployeeClaimListRoute: 'rrhh-employee-claims-list',
              listClaimsPendingRoute: 'list-claims-pending-rrhh',
              listClaimsResolvedRoute: 'list-claims-resolved-rrhh',
              listClaimsNotResolvedRoute: 'list-claims-not-resolved-rrhh',
              listClaimsRejectedRoute: 'list-claims-rejected-rrhh',
              employeeClaimsDashboardRoute: 'dashboard-employee-claims-rrhh',
            },
          },

        ]
      },
      {
        path: "",
        name: "rrhh-employee-claims-list",
        component: () =>
          import(
            /* webpackChunkName: "rrhh-Employee-claims-grid" */ "@/views/commons/employee-claims/components/Grid.vue"
          ),
        meta: {
          pageTitle: "Staff Claims",
          breadcrumb: [
            {
              text: "My Claims",
              active: true,
            },
          ],
          tabOthersClaimsRoute: 'rrhh-employee-claims-others-claims',
          tabEmployeeClaimListRoute: 'rrhh-employee-claims-list',
          employeeClaimsDashboardRoute: 'dashboard-employee-claims-rrhh',
        },
      },

    ],
    meta: {
      pageTitle: "Staff Claims",
      breadcrumb: [
        {
          text: "Staff Claims",
          active: true,
        },
      ],
      tabOthersClaimsRoute: 'rrhh-employee-claims-others-claims',
      tabEmployeeClaimListRoute: 'rrhh-employee-claims-list',
      listClaimsPendingRoute: 'list-claims-pending-rrhh',
      listClaimsResolvedRoute: 'list-claims-resolved-rrhh',
      listClaimsNotResolvedRoute: 'list-claims-not-resolved-rrhh',
      listClaimsRejectedRoute: 'list-claims-rejected-rrhh',
      employeeClaimsDashboardRoute: 'dashboard-employee-claims-rrhh',
    },
  },
];
