<template>
  <b-modal title="EDIT SCHEDULE" ref="modalSchedule" @hidden="$emit('hidden')">
    <div>
      <b-form-group label="Day">
        <v-select
          v-model="schedule.date"
          :options="daysOfTheWeek"
          :reduce="(days) => days.id"
          multiple
          :closeOnSelect="false"
          disabled
        >
          <template #selected-option="{label}">
            <span class="text-dark">
              {{label}}
            </span>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="Priority">
        <v-select
          v-model="schedule.priority"
          :options="['High', 'Medium', 'Low']"
        />
      </b-form-group>
      <b-row>
        <b-col cols="12">
          <b-form-group label="From">
            <kendo-timepicker
              :format="'HH:mm'"
              v-model="schedule.time_from"
              :interval="15"
              class="w-100 rounded bg-transparent"
              placeholder="Time from"
              style="height: 2.73rem"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="To">
            <template #label>
              <span>To</span>
            </template>
            <kendo-timepicker
              :format="'HH:mm'"
              v-model="schedule.time_to"
              :interval="15"
              class="w-100 rounded bg-transparent"
              placeholder="Time from"
              style="height: 2.73rem"
              :min="minTime"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <b-button variant="primary" class="text-nowrap" @click="editSchedule()">
        EDIT
      </b-button>
      <b-button
        variant="secondary"
        class="text-nowrap"
        @click="$emit('hidden')"
      >
        CANCEL
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import NotesServices from "@/views/commons/components/first-notes/services/notes.service";
export default {
  data() {
    return {
      Moment: moment,
      date: [],
      daysOfTheWeek: [
        { id: 1, label: "MONDAY" },
        { id: 2, label: "TUESDAY" },
        { id: 3, label: "WEDNESDAY" },
        { id: 4, label: "THURSDAY" },
        { id: 5, label: "FRIDAY" },
        { id: 6, label: "SATURDAY" },
        { id: 7, label: "SUNDAY" },
      ],
    };
  },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.toggleModal("modalSchedule");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    minTime() {
      let dTime = this.schedule.time_from || "00:00";
      let contactDtime = "01/01/1970 " + dTime;
      var date = new Date(contactDtime);
      return date;
    },
  },
  methods: {
    verificateTime() {
      if (this.schedule.time_from == this.schedule.time_to) {
        this.showInfoSwal("equals");
      }
    },
    async editSchedule() {
      const result = await this.showConfirmSwal();

      if (result.isConfirmed) {
        this.date.push(this.schedule.date);
        this.addPreloader();
        let params = {
          schedule_id: this.schedule.schedule_id,
          date: this.schedule.date,
          time_from: this.schedule.time_from,
          time_to: this.schedule.time_to,
          priority: this.schedule.priority,
          user_id: this.currentUser.user_id,
        };
        try {
          const { data, status } = await NotesServices.editSchedule(params);
          if (data[0].type == "Error") {
            this.showInfoSwal(data[0].message);
          }
          if (data[0].type == "Success") {
            this.showInfoSwal(data[0].message);
            this.$emit("edit", true);
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
  },
};
</script>