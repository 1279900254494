var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-welcome-actions",attrs:{"hide-header":"","hide-footer":"","hide-header-close":"","no-close-on-backdrop":"","size":"sm","sticky-header":"300px"},on:{"hidden":function($event){return _vm.$refs['modal-welcome-actions'].hide()}}},[_c('b-card',{staticClass:"mt-2",attrs:{"bg-variant":_vm.isDarkSkin ? 'dark' : 'light'}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","flex-direction":"column"}},[_c('span',{staticClass:"mt-2 mb-2",staticStyle:{"font-weight":"bold","font-size":"15px"}},[_vm._v("ACTIONS ")]),_c('b-avatar',{staticStyle:{"width":"100px","height":"100px"}}),_c('div',{staticClass:"mt-2 mb-2",staticStyle:{"font-weight":"bold","font-size":"15px","align-items":"center","display":"flex","flex-direction":"column"}},[_c('span',[_vm._v(_vm._s(_vm.digitalClientData.lead_name))]),_c('div',{staticClass:"mb-2",staticStyle:{"font-weight":"bold","font-size":"15px","align-items":"center","display":"flex","flex-direction":"column"}},[_c('span',[_vm._v(_vm._s(_vm.digitalClientData.mobile))])]),_c('badge-risky-clients',{attrs:{"id-account":_vm.digitalClientData.client_account_id,"risk-level":_vm.digitalClientData.level,"risk-description":_vm.digitalClientData.comments,"risk-type":_vm.digitalClientData.type}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('b-alert',{attrs:{"show":_vm.dismissCountDown,"variant":"transparent"},on:{"dismiss-count-down":_vm.countDownChanged}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Time left: "+_vm._s(_vm.minDate))])])],1)],1)],1),_c('validation-observer',{ref:"form"},[_c('b-row',{staticClass:"justify-content-center"},[_vm._l((_vm.actionArray),function(act,i){return _c('b-col',{key:i,attrs:{"cols":"3"}},[_c('b-button',{style:("border-radius: 50%; width: 50px; height: 50px"),attrs:{"id":("act-target-" + i),"to":act.id === 1
                  ? {
                      name: 'ce-customer-service-dashboard',
                      params: {
                        idClient: _vm.digitalClientData.client_account_id,
                        hasAccess: true, // is alert
                        typeAccess: _vm.digitalClientData.alert_type, // Welcome,
                        wayAccess: 3, // enter by modal
                      },
                    }
                  : null,"size":"sm","variant":_vm.setVariant(act.id),"disabled":_vm.isSuccess === true},on:{"mouseover":function($event){act.id === 2
                  ? (_vm.upNotAvailable = true)
                  : (_vm.upNotAvailable = false)},"click":function($event){act.id !== 2 ? _vm.saveAction(act) : null}}},[_c('feather-icon',{style:({
                  left: "23px",
                  bottom: "10px",
                  position: "absolute",
                  color: _vm.isDarkSkin ? "#222222" : null,
                  transform: act.id === 2 ? "rotate(135deg)" : null,
                }),attrs:{"icon":act.icon,"size":"30"}})],1),_c('b-tooltip',{attrs:{"target":("act-target-" + i),"triggers":"hover","placement":"top"}},[_c('span',[_vm._v(_vm._s(act.action))])])],1)}),(_vm.upNotAvailable === true)?_c('b-col',{staticClass:"mt-2",attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Date"}},[_c('kendo-datepicker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],staticClass:"leads-datepicker w-100 rounded bg-transparent k-picker-custom picker-select-date",class:{ 'border-danger': errors[0] },attrs:{"id":"contactDate","format":'yyyy/MM/dd'},model:{value:(_vm.notAvailableData.date),callback:function ($$v) {_vm.$set(_vm.notAvailableData, "date", $$v)},expression:"notAvailableData.date"}})],1)]}}],null,false,1528125199)})],1):_vm._e(),(_vm.upNotAvailable === true)?_c('b-col',{staticClass:"mt-2",attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Time"}},[_c('kendo-timepicker',{staticClass:"w-100 rounded bg-transparent k-picker-custom",class:{ 'border-danger': errors[0] },staticStyle:{"height":"2.73rem"},attrs:{"format":'HH:mm',"minutes-step":"30"},model:{value:(_vm.notAvailableData.time),callback:function ($$v) {_vm.$set(_vm.notAvailableData, "time", $$v)},expression:"notAvailableData.time"}})],1)]}}],null,false,2642938167)})],1):_vm._e(),(_vm.upNotAvailable === true)?_c('b-col',{staticStyle:{"margin-top":"50px"},attrs:{"cols":"2"}},[_c('b-button',{staticStyle:{"position":"relative","right":"15px"},attrs:{"size":"sm","variant":"success","disabled":_vm.isSuccess === true},on:{"click":function($event){return _vm.saveAction({ id: 2 })}}},[_vm._v("Save")])],1):_vm._e()],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }