<template>
  <b-card
    header-text-variant="white"
    header-bg-variant="info"
    header-class="p-0"
    body-class="pt-1 card-body-border container-dependents"
  >
    <template #header>
      <div class="pl-1 pt-1">
        <p class="font-weight-bolder">Goals Information</p>
      </div>
    </template>
    <div class="d-flex justify-content-between">
      <b-button
        v-if="arrayGoals.length != 4 && !readOnly"
        variant="success"
        @click="addGoals()"
      >
        <feather-icon icon="PlusIcon" title="ADD NEW GOAL" />ADD GOAL
      </b-button>
    </div>
    <validation-observer ref="formGoals">
      <b-row>
        <b-col
          v-for="(goal, index) in arrayGoals"
          :key="goal.id"
          md="3"
          class="mt-1"
        >
          <div class="d-flex justify-content-between align-items-center">
            <label :for="`date-label-${index}`">{{ goal.text }}</label>
            <label v-if="index != 0 && index == arrayGoals.length - 1">
              <feather-icon
                v-if="!readOnly"
                icon="XCircleIcon"
                class="font-medium-3 text-danger cursor-pointer"
                @click="arrayGoals.splice(index, 1)"
              />
            </label>
          </div>
          <b-row>
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="goals"
              >
                <!-- the input was replaced by the v-select -->
                <b-form-group>
                  <v-select
                    :id="`date-label-${index}`"
                    v-model="goal.goal"
                    :options="optionsGoals"
                    :reduce="(item) => item.label"
                    :disabled="readOnly"
                    :class="{ 'border-danger': errors[0] }"
                  >
                  </v-select>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <validation-provider v-slot="{ errors }" rules="required">
            <label class="mt-1" :for="`date-goal-${index}`">Date</label>
            <b-form-datepicker
              :id="`date-goal-${index}`"
              v-model="goal.date"
              locale="en"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :disabled="readOnly"
              :class="{ 'border-danger': errors[0] }"
            />
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    arrayGoals: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  data() {
    return {
      optionsGoals: [
        { id: 1, label: "Negotiate Debts" },
        { id: 2, label: "Improve Credit" },
        { id: 3, label: "Apply for specific credits" },
        { id: 4, label: "Save Money" },
      ],
    };
  },
  methods: {
    async validateForm() {
      const isValidated = await this.$refs.formGoals.validate();
      return isValidated;
    },
    ordinalSuffixOf(i) {
      const j = i % 10;
      const k = i % 100;
      if (j === 1 && k !== 11) {
        return `${i}st`;
      }
      if (j === 2 && k !== 12) {
        return `${i}nd`;
      }
      if (j === 3 && k !== 13) {
        return `${i}rd`;
      }
      return `${i}th`;
    },
    addGoals() {
      this.arrayGoals.push({
        text: `${this.ordinalSuffixOf(this.arrayGoals.length + 1)} Goal`,
        goal: null,
        date: null,
      });
    },

    // async getQuestionnaireInfo() {
    //   try {
    //     this.addPreloader();
    //     const params = {
    //       lead_id: this.leadId,
    //     };

    //     const { data } = await CourtInfoService.getformationOfQuestionnaire(
    //       params
    //     );
    //     if (data.questionnaire) {
    //     }
    //   } catch (e) {
    //     this.showErrorSwal(e);
    //   } finally {
    //     this.removePreloader();
    //   }
    // },
  },
};
</script>

<style lang="scss">
.container-dependents {
  .p_desing2 {
    color: white;
    font-size: 13px;
    background-color: #b139ee;
    border-radius: 3px;
    padding: 8px 10px;
    text-align: center;
  }

  .select-custom {
    .vs__dropdown-toggle {
      border: 1px solid red;
    }
  }

  .min-mb {
    margin-bottom: 3px;
  }
}
</style>
