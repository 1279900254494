import { amgApi } from "@/service/axios";

class DocumentSettings {
    async getDocuments(params) {
        try {
            const { data } = await amgApi.post("/debt-solution/document-settings/get-document-settings", params);
            return data;
        } catch (error) {
            console.log("Error DocumentSettings: getDocuments");
            throw error;
        }
    }

    async getDocumentsById(params) {
        try {
            const { data } = await amgApi.post("/debt-solution/document-settings/get-document-settings-by-id", params);
            return data;
        } catch (error) {
            console.log("Error DocumentSettings: getDocumentsById");
            throw error;
        }
    }

    async createOrUpdateDocument(params) {
        try {
            const { data } = await amgApi.post("/debt-solution/document-settings/create-or-update-document-settings", params);
            return data;
        } catch (error) {
            console.log("Error DocumentSettings: createOrUpdateDocument");
            throw error;
        }
    }

    async deleteDocument(params) {
        try {
            const { data } = await amgApi.post("/debt-solution/document-settings/delete-document-settings", params);
            return data;
        } catch (error) {
            console.log("Error DocumentSettings: deleteDocument");
            throw error;
        }
    }

    async getDocumentActives(params) {
        try {
            const { data } = await amgApi.post("/debt-solution/document-settings/get-document-setting-actives", params);
            return data;
        } catch (error) {
            console.log("Error DocumentSettings: getDocumentActives");
            throw error;
        }
    }

    async getDocumentFormats(params) {
        try {
            const { data } = await amgApi.post("/debt-solution/document-settings/get-document-formats", params);
            return data;
        } catch (error) {
            console.log("Error DocumentSettings: getDocumentFormats");
            throw error;
        }
    }

    async getMessageConfigByIdCi(params) {
        try {
            const { data } = await amgApi.post("/debt-solution/get-message-config-by-id-ci", params);
            return data;
        } catch (error) {
            console.log("Error DocumentSettings: getMessageConfigByIdCi");
            throw error;
        }
    }
}

export default new DocumentSettings();