export default [
  {
    path: '/managment/payroll-payments',
    name: 'management-payroll-payments',
    component: () => import('@/views/commons/components/payroll-payments/PayrollPayments.vue'),
    // redirect: { name: 'management-payroll-AMG' },
    meta: {
      pageTitle: 'Payroll payments',
      breadcrumb: [
        {
          text: 'Payroll payments',
          active: true,
        },
      ],
      permittedRoles: [1, 2, 17],
    },
    // children: [
    //   {
    //     path: 'management-payroll-AMG',
    //     component: () => import('@/views/commons/components/payroll-payments/PayrollPaymentsList.vue'),
    //     name: 'management-payroll-AMG',
    //     meta: {
    //       pageTitle: 'AMG',
    //       breadcrumb: [
    //         {
    //           text: 'AMG',
    //           active: true,
    //         },
    //       ],
    //       permittedRoles: [1, 2, 17],
    //       payrollPaymentEmployeesAMG: 'management-payroll-AMG',
    //       payrollPaymentEmployeesAIG: 'management-payroll-AIG',
    //       tabRole: '1',
    //     },
    //   },
    //   {
    //     path: 'management-payroll-AIG',
    //     component: () => import('@/views/commons/components/payroll-payments/PayrollPaymentsList.vue'),
    //     name: 'management-payroll-AIG',
    //     meta: {
    //       pageTitle: 'AIG',
    //       breadcrumb: [
    //         {
    //           text: 'AIG',
    //           active: true,
    //         },
    //       ],
    //       permittedRoles: [1, 2, 17],
    //       payrollPaymentEmployeesAMG: 'management-payroll-AMG',
    //       payrollPaymentEmployeesAIG: 'management-payroll-AIG',
    //       tabRole: '4',
    //     },
    //   },
    // ],
  },
];
