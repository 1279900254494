import ZeroPayment from "@/views/debt-solution/views/zero-payment/router";
import ReportsRoute from "@/views/debt-solution/views/reports/reports.router";
import HelpdeskRoute from "@/views/debt-solution/views/help-desk/helpdesk.route";
import DebtSolutionMain from "@/views/debt-solution/views/DebtSolutionMain.vue";
import SettlementsRouter from "@/views/debt-solution/views/settlements/router/settlements.router";
import OtherPaymentRouter from "@/views/debt-solution/views/other-payments/other-payments.router";
import AttendanceRouter from "@/views/debt-solution/views/attendance/attendance.router";
import leadsRoute from "../views/leads/leads.router";
import Applications from "../views/applications/router";
import Appointment from "@/views/debt-solution/views/appointment/appointment.router.js";
import OtherServices from "@/views/debt-solution/views/other-services/other-services.router.js";
import CreditorsRoute from "../views/creditors/creditors.router";
import clientsRouter from "../views/clients/clients.router";
import PaymentsRouter from "../views/payments/router/payments.router";

import ChargeBackRoute from "../views/charge-back/charge-back.router";
import SalesMade from "../views/sales-made/sales-made.router";
import Claims from "../views/claims-v2/claims.router";

import SettingsRouter from "../views/settings/router/settings.router";
import Commissions from "../views/commissions/commissions.router";
import FileManager from "../views/file-manager/file-manager.router";
import PerformanceRouter from "../views/performance/performance.router";
import ProductRequestRouter from "@/views/debt-solution/views/request/request.router.js";
import EmployeeClaimsRouter from "@/views/debt-solution/views/employee-claims/router/employee-claims.router";
import DashboardRouter from "@/views/debt-solution/views/dashboard/router/dashboard.router";
import CallLogRouter from "@/views/debt-solution/views/ring-central/call-log/call-log.router.js";
import SpecialistRouter from "@/views/debt-solution/views/specialist/router/specialist.router.js";
import SendingReportRouter from "@/views/debt-solution/views/sending-report/sending-report.router.js";

import MOFRouter from "@/views/debt-solution/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/debt-solution/views/recruitment-process/debt-solution-recruitment_process.router";
import PermissionActionRouter from "@/views/debt-solution/views/permission-action/router/permission.router.js";
import CourtInfoRouter from "@/views/debt-solution/views/court-info/router/ds-court-info.router.js";
import DashboardCalls from "@/views/debt-solution/views/ring-central/dashboard-calls/dashboard.router.js";
import CustomerTicketsRouter from "@/views/debt-solution/views/customer-tickets/debt-solution-customer-tickets.router";
import PotencialAppointmentRouter from "@/views/debt-solution/views/potencial-appointment/potencial-appointment.route";
import CommissionsRouter from "@/views/debt-solution/views/commission-specialists/commissions-specialists.router";
import InconsistentTransactionRouter from '@/views/debt-solution/views/inconsistent-transactions/router/inconsistent-transactions.router.js';
import PendingPaymentsRouter from "@/views/debt-solution/views/pending-payments/router/pending-payments.router.js"
import CallImpressionRouter from "@/views/debt-solution/views/call-impression/router/call-impression.router.js";


const routes = [
  {
    path: "/debtsolution",
    meta: { module: 5, program: 4 },
    redirect: { name: "debt-solution-dashboard-main" },
    component: DebtSolutionMain,
    children: [
      ...DashboardRouter,
      ...leadsRoute,
      ...clientsRouter,
      ...SettlementsRouter,
      ...ReportsRoute,
      ...ZeroPayment,
      ...Applications,
      ...Appointment,
      ...OtherServices,
      ...CreditorsRoute,
      ...PaymentsRouter,
      ...ChargeBackRoute,
      ...SalesMade,
      ...Claims,
      ...SettingsRouter,
      ...Commissions,
      FileManager,
      HelpdeskRoute,
      ...PerformanceRouter,
      ...ProductRequestRouter,
      ...AttendanceRouter,
      ...OtherPaymentRouter,
      ...EmployeeClaimsRouter,
      ...CallLogRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...SpecialistRouter,
      ...PermissionActionRouter,
      ...CourtInfoRouter,

      ...DashboardCalls,

      ...CustomerTicketsRouter,
      ...SendingReportRouter,
      ...PotencialAppointmentRouter,
      ...CommissionsRouter,
      ...InconsistentTransactionRouter,
      ...PendingPaymentsRouter,
      ...CallImpressionRouter
    ],
  },
];

export default routes;
