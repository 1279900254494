export default [
    {
      path: "sending-reports",
      name: "debtsolution-sending-reports",
      redirect: { name: "debtsolution-sending-reports-in-proccess" },
      component: () => import(/* webpackChunkName: "CorrespondenceSendingReports" */ "@/views/correspondence/views/sending-reports/view/SendingReports.vue"),
      meta: {
        pageTitle: "Sending Reports",
        route: "debtsolution",
        breadcrumb: [
          {
            text: "Sending Reports",
            active: true,
          },
        ],
  
      },
      children: [
        {
          path: "in-proccess",
          component: () => import(/* webpackChunkName: "CorrespondenceSendingReportsInProccess" */ "@/views/correspondence/views/sending-reports/view/components/Grid.vue"),
          name: "debtsolution-sending-reports-in-proccess",
          route: "debtsolution",
          meta: {
            pageTitle: "Sending Reports",
            breadcrumb: [
              {
                text: "In Proccess",
                active: true,
              },
            ],
            inProccessTab:
              "debtsolution-sending-reports-in-proccess",
            sentTab: "debtsolution-sending-reports-sent",
  
          },
        },
        {
          path: "sent",
          component: () => import(/* webpackChunkName: "CorrespondenceSendingreportsSent" */ "@/views/correspondence/views/sending-reports/view/components/Grid.vue"),
          name: "debtsolution-sending-reports-sent",
          route: "debtsolution",
          meta: {
            pageTitle: "Sending Reports",
            breadcrumb: [
              {
                text: "Sent",
                active: true,
              },
            ],
            inProccessTab:
              "debtsolution-sending-reports-in-proccess",
            sentTab: "debtsolution-sending-reports-sent",
  
          },
        },
      ],
    },
  ];
  