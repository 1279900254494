import Vue from 'vue'; // To get the 'component' of 'Notification';
import store from '@/store'; // To get user(s)

const ManualOfFunctionsSocketRRHH = () => {

  window.socket.bind('send-mof-to-human-talent', async data => {
    const userModule = store.state.auth.currentUser.main_module;
    // show alert to RRHH status SENT TO HT
    if (userModule === 17 && data.status === 2) {
      await Vue.prototype.$amgAlert({
        title: 'MANUALS OF FUNCTIONS IN REVISION BY HT',
        icon: 'BellIcon',
        subtitle: `Employee ${data.created_by}`,
        data: [
          {
            icon: 'userIcon',
            title: `From ${data.department_name}`,
            subtitle: 'Received for HT',
            description: `MOF ${data.mof_name}, sender by ${data.created_by}`,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }

    // show alert to CEO status SENT TO CEO
    if (userModule === 1 && data.status === 4) {
      await Vue.prototype.$amgAlert({
        title: 'MANUALS OF FUNCTIONS IN REVISION BY CEO',
        icon: 'BellIcon',
        subtitle: `Employee ${data.created_by}`,
        data: [
          {
            icon: 'userIcon',
            title: `From ${data.department_name}`,
            subtitle: 'Received for HT',
            description: `MOF ${data.mof_name}, sender by ${data.created_by}`,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }

    // show alert to RRHH status APPROVE
    if (userModule === 17 && data.status === 5) {
      await Vue.prototype.$amgAlert({
        title: 'MANUALS OF FUNCTIONS APPROVED',
        icon: 'BellIcon',
        subtitle: `Employee ${data.created_by}`,
        data: [
          {
            icon: 'userIcon',
            title: `From ${data.department_name}`,
            subtitle: 'Received for HT',
            description: `MOF ${data.mof_name}, sender by ${data.created_by}`,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }

    // show alert to RRHH status DISAPPROVE
    if (userModule === 17 && data.status === 6) {
      await Vue.prototype.$amgAlert({
        title: 'MANUALS OF FUNCTIONS DISAPPROVED',
        icon: 'BellIcon',
        subtitle: `Employee ${data.created_by}`,
        data: [
          {
            icon: 'userIcon',
            title: `From ${data.department_name}`,
            subtitle: 'Received for HT',
            description: `MOF ${data.mof_name}, sender by ${data.created_by}`,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }

    // show alert to RRHH status DISAPPROVE
    if (userModule === 17 && data.status === 7) {
      await Vue.prototype.$amgAlert({
        title: 'MANUALS OF FUNCTIONS Review by CEO and HT',
        icon: 'BellIcon',
        subtitle: `Employee ${data.created_by}`,
        data: [
          {
            icon: 'userIcon',
            title: `From ${data.department_name}`,
            subtitle: 'Received for CEO',
            description: `MOF ${data.mof_name}, sender by ${data.created_by}`,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }
  });
};

export default ManualOfFunctionsSocketRRHH;
