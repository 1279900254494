<template>
  <b-card>
    <template #header>
      <b-card-title>DERIVATION NOTE</b-card-title>
    </template>
    <!--  derivation notes -->
    <b-row>
      <b-col md="6">
        <validation-provider v-slot="{errors}" name="derivation-note" rules="required">
          <b-form-group label="NOTE">
            <b-textarea
              id="derivation-note"
              v-model="userData.derivationNote"
              :state="errors[0] ? false : null"
            ></b-textarea>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
export default {
  name: "DerivationNoteLead",
  props: {
    userData: {
      //
    },
  },
}
</script>

<style scoped>

</style>