import Vue from 'vue';
import store from '@/store/index';
import router from '@/router';

const SendPayrollNotification = () => {
  window.socket.bind('send-payroll-notification', async response => {
    const currentSession = store.state.auth.currentUser.user_id;
    const userExists = response.users.some(user => user === currentSession);

    if (userExists) {
      const { isConfirmed } = await Vue.swal.fire({
        icon: 'success',
        title: 'Estimado AMGer, se ha generado la boleta de pago del mes.',
        text: 'Se te recuerda que aún tienes pendiente la firma de la boleta correspondiente al mes. Es crucial tener este documento firmado para llevar a cabo distintos procedimientos personales.\n'
                + '\n'
                + 'Por favor, gestiona la firma de tu boleta a la brevedad posible.',
      });

      if (isConfirmed) {
        await window.amgApi.post('/commons/close-all-swal', { to_id: currentSession });
        const directToPayroll = 1;

        router.push({ name: 'user-attendance-dashboard', params: { directToPayroll } });
      }
    }
  });
};
export default SendPayrollNotification;
