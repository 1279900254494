import { amgApi } from "@/service/axios";

class LeadService {


    async update(id, fields) {
        try {
            const data = await amgApi.patch(`/lead/${id}`, fields);
            return data;
        } catch (error) {
            console.log("Something went wrong on LeadService.update:", error);
            throw error;
        }
    }
}

export default new LeadService();
