export default {
    namespaced: true,
    state: {
        S_IS_ACTIVE: false,
        S_DATA_SEND_REPORT: null,
        S_TITLE: null,
        S_DETAIL_SEND_REPORT: null,
    },
    getters: {
        G_IS_ACTIVE(state) {
            return state.S_IS_ACTIVE;
        },
        G_DATA_SEND_REPORT(state) {
            return state.S_DATA_SEND_REPORT;
        },
        G_TITLE(state) {
            return state.S_TITLE;
        },
        G_DETAIL_SEND_REPORT(state) {
            return state.S_DETAIL_SEND_REPORT;
        },
    },
    mutations: {
        M_OPEN_ALERT(state, payload) {
            state.S_IS_ACTIVE = payload;
        },
        M_DATA_SEND_REPORT(state, payload) {
            state.S_DATA_SEND_REPORT = payload;
        },
        M_TITLE(state, payload) {
            state.S_TITLE = payload;
        },
        M_RESET(state) {
            state.S_IS_ACTIVE = false;
            state.S_TITLE = "";
            state.S_DATA_NCR = null;
        },
        M_DETAIL_SEND_REPORT(state, payload) {
            state.S_DETAIL_SEND_REPORT = payload;
        }
    }
}