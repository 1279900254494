import { amgApi } from "@/service/axios";
class OfficeHoursService {
    async setOfficeHours(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/set-office-hours", params);
            return data;
        } catch (error) {
            console.log("Error setOfficeHours");
        }
    }
    async getTrackingOfficeHours(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-tracking-office-hours", params);
            return data;
        } catch (error) {
            console.log("Error getTrackingOfficeHours");
        }
    }
    async getOfficeHoursPerDay() {
        try {
            const data = await amgApi.post("credit-experts-digital/get-office-hours-per-day");
            return data;
        } catch (error) {
            console.log("Error getOfficeHoursPerDay");
        }
    }
    async getDay(params) {
        try {
            const data = await amgApi.post("credit-experts-digital/get-day", params);
            return data.data;
        } catch (error) {
            console.log("Error getDay");
        }
    }
}
export default new OfficeHoursService();