<template>
  <div>
    <ModalTasksTodayCrm v-if="taskToday && isCrm" @hide="hideModal" />
 
    <ModalOfferPaymentOrderPriorityAlert
      v-if="S_VALIDATE_PAYMENT_PRIORITY_ALERT"
    >
      <template v-slot:header>
        <feather-icon
          icon="BellIcon"
          class="text-primary"
          style="height: 40%; width: 40%"
        />
        <p class="m-0 mt-2" style="font-size: 1.5rem">
          Offer Payments Orders In Process !
        </p>
      </template>

      <template v-slot:body>
        <b-row class="mx-1" style="font-size: 1.2rem">
          <b-col sm="12" md="12">
            <b-list-group>
              <b-list-group-item v-if="offerPaymentOrderAlert.high > 0">
                <feather-icon
                  icon="AlertTriangleIcon"
                  class="text-danger mr-1"
                  size="20"
                />
                <span class="text-danger">
                  <strong>{{ offerPaymentOrderAlert.high }}</strong>
                </span>
                offer payment orders with <strong>hight</strong> priority.
              </b-list-group-item>
              <b-list-group-item v-if="offerPaymentOrderAlert.medium > 0">
                <feather-icon
                  icon="AlertCircleIcon"
                  class="text-warning mr-1"
                  size="20"
                />
                <span class="text-warning">
                  <strong>{{ offerPaymentOrderAlert.medium }}</strong>
                </span>
                offer payment orders with <strong>medium</strong> priority.
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </template>

      <template v-slot:footer>
        <b-button variant="primary" @click="redirectSettlementsMade"
          >Go to Settlements Made (DS)</b-button
        >
        <b-button
          variant="outline-danger"
          class="ml-2"
          @click="S_VALIDATE_PAYMENT_PRIORITY_ALERT = false"
          >Cancel</b-button
        >
      </template>
    </ModalOfferPaymentOrderPriorityAlert>
 
    <ModalIsuficiencyOfundsManagement v-if="S_VALIDATE_BALANCE_BANK_ACCOUNT">
      <template v-slot:header>
        <feather-icon
          icon="AlertCircleIcon"
          class="text-warning"
          style="height: 40%; width: 40%"
        />
        <p class="m-0 mt-2" style="font-size: 1.5rem">
          Bank account with low funding!
        </p>
        <p class="m-0" style="font-size: 1rem; padding-top: 6px">
          Please deposit money.
        </p>
      </template>
      <template v-slot:body>
        <b-row
          v-for="(item, index) in S_DATA_BANK_ACCOUNT"
          :key="index"
          class="mx-1"
        >
          <b-col sm="6" md="3">
            <b-form-group label="Bank Name">
              <b-form-input
                v-model="item.bank_account_name"
                disabled /></b-form-group
          ></b-col>
          <b-col sm="6" md="3">
            <b-form-group label="Account Type">
              <b-form-input
                v-model="item.bank_account_type"
                disabled /></b-form-group
          ></b-col>
          <b-col sm="6" md="3">
            <b-form-group label="Balance">
              <money
                v-bind="vMoney"
                v-model="item.balance"
                class="form-control input-form"
                disabled
              ></money> </b-form-group
          ></b-col>
          <b-col sm="6" md="3">
            <b-form-group label="Available Balance">
              <div class="d-flex">
                <money
                  v-bind="vMoney"
                  v-model="item.available_balance"
                  class="form-control input-form border-w"
                  disabled
                >
                </money>
              </div> </b-form-group
          ></b-col>
        </b-row>
      </template>
      <template v-slot:footer>
        <b-button variant="primary" @click="goToBankAccounts"
          >Go to bank accounts</b-button
        >
        <b-button
          variant="outline-danger"
          class="ml-2"
          @click="S_VALIDATE_BALANCE_BANK_ACCOUNT = false"
          >Cancel</b-button
        >
      </template>
    </ModalIsuficiencyOfundsManagement>

    <ModalFewChecks v-if="S_VALIDATE_FEW_CHECKS">
      <template #header>
        <div style="height: 150px">
          <feather-icon
            icon="InfoIcon"
            class="text-warning"
            style="height: 60%; width: 60%"
          />
          <p class="mt-2" style="font-size: 1.5rem">
            The following checkbooks have less than 10 checks available:
          </p>
        </div>
      </template>
      <template #body>
        <b-table
          :fields="fewChecksFields"
          :items="S_DATA_FEW_CHECKS"
          show-empty
          empty-text="No records to view..."
        >
          <template #cell(account_bank_number)="data">
            <span>
              {{ data.item.bank_name }}
            </span>
            <br />
            <span>
              <small>
                {{ data.item.account_number }}
              </small>
            </span>
          </template>
          <template #cell(available_checks)="data">
            <span>
              <feather-icon
                :icon="iconFeather(data.item.available_checks)"
                size="20"
                class="mr-1"
                :class="iconClass(data.item.available_checks)"
              />
            </span>
            <span> {{ data.item.available_checks }}</span>
          </template>
        </b-table>
      </template>

      <template #footer>
        <b-button variant="primary" @click="goToBankAccounts"
          >Go to bank accounts</b-button
        >
        <b-button
          variant="outline-danger"
          class="ml-2"
          @click="S_VALIDATE_FEW_CHECKS = false"
        >
          Cancel
        </b-button>
      </template>
    </ModalFewChecks>

    <ModalNotificationOldQuestionnaire
      v-if="G_S_VALIDATE_OLD_QUESTIONNAIRE"
      @hidden="closeOldQuestionnaire"
    >
      <template v-slot:header>
        <feather-icon
          icon="AlertCircleIcon"
          class="text-warning"
          style="height: 40%; width: 40%"
        />
        <p class="m-0 mt-2" style="font-size: 1.5rem">
          Update the questionnaires of the following clients, a total of
          <span class="text-danger">{{ S_DATA_OLD_QUESTIONNAIRE.length }}</span>
        </p>
        <p class="m-0" style="font-size: 1rem; padding-top: 6px">
          Please update
        </p>
      </template>
      <template v-slot:body>
        <div class="mx-4">
          <b-table
            slot="table"
            :ref="`refSendingToCreditors`"
            small
            :items="S_DATA_OLD_QUESTIONNAIRE"
            :fields="fieldsTable"
            primary-key="id"
            table-class="text-nowrap"
            responsive="sm"
            show-empty
            sticky-header="35vh"
            scrollabe
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(months_elapsed)="data">
              <b-badge
                :variant="
                  data.item.months_elapsed <= 12
                    ? 'light-warning'
                    : 'light-danger'
                "
                pill
                class="w-100"
              >
                {{ data.item.months_elapsed }}
              </b-badge>
            </template>
            <template #cell(updated_at)="data">
              <div class="d-flex justify-content-center">
                <p>{{ data.item.updated_at | myGlobal }}</p>
              </div>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex justify-content-center">
                <feather-icon
                  icon="ArrowUpRightIcon"
                  class="text-primary mr-1 cursor pointer"
                  size="20"
                  @click="redirectOldquestionnaire(data.item.client_account_id)"
                  v-b-tooltip.hover.right="'Go To Update'"
                />
              </div>
            </template>
          </b-table>
        </div>
      </template>
    </ModalNotificationOldQuestionnaire>

    <ModalRescheduleAppointment v-if="S_VALIDATE_RESCHEDULE_APPOINTMENT">
      <template v-slot:header>
        <tabler-icon
          icon="CalendarEventIcon"
          class="text-success"
          style="height: 40%; width: 40%"
        />
        <p class="m-0 mt-2" style="font-size: 1.5rem">
          Rescheduled Appointment!
        </p>
        <p class="m-0" style="font-size: 1rem; padding-top: 4px">
          Lead:
          <span style="opacity: 0.6"
            >{{ S_DATA_RESCHEDULE_APPOINTMENT.lead_name }}.</span
          >
        </p>
        <p class="m-0" style="font-size: 1rem; padding-top: 4px">
          Location:
          <span style="opacity: 0.6"
            >{{ S_DATA_RESCHEDULE_APPOINTMENT.location }}.</span
          >
        </p>
        <p class="m-0" style="font-size: 1rem; padding-top: 4px">
          Date:
          <span style="opacity: 0.6"
            >{{
              S_DATA_RESCHEDULE_APPOINTMENT.reschedule_date | myGlobal
            }}.</span
          >
        </p>
      </template>
      <template v-slot:footer>
        <b-button
          v-if="isCurrentDay"
          variant="outline-primary"
          @click="openModalEditEventShow"
          >Attend</b-button
        >
        <b-button
          variant="outline-warning"
          class="ml-2"
          @click="redirectDashboardLead"
          >Go to Lead</b-button
        >
        <b-button
          variant="outline-danger"
          class="ml-2"
          @click="S_VALIDATE_RESCHEDULE_APPOINTMENT = false"
          >Cancel</b-button
        >
      </template>
    </ModalRescheduleAppointment>

    <b-modal
      id="modal-event-attend-from-notification"
      title-class="h2 text-white"
      ok-only
      modal-class="modal-primary"
      centered
      size="lg"
      :title="'TEL'"
      hide-footer
    >
      <ModalEventEdit
        :lead="S_LEAD"
        :event="event"
        :onlyRead="false"
        :is-from-lead-dashboard="true"
        @closeModal="closeModal"
      />
    </b-modal>
  </div>
</template>

<script>
import ModalTasksTodayCrm from "@/views/commons/components/modals-container/ModalTasksTodayCrm.vue"
import ModalIsuficiencyOfundsManagement from "@/views/commons/components/modals-container/ModalIsuficiencyOfundsManagement.vue"
import ModalRescheduleAppointment from "@/views/commons/components/modals-container/ModalRescheduleAppointment.vue";
import ModalEventEdit from "@/views/crm/views/Lead/lead-event/ModalEventEdit.vue";
import ModalNotificationOldQuestionnaire from "@/views/commons/components/modals-container/ModalIsuficiencyOfundsManagement.vue"
import ModalOfferPaymentOrderPriorityAlert from "@/views/commons/components/modals-container/ModalIsuficiencyOfundsManagement.vue"

import ModalFewChecks from "@/views/commons/components/modals-container/ModalIsuficiencyOfundsManagement.vue"

import { mapGetters, mapState, mapMutations, mapActions} from "vuex";
import BankAccountsService from "@/views/debt-solution/views/bank-account/bank-accounts.service";
import CrmEventService from "@/views/crm/services/event/index.js";

import { isUserLoggedIn } from "@/auth/utils";
import moment from "moment";

export default {
  components: {
    ModalTasksTodayCrm,
    ModalIsuficiencyOfundsManagement,
    ModalOfferPaymentOrderPriorityAlert,
    ModalNotificationOldQuestionnaire,
  },
  data() {
    return {
      modalUp: false,
      modalFewChecks: false,
      statusBalanceM: false,
      lead: null,
      titleModalEventEdit: "EVENT EDIT",
      blankEvent: {},
      event: {
        attend: null,
        date: "",
        from: "",
        id: null,
        location: "",
        real_time: "",
        title: "",
        to: "",
      },
      isuficiencyFunds: [],
      vMoney: {
        decimal: ".",
        thousand: ",",
        prefix: "$ ",
        precision: 2,
        min: 0.0,
      },
      showOfferPaymentOrderAlert: false,
      offerPaymentOrderAlert: {},
      fewChecksFields: [
        {
          key: "number",
          label: "NUMBER OF CHECKBOOK",
        },
        {
          key: "account_bank_number",
          label: "Account",
        },
        {
          key: "account_name",
        },
        {
          key: "available_checks",
          label: "CHECKS AVAILABLE",
        },
      ],
      itemsFewChecks: [],
      fieldsTable: [
        { key: "client_name", visible: true },
        { key: "account", visible: true },
        {
          key: "months_elapsed",
          thClass: "text-center",
          tdClass: "text-center",
          visible: true
        },
        {
          key: "updated_at",
          label: "last update",
          thClass: "text-center",
          visible: true
        },
        {
          key: "user_name",
          label: "Advisor",
          thClass: "text-center",
          visible: true
        },
        { key: "actions", visible: true },
      ],
    }
  },
  async created() {
    // console.log(this.S_ALERT_EMPLOYEE_CLAIMS, "S_ALERT_EMPLOYEE_CLAIMS")
    const isLoggedIn = isUserLoggedIn()
    if (isLoggedIn) {
      this.fieldsTable.find((f) => f.key === "user_name").visible = this.isSupervisor
      // execute if user is logged in
      this.setDataBlank("event");
      await this.hasPriorityOrderAlert();
      // await this.isuficiencyOfundsManagement();
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      moduleId: "auth/moduleId",
      userSession: "auth/userSession",
      G_S_VALIDATE_OLD_QUESTIONNAIRE : "modal-actions/G_S_VALIDATE_OLD_QUESTIONNAIRE",
    }),
    ...mapState({
      taskToday: (state) => state.TaskStore.S_TASK_TODAY_INITIAL,
      S_LEAD: (state) => state.CrmLeadStore.S_LEAD,
      S_DATA_BANK_ACCOUNT: (state) =>
        state["modal-actions"].S_DATA_BANK_ACCOUNT,
      S_DATA_FEW_CHECKS: (state) => state["modal-actions"].S_DATA_FEW_CHECKS,
      S_DATA_OLD_QUESTIONNAIRE: (state) =>
        state["modal-actions"].S_DATA_OLD_QUESTIONNAIRE,
    }),
    S_VALIDATE_BALANCE_BANK_ACCOUNT: {
      get() {
        return this.$store.state["modal-actions"]
          .S_VALIDATE_BALANCE_BANK_ACCOUNT
      },
      set(value) {
        this.$store.commit(
          "modal-actions/M_VALIDATE_BALANCE_BANK_ACCOUNT",
          value
        )
      },
    },
    S_VALIDATE_PAYMENT_PRIORITY_ALERT: {
      get() {
        return this.$store.state["modal-actions"]
          .S_VALIDATE_PAYMENT_PRIORITY_ALERT
      },
      set(value) {
        this.$store.commit(
          "modal-actions/M_VALIDATE_PAYMENT_PRIORITY_ALERT",
          value
        )
      },
    },
    S_VALIDATE_RESCHEDULE_APPOINTMENT: {
      get() {
        return this.$store.state["modal-actions"]
          .S_VALIDATE_RESCHEDULE_APPOINTMENT;
      },
      set(value) {
        this.$store.commit(
          "modal-actions/M_VALIDATE_RESCHEDULE_APPOINTMENT",
          value
        );
      },
    },
    S_DATA_RESCHEDULE_APPOINTMENT: {
      get() {
        return this.$store.state["modal-actions"].S_DATA_RESCHEDULE_APPOINTMENT;
      },
      set(value) {
        this.$store.commit(
          "modal-actions/M_SET_DATA_RESCHEDULE_APPOINTMENT",
          value
        );
      },
    },
    S_VALIDATE_RESCHEDULE_APPOINTMENT: {
      get() {
        return this.$store.state["modal-actions"]
          .S_VALIDATE_RESCHEDULE_APPOINTMENT;
      },
      set(value) {
        this.$store.commit(
          "modal-actions/M_VALIDATE_RESCHEDULE_APPOINTMENT",
          value
        );
      },
    },
    S_DATA_RESCHEDULE_APPOINTMENT: {
      get() {
        return this.$store.state["modal-actions"].S_DATA_RESCHEDULE_APPOINTMENT;
      },
      set(value) {
        this.$store.commit(
          "modal-actions/M_SET_DATA_RESCHEDULE_APPOINTMENT",
          value
        );
      },
    },
    S_VALIDATE_FEW_CHECKS: {
      get() {
        return this.$store.state["modal-actions"].S_VALIDATE_FEW_CHECKS
      },
      set(value) {
        this.$store.commit("modal-actions/M_VALIDATE_FEW_CHECKS", value)
      },
    },
    S_VALIDATE_OLD_QUESTIONNAIRE: {
      get() {
        return this.$store.state["modal-actions"].S_VALIDATE_OLD_QUESTIONNAIRE
      },
      set(value) {
        this.$store.commit("modal-actions/M_VALIDATE_OLD_QUESTIONNAIRE", value)
      },
    },
    // S_ALERT_EMPLOYEE_CLAIMS: {
    //   get() {
    //     return this.$store.state["EmployeeClaimsStore"].S_ALERT_EMPLOYEE_CLAIMS
    //   },
    //   set(value) {
    //     this.$store.commit("EmployeeClaimsStore/M_ALERT_EMPLOYEE_CLAIMS", value)
    //   },
    // },

    isManagement() {
      return this.$route.matched[0].meta.module === 16
    },
    isCrm() {
      return this.moduleId == 2
    },
    isManagement() {
      return this.moduleId == 16
    },
    visibleFieldsTable() {
      return this.fieldsTable.filter((field) => field.visible)
    },
    isCurrentDay() {
      return (
        moment(this.S_DATA_RESCHEDULE_APPOINTMENT.reschedule_date).format(
          "YYYY-MM-DD"
        ) == moment().format("YYYY-MM-DD")
      );
    },
  },
  methods: {
    ...mapMutations({
      setTaskToday: "TaskStore/M_TASK_TODAY",
    }),
    ...mapActions({
      A_GET_EVENT: "CrmEventStore/A_GET_EVENT",
      A_GET_LEAD: "CrmLeadStore/A_GET_LEAD",
    }),
    closeModal() {
      this.$bvModal.hide("modal-event-edit");
    },
    hideModal() {
      this.showModalTaskToday = false
      this.setTaskToday(false)
    },
    setDataBlank(key) {
      this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`] = {
        ...this[key],
      };
    },
    resetData(key) {
      this[key] = {
        ...this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`],
      };
    },
    async getLead() {
      try {
        this.isPreloading(true);
        await this.A_GET_LEAD({
          id: this.S_DATA_RESCHEDULE_APPOINTMENT.lead_id,
        });
        this.isPreloading(false);
      } catch (error) {
        console.log("Something went wrong getLead", error);
        this.showErrorSwal();
        this.isPreloading(false);
      }
    },
    closeOldQuestionnaire() {
      this.S_VALIDATE_OLD_QUESTIONNAIRE = false
    },
    redirectOldquestionnaire(data) {
      this.$router.push({
        name: "ds-questionnaire",
        params: { idClient: data },
      });
      this.S_VALIDATE_OLD_QUESTIONNAIRE = false;
    },

    async hasPriorityOrderAlert() {
      await this.$store.dispatch(
        "SettlementStore/GET_COUNTER_PRIORITY_ORDER_ALERT"
      )
      const data =
        this.$store.getters["SettlementStore/S_COUNTER_PRIORITY_ORDER_ALERT"]
      if (data.high > 0 || data.medium > 0) {
        this.offerPaymentOrderAlert = data
      }
    },

    async getCheckbooksWithFewChecks() {
      try {
        const { data, status } =
          await BankAccountsService.getCheckbooksWithFewChecks()
        if (status == 200) {
          this.itemsFewChecks = data
          this.modalFewChecks = true
        }
      } catch (error) {
        console.log(error)
      }
    },

    redirectSettlementsMade() {
      this.S_VALIDATE_PAYMENT_PRIORITY_ALERT = false
      this.$router
        .push({ name: "debtsolution-settlements-to-pay" })
        .catch((err) => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            throw err
          }
        })
    },
    goToBankAccounts(){
      this.S_VALIDATE_BALANCE_BANK_ACCOUNT = false;
      this.$router
        .push({ name: "debt-solution-settings-bank-accounts" })
        .catch((err) => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            throw err;
          }
        });
    },
    redirectBancAccount() {
      this.S_VALIDATE_BALANCE_BANK_ACCOUNT = false;
      this.$router
        .push({ name: "debtsolution-settlements-to-pay" })
        .catch((err) => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            throw err;
          }
        });
    },
    redirectDashboardLead() {
      this.S_VALIDATE_RESCHEDULE_APPOINTMENT = false;
      this.$router
        .push({
          name: "lead-show",
          params: { id: this.S_DATA_RESCHEDULE_APPOINTMENT.lead_id },
        })
        .catch((err) => {
          if (
            err.name !== "NavigationDuplicated" &&
            !err.message.includes(
              "Avoided redundant navigation to current location"
            )
          ) {
            throw err;
          }
        });
    },
    iconClass(available_checks) {
      if (available_checks < 5) return "text-danger"
      if (available_checks < 10) return "text-warning"
    },
    iconFeather(available_checks) {
      if (available_checks < 5) return "AlertTriangleIcon"
      if (available_checks < 10) return "AlertCircleIcon"
    },
  },
}
</script>

<style scoped>
.border-w {
  border: 1px solid #ff9f43 !important;
  border-color: #ff9f43 !important;
}
</style>
