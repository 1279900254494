

export default [
  {
    path: 'dashboard-tracking',
    name: 'ced-settings-dashboard-tracking',
    component: () => import(/* webpackChunkName: "CEDCustomerServiceDashboardTracking" */ "@/views/ce-digital/sub-modules/customer-service/views/dashboard-tracking/DashboardTracking.vue"),
    meta: {
      route: 'settings',
      permittedRoles: [1, 2, 14, 17],
      pageTitle: 'Dashboard Tracking',
      breadcrumb: [
        {
          text: 'Dashboard Tracking',
          active: true,
        },
      ],
    },
  },
]
