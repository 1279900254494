export default
  {
    path: 'settings',
    name: 'sn-settings',
    component: () => import(/* webpackChunkName: "SnSettings" */ '@/views/social-network/views/settings/Settings.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [{
        text: 'Settings',
      }],
      permittedRoles: [1, 2, 11],
    },
    redirect: { name: 'sn-s-motives' },
    children: [
      {
        path: 'reasons',
        name: 'sn-s-motives',
        component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/social-network/views/settings/components/motives/SettingsMotives.vue'
        ),
        meta: {
          pageTitle: 'Settings',
          breadcrumb: [
            {
              text: 'Reasons',
              active: true
            }
          ]
        }
      },
      {
        path: 'sources',
        name: 'sn-s-source',
        redirect: { name: 'sn-s-source-list' },
        component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/social-network/views/settings/components/tab-source/SettingsSource.vue'
        ),
        meta: {
          pageTitle: 'Settings',
          permittedRoles: [1, 11],
          breadcrumb: [
            {
              text: 'Sources',
              active: true
            }
          ]
        },
        children: [
          {
            path: 'list',
            name: 'sn-s-source-list',
            component: () => import(
            /* webpackChunkName: "SnSettingsMotives" */ '@/views/social-network/views/settings/components/tab-source/SourceTab.vue'
            ),
            meta: {
              tab: 1,
              pageTitle: 'Settings',
              breadcrumb: [
                {
                  text: 'Source',
                },
                {
                  text: 'List',
                  active: true
                }
              ]
            }
          },
          {
            path: 'contact-methods',
            name: 'sn-s-source-contact-methods',
            component: () => import(
            /* webpackChunkName: "SnSettingsMotives" */ '@/views/social-network/views/settings/components/tab-source/SourceTab.vue'
            ),
            meta: {
              tab: 2,
              pageTitle: 'Settings',
              breadcrumb: [
                {
                  text: 'Source',
                },
                {
                  text: 'Contact Methods',
                  active: true
                }
              ]
            }
          },
        ]
      },
      {
        path: 'team-rank',
        name: 'sn-s-team-rank',
        component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/social-network/views/settings/components/team-rank/SettingsTeamRank.vue'
        ),
        meta: {
          pageTitle: 'Settings',
          permittedRoles: [1, 11],
          breadcrumb: [
            {
              text: 'Team Rank',
              active: true
            }
          ]
        }
      },
      {
        path: 'appointment-sms',
        name: 'sn-s-appointment-sms',
        component: () => import(
        /* webpackChunkName: "SettingsAppointmentSms" */ '@/views/social-network/views/settings/components/appointment-sms/SettingsAppointmentSms.vue'
        ),
        meta: {
          pageTitle: 'Settings',
          permittedRoles: [1, 11],
          breadcrumb: [
            {
              text: 'Appointment SMS',
              active: true
            }
          ]
        }
      }
    ]
  }
