import store from "@/store";

export const newRiskClientNotification = () => {
  window.socket.bind(
    "new-client-risk-notification",
    async ({
      title,
      qualityUsers,
      clientData,
      modules,
    }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];

      if(clientData.length == 0) return

      if (
        qualityUsers.some((user) => user.id == user_id) &&
        current_module_id == modules[0]
      ) {

        store.commit("AutoAlertRc/M_OPEN_ALERT_RC", true);
        store.commit("AutoAlertRc/M_DATA_RC", clientData);
        store.commit("AutoAlertRc/M_TITLE_RC", title);
      }
    }
  );
};

export const CloseAllRcNotification = () => {
  window.socket.bind("client-close-all-risk-client-notification", async (data) => {
    const { user_id } = store.getters["auth/currentUser"];
    if (data.user_id == user_id) {
      store.commit("AutoAlertRc/M_OPEN_ALERT_RC", true);
      store.commit("AutoAlertRc/M_DATA_RC", null);
      store.commit("AutoAlertRc/M_TITLE_RC", null);
    }
  });
};
