<template>
  <validation-observer ref="form">
    <b-modal
      v-model="modal.observe"
      :title="title"
      title-class="h3 text-white font-weight-bolder"
      size="lg"
      no-close-on-backdrop
      modal-class="modal-primary"
    >
      <b-container fluid>
        <program-client-header
          :client="observe.nameClient"
          :program="observe.nameProgram"
          class="sticky-top mt-1"
        />

        <b-row>
          <validation-provider
            v-slot="{ errors }"
            name="typeOfAgreement"
            rules="required"
            class="w-100 mx-1 mb-1"
          >
            <h6 class="mt-2">Observation</h6>
            <b-form-textarea
              id="textarea-1"
              v-model="observation"
              placeholder="Enter observation..."
              rows="5"
              max-rows="6"
              :class="{ 'border-danger': errors[0] }"
            />
          </validation-provider>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button
          :disabled="disabledButton"
          :variant="observe.type === 8 ? 'warning' : 'danger'"
          @click="processSale"
          >{{ buttonText }}</b-button
        >
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import ProgramClientHeader from "@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import store from "@/store";
import { amgApi } from "@/service/axios";
import SalesMadeService from "@/views/crm/views/sales-made/services/sales-made.service";
import { mapGetters } from "vuex";

export default {
  name: "ObserveModal",
  components: { ProgramClientHeader, vSelect },
  props: {
    modal: {
      required: true,
      type: Object,
    },
    observe: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      advisor: [],
      advisorId: null,
      sendSms: false,
      observation: "",
      disabledButton: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    title() {
      if (this.observe.type === 8) return "Underview";
      if (this.observe.type === 9) return "Rejected By Program";
      if (this.observe.type === 10) return "Rejected By MG";
      return "";
    },
    buttonText() {
      if (this.observe.type === 8) return "Underview";
      if (this.observe.type === 9) return "Rejected";
      if (this.observe.type === 10) return "Rejected";
      return "";
    },
    flexCenter: function () {
      return "d-flex justify-content-center align-items-center";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    S_COUNTER_DISAPPROVED_SALE: {
      get() {
        return this.$store.state["SellerPermissionsStore"]
          .S_COUNTER_DISAPPROVED_SALE;
      },
      set(value) {
        this.$store.commit(
          "SellerPermissionsStore/M_COUNTER_DISAPPROVED_SALE",
          value
        );
      },
    },
  },
  methods: {
    async processSale() {
      const response = await this.$refs.form.validate();
      if (response) {
        if (this.observe.type == 8) {
          await this.observeSale();
        } else if (this.observe.type == 9 || this.observe.type == 10) {
          await this.rejecteSale();
        }
      }
    },
    async rejecteSale() {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          this.disabledButton = true;
          const params = {
            leadid: this.observe.leadId,
            observation: this.observation,
            role_id: this.currentUser.role_id,
            sale_id: this.observe.idsales,
            state: this.observe.type,
            user_id: this.observe.user_id,
            type: this.observe.sale_type,
            moduleAction: this.moduleId, // MG 16
          };
          const response = await SalesMadeService.rejecteSale(params);
          this.$emit("hideModal", true);
          this.showSuccessSwal("Success", "Success Operation");
          if (this.moduleId == 16) {
            this.S_COUNTER_DISAPPROVED_SALE--;
          }
        }
      } catch (error) {
        this.showToast("danger", "top-right", "Error", "XIcon", error);
      } finally {
        this.disabledButton = false;
        this.removePreloader();
      }
    },
    async observeSale() {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          this.disabledButton = true;
          const params = {
            leadid: this.observe.leadId,
            observation: this.observation,
            role_id: this.currentUser.role_id,
            sale_id: this.observe.idsales,
            state: this.observe.type,
            user_id: this.observe.user_id,
            type: this.observe.sale_type,
            lead: this.observe.nameClient,
            mobile: this.observe.mobile,
          };

          const response = await SalesMadeService.observeSale(params);

          this.$emit("hideModal", true);
          this.showSuccessSwal("Success", "Success Operation");
        }
      } catch (error) {
        this.showToast("danger", "top-right", "Error", "XIcon", error);
      } finally {
        this.disabledButton = false;
        this.removePreloader();
      }
    },
  },
  mounted() {},
};
</script>

