export default [
  {
    path: 'manual-of-functions',
    name: 'manual-of-functions-court-info',
    redirect: { name: 'manual-of-functions-court-info-redirect' },
    component: () => import(
      /* webpackChunkName: "Managment MOF" */
      '@/views/commons/components/manual-of-functions/TabManualsOfFunctions.vue'
    ),
    children: [
      {
        path: 'create-mof',
        name: 'manual-of-functions-court-info-redirect',
        component: () => import(
          /* webpackChunkName: "mof-all" */
          '@/views/commons/components/manual-of-functions/ManualOfFunctions.vue'
        ),
        meta: {
          permittedRoles: [1, 2, 17],
          pageTitle: 'Manuals Of Functions',
          breadcrumb: [
            {
              text: 'List Of mofs',
              // active: true,
            },
          ],

          tab: 1,
          create_mof: 'manual-of-functions-court-info-redirect',
          review_mof: 'review-mof-by-court-info-redirect',
          review_mof_by_ceo: 'review-mof-by-ceo-court-info-redirect',
        },
      },
      {
        path: 'review-mof',
        name: 'review-mof-by-court-info-redirect',
        component: () => import(
          /* webpackChunkName: "mof-all" */
          '@/views/commons/components/manual-of-functions/ManualOfFunctions.vue'
        ),
        meta: {
          permittedRoles: [1, 2, 17],
          pageTitle: 'Manuals Of Functions',
          // permittedRoles: [],
          breadcrumb: [
            {
              text: 'Review mofs',
              // active: true,
            },
          ],
          tab: 2,
          create_mof: 'manual-of-functions-court-info-redirect',
          review_mof: 'review-mof-by-court-info-redirect',
          review_mof_by_ceo: 'review-mof-by-ceo-court-info-redirect',
        },
      },

      {
        path: 'review-mof-by-ceo',
        name: 'review-mof-by-ceo-court-info-redirect',
        component: () => import(
          /* webpackChunkName: "mof-all" */
          '@/views/commons/components/manual-of-functions/ManualOfFunctions.vue'
        ),
        meta: {
          permittedRoles: [1, 2, 17],
          pageTitle: 'Manuals Of Functions',
          // permittedRoles: [],
          breadcrumb: [
            {
              text: 'Review mofs',
              active: true,
            },
          ],
          tab: 3,
          create_mof: 'manual-of-functions-court-info-redirect',
          review_mof: 'review-mof-by-court-info-redirect',
          review_mof_by_ceo: 'review-mof-by-ceo-court-info-redirect',
        },
      },
    ],
  },
];
