export default {
  path: "ticket/",
  name: "helpdesk-ti",
  component: () => import(/* webpackChunkName: "PRTicket" */ "./Helpdesk.vue"),
  meta: {
    pageTitle: "Help Desk",
    breadcrumb: [
      {
        text: "Help Desk",
        active: true,
      },
    ],
    // onlyChief: true,
    permittedRoles: [2,11,17]
  },
}
