var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"formOther"},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('label',[_vm._v("Type of payment")]),_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-40"},[_c('b-input-group-text',{staticClass:"title_lable w-100"},[_vm._v(" TYPE ")])],1),(!_vm.disableIp)?_c('b-select',{attrs:{"name":"type","options":_vm.optionsMethods,"value-field":"value","text-field":"name"},on:{"change":function($event){return _vm.changeOptionType($event)}},model:{value:(_vm.methodSelected),callback:function ($$v) {_vm.methodSelected=$$v},expression:"methodSelected"}}):_c('b-form-input',{attrs:{"name":"type","value":_vm.nameOtherPayment,"disabled":""}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger float-right"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),(_vm.methodSelected != 2)?[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('label',[_vm._v("Serial number")]),_c('validation-provider',{attrs:{"name":"serial_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-40"},[_c('b-input-group-text',{staticClass:"title_lable w-100"},[_vm._v(" SERIAL NUMBER ")])],1),_c('b-form-input',{attrs:{"name":"serial_number","maxlength":"15"},on:{"input":function($event){_vm.serialNumber = _vm.serialNumber.toUpperCase()}},model:{value:(_vm.serialNumber),callback:function ($$v) {_vm.serialNumber=$$v},expression:"serialNumber"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger float-right"},[_vm._v(" Serial number "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,1044250072)})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('label',[_vm._v("Is the client the holder?")]),_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-40"},[_c('b-input-group-text',{staticClass:"title_lable w-100"},[_vm._v(" NO "),_c('b-form-checkbox',{staticClass:"check-custom-other",attrs:{"name":"check-button","switch":""},model:{value:(_vm.isClientOwner),callback:function ($$v) {_vm.isClientOwner=$$v},expression:"isClientOwner"}}),_vm._v(" YES ")],1)],1),_c('b-form-input',{attrs:{"name":"isClientOwner","disabled":_vm.isClientOwner},model:{value:(_vm.owner),callback:function ($$v) {_vm.owner=$$v},expression:"owner"}})],1),_c('validation-provider',{attrs:{"name":"check-button","rules":!_vm.isClientOwner ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"hidden"},[_c('b-form-input',{attrs:{"name":"isClientOwner"},model:{value:(_vm.owner),callback:function ($$v) {_vm.owner=$$v},expression:"owner"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger float-right"},[_vm._v(" Holder "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,322830245)})],1)]:_vm._e(),(_vm.methodSelected == 5 || _vm.methodSelected == 6)?[_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('label',[_vm._v("Date of payment")]),_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-40"},[_c('b-input-group-text',{staticClass:"title_lable w-100"},[_vm._v(" DATE ")])],1),_c('b-form-datepicker',{attrs:{"date-format-options":{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger float-right"},[_vm._v(" Date "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,1336967226)})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"6"}},[_c('label',[_vm._v("Hour of payment")]),_c('validation-provider',{attrs:{"name":"PROGRAM","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-40"},[_c('b-input-group-text',{staticClass:"title_lable w-100"},[_vm._v(" HOUR ")])],1),_c('b-form-timepicker',{attrs:{"locale":"en"},model:{value:(_vm.hour),callback:function ($$v) {_vm.hour=$$v},expression:"hour"}})],1),(errors.length > 0)?_c('small',{staticClass:"text-danger float-right"},[_vm._v(" Hour "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,3590073779)})],1)]:_vm._e(),(_vm.methodSelected > 2)?[_c('b-col',{attrs:{"md":"6"}},[_c('label',[_vm._v("Upload the receipt")]),_c('validation-provider',{attrs:{"name":"file","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"w-40"},[_c('b-input-group-text',{staticClass:"title_lable w-100"},[_vm._v(" IMAGE ")])],1),_c('b-form-group',{staticClass:"form-control",style:(_vm.isDarkSkin ? 'background-color: transparent !important' : '')},[_c('b-form-file',{staticStyle:{"position":"inherit"},on:{"input":_vm.fileFill},model:{value:(_vm.fileToUpload),callback:function ($$v) {_vm.fileToUpload=$$v},expression:"fileToUpload"}})],1)],1),(errors.length > 0)?_c('small',{staticClass:"text-danger float-right"},[_vm._v(" File "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,false,3948381983)})],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }