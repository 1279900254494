import store from "@/store"
export default [
  {
    path: "employee-claims",
    name: "specialist-d-employee-claims",
    redirect: { name:  "specialist-d-employee-claims-list" },
    component: () =>
      import(
        /* webpackChunkName: "specialist-d-Employee-claims" */ "@/views/commons/employee-claims/EmployeeClaims.vue"
      ),
    meta: {
      pageTitle: "Staff Claims",
      breadcrumb: [
        {
          text: "Staff Claims",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "others-claims",
        name: "specialist-d-employee-claims-others-claims",
        component: () =>
          import(
            /* webpackChunkName: "specialist-d-Employee-claims-grid" */ "@/views/commons/employee-claims/components/ListClaims.vue"
          ),
        meta: {
          pageTitle: "Staff Claims",
          breadcrumb: [
            {
              text: "All Claims",
              active: true,
            },
          ],
          tabOthersClaimsRoute: 'specialist-d-employee-claims-others-claims',
          tabEmployeeClaimListRoute: 'specialist-d-employee-claims-list',
        },
      },
      {
        path: "",
        name: "specialist-d-employee-claims-list",
        component: () =>
          import(
            /* webpackChunkName: "specialist-d-Employee-claims-grid" */ "@/views/commons/employee-claims/components/Grid.vue"
          ),
        meta: {
          pageTitle: "Staff Claims",
          breadcrumb: [
            {
              text: "My Claims",
              active: true,
            },
          ],
          tabOthersClaimsRoute: 'specialist-d-employee-claims-others-claims',
        },
      },

    ],
  },
];
