import { nestApi } from "@/service/axios";
class MessengerMetaService {
    async getInstagramMessages(payload) {
        try {
            const data = await nestApi.post(`/instagram/messages/index`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getFacebookPages() {
        try {
            const data = await nestApi.get(`/meta/facebook/page`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getInstagramSidebarComments(payload) {
        try {
            const data = await nestApi.post(`meta/instagram/post`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getInstagramCommentChat(payload) {
        try {
            const data = await nestApi.post(`meta/instagram/comment`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getFacebookSidebarComments(payload) {
        try {
            const data = await nestApi.post(`/meta/facebook/post`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getFacebookCommentChat(payload) {
        try {
            const data = await nestApi.post(`/meta/facebook/comment`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async storeFacebookComment(payload) {
        try {
            const data = await nestApi.post(`meta/facebook/comment/store`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getMessengerUnreadChatsCount() {
        try {
            const data = await nestApi.get(`/facebook/chats/unread-count`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getInstagramUnreadChatsCount() {
        try {
            const data = await nestApi.get(`/instagram/chats/unread-count`);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getPendingClassifications(payload) {
        try {
            const data = await nestApi.post(`/facebook/chats/classification`, payload);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getRateLimit() {
        try {
            const data = await nestApi.get(`meta/rate-limit`);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default new MessengerMetaService();