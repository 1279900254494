/* eslint-disable class-methods-use-this */
import { amgApi } from "@/service/axios";

// General services on module MetaMedia after refactoring
class EmployeesService {
  async getAllDepartments(body) {
    try {
      const data = await amgApi.get(
        "management/employees/get-all-departments",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getAllDepartments:", error);
      throw error;
    }
  }

  async getEmployees(body, page) {
    try {
      const data = await amgApi.post(
        `management/employees/get-employess?page=${page}`,
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getEmployees:", error);
      throw error;
    }
  }

  async getAllCompanies(body) {
    try {
      const data = await amgApi.post(
        "management/companies/get-companies-payroll-active",
        body
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log("Something went wrong on getEmployees:", error);
      throw error;
    }
  }

  async getAllPaymentInformationHistory(body) {
    try {
      const data = await amgApi.post(
        "management/companies/get-payment-info-history",
        body
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log("Something went wrong on getEmployees:", error);
      throw error;
    }
  }

  async getAllPensionFund(body) {
    try {
      const data = await amgApi.post(
        "management/companies/get-pension-fund",
        body
      );
      // console.log(data);
      return data;
    } catch (error) {
      console.log("Something went wrong on getEmployees:", error);
      throw error;
    }
  }

  async getAllBanks(body) {
    try {
      const data = await amgApi.post("management/companies/get-banks", body);
      // console.log(data);
      return data;
    } catch (error) {
      console.log("Something went wrong on getEmployees:", error);
      throw error;
    }
  }

  async postBank(body) {
    try {
      const data = await amgApi.post("management/companies/post-banks", body);
      // console.log(data);
      return data;
    } catch (error) {
      console.log("Something went wrong on getEmployees:", error);
      throw error;
    }
  }

  async getEmployeesScheduleStatus(body) {
    try {
      const data = await amgApi.post(
        "attendance/get-employees-schedule-status",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getEmployeesScheduleStatus:", error);
      throw error;
    }
  }

  async getActiveEmployeesWithIdUser(body) {
    try {
      const data = await amgApi.post(
        "management/employees/get-active-employees-with-id-user",
        body
      );
      return data;
    } catch (error) {
      console.error(
        "Something went wrong on getActiveEmployeesWithIdUser:",
        error
      );
      throw error;
    }
  }

  async getDataByDni(body) {
    try {
      const data = await amgApi.post(
        "management/employees/get-data-by-dni",
        body
      );
      return data;
    } catch (error) {
      console.error(
        "Something went wrong on getActiveEmployeesWithIdUser:",
        error
      );
      throw error;
    }
  }

  async generateEmployeeQrCode(body) {
    try {
      const data = await amgApi.post(
        "management/employees/get-employee-qr-code",
        body,
        { responseType: "blob" }
      );
      return data;
    } catch (error) {
      console.error("Something went wrong on getEmployeeQrCode:", error);
    }
    return [];
  }

  async getDepartments(body) {
    try {
      const data = await amgApi.post(
        "management/employees/get-departments",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getDepartments:", error);
      throw error;
    }
  }

  async getAllDocuments() {
    try {
      const data = await amgApi.get("management/employees/get-all-documents");
      return data;
    } catch (error) {
      console.log("Something went wrong on getAllDocuments:", error);
      throw error;
    }
  }

  async getAllMartial() {
    try {
      const data = await amgApi.get("management/employees/get-all-martial");
      return data;
    } catch (error) {
      console.log("Something went wrong on getAllMartial:", error);
      throw error;
    }
  }

  async getAllRelations() {
    try {
      const data = await amgApi.get("management/employees/get-all-relations");
      return data;
    } catch (error) {
      console.log("Something went wrong on getAllRelations:", error);
      throw error;
    }
  }

  async getRolByDepartment(body) {
    try {
      const data = await amgApi.post(
        "management/employees/roles-departament",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getRolByDepartment:", error);
      throw error;
    }
  }

  async createEmployee(body) {
    try {
      const data = await amgApi.post(
        "management/employees/create-employee",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on createEmployee:", error);
      throw error;
    }
  }

  async getEmployeeDashboard(body) {
    try {
      const data = await amgApi.post(
        "management/employees/get-headboard",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getEmployeeDashboard:", error);
      throw error;
    }
  }

  async deleteEmployee(body) {
    try {
      const data = await amgApi.post(
        "management/employees/delete-employee",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteEmployee:", error);
      throw error;
    }
  }

  async getEmployeeInformation(body) {
    try {
      const data = await amgApi.post(
        "management/employees/get-employee-information",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getEmployeeInformation:", error);
      throw error;
    }
  }

  async getJobDetailsInformation(body) {
    try {
      const data = await amgApi.post(
        "management/employees/get-jobdetails-information",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getJobDetailsInformation:", error);
      throw error;
    }
  }

  async updateEmployee(body) {
    try {
      const data = await amgApi.post(
        "management/employees/update-employee",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on updateEmployee:", error);
      throw error;
    }
  }

  async updateJobDetails(body) {
    try {
      const data = await amgApi.post(
        "management/employees/update-jobdetails",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on updateJobDetails:", error);
      throw error;
    }
  }

  async getTrackingJobDetails(body) {
    try {
      const data = await amgApi.post(
        "management/employees/get-tracking-employees",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getTrackingJobDetails:", error);
      throw error;
    }
  }

  async getFiles(body) {
    try {
      const data = await amgApi.post("management/employees/get-files", body);
      return data;
    } catch (error) {
      console.log("Something went wrong on getFiles:", error);
      throw error;
    }
  }

  async renameFile(body) {
    try {
      const data = await amgApi.post("management/employees/rename-file", body);
      return data;
    } catch (error) {
      console.log("Something went wrong on renameFile:", error);
      throw error;
    }
  }

  async deleteFile(body) {
    try {
      const data = await amgApi.post(
        "management/employees/delete-file-employee",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteFile:", error);
      throw error;
    }
  }

  async getSchedule(body) {
    try {
      const data = await amgApi.post("management/employees/get-schedule", body);
      return data;
    } catch (error) {
      console.log("Something went wrong on getSchedule:", error);
      throw error;
    }
  }

  async getAllHourWorked(body) {
    try {
      const data = await amgApi.post(
        "management/employees/get-all-hour-worked",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getAllHourWorked:", error);
      throw error;
    }
  }

  async editSchedule(body) {
    try {
      const data = await amgApi.post(
        "management/employees/update-schedule",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on editSchedule:", error);
      throw error;
    }
  }

  async editHourWorked(body) {
    try {
      const data = await amgApi.post(
        "management/employees/save-hour-work",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on editHourWorked:", error);
      throw error;
    }
  }

  async editPhoto(body) {
    try {
      const data = await amgApi.post("management/employees/edit-photo", body);
      return data;
    } catch (error) {
      console.log("Something went wrong on editPhoto:", error);
      throw error;
    }
  }

  async getRoles(body) {
    try {
      const data = await amgApi.get("management/employees/get-roles", body);
      return data;
    } catch (error) {
      console.log("Something went wrong on getRoles:", error);
      throw error;
    }
  }

  async createDepartments(body) {
    try {
      const data = await amgApi.post(
        "management/employees/create-department",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on createDepartments:", error);
      throw error;
    }
  }

  async saveRoles(body) {
    try {
      const data = await amgApi.post("management/employees/save-roles", body);
      return data;
    } catch (error) {
      console.log("Something went wrong on saveRoles:", error);
      throw error;
    }
  }

  async getScheduleDepartment(body) {
    try {
      const data = await amgApi.post(
        "management/employees/get-schedule-department",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on getScheduleDepartment:", error);
      throw error;
    }
  }

  async updateScheduleDepartment(body) {
    try {
      const data = await amgApi.post(
        "management/employees/update-department-schedule",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on updateScheduleDepartment:", error);
      throw error;
    }
  }

  async updateScheduleDepartmentCheck(body) {
    try {
      const data = await amgApi.post(
        "management/employees/update-department-schedule-check",
        body
      );
      return data;
    } catch (error) {
      console.log(
        "Something went wrong on updateScheduleDepartmentCheck:",
        error
      );
      throw error;
    }
  }

  async updateScheduleDepartmentCheckClose(body) {
    try {
      const data = await amgApi.post(
        "management/employees/update-check-close",
        body
      );
      return data;
    } catch (error) {
      console.log(
        "Something went wrong on updateScheduleDepartmentCheckClose:",
        error
      );
      throw error;
    }
  }

  async deleteDepartment(body) {
    try {
      const data = await amgApi.post(
        "management/employees/delete-department",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteDepartment:", error);
      throw error;
    }
  }

  async searchUserRrhh(body) {
    try {
      const data = await amgApi.post(
        "management/employees/search-user-rrhh",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on searchUserRrhh:", error);
      throw error;
    }
  }

  async insertRelationEmployee(body) {
    try {
      const data = await amgApi.post(
        "management/employees/insert-relation-employee",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on insertRelationEmployee:", error);
      throw error;
    }
  }

  async deleteUserRelation(body) {
    try {
      const data = await amgApi.post(
        "management/employees/delete-user-relation",
        body
      );
      return data;
    } catch (error) {
      console.log("Something went wrong on deleteUserRelation:", error);
      throw error;
    }
  }
}

export default new EmployeesService();
