export default [
  {
    path: 'salary-record-rrhh',
    name: 'salary-record-rrhh',
    component: () => import(
      /* webpackChunkName: "SallaryRecord" */ '@/views/rrhh/views/salary-record/SalaryRecord.vue'
    ),
    redirect: { name: 'request-salary-rrhh' },
    meta: {
      pageTitle: 'Salary Record',
      breadcrumb: [
        {
          text: 'Salary Record',
        },
      ],
      employeeRoute: 'information-employee-rrhh',
    },
    children: [
      {
        path: 'request-salary',
        component: () => import(
          /* webpackChunkName: "RequestSalary" */ '@/views/management/views/salary-record/content/SalaryGrid.vue'
        ),
        name: 'request-salary-rrhh',
        meta: {
          pageTitle: 'Request Salary',
          breadcrumb: [
            {
              text: 'Request Salary',
              active: true,
            },
          ],
        },
      },
      {
        path: 'completed-salary',
        component: () => import(
          /* webpackChunkName: "CompletedSalary" */ '@/views/management/views/salary-record/content/SalaryGrid.vue'
        ),
        name: 'completed-salary-rrhh',
        meta: {
          pageTitle: 'Completed Salary',
          breadcrumb: [
            {
              text: 'Completed Salary',
              active: true,
            },
          ],
          completed: true,
        },
      },
    ],
  },
];
