import moment from "moment";

export default {
  namespaced: true,
  state: {
    year: moment().format("YYYY"),
    halfYear: true,
    percentApartment: "100.00",
    loading: false,
    moduleProgram: null,
    tabModuleId:null,
    totalCommissionCeDigital: 0,
  },
  getters: {
    year: (state) => state.year,
    halfYear: (state) => state.halfYear,
    percentApartment: (state) => state.percentApartment,
    loading: (state) => state.loading,
    moduleProgram: (state) => state.moduleProgram,
    tabModuleId: (state) => state.tabModuleId,
    totalCommissionCeDigital: (state) => state.totalCommissionCeDigital,
  },
  mutations: {
    SET_TOTAL_COMMISSION_CE_DIGITAL(state, payload) {
      return (state.totalCommissionCeDigital = payload);
    },
    SET_YEAR_COMMISSIONS(state, payload) {
      return (state.year = payload);
    },
    SET_HALF_YEAR_COMMISSIONS(state, payload) {
      return (state.halfYear = payload);
    },
    SET_PERCENT_APARTMENT_COMMISSIONS(state, payload) {
      return (state.percentApartment = payload);
    },
    SET_LOADING(state, payload) {
      return (state.loading = payload);
    },
    SET_MODULE_PROGRAM(state, payload) {
      return (state.moduleProgram = payload);
    },
    SET_MODULE_TABMODULEID(state, payload) {
      return (state.tabModuleId = payload);
    },
  },
  actions: {
    A_UPDATE_TABMODULEID({commit}, params) {
        commit('SET_MODULE_TABMODULEID', params)
    },
    A_TOTAL_COMMISSION_CE_DIGITAL({ commit }, params) {
      commit("SET_TOTAL_COMMISSION_CE_DIGITAL", params);
    }
  },
};
