import SalesMade from "@/views/ce-digital/sub-modules/sales/views/sales-made/SalesMade.vue";
import NewClientComponent from "@/views/crm/views/sales-made/components/NewClientComponent.vue";
import AddChangeComponent from "@/views/crm/views/sales-made/components/AddChangeComponent.vue";
import { saleMadeTabs } from "@/views/commons/components/sales-made/router/sale-made.router";

export default [
  {
    path: "sales-made",
    name: "cedigital-sales-made-home",
    redirect: { name: "sales-made-cedigital-lead" },
    component: SalesMade,
    meta: {
      module: 26,
      program: 3,
      pageTitle: "Sales Made",
      breadcrumb: [
        {
          text: "Sales Made",
          active: true,
        },
      ],
    },
    // children: [
    //     {
    //         path: "new-client",
    //         component: NewClientComponent,
    //         name: "sales-made-cedigital-new-client",
    //         props: true,
    //         meta: {
    //             module: 26,
    //             program: 3,
    //             pageTitle: "Sales Made",
    //             breadcrumb: [
    //                 {
    //                     text: "New Clients",
    //                     active: true,
    //                 },
    //             ],
    //         },
    //     },
    //     {
    //         path: "add-change",
    //         component: AddChangeComponent,
    //         name: "sales-made-cedigital-add-change",
    //         meta: {
    //             module: 26,
    //             program: 3,
    //             permittedRoles: [1, 2, 5, 14, 17],
    //             pageTitle: "Sales Made",
    //             breadcrumb: [
    //                 {
    //                     text: "Add/Change Service",
    //                     active: true,
    //                 },
    //             ],
    //         },
    //     },
    // ]
    children: saleMadeTabs("cedigital"),
  },
];
