export default [
  {
    key: 'selected',
    label: 'Selected',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'employee',
    label: 'Employee',
  },
  {
    key: 'modules',
    label: 'modules',
  },
];
