import CustomerTicketsMain from "@/views/commons/components/customer-tickets/CustomerTicketsMain.vue";
import { ticketsTabs } from "@/views/customer-tickets/router/tickets.router.js";

export default [
  {
    path: "/debt-solution/tickets",
    name: "debt-solution-customer-tickets",
    redirect: { name: "customer-ticket-debt-solution-in-progress" },
    component: CustomerTicketsMain,
    meta: {
      pageTitle: "Customer Tickets",
      breadcrumb: [
        {
          text: "My tickets",
        },
      ],
      tab: 4,
    },
    children: ticketsTabs("debt-solution", [2, 17]),
  },
];
