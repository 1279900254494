/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class ManualsOfFunctionsService {
  async getSkills() {
    try {
      return await amgApi.post(
        '/manuals-of-functions/get-skills',
      );
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }

  async insertSkills(body) {
    try {
      return await amgApi.post(
        '/manuals-of-functions/insert-skill', body,
      );
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }

  async deletetSkill(body) {
    try {
      return await amgApi.post(
        '/manuals-of-functions/del-skill', body,
      );
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }

  async validateMOF(body) {
    try {
      return await amgApi.post(
        '/manuals-of-functions/validate-mof', body,
      );
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }

  async validateMOFDisapproved(body) {
    try {
      return await amgApi.post(
        '/manuals-of-functions/validate-disapproved-mof', body,
      );
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }

  async getHRRoles() {
    try {
      return await amgApi.post(
        '/manuals-of-functions/get-hr-roles',
      );
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }

  async getMOFStatusTracking(body) {
    try {
      return await amgApi.post(
        '/manuals-of-functions/mof-status-tracking', body,
      );
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }

  async insertMOF(body) {
    try {
      return await amgApi.post(
        '/manuals-of-functions/post-insert-mof',
        body,
      );
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }

  async getMOFs(body) {
    try {
      const data = await amgApi.post(
        '/manuals-of-functions/get-mofs',
        body,
      );
      return data.data;
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }

  async getStatusLegend() {
    try {
      const data = await amgApi.post(
        '/manuals-of-functions/get-legends',
      );
      return data.data;
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }

  // insert-rol
  async insertRol(body) {
    try {
      const data = await amgApi.post(
        '/manuals-of-functions/insert-rol',
        body,
      );
      return data.data;
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }

  async getPendigMOFReview(body) {
    try {
      const data = await amgApi.post(
        '/manuals-of-functions/get-pending-mof',
        body,
      );
      return data.data;
    } catch (error) {
      console.log('Something went wrong on mofs:', error);
      throw error;
    }
  }
}

export default new ManualsOfFunctionsService();
