/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
import Vue from "vue";
import store from "@/store";
import router from "@/router";

const moduleIdToRouteMap = {
  2: "crm-customer-tickets",
  3: "business-customer-tickets",
  4: "administration-customer-tickets",
  5: "debt-solution-customer-tickets",
  6: "credit-experts-customer-tickets",
  7: "boost-credit-customer-tickets",
  8: "tax-research-customer-tickets",
  9: "correspondence-customer-tickets",
  10: "court-info-customer-tickets",
  11: "analyst-department-customer-tickets",
  12: "paragon-customer-tickets",
  14: "bookeeping-customer-tickets",
  15: "social-network-customer-tickets",
  16: "management-customer-tickets",
  17: "rrhh-customer-tickets",
  18: "quality-customer-tickets",
  19: "logistic-customer-tickets",
  20: "connection-customer-tickets",
  22: "customer-service-customer-tickets",
  23: "financial-customer-tickets",
  25: "specialist-digital-customer-tickets",
  26: "sales-customer-tickets",
  27: "creative-customer-tickets",
};
/**
 * !Check if window has focus
 */

let hasFocus = true;
window.onfocus = () => {
  hasFocus = true;
};
window.onblur = () => {
  hasFocus = false;
};
export const sendTicketCustomerMessage = () => {
  window.socket.bind("send-ticket-costumer-message-event", async (data) => {
    const actualChat =
      store.state.TicketCustomerStore.S_OPEN_TICKET_CUSTOMER_ID;
    const actualSession =
      store.state.TicketCustomerStore.S_TICKET_CUSTOMER_CURRENT_SESSION;
    const actualUser = store.state.auth.currentUser.user_id;
    const { modul_id } = store.state.auth.currentUser;
    const messagenot = {
      ticketCustomerId: data.messageParams.ticketCustomerId,
      account: data.messageParams.customerTicketChat.account,
      nameClient: data.messageParams.customerTicketChat.name_client,
    };
    const notifyNewMessagesDelay = async (messageNot) => {
      store.dispatch("NotificationStore/A_PENDING_CUSTOMER_TICKETS");
      const pendingMessages =
        store.getters["TicketCustomerStore/G_PENDING_MESSAGES_TO_NOTIFY"];
      let found = false;
      for (const message of pendingMessages) {
        // Comprueba si el elemento ya existe y tiene el mismo ticketCustomerId
        if (message.ticketCustomerId === messageNot.ticketCustomerId) {
          message.messageCount++;
          found = true;
          break;
        }
        if (!found) {
          pendingMessages.push({
            ticketCustomerId: messageNot.ticketCustomerId,
            account: messageNot.account,
            nameClient: messageNot.nameClient,
            messageCount: 1,
            customerTicketChat: data.messageParams.customerTicketChat,
          });
        }
        store.dispatch(
          "TicketCustomerStore/A_SET_PENDING_MESSAGES_TO_NOTIFY",
          pendingMessages
        );
        const lastMessageTime = localStorage.getItem("lastMessageTime");
        if (!lastMessageTime) {
          localStorage.setItem("lastMessageTime", data.messageParams.createdAt);
          setTimeout(async () => {
            if (actualSession !== null) return;
            const messageToNotify =
              store.state.TicketCustomerStore.S_PENDING_MESSAGES_TO_NOTIFY;
            const data2 = () => {
              const data3 = [];
              messageToNotify.forEach((element) => {
                data3.push({
                  icon: "UserIcon",
                  title: `${element.nameClient}-${
                    element.account ? element.account : ""
                  } - ${element.messageCount} new messages`,
                  dataComponent: {
                    name: "ModalTicketCustomer",
                    props: {
                      customerTicketChat: element.customerTicketChat,
                      fileType: {},
                    },
                  },
                });
              });
              return data3;
            };

            // if the 'current user' is in the 'customer ticket view', the notifications are 'not displayed'.
            let currentView = store.state.TicketCustomerStore.S_CURRENT_VIEW;
            currentView = currentView ? currentView.toLowerCase() : null;
            if (currentView !== "modalticketcustomer") {
              await Vue.prototype.$amgAlert({
                icon: "BellIcon",
                title: "New Messsages",
                subtitle: "You have new messages from this tickets",
                data: data2(),
                okTitle: data.status === 1 ? "Go Ticket" : "Ok",
                // ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
                ok: async () => {
                  if (data.status === 1) {
                    router.push({ name: moduleIdToRouteMap[modul_id] });
                  } else {
                    await window.amgApi.post("/commons/close-all-swal", {
                      to_id: actualUser,
                    });
                  }
                  await window.amgApi.post("/commons/close-all-swal", {
                    to_id: actualUser,
                  });
                },
              }); //        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
            }
            localStorage.removeItem("lastMessageTime");
            store.dispatch(
              "TicketCustomerStore/A_SET_PENDING_MESSAGES_TO_NOTIFY",
              []
            );
          }, 1000 * 20);
        }
      }
      const notifyNewMessageBrowser = async () => {
        if (Notification.permission === "granted") {
          if (!data.participants.includes(actualUser)) return;

          const notification = new Notification("New Message", {
            body: `TICKET CUSTOMER MESSAGE: ${data.messageParams.message} from ${data.messageParams.creatorName}`,
            icon: "icono.png", // Opcional: URL de la imagen para el icono
          });
          notification.onclick = () => {
            // Check if window is focused
            if (hasFocus) return;
            window.focus();
          };
        } else {
          if (!data.participants.includes(actualUser)) return;
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              const notification = new Notification("New Message", {
                body: `TICKET CUSTOMER MESSAGE: ${data.messageParams.message} from ${data.messageParams.creatorName}`,
                icon: "icono.png", // Opcional: URL de la imagen para el icono
              });
              notification.onclick = () => {
                if (hasFocus) return;
                window.focus();
              };
            }
          });
        }
      };

      //! check if currentUser id is in toNotify array of objects
      if (data.sessions.includes(actualSession) || actualSession === null) {
        // ? check if Participant is in actual chat
        if (actualChat === data.messageParams.ticketCustomerId) {
          data.messageParams.edited = data.edited;
          data.messageParams.replied = data.replied;
          //* if is in actual chat, set new message to store and update array of messages
          store.dispatch(
            "TicketCustomerStore/A_SET_NEW_TICKET_CUSTOMER_MESSAGE",
            data.messageParams
          );
          notifyNewMessageBrowser();
          // store.dispatch('NotificationStore/A_PENDING_CUSTOMER_TICKETS');
        } else {
          if (!data.participants.includes(actualUser)) return;
          // Recargar los contadores de customer tickets
          const currentUserId = store.state.auth.currentUser.user_id;
          const moduleId = store.state.auth.currentUser.modul_id;
          await store.dispatch(
            "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
            { user_id: currentUserId }
          );
          await store.dispatch(
            "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE"
          );
          await store.dispatch(
            "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT",
            {
              user_id: currentUserId,
            }
          );
          if (data.messageParams.customerTicketChat.client_account) {
            await store.dispatch(
              "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
              {
                user_id: currentUserId,
                client_account_id:
                  data.messageParams.customerTicketChat.client_account,
              }
            );
          }
          await store.dispatch("NotificationStore/A_COUNT_INDICATORS_TICKETS", {
            user_id: currentUserId,
            module_id: moduleId,
          });
          const countPendingTickets = await store.getters[
            "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS"
          ];
          const payload = {
            routeName: moduleIdToRouteMap[moduleId],
            tag: countPendingTickets.v_total_open,
          };
          store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
          if (
            data.messageParams.creatorId ===
            store.state.auth.currentUser.user_id
          ) {
            return;
          }
          notifyNewMessageBrowser();
          notifyNewMessagesDelay(messagenot);
        }
        // eslint-disable-next-line brace-style
      }
      if (!found) {
        pendingMessages.push({
          ticketCustomerId: messageNot.ticketCustomerId,
          account: messageNot.account,
          nameClient: messageNot.nameClient,
          messageCount: 1,
          customerTicketChat: data.messageParams.customerTicketChat,
        });
      }
      store.dispatch(
        "TicketCustomerStore/A_SET_PENDING_MESSAGES_TO_NOTIFY",
        pendingMessages
      );
      const lastMessageTime = localStorage.getItem("lastMessageTime");
      if (!lastMessageTime) {
        localStorage.setItem("lastMessageTime", data.messageParams.createdAt);
        setTimeout(async () => {
          if (actualSession !== null) return;
          const messageToNotify =
            store.state.TicketCustomerStore.S_PENDING_MESSAGES_TO_NOTIFY;
          const data2 = () => {
            const data3 = [];
            messageToNotify.forEach((element) => {
              data3.push({
                icon: "UserIcon",
                title: `${element.nameClient}-${
                  element.account ? element.account : ""
                } - ${element.messageCount} new messages`,
                dataComponent: {
                  name: "ModalTicketCustomer",
                  props: {
                    customerTicketChat: element.customerTicketChat,
                    fileType: {},
                  },
                },
              });
            });
            return data3;
          };

          // if the 'current user' is in the 'customer ticket view', the notifications are 'not displayed'.
          let currentView = store.state.TicketCustomerStore.S_CURRENT_VIEW;
          currentView = currentView ? currentView.toLowerCase() : null;
          if (currentView !== "modalticketcustomer") {
            await Vue.prototype.$amgAlert({
              icon: "BellIcon",
              title: "New Messsages",
              subtitle: "You have new messages from this tickets",
              data: data2(),
              okTitle: data.status === 1 ? "Go Ticket" : "Ok",
              // ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
              ok: async () => {
                if (data.status === 1) {
                  router.push({ name: moduleIdToRouteMap[modul_id] });
                } else {
                  await window.amgApi.post("/commons/close-all-swal", data);
                }
              },
            }); //
          }
          localStorage.removeItem("lastMessageTime");
          store.dispatch(
            "TicketCustomerStore/A_SET_PENDING_MESSAGES_TO_NOTIFY",
            []
          );
        }, 1000 * 20);
      }
    };
    const notifyNewMessageBrowser = () => {
      if (Notification.permission === "granted") {
        if (!data.participants.includes(actualUser)) return;

        const notification = new Notification("New Message", {
          body: `TICKET CUSTOMER MESSAGE: ${data.messageParams.message} from ${data.messageParams.creatorName}`,
          icon: "icono.png", // Opcional: URL de la imagen para el icono
        });
        notification.onclick = () => {
          // Check if window is focused
          if (hasFocus) return;
          window.focus();
        };
      } else {
        if (!data.participants.includes(actualUser)) return;
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            const notification = new Notification("New Message", {
              body: `TICKET CUSTOMER MESSAGE: ${data.messageParams.message} from ${data.messageParams.creatorName}`,
              icon: "icono.png", // Opcional: URL de la imagen para el icono
            });
            notification.onclick = () => {
              if (hasFocus) return;
              window.focus();
            };
          }
        });
      }
    };

    //! check if currentUser id is in toNotify array of objects
    if (data.sessions.includes(actualSession) || actualSession === null) {
      // ? check if Participant is in actual chat
      if (actualChat === data.messageParams.ticketCustomerId) {
        data.messageParams.edited = data.edited;
        data.messageParams.replied = data.replied;
        //* if is in actual chat, set new message to store and update array of messages
        store.dispatch(
          "TicketCustomerStore/A_SET_NEW_TICKET_CUSTOMER_MESSAGE",
          data.messageParams
        );
        notifyNewMessageBrowser();
        // store.dispatch('NotificationStore/A_PENDING_CUSTOMER_TICKETS');
      } else {
        if (!data.participants.includes(actualUser)) return;
        // Recargar los contadores de customer tickets
        const currentUserId = store.state.auth.currentUser.user_id;
        const moduleId = store.state.auth.currentUser.modul_id;
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
          { user_id: currentUserId }
        );
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE"
        );
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT",
          {
            user_id: currentUserId,
          }
        );
        if (data.messageParams.customerTicketChat.client_account) {
          await store.dispatch(
            "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
            {
              user_id: currentUserId,
              client_account_id:
                data.messageParams.customerTicketChat.client_account,
            }
          );
        }
        await store.dispatch("NotificationStore/A_COUNT_INDICATORS_TICKETS", {
          user_id: currentUserId,
          module_id: moduleId,
        });
        const countPendingTickets = await store.getters[
          "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS"
        ];
        const payload = {
          routeName: moduleIdToRouteMap[moduleId],
          tag: countPendingTickets.v_total_open,
        };
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
        if (
          data.messageParams.creatorId === store.state.auth.currentUser.user_id
        ) {
          return;
        }
        notifyNewMessageBrowser();
        notifyNewMessagesDelay(messagenot);
      }
      // eslint-disable-next-line brace-style
    }
    // if have chat open but not in actual chat
    else {
      if (!data.participants.includes(actualUser)) return;
      notifyNewMessagesDelay(messagenot);
    }
  });
};

// Cuando estas dentro del chat y te envian un mensaje
export const viewTicketCustomerMessage = () => {
  window.socket.bind("view-ticket-costumer-message-event", async (data) => {
    const actualSession =
      store.state.TicketCustomerStore.S_TICKET_CUSTOMER_CURRENT_SESSION;
    if (data.toNotify.includes(actualSession)) {
      store.dispatch(
        "TicketCustomerStore/A_SET_NEW_VIEWER_TICKET_CUSTOMER_MESSAGE",
        data.viewerParams
      );
      const currentUserId = store.state.auth.currentUser.user_id;

      await store.dispatch(
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
        { user_id: currentUserId }
      );
      const countPendingTickets = await store.getters[
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS"
      ];
      const moduleId = store.state.auth.currentUser.modul_id;

      const payload = {
        routeName: moduleIdToRouteMap[moduleId],
        tag: countPendingTickets.v_total_open,
      };
      store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
    }
  });
};

// Cuando abres el chat
export const viewMassTicketCustomerMessages = () => {
  window.socket.bind(
    "view-mass-ticket-costumer-message-event",
    async (data) => {
      const actualSession =
        store.state.TicketCustomerStore.S_TICKET_CUSTOMER_CURRENT_SESSION;

      // const currentUserId = store.state.auth.currentUser.user_id;
      if (data.toNotify.includes(actualSession)) {
        // sleep a moment
        store.dispatch(
          "TicketCustomerStore/A_SET_NEW_VIEWER_TICKET_CUSTOMER_MESSAGE",
          {
            creatorId: data.viewerInfo.creatorId,
            creatorName: data.viewerInfo.creatorName,
            ticketCustomerId: data.viewerInfo.ticketCustomerId,
            viewerAt: data.viewerInfo.viewedAt,
            creatorImage: data.viewerInfo.creatorImage,
            chatTicketMessageIds: data.viewedMessages,
          }
        );
        // Recargar los contadores de customer tickets
        const currentUserId = store.state.auth.currentUser.user_id;
        const moduleId = store.state.auth.currentUser.modul_id;
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
          { user_id: currentUserId }
        );
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
          {
            user_id: currentUserId,
            client_account_id:
              data.messageParams.customerTicketChat.client_account,
          }
        );
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE"
        );
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT",
          { user_id: currentUserId }
        );
        await store.dispatch("NotificationStore/A_COUNT_INDICATORS_TICKETS", {
          user_id: currentUserId,
          module_id: moduleId,
        });
        const countPendingTickets = await store.getters[
          "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS"
        ];
        const payload = {
          routeName: moduleIdToRouteMap[moduleId],
          tag: countPendingTickets.v_total_open,
        };
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
      }
    }
  );
};

export const sendTicketToCeo = async () => {
  window.socket.bind("send-ticket-customer-to-ceo", async (data) => {
    const currentUserId = store.state.auth.currentUser.user_id;
    const currentModuleId = store.state.auth.currentUser.modul_id;

    const objectChat = {
      name: "ModalTicketCustomer",
      props: {
        customerTicketChat: data,
        fileType: {},
      },
    };

    let currentView = store.state.TicketCustomerStore.S_CURRENT_VIEW;
    currentView = currentView ? currentView.toLowerCase() : null;
    if (currentUserId === 1) {
      // if the 'current user' is in the 'customer ticket view', the notifications are 'not displayed'.
      if (currentView !== "modalticketcustomer") {
        await Vue.prototype.$amgAlert({
          title: "NEW TICKET GENERATED",
          icon: "BellIcon",
          subtitle: "IMPORTANT",
          persistent: true,
          data: [
            {
              icon: "CalendarIcon",
              title: "Motive:",
              description: data.title,
            },
            {
              icon: "BookOpenIcon",
              title: "Description:",
              description: data.description,
            },
            {
              icon: "StarIcon",
              title: "Priority:",
              description: `${data.priority.toUpperCase()}`,
            },
            {
              icon: "BookmarkIcon",
              title: "Client:",
              description: `${data.client}`,
            },
            {
              icon: "UserIcon",
              title: "Sender:",
              description: `${data.senderBy} from ${data.moduleName}`,
            },
          ],
          okTitle: "Go tickets",
          ok: async () => {
            router.push({ name: "in-progress-customer-tickets" });
            await window.amgApi.post("/commons/close-all-swal", {
              to_id: currentUserId,
            });
          },
          closeSkt: async () => {
            await window.amgApi.post("/commons/close-all-swal", {
              to_id: currentUserId,
            });
          },
        });
      }
      await store.dispatch("NotificationStore/A_COUNT_INDICATORS_TICKETS", {
        user_id: currentUserId,
        module_id: currentModuleId,
      });
      await store.dispatch(
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
        { user_id: currentUserId }
      );
      await store.dispatch(
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE"
      );
      await store.dispatch(
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT",
        { user_id: currentUserId }
      );
      await store.dispatch(
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
        {
          user_id: currentUserId,
          client_account_id:
            data.messageParams.customerTicketChat.client_account,
        }
      );
    }
  });
};
export const sendReactionCustomer = () => {
  window.socket.bind("reaction-ticket-costumer-message-event", async (data) => {
    const actualChat =
      store.state.TicketCustomerStore.S_OPEN_TICKET_CUSTOMER_ID;
    const actualSession =
      store.state.TicketCustomerStore.S_TICKET_CUSTOMER_CURRENT_SESSION;
    if (data.sessions.includes(actualSession) || actualSession === null) {
      // ? check if Participant is in actual chat
      if (actualChat === data.reaction.ticketCustomerId) {
        store.dispatch(
          "TicketCustomerStore/A_SET_REACTION_CUSTOMER_MESSAGE",
          data.reaction
        );
      }
    }
  });
};

export const sendNotificationMeetingCeo = () => {
  window.socket.bind("send-notification-meeting-ceo", async (data) => {
    const currentUserId = store.state.auth.currentUser.user_id;
    if (currentUserId === 1) {
      await Vue.prototype.$amgAlert({
        title: "MEETING IN 10 MINUTES",
        icon: "BellIcon",
        subtitle: "IMPORTANT",
        data: [
          {
            icon: "BookmarkIcon",
            title: "Ticket code:",
            description: data.meeting[0].code,
          },
          {
            icon: "UserCheckIcon",
            title: "Client:",
            description: `${data.meeting[0].client_name}`,
          },
          {
            icon: "PhoneIcon",
            title: "Phone:",
            description: data.meeting[0].phone_number
              ? `${data.meeting[0].phone_number}`
              : "unregistered",
          },
          {
            icon: "BriefcaseIcon",
            title: "Account:",
            description: data.meeting[0].account
              ? `${data.meeting[0].account}`
              : "Lead customer",
          },
          {
            icon: "BookOpenIcon",
            title: "Description:",
            description: `${data.meeting[0].description}`,
          },
          {
            icon: "StarIcon",
            title: "Priority:",
            description: `${data.meeting[0].priority.toUpperCase()}`,
          },
          {
            icon: "ClockIcon",
            title: "Starts at:",
            description: `${data.meeting[0].meeting_start}`,
          },
        ],
        okTitle: "Go tickets",
        ok: async () => {
          router.push({ name: "in-progress-customer-tickets" });
          await window.amgApi.post("/commons/close-all-swal", {
            to_id: currentUserId,
          });
        },
        closeSkt: async () => {
          await window.amgApi.post("/commons/close-all-swal", {
            to_id: currentUserId,
          });
        },
      });
    }
  });
};

if (localStorage.getItem("lastMessageTime")) {
  localStorage.removeItem("lastMessageTime");
}
