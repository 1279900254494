import LeadsRouter from "@/views/tax-research/views/leads/leads.router";
import HelpDeskRouter from "@/views/tax-research/views/helpdesk/helpdesk.route";
import LoansRouter from "@/views/tax-research/views/loans/loans.router";
import ClaimsRouter from "@/views/tax-research/views/claims-v2/claims.router";
import SchedulesRouter from "@/views/tax-research/views/schedules/schedules.router";
import PaymentsRouter from "@/views/tax-research/views/payments/payments.router";
import CalendarRouter from "@/views/tax-research/views/calendar/calendar.router";
import FileManagerRouter from "@/views/tax-research/views/file-manager/file-manager.router";
import ChargeBackRouter from "@/views/tax-research/views/charge-back/charge-back.router";
import PaymentReportRouter from "@/views/tax-research/views/report/report.router";
import TrAttendanceRouter from "@/views/tax-research/views/attendance/tr-attendance.router";
import CommisionsRouter from "@/views/tax-research/views/commissions/commissions.router";
import SalesMadeRouter from "@/views/tax-research/views/sales-made/sales-made.router";
import ZeroPaymentRouter from "@/views/tax-research/views/zero-payment/zero-payment.router";
import ClientsRouter from "@/views/tax-research/views/clients/clients.router";
import ProductRequestRouter from "@/views/tax-research/views/request/request.router.js";
import OtherPaymentsRoute from "@/views/tax-research/views/other-payments/other-payments.router";
import EmployeeClaimsRoute from "@/views/tax-research/views/employee-claims/router/employee-claims.router";
import TaxResearchMain from "@/views/tax-research/views/TaxResearchMain.vue";
import CallLogRouter from "@/views/tax-research/views/ring-central/call-log/call-log.router.js";
import MOFRouter from "@/views/tax-research/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/tax-research/views/recruitment-process/tax-research-recruitment_process.router";

import RequestNcr from "@/views/tax-research/views/request-ncr/request-ncr.router";
import CourtInfoRouter from "@/views/tax-research/views/cour-info/router/ds-court-info.router";

import DashboardCalls from "@/views/tax-research/views/ring-central/dashboard-calls/dashboard.router.js";

import CustomerTicketsRouter from "@/views/tax-research/views/customer-tickets/tax-research-customer-tickets.router";

import RequestWorkplanRouter from "@/views/tax-research/views/request-workplan/request-workplan.router.js";
import LettersRouter from "@/views/tax-research/views/dispute/letters/router/letters.router";
import DirectRouter from "@/views/tax-research/views/dispute/direct/router/direct.router";
import InconsistentTransactionRouter from "@/views/tax-research/views/inconsistent-transactions/router/inconsistent-transactions.router.js";
import TaxResearchDashboardRouter from "@/views/tax-research/views/dashboard/router/taxresearch-dashboard.router.js";
import PendingPaymentsRouter from "@/views/tax-research/views/pending-payments/router/pending-payments.router.js";
import CallImpressionRoute from "@/views/tax-research/views/call-impression/router/call-impression.router.js";

const routes = [
  {
    path: "/taxresearch",
    redirect: { name: "taxresearch-dashboard" },
    component: TaxResearchMain,
    meta: { module: 8, program: 5 },
    children: [
      ...TaxResearchDashboardRouter,
      ...LeadsRouter,
      ...LoansRouter,
      HelpDeskRouter,
      ...ClaimsRouter,
      ...SchedulesRouter,
      ...PaymentsRouter,
      ...CalendarRouter,
      FileManagerRouter,
      ...ChargeBackRouter,
      PaymentReportRouter,
      CommisionsRouter,
      SalesMadeRouter,
      ZeroPaymentRouter,
      ...ClientsRouter,
      ...ProductRequestRouter,
      ...TrAttendanceRouter,
      ...OtherPaymentsRoute,
      ...EmployeeClaimsRoute,
      ...CallLogRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...RequestNcr,
      ...CourtInfoRouter,
      ...DashboardCalls,
      ...CustomerTicketsRouter,
      ...RequestWorkplanRouter,
      ...LettersRouter,
      ...DirectRouter,
      ...InconsistentTransactionRouter,
      ...PendingPaymentsRouter,
      ...CallImpressionRoute
    ],
  },
];

export default routes;
