import HelpDesk from '@/views/specialists/sub-modules/analyst-department/views/support/helpdesk/helpdesk.nav';
import Inquiries from '@/views/specialists/sub-modules/analyst-department/views/support/inquiries/inquiries.nav';
import LogisticRequest from '@/views/specialists/sub-modules/analyst-department/views/support/logistic-request/request.nav';

export default {

  title: "Support",
  icon: "ShieldIcon",
  children : [HelpDesk, ...Inquiries, LogisticRequest],
}
