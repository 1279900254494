import DashboardRouter from "@/views/specialists/sub-modules/accounts-resolution/views/dashboard/router/dashboard.router";

import AccountsResolutionMain from "@/views/specialists/sub-modules/accounts-resolution/AccountsResolutionMain.vue";
import AccountsResolutionAttendance from "@/views/specialists/sub-modules/accounts-resolution/views/attendance/accounts-resolution-attendance.router.js";

const routes = [
  {
    path: "/specialists/accounts-resolution",
    redirect: { name: "accounts-resolution-dashboard" },
    component: AccountsResolutionMain,
    meta: {
      module: 30,
      parentModule: 11,
      program: 7,
    },
    children: [
      DashboardRouter,
      ...AccountsResolutionAttendance,
    ],
  },
];

export default routes;
