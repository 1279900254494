<template>
  <b-modal
    v-model="onControl"
    modal-class="modal-primary"
    :title="`Last searched users`"
    title-tag="h3"
    size="xlg"
    centered
    no-close-on-backdrop
    header-bg-variant="success"
    @hidden="close"
  >
    <b-row class="d-flex align-items-end my-2">
      <b-col
        class="d-flex align-items-center justify-content-start"
        style="gap: .5rem;"
      >
        <span>Show</span>
        <b-form-group
          label=""
          label-for="per-page-select"
          label-size="sm"
          label-align-sm="right"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="md"
            style="width:90px;"
          />
        </b-form-group>
        <span>entries</span>
        <span
          class="pl-1"
        >
          <feather-icon
            class="cursor-pointer"
            icon="RefreshCcwIcon"
            size="20"
            @click="refreshTable()"
          />
        </span>
      </b-col>
      <b-col
        lg="6"
        md="6"
        sm="12"
        class="mt-1"
      >
        <b-form-group
          class="m-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="m-0"
            />

            <b-input-group-append>
              <b-button
                variant="primary"
                @click="filter = ''"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          id="paymentsList"
          ref="paymentsTable"
          :items="items"
          :fields="fields"
          no-border-collapse
          class="position-relative mt-1"
          show-empty
          no-provider-filtering
          sticky-header="45vh"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          :busy="isBusy"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle" />
              <strong>Loading...</strong>
            </div>
          </template> <!-- loading spinner -->

          <template #cell(searched_for)="data">
            <span class="text-primary">
              {{ data.item.first_name }} {{ data.item.last_name }}
            </span>
          </template> <!-- searched_for cell -->

          <template #cell(last_search_performed)="data">
            {{ data.item.last_search_performed }}
          </template> <!-- last_search_performed cell -->

          <template #cell(client_transaction_id)="data">
            <div class="">
              <span
                v-for="(item, index) in JSON.parse(data.item.client_transaction_id)"
                :key="index"
              >
                <div class="d-flex justify-content-between">

                  <div>
                    <div
                      v-if="item.first_name"
                      class="text-primary"
                    >Name: {{ item.first_name }} {{ item.last_name }}</div>
                    <div v-else class="text-primary"> Last digits searched: {{ item.search_digits }}</div>
                  </div>
                  <div>
                    <div
                      v-if="item.first_name"
                      class="text-primary"
                    >Amount: {{ item.amount }}</div>
                    <div v-else />
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <div v-if="item.card_number">Card Number: {{ item.card_number }}</div>
                    <div v-else />
                  </div>
                  <div>
                    <div v-if="item.merchant_description">
                      Merchant: <span :class="['merchant-description', getMerchantDescriptionClass(item.merchant_description)]">{{ item.merchant_description }}</span>
                    </div>
                    <div v-else />
                  </div>
                </div>
                <div class="d-flex justify-content-between pb-1">
                  <div>
                    <div v-if="item.settlement_date">Settlement Date: {{ item.settlement_date }}</div>
                    <div v-else />
                  </div>
                  <div>
                    <div v-if="item.first_name">
                      <div v-if="item.value">Program: {{ item.value }}</div>
                      <div
                        v-else
                        class="text-danger"
                      >Lead</div>
                    </div>
                    <div v-else />
                  </div>

                </div>
              </span>
            </div>
          </template><!-- client_transaction_id cell -->
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        {{ result.from && result.to && totalRows ? `Showing ${result.from} to ${result.to} of ${totalRows} entries` :
          'Showing 0 to 0 of 0 entries' }}
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="md"
          class="my-0"
        />
      </b-col>
    </b-row>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="secondary"
          size="md"
          class="float-right mr-1"
          @click="close()"
        >
          CANCEL
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import chargeBackService from '@/views/commons/components/charge-back_v2/service/charge.back.service';
// fields
import modalTrackingPayments from '@/views/commons/components/charge-back_v2/data/fields-modal-tracking-payments';

export default {
  components: {
  },
  data() {
    return {
      result: [],
      isBusy: false,
      totalRows: 1,
      sortDirection: 'asc',
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 25, 50],
      filter: null,
      filterOn: [],
      startPage: null,
      toPage: null,
      items: [],
      fields: modalTrackingPayments,
      sortBy: 'created_at',
      sortDesc: true,
      onControl: false,
    };
  },
  computed: {

    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    currentPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getTrackingPayments(newVal);
      }
    },
    perPage(newPerPage, oldPerPage) {
      if (newPerPage !== oldPerPage) {
        this.currentPage = 1;
        this.getTrackingPayments();
      }
    },
  },
  async created() {
    this.onControl = true;
  },

  mounted() {
    this.getTrackingPayments();
  },
  methods: {
    async refreshTable() {
      try {
        this.isBusy = true;
        await this.getTrackingPayments(this.currentPage);
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    close() {
      this.$emit('close');
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    async getTrackingPayments(current_page = 1) {
      try {
        this.isBusy = true;
        const params = {
          user_id: this.currentUser.user_id,
          per_page: this.perPage,
          current_page,
        };
        const result = await chargeBackService.getTrackingPaymentsV(params);
        this.result = result;
        this.items = result.data;
        this.totalRows = result.total;
        this.currentPage = result.current_page;
      } catch (error) {
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },
    getMerchantDescriptionClass(merchantDescription) {
      return {
        'text-info': merchantDescription === 'AMG BUSINESS INC(EMS DATA)',
        'text-primary': merchantDescription === 'AMG LLC(EMS DATA) ',
        'text-warning': merchantDescription === 'AMIG INC(CHASE)',
        'text-success': merchantDescription === 'AMG BUSINESS LLC(WELLS FARGO)',
      };
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
