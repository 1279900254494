<template>
  <b-button
    :variant="variant"
    :disabled="disabled"
    @click="$emit('click')"
    :size="size"
  >
    {{textButton}}
    <feather-icon icon="ShareIcon" v-if="viewIcon"/>
  </b-button>
</template>

<script>
export default {
  name: 'ButtonExportPdf',
  props: {
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    variant:{
      type: String,
      default: "outline-secondary"
    },
    viewIcon:{
      type: Boolean,
      default: false
    },
    textButton:{
      type: String,
      default: "Export to PDF"
    },
    size:{
      type: String,
      required: false,
      default: "md"
    }
  },
}
</script>

<style scoped>

</style>
