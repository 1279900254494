var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0 border-0",attrs:{"no-body":""}},[_c('filter-slot',{attrs:{"filter":_vm.tabFilters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){_vm.$refs[("refSaleLeads" + _vm.typeTab)].refresh()}}},[_c('b-table',{ref:("refSaleLeads" + _vm.typeTab),attrs:{"slot":"table","primary-key":"id","empty-text":"No matching records found","select-mode":"multi","responsive":"sm","table-class":"text-nowrap","sticky-header":"68vh","small":"","no-provider-filtering":"","show-empty":"","current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"fields":_vm.tabFields,"items":_vm.myProvider,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"busy":_vm.isBusy},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(selected)",fn:function(data){return undefined}},{key:"cell(lead_name)",fn:function(data){return [_c('div',[_c('router-link',{class:[_vm.textLink],attrs:{"target":"_blank","to":{
                name: 'sales-lead-show',
                params: {
                  id: data.item.id,
                },
                props: { programsAll: _vm.programsAll },
              }}},[_c('span',[_vm._v(_vm._s(data.item.lead_name))])]),_c('p',[_vm._v(" "+_vm._s(data.item.source_name)+" ")])],1),(data.item.user_responsible_date)?_c('div',{staticClass:"text-warning font-weight-bolder"},[_c('feather-icon',{staticStyle:{"margin-right":"3px"},attrs:{"icon":"StarIcon"}}),_vm._v(" CEO's client ")],1):_vm._e()]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center pt-1"},[_c('b-badge',{staticClass:"text-capitalize w-75",attrs:{"pill":"","variant":("light-" + (_vm.resolveUserStatusVariant(data.item.status)))}},[_vm._v(_vm._s(data.item.status))]),_c('p')],1)]}},{key:"cell(catcher_by)",fn:function(data){return [_c('div',{staticClass:"pt-1"},[_c('small',[_vm._v(_vm._s(data.item.catcher_by))])])]}},{key:"cell(seller_by)",fn:function(data){return [_c('div',{staticClass:"pt-1"},[_c('small',[_vm._v(_vm._s(data.item.seller_by))])])]}},{key:"cell(mobile)",fn:function(data){return [_c('div',{staticClass:"pt-1"},[_c('small',[_vm._v(_vm._s(data.item.mobile))])])]}},{key:"cell(credit_report)",fn:function(data){return [_c('div',{staticClass:"pt-1"},[_c('strong',{class:("text-" + (data.item.credit_report == 1 ? 'danger' : 'success'))},[_vm._v(_vm._s(data.item.credit_report == 1 ? "NO" : "YES"))])])]}},{key:"cell(programs)",fn:function(data){return [_c('div',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(data.item.programs)+" ")])]}},{key:"cell(module)",fn:function(data){return [_c('div',{staticClass:"pt-1"},[_c('small',[_vm._v(_vm._s(data.item.module))])])]}},{key:"cell(created_at)",fn:function(data){return [_c('div',{staticClass:"pt-1"},[_c('small',[_vm._v(_vm._s(_vm._f("myDate")(data.item.created_at)))])])]}},{key:"cell(tracking)",fn:function(data){return [_c('div',{staticClass:"pt-1"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MenuIcon","size":"15"},on:{"click":function($event){return _vm.openTracking(data.item)}}})],1)]}},(
            _vm.currentUser.role_id == 1 ||
            _vm.currentUser.role_id == 2 ||
            _vm.currentUser.role_id == 5 ||
            _vm.currentUser.role_id == 14 ||
            _vm.currentUser.role_id == 17
          )?{key:"cell(actions)",fn:function(data){return [_c('div',[_c('actions-table',{attrs:{"options":_vm.actionsOptions,"row-data":data.item},on:{"modalAddService":_vm.modalAddService,"modalChangeService":_vm.modalChangeService,"onTracing":_vm.openActions,"onRecycled":_vm.openActions,"modalSmsOpen":_vm.modalSmsOpen,"modalHistorySmsOpen":_vm.modalHistorySmsOpen}})],1)]}}:null,{key:"cell(client_ceo)",fn:function(data){return [_c('span',{staticClass:"text-warning"},[(data.item.user_responsible_date)?[_vm._v("From")]:_vm._e(),_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.item.user_responsible_date))+" ")],2)]}}],null,true)})],1)],1),(_vm.stateTracing)?_c('actions-type-tab-modal',{attrs:{"client-data":_vm.clientData},on:{"hideModal":function($event){return _vm.closeModal(1)}}}):_vm._e(),(_vm.stateTracking)?_c('tracking-modal',{attrs:{"client-data":_vm.clientData},on:{"hideModal":function($event){return _vm.closeModal(2)}}}):_vm._e(),(_vm.modalOpenAddService)?_c('modal-add-service',{attrs:{"modal-open-add-service":_vm.modalOpenAddService,"details-client":_vm.detailsClient,"type-modal":_vm.typeModal},on:{"hideModal":_vm.closeModalService,"changeProgram":_vm.openModalProgram,"leadEvent":_vm.leadEvent}}):_vm._e(),(_vm.modalSms)?_c('modal-send-sms',{attrs:{"smss":_vm.leads_sms,"modul":_vm.currentUser.modul_id,"typesms":_vm.typesms,"sms":_vm.leads_sms_o,"name-leads":_vm.name_leads_arr},on:{"hide":function($event){_vm.modalSms = false}},scopedSlots:_vm._u([{key:"manage-quick-sms",fn:function(){return [_c('div')]},proxy:true}],null,false,3287984410)}):_vm._e(),_c('b-modal',{attrs:{"id":"modal-history-sms","ok-only":"","modal-class":"modal-primary","title-class":"text-white h4","centered":"","size":"lg","title":"History of Sms","hide-footer":""}},[_c('modal-history-sms',{attrs:{"id":_vm.historySms.id,"modul":_vm.currentUser.modul_id,"lead-name":_vm.historySms.leadName}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }