 <template>
  <div class="chat-container-rc2">
    <div
      class="message-important"
      @click="searchImportant(messageImportant._id)"
      v-if="messageImportant && filesUpload.length == 0"
    >
      <div class="mr-2">
        <feather-icon icon="TagIcon" size="20"></feather-icon>
      </div>
      <div v-if="messageImportant.type == 'SMS'" class="text-truncate">
        {{ messageImportant.subject }}

        <div v-if="messageImportant.attachments.length > 0">
          {{ messageImportant.attachments.length }} Attachments
        </div>
      </div>
      <div v-if="messageImportant.type == 'VoiceMail'" class="text-truncate">
        <feather-icon icon="MicIcon" />
        <span style="margin-left: 4px">{{
          convertSecondsShort(messageImportant.attachments[0].vmDuration)
        }}</span>
      </div>

      <div v-if="messageImportant.type == 'Fax'" class="text-truncate">
        <feather-icon icon="FileIcon" />
        <span style="margin-left: 4px">{{
          messageImportant.attachments[0].id +
          "." +
          messageImportant.attachments[0].contentType.split("/")[1]
        }}</span>
      </div>
    </div>
    <div
      class="messages-container"
      ref="chatContainer"
      @scroll="loadMoreMessages"
      v-if="filesUpload.length == 0"
    >
      <div
        v-for="(message, index) in chatMessages"
        :key="`message_${index}`"
        style="margin-top: 4px"
      >
        <div v-if="message.direction == 'Outbound'" class="text-end pr-1">
          <feather-icon icon="UserIcon" v-if="message.createdBy"></feather-icon>
          <img
            height="20px"
            style="margin-bottom: 2px"
            width="20px"
            v-else
            :src="geModuleImageRoute(credential.module_id)"
          />
          {{ message.createdBy ? message.createdBy.name : credential.rc_name }}
        </div>

        <div
          class="message"
          :id="`message_${message._id}`"
          :class="getTypeMessage(message)"
        >
          <div
            class="options-message"
            v-if="message.chatId && getTypeMessage(message) != 'received'"
          >
            <feather-icon
              v-if="
                !message.error &&
                message.messageStatus != 'DeliveryFailed' &&
                message.messageStatus != 'SendingFailed' &&
                !message.queueId
              "
              :id="`popover_target_${message._id}`"
              icon="MoreVerticalIcon"
              class="icon-menu-action"
              size="18"
            />
          </div>

          <div v-if="message.chatId" class="message-content">
            <feather-icon
              icon="PaperclipIcon"
              class="icon-important-messages"
              v-if="message.important"
            />

            <div :class="getTypeMessage(message)" class="p-1">
              <div v-if="message.type != 'Fax'">
                <div
                  v-if="message.subject && message._id == messageId"
                  class="text"
                  v-html="highlightMatchingText(message.subject)"
                ></div>

                <div v-else>{{ message.subject }}</div>
              </div>

              <div class="d-flex flex-wrap">
                <div v-for="file in message.attachments" :key="file.id">
                  <div
                    v-if="file.contentType.startsWith('image/')"
                    class="container-img mt-1"
                  >
                    <img
                      :src="file.recordUrl"
                      class="custom-img-2"
                      loading="lazy"
                      @click="currentImage = file.recordUrl"
                    />
                  </div>

                  <div
                    v-if="file.contentType.startsWith('video/')"
                    class="container-img mt-1"
                  >
                    <div class="video-download">
                      <div class="download">
                        <feather-icon
                          icon="PlayCircleIcon"
                          class="cursor-pointer"
                          size="40"
                          @click="downloadFileRc(file, 'mp4')"
                        />
                      </div>

                      <div class="options-video">
                        <span></span>
                        <span>{{ formatSize(file.size) }}</span>

                        <div class="circle-video"></div>
                      </div>
                    </div>
                  </div>

                  <div v-if="file.contentType.startsWith('audio/')">
                    <wave-surfer-player
                      style="height: 1.5rem"
                      :url="file.recordUrl"
                      :options="audioOptions"
                      :wave-surfer-id="`wave-surfer-${file.id}`"
                      :elapsed-time-width="50"
                      :file_name="file.id"
                      :download="false"
                      :not-hidden-badge="false"
                      :color-icons="'#D1D7DB'"
                    ></wave-surfer-player>
                  </div>

                  <div
                    v-if="
                      file.contentType.startsWith('application/') ||
                      file.contentType.startsWith('text/')
                    "
                  >
                    <view-file
                      :url="file.recordUrl"
                      :filename="`${file.id}.${file.contentType.split('/')[1]}`"
                      :extension="file.contentType.split('/')[1]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="day">{{ message.day }}</div>
          </div>

          <div
            class="options-message"
            v-if="message.chatId && getTypeMessage(message) == 'received'"
          >
            <feather-icon
              :id="`popover_target_${message._id}`"
              icon="MoreVerticalIcon"
              class="icon-menu-action"
              size="18"
            />
          </div>

          <b-popover
            v-if="message.chatId"
            :target="`popover_target_${message._id}`"
            triggers="hover focus"
            :placement="
              getTypeMessage(message) == 'received' ? 'right' : 'left'
            "
          >
            <template #title></template>
            <div
              class="cursor-pointer d-flex align-items-center"
              style="height: 30px"
              @click="setImportantMessage(message._id, !message.important)"
            >
              <feather-icon icon="TagIcon" style="margin-right: 4px" />
              {{ message.important ? "Unpin" : "Pin" }}
            </div>
            <div
              class="cursor-pointer d-flex align-items-center"
              style="height: 30px"
              @click="setHighLight(message._id, !message.highlight)"
            >
              <feather-icon icon="StarIcon" style="margin-right: 4px" />
              {{ message.highlight ? "No Mark" : "Mark" }}
            </div>
          </b-popover>
        </div>

        <div
          v-if="message.chatId"
          :class="
            getTypeMessage(message) == 'received' ? 'text-start' : 'text-end'
          "
          class="rc-color-secondary"
          style="margin-top: 5px"
        >
          <feather-icon
            icon="StarIcon"
            size="12"
            class="star"
            v-if="message.highlight"
          />

          <span
            class="text-danger"
            style="margin-top: 1px; margin-right: 5px"
            v-if="
              message.error ||
              message.messageStatus == 'DeliveryFailed' ||
              message.messageStatus == 'SendingFailed'
            "
            >Message cannot be sent</span
          >

          <span
            class="text-info"
            style="margin-top: 1px; margin-right: 5px"
            v-if="message.messageStatus == 'Queued'"
            >Message in Queued</span
          >

          <span
            class="text-warning"
            style="margin-top: 1px; margin-right: 5px"
            v-if="message.queueId"
            >Message in Soft Queued</span
          >

          <feather-icon
            v-if="
              message.error ||
              message.messageStatus == 'DeliveryFailed' ||
              message.messageStatus == 'SendingFailed'
            "
            icon="AlertTriangleIcon"
            size="16"
            class="text-danger"
            style="margin: 0 4px"
          />

          <feather-icon
            v-if="message.resource == 'Topic'"
            icon="BookIcon"
            size="12"
            class="text-success"
            v-b-tooltip.hover
            title="From List"
            style="margin-right: 3px"
          />

          <span v-if="message.queueId">{{ message.created_at | myTime }}</span>
          <span v-else>{{ message.creationTime | Iso2CATime }}</span>

          <b-avatar
            :id="`tooltip-target-${message._id}`"
            v-if="message.readBy"
            variant="info"
            size="16"
            :src="getImageUser(message.readBy.id)"
            style="margin-left: 5px"
          ></b-avatar>

          <b-tooltip
            v-if="message.readBy"
            :target="`tooltip-target-${message._id}`"
            triggers="hover"
          >
            {{ message.readBy.name }}
            {{ message.readBy.createdAt | Iso2CADateWithHour }}
          </b-tooltip>
        </div>
      </div>
    </div>

    <div
      class="input-container"
      v-if="filesUpload.length == 0 && !currentChat.recycledBy"
    >
      <feather-icon
        icon="PaperclipIcon"
        class="mr-1 cursor-pointer mb-1"
        size="25"
        @click="activeSearchFiles()"
      ></feather-icon>

      <input
        id="inputFiles"
        type="file"
        ref="inputFiles"
        style="display: none"
        @change="handleFile"
      />

      <div class="w-100">
        <b-form-textarea
          v-model="newMessage"
          @keydown.enter="handleCtrlEnter"
          class="custom-form-input"
          placeholder="Type message ..."
          max-rows="3"
          :state="
            newMessage != '' &&
            newMessage.length >= 1 &&
            newMessage.length <= 500
          "
        />

        <span class="float-right"
          >{{ newMessage ? newMessage.length : 0 }} / 500</span
        >
      </div>
      <feather-icon
        v-if="!loadingSearchMessage"
        icon="SendIcon"
        class="cursor-pointer custom-buttonv2 mb-1"
        size="25"
        @click="sendMessage(1)"
      ></feather-icon>

      <b-spinner variant="primary" label="Loading" v-else class="ml-1" />
    </div>

    <div
      class="input-container justify-content-center"
      style="font-size: 16px; padding: 0; height: 80px"
      v-if="currentChat.recycledBy"
    >
      this client was sent for recycle by {{ currentChat.recycledBy.name }} at
      {{ currentChat.recycledBy.createdAt | Iso2CADateWithHour }}
    </div>

    <div v-if="filesUpload.length > 0" class="container-upload-files">
      <feather-icon
        icon="XIcon"
        @click="filesUpload = []"
        size="25"
        class="mt-2 ml-2 cursor-pointer"
      ></feather-icon>

      <div class="size-files">{{ formatSize(totalSizeFiles) }}/1.5 MB</div>

      <div class="container-preview-file">
        <b-img
          class="h-100"
          style="max-width: 80%"
          v-if="
            filePreview.fileType && filePreview.fileType.startsWith('image/')
          "
          :src="castImage2Url(filePreview.file)"
        ></b-img>
        <div
          v-else
          class="h-100 d-flex align-items-center justify-content-center"
        >
          <feather-icon icon="FileIcon" size="150"></feather-icon>
        </div>
      </div>

      <div class="mx-5 px-5 py-2 mb-2">
        <b-form-textarea
          class="custom-form-input"
          placeholder="Type message ..."
          max-rows="2"
          v-model="newMessage"
        />
      </div>

      <div class="list-files">
        <b-row class="h-100">
          <b-col
            cols="11"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              v-for="file in filesUpload"
              :key="`file_${file.id}`"
              class="file"
              @click="filePreview = file"
              :class="filePreview.id == file.id ? 'file-active' : ''"
            >
              <div
                v-if="file.fileType && file.fileType.startsWith('image/')"
                :style="{ backgroundImage: `url(${castImage2Url(file.file)})` }"
                class="file-img"
              ></div>
              <div
                v-else
                class="h-100 d-flex align-items-center justify-content-center"
              >
                <feather-icon icon="FileIcon" size="35"></feather-icon>
              </div>
            </div>
            <!-- <div
              class="file-upload d-flex align-items-center justify-content-center"
              @click="activeSearchFiles()"
            >
              <feather-icon icon="PlusIcon" size="25"></feather-icon>
              <input
                id="inputFiles"
                type="file"
                ref="inputFiles"
                style="display: none"
                @change="handleFile"
              />
            </div> -->
          </b-col>
          <b-col
            cols="1"
            class="h-100 d-flex align-items-center justify-content-center"
          >
            <div
              class="send-icon-files"
              @click="sendMessage(2)"
              v-if="!loadingSearchMessage"
            >
              <feather-icon
                size="22"
                icon="SendIcon"
                class="feather-icon-file"
              ></feather-icon>
              <div class="counter-file">{{ filesUpload.length }}</div>
            </div>
            <b-spinner variant="primary" label="Loading" v-else class="ml-1" />
          </b-col>
        </b-row>
      </div>
    </div>

    <image-viewer
      v-if="currentImage != ''"
      :objectImages="G_CURRENT_CHAT_FILES.images"
      :route="currentImage"
      @hidden="currentImage = ''"
      style="z-index: 10000 !important"
    />
  </div>
</template>

<script>
import io from "socket.io-client";

import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import NestSmsService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
import WaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/WaveSurferPlayer.vue";
import ViewFile from "@/views/commons/components/ring-central/chat-sms/components/utils/ViewFile.vue";
import ImageViewer from "@/views/commons/components/paragon-soft/components/ImageViewer.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { WaveSurferPlayer, ViewFile, ImageViewer },
  props: {
    messageId: {
      type: String,
    },

    searchTxt: {
      type: String,
    },

    credential: {
      type: Object,
      required: true,
    },

    currentChat: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      chatMessages: [],

      socket: null,
      messages: [],
      newMessage: "",
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#111B21",
        barWidth: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        width: 180,
        backend: "MediaElement",
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      currentImage: "",
      currentPage: 1,
      moreMessage: true,
      loadingMessageScroll: false,
      messageImportant: null,
      filesUpload: [],
      filePreview: {
        file: null,
        type: null,
      },

      FILES_AVAILABLE: [
        "application/gzip",
        "application/rtf",
        "application/zip",
        "audio/mpeg",
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/tiff",
        "text/vcard",
        "video/mp4",
        "video/mpeg",
      ],

      loadingSearchMessage: false,
    };
  },

  computed: {
    ...mapGetters({
      G_CURRENT_CHAT_FILES: "SmsInboxStore/G_CURRENT_CHAT_FILES",
      G_USERS_IMAGES: "SmsInboxStore/G_USERS_IMAGES",
      currentUser: "auth/currentUser",
    }),

    totalSizeFiles() {
      let size = 0;
      this.filesUpload.forEach((file) => {
        size += file.file.size;
      });
      return size;
    },
  },

  methods: {
    ...mapActions({
      A_GET_CURRENT_CHAT_FILES: "SmsInboxStore/A_GET_CURRENT_CHAT_FILES",
      A_GET_COUNTER_UNREAD_MESSAGES:
        "SmsInboxStore/A_GET_COUNTER_UNREAD_MESSAGES",
    }),

    handleCtrlEnter(event) {
      if (event.ctrlKey && event.key === "Enter") {
        const cursorPos = event.target.selectionStart;
        this.newMessage =
          this.newMessage.slice(0, cursorPos) +
          "\n" +
          this.newMessage.slice(cursorPos);

        event.stopPropagation();
        event.preventDefault();
      } else if (event.key === "Enter") {
        this.sendMessage(1);
      }
    },

    getImageUser(userId) {
      const user = this.G_USERS_IMAGES.find((user) => user.id == userId);
      if (user) {
        return user.image_thumb;
      }
      return null;
    },

    loadMoreMessages() {
      const contactList = this.$refs.chatContainer;
      if (
        contactList.scrollHeight -
          (Math.abs(contactList.scrollTop) + contactList.clientHeight) <=
          10 &&
        !this.loadingMessageScroll
      ) {
        this.getMessages();
      }
    },

    async loadImportantMessage() {
      const params = {
        chatId: this.currentChat._id,
        column: "important",
        value: true,
        limit: 1,
      };

      const { data } = await NestSmsService.findMessages(params);
      if (data.length > 0) {
        this.messageImportant = { ...data[0] };
      } else {
        this.messageImportant = null;
      }
    },

    async getMessages() {
      if (!this.moreMessage || !this.currentChat._id) return;

      try {
        this.loadingMessageScroll = true;
        this.addPreloader();
        const params = {
          chatId: this.currentChat._id,
          page: this.currentPage,
        };

        const { data } = await NestSmsService.getMessages(params);

        if (data.length == 0) {
          this.moreMessage = false;
        }

        const auxArray = [...this.chatMessages];
        const newContacts = [...auxArray, ...data];

        this.chatMessages = newContacts;

        this.currentPage++;
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.loadingMessageScroll = false;
        this.removePreloader();
      }
    },

    getTypeMessage(message) {
      if (!message.chatId) {
        return "day";
      }

      return message.direction == "Outbound" ? "sent" : "received";
    },

    highlightMatchingText(text) {
      if (this.searchTxt == "" || !this.searchTxt) return text;
      const searchTextLower = this.searchTxt.toLowerCase();
      const regex = new RegExp(`(${searchTextLower})`, "gi");
      return text.replace(regex, `<span class="match">$1</span>`);
    },

    async findMessageById(messageId = null) {
      try {
        let findElement = false;
        const findMessageId = messageId ? messageId : this.messageId;
        let reamingSearch = 20;

        while (!findElement && reamingSearch > 0) {
          await new Promise((resolve) => setTimeout(resolve, 100));

          const element = document.getElementById(`message_${findMessageId}`);

          if (element) {
            setTimeout(() => {
              element.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }, 100);

            findElement = true;
          } else {
            reamingSearch--;
            await this.getMessages();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
      }
    },

    searchImportant(messageId) {
      this.findMessageById(messageId);
    },

    async setHighLight(messageId, statusHigh) {
      try {
        const params = {
          highlight: statusHigh,
        };

        await NestSmsService.updateMessage(messageId, params);
      } catch (e) {
        this.showErrorSwal(e);
      }
    },

    async setImportantMessage(messageId, important) {
      const params = {
        important: important,
        chatId: this.currentChat._id,
        messageId: messageId,
      };

      try {
        await NestSmsService.asignImportantMessage(params);
      } catch (e) {
        this.showErrorSwal(e);
      }
    },

    async getMessagesInQueue() {
      const { data } = await SmsService.getMessageInQueueSoft(
        this.currentChat._id
      );

      if (data.length == 0) {
        const existeQueueId = this.chatMessages.some((obj) =>
          obj.hasOwnProperty("queueId")
        );
        if (existeQueueId) {
          const auxArrayDelete = this.chatMessages.filter(
            (obj) => !obj.hasOwnProperty("queueId")
          );

          this.chatMessages = [...auxArrayDelete];
        }
      } else {
        const auxArrayQueu = this.chatMessages.filter((obj) =>
          obj.hasOwnProperty("queueId")
        );

        if (auxArrayQueu.length == 0) {
          data.forEach((element) => {
            this.chatMessages.unshift(element);
          });
        } else {
          // delete messages in queue front

          auxArrayQueu.forEach((element) => {
            const existeQueueId = data.some(
              (obj) => obj.queueId == element.queueId
            );

            if (!existeQueueId) {
              const xchatMessages = this.chatMessages.filter(
                (obj) => obj.queueId != element.queueId
              );

              this.chatMessages = [...xchatMessages];
            }
          });

          // add messages in queue back
          data.forEach((element) => {
            const existeQueueId = this.chatMessages.some(
              (obj) => obj.queueId == element.queueId
            );

            if (!existeQueueId) {
              this.chatMessages.unshift(element);
            }
          });
        }
      }
    },

    async sendMessage(image) {
      if (
        (this.newMessage == "" ||
          !this.newMessage ||
          this.newMessage.length > 500) &&
        image == 1
      )
        return;

      // const confirm = await this.showConfirmSwal();
      // if (!confirm.isConfirmed) return;

      if (image == 2) {
        await Promise.all(
          this.filesUpload.map(async (file) => {
            file.base64 = await this.readFileAsync(file.file);
          })
        );

        if (this.subject == "") {
          this.subject = " ";
        }
      }

      this.loadingSearchMessage = true;

      try {
        const params = {
          chatId: this.currentChat._id,
          subject: this.newMessage,
          attachment: this.filesUpload,
          direction: "Outbound",
          createdBy: {
            id: this.currentUser.user_id,
            name: this.currentUser.fullName,
          },
          credentialId: this.credential.id,
          toNumber: this.currentChat.leadPhoneNumber,
          withImage: image,
          leadId: this.currentChat.leadId,
        };

        const { status } = await SmsService.sendMessage(params);
        if (status == 200) {
          this.newMessage = "";
          this.filesUpload = [];
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.loadingSearchMessage = false;
      }
    },

    activeSearchFiles() {
      this.$refs["inputFiles"].click();
    },

    handleFile(event) {
      const file = event.target.files[0];

      if (!this.FILES_AVAILABLE.includes(file.type)) {
        this.showToast(
          "info",
          "top-right",
          "Oops!",
          "XIcon",
          "Format not accepted"
        );
        return;
      }
      const maxSizeInBytes = 1500000;

      if (file.size > maxSizeInBytes) {
        this.showToast("info", "top-right", "Oops!", "XIcon", "File too big");
        return;
      }

      if (this.totalSizeFiles + file.size > maxSizeInBytes) {
        this.showToast(
          "info",
          "top-right",
          "Oops!",
          "XIcon",
          "files must not exceed 1.5 MB in size"
        );
        return;
      }

      const element = {
        id: this.generateUniqueId(),
        file: file,
        name: file.name,
        fileType: file.type,
        base64: null,
      };
      this.filePreview = element;
      this.filesUpload.push(element);
    },

    castImage2Url(file) {
      return URL.createObjectURL(file);
    },

    formatSize(sizeInBytes) {
      if (sizeInBytes < 1024) {
        return sizeInBytes + " bytes";
      } else if (sizeInBytes < 1024 * 1024) {
        return (sizeInBytes / 1024).toFixed(2) + " KB";
      } else {
        return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
      }
    },

    // MUTATIONS

    clearAllImport(message) {
      if (this.currentChat._id != message.chatId) return;

      const existIndex = this.chatMessages.findIndex(
        (message) => message.important == true
      );
      if (existIndex !== -1) {
        this.chatMessages[existIndex].important = false;
      }

      const payload = {
        propFind: "_id",
        findValue: message._id,
        propName: "important",
        propValue: message.important,
      };

      this.updateMessage(payload);
    },

    updateMessage(payload) {
      const { propFind, findValue, propName, propValue } = payload;
      const existIndex = this.chatMessages.findIndex(
        (message) => message[propFind] == findValue
      );
      if (existIndex !== -1) {
        this.chatMessages[existIndex][propName] = propValue;
      }
    },

    addMessageCurrentChat(messageNew) {
      if (this.currentChat && messageNew.chatId == this.currentChat._id) {
        const existingContactIndex = this.chatMessages.findIndex(
          (message) => message._id == messageNew._id
        );
        if (existingContactIndex == -1) {
          const existingNewMessage = this.chatMessages.findIndex(
            (message) => message.day == "New Messages"
          );
          if (existingNewMessage == -1) {
            this.chatMessages.unshift({
              chatId: null,
              day: "New Messages",
            });
          }
          this.chatMessages.unshift(messageNew);
          this.readAllMessages();
        }

        this.getMessagesInQueue();
      }
    },

    readAllMessages() {
      const newUser = this.currentUser;
      const el = this;
      setTimeout(async function () {
        const dateUTC = new Date();
        const isoDate = dateUTC.toISOString();

        await NestSmsService.readAllByChat(el.currentChat._id, {
          readBy: {
            id: newUser.user_id,
            name: newUser.fullName,
            createdAt: isoDate,
          },
          readStatus: "Read",
        });

        el.A_GET_COUNTER_UNREAD_MESSAGES(el.credential.id);
      }, 1000);
    },

    updateReadMessages(payload) {
      const { chatId, updatedDocuments } = payload;
      if (this.currentChat && chatId == this.currentChat._id) {
        updatedDocuments.forEach((element) => {
          const existIndex = this.chatMessages.findIndex(
            (message) => message._id == element._id
          );
          console.log(existIndex);
          if (existIndex !== -1) {
            this.chatMessages[existIndex].readBy = element.readBy;
          }
        });
      }
    },

    updateStatusMessages(messages) {
      messages.forEach((element) => {
        if (element.chatId == this.currentChat._id) {
          const existIndex = this.chatMessages.findIndex(
            (message) => message._id == element._id
          );
          if (existIndex !== -1) {
            this.chatMessages[existIndex].messageStatus = element.messageStatus;
          }
        }
      });
    },

    // SOCKETS
    bindUpdateMessage() {
      try {
        this.socket.on("notification-message-created", (data) => {
          this.addMessageCurrentChat(data);
        });

        this.socket.on("notification-read-all-messages-update", (data) => {
          this.updateReadMessages(data);
        });

        this.socket.on("notification-update-message", (data) => {
          const payload = {
            propFind: "_id",
            findValue: data._id,
            propName: "highlight",
            propValue: data.highlight,
          };
          this.updateMessage(payload);
        });

        this.socket.on("notification-update-message-important", (data) => {
          this.loadImportantMessage();
          this.clearAllImport(data);
        });

        this.socket.on("notification-message-update-queue", (data) => {
          this.updateStatusMessages(data);
        });

        window.socket.bind("new-sms-to-queue", async ({ message }) => {
          if (this.currentChat._id == message.chat_id) {
            this.getMessagesInQueue();
          }
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },

    downloadFileRc(item, extension = null) {
      const gExtension = extension ? extension : item.contentType.split("/")[1];
      this.forceDownloadAnyFile(item.recordUrl, `${item.id}.${gExtension}`);
    },

    initWebsocket() {
      const connectionOptions = {
        "force new connection": true,
        reconnectionAttempts: "Infinity",
        timeout: 10000,
        transports: ["websocket"],
      };
      this.socket = io(process.env.VUE_APP_NEST_BACK, connectionOptions);

      window.socket.unsubscribe("ringcentral-notifications");
      window.socket.subscribe("ringcentral-notifications");
    },
  },
  watch: {
    currentChat(value) {
      if (value == null) return;

      this.chatMessages = [];
      this.A_GET_CURRENT_CHAT_FILES(this.currentChat._id);
      this.moreMessage = true;
      this.currentPage = 1;
      this.newMessage = "";
      this.filesUpload = [];

      this.getMessages();
      this.loadImportantMessage();

      if (value.unreadCount && value.unreadCount > 0) {
        this.readAllMessages();
      }

      this.getMessagesInQueue();
    },

    filesUpload() {
      this.$emit("filesUploadSize", this.filesUpload.length);
    },

    messageId() {
      this.findMessageById();
    },
  },

  created() {
    this.initWebsocket();
  },

  mounted() {
    this.bindUpdateMessage();
    this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight;
    this.getMessages();
    this.loadImportantMessage();
    this.readAllMessages();

    this.getMessagesInQueue();
  },

  beforeDestroy() {
    this.socket.disconnect();
  },
};
</script>

<style lang="scss">
.chat-container-rc2 {
  font-family: "Rubick" !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 92%;
  background-image: url("https://i.pinimg.com/736x/8c/98/99/8c98994518b575bfd8c949e91d20548b.jpg");

  .star {
    color: yellowgreen;
    fill: yellowgreen;
    margin-bottom: 2px;
  }

  .text-end {
    text-align: end;
  }

  .message-important {
    display: flex;
    background-color: #f5f6f6;
    min-height: 50px;
    padding: 0 2rem;
    align-items: center;
    cursor: pointer;
  }

  .messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 10px;

    flex-direction: column-reverse;
    display: flex;
  }

  .message {
    display: flex;

    .message-content {
      max-width: 600px;
      position: relative;

      .icon-important-messages {
        position: absolute;
        top: 0;
        right: 0;
      }

      .container-files {
        display: flex;
        margin-bottom: 4px;
        overflow: hidden;
      }

      .container-img {
        width: 180px;
        height: 150px;
        border: 2px solid #f0f2f5;
        border-radius: 10px;
        cursor: pointer;
        position: relative;
        display: flex;
        flex: 1;

        .custom-img-2 {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .video-download {
          width: 100%;
          height: 100%;
          background-color: #ebebeb;

          .download {
            height: 70%;
            widows: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .options-video {
            height: 30%;
            width: 100%;
            border-top: 1px solid #656565;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.5rem;
            position: relative;

            .circle-video {
              position: absolute;
              height: 10px;
              width: 10px;
              border-radius: 50%;
              background-color: #bbbbbb;
              top: -0.5rem;
              left: 0;
            }
          }
        }

        .show-more {
          position: absolute;
          top: 0;
          right: 0;
          background-color: rgba(10, 10, 10, 0.8);
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          font-size: 20px;
        }
      }
    }

    .options-message {
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      width: 30px;

      .icon-menu-action {
        color: #989898 !important;
      }
    }

    // .message-content:hover .options-message {
    //   opacity: none;
    // }

    .sent {
      text-align: end;
      background-color: #0099dd;
      color: #f8f9fd;
      border-radius: 32px 32px 0px 32px;
    }

    .received {
      text-align: start;
      background-color: #ffffff;
      border-radius: 32px 32px 32px 0px;
    }

    .day {
      text-align: center;
      background-color: #f0f2f5;
      padding: 0.7rem;
      border-radius: 10px;
    }
  }

  .sent {
    justify-content: end;
  }

  .received {
    justify-content: start;
  }

  .day {
    justify-content: center;
  }

  .input-container {
    display: flex;
    align-items: center;
    padding: 15px 15px 5px 15px;
    background-color: #f0f2f5;
    .custom-buttonv2 {
      margin: 0 10px;
    }
  }

  textarea.form-control {
    padding: 0.4rem 0.8rem 0 0.8rem !important;
  }

  .custom-form-input {
    background-color: #ffffff;
  }

  .container-upload-files {
    background-color: #e9edef;
    height: 100%;
    position: relative;

    .container-preview-file {
      height: 65%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .list-files {
      height: 14%;
      width: 100%;
      border-top: 1px solid #f5f6f6;

      .file,
      .file-upload {
        height: 60px;
        width: 60px;
        border-radius: 10px;
        margin: 0 5px;
        cursor: pointer;
      }

      .file-active {
        border: 2px solid #00a884 !important;
      }
      .file {
        border: 1px solid #bec3c6;

        .file-img {
          height: 100%;
          width: 100%;
          border-radius: 10px;
          background-size: cover;
          background-position: center;
        }
      }

      .file-upload {
        border: 1px solid #c5cbcf;
      }

      .send-icon-files {
        width: 55px;
        height: 55px;
        background-color: #00a884;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        .feather-icon-file {
          color: #ffffff;
          font-weight: light;
          transform: rotate(45deg);
          margin-right: 5px;
        }

        .counter-file {
          background-color: #ffffff;
          border-radius: 50%;
          height: 20px;
          width: 20px;
          position: absolute;
          top: -5px;
          right: -5px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #101a20;
        }
      }
    }

    .size-files {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.dark-layout {
  .chat-container-rc2 {
    background-image: url("https://c4.wallpaperflare.com/wallpaper/217/640/970/technology-discord-wallpaper-preview.jpg");

    .message-important {
      background-color: #0b141a;
    }
    .message {
      .container-img {
        border: 2px solid #202c33;

        .video-download {
          background-color: #212121;
        }
      }

      .sent,
      .received {
        color: #ffffff;
      }

      .sent {
        background-color: #005c4b;
      }

      .received {
        background-color: #202c33;
      }

      .day {
        background-color: #202c33;
      }
    }

    .input-container {
      background-color: #202c33;
    }

    .custom-form-input {
      background-color: #2a3942;
    }

    .container-upload-files {
      background-color: #101a20;

      .list-files {
        border-top: 1px solid #2b3b45;

        .file {
          border: 1px solid #2b3b45;
        }
      }
    }
  }
}
</style> 