export default {
    namespaced: true,
    state: {
        S_COUNTER_DIRECT_ROUND_SPECIALISTS:0
    },
    mutations: {
        M_SET_COUNTER_DIRECT_ROUND_SPECIALISTS(state, payload = { pending: 0 }) {
            state.S_COUNTER_DIRECT_ROUND_SPECIALISTS = payload.pending
        }
    },
    actions: {
        A_SET_COUNTER_DIRECT_ROUND_SPECIALIST({ commit }, payload = { pending: 0 }) {
            commit('M_SET_COUNTER_DIRECT_ROUND_SPECIALISTS', payload)
        }
    },
    getters: {
        G_COUNTER_PENDING_DIRECT_ROUND_SPECIALISTS: state => state.S_COUNTER_DIRECT_ROUND_SPECIALISTS,
    }
}