export default [
    {
        path: "merchandising-logistics",
        name: "merchandising-logistics",
        component: () => import("@/views/logistic/views/merchandising/Merchandising.vue"),
        redirect: { name: "lo-list-merchandising" },
        children: [
            {
                path: 'merchandising',
                component: () => import(/* webpackChunkName: "LogisticMerchandising" */ "@/views/logistic/views/merchandising/components/MerchandisingGrillas.vue"),
                name: 'lo-list-merchandising',
                meta: {
                    pageTitle: 'Merchandising',
                    breadcrumb: [
                        {
                            text: 'Merchandising',
                            permittedRoles: [2, 1, 3],
                        },
                    ],
                },
            },
        ]
    },
];