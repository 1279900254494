import customerTickets from '@/views/debt-solution/views/customer-tickets/debt-solution-customer-tickets.navigation';
import clients from '@/views/debt-solution/views/clients/clients.navigation';
// eslint-disable-next-line import/no-cycle


// const childs = () => {
//   console.log(auth.state.currentUser, 'dropdown');
//   const userId = auth.state.currentUser ? auth.state.currentUser.user_id : 0;
//   // Verificar si this.currentUser.user_id es igual a 1
//   if (userId === 1) {
//     // Si es 1, no incluir customerTickets en los children
//     return [clients];
//   }
//   // Si no es 1, incluir customerTickets en los children
//   return [clients, customerTickets];
// };
export default [ {
  title: 'Clients',
  icon: 'UsersIcon',
  children: [clients, customerTickets],
  tag: 0
}];
