<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    :title="validateCommission ? 'PAYMENT PENALTY' : 'PAYMENT CHARGEBACK'"
    @hidden="closeModal"
    modal-class="custom_modal_charge_back"
  >
    {{ type_responsible }} --
    <b-row>
      <b-col>
        <b-card
          footer-class="p-2"
          header="CLIENT"
          header-class="p-1"
          :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
          header-text-variant="white"
          header-tag="header"
          footer-tag="footer"
          no-body
        >
          <template #footer>
            <div style="margin: 9px">{{ client.client_name }}</div>
          </template>
        </b-card></b-col
      >
      <b-col
        ><b-card
          footer-class="p-2"
          header="PROGRAM"
          header-class="p-1"
          :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
          header-text-variant="white"
          header-tag="header"
          footer-tag="footer"
          no-body
        >
          <template #footer>
            <div style="margin: 9px">{{ client.program_name }}</div>
          </template>
        </b-card></b-col
      >
      <b-col v-if="payment == 1">
        <b-card
          footer-class="p-2"
          header="AMOUNT"
          header-class="p-1"
          :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
          header-text-variant="white"
          header-tag="header"
          footer-tag="footer"
          no-body
        >
          <template #footer>
            <money
              id="input-2"
              v-model="ammount"
              v-bind="maskMoney"
              class="form-control"
              disabled
            />
          </template> </b-card
      ></b-col>
      <b-col v-else>
        <b-card
          footer-class="p-2"
          header="PENALITY"
          header-class="p-1"
          :header-bg-variant="isDarkSkin ? 'secondary' : 'primary'"
          header-text-variant="white"
          header-tag="header"
          footer-tag="footer"
          no-body
        >
          <template #footer>
            <money
              id="input-3"
              v-model="amountCommission"
              v-bind="maskMoney"
              class="form-control"
              disabled
            />
          </template> </b-card
      ></b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-table
          ref="refCards"
          small
          show-empty
          :fields="fields"
          :items="searchCards"
          class="font-small-3 text-center"
          sticky-header="50vh"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:cell(radio)="data">
            <b-form-radio
              v-model="card_id"
              :disabled="data.item.count_declined >= 2"
              :value="data.item.id"
              :name="'card' + data.item.id"
              @change="changeCard(data.item.type_card)"
            />
          </template>

          <template v-slot:cell(card_holder_name)="data">
            <p class="mb-0 font-weight-bold">
              {{ data.item.cardholdername }}
            </p>
          </template>
          <template v-slot:cell(card_number)="data">
            <p class="mb-0 font-weight-bold">
              {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }}
            </p>
          </template>
          <template v-slot:cell(type)="data">
            <p class="mb-0 font-weight-bold">
              {{ data.item.type_card }}
            </p>
          </template>
          <template v-slot:cell(mm)="data">
            <p class="mb-0 font-weight-bold">
              {{ data.item.card_expi_month }}
            </p>
          </template>
          <template v-slot:cell(yy)="data">
            <p class="mb-0 font-weight-bold">
              {{ data.item.card_expi_year }}
            </p>
          </template>
          <template v-slot:cell(cvc)="data">
            <p class="mb-0 font-weight-bold">
              {{
                data.item.cardsecuritycode != null
                  ? data.item.cardsecuritycode.length == 3
                    ? "XX" + data.item.cardsecuritycode.substr(2)
                    : "XXX" + data.item.cardsecuritycode.substr(3)
                  : ""
              }}
            </p>
          </template>
          <template v-slot:cell(created_by)="data">
            <p class="mb-0 font-weight-bold">
              <span>{{ data.item.created_by }}</span>
              <br />
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </p>
          </template>
          <template v-slot:cell(actions)="data">
            <feather-icon
              size="16"
              icon="TrashIcon"
              class="cursor-pointer text-danger"
              @click="openDeleteCreditCard(data.item.id)"
            />
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="d-flex justify-content-end">
          <b-button variant="success" @click="openCreateCreditCard">
            ADD</b-button
          >
        </div>
      </b-col>
    </b-row>
    <delete-credit-card
      v-if="deleteCreditOn"
      :card_id="card_id"
      :lead_id="client.lead_id"
      @refresh="refresh"
      @close="closeDeleteCreditCard"
    />
    <create-credit-card
      v-if="createCreditOn"
      :lead="client.lead_id"
      @reload="refresh"
      @close="closeCreateCreditCard"
    />
    <template #modal-footer>
      <b-button
        class="d-flex justify-content-center"
        variant="primary"
        @click="insertPayment"
      >
        <b-spinner v-if="spinnerOn" small />
        CHARGE
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import dataFields from "@/views/commons/components/clients/dashboard/options/pay-module/modals/monthly-payment-modal/fields.data.js";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import vSelect from "vue-select";
import DeleteCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/DeleteCreditCard.vue";
import CreateCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue";
import ChargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    vSelect,
    DeleteCreditCard,
    CreateCreditCard,
  },
  props: {
    chargeBack: {
      type: Object,
      default: null,
    },
    payment: {
      type: Number,
      default: null,
    },
    type_responsible: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      ammount: 0,
      spinnerOn: false,
      type_card: "",
      cantcards: [],
      isBusy: false,
      fields: dataFields,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
      },
      ownControl: false,
      card_id: null,
      deleteCreditOn: false,
      createCreditOn: false,
      transaction_id: null,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    amountCommission() {
      return this.chargeBack.amount_penalty;
    },
    moduleId() {
      return this.$route.matched[0].meta.module == 28
        ? 11
        : this.$route.matched[0].meta.module;
    },
    validateCommission() {
      if (this.chargeBack.status_commission == 1) {
        return true;
      }
    },
    isStatusResponsible() {
      return this.$route.matched[3]?.meta.status;
    },
    isRemoveSubTab() {
      return this.isInProcessTab || this.isDisputeTab
        ? false
        : this.$route.matched[3]?.meta.status == 1;
    },
    isPaymentSubTab() {
      return this.isInProcessTab || this.isDisputeTab
        ? false
        : this.$route.matched[3]?.meta.status == 2;
    },
    isSentSubTab() {
      return this.isInProcessTab || this.isSolutionTab
        ? false
        : this.$route.matched[3]?.meta.status == 3;
    },
    amountParam() {
      return this.payment == 1
        ? this.chargeBack.amount
        : this.chargeBack.amount_penalty;
    },
    statusCommissionParam() {
      if (this.isPaymentSubTab) {
        if (
          this.chargeBack.status_commission == 1 ||
          this.chargeBack.status_commission == null
        ) {
          return 1;
        } else {
          return 0;
        }
      } else if (this.isRemoveSubTab || this.isSentSubTab) {
        return 0;
      }
    },
    typeModalityTransactionParam() {
      return this.payment == 1 ? 6 : 7;
    },
  },
  created() {
    this.ammount = this.chargeBack.amount;
    this.ownControl = true;
  },
  methods: {
    ...mapActions({
      A_CHARGEBACK_COUNTERS: "ChargeBackStoreV2/A_CHARGEBACK_COUNTERS",
      A_IN_PROCESS_AND_SOLUTION_CHARGEBACK_COUNTER:
        "ChargeBackStoreV2/A_IN_PROCESS_AND_SOLUTION_CHARGEBACK_COUNTER",
    }),
    closeCreateCreditCard() {
      this.createCreditOn = false;
    },
    openCreateCreditCard() {
      this.createCreditOn = true;
    },
    refresh() {
      this.$refs.refCards.refresh();
    },
    openDeleteCreditCard(id) {
      this.card_id = id;
      this.deleteCreditOn = true;
    },
    closeDeleteCreditCard() {
      this.deleteCreditOn = false;
    },
    changeCard(id) {
      this.type_card = id;
    },
    closeModal() {
      this.$emit("close");
    },

    async insertPayment() {
      try {
        this.addPreloader();
        const params = {
          charge_back_id: this.chargeBack.charge_back_id,
          create_by: this.currentUser.user_id,
          type_transaction: 15,
          idcard: this.card_id,
          program: this.chargeBack.program_id,
          account: this.chargeBack.client_account,
          merchant: this.chargeBack.merchant_id,
          amount: this.amountParam,
          status_commission: this.statusCommissionParam,
          type_responsible: this.type_responsible ?? this.isStatusResponsible,
          transaction_id: this.transaction_id,
          modality_transaction_id: this.typeModalityTransactionParam,
        };

        const data = await ChargeBackService.insertPayment(params);
        if (data.status === 200 && data.data.status == "200") {
          const { responseCode } = data.data.transaction;
          if (responseCode === "1") {
            this.closeModal();
            this.A_CHARGEBACK_COUNTERS(this.moduleId);
            this.$emit("reload");
            this.showSuccessSwal(
              data.data.transaction.messages.message[0].description
            );
            await this.A_IN_PROCESS_AND_SOLUTION_CHARGEBACK_COUNTER(
              this.moduleId
            );
          }
          if (
            responseCode === "2" ||
            responseCode === "3" ||
            responseCode === "4"
          ) {
            const errorsLengt = data.data.transaction.errors.error.length;
            for (let i = 0; i < errorsLengt; i++) {
              this.spinnerOn = false;

              this.showToast(
                "danger",
                "top-right",
                data.data.transaction.errors.error[i].errorText,
                "XIcon",
                "Invalid"
              );
            }
            this.showErrorSwal(
              data.data.transaction.messages.message[0].description,
              "Transaction Declined",
              "warning"
            );
          }
          if (responseCode === "") {
            this.showConfirmSwal(
              data.data.transaction.messages.message[0].description
            );
            this.closeModal();
          }
          await this.A_IN_PROCESS_AND_SOLUTION_CHARGEBACK_COUNTER(
            this.moduleId
          );
          this.$emit("reload");
        }
      } catch (e) {
        console.log(e);
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async searchCards() {
      try {
        this.addPreloader();
        const data = await ClientsOptionsServices.searchcards({
          id: this.client.lead_id,
        });
        this.cantcards = data.data;

        return this.cantcards;
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
.custom_modal_charge_back .modal-lg {
  max-width: 53vw !important;
}
@media screen and (max-width: 767px) {
  .custom_modal_charge_back .modal-lg {
    max-width: 100vw !important;
  }
}
</style>
