<template>
  <b-modal
    v-model="showModal"
    size="xl"
    header-bg-variant="transparent"
    hide-footer
    centered
    @hidden="closeModal"
  >
    <template #modal-title>
      <h4
        class="font-weight-bolder d-inline-flex mt-1"
        :class="isDarkSkin ? 'text-light' : 'text-my-dark'"
      >
        My Clients
      </h4>
      <b-button
        v-if="isCeo"
        variant="outline-primary"
        size="sm"
        class="ml-1"
        @click="openModalHistoryClientOfCeo"
      >
        Previous ceo clients
      </b-button>
    </template>
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="[]"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="refreshTableReload()"
    >
      <b-table
        id="refResponsibleList"
        slot="table"
        ref="refResponsibleList"
        :items="getMyClientsProvider"
        :fields="fieldsMyClients"
        primary-key="id"
        table-class="text-nowrap"
        sticky-header="45vh"
        show-empty
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :head-row-variant="'grid-custom-tickets-header'"
        class="table-scroll-per"
        style="border-radius: 7px"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(name_client)="data">
          <div>
            <strong class="m-0">
              <b-row>
                <b-col cols="9" style="margin-bottom: 5px">
                  <!-- {{ data.item }} -->

                  <router-link
                    v-if="isCeo"
                    v-b-tooltip.hover.top="'Lead dashboard'"
                    :class="textLink"
                    :to="{
                      name: 'ticket-customer-lead-show',
                      params: {
                        id: data.item.lead_id_client,
                      },
                    }"
                    target="_blank"
                    >{{ data.item.name_client }}</router-link
                  >

                  <router-link
                    v-else
                    v-b-tooltip.hover.top="'Lead dashboard'"
                    class="text-capitalize"
                    style="font-size: 16px"
                    :class="textLink"
                    :to="`/${
                      isCeo ? 'debtsolution' : routeModule(currentUser.modul_id)
                    }/leads/${
                      currentUser.modul_id === 2 || currentUser.modul_id === 4
                        ? ''
                        : currentUser.modul_id === 15
                        ? 'new/dashboard/'
                        : 'show/'
                    }${data.item.lead_id_client}`"
                    target="_blank"
                    >{{ data.item.name_client }}</router-link
                  >
                </b-col>
              </b-row>
              <p
                v-if="data.item.number_phone"
                class="m-0"
                style="
                  font-family: Montserrat;
                  font-size: 1.2rem;
                  font-weight: 500;
                  margin-bottom: 5px;
                "
              >
                <b-badge variant="light-primary">
                  <b-icon icon="telephone-fill" aria-hidden="true" />
                  {{ data.item.number_phone }}
                </b-badge>
              </p>
            </strong>
          </div>
          <b-button
            size="sm"
            variant="info"
            style="margin-top: 5px"
            @click="openSeeClientRelevantInformation(data.item)"
          >
            See more information
          </b-button>
        </template>
        <template #cell(accounts)="data">
          <div v-if="data.item.accounts">
            <ul class="p-0">
              <li
                v-if="data.item.client_account_id"
                class="d-flex"
                style="align-items: center; gap: 1rem"
              >
                <!-- <div
                  v-b-tooltip.hover.top=" data.item.status_account "
                  v-b-tooltip.hover.v-primary
                  style="height: 10px; width: 10px; border-radius: 50%;"
                  :style="'background: ' + filterColor(parseInt(data.item.ca_status)) + ';'"
                /> -->

                <status-account :account="data.item" :text="false" />

                <router-link
                  :class="'text-primary'"
                  :to="{
                    name: dashboardRouteNameByClient(data.item),
                    params: {
                      idClient: data.item.client_account_id,
                    },
                  }"
                  target="_blank"
                >
                  {{ data.item.account }}
                </router-link>
                <span> Created {{ data.item.created_at | myGlobal }} </span>
              </li>
              <li
                v-for="account in uniqueAccounts(
                  JSON.parse(data.item.accounts),
                  data.item.client_account_id
                )"
                :key="account.id"
                class="d-flex"
                style="align-items: center; gap: 1rem"
              >
                <!-- <div
                  v-b-tooltip.hover.top=" account.status_account "
                  v-b-tooltip.hover.v-primary
                  style="height: 10px; width: 10px; border-radius: 50%;"
                  :style="'background: ' + filterColor(parseInt(account.ca_status)) + ';'"
                /> -->
                <status-account :account="account" :text="false" />

                <router-link
                  :class="'text-primary'"
                  :to="{
                    name: dashboardRouteNameByClient(account),
                    params: {
                      idClient: account.id,
                    },
                  }"
                  target="_blank"
                >
                  {{ account.account }}
                </router-link>
                <span> Created {{ account.created_at | myGlobal }} </span>
              </li>
            </ul>
          </div>
        </template>
        <template #cell(created_at)="data">
          <span v-if="data.item.is_a_client_since">
            Client since <br />
            <strong>
              {{ data.item.is_a_client_since | myGlobalDay }}
            </strong>
          </span>
          <span v-else>
            Lead since <br />
            <strong>
              {{ data.item.is_a_lead_since | myGlobalDay }}
            </strong>
          </span>
        </template>
        <template #cell(tickets)="data">
          <!-- vertical -->
          <div
            class="d-flex"
            style="
              flex-direction: column;
              align-items: flex-start;
              margin-left: 30px;
            "
          >
            <span
              v-for="ticket in JSON.parse(
                data.item.tickets_lead || data.item.tickets_account
              )"
              :key="ticket.id"
              style="padding: 5px 3px; margin: 3px"
            >
              <b-button
                variant="outline-primary"
                size="sm"
                style="margin-top: 3px"
                @click="redirect(ticket)"
              >
                {{ ticket.code }}
                <feather-icon icon="SearchIcon" size="15" />
              </b-button>
              <span
                style="
                  border-radius: 5px;
                  padding: 3px 10px !important;
                  color: white !important;
                  font-size: 12px;
                  margin-left: 5px;
                "
                :class="colorStatus(ticket)"
              >
                {{ ticket.status.toUpperCase() }}
              </span>
            </span>
          </div>
          <ul class="p-0 d-none">
            <!-- Listar los tickets de tickets_lead o tickets_account -->
            <li
              v-for="ticket in JSON.parse(
                data.item.tickets_lead || data.item.tickets_account
              )"
              :key="ticket.id"
              class="d-flex align-items-center justify-content-center"
            >
              <b-button
                variant="outline-primary"
                size="sm"
                style="margin-top: 5px"
                @click="redirect(ticket)"
              >
                {{ ticket.code }}
                <feather-icon icon="SearchIcon" size="15" />
              </b-button>
            </li>
          </ul>
        </template>

        <template #cell(actions)="data">
          <span v-if="isCeo">
            <div
              class="custom-switch"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 5px;
              "
            >
              <strong
                :class="data.item.user_responsible === 1 ? 'text-primary' : ''"
              >
                It is my client
              </strong>
              <b-form-checkbox
                :id="`responsible_${data.item.lead_id}`"
                v-model="data.item.user_responsible"
                switch
                :value="1"
                style="margin-left: 5px"
                @change="handleUserResponsibleChange(data.item, $event)"
              />
            </div>
          </span>
        </template>
        <template #cell(client_ceo)="data">
          <strong style="font-size: 13px" class="p-1 text-info">
            <template v-if="data.item.user_responsible_date">From</template>
            {{ data.item.user_responsible_date | myGlobal }}
          </strong>
          <feather-icon
            icon="ListIcon"
            size="20"
            style="margin-left: -5px"
            class="ml-1 cursor-pointer text-info"
            @click="openModalTrackingClientOfCeo(data.item)"
          />
        </template>
      </b-table>
    </filter-slot>
    <modal-see-client-relevant-information
      v-if="modalSeeClientRelevantInformationControl"
      :customer-ticket="clientInformation"
      @close="modalSeeClientRelevantInformationControl = false"
    />
    <modal-tracking-client-of-ceo
      v-if="showModalTrackingClientOfCeo"
      :lead="lead"
      @closeModal="showModalTrackingClientOfCeo = false"
    />
    <modal-history-client-of-ceo
      v-if="showModalHistoryClientOfCeo"
      @closeModal="showModalHistoryClientOfCeo = false"
    />
  </b-modal>
</template>
<script>
import Fields from "@/views/commons/components/customer-tickets/data/fields-my-clients";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import { mapGetters, mapActions } from "vuex";
import ModalSeeClientRelevantInformation from "@/views/commons/components/customer-tickets/components/modal/ModalSeeClientRelevantInformation.vue";
import ModalTrackingClientOfCeo from "@/views/commons/components/customer-tickets/components/modal/ModalTrackingMyClient.vue";
import ModalHistoryClientOfCeo from "@/views/commons/components/customer-tickets/components/modal/ModalHistoryMyClient.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    ModalSeeClientRelevantInformation,
    ModalTrackingClientOfCeo,
    ModalHistoryClientOfCeo,
    StatusAccount,
  },
  props: {},
  data() {
    return {
      showModal: false,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      isBusy: false,
      totalRows: 0,
      startPage: null,
      toPage: null,
      sortBy: "",
      sortDesc: true,
      dataGrid: [],
      fieldsMyClients: Fields,
      modalSeeClientRelevantInformationControl: false,
      clientInformation: null,
      lead: {},
      showModalTrackingClientOfCeo: false,
      showModalHistoryClientOfCeo: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.showModal = true;
  },
  methods: {
    openModalHistoryClientOfCeo() {
      this.showModalHistoryClientOfCeo = true;
    },
    openModalTrackingClientOfCeo(item) {
      this.lead = {
        id: item.lead_id,
        name: item.name_client,
      };
      this.showModalTrackingClientOfCeo = true;
    },
    colorStatus(item) {
      const color = {
        1: "bg-secondary",
        2: "bg-primary",
        3: "bg-warning",
        4: "bg-danger",
        5: "bg-info",
        6: "bg-solved-fidelized",
        7: "bg-secondary",
      };
      return color[item.id_status];
    },
    redirect(item) {
      const routeParams = {
        code: item.code,
        searchByCode: true,
        module: item.module_id,
      };

      let routeName = "";
      const type = item.type == 'payments' ? 'payment' : item.type
      // Establecer el nombre de la ruta según las condiciones
      if (item.id_status === 5 || item.id_status === 6) {
        routeName = `${type}-ticket-ceo-in-progress`;
      } else if (item.id_status === 4) {
        routeName = `${type}-ticket-ceo-rejected`;
      } else if (item.id_status === 3) {
        routeName = `${type}-ticket-ceo-in-progress`;
      } else if (
        item.id_status === 2 ||
        (item.created_by === 1 && item.id_status === 1)
      ) {
        routeName = `${type}-ticket-ceo-new`;
      } else if (item.id_status === 7) {
        routeName = `${type}-ticket-ceo-not-solved`;
      }
      this.$store.dispatch("TicketCustomerStore/A_SET_CODE", item.code);
      // Realizar la navegación utilizando 'replace' en lugar de 'push'
      if (routeName) {
        this.$nextTick(() => {
          this.$router
            .replace({
              name: routeName,
              params: routeParams,
            })
            .catch((error) => {
              // Manejar específicamente la excepción NavigationDuplicated
              if (
                error.name === "NavigationDuplicated" &&
                error.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                if (routeName === `${item.type}-ticket-ceo-in-progress`) {
                  this.$root.$emit("redirect-to-module", routeParams);
                }
                // Puedes omitir la excepción ya que se trata de una navegación redundante
              } else {
                // Lanzar otras excepciones
                throw error;
              }
            });
        });
      }
      // Emitir un evento personalizado con la variable
      this.$emit("closeModalSearch");
      this.$emit("closeModal");
    },
    async handleUserResponsibleChange(lead, value) {
      const result = await this.showConfirmSwal();
      if (result.isConfirmed) {
        try {
          const params = {
            ticket_id: lead.tickets_lead
              ? JSON.parse(lead.tickets_lead)[0].id
              : JSON.parse(lead.tickets_account)[0].id,
            user_id: value === 1 ? this.currentUser.user_id : null,
          };
          const data = await CustomerTicketsService.setUserResposible(params);
          if (data.status === 200) {
            this.refreshTableReload();
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        lead.user_responsible = lead.user_responsible === 1 ? 0 : 1;
      }
    },
    refreshTableReload() {
      this.$refs.refResponsibleList.refresh();
    },
    uniqueAccounts(accounts, clientAccountId) {
      // Filtrar la lista para eliminar elementos con el mismo id que client_account_id
      return accounts.filter((account) => account.id !== clientAccountId);
    },
    openSeeClientRelevantInformation(data) {
      this.modalSeeClientRelevantInformationControl = true;
      this.clientInformation = data;
    },
    routeModule(module) {
      const routes = {
        6: "creditexperts",
        21: "creditexperts",
        22: "cedigital/customerservice",
        23: "cedigital/financial",
        26: "cedigital/sales",
        20: "cedigital/connection",
        4: "administration",
        2: "crm",
        3: "bussiness/leads",
        7: "boostcredit",
        18: "quality",
        8: "taxresearch",
        11: "specialists",
        25: "specialistdigital",
        12: "ti", // paragon
        17: "rrhh",
        5: "debtsolution",
        10: "claimdepartment", // court info
        19: "crm",
        14: "bookeeping",
        15: "socialnetwork",
      };
      return routes[module];
    },
    filterColor(value) {
      switch (value) {
        case 1:
          return "#00cc00";
        case 2:
          return "#ffc107";
        case 3:
          return "#0066ff";
        case 4:
          return "red";
        case 5:
          return "red";
        case 6:
          return "red";
        // case 7: return 'white';
        // case 8: return 'pink';
        // case 9: return 'pinkhot';
        // case 10: return 'orange';
        // case 11: return 'brow';
        // case 12: return 'lightblue';
        default:
          return "gray";
      }
    },
    dashboardRouteNameByClient(account) {
      let route = null;
      if (account.program_id !== 3) {
        const routers = {
          1: "bussiness-dashboard-general",
          2: "boost-credit-dashboard-general",
          // 3: 'credit-experts-dashboard-general',
          4: "debt-solution-dashboard-general",
          5: "tax-research-dashboard-general",
          6: "court-info-dashboard-general",
          7: "specialists-dashboard-general",
          8: "bookeeping-dashboard-general",
          9: "paragon-dashboard-general",
          // 18: 'quality-client-dashboard',
          // 22: 'ce-customer-service-dashboard',
        };
        route = routers[account.program_id] || null;
      }
      if (account.program_id === 3) {
        if (account.client_type_id === 1)
          route = "credit-experts-dashboard-general";
        if (account.client_type_id === 2)
          route = "ce-customer-service-dashboard-general";
      }
      return route;
    },
    async getMyClientsProvider() {
      try {
        // this.searchGlobal es para el modal del buscador global
        const params = {
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sortBy: null,
          sortOrder: "DESC",
          orderBy: 10,
          order: "DESC",
          user_id: this.currentUser.user_id,
          search_text: this.filterPrincipal.model,
        };

        const data = await CustomerTicketsService.getMyClientsList(params);
        if (data.status === 200) {
          this.dataGrid = data.data.data;
          this.totalRows = data.data.total;
          this.startPage = data.data.from || 0;
          this.toPage = data.data.to || 0;
          this.paginate.currentPage = data.data.current_page;
          this.paginate.perPage = data.data.per_page;
          this.removePreloader();
          return this.dataGrid;
        }
      } catch (error) {
        this.showErrorSwal(error);
        console.error(error);
      }
      return [];
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style scoped>
.bg-solved-fidelized {
  background-color: #00853a !important;
}
</style>
