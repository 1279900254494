import acceptFiles from "@/views/ce-digital/sub-modules/customer-service/views/file-app/pusher/accept-files.js";
import changeInProgramCommission from "@/views/crm/views/manage-commissions/socket/change-in-program-commission";
import approveDisapproveProgramCommission from "@/views/crm/views/manage-commissions/socket/approve-disapprove-program-commission.pusher";
import alertChangeStatusLeadSn from "@/views/social-network/views/leads/change-status-lead.pusher";
import uploadPifNotification from "@/views/debt-solution/views/settlements-made/socket/upload-pif-notification.js";
// import ApplicationsRequestNotification from '@/views/specialists/views/applications/pusher/applications-request-nofitications.pusher';
import ApplicationsRequestNotification from "@/views/specialists/sub-modules/analyst-department/views/applications/pusher/applications-request-notifications.pusher.js";
import MarkAttendanceSocket from "@/views/assistance/views/dashboard/socket/mark-attendance.socket";
import ChangeStatusUser from "@/views/assistance/views/dashboard/socket/ChangeStatusUser.socket";
import JustificationCreatedSocket from "@/views/assistance/views/dashboard/socket/justification-created.socket";
import PayrollNotification from "@/views/commons/components/payroll-payments/socket/payroll-notification.socket";
import { RequestNotificationSocket } from "@/views/logistic/socket/request.socket.js";
import notificationRequestRefundVoidToManagement from "@/views/commons/components/charge-back_v2/socket/notification-refund-void-to-management";
import SendPayrollNotification from "@/views/commons/components/payroll-payments/socket/send-payroll-notification.socket";

import {
  NotifyNewEmployeeClaimMessage,
  UpdateClaimStatusNotification,
  sendSocketNotificationCreative,
  updateCounterSiderbarCreativeAndParagon,
  updateDueDateNotifications,
} from "@/views/commons/socket/employee-notifications.socket.js";
import closeProject from "@/views/commons/components/projects/socket/close-project.socket";
import TicketMessaging from "@/views/commons/components/projects/socket/tickets-messaging.socket";
import meetingNotification from "@/views/commons/components/projects/socket/meetings-notification.socket";
import notificationDeliverable from "@/views/commons/components/projects/socket/notificatiion-deliverable.socket.js";
import seeMessages from "@/views/commons/components/projects/socket/see-messages.socket";
import canCloseProject from "@/views/commons/components/projects/socket/can-close-project.socket";
import fixObservation from "@/views/commons/components/projects/socket/fix-observation.socket";
import {
  captureDocusignEvent,
  docusignClosePreloader,
} from "@/views/commons/socket/docusign-notifications.socket.js";
import notificaAnsweredRequestTransaction from "@/views/management/views/requests-transactions/socket/notification-answered-request-transaction";
import notificationEssentialDocuments from "@/commons/users/sockets/notification-essential-documents.js";
import notificationManualsOfFunctions from "@/views/commons/components/manual-of-functions/socket/manuals-of-functions.socket.js";
import notificationUpdatedAccountEmployee from "@/views/commons/components/charge-back_v2/socket/notification-updated-account-employee";
import notificationRecruitmentProcess from "@/views/commons/components/recruitment-process/socket/recruitment-process.socket.js";
import CorpMailStatusSocket from "@/views/paragon/views/new-employees/sockets/corp-mail-status.socket.js";
import NotifyPostulantChief from "@/views/commons/components/recruitment-process/socket/notify-postulant-chief.socket.js";
import NotificationUpdatedPensionFundEmployee from "@/views/commons/components/charge-back_v2/socket/notification-updated-pension-fund-employee";
import { ChangeStatusNotification } from "@/views/commons/components/clients/dashboard/socket/client-notification";
import NotificationParticipants from "@/views/commons/participants-tickets/socket/NotificationParticipants.socket";
import UserStatusEvent from "@/views/commons/participants-tickets/socket/UserStatusEvent.socket";
import { checkTransactionStatus } from "@/views/commons/socket/check-transaction-status.socket.js";
import SendPayrollNotificationSocket from "@/views/commons/components/payroll-payments/socket/send-payroll-notification.socket";
import NotificationStatusMeeting from "@/views/commons/components/customer-tickets/sockets/notification-status-meeting.socket";
import NotificationDeletedMeeting from "@/views/commons/components/customer-tickets/sockets/notification-deleted-meeting.socket";
import RefreshTaskBadges from "@/views/commons/components/customer-tickets/sockets/refresh-task-badges.socket.js";

import {
  captureParagonSoftNewRequestEvent,
  createMeetingParagonSoft,
} from "@/views/commons/socket/paragon-soft-new-request-notifications.socket.js";

const subscribeSocket = (socket) => {
  try {
    socket.subscribe("channel-chat");
    socket.subscribe("employee-claim");
    socket.subscribe("docusign-channel");
    socket.subscribe("channel-check-transaction-status");
    socket.subscribe("transcribe");

    socket.subscribe("notification-update-status");
    socket.subscribe("notification-update-counter");
    socket.subscribe("notification-update-due-date");
    socket.subscribe("paragon-soft-channel");
    socket.subscribe("facebook-ads-channel");
    acceptFiles();
    changeInProgramCommission();
    sendSocketNotificationCreative();
    updateCounterSiderbarCreativeAndParagon();
    updateDueDateNotifications();
    approveDisapproveProgramCommission();
    alertChangeStatusLeadSn();
    uploadPifNotification();
    ApplicationsRequestNotification();
    MarkAttendanceSocket();
    ChangeStatusUser();
    JustificationCreatedSocket();
    PayrollNotification();
    UpdateClaimStatusNotification();
    NotifyNewEmployeeClaimMessage();
    RequestNotificationSocket();
    closeProject();
    meetingNotification();
    TicketMessaging();
    notificationDeliverable();
    seeMessages();
    canCloseProject();
    fixObservation();
    notificationRequestRefundVoidToManagement();
    notificaAnsweredRequestTransaction();
    captureDocusignEvent();
    docusignClosePreloader();
    notificationEssentialDocuments();
    notificationManualsOfFunctions();
    captureDocusignEvent();
    docusignClosePreloader();
    notificationEssentialDocuments();
    notificationUpdatedAccountEmployee();
    notificationRecruitmentProcess();
    CorpMailStatusSocket();
    NotifyPostulantChief();
    NotificationUpdatedPensionFundEmployee();
    ChangeStatusNotification();
    NotificationParticipants();
    UserStatusEvent();
    SendPayrollNotification();
    NotificationStatusMeeting();
    checkTransactionStatus();
    NotificationDeletedMeeting();
    RefreshTaskBadges();
    captureParagonSoftNewRequestEvent();
    createMeetingParagonSoft();
  } catch (error) {
    console.error(error, "error");
  }
};

export default subscribeSocket;
