

export default [
  {
    path: 'callround',
    name: 'customerservice-callround',
    redirect: { name: 'customerservice-callround-pending' },
    component: () => import(/* webpackChunkName: "CEDCustomerServiceCallround" */ "@/views/ce-digital/sub-modules/customer-service/views/call-round/view/components/Main.vue"),
    meta: {
      pageTitle: 'Call rounds',
      permittedRoles: [1, 2, 14, 15, 17],
      breadcrumb: [
        {
          text: 'Call rounds',
          active: true,
        },
      ],
    },
    children: [
      {
        path: 'pending',
        component: () => import(/* webpackChunkName: "CEDCustomerServiceCallroundPending" */ "@/views/ce-digital/sub-modules/customer-service/views/call-round/view/components/Grid.vue"),
        name: 'customerservice-callround-pending',
        meta: {
          pageTitle: 'Call rounds',
          breadcrumb: [
            {
              text: 'Pending',
              active: true,
            },
          ],
          tab: 1,
          permittedRoles: [1, 2, 14, 15, 16, 17],

        },

      },
      {
        path: 'completed',
        component: () => import(/* webpackChunkName: "CEDCustomerServiceCallroundCompleted" */ "@/views/ce-digital/sub-modules/customer-service/views/call-round/view/components/Grid.vue"),
        name: 'customerservice-callround-completed',
        meta: {
          pageTitle: 'Call rounds',
          breadcrumb: [
            {
              text: 'Completed',
              active: true,
            },
          ],
          tab: 2,
          permittedRoles: [1, 2, 14, 15, 16, 17],
        },
      },
    ],

  },
]
