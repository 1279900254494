<template>
  <div>
    <b-modal
      ref="lead-add-transaction-modal"
      @hidden="$emit('hidden')"
      size="payment"
      scrollable
      header-class="custom-modal-header"
      content-class="custom-modal-content"
      modal-class="lead-add-transaction-modal"
      no-enforce-focus
      centered
    >
      <!-- TITLE MODAL -->
      <template #modal-header="{ close }">
        <div>New Payment</div>
        <feather-icon
          icon="XCircleIcon"
          class="close-icon cursor-pointer"
          size="23"
          @click="close()"
        />
      </template>
      <!-- BODY MODAL -->
      <template #default>
        <ValidationObserver ref="form">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group
                class="lead-input"
                id="lead"
                label="Lead"
                label-for="lead"
              >
                <div class="lead-name">
                  {{ leadName }}
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group
                class="lead-input"
                id="concept"
                label="Concept"
                label-for="concept"
              >
                <div class="lead-name">
                  {{ concept }}
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                class="lead-input"
                id="payment-method"
                label-for="payment-method"
              >
                <template #label>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span> Choose a Card </span>
                    <div>
                      <b-button
                        variant="primary"
                        size="sm"
                        @click="$bvModal.show(`modal-card-create`)"
                      >
                        <feather-icon icon="PlusIcon" size="13" />
                        Add
                      </b-button>
                    </div>
                  </div>
                </template>
                <div class="payment-card-container">
                  <div
                    class="payment-card"
                    :class="{
                      active: card.active,
                      'border-danger': !validateCard && fistValidateCard,
                    }"
                    v-for="card in cards"
                    :key="card.id"
                    @click="selectCard(card)"
                  >
                    <div class="payment-card-customer-information">
                      <span class="cu-name">
                        {{ card.cardholdername }}
                      </span>
                      <span class="cu-card"> XXXX-{{ card.cardnumber }} </span>
                    </div>
                    <div>
                      <feather-icon
                        icon="TrashIcon"
                        size="18"
                        class="text-danger cursor-pointer"
                        @click.stop.prevent="deleteCard(card.id)"
                      />
                    </div>
                  </div>
                </div>
                <span
                  v-if="paymentInformation.cardId == null"
                  class="text-danger"
                  >Please select a card</span
                >
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="lead-input"
                id="amount-input"
                label="Amount"
                label-for="amount-input"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="amount-input"
                  rules="required|validate-amount"
                >
                  <money
                    v-model="paymentInformation.amount"
                    v-bind="{
                      decimal: '.',
                      thousands: ',',
                      prefix: '$ ',
                      precision: 2,
                    }"
                    class="amount-input"
                    :class="{ 'border-danger': errors[0] && validateMoney }"
                  />
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                class="lead-input"
                id="payment-date"
                label="Payment Date"
                label-for="payment-date"
              >
                <div class="lead-name">{{ moment().format("MM/DD/YYYY") }}</div>
              </b-form-group>
            </b-col>
          </b-row>
        </ValidationObserver>
      </template>
      <template #modal-footer="{ close }">
        <b-button variant="outline-secondary" class="rounded" @click="close()">
          Cancel
        </b-button>
        <b-button
          variant="primary"
          class="rounded button-collect"
          @click="collectPayment()"
        >
          Collect Payment
        </b-button>
      </template>
    </b-modal>
    <b-modal
      :id="`modal-card-create`"
      modal-class="modal-primary"
      title-class="h3 text-white"
      centered
      size="lg"
      title="Create Credit Card"
      hide-footer
      no-close-on-backdrop
    >
      <modal-card-new-create
        :lead="{ id: leadId }"
        @onReloadCards="getLeadCards()"
        @closeModalCard="$bvModal.hide(`modal-card-create`)"
      />
    </b-modal>
  </div>
</template>
<script>
import ModalCardNewCreate from "@/views/crm/views/Lead/lead-card/ModalCardNewCreate.vue";
import LeadCardTransaction from "@/views/commons/components/leads/cards/lead.card.service";
import CreditCardService from "@/views/crm/services/creditCard/index.js";
import moment from "moment";
import { mapGetters } from "vuex";
import PaymentService from "@/views/commons/components/payments/services/Payments.service.js";
export default {
  components: {
    ModalCardNewCreate,
  },
  data() {
    return {
      paymentInformation: {
        cardId: null,
        amount: 0,
        type: 4,
        observation: "",
      },
      cards: [],
      validateMoney: false,
      fistValidateCard: false,
    };
  },
  props: {
    leadId: {
      type: Number,
      required: true,
    },
    leadName: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      default: 0,
    },
    concept: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.toggleModal("lead-add-transaction-modal");
    this.getLeadCards();
    this.paymentInformation.amount = this.amount;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    validateCard() {
      return this.cards.some((card) => card.active);
    },
  },
  methods: {
    selectCard(card) {
      this.cards.forEach((card) => {
        card.active = false;
      });
      card.active = true;
      this.paymentInformation.cardId = card.id;
    },
    onReloadCards() {
      this.$emit("reloadCards");
    },
    async getLeadCards() {
      try {
        const { data: cards } = await LeadCardTransaction.getLeadCards({
          leadId: this.leadId,
        });
        cards.forEach((card) => {
          card.active = false;
        });
        this.cards = cards;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    moment() {
      return moment();
    },
    async deleteCard(id) {
      try {
        const { isConfirmed, value: confirmValue } = await this.showConfirmSwal(
          "Delete Credit Card",
          "You won't be able to revert this!",
          {
            input: "textarea",
            inputValidator: (value) => {
              if (!value) {
                return "You need to write something!";
              }
            },
          }
        );
        if (isConfirmed) {
          if (confirmValue) {
            this.paymentInformation.amount = confirmValue;
            const { data } = await CreditCardService.deleteCreditCard({
              cardid: id,
              leadid: this.leadId,
              user_id: this.currentUser.user_id,
              comment: confirmValue,
            });
            data.forEach((card) => {
              card.active = false;
            });
            this.cards = data;
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Successful operation"
            );
          }
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async collectPayment() {
      try {
        this.addPreloader();
        this.validateMoney = true;
        this.fistValidateCard = true;
        const isValid = await this.$refs.form.validate();
        if (!isValid || !this.validateCard) return;
        const { data } = await PaymentService.createPayment({
          amount: this.paymentInformation.amount,
          card_id: this.paymentInformation.cardId,
          is_client: false,
          module_id: 0,
          lead_id: this.leadId,
        });
        if (data) {
          const title = this.$options.filters.myFontCapitalize(data.status);
          const message = data.message;
          if (data.status == "success") {
            this.showSuccessSwal(title, message);
            this.$emit("on-collect", data);
          } else {
            this.showErrorSwal(message, title);
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss">
.lead-add-transaction-modal {
  .custom-modal-header {
    background: #fff !important;
    color: #000;
    padding: 1.9rem 1.4rem 0.8rem 1.4rem;
    border-radius: 20px;
    font-size: 1.5rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close-icon {
      color: #82868b;
    }
  }
  .custom-modal-content {
    border-radius: 20px;
  }
  .lead-input {
    label {
      font-size: 1rem;
      font-weight: 700;
      color: #111113;
    }
    .lead-name {
      background: #f3f3f7;
      padding: 10px;
      border-radius: 5px;
      font-size: 1rem;
      color: #111113;
      font-weight: 600;
    }
    .pay-type-select {
      background: #f3f3f7;
      border-radius: 5px;
      border: none;
      .vs__dropdown-toggle {
        padding: 4px;
        border: none;
        .vs__selected-options {
          .vs__selected {
            color: #111113;
            font-weight: 600;
          }
        }
      }
    }
    .amount-input {
      background: #f3f3f7;
      border-radius: 5px;
      border: none;
      padding: 10px 13px;
      color: #111113;
      font-weight: 600;
      &:focus-visible {
        outline: none;
      }
    }
  }
  .payment-card-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    .payment-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f3f3f7;
      padding: 10px 13px;
      border-radius: 5px;
      font-size: 1.1rem;
      color: #111113;
      font-weight: 600;
      .payment-card-customer-information {
        display: flex;
        flex-direction: column;
        .cu-name {
          font-size: 1rem;
          color: #111113;
          font-weight: 700;
        }
        .cu-card {
          font-size: 1rem;
          color: #82868b;
          font-weight: 600;
        }
      }
      &.active {
        padding: 8px 11px;
        border: 2px solid #0090e7;
      }
    }
  }
  .button-collect {
    font-weight: 700;
  }
}

.dark-layout {
  .lead-add-transaction-modal {
    .custom-modal-header {
      background: #17171a !important;
      color: #fff;
      .close-icon {
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .lead-input {
      label {
        color: #fff;
      }
      .lead-name {
        background: #323241;
        color: #fff;
      }
      .pay-type-select {
        background: #323241;
        .vs__dropdown-toggle {
          background: #323241;
          .vs__selected-options {
            .vs__selected {
              color: #fff;
            }
          }
        }
      }
      .amount-input {
        background: #323241;
        color: #fff;
      }
    }
    .payment-card-container {
      .payment-card {
        background: #323241;
        color: #f3f3f7;
        .payment-card-customer-information {
          .cu-name {
            color: #f3f3f7;
          }
          .cu-card {
            color: #82868b;
          }
        }
      }
    }
  }
}
</style>