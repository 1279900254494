const { amgApi } = require("@/service/axios");

class RequestWorkplanService {
  constructor() {
    this.routeWp = "request-workplan";
  }

  async search(params) {
    try {
      const data = await amgApi.post(
        `${this.routeWp}/search-request-workplan`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async searchWorkPlanClient(params) {
    try {
      const data = await amgApi.post(
        `${this.routeWp}/search-work-plan-client`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getPendingWPCounterClient(params) {
    try {
      const data = await amgApi.post(
        `${this.routeWp}/get-pending-wp-counter-client`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getCounters(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeWp}/get-counters-wp`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getCreditAnalysts() {
    try {
      const { data } = await amgApi.post(`${this.routeWp}/get-credit-analysts`);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getTracking(params) {
    try {
      const data = await amgApi.post(`${this.routeWp}/get-tracking`, params);
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getDisputeReasonsInstructions(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeWp}/get-dispute-reasons-instructions`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async saveView(params) {
    try {
      const { data } = await amgApi.post(`${this.routeWp}/save-view`, params);

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async assignWorkplan(params) {
    try {
      return await amgApi.post(`${this.routeWp}/assign-workplan`, params);
    } catch (error) {
      throw new Error(error);
    }
  }

  async getSpecialist(params) {
    try {
      return await amgApi.post(`${this.routeWp}/get-specialists`, params);
    } catch (error) {
      throw new Error(error);
    }
  }
  async assignSpecificManual(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeWp}/specific-task-assignment`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getOnlyClassifications(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeWp}/get-only-classifications`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async returnToPending(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeWp}/return-to-pending`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async addCommentWithoutDispute(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeWp}/add-comment-without-dispute`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async completeWp(params) {
    try {
      const { data } = await amgApi.post(`${this.routeWp}/complete-wp`, params);

      return data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message || error.message;
      throw new Error(errorMessage);
    }
  }

  async getCompletedWpInfo(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeWp}/get-completed-workplan-information`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getRoundDetails(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeWp}/get-round-details`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getRoundDetailDispute(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeWp}/get-round-detail-dispute`,
        params
      );

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async addUrlTemporaryPdf(body) {
    try {
      const data = await amgApi.post("/dispute/add-url-temporary-pdf", body);
      return data;
    } catch (error) {
      console.error("Something went wrong on add-url-temporary-pdf:", error);
      throw error;
    }
  }

  async deleteWorkplan(params) {
    try {
      return await amgApi.post(`${this.routeWp}/delete-workplan`, params);
    } catch (error) {
      throw new Error(error);
    }
  }

  async getStepWorkPlan(params) {
    try {
      return await amgApi.post(`${this.routeWp}/get-step-work-plan`, params);
    } catch (error) {
      throw new Error(error);
    }
  }
  async updateStepWorkPlan(params) {
    try {
      return await amgApi.post(`${this.routeWp}/update-step-work-plan`, params);
    } catch (error) {
      throw new Error(error);
    }
  }

  async getRecommendationWorkPlan(params) {
    try {
      return await amgApi.post(`${this.routeWp}/get-recommendation-work-plan`, params);
    } catch (error) {
      throw new Error(error);
    }
  }

  async copyWorkplan(params){
    try {
      return await amgApi.post(`${this.routeWp}/copy-workplan`, params)
    } catch (error) {
      
    }
  }

  async getInfoOldWp(params){
    try {
      return await amgApi.post(`${this.routeWp}/get-info-old-wp`, params)
    } catch (error) {
      throw new Error(error)
    }
  }
}

export default new RequestWorkplanService();
