<template>
  <b-card footer-class="border-0">
    <b-card-title>
      <h4
        class="font-weight-bolder px-50 border-bottom-secondary border-2 pb-1 title-credit-card"
      >
        {{ title ? title : `Credit Cards` }}
      </h4>
    </b-card-title>

    <b-table
      v-if="!!statusLeadCards"
      show-empty
      sticky-header
      :fields="fieldsTask"
      :items="statusLeadCards"
      class="table-new-customization custom-table"
    >
      <template #cell(cardholdername)="data">
        <div
          style="min-width: 100px !important"
          class="w-100 d-flex custom-space"
          :class="
            isDarkSkin
              ? 'bg-custom-dark'
              : 'bg-white border-top-light  border-left-light border-bottom-light'
          "
        >
          <span class="text-capitalize">{{ data.item.cardholdername }}</span>
        </div>
      </template>

      <template #cell(cardnumber)="data">
        <div
          style="min-width: 120px !important"
          class="w-100 d-flex custom-space"
          :class="
            isDarkSkin
              ? 'bg-custom-dark'
              : 'bg-white border-top-light border-bottom-light'
          "
        >
          {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }}
        </div>
      </template>

      <template #cell(cardsecuritycode)="data">
        <div
          class="w-100 d-flex custom-space"
          :class="
            isDarkSkin
              ? 'bg-custom-dark'
              : 'bg-white border-top-light border-bottom-light'
          "
        >
          {{
            data.item.cardsecuritycode.length === 3
              ? "XX" + data.item.cardsecuritycode.substr(2)
              : "XXX" + data.item.cardsecuritycode.substr(3)
          }}
        </div>
      </template>

      <template #cell(type_card)="data">
        <div
          class="w-100 d-flex custom-space"
          :class="
            isDarkSkin
              ? 'bg-custom-dark'
              : 'bg-white border-top-light border-bottom-light'
          "
        >
          {{ data.item.type_card }}
        </div>
      </template>

      <template #cell(card_expi_month)="data">
        <div
          class="w-100 d-flex custom-space"
          :class="
            isDarkSkin
              ? 'bg-custom-dark'
              : 'bg-white border-top-light border-bottom-light'
          "
        >
          {{ data.item.card_expi_month }}
        </div>
      </template>

      <template #cell(card_expi_year)="data">
        <div
          class="w-100 d-flex custom-space"
          :class="
            isDarkSkin
              ? 'bg-custom-dark'
              : 'bg-white border-top-light border-bottom-light'
          "
        >
          {{ data.item.card_expi_year }}
        </div>
      </template>

      <template #cell(actions)="data">
        <div
          class="d-flex justify-content-center custom-space"
          :class="
            isDarkSkin
              ? 'bg-custom-dark'
              : 'bg-white border-top-light  border-right-light border-bottom-light'
          "
        >
          <b-button
            variant="flat-light"
            class="button-little-size rounded-circle"
            :disabled="isActionButtonLoading || isLoading || isResponsibleLead"
            @click="onModalCardOpen(data.item.id)"
          >
            <feather-icon
              v-if="!isActionButtonLoading && !isLoading"
              icon="EyeIcon"
              size="18"
              class="hover-me"
            />
            <b-spinner v-else small />
          </b-button>
          <b-button
            v-if="(authUser.role_id == 1 || authUser.role_id == 2) && !onlyRead"
            variant="flat-danger"
            class="button-little-size rounded-circle ml-1"
            :disabled="isActionButtonLoading || isLoading || isResponsibleLead"
            @click="onDeleteCard(data.item.id)"
          >
            <feather-icon
              v-if="!isActionButtonLoading && !isLoading"
              icon="Trash2Icon"
              size="18"
              class="hover-me"
            />
            <b-spinner v-else small />
          </b-button>
        </div>
      </template>
    </b-table>
    <div
      v-else
      class="content-empty p-1 position-relative rounded-lg"
      :class="isDarkSkin ? 'bg-custom-dark' : null"
    >
      <img
        :src="require('@/assets/images/icons/credit-cards.svg')"
        width="150px"
        height="150px"
        class="custom-img-empty"
      />
      <span class="custom-text-empty">No Credit Cards</span>
    </div>

    <template v-if="!onlyRead" #footer>
      <div class="text-right">
        <b-button
          class="add-button"
          :disabled="isResponsibleLead"
          @click="$bvModal.show(`modal-card-create-${key}`)"
        >
          <feather-icon icon="PlusIcon" class="mr-1 text-black"></feather-icon>
          <span class="align-middle">Add</span>
        </b-button>
      </div>
    </template>

    <b-modal
      :id="`modal-card-create-${key}`"
      modal-class="custom-modal-amg"
      title-class="h3 text-white"
      centered
      size="lg"
      hide-footer
      no-close-on-backdrop
      header-class="p-0"
      header-bg-variant="transparent"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>Create Credit Card</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="$bvModal.hide(`modal-card-create-${key}`)"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <modal-card-new-create
        :only-read="onlyRead"
        :lead="lead"
        @onReloadCards="onReloadCards"
        @closeModalCard="$bvModal.hide(`modal-card-create-${key}`)"
        @reloadLeadEmit="reloadLeadEmit()"
      />
    </b-modal>

    <b-modal
      v-model="viewCardModal"
      ok-only
      modal-class="custom-modal-amg"
      title-class="h3 text-white"
      centered
      size="lg"
      hide-footer
      no-close-on-backdrop
      header-class="p-0"
      header-bg-variant="transparent"
      header-text-variant="primary"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>Credit Card</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="viewCardModal = false"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <modal-card-show :only-read="onlyRead" :lead="lead" :card="card" />
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import Ripple from "vue-ripple-directive";

import ModalCardShow from "../../lead-card/ModalCardShow.vue";
import ModalCardCreate from "../../lead-card/ModalCardCreate.vue";
import ModalCardNewCreate from "../../lead-card/ModalCardNewCreate.vue";

export default {
  components: {
    ModalCardShow,
    ModalCardCreate,
    ModalCardNewCreate,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      updatedCards: "CrmCreditCardStore/LISTCARDS",
      /* G_TEMPLATES: 'CrmTemplateStore/G_TEMPLATES' */
    }),
    ...mapState({
      /* S_TEMPLATES: event => event.CrmTemplateStore.S_TEMPLATES */
    }),
    statusLeadCards() {
      return this.updatedCards != null ? this.updatedCards : this.lead.cards;
    },
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  created() {
    this.isResponsible();
    this.authUser = this.currentUser;
    this.getStatesEeuu();
  },
  directives: { Ripple },
  data() {
    return {
      viewCardModal: false,
      key: Math.random(),
      authUser: new Object(),
      card: new Object(),
      fieldsTask: [
        { key: "cardholdername", label: "Card", tdClass: "py-50 px-0" },
        { key: "cardnumber", label: "Card Number", tdClass: "py-50 px-0" },
        { key: "type_card", label: "Type", tdClass: "py-50 px-0" },
        { key: "card_expi_month", label: "MM", tdClass: "py-50 px-0" },
        { key: "card_expi_year", label: "YY", tdClass: "py-50 px-0" },
        { key: "cardsecuritycode", label: "CVV", tdClass: "py-50 px-0" },
        { key: "actions", tdClass: "py-50 px-0", thClass: "text-center" },
      ],
      isActionButtonLoading: false,
      isLoading: false,
    };
  },
  methods: {
    isResponsible() {
      this.$store.dispatch("NotificationStore/A_IS_RESPONSIBLE_CEO", {
        lead_id: this.$route.params.id,
      });
    },
    ...mapActions({
      A_GET_EEUU_STATES: "CrmGlobalStore/A_GET_EEUU_STATES",
      A_GET_CREDIT_CARD: "CrmCreditCardStore/A_GET_CREDIT_CARD",
      A_DELETE_CREDIT_CARD: "CrmCreditCardStore/A_DELETE_CREDIT_CARD",
      SET_DATA_CARDS_UPDATE: "CrmCreditCardStore/SET_DATA_CARDS_UPDATE",
    }),
    reloadLeadEmit() {
      this.$emit("reloadLead");
    },
    async getStatesEeuu() {
      try {
        await this.A_GET_EEUU_STATES();
      } catch (error) {
        console.log("Something went wrong getStatesEeuu", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async onModalCardOpen(id) {
      try {
        this.isActionButtonLoading = true;
        const response = await this.A_GET_CREDIT_CARD({ id });
        if (this.isResponseSuccess(response)) {
          this.card = response.data[0];
          this.viewCardModal = true;
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong. ${response.message}`
          );
        }
        this.isActionButtonLoading = false;
      } catch (error) {
        console.log("Something went wrong onModalCardOpen", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
        this.isActionButtonLoading = false;
      }
    },
    onReloadCards(cards) {
      // this.lead.cards = cards;
      this.SET_DATA_CARDS_UPDATE(cards);
      this.lead.cards = this.updatedCards;
    },
    onDeleteCard(id) {
      this.isActionButtonLoading = true;
      this.showConfirmSwal(
        "Delete Credit Card",
        "You won't be able to revert this!",
        {
          input: "textarea",
          inputValidator: (value) => {
            if (!value) {
              return "You need to write something!";
            }
          },
        }
      )
        .then(async (result) => {
          if (result.value) {
            const response = await this.A_DELETE_CREDIT_CARD({
              cardid: id,
              leadid: this.lead.id,
              user_id: this.authUser.user_id,
              comment: result.value,
            });
            if (this.isResponseSuccess(response)) {
              this.SET_DATA_CARDS_UPDATE(response.data);
              this.lead.cards = this.updatedCards;
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
              this.isActionButtonLoading = false;
            } else {
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                `Something went wrong. ${response.message}`
              );
              this.isActionButtonLoading = false;
            }
          }
          this.isActionButtonLoading = false;
        })
        .catch((error) => {
          console.log("Something went wrong onDeleteCard", error);
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
          );
          this.isActionButtonLoading = false;
        });
    },
  },
  mounted() {},
  props: {
    onlyRead: {
      type: Boolean,
      required: true,
    },
    lead: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  setup() {},
};
</script>
<style scoped>
.custom-table
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th {
  background-color: rgba(58, 114, 234, 1) !important;
  color: #111113;
  padding: 12px 10px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: none !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child {
  border-top-right-radius: 10px !important;
}
.custom-table >>> .table tr td:first-child,
.custom-table-dark >>> .table tr td:first-child {
  border-radius: 10px 0px 0px 10px !important;
}
.custom-table >>> .table tr td,
.custom-table-dark >>> .table tr td {
  border: none !important;
}
.custom-table >>> .table tr td:last-child,
.custom-table-dark >>> .table tr td:last-child {
  border-radius: 0px 10px 10px 0px !important;
}
.custom-table >>> .table tr td .custom-space,
.custom-table-dark >>> .table tr td .custom-space {
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  padding-right: 15px !important;
  padding-left: 15px !important;
}
.custom-table >>> .table tr td:first-child .custom-space,
.custom-table-dark >>> .table tr td:first-child .custom-space {
  border-radius: 10px 0px 0px 10px !important;
}
.custom-table >>> .table tr td:last-child .custom-space,
.custom-table-dark >>> .table tr td:last-child .custom-space {
  border-radius: 0px 10px 10px 0px !important;
}
.content-empty {
  height: 350px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-img-empty {
  opacity: 0.2;
}
.custom-text-empty {
  position: absolute;
  font-size: 25px !important;
  font-weight: bolder;
}

.bg-custom-dark {
  background: #111113;
}
.add-button {
  background-color: #3f7afa !important;
  background: #3f7afa !important;
  color: #111113 !important;
  border-color: #3f7afa !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.title-credit-card {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #111113;
}
</style>
<style lang="scss" scoped>
.dark-layout {
  .title-credit-card {
    color: #fafafa;
  }
}
</style>
