<template>
  <div>
    <b-modal
      v-model="isActive"
      :body-class="`rounded`"
      no-close-on-backdrop
      size="summary"
      hide-header
      hide-header-close
      @hidden="isActive = false"
      centered
      :ok-only="true"
      ok-variant="success"
      hide-footer
    >
      <div v-if="clientData && letterData">
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
        >
          <div class="d-flex align-items-center">
            <div class="icon-badge mr-1">
              <FeatherIcon
                icon="CornerDownLeftIcon"
                class="text-info"
                size="18"
              />
              <FeatherIcon icon="MailIcon" class="text-info" size="18" />
            </div>
            <span class="text-info font-strong text-uppercase">
              {{ alertTitle }}
            </span>
          </div>
          <div
            class="close-modal cursor-pointer"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
            @click="closeAlert()"
          >
            <FeatherIcon icon="XIcon" size="16" />
          </div>
        </div>
        <div class="d-flex flex-column" style="gap: 15px">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <FeatherIcon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasees="font-small-4"
              />
              CLIENT
            </span>
            <span class="font-strong">
              {{ clientData.client_name }}
            </span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <FeatherIcon
                icon="MapIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasees="font-small-4"
              />
              DEPARTMENT
            </span>
            <span class="font-strong">
              {{ clientData.module_name }}
            </span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <FeatherIcon
                icon="UserCheckIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasees="font-small-4"
              />
              RETURNED BY
            </span>
            <span class="font-strong">
              {{ letterData.returnedBy }}
            </span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <FeatherIcon
                icon="FileIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasees="font-small-4"
              />
              SUBJECT
            </span>
            <div>
              <span
                class="font-strong"
                v-for="(item, idx) in letterData.subject"
                :key="idx"
              >
                <b-badge pill class="ml-1" variant="danger">{{ item }}</b-badge>
              </span>
            </div>
          </div>

          <div>
            <div
              class="d-flex justify-content-between align-items-center"
              style="gap: 50px"
            >
              <span class="font-strong">
                <FeatherIcon
                  icon="MessageSquareIcon"
                  class="mr-1 font-strong"
                  size="14"
                  svgClasees="font-small-4"
                />
                OBSERVATION
              </span>
              <span class="font-strong" style="text-align: right">
                {{
                  showAll
                    ? letterData.observation
                    : letterData.observation.slice(0, 25) +
                      (letterData.observation.length < 25 ? "" : "...")
                }}
              </span>
            </div>

            <div class="d-flex justify-content-end">
              <p
                v-if="letterData.observation.length > 25"
                class="font-strong text-primary cursor-pointer"
                @click="showAll = !showAll"
              >
                {{ showAll ? "see less" : "see more" }}
              </p>
            </div>
          </div>
        </div>

        <b-row class="mt-1">
          <b-col v-if="targetRoute !== $route.name">
            <b-button
              class="w-100 block"
              target="_blank"
              variant="info"
              @click="goToLetters"
            >
              GO TO LETTERS
            </b-button>
          </b-col>
          <b-col class="d-flex justify-content-center">
            <b-button
              :class="targetRoute == $route.name ? 'w-50' : 'w-100 block'"
              variant="success"
              @click="closeAlert()"
              >OK</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      showAll: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_IS_ACTIVE: "AutoAlertReturnedLetters/G_IS_ACTIVE",
      clientData: "AutoAlertReturnedLetters/G_CLIENT_DATA",
      letterData: "AutoAlertReturnedLetters/G_LETTER_DATA",
      alertTitle: "AutoAlertReturnedLetters/G_TITLE",
      updateLetterTable:
        "SpecialistsDigitalRoundLettersStore/G_UPDATE_LETTER_TABLE",
    }),
    isActive: {
      get() {
        return this.G_IS_ACTIVE;
      },
      set(value) {
        this.$store.commit("AutoAlertReturnedLetters/M_OPEN_ALERT", value);
      },
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    targetRoute() {
      const abbr = {
        5: "ds",
        6: "ce",
        7: "bc",
        8: "tr",
        10: "cd",
        12: "pr",
        20: "cn",
        21: "ce",
        22: "csd",
      };

      return `${abbr[this.moduleId]}-letters-dispute-to-send-pending`;
    },
  },
  methods: {
    ...mapActions({
      A_UPDATE_TABLE:
        "SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE",
    }),
    closeAlert() {
      const channel = socket.channel("round-letters-notifications", {});
      channel.trigger("client-close-returned-letter-notification", {
        message: "close all",
        user_id: this.currentUser.user_id,
      });
      this.isActive = false;
      if (this.$route.matched[2].meta.routeToSendPending == this.$route.name) {
        this.A_UPDATE_TABLE(true);
      }
    },
    goToLetters() {
      this.closeAlert();
      this.$router.push({
        name: this.targetRoute,
      });
    },
  },
};
</script>