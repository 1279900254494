export default [
  {
    key: "from",
    type: "datepicker",
    cols: 6,
    margin: true,
    showLabel: true,
    visible: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
  },
  {
    key: "to",
    type: "datepicker",
    cols: 6,
    margin: true,
    showLabel: true,
    visible: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
  },
  {
    key: "program_id",
    type: "select",
    margin: true,
    showLabel: true,
    visible: true,
    label: "Original Program",
    model: null,
    options: [
      { value: 0, label: "CRM" },
      { value: 1, label: "Business" },
      { value: 2, label: "Boost Credit" },
      { value: 3, label: "Credit Experts" },
      { value: 4, label: "Debt Solution" },
      { value: 5, label: "Tax Research" },
      { value: 6, label: "Court Info" },
      { value: 7, label: "Specialist" },
      { value: 8, label: "KeyBook" },
      { value: 9, label: "Paragon" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
  },
  {
    key: "status_id",
    type: "select",
    cols: 12,
    margin: true,
    showLabel: true,
    visible: true,
    label: "Select status",
    placeholder: "",
    class: "font-small-3",
    model: null,
    options: [
      { value: null, label: "All" },
      { value: 1, label: "Draft" },
      { value: 2, label: "New" },
      { value: 3, label: "In Progress" },
      { value: 4, label: "Rejected" },
      { value: 5, label: "Solved Not Fidelized" },
      { value: 6, label: "Solved Fidelized" },
      { value: 7, label: "Not Solved" },
    ],
    reduce: "value",
    selectText: "label",
  },
  {
    key: "priority_id",
    type: "select",
    cols: 12,
    margin: true,
    showLabel: true,
    visible: true,
    label: "Select Priority",
    placeholder: "",
    class: "font-small-3",
    model: null,
    options: [
      { value: null, label: "All" },
      { value: "low", label: "Low" },
      { value: "medium", label: "Medium" },
      { value: "high", label: "High" },
    ],
    reduce: "value",
    selectText: "label",
  },
  {
    key: "paid",
    type: "select",
    margin: true,
    showLabel: true,
    visible: false,
    label: "Last Payment",
    model: null,
    options: [
      { value: 0, label: "UP TO DATE" },
      { value: 1, label: "OVERDUE" },
    ],
    reduce: "value",
    selectText: "label",
    cols: 12,
  },
];
