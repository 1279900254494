export default [
  {
    path: "sales-made",
    name: "quality-sales-made",
    redirect: { name: "sales-made-quality-add-change" },
    component: () =>
      import(
        /* webpackChunkName: "QualitySalesMadeMain" */
        "@/views/quality/views/sales-made/SalesMade.vue"
      ),
    meta: {
      program: 18,
      pageTitle: "Sales Made",
      breadcrumb: [
        {
          text: "Sales Made",
        },
      ],
    },
    children: [
      {
        path: "add-change",
        name: "sales-made-quality-add-change",
        component: () =>
          import(
            /* webpackChunkName: "BCSalesMadeAddChange" */ "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue"
          ),
        meta: {
          program: 18,
          pageTitle: "Sales Made",
          breadcrumb: [
            {
              text: "Add/Change Service",
              active: true,
            },
          ],
        },
      },
      {
        path: "add-sale",
        name: "sales-made-quality-add-sale",
        component: () =>
          import(
            /* webpackChunkName: "BCSalesMadeAddSale" */ "@/views/commons/components/sales-made/add-sale/components/SalesMadeAddSaleComponent.vue"
          ),
        meta: {
          program: 18,
          type: 0,
          pageTitle: "Sales Made",
          breadcrumb: [
            {
              text: "Add Sale",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
