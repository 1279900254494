import { amgApi } from "@/service/axios";
import { amgApiApp } from "@/service/axios";

class CsAlertService {
  async clientAvailable(object) {
    const data = await amgApi.post(
      "credit-experts-digital/save-letter-alert-actions",
      object
    );
    return data.status;
  }
  async clientNotAvailable(object) {
    const data = await amgApi.post(
      "credit-experts-digital/save-letter-alert-actions",
      object
    );
    return data.status;
  }
  async clientAvailable(object) {
    const data = await amgApi.post(
      "credit-experts-digital/save-letter-alert-actions",
      object
    );
    return data.status;
  }
  async clientNotAvailable(object) {
    const data = await amgApi.post(
      "credit-experts-digital/save-letter-alert-actions",
      object
    );
    return data.status;
  }
  async clientNotAnswered(object) {
    const data = await amgApi.post(
      "credit-experts-digital/save-letter-alert-actions",
      object
    );
    return data.status;
  }

  // -- Chat Alerts
  async acceptChat(params) {
    const data = await amgApiApp.post("round/accept-request-advisor", params);
    //
    return data.status;
  }
  // --

  async captureAlert(pending_to_call_id, agent_user_id) {
    const data = await amgApi.post(
      "credit-experts-digital/pusher-letter-agent-actions",
      { pending_to_call_id, agent_user_id }
    );
  }

  async closeModalForClient({ user_id }) {
    const data = await amgApi.post(
      "credit-experts-digital/pusher-close-alert-modal",
      { user_id }
    );
    return data.status;
  }
  async clientNotAnswered(object) {
    const data = await amgApi.post(
      "credit-experts-digital/save-letter-alert-actions",
      object
    );
    return data.status;
  }

  // -- Chat Alerts
  async acceptChat(params) {
    const data = await amgApiApp.post("round/accept-request-advisor", params);
    //
    return data.status;
  }
  // --

  async captureAlert(pending_to_call_id, agent_user_id) {
    const data = await amgApi.post(
      "credit-experts-digital/pusher-letter-agent-actions",
      { pending_to_call_id, agent_user_id }
    );
  }

  async closeModalForClient({ user_id }) {
    const data = await amgApi.post(
      "credit-experts-digital/pusher-close-alert-modal",
      { user_id }
    );
    return data.status;
  }

  async closeAlertChat(data) {
    const response = await amgApiApp.post("round/close-alert", data);
    return response.status;
  }
}

export default new CsAlertService();
