export default {
    namespaced: true,
    state: {
        S_CLIENT_INFORMATION: null,
        S_OTHER_FILES: [],
        S_COMMENTS_STATUS: [],
        S_COMMENTS: [],
        S_NEW_MESSAGE_BINNACLE: null,
        S_NEW_FILE_BINNACLE: null,
        S_SEND_MESSAGE_BINNACLE: null,
        S_MARK_MESSAGE_AS_VIEWED: null,
        S_MODAL_FILES: false,
        S_TOTAL_NEW_COMMENTS: 0,
    },
    getters: {
        G_CLIENT_INFORMATION(state) {
            return state.S_CLIENT_INFORMATION;
        },
        G_OTHER_FILES(state) {
            return state.S_OTHER_FILES;
        },
        G_COMMENTS_STATUS(state) {
            return state.S_COMMENTS_STATUS;
        },
        G_COMMENTS(state) {
            return state.S_COMMENTS;
        },
        G_NEW_MESSAGE_BINNACLE(state) {
            return state.S_NEW_MESSAGE_BINNACLE;
        },
        G_NEW_FILE_BINNACLE(state) {
            return state.S_NEW_FILE_BINNACLE;
        },
        G_SEND_MESSAGE_BINNACLE(state) {
            return state.S_SEND_MESSAGE_BINNACLE;
        },
        G_MARK_MESSAGE_AS_VIEWED(state) {
            return state.S_MARK_MESSAGE_AS_VIEWED;
        },
        G_MODAL_FILES(state) {
            return state.S_MODAL_FILES;
        },
        G_TOTAL_NEW_COMMENTS(state) {
            return state.S_TOTAL_NEW_COMMENTS;
        }
    },
    mutations: {
        M_SET_CLIENT_INFORMATION(state, payload) {
            state.S_CLIENT_INFORMATION = payload;
        },
        M_REMOVE_CLIENT_INFORMATION(state, payload) {
            state.S_CLIENT_INFORMATION = payload;
        },
        M_SET_OTHER_FILES(state, payload) {
            state.S_OTHER_FILES = payload;
        },
        M_ADD_OTHER_FILES(state, payload) {
            state.S_OTHER_FILES.push(payload);
        },
        M_SET_COMMENTS_STATUS(state, payload) {
            state.S_COMMENTS_STATUS = payload;
        },
        M_SET_COMMENTS(state, payload) {
            state.S_COMMENTS = payload;
        },
        M_ADD_COMMENTS(state, payload) {
            state.S_COMMENTS.push(payload);
        },
        M_NEW_MESSAGE_BINNACLE(state, payload) {
            state.S_NEW_MESSAGE_BINNACLE = payload;
        },
        M_NEW_FILE_BINNACLE(state, payload) {
            state.S_NEW_FILE_BINNACLE = payload;
        },
        M_SEND_MESSAGE_BINNACLE(state, payload) {
            state.S_SEND_MESSAGE_BINNACLE = payload;
        },
        M_MARK_MESSAGE_AS_VIEWED(state, payload) {
            state.S_MARK_MESSAGE_AS_VIEWED = payload;
        },
        M_MODAL_FILES(state, payload) {
            state.S_MODAL_FILES = payload;
        },
        M_TOTAL_NEW_COMMENTS(state, payload) {
            state.S_TOTAL_NEW_COMMENTS = payload;
        }
    },
    actions: {
        addClientInformation({ commit }, payload) {
            commit('M_SET_CLIENT_INFORMATION', payload);
        },
        removeClientInformation({ commit }, payload) {
            commit('M_REMOVE_CLIENT_INFORMATION', payload);
            commit("M_SEND_MESSAGE_BINNACLE", null);
            commit("M_NEW_FILE_BINNACLE", null);
            commit('M_MARK_MESSAGE_AS_VIEWED', null);
            commit("M_SET_COMMENTS", []);
            commit("M_NEW_MESSAGE_BINNACLE", null);
            commit('M_SET_OTHER_FILES', []);
            commit('M_SET_COMMENTS_STATUS', null);
            commit("M_TOTAL_NEW_COMMENTS", 0);
        },
        addOtherFiles({ commit }, payload) {
            if (Array.isArray(payload)) {
                commit('M_SET_OTHER_FILES', payload);
            } else {
                if (payload && typeof payload === 'object') {
                    commit('M_ADD_OTHER_FILES', payload);
                }
            }
        },
        addCommentsStatus({ commit }, payload) {
            commit('M_SET_COMMENTS_STATUS', payload);
        },
        addComments({ state,commit }, payload) {
            if (Array.isArray(payload)) {
                commit("M_SET_COMMENTS", payload);
            } else {
                if (payload && typeof payload === 'object') {
                    commit("M_ADD_COMMENTS", payload);
                }
            }
        },
        sendMessageBinnacle({ commit }, payload) {
            if (payload && typeof payload === 'object') {
                commit("M_SEND_MESSAGE_BINNACLE", payload);
            } else {
                commit("M_SEND_MESSAGE_BINNACLE", null);
            }
        },
        insertCommentsBinnacle({ commit }, payload) {
            if (typeof payload === 'object' && payload !== null) {
                commit("M_NEW_MESSAGE_BINNACLE", payload);
            } else {
                commit("M_NEW_MESSAGE_BINNACLE", null);
            }
        },
        insertFilesBinnacle({ commit }, payload) {
            if (typeof payload === 'object' && payload !== null) {
                commit("M_NEW_FILE_BINNACLE", payload);
            } else {
                commit("M_NEW_FILE_BINNACLE", null);
            }
        },
        markMessageAsViewed({ commit }, payload) {
            if (typeof payload === 'object') {
                commit('M_MARK_MESSAGE_AS_VIEWED', payload);
            } else {
                commit('M_MARK_MESSAGE_AS_VIEWED', null);
            }
        },
        showModalFiles({ commit }, payload) {
            if (payload && typeof payload === 'boolean') {
                commit("M_MODAL_FILES", payload);
            } else {
                commit("M_MODAL_FILES", false);
            }
        },
        totalNewComments({ state,commit }, payload) {
            let total = state.S_TOTAL_NEW_COMMENTS + payload;
            commit("M_TOTAL_NEW_COMMENTS", total);
        }
    }
}