export default {
  namespaced: true,
  state: {
    S_SALE_COUNTER: [],
  },
  getters: {
    G_SALE_COUNTER() {
      return state.S_SALE_COUNTER;
    },
  },
  mutations: {
    M_SET_SALE_COUNTER(state, params) {
      state.S_SALE_COUNTER = params;
    },
  },
  actions: {
    A_SET_SALE_COUNTER({ commit }, data) {
      commit("M_SET_SALE_COUNTER", data);
    },
  },
};
