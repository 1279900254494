import store from "@/store";
import EmployeeClaimsService from "@/views/commons/employee-claims/services/employee-claims.service.js";
import crmService from "@/views/crm/services/crm.service";

const employeeClaims = async (route) => {
  const { user_id, modul_id } = store.state.auth.currentUser;
  const params = {
    userId: user_id,
    moduleId: [19, 17].includes(modul_id) ? modul_id : null,
  };
  const { data, status } = await EmployeeClaimsService.getCounterEmployeeClaims(
    params
  );
  if (status == 200) {
    const counters = {
      counterUser: data[0].counter_user,
      counterModule: data[0].counter_module,
    };

    store.commit("EmployeeClaimsStore/M_COUNTER_EMPLOYEE_CLAIMS", counters);

    const payload = {
      routeName:
        route === "rrhh-employee-claims"
          ? "dashboard-employee-claims-rrhh"
          : route,
      tag:
        store.state.EmployeeClaimsStore.S_COUNTER_EMPLOYEE_CLAIMS.counterUser +
        store.state.EmployeeClaimsStore.S_COUNTER_EMPLOYEE_CLAIMS.counterModule,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

export default employeeClaims;
const convertoModuleToProgramC = (module) => {
  let routeCase = "";
  let program_id = null;
  switch (module) {
    case 2:
      program_id = 0;
      routeCase = "sales-made-crm";
      break;
    case 3:
      program_id = 1;
      routeCase = "bussiness-sales-made";
      break;
    case 5:
      program_id = 4;
      routeCase = "debtsolution-sales-made-home";
      break;
    case 6:
      program_id = 3;
      routeCase = "credit-experts-sales-made";
      break;
    case 7:
      program_id = 2;
      routeCase = "boost-credit-sales-made";
      break;
    case 8:
      program_id = 5;
      routeCase = "sales-made-tax-research";
      break;
    case 10:
      program_id = 6;
      routeCase = "claimdepartment-sales-made";
      break;
    case 11:
    case 28:
    case 29:
    case 30:
      program_id = 7;
      routeCase = "spfa-sales-made";
      break;
    case 12:
      program_id = 9;
      routeCase = "sales-made-paragon";
      break;
    case 14:
      program_id = 8;
      routeCase = "bookeeping-sales-made";
      break;
    case 20:
      program_id = 3;
      routeCase = "connection-sales-made";
      break;
    case 26:
      program_id = 3;
      routeCase = "cedigital-sales-made-home";
      break;
  }
  return {
    program_id,
    routeCase,
  };
};

export const salesMadeCounter = async (routeName = null) => {
  try {
    const { modul_id, user_id, role_id } = store.state.auth.currentUser;
    const result = await crmService.countSalesMadeByLead({
      module_id: modul_id,
      user_id,
      role_id,
    });

    const { program_id, routeCase } = convertoModuleToProgramC(modul_id);
    const addChangeCounter = await crmService.getClientCounters({
      module_id: modul_id,
      program_id,
      user_id,
      role_id,
    });
    //
    const {
      TOTAL_CRM,
      TOTAL_ORIGEN_FOR_APROVED,
      TOTAL_ORIGEN_DISAPPROVED,
      TOTAL_DESTINO_FOR_APROVED,
      TOTAL_DISAPPROVED_BY_SELLER,
    } = result;
    const total =
      TOTAL_CRM +
      TOTAL_DESTINO_FOR_APROVED +
      TOTAL_ORIGEN_DISAPPROVED +
      TOTAL_ORIGEN_FOR_APROVED +
      TOTAL_DISAPPROVED_BY_SELLER;
    if (routeName == null) routeName = routeCase;
    const payload = {
      routeName,
      tag: total + addChangeCounter,
    };
    store.commit("CrmSaleMadeStore/M_SET_LEAD_SALE_COUNTERS", {
      total,
      result,
    });
    store.commit("CrmSaleMadeStore/M_SET_ADDCHANGE_COUNTERS", addChangeCounter);
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  } catch (ex) {
    console.log("salesMadeCounter", { ex });
  }
};
