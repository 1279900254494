import { render, staticRenderFns } from "./ModalCreateCustomerTicket.vue?vue&type=template&id=45e6d35c&scoped=true"
import script from "./ModalCreateCustomerTicket.vue?vue&type=script&lang=js"
export * from "./ModalCreateCustomerTicket.vue?vue&type=script&lang=js"
import style0 from "./ModalCreateCustomerTicket.vue?vue&type=style&index=0&id=45e6d35c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e6d35c",
  null
  
)

export default component.exports