<template>
  <b-modal
    ref="modal-timeline"
    title-tag="h3"
    size="xmd"
    modal-class="modal-primary"
    hide-footer
    @hidden="$emit('close')"
    scrollable
  >
    <template #modal-title>
      <span>
        TIMELINE - CASE:
        <strong>{{ caseNumber }}</strong></span
      >
    </template>
    <b-card v-if="caseInfo">
      <b-row>
        <b-col cols="12" :md="caseInfo.modul_id == 5 ? 6 : 3">
          <span>
            {{ caseInfo.is_lead == 1 ? "LEAD" : "CLIENT" }}
          </span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ caseInfo.name_lead }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" :md="caseInfo.modul_id == 5 ? 6 : 3">
          <span>ACCOUNT</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ caseInfo.is_lead == 1 ? "-" : caseInfo.account }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="3" v-if="caseInfo.modul_id != 5">
          <span>TOTAL COST</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              <span v-if="total_amount == null"> - </span>
              <span v-else> $ {{ total_amount }} </span>
            </p>
          </div>
        </b-col>

        <b-col cols="12" md="3" v-if="caseInfo.modul_id != 5">
          <span>TOTAL PENDING COST</span>
          <div class="border border-primary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
              :class="total_pending_amount > 0 ? 'text-warning' : ''"
            >
              <span v-if="total_pending_amount == null"> - </span>
              <span v-else> $ {{ total_pending_amount }} </span>
            </p>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <div class="mt-2">
      <Timeline
        :caseId="caseId"
        @total_amount="total_amount = $event"
        @pending_total_amount="total_pending_amount = $event"
      />
    </div>
  </b-modal>
</template>
<script>
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import Timeline from "@/views/debt-solution/views/court-info/components/Timeline.vue";
export default {
  components: {
    Timeline,
  },
  props: {
    caseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      caseNumber: "-",
      caseInfo: null,
      total_amount: 0,
      total_pending_amount: 0,
    };
  },
  created() {
    this.getCourtCase();
  },
  async mounted() {
    this.toggleModal("modal-timeline");
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async getCourtCase() {
      try {
        this.addPreloader();
        const data = await CourtInfoService.getCourtCase({
          case_id: this.caseId,
        });
        this.caseNumber = data[0].case_number.toUpperCase();
        this.caseInfo = data[0];
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>