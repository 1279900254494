export default {
    namespaced: true,
    state: {
        S_IS_ACTIVE: false,
        S_CLIENT_DATA: null,
        S_TITLE: null,
        S_LETTER_DATA: null,
    },
    getters: {
        G_IS_ACTIVE(state) {
            return state.S_IS_ACTIVE;
        },
        G_CLIENT_DATA(state) {
            return state.S_CLIENT_DATA;
        },
        G_TITLE(state) {
            return state.S_TITLE;
        },
        G_LETTER_DATA(state) {
            return state.S_LETTER_DATA;
        },
    },
    mutations: {
        M_OPEN_ALERT(state, payload) {
            state.S_IS_ACTIVE = payload;
        },
        M_CLIENT_DATA(state, payload) {
            state.S_CLIENT_DATA = payload;
        },
        M_TITLE(state, payload) {
            state.S_TITLE = payload;
        },
        M_RESET(state) {
            state.S_IS_ACTIVE = false;
            state.S_TITLE = "";
            state.S_CLIENT_DATA = null;
            state.S_LETTER_DATA = null;
        },
        M_LETTER_DATA(state, payload) {
            state.S_LETTER_DATA = payload;
        },
    }
}