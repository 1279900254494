<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-footer
      hide-header-close
      hide-header
      :body-class="`rounded`"
      no-close-on-backdrop
      no-close-on-esc
      size="summary"
      @hidden="isActive = false"
      centered
    >
      <div v-if="salesMadeInfo">
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
        >
          <div class="icon-badge mr-1" :class="badgeColor">
            <feather-icon :icon="iconTitle" size="20"></feather-icon>
          </div>
          <span>
            <h4 :class="colorText" class="text-uppercase">{{ title }}</h4>
          </span>
          <!-- <div
            class="close-modal"
            @click="closeModal"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
          >
            <feather-icon icon="XIcon" size="16" />
          </div> -->
        </div>

        <hr />
        <div class="d-flex flex-column" style="gap: 15px">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserPlusIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              {{ salesMadeInfo.type_client }}
            </span>
            <span class="font-strong">{{ salesMadeInfo.lead_name }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              SELLER
            </span>
            <span class="font-strong">{{ salesMadeInfo.seller_name }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="BriefcaseIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              SERVICE
            </span>
            <span class="font-strong">{{ salesMadeInfo.program_name }}</span>
          </div>

          <!-- <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="WatchIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CLIENT HOUR
            </span>
            <span class="font-strong">{{
              salesMadeInfo.original_hour | myGlobalDay
            }}</span>
          </div> -->

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="TagIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              OBSERVATION
            </span>
            <span class="font-strong">{{ salesMadeInfo.observation }}</span>
          </div>
        </div>
        <hr />
        <div>
          <b-row v-if="alert_buttons">
            <b-col
              :cols="5"
              class="d-flex align-items-center justify-content-center"
            >
              <b-button
                size="md"
                variant="outline-success"
                class="w-100"
                @click="closeModal()"
                >OK
              </b-button>
            </b-col>
            <b-col
              :cols="7"
              class="d-flex align-items-center justify-content-center"
            >
              <b-button
                size="6"
                variant="outline-warning"
                target="_blank"
                class="w-100"
                @click="goToSalesMade()"
                >Go to Sales Made
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { salesMadeCounter } from "@/views/commons/commons-counter.js";
export default {
  name: "SalesMadeNotification",
  data() {
    return {
      alert_buttons: true,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    pathNameSaleMade() {
      let type_client = this.salesMadeInfo.type_client.toLowerCase();
      switch (this.moduleId) {
        case 2:
          return `sales-made-crm-${type_client}`;
        case 5:
          return `sales-made-debtsolution-${type_client}`;
        case 7:
          return `sales-made-boostcredit-${type_client}`;
        case 8:
          return `sales-made-tax-research-${type_client}`;
        case 10:
          return `sales-made-claimdepartment-${type_client}`;
        case 12:
          return `sales-made-paragon-${type_client}`;
        case 3:
          return `sales-made-bussiness-${type_client}`;
        case 14:
          return `sales-made-bookeeping-${type_client}`;
        case 21:
        case 22:
        case 23:
        case 24:
        case 26:
        case 20:
          return `sales-made-connection-${type_client}`;
        case 11:
        case 28:
        case 29:
        case 30:
          return `sales-made-spfa-${type_client}`;
      }
    },
    colorText() {
      let color = {
        "DISAPPROVED SALE": "text-danger font-strong",
        "SENT TO REVISION": "text-warning font-strong",
        "APPROVED SALE": "text-success font-strong",
      };
      return color[this.title] || "text-warning font-strong";
    },
    badgeColor() {
      let classes = {
        "DISAPPROVED SALE": "bg-light-danger",
        "SENT TO REVISION": "bg-light-warning",
        "APPROVED SALE": "bg-light-success",
      };
      return classes[this.title] || "bg-light-warning";
    },
    iconTitle() {
      let icons = {
        "APPROVED SALE": "CheckIcon",
        "DISAPPROVED SALE": "XOctagonIcon",
        "SENT TO REVISION": "InfoIcon",
      };
      return icons[this.title] || "AlertTriangleIcon";
    },
    ...mapGetters({
      G_IsActive: "SalesMadeStore/G_IsActive",
      G_SalesMadeInfo: "SalesMadeStore/G_SalesMadeInfo",
      title: "SalesMadeStore/G_Title",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },

    isActive: {
      get() {
        return this.G_IsActive;
      },
      set(value) {
        this.$store.commit("SalesMadeStore/M_IsActive", value);
      },
    },

    salesMadeInfo: {
      get() {
        return this.G_SalesMadeInfo;
      },
      set(value) {
        this.$store.commit("SalesMadeStore/M_SalesMadeInfo", value);
      },
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "SalesMadeStore/CHANGE_REFRESH_TABLE",
    }),
    goToSalesMade() {
      const url = this.$router.resolve({
        name: this.pathNameSaleMade,
      }).href;
      window.open(url, "_blank");
    },

    async closeModal() {
      await salesMadeCounter();
      this.isActive = false;
    },
  },
  async created() {},
};
</script>
<style lang="scss" scoped>
.icon-badge {
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &.completed {
    background-color: rgba(40, 199, 111, 0.1);
    color: #468844;
  }
  &.pending {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.reactivated {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.scaled {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
}

.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}
</style>
