import CustomerTicketsCeo from "@/views/customer-tickets/views/CustomerTicketsCeo.vue";
import { ticketsTabs } from "@/views/customer-tickets/router/tickets.router.js"
export default [
  {
    path: "/tickets",
    name: "customer-tickets-ceo",
    redirect: { name: "customer-ticket-ceo-in-progress" },
    component: CustomerTicketsCeo,
    children:
      ticketsTabs("ceo", 1),
    // tab in progress
    // {
    //   path: "in-progress-customer-tickets",
    //   name: "in-progress-customer-tickets",
    //   component: () =>
    //     import("@/views/customer-tickets/views/CustomerTicketsModules.vue"),
    //   meta: {
    //     pageTitle: "Customer Tickets",
    //     breadcrumb: [
    //       {
    //         text: "In progress",
    //       },
    //     ],
    //     permittedRoles: 1,
    //     resolvedCustomerTickets: "resolved-customer-tickets",
    //     rejectedCustomertickets: "rejected-customer-tickets",
    //     NotSolvedCustomertickets: "not-solved-customer-tickets",
    //     reviewRequest: "review-request",
    //     tab: 1,
    //   },
    // },
    // // tab resolved
    // {
    //   path: "resolved-customer-tickets",
    //   name: "resolved-customer-tickets",
    //   component: () =>
    //     import(
    //       "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
    //     ),
    //   meta: {
    //     pageTitle: "Customer Tickets",
    //     breadcrumb: [
    //       {
    //         text: "Resolved",
    //       },
    //     ],
    //     permittedRoles: 1,
    //     inProgressCustomerTickets: "in-progress-customer-tickets",
    //     rejectedCustomertickets: "rejected-customer-tickets",
    //     NotSolvedCustomertickets: "not-solved-customer-tickets",
    //     newCustomerTickets: "new-customer-tickets",
    //     reviewRequest: "review-request",
    //     tab: 2,
    //   },
    // },
    // // tab rejected
    // {
    //   path: "rejected-customer-tickets",
    //   name: "rejected-customer-tickets",
    //   component: () =>
    //     import(
    //       "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
    //     ),
    //   meta: {
    //     pageTitle: "Customer Tickets",
    //     breadcrumb: [
    //       {
    //         text: "Rejected",
    //       },
    //     ],
    //     permittedRoles: 1,
    //     resolvedCustomerTickets: "resolved-customer-tickets",
    //     inProgressCustomerTickets: "in-progress-customer-tickets",
    //     NotSolvedCustomertickets: "not-solved-customer-tickets",
    //     newCustomerTickets: "new-customer-tickets",
    //     reviewRequest: "review-request",
    //     tab: 3,
    //   },
    // },
    // // tab not solved
    // {
    //   path: "not-solved-customer-tickets",
    //   name: "not-solved-customer-tickets",
    //   component: () =>
    //     import(
    //       "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
    //     ),
    //   meta: {
    //     pageTitle: "Customer Tickets",
    //     breadcrumb: [
    //       {
    //         text: "Not solved",
    //       },
    //     ],
    //     permittedRoles: 1,
    //     resolvedCustomerTickets: "resolved-customer-tickets",
    //     inProgressCustomerTickets: "in-progress-customer-tickets",
    //     rejectedCustomertickets: "rejected-customer-tickets",
    //     newCustomerTickets: "new-customer-tickets",
    //     reviewRequest: "review-request",
    //     tab: 5,
    //   },
    // },
    // {
    //   path: "new-customer-tickets",
    //   name: "new-customer-tickets",
    //   component: () =>
    //     import(
    //       "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
    //     ),
    //   meta: {
    //     pageTitle: "Customer Tickets",
    //     breadcrumb: [
    //       {
    //         text: "New",
    //       },
    //     ],
    //     permittedRoles: 1,
    //     resolvedCustomerTickets: "resolved-customer-tickets",
    //     inProgressCustomerTickets: "in-progress-customer-tickets",
    //     rejectedCustomertickets: "rejected-customer-tickets",
    //     newCustomerTickets: "new-customer-tickets",
    //     reviewRequest: "review-request",
    //     tab: 6,
    //   },
    // },
    // {
    //   path: "review-request",
    //   name: "review-request",
    //   component: () =>
    //     import(
    //       "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue"
    //     ),
    //   meta: {
    //     pageTitle: "Customer Tickets",
    //     breadcrumb: [
    //       {
    //         text: "Review Request",
    //       },
    //     ],
    //     permittedRoles: 1,
    //     resolvedCustomerTickets: "resolved-customer-tickets",
    //     inProgressCustomerTickets: "in-progress-customer-tickets",
    //     rejectedCustomertickets: "rejected-customer-tickets",
    //     newCustomerTickets: "new-customer-tickets",
    //     reviewRequest: "review-request",
    //     tab: 7,
    //   },
    // },

  },
];
