export default [
  {
    path: "clients/account/:idClient",
    name: "ce-customer-service-dashboard",
    redirect: { name: "personal-information-ce-customer-service" },
    component: () =>
      import(
        /* webpackChunkName: "CEDCustomerServiceClientsAccount" */ "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/AccountCustomerService.vue"
      ),
    props: true,
    meta: {
      module: 22,
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "dis",
        name: "dis-ce-customer-service",
        redirect: { name: "analysis-cr-cs" },
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountDis" */ "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Credit > Items",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "dispute-detail",
            name: "dispute-detail-cs",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/DisputeDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Disputes",
                  active: true,
                },
              ],
            },
          },
          {
            path: "report-detail",
            name: "report-detail-cs",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Credit Report",
                  active: true,
                },
              ],
            },
          },
          {
            path: "updates-detail",
            name: "updates-detail-cs",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/UpdateDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Updates",
                  active: true,
                },
              ],
            },
          },
            {
            path: "workplan-detail",
            name: "workplan-detail-cs",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Workplan",
                  active: true,
                },
              ],
            },
          },
            {
            path: "recommendation-detail",
            name: "recommendation-detail-cs",
            component: () =>
              import(
                /* webpackChunkName: "recommendationDetailCs" */   "@/views/commons/components/clients/dashboard/options/services/services.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Recommendations",
                  active: true,
                },
              ],
            },
          },
          {
            path: "analysis-cr",
            name: "analysis-cr-cs",
            component: () =>
              import(
                /* webpackChunkName: "AnalysisCr" */ "@/views/commons/components/clients/dashboard/analysis/AccountsClients.vue"
              ),
            meta: {
              module: 22,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Analysis CR",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "acr",
        name: "acr-ce-customer-service",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "pay",
        name: "pay-ce-customer-service",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "task-notes",
        name: "task-notes-ce-customer-service",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotesNew.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information",
        name: "personal-information-ce-customer-service",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountPersonalInformation" */ "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/information-client/CustomerServiceClientInformation.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files",
        name: "client-files-ce-customer-service",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "report",
        name: "report-ce-customer-service",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceClientsAccountReport" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Report",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log",
        name: "call-log-client-ce-customer-service",
        redirect: { name: "call-log-conversation-ce-customer-service" },
        component: () =>
          import(
            "@/views/commons/components/ring-central/call-log/tabs/ClientTabs.vue"
          ),
        props: true,
        meta: {
          module: 22,
          isClientsTab: true,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
          callConversationRoute: "call-log-conversation-ce-customer-service",
          callNoConversationRoute:
            "call-log-not-conversation-ce-customer-service",
        },

        children: [
          {
            path: "conversation",
            name: "call-log-conversation-ce-customer-service",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Conversation",
                  active: true,
                },
              ],
              isConversation: 1,
            },
          },
          {
            path: "no-conversation",
            name: "call-log-not-conversation-ce-customer-service",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "No Conversation",
                  active: true,
                },
              ],
              isConversation: 0,
            },
          },
        ],
      },
      {
        path: "services",
        name: "services-client-ce-digital",
        component: () =>
          import(
            /* webpackChunkName: "servicesClient" */
            "@/views/commons/components/clients/dashboard/options/services/services.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 22,
          pageTitle: "Services",
          breadcrumb: [{ text: "Services", active: true }],
        },
      },
      {
        path: "tickets",
        name: "client-tickets-ce-customer-service",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          module: 22,
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "leads/report/:idlead/:idfile",
    name: "ce-customer-service-report-lead",
    component: () =>
      import(
        /* webpackChunkName: "CreditExpertsLeadsReport" */ "@/views/commons/components/ncr/components/report/ReportLead.vue"
      ),
    props: true,
    meta: {
      module: 22,
      isClientsTab: true,
      pendingRoute: "ncr-pending",
      returnedRoute: "ncr-returned",
      completedRoute: "ncr-completed",
      reportLeadRoute: "report-lead",
      leadRoute: "lead-show",
    },
  },
];
