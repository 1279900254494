import { amgApi } from '@/service/axios'

class Calendar {
  async filterMonthNext(body) {
    try {
      const data = await amgApi.post('/commons/calendar/get-event-calendar', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async filterMonthNextTask(body) {
    try {
      const data = await amgApi.post('/commons/calendar/filter-month-next-task-all', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getAppointmentsProgram(body) {
    try {
      const data = await amgApi.post('/commons/calendar/get-appointments-program', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async searchAccountName(body) {
    try {
      const data = await amgApi.post('/commons/calendar/search-account-name', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async doneTaskById(body) {
    try {
      const data = await amgApi.post('/commons/calendar/done-task-by-id', body)
      return data
    } catch (error) {
      throw error
    }
  }
}

export default new Calendar()
