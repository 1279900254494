<template>
  <div>
    <header-slot />
    <b-nav
        pills
        class="m-0"
    >
      <b-nav-item
          :to="{ name: 'ced-sales-derivations-lead-list' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
      >Leads

        <span v-if="G_DERIVATION_LEAD_COUNTER > 0" class="ml-2">
          <feather-icon
              icon
              :badge="
              G_DERIVATION_LEAD_COUNTER > 99 ? '99+' : G_DERIVATION_LEAD_COUNTER
            "
              badge-classes="badge-danger badge-glow "
          />
        </span>
      </b-nav-item>
      <b-nav-item
          :to="{ name: 'ced-sales-derivations-client-list' }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
      >Clients

        <span v-if="G_DERIVATION_CLIENT_COUNTER > 0" class="ml-2">
          <feather-icon
              icon
              :badge="
              G_DERIVATION_CLIENT_COUNTER > 99 ? '99+' : G_DERIVATION_CLIENT_COUNTER
            "
              badge-classes="badge-danger badge-glow "
          />
        </span>
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-primary border-3 border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DerivationsService from '@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service'
import store from '@/store'

export default {
  name: "Derivations",
  computed: {
    ...mapGetters({
      G_DERIVATION_LEAD_COUNTER: 'ceDigitalSalesDerivations/G_DERIVATION_LEAD_COUNTER',
      G_DERIVATION_CLIENT_COUNTER: 'ceDigitalSalesDerivations/G_DERIVATION_CLIENT_COUNTER',
    })
  },
  async created() {
    const params = {
      program_origin: 3,
    }
    const data = await DerivationsService.getLeadClientDerivationCounters(params)
    if (data.status === 200) {
      if (data.data[0].all_count > 0) {
        const payload = {
          routeName: "sales-derivation",
          tag: data.data[0].all_count > 99 ? "99+" : data.data[0].all_count,
        }
        store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload)
        await store.dispatch('ceDigitalSalesDerivations/A_DERIVATION_CLIENT_COUNTER', data.data[0].client_count)
        await store.dispatch('ceDigitalSalesDerivations/A_DERIVATION_LEAD_COUNTER',data.data[0].lead_count)
      }
    }
  }
}
</script>

<style scoped>

</style>
