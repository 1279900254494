import helpdeskService from "../services/helpdesk.service";

export default {
  namespaced: true,
  state: {
    counterNotifications: 0,
    completedNotify: 0,
    rejectedNotify: 0,

    //-- reviewer counters
    reviewerCounterNotifications: 0,
    reviewerOnProcessNotify: 0,
  
    //* categories on helpdesk
    helpdeskRoles: {
      isEngSoftChief: false, //? categoria especial, máxima visibilidad
      isReviewer: false, //? el encargado de recibir los tickets
      isDevLeader: false, //? el encargado de revisar los tickets
      isEngSoft: false //? desarrolladores de software que pueden ser asignados
    },

    //* tab active
    tabActive: 1
  },
  getters: {
    G_COUNTER_NOTIFICATIONS: state => state.counterNotifications,
    G_REVIEWER_COUNTER_NOTIFICATIONS: state => state.reviewerCounterNotifications,
    G_REVIEWER_ON_PROCESS_NOTIFICATIONS: state => state.reviewerOnProcessNotify,
    G_HELPDESK_ROLES: state => state.helpdeskRoles,
    G_TAB_ACTIVE: state => state.tabActive
  },
  mutations: {
    SET_COUNTER_NOTIFICATIONS(state, payload) {
      state.counterNotifications = payload;
    },
    SET_REVIEWER_COUNTER_NOTIFICATIONS(state, payload) {
      state.reviewerCounterNotifications = payload;
    },
    SET_REVIEWER_ON_PROCESS_NOTIFICATIONS(state, payload) {
      state.reviewerOnProcessNotify = payload;
    },
    SET_HELPDESK_ROLES(state, payload) {
      state.helpdeskRoles = {
        ...state.helpdeskRoles,
        ...payload
      };
    },
    SET_TAB_ACTIVE(state, payload) {
      state.tabActive = payload
    }
  },
  actions: {
    async loadCounterNotifications({ commit, rootState }) {
      const params = {
        id_user: rootState.auth.currentUser.user_id,
        id_module: rootState.auth.currentUser.modul_id,
      };
      const response = await helpdeskService.getCounterNotifications(params);
      commit("SET_COUNTER_NOTIFICATIONS", response[0]);

      return response;
    },
  },
};
