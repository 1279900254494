import { render, staticRenderFns } from "./GridCustomerTickets.vue?vue&type=template&id=ed22c906&scoped=true"
import script from "./GridCustomerTickets.vue?vue&type=script&lang=js"
export * from "./GridCustomerTickets.vue?vue&type=script&lang=js"
import style0 from "./GridCustomerTickets.vue?vue&type=style&index=0&id=ed22c906&prod&scoped=true&lang=css"
import style1 from "./GridCustomerTickets.vue?vue&type=style&index=1&id=ed22c906&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed22c906",
  null
  
)

export default component.exports