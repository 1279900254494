export default [
    {
        path:'/cedigital/settings/users-relationship',
        name:'settings-users-relationship',
        component: ()=> import('@/views/ce-digital/sub-modules/settings/views/users-relationship/UsersRelationship.vue'),
        meta: {
            module: 24,
            pageTitle: "Users relationship",
            permittedRoles: [1, 17],
            breadcrumb: [
              {
                text: "Users relationships in connection",
              },
            ]
          },
    }
]