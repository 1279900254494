export default
{
    path: 'reports',
    name: 'ti-reports',
    component: () => import(/* webpackChunkName: "PRReports" */ '@/views/paragon/views/roi-reports/Reports.vue'),
    redirect: { name: 'ti-reports-daily' },
    children: [
        {
            path: 'daily',
            component: () => import(/* webpackChunkName: "PRReportsDaily" */ '@/views/commons/components/roi-reports/ReportsCrmDaily.vue'),
            name: 'ti-reports-daily',
            meta: {
                route: 'ti/leads/show',
                permittedRoles: [1, 7, 17],
                pageTitle: 'Reports',
                breadcrumb: [{
                    text: 'Reports',
                }],
            },
        },

        {
            path: 'general',
            component: () => import(/* webpackChunkName: "PRReportsGeneral" */ '@/views/commons/components/roi-reports/ReportsCrmGeneral.vue'),
            name: 'ti-reports-general',
            meta: {
                route: 'ti/leads/show',
                permittedRoles: [1, 7, 17],
                pageTitle: 'Reports',
                breadcrumb: [{
                    text: 'Reports',
                }],
            },
        },

    ],
    meta: {
        route: 'ti/leads/show',
        permittedRoles: [1, 7, 17],
        pageTitle: 'Reports',
        breadcrumb: [{
            text: 'Reports',
        }],
    },
}
