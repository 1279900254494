import { amgApi } from "@/service/axios";

class AppointmentsService {
    async updatePendingAppointment(params){
        try {
            const response = await amgApi.post(
                "/commons/calendar/update-pending-appointment",
                params
              );
              return response; 
        } catch (error) {
            throw error;
        }
    }

    async getDefaultSmsFinancialAnalysis() {
      try {
        const data = await amgApi.get("/debt-solution/list-default-sms-financial-analysis");
        return data;
      } catch (error) {
        console.error(error)
      }
    }

    async verifyIfExistCR(params){
      try {
        const data = await amgApi.post("sales-made/debt-solution/verify-if-exist-cr", params);
        return data;
      } catch (error) {
        console.error(error)
      }
    }

    async validateCompleteCrProcess(body){
      try {
          const data = await amgApi.post("/sales-made/debt-solution/validate-complete-cr-process", body);
          return data;
      } catch (error) {
          throw error;
      }
    }

    async accountsRemoveToEvent(body){
      try {
          const data = await amgApi.post("/crm/event/accounts-remove-to-event", body);
          return data;
      } catch (error) {
          throw error;
      }
    }

    async getCalls(body){
      try {
        const data = await amgApi.post("/crm/event/get-calls", body);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async getSummary(body){
      try {
        const data = await amgApi.post("/crm/event/get-summary", body);
        return data;
      } catch (error) {
        throw error;
      }
    }

    async getCallSelected(body){
      try {
        const data = await amgApi.post("/crm/event/get-call-selected", body);
        return data;
      } catch (error) {
        throw error;
      }
    }
}
export default new AppointmentsService();