export default [
    {
      path: "sms-inbox",
      name: "mm-chat-sms",
      component: () =>
        import(
          /* webpackChunkName: "CRM sms-chat-sms" */
          "@/views/commons/components/ring-central/chat-sms/RcChatMain.vue"
        ),
      meta: {
        pageTitle: "Messages",
        // permittedRoles: [1, 2, 17],
        breadcrumb: [
          {
            text: "Inbox",
            active: true,
          },
        ],
  
      },
    },
  ]
  