import store from "@/store";
import Vue from "vue";
import moment from "moment";
import CreativeRequestService from '@/views/commons/components/creative-requests/services/creative.request.js';
import router from '@/router';

const UpdateClaimStatusNotification = () => {
  window.socket.bind("update-claim-status-general", async (response) => {
    const { status, claimer_id, claim } = response;
    const user_id = store.state.auth.currentUser.user_id;
    const statusInt = parseInt(status);
    if (user_id == claimer_id && statusInt > 3) {
      const status_name = statusInt == 4 ? "Resolved" : "Rejected";
      const iconMap = {
        3: "BookOpenIcon",
        4: "CheckCircleIcon",
        5: "SlashIcon",
      };

      const icon = iconMap[parseInt(status)] || "BookOpenIcon";

      Vue.prototype.$amgAlert({
        title: "You have a new claim in status " + status_name,
        subtitle: claim.username,
        icon,
        data: [
          { icon: "TypeIcon", title: "TYPE", description: claim.claim_type },
          { icon, title: "TITLE", description: claim.title },
          {
            icon: "ListIcon",
            title: "DESCRIPTION",
            description: claim.description,
          },
        ],
      });
    }
  });
};

const sendSocketNotificationCreative = () => {
  window.socket.bind(
    "notification-update-status-by-paragon-or-creative",
    async (dataInfo) => {
      const { employee } = dataInfo;
      const { user_id, modul_id } = store.state.auth.currentUser;
      const getLatestData = () => {
        if (employee.data_body.length === 1) {
          return employee.data_body[0].name;
        } else if (employee.data_body.length > 1) {
          return employee.data_body[employee.data_body.length - 1].name;
        }
        return null;
      };

      const latestData = getLatestData();
      const material = employee.data_body[0].material;
      const moduleId = employee.moduleId;

      const linkMappings = {
        12: {
          Pending: 'creative-request-options-ti',
          'In process': 'paragon-in-process',
          Finished: 'paragon-finished',
          Approved: 'paragon-approved',
          Remarks: 'paragon-observed',
        },
        27: {
          Pending: 'creative-request-options',
          'In process': 'creative-in-process',
          Finished: 'creative-finished',
          Approved: 'creative-approved',
          Remarks: 'creative-observed',
        },
      };

      let linkParagon = linkMappings[12][latestData] || 'creative-request-options-ti';
      let linkCreative = linkMappings[27][latestData] || 'creative-request-options';

      for (const superUser of employee.super) {
        if (user_id === superUser.user_id) {
          const timeLine = employee.data_body.map((item) => {
            return {
              title: item.name,
              date: item.created_at,
              created_by: item.created_by,
            };
          });
          Vue.prototype.$timeLineCreative({
            title: employee.title,
            icon: "CheckIcon",
            subtitle: material,
            timeLine: timeLine,
            okTitle: 'GO TO DESIGN REQUESTS',
            ok: () => {
              if (router.currentRoute.name !== linkParagon && router.currentRoute.name !== linkCreative) {
                if (modul_id === 12) { // paragon
                  router.push({ name: linkParagon })
                  .catch( (err) => {
                    if(
                      err.name !== 'NavigationDuplicated' &&
                      !err.message.includes('Avoided redundant navigation to current location')
                    ) {
                      throw err;
                    }
                  });
                }
    
                if (modul_id === 27) { // creative
                  router.push({ name: linkCreative })
                  .catch( (err) => {
                    if(
                      err.name !== 'NavigationDuplicated' &&
                      !err.message.includes('Avoided redundant navigation to current location')
                    ) {
                      throw err;
                    }
                  });
                }
              }
              
              window.amgApi.post("/commons/close-all-swal", {to_id: superUser.user_id});
            }
          });
        }
      }
    }
  );
};


const updateCounterSiderbarCreativeAndParagon = () => {
  window.socket.bind(
    "notification-update-counter-by-paragon-or-creative",
    async (dataInfo) => {
      const { employee } = dataInfo;
      if (store.state.auth.currentUser.modul_id === 27) {
        const params = {
          modul_id: 27,
          user_id: store.state.auth.currentUser.user_id,
          role_id: store.state.auth.currentUser.role_id,
        }
        const response = await CreativeRequestService.getdesignRequestCounterNotifications(params)
        const payload = {
            routeName: "creative-request-options",
            tag: response.data[0].total,
          }
        store.commit("CreativeDesignStore/SET_COUNTERS", response.data[0]);
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
      }
      if (store.state.auth.currentUser.modul_id === 12) {
        const paramsParagon = {
          modul_id: 12,
          user_id: store.state.auth.currentUser.user_id,
          role_id: store.state.auth.currentUser.role_id,
        }
        const data = await CreativeRequestService.getdesignRequestCounterNotifications(paramsParagon)
        const payloadParagon = {
            routeName: "creative-request-options-ti",
            tag: data.data[0].total,
          }
        store.commit("CreativeDesignStore/SET_COUNTERS", data.data[0]);
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payloadParagon)
      }
    }
  );
};

const updateDueDateNotifications = () => {
  window.socket.bind(
    "notification-update-due-date-by-paragon-or-creative",
    async (dataInfo) => {
      const { employee } = dataInfo;      
      const { user_id } = store.state.auth.currentUser;

      if(employee.option == 'request'){
        for (const superUser of employee.super){
          if(user_id === superUser.user_id){
            Vue.prototype.$amgAlert({
              title: "Request: " + employee.documentName,
              iconsubtitle2:"ClockIcon",
              icon:"ClockIcon",
              iconCreatedBy: "UserIcon",
              created_by: employee.userName + " " + "CHANGED THE REQUEST'S DUE DATE",
              subtitle: "OLD DUE DATE: " + employee.oldDueDate,
              subtitle2: "NEW DUE DATE: " +  employee.dueDate,
              ok: async () => {
                window.amgApi.post("/commons/close-all-swal", {to_id: superUser.user_id});
              },
            });
          }
        }
      }else{
        if(user_id === employee.designer){
          Vue.prototype.$amgAlert({
            title: "Update: " + employee.documentName,
            iconsubtitle2:"ClockIcon",
            iconsubtitle3:"LayersIcon",
            iconsubtitle4:"GridIcon",
            icon:"ClockIcon",
            iconCreatedBy: "UserIcon",
            created_by: employee.userName + " " + "CHANGED YOUR TASK'S DUE DATE ",

            subtitle3: "TASK: " +   employee.option,
            subtitle4: "REQUEST: " + employee.parentDocumentName,

            subtitle: "OLD DUE DATE: " + employee.oldDueDate,
            subtitle2: "NEW DUE DATE: " +  employee.dueDate,
            ok: async () => {
              window.amgApi.post("/commons/close-all-swal");
            },
          });
        }
      }

    }
  );
};

const NotifyNewEmployeeClaimMessage = () => {
  window.socket.bind("new-message-in-employee-claim", async (response) => {
    const { message } = response;
    let time = moment(message.date_time).format("ddd, D MMM YYYY hh:mm A");

    if (message.recipient != store.state.auth.currentUser.user_id) return;
    if (message.created_by === store.state.auth.currentUser.user_id) return;

    Vue.prototype.$amgAlert({
      title: "You have a new message in your claim",
      icon: "UserIcon",
      subtitle: `From ${message.user_name}`,
      data: [
        {
          icon: "TypeIcon",
          title: "TITLE",
          description: message.claim_title,
        },
        {
          icon: "MessageSquareIcon",
          title: "MESSAGE",
          description: message.content,
        },
        {
          icon: "ClockIcon",
          title: "DATE",
          description: time
        },
      ],
      ok: async () => {
        await window.amgApi.post('/commons/close-all-swal', { to_id: message.recipient });
      },
    });
  });
};

export { UpdateClaimStatusNotification,
   NotifyNewEmployeeClaimMessage,
   sendSocketNotificationCreative,
   updateCounterSiderbarCreativeAndParagon,
   updateDueDateNotifications,
  };
