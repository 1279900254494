<template>
  <b-modal
    v-model="ownControl"
    title-class="h3 text-white font-weight-bolder"
    modal-class="custom-modal-amg"
    size="md"
    :title="title"
    header-bg-variant="transparent"
    header-class="p-0"
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          {{ title }}
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <b-row class="mt-1">
      <b-col lg="12">
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
            >CLIENT
            </b-input-group-text>
          </template>
          <b-skeleton
            v-if="!finishedLoading"
            type="input"
            class="space"
          />
          <b-form-input
            v-if="finishedLoading"
            v-model="voidRefundData.client_name"
            :disabled="true"
          /> </b-input-group></b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
            >TRANSACTION ID
            </b-input-group-text>
          </template>
          <b-skeleton
            v-if="!finishedLoading"
            class="space"
            type="input"
          />
          <b-form-input
            v-if="finishedLoading"
            v-model="voidRefundData.transaction_id"
            :disabled="true"
          /> </b-input-group></b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
            >AMOUNT
            </b-input-group-text>
          </template>

          <b-skeleton
            v-if="!finishedLoading"
            class="space"
            type="input"
          />
          <validation-observer
            ref="form2"
          >
            <validation-provider
              v-slot="{ errors }"
              name="amount"
              :rules="`required|max_value:${initialVoidRefundAmount}|min_value:0`"
            >
              <money
                v-if="finishedLoading"
                id="input-1a"
                v-model="voidRefundData.amount"
                style="'border-color:rgba(255, 255, 255, 0.4)'"
                v-bind="maskMoney"
                class="form-control"
                :disabled="disabledPartialVoidRefundAmount"
              />
              <small
                v-if="errors[0]"
                class="text-danger"
              >
                $ {{ errors[0].includes('min')?`0 ${errors[0]} amount`:`${initialVoidRefundAmount} ${errors[0]} amount` }}
              </small>

            </validation-provider>
          </validation-observer>
        </b-input-group>
      </b-col>

    </b-row>
    <b-row
      v-if="isPartial && typeVoidRefund === 1"
      class="mt-1"
    >
      <b-col>
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
              style="width: 15em"
            >{{ `PROGRAM ${title}` }}
            </b-input-group-text>
          </template>
          <b-skeleton
            v-if="!finishedLoading"
            class="space"
            type="input"
          />
          <b-form-datepicker
            v-if="finishedLoading"
            v-model="programVoid"
            :min="minimumProgramVoid"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
          />
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
            >DATE
            </b-input-group-text>
          </template>
          <b-skeleton
            v-if="!finishedLoading"
            class="space"
            type="input"
          />
          <span
            v-if="finishedLoading"
            style="'border-color:rgba(255, 255, 255, 0.4)'; "
            :style="
              isDarkSkin
                ? 'background: #17171A;opacity: 0.3;color:white'
                : 'background: #efefef'
            "
            class="form-control"
          >{{ voidRefundData.settlement_date | myGlobal }}</span>
        </b-input-group></b-col>
    </b-row>

    <validation-observer ref="form">
      <div class="col-lg-12 mt-1">
        <ValidationProvider
          v-slot="{ errors }"
          name="comment"
          rules="required"
        >
          <div class="form-group row">
            <span>Comment</span>
            <b-skeleton
              v-if="!finishedLoading"
              class="space"
              type="input"
            />
            <b-textarea
              v-if="finishedLoading"
              v-model="comment"
              class="input-form"
              style="height: 140px"
              :class="{ 'border-danger': errors[0] }"
            />
          </div>
        </ValidationProvider>
      </div>
    </validation-observer>

    <template #modal-footer>
      <b-button
        v-if="typeVoidRefund === 1"
        class="d-flex justify-content-center"
        variant="primary"
        :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
        @click="isPartial ? programPartialVoid() : insertVoid()"
      >
        <b-spinner
          v-if="spinnerOn"
          small
        />
        PROCESS {{ title }}
      </b-button>

      <b-button
        v-if="typeVoidRefund === 2"
        class="d-flex justify-content-center"
        :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
        variant="primary"
        @click="isPartial ? insertPartialRefund() : insertRefund()"
      >
        <b-spinner
          v-if="spinnerOn"
          small
        />
        PROCESS {{ title  }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services';

export default {
  props: {
    voidRefundData: {
      type: Object,
      default: null,
    },
    typeVoidRefund: {
      type: Number,
      default: null,
    },
    disabledPartialVoidRefundAmount: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      comment: '',
      spinnerOn: false,
      initialVoidRefundAmount: 0,
      title: '',
      maskMoney: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        precision: 2,
        masked: false,
      },
      finishedLoading: false,
      ownControl: false,
      programVoid: null,
      initialVoidRefundDate: null,
      minimumProgramVoid: null,
      data: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    isPartial() {
      return (
        parseFloat(this.voidRefundData.amount)
        !== parseFloat(this.initialVoidRefundAmount)
      );
    },
  },
  watch: {
    'voidRefundData.amount': function (val) {

      if (this.isPartial) {
        this.title = this.title.includes('VOID')
          ? 'PARTIAL VOID'
          : ' PARTIAL REFUND';
      } else {
        this.title = this.title.includes('VOID') ? 'VOID' : 'REFUND';
      }
    },

  },
  async created() {
    this.initialVoidRefundAmount = this.voidRefundData.amount;
    if (this.typeVoidRefund === 1) {
      this.title = 'VOID';
    }
    if (this.typeVoidRefund === 2) {
      this.title = 'REFUND';
    }

    this.ownControl = true;
    this.finishedLoading = true;

    const date = new Date(this.voidRefundData.settlement_date);
    const date2 = new Date(date.getTime() + 24 * 60 * 60 * 1000);
    this.minimumProgramVoid = date2.toISOString().slice(0, 10);
    this.programVoid = date2.toISOString().slice(0, 10);
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    /**
     * !Insert Void
     */
    async insertVoid() {
      try {
        this.validador = true;
        const result = await this.$refs.form.validate();

        if (result) {
          this.spinnerOn = true;
          this.addPreloader();
          const params = {
            idtransaction: this.voidRefundData.transaction_id,
            idmerchant: this.voidRefundData.merchant,
            comment: this.comment,
            iduser: this.currentUser.user_id,
            client_name: this.voidRefundData.client_name,
          };

          const data = await ClientsOptionsServices.voidTransaction(params);

          if (data.status === 200) {
            this.$emit('refresh', {
              amount: this.voidRefundData.amount,
              id: data.data.id,
            });
            this.removePreloader();
            this.showSuccessSwal();
            this.closeModal();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.spinnerOn = false;
        this.showErrorSwal(e);
        return [];
      }
      return [];
    },
    /**
     * !Program Partial Void
     */
    async programPartialVoid() {
      try {
        this.validador = true;
        const result = await this.$refs.form.validate();
        const result2 = await this.$refs.form2.validate();
        if (result && result2) {
          if (this.programVoidTime < this.initialVoidRefundTime && this.initialVoidRefundDate === this.programVoid) {
            this.showWarningSwal(`La Programacion Minima es ${this.initialVoidRefundDate}`);
            this.programVoidTime = this.initialVoidRefundTime;
            return;
          }

          const date = new Date(this.voidRefundData.settlement_date);
          const hours = (`0${date.getHours()}`).slice(-2);
          const minutes = (`0${date.getMinutes()}`).slice(-2);
          const seconds = (`0${date.getSeconds()}`).slice(-2);
          const programmedVoid = `${this.programVoid} ${hours}:${minutes}:${seconds}`;

          this.spinnerOn = true;
          const params = {
            idtransaction: this.voidRefundData.transaction_id,
            idmerchant: this.voidRefundData.merchant,
            comment: this.comment,
            idUser: this.currentUser.user_id,
            datetoPay: programmedVoid,
            amount: this.voidRefundData.amount,
            client_name: this.voidRefundData.client_name,

          };
          this.spinnerOn = false;
          this.addPreloader();
          const data = await ClientsOptionsServices.programPartialVoidTransaction(params);


          if (data.status === 200) {
            this.$emit('refresh', {
              amount: this.voidRefundData.amount,
              id: data.data.id,
            });
            this.removePreloader();
            this.showSuccessSwal();
            this.closeModal();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.spinnerOn = false;
        this.showErrorSwal(e);
      }
    },
    /**
     * !Transaction Refund
     */
    async insertRefund() {
      try {
        this.validador = true;
        const result = await this.$refs.form.validate();

        if (result) {
          this.spinnerOn = true;
          const params = {
            idtransaction: this.voidRefundData.transaction_id,
            idmerchant: this.voidRefundData.merchant,
            comment: this.comment,
            idUser: this.currentUser.user_id,
            iduser: this.currentUser.user_id,
            client_name: this.voidRefundData.client_name,

          };
          this.addPreloader();
          const data = await ClientsOptionsServices.refundTransaction(params);

          if (data.status === 200) {
            if (data.data.code === '1') {
              this.$emit('refresh', {
                amount: this.voidRefundData.amount,
                id: data.data.id,
              });
              this.showSuccessSwal(data.data.message);
              this.closeModal();
            } else if (data.data.code === 'E00040') {
              this.$emit('refresh', {
                amount: this.voidRefundData.amount,
                id: data.data.id,
              });
              this.showInfoSwal(data.data.message);
              this.closeModal();
            } else {
              this.spinnerOn = false;
              this.showToast('danger', 'top-right', data.data.message, 'XIcon');
            }
            this.removePreloader();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.spinnerOn = false;
        this.showErrorSwal(e);
        return [];
      }
      return [];
    },

    async insertPartialRefund() {
      try {
        this.validador = true;
        const result = await this.$refs.form.validate();
        const result2 = await this.$refs.form2.validate();

        if (result && result2) {
          this.spinnerOn = true;
          const params = {
            idtransaction: this.voidRefundData.transaction_id,
            idmerchant: this.voidRefundData.merchant,
            comment: this.comment,
            idUser: this.currentUser.user_id,
            amount: this.voidRefundData.amount,
            client_name: this.voidRefundData.client_name,

          };
          const data = await ClientsOptionsServices.refundPartialTransaction(params);

          if (data.status === 200) {
            if (data.data.code === '1') {
              this.$emit('refresh', {
                amount: this.voidRefundData.amount,
                id: data.data.id,
              });
              this.showSuccessSwal(data.data.message);
              this.closeModal();
            } else if (data.data.code === 'E00040') {
              this.$emit('refresh', {
                amount: this.voidRefundData.amount,
                id: data.data.id,
              });
              this.showInfoSwal(data.data.message);
              this.closeModal();
            } else {
              this.spinnerOn = false;
              this.showToast('danger', 'top-right', data.data.message, 'XIcon');
            }
            this.removePreloader();
          }
        }
      } catch (e) {
        this.removePreloader();
        this.spinnerOn = false;
        this.showErrorSwal(e);
        return [];
      }
      return [];
    },
  },

};
</script>

<style scoped>
.title_lable {
  width: 150px;
}
.space {
  width: 65% !important;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
</style>
