<template>
  <component
    :is="moduleId == 21 ? 'div' : 'b-modal'"
    v-model="ownShow"
    modal-class=" modal-primary"
    modal
    header-class="p-0"
    header-bg-variant="border-bottom border-bottom-2"
    size="xl"
    hide-footer
    @hidden="hideModal(false, 0)"
  >
    <template #modal-header="{}">
      <modal-service-header
        :type-modal="typeModal"
        :users-services="usersServices"
        :programs-all="programsAll"
        :header-s="headerS"
        :sales="salesClient"
        @changeProgram="changeProgram"
        @close="hideModal(false, 0)"
      />
    </template>

    <form-wizard
      ref="wizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :start-index="startIndex"
      :finish-button-text="textNextButton"
      :hide-buttons="disableActions || !buttonSubmitVisible"
      back-button-text="Previous"
      @on-complete="formSubmitted"
      v-if="!salesClient.ds_analysis_options_id"
    >
      <!-- accoint details tab -->
      <tab-content
        title="CREDITORS'S LIST"
        :before-change="validationFirstStep"
      >
        <modal-services-d-s-first-step
          v-if="step1 == 1 || isFirstStep"
          ref="firstStep"
          v-model="passwordIsCorrect"
          :idleyend="idleyend"
          :sales-client="salesClient"
          :is-modal-show="disableActions || (isModalShow && !hasInitialPaymentAndDS)"
          :is-modal-add="isModalAdd"
          :type-modal="typeModal"
          @nextStep="leyendDebtsolution"
          @input="validatePassword"
          @stateCreditor="getStateCreditor"
          @getCreditsDS="getCreditsDS"
        >
          <template #left-content>
            <verify-request-status
              v-if="requiredNewAppr"
              ref="verifyStatusRequest"
              :type-permission-id="1"
              :id-table="salesClient.event_id"
              @state="wasApprovedCheif = $event"
              send-new
              :key="verifyStatusKey"
            ></verify-request-status>
          </template>
        </modal-services-d-s-first-step>
      </tab-content>
      <!-- personal details tab -->
      <tab-content title="ANALYSIS" :before-change="validationSecondStep">
        <modal-services-d-s-second-step
          :key="key2"
          ref="secondStep"
          :sales-client="salesClient"
          :idleyend="idleyend"
          :is-modal-show="disableActions || (isModalShow && !hasInitialPaymentAndDS)"
          :is-modal-add="isModalAdd"
          :type-modal="typeModal"
          @nextStep="leyendDebtsolution(3)"
        />
      </tab-content>
      <!-- address  -->
      <tab-content title="QUESTIONNAIRE">
        <modal-services-d-s-third-step
          v-if="state3 == 1 || isThirdStep"
          ref="thirdStep"
          :key="key3"
          :sales-client="salesClient"
          :idleyend="idleyend"
          :is-modal-show="disableActions || (isModalShow && !hasInitialPaymentAndDS)"
          :is-modal-add="isModalAdd"
          :header-s="headerS"
          :type-modal="typeModal"
          @hideModal="hideModal"
        />
      </tab-content>
    </form-wizard>
    <form-wizard
      ref="wizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :start-index="newStartIndex"
      :finish-button-text="textNextButton"
      :hide-buttons="hideButtons"
      back-button-text="Previous"
      @on-complete="formSubmitted"
      v-else
    >
      <!-- accoint details tab -->
      <tab-content
        title="CREDITORS'S LIST"
        :before-change="validationFirstStep"
      >
        <modal-services-d-s-first-step
          ref="firstStep"
          v-model="passwordIsCorrect"
          :idleyend="idleyend"
          :sales-client="salesClient"
          :is-modal-show="disableActions || (isModalShow && !hasInitialPaymentAndDS)"
          :is-modal-add="isModalAdd"
          :type-modal="typeModal"
          @nextStep="leyendDebtsolution(2)"
          @input="validatePassword"
          @stateCreditor="getStateCreditor"
        />
      </tab-content>
      <!-- address  -->
      <tab-content title="QUESTIONNAIRE">
        <modal-services-d-s-third-step
          ref="thirdStep"
          :key="key3"
          :sales-client="salesClient"
          :idleyend="idleyend"
          :is-modal-show="disableActions || (isModalShow && !hasInitialPaymentAndDS)"
          :is-modal-add="isModalAdd"
          :header-s="headerS"
          :type-modal="typeModal"
          @hideModal="hideModal"
        />
      </tab-content>
    </form-wizard>
    <modal-send-request-permission
      v-if="showModalAppro"
      @close="showModalAppro = false"
      :type-permission-id="1"
      :id-table="salesClient.event_id"
      :client-name="salesClient.client"
      :lead-id="salesClient.lead_id"
      @updatePermission="refreshVerifyStatusRequest"
    ></modal-send-request-permission>
  </component>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import ModalServiceHeader from "@/views/crm/views/sales-made/components/modals/services/ModalServiceHeader.vue";
import ModalServicesDSFirstStep from "./debt-solution/ModalServicesDSFirstStep.vue";
import ModalServicesDSSecondStep from "./debt-solution/ModalServicesDSSecondStep.vue";
import ModalServicesDSThirdStep from "./debt-solution/ModalServicesDSThirdStep.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapGetters } from "vuex";
import ModalSendRequestPermission from "@/views/commons/components/permissions/ModalSendRequestPermission.vue";
import VerifyRequestStatus from "@/views/commons/components/permissions/components/VerifyStatusRequest.vue";
import AccountsRemoveSettlement from "@/views/commons/components/request-ncr/views/components/AccountsRemoveSettlement.vue";
import { amgApi } from "@/service/axios";

export default {
  components: {
    ModalServicesDSFirstStep,
    ModalServicesDSSecondStep,
    ModalServicesDSThirdStep,
    ModalServiceHeader,
    FormWizard,
    TabContent,
    ModalSendRequestPermission,
    VerifyRequestStatus,
    AccountsRemoveSettlement,
  },
  props: {
    modalServices: {
      type: Boolean,
      default: false,
    },
    salesClient: {
      type: Object,
      default: () => ({}),
    },
    typeModal: {
      type: Number,
      default: 1,
      // 1: create, 2: show
    },
    usersServices: {
      type: Array,
      default: () => [],
    },
    programsAll: {
      type: Array,
      default: () => [],
    },
    headerS: {
      type: Object,
      default: () => ({ program: "", seller: "", captured: "" }),
    },
  },
  data() {
    return {
      buttonSubmitVisible: true,
      show: false,
      passwordIsCorrect: false,
      ownShow: false,
      state1: 0,
      state2: 0,
      state3: 0,
      step1: 0,
      step2: 0,
      step3: 0,
      idleyend: "",
      leyend: "",
      key2: 0,
      key3: 100,
      startIndex: -1,
      requiredNewAppr: this.$route.meta.requiredNewAppr ? true : false,
      wasApprovedCheif: {
        status: "NOT",
        exists: false,
      },
      showModalAppro: null,
      verifyStatusKey: 1,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    directRoles() {
      return [1, 2, 17].includes(this.currentUser.role_id);
    },
    hideButtons() {
      if(this.moduleId == 16) return true;
      if (this.state1 == 0) {
        if (this.directRoles) {
          return false;
        } else if (!this.isFirstStep) {
          return !this.passwordIsCorrect || !this.buttonSubmitVisible;
        }
      } else {
        return false;
      }
    },
    textNextButton() {
      if (this.salesClient.status == 8 && this.moduleId != 2) return "Close";

      if (this.salesClient.status == 8) return "Update";

      if (this.salesClient.haveQuestionnaire !== "0") return "Close";

      if (this.isModalShow && !this.hasInitialPaymentAndDS && this.salesClient.haveQuestionnaire !== "0") return "Close";

      return "Submit";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isFirstStep() {
      return this.step1 === 0 && this.step2 === 0 && this.step3 === 0;
    },
    isSecondStep() {
      return this.step1 === 1 && this.step2 === 0 && this.step3 === 0;
    },
    isThirdStep() {
      return (
        this.step1 === 1 &&
        this.step2 === 1 &&
        (this.step3 === 0 || this.step3 === 1)
      );
    },
    disableActions(){
      return this.moduleId == 16;
    },
    isModalShow() {
      console.log("module", this.moduleId)
      return (
        this.disableActions ||
        ((this.$route.matched[0].meta.module === 2 &&
          this.salesClient.from_module === 26 &&
          (this.typeModal === 1 ||
            this.typeModal === 2 ||
            this.typeModal === 5)) ||
        (this.salesClient.from_module !== 26 &&
          (this.typeModal === 2 || this.typeModal === 5)))
      );
    },
    hasInitialPaymentAndDS() {
      return (
        this.salesClient.program_id == 4 &&
        this.salesClient.initial_payment_status == 1
      );
    },
    isModalAdd() {
      return (
        this.typeModal === 3 || this.typeModal === 4 || this.typeModal === 6
      );
    },
    isInModuleConnection() {
      return this.$route.matched[0].meta.module == 20;
    },
    clientAccountId() {
      if (
        this.typeModal === 3 ||
        this.typeModal === 4 ||
        this.typeModal === 5
      ) {
        return this.salesClient.account_id;
      }
      if (this.isInModuleConnection) {
        return this.salesClient.account_id;
      }
      return null;
    },

    newStartIndex() {
      if (this.step1 == "1" && this.step2 == "0") {
        return 0;
      }

      return 1;
    },

    notRequiredPassword() {
      return [1, 2].includes(this.currentUser.role_id);
    },
  },
  async mounted() {
    if (this.$route.meta.requiredNewAppr) {
      this.activeSocketReloadRequestStatus();
    }
  },
  beforeDestroy() {
    try {
      window.socket.unbind("permission-attended-notification-debtsolution");
    } catch (error) {
      console.log(error);
    }
  },
  async created() {
    this.addPreloader();
    if (!this.salesClient.account_id)
      this.salesClient.account_id = this.salesClient.client_account_id;
    await this.leyendDebtsolution();
    this.showNextButton();
    this.ownShow = true;
    this.removePreloader();
  },
  methods: {
    async getCreditsDS() {
      await this.$refs.secondStep.allDebtSolution();
    },
    validatePassword(value) {
      if (this.notRequiredPassword) {
        this.passwordIsCorrect = true;
      } else {
        this.passwordIsCorrect = value;
      }
    },
    getStateCreditor(state) {
      this.state1 = state;
    },
    isLastStep() {
      if (this.$refs.wizard) return this.$refs.wizard.isLastStep;
      return false;
    },
    hideModal(refresh, programSelect) {
      this.$emit("closeModal", refresh, programSelect);
    },
    changeProgram(headerS) {
      this.$emit("changeProgram", headerS);
    },
    activeSocketReloadRequestStatus() {
      try {
        window.socket.subscribe("debtsolution-notifications");
        window.socket.bind(
          "permission-attended-notification-debtsolution",
          async (response) => {
            if (response.user_receiver == this.currentUser.user_id) {
              this.verifyStatusKey++;
            }
          }
        );
      } catch (error) {
        console.log("Socket-settlement", error);
      }
    },
    validationFirstStep() {
      return new Promise((resolve, reject) => {
        if (
          (this.isModalShow && !this.hasInitialPaymentAndDS) ||
          this.directRoles
        )
          resolve(true);
        else if (
          !this.passwordIsCorrect &&
          this.isFirstStep &&
          this.state1 == 0 &&
          !this.requiredNewAppr
        ) {
          const creditorsLength = this.$refs.firstStep.getCreditorsLength();
          const needsValidatePassword =
            this.$refs.firstStep.needsValidatePassword();
          const message = `${
            creditorsLength > 0 ? "" : "Please add creditor"
          } ${
            !needsValidatePassword && creditorsLength > 0
              ? "Please validate password"
              : ""
          }`;
          this.showToast(
            "danger",
            "top-right",
            "Validate error",
            "XIcon",
            message
          );
          reject();
        } else if (
          this.requiredNewAppr &&
          this.state1 == 0 &&
          this.wasApprovedCheif.status !== "APPROVED" &&
          !this.notRequiredPassword
        ) {
          if (!this.wasApprovedCheif.exists) {
            this.showModalAppro = true;
          } else {
            this.showToast(
              "danger",
              "top-right",
              "Validate error",
              "XIcon",
              `Permission Action ${
                this.wasApprovedCheif.status === "REJECTED"
                  ? "Was Rejected"
                  : "In Progress"
              }`
            );
          }
          reject();
        } else {
          this.$refs.firstStep
            .nextfirst(this.idleyend, 1)
            .then((value) => {
              if (value) {
                this.key2 += 1;
                resolve(true);
              }
              reject();
            })
            .catch((error) => {
              console.error(error);
              reject();
            });
        }
      });
    },
    validationSecondStep() {
      return new Promise((resolve, reject) => {
        this.$refs.secondStep
          .nextfirst(this.idleyend, 2)
          .then((value) => {
            if (value) {
              this.key3 += 1;
              resolve(true);
            }
            reject();
          })
          .catch((error) => {
            console.error(error);
            reject();
          });
      });
    },
    async formSubmitted() {
      await this.$refs.thirdStep.nextfirst(this.idleyend, 3);
    },
    async leyendDebtsolution(indexSecondStep = null) {
      if (indexSecondStep == 2) {
        await this.updateSecondStepIndex(this.salesClient.event_id);
      } else if (indexSecondStep == 3) {
        this.state3 = 1;
      }

      try {
        const response = await amgApi.post(
          "/sales-made/debt-solution/get-leyend-debt-solution",
          {
            event:
              this.typeModal === 3 ||
              this.typeModal === 4 ||
              this.typeModal === 5
                ? null
                : this.salesClient.event_id,
            account: this.clientAccountId,
          }
        );
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.leyend = response.data;
            this.idleyend = response.data[0].id;
            this.step1 = response.data[0].step1;
            this.step2 = response.data[0].step2;
            this.step3 = response.data[0].step3;
            this.removePreloader();
          }
        }
        if (this.startIndex === -1) {
          if (this.step1 === 0 && this.step2 === 0 && this.step3 === 0)
            this.startIndex = 0;
          if (this.step1 === 1) this.startIndex = 1;
          if (this.step2 === 1) this.startIndex = 2;
        }
      } catch (error) {
        console.error(error);
      }
    },

    showNextButton() {
      if (
        this.salesClient.program_id === 4 &&
        ((this.salesClient.type === 0 &&
          this.salesClient.ds_analysis_options_id) ||
          this.salesClient.type != 0)
      ) {
        this.buttonSubmitVisible = this.salesClient.haveQuestionnaire === "0";
        // return;
      } else {
        this.buttonSubmitVisible = !(this.salesClient.haveRates === 1);
      }

      if (this.salesClient.status == 8) {
        this.buttonSubmitVisible = true;
      }
    },

    async updateSecondStepIndex(event_id) {
      try {
        const response = await amgApi.post(
          "/sales-made/debt-solution/update-leyend-index",
          {
            event: event_id,
          }
        );
      } catch (e) {
        this.showErrorSwal(e);
      }
    },

    refreshVerifyStatusRequest() {
      this.$refs.verifyStatusRequest.getVerifyStatusRequest();
      this.showModalAppro = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.wizard-tab-content {
  overflow: scroll;
  max-height: 45vh !important;
}
.wizard-card-footer {
  padding-top: 15px !important;
}

@media (min-height: 800px) {
  .wizard-tab-content {
    overflow: scroll;
    max-height: 60vh !important;
  }
}
</style>
