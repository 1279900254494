import callRoundNavigation from "@/views/ce-digital/sub-modules/customer-service/views/call-round/navigation/callround.navigation";
import dashboardAgent from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-agent/dashboard.nav";
import dashboardSuper from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-super/dashboard.nav";
import clientsGridNavigation from "@/views/ce-digital/sub-modules/customer-service/views/clients/clients-group.navigation";
import applicationsNavigation from "@/views/ce-digital/sub-modules/customer-service/views/applications/navigation/applications.navigation";
import dashboardTrackingNav from "@/views/ce-digital/sub-modules/customer-service/views/dashboard-tracking/dashboard-tracking.nav";
import chatAppNav from "@/views/ce-digital/sub-modules/customer-service/views/chat-app/navigation/chat.navigation";
import chatRevisionNav from "@/views/ce-digital/sub-modules/customer-service/views/chat-revision/revision.nav";
import FileAppNav from "@/views/ce-digital/sub-modules/customer-service/views/file-app/navigation/file-app.navigation";
import NotificationAppNav from "@/views/ce-digital/sub-modules/customer-service/views/notification-app/navigation/notification-app.navigation";
import alertTrackingNav from "@/views/ce-digital/sub-modules/customer-service/views/alert-tracking/alert-tracking.nav";
import AgentAwayNav from "@/views/ce-digital/sub-modules/customer-service/views/agent-away/agent-away.nav";
import UserCommissionNav from "@/views/ce-digital/sub-modules/customer-service/views/user-commission/user-commission.nav";
import LeadsNav from "@/views/ce-digital/sub-modules/customer-service/views/leads/leads.nav";
import HelpDeskNav from "@/views/ce-digital/sub-modules/customer-service/views/helpdesk/helpdesk.nav";
import DisputeResultsNav from "@/views/ce-digital/sub-modules/customer-service/views/dispute-results/navigation/dispute-results.nav";
import CommissionNav from "@/views/ce-digital/sub-modules/customer-service/views/commissions/commissions.nav";
import DisputeNav from "@/views/ce-digital/sub-modules/customer-service/views/dispute/navigation/dispute.nav";
import ReportNav from "@/views/ce-digital/sub-modules/customer-service/views/reports/reports.navigation";
import RequestNav from "@/views/ce-digital/sub-modules/customer-service/views/request/request.nav.js";
import ClaimsNav from "@/views/ce-digital/sub-modules/customer-service/views/claims/claims.nav.js";
import EmployeeClaimsNavigation from "@/views/ce-digital/sub-modules/customer-service/views/employee-claims/navigation/employee-claims.nav";
import RingCentralNavigation from "@/views/ce-digital/sub-modules/customer-service/views/ring-central/ring-central.nav.js";
import MOFNavigation from "@/views/ce-digital/sub-modules/customer-service/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/ce-digital/sub-modules/customer-service/views/recruitment-process/customer-service-recruitment_process.navigation";
import RequestNcrNav from "@/views/ce-digital/sub-modules/customer-service/views/request-ncr/request-ncr.nav";
import CourtInfoNav from "@/views/ce-digital/sub-modules/customer-service/views/cour-info/navigation/ds-court-info.nav";

import RequestWorkplanNav from "@/views/ce-digital/sub-modules/customer-service/views/request-workplan/request-workplan.nav.js";
import SendingReportNav from "@/views/ce-digital/sub-modules/customer-service/views/sending-report/sending-report.nav.js";
import AppointmentNav from "@/views/ce-digital/sub-modules/customer-service/views/appointment/appointment.navigation";
import PotencialAppointmentNav from "@/views/ce-digital/sub-modules/customer-service/views/potencial-appointment/potencial.nav";
import OtherServicesNav from "@/views/ce-digital/sub-modules/customer-service/views/other-services/other-services.navigation";
import CommissionForSpecialistsNavigation from "@/views/ce-digital/sub-modules/customer-service/views/commission-specialists/commissions-specialists.navigation";

// data
import sidebarItems from "@/views/credit-experts/data/sidebarItems";
import { sumTag } from "@/@core/utils/utils";

import RequestClientStatusNav from "@/views/ce-digital/sub-modules/customer-service/views/request-client-status/navigation/request-client-status.nav";

const navigation = [
  {
    header: "CUSTOMER SERVICE DIGITAL",
    module: 22,
    modules: sidebarItems,
  },
  // dashboardAgent,
  dashboardSuper,
  clientsGridNavigation,
  LeadsNav,
  ClaimsNav,
  ...callRoundNavigation,
  RequestNcrNav,
  RequestWorkplanNav,
  DisputeNav,
  ...dashboardTrackingNav,
  {
    title: "Services",
    icon: "CreditCardIcon",
    tag: sumTag([
      ...AppointmentNav,
      applicationsNavigation,
      ...OtherServicesNav,
      CommissionForSpecialistsNavigation,
      // ...PotencialAppointmentNav
    ]),
    children: [
      ...AppointmentNav,
      applicationsNavigation,
      ...OtherServicesNav,
      CommissionForSpecialistsNavigation,
      // ...PotencialAppointmentNav
    ],
  },
  FileAppNav,
  NotificationAppNav,
  chatAppNav,
  chatRevisionNav,
  alertTrackingNav,
  UserCommissionNav,
  AgentAwayNav,
  HelpDeskNav,
  ...DisputeResultsNav,
  // CommissionNav,
  EmployeeClaimsNavigation,
  ReportNav,
  RequestNav,
  ...CourtInfoNav,
  RingCentralNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  ...RequestClientStatusNav,
  ...SendingReportNav,
];
export default navigation;
