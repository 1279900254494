import store from '@/store';
import Vue from "vue";
import router from "@/router";

const NotificationDepartamentChangeStatusDispute = () => {
    window.socket.bind("charge-back-in-dispute", async ({ title, text, client_name, client_account, module_send, send_to_rol }) => {
        let temps = false
        let role_id = store.getters['auth/roleId'];
        let current_module = store.getters['auth/moduleId']; //module actual
        let session_id = store.state.auth.currentUser.user_id

        //get id of the modules to which the user belongs
        let get_modules_of_user = store.getters['auth/currentUser'].arrModuls;
        let state_user_in_module = get_modules_of_user.map(elemento => elemento.id_module).includes(module_send); //true - false

        //roles
        send_to_rol.forEach((element) => {
            if (element === role_id) temps = true
        })
        if (temps && state_user_in_module && current_module !== undefined) {
            const res = await Vue.swal.fire({
                icon: "success",
                title: title,
                text: text,
                showConfirmButton: true,
            });

            if (res.value) {
                await window.amgApi.post("/commons/close-all-swal", {
                    to_id: session_id,
                })
            }

        }
    });
}

const DepartamentNotificationNewChargeback = () => {
    window.socket.bind("departament-notifications-new-chargeback", async ({ title, text, module_send, send_to_rol }) => {

        let temps = false
        let roles_access = false
        let role_id = store.getters['auth/roleId']; //rol actual
        let current_module = store.getters['auth/moduleId']; //module actual
        let session_id = store.state.auth.currentUser.user_id
        //get id of the modules to which the user belongs
        let get_modules_of_user = store.getters['auth/currentUser'].arrModuls.map(elemento => elemento.id_module);
        //let state_user_in_module = get_modules_of_user.map(elemento => elemento.id_module).includes(module_send); //true - false

        module_send.forEach((element) => {
            let module_acceso = get_modules_of_user.includes(parseInt(element));
            if (module_acceso) {
                temps = true;
            }
        });

        //roles
        send_to_rol.forEach((element) => {
            if (element === role_id) roles_access = true
        })

        if (temps && current_module !== undefined && roles_access) {
            const res = await Vue.swal.fire({
                icon: "success",
                title: title,
                text: text,
                showConfirmButton: true,
            });

            if (res.value) {
                await window.amgApi.post("/commons/close-all-swal", {
                    to_id: session_id,
                })
            }

        }
    });
}

const DepartamentNotificationChargebackFileEvidenceStatus = () => {
    window.socket.bind("charge-back-in-dispute-status", async ({ title, text, module_send, send_to_rol }) => {

        let temps = false
        let roles_access = false
        let role_id = store.getters['auth/roleId']; //rol actual
        let current_module = store.getters['auth/moduleId']; //module actual
        let session_id = store.state.auth.currentUser.user_id
        //get id of the modules to which the user belongs
        let get_modules_of_user = store.getters['auth/currentUser'].arrModuls.map(elemento => elemento.id_module);
        //let state_user_in_module = get_modules_of_user.map(elemento => elemento.id_module).includes(module_send); //true - false
        
        module_send.forEach((element) => {
            let module_acceso = get_modules_of_user.includes(parseInt(element));
            if (module_acceso) {
                temps = true;
            }
        });

        //roles
        send_to_rol.forEach((element) => {
            if (element === role_id) roles_access = true
        })

        if (temps && current_module !== undefined && roles_access) {
            const res = await Vue.swal.fire({
                icon: "success",
                title: title,
                text: text,
                showConfirmButton: true,
            });

            if (res.value) {
                await window.amgApi.post("/commons/close-all-swal", {
                    to_id: session_id,
                })
            }

        }
    });
}


const DepartamentOfDisputesentResponse = () => {
    window.socket.bind("departament-of-dispute-sent-response", async ({ title, text, module_send, send_to_rol }) => {
        
        let temps = false
        let roles_access = false
        let role_id = store.getters['auth/roleId']; //rol actual
        let current_module = store.getters['auth/moduleId']; //module actual
        let session_id = store.state.auth.currentUser.user_id
        //get id of the modules to which the user belongs
        let get_modules_of_user = store.getters['auth/currentUser'].arrModuls.map(elemento => elemento.id_module);
        //let state_user_in_module = get_modules_of_user.map(elemento => elemento.id_module).includes(module_send); //true - false
        
        module_send.forEach((element) => {
            let module_acceso = get_modules_of_user.includes(parseInt(element));
            if (module_acceso) {
                temps = true;
            }
        });

        //roles
        send_to_rol.forEach((element) => {
            if (element === role_id) roles_access = true
        })

        if (temps && current_module !== undefined && roles_access) {
            const res = await Vue.swal.fire({
                icon: "success",
                title: title,
                text: text,
                showConfirmButton: true,
            });

            if (res.value) {
                await window.amgApi.post("/commons/close-all-swal", {
                    to_id: session_id,
                })
            }

        }
    });
}


const DepartamentOfPaymentValidation = () => {
    window.socket.bind("departament-of-payment-validation", async ({ title, text, module_send, send_to_rol }) => {
        
        let temps = false
        let roles_access = false
        let role_id = store.getters['auth/roleId']; //rol actual
        let current_module = store.getters['auth/moduleId']; //module actual
        let session_id = store.state.auth.currentUser.user_id
        //get id of the modules to which the user belongs
        let get_modules_of_user = store.getters['auth/currentUser'].arrModuls.map(elemento => elemento.id_module);
        //let state_user_in_module = get_modules_of_user.map(elemento => elemento.id_module).includes(module_send); //true - false
        
        module_send.forEach((element) => {
            let module_acceso = get_modules_of_user.includes(parseInt(element));
            if (module_acceso) {
                temps = true;
            }
        });

        //roles
        send_to_rol.forEach((element) => {
            if (element === role_id) roles_access = true
        })

        if (temps && current_module !== undefined && roles_access) {
            const res = await Vue.swal.fire({
                icon: "success",
                title: title,
                text: text,
                showConfirmButton: true,
            });

            if (res.value) {
                await window.amgApi.post("/commons/close-all-swal", {
                    to_id: session_id,
                })
            }

        }
    });
}

const NewClaimNotification = () => {
    window.socket.bind("new-claim-employe", async (response) => {
        const { id_client, module_to_id , client_name, title, description } = response;
        const user_id = store.state.auth.currentUser.user_id;
        const module_id = store.state.auth.currentUser.modul_id;
        
        const params={
            idModule : module_to_id,
            idUserClaim: id_client,
            status: !store.state["global-store"].S_DATA_UPDATE_CLAIM.status,
        }
        let view_alert = false;
         if (module_id == module_to_id) {
            if(id_client != user_id){
                view_alert = true;

                // Update counter SidebarStore Staff Claims
                const position = store.state.SidebarStore.S_SIDEBAR_ITEMS.findIndex(obj => obj.title === "Staff Claims");
                store.state.SidebarStore.S_SIDEBAR_ITEMS[position].tag = store.state.SidebarStore.S_SIDEBAR_ITEMS[position].tag + 1;
                store.state.EmployeeClaimsStore.S_COUNTER_EMPLOYEE_CLAIMS.counterModule = store.state.EmployeeClaimsStore.S_COUNTER_EMPLOYEE_CLAIMS.counterModule + 1;

                const iconMap = {
                    3: "BookOpenIcon",
                    4: "CheckCircleIcon",
                    5: "SlashIcon",
                  };
            
                const icon = iconMap[parseInt(status)] || "BookOpenIcon";
                
                Vue.prototype.$amgAlert({
                    title: 'Staff Claims',
                    subtitle: title,
                    data: [
                        { icon: 'UserIcon', title: 'EMPLOYEE', description: client_name },
                        { icon: 'ListIcon', title: 'DESCRIPTION', description: description },
                    ],
                

                    okTitle: 'Go to Staff Claims',
                    icon,
                    ok: () => {
                    const goSection = module_to_id == 19 ? "list-claims-pending-logistic" : "list-claims-pending-rrhh";
                        if (router.currentRoute.name !== goSection) {                    
                            router.push({ name: goSection });
                        }
                    },
                });
            }
            
         }
        store.dispatch("global-store/A_DATA_UPDATE_CLAIM", params);        
    });
}



export {DepartamentNotificationNewChargeback, 
    NotificationDepartamentChangeStatusDispute, 
    DepartamentNotificationChargebackFileEvidenceStatus, 
    DepartamentOfDisputesentResponse,
    DepartamentOfPaymentValidation,
    NewClaimNotification
};