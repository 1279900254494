export default [
  {
    path: "schedules",
    name: "schedules-bussiness",
    redirect: { name: "report-schedules-bussiness" },
    component: () => import(/* webpackChunkName: "BusinessSchedules" */ "@/views/commons/components/schedules/SchedulesComponent.vue"),
    children: [
      {
        path: "report",
        name: "report-schedules-bussiness",
        component: () => import(/* webpackChunkName: "BusinessReportSchedules" */ "@/views/commons/components/schedules/SchedulesReport.vue"),
        meta: {
          route: "bussiness",
          permittedRoles: [1, 2, 3],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Reports By Module",
              active: true,
            },
          ],
        },
      },
      {
        path: "report-by-user",
        name: "report-by-user-bussiness",
        component: () => (/* webpackChunkName: "BusinessReportByUser" */ "@/views/commons/components/schedules/SchedulesReportByUser.vue"),
        meta: {
          route: "bussiness",
          permittedRoles: [1, 2, 3],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Report by User",
              active: true,
            },
          ],
        },
      },
      {
        path: "overtime",
        name: "overtime-bussiness",
        component: () => import(/* webpackChunkName: "BusinessOvertime" */ "@/views/commons/components/schedules/SchedulesOvertime.vue"),
        meta: {
          route: "bussiness",
          permittedRoles: [1, 2, 3],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Overtime",
              active: true,
            },
          ],
        },
      },
      {
        path: "justifications",
        name: "justifications-bussiness",
        component: () => import(/* webpackChunkName: "BusinessJustifications" */ "@/views/commons/components/schedules/SchedulesJustifications.vue"),
        meta: {
          route: "bussiness",
          permittedRoles: [1, 2, 3],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Justifications",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      route: "bussiness",
      permittedRoles: [1, 2, 3],
    },
  },
];
