<template>
  <div>
    <div class="d-flex justify-content-end" v-if="fromDsQuestionnaire">
      <div class="d-flex">
        <feather-icon
          icon="ListIcon"
          title="INFO"
          size="24"
          class="cursor-pointer text-primary mr-2 align-self-center"
          v-b-tooltip.hover.left="'Tracking'"
          @click="statusTracking = true"
        />
      </div>
      <div>
        <div class="d-flex justify-content-center">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            boundary="viewport"
            v-b-tooltip.hover.left="'Download questionnaire'"
          >
            <template #button-content>
              <b-img
                width="24"
                height="24"
                :src="assetsImg + '/images/icons/pdf.png'"
                class="cursor-pointer align-self-center"
              />
            </template>
            <!-- ES -->
            <b-dropdown-item
              v-b-tooltip.hover.left="'Questionnaire ES'"
              variant="success"
            >
              <div @click="questionnnairePdf('es')">
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">ES</span>
              </div>
            </b-dropdown-item>
            <!-- EN -->
            <b-dropdown-item v-b-tooltip.hover.left="'Questionnaire EN'">
              <div @click="questionnnairePdf('en')">
                <feather-icon icon="DownloadIcon" />
                <span class="align-middle ml-50">EN</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <ValidationObserver ref="formThirdStep">
      <b-container id="item3" fluid>
        <b-row class="mb-1">
          <b-col md="6">
            <b-card
              header-text-variant="white"
              header-bg-variant="info"
              header-class="p-0"
              body-class="pt-1 card-body-border"
            >
              <template #header>
                <div style="padding-top: 12px; padding-left: 20px">
                  <p class="font-weight-bolder">Applicant personal data</p>
                </div>
              </template>

              <b-row>
                <b-col>
                  <div>
                    <label for>First Name</label>
                    <b-form-input
                      v-model="applicantObject.firstName"
                      :disabled="readOnly"
                    />
                  </div>
                  <div class="mt-1">
                    <label for>Last Name</label>
                    <b-form-input
                      v-model="applicantObject.lastName"
                      :disabled="readOnly"
                    />
                  </div>
                  <div class="mt-1">
                    <label for>DOB</label>
                    <b-form-datepicker
                      id="date4"
                      v-model="applicantObject.dob"
                      locale="en"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      name="date4"
                      :disabled="readOnly"
                    />
                  </div>
                </b-col>
                <b-col>
                  <div>
                    <label for>Middle Name</label>
                    <b-form-input
                      v-model="applicantObject.middleName"
                      :disabled="readOnly"
                    />
                  </div>
                  <div class="mt-1">
                    <label for>Status</label>
                    <v-select
                      v-model="applicantObject.status"
                      :disabled="readOnly"
                      :options="states_leads"
                      label="name"
                      :reduce="(value) => value.id"
                    />
                  </div>
                  <div class="mt-1">
                    <label for>SSN</label>
                    <b-form-input
                      id="SSN"
                      v-model="applicantObject.ssn"
                      v-mask="'###-##-####'"
                      name="ssn"
                      :disabled="readOnly"
                      maxlength="11"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card
              header-text-variant="white"
              header-bg-variant="info"
              header-class="p-0"
              body-class="pt-1 card-body-border"
            >
              <template #header>
                <div style="padding-top: 12px; padding-left: 20px">
                  <p class="font-weight-bolder">Contact Data</p>
                </div>
              </template>
              <b-row>
                <b-col>
                  <div>
                    <label for>Phone M</label>
                    <b-form-input
                      id="phone-m"
                      v-model="applicantObject.phoneM"
                      v-mask="'(###) ###-####'"
                      name="phone-m"
                      maxlength="14"
                      :disabled="readOnly"
                    />
                  </div>
                  <div class="mt-1">
                    <label for>Address</label>
                    <vue-google-autocomplete
                      id="address_principal"
                      ref="addressprincipal"
                      v-model="applicantObject.address"
                      class="form-control"
                      placeholder="Please type your address"
                      country="us"
                      :disabled="readOnly"
                      @placechanged="getAddressData"
                    />
                  </div>
                  <div class="mt-1">
                    <label for>State</label>
                    <v-select
                      id="state"
                      v-model="applicantObject.state"
                      name="state"
                      :disabled="readOnly"
                      :options="states"
                      label="state"
                      :reduce="(value) => value.slug"
                    />
                  </div>
                </b-col>
                <b-col>
                  <div>
                    <label for>Email</label>
                    <b-form-input
                      v-model="applicantObject.email"
                      :disabled="readOnly"
                    />
                  </div>
                  <div class="mt-1">
                    <label for>City</label>
                    <b-form-input
                      v-model="applicantObject.city"
                      :disabled="readOnly"
                    />
                  </div>
                  <div class="mt-1">
                    <label for>Zipcode</label>
                    <b-form-input
                      v-model="applicantObject.zipcode"
                      :disabled="readOnly"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-form-checkbox
            id="goapplication"
            v-model="applicant"
            :disabled="readOnly"
            class="ml-1"
            >Co applicant</b-form-checkbox
          >
        </b-row>
        <b-row v-if="applicant === true" class="mt-1">
          <b-col class="cont-user-info">
            <b-card
              header-text-variant="white"
              header-bg-variant="info"
              header-class="p-0"
              body-class="pt-1 card-body-border"
            >
              <template #header>
                <div style="padding-top: 12px; padding-left: 20px">
                  <p class="font-weight-bolder">Co-Applicant personal data</p>
                </div>
              </template>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col lg="3">
                      <label for>First Name</label>
                      <b-form-input
                        v-model="coApplicantObject.firstName"
                        type="text"
                        :disabled="readOnly"
                      />
                    </b-col>
                    <b-col lg="3">
                      <label for>Middle Name</label>
                      <b-form-input
                        v-model="coApplicantObject.middleName"
                        :disabled="readOnly"
                      />
                    </b-col>
                    <b-col lg="3">
                      <label for>Last Name</label>
                      <b-form-input
                        v-model="coApplicantObject.lastName"
                        :disabled="readOnly"
                      />
                    </b-col>
                    <b-col lg="3">
                      <label for>Status</label>
                      <v-select
                        v-model="coApplicantObject.status"
                        :options="states_leads"
                        :reduce="(value) => value.id"
                        :disabled="readOnly"
                        label="name"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col lg="3">
                      <label for>Date of birth</label>
                      <b-form-datepicker
                        id="date5"
                        v-model="coApplicantObject.dob"
                        locale="en"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        name="date5"
                        :disabled="readOnly"
                      />
                    </b-col>
                    <b-col lg="3">
                      <label for>SSN</label>
                      <b-form-input
                        id="SSN2"
                        v-model="coApplicantObject.ssn"
                        v-mask="'###-##-####'"
                        name="ssn2"
                        :disabled="readOnly"
                        maxlength="11"
                      />
                    </b-col>
                    <b-col lg="3">
                      <label v-if="applicant == true" for>Phone number</label>
                      <b-form-input
                        v-if="applicant == true"
                        id="work1"
                        v-model="coApplicantObject.phoneM"
                        v-mask="'(###) ###-####'"
                        name="work1"
                        maxlength="14"
                        :disabled="readOnly"
                      />
                    </b-col>
                    <b-col lg="3">
                      <label v-if="applicant == true" for>Email</label>
                      <b-form-input
                        v-if="applicant == true"
                        id="coApplicantEmail"
                        v-model="coApplicantObject.coApplicantEmail"
                        name="coApplicantEmail"
                        type="email"
                        :disabled="readOnly"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <b-card
              header-text-variant="white"
              header-bg-variant="info"
              header-class="p-0"
              body-class="pt-1 card-body-border"
            >
              <template #header>
                <div style="padding-top: 12px; padding-left: 20px">
                  <p class="font-weight-bolder">Aditional Information</p>
                </div>
              </template>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col md="3">
                      <label for>Civil Status</label>
                      <v-select
                        v-model="applicantObject.civilStatus"
                        :disabled="readOnly"
                        :options="[
                          'SINGLE',
                          'ENGAGED',
                          'MARRIED',
                          'DIVORCED',
                          'WIDOW/ER',
                        ]"
                      />
                    </b-col>
                    <b-col md="3">
                      <!-- lgmd -->
                      <!-- APPLICANTS EMPLOYEE V-SELECT -->
                      <label for>Applicant's Employer</label>
                      <v-select
                        id="employer1"
                        v-model="applicantObject.applicantEmployer"
                        :options="optionsVSelect.optionsApplicantEmployer"
                        :reduce="(opts) => opts.label"
                        :disabled="readOnly"
                      />
                    </b-col>
                    <b-col md="3">
                      <label for>Dependents</label>
                      <v-select
                        id="dependents"
                        v-model="applicantObject.dependents"
                        :options="optionsVSelect.optionsApplicantDepentends"
                        :reduce="(opts) => opts.label"
                        :disabled="readOnly"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col md="3" v-if="visibleIfIsEmployer">
                      <b-button variant="primary" @click="addEmployer()">
                        Add employer
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row v-for="(item, index) in employes" :key="index">
                    <b-col class="mt-1" md="3" v-if="visibleIfIsEmployer">
                      <label for>Job</label>
                      <b-form-input
                        :disabled="readOnly"
                        type="text"
                        v-model="item.job"
                      />
                    </b-col>

                    <b-col class="mt-1" md="2" v-if="visibleIfIsEmployer">
                      <div>
                        <feather-icon
                          v-if="item.remunerated != 'MONTHLY'"
                          class="float-right text-danger mr-1 cursor-pointer"
                          @click="deleteEmployer(item.id)"
                          icon="XSquareIcon"
                        />
                      </div>
                      <label>Remunerated</label>
                      <v-select
                        ref="remunerated"
                        v-model="item.remunerated"
                        :options="optionsVSelect.optionsApplicantRemunerated"
                        :reduce="(opts) => opts.label"
                        @input="clearInputsEmployee(item)"
                        :disabled="readOnly"
                      />
                    </b-col>

                    <b-col class="mt-1" md="2" v-if="item.remunerated">
                      <label for>Type Payment</label>
                      <v-select
                        v-model="item.typePaymet"
                        :options="optionsVSelect.optionsApplicantTypeOfPayment"
                        :reduce="(opts) => opts.id"
                        :disabled="readOnly"
                      />
                    </b-col>
                    <b-col class="mt-1" md="2" v-if="item.typePaymet == 4">
                      <label for>Other type of payment</label>
                      <b-form-input
                        :disabled="readOnly"
                        type="text"
                        v-model="item.otherTypePaymet"
                      />
                    </b-col>

                    <b-col
                      class="mt-1"
                      v-if="item.remunerated == 'MONTHLY'"
                      md="2"
                    >
                      <label class="w-100">
                        <div class="clearfix w-100">
                          <span> Payment Date </span>
                          <feather-icon
                            class="float-right text-danger mr-1 cursor-pointer"
                            @click="deleteEmployer(item.id)"
                            icon="XSquareIcon"
                          />
                        </div>
                      </label>
                      <b-form-datepicker
                        v-model="item.paymetDate"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        }"
                        locale="en-US"
                        :format="'MM/dd/yyyy'"
                        :disabled="readOnly"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col md="3">
                      <label v-if="applicant == true" for
                        >Co-applicant Civil Status</label
                      >
                      <v-select
                        v-if="applicant == true"
                        v-model="coApplicantObject.civilStatus"
                        :disabled="readOnly"
                        :options="[
                          'SINGLE',
                          'ENGAGED',
                          'MARRIED',
                          'DIVORCED',
                          'WIDOW/ER',
                        ]"
                      />
                    </b-col>
                    <b-col md="3">
                      <label v-if="applicant == true" for
                        >Co-applicant employer</label
                      >
                      <v-select
                        v-if="applicant == true"
                        id="employer2"
                        v-model="coApplicantObject.coApplicantEmployer"
                        :options="optionsVSelect.optionsCoApplicantEmployee"
                        :reduce="(opts) => opts.label"
                        :disabled="readOnly"
                      />
                    </b-col>
                    <b-col md="3">
                      <label v-if="applicant == true" for
                        >Co-applicant Dependents</label
                      >
                      <v-select
                        id="dependents2"
                        v-if="applicant == true"
                        v-model="coApplicantObject.dependents"
                        :options="optionsVSelect.optionsApplicantDepentends"
                        :reduce="(opts) => opts.label"
                        :disabled="readOnly"
                      />
                    </b-col>

                    <b-col cols="3">
                      <b-col
                        class="d-flex align-items-center justify-content-start"
                      >
                        <span>Availability to give full amounts?</span>
                      </b-col>
                      <b-col>
                        <br />
                      </b-col>
                      <b-col
                        class="d-flex align-items-center justify-content-start"
                      >
                        <div
                          class="d-flex ml-1 align-items-center justify-content-center"
                        >
                          <b-form-checkbox
                            id="fullAmounts"
                            v-model="fullAmounts"
                            name="fullAmounts"
                            :disabled="readOnly"
                            switch
                          />
                          <span v-if="fullAmounts != ''">YES</span>
                          <span v-else>NO</span>
                        </div>
                      </b-col>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card
              header-text-variant="white"
              header-bg-variant="info"
              header-class="p-0"
              body-class="pt-1 card-body-border"
            >
              <template #header>
                <div style="padding-top: 12px; padding-left: 20px">
                  <p class="font-weight-bolder">Incomes</p>
                </div>
              </template>
              <b-row>
                <b-col md="4">
                  <validation-provider
                    rules="validate-amount-zero"
                    v-slot="{ errors }"
                  >
                    <label>Gross Income</label>
                    <money
                      id="app-monthly-net-income"
                      v-model="applicantObject.grossIncome"
                      v-bind="vMoney"
                      :disabled="readOnly"
                      class="form-control"
                      maxlength="12"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col md="4">
                  <validation-provider
                    rules="validate-amount-zero"
                    v-slot="{ errors }"
                  >
                    <label>Tax Deduction</label>
                    <money
                      id="app-monthly-net-income"
                      v-model="applicantObject.taxDeduction"
                      v-bind="vMoney"
                      :disabled="readOnly"
                      class="form-control"
                      maxlength="12"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col md="4">
                  <label>Net Income</label>
                  <money
                    id="app-monthly-net-income"
                    v-model="applicantObject.netIncome"
                    v-bind="vMoney"
                    :disabled="true"
                    class="form-control"
                    maxlength="12"
                  />
                </b-col>

                <b-col md="4" class="mt-1">
                  <validation-provider
                    rules="validate-amount-zero"
                    v-slot="{ errors }"
                  >
                    <label>Additional monthly net income</label>
                    <money
                      id="additiona-m-net-income"
                      v-model="applicantObject.additionalMonthlyNetincome"
                      v-bind="vMoney"
                      :disabled="readOnly"
                      class="form-control"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col md="4" class="pr-0 mt-1">
                  <validation-provider
                    rules="validate-amount-zero"
                    v-slot="{ errors }"
                  >
                    <label>Co-applicant's monthly net income</label>
                    <money
                      id="co-applicant-net"
                      v-model="coApplicantObject.monthlyNetIncome"
                      v-bind="vMoney"
                      :disabled="readOnly || !(applicant == true)"
                      class="form-control"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col class="mt-2" md="5">
                  <b-input-group prepend="Monthly Net Income">
                    <div
                      class="form-control d-flex align-items-center justify-content-center bg-transparent border"
                    >
                      $ {{ totalMonthlyNetIncome | currencyZero }}
                    </div>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card
              header="Housing expenses"
              header-text-variant="white"
              header-bg-variant="info"
              header-class="p-0"
              body-class="pt-1 card-body-border"
            >
              <template #header>
                <div style="padding-top: 12px; padding-left: 20px">
                  <p class="font-weight-bolder">Housing Expenses</p>
                </div>
              </template>
              <b-row>
                <b-col class="d-flex align-items-center justify-content-start">
                  <span>House Owner</span>
                  <div
                    class="d-flex ml-1 align-items-center justify-content-center"
                  >
                    <b-form-checkbox
                      id="housing"
                      v-model="housingExpensesObject.housing"
                      name="housing"
                      :disabled="readOnly"
                      switch
                      @change="changeHouse($event)"
                    />
                    <span v-if="housingExpensesObject.housing">YES</span>
                    <span v-else>NO</span>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col md="3">
                  <b-button
                    variant="primary"
                    :disabled="isResponsibleAccount"
                    @click="addHouse()"
                  >
                    Add House
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mt-1" v-for="(item, index) in houses" :key="index">
                <b-col
                  cols="1"
                  class="d-flex align-items-center justify-content-center"
                  style="margin: 0.5 0 0 0"
                >
                  <span class="p_desing my-2">
                    <feather-icon icon="HomeIcon"
                  /></span>
                </b-col>
                <b-col md="3" v-if="housingExpensesObject.housing">
                  <label for>Housing status</label>
                  <v-select
                    :disabled="readOnly"
                    :options="optionsStatusHouse"
                    :reduce="(opts) => opts.id"
                    label="text"
                    v-model="item.statusHouse"
                    @input="changeHousing(item, index)"
                  />
                </b-col>
                <b-col
                  md="3"
                  v-if="
                    item.statusHouse == 2 ||
                    item.statusHouse == '' ||
                    (item.statusHouse == null && item.cost > 0)
                  "
                >
                  <validation-provider
                    rules="validate-amount-zero"
                    v-slot="{ errors }"
                  >
                    <label for> Cost </label>
                    <money
                      id="item.id"
                      v-model="item.cost"
                      v-bind="vMoney"
                      :disabled="readOnly"
                      class="form-control"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col cols="2">
                  <b-row>
                    <b-col cols="12" class="justify-content-between d-flex">
                      <label :for="'house' + item.id" v-if="countHouse > 1"
                        >Main house</label
                      >
                      <label>
                        <div class="clearfix w-100">
                          <feather-icon
                            class="text-danger cursor-pointer"
                            @click="deleteHouses(item.id)"
                            icon="XSquareIcon"
                          />
                        </div>
                      </label>
                    </b-col>
                    <b-col cols="6" v-if="countHouse > 1">
                      <b-form-checkbox
                        class="mr-3"
                        :disabled="readOnly"
                        :id="'house' + index"
                        v-model="item.mainHouse"
                        switch
                        @change="disabilityAll($event, index)"
                      />
                    </b-col>
                    <b-col cols="6" v-if="countHouse > 1">
                      <span class="d-flex" v-if="item.mainHouse != ''"
                        >YES</span
                      >
                      <span class="d-flex" v-else>NO</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col md="4">
                  <label>Total monthly housing expenses</label>
                  <span> $ {{ allCosts | currency }}</span>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card
              header-text-variant="white"
              header-bg-variant="info"
              header-class="p-0"
              body-class="pt-1 card-body-border"
            >
              <template #header>
                <div style="padding-top: 12px; padding-left: 20px">
                  <p class="font-weight-bolder">Cost of living</p>
                </div>
              </template>
              <b-row>
                <b-col md="8">
                  <v-select
                    v-model="selected"
                    :options="options"
                    label="text"
                    multiple
                    placeholder="Utilities"
                    :disabled="readOnly"
                    :close-on-select="false"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-for="(select, index) in selected"
                  :key="select.id"
                  cols="2"
                  class="mt-1"
                >
                  <div>
                    <validation-provider
                      rules="validate-amount-zero"
                      v-slot="{ errors }"
                    >
                      <label>{{ select.text }}</label>
                      <money
                        :id="`inp-${index}`"
                        v-model="select.value"
                        v-bind="vMoney"
                        :disabled="readOnly"
                        class="form-control"
                      />
                      <span style="color: red">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col class="mt-1" cols="2">
                  <label>Total Utilities</label>
                  <div
                    class="form-control d-flex align-items-center justify-content-start bg-transparent border"
                  >
                    $ {{ totalDato5 | currencyZero }}
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col md="4">
                  <validation-provider
                    rules="validate-amount-zero"
                    v-slot="{ errors }"
                  >
                    <label> Food(expenses in store or eating outside) </label>
                    <money
                      id="dato7"
                      v-model="dato7"
                      v-bind="vMoney"
                      :disabled="readOnly"
                      class="form-control"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col md="4">
                  <validation-provider
                    rules="validate-amount-zero"
                    v-slot="{ errors }"
                  >
                    <label for>Insurance</label>
                    <money
                      id="dato8"
                      v-model="dato8"
                      v-bind="vMoney"
                      :disabled="readOnly"
                      class="form-control"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <b-button :disabled="isResponsibleAccount" variant="success" @click="addCards()">
                    ADD CARDS
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col cols="2" v-for="(item, index) in cards" :key="index">
                  <b-row>
                    <b-col cols="3" style="margin-top: 30px">
                      <span class="p_desing">
                        <feather-icon icon="CreditCardIcon" />
                      </span>
                    </b-col>
                    <b-col cols="9">
                      <feather-icon
                        class="float-right text-danger cursor-pointer"
                        @click="deleteCards(item.id)"
                        icon="XSquareIcon"
                      />
                      <validation-provider
                        rules="validate-amount-zero"
                        v-slot="{ errors }"
                      >
                        <label for>Pay</label>
                        <money
                          id="item.id"
                          v-model="item.pay"
                          v-bind="vMoney"
                          :disabled="readOnly"
                          class="form-control"
                        />
                        <span style="color: red">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <b-button
                    :disabled="cars.length >= 5 || readOnly"
                    variant="primary"
                    @click="addCar()"
                  >
                    CAR PAYMENTS
                    <feather-icon
                      v-if="cars.length < 5 && !readOnly"
                      icon="PlusIcon"
                    />
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-for="(car, index) in cars"
                  :key="car.id"
                  class="mt-1"
                  cols="2"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <label>{{ car.text }}</label>
                    <label v-if="index != 0 && index == cars.length - 1">
                      <feather-icon
                        v-if="!readOnly"
                        icon="XCircleIcon"
                        class="cursor-pointer text-danger font-medium-2"
                        @click="cars.splice(index, 1)"
                      />
                    </label>
                  </div>
                  <validation-provider
                    rules="validate-amount-zero"
                    v-slot="{ errors }"
                  >
                    <money
                      :id="`car-${index}`"
                      v-model="car.value"
                      v-bind="vMoney"
                      :disabled="readOnly"
                      class="form-control"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
                <b-col class="mt-1" cols="2">
                  <validation-provider
                    rules="validate-amount-zero"
                    v-slot="{ errors }"
                  >
                    <label>Gasoline</label>
                    <money
                      id="gasoline"
                      v-model="gasoline"
                      v-bind="vMoney"
                      :disabled="readOnly"
                      class="form-control"
                    />
                    <span style="color: red">{{ errors[0] }}</span>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <v-select
                    v-model="selectedOther"
                    :options="optionsOthers"
                    label="text"
                    multiple
                    :close-on-select="false"
                    placeholder="Others"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-for="(select, index) in selectedOther"
                  :key="select.id"
                  cols="2"
                  class="mt-1"
                >
                  <div>
                    <label>
                      <span v-if="select.id != 11">{{ select.text }}</span>
                      <span v-else>
                        <b-form-input
                          v-model="select.text"
                          type="text"
                          placeholder="Specify..."
                          :disabled="readOnly"
                          size="sm"
                          class="py-0"
                        />
                      </span>
                    </label>
                    <validation-provider
                      rules="validate-amount-zero"
                      v-slot="{ errors }"
                    >
                      <money
                        :id="`inp-${index}`"
                        v-model="select.value"
                        v-bind="vMoney"
                        :disabled="readOnly"
                        class="form-control"
                      />
                      <span style="color: red">{{ errors[0] }}</span>
                    </validation-provider>
                  </div>
                </b-col>
                <b-col class="mt-1" cols="2">
                  <label>Total Others</label>
                  <div
                    class="form-control d-flex align-items-center justify-content-start bg-transparent border"
                  >
                    $ {{ totalOthers.toFixed(2) }}
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col cols="2">
                  <label>Total monthly living cost</label>
                  <div
                    class="form-control d-flex align-items-center justify-content-start bg-transparent border"
                  >
                    $ {{ totalDato7 | currency }}
                  </div>
                </b-col>
                <b-col md="2">
                  <label>Amount available</label>
                  <div
                    :class="[
                      totalMonthlyNetIncome - totalDato7 > 0
                        ? 'text-primary'
                        : 'text-danger',
                    ]"
                    class="form-control d-flex align-items-center justify-content-start bg-transparent border"
                  >
                    $
                    {{
                      (totalMonthlyNetIncome - totalDato7) | currency
                    }}
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card
              ref="cardGoals"
              header-text-variant="white"
              header-bg-variant="info"
              header-class="p-0"
              body-class="pt-1 card-body-border"
            >
              <template #header>
                <div style="padding-top: 12px; padding-left: 20px">
                  <p class="font-weight-bolder">Goals</p>
                </div>
              </template>
              <b-row>
                <b-col>
                  <b-button
                    v-if="arrayGoals.length != 4 && !readOnly"
                    variant="success"
                    @click="addGoals()"
                  >
                    <feather-icon icon="PlusIcon" title="ADD NEW GOAL" />ADD
                    GOAL
                  </b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  v-for="(goal, index) in arrayGoals"
                  :key="goal.id"
                  md="3"
                  class="mt-1"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label :for="`date-label-${index}`">{{ goal.text }}</label>
                    <label v-if="index != 0 && index == arrayGoals.length - 1">
                      <feather-icon
                        v-if="!readOnly"
                        icon="XCircleIcon"
                        class="font-medium-3 text-danger cursor-pointer"
                        @click="arrayGoals.splice(index, 1)"
                      />
                    </label>
                  </div>
                  <b-row>
                    <b-col cols="12">
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required"
                        name="goals"
                      >
                        <!-- the input was replaced by the v-select -->
                        <b-form-group :class="errors[0]">
                          <v-select
                            :id="`date-label-${index}`"
                            v-model="goal.goal"
                            :options="optionsGoals"
                            :reduce="(item) => item.label"
                            :disabled="readOnly"
                            :class="{ 'border-danger': errors[0] }"
                          >
                          </v-select>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <label class="mt-1" :for="`date-goal-${index}`">Date</label>
                    <b-form-datepicker
                      :id="`date-goal-${index}`"
                      v-model="goal.date"
                      locale="en"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      :disabled="readOnly"
                      :class="{ 'border-danger': errors[0] }"
                    />
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </ValidationObserver>
    <ModalTrackingQuestionnaire
      v-if="statusTracking"
      @hidden="statusTracking = false"
      :account="idAnalysisCredits"
      :salesClient="salesClient"
    />
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import VSelectOptions from "@/views/crm/views/clients/components/data/options.modal-questionnaire";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import QuestionnnaireService from "@/views/crm/views/sales-made/components/modals/services/debt-solution/services/questionnnaire-service";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service.js";
extend("required-v-select", {
  ...required,
  message: "is-invalid",
});
import ModalTrackingQuestionnaire from "@/views/crm/views/sales-made/components/modals/services/debt-solution/ModalTrackingQuestionnaire.vue";
export default {
  components: { VueGoogleAutocomplete, vSelect, ModalTrackingQuestionnaire },
  props: {
    salesClient: Object,
    idleyend: String,
    headerS: {
      type: Object,
      default: () => ({ program: "", seller: "", captured: "" }),
    },
    typeModal: {
      type: Number,
      default: 1,
      // 1: create, 2: show
    },
    isModalShow: Boolean,
    isModalAdd: Boolean,
    fromDsQuestionnaire: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      readOnly: false,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      errorGoal: false,
      errorDate: false,
      blocking: true,
      selected: [], // Must be an array reference!
      selectedOther: [],
      fullAmounts: false,
      idAnalysisCredits: "",
      optionsGoals: [
        { id: 1, label: "Negotiate Debts" },
        { id: 2, label: "Improve Credit" },
        { id: 3, label: "Apply for specific credits" },
        { id: 4, label: "Save Money" },
      ],
      options: [
        { id: 1, text: "Electricity", value: 0 },
        { id: 2, text: "Gas", value: 0 },
        { id: 3, text: "Water", value: 0 },
        { id: 4, text: "Telephone", value: 0 },
        { id: 5, text: "Cable", value: 0 },
        { id: 6, text: "Internet", value: 0 },
        { id: 7, text: "Trash", value: 0 },
      ],
      optionsOthers: [
        { id: 1, text: "Child Support", value: 0 },
        { id: 2, text: "Spouse Suppport", value: 0 },
        { id: 3, text: "Clothes", value: 0 },
        { id: 4, text: "Education", value: 0 },
        { id: 5, text: "Health & SelfCare", value: 0 },
        { id: 6, text: "Transportation", value: 0 },
        { id: 7, text: "Laundry", value: 0 },
        { id: 8, text: "Donations", value: 0 },
        { id: 9, text: "Family Support", value: 0 },
        { id: 10, text: "Recreation", value: 0 },
        { id: 11, text: "Others", value: 0 },
      ],
      percentajes: [
        { value: "0.0", label: "0%" },
        { value: "0.01", label: "1%" },
        { value: "0.02", label: "2%" },
        { value: "0.03", label: "3%" },
        { value: "0.04", label: "4%" },
        { value: "0.05", label: "5%" },
        { value: "0.06", label: "6%" },
        { value: "0.07", label: "7%" },
        { value: "0.08", label: "8%" },
        { value: "0.09", label: "9%" },
        { value: "0.1", label: "10%" },
      ],
      optionsStatusHouse: [
        { id: 1, text: "Paid", mainHouse: 0 },
        { id: 2, text: "Paying", mainHouse: 0 },
      ],
      arrayGoals: [{ text: "1st Goal", goal: null, date: null }],
      cars: [{ text: "Car 1", value: 0 }],
      min: new Date(1000, 0, 1, 8, 0, 0),
      max: new Date(3000, 11, 31, 18, 0, 0),
      minDate: new Date(1000, 1, 1),
      maxDate: new Date(3000, 1, 1),
      applicantObject: {
        firstName: "",
        middleName: "",
        lastName: "",
        status: "",
        dob: "",
        ssn: "",
        phoneM: "",
        phoneH: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zipcode: "",
        civilStatus: "",
        applicantEmployer: "",
        dependents: "",
        monthlyNetIncome: 0,
        additionalMonthlyNetincome: 0,
        // new columns
        remunerated: 0,
        payment_date: "",
        type_payment: 0,
        netIncome: 0,
      },
      applicant: false,
      coApplicantObject: {
        firstName: "",
        middleName: "",
        lastName: "",
        status: "",
        dob: "",
        ssn: "",
        phoneM: "",
        civilStatus: "",
        coApplicantEmployer: "",
        dependents: "",
        monthlyNetIncome: 0,
        coApplicantEmail: "",
      },
      dato4: 0,
      dato7: 0,
      dato8: 0,
      gasoline: 0,
      housingExpensesObject: {
        housing: "",
        monthlyPaymentHousingExpenses: 0,
      },
      inputDisable: false,
      states_leads: [],
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
      countEmployer: 1,
      countHouse: 0,
      countCards: 0,
      employes: [
        {
          id: 1,
          job: "",
          remunerated: "",
          typePaymet: "",
          paymetDate: "",
          otherTypePaymet: "",
        },
      ],
      houses: [
        {
          id: 1,
          house: "",
          statusHouse: "",
          cost: 0,
          mainHouse: "",
        },
      ],
      cards: [],
      direccion: null,
      address_principal: null,
      creditors: [],
      id_analisis: "",
      id_history: "",
      states: [],
      applicant: "",
      workphone1: "",
      dato6: "",
      porctrf: "",
      total_balance: "",
      total_ce: null,
      monthly_payment: "",
      cost: "",
      totales: 0,
      total_monthly: "",
      score_id: null,
      optionsVSelect: VSelectOptions,
      statusTracking: false,
      allCostsTotal: 0,
      dsSituation: {},
    };
  },
  computed: {
    allCosts() {
      const sum = this.houses.reduce((accumulator, house) => {
        return (this.housingExpensesObject.monthlyPaymentHousingExpenses =
          accumulator + Number(house.cost + this.allCostsTotal));
      }, 0);
      return sum;
    },
    allPay() {
      const tal = this.cards.reduce((accumulator, card) => {
        return (this.totales = accumulator + Number(card.pay));
      }, 0);
      return tal;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      statusModal: "connectionStore/G_UPDATE_MODAL",
    }),
    totalMonthlyNetIncome() {
      return Number(
        this.applicantObject.netIncome +
        // this.applicantObject.monthlyNetIncome + fix ticket CRM20230020
          this.applicantObject.additionalMonthlyNetincome +
          this.coApplicantObject.monthlyNetIncome
      );
    },
    totalDato5() {
      const total = this.selected.reduce(
        (sum, select) => sum + Number(select.value),
        0
      );
      return total;
    },
    totalDato7() {
      const total = this.cars.reduce((sum, car) => sum + Number(car.value), 0);
      const totalOthers = this.selectedOther.reduce(
        (sum, other) => sum + Number(other.value),
        0
      );
      return Number(
        total +
          totalOthers +
          this.gasoline +
          this.totalDato5 +
          this.dato7 +
          this.dato8 +
          this.allPay +
          this.allCosts
      );
    },
    totalOthers() {
      const totalOthers = this.selectedOther.reduce(
        (sum, other) => sum + Number(other.value),
        0
      );
      return totalOthers;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isInConnectionModule() {
      return this.moduleId == 20;
    },
    // new computed
    visibleIfIsEmployer() {
      if (this.applicantObject.applicantEmployer !== "EMPLOYED") {
        this.applicantObject.remunerated = "";
        this.applicantObject.type_payment = "";
        return false;
      } else {
        return true;
      }
    },
    visibleIfIsMonthly() {
      if (this.applicantObject.remunerated !== "MONTHLY") {
        this.applicantObject.type_payment = null;
        this.applicantObject.payment_date = "";
        return false;
      } else {
        this.applicantObject.type_payment;
        this.applicantObject.payment_date;
        return true;
      }
    },
    isResponsibleAccount() {
      if (this.$route.params.idClient) {
        return !(this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === this.currentUser.user_id || this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === null);
      }
      return false;
    },
  },
  async mounted() {
    await this.statesleads();
    await this.stateseeuu();
    await this.analisisDebsolution();
    await this.allDebtSolution();
    await this.getCurrentFinancialAnalysis();

    this.readOnly = this.isModalShow;
    if (
      this.salesClient.haveQuestionnaire !== "0" &&
      this.salesClient.status !== 8 &&
      !this.fromDsQuestionnaire
    ) {
      this.readOnly = true;
    } else if (this.salesClient.status == 4 && !this.fromDsQuestionnaire) {
      this.readOnly = true;
    } else if (this.$route.params.idClient && this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible) {
      this.readOnly = (!(this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === this.currentUser.user_id || this.$store.getters['NotificationStore/G_ACCOUNT_IS_RESPONSIBLE_CEO'].is_responsible === null) || this.moduleId === 18);
    } else {
      this.readOnly = false;
    }
  },
  methods: {
    changeHousing(item, index) {
      if (item.statusHouse === 1) {
        this.houses[index].cost = 0;
      }
    },
    clearInputsEmployee(param) {
      if (param.remunerated != "MONTHLY" || param.remunerated == null) {
        param.typePaymet = "";
        param.paymetDate = "";
        param.otherTypePaymet = "";
      }
    },
    disabilityAll(evt, index) {
      // Desactivar todos los objetos excepto el seleccionado
      this.houses.forEach((val, idx) => {
        if (idx !== index) {
          val.mainHouse = false;
          val.isActive = false;
        }
      });

      // Actualizar el objeto seleccionado
      const selectedHouse = this.houses.find((val, idx) => idx === index);
      if (selectedHouse) {
        selectedHouse.mainHouse = evt;
        selectedHouse.isActive = evt;
      }

      // Asegurar que no haya más de un objeto activo
      const activeCount = this.houses.reduce(
        (count, val) => count + (val.isActive ? 1 : 0),
        0
      );
      if (activeCount > 1) {
        this.houses.forEach((val, idx) => {
          if (idx !== index) {
            val.isActive = false;
          }
        });
      }
    },
    ...mapActions({
      updateData: "connectionStore/A_UPDATE_MODAL",
    }),
    changeStateModal() {
      this.updateData(true);
    },
    async nextfirst(id, type) {
      if (this.readOnly) {
        this.hideModal(false, this.program);
        return true;
      }
      if (type == 1 || type == 2) {
        return await this.axiosNext(id, type);
      }
      if (type == 4) {
        return await this.saveant(id, type);
      }
      return await this.axiosNext(id, type);
    },
    async axiosNext(id, type) {
      try {
        const swalTitle = this.fromDsQuestionnaire
          ? "Are you sure?"
          : "Are you sure of continue ?";
        const swalText = this.fromDsQuestionnaire
          ? "You won't be able to revert this!"
          : "Before finalizing you must save.";
        const success = await this.$refs.formThirdStep.validate();
        if (success) {
          const result = await this.showConfirmSwal(swalTitle, swalText);
          if (result.value) {
            this.addPreloader();
            const arrayUtilities = [];
            const arrayObjUtilities = [];
            // eslint-disable-next-line array-callback-return
            this.selected.map((select) => {
              const obj = {
                id: select.id,
                mont: select.value,
              };
              arrayUtilities.push(select.id);
              arrayObjUtilities.push(obj);
            });
            const arrayOthersTemp = [];
            const arrayObjOthersTemp = [];
            // eslint-disable-next-line array-callback-return
            this.selectedOther.map((select) => {
              const obj = {
                id: select.id,
                mont: select.value,
              };
              arrayOthersTemp.push(select.id);
              arrayObjOthersTemp.push(obj);
            });
            const response = await amgApi.post(
              "/sales-made/debt-solution/save-first-debt-solution",
              {
                type,
                id,
                event:
                  this.typeModal == 3 || this.typeModal == 4
                    ? null
                    : this.salesClient.event_id,
                account:
                  this.typeModal == 3 ||
                  this.typeModal == 4 ||
                  this.typeModal == 6
                    ? this.salesClient.account_id
                    : null,
                id_history: this.id_history,
                id_analisis: this.idAnalysisCredits,
                //  id_analisis: this.id_analisis,
                name1: this.applicantObject.firstName,
                last1: this.applicantObject.lastName,
                date1: this.applicantObject.dob,
                ssn1: this.applicantObject.ssn,
                applicant: this.applicant,
                name2: this.coApplicantObject.firstName,
                last2: this.coApplicantObject.lastName,
                date2: this.coApplicantObject.dob,
                ssn2: this.coApplicantObject.ssn,
                address: this.applicantObject.address,
                city: this.applicantObject.city,
                state: this.applicantObject.state,
                zipcode: this.applicantObject.zipcode,
                civil: this.applicantObject.civilStatus,
                civil2: this.coApplicantObject.civilStatus,
                dependents: this.applicantObject.dependents,
                dependents2: this.coApplicantObject.dependents,
                phone1: this.applicantObject.phoneM,
                employer1: this.applicantObject.applicantEmployer,
                workphone1: this.workphone1,
                employer2: this.coApplicantObject.coApplicantEmployer,
                workphone2: this.coApplicantObject.phoneM,
                dato1: this.applicantObject.monthlyNetIncome,
                dato2: this.coApplicantObject.monthlyNetIncome,
                dato3: this.applicantObject.additionalMonthlyNetincome,
                dato4: this.housingExpensesObject.monthlyPaymentHousingExpenses,
                dato5: this.totalDato5.toFixed(2),
                dato6: this.dato6,
                dato7: this.dato7,
                dato8: this.dato8,
                dato91: this.cars[0].value,
                dato92: this.cars[1] != undefined ? this.cars[1].value : "",
                dato93: this.cars[2] != undefined ? this.cars[2].value : "",
                dato94: this.cars[3] != undefined ? this.cars[3].value : "",
                dato95: this.cars[4] != undefined ? this.cars[4].value : "",
                dato10: this.gasoline,
                dato11: this.totalOthers,
                dato12: this.arrayGoals[0].goal,
                dato13:
                  this.arrayGoals[1] != undefined
                    ? this.arrayGoals[1].goal
                    : "",
                dato14:
                  this.arrayGoals[2] != undefined
                    ? this.arrayGoals[2].goal
                    : "",
                dato15:
                  this.arrayGoals[3] != undefined
                    ? this.arrayGoals[3].goal
                    : "",
                date3: this.arrayGoals[0].date,
                date4:
                  this.arrayGoals[1] != undefined
                    ? this.arrayGoals[1].date
                    : "",
                date5:
                  this.arrayGoals[2] != undefined
                    ? this.arrayGoals[1].date
                    : "",
                date6:
                  this.arrayGoals[3] != undefined
                    ? this.arrayGoals[1].date
                    : "",
                others: this.optionsOthers[10].text,
                middle1: this.applicantObject.middleName,
                state_lead1: this.applicantObject.status,
                middle2: this.coApplicantObject.middleName,
                state_lead2: this.coApplicantObject.status,
                phone2: this.applicantObject.phoneH,
                email: this.applicantObject.email,
                valorutility: arrayUtilities,
                montoutlity: arrayObjUtilities,
                valorothers: arrayOthersTemp,
                montoothers: arrayObjOthersTemp,
                housing: this.housingExpensesObject.housing == false ? 0 : 1,
                isFromDashboard: this.fromDsQuestionnaire === true ? 1 : null,
                fullAmounts: this.fullAmounts == false ? 0 : 1,
                // new data lgmd
                remunerated: this.applicantObject.remunerated,
                payment_date: this.applicantObject.payment_date,
                type_payment: this.applicantObject.type_payment,
                //new data employes
                created_by: this.currentUser.user_id,
                deleteEmployes: this.deleteEmployes,
                jsonJobsEmployes: this.employes,
                jsonHouses: this.houses,
                jsonCards: this.cards,
                coApplicantEmail: this.coApplicantObject.coApplicantEmail,
                gross_income: this.applicantObject.grossIncome,
                tax_deduction: this.applicantObject.taxDeduction,
                net_income: this.applicantObject.netIncome,
              }
            );
            if (response.status === 200) {
              this.removePreloader();
              this.$emit("saved");
              if (!this.fromDsQuestionnaire) {
                await this.save(4);
              }
              return true;
            }
            return false;
          }
          return false;
        } else {
          this.scrollToDiv(this.$refs.cardGoals);
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async insertTrackingDerivation() {
      if (this.$route.matched[0].meta.module === 26) {
        const params = {
          lead_id: this.salesClient.lead_id,
          sale_status_id: 1,
          seller_id: this.salesClient.seller_id,
          sale_id: this.salesClient.id,
        };
        await DerivationsService.insertTrackingSaleStatusDerivation(params);
      }
    },
    async save(id) {
      try {
        const total = parseFloat(
          Number(this.total_balance) * Number(this.porctrf)
        ).toFixed(2);
        const due = parseFloat(Number(this.total_balance) * 0.8).toFixed(2);
        const months = Math.ceil(
          parseFloat(Number(due) / Number(this.monthly_payment)).toFixed(1)
        );
        const years = Math.ceil(parseFloat(Number(months) / 12).toFixed(1));
        const mont3 = parseFloat(
          (Number(this.total_balance) * Number(this.total_interest)) / 100
        ).toFixed(2);
        const mont4 = parseFloat(Number(mont3) / 12).toFixed(2);
        const mont5 = parseFloat(
          Number(this.total_monthly) - Number(mont4)
        ).toFixed(2);
        const mont9 = Math.ceil(
          parseFloat(Number(this.total_balance) / Number(mont5)).toFixed(1)
        );
        const mont11 = parseFloat(
          Number(mont9) * Number(this.total_monthly)
        ).toFixed(2);
        const estimated = parseFloat(Number(mont11) - Number(due)).toFixed(2);
        //   this.oneclick = false;
        const nev = [];
        let message = "";
        let route = "";
        let typeADD = "";
        //   let prices = [];
        // Depends of the Modal type
        switch (this.typeModal) {
          case 1:
            message = "complete Rates";
            route = "/sales-made/attendend-sale";
            break;
          case 3:
            message = "add new service";
            route = "/commons/attendend-program";
            typeADD = 1;
            break;
          case 2:
            message = "complete Rates";
            route = "/sales-made/attendend-sale";
            break;
          case 4:
            message = "change service";
            route = "/commons/attendend-program";
            typeADD = 2;
            break;
          case 6:
            message = "add new service";
            route = "/sale/insert-lead-attendance";
            break;
          default:
            break;
        }
        const param = {
          prices: nev,
          observation: this.observation,
          contract: 1,
          program: id,
          fee: this.dsSituation.service ? this.dsSituation.service : total,
          event:
            this.typeModal === 3 || this.typeModal === 4 || this.typeModal === 5
              ? null
              : this.salesClient.event_id,
          suggested: this.total_ce == null ? total : this.total_ce,
          otherpricesp: [],
          typepayment: 0,
          mpds: this.monthly_payment,
          total_due: due,
          monthly: this.monthly_payment,
          cost: this.cost,
          retainer: total,
          months,
          years,
          estimated,
          id_history: this.id_history,
          stateid: id == 4 ? 1 : 0,
          porctrf: this.porctrf,
          json_ce: null,
          id_score: this.score_id,
          json_noce: null,
          account:
            this.typeModal === 3 || this.typeModal === 4 || this.typeModal === 5
              ? this.salesClient.account_id
                ? this.salesClient.account_id
                : ""
              : null,
          captured:
            typeADD == 1 || typeADD == 2
              ? this.salesClient.captured.id
                ? this.salesClient.captured.id.toString()
                : this.salesClient.captured.toString()
              : this.headerS.captured.id,
          seller:
            typeADD == 1 || typeADD == 2
              ? this.salesClient.seller.id
                ? this.salesClient.seller.id.toString()
                : this.salesClient.seller.toString()
              : this.headerS.seller.id,
          type: typeADD,
          user_id: this.currentUser.user_id,
          module: this.currentUser.modul_id,
        };
        const result = await this.showConfirmSwal(
          `Are you sure you want to ${message}`
        );
        if (result.value) {
          this.addPreloader();
          const response = await amgApi.post(route, param);
          if (response.status === 200) {
            await this.insertTrackingDerivation();
            if (this.moduleId == 5) {
              if (this.$route.name !== "sales-made-debtsolution-lead") {
                this.$router.push({ name: "sales-made-debtsolution-lead" });
              }
            } else if (this.moduleId == 3) {
              if (this.$route.name !== "bussiness-sales-made") {
                this.$router.push({ name: "bussiness-sales-made" });
              }
            } else if (this.moduleId == 12) {
              if (this.$route.name !== "sales-made-paragon") {
                this.$router.push({ name: "sales-made-paragon" });
              }
            }

            if (
              this.$route.matched[0].meta.module == 20 ||
              this.$route.matched[0].meta.module == 21
            ) {
              this.changeStateModal();
            }
            this.hideModal(true, this.program);
            this.removePreloader();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async stateseeuu() {
      try {
        const response = await amgApi.get("/commons/get-eeuu-states");
        this.states = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async prevfirst(id, type) {
      try {
        this.addPreloader();
        const response = await amgApi.post("/sale/delete-leyend-states-ds", {
          type,
          id,
          event:
            this.typeModal === 3 || this.typeModal === 4 || this.typeModal === 5
              ? null
              : this.salesClient.event_id,
          account:
            this.typeModal === 3 || this.typeModal === 4 || this.typeModal === 5
              ? this.salesClient.account_id
              : null,
        });
        if (response.status === 200) {
          this.$emit("previousStep");
        }
      } catch (error) {
        this.removePreloader();
        console.error(error);
      }
    },
    async allDebtSolution() {
      try {
        const response = await amgApi.post(
          "/sales-made/debt-solution/get-credits-debt-solution",
          {
            event:
              this.typeModal === 3 ||
              this.typeModal === 4 ||
              this.typeModal === 5
                ? null
                : this.salesClient.event_id,
            account:
              this.typeModal === 3 ||
              this.typeModal === 4 ||
              this.typeModal === 5 ||
              this.typeModal === 6
                ? this.salesClient.account_id
                : null,
          }
        );
        if (response.status === 200) {
          this.creditors = response.data;
          if (this.creditors.length > 0) {
            this.total_balance = this.creditors[0].total_balance;
            this.total_interest = this.creditors[0].total_interest;
            this.total_monthly = this.creditors[0].total_monthly;
            this.id_history = this.creditors[0].id_history;
            this.id_analisis = this.creditors[0].id_analisis;
            this.monthly_payment =
              this.creditors[0].monthly_payment == null
                ? ""
                : this.creditors[0].monthly_payment;
            this.porctrf =
              this.creditors[0].pcrf == null
                ? 0.1
                : this.creditors[0].pcrf == "0.10"
                ? 0.1
                : this.creditors[0].pcrf;
            this.cost = this.creditors[0].cost;
          } else {
            this.id_history = "";
            this.id_analisis = "";
          }
          await this.analisisDebsolution(1);
          //   await this.statesleads();
        }
      } catch (error) {
        // console.error(error);
      }
    },
    async statesleads() {
      try {
        const response = await amgApi.get("/lead/get-state-leads");
        this.states_leads = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    addGoals() {
      this.arrayGoals.push({
        text: `${this.ordinalSuffixOf(this.arrayGoals.length + 1)} Goal`,
        goal: null,
        date: null,
      });
    },
    ordinalSuffixOf(i) {
      const j = i % 10;
      const k = i % 100;
      if (j === 1 && k !== 11) {
        return `${i}st`;
      }
      if (j === 2 && k !== 12) {
        return `${i}nd`;
      }
      if (j === 3 && k !== 13) {
        return `${i}rd`;
      }
      return `${i}th`;
    },
    addCar() {
      const index = this.cars.length;
      const car = {
        text: `Car ${index + 1}`,
        value: 0,
      };
      this.cars.push(car);
    },
    hideModal(refresh, programSelect) {
      this.$emit("hideModal", refresh, programSelect);
    },
    getAddressData(address_principal, placeResultData, id) {
      this.direccion = address_principal;
      this.address_principal = `${this.direccion.street_number} ${this.direccion.route}`;
      this.applicantObject.address = this.address_principal;
      this.applicantObject.state = this.direccion.administrative_area_level_1;
      this.applicantObject.city = this.direccion.locality;
      this.applicantObject.zipcode = this.direccion.postal_code;
    },
    async analisisDebsolution(param = 0) {
      try {
        const response = await amgApi.post(
          "/sales-made/debt-solution/get-analysis-debt-solution",
          {
            event:
              this.typeModal === 3 ||
              this.typeModal === 4 ||
              this.typeModal === 5
                ? null
                : this.salesClient.event_id,
            account:
              this.typeModal === 3 ||
              this.typeModal === 4 ||
              this.typeModal === 5 ||
              this.typeModal === 6
                ? this.salesClient.account_id
                : null,
            id: this.id_analisis,
          }
        );
        if (response.status === 200) {
          if (response.data.length > 0) {
            response.data[0].full_amounts == 1
              ? (this.fullAmounts = true)
              : (this.fullAmounts = false);
            this.idAnalysisCredits = response.data[0].id;
            this.id_analisis = response.data[0].id;
            this.applicantObject.firstName = response.data[0].name;
            this.applicantObject.lastName = response.data[0].last;
            this.applicantObject.dob = response.data[0].dob;
            this.applicantObject.ssn = response.data[0].ssn;
            this.applicantObject.taxDeduction = response.data[0].tax_deductions;
            this.applicantObject.grossIncome = response.data[0].gross_income;
            this.coApplicantObject.firstName = response.data[0].c_name;
            this.coApplicantObject.lastName = response.data[0].c_last;
            this.coApplicantObject.dependents = response.data[0].c_dependents;
            this.coApplicantObject.dob = response.data[0].c_dob;
            this.coApplicantObject.ssn = response.data[0].c_ssn;
            this.coApplicantObject.civilStatus =
              response.data[0].c_status_civil;
            this.applicantObject.address = response.data[0].address;
            this.applicantObject.city = response.data[0].city;
            this.applicantObject.state = response.data[0].state;
            this.applicantObject.zipcode = response.data[0].zipcode;
            this.applicantObject.civilStatus = response.data[0].status_civil;
            this.applicantObject.dependents = response.data[0].dependents;
            this.applicantObject.phoneM = response.data[0].phone;
            this.applicantObject.applicantEmployer = response.data[0].employer;
            this.applicantObject.phoneH = response.data[0].phone_work;
            this.applicantObject.netIncome = response.data[0].net_income;
            this.coApplicantObject.coApplicantEmployer =
              response.data[0].c_employer;
            this.coApplicantObject.phoneM = response.data[0].c_phone_work;
            this.applicantObject.monthlyNetIncome = Number(
              response.data[0].applicant_monthly
            );
            this.coApplicantObject.monthlyNetIncome = Number(
              response.data[0].c_applicant_monthly
            );
            this.applicantObject.additionalMonthlyNetincome = Number(
              response.data[0].adicional_monthly
            );
            this.housingExpensesObject.monthlyPaymentHousingExpenses = Number(
              response.data[0].monthly_payment
            );
            this.dato6 = Number(response.data[0].telephone);
            this.dato7 = Number(response.data[0].food);
            this.dato8 = Number(response.data[0].insurance);
            this.workphone1 = response.data[0].phone_work;
            this.coApplicantObject.coApplicantEmail =
              response.data[0].co_applicant_email;

            if (param == 1) {
              if (response.data[0].car_payment) {
                this.cars[0].value = Number(response.data[0].car_payment);
              }
              for (let i = 2; i <= 5; i++) {
                if (response.data[0][`car_payment_${i}`]) {
                  const car = {
                    text: `Car ${i}`,
                    value: Number(response.data[0][`car_payment_${i}`]),
                  };
                  this.cars.push(car);
                }
              }
            }
            this.gasoline = Number(response.data[0].gasoline);

            if (param == 1) {
              for (let i = 1; i <= 4; i++) {
                if (i == 1) {
                  this.arrayGoals[0].goal = response.data[0].goal1;
                  this.arrayGoals[0].date = response.data[0].date_g1;
                } else if (response.data[0][`goal${i}`]) {
                  const goal = {
                    text: `${this.ordinalSuffixOf(i)} Goal`,
                    goal: response.data[0][`goal${i}`],
                    date: response.data[0][`date_g${i}`],
                  };
                  this.arrayGoals.push(goal);
                }
              }
            }

            this.others = response.data[0].others_t;
            this.applicantObject.middleName = response.data[0].middle_name;
            this.coApplicantObject.middleName = response.data[0].c_middle;
            this.applicantObject.status = response.data[0].state_lead;
            this.coApplicantObject.status = response.data[0].c_status_lead;
            this.applicantObject.phoneH = response.data[0].phone2;
            this.applicantObject.email = response.data[0].email;
            // new gets
            this.applicantObject.remunerated = response.data[0].remunerated;
            this.applicantObject.payment_date = response.data[0].payment_date;
            this.applicantObject.type_payment = response.data[0].type_payment;
            let othersObject = [];
            if (param == 1) {
              const utilitiesObjects =
                response.data[0].json_montutility == null
                  ? []
                  : JSON.parse(response.data[0].json_montutility);

              othersObject =
                response.data[0].json_montothers == null
                  ? []
                  : JSON.parse(response.data[0].json_montothers);

              utilitiesObjects.map((utility) => {
                this.options.map((option) => {
                  if (Number(utility.id) == option.id) {
                    option.value = Number(utility.mont);
                    this.selected.push(option);
                  }
                });
              });
            }

            this.countEmployer =
              response.data[0].json_jobs_details == null
                ? []
                : JSON.parse(response.data[0].json_jobs_details).length;
            let listJsonJobsDetails = [];
            listJsonJobsDetails =
              response.data[0].json_jobs_details == null
                ? []
                : JSON.parse(response.data[0].json_jobs_details);

            if (listJsonJobsDetails.length == 0) {
              this.employes = [
                {
                  id: 1,
                  job: "",
                  remunerated: "",
                  typePaymet: "",
                  paymetDate: "",
                  otherTypePaymet: "",
                },
              ];
              this.countEmployer = 1;
            } else {
              this.employes = [];
              for (let i = 0; i < this.countEmployer; i++) {
                this.employes.push({
                  id: listJsonJobsDetails[i].id,
                  job: listJsonJobsDetails[i].job,
                  remunerated: listJsonJobsDetails[i].remunerated,
                  typePaymet: listJsonJobsDetails[i].typePaymet,
                  paymetDate: listJsonJobsDetails[i].paymetDate,
                  otherTypePaymet: listJsonJobsDetails[i].otherTypePaymet,
                });
              }
            }

            this.countHouse =
              response.data[0].json_houses_details == null
                ? []
                : JSON.parse(response.data[0].json_houses_details).length;
            let listJsonHouseDetails = [];
            listJsonHouseDetails =
              response.data[0].json_houses_details == null
                ? []
                : JSON.parse(response.data[0].json_houses_details);

            if (this.countHouse > 0) {
              // this.housingExpensesObject.housing = true;
            }

            if (listJsonHouseDetails.length == 0) {
              this.houses = [
                {
                  id: 1,
                  house: "",
                  statusHouse: "",
                  cost: 0,
                  mainHouse: "",
                },
              ];
              this.countHouse = 1;
            } else {
              this.houses = [];
              for (let x = 0; x < this.countHouse; x++) {
                this.houses.push({
                  id: listJsonHouseDetails[x].id,
                  cost: listJsonHouseDetails[x].cost,
                  house: listJsonHouseDetails[x].house,
                  mainHouse: listJsonHouseDetails[x].mainHouse,
                  statusHouse: listJsonHouseDetails[x].statusHouse,
                });
              }
            }

            this.countCards =
              response.data[0].json_cards == null
                ? []
                : JSON.parse(response.data[0].json_cards).length;
            let listCardsDetails = [];
            listCardsDetails =
              response.data[0].json_cards == null
                ? []
                : JSON.parse(response.data[0].json_cards);

            this.cards = [];
            for (let c = 0; c < this.countCards; c++) {
              this.cards.push({
                id: listCardsDetails[c].id,
                card: listCardsDetails[c].card,
                pay: listCardsDetails[c].pay,
              });
            }

            othersObject.map((other) => {
              this.optionsOthers.map((option) => {
                if (Number(other.id) == option.id) {
                  option.value = Number(other.mont);
                  this.selectedOther.push(option);
                }
              });
            });
            this.applicant = !!(
              this.coApplicantObject.firstName != null ||
              this.coApplicantObject.dob != null ||
              this.coApplicantObject.ssn != null ||
              this.coApplicantObject.coApplicantEmployer != null ||
              this.coApplicantObject.phoneM != null ||
              this.coApplicantObject.monthlyNetIncome != null
            );
            if (response.data[0].housing == null) {
              this.housingExpensesObject.housing = false;
            } else if (response.data[0].housing == 1) {
              this.housingExpensesObject.housing = true;
            } else if (response.data[0].housing == 0) {
              this.housingExpensesObject.housing = false;
            }
            this.removePreloader();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    changeHouse(evt) {
      if (this.houses.length == 0) {
        this.houses.push({
          id: 1,
          house: "",
          statusHouse: "",
          cost: 0,
          mainHouse: "",
        });
      }
      this.housingExpensesObject.housing = evt;
      this.houses = [this.houses[0]];
      this.houses[0].statusHouse = 2;
      this.countHouse = this.houses.length;
      // if (this.housingExpensesObject.housing != true) {
      //   this.analisisDebsolution();
      // }
    },
    addEmployer() {
      this.countEmployer++;
      this.employes.push({
        id: this.employes.length + 1,
        job: "",
        remunerated: "",
        typePaymet: "",
        paymetDate: "",
        otherTypePaymet: "",
      });
    },
    addCards() {
      this.countCards++;
      this.cards.push({
        id: this.cards.length + 1,
        card: "",
        pay: 0,
      });
    },
    addHouse() {
      if (
        this.houses.length == 1 &&
        this.housingExpensesObject.housing == false
      ) {
        this.showInfoSwal(
          "To add more houses you have to activate the House Owner option"
        );
      } else {
        this.countHouse++;
        this.houses.push({
          id: this.houses.length + 1,
          house: "",
          statusHouse: "",
          cost: 0,
          mainHouse: "",
        });
      }
    },
    deleteEmployer(id) {
      const index = this.employes.findIndex((x) => x.id == id);
      if (index !== -1) {
        this.employes.splice(index, 1);
      }
      this.countEmployer--;
    },
    deleteCards(id) {
      const index = this.cards.findIndex((x) => x.id == id);
      if (index !== -1) {
        this.cards.splice(index, 1);
      }
    },
    deleteHouses(id) {
      this.countHouse--;

      const index = this.houses.findIndex((x) => x.id == id);
      if (index !== -1) {
        this.houses.splice(index, 1);
      }
    },
    async getScore() {
      try {
        const response = await amgApi.post("/attend/get-score-attend", {
          lead_id: this.salesClient.lead_id,
        });
        if (response.status === 200) {
          this.score_id = response.data.score_id;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async questionnnairePdf(type) {
      try {
        let params = {
          event_id: this.salesClient.event_id,
          language: type,
        };
        this.addPreloader();
        const response = await QuestionnnaireService.questionnnairePdf(params);
        if (response.status === 200) {
          await this.forceFileDownloadPdf(response.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    async getCurrentFinancialAnalysis() {
      try {
        const params = {
          event: this.salesClient.event_id,
        };
        const { data } = await LeadService.getCurrentFinancialAnalysis(params);
        if (data.length > 0) {
          this.dsSituation = data[1];
        }
      } catch (error) {}
    },
  },

  watch: {
    "applicantObject.taxDeduction"(val) {
      this.applicantObject.netIncome = this.applicantObject.grossIncome - val;
    },

    "applicantObject.grossIncome"(val) {
      this.applicantObject.netIncome = val - this.applicantObject.taxDeduction;
    },
  },
};
</script>

<style>
.p_desing {
  color: white;
  font-size: 13px;
  background-color: #b139ee;
  border-radius: 3px;
  padding: 10px;
  text-align: center;
}
</style>
