<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-footer
      hide-header-close
      hide-header
      body-class="rounded show-alert"
      no-close-on-backdrop
      sticky-header="300px"
      size="alert"
      @hidden="isActive = false"
    >
      <div role="alert" class="my-1 px-1">
        <b-row>
          <b-col cols="12" class="text-center">
            <b-img-lazy
              :src="imageUrl + '/assets/images/icons/swal/bell-light.svg'"
              class="img-fluid"
              alt="Alerts"
            />
            <div class="h4 mt-2">
              <span class="font-strong" :class="colorText"> SUBJECT: </span>
              <span class="text-primary font-strong">{{ nameAlert }}</span>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex justify-content-center" style="gap: 15px">
              <badge-risky-clients
                v-if="Boolean(G_CLIENT_DATA)"
                :id-account="G_CLIENT_DATA.client_account_id"
                :risk-level="risky.level"
                :risk-description="risky.description"
                :risk-type="risky.type"
              />
              <template v-if="Boolean(G_CLIENT_DATA)">
                <b-badge
                  class="text-uppercase"
                  :variant="
                    G_CLIENT_DATA.queue_alert === 'original'
                      ? 'light-success'
                      : 'light-warning'
                  "
                >
                  {{ G_CLIENT_DATA.queue_alert }}
                </b-badge>
              </template>
            </div>
          </b-col>
          <b-col cols="12">
            <hr />
            <b-row>
              <b-col
                lg="12"
                md="12"
                class="mb-1"
                v-for="(item, index) in clientData"
                :key="index"
              >
                <b-input-group>
                  <b-input-group-prepend class="w-35">
                    <b-input-group-text
                      class="bg-primary font-strong text-white w-100"
                    >
                      <feather-icon
                        :icon="item.icon"
                        class="mr-1"
                        size="14"
                        svgClasses="font-small-4"
                      />
                      {{ item.label }}
                    </b-input-group-text>
                  </b-input-group-prepend>
                  <div
                    v-if="item.type === 'phone'"
                    class="form-control bg-transparent"
                    :class="colorText"
                  >
                    {{ item.value }}
                  </div>
                  <div
                    readonly
                    v-else
                    class="form-control bg-transparent"
                    :class="colorText"
                  >
                    {{ formatDate(item) }}
                    <span
                      v-if="item.type === 'user'"
                      v-b-tooltip.hover
                      :title="`Client language: ${clientLang}`"
                    >
                      {{ clientLang === "ES" ? "🇲🇽" : "🇺🇸" }}
                    </span>
                  </div>
                </b-input-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" class="text-center my-1">
            this alert will close in
            <b> {{ ` ${timeInMinutes} minutes, ${timeInSeconds} seconds` }}</b>
          </b-col>
        </b-row>
        <b-row class="mt-1" v-if="!isCustomerAnswer && !inActions">
          <b-col class="text-center">
            <b-button variant="primary" @click="acceptAlert" class="mr-1"
              >Accept</b-button
            >
            <b-button variant="danger" @click="declineAlert">Decline</b-button>
          </b-col>
        </b-row>
        <b-row class="mt-1" v-else>
          <b-col
            v-for="(action, index) in actions"
            :key="index"
            cols="4"
            class="text-center"
            v-b-tooltip.hover
            :title="action.name"
          >
            <a
              @click="actionAlert(action.action)"
              @mouseover="
                action.id === 2
                  ? (upNotAvailable = true)
                  : (upNotAvailable = false)
              "
            >
              <b-avatar
                button
                size="45px"
                class="cursor-pointer"
                :variant="action.color"
              >
                <feather-icon :icon="action.icon" size="25" />
              </b-avatar>
            </a>
          </b-col>
        </b-row>
        <transition name="form" appear>
          <fieldset
            class="border p-1 rounded border-danger mt-2"
            v-if="upNotAvailable"
          >
            <legend class="w-auto text-danger mb-0">Reschedule Alert</legend>
            <b-row class="justify-content-center">
              <form-validate-hour
                @submitSave="rescheduleAlert"
              ></form-validate-hour>
            </b-row>
          </fieldset>
        </transition>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment"
import { mapGetters, mapMutations } from "vuex"
import AlertActionService from "@/views/ce-digital/sub-modules/customer-service/views/auto-alerts/services"
import WelcomeActionService from "@/views/ce-digital/sub-modules/customer-service/views/clients/welcome-actions/service/welcome-action.service"
import ApplicationService from "@/views/commons/components/applications/services/applications.service"
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients.vue"
import FormValidateHour from "@/views/ce-digital/sub-modules/customer-service/views/commons/FormValidateHour.vue"
export default {
  components: {
    BadgeRiskyClients,
    FormValidateHour,
  },
  name: "AcceptanceAlert",
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL_FRONT,
      timeInMinutes: 0,
      timeInSeconds: 0,
      timer: 5,
      countDown: null,
      isCustomerAnswer: false,
      upNotAvailable: false,
      idGenerated: null,
      notAvailableData: {
        date: null,
        time: null,
      },
      actions: [
        {
          id: 1,
          name: "Available",
          icon: "PhoneIcon",
          color: "success",
          action: "available",
        },
        {
          id: 2,
          name: "Not Available",
          icon: "PhoneOffIcon",
          color: "danger",
          action: "not_available",
        },
        {
          id: 3,
          name: "Not Answer",
          icon: "XIcon",
          color: "warning",
          action: "not_answer",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      G_OPEN_ALERT: "AutoAlertStore/G_OPEN_ALERT",
      G_CLIENT_DATA: "AutoAlertStore/G_CLIENT_DATA",
      G_ID_ALERT: "AutoAlertStore/G_ID_ALERT",
      currentUser: "auth/currentUser",
    }),
    isActive: {
      get() {
        return this.G_OPEN_ALERT
      },
      set(value) {
        this.$store.commit("AutoAlertStore/OPEN_ALERT", value)
      },
    },
    colorText() {
      return this.isDark ? "text-white" : "text-dark"
    },
    inActions() {
      return this.G_CLIENT_DATA?.status === "IN ACTIONS"
    },
    clientLang() {
      return this.G_CLIENT_DATA?.language || "ES"
    },
    risky() {
      return {
        type: this.G_CLIENT_DATA?.risky_type,
        level: this.G_CLIENT_DATA?.risky_level,
        description: this.G_CLIENT_DATA?.risky_description,
      }
    },
    alertType() {
      return this.G_CLIENT_DATA?.alert_type
    },
    idAlert() {
      return this.G_ID_ALERT
    },
    nameAlert() {
      const alerts = {
        1: "WELCOME",
        2: "LETTERS SENT BY CORRESPONDENCE",
        3: "LETTERS RECEIVED BY CLIENT",
        4: "UPDATE REQUEST",
        5: "UPDATE REQUEST (NEW NCR RECOMMENDED)",
        6: "UPDATE REQUEST (NEW APPLICATION RECOMMENDED)",
        7: "UPDATE REQUEST (NEW NCR AND APPLICATION RECOMMENDED)",
        8: "NEW NCR REQUEST",
        9: "NEW APPLICATION REQUEST",
        10: "NEW NCR REALTOR REQUEST",
        11: "NEW CUSTOMER TRACKING REQUEST",
      }
      return alerts[this.alertType]
    },
    clientData() {
      return [
        {
          label: "Client Name",
          value: this.G_CLIENT_DATA?.lead_name,
          icon: "UserIcon",
          type: "user",
        },
        {
          label: "Phone",
          value: this.G_CLIENT_DATA?.mobile,
          icon: "PhoneIcon",
          type: "phone",
        },
        {
          label: "System Date",
          value: this.G_CLIENT_DATA?.original_hour || "N/A",
          icon: "ClockIcon",
          type: "date",
        },
        {
          label: "Client Date",
          value:
            `${this.G_CLIENT_DATA?.date_event} ${this.G_CLIENT_DATA?.hour_event}` ||
            "N/A",
          icon: "ClockIcon",
          type: "date",
        },
      ]
    },
    moduleId() {
      return this.$route.matched[0].meta.module
    },
  },
  mounted() {},
  methods: {
    ...mapMutations({
      SET_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/SET_USER_STATUS_SESSION_SUB_MODULE",
      SET_CURRENT_ALERT_ID: "AutoAlertStore/SET_ID_ALERT_ACCEPTED",
    }),
    // select the action for the alert
    async actionAlert(action) {
      switch (action) {
        case "available":
          await this.availableClient()
          break
        case "not_available":
          break
        case "not_answer":
          await this.notAnswer()
          break
        default:
          break
      }
    },
    // if the client is available, redirect to the dashboard of the client
    async availableClient() {
      this.addPreloader()
      try {
        const params = {
          agent_id: this.currentUser.user_id,
          alert_id: this.idAlert,
          agent_action_id: 1,
        }
        this.SET_CURRENT_ALERT_ID([{ alert_id: this.idAlert }])
        await WelcomeActionService.saveAgentAction(params)
        if (this.isAgent) {
          await document.documentElement.requestFullscreen()
        }
        if ([6, 7, 9].some((e) => e === this.alertType)) {
          const paramsAssign = {
            user_id: this.currentUser.user_id,
            app_id: this.G_CLIENT_DATA.app_id,
          }
          await ApplicationService.assignAgentToApplication(paramsAssign)
        }
        this.$router.push({
          name: "ce-customer-service-dashboard",
          params: {
            idClient: this.G_CLIENT_DATA.client_account_id,
            hasAccess: true,
            typeAccess: this.alertType,
            wayAccess: 3,
          },
        })
        this.isActive = false
        this.closeAllAlert()
        this.changeStatusAgent(5)
      } catch (error) {
        console.error(error)
      } finally {
        this.removePreloader()
      }
    },
    // if the client is not answer, close the alert
    async notAnswer() {
      this.addPreloader()
      const params = {
        agent_id: this.currentUser.user_id,
        alert_id: this.idAlert,
        client_account_id: this.G_CLIENT_DATA.client_account_id,
        agent_action_id: 3,
        calls_count: this.G_CLIENT_DATA.calls_count + 1,
      }
      await WelcomeActionService.saveAgentAction(params)
      this.isActive = false
      this.closeAllAlert()
      this.changeStatusAgent(1)
      this.removePreloader()
    },
    // if the client is not available, reschedule the alert
    async rescheduleAlert(date) {
      this.addPreloader()
      try {
        const params = {
          agent_id: this.currentUser.user_id,
          alert_id: this.idAlert,
          agent_action_id: 2,
          date_event: date.date_event,
          hour_event: date.hour_event,
        }
        await WelcomeActionService.saveAgentAction(params)
        this.isActive = false
        this.closeAllAlert()
        this.changeStatusAgent(1)
      } catch (error) {
        console.error(error)
      } finally {
        this.removePreloader()
      }
    },
    // if the agent accept the alert, show the actions for 5 minutes more
    async acceptAlert() {
      await AlertActionService.acceptAlert({
        user_id: this.currentUser.user_id,
        alert_id: this.idAlert,
      })
      this.isCustomerAnswer = true
      clearInterval(this.countDown)
      this.closeAllAlert()
      this.startTimer()
    },
    // if the agent decline the alert, close the alert and change the status of the agent
    async declineAlert() {
      this.isActive = false
      const params = {
        agent_id: this.currentUser.user_id,
        alert_id: this.idAlert,
        agent_action_id: 0,
        deny_first: true,
      }
      await WelcomeActionService.saveAgentAction(params)

      this.closeAllAlert()
      this.changeStatusAgent(1)
      clearInterval(this.countDown)
    },
    // change the status of the agent to 5 (in call)
    async changeStatusAgent(status) {
      const params = {
        created_by: this.currentUser.user_id,
        sub_module_id: this.moduleId,
        status_sub_module_id: status,
      }
      this.SET_USER_STATUS_SESSION_SUB_MODULE([{ status_session: status }])
      await this.$store.dispatch(
        "UserStore/A_CHANGE_USER_STATUS_SESSION_SUB_MODULE",
        params
      )
    },
    // start the timer for 5 minutes
    startTimer() {
      this.timeInMinutes = this.timer
      this.timeInSeconds = 0
      if (this.inActions) {
        const timeDiffSeconds = this.G_CLIENT_DATA?.time_diff_in_seconds
        this.timeInMinutes = Math.floor(timeDiffSeconds / 60)
        this.timeInSeconds = timeDiffSeconds % 60
      }
      this.countDown = setInterval(async () => {
        if (this.timeInMinutes === 0 && this.timeInSeconds === 0) {
          if (!this.isCustomerAnswer) {
            const params = {
              operation: 5,
              alert_id: this.idAlert,
              agent_id: this.currentUser.user_id,
            }
            await WelcomeActionService.saveAgentAlertTracking(params)
          }
          this.declineAlert()
        } else {
          if (this.timeInSeconds === 0) {
            this.timeInMinutes--
            this.timeInSeconds = 59
          } else {
            this.timeInSeconds--
          }
        }
      }, 1000)
    },
    formatDate(item) {
      if (item.type === "date" && item.value !== "N/A") {
        return moment(item.value).format("DD/MM/YYYY HH:mm:ss")
      }
      return item.value
    },
    generateIdUnique() {
      this.idGenerated = Date.now() + Math.floor(Math.random() * 1000)
      this.$store.commit("AutoAlertStore/ID_GENERATED", this.idGenerated)
    },
    closeAllAlert() {
      const channel = socket.channel("channel-auto-alert", {})
      channel.trigger("client-close-all-auto-alert", {
        message: "close all",
        user_id: this.currentUser.user_id,
      })
    },
  },
  watch: {
    // this function will be called when the value of isActive changes and start the timer
    async isActive(newVal) {
      if (newVal) {
        this.changeStatusAgent(5)
        this.startTimer()
        this.generateIdUnique()
      } else {
        clearInterval(this.countDown)
        this.$store.commit("AutoAlertStore/ID_ALERT", null)
        this.$emit("rebuildAcceptance")
      }
    },
  },
}
</script>

<style scoped>
.w-35 {
  width: 35% !important;
}
</style>
<style>
.show-alert {
  border: 2px solid #fff;
  border-radius: 10px;
  animation: mymove 1s infinite;
}

@keyframes mymove {
  0% {
    border-color: #fff;
  }
  50% {
    border-color: #0087db;
  }
  100% {
    border-color: #fff;
  }
}

.form-container {
  transition: all 0.3s ease-in-out;
  transform: scale(0.7);
  opacity: 0;
}

.form-enter-active {
  transition: all 0.3s ease-in-out;
}

.form-enter-to {
  transform: scale(1);
  opacity: 1;
}

.form-leave-active {
  transition: all 0.3s ease-in-out;
}

.form-leave-to {
  transform: scale(0.7);
  opacity: 0;
}

.form {
  transition: all 0.3s ease-in-out;
  transform: scale(0.7);
  opacity: 0;
}
</style>
