export default [
    {
        key: "sent_by",
        label: "Sent By",
        thClass: "text-left",
        tdClass: "text-left py-50 px-0",
        sortable: false,
        visible: true,
    },
    {
        key: "type",
        label: "From List",
        thClass: "text-left",
        tdClass: "text-left py-50 px-0",
        sortable: false,
        visible: true,
    },
    {
        key: "line",
        label: "Line Number",
        thClass: "text-left",
        tdClass: "text-left py-50 px-0",
        sortable: false,
        visible: true,
    },
    {
        key: 'actions',
        label: "Actions",
        thClass: "text-center",
        tdClass: "text-center py-50 px-0",
        sortable: false,
    },

];
