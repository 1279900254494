<template>
  <div>
    <b-table
      :class="showDetails ? 'mh-5' : ''"
      show-empty
      sticky-header
      responsive
      :fields="[
        { key: 'title' },
        { key: 'seller_name' },
        { key: 'location' },
        { key: 'date' },
        { key: 'from' },
        { key: 'to' },
        { key: 'case', label: 'STATUS' },
        { key: 'created_by', label: 'ACTION DONE BY' },
      ]"
      :items="S_LEAD_APPOINTMENT_TRACKING"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <template #cell(title)="data">
        {{ data.item.title }}
      </template>
      <template #cell(date)="data">
        {{ data.item.date | myDate }}
      </template>
      <template #cell(from)="data">
        {{ data.item.from | myTime }}
      </template>
      <template #cell(to)="data">
        {{ data.item.to | myTime }}
      </template>
      <template #cell(case)="data">
        <b-badge
          v-if="data.item.case == 'create'"
          variant="light-secondary"
          class="ml-1 text-uppercase"
        >
          {{ data.item.case }}
        </b-badge>
        <b-badge
          v-if="data.item.case == 'update' && data.item.is_attended === 0"
          variant="light-primary"
          class="ml-1 text-uppercase"
        >
          {{ data.item.case }}
        </b-badge>

        <b-badge
          v-if="data.item.case == 'update' && data.item.is_attended === 1"
          variant="light-success"
          class="ml-1 text-uppercase"
        >
          Attended
        </b-badge>
      </template>
      <template #cell(created_by)="data">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <b-row>
              <b-col>{{ data.item.created_by }}</b-col>
            </b-row>
            <b-row>
              <b-col>{{ data.item.created_at | myGlobalWithHour }}</b-col>
            </b-row>
          </div>
          <!-- @click="data.toggleDetails" -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="link"
            @click="showRowDetail(data.index)"
            size="sm"
            ><feather-icon icon="ChevronDownIcon" variant="primary" size="14"
          /></b-button>
        </div>
      </template>
      <!-- Row details slot -->
      <template #row-details="data">
        <detail-row-tracking :data="data" :event="event" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import DetailRowTracking from "@/views/crm/views/Lead/lead-event/DetailRowTracking.vue";

export default {
  name: "LeadAppointmentTracking",
  components: {
    flatPickr,
    vSelect,
    DetailRowTracking,
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    isCrm() {
      return this.$route.matched[0].meta.module === 2;
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_EVENT_TITLES_OPTIONS: "CrmEventStore/G_EVENT_TITLES",
      G_OWNERS: "CrmGlobalStore/G_OWNERS",
    }),
    ...mapState({
      S_LEAD_APPOINTMENT_TRACKING: (event) =>
        event.CrmLeadStore.S_LEAD_APPOINTMENT_TRACKING,
    }),
  },
  async created() {
    this.getLeadAppointmentTracking();
  },
  data() {
    return {
      showDetails: false,
      isBusy: false,
    };
  },
  directives: { Ripple },
  methods: {
    showRowDetail(index) {
      this.S_LEAD_APPOINTMENT_TRACKING.forEach((element, i) => {
        if (i != index) {
          element._showDetails = false;
          element.detailsShowing = false;
        }
      });
      this.showDetails = !this.showDetails;
      this.S_LEAD_APPOINTMENT_TRACKING[index]._showDetails =
        !this.S_LEAD_APPOINTMENT_TRACKING[index]._showDetails;
      this.S_LEAD_APPOINTMENT_TRACKING[index].detailsShowing =
        !this.S_LEAD_APPOINTMENT_TRACKING[index].detailsShowing;
    },
    ...mapActions({
      A_LEAD_APPOINTMENT_TRACKING: "CrmLeadStore/A_LEAD_APPOINTMENT_TRACKING",
    }),

    async getLeadAppointmentTracking() {
      try {
        this.isBusy = true;
        await this.A_LEAD_APPOINTMENT_TRACKING({
          event_id: this.event.id,
        });
        this.isBusy = false;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
.mh-5 {
  min-height: 500px;
}
</style>
