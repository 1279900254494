import store from "@/store";
import Vue from "vue";
import HelpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import router from "@/router/index";

const ticketNewNotification = () => {
  window.socket.bind("new-ticket", async (data) => {
    const { user_id: sessionId, modul_id: module_id } =
      store.state.auth.currentUser;
    const arrUsers = data.to_id;
    const routes = {
      4: "helpdesk-administration",
      8: "helpdesk-tax-research",
      12: "helpdesk-ti",
    };
    const module_name = routes[module_id];
    if (arrUsers.includes(Number(sessionId))) {
      const params = {
        modul_id: 12,
        user_id: sessionId,
      };
      if (store.state.auth.currentUser.is_reviewer) {
        const tab = {
          user_id: 124,
          is_reviewer: 1,
        };
        const { data } = await HelpdeskService.getCountTab(tab);
        store.commit(
          "HelpdeskStore/SET_REVIEWER_COUNTER_NOTIFICATIONS",
          data.total
        );
      } else {
        const { data } = await HelpdeskService.getCountTab(params);
        store.commit("HelpdeskStore/SET_COUNTER_NOTIFICATIONS", data.total);
      }
      const response = await HelpdeskService.getCounterNotifications(params);
      await Vue.prototype.$amgAlert({
        title: "New Ticket",
        icon: "CheckIcon",
        subtitle: `You have a new ticket: ${data.message.ticket_id}`,
        okTitle: "Go to Helpdesk",
        okComponentTitle: "Open Ticket",
        dataComponent: {
          name: "ModalDetails",
          props: {
            ticket_id: data.message.ticket_id.toString(),
          },
        },
        data: [
          {
            icon: "UserIcon",
            title: "CREATED BY",
            description: data.message.user_name,
          },
          {
            icon: "GridIcon",
            title: "TITLE",
            description: data.message.title,
          },
          {
            icon: "GridIcon",
            title: "MODULE",
            description: data.message.module_name,
          },
        ],
        okTitle: "Ok",
        ok: async () => {
          if (router.currentRoute.name !== module_name) {
            await router.push({
              name: module_name,
            });
          }
          await window.amgApi.post("/commons/close-all-swal", data);
        },
      });

      if (Object.keys(response.data).length > 0) {
        const payload = {
          routeName: module_name,
          tag: response.data.total,
        };
        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
      }
    }
  });
};

export default ticketNewNotification;
