import { applicationTabs } from "@/views/commons/components/applications/router/application.tabs";

export default [
  {
    path: "applications",
    name: "boost-credit-applications",
    redirect: { name: "bc-application-pending" },
    component: () => import(/* webpackChunkName: "FinancialApprovalApplications" */ "@/views/commons/components/applications/Applications.vue"),
    meta: {
      program: 2,
      module: 7,
      router: "bc",
      pageTitle: "Credit Card",
      breadcrumb: [
        {
          text: "CREDIT CARD",
          active: true,
        },
      ],
      routePending: "bc-application-pending",
      routeCompleted: "bc-application-completed",
      routeExpired: "bc-application-expired",
      routeSuccessfull: "bc-application-successfull",
      routeUnsuccessful: "bc-application-unsuccessful",
    },
    children: applicationTabs("bc"),
  },
];
