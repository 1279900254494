import Pusher from "pusher-js"
import refreshMessageQuantites from "@/commons/messages/messages.pusher"
import reloadCounterLoan from "@/views/commons/pusher/reload-counter-loan.pusher"
import acceptLeadFromSocialNetwork from "@/views/crm/views/Lead/pusher/social-accept-yes-or-not.pusher"
import sendJustifyFromSupervisor from "@/views/crm/views/schedules/pusher/send-justify-to-supervisor.pusher"
import sendFromRrhhJustifyToManagment from "@/views/crm/views/schedules/pusher/send-from-rrhh-justify-to-management.pusher"
import closedAllSwal from "@/views/commons/pusher/closed-all-swal.pusher" // migrate
import alertMarkSchedules from "@/views/crm/views/schedules/pusher/alert-mark-schedules.pusher"
import taskAlert from "@/views/commons/pusher/task-alert.pusher"
import { paragonNewCommentNotification } from "@/views/social-network/views/bank-of-flyers/bank-of-flyers.pusher"
import infoAdmToCrm from "@/views/crm/views/Lead/pusher/info-adm-to-crm.pusher"
import changeUserStatusSession from "@/views/commons/pusher/change-user-status-session.pusher"
import dateUserStatusSession from "@/views/commons/pusher/date-user-status-session.pusher"
import salesMadePusher from "@/views/crm/views/sales-made/sales-made.pusher"
import { newFlyerNotification } from "@/views/social-network/views/bank-of-flyers/new-flyer.pusher"
import assignTaskToSeller from "@/views/commons/pusher/assign-task-to-seller.pusher"
import socialAcceptAppoitment from "@/views/commons/pusher/social-accept-appointment.pusher" // Migrate
import recoveryListAlert from "@/views/social-network/views/recovery-list/pusher/recovery-list.pusher"
import createScheduleTransfer from "@/views/management/views/companies/dashboard/create-schedule-transfer.pusher"
import scheduleTransferAlert from "@/views/management/views/companies/dashboard/schedule-transfer-alert.pusher"
import ticketDueDateNotification from "@/views/commons/components/helpdesk/ticket-due-date.pusher.js"
import ticketNewNotification from "@/views/commons/components/helpdesk/ticket-new.pusher.js"
import ticketStatusNotification from "@/views/commons/components/helpdesk/ticket-status.pusher.js"
import ticketCommentNotification from "@/views/commons/components/helpdesk/ticket-comment.pusher.js"
import reloadCounterTicket from "@/views/commons/pusher/reload-counter-ticket.pusher"
import updateSalePermission from "@/views/crm/views/settings/pusher/update-sale-permission.pusher"
import ticketUpdateOwnerNotification from "@/views/commons/components/helpdesk/update-owner.pusher.js"
import LetterPusher from "@/views/correspondence/views/letters/letter.pusher.js"
import migrationRequestDigitalNotification from "@/views/ce-digital/sub-modules/settings/views/migrations/migration-request-digital.pusher"
import migrationRequestRespondNotification from "@/views/ce-digital/sub-modules/settings/views/migrations/migrations-request-respond.pusher.js"
import ncrProgramRealtorRequested from "@/views/ce-digital/sub-modules/customer-service/views/ncr-programs/ncr-program-realtor-requested.pusher"
import ncrProgramRealtorProcessed from "@/views/ce-digital/sub-modules/customer-service/views/ncr-programs/ncr-program-realtor-processed.pusher"
import SettlementOfferNotification from "@/views/debt-solution/views/clients/clients.pusher"
import connectionClientDiigtalNotification from "@/views/ce-digital/sub-modules/connection/views/clients/client-send-recycle-pusher"

import notificationManChargeBack from "@/views/commons/components/charge-back/charge-back.pusher"
import notificationActCallRound from "@/views/commons/call-rounds/components/call-round.pusher"
import statusSubmoduleRedirectPusher from "@/views/ce-digital/pusher/status-submodule-redirect.pusher"
import ceSetCommissionsPusher from "@/views/ce-digital/sub-modules/settings/views/commissions/ce-set-commissions.pusher"
import openWelcomeActionAgents from "@/views/ce-digital/sub-modules/customer-service/views/clients/welcome-actions/welcome-actions.pusher"
import closeAllAgentSwals from "@/views/ce-digital/sub-modules/customer-service/views/clients/welcome-actions/close-all-agent-swall.pusher"
import ActivationAppPusher from "@/views/ce-digital/sub-modules/customer-service/views/clients/dashboard/pusher/activation-app.pusher"
import customerWelcomeAlert from "@/views/commons/pusher/customer-welcome-alert.pusher"
import openAlertsLetter from "@/views/ce-digital/sub-modules/customer-service/views/alerts/pusher/index.js"
import closeAlertsLetter from "@/views/ce-digital/sub-modules/customer-service/views/alerts/pusher/closeAlertsLetter.js"
import alertChiefsAboutCommissionDecision from "@/views/ce-digital/sub-modules/settings/views/commissions/alert-chiefs-about-commission-decision.pusher"
import pendingPaymentAlert from "@/views/ce-digital/sub-modules/financial/view/alerts/pending-payments-alert/pending-payment-alert.pusher"
import {
  newOtherPaymentCeFinancialRequest,
  resultFinancialRequest,
} from "@/views/management/views/approval-process/other-payments/other-payments.pusher"
import ceDynamicCommissionsCreation from "@/views/ce-digital/sub-modules/settings/views/commissions/components/dynamic-commissions/pusher/ce-dynamic-commissions-creation.pusher"
import ceDynamicCommissionsManDecision from "@/views/ce-digital/sub-modules/settings/views/commissions/components/dynamic-commissions/pusher/ce-dynamic-commissions-man-decision.pusher"
import {
  newZeroPaymentCeFinancialRequest,
  newZeroPaymentCeDigitalResult,
} from "@/views/ce-digital/sub-modules/financial/view/zero-payment/zero-payment-ce-digital.pusher"
import newClientSpecialistDigitalAlert from "@/views/specialist-digital/views/clients/specialist-digital-clients.pusher"
import requestNcrToAdm from "@/views/administration/views/ncr-leads/pusher/adm-ncr-leads.pusher"

import derivationsAlertPusher from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations-alert.pusher"
import sellerPermissionAlertPusher from "@/views/ce-digital/sub-modules/settings/views/permissions/seller-permission-actions.pusher"
import sellerResponseAlertPusher from "@/views/ce-digital/sub-modules/settings/views/permissions/seller-permission-response.pusher"
import {
  newLeadRealtorRequest,
  newLeadRealtorRequestDone,
} from "@/views/specialist-digital/specialist-digital.pusher.js"

import { isUserLoggedIn } from "@/auth/utils"

const subscribeOldSocket = (socket) => {
  try {
    //  sendFromRrhhJustifyToManagment()
    //  alertMarkSchedules()
    //  taskAlert()
    //  reloadCounterTicket()
    //  customerWelcomeAlert()
    //  LetterPusher()
    //  closeAlertsLetter()
    //  connectionClientDiigtalNotification()

    socket.subscribe("channel-single-alert")
    pendingPaymentAlert()
    ActivationAppPusher()
    reloadCounterLoan()
    changeUserStatusSession()
    closeAllAgentSwals()

    socket.subscribe("channel-migration-alert")
    salesMadePusher()
    migrationRequestDigitalNotification()
    newClientSpecialistDigitalAlert()

    socket.subscribe("channel-social")
    socialAcceptAppoitment()
    acceptLeadFromSocialNetwork()

    socket.subscribe("channel-ce-dynamic-commission")
    ceDynamicCommissionsManDecision()
    ceDynamicCommissionsCreation()

    socket.subscribe("channel-alert-about-commission")
    alertChiefsAboutCommissionDecision()
    ceSetCommissionsPusher()

    socket.subscribe("channel-close-all")
    closedAllSwal()

    socket.subscribe("channel-new-ncr-program")
    ncrProgramRealtorRequested()
    ncrProgramRealtorProcessed()

    socket.subscribe("channel-new-ncr-lead")
    newLeadRealtorRequest()
    newLeadRealtorRequestDone()

    socket.subscribe("channel-new-payment-digital")
    resultFinancialRequest()
    newZeroPaymentCeFinancialRequest()
    newZeroPaymentCeDigitalResult()
    newOtherPaymentCeFinancialRequest()

    socket.subscribe("channel-crm-to-adm")
    requestNcrToAdm()

    socket.subscribe("channel-schedule-transfer")
    scheduleTransferAlert()

    socket.subscribe("channel-adm-to-crm")
    infoAdmToCrm()

    socket.subscribe("channel-migrations")
    migrationRequestRespondNotification()

    socket.subscribe("channel-call-round-operator")
    notificationActCallRound()

    socket.subscribe("channel-notification-man")
    notificationManChargeBack()
    createScheduleTransfer()

    socket.subscribe("channel-schedules")
    sendJustifyFromSupervisor() // sendJustifyToSupervisor

    socket.subscribe("channel-new-flyer")
    newFlyerNotification()

    socket.subscribe("channel-user-submodule")
    statusSubmoduleRedirectPusher()
    dateUserStatusSession() // userSubmoduleStatusSession

    socket.subscribe("channel-paragon")
    paragonNewCommentNotification()

    socket.subscribe("channel-ticket-alert")
    ticketDueDateNotification()
    ticketStatusNotification()
    ticketCommentNotification()
    ticketUpdateOwnerNotification()
    ticketNewNotification()

    socket.subscribe("channel-update-seller-typesenior")
    sellerPermissionAlertPusher()
    sellerResponseAlertPusher()

    socket.subscribe("channel-offers")
    SettlementOfferNotification()

    socket.subscribe("channel-message")
    refreshMessageQuantites()

    socket.subscribe("channel-cedigital-alerts")
    derivationsAlertPusher()

    socket.subscribe("channel-request-advisor-alerts")
    openAlertsLetter()

    socket.subscribe("welcome-agent-action")
    openWelcomeActionAgents()

    socket.subscribe("channel-sale-permission")
    updateSalePermission()

    socket.subscribe("channel-round-robin")
    assignTaskToSeller()

    socket.subscribe("channel-sn-recovery")
    recoveryListAlert()
  } catch (error) {
    console.error(error, "error")
  }
}
export default subscribeOldSocket
