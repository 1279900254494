export default [
  {
    path: "clients/",
    name: "paragon-clients",
    component: () =>
      import(
        /* webpackChunkName: "PRClients" */ "@/views/paragon/views/clients/Clients.vue"
      ),
    redirect: { name: "paragon-clients-list" },
    meta: {
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "paragon",
        name: "paragon-clients-list",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsList" */ "@/views/paragon/views/clients/digital/components/ClientsGrid.vue"
          ),
        meta: {
          moduleParagon: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
        },
      },
      {
        path: "business",
        name: "paragon-clients-business-list",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsBusiness" */ "@/views/paragon/views/clients/digital/components/ClientsGrid.vue"
          ),
        meta: {
          moduleParagon: false,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
