import crm from "@/views/crm/navigation/index"
import socialNetwork from "@/views/social-network/navigation"
import debtSolution from "@/views/debt-solution/navigation"
import boostCredit from "@/views/boost-credit/navigation"
import management from "@/views/management/navigation"
import rrhh from "@/views/rrhh/navigation"
import logistic from "@/views/logistic/navigation"
import paragon from "@/views/paragon/navigation"
import bussiness from "@/views/business/navigation"
import bookeeping from "@/views/bookeeping/navigation"
import courtInfo from "@/views/court-info/navigation"
import taxResearch from "@/views/tax-research/navigation"
import users from "@/views/users/navigation"
import correspondence from "@/views/correspondence/navigation"
import cDigitalCustomerService from "@/views/ce-digital/sub-modules/customer-service/navigation"
import cDigitalSettings from "@/views/ce-digital/sub-modules/settings/navigation"
import cDigitalFinancial from "@/views/ce-digital/sub-modules/financial/navigation"
import cDigitalSales from "@/views/ce-digital/sub-modules/sales/navigation"
import specialistsDigital from "@/views/specialist-digital/navigation"
import quality from "@/views/quality/navigation"
import creditExperts from "@/views/credit-experts/navigation"
import administration from "@/views/administration/navigation"
import connection from "@/views/ce-digital/sub-modules/connection/navigation"
// import specialist from "@/views/specialists/navigation"
import creative from "@/views/creative/navigation"
import analystDepartment from "@/views/specialists/sub-modules/analyst-department/navigation/index"
import accountsResolution from "@/views/specialists/sub-modules/accounts-resolution/navigation/index"
import financialApproval from "@/views/specialists/sub-modules/financial-approval/navigation/index"

export default {
  15: socialNetwork,
  16: management,
  17: rrhh,
  19: logistic,
  5: debtSolution,
  6: creditExperts,
  7: boostCredit,
  2: crm,
  12: paragon,
  3: bussiness,
  8: taxResearch,
  9: correspondence,
  14: bookeeping,
  10: courtInfo,
  25: specialistsDigital,
  22: cDigitalCustomerService,
  23: cDigitalFinancial,
  24: cDigitalSettings,
  1: users,
  4: administration,
  18: quality,
  20: connection,
  // 11: specialist,
  26: cDigitalSales,
  27: creative,
  28: analystDepartment,
  29: financialApproval,
  30: accountsResolution
}
