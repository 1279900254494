export default [
  // {
  //   key: "selected",
  //   label: "",
  //   sortable: false,
  //   visible: true,
  // },
  {
    key: "client",
    label: "Client",
    sortable: false,
    visible: true,
    thStyle: { width: "170px" },
  },
  {
    key: "type",
    label: "TY",
    sortable: false,
    visible: true,
    thClass: "text-center",
  },
  {
    key: "first_program",
    label: "OR",
    sortable: false,
    visible: true,
    thClass: "text-center",
  },
  {
    key: "program",
    label: "DE",
    sortable: false,
    visible: true,
    thClass: "text-center",
  },
  {
    key: "captured",
    sortable: false,
    visible: false,
    label: "Catcher",
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "seller",
    sortable: false,
    visible: false,
    label: "Seller",
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "executives",
    sortable: false,
    visible: true,
    label: "Executives",
    class: "text-center",
    tdClass: "px-3",
    thStyle: { width: "250px" },
  },
  {
    key: "fee",
    sortable: false,
    visible: true,
    label: "Fee ($)",
    class: "text-center",
  },
  {
    key: "initial_amount",
    sortable: false,
    visible: true,
    label: "IP",
    tdClass: "text-center",
    thClass: "text-center",
    thStyle: { width: "125px" },
  },
  {
    key: "contract_fee_status",
    sortable: false,
    visible: true,
    label: "CF",
    tdClass: "text-center",
    thClass: "text-center",
    thStyle: { width: "70px" },
  },
  {
    key: "notes_status",
    sortable: false,
    visible: true,
    label: "NT",
    tdClass: "text-center",
    thClass: "text-center",
    thStyle: { width: "70px" },
  },
  {
    key: "summary",
    sortable: false,
    visible: true,
    label: "Call",
    tdClass: "text-center",
    thClass: "text-center",
    thStyle: { width: "70px" },
  },
  {
    key: "trackings",
    sortable: false,
    visible: false,
    label: "TK",
  },
  {
    key: "files",
    sortable: false,
    visible: true,
    label: "FI",
    tdClass: "text-center",
    thClass: "text-center",
    thStyle: { width: "70px" },
  },
  {
    key: "status",
    sortable: false,
    visible: false,
    label: "ST",
  },
  {
    key: "date_time",
    sortable: false,
    visible: true,
    label: "Date/Time",
  },
  {
    key: "creates",
    sortable: false,
    visible: false,
    label: "Created",
  },
  {
    key: "approved",
    sortable: false,
    visible: false,
    label: "Approved",
  },
  {
    key: "sms",
    sortable: false,
    visible: true,
    label: "More",
  },
  {
    key: "actions",
    sortable: false,
    visible: true,
    label: "Action",
  },
];
