import store from "@/store";

export const newRoundLetterToSend = () => {
    window.socket.bind(
        "alert-new-letters-to-send-notification",
        async ({
            title,
            modules,
            loggedInUserId,
            moduleUsers,
            clientData
        }) => {
            const { modul_id: current_module_id, user_id } = store.getters["auth/currentUser"];

            if (
                moduleUsers.filter((user) => user.id != loggedInUserId).some((user) => user.id == user_id) && current_module_id == modules[0]
            ) {
                store.commit("AutoAlertLetterRound/M_OPEN_ALERT", true);
                store.commit("AutoAlertLetterRound/M_DATA_LETTER_ROUND", clientData);
                store.commit("AutoAlertLetterRound/M_TITLE", title);
            }
        }
    )
}

export const missingFilesNotification = () => {
    window.socket.bind(
        "alert-missing-files-nrl-notification",
        async ({
            title,
            description,
            modules,
            loggedInUserId,
            moduleUsers,
            clientData
        }) => {
            const { modul_id: current_module_id, user_id } = store.getters["auth/currentUser"];

            if (
                // true
                moduleUsers.filter((user) => user.id != loggedInUserId).some((user) => user.id == user_id) && current_module_id == modules[0]
            ) {
                store.commit("AutoAlertLetterRound/M_OPEN_ALERT", true);
                store.commit("AutoAlertLetterRound/M_DATA_LETTER_ROUND", clientData);
                store.commit("AutoAlertLetterRound/M_TITLE", title);
                store.commit("AutoAlertLetterRound/M_DESCRIPTION", description);
            }
        }
    )
}

export const closeAllRoundLetterNotification = () => {
    window.socket.bind("client-close-all-round-letter-notification", async (data) => {
        const { user_id } = store.getters["auth/currentUser"];
        if (data.user_id == user_id) {
            store.commit("AutoAlertLetterRound/M_OPEN_ALERT", true);
            store.commit("AutoAlertLetterRound/M_DATA_LETTER_ROUND", null);
            store.commit("AutoAlertLetterRound/M_TITLE", null);
        }
    })
}

export const newReportSend = () => {
    window.socket.bind(
        "sending-report-notifications",
        async ({
            title,
            modules,
            loggedInUserId,
            moduleUsers,
            clientData
        }) => {
            const { modul_id: current_module_id, user_id } = store.getters["auth/currentUser"];

            if (
                moduleUsers.filter((user) => user.id != loggedInUserId).some((user) => user.id == user_id) && current_module_id == modules[0]
            ) {
                store.commit("AutoAlertSendReport/M_OPEN_ALERT", true);
                store.commit("AutoAlertSendReport/M_DATA_SEND_REPORT", clientData);
                store.commit("AutoAlertSendReport/M_TITLE", title);
            }
        }
    )
}

export const closeSendReportNotification = () => {
    window.socket.bind("client-close-send-report-notification", async (data) => {
        const { user_id } = store.getters["auth/currentUser"];
        if (data.user_id == user_id) {
            store.commit("AutoAlertSendReport/M_OPEN_ALERT", true);
            store.commit("AutoAlertSendReport/M_DATA_SEND_REPORT", null);
            store.commit("AutoAlertSendReport/M_TITLE", null);
        }
    })
}

export const returnedLetterNotification = () => {
    window.socket.bind(
        "returned-letters-notification",
        async ({
            title,
            modules,
            loggedInUserId,
            moduleUsers,
            clientData,
            subject,
            observation,
            returnedBy,
        }) => {
            const { modul_id: current_module_id, user_id } = store.getters["auth/currentUser"];
            if (
                moduleUsers.filter((user) => user.id != loggedInUserId).some((user) => user.id == user_id) && current_module_id == modules[0]
            ) {
                store.commit("AutoAlertReturnedLetters/M_OPEN_ALERT", true);
                store.commit("AutoAlertReturnedLetters/M_CLIENT_DATA", clientData);
                store.commit("AutoAlertReturnedLetters/M_TITLE", title);
                store.commit("AutoAlertReturnedLetters/M_LETTER_DATA", {
                    subject,
                    observation,
                    returnedBy,
                });
            }
        });
}

export const closeReturnedLetterNotification = () => {
    window.socket.bind("client-close-returned-letter-notification", async (data) => {
        const { user_id } = store.getters['auth/currentUser'];
        if (data.user_id == user_id) {
            store.commit("AutoAlertReturnedLetters/M_RESET");
        }
    })
}