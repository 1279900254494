import CorrespondenceServices from "@/views/correspondence/services/correspondence.service.js";

export default {
  namespaced: true,
  state: {
    COUNTERS_CHECKS: {
    },
  },
  getters: {
    G_COUNTER_CHECK(state) {
      return state.COUNTERS_CHECKS
    },

  },
  mutations: {
    M_COUNTER_CHECK(state, payload) {
      state.COUNTERS_CHECKS = payload
    },
  },
  actions: {
    async A_COUNTER_CHECK({ commit }) {
      const { data } = await CorrespondenceServices.getCounterCheck()
      commit("M_COUNTER_CHECK", data)
    },
  },
}
