export default {
  path: "commissions",
  name: "commissions-bookeeping",
  component: () => import(/* webpackChunkName: "BookeepingCommissions" */ "@/views/commons/components/commissions/CommissionsModules.vue"),
  meta: {
    pageTitle: "Commissions",
    breadcrumb: [
      {
        text: "Commissions",
        active: true,
      },
    ],
    tab: "8",
  },
};
