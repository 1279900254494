<template>
  <b-card>
    <b-card-title>
      <h4
        class="font-weight-bolder px-50 border-bottom-secondary border-2 pb-1 w-100"
      >
        Personal Information
      </h4>
    </b-card-title>
    <b-row>
      <!-- First Name -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="FirstName"
          rules="required"
        >
          <b-form-group label="First Name" label-for="first-name">
            <template #label>
              <span class="font-ce"> First Name </span>
            </template>
            <b-form-input
              id="first-name"
              v-model="userData.first_name"
              name="first-name"
              autofocus
              :state="errors[0] ? false : null"
              trim
              @keyup="capitalize('first_name')"
              :disabled="disabledForm"
              class="font-ce"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `First name ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Middle Name -->
      <b-col md="6" lg="3">
        <validation-provider name="Middle Name">
          <b-form-group label="Middle Name" label-for="middle-name">
            <template #label>
              <span class="font-ce"> Middle Name </span>
            </template>
            <b-form-input
              id="middle-name"
              v-model="userData.middle_name"
              trim
              :disabled="disabledForm"
              class="font-ce"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Last Name -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="LastName"
          rules="required"
        >
          <b-form-group label="Last Name" label-for="last-name">
            <template #label>
              <span class="font-ce"> Last Name </span>
            </template>
            <b-form-input
              id="last-name"
              v-model="userData.last_name"
              trim
              :state="errors[0] ? false : null"
              @keyup="capitalize('last_name')"
              :disabled="disabledForm"
              class="font-ce"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Last name ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Email -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Email"
          :rules="`${!hasNotEmailChecked ? 'required|email' : ''}`"
        >
          <b-form-group label="Email" label-for="email">
            <template #label>
              <span class="font-ce"> Email </span>
            </template>
            <b-form-input
              id="email"
              v-model="userData.email"
              trim
              class="font-ce"
              :state="errors[0] ? false : null"
              :disabled="hasNotEmailChecked || disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Email ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
        <span v-if="!disabledForm">
          <b-form-group>
            <b-form-checkbox
              v-model="hasNotEmailChecked"
              class="cursor-pointer"
            >
              Client has not email
            </b-form-checkbox>
          </b-form-group>
        </span>
      </b-col>
      <!-- Programs -->
      <b-col
        md="6"
        lg="3"
        v-if="moduleId != 5 && moduleId != 10 && moduleId != 14"
      >
        <validation-provider name="Programs">
          <b-form-group label="Programs" label-for="programs">
            <template #label>
              <span class="font-ce"> Programs </span>
            </template>
            <v-select
              v-if="!disabledForm"
              v-model="programsList"
              multiple
              label="label"
              input-id="programs"
              :selectable="(option) => option.selectable === true"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="G_PROGRAMS"
              :disabled="disabledForm"
              class="font-ce"
            />
            <div
              v-else
              class="form-group-input2"
              style="background-color: transparent; min-height: 38.34px"
            >
              <b-badge
                v-for="(program, index) in programsList"
                :key="index"
                variant="primary"
                class="mr-1"
                style="font-size: 12px; padding: 7px"
              >
                {{ program.label }}
              </b-badge>
            </div>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Dob -->
      <b-col md="6" lg="3">
        <validation-provider name="DOB">
          <b-form-group label="DOB" label-for="dob">
            <template #label>
              <span class="font-ce"> DOB </span>
            </template>
            <kendo-datepicker
              :class="[
                'w-100 rounded bg-transparent k-picker-custom',
                { 'text-white': isDarkSkin },
              ]"
              :format="'MM/dd/yyyy'"
              v-model="userData.dob"
              v-mask="'##/##/####'"
              class="leads-datepicker font-ce"
              :disabled="disabledForm"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Language -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Language"
          rules="required"
        >
          <b-form-group
            label="Language"
            label-for="language"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce"> Language </span>
            </template>
            <v-select
              v-model="userData.language"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="G_LANGUAGES"
              :clearable="false"
              input-id="language"
              class="font-ce"
              :reduce="(el) => el.value"
              :disabled="disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Language ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Lead State -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Legal Status"
          rules="required"
        >
          <b-form-group
            label="Legal Status"
            label-for="state-lead"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce"> Legal Status </span>
            </template>
            <v-select
              id="state-lead"
              v-model="userData.state_lead"
              :options="G_STATE_LEADS"
              :clearable="false"
              :reduce="(el) => el.id"
              label="label"
              class="font-ce"
              :disabled="disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Legal Status ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
        <b-form-group
          label="Work Status"
          label-class="font-weight-bolder"
          v-if="moduleId == 2"
        >
          <template #label>
            <span class="font-ce"> Work Status </span>
          </template>
          <v-select
            v-model="userData.work_status"
            label="label"
            class="font-ce"
            :reduce="(value) => value.value"
            :options="workStatus.options"
          />
          <!-- :class="{ 'border-danger': errors[0] }" -->
        </b-form-group>
      </b-col>
    </b-row>
    <address-information-lead
      :type-form="typeForm"
      :user-data="userData"
      :blank-user-fields="blankUserFields"
      :disabledForm="disabledForm"
      @onModalTrackingChangeOpen="$emit('onModalTrackingChangeOpen', $event)"
    />
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";

import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import formValidation from "@core/comp-functions/forms/form-validation";

import moment from "moment";
import AddressInformationLead from "./AddressInformationLead.vue";

export default {
  watch: {
    hasNotEmailChecked: {
      handler(val) {
        if (val) {
          this.userData.email = "";
        }
      },
    },
  },
  mounted() {
    if (!this.userData.email && this.typeForm == "editLead") {
      this.hasNotEmailChecked = true;
    }
  },
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    flatPickr,
    vSelect,
    AddressInformationLead,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    disabledForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    userData: {
      type: Object,
      required: true,
    },
    blankUserFields: {
      type: Object,
      required: false,
    },
    typeForm: {
      type: String,
      required: false, // newLead, editLead
    },
  },
  data() {
    return {
      currentDate: "",
      required,
      alphaNum,
      email,
      minDate: new Date(1000, 1, 1),
      maxDate: new Date(3000, 1, 1),
      configFlatPickr: {
        dateFormat: "m/d/Y",
        locale: "en",
        allowInput: true,
      },
      programsList: [],
      hasNotEmailChecked: false,
      workStatus: {
        options: [
          {
            label: "EMPLOYED",
            value: 1,
          },
          {
            label: "SELF EMPLOYED",
            value: 2,
          },
          {
            label: "RETIRED",
            value: 3,
          },
          {
            label: "STUDENT",
            value: 4,
          },
          {
            label: "DISABILITY",
            value: 5,
          },
          {
            label: "UNEMPLOYED",
            value: 6,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_PROGRAMS: "CrmGlobalStore/G_PROGRAMS",
      G_LANGUAGES: "CrmGlobalStore/G_LANGUAGES",
      G_STATE_LEADS: "CrmLeadStore/G_STATE_LEADS",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  setup() {
    const { refFormObserver, getValidationState } = formValidation(() => {});

    return {
      refFormObserver,
      getValidationState,
    };
  },
  created() {
    this.formatInitialData();
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    capitalize(el) {
      const element = this.userData[el];
      this.userData[el] =
        element.substr(0, 1).toUpperCase() + element.substr(1);
    },
    formatInitialData() {
      this.userData.dob = this.userData.dob
        ? moment(this.userData.dob).format("MM/DD/YYYY")
        : "";
      this.programsList = this.userData.programs;
    },
    returnProgramlist() {
      return this.programsList;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl {
  width: 90rem;
}
input.k-input {
  padding: 0.5rem 1rem !important;
  height: auto !important;
}
.k-select {
  background-color: transparent !important;
  background-image: none !important;
}
</style>
<style scoped>
* {
  font-family: "Montserrat", sans-serif !important;
}
.card-title h4 {
  font-size: 22px;
}
.font-ce {
  font-size: 16px;
}
.form-group-input2 {
  padding: 0.438rem 1rem;
  /* background-color: #fff; */
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  width: 100%;
}
</style>
