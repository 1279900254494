export default [
  {
    path: "tracking-list",
    name: "credit-experts-tracking",
    redirect: { name: "credit-experts-tracking-list" },
    component: () => import(/* webpackChunkName: "CreditExpertsTrackingList" */ "@/views/commons/components/tracking-list/views/components/TrackingListTab.vue"),
    meta: {
      pageTitle: "Tracking List",
      permittedRoles: [1, 2, 11, 3],
      breadcrumb: [
        {
          text: "Tracking List",
        },
      ],
    },
    children: [
      {
        path: "in-course",
        component: () => import(/* webpackChunkName: "CreditExpertsTrackingListInCourse" */ "@/views/commons/components/tracking-list/views/components/TrackingInCourseGrid.vue"),
        name: "credit-experts-tracking-list",
        meta: {
          status: 1,
          pageTitle: "Tracking List",
          permittedRoles: [1, 2, 11, 3],
          breadcrumb: [
            {
              text: "In course",
              active: true,
            },
          ],
        },
      },
      {
        path: "completed",
        component: () => import(/* webpackChunkName: "CreditExpertsTrackingListCompleted" */ "@/views/commons/components/tracking-list/views/components/TrackingCompletedGrid.vue"),
        name: "credit-experts-tracking-list-completed",
        meta: {
          status: 2,
          permittedRoles: [1, 2, 11, 3],
          pageTitle: "Tracking List",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
        },
      },
      {
        path: "pending",
        component: () => import(/* webpackChunkName: "CreditExpertsTrackingListPending" */ "@/views/commons/components/tracking-list/views/components/TrackingPending.vue"),
        name: "credit-experts-tracking-list-pending",
        meta: {
          status: 3,
          permittedRoles: [1, 2, 11, 3],
          pageTitle: "Tracking List",
          breadcrumb: [
            {
              text: "Pending",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
