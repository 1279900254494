export const requestWpTabs = (abv) => {
    return [
        {
            path: "pending",
            name: `${abv}-wp-pending`,
            component: () =>
                import(
                    "@/views/commons/components/request-workplan/views/components/RequestWorkplanTable.vue"
                ),
            meta: {
                pageTitle: "Request Workplan",
                tabName: "pending",
                breadcrumb: [
                    {
                        text: "Pending",
                        active: true,
                    },
                ],
                status: 1,
                permittedRoles: [1, 2, 3, 17, 21, 22, 23],
            },
        },
        {
            path: "completed",
            name: `${abv}-wp-completed`,
            component: () =>
                import(
                    "@/views/commons/components/request-workplan/views/components/RequestWorkplanTable.vue"
                ),
            meta: {
                pageTitle: "Request Workplan",
                tabName: "completed",
                breadcrumb: [
                    {
                        text: "Completed",
                        active: true,
                    },
                ],
                status: 7,
                permittedRoles: [1, 2, 3, 17, 21, 22, 23],
            },
        },
        {
            path: "to-revision",
            name: `${abv}-wp-to-revision`,
            component: () =>
                import(
                    "@/views/commons/components/request-workplan/views/components/RequestWorkplanTable.vue"
                ),
            meta: {
                pageTitle: "Request Workplan",
                tabName: "to-revision",
                breadcrumb: [
                    {
                        text: "To Revision",
                        active: true,
                    },
                ],
                status: 6,
                permittedRoles: [1, 2, 3, 17, 21, 22, 23],
            },
        },
        {
            path: "deleted",
            name: `${abv}-wp-deleted`,
            component: () =>
                import(
                    "@/views/commons/components/request-workplan/views/components/RequestWorkplanTable.vue"
                ),
            meta: {
                pageTitle: "Request Workplan",
                tabName: "Deleted",
                breadcrumb: [
                    {
                        text: "Deleted",
                        active: true,
                    },
                ],
                status: 8,
                permittedRoles: [1, 2, 3, 17, 21, 22, 23],
            },
        },
    ]
}