<template>
  <div class="img-container"
  > 
    <div class="d-flex justify-content-center align-items-center">

      <div
        ref="zoomContainer"
        class="zoom-container"
        :style="{ cursor: isDragging ? 'grabbing' : 'grab' }"

        @dblclick="resetZoom"
      >
        <b-img
          ref="image"
          :src="getUrlImage"
          fluid
          class="initial-image-size zoomable-image"
          @wheel="handleWheel"
        />
      </div>
    </div>  
    <div class="options-top">
      <div style="margin: 2rem;"> 
        <button
          v-b-tooltip.hover="'Zoom in.'"
          class="btn-option mr-1"
          @click="zoomIn"
        >
          <feather-icon
            icon="ZoomInIcon"
            size="25"
          />
        </button>
        <button
          v-b-tooltip.hover="'Zoom out.'"
          class="btn-option mr-1"
          @click="zoomOut"
        >
          <feather-icon
            icon="ZoomOutIcon"
            size="25"
          />
        </button>
        <button
          v-b-tooltip.hover="'Rotate left.'"
          class="btn-option mr-1"
          @click="rotateLeft"
        >
          <feather-icon
            icon="RotateCcwIcon"
            size="25"
          />
        </button>
        <button
          v-b-tooltip.hover="'Rotate right.'"
          class="btn-option mr-1"
          @click="rotateRight"
        >
          <feather-icon
            icon="RotateCwIcon"
            size="25"
          />
        </button>
        <button
          v-b-tooltip.hover="'Download.'"
          class="btn-option mr-1"
          style="background: #cddc39"
          @click="downloadFile()"
        >
          <feather-icon
            icon="DownloadIcon"
            size="25"
          />
        </button>
        <button
          v-b-tooltip.hover="'Close.'"
          class="btn-option mr-1"
          style="background: #e91e63"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="25"
          />
        </button> 
      </div>
    </div>
    <div class="images-bottom" v-if="showBottom" > 
      <div   :class="item.route==urlImage?'img-selected':'img-item'"  v-for="(item,index) in objectImages" :key="index" >  
          <b-img  
              :src="item.route"
              fluid
              class="array-image-size"
              
              @click="selectedImg(item.route)"
            /> 
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showBottom: {
      type: Boolean,
      default: true,
    },
    objectImages: {
      type: Array,
      default: '',
    },
    route: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      control: false,
      zoomLevel: 1,
      rotation: 0,
      isDragging: false,

      initialMouseX: 0,
      initialMouseY: 0,
      initialTranslateX: 0,
      initialTranslateY: 0,
      urlImage:null,
    };
  },
  computed:{
    getUrlImage(){
      return this.urlImage; 
    }
  },
  mounted() {
    this.control = true;
    this.urlImage = this.route;
  },

  methods: {
    close() {
      this.$emit('hidden');
    },
    downloadFile() {
      const link = document.createElement('a');
      link.href = this.route;
      link.target = '__blank';
      link.download = 'image.jpg'; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    zoomIn() {
      if (this.zoomLevel < 2.5) {
        this.zoomLevel += 0.2;  
        this.updateImageSize();
      }
    },
    zoomOut() {
      if (this.zoomLevel > 0.5) {
        this.zoomLevel -= 0.2;  
        this.updateImageSize();
      }
    },
 
    rotateLeft() {
      this.rotation -= 90;
      this.updateImageTransform();
    },

    rotateRight() {
      this.rotation += 90;
      this.updateImageTransform();
    },

    updateImageTransform() {
      this.$refs.image.style.transform = `scale(${this.zoomLevel}) rotate(${this.rotation}deg)`;
    },

    updateImageSize() {
      this.$refs.image.style.transform = `scale(${this.zoomLevel})  rotate(${this.rotation}deg)`;
    },

    handleWheel(event) {
      event.preventDefault(); 
      const { zoomContainer } = this.$refs;
      const { image } = this.$refs;
      const zoomFactor = 0.1;  

      let { zoomLevel } = this;
      zoomLevel += event.deltaY > 0 ? -zoomFactor : zoomFactor;
      zoomLevel = Math.min(Math.max(zoomLevel, 0.5), 2.5); 

      this.zoomLevel = zoomLevel; 
      const { clientX, clientY } = event;
      const containerRect = zoomContainer.getBoundingClientRect();
      const offsetX = clientX - containerRect.left;
      const offsetY = clientY - containerRect.top;
      const deltaX = (offsetX - zoomContainer.clientWidth / 2) / zoomContainer.clientWidth;
      const deltaY = (offsetY - zoomContainer.clientHeight / 2) / zoomContainer.clientHeight;

      const newTranslateX = -deltaX * (image.clientWidth * zoomLevel - zoomContainer.clientWidth);
      const newTranslateY = -deltaY * (image.clientHeight * zoomLevel - zoomContainer.clientHeight);

      image.style.transform = `scale(${zoomLevel}) translate(${newTranslateX}px, ${newTranslateY}px)`;
    },

    startDragging(event) {
      this.isDragging = true;
      this.initialMouseX = event.clientX;
      this.initialMouseY = event.clientY;
      const { transform } = this.$refs.image.style;
      const match = transform.match(/translate\(([^,]+)px,\s([^,]+)px\)/);
      if (match) {
        this.initialTranslateX = parseFloat(match[1]);
        this.initialTranslateY = parseFloat(match[2]);
      } else {
        this.initialTranslateX = 0;
        this.initialTranslateY = 0;
      }
    },

    handleDragging(event) {
      if (this.isDragging) {
        const currentMouseX = event.clientX;
        const currentMouseY = event.clientY;
        const deltaX = currentMouseX - this.initialMouseX;
        const deltaY = currentMouseY - this.initialMouseY;
        const newTranslateX = this.initialTranslateX + deltaX;
        const newTranslateY = this.initialTranslateY + deltaY;
        this.$refs.image.style.transform = `scale(${this.zoomLevel}) translate(${newTranslateX}px, ${newTranslateY}px)`;
      }
    },

    stopDragging() {
      this.isDragging = false;
    },

    resetZoom() {
      this.zoomLevel = 1;
      this.updateImageSize();
    },
    selectedImg(route){
      this.urlImage = route;
    },
  
  },
};
</script>

<style scoped>
.custom-modal {
  transition: none !important;
  display: flex;
  align-items: center;
  justify-content: center;

}

.custom-modal.fade.show {
  opacity: 1;
  transform: scale(1) !important;
  transition: opacity 0.3s, transform 0.3s;
}

.custom-modal.fade:not(.show) {
  opacity: 0;
  transform: scale(0.9) !important;
  transition: opacity 0.3s, transform 0.3s;
}

.zoomable-image {
  transition: transform 0.3s;  
  max-width: 100%;  
  max-height: 100vh;  
  width: auto;  
  height: auto;  
}

.zoom-container {
  overflow: hidden;
  position: relative;
  max-width: 80vw;
  max-height: 80vh;
  cursor: grab;
}

.zoom-container:active {
  cursor: grabbing;
}
 
.images-bottom{ 
  position: fixed;
  bottom: 0;
  z-index: 1030;
  width: 100%;
  background: transparent;
  white-space: nowrap;
  overflow:overlay;
  padding: 10px;
  left:0;
  text-align: center;
}

.initial-image-size {
  width: 990px; 
  max-height: 700px;
  object-fit: scale-down;
}

.btn-option{
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: #9bcefa;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-option:hover{
  background: #2980b9;

}

.hover-btn:active {
  background-color: #216a94;
}

.array-image-size{
    width: 150px;
    height: 150px;
    object-fit: cover; 
}

.img-item{
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 2px solid gray;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 5px;
    cursor: pointer; 

}

.img-item:hover{
  border: 2px solid blue;
  width: 110px;
  height: 110px;
}

.img-selected{
  width: 80px;
  height: 80px; 
  display: inline-block; 
  border: 2px solid purple;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 5px;
  cursor: pointer; 
}
.options-top{
    position: fixed;
    top: 0;
    z-index: 1030;
    right:0;
}
.img-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000e6;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

</style>
