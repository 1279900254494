import { requestWpTabs } from "@/views/commons/components/request-workplan/router/request-workplan.tabs.js";

export default [
    {
        path: "request-workplan",
        name: "request-workplan-boost-credit",
        redirect: { name: "bc-wp-pending" },
        meta: {
            pageTitle: "Request Workplan",
            breadcrumb: [
                {
                    text: "Request Workplan",
                    active: true,
                },
            ],
            routePending: "bc-wp-pending",
            routeCompleted: "bc-wp-completed",
            routeToRevision: "bc-wp-to-revision",
        },
        component: () =>
            import(
                "@/views/commons/components/request-workplan/RequestWorkplanMain.vue"
            ),
        children: requestWpTabs('bc')
    }
]