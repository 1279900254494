import DashboardRouter from "@/views/specialists/sub-modules/analyst-department/views/dashboard/router/specialists-dashboard.router";
import SettingsRouter from "@/views/specialists/sub-modules/analyst-department/views/settings/router/settings.router";
import RequestWorkplanRouter from "@/views/specialists/sub-modules/analyst-department/views/request-workplan/router/request-workplan.router";
import ClientsRouter from "@/views/specialists/sub-modules/analyst-department/views/clients/clients-list/router/clients-list.router";
import LeadsRouter from "@/views/specialists/sub-modules/analyst-department/views/leads/router/leads.router";
import LetterRoundsRouter from "@/views/specialists/sub-modules/analyst-department/views/letter-round/router/letter-round.router.js";
import DirectRoundRouter from "@/views/specialists/sub-modules/analyst-department/views/direct-round/direct-round.router";
import UpdateRequestRouter from "@/views/specialists/sub-modules/analyst-department/views/update-request/router/update-request.router";
import AnalystDepartmentAttendance from "@/views/specialists/sub-modules/analyst-department/views/attendance/analyst-department-attendance.router.js";
import CustomerTicketsRouter from "@/views/specialists/sub-modules/analyst-department/views/clients/customer-tickets/router/customer-tickets.router.js";
import PaymentReportRouter from "@/views/specialists/sub-modules/analyst-department/views/payment-report/router/payment-report.router.js";
import ClaimsRouter from "@/views/specialists/sub-modules/analyst-department/views/claims/router/claims.router.js";
import CourtInfoRouter from "@/views/specialists/sub-modules/analyst-department/views/court-info/router/court-info.router.js";
//import ProjectsRouter from "@/views/specialists/sub-modules/analyst-department/views/projects/router/projects.router.js";
// import ApplicationsRouter from "@/views/specialists/sub-modules/analyst-department/views/applications/routes/index.js";
import ApplicationsRouter from "@/views/specialists/sub-modules/analyst-department/views/services/credit-cards/credit-cards.router.js";
import HelpdeskRouter from "@/views/specialists/sub-modules/analyst-department/views/support/helpdesk/helpdesk.router";
import InquiriesRouter from "@/views/specialists/sub-modules/analyst-department/views/support/inquiries/inquiries.router";
import ProductRequestRouter from "@/views/specialists/sub-modules/analyst-department/views/support/logistic-request/request.router";
import EmployeeClaimsRouter from "@/views/specialists/sub-modules/analyst-department/views/rrhh/staff-claims/router/employee-claims.router";
import MOFRouter from "@/views/specialists/sub-modules/analyst-department/views/rrhh/mof/manual-of-functions.router";
import RecruitmentProcessRouter from "@/views/specialists/sub-modules/analyst-department/views/rrhh/recruitment-process/specialists-recruitment_process.router";
import CallLogRouter from "@/views/specialists/sub-modules/analyst-department/views/ring-central/call-log/router/call-log.router.js";
import DashboardCallsRouter from "@/views/specialists/sub-modules/analyst-department/views/ring-central/dashboard-calls/router/dashboard-calls.router.js";
import ChargeBack from "@/views/specialists/sub-modules/analyst-department/views/charge-back/charge-back.router.js";
import OtherPayments from "@/views/specialists/sub-modules/analyst-department/views/other-payments/other-payments.router.js";
import Payments from "@/views/specialists/sub-modules/analyst-department/views/payments/payments.router.js";
import FileManagerRouter from "@/views/specialists/sub-modules/analyst-department/views/file-manager/file-manager-ad.router.js";
import CallImpressionRoute from "@/views/specialists/sub-modules/analyst-department/views/call-impression/router/call-impression.router.js";

const routes = [
  {
    path: "/specialists/analyst-department",
    redirect: { name: "analyst-department-dashboard" },
    component: () =>
      import(
        /* webpackChunkName: "AnalystDepartmentMain" */
        "@/views/specialists/sub-modules/analyst-department/AnalystDepartmentMain.vue"
      ),
    meta: {
      module: 28,
      parentModule: 11,
      program: 7,
    },
    children: [
      ...DashboardRouter,
      ...SettingsRouter,
      ...ClientsRouter,
      ...CustomerTicketsRouter,
      ...PaymentReportRouter,
      ...ClaimsRouter,
      ...LeadsRouter,
      ...RequestWorkplanRouter,
      ...LetterRoundsRouter,
      ...DirectRoundRouter,
      ...UpdateRequestRouter,
      ...AnalystDepartmentAttendance,
      ...CourtInfoRouter,
      //...ProjectsRouter,
      ...ApplicationsRouter,
      // ...AppointmentsRouter,
      // ...OtherServicesRouter,
      // ...PotentialRouter,
      ...HelpdeskRouter,
      ...InquiriesRouter,
      ...ProductRequestRouter,
      ...EmployeeClaimsRouter,
      ...MOFRouter,
      ...RecruitmentProcessRouter,
      ...CallLogRouter,
      ...DashboardCallsRouter,
      ...ChargeBack,
      ...OtherPayments,
      ...Payments,
      FileManagerRouter,
      ...CallImpressionRoute,
    ],
  },
];

export default routes;
