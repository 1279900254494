<template>
  <div>
    <v-select
      v-model="filters.model"
      :options="filteredOptions"
      :reduce="(obj) => obj.value"
    >
      <template #selected-option="{ label, icon, color }">
        <feather-icon :icon="icon" size="12" :style="styleIcon(color)" />
        <span style="margin-left: 0.8rem">{{ label }}</span>
      </template>

      <template #option="{ label, icon, color }">
        <feather-icon :icon="icon" size="12" :style="styleIcon(color)" />
        <span style="margin-left: 0.8rem">{{ label }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    filters: {
      type: Object | Array,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    filteredOptions() {
      return this.filters.options.filter((option) => {
        if (
          [3, 6, 20, 21, 22, 23, 24, 26, 28, 29, 30].includes(
            this.currentUser.modul_id
          )
        ) {
          return option.filter === 2 || option.filter == null;
        } else if (
          [2, 4, 9, 11, 16, 25, 18].includes(this.currentUser.modul_id)
        ) {
          return (
            (![2, 4, 6].includes(option.value) && option.filter === 1) ||
            option.filter === 2 ||
            option.filter == null
          );
        } else {
          return option.filter === 1 || option.filter == null;
        }
      });
    },
  },
  methods: {
    styleIcon(color_status) {
      return (
        "color: " +
        color_status +
        "; background-color: " +
        color_status +
        "; border-radius: 50%;"
      );
    },
  },
  created() {
    //
  },
};
</script>
