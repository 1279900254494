<template>
  <div>
    <b-modal
      v-model="ownControl"
      size="xl"
      hide-footer
      scrollable
      header-bg-variant="transparent border-bottom border-bottom-2"
      header-class="p-0"
      @hidden="closeModal"
      no-enforce-focus
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center container-title">
            <span>{{ `History Notes (Lead: ${lead.lead_name})` }}</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModal"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <b-table
        style="overflow-x: hidden"
        :items="historyNotes"
        :fields="historyNotesFields"
        small
        fixed
        class="font-small-3"
        responsive
        bordered
        sticky-header="70vh"
      >
        <template #cell(created_by_name)="data">
          <div>
            <div>
              {{ data.item.created_by_name }} |
              {{ data.item.created_at | myGlobalWithHour }}
            </div>
            <div
              v-if="data.item.queue_status == 'PENDING'"
              style="margin-top: 5px"
            >
              <feather-icon size="15" icon="LoaderIcon" class="text-warning" />
              <span class="pl-1" style="font-weight: 400">Call in Queue</span>
            </div>
          </div>
        </template>
        <template #cell(text)="data">
          <div
            v-if="data.item.transcription_status != 'IN_PROGRESS'"
            class="p-1"
            style="max-height: 350px !important; overflow-y: auto"
            v-html="data.item.text"
          ></div>
          <div v-else class="mt-1">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
          </div>
        </template>
        <!-- <template #cell(original_text)="data">
          <div
            v-if="data.item.transcription_status != 'IN_PROGRESS'"
            class="p-1"
            style="max-height: 350px !important; overflow-y: auto"
            v-html="data.item.original_text"
          ></div>
          <div v-else class="mt-1">
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="85%"></b-skeleton>
          </div>
        </template> -->
        <template #cell(important)="data">
          <b-row>
            <b-col class="d-flex align-items-center justify-content-center">
              <b-form-radio v-model="importantNote" :value="data.item" />
            </b-col>
          </b-row>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="(isCeo || isChief) && data.item.transcription_status != null"
            class="m-1"
            size="sm"
            variant="outline-primary"
            @click="sendToTranscribe(data.item)"
            :disabled="data.item.transcription_status == 'IN_PROGRESS'"
          >
            Regenerate Summary
          </b-button>
          <b-button
            v-if="data.item.versions > 0"
            class="m-1"
            size="sm"
            variant="outline-primary"
            @click="showAllNotesVersionModal(data.item)"
          >
            <feather-icon icon="ListIcon" title="Show previous notes" />
          </b-button>
        </template>
      </b-table>
    </b-modal>

    <notes-version-modal
      v-if="showNotesVersionModal"
      :note-id="noteId"
      :reload="reload"
      @close="showNotesVersionModal = false"
    />
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
import NotesVersionModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/NotesVersionModal.vue";
import NotesService from "@/views/crm/services/notes/index.js";

import { mapGetters } from "vuex";
export default {
  name: "HistoryNotesModal",
  props: {
    lead: {
      required: true,
      type: Object,
    },
  },
  components: {
    NotesVersionModal,
  },
  data() {
    return {
      ownControl: false,
      historyNotes: [],
      historyNotesFields: [
        {
          key: "text",
          label: "Note",
          thStyle: { width: "65%" },
        },
        // {
        //   key: "original_text",
        //   label: "Original Note",
        //   thStyle: { width: "35%" },
        // },
        {
          key: "created_by_name",
          label: "Created by",
          thStyle: { width: "25%" },
        },
        {
          key: "important",
          label: "Important",
          class: "text-center",
          thStyle: { width: "10%" },
        },
      ],
      importantNote: {},
      showNotesVersionModal: false,
      noteId: null,
      reload: 0,
    };
  },
  watch: {
    async importantNote(newValue, oldValue) {
      if (!oldValue) return;

      if (oldValue.id) {
        const resolve = await this.showConfirmSwal();
        if (resolve.value) {
          console.log(newValue);
          const response = await amgApi.post("/note/note/important-note", {
            id: newValue.id,
            lead_id: this.lead.id,
            user_id: parseInt(newValue.created_by, 10),
          });
          if (response.status === 200) {
            this.$emit("newImportant", response.data[0]);
            this.closeModal();
          }
        }
      }
    },
  },
  mounted() {
    if (this.isCeo || this.isChief) {
      // change historyNotesFields where width is 40 to 30
      this.historyNotesFields = this.historyNotesFields.map((field) => {
        if (field.thStyle.width === "40%") {
          field.thStyle.width = "30%";
        }
        return field;
      });
      this.historyNotesFields.push({
        key: "actions",
        label: "Actions",
        class: "text-center",
        thStyle: { width: "10%" },
      });
    }
  },
  async created() {
    this.bindUpdateManualNotes();
    await this.getHistoryLeadNotes();
    this.importantNote = this.historyNotes.filter(
      (val) => val.important === 1
    )[0];
    this.ownControl = true;
  },
  beforeDestroy() {
    try {
      window.socket.unbind("update-manual-notes");
    } catch (error) {
      console.log("update-manual-notes", error);
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async showAllNotesVersionModal(note) {
      try {
        this.showNotesVersionModal = true;
        this.noteId = note.id.toString();
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async sendToTranscribe(note) {
      try {
        const { isConfirmed, value } = await this.showSimpleInputSwal({
          input: "text",
          title: "Why do you want to regenerate the summary?",
          inputPlaceholder: "Ex: The summary is not correct",
        });

        if (!isConfirmed) return;

        this.addPreloader();

        const { data } = await ClientsOptionsServices.sendToTranscribe({
          note_id: note.id,
          reason: value,
          created_by: this.currentUser.user_id,
        });
        this.showTimedToast({
          title: data.message,
          timeout: 5000,
        });
      } catch (e) {
        this.showErrorSwal(e.response.data.message);
      } finally {
        this.removePreloader();
      }
    },
    async bindUpdateManualNotes() {
      try {
        window.socket.bind("update-manual-notes", async ({ data }) => {
          const found = this.historyNotes.find(
            (note) => note.id == data.note_id
          );

          if (found) {
            let index = this.historyNotes.findIndex(
              (note) => note.id == data.note_id
            );

            if (index !== -1) {
              let res = await this.getLeadNoteById(data.note_id);

              if (res.data.length == 0) return;

              let new_data = res.data[0];

              this.$set(this.historyNotes, index, new_data);
              this.reload = this.reload + 1;
              console.log("updated");
            }
          }
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    closeModal() {
      this.ownControl = false;
      this.$emit("close");
    },
    async getHistoryLeadNotes() {
      try {
        const response = await amgApi.post("/note/note/get-lead-notes", {
          lead_id: this.lead.id,
        });
        if (response.status === 200) {
          this.historyNotes = response.data;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getLeadNoteById(id) {
      try {
        const response = await NotesService.getLeadNotesById({ note_id: id });
        return response;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  .container-title {
    width: 100%;
    height: 100%;

    span {
      font-size: 1.3rem;
      font-weight: bold;
      margin-left: 8px;
      color: #ffffff;
    }
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
</style>
