export default{
  path: "calendar",
  name: "calendar-bookeeping",
  component: () => import(/* webpackChunkName: "BookeepingCalendar" */ "@/views/bookeeping/views/calendar/calendar.vue"),
  meta: {
    pageTitle: "Calendar",
    breadcrumb: [
      {
        text: "Calendar",
      },
    ],
    permittedRoles: [1,2],
  },
};

  