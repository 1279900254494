
export default {
    namespaced: true,
    state: {
        S_REFRESH: false,
    },
    mutations: {
        M_REFRESH: (state) => {
            state.S_REFRESH = !state.S_REFRESH;
        }
    },
    actions: {
        A_REFRESH: ({ commit }) => {
            commit('M_REFRESH');
        }
    },
};
