// IMPORT LIBRARIES
import Vue from 'vue'

const state = {
  SalesMadeNavigation: 0,
}
const getters = {
}
const mutations = {
}
const actions = {
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}
