export default [
    {
        key: "lead_client_name",
        label: "name",
    },
    {
        key: 'derived_from',
        label: 'Derived From',
        thClass: 'text-center',
        tdClass: 'text-center',
    },
    {
        key: "sale_process_status",
        label: "sale process status",
        thClass: 'text-center',
        tdClass: 'text-center',
    },
    {
        key: "catcher_by",
        label: "Catcher",
    },
    {
        key: "seller_by",
        label: "Seller",
    },
    {
        key: "action",
        label: "Actions",
        thClass: 'text-center',
        tdClass: 'text-center',
    }
]