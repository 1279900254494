import { amgApi } from "@/service/axios";
class DashboardServices {
    async getDepartments(){
        try{
            return await amgApi.post('specialists/dashboard-financial/get-departments');
        }catch(error){
            throw error;
        }
    }
    async getIndicators(body){
        try{
            const data = await amgApi.post('specialists/dashboard-financial/get-indicators', body);
            return data;
        }catch(error){
            throw error;
        }
    }
    async getAgentProductivity(body){
        try{
            const data = await amgApi.post('specialists/dashboard-financial/get-agents-productivity', body);
            return data;
        }catch(error){
            throw error;
        }
    }
    async getDepartmentProductivity(body){
        try{
            const data = await amgApi.post('specialists/dashboard-financial/get-departments-productivity', body);
            return data;
        }catch(error){
            throw error;
        }
    }
    async getUserSpecialists(){
        try{
            const data = await amgApi.post('specialists/dashboard-financial/get-specialists');
            return data;
        }catch(error){
            throw error;
        }
    }
    async getDetailsIndicator(body){
        try{
            const data = await amgApi.post('specialists/dashboard-financial/get-details-indicator', body);
            return data;
        }catch(error){
            throw error;
        }
    }
    async getSettlementDashboard(body){
      try{
          const data = await amgApi.post('specialists/dashboard-financial/get-settlement-dashboard', body);
          return data;
      }catch(error){
          throw error;
      }
    }

    async getAvailableSources(body){
      try{
          const data = await amgApi.post('specialists/dashboard-financial/get-available-sources', body);
          return data;
      }catch(error){
          throw error;
      }
    }

    async getReportModalDash(body){
      try{
          const data = await amgApi.post('specialists/dashboard-financial/get-report-modal-dash', body);
          return data;
      }catch(error){
          throw error;
      }
    }

    async getLeadsForSettlementDash(body){
      try{
          const data = await amgApi.post('specialists/dashboard-financial/get-leads-for-settlement-dash', body);
          return data;
      }catch(error){
          throw error;
      }
    }
}
export default new DashboardServices();
