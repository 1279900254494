const { amgApi } = require("@/service/axios");

class PendingPaymentsService {
  async getPendingPayments(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/get-pending-payments",
        params
      );
      return data;
    } catch (error) {
      console.log("Error getPendingPayments");
    }
  }
  async getNotProcessPayments(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/get-not-process-payments",
        params
      );
      return data
    } catch (error) {
      throw error
    }
  } async deleteNotProcessPayments(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/delete-not-process-payments", params
      );
      return data
    } catch (error) {
      throw error
    }
  }
  async insertToNotProcess(params) {
    try {
      const data = await amgApi.post('credit-experts-digital/financial/insert-to-not-process-payments', params);
      return data;
    } catch (error) {
      console.log("Error Inserttonotproc")
    }
  }

  async updateClientBalance(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/update-client-balance",
        params
      );
      return data;
    } catch (error) {
      console.log("Error updateClientBalance");
    }
  }

  async insertOtherCollection(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/insert-other-collection",
        params
      );
      return data;
    } catch (error) {
      console.log("Error insertOtherCollection");

      return error
    }
  }
  async makeZeroPayment(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/ce-digital-make-zero-payment",
        params
      );
      return data;
    } catch (error) {
      console.log("Error makeZeroPayment");
    }
  }
  async searchPendingPayments(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/search-pending-payments",
        params
      );
      return data;
    } catch (error) {
      console.log("Error searchPendingPayments");
    }
  }
  async sendToTheEnd(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/send-to-the-end-pending-payments",
        params
      );
      return data;
    } catch (error) {
      console.log("Error sendToTheEnd");
    }
  }
  async getNotProcessReasonsByClientAccountId(params) {
    try {
      const data = await amgApi.post(
        "credit-experts-digital/financial/get-not-process-reasons-by-client-account-id",
        params
      );
      return data;
    } catch (error) {
      console.log("Error getNotProcessReasonsByClientAccountId");
    }
  }

  async getPendingPaymentsV2(params) {
    try {
      const data = await amgApi.post(
        "/commons/pending-payments/index",
        params
      );
      return data;
    } catch (error) {
      console.log("Error getPendingPaymentsV2");
    }
  }

  async getPendingPaymentsCounters(params) {
    try {
      const data = await amgApi.post(
        "/commons/pending-payments/counter/index",
        params
      );
      return data;
    } catch (error) {
      console.log("Error getPendingPaymentsCounters");
    }
  } 
}

export default new PendingPaymentsService();
