<template>
  <div
    v-b-tooltip.hover.b.bottom="content.file_name"
    class="w-100 p-1 mb-1 d-flex container flex-column custom-container"
    :class="isDarkSkin ? 'custom-container' : 'custom-container-light'"
    @contextmenu.prevent="contentRightClicked"
  >
    <div class="row d-flex justify-content-between mb-1">
      <div class="col-10 d-flex align-items-center">
        <b-form-checkbox
          v-if="content.type != 'Folder'"
          v-model="content.check"
          @change="onCheckFile()"
        />
        <feather-icon
          size="20"
          :icon="!content.extension ? 'FolderIcon' : 'FileIcon'"
          class="mr-50"
        />
        <span
          v-if="content.editName"
          class="text-truncate"
          style="width: 160px"
          :id="`rename-button-${content.id}`"
          >{{ content.file_name }}
        </span>
      </div>
      <div class="col-2 d-flex align-self-end clickable justify-content-end">
        <b-dropdown
          size="sm"
          variant="link"
          toggle-class="text-decoration-none p-0"
          no-caret
        >
          <template #button-content>
            <feather-icon size="22" icon="MoreVerticalIcon" />
          </template>
          <b-dropdown-item href="#" @click="downloadFile"
            ><feather-icon class="mr-1" icon="DownloadIcon" />
            Download</b-dropdown-item
          >
          <b-dropdown-item href="#" @click="openTooltipEditName"
            ><feather-icon
              class="mr-1"
              icon="Edit3Icon"
            />Rename</b-dropdown-item
          >
          <b-dropdown-item href="#" @click="deleteFile"
            ><feather-icon
              class="mr-1 text-danger"
              icon="Trash2Icon"
            />Delete</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>
    <div class="row px-2">
      <div
        class="col d-flex align-items-center justify-content-center"
        :class="isDarkSkin ? 'custom-folder' : 'custom-folder-light'"
        @click="content.type != 'Folder' ? downloadFile() : clickFile()"
      >
        <feather-icon
          v-if="!content.extension"
          class="custom-color-folder"
          size="40"
          icon="FolderIcon"
        />
        <img v-else :src="imageFile(content.extension)" width="50" />
      </div>
    </div>
  </div>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "FileComponent",
  props: {
    content: {
      required: true,
      type: Object,
    },
    folder: {
      required: false,
    },
    dataFile: {
      required: false,
    },
    onlyRead: {
      required: true,
    },
    isCheckedAll: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit: false,
      newName: "",
      showTooltip: false,
      tooltipVisible: false,
      originalName: null,
      selectFiles: [],
    };
  },
  methods: {
    contentRightClicked() {
      this.$refs.dropdown.show();
    },
    enableRenameFile() {
      this.newName = this.content.file_name;
      this.edit = true;
      this.$emit("edit", true);
    },
    async deleteFile(file, isFolder) {
      try {
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          this.addPreloader();
          let data = [];
          if (isFolder) {
            data = await ClientsOptionsServices.deleteFolder({
              id: this.content.id,
              user_id: this.currentUser.user_id,
            });
          } else {
            data = await ClientsOptionsServices.deleteFile({
              id: this.content.id,
              user_id: this.currentUser.user_id,
            });
          }
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal("File has been deleted successfully");
            this.$emit("deleteFile", this.content);
          }
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async changeStatus() {
      const params = {
        id: this.content.id,
        status: this.content.status === "Private" ? "Public" : "Private",
      };
      const response = await amgApi.post(
        "/file-manager/change-status-file-folder",
        params
      );
      if (response.status === 200) {
        this.$emit("refresh");
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      }
    },
    shareFile() {
      this.$emit("shareFile", this.content);
    },
    clickFile() {
      this.edit = false;
      this.$emit("contentClicked", this.content);
    },
    imageFile(extension) {
      switch (extension) {
        case "jpg":
        case "png":
        case "gif":
          return "/assets/images/drag-drop/ext_files/jpg.png";
        case "xls":
        case "xlsx":
          return "/assets/images/drag-drop/ext_files/excel.png";
        case "html":
          return "/assets/images/drag-drop/ext_files/html.png";
        case "pdf":
          return "/assets/images/drag-drop/ext_files/pdf.png";
        default:
          return "/assets/images/drag-drop/ext_files/archivodesc.png";
      }
    },
    downloadFile() {
      window.open(this.content.route_pdf, "_blank");
    },
    updateName() {
      this.content.editName = false;
    },
    selectText() {
      this.$refs.textInput.select();
    },
    async openTooltipEditName() {
      const validateName = await this.$swal({
        title: "Update Name",
        input: "text",
        inputValue:
          this.content.type !== "Folder"
            ? this.content.file_name.split(".").slice(0, -1).join(".")
            : this.content.file_name,
        showCancelButton: true,
        confirmButtonText: "Save",
        cancelButtonText: "Cancel",

        inputValidator: (file_name) => {
          return !file_name ? "Please enter your observation" : undefined;
        },
      });
      if (validateName.value) {
        try {
          this.addPreloader();
          const params = {
            file: this.originalName,
            route: this.content.route,
            newfile: validateName.value,
            id: this.content.id,
            user_id: this.currentUser.user_id,
            account: this.$route.params.idClient
              ? this.$route.params.idClient
              : this.dataFile.client_account_id,
            extension: this.content.extension,
            folder_name: this.folder.file_name,
            folder_id: this.folder.id,
            newfolder: validateName.value,
            folde: this.originalName,
            program_id: this.client.program_id
              ? this.client.program_id
              : this.dataFile.program_id,
          };

          this.content.type != "Folder"
            ? await ClientsOptionsServices.renameFile(params)
            : await ClientsOptionsServices.renameFolder(params);
          this.showToast(
            "success",
            "top-right",
            "Congratulations",
            "CheckIcon",
            "Renamed successfully"
          );
          this.$emit("refresh");
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    onCheckFile() {
      this.$emit("pushFiles", this.content);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      updateLetterTable:
        "SpecialistsDigitalRoundLettersStore/G_UPDATE_LETTER_TABLE",
    }),
  },
  created() {
    this.originalName = this.content.file_name;
  },
  watch: {
    isCheckedAll(value) {
      if (value) {
        this.content.check = true;
      } else {
        this.content.check = false;
      }
    },

    "content.file_name": function (value) {
      this.originalName = value;
    },
  },
};
</script>

<style scoped>
.text {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.custom-folder {
  background: rgba(192, 192, 192, 1);
  height: 160px !important;
  border-radius: 10px;
}
.custom-folder-light {
  background: rgba(210, 219, 242, 1);
  height: 160px !important;
  border-radius: 10px;
}

.custom-container {
  background: rgb(17, 17, 19);
  border-radius: 10px;
}

.custom-container-light {
  background: rgb(255, 255, 255);
  border-radius: 10px;
}

.custom-color-folder {
  color: rgba(35, 42, 70, 0.8);
}
</style>
