export default [
  {
    path: "bookeeping-pending-payments",
    name: "bookeeping-pending-payments",
    redirect: { name: "bookeeping-pending-payments-automatic-declined" },
    component: () =>
      import(
        /* webpackChunkName: "bookeepingPendingPayments" */ "@/views/commons/pending-payments/PendingPayments.vue"
      ),
    meta: {
      pageTitle: "Pending Payments",
      breadcrumb: [
        {
          text: "Pending Payments",
          active: true,
        },
      ],
      program_id: 5,
    },
    children: [
      {
        path: "automatic-declined",
        name: "bookeeping-pending-payments-automatic-declined",
        component: () =>
          import(
            /* webpackChunkName: "bookeepingPendingPaymentsOfTheMonth" */ "@/views/commons/pending-payments/TabAutomaticDeclined.vue"
          ),
        redirect: {
          name: "bookeeping-pending-payments-automatic-declined-day",
          params: {
            day: "5",
          },
        },
        meta: {
          pageTitle: "Pending Payments",
          breadcrumb: [
            {
              text: "Automatic Declined",
              active: true,
            },
          ],
        },
        children: [
          {
            path: ":day",
            name: "bookeeping-pending-payments-automatic-declined-day",
            component: () =>
              import(
                "@/views/commons/pending-payments/components/PendingPaymentGrid.vue"
              ),
            meta: {
              pageTitle: "Pending Payments",
              breadcrumb: [
                {
                  text: "Automatic Declined",
                  active: true,
                },
              ],
              typeTransaction: 1,
              pendingPaymentDayTab:
                "bookeeping-pending-payments-automatic-declined-day",
              pendingPaymentTab:
                "bookeeping-pending-payments-automatic-declined",
              manualTab: "bookeeping-pending-payments-automatic-manual",
              othersTab: "bookeeping-pending-payments-automatic-others",
            },
          },
        ],
      },
      {
        path: "manual",
        name: "bookeeping-pending-payments-automatic-manual",
        component: () =>
          import(
            /* webpackChunkName: "bookeepingPendingPaymentsOfTheMonth" */ "@/views/commons/pending-payments/components/ManualOthersGrid.vue"
          ),
        // redirect: {
        //   name: "bookeeping-pending-payments-of-the-month-automatic-declined",
        // },
        meta: {
          pageTitle: "Pending Payments",
          breadcrumb: [
            {
              text: "Manual",
              active: true,
            },
          ],
          typeTransaction: 2,
          pendingPaymentTab: "bookeeping-pending-payments-automatic-declined",
          manualTab: "bookeeping-pending-payments-automatic-manual",
          othersTab: "bookeeping-pending-payments-automatic-others",
        },
      },
      {
        path: "others",
        name: "bookeeping-pending-payments-automatic-others",
        component: () =>
          import(
            /* webpackChunkName: "bookeepinglPendingPaymentsOfTheMonth" */ "@/views/commons/pending-payments/components/ManualOthersGrid.vue"
          ),
        // redirect: {
        //   name: "bookeeping-pending-payments-of-the-month-automatic-declined",
        // },
        meta: {
          pageTitle: "Pending Payments",
          breadcrumb: [
            {
              text: "Others",
              active: true,
            },
          ],
          typeTransaction: 3,
          pendingPaymentTab: "bookeeping-pending-payments-automatic-declined",
          manualTab: "bookeeping-pending-payments-automatic-manual",
          othersTab: "bookeeping-pending-payments-automatic-others",
        },
      },
    ],
  },
];
