import { amgApi } from '@/service/axios'

class RiskyClientsService {
    async getDigitalClients(body) {
        try {
            const data = await amgApi.post(`risky-clients/search-digital-clients`, body)
            return data
        } catch (error) {
            console.log('Something went wrong on getDigitalClients:', error)
            throw error
        }
    }
    async getRiskyClients(body) {
        try {
            const data = await amgApi.post(`risky-clients/search-risky-clients`, body)
            return data
        } catch (error) {
            console.log('Something went wrong on getRiskyClients:', error)
            throw error
        }
    }

    async addRiskyClient(body) {
        try {
            const data = await amgApi.post(`risky-clients/add-risky-clients`, body)
            return data
        } catch (error) {
            console.log('Something went wrong on addRiskyClient:', error)
            throw error
        }
    }

    async getRiskyTypeByClientId(body) {
        try {
            const data = await amgApi.post(`risky-clients/get-risky-type-by-client-id`, body)
            return data
        } catch (error) {
            console.log('Something went wrong on getRiskyTypeByClientId:', error)
            throw error
        }
    }

}

export default new RiskyClientsService()
