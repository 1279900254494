<template>
  <b-card footer-class="border-0" class="card-lead-appointments">
    <b-card-title class="border-bottom-secondary border-2 pb-1">
      <h4 class="title-appointment">
        {{ isMetamedia ? "Appointment" : "Appointments" }}
      </h4>
    </b-card-title>
    <b-table
      v-if="S_EVENTS.length"
      show-empty
      :sticky-header="stickyHeader"
      :bordered="false"
      :borderless="false"
      small
      responsive="sm"
      :fields="visibleFieldsAppointment"
      :items="S_EVENTS"
      :busy.sync="isBusy"
      class="mb-0 font-small-3 appointments-grid diego-scrollbar"
      :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(title)="data">
        <div
          class="w-100 d-flex custom-space"
          :class="
            isDarkSkin
              ? 'bg-dark'
              : 'bg-white border-top-light border-bottom-light border-left-light'
          "
        >
          <b-button
            variant="flat-primary"
            size="small"
            style="text-decoration: underline !important"
            :disabled="isResponsibleLead"
            @click="openModalEditEventShow(data.item.id, data.item)"
            >{{ data.item.title }}</b-button
          >
        </div>
      </template>
      <template #cell(seller)="data">
        <div
          class="w-100 d-flex custom-space"
          style="min-width: 100px !important"
          :class="
            isDarkSkin
              ? 'bg-dark'
              : 'bg-white border-top-light border-bottom-light'
          "
        >
          <img
            v-if="!!data.item.image_url || !!data.item.user"
            :src="imageUrl(data.item)"
            class="custom-img mr-1"
          />
          <span v-if="!!userName(data.item)" :id="`user_${data.index}`">{{
            userName(data.item).length > maxLetter
              ? `${userName(data.item).substr(0, maxLetter)}...`
              : userName(data.item)
          }}</span>
          <b-tooltip
            v-if="
              !!userName(data.item) && userName(data.item).length > maxLetter
            "
            :target="`user_${data.index}`"
          >
            {{ userName(data.item) }}
          </b-tooltip>
        </div>
      </template>
      <template #cell(location)="data">
        <div
          class="w-100 d-flex justify-content-center custom-space"
          :class="
            isDarkSkin
              ? 'bg-dark'
              : 'bg-white border-top-light border-bottom-light'
          "
        >
          <span>{{ data.item.location }}</span>
        </div>
      </template>
      <template #cell(date)="data">
        <div
          style="white-space: nowrap"
          class="d-flex w-100 custom-space"
          :class="
            isDarkSkin
              ? 'bg-dark'
              : 'bg-white border-top-light border-bottom-light'
          "
        >
          <span>{{ data.item.date | myDate }}</span>
        </div>
      </template>
      <template #cell(from)="data">
        <div
          style="white-space: nowrap"
          class="d-flex w-100 custom-space"
          :class="
            isDarkSkin
              ? 'bg-dark'
              : 'bg-white border-top-light border-bottom-light'
          "
        >
          {{ data.item.from | myTime }}
        </div>
      </template>
      <template #cell(to)="data">
        <div
          style="white-space: nowrap"
          class="d-flex w-100 custom-space"
          :class="
            isDarkSkin
              ? 'bg-dark'
              : 'bg-white border-top-light border-bottom-light'
          "
        >
          {{ data.item.to | myTime }}
        </div>
      </template>

      <template #cell(actions)="data">
        <div
          class="w-100 text-center d-flex justify-content-center custom-space"
          :class="
            isDarkSkin
              ? 'bg-dark'
              : 'bg-white border-top-light border-bottom-light border-right-light'
          "
        >
          <!-- Reschedule Appointment -->
          <template v-if="isCrm">
            <template v-if="data.item.attend != 2 && data.item.attend != 1">
              <b-button
                v-if="!onlyRead"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :disable="
                  data.item.call_answered === 1 && data.item.call_attended === 1
                "
                class="button-little-size rounded-circle"
                size="sm"
                :variant="
                  (data.item.call_answered === 1 &&
                    data.item.call_attended === 1 &&
                    data.item.attend === 1) === true
                    ? 'flat-muted'
                    : 'flat-primary'
                "
                v-b-tooltip.hover.top="'Reschedule Appointment'"
                @click="openModalRescheduleEvent(data.item.id)"
              >
                <feather-icon icon="CalendarIcon" size="15" class="hover-me" />
              </b-button>
            </template>
          </template>

          <template v-else>
            <b-button
              v-if="!onlyRead"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :variant="data.item.attend === 0 ? 'flat-primary' : 'flat-muted'"
              class="button-little-size rounded-circle"
              size="sm"
              v-b-tooltip.hover.top="'Reschedule Appointment'"
              :disabled="isResponsibleLead"
              @click="openModalRescheduleEvent(data.item.id)"
            >
              <feather-icon icon="CalendarIcon" size="15" class="hover-me" />
            </b-button>
          </template>

          <!-- Tracking -->
          <b-button
            v-if="!onlyRead"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-dark"
            class="button-little-size rounded-circle"
            size="sm"
            v-b-tooltip.hover.top="'See Appointment Tracking'"
            @click="openModalTrackingEvent(data.item.id)"
          >
            <feather-icon icon="ListIcon" size="15" class="hover-me" />
          </b-button>

          <b-button
            v-if="
              data.item.attend != 2 &&
              !onlyRead &&
              !leadComesFromMetaMedia &&
              isNotMetamedia &&
              !isCrm &&
              !isDebtSolution
            "
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-danger"
            class="button-little-size rounded-circle"
            size="sm"
            v-b-tooltip.hover.v-danger="'Delete Appointment'"
            :disabled="isResponsibleLead"
            @click="deleteEvent(data.item.id)"
          >
            <feather-icon icon="Trash2Icon" size="15" class="hover-me" />
          </b-button>

          <b-button
            v-if="data.item.attend == 2 && isNotMetamedia"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
            class="button-little-size rounded-circle"
            v-b-tooltip.hover.v-primary="'Redirect to Sales Made'"
            size="sm"
            :disabled="isResponsibleLead"
            :to="{ name: pathNameToRedirectSM }"
          >
            <feather-icon icon="CornerLeftUpIcon" size="15" class="hover-me" />
          </b-button>

          <b-button
            v-if="data.item.attend == 2 && isCrm && data.item.program_id == 4"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
            class="button-little-size rounded-circle"
            v-b-tooltip.hover.v-primary="'View Debts'"
            size="sm"
            @click="viewDebst(data.item.id)"
          >
            <feather-icon icon="EyeIcon" size="15" class="hover-me" />
          </b-button>
        </div>
      </template>
    </b-table>
    <div
      v-else
      class="custom-content-empty position-relative d-flex justify-content-center align-items-center rounded-lg"
      :class="isDarkSkin ? 'bg-dark' : null"
    >
      <img
        class="custom-img-empty"
        :src="require('@/assets/images/icons/no-appointments.svg')"
        width="150px"
        height="150px"
      />
      <span class="custom-span">No Appointments</span>
    </div>

    <!-- this.currentUser.can_sell == 1  CAN SELL -->
    <template
      v-if="
        ((!onlyRead && this.userCanSell[0].can_sell == 1) ||
          this.currentUser.role_id != 13) &&
        validateRecovery
      "
      #footer
    >
      <div v-if="!isMetamedia && !onlyRead" class="text-right">
        <b-button
          v-if="[6, 21, 22, 23].indexOf($route.matched[0].meta.module) === -1"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :disabled="!appointmentIsAttended || isResponsibleLead"
          variant="bg-transparent"
          v-b-tooltip.hover.bottom="
            !appointmentIsAttended
              ? 'You can\'t  create an appointment'
              : 'Add new appointment'
          "
          class="add-appointment-button"
          @click="openModalCreateAppointment()"
        >
          <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
          <span class="align-middle">Add Appoinment</span>
        </b-button>
      </div>
    </template>
    <!-- modal EVENT CREATE -->
    <b-modal
      id="modal-event-create"
      no-close-on-backdrop
      title-class="h2 text-white"
      ok-only
      modal-class="modal-primary"
      centered
      size="lg"
      title="Create Appointment"
      hide-footer
    >
      <modal-event-create
        :reply_id="id_replay"
        :only-read="onlyRead"
        :lead="lead"
        :id-process-sale="idProcessSale"
        @resetData="resetData"
      />
    </b-modal>
    <!-- modal EVENT EDIT -->
    <b-modal
      id="modal-event-edit"
      title-class="h2 text-white"
      ok-only
      modal-class="modal-primary"
      centered
      size="lg"
      :title="'Attend Appointment: ' + titleModalEventEdit"
      hide-footer
    >
      <modal-event-edit
        :only-read="onlyRead"
        :lead="lead"
        :event="event"
        :id-process-sale="idProcessSale"
        :reply_id="id_replay"
        :is-from-lead-dashboard="true"
        :is-social-network="lead.belongs_module"
        @modal-add-service="openModalAddService"
        @closeModal="closeModal"
        @updated="closeRescheduleFromTel"
        @refreshEvent="refreshEvent()"
      />
    </b-modal>

    <!-- Reschedule event -->
    <b-modal
      id="modal-event-reschedule"
      no-close-on-backdrop
      title-class="h2 text-white"
      ok-only
      modal-class="modal-primary"
      centered
      size="lg"
      :title="titleModalEventReschedule"
      hide-footer
    >
      <modal-event-reschedule
        :only-read="onlyRead"
        :lead="lead"
        :event="event"
        :id-process-sale="idProcessSale"
        :reply_id="id_replay"
        @modal-add-service="openModalAddService"
        @closeModal="closeModal"
        @updated="updateReschedule"
      />
    </b-modal>

    <b-modal
      id="modal-event-tracking"
      ref="modal-event-tracking"
      on-close="closeModal"
      no-close-on-backdrop
      title-class="h2 text-white"
      ok-only
      modal-class="modal-primary"
      centered
      size="xmd"
      :title="titleModalTracking"
      hide-footer
    >
      <modal-event-tracking :event="event" />
    </b-modal>

    <!-- modal Add Service -->
    <modal-add-service
      v-if="showModalAddService"
      :programs-all="programsAll"
      :sales-client="salesClient"
      :users-services="servicesUsers"
      :current-user="currentUser"
      :is-from-lead-dashboard="true"
      @changeProgram="openModalProgram"
      @hideModal="showModalAddService = false"
    />
    <add-creditor-ds-modal
      v-if="addCreditorModalController"
      :state-modal="0"
      :id_event="event_id"
      :rejected-appointments="true"
      @close="addCreditorModalController = false"
    />
  </b-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { BModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import ModalAddService from "@/views/commons/components/lead-programs/components/lead-table/ModalAddService.vue";
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import RoiReportsService from "@/views/commons/components/roi-reports/roi-reports.service";
import moment from "moment";
import ModalEventReschedule from "@/views/crm/views/Lead/lead-event/ModalRescheduleEvent.vue";
import ModalEventTracking from "@/views/crm/views/Lead/lead-event/ModalEventTracking.vue";
import AddCreditorDsModal from "@/views/crm/views/Lead/lead-module/dashboard/modal/AddCreditorDsModal.vue";
import ModalEventCreate from "../../lead-event/ModalEventCreate.vue";
import ModalEventEdit from "../../lead-event/ModalEventEdit.vue";

export default {
  name: "CardLeadAppointment",
  components: {
    BModal,
    ModalEventCreate,
    ModalEventEdit,
    ModalAddService,
    BusinessModal,
    CreditExpertsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    ModalEventReschedule,
    ModalEventTracking,
    AddCreditorDsModal,
  },
  props: {
    onlyRead: {
      type: Boolean,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
    },
    lead: {
      type: Object,
      required: true,
    },
    stickyHeader: {
      type: String,
      required: false,
      default: "22vh",
    },
  },
  data() {
    return {
      height: "auto",
      isShowDetail: false,
      idProcessSale: null,
      id_replay: null,
      validateRecovery: false,
      blankEvent: {},
      campos: {},
      event: {
        attend: null,
        date: "",
        from: "",
        id: null,
        location: "",
        real_time: "",
        title: "",
        to: "",
        call_attended: false,
        call_answered: false,
        programs: [],
      },
      fieldsEvent: [
        {
          key: "title",
          tdClass: "py-50 px-0 text-center",
          thClass: "text-center",
          visible: true,
        },
        { key: "seller", tdClass: "py-50 px-0", visible: true },
        {
          key: "location",
          tdClass: "py-50 px-0 text-center",
          thClass: "text-center",
          visible: true,
        },
        { key: "date", tdClass: "py-50 px-0", visible: true },
        { key: "from", tdClass: "py-50 px-0", visible: true },
        { key: "to", tdClass: "py-50 px-0", visible: true },
        {
          key: "Actions",
          tdClass: "py-50 px-0 text-center",
          thClass: "text-center",
          visible: true,
        },
      ],
      titleModalEventEdit: "EVENT EDIT",
      titleModalEventReschedule: "EVENT RESCHEDULE",
      titleModalTracking: "TRACKING RESCHEDULE",
      showModalAddService: false,
      modal: {
        programs: false,
      },
      headerS: {},
      leadData: [],
      programsAll: [],
      servicesUsers: [],
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      salesClient: {},
      addCreditorModalController: false,
      event_id: 0,
      maxLetter: 20,
      eventSelected: null,
    };
  },
  async created() {
    this.setDataBlank("event");
    await this.A_GET_EVENTS({
      idLead: this.$route.params.id ? this.$route.params.id : this.lead.id,
    });
    if (
      this.lead.belongs_module == 15 &&
      this.S_EVENTS.length != 0 &&
      !(moment(this.lead.created_at).format("YYYY-MM-DD") < "2022-07-26")
    ) {
      await this.getIdReplay();
    } else {
      this.validateRecovery = true;
    }
  },
  computed: {
    // first metamedia appointment is attended
    appointmentIsAttended() {
      if (this.S_EVENTS.length > 0) {
        return this.S_EVENTS[0].attend === 2;
      }
      return true;
    },

    isNotMetamedia() {
      return this.$route.matched[0].meta.module !== 15;
    },
    visibleFieldsAppointment() {
      if (this.$route.matched[0].meta.module === 15) {
        this.fieldsEvent[0].visible = false;
      } else {
        this.fieldsEvent[0].visible = true;
      }
      return this.fieldsEvent.filter((field) => field.visible);
    },
    ...mapGetters({
      userCanSell: "auth/userCanSell",
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    ...mapState({
      S_EVENTS: (event) => event.CrmEventStore.S_EVENTS,
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isMetamedia() {
      return this.$route.matched[0].meta.module === 15;
    },
    isCrm() {
      return this.$route.matched[0].meta.module === 2;
    },
    isDebtSolution() {
      return this.$route.matched[0].meta.module === 5;
    },
    leadHasPhone() {
      if (this.lead.mobile) {
        return true;
      }
      return false;
    },

    leadComesFromMetaMedia() {
      return this.lead.belongs_module === 15;
    },
    pathNameSaleMade() {
      switch (this.moduleId) {
        case 5:
          return "sales-made-debtsolution-lead";
          break;
        case 7:
          return "sales-made-boostcredit-lead";
          break;
        case 6:
          return "sales-made-creditexperts-lead";
          break;
        case 8:
          return "sales-made-tax-research-lead";
          break;
        case 14:
          return "sales-made-bookeeping-lead";
          break;
        case 10:
          return "sales-made-claimdepartment-lead";
          break;
        case 21:
        case 22:
        case 23:
        case 20:
          return "sales-made-connection-lead";
          break;
        case 4:
          return "administration-sales-made-new-client";
        case 11:
          return "sales-made-specialists-add-change";
        case 3:
          return "sales-made-bussiness-lead";
        case 0:
          return "administration-sales-made-new-client";
        case 18:
          return "sales-made-quality-lead";
      }
    },
    // redirect Sales Made: done
    pathNameToRedirectSM() {
      if (this.moduleId === 26) {
        return "sales-made-cedigital-new-client";
      }
      return "sales-made-crm";
    },
    isResponsibleLead() {
      if (this.$route.params.id) {
        return !(
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === this.currentUser.user_id ||
          this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
            .is_responsible === null
        );
      }
      return false;
    },
  },
  methods: {
    viewDebst(id) {
      this.event_id = id;
      this.addCreditorModalController = true;
    },
    closeModal() {
      this.$bvModal.hide("modal-event-edit");
      this.$bvModal.hide("modal-event-reschedule");
    },
    async updateReschedule(event) {
      await this.A_GET_EVENTS({
        idLead: this.$route.params.id ? this.$route.params.id : this.lead.id,
      });
      this.$bvModal.hide("modal-event-reschedule");
    },
    closeRescheduleFromTel() {
      this.$bvModal.hide("modal-event-edit");
    },
    ...mapActions({
      A_DELETE_EVENTS: "CrmEventStore/A_DELETE_EVENTS",
      A_GET_EVENT: "CrmEventStore/A_GET_EVENT",
      A_GET_EVENTS: "CrmEventStore/A_GET_EVENTS",
    }),
    setDataBlank(key) {
      this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`] = {
        ...this[key],
      };
    },
    async resetData(key) {
      this[key] = {
        ...this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`],
      };
      await this.A_GET_EVENTS({ idLead: this.$route.params.id });
    },
    openModalCreateAppointment() {
      this.addPreloader();
      this.isInfo = false;
      this.$bvModal.show("modal-event-create");
    },
    async refreshEvent() {
      const response = await this.A_GET_EVENT({ id: this.eventSelected });
      this.event = response.data[0];
    },
    async openModalEditEventShow(id, data) {
      this.eventSelected = id;
      try {
        const { can_attend_to } = data;
        const response = await this.A_GET_EVENT({ id });
        this.resetData("event");
        if (this.isResponseSuccess(response)) {
          this.event = {
            ...response.data[0],
            can_attend_to,
          };
          this.event.user_id = {
            label: this.event.seller_name,
            value: this.event.seller_id,
          };
          this.titleModalEventEdit = this.event.title;
          this.$bvModal.show("modal-event-edit");
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong. ${response.message}`
          );
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async openModalRescheduleEvent(id) {
      try {
        this.eventSelected = id;
        const response = await this.A_GET_EVENT({ id });
        this.resetData("event");
        if (this.isResponseSuccess(response)) {
          this.event = response.data[0];
          this.event.programs = [];
          this.event.user_id = {
            label: this.event.seller_name,
            value: this.event.seller_id,
          };
          this.titleModalEventReschedule = `Reschedule ${this.event.title}`;
          this.$bvModal.show("modal-event-reschedule");
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong. ${response.message}`
          );
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async openModalTrackingEvent(id) {
      try {
        const response = await this.A_GET_EVENT({ id });
        this.resetData("event");
        if (this.isResponseSuccess(response)) {
          this.event = response.data[0];
          this.event.user_id = {
            label: this.event.seller_name,
            value: this.event.seller_id,
          };
          this.titleModalTracking = "Reschedule Tracking";
          this.$bvModal.show("modal-event-tracking");
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong. ${response.message}`
          );
        }
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

    deleteEvent(id) {
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            const response = await this.A_DELETE_EVENTS(id);
            if (this.isResponseSuccess(response)) {
              this.showToast(
                "success",
                "top-right",
                "Deleted!",
                "CheckIcon",
                "Your file has been deleted."
              );
            } else {
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                `Something went wrong. ${response.message}`
              );
            }
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
          );
        });
    },
    async openModalAddService(event_id, data) {
      await this.getAllPrograms();
      await this.getSellers();
      this.showModalAddService = true;
      this.$bvModal.hide("modal-event-edit");
      const sales = {
        id: this.lead.id,
        lead_name: this.lead.lead_name,
        event_id,
        derivation_id: this.lead?.derivation_id,
        event: data,
      };
      this.salesClient = sales;
    },
    async getAllPrograms() {
      try {
        const data = await LeadService.getPrograms();
        if (data) {
          this.programsAll = data;
          const sper = this.currentUser.sale_permission;
          const spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getSellers() {
      const result = await LeadService.getUsersAllPrograms({
        id: this.moduleId,
      });
      if (result) {
        result.unshift({
          id: 1,
          user_name: "CEO",
        });
      }
      this.servicesUsers = result;
    },

    async getValidateRecoverySeller() {
      const data = await RoiReportsService.validateRecoverySeller({
        lead_id: this.lead.id,
      });

      if (data.status == 200) {
        if (data.data.length > 0) {
          this.idProcessSale = data.data[0].id;
        }
      }
    },
    async getIdReplay() {
      const data = await RoiReportsService.getIdReplay({
        lead_id: this.lead.id,
      });

      if (data.status == 200) {
        if (data.data.length > 0) {
          if (data.data[0].attend != 0) {
            this.validateRecovery = true;
          }
          if (
            moment(data.data[0].created_at).format("YYYY-MM-DD") !==
            moment().format("YYYY-MM-DD")
          ) {
            await this.getValidateRecoverySeller();
            this.validateRecovery = true;
            this.id_replay = data.data[0].reply_id;
          }
        } else {
          this.validateRecovery = true;
        }
      } else {
        this.validateRecovery = true;
      }
    },

    async openModalProgram(data) {
      console.log(this.moduleId)
      await this.addPreloader();
      const params = {
        leadId: this.lead.id,
        eventId: this.event.id,
      };
      if (data.program.id == 3) {
        await LeadService.accountsRemoveToEvent(params);
      } else if (data.program.id == 4) {
        await LeadService.listCreditorsToEvent(params);
      }
      try {
        const result = await LeadService.insertSaleDebt({
          id: this.salesClient.event_id,
          program: data.program.id,
          done: 1,
          module: this.moduleId,
          captured: data.captured.id,
          seller: data.seller.id,
          type_client: "LEAD",
        });
        if (result.status == 200) {
          this.modalAddProgramsState = false;
          this.$router
            .push({
              name:
                this.moduleId === 0 ? "sales-made-crm" : this.pathNameSaleMade,
            })
            .catch((err) => {
              this.showErrorSwal(err);
            });
          this.removePreloader();
        }
      } catch (error) {
        throw error;
      } finally {
        this.removePreloader();
      }
    },
    closeModalEventEdit() {
      this.$bvModal.hide("modal-event-edit");
    },
    userName(item) {
      return item.name_user || `${item.user.first_name} ${item.user.last_name}`;
    },
    imageUrl(item) {
      return item.image_url || item.user.image_url;
    },
  },
  directives: {
    Ripple,
  },
};
</script>
<style scoped>
.mh-5 {
  min-height: 500px;
}
.custom-table
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th {
  background-color: #3a72ea !important;
  color: rgba(17, 17, 19, 1);
  padding: 12px 10px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: none !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> .table tr td,
.custom-table-dark >>> .table tr td {
  border: none !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child {
  border-top-right-radius: 10px !important;
}
.custom-table >>> tbody tr tr {
  background-color: red !important;
}
.custom-table >>> .table tr td:first-child,
.custom-table-dark >>> .table tr td:first-child {
  border-radius: 10px 0px 0px 10px !important;
}
.custom-table >>> .table tr td:last-child,
.custom-table-dark >>> .table tr td:last-child {
  border-radius: 0px 10px 10px 0px !important;
}
.custom-table >>> .table tr td .custom-space,
.custom-table-dark >>> .table tr td .custom-space {
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.custom-table >>> .table tr td:first-child .custom-space,
.custom-table-dark >>> .table tr td:first-child .custom-space {
  border-radius: 10px 0px 0px 10px !important;
}
.custom-table >>> .table tr td:last-child .custom-space,
.custom-table-dark >>> .table tr td:last-child .custom-space {
  border-radius: 0px 10px 10px 0px !important;
}
.b-table > tbody > tr {
  border: none !important;
}
.custom-img {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50% !important;
}
.custom-content-empty {
  height: 80% !important;
}
.custom-span {
  position: absolute !important;
  font-size: 25px !important;
  font-weight: bolder !important;
}
.custom-img-empty {
  opacity: 0.4 !important;
}
</style>
<style lang="scss" scoped>
.card-lead-appointments {
  .add-appointment-button {
    background-color: #3f7afa !important;
    background: #3f7afa !important;
    color: #111113 !important;
    border-color: #3f7afa !important;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
  .title-appointment {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #111113;
  }
  .appointments-grid {
    overflow: auto !important;
    padding-right: 10px;
  }
}

.dark-layout {
  .card-lead-appointments {
    .title-appointment {
      color: #fafafa;
    }
  }
}
</style>
