<template>
  <div>
    <feather-icon
      v-if="
        fromMetamedia ||
        ((this.userCanSell[0].can_sell == 1 ||
          this.currentUser.role_id != 13) &&
          validateRecovery)
      "
      :class="
        getMessageIcon != 'Add new appointment'
          ? 'text-secondary'
          : 'text-primary'
      "
      icon="CalendarIcon"
      class="cursor-pointer"
      v-b-tooltip.hover.bottom="getMessageIcon"
      size="20"
      @click="openModalCreateAppointment()"
    ></feather-icon>

    <b-modal
      id="modal-event-create"
      no-close-on-backdrop
      title-class="h2 text-white"
      ok-only
      modal-class="modal-primary"
      centered
      size="lg"
      title="Create Appointment"
      hide-footer
    >
      <modal-event-create
        :reply_id="replyId"
        :lead="lead"
        :id-process-sale="idProcessSale"
        appointment-validate
        @resetData="resetData"
        @sendMessageFromMeta="sendMessageFromMeta"
        :fromMeta="fromMetamedia"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MessengerMetaService from "@/views/commons/Messenger/components/messenger/services/messenger.meta.service.js";
import InstagramMetaService from "@/views/commons/Messenger/components/instagram/services/instagram.meta.service.js";

import ModalEventCreate from "@/views/crm/views/Lead/lead-event/ModalEventCreate.vue";
import LeadService from "@/views/crm/services/lead/index.js";
import EventService from "@/views/crm/services/event/index.js";
import RoiReportsService from "@/views/commons/components/roi-reports/roi-reports.service";
import moment from "moment";
export default {
  props: {
    leadId: {
      type: [Number, String],
      required: true,
    },
    fromMetamedia: {
      type: Boolean,
      default: false,
    },
    senderId: {
      type: String,
      required: false,
      default: null,
    },
    platform: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: {
    ModalEventCreate,
  },

  computed: {
    ...mapGetters({
      userCanSell: "auth/userCanSell",
      currentUser: "auth/currentUser",
      G_CURRENT_FB_PAGE: "MessengerMetaStore/G_CURRENT_FB_PAGE",
    }),

    appointmentIsAttended() {
      if (this.lead == null) return true;

      if (this.appointments.length > 0 && this.appointments[0].attend != 2) {
        return true;
      }

      return false;
    },

    getMessageIcon() {
      if (this.lead && !this.lead.mobile) return "Don't have a mobile";

      return this.appointmentIsAttended
        ? "You can't  create an appointment"
        : "Add new appointment";
    },
  },

  data() {
    return {
      isInfo: false,
      replyId: null,
      lead: null,
      appointments: [],
      idProcessSale: null,

      validateRecovery: false,
    };
  },

  methods: {
    openModalCreateAppointment() {
      if (this.lead && !this.lead.mobile) return;

      if (this.appointmentIsAttended) return;

      this.isInfo = false;
      this.$bvModal.show("modal-event-create");
    },

    async getLeadInformation() {
      const data = await LeadService.getLead(this.leadId);
      if (data && data.length > 0) {
        this.lead = data[0];

        if (
          this.lead.belongs_module == 15 &&
          this.appointments.length != 0 &&
          !(moment(this.lead.created_at).format("YYYY-MM-DD") < "2022-07-26")
        ) {
          await this.getIdReplay();
        } else {
          this.replyId = this.lead.last_reply;
          this.validateRecovery = true;
        }
      }
    },

    async getValidateRecoverySeller() {
      const data = await RoiReportsService.validateRecoverySeller({
        lead_id: this.leadId,
      });

      if (data.status == 200) {
        if (data.data.length > 0) {
          this.idProcessSale = data.data[0].id;
        }
      }
    },

    async getAppointments() {
      const { data } = await EventService.getEvents({
        idLead: this.leadId,
      });

      if (data && data.data.length > 0) {
        this.appointments = data.data;
      }
    },

    async getIdReplay() {
      const data = await RoiReportsService.getIdReplay({
        lead_id: this.leadId,
      });

      if (data.status == 200) {
        if (data.data.length > 0) {
          if (data.data[0].attend != 0) {
            this.validateRecovery = true;
          }
          if (
            moment(data.data[0].created_at).format("YYYY-MM-DD") !==
            moment().format("YYYY-MM-DD")
          ) {
            await this.getValidateRecoverySeller();
            this.validateRecovery = true;
            this.replyId = data.data[0].reply_id;
          }
        } else {
          this.validateRecovery = true;
        }
      } else {
        this.validateRecovery = true;
      }
    },

    resetData() {
      this.getAppointments();
    },

    sendMessageFromMeta(event) {
      this.getAppointments();
      this.sendMessage(event);
    },

    async sendMessage(event) {
      try {
        const hasPhone = event.seller_phone
          ? `desde la línea ${event.seller_phone}`
          : ``;
        const message =
          `Cita confirmada para el ${event.dateSp} a las ${event.from}. Lo atenderá el especialista ${event.seller} ` +
          hasPhone;

        const params = {
          pageId: this.G_CURRENT_FB_PAGE.id,
          message: message,
          senderId: this.senderId,
          user: {
            id: this.currentUser.user_id,
            name: this.currentUser.fullName,
          },
        };
        const services = {
          messenger: MessengerMetaService,
          instagram: InstagramMetaService,
        };

        const { data } = await services[this.platform].sendMessage(params);

        if (data.errorMessage) {
          this.showErrorSwal(data.errorMessage);
          return;
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    handleError(error) {
      const { response } = error;
      const { data } = response || {};

      const errorMessages = {
        551: () => {
          this.showInfoSwal(data.message);
        },
        100: () => {
          this.showErrorSwal(data.message);
        },
        400: () => this.showWarningSwal(data.message),
        10: () => {
          this.showInfoSwal(data.message);
        },
      };

      if (data && errorMessages[data.code]) {
        errorMessages[data.code]();
      } else {
        this.showErrorSwal(error);
      }
    },
  },

  async created() {
    await Promise.all([this.getLeadInformation(), this.getAppointments()]);
  },
};
</script>

<style>
</style>