

export default [
  {
    path: "loans",
    name: "loans-quality",
    redirect: "/quality/loans/my-loans",
    component: () => import(/* webpackChunkName: "QualityLoans" */ "@/views/commons/components/loans/LoansComponent.vue"),
    children: [
      {
        path: "my-loans",
        name: "my-loans-quality",
        component: () => import(/* webpackChunkName: "QualityLoansMyLoans" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 1,
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "My Loans",
              active: true,
            },
          ],
          route: "quality",
        },
      },
      {
        path: "loans-module",
        name: "loans-module-quality",
        component: () => import(/* webpackChunkName: "QualityLoansModule" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 2,
          route: "quality",
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "Loans By Module",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      tab: 1,
      route: "quality",
    },
  },
];
