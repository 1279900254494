<template>
  <b-modal v-model="isActive" @hidden="close(false)" hide-footer>
    <b-row class="my-1">
      <b-col>
        <h2 class="text-center">ASSIGN NEW RELATIONSHIP</h2>
      </b-col>
    </b-row>
    <ValidationObserver ref="observator">
      <b-row>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <BFormGroup label="Select a agent">
              <BFormSelect
                :disabled="!!preSelectedAgentId"
                v-model="users.agent_id"
                :options="usersLists.agents"
                :class="{ 'border-danger': errors[0] }"
              />
            </BFormGroup>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <BFormGroup label="Select a specialist">
              <BFormSelect
                v-model="users.specialist_id"
                :options="usersLists.specialists"
                :class="{ 'border-danger': errors[0] }"
              />
            </BFormGroup>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <BFormGroup label="Select a admin">
              <BFormSelect
                v-model="users.admin_id"
                :options="usersLists.administrators"
                :class="{ 'border-danger': errors[0] }"
              />
            </BFormGroup>
          </ValidationProvider>
        </b-col>
      </b-row>
      <b-row v-if="!!preSelectedAgentId">
        <b-col>
          <div class="text-center mt-1">
            <span class="text-danger">
              <small>
                The client you have selected has an agent assigned who does not
                have a specialist-administrator relationship. Please set one
                before continuing.
              </small>
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col>
          <center>
            <b-button @click="save" variant="success"> SAVE </b-button>
          </center>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>
<script>
// Import services
import ConnectionUsersRelationshipService from "@/views/ce-digital/sub-modules/settings/views/users-relationship/service/users-relationship-connecion.service";
// Import Components
import AgentHeader from "./AgentHeader.vue";

export default {
  components: {
    AgentHeader,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    preSelectedAgentId: {
      default: null,
    },
  },
  created() {
    if (!!this.preSelectedAgentId)
      this.users.agent_id = this.preSelectedAgentId;
  },
  data() {
    return {
      isActive: false,
      users: {
        agent_id: null,
        specialist_id: null,
        admin_id: null,
      },
      usersLists: {
        agents: [],
        specialists: [],
        administrators: [],
      },
    };
  },
  async mounted() {
    await this.getUsers();

    this.isActive = this.active;
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observator.validate();
      if (!isValid) return;
      const swalResponse = await this.showConfirmSwal();
      if (!swalResponse.isConfirmed) return;

      await this.newRelationship();
    },
    async newRelationship() {
      this.addPreloader();
      const { status, msg } =
        await ConnectionUsersRelationshipService.createNewRelationship(
          this.users
        );
      this.removePreloader();

      if (status === "success") {
        this.showSuccessSwal(msg);
        this.close(true);
      } else {
        this.showErrorSwal(msg);
      }
    },
    async getUsers() {
      const { data } =
        await ConnectionUsersRelationshipService.relationshipUsers();

      Object.assign(this.usersLists, data);
    },
    close(refresh = false) {
      this.$emit("close", refresh);
      this.isActive = false;
    },
  },
};
</script>
