export default [
  {
    path: "meta-investment",
    name: "meta-investment-creative",
    component: () =>
      import(
        /* webpackChunkName: "MetaInvestment" */ "@/views/creative/views/meta-investment/MetaInvestmentMain.vue"
      ),
    meta: {
      pageTitle: "Meta",
      breadcrumb: [
        {
          text: "Investment",
          active: true,
        },
      ],
    },
    children: [],
  },
];
