import DashboardNav from "@/views/specialists/sub-modules/financial-approval/views/dashboard/navigation/dash.nav";
import SettingsNav from "@/views/specialists/sub-modules/financial-approval/views/settings/navigation/settings.nav";
import RequestNcrNav from "@/views/specialists/sub-modules/financial-approval/views/request-ncr/navigation/request-ncr.nav";
// import RecomendationsNav from "@/views/specialists/sub-modules/financial-approval/views/recomendations/navigation/recomendations.nav.js";
import ApplicationsNav from "@/views/specialists/sub-modules/financial-approval/views/applications/applications.navigation";
import OtherServicesNav from "@/views/specialists/sub-modules/financial-approval/views/other-services/other-services.navigation.js";
import FileManagerNav from "@/views/specialists/sub-modules/financial-approval/views/file-manager/file-manager-fa.nav.js";
import CommissionsNav from "@/views/specialists/sub-modules/financial-approval/views/commissions/commissions.nav";
import AppointmentaNav from "@/views/specialists/sub-modules/financial-approval/views/appointment/appointment.navigation";
import PotentialNav from "@/views/specialists/sub-modules/financial-approval/views/potencial-appointment/potencial.nav";
import SupportNav from "@/views/specialists/sub-modules/financial-approval/views/support/navigation/support.nav";
import InconsistentTransactionNavigation from "@/views/specialists/sub-modules/financial-approval/views/inconsistent-transactions/navigation/inconsistent-transactions.nav.js";
import SalesMadeNav from "@/views/specialists/sub-modules/financial-approval/views/sales-made/sales-made.nav.js"

const navigation = [
  {
    header: "FINANCIAL APPROVAL",
    module: 29,
    modules: [
      {
        value: 28,
        text: "ANALYST DEPARTMENT",
        route: "analyst-department-dashboard",
      },
      {
        value: 29,
        text: "FINANCIAL APPROVAL",
        route: "financial-approval-dashboard",
      },
      {
        value: 30,
        text: "ACCOUNTS RESOLUTION",
        route: "accounts-resolution-dashboard",
      },
    ],
  },
  DashboardNav,
  RequestNcrNav,
  ...SalesMadeNav,
  ...ApplicationsNav,
  AppointmentaNav,
  PotentialNav,
  OtherServicesNav,
  // ...PendingPaymentNavigation,
  CommissionsNav,
  FileManagerNav,
  SupportNav,
  SettingsNav,
  ...InconsistentTransactionNavigation
];

export default navigation;
