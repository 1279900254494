import { appointmentTabs } from "@/views/commons/components/appointments/router/appointment.tab";


export default [
  {
    path: "appointment",
    name: "boost-credit-appointment",
    redirect: { name: "bc-appointment-pending" },
    component: () => import(
      "@/views/commons/components/appointments/view/AppointmentMain.vue"
    ),
    meta: {
      router: "bc-appointment",
      pageTitle: "Appointment",
      breadcrumb: [
        {
          text: "Appointment",
          active: true,
        },
      ],
      routePending: `bc-appointment-pending`,
      routeInProcess: `bc-appointment-in-process`,
      routeCompleted: `bc-appointment-completed`,
      routeAccepted: `bc-appointment-accepted`,
      routeRejected: `bc-appointment-rejected`,
      routeExpired: `bc-appointment-expired`,
    },
    children: appointmentTabs("bc-appointment","Appointments"),
  },
];


