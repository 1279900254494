 <template>
  <div class="content-wavesurfer"  >
    <div class="d-flex align-items-center" v-if="waveSurferRendered"> 
      <div class="button-play d-flex justify-content-center mr-1">
        <span class="cursor-pointer d-flex justify-content-center align-self-center" v-if="!isPlaying" @click="onPlayClick">
          <ws-play-icon class="align-self-center" />
        </span>
        <span class="cursor-pointer d-flex justify-content-center align-self-center" v-else @click="onPlayClick">
          <ws-pause-icon class="align-self-center" />
        </span>
      </div>
      <div
        :id="waveSurferId"
        :style="{
          width: '180px',
          height: '20px',
          marginRight: '10px',
        }"
      ></div>
      <div class="time-elapsed" :style="{ width: `${elapsedTimeWidth}px` }">
        {{ currentTime }} {{ showDuration ? ` / ${fullDuration}` : "" }}
      </div>
      <feather-icon
        icon="DownloadIcon" 
        size="20"
        class="cursor-pointer"
        style="margin-right: 15px"
        @click="onDownload(url, file_name)"
      />
      <b-badge
        size="sm"
        class="cursor-pointer"
        v-if="wavesurfer"
        @click="incrementSpeed"
        >x {{ wavesurfer.getPlaybackRate().toFixed(1) }}</b-badge
      > 
    </div>
    <b-spinner
      style="color: blue; width: 20px; height: 20px"
      label="Spinning"
      v-else
    ></b-spinner>
  </div>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import WsPauseIcon from "@/views/management/views/approval-process/settlement-approval/icons/WsPauseIcon.vue";
import WsPlayIcon from "@/views/management/views/approval-process/settlement-approval/icons/WsPlayIcon.vue";
import axios from "axios";
export default {
  props: {
    showDuration: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
    waveSurferId: {
      type: String,
      required: true,
    },
    elapsedTimeWidth: {
      type: Number,
      required: false,
      default: 35,
    },
    file_name: {
      type: String,
      required: false,
    },
    options: {
      type: Object,
      required: false,
      default: () => ({
        container: "#waveform",
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 30,
        barGap:2,
        width: 185,
        backend: "MediaElement",
        minPxPerSec: 0,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      }),
    },
    download: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    WsPauseIcon,
    WsPlayIcon,
  },
  data() {
    return {
      isPlaying: false,
      currentTime: "00:00",
      wavesurfer: null,
      fullDuration: 0,
      currentDuration: 0,
      downloadAudio: null,
      loadingAudio: false,
      waveSurferRendered: false,
      urlParsed: "",
    };
  },
  mounted() {
    this.donwloadAudio();
  },
  beforeDestroy() {
    this.wavesurfer.destroy();
  },
  methods: {
    async donwloadAudio() {
      this.loadingAudio = true;
      axios({
        method: "get",
        url: this.url,
        responseType: "blob",
      })
        .then((response) => {
          // Obtén la respuesta como un objeto Blob
          const audioBlob = response.data;

          // Crea una URL del objeto Blob
          const audioUrl = URL.createObjectURL(audioBlob);

          // Carga el archivo de audio en WaveSurfer
          this.loadingAudio = false;
          this.waveSurferRendered = true;
          this.urlParsed = audioUrl;
        })
        .catch((error) => {
          console.error("Error al descargar el archivo de audio:", error);
        });
      // return new Promise((resolve, reject) => {
      //   try {

      //   } catch (error) {
      //       reject(error);
      //   }
      // })
    },
    onPlayClick() {
      if (this.wavesurfer.isPlaying()) {
        this.wavesurfer.pause();
      } else {
        this.wavesurfer.play();
      }
    },
    // loadAudio() {
    //   this.wavesurfer.load(this.downloadAudio)
    // },
    incrementSpeed() {
      const playbackRate = this.wavesurfer.getPlaybackRate();
      if (playbackRate === 2.0) {
        this.wavesurfer.setPlaybackRate(1.0);
      } else {
        this.wavesurfer.setPlaybackRate(playbackRate + 0.25);
      }
    },
    async renderWave() {
      // const audioUrl = await this.donwloadAudio()
      this.$nextTick(() => {
        this.options.container = `#${this.waveSurferId}`;
        this.wavesurfer = WaveSurfer.create(this.options);

        this.wavesurfer.on("ready", () => {
          this.fullDuration = this.getTimeCodeFromNum(
            this.wavesurfer.getDuration()
          );
        });

        this.wavesurfer.on("play", () => {
          this.isPlaying = true;
        });

        this.wavesurfer.on("pause", () => {
          this.isPlaying = false;
        });

        this.wavesurfer.on("audioprocess", (currentTime) => {
          this.currentTime = this.getTimeCodeFromNum(currentTime);
        });
        this.wavesurfer.load(this.urlParsed);
      });
    },
    getTimeCodeFromNum(num) {
      let seconds = parseInt(num.toString());
      let minutes = parseInt((seconds / 60).toString());
      seconds -= minutes * 60;
      const hours = parseInt((minutes / 60).toString());
      minutes -= hours * 60;

      const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

      if (hours === 0) return `${formattedMinutes}:${formattedSeconds}`;
      const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    },
    onDownload(url, file_name) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = file_name?file_name:'example_name.mp3';
          link.click();
        });
    },
  },
  watch: {
    urlParsed() {
      if (this.waveSurferRendered != "") {
        this.renderWave();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.time-elapsed {
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 10px;
}

.button-play { 
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #0077E633;
}

.content-wavesurfer{
  padding: 10px 20px;
  border:2px solid #ccc;
  width: 450px;
  border-radius: 5px;
  margin: 10px 0px; 
}
</style>

