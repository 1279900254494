export default [
    {
        key: "selector",
        label: "Selector",
        thClass: "text-left first-item",
        tdClass: "text-left first-item", 
        visible: true,
    },
    {
        key: "direction",
        label: "Direction",
        thClass: "text-left first-item",
        tdClass: "text-left first-item", 
        visible: true,
    },
    {
        key: "start_time",
        label: "Start Time",
        thClass: "text-left",
        tdClass: "text-left", 
        visible: true,
    },
    {
        key: "record_url",
        label: "Audio",
        thClass: "text-center last-item",
        tdClass: "text-center last-item",
        sortable: false,
        visible: true,
    },
];