export default [
    {
        path: "client-company",
        name: "bussiness-client-company",
        component: () => import(/* webpackChunkName: "BusinessClientCompany" */ "@/views/commons/components/list-client-company/ClientCompany.vue"),
        meta: {
            pageTitle: "Clients Company Data",
            breadcrumb: [
                {
                    text: "Clients Company Data",
                    active: true,
                },
            ],
        },
    },
];