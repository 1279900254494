export default {
  path: "file-manager",
  name: "paragon-file-manager",
  // component: () => import("./index.vue"), // Remove file
  component: () => import(/* webpackChunkName: "PRFileManager" */ "@/views/crm/views/file-mananger/FileMananger.vue"),
  meta: {
    pageTitle: 'File Manager',
    breadcrumb: [
      {
        text: 'File Manager',
      },
    ],
  },
}