export default [
  {
    path: "clients",
    name: "administration-clients",
    redirect: { name: "administration-clients-regular-tab" },
    component: () =>
      import(
        /* webpackChunkName: "AdministrationClients" */ "@/views/administration/views/clients/Clients.vue"
      ),
    meta: {
      permittedRoles: [1, 2, 6],
      pageTitle: "Clients",
      route: "administration",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "administration-clients-regular-tab",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientsRegularTab" */ "@/views/crm/views/clients/components/RegularTab.vue"
          ),
        meta: {
          permittedRoles: [1, 2, 6],
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Regular",
              to: { name: "administration-clients-regular-tab" },
            },
          ],
        },
      },
      {
        path: "",
        name: "administration-clients-digital-tab",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationClientsDigitalTab" */ "@/views/crm/views/clients/components/DigitalTab.vue"
          ),
        meta: {
          permittedRoles: [1, 2, 6],
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Digital",
              to: { name: "administration-clients-digital-tab" },
            },
          ],
        },
      },
    ],
  },
];
