<template>
  <b-modal
    v-model="control"
    title="File preview"
    class="modal"
    @hidden="$emit('close')"
  >
    <h3>Preview</h3>
    <!-- {{ file }} -->
    <iframe
      v-if="isDocx(file)"
      :src="getDocxViewerUrl(file)"
      title="file preview"
    />
    <img
      v-else-if="isImage(file)"
      :src="file"
      alt="File Preview"
    >
    <iframe
      v-else
      :src="file"
      title="file preview"
      style="width: 100%; height: 700px;"
    />
  </b-modal>
</template>

<script>

export default {
  name: 'ModalPreviewFile',
  props: {
    file: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fileUrl: null,
      control: false,
    };
  },
  mounted() {
    this.control = true;
    this.fileUrl = this.file;
  },
  beforeDestroy() {
    if (this.fileUrl) {
      URL.revokeObjectURL(this.fileUrl);
    }
  },

  methods: {

    isDocx(file) {
      console.log(file.endsWith('.docx'));
      return file.endsWith('.docx');
    },

    isImage(file) {
      // Check if the file is an image (you can expand this check for other file types)
      return file.endsWith('.jpg')
          || file.endsWith('.jpeg')
          || file.endsWith('.png')
          || file.endsWith('.gif');
    },

    getDocxViewerUrl(file) {
      // Replace this with a proper URL for a DOCX viewer, such as Google Docs Viewer
      // For example, if using Google Docs Viewer:

      console.log(`https://docs.google.com/gview?url=${file}&embedded=true`);
      return `https://docs.google.com/gview?url=${file}&embedded=true`;
    },
  },
};

</script>

<style scoped>
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.modal-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.25rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
}

.modal-close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #fff;
}
</style>
