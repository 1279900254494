

export default [
  {
    path: "ds-pending-payments",
    name: "ds-pending-payments",
    redirect: { name: "ds-pending-payments-automatic-declined" },
    component: () => import(/* webpackChunkName: "DSPendingPayments" */ "@/views/commons/pending-payments/PendingPayments.vue"),
    meta: {
      pageTitle: "Pending Payments",
      breadcrumb: [
        {
          text: "Pending Payments",
          active: true,
        },
      ],
      program_id: 4, 
    },
    children: [
      {
        path: "automatic-declined",
        name: "ds-pending-payments-automatic-declined",
        component: () => import(/* webpackChunkName: "DSPendingPaymentsOfTheMonth" */ "@/views/commons/pending-payments/TabAutomaticDeclined.vue"),
        redirect: {
          name: "ds-pending-payments-automatic-declined-day",
          params: {
            day: '5'
          }
        },
        meta: {
          pageTitle: "Pending Payments",
          breadcrumb: [
            {
              text: "Automatic Declined",
              active: true,
            },
          ],
          parentModule: 21, 
        },
        children: [
          {
            path: ':day',
            name: 'ds-pending-payments-automatic-declined-day',
            component: () => import('@/views/commons/pending-payments/components/PendingPaymentGrid.vue'),
            meta: {
              pageTitle: "Pending Payments",
              breadcrumb: [
                {
                  text: "Automatic Declined",
                  active: true,
                },
              ],
              typeTransaction:1,
              pendingPaymentDayTab :'ds-pending-payments-automatic-declined-day',
              pendingPaymentTab: "ds-pending-payments-automatic-declined",
              manualTab: "ds-pending-payments-automatic-manual",
              othersTab: "ds-pending-payments-automatic-others"
            },
          },
        ]
      },
      {
        path: "manual",
        name: "ds-pending-payments-automatic-manual",
        component: () => import(/* webpackChunkName: "DSPendingPaymentsOfTheMonth" */ "@/views/commons/pending-payments/components/ManualOthersGrid.vue"),
        // redirect: {
        //   name: "ds-pending-payments-of-the-month-automatic-declined",
        // },
        meta: {
          pageTitle: "Pending Payments",
          breadcrumb: [
            {
              text: "Manual",
              active: true,
            },
          ],
          parentModule: 21, 
          typeTransaction:2,
          pendingPaymentTab: "ds-pending-payments-automatic-declined",
          manualTab: "ds-pending-payments-automatic-manual",
          othersTab: "ds-pending-payments-automatic-others"
        },
      },
      {
        path: "others",
        name: "ds-pending-payments-automatic-others",
        component: () => import(/* webpackChunkName: "DSlPendingPaymentsOfTheMonth" */ "@/views/commons/pending-payments/components/ManualOthersGrid.vue"),
        // redirect: {
        //   name: "ds-pending-payments-of-the-month-automatic-declined",
        // },
        meta: {
          pageTitle: "Pending Payments",
          breadcrumb: [
            {
              text: "Others",
              active: true,
            },
          ],
          parentModule: 21, 
          typeTransaction:3,
          pendingPaymentTab: "ds-pending-payments-automatic-declined",
          manualTab: "ds-pending-payments-automatic-manual",
          othersTab: "ds-pending-payments-automatic-others"
        },
      },
    ],
  },
];
