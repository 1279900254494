import UsersRouter from "@/views/users/views/users/users.router"
import EditUserRouter from "@/views/users/commons/users/router/user-create.router"
import SessionUserRouter from "@/views/users/commons/users/router/user-session.router"
import UsersMain from "@/views/users/views/UsersMain.vue"
const routes = [
  {
    path: "/users",
    meta: { module: 1 },
    redirect: { name: "users-users" },
    component: UsersMain,
    children: [
      ...UsersRouter,
      ...EditUserRouter,
      ...SessionUserRouter],
  },
]

// eslint-disable-next-line array-callback-return
// routes.map((route) => {
//   if (route.children) {
//     // eslint-disable-next-line array-callback-return
//     route.children.map((child) => {
//       if (child.meta) {
//         // eslint-disable-next-line array-callback-return,no-param-reassign
//         child.meta.module = 1;
//       } else {
//         // eslint-disable-next-line array-callback-return,no-param-reassign
//         child.meta = { module: 1 };
//       }
//     });
//   }
//   if (route.meta) {
//     // eslint-disable-next-line array-callback-return,no-param-reassign
//     route.matched[0].meta.module = 1;
//   } else {
//     // eslint-disable-next-line array-callback-return,no-param-reassign
//     route.meta = { module: 1 };
//   }
// });

export default routes
