import {
    toRevisionWpNotification,
    assignCreditAnalystNotification,
    returnWpNotification,
    completeWpNotification,
  } from "@/views/commons/components/request-workplan/socket/requestWp";

  const subscribeWorkPlanSocket = (socket) => {
    try {
      socket.subscribe("request-wp-notifications");
      toRevisionWpNotification();
      assignCreditAnalystNotification();
      returnWpNotification();
      completeWpNotification();
    } catch (error) {
      console.error(error, "error");
    }
  };

  export default subscribeWorkPlanSocket;
