import { amgApi } from "@/service/axios";

class PotencialAppointmentService {
    constructor() {
        this.url = '/potential-appointment';
    }
    async search(params) {
        const { data } = await amgApi.post(`${this.url}/index`, params);
        return data;
    }
    async updateStatus(params) {
        const { data } = await amgApi.post(`${this.url}/update`, params);
        return data;
    }
    async getTracking(params) {
        const { data } = await amgApi.post(`${this.url}/tracking/index`, params);
        return data;
    }
    async getDataBank(params) {
        const { data } = await amgApi.post(`${this.url}/bank/index`, params);
        return data;
    }
    async updateDataBank(params) {
        const { data } = await amgApi.post(`${this.url}/bank/update`, params);
        return data;
    }
    async getFiles(params) {
        const { data } = await amgApi.post(`${this.url}/files/index`, params);
        return data;
    }
    async uploadFiles(params) {
        const { data } = await amgApi.post(`${this.url}/files/store`, params);
        return data;
    }
    async deleteFile(params) {
        const { data } = await amgApi.post(`${this.url}/files/delete`, params);
        return data;
    }
    async getCountPending(params) {
        const { data } = await amgApi.post(`${this.url}/utils/get-count-pending-potential`, params);
        return data;
    }
    async removeRegister(params) {
        try {
            const { data } = await amgApi.post(`${this.url}/destroy`, params)
            return data;
        } catch (error) {
            throw error;
        }
    }
    async setPotentialInProcess(params) {
        try {
          const { data } = await amgApi.post(
            `${this.url}/utils/set-in-process`, params
          );
          return data;
        } catch (error) {
          throw error;
        }
      }
}
export default new PotencialAppointmentService();