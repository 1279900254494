import store from "@/store"
export default [
  {
    path: "employee-claims",
    name: "lg-employee-claims",
    redirect: { name: "lg-employee-claims-others-claims"},
    component: () =>
      import(
        /* webpackChunkName: "LGemployee-claims" */ "@/views/commons/employee-claims/EmployeeClaims.vue"
      ),
    children: [
      {
        path: "others-claims",
        name: "lg-employee-claims-others-claims",
        component: () =>
          import(
            /* webpackChunkName: "LGemployee-claims-grid" */ '@/views/commons/employee-claims/Claims.vue'
          ),
        redirect: { name: 'list-claims-pending-logistic' },
        meta: {
          pageTitle: "Staff Claims",
          breadcrumb: [
            {
              text: "Other Claims",
              active: true,
            },
          ],
          tabOthersClaimsRoute: 'lg-employee-claims-others-claims',
          tabEmployeeClaimListRoute: 'lg-employee-claims-list',
          listClaimsPendingRoute: 'list-claims-pending-logistic',
          listClaimsResolvedRoute: 'list-claims-resolved-logistic',
          listClaimsNotResolvedRoute: 'list-claims-not-resolved-logistic',
          listClaimsRejectedRoute: 'list-claims-rejected-logistic',
          employeeClaimsDashboardRoute: 'dashboard-claims-logistic',
        },
        children: [
          {
            path: 'pending',
            component: () => import(/* webpackChunkName: "LogisticClaimsMain" */ "@/views/commons/employee-claims/components/ListClaims.vue"),
            name: 'list-claims-pending-logistic',
            meta: {
              pageTitle: "Staff Claims",
  
              pageCodeIcon: "workers_claim",
              breadcrumb: [{
                text: 'Pending',
                active: true,
              }],
              status: 1,
              tabOthersClaimsRoute: 'lg-employee-claims-others-claims',
              tabEmployeeClaimListRoute: 'lg-employee-claims-list',
              listClaimsPendingRoute: 'list-claims-pending-logistic',
              listClaimsResolvedRoute: 'list-claims-resolved-logistic',
              listClaimsNotResolvedRoute: 'list-claims-not-resolved-logistic',
              listClaimsRejectedRoute: 'list-claims-rejected-logistic',
              employeeClaimsDashboardRoute: 'dashboard-claims-logistic',
            },
          },
          {
            path: 'resolved',
            component: () => import(/* webpackChunkName: "LogisticClaimsMain" */ "@/views/commons/employee-claims/components/ListClaims.vue"),
            name: 'list-claims-resolved-logistic',
            meta: {
              pageTitle: "Staff Claims",
  
              pageCodeIcon: "workers_claim",
              breadcrumb: [{
                text: 'Resolved',
                active: true,
              }],
              status: 2,
              tabOthersClaimsRoute: 'lg-employee-claims-others-claims',
              tabEmployeeClaimListRoute: 'lg-employee-claims-list',
              listClaimsPendingRoute: 'list-claims-pending-logistic',
              listClaimsResolvedRoute: 'list-claims-resolved-logistic',
              listClaimsNotResolvedRoute: 'list-claims-not-resolved-logistic',
              listClaimsRejectedRoute: 'list-claims-rejected-logistic',
              employeeClaimsDashboardRoute: 'dashboard-claims-logistic',
            },
          },
          {
            path: 'rejected',
            component: () => import(/* webpackChunkName: "LogisticClaimsMain" */ "@/views/commons/employee-claims/components/ListClaims.vue"),
            name: 'list-claims-rejected-logistic',
            meta: {
              pageTitle: "Staff Claims",
  
              pageCodeIcon: "workers_claim",
              breadcrumb: [{
                text: 'Rejected',
                active: true,
              }],
              status: 3,
              tabOthersClaimsRoute: 'lg-employee-claims-others-claims',
              tabEmployeeClaimListRoute: 'lg-employee-claims-list',
              listClaimsPendingRoute: 'list-claims-pending-logistic',
              listClaimsResolvedRoute: 'list-claims-resolved-logistic',
              listClaimsNotResolvedRoute: 'list-claims-not-resolved-logistic',
              listClaimsRejectedRoute: 'list-claims-rejected-logistic',
              employeeClaimsDashboardRoute: 'dashboard-claims-logistic',
            },
          },
        ]
      },
      {
        path: "my-claims",
        name: "lg-employee-claims-list",
        component: () =>
          import(
            /* webpackChunkName: "LGemployee-claims-grid" */ "@/views/commons/employee-claims/components/Grid.vue"
          ),
        meta: {
          pageTitle: "Staff Claims",
          breadcrumb: [
            {
              text: "My Claims",
              active: true,
            },
          ],
          tabOthersClaimsRoute: 'lg-employee-claims-others-claims',
          employeeClaimsDashboardRoute: 'dashboard-claims-logistic',
        },
      },

    ],
    meta: {
      pageTitle: "Employee Claim",
      breadcrumb: [
        {
          text: "Staff Claims",
          active: true,
        },
      ],
      tabOthersClaimsRoute: 'lg-employee-claims-others-claims',
      tabEmployeeClaimListRoute: 'lg-employee-claims-list',
      listClaimsPendingRoute: 'list-claims-pending-logistic',
      listClaimsResolvedRoute: 'list-claims-resolved-logistic',
      listClaimsNotResolvedRoute: 'list-claims-not-resolved-logistic',
      listClaimsRejectedRoute: 'list-claims-rejected-logistic',
      employeeClaimsDashboardRoute: 'dashboard-claims-logistic',
    },
  },
];
