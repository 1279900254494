export default [
  {
    path: "status-change",
    name: "administration-status-change",
    redirect: { name: "administration-status-change-loyal-tab" },
    component: () =>
      import(
        /* webpackChunkName: "AdministrationStatusChange" */ "@/views/administration/views/status-change/StatusChange.vue"
      ),
    meta: {
      permittedRoles: [1, 2, 6],
      pageTitle: "Status Change",
      breadcrumb: [
        {
          text: "Status Change",
        },
      ],
    },
    children: [
      {
        path: "",
        name: "administration-status-change-loyal-tab",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationStatusChangeLoyalTab" */ "@/views/crm/views/status-change/components/LoyalTab.vue"
          ),
        meta: {
          permittedRoles: [1, 2, 6],
          pageTitle: "Status Change",
          breadcrumb: [
            {
              text: "Loyal",
              to: { name: "administration-status-change-loyal-tab" },
            },
          ],
        },
      },
      {
        path: "",
        name: "administration-status-change-hold-tab",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationStatusChangeHoldTab" */ "@/views/crm/views/status-change/components/HoldTab.vue"
          ),
        meta: {
          permittedRoles: [1, 2, 6],
          pageTitle: "Status Change",
          breadcrumb: [
            {
              text: "Hold",
              to: { name: "administration-status-change-hold-tab" },
            },
          ],
        },
      },
    ],
  },
];
