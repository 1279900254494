import { sumTag } from "@/@core/utils/utils.js";
import customerTickets from "@/views/management/views/customer-tickets/management-customer-tickets.navigation";
import clients from "@/views/management/views/clients/clients.nav";

export default {
  title: "Clients",
  icon: "UsersIcon",
  children: [clients, customerTickets],
  tag: sumTag([clients, customerTickets]),
};
