import Vue from "vue";
import Vuex from "vuex";



import ClientsStore from '@/views/tax-research/views/clients/store/index'
Vue.use(Vuex);

export default {
  TaxResearchClients: ClientsStore,
};
