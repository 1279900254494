var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isClient)?_c('b-row',{staticClass:"client-info"},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"mr-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v("Client | "+_vm._s(_vm.client.client_name))])])]),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"ml-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v("Program | "+_vm._s(_vm.client.program_name))])])])],1):_c('b-row',{staticClass:"client-info"},[_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"mr-1",class:_vm.isDarkSkin ? 'border-custom' : 'border-custom-light'},[_c('p',[_vm._v(" Lead | "+_vm._s(_vm.lead.first_name ? _vm.lead.first_name + " " + _vm.lead.last_name : _vm.lead.lead_name)+" ")])])]),_c('b-col',{staticClass:"p-0",attrs:{"cols":"6"}})],1),_c('b-table',{staticClass:"table-new-customization custom-table",attrs:{"show-empty":"","sticky-header":"","responsive":"","fields":_vm.fields,"items":_vm.items,"busy":_vm.isBusy},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(main_row)",fn:function(data){return [_c('div',{staticClass:"w-100 d-flex justify-content-between"},[(!data.item.seeHideCell)?_c('span',[_vm._v(" "+_vm._s(data.item.main_row_hide ? data.item.main_row_hide : data.item.main_row)+" ")]):_c('span',[_vm._v(" "+_vm._s(data.item.main_row_hide ? data.item.main_row : data.item.main_row_hide)+" ")]),(
            data.item.main_row_hide &&
            (_vm.isSupervisor || _vm.isCeo || _vm.isCoordinator || _vm.isChief)
          )?_c('feather-icon',{staticClass:"cursor-pointer mr-3",attrs:{"size":"16","icon":!data.item.seeHideCell ? 'EyeIcon' : 'EyeOffIcon'},on:{"click":function($event){data.item.seeHideCell = !data.item.seeHideCell}}}):_vm._e()],1)]}},{key:"cell(created_by)",fn:function(data){return [_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-2 p-0"},[_c('b-avatar',{attrs:{"variant":"info","src":data.item.user_image_created
                ? data.item.user_image_created
                : require('@/assets/images/logo/amg_logo.svg')}})],1),_c('div',{staticClass:"col-9 px-1"},[_c('span',[_vm._v(_vm._s(data.item.created_name_user))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.created_at)))])])])]}},{key:"cell(updated_by)",fn:function(data){return [(data.item.updated_at != null)?_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-2 p-0"},[_c('b-avatar',{attrs:{"variant":"info","src":data.item.user_image_updated
                ? data.item.user_image_updated
                : require('@/assets/images/logo/amg_logo.svg')}})],1),_c('div',{staticClass:"col-9 px-1"},[_c('span',[_vm._v(_vm._s(data.item.updated_name_user))]),_vm._v(" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm._f("myGlobalDay")(data.item.updated_at)))])])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }