<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="25" cy="25" r="25" fill="var(--surface-card)" />
    <path
      d="M24.7231 38.7186C23.3316 38.7729 21.9581 38.6403 20.6088 38.297C19.5425 38.0199 18.5667 37.5681 17.8016 36.7488C17.7354 36.6765 17.657 36.5621 17.657 36.4717C17.6992 35.3934 17.7052 34.3031 17.8197 33.2309C17.9944 31.5803 18.9462 30.496 20.5003 29.9659C21.169 29.737 21.8738 29.5924 22.5665 29.4479C22.7292 29.4117 22.9701 29.5021 23.1026 29.6165C23.8797 30.2852 25.2291 30.2792 25.964 29.5623C26.1086 29.4177 26.2411 29.3695 26.4399 29.4298C27.1989 29.6406 27.988 29.7852 28.729 30.0502C30.2169 30.5924 31.0482 31.7068 31.2229 33.267C31.3373 34.3091 31.3494 35.3693 31.3915 36.4175C31.3976 36.5199 31.3192 36.6404 31.247 36.7247C30.5422 37.5259 29.6145 37.9355 28.6266 38.2367C27.3495 38.6343 26.0363 38.6885 24.7231 38.7186ZM26.4459 30.6526C26.3977 30.6767 26.3616 30.6948 26.3254 30.7129C25.0966 31.3635 23.8797 31.3996 22.6629 30.6828C22.5424 30.6105 22.3617 30.5743 22.2352 30.6105C21.5846 30.8093 20.922 30.99 20.3076 31.261C19.6389 31.5562 19.2052 32.0984 19.0184 32.8333C18.7654 33.8333 18.6811 34.8453 18.7173 35.8693C18.7233 36.05 18.8438 36.297 18.9943 36.3994C19.3618 36.6524 19.7654 36.8753 20.1811 37.038C21.1991 37.4355 22.2713 37.5681 23.3617 37.5922C24.8556 37.6283 26.3495 37.6765 27.8254 37.3392C28.6808 37.1404 29.5301 36.9235 30.1807 36.285C30.2651 36.2006 30.3434 36.05 30.3374 35.9356C30.3072 35.1947 30.2651 34.4537 30.1988 33.7188C30.0663 32.1586 29.2591 31.2189 27.735 30.8514C27.6326 30.8273 27.5061 30.8213 27.4158 30.8574C26.8977 31.0502 26.8375 31.0442 26.4459 30.6526Z"
      fill="white"
    />
    <path
      d="M28.4399 24.2974C28.4459 25.4841 28.1869 26.5865 27.464 27.5503C25.7894 29.7792 22.922 29.6768 21.41 27.3275C20.8979 26.5323 20.6269 25.6709 20.6269 24.7191C20.6269 23.8758 20.6088 23.0384 20.8979 22.2252C21.3738 20.8638 22.3136 20.0807 23.7533 19.9783C24.3978 19.9301 25.0665 19.9241 25.699 20.0385C27.1568 20.3036 28.0965 21.3819 28.3134 22.924C28.3796 23.3758 28.4037 23.8396 28.4399 24.2974ZM27.3736 24.3336C27.2833 23.7613 27.2411 23.177 27.0965 22.6228C26.8857 21.8156 26.3496 21.2795 25.5123 21.1048C24.8677 20.9722 24.2231 20.9542 23.5726 21.0927C22.681 21.2855 22.1148 21.8216 21.8979 22.7011C21.8015 23.1047 21.7473 23.5264 21.7112 23.948C21.6148 25.1046 21.8377 26.1829 22.5967 27.1046C23.6328 28.3636 25.3135 28.3937 26.3797 27.1528C27.0724 26.3456 27.3616 25.3998 27.3736 24.3336Z"
      fill="white"
    />
    <path
      d="M35.5782 20.7193C35.5661 21.9722 35.3131 22.9843 34.5903 23.8397C33.3313 25.3336 31.1808 25.3336 29.9157 23.8397C29.1025 22.8758 28.8856 21.7373 28.9519 20.5205C28.97 20.1651 29.0242 19.8036 29.0965 19.4482C29.3917 18.1169 30.223 17.3459 31.5783 17.2073C32.1506 17.1471 32.753 17.1531 33.3132 17.2676C34.5421 17.5085 35.283 18.4181 35.4878 19.8036C35.5421 20.1591 35.5601 20.5205 35.5782 20.7193ZM30.0181 20.8759C30.0121 21.5265 30.1205 22.1469 30.4458 22.7072C30.8615 23.418 31.4458 23.8939 32.3193 23.8577C33.2771 23.8216 33.8674 23.2312 34.2047 22.406C34.6083 21.4241 34.5361 20.388 34.265 19.394C34.1023 18.7976 33.6325 18.4061 33 18.3338C32.5181 18.2796 32.0181 18.2736 31.5362 18.3338C30.8434 18.4181 30.3796 18.8519 30.2109 19.5205C30.0964 19.9542 30.0784 20.4241 30.0181 20.8759Z"
      fill="white"
    />
    <path
      d="M20.1148 20.8759C20.0967 21.8276 19.8979 22.7132 19.3558 23.5023C18.5787 24.6468 17.4944 25.1468 16.139 24.8938C15.2053 24.7191 14.5005 24.1167 14.0547 23.2915C13.2415 21.7795 13.2355 20.2132 13.9041 18.653C14.2475 17.8458 14.9282 17.3639 15.7776 17.2495C16.4342 17.1591 17.1269 17.1471 17.7835 17.2495C18.9582 17.4302 19.7293 18.2976 19.9582 19.5627C20.0365 20.0024 20.0606 20.4421 20.1148 20.8759ZM19.0666 21.0265C19.0425 21.0204 19.0184 21.0084 18.9943 21.0024C18.9702 20.653 18.9522 20.3036 18.916 19.9542C18.8257 19.0627 18.3076 18.4241 17.5245 18.3278C17.0305 18.2675 16.5185 18.2675 16.0306 18.3278C15.3318 18.4181 14.868 18.8398 14.7113 19.5506C14.5366 20.3458 14.5126 21.1409 14.6632 21.9421C14.6993 22.1288 14.7535 22.3156 14.8378 22.4842C15.2475 23.2613 15.8017 23.8697 16.7534 23.8577C17.7052 23.8457 18.3317 23.2975 18.6811 22.4602C18.8739 22.0084 18.9401 21.5024 19.0666 21.0265Z"
      fill="white"
    />
    <path
      d="M31.4699 31.9237C32.3915 31.8635 33.2831 31.8394 34.1686 31.7309C34.771 31.6587 35.3613 31.4779 35.9516 31.3153C36.1685 31.2551 36.3733 31.1286 36.5721 31.0081C36.8612 30.8334 37.0299 30.6105 36.9697 30.219C36.8552 29.4961 36.8191 28.7672 36.6986 28.0443C36.5962 27.4058 36.1986 26.948 35.5962 26.713C35.1324 26.5323 34.6505 26.3817 34.1686 26.2432C34.0601 26.2131 33.9095 26.2552 33.8011 26.3094C32.765 26.8817 31.7349 26.8697 30.6988 26.3155C30.5663 26.2432 30.3675 26.2311 30.2169 26.2733C29.735 26.3998 29.2651 26.5624 28.723 26.7311C28.8133 26.3396 28.8796 25.9721 28.994 25.6167C29.0302 25.5143 29.1928 25.4179 29.3133 25.3818C29.7591 25.2553 30.2048 25.1408 30.6566 25.0685C30.7952 25.0444 30.994 25.1227 31.1084 25.2251C31.7951 25.8095 32.7529 25.7914 33.4035 25.183C33.4879 25.1047 33.6565 25.0324 33.7589 25.0565C34.4035 25.2071 35.0541 25.3456 35.6745 25.5625C36.9396 25.9962 37.6865 26.8998 37.8492 28.225C37.9516 29.0804 37.9757 29.9419 37.9998 30.8033C38.0058 30.978 37.8913 31.1888 37.7709 31.3334C37.3673 31.8093 36.8311 32.1044 36.2468 32.3273C35.0481 32.7791 33.7951 32.9116 32.524 32.9719C31.7891 33.008 31.7891 33.008 31.5723 32.3153C31.5542 32.1888 31.512 32.0562 31.4699 31.9237Z"
      fill="white"
    />
    <path
      d="M17.5366 31.9237C17.4462 32.255 17.38 32.5562 17.2776 32.8454C17.2535 32.9116 17.139 32.996 17.0727 32.996C15.621 32.9417 14.1632 32.8935 12.7837 32.3393C12.2295 32.1165 11.6874 31.8635 11.2958 31.4056C11.1452 31.2309 10.9886 30.9659 11.0007 30.749C11.0549 29.7491 11.1031 28.7431 11.2778 27.7612C11.4585 26.7311 12.1452 26.0263 13.1211 25.6649C13.7837 25.4179 14.4825 25.2492 15.1692 25.0805C15.3017 25.0504 15.5065 25.1227 15.621 25.2191C16.3499 25.8275 17.2595 25.8094 17.9583 25.1649C18.0305 25.0986 18.1751 25.0444 18.2715 25.0625C18.7594 25.1589 19.2474 25.2854 19.7353 25.3998C19.9341 25.448 20.0485 25.5564 20.0847 25.7612C20.1389 26.0805 20.2112 26.3938 20.2835 26.7371C19.7474 26.5685 19.2775 26.4058 18.7956 26.2793C18.657 26.2432 18.4703 26.2552 18.3438 26.3215C17.2836 26.8937 16.2414 26.8998 15.1873 26.3094C15.0969 26.2612 14.9644 26.213 14.88 26.2432C14.2777 26.454 13.6753 26.6709 13.0909 26.9239C12.7657 27.0624 12.5729 27.3576 12.4464 27.6889C12.1091 28.5865 12.0488 29.5262 12.085 30.4719C12.097 30.7671 12.2898 30.9298 12.5307 31.0623C13.3921 31.5442 14.3379 31.7068 15.3017 31.7972C15.8198 31.8454 16.3439 31.8514 16.8619 31.8755C17.0788 31.8876 17.2956 31.9056 17.5366 31.9237Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      required: false,
      default: "50",
    },
    height: {
      type: String,
      required: false,
      default: "50",
    },
  },
};
</script>
