import SalesSellerServices from '@/views/ce-digital/sub-modules/settings/views/permissions/service/sales-seller.services'
import otherPaymentsService from "@/views/management/views/approval-process/other-payments/other-payments.service";

export default {
    namespaced: true,
    state: {
        S_COUNTER_SELLERS: 0,
        S_COUNTER_APPROVAL_PROCESS: 0,
        S_COUNTER_DISAPPROVED_SALE: 0,
    },
    getters: {
        S_COUNTER_SELLERS: state => state.S_COUNTER_SELLERS,
        S_COUNTER_APPROVAL_PROCESS: state => state.S_COUNTER_APPROVAL_PROCESS,
        G_COUNTER_DISAPPROVED_SALE: state => state.S_COUNTER_DISAPPROVED_SALE,
    },
    mutations: {
        SET_COUNTER_SELLERS(state, payload) {
            state.S_COUNTER_SELLERS = payload
        },
        SET_COUNTER_APPROVAL_PROCESS(state, payload) {
            state.S_COUNTER_APPROVAL_PROCESS = payload
        },
        M_COUNTER_DISAPPROVED_SALE(state, payload) {
            state.S_COUNTER_DISAPPROVED_SALE = payload
        }

    },
    actions: {
        async GET_COUNTER_SELLERS({ commit }, body) {
            try {
                let res = await SalesSellerServices.countSellersPending(body);
                res = res.data[0].total
                commit('SET_COUNTER_SELLERS', res)
                return res
            } catch (err) {
                console.log("ERROR_GET_COUNTER [ACTION]", err);
                throw err
            }
        },

        async GET_APPROVAL_PROCESS({ commit }) {
            try {
                let res = await SalesSellerServices.getCounterApprovalProcess();
                res = res.data[0].total
                commit('SET_COUNTER_APPROVAL_PROCESS', res)
                return res
            } catch (err) {
                console.log("ERROR_GET_COUNTER [ACTION]", err);
                throw err
            }
        },
        
        async A_COUNTER_DISAPPROVED_SALE({ commit }) {
            try {
                    const res = await otherPaymentsService.getDisapprovedSaleCounter();
                commit('M_COUNTER_DISAPPROVED_SALE', res.data)
                return res
            } catch (err) {
                console.log("ERROR_A_COUNTER_DISAPPROVED_SALE", err);
                throw err
            }
        }

    }
}