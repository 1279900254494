export default {
  namespaced: true,
  state: {
      refreshTable: false,
      S_IsActive: false,
      S_DataAppointment: null,
      S_Title: null,
      S_DetailAppointment:null,
      S_SHOW_TOAST: false,
      S_DataAppointmentNotice: null,
      S_IsActiveNotice: false,
  },
  getters: {
      getRefreshTable: (state) => state.refreshTable,
      G_IsActive(state) {
          return state.S_IsActive;
      },
      G_IsActiveNotice(state) {
        return state.S_IsActiveNotice;
      },
      G_DataAppointment(state) {
          return state.S_DataAppointment;
      },
      G_DataAppointmentNotice(state) {
        return state.S_DataAppointmentNotice;
      },
      G_Title(state) {
          return state.S_Title;
      },
      G_DetailAppointment(state){
          return state.S_DetailAppointment;
      },
      G_SHOW_TOAST(state){
          return state.S_SHOW_TOAST;
      },
      G_PENDING_APPOINTMENTS(state){
        return state.S_PENDING_APPOINTMENTS;
      }
  },
  mutations: {
      SET_REFRESH_TABLE(state, payload) {
        state.refreshTable = payload
      },
      M_SHOW_TOAST(state,params){
          state.S_SHOW_TOAST = params;
      },
      M_OpenAlert(state, payload) {
          state.S_IsActive = payload;
      },
      M_OpenNotice(state, payload) {
        state.S_IsActiveNotice = payload;
      },
      M_DataAppointment(state, payload) {
          state.S_DataAppointment = payload;
      },
      M_DataAppointmentNotice(state, payload) {
        state.S_DataAppointmentNotice = payload;
    },
      M_Title(state, payload) {
          state.S_Title = payload;
      },
      M_RESET(state){
          state.S_IsActive = false;
          state.S_Title = '';
          state.S_DataAppointment = null;
      },
      M_DetailAppointment(state,payload){
          state.S_DetailAppointment = payload;
      }
  },
  actions: {
    CHANGE_REFRESH_TABLE({ commit }, data) {
        commit("SET_REFRESH_TABLE", data);
    },
  }
}
