<template>
  <b-modal
    ref="modal-view-documents-case"
    title-tag="h3"
    size="xmd"
    modal-class="modal-primary"
    hide-footer
    no-close-on-backdrop
    @hidden="close"
    scrollable
  >
    <template #modal-title>
      <span>
        HISTORY FILES - CASE:
        <strong>{{ caseNumber }}</strong></span
      >
    </template>
    <div>
      <b-card v-if="caseInfo">
        <b-row>
          <b-col cols="12" md="6">
            <span>
              {{ caseInfo.is_lead == 1 ? "LEAD" : "CLIENT" }}
            </span>
            <div class="border border-primary rounded">
              <p
                class="text-primary d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ caseInfo.name_lead }}
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <span>ACCOUNT</span>
            <div class="border border-primary rounded">
              <p
                class="text-primary d-flex align-items-center justify-content-center my-1s"
                style="gap: 5px"
              >
                {{ caseInfo.is_lead == 1 ? "-" : caseInfo.account }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <b-nav card-header pills class="m-0">
        <b-nav-item
          @click="selectedTabs(2)"
          exact
          :active="selectedTab == 2"
          exact-active-class="active border-radius-tabs"
          :link-classes="['px-3', bgTabsNavs]"
        >
          CASE FORMATS
        </b-nav-item>
        <b-nav-item
          @click="selectedTabs(1)"
          exact
          :active="selectedTab == 1"
          exact-active-class="active border-radius-tabs"
          :link-classes="['px-3', bgTabsNavs]"
        >
          FILES
        </b-nav-item>
      </b-nav>
      <b-card
        no-body
        class="border-3 border-table-radius"
        :class="'border-top-primary'"
      >
      </b-card>
      <case-files v-if="selectedTab == 1" :case-id="caseId" />
      <case-formats v-if="selectedTab == 2" :case-id="caseId" />
    </div>
  </b-modal>
</template>
<script>
import CaseFormats from "@/views/debt-solution/views/court-info/components/ListCaseFormats.vue";
import CaseFiles from "@/views/debt-solution/views/court-info/components/ListCaseFiles.vue";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";

export default {
  components: {
    CaseFormats,
    CaseFiles,
  },
  props: {
    caseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedTab: 2,
      caseNumber: null,
      caseInfo: null,
    };
  },
  created() {
    this.getCourtCase();
  },
  async mounted() {
    this.toggleModal("modal-view-documents-case");
  },

  methods: {
    close() {
      this.$emit("close");
    },

    selectedTabs(tab) {
      return (this.selectedTab = tab);
    },

    async getCourtCase() {
      try {
        this.addPreloader();
        const data = await CourtInfoService.getCourtCase({
          case_id: this.caseId,
        });
        this.caseInfo = data[0];
        this.caseNumber = data[0].case_number.toUpperCase();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

