<template>
  <b-modal
    size="xl"
    v-model="showModal"
    @hidden="close"
    hide-footer
    title="Notes Version"
  >
    <div class="mt-2 d-flex justify-content-center align-items-center">
      <b-table-simple
        small
        caption-top
        responsive
        bordered
        sticky-header="70vh"
      >
        <b-thead>
          <b-tr>
            <b-th v-for="field in fields" :key="field">
              <div class="d-flex justify-content-center align-items-center">
                {{ field }}
              </div>
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="item in items" :key="item.id">
            <b-td :style="columnStyles[0]">
              <span> {{ item.created_by }}</span
              ><br />
              <span> {{ item.created_at | myGlobalWithHour }}</span>
            </b-td>
            <b-td :style="columnStyles[1]">
              <div
                v-if="item.summary != null && item.summary != ''"
                class="p-1"
                style="max-height: 350px !important; overflow-y: auto"
                v-html="item.summary"
              ></div>
              <div v-else class="mt-1">
                <b-skeleton width="55%"></b-skeleton>
                <b-skeleton width="70%"></b-skeleton>
                <b-skeleton width="85%"></b-skeleton>
              </div>
            </b-td>
            <b-td :style="columnStyles[2]">
              <div
                class="p-1"
                style="max-height: 350px !important; overflow-y: auto"
                v-html="item.reason"
              ></div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </b-modal>
</template>
<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
export default {
  data() {
    return {
      showModal: true,
      items: [],
      fields: ["Created By", "Old Summary", "Reason"],
      columnStyles: [{ width: "10%" }, { width: "30%" }, { width: "20%" }],
    };
  },
  props: {
    noteId: {
      type: String,
      required: true,
    },
    reload: {
      type: Number,
    },
  },
  async created() {
    this.showAllNotesVersions();
  },
  methods: {
    async showAllNotesVersions() {
      try {
        const { data } = await ClientsOptionsServices.getAllNotesVersions({
          note_id: this.noteId,
        });
        if (data.data.length >= 0) {
          this.items = data.data;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    close() {
      this.$emit("close");
    },
  },
  watch: {
    reload(oldValue, newValue) {
      if (newValue > 0) {
        this.showAllNotesVersions();
      }
    },
  },
};
</script>