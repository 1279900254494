import AppointmentService from '@/views/commons/components/appointments/service/appointment.service'
import store from "@/store";

export default {
  namespaced: true,
  state: {
    S_PENDING_APPOINTMENTS: 0,
    S_IN_PROCESS_APPOINTMENTS: 0,
    S_COMPLETED_APPOINTMENTS: 0,
  },
  getters: {
    G_PENDING_APPOINTMENTS(state) {
      return state.S_PENDING_APPOINTMENTS;
    },
    G_IN_PROCESS_APPOINTMENTS(state) {
      return state.S_IN_PROCESS_APPOINTMENTS;
    },
    G_COMPLETED_APPOINTMENTS(state) {
      return state.S_COMPLETED_APPOINTMENTS;
    },
  },
  mutations: {
    M_PENDING_APPOINTMENTS(state, payload) {
      state.S_PENDING_APPOINTMENTS = payload;
    },
    M_IN_PROCESS_APPOINTMENTS(state, payload) {
      state.S_IN_PROCESS_APPOINTMENTS = payload;
    },
    M_COMPLETED_APPOINTMENTS(state, payload) {
      state.S_COMPLETED_APPOINTMENTS = payload;
    },
  },
  actions: {
    async A_GET_PENDING_APPOINTMENTS({ commit }) {
      const { modul_id, user_id, role_id } = store.state.auth.currentUser;
      const { pending, in_process, completed } = await AppointmentService.getCountAppointmentsPending({ module_id: modul_id !== 29 ? modul_id : null, user_id, role_id });

      commit("M_PENDING_APPOINTMENTS", pending);
      commit("M_IN_PROCESS_APPOINTMENTS", in_process);
      commit("M_COMPLETED_APPOINTMENTS", completed);
      
      const routesModules = {
        5: "debt-solution-appointment",
        6: "credit-experts-appointment",
        7: "boost-credit-appointment",
        20: "connection-appointment",
        22: "customerservice-appointment",
        29: "appointment-financial-approval",
      }
      const payload = {
        routeName: routesModules[modul_id],
        tag: modul_id == 29 ? pending + in_process + completed: pending,
      };
      store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
    }
  }
};
