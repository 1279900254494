export default [
  {
    path: 'request-transactions',
    name: 'request-transactions',
    component: () => import(/* webpackChunkName: "RequestTransactions" */ '@/views/management/views/requests-transactions/RequestTransactions.vue'),
    redirect: { name: 'requests-pending' },
    meta: {
      pageTitle: 'Request Transactions',
      breadcrumb: [{
        text: 'Request',
      }],
      permittedRoles: [],
    },
    children: [
      {
        path: 'pending',
        name: 'requests-pending',
        component: () => import(
          /* webpackChunkName: "RequestTransactions List" */
          '@/views/management/views/requests-transactions/views/RequestsList.vue'
        ),
        meta: {
          pageTitle: 'Request Transactions',
          permittedRoles: [],
          breadcrumb: [
            {
              text: 'Pending',
            },
          ],
          tab: 1,
        },
      },
      {
        path: 'proccesed',
        name: 'requests-proccesed',
        component: () => import(
          /* webpackChunkName: "RequestTransactions List" */
          '@/views/management/views/requests-transactions/views/RequestsList.vue'
        ),
        meta: {
          pageTitle: 'Request Transactions',
          permittedRoles: [],
          breadcrumb: [
            {
              text: 'Proccesed',
            },
          ],
          tab: 2,
        },
      },
    ],
  },
];
