import { amgApi } from '@/service/axios'

class DeletedCardsService {
  async getDeletedCards(body) {
    try {
      const data = await amgApi.post('/cards/get-deleted-cards', body)
      return data
    } catch (error) {
      console.log('ERRORS ON DeletedCardsService: getDeletedCards')
    }
  }
  async markAsSeen(body) {
    try {
      const data = await amgApi.post('/cards/mark-as-seen-deleted-card', body)
      return data
    } catch (error) {
      console.log('ERRORS ON DeletedCardsService: markAsSeen')
    }
  }
  async getNotSeenDeletedCards(body) {
    try {
      const data = await amgApi.get('/cards/get-not-seen-deleted-cards', body)
      return data
    } catch (error) {
      console.log('ERRORS ON DeletedCardsService: markAsSeen')
    }
  }
}

export default new DeletedCardsService()
