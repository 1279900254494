export default [
  {
    path: "schedules",
    name: "schedules-administration",
    redirect: { name: "report-administration" },
    component: () =>
      import(
        /* webpackChunkName: "PRSchedules" */ "@/views/commons/components/schedules/SchedulesComponent.vue"
      ),
    children: [
      {
        path: "report",
        name: "report-administration",
        component: () =>
          import(
            /* webpackChunkName: "PRSchedulesReport" */ "@/views/commons/components/schedules/SchedulesReport.vue"
          ),
        meta: {
          permittedRoles: [1, 2],
          route: "administration",
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Reports By Module",
              active: true,
            },
          ],
        },
      },
      {
        path: "report-by-user",
        name: "report-by-user-administration",
        component: () =>
          import(
            /* webpackChunkName: "PRSchedulesReportByUser" */ "@/views/commons/components/schedules/SchedulesReportByUser.vue"
          ),
        meta: {
          permittedRoles: [1, 2],
          route: "administration",
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Report by User",
              active: true,
            },
          ],
        },
      },
      {
        path: "overtime",
        name: "overtime-administration",
        component: () =>
          import(
            /* webpackChunkName: "PRSchedulesOvertime" */ "@/views/commons/components/schedules/SchedulesOvertime.vue"
          ),
        meta: {
          permittedRoles: [1, 2],
          route: "administration",
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Overtime",
              active: true,
            },
          ],
        },
      },
      {
        path: "justifications",
        name: "justifications-administration",
        component: () =>
          import(
            /* webpackChunkName: "PRSchedulesJustifications" */ "@/views/commons/components/schedules/SchedulesJustifications.vue"
          ),
        meta: {
          permittedRoles: [1, 2],
          route: "administration",
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Justifications",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      permittedRoles: [1, 2],
      route: "administration",
    },
  },
];
