var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle)?_c('b-row',{staticClass:"content-header",class:{ 'align-items-center': !_vm.detailBc }},[_c('b-col',{staticClass:"content-header-left mb-2 d-sm-block d-block",attrs:{"cols":"10","md":_vm.chargeBack
        ? '6'
        : _vm.clientsSearch == true
        ? _vm.globalSearch
          ? '6'
          : '3'
        : _vm.moduleId == 15
        ? '8'
        : '9'}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"content-header-title pr-1 mb-0",class:{ 'float-left': _vm.detailBc }},[_vm._v(" "+_vm._s(_vm.$route.meta.pageTitle)+" ")]),(_vm.detailBc)?_c('div',{staticClass:"breadcrumb-wrapper"},[_c('b-breadcrumb',[_c('b-breadcrumb-item',{attrs:{"to":_vm.toModule}},[_c('feather-icon',{staticClass:"align-text-top",attrs:{"icon":"HomeIcon","size":"16"}})],1),_vm._l((_vm.$route.meta.breadcrumb),function(item){return _c('b-breadcrumb-item',{key:item.text,attrs:{"active":item.active,"to":item.to}},[_vm._v(" "+_vm._s(item.text)+" ")])})],2)],1):_vm._e()])],1)],1),_c('b-col',{staticClass:"content-header-right text-md-right d-md-block d-none",class:{ 'mb-2': !_vm.noBorderBottom },attrs:{"md":_vm.chargeBack
        ? '6'
        : _vm.clientsSearch == true
        ? _vm.globalSearch
          ? '6'
          : '9'
        : _vm.globalSearch
        ? '6'
        : _vm.moduleId == 15
        ? '4'
        : '3',"sm":"12","cols":"12"}},[_vm._t("actions"),_vm._t("indicators")],2),(_vm.$slots.actionsDropdown)?_c('b-col',{staticClass:"d-sm-block content-header-right d-md-none d-lg-none d-xl-none d-block pl-0",attrs:{"cols":"2"}},[_c('b-dropdown',{staticClass:"dropCustom",attrs:{"variant":"outline-success","dropleft":"","text":"asd"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}})]},proxy:true}],null,false,1264634473)},[_vm._t("actionsDropdown")],2)],1):_vm._e(),(_vm.$slots.actionsButtons)?_c('b-col',{staticClass:"d-sm-block content-header-right d-md-none d-lg-none d-xl-none d-block pl-0 mb-2",attrs:{"cols":"2"}},[_vm._t("actionsButtons")],2):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }