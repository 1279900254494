

export default [
  {
    path: 'deleted-cards',
    name: 'ce-digital-deleted-cards',
    component: () => import(/* webpackChunkName: "CEDFinancialDeleteCards" */ "@/views/ce-digital/sub-modules/financial/view/deleted-cards/DeletedCards.vue"),
    meta: {
      pageTitle: 'App Deleted Cards',
      parentModule: 21,
      breadcrumb: [
        {
          text: 'App Deleted Cards',
          active: true,
        },
      ],
      permittedRoles: [1, 2, 14, 17],
    },
  },
]
