import ClientCompany from "../views/client-company/client-company.router";
import Clients from "../views/clients/clients.router";
// import Schedules from "../views/schedules/schedules.router"
import LoansRoute from "../views/loans/loans.router";
import FileManagerRouter from "../views/file-manager/file-manager.router";
import ClientDashboard from "@/views/paragon/views/clients/dashboard/router";
import CommissionsRouter from "../views/commissions/commissions.router";
import CalendarRouter from "../views/calendar/calendar.router";
import HelpdeskRouter from "@/views/paragon/views/helpdesk/helpdesk.route.js";
import BankOfFlyers from "../views/bank-of-flyers/bank-of-flyers.router";
import SalesMadeRouter from "../views/sales-made/sales-made.router";
import PaymentReportRouter from "../views/payment-report/report.router";
import PaymentsRouter from "../views/payments/payments.router";
import LeadsRouter from "../views/leads/leads.router";
import ProcessSale from "@/views/paragon/views/process-sale/process-sale.router";
import RoiReportsRouter from "@/views/paragon/views/roi-reports/roi-reports.router";
import UsersRouter from "@/views/paragon/views/users/users.router";
import ProductRequestRouter from "@/views/paragon/views/request/request.router.js";
import ChargeBack from "@/views/paragon/views/charge-back/charge-back.router";
import ClaimRouter from "@/views/paragon/views/claims/claims.router.js";
import ParagonAttendanceRouter from "@/views/paragon/views/attendance/paragon-attendance.router";
import OtherPaymentsRouter from "@/views/paragon/views/other-payments/other-payments.router";
import EmployeeClaimsRouter from "@/views/paragon/views/employee-claims/router/employee-claims.router";
//import ParagonProjectsRouter from "@/views/paragon/views/projects/paragon-projects.router";
import ParagonMain from "@/views/paragon/views/ParagonMain.vue";
import ParagonSettingsRouter from "@/views/paragon/views/settings/router/settings.router.js";
import CallLogRouter from "@/views/paragon/views/call-log/call-log.router.js";
import NewEmployeesRouter from "@/views/paragon/views/new-employees/new-employees.router";
import MOFRouter from "@/views/paragon/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/paragon/views/recruitment-process/paragon-recruitment_process.router";
import RequestWorkPlanRouter from "@/views/paragon/views/request-workplan/request-workplan.router.js";
import creativeRequestRouter from "@/views/paragon/views/creative-request/router/creative.request";
import CustomerTicketsRouter from "@/views/paragon/views/customer-tickets/paragon-customer-tickets.router";
import LettersRouter from "@/views/paragon/views/dispute/letters/router/letters.router.js";
import DirectRouter from "@/views/paragon/views/dispute/direct/router/direct.router.js";
import ParagonSoft from "@/views/paragon/views/paragon-soft/paragon-soft.router.js";
import InconsistentTransactionRouter from '@/views/paragon/views/inconsistent-transactions/router/inconsistent-transactions.router.js';
import CallImpressionRouter from '@/views/paragon/views/call-impression/router/call-impression.router.js';
import DashboardRouter from "@/views/paragon/views/dashboard/router/dashboard.router.js"


const routes = [
  {
    path: "/ti",
    redirect: { name: "paragon-dashboard-main" },
    meta: {
      module: 12,
      program: 9
    },
    component: ParagonMain,
    children: [
      ...ClientDashboard,
      ...LeadsRouter,
      ...Clients,
      ...ClientCompany,
      // ...Schedules,
      ...LoansRoute,
      FileManagerRouter,
      ...ChargeBack,
      ...ProcessSale,
      RoiReportsRouter,
      CommissionsRouter,
      ...CalendarRouter,
      HelpdeskRouter,
      ...BankOfFlyers,
      SalesMadeRouter,
      PaymentReportRouter,
      ...PaymentsRouter,
      ...UsersRouter,
      ...ProductRequestRouter,
      ...OtherPaymentsRouter,
      //...ParagonProjectsRouter,
      ...ParagonAttendanceRouter,
      ...EmployeeClaimsRouter,
      ...ClaimRouter,
      ...ParagonSettingsRouter,
      ...CallLogRouter,
      NewEmployeesRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      creativeRequestRouter,
      ...CustomerTicketsRouter,
      ...RequestWorkPlanRouter,
      ...LettersRouter,
      ...DirectRouter,

      ...ParagonSoft,
      ...InconsistentTransactionRouter,
      ...CallImpressionRouter,
      ...DashboardRouter,
    ],
  },
];

export default routes;
