<template>
  <div>
    <b-input-group>
      <b-input-group-prepend>
        <b-button
          variant="info"
          class="text-uppercase ml-1"
          @click="loadByMonth"
        >
          by month</b-button
        >
      </b-input-group-prepend>
      <b-form-select style="height: 40px" v-model="selected" :options="options"></b-form-select>
    </b-input-group>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "FilterButtonByMonth",
  data() {
    return {
      selected: new Date().getFullYear(),
      options: [],
      showModalMonths: false,
    };
  },
  watch: {
    selected(value) {
      this.$emit("changeYear", value);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    getParams() {
      return {
        user_id: this.currentUser.user_id,
        role_id: this.currentUser.role_id,
        year: this.selected,
        status: 4,
        module_id: this.moduleId,
      };
    },
  },
  created() {
    this.getYears();
  },
  methods: {
    getYears() {
      const date = new Date();
      const start = date.getFullYear();
      const end = 2018;
      let years = [];
      for (let i = start; i > end; i--) {
        years.push({
          text: i,
          value: i,
        });
      }
      this.options = years;
    },
    loadByMonth() {
      this.$emit("loadByMonth");
    },
  },
};
</script>
