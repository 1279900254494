import applicationsNavigation from "@/views/boost-credit/views/applications/applications.navigation";
import ZeroPayment from "@/views/boost-credit/views/zero-payment/navigation/zero-payment.navigation";
import RequestNcrNav from "@/views/boost-credit/views/request-ncr/request-ncr.nav";
import RequestWorkplanNav from "@/views/boost-credit/views/request-workplan/request-workplan.nav.js";
import DisputeRoute from "@/views/boost-credit/views/dispute/navigation/dispute.nav";
import disputeResultsNav from "@/views/boost-credit/views/dispute-results/navigation/dispute-result.navigation";
import reportsNav from "@/views/boost-credit/views/reports/reports.nav";
import PaymentsNavigation from "@/views/boost-credit/views/payments/payments.nav";
import trackingListNav from "@/views/boost-credit/views/tracking-list/navigation/tracking-list.nav";
import saleMadeNav from "@/views/boost-credit/views/sales-made/sales-made.nav";
import commissionNav from "@/views/boost-credit/views/commission/commission.nav";
import ChargeBackNav from "@/views/boost-credit/views/charge-back/charge-back.nav";
import calendarNav from "@/views/boost-credit/views/calendar/calendar.nav";
import helpdeskNav from "@/views/boost-credit/views/helpdesk/helpdesk.nav";
import FileManagerNav from "@/views/boost-credit/views/file-manager/file-manager.nav";
import loansNav from "@/views/boost-credit/views/loans/loans.nav";
import schedulesNav from "@/views/boost-credit/views/schedules/schedules.nav";
import performanceNav from "@/views/boost-credit/views/advisor-performance/performance.nav";
import ProductRequestNav from "@/views/boost-credit/views/request/request.nav.js";
import claimsNav from "@/views/boost-credit/views/claims-v2/claims.nav";
import OtherPaymentNavigation from "@/views/boost-credit/views/other-payments/other-payments.nav";
import clientsNav from "@/views/boost-credit/views/clients/clients-group.navigation";
import callRoundsRoute from "../views/call-rounds/call-rounds.navigation";
import leadsNav from "../views/leads/leads.nav";
import EmployeeClaimsNav from "@/views/boost-credit/views/employee-claims/navigation/employee-claims.nav";
import RingCentralNav from "@/views/boost-credit/views/ring-central/ring-central.nav.js";
import CourtInfoNavigation from "@/views/boost-credit/views/cour-info/navigation/ds-court-info.nav";

import MOFNavigation from "@/views/boost-credit/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/boost-credit/views/recruitment-process/boost-credit-recruitment_process.navigation";
import SendingReportNavigation from "@/views/boost-credit/views/sending-report/sending-report.nav.js";
import AppointmentNav from "@/views/boost-credit/views/appointment/appointment.navigation";
import OtherServicesNav from "@/views/boost-credit/views/other-services/other-services.navigation.js";
import CommissionForSpecialistsNavigation from "@/views/boost-credit/views/commission-specialists/commissions-specialists.navigation";

import { sumTag } from "@/@core/utils/utils";
import RequestClientStatusNavigation from "@/views/boost-credit/views/request-client-status/navigation/request-client-status.nav";
import InconsistentTransactionNavigation from "@/views/boost-credit/views/inconsistent-transactions/navigation/inconsistent-transactions.nav.js";
import bcDashboardNavigation from "@/views/boost-credit/views/dashboard/navigation/bc-dashboard.nav.js";
import CallImpressionNavigation from "@/views/boost-credit/views/call-impression/navigation/call-impression.navigation.js";

// import PendingPaymentNavigation from "@/views/boost-credit/views/pending-payments/navigation/pending-payments.navigation.js";

const navigation = [
  {
    header: "BOOST CREDIT",
  },
  bcDashboardNavigation,
  ...leadsNav,
  clientsNav,
  ...claimsNav,
  ...ZeroPayment,
  {
    title: "Services",
    icon: "CreditCardIcon",
    tag: sumTag([
      ...AppointmentNav,
      ...applicationsNavigation,
      ...OtherServicesNav,
      CommissionForSpecialistsNavigation,
    ]),
    children: [
      ...AppointmentNav,
      ...applicationsNavigation,
      ...OtherServicesNav,
      CommissionForSpecialistsNavigation,
    ],
  },
  ...saleMadeNav,
  // {
  //   title: "Payments",
  //   icon: "CreditCardIcon",
  //   children: [
  //     ...PaymentsNavigation,
  //     ...ZeroPayment,
  //     ...OtherPaymentNavigation,
  //     ...ChargeBackNav,
  //     ...PendingPaymentNavigation,
  //   ],
  //   tag: sumTag([
  //     ...PaymentsNavigation,
  //     ...ZeroPayment,
  //     ...OtherPaymentNavigation,
  //     ...ChargeBackNav,
  //   ]),
  // },
  // ...commissionNav,
  RequestNcrNav,
  RequestWorkplanNav,
  DisputeRoute,
  ...disputeResultsNav,
  ...trackingListNav,
  ...reportsNav,
  ...PaymentsNavigation,
  ...callRoundsRoute,
  calendarNav,
  ...ChargeBackNav,
  FileManagerNav,
  ...performanceNav,
  schedulesNav,
  loansNav,
  helpdeskNav,
  ProductRequestNav,
  ...OtherPaymentNavigation,
  EmployeeClaimsNav,
  ...CourtInfoNavigation,
  RingCentralNav,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  ...RequestClientStatusNavigation,
  ...SendingReportNavigation,
  ...InconsistentTransactionNavigation,
  ...CallImpressionNavigation,
];
export default navigation;
