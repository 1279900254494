
export default [
  {
    path: '/paragon-soft',
    name: 'paragon-soft',
    component: () => import(/* webpackChunkName: "ManaPayRoll" */ '@/views/commons/components/paragon-soft/ParagonSoftMain.vue'),
    meta: {
      pageTitle: 'Soft Projects',
      permittedRoles: [2,17,35],
    },
  },
];
