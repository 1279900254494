<template>
  <div>
    <b-modal
      v-model="onControl"
      size="caro"
      header-bg-variant="custom-amg"
      header-class="p-0"
      :title="`Relevant Information of the client ${customerTicket.name_client}`"
      title-class="h1 text-white font-weight-bolder p-2"
      no-close-on-backdrop
      hide-footer
      @hidden="close"
      header-close-variant="primary"
      modal-class="custom-modal-amg"
    >
      <b-row>
        <b-col>
          <div
            v-if="
              score.equifax !== '' &&
              score.experian !== '' &&
              score.transunion !== ''
            "
          >
            <div class="header-indicators text-white">
              <div v-if="score.transunion !== ''">
                <span> TransUnion </span>
                <span
                  :style="`color: ${colorScoreTransunion(score.transunion)};`"
                >
                  {{ transunionCharAt == "N" ? "-" : score.transunion }}
                </span>
              </div>
              <div v-if="score.experian !== ''">
                <span> Experian </span>
                <span
                  :style="`color: ${colorScoreTransunion(score.experian)};`"
                >
                  {{ experianCharAt == "N" ? "-" : score.experian }}
                </span>
              </div>
              <div v-if="score.equifax !== ''">
                <span> EQUIFAX </span>
                <span :style="`color: ${colorScoreTransunion(score.equifax)};`">
                  {{ equifaxCharAt == "N" ? "-" : score.equifax }}
                </span>
              </div>
              <div>
                <a
                  v-if="score.route_pdf"
                  :href="score.route_pdf"
                  target="_blanck"
                  class="bg-danger"
                  style="padding: 0.5rem; display: flex; border-radius: 5px"
                >
                  <span class="text-light">
                    <amg-icon icon="FilePdfIcon" size="20" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="header-indicators">
              <span v-if="customerTicket.account"
                >This client do not have credit report</span
              >
              <span v-else>This Lead do not have credit report</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="px-2">
        <b-tabs
          v-if="customerTicket.client_account_id"
          lazy
          pills
          nav-class="mb-0"
          tab-class
          active-nav-item-class="bg-primary box-shadow-info"
        >
          <div class="p-2" :class="isDarkSkin ? 'boder-dark' : 'border-light'">
            <div
              v-for="customerAccount in JSON.parse(customerTicket.accounts)"
              :key="customerAccount.account"
            >
              <b-tab
                lazy
                :title="customerAccount.account"
                :title-link-class="[bgTabsNavs, 'px-3']"
                @click="companyIdToPass = customerAccount.id"
              >
                <h3 class="mb-2">Notes</h3>
                <b-row class="mb-1" :class="isDarkSkin ? 'my-dark' : ''">
                  <b-col>
                    <div lass="d-flex align-items-center">
                      <span> Select Year: </span>
                      <b-form-select
                        v-model="year"
                        :class="isDarkSkin ? 'my-dark' : 'my-light'"
                        :options="yearOptions"
                        class="ml-1 w-auto"
                        @input="getNotesPerYear"
                      />
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  class="align-items-center"
                  :class="isDarkSkin ? 'my-dark' : ''"
                >
                  <b-col class="month-class" cols="5">
                    <div v-for="(month, index) in months" :key="index">
                      <div
                        class="d-flex"
                        style="
                          color: white;
                          padding: 1rem 1.5rem;
                          border-radius: 30px;
                          justify-content: space-between;
                          align-items: center;
                        "
                        :style="
                          isDarkSkin
                            ? 'background-color: #A201EA !important;'
                            : 'background-color: #0993E3; !important;'
                        "
                      >
                        <span style="font-size: 16px; font-weight: bold">
                          {{ month }}
                        </span>
                        <span
                          class="cursor-pointer text-info m-0 p-0"
                          style="
                            font-size: 18px;
                            font-weight: bold;
                            padding: 0.3rem;
                            background-color: white;
                            border-radius: 100%;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                          @click="openModalAllNotes(index + 1)"
                        >
                          {{ getNumberOfNotes(index) }}
                        </span>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="7">
                    <client-call-log-ticket
                      :key="customerAccount.id"
                      :lead_id="customerAccount.lead_id"
                      :client_account="customerAccount.id"
                      :program-id="customerAccount.program_id"
                    />
                  </b-col>
                </b-row>
                <hr />
                <h3 class="mt-2 mb-2">Payments</h3>
                <div class="px-2">
                  <modal-balance-tickets
                    :key="customerAccount.id"
                    :account-id="customerAccount.account"
                    :name-client="customerAccount.name_client"
                    :client_account_id="customerAccount.id"
                    :total_payment="customerAccount.total_payments"
                  />
                  <list-all-notes-modal
                    v-if="openModalAllNotesController"
                    :month="monthToSend"
                    :id-account="customerAccount.id"
                    :year="year"
                    :name-client="customerTicket.name_client"
                    @close="closeModalAllNotes"
                  />
                </div>

                <b-row
                  class="ml-2 mr-2 p-2"
                  :class="isDarkSkin ? 'my-dark' : 'my-light-blue'"
                >
                  <b-col
                    style="
                      display: grid;
                      grid-template-columns: repeat(
                        auto-fit,
                        minmax(140px, 1fr)
                      );
                      gap: 1rem;
                    "
                    cols="5"
                  >
                    <div v-for="(month, index) in months" :key="index">
                      <div
                        class="d-flex"
                        style="
                          color: white;
                          padding: 1rem 1.5rem;
                          border-radius: 30px;
                          justify-content: space-between;
                          align-items: center;
                        "
                        :style="
                          isDarkSkin
                            ? 'background-color: #A201EA !important;'
                            : 'background-color: #0993E3; !important;'
                        "
                      >
                        <span style="font-size: 16px; font-weight: bold">
                          {{ month }}
                        </span>
                        <span
                          class="cursor-pointer text-info m-0 p-0"
                          style="
                            font-size: 18px;
                            font-weight: bold;
                            padding: 0.3rem;
                            background-color: white;
                            border-radius: 100%;
                            width: 30px;
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                          @click="openModalAllNotes(index + 1)"
                        >
                          {{ getNumberOfNotes(index) }}
                        </span>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="7">
                    <client-call-log-ticket
                      :key="customerAccount.id"
                      :lead_id="customerAccount.lead_id"
                      :client_account="customerAccount.id"
                      :program-id="customerAccount.program_id"
                    />
                  </b-col>
                  <b-col>
                    <modal-balance-tickets
                      :key="customerAccount.id"
                      :account-id="customerAccount.account"
                      :name-client="customerAccount.name_client"
                      :client_account_id="customerAccount.id"
                      :total_payment="customerAccount.total_payments"
                    />
                    <list-all-notes-modal
                      v-if="openModalAllNotesController"
                      :month="monthToSend"
                      :id-account="customerAccount.id"
                      :year="year"
                      :name-client="customerTicket.name_client"
                      @close="closeModalAllNotes"
                    />
                    <files
                      :data-file="{
                        client_account_id: customerAccount.id,
                        account: customerAccount.account,
                        program_id: customerAccount.program_id,
                      }"
                    />
                  </b-col>
                </b-row>
              </b-tab>
            </div>
          </div>
        </b-tabs>
      </div>

      <b-row v-if="customerTicket.lead_id">
        <list-all-notes-lead-modal
          :month="monthToSend"
          :id-lead="customerTicket.lead_id"
          :year="year"
          :name-client="customerTicket.name_client"
        />
        <client-call-log-ticket
          :key="companyIdToPass"
          :lead_id="customerTicket.lead_id"
        />
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { VMoney } from "v-money";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import ListAllNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ListAllNotesModal.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import moment from "moment/moment";
import ListAllNotesLeadModal from "@/views/commons/components/customer-tickets/components/modal/ListAllNotesLeadModal.vue";
import ModalBalanceTickets from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalBalanceTickets.vue";
import ClientCallLogTicket from "@/views/commons/components/customer-tickets/components/modal/ClientCallLogTicket.vue";
import Files from "@/views/commons/components/clients/dashboard/options/files-module/Files.vue";

import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";

export default {
  components: {
    ListAllNotesModal,
    ListAllNotesLeadModal,
    ModalBalanceTickets,
    ClientCallLogTicket,
    Files,
  },
  directives: { money: VMoney },
  props: {
    customerTicket: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      score: {
        equifax: "",
        experian: "",
        transunion: "",
        score_id: "",
      },
      onControl: false,
      yearOptions: [],
      companyIdToPass: 1,
      notes: [],
      monthToSend: null,
      openModalAllNotesController: false,
      openModalAllNotesLeadController: false,
      year: null,
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    transunionCharAt() {
      return this.score.transunion?.charAt(0);
    },
    experianCharAt() {
      return this.score.experian?.charAt(0);
    },
    equifaxCharAt() {
      return this.score.equifax?.charAt(0);
    },
  },
  watch: {
    companyIdToPass(val) {
      if (val && this.customerTicket.client_account_id) {
        this.getNotesPerYear(this.year);
      }
    },
  },
  created() {
    this.onControl = true;

    if (this.customerTicket.client_account_id) {
      this.orderAccounts();
    }
    this.companyIdToPass = this.customerTicket.client_account_id
      ? this.customerTicket.client_account_id
      : this.customerTicket.lead_id;
    this.year = moment().format("YYYY");

    this.yearSelect();
    this.getScoreAndLead();
  },

  async mounted() {
    this.errorMessage = true;
  },

  methods: {
    colorScoreTransunion(score) {
      if (score <= 659) return "#ff0707";
      if (score >= 660 && score <= 699) return "#ffc107";
      if (score >= 700 && score <= 759) return "#bfff00";
      if (score >= 760 && score <= 850) return "#0dff34";
      return "#000";
    },
    async getScoreAndLead() {
      try {
        const params = {
          lead_id: this.customerTicket.lead_id
            ? this.customerTicket.lead_id
            : this.customerTicket.lead_id_client,
        };
        const response = await ClientDashboardService.getLastScore(params);
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.score.equifax = response.data[0].equifax;
            this.score.experian = response.data[0].experian;
            this.score.transunion = response.data[0].transunion;
            this.score.route_pdf = response.data[0].route_pdf;
          }
        }
      } catch (error) {
        console.error(error);
        this.showErrorSwal(error);
      }
    },

    orderAccounts() {
      // Parse the JSON
      const parsedAccounts = JSON.parse(this.customerTicket.accounts);

      // Find the index of the object with the specified client_account_id
      const clientAccountIndex = parsedAccounts.findIndex(
        (account) => account.id === this.customerTicket.client_account_id
      );

      // Check if the client_account_id exists in the accounts
      if (clientAccountIndex !== -1) {
        // Remove the object from its current position
        const clientAccount = parsedAccounts.splice(clientAccountIndex, 1)[0];

        // Add the object back at the beginning of the array
        parsedAccounts.unshift(clientAccount);
      }
      this.customerTicket.accounts = JSON.stringify(parsedAccounts);
      // Now parsedAccounts contains the desired order with client_account_id at the beginning
    },

    uniqueAccounts(accounts, clientAccountId) {
      // Filtrar la lista para eliminar elementos con el mismo id que client_account_id
      return accounts.filter((account) => account.id !== clientAccountId);
    },
    yearSelect() {
      for (let x = 2014; x <= moment().format("YYYY"); x++) {
        this.yearOptions.push({ value: x, text: x });
      }
    },
    async getNotesPerYear(year) {
      const data = await ClientsOptionsServices.getNotesYear({
        id: this.companyIdToPass,
        year,
      });
      if (data.status === 200) {
        this.notes = data.data;
      }
    },
    closeModalAllNotes() {
      this.openModalAllNotesController = false;
    },
    openModalAllNotes(monthNumber) {
      this.monthToSend = monthNumber;
      this.openModalAllNotesController = true;
    },
    getNumberOfNotes(index) {
      switch (index) {
        case 0:
          return this.notes.jan;
        case 1:
          return this.notes.feb;
        case 2:
          return this.notes.mar;
        case 3:
          return this.notes.apr;
        case 4:
          return this.notes.may;
        case 5:
          return this.notes.jun;
        case 6:
          return this.notes.jul;
        case 7:
          return this.notes.agu;
        case 8:
          return this.notes.set;
        case 9:
          return this.notes.oct;
        case 10:
          return this.notes.nov;
        case 11:
          return this.notes.dec;
      }
    },
    close() {
      this.$emit("close");
    },
    preventNegativeInput(event) {
      const invalidKeys = [
        "-",
        "+",
        "*",
        "/",
        "=",
        "!",
        "@",
        "#",
        "$",
        "%",
        "^",
        "&",
        "(",
        ")",
      ];
      if (invalidKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    async save() {
      try {
        const validation = await this.$refs.observer.validate();
        if (!validation) return;

        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        const params = {
          current_user_id: this.currentUser.user_id,
          new_motive_name: this.newMotiveCustomerTicket,
        };

        const result = await CustomerTicketsService.AddNewMotiveCustomerTicket(
          params
        );

        this.errorMessage = false;
        this.result = result.data;

        if (this.result[0]) {
          this.showWarningSwal(null, this.result[0].Exist_Message);
        } else {
          this.$emit("close");
          this.showSuccessSwal(null, "Motive added successfully!");
          this.$emit("updateMotives");
        }
      } catch (error) {
        console.error(error);
        this.showErrorSwal(error);
      }
    },
  },
};
</script>
<style scoped>
h1,
h2,
h3,
h4,
h5,
span,
p,
small {
  font-family: montserrat;
}
.my-black {
  background-color: black !important;
}
.my-light {
  background-color: white !important;
}
.my-light-blue {
  background-color: #e0f5ff !important;
}
.my-dark {
  background-color: #2d2d2d !important;
}
.bg-my-dark {
  background-color: #2d2d2d !important;
}
.text-my-dark {
  color: #5a5a5a !important;
}
.header-indicators {
  display: flex;
  position: absolute;
  width: 100%;
  justify-content: end;
  align-items: center;
  padding: 0 2.5rem;
  gap: 1.5rem;
  left: 0;
  top: -4.5rem;
  font-size: 18px;
}

.border-dark {
  border-radius: 5px;
  border: 1px solid #82868b;
}

.border-light {
  border-radius: 5px;
  border: 1px solid #2f2f31;
}
.month-class {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(128px, 1fr));
  gap: 1rem;
  row-gap: 5rem;
}
</style>
