import Vue from "vue";
import Vuex from "vuex";

import NcrRealtor from "@/views/commons/components/ncr-realtor/store/ncr-realtor.store";
import Clients from "../views/clients/clients.store";
import ZeroPayment from "@/views/commons/components/zero-payment/store/zero-payment.store";
import Applications from "@/views/commons/components/applications/store/applications.store";
import DisputeResult from "@/views/commons/components/dispute-results/store/dispute-results.store";
import Creditors from "@/views/debt-solution/views/creditors/store/creditors.store";
import Leads from "@/views/commons/components/lead-programs/store/leads.store";
import NcrPrograms from "@/views/commons/components/ncr-programs/store/ncr-programs.store";
import AccountSettlement from "@/views/debt-solution/views/accounts-actions/store/account-settlement.store.js";
import SettlementsMade from "@/views/debt-solution/views/settlements-made/store/settlementsMade.store.js";
import Settlement from "@/views/debt-solution/views/settlements/store/settlements.store.js";
import Timeline from "@/views/commons/components/clients/dashboard/information-client/time-line/store/timeline.store.js";
import SalesMade from "@/views/debt-solution/views/sales-made/store/sales-made.store.js";
import DocumentServiceTypeSettings from "@/views/debt-solution/views/settings/views/documents-config/document-service-type/store/document-service-type.store.js";
import DocumentSettings from "@/views/debt-solution/views/settings/views/documents-config/documents/store/documents.store.js";
// import AnalysisCrStore from '@/views/commons/components/clients/dashboard/analysis/store/analysis.store';

Vue.use(Vuex);

export default {
  DebtSolutionZeroPayment: ZeroPayment,
  DebtSolutionApplications: Applications,
  DebtSolutionDisputeResult: DisputeResult,
  DebtSolutionNcrRealtor: NcrRealtor,
  DebtSolutionClients: Clients,
  DebtSolutionCreditors: Creditors,
  DebtSolutionLeads: Leads,
  DebtSolutionNcrPrograms: NcrPrograms,
  DebtSolutionSettlementStore: AccountSettlement,
  DebtSolutionSettlementsMadeStore: SettlementsMade,
  DebtSolutionSettlementOfferStore: Settlement,
  DebtSolutionTimelineStore: Timeline,
  DebtSolutionSalesMadeStore: SalesMade,
  DSCourtInfoDocumentServiceTypeSettingsStore: DocumentServiceTypeSettings,
  DSCourtInfoDocumentSettingsStore: DocumentSettings,
  // DSAnalysisCrStore: AnalysisCrStore
};
