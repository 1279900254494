export default [
  {
    key: 'date',
    sortable: false,
    label: 'Date',
    visible: true,
  },
  {
    key: 'fee_or_charge',
    sortable: false,
    label: 'Fee or Charge',
    visible: true,
  },
  {
    key: 'amount',
    sortable: false,
    label: 'Amount',
    visible: true,
  },


  {
    key: 'actions',
    sortable: false,
    label: 'Actions',
    visible: true,
  },
]
