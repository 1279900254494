<template>
  <div>
    <b-modal
      ref="modal-tracking-settlement-date"
      title="TRACKING"
      modal-class="modal-primary"
      title-tag="h3"
      hide-footer
      @hidden="hideModal"
    >
      <b-container>
        <b-table
          slot="table"
          ref="refTrackingTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          :fields="fields"
          :items="myProvider"
        >
          <!-- Loading -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <!-- Column: updated by -->
          <template #cell(updated_by)="data">
            <div style="paddingtop: 5px">
              <p class="m-0">{{ data.item.updated_by }}</p>
              <p class="m-0">{{ data.item.created_at | myGlobal }}</p>
            </div>
          </template>
          <!-- Column: updated by -->
          <template #cell(settlement_date_new)="data">
            <div style="paddingtop: 5px">
              <p class="m-0">
                {{ data.item.settlement_date_new | myGlobalDay }}
              </p>
            </div>
          </template>

          <template #cell(settlement_date_old)="data">
            <div style="paddingtop: 5px">
              <p class="m-0">
                {{ data.item.settlement_date_old | myGlobalDay }}
              </p>
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import ModalHeader from "@/views/commons/components/letters/components/modal/ModalHeader.vue";
import ChargesMadeService from "../../charges-mage.service";

export default {
  components: {
    ModalHeader,
  },
  props: {
    clientData: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: true,
      fields: [
        {
          key: "updated_by",
          label: "Updated at",
        },
        {
          key: "settlement_date_new",
          label: "New settlement date",
        },
        {
          key: "settlement_date_old",
          label: "Old settlement date",
        },
      ],
    };
  },
  mounted() {
    this.toggleModal("modal-tracking-settlement-date");
  },
  methods: {
    hideModal() {
      this.modalCenter = false;
      this.$emit("hideModal");
    },
    async myProvider() {
      this.addPreloader();
      try {
        let result = await ChargesMadeService.trackingSettlementDate({
          transaction_id: this.clientData.transaction_id,
        });

        return result;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style></style>
