import store from "@/store";
import Vue from "vue";
import NotificationMixin from "@/mixins/notification";

const ticketDueDateNotification = () => {
  window.socket.bind("ticket-due-date", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id;
    if (data.to_id == sessionId) {
      const result = await Vue.swal.fire({
        icon: "success",
        html: `<h2 style="font-weight: 600;">${data.message}</h2>
                <h3>
                  <br><b>Ticket:</b> ${data.ticket_id}
                  <br><b>Date:</b> ${data.due_date}
                </h3>`,
        showConfirmButton: true,
      });
      if (result.value) {
        await window.amgApi.post("/commons/close-all-swal", data);
      }
      NotificationMixin.methods.showWindowNotification(
        "Update Due Date",
        `${data.message} - Due Date: ${data.due_date}`,
        undefined,
        undefined,
        () => {
          window.focus();
        }
      );
    }
  });
};
export default ticketDueDateNotification;
