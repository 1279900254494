export default [
  {
    path: 'pay-roll',
    name: 'pay-roll',
    component: () => import(/* webpackChunkName: "ManaPayRoll" */ '@/views/management/views/payroll/Payroll.vue'),
    meta: {
      pageTitle: 'Pay Roll',
      breadcrumb: [{
        text: 'Pay Roll',
      }],
    },
  },
]
