<template>
  <b-modal
    v-model="showModal"
    modal-class="modal-primary"
    title-class="h3 text-white"
    title="TRACKING OWNER"
    size="lg"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <template>
      <div>
        <b-table striped hover :items="tracking" :fields="fields"> </b-table>
      </div>
    </template>
  </b-modal>
</template>
<script>
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service"
export default {
  props: {
    ticket_id: {
      type: String,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tracking: [],
      fields: [
        {
          key: "owner",
          label: "Owner Name",
        },
        {
          key: "created_by",
          label: "Reassigned by",
        },
        {
          key: "created_at",
          label: "Created At",
        },
      ],
    }
  },
  created() {
    this.getTracking()
  },
  methods: {
    async getTracking() {
      this.addPreloader()
      const params = { ticket_id: this.ticket_id }
      try {
        const response = await helpdeskService.getTrackingOwner(params)
        this.tracking = response.data
        this.removePreloader()
      } catch (e) {
        this.$swal.fire({
          type: "error",
          title: e,
        })
        this.removePreloader()
      }
    },
    closeModal() {
      this.$emit("closeModal")
    },
  },
}
</script>