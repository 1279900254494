<template>
  <div>
    <filter-slot
      :filter-principal="filterPrincipal"
      :filter="filters"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs[`refSaleClients${typeTab}`].refresh()"
    >
      <template #custom-vselect>
        <FilterStatusAccount :filters="filters[2]" />
      </template>
      <template #table>
        <b-table
          slot="table"
          :ref="`refSaleClients${typeTab}`"
          small
          no-provider-filtering
          :items="myProvider"
          :fields="tabFields"
          primary-key="id"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <!-- Column NAME -->
          <template #cell(lead_name)="data">
            <router-link
              :class="[textLink]"
              target="_blank"
              class
              :to="{
                name: 'sales-lead-show',
                params: {
                  id: data.item.lead_id,
                },
                props: { programsAll: programsAll },
              }"
              ><span>{{ data.item.lead_name }}</span>
            </router-link>
            <br />
            <span v-if="data.item.user_responsible" class="text-warning">
              <tabler-icon
                :icon="
                  data.item.user_responsible === currentUser.user_id
                    ? 'StarIcon'
                    : 'LockIcon'
                "
                size="15"
              />
              <strong>CEO's client</strong>
            </span>
            <div>
              <span>{{ data.item.account }}</span>
              <status-account :account="data.item" />
            </div>
          </template>

          <!-- Column Fee and Charges -->
          <template #cell(charges)="data"> $ {{ data.item.charges }} </template>
          <!-- Column MP -->
          <template
            v-if="
              ($route.matched[0].meta.module != 2 &&
                $route.matched[0].meta.module != 6) ||
              ($route.matched[0].meta.module == 6 && currentUser.role_id != 4)
            "
            #cell(monthly_amount)="data"
          >
            $ {{ data.item.monthly_amount }}
          </template>
          <!-- Column LP -->
          <template
            v-if="
              ($route.matched[0].meta.module != 2 &&
                $route.matched[0].meta.module != 6) ||
              ($route.matched[0].meta.module == 6 && currentUser.role_id != 4)
            "
            #cell(last_payment)="data"
          >
            <div>
              <span style="margin-left: 15px">
                <b-img
                  v-if="data.item.state_lp == 1"
                  :src="assetsImg + '/images/icons/lastpayment-out.ico'"
                />
                <b-img
                  v-else
                  :src="assetsImg + '/images/icons/lastpayment-in.ico'"
                />
                {{
                  data.item.last_payment == "-"
                    ? ""
                    : data.item.last_payment | myGlobal
                }}
              </span>
            </div>
          </template>
          <!-- Column PT -->
          <template #cell(type_payment)="data">
            <status-payment :account="data.item" />
          </template>
          <!-- Column CREATED_AT -->
          <template #cell(created_at)="data">
            {{ data.item.created_at | myGlobal }}
          </template>

          <!-- Column tracking -->
          <template #cell(tracking)="data">
            <div class="pt-1">
              <feather-icon
                icon="MenuIcon"
                class="cursor-pointer"
                size="15"
                @click="openTracking(data.item)"
              />
            </div>
          </template>

          <!-- Column ACTIONS -->
          <template
            v-if="
              currentUser.role_id == 1 ||
              currentUser.role_id == 2 ||
              currentUser.role_id == 5 ||
              currentUser.role_id == 14 ||
              currentUser.role_id == 17
            "
            #cell(actions)="data"
          >
            <actions-table
              v-if="
                data.item.user_responsible == currentUser.user_id ||
                data.item.user_responsible == null
              "
              :options="actionOptions"
              :row-data="data.item"
              :fromCeDigital="true"
              @modalAddService="modalAddService"
              @modalChangeService="modalChangeService"
              @onTracing="openActions"
              @onRecycled="openActions"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>

    <!-- Modal Add Services -->
    <modal-add-service
      v-if="modalOpenAddService"
      :modal-open-add-service="modalOpenAddService"
      :details-client="detailsClient"
      :type-modal="typeModal"
      @hideModal="closeModalService"
      @changeProgram="openModalProgram"
    />

    <!-- Component Commons -->
    <component
      :is="modalData.programs.programSelected"
      v-if="modal.programs"
      :type-modal="modalData.programs.typeModal"
      :sales-client="saleClientP"
      :programs-all="programsAll"
      :users-services="usersServices"
      :header-s="headerS"
      @closeModal="hideModalProgram"
      @changeProgram="openModalProgram"
    />
    <!-- Modal change tab -->
    <actions-type-tab-modal
      v-if="stateActionsTypeTab"
      :client-data="clientData"
      @hideModal="stateActionsTypeTab = false"
      @refresh="$refs[`refSaleClients${typeTab}`].refresh()"
    />
    <!-- Modal Tracking -->
    <tracking-modal
      v-if="stateTracing"
      :client-data="clientData"
      @hideModal="stateTracing = false"
    />
  </div>
</template>

<script>
import fields from "../data/fields.clients.data";
import BusinessModal from "@/views/crm/views/sales-made/components/modals/services/BuisnessModal.vue";
// import CreditExpertsModal from "@/views/crm/views/sales-made/components/modals/services/credit-experts/CreditExpertsModal.vue";
import BoostCreditModal from "@/views/crm/views/sales-made/components/modals/services/BoostCreditModal.vue";
import GeneralSupportModal from "@/views/crm/views/sales-made/components/modals/services/GeneralSupportModal.vue";
import KeyBookModal from "@/views/crm/views/sales-made/components/modals/services/KeyBookModal.vue";
import ParagonModal from "@/views/crm/views/sales-made/components/modals/services/ParagonModal.vue";
import SpecialistModal from "@/views/crm/views/sales-made/components/modals/services/SpecialistModal.vue";
import TaxResearchModal from "@/views/crm/views/sales-made/components/modals/services/TaxResearchModal.vue";
import DebtSolutionModal from "@/views/crm/views/sales-made/components/modals/services/DebtSolutionModal.vue";
import ModalAddService from "@/views/commons/components/clients/modals/ModalAddService.vue";
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ClientService from "@/views/ce-digital/sub-modules/sales/views/clients/service/clients.service";
import ActionsTable from "@/views/ce-digital/components/ActionsTable.vue";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service";
import FiltersClientsData from "../data/filters.clients.data";
import ActionsTypeTabModal from "@/views/ce-digital/sub-modules/sales/views/leads/view/components/modal/ActionsType.vue";
import TrackingModal from "@/views/ce-digital/sub-modules/sales/views/clients/view/components/modal/Tracking.vue";
import { mapGetters, mapActions } from "vuex";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
import Vue from "vue";
export default {
  components: {
    //Programs
    BusinessModal,
    // CreditExpertsModal,
    BoostCreditModal,
    GeneralSupportModal,
    KeyBookModal,
    ParagonModal,
    SpecialistModal,
    TaxResearchModal,
    DebtSolutionModal,
    //Modals
    ModalAddService,
    ActionsTable,
    ActionsTypeTabModal,
    TrackingModal,
    // components
    StatusPayment,
    StatusAccount,
    NcrLeadsService,
    FilterStatusAccount,
  },
  data() {
    return {
      headerS: {},
      modalData: {
        programs: {
          programSelected: "",
          typeModal: 0,
          salesClient: {},
        },
      },
      modal: {
        programs: false,
      },
      modalOpenAddService: false,
      typeModal: null,
      clients: null,
      filters: FiltersClientsData,
      fields: fields,
      assetsImg: process.env.VUE_APP_BASE_URL_ASSETS,
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },

      sortBy: "",
      sortDesc: true,
      startPage: null,
      endPage: "",
      nextPage: "",
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },

      actionOptions: [],
      detailsClient: {},
      currentClient: {},
      saleClientP: {},
      programsAll: [],
      usersServices: [],
      items: [],
      event: null,

      clientData: {},
      // Modals State
      stateActionsTypeTab: false,
      stateTracing: false,
      typeAddChange: null,
      allSellers: [],
    };
  },
  async mounted() {
    await this.getSellers();
    // this.tabFields()

    // List of actions
    if (this.typeTab == 1) {
      this.actionOptions.push("addService"); // Add Service
      this.actionOptions.push("changeService"); // ChangeService
      this.actionOptions.push("tracing"); // Tracing
      this.actionOptions.push("recycled"); // Recycled
    }
    if (this.typeTab == 2) {
      this.actionOptions.push("addService"); // Add Service
      this.actionOptions.push("changeService"); // ChangeService
      this.actionOptions.push("recycled"); // Recycled
    }
    if (this.typeTab == 3) {
      this.actionOptions.push("addService"); // Add Service
      this.actionOptions.push("changeService"); // ChangeService
    }
    if (this.typeTab == 4) {
      this.actionOptions.push("tracing"); // Tracing
    }
  },
  methods: {
    styleIcon(color_status) {
      return (
        "color: " +
        color_status +
        "; background-color: " +
        color_status +
        "; border-radius: 50%;"
      );
    },
    //Seller filter
    async getSellers() {
      try {
        if (
          this.currentUser.role_id == 1 ||
          this.currentUser.role_id == 2 ||
          this.currentUser.role_id == 14 ||
          this.currentUser.role_id == 17
        ) {
          const { data, status } = await ClientService.getSellers({
            module_id: this.moduleId,
            role_id: 5,
          });
          if (status == 200) {
            this.allSellers = data;
            this.filters[4].options = this.allSellers;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // Trcking change tab
    openTracking(item) {
      let data = {
        lead_name: item.lead_name,
        account: item.account,
        derivation_id: item.derivation_id,
        type: 2, // 2 Client
      };
      this.stateTracing = true;
      this.clientData = data;
    },
    // Data by action modal
    openActions(item, type) {
      let data = {
        lead_name: item.lead_name,
        catcher_by: item.catcher_by,
        actionType: type,
        derivation_id: item.derivation_id,
        type: 2, // 2 Client
      };
      this.stateActionsTypeTab = true;
      this.clientData = data;
    },

    async getAllPrograms() {
      try {
        const data = await LeadService.getPrograms();
        if (data) {
          this.programsAll = data;
          var sper = this.currentUser.sale_permission;
          var spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // Data by grid
    async myProvider(ctx) {
      let sortDirection = "desc";
      try {
        const params = {
          per_page: ctx.perPage,
          text: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          program: 3, // Credit Experts
          orderby: 9,
          order: sortDirection,
          status: this.filters[2].model,
          seller: this.filters[4].model ? this.filters[4].model : null,
          paymentType: this.filters[3].model, // Type payment
          userid: this.currentUser.user_id,
          roleid: this.currentUser.role_id,
          tabType: this.typeTab, // News, Tracing, Success and Recycled
        };
        const { data } = await ClientService.getClients(params);

        this.items = data.data;
        this.clients = this.items;
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        return this.items || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },

    async searchPrograms(lead_id) {
      try {
        const response = await ClientService.searchClientPayment({
          id: lead_id,
        });
        if (response.status == 200) {
          const programs = response.data;
          const data = await ClientService.programs();
          this.programsAll = data.data;
          programs.sort((a, b) => a.program_id - b.program_id);
          for (let i = 0; i < this.programsAll.length; i++) {
            for (let y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == programs[y].program_id) {
                this.programsAll.splice(i, 1);
              }
            }
          }
          const sper = this.currentUser.sale_permission;
          const spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async usersAllPrograms() {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.$route.matched[0].meta.module,
        });
        this.usersServices = data.data; // .filter(item => item.id != 1);
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },

    async modalChangeService(data) {
      const catcherId = await this.getCatcherSeller(data.lead_id);
      this.typeAddChange = 2;
      const result = await this.showConfirmSwal("Are you sure change service?");
      const params = {
        user_id: catcherId === 0 ? this.currentUser.user_id : catcherId,
        lead_id: data.lead_id,
      };
      if (result.value) {
        await this.createEvent(params);
        await this.updateEvent();
        this.modalGeneralService(data, 7);
      }
    },

    async getCatcherSeller(leadId) {
      const params = {
        lead_id: leadId,
      };
      const data = await DerivationsService.getCatcherSeller(params);
      if (data.status === 200) {
        return data.data[0].catcher_id;
      }
      return 0;
    },

    async modalAddService(data) {
      const catcherId = await this.getCatcherSeller(data.lead_id);
      this.typeAddChange = 1;
      const result = await this.showConfirmSwal(
        "Are sure want to add new service?"
      );
      const params = {
        user_id: catcherId === 0 ? this.currentUser.user_id : catcherId,
        lead_id: data.lead_id,
      };
      if (result.value) {
        await this.createEvent(params);
        this.modalGeneralService(data, 7);
        await this.updateEvent();
      }
    },

    ...mapActions("crm-store", ["SET_PROGRAM", "SET_CATCHER", "SET_SELLER"]),
    modalGeneralService(data, type) {
      this.detailsClient = data;
      this.detailsClient.accounts = JSON.stringify([
        { program: "Credit Experts" },
      ]);
      this.typeModal = type;
      Vue.set(this.detailsClient, "event_id", this.event)
      this.SET_PROGRAM(null);
      this.SET_CATCHER(null);
      this.SET_SELLER(null);
      this.modalOpenAddService = true;
    },
    closeModalService() {
      this.modalOpenAddService = false;
    },
    async createEvent(data) {
      const response = await LeadService.createLeadEvent({
        user_id: data.user_id,
        lead_id: data.lead_id,
      });
      this.event = response.id;
    },
    async updateEvent() {
      await LeadService.updateLeadEvent({
        user_id: this.currentUser.user_id,
        event_id: this.event,
      });
    },
    async openModalProgram(data) {
      const param = {
        prices: null,
        observation: "Service",
        contract: 1,
        program: data.program.id,
        fee: null,
        suggested: null,
        otherpricesp: null,
        event: this.event,
        json_noce: null,
        stateid: 0,

        // Diferents to add change Services
        account: this.detailsClient.account_id,
        captured: data.captured.id,
        seller: data.seller.id,
        type: this.typeAddChange,
        user_id: this.currentUser.user_id,
        module: this.moduleId,
        id_score: null,
        json_ce: null,
        derivation_id: this.detailsClient.derivation_id,
        type_client: "CLIENT",
      };
      try {
        const result = await LeadService.insertSaleMade(param);
        if (result.status === 200) {
          this.modalOpenAddService = false;
          await this.$router
            .push({ name: "sales-made-cedigital-client-pending" })
            .catch((err) => {
              console.log(err);
            });
        }
      } catch (error) {
        this.showErrorSwal(
          "Please, verify if the client already has the service, otherwise contact support.",
          "Error"
        );
      } finally {
        this.removePreloader();
      }
    },
    async hideModalProgram(refresh) {
      if (refresh) {
        this.showToast(
          "success",
          "top-right",
          "Success!",
          "CheckIcon",
          "Successful operation"
        );
      }

      this.modalData.programs.programSelected = "";
      this.modal.programs = false;
      this.removePreloader();
    },
    async getAllStatusClientAccount() {
      this.filters[2].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.filters[2].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filters[2].options.push(...data);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    typeTab() {
      switch (this.$route.name) {
        case "sales-clients-news":
          return 1; // New
          break;
        case "sales-clients-tracing":
          return 2; // Tracing
          break;
        case "sales-clients-success":
          return 3; // Success
          break;
        case "sales-clients-recycled":
          return 4; // One Recycled
          break;
      }
    },
    tabFields() {
      if (this.typeTab != 3) {
        const field = this.fields.filter((item) => item.key != "program_name");
        return field;
      } else {
        return this.fields;
      }
    },
  },
  created() {
    this.getAllStatusClientAccount();
  },
};
</script>

<style scoped>
#icon {
  border-radius: 50%;
  margin-bottom: 2px;
  margin-right: 5px;
}
.status_circle {
  height: 10px;
  width: 10px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin-right: 15px !important;
}
</style>
