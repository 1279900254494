import { amgApi } from "@/service/axios";

class ClientConnectionService {
  async getConnectionClients(params) {
    try {
      const data = await amgApi.post(
        "/clients/search-clients-connection",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getConnectionClientsLoyal(params) {
    try {
      const data = await amgApi.post(
        "/clients/search-clients-connection-loyal",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getConnectionClientsLoyalv2(params) {
    try {
      const data = await amgApi.post(
        "/clients/search-clients-connection-loyal-v2",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  // connection-grid-counter
  async getConnectionClientsCounter(params) {
    try {
      const data = await amgApi.post(
        "/clients/connection-grid-counter",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async getConnectionClientsHold(params) {
    try {
      const data = await amgApi.post(
        "/clients/search-clients-connection-hold",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async getConnectionClientsRecycle(params) {

    try {
      const data = await amgApi.post(
        "/clients/search-clients-connection-recycle",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getConnectionClientsCanceled(params) {

    try {
      const data = await amgApi.post(
        "/clients/search-clients-connection-canceled",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async getClientsTrackingList(params) {
    try {
      const data = await amgApi.post(
        "/clients/tracking-list-view-connection",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async asignConnectionAdvisor(params) {
    try {
      const data = await amgApi.post(
        "/clients/asign-connection-advisor",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async sendClientToRecycleOrClosed(params) {
    try {
      const data = await amgApi.post(
        "/clients/pass-client-to-connection",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async sendClientsAgentMultiple(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/asign-multiple-advisor-in-connection",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async getUserModule(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/get-advisors",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async getAgentsInRelationship() {
    try {
      const { data } = await amgApi.post(
        "credit-expert/connection/get-agents-in-relationship"
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async deleteAdivsorConnection(params) {
    try {
      const data = await amgApi.post(
        "clients/delete-advisor-connection-id",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async countCallsAndSms(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/count-calls-sms",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async sendPusherByNcr(params) {
    try {
      const data = await amgApi.post(
        "commons/send-pusher-by-ncr-created",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async getAgentAdminSpecialistRelation(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/get-agent-admin-specialist-relation",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async countSmsMultiple(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/count-sms-multiple",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getClientsTrackingHoldSuccess(params) {
    try {
      const data = await amgApi.post(
        "clients/tracking-list-view-hold-success",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async alltrakingcallround(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/all-traking-call-rounds ",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getCountRecyclePending(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/counter-recycle-pending",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getCounterHoldUnassigned(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/counter-hold-Unassigned",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getCounterLoyalUnassigned(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/counter-loyal-Unassigned",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getCounterTotalClientPending(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/counter-total-client-pending",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getCounterClientWithoutReading(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/counter-client-without-reading",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async getReasonStateChange(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/get-state-reason-connection",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async updateAdvisorRead(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/update-advisor-read",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
  async insertReasonStateChange(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/insert-state-reason-connection",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async getReasonStateChangeAll(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/get-state-reason-connection-all",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async deleteObservation(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/delete-state-reason-connection",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async getCounterTotalClientWithoutReading(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/count-total-client-reading",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async getCounterClientLoyalReadingTab(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/counter-client-reading-tab",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  async recoverClient(params) {
    try {
      const data = await amgApi.post(
        "credit-expert/connection/recover-client",
        params
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  }
}

export default new ClientConnectionService();
