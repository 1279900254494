
 <template>
  <div>
    <header-slot :clients-search="true">
      <template #actions>
        <b-row class="w-100 p-0 m-0">
          <b-col
            cols="12"
            class="p-0 d-flex flex-wrap justify-content-end"
            style="gap: 1rem"
          >
            <div style="width: 100%; max-width: 50%; min-width: 350px">
              <b-row v-if="currentUser.role_id === 1" class="w-100">
                <b-col cols="12" class="pr-0 mb-2">
                  <b-input-group class="ml-1">
                    <b-input-group-prepend>
                      <b-button variant="outline-info">
                        <span class="show-hidden-search">GLOBAL</span> SEARCH
                      </b-button>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="campo1"
                      placeholder="Search by Name, Account or Code"
                      style="padding: 18px 0 18px 8px"
                      @keyup.enter="search"
                    />
                    <b-input-group-append>
                      <b-button variant="primary" @click="search">
                        <feather-icon icon="SearchIcon" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>
            <div>
              <b-button
                v-if="$route.meta.tab !== 4"
                variant="outline-success"
                @click="openModalMyClients()"
              >
                <feather-icon
                  icon="UsersIcon"
                  size="15"
                  class="mr-50 text-success"
                />My Clients
              </b-button>
            </div>
            <div>
              <b-dropdown
                id="meetings-dropdown"
                text="Meetings"
                variant="outline-warning"
              >
                <template #button-content>
                  <strong class="align-middle text-capitalize">
                    Meetings
                    <feather-icon icon="ChevronDownIcon" size="15" />
                    <b-badge
                      style="top: -10px; right: -10px; position: absolute"
                      :class="
                        G_MEETINGS_PENDING_CUSTOMER_TICKETS.counter_pending_meetings >
                          0 || 'd-none'
                      "
                      variant="danger"
                      pill
                      >{{
                        G_MEETINGS_PENDING_CUSTOMER_TICKETS.counter_pending_meetings >
                        99
                          ? "+99"
                          : G_MEETINGS_PENDING_CUSTOMER_TICKETS.counter_pending_meetings
                      }}</b-badge
                    >
                  </strong>
                </template>
                <b-dropdown-group id="dropdown-group-1">
                  <b-dropdown-item @click="showModalMeetings = true">
                    <span class="text-warning d-flex w-100 text-capitalize">
                      <feather-icon
                        icon="CalendarIcon"
                        size="15"
                        class="text-warning mr-1"
                      />
                      Meetings
                      <b-badge
                        :class="
                          G_MEETINGS_PENDING_CUSTOMER_TICKETS.counter_pending_meetings >
                            0 || 'd-none'
                        "
                        variant="danger"
                        pill
                        class="ml-1"
                        >{{
                          G_MEETINGS_PENDING_CUSTOMER_TICKETS.counter_pending_meetings >
                          99
                            ? "+99"
                            : G_MEETINGS_PENDING_CUSTOMER_TICKETS.counter_pending_meetings
                        }}</b-badge
                      >
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="openModalSee()">
                    <span class="text-success">
                      <feather-icon
                        icon="ClockIcon"
                        size="15"
                        class="text-success mr-1"
                      />
                      Availability
                    </span>
                  </b-dropdown-item>
                </b-dropdown-group>
              </b-dropdown>
            </div>
            <div>
              <b-button
                variant="outline-info"
                class="position-relative"
                @click="showModalTask = true"
              >
                <b-badge
                  v-if="G_TASKS_PENDING_CUSTOMER_TICKETS > 0"
                  variant="danger"
                  style="top: -10px; right: -10px; position: absolute"
                  pill
                >
                  {{
                    G_TASKS_PENDING_CUSTOMER_TICKETS > 99
                      ? "+99"
                      : G_TASKS_PENDING_CUSTOMER_TICKETS
                  }}
                </b-badge>
                <feather-icon icon="ClipboardIcon" size="15" class="mr-50" />
                Tasks
              </b-button>
            </div>
            <div>
              <b-button
                v-if="$route.meta.tab !== 4"
                variant="primary"
                @click="openModalCreateCustomerTicket({})"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="15"
                  class="mr-50 text-white"
                />New Ticket
              </b-button>
            </div>
            <div>
              <b-button
                v-b-tooltip="'Ticket Customer Settings'"
                v-b-tooltip.v-primary
                variant="outline-primary"
                @click="openModalTicketCustomerSettings"
              >
                <feather-icon icon="SettingsIcon" size="15" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <header-indicators
      class="mb-2"
      @updateTicketC="updateTicketCustomer"
      @filterTable="filterTable"
    />
    <div>
      <b-nav class="m-0" pills>
        <!-- in progress -->
        <b-nav-item
          :to="{ name: tabRouteInProgress }"
          exact
          exact-active-class="active"
          :link-classes="['px-3 h-full', bgTabsNavs]"
        >
          In progress
          <b-badge
            variant="danger"
            :class="
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_in_progress > 0 ||
              'd-none'
            "
            class="rounded-circle"
            :style="'margin-left:10px'"
            >{{
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_in_progress > 99
                ? "+99"
                : G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_in_progress
            }}</b-badge
          >
        </b-nav-item>
        <!-- New -->
        <b-nav-item
          v-if="isTabCustomer"
          :to="{ name: tabRouteReviewRequest }"
          exact
          exact-active-class="active"
          :link-classes="['px-3 h-full', bgTabsNavs]"
        >
          Review Request
          <b-badge
            variant="danger"
            :class="
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_requested > 0 || 'd-none'
            "
            class="rounded-circle"
            :style="'margin-left:10px'"
            >{{
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_requested > 99
                ? "+99"
                : G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_requested
            }}</b-badge
          > </b-nav-item
        ><!-- New -->
        <b-nav-item
          v-if="isTabCustomer"
          :to="{ name: tabRouteNew }"
          exact
          exact-active-class="active"
          :link-classes="['px-3 h-full', bgTabsNavs]"
        >
          New
          <b-badge
            variant="danger"
            :class="
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_new > 0 || 'd-none'
            "
            class="rounded-circle"
            :style="'margin-left:10px'"
            >{{
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_new > 99
                ? "+99"
                : G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_new
            }}</b-badge
          >
        </b-nav-item>
        <!-- solved -->
        <b-nav-item
          :to="{ name: tabRouteCompleted }"
          exact
          exact-active-class="active"
          :link-classes="['px-3 h-full', bgTabsNavs]"
        >
          {{ currentTabType === "customer" ? "Resolved" : "Completed" }}
          <b-badge
            variant="danger"
            :class="
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_solved > 0 || 'd-none'
            "
            class="rounded-circle"
            :style="'margin-left:10px'"
            >{{
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_solved > 99
                ? "+99"
                : G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_solved
            }}</b-badge
          >
        </b-nav-item>
        <b-nav-item
          v-if="isTabCustomer"
          :to="{ name: tabRouteRejected }"
          exact
          exact-active-class="active"
          :link-classes="['px-3 h-full', bgTabsNavs]"
        >
          Rejected
          <b-badge
            variant="danger"
            :class="
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_rejected > 0 || 'd-none'
            "
            class="rounded-circle"
            :style="'margin-left:10px'"
            >{{
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_rejected > 99
                ? "+99"
                : G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_rejected
            }}</b-badge
          >
        </b-nav-item>
        <!-- not solved -->
        <b-nav-item
          v-if="isTabCustomer"
          :to="{ name: tabRouteNotSolved }"
          exact
          exact-active-class="active"
          :link-classes="['px-3 h-full', bgTabsNavs]"
        >
          Not solved
          <b-badge
            variant="danger"
            :class="
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_not_solved > 0 ||
              'd-none'
            "
            class="rounded-circle"
            :style="'margin-left:10px'"
            >{{
              G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_not_solved > 99
                ? "+99"
                : G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_not_solved
            }}</b-badge
          >
        </b-nav-item>
      </b-nav>
      <div class="border-top-primary">
        <router-view :key="`${keyTickets}-${$route.name}`" />
      </div>
    </div>
    <modal-create-customer-ticket
      v-if="showModalCreateCustomerTicket"
      :customer-ticket="customerTicket"
      @hidden="showModalCreateCustomerTicket = false"
      @saved="refreshTableTickets"
    />
    <modal-availability-lasted
      v-if="openModalSeeAvailability"
      @close="closeModalSee"
    />
    <modal-search-global-customer-tickets
      v-if="modalGlobalSearch"
      :search="campo1"
      @closeModal="closeSearchModal"
    />
    <!--MODAL MEETINGS-->
    <modal-list-meetings
      v-if="showModalMeetings"
      @closeModal="closeListMeetings"
    />
    <!--MODAL MY CLIENTS-->
    <modal-list-my-clients
      v-if="showModalMyClients"
      @closeModal="closeModalMyClients"
      @closeModalSearch="closeSearchModal"
    />
    <!--MODAL TASKS-->
    <modal-calendar-task
      v-if="showModalTask"
      @closeModal="showModalTask = false"
    />
    <!--MODAL SETTINGS-->
    <modal-ticket-customer-settings
      v-if="showModalTicketCustomerSettings"
      @closeModal="showModalTicketCustomerSettings = false"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import store from "@/store";
import HeaderIndicators from "@/layouts/components/navbar/components/customer-tickets/modals/IndicatorsTickets.vue";
import ModalCreateCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalCreateCustomerTicket.vue";
import ModalAvailabilityLasted from "@/views/commons/components/customer-tickets/components/modal/ModalAvailabilityLasted.vue";
import ModalSearchGlobalCustomerTickets from "@/views/commons/components/customer-tickets/components/modal/ModalSearchGlobalCustomerTickets.vue";
import ModalListMeetings from "@/views/commons/components/customer-tickets/components/modal/meetings/ListMeetings.vue";
import ModalListMyClients from "@/views/commons/components/customer-tickets/components/modal/ListMyClients.vue";
import ModalCalendarTask from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalCalendarTask.vue";
import ModalTicketCustomerSettings from "@/views/commons/components/customer-tickets/components/modal/ModalTicketCustomerSettings.vue";

export default {
  components: {
    HeaderIndicators,
    ModalCreateCustomerTicket,
    ModalAvailabilityLasted,
    ModalSearchGlobalCustomerTickets,
    ModalListMeetings,
    ModalListMyClients,
    ModalCalendarTask,
    ModalTicketCustomerSettings,
  },
  data() {
    return {
      showModalCreateTicket: null,
      customerTicket: null,
      showModalCreateCustomerTicket: false,
      tabInProgress: 0,
      tabSolved: 0,
      tabRejected: 0,
      tabNotSolved: 0,
      openModalSeeAvailability: false,

      ticketsGlobal: [],
      campo1: "",
      modalGlobalSearch: false,

      searchByCode: false,
      searchModule: null,
      keyTickets: 1,
      showModalMeetings: false,
      showModalMyClients: false,
      showModalTask: false,
      showModalTicketCustomerSettings: false,
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isParagon() {
      return this.moduleId === 12;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_COUNT_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS",
      G_MEETINGS_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/G_MEETINGS_PENDING_CUSTOMER_TICKETS",
      G_TASKS_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/G_TASKS_PENDING_CUSTOMER_TICKETS",
      currentType: "TicketCustomerStore/G_TYPE_TICKET",
    }),

    tabRouteInProgress() {
      const path = this.$route.matched[1].path;
      const meta = this.$route.matched[1].meta;

      const routes = {
        "/tickets/customer": meta.routeCustomerInProgress,
        "/tickets/payment": meta.routePaymentInProgress,
        "/tickets/expense": meta.routeExpenseInProgress,
      };

      return routes[path] || null;
    },

    tabRouteNew() {
      const path = this.$route.matched[1].path;
      const meta = this.$route.matched[1].meta;

      const routes = {
        "/tickets/customer": meta.routeCustomerNew,
        "/tickets/payment": meta.routePaymentNew,
        "/tickets/expense": meta.routeExpenseNew,
      };

      return routes[path] || null;
    },

    tabRouteRejected() {
      const path = this.$route.matched[1].path;
      const meta = this.$route.matched[1].meta;

      const routes = {
        "/tickets/customer": meta.routeCustomerRejected,
        "/tickets/payment": meta.routePaymentRejected,
        "/tickets/expense": meta.routeExpenseRejected,
      };

      return routes[path] || null;
    },

    tabRouteNotSolved() {
      const path = this.$route.matched[1].path;
      const meta = this.$route.matched[1].meta;

      const routes = {
        "/tickets/customer": meta.routeCustomerNotSolved,
        "/tickets/payment": meta.routePaymentNotSolved,
        "/tickets/expense": meta.routeExpenseNotSolved,
      };

      return routes[path] || null;
    },

    tabRouteCompleted() {
      const path = this.$route.matched[1].path;
      const meta = this.$route.matched[1].meta;

      const routes = {
        "/tickets/customer": meta.routeCustomerCompleted,
        "/tickets/payment": meta.routePaymentCompleted,
        "/tickets/expense": meta.routeExpenseCompleted,
      };

      return routes[path] || null;
    },

    tabRouteReviewRequest() {
      const path = this.$route.matched[1].path;
      const meta = this.$route.matched[1].meta;

      const routes = {
        "/tickets/customer": meta.routeCustomerReviewRequest,
        "/tickets/payment": meta.routePaymentReviewRequest,
        "/tickets/expense": meta.routeExpenseReviewRequest,
      };

      return routes[path] || null;
    },
    isTabCustomer() {
      return this.$route.matched[this.orderMatched].meta.type == "customer";
    },
    orderMatched() {
      const sizeMatched = {
        5: 3,
        4: 2,
        3: 1,
      }
      return sizeMatched[this.$route.matched.length] || "Unknown"
    },
     currentTabType() {
      return this.$route.matched[this.orderMatched].meta.type;
    },
  },
  created() {
    this.SET_CURRENT_VIEW("modalticketcustomer");
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAVBAR_CONFIG", {
      type: "sticky",
    });
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
    this.$store.commit("appConfig/UPDATE_LAYOUT_TYPE", "vertical");
    this.SET_CURRENT_VIEW(null);
  },
  async mounted() {
    await store.dispatch("NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS", {
      user_id: this.currentUser.user_id,
      client_account_id: null,
      type_ticket: this.currentTabType,
    });
    await store.dispatch(
      "NotificationStore/A_MEETINGS_PENDING_CUSTOMER_TICKETS"
    );
    await store.dispatch("NotificationStore/A_TASKS_PENDING_CUSTOMER_TICKETS", {
      user_id: this.currentUser.user_id,
    });

    // show content header right responsive
    this.showActionsHeader();
  },
  methods: {
    closeSearchModal() {
      this.modalGlobalSearch = false;
      this.reloadRouterView();
    },
    reloadRouterView() {
      // Obten el nombre de la ruta actual
      const currentRouteName = this.$route.name;

      // Cambia el valor de keyTickets para forzar la recarga del router-view
      this.keyTickets = Date.now();

      // Navega a la misma ruta para forzar la recarga del componente
      this.$router.push({ name: currentRouteName }).catch((error) => {
        // Manejar específicamente la excepción NavigationDuplicated
        if (
          error.name === "NavigationDuplicated" &&
          error.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          // No hacer nada
        } else {
          // Lanzar otras excepciones
          throw error;
        }
      });
    },
    redirectToModules(data) {
      console.log("redirectToModules", data);
    },
    ...mapActions({
      A_SET_INDICATORS_STATUS: "TicketCustomerStore/A_SET_INDICATORS_STATUS",
      A_SET_TYPE_TICKET: "TicketCustomerStore/A_SET_TYPE_TICKET",
      A_COUNT_PENDING_CUSTOMER_TICKETS_TAB:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_TAB",
    }),
    ...mapMutations({
      SET_CURRENT_VIEW: "TicketCustomerStore/SET_CURRENT_VIEW",
    }),
    async search() {
      this.modalGlobalSearch = true;
    },
    openModalCreateCustomerTicket(item) {
      this.customerTicket = { ...item };
      this.showModalCreateCustomerTicket = true;
    },
    openModalMyClients() {
      this.showModalMyClients = true;
    },
    async refreshTableTickets() {
      await store.dispatch(
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
        { user_id: this.currentUser.user_id, client_account_id: null }
      );
      this.keyTickets += 1;
    },
    createTicket() {
      this.showModalCreateTicket = true;
    },
    updateTicketCustomer(state) {
      this.A_SET_INDICATORS_STATUS(state);
      // console.log('updateTicketCustomer', state, this.$route.meta.status);
      // this.$route.meta.status = state;
    },
    filterTable(type) {
      this.A_SET_TYPE_TICKET(type);
    },
    openModalSee() {
      this.openModalSeeAvailability = true;
    },
    closeModalSee() {
      // this.refreshTable();
      this.openModalSeeAvailability = false;
    },
    async closeListMeetings(answeredMeeting) {
      this.showModalMeetings = false;
      if (answeredMeeting) {
        await this.refreshTableTickets();
      }
    },
    closeModalMyClients() {
      this.showModalMyClients = false;
    },
    showActionsHeader() {
      if (document.getElementsByClassName("content-header-right")) {
        document
          .getElementsByClassName("content-header-right")[0]
          .classList.remove("d-none");
      }
    },
    openModalTicketCustomerSettings() {
      this.showModalTicketCustomerSettings = true;
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 700px) {
  .show-hidden-search {
    display: none !important;
  }
}
</style>
