import { amgApi } from "@/service/axios";

class DocumentFormatService {
  async getDocumentsFormat(params) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/document-format-settings/get-document-format-settings",
        params
      );
      return data;
    } catch (error) {
      console.log("Error DocumentFormatService: getDocumentsFormat");
      throw error;
    }
  }

  async getDocumentFormatById(params) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/document-format-settings/get-document-format-settings-by-id",
        params
      );
      return data;
    } catch (error) {
      console.log("Error DocumentFormatService: getDocumentFormatById");
      throw error;
    }
  }

  async createOrUpdateDocumentFormat(params) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/document-format-settings/create-or-update-document-format-settings",
        params,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      console.log("Error DocumentFormatService: createOrUpdateDocumentFormat");
      throw error;
    }
  }

  async deleteDocumentFormat(params) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/document-format-settings/delete-document-format-settings",
        params
      );
      return data;
    } catch (error) {
      console.log("Error DocumentFormatService: deleteDocumentFormat");
      throw error;
    }
  }


  async getDocumentsFormats() {
    try {
      const { data } = await amgApi.get(
        "/debt-solution/document-format-settings/get-all-documents-formats");
      return data;
    } catch (error) {
      console.log("Error DocumentFormatService: getDocumentsFormats");
      throw error;
    }
  }

  async getDocumentModelLetter(params) {
    try {
      const { data } = await amgApi.post(
        "/correspondence/get-model-letter", params, { responseType: 'blob' });
      return data;
    } catch (error) {
      console.log("Error DocumentFormatService: getDocumentModelLetter");
      throw error;
    }
  }

  async getDocumentModelEmail(params) {
    try {
      const { data } = await amgApi.post(
        "/correspondence/get-model-email", params, { responseType: 'blob' });
      return data;
    } catch (error) {
      console.log("Error DocumentFormatService: getDocumentModelEmail");
      throw error;
    }
  }

  async sendDocumentsToLead(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/send-documents-lead",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCaseSummary(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-case-summary",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTemplateContent(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/court-info/get-template-content",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new DocumentFormatService();
