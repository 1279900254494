import { amgApi } from "@/service/axios";

class EmployeeClaimsService {
  async getEmployeeClaims(params) {
    try {
      return await amgApi.post('/commons/employee-claims/get-employee-claims', params);
    } catch (error) {
      console.log("Error Staff Claims: getEmployeeClaims");
    }
  }
  async getTrackingEmployeeClaims(params) {
    try {
      return await amgApi.post('/commons/employee-claims/get-tracking-employee-claims', params);
    } catch (error) {
      console.log("Error tracking Staff Claims: getTrackingEmployeeClaims");
    }
  }
  async getModules() {
    try {
      return await amgApi.get('/commons/employee-claims/get-modules');
    } catch (error) {
      console.log("Error filter Staff Claims: getModules");
    }
  }
  async getCounterEmployeeClaims(params) {
    try {
      return await amgApi.post('/commons/employee-claims/get-counter-employee-claims', params);
    } catch (error) {
      console.log("Error counter Staff Claims: getCounterEmployeeClaims");
    }
  }
  async updateEmployeeClaimView(params) {
    try {
      return await amgApi.post('/commons/employee-claims/update-employee-claim-view', params);
    } catch (error) {
      console.log("Error update Staff Claims: updateEmployeeClaimView");
    }
  }

  async saveOrUpdateEmployeeClaim(params) {
    try {
      const data = await amgApi.post(`/management/claims/create-claim-request`, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return data;
    } catch (error) {
      throw error
    }
  }

  async getEmployeeClaim(params) {
    try {
      const data = await amgApi.post(`/management/claims/get-claim-request`, params)
      return data;
    } catch (error) {
      throw error
    }
  }
  async removeEmployeeClaimFile(params) {
    try {
      const data = await amgApi.post(`/management/claims/remove-claim-request-file`, params)
      return data;
    } catch (error) {
      throw error
    }
  }
  async searchMessageHistory(params) {
    try {
      const data = await amgApi.post(`/management/claims/search-message-history`, params)
      return data;
    } catch (error) {
      throw error
    }
  }
  async createClaimMessage(params) {
    try {
      const data = await amgApi.post(`/management/claims/create-claim-message`, params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      return data;
    } catch (error) {
      throw error
    }
  }

  async setClaimRequestSurvey(params) {
    try {
      const data = await amgApi.post(`/management/claims/set-claim-request-survey`, params)
      return data;
    } catch (error) {
      throw error
    }
  }
  async validateEmployeeSchedule(params) {
    try {
      const data = await amgApi.post(`/management/claims/validate-employee-schedule`, params)
      return data;
    } catch (error) {
      throw error
    }
  }

  async getUsersForAttendEmployeeClaims(params) {
    try {
      const data = await amgApi.get(`/management/claims/get-users-for-attend-employee-claims`, params)
      return data;
    } catch (error) {
      throw error
    }
  }
}
export default new EmployeeClaimsService();
