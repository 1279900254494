

export default [
  {
    path: "notification-app",
    name: "cdigital-notification-app",
    component: () => import(/* webpackChunkName: "CEDCustomerServiceNotificationApp" */ "@/views/ce-digital/sub-modules/customer-service/views/notification-app/views/NotificationApp.vue"),
    meta: {
      pageTitle: "Notification Files",
      breadcrumb: [
        {
          text: "Notification Files",
          active: true,
        },
      ],
      is_digital: 2,
      permittedRoles: [1, 2, 14, 16, 17],
    },
  },
];
