export default [
    {
        key: "status",
        label: "date",
        thClass: "text-left",
        tdClass: "text-left py-50 px-0",
        sortable: false,
        visible: true,
    },
    {
        key: "from",
        label: "From",
        thClass: "text-left",
        tdClass: "text-left py-50 px-0",
        sortable: false,
        visible: true,
    },
    {
        key: "to",
        label: "To",
        thClass: "text-left",
        tdClass: "text-left py-50 px-0",
        sortable: false,
        visible: true,
    },
    // {
    //     key: "start_time",
    //     label: "Start Time",
    //     thClass: "text-center",
    //     tdClass: "text-center py-50 px-0",
    //     sortable: false,
    //     visible: true,
    //     // thStyle: { width: "250px" },
    // },
    {
        key: 'record_url',
        label: "Audio",
        thClass: "text-center",
        tdClass: "text-center py-50 px-0",
        sortable: false,
        visible: true,
    },
    // {
    //     key: "duration",
    //     label: "Duration",
    //     thClass: "text-center",
    //     tdClass: "text-center py-50 px-0",
    //     sortable: false,
    //     visible: true,
    // },
    {
        key: 'actions',
        label: "Actions",
        thClass: "text-center",
        tdClass: "text-center py-50 px-0",
        sortable: false,
    },
];
