export default {
    namespaced: true,
    state: {
        S_IS_ACTIVE: false,
        S_DATA_NCR: null,
        S_TITLE: null,
        S_DETAIL_NCR:null
    },
    getters: {
        G_IS_ACTIVE(state) {
            return state.S_IS_ACTIVE;
        },
        G_DATA_NCR(state) {
            return state.S_DATA_NCR;
        },
        G_TITLE(state) {
            return state.S_TITLE;
        },
        G_DETAIL_NCR(state){
            return state.S_DETAIL_NCR;
        }
    },
    mutations: {
        M_OPEN_ALERT(state, payload) {
            state.S_IS_ACTIVE = payload;
        },
        M_DATA_NCR(state, payload) {
            state.S_DATA_NCR = payload;
        },
        M_TITLE(state, payload) {
            state.S_TITLE = payload;
        },
        M_RESET(state){
            state.S_IS_ACTIVE = false;
            state.S_TITLE = '';
            state.S_DATA_NCR = null;
        },
        M_DETAIL_NCR(state,payload){
            state.S_DETAIL_NCR = payload;
        }
    }
}