import store from "@/store";
import Notifications from "@/service/notification/index";
import ClientsService from "@/views/tax-research/views/clients/services/client.service";
import ZeroPaymentService from "@/views/boost-credit/views/zero-payment/service/zero-payment.service";
import ScheduleService from "@/views/commons/components/schedules/services/schedules.service";
import chargeBackV2Service from "@/views/commons/components/charge-back_v2/service/charge.back.service.js";
import employeeClaims, {
  salesMadeCounter,
} from "@/views/commons/commons-counter";
import RequestService from "@/views/commons/components/request/services/request.service.js";
import RecruitmentService from "@/views/commons/components/recruitment-process/services/recruiment.service";
import LoansService from "../commons/components/loans/services/loans.service";
import HelpDeskService from "../commons/components/helpdesk/services/helpdesk.service";
import LettersRoundService from "@/views/specialists/sub-modules/analyst-department/views/letter-round/services/letters-rounds.service.js";

const clientsCounter = async () => {
  const data = await ClientsService.getClientsCounter();
  if (data) {
    store.dispatch("TaxResearchClients/A_SET_COUNTERS_TABS_CLIENTS", data.data);
  }
};

const loanCounter = async () => {
  const statusShow =
    store.state.auth.currentUser.modul_id === 16
      ? 4
      : store.state.auth.currentUser.modul_id === 17
      ? 3
      : store.state.auth.currentUser.role_id === 2 ||
        store.state.auth.currentUser.role_id === 1
      ? 2
      : 0;

  const params = {
    id_module: store.state.auth.currentUser.modul_id,
    page: 1,
    status: 1,
    type: statusShow,
  };
  const data = await LoansService.getCounterLoan(params);
  const payload = {
    routeName: "loans-tax-research",
    tag: data[0].counter,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const zeroPaymentCounter = async () => {
  const params = {
    id_global: store.state.auth.currentUser.user_id,
    id_status: 3,
    id_modul: 8, // Tax Research
    id_role: store.state.auth.currentUser.role_id,
  };
  const { data } = await ZeroPaymentService.getZeroPaymentCounter(params);
  if (data.length > 0) {
    const payload = {
      routeName: "zeropayment-tax-research",
      tag: data.length,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const schedulesCounter = async () => {
  const params = {
    moduleid: 8,
  };
  const data = await ScheduleService.getSchedulesCounter(params);
  if (data) {
    const payload = {
      routeName: "schedules-tax-research",
      tag: data.grandTotal > 99 ? "99+" : data.grandTotal,
    };
    store.dispatch("SchedulesStore/A_SET_COUNTERS_TABS_SCHEDULES", data);
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const helpdeskCounter = async () => {
  const params = {
    modul_id: 8,
    user_id: store.state.auth.currentUser.user_id,
  };
  const { data } = await HelpDeskService.getCounterNotifications(params);
  if (Object.keys(data).length > 0) {
    const payload = {
      routeName: "helpdesk-tax-research",
      tag: data.total,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const chargebackCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;

  const params = {
    module_id: module,
  };

  const { in_process, remove_cb, payment_client, pending, observed } =
    await chargeBackV2Service.getChargebackCounters(params);

  const solution = remove_cb + payment_client;
  const dispute = pending + observed;
  const counterSide = in_process + solution + dispute;

  const payload = {
    routeName: "tr-charge-back",
    tag: counterSide,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: "request-taxresearch",
    tag: data[0].counter,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};
const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "tax-research-projects",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};
const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "tax-research-recruitment-process",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({
    user_id,
    moduleId,
  });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: "tax-research-customer-tickets",
      tag: data[0].v_total_open,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const returnedLettersCounter = async () => {
  const { data } =
    await LettersRoundService.getCounterReturnedLettersDepartment({
      moduleId: 8,
      isConnection: false,
    });

  const value = data > 99 ? "99+" : data;

  const payload = {
    routeName: "tr-letters-dispute",
    tag: value,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const allCountersTaxResearch = async () => {
  Promise.all([
    salesMadeCounter("sales-made-tax-research"),
    clientsCounter(),
    projectsCounter(),
    zeroPaymentCounter(),
    schedulesCounter(),
    helpdeskCounter(),
    loanCounter(),
    chargebackCounter(),
    requestNotAsignedCounter(),
    employeeClaims("tax-research-employee-claims"),
    applicantsCounter(),
    getPendinCustomerTickets(),
    returnedLettersCounter(),
  ]);
};
export default allCountersTaxResearch;
