import SettlementsMadeService from '@/views/debt-solution/views/settlements-made/services/SettlementsMade.service';
import store from '@/store';

export default {
    namespaced: true,
    state: {
        S_COUNT_NOT_TIF: 0,
        S_COUNT_NOT_COMPLETE: 0,
        S_CONFIRMED_PAYMENTS_TOTAL: 0,
        S_PENDING_TO_BE_PAID_TOTAL: 0,
        S_PAYMENTS_TO_BE_CONFIRMED_TOTAL: 0,
        S_REJECTED_PAYMENTS_TOTAL: 0
    },
    getters: {
        G_COUNT_NOT_TIF: state => state.S_COUNT_NOT_TIF,
        G_COUNT_NOT_COMPLETE: state => state.S_COUNT_NOT_COMPLETE,
        G_CONFIRMED_PAYMENTS_TOTAL: state => state.S_CONFIRMED_PAYMENTS_TOTAL,
        G_PENDING_TO_BE_PAID_TOTAL: state => state.S_PENDING_TO_BE_PAID_TOTAL,
        G_PAYMENTS_TO_BE_CONFIRMED_TOTAL: state => state.S_PAYMENTS_TO_BE_CONFIRMED_TOTAL,
        G_REJECTED_PAYMENTS_TOTAL: state => state.S_REJECTED_PAYMENTS_TOTAL
    },

    mutations: {
        M_COUNT_NOT_TIF(state, payload) {
            state.S_COUNT_NOT_TIF = payload;
        },
        M_COUNT_NOT_COMPLETE(state, payload) {
            state.S_COUNT_NOT_COMPLETE = payload;
        },
        M_CONFIRMED_PAYMENTS_TOTAL(state, payload) {
            state.S_CONFIRMED_PAYMENTS_TOTAL = payload
        },
        M_PENDING_TO_BE_PAID_TOTAL(state, payload) {
            state.S_PENDING_TO_BE_PAID_TOTAL = payload
        },
        M_PAYMENTS_TO_BE_CONFIRMED_TOTAL(state, payload) {
            state.S_PAYMENTS_TO_BE_CONFIRMED_TOTAL = payload
        },
        M_REJECTED_PAYMENTS_TOTAL(state, payload) {
            state.S_REJECTED_PAYMENTS_TOTAL = payload
        }
    },
    actions: {
        async A_COUNT_NOT_TIF({ commit }, payload) {
            try {
                const { notTif, notComplete } = await SettlementsMadeService.countSettlementsMade(payload);
                commit('M_COUNT_NOT_TIF', notTif)
                commit('M_COUNT_NOT_COMPLETE', notComplete)

                const role_id = store.state.auth.currentUser.role_id;
                const params = {
                    routeName: "debt-solution-settlements-made",
                    tag: role_id === 3 ? notTif : notTif + notComplete,
                };
                store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", params);
            } catch (error) {
                throw (error)
            }
        },
        async A_GET_PAYMENT_STATUS_TOTAL({ commit }) {
            try {
                const data = await SettlementsMadeService.getPaymentStatusTotal();
                commit('M_CONFIRMED_PAYMENTS_TOTAL', data.data[0].confirmed_payments)
                commit('M_PENDING_TO_BE_PAID_TOTAL', data.data[0].pending_to_be_paid)
                commit('M_PAYMENTS_TO_BE_CONFIRMED_TOTAL', data.data[0].payments_to_be_confirmed)
                commit('M_REJECTED_PAYMENTS_TOTAL', data.data[0].rejected_payments)

            } catch (error) {
                throw (error)
            }
        }
    }
}