export default
{
  path: 'paragon-creative-request',
  name: 'creative-request-options-ti',
  redirect: { name: 'paragon-pending' },
  component: () => import(/* webpackChunkName: "SnSettings" */ '@/views/commons/components/creative-requests/creativeRequest.vue'),
  children: [
    {
      path: 'pending',
      name: 'paragon-pending',
      component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/commons/components/creative-requests/components/RequestInProcessGrid.vue'
      ),
      meta: {
        pageTitle: 'Creative Design',
        moduleName: 'paragon',
        status: 'pending',
        breadcrumb: [
          {
            text: 'Pending',
            active: true,
          },
        ],
        permittedRoles: [1, 17, 26, 30],
      },
    },
    {
      path: 'in-process',
      name: 'paragon-in-process',
      component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/commons/components/creative-requests/components/RequestInProcessGrid.vue'
      ),
      meta: {
        pageTitle: 'Creative Design',
        moduleName: 'paragon',
        status: 'in process',
        breadcrumb: [
          {
            text: 'In process',
            active: true,
          },
        ],
        permittedRoles: [1, 17, 26, 30],
      },
    },
    {
      path: 'finished',
      name: 'paragon-finished',
      component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/commons/components/creative-requests/components/RequestInProcessGrid.vue'
      ),
      meta: {
        pageTitle: 'Creative Design',
        moduleName: 'paragon',
        status: 'finished',
        breadcrumb: [
          {
            text: 'Finished',
            active: true,
          },
        ],
        permittedRoles: [1, 17, 26, 30],
      },
    },
    {
      path: 'observed',
      name: 'paragon-observed',
      component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/commons/components/creative-requests/components/RequestInProcessGrid.vue'
      ),
      meta: {
        pageTitle: 'Creative Design',
        moduleName: 'paragon',
        status: 'observed',
        breadcrumb: [
          {
            text: 'Observed',
            active: true,
          },
        ],
        permittedRoles: [1, 17, 26, 30],
      },
    },
    {
      path: 'approved',
      name: 'paragon-approved',
      component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/commons/components/creative-requests/components/RequestInProcessGrid.vue'
      ),
      meta: {
        pageTitle: 'Creative Design',
        moduleName: 'paragon',
        status: 'approved',
        breadcrumb: [
          {
            text: 'Approved',
            active: true,
          },
        ],
        permittedRoles: [1, 17, 26, 30],
      },
    },
  ],
};
