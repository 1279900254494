<template>
  <div>
    <b-modal
      ref="modalTracking"
      v-model="modalCenter"
      centered
      modal
      size="lg"
      header-class="p-0"
      no-close-on-backdrop
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title="TRACKING"
      title-class="h2 text-white"
      hide-footer
      @hidden="hideModal"
    >
      <template v-slot:modal-header>
        <ModalHeader
          :dataItem="dataItem"
          :title="'TRACKING'"
          @close="hideModal()"
        />
      </template>
      <b-container>
        <b-table
          slot="table"
          ref="refTrackingTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          show-empty
          :fields="visibleFields"
          :items="myProvider"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(created_at)="data">
            <div style="paddingtop: 5px">
              <p class="m-0">{{ data.item.created_at | myGlobalDay }}</p>
            </div>
          </template>
          <template #cell(typesenior)="data">
            <div style="paddingtop: 5px">
              <p class="m-0">{{ data.item.typesenior }}</p>
            </div>
          </template>
          <template #cell(status)="data">
            <div class="d-flex justify-content-center align-v-center">
              <b-badge
                class="text-center w-100"
                :class="
                  data.item.status == 'PENDING'
                    ? 'bg-light-dark'
                    : data.item.status == 'APPROVED'
                    ? 'bg-light-success'
                    : data.item.status == 'DENIED' ||
                      data.item.status == 'CANCELED'
                    ? 'bg-light-danger'
                    : 'bg-light-warning'
                "
                pill
              >
                {{ data.item.status }} ({{ data.item.typesenior_pending }})
              </b-badge>
            </div>
          </template>
        </b-table>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import ModalHeader from "@/views/crm/views/settings/view/components/ModalHeader.vue";
import SalesSellerServices from "@/views/ce-digital/sub-modules/settings/views/permissions/service/sales-seller.services";
export default {
  components: {
    ModalHeader,
  },
  props: {
    dataItem: {
      type: Object,
    },
  },
  data() {
    return {
      modalCenter: true,
      fields: [
        {
          key: "created_at",
          label: "Date",
          visible: true,
        },
        {
          key: "user",
          label: "User",
          visible: false,
        },
        {
          key: "typesenior",
          label: "Current Level",
          visible: true,
        },
        {
          key: "status",
          label: "Status",
          visible: true,
        },
      ],
    };
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    hideModal() {
      this.$refs['modalTracking'].hide();
      this.modalCenter = false;
      this.$emit("hideModal");
    },
    async myProvider() {
      this.addPreloader();
      try {
        const response = await SalesSellerServices.getSalesSellerTracking({
          _user_id: this.dataItem.account_id,
        });
        return response.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
</script>

<style scoped>
</style>
