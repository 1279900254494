export default [
    {
        path: 'bank-of-flyers',
        name: 'bank-of-flyers-social-network',
        redirect: {name: 'list-active-social-network'},
        component: () => import(/* webpackChunkName: "SnBankOfFlyers" */ '@/views/commons/components/bank-of-flyers/BankOfFlyers.vue'),
        children: [
            {
                path: 'active',
                component: () => import(/* webpackChunkName: "SnBankOfFlyersActive" */ '@/views/commons/components/bank-of-flyers/bank-of-flyers-module/bank-of-flyers-list-active/BankOfFlyerActive.vue'),
                name: 'list-active-social-network',
                meta: {
                    pageTitle: "Bank of flyers",
                    breadcrumb: [{
                        text: 'Active',
                        active: true,
                    }],
                    route: "socialnetwork",
                },
            },
            {
                path: 'inactive',
                component: () => import(/* webpackChunkName: "SnBankOfFlyerInactive" */ '@/views/commons/components/bank-of-flyers/bank-of-flyers-module/bank-of-flyers-list-inactive/BankOfFlyerInactive.vue'),
                name: 'list-inactive-social-network',
                meta: {
                    pageTitle: "Bank of flyers",
                    breadcrumb: [{
                        text: 'Inactive',
                        active: true,
                    }],
                    route: "socialnetwork",
                },
            },
            {
                path: 'preactive',
                component: () => import(/* webpackChunkName: "SnBankOfFlyerPreActive" */ '@/views/commons/components/bank-of-flyers/bank-of-flyers-module/bank-of-flyer-preactive/BankOfFlyerPreActive.vue'),
                name: 'list-preactive-social-network',
                meta: {
                    pageTitle: "Bank of flyers",
                    breadcrumb: [{
                        text: 'Preactive',
                        active: true,
                    }],
                    route: "socialnetwork",
                },
            },

        ],
        meta: {
            pageTitle: "Bank of flyers",
            breadcrumb: [{
                text: 'Bank of flyers',
                active: true,
            }],
            route: "social-network",
        },
    },
]
