<template>
  <b-container>
    <b-row>
      <b-col cols="8">
        <b-row>
          <b-col>
            <b-form-group label="Creditor:">
              <vue-autosuggest
                ref="autocomplete"
                :suggestions="filteredOptions"
                :get-suggestion-value="getSuggestionValue"
                :input-props="{
                  id: 'autosuggest__input',
                  class: 'form-control',
                }"
                @input="onInputChange"
                @selected="selectHandler"
              >
                <template slot-scope="{ suggestion }">
                  <span class="my-suggestion-item">{{
                    suggestion.item.value
                  }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Initial Balance:">
              <money
                v-model="creditor.balance"
                v-bind="vMoney"
                class="form-control"
                :disabled="disable.all"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Account Number:">
              <b-form-input v-model="creditor.account" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Actual Balance:">
              <money
                id="amount"
                v-model="creditor.actual_balance"
                v-bind="vMoney"
                class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col />
          <b-col>
            <b-form-checkbox
              v-model="creditor.collection_ds"
              value="1"
              unchecked-value="0"
            >
              <span class="text-primary">Collection</span>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Monthly payment:">
              <money
                id="amount"
                v-model="creditor.monthly"
                v-bind="vMoney"
                class="form-control"
                :disabled="disable.all"
              />
            </b-form-group>
          </b-col>
          <b-col />
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Monthly behind:">
              <b-form-input
                v-model="creditor.months"
                :disabled="
                  !(
                    dateOfCreation >= startOfMonth &&
                    dateOfCreation <= endOfMonth
                  )
                "
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Status:">
              <b-form-select
                v-model="creditor.account_status_ds"
                :options="statusesOptions"
                :disabled="disable.all"
              />
            </b-form-group>
          </b-col>
        </b-row>
        
        <b-row>
          <b-col cols="2">
            <b-form-group label="Interest rate:">
              <b-form-input
                :value="creditor.interest + ' %'"
                :disabled="disable.all"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Phase:">
              <b-form-select
                v-model="creditor.account_phase_id"
                :options="phases"
                value-field="id"
                text-field="description"
                :disabled="disabledPhase === 6 || disabledPhase === 9"
                @change="modalPhase(creditor.account_phase_id)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Card Number"
              v-if="isCreditCard && isMonthsZero"
            >
              <b-row>
                <!-- Input Number 1 -->
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="cardnumber1"
                    rules="required|min:4|max:4"
                  >
                    <b-form-input
                      :disabled="disable.all"
                      maxlength="4"
                      v-model="card.number1"
                      :class="{ 'border-danger': errors[0] }"
                      ref="input-1"
                      @input="activeFocus(1, 4)"
                      @blur="fillWithAstreisks(1)"
                    />
                  </validation-provider>
                </b-col>

                <!-- Input Number 2 -->
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="cardnumber2"
                    rules="required|min:4|max:4"
                  >
                    <b-form-input
                      :disabled="disable.all"
                      maxlength="4"
                      v-model="card.number2"
                      :class="{ 'border-danger': errors[0] }"
                      ref="input-2"
                      @input="activeFocus(2, 4)"
                      @blur="fillWithAstreisks(2)"
                    />
                  </validation-provider>
                </b-col>

                <!-- Input Number 3 -->
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="cardnumber3"
                    rules="required|min:4|max:4"
                  >
                    <b-form-input
                      :disabled="disable.all"
                      v-model="card.number3"
                      maxlength="4"
                      :class="{ 'border-danger': errors[0] }"
                      ref="input-3"
                      @input="activeFocus(3, 4)"
                      @blur="fillWithAstreisks(3)"
                    />
                  </validation-provider>
                </b-col>

                <!-- Input Number 4 -->
                <b-col>
                  <validation-provider v-slot="{ errors }" name="cardnumber4">
                    <b-form-input
                      :disabled="disable.all"
                      :rules="'required|max:' + maxCardLength"
                      v-model="card.number4"
                      :maxlength="maxCardLength"
                      :class="{ 'border-danger': errors[0] }"
                      ref="input-4"
                      @input="activeFocus(4, 4)"
                      @blur="fillWithAstreisks(4)"
                    />
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <b-form-group label="Type:">
              <b-form-select
                v-model="creditor.type_id"
                :options="types"
                :disabled="disable.all"
                value-field="id"
                text-field="value"
              />
            </b-form-group>
          </b-col>
          <b-col class="mt-1" cols="4" v-if="isCreditCard && isMonthsZero">
            <b-form-group label="Turn off automatic payments:">
              <div>
                <label style="color: #e26969; margin-right: 1rem">OFF</label>
                <label class="switch">
                  <b-form-checkbox
                    v-model="creditor.has_automatic_payments"
                    value="1"
                    switch
                    :disabled="disable.all"
                    type="checkbox"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">ON</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col class="mt-1" cols="4" v-if="isReposition">
            <b-form-group label="Has Car:" class="text-left">
              <div>
                <label style="color: #e26969; margin-right: 1rem">NO</label>
                <label class="switch">
                  <b-form-checkbox
                    v-model="creditor.has_car"
                    value="1"
                    switch
                    :disabled="disable.all"
                    type="checkbox"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col class="mt-1" cols="4" v-if="isReposition && hasCar">
            <b-form-group label="Was Auctioned:" class="text-left">
              <div>
                <label style="color: #e26969; margin-right: 1rem">NO</label>
                <label class="switch">
                  <b-form-checkbox
                    :disabled="disable.all"
                    v-model="creditor.was_auctioned"
                    switch
                    type="checkbox"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col class="mt-1" cols="4" v-if="isPersonalLoan">
            <b-form-group label="Has Warranty:">
              <div>
                <label style="color: #e26969; margin-right: 1rem">NO</label>
                <label class="switch">
                  <b-form-checkbox
                    :disabled="disable.all"
                    v-model="creditor.has_warranty"
                    switch
                    value="1"
                    type="checkbox"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col class="mt-1" cols="4" v-if="isPersonalLoan && hasWarranty">
            <b-form-group label="Select Motives:">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-select
                  :disabled="disable.all"
                  v-model="creditor.motives"
                  :class="{ 'bri-select': errors[0] }"
                  :options="personalLoanMotives"
                  :reduce="(value) => value.id"
                  label="value"
                />
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col class="mt-1" cols="4" v-if="isCT">
            <b-form-group label="Judgment Exist:">
              <div>
                <label style="color: #e26969; margin-right: 1rem">NO</label>
                <label class="switch">
                  <b-form-checkbox
                    :disabled="disable.all"
                    v-model="creditor.judgment_exists"
                    switch
                    value="1"
                    type="checkbox"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col class="mt-1" cols="4" v-if="isCT && judgmentExists">
            <b-form-group label="Judgment Type:">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <v-select
                  :disabled="disable.all"
                  v-model="creditor.judgment_type"
                  :class="{ 'bri-select': errors[0] }"
                  :options="judgmentOptions"
                  :reduce="(value) => value.id"
                  label="value"
                />
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="4" v-if="isCollection">
            <b-form-group label="Do you know which Collection it is in? :">
              <div>
                <label style="color: #e26969; margin-right: 1rem">NO</label>
                <label class="switch">
                  <b-form-checkbox
                    :disabled="disable.all"
                    v-model="creditor.has_collection_knowledge"
                    switch
                    type="checkbox"
                    value="1"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="4" v-if="isCollection && hasCollectionKnowledge">
            <b-form-group label="Collection Name:">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-form-input
                  :disabled="disable.all"
                  v-model="creditor.collection_name"
                  :style="errors[0] ? 'border-color:red' : ''"
                />
                <div v-if="errors[0]" class="invalid-feedback">
                  The field {{ errors[0] }}
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>

          <b-col cols="4" v-if="isOthers">
            <b-form-group label="New Debt Type :">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-form-input
                  :disabled="disable.all"
                  v-model="creditor.new_debt_type"
                  :style="errors[0] ? 'border-color:red' : ''"
                />
                <div v-if="errors[0]" class="invalid-feedback">
                  The field {{ errors[0] }}
                </div>
              </ValidationProvider>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group label="Unsecured Type">
              <div>
                <label style="color: #e26969; margin-right: 1rem">OFF</label>
                <label class="switch">
                  <b-form-checkbox
                    v-model="creditor.unsecured"
                    switch
                    type="checkbox"
                    value="1"
                    unchecked-value="0"
                    :disabled="disable.all"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">ON</label>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="4">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Recovery:">
              <div v-for="(item, index) in creditor.recovery" :key="index">
                {{ "- " + typeof item.phone === "undefined" ? "" : item.phone }}
              </div>
              <span v-if="creditor.recovery.length === 0"> - </span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Address:">
              <b-form-input
                :value="
                  creditor.mailing_address === null
                    ? '-'
                    : creditor.mailing_address
                "
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Settlement:">
              <b-form-input
                :value="
                  creditor.settlement === null
                    ? '-'
                    : creditor.settlement + ' %'
                "
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Memo:">
              <b-form-textarea v-model="creditor.memo" />
            </b-form-group>
            <span v-if="creditor.updated_memo !== ''"
              ><span>Updated by:</span> <br />
              <span>{{ creditor.updated_memo }}</span>
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <modal-edit-phase-creditor
      v-if="modalPhaseCreditorOn"
      :title="nameCreditor"
      :id-creditor="idcreditor"
      :id-phase="phaseToSend"
      @actFunEmitEditCreator="saveEditCreditor"
      @close="closeModalEditPhaseCreditor"
      @reloadAmountSaved="reloadAmountSaved"
    />
  </b-container>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
import ModalEditPhaseCreditor from "@/views/commons/components/clients/dashboard/options/acc-module/modals/ModalEditPhaseCreditor.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";

export default {
  name: "EditCreditorComponent",
  data() {
    return {
      maxCardLength: 1,
      personalLoanMotives: [
        { id: 1, value: "Home" },
        { id: 2, value: "Car" },
        { id: 3, value: "Others" },
      ],
      judgmentOptions: [
        { id: 1, value: "WG" },
        { id: 2, value: "Lien" },
        { id: 3, value: "Bank Levy" },
        { id: 4, value: "None" },
      ],
      modalPhaseCreditorOn: false,
      filteredOptions: [],
      disabledPhase: "",
      card: {
        number1: "",
        number2: "",
        number3: "",
        number4: "",
      },
      creditor: {
        id: "",
        recovery: "",
        has_automatic_payments: "",
        has_car: "",
        was_auctioned: "",
        has_warranty: "",
        motives: "",
        judgment_exists: "",
        judgment_type: "",
        has_collection_knowledge: "",
        has_collection_knowledge: "",
        collection_name: "",
        new_debt_type: "",
      },
      nameCreditor: "",
      disable: {
        all: true,
        save: false,
      },
      types: [],
      phases: [],
      dateOfCreation: "",
      vMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false,
      },
      startOfMonth: "",
      endOfMonth: "",
      statusesOptions: [
        {
          value: 1,
          text: "LATE PAYMENT(LP)",
        },
        {
          value: 2,
          text: "CHARGE OFF (CO)",
        },
        {
          value: 3,
          text: "READY TO SETTLE (RTS)",
        },
      ],
      phaseToSend: "",
      phaseSaved:""
    };
  },
  components: {
    VueAutosuggest,
    ModalEditPhaseCreditor,
  },
  props: {
    idcreditor: null,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isInThisMonth() {
      return (
        this.dateOfCreation >= this.startOfMonth &&
        this.creditor.created_at <= this.endOfMonth
      );
    },

    isMonthsZero() {
      return this.creditor.months == 0;
    },
    isCreditCard() {
      return this.creditor.type_id == 1;
    },
    isReposition() {
      return this.creditor.type_id == 2;
    },
    isPersonalLoan() {
      return this.creditor.type_id == 3;
    },
    isCT() {
      return this.creditor.type_id == 6;
    },
    isCollection() {
      return this.creditor.type_id == 8;
    },
    isOthers() {
      return this.creditor.type_id == 9;
    },

    hasCar() {
      return this.creditor.has_car == 1;
    },

    hasWarranty() {
      return this.creditor.has_warranty == 1;
    },

    judgmentExists() {
      return this.creditor.judgment_exists == 1;
    },
    hasCollectionKnowledge() {
      return this.creditor.has_collection_knowledge == 1;
    },
  },
  watch: {
    // balance(val) {
    //   this.monthly = (val * 0.03).toFixed(2);
    // },
    "card.number1"(val) {
      if (val) {
        let firstChar = val.charAt(0);
        // if first char starts with 4, 5 or 6 the card can be 16 digits long
        if (firstChar == 4 || firstChar == 5 || firstChar == 6) {
          this.maxCardLength = 4;
          // this.card.number2 = "";
          // this.card.number3 = "";
          // this.card.number4 = "";
        }
        // if first char starts with 3 the card can be 15 digits long
        else if (firstChar == 3) {
          this.maxCardLength = 3;
          // this.card.number2 = "";
          // this.card.number3 = "";
          // this.card.number4 = "";
        }
      }
    },
  },

  async created() {
    this.addPreloader();
    await Promise.all([
      this.allTypes(),
      this.getDataCreditor(),
      this.getAccountPhases(),
    ]);
    const date = new Date();
    this.startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    this.endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.dateOfCreation = new Date(this.creditor.created_at);
    this.removePreloader();
  },
  methods: {
    fillWithAstreisks(index) {
      if (index === 1) {
        this.card.number1 = this.card.number1.padEnd(4, "*");
      }
      if (index === 2) {
        this.card.number2 = this.card.number2.padEnd(4, "*");
      }
      if (index === 3) {
        this.card.number3 = this.card.number3.padEnd(4, "*");
      }
      if (index === 4) {
        this.card.number4 = this.card.number4.padEnd(this.maxCardLength, "*");
        this.card.number1 = this.card.number1.padEnd(4, "*");
        this.card.number2 = this.card.number2.padEnd(4, "*");
        this.card.number3 = this.card.number3.padEnd(4, "*");
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.value;
    },
    closeModalEditPhaseCreditor(num) {
      this.modalPhaseCreditorOn = false;
      if (num === 1) this.$emit("close");
      this.creditor.account_phase_id=this.phaseSaved;
    },
    modalPhase(phase) {
      this.modalPhaseCreditorOn = phase === 6 || phase === 9;
      this.phaseToSend = phase;
    },
    onInputChange(text) {
      this.creditor.id = null;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        return;
      }
      amgApi
        .post(`/sales-made/debt-solution/search-creditors?q=${text}`)
        .then((response) => {
          if (response.status === 200) {
            this.filteredOptions = [{ data: [...response.data] }];
          }
        });
    },
    selectHandler(value) {
      this.creditor.id = value.item.id;
      this.nameCreditor = value.item.value;
    },
    async allTypes() {
      try {
        const response = await amgApi.get(
          "/sales-made/debt-solution/get-debts-types"
        );
        if (response.status === 200) {
          this.types = response.data;
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    async getDataCreditor() {
      try {
        const { data, status } =
          await ClientsOptionsServices.getDataEditCreditor({
            id: this.idcreditor,
          });
        if (status === 200) {
          this.creditor = data[0];
          this.creditor.id = data[0].credit_id;
          this.creditor.recovery =
            data[0].recovery === null ? [] : JSON.parse(data[0].recovery);
          this.$refs.autocomplete.value = this.creditor.credit_name;
          this.nameCreditor = this.creditor.credit_name;
          this.disabledPhase = this.creditor.account_phase_id;
          this.phaseSaved=this.creditor.account_phase_id;
          this.creditor.has_automatic_payments = data[0].has_automatic_payments;
          this.creditor.has_car = data[0].has_car;
          this.creditor.was_auctioned = data[0].was_auctioned;
          this.creditor.has_warranty = data[0].has_warranty;
          this.creditor.motives = data[0].motives;
          this.creditor.judgment_exists = data[0].judgment_exists;
          this.creditor.judgment_type = data[0].judgment_type;
          this.creditor.has_collection_knowledge =
            data[0].has_collection_knowledge;
          this.creditor.collection_name = data[0].collection_name;
          this.creditor.new_debt_type = data[0].new_debt_type;

          if (data[0].card_number) {
            this.card.number1 = data[0].card_number.slice(0, 4);
            this.card.number2 = data[0].card_number.slice(4, 8);
            this.card.number3 = data[0].card_number.slice(8, 12);
            this.card.number4 = data[0].card_number.slice(12, 16);
          }
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    async saveEditCreditor(num = 0) {

      try {
        let confirmed = "";
        if (num !== 1) confirmed = await this.showConfirmSwal();
        if (confirmed.isConfirmed || num === 1) {
          this.addPreloader();

          let params = {
            id_user: this.currentUser.user_id,
            creditorSelectId: this.creditor.id,
            creditor_id: this.idcreditor,
            original_creditor: this.creditor.credit_id,
            accountnumber: this.creditor.account,
            phase: this.creditor.account_phase_id,
            collection: this.creditor.collection_ds,
            actualbalance: this.creditor.actual_balance,
            memo: this.creditor.memo,
            // card_number:
            //   this.card.number1 +
            //   this.card.number2 +
            //   this.card.number3 +
            //   this.card.number4,
          };

          // if (this.isCreditCard && this.isMonthsZero) {
          //   params.has_automatic_payments =
          //     this.creditor.has_automatic_payments;
          // }

          // if (this.isReposition) {
          //   params.has_car = this.creditor.has_car;
          // }
          // if (this.isReposition && this.hasCar) {
          //   params.was_auctioned = this.creditor.was_auctioned;
          // }

          // if (this.isPersonalLoan) {
          //   params.has_warranty = this.creditor.has_warranty;
          // }

          // if (this.isPersonalLoan && this.hasWarranty) {
          //   params.motives = this.creditor.motives;
          // }

          // if (this.isCT) {
          //   params.judgment_exists = this.creditor.judgment_exists;
          // }

          // if (this.isCT && this.judgmentExists) {
          //   params.judgment_type = this.creditor.judgment_type;
          // }

          // if (this.isCollection) {
          //   params.has_collection_knowledge =
          //     this.creditor.has_collection_knowledge;
          // }

          // if (this.isCollection && this.hasCollectionKnowledge) {
          //   params.collection_name = this.creditor.collection_name;
          // }
          // if (this.isOthers) {
          //   params.new_debt_type = this.creditor.new_debt_type;
          // }

          const response = await ClientsOptionsServices.updateCreditor(params);
          if (response.status === 200) {
            this.removePreloader();
            this.showSuccessSwal();
            this.$emit("reload");
            this.$emit("close");
          }
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
    reloadAmountSaved() {
      this.$emit("reloadAmountSaved")
    },
    async getAccountPhases() {
      try {
        const response = await ClientsOptionsServices.getAccountPhase({});
        if (response.status === 200) {
          this.phases = response.data;
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style scoped>
</style>
