<template>
  <b-modal
    v-model="showModal"
    :size="withDetails ? 'xlg' : 'lg'"
    header-bg-variant="primary"
    no-close-on-backdrop
    @hidden="closeModal"
    title="Payment details"
    title-class="h3 text-white font-weight-bolder"
    hide-footer
  >
    <template>
      <b-row>
        <b-col cols="12" md="6">
          <span class="title-tag">ACCOUNT</span>
          <div class="border border-secondary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ account.account }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <span class="title-tag">CLIENT</span>
          <div class="border border-secondary rounded">
            <p
              class="text-primary d-flex align-items-center justify-content-center my-1s"
              style="gap: 5px"
            >
              {{ account.client_name || "-" }}
            </p>
          </div>
        </b-col>
      </b-row>
      <div class="mt-1 border-secondary border-3 rounded p-1">
        <div v-if="accountData.transactions.state_lp">
          <span class="font-weight-bolder">LAST PAYMENT:</span>
          <span
            class="font-weight-bolder"
            style="font-size: 13px"
            :class="
              accountData.transactions.state_lp > 0
                ? 'text-danger'
                : 'text-success'
            "
          >
            {{ accountData.transactions.last_payment || "-" }}
          </span>
        </div>
        <div class="mt-1" v-if="!withDetails">
          <span class="font-weight-bolder">AMOUNT:</span>
          <span class="font-weight-bolder" style="font-size: 13px">
            $ {{ account.last_payment || "-" }}
          </span>
        </div>
        <hr />
        <b-row class="mt-1">
          <b-col cols="12" md="4">
            <div class="card-payment">
              <div class="payment-name text-uppercase">TOTAL CHARGES</div>
              <div class="payment-counter border-payment">
                $ {{ account.total_charges }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="card-payment">
              <div class="payment-name text-uppercase">TOTAL PAYMENTS</div>
              <div class="payment-counter border-payment">
                $ {{ account.total_payments }}
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="card-payment">
              <div class="payment-name text-uppercase">BALANCE</div>
              <div
                class="payment-counter border-payment"
                :class="account.is_negative ? 'text-danger' : ''"
              >
                $ {{ account.balance }}
              </div>
            </div>
          </b-col>
        </b-row>
        <template v-if="withDetails">
          <hr />
          <modal-balance-tickets
            withDetails
            :client_account_id="accountData.client_account_id"
          />
        </template>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import CustomerTicketsServices from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import ModalBalanceTickets from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/ModalBalanceTickets.vue";
export default {
  components: {
    ModalBalanceTickets,
  },
  props: {
    customer_ticket_id: {
      type: Number,
      required: false,
    },
    accountData: {
      type: Object,
      required: true,
    },
    withDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      account: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    this.addPreloader();
    await this.getPaymentDetailsByClientAccount();
    this.showModal = true;
    this.removePreloader();
  },
  methods: {
    async getPaymentDetailsByClientAccount() {
      this.addPreloader();
      try {
        const params = {
          client_account: this.accountData.account,
        };
        const { data } =
          await CustomerTicketsServices.getPaymentDetailsCustomerTickets(
            params
          );
        this.account = data;
      } catch (e) {
        this.spinner = false;
        console.log(e);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.card-payment {
  border: 1px solid #3f3f3f;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
}

.payment-name {
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2px 0;
  background-color: #1c90e7;
  color: #fff;
}

.payment-counter {
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-payment {
  border-top: 1px solid #3f3f3f;
}
</style>