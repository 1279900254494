<template>
  <div>
    <b-row>
      <b-col cols="12" md="3">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group label="Type">
            <v-select
              v-bind="typeScope.attr"
              v-model="formExpense.type"
              :class="{ 'invalid-select': errors.length > 0 }"
              :disabled="disabledType"
            ></v-select>
          </b-form-group>
        </ValidationProvider>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Routing number">
          <b-form-input disabled v-model="routingNumber"/>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="5">
        <b-form-group label="Account number">
          <b-form-input disabled v-model="accountNumber"/>
        </b-form-group>
      </b-col>
    </b-row>
    <slot name="selectors-general" />
    <slot name="transaction-inputs" />
  </div>
</template>
<script>
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";

export default {
  name: "ECheckForm",
  props: {
    formExpense: {
      type: Object,
      required: true,
    },
    disabledType: {
      type: Boolean,
      required: true,
    },
    programIdClient: {
      type: Number,
      default: () => null,
    },
    methodScope: {
      type: Object,
      required: true,
    },
    typeScope: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fileToUpload: null,
      accountData: {
        routing_number: null,
        account_number: null,
      },
      accountNumber: null,
      routingNumber: null,
    };
  },
  computed: {
    openFromDashboardCeo() {
      return this.$route.matched[0].name === "ceo-dashboard";
    },
  },
  methods: {
    async getAccountData(){
      try {
        this.addPreloader();
        const data = await DepartmentExpenses.getAccountData();
        this.accountData = {...data};
      } catch (ex) {
        this.showErrorSwal(ex);
      } finally {
        this.removePreloader();
      }
    },
    removeFile() {
      this.$emit("removeFile");
    },
    previewPdf(file) {
      this.$emit("previewPdf", file);
    },
  },
  async mounted() {
    await this.getAccountData();
    this.accountNumber = `XXXX${this.accountData.account_number.slice(-4)}`;
    this.routingNumber = `XXXX${this.accountData.routing_number.slice(-4)}`;
  },
};
</script>
