<template>
  <div>
    <validation-observer v-slot="{ invalid }">
      <b-row>
        <b-col lg="12" xl="12" sm="12" md="12" cols="12">
          <b-form-group label="Commentary">
            <validation-provider
              v-if="caseInfo.is_lead == 1"
              #default="{ errors }"
              name="commentary"
              vid="commentary"
              rules="required"
            >
              <b-form-textarea
                v-model="commentary"
                size="lg"
                rows="3"
                :state="errors.length > 0 ? false : null"
                name="commentary"
                placeholder="Write your commentary"
                :disabled="readOnly"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <validation-provider
              v-else
              name="commentary"
              rules="required"
              tag="div"
              class="commentary-editor"
              style="height: calc(100% - 35px)"
            >
              <quill-editor
                id="quil-content"
                :value="commentary"
                :options="editorOption"
                @change="(v) => (commentary = v.html)"
                style="height: 50%; overflow: hidden"
                class="chat-compose-fields-header chat-compose-file-field quill-editor-tracking"
                :disabled="readOnly"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end">
        <b-button
          variant="outline-success"
          @click="saveCommentary"
          :disabled="invalid"
        >
          <feather-icon icon="SaveIcon" class="mr-50" />
          SAVE
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
export default {
  props: {
    caseInfo: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    quillEditor,
  },
  data() {
    return {
      commentary: "",
      editorOption: {
        modules: { toolbar: true },
      },
    };
  },
  methods: {
    saveCommentary() {
      this.$emit("saveCommentary", this.commentary);
    },
  },
};
</script>