export default [
    {
        path: "call-log",
        name: "analyst-department-call-log",
        redirect: { name: "call-log-internal-specialists" },
        component: () =>
            import(
                /* webpackChunkName: "Managment call-log" */
                "@/views/commons/components/ring-central/call-log/CallLog.vue"
            ),
        meta: {
            pageTitle: "Call log",
            permittedRoles: [1, 2, 17, 24],
            breadcrumb: [
                {
                    text: "call log",
                    active: true,
                },
            ],
            callLogInternalRoute: 'call-log-internal-specialists',
            callLogExternalRoute: 'call-log-external-specialists',
            callLogOthersRoute: 'call-log-others-specialists'

        },
        children: [
            {
                path: "internal",
                name: "call-log-internal-specialists",
                component: () =>
                    import(
                        /* webpackChunkName: "call-log-all" */
                        "@/views/commons/components/ring-central/call-log/components/ListCallLog.vue"
                    ),
                meta: {
                    pageTitle: "Call log",
                    permittedRoles: [1, 2, 17, 24],
                    breadcrumb: [
                        {
                            text: "Internal",
                            active: true,
                        },
                    ],
                    status: 1,
                },
            },
            {
                path: "external",
                name: "call-log-external-specialists",
                redirect: { name: "call-log-external-inbound-specialists" },
                component: () =>
                    import(
                        /* webpackChunkName: "call-log-all" */
                        "@/views/commons/components/ring-central/call-log/tabs/CallLogTabs.vue"
                    ),
                meta: {
                    pageTitle: "Call log",
                    permittedRoles: [1, 2, 17, 24],
                    breadcrumb: [
                        {
                            text: "External",
                            active: true,
                        },
                    ],
                    callLogExternalInboundRoute: 'call-log-external-inbound-specialists',
                    callLogExternalOutboundRoute: 'call-log-external-outbound-specialists'
                },

                children: [
                    {
                        path: "inbound",
                        name: "call-log-external-inbound-specialists",
                        component: () => import(
                            /* webpackChunkName: "call-log-all" */
                            "@/views/commons/components/ring-central/call-log/components/ListCallLog.vue"
                        ),
                        meta: {
                            pageTitle: "Call log",
                            permittedRoles: [1, 2, 17, 24],
                            breadcrumb: [
                                {
                                    text: "Inbound",
                                    active: true,
                                },
                            ],
                            status: 2,
                        },
                    },
                    {
                        path: "outbound",
                        name: "call-log-external-outbound-specialists",
                        component: () => import(
                            /* webpackChunkName: "call-log-all" */
                            "@/views/commons/components/ring-central/call-log/components/ListCallLog.vue"
                        ),
                        meta: {
                            pageTitle: "Call log",
                            permittedRoles: [1, 2, 17, 24],
                            breadcrumb: [
                                {
                                    text: "Outbound",
                                    active: true,
                                },
                            ],
                            status: 3,
                        },
                    }
                ]
            },
            {
                path: "others",
                name: "call-log-others-specialists",
                component: () =>
                    import(
                        /* webpackChunkName: "call-log-all" */
                        "@/views/commons/components/ring-central/call-log/components/ListCallLog.vue"
                    ),
                meta: {
                    pageTitle: "Call log",
                    permittedRoles: [1, 2, 17, 24],
                    breadcrumb: [
                        {
                            text: "External",
                            active: true,
                        },
                    ],
                    status: 4,
                },
            },
        ],
    },
]
