var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-body',{staticClass:"p-0"},[_c('b-table',{staticClass:"mb-0",attrs:{"show-empty":"","sticky-header":"17vh","striped":"","responsive":"sm","fields":_vm.fieldsEvent,"items":_vm.S_CREDIT_REPORTS,"busy":_vm.busy,"small":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(provider)",fn:function(data){return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(data.item.plataform_name),expression:"data.item.plataform_name",modifiers:{"hover":true,"top":true}}],staticStyle:{"width":"20px","height":"20px","background-position":"center","background-repeat":"no-repeat","background-size":"contain"},style:({
            backgroundImage: ("url(" + (_vm.baseUrl + data.item.plataform_icon) + ")"),
          })})]}},{key:"cell(date)",fn:function(data){return [_vm._v(_vm._s(_vm._f("myGlobal")(data.item.date)))]}},{key:"cell(cr)",fn:function(data){return [(
            _vm.currentUser.modul_id === 4 &&
            data.item.state == 0 &&
            data.item.plataform_type == 'Source'
          )?_c('span',{staticClass:"text-danger",on:{"click":function($event){/* *INTEGRATE* resources\js\components\lead\showlead\ContentCreditReport.vue - on: processhtml(data.item.id) */}}},[_vm._v("Process")]):_vm._e(),(_vm.moduleId == 5)?_c('div',[(data.item.state == 1)?_c('router-link',{attrs:{"to":{
              name: 'debtsolution-report-lead',
              params: {
                idfile: data.item.id,
                idlead: data.item.lead_id,
                modul: 5,
                global: {
                  idfile: data.item.score_id,
                  idlead: data.item.lead_id,
                },
              },
            },"target":"_blank"}},[_c('amg-icon',{attrs:{"icon":"FileChartIcon","size":"18"}})],1):_vm._e()],1):(_vm.moduleId == 20)?_c('div',[(data.item.state == 1)?_c('router-link',{attrs:{"to":{
              name: 'connection-report-lead',
              params: {
                idfile: data.item.id,
                idlead: data.item.lead_id,
                modul: 20,
                global: {
                  idfile: data.item.score_id,
                  idlead: data.item.lead_id,
                },
              },
            },"target":"_blank"}},[_c('amg-icon',{attrs:{"icon":"FileChartIcon","size":"18"}})],1):_vm._e()],1):(_vm.moduleId == 26)?_c('div',[(data.item.state == 1)?_c('router-link',{attrs:{"to":{
              name: 'sales-report-lead',
              params: {
                idfile: data.item.id,
                idlead: data.item.lead_id,
                modul: 26,
                global: {
                  idfile: data.item.score_id,
                  idlead: data.item.lead_id,
                },
              },
            },"target":"_blank"}},[_c('amg-icon',{attrs:{"icon":"FileChartIcon","size":"18"}})],1):_vm._e()],1):(_vm.moduleId == 22)?_c('div',[(data.item.state == 1)?_c('router-link',{attrs:{"to":{
              name: 'customerservice-report-lead',
              params: {
                idfile: data.item.id,
                idlead: data.item.lead_id,
                modul: 26,
                global: {
                  idfile: data.item.score_id,
                  idlead: data.item.lead_id,
                },
              },
            },"target":"_blank"}},[_c('amg-icon',{attrs:{"icon":"FileChartIcon","size":"18"}})],1):_vm._e()],1):_c('div',[(data.item.state == 1)?_c('router-link',{attrs:{"to":{
              name: 'report-lead',
              params: {
                idfile: data.item.id,
                idlead: data.item.lead_id,
                modul: 2,
                global: {
                  idfile: data.item.score_id,
                  idlead: data.item.lead_id,
                },
              },
            },"target":"_blank"}},[_c('amg-icon',{attrs:{"icon":"FileChartIcon","size":"18"}})],1):_vm._e()],1)]}},{key:"cell(pdf)",fn:function(data){return [(data.item.route_pdf)?_c('a',{staticClass:"button-little-size rounded-circle text-important",attrs:{"href":data.item.route_pdf,"target":"_blanck"}},[_c('amg-icon',{attrs:{"icon":"FilePdfIcon","size":"18"}})],1):_vm._e()]}},{key:"cell(ad)",fn:function(data){return [_c('b-button',{staticClass:"button-little-size rounded-circle",attrs:{"variant":"flat-info"},on:{"click":function($event){data.item
              .nlr; /* *INTEGRATE* resources\js\components\lead\showlead\ContentCreditReport.vue - on: openModalAD(data.item.nlr, data.item.lead_name) */}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"18"}})],1)]}},{key:"cell(marked)",fn:function(data){return [(data.item.marked_pdf)?_c('a',{staticClass:"button-little-size rounded-circle text-important",attrs:{"href":data.item.marked_pdf,"target":"_blanck"}},[_c('amg-icon',{attrs:{"icon":"FilePdfIcon","size":"18"}})],1):_vm._e()]}},{key:"cell(is_collected)",fn:function(data){return [_c('b-badge',{attrs:{"id":'hours-target' + data.index,"variant":data.item.is_collected == 1 ? 'light-warning' : 'light-success'}},[_vm._v(" "+_vm._s(data.item.is_collected == 1 ? "Not Collected" : "Collected")+" "),(data.item.is_collected == 2)?_c('b-tooltip',{attrs:{"custom-class":"ga","target":'hours-target' + data.index,"triggers":"hover","placement":"left"}},[_c('div',[_c('h6',{staticClass:"font-weight-bolder"},[_vm._v("COLLECTED DATA")]),_c('span',{staticClass:"font-weight-bolder text-justify",staticStyle:{"max-width":"1px !important"}},[_vm._v(" Created By: ")]),_c('span',{staticClass:"text-justify",staticStyle:{"max-width":"10px !important"}},[_vm._v(" "+_vm._s(data.item.created_by)+" ")]),_c('br'),_c('span',{staticClass:"font-weight-bolder text-justify",staticStyle:{"max-width":"1px !important"}},[_vm._v(" Amount: ")]),_c('span',{staticClass:"text-justify",staticStyle:{"max-width":"10px !important"}},[_vm._v(" $ "+_vm._s(_vm._f("currency")(data.item.amount))+" ")]),_c('br'),_c('span',{staticClass:"font-weight-bolder text-justify",staticStyle:{"max-width":"1px !important"}},[_vm._v(" Created At: ")]),_c('span',{staticClass:"text-justify",staticStyle:{"max-width":"10px !important"}},[_vm._v(" "+_vm._s(_vm._f("myGlobal")(data.item.created_at))+" ")]),_c('br')])]):_vm._e()],1)]}},{key:"cell(id)",fn:function(data){return [(
            data.item.date != null &&
            data.item.date != '' &&
            data.item.is_collected == 1
          )?_c('tabler-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.left",value:('Collect Payment'),expression:"'Collect Payment'",modifiers:{"hover":true,"left":true}}],staticClass:"text-success cursor-pointer",attrs:{"icon":"CoinIcon","size":"19"},on:{"click":function($event){return _vm.collectPayment(data.item)}}}):_vm._e()]}}])})],1),(_vm.modalCollectCR)?_c('ModalCollectCR',{attrs:{"lead":_vm.lead},on:{"hidden":function($event){_vm.modalCollectCR = false},"onCollectedPayment":_vm.refreshTable}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }