<template>
  <b-sidebar
    id="sidebar-right"
    v-model="ownControl"
    :title="dataR.type === 1 ? 'COPY' : 'MOVE'"
    bg-variant="white"
    right
    shadow
    @hidden="closeModal"
  >
    <template #header="{ hide }">
      <div
        class="d-flex justify-content-between mt-2"
        style="width: 100%; height: 80%"
      >
        <span class="text-primary k-font-weight-bold">{{
          dataR.type === 1 ? "COPY" : "MOVE"
        }}</span>
        <feather-icon
          class="text-primary cursor-pointer"
          icon="XIcon"
          size="20"
          @click="hide"
        />
      </div>
    </template>
    <template #footer="{ hide }">
      <div
        class="d-flex text-light align-items-center justify-content-end px-3 py-2"
      >
        <button-cancel @click="hide" />
        <b-button class="ml-1" variant="primary" @click="moveOrCopy">
          <b-spinner v-if="spinnerOn" small />
          {{ dataR.type === 1 ? "Copy" : "Move" }}
        </b-button>
      </div>
    </template>
    <b-list-group class="mt-1">
      <b-list-group-item
        v-for="(folder, index) in folders"
        :key="index"
        class="cursor-pointer"
        :active="folder.id === folderSelected.id"
        @click="folderSelected = folder"
      >
        <amg-icon
          icon="CustomFolderIcon"
          style="fill: #ff9f43"
          class="mr-50 text-warning"
          size="15"
        />{{ folder.file_name }}
      </b-list-group-item>
    </b-list-group>
  </b-sidebar>
</template>

<script>
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services'
import ButtonCancel from '@/views/commons/utilities/ButtonCancel.vue'

export default {
  components: {
    ButtonCancel,
  },
  props: {
    userId: null,
    dataR: null,
    clientAccountId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      ownControl: false,
      folders: [],
      spinnerOn: false,
      folderSelected: [],
    };
  },
  async mounted() {
    this.ownControl = true;
    await this.getFolderRoutes();
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async getFolderRoutes() {
      try {
        this.addPreloader();
        const data = await ClientsOptionsServices.getFoldersRoutes({
          idaccount: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.clientAccountId,
        });
        if (data.status === 200) {
          this.removePreloader();
          this.folders = data.data;
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
      }
    },
    async moveOrCopy() {
      try {
        this.spinnerOn = true;
        const data = await ClientsOptionsServices.moveCopyFile({
          account: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.clientAccountId,
          folder_id: this.folderSelected.id,
          folder_name: this.folderSelected.file_name,
          route: this.folderSelected.route,
          type: this.dataR.type,
          user_id: this.userId,
          files: this.dataR.data,
        });
        if (data.status === 200) {
          this.spinnerOn = false;
          this.closeModal();
          this.$emit("reload");
        }
      } catch (e) {
        this.spinnerOn = false;
        this.showErrorSwal(e);
      }
    },
  },
};
</script>

<style scoped>
</style>
