export default {
  namespaced: true,

  state: {
    S_CHANGE_PROGRAM: {},
    S_LENDS_DATA: [],
    S_USER_ALL_PROGRAMS: [],
  },

  mutations: {
    CHANGE_PROGRAM(state, params) {
      state.S_CHANGE_PROGRAM = params;
    },
    LENDS_DATA(state, params) {
      state.S_LENDS_DATA = params;
    },
    USER_ALL_PROGRAMS(state, params) {
      state.S_USER_ALL_PROGRAMS = params;
    },
  },
  actions: {
    A_CHANGE_PROGRAM({ commit }, data) {
      commit("CHANGE_PROGRAM", data);
    },
    A_LENDS_DATA({ commit }, data) {
      commit("LENDS_DATA", data);
    },
    A_USER_ALL_PROGRAMS({ commit }, data) {
      commit("USER_ALL_PROGRAMS", data);
    },
  },
  getters: {
    G_CHANGE_PROGRAM(state) {
      return state.S_CHANGE_PROGRAM;
    },
    G_LENDS_DATA(state) {
      return state.S_LENDS_DATA;
    },
    G_USER_ALL_PROGRAMS(state) {
      return state.S_USER_ALL_PROGRAMS;
    },
  },
};
