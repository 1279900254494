import store from "@/store";
import Vue from "vue";
import router from '@/router/index';

const ncrProgramRealtorRequested = () => {
  window.socket.bind("new-ncr-realtor-or-program", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id;
    if (data.to_id.includes(Number(sessionId))) {
      const swalConfig = {
        icon: "success",
        html: `<h2 style="font-weight: 600;">${data.message}</h2>`,
        showConfirmButton: true,
      };

      if (data.type != "realtor") {
        swalConfig.showCancelButton = true;
        swalConfig.confirmButtonText = `Go to NCR ${data.type.charAt(0).toUpperCase() + data.type.slice(1)}`;
        swalConfig.cancelButtonText = "Cancel";
      } else {
        swalConfig.showCancelButton = false;
      }

      const result = await Vue.swal.fire(swalConfig);

      if (result.value) {
        let route = ''
        if (data.type == "programs") {
          route = 'specialistdigital-process-cr-pending'
        }
        else if (data.type == "realtor") {
          //
        } else {
          route = 'spd-cr-realtor-pending'
        }
        router.push({ name: route })
          .catch((err) => {
            if (
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')
            ) {
              throw err;
            }
          });
        await window.amgApi.post("/commons/close-all-swal", data);
      } else {
        await window.amgApi.post("/commons/close-all-swal", data);
      }
    }
  });
};
export default ncrProgramRealtorRequested;