import Vue from "vue";
import Vuex from "vuex";

import BankOfFlyers from '@/views/social-network/views/bank-of-flyers/bank-of-flyers.store'
import CreativeDesign from "@/views/creative/views/creative-requests/store/creative-design.store";
Vue.use(Vuex);

export default {
  CreativeDesign,
  SocialNetworkBankOfFlyersStore: BankOfFlyers,
};
