export default [
    {
        path: "notes-wc",
        name: "calls-without-call",
        // redirect: { name: "calls-review-pending" },
        component: () =>
            import(/* webpackChunkName: "CallReviewMain" */ "@/views/quality/views/notes-without-calls/components/NotesWc.vue"),
        meta: {
            pageTitle: "Notes",
            breadcrumb: [
                {
                    text: "Without Calls",
                },
            ],
        },
    },
]