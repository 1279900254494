export default [
  {
    path: '/attendances',
    name: 'user-attendance-dashboard',
    component: () => import('@/views/assistance/views/dashboard/views/dashboardUser.vue'),
    meta: {
       pageTitle: 'Attendance Dashboard',
      breadcrumb: [
        {
            text: 'Attendance Dashboard',
        },
      ],
      layout: 'horizontal',
    },
    props: true,
  },

]
