import { amgApi } from "@/service/axios";

class ApplicationsService {
  async searchApplicationAdvisor(parameters) {
    /*
        recibe los sgtes parametros
            column:
            filter_advisor:
            filter_paid:
            filter_program:
            filter_result:
            filter_status:
            from:
            id:
            order:
            orderby:
            role_id:
            status_app:
            to:

        */
    try {
      let data = await amgApi.post(
        "applications/search-application-advisor",
        parameters
      );
      return data;
    } catch (error) {
       throw error;
    }
  }
  async getUsersProgramNcr(parameters) {
    try {
      let data = await amgApi.post("program/get-users-program-ncr", parameters);
      return data;
    } catch (error) {
       throw error;
    }
  }
  async getPersonalInformation(parameter) {
    try {
      let data = await amgApi.post(
        "applications/search-personal-information",
        parameter
      );
      return data;
    } catch (error) {
       throw error;
    }
  }
  async getCountries() {
    try {
      let data = await amgApi.post("commons/get-world-countrys");
      return data;
    } catch (error) {
       throw error;
    }
  }
  async getInternalEmail(id) {
    try {
      let data = [];
      let result = await amgApi.post("applications/get-access-credentials", {
        ca_id: id,
      });
      result.data.map((el) => {
        data.push({ id: el.id, email: el.user });
      });
      return data;
    } catch (error) {
       throw error;
    }
  }
  async savePersonalInfo(parameters) {
    try {
      let data = await amgApi.post(
        "applications/save-personal-info-form",
        parameters
      );
      return data.status === 200;
    } catch (error) {
       throw error;
      return false;
    }
  }
  async updateStatusApp(parameters) {
    try {
      let data = await amgApi.post(
        "applications/app-status-update",
        parameters
      );
      return data.status === 200;
    } catch (error) {
      throw error;
    }
  }

  async setAppInProgress(parameters) {
    try {
      let data = await amgApi.post(
        "applications/app-in-progress",
        parameters
      );
      return data.status === 200;
    } catch (error) {
      throw error;
    }
  }

  async getBankInfo(parameter) {
    try {
      let data = await amgApi.post("applications/get-bank-info", parameter);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getBanks(parameter) {
    try {
      let data = await amgApi.post("applications/get-all-banks", parameter);
      return data;
    } catch (error) {
       throw error;
    }
  }
  async getFeatures() {
    try {
      let data = await amgApi.get("applications/get-all-features");
      return data;
    } catch (error) {
       throw error;
    }
  }
  async getFiles(parameter) {
    try {
      let data = await amgApi.post("applications/get-files", {
        idApp: parameter,
      });
      return data;
    } catch (error) {
       throw error;
    }
  }
  async uploadFiles(parameters) {
    try {
      let data = await amgApi.post("applications/save-file", parameters);
      return data.status === 200;
    } catch (error) {
       throw error;
    }
  }
  async deleteFile(parameters) {
    try {
      let data = await amgApi.post("applications/delete-file", parameters);
      return data.status === 200;
    } catch (error) {
       throw error;
    }
  }
  async getTracking(parameters) {
    try {
      let data = await amgApi.post("applications/get-tracking", parameters);
      return data;
    } catch (error) {
       throw error;
    }
  }
  async getTypesCharges(body) {
    try {
      let data = await amgApi.post("applications/get-types-charges", body);
      return data;
    } catch (error) {
       throw error;
    }
  }
  async searchClientByAccount(parameters) {
    try {
      let data = await amgApi.post(
        "applications/search-client-by-account",
        parameters
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async saveApplication(parameters) {
    try {
      let data = await amgApi.post("applications/save-application", parameters);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async assignAgentToApplication(parameters) {
    try {
      let data = await amgApi.post(
        "applications/assign-agent-to-app",
        parameters
      );
      return data.status == 200;
    } catch (error) {
       throw error;
    }
  }
  // recibe de parametro el id de client account
  async searchAccount(parameter) {
    try {
      let data = await amgApi.post("commons/search-account", parameter);
      return data;
    } catch (error) {
       throw error;
    }
  }
  // recibe de parametro el id del lead
  async searchCardsClient(parameter) {
    try {
      let data = await amgApi.post("clients/search-cards-clients", parameter);
      return data;
    } catch (error) {
       throw error;
    }
  }
  // recibe id del modulo y id del cliente
  async getTypesChargesByClient(parameters) {
    try {
      let data = await amgApi.post(
        "applications/get-type-charges-application",
        parameters
      );
      return data;
    } catch (error) {
       throw error;
    }
  }
  async saveCharge(parameters) {
    try {
      let data = await amgApi.post("applications/save-charge", parameters);
      return data;
    } catch (error) {
       throw error;
    }
  }
  async saveBankInfo(parameters) {
    try {
      let result = await amgApi.post("applications/save-bank-info", parameters);
      return result.status == 200;
    } catch (error) {
       throw error;
    }
  }
  async updateStatusApplication(parameter) {
    try {
      let result = await amgApi.post("applications/update-status-application", parameter);
      return result;
    } catch (error) {
       throw error;
    }
  }
  async getNotificationsApp(parameter) {
    try {
      let result = await amgApi.post(
        "applications/get-notifications-application",
        {
          iduser: parameter.id,
        }
      );
      return result.data;
    } catch (error) {
       throw error;
    }
  }
  async getOccupations() {
    const { data } = await amgApi.post("applications/get-occupations");
    const { employment_occupations, self_employment_occupations } = data;

    return { employment_occupations, self_employment_occupations };
  }

  async searchApplications(parameters) {
    try {
      const data = await amgApi.post(
        "applications/search-application",
        parameters
      );
      return data;
    } catch (error) {
       throw error;
    }
  }
  async searchApplicationsDigital(parameters) {
    try {
      const data = await amgApi.post(
        "applications/search-application-digital",
        parameters
      );
      return data;
    } catch (error) {
       throw error;
    }
  }
  async getSpecialists(parameters) {
    try {
      const data = await amgApi.post(
        "applications/get-advisor-specialists",
        parameters
      );
      return data;
    } catch (error) {
       throw error;
    }
  }
  async appDeleteApplication(parameters) {
    try {
      const data = await amgApi.post(
        "applications/delete-application",
        parameters
      );
      return data;
    } catch (error) {
       throw error;
    }
  }
  async setAgentToApplication(parameters) {
    try {
      const data = await amgApi.post(
        "applications/set-agent-to-application",
        parameters
      );
      return data;
    } catch (error) {
       throw error;
    }
  }
  async markAsSeenApplication(appId) {
    try {
      const { status } = await amgApi.post(
        "applications/mark-as-seen",
        { app_id: appId }
      );
      return status == 200;
    } catch (error) {
       throw error;
    }
  }

  async getTypesChargesUsed(parameters) {
    try {
      let data = await amgApi.get("applications/get-types-charges-used");
      return data;
    } catch (error) {
       throw error;
    }
  }

  async updateStatusOtherServices(parameters) {
    try {
      const data = await amgApi.post(
        "applications/update-status-other-services",
        parameters
      );
      return data;
    } catch (error) {
       throw error;
    }
  }

  async saveOtherPayment(params){
    try{
      const data = await amgApi.post("applications/charge-others/store", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  
  async getCountPending(params){
    try{
        const { data } = await amgApi.post(`applications/get-count-pending-applications`, params);
        return data;
    }catch(error){
        throw error;
    }
  }

  async getCounterAllServicesDashboardClient(params) {
    try {
      const { data } = await amgApi.post(`applications/count-all-services-dc`, params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}

export default new ApplicationsService();
