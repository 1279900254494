<template>
  <div>
    <b-nav card-header pills class="mt-1 ml-1 mb-0">
      <b-nav-item
        v-for="(tab, index) in tabs"
        :key="index"
        :to="{ name: tab.route }"
        exact
        exact-active-class="active"
        :link-classes="[
          $route.matched[3].name ==
            $route.matched[2].meta.routeForApprovedClient &&
          tab.title == 'For Approval'
            ? 'router-link-active'
            : '',
          'sub-tab-nav',
          'px-3 h-full',
          bgTabsNavs,
        ]"
      >
        {{ tab.title }}
        <span
          v-if="G_ADDCHANGE_COUNTERS > 0 && tab.title == 'For Approval'"
          class="ml-1"
        >
          <b-badge pill variant="danger">
            {{ G_ADDCHANGE_COUNTERS > 99 ? "99+" : G_ADDCHANGE_COUNTERS }}
          </b-badge>
        </span>
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-3 border-top-info border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import SalesMadeAddChangeComponent from "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AddChangeComponent",
  components: {
    SalesMadeAddChangeComponent,
  },
  data() {
    return {
      tabs: [
        {
          title: "Pending",
          key: 1,
          done: 0,
          route: this.$route.matched[2].meta.routePendingClient,
        },
        {
          title: "For Approval",
          key: 2,
          done: 1,
          route: this.$route.matched[2].meta.routeForApprovedClient,
        },
        {
          title: "Done",
          key: 3,
          done: 2,
          route: this.$route.matched[2].meta.routeDoneClient,
        },
      ],
      isForApprovedTab() {
        return [
          this.$route.matched[2].meta.routeForApprovedClientInProcess,
          this.$route.matched[2].meta.routeForApprovedClientCompleted,
        ].includes(this.$route.name);
      },
    };
  },
  computed: {
    ...mapGetters({
      G_ADDCHANGE_COUNTERS: "CrmSaleMadeStore/G_ADDCHANGE_COUNTERS",
    }),
  },
  created() {
    this.getPrograms();
  },
  methods: {
    ...mapActions({
      getPrograms: "CrmSaleMadeStore/A_GET_PROGRAMS",
    }),
  },
};
</script>

<style scoped></style>
