export default [
  {
    path: "clients",
    name: "debt-s-clients",
    component: () =>
      import(
        /* webpackChunkName: "DsClients" */ "@/views/debt-solution/views/clients/components/clients/ClientsTab"
      ),
    redirect: { name: "debt-s-clients-detail" },
    meta: {
      // permittedRoles: [1, 2],
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "debt-s-clients-detail",
        component: () =>
          import(
            /* webpackChunkName: "DSsClients" */ "@/views/debt-solution/views/clients/views/ClientsGrid.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
        },
      },
      {
        path: "shared",
        name: "debt-s-shared",
        component: () =>
          import(
            /* webpackChunkName: "DSsShared" */ "@/views/commons/components/clients/components/Shared.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Shared",
          breadcrumb: [
            {
              text: "Shared",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    // "@/views/debt-solution/views/clients/components/clients/tab/ClientsTab"
    path: "clients/test",
    name: "debt-s-clients-test",
    component: () =>
      import(
        /* webpackChunkName: "DsClients" */ "@/views/commons/components/clients/ClientsTab.vue"
      ),
    redirect: { name: "debt-s-clients-test" },
    meta: {
      // permittedRoles: [1, 2],
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
    },
    children: [
      {
        path: " ",
        name: "debt-s-clients-test",
        component: () =>
          import(
            /* webpackChunkName: "DSsClients" */ "@/views/commons/components/clients/components/Clients.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
        },
      },
      {
        path: "shared",
        name: "debt-s-shared-prueba",
        component: () =>
          import(
            /* webpackChunkName: "DSsShared" */ "@/views/commons/components/clients/components/Shared.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Shared",
          breadcrumb: [
            {
              text: "Shared",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "clients/account/:idClient",
    name: "debt-solution-dashboard",
    redirect: { name: "personal-information" },
    component: () =>
      import(
        /* webpackChunkName: "DsClientAccount" */ "@/views/commons/components/clients/dashboard/Account.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      requiredNewAppr: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay",
        name: "pay",
        component: () =>
          import(
            /* webpackChunkName: "DsClientAccountPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "acc",
        name: "acc",
        component: () =>
          import(
            /* webpackChunkName: "DsClientAccountAcc" */ "@/views/commons/components/clients/dashboard/options/acc-module/Acc.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acc",
              active: true,
            },
          ],
          offerCreatedFrom: "client-dashboard",
        },
      },
      {
        path: "dis",
        name: "dis",
        redirect: { name: "analysis-cr-ds" },
        component: () =>
          import(
            /* webpackChunkName: "DsClientAccountDis" */ "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Dispute",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "analysis-cr",
            name: "analysis-cr-ds",
            component: () =>
              import(
                /* webpackChunkName: "DsAnalysisCr" */ "@/views/commons/components/clients/dashboard/analysis/AccountsClients.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Items",
                  active: true,
                },
              ],
            },
          },
          {
            path: "dispute-detail",
            name: "dispute-detail-ds",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/DisputeDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Disputes",
                  active: true,
                },
              ],
            },
          },
          {
            path: "report-detail",
            name: "report-detail-ds",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Credit Report",
                  active: true,
                },
              ],
            },
          },
          {
            path: "updates-detail",
            name: "updates-detail-ds",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/UpdateDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Updates",
                  active: true,
                },
              ],
            },
          },
          {
            path: "workplan-detail",
            name: "workplan-detail-ds",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Workplan",
                  active: true,
                },
              ],
            },
          },
          {
            path: "recommendation-detail",
            name: "recommendation-detail-ds",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/services/services.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Recommendations",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "task-notes",
        name: "task-notes",
        component: () =>
          import(
            /* webpackChunkName: "DsClientAccountTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotesNew.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information",
        name: "personal-information",
        component: () =>
          import(
            /* webpackChunkName: "DsClientAccountPi" */ "@/views/commons/components/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files",
        name: "client-files",
        component: () =>
          import(
            /* webpackChunkName: "DsClientAccountFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr",
        name: "acr",
        component: () =>
          import(
            /* webpackChunkName: "DsClientAccountAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "report",
        name: "report",
        component: () =>
          import(
            /* webpackChunkName: "DsClientAccountReport" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "questionnaire",
        name: "ds-questionnaire",
        component: () =>
          import(
            "@/views/commons/components/clients/dashboard/options/questionnaire-module/DsQuestionnaire.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 5,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Questionnaire",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log",
        name: "ds-call-log",
        redirect: { name: "call-log-conversation-debtsolution" },
        component: () =>
          import(
            "@/views/commons/components/ring-central/call-log/tabs/ClientTabs.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 5,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
          callConversationRoute: "call-log-conversation-debtsolution",
          callNoConversationRoute: "call-log-not-conversation-debtsolution",
        },

        children: [
          {
            path: "conversation",
            name: "call-log-conversation-debtsolution",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Internal",
                  active: true,
                },
              ],
              isConversation: 1,
            },
          },
          {
            path: "no-conversation",
            name: "call-log-not-conversation-debtsolution",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Internal",
                  active: true,
                },
              ],
              isConversation: 0,
            },
          },
        ],
      },
      {
        path: "services",
        name: "services-client-debt-solution",
        component: () =>
          import(
            /* webpackChunkName: "servicesClient" */
            "@/views/commons/components/clients/dashboard/options/services/services.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 5,
          pageTitle: "Services",
          breadcrumb: [{ text: "Services", active: true }],
        },
      },
      {
        path: "tickets",
        name: "ds-client-tickets",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
