import { amgApi } from "@/service/axios";

class ScheduleService {
  async getHourReport(params) {
    const data = await amgApi.post("/schedules/get-hour-report", params);
    return data.data;
  }
  async getDetailsSchedule(params) {
    const data = await amgApi.post("/schedules/get-details-schedule", params);
    return data.data;
  }
  async getExportExcel(params) {
    const data = await amgApi.post("/schedules/get-export-excel", params, {
      responseType: "blob",
    });
    return data.data;
  }
  // ********************* JUSTIFY LATE / ABSENCE ********************
  async getPendingJustify(params) {
    const data = await amgApi.post("/schedules/get-pending-justify", params);
    return data.data;
  }
  async sendJustify(formData) {
    const data = await amgApi.post("/schedules/send-justify", formData);
    return data.data;
  }
  async getTrackingJustify(params) {
    const data = await amgApi.post("/schedules/get-tracking-justify", params);
    return data.data;
  }
  async getDoneJustify(params) {
    const data = await amgApi.post("/schedules/get-done-justify", params);
    return data.data;
  }
  async getFiles(params) {
    const data = await amgApi.post("/schedules/get-files-justify", params);
    return data.data;
  }
  async updateSeendStatus(params) {
    amgApi.post("/schedules/update-seen-status", params);
  }
  // ********************* OVERTIME ********************
  async getOvertime(params) {
    const data = await amgApi.post("/schedules/get-overtime", params);
    return data.data;
  }
  async getOvertimeRrhh(params) {
    const data = await amgApi.post("/schedules/get-overtime-rrhh", params);
    return data.data;
  }
  async sendJustifyOvertime(params) {
    const data = await amgApi.post("/schedules/send-justify-overtime", params);
    return data.data;
  }
  async getTrackingOvertime(params) {
    const data = await amgApi.post("/schedules/get-tracking-overtime", params);
    return data.data;
  }
  async getUsersModule(params) {
    const data = await amgApi.post("/schedules/get-users-module", params);
    return data;
  }
  async listScheduleModules(params) {
    const data = await amgApi.post("/schedules/list-modules-schedules", params);
    return data;
  }
  async insertSchedules(params) {
    const data = await amgApi.post("/schedules/insert-schedules", params);
    return data;
  }
  async insertSchedulesBucle(params) {
    const data = await amgApi.post("/schedules/insert-schedules-bucle", params);
    return data;
  }
  async reportSchedulesByUser(params) {
    const data = await amgApi.post(
      "/schedules/report-schedules-by-user",
      params
    );
    return data;
  }
  async getAllUsersForSchedules(params) {
    const data = await amgApi.post(
      "/schedules/get-all-users-for-schedules",
      params
    );
    return data;
  }
  async getAllCounters(params) {
    const data = await amgApi.post("/schedules/get-all-counters", params);
    return data;
  }
  async searchPendingJustify(params) {
    const data = await amgApi.post("/schedules/search-pending-justify", params);
    return data.data;
  }
  async searchDoneJustify(params) {
    const data = await amgApi.post("/schedules/search-done-justify", params);
    return data.data;
  }
  async updateApprovedJustifySchedules(params) {
    const data = await amgApi.post(
      "/schedules/update-approved-justify-schedules",
      params
    );
    return data;
  }
  async insertJustifyOvertime(params) {
    const data = await amgApi.post(
      "/schedules/insert-justify-overtime",
      params
    );
    return data;
  }
  async importSchedules(params) {
    const data = await amgApi.post("/schedules/import-schedules", params);
    return data;
  }
  async getSchedulesCounter(params) {
    const data = await amgApi.post("/schedules/total-counter", params);
    return data.data;
  }
}
export default new ScheduleService();
