import store from "@/store";
import PaymentsService from "@/views/crm/views/payments/service/payments.service.js";

export default {
  namespaced: true,
  state: {
    S_DAILY_SETTLEMENTS_DATE_RANGE: {
      startDate: null,
      endDate: null,
    },
    S_DAILY_SETTLEMENT_MERCHANT: {
      merchantId: null,
      merchantName: null,
    },
    S_CURRENT_DAILY_SETTLEMENT_MERCHANT: {
      merchantId: null,
      merchantName: null,
    },
    S_GET_DAILY_SETTLEMENT: [],
    S_GET_DAILY_SETTLEMENT_BY_MERCHANT: [],
    S_GET_DAILY_SETTLEMENT_BY_MERCHANT_DETAIL: [],
    S_GET_DAILY_SETTLEMENT_PIE: [],
    S_DAILY_AUTHORIZE: [],
    S_DAILY_AUTHORIZE_TITLE_COUNTER: {
      pending: 0,
      verified: 0,
      completed: 0,
    },
  },
  getters: {
    G_DAILY_SETTLEMENTS_DATE_RANGE(state) {
      return state.S_DAILY_SETTLEMENTS_DATE_RANGE;
    },
    G_DAILY_SETTLEMENT_MERCHANT(state) {
      return state.S_DAILY_SETTLEMENT_MERCHANT;
    },
    G_CURRENT_DAILY_SETTLEMENT_MERCHANT(state) {
      return state.S_CURRENT_DAILY_SETTLEMENT_MERCHANT;
    },
    G_GET_DAILY_SETTLEMENT(state) {
      return state.S_GET_DAILY_SETTLEMENT;
    },
    G_GET_DAILY_SETTLEMENT_BY_MERCHANT(state) {
      return state.S_GET_DAILY_SETTLEMENT_BY_MERCHANT;
    },
    G_GET_DAILY_SETTLEMENT_BY_MERCHANT_DETAIL(state) {
      return state.S_GET_DAILY_SETTLEMENT_BY_MERCHANT_DETAIL;
    },
    G_GET_DAILY_SETTLEMENT_PIE(state) {
      return state.S_GET_DAILY_SETTLEMENT_PIE;
    },
    G_DAILY_AUTHORIZE(state) {
      return state.S_DAILY_AUTHORIZE;
    },
    G_DAILY_AUTHORIZE_TITLE_COUNTER(state) {
      return state.S_DAILY_AUTHORIZE_TITLE_COUNTER;
    },
  },
  mutations: {
    M_DAILY_SETTLEMENTS_DATE_RANGE(state, payload) {
      state.S_DAILY_SETTLEMENTS_DATE_RANGE = payload;
    },
    M_DAILY_SETTLEMENT_MERCHANT(state, payload) {
      state.S_DAILY_SETTLEMENT_MERCHANT = payload;
    },
    M_CURRENT_DAILY_SETTLEMENT_MERCHANT(state, payload) {
      state.S_CURRENT_DAILY_SETTLEMENT_MERCHANT = payload;
    },
    M_GET_DAILY_SETTLEMENT(state, payload) {
      state.S_GET_DAILY_SETTLEMENT = payload;
    },
    M_GET_DAILY_SETTLEMENT_BY_MERCHANT(state, payload) {
      state.S_GET_DAILY_SETTLEMENT_BY_MERCHANT = payload;
    },
    M_GET_DAILY_SETTLEMENT_BY_MERCHANT_DETAIL(state, payload) {
      state.S_GET_DAILY_SETTLEMENT_BY_MERCHANT_DETAIL = payload;
    },
    M_GET_DAILY_SETTLEMENT_PIE(state, payload) {
      state.S_GET_DAILY_SETTLEMENT_PIE = payload;
    },
    M_DAILY_AUTHORIZE(state, payload) {
      state.S_DAILY_AUTHORIZE = payload;
    },
    M_DAILY_AUTHORIZE_TITLE_COUNTER(state, payload) {
      state.S_DAILY_AUTHORIZE_TITLE_COUNTER = payload;
    },
  },
  actions: {
    A_DAILY_SETTLEMENTS_DATE_RANGE({ commit }, payload) {
      commit("M_DAILY_SETTLEMENTS_DATE_RANGE", payload);
    },
    A_CURRENT_DAILY_SETTLEMENT_MERCHANT({ commit }, payload) {
      commit("M_CURRENT_DAILY_SETTLEMENT_MERCHANT", payload);
    },
    A_DAILY_SETTLEMENT_MERCHANT({ commit }, payload) {
      commit("M_DAILY_SETTLEMENT_MERCHANT", payload);
    },
    async A_GET_DAILY_SETTLEMENT({ commit }, payload) {
      const { data } = await PaymentsService.getDailySettlement(payload);
      commit("M_GET_DAILY_SETTLEMENT", data);
    },
    async A_GET_DAILY_SETTLEMENT_BY_MERCHANT({ commit }, payload) {
      const { data } = await PaymentsService.getDailySettlementByMerchant(
        payload
      );
      commit("M_GET_DAILY_SETTLEMENT_BY_MERCHANT", data);
    },
    async A_GET_DAILY_SETTLEMENT_BY_MERCHANT_DETAIL({ commit }, payload) {
      const { data } = await PaymentsService.getDailySettlementByMerchant(
        payload
      );
      commit("M_GET_DAILY_SETTLEMENT_BY_MERCHANT_DETAIL", data);
    },
    async A_GET_DAILY_SETTLEMENT_PIE({ commit }, payload) {
      const { data } = await PaymentsService.getDailySettlementsPie(payload);
      commit("M_GET_DAILY_SETTLEMENT_PIE", data);
    },
    async A_GET_DAILY_AUTHORIZE({ commit }, payload) {
      const { data } = await PaymentsService.getDailyAuthorize(payload);
      commit("M_DAILY_AUTHORIZE", data);
    },
    async A_GET_DAILY_AUTHORIZE_TITLE_COUNTER({ commit }) {
      const { data } = await PaymentsService.getCounterPendingAuthorizeReport();
      if (data.length > 0) {
        commit("M_DAILY_AUTHORIZE_TITLE_COUNTER", data[0]);
      }
    },
  },
};
