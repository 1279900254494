import DashboardRouter from "@/views/ce-digital/sub-modules/connection/views/dashboard/dashboard.router";
import CommissionsRouter from "@/views/ce-digital/sub-modules/connection/views/commissions/commissions.router";
import TrackingListRouter from "@/views/ce-digital/sub-modules/connection/views/tracking-list/tracking-list.router";
import LeadsRouter from "@/views/ce-digital/sub-modules/connection/views/leads/leads.router";
import SalesMadeRouter from "@/views/ce-digital/sub-modules/connection/views/sales-made/sales-made.router";
import HelpDeskRouter from "@/views/ce-digital/sub-modules/connection/views/helpdesk/helpdesk.route";
import CalendarRouter from "@/views/ce-digital/sub-modules/connection/views/calendar/calendar.router";
// import ClientsRouter from "@/views/ce-digital/sub-modules/connection/views/clients/router/clients.router";
import ClientsRouter from "@/views/ce-digital/sub-modules/connection/views/clients/router/clients.routerv2.js";
import ClientDashboardRouter from "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/router/index.router";
import SuccessRouter from "@/views/ce-digital/sub-modules/connection/views/success/router/success.router";
import ApplicationsRouter from "@/views/ce-digital/sub-modules/connection/views/applications/routes";
import LettersRouter from "@/views/ce-digital/sub-modules/connection/views/letters/routes";
import DirectRouter from "@/views/ce-digital/sub-modules/connection/views/direct/router/direct.router";
import NcrRealtorRouter from "@/views/ce-digital/sub-modules/connection/views/ncr-realtor/router/index";
import disputeResults from "@/views/ce-digital/sub-modules/connection/views/dispute-results/router/index";
import OtherPaymentsRouter from "@/views/ce-digital/sub-modules/connection/views/other-payments/other-payments.router";
import CeConnectionRouter from "@/views/ce-digital/sub-modules/connection/views/attendance/ce-connection.router";
import EmployeeClaimsRouter from "@/views/ce-digital/sub-modules/connection/views/employee-claims/router/employee-claims.router";
import RequestNcrRouter from "@/views/ce-digital/sub-modules/connection/views/request-ncr/request-ncr.router";
import RequestWorkPlanRouter from "@/views/ce-digital/sub-modules/connection/views/request-workplan/request-workplan.router.js";

import ConnectionMain from "@/views/ce-digital/sub-modules/connection/views/ConnectionMain.vue";
import RequestRouter from "@/views/ce-digital/sub-modules/connection/views/request/request.router.js";
//import ConnectionProjectsRouter from "@/views/ce-digital/sub-modules/connection/views/projects/connection-projects.router";
import CallLogRouter from "@/views/ce-digital/sub-modules/connection/views/ring-central/call-log/call-log.router.js";
import MOFRouter from "@/views/ce-digital/sub-modules/connection/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/ce-digital/sub-modules/connection/views/recruitment-process/connection-recruitment_process.router";
import ClaimsRouter from "@/views/ce-digital/sub-modules/connection/views/claims/claims.router.js";
import CourtInfoRouter from "@/views/ce-digital/sub-modules/connection/views/cour-info/router/ds-court-info.router";

import DashboardCalls from "@/views/ce-digital/sub-modules/connection/views/ring-central/dashboard-calls/dashboard.router.js";

import CustomerTicketsRouter from "@/views/ce-digital/sub-modules/connection/views/customer-tickets/connection-customer-tickets.router";
import AppointmentRouter from "@/views/ce-digital/sub-modules/connection/views/appointment/appointment.router";
import PotencialAppointmentRouter from "@/views/ce-digital/sub-modules/connection/views/potencial-appointment/potencial-appointment.route";
import OtherServicesNav from "@/views/ce-digital/sub-modules/connection/views/other-services/other-services.router";
import CommissionForSpecialistsRouter from "@/views/ce-digital/sub-modules/connection/views/commission-specialists/commissions-specialists.router";

const routes = [
  {
    path: "/cedigital/connection",
    redirect: { name: "connection-dashboard" },
    component: ConnectionMain,
    meta: {
      module: 20,
      parentModule: 21,
      childModule: 6,
      program: 3,
    },
    children: [
      DashboardRouter,
      ...ApplicationsRouter,
      ClientDashboardRouter,
      CommissionsRouter,
      TrackingListRouter,
      ...LeadsRouter,
      ...SalesMadeRouter,
      ...CalendarRouter,
      ...RequestNcrRouter,
      ...RequestWorkPlanRouter,
      HelpDeskRouter,
      ...ClientsRouter,
      ...NcrRealtorRouter,
      ...LettersRouter,
      ...DirectRouter,
      ...SuccessRouter,
      ...disputeResults,
      ...OtherPaymentsRouter,
      ...CeConnectionRouter,
      ...EmployeeClaimsRouter,
      ...RequestRouter,
      //...ConnectionProjectsRouter,
      ...CallLogRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...ClaimsRouter,
      ...CourtInfoRouter,
      ...DashboardCalls,
      ...CustomerTicketsRouter,
      ...AppointmentRouter,
      ...PotencialAppointmentRouter,
      ...OtherServicesNav,
      ...CommissionForSpecialistsRouter,
    ],
  },
];

export default routes;
