import Vue from "vue";
import store from "@/store";

export const newPurchaseOrder = () => {
  window.socket.bind(
    "new-purchase-order",
    async ({ correspondence_users, offer_code }) => {
      const user_id = store.state.auth.currentUser.user_id;
      const users = correspondence_users;
      const offerCode = offer_code;
      if (users.includes(user_id)) {
        Vue.swal.fire({
          icon: "info",
          title: "Purchase Order",
          html: `<div>
                        <h4> A purchase order has been created.</h2>
                        <small> OFFER CODE: ${offerCode} </small>
                       </div>`,
          showConfirmButton: true,
          confirmButtonText: "Accept",
        });
      }
    }
  );
};

export const checkRejectCeoNotification = () => {
  window.socket.bind("check-reject-ceo-notification", async ({ data }) => {
    const user_id = store.state.auth.currentUser.user_id;
    if(user_id == 1){
      await Vue.prototype.$amgAlert({
        title: "CHECKS",
        icon: "AlertCircleIcon",
        subtitle: "The check has been annulled for correspondence",
        data: [
          {
            icon: "UserIcon",
            title: "Annulled by",
            description: data.user_name
          },
          {
            icon: "CreditCardIcon",
            title: "Check number",
            description: data.check_number
          },
        ],
      });
    }
  });
};

export const changeCheckNumberNotification = () => {
  window.socket.bind("change-check-number-notification", async ({ data }) => {
    const user_id = store.state.auth.currentUser.user_id;
    if(user_id == 1){
      await Vue.prototype.$amgAlert({
        title: "CHECKS",
        icon: "AlertCircleIcon",
        subtitle: "The check number has been changed by correspondence",
        data: [
          {
            icon: "UserIcon",
            title: "Changed by",
            description: data.changed_by
          },
          {
            icon: "XIcon",
            title: "Old check number",
            description: data.old_check_number
          },
          {
            icon: "CheckIcon",
            title: "New check number",
            description: data.new_check_number
          },
          {
            icon: "UserIcon",
            title: "Requested by",
            description: data.requested_by
          },
          {
            icon: "DollarSignIcon",
            title: "Amount",
            description: `$${data.amount}`
          },
          {
            icon: "HomeIcon",
            title: "Vendor",
            description: data.vendor
          },
          {
            icon: "AlignJustifyIcon",
            title: "Memo",
            description: data.memo
          }
        ],
      });
    }
  });
};
