import Vue from "vue"

import store from "@/store"

const createScheduleTransfer = () => {
  window.socket.bind("notification-man-schedule-transfer", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id
    if (sessionId === data.to_id) {
      const htmlSwal =
        '<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;">' +
        '<div><h2 style="font-weight: 600;">NEW TRANSFER SCHEDULED</h2></div>' +
        '<div style="text-align: left;margin-left: 105px;">'

      const res = await Vue.swal.fire({
        html: htmlSwal,
        confirmButtonText: "Ok",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      })
      if (res) {
        window.open(
          `/management/companies/schedule-transfer/${data.company_id}`,
          "_blank"
        )
      }
    }
  })
}
export default createScheduleTransfer
