import store from "@/store";
import Vue from "vue";
import router from '@/router/index'

export const newRequestNcrNotification = () => {
  window.socket.bind(
    "new-ncr-notification",
    async ({
      title,
      clientData,
      specialistUsers,
      modules,
      loggedInUserId,
      roleUserId,
    }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];
      specialistUsers = filterUsers(
        specialistUsers,
        modules[0],
        loggedInUserId,
        roleUserId,
        clientData
      );

      if (
        specialistUsers.some((user) => user.id == user_id) &&
        current_module_id == modules[0] //  mncr current_module_id == (modules[0] == 11 ? 28 : modules[0])
      ) {
        store.commit("AutoAlertNcr/M_OPEN_ALERT", true);
        store.commit("AutoAlertNcr/M_DATA_NCR", clientData);
        store.commit("AutoAlertNcr/M_TITLE", title);
      }
    }
  );
};

export const annulledNcrRequestNotification = () => {
  window.socket.bind(
    "annulled-ncr-request-notification",
    async ({
      title,
      clientData,
      moduleUsers,
      modules,
      loggedInUserId,
      roleUserId
    }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];
      moduleUsers = filterUsers(
        moduleUsers,
        modules[0],
        loggedInUserId,
        roleUserId,
        clientData
      );
      if (
        moduleUsers.some((user) => user.id == user_id) &&
        current_module_id == modules[0] //  mncr current_module_id == (modules[0] == 11 ? 28 : modules[0])
      ) {
        store.commit("AutoAlertNcr/M_OPEN_ALERT", true);
        store.commit("AutoAlertNcr/M_DATA_NCR", clientData);
        store.commit("AutoAlertNcr/M_TITLE", title);
      }
    }
  );
};

export const attemptRequestNcrNotification = () => {
  window.socket.bind(
    "attempt-ncr-request-notification",
    async ({
      title,
      clientData,
      moduleUsers,
      modules,
      loggedInUserId,
      roleUserId,
    }) => {
      const { modul_id: current_module_id, user_id } =
        store.getters["auth/currentUser"];
      moduleUsers = filterUsers(
        moduleUsers,
        modules[0],
        loggedInUserId,
        roleUserId,
        clientData
      );
      if (
        moduleUsers.some((user) => user.id == user_id) &&
        current_module_id == modules[0] //  mncr current_module_id == (modules[0] == 11 ? 28 : modules[0])
      ) {
        store.commit("AutoAlertNcr/M_OPEN_ALERT", true);
        store.commit("AutoAlertNcr/M_DATA_NCR", clientData);
        store.commit("AutoAlertNcr/M_TITLE", title);
      }
    }
  );
};

function filterUsers(
  usersToSend,
  moduleToSend,
  loggedInUserId,
  roleIdUserSender,
  clientData
) {
  let result;

  // request specialists - alert to specialists
  if (moduleToSend == 29 && clientData.module_id == 29) {
    result =
      roleIdUserSender != 2
        ? usersToSend.filter((user) => user.role_id == 2)
        : usersToSend.filter((user) => user.id != loggedInUserId);
  }

  // request module - alert to specialists
  if (moduleToSend == 29 && clientData.module_id != 29){
    result = usersToSend
  }

  // alert to module - request module
  if ( moduleToSend != 29 ) {
    result = usersToSend.filter((user) => user.id == clientData.created_by_id)
  }

    //   console.log({
    //     usersToSend,
    //     moduleToSend,
    //     loggedInUserId,
    //     roleIdUserSender,
    //     clientData,
    //     result,
    // })

  return result;

}

export const CloseAllNcrNotification = () => {
  window.socket.bind("client-close-all-ncr-notification", async (data) => {
    const { user_id } = store.getters["auth/currentUser"];
    if (data.user_id == user_id) {
      store.commit("AutoAlertNcr/M_OPEN_ALERT", true);
      store.commit("AutoAlertNcr/M_DATA_NCR", null);
      store.commit("AutoAlertNcr/M_TITLE", null);
    }
  });
};

export const specialistNewItemEvent = () => {
  window.socket.bind("specialist-new-item-event", async (data) => {
    const { user_id } = store.getters["auth/currentUser"]
    if(data.users_id.includes(user_id)){
      Vue.prototype.$amgAlert({
        title: "New Items",
        subtitle: `The client ${data.client_name} has new items to dispute`,
        data: [
          { icon: "InfoIcon", title: "Client Name: ", description: data.client_name }
        ],
        okTitle: "Close",
        icon: "InfoIcon",
        ok: () => {

          // const resolved = router.resolve({
          //   name: 'specialists-client-dashboard',
          //   params: { idClient: data.client_account_id }
          // })
          // const url = resolved.href;
          // window.open(url, '_blank');
          
          window.amgApi.post("/commons/close-all-swal", {
            to_id: user_id,
          });
        },
      });
    }
  })
};
