import { LetterDirectCompletedSubTabs, LetterDirectToSendSubTabs } from "@/views/commons/components/letters-v2/router/dispute-letters-sub-tabs.router.js";
export default [
  {
    path: "dispute-letters",
    name: "cn-letters-dispute",
    redirect: { name: "cn-letters-dispute-to-send" },
    component: () =>
      import("@/views/commons/components/letters-v2/LettersMain.vue"),
    meta: {
      pageTitle: "Letter Dispute",
      parentModule: 21,
      module: 20,
      breadcrumb: [
        {
          text: "Letter Dispute",
          active: true,
        },
      ],
      routeToSend: "cn-letters-dispute-to-send",
      routeCompleted: "cn-letters-dispute-completed",
      routeDeleted: "cn-letters-dispute-deleted",
      routeExpired: "cn-letters-dispute-expired",
    },
    children: [
      {
        path: "to-send",
        name: "cn-letters-dispute-to-send",
        component: () =>
          import(
            "@/views/commons/components/letters-v2/LetterDisputeToSend.vue"
          ),
        redirect: { name: "cn-letters-dispute-to-send-pending" },
        meta: {
          pageTitle: "Letter Dispute",
          tabName: "completed",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
          routeToSendPending: "cn-letters-dispute-to-send-pending",
          routeToSendInQueue: "cn-letters-dispute-to-send-in-queue",
          routeToSendReturned: "cn-letters-dispute-to-send-returned",
        },
        children: LetterDirectToSendSubTabs("to-send", "cn"),
      },

      {
        path: "completed",
        name: "cn-letters-dispute-completed",
        component: () =>
          import(
            "@/views/commons/components/letters-v2/LetterDisputeCompleted.vue"
          ),
        redirect: { name: "cn-letters-dispute-completed-in-correspondence" },
        meta: {
          pageTitle: "Letter Dispute",
          tabName: "completed",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
          routeCompletedInCorrespondence:
            "cn-letters-dispute-completed-in-correspondence",
          routeCompletedSent: "cn-letters-dispute-completed-sent",
        },
        children: LetterDirectCompletedSubTabs("completed", "cn"),
      },
      {
        path: "deleted",
        name: "cn-letters-dispute-deleted",
        component: () =>
          import(
            "@/views/commons/components/letters-v2/components/LettersDisputeTable.vue"
          ),
        meta: {
          pageTitle: "Letter Dispute",
          tabName: "deleted",
          breadcrumb: [
            {
              text: "Deleted",
              active: true,
            },
          ],
        },
      },
      {
        path: "expired",
        name: "cn-letters-dispute-expired",
        component: () =>
          import(
            "@/views/commons/components/letters-v2/components/LettersDisputeTable.vue"
          ),
        meta: {
          pageTitle: "Letter Dispute",
          tabName: "expired",
          breadcrumb: [
            {
              text: "Expired",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
