/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class ServicesRequestTransactions {
  async getRequestTransactions(body) {
    try {
      const data = await amgApi.post('/management/charge-back/get-requests-to-transactions', body);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getPrograms() {
    try {
      const data = await amgApi.get('/management/settings/get-all-programs');
      return data;
    } catch (error) {
      throw error;
    }
  }

  async countPendingRequestOfTransaction() {
    try {
      const data = await amgApi.get('/management/charge-back/count-requests-pending-refund-void');
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllMerchants() {
    try {
      const data = await amgApi.get(`/commons/administration/get-all-merchants`)
      return data
    } catch (error) {
      console.log("Something went wrong on getAllMerchants:", error)
      throw error
    }
  }
}
export default new ServicesRequestTransactions();
