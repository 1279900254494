export default
  {
    path: "agent-away",
    name: "cs-agent-away",
    component: () => import("@/views/ce-digital/sub-modules/customer-service/views/agent-away/AgentAway.vue"),
    meta: {
      pageTitle: 'Agent Away',
      parentModule: 21,
      is_digital: 2,
      permittedRoles: [1, 17],
      breadcrumb: [
        {
          text: "Agent Away",
          active: true,
        },
      ],
    },
  }
