export default [
  {
    path: '/ui/feather',
    name: 'ui-feather',
    component: () => import(/* webpackChunkName: "FeatherIcons" */ '@/views/ui/feather/Feather.vue'),
    meta: {
      pageTitle: 'Feather',
      breadcrumb: [
        {
          text: 'UI',
        },
        {
          text: 'Feather',
          active: true,
        },
      ],
    },
  },
  {
    path: '/ui/tabler',
    name: 'ui-tabler',
    component: () => import(/* webpackChunkName: "TablerIcons" */ '@/views/ui/tabler/Tabler.vue'),
    meta: {
      pageTitle: 'Tabler',
      breadcrumb: [
        {
          text: 'UI',
        },
        {
          text: 'Tabler',
          active: true,
        },
      ],
    },
  },
]
