import Pusher from "pusher-js";
import {
  UpdateExpenseByCeo,
  SendExpenseToCeo,
} from "@/views/commons/expenses/Notification/department-expenses.socket.js";
const subscribeSocketExpenses = (socket) => {
  try {
    socket.subscribe("department-expenses");
    UpdateExpenseByCeo();
    SendExpenseToCeo();
  } catch (error) {
    console.error(error, "error");
  }
};

export default subscribeSocketExpenses;
