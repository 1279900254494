export default {
  namespaced: true,
  state: {
    S_ID_ALERT: null,
    S_ID_GENERATED: null,
    S_OPEN_ALERT: false,
    S_ALERT_TYPE: null,
    S_CLIENT_DATA: null,
    S_TIME_LAST_TOAST: null,
    S_SHOW_TOAST: false,
    S_ID_ALERT_ACCEPTED: null,
  },
  mutations: {
    ID_ALERT(state, params) {
      state.S_ID_ALERT = params;
    },
    ID_GENERATED(state, params) {
      state.S_ID_GENERATED = params;
    },
    OPEN_ALERT(state, params) {
      state.S_OPEN_ALERT = params;
    },
    OPEN_ALERT_WITH_ID(state, params) {
      state.S_OPEN_ALERT = params.bool;
      state.S_ID_ALERT = params.id_alert;
      state.S_CLIENT_DATA = params.client_data;
    },
    ALERT_TYPE(state, params) {
      state.S_ALERT_TYPE = params;
    },
    CLIENT_DATA(state, params) {
      state.S_CLIENT_DATA = params;
    },
    TIME_LAST_TOAST(state, params) {
      state.S_TIME_LAST_TOAST = params;
    },
    SHOW_TOAST(state, params) {
      state.S_SHOW_TOAST = params;
    },
    SET_ID_ALERT_ACCEPTED(state, params) {
      state.S_ID_ALERT_ACCEPTED = params[0].alert_id;
    },
  },
  actions: {},
  getters: {
    G_ID_ALERT(state) {
      return state.S_ID_ALERT;
    },
    G_ID_GENERATED(state) {
      return state.S_ID_GENERATED;
    },
    G_OPEN_ALERT(state) {
      return state.S_OPEN_ALERT;
    },
    G_ALERT_TYPE(state) {
      return state.S_ALERT_TYPE;
    },
    G_CLIENT_DATA(state) {
      return state.S_CLIENT_DATA;
    },
    G_TIME_LAST_TOAST(state) {
      return state.S_TIME_LAST_TOAST;
    },
    G_SHOW_TOAST(state) {
      return state.S_SHOW_TOAST;
    },
    G_ID_ALERT_ACCEPTED(state) {
      return state.S_ID_ALERT_ACCEPTED;
    },
  },
};
