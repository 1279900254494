import store from "@/store";
import Notifications from '@/service/notification/index';
import MigrationsService from "@/views/ce-digital/sub-modules/settings/views/migrations/services/migrations.service";
import SalesSellerServices from '@/views/ce-digital/sub-modules/settings/views/permissions/service/sales-seller.services'

const cDigitalMigrations = async () => {
  const params = {
    program: 3,
    client_type_id: 1,
    user_id: store.state.auth.currentUser.user_id,
  };
  const { data } = await MigrationsService.getClientPendingMigration(params);
  if (Object.keys(data).length > 0) {
    const payload = {
      routeName: "cdigital-migrations",
      tag: data[0].count,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
    store.dispatch("CeDigitalMigrations/A_SET_COUNTERS_TABS_MIGRATIONS", { pending: data[0].count});
  }
};
const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'settings-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const allCountersCeDigitalSettings = async () => {
  Promise.all([
    cDigitalMigrations(),
    projectsCounter(),
  ]);
};
export default allCountersCeDigitalSettings;
