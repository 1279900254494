export default {
    namespaced: true,
    state: {
      S_COUNTER_MIGRATIONS_PENDING: 0,
    },
    getters: {
      G_COUNTER_MIGRATIONS_PENDING: state => state.S_COUNTER_MIGRATIONS_PENDING
    },
    mutations: {
      M_SET_COUNTER_MIGRATIONS_TABS(state, payload = { pending: 0 }) {
        state.S_COUNTER_MIGRATIONS_PENDING = payload.pending
      },
    },
    actions: {
      A_SET_COUNTERS_TABS_MIGRATIONS({ commit }, payload = { pending: 0 }) {
        commit('M_SET_COUNTER_MIGRATIONS_TABS', payload)
      },
    },
}