<template>
  <div class="d-flex align-items-center">
    <div>
      <feather-icon
        class="mx-1 cursor-pointer"
        icon="RotateCcwIcon"
        v-if="sendNew && status === 'REJECTED'"
        @click="generateNew = true"
        v-b-tooltip.hover
        title="New Request"
      ></feather-icon>
    </div>
    <div :class="inRow ? 'd-flex mb-1' : ''">
      <div v-if="exists">
        <b-badge
          :variant="status === 'APPROVED' ? 'light-success' : 'light-danger'"
        >
          {{ code | limitChars(19)}} -
          {{ status }}
        </b-badge>
        BY:
      </div>
      <div v-if="exists && !attended_by" :class="inRow ? 'ml-1' : ''">
        <b-badge
          style="margin-right: 2px"
          variant="light-primary"
          v-for="(user, index) in usersAppr"
          v-bind:key="index"
        >
          {{ user.user }}
        </b-badge>
      </div>
      <div v-if="exists && attended_by" :class="inRow ? 'ml-1' : ''">
        <b-badge variant="light-primary">
          {{ attended_by }}
        </b-badge>
      </div>
    </div>
    <new-request-permission
      v-if="generateNew"
      :id-table="idTable"
      :type-permission-id="typePermissionId"
      @close="generateNew = false"
      @updatePermission="refreshVerifyStatusRequest"
    ></new-request-permission>
  </div>
</template>

<script>
import PermissionService from "@/views/commons/components/permissions/services/permission.service.js";
import NewRequestPermission from "@/views/commons/components/permissions/ModalSendRequestPermission.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    NewRequestPermission,
  },
  props: {
    typePermissionId: {
      type: Number,
      required: true,
    },
    idTable: {
      type: [Number, String],
      required: true,
    },

    sendNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    inRow: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      code: "",
      status: "NOT",
      exists: false,
      usersAppr: [],
      attended_by: null,
      generateNew: false,
    };
  },

  methods: {
    async getVerifyStatusRequest() {
      const params = {
        type_id: this.typePermissionId,
        id_table: this.idTable,
      };
      const { data } = await PermissionService.verifyStatusRequest(params);

      if (data.exists) {
        this.code = data.code;
        this.status = data.status;
        this.exists = data.exists;
        this.usersAppr = data.users;
        this.attended_by = data.attended_by;
      }

      this.$emit("state", {
        status: this.status,
        exists: this.exists,
        code: this.code,
      });
    },
    async refreshVerifyStatusRequest() {
      this.generateNew = false;
      this.getVerifyStatusRequest();
    },
  },

  mounted() {
    this.getVerifyStatusRequest();
  },
};
</script>

<style>
</style>