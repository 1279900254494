import { LetterRoundCompletedSubTabs } from "@/views/specialists/sub-modules/analyst-department/views/letter-round/router/letter-round-completed.router.js"
export default [
  {
    path: "letter-round",
    name: "analyst-department-letter-round",
    redirect: { name: "ad-letter-round-to-send" },
    component: () =>
      import(
        "@/views/specialists/sub-modules/analyst-department/views/letter-round/LetterRoundMain.vue"
      ),
    meta: {
      pageTitle: "Letter Dispute",
      parentModule: 11,
      module: 28,
      breadcrumb: [
        {
          text: "Letter Dispute",
          active: true,
        }
      ],
      routePending: "ad-letter-round-pending",
      routeToSend: "ad-letter-round-to-send",
      routeCompleted: "ad-letter-round-completed",
      routeDeleted: "ad-letter-round-deleted",
      routeExpired: "ad-letter-round-expired",
    },
    children: [
      {
        path: "pending",
        name: "ad-letter-round-pending",
        component: () =>
          import(
            "@/views/specialists/sub-modules/analyst-department/views/letter-round/views/components/LetterRoundTable.vue"
          ),
        meta: {
          pageTitle: "Letter Dispute",
          tabName: "pending",
          breadcrumb: [
            {
              text: "Pending",
              active: true,
            }
          ]
        }
      },
      {
        path: "to-send",
        name: "ad-letter-round-to-send",
        component: () =>
          import(
            "@/views/specialists/sub-modules/analyst-department/views/letter-round/views/components/LetterRoundTable.vue"
          ),
        meta: {
          pageTitle: "Letter Dispute",
          tabName: "To Send",
          breadcrumb: [
            {
              text: "To Send",
              active: true,
            }
          ]
        }
      },
      {
        path: "completed",
        name: "ad-letter-round-completed",
        component: () =>
          import(
            "@/views/specialists/sub-modules/analyst-department/views/letter-round/LetterRoundCompleted.vue"
          ),
        redirect: { name: "ad-letter-round-completed-in-correspondence" },
        meta: {
          pageTitle: "Letter Dispute",
          tabName: "completed",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            }
          ],
          routeCompletedInCorrespondence: "ad-letter-round-completed-in-correspondence",
          routeCompletedSent: "ad-letter-round-to-send-completed-sent",
        },
        children: LetterRoundCompletedSubTabs('completed'),
      },
      {
        path: "deleted",
        name: "ad-letter-round-deleted",
        component: () =>
          import(
            "@/views/specialists/sub-modules/analyst-department/views/letter-round/views/components/LetterRoundTable.vue"
          ),
        meta: {
          pageTitle: "Letter Dispute",
          tabName: "deleted",
          breadcrumb: [
            {
              text: "Deleted",
              active: true,
            }
          ]
        }
      },
      {
        path: "expired",
        name: "ad-letter-round-expired",
        component: () =>
          import(
            "@/views/specialists/sub-modules/analyst-department/views/letter-round/views/components/LetterRoundTable.vue"
          ),
        meta: {
          pageTitle: "Letter Dispute",
          tabName: "expired",
          breadcrumb: [
            {
              text: "Expired",
              active: true,
            }
          ]
        }
      },
    ]
  },
]
