<template>
  <div class="d-flex align-items-center justify-content-center">
    <span
      :class="timerClass"
      :style="{ fontSize: `${size}px` }"
      v-b-tooltip.hover.left="
        special == 1
          ? 'This ticket may take longer than 48 hours to be resolved'
          : ''
      "
    >
      {{ time == "NaN:NaN:NaN" ? "Loading ..." : textTimer }}
    </span>
    <feather-icon
      v-if="special == 1"
      icon="AlertTriangleIcon"
      class="text-danger"
      style="margin-left: 5px; margin-bottom: 5px"
      :size="size.toString()"
    />
  </div>
</template>

<script>
import moment from "moment"
export default {
  props: {
    created_at: {
      type: String | null,
      required: true,
    },
    due_date: {
      type: String | null,
      required: true,
    },
    size: {
      type: Number,
      default: 14,
    },
    special: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      time: 0,
      interval: null,
      isExpired: false,
    }
  },
  created() {
    this.startCountDown()
  },
  beforeDestroy() {
    this.stopCountDown()
  },
  computed: {
    textTimer() {
      return this.time
    },
    timerClass() {
      return {
        "timer-text-success": !this.isExpired,
        "timer-text-danger": this.isExpired,
        "timer-text-special": this.special == 1 && this.isExpired,
      }
    },
  },
  methods: {
    startCountDown() {
      this.updateCountDown()
      this.interval = setInterval(() => {
        this.updateCountDown()
      }, 1000)
    },
    stopCountDown() {
      clearInterval(this.interval)
    },
    updateCountDown() {
      let dueDate = moment(this.due_date)
      let now = moment()

      if (now.isAfter(dueDate)) {
        this.isExpired = true
        this.time = `Expired ${now.diff(dueDate, "days")} days ago`
        this.stopCountDown()
      } else {
        this.isExpired = false
        let diffDuration = moment.duration(dueDate.diff(now))
        let diffHours = Math.floor(diffDuration.asHours())
        let diffMinutes = Math.floor(diffDuration.asMinutes()) - diffHours * 60
        let diffSeconds =
          Math.floor(diffDuration.asSeconds()) -
          diffHours * 3600 -
          diffMinutes * 60
        this.time = `${String(diffHours).padStart(2, "0")}:${String(
          diffMinutes
        ).padStart(2, "0")}:${String(diffSeconds).padStart(2, "0")}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.timer-text-success {
  color: #00d25b !important;
  font-weight: 700;
}

.timer-text-danger {
  color: #ff0000 !important;
  font-weight: 700;
}
</style>