export default [
  {
    path: "tracking-list",
    name: "boost-credit-tracking",
    redirect: { name: "boost-credit-tracking-list" },
    component: () =>
      import(
        /* webpackChunkName: "BCtrackingListMain" */ "@/views/commons/components/tracking-list/views/components/TrackingListTab.vue"
      ),
    meta: {
      pageTitle: "Tracking List",
      permittedRoles: [1, 2, 3],
      breadcrumb: [
        {
          text: "Tracking List",
        },
      ],
    },
    children: [
      {
        path: "in-course",
        component: () =>
          import(
            /* webpackChunkName: "BCTrackingListInCourse" */ "@/views/commons/components/tracking-list/views/components/TrackingInCourseGrid.vue"
          ),
        name: "boost-credit-tracking-list",
        meta: {
          status: 1,
          pageTitle: "Tracking List",
          permittedRoles: [1, 2, 3],
          breadcrumb: [
            {
              text: "In course",
              active: true,
            },
          ],
        },
      },
      {
        path: "completed",
        component: () =>
          import(
            /* webpackChunkName: "BCTrackingListCompleted" */ "@/views/commons/components/tracking-list/views/components/TrackingCompletedGrid.vue"
          ),
        name: "boost-credit-tracking-list-completed",
        meta: {
          status: 2,
          permittedRoles: [1, 2, 3],
          pageTitle: "Tracking List",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
        },
      },
      {
        path: "pending",
        component: () =>
          import(
            /* webpackChunkName: "BCTrackingListPending" */ "@/views/commons/components/tracking-list/views/components/TrackingPending.vue"
          ),
        name: "boost-credit-tracking-list-pending",
        meta: {
          status: 3,
          permittedRoles: [1, 2, 3],
          pageTitle: "Tracking List",
          breadcrumb: [
            {
              text: "Pending",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
