import store from '@/store'
import Vue from 'vue'

const alertChiefsAboutCommissionDecision = () => {
  window.socket.bind('alert-chiefs-about-commission-decision', async data => {
    let temps = false
    const sessionId = store.state.auth.currentUser.user_id
    if (data.to_id === sessionId) {
      temps = true
    }
    if (temps) {
      const htmlAlert = `${`${'<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;" alt="alert-info-image">'
                + `<div><h2 style="font-weight: 600;">COMMISSION REQUEST CHANGE WAS ${data.status === 2 ? 'APPROVED' : 'DENIED'} </h2></div>`
                + '<div style="text-align: center;">'
                + '<div style="font-weight: bolder;">Evaluated By: '}${
        data.user_name
      } </div>`
                + ' </div>'
                + '<div style="font-weight: bolder; margin-bottom: 0.5rem; margin-top: 0.5rem;">Type Commission: '}${
        data.type_commission_description.toLowerCase().replace(/\b\w/g, l => l.toUpperCase())
      } </div>`
                + `<div>Old Value: ${
                  data.old_type_commission === 'AMOUNT' ? '$ ' : ''
                }${data.old_value
                }${data.old_type_commission === 'PERCENTAGE' ? ' %' : ''
                }</div>`
                + `<div>New Value: ${
                  data.type_data_commission === 1 ? '$ ' : ''
                }${data.new_value
                }${data.type_data_commission === 2 ? ' %' : ''
                }</div>`
                + '</div>'
      const res = await Vue.swal.fire({
        html: htmlAlert,
        confirmButtonText: 'Ok',
        allowOutsideClick: false,
        showConfirmButton: true,
      })
      if (res.value) {
        await window.amgApi.post('/commons/close-all-swal', data)
      }
    }
  })
}
export default alertChiefsAboutCommissionDecision
