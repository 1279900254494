<template>
  <div>
    <b-card class="mb-1">
      <span class="d-block text-uppercase text-primary fw-700">
        <strong>TIME OF SOLUTION</strong>
      </span>
      <b-card-text ref="cardText">
        <ul class="step-wizard-list" ref="wizarList">
          <template v-for="(item, index) in Solutions">
            <li class="step-wizard-item" :key="index">
              <span
                class="progress-count current_progress"
                :id="`span-detail-${index + 1}`"
                >1
              </span>
              <span
                class="progress-label"
                :class="!isLightSkin ? 'text-light' : ''"
              >
                <strong class="d-block">{{ item.level_name }}</strong>
                <div style="position: absolute; right: -46px; top: -7px">
                  <b-badge
                    :variant="badgeColor(item.claim_status)"
                    class="time_badge"
                  >
                    {{
                      item.claim_status === "COMPLETED"
                        ? "SOLVED"
                        : item.claim_status
                    }}
                  </b-badge>
                </div>
                <small class="d-block text-primary">{{
                  item.created_at | myGlobalDay
                }}</small>
              </span>
              <Tooltip to=".modal-details" :target="`span-detail-${index + 1}`">
                <template #body>
                  <b-row>
                    <b-col>
                      <b-form-group label="Solutions">
                        <div class="d-flex flex-wrap">
                          <div
                            v-for="(item1, index1) in item.solutions"
                            :key="index1"
                            class="d-flex"
                            style="margin: 5px"
                          >
                            <b-badge
                              v-if="item1.id != null"
                              variant="light-success"
                              class="px-1"
                              >{{ item1.name }}</b-badge
                            >
                            <b-badge v-else variant="light-danger"
                              >NO SOLUTION</b-badge
                            >
                          </div>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Description">
                        <b-form-textarea
                          v-model="item.description"
                          disabled
                          rows="4"
                          no-resize
                        >
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </template>
              </Tooltip>
            </li>
          </template>
          <li
            class="step-wizard-item"
            :class="!(solved || annulled) ? 'level_now' : 'current-item'"
          >
            <span
              class="progress-count"
              :class="
                !(solved || annulled)
                  ? 'current_progress'
                  : annulled
                  ? 'size_2 bg_red'
                  : 'size_2'
              "
            >
              <feather-icon
                :icon="annulled ? 'XIcon' : solved ? 'CheckIcon' : 'ClockIcon'"
                size="22"
                class="text-white"
                id="tooltip-1"
                style="z-index: 9999"
              />
            </span>
            <span
              class="progress-label"
              :class="!isLightSkin ? 'text-light' : ''"
              v-if="!(solved || annulled)"
            >
              <strong class="d-block fw-bold">
                Level {{ selectedClaim.level_id - 1 }}
              </strong>
            </span>
            <span
              v-else
              class="progress-label"
              :class="!isLightSkin ? 'text-light' : ''"
              >{{ selectedClaim.status }}</span
            >
          </li>
        </ul>
      </b-card-text>
    </b-card>
    <!-- tooltip -->
  </div>
</template>
<script>
export default {
  name: "StepLevel",
  props: {
    dataSolutionsForClaim: {
      type: Array,
      required: true,
      default: () => [],
    },
    onlyStep: {
      type: Boolean,
      default: false,
    },
    selectedClaim: {
      type: Object,
      required: false,
      default: () => undefined,
    },
  },
  data() {
    return {
      dataSolutions: [],
      description: "",
      showModalSolutions: false,
      tooltip: null,
      showTooltip: false,
      currentSolution: {
        show: false,
        index: 0,
      },
    };
  },
  computed: {
    solved() {
      let total = this.dataSolutionsForClaim.filter(
        (x) => x.claim_status === "COMPLETED"
      );
      return total.length > 0 ? true : false;
    },
    annulled() {
      let total = this.dataSolutionsForClaim.filter(
        (x) => x.claim_status === "ANNULLED"
      );
      return total.length > 0 ? true : false;
    },
    level_claim() {
      let num = this.dataSolutionsForClaim.length - 1;
      let current_level = this.dataSolutionsForClaim[num].claim_current_level;
      return current_level;
    },
    Solutions() {
      let dataSolutions = [];
      this.dataSolutionsForClaim.forEach((item) => {
        dataSolutions.push({ ...item, solutions: JSON.parse(item.solutions) });
      });
      return dataSolutions;
    },
  },
  watch: {},
  methods: {
    badgeColor(badge) {
      let badgeClass = {
        PENDING: "light-warning",
        SCALED: "light-primary",
        REACTIVATED: "light-info",
        COMPLETED: "light-success",
        ANNULLED: "light-danger",
      };
      return badgeClass[badge];
    },
    resetTooltip() {
      // this.tooltip = null;
      // this.showTooltip = false;
    },
    prueba() {
      // alert("entro al")
    },
    veriifyScroll() {
      const container = this.$refs.wizarList;
      const isScrollVisible = container.scrollWidth > container.clientWidth;
      return isScrollVisible;
    },
    createdElement() {
      const divPadre = document.createElement("div");
      divPadre.setAttribute("class", "pruebas");
    },
  },
  created() {},
};
</script>
<style scoped>
.step-wizard-list {
  color: #333;
  list-style-type: none;
  display: flex;
  padding: 26px 0 25px 0;
  position: relative;
  z-index: 1000;
  overflow-x: auto;
}

.step-wizard-item {
  padding: 0 15px;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 170px;
  position: relative;
}
.step-wizard-item span {
  text-align: center;
}

.step-wizard-item + .step-wizard-item:after {
  content: "";
  position: absolute;
  left: 0;
  top: 19px;
  background: #ababab;
  width: 100%;
  height: 3px;
  transform: translateX(-50%);
  z-index: -10;
}

.progress-count.current_progress {
  cursor: pointer;
}

.progress-count {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}

.progress-count:after {
  content: "";
  height: 25px;
  width: 25px;
  background: #ababab;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: 1200;
}

.progress-count:before {
  content: "";
  height: 7px;
  width: 13px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%) rotate(-45deg);
  transform-origin: center center;
  z-index: 5500;
}

.progress-label {
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
}

.current-item .progress-count:before,
.current-item ~ .step-wizard-item .progress-count:before {
  content: "";
  height: 7px;
  width: 13px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  background: #00d25b;
  /*border-radius: 100%;*/
  transform: translate(-50%, -50%) rotate(-45deg);
}

.current-item .progress-count:after,
.current-item ~ .step-wizard-item .progress-count:after {
  background: #00d25b;
}

.text-wrap {
  word-wrap: break-word;
}

.level_now .progress-count:after {
  background: #005ce7 !important;
  height: 40px;
  width: 40px;
}

.level_now .progress-count:before {
  display: none;
}

.time_badge {
  width: 92px;
  margin-bottom: 5px;
}

.size_2:after {
  height: 40px;
  width: 40px;
}

.size_2:before {
  display: none;
}

.bg_red:after {
  background: #f00 !important;
}

.pruebas {
  padding: 20px;
  width: 100px;
  height: 20px;
  background: red;
}
</style>