export default [
  {
    path: '/cedigital/customerservice/commissions',
    name: 'cedigital-cs-commissions',
    component: () => import('@/views/ce-digital/sub-modules/sales/views/commissions/Commissions.vue'),
    meta: {
      module: 6,
      program: 3,
      pageTitle: 'Commissions',
      breadcrumb: [
        {
          text: 'Commissions',
        },
      ],
    },
  },
]
