var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filter,"filter-principal":_vm.filterMain,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.refreshTable()}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refDerivationsTable",attrs:{"small":"","show-empty":"","responsive":"sm","fields":_vm.fields,"items":_vm.myProvider,"busy":_vm.isBusy,"per-page":_vm.paginate.perPage,"current-page":_vm.paginate.currentPage},on:{"update:busy":function($event){_vm.isBusy=$event}},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(lead_client_name)",fn:function(data){return [_c('router-link',{class:[_vm.textLink],attrs:{"target":"_blank","to":{
              name: 'sales-lead-show',
              params: {
                id: data.item.lead_id,
              },
            }}},[_c('span',[_vm._v(_vm._s(data.item.lead_client_name))])])]}},{key:"cell(derived_from)",fn:function(data){return [_c('div',[_c('b-badge',{attrs:{"variant":data.item.derived_from === 'CUSTOMER SERVICE DIGITAL' ? 'success' :
                data.item.derived_from === 'FINANCIAL' ? 'primary' :
                data.item.derived_from === 'SALES' ? 'warning' :
                data.item.derived_from === 'CUSTOMER SERVICE REGULAR' ? 'info' : ''}},[_vm._v(" "+_vm._s(data.item.derived_from))])],1)]}},{key:"cell(sale_process_status)",fn:function(data){return [(data.item.sale_process_status !== null)?_c('b-badge',{attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(data.item.sale_process_status)+" ")]):_vm._e()]}},{key:"cell(catcher_by)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.catcher_name)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(data.item.created_at)))])]}},{key:"cell(seller_by)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.seller_name)+" "),_c('br'),_vm._v(" "+_vm._s(_vm._f("myGlobalWithHour")(data.item.updated_at)))])]}},{key:"cell(action)",fn:function(data){return [_c('b-badge',{class:data.item.seller_name === '' ? 'cursor-pointer' : null,attrs:{"variant":data.item.seller_name === '' ? 'warning' : 'success'},on:{"click":function($event){data.item.seller_name === '' ? _vm.openAssignSellerModal(data.item) : null}}},[(data.item.seller_name === '')?_c('span',[_vm._v("Assign seller")]):_vm._e(),(data.item.seller_name !== '')?_c('span',[_vm._v("Assigned")]):_vm._e()])]}}])})]},proxy:true}])}),(_vm.assignSellerModal)?_c('assign-seller-modal',{attrs:{"derivation-data":_vm.derivedData},on:{"refresh":_vm.refreshTable,"closeModal":_vm.closeAssignSellerModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }