<template>
  <b-modal
    v-model="onControl"
    :title="`${leadName ? 'Tracking for ' + leadName : 'Tracking'}`"
    title-tag="h3"
    modal-class="modal-primary"
    @hidden="close"
    size="lg"
    scrollable
    hide-footer
  >
    <b-table
      id="tracking-list"
      ref="trackingList"
      no-border-collapse
      class="position-relative"
      :fields="fields"
      show-empty
      no-provider-filtering
      sticky-header="50vh"
      primary-key="id"
      responsive="sm"
      :items="trackingData"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <template #cell(filename)="data">
        <a :href="data.item.url" target="_blank">{{ data.item.file_name }}</a>
      </template>
      <template #cell(status)="{ item }">
        <b-badge :variant="statusTracking(item.status, item.dispute)">
          <span v-if="item.status == 'responsable'">
            {{ `${item.status} : ${item.responsable}`.toUpperCase() }}
          </span>
          <span v-else-if="item.status == 'dispute'">
            {{ `${item.status} : ${item.dispute}`.toUpperCase() }}
          </span>
          <span v-else>
            {{ item.status.toUpperCase() }}
          </span>
        </b-badge>
      </template>

      <template #cell(departament)="data">
        {{ data.item.departament }}
      </template>

      <template #cell(observation)="data">
        <span
          :class="data.item.observation == 'No Description' && 'text-danger'"
        >
          {{
            data.item.observation == "No Description"
              ? ""
              : data.item.observation
          }}
        </span>
      </template>

      <template #cell(createdBy)="data">
        <span class="text-center">
          {{ data.item.user_name }} <br />
          {{ data.item.created_at | myGlobalWithHour }}
        </span>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import ChargeBackService from "@/views/commons/components/charge-back_v2/service/charge.back.service"
import {
  chargeBackTrackingFields,
  disputeTrackingFields,
} from "@/views/commons/components/charge-back_v2/data/fields.modal.charge.back.tracking.js"

export default {
  props: {
    chargeBackId: null,
    fileId: null,
    leadName: null,
  },
  data() {
    return {
      onControl: false,
      trackingData: [],
      fields: [],
      isBusy: false,
    }
  },
  async created() {
    this.onControl = true
    this.fields =
      typeof this.fileId == "undefined"
        ? chargeBackTrackingFields
        : disputeTrackingFields
    await this.getTrackingData()
  },
  methods: {
    close() {
      this.$emit("close")
    },
    async getTrackingData() {
      this.isBusy = true
      const data =
        typeof this.fileId == "undefined"
          ? await ChargeBackService.getTrackingChargeBack({
              idchargeback: this.chargeBackId,
            })
          : await ChargeBackService.getFileTracking({
              file_id: this.fileId,
            })

      this.trackingData = data.data || []
      this.isBusy = false
    },
    statusTracking(status, dispute) {
      let text = ""

      switch (status) {
        case "in process":
        case "rejected":
          text = "light-danger"
          break
        case "responsable":
        case "approved":
          text = "light-success"
          break
        case "pending":
          text = "light-warning"
          break
        case "pay penalty":
        case "pay chargeback":
          text = "light-primary"
          break
        default:
          if (dispute == "pending") text = "light-warning"
          else if (dispute == "observed") text = "light-danger"
          else text = "light-success"
          break
      }

      return text
    },
  },
}
</script>
