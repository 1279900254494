<template>
  <b-form-group label-class="font-weight-bolder font-small-5">
    <b-alert variant="transparent" show>
      <div
        class="alert-body alert-body text-dark border-dark rounded text-start"
      >
        <strong>{{ labelTitle }} | {{ title }}</strong>
      </div>
    </b-alert>
  </b-form-group>
</template>

<script>
export default {
  name: "TitlesModal",
  props: {
    title: {
      type: String,
      default: "",
    },
    labelTitle: {
      type: String,
      default: "Title",
    },
  },
};
</script>

<style >
</style>
