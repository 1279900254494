<template>
  <b-card class="card-lead-tasks" footer-class="border-0">
    <b-card-title>
      <h4
        class="font-weight-bolder pb-1 border-bottom-secondary border-2 title-task"
      >
        Tasks
      </h4>
    </b-card-title>

    <b-row>
      <b-col cols="6" align="left">
        <b-button-group>
          <b-button
            variant="transparent"
            @click="changeDate(-1)"
            :disabled="minYear == year"
            class="custom-filter-date"
          >
            <span>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </span>
          </b-button>
          <b-button
            variant="transparent"
            class="custom-filter-date pointer-events-none font-weight-bolder"
          >
            <span>{{ year }}</span>
          </b-button>
          <b-button
            variant="transparent"
            @click="changeDate(1)"
            :disabled="maxYear == year"
            class="custom-filter-date"
          >
            <span>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </span>
          </b-button>
        </b-button-group>
      </b-col>
      <b-col cols="6" align="right">
        <b-dropdown variant="transparent" class="custom-task-dropdown">
          <template #button-content>
            <span
              >{{ currentTypeTask.text }}
              <b-badge
                v-if="currentTypeTask.quantity > 0"
                class="ml-1 rounded-circle text-black"
                :class="variantTaks[currentTypeTask.id]"
                >{{ currentTypeTask.quantity }}</b-badge
              >
            </span>
          </template>
          <b-dropdown-item
            v-for="(item, index) in optionsTypeTask"
            :key="index"
            @click="selectedTask(item.id)"
          >
            <div class="d-flex-justify-content-between align-items-center">
              <span style="color: #b4b4b4">{{ item.text }}</span>
              <b-badge
                v-if="item.quantity > 0"
                class="ml-1 rounded-circle text-black"
                :class="variantTaks[item.id]"
                >{{ item.quantity }}</b-badge
              >
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <b-list-group
      class="task-list diego-scrollbar"
      :class="{
        'custom-scrollbar-dark': isDarkSkin,
        'custom-scrollbar': !isDarkSkin,
      }"
    >
      <template v-if="haveTasks">
        <b-list-group-item
          v-for="(item, index) in lead.lead_tasks"
          :key="index"
          class="task-item"
          :class="{
            'border-success': !!item.done && !item.favorite,
            'border-warning': !!item.favorite,
            'border-secondary:': !item.done,
          }"
        >
          <b-row>
            <b-col
              cols="6"
              class="d-flex justify-content-start align-items-center"
            >
              <feather-icon
                @click="!item.done ? onDoneTask(item.id) : null"
                :icon="!item.done ? 'CircleIcon' : 'CheckCircleIcon'"
                size="15"
                class="mr-1"
                :class="{
                  'text-success': !!item.done,
                  'text-secondary cursor-pointer': !item.done,
                }"
                v-b-tooltip.hover.left="
                  !!item.done ? 'COMPLETED' : 'TO COMPLETED'
                "
              />
              Subject: {{ item.subject }}
            </b-col>
            <b-col
              cols="2"
              class="d-flex justify-content-center align-items-center font-weight-bolder"
            >
              {{ item.real_time | formatRealTime }}
            </b-col>
            <b-col
              cols="2"
              class="d-flex justify-content-center align-items-center"
            >
              <b-icon
                :icon="item.favorite ? 'star-fill' : 'star'"
                style="transform: scale(1.3) !important"
                class="text-warning cursor-pointer"
                @click="onMakeFavoriteTask(item.id, item.favorite)"
                v-b-tooltip.hover.left="
                  !!item.favorite ? 'FAVORITE' : 'TO FAVORITE'
                "
              />
            </b-col>

            <b-col
              v-if="!onlyRead"
              cols="1"
              class="d-flex justify-content-center align-items-center"
            >
              <b-button
                @click="onModalEditTaskOpen(item.id, false)"
                v-b-tooltip.hover.left="'Edit Task'"
                variant="bg-transparent"
                size="sm"
              >
                <feather-icon icon="EditIcon" size="15" class="text-warning" />
              </b-button>
              <b-button
                @click="onModalEditTaskOpen(item.id, true)"
                v-b-tooltip.hover.left="'View Task'"
                variant="bg-transparent"
                size="sm"
              >
                <feather-icon icon="EyeIcon" size="15" class="text-info" />
              </b-button>
              <b-button
                @click="onDeleteTask(item.id)"
                v-b-tooltip.hover.left="'Delete Task'"
                variant="bg-transparent"
                size="sm"
              >
                <feather-icon icon="Trash2Icon" size="15" class="text-danger" />
              </b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </template>
      <div
        v-else
        class="w-100 d-flex justify-content-center align-items-center position-relative rounded-lg"
        :class="isDarkSkin ? 'bg-dark' : null"
      >
        <img
          :src="require('@/assets/images/icons/empty-notes.svg')"
          class="my-0"
          style="opacity: 0.2"
          width="15%"
          height="100%"
        />
        <span v-if="!isLoading" class="custom-span"
          >No
          {{ currentTypeTask.id === 0 ? "Tasks" : currentTypeTask.text }}</span
        >
        <b-spinner
          class="custom-spinner"
          v-else
          variant="primary"
          label="Spinning"
        ></b-spinner>
      </div>
    </b-list-group>

    <template v-if="!onlyRead" #footer>
      <div class="text-right">
        <b-button
          class="add-task-button"
          :disabled="isResponsible"
          @click="onModalCreateTaskOpen"
        >
          <feather-icon icon="PlusIcon" class="mr-1"></feather-icon>
          <span class="align-middle">New Task </span>
        </b-button>
      </div>
    </template>

    <!-- modal TASK CREATE -->
    <b-modal
      id="modal-task-create"
      ok-only
      title-class="h2 text-white"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Tasks"
      hide-footer
      no-close-on-backdrop
    >
      <modal-task-create
        :only-read="onlyRead"
        :lead="lead"
        :type="type"
        @onReloadTasks="onReloadTasks"
      />
    </b-modal>

    <!-- modal TASK EDIT -->
    <b-modal
      id="modal-task-edit"
      title-class="h2 text-white"
      ok-only
      modal-class="modal-primary"
      centered
      size="lg"
      title="Tasks"
      hide-footer
      no-close-on-backdrop
    >
      <modal-task-edit
        :only-read="onlyRead"
        :lead="lead"
        :task="task"
        :is-disabled="isTaskDisabled"
        @onReloadTasks="onReloadTasks"
      />
    </b-modal>

    <!-- modal TASK HISTORY -->
    <b-modal
      id="modal-task-history"
      title-class="h3 text-white"
      modal-class="modal-primary"
      centered
      size="lg"
      :title="`All Tasks - ${nameModule}`"
      hide-footer
    >
      <modal-task-history :lead="lead" />
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Ripple from "vue-ripple-directive";

import ModalTaskCreate from "../../lead-task/ModalTaskCreate.vue";
import ModalTaskEdit from "../../lead-task/ModalTaskEdit.vue";
import ModalTaskHistory from "../../lead-task/ModalTaskHistory.vue";
import moment from "moment";
import TasksServices from "@/service/task/index.js";

export default {
  components: {
    ModalTaskCreate,
    ModalTaskEdit,
    ModalTaskHistory,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_TASKS_LEADS: "TaskStore/G_TASKS_LEADS",
    }),
    nameModule() {
      if (this.taskForSn) {
        return "SOCIAL NETWORK";
      }
      return "CRM";
    },
    isResponsible() {
      return !(
        this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
          .is_responsible === this.currentUser.user_id ||
        this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
          .is_responsible === null
      );
    },
    currentTypeTask() {
      return this.optionsTypeTask.find((item) => !!item.selected);
    },
    haveTasks() {
      return !!this.lead.lead_tasks && this.lead.lead_tasks.length > 0;
    },
  },
  created() {
    this.getUserAppointments();
    this.getCountersTask();
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fieldsTask: [
        { key: "subject", tdClass: "py-1" },
        { key: "date", label: "Date/Hour", tdClass: "py-1" },
        { key: "sms", tdClass: "py-1" },
        { key: "Actions", tdClass: "py-1" },
      ],
      isLoading: false,
      isTaskDisabled: false,
      task: {},
      taskForSn: 0,
      type: 0,
      // showDsCourtInfoTasks: false,
      minYear: 2020,
      maxYear: parseInt(moment().format("YYYY")) + 1,
      year: parseInt(moment().format("YYYY")),
      variantTaks: ["bg-info", "bg-warning", "bg-success", "bg-secondary"],
      optionsTypeTask: [
        {
          id: 0,
          text: "All tasks",
          quantity: 0,
          selected: false,
        },
        {
          id: 1,
          text: "Outstanding Tasks",
          quantity: 0,
          selected: false,
        },
        {
          id: 2,
          text: "Completed Tasks",
          quantity: 0,
          selected: false,
        },
        {
          id: 3,
          text: "Pending Tasks",
          quantity: 0,
          selected: true,
        },
      ],
      typeTasks: 0,
    };
  },
  methods: {
    ...mapActions({
      A_GET_USER_APPOINTMENT_SN: "CrmLeadStore/A_GET_USER_APPOINTMENT_SN",
      A_DONE_LEAD_TASK: "TaskStore/A_DONE_LEAD_TASK",
      A_GET_TASKS: "TaskStore/A_GET_TASKS",
      A_GET_TASK: "TaskStore/A_GET_TASK",
      A_DELETE_LEAD_TASK: "TaskStore/A_DELETE_LEAD_TASK",
      A_MAKE_FAVORITE_LEAD_TASK: "TaskStore/A_MAKE_FAVORITE_LEAD_TASK",
      A_GET_TASK_COUNTER: "TaskStore/A_GET_TASK_COUNTER",
      A_GET_TASKS_LEAD: "TaskStore/A_GET_TASKS_LEAD",
    }),
    async getUserAppointments() {
      try {
        await this.A_GET_USER_APPOINTMENT_SN({
          date: "date_task",
          from: "from_task",
          to: "to_task",
          taskForSn: this.taskForSn,
        });
      } catch (error) {
        console.log("Something went wrong getUserAppointments", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    onModalCreateTaskOpen() {
      this.type = 1;
      this.addPreloader();
      this.$bvModal.show("modal-task-create");
    },
    async onReloadTasks(tasks) {
      this.year = parseInt(moment().format("YYYY"));
      this.lead.lead_tasks = tasks;
      await this.getCountersTask();
    },
    onDoneTask(id) {
      this.isLoading = true;
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            const response = await this.A_DONE_LEAD_TASK({
              id,
              user_id: this.currentUser.user_id,
              lead_id: this.lead.id,
              taskForSn: 0,
            });
            if (this.isResponseSuccess(response)) {
              this.A_GET_TASK_COUNTER({ id: this.currentUser.user_id });
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
              this.lead.lead_tasks = response.data;
              this.lead.count_task = response.data.count_tasks;
            }
          }
          this.isLoading = false;
          await this.getCountersTask();
        })
        .catch((error) => {
          console.log("Something went wrong onDoneTask", error);
          this.showErrorSwal(error);
          this.isLoading = false;
        });
    },
    async onModalEditTaskOpen(id, isDisabled) {
      try {
        this.addPreloader();
        this.isLoading = true;
        const response = await this.A_GET_TASK({ id });
        if (this.isResponseSuccess(response)) {
          this.A_GET_TASK_COUNTER({ id: this.currentUser.user_id });
          this.task = response.data[0];
          this.isTaskDisabled = isDisabled;
          this.$bvModal.show("modal-task-edit");
        } else {
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            `Something went wrong. ${response.message}`
          );
        }
        this.isLoading = false;
        await this.getCountersTask();
      } catch (error) {
        console.log("Something went wrong onGetTask", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
        this.isLoading = false;
      }
    },
    onDeleteTask(id) {
      this.isLoading = true;
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            const response = await this.A_DELETE_LEAD_TASK({
              id,
              user_id: this.currentUser.user_id,
              lead_id: this.lead.id,
              taskForSn: 0,
            });
            if (this.isResponseSuccess(response)) {
              this.A_GET_TASK_COUNTER({ id: this.currentUser.user_id });
              await this.A_GET_TASKS({ id: this.currentUser.user_id });
              const index = this.lead.lead_tasks.map((el) => el.id).indexOf(id);
              if (index !== -1) this.lead.lead_tasks.splice(index, 1);
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Deleted successful"
              );
            } else {
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                `Something went wrong. ${response.message}`
              );
            }
          }
          this.isLoading = false;
          await this.getCountersTask();
        })
        .catch((error) => {
          console.log("Something went wrong onDeleteTask", error);
          this.showErrorSwal();
          this.isLoading = false;
        });
    },
    onMakeFavoriteTask(id, favorite) {
      this.isLoading = true;
      this.showConfirmSwal("Are you sure?", "Favorite Task")
        .then(async (result) => {
          if (result.value) {
            const response = await this.A_MAKE_FAVORITE_LEAD_TASK({
              id,
              favorite,
              user_id: this.currentUser.user_id,
              lead_id: this.lead.id,
              taskForSn: 0,
            });
            if (this.isResponseSuccess(response)) {
              this.lead.lead_tasks = response.data;
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Favorite successful"
              );
            } else {
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                `Something went wrong. ${response.message}`
              );
            }
          }
          this.isLoading = false;
          await this.getCountersTask();
        })
        .catch((error) => {
          console.log("Something went wrong onMakeFavoriteTask", error);
          this.showErrorSwal();
          this.isLoading = false;
        });
    },

    // openDsCourtInfoTasks() {
    //   this.showDsCourtInfoTasks = true;
    // },
    selectedTask(taskId) {
      this.optionsTypeTask.forEach(
        (element) => (element.selected = element.id == taskId)
      );
      this.typeTasks = taskId;
    },
    changeDate(value) {
      const newValue = this.year + value;
      if (newValue < this.minYear || newValue > this.maxYear) return;
      this.year = newValue;
    },
    async getCountersTask() {
      await this.A_GET_TASKS_LEAD({ leadId: this.lead.id, year: this.year });
      const { all, pending, favorites, done } = this.G_TASKS_LEADS;

      this.optionsTypeTask = [
        {
          id: 0,
          text: "All tasks",
          quantity: all,
          selected: this.typeTasks == 0,
        },
        {
          id: 1,
          text: "Outstanding Tasks",
          quantity: favorites,
          selected: this.typeTasks == 1,
        },
        {
          id: 2,
          text: "Completed Tasks",
          quantity: done,
          selected: this.typeTasks == 2,
        },
        {
          id: 3,
          text: "Pending Tasks",
          quantity: pending,
          selected: this.typeTasks == 3,
        },
      ];
    },
    async getTasksLead() {
      try {
        this.isLoading = true;
        const params = {
          year: this.year,
          typeTask: this.typeTasks,
          leadId: this.lead.id,
        };
        const data = await TasksServices.getTasksLead(params);
        this.lead.lead_tasks = data;
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {},
  props: {
    onlyRead: {
      type: Boolean,
      required: true,
    },
    lead: {
      type: Object,
      required: true,
    },
  },
  setup() {},
  filters: {
    formatRealTime(value) {
      const formatValue = moment(moment(value).format("YYYY-MM-DD"));
      const now = moment(moment().format("YYYY-MM-DD"));
      const diff = formatValue.diff(now, "days");

      switch (diff) {
        case -3:
          value = "3 days ago";
          break;
        case -2:
          value = "2 days ago";
          break;
        case -1:
          value = "Yesterday";
          break;
        case 0:
          value = "Today";
          break;
        case 1:
          value = "Tomorrow";
          break;
        default:
          value = moment(value).format("MMMM DD, YYYY");
          break;
      }

      return value;
    },
  },
  watch: {
    async year() {
      await this.getTasksLead();
      await this.getCountersTask();
    },
    async typeTasks() {
      await this.getTasksLead();
    },
  },
};
</script>
<style scoped>
.custom-primary {
  background-color: #e0f2fc !important;
  color: black;
}
.custom-primary-dark {
  background-color: #142633 !important;
  color: white !important;
}
.font-size-md {
  font-size: 14px !important;
}
.custom-span {
  position: absolute !important;
  font-size: 25px !important;
  font-weight: bolder !important;
  color: dark !important;
}
.custom-scrollbar::-webkit-scrollbar,
.custom-scrollbar-dark::-webkit-scrollbar {
  width: 5px !important;
  margin-left: 10px !important;
}
.custom-scrollbar::-webkit-scrollbar-thumb,
.custom-scrollbar-dark::-webkit-scrollbar-thumb {
  background-color: #3a72ea !important;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #dbe7ff !important;
}
.custom-scrollbar-dark::-webkit-scrollbar-track {
  background-color: #3f7afa !important;
}
.custom-spinner {
  position: absolute !important;
}
</style>
<style lang="scss" scoped>
.card-lead-tasks {
  .my-05 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .title-task {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #111113;
  }
  .task-list {
    max-height: 13vh !important;
    min-height: 13vh !important;
    overflow: auto !important;
    padding-right: 10px;
    margin-top: 10px;
  }
  .task-item {
    border-width: 2px !important;
    border-radius: 5px !important;
    padding: 0.5rem 1rem !important;
    margin-bottom: 1rem !important;
    background: transparent !important;
  }
  .custom-filter-date {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    background-color: #efefef;
    color: #5e5873;
    text-decoration: underline;
    border: none !important;
  }
  .custom-task-dropdown {
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    background-color: #efefef;
    color: #111113;
    border: none !important;
  }
  .add-task-button {
    background-color: #3f7afa !important;
    color: #111113 !important;
    border-color: #3f7afa !important;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
  }
}
.dark-layout {
  .title-task {
    color: #fafafa;
  }
  .custom-filter-date {
    background-color: #131323;
    color: #b4b4b4;
  }
  .custom-task-dropdown {
    color: #b4b4b4;
    background-color: #131323;
  }
}
</style>