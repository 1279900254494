import Pusher from "pusher-js"
import {
  DerivedToSupervisorCrmNotification,
  DerivedToSupervisorDebtSolutionNotification,
} from "@/views/crm/crm.socket.js"
import { NotificationSaleMade } from "@/views/management/management.socket.js"

const subscribeSocketCrm = (socket) => {
  try {
    socket.subscribe("crm-notifications")
    DerivedToSupervisorCrmNotification()
    DerivedToSupervisorDebtSolutionNotification()
    NotificationSaleMade()
  } catch (error) {
    console.error(error, "error")
  }
}

export default subscribeSocketCrm
