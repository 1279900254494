export default [
  {
    path: "clients",
    name: "management-clients",
    redirect: { name: "management-clients-tab" },
    component: () =>
      import(
        /* webpackChunkName: "managementClients" */ "@/views/management/views/clients/Clients.vue"
      ),
    meta: {
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "management-clients-tab",
        redirect: { name: "management-clients-main" },
        component: () =>
          import(
            /* webpackChunkName: "managementClientsTab" */ "@/views/management/views/clients/components/ClientsTab.vue"
          ),
        meta: {
          type: "clients",
          tab: 1,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Settings",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "", //url
            name: "management-clients-main", //name
            component: () =>
              import(
                /* webpackChunkName: "managementClientsMain" */ "@/views/management/views/clients/components/ClientsGridTable.vue"
              ),
            meta: {
              type: "clients",
              tab: 1,
              pageTitle: "Clients",
              list_table: "Main",
              breadcrumb: [
                {
                  text: "Main",
                  active: true,
                },
              ],
            },
          },
          {
            path: "archived", //url
            name: "management-clients-archived", //name
            component: () =>
              import(
                /* webpackChunkName: "managementClientsArchived" */ "@/views/management/views/clients/components/ClientsGridTable.vue"
              ),
            meta: {
              type: "clients",
              tab: 1,
              pageTitle: "Clients",
              list_table: "Archived",
              breadcrumb: [
                {
                  text: "Archived",
                  active: true,
                },
              ],
            },
          },
          {
            path: "calls", //url
            name: "management-clients-calls", //name
            //redirect: { name: "charge_active" },
            component: () =>
              import(
                /* webpackChunkName: "managementClientsCalls" */ "@/views/management/views/clients/components/ClientsGridTable.vue"
              ),
            meta: {
              type: "clients",
              list_table: "CallsRound",
              tab: 1,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Calls Round",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "shared",
        name: "management-shared-tab",
        component: () =>
          import(
            /* webpackChunkName: "managementClientsShared" */ "@/views/commons/components/clients/components/Shared.vue"
          ),
        meta: {
          tab: 2,
          pageTitle: "Clients",
          program: 5,
          breadcrumb: [
            {
              text: "Shared",
              active: true,
            },
          ],
          route: "bussiness",
        },
      },
    ],
  },
  {
    path: "clients/account/:idClient",
    name: "management-client-dashboard",
    redirect: { name: "personal-information-management" },
    component: () =>
      import(
        /* webpackChunkName: "managementClientsAccountDashboard" */ "@/views/management/views/clients/dashboard/AccountManagement.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay",
        name: "pay-management",
        component: () =>
          import(
            /* webpackChunkName: "managementClientsAccountDashboardPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "task-notes",
        name: "task-notes-management",
        component: () =>
          import(
            /* webpackChunkName: "managementClientsAccountDashboardTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information",
        name: "personal-information-management",
        component: () =>
          import(
            /* webpackChunkName: "managementClientsAccountDashboardPersonalInformation" */ "@/views/management/views/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files",
        name: "client-files-management",
        component: () =>
          import(
            /* webpackChunkName: "managementClientsAccountDashboardClientFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr",
        name: "acr-management",
        component: () =>
          import(
            /* webpackChunkName: "managementClientsAccountDashboardAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "dis",
        name: "dis-management",
        redirect: { name: "report-detail-man" },
        component: () =>
          import(
            /* webpackChunkName: "managementClientsAccountDashboardDis" */ "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Dispute",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "dispute-detail",
            name: "dispute-detail-man",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/DisputeDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Disputes",
                  active: true,
                },
              ],
            },
          },
          {
            path: "report-detail",
            name: "report-detail-man",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Credit Report",
                  active: true,
                },
              ],
            },
          },
          {
            path: "updates-detail",
            name: "updates-detail-man",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/UpdateDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Updates",
                  active: true,
                },
              ],
            },
          },
          {
            path: "workplan-detail",
            name: "workplan-detail-man",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Workplan",
                  active: true,
                },
              ],
            },
          },
          {
            path: "recommendation-detail",
            name: "recommendation-detail-man",
            component: () =>
              import(
                /* webpackChunkName: "ManClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/services/services.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Recommendations",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "acc",
        name: "acc-management",
        component: () =>
          import(
            /* webpackChunkName: "QuaClientAccountAcc" */ "@/views/commons/components/clients/dashboard/options/acc-module/Acc.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acc",
              active: true,
            },
          ],
        },
      },
      {
        path: "report",
        name: "report-management",
        component: () =>
          import(
            /* webpackChunkName: "managementClientsAccountDashboardReport" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Report",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log",
        name: "call-log-client-management",
        redirect: { name: "call-log-conversation-management" },
        component: () =>
          import(
            "@/views/commons/components/ring-central/call-log/tabs/ClientTabs.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
          callConversationRoute: "call-log-conversation-management",
          callNoConversationRoute: "call-log-not-conversation-management",
        },

        children: [
          {
            path: "conversation",
            name: "call-log-conversation-management",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Conversation",
                  active: true,
                },
              ],
              isConversation: 1,
            },
          },
          {
            path: "no-conversation",
            name: "call-log-not-conversation-management",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "No Conversation",
                  active: true,
                },
              ],
              isConversation: 0,
            },
          },
        ],
      },
    ],
  },
];
