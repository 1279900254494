import { amgApi } from "@/service/axios";

class CorrespondenceServices {

  async getCorrespondenceChecks(params) {
    try {
        return await amgApi.post("commons/check-expenses/index", params);
    } catch (error) {
        throw error;
    }
  }

  async changeCheckStatus(params) {
    try {
        return await amgApi.post("commons/check-expenses/change-status", params);
    } catch (error) {
        throw error;
    }
  }

  async saveRejectedCheck(params) {
    try {
        return await amgApi.post("commons/check-expenses/save-rejected-check", params);
    } catch (error) {
        throw error;
    }
  }

  async changeCheckNumber(params) {
    try {
        return await amgApi.post("commons/check-expenses/change-check-number", params);
    } catch (error) {
        throw error;
    }
  }

  async getCounterCheck(params) {
    try {
        return await amgApi.post("commons/check-expenses/get-counter-check", params);
    } catch (error) {
        throw error;
    }
  }

  async annulCheck(params) {
    try {
        return await amgApi.post("/ceo/dashboard/expenses/department/request/check/annul-check", params);
    } catch (error) {
        throw error;
    }
  }

  async saveTracking(params) {
    try {
        return await amgApi.post("commons/check-expenses/save-tracking", params);
    } catch (error) {
        throw error;
    }
  }
}

export default new CorrespondenceServices();
