<template>
  <div>
    <b-modal
      v-model="showModal"
      size="smd"
      centered
      header-bg-variant="transparent"
      hide-footer
      @hidden="closeModal"
    >
      <template #modal-title>
        <h5 class="text-uppercase font-weight-bolder" style="padding-top: 5px">
          <feather-icon
            icon="ClipboardIcon"
            size="15"
            style="margin-right: 10px"
          />
          Ticket customer Tasks
        </h5>
      </template>
      <b-container fluid class="py-1">
        <b-row>
          <b-col cols="12" lg="6">
            <header
              class="d-flex w-100 justify-content-start align-items-center mb-1 pl-1"
            >
              <strong style="font-size: 16px" class="mr-2"> Month: </strong>
              <div
                class="d-flex justify-content-center align-items-center rounded"
                :class="`border-${
                  variantCalendar ? variantCalendar : 'primary'
                }`"
              >
                <b-button
                  :variant="variantCalendar"
                  class="p-0"
                  style="
                    padding: 5px !important;
                    border-radius: 3px 0px 0px 3px !important;
                  "
                  size="sm"
                  @click="nextMonth(-1)"
                >
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="17"
                    class="hover-me"
                  />
                </b-button>
                <span
                  class="text-center"
                  style="font-size: 13px; padding: 3px 15px; min-width: 150px"
                  >{{ monthAndYear }}</span
                >
                <b-button
                  :variant="variantCalendar"
                  class="p-0"
                  style="
                    padding: 5px !important;
                    border-radius: 0px 3px 3px 0px !important;
                  "
                  size="sm"
                  @click="nextMonth(1)"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="17"
                    class="hover-me"
                  />
                </b-button>
              </div>
            </header>
            <body-calendar-component
              v-if="!loadCalendar"
              :key="keyCalendar"
              class="mt-1"
              :month="month"
              :year="year"
              :hours-availables="dataTask"
              :variant="variantCalendar"
              @selectedDays="selectedDays"
            />
            <b-skeleton-table
              v-else
              :rows="4"
              :columns="5"
              :table-props="{ bordered: true, striped: true }"
            />
          </b-col>
          <b-col cols="12" lg="6">
            <div
              class="d-flex justify-content-between align-items-center align-items-center w-100 mt-2 mt-lg-0"
              style="padding: 3px 0px; width: 100% !important"
            >
              <h5 class="font-weight-bolder text-uppercase d-inline-flex m-0">
                List Tasks
              </h5>
              <span class="d-inline-flex">CURRENT DATE: {{ currentDate }}</span>
            </div>
            <b-list-group style="max-height: 320px; overflow-y: auto">
              <template v-if="selectedTask.length > 0">
                <b-list-group-item
                  v-for="(item, index) in selectedTask"
                  :key="index"
                  class="bg-transparent rounded border"
                  style="margin: 5px 0px"
                  :class="`${item.status_id == 1 ? 'border-warning' : ''} ${
                    isDarkSkin ? 'text-white' : 'text-dark'
                  }`"
                  :style="
                    item.status_id == 2
                      ? 'background-color: #58D68D !important;'
                      : null
                  "
                >
                  <!------------------------>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <strong
                      v-b-tooltip.hover.title="'View in the Grid'"
                      style="font-size: 14px"
                      class="cursor-pointer"
                      :class="
                        item.status_id === 1 ? 'text-primary' : 'text-warning'
                      "
                      @click="redirect(item)"
                    >
                      <feather-icon
                        :icon="item.type == 'expenses' ? 'MapIcon' : 'UserIcon'"
                        size="15"
                        class="m-0"
                        style="margin-right: 3px"
                      />
                      {{
                        item.type == "expenses"
                          ? item.code
                          : item.name_client
                      }}
                      <feather-icon
                        icon="ArrowUpRightIcon"
                        size="17"
                        class="m-0 hover-me"
                        style="margin-left: 3px"
                      />
                    </strong>
                    <small v-if="item.phone">
                      <feather-icon
                        icon="PhoneIcon"
                        size="13"
                        style="margin-right: 3px"
                      />
                      {{ item.phone }}
                    </small>
                  </div>
                  <!------------------------>
                  <span>
                    <feather-icon
                      v-if="item.account"
                      icon="BriefcaseIcon"
                      size="13"
                    />
                    {{ item.account }}
                  </span>
                  <!------------------------>
                  <div
                    class="d-flex justify-content-between align-items-center"
                    style="margin-top: 3px"
                  >
                    <strong style="font-size: 13px">{{ item.title }}</strong>
                    <strong
                      v-if="item.hour_task"
                      style="font-size: 14px"
                      class="text-info"
                    >
                      <feather-icon icon="ClockIcon" size="13" />
                      {{ item.hour_task | myFullTime }}
                    </strong>
                  </div>
                  <!------------------------>
                  <p class="w-100 m-0 p-0" style="line-height: 13px">
                    {{
                      item.description.length > limitDescription
                        ? item.description.substring(0, limitDescription)
                        : item.description
                    }}
                    <b
                      v-if="item.description.length > limitDescription"
                      class="text-primary cursor-pointer"
                      @click="showDescription(item)"
                      >...show more</b
                    >
                  </p>
                  <div
                    class="d-flex justify-content-end align-items-center"
                    style="margin-top: 10px"
                  >
                    <template v-if="item.status_id === 1">
                      <feather-icon
                        v-b-tooltip.hover.v-success
                        v-b-tooltip.hover.bottom
                        title="COMPLETE"
                        icon="CheckCircleIcon"
                        size="18"
                        class="text-success hover-me cursor-pointer"
                        style="margin-right: 8px"
                        @click="updateTask(item, 2)"
                      />
                      <feather-icon
                        v-b-tooltip.hover.v-primary
                        v-b-tooltip.hover.bottom
                        title="EDIT"
                        icon="Edit2Icon"
                        size="18"
                        class="text-primary hover-me cursor-pointer"
                        style="margin-right: 8px"
                        @click="updateTask(item, 1)"
                      />
                      <feather-icon
                        v-b-tooltip.hover.v-danger
                        v-b-tooltip.hover.bottom
                        title="DELETE"
                        icon="XCircleIcon"
                        size="18"
                        class="text-danger hover-me cursor-pointer"
                        style="margin-right: 8px"
                        @click="cancelTask(item.id)"
                      />
                    </template>
                    <b-badge
                      v-else
                      variant="light"
                      class="bg-white text-black"
                      style="font-size: 12px; margin-top: 8px"
                    >
                      {{ item.status }}
                    </b-badge>
                  </div>
                </b-list-group-item>
              </template>
              <b-list-group-item
                v-else
                style="height: 320px; border: 0px solid white"
                class="d-flex justify-content-center align-items-center bg-transparent"
                :class="loadCalendar ? 'text-primary' : 'text-light'"
              >
                <div class="d-block text-center">
                  <feather-icon icon="ClipboardIcon" size="60" /><br />
                  <strong
                    v-if="!loadCalendar"
                    class="mt-3 d-flex"
                    style="font-size: 20px"
                    >SELECTED A DAY TO DISPLAY THE TASKS</strong
                  >
                  <strong v-else class="mt-3 d-flex" style="font-size: 20px"
                    >LOADING ...</strong
                  >
                </div>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <!--MODAL DESCRIPTION-->
    <modal-description
      v-if="paramsModalDescription.description"
      :task="paramsModalDescription"
      @closeModal="paramsModalDescription = {}"
    />
    <!--MODAL TASK-->
    <modal-task-component
      v-if="showModalTask"
      :ticket-customer="paramsModalTask"
      :task-id="taskId"
      @closeModal="closeModalTask"
      @successTask="successTask"
    />
  </div>
</template>
<script>
import moment from "moment";
import BodyCalendarComponent from "@/views/commons/components/customer-tickets/components/modal/meetings/BodyCalendar.vue";
import CustomerTicketsServices from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import ModalDescription from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalDescription.vue";
import { mapGetters, mapActions } from "vuex";
import ModalTaskComponent from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalTask.vue";
import { getModuleText } from "@/utils/convertModule.js";
export default {
  components: {
    BodyCalendarComponent,
    ModalDescription,
    ModalTaskComponent,
  },
  data() {
    return {
      showModal: false,
      month: parseInt(moment().format("MM"), 10),
      year: parseInt(moment().format("YYYY"), 10),
      day: null,
      dataTask: [],
      keyCalendar: 0,
      selectedTask: [],
      limitDescription: 60,
      paramsModalDescription: {},
      classStatus: ["border-warning", "bg-success"],
      loadCalendar: false,
      loadTasks: false,
      showModalTask: false,
      paramsModalTask: {},
      taskId: 0,
      ticketCustomer: {
        id: 0,
        code: null,
      },
      variantCalendar: "info",
      moduleText: "",
    };
  },
  computed: {
    monthAndYear() {
      return moment(`${this.month}/${this.year}`, "MM/YYYY").format(
        "MMMM YYYY"
      );
    },
    currentDate() {
      return this.day
        ? moment(`${this.year}-${this.month}-${this.day}`).format("MM-DD-YYYY")
        : "-";
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async mounted() {
    await this.getTasks();
    this.convertModule();
    this.showModal = true;
  },
  methods: {
    ...mapActions({
      A_TASKS_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/A_TASKS_PENDING_CUSTOMER_TICKETS",
    }),
    async nextMonth(value) {
      const newDate = moment(`${this.year}-${this.month}`, "YYYY-MM").add(
        value,
        "month"
      );
      this.year = parseInt(newDate.format("YYYY"), 10);
      this.month = parseInt(newDate.format("MM"), 10);
      this.day = null;
      await this.getTasks();
    },
    closeModal() {
      this.$emit("closeModal");
    },
    selectedDays(days) {
      if (days.length > 0) {
        this.day = days[0].day;
        this.selectedTask = JSON.parse(days[0].tasks);
        return;
      }
      this.day = null;
      this.selectedTask = [];
    },
    async getTasks() {
      try {
        this.loadCalendar = true;
        this.selectedTask = [];
        const params = {
          month: this.month,
          year: this.year,
          user_id: this.currentUser.user_id,
        };
        const { data } =
          await CustomerTicketsServices.getTicketCustomerTaskCalendar(params);
        this.dataTask = data.map((item) => ({
          ...item,
          notification: item.pending_task,
          date: item.date_task,
          day: parseInt(moment(item.date_task).format("DD"), 10),
          highlight: !!item.tasks,
          disabled: !item.tasks,
          selected:
            this.day === parseInt(moment(item.date_task).format("DD"), 10) &&
            !!item.tasks,
        }));
        if (this.day) {
          this.selectedTask = this.dataTask[this.day - 1].tasks
            ? JSON.parse(this.dataTask[this.day - 1].tasks)
            : [];
        }
        this.loadCalendar = false;
        this.keyCalendar += 1;
      } catch (error) {
        this.showErrorSwal();
        console.log("Error in getTasks: ", error);
      }
      return [];
    },
    showDescription({ title, description }) {
      this.paramsModalDescription = {
        title,
        description,
      };
    },
    async cancelTask(taskId) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;

        this.addPreloader();
        const params = {
          taskId,
          deletedBy: this.currentUser.user_id,
        };
        const { status } =
          await CustomerTicketsServices.deletedTicketCustomerTask(params);
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal();
          await this.getTasks();
          await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
            user_id: this.currentUser.user_id,
          });
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log("error in cancelTask:", error);
      }
    },
    async updateTask({ id, code, ticket_code }, status) {
      // change status to completed
      if (status === 2) {
        try {
          const { isConfirmed } = await this.showConfirmSwal();
          if (!isConfirmed) return;

          this.addPreloader();
          const params = {
            taskId: id,
            maxDate: null,
            title: null,
            description: null,
            createdBy: this.currentUser.user_id,
            status,
            allDay: null,
          };

          const { status: statusResult } =
            await CustomerTicketsServices.updateTicketCustomerTask(params);
          this.removePreloader();

          if (statusResult === 200) {
            this.showSuccessSwal();
            await this.getTasks();
            await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
              user_id: this.currentUser.user_id,
            });
          }
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal();
          console.log("Error update task:", error);
        }
      } else {
        // edit information of task
        this.showModalTask = true;
        this.paramsModalTask = {
          id: ticket_code,
          code,
        };
        this.taskId = id;
      }
    },
    closeModalTask() {
      this.showModalTask = false;
      this.paramsModalTask = {};
    },
    async successTask() {
      this.showModalTask = false;
      this.paramsModalTask = {};
      await this.getTasks();
      await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
        user_id: this.currentUser.user_id,
      });
    },
    redirect({
      code: code_ticket,
      created_by_ticket,
      module_id,
      id_status,
      type,
    }) {
      const routeParams = {
        code: code_ticket,
        searchByCode: true,
        module: module_id,
      };

      let routeName = "";
      let typeTicket = "";
      if (type == "payments") {
        typeTicket = "payment";
      } else if (type == "expenses") {
        typeTicket = "expense";
      } else {
        typeTicket = type;
      }
      // Establecer el nombre de la ruta según las condiciones
      if (id_status === 5 || id_status === 6) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-in-progress`;
      } else if (id_status === 4) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-rejected`;
      } else if (id_status === 3) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-in-progress`;
      } else if (id_status === 2 || (created_by === 1 && id_status === 1)) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-new`;
      } else if (id_status === 7) {
        routeName = `${typeTicket}-ticket-${this.moduleText}-not-solved`;
      }
      console.log(routeName);
      this.$store.dispatch("TicketCustomerStore/A_SET_CODE", code_ticket);
      // Realizar la navegación utilizando 'replace' en lugar de 'push'
      if (routeName) {
        this.$nextTick(() => {
          this.$router
            .replace({
              name: routeName,
              params: routeParams,
            })
            .catch((error) => {
              // Manejar específicamente la excepción NavigationDuplicated
              if (
                error.name === "NavigationDuplicated" &&
                error.message.includes(
                  "Avoided redundant navigation to current location"
                )
              ) {
                if (
                  routeName ===
                  `${typeTicket}-ticket-${this.moduleText}-in-progress`
                ) {
                  this.$root.$emit("redirect-to-module", routeParams);
                }
                // Puedes omitir la excepción ya que se trata de una navegación redundante
              } else {
                // Lanzar otras excepciones
                throw error;
              }
            });
        });
      }
      // Emitir un evento personalizado con la variable
      this.$emit("closeModal");
    },

    convertModule() {
      this.moduleText = getModuleText(this.moduleId);
    },
  },
};
</script>
