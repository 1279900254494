export default {
  path: "helpdesk",
  name: "helpdesk-boostcredit",
  component: () =>
    import(/* webpackChunkName: "BCHelpdesk" */ "./Helpdesk.vue"),
  meta: {
    pageTitle: "Help Desk",
    breadcrumb: [
      {
        text: "Help Desk",
        active: true,
      },
    ],
    onlyChief: true,
  },
}
