<template>
  <b-modal
    scrollable
    v-model="onControl"
    size="xlg"
    :title="titleModal"
    no-close-on-backdrop
    title-tag="h3"
    modal-class="custom-modal-amg"
    body-class="overflow-visible"
    @hidden="isNewAppointmentDS == true ? hiddeOnly() : close()"
    title-class="h1 text-white fw-bold"
    no-close-on-esc
    no-enforce-focus
    hide-footer
  >
    <template #modal-title>
      <tabler-icon icon="ReplaceIcon" class="text-white" size="22" />
      <!-- Debt solution program sales process -->
      {{titleModal}}
    </template>
    <modal-services-d-s
      ref="modalServicesDS"
      :id_event="id_event"
      :state="stateModal"
      :client_id="client.client_id"
      :ncrRequestId="ncrRequestId"
      :idEventAccountApproved="idEventAccountApproved"
      :analyzedBySoft="analyzedBySoft"
      :fromDashboardLead="fromDashboardLead"
      @lengthTable="lengthTab"
      @getDataProgram="getProgram"
      @stateCreditor="getstateCreditor"
      @refreshCounter="$emit('refreshCounter')"
      @close="close"
    />
    <!--   :hide-footer="!isModuleCRM || stateModal == 0 || hideFooter"  -->
    <!-- <template #modal-footer="">
      <div
        v-if="stateModal !== '2'"
        class="w-100"
        style="display: flex; justify-content: end; align-items: center"
      >
        <b-row class="w-100 pt-1 pb-1 pr-0-1 pl-0">
          <b-col cols="3" class="p-0">
            <field-approve-supervisor
              v-if="lengthTable != 0"
              pt-1
              :moduleId="2"
              :isRequired="!passwordIsCorrectAndCreditorLength && !directRoles"
              :isModalAddThis="true"
              @okPassword="statePassword"
              class="stateCreditor p-0"
            />
          </b-col>
          <b-col class="d-flex justify-content-end" cols="9">
            <div
              class="d-flex justify-content-center"
              v-if="!hideNextButtons || stateCreditor == 1"
            >
              <span class="text-primary mr-1 mt-1 h4">Was the sale made?</span>
              <div id="btnYes" class="p-0 mr-2">
                <b-button
                  class="h-100"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  :disabled="creditorsIsNotNull || programPayment == 0"
                  @click="finish()"
                >
                  <feather-icon icon="CheckIcon" class="mr-50" />
                  <span>Yes</span>
                </b-button>
              </div>
              <b-tooltip
                v-if="creditorsIsNotNull || programPayment == 0"
                disabled-triggers="never"
                target="btnYes"
                triggers="hover"
                placement="top"
              >
                <b-row
                  class="d-flex w-100 justify-content-center mx-0 mt-1 mb-1"
                >
                  <feather-icon
                    size="40"
                    icon="InfoIcon"
                    class="text-primary"
                  />
                </b-row>
                <small v-if="creditorsIsNotNull">
                  Please,
                  <strong class="text-primary"> add creditors </strong> to the
                  list to continue the sale.
                </small>
                <small v-else>
                  Please, you must complete the
                  <strong class="text-primary">
                    Program Payment, Retainer fee and Settlement cost
                  </strong>
                  before proceeding with the sale
                </small>
              </b-tooltip>
              <b-button
                @click="close()"
                id="not"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="danger"
                type="button"
                class="mr-1"
              >
                <feather-icon icon="TrendingDownIcon" class="mr-50" />
                <span>Not</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </template> -->
  </b-modal>
</template>

<script>
// import ModalServicesDS from "@/views/crm/views/Lead/lead-module/dashboard/modal/ModalServicesDS.vue";
import ModalServicesDS from "@/views/crm/views/Lead/lead-module/dashboard/modal/ModalProcessSaleDebtSolution.vue";
import DebtSolutionService from "@/views/debt-solution/services/debt-solution.service.js";
import ModalFinancialAnalysisOptions from "@/views/crm/views/Lead/lead-module/dashboard/modal/ModalFinancialAnalysisOptions.vue";
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import FieldApproveSupervisor from "@/views/commons/utilities/FieldApproveSupervisor.vue";

export default {
  components: {
    DebtSolutionService,
    ModalServicesDS,
    ModalFinancialAnalysisOptions,
    FieldApproveSupervisor,
  },
  directives: {
    Ripple,
  },
  props: {
    fromDashboardLead: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNewAppointmentDS: {
      type: Boolean,
      default: false,
    },
    stateModal: null,
    id_event: null,
    title: null,
    id_lead: null,
    rejectedAppointments: {
      type: Boolean,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    ncrRequestId: {
      default: null,
    },
    idEventAccountApproved: {
      type: Number,
      default: null,
    },
    analyzedBySoft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onControl: false,
      lengthTable: 0,
      // Modal Financial Analysis Options
      showAnalysisOptions: false,
      programPayment: 0,
      stateCreditor: 0,
      okPassword: 0,
    };
  },
  async created() {
    this.onControl = true;
  },
  methods: {
    getstateCreditor(value) {
      this.stateCreditor = value;
    },
    statePassword(value) {
      this.okPassword = value;
    },
    hiddeOnly() {
      this.$emit("hideOnly");
    },
    async listCreditorsToEvent() {
      await DebtSolutionService.listCreditorsToEvent({
        leadId: this.id_lead,
        eventId: this.id_event,
        disableEdit: false,
      });
    },
    async close() {
      if (this.rejectedAppointments || this.hideFooter) {
        this.$emit("close");
      } else {
        const result = await this.showConfirmSwal();

        if (result.isConfirmed) {
          this.addPreloader();
          try {
            let data = {
              id_event: this.id_event,
              lead_pending_appointment: 1,
            };
            if (this.idEventAccountApproved != null) {
              await this.listCreditorsToEvent();
            }

            this.showSuccessSwal(
              "Successfully!",
              "This sale has been sent to the Rejected DS Sales list at Sales Made section."
            );
            this.$emit("close", data);
          } catch (error) {
            this.showErrorSwal(error);
          } finally {
            this.removePreloader();
          }
        } else {
          this.onControl = true;
        }
      }
    },
    async emitReloadAmountSaved(emitData = null) {
      this.showAnalysisOptions = false;
      this.$emit("reloadAmountSaved", emitData);
    },
    async finish() {
      const result = await this.showQuestionSwal(
        "Do you want to continue with the sale?"
      );

      if (result.isConfirmed) {
        this.showAnalysisOptions = true;
      }
    },

    lengthTab(length) {
      this.lengthTable = length;
    },
    getProgram(item) {
      this.programPayment = item;
    },
  },
  computed: {
    hideNextButtons() {
      if (!!this.directRoles) {
        return false;
      } else {
        return !this.okPassword;
      }
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
    }),
    directRoles() {
      if (this.currentUser.role_id == 1) {
        return true;
      } else if (this.currentUser.role_id == 2) {
        return true;
      } else {
        return false;
      }
    },
    passwordIsCorrectAndCreditorLength() {
      return (
        (this.okPassword && this.lengthTable > 0) || this.stateCreditor == 1
      );
    },
    titleModal() {
      if (this.title != null) {
        return this.title;
      } else if (this.stateModal == 1) {
        return "Debt solution program sales process";
      } else {
        return "VIEW CREDITOR";
      }
      // return this.stateModal ==1 ? "ADD CREDITOR" : "VIEW CREDITOR";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isModuleCRM() {
      return this.moduleId === 2;
    },
    creditorsIsNotNull() {
      return this.lengthTable == 0;
    },
  },
};
</script>
