import store from "@/store";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import employeeClaims, {
  salesMadeCounter,
} from "@/views/commons/commons-counter";
import RequestService from "@/views/commons/components/request/services/request.service.js";
import Notifications from "@/service/notification/index";
import RecruitmentService from "@/views/commons/components/recruitment-process/services/recruiment.service";

const getLeadClientDerivationCounters = async () => {
  const params = {
    program_origin: 3,
  };
  const data = await DerivationsService.getLeadClientDerivationCounters(params);
  if (data.status === 200) {
    if (data.data[0].all_count > 0) {
      const payload = {
        routeName: "sales-derivation",
        tag: data.data[0].all_count > 99 ? "99+" : data.data[0].all_count,
      };
      store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
      await store.dispatch(
        "ceDigitalSalesDerivations/A_DERIVATION_CLIENT_COUNTER",
        data.data[0].client_count
      );
      await store.dispatch(
        "ceDigitalSalesDerivations/A_DERIVATION_LEAD_COUNTER",
        data.data[0].lead_count
      );
    }
  }
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: "request-sales",
    tag: data[0].counter,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "sales-projects",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "sales-recruitment-process",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const { data } = await Notifications.getCountPendingCustomerTickets({
    user_id,
  });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: "sales-customer-tickets",
      tag: data[0].v_total_open,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const allCountersCeDigitalSales = async () => {
  await Promise.all([
    salesMadeCounter("cedigital-sales-made-home"),
    getLeadClientDerivationCounters(),
    employeeClaims("sales-employee-claims"),
    requestNotAsignedCounter(),
    projectsCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
  ]);
};
export default allCountersCeDigitalSales;
