<template>
  <b-modal
    v-model="showModal"
    size="lg"
    centered
    header-bg-variant="transparent"
    @hidden="closeModal"
  >
    <template #modal-title>
      <h5
        class="text-uppercase p-0 m-0 d-flex justify-content-start align-items-center"
        style="margin-top: 3px !important;"
      >
        <feather-icon
          icon="ClockIcon"
          size="15"
          class="mr-1"
        />
        The client <b style="margin: 0px 5px;">{{ ticket.name_client }}</b> of ticket <b style="margin: 0px 5px;">{{ ticket.code }}</b> has not answered the meeting
      </h5>
    </template>
    <b-container
      fluid
      class="p-2"
    >
      <b-row>
        <b-col
          cols="12"
        >
          <body-meeting-ticket
            :ticket-customer="paramsMeetingNotAnswer"
            :return-hours="true"
            :default-today="false"
            @closeModal="showMeetingsTicket=false"
            @selectedHours="selectedHoursToReprogramm"
          />
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="secondary"
            class="mr-2"
            @click="closeModal"
          >
            CANCEL
          </b-button>
          <b-button
            variant="danger"
            @click="saveNotAnswered"
          >
            <feather-icon
              icon="UserXIcon"
              size="15"
              style="margin-right: 5px;"
            />
            {{ !hoursReprogram ? 'NOT ANSWERED':'NOT ANSWERED (REPROGRAMMED)' }}
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import BodyMeetingTicket from '@/views/commons/components/customer-tickets/components/modal/meetings/BodyMeetingTicket.vue';
import CustomerTicketsServices from '@/views/commons/components/customer-tickets/services/customer-tickets.service';
import store from '@/store';

export default {
  components: {
    BodyMeetingTicket,
  },
  props: {
    ticket: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      showMeetingsTicket: false,
      hoursReprogram: null,
      paramsMeetingNotAnswer: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.showModal = true;
    this.paramsMeetingNotAnswer = {
      module_id: 4,
    };
    console.log('asdd', this.ticket);
  },
  methods: {
    closeModal() {
      this.$emit('closeModal', false);
    },
    selectedHoursToReprogramm(hours) {
      this.hoursReprogram = hours.length > 0 ? hours : null;
    },
    async saveNotAnswered() {
      try {
        let save = false;

        // no haven't hours to reprogramm
        if (!this.hoursReprogram) {
          const { isConfirmed } = await this.showConfirmSwal('NO DATE SELECTED', 'Are you sure you want to save the meeting without rescheduling?');
          if (!isConfirmed) return;
          save = true;
        }

        if (!this.validHours()) return;

        // confirm save
        if (!save) {
          const { isConfirmed } = await this.showConfirmSwal();
          if (!isConfirmed) return;
        }

        this.addPreloader();
        let newMeeting = null;
        if (this.hoursReprogram) {
          newMeeting = this.hoursReprogram.map(item => ({
            meetingDate: item.meeting_date || null,
            meetingStart: item.range_start || null,
            meetingEnd: item.range_end || null,
            status: 2,
          }));
        }

        let isCorrelative = 0;
        if (!!this.hoursReprogram && this.hoursReprogram.length > 1) {
          isCorrelative = 1;
        }
        const params = {
          ticketCustomerId: this.ticket.id,
          createdBy: this.currentUser.user_id,
          meetingCreator: this.ticket.old_meeting.created_by,
          descriptionNote: null,
          status: 6,
          newMeeting,
          isCorrelative,
          ticketCode: this.ticket.code,
          clientName: this.ticket.name_client,
        };

        const { status } = await CustomerTicketsServices.updateMeetingTicketCustomer(params);
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal();
          this.$emit('success', true);
          await store.dispatch('NotificationStore/A_MEETINGS_PENDING_CUSTOMER_TICKETS');
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log(error);
      }
    },
    validHours() {
      if (!this.hoursReprogram || this.hoursReprogram.length === 1) {
        return true;
      }

      let isValid = true;
      this.hoursReprogram.forEach((item, index) => {
        const beforeHourEnd = index > 0 ? this.hoursReprogram[index - 1].range_end : null;

        if (beforeHourEnd && beforeHourEnd !== item.range_start) {
          isValid = false;
        }
      });

      if (!isValid) {
        this.showWarningSwal('VERY IMPORTANT', 'You cannot select more than one range of hours that are not correlative.');
      }
      return isValid;
    },
  },
};
</script>
