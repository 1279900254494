export default [
  {
    path: "alert-tracking",
    name: "ced-settings-alert-tracking",
    redirect: { name: "ced-tracking-alerts" },
    component: () =>
      import(
        /* webpackChunkName: "CEDCustomerServiceAlertTracking" */ "@/views/ce-digital/sub-modules/customer-service/views/alert-tracking/AlertTracking.vue"
      ),
    meta: {
      route: "settings",
      permittedRoles: [1, 2, 14, 17],
      pageTitle: "Alerts Tracking",
      breadcrumb: [
        {
          text: "Alerts Tracking",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "alerts",
        name: "ced-tracking-alerts",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceAlertTrackingAlerts" */ "@/views/ce-digital/sub-modules/customer-service/views/alert-tracking/components/GridAlertModalActions"
          ),
        meta: {
          mood: 1, // 1 = Alerts, 2 = Modal Actions
          pageTitle: "Alerts Tracking",
          breadcrumb: [
            {
              text: "Alerts Tracking",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 14, 15, 17],
        },
      },
      {
        path: "actions",
        name: "ced-tracking-modal-actions",
        component: () =>
          import(
            /* webpackChunkName: "CEDCustomerServiceAlertTrackingActions" */ "@/views/ce-digital/sub-modules/customer-service/views/alert-tracking/components/GridAlertModalActions"
          ),
        meta: {
          mood: 2, // 1 = Alerts, 2 = Modal Actions
          pageTitle: "Modal Actions Tracking",
          breadcrumb: [
            {
              text: "Modal Actions Tracking",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 14, 15, 17],
        },
      },
    ],
  },
]
