import DashboardNavigation from '@/views/logistic/views/dashboard/dashboard.nav'
import ProductsInventoryNavigation from '@/views/logistic/views/products-inventory/products-inventory.nav'
import HelpdeskNavigation from '@/views/logistic/views/helpdesk/helpdesk.nav'
import BillsNavigation from '@/views/logistic/views/bills/navigation/bills.navigation.js';
import merchandisingNavigation from '@/views/logistic/views/merchandising/merchandising.nav'
import budgetNavigation from '@/views/logistic/views/budget/budget.nav'
import SettingsNavigation from '@/views/logistic/views/settings/settings.nav'
import ProductRequestNavigation from '@/views/logistic/views/product-request/navigation/product-request.nav'
// import ProductRequestNavigation from '@/views/logistic/views/product-request/navigation/product-request.nav'
import VendorNavigation from "@/views/logistic/views/vendors/vendor.nav.js"
import RequestNavigation from "@/views/logistic/views/request/request.nav.js"
import InventoryNav from '@/views/logistic/views/inventory2/inventory.nav';
// import SettingsNavigation from '@/views/logistic/views/settings/nav/settings.navigation.js'
import EmployeeClaimsNavigation from '@/views/logistic/views/employee-claims/navigation/employee-claims.nav'
import DashboardClaimsNavigation from '@/views/logistic/views/dashboard-claims/dashboard.navigation';
//import LogisticProjectsNavigation from '@/views/logistic/views/projects/logistic-projects.navigation';
import CallLogNavigation from "@/views/logistic/views/call-log/call-log.nav.js";
import MOFNavigation from '@/views/logistic/views/manual-of-functions/manual-of-functions.nav.js'
import RecruitmentProcessNavigation from '@/views/logistic/views/recruitment-process/logistic-recruitment_process.navigation';
import CustomerTicketsNavigation from '@/views/logistic/views/customer-tickets/logistic-customer-tickets.navigation';

const navigation = [
  {
    header: 'LOGISTIC',
  },
  DashboardNavigation,
  ...BillsNavigation,
  InventoryNav,
  RequestNavigation,
  //...TransactionsNavigation,
  // ...ProductsInventoryNavigation,
  // LoansNavigation,
  // ProductRequestNavigation,
  // ...ProductsInventoryNavigation,
  ...merchandisingNavigation,
  VendorNavigation,
  budgetNavigation,
  SettingsNavigation,
  HelpdeskNavigation,
  //HelpdeskNavigation,
  //LoansNavigation,
  // ProductRequestNavigation,
  // EmployeeClaimsNavigation,
  // ...SettingsNavigation,
  ...DashboardClaimsNavigation,
  //LogisticProjectsNavigation,
  ...CallLogNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  CustomerTicketsNavigation,
]

export default navigation;
