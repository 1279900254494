<template>
  <b-container>
    <CoolLightBox
      :items="itemImage"
      :index="showImage"
      :effect="'fade'"
      @close="showImage = null"
    />
    <div class="file-grid">
      <div
        class="file-item clickable"
        v-for="(item, index) in files"
        :key="index"
      >
        <b-img
          :src="validateExtensionFile(item.file_type, item)"
          fluid
          alt="Responsive image"
          class="rounded"
          style="object-fit: cover; width: 100%; height: 100%"
          @click="openImage(item.file_type, item)"
        ></b-img>
        <div>{{ subStringFileName(item) }}</div>
      </div>
    </div>
  </b-container>
</template>
<script>
import CoolLightBox from "vue-cool-lightbox"
export default {
  components: {
    CoolLightBox,
  },
  props: ["files"],
  data() {
    return {
      showImage: null,
      itemImage: [],
    }
  },
  methods: {
    openImage(extension, image) {
      if (
        extension == "jpg" ||
        extension == "png" ||
        extension == "jpeg" ||
        extension == "webp"
      ) {
        this.itemImage = [image.file_path]
        this.showImage = 0
      } else {
        this.forceDownload(image.file_path, image.file_name)
      }
    },
    forceDownload(url, filename) {
      const link = document.createElement("a")
      link.href = url
      link.target = "_blank"
      link.setAttribute("download", filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    subStringFileName(item) {
      if (item.file_name.length < 22) {
        return item.file_name
      }
      return item.file_name.substring(0, 10) + "..." + item.file_type
    },
    validateExtensionFile(extension, item) {
      if (
        extension == "jpg" ||
        extension == "png" ||
        extension == "jpeg" ||
        extension == "webp"
      ) {
        return item.file_path
      } else {
        return "/assets/images/drag-drop/ext_files/archivodesc.png"
      }
    },
  },
}
</script>

<style scoped>
.file-grid {
  height: 40vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1rem;
  text-align: center;
}
.file-item {
  width: 150px;
  height: 150px;
}
</style>