import { otherServicesTab } from "@/views/commons/components/other-services/router/appointment.tab";

export default [
  {
    path: "other-services",
    name: "boost-credit-other-services",
    redirect: { name: "bc-other-services-pending" },
    component: () => import(
      "@/views/commons/components/other-services/view/OtherServicesMain.vue"
    ),
    meta: {
      router: "bc-other-services",
      pageTitle: "Other Services",
      breadcrumb: [
        {
          text: "Other Services",
          active: true,
        },
      ],
      routePending: "bc-other-services-pending",
      routeCompleted: "bc-other-services-completed",
      routeExpired: "bc-other-services-expired",
      routeSuccessfull: "bc-other-services-successfull",
      routeUnsuccessful: "bc-other-services-unsuccessful",
    },
    children: otherServicesTab("bc-other-services","Other Services"),
  },
];