export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import(/* webpackChunkName: "PagesError404" */ '@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import(/* webpackChunkName: "PagesLogin" */ '@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/expired-password',
    name: 'expired-password',
    component: () => import('@/views/pages/authentication/PasswordExpirated.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
    props: true,
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import(/* webpackChunkName: "PagesNoAuthorized" */ '@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full'
    },
  },
]
