<template>
  <b-col>
    <template
      v-if="withHeader"
    >
      <b-card-title class="card-title-address">
        <div>Call Log</div>
      </b-card-title>
    </template>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :double-paginate="doublePaginate"
      :class="isDarkSkin ? 'personal-table-p' : ' '"
      @onChangeCurrentPage="getClientLog"
      @reset-all-filters="resetAllFilters"
      @reload="getClientLog()"
    >
      <b-table
        id="rcTable"
        slot="table"
        ref="rcTable"
        :items="listCallRecording"
        :fields="fields"
        :filter="filter"
        table-class="text-nowrap"
        responsive="sm"
        no-border-collapse
        :sticky-header="maxHeight"
        show-empty
        class="table-new-customization custom-table"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(from)="data">
          <div class="d-flex">
            <b-avatar
              class="mr-1"
              variant="light-success"
            >
              <feather-icon
                icon="PhoneOutgoingIcon"
                size="16"
              />
            </b-avatar>
            <div>
              <div>
                {{ data.item.from_name }}
              </div>
              <b-badge variant="light-success">{{
                data.item.from_number
              }}</b-badge>
            </div>
          </div>
        </template>

        <template #cell(to)="data">
          <div class="d-flex">
            <b-avatar
              v-b-tooltip.hover
              class="mr-1"
              :variant="getCallStatus(data.item.status)[0]"
              :title="data.item.status"
            >
              <feather-icon
                :icon="getCallStatus(data.item.status)[1]"
                size="16"
              />
            </b-avatar>
            <div>
              <div>
                {{ data.item.to_name }}
              </div>
              <div>
                <b-badge :variant="getCallStatus(data.item.status)[0]">{{
                  data.item.to_number
                }}</b-badge>
              </div>
            </div>
          </div>
        </template>

        <template #cell(status)="data">
          <div class="text-left">
            <div>{{ data.item.status }}</div>
            <div v-if="minFields">
              {{ data.item.start_time | myGlobalWithHour }}
            </div>
          </div>
        </template>

        <template #cell(details)="data">
          <div>
            {{ data.item.start_time | myGlobalWithHour }}
          </div>
          <div class="w-100 d-flex justify-content-around text-center">
            {{ convertMSeconds(data.item.duration) }}
          </div>
          <div
            v-if="data.item.was_recording"
            class="w-100 d-flex justify-content-around text-center"
          >
            <div
              v-if="data.item.show_item && data.item.record_url"
              style="position: sticky; top: 0; z-index: 1"
            >
              <wave-surfer-player
                style="height: 1.5rem"
                :url="data.item.record_url"
                :options="audioOptions"
                :wave-surfer-id="`wave-surfer-${data.index}`"
                :elapsed-time-width="50"
                :file_name="data.item.original_id"
                :download="true"
                @loading="data.item.isLoading = true"
                @ready="data.item.isLoading = false"
                @WaveSurferEvents="load"
              />
            </div>
            <feather-icon
              v-else
              v-b-tooltip.hover
              icon="LoaderIcon"
              class="text-primary cursor-pointer"
              title="Load record"
              @click="data.item.show_item = !data.item.show_item"
            />
          </div>
        </template>

      </b-table>
    </filter-slot>
  </b-col>
</template>

<script>
import Filters from '@/views/commons/components/ring-central/call-log/data/FiltersClient.js';
import Fields from '@/views/commons/components/customer-tickets/components/modal/FieldsClient.js';
import CallLogService from '@/views/commons/components/ring-central/call-log/service/call-log.service';
import { mapGetters } from 'vuex';
import WaveSurferPlayer from '@/views/management/views/approval-process/settlement-approval/components/WaveSurferPlayer.vue';

export default {
  name: 'ClientCallLog',
  components: { WaveSurferPlayer },
  props: {
    client_account: {
      type: String || Number,
      required: false,
      default: null,
    },
    lead_id: {
      type: Number,
      required: false,
      default: null,
    },
    maxHeight: {
      type: String,
      default: '30vh',
      required: false,
    },
    doublePaginate: {
      type: Boolean,
      required: false,
      default: true,
    },
    withHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    minFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    programId: {
      type: Number,
      required: false,
      default: null,
    },

  },
  data() {
    return {
      totalRows: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by Number...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      filter: Filters,
      startPage: null,
      toPage: null,
      items: [],
      sortBy: '1',
      sortDesc: true,
      listCallRecording: [],
      audioOptions: {
        waveColor: '#99989A',
        progressColor: '#0077E6',
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 3,
        width: 180,
        backend: 'MediaElement',
        minPxPerSec: 5,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    fields() {
      const newFields = [...Fields];

      if (this.minFields) {
        newFields.splice(3, 1);
      }
      return newFields;
    },
  },

  methods: {
    resetAllFilters() {
      this.filter.forEach(filter => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.getClientLog();
    },
    resetSearch() {
      this.searchInput = '';
      this.getClientLog();
    },

    async getClientLog() {
      try {
        this.addPreloader();
        const params = {
          module_id: this.convertProgramToModule(
            this.programId,
          ),
          lead_id: this.lead_id,
          client_account: this.client_account,
          search_txt: this.filterPrincipal.model,
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          tab: this.statusRouter,
          date_from: this.filter[0].model,
          date_to: this.filter[1].model,
          status: this.filter[2].model,
          hidden: this.filter[3].model,
        };
        const { data } = this.lead_id ? await CallLogService.getCallLogsByLeadId(params) : await CallLogService.getLeadCallLog(params);
        this.startPage = data.from ? data.from : 0;
        this.paginate.perPage = data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.last_page;
        this.totalRows = data.total;
        this.toPage = data.to ? data.to : 0;
        this.listCallRecording = data.data;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      } finally {
        this.removePreloader();
      }
    },
    getCallStatus(status) {
      if (status === 'Accepted' || status === 'Call connected') {
        return ['light-primary', 'PhoneIncomingIcon'];
      }

      return ['light-danger', 'PhoneMissedIcon'];
    },

    async hiddenCall(id) {
      const result = await this.showQuestionSwal(
        'Do you want to perform this action?',
        'This will hide the call for all users.',
      );
      if (result.isConfirmed) {
        try {
          this.addPreloader();
          const params = {
            call_id: id,
          };
          const { data } = await CallLogService.hiddenCall(params);
          this.getClientLog();
        } catch (e) {
          this.showErrorSwal(e);
        } finally {
          this.removePreloader();
        }
      }
    },
  },
};
</script>
<style lang="scss">
.personal-table-p .card{
  background-color: #3d3d41 !important;
  padding: 1rem !important;
  color: white !important;
}
</style>
<style scoped>
h1, h2, h3, h4, h5, span, p, small{
  font-family: montserrat;
}
.my-dark{
  background-color: #2d2d2d !important;
}

.my-dark .card{
  background-color: #2d2d2d !important;
}
.bg-my-dark{
  background-color: #2d2d2d !important;
}
.table-new-customization >>> thead tr th {
  background: rgba(63, 122, 250, 1) !important;
}
</style>
