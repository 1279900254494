


export default [
  {
    path: "office-hours",
    name: "settings-office-hours",
    redirect: { name: "settings-office-hours-list" },
    component: () => import(/* webpackChunkName: "CEDSettingsOfficeHours" */ "@/views/ce-digital/sub-modules/settings/views/office-hours/OfficeHours.vue"),
    meta: {
      route: "settings",
      permittedRoles: [1, 17],
      breadcrumb: [
        {
          text: "Settings",
          active: true
        }
      ]
    },
    children: [
      {
        path: "",
        name: "settings-office-hours-list",
        component: () => import(/* webpackChunkName: "CEDSettingsOfficeHoursList" */ "@/views/ce-digital/sub-modules/settings/views/office-hours/components/OfficeHoursList.vue"),
        meta: {
          route: "settings",
          permittedRoles: [1, 17],
          pageTitle: "Office Hours",
          breadcrumb: [
            {
              text: "Office Hours",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
