<template>
  <b-modal
    size="xlg"
    v-model="showModal"
    @hidden="closeModal"
    no-close-on-backdrop
  >
    <template #modal-title>
      <h4 class="font-weight-bolder text-uppercase text-white m-0">
        {{ titleModal }}
      </h4>
    </template>
    <div class="mt-2 d-flex justify-content-center align-items-center">
      <b-table-simple
        small
        caption-top
        responsive
        bordered
        sticky-header="70vh"
      >
        <b-thead>
          <b-tr>
            <b-th class="td-left text-center header-width">Accounts</b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">TransUnion</span>
              <b-img :src="require('@/assets/images/icons/transunion.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Experian</span>
              <b-img :src="require('@/assets/images/icons/experian.png')" />
            </b-th>
            <b-th class="text-center col-3 v-middle">
              <span class="mr-1">Equifax</span>
              <b-img :src="require('@/assets/images/icons/equifax.png')" />
            </b-th>
          </b-tr>
        </b-thead>
        <template v-if="accounts.length > 0">
          <b-tbody>
            <template v-for="(account, indexAccount) in accounts">
              <b-tr
                :key="indexAccount"
                :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
              >
                <b-th class="d-flex justify-content-between">
                  <span class="text-danger">
                    {{ account.typeAccount }}
                  </span>
                  <b-form-checkbox
                    v-model="account.selected"
                    :options="account"
                    :value-field="account.cr_account_ac_id"
                  ></b-form-checkbox>
                </b-th>
                <b-th></b-th>
                <b-th></b-th>
                <b-th></b-th>
              </b-tr>
              <b-tr
                v-for="(header, index) in headerAccounts"
                :key="header.key + indexAccount"
              >
                <b-td
                  :class="
                    account.showMore
                      ? ''
                      : index > 3 && index < 14
                      ? 'd-none'
                      : ''
                  "
                >
                  <div v-if="header.key == 'detail'">
                    <b-badge
                      variant="light-primary"
                      class="cursor-pointer"
                      @click="changeRowAccount(account)"
                    >
                      {{ titleButton(account) }}
                    </b-badge>
                  </div>
                  <div v-else>
                    {{ header.label }}
                  </div>
                </b-td>
                <!--trasnution-->
                <b-td
                  :class="
                    account.showMore
                      ? ''
                      : index > 3 && index < 14
                      ? 'd-none'
                      : ''
                  "
                  class="text-center"
                >
                  <div
                    v-if="
                      header.key == 'status' &&
                      account.account[0][header.key] != null
                    "
                  >
                    <template v-if="account.someIsNegative">
                      <b-badge variant="danger"> Negative </b-badge>
                    </template>
                    <template v-else>
                      <b-badge
                        :variant="
                          account.account[0][header.key] == 5
                            ? 'primary'
                            : account.account[0][header.key] == 6
                            ? 'danger'
                            : ''
                        "
                      >
                        {{ statusName(account.account[0][header.key]) }}
                      </b-badge>
                    </template>
                  </div>
                  <span v-else>
                    {{ account.account[0][header.key] }}
                  </span>
                </b-td>
                <!--experian-->
                <b-td
                  :class="
                    account.showMore
                      ? ''
                      : index > 3 && index < 14
                      ? 'd-none'
                      : ''
                  "
                  class="text-center"
                >
                  <div
                    v-if="
                      header.key == 'status' &&
                      account.account[1][header.key] != null
                    "
                  >
                    <template v-if="account.someIsNegative">
                      <b-badge variant="danger"> Negative </b-badge>
                    </template>
                    <template v-else>
                      <b-badge
                        :variant="
                          account.account[1][header.key] == 5
                            ? 'primary'
                            : account.account[1][header.key] == 6
                            ? 'danger'
                            : ''
                        "
                      >
                        {{ statusName(account.account[1][header.key]) }}
                      </b-badge>
                    </template>
                  </div>
                  <span v-else>
                    {{ account.account[1][header.key] }}
                  </span>
                </b-td>
                <!--equifax-->
                <b-td
                  :class="
                    account.showMore
                      ? ''
                      : index > 3 && index < 14
                      ? 'd-none'
                      : ''
                  "
                  class="text-center"
                >
                  <div
                    v-if="
                      header.key == 'status' &&
                      account.account[2][header.key] != null
                    "
                  >
                    <template v-if="account.someIsNegative">
                      <b-badge variant="danger"> Negative </b-badge>
                    </template>
                    <template v-else>
                      <b-badge
                        :variant="
                          account.account[2][header.key] == 5
                            ? 'primary'
                            : account.account[2][header.key] == 6
                            ? 'danger'
                            : ''
                        "
                      >
                        {{ statusName(account.account[2][header.key]) }}
                      </b-badge>
                    </template>
                  </div>
                  <span v-else>
                    {{ account.account[2][header.key] }}
                  </span>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </template>
        <template v-else>
          <b-tbody>
            <b-tr>
              <b-td colspan="4" class="text-center">
                <div
                  class="d-flex w-100 align-items-center justify-content-center my-2"
                >
                  <img
                    :src="
                      filterParams.typeAccount === 'REMOVE'
                      ? require('@/assets/images/accounts/remove.svg')
                      : require('@/assets/images/accounts/settlement-accounts.svg')
                    "
                    class="custom-img"
                  />
                  <h4 class="text-uppercase position-absolute font-weight-bolder">
                    No accounts to {{ filterParams.typeAccount }}
                  </h4>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </template>
      </b-table-simple>
    </div>
    <template #modal-footer>
      <b-button variant="primary" @click="addAccounts()"> ADD </b-button>
    </template>
  </b-modal>
</template>
<script>
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service";
import HeaderAccounts from "@/views/commons/components/request-ncr/data/header-accounts.data.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showModal: false,
      accounts: [],
      headerAccounts: HeaderAccounts,
      statusOptions: [],
      endLoad: false,
    };
  },
  props: {
    accountResultId: {
      type: Number,
      required: true,
    },
    resultType: {
      type: String,
      required: true,
    },
    scoreId: {
      type: Number,
      required: true,
    },
    filterParams: {
      type: Object,
      require: false,
      default: {},
    },
  },
  async created() {
    this.addPreloader();
    await this.getAccounts();
    await this.allStatus();
    this.endLoad = true;
    this.removePreloader();
    this.showModal = true;
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    titleModal() {
      return this.resultType == "REMOVE"
        ? "ADD ACCOUNT FOR REMOVE"
        : "ADD ACCOUNT FOR SETTLEMENT";
    },
  },
  methods: {
    sanitizeData(data) {
      // const { idAccounts } = this.filterParams;

      const newData = data
        // .filter((element) => !idAccounts.includes(element.cr_account_ac_id))
        .map((item) => {
          let account = JSON.parse(item.account);
          return {
            ...item,
            account: account,
            typeAccount: account[0].classification,
          };
        });
      // if some account is negative then all accounts are negatives
      newData.forEach((element) => {
        element.someIsNegative = !!element.account.find(
          (item) => item.status === 6
        );
      });

      return newData;
    },
    async getAccounts() {
      const params = {
        scoreId: this.scoreId,
        accountResultId: this.accountResultId,
        resultType: this.resultType,
      };
      try {
        const { data } = await NcrRequestService.getAccounts(params);

        const filteredData = this.sanitizeData(data);

        this.accounts = filteredData.filter((item) =>
          this.filterParams.typeAccount === "REMOVE"
            ? item.someIsNegative
            : true
        );

        this.fillMisingInformation();
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async addAccounts() {
      let aux = this.accounts.filter((item) => item.selected);
      let accountsSelected = aux.map((item) => {
        return {
          cr_account_ac_id: item.cr_account_ac_id,
          type_account: item.typeAccount,
        };
      });
      if (accountsSelected.length == 0) {
        this.showToast(
          "warning",
          "top-right",
          "Oops!",
          "AlertIcon",
          "Select an account"
        );
        return;
      }
      const confirm = await this.showConfirmSwal();
      if (!confirm.isConfirmed) return;

      const params = {
        accountsSelected: accountsSelected,
        resultAnalysisAccId: this.accountResultId,
        resultType: this.resultType,
        userId: this.currentUser.user_id,
      };
      try {
        this.addPreloader();
        const { data } = await NcrRequestService.addAccounts(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful process"
          );
          this.$emit("refreshData");
          this.$emit("closeModal");
        } else {
          this.showToast(
            "danger",
            "top-right",
            "Oops!",
            "XIcon",
            "Failed preccess"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    fillMisingInformation() {
      let i;
      let account = [];
      this.accounts.map((item) => {
        for (i = 0; i < 3; i++) {
          if (item.account.some((val) => val.bureau_id == i + 1)) {
            account.push(item.account.find((val) => val.bureau_id == i + 1));
          } else {
            account.push({
              content: "-",
              a_number: "-",
              balance: 0,
              type: "-",
              status: null,
              bureau_id: i + 1,
              p_status: "-",
              c_limit: 0,
              monthly: 0,
              last_p: 0,
              a_status: "-",
              a_description: "-",
              date: "-",
              n_months: "-",
              h_credit: "-",
              comments: "-",
              isShow: true,
              edit: false,
              round: null,
              to_select: false,
              edit_classification: false,
              classification: null,
              results: null,
              negative: null,
            });
          }
        }
        item.account = account;
        item.selected = false;
        item.showMore = false;
        account = [];
      });
    },
    async allStatus() {
      try {
        const { data } = await NcrRequestService.getAllStatusLetters();
        this.status = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    changeRowAccount(account) {
      account.showMore = !account.showMore;
      this.accounts = [...this.accounts];
    },
    statusName(id) {
      if (this.endLoad) {
        if (id == null) {
          return "-";
        }
        return this.status.find((item) => item.id == id)["value"];
      }
    },
    titleButton(account) {
      return account.showMore ? "Show less details" : "Show more details";
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>
<style scoped>
.custom-img {
  width: 300px;
  height: 300px;
  opacity: 0.2;
  padding: 20px;
  position: relative;
}
</style>
