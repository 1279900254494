export default [
  {
    path: "commissions-sp-credit-experts",
    name: "commissions-sp-credit-experts",
    component: () =>
      import(
        /* webpackChunkName: "commissionsSpecialists" */
        "@/views/specialists/sub-modules/financial-approval/views/commissions/views/CommissionForSpecialists"
      ),
    meta: {
      pageTitle: "Commissions",
      breadcrumb: [
        {
          text: "Commissions",
          active: true,
        },
      ],
      module: 6,
    },
  },
];
