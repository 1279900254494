import { saleMadeTabs } from "@/views/commons/components/sales-made/router/sale-made.router";
export default [
  {
    path: "sales-made",
    name: "bussiness-sales-made",
    redirect: { name: "sales-made-bussiness-lead" },
    component: () => import(/* webpackChunkName: "BusinessSalesMade" */ "@/views/business/views/sales-made/SalesMade.vue"),
    meta: {
      program: 1,
      pageTitle: "Sales Made",
      breadcrumb: [
        {
          text: "Sales Made",
        },
      ],
    },
    // children: [
    //   {
    //     path: "add-change",
    //     name: "sales-made-bussiness-add-change",
    //     component: () =>
    //       import(
    //           /* webpackChunkName: "BusinessSalesMadeAddChange" */ "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue"
    //       ),
    //     meta: {
    //       program: 1,
    //       pageTitle: "Sales Made",
    //       breadcrumb: [
    //         {
    //           text: "Sales Made",
    //           active: true,
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     path: "add-sale",
    //     name: "sales-made-bussiness-add-sale",
    //     component: () =>
    //       import(
    //             /* webpackChunkName: "BusinessSalesMadeAddSale" */ "@/views/commons/components/sales-made/add-sale/components/SalesMadeAddSaleComponent.vue"
    //       ),
    //     meta: {
    //       type: 0,
    //       program: 1,
    //       pageTitle: "Sales Made",
    //       breadcrumb: [
    //         {
    //           text: "Sales Made",
    //           active: true,
    //         },
    //       ],
    //     },
    //   },
    // ],
    children: saleMadeTabs("bussiness")
  },
];
