<template>
  <div>
    <b-dropdown :size="size" variant="link" no-caret boundary="viewport" :disabled="disabled">
      <template #button-content>
        <button-export-pdf
          :variant="'outline-primary'"
          :textButton="'Export Analysis'"
          :viewIcon="true"
          
          :size="size"
        />
      </template>
      <b-dropdown-item @click="exportPdf(0)">
        <feather-icon icon="DownloadIcon" class="mr-1" />
        <span>Export PDF</span>
      </b-dropdown-item>
      <!-- SEND SMS BUTTON -->
      <!-- WITH TOOLTIP -->
      <b-dropdown-item id="sendSMS" @click="exportPdf(1)" v-if="!smsExists" disabled  v-b-tooltip.hover.bottom="noSMSMessage" class="cursor-pointer">
        <feather-icon icon="MessageCircleIcon" class="mr-1" />
        <span>Send SMS</span>
      </b-dropdown-item>
      <!-- WITHOUT TOOLTIP -->
      <b-dropdown-item id="sendSMS" v-else @click="exportPdf(1)" class="cursor-pointer">
        <feather-icon icon="MessageCircleIcon" class="mr-1" />
        <span>Send SMS</span>
      </b-dropdown-item>
      <!-- END SMS BUTTON -->
    </b-dropdown>
  </div>
</template>
  
  <script>
import ButtonExportPdf from "@/views/commons/utilities/ButtonExportPdf.vue";
import FinancialService from "@/views/crm/views/sales-made/components/modals/services/debt-solution/services/questionnnaire-service.js";
import {mapGetters} from "vuex"
import { getUserData } from "@/auth/utils"
import { required } from 'vee-validate/dist/rules';
export default {
  name: "buttonExportAnalysis",
  components: {
    ButtonExportPdf,
  },
  props: {
    leadId: {
      required: true,
    },
    eventId: {
      required: true,
    },
    disabled :{
      default:false,
    },
    size:{
      type: String,
      required: false,
      default: "md"
    }
  },
  data() {
    return {
      noSMSMessage: 'This option is disabled because there is no default SMS',
      smsExists: false
    };
  },
  async mounted(){
    this.checkSMSExists()
  },
   computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser/',
    }),
  },
  methods: {
    async checkSMSExists(){
      const userData = getUserData()
      try {
        const params = {}
        const {data} = await FinancialService.checkSMSExists(params)
        if(data[0].sms_exists){
          this.smsExists = true
          // if(userData.arrRoles[0].role_id == 1){
          //   const result = await this.showQuestionSwal('There is no default SMS settled in Debt Solution Settings.','Do you want to go to settings?','Go!','Cancel','info')
          //   if(result.isConfirmed){
          //    this.$router.push({name: 'debt-solution-settings'})
          //   }
          // }else{
          //   this.showInfoSwal('There is no default SMS settled in Debt Solution Settings. Please contact with DS supervisor.', 'Important!')
          // }
        }
        
      } catch (error) {
        console.log('Failed check if sms exists')
      }
    },
    // value = 1 Send SMS
    // value = 2 Export Analysis
    async exportPdf(value) {
      try {
        const params = {
          lead: this.leadId,
          event_id: this.eventId,
          sms: value,
        };
        this.addPreloader();
        const { data, status } = await FinancialService.financialAnalysisPdf(
          params
        );
        if (value === 0) {
          await this.forceFileDownloadPdf(data);
        }
        if (status == 200) {
          let successMessage = value === 1 ? "Send SMS" : "Export PDF";
          this.showToast(
            "success",
            "top-right",
            "Financial Analysis",
            "CheckIcon",
            successMessage
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

  