export default [
  {
    path: 'dashboard',
    name: 'dashboard-social-network',
    component: () => import(/* webpackChunkName: "SnDashboard" */ '@/views/social-network/views/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: 'Dashboard',
      breadcrumb: [{
        text: 'Dashboard',
      }]
    },
  },
]
