

export default {
  path: "chat",
  name: "customerservice-chat",
  component: () => import(/* webpackChunkName: "CEDCustomerServiceChat" */ "@/views/ce-digital/sub-modules/customer-service/views/chat-app/Chat.vue"),
  meta: {
    pageTitle: "chat-app",
    breadcrumb: [
      {
        text: "chat-app",
        active: true,
      },
    ],
    permittedRoles: [1, 2, 16, 17],
  },
};
