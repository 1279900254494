const leadFields = [
  {
    key: "lead_name",
    label: "Name",
    sortable: false,
    thClass: "text-left",
  },
  {
    key: "states_eeuu_slug",
    label: "State",
    sortable: false,
  },
  {
    key: "status",
    thClass: "text-center",
  },
  {
    key: "mobile",
    thClass: "text-left",
  },
  {
    key: "credit_report",
    label: "CR",
    thClass: "text-left",
  },
  {
    key: "source_name",
    label: "Source",
    sortable: false,
  },
  {
    key: "programs",
    thClass: "text-left",
  },
  {
    label: "Created By",
    thClass: "text-left",
    key: "created_at",
    sortable: false,
  },
  // {
  //   key: 'client_ceo',
  //   label: 'client of ceo',
  //   thClass: 'text-left',
  // },
  {
    key: "actions",
    thClass: "text-left",
  },
];

export default {
  leadFields,
};
