import EmployeesService from '@/views/management/views/employees/employees.service'
import Vue from 'vue'

const state = {
  S_EMPLOYEE_ID: '',
  S_EMPLOYEE_INFO: [],
}
const getters = {
  G_EMPLOYEE_ID() {
    return state.S_EMPLOYEE_ID
  },
  G_EMPLOYEE_INFO() {
    return state.S_EMPLOYEE_INFO
  },
}
const mutations = {
  M_SET_S_EMPLOYEE_ID(state, val) {
    state.S_EMPLOYEE_ID = val
  },
  M_SET_S_EMPLOYEE_INFO(state, val) {
    Vue.set(state, 'S_EMPLOYEE_INFO', val)
  },
}
const actions = {
  async A_EMPLOYEE_INFO({ commit }, body) {
    try {
      const params = {
        id: body,
      }
      const response = await EmployeesService.getEmployeeDashboard(params)
      commit('M_SET_S_EMPLOYEE_INFO', response.data[0])
      return response.data[0]
    } catch (error) {
      console.log('ERROR_GET_EMPLOYEE_INFO [ACTION]', error)
      throw error
    }
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,

}
