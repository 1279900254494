export default {
  path: "/cedigital/customerservice/helpdesk/",
  name: "helpdesk-customer",
  component: () => import("./Helpdesk.vue"),
  meta: {
    pageTitle: "Help Desk",
    module: 21,
    breadcrumb: [
      {
        text: "Help Desk",
        active: true,
      },
    ],
    onlyChief: true,
  },
}
