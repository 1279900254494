import { amgApi } from "@/service/axios";

class SmsService {


    async getNewCredentials() {
        try {
            const data = await amgApi.get('/ring-central/credentials/new');
            return data;
        } catch (error) {
            console.log("Something went wrong on getNewCredentials:", error);
            throw error;
        }
    }

    async getInfoChat(payload) {
        try {
            const data = await amgApi.post('/ring-central/lead/index', payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getInfoChat:", error);
            throw error;
        }
    }

    async getClientsAccountByLeadId(leadId) {
        try {
            const { data } = await amgApi.get(`/ring-central/lead/show/${leadId}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getClientsAccountByLeadId:", error);
            throw error;
        }
    }


    async getAllUsersByModule(moduleId) {
        try {
            const { data } = await amgApi.get(`/ring-central/users/index/${moduleId}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getAllUsersByModule:", error);
            throw error;
        }
    }

    async storeNewTopic(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/topic/store`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on storeNewTopic:", error);
            throw error;
        }
    }

    async searchTopic(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/topic/index`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on searchTopic:", error);
            throw error;
        }
    }

    async getMessages(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/messages/index`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getMessages:", error);
            throw error;
        }
    }

    async getMessagesDetail(id, payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/messages/show/${id}`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getMessagesDetail:", error);
            throw error;
        }
    }

    async regenerateMessageTopic(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/messages/regenerate`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on regenerateMessageTopic:", error);
            throw error;
        }
    }

    async getCounterMessagesFailed(id) {
        try {
            const data = await amgApi.get(`/ring-central/sms/messages/${id}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getCounterMessagesFailed:", error);
            throw error;
        }
    }

    async getRegenerateExists(id) {
        try {
            const data = await amgApi.get(`/ring-central/sms/messages/exists/${id}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getRegenerateExists:", error);
            throw error;
        }
    }

    async deleteMessageTopic(id, payload) {
        try {
            const data = await amgApi.delete(`/ring-central/sms/messages/${id}`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on deleteMessageTopic:", error);
            throw error;
        }
    }

    async getLeadsList(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/topic/leads/index`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getLeadsList:", error);
            throw error;
        }
    }

    async getLeadsListTotal(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/topic/leads/total`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getLeadsListTotal:", error);
            throw error;
        }
    }

    async saveLeads(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/topic/leads/store`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on saveLeads:", error);
            throw error;
        }
    }

    async getLeadsByTopic(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/topic/leads/show`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getLeadsByTopic:", error);
            throw error;
        }
    }

    async deleteLeadFromTopic(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/topic/leads/delete`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on deleteLeadFromTopic:", error);
            throw error;
        }
    }

    async saveMessageAtt(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/messages/store`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on saveMessageAtt:", error);
            throw error;
        }
    }

    async updateTopic(id, payload) {
        try {
            const data = await amgApi.patch(`/ring-central/sms/topic/${id}`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on updateTopic:", error);
            throw error;
        }
    }

    async searchLeadByPhoneName(search) {
        try {
            const data = await amgApi.post(`/ring-central/lead/search`, search);
            return data;
        } catch (error) {
            console.log("Something went wrong on searchLeadByPhoneName:", error);
            throw error;
        }
    }

    async getCredentialByLeadId(leadId) {
        try {
            const data = await amgApi.get(`/ring-central/credentials/lead-id/${leadId}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getCredentialByLeadId:", error);
            throw error;
        }
    }

    async getAllCountersAndDetails(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/index`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getAllCountersAndDetails:", error);
            throw error;
        }
    }


    async getAllLeadsFromList(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/topic/detail/index`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getAllLeadsFromList:", error);
            throw error;
        }
    }

    async deleteLeadsList(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/topic/detail/delete`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on deleteLeadsFromList:", error);
            throw error;
        }
    }

    async getTotalLeadsFromList(payload) {
        try {
            const data = await amgApi.post(`/ring-central/sms/topic/detail/total`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on getTotalLeadsFromList:", error);
            throw error;
        }
    }

    async sendMessage(payload) {
        try {
            const data = await amgApi.post(`/ring-central/chat-message/store`, payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on sendMessage:", error);
            throw error;
        }
    }


    async getMessageInQueueSoft(chatId) {
        try {
            const data = await amgApi.get(`/ring-central/chat-message/queue/show/${chatId}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getMessageInQueueSoft:", error);
            throw error;
        }
    }

    async getRcList(listId) {
        try {
            const data = await amgApi.get(`/ring-central/sms/topic/${listId}`);
            return data;
        } catch (error) {
            console.log("Something went wrong on getRcList:", error);
            throw error;
        }
    }

}

export default new SmsService();
