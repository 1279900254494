import store from "@/store"
import router from "@/router/index"

const openWelcomeActionAgents = () => {
  window.socket.bind("welcome-agent-action", async (data) => {
    const agentId = store.state.auth.currentUser.user_id
    if (data.to_id === agentId) {
      if (router.currentRoute.matched[0]?.meta?.module === 22) {
        await store.dispatch("CeDigitalWelcomeActions/A_OPEN_MODAL_ACTIONS", {
          bool: data.bool_data,
          id_alert: JSON.parse(localStorage.getItem("getCEDigitalAlert"))
            .alert_id,
        })
      }
    }
  })
}

export default openWelcomeActionAgents
