var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"annulled-sales-clients-modal",attrs:{"title":"Annulled Sales","title-class":"h3 text-white font-weight-bolder","size":"xmd","no-close-on-backdrop":"","modal-class":"modal-primary","hide-footer":""},on:{"hidden":_vm.hiddenModal}},[_c('b-container',{attrs:{"fluid":""}},[_c('filter-slot',{attrs:{"filter":[],"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage},on:{"reload":function($event){return _vm.$refs['refListTable'].refresh()}},scopedSlots:_vm._u([{key:"buttons",fn:function(){return undefined},proxy:true}])},[_c('b-table',{ref:"refListTable",attrs:{"slot":"table","primary-key":"id","busy":_vm.isBusy,"empty-text":"No matching records found","select-mode":"multi","responsive":"sm","no-provider-filtering":"","table-class":"text-nowrap","items":_vm.myProvider,"fields":_vm.fields,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"sticky-header":"","small":"","sort-icon-left":"","show-empty":""},on:{"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"cell(client)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{class:_vm.textLink,attrs:{"to":_vm.routerClient(item),"target":"_blank"}},[_vm._v(" "+_vm._s(item.client)+" ")]),_c('div',[_vm._v(" "+_vm._s(item.account)+" ")])]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [(item.type === 1)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:('Add'),expression:"'Add'",modifiers:{"bottom":true}}]},[_vm._v("AD")]):_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:('Change'),expression:"'Change'",modifiers:{"bottom":true}}]},[_vm._v("CH")])]}},{key:"cell(first_program)",fn:function(ref){
var item = ref.item;
return [_c('program-icon',{attrs:{"programName":item.first_program,"programId":item.first_program_id}})]}},{key:"cell(program)",fn:function(ref){
var item = ref.item;
return [_c('program-icon',{attrs:{"programName":item.program,"programId":item.program_id}})]}},{key:"cell(executives)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:("Seller"),expression:"`Seller`",modifiers:{"hover":true,"top":true}}],staticClass:"text-info"},[_vm._v(" "+_vm._s(item.seller)+" ")]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:("Catcher"),expression:"`Catcher`",modifiers:{"hover":true,"top":true}}],staticClass:"text-warning"},[_vm._v(" "+_vm._s(item.captured)+" ")])])]}},{key:"cell(notes)",fn:function(data){return [(data.item.creates > '2021-05-16 00:00:00')?_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"chat-square-text-fill","variant":data.item.notes_status_new == null
                ? 'muted'
                : data.item.notes_status_new == 0
                ? 'success'
                : 'warning'},on:{"click":function($event){return _vm.notesModal(data.item, data.index)}}}):_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"chat-square-text-fill","variant":data.item.notes_status === 0 ? 'muted' : 'success'},on:{"click":function($event){return _vm.notesModal(data.item, data.index)}}})]}},{key:"cell(dates)",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"text-warning d-block"},[_vm._v(_vm._s(item.annulled_by_name))]),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Annulled at'),expression:"'Annulled at'",modifiers:{"hover":true,"top":true}}],staticClass:"text-warning d-block"},[_vm._v(_vm._s(_vm._f("myGlobalWithHour")(item.annulled_at)))])]}},{key:"cell(fee)",fn:function(ref){
                var item = ref.item;
return [_vm._v(" $ "+_vm._s(_vm._f("currency")(item.fee))+" ")]}}])})],1)],1),(_vm.modal.notes)?_c(_vm.modalData.notes.programSelected,{tag:"component",attrs:{"note-info":_vm.modalData.notes},on:{"hide":function($event){_vm.modal.notes = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }