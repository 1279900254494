export default {
  path: 'new-employees/',
  name: 'new-employees',
  component: () => import('./index.vue'),
  meta: {
    pageTitle: 'New Employees',
    breadcrumb: [
      {
        text: 'New Employees',
        active: true,
      },
    ],

    onlyJuan: true,

  },
};
