export default [
  {
    path: "commissions",
    name: "commissions-bussiness",
    component: () =>
      import(/* webpackChunkName: "BusinessCommissiions" */ "@/views/commons/components/commissions/CommissionsModules.vue"),
    meta: {
      pageTitle: "Commissions",
      breadcrumb: [
        {
          text: "Commissions",
          active: true,
        },
      ],
      tab: "1",
    },
  },
];
