export default [
  {
    path: "/specialistdigital/cr-realtor",
    name: "specialist-digital-cr-realtor", //specialist-digital-cr-realtor
    component: () =>
      import(/* webpackChunkName: "SpecialistDigitalCrRealtor" */  "@/views/specialist-digital/views/cr-realtor/RealtorCRMain.vue"),
    redirect: { name: "spd-cr-realtor-clients" },
    meta: {
      module: 25,
      pageTitle: "Realtor CR",
      breadcrumb: [
        {
          text: "Realtor CR",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "/specialistdigital/cr-realtor/clients",
        name: "spd-cr-realtor-clients",
        component: () =>
          import(/* webpackChunkName: "SpecialistDigitalCrRealtorCR" */ "@/views/specialist-digital/views/cr-realtor/RealtorCR.vue"),
        redirect: { name: "spd-cr-realtor-pending" },
        children: [
          {
            path: "pending",
            name: "spd-cr-realtor-pending",
            component: () =>
              import(
                /* webpackChunkName: "SpecialistDigitalCrRealtorPending" */ "@/views/specialist-digital/views/cr-realtor/components/GridRealtorCR.vue"
              ),
            meta: {
              module: 25,
              statusCR: 0,
              pageTitle: "Realtor CR",
              breadcrumb: [
                {
                  text: "Pending",
                  active: true,
                },
              ],
            },
          },
          {
            path: "completed",
            name: "spd-cr-realtor-completed",
            component: () =>
              import(
                /* webpackChunkName: "SpecialistDigitalCrRealtorCompleted" */ "@/views/specialist-digital/views/cr-realtor/components/GridRealtorCR.vue"
              ),
            meta: {
              module: 25,
              statusCR: 1,
              pageTitle: "Realtor CR",
              breadcrumb: [
                {
                  text: "Completed",
                  active: true,
                },
              ],
            },
          },
        ],
        meta: {
          module: 25,
          statusCR: 0,
          pageTitle: "Realtor CR",
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
        },
      },
      {
        path: "/specialistdigital/cr-realtor/leads",
        name: "spd-cr-realtor-leads",
        component: () =>
          import(
            /* webpackChunkName: "SpecialistDigitalCrRealtorRealtorLeads" */ "@/views/specialist-digital/views/cr-realtor/LeadsRealtorCR.vue"
          ),
        redirect: { name: "spd-cr-realtor-lead-pending" },
        children: [
          {
            path: "pending",
            name: "spd-cr-realtor-lead-pending",
            component: () =>
              import(
                /* webpackChunkName: "SpecialistDigitalCrRealtorGridLeads" */ "@/views/specialist-digital/views/cr-realtor/components/LeadGridRealtorCR.vue"
              ),
            meta: {
              module: 25,
              statusCR: 1,
              pageTitle: "Realtor CR",
              breadcrumb: [
                {
                  text: "Pending",
                  active: true,
                },
              ],
            },
          },
          {
            path: "completed",
            name: "spd-cr-realtor-lead-completed",
            component: () =>
              import(
                /* webpackChunkName: "SpecialistDigitalCrRealtorLeadCompleted" */ "@/views/specialist-digital/views/cr-realtor/components/LeadGridRealtorCR.vue"
              ),
            meta: {
              module: 25,
              statusCR: 2,
              pageTitle: "Realtor CR",
              breadcrumb: [
                {
                  text: "Completed",
                  active: true,
                },
              ],
            },
          },
        ],
        meta: {
          module: 25,
          statusCR: 0,
          pageTitle: "Realtor CR",
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "/specialistdigital/leads/report/:idlead/:idfile/:accountId",
    name: "specialistdigital-lead-car",
    component: () =>
      import(/* webpackChunkName: "SpecialistDigitalCrRealtorLeadReport" */ "@/views/commons/components/ncr/components/report/ReportLead.vue"),
    props: true,
    meta: {
      isClientsTab: true,
      module: 25,
      typeTable: 3,
    },
  },
]
