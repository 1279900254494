import TimelineCreative from "@/views/commons/utilities/TimeLineCreative.vue"
const ModalPlugin = {
  install(Vue, options) {
    const modalInstances = []
    Vue.prototype.$timeLineCreative = function (propsData = {}) {
      modalInstances.forEach((instance) => {
        instance.close()
      })
      const ModalComponentConstructor = Vue.extend(TimelineCreative)
      const modalInstance = new ModalComponentConstructor({
        propsData: {
          ...options,
          ...propsData,
        },
      })
      modalInstances.push(modalInstance)
      modalInstance.$mount()
      document.body.appendChild(modalInstance.$el)
    }
    Vue.prototype.$timeLineCreative.closeAll = function () {
      modalInstances.forEach((instance) => {
        instance.close()
      })
    }
  },
}

export default ModalPlugin
