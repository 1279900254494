import { amgApi } from '@/service/axios'

class UserService {
  async getUserSessions(body) {
    try {
      const data = await amgApi.post('/commons/session-users', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async validatePasswordUser(body) {
    try {
      const data = await amgApi.post(
        '/profile/validate-if-password-is-correct',
        body,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async changePasswordUser(body) {
    try {
      const data = await amgApi.post('/commons/change-password-user', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getUserStatusSession(body) {
    try {
      const data = await amgApi.post('/commons/get-user-status-session', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async getUserStatusSubModuleSession(body) {
    try {
      const data = await amgApi.post('/commons/get-user-status-submodule-session', body)
      return data
    } catch (error) {
      throw error
    }
  }

  async changeUserStatusSession(body) {
    try {
      const data = await amgApi.post(
        '/commons/change-user-status-session',
        body,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async changeUserStatusSessionSubModule(body) {
    try {
      const data = await amgApi.post(
        '/commons/date-user-status-session',
        body,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async saveArgumentMotiveOther(body) {
    try {
      const data = await amgApi.post(
        '/commons/save-arg-motive-other',
        body,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getCurrentActiveModule(body) {
    try {
      const data = await amgApi.post(
          '/commons/get-current-active-module',
          body,
      )
      return data
    } catch (error) {
      throw error
    }
  }
}

export default new UserService()
