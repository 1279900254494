export default {
    path: "calendar",
    name: 'calendar-social-network',
    component: () => import(/* webpackChunkName: "SnCalendar" */ '@/views/social-network/views/calendar/Calendar.vue'),
    meta: {
      pageTitle: "Calendar",
      breadcrumb: [
        {
          text: "Tasks",
          active: true,
        },
      ],
    },
  };
