<template>
  <b-modal
    :title="modalTitle"
    v-model="modalShow"
    @hidden="$emit('onlyClose')"
    hide-footer
    size="xmd"
    no-close-on-backdrop
    no-close-on-esc
    modal-class="rc-chat-create-list"
  >
    <form-wizard
      ref="wizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      :start-index="newStartIndex"
      finish-button-text="Done"
      back-button-text="Previous"
      @on-complete="formSubmitted"
    >
      <tab-content title="INFORMATION" :before-change="storeNewTopic">
        <validation-observer ref="form">
          <b-row>
            <b-col cols="12">
              <validation-provider rules="required" v-slot="{ errors }">
                <b-form-group label="List Name: " label-for="list-name">
                  <b-form-input
                    id="list-name"
                    v-model="currentTopic.name"
                    placeholder="Example: DS March 2024"
                    style="flex: 1 1 auto"
                    :class="{ 'border-danger': errors[0] }"
                    :disabled="wasSaved"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6" v-if="!modulesOnlyLeads.includes(moduleId)">
              <validation-provider rules="required" v-slot="{ errors }">
                <b-form-group label="Type: " label-for="type">
                  <v-select
                    id="type"
                    v-model="currentTopic.type"
                    style="flex: 1 1 auto"
                    label="name"
                    :reduce="(val) => val.id"
                    :options="listOptions"
                    :class="{ 'border-danger': errors[0] }"
                    :clearable="false"
                    :disabled="wasSaved"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content :title="secondStepName">
        <topic-leads
          v-if="currentTopic.id"
          :listId="currentTopic.id"
          :moduleId="moduleId"
          :typeList="currentTopic.type"
        />
      </tab-content>
    </form-wizard>
  </b-modal>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import SmsInboxStore from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import { mapGetters, mapMutations } from "vuex";
import TopicLeads from "@/views/commons/components/ring-central/chat-sms/components/RcChatTopicLeads.vue";

export default {
  props: {
    credential: {
      type: Object,
      required: true,
    },
  },

  components: {
    FormWizard,
    TabContent,
    TopicLeads,
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
       G_LIST_TAB: "SmsInboxStore/G_LIST_TAB",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    secondStepName() {
      if ([2, 15].includes(this.moduleId) || this.typeList == 1) {
        return "LEADS";
      }
      return "CLIENTS";
    },

    modalTitle() {
      if (this.wasSaved) {
        return `LIST: ${this.currentTopic.name}`;
      } else {
        return "NEW LIST";
      }
    },
  },

  data() {
    return {
      modalShow: true,
      newStartIndex: 0,
      modulesOnlyLeads: [2, 15],
      listOptions: [
        {
          id: 1,
          name: "Only Leads",
        },
        {
          id: 2,
          name: "Only Clients",
        },
      ],

      wasSaved: false,
      currentTopic: {
        name: null,
        type: 1,
        id: null,
      },
    };
  },

  methods: {
    ...mapMutations({
      M_ADD_LAST_TOPIC: "SmsInboxStore/M_ADD_LAST_TOPIC",
    }),

    async formSubmitted() {
      this.$emit("onlyClose");
    },

    validateModuleOnlyLead() {
      if (this.modulesOnlyLeads.includes(this.moduleId)) {
        this.listOptions = [
          {
            id: 1,
            name: "Only Leads",
          },
        ];
        this.currentTopic.type = 1;
      } else {
        this.listOptions = [
          {
            id: 1,
            name: "Only Leads",
          },
          {
            id: 2,
            name: "Only Clients",
          },
        ];
      }
    },

    async storeNewTopic() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return false;

      if (this.wasSaved && this.currentTopic.name) return true;

      try {
        this.addPreloader();

        const params = {
          created_by: this.currentUser.user_id,
          credential_id: this.credential.id,
          name: this.currentTopic.name,
          type: this.currentTopic.type,
          module_id: this.moduleId,
        };

        const { data } = await SmsInboxStore.storeNewTopic(params);

        if (data && data.data && this.G_LIST_TAB == 'active') {
          this.currentTopic = { ...data.data };
          this.M_ADD_LAST_TOPIC(data.data);
        }

        this.wasSaved = true;
        this.showSuccessSwal(null, "List created successfully");
        return true;
      } catch (error) {
        this.showErrorSwal(error);
        return false;
      } finally {
        this.removePreloader();
      }
    },
  },

  mounted() {
    this.validateModuleOnlyLead();
  },
};
</script>

<style lang="scss">
.rc-chat-create-list {
  .wizard-tab-content {
    min-height: 200px;
  }
}
</style>