export default [
  {
    path: "customer-service-request-client-status",
    name: "customer-service-request-client-status",
    redirect: { name: 'customer-service-request-client-status-pending' },
    component: () => import(/* webpackChunkName: "CustomerServiceRequestClientStatus" */ "@/views/management/views/request-client-status/RequestClientStatus.vue"),
    meta: {
      pageTitle: "Request Client Status",
      breadcrumb: [
        {
          text: "Request Client Status",
        },
      ],
    },
    children: [
      {
        path: "pending",
        name: "customer-service-request-client-status-pending",
        component: () => import(/* webpackChunkName: "CustomerServiceRequestClientStatusList" */ "@/views/management/views/request-client-status/components/RequestClientStatusList.vue"),
        meta: {
          pageTitle: "Request Client Status",
          breadcrumb: [
            {
              text: "Pending",
              active: true,
            },
          ],
          type: 1,
          RequestClientStatusPending: 'customer-service-request-client-status-pending',
          RequestClientStatusRejected: 'customer-service-request-client-status-rejected',
          RequestClientStatusAccepted: 'customer-service-request-client-status-accepted',
        },
      },
      {
        path: "rejected",
        name: "customer-service-request-client-status-rejected",
        component: () => import(/* webpackChunkName: "CustomerServiceRequestClientStatusList" */ "@/views/management/views/request-client-status/components/RequestClientStatusList.vue"),
        meta: {
          pageTitle: "Request Client Status",
          breadcrumb: [
            {
              text: "Rejected",
              active: true,
            },
          ],
          type: 2,
          RequestClientStatusPending: 'customer-service-request-client-status-pending',
          RequestClientStatusRejected: 'customer-service-request-client-status-rejected',
          RequestClientStatusAccepted: 'customer-service-request-client-status-accepted',
        },
      },
      {
        path: "accepted",
        name: "customer-service-request-client-status-accepted",
        component: () => import(/* webpackChunkName: "CustomerServiceRequestClientStatusList" */ "@/views/management/views/request-client-status/components/RequestClientStatusList.vue"),
        meta: {
          pageTitle: "Request Client Status",
          breadcrumb: [
            {
              text: "Accepted",
              active: true,
            },
          ],
          type: 3,
          RequestClientStatusPending: 'customer-service-request-client-status-pending',
          RequestClientStatusRejected: 'customer-service-request-client-status-rejected',
          RequestClientStatusAccepted: 'customer-service-request-client-status-accepted',
        },
      },
    ],
  },
]
