import ApplicationsService from "../services/applications.service";
import ClientConnectionService from '@/views/ce-digital/sub-modules/connection/views/clients/services/connection.service'
import store from "@/store";

const state = {
  fields: [
    { key: "name_client", label: "Client", visible: true },
    { key: "seen", label: "Seen", visible: true, thClass: "text-center" },
    { key: "name_advisor", label: "Advisor", visible: true, class: "text-center" },
    { key: "created_at", label: "Specialist", sortable: true, visible: true },
    {
      key: "name_charge",
      label: "Type",
      class: "text-center",
      visible: true,
    },
    {
      key: "num_responsible",
      label: "Responsible",
      class: "text-center",
      visible: true,
    },
    { key: "status", label: "Status", thClass: "text-center", visible: true },
    { key: "form", label: "Form", class: "text-center", visible: true },
    {
      key: "status_result",
      label: "Result",
      thClass: "text-center",
      visible: true,
    },
    {
      key: "bank_info",
      label: "Bank info",
      class: "text-center",
      visible: true,
    },
    {
      key: 'minimum_amount',
      label: 'Minimum amount',
      class: 'text-center',
      visible: true,
    },
    {
      key: "status_paid",
      label: "Paid",
      thClass: "text-center",
      visible: true,
      class: "text-center",
    },
    {
      key: "amount", label: "Amount", thClass: "text-right",
      thStyle: { width: "8%" }, visible: true
    },
    {
      key: "verification",
      label: "Verification",
      class: "text-center",
      visible: true,
    },
    {
      key: "binnacle",
      label: "Binnacle",
      class: "text-center",
      visible: true,
    },
    {
      key: "tracking",
      label: "Tracking",
      thClass: "text-center",
      visible: true,
    },
    {
      key: "app_status",
      label: "Deleted",
      class: "text-center",
      thStyle: { textAlign: "center" },
      visible: true,
    },
    {
      key: "actions",
      label: "Actions",
      thStyle: { textAlign: "center" },
      visible: true,
    },
  ],
  fieldsVerification: [
    { key: "file_name", label: "File Name", thClass: "text-left" },
    { key: "file_size", label: "Size", thClass: "text-left" },
    { key: "upload_by", label: "Upload By", thClass: "text-left" },
    { key: "actions", label: "Actions", thClass: "text-center" },
  ],
  fieldsTracking: [
    { key: "created_at", label: "Date", thStyle: { textAlign: "center" } },
    { key: "name_user", label: "User", thStyle: { textAlign: "center" } },
    { key: "app_action", label: "Action", thStyle: { textAlign: "center" } },
    {
      key: "comment",
      label: "Observation",
      tdClass: "w-25",
      thStyle: { textAlign: "center" },
    },
    { key: "files", label: "Files", thStyle: { textAlign: "center" } },
  ],
  advisors: [],
  countries: [],
  banks: [],
  features: [],
  program_id: 0,
  agentsConnection: [],
  pendingApplications: 0,
};
const mutations = {
  getAdvisors(state, params) {
    state.advisors = [...params];
  },
  getFieldsCompleted(state) {
    state.fields[state.fields.length - 1].visible = false;
  },
  getFieldsInProcess(state) {
    state.fields[state.fields.length - 1].visible = true;
  },
  fieldsForSpecialistsDigital(state) {
    state.fields[1].label = "Agent";
  },
  getCountries(state, params) {
    state.countries = [...params];
  },
  getBanks(state, params) {
    let data = [];
    params.map((el) => {
      data.push({ value: el.id, text: el.name_bank });
    });
    state.banks = [...data];
  },
  getFeatures(state, params) {
    let data = [
      /* { value: null, text: 'Pick some ' } */
    ];
    data = params;

    state.features = [...data];
  },
  insertProgramId(state, id) {
    state.program_id = id;
  },
  M_GET_AGENTS_CONNECTION(state, agents) {

    state.agentsConnection = agents
  },
  M_PENDING_APPLICATIONS(state, payload) {
    state.pendingApplications = payload;
  }
};
const actions = {
  async getAdvisorsAction(context, params) {
    let result = await ApplicationsService.getUsersProgramNcr(params);
    let data = [];
    if (result.status == 200) {
      result.data.map((item) => {
        data.push({ id: item.id, name: item.user_name });
      });
    }

    context.commit("getAdvisors", data);
  },
  async getCountriesAction(context) {
    let data = await ApplicationsService.getCountries();
    context.commit("getCountries", data.data);
  },
  async getBanksAction(context, params) {
    let data = await ApplicationsService.getBanks(params);
    context.commit("getBanks", data.data);
  },
  async getFeaturesAction(context) {
    let data = await ApplicationsService.getFeatures();
    context.commit("getFeatures", data.data);
  },
  async A_GET_AGENTS_CONNECTION({ commit }) {

    const agents = await ClientConnectionService.getAgentsInRelationship()
    commit('M_GET_AGENTS_CONNECTION', agents)

  },
  async A_GET_PENDING_APPLICATIONS({ commit }, moduleId) {
    const programs = {
      5: 4,
      7: 2,
      6: 3,
      20: 3,
      21: 3,
      22: 3,
    };
    const { role_id, modul_id, program_id, user_id } = store.state.auth.currentUser;
    let programm = [11, 25, 28, 29, 30].includes(modul_id)
      ? null
      : program_id || programs[moduleId];
    let advisorId =
      [1, 2, 17].includes(role_id) ||
        (role_id === 11 && modul_id === 6) ||
        [11, 25, 28, 29, 30].includes(moduleId)
        ? null
        : user_id;

    const routesModules = {
      5: "debt-solution-applications",
      6: "credit-experts-applications",
      7: "boost-credit-applications",
      20: "connection-applications",
      22: "customerservice-applications",
      29: "financial-approval-applications",
    };
    const params = {
      user_id,
      role_id,
      program_id: programm,
      specialist_id: null,
      module_id: modul_id,
      advisor_id: advisorId,
    };
    const data = await ApplicationsService.getCountPending(params);
    const count = modul_id === 29 && data > 99 ? '+99' : data;
    commit("M_PENDING_APPLICATIONS", count);

    const payload = {
      routeName: routesModules[modul_id],
      tag: count,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  },
};
const getters = {
  countries(state) {
    return state.countries;
  },
  banks(state) {
    return state.banks;
  },
  features(state) {
    return state.features;
  },
  program(state) {
    return state.program_id;
  },
  G_AGENTS_CONNECTION({ agentsConnection }) {
    const agents = []

    agentsConnection.map(el => {
      agents.push({
        name: el.agent_name,
        id: el.agent_id,
        user_name: el.agent_name,
      })
    })
    return agents
  },
  G_PENDING_APPLICATIONS(state) {
    return state.pendingApplications;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
