export default [
  {
    path: "loans",
    name: "loans-crm",

    redirect: "/crm/loans/my-loans",
    component: () => import(/* webpackChunkName: "CrmLoansComponent" */ "@/views/commons/components/loans/LoansComponent.vue"),
    children: [
      {
        path: "my-loans",
        name: "my-loans-crm",
        component: () => import(/* webpackChunkName: "CrmMyLoansView" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 1,
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "My Loans",
              active: true,
            },
          ],
          route: "crm",
        },
      },
      {
        path: "loans-module",
        name: "loans-module-crm",
        component: () => import(/* webpackChunkName: "CrmLoansCrmView" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 2,
          route: "crm",
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "Loans By Module",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      tab: 1,
      route: "crm",
    },
  },
];
