<template>
  <div class="emoji-container">
    <div class="emoji-categories">
      <div
        class="emoji"
        v-for="(category, index) in emojis.emojiscategories"
        :key="`emoji-category-${index}`"
        :class="{
          'current-emoji-category': categorySelected == category.slug,
        }"
        style=""
        @click="categorySelected = category.slug"
      >
        {{ category.character }}
      </div>
    </div>
    <div class="emojis-list">
      <span
        v-for="(emoji, index2) in emojis.emojis.filter(
          (emoji) => emoji.group == categorySelected
        )"
        :key="`emoji-${index2}`"
        @click="addEmoji(emoji)"
        >{{ emoji.character }}
      </span>
    </div>
  </div>
</template>
<script>
import Emojis from "@/views/commons/components/customer-tickets/data/emojis.json";

export default {
  data() {
    return {
      emojis: Emojis,
      categorySelected: "smileys-emotion",
    };
  },
  methods: {
    addEmoji(emoji) {
      this.$emit("addEmoji", emoji.character);
    },
  },
};
</script>
<style lang="scss" scoped>
.emoji-container {
  user-select: none;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  .emoji-categories {
    display: flex;
    flex-direction: row;
  }
  .emoji {
    font-size: 25px;
    cursor: pointer;
    border: 1px solid #f1f1f1;
    padding: 5px;
    margin: 5px;
  }
  .current-emoji-category {
    background-color: #f2f3f4;
  }
  .emojis-list {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    overflow-y: scroll;
    gap: 2px;
    height: 200px;
    span {
      cursor: pointer;
      font-size: 20px;
      text-align: center;
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
}

.dark-layout {
  .emoji-container {
    background-color: #09132c;
  }
  .emoji {
    border: 1px solid #09132c;
    &:active {
      background-color: #0b213f;
    }
    &:hover {
      background-color: #09132c;
    }
  }
  .emojis-list {
    span {
      &:hover {
        background-color: #0b213f;
      }
    }
  }
  .current-emoji-category {
    background-color: #0b213f;
  }
}
</style>
