export default [
  // {
  //   label: 'Select',
  //   key: 'select',
  //   thClass: 'text-center mx-0 px-0',
  //   tdClass: 'justify-content-center',
  // },
  {
    key: 'searched_for',
    label: 'SEARCHED FOR',
    sortable: true,
    sortDirection: 'desc',
  },
  {
    key: 'last_search_performed',
    label: 'LAST SEARCH PERFORMED',
    sortable: true,
    sortDirection: 'desc',
  },
  // {
  //   key: "card_number",
  //   label: "CREDIT CARD",
  // },
  {
    key: 'client_transaction_id',
    label: 'CLIENTS SOUGHT',
  },
];
