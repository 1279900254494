export default [
  {
    path: "motives",
    name: "settings-motives",
    redirect: { name: "settings-motives-list" },
    component: () => import(/* webpackChunkName: "CEDSettingsNotes" */ "@/views/ce-digital/sub-modules/settings/views/motives/Motives.vue"),
    meta: {
      route: "settings",
      permittedRoles: [1, 2, 17],
      breadcrumb: [
        {
          text: "Settings",
          active: true
        }
      ]
    },
    children: [
      {
        path: "",
        name: "settings-motives-list",
        redirect: { name: "settings-motives-application" },
        component: () => import(/* webpackChunkName: "CEDSettingsNotesList" */ "@/views/ce-digital/sub-modules/settings/views/motives/components/MotivesList.vue"),
        meta: {
          route: "settings",
          permittedRoles: [1, 2, 17],
          pageTitle: "Motives",
          breadcrumb: [
            {
              text: "List",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "application-motives",
            name: "settings-motives-application",
            component: () => import(/* webpackChunkName: "CEDSettingsNotesList" */ "@/views/ce-digital/sub-modules/settings/views/motives/components/ApplicationsMotives.vue"),
            meta: {
              route: "settings",
              permittedRoles: [1, 2, 17],
              pageTitle: "Motives",
              breadcrumb: [
                {
                  text: "List",
                  active: true,
                },
              ],
            },
          },
        ],
      },
    ],
  },
];
