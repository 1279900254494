import Vue from 'vue';
import store from '@/store';

const NotifyPostulantChief = () => {
  window.socket.bind('event-notify-approve-postulant-to-chief', async data => {
    const user = store.state.auth.currentUser.user_id;
    if (user === data.chiefId) {
      await Vue.prototype.$amgAlert({
        title: 'Postulant Pending to Approve',
        icon: 'BellIcon',
        subtitle: 'From HUMAN TALENT',
        data: [
          {
            icon: 'userIcon',
            title: `Postulant Name: ${data.postulantName}`,
            description: `Commentary: ${data.comentary}`,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }
  });
};
export default NotifyPostulantChief;
