export const canNavigate = (to, roles, eng_soft) =>
  to.matched.some((route) => {
    if (route.meta.permittedRoles) {
      if (route.meta.permittedRoles.length > 0) {
        const userModuls = roles.map((role) => role.module_id);
        if (!userModuls.includes(to.matched[0]?.meta?.module)) return false;
        const roleId = roles.filter(
          (role) => role.module_id === to.matched[0]?.meta?.module
        )[0].role_id;
        const levelId = roles.filter(
          (role) => role.module_id === to.matched[0]?.meta?.module
        )[0].typesenior;
        for (let i = 0; i < roles.length; i++) {
          if (roles[i].module_id == to.matched[0]?.meta?.module) {
            return (
              roles[i].module_id == to.matched[0]?.meta?.module &&
              (to.meta.hasLevelValidation
                ? to.meta.permittedRoles.some(
                    (permittedRole) =>
                      permittedRole.role_id == roleId &&
                      (permittedRole.hasLevelValidation
                        ? permittedRole.level_id == levelId
                        : true)
                  )
                : to.meta.permittedRoles.includes(roleId))
            );
          }
        }
      } else {
        const userModuls = roles.map((role) => role.module_id);
        if (!userModuls.includes(to.matched[0]?.meta?.module)) return false;
        if (route.meta.eng_soft && eng_soft === 1) {
          return true;
        }
        return false;
      }
    } else if (
      route.name === "amg-messages" ||
      route.name === "login-old-soft" ||
      route.name === "log-reader" ||
      route.name === "log-reader-nest" ||
      route.name === "setting-upload" ||
      route.name === "users-my-profile" ||
      route.name === "ui-feather" ||
      route.name === "ui-tabler" ||
      route.name === "user-attendance-dashboard" ||
      route.name === "projects-ceo" ||
      route.name === "review-projects-ceo" ||
      route.name === "customer-tickets-ceo" ||
      route.name === "debt-solution-dashboard-general" ||
      route.name === "paragon-dashboard-general" ||
      route.name === "bussiness-dashboard-general" ||
      route.name === "boost-credit-dashboard-general" ||
      route.name === "credit-experts-dashboard-general" ||
      route.name === "ce-customer-service-dashboard-general" ||
      route.name === "court-info-dashboard-general" ||
      route.name === "tax-research-dashboard-general" ||
      route.name === "bookeeping-dashboard-general" ||
      route.name === "specialists-dashboard-general" ||
      route.name === "ticket-customer-lead-show"
    ) {
      return true;
    } else if (route.name === "employee-dashboard") {
      const userModuls = roles.map((role) => role.module_id);
      const exists = userModuls.find((element) =>
        to.matched[0]?.meta?.notPermittedRoles.includes(element)
      );
      if (typeof exists === "undefined") return true;
      return false;
    } else if (
      route.name === "amg-messages" ||
      route.name === "login-old-soft" ||
      route.name === "log-reader" ||
      route.name === "log-reader-nest" ||
      route.name === "setting-upload" ||
      route.name === "users-my-profile" ||
      route.name === "ui-feather" ||
      route.name === "ui-tabler" ||
      route.name === "user-attendance-dashboard" ||
      route.name === "projects-ceo" ||
      route.name === "review-projects-ceo" ||
      route.name === "customer-tickets-ceo" ||
      route.name === "debt-solution-dashboard-general" ||
      route.name === "paragon-dashboard-general" ||
      route.name === "bussiness-dashboard-general" ||
      route.name === "boost-credit-dashboard-general" ||
      route.name === "credit-experts-dashboard-general" ||
      route.name === "ce-customer-service-dashboard-general" ||
      route.name === "court-info-dashboard-general" ||
      route.name === "tax-research-dashboard-general" ||
      route.name === "bookeeping-dashboard-general" ||
      route.name === "specialists-dashboard-general" ||
      route.name === "ticket-customer-lead-show"
    ) {
      return true;
    } else if (route.name === "employee-dashboard") {
      const userModuls = roles.map((role) => role.module_id);
      const exists = userModuls.find((element) =>
        to.matched[0]?.meta?.notPermittedRoles.includes(element)
      );
      if (typeof exists === "undefined") return true;
      return false;
    } else if (route.name === "amg-menu") {
      const userModuls = roles.map((role) => role.module_id);
      const exists = userModuls.find((element) =>
        to.matched[0]?.meta?.notPermittedRoles.includes(element)
      );
      if (typeof exists === "undefined") return false;
      return true;
    } else if (route.name === "ceo-dashboard") {
      const userModuls = roles.map((role) => role.module_id);
      const exists = userModuls.find((element) =>
        to.matched[0]?.meta?.notPermittedRoles.includes(element)
      );
      if (typeof exists === "undefined") return false;
      return true;
    } else {
      const userModuls = roles.map((role) => role.module_id);
      if (!userModuls.includes(to.matched[0]?.meta?.module)) return false;
      return true;
    }
  });
export const _ = undefined;
