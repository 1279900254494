/* eslint-disable camelcase */
import store from '@/store';

const ChangeStatusUser = () => {
  window.socket.bind('change-status-user-event', async data => {
    const { user_id } = store.state.auth.currentUser;
    const permittedUserscustomerTickets = store.getters['NotificationStore/G_USERS_AVAILABILITY_CUSTOMER_TICKETS'];
    let status = null;

    if (data.marksCount === 2) status = 7;
    if (data.marksCount === 3) status = 1;

    if (user_id === data.idUser && user_id !== 1 && data.marksCount > 1 && data.marksCount < 4) {
      if (store.state.auth.currentUser.arrRoles != null) {
        if ((store.state.auth.currentUser.arrRoles.find(rol => (rol.role_id === 2 || rol.role_id === 1 || rol.role_id === 6) && rol.module_id !== 24 && rol.user_id !== 38))
          || (permittedUserscustomerTickets.includes(user_id))) {
          const params = [{
            status_session: status,
          }];

          store.commit(
            'UserStore/SET_USER_STATUS_SESSION_SUB_MODULE',
            params,
          );

          store.commit(
            'UserStore/SET_USER_STATUS_SESSION',
            params,
          );
        }
      }
    }
  });
};

export default ChangeStatusUser;
