export default [
  {
    path: "settings",
    name: "settings-financial-approval",
    component: () =>
      import(
        "@/views/specialists/sub-modules/financial-approval/views/settings/Settings.vue"
      ),

    redirect: { name: "manual" },
    meta: {
      pageTitle: "Settings",
      parentModule: 11,
      module: 28,
      permittedRoles: [1, 2, 17],
      moduleId: 28,
      breadcrumb: [
        {
          text: "Settings",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "manual",
        name: "manual",
        component: () =>
          import(
            "@/views/specialists/sub-modules/financial-approval/views/settings/views/Manual.vue"
          ),
        meta: {
          permittedRoles: [1, 2, 17],
          type: "settings",
          tab: 1,
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Ncr provider manual",
              active: true,
            },
          ],
        },
      },
      {
        path: "automatic",
        name: "automatic",
        component: () =>
          import(
            "@/views/specialists/sub-modules/financial-approval/views/settings/views/Automatic.vue"
          ),
        meta: {
          permittedRoles: [1, 2, 17],
          type: "settings",
          tab: 2,
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Ncr provider automatic",
              active: true,
            },
          ],
        },
      },
      {
        path: "rates",
        name: "rates",
        component: () =>
          import(
            "@/views/specialists/sub-modules/financial-approval/views/settings/views/Rates.vue"
          ),
        meta: {
          permittedRoles: [1, 2, 17],
          type: "settings",
          tab: 3,
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Rates",
              active: true,
            },
          ],
        },
      },
      {
        path: "questionnaire",
        name: "questionnaire",
        component: () =>
          import(
            "@/views/specialists/sub-modules/financial-approval/views/settings/views/Questionnaire.vue"
          ),
        meta: {
          permittedRoles: [1, 2, 17],
          type: "settings",
          tab: 6,
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Questionnaire",
              active: true,
            },
          ],
        },
      },
      {
        path: 'services',
        name: 'spfa-settings-commissions',
        component: () => import('@/views/specialists/sub-modules/financial-approval/views/settings/views/commissions/Commissions.vue'),
        meta: {
          permittedRoles: [1, 17],
          pageTitle: 'Settings',
          breadcrumb: [
            {
              text: 'Services',
              active: true,
            },
          ],
        },
      },
    ],
  },
];

