<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    :title="titleAddCharge"
    @hidden="closeModal"
  >
    <validation-observer ref="form">
      <template v-if="edit">
        <b-row>
          <b-col lg="6" class="mt-1">
            <validation-provider name="PROGRAM">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >PROGRAM
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="client.program_name"
                  :disabled="true"
                />
              </b-input-group> </validation-provider
          ></b-col>
          <b-col lg="6" class="mt-1">
            <validation-provider name="CLIENT">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >CLIENT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="client.client_name"
                  :disabled="true"
                />
              </b-input-group> </validation-provider
          ></b-col>
        </b-row>

        <b-row>
          <b-col lg="6" class="mt-1">
            <validation-provider name="CONCEPT">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >CONCEPT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <span
                  v-if="finishedLoading"
                  style="'border-color:rgba(255, 255, 255, 0.4)'; "
                  :style="
                    isDarkSkin
                      ? 'background: #17171A;opacity: 0.3;color:white'
                      : 'background: #efefef'
                  "
                  class="form-control"
                  >{{ description }}</span
                >
              </b-input-group>
            </validation-provider>
            <validation-provider
              v-if="valDescription"
              v-slot="{ errors }"
              name="OTHERS"
              rules="required"
            >
              <b-input-group class="mt-1">
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >OTHERS
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <b-input
                  v-if="finishedLoading"
                  v-model="notcharges"
                  :state="errors[0] ? false : null"
                  placeholder="Specify"
                >
                  Specify
                </b-input>
              </b-input-group>
              <label v-if="errors[0]" class="text-danger"
                >Specify {{ errors[0] }}</label
              >
            </validation-provider>
          </b-col>
          <b-col v-if="validateCreditor" lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="CREDITOR"
              rules="required"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >CREDITOR
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-select
                  v-if="finishedLoading"
                  v-model="creditorSelect"
                  :state="errors[0] ? false : null"
                  value-field="id"
                >
                  <b-form-select-option
                    v-for="item in creditors"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{
                      item.creditor_name + ("(" + item.account + ")")
                    }}</b-form-select-option
                  >
                </b-form-select>
              </b-input-group>
            </validation-provider></b-col
          >
          <b-col lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="AMOUNT"
              rules="money-2"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >AMOUNT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <money
                  v-if="finishedLoading"
                  id="input-1a"
                  v-model="ammount"
                  :style="{
                    borderColor:
                      errors[0] === 'The amount must be greater than 0.00' &&
                      validador
                        ? '#fc424a'
                        : isDarkSkin
                        ? '#d8d6de'
                        : 'rgb(217 209 209)',
                    opacity: isDarkSkin
                      ? errors[0] === 'The amount must be greater than 0.00' &&
                        validador
                        ? '1'
                        : '0.3'
                      : '1',
                  }"
                  v-bind="maskMoney"
                  :disabled="true"
                  class="form-control"
                />
              </b-input-group>
              <label v-if="errors[0]" class="text-danger">
                {{ errors[0] }}
              </label>
            </validation-provider></b-col
          >
        </b-row>
      </template>
      <template v-else>
        <b-row>
          <b-col lg="6" class="mt-1">
            <validation-provider name="PROGRAM">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >PROGRAM
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="client.program_name"
                  :disabled="true"
                />
              </b-input-group> </validation-provider
          ></b-col>
          <b-col lg="6" class="mt-1">
            <validation-provider name="CLIENT">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >CLIENT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  v-model="client.client_name"
                  :disabled="true"
                />
              </b-input-group> </validation-provider
          ></b-col>
        </b-row>

        <b-row>
          <b-col lg="6" class="mt-1">
            <validation-provider name="CONCEPT">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >CONCEPT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <span
                  v-if="finishedLoading"
                  style="'border-color:rgba(255, 255, 255, 0.4)'; "
                  :style="
                    isDarkSkin
                      ? 'background: #17171A;opacity: 0.3;color:white'
                      : 'background: #efefef'
                  "
                  class="form-control"
                  >{{ description }}</span
                >
              </b-input-group>
            </validation-provider>
            <validation-provider
              v-if="valDescription"
              v-slot="{ errors }"
              name="OTHERS"
              rules="required"
            >
              <b-input-group class="mt-1">
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >OTHERS
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <b-input
                  v-if="finishedLoading"
                  v-model="notcharges"
                  :state="errors[0] ? false : null"
                  placeholder="Specify"
                >
                  Specify
                </b-input>
              </b-input-group>
              <label v-if="errors[0]" class="text-danger"
                >Specify {{ errors[0] }}</label
              >
            </validation-provider>
          </b-col>
          <b-col v-if="validateCreditor" lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="CREDITOR"
              rules="required"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >CREDITOR
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-select
                  v-if="finishedLoading"
                  v-model="creditorSelect"
                  :state="errors[0] ? false : null"
                  value-field="id"
                >
                  <b-form-select-option
                    v-for="item in creditors"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{
                      item.creditor_name + ("(" + item.account + ")")
                    }}</b-form-select-option
                  >
                </b-form-select>
              </b-input-group>
            </validation-provider></b-col
          >
          <b-col lg="6" class="mt-1">
            <validation-provider
              v-slot="{ errors }"
              name="AMOUNT"
              rules="money-2"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >AMOUNT
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <money
                  v-if="finishedLoading"
                  id="input-1a"
                  v-model="ammount"
                  :style="{
                    borderColor:
                      errors[0] === 'The amount must be greater than 0.00' &&
                      validador
                        ? '#fc424a'
                        : isDarkSkin
                        ? '#d8d6de'
                        : 'rgb(217 209 209)',
                    opacity: isDarkSkin
                      ? errors[0] === 'The amount must be greater than 0.00' &&
                        validador
                        ? '1'
                        : '0.3'
                      : '1',
                  }"
                  v-bind="maskMoney"
                  :disabled="true"
                  class="form-control"
                />
                <label v-if="errors[0]" class="text-danger">
                  {{ errors[0] }}
                </label>
              </b-input-group>
            </validation-provider></b-col
          >
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <validation-provider
              v-slot="{ errors }"
              name="Payment_Method"
              rules="required"
            >
              <b-form-group label="Payment Method">
                <b-form-radio-group
                  @change="type_payment = null"
                  v-model="method_payment"
                  :state="errors[0] ? false : null"
                  :options="optionsPaymentMethod"
                  name="radio-payment"
                />
                <label v-if="errors[0]" class="text-danger"
                  >Payment Method {{ errors[0] }}</label
                >
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row v-if="method_payment === 1 || method_payment === 2">
          <b-col md="6">
            <b-form-group v-slot="{ ariaDescribedby }" label="Type Payment">
              <b-form-radio-group
                v-model="type_payment"
                :options="optionsTypePayment"
                :aria-describedby="ariaDescribedby"
                name="radio-inline"
              />
            </b-form-group>
          </b-col>

          <b-col v-if="method_payment === 2" lg="6">
            <validation-provider
              v-slot="{ errors }"
              name="AMOUNT_PARTIAL"
              rules="money-2"
            >
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >AMOUNT PARTIAL
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <money
                  id="input-1"
                  v-model="ammount_partial"
                  :style="{
                    borderColor:
                      errors[0] === 'The amount must be greater than 0.00' &&
                      validador
                        ? '#fc424a'
                        : isDarkSkin
                        ? '#d8d6de'
                        : 'rgb(217 209 209)',
                    opacity: isDarkSkin
                      ? errors[0] === 'The amount must be greater than 0.00' &&
                        validador
                        ? '1'
                        : '0.3'
                      : '1',
                  }"
                  v-bind="maskMoney"
                  class="form-control"
                />
              </b-input-group>
              <label v-if="errors[0]" class="text-danger">
                {{ errors[0] }}
              </label>
            </validation-provider></b-col
          >
        </b-row>
        <b-row>
          <b-col v-if="type_payment === 2 && method_payment !== 3" md="6">
            <validation-provider name="TYPE">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >TYPE
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />

                <b-select
                  v-if="finishedLoading"
                  v-model="types"
                  :options="optionsType"
                  value-field="value"
                  text-field="name"
                />
              </b-input-group> </validation-provider
          ></b-col>

          <b-col v-if="type_payment === 2 && method_payment !== 3" md="6">
            <validation-provider name="PROGRAM">
              <b-input-group>
                <template #prepend>
                  <b-input-group-text class="title_lable"
                    >SERIAL NUMBER
                  </b-input-group-text>
                </template>
                <b-skeleton
                  v-if="!finishedLoading"
                  class="space"
                  type="input"
                />
                <b-form-input v-if="finishedLoading" v-model="specify" />
              </b-input-group> </validation-provider
          ></b-col>
        </b-row>
        <b-row v-if="type_payment === 1">
          <b-col>
            <b-card no-body>
              <b-table
                ref="refCards"
                small
                show-empty
                sticky-header="50vh"
                :busy="isBusy"
                :fields="fields"
                :items="searchCards"
                class="font-small-3 text-center"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template v-slot:cell(radio)="data">
                  <b-form-radio
                    v-model="card_id"
                    :disabled="data.item.count_declined >= 2"
                    :value="data.item.id"
                    :name="'card' + data.item.id"
                    @change="changeCard(data.item.type_card)"
                  />
                </template>

                <template v-slot:cell(card_holder_name)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.cardholdername }}
                  </p>
                </template>
                <template v-slot:cell(card_number)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }}
                  </p>
                </template>
                <template v-slot:cell(type)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.type_card }}
                  </p>
                </template>
                <template v-slot:cell(mm)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.card_expi_month }}
                  </p>
                </template>
                <template v-slot:cell(yy)="data">
                  <p class="mb-0 font-weight-bold">
                    {{ data.item.card_expi_year }}
                  </p>
                </template>
                <template v-slot:cell(cvc)="data">
                  <p class="mb-0 font-weight-bold">
                    {{
                      data.item.cardsecuritycode != null
                        ? data.item.cardsecuritycode.length == 3
                          ? "XX" + data.item.cardsecuritycode.substr(2)
                          : "XXX" + data.item.cardsecuritycode.substr(3)
                        : ""
                    }}
                  </p>
                </template>
                <template v-slot:cell(created_by)="data">
                  <p class="mb-0 font-weight-bold">
                    <span>{{ data.item.created_by }}</span>
                    <br />
                    <span>{{ data.item.created_at | myGlobalDay }}</span>
                  </p>
                </template>
                <template v-slot:cell(actions)="data">
                  <feather-icon
                    size="16"
                    icon="TrashIcon"
                    class="cursor-pointer text-danger"
                    @click="openDeleteCreditCard(data.item.id)"
                  />
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>

        <b-row v-if="type_payment === 1">
          <b-col>
            <div class="d-flex justify-content-end">
              <b-button variant="success" @click="openCreateCreditCard">
                ADD</b-button
              >
            </div>
          </b-col>
        </b-row></template
      >
    </validation-observer>

    <delete-credit-card
      v-if="deleteCreditOn"
      :card_id="card_id"
      :lead_id="client.lead_id"
      @refresh="refresh"
      @close="closeDeleteCreditCard"
    />

    <template #modal-footer>
      <b-button
        v-if="edit"
        class="d-flex justify-content-center"
        variant="primary"
        @click="editCharge"
      >
        <b-spinner v-if="spinnerOn" small />
        EDIT
      </b-button>
      <b-button
        v-else
        class="d-flex justify-content-center"
        variant="primary"
        @click="insertCharge"
      >
        <b-spinner v-if="spinnerOn" small />
        PAY
      </b-button>
    </template>

    <create-credit-card
      v-if="createCreditOn"
      :lead="client.lead_id"
      @reload="refresh"
      @close="closeCreateCreditCard"
    />
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters } from "vuex";
import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services";

import dataFields from "@/views/commons/components/clients/dashboard/options/pay-module/modals/fields.data";
import DeleteCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/DeleteCreditCard.vue";
import CreateCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue";
import FinancialCommissionsService from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service";

export default {
  components: {
    DeleteCreditCard,
    CreateCreditCard,
  },
  props: {
    dataCharge: {
      type: Object,
      default: null,
    },
    edit: {
      type: Object,
      default: null,
    },
    titleAddCharge: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      t_id: null,
      createCreditOn: false,
      creditorSelect: "",
      notcharges: null,
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
        maxlength: 11,
      },
      types: null,
      type_card: "",
      spinnerOn: false,
      isBusy: false,
      cantcards: [],
      card_id: null,
      fields: dataFields,
      specify: "",
      type_payment: null,
      method_payment: null,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      finishedLoading: false,
      typecharges: [],
      valDescription: false,
      description: null,
      amount_charge: null,
      validateCreditor: false,
      optionsPaymentMethod: [
        { text: "Direct Payment", value: 1, disabled: false },
        { text: "Parcial", value: 2, disabled: false },
        { text: "Fee Charge", value: 3, disabled: false },
      ],
      optionsTypePayment: [
        { text: "Credit Card", value: 1, disabled: false },
        { text: "Others", value: 2, disabled: false },
      ],
      descriptionName: null,
      optionsType: [
        { name: "Cash", value: 1 },
        { name: "Check", value: 2 },
        { name: "Money Order", value: 3 },
        { name: "Cashier Check", value: 4 },
      ],
      deleteCreditOn: false,
      ammount: 0,
      ammount_partial: 0,
      validarMoney: false,
      creditors: [],

      //
      errorConcept: false,
      errorAmmount: false,
      errorPayment: false,
      errorType: false,
      errorCard: false,
      errorType2: false,
      errorSerial: false,
      errorAmmountPartial: false,
      errorConceptSpecify: false,
      errosAutorize: [],
      messageAutorize: [],
      errosList: false,
      messageList: false,
      responseCode: "",
      validador: false,
    };
  },
  created() {},

  async mounted() {
    this.ownControl = true;

    if (this.dataCharge) {
      await this.editChargeInfo0();
    }
    await this.search();

    await this.getCreditors();
    this.finishedLoading = true;
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
    }),
    modul_id() {
      return this.currentUser.modul_id;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },

  watch: {
    description(oldVal) {
      const myVal = this.typecharges.find((element) => {
        if (element.id === oldVal) {
          return element.description ? element.description : null;
        }
      });

      this.descriptionName = myVal ? myVal.description : null;

      if (this.description === 5) {
        this.valDescription = true;
      } else {
        this.valDescription = false;
      }

      if (
        this.description === 15 ||
        this.description === 16 ||
        this.description === 17
      ) {
        this.validateCreditor = true;
      } else {
        this.validateCreditor = false;
      }
    },
    dataCharge: {
      handler(value) {
        if (value.ncr_request_id) {
          this.optionsPaymentMethod.forEach((item) => {
            if (item.value != 1) {
              item.disabled = true;
            }
          });

          this.optionsTypePayment.forEach((item) => {
            if (item.value != 1) {
              item.disabled = true;
            }
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async editChargeInfo0() {
      this.description = this.dataCharge.fee;
      this.ammount = this.dataCharge.amount;
      this.t_id = this.dataCharge.id
        ? this.dataCharge.id
        : this.dataCharge.t_id;
    },

    closeCreateCreditCard() {
      this.createCreditOn = false;
    },
    openCreateCreditCard() {
      this.createCreditOn = true;
    },
    refresh() {
      this.$refs.refCards.refresh();
    },
    changeCard(id) {
      this.type_card = id;
    },
    async search() {
      try {
        const data =
          await ClientsOptionsServices.getTypeChargesDevSolutionClients(
            this.$route.params.idClient || this.client.id,
            this.modul_id
          );
        this.typecharges = data.data;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    closeModal() {
      this.$emit("close");
    },

    openDeleteCreditCard(id) {
      this.card_id = id;
      this.deleteCreditOn = true;
    },
    closeDeleteCreditCard() {
      this.deleteCreditOn = false;
    },

    async searchCards() {
      try {
        const data = await ClientsOptionsServices.searchcards({
          id: this.client.lead_id,
        });
        this.cantcards = data.data;

        return this.cantcards;
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },

    // eslint-disable-next-line consistent-return
    async getCreditors() {
      try {
        const data = await ClientsOptionsServices.getCreditors({
          id: this.$route.params.idClient || this.client.id,
        });
        this.creditors = data.data;

        return this.creditors;
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
    // eslint-disable-next-line consistent-return
    async insertCharge() {
      const response = await this.showConfirmSwal();
      if (response.isConfirmed) {
        this.validador = true;
        const result = await this.$refs.form.validate();

        try {
          this.addPreloader();
          if (result) {
            this.spinnerOn = true;
            const params = {
              id: this.$route.params.idClient || this.client.id,
              account: this.client.account,
              concept: 1,
              ammount: this.ammount.toString(),
              ammount_partial:
                this.method_payment === 1
                  ? (this.ammount =
                      this.ammount === 0 ? "" : this.ammount.toString())
                  : (this.ammount_partial =
                      this.ammount_partial === 0
                        ? ""
                        : this.ammount_partial.toString()),
              method_payment: this.method_payment.toString(),
              card_id: this.card_id ? this.card_id : (this.card_id = ""),
              type_payment: this.type_payment
                ? this.type_payment.toString()
                : (this.type_payment = ""),
              type:
                this.type_payment !== 1
                  ? (this.types = this.types ? this.types.toString() : "")
                  : 0,
              specify: this.specify,
              user_id: this.currentUser.user_id,
              notcharges: this.notcharges ? this.notcharges : "",

              programid: this.client.program_id,
              type_process: 1,
              id_charge: this.t_id,
              merchant: this.type_card,
              ncr_request_id:this.dataCharge.ncr_request_id
            };
            const data = await ClientsOptionsServices.saveCharge(params);

            if (data.status === 200 && data.data.status === "200") {
              if (data.data.transaction.messages) {
                this.spinnerOn = false;
                const { responseCode } = data.data.transaction;
                if (responseCode === "1") {
                  if (
                    this.moduleId === 22 &&
                    (this.isAgent ||
                      this.isAssistantSupervisor ||
                      this.isSupervisor)
                  ) {
                    await FinancialCommissionsService.createCommissionChargesCed(
                      {
                        chargeId: 1,
                        description: this.description,
                        base_amount: this.ammount,
                        agent_id: this.currentUser.user_id,
                        client_account:
                          this.$route.params.idClient || this.client.id,
                        module_id: this.moduleId,
                        slug: this.moduleId === 20 ? "con-ncr" : null,
                      }
                    );
                  } else if (this.moduleId === 20 && this.isAgent) {
                    await ClientsOptionsServices.generateComission({
                      amount: this.ammount,
                      client_account_id:
                        this.$route.params.idClient || this.client.id,
                      slug: this.moduleId === 20 ? "con-ncr" : null,
                    });
                  }
                  this.showSuccessSwal(
                    data.data.transaction.messages.message[0].description
                  );
                  this.closeModal();
                }
                if (responseCode === "2") {
                  this.showToast(
                    "danger",
                    "top-right",
                    data.data.transaction.messages.message[0].description,
                    "XIcon"
                  );
                }
                if (responseCode === "3") {
                  this.showToast(
                    "danger",
                    "top-right",
                    data.data.transaction.messages.message[0].description,
                    "XIcon"
                  );
                }
                if (responseCode === "4") {
                  this.showToast(
                    "danger",
                    "top-right",
                    data.data.transaction.messages.message[0].description,
                    "XIcon"
                  );
                }
                if (responseCode === "") {
                  this.showConfirmSwal(
                    data.data.transaction.messages.message[0].description
                  );
                  this.closeModal();
                }
              } else {
                this.showSuccessSwal();
                this.closeModal();
              }
            }
            if (data.status === 200 && data.data.status === "500") {
              if (data.data.transaction.errors) {
                const errorsLengt = data.data.transaction.errors.error.length;
                for (let i = 0; i < errorsLengt; i++) {
                  this.spinnerOn = false;

                  this.showToast(
                    "danger",
                    "top-right",
                    data.data.transaction.errors.error[i].errorText,
                    "XIcon",
                    "Invalid"
                  );
                }
              }
            }
            if (this.type_payment === 1) {
              this.ammount = 0;
              this.card_id = null;
              this.$refs.refCards.refresh();
            }
            this.$emit("refresh");
          }
        } catch (e) {
          this.spinnerOn = false;
          return [];
        } finally {
          this.removePreloader();
        }
      }
    },
  },
};
</script>

<style scoped>
.space {
  width: 63%;
}
.title_lable {
  width: 150px;
}
</style>
