import { amgApi } from "@/service/axios";
// General services on module MetaMedia after refactoring
class DebtSolutionService {
  async getAdvisors(id) {
    try {
      return await amgApi.get(`/commons/get-users-by-module/${id}`);
    } catch (error) {
      console.log("Error settlementsmade: getAdvisorName");
    }
  }
  async getCreditorsByName(text) {
    try {
      return await amgApi.post(
        `/sales-made/debt-solution/search-creditors?q=${text}`
      );
    } catch (error) {
      console.log("Error PriorityOffersService: getCreditorsByName");
    }
  }
  async notificationOldQquestionnaire(params) {
    try {
      const data = await amgApi.post("debt-solution/notification-old-questionnaire",
        params);
      return data;
    } catch (error) {
      console.log("Error notificationOldQquestionnaire");
    }
  }

  async updateAlternativeContact(params) {
    try {
      const data = await amgApi.post("commons/update-alternative-contact-data", params);
      return data;
    } catch (err) {
      console.error("Error updateAlternativeContact", err);
    }
  }

  async getAlternativeDataTracking(params) {
    try {
      const data = await amgApi.post("commons/get-alternative-contact-data-tracking", params);
      return data;
    } catch (err) {
      console.error("Error getAlternativeDataTracking", err);
    }
  }

  async getCreditorPreview(params) {
    try {
      const data = await amgApi.post("/sales-made/debt-solution/get-creditor-preview", params); //falta cambiar
      return data;
    } catch (error) {
      console.error("Error getCreditorPreview", err);
      throw error;
    }
  }

  async deleteDebtPreview(params) {
    try {
      const data = await amgApi.post("/sales-made/debt-solution/delete-debt-preview", params)
      return data
    } catch (error) {
      console.log("Error in deleteDebtPreview")
    }
  }

  async deleteDebt(params) {
    try {
      const data = await amgApi.post("/sales-made/debt-solution/delete-debt", params)
      return data;
    } catch (error) {
      throw error;
    }
  }

  async UpdateProgramPaymethAndRetainer(params) {
    try {
      const data = await amgApi.post("/sales-made/debt-solution/update-debt-retainer-program-payment", params)
      return data
    } catch (error) {
      console.log("Error in UpdateProgramPaymethAndRetainer")
    }
  }

  async listCreditorsToEvent(body) {
    try {
      const data = await amgApi.post("/crm/event/list-creditors-to-event", body);
    } catch (error) {
      throw error;
    }
  }
  async getCreditorsDS(params) {
    try {
      const { data } = await amgApi.post("/sales-made/debt-solution/search-creditors", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new DebtSolutionService();
