<template>
  <div class="position-relative bot-icon-class">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-robot-face drawing-icon"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="#FFFFFF"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path
        d="M6 5h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2z"
      />
      <path d="M9 16c1 .667 2 1 3 1s2 -.333 3 -1" />
      <path d="M9 7l-1 -4" />
      <path d="M15 7l1 -4" />
      <path d="M9 12v-1" />
      <path d="M15 12v-1" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "20",
    },
  },
};
</script>

<style lang="scss" scoped>
.bot-icon-class {
  .icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
.drawing-icon {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: draw 5s forwards;
  animation-iteration-count: infinite;
  margin-left: -5px;
  margin-top: -2px;
}

.new-icon {
  position: absolute;
}

.badge.badge-up {
  right: -18px !important;
}
}
</style>