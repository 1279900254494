var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('b-modal',{ref:"myModal",attrs:{"modal-class":"modal-primary","modal":"","size":"lg","scrollable":"","header-class":"p-0","header-bg-variant":"transparent border-bottom border-bottom-2","hide-footer":_vm.showNewBody == true ? !_vm.showNewBody : _vm.hideFooter,"centered":""},on:{"hidden":function($event){return _vm.hideModal(false, 0)}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('modal-service-header',{attrs:{"type-modal":_vm.typeModal,"users-services":_vm.usersServices,"programs-all":_vm.programsAll,"header-s":_vm.headerS,"sales":_vm.salesClient,"two-per-row":true},on:{"changeProgram":_vm.changeProgram,"close":function($event){return _vm.hideModal(false, 0)}}})]},proxy:true},{key:"modal-footer",fn:function(ref){return [_c('b-row',{staticClass:"w-100"},[(!_vm.isModalAdd)?_c('b-col',{staticClass:"d-flex align-items-center justify-content-end"},[_c('button-save',{staticClass:"mr-1",on:{"click":function($event){return _vm.saveRates()}}}),_c('button-cancel',{on:{"click":function($event){return _vm.hideModal(false, 0)}}})],1):_vm._e(),(_vm.isModalAdd)?_c('b-col',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){return _vm.saveRates()}}},[_vm._v(" Continue ")])],1):_vm._e()],1)]}}]),model:{value:(_vm.ownControl),callback:function ($$v) {_vm.ownControl=$$v},expression:"ownControl"}},[(_vm.showNewBody == true ? _vm.showNewBody : _vm.hideBody)?_c('b-container',{staticStyle:{"width":"300px"}},[_c('b-row',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-card',{staticClass:"mb-0",attrs:{"header":"FEE","header-bg-variant":"info","header-class":"text-white py-1 font-weight-bolder "}},[_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"\n                      d-flex\n                      align-items-center\n                      justify-content-center\n                      text-success\n                      font-medium-5\n                    ",attrs:{"cols":"2"}},[_vm._v("$")]),_c('b-col',[(
                        [
                          0,
                          null,
                          undefined,
                          19.99,
                          24.99,
                          29.99,
                          34.99,
                          39.99,
                          44.99,
                          49.99,
                          54.99,
                          59.99 ].includes(_vm.fee)
                      )?_c('b-form-select',{staticClass:"text-center",class:{ 'border-danger': errors[0] },attrs:{"disabled":_vm.isModalShow,"options":[
                        19.99, 24.99, 29.99, 34.99, 39.99, 44.99, 49.99,
                        54.99, 59.99 ]},model:{value:(_vm.fee),callback:function ($$v) {_vm.fee=$$v},expression:"fee"}}):_c('money',_vm._b({staticClass:"form-control text-center",class:{ 'border-danger': errors[0] && _vm.validateMoney },attrs:{"disabled":_vm.isModalShow},model:{value:(_vm.fee),callback:function ($$v) {_vm.fee=$$v},expression:"fee"}},'money',{
                        decimal: '.',
                        thousands: ',',
                        prefix: '',
                        precision: 2,
                        masked: false,
                      },false))],1)],1)],1)]}}],null,false,3720888985)})],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }