<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.919 5.82339C13.9326 5.82339 12.9907 5.82339 12.0489 5.82339C11.718 5.82339 11.3934 5.82339 11.0625 5.82339C10.547 5.817 10.2925 5.5677 10.2925 5.02435C10.2861 3.9888 10.2925 2.95964 10.2925 1.92408C10.2925 1.72592 10.2925 1.52137 10.2925 1.29125C10.1843 1.28485 10.0888 1.26568 9.99973 1.26568C7.54967 1.26568 5.0996 1.26568 2.64954 1.26568C2.19134 1.26568 2.0577 1.39991 2.0577 1.85377C2.0577 6.0599 2.0577 10.266 2.0577 14.4786C2.0577 14.9324 2.19771 15.0731 2.64317 15.0731C4.01139 15.0794 5.37961 15.0731 6.74783 15.0794C7.21238 15.0794 7.50512 15.3224 7.51785 15.7059C7.53057 16.0958 7.21875 16.3579 6.74146 16.3579C5.35415 16.3579 3.96048 16.3643 2.57317 16.3579C1.52314 16.3579 0.784942 15.6356 0.784942 14.5745C0.778578 10.2916 0.778578 6.00876 0.791306 1.72592C0.791306 0.703153 1.52314 0 2.54772 0C5.33506 0 8.12877 0 10.9161 0.0063923C11.0879 0.0063923 11.2979 0.0830999 11.4189 0.198161C12.978 1.73231 14.5244 3.28564 16.0644 4.83897C16.1408 4.91568 16.1853 5.05631 16.1917 5.17137C16.2044 5.76586 16.1981 6.36673 16.1981 6.96122C16.1981 7.3895 15.9244 7.68355 15.5553 7.67716C15.1735 7.67076 14.9253 7.3959 14.919 6.95483C14.9126 6.59686 14.919 6.2325 14.919 5.82339ZM11.5907 2.17978C11.5907 3.00438 11.5907 3.76507 11.5907 4.51936C12.3734 4.51936 13.1371 4.51936 13.8562 4.51936C13.118 3.75867 12.3798 2.99799 11.5907 2.17978Z"
      :fill="isDarkTheme ? darkColor : color"
    />
    <path
      d="M13.6082 12.7206C14.4419 13.5644 15.1864 14.3059 15.9183 15.0602C16.0328 15.1753 16.1474 15.3223 16.1919 15.4757C16.2746 15.7697 16.1728 16.0382 15.8992 16.1789C15.6192 16.3259 15.3392 16.2875 15.1101 16.0574C14.4419 15.3926 13.7737 14.7214 13.1055 14.0502C12.9973 13.9415 12.9082 13.8073 12.7555 13.6155C12.0936 14.2995 11.5018 14.9196 10.9036 15.5396C10.7381 15.7058 10.579 15.872 10.4072 16.0318C10.0954 16.3259 9.7072 16.3387 9.44629 16.0638C9.18537 15.7889 9.1981 15.4373 9.50356 15.1305C10.229 14.3954 10.9609 13.6731 11.6863 12.9443C11.7309 12.8996 11.7691 12.8549 11.8582 12.759C11.6227 12.5416 11.3936 12.3371 11.1772 12.1261C10.6554 11.6084 10.1399 11.0906 9.62447 10.5728C9.20446 10.1573 9.15355 9.79934 9.46538 9.4989C9.76448 9.20485 10.1208 9.25599 10.5154 9.65231C11.1836 10.3235 11.8518 10.9883 12.52 11.6595C12.5836 11.7234 12.66 11.7873 12.7809 11.9088C13.0991 11.57 13.4046 11.2376 13.7228 10.9116C14.1619 10.4641 14.6073 10.0231 15.0464 9.57561C15.371 9.25599 15.7528 9.23042 16.0328 9.51168C16.3001 9.78016 16.2683 10.1765 15.9437 10.4961C15.2755 11.1673 14.6073 11.8321 13.9328 12.4969C13.8564 12.58 13.7355 12.6311 13.6082 12.7206Z"
      :fill="isDarkTheme ? darkColor : color"
    />
    <path
      d="M11.5903 2.17969C12.3794 2.99151 13.124 3.75859 13.8558 4.51927C13.1367 4.51927 12.3731 4.51927 11.5903 4.51927C11.5903 3.76498 11.5903 3.01069 11.5903 2.17969Z"
      fill="white"
    />
  </svg>
</template>
  
<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 50,
    },
    height: {
      type: Number,
      required: false,
      default: 50,
    },
    color: {
      type: String,
      required: false,
      default: "#27292E",
    },

    darkColor: {
      type: String,
      required: false,
      default: "#FFFFFF",
    },
  },

  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin == "dark";
    },
  },
};
</script>