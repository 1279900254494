export default {
  namespaced: true,
  state: {
    S_UPDATE_TABLE_NCR_PROGRAMS: false,
  },
  mutations: {
    UPDATE_TABLE_NCR_PROGRAMS(state, params) {
      state.S_UPDATE_TABLE_NCR_PROGRAMS = params;
    },
  },
  actions: {
    A_UPDATE_TABLE_NCR_PROGRAMS({ commit }, data) {
      commit("UPDATE_TABLE_NCR_PROGRAMS", data);
    },
  },
  getters: {
    G_UPDATE_TABLE_NCR_PROGRAMS(state) {
      return state.S_UPDATE_TABLE_NCR_PROGRAMS;
    },
  },
};
