export default [
  {
    path: 'commissions',
    name: 'cedigital-sales-commissions',
    component: () => import('@/views/ce-digital/sub-modules/customer-service/views/commissions/Commissions.vue'),
    meta: {
      module: 6,
      program: 3,
      pageTitle: 'Commissions',
      breadcrumb: [
        {
          text: 'Commissions',
        },
      ],
    },
  },
]
