import Vue from 'vue'
import store from '@/store/index'

const WpRequestNotification = () => {
  window.socket.bind('wp-request-action', async data => {
    // data.to_id
    // data.message
    if (data.to_id == store.state.auth.currentUser.user_id) {
    
    const {isConfirmed,isDismissed} = await Vue.swal.fire({
        icon: "success",
        title: "Work Plan Request Completed",
        text: data.message,
      });

    if(isConfirmed)
        await window.amgApi.post("/commons/close-all-swal", {to_id: data.to_id});


    }
  })
}
export default WpRequestNotification