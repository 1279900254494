export default [
  {
    path: "recomendations",
    name: "recomendations-financial-approval",
    redirect: { name: "recomendations-pending" },
    meta: {
      pageTitle: "Recomendations",
      parentModule: 11,
      module: 28,
      moduleId: 28,
      breadcrumb: [
        {
          text: "Recomendations",
          active: true,
        },
      ],
      routeRecomendationPending: "recomendations-pending",
      routeRecomendationApproved: "recomendations-approved",
      routeRecomendationRejected: "recomendations-rejected",
    },
    component: () => import("@/views/specialists/sub-modules/financial-approval/views/recomendations/views/RecomendationsMain.vue"),
    children: [
      {
        path: "pending",
        name: "recomendations-pending",
        component: () => 
        import(
            "@/views/specialists/sub-modules/financial-approval/views/recomendations/views/RecomendationsPending.vue"
        ),
        meta: {
          pageTitle: "Recomendations",
          tab: 1,
          permittedRoles: [1, 2, 17, 23],
          breadcrumb: [
            {
              text: "Pending",
              active: true,
            },
          ],
          status: 1
        },
      },
      {
        path: "approved",
        name: "recomendations-approved",
        component: () =>
        import("@/views/specialists/sub-modules/financial-approval/views/recomendations/views/RecomendationsApproved.vue"
        ),
        meta: {
            pageTitle: "Recomendations",
            tab:2,
            permittedRoles: [1,2,17,23],
            breadcrumb: [
                {
                    text: "Approved",
                    active: true,
                }
            ],
            status: 2
        }
      },
      {
        path: "rejected",
        name: "recomendations-rejected",
        component: () => 
        import(
            "@/views/specialists/sub-modules/financial-approval/views/recomendations/views/RecomendationsRejected.vue"
        ),
        meta: {
          pageTitle: "Recomendations",
          tab: 1,
          permittedRoles: [1, 2, 17, 23],
          breadcrumb: [
            {
              text: "Rejected",
              active: true,
            },
          ],
          status: 3
        },
      },
    ],
  },
];
