import LettersToClientsRoute from "../views/letters-to-clients/router/lettersToClients.router";
import SchedulesRoute from "../views/schedules/router/schedules.router";
import LettersRoute from "@/views/correspondence/views/letters/letters.router";
import FilePOBoxRoute from "@/views/correspondence/views/file-po-box/file-po-box.router";
import FileManagerRoute from "@/views/correspondence/views/file-manager/file-manager.router";
import MarketingLettersRoute from "../views/marketing-letters/router/marketing-letters.router";
import ProductRequestRoute from "@/views/correspondence/views/request/request.router.js";
import OtherPayments from "@/views/correspondence/views/other-payments/router/other-payments.router";
import CorrespondenceAttendanceRouter from "@/views/correspondence/views/attendance/correspondence-attendance.router";
import EmployeeClaimsRoute from "@/views/correspondence/views/employee-claims/router/employee-claims.router";
import SendingCreditors from "@/views/correspondence/views/sending-creditors/router/sending-creditors.router";
import SendingReports from "@/views/correspondence/views/sending-reports/router/sending-reports.router";
import Settings from "@/views/correspondence/views/settings/router/settings.router";
import CorrespondenceMain from "@/views/correspondence/views/CorrespondenceMain.vue";
//import correspondenceProjectsRouter from "@/views/correspondence/views/projects/correspondence-projects.router";
import CallLogRouter from "@/views/correspondence/views/call-log/call-log.router.js";
import MOFRouter from "@/views/correspondence/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/correspondence/views/recruitment-process/correspondence-recruitment_process.router";
import CustomerTicketsRouter from "@/views/correspondence/views/customer-tickets/correspondence-customer-tickets.router";
import DashboardRouter from '@/views/correspondence/views/dashboard/router/dashboard.router.js';
import ChecksRouter from "@/views/correspondence/views/checks/checks.router.js";


const routes = [
  {
    path: "/correspondence",
    redirect: { name: "correspondence-letters" },
    meta: {
      module: 9,
    },
    component: CorrespondenceMain,
    children: [
      ...LettersRoute,
      ...LettersToClientsRoute,
      ...SchedulesRoute,
      ...FilePOBoxRoute,
      FileManagerRoute,
      ...MarketingLettersRoute,
      ...ProductRequestRoute,
      ...SendingCreditors,
      ...Settings,
      ...OtherPayments,
      ...CorrespondenceAttendanceRouter,
      ...EmployeeClaimsRoute,
      //...correspondenceProjectsRouter,
      ...CallLogRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...CustomerTicketsRouter,
      ...SendingReports,
      ...DashboardRouter,
      ...ChecksRouter,
    ],
  },
];

export default routes;
