<template>
  <b-container class="bg-light-primary pt-1" v-if="weekly == 1">
    <b-row
      class="d-flex align-items-center justify-content-center"
      :style="task.length > 2 ? 'overflow-y: auto; height: 100px;' : ''"
    >
      <b-col
        cols="10"
        class="p-0 d-flex align-items-center justify-content-center flex-column"
      >
        <b-badge
          v-for="t in task"
          :key="t.id"
          :style="`background-color: ${t.color}; border-color: ${t.color}; width: auto !important; height: auto !important;`"
          class="mb-1"
          style="font-size: 17px"
        >
          {{ t.catcher }}
          <div
            title="EDIT"
            style="float: right"
            v-if="[1, 2].includes(currentUser.role_id)"
          >
            <feather-icon
              icon="EditIcon"
              style="width: 20px; height: 20px"
              class="cursor-pointer"
              @click="openMOdalEditAsignFanpage(t)"
            />
          </div>
          <br />
          {{ t.front_time | myTime }} - {{ t.to_time | myTime }}
        </b-badge>
      </b-col>
    </b-row>
  </b-container>

  <b-container
    v-else
    :class="[
      `bg-light-${task.title ? taskColor[task.title] : 'primary'}`,
      'pt-1',
    ]"
    :style="task.done == '0' ? '' : 'opacity: 0.5;'"
  >
    <b-row class="d-flex align-items-center justify-content-center">
      <b-col
        cols="10"
        class="p-0 d-flex align-items-center justify-content-center flex-column"
      >
        <div
          class="
            font-weight-bolder
            d-flex
            align-items-center
            justify-content-start
            mb-1
            w-100
          "
          :style="`color: ${skin === 'dark' ? 'white' : 'black'} !important;`"
        >
          <b-avatar :src="task.seller_image" size="4rem"></b-avatar>
          <span class="ml-2" style="width: 55%">{{
            task.title ? task.title : task.subject
          }}</span>

          <feather-icon
            icon="CheckCircleIcon"
            size="25"
            class="ml-auto cursor-pointer text-dark"
            v-if="
              task.done == '0' &&
              moduleId == 15 &&
              currentUser.user_id == task.seller_id
            "
            @click="(e) => doneTask(e, task.id)"
          />
          <feather-icon
            icon="CheckCircleIcon"
            size="25"
            class="ml-auto cursor-not-allowed text-success"
            v-else-if="
              task.done == '1' &&
              moduleId == 15 &&
              currentUser.user_id == task.seller_id
            "
          />
        </div>
      </b-col>
      <div class="w-100">
        <ul
          class="d-flex flex-column p-0"
          style="
            line-height: 21px !important;
            margin-left: 6px;
            font-size: 1rem !important;
            padding-left: 18.27% !important;
          "
        >
          <li
            v-if="moduleId == 15"
            :style="`color: ${skin === 'dark' ? 'white' : 'black'} !important;`"
          >
            {{ task.seller_name }}
          </li>
          <li
            :style="`color: ${skin === 'dark' ? 'white' : 'black'} !important;`"
          >
            {{ task.lead_mobile }}
          </li>
          <li>
            <router-link
              v-if="moduleId === 12"
              :to="{ path: `/ti/leads/show/${task.lead_id}` }"
              target="_blank"
            >
              {{ task.lead_name }}
            </router-link>
            <router-link
              v-else-if="moduleId === 3"
              :to="{ path: `/bussiness/leads/show/${task.lead_id}` }"
              target="_blank"
            >
              {{ task.lead_name }}
            </router-link>
            <router-link
              v-if="moduleId === 3"
              :to="{ path: `/bussiness/leads/show/${task.lead_id}` }"
              target="_blank"
            >
              {{ task.lead_name }}
            </router-link>
            <router-link
              v-else-if="moduleId === 8"
              :to="{
                path: `/taxresearch/leads/show/${task.lead_id}`,
              }"
              target="_blank"
            >
              {{ task.lead_name }}
            </router-link>
            <router-link
              v-else-if="$route.matched[0].meta.module === 11"
              :to="{
                name: `specialist-lead-show`,
                params: {id : task.lead_id}
              }"
              target="_blank"
            >
              {{ task.lead_name }}
            </router-link>
            <router-link
              v-else
              :to="{ path: `/crm/leads/${task.lead_id}` }"
              target="_blank"
            >
              {{ task.lead_name }}
            </router-link>
          </li>
          <li
            :style="`color: ${skin === 'dark' ? 'white' : 'black'} !important;`"
          >
            <!-- CRM -->
            <template v-if="type == 'appointment'">
              {{ (task.date + " " + task.from) | myHourTime }}
              {{ (task.date + " " + task.to) | myHourTime }}
            </template>
            <template v-else>
              {{ (task.due_date + " " + task.hour) | myHourTime }}
            </template>
            <!-- SN -->
            <!-- {{ (task.due_date + ' ' + task.hour)  | myHourTime }} -->
          </li>
          <li class="text-danger">
            <!-- SN -->
            <!-- {{ (task.real_time + ' ' + task.hour_real_time)  | myHourTime }} ({{ task.state }}) -->
            <!-- CRM -->
            <template v-if="type == 'appointment'">
              {{ task.real_time | myHourTime }} ({{ task.state }})
            </template>
            <template v-else>
              {{ (task.real_time + " " + task.hour_real_time) | myHourTime }}
              ({{ task.state }})
            </template>
          </li>
        </ul>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

import CalendarService from "@/views/crm/services/calendar";
import { number } from "echarts/lib/export";

export default {
  name: "TaskCalendar",
  props: {
    task: {
      type: Object | Array,
      required: true,
    },
    type: {
      type: String,
    },
    weekly: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  data() {
    return {
      taskColor: {
        TEL: "primary",
        CN: "primary",
        Personal: "primary",
        Family: "primary",
        ETC: "primary",
      },
    };
  },
  methods: {
    async doneTask(e, id) {
      e.preventDefault();
      e.stopPropagation();
      const confirm = await this.showConfirmSwal(
        "Are you sure?",
        "You won't be able to revert this!"
      );
      if (confirm.isConfirmed) {
        try {
          const response = await CalendarService.doneTaskById({
            idUser: this.currentUser.user_id,
            idTask: id,
          });
          if (this.isResponseSuccess(response)) {
            this.$emit("emitEventDateChange");
            this.$swal.fire({
              icon: "success",
              title: "Successfully",
            });
          } else {
            this.showToast(
              "warning",
              "top-right",
              "Warning!",
              "AlertTriangleIcon",
              `Something went wrong. ${response.message}`
            );
          }
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
    },
    async openMOdalEditAsignFanpage(t_data) {
      this.$emit("openMOdalEditAsignFanpage", t_data);
    },
  },
};
</script>

<style scoped>
</style>
