export default {
  path: "folders",
  name: "file-mananger-creative",
  component: () =>
    import(
      /* webpackChunkName: "BusinessFileManager" */ "@/views/crm/views/file-mananger/FileMananger.vue"
    ),
  meta: {
    pageTitle: "File Manager",
    breadcrumb: [
      {
        text: "File Manager",
        active: true,
      },
    ],
  },
}
