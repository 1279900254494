export default [
  {
      path: "inquiries",
      name: "analyst-department-inquiries",
      component: () =>
      import(/* webpackChunkName: "inquiriesMain" */ "@/views/commons/components/claims_v2/Inquiries.vue"),
      redirect : { name :"analyst-department-claim-inquiry-pending" },
      children: [
          {
            path: "pendings",
            name: "analyst-department-claim-inquiry-pending",
            component: () =>
              import(/* webpackChunkName: "inquiriesPending" */ "@/views/commons/components/claims_v2/views/claim-inquiries/views/InquiriesPending.vue"),
            meta: {
              pageTitle: "Inquiries",
              route: "analyst-department",
              status: "pending",
              permittedRoles: [1, 2, 17],
              breadcrumb: [
                {
                  text: "Pendings",
                  active: true,
                },
              ],
            },
          },
          {
            path: "solveds",
            name: "analyst-department-claim-inquiry-solved",
            component: () => import(/* webpackChunkName: "inquiriesSolved" */ "@/views/commons/components/claims_v2/views/claim-inquiries/views/InquirySolveds.vue"),
            meta: {
              pageTitle: "Inquiries",
              route: "analyst-department",
              status: "solved",
              permittedRoles: [1, 2, 17],
              breadcrumb: [
                {
                  text: "Solveds",
                  active: true,
                },
              ],
            },
          }
        ],


  }



]
