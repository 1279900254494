

export default [
  {
    path: "notification-app",
    name: "creditexperts-notification-app",
    component: () => import(/* webpackChunkName: "CreditExpertsNotificationApp" */ "@/views/credit-experts/views/notification-app/views/NotificationApp.vue"),
    meta: {
      pageTitle: "Notification Files",
      breadcrumb: [
        {
          text: "Notification Files",
          active: true,
        },
      ],
      is_digital: 1,
    },
  },
];
