import { amgApi } from "@/service/axios";

class PaymentService {
  // Process
  async getCard(params) {
    const data = await amgApi.post(
      "/crm/payment/get-lead-payment-by-id",
      params
    );
    return data.data;
  }

  async subtAutorize(params) {
    const data = await amgApi.post("/authorize/checkout-payment", params);
    return data;
  }

  async searchlead(params) {
    const data = await amgApi.post("/crm/payment/get-lead-by-id", params);
    return data.data;
  }

  // Payment
  async getAllUsers(params) {
    const data = await amgApi.post("/commons/user-module/2", params);
    return data.data;
  }

  // VoidRefundInfo
  async getVoidRefund(params) {
    const data = await amgApi.post("/crm/payment/get-void-refund", params);
    return data.data;
  }

  // Refund
  async voidTransaction(params) {
    const data = await amgApi.post("/authorize/void-transaction", params);
    return data;
  }

  async refundTransaction(params) {
    const data = await amgApi.post("/authorize/refund-transaction", params);
    return data;
  }

  // CrediCard
  async searchcards(params) {
    const data = await amgApi.post("/clients/search-cards-clients", params);
    return data.data;
  }

  // CreateCard
  async getStates(params) {
    const data = await amgApi.get("/commons/get-eeuu-states");
    return data.data;
  }
  // Program Partial Void

  async programPartialVoidTransaction(params) {
    const data = await amgApi.post(
      "/authorize/program-partial-void-transaction",
      params
    );
    return data;
  }

  //Partial Refund
  async refundPartialTransaction(params) {
    const data = await amgApi.post(
      "/authorize/refund-partial-transaction",
      params
    );
    return data;
  }

  async getDailySettlement(params) {
    const data = await amgApi.post("/authorize/get-daily-settlement", params);
    return data;
  }

  async getDailySettlementByMerchant(params) {
    const data = await amgApi.post("/authorize/get-daily-settlement-by-merchant", params);
    return data;
  }

  async getDailySettlementTransactions(params) {
    const data = await amgApi.post(
      "/authorize/get-daily-settlement-transactions",
      params
    );
    return data;
  }

  async getStatusAllTransactions(params) {
    try {
      const data = await amgApi.post(
        "/authorize/get-status-all-transactions",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDailySettlementsPie(params) {
    try {
      const data = await amgApi.post(
        "/authorize/get-daily-settlements-pie",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getHeldForReviewTransactions(params) {
    try {
      const data = await amgApi.post(
        "/authorize/get-held-for-review-transactions",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTransactionModalities(params) {
    try {
      const data = await amgApi.post(
        "/authorize/get-transaction-modalities",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAuthorizeTransactions(params) {
    try {
      const data = await amgApi.post(
        "/authorize/get-authorize-transaction-by-id",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getClientsOrLeadsForProgram(params) {
    try {
      const data = await amgApi.post(
        "/authorize/get-clients-or-leads-for-program",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDailySettlementsPieDetail(params) {
    try {
      const data = await amgApi.post(
        "/authorize/get-daily-settlements-pie-detail",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fixInconsistentAmount(params) {
    try {
      const data = await amgApi.post(
        "/authorize/fix-inconsistent-amount",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async fixAllInconsistentAmount(params) {
    try {
      const data = await amgApi.post(
        "/authorize/fix-all-inconsistent-amount",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async fixOnlySoftTransaction(params) {
    try {
      const data = await amgApi.post(
        "/authorize/fix-only-soft-transaction",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async fixAllOnlySoftTransaction(params) {
    try {
      const data = await amgApi.post(
        "/authorize/fix-all-only-soft-transaction",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async processAuthorizeTransactionManual(params) {
    try {
      const data = await amgApi.post(
        "/authorize/process-authorize-transaction-manual",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTypeCharges(params) {
    try {
      const data = await amgApi.post("/authorize/get-type-charges", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTransactionTypes(params) {
    try {
      const data = await amgApi.post(
        "/authorize/get-transaction-types",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateExpiredTransaction(params) {
    try {
      const data = await amgApi.post(
        "/authorize/update-expired-transaction",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getSales(params) {
    try {
      const data = await amgApi.post("/authorize/get-sales", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getDailyAuthorize(params) {
    try {
      const data = await amgApi.post("/authorize/get-daily-authorize", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async toogleDailyAuthorize(params) {
    try {
      const data = await amgApi.post("/authorize/toogle-daily-authorize", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getDailyAuthorizeReport(params) {
    try {
      const data = await amgApi.post("/authorize/get-daily-authorize-report", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getCounterPendingAuthorizeReport(params) {
    try {
      const data = await amgApi.post("/authorize/get-counter-pending-authorize-report", params);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new PaymentService();
