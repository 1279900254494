

export default [
  {
    path: "quality-call-impression",
    name: "quality-call-impression",
    redirect: { name: "quality-call-impression-all" },
    component: () => import(/* webpackChunkName: "QualityCallImpression" */ "@/views/commons/call-impression/CallImpression.vue"),
    meta: {
      pageTitle: "Call Impression",
      breadcrumb: [
        {
          text: "Call Impression",
          active: true,
        },
      ],  
      permittedRoles: [1, 2, 17],
    },
    children: [
      {
        path: "all",
        name: "quality-call-impression-all",
        component: () => import(/* webpackChunkName: "QualityCallImpressionAll" */ "@/views/commons/call-impression/components/CallImpressionGrid.vue"),
        meta: {
          pageTitle: "Call Impression",
          breadcrumb: [
            {
              text: "all",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 17],
          typeSentiment: 0, 
        }, 
      }, 
    ],
  },
];
