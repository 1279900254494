<template>
  <b-modal
    v-model="onControl"
    size="xmd"
    :title="titleModal"
    no-close-on-backdrop
    title-tag="h3"
    modal-class="modal-primary"
    :hide-footer="!(isModuleDebtSolution || isModuleCRM ) || stateModal == 2 "
   
    @ok.prevent="
      stateModal === 1
        ? $refs.modalCreditorNew.savecreditor()
        : $refs.modalCreditorEdit.saveEditCreditor()
    "
    @hidden="close"
  >
    <modal-add-creditor-new
      v-if="stateModal === 1"
      ref="modalCreditorNew"
      :idevent="isModuleDebtSolution ? client.event_id : eventCrm"
      :state="stateModal"
      :idcreditor="idCreditorToPass"
      :client_id="client.client_id"
      :type_in="1"
      :true-retainer="1"
      @reload="emitReload"
      @click="close"
      @reloadAmountSaved="emitReloadAmountSaved"
    />
    <edit-creditor-component-debt-solution
      v-else-if="isModuleDebtSolution"
      ref="modalCreditorEdit"
      :idcreditor="idCreditorToPass"
      :disableInputs="disableInputs"
      @close="close"
      @reload="emitReload"
      @reloadAmountSaved="emitReloadAmountSaved"
    />
    <edit-creditor-component-general
      v-else
      ref="modalCreditorEdit"
      :idcreditor="idCreditorToPass"
      @close="close"
      @reload="emitReload"
      @reloadAmountSaved="emitReloadAmountSaved"
    />
    <template #modal-footer="{ ok, cancel }">
      <div  v-if="stateModal !== '2'" style="display: flex; justify-content: end; align-items: center">
        <b-button
          variant="primary"
          class="rounded"
          style="border-width: 1px; margin-right: 7px"
          @click="ok()"
          >{{ stateModal === 1 ? "Add" : "Update" }}</b-button
        >
        <b-button variant="secondary" class="rounded mr-2" @click="cancel()">
          Cancel
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ModalAddCreditorNew from "@/views/crm/views/sales-made/components/modals/services/debt-solution/ModalAddCreditorNew.vue";
import EditCreditorComponentGeneral from "@/views/commons/components/clients/dashboard/options/acc-module/components/EditCreditorComponent.vue";
import EditCreditorComponentDebtSolution from "@/views/crm/views/sales-made/components/modals/services/debt-solution/ModalEditCreditorNew.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalAddCreditorNew,
    EditCreditorComponentGeneral,
    EditCreditorComponentDebtSolution,
  },
  props: {
    stateModal: null,
    idCreditorToPass: null,
    eventCrm: null ,
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      onControl: false,
    };
  },
  async created() {
    this.onControl = true;
  },
  methods: {
    close() {
      this.$emit("close");
    },
    emitReload() {
      this.$emit("reload");
    },
    emitReloadAmountSaved() {
      this.$emit("reload");
      this.$emit("reloadAmountSaved");
    },
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS"
    }),
    disableInputs() {
      switch (this.stateModal) {
        case 0:
          return false;
        case 1:
          return false;
        case 2:
          return true;
      }
    },
    titleModal() {
      switch (this.stateModal) {
        case 0:
          return "EDIT CREDITOR";
          break;
        case 1:
          return "ADD CREDITOR";
          break;
        case 2:
          return "SHOW CREDITOR DETAILS";
          break;
      }
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isModuleCRM() {
      return this.moduleId === 2;
    },
    isModuleDebtSolution() {
      return this.moduleId === 5;
    },
  },
};
</script>

<style scoped></style>
