<template>
  <div>
    <!-- Payable TO  -->
    <b-row>
      <b-col cols="12">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group :label="titleVendor">
            <b-input-group>
              <template #append>
                <b-button
                  variant="primary"
                  @click="openModal('PAYABLE')"
                  :disabled="loadingVendor"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </template>
              <v-select
                :disabled="loadingVendor"
                style="flex: 1 !important"
                v-bind="payableExpenseScope.attr"
                v-model="formExpense.payable_expense_id"
                :class="{ 'invalid-select': errors.length > 0 }"
                :loading="loadingVendor"
              ></v-select>
            </b-input-group>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
    <!-- END Payable TO  -->

    <!-- Description  -->
    <b-row>
      <b-col cols="12">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group :label="titleConcept">
            <b-input-group>
              <template #append>
                <b-button
                  variant="primary"
                  @click="openModal('DESCRIPTION')"
                  :disabled="loadingConcept || !formExpense.payable_expense_id"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </template>
              <v-select
                style="flex: 1 !important"
                v-bind="descriptionExpenseScope.attr"
                v-model="formExpense.description_expense_id"
                :class="{ 'invalid-select': errors.length > 0 }"
                :loading="loadingConcept"
                :disabled="!formExpense.payable_expense_id"
              ></v-select>
            </b-input-group>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
    <!-- END Description  -->

    <!-- ADDRESS -->
    <b-row v-if="isCheck">
      <b-col cols="12">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <b-form-group label="Address">
            <b-input-group>
              <template #append>
                <b-button
                  variant="primary"
                  :disabled="!formExpense.payable_expense_id || loadingAddress"
                  @click="openModal('ADDRESS_PAY_TO')"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
              </template>
              <v-select
                style="flex: 1 !important"
                v-bind="payableExpenseAddressesScope.attr"
                v-model="formExpense.payable_expense_address_id"
                :class="{ 'invalid-select': errors.length > 0 }"
                v-on="payableExpenseAddressesScope.events"
                :loading="loadingAddress"
                :disabled="loadingAddress"
              ></v-select>
            </b-input-group>
          </b-form-group>
        </ValidationProvider>
      </b-col>
    </b-row>
    <!-- END ADDRESS -->
    <ModalCreateDescriptionModal
      v-if="modalDescriptionExpense.show"
      :optionsVendor="dataPayableExpense"
      :vendorId="formExpense.payable_expense_id"
      @closing="closeModalOther"
      @refresh="refreshModalOther"
    />
    <ModalCreatePayableModal
      v-if="modalPayableExpense.show"
      @closing="closeModalOther"
      @refresh="refreshModalOther"
      :method="formExpense.method"
    />
    <ModalCreatePayableExpenseAddresses
      v-bind="modalPayableExpenseAddressScope.attr"
      v-on="modalPayableExpenseAddressScope.events"
      v-if="modalPayableExpenseAddresses"
    />
  </div>
</template>
<script>
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";
import ModalCreateDescriptionModal from "../ModalCreateDescriptionModal.vue";
import ModalCreatePayableModal from "../ModalCreatePayableModal.vue";
import ModalCreatePayableExpenseAddresses from "../ModalCreatePayableExpenseAddresses.vue";

export default {
  components: {
    ModalCreateDescriptionModal,
    ModalCreatePayableModal,
    ModalCreatePayableExpenseAddresses,
  },
  name: "SelectorsForm",
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    formExpense: {
      type: Object,
      required: true,
    },
    isCheck: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      typeModal: "",
      modalDescriptionExpense: {
        show: false,
      },
      modalPayableExpense: {
        show: false,
      },
      modalPayableExpenseAddresses: false,
      dataPayableExpenseAddresses: [],
      dataPayableExpense: [],
      dataDescriptionExpense: [],
      loadingVendor: false,
      loadingConcept: false,
      loadingAddress: false,
    };
  },
  computed: {
    openFromDashboardCeo() {
      return this.$route.matched[0].name === "ceo-dashboard";
    },
    moduleId() {
      return this.openFromDashboardCeo ? 1 : this.$route.matched[0].meta.module;
    },
    programId() {
      return this.$route.matched[0].meta?.program;
    },
    titleVendor() {
      let title;
      switch (this.formExpense.method) {
        case "CREDIT_CARD":
          title = "Vendor";
          break;
        case "CHECK":
        case "E-CHECK":
          title = "Pay to";
          break;
        default:
          title = "Vendor";
          break;
      }
      return title;
    },
    titleConcept() {
      let title;
      switch (this.formExpense.method) {
        case "CREDIT_CARD":
          title = "Concept";
          break;
        case "CHECK":
        case "E-CHECK":
          title = "Memo";
          break;
        default:
          title = "Concept";
          break;
      }
      return title;
    },
    descriptionExpenseScope() {
      return {
        attr: {
          options: this.dataDescriptionExpense,
          label: "description",
          reduce: (item) => item.id,
        },
      };
    },
    payableExpenseScope() {
      return {
        attr: {
          options: this.dataPayableExpense,
          label: "description",
          reduce: (item) => item.id,
        },
      };
    },
    payableExpenseAddressesScope() {
      return {
        attr: {
          options: this.dataPayableExpenseAddresses,
          label: "street_address",
          clearable: false,
          reduce: (item) => item.id,
        },
        events: {
          input: (e) => {
            console.log(e);
          },
        },
      };
    },
    modalPayableExpenseAddressScope() {
      return {
        attr: {
          payable_expense_id: this.formExpense.payable_expense_id,
        },
        events: {
          close: (e) => {
            this.modalPayableExpenseAddresses = false;
          },
          refresh: (address) => {
            const { id } = address;
            this.dataPayableExpenseAddresses.push(address);
            this.formExpense.payable_expense_address_id = id;
          },
        },
      };
    },
  },
  watch: {
    "formExpense.method": {
      async handler(value) {
        await this.loadPayableExpense();
        if (this.item) {
          this.formExpense.credit_card_expense_id =
            this.item.credit_card_expense_id;
          this.formExpense.payable_expense_id = this.item.payable_expense_id;
        } else {
          this.formExpense.bank_check_account_id = null;
          this.formExpense.credit_card_expense_id = null;
          this.formExpense.payable_expense_id = null;
          this.formExpense.description_expense_id = null;
        }
      },
      deep: true,
      immediate: true,
    },
    "formExpense.payable_expense_id": {
      async handler(value) {
        this.formExpense.description_expense_id = null;
        this.formExpense.payable_expense_address_id = null;
        if (value) {
          await this.loadDescriptionExpense();
          await this.loadPayableAddresses();
          if (this.item && this.item.payable_expense_id === value) {
            this.formExpense.description_expense_id =
              this.item.description_expense_id;
          }
        } else {
          this.dataPayableExpenseAddresses = [];
          this.dataDescriptionExpense = [];
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openModal(type) {
      this.typeModal = type;
      switch (type) {
        case "DESCRIPTION": {
          this.modalDescriptionExpense.show = true;
          break;
        }
        case "PAYABLE": {
          this.modalPayableExpense.show = true;
          break;
        }
        case "ADDRESS_PAY_TO": {
          this.modalPayableExpenseAddresses = true;
          break;
        }
      }
    },
    async loadPayableExpense() {
      try {
        this.loadingVendor = true;
        this.loadingAddress = true;
        this.loadingConcept = true;
        const data = await DepartmentExpenses.getPayableExpenses({
          module_id: this.moduleId,
          program_id: this.programId,
          method: this.formExpense.method,
        });
        this.dataPayableExpense = data;
      } catch (ex) {
      } finally {
        this.loadingVendor = false;
        this.loadingAddress = false;
        this.loadingConcept = false;
      }
    },
    async loadDescriptionExpense() {
      try {
        this.loadingConcept = true;
        const data = await DepartmentExpenses.getDescriptionsExpenses({
          module_id: this.moduleId,
          program_id: this.programId,
          vendor_id: this.formExpense.payable_expense_id,
        });
        this.dataDescriptionExpense = data;
      } catch (ex) {
      } finally {
        this.loadingConcept = false;
      }
    },
    async loadPayableAddresses() {
      try {
        this.loadingAddress = true;
        const data = await DepartmentExpenses.getPayableExpensesAddresses({
          vendor_id: this.formExpense.payable_expense_id,
        });
        this.dataPayableExpenseAddresses = data;
      } catch (ex) {
      } finally {
        this.loadingAddress = false;
      }
    },
    closeModalOther() {
      switch (this.typeModal) {
        case "DESCRIPTION": {
          this.modalDescriptionExpense.show = false;
          break;
        }
        case "PAYABLE": {
          this.modalPayableExpense.show = false;
          break;
        }
      }
    },
    refreshModalOther(item) {
      switch (this.typeModal) {
        case "DESCRIPTION": {
          this.dataDescriptionExpense.push(item);
          this.formExpense.description_expense_id = item.id;
          break;
        }
        case "PAYABLE": {
          this.formExpense.payable_expense_id = item.id;
          this.dataPayableExpense.push(item);
          break;
        }
      }
    },
  },
};
</script>
