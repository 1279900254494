import store from '@/store';
import Vue from 'vue';

const ticketMessaging = () => {
  window.socket.bind('ticket-messaging', async data => {
    if (data.messageData.sender_id !== store.state.auth.currentUser.user_id) {
      if (store.state.ticketmessagesstore.S_PROJECT_SELECTED === data.projectId) {
        store.dispatch('ticketmessagesstore/A_SET_LAST_MESSAGE', data);
        // store.dispatch('NotificationStore/A_GET_PROJECT_MESSAGE_PENDING', data.messageData);
      } else if (data.messageData.createdBy === store.state.auth.currentUser.user_id) {
        await Vue.prototype.$amgAlert({
          title: 'Paragon Has Sent You A Message',
          subtitle: `From Project ${data.messageData.projectName}`,
          icon: 'CheckIcon',
          data: [
          ],
          ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
        });
      } else if (data.messageData.paragonUserId === store.state.auth.currentUser.user_id) {
        await Vue.prototype.$amgAlert({
          title: 'The Project Creator Has Sent You A Message',
          subtitle: `From Project ${data.messageData.projectName}`,
          icon: 'CheckIcon',
          data: [
          ],
          ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
        });
      }
    }
  });
};

export default ticketMessaging;
