import { amgApi } from "@/service/axios";

class ChargeBackService {
  async chargeBackSearchInProcess(body) {
    try {
      const data = await amgApi.post(
        "/management/charge-back/man-charge-back-search-inprocess",
        body
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
  async chargeBackSearchApproved(body) {
    try {
      const data = await amgApi.post(
        "/management/charge-back/man-charge-back-search-approved",
        body
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
  async getPrograms() {
    try {
      const data = await amgApi.get("/management/charge-back/programs");
      return data.data;
    } catch (error) {
      throw error;
    }
  }
  async getCardTransactions(body) {
    try {
      const data = await amgApi.post(
        "/management/charge-back/get-card-transactions",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async saveChargeBack(body) {
    try {
      const data = await amgApi.post(
        "/management/charge-back/save-man-charge-back",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getTrackingChargeBack(body) {
    try {
      const data = await amgApi.post(
        "/management/charge-back/get-tracking-charge-back",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async setPaid(body) {
    try {
      const data = await amgApi.post(
        "/management/charge-back/man-charge-back/set-paid",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async postManAEvidences(body) {
    try {
      const data = await amgApi.post(
        "/management/charge-back/man-add-evidences",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getCountNotificationsChargeBack(body) {
    try {
      const data = await amgApi.post(
        "/management/charge-back/get-count-notifications-charge-back",
        body
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
}
export default new ChargeBackService();
