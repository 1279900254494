export default [
    {
        path: 'dashboard-claims',
        name: 'dashboard-claims-management',
        component: () => import(/* webpackChunkName: "RrhhEmployeesMain" */ '@/views/management/views/dashboard-claims/MainDashboardClaims.vue'),
        meta: {
            pageTitle: "Dashboard Claims",
            permittedRoles: [1, 2, 17],
            breadcrumb: [{
                text: 'Dashboard Claims',
                active: true,
            }],
            tabOthersClaimsRoute: 'mg-employee-claims-all',
            tabEmployeeClaimListRoute: 'mg-employee-claims-list',
        },
    }
]
