export default [{
  path: 'clients/account/:idClient',
  name: 'bussiness-dashboard-clients',
  redirect: { name: 'bussiness-client-task-notes' },
  component: () => import(/* webpackChunkName: "BusinessDashboardClients" */ '@/views/business/views/clients/dashboard/views/Dashboard.vue'),
  meta: {
    pageTitle: 'Client',
    breadcrumb: [
      {
        text: 'Personal Information',
        active: true,
      },
    ],
    module: 3,
  },
  children: [
    {
      path: 'information',
      name: 'bussiness-client-information',
      component: () => import(/* webpackChunkName: "BusinessClientInformation" */ '@/views/business/views/clients/dashboard/views/components/sections/Information.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'task-notes',
      name: 'bussiness-client-task-notes',
      component: () => import(/* webpackChunkName: "BusinessClientTaskNotes" */ '@/views/business/views/clients/dashboard/views/components/sections/TaskNotes.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'payments',
      name: 'bussiness-client-payments',
      component: () => import(/* webpackChunkName: "BusinessClientPayments" */ '@/views/business/views/clients/dashboard/views/components/sections/Payments.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'files',
      name: 'bussiness-client-o-files',
      component: () => import(/* webpackChunkName: "BusinessClientFiles" */'@/views/business/views/clients/dashboard/views/components/sections/Files.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'access-credentials',
      name: 'bussiness-client-access-credentials',
      component: () => import(/* webpackChunkName: "BusinessClientAccessCredentials" */ '@/views/business/views/clients/dashboard/views/components/sections/AccessCredential.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'call-log',
      name: 'bussiness-client-call-log',
      redirect: { name: "call-log-conversation-bussiness" },
      component: () => import(
        "@/views/commons/components/ring-central/call-log/tabs/ClientTabs.vue"
      ),
      meta: {
        pageTitle: 'Call Log',
        breadcrumb: [
          {
            text: 'Recording',
            active: true,
          },
        ],
        callConversationRoute: 'call-log-conversation-bussiness',
        callNoConversationRoute: 'call-log-not-conversation-bussiness'
      },

      children: [
        {
          path: "conversation",
          name: "call-log-conversation-bussiness",
          component: () =>
            import(
              /* webpackChunkName: "call-log-all" */
              "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
            ),
          meta: {
            pageTitle: "Call log",
            breadcrumb: [
              {
                text: "Internal",
                active: true,
              },
            ],
            isConversation: 1,
          },
        }, {
          path: "no-conversation",
          name: "call-log-not-conversation-bussiness",
          component: () =>
            import(
              /* webpackChunkName: "call-log-all" */
              "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
            ),
          meta: {
            pageTitle: "Call log",
            breadcrumb: [
              {
                text: "Internal",
                active: true,
              },
            ],
            isConversation: 0,
          },
        },
      ]
    },
    {
      path: 'tickets',
      name: 'client-bussiness-tickets',
      component: () => import(/* webpackChunkName: "PRClientsAccountScores" */'@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Tickets',
            active: true,
          },
        ],
      },
    },
  ],

}];
