const { amgApi } = require("@/service/axios");

class InquiryClaimService {

    async getInquiryClaims(params) {
        try {
            const dataset = await amgApi.post("/commons/claims-v2/get-inquiry-claims", params);
            return dataset;

        } catch (error) {
            throw error
        }
    }

    async saveResponseInquiry(params) {
        try {
            const data = await amgApi.post("/commons/claims-v2/save-response-inquiry", params);
            return data.data;
        } catch (error) {
            throw error
        }
    }

    getClaimForIdToInquiry(nClaimId) {
        return amgApi.get(`/commons/claims-v2/get-claim-for-id-to-inquiry/${nClaimId}`);
    }

    async getInquiriesCounter(params) {
        try {
            const data = await amgApi.post("/commons/claims-v2/count-pending-inquiries", params);
            return data.data;
        } catch (error) {
            throw error
        }
    }

    async getInquiryFiles(params) {
        try {
            const data = await amgApi.post("/commons/claims-v2/get-inquiry-files", params);
            return data.data;
        } catch (error) {
            throw error
        }
    }

    async getUsersForInquiries() {
        try {
            const { data } = await amgApi.post("/commons/claims-v2/get-inquiries-users");

            return data;
        } catch (error) {
            throw error
        }
    }

    async getInquiriesByClaim(params) {
        try {
            const data = await amgApi.post("/commons/claims-v2/get-inquiries-by-claim", params);
            return data;
        } catch (error) {
            throw error
        }
    }

    async changeInquiryStatusView(params) {
        try {
            const data = await amgApi.post("/commons/claims-v2/change-inquiry-status-view", params);
            return data;
        } catch (error) {
            throw error
        }
    }
}

export default new InquiryClaimService();