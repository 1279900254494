<template>
  <b-row v-if="isCeo || isSupervisor">
    <b-col xl="6" lg="6" md="6" sm="6" xs="12" cols="12">
      <b-form-group label="How did I spend my money?">
        <v-select
          v-model="option"
          :fields="fields"
          :options="billsOptions"
          :reduce="(value) => value.id"
          label="value"
        />
      </b-form-group>
    </b-col>
    <b-col xl="5" lg="5" md="6" sm="6" cols="12" v-if="option == 5">
      <b-form-group label="Other Bills">
        <b-form-input
          type="text"
          v-model="textBill"
          placeholder="Enter others bills"
        ></b-form-input>
      </b-form-group>
    </b-col>
    <b-col cols="1">
      <b-button
        :disabled="disabledButton()"
        variant="success"
        :class="[centerButton() ? 'center-button' : '']"
        @click="addBill()"
        >+</b-button
      >
    </b-col>

    <b-col cols="12" class="mt-1" v-if="bills.length > 0">
      <h3 class="mb-1">Bills</h3>

      <template v-for="(bill, index) in bills">
        <b-badge variant="primary" class="mr-1" pill :key="index"
          >{{ bill.bill }}
          <feather-icon
            style="top: -5px; right: -5px"
            icon="XIcon"
            size="10"
            class="cursor-pointer"
            @click="deleteBill(index)"
          />
        </b-badge>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    currentBills: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      billsOptions: [
        { id: 1, value: "Cash" },
        { id: 2, value: "Shopping" },
        { id: 3, value: "Emergency" },
        { id: 4, value: "None" },
        { id: 5, value: "Other" },
      ],
      option: null,
      textBill: "",
      bills: this.currentBills === null ? [] : this.currentBills,
      fields: [{ key: "N", label: "#" }, "bill", "actions"],
    };
  },

  computed: {
    ...mapState({
      sizeOfScreem: (state) => state.app.windowWidth,
    }),
  },

  methods: {
    addBill() {
      const index = this.option;
      if (this.bills.find((option) => option.id === index)) {
        return;
      }

      if (index != 5) {
        const bill = this.billsOptions.find((option) => option.id === index);
        this.bills.push({ id: index, bill: bill.value });
        this.option = null;
      } else {
        this.bills.push({
          id: "O" + this.bills.length + 1,
          bill: this.textBill,
          //   _rowVariant: "danger",
        });
        this.textBill = "";
      }

      this.$emit("bills", this.bills);
    },

    deleteBill(id) {
      console.log(id);
      // this.bills = this.bills.filter((option) => option.id != id);
      // remove the index of the array
      this.bills.splice(id, 1);
      this.$emit("bills", this.bills);
    },

    centerButton() {
      return (this.option == 5 && this.sizeOfScreem > 991) ||
        (this.option != 5 && this.sizeOfScreem > 575)
        ? true
        : false;
    },

    disabledButton() {
      if (this.option == "" || this.option == null) {
        return true;
      } else {
        if (this.option == 5) {
          if (this.textBill != "") {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      }
    },
  },

  filters: {
    truncateText(value, length) {
      if (!value) return "";
      if (value.length <= length) return value;
      return value.slice(0, length) + "...";
    },
  },
};
</script>

<style>
.center-button {
  margin-top: 1.9rem;
}
</style>