
export default [
  {
    path: "loans",
    name: "loans-creditexperts",
    redirect: "/creditexperts/loans/my-loans",
    component: () => import(/* webpackChunkName: "CreditExpertsLoans" */ "@/views/commons/components/loans/LoansComponent.vue"),
    children: [
      {
        path: "my-loans",
        name: "my-loans-creditexperts",
        component: () => import(/* webpackChunkName: "CreditExpertsLoansMyLoans" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 1,
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "My Loans",
              active: true,
            },
          ],
          route: "creditexperts",
        },
      },
      {
        path: "loans-module",
        name: "loans-module-creditexperts",
        component: () => import(/* webpackChunkName: "CreditExpertsLoansModule" */ "@/views/commons/components/loans/LoansView.vue"),
        meta: {
          tab: 2,
          route: "creditexperts",
          pageTitle: "Loans",
          breadcrumb: [
            {
              text: "Loans By Module",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      tab: 1,
      route: "creditexperts",
    },
  },
];
