export default [
  // {
  //   key: "selected",
  //   label: "",
  //   sortable: false,
  //   visible: true,
  // },
  {
    key: "client",
    sortable: false,
    label: "Lead",
    visible: true,
    thStyle: { width: "170px" },
  },
  {
    key: "program",
    sortable: false,
    label: "Service",
    visible: true,
    thClass: "text-center",
  },
  {
    key: "captured",
    sortable: false,
    label: "Executives",
    visible: true,
    class: "text-center",
    tdClass: "px-3",
    thStyle: { width: "250px" },
  },
  {
    key: "fee",
    sortable: false,
    label: "Fee ($)",
    visible: true,
    class: "text-center",
  },
  {
    key: "initial_amount",
    sortable: false,
    label: "IP",
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
    thStyle: { width: "125px" },
  },
  {
    key: "contract_fee_status",
    sortable: false,
    label: "CF",
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
    thStyle: { width: "70px" },
  },
  {
    key: "notes_status",
    sortable: false,
    label: "NT",
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
    thStyle: { width: "70px" },
  },
  {
    key: "summary",
    sortable: false,
    label: "Call",
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
    thStyle: { width: "70px" },
  },
  {
    key: "trackings",
    sortable: false,
    label: "TK",
    visible: false,
  },
  {
    key: "files",
    sortable: false,
    label: "FI",
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
    thStyle: { width: "70px" },
  },
  // {
  //   key: "status_enum",
  //   sortable: false,
  //   label: "st",
  //   visible: true,
  // },
  {
    key: "creates",
    sortable: false,
    label: "Date/Time",
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "more",
    sortable: false,
    label: "More",
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "actions",
    sortable: false,
    label: "Action",
    class: "text-center",
    tdClass: "px-1",
    visible: true,
  },

  // {
  //   key: "approved",
  //   sortable: true,
  //   label: "Approved",
  //   visible: true,
  // },
  // {
  //   key: "sms",
  //   sortable: false,
  //   label: "Actions",
  //   visible: true,
  // },
  // {
  //   key: "done",
  //   sortable: false,
  //   label: "Done",
  //   visible: true,
  // },
];
