<template>
  <div>
    <b-modal
      id="crm-sm-modal-initial-payment"
      v-model="ownControl"
      title-class="h3 text-white font-weight-bolder"
      size="1040"
      title="Initial Payment"
      scrollable
      modal-class="modal-primary"
      @hide="hideModal"
    >
      <b-container class="ml-1 pr-3">
        <program-client-header
          :client="initial_payment.nameClient"
          :program="initial_payment.nameProgram"
          class="sticky-top mt-1"
          :class="{
            'bg-background-dark': skin === 'dark',
            'bg-white': skin !== 'dark',
          }"
          no-title
        />
        <b-row>
          <b-col sm="6" cols="12">
            <label>Amount</label>
            <b-input-group prepend="$">
              <money
                v-model="amount"
                class="form-control"
                v-bind="{ decimal: '.', thousands: ',', precision: 2 }"
                :disabled="amount_camp || valorEdit"
              />
            </b-input-group>
          </b-col>
          <b-col sm="6" cols="12">
            <label class="mt-1">Method of Payment</label>
            <b-form-radio-group
              v-model="method"
              :options="[
                { text: 'Credit Card', value: 'credit-card' },
                { text: 'Others', value: 'others' },
              ]"
              class="d-flex"
              :disabled="valorEdit"
            >
            </b-form-radio-group>
          </b-col>
        </b-row>
      </b-container>
      <div
        v-if="
          (method === 'credit-card' &&
            listCards.length === 0 &&
            initial_payment.id_transaction != null) ||
          (method === 'credit-card' && hasASinglePayment)
        "
        class="mt-3 margin-0"
      >
        <b-table
          small
          :fields="fieldsT1"
          class="table-new-customization"
          :items="initial_payment.allcards"
          responsive
        >
          <template v-slot:cell(select)="data">
            <b-form-radio
              :ref="'campo' + data.item.id"
              v-model="cardId"
              :value="data.item.id"
              :disabled="valorEdit"
              plain
            />
          </template>
          <template v-slot:cell(type_card)="data">
            <type-card :type_card="data.item.type_card" />
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
              :disabled="
                (initial_payment.role_id == 1 ||
                  initial_payment.role_id == 2) &&
                initial_payment.modul == 2
                  ? false
                  : true
              "
              class="btn-icon ml-1"
              variant="flat-danger"
              size="sm"
              @click="deleteCard(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </template>
        </b-table>
      </div>
      <div
        v-if="
          method === 'credit-card' &&
          initial_payment.cfeestatus == 0 &&
          !hasASinglePayment
        "
        class="mt-1 margin-0"
      >
        <b-table
          small
          class="table-new-customization"
          :fields="fieldsT2"
          :items="initial_payment.allcards"
          responsive
        >
          <template v-slot:cell(amount)="data">
            <b-input-group style="min-width: 130px !important">
              <money
                :ref="'campo' + data.item.id"
                v-model="data.item.model"
                class="form-control form-control-sm"
                v-bind="{
                  decimal: '.',
                  thousands: ',',
                  precision: 2,
                  prefix: '$  ',
                }"
                maxLength="12"
                :disabled="initial_payment.cfeestatus != 0"
              />
              <b-input-group-append>
                <b-button
                  class="btn-icon"
                  variant="success"
                  :disabled="
                    (((initial_payment.cfeestatus == 0 ? false : true) ||
                      valorEdit) &&
                      initial_payment.programid == 2) ||
                    initial_payment.statusSale == 4 || valorEdit
                  "
                  size="sm"
                  @click="savePayment(data.item.id, 'campo' + data.item.id)"
                >
                  <!-- @click="savePayment(data.item.id, 'campo' + data.item.id)" -->
                  <feather-icon icon="CreditCardIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </template>
          <template v-slot:cell(type_card)="data">
            <type-card :type_card="data.item.type_card" />
          </template>
          <template v-slot:cell(sms)="data">
            <b-form-checkbox :ref="'sms' + data.item.id" :checked="true" />
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
              :disabled="
                (initial_payment.role_id == 1 ||
                  initial_payment.role_id == 2) &&
                initial_payment.modul == 2
                  ? false
                  : true
              "
              class="btn-icon"
              variant="flat-danger"
              size="sm"
              @click="deleteCard(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </template>
        </b-table>
      </div>
      <b-container class="pl-2 pr-2 margin-0 mt-2">
        <other-payment-options
          :amount="amount * 1"
          :isClient="false"
          :idClientOrLead="leadId"
          :paymentSelected="paymentSelected"
          :idProgram="initial_payment.programid"
          :idCurrentUser="currentUser.user_id"
          :idModule="currentUser.modul_id"
          :modalityPayment="initialPayment"
          :saleId="initial_payment.payments.sale_id"
          :disableIp="valorEdit"
          :otherMethod="initial_payment.other_method"
          v-if="method == 'others'"
          ref="otherPaymentOptions"
        ></other-payment-options>
      </b-container>
      <b-container class="pl-2 pr-2 margin-0">
        <b-row
          v-if="method === 'credit-card' && initial_payment.cfeestatus == 0"
          class="align-items-center justify-content-between"
        >
          <b-col class="text-left">
            <b-form-checkbox
              ref="smsgeneral"
              :class="{
                'hide-visible': !(
                  (method === 'credit-card' &&
                    listCards.length === 0 &&
                    initial_payment.idtransaction != null) ||
                  (method === 'credit-card' && hasASinglePayment)
                ),
              }"
            >
              Send SMS
            </b-form-checkbox>
          </b-col>
          <b-col class="text-right">
            <b-button variant="success" size="sm" @click="createCard">
              <feather-icon icon="PlusIcon" class="mr-50" />
              Add
            </b-button>
          </b-col>
        </b-row>
      </b-container>
      <div class="text-left mt-2 ml-2" v-if="method != 'others'">
        <b-button
          :disabled="valorEdit"
          variant="primary"
          size="sm"
          @click="modalRequest = true"
        >
          <feather-icon icon="GitPullRequestIcon" class="mr-50" />
          Request transaction</b-button
        >
      </div>
      <div
        v-if="method === 'credit-card' && listCards.length > 0"
        class="mt-2 margin-0"
      >
        <b-table
          class="table-new-customization"
          :fields="fieldsT3"
          :items="listCards"
          small
          responsive
        >
          <template #cell(status)="data">
            <div
              :class="
                data.item.status_id != 4 && data.item.status_id != 19
                  ? 'cursor-auto'
                  : 'cursor-pointer'
              "
            >
              <small
                :style="`color:${data.item.color}!important`"
                class="text-status"
              >
                <!-- @click="checkoutStatusAuthorize(data.item)" -->
                <feather-icon
                  @click="ApproveOrDeclineHeldTransaction(data.item)"
                  icon="RefreshCcwIcon"
                  style="margin-right: 0.3rem"
                  size="15"
                  v-if="data.item.status_id == 4 || data.item.status_id == 19"
                />
                {{ data.item.status }}
              </small>
            </div>
          </template>
          <template #cell(amount)="data">
            $ {{ data.value | currency }}
          </template>
        </b-table>
      </div>
      <b-row
        v-if="
          valorEdit &&
          method_payment == 1 &&
          initial_payment.valorInitalPayment == 2 &&
          isBeforeUpdateOther
        "
      >
        <b-col>
          <v-select
            v-model="initial_payment.payments.type_payment"
            :options="typesOption"
            label="label"
            :reduce="(value) => value.code"
            :disabled="valorEdit"
          />
        </b-col>
        <b-col v-if="initial_payment.payments.type_payment == 5">
          <b-form-input
            v-model="initial_payment.payments.specify"
            :disabled="valorEdit"
          />
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-row class="d-flex align-items-center justify-content-end w-100">
          <b-button
            v-if="initial_payment.seller_id === currentUser.user_id"
            variant="primary"
            :disabled="condition"
            @click="savePayment"
          >
            Submit
          </b-button>
          <b-button
            v-else-if="
              (valorEdit != true && amount_camp == false) ||
              (valorEdit != true && hasASinglePayment)
            "
            variant="primary"
            :disabled="condition"
            @click="savePayment"
          >
            Submit
          </b-button>
        </b-row>
      </template>
      <delete-card-modal
        v-if="deletecardmodal"
        :if-modal-card="deletecardmodal"
        :card_id="cardId"
        :lead_id="initial_payment.payments.lead_id"
        :session="initial_payment.sessionId"
        @click="deletecardmodal = false"
        @new="addCard"
      />
      <modal-card-create
        v-if="createmodal"
        :if-modal-card="createmodal"
        :idlead="initial_payment.payments.lead_id"
        :session="initial_payment.sessionId"
        @new="addCard"
        @click="closedModalCar"
      />
    </b-modal>
    <b-modal
      id="crm-sm-modal-initial-payment"
      v-model="modalRequest"
      centered
      title-class="h3 text-white font-weight-bolder"
      size="sm"
      title="REQUEST TRANSACTION"
      scrollable
      modal-class="modal-primary"
    >
      <b-container :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'">
        <validation-observer ref="formComposeMessage" tag="form">
          <b-form>
            <b-form-group label="SELECT CARD">
              <validation-provider rules="required" v-slot="{ errors }">
                <b-form-select
                  v-model="requestTransaction.cardId"
                  :state="errors[0] ? false : null"
                  :options="initial_payment.allcards"
                  text-field="cardholdername"
                  value-field="id"
                ></b-form-select>
              </validation-provider>
            </b-form-group>
            <b-form-group label="DATE TIME">
              <validation-provider rules="required" v-slot="{ errors }">
                <kendo-datetimepicker
                  :format="'MM/dd/yyyy HH:mm'"
                  :state="errors[0] ? false : null"
                  v-model="requestTransaction.dateTime"
                  style="background: transparent !important; width: 100%"
                  name="date"
                  id="date"
                >
                </kendo-datetimepicker
              ></validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-container>
      <template #modal-footer>
        <b-button variant="primary" @click="sendRequestTransaction()">
          <feather-icon icon="SendIcon" class="mr-50" />SEND</b-button
        >
      </template>
    </b-modal>
    <LeadAddTransaction
      :lead-id="Number(leadId)"
      :lead-name="initial_payment.nameClient"
      concept="Initital Payment"
      :amount="selectedAmount"
      v-if="showAddLeadTransaction"
      @hidden="showAddLeadTransaction = false"
    />
    <!-- @on-collect="registerTransaction" -->
  </div>
</template>

<script>
import OtherPaymentOptions from "@/views/commons/components/clients/dashboard/options/pay-module/modals/OtherPaymentOptions.vue";
import vSelect from "vue-select";
import CrmService from "@/views/crm/services/crm.service";
import ModalCardCreate from "@/views/crm/views/payments/components/ModalCardCreate.vue";
import DeleteCardModal from "@/views/crm/views/sales-made/components/modals/DeleteCardModal.vue";
import ProgramClientHeader from "@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import LeadService from "@/views/commons/components/lead-programs/service/lead.service.js";
import LeadAddTransaction from "@/views/commons/components/leads/transactions/LeadAddTransaction.vue";
import TypeCard from "@/views/commons/card/TypeCard.vue";

export default {
  name: "InitialPaymentModal",
  components: {
    ProgramClientHeader,
    DeleteCardModal,
    ModalCardCreate,
    vSelect,
    OtherPaymentOptions,
    LeadAddTransaction,
    TypeCard,
  },
  props: {
    modal: {
      type: Object,
      required: true,
    },
    initial_payment: {
      type: Object,
      required: true,
      default: () => ({
        payments: null,
        nameProgram: null,
        nameClient: null,
        type: null,
        editmodal: null,
        statusSale: null,
        sessionId: null,
        valorInitalPayment: null,
        feeprops: null,
        modul: null,
        cfeestatus: null,
        idtransaction: null,
        programid: null,
        allcards: null,
        role_id: null,
        initialPayment: 2,
      }),
    },
  },
  data() {
    return {
      modalRequest: false,
      requestTransaction: {
        cardId: null,
        dateTime: null,
        amount: 0,
      },
      method: "",
      deletecardmodal: false,
      amount: this.initial_payment.payments.amount
        ? this.initial_payment.payments.amount
        : 0,
      // charge: this.initial_payment.payments.charge === 0 ? true : false,
      listCards: [],
      cardId: "",
      typesOption: [
        {
          label: "Cash",
          code: 1,
        },
        {
          label: "Check",
          code: 2,
        },
        {
          label: "Money Order",
          code: 3,
        },
        {
          label: "Cashier Check",
          code: 4,
        },
        {
          label: "Others",
          code: 5,
        },
      ],
      createmodal: false,
      fieldsT1: [
        {
          label: "",
          key: "select",
        },
        {
          label: "Card Holder Name",
          key: "cardholdername",
          thStyle: { minWidth: "140px !important" },
        },
        {
          label: "Card Number",
          key: "cardnumber",
          formatter: (value) => `XXXX-XXXX-XXXX-${value}`,
          thStyle: { minWidth: "160px !important" },
        },
        {
          label: "Type",
          key: "type_card",
        },
        {
          label: "MM",
          key: "card_expi_month",
        },
        {
          label: "YY",
          key: "card_expi_year",
        },
        {
          label: "CVC",
          key: "cardsecuritycode",
          formatter: (value) => `XX${value}`,
        },
        {
          label: "Actions",
          key: "actions",
        },
      ],
      fieldsT2: [
        {
          label: "Amount",
          key: "amount",
        },
        {
          label: "SMS",
          key: "sms",
        },
        {
          label: "Card Holder Name",
          key: "cardholdername",
          thStyle: { minWidth: "135px !important" },
        },
        {
          label: "Card Number",
          key: "cardnumber",
          formatter: (value) => `XXXX-XXXX-XXXX-${value}`,
          thStyle: { minWidth: "152px !important" },
        },
        {
          label: "Type",
          key: "type_card",
        },
        {
          label: "MM",
          key: "card_expi_month",
        },
        {
          label: "YY",
          key: "card_expi_year",
        },
        {
          label: "CVC",
          key: "cardsecuritycode",
          formatter: (value) => `XX${value}`,
        },
        {
          label: "Actions",
          key: "actions",
        },
      ],
      fieldsT3: [
        {
          label: "Transaction ID",
          key: "transaction_id",
          thStyle: { minWidth: "120px !important" },
        },
        {
          label: "Amount",
          key: "amount",
          thStyle: { minWidth: "90px !important" },
        },
        {
          label: "Credit Card",
          key: "card_number",
          thStyle: { minWidth: "110px !important" },
        },
        {
          label: "User",
          key: "user",
          thStyle: { minWidth: "100px !important" },
        },
        {
          label: "Date",
          key: "settlement_date",
          formatter: (value) => this.$options.filters.myGlobalDay(value),
          thStyle: { minWidth: "160px !important" },
        },
        {
          label: "Status",
          key: "status",
        },
      ],
      notApiCards: false,
      reloadTable: false,
      ownControl: false,
      amount2: 0,
      dsSituation: {},
      initialPayment: 2,
      selectedPayment: 0,
      showAddLeadTransaction: false,
    };
  },
  computed: {
    ...mapGetters({
      skin: "appConfig/skin",
      currentUser: "auth/currentUser",
    }),
    isBeforeUpdateOther() {
      return moment().isBefore("2023-05-21");
    },
    valorEdit() {
      const { type, editmodal, statusSale, valorInitalPayment } =
        this.initial_payment;
      return (
        this.currentUser.modul_id == 16 ||
        type == 1 ||
        editmodal == false ||
        statusSale == 2 ||
        statusSale == 4 ||
        valorInitalPayment != 1
      );
    },
    amount_camp() {
      const { programid, type_payment_ce } = this.initial_payment;
      return (
        this.method === "credit-card" ||
        this.method === "" ||
        (programid === 3 && type_payment_ce != null)
      );
    },
    method_payment() {
      return this.method === "credit-card" ? 0 : 1;
    },
    hasASinglePayment() {
      const { programid, type_payment_ce } = this.initial_payment;
      return programid === 2 || (programid === 3 && type_payment_ce != null);
    },
    condition: function () {
      if (this.method == "others") {
        return false;
      } else {
        return !!(
          (this.initial_payment.allcards.length === 0 &&
            this.method === "credit-card") ||
          (!this.cardId && this.method === "credit-card")
        );
      }
    },
    leadId: function () {
      let aux = this.initial_payment.payments.lead_id.toString();
      return aux;
    },
    paymentSelected: function () {
      if (this.method == "credit-card") {
        return 1;
      } else if (this.method == "others") {
        return 2;
      }
    },
  },
  async mounted() {
    this.method =
      this.initial_payment.payments.type_payment == null ||
      this.initial_payment.payments.type_payment == 0
        ? "credit-card"
        : "others";
    await this.getListCards();
    this.initial_payment.allcards.map((val) => (val.model = 0));
    this.ownControl = true;
    await this.getCurrentFinancialAnalysis();
    this.removePreloader();
  },
  methods: {
    createCard() {
      this.createmodal = true;
    },
    closedModalCar() {
      this.createmodal = false;
    },
    deleteCard(id) {
      this.cardId = id;
      this.deletecardmodal = true;
    },
    async getListCards() {
      try {
        this.listCards = await CrmService.getListCards({
          sale_id: this.initial_payment.payments.sale_id,
        });
      } catch (error) {
        this.showToast("danger", "top-right", "Error", "XIcon", error);
        this.modal.initial_payment = false;
      }
    },
    openModalCreateCard() {
      this.modalCard = true;
    },
    closeModalCreateCard() {
      this.modalCard = false;
    },
    addCard(cards) {
      this.initial_payment.allcards = cards;
    },
    async saveOtherPayment() {
      this.addPreloader();
      if (this.amount == null || this.amount == 0) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Invalid amount value"
        );
        this.removePreloader();
        return;
      }
      try {
        const validate =
          await this.$refs.otherPaymentOptions.$refs.formOther.validate();
        if (!validate) {
          this.removePreloader();
          return;
        }
        await this.$refs.otherPaymentOptions.saveOtherPayment();
        this.$emit("close", true);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async savePayment(cardId, ref) {
      if (this.method == "others") {
        await this.saveOtherPayment();
      } else {
        let refCard = ref;
        if (!this.hasASinglePayment) {
          this.cardId = cardId;
        } else {
          refCard = "campo1";
        }
        if (this.method_payment == 0 && !this.hasASinglePayment) {
          this.amount2 = this.$refs[refCard].$el.value;
          this.amount2 = this.amount2.replace(this.$refs[refCard].prefix, "");
        } else {
          this.amount2 = this.amount;
        }
        if (this.amount2 == null) {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Invalid amount value"
          );
        } else if (this.method_payment == null) {
          this.showToast(
            "danger",
            "top-right",
            "Error",
            "XIcon",
            "Method payment invalid"
          );
        } else if (this.method_payment == 0 || this.method_payment == 1) {
          await this.sendValidatePayment(this.method_payment, refCard, cardId);
        }
      }
    },
    async sendValidatePayment(type, refCard, cardId) {
      try {
        let sms = null;
        if (this.method_payment == 0) {
          if (!this.hasASinglePayment) {
            sms = this.$refs[`sms${cardId}`].isChecked;
          } else {
            sms = this.$refs.smsgeneral.isChecked;
          }
        }
        if (type == 0) {
          if (this.amount2 == 0) {
            this.showToast(
              "danger",
              "top-right",
              "Error",
              "XIcon",
              "Invalid amount value"
            );
            return;
          }
          const result = await this.showConfirmSwal();
          if (result.value) {
            this.addPreloader();
            this.sendMessage = true;
            const { data } = await amgApi.post(
              "/note/first-note/save-initial",
              {
                amount: this.amount2.toString(),
                idcard: this.cardId,
                method_payment: this.method_payment.toString(),
                specify: this.initial_payment.payments.specify,
                sale_id: this.initial_payment.payments.sale_id,
                user_id: this.initial_payment.sessionId,
                program_id: this.initial_payment.payments.program_id,
                sendsms: sms === true ? 1 : 0,
                min_ip: this.initial_payment.payments.min_ip,
              }
            );
            if (data) {
              this.removePreloader();
              const title = this.$options.filters.myFontCapitalize(data.status);
              const message = data.message;
              if (data.status == "success") {
                await this.insertTrackingDerivation();
                const res = await this.showSuccessSwal(message, title);
                if (!this.hasASinglePayment)
                  this.$refs[refCard].$el.value = "$ 0.00";
                await this.getListCards();
                this.reloadTable = true;
                if (this.hasASinglePayment) {
                  this.hideModal();
                }
                this.$emit("reload");
              } else {
                this.showErrorSwal(message, title);
                return;
              }
            } else {
              this.removePreloader();
            }
          } else {
            this.removePreloader();
          }
        } else {
          const result = await this.showConfirmSwal();
          if (result.value) {
            this.sendMessage = true;
            this.addPreloader();
            const response = await amgApi.post(
              "/note/first-note/save-initial-others",
              {
                amount: this.amount.toString(),
                idcard: this.cardId,
                method_payment: this.method_payment.toString(),
                specify: this.initial_payment.payments.specify,
                sale_id: this.initial_payment.payments.sale_id,
                user_id: this.initial_payment.sessionId,
                program_id: this.initial_payment.payments.program_id,
              }
            );
            if (response.status === 200) {
              this.removePreloader();
              this.sendMessage = false;
              const res = await this.showSuccessSwal();
              this.reloadTable = true;
              if (res.value) {
                this.hideModal();
              }
            }
            this.$emit("close");
          } else {
            this.removePreloader();
          }
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal(error);
      }
    },

    async checkoutStatusAuthorize(item) {
      if (item.status_id != 4) return;
      this.addPreloader();
      const params = {
        merchant: item.merchant,
        id: item.transaction_id,
        is_inital: 1,
      };
      await amgApi
        .post("/authorize/checkout-payment-status", params)
        .then(async (res) => {
          this.reloadTable = true;
          await this.getListCards();
          if (res.data == 2) {
            this.$swal({
              title: "Oops...",
              text: "The transaction was declined",
              icon: "warning",
            });
          }
          this.removePreloader();
        })
        .catch((error) => {
          this.removePreloader();
        });
    },
    hideModal() {
      this.ownControl = false;
      this.$emit("close", this.reloadTable);
    },
    async sendRequestTransaction() {
      const isValidated = await this.$refs.formComposeMessage.validate();
      if (isValidated) {
        this.addPreloader();
        const params = {
          card_id: this.requestTransaction.cardId,
          datetime: moment(this.requestTransaction.dateTime).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          module_id: this.currentUser.modul_id,
          user_id: this.currentUser.user_id,
          amount: this.requestTransaction.amount,
          sale_id: this.initial_payment.payments.sale_id,
        };
        await amgApi.post("/authorize/send-request-transaction", params);
        this.removePreloader();
        this.modalRequest = false;
      }
    },
    async insertTrackingDerivation() {
      if (this.$route.matched[0].meta.module === 26) {
        try {
          const params = {
            lead_id: this.initial_payment.payments.lead_id,
            sale_status_id: 2,
            seller_id: this.initial_payment.sessionId,
            sale_id: this.initial_payment.payments.sale_id,
          };
          await DerivationsService.insertTrackingSaleStatusDerivation(params);
        } catch (error) {
          console.log(error);
        }
      }
    },

    async getCurrentFinancialAnalysis() {
      try {
        const params = {
          sales_id: this.initial_payment.payments.sale_id,
          event: null,
        };
        const { data } = await LeadService.getCurrentFinancialAnalysis(params);
        if (data.length > 0) {
          this.dsSituation = data[1];
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async openAddLeadTransactionModal(amount) {
      this.selectedAmount = amount;
      this.showAddLeadTransaction = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.hide-visible {
  visibility: hidden;
}

.modal-body {
  padding: 1px 0px !important;
}

.bg-background-dark {
  background-color: #17171a !important;
}

.text-status {
  font-weight: bold;
  text-transform: uppercase;
}
</style>

<style scoped>
.transparent-datetime-kanban:hover {
  background: transparent !important;
}
</style>
