export default [
    {
        path: "settings",
        name: "human-talent-settings",
        component: () => import("@/commons/employee-claims/components/settings/SettingsMain.vue"),
        redirect: { name: 'human-talent-s-type-request' },
        meta: {
            pageTitle: 'Settings',
            module: 17,
            breadcrumb: [
                {
                    text: "Settings",
                    active: true,
                },
            ],
        },
        children: [
            {
                path: "type-request",
                name: "human-talent-s-type-request",
                component: () => import("@/commons/employee-claims/components/settings/components/TypeRequest.vue"),
                meta: {
                    pageTitle: 'Settings',
                    module: 19,
                    breadcrumb: [
                        {
                            text: "Type Request",
                            active: true,
                        },
                    ],
                },
            }
        ]
    }
]