import { amgApi } from "@/service/axios";

class RequestService{

    async getProducts(parameters){
        try{
            const data = await amgApi.post('commons/request/get-products',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }

    async getRequest(parameters){
        try{
            const data = await amgApi.post('logistics/request/get-request',parameters)
            return data
        }catch(error){
            console.log(error)            
        }
    }
    
    async addProductRequest(params){
        try {
            const {data} = await amgApi.post('commons/request/add-request', params)
            return data
        } catch (error) {
            console.log(error)
        }
    }
    
    async sendRequestStatus(params){
        try {
            const {data} = await amgApi.post('logistics/request/edit-request-status', params)
            return data
        } catch (error) {
            console.log(error)
        }
    }
    
    async requestDeleted(params){
        try {
            const {data} = await amgApi.post('logistics/request/request-deleted', params)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    async requestAnnulled(params){
        try {
            const {data} = await amgApi.post('logistics/request/request-annulled', params)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    async requestDetail(params){
        try {
            const {data} = await amgApi.post('logistics/request/request-detail', params)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    async getItemDetail(params){
        try {
            const {data} = await amgApi.post('logistics/request/get-item-detail', params)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    async saveDetail(params){
        try {
            const data = await amgApi.post('logistics/request/save-item-detail', params)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    async getUsersByModule(params){
        try {
            const {data} = await amgApi.post('logistics/request/get-users-by-module', params)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    async saveAsigned(params){
        try {
            const {data} = await amgApi.post('logistics/request/save-asigned-to', params)
            return data
        } catch (error) {
            console.log(error)
        }
        
    }

    async getRequestTracking(params){
        try {
            const {data} = await amgApi.post('logistics/request/get-request-tracking', params)
            return data
        } catch (error) {
            console.log(error)
        }
        
    }

    async saveDate(params){
        try {
            const {data} = await amgApi.post('logistics/request/save-date', params)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    async getCounterNotAsigned(params){
        try {
            const {data} = await amgApi.post('logistics/request/counter-not-asigneds', params)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    async getModules(){
        try {
            const {data} = await amgApi.get('logistics/request/get-modules')
            return data
        } catch (error) {
            console.log(error)
        }
    }
    
}

export default new RequestService