<template>
  <div v-if="items">
    <b-table
      small
      slot="table"
      no-provider-filtering
      ref="refClientsList"
      primary-key="id"
      table-class="text-nowrap"
      responsive="sm"
      show-empty
      sticky-header="30vh"
      :busy="isBusy"
      :tbody-tr-class="setRowClass"
      :fields="fields"
      :items="cards"
      class="custom-table table-new-customization"
    >
      <template #empty>
        <p class="text-center my-2">No cards were found</p>
      </template>
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(Select)="data">
        <ValidationProvider
          name="select"
          :rules="valueOption == '0.00' || valueOption == '0' ? '' : 'required'"
          v-slot="{ errors }"
        >
          <b-form-radio
            name="select"
            class="vs-checkbox-con"
            :class="{ 'border-required': errors[0] }"
            :value="data.item.id"
            @change="$emit('cardId', data.item)"
            v-model="selected"
            plain
            :disabled="checkExpiredCard(data.item.id)"
          >
          </b-form-radio>
        </ValidationProvider>
      </template>
      <template #cell(cardnumber)="data">
        <div :class="classByColumn">
          <span> {{ "XXXX-XXXX-XXXX-" + data.item.cardnumber }} </span>
        </div>
      </template>
      <template #cell(cardsecuritycode)="data">
        <div :class="classByColumn">
          <span>
            {{ "XX" + data.item.cardsecuritycode.substr(2) }}
          </span>
        </div>
      </template>
      <template #cell(created_by)="data">
        <div>
          <span>
            {{ data.item.created_by ? data.item.created_by : "-" }}
          </span>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    cards: {
      type: Array,
    },
    optionName: {
      type: [String, Number],
    },
    disabledCards: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    usedCard: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "Select",
          label: "",
        },
        {
          key: "cardholdername",
          label: "Card Holder Name",
        },
        {
          key: "cardnumber",
          label: "Card Number",
        },
        {
          key: "type_card",
          label: "Type",
        },
        {
          key: "card_expi_month",
          label: "MM",
        },
        {
          key: "card_expi_year",
          label: "YY",
        },
        {
          key: "cardsecuritycode",
          label: "CVC",
        },
        {
          key: "created_by",
          label: "Created by",
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      selected: null,
      valueOption: "",
      items: [],
    };
  },
  computed: {
    classByColumn() {
      return "d-flex flex-column justify-content-start align-items-start";
    },
  },
  created() {
    this.selected = this.usedCard;
  },
  methods: {
    checkExpiredCard(card) {
      return this.disabledCards.includes(card) || false;
    },

    setRowClass(row) {
      if (row) {
        return this.disabledCards.includes(row.id) ? "bg-expired-card-row" : "";
      }
    },

    verifyValidCards() {
      if (this.disabledCards.length == this.cards.length) {
        this.showInfoSwal(
          "All cards are invalid, but now you can send the request without a card",
          "Invalid cards"
        );
        this.selected = 0; // avoid the required rule from vee-validate after all cards are expired
        return;
      }

      if (this.disabledCards.includes(this.selected)) this.selected = null; // restart the v-model to allow required rule from vee-validate after one failed attempt
    },
  },
  watch: {
    optionName(newValue) {
      this.valueOption = newValue;
    },
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
.border-required {
  border: 1px solid red;
}

.bg-expired-card-row {
  background-color: rgb(252, 66, 74);
  color: #fff;
}
</style>
