<template>
  <div>
    <b-table
      id="CreditCardExpensesTable"
      ref="CreditCardExpensesTable"
      small
      show-empty
      sticky-header="50vh"
      class="table-new-customization custom-table font-small-3 justify-content-start blue-scrollbar"
      :items="expensesData"
      :fields="visibleFields"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(expenses_date)="{ item }">
        <div class="text-info">
          <feather-icon icon="CalendarIcon" style="margin-right: 5px" />
          {{ item.expenses_date | myGlobal }}
        </div>
      </template>
      <template #cell(vendor)="{ item }">
        <div class="text-warning">
          {{ item.vendor }}
        </div>
      </template>
      <template #cell(method)="{ item }">
        <template v-if="item.method == 'CREDIT_CARD'">
          <div class="row">
            <div class="col-4 p-0 justify-content-end d-flex">
              <type-card :type_card="item.type_card" />
            </div>
            <div
              class="col-8 p-0 d-flex justify-content-center align-items-center"
            >
              <strong>XXXX-{{ item.cardnumber.slice(-4) }}</strong>
            </div>
          </div>
        </template>
        <template v-else>
          <b-badge variant="primary">{{ item.method }}</b-badge>
        </template>
      </template>
      <template #cell(amount)="{ item }"> $ {{ item.amount }}</template>
      <template #cell(details)="{ item }">
        <span class="d-block">
          <span>
            <strong>Vendor: </strong>
          </span>
          {{ item.vendor }}
        </span>
        <span class="d-block">
          <span>
            <strong>Concept: </strong>
          </span>
          {{ item.concept }}
        </span>
        <span v-if="item.reference_number" class="d-block">
          <span>
            <strong>Ref. number: </strong>
          </span>
          {{ item.reference_number }}
        </span>
        <span v-if="item.url_file" class="d-block">
          <span>
            <strong>Invoice: </strong>
          </span>
          <a :href="item.url_file" target="_blank" :title="item.file">
            <amg-icon
              class="cursor-pointer text-danger"
              icon="FilePdfIcon"
              size="18"
            />
          </a>
        </span>
        <!-- <span class="d-block">Vendor: {{ item.vendor }}</span>
        <span class="d-block">Concept: {{ item.concept }} </span>
        <span v-if="item.reference_number" class="d-block"
          >Ref. number: {{ item.reference_number }}</span
        >
        <span v-if="item.url_file" class="d-block"
          >Invoice:
          <a :href="item.url_file" target="_blank" :title="item.file">
            <amg-icon
              class="cursor-pointer text-danger"
              icon="FilePdfIcon"
              size="18"
            />
          </a>
        </span> -->
      </template>
      <template #cell(created_by)="{ item }">
        <span class="d-block">{{ item.created_by }}</span>
        <span class="d-block">{{ item.created_at | myGlobalWithHour }} </span>
      </template>
      <template #cell(updated_by)="{ item }">
        <!-- <span class="d-block">{{ item.updated_by }}</span> -->
        <div class="d-flex justify-content-start">
          <span class="d-block">{{ item.updated_at | myGlobalWithHour }} </span>
          <feather-icon
            v-if="item.observation && tab == 3"
            icon="InfoIcon"
            v-b-tooltip.hover.top="item.observation"
            variant="info"
            class="text-info"
            style="margin-left: 3px"
          />
        </div>
      </template>
      <template v-if="tab === 2" #bottom-row>
        <b-th> </b-th>
        <b-th class="text-center py-0">
          <div class="m-0 p-1" style="font-weight: 600">TOTAL</div>
        </b-th>
        <b-th class="text-center py-0">
          <div class="m-0 p-1" style="font-weight: 600">
            {{ total | formatMoney }}
          </div>
        </b-th>
        <b-th :colspan="4"> </b-th>
      </template>
    </b-table>
  </div>
</template>
<script>
import DashboardServices from "@/views/commons/components/clients/dashboard/options/pay-module/components/service/dashboard.service.js";
import Fields from "@/views/commons/components/clients/dashboard/options/pay-module/components/credit-cards-expenses.fields.js";
import { mapGetters, mapActions } from "vuex";
import TypeCard from "@/views/commons/card/TypeCard.vue";

export default {
  components: {
    TypeCard,
  },
  props: {
    tab: {
      type: Number,
      required: true,
    },
    caId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fields: Fields,
      expensesData: [],
      total: 0,
      isBusy: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_REFRESH_AFTER_CREATE_EXPENSE:
        "ExpensesRequest/G_REFRESH_AFTER_CREATE_EXPENSE",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  async created() {
    let field = this.fields.find((field) => field.key == "updated_by");
    field.visible = false;
    await this.getExpenses();
  },
  watch: {
    tab: function (value) {
      let field = this.fields.find((field) => field.key == "updated_by");
      if (value == 2) {
        field.visible = true;
        field.label = "Confirmed at";
      } else if (value == 3) {
        field.visible = true;
        field.label = "Rejected at";
      } else {
        field.visible = false;
      }
      this.getExpenses();
    },
    G_REFRESH_AFTER_CREATE_EXPENSE(newVal) {
      if (newVal) {
        this.getExpenses();
        this.refreshAfterCreateExpense(false);
      }
    },
  },
  methods: {
    ...mapActions({
      refreshAfterCreateExpense: "ExpensesRequest/refreshAfterCreateExpense",
    }),
    async getExpenses() {
      const params = {
        tab: this.tab,
        module_id: this.currentUser.modul_id,
        caId: this.caId,
      };
      try {
        this.addPreloader();
        const { data } = await DashboardServices.getCreditCardExpenses(params);
        this.expensesData = data.data;
        this.total = data.total;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.b-table-bottom-row th:nth-child(2) {
  border-radius: 0px 0px 0px 15px !important;
  margin-left: 5px;
  background: #0090e7 !important;
  color: #ffffff !important;
}

.b-table-bottom-row th:nth-child(3) {
  border-radius: 0px 0px 15px 0px !important;
  margin-left: -10px;
  background: #0090e7 !important;
  color: #ffffff !important;
}
</style>
