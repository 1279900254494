import Clients from "@/views/ce-digital/sub-modules/sales/views/clients/view/Clients.vue";
import Grid from "../view/components/Grid.vue";

export default [
  {
    path: "clients",
    name: "sales-clients",
    redirect: { name: "sales-clients-news" },
    component: Clients,
    meta: {
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
      parentModule: 21,
      module: 26,
    },
    children: [
      {
        path: "clients/news",
        component: Grid,
        name: "sales-clients-news",
        meta: {
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "News",
              active: true,
            },
          ],
          parentModule: 21,
          module: 26,
          // Tab
          newsTab: "sales-clients-news",
          tracingTab: "sales-clients-tracing",
          successTab: "sales-clients-success",
          recycledTab: "sales-clients-recycled",
          section: "client"
        },
      },
      {
        path: "clients/tracing",
        component: Grid,
        name: "sales-clients-tracing",
        meta: {
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Tracing",
              active: true,
            },
          ],
          parentModule: 21,
          module: 26,
          // Tab
          newsTab: "sales-clients-news",
          tracingTab: "sales-clients-tracing",
          successTab: "sales-clients-success",
          recycledTab: "sales-clients-recycled",
          section: "client"
        },
      },
      {
        path: "clients/success",
        component: Grid,
        name: "sales-clients-success",
        meta: {
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Success",
              active: true,
            },
          ],
          parentModule: 21,
          module: 26,
          // Tab
          newsTab: "sales-clients-news",
          tracingTab: "sales-clients-tracing",
          successTab: "sales-clients-success",
          recycledTab: "sales-clients-recycled",
          section: "client"
        },
      },
      {
        path: "clients/recycled",
        component: Grid,
        name: "sales-clients-recycled",
        meta: {
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Recycled",
              active: true,
            },
          ],
          parentModule: 21,
          module: 26,
          // Tab
          newsTab: "sales-clients-news",
          tracingTab: "sales-clients-tracing",
          successTab: "sales-clients-success",
          recycledTab: "sales-clients-recycled",
          section: "client"
        },
      },
    ],
  },
];
