export default [
  {
    path: "ncr/realtor",
    name: "connection-ncr-realtor", //debt-solution-ncr-realtor
    component: () =>
      import(
        /* webpackChunkName: "ConnectionNcrRealtor" */
        "@/views/ce-digital/sub-modules/connection/views/ncr-realtor/views/NcrRealtorTemplate"
      ),
    redirect: { name: "connection-ncr-realtor-in-process" },
    meta: {
      pageTitle: "NCR Realtor",
      breadcrumb: [
        {
          text: "NCR Realtor",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "ncr/realtor/in-process",
        name: "connection-ncr-realtor-in-process",
        component: () =>
          import(
            /* webpackChunkName: "ConnectionNcrRealtorInProcess" */
            "@/views/commons/components/ncr-realtor/views/components/TableNcrRealtor.vue"
          ),
        meta: {
          statusNCR: 0,
          module: 20,
          pageTitle: "NCR Realtor",
          breadcrumb: [
            {
              text: "NCR Realtor",
              active: true,
            },
          ],
        },
      },
      {
        path: "ncr/realtor/completed",
        name: "connection-ncr-realtor-completed",
        component: () =>
          import(
            /* webpackChunkName: "ConnectionNcrRealtorCompleted" */
            "@/views/commons/components/ncr-realtor/views/components/TableNcrRealtor.vue"
          ),
        meta: {
          statusNCR: 1,
          module: 20,
          pageTitle: "NCR Realtor",
          breadcrumb: [
            {
              text: "NCR Realtor",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
