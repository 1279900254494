export default [
  {
    path: "leads/show/:id",
    name: "management-lead-show",
    component: () =>
      import(
        /* webpackChunkName: "BCLeadShow" */ "@/views/crm/views/Lead/lead-module/dashboard/default.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      route: "management",
      contentClass: "leads-app",
    },
  },
  {
    path: "leads/report/:idlead/:idfile",
    name: "management-report-lead",
    component: () =>
      import(
        /* webpackChunkName: "BCReportLead" */ "@/views/commons/components/ncr/components/report/ReportLead.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      pendingRoute: "ncr-pending",
      returnedRoute: "ncr-returned",
      completedRoute: "ncr-completed",
      reportLeadRoute: "report-lead",
      leadRoute: "lead-show",
    },
  },
];
