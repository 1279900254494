import SalaryRecordService from "@/views/management/views/salary-record/salary-record.service.js"

const state = {
  S_COUNTER_MANAGEMENT: 0,
  S_COUNTER_RRHH: 0,
}
const getters = {
  G_COUNT_MANAGEMENT() {
    return state.S_COUNTER_MANAGEMENT
  },
  G_COUNT_RRHH() {
    return state.S_COUNTER_RRHH
  },
}
const mutations = {
  M_SET_S_COUNTER_MANAGEMENT(state, val) {
    state.S_COUNTER_MANAGEMENT = val
  },
  M_SET_S_COUNTER_RRHH(state, val) {
    state.S_COUNTER_RRHH = val
  },
}
const actions = {
  async A_GET_COUNTER_SALARY({ commit }) {
    try {
      const { data } = await SalaryRecordService.getCounterRecord()
      commit("M_SET_S_COUNTER_MANAGEMENT", data.management)
      commit("M_SET_S_COUNTER_RRHH", data.rrhh)
    } catch (error) {
      console.log("ERROR_GET_COUNTER_SALARY [ACTION]", error)
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
