<template>
  <b-modal
    v-model="showModal"
    size="default"
    centered
    header-bg-variant="transparent"
    hide-footer
    @hidden="closeModal"
  >
    <template #modal-title>
      <h5
        class="text-uppercase font-weight-bolder"
        style="padding-top: 5px;"
      >
        <feather-icon
          icon="ClipboardIcon"
          size="15"
          style="margin-right: 10px;"
        />
        {{ task.title }}
      </h5>
    </template>
    <b-container
      fluid
      class="pb-1"
    >
      {{ task.description }}
    </b-container>
  </b-modal>
</template>
<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  mounted() {
    this.showModal = true;
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>
