<template>
  <div class="send-to-correspondence-ci">
    <b-row class="py-0">
      <b-col cols="6" class="text-left">
        <span class="document-title font-weight-bolder">Case Summary</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="8" lg="12" md="12" sm="12" cols="12">
        <b-card>
          <b-row>
            <b-col xl="6" lg="6" md="12" sm="12" cols="6">
              <div class="font-weight-bolder">
                <b-badge variant="light-primary">Client Information</b-badge>
              </div>
              <div class="container-information-ci border h-300">
                <div class="item"><b>Name: </b> {{ caseInfo.full_name }}</div>
                <div class="item"><b>Mobile: </b> {{ caseInfo.mobile }}</div>

                <div class="item">
                  <b> {{ caseInfo.program ? "Program" : "Module" }}: </b>
                  {{
                    caseInfo.program ? caseInfo.program : caseInfo.module_name
                  }}
                </div>
                <div class="item" v-if="caseInfo.account">
                  <b>Account: </b> {{ caseInfo.account }}
                </div>
                <div class="item"><b>Email: </b> {{ caseInfo.email }}</div>
                <div class="item">
                  <b>Mailing Address: </b> {{ caseInfo.lead_address }}
                </div>
              </div>
            </b-col>

            <b-col xl="6" lg="6" md="12" sm="12" cols="6">
              <b-badge variant="light-primary">Case Information</b-badge>
              <div class="container-information-ci border h-300">
                <div class="item">
                  <b>Number: </b> {{ caseInfo.case_number }}
                </div>
                <div class="item"><b>Assigned: </b> {{ caseInfo.advisor }}</div>
                <div class="item">
                  <b>Attorney: </b> {{ caseInfo.attorney }}
                </div>
                <div class="item"><b>Court: </b> {{ caseInfo.court_name }}</div>
                <div class="item">
                  <b>Court Mobile: </b> {{ caseInfo.court_number }}
                </div>
                <div class="item">
                  <b>Court Address: </b> {{ caseInfo.address }}
                </div>
              </div>
            </b-col>

            <b-col xl="6" lg="6" md="12" sm="12" cols="6">
              <b-badge variant="light-primary">Creditor Information</b-badge>
              <div class="container-information-ci border he-80">
                <div class="item">
                  <b>Name: </b> {{ caseInfo.creditor_name }}
                </div>
                <div class="item"><b>Debt: </b> {{ caseInfo.balance }}</div>
                <div class="item">
                  <b>Mailing Address: </b>
                  {{ caseInfo.creditor_mailing_address }}
                </div>
              </div>
            </b-col>

            <b-col xl="6" lg="6" md="12" sm="12" cols="6">
              <b-badge variant="light-primary">Send For</b-badge>
              <div class="container-send-ci border he-80">
                <div
                  @click="optionSelected = 1"
                  class="option_correspondence cursor-pointer"
                  :class="optionSelected == 1 ? 'item-active' : ''"
                >
                  <feather-icon icon="AtSignIcon" size="25"></feather-icon>
                  Email
                </div>
                <div
                  @click="optionSelected = 2"
                  class="option_correspondence cursor-pointer"
                  :class="optionSelected == 2 ? 'item-active' : ''"
                >
                  <feather-icon icon="MailIcon" size="25"></feather-icon>
                  Correspon...
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col xl="4" lg="12" md="12" sm="12" cols="12">
        <b-card>
          <div class="d-flex justify-content-between align-items-center">
            <div class="document-title">
              {{ optionSelected == 1 ? "Email Model" : "Letter Model" }}
            </div>
            <div>
              <feather-icon
                v-if="optionSelected == 1"
                icon="RotateCcwIcon"
                size="25"
                v-b-tooltip.hover
                title="Refresh"
                class="icon-badge text-primary cursor-pointer mr-1"
                @click="getAllDocumentsFormat()"
              ></feather-icon>
              <feather-icon
                icon="MonitorIcon"
                size="25"
                v-b-tooltip.hover
                title="Preview"
                @click="showPreviewPdf = true"
                class="container-icon text-primary my-1 icon-badge text-primary cursor-pointer"
              ></feather-icon>
            </div>
          </div>

          <b-skeleton-img
            v-if="loadingModel"
            class="canva-pdf-ci"
          ></b-skeleton-img>
          <div v-else class="container-pdf-send">
            <v-pdf class="canva-pdf-ci" :src="src" />
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-row class="px-2" v-if="smsTemplateSelected">
          <b-col cols="5" offset="7" class="text-right">
            <v-select
              class="custom-vselect-positon"
              v-model="smsTemplateId"
              :options="smsTemplates"
              label="title"
              :reduce="(x) => x.id"
              :clearable="false"
              @input="changeSmsTemplate"
            >
              <template #selected-option="{ title }">
                <span>{{ title | limitChars(15) }} </span>
              </template>
              <template #option="{ title }">
                <span>{{ title | limitChars(15) }} </span>
              </template>
            </v-select>
          </b-col>
          <b-col
            xl="12"
            lg="12"
            md="12"
            sm="12"
            cols="12"
            class="mb-2"
            v-if="caseInfo.language == 'ES'"
          >
            <b-form-group :label="`${smsTemplateSelected.type}`">
              <validation-provider #default="{ errors }" rules="required">
                <quill-editor
                  v-model="smsTemplateSelected.body"
                  :class="{
                    'border-danger': errors[0],
                  }"
                  class="quill-br"
                  :options="editorOption"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-2" v-else>
            <b-form-group :label="`${smsTemplateSelected.type}`">
              <validation-provider #default="{ errors }" rules="required">
                <quill-editor
                  v-model="smsTemplateSelected.en_body"
                  :class="{
                    'border-danger': errors[0],
                  }"
                  class="quill-br"
                  :options="editorOption"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row class="px-2" v-if="emailTemplateSelected">
          <b-col cols="5" offset="7" class="text-right">
            <v-select
              class="custom-vselect-positon"
              v-model="emailTemplateId"
              :options="emailTemplates"
              label="title"
              :reduce="(x) => x.id"
              :clearable="false"
              @input="changeEmailTemplate"
            >
              <template #selected-option="{ title }">
                <span>{{ title | limitChars(15) }} </span>
              </template>
              <template #option="{ title }">
                <span>{{ title | limitChars(15) }} </span>
              </template>
            </v-select>
          </b-col>
          <b-col
            xl="12"
            lg="12"
            md="12"
            sm="12"
            cols="12"
            class="mb-2"
            v-if="caseInfo.language == 'ES'"
          >
            <b-form-group :label="`${emailTemplateSelected.type}`">
              <validation-provider #default="{ errors }" rules="required">
                <quill-editor
                  v-model="emailTemplateSelected.body"
                  :class="{
                    'border-danger': errors[0],
                  }"
                  class="quill-br"
                  :options="editorOption"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col xl="12" lg="12" md="12" sm="12" cols="12" class="mb-2" v-else>
            <b-form-group :label="`${emailTemplateSelected.type} `">
              <validation-provider #default="{ errors }" rules="required">
                <quill-editor
                  v-model="emailTemplateSelected.en_body"
                  :class="{
                    'border-danger': errors[0],
                  }"
                  class="quill-br"
                  :options="editorOption"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <preview-pdf
      v-if="showPreviewPdf"
      :pdf-file="currentPdf"
      :pdf-name="optionSelected == 1 ? 'Email Model' : 'Letter Model'"
      @close="showPreviewPdf = false"
    ></preview-pdf>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VPdf from "vue-pdf";
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";
import DocumentFormatService from "@/views/debt-solution/views/settings/views/documents-config/document-formats/service/document-format.service.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    VPdf,
    PreviewPdf,
    quillEditor,
  },
  props: {
    LeadId: {
      type: [Number, String],
      required: true,
    },
    caseId: {
      type: [Number, String],
      required: true,
    },
    caseTypeDocumentId: {
      type: [Number, String],
      required: true,
    },
    clientAccountId: {
      type: String,
      required: false,
    },
    documentType: {
      type: [String, Number],
      required: false,
    },
    serviceId: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      showPreviewPdf: false,
      optionSelected: 1,
      caseInfo: {},
      options: [
        {
          value: "1",
          text: "EMAIL",
        },
        {
          value: "2",
          text: "CORRESPONDENCE",
        },
      ],
      status: false,
      editorOption: {
        modules: { toolbar: true },
      },
      templates: [],
      emailTemplateSelected: {},
      smsTemplateSelected: {},
      emailTemplateId: null,
      smsTemplateId: null,
      loadingModel: false,
      src: null,
    };
  },
  watch: {
    async optionSelected() {
      this.status = false;
      await this.getAllDocumentsFormat();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    emailTemplates() {
      return this.templates.filter((item) => item.type == "EMAIL");
    },

    smsTemplates() {
      return this.templates.filter((item) => item.type == "SMS");
    },
  },
  methods: {
    changeEmailTemplate(val) {
      this.emailTemplateSelected = this.templates.find(
        (item) => item.id == val
      );
      this.emailTemplateId = val;
    },
    changeSmsTemplate(val) {
      this.smsTemplateSelected = this.templates.find((item) => item.id == val);
      this.smsTemplateId = val;
    },
    async save() {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        try {
          this.addPreloader();
          const params = {
            type: this.optionSelected,
            lead_id: this.LeadId,
            case_type_document: this.caseTypeDocumentId,
            user_id: this.currentUser.user_id,
            client_account: this.clientAccountId,

            emailTextEn: this.emailTemplateSelected.en_body,
            emailTextEs: this.emailTemplateSelected.body,
            email_id: this.emailTemplateSelected.id,

            smsTextEn: this.smsTemplateSelected.en_body,
            smsTextEs: this.smsTemplateSelected.body,
            sms_id: this.smsTemplateSelected.id,
            language: this.caseInfo.language,
          };

          const { data, status } =
            await DocumentFormatService.sendDocumentsToLead(params);
          if (status === 200 && data.status == 1) {
            this.showGenericSuccessSwal({});
            this.removePreloader();
            return true;
          } else {
            this.showInfoSwal(`${data.message}`, "Warning");
            this.removePreloader();
            return false;
          }
        } catch (error) {
          this.showErrorSwal(error.response.data.message, "Error");
        } finally {
          this.removePreloader();
        }

        return false;
      }
    },
    async readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },

    async getAllDocumentsFormat() {
      this.loadingModel = true;
      const params = {
        lead_id: this.LeadId,
        case_id: this.caseId,
        document_type_id: this.caseTypeDocumentId,
        body:
          this.caseInfo.language == "ES"
            ? this.emailTemplateSelected.body
            : this.emailTemplateSelected.en_body,
      };
      try {
        this.addPreloader();
        if (this.optionSelected == 1) {
          this.doc = await DocumentFormatService.getDocumentModelEmail(params);
        } else if (this.optionSelected == 2) {
          this.doc = await DocumentFormatService.getDocumentModelLetter(params);
        }

        if (this.doc) {
          this.currentPdf = this.doc;
          const arrayBuffer = await this.readFileAsArrayBuffer(this.doc);
          this.src = await VPdf.createLoadingTask({ data: arrayBuffer });
          this.status = true;
          this.loadingModel = false;
        }
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
        this.loadingModel = false;
      }
    },

    async getSummary() {
      const params = {
        case_id: this.caseId,
      };

      const { data, status } = await DocumentFormatService.getCaseSummary(
        params
      );
      if (status === 200) {
        this.caseInfo = data.info[0];
      }
    },

    async getTemplateContent() {
      try {
        const params = {
          document_type: this.documentType,
          service_id: this.serviceId,
        };

        const { data } = await DocumentFormatService.getTemplateContent(params);
        this.templates = data;
        this.emailTemplateSelected = data.find((item) => item.type == "EMAIL");
        if (this.emailTemplateSelected) {
          this.emailTemplateId = this.emailTemplateSelected.id;
        }
        this.smsTemplateSelected = data.find((item) => item.type == "SMS");
        if (this.smsTemplateSelected) {
          this.smsTemplateId = this.smsTemplateSelected.id;
        }

        if (
          this.emailTemplateSelected == null ||
          this.smsTemplateSelected == null
        ) {
          this.$emit("blockLastButton");
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
  async mounted() {
    this.isShow = true;
    Promise.all([
      this.getTemplateContent(),
      this.getSummary(),
      this.getAllDocumentsFormat(),
    ]);
  },
};
</script>


<style lang="scss">
.custom-vselect-positon .vs__dropdown-menu {
  right: 0;
  left: auto;
}
.send-to-correspondence-ci {
  .h-300 {
    height: 220px;
  }
  .he-80 {
    height: 100px;
  }
  .document-title {
    font-size: 15px;
  }
  .container-send-ci {
    display: flex;
    justify-content: space-evenly;
    padding: 10px 0;
    border-radius: 10px;
  }

  .container-information-ci {
    padding: 10px 20px;
    border-radius: 10px;
    margin-bottom: 15px;

    .item {
      margin-top: 3px;
    }
  }
  .option_correspondence {
    width: 100px;
    margin: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    border-radius: 10px;
  }

  .item-active {
    background-color: #cfefff;
  }

  .option_correspondence:hover {
    background-color: #cfefff;
  }
}
.dark-layout {
  .option_correspondence {
    background-color: #131314;
  }

  .item-active {
    background-color: #323232;
  }

  .option_correspondence:hover {
    background-color: #323232;
  }
}

.container-pdf-send {
  height: 315px !important;
  canvas {
    height: 315px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .send-to-correspondence-ci {
    .container-information-ci {
      .item {
        margin-top: 1.5px;
        font-size: 12.5px;
      }
    }
  }

  .option_correspondence {
    font-size: 12.5px;
  }
}
</style>