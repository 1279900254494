export default [
    {
      path: "call-rounds",
      name: "call-rounds-boostcredit",
      redirect: { name: "boostcredit-call-rounds" },
      component: () => import(/* webpackChunkName: "BCCallRounds" */ "@/views/commons/call-rounds/TabsCallRounds.vue"),
      children: [
        {
          path: "",
          name: "boostcredit-call-rounds",
          component: () =>
            import(/* webpackChunkName: "BCCallRoundsActives" */ "@/views/commons/call-rounds/components/sub-tabs/TabGridActives.vue"),
          meta: {
            programId: 2,
            isClientsTab: true,
            pageTitle: "Call Rounds",
            breadcrumb: [
              {
                text: "Actives",
                active: true,
              },
            ],
          },
        },
        {
          path: "  ",
          name: "boostcredit-call-rounds-hold",
          component: () =>
            import(/* webpackChunkName: "BCCallRoundsHold" */ "@/views/commons/call-rounds/components/sub-tabs/TabGridHold.vue"),
          meta: {
            programId: 2,
            isClientsTab: false,
            pageTitle: "Call Rounds",
            breadcrumb: [
              {
                text: "Hold",
                active: true,
              },
            ],
          },
        },
        {
            path: "   ",
            name: "boostcredit-call-rounds-stand-by",
            component: () =>
              import(/* webpackChunkName: "BCCallRoundsStandBy" */ "@/views/commons/call-rounds/components/grids/GridStandBy.vue"),
            meta: {
              programId: 2,
              isClientsTab: false,
              pageTitle: "Call Rounds",
              breadcrumb: [
                {
                  text: "Stand  By",
                  active: true,
                },
              ],
            },
          },
      ],
    },
  ];
  