import store from '@/store';
import Vue from 'vue';
import router from '@/router';

const AssignTaskToDesigner = () => {
  window.socket.bind('creative-task-assigned', async data => {
    const { user_id, modul_id } = store.state.auth.currentUser;
    const notification = data;

    if (notification.user_id === user_id) {
      const icon = 'InfoIcon';
      const { title, description, assignedBy } = notification;

      Vue.prototype.$amgAlert({
        title,
        subtitle: description,
        data: [
          { icon: 'InfoIcon', title: 'Assigned By: ', description: assignedBy },
        ],
        okTitle: 'Go to design requests',
        icon,
        ok: () => {
          const goSectionParagon = 'paragon-in-process';
          const goSectionCreative = 'creative-request-options';
          if (router.currentRoute.name !== goSectionParagon && router.currentRoute.name !== goSectionCreative) {
            if (modul_id === 12) { // paragon
              router.push({ name: goSectionParagon });
            }

            if (modul_id === 27) { // creative
              router.push({ name: goSectionCreative });
            }
          }
          const param = {
            to_id: notification.user_id,
          };
          window.amgApi.post('/commons/close-all-swal', param);
        },
      });
    }
  });
};



const MarkMaterialAsRemarked = () => {

  window.socket.bind('creative-task-remarked', async data => {
    const { user_id, modul_id } = store.state.auth.currentUser;
    const notification = data;

    if (notification.userId === user_id) {
      const icon = 'InfoIcon';
      const { title, description, changedBy } = notification;

      Vue.prototype.$amgAlert({
        title,
        subtitle: description,
        data: [
          { icon: 'InfoIcon', title: 'Remarked By: ', description: changedBy },
        ],
        okTitle: 'Go to design requests',
        icon,
        ok: () => {
          const goSectionParagon = 'creative-request-options-ti';
          const goSectionCreative = 'creative-request-options';
          if (router.currentRoute.name !== goSectionParagon && router.currentRoute.name !== goSectionCreative) {
            if (modul_id === 12) { // paragon
              router.push({ name: goSectionParagon });
            }

            if (modul_id === 27) { // creative
              router.push({ name: goSectionCreative });
            }
          }
          const param = {
            to_id: notification.user_id,
          };
          window.amgApi.post('/commons/close-all-swal', param);
        },
      });
    }
  });
};

const NotificationCommentTeamLeaderDesignByDesigner = () => {

  window.socket.bind('notification-comment-team-leader-design-by-designer', async (response) => {
    const { role_id , modul_id } = store.state.auth.currentUser;
    const { employee } = response;

    console.log("employee",employee)
    
    let comment = employee.comment;
    let rol = employee.role_id;
    let module = employee.module;
    let document_name = employee.document_name;
    let status = employee.status;
    let user_name = employee.user_name;
    const linkMappings = {
      12: {
        Pending: 'creative-request-options-ti',
        'In process': 'paragon-in-process',
        Finished: 'paragon-finished',
        Approved: 'paragon-approved',
        Remarks: 'paragon-observed',
      },
    };
    let linkParagon = linkMappings[12][status] || 'creative-request-options-ti';
    if ( role_id == rol && modul_id == module) {
      Vue.prototype.$amgAlert({
        title : document_name,
        subtitle: comment,
        okTitle: 'GO TO DESIGN REQUESTS',
        iconCreatedBy: "UserIcon",
        created_by: user_name,
        icon : "InfoIcon",
        ok: () => {
              router.push({ name: linkParagon })
              .catch( (err) => {
                if(
                  err.name !== 'NavigationDuplicated' &&
                  !err.message.includes('Avoided redundant navigation to current location')
                ) {
                  throw err;
                }
              });          
          window.amgApi.post('/commons/close-all-swal', comment);
        },
      });
    }
  });
};

export { AssignTaskToDesigner, MarkMaterialAsRemarked,NotificationCommentTeamLeaderDesignByDesigner };
