const { amgApi } = require("@/service/axios")

class ClaimV2Service {
  constructor() {
    this.routeClaims = "quality/claims"
  }

  async searchClientForClaim({
    search,
    perPage,
    currentPage,
    moduleId,
    typeClient,
  }) {
    try {
      const { data } = await amgApi.post(`${this.routeClaims}/search-client`, {
        search,
        per_page: perPage,
        page: currentPage,
        module_id: moduleId,
        type_client: typeClient,
      })
      return data
    } catch (error) {
      throw new Error(error)
    }
  }

  async getTableRelatedToClaim() {
    try {
      const { data } = await amgApi.get(
        `${this.routeClaims}/get-table-related-to-claims`
      )
      return {
        success: true,
        data,
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async store(params) {
    return amgApi.post(`${this.routeClaims}/store`, params)
  }

  async edit(params) {
    return amgApi.post(`${this.routeClaims}/edit`, params)
  }

  async getClaimData(params) {
    const { data } = await amgApi.get("commons/claims-v2/get-for-status", {
      params,
    })
    return data
  }

  async getAll() {
    const { success, data } = await this.getTableRelatedToClaim()
    const { reason_claims, source_claims, types_claims } = data
    if (!success) {
      return {
        reason_claims: [],
        source_claims: [],
        types_claims: [],
      }
    } else {
      return {
        reason_claims,
        source_claims,
        types_claims,
      }
    }
  }

  async getAdvisorModules() {
    try {
      const { data } = await amgApi.get(`commons/claims-v2/get-advisor-modules`)
      return {
        success: true,
        data,
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async getAdvisor(module_id) {
    try {
      const { data } = await amgApi.get(
        `commons/claims-v2/get-advisor-modulo/${module_id}`
      )
      return {
        success: true,
        data,
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async getTrackingclaim(params) {
    try {
      const dataset = await amgApi.post(
        "commons/claims-v2/get-tracking-claim",
        params
      )
      const { data } = dataset.data
      return data
    } catch (error) {
      throw error
    }
  }

  async storeAddSolution(params) {
    try {
      const { data } = await amgApi.post(
        `${this.routeClaims}/store-solutions`,
        params
      )
      return {
        success: true,
        data,
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async getSolutionsClaimsQuality() {
    try {
      const { data } = await amgApi.get(
        `${this.routeClaims}/get-solution-claims-quality`
      )
      return {
        success: true,
        data,
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async getSolutionsForClaimQuality(claimId) {
    try {
      const { data } = await amgApi.get(
        `${this.routeClaims}/get-solutions-for-claim/${claimId}`
      )
      return {
        success: true,
        data,
      }
    } catch (error) {
      throw new Error(error)
    }
  }

  async saveInquiryClaim(params) {
    try {
      return await amgApi.post(`${this.routeClaims}/save-inquiry-claim`, params)
    } catch (error) {
      throw new Error(error)
    }
  }

  async getSanctionForLevelClaim(levelId) {
    try {
      return await amgApi.get(
        `${this.routeClaims}/get-sanction-for-level-claim/${levelId}`
      )
    } catch (error) {
      throw new Error(error)
    }
  }

  async sendSurvey(params) {
    try {
      return await amgApi.post(
        `${this.routeClaims}/send-email-to-client`,
        params
      )
    } catch (error) {
      throw error
    }
  }

  async sendSms(params) {
    try {
      return await amgApi.post("/messages/send-mass-message", params)
    } catch (error) {
      console.log("Error send-mass-message:", error)
      throw error
    }
  }

  async getClaimsCounters(body) {
    try {
      const { data } = await amgApi.post(
        `${this.routeClaims}/get-claims-counters`,
        body
      )
      return data
    } catch (error) {
      console.log("Error getClaimsCounters:", error)
      throw error
    }
  }

  async saveCallRegister(params) {
    try {
      const data = await amgApi.post(
        "commons/claims-v2/save-call-register",
        params
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async annullClaim(params) {
    try {
      const data = await amgApi.post(`${this.routeClaims}/annull-claim`, params)
      return data
    } catch (error) {
      console.log("Error annullClaim:", error)
      throw error
    }
  }

  async countClientClaims(params) {
    try {
      const data = await amgApi.post(
        `${this.routeClaims}/count-client-claims`,
        params
      )
      return data.data
    } catch (error) {
      console.log("Error countClientClaims:", error)
      throw error
    }
  }
  getUsersAdvisorForModule(moduleId) {
    return amgApi.post('commons/claims-v2/get-users-advisor-for-module', {
      moduleId
    });
  }
  async changeStatusQuality(params){
    const result = await amgApi.post('commons/claims-v2/change-status-quality', params);
  }
}
export default new ClaimV2Service()
