const { amgApi } = require("@/service/axios");

class FinancialApprovalService {
  constructor() {
    this.routeFinancial = "specialists";
  }

  async getFormQuestion(params) {
    try {
      const data = await amgApi.post(
        `${this.routeFinancial}/get-form-question`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async getForm(params) {
    try {
      const data = await amgApi.post(
        `${this.routeFinancial}/get-form`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async storeForm(params) {
    try {
      const data = await amgApi.post(
        `${this.routeFinancial}/store-form`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async storeDate(params) {
    try {
      const data = await amgApi.post(
        `${this.routeFinancial}/store-date`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async chageStatusNotContacted(params) {
    try {
      const data = await amgApi.post(
        `financial-appointment/utils/appointment-alert-not-contacted`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getLoanExaminers(params) {
    try {
      const data = await amgApi.post(
        `financial-appointment/utils/get-appointment-agents`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async assignAppointment(params) {
    try {
      const { data } = await amgApi.post(
        `financial-appointment/utils/assign-appointment`, params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export default new FinancialApprovalService();
