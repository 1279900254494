import { amgApi } from "@/service/axios";

class PriorityOffersService {
  async getPriorityOffers(params) {
    try {
      return await amgApi.post("debt-solution/get-priority-offers", params);
    } catch (error) {
      console.log("Error PriorityOffersService: getPriorityOffers");
    }
  }

  async getSettlements(params) {
    try {
      return await amgApi.post(
          'debt-solution/get-settlements',
          params,
      )
    } catch (error) {
      console.log('Error PriorityOffersService: getPriorityOffers')
    }
  }

  async getSettlementsDetails(params) {
    try {
      return await amgApi.post(
          'debt-solution/get-settlements-details',
          params,
      )
    } catch (error) {
      console.log('Error PriorityOffersService: getSettlementsDetails')
    }
  }
  async getCreditorsByName(text) {
    try {
      return await amgApi.post(
        `/sales-made/debt-solution/search-creditors?q=${text}`
      );
    } catch (error) {
      console.log("Error PriorityOffersService: getCreditorsByName");
    }
  }
  async getUsersByModule(moduleId) {
    try {
      return await amgApi.get(`/commons/get-users-by-module/${moduleId}`);
    } catch (error) {
      console.log("Error PriorityOffersService: getUsersByModule");
    }
  }
  async getOfferMotives(params) {
    try {
      return await amgApi.post("/debt-solution/get-offer-motives", params);
    } catch (error) {
      console.log("Error getOfferMotives");
    }
  }
  async getOfferMotive(params) {
    try {
      return await amgApi.post("/debt-solution/get-offer-motive", params);
    } catch (error) {
      console.log("Error getOfferMotive");
    }
  }
  async setNewMotive(params) {
    try {
      return await amgApi.post("/debt-solution/set-new-motive", params);
    } catch (error) {
      console.log("Error setNewMotive");
    }
  }
  // agregar un nuevo numero de telefono
  async setNewPhone(params) {
    try {
      return await amgApi.post("/debt-solution/set-new-phone", params);
    } catch (error) {
      console.log("Error setNewPhone");
    }
  }
  // agregar un nuevo agent+extension
  async setNewAgent(params) {
    try {
      return await amgApi.post("/debt-solution/set-new-agent", params);
    } catch (error) {
      console.log("Error setNewAgent");
    }
  }
  async getPendingMadeDsListCreditTracking(params) {
    try {
      return await amgApi.post(
        "/debt-solution/get-pending-made-ds-list-credit-tracking",
        params
      );
    } catch (error) {
      console.log("Error getPendingMadeDsListCreditTracking");
    }
  }
  async updateStatusByCreditor(params) {
    try {
      return await amgApi.post(
        "/debt-solution/update-status-by-creditor",
        params
      );
    } catch (error) {
      console.log("Error getPendingMadeDsListCreditTracking");
    }
  }
  // "
  async getOfferById(offerId) {
    try {
      const response = await amgApi.get(
        `/debt-solution/get-offer-by-id/${offerId}`
      );
      return response;
    } catch (error) {
      console.log("Error getOfferById");
    }
  }
}

export default new PriorityOffersService();
