<template>
  <b-modal
    ref="modalDescriptionExpense"
    title="CREATE CONCEPT"
    size="sm"
    centered
    modal
    title-class="h4 text-white font-weight-bolder"
    header-class="class_modal_js"
    @ok="handleOk"
    @hidden="handleHidden"
    ok-title="Create"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col>
          <b-form-group label="Vendor">
            <b-form-input :value="titleVendor" disabled />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Concept">
              <b-form-input
                autofocus
                v-model="name"
                @keyup.enter="handleOk"
                :state="errors.length > 0 ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";
export default {
  name: "ModalCreateDescriptionModal",
  props: {
    optionsVendor: {
      type: Array,
      required: true,
    },
    vendorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      name: null,
    };
  },
  computed: {
    openFromDashboardCeo() {
      return this.$route.matched[0].name === "ceo-dashboard";
    },
    moduleId() {
      return this.openFromDashboardCeo ? 1 : this.$route.matched[0].meta.module;
    },
    titleVendor() {
      return this.vendorId
        ? this.optionsVendor.find((item) => item.id === this.vendorId)
            .description
        : "";
    },
  },
  methods: {
    async handleOk(ev) {
      ev.preventDefault();
      let isValid = await this.$refs["form"].validate();
      if (!isValid) return;
      await this.createDescription();
    },
    handleHidden() {
      this.$emit("closing");
    },
    async createDescription() {
      try {
        this.addPreloader();
        let res = await DepartmentExpenses.insertDescriptionExpenses({
          description: this.name,
          vendor_id: this.vendorId,
          module_id: this.moduleId,
        });
        this.$emit("refresh", res);
        this.$emit("closing");
        this.name = null;
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showAlertWarning(e);
      }
    },
    showAlertWarning(message) {
      this.showToast(
        "warning",
        "top-right",
        "Warning",
        "AlertTriangleIcon",
        message
      );
    },
  },
  mounted() {
    this.toggleModal("modalDescriptionExpense");
  },
};
</script>
