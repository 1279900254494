

export default {
  path: "dashboard-agent",
  name: "ce-dashboard-agent",
  component: () => import(/* webpackChunkName: "CEDCustomerServiceDashboardAgent" */ "@/views/ce-digital/sub-modules/customer-service/views/dashboard-agent/index.vue"),
  meta: {
    childModule: 6,
    programParent: 3,
    program: 22,
    permittedRoles: [15, 16],
    pageTitle: "Dashboard",
    breadcrumb: [
      {
        text: "Dashboard",
      },
    ],
  },
}
