var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"lead-client px-1 position-relative"},[_c('b-card-title',{staticClass:"d-block d-md-flex align-items-center w-100"},[_c('h4',{staticClass:"font-weight-bolder font-size-lg m-0 w-100 mr-1 border-2 border-bottom-secondary pb-1"},[_c('span',[_vm._v("Client ")]),_c('span',[(_vm.currentUser.modul_id === 15)?_c('a',{staticClass:"text-important",on:{"click":function($event){return _vm.$emit('onEditLead', false)}}},[_vm._v(_vm._s(_vm.leadName.name))]):(_vm.onlyRead || _vm.isResponsibleLead)?_c('span',[_vm._v(_vm._s(_vm.leadName.name))]):_c('a',{staticClass:"text-primary",on:{"click":function($event){return _vm.$emit('onEditLead', false)}}},[_vm._v(" "+_vm._s(_vm.leadName.name)+" ")]),(
            _vm.$store.getters['NotificationStore/G_IS_RESPONSIBLE_CEO']
              .is_responsible
          )?_c('span',{staticClass:"text-warning"},[_c('tabler-icon',{staticStyle:{"margin-left":"5px"},attrs:{"icon":_vm.$store.getters['NotificationStore/G_IS_RESPONSIBLE_CEO']
                .is_responsible === _vm.currentUser.user_id
                ? 'StarIcon'
                : 'LockIcon',"size":"15"}}),_c('strong',[_vm._v("CEO's client")])],1):_vm._e()])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-2",staticStyle:{"height":"40px"}},[_c('rc-chat-icon',{attrs:{"lead-phone-number":_vm.lead.mobile,"lead-id":_vm.lead.id,"lead-name":_vm.lead.lead_name}})],1),_c('court-info-action',{attrs:{"lead-id":_vm.lead.id}}),(_vm.moduleId === 2)?_c('LeadEmailDropdown',{attrs:{"lead":_vm.lead}}):_vm._e()],1),_c('b-dropdown',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('More options'),expression:"'More options'",modifiers:{"hover":true,"top":true}}],staticClass:"custom-dropdown",attrs:{"size":"lg","variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"hover-me",class:_vm.isDarkSkin ? 'text-white' : 'text-dark',attrs:{"icon":"MoreVerticalIcon","size":"20"}})]},proxy:true}])},[(_vm.lead.mobile != null)?_c('b-dropdown-item',{attrs:{"href":"#","disabled":_vm.isResponsibleLead},on:{"click":_vm.onAddMyList}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ClipboardIcon","size":"15"}}),_vm._v(" Add To My List ")],1):_vm._e(),(
          (_vm.lead.status_sn_id && _vm.moduleId === 2 && !_vm.lead.potential) || 1 == 1
        )?_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":_vm.onSetPotential}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"UserXIcon","size":"15"}}),_vm._v(" Non Potential ")],1):_vm._e(),_c('b-dropdown-divider'),_c('b-dropdown-group',{attrs:{"id":"dropdown-group-1"}},[((_vm.lead.status_sn_id && _vm.moduleId === 2) || 1 == 1)?_c('b-dropdown-item',{attrs:{"href":"#","disabled":_vm.isResponsibleLead},on:{"click":_vm.onNotCall}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"PhoneMissedIcon","size":"15"}}),_vm._v(" No Call ")],1):_vm._e(),(
            (_vm.lead.status_sn_id &&
              _vm.moduleId === 2 &&
              _vm.lead.not_call &&
              _vm.lead.not_call.length) ||
            1 == 1
          )?_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.$bvModal.show('modal-trackin-not-call')}}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ListIcon","size":"15"}}),_vm._v(" Tracking Not Call ")],1):_vm._e()],1)],1)],1),_c('b-card-body',{staticClass:"border rounded mt-2",class:!_vm.isDarkSkin ? 'custom-bg-gray' : null},[_c('h4',{staticClass:"font-weight-bolder pb-1 px-1 mb-2 border-bottom-secondary border-2"},[_vm._v(" Relevant Information ")]),_c('detail-component',{staticClass:"px-2",attrs:{"details":_vm.details}})],1),_c('b-modal',{attrs:{"id":"modal-trackin-not-call","ok-only":"","modal-class":"modal-primary","centered":"","size":"lg","title":("TRACKING NOT CALL - " + (_vm.lead.lead_name)),"hide-footer":"","no-close-on-backdrop":""}},[_c('modal-trackin-not-call',{attrs:{"lead":_vm.lead}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }