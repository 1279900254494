import DashboardNav from "@/views/specialists/sub-modules/analyst-department/views/dashboard/navigation/specialists-dashboard.nav";
import ClientsNav from "@/views/specialists/sub-modules/analyst-department/views/clients/clients.nav.js";
import SettingsNav from "@/views/specialists/sub-modules/analyst-department/views/settings/navigation/settings.nav";
import RequestWorkplanNav from "@/views/specialists/sub-modules/analyst-department/views/request-workplan/navigation/request-workplan.nav";
import RoundsNav from "@/views/specialists/sub-modules/analyst-department/views/rounds/navigation/rounds.nav.js";
import UpdateRequestsNav from "@/views/specialists/sub-modules/analyst-department/views/update-request/navigation/update-request.nav";
import CourtInfoNav from "@/views/specialists/sub-modules/analyst-department/views/court-info/navigation/court-info.nav.js";
//import ProjectsNav from "@/views/specialists/sub-modules/analyst-department/views/projects/navigation/projects.nav.js";
import SupportNav from "@/views/specialists/sub-modules/analyst-department/views/support/navigation/support.nav";
import RrhhNav from "@/views/specialists/sub-modules/analyst-department/views/rrhh/navigation/rrhh.nav";
// import RingCentralNav from "@/views/specialists/sub-modules/analyst-department/views/ring-central/ring-central.nav.js";
import FileManagerNav from "@/views/specialists/sub-modules/analyst-department/views/file-manager/file-manager-ad.nav.js";
import FinancialNav from "@/views/specialists/sub-modules/analyst-department/views/financial/navigation/financial.nav.js";
import CallImpressionNav from "@/views/specialists/sub-modules/analyst-department/views/call-impression/navigation/call-impression.navigation.js";


const navigation = [
  {
    header: "ANALYST DEPARTMENT",
    module: 28,
    modules: [
      {
        value: 28,
        text: "ANALYST DEPARTMENT",
        route: "analyst-department-dashboard",
      },
      {
        value: 29,
        text: "FINANCIAL APPROVAL",
        route: "financial-approval-dashboard",
      },
      {
        value: 30,
        text: "ACCOUNTS RESOLUTION",
        route: "accounts-resolution-dashboard",
      },
    ],
  },
  DashboardNav,
  ClientsNav,
  RequestWorkplanNav,
  RoundsNav,
  UpdateRequestsNav,
  FinancialNav,
  FileManagerNav,
  CourtInfoNav,
  //ProjectsNav,
  SupportNav,
  RrhhNav,
  // RingCentralNav,
  SettingsNav,
  // ServicesNav,
  ...CallImpressionNav,
];

export default navigation;
