import { newRoundLetterToSend, closeAllRoundLetterNotification , newReportSend , closeSendReportNotification, missingFilesNotification, returnedLetterNotification, closeReturnedLetterNotification } from "@/views/commons/components/letters/sockets/RoundLetter.socket.js";


const subscribeSocketLetters = (socket) => {
    try {
        socket.subscribe("round-letters-notifications");
        newRoundLetterToSend();
        closeAllRoundLetterNotification();
        newReportSend();
        closeSendReportNotification();
        missingFilesNotification();
        returnedLetterNotification();
        closeReturnedLetterNotification();
    } catch (error) {
        console.error(error);
    }
}
export default subscribeSocketLetters;
