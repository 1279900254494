<template>
  <b-modal
    v-model="ownControl"
    title-class="h3 text-white font-weight-bolder"
    hide-footer
    size="md"
    :title="title"
    @hidden="closeModal"
  >
    <b-row class="mt-1">
      <b-col lg="12">
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
              >CLIENT
            </b-input-group-text>
          </template>
          <b-skeleton v-if="!finishedLoading" type="input" class="space" />
          <b-form-input
            v-if="finishedLoading"
            v-model="data.client_name"
            :disabled="true"
          /> </b-input-group
      ></b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
              >TRANSACTION ID
            </b-input-group-text>
          </template>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />
          <b-form-input
            v-if="finishedLoading"
            v-model="data.transaction_id"
            :disabled="true"
          /> </b-input-group
      ></b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
              >AMOUNT
            </b-input-group-text>
          </template>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />
          <money
            v-if="finishedLoading"
            id="input-1a"
            v-model="data.amount"
            style="'border-color:rgba(255, 255, 255, 0.4)'"
            v-bind="maskMoney"
            :disabled="true"
            class="form-control"
          /> </b-input-group
      ></b-col>
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
              >DATE
            </b-input-group-text>
          </template>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />
          <span
            v-if="finishedLoading"
            style="'border-color:rgba(255, 255, 255, 0.4)'; "
            :style="
              isDarkSkin
                ? 'background: #17171A;opacity: 0.3;color:white'
                : 'background: #efefef'
            "
            class="form-control"
            >{{ data.settlement_date | myGlobal }}</span
          >
        </b-input-group></b-col
      >
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
              >DONE BY
            </b-input-group-text>
          </template>
          <b-skeleton
            v-if="!finishedLoading"
            class="space"
            style="width: 100% !important"
            type="input"
          />
          <span
            v-if="finishedLoading"
            style="'border-color:rgba(255, 255, 255, 0.4)'; "
            :style="
              isDarkSkin
                ? 'background: #17171A;opacity: 0.3;color:white'
                : 'background: #efefef'
            "
            class="form-control"
            >{{ data.updated_by }} | {{ data.updated_at | myGlobalDay() }}</span
          >
        </b-input-group></b-col
      >
    </b-row>

    <b-row class="mt-1">
      <b-col>
        <b-input-group>
          <template #prepend>
            <b-input-group-text
              class="title_lable d-flex justify-content-center"
              >COMMENT
            </b-input-group-text>
          </template>
          <b-skeleton v-if="!finishedLoading" class="space" type="input" />
          <b-form-textarea
            v-if="finishedLoading"
            v-model="data.comment"
            :disabled="true"
          /> </b-input-group
      ></b-col>
    </b-row>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";

export default {
  props: {
    transactionId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      title: "",
      maskMoney: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
      },
      finishedLoading: false,
      ownControl: false,

      data: [],
    };
  },

  async created() {
    await this.getRefund();
    this.ownControl = true;
    this.finishedLoading = true;
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },

    // eslint-disable-next-line consistent-return
    async getRefund() {
      try {
        const response = await ClientsOptionsServices.getVoidRefund({
          idtransaction: this.transactionId,
        });

        this.data = response[0];
        if (this.data.type_transaction_id === 10) {
          this.title = "VOID";
        }
        if (this.data.type_transaction_id === 11) {
          this.title = "REFUND";
        }

        return this.data;
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
    },
  },
};
</script>

<style scoped>
.title_lable {
  width: 150px;
}
.space {
  width: 65% !important;
}
</style>
