


export default [
  {
    path: "notes",
    name: "settings-notes",
    redirect: { name: "settings-notes-list" },
    component: () => import(/* webpackChunkName: "CEDSettingsNotes" */ "@/views/ce-digital/sub-modules/settings/views/notes/Notes.vue"),
    meta: {
      route: "settings",
      permittedRoles: [1, 2, 17],
      breadcrumb: [
        {
          text: "Settings",
          active: true
        }
      ]
    },
    children: [
      {
        path: "",
        name: "settings-notes-list",
        component: () => import(/* webpackChunkName: "CEDSettingsNotesList" */ "@/views/ce-digital/sub-modules/settings/views/notes/components/NotesList.vue"),
        meta: {
          route: "settings",
          permittedRoles: [1, 2, 17],
          pageTitle: "Notes",
          breadcrumb: [
            {
              text: "Notes",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
