export default {
  path: "helpdesk",
  name: "helpdesk-creative",
  component: () => import("@/views/creative/views/helpdesk/Helpdesk.vue"),
  meta: {
    pageTitle: "Help Desk",
    breadcrumb: [
      {
        text: "Help Desk",
        active: true,
      },
    ],
    onlyChief: true,
  },
}
