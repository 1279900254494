import store from "@/store";
import router from "@/router/index";

const statusSubmoduleRedirectPusher = () => {
  window.socket.bind("status-submodule-redirect", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id;
    if (data.to_id === sessionId) {
      if (
        router.currentRoute.matched[0]?.meta?.module !==
          data.to_except_module_id &&
        router.currentRoute.matched[0]?.meta?.parentModule === 21 &&
        sessionId !== 1
      ) {
        await router.push("/cedigital");
      }
    }
  });
};
export default statusSubmoduleRedirectPusher;
