export const potencialTabRoute = (abv,title,permitted_roles = null)=>{
    return [
        {
            path: "pending",
            name: `${abv}-in-process`,

            component: () => import(
                "@/views/commons/components/potencial-appointment/components/TablePotencialAppointment.vue"
            ),

            meta: {
                router: "financial-approval",
                statusApp: 1,
                pageTitle: `${title}`,
                breadcrumb: [
                    {
                        text: "In process",
                        active: true,
                    },
                ],
                permittedRoles: permitted_roles,
                routeInProcess: `${abv}-in-process`,
                routeFinished: `${abv}-finished`,
                routeCompleted: `${abv}-completed`,
                routeRejected: `${abv}-rejected`,
                routeExpired: `${abv}-expired`,
                tabName:"IN PROCESS",
            },
        },
        {
            path: "finished",
            name: `${abv}-finished`,
            redirect: { name: `${abv}-completed` },
            component: () => import(/* webpackChunkName: "FinancialApprovalApplicationsCompleted" */ "@/views/commons/components/potencial-appointment/views/CompletedSubTab"),
            meta: {
                router: "financial-approval",
                statusApp: 2,
                pageTitle: `${title}`,
                breadcrumb: [
                    {
                        text: "FINISHED",
                        active: true,
                    },
                ],
                permittedRoles: permitted_roles,
                routeInProcess: `${abv}-in-process`,
                routeFinished: `${abv}-finished`,
                routeCompleted: `${abv}-completed`,
                routeRejected: `${abv}-rejected`,
                routeExpired: `${abv}-expired`,
            },
            children: [{
                path: "completed",
                name: `${abv}-completed`,
                component: () =>
                    import(
                        "@/views/commons/components/potencial-appointment/components/TablePotencialAppointment.vue"
                    ),
                meta: {
                    pageTitle: `${title}`,
                    statusApp: 2,
                    tabName: "COMPLETED",
                    breadcrumb: [
                        {
                            text: "Completed",
                            active: true,
                        },
                    ],
                    permittedRoles: permitted_roles,
                },
                routeInProcess: `${abv}-in-process`,
                routeFinished: `${abv}-finished`,
                routeCompleted: `${abv}-completed`,
                routeRejected: `${abv}-rejected`,
                routeExpired: `${abv}-expired`,
            },
            {
                path: "rejected",
                name: `${abv}-rejected`,
                component: () =>
                    import(
                        "@/views/commons/components/potencial-appointment/components/TablePotencialAppointment.vue"
                    ),
                meta: {
                    pageTitle: `${title}`,
                    tabName: "REJECTED",
                    statusApp: 3,
                    breadcrumb: [
                        {
                            text: "Rejected",
                            active: true,
                        },
                    ],
                    permittedRoles: permitted_roles,
                },

            },
            {
                path: "expired",
                name: `${abv}-expired`,
                component: () =>
                    import(
                        "@/views/commons/components/potencial-appointment/components/TablePotencialAppointment.vue"
                    ),
                meta: {
                    pageTitle: `${title}`,
                    tabName: "EXPIRED",
                    statusApp: 4,
                    breadcrumb: [
                        {
                            text: "Expired",
                            active: true,
                        },
                    ],
                    permittedRoles: permitted_roles,
                },
                routeInProcess: `${abv}-in-process`,
                routeFinished: `${abv}-finished`,
                routeCompleted: `${abv}-completed`,
                routeRejected: `${abv}-rejected`,
                routeExpired: `${abv}-expired`,

            }]

        },
    ]
}
