export default [
    {
       type:"select",
       margin:true,
       showLabel:true,
       label:"Seller Level",
       options:[
          {label:"TRAINER",id:1},
          {label:"JUNIOR",id:2},
          {label:"SENIOR",id:3},
          {label:"MASTER",id:4}
       ],
       model:null,
       reduce:"id",
       selectText:"label",
       col:12
    }
]