

export default [
  {
    path: 'zero-payment',
    name: 'ce-zero-payment',
    component: () => import(/* webpackChunkName: "CEDFinancialZeroPayment" */ "@/views/ce-digital/sub-modules/financial/view/zero-payment/ZeroPayment.vue"),
    meta: {
      parentModule: 21,
      pageTitle: 'Zero Payment',
      breadcrumb: [
        {
          text: 'Zero Payment',
          active: true,
        },
      ],
      permittedRoles: [1, 2, 17]
    },
  },
]
