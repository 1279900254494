export default {
  path: "no-answer",
  name: "appointment-no-answered",
  component: () => import(/* webpackChunkName: "NoAnswer" */ "./NoAnswer.vue"),
  meta: {
    pageTitle: "No Answer",
    breadcrumb: [
      {
        text: "No Answer",
        active: true,
      },
    ],
  },
};
