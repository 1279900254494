export default [
  {
    path: "ncr/realtor",
    name: "boost-credit-ncr-realtor", //boost-credit-ncr-realtor
    component: () =>
      import(
        /* webpackChunkName: "BCNcrRealtor" */ "@/views/boost-credit/views/ncr-realtor/views/NcrRealtorTemplate.vue"
      ),
    redirect: { name: "bc-ncr-realtor-in-process" },
    meta: {
      pageTitle: "NCR Realtor",
      breadcrumb: [
        {
          text: "NCR Realtor",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "in-process",
        name: "bc-ncr-realtor-in-process",
        component: () =>
          import(
            /* webpackChunkName: "BCNcrRealtorInProcess" */ "@/views/commons/components/ncr-realtor/views/components/TableNcrRealtor.vue"
          ),
        meta: {
          statusNCR: 0,
          pageTitle: "NCR Realtor",
          breadcrumb: [
            {
              text: "NCR Realtor",
              active: true,
            },
          ],
        },
      },
      {
        path: "completed",
        name: "bc-ncr-realtor-completed",
        component: () =>
          import(
            /* webpackChunkName: "BCNcrRealtorCompleted" */ "@/views/commons/components/ncr-realtor/views/components/TableNcrRealtor.vue"
          ),
        meta: {
          statusNCR: 1,
          pageTitle: "NCR Realtor",
          breadcrumb: [
            {
              text: "NCR Realtor",
              active: true,
            },
          ],
        },
      },
    ],
  },
]
