<template>
  <div>
    <div style="text-align: justify">
      <span v-html="displayedText"></span>
      <a
        :style="showMoreStyles"
        v-if="showMoreButton"
        @click="toggleShowMore"
        style="cursor: pointer; margin-left: 3px"
        >...{{ showMoreText }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    letters: {
      type: Number,
      default: 100,
    },
    showMoreStyles: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      showMoreButton: false,
      displayedText: "",
      showMoreText: "Show more",
    };
  },

  methods: {
    toggleShowMore() {
      if (this.showMoreText === "Show more") {
        this.displayedText = this.text;
        this.showMoreText = "Show less";
      } else {
        this.displayedText = this.text.substring(0, this.letters);
        this.showMoreText = "Show more";
      }
    },
  },

  created() {
    this.displayedText = this.text.substring(0, this.letters); // Inicializamos displayedText
    if (this.letters < this.text.length) {
      this.showMoreButton = true; // Mostramos el botón "Show more" solo si hay más texto que letras
    }
  },
};
</script>

<style>
</style>