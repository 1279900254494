import { newPurchaseOrder, checkRejectCeoNotification, changeCheckNumberNotification } from "@/views/correspondence/correspondence.socket"
import {SentCiLetterNotification } from "@/views/correspondence/views/letters-to-clients/letter-client-notification.js";

const subscribeSocketCorrespondence = (socket) => {
  try {
    socket.subscribe("correspondence-notifications")
    newPurchaseOrder()
    SentCiLetterNotification()
    checkRejectCeoNotification()
    changeCheckNumberNotification()
  } catch (error) {
    console.error(error, "error")
  }
}

export default subscribeSocketCorrespondence
