export default [
  {
    key: "details",
    label: "Check details",
    visible: true,
    tdClass: "text-left col-4",
  },
  // {
  //     key: "payment_date",
  //     label: "Payment Date",
  //     visible: true,
  //     thClass: "text-center",
  //     tdClass: "text-center",
  // },
  {
    key: "status_check",
    label: "Status",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "amount",
    label: "Amount",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  // {
  //     key: "account_number",
  //     label: "Account Number",
  //     visible: true
  // },
  {
    key: "check_number",
    label: "Check Number",
    visible: true,
    tdClass: "text-center",
    thClass: "text-center",
  },
  {
    key: "requested_by",
    label: "Requested by",
    visible: true,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "updated_by",
    label: "Updated by",
    visible: false,
    thClass: "text-center",
    tdClass: "text-center",
  },
  {
    key: "tracking",
    label: "Tracking",
    visible: true,
    class: "text-center",
  },
];
