export default [
    {
        label: "Creditors Name",
        key: "credit",
    },
    {
        label: "# Account",
        key: "account",
    },
    {
        label: "Total Balance",
        key: "balance",
    },
    {
        label: "Monthly Current Payment",
        key: "monthly",
    },
    {
        label: "Months Behind",
        key: "months",
    },
    {
        label: "Interest Rate (%)",
        key: "interest",
        formatter: (value) => `${value} %`,
    },
    {
        label: "Type",
        key: "typt",
    },
    {
        label: "Unsecured",
        key: "unsecured",
    },
    {
        label: "Actions",
        key: "actions",
    },
]