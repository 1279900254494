export default [
  {
    path: 'users-log',
    name: 'management-users-log',
    redirect: { name: 'management-active-users-log' },
    component: () => import(
      /* webpackChunkName: "Managment UsersLog" */
      '@/views/management/views/users-log/TabUsersLog.vue'
    ),
    meta: {
      pageTitle: 'Users Log',
      permittedRoles: [1],
      breadcrumb: [
        {
          text: '',
        },
      ],
    },
    children: [
      {
        path: 'management-active-users-log',
        name: 'management-active-users-log',
        component: () => import('@/views/management/views/users-log/components/UsersLog.vue'),
        meta: {
          pageTitle: 'Users Log',
          permittedRoles: [1],
          breadcrumb: [
            {
              text: 'Active Users',
            },
          ],
          active_user: 1,
        },
      },
      {
        path: 'management-inactive-users-log',
        name: 'management-inactive-users-log',
        component: () => import('@/views/management/views/users-log/components/UsersLog.vue'),
        meta: {
          pageTitle: 'Users Log',
          permittedRoles: [1],
          breadcrumb: [
            {
              text: 'Inactive Users',
            },
          ],
          active_user: 2,
        },
      },
    ],
  },
];
