import {
  RescheduleTelNotification,
  showNoAnswered,
} from "@/views/social-network/meta-media.socket.js";

const subscribeSocketMetaMedia = (socket) => {
  try {
    socket.subscribe("metamedia-notifications");
    RescheduleTelNotification();
    showNoAnswered();
  } catch (error) {
    console.error(error, "error");
  }
};

export default subscribeSocketMetaMedia;
