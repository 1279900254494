<template>
  <b-modal
    v-model="ownControl"
    size="1040"
    hide-footer
    header-bg-variant="transparent border-bottom border-bottom-2"
    header-class="p-0"
    scrollable
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center container-title">
          <span>{{ `Add Note (Lead: ${lead.lead_name})` }}</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="closeModal"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="form">
      <b-container fluid class="mt-1">
        <b-row>
          <b-col md="12" class="mb-2" v-if="!isConversation">
            <SmartNoteInput
              ref="smartNoteInput"
              v-model="noteText"
              :rows="6"
              :show-over="false"
              @valid="handleValid"
              @original="handleOriginalText"
              required
            >
              <template v-slot:more-items>
                <div class="d-flex align-items-center gap-10">
                  <div
                    class="select-card"
                    @click.prevent="noteImportant = !noteImportant"
                    :class="{ active: noteImportant }"
                  >
                    <span>Important</span>
                    <b-form-checkbox v-model="noteImportant" />
                  </div>
                  <div
                    class="select-card"
                    v-if="moduleId !== 2"
                    @click.prevent="isConversation = !isConversation"
                    :class="{ active: isConversation }"
                    v-b-tooltip.hover.auto="`The client was called?`"
                  >
                    <span>Conversation</span>
                    <b-form-checkbox v-model="isConversation" />
                  </div>
                </div>
              </template>
            </SmartNoteInput>
            <span class="text-danger" v-if="!useOptimize">
              Optimized message is required
            </span>
          </b-col>

          <b-col cols="12" v-if="isConversation">
            <calls-in-rc
              ref="callRcComponent"
              :client-phone-original="lead.mobile"
              @hasSearch="hasSearch = true"
              @countCalls="countCalls = $event"
              :saving="saving"
              v-model="callNumbers"
            ></calls-in-rc>
          </b-col>

          <b-col cols="12">
            <div class="d-flex justify-content-end">
              <b-button
              :disabled="isConversation ? countCalls === 0 : (!noteText || !useOptimize) "
                @click="saveNewNote"
                variant="primary"
              >
                Save
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import CallsInRc from "@/views/commons/components/ring-central/call-log/components/CallsInRC.vue";
import SmartNoteInput from "@/commons/smart-input/SmartNoteInput.vue";

export default {
  name: "AddNotesModal",
  components: { ButtonSave, SmartNoteInput, CallsInRc },
  props: {
    lead: {
      required: true,
      type: Object,
    },
    firstNote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countCalls: 0,
      ownControl: false,
      noteText: "",
      noteImportant: false,
      isConversation: false,
      callNumbers: {
        module_number: null,
        lead_number: null,
        transfer: false,
      },
      hasSearch: false,
      saving: false,
      smartNoteValid: null,
      originalText: "",
      useOptimize: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  async created() {
    this.isConversation = this.moduleId === 2;
    this.ownControl = true;
  },
  methods: {
    async testingFileFill() {
      const reader = new FileReader();
      reader.readAsDataURL(this.testingFile);
      reader.onload = async (file) => {
        this.testingFile = file.target.result;
      };
    },
    handleOriginalText(text) {
      this.originalText = text;
      this.useOptimize = true;
    },
    handleValid(state) {
      this.smartNoteValid = state;
    },
    closeModal() {
      this.ownControl = false;
      this.$emit("close");
    },
    async saveNewNote() {
      const resolve = await this.$refs.form.validate();

      if (!this.hasSearch && this.isConversation) {
        this.showToast(
          "warning",
          "top-right",
          "Oops!",
          "XIcon",
          "You must search a call before saving the note"
        );
        return;
      }

      if (resolve) {
        const result = await this.showConfirmSwal();
        if (result.value) {
          try {
            this.addPreloader();

            let params = {
              important: this.noteImportant || this.firstNote ? 1 : 0,
              lead_id: this.lead.id,
              text: this.noteText,
              original_text: this.originalText,
              user_id: this.currentUser.user_id,
              module_id: this.moduleId,
              is_conversation: this.isConversation,
              module_number: this.callNumbers.module_number,
              lead_number: this.callNumbers.lead_number,
               transfer: this.callNumbers.transfer,
              countCalls: this.countCalls || 0,
            };

            const response = await amgApi.post(
              "/note/note/notes-create",
              params
            );

            if (response.status === 200) {
              this.closeModal();
              this.$emit("newImportant", response.data[0]);
            }
          } catch (error) {
            this.closeModal();
            this.showErrorSwal(error);
          } finally {
            this.removePreloader();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  .container-title {
    width: 100%;
    height: 100%;

    span {
      font-size: 1.3rem;
      font-weight: bold;
      margin-left: 8px;
      color: #ffffff;
    }
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}

.select-card {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid #e4e3e0;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  &.active {
    border: 1px solid #0077e6;
  }
}
</style>