export default {
  path: "clients/account/:idClient",
  name: "connection-clients-account",
  component: () =>
    import(
      /* webpackChunkName: "ConnectionClientAccount" */ "@/views/ce-digital/sub-modules/connection/views/clients/dashboard/Dashboard.vue"
    ),
  redirect: { name: "connection-clients-account-personal-information" },
  meta: {
    module: 20,
    breadcrumb: [
      {
        text: "Personal Information",
        active: true,
      },
    ],
    pageTitle: "Client",
    // permitedRoles: [1,17]
  },
  children: [
    {
      path: "personal-information",
      name: "connection-clients-account-personal-information",
      component: () =>
        import(
          /* webpackChunkName: "ConnectionClientAccountPersonalInformation" */
          "@/views/credit-experts/views/clients/dashboard/information-client/ClientInformation.vue"
        ),
      meta: {
        module: 20,
        breadcrumb: [
          {
            text: "Personal Information",
            active: true,
          },
        ],
        pageTitle: "Client",
      },
    },
    {
      path: "task-notes",
      name: "connection-clients-account-task-notes",
      component: () =>
        import(
          /* webpackChunkName: "ConnectionClientAccountTaskNotes" */
          "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotesNew.vue"
        ),
      meta: {
        module: 20,
        breadcrumb: [
          {
            text: "Notes",
            active: true,
          },
        ],
        pageTitle: "Client",
      },
    },
    {
      path: "payments",
      name: "connection-clients-account-payments",
      component: () =>
        import(
          /* webpackChunkName: "ConnectionClientAccountPayments" */
          "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
        ),
      meta: {
        module: 20,
        breadcrumb: [
          {
            text: "Payments",
            active: true,
          },
        ],
        pageTitle: "Client",
      },
    },
    {
      path: "files",
      name: "connection-clients-account-files",
      component: () =>
        import(
          /* webpackChunkName: "ConnectionClientAccountFiles" */
          "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
        ),
      meta: {
        module: 20,
        breadcrumb: [
          {
            text: "Files",
            active: true,
          },
        ],
        pageTitle: "Client",
      },
    },
    {
      path: "access-credentials",
      name: "connection-clients-account-access-credential",
      component: () =>
        import(
          /* webpackChunkName: "ConnectionClientAccountAccessCredential" */
          "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
        ),
      meta: {
        module: 20,
        breadcrumb: [
          {
            text: "Access Credentials",
            active: true,
          },
        ],
        pageTitle: "Client",
      },
    },
    {
      path: "dispute",
      name: "connection-clients-account-dispute",
      redirect: { name: "analysis-cr-cn" },
      component: () =>
        import(
          /* webpackChunkName: "ConnectionClientAccountDispute" */
          "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
        ),
      meta: {
        module: 20,
        breadcrumb: [
          {
            text: "Dispute",
            active: true,
          },
        ],
        pageTitle: "Client",
      },
      children: [
        {
          path: "dispute-detail",
          name: "dispute-detail-cn",
          component: () =>
            import(
              /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/DisputeDetail.vue"
            ),
          meta: {
            isClientsTab: true,
            pageTitle: "Clients",
            breadcrumb: [
              {
                text: "Credit > Dispute",
                active: true,
              },
            ],
          },
        },
        {
          path: "report-detail",
          name: "report-detail-cn",
          component: () =>
            import(
              /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
            ),
          meta: {
            isClientsTab: true,
            pageTitle: "Clients",
            breadcrumb: [
              {
                text: "Credit > Credit Report",
                active: true,
              },
            ],
          },
        },
        {
          path: "updates-detail",
          name: "updates-detail-cn",
          component: () =>
            import(
              /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/UpdateDetail.vue"
            ),
          meta: {
            isClientsTab: true,
            pageTitle: "Clients",
            breadcrumb: [
              {
                text: "Credit > Updates",
                active: true,
              },
            ],
          },
        },
        {
          path: "workplan-detail",
          name: "workplan-detail-cn",
          component: () =>
            import(
              /* webpackChunkName: "CnClientAccountWorkPlanDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
            ),
          meta: {
            isClientsTab: true,
            pageTitle: "Clients",
            breadcrumb: [
              {
                text: "Credit > Workplan",
                active: true,
              },
            ],
          },
        },
        {
          path: "recommendation-detail",
          name: "recommendation-detail-cn",
          component: () =>
            import(
              /* webpackChunkName: "CnClientAccountRecommendation" */ "@/views/commons/components/clients/dashboard/options/services/services.vue"
            ),
          meta: {
            isClientsTab: true,
            pageTitle: "Clients",
            breadcrumb: [
              {
                text: "Credit > Recommendations",
                active: true,
              },
            ],
          },
        },
        {
          path: "analysis",
          name: "analysis-cr-cn",
          component: () =>
            import(
              /* webpackChunkName: "CnClientAccountItems" */ "@/views/commons/components/clients/dashboard/analysis/AccountsClients.vue"
            ),
          meta: {
            isClientsTab: true,
            pageTitle: "Clients",
            breadcrumb: [
              {
                text: "Credit > Items",
                active: true,
              },
            ],
          },
        },
      ],
    },
    {
      path: "report",
      name: "connection-clients-account-report",
      component: () =>
        import(
          /* webpackChunkName: "ConnectionClientAccountReport" */
          "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
        ),
      props: {
        clientId: "",
      },
      meta: {
        module: 20,
        breadcrumb: [
          {
            text: "Report",
            active: true,
          },
        ],
        pageTitle: "Client",
      },
    },
    {
      path: "call-log",
      name: "connection-clients-account-call-log",
      component: () =>
        import(
          /* webpackChunkName: "ConnectionClientAccountReport" */
          "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
        ),
      props: {
        clientId: "",
      },
      meta: {
        module: 20,
        breadcrumb: [
          {
            text: "Call Log",
            active: true,
          },
        ],
        pageTitle: "Recording",
      },
    },
    {
      path: "services",
      name: "connection-client-services",
      component: () =>
        import(
          /* webpackChunkName: "servicesClient" */
          "@/views/commons/components/clients/dashboard/options/services/services.vue"
        ),
      meta: {
        isClientsTab: true,
        module: 20,
        pageTitle: "Services",
        breadcrumb: [{ text: "Services", active: true }],
      },
    },
  ],
};
