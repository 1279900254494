import { amgApi } from '@/service/axios'

// General services on module MetaMedia after refactoring
class FilterColumnsServices {
    async getSettingsFields(params) {
        try {
            const { data, status } = await amgApi.post("/management/custom-forms/get-fields-form", params);
            return { data, status };
        } catch (error) {
            throw error;
        }
    }
    async getDynamicFilters(params) {
        try {
            const { data, status } = await amgApi.post("/management/custom-forms/get-dynamic-filters", params);
            return { data, status };
        } catch (error) {
            throw error;
        }
    }
    // async saveDynamicFilters(params) {
    //     try {
    //         // const { data, status } = await amgApi.post("/management/custom-forms/get-dynamic-filters", params);
    //         return { data, status };
    //     } catch (error) {
    //         throw error;
    //     }
    // }
    async searchCustomForm(params) {
        try {
            const { data, status } = await amgApi.post(
                "management/custom-forms/search-custom-forms",
                params
            );
            return { data, status };
        } catch (error) {
            throw error;
        }
    }
    async saveFieldCustomForms(params) {
        try {
            const { data, status } = await amgApi.post(
                "management/custom-forms/save-fields-custom-form",
                params
            );
            return { data, status };
        } catch (error) {
            throw error;
        }
    }
    async getFieldsCustomForms(params) {
        try {
            const { data, status } = await amgApi.post(
                "management/custom-forms/get-fields-form",
                params
            );
            return { data, status };
        } catch (error) {
            throw error;
        }
    }
}
export default new FilterColumnsServices();