<template>
  <b-modal
    v-model="onControl"
    :title="entityName"
    title-tag="h3"
    modal-class="modal-primary"
    size="lg"
    no-close-on-backdrop
    @hidden="close"
  >
    <validation-observer ref="form">
      <b-container class="mt-1 px-3">
        <b-row>
          <b-col>
            <span>Account removed or no balance</span>
          </b-col>
          <b-col>
            <b-input-group prepend="$">
              <money
                v-model="noBalance"
                v-bind="{ decimal: '.', thousands: ',', precision: 2 }"
                class="text-center form-control"
                disabled
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <span>Initial Balance:</span>
          </b-col>
          <b-col>
            <b-input-group prepend="$">
              <money
                v-model="initialBalance"
                v-bind="{ decimal: '.', thousands: ',', precision: 2 }"
                class="text-center form-control"
                disabled
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <span>Savings Percentage:</span>
          </b-col>
          <b-col>
            <b-input-group prepend="%">
              <b-form-input class="text-center" value="100.00" disabled />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <span>Saving:</span>
          </b-col>
          <b-col>
            <b-input-group prepend="$">
              <money
                v-model="saving"
                v-bind="{ decimal: '.', thousands: ',', precision: 2 }"
                class="text-center form-control"
                disabled
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col>
            <span>{{ cost }}% charges for A.M.G:</span>
          </b-col>
          <b-col>
            <b-input-group prepend="$">
              <money
                v-model="charges"
                v-bind="{ decimal: '.', thousands: ',', precision: 2 }"
                class="text-center form-control"
                disabled
              />
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="commentary"
              rules="required"
            >
              <b-form-group label="Commentary">
                <b-form-textarea
                  v-model="commentary"
                  :state="errors[0] ? false : null"
                />
                <span v-if="errors[0]" class="text-danger">
                  Commentary {{ errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </b-container>
    </validation-observer>
    <template #modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-center">
        <button-cancel @click="cancel()" />
        <b-button class="ml-1" variant="primary" @click="savedCreditor">
          <feather-icon icon="SaveIcon" style="margin-right: 0.5rem" />Done
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters, mapSetters } from "vuex";

export default {
  components: {
    ButtonCancel,
  },
  props: {
    title: null,
    idCreditor: null,
    idPhase: null,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      amountSaved: "DebtSolutionClients/G_AMOUNTSAVED",
    }),
    isSupervisor() {
      return this.currentUser.role_id == 2;
    },
    getAccounts(){
      if(this.creditor.account){
        if(this.creditor.account.length >=4){
          return "#. "+this.creditor.account.substring(this.creditor.account.length - 4);
        } else {
          return "#. "+this.creditor.account;
        }
      }else{
        return "Undefined";
      }
      
    },
  },
  data() {
    return {
      onControl: false,
      creditor: {
        id_creditor: "",
      },
      entityName: "",
      commentary: null,
      noBalance: "",
      initialBalance: "",
      saving: "",
      charges: "",
      cost: "",
    };
  },
  async created() {
    this.addPreloader();
    this.onControl = true;
    this.creditor.id_creditor = this.idCreditor;
    await this.getDataCreditor();
    this.removePreloader();
  },
  methods: {
    close(num = 0) {
      this.$emit("close", num);
    },
    async getDataCreditor() {
      try {
        const response = await ClientsOptionsServices.getCreditorPhase({
          id: this.creditor.id_creditor,
        });
        if (response.status === 200) {
          this.creditor = response.data[0];
          this.noBalance = this.creditor.actual_balance;
          this.initialBalance = this.creditor.balance;
          this.saving = this.creditor.actual_balance;
          this.cost = this.creditor.cost;
          this.charges = this.creditor.charge_amg;
          this.entityName = this.creditor.entidad_name;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async savedCreditor() {
      const result = await this.$refs.form.validate();
      if (result) {
        try {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            this.addPreloader();
            const data = await ClientsOptionsServices.insertAccountAction({
              id_role: this.currentUser.role_id,
              id_creditor: this.idCreditor,
              id_bank: this.creditor.credit_id,
              balancee: this.creditor.actual_balance,
              phase_id: this.idPhase,
              prev_phase_id: this.creditor.account_phase_id,
              id_user: this.currentUser.user_id,
              comment: this.commentary,
              id_account: this.$route.params.idClient,
              name_entidad: this.creditor.entidad_name,
              charges_amg: this.charges,
              account: this.getAccounts,
            });
            if (data.status === 200) {
              this.removePreloader();
              this.showSuccessSwal();
              this.$emit("actFunEmitEditCreator", 1);
              // this.close(1);
              this.$emit("reloadAmountSaved");
            }
          }
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal(error);
        }
      }
    },
  },
};
</script>

<style scoped>
</style>
