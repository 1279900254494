// import NewLeads from "./tabs/new-leads/NewLeads.vue"
// import CreateNewLead from "./tabs/new-leads/views/create/Create.vue"
// import DashboardNewLead from "./tabs/new-leads/views/dashboard/Dashboard.vue"
// import DashboardOldLead from "./tabs/old-leads/views/dashboard/Dashboard.vue"
// import EditOldLead from "./tabs/old-leads/views/edit/Edit.vue"
// import WithoutLeads from "./tabs/without-leads/WithoutLeads.vue"
// import ClosedLeads from "./tabs/closed-leads/ClosedLeads.vue"
// import NoAnswerLeads from "@/views/social-network/views/leads/tabs/no-answered-leads/NoAnswerLeads"

export default [
  {
    path: "leads",
    name: "leads-social-network",
    component: () =>
      import(
        /* webpackChunkName: "SnLeads" */ "@/views/social-network/views/leads/Leads.vue"
      ),
    redirect: { name: "sn-list-new-leads" },
    meta: {
      permittedRoles: [12, 9, 11, 2, 1],
    },
    children: [
      {
        path: "",
        redirect: { name: "sn-list-new-leads" },
        meta: {
          permittedRoles: [12, 9, 11, 2, 1],
        },
      },
      {
        path: "new",
        component: () =>
          import(
            /* webpackChunkName: "SnNewLeads" */ "./tabs/new-leads/NewLeads.vue"
          ),
        name: "sn-list-new-leads",
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
      
            {
              text: "Potential",
              active: true,
            },
          ],
          permittedRoles: [12, 9, 11, 2, 1],
        },
      },
      {
        path: "without-number",
        component: () => import(/* webpackChunkName: "SnWithoutPotential" */ "./tabs/without-leads/WithoutLeads.vue"),
        name: "sn-list-without-number-leads",
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Without Number",
              active: true,
            },
          ],
          permittedRoles: [12, 9, 11, 2, 1],
        },
      },
      {
        path: "closed",
        component: () => import(/* webpackChunkName: "SnClosedLeads" */ "./tabs/closed-leads/ClosedLeads.vue"),
        name: "sn-list-closed-leads",
        meta: {
          typeLead: 2,
          pageTitle: "Leads",
          breadcrumb: [
         
            {
              text: "Closed",
              active: true,
            },
          ],
          permittedRoles: [12, 9, 11, 2, 1],
        },
      },
      {
        path: "requests",
        component: () => import(/* webpackChunkName: "SnClosedLeads" */ "./tabs/closed-leads/ClosedLeads.vue"),
        name: "sn-list-requests-leads",
        meta: {
          typeLead: 5,
          pageTitle: "Leads",
          breadcrumb: [
      
            {
              text: "Requests",
              active: true,
            },
          ],
          permittedRoles: [1,2,11],
        },
      },
      {
        path: "no-answer",
        component: () => import(/* webpackChunkName: "SnNoAnswerLeads" */ "./tabs/no-answered-leads/NoAnswerLeads.vue"),
        name: "sn-no-answer-leads",
        meta: {
          pageTitle: "Leads",
          breadcrumb: [
      
            {
              text: "In Pending",
              active: true,
            },
          ],
          permittedRoles: [12, 9, 11, 2, 1],
        },
      },
      {
        path: '/social-network/leads/recovery',
        name: 'recovery-leads-social-network',
        component: () => import('@/views/social-network/views/leads/tabs/recovery-leads/RecoverySnMain.vue'),
        redirect: { name: 'sn-list-leads-business' },
        permittedRoles: [12, 9, 11, 2, 1],
        children: [
          {
            path: '',
            redirect: { name: 'sn-list-leads-business' },
            permittedRoles: [12, 9, 11, 2, 1],
          },
          {
            path: 'business',
            component: () => import('@/views/social-network/views/recovery/components/GrillaProgram.vue'),
            name: 'sn-list-leads-business',
            meta: {
              program: 1,
              pageTitle: 'Recovery',
              breadcrumb: [
                {
                  text: 'Recovery'
                },
                {
                  text: 'Business',
                  active: true
                },
              ],
              permittedRoles: [12, 9, 11, 2, 1],
            },
          },
          {
            path: 'credit-experts',
            component: () => import('@/views/social-network/views/recovery/components/GrillaProgram.vue'),
            name: 'sn-list-leads-credit-experts',
            meta: {
              program: 3,
              pageTitle: 'Recovery',
              breadcrumb: [
                {
                  text: 'Recovery'
                },
                {
                  text: 'Credit Experts',
                  active: true
                },
              ],
              permittedRoles: [12, 9, 11, 2, 1],
            },
          },
          {
            path: 'tax-research',
            component: () => import('@/views/social-network/views/recovery/components/GrillaProgram.vue'),
            name: 'sn-list-leads-tax-research',
            meta: {
              program: 5,
              pageTitle: 'Recovery',
              breadcrumb: [
                {
                  text: 'Recovery'
                },
                {
                  text: 'Tax Research',
                  active: true
                },
              ],
              permittedRoles: [12, 9, 11, 2, 1],
            },
          },
          {
            path: 'boost-credit',
            component: () => import('@/views/social-network/views/recovery/components/GrillaProgram.vue'),
            name: 'sn-list-leads-boost-credit',
            meta: {
              program: 2,
              pageTitle: 'Recovery',
              breadcrumb: [
                {
                  text: 'Recovery'
                },
                {
                  text: 'Boost Credit',
                  active: true
                },
              ],
              permittedRoles: [12, 9, 11, 2, 1],
            },
          },
          {
            path: 'debt-solution',
            component: () => import('@/views/social-network/views/recovery/components/GrillaProgram.vue'),
            name: 'sn-list-leads-debt-solution',
            meta: {
              program: 4,
              pageTitle: 'Recovery',
              breadcrumb: [
                {
                  text: 'Recovery'
                },
                {
                  text: 'Debt Solution',
                  active: true
                },
              ],
              permittedRoles: [12, 9, 11, 2, 1],
            },
          },
          {
            path: 'not-call',
            component: () => import('@/views/social-network/views/recovery/components/GrillaProgram.vue'),
            name: 'sn-list-leads-not-call',
            meta: {
              program: 0,
              pageTitle: 'Recovery',
              breadcrumb: [
                {
                  text: 'Recovery'
                },
                {
                  text: 'Not Call',
                  active: true
                },
              ],
              permittedRoles: [12, 9, 11, 2, 1],
            },
          },

        ],
      },
    ],
  },
  {
    path: "leads/new/dashboard/:id",
    component: () => import(/* webpackChunkName: "SnDashboardNewLead" */ "./tabs/new-leads/views/dashboard/Dashboard.vue"),
    name: "sn-dashboard-new-lead",
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Leads",
          to: { name: "sn-list-new-leads" },
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      // permittedRoles: [12, 9, 11, 2, 1],
    },
  },
  {
    path: "leads/old/dashboard/:id",
    component: () => import(/* webpackChunkName: "SnDashboardOldLead" */ "./tabs/old-leads/views/dashboard/Dashboard.vue"),
    name: "sn-dashboard-old-lead",
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Leads",
        },
        {
          text: "Old",
          to: { name: "sn-list-old-leads" },
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      permittedRoles: [12, 9, 11, 2, 1],
    },
  },
  {
    path: "leads/old/edit",
    component: () => import(/* webpackChunkName: "SnEditOldLead" */ "./tabs/old-leads/views/edit/Edit.vue"),
    name: "sn-edit-old-lead",
    meta: {
      pageTitle: "Edit Lead",
      breadcrumb: [
        {
          text: "Leads",
        },
        {
          text: "Old",
          to: { name: "sn-list-old-leads" },
        },
        {
          text: "Edit Lead",
          active: true,
        },
      ],
      permittedRoles: [12, 9, 11, 2, 1],
    },
  },
]
