import { amgApi } from "@/service/axios";

class DepartmentExpenses {
  constructor() {
    this.url = "/expenses";
    this.urlDescription = `${this.url}/description-expenses`;
    this.urlPayable = `${this.url}/payable-expenses`;
    this.urlRP = `${this.url}/recurring-payments`;
  }
  async store(params) {
    const { data } = await amgApi.post(`${this.url}/store`, params);
    return data;
  }
  async update(id, params) {
    const { data } = await amgApi.post(`${this.url}/update/${id}`, params);
    return data;
  }
  async getDescriptionsExpenses(params) {
    const { data } = await amgApi.post(`${this.urlDescription}/list`, params);
    return data;
  }
  async insertDescriptionExpenses(params) {
    try {
      const { data } = await amgApi.post(
        `${this.urlDescription}/store`,
        params
      );
      return data;
    } catch (error) {
      const { response } = error;
      throw response.data;
    }
  }

  async getPayableExpenses(params) {
    const { data } = await amgApi.post(`${this.urlPayable}/list`, params);
    return data;
  }
  async getPayableExpensesAddresses(params) {
    const { data } = await amgApi.post(
      `${this.urlPayable}/list-addresses`,
      params
    );
    return data;
  }
  async insertPayableExpensesAddresses(params) {
    try {
      const { data } = await amgApi.post(
        `${this.urlPayable}/store-addresses`,
        params
      );
      return data;
    } catch (error) {
      const { response } = error;
      throw response.data;
    }
  }
  async updatePayableExpensesAddresses(params) {
    try {
      const { data } = await amgApi.post(
        `${this.urlPayable}/update-addresses`,
        params
      );
      return data;
    } catch (ex) {
      const { response } = error;
      throw response.data;
    }
  }
  async insertPayableExpenses(params) {
    try {
      const { data } = await amgApi.post(`${this.urlPayable}/store`, params);
      return data;
    } catch (error) {
      const { response } = error;
      throw response.data;
    }
  }
  async getAllCardsExpensesByModule(params) {
    const { data } = await amgApi.post(`${this.url}/list-cards`, params);
    return data;
  }
  async getAllCheckExpensesByModule(params) {
    const { data } = await amgApi.post(`${this.url}/list-checks`, params);
    return data;
  }

  async getRecurringPayments(params) {
    const { data } = await amgApi.post(`${this.urlRP}/index`, params);
    return data;
  }
  async getExpesesToReview(params) {
    const { data } = await amgApi.post(
      `${this.urlRP}/get-expenses-to-review`,
      params
    );
    return data;
  }
  async getExpensesToReviewCounter(params) {
    const { data } = await amgApi.post(
      `${this.urlRP}/get-expenses-to-review-counter`,
      params
    );
    return data;
  }
  async sendToCEO(params) {
    const { data } = await amgApi.post(`${this.urlRP}/send-to-ceo`, params);
    return data;
  }
  async setIsActive(params) {
    const { data } = await amgApi.post(`${this.urlRP}/set-is-active`, params);
    return data;
  }
  async saveExpense(params) {
    const { data } = await amgApi.post(`${this.urlRP}/save-expense`, params);
    return data;
  }
  async getClientAccounts(params) {
    const { data } = await amgApi.post(
      `${this.url}/search-client-accounts`,
      params
    );
    return data;
  }
  async getDetailDepartmentExpenses(params) {
    try {
      const { data } = await amgApi.post(`${this.url}/department/show`, params);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getDepartmentExpenses(params) {
    try {
      const { data } = await amgApi.post(
        `${this.url}/department/index`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getCountersExpensesDashboardClient(params) {
    try {
      const { data } = await amgApi.post(
        `${this.url}/counters/count-expenses-by-client`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async existCardModule(params) {
    try {
      const { data } = await amgApi.post(
        `${this.url}/department/exist-card-module`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCountersCheckExpensesDashboardClient(params) {
    try {
      const { data } = await amgApi.post(
        `${this.url}/counters/count-check-expenses-by-client`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCountersECheckExpensesDashboardClient(params) {
    try {
      const { data } = await amgApi.post(
        `${this.url}/counters/count-echeck-expenses-by-client`,
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTotalExpensesByClientAccount(params) {
    const { data } = await amgApi.post(
      `${this.url}/counters/count-total-expenses-by-client`,
      params
    );
    return data;
  }

  async getAccountData() {
    try {
      const { data } = await amgApi.get(`/ceo/dashboard/expenses/department/request/e-check/account-data`);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
export default new DepartmentExpenses();
