export default {
  path: 'initial-payment',
  name: 'initial-payment-administration',
  component: () => import(/* webpackChunkName: "AdministrationInitial Payment" */ '@/views/administration/views/initial-payment/InitialPayment.vue'),
  redirect: { name: 'initial-payment-pending-administration' },
  meta: {
    pageTitle: 'Initial Payment',
    route: 'administration',

    breadcrumb: [
      {
        text: 'Initial Payment',
        active: true,
      },

    ],
    permittedRoles: [1, 2, 6],
  },
  children: [
    {
      path: 'pending',
      name: 'initial-payment-pending-administration',
      component: () => import(/* webpackChunkName: "BusinessNewClaim" */ '@/views/administration/views/initial-payment/InitialPaymentGrid.vue'),
      meta: {
        currentTab: 1,
        pageTitle: 'Initial Payment',
        route: 'administration',
        breadcrumb: [
          {
            text: 'Pending',
            active: true,
          },
        ],
        permittedRoles: [1, 2, 6],
      },
    },
    {
      path: 'completed',
      name: 'initial-payment-completed-administration',
      component: () => import(/* webpackChunkName: "BusinessPendingInitial Payment" */ '@/views/administration/views/initial-payment/InitialPaymentGrid.vue'),
      meta: {
        pageTitle: 'Initial Payment',
        route: 'administration',
        currentTab: 2,
        breadcrumb: [
          {
            text: 'Completed',
            active: true,
          },
        ],
        permittedRoles: [1, 2, 6],
      },
    },

  ],
}
