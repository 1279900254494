export default {
  path: "/cedigital/financial/helpdesk/",
  name: "helpdesk-ce-financial",
  component: () => import("./Helpdesk.vue"),
  meta: {
    pageTitle: "Help Desk",
    module: 23,
    breadcrumb: [
      {
        text: "Help Desk",
        active: true,
      },
    ],
    onlyChief: true,
  },
}
