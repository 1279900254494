import { saleMadeTabs } from "@/views/commons/components/sales-made/router/sale-made.router";

export default [
  {
    path: "sales-made",
    name: "boost-credit-sales-made",
    redirect: { name: "sales-made-boostcredit-lead" },
    component: () =>
      import(/* webpackChunkName: "BCSalesMadeMain" */ "@/views/boost-credit/views/sales-made/SalesMade.vue"),
    meta: {
      program: 2,
      permittedRoles: [1, 2, 3],
      pageTitle: "Sales Made",
      breadcrumb: [
        {
          text: "Sales Made",
        },
      ],
    },
    // children: [
    //   {
    //     path: "add-change",
    //     name: "sales-made-boostcredit-add-change",
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "BCSalesMadeAddChange" */ "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue"
    //       ),
    //     meta: {
    //       program: 2,
    //       permittedRoles: [1, 2, 3],
    //       pageTitle: "Sales Made",
    //       breadcrumb: [
    //         {
    //           text: "Sales Made",
    //           active: true,
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     path: "add-sale",
    //     name: "sales-made-boostcredit-add-sale",
    //     component: () =>
    //       import(
    //         /* webpackChunkName: "BCSalesMadeAddSale" */ "@/views/commons/components/sales-made/add-sale/components/SalesMadeAddSaleComponent.vue"
    //       ),
    //     meta: {
    //       type: 0,
    //       program: 2,
    //       permittedRoles: [1, 2, 3],
    //       pageTitle: "Sales Made",
    //       breadcrumb: [
    //         {
    //           text: "Sales Made",
    //           active: true,
    //         },
    //       ],
    //     },
    //   },
    // ],
    children: saleMadeTabs("boostcredit")
  },
];
