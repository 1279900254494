export default {
  path: "ticket",
  name: "helpdesk-rrhh",
  component: () =>
    import(/* webpackChunkName: "RrhhHelpdesk" */ "./Helpdesk.vue"),
  meta: {
    pageTitle: "Help Desk",
    breadcrumb: [
      {
        text: "Help Desk",
        active: true,
      },
    ],
    onlyChief: true,
  },
}
