import store from '@/store';
import chargeBackV2Service from '@/views/commons/components/charge-back_v2/service/charge.back.service.js';
import SettingsService from '@/views/management/views/approval-process/permissions/service/settings.service';
import otherPaymentsService from '@/views/management/views/approval-process/other-payments/other-payments.service';
import ClaimsV2Service from '@/views/commons/components/claims_v2/services/claims-v2.service.js';
import employeeClaims from '@/views/commons/commons-counter';
import RequestService from '@/views/commons/components/request/services/request.service.js';
import Notifications from '@/service/notification/index';
import ServicesRequestTransactions from '@/views/management/views/requests-transactions/services/request-transactions.services.js';
import ManualOfFunctionService from '@/views/commons/components/manual-of-functions/services/manuals-of-functions.service';
import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';
import LoansService from '../commons/components/loans/services/loans.service';
import RequestClientStatusServices from '@/views/management/views/request-client-status/services/RequestClientStatus.services.js';

const loanCounter = async () => {
  const params = {
    id_module: store.state.auth.currentUser.modul_id,
    page: 1,
    status: 1,
    type: 4,
  };
  const data = await LoansService.getCounterLoan(params);

  const payload = {
    routeName: 'loans-management',
    tag: data[0].counter,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const chargebackCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;

  const params = {
    module_id: module,
  };

  const {
    in_process, remove_cb, payment_client, pending, observed, sent,
  } = await chargeBackV2Service.getChargebackCounters(params);

  const solution = remove_cb + payment_client;
  const dispute = pending + observed + sent;
  const counterSide = in_process + solution + dispute;

  const payload = {
    routeName: 'charge-back',
    tag: counterSide,
    chargebackCounter,
  };

  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const mofcounter = async () => {
  // const module = store.state.auth.currentUser.modul_id;

  // const params = {
  //   module_id: module,
  // };

  const data = await ManualOfFunctionService.getPendigMOFReview();

  const payload = {
    routeName: 'manual-of-functions-administration',
    tag: data.pending,
  };

  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const settingsPermissionCount = async () => {
  const { data } = await SettingsService.getCountPermissionPending();
  const payload = {
    routeName: 'management-settings',
    tag: data[0].pending,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const settlementsCounter = async () => {
  const res = await amgApi.post('/debt-solution/get-active-offers-counter', {
    client_account_id: null,
    user_id: store.state.auth.currentUser.user_id,
    module_id: 16,
  });

  const payload = {
    routeName: 'settlements-pending',
    tag: res.data,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const otherPaymentsCounter = async () => {
  const res = await otherPaymentsService.getCeOtherPaymentsPendingCounter();
  const payload = {
    routeName: 'other-payments',
    tag: res.data,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const counterApprovalProcess = async () => {
  await store.dispatch('SellerPermissionsStore/GET_APPROVAL_PROCESS');
  await store.dispatch('SellerPermissionsStore/A_COUNTER_DISAPPROVED_SALE');
  const payload = {
    routeName: 'management-approved',
    tag: parseInt(store.getters['SellerPermissionsStore/S_COUNTER_APPROVAL_PROCESS']) + store.getters['SellerPermissionsStore/G_COUNTER_DISAPPROVED_SALE'],
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

// const counterApprovalProcessSettlementsMadePending = async () => {
//   await store.dispatch(
//     'SettlementApprovalStore/GET_COUNTER_SETTLEMENTS_MADE_PENDING',
//     { payment_order_status_id: 1 },
//   );
// };

// const counterApprovalProcessSettlementsMadeInsufficient = async () => {
//   await store.dispatch(
//     'SettlementApprovalStore/GET_COUNTER_SETTLEMENTS_MADE_INSUFFICIENT',
//     { payment_order_status_id: 2 },
//   );
// };

const counterSalaryRecordManagement = async () => {
  await store.dispatch('SalaryRecordStore/A_GET_COUNTER_SALARY');
  const payload = {
    routeName: 'salary-record',
    tag: store.getters['SalaryRecordStore/G_COUNT_MANAGEMENT'],
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const counterOtherPayments = async () => {
  await store.dispatch('other-payment-store/A_COUNTER_OP', {
    module_id: store.state.auth.currentUser.modul_id,
  });
  const payload = {
    routeName: 'other-payments-management',
    tag: store.getters['other-payment-store/G_SUM_COUNTER_OP'],
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'managment-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const claimsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;

  const params = {
    module_id: module,
  };

  const { pending_claims_3 } = await ClaimsV2Service.getClaimsCounters(params);

  const sideCounter = pending_claims_3;

  const payload = {
    routeName: 'claims-management',
    tag: sideCounter,
  };

  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const countPendingRequestOfTransaction = async () => {
  const { data } = await ServicesRequestTransactions.countPendingRequestOfTransaction();
  const payload = {
    routeName: 'request-transactions',
    tag: data.data,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: 'request-management',
    tag: data[0].counter,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};
const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'managment-recruitment-process',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({ user_id, moduleId });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: 'management-customer-tickets',
      tag: data[0].v_total_open,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const RequestClientStatusCounter = async () => {
  const module_id = store.state.auth.currentUser.modul_id;
  const data = await  RequestClientStatusServices.getCountPendingChangeStatus({module_id : module_id}); 
    const payload = {
      routeName: 'mg-request-client-status',
      tag: data.data,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload); 
};
const allCountersManagement = async () => {
  Promise.all([
    countPendingRequestOfTransaction(),
    loanCounter(),
    chargebackCounter(),
    projectsCounter(),
    settingsPermissionCount(),
    settlementsCounter(),
    otherPaymentsCounter(),
    counterApprovalProcess(),
    // counterApprovalProcessSettlementsMadePending(),
    // counterApprovalProcessSettlementsMadeInsufficient(),
    counterSalaryRecordManagement(),
    counterOtherPayments(),
    employeeClaims('mg-employee-claims'),
    requestNotAsignedCounter(),
    claimsCounter(),
    mofcounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
    RequestClientStatusCounter(),
  ]);
};

export default allCountersManagement;
