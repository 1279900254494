export default [
    {
        path: "loans",
        name: "loans-logistic",
        redirect: "/logistic/loans/my-loans",
        component: () =>
            import(
                /* webpackChunkName: "LogisticLoans" */ "@/views/commons/components/loans/LoansComponent.vue"
            ),
        children: [
            {
                path: "my-loans",
                name: "my-loans-logistic",
                component: () =>
                    import(
                        /* webpackChunkName: "LogisticMyLoans" */ "@/views/commons/components/loans/LoansView.vue"
                    ),
                meta: {
                    tab: 1,
                    pageTitle: "Loans",
                    breadcrumb: [
                        {
                            text: "My Loans",
                            active: true,
                        },
                    ],
                    route: "logistic",
                },
            },
            {
                path: "loans-module",
                name: "loans-module-logistic",
                component: () =>
                    import(
                        /* webpackChunkName: "LogisticLoansModule" */ "@/views/commons/components/loans/LoansView.vue"
                    ),
                meta: {
                    tab: 2,
                    route: "logistic",
                    pageTitle: "Loans",
                    breadcrumb: [
                        {
                            text: "Loans By Module",
                            active: true,
                        },
                    ],
                },
            },
        ],
        meta: {
            tab: 1,
            route: "logistic",
        },
    },
];
