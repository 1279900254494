export default [
  {
    path: "status-change",
    name: "credit-experts-status-change",
    component: () => import(/* webpackChunkName: "CreditExpertsStatusChange" */ "@/views/credit-experts/views/status-change/components/tab/StatusChangeTab.vue"),
    redirect: { name: "credit-experts-status-change-tab-loyal" },
    meta: {
      permittedRoles: [1, 2],
      isClientsTab: true,
      pageTitle: "Status Change",
      breadcrumb: [
        {
          text: "Status Change",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "loyal",
        name: "credit-experts-status-change-tab-loyal",
        component: () => import(/* webpackChunkName: "CreditExpertsStatusChangeTabLoyal" */ "@/views/credit-experts/views/status-change/components/Tables/StatusChangeGrid.vue"),
        meta: {
          permittedRoles: [1, 2],
          isClientsTab: true,
          tabValue: 1,
          pageTitle: "Status Change",
          breadcrumb: [
            {
              text: "Loyal",
              active: true,
            },
          ],
        },
      },
      {
        path: "hold",
        name: "credit-experts-status-change-tab-hold",
        component: () => import(/* webpackChunkName: "CreditExpertsStatusChangeTabHold" */ "@/views/credit-experts/views/status-change/components/Tables/StatusChangeGrid.vue"),
        meta: {
          permittedRoles: [1, 2],
          isClientsTab: true,
          tabValue: 2,
          pageTitle: "Status Change",
          breadcrumb: [
            {
              text: "Hold",
              active: true,
            },
          ],
        },
      }
    ],
  },
];
