import ProductRequestRouter from "@/views/quality/views/request/request.router.js";
import LeadsRouter from "@/views/quality/views/leads/leads.router";
import HelpDeskRouter from "@/views/quality/views/helpdesk/helpdesk.route";
import LoansRouter from "@/views/quality/views/loans/loans.router";
import FileManagerRouter from "@/views/quality/views/file-manager/file-manager.router";
import ClientsRouter from "@/views/quality/views/clients/clients.router";
import QualityAttendanceRouter from "@/views/quality/views/attendance/quality-attendance.router";
import SettingsRouter from "@/views/quality/views/settings/settings.router";
import ClaimsROuter from "@/views/quality/views/claims/claims.router";
import InquiriesRouter from "@/views/quality/views/inquiries/inquiries.router.js";
import DashboardRouter from "@/views/quality/views/dashboard/router/dashboard.router";
import EmployeeClaimsRouter from "@/views/quality/views/employee-claims/router/employee-claims.router";
import QualityMain from "@/views/quality/views/QualityMain.vue";
//import QualityProjectsRouter from "@/views/quality/views/projects/quality-projects.router";
import CallLogRouter from "@/views/quality/views/ring-central/call-log/call-log.router.js";
import CourtInfoRouter from "@/views/quality/views/cour-info/router/ds-court-info.router";
import DashboardCalls from "@/views/quality/views/ring-central/dashboard-calls/dashboard.router.js";
import MOFRouter from "@/views/quality/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/quality/views/recruitment-process/quality-recruitment_process.router";
import RiskyClientsRouter from "@/views/quality/views/risky-clients/router/risky.router";
import CustomerTicketsRouter from "@/views/quality/views/customer-tickets/quality-customer-tickets.router";
import CallsReviewRouter from "@/views/quality/views/calls-review/calls-review.router.js";
import RequestNcrRouter from "@/views/quality/views/request-ncr/request-ncr.router";
import NotesWC from "@/views/quality/views/notes-without-calls/notes-wc.router.js";
import RequestClientStatusRouter from "@/views/quality/views/request-client-status/router/request-client-status.router.js";
import SalesMade from "@/views/quality/views/sales-made/router/sales-made.router.js";
import CallImpressionRouter from "@/views/quality/views/call-impression/router/call-impression.router.js";

const routes = [
  {
    path: "/quality",
    redirect: { name: "dashboard-quality" },
    meta: {
      module: 18,
    },
    component: QualityMain,
    children: [
      ...DashboardRouter,
      ...ClientsRouter,
      ...LeadsRouter,
      ...SalesMade,
      ...RequestNcrRouter,
      ...LoansRouter,
      ...ProductRequestRouter,
      FileManagerRouter,
      HelpDeskRouter,
      ...QualityAttendanceRouter,
      //...QualityProjectsRouter,
      ...EmployeeClaimsRouter,
      ...SettingsRouter,
      ...ClaimsROuter,
      ...InquiriesRouter,
      ...CallLogRouter,
      ...CourtInfoRouter,
      ...DashboardCalls,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...CustomerTicketsRouter,
      ...RiskyClientsRouter,
      ...CallsReviewRouter,
      ...NotesWC,
      ...RequestClientStatusRouter,
      ...CallImpressionRouter,
    ],
  },
];

export default routes;
