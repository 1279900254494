export default {
	path: "zeropayment",
	name: "zeropayment-tax-research",
	component: () => import(/* webpackChunkName: "TaxResearchZeroPayment" */ "@/views/tax-research/views/zero-payment/index.vue"),
	meta: {
		pageTitle: "Zero Payment",
		breadcrumb: [
			{
				text: "Zero Payment",
				active: true,
			},
		],
	},
}
