import store from "@/store";
import Notifications from "@/service/notification/index";
import ScheduleService from "@/views/commons/components/schedules/services/schedules.service";
import ZeroPaymentService from "@/views/bookeeping/views/zero-payment/service/zero-payment.service";
import chargeBackV2Service from "@/views/commons/components/charge-back_v2/service/charge.back.service.js";
import employeeClaims, {
  salesMadeCounter,
} from "@/views/commons/commons-counter";
import RequestService from "@/views/commons/components/request/services/request.service.js";
import RecruitmentService from "@/views/commons/components/recruitment-process/services/recruiment.service";

const schedulesCounter = async () => {
  const params = {
    moduleid: 14,
  };
  const data = await ScheduleService.getSchedulesCounter(params);
  if (data) {
    const payload = {
      routeName: "schedules-bookeeping",
      tag: data.grandTotal > 99 ? "99+" : data.grandTotal,
    };
    store.dispatch("SchedulesStore/A_SET_COUNTERS_TABS_SCHEDULES", data);
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const zeroPaymentCounter = async () => {
  const params = {
    id_global: store.state.auth.currentUser.user_id,
    id_status: getStatusId(),
    id_modul: 8, // Bookeeping
    id_role: store.state.auth.currentUser.role_id,
  };

  const { data } = await ZeroPaymentService.getZeroPaymentCounter(params);
  if (data.length > 0) {
    const payload = {
      routeName: "zero-payment-bookeeping",
      tag: data.length,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const getStatusId = () => {
  const { role_id } = store.state.auth.currentUser;
  if (role_id == 1) {
    return 3;
  }
  if (role_id == 2) {
    return 2;
  }
};

const chargebackCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;

  const params = {
    module_id: module,
  };

  const { in_process, remove_cb, payment_client, pending, observed } =
    await chargeBackV2Service.getChargebackCounters(params);

  const solution = remove_cb + payment_client;
  const dispute = pending + observed;
  const counterSide = in_process + solution + dispute;

  const payload = {
    routeName: "bookeeping-charge-back",
    tag: counterSide,
  };

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: "request-bookeeping",
    tag: data[0].counter,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "bookeeping-projects",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: "bookeeping-recruitment-process",
      tag: data.data[0].cn,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({
    user_id,
    moduleId,
  });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: "bookeeping-customer-tickets",
      tag: data[0].v_total_open,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const allCountersBookEeping = async () => {
  Promise.all([
    salesMadeCounter("bookeeping-sales-made"),
    schedulesCounter(),
    projectsCounter(),
    zeroPaymentCounter(),
    chargebackCounter(),
    employeeClaims("bookeeping-employee-claims"),
    requestNotAsignedCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
  ]);
};
export default allCountersBookEeping;
