import store from '@/store';
import Vue from 'vue';

const ChangeStatusRequestNotificationSocket = () => {
    window.socket.bind('change-status-request-notification', async data => {
        let user_id = store.state.auth.currentUser.user_id;
        if(data.created_by === user_id){
            const icon = "InfoIcon";
            let title = "Status request";
            let description = "Your request for items to logistics area was "+data.status;
            // const htmlAlert = `${`${'<img src="/assets/images/icons/swal/info.svg" style="margin-bottom: 10px;" alt="alert-info-image">'
            //     + `<div><h2 style="font-weight: 600;">${
            //     title
            //     }</h2></div>`
            //     + '<div style="text-align: center;">'
            //     + '<div style="font-weight: bolder;">'}${
            //     description
            //     }
            //  </div>`
            // } </div>`;

            Vue.prototype.$amgAlert({
              title: title,
              subtitle: description,
              data: [
                  // { icon: 'UserIcon', title: 'EMPLOYEE', description: description },
                  // { icon: 'ListIcon', title: 'DESCRIPTION', description: description },
              ],
          

              okTitle: 'OK',
              icon,
              ok: () => {
              // const goSection = module_to_id == 19 ? "list-claims-pending-logistic" : "list-claims-pending-rrhh";
              //     if (router.currentRoute.name !== goSection) {                    
              //         router.push({ name: goSection });
              //     }
                  window.amgApi.post('/commons/close-all-swal', data);
              },
          });

            // const res = await Vue.swal.fire({
            //   html: htmlAlert,
            //   confirmButtonText: 'Ok',
            //   allowOutsideClick: true,
            //   showConfirmButton: true,
            // });
            // if (res.value) {
            //   await window.amgApi.post('/commons/close-all-swal', data);
            // }
        }
    })
};
export default ChangeStatusRequestNotificationSocket;