import { potencialTabRoute } from "@/views/commons/components/potencial-appointment/router/potencial.tab.route";

export default [
  {
    path: "potential",
    name: "boost-credit-potencial-appointment",
    redirect: { name: "bc-potencial-appointment-in-process" },
    component: () => import("@/views/commons/components/potencial-appointment/views/PotencialMain"),
    meta: {
      pageTitle: "Potential",
      router: "bc-potencial-appointment",
      breadcrumb: [
        {
          text: "Potential",
          active: true,
        },
      ],
      routeInProcess: "bc-potencial-appointment-in-process",
      routeFinished: "bc-potencial-appointment-finished",
      routeCompleted: "bc-potencial-appointment-completed",
      routeRejected: "bc-potencial-appointment-rejected",
      routeExpired: "bc-potencial-appointment-expired",
      program: 2
    },
    children: potencialTabRoute("bc-potencial-appointment", "Potential")
  }
];