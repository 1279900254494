import Vue from "vue";

Vue.filter("myPrograms", (param) => {
  if (param == "Paragon") return "/assets/images/logos/logo-paragon.png"
  return null;
});

Vue.filter("programUrl", (param) => {

  const urlOriginalSoft = process.env.VUE_APP_ORIGINAL_SOFT;

  let arrRoutes = [
    "administration/clients/account/",
    "bussiness/clients/account/",
    "boostcredit/clients/account/",
    "creditexperts/clients/account/",
    "debtsolution/clients/account/",
    "taxresearch/clients/account/",
    "claimdepartment/clients/account/",
  ];

  return urlOriginalSoft + arrRoutes[param];

})