import Pusher from "pusher-js"

import {

  AppointmentNotice,
  AppointmentAlert,
  AppointmentToast,
  CloseAllAppointmentAlert,
  CloseAllAppointmentNotice

} from "@/views/commons/components/appointments/sockets/Appointments.socket"

const subscribeAppointmentsSocket = (socket) => {
  try {
    socket.subscribe("appointments-notifications")
    AppointmentToast()
    AppointmentNotice()
    AppointmentAlert()
    CloseAllAppointmentAlert()
    CloseAllAppointmentNotice()
  } catch (error) {
    console.error(error, "error")
  }
}

export default subscribeAppointmentsSocket
