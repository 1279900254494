export default [
  {
    path: 'employees',
    name: 'employees-management',
    component: () => import(
      /* webpackChunkName: "ManaEmployees" */ '@/views/management/views/employees/Employees.vue'
    ),
    redirect: { name: 'list-employees-management' },
    children: [
      {
        path: '',
        redirect: { name: 'list-employees-management' },
      },
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "ManaEmployeesList" */ '@/views/management/views/employees/employees-module/employees-list/EmployeesList'
        ),
        name: 'list-employees-management',
        meta: {
          pageTitle: 'Employees',
          breadcrumb: [
            {
              text: 'Employees',
              active: true,
            },
          ],
          employeesRoute: 'employees-management',
          employeeRoute: 'information-employee-management',
          listEmployeesRoute: 'list-employees-management',
          listDepartmentRoute: 'list-department-management',
          basicInformationRoute: 'basic-information-management',
          filesRoute: 'files-management',
          jobDetailsRoute: 'job-details-management',
          scheduleRoute: 'schedule-management',
          PendingPensionFund: 'list-pending-pension-fund-management',
        },
      },
      {
        path: 'department',
        component: () => import(
          /* webpackChunkName: "ManaDeparmentsList" */ '@/views/management/views/employees/employees-module/departments-list/DepartmentsList'
        ),
        name: 'list-department-management',
        meta: {
          pageTitle: 'Department',
          breadcrumb: [
            {
              text: 'department',
              active: true,
            },
          ],
          employeesRoute: 'employees-management',
          employeeRoute: 'information-employee-management',
          listEmployeesRoute: 'list-employees-management',
          listDepartmentRoute: 'list-department-management',
          basicInformationRoute: 'basic-information-management',
          filesRoute: 'files-management',
          jobDetailsRoute: 'job-details-management',
          scheduleRoute: 'schedule-management',
          PendingPensionFund: 'list-pending-pension-fund-management',
        },
      },
      {
        path: 'information/:id',
        component: () => import(
          /* webpackChunkName: "ManaInformationEmployee" */ '@/views/management/views/employees/employees-module/employees-list/modals/information-employee/InformationEmployee.vue'
        ),
        name: 'information-employee-management',
        redirect: { name: 'basic-information-management' },
        children: [
          {
            path: '',
            component: () => import(
              /* webpackChunkName: "ManaBasicInformation" */ '@/views/management/views/employees/employees-module/employees-list/modals/options/basic-information/BasicInformation'
            ),
            name: 'basic-information-management',
            meta: {
              pageTitle: 'Employees',
              breadcrumb: [
                {
                  text: 'Basic Information',
                  active: true,
                },
              ],
              employeesRoute: 'employees-management',
              employeeRoute: 'information-employee-management',
              listEmployeesRoute: 'list-employees-management',
              listDepartmentRoute: 'list-department-management',
              basicInformationRoute: 'basic-information-management',
              filesRoute: 'files-management',
              jobDetailsRoute: 'job-details-management',
              scheduleRoute: 'schedule-management',
              PendingPensionFund: 'list-pending-pension-fund-management',
            },
          },
          {
            path: 'files',
            component: () => import(
              /* webpackChunkName: "ManaFilesManagment" */ '@/views/management/views/employees/employees-module/employees-list/modals/options/files/Files'
            ),
            name: 'files-management',
            meta: {
              pageTitle: 'Employees',
              breadcrumb: [
                {
                  text: 'Files',
                  active: true,
                },
              ],
              employeesRoute: 'employees-management',
              employeeRoute: 'information-employee-management',
              listEmployeesRoute: 'list-employees-management',
              listDepartmentRoute: 'list-department-management',
              basicInformationRoute: 'basic-information-management',
              filesRoute: 'files-management',
              jobDetailsRoute: 'job-details-management',
              scheduleRoute: 'schedule-management',
              PendingPensionFund: 'list-pending-pension-fund-management',
            },
          },
          {
            path: 'job',
            component: () => import(
              /* webpackChunkName: "ManaJobDetails" */ '@/views/management/views/employees/employees-module/employees-list/modals/options/job-details/JobDetails'
            ),
            name: 'job-details-management',
            meta: {
              pageTitle: 'Employees',
              breadcrumb: [
                {
                  text: 'Information',
                  active: true,
                },
              ],
              employeesRoute: 'employees-management',
              employeeRoute: 'information-employee-management',
              listEmployeesRoute: 'list-employees-management',
              listDepartmentRoute: 'list-department-management',
              basicInformationRoute: 'basic-information-management',
              filesRoute: 'files-management',
              jobDetailsRoute: 'job-details-management',
              scheduleRoute: 'schedule-management',
              PendingPensionFund: 'list-pending-pension-fund-management',
            },
          },
          {
            path: 'schedule',
            component: () => import(
              /* webpackChunkName: "ManaSchedule" */ '@/views/management/views/employees/employees-module/employees-list/modals/options/schedule/Schedule'
            ),
            name: 'schedule-management',
            meta: {
              pageTitle: 'Employees',
              breadcrumb: [
                {
                  text: 'Schedule',
                  active: true,
                },
              ],
              employeesRoute: 'employees-management',
              employeeRoute: 'information-employee-management',
              listEmployeesRoute: 'list-employees-management',
              listDepartmentRoute: 'list-department-management',
              basicInformationRoute: 'basic-information-management',
              filesRoute: 'files-management',
              jobDetailsRoute: 'job-details-management',
              scheduleRoute: 'schedule-management',
              PendingPensionFund: 'list-pending-pension-fund-management',
            },
          },
        ],
      },
      {
        path: 'pending-pension-fund',
        component: () => import(/* webpackChunkName: "RrhhRegisterPensionFund" */ '@/views/management/views/employees/employees-module/pending-pension-fund/pendingList.vue'),
        name: 'list-pending-pension-fund-management',
        meta: {
          pageTitle: 'Pending Pension Fund',
          breadcrumb: [{
            text: 'Pending',
            active: true,
          }],
          employeesRoute: 'employees-management',
          employeeRoute: 'information-employee-management',
          listEmployeesRoute: 'list-employees-management',
          listDepartmentRoute: 'list-department-management',
          basicInformationRoute: 'basic-information-management',
          filesRoute: 'files-management',
          jobDetailsRoute: 'job-details-management',
          scheduleRoute: 'schedule-management',
          PendingPensionFund: 'list-pending-pension-fund-management',
        },
      },
    ],
    meta: {
      pageTitle: 'Employees',
      breadcrumb: [
        {
          text: 'Employees',
          active: true,
        },
      ],
      employeesRoute: 'employees',
      employeeRoute: 'information-employee',
      listEmployeesRoute: 'list-employees',
      listDepartmentRoute: 'list-department',
      basicInformationRoute: 'basic-information',
      filesRoute: 'files',
      jobDetailsRoute: 'job-details',
      scheduleRoute: 'schedule',
      PendingPensionFund: 'list-pending-pension-fund-management',
    },
  },
];
