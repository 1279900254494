import { amgApi } from "@/service/axios"

class welcomeActionService {
  async pusherAgentAction(params) {
    const response = await amgApi.post(
      "credit-experts-digital/pusher-agent-action",
      params
    )
    return response
  }

  async getNewDigitalClient(params) {
    const response = await amgApi.post(
      "credit-experts-digital/get-new-digital-client",
      params
    )
    return response
  }

  async getAgentActions(params) {
    const response = await amgApi.post(
      "credit-experts-digital/get-agent-actions",
      params
    )
    return response
  }

  async saveAgentAction(params) {
    const response = await amgApi.post(
      "credit-experts-digital/save-agent-action",
      params
    )
    return response
  }

  async saveAgentAlertTracking(params) {
    const response = await amgApi.post(
      "credit-experts-digital/save-agent-alert-tracking",
      params
    )
    return response
  }

  async getSecToLeaveAgentAction(params) {
    const response = await amgApi.post(
      "credit-experts-digital/get-sec-to-leave-agent-action",
      params
    )
    return response
  }

  async closeAllAgentSwals(params) {
    const response = await amgApi.post(
      "credit-experts-digital/close-all-agent-swals",
      params
    )
    return response
  }
}

export default new welcomeActionService()
