import { saleMadeTabs } from "@/views/commons/components/sales-made/router/sale-made.router";

export default [
  {
    path: "sales-made",
    name: "debtsolution-sales-made-home",
    redirect: { name: "sales-made-debtsolution-client" },
    component: () => import(/* webpackChunkName: "DSSalesMade" */ "@/views/debt-solution/views/sales-made/SalesMade.vue"),
    meta: {
      program: 4,
      pageTitle: "Sales Made",
      breadcrumb: [
        {
          text: "Sales Made",
        },
      ],
    },
    // children: [
    //   {
    //     path: "add-change",
    //     name: "sales-made-debtsolution-add-change",
    //     component: () => import(/* webpackChunkName: "DSSalesMadeAddChange" */ "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue"),
    //     meta: {
    //       program: 4,
    //       pageTitle: "Sales Made",
    //       breadcrumb: [
    //         {
    //           text: "Sales Made",
    //           active: true,
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     path: "add-sale",
    //     name: "sales-made-debtsolution-add-sale",
    //     component: () => import(/* webpackChunkName: "DSSalesMadeAddChange" */ "@/views/commons/components/sales-made/add-sale/components/SalesMadeAddSaleComponent.vue"),
    //     meta: {
    //       type: 0,
    //       program: 4,
    //       requiredNewAppr: true,
    //       pageTitle: "Sales Made",
    //       breadcrumb: [
    //         {
    //           text: "Sales Made",
    //           active: true,
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     path: "new",
    //     name: "sales-made-debtsolution-new",
    //     component: () => import(/* webpackChunkName: "CrmSalesMadeNew" */ "@/views/debt-solution/views/sales-made/components/NewComponent.vue"),
    //     redirect: { name: "sales-made-debtsolution-new-pending" },
    //     children: [
    //       {
    //         path: "pending",
    //         name: "sales-made-debtsolution-new-pending",
    //         component: () => import(/* webpackChunkName: "CrmSalesMadeNewPending" */ "@/views/debt-solution/views/sales-made/components/pending/PendingComponent.vue"),
    //         meta: {
    //           type: 0,
    //           tab: 1,
    //           program: 4,
    //           pageTitle: "Sales Made",
    //           breadcrumb: [
    //             {
    //               text: "New",
    //             },
    //             {
    //               text: "Pending",
    //               active: true,
    //             },
    //           ],
    //           permittedRoles: [1, 2],
    //         },
    //       },
    //       {
    //         path: "observed",
    //         name: "sales-made-debtsolution-new-observed",
    //         component: () => import(/* webpackChunkName: "CrmSalesMadeNewObserved" */ "@/views/debt-solution/views/sales-made/components/pending/PendingComponent.vue"),
    //         meta: {
    //           type: 0,
    //           tab: 2,
    //           pageTitle: "Sales Made",
    //           breadcrumb: [
    //             {
    //               text: "New",
    //             },
    //             {
    //               text: "Observed",
    //               active: true,
    //             },
    //           ],
    //           permittedRoles: [1, 2],
    //         },
    //       },
    //       {
    //         path: "approved",
    //         name: "sales-made-debtsolution-new-approved",
    //         component: () => import(/* webpackChunkName: "CrmSalesMadeNewApproved" */ "@/views/debt-solution/views/sales-made/components/pending/PendingComponent.vue"),
    //         meta: {
    //           type: 0,
    //           tab: 3,
    //           pageTitle: "Sales Made",
    //           breadcrumb: [
    //             {
    //               text: "New",
    //             },
    //             {
    //               text: "Approved",
    //               active: true,
    //             },
    //           ],
    //           permittedRoles: [1, 2],
    //         },
    //       },
    //       {
    //         path: "rejected",
    //         name: "sales-made-debtsolution-new-rejected",
    //         component: () => import(/* webpackChunkName: "CrmSalesMadeNewApproved" */ "@/views/debt-solution/views/sales-made/components/pending/PendingComponent.vue"),
    //         meta: {
    //           type: 0,
    //           tab: 6,
    //           pageTitle: "Sales Made",
    //           breadcrumb: [
    //             {
    //               text: "New",
    //             },
    //             {
    //               text: "Rejected",
    //               active: true,
    //             },
    //           ],
    //           permittedRoles: [1, 2],
    //         },
    //       }
    //     ],
    //   },
    // ],
    children: saleMadeTabs("debtsolution")
  },
]
