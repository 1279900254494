import ClientsServices from "@/views/credit-experts/views/clients/dashboard/services/clients.ce.dashboard.services";

export default {
  namespaced: true,
  state: {
    S_COUNT_ANALYSIS_CR: 0,
  },
  getters: {
    G_COUNT_ANALYSIS_CR(state) {
      return state.S_COUNT_ANALYSIS_CR;
    },
  },
  mutations: {
    M_COUNT_ANALYSIS_CR(state, payload) {
      state.S_COUNT_ANALYSIS_CR = payload;
    },
  },
  actions: {
    async A_COUNT_ANALYSIS_CR({ commit }, params) {
      try {
        const { data } = await ClientsServices.countAnalysisCr(params);
        commit("M_COUNT_ANALYSIS_CR", data);
      } catch (error) {
        throw error;
      }
    },
  },
};
