import Pusher from 'pusher-js';

import {
  sendTicketCustomerMessage,
  viewTicketCustomerMessage,
  viewMassTicketCustomerMessages,
  sendTicketToCeo,
  sendReactionCustomer,
  sendNotificationMeetingCeo,
} from '@/views/commons/components/customer-tickets/sockets/ticket-customer-message.socket';
import NotificationParticipantsToTicketCustomerStatus from '@/views/commons/participants-tickets/socket/NotificationParticipantsStatus.socket';

const subscribeClaimsSocket = socket => {
  try {
    socket.subscribe('ticket-costumer-messages-channel');
    sendTicketCustomerMessage();
    viewTicketCustomerMessage();
    viewMassTicketCustomerMessages();
    NotificationParticipantsToTicketCustomerStatus();
    sendTicketToCeo();
    sendReactionCustomer();
    sendNotificationMeetingCeo();
    // newClaimNotification()
    // SolvedClaimNotification()
    // ScaledClaimNotification()
    // ClaimNotificationDepartaments()
    // NewInquiryNotification()
    // SolvedInquiryNotification()
    // AnnulledClaimNotification()
    // CloseAllClaimNotification()
  } catch (error) {
    console.error(error, 'error');
  }
};

export default subscribeClaimsSocket;
