import { requestWpTabs } from "@/views/commons/components/request-workplan/router/request-workplan.tabs.js";

export default [
  {
    path: "request-workplan",
    name: "request-workplan-connection",
    redirect: { name: "connection-wp-pending" },
    meta: {
      pageTitle: "Request Workplan",
      breadcrumb: [
        {
          text: "Request Workplan",
          active: true,
        },
      ],
      routePending: "connection-wp-pending",
      routeCompleted: "connection-wp-completed",
      routeToRevision: "connection-wp-to-revision",
    },
    component: () =>
      import(
        "@/views/commons/components/request-workplan/RequestWorkplanMain.vue"
      ),
    children: requestWpTabs('connection')
  }
]