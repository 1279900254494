<!-- CeDigital -> Settings -> Permissions View 
    In this view the chief can see the seniority of the sellers and can change them.
-->
<template>
  <div>
    <div>
      <filter-slot
        :filter-principal="filterPrincipal"
        :filter="filter"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="refresh"
      >
        <template #table>
          <b-table
            slot="table"
            ref="refSettingsList"
            small
            no-provider-filtering
            :items="getSellers"
            :fields="visibleFields"
            primary-key="id"
            table-class="text-nowrap"
            responsive="sm"
            show-empty
            sticky-header="70vh"
            :busy.sync="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template v-slot:cell(permission)="data">
              <div
                b-row
                class="d-flex justify-content-center align-items-center"
              >
                <div b-col>
                  <select
                    @change="onChangeSeniority(data.item)"
                    v-model="data.item.typesenior_number"
                    :disabled="disabledPermission(data)"
                    class="form-control"
                    style="font-size: 11px; width: 110%"
                    v-if="data.item.status_permission_seller === 'DENIED'"
                  >
                    <option
                      v-for="option in options"
                      :key="option.value"
                      :value="option.value"
                      :selected="option.value == data.item.typesenior_number"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                  <select
                    v-else
                    @change="onChangeSeniority(data.item)"
                    v-model="data.item.type_pending_number"
                    :disabled="disabledPermission(data)"
                    class="form-control"
                    style="font-size: 11px; width: 110%"
                  >
                    <option
                      v-for="option in options"
                      :key="option.value"
                      :value="option.value"
                      :selected="option.value == data.item.type_pending_number"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
            </template>
            <template v-slot:cell(status_session)="data">
              <span v-if="data.item.status_session === 1">
                <feather-icon
                  icon="CircleIcon"
                  size="17"
                  style="fill: #00d25b; color: #00d25b"
                />
                Active
              </span>
              <span v-if="data.item.status_session === 2">
                <feather-icon
                  icon="CircleIcon"
                  size="17"
                  style="fill: #ff9f43; color: #ff9f43"
                />
                Busy
              </span>
              <span v-if="data.item.status_session === 3">
                <feather-icon
                  icon="CircleIcon"
                  size="17"
                  style="fill: #fc424a; color: #fc424a"
                />
                Away
              </span>
              <span
                v-if="
                  data.item.status_session === 4 ||
                  data.item.status_session === 0
                "
              >
                <feather-icon
                  icon="CircleIcon"
                  size="17"
                  style="fill: #82868b; color: #82868b"
                />
                Offline
              </span>
            </template>
            <template #cell(typesenior)="data">
              {{ data.item.typesenior }}
            </template>
            <template #cell(status_permission)="data">
              <div class="d-flex justify-content-center align-v-center">
                <b-badge
                  class="text-center w-100"
                  v-if="
                    data.item.status_permission_seller === 'DENIED' ||
                    data.item.status_permission_seller === 'CANCELED' ||
                    data.item.status_permission_seller === 'APPROVED' ||
                    data.item.status_permission_seller === 'PENDING'
                  "
                  :class="
                    data.item.status_permission_seller === 'DENIED' ||
                    data.item.status_permission_seller === 'CANCELED'
                      ? 'bg-light-danger'
                      : data.item.status_permission_seller === 'APPROVED'
                      ? 'bg-light-success'
                      : 'bg-light-warning'
                  "
                  pill
                >
                  {{ data.item.status_permission_seller }} ({{
                    data.item.typesenior_pending
                  }})
                </b-badge>
              </div>
            </template>
            <template #cell(tracking)="data">
              <div class="text-center">
                <b-button
                  variant="outline"
                  @click="openModalTracking(data.item)"
                >
                  <feather-icon
                    class="text-info"
                    v-b-tooltip.hover.right="'Tracking'"
                    icon="ListIcon"
                  >
                  </feather-icon>
                </b-button>
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </div>
    <modal-tracking
      v-if="modalTrackingState"
      :dataItem="dataItem"
      @hideModal="modalTrackingState = false"
    />
  </div>
</template>

<script>
import SalesSellerServices from "@/views/ce-digital/sub-modules/settings/views/permissions/service/sales-seller.services";
import ModalTracking from "@/views/ce-digital/sub-modules/settings/views/permissions/components/ModalTrackingSeller.vue";
import { mapGetters, mapMutations } from "vuex";

import fields from "./data/fields.settings.data";
import filters from "./data/filters.settings.data";

export default {
  components: {
    ModalTracking,
  },
  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "created_at",
      sortDesc: true,
      fields,
      startPage: null,
      endPage: null,
      nextPage: null,
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      filter: filters,
      dataItem: [],
      modalTrackingState: false,
      modalCategoryState: false,
      flagdisabled: false,
      selected: null,
      options: [
        { value: 1, text: "TRAINER" },
        { value: 2, text: "JUNIOR" },
        { value: 3, text: "SENIOR" },
        { value: 4, text: "MASTER" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    visibleFields() {
      return this.fields.filter((field) => field.visible);
    },
  },
  methods: {
    ...mapMutations({
      countPending: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),

    disabledPermission(data) {
      return data.item.status_permission_seller === "PENDING";
    },

    onChangeSeniority(data) {
      let seniority =
        data.status_permission_seller === "DENIED"
          ? data.typesenior_number
          : data.type_pending_number;
      this.updatePermission(data, seniority);
    },

    closeModal() {
      this.modalCategoryState = false;
    },

    async updatePermission(data, level) {
      try {
        const confirm = await this.showConfirmSwal();
        if (confirm.value) {
          this.flagdisabled = true;
          this.addPreloader();
          const response =
            await SalesSellerServices.updatePermissionSellerInSales({
              _user_session_id: this.currentUser.user_id,
              _user_module_id: data.sales_seller_id,
              _user_id: data.account_id,
              _level: level,
            });

          this.showSuccessSwal("Permission successfully changed");
          this.flagdisabled = false;
          this.removePreloader();
          this.refresh();
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    refresh() {
      this.$refs.refSettingsList.refresh();
    },
    async getSellers() {
      try {
        let status;
        switch (this.$route.name) {
          case "seniority-completed":
            status = 2;
            break;
          case "seniority-rejected":
            status = 3;
            break;
          case "seniority-canceled":
            status = 4;
            break;
        }
        const params = {
          texto: this.filterPrincipal.model,
          orderby: 1,
          order: "",
          per_page: this.paginate.perPage,
          page: this.paginate.currentPage,
          _typesenior: this.filter[0].model,
          status,
        };

        const response = await SalesSellerServices.getSellersFromCeDigital(
          params
        );
        this.startPage = response.data.from;
        this.paginate.currentPage = response.data.current_page;
        this.paginate.perPage = response.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.data.last_page;
        this.totalRows = response.data.total;
        this.toPage = response.data.to;
        let items = response.data.data;
        items.forEach((item) => {
          if (item.typesenior_pending == "TRAINER") {
            item.type_pending = 1;
          } else if (item.typesenior_pending == "JUNIOR") {
            item.type_pending = 2;
          } else if (item.typesenior_pending == "SENIOR") {
            item.type_pending = 3;
          } else if (item.typesenior_pending == "MASTER") {
            item.type_pending = 4;
          }
        });
        return response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    openModalTracking(data) {
      this.dataItem = data;
      this.modalTrackingState = true;
    },
  },
};
</script>
<style scoped>
#span_status_notes {
  padding: 3px 10px;
  border-radius: 15px;
  width: 115px;
}
#fluid {
  padding: 0;
}
.green {
  background: var(--success);
  color: #f2f2f2;
}
.red {
  background: var(--danger);
  color: #f2f2f2;
}
.yellow {
  background: var(--warning);
  color: #f2f2f2;
}
.button {
  padding: 0;
  margin: 5px 2.5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
td {
  text-align: center;
}
</style>
