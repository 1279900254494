export default [
  {
    path: '/crm/manage-commissions',
    name: 'crm-manage-commissions',
    component: () => import('@/views/crm/views/manage-commissions/ManageCommissions.vue'),
    redirect: { name: 'crm-manage-commissions-crm' },
    meta: {
      permittedRoles: [1, 2, 17],
      pageTitle: 'Manage Commissions',
      breadcrumb: [
        {
          text: 'Manage Commissions',
          active: true,
        },
      ],
      module: 2,
    },
    children: [
      {
        path: 'crm',
        component: () => import('@/views/crm/views/manage-commissions/components/TabProgram.vue'),
        redirect: { name: 'crm-manage-commissions-crm-roles' },
        name: 'crm-manage-commissions-crm',
        meta: {
          pageTitle: 'Manage Commissions',
          breadcrumb: [
            {
              text: 'CRM',
              active: true,
            },
          ],
          module: 2,
          moduleOfTab: 2,
          permittedRoles: [1, 2, 17],
          rolesTabRoute: 'crm-manage-commissions-crm-roles',
        },
        children: [
          {
            path: 'roles',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentRolesCommissions.vue'),
            name: 'crm-manage-commissions-crm-roles',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'CRM',
                  active: true,
                },
                {
                  text: 'Roles',
                  active: true,
                },
              ],
              module: 2,
              permittedRoles: [1, 2, 17],
              moduleOfTab: 2,
              rolesTabRoute: 'crm-manage-commissions-crm-roles',
            },
          },
        ],
      },
      {
        path: 'business',
        component: () => import('@/views/crm/views/manage-commissions/components/TabProgram.vue'),
        redirect: { name: 'crm-manage-commissions-bu-types' },
        name: 'crm-manage-commissions-bu',
        meta: {
          pageTitle: 'Manage Commissions',
          breadcrumb: [
            {
              text: 'Business',
              active: true,
            },
          ],
          module: 2,
          programId: 1,
          moduleOfTab: 3,
          permittedRoles: [1, 2, 17],
          typesTabRoute: 'crm-manage-commissions-bu-types',
          rolesTabRoute: 'crm-manage-commissions-bu-roles',
        },
        children: [
          {
            path: 'types',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentCommissions.vue'),
            name: 'crm-manage-commissions-bu-types',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Business',
                  active: true,
                },
                {
                  text: 'Types',
                  active: true,
                },
              ],
              module: 2,
              permittedRoles: [1, 2, 17],
              programId: 1,
              moduleOfTab: 3,
              typesTabRoute: 'crm-manage-commissions-bu-types',
              rolesTabRoute: 'crm-manage-commissions-bu-roles',
            },
          },
          {
            path: 'roles',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentRolesCommissions.vue'),
            name: 'crm-manage-commissions-bu-roles',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Business',
                  active: true,
                },
                {
                  text: 'Roles',
                  active: true,
                },
              ],
              module: 2,
              permittedRoles: [1, 2, 17],
              programId: 1,
              moduleOfTab: 3,
              typesTabRoute: 'crm-manage-commissions-bu-types',
              rolesTabRoute: 'crm-manage-commissions-bu-roles',
            },
          },
        ],
      },
      {
        path: 'boost-credit',
        component: () => import('@/views/crm/views/manage-commissions/components/TabProgram.vue'),
        redirect: { name: 'crm-manage-commissions-bc-types' },
        name: 'crm-manage-commissions-bc',
        meta: {
          pageTitle: 'Manage Commissions',
          breadcrumb: [
            {
              text: 'Business',
              active: true,
            },
          ],
          module: 2,
          programId: 2,
          moduleOfTab: 7,
          permittedRoles: [1, 2, 17],
          typesTabRoute: 'crm-manage-commissions-bc-types',
          rolesTabRoute: 'crm-manage-commissions-bc-roles',
        },
        children: [
          {
            path: 'types',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentCommissions.vue'),
            name: 'crm-manage-commissions-bc-types',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Boost Credit',
                  active: true,
                },
                {
                  text: 'Types',
                  active: true,
                },
              ],
              module: 2,
              programId: 2,
              moduleOfTab: 7,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-bc-types',
              rolesTabRoute: 'crm-manage-commissions-bc-roles',
            },
          },
          {
            path: 'roles',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentRolesCommissions.vue'),
            name: 'crm-manage-commissions-bc-roles',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Boost Credit',
                  active: true,
                },
                {
                  text: 'Roles',
                  active: true,
                },
              ],
              module: 2,
              programId: 2,
              moduleOfTab: 7,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-bc-types',
              rolesTabRoute: 'crm-manage-commissions-bc-roles',
            },
          },
        ],
      },
      {
        path: 'credit-experts',
        component: () => import('@/views/crm/views/manage-commissions/components/TabProgram.vue'),
        redirect: { name: 'crm-manage-commissions-ce-types' },
        name: 'crm-manage-commissions-ce',
        meta: {
          pageTitle: 'Manage Commissions',
          breadcrumb: [
            {
              text: 'Credit Experts',
              active: true,
            },
          ],
          module: 2,
          programId: 3,
          moduleOfTab: 6,
          permittedRoles: [1, 2, 17],
          typesTabRoute: 'crm-manage-commissions-ce-types',
          rolesTabRoute: 'crm-manage-commissions-ce-roles',
        },
        children: [
          {
            path: 'types',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentCommissions.vue'),
            name: 'crm-manage-commissions-ce-types',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Credit Experts',
                  active: true,
                },
                {
                  text: 'Types',
                  active: true,
                },
              ],
              module: 2,
              programId: 3,
              moduleOfTab: 6,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-ce-types',
              rolesTabRoute: 'crm-manage-commissions-ce-roles',
            },
          },
          {
            path: 'roles',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentRolesCommissions.vue'),
            name: 'crm-manage-commissions-ce-roles',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Credit Experts',
                  active: true,
                },
                {
                  text: 'Roles',
                  active: true,
                },
              ],
              module: 2,
              programId: 3,
              moduleOfTab: 6,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-ce-types',
              rolesTabRoute: 'crm-manage-commissions-ce-roles',
            },
          },
        ],
      },
      {
        path: 'debt-solution',
        component: () => import('@/views/crm/views/manage-commissions/components/TabProgram.vue'),
        redirect: { name: 'crm-manage-commissions-ds-types' },
        name: 'crm-manage-commissions-ds',
        meta: {
          pageTitle: 'Manage Commissions',
          breadcrumb: [
            {
              text: 'Debt Solution',
              active: true,
            },
          ],
          module: 2,
          programId: 4,
          moduleOfTab: 5,
          permittedRoles: [1, 2, 17],
          typesTabRoute: 'crm-manage-commissions-ds-types',
          rolesTabRoute: 'crm-manage-commissions-ds-roles',
        },
        children: [
          {
            path: 'types',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentCommissions.vue'),
            name: 'crm-manage-commissions-ds-types',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Debt Solution',
                  active: true,
                },
                {
                  text: 'Types',
                  active: true,
                },
              ],
              module: 2,
              programId: 4,
              moduleOfTab: 5,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-ds-types',
              rolesTabRoute: 'crm-manage-commissions-ds-roles',
            },
          },
          {
            path: 'roles',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentRolesCommissions.vue'),
            name: 'crm-manage-commissions-ds-roles',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Debt Solution',
                  active: true,
                },
                {
                  text: 'Roles',
                  active: true,
                },
              ],
              module: 2,
              programId: 4,
              moduleOfTab: 5,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-ds-types',
              rolesTabRoute: 'crm-manage-commissions-ds-roles',
            },
          },
        ],
      },
      {
        path: 'tax-research',
        component: () => import('@/views/crm/views/manage-commissions/components/TabProgram.vue'),
        redirect: { name: 'crm-manage-commissions-tr-types' },
        name: 'crm-manage-commissions-tr',
        meta: {
          pageTitle: 'Manage Commissions',
          breadcrumb: [
            {
              text: 'Tax Research',
              active: true,
            },
          ],
          module: 2,
          permittedRoles: [1, 2, 17],
          typesTabRoute: 'crm-manage-commissions-tr-types',
          rolesTabRoute: 'crm-manage-commissions-tr-roles',
          programId: 5,
          moduleOfTab: 8,
        },
        children: [
          {
            path: 'types',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentCommissions.vue'),
            name: 'crm-manage-commissions-tr-types',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Tax Research',
                  active: true,
                },
                {
                  text: 'Types',
                  active: true,
                },
              ],
              module: 2,
              programId: 5,
              moduleOfTab: 8,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-tr-types',
              rolesTabRoute: 'crm-manage-commissions-tr-roles',
            },
          },
          {
            path: 'roles',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentRolesCommissions.vue'),
            name: 'crm-manage-commissions-tr-roles',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Tax Research',
                  active: true,
                },
                {
                  text: 'Roles',
                  active: true,
                },
              ],
              module: 2,
              programId: 5,
              moduleOfTab: 8,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-tr-types',
              rolesTabRoute: 'crm-manage-commissions-tr-roles',
            },
          },
        ],
      },
      {
        path: 'court-info',
        component: () => import('@/views/crm/views/manage-commissions/components/TabProgram.vue'),
        redirect: { name: 'crm-manage-commissions-ci-types' },
        name: 'crm-manage-commissions-ci',
        meta: {
          pageTitle: 'Manage Commissions',
          breadcrumb: [
            {
              text: 'Court info',
              active: true,
            },
          ],
          module: 2,
          permittedRoles: [1, 2, 17],
          typesTabRoute: 'crm-manage-commissions-ci-types',
          rolesTabRoute: 'crm-manage-commissions-ci-roles',
          programId: 6,
          moduleOfTab: 10,
        },
        children: [
          {
            path: 'types',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentCommissions.vue'),
            name: 'crm-manage-commissions-ci-types',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Court Info',
                  active: true,
                },
                {
                  text: 'Types',
                  active: true,
                },
              ],
              module: 2,
              programId: 6,
              moduleOfTab: 10,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-ci-types',
              rolesTabRoute: 'crm-manage-commissions-ci-roles',
            },
          },
          {
            path: 'roles',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentRolesCommissions.vue'),
            name: 'crm-manage-commissions-ci-roles',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Court Info',
                  active: true,
                },
                {
                  text: 'Roles',
                  active: true,
                },
              ],
              module: 2,
              programId: 6,
              moduleOfTab: 10,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-ci-types',
              rolesTabRoute: 'crm-manage-commissions-ci-roles',
            },
          },
        ],
      },
      {
        path: 'specialists',
        component: () => import('@/views/crm/views/manage-commissions/components/TabProgram.vue'),
        redirect: { name: 'crm-manage-commissions-sp-types' },
        name: 'crm-manage-commissions-sp',
        meta: {
          pageTitle: 'Manage Commissions',
          breadcrumb: [
            {
              text: 'Specialists',
              active: true,
            },
          ],
          module: 2,
          permittedRoles: [1, 2, 17],
          typesTabRoute: 'crm-manage-commissions-sp-types',
          rolesTabRoute: 'crm-manage-commissions-sp-roles',
          programId: 7,
          moduleOfTab: 11,
        },
        children: [
          {
            path: 'types',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentCommissions.vue'),
            name: 'crm-manage-commissions-sp-types',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Specialists',
                  active: true,
                },
                {
                  text: 'Types',
                  active: true,
                },
              ],
              module: 2,
              programId: 7,
              moduleOfTab: 11,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-sp-types',
              rolesTabRoute: 'crm-manage-commissions-sp-roles',
            },
          },
          {
            path: 'roles',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentRolesCommissions.vue'),
            name: 'crm-manage-commissions-sp-roles',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Specialists',
                  active: true,
                },
                {
                  text: 'Roles',
                  active: true,
                },
              ],
              module: 2,
              programId: 7,
              moduleOfTab: 11,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-sp-types',
              rolesTabRoute: 'crm-manage-commissions-sp-roles',
            },
          },
        ],
      },
      {
        path: 'keybook',
        component: () => import('@/views/crm/views/manage-commissions/components/TabProgram.vue'),
        redirect: { name: 'crm-manage-commissions-kb-types' },
        name: 'crm-manage-commissions-kb',
        meta: {
          pageTitle: 'Manage Commissions',
          breadcrumb: [
            {
              text: 'KeyBook',
              active: true,
            },
          ],
          module: 2,
          permittedRoles: [1, 2, 17],
          typesTabRoute: 'crm-manage-commissions-kb-types',
          rolesTabRoute: 'crm-manage-commissions-kb-roles',
          programId: 8,
          moduleOfTab: 14,
        },
        children: [
          {
            path: 'types',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentCommissions.vue'),
            name: 'crm-manage-commissions-kb-types',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'KeyBook',
                  active: true,
                },
                {
                  text: 'Types',
                  active: true,
                },
              ],
              module: 2,
              programId: 8,
              moduleOfTab: 14,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-kb-types',
              rolesTabRoute: 'crm-manage-commissions-kb-roles',
            },
          },
          {
            path: 'roles',
            component: () => import('@/views/crm/views/manage-commissions/components/ContentRolesCommissions.vue'),
            name: 'crm-manage-commissions-kb-roles',
            meta: {
              pageTitle: 'Manage Commissions',
              breadcrumb: [
                {
                  text: 'Specialists',
                  active: true,
                },
                {
                  text: 'Roles',
                  active: true,
                },
              ],
              module: 2,
              programId: 8,
              moduleOfTab: 14,
              permittedRoles: [1, 2, 17],
              typesTabRoute: 'crm-manage-commissions-kb-types',
              rolesTabRoute: 'crm-manage-commissions-kb-roles',
            },
          },
        ],
      },
    ],
  },
]
