<template>
  <div>
    <filter-slot
      :filter="filterSlot.filters"
      :paginate="filterSlot.paginate"
      :starPage="filterSlot.starPage"
      :toPage="filterSlot.toPage"
      :totalRows="filterSlot.totalRows"
      :filterPrincipal="filterSlot.filterPrincipal"
      :double-paginate="false"
      @reload="getCreditsAndIP"
      @onChangeCurrentPage="getCreditsAndIP"
    >
      <template #table>
        <div class="container-credits-table-ce-ing" v-if="!loading">
          <b-row class="row-item header">
            <b-col cols="2" class="row-header custom-cols">Date</b-col>
            <b-col cols="3" class="row-header custom-cols">Type</b-col>
            <b-col cols="3" class="row-header custom-cols">
              Transaction ID
            </b-col>
            <b-col cols="2" class="row-header custom-cols">Amount</b-col>
            <!-- <b-col cols="2" class="row-header custom-cols">Method</b-col> -->
            <b-col cols="2" class="row-header custom-cols">Status</b-col>
          </b-row>

          <b-row
            class="row-item detail"
            v-for="(item, index) in items"
            :key="index + '_item'"
          >
            <b-col cols="2" class="custom-cols">
              <div class="d-flex justify-content-center w-100">
                <p class="mb-0 font-weight-bold">
                  {{ item.settlement_date | myGlobal }}
                </p>

                <!-- only credits -->
                <feather-icon
                  :id="`credit_${index}_information`"
                  v-if="item.type_transaction_id === 8"
                  v-b-tooltip.hover.bottom
                  icon="EyeIcon"
                  class="cursor-pointer ml-1 text-primary"
                  size="18"
                />
                <b-tooltip
                  :target="`credit_${index}_information`"
                  variant="primary"
                  placement="bottom"
                >
                  Credit Created by:

                  {{ item.user_creator }}
                  <br />
                  Credit Created at:
                  <br />
                  {{ item.created_at }}
                  <br />
                  Reason of Credit:
                  <br />
                  {{ item.comment }}
                </b-tooltip>
              </div>
            </b-col>
            <b-col cols="3" class="custom-cols">
              <div class="type text-uppercase">{{ item.type }}</div>
            </b-col>
            <b-col cols="3" class="custom-cols">
              {{ item.transaction_id }}
            </b-col>
            <b-col cols="2" class="custom-cols">
              <!-- original amount -->
              <span v-if="![10, 11].includes(item.type_transaction_id)">
                $ {{ item.amount | currencyZero }}
              </span>
              <!-- remaining amount -->
              <span
                v-if="
                  ![10, 11].includes(item.type_transaction_id) &&
                  parseInt(item.sumref) > 0
                "
                class="text-info ml-1"
              >
                ($ {{ (item.amount - item.sumref).toFixed(2) | currencyZero }})
              </span>
              <!-- void/refund amount -->
              <span v-if="[10, 11].includes(item.type_transaction_id)">
                $ ({{ data.item.amount }})
              </span>
            </b-col>
            <!-- <b-col cols="2" class="custom-cols">
              <div class="d-flex justify-content-start w-100">
                <p class="mb-0">{{ item.method_payment }}</p>

                // show void and refund info  
                <feather-icon
                  v-if="[10, 11].includes(item.type_transaction_id)"
                  :id="`EyeIcon-${index}`"
                  v-b-tooltip.hover.bottom
                  icon="EyeIcon"
                  class="text-primary cursor-pointer ml-1"
                  size="18"
                  title="SHOW"
                  @click="openVoidRefundInfoModal(item.transaction_id)"
                />

              // Void  
                <tabler-icon
                  v-if="
                    [3].includes(item.type_transaction_id) &&
                    item.void == 1 &&
                    item.w_card == 1 &&
                    item.amount != 0 &&
                    item.pending_void_transactions == 0 &&
                    parseFloat(item.sumref) < parseFloat(item.amount) &&
                    (roleId == 1 || moduleId == 4) &&
                    !readOnly
                  "
                  v-b-tooltip.hover.bottom
                  size="18"
                  class="text-danger cursor-pointer ml-1"
                  icon="CoinIcon"
                  title="Void"
                  @click="openVoidRefundModal(item, 1)"
                />

               // Refund  
                <tabler-icon
                  v-if="
                    [3].includes(item.type_transaction_id) &&
                    item.refund == 1 &&
                    item.w_card == 1 &&
                    item.amount != 0 &&
                    item.pending_void_transactions == 0 &&
                    parseFloat(item.sumref) < parseFloat(item.amount) &&
                    (roleId == 1 || moduleId == 4) &&
                    !readOnly
                  "
                  v-b-tooltip.hover.bottom
                  icon="ClockIcon"
                  class="text-success cursor-pointer ml-1"
                  size="18"
                  title="Refund"
                  @click="openVoidRefundModal(item, 2)"
                />
              </div>
            </b-col> -->
            <b-col cols="2" class="custom-cols justify-content-between">
              <div class="d-flex w-100 justify-content-center">
                <span :style="`color:${item.color};`">{{ item.status }}</span>
              </div>
              <feather-icon
                v-if="item.general_status != 'Fee Charge'"
                :icon="item.show ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                class="ml-3 cursor-pointer"
                size="23"
                @click="openCollapse(index)"
              ></feather-icon>
            </b-col>
            <b-col cols="12" class="pl-4">
              <b-collapse
                :visible="Boolean(item.show)"
                accordion="my-accordion"
                class="my-accordion"
              >
                <template v-for="(item, index) in item.details">
                  <b-row
                    v-if="item.transaction_id "
                    :key="index"
                  >
                    <b-col cols="12" class="mt-1">
                      <b-row class="header-accordion">
                        <b-col cols="2">
                          <span
                            :class="
                              ![10, 11].includes(item.z)
                                ? 'text-success'
                                : 'text-info'
                            "
                          >
                            Transaction Detail
                          </span>
                        </b-col>
                        <b-col cols="3"></b-col>
                        <b-col cols="5"></b-col> 
                      </b-row>
                    </b-col>
                    <!-- Transaction Detail -->
                    <b-col cols="12" class="my-1 custom-status">
                      <b-row>
                        <!-- header -->
                        <b-col cols="2">
                          <p>Transaction ID:</p>
                          <p>Method:</p>
                          <p>Date:</p>
                          <p>Created By:</p>
                          <p>Status:</p>
                          <p>Amount:</p>
                        </b-col>
                        <!-- body: -->
                        <b-col cols="8">
                          <!-- Transaction ID -->
                          <p>
                            {{
                              item.transaction_id ? item.transaction_id : "-"
                            }}
                          </p>
                          <!-- Method -->
                          <p>
                            {{
                               !item.status
                                ? "Other Payment"
                                : item.method_payment
                                ? item.method_payment
                                : "-"
                            }}
                          </p>
                          <p>{{ item.settlement_date | myGlobal }}</p>
                          <p>{{ item.user_creator ? item.user_creator : "-" }}</p>
                          <!-- Status: -->
                          <div class="d-flex align-items-center">
                            <p
                              class="status"
                              :class="getStatusVariant(item).classCus"
                              v-b-tooltip.hover
                              :title="getStatusVariant(item).message"
                            >
                              {{ getStatusVariant(item).message }}
                            </p>
                          </div>
                          <!-- amount -->
                          <template>
                            <p
                              v-if="
                                ![10, 11].includes(item.type_transaction_id)
                              "
                              class="text-success"
                            >
                              $ {{ item.amount }}
                            </p>
                            <p
                              style="text-decoration: line-through"
                              class="text-danger"
                              v-if="[10, 11].includes(item.type_transaction_id)"
                            >
                              ($ {{ item.amount }})
                            </p>
                          </template>
                        </b-col> 
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
                <!-- pending partial paid charge -->
                <b-row v-if="parseFloat(item.pending_paid_charge) > 0">
                  <b-col cols="12" class="mt-1">
                    <b-row class="header-accordion"
                      ><b-col cols="2">
                        <span class="text-warning"
                          >Transaction Detail</span
                        ></b-col
                      >
                      <b-col cols="3"></b-col>
                      <b-col cols="5"></b-col>
                      <b-col cols="2">
                        <span class="p-1">Actions:</span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" class="my-1">
                    <b-row>
                      <b-col cols="2">
                        <p>Transaction ID:</p>
                        <p>Method:</p>
                        <p>Status:</p>
                        <p>Amount:</p>
                      </b-col>
                      <b-col cols="8">
                        <p>-</p>
                        <p>-</p>
                        <p class="text-warning">Pending</p>
                        <p class="text-warning">
                          $ {{ item.pending_paid_charge }}
                        </p>
                      </b-col>
                      <b-col cols="2">
                        <div class="badge-container">
                          <div
                            v-if="!readOnly"
                            class="badge-action"
                            @click="openChargeModal(3, item)"
                          >
                            <cash-icon
                              :height="17"
                              :width="17"
                              style="margin: 3px"
                            />
                            Pay
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-col>
          </b-row>

          <div
            v-if="items.length == 0"
            class="text-center mt-2"
            style="font-size: 15px"
          >
            There are no records to show
          </div>
        </div>

        <div class="container-credits-table-ce-ing w-100" v-else>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
          <b-skeleton type="input" class="row-item w-100"></b-skeleton>
        </div>
      </template>
    </filter-slot>

    <void-refund-modal
      v-if="voidRefundModalShow"
      :void-refund-data="voidRefundData"
      :type-void-refund="typeVoidRefund"
      @refresh="refreshComponents"
      @close="voidRefundModalShow = false"
    />

    <void-refund-info
      v-if="voidRefundInfoModalShow"
      :transaction-id="transactionId"
      @close="voidRefundInfoModalShow = false"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import VoidRefundModal from "@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundModal.vue";
import VoidRefundInfo from "@/views/commons/components/clients/dashboard/options/pay-module/modals/VoidRefundInfoModal.vue";
import ClientService from "@/views/commons/components/clients/dashboard/services/clients.options.services.js";
export default {
  components: {
    VoidRefundModal,
    VoidRefundInfo,
  },
  props: {
    clientAccountId: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      items: [],
      filterSlot: {
        filters: [],
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by Transaction ID",
          model: "",
        },
        paginate: {
          currentPage: 1,
          perPage: 100,
        },
        starPage: 0,
        toPage: 0,
        totalRows: 0,
      },
      loading: true,
      voidRefundModalShow: false,
      voidRefundInfoModalShow: false,
      transactionId: null,
      typeVoidRefund: null,
      voidRefundData: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    roleId() {
      return this.currentUser.role_id;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {},
  methods: {
    async getCreditsAndIP() {
      try {
        this.loading = true;

        const params = {
          client_account_id: this.clientAccountId,
          search_text: this.filterSlot.filterPrincipal.model,
          page: this.filterSlot.paginate.currentPage,
          per_page: this.filterSlot.paginate.perPage,
        };

        const { data } =
          await ClientService.getCreditsAndInitialPaymentsByClientAccount(
            params
          );

        this.filterSlot.totalRows = data.total;
        this.filterSlot.starPage = data.from ?? 0;
        this.filterSlot.toPage = data.to ?? 0;
        this.filterSlot.paginate.currentPage = data.current_page;
        this.filterSlot.paginate.perPage = data.per_page;
        this.items = data.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },

    openVoidRefundModal(item, type) {
      this.typeVoidRefund = type;
      this.voidRefundData = {
        transaction_id: item.transaction_id,
        merchant: item.merchant,
        client_name: item.client_name,
        amount:
          parseFloat(item.sumref) > 0
            ? (item.amount - parseFloat(item.sumref)).toFixed(2)
            : parseFloat(item.amount.replace(/,/g, "")),
        settlement_date: item.settlement_date,
      };
      this.voidRefundModalShow = true;
    },

    openVoidRefundInfoModal(transactionId) {
      this.transactionId = transactionId;
      this.voidRefundInfoModalShow = true;
    },

    refreshComponents() {
      this.$emit("refresh");
      this.voidRefundModalShow = false;
      this.getCreditsAndIP();
    },
    openCollapse(index) { 
      this.items.forEach((item, index2) => {
        if (index2 != index) {
          item.show = false;
        }
      });

      this.items[index].show = !this.items[index].show;
    },
    getStatusVariant(item) {
      let response = {
        classCus: "status-1",
        message: item.status,
      }; 

      if (!item.status) {
        response.classCus = "status-null";
        response.message = "Pending";
      }

      if (item.status == 5) {
        response.classCus = "status-set";
      }

      if (!item.transaction_id && item.from_op == 0) {
        response.classCus = "status-set";
        response.message = "Fee Charge";
      }

      if (item.type_transaction_id == 10) {
        response.classCus = "status-set";
        response.message = "Void";
      }

      if (item.type_transaction_id == 11) {
        response.classCus = "status-set";
        response.message = "Refund";
      }

      return response;
    },
  },
};
</script>
<style lang="scss">
.container-credits-table-ce-ing {
  width: 100%;
  max-height: 640px;
  overflow-y: auto;
  padding: 0 20px 5px 20px;

  .header {
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #0090e7 !important;
    color: #ffffff !important;
  }

  .row-header {
    padding-left: 20px;
  }

  .row-item {
    min-height: 60px;
    margin-top: 10px !important;
    color: black;
    font-size: 16px;
    text-align: left;
    border-radius: 12px;

    background-color: #f5f5f5;

    .custom-cols {
      text-align: left;
      display: flex;
      justify-content: center;
      align-items: center;

      .type {
        color: #0090e7;
        font-weight: 600;
      }
    }

    .my-accordion {
      background-color: #f5f5f5;
      border-top: 1px solid black;
      margin: 10px 0;
      text-align: left;
      font-size: 16px;

      .header-accordion {
        font-weight: 600;
      }

      .badge-container {
        display: flex;
        flex-wrap: wrap;
        // flex-direction: column;

        .badge-action {
          // margin-top: 5px;
          cursor: pointer;
          text-align: center;
          display: flex;
          align-items: center;
          margin-left: 5px;
          margin-right: 5px;
          border-radius: 20px;
          border: 1px solid #d9d9d9;
          padding: 8px 12px;
          color: black;
          margin-bottom: 3px;
        }
      }
    }
  }
   .detail {
    // border-left: 8px solid #e35403;
    padding-top: 12px;
  }
}

.dark-layout {
  .container-credits-table-ce-ing {
    .row-item {
      color: #ffffff;

      background-color: #1d1d20;

            .my-accordion {
        background-color: #1d1d20;
        border-top: 1px solid #ffffff;

        .badge-container {
          .badge-action {
            border: 1px solid #d9d9d9;
            color: #ffffff;
          }
        }
      }
    } 
  }
  
}

@media (max-width: 1450px) and (min-width: 407px) {
  .container-credits-table-ce-ing {
    padding: 0 15px;

    .row-item {
      min-height: 45px;
      margin-top: 10px !important;
      font-size: 13px;

      .custom-cols {
        .void {
          padding: 2px 10px;
        }
      }
    }
  }
}

@media (max-width: 800px) and (min-width: 120px) {
  .container-credits-table-ce-ing {
    width: 800px;
    overflow-x: auto;
  }
}
</style>