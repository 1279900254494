<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-header-close
      hide-header
      :body-class="`rounded`"
      no-close-on-backdrop
      size="summary"
      @hidden="isActive = false"
      centered
      :ok-only="true"
      ok-variant="success"
      hide-footer
    >
      <div v-if="dataRc">
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
        >
          <div class="d-flex align-items-center" style="gap: 7px;">
            <span class="title-alert font-strong">
              {{ title }} </span
            >
          </div>
          <div
            class="close-modal"
            @click="closeModal()"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
          >
            <feather-icon icon="XIcon" size="16" />
          </div>
        </div>
        <div class="d-flex align-items-center mt-1">
          <div class="icon-badge mr-1" :class="badgeColor">
            <feather-icon :icon="iconTitle" size="20"></feather-icon>
          </div>
          <span>
            <span :class="colorText" class="text-uppercase"> {{ dataRc.type_text }} {{ dataRc.level_text ? "- "+ dataRc.level_text : "" }} </span>
          </span>
        </div>
        <hr />
        <div class="d-flex flex-column" style="gap: 15px">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CLIENT
            </span>
            <span class="font-strong">{{ dataRc.client_name }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="CalendarIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              DATE
            </span>
            <span class="font-strong">{{
              dataRc.created_at | myGlobalDay
            }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="MapIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              DEPARTMENT
            </span>
            <span class="font-strong">{{
              nameModule(dataRc.module_name)
            }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserCheckIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CLASSIFIED BY
            </span>
            <span class="font-strong">{{ dataRc.created_by }}</span>
          </div>
        </div>
      </div>
      <hr />
      <b-row>
        <b-col cols="9">
          <b-button
            class="w-100 block"
            target="_blank"
            variant="info"
            @click="goToRiskyClients"
            >GO TO RISKY CLIENTS</b-button
          >
        </b-col>
        <b-col cols="3">
          <b-button class="w-100 block" variant="success" @click="closeModal()"
            >OK</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AlertNcr",
  data() {
    return {
    };
  },
  created(){
  },
  computed: {
    ...mapGetters({
      G_IS_ACTIVE_RC: "AutoAlertRc/G_IS_ACTIVE_RC",
      G_DATA_RC: "AutoAlertRc/G_DATA_RC",
      title: "AutoAlertRc/G_TITLE_RC",
      currentUser: "auth/currentUser",
      getRefreshTable: "QualityRiskyClientsStore/getRefreshTable",
    }),

    isActive: {
      get() {
        return this.G_IS_ACTIVE_RC;
      },
      set(value) {
        this.$store.commit("AutoAlertRc/M_OPEN_ALERT_RC", value);
        setTimeout(() => {
          this.dataRc = null;
        }, 500);
      },
    },

    dataRc: {
      get() {
        return this.G_DATA_RC;
      },
      set(value) {
        this.$store.commit("AutoAlertRc/M_DATA_RC", value);
      },
    },

    moduleId() {
      return (this.$route.matched[0].meta.module == 28 ? 11 : this.$route.matched[0].meta.module) || this.currentUser.modul_id;
    },

    iconTitle() {
      const status = this.dataRc?.status;
      let icons = {
        PENDING: "CheckIcon",
        ANNULLED: "XOctagonIcon",
        FAILED: "XOctagonIcon",
        "IN PROCESS": "InfoIcon",
        COMPLETED: "CheckIcon",
      };
      return icons[status] || "InfoIcon";
    },

    colorText() {
      const status = this.dataRc?.level_number;
      let color = {
        1: "text-info font-strong",
        2: "text-warning font-strong",
        3: "text-danger font-strong",
      };
      return color[status] || "text-success font-strong";
    },

    badgeColor() {
      const status = this.dataRc?.level_number;
      let classes = {
        1: "bg-light-info",
        2: "bg-light-warning",
        3: "bg-light-danger",
      };
      return classes[status] || "bg-light-success";
    },
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "QualityRiskyClientsStore/CHANGE_REFRESH_TABLE",
    }),
    closeModal() {
      const channel = socket.channel("request-ncr-notifications", {});
      channel.trigger("client-close-all-risk-client-notification", {
        message: "close all",
        user_id: this.currentUser.user_id,
      });
      this.isActive = false;
    },
    closeModalDetail() {
      this.isActive = false;
    },
    goToRiskyClients() {
      this.closeModal();

      const nameRoute = "quality-risky-clients";

      if(this.$route.name != nameRoute){
        this.$router.push({ name: "quality-risky-clients" });
      } else {
        this.CHANGE_REFRESH_TABLE(true);
      }

    },

    nameModule(module) {
      if (module == "CUSTOMER SERVICE REGULAR") {
        return "CREDIT EXPERTS (REGULAR)";
      } else if (module == "CUSTOMER SERVICE DIGITAL") {
        return "CREDIT EXPERTS (DIGITAL)";
      } else {
        return module;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-badge {
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
}
.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}
</style>
