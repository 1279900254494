import { amgApi } from "@/service/axios";

class OfferNotificationService {
  async getUserNotifications(body) {
    try {
      // in notifications excludes those that have type !10000
      const data = await amgApi.post(
        "/notification/get-offer-notification",
        body
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getAllOfferNotifications(body) {
    try {
      const data = await amgApi.post(
        "/notification/get-all-notifications-by-id",
        body
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
  async getDsSettlementsCounterByRole(body) {
    try {
      const data = await amgApi.get(
        `/debt-solution/notification/get-ds-settlements-by-role/${body.role_id}`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
  async getAllSettlementNotifiactions(body) {
    try {
      const data = await amgApi.post(
        `/debt-solution/notification/get-all-settlement-notifications`, body);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new OfferNotificationService();
