import store from "@/store"
import Vue from "vue"
import WelcomeActionService from "@/views/ce-digital/sub-modules/customer-service/views/clients/welcome-actions/service/welcome-action.service"

const closeAllAgentSwals = () => {
  window.socket.bind("agent-alerts-closed", async (data) => {
    const sessionId = store.state.auth.currentUser.user_id
    const localParams = JSON.parse(localStorage.getItem("getCEDigitalAlert"))
    if (Array.isArray(data.to_id)) {
      data.to_id.map(async (element) => {
        if (element.user_id === sessionId) {
          await store.dispatch(
            "CeDigitalWelcomeActions/A_AGENT_OPERATION",
            localParams.agent_alert_tacking
          )
          const params = {
            operation:
              store.getters["CeDigitalWelcomeActions/G_AGENT_OPERATION"],
            alert_id: localParams.alert_id,
            agent_id: sessionId,
          }
          await WelcomeActionService.saveAgentAlertTracking(params)
          Vue.swal.close()
        }
      })
    } else if (data.to_id === sessionId) {
      if (store.getters["CeDigitalWelcomeActions/G_AGENT_OPERATION"] !== 5) {
        await store.dispatch(
          "CeDigitalWelcomeActions/A_AGENT_OPERATION",
          localParams.agent_alert_tacking
        )
        const params = {
          operation: store.getters["CeDigitalWelcomeActions/G_AGENT_OPERATION"],
          alert_id: localParams.alert_id,
          agent_id: sessionId,
        }
        await WelcomeActionService.saveAgentAlertTracking(params)
      }
      Vue.swal.close()
    }
  })
}

export default closeAllAgentSwals
