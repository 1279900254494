<template>
  <b-row>
    <!-- Street -->
    <b-col sm="12" lg="9">
      <validation-provider
        v-slot="{ errors }"
        :name="`Mailing address (${addressData.prename})`"
        :rules="isRequired ? 'required' : null"
      >
        <b-form-group
          label="Mailing address"
          :label-for="`${addressData.prename}-street`"
          :state="errors[0] ? false : null"
        >
          <template #label>
            <span
              class="d-flex justify-content-between align-items-center font-ce"
            >
              Mailing address
              <span
                v-if="addressData.id"
                class="see-tracking"
                @click="$emit('onModalTrackingChangeOpen')"
                >See Tracking</span
              >
            </span>
          </template>
          <b-input-group>
            <b-form-input
              v-if="(addressData.id && disabled.street) || isDisabled"
              :id="`${addressData.prename}-street`"
              :ref="`${addressData.prename}-street`"
              v-model="addressData.street"
              class="font-ce"
              style="background-color: transparent"
              disabled
            />
            <vue-google-autocomplete
              v-else
              :id="`${addressData.prename}-street`"
              :ref="`${addressData.prename}-street`"
              v-model="addressData.street"
              class="form-control input-form fond-white border-hover font-ce"
              placeholder="Please type your address"
              country="us"
              :class="{ 'border border-danger': errors[0] }"
              @placechanged="getAddressData"
              @keyup="(e) => onChangeMaillingAddress(e, addressData)"
            />
            <template v-if="addressData.id && isEditable && !isDisabled">
              <b-input-group-append
                v-if="!disabled.street"
                class="border-right"
              >
                <b-button
                  variant="outline-primary"
                  class="btn-sm"
                  @click="onSubmitAddress"
                >
                  <feather-icon icon="SaveIcon" class="cursor-pointer" />
                </b-button>
              </b-input-group-append>
              <b-input-group-append class="border-right">
                <b-button
                  variant="outline-warning"
                  class="btn-sm"
                  @click="toggleData('street')"
                >
                  <feather-icon
                    v-if="disabled.street"
                    icon="Edit2Icon"
                    class="cursor-pointer"
                  />
                  <amg-icon
                    v-else
                    icon="Edit2SlashIcon"
                    class="cursor-pointer"
                  />
                </b-button>
              </b-input-group-append>
            </template>
          </b-input-group>
          <span v-if="errors[0]" class="text-danger font-ce">{{
            `Mailing address ${errors[0]}`
          }}</span>
        </b-form-group>
      </validation-provider>
    </b-col>
    <!-- City -->
    <b-col md="6" lg="3">
      <validation-provider
        v-slot="{ errors }"
        :name="`City (${addressData.prename})`"
        :rules="isRequired ? 'required' : null"
      >
        <b-form-group label="City" label-for="city">
          <template #label>
            <span class="font-ce">City</span>
          </template>
          <b-form-input
            id="city"
            v-model="addressData.city"
            trim
            :disabled="isDisabled || disabled.street"
            :state="errors[0] ? false : null"
            class="font-ce"
          />
          <span v-if="errors[0]" class="text-danger font-ce">{{
            `City ${errors[0]}`
          }}</span>
        </b-form-group>
      </validation-provider>
    </b-col>
    <!-- State -->
    <b-col md="6" lg="3">
      <validation-provider
        v-slot="{ errors }"
        :name="`State (${addressData.prename})`"
        :rules="isRequired ? 'required' : null"
      >
        <b-form-group
          label="State"
          label-for="country"
          :state="errors[0] ? false : null"
        >
          <template #label>
            <span class="font-ce">State</span>
          </template>
          <v-select
            id="country"
            v-model="addressData.state"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="state"
            class="font-ce"
            :options="G_EEUU_STATES"
            :disabled="isDisabled || disabled.street"
            :reduce="(el) => el.value"
          />
          <span v-if="errors[0]" class="text-danger font-ce">{{
            `State ${errors[0]}`
          }}</span>
        </b-form-group>
      </validation-provider>
    </b-col>
    <!-- Zip Code -->
    <b-col md="6" lg="3">
      <validation-provider
        v-slot="{ errors }"
        :name="`Zip Code (${addressData.prename})`"
        :rules="isRequired ? 'required' : null"
      >
        <b-form-group label="Zip Code" label-for="zipcode">
          <template #label>
            <span class="font-ce">Zip Code</span>
          </template>
          <b-form-input
            id="zipcode"
            v-model="addressData.zipcode"
            trim
            :disabled="isDisabled || disabled.street"
            :state="errors[0] ? false : null"
            class="font-ce"
          />
          <span v-if="errors[0]" class="text-danger font-ce">{{
            `Zip Code ${errors[0]}`
          }}</span>
        </b-form-group>
      </validation-provider>
    </b-col>
    <!-- Country -->
    <b-col md="6" lg="3">
      <validation-provider
        v-slot="{ errors }"
        :name="`Country (${addressData.prename})`"
        :rules="isRequired ? 'required' : null"
      >
        <b-form-group label="Country" label-for="country">
          <template #label>
            <span class="font-ce">Country</span>
          </template>
          <b-form-input
            id="country"
            v-model="addressData.country"
            trim
            class="font-ce"
            :disabled="isDisabled || disabled.street"
            :state="errors[0] ? false : null"
          />
          <span v-if="errors[0]" class="text-danger font-ce">{{
            `Country ${errors[0]}`
          }}</span>
        </b-form-group>
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    addressData: {
      type: Object,
      required: true,
      default() {
        return new Object();
      },
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      blankAddressData: new Object(),
      disabled: {
        street: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      G_EEUU_STATES: "CrmGlobalStore/G_EEUU_STATES",
    }),
  },
  created() {
    this.setDataBlank("addressData");
    this.fieldsNotDisabled();
  },
  mounted() {},
  methods: {
    onChangeMaillingAddress(event, data) {
      this.$set(data, "street", event.target.value);
    },
    fieldsNotDisabled() {
      if (!(this.addressData.id && this.isEditable))
        this.disabled.street = false;
    },
    setDataBlank(key) {
      this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`] = {
        ...this[key],
      };
    },
    resetData(key) {
      const object = this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`];
      for (const subkey in object) {
        this[key][subkey] = object[subkey];
      }
    },
    getAddressData(mainAddress) {
      const location = mainAddress;
      const address = `${
        location.street_number ? location.street_number : ""
      } ${location.route}`;
      this.$refs[`${this.addressData.prename}-street`].$el.value = address;
      this.addressData.state = mainAddress.administrative_area_level_1;
      this.$refs[`${this.addressData.prename}-street`].$el.value = address;
      this.addressData.street = address;
      this.addressData.city = location.locality;
      this.addressData.zipcode = location.postal_code;
    },
    toggleData(key) {
      this.disabled[key] = !this.disabled[key];
      if (this.disabled[key]) this.resetData("addressData");
    },
    onSubmitAddress() {
      if (!this.addressData.street) {
        this.addressData.street = this.addressData.streetReal;
      }
      this.$emit("onSubmitAddress", false);

      this.blankAddressData.street = this.addressData.street;
      this.blankAddressData.city = this.addressData.city;
      this.blankAddressData.state = this.addressData.state;
      this.blankAddressData.zipcode = this.addressData.zipcode;
      this.blankAddressData.country = this.addressData.country;
      this.disabled.street = !this.disabled.street;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.sidebar-xl {
  width: 90rem;
}
</style>
<style scoped>
* {
  font-family: "Montserrat", sans-serif !important;
}
.form-group {
  font-size: 16px;
}
h4 {
  font-size: 22px;
}
.font-ce {
  font-size: 16px;
}
.see-tracking {
  cursor: pointer;
  color: rgb(0, 183, 255);
  transition: text-decoration 0.1s, font-weight 0.1s;
}
.see-tracking:hover {
  text-decoration: underline;
  font-weight: bold;
}
</style>
