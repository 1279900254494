import { amgApi } from "@/service/axios";

class CallLogService {
    async getCallLogs(params) {
        try {
            const data = await amgApi.post("ring-central/get-calls-recording", params);
            return data;
        } catch (error) {
            console.log("Error getCallLogs");
            throw error;
        }
    }
  async getCallLogsByLeadId(params) {
    try {
      const data = await amgApi.post("ring-central/get-calls-recording-by-lead-id", params);
      return data;
    } catch (error) {
      console.log("Error getCallLogs");
      throw error;
    }
  }

    async getLeadCallLog(params) {
        try {
            const data = await amgApi.post("ring-central/get-leads-call-recording", params);
            return data;
        } catch (error) {
            console.log("Error getLeadCallLog");
            throw error;
        }
    }

    async asigModuleLeadCallRecording(params) {
        try {
            const data = await amgApi.post("ring-central/asing-module-lead-call-recording", params);
            return data;
        } catch (error) {
            console.log("Error asigModuleLeadCallRecording");
            throw error;
        }
    }

    async searchLeadWithLimit(params) {
        try {
            const data = await amgApi.post("ring-central/search-lead-by-name", params);
            return data;
        } catch (error) {
            console.log("Error searchLeadWithLimit");
            throw error;
        }
    }

    async getTotalCountCallRecording(params) {
        try {
            const data = await amgApi.post("ring-central/get-count-call-recording", params);
            return data;
        } catch (error) {
            console.log("Error getTotalCountCallRecording");
            throw error;
        }
    }

    async hiddenCall(params) {
        try {
            const data = await amgApi.post("ring-central/hidden-call", params);
            return data;
        } catch (error) {
            console.log("Error getTotalCountCallRecording");
            throw error;
        }
    }

    async getActiveCalls(params){
        try {
            const data = await amgApi.post("ring-central/api/active-calls/index", params);
            return data;
        } catch (error) {
            console.log("Error getActiveCalls");
            throw error;
        }
    }

    async getTodayCalls(params){
        try {
            const data = await amgApi.post("ring-central/api/call-recording/index", params);
            return data;
        } catch (error) {
            console.log("Error getActiveCalls");
            throw error;
        }
    }

    async getCallsByNumbers(params){
        try {
            const data = await amgApi.post("ring-central/call-recording/index", params);
            return data;
        } catch (error) {
            console.log("Error getActiveCalls");
            throw error;
        }
    }

    async getRCredentials(params){
        try {
            const data = await amgApi.post("ring-central/credentials/index", params);
            return data;
        } catch (error) {
            console.log("Error getRCredentials");
            throw error;
        }
    }

    async getCallsCounterPerClientAccountId(params) {
        try {
            const { data } = await amgApi.post('ring-central/get-counter-calls-per-client-account-id', params);
            return data;
        } catch (err) {
            console.log('Error in getCallsCounterPerClientAccountId', err);
            throw err;
        }
    }

    async getCallsByLead(params){
        try {
            const data = await amgApi.post("ring-central/call-recording/show", params);
            return data;
        } catch (error) {
            console.log("Error getActiveCalls");
            throw error;
        }
    }

    async getSelectedCalls(params){
        try {
            const data = await amgApi.post("ring-central/call-recording/calls-merged/index", params);
            return data;
        } catch (error) {
            console.log("Error getActiveCalls");
            throw error;
        }
    }
}
export default new CallLogService();
