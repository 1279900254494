import store from "@/store"
import router from "@/router/index"
import Vue from "vue"

const SalaryRecordNotification = () => {
  window.socket.bind("salary-notifications", async (response) => {
    const routerSession = router.currentRoute.matched[0]?.meta?.module
    const currentSession = store.state.auth.currentUser.user_id
    const messages = {
      1: "A new salary increase has been requested",
      2: "The salary increase has been approved successfully",
      3: "The salary increase was observed",
      4: "The salary increase has been rejected",
      5: "The salary increase has been edited",
    }
    const iconSwal = {
      1: "info",
      2: "success",
      3: "warning",
      4: "warning",
    }
    const { module_id, status } = response
    if (module_id != routerSession) return
    const { isConfirmed } = await Vue.swal.fire({
      icon: iconSwal[status] || "info",
      html: `<h2 style="font-weight: 600;">${messages[status]}</h2>`,
      showConfirmButton: true,
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    })
    if (isConfirmed) {
      await window.amgApi.post("/commons/close-all-swal", {
        to_id: currentSession,
      })
    }
  })
}

export { SalaryRecordNotification }
