const { amgApi } = require("@/service/axios");

class RequestWorkplanService {
  constructor() {
    this.routeAppointment = "financial-appointment";
  }

  async search(params) {
    try {
      const data = await amgApi.post(
        `${this.routeAppointment}/index`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async searchOtherServices(params){
    try {
      const data = await amgApi.post(`${this.routeOtherServices}/index`, params);
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateReprogrammingAppointment(params) {
    try {
      const data = await amgApi.post(
        `potential-appointment/store`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async searchTracking(params) {
    try {
      const data = await amgApi.post(
        `${this.routeAppointment}/tracking/index`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateAppointmentStatus(params) {
    try {
      const {data} = await amgApi.post(
        `${this.routeAppointment}/update`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async getAppointmentByClientId(params) {
    try {
      const {data} = await amgApi.post(
        `${this.routeAppointment}/show`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  //FILES
  async getFiles(parameter) {
    try {
      let data = await amgApi.post(`${this.routeAppointment}/files/index`, {
        appointment_id: parameter,
      });
      return data;
    } catch (error) {
       throw error;
    }
  }
  async uploadFiles(parameters) {
    try {
      let data = await amgApi.post(`${this.routeAppointment}/files/store`, parameters);
      return data.status === 200;
    } catch (error) {
       throw error;
    }
  }
  async deleteFile(parameters) {
    try {
      let data = await amgApi.post(`${this.routeAppointment}/files/delete`, parameters);
      return data.status === 200;
    } catch (error) {
       throw error;
    }
  }
  async getCountAppointmentsPending(body){
    try{
      const { data } = await amgApi.post(`${this.routeAppointment}/utils/get-count-pending-appointments`,body)
      return data;
    }catch(error){
      throw error;
    }
  }

  async removeRegister(params){
    try{
      const { data } = await amgApi.post(`${this.routeAppointment}/remove-register`,params)
      return data;
    }catch(error){
      throw error;
    }
  }
}

export default new RequestWorkplanService();
