export default {
	path: "zeropayment",
	name: "zeropayment-business",
	component: () => import(/* webpackChunkName: "BusinessZeroPayment" */ "./index.vue"),
	meta: {
		pageTitle: "Zero Payment",
		breadcrumb: [
			{
				text: "Zero Payment",
				active: true,
			},
		],
	},
}
