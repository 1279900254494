<template>
  <div>
    <b-modal
      ref="binnacle-sales-made-modal"
      title="Binnacle Sales Made"
      size="xl"
      :header-bg-variant="isDarkSkin ? 'default' : 'primary'"
      hide-footer
      no-close-on-backdrop
      @close="closeModal"
      @hidden="closeModal"
      title-tag="h3"
      centered
    >
      <div>
        <b-row>
          <!-- Details of Order Payment -->
          <b-col lg="6" xl="5" class="border-right">
            <div class="py-1 px-3 mx-3 mt-2 mb-2 show-module rounded border">
              <b-icon icon="person" class="bg-orange-i"></b-icon>
              <span class="module-name ml-1">{{ binnacle.clientName }}</span>
            </div>

            <hr style="margin-top: 0.2rem !important" />
            <div class="scroll-kanban max-height-content">
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4">Mobile</span>
                <div class="col-md-8">
                  <strong>{{ binnacle.mobile }}</strong>
                </div>
              </b-row>
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4">State</span>
                <div class="col-md-8">
                  <strong>{{ binnacle.state }}</strong>
                </div>
              </b-row>
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4">Seller</span>
                <div class="col-md-8">
                  <div>
                    {{ binnacle.seller }}
                  </div>
                </div>
              </b-row>
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4">Status</span>
                <div class="col-md-8">
                  <b-badge
                    :variant="`light-${resolveStyleStatus(binnacle.status)}`"
                  >
                    {{ resolveTextStatus(binnacle.status) }}
                  </b-badge>
                </div>
              </b-row>
            </div>
          </b-col>

          <!-- Chat -->
          <b-col lg="6" xl="7">
            <div class="py-1 px-2 scroll-kanban max-height-content">
              <div
                class="my-1 rounded card-comments"
                :class="[
                  isDarkSkin ? 'bg-rgba' : 'bg-rgba-light',
                  comments.length === 0
                    ? 'd-flex text-center justify-content-center align-items-center'
                    : '',
                ]"
                ref="scrollList"
              >
                <div v-if="comments.length === 0">
                  <feather-icon
                    icon="MessageSquareIcon"
                    class="mr-1 text-warning"
                    size="50"
                  ></feather-icon>

                  <span
                    class="text-warning"
                    style="font-size: 1.4em; font-weight: 700"
                    >No comments</span
                  >
                </div>

                <div else>
                  <template v-for="(item, index) in comments">
                    <div
                      v-if="comments.length > 0"
                      :key="index"
                      :class="{
                        'cursor-pointer': item.is_file,
                        me: item.user_id == currentUser.user_id,
                        other: item.user_id != currentUser.user_id,
                      }"
                      class="p-1 mb-1 content-comment rounded"
                      :style="backgroundComment(1)"
                    >
                      <span
                        :style="{ color: colorNames(item) }"
                        class="font-weight-bold"
                      >
                        {{ item.user_name }}
                      </span>
                      <br />
                      <span> {{ item.message }}</span>
                      <div class="ml-4 text-right" style="color: #6e6b7b">
                        {{ item.date_time | myGlobalDay }}
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <b-row v-if="binnacle.status != 1 && !disableSends">
                <b-col sm="12">
                  <div class="d-flex justify-content-between">
                    <b-form-textarea
                      v-model="comment"
                      rows="1"
                      size="sm"
                      style="width: 89%"
                      :disabled="loading"
                    />
                    <b-button
                      variant="outline-success"
                      class="rounded"
                      @click="insertComment()"
                      :disabled="loading"
                      style="width: 10%; height: 40px"
                    >
                      <b-spinner v-if="loading" small type="grow"></b-spinner>
                      <feather-icon v-else icon="SendIcon" />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <!-- <b-col
            lg="12"
            v-if="showButtons && [3,8].includes(binnacle.status)"
          >
            <div class="text-right mt-2">
              <b-button
                variant="outline-info"
                @click="validatePassword('approve')"
                >APPROVE</b-button
              >
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="validatePassword('rejecte')"
                >REJECT</b-button
              >
            </div>
          </b-col> -->
        </b-row>
      </div>
    </b-modal>

    <ValidatePasswordModal
      v-if="validatePasswordModal"
      :validationType="validationType"
      :action="action"
      @hideModal="validatePasswordModal = false"
      @validatedPass="approve"
    />

    <revission-modal
      v-if="modal.revission"
      :modal="modal"
      :revission="modalData.revission"
      @click="
        hideModalRevission('click')
        modal.revission = false
      "
      @response="
        hideModalRevission('hideModalRevission')
        modal.revission = false
      "
      @removeFromTable="
        hideModalRevission('removeFromTable')
        modal.revision = false
      "
    />

    <ObserveModal
      v-if="modal.observe"
      :modal="modal"
      :observe="modalData.observe"
      @hideModal="responseAction"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import moment from "moment"

/* SERVICES */
import SettlementsMadeService from "@/views/debt-solution/views/settlements-made/services/SettlementsMade.service"
import SalesMadeService from "@/views/debt-solution/views/sales-made/services/sales-made.service.js"

// Import Data
import IconStatus from "@/views/specialist-digital/views/process-cr/view/components/grids/IconStatus.vue"
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue"
import StatusPayment from "@/views/commons/components/clients/stylescomponents/StatusPayment.vue"

import ValidatePasswordModal from "@/views/commons/validate-password/ValidatePasswordModal.vue"
import RevissionModal from "@/views/crm/views/sales-made/components/modals/RevissionModal.vue"
import ObserveModal from "@/views/debt-solution/views/sales-made/modal/ObservedModal.vue"
export default {
  components: {
    StatusAccount,
    StatusPayment,
    IconStatus,
    ValidatePasswordModal,
    RevissionModal,
    ObserveModal,
  },
  props: {
    binnacle: {
      type: Object,
      required: true,
    },
    disableSends: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: "",
      data: null,
      comment: null,
      optionsMessage: [],
      comments: [],
      details: {
        order_id: null,
        titleHeader: null,
        created_at: null,
        status: null,
        status_id: null,
        statusColor: null,
        request_by: null,
        code: null,
        amount: null,
      },
      modal: {
        revission: false,
        observe: false,
      },
      modalData: {
        revission: {},
        observe: {},
      },
      statusBySeller: "pending",
      statusBySupervisor: "pending",
      loading: false,
      validatePasswordModal: false,
      validationType: "seller",
      showButtons: false,
      chatUserIds: [],
      colors: [
        '#BB1EF5',
        '#2BC8CA',
        '#F2994A',
        '#F2C94C',
        '#F24A4A'
      ]
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    origin() {
      return this.binnacle.origin == "DS" ? 1 : 2
    },
    moduleId() {
      return this.$route.matched[0].meta.module
    },
  },
  async created() {
    try {
      this.addPreloader()
      await Promise.all([
        this.searhBinnacleSalesMade(),
        this.getSaleMadeTracking(),
      ])
    } catch (error) {
      console.log(error)
    } finally {
      this.removePreloader()
    }
  },
  mounted() {
    this.toggleModal("binnacle-sales-made-modal")

    this.scrollCommentsBottom()
    this.activeSocketBinnacleMessage()
  },

  methods: {
    closeModal() {
      this.$emit("close")
    },
    hideModalRevission(action) {
      this.$emit("close", true)
    },
    responseAction(action) {
      if (action == true) {
        this.$emit("close", true)
      }
      this.modal.observe = false
    },
    resolveStyleStatus(status) {
      switch (status) {
        case 8:
          return "warning"
        case 4:
          return "success"
        case 3:
          return "danger"
        case 9:
          return "danger"
        case 10:
          return "danger"
        default:
          return "primary";
      }
    },
    resolveTextStatus(status) {
      switch (status) {
        case 8:
          return "Underview"
        case 4:
          return "Approve"
        case 3:
          return "Dissaproved"
        case 9:
          return "Rejected by Program"
        case 10:
          return "Rejected by MG"
        default:
          return "Not defined";
      }
    },
    async approve() {
      try {
        this.validatePasswordModal = false
        this.addPreloader()
        if (this.moduleId == 5) {
          if (this.action == "approve") {
            // this.statusBySeller == "approved by seller" &&
            this.modalData.revission.nameProgram = this.binnacle.nameProgram
            this.modalData.revission.idProgram = this.binnacle.idProgram
            this.modalData.revission.client_type_id =
              this.binnacle.client_type_id
            this.modalData.revission.nameClient = this.binnacle.nameClient
            this.modalData.revission.type = 4
            this.modalData.revission.idsales = this.binnacle.idsales
            this.modalData.revission.initialPayment =
              this.binnacle.initialPayment
            this.modalData.revission.account = this.binnacle.account
            this.modalData.revission.leadId = this.binnacle.leadId
            this.modalData.revission.datevent = this.binnacle.datevent
            this.modalData.revission.sellerName = this.binnacle.sellerName
            this.modalData.revission.language = this.binnacle.language
            this.modalData.revission.user_id = this.binnacle.user_id
            this.modalData.revission.last_name = this.binnacle.last_name
            this.modalData.revission.seller_id = this.binnacle.seller_id

            this.modalData.revission.comes_from = this.binnacle?.comes_from
            this.modal.revission = true
          } else if (this.action == "rejecte") {
            this.modalData.observe.nameProgram = this.binnacle.nameProgram
            this.modalData.observe.idProgram = this.binnacle.idProgram
            this.modalData.observe.client_type_id = this.binnacle.client_type_id
            this.modalData.observe.nameClient = this.binnacle.nameClient
            this.modalData.observe.type = 9
            this.modalData.observe.idsales = this.binnacle.idsales
            this.modalData.observe.initialPayment = this.binnacle.initialPayment
            this.modalData.observe.account = this.binnacle.account
            this.modalData.observe.leadId = this.binnacle.leadId
            this.modalData.observe.datevent = this.binnacle.datevent
            this.modalData.observe.sellerName = this.binnacle.sellerName
            this.modalData.observe.language = this.binnacle.language
            this.modalData.observe.user_id = this.binnacle.user_id
            this.modalData.observe.last_name = this.binnacle.last_name
            this.modalData.observe.seller_id = this.binnacle.seller_id
            this.modalData.observe.mobile = this.binnacle.mobile
            this.modal.observe = true
          }
          const params = {
            leadid: this.modalData.observe.leadId,
            observation: this.observation,
            role_id: this.currentUser.role_id,
            sale_id: this.modalData.observe.idsales,
            state: this.modalData.observe.type,
            user_id: this.modalData.observe.user_id,
            moduleAction: this.moduleId, // MG 16
          }
          const response = await SalesMadeService.rejecteSale(params)
        }
        await this.getSaleMadeTracking()
      } catch (error) {
        console.log(error)
      } finally {
        this.removePreloader()
      }
    },
    validatePassword(action) {
      this.action = action
      if (this.moduleId == 5) {
        this.validationType = "ds_supervisor"
      } else if (this.moduleId == 2 && this.currentUser.role_id == 2) {
        this.validationType = "crm_supervisor"
      } else if (this.moduleId == 2 && this.currentUser.role_id == 5) {
        this.validationType = "crm_supervisor"
      }

      this.validatePasswordModal = true
    },
    async getSaleMadeTracking() {
      try {
        this.addPreloader()
        this.showButtons = true
        const { data } = await SalesMadeService.getSaleMadeTracking({
          sale_id: this.binnacle.sale_id,
        })

        if (data[0]) {
          const type = data[0].type
          if (type == "approved by seller" || type == "rejected by seller") {
            this.statusBySeller = type
            if (this.moduleId == 2) {
              this.showButtons = false
            }
          } else if (
            type == "approved by supervisor" ||
            type == "rejected by supervisor"
          ) {
            this.statusBySupervisor = type
            if (this.moduleId == 5) {
              this.showButtons = true
            }
          }
        }

        if (data[1]) {
          const type = data[1].type
          if (type == "approved by seller" || type == "rejected by seller") {
            this.statusBySeller = type
          } else if (
            type == "approved by supervisor" ||
            type == "rejected by supervisor"
          ) {
            this.statusBySupervisor = type
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.removePreloader()
      }
    },
    async searhBinnacleSalesMade() {
      try {
        // this.addPreloader();
        const params = {
          sale_id: this.binnacle.sale_id,
          origin: this.binnacle.origin == "DS" ? 1 : 2, // no requerido
        }
        const { data } = await SalesMadeService.getMessageBinnacle(params)
        this.comments = data
        let userIds = this.comments.map(item => item.user_id);
        this.chatUserIds = [...new Set(userIds)];
        this.scrollCommentsBottom()
      } catch (error) {
        c`onsole.log(error);`
      } finally {
        // this.removePreloader();
      }
    },
    colorNames(item){
      let index = this.chatUserIds.findIndex(val => val == item.user_id)
      return this.colors[index]
    },
    async insertComment() {
      if (this.comment == null || this.comment == "") {
        return
      }
      try {
        this.addPreloader()
        this.scrollCommentsBottom()
        this.loading = true
        const params = {
          sale_id: this.binnacle.sale_id,
          message: this.comment,
          user_id: this.currentUser.user_id,
          origin: 1,
          client_name: this.binnacle.clientName,
          seller_id: this.binnacle.seller_id,
          origin_id: this.moduleId
        }
        const response = await SalesMadeService.insertBinnacleMessage(params)
        this.comment = null
        this.comments = response.data
      } catch (e) {
        this.$swal.fire({
          icon: "error",
          title: e,
        })
        this.comments.splice(this.components.length - 1, 1)
      } finally {
        this.loading = false
        this.removePreloader()
      }
    },
    scrollCommentsBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.scrollList.scrollTop = this.$refs.scrollList.scrollHeight
        }, 100)
      })
    },
    backgroundComment(type) {
      let boxShadow = "0 1px 3px rgb(0 0 0 / 20%)"
      let style = ""
      if (type == 1) {
        style = `background: ${
          this.isDarkSkin ? `rgb(32 32 36)` : "rgb(255 255 255)"
        }!important;`
      } else if (type == 2) {
        style = `background: ${
          this.isDarkSkin ? `rgb(16 16 18)` : "rgb(228 234 236)"
        }!important;`
      } else {
        style = `background: ${
          this.isDarkSkin ? `rgb(23 23 26)` : "rgb(255 246 244) "
        }!important;`
      }

      return `${style} box-shadow: ${
        !this.isDarkSkin ? boxShadow : ""
      }!important;`
    },
    checkStatusColor(status) {
      switch (true) {
        case status === 1: // PENDING DEBIT CONFIRMATION
          return "#ffd833"
        case status === 2: // PENDING PROCESSING DUE TO INSUFFICIENT FUNDS
          return "#ff6207"
        case status === 3: // DEBIT REJECTED
          return "#ff0000"
        case status === 4: // DEBIT CONFIRMED
          return "#66b70b"
        case status === 5: // PENDING COMPLETION
          return "ffd833"
        case status === 6: // COMPLETED
          return "#54b408"
        default:
          break
      }
    },
    formatDateTime(date) {
      return moment(date).format("MM/DD/YYYY HH:mm")
    },
    activeSocketBinnacleMessage() {
      try {
        window.socket.subscribe("debtsolution-notifications")
        window.socket.bind(
          "binnacle-message-debtsolution-notifications",
          async (response) => {
            const { message } = response
            if (message.type == "message") {
              if (this.binnacle.sale_id == message.sale_id) {
                const isset_message = this.comments.find(
                  (row) => row.id == message.id
                )
                if (isset_message == undefined) {
                  this.comments.push(message)
                  this.scrollCommentsBottom()
                }
              }
            } else {
              this.getSaleMadeTracking()
              this.searhBinnacleSalesMade()
            }
          }
        )
      } catch (error) {
        console.log("Socket-binnacle: ", error)
      }
    },
  },
  beforeDestroy() {
    try {
      window.socket.unbind("binnacle-message-debtsolution-notifications")
    } catch (error) {
      console.log(error)
    }
  },
}
</script>

<style scoped>
.card-comments {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  font-size: 1rem;
  min-height: 45vh;
  max-height: 45vh;
  overflow-y: auto;
  box-shadow: 0 0 1.2px 1px rgb(0 0 0 / 0.1);
}
.content-comment {
  max-width: 80%;
  border-radius: 20px;
  padding: 5px 10px;
}
.me {
  margin-left: auto;
}
.other {
  margin-right: auto;
}

.bg-rgba {
  background: rgb(0 0 0 / 18%) !important;
}
.bg-rgba-light {
  background: rgb(0 0 0 / 4%) !important;
}
.bg-orange-i {
  color: #ff6045;
  font-size: 1.5rem;
}

.style-chooser.vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
}
</style>
