export default [
    {
        path: "risky-clients",
        name: "quality-risky-clients",
        component: () =>
            import(
                "@/views/quality/views/risky-clients/views/components/RiskyClientsTable.vue"
            ),
        meta: {
            pageTitle: "Risky Clients",
            breadcrumb: [
                {
                    text: "Risky Clients",
                },
            ],
        },
    },
]