import store from '@/store'
import Vue from "vue";
import router from "@/router";
import PermissionActionService from "@/views/commons/components/permissions/services/permission.service.js";

const PermissionNotificationDS = () => {
    window.socket.bind('permission-notification-debtsolution', async (response) => {
        const { user, code, reason, name, status} = response;
        const json_user = JSON.parse(user);
        const current_user_id = store.state.auth.currentUser.user_id;

        for (const user of json_user) {
            if (user.user_id == current_user_id) {
                Vue.prototype.$amgAlert({
                    title: status === 1 ? 'Forwarding' : 'New' + ' Permission',
                    subtitle: name,
                    data: [
                        { icon: 'KeyIcon', title: 'CODE', description: code },
                        { icon: 'UserIcon', title: 'USER', description: user.username },
                        { icon: 'RepeatIcon', title: 'REASON', description: reason },

                    ],


                    okTitle: 'Go to Permission Request',
                    icon: "KeyIcon",
                    ok: () => {
                        const goSection = "permission-section-debtsolution";
                        if (router.currentRoute.name !== goSection) {
                            router.push({ name: goSection });
                        }
                    },
                });
            }
        }

        const params = { 
            module_id: 5,
            user_id: current_user_id
          }
          const {data} = await PermissionActionService.getCounter(params)

          const payload = {
              routeName: 'permission-section-debtsolution',
              tag: data
          }
          store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload)

    })
}

const PermissionAttenNotificationDS = () => {
    window.socket.bind('permission-attended-notification-debtsolution', async (response) => {
        const { user_approved, user_receiver, name, code, to_redirect } = response;
        const current_user_id = store.state.auth.currentUser.user_id;

        if (user_receiver === current_user_id) {
            Vue.prototype.$amgAlert({
                title: 'Permission Action',
                subtitle: name,
                data: [
                    { icon: 'KeyIcon', title: 'CODE', description: code },
                    { icon: 'UserIcon', title: 'ATTENDED BY', description: user_approved },
                    { icon: 'RepeatIcon', title: 'STATUS', description: to_redirect.status },

                ],

                okTitle: to_redirect.title,
                icon: "KeyIcon",
                ok: () => {
                    const goSection = to_redirect.router_name;
                    if (router.currentRoute.name !== goSection) {
                        let route_to = { name: goSection }
                        if (to_redirect.has_params) {
                            route_to = {
                                name: goSection, params: {
                                    [to_redirect.params_name]: to_redirect.params
                                }
                            }
                        }
                        router.push(route_to);

                    }
                },
            });
        }
    })
}

export { PermissionNotificationDS, PermissionAttenNotificationDS }

