export default {
  path: "calendar",
  name: "calendar-administration",
  component: () =>
    import(
      /* webpackChunkName: "BookeepingCalendar" */ "@/views/administration/views/calendar/calendar.vue"
    ),
  meta: {
    permittedRoles: [1, 2],
    pageTitle: "Calendar",
    breadcrumb: [
      {
        text: "Calendar",
      },
    ],
    // permittedRoles: [1,2],
  },
};
