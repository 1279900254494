/* eslint-disable camelcase */
/* eslint-disable no-useless-catch */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class ClientDashboardService {
  async getClientData(id) {
    try {
      const { data } = await amgApi.post('commons/search-account', { id });
      return data[0];
    } catch (err) {
      console.log(err);
    }
  }

  async getHistoryStatus(id) {
    // recibe client account id
    try {
      const { data } = await amgApi.post('paragon/get-history-status', { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getCompanyData(id_account) {
    try {
      const { data } = await amgApi.post('paragon/get-company-data', {
        id_account,
      });
      return data[0];
    } catch (err) {
      console.log(err);
    }
  }

  async getTaskData(parameters) {
    // recibe 2 parametros: id:(client account id) user_id:( id de usuario actual)
    try {
      const { data } = await amgApi.post('paragon/get-task-data', parameters);
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getNotesData(parameters) {
    // recibe 3 parametros: id:(client account id) year:(year actual o a busacar) iduser:(id de usuario actual)
    try {
      const { data } = await amgApi.post('paragon/get-note-data', parameters);
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async editMemo(parameters) {
    // recibe 3 parametros: id:(client_account_id) memo: (mensaje) user_id: (id de usuario actual)
    try {
      const { status } = await amgApi.post('paragon/edit-memo', parameters);
      return !!status;
    } catch (err) {
      console.log(err);
    }
  }

  async updateCompanyData(parameters) {
    try {
      /* recibe 4 parametros:
                dataa: (mensaje)
                id_account: (client account id)
                id_user: (id de usuario actual)
                typee: (indice del input a actualizar del 1 al 7)
            */
      const { status } = await amgApi.post(
        'paragon/update-company-data',
        parameters,
      );
      return status === 200;
    } catch (err) {
      console.log(err);
    }
  }

  async getCharges(id) {
    try {
      const { data } = await amgApi.post('paragon/get-charges', { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getPayments(id) {
    try {
      const { data } = await amgApi.post('paragon/get-payments', { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getScores(id) {
    // recibe lead_id
    try {
      const { data } = await amgApi.post('paragon/get-scores', { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getHistorySms(parameters) {
    // recibe 2 parametros: id(client account id) type: 0
    try {
      const { data } = await amgApi.post('paragon/get-history-sms', parameters);
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getHistoryEmail(id) {
    // recibe id(client account id)
    try {
      const { data } = await amgApi.post('paragon/get-history-email', { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getHistoryLetter(id) {
    // recibe id(client account id)
    try {
      const { data } = await amgApi.post('paragon/get-history-letter', { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async saveBenefitAccount(parameters) {
    // recibe 4 parametros id_account(client account id), id_benefit, comment, id_user (id usuario actual)
    try {
      const { status } = await amgApi.post(
        'paragon/save-benefit-account',
        parameters,
      );
      return status;
    } catch (err) {
      console.log(err);
    }
  }

  async getTrackingChangeAddress(parameters) {
    // recibe 3 parametros id_lead, typee:1 , id_module
    try {
      const { data } = await amgApi.post(
        'paragon/get-tracking-change-address',
        parameters,
      );
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getTrackingCompanyData(parameters) {
    // recibe 2 parametros : id_account , typee
    try {
      const { data } = await amgApi.post(
        'paragon/get-tracking-company-data',
        parameters,
      );
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getHistoryMonthlyPayments(id) {
    // recibe id (account id)
    try {
      const { data } = await amgApi.post(
        'paragon/get-history-monthly-payments',
        { id },
      );
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getPaymentsData(id) {
    // recibe id (account id)
    try {
      const { data } = await amgApi.post('paragon/get-payments-data', { id });
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async getBusinessType() {
    try {
      const response = await amgApi.get('business/get-business-type');
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  async getDataClient(params) {
    try {
      const response = await amgApi.post('commons/get-data-client',  params);
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  async saveFormCompany(params) {
    try {
      const response = await amgApi.post("commons/save-form-company", params);
      return response;
    } catch (err) {
      console.log(err);
    }
  }
  async saveFormCompany(params) {
    try {
      const response = await amgApi.post("commons/save-form-company", params);
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  async generatePdfBusinessClient(params) {
    try {
      const data = await amgApi.post(
        "/business/generate-pdf-bussiness-client",
        params,
        { responseType: "blob" }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getInCourtByClient(params) {
    try {
      const data = await amgApi.post("/commons/get-in-court-by-client", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getLeadInformation(params) {
    try {
      const data = await amgApi.post("/commons/get-lead-information", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCountCasesPending(params) {
    try {
      const data = await amgApi.post("/debt-solution/court-info/get-cases-pending",params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async listTicketPerCustomer(body) {
    try {
      const { data, status } = await amgApi.post('customer-tickets/list-ticket-per-customer', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }
}

export default new ClientDashboardService();
