<template>
  <b-modal
    ref="modalNotesFirstCurrent"
    modal-class="custom-modal-amg"
    header-class="p-0"
    header-bg-variant="transparent"
    title="Business Note"
    size="xmd"
    no-close-on-backdrop
    scrollable
    @hidden="$emit('hidden')"
  >
    <template #modal-header>
      <header-modal-notes
        :info="noteInfo"
        program="Bussiness"
        @close="$emit('hidden')"
      />
    </template>
    <div class="d-flex justify-content-end mb-1" v-if="!isNewSale">
      <b-button variant="outline-success" @click="$emit('showOldVersion')"
        >Show Old Version</b-button
      >
    </div>
    <validation-observer ref="form">
      <b-row class="mx-0">
        <b-col cols="12" sm="6">
          <b-form-group label="Tax ID">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-select
                v-model="note.taxId"
                :options="taxIdOptions"
                :clearable="false"
                placeholder="Select an option"
                :class="{ 'border-danger rounded': errors.length > 0 }"
                :disabled="disabled"
              />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" sm="6">
          <b-form-group label="Contact Time">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-select
                v-model="note.contactTime"
                :options="contactTimeOptions"
                :clearable="false"
                placeholder="Select an option"
                :class="{ 'border-danger rounded': errors.length > 0 }"
                :disabled="disabled"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
        <hr class="w-100 mx-1" />
      </b-row>

      <!-- Administrative -->
      <b-row class="border rounded-lg m-1 p-1">
        <b-col cols="12">
          <strong>1. ADMINISTRATIVE</strong>
          <hr />
        </b-col>

        <!-- New Business -->
        <b-col cols="12" :sm="isNotRegister ? 6 : 4">
          <b-form-group label="New Business">
            <validation-provider rules="required" v-slot="{ errors }">
              <v-select
                v-model="note.new_business"
                :options="generalOPtions"
                :clearable="false"
                placeholder="Select an option"
                :class="{ 'border-danger rounded': errors.length > 0 }"
                :disabled="disabled"
                @input="resetFields(1)"
              />
            </validation-provider>
          </b-form-group>
        </b-col>

        <!-- Register -->
        <template v-if="isNotNewBusiness">
          <b-col cols="12" :sm="isNotRegister ? 6 : 4">
            <b-form-group label="Register">
              <validation-provider rules="required" v-slot="{ errors }">
                <v-select
                  v-model="note.register"
                  :options="generalOPtions"
                  :clearable="false"
                  placeholder="Select an option"
                  :class="{ 'border-danger rounded': errors.length > 0 }"
                  :disabled="disabled"
                  @input="resetFields(2)"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </template>

        <!-- Notes -->
        <template v-if="isNewBusiness || isNotRegister">
          <b-col cols="12" sm="12">
            <b-form-group label="Notes">
              <validation-provider rules="required" v-slot="{ errors }">
                <quill-editor
                  v-model="note.notes"
                  :options="editorOption"
                  :disabled="disabled"
                  :class="{ 'border-danger rounded': errors[0] }"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
        </template>

        <template v-if="isNotNewBusiness && isRegister">
          <!-- corporation -->
          <template>
            <b-col cols="12" sm="4">
              <b-form-group label="Company">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="note.corporation"
                    :options="generalOPtions"
                    :clearable="false"
                    placeholder="Select an option"
                    :class="{ 'border-danger rounded': errors.length > 0 }"
                    :disabled="disabled"
                    @input="resetFields(3)"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="isCorporation">
              <b-col cols="12" sm="4">
                <b-form-group label="Type">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="note.corporationType"
                      :options="corporationTypeOptions"
                      :clearable="false"
                      placeholder="Select an option"
                      :class="{ 'border-danger rounded': errors.length > 0 }"
                      :disabled="disabled"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="4">
                <b-form-group
                  :label="
                    notesCompleted && note.corporationImgeUrl
                      ? 'Show Company Image'
                      : 'Upload Company Image'
                  "
                >
                  <b-input-group>
                    <b-input-group-append
                      v-if="note.corporationImgeUrl"
                      is-text
                      class="cursor-pointer"
                      v-b-tooltip.hover.left="'Preview Image'"
                      @click="openPreviewImage(note.corporationImgeUrl)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-append>
                    <b-form-file
                      v-model="note.corporationImge"
                      placeholder="Choose an image or drop it here..."
                      drop-placeholder="Drop image here..."
                      accept="image/jpeg, image/png, image/bmp, image/webp"
                      :disabled="disabled"
                      @change="loadFile($event, 1)"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </template>
          </template>

          <!-- banck account -->
          <template v-if="isNotNullCorporation">
            <b-col cols="12" sm="4">
              <b-form-group label="Bank Account">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="note.bankAccount"
                    :options="generalOPtions"
                    :clearable="false"
                    placeholder="Select an option"
                    :class="{ 'border-danger rounded': errors.length > 0 }"
                    :disabled="disabled"
                    @input="resetFields(4)"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="isBankAccount">
              <b-col cols="12" sm="4">
                <b-form-group label="Bank Type">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="note.bankType"
                      :options="bankTypeOptions"
                      :clearable="false"
                      placeholder="Select an option"
                      :disabled="disabled"
                      :class="{ 'border-danger rounded': errors.length > 0 }"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="4">
                <b-form-group label="Bank Name">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="note.bankName"
                      :options="bankNameOptions"
                      :clearable="false"
                      placeholder="Select an option"
                      :disabled="disabled"
                      :class="{ 'border-danger rounded': errors.length > 0 }"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </template>

          <!-- Bookkeeping -->
          <template v-if="isNotNullBankAccount">
            <b-col cols="12" sm="4">
              <b-form-group label="Bookkeeping Up To Date">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="note.bookeeping"
                    :options="generalOPtions"
                    :clearable="false"
                    placeholder="Select an option"
                    :class="{ 'border-danger rounded': errors.length > 0 }"
                    :disabled="disabled"
                    @input="resetFields(5)"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="isBookeepingUpToDate">
              <b-col class="12" sm="4">
                <b-form-group
                  label="Bookkeeping Type"
                  v-slot="{ ariaDescribedby }"
                >
                  <validation-provider rules="required" v-slot="{ errors }">
                    <b-form-radio-group
                      id="btn-radios-1"
                      v-model="note.bookeepingType"
                      :options="bookeepingTypeOptions"
                      :aria-describedby="ariaDescribedby"
                      name="bookeepingType"
                      buttons
                      :button-variant="
                        errors.length > 0 ? 'outline-danger' : 'outline-primary'
                      "
                      :state="errors[0] ? false : null"
                      :disabled="disabled"
                      class="w-100"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>

            <template
              v-if="!isBookeepingUpToDate && isNotNullBookeepingUpToDate"
            >
              <b-col cols="12" sm="12">
                <b-form-group label="Bookkeeping Description">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <quill-editor
                      v-model="note.bookeepingDescription"
                      :options="editorOption"
                      :class="{ 'border-danger rounded': errors[0] }"
                      :disabled="disabled"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </template>

          <!-- Taxes Up To Date -->
          <template v-if="isNotNullBookeepingUpToDate">
            <b-col cols="12" sm="4">
              <b-form-group label="Taxes Up To Date">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="note.taxes"
                    :options="generalOPtions"
                    :clearable="false"
                    placeholder="Select an option"
                    :class="{ 'border-danger rounded': errors.length > 0 }"
                    :disabled="disabled"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="isNotNullTaxes">
              <b-col cols="12" sm="12">
                <b-form-group label="Tax Description">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <quill-editor
                      v-model="note.taxesDescription"
                      :options="editorOption"
                      :class="{ 'border-danger rounded': errors[0] }"
                      :disabled="disabled"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </template>

          <!-- Merchant -->
          <template v-if="isNotNullTaxes">
            <b-col cols="12" sm="4">
              <b-form-group label="Merchant">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="note.merchant"
                    :options="generalOPtions"
                    :clearable="false"
                    placeholder="Select an option"
                    :class="{ 'border-danger rounded': errors.length > 0 }"
                    :disabled="disabled"
                    @input="resetFields(6)"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="isMerchant">
              <b-col cols="12" sm="4">
                <b-form-group label="Merchant Type">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="note.merchantType"
                      :options="merchantTypeOptions"
                      :clearable="false"
                      placeholder="Select an option"
                      :disabled="disabled"
                      :class="{ 'border-danger rounded': errors.length > 0 }"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="isNotNullMerchant && !isMerchant">
              <b-col cols="12" sm="12">
                <b-form-group label="Merchant Description">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <quill-editor
                      v-model="note.merchantDescription"
                      :options="editorOption"
                      :class="{ 'border-danger rounded': errors[0] }"
                      :disabled="disabled"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </template>

          <!-- Business Credit -->
          <template v-if="isNotNullMerchant">
            <b-col cols="12" sm="4">
              <b-form-group label="Business Credit">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="note.businessCredit"
                    :options="generalOPtions"
                    :clearable="false"
                    placeholder="Select an option"
                    :class="{ 'border-danger rounded': errors.length > 0 }"
                    :disabled="disabled"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="isNotNullBusinessCredit">
              <b-col cols="12" sm="12">
                <b-form-group label="Business Credit Description">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <quill-editor
                      v-model="note.businessCreditDescription"
                      :options="editorOption"
                      :class="{ 'border-danger rounded': errors[0] }"
                      :disabled="disabled"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </template>
        </template>
      </b-row>

      <!-- marketing -->
      <template
        v-if="isNotNewBusiness && isRegister && isNotNullBusinessCredit"
      >
        <b-row class="border rounded-lg m-1 p-1">
          <b-col cols="12 mb-2 pb-1 border-bottom">
            <strong>2. MARKETING</strong>
          </b-col>

          <!-- logo -->
          <template v-if="isNotNullBusinessCredit">
            <b-col cols="12" sm="6">
              <b-form-group label="Logo">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="note.logo"
                    :options="generalOPtions"
                    :clearable="false"
                    placeholder="Select an option"
                    :class="{ 'border-danger rounded': errors.length > 0 }"
                    :disabled="disabled"
                    @input="resetFields(7)"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="isLogo">
              <b-col cols="12" sm="6">
                <b-form-group
                  :label="
                    notesCompleted && note.logoImageUrl
                      ? 'Show Logo'
                      : 'Upload Logo'
                  "
                >
                  <b-input-group>
                    <b-input-group-append
                      v-if="note.logoImageUrl"
                      is-text
                      class="cursor-pointer"
                      v-b-tooltip.hover.left="'Preview Image'"
                      @click="openPreviewImage(note.logoImageUrl)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-input-group-append>
                    <b-form-file
                      v-model="note.logoImage"
                      placeholder="Choose an image or drop it here..."
                      drop-placeholder="Drop image here..."
                      accept="image/jpeg, image/png, image/bmp, image/webp"
                      :disabled="disabled"
                      @change="loadFile($event, 2)"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="!isLogo && isNotNullLogo">
              <b-col cols="12" sm="12">
                <b-form-group label="Logo Description">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <quill-editor
                      v-model="note.logoDescription"
                      :options="editorOption"
                      :disabled="disabled"
                      :class="{ 'border-danger rounded': errors[0] }"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </template>

          <!-- website  -->
          <template v-if="isNotNullLogo">
            <b-col cols="12" sm="4">
              <b-form-group label="Website">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="note.website"
                    :options="generalOPtions"
                    :clearable="false"
                    placeholder="Select an option"
                    :class="{ 'border-danger rounded': errors.length > 0 }"
                    :disabled="disabled"
                    @input="resetFields(8)"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="isWebsite">
              <b-col cols="12" sm="4">
                <b-form-group label="Website Type">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="note.websiteType"
                      :options="websiteTypeOptions"
                      :clearable="false"
                      placeholder="Select an option"
                      :class="{ 'border-danger rounded': errors.length > 0 }"
                      :disabled="disabled"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="4">
                <b-form-group label="Website URL">
                  <validation-provider rules="required|url" v-slot="{ errors }">
                    <b-form-input
                      v-model="note.websiteUrl"
                      :class="{ 'border-danger rounded': errors.length > 0 }"
                      placeholder="Write here..."
                      :disabled="disabled"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="isNotNullWebsite">
              <b-col cols="12" sm="12">
                <b-form-group label="Website Description">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <quill-editor
                      v-model="note.websiteDescription"
                      :options="editorOption"
                      :class="{ 'border-danger rounded': errors[0] }"
                      :disabled="disabled"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </template>

          <!-- fanpage -->
          <template v-if="isNotNullWebsite">
            <b-col cols="12" sm="4">
              <b-form-group label="Fan Page">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="note.fanpage"
                    :options="generalOPtions"
                    :clearable="false"
                    placeholder="Select an option"
                    :class="{ 'border-danger rounded': errors.length > 0 }"
                    :disabled="disabled"
                    @input="resetFields(9)"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="isFanpage">
              <b-col cols="12" sm="4">
                <b-form-group label="Fan Page Type">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="note.fanpageType"
                      :options="fanpageTypeOptions"
                      multiple
                      placeholder="Select an option"
                      :class="{ 'border-danger rounded': errors.length > 0 }"
                      :disabled="disabled"
                      @input="buildFanpageUrl"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>

              <template v-for="(item, index) in note.fanpageUrl">
                <b-col cols="12" sm="4" :key="index">
                  <b-form-group :label="`${note.fanpageType[index]} Url`">
                    <validation-provider
                      rules="required|url"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="note.fanpageUrl[index]"
                        :class="{ 'border-danger rounded': errors.length > 0 }"
                        placeholder="Write here..."
                        :disabled="disabled"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
            </template>

            <template v-if="isNotNullFanpage">
              <b-col cols="12" sm="12">
                <b-form-group label="Fan Page Description">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <quill-editor
                      v-model="note.fanpageDescription"
                      :options="editorOption"
                      :class="{ 'border-danger rounded': errors[0] }"
                      :disabled="disabled"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </template>

          <!-- ads -->
          <template v-if="isNotNullFanpage">
            <b-col cols="12" sm="6">
              <b-form-group label="Ads">
                <validation-provider rules="required" v-slot="{ errors }">
                  <v-select
                    v-model="note.ads"
                    :options="generalOPtions"
                    :clearable="false"
                    placeholder="Select an option"
                    :class="{ 'border-danger rounded': errors.length > 0 }"
                    @input="resetFields(10)"
                    :disabled="disabled"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>

            <template v-if="isAds">
              <b-col cols="12" sm="6">
                <b-form-group label="Ads Type">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <v-select
                      v-model="note.adsType"
                      :options="adsTypeOptions"
                      :clearable="false"
                      placeholder="Select an option"
                      :class="{ 'border-danger rounded': errors.length > 0 }"
                      :disabled="disabled"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>

            <template v-if="isNotNullAds">
              <b-col cols="12" sm="12">
                <b-form-group label="Ads Description">
                  <validation-provider rules="required" v-slot="{ errors }">
                    <quill-editor
                      v-model="note.adsDescription"
                      :options="editorOption"
                      :class="{ 'border-danger rounded': errors[0] }"
                      :disabled="disabled"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </template>
          </template>
        </b-row>
      </template>

      <template v-else>
        <b-row class="border rounded-lg m-1 p-1 disabled-row" disabled>
          <b-col cols="12">
            <strong class="text-muted">2. MARKETING</strong>
            <hr />
          </b-col>
        </b-row>
      </template>

      <!-- comments -->
      <b-row class="mx-1 p-1 border rounded-lg">
        <b-col cols="12">
          <strong>3. COMMENTS </strong>
          <hr />
        </b-col>
        <b-col cols="12" sm="12">
          <b-form-group>
            <quill-editor
              v-model="note.comments"
              :options="editorOption"
              :disabled="disabled"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <button-save v-if="showButtonSave" @click="saveNotesIncomplete" />
      <button-save-and-complete
        v-if="showButtonSave"
        @click="saveNotesCompleted"
      />
      <button-update v-if="showButtonUpdate" @click="updateNotesCompleted" />
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
import ButtonSaveAndComplete from "@/views/commons/utilities/ButtonSaveAndComplete.vue";
import ButtonUpdate from "@/views/commons/utilities/ButtonUpdate.vue";
import HeaderModalNotes from "@/views/commons/components/first-notes/HeaderModalNotes.vue";
import NotesServices from "@/views/commons/components/first-notes/services/notes.service";
import PredefinedAnswersService from "@/views/business/views/settings/service/predefined-answers.service.js";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
  components: {
    ButtonSave,
    ButtonSaveAndComplete,
    ButtonUpdate,
    HeaderModalNotes,
    quillEditor,
  },
  props: {
    noteInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isNewSale: {
      type: Boolean,
      required: true,
    },
    salesCompleted: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showSave: false,
      showUpdate: false,
      generalOPtions: ["Yes", "No"],
      taxIdOptions: ["SSN", "ITIN", "ENROLLED IN TAX RESEARCH"],
      contactTimeOptions: [
        "8:00-9:00",
        "9:00-10:00",
        "10:00-11:00",
        "11:00-12:00",
        "12:00-13:00",
        "13:00-14:00",
        "14:00-15:00",
        "15:00-16:00",
        "16:00-17:00",
        "17:00-18:00",
        "18:00-19:00",
        "19:00-20:00",
      ],
      bookeepingTypeOptions: [
        { text: "Private", value: "Private" },
        { text: "In Proper", value: "In Proper" },
      ],

      corporationTypeOptions: [],
      bankNameOptions: [],
      merchantTypeOptions: [],
      websiteTypeOptions: [],
      fanpageTypeOptions: [],
      adsTypeOptions: [],
      bankTypeOptions: [],

      note: {
        taxId: null,
        contactTime: null,
        new_business: null,
        register: null,
        notes: null,
        corporation: null,
        corporationType: null,
        corporationImge: null,
        bankAccount: null,
        bankType: null,
        bankName: null,
        bookeeping: null,
        bookeepingType: null,
        bookeepingDescription: null,
        taxes: null,
        taxesDescription: null,
        merchant: null,
        merchantType: null,
        merchantDescription: null,
        businessCredit: null,
        businessCreditDescription: null,
        logo: null,
        logoImage: null,
        logoDescription: null,
        website: null,
        websiteType: null,
        websiteDescription: null,
        websiteUrl: null,
        fanpage: null,
        fanpageType: [],
        fanpageDescription: null,
        fanpageUrl: [],
        ads: null,
        adsType: null,
        adsDescription: null,
        comments: null,
        noteNull: null,
      },
      corporationImage: null,
      corporationImageName: null,
      logoImage: null,
      logoImageName: null,

      emptyNoteNewVersion: true,
      updateNewVersion: false,

      editorOption: {
        modules: { toolbar: false },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId(){
      console.log(this.$route.matched[0].meta.module)
      return this.$route.matched[0].meta.module
    },
    newNote() {
      return this.noteInfo.created > "2021-05-16 00:00:00";
    },
    emptyNote() {
      if (this.newNote) {
        return this.noteInfo.notes_status_new == null;
      }
      return this.noteInfo.notes_status == 0;
    },
    showButtonSave() {
      return this.moduleId != 16 && (this.showSave && !this.noteInfo.notSeller);
    },
    showButtonUpdate() {
      return this.moduleId != 16 && (this.showUpdate && !this.noteInfo.notSeller);
    },
    isNewBusiness() {
      return this.note.new_business === "Yes";
    },
    isNotNewBusiness() {
      return this.note.new_business === "No";
    },
    isNotRegister() {
      return this.note.register === "No";
    },
    isRegister() {
      return this.note.register === "Yes";
    },
    isCorporation() {
      return this.note.corporation === "Yes";
    },
    isNotNullCorporation() {
      return this.note.corporation !== null;
    },
    isBankAccount() {
      return this.note.bankAccount === "Yes";
    },
    isNotNullBankAccount() {
      return this.note.bankAccount !== null;
    },
    isBookeepingUpToDate() {
      return this.note.bookeeping === "Yes";
    },
    isNotNullBookeepingUpToDate() {
      return this.note.bookeeping !== null;
    },
    isNotNullTaxes() {
      return this.note.taxes !== null;
    },
    isMerchant() {
      return this.note.merchant === "Yes";
    },
    isNotNullMerchant() {
      return this.note.merchant !== null;
    },
    isNotNullBusinessCredit() {
      return this.note.businessCredit !== null;
    },
    isLogo() {
      return this.note.logo === "Yes";
    },
    isNotNullLogo() {
      return this.note.logo !== null;
    },
    isWebsite() {
      return this.note.website === "Yes";
    },
    isNotNullWebsite() {
      return this.note.website !== null;
    },
    isFanpage() {
      return this.note.fanpage === "Yes";
    },
    isNotNullFanpage() {
      return this.note.fanpage !== null;
    },
    isAds() {
      return this.note.ads === "Yes";
    },
    isNotNullAds() {
      return this.note.ads !== null;
    },
    notesCompleted() {
      return this.salesCompleted && this.note.noteNull == "true";
    },
    disabled() {
      return (
        this.moduleId == 16 ||
        this.noteInfo.statusSale === 4 ||
        this.noteInfo.notSeller ||
        this.notesCompleted
      );
    },
  },
  created() {
    this.getFirstNote();
  },
  mounted() {
    this.toggleModal("modalNotesFirstCurrent");
    this.getAllPredefinedAnswers();
  },
  methods: {
    async getFirstNote() {
      try {
        this.addPreloader();
        const params = { sale_id: this.noteInfo.saleId };
        const response = await NotesServices.getFirstNote(params);
        await this.getDetailsAnswers(response);
        await this.initialValidationNote(response);
        await this.validateEmptyNoteNewVersion(response);
      } catch (error) {
        this.showErrolSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getAllPredefinedAnswers() {
      try {
        const params = {
          program_id: 1,
          show_all: true,
        };
        const { data } = await PredefinedAnswersService.getPredefinedAnswers(
          params
        );
        this.buildSelectsOptions(data);
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    buildSelectsOptions(data) {
      if (data.length === 0) return;

      const findOption = (slug) => {
        const item = data.find((item) => item.slug === slug);
        return item ? JSON.parse(item.options) : [];
      };
      this.corporationTypeOptions = findOption("company-types");
      this.bankNameOptions = findOption("bank-names");
      this.merchantTypeOptions = findOption("merchant-types");
      this.websiteTypeOptions = findOption("website-types");
      this.fanpageTypeOptions = findOption("fan-page-types");
      this.adsTypeOptions = findOption("ads-types");
      this.bankTypeOptions = findOption("bank-types");
    },

    async saveNotesIncomplete() {
      if (this.emptyNote || this.emptyNoteNewVersion) {
        await this.saveNotes("insert");
        return;
      }
      await this.saveNotes("update");
    },

    async saveNotesCompleted() {
      const validate = await this.$refs.form.validate();
      if (!validate || this.note.bookeepingType === false) return;
      this.note.noteNull = "true";
      this.updateNewVersion = this.isNewSale ? false : true;
      if (this.emptyNote || this.emptyNoteNewVersion) {
        await this.saveNotes("insert");
        return;
      }
      await this.saveNotes("update");
    },

    async updateNotesCompleted() {
      const validate = await this.$refs.form.validate();
      if (!validate || this.note.bookeepingType === false) return;
      this.note.noteNull = "true";
      this.updateNewVersion = this.isNewSale ? false : true;
      await this.saveNotes("update");
    },

    answersNote() {
      return [
        { number: 2060, value: this.note.taxId },
        { number: 2061, value: this.note.contactTime },
        { number: 2062, value: this.note.new_business },
        { number: 2063, value: this.note.register },
        { number: 2064, value: this.note.notes },
        { number: 2065, value: this.note.corporation },
        { number: 2066, value: this.note.corporationType },
        { number: 2067, value: this.note.corporationImge },
        { number: 2068, value: this.note.bankAccount },
        { number: 2069, value: this.note.bankName },
        { number: 2070, value: this.note.bookeeping },
        { number: 2071, value: this.note.bookeepingType },
        { number: 2072, value: this.note.bookeepingDescription },
        { number: 2073, value: this.note.taxes },
        { number: 2074, value: this.note.taxesDescription },
        { number: 2075, value: this.note.merchant },
        { number: 2076, value: this.note.merchantType },
        { number: 2077, value: this.note.merchantDescription },
        { number: 2078, value: this.note.businessCredit },
        { number: 2079, value: this.note.businessCreditDescription },
        { number: 2080, value: this.note.logo },
        { number: 2081, value: this.note.logoImage },
        { number: 2082, value: this.note.logoDescription },
        { number: 2083, value: this.note.website },
        { number: 2084, value: this.note.websiteType },
        { number: 2085, value: this.note.websiteDescription },
        { number: 2086, value: this.note.websiteUrl },
        { number: 2087, value: this.note.fanpage },
        { number: 2088, value: JSON.stringify(this.note.fanpageType) },
        { number: 2089, value: this.note.fanpageDescription },
        { number: 2090, value: JSON.stringify(this.note.fanpageUrl) },
        { number: 2091, value: this.note.ads },
        { number: 2092, value: this.note.adsType },
        { number: 2093, value: this.note.adsDescription },
        { number: 2094, value: this.note.comments },
        { number: 2095, value: this.note.noteNull },
        { number: 2096, value: this.note.bankType },
      ];
    },

    async saveNotes(type) {
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          "Are you sure you want to save this note?"
        );
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          sale_id: this.noteInfo.saleId,
          note: this.answersNote(),
          idLead: this.noteInfo.idLead,
          user_id: this.currentUser.user_id,
          corporation_image: this.corporationImage,
          corporation_image_name: this.corporationImageName,
          logo_image: this.logoImage,
          logo_image_name: this.logoImageName,
          update_new_version: this.updateNewVersion,
        };
        const service =
          type === "insert" ? "insertFirstNote" : "updateFirstNote";
        await NotesServices[service](params);
        await this.insertTrackingDerivation();
        this.showToast(
          "success",
          "top-right",
          "Note saved successfully",
          "CheckIcon"
        );
        this.$emit("refreshNotes");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async insertTrackingDerivation() {
      try {
        if (this.$route.matched[0].meta.module !== 26) return;
        this.addPreloader();
        const params = {
          lead_id: this.noteInfo.idLead,
          sale_status_id: 4,
          seller_id: this.noteInfo.seller_id,
          sale_id: this.noteInfo.saleId,
        };
        await DerivationsService.insertTrackingSaleStatusDerivation(params);
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    initialValidationNote(note) {
      if (this.notesCompleted) {
        this.showSave = false;
        this.showUpdate = false;
        return;
      }
      if (
        note.length != 0 &&
        this.noteInfo.statusSale != 4 &&
        this.note.noteNull == "true"
      ) {
        this.showUpdate = true;
        return;
      }
      if (this.noteInfo.editmodal == false) {
        this.showSave = false;
        this.showUpdate = false;
        return;
      }
      if (
        note.length == 0 ||
        this.note.noteNull == "null" ||
        this.note.noteNull == null
      ) {
        this.showSave = true;
        return;
      }
    },

    validateEmptyNoteNewVersion(note) {
      note.forEach((answer) => {
        if (answer.question_id >= 2060 && answer.question_id <= 2096) {
          this.emptyNoteNewVersion = false;
          return;
        }
        this.emptyNoteNewVersion = true;
      });
    },

    getDetailsAnswers(note) {
      const questionIdMap = {
        2060: "taxId",
        2061: "contactTime",
        2062: "new_business",
        2063: "register",
        2064: "notes",
        2065: "corporation",
        2066: "corporationType",
        2067: "corporationImge",
        2068: "bankAccount",
        2069: "bankName",
        2070: "bookeeping",
        2071: "bookeepingType",
        2072: "bookeepingDescription",
        2073: "taxes",
        2074: "taxesDescription",
        2075: "merchant",
        2076: "merchantType",
        2077: "merchantDescription",
        2078: "businessCredit",
        2079: "businessCreditDescription",
        2080: "logo",
        2081: "logoImage",
        2082: "logoDescription",
        2083: "website",
        2084: "websiteType",
        2085: "websiteDescription",
        2086: "websiteUrl",
        2087: "fanpage",
        2089: "fanpageDescription",
        2091: "ads",
        2092: "adsType",
        2093: "adsDescription",
        2094: "comments",
        2095: "noteNull",
        2096: "bankType",
      };
      const questionIdMapImage = {
        2067: "corporationImgeUrl",
        2081: "logoImageUrl",
      };
      const questionIdMapArray = {
        2088: "fanpageType",
        2090: "fanpageUrl",
      };
      note.forEach((answer) => {
        if (answer.answer != "null") {
          const key = questionIdMap[answer.question_id];
          if (key) {
            this.note[key] = answer.answer;
          }
          const keyImage = questionIdMapImage[answer.question_id];
          if (keyImage) {
            this.note[keyImage] = answer.url;
          }
          const keyArray = questionIdMapArray[answer.question_id];
          if (keyArray) {
            this.note[keyArray] = JSON.parse(answer.answer.replace(/\\/g, '"'));
          }
        }
      });
    },

    resetFields(type) {
      const types = {
        NEW_BUSINESS: 1,
        REGISTER: 2,
        CORPORATION: 3,
        BANK_ACCOUNT: 4,
        BOOKEEPING: 5,
        MERCHANT: 6,
        LOGO: 7,
        WEBSITE: 8,
        FANPAGE: 9,
        ADS: 10,
      };
      if (type === types.NEW_BUSINESS || type === types.REGISTER) {
        if (type === types.NEW_BUSINESS) {
          this.note.register = null;
        }
        this.note.notes = null;
        this.note.corporation = null;
        this.note.corporationImge = null;
        this.note.corporationType = null;
        this.note.bankAccount = null;
        this.note.bankType = null;
        this.note.bankName = null;
        this.note.bookeeping = null;
        this.note.bookeepingType = null;
        this.note.bookeepingDescription = null;
        this.note.taxes = null;
        this.note.taxesDescription = null;
        this.note.merchant = null;
        this.note.merchantType = null;
        this.note.merchantDescription = null;
        this.note.businessCredit = null;
        this.note.businessCreditDescription = null;
        this.note.logo = null;
        this.note.logoImage = null;
        this.note.logoDescription = null;
        this.note.website = null;
        this.note.websiteType = null;
        this.note.websiteDescription = null;
        this.note.websiteUrl = null;
        this.note.fanpage = null;
        this.note.fanpageType = [];
        this.note.fanpageDescription = null;
        this.note.fanpageUrl = [];
        this.note.ads = null;
        this.note.adsType = null;
        this.note.adsDescription = null;
        this.corporationImage = null;
        this.corporationImageName = null;
        this.logoImage = null;
        this.logoImageName = null;
      }
      if (type === types.CORPORATION) {
        this.note.corporationImge = null;
        this.note.corporationType = null;
        this.corporationImage = null;
        this.corporationImageName = null;
      }
      if (type === types.BANK_ACCOUNT) {
        this.note.bankType = null;
        this.note.bankName = null;
      }
      if (type === types.BOOKEEPING) {
        this.note.bookeepingType = null;
        this.note.bookeepingDescription = null;
      }
      if (type === types.MERCHANT) {
        this.note.merchantType = null;
        this.note.merchantDescription = null;
      }
      if (type === types.LOGO) {
        this.note.logoImage = null;
        this.note.logoDescription = null;
        this.logoImage = null;
        this.logoImageName = null;
      }
      if (type === types.WEBSITE) {
        this.note.websiteType = null;
        this.note.websiteUrl = null;
      }
      if (type === types.FANPAGE) {
        this.note.fanpageType = [];
        this.note.fanpageUrl = [];
      }
      if (type === types.ADS) {
        this.note.adsType = null;
      }
    },

    openPreviewImage(image) {
      window.open(image, "_blank");
    },

    loadFile(event, type) {
      const TYPES = {
        CORPORATION: 1,
        LOGO: 2,
      };
      const file = event.target.files[0];
      if (type === TYPES.CORPORATION) {
        this.corporationImage = null;
        this.corporationImageName = null;
      }
      if (type === TYPES.LOGO) {
        this.logoImage = null;
        this.logoImageName = null;
      }

      if (!file) return;

      const fileName = file.name;
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target.result;
        if (type === TYPES.CORPORATION) {
          this.corporationImage = base64Image;
          this.corporationImageName = fileName;
        }
        if (type === TYPES.LOGO) {
          this.logoImage = base64Image;
          this.logoImageName = fileName;
        }
      };
      reader.readAsDataURL(file);
    },

    buildFanpageUrl() {
      const newLength = this.note.fanpageType.length;
      const existingLength = this.note.fanpageUrl.length;
      if (newLength > existingLength) {
        const additionalLength = newLength - existingLength;
        const additionalItems = new Array(additionalLength).fill("");
        this.note.fanpageUrl.push(...additionalItems);
      }

      if (newLength < existingLength) {
        const removeLength = existingLength - newLength;
        this.note.fanpageUrl.splice(newLength, removeLength);
      }
    },
  },
};
</script>
<style scoped>
.input-group-text {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 0.357rem 0 0 0.357rem !important;
}
.dark-layout .input-group-text {
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
}
.quill-editor {
  height: 100px;
}
.disabled-row {
  background-color: #f8f8f8;
}
.dark-layout .disabled-row {
  background-color: #17171a;
}
</style>
