var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rc-chat-info"},[_c('div',{staticClass:"new-container pt-1"},[_c('div',{staticClass:"d-flex flex-column align-items-center my-1"},[(_vm.currentChat.leadName && _vm.currentChat.leadId)?_c('div',{staticClass:"title rc-color-dark d-flex justify-content-center align-items-center"},[_c('router-link',{staticClass:"d-block text-primary",class:_vm.textLink,attrs:{"to":_vm.routerDashboardLeadRedirectioner(_vm.moduleId, _vm.currentChat.leadId),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm._f("myFontCapitalize")(_vm.currentChat.leadName))+" ")])],1):_vm._e(),_c('div',{staticClass:"title rc-color-dark text-center"},[_vm._v(" "+_vm._s(_vm.currentChat.leadPhoneNumber)+" ")])]),(_vm.clientsAccounts.length > 0)?_c('div',{staticClass:"divider-v2 mt-2 mb-1"}):_vm._e(),(_vm.clientsAccounts.length > 0)?_c('div',{staticClass:"mx-2"},[_c('div',{staticClass:"rc-color-dark subtitle"},[_vm._v("Accounts:")]),_c('b-row',_vm._l((_vm.clientsAccounts),function(account){return _c('b-col',{key:account.id,staticStyle:{"margin-bottom":"4px"},attrs:{"xl":"6","cols":"12"}},[_c('router-link',{staticClass:"font-weight-bolder mr-1",attrs:{"target":"_blank","to":_vm.routerDashboardRedirectioner(account.program_id, account.id)}},[_vm._v(" "+_vm._s(account.account)+" ")]),_c('status-account',{attrs:{"account":account,"text":""}}),_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(account.created_at)))])])],1)}),1)],1):_vm._e(),(_vm.leadInformation && _vm.leadInformation.description)?_c('div',{staticClass:"divider-v2 mt-2"}):_vm._e(),(_vm.leadInformation && _vm.leadInformation.description)?_c('div',{staticClass:"px-2 pb-2 pt-1"},[_c('div',{staticClass:"rc-color-dark subtitle"},[_vm._v("Description:")]),(_vm.leadInformation.description)?_c('show-more-text',{attrs:{"text":_vm.leadInformation.description,"letters":100,"show-more-styles":{
          color: '#0099DD',
        }}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"divider-v2"}),_c('div',{staticClass:"py-2 d-flex justify-content-between cursor-pointer"},[_c('div',[_c('feather-icon',{staticClass:"ml-1 mb-1",attrs:{"icon":"StarIcon","size":"16"}}),_c('span',{staticClass:"ml-1 subtitle rc-color-dark"},[_vm._v("Marked messages")])],1),_c('div',[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"ChevronRightIcon","size":"20"},on:{"click":function($event){return _vm.$emit('openMessageHighlight')}}})],1)]),_c('div',{staticClass:"divider-v2"}),_c('div',{staticClass:"pb-1 d-flex justify-content-between cursor-pointer recent-files"},[_c('div',[_c('span',{staticClass:"ml-1 rc-color-dark subtitle"},[_vm._v(" Recent Files")]),_c('span',{staticClass:"ml-1"},[_vm._v(" ("+_vm._s(_vm.G_CURRENT_CHAT_FILES.filesCounter)+" files) ")])]),(_vm.G_CURRENT_CHAT_FILES.filesCounter > 0)?_c('div',[_c('feather-icon',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-123456",modifiers:{"collapse-123456":true}}],staticClass:"text-primary",attrs:{"icon":_vm.statusDropdown ? 'ChevronUpIcon' : 'ChevronDownIcon',"size":"20"},on:{"click":function($event){_vm.statusDropdown = !_vm.statusDropdown}}})],1):_vm._e()]),_c('b-collapse',{attrs:{"id":"collapse-123456"}},[_c('div',{staticClass:"ml-1 pb-1"},_vm._l((_vm.G_CURRENT_CHAT_FILES.files),function(file,index){return (index < 4)?_c('div',{key:file.id,staticStyle:{"margin-bottom":"3px"}},[_c('div',{staticClass:"d-flex justify-content-between align-items-start"},[_c('div',[(file.contentType.startsWith('image/'))?_c('feather-icon',{attrs:{"icon":"ImageIcon"}}):_vm._e(),(file.contentType.startsWith('video/'))?_c('feather-icon',{attrs:{"icon":"YoutubeIcon"}}):_vm._e(),(file.contentType.startsWith('audio/'))?_c('feather-icon',{attrs:{"icon":"MicIcon"}}):_vm._e(),(
                  file.contentType.startsWith('application/') ||
                  file.contentType.startsWith('text/')
                )?_c('feather-icon',{attrs:{"icon":"FileIcon"}}):_vm._e(),_c('span',{staticClass:"text-truncate",staticStyle:{"margin-left":"4px"}},[_vm._v(_vm._s(file.id + "." + file.contentType.split("/")[1]))])],1),_c('b-dropdown',{attrs:{"size":"md","variant":"muted","toggle-class":"text-decoration-none","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"cursor-pointer text-light",attrs:{"icon":"MoreHorizontalIcon"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.downloadFileRc(file)}}},[_vm._v("Download")])],1)],1)]):_vm._e()}),0)]),_c('div',{staticClass:"divider-v2"}),_c('div',{staticClass:"pb-2"},[_c('div',[_c('span',{staticClass:"ml-1 rc-color-dark subtitle"},[_vm._v(" Images ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" ("+_vm._s(_vm.G_CURRENT_CHAT_FILES.imagesCounter)+" files) ")])]),_c('div',{staticClass:"rc-display-images px-2"},[_vm._l((_vm.G_CURRENT_CHAT_FILES.images),function(item,index){return (index < 2)?_c('div',{key:index,staticClass:"container-image"},[_c('img',{attrs:{"src":item.route,"loading":"lazy"},on:{"click":function($event){_vm.currentImage = item.route}}})]):_vm._e()}),(
            _vm.G_CURRENT_CHAT_FILES.images &&
            _vm.G_CURRENT_CHAT_FILES.images.length == 3
          )?_c('div',{staticClass:"container-image"},[_c('img',{attrs:{"src":_vm.G_CURRENT_CHAT_FILES.images[2].route,"loading":"lazy"},on:{"click":function($event){_vm.currentImage = _vm.G_CURRENT_CHAT_FILES.images[2].route}}})]):_vm._e(),(
            _vm.G_CURRENT_CHAT_FILES.images &&
            _vm.G_CURRENT_CHAT_FILES.images.length > 3
          )?_c('div',{staticClass:"container-image"},[_c('img',{attrs:{"src":_vm.G_CURRENT_CHAT_FILES.images[2].route,"loading":"lazy"}}),_c('div',{staticClass:"view-more-images",on:{"click":function($event){_vm.currentImage = _vm.G_CURRENT_CHAT_FILES.images[2].route}}},[_vm._v(" +"+_vm._s(_vm.G_CURRENT_CHAT_FILES.images.length - 3)+" ")])]):_vm._e()],2)]),_c('div',{staticClass:"divider-v2"}),_c('div',{staticClass:"button-block-contact"},[_c('b-button',{attrs:{"variant":"outline-danger","block":""},on:{"click":function($event){return _vm.blockContact()}}},[_c('feather-icon',{attrs:{"icon":"SlashIcon"}}),_vm._v(" Block ")],1)],1),(_vm.currentImage != '')?_c('image-viewer',{staticStyle:{"z-index":"1001 !important"},attrs:{"objectImages":_vm.G_CURRENT_CHAT_FILES.images,"route":_vm.currentImage},on:{"hidden":function($event){_vm.currentImage = ''}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }