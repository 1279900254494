export default [
  {
    path: "sales-made",
    name: "administration-sales-made",
    redirect: { name: "administration-sales-made-new-client" },
    component: () =>
      import(
        /* webpackChunkName: "AdministrationSalesMade" */ "@/views/administration/views/sales-made/SalesMade.vue"
      ),
    meta: {
      pageTitle: "Sales Made",
      breadcrumb: [
        {
          text: "Sales Made",
        },
      ],
      permittedRoles: [1, 2, 6],
    },
    children: [
      {
        path: "",
        name: "administration-sales-made-new-client",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationSalesMadeNewClient" */ "@/views/crm/views/sales-made/components/NewClientComponent.vue"
          ),
        meta: {
          pageTitle: "Sales Made",
          breadcrumb: [
            {
              text: "New Client",
              to: { name: "administration-sales-made-new-client" },
              active: true,
            },
          ],
          permittedRoles: [1, 2, 6],
        },
      },
      {
        path: "programs",
        name: "administration-sales-made-add-change",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationSalesMadeAddChange" */ "@/views/crm/views/sales-made/components/AddChangeComponent.vue"
          ),
        meta: {
          pageTitle: "Sales Made",
          program: 0,
          breadcrumb: [
            {
              text: "Add/Change Service",
              to: { name: "administration-sales-made-new-client" },
              active: true,
            },
          ],
          permittedRoles: [1, 2, 6],
        },
      },
      {
        path: "annul",
        name: "administration-sales-made-annuled",
        component: () =>
          import(
            /* webpackChunkName: "AdministrationSalesMadeAnnuled" */ "@/views/crm/views/sales-made/components/AnnuledComponent.vue"
          ),
        meta: {
          pageTitle: "Sales Made",
          program: 0,
          breadcrumb: [
            {
              text: "Annuled",
              to: { name: "administration-sales-made-new-client" },
              active: true,
            },
          ],
          permittedRoles: [1, 2, 6],
        },
      },
    ],
  },
  {
    path: "sales-made/report/:id",
    name: "administration-sales-made-report",
    component: () =>
      import(
        /* webpackChunkName: "AdministrationSalesMadeReport" */ "@/views/crm/views/Lead/lead-module/dashboard/default.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      typeTable: 2,
      permittedRoles: [1, 2, 3, 8, 20],
    },
  },
]
