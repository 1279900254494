<template>
  <div>
    <span v-if="G_PROGRAMS_IN_NEW_PAYMENT.includes(parseInt(programId))">
      <span v-if="text">
        <feather-icon
          :icon="account.icon_status"
          size="12"
          :style="parentFillColors(account.parent_name)"
          :class="parentColor(account.parent_name)"
        />

        <span :class="parentColor(account.parent_name)">
          {{ account.parent_name }}
        </span>

        <span :style="'color: ' + account.color_status">
          {{ "[ " + account.name_status + " ]" }}
        </span>
      </span>
      <span v-else>
        <feather-icon
          :icon="account.icon_status"
          size="12"
          style="margin-right: 3px"
          :style="parentFillColors(account.parent_name)"
          :class="parentColor(account.parent_name)"
          v-b-tooltip.hover.top="tooltipStatus"
        />
      </span>
    </span>
    <span v-else>
      <IconStatusAccount v-if="statusId" :status="statusId" :fromBc="!text" />
    </span>
  </div>
</template>

<script>
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    account: {
      type: Object,
    },
    text: {
      type: Boolean,
      default: true,
    },
    optionalId: {
      type: Number,
      default: null,
    },
  },
  components: {
    IconStatusAccount,
  },
  computed: {
    statusId() {
      if (this.account.client_status) {
        return this.account.client_status;
      } else if (this.account.status_account) {
        return this.account.status_account;
      } else if (this.account.cliente_account_status) {
        return this.account.cliente_account_status;
      } else if (this.account.ca_status) {
        return this.account.ca_status;
      } else if (this.account.account_status) {
        return this.account.account_status;
      } else {
        return this.account.status;
      }
    },
    programId() {
      if (this.account.program_id) {
        return this.account.program_id;
      } else if (this.account.request_from) {
        return this.account.request_from;
      } else if (this.account.client_program_id) {
        return this.account.client_program_id;
      } else {
        return this.account.program_id || this.optionalId;
      }
    },
    styleIcon() {
      return (
        "color: " +
        this.account.color_status +
        "; background-color: " +
        this.account.color_status +
        "; border-radius: 50%;"
      );
    },
    tooltipStatus() {
      return this.account.parent_name + " [ " + this.account.name_status + " ]";
    },

    ...mapGetters({
      G_PROGRAMS_IN_NEW_PAYMENT: "TransactionStatusStore/G_PROGRAMS",
    }),
  },
  methods: {
    parentColor(name) {
      const colors = {
        Loyal: "text-primary",
        Active: "text-success",
        Outstanding: "text-danger",
      };
      return colors[name];
    },

    parentFillColors(name) {
      const colors = {
        Loyal: "fill: #007bff",
        Active: "fill: #28a745",
        Outstanding: "fill: #dc3545",
      };
      return colors[name];
    },
  },
  created() {
    //
  },
};
</script>
