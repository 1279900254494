export default [
  {
    path: "payments",
    name: "debt-solution-payment",
    redirect: { name: "debt-solution-payment-list-clients" },
    component: () =>
      import(
        /* webpackChunkName: "DSPayments" */ "@/views/commons/components/payments/views/components/PaymentsTab.vue"
      ),
    meta: {
      pageTitle: "Payments",
      breadcrumb: [
        {
          text: "Payments",
          active: true,
        },
      ],
      route: "debt-solution",
    },
    children: [
      {
        path: "clients",
        component: () =>
          import(
            /* webpackChunkName: "DSPaymentsClients" */ "@/views/commons/components/payments/views/components/PaymentsClientsGrid.vue"
          ),
        name: "debt-solution-payment-list-clients",
        meta: {
          pageTitle: "Payments",
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
          route: "debt-solution",
        },
      },
      {
        path: "leads",
        component: () =>
          import(
            /* webpackChunkName: "DSPaymentsLeads" */ "@/views/commons/components/payments/views/components/PaymentsLeadsGrid.vue"
          ),
        name: "debt-solution-payment-list-leads",
        meta: {
          pageTitle: "Payments",
          breadcrumb: [
            {
              text: "Leads",
              active: true,
            },
          ],
          route: "debt-solution",
        },
      },
    ],
  },
]
