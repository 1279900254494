<template>
  <div>
    <b-modal
      ref="modalDetailsHelpdesk"
      modal-class="modal-top-0 p-0"
      size="xl"
      @hidden="closeModal()"
      body-class="body-class-detail-kanbam"
      content-class="content-class-detail-kanbam overflow-hidden"
      title="Ticket Details"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <div class="p-0 h-100">
        <b-row class="h-100 m-0">
          <b-col cols="8" class="border-right">
            <div class="p-2">
              <div class="title-header">
                {{ details.title_header }}
              </div>
              <div class="p-1">
                <div
                  class="title-ticket mb-2 font-weight-bolder text-truncate"
                  :title="details.title"
                >
                  {{ details.title }}
                </div>
                <div class="mb-1 d-flex align-items-center" style="gap: 5px">
                  <button
                    class="btn"
                    :class="{
                      'bg-orange': !toogleOption,
                      'bg-rgba': isDarkSkin && toogleOption,
                      'bg-rgba-light': !isDarkSkin && toogleOption,
                    }"
                    @click="toogleOption = false"
                  >
                    DESCRIPTION
                  </button>
                  <button
                    class="btn"
                    :class="{
                      'bg-orange': toogleOption,
                      'bg-rgba': isDarkSkin && !toogleOption,
                      'bg-rgba-light': !isDarkSkin && !toogleOption,
                    }"
                    @click="toogleOption = true"
                  >
                    ATTACHMENTS
                  </button>
                </div>
                <div v-if="toogleOption" class="scroll-kanban">
                  <p style="font-size: 14px">{{ details.role_id }}</p>
                  <ListFiles :files="files" />
                </div>
                <template v-else>
                  <Conversation :ticket_id="ticket_id" @refresh="searchIsuue" />
                </template>
              </div>
            </div>
          </b-col>

          <b-col cols="4" class="p-0">
            <div class="float-right p-1">
              <div
                class="clickable"
                @click="closeModal()"
                :class="isDarkSkin ? 'close-btn' : 'close-btn-light'"
              >
                <feather-icon icon="XIcon" size="20" />
              </div>
            </div>
            <div class="py-1 px-3 mx-3 mt-5 show-module rounded border">
              <b-icon icon="laptop" class="bg-orange-i"></b-icon>
              <span class="module-name ml-1">{{ details.title_header }}</span>
            </div>
            <div class="px-1 mx-3 my-2">
              <span class="attr-text">DETAILS TICKET</span>
            </div>
            <hr style="margin-top: 0.2rem !important" />
            <div class="scroll-kanban max-height-content over">
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4">TICKET ID</span>
                <div class="col-md-8">
                  <strong>#{{ details.ticket_id }}</strong>
                </div>
              </b-row>

              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4">REQUEST BY</span>
                <div class="col-md-8">
                  <div class="d-flex justify-content-between">
                    <span>{{ details.request_by }} </span>
                    <span class="text-muted">
                      [{{ formatDateTime(details.created_at) }}]
                    </span>
                  </div>
                </div>
              </b-row>

              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
                v-if="showErrorType"
              >
                <span class="attr-text col-md-4">ERROR TYPE</span>
                <div class="col-md-8" v-if="currentUser.is_reviewer == 0">
                  <b-dropdown
                    no-caret
                    variant="none"
                    class="wo-padding"
                    :disabled="
                      (!isReviewer &&
                        !isShowEngSoft &&
                        !currentUser.user_id != 124) ||
                      (details.status_id == 5 && Boolean(details.on_review))
                    "
                  >
                    <template #button-content>
                      <div
                        class="attr-content"
                        :style="
                          isDarkSkin
                            ? `
                              background-color: rgba(${hexToRgb(
                                details.errorColor || '#FAFAFA'
                              )}, 0.2);
                              color: ${details.errorColor || '#FAFAFA'};
                            `
                            : `
                              background-color: none;
                              color: ${
                                details.errorColor
                                  ? details.errorName == 'Rule Conflict'
                                    ? '#ff9f43'
                                    : details.errorColor
                                  : '#878484'
                              };
                              border: 1px solid ${
                                details.errorColor
                                  ? details.errorName == 'Rule Conflict'
                                    ? '#ff9f43'
                                    : details.errorColor
                                  : '#ACACAC'
                              }
                            `
                        "
                      >
                        <b-icon icon="circle-fill"> </b-icon>
                        {{ details.errorName }}
                      </div>
                    </template>
                    <b-dropdown-item
                      :disabled="
                        (!isReviewer && !isShowEngSoft) ||
                        item.id == details.error_id
                      "
                      v-for="(item, index) in errors"
                      :key="index"
                      @click="changeErrorType(item.id)"
                    >
                      {{ item.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <div class="col-md-8" v-else>
                  <b-dropdown
                    no-caret
                    variant="none"
                    class="wo-padding"
                    :disabled="
                      (currentUser.user_id != 124 &&
                        !currentUser.is_reviewer) ||
                      (details.status_id == 5 && Boolean(details.on_review))
                    "
                  >
                    <template #button-content>
                      <div
                        class="attr-content"
                        :style="
                          isDarkSkin
                            ? `
                              background-color: rgba(${hexToRgb(
                                details.errorRevColor || '#FAFAFA'
                              )}, 0.2);
                              color: ${details.errorRevColor || '#FAFAFA'};
                            `
                            : `
                              background-color: none;
                              color: ${
                                details.errorRevColor
                                  ? details.errorRevName == 'Rule Conflict'
                                    ? '#ff9f43'
                                    : details.errorRevColor
                                  : '#878484'
                              };
                              border: 1px solid ${
                                details.errorRevColor
                                  ? details.errorRevName == 'Rule Conflict'
                                    ? '#ff9f43'
                                    : details.errorRevColor
                                  : '#ACACAC'
                              }
                            `
                        "
                      >
                        <b-icon icon="circle-fill"> </b-icon>
                        {{ details.errorRevName }}
                      </div>
                    </template>
                    <b-dropdown-item
                      :disabled="
                        (!roles.isEngSoft && !roles.isReviewer) ||
                        item.id == details.error_id_reviewer
                      "
                      v-for="(item, index) in errors"
                      :key="index"
                      @click="changeErrorType(item.id)"
                    >
                      {{ item.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-row>

              <!-- TODO: refactor disabled -->
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
                v-if="details.errorName == 'Bug' && this.isShowEngSoft"
              >
                <span class="attr-text col-md-4">TEAM RESPONSIBLE </span>
                <div class="col-md-8">
                  <b-form-select
                    v-model="details.teamId"
                    :options="teams"
                    value-field="id"
                    text-field="name"
                    :disabled="
                      (details.owner_id == 360 &&
                        currentUser.user_id != 124 &&
                        !currentUser.is_reviewer &&
                        !(isChief && currentUser.modul_id == 12)) ||
                      (details.status_id == 5 && Boolean(details.on_review))
                    "
                    @change="details.error_developer_responsible = null"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >Please select a Team</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </div>
              </b-row>

              <!-- TODO: refactor disabled -->
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
                v-if="
                  details.errorName == 'Bug' &&
                  (currentUser.eng_soft ||
                    (isChief && currentUser.modul_id == 12))
                "
              >
                <span class="attr-text col-md-4">DEVELOPER RESPONSIBLE</span>
                <div class="col-md-8">
                  <b-form-select
                    v-model="details.error_developer_responsible"
                    :options="developersList"
                    value-field="id"
                    text-field="name"
                    :disabled="
                      (details.owner_id == 360 &&
                        currentUser.user_id != 124 &&
                        !currentUser.is_reviewer &&
                        !(isChief && currentUser.modul_id == 12)) ||
                      (details.status_id == 5 && Boolean(details.on_review))
                    "
                    @change="changeTeam($event)"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >Please select a Developer
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-row>

              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
              >
                <span class="attr-text col-md-4" v-if="hasActions">
                  ACTIONS
                </span>
                <span class="attr-text col-md-4" v-else>STATUS</span>
                <div class="col-md-8">
                  <div v-if="hasActions" class="d-flex gap-5">
                    <b-button
                      variant="outline-success"
                      size="sm"
                      @click="changeStatusTicket(2)"
                      :disabled="!details.error_id_reviewer && !!isReviewer"
                      :style="
                        !details.error_id_reviewer && isReviewer
                          ? 'cursor: not-allowed;'
                          : ''
                      "
                    >
                      {{ this.isShowEngSoft ? "ACCEPT" : "SEND TO PARAGON" }}
                      <feather-icon
                        :icon="`${
                          this.isShowEngSoft ? 'CheckCircleIcon' : 'SendIcon'
                        }`"
                        size="15"
                        class="clickable text-success"
                      />
                    </b-button>

                    <b-button
                      variant="outline-danger"
                      size="sm"
                      @click="showRejected = true"
                    >
                      REJECT
                      <feather-icon
                        icon="ArrowLeftCircleIcon"
                        size="15"
                        class="clickable text-danger"
                      />
                    </b-button>
                  </div>

                  <div v-else-if="showStatus">
                    <div
                      class="attr-content"
                      :style="
                        styleColor(
                          details.status_id == 2 && !details.accept_paragon
                            ? '#ff9f43'
                            : details.statusColor,
                          details.status_id == 1
                        )
                      "
                    >
                      <b-icon icon="circle-fill"> </b-icon>
                      <span
                        v-if="details.status_id == 2 && !details.accept_paragon"
                      >
                        In Paragon
                      </span>
                      <span v-else>
                        {{ details.statusName }}
                      </span>
                    </div>
                  </div>

                  <b-dropdown
                    v-else
                    no-caret
                    variant="none"
                    class="wo-padding"
                    :disabled="
                      details.status_id == 5 ||
                      (roles.isDevLeader && details.redirect_ticket == 1)
                    "
                  >
                    <template #button-content>
                      <div
                        class="attr-content"
                        :style="
                          styleColor(
                            details.statusColor,
                            details.status_id == 1
                          )
                        "
                      >
                        <b-icon icon="circle-fill"> </b-icon>
                        {{ details.statusName }}
                      </div>
                    </template>
                    <b-dropdown-item
                      v-for="(item, index) in statuses"
                      :disabled="
                        [4, 5].includes(details.status_id) ||
                        (details.error_id == null &&
                          details.category_ticket !== 'support') ||
                        item.id == details.status_id
                      "
                      :key="index"
                      @click="changeStatusTicket(item.id)"
                    >
                      {{ item.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-row>

              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
                v-if="
                  details.status_id != 4 && details.due_date && isShowEngSoft
                "
              >
                <span class="attr-text col-md-4">SPECIAL TICKET </span>
                <div
                  class="col-md-8 d-flex align-items-center"
                  style="gap: 5px"
                >
                  <kendo-datetimepicker
                    :format="'MM/dd/yyyy HH:mm'"
                    v-model="details.due_date"
                    :disabled="details.special == 1 || !roles.isEngSoft"
                    class="transparent-datetime-kanban"
                    style="width: 90%"
                    name="date"
                    id="date"
                  >
                  </kendo-datetimepicker>
                  <b-button
                    variant="success"
                    size="md"
                    class="text-black font-weight-bolder"
                    :disabled="details.special == 1 || !roles.isEngSoft"
                    @click="changeSpecialTicket(details)"
                  >
                    Special
                  </b-button>
                </div>
              </b-row>
              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
                v-if="isShowEngSoft || isReviewer"
              >
                <span class="attr-text col-md-4"> Owner </span>
                <div class="col-md-8">
                  <b-form-select
                    v-model="details.owner_id"
                    @change="updateOwner"
                    :options="users"
                    :disabled="
                      !!isReviewer ||
                      details.status_id == 4 ||
                      details.status_id == 5 ||
                      Boolean(details.redirect_ticket) ||
                      !details.accept_paragon
                    "
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled>
                        {{
                          roles.isReviewer ? "Unknown" : "Please select a Owner"
                        }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-row>

              <b-row
                class="py-1 px-1 mx-3 my-1 content-att"
                :class="isDarkSkin ? 'bg-rgba' : 'bg-rgba-light'"
                v-if="details.status_id !== 1"
              >
                <span class="attr-text col-md-4">Due Date</span>
                <div class="col-md-8">
                  <div
                    v-if="!details.due_date"
                    class="d-flex align-items-center justify-content-center"
                  >
                    <span class="text-on-review" style="font-size: 20px">{{
                      details.statusName == "Deployed" ||
                      details.statusName == "Completed"
                        ? details.statusName
                        : "Ticket need review"
                    }}</span>
                  </div>
                  <Timer
                    v-else
                    :created_at="details.created_at"
                    :size="20"
                    :special="details.special"
                    :due_date="details.due_date"
                  />
                </div>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <tracking-owner
      v-if="modalTrackingOwner"
      :showModal="modalTrackingOwner"
      :ticket_id="ticket_id"
      @closeModal="closeModalTrackingOwner"
    ></tracking-owner>
    <ModalRejected
      :ticket_id="ticket_id"
      v-if="showRejected"
      @close="showRejected = false"
      @rejected="CloseModalAndRefresh()"
    />
  </div>
</template>
<script>
import moment from "moment";
import store from "@/store";
import Timer from "@/views/commons/components/helpdesk/components/Timer.vue";
import Conversation from "@/views/commons/components/helpdesk/components/partials/Conversation.vue";
import ListFiles from "@/views/commons/components/helpdesk/components/partials/ListFiles.vue";
import TrackingOwner from "@/views/commons/components/helpdesk/components/modals/TrackingOwner.vue";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import navMenuItems from "@/navigation/vertical";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import ModalRejected from "@/views/commons/components/helpdesk/components/modals/ModalRejected.vue";

export default {
  components: {
    ListFiles,
    TrackingOwner,
    Timer,
    Conversation,
    ModalRejected,
  },
  props: {
    ticket_id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedType: 1,
      details: {
        ticket_id: "",
        request_by: "",
        title: "",
        category_ticket: "",
        title_header: "",
        status_id: null,
        statusName: "",
        statusColor: "",
        special: null,
        owner_id: null,
        created_at: "",
        role_id: "",
        due_date: "",
        error_id: null,
        error_team_id: null,
        error_developer_responsible: null,
        errorName: "Uknown",
        errorColor: null,
        errorRevName: "Uknown",
        errorRevColor: null,
        teamId: null,
        teamName: null,
        teamSelect: null,
        redirect_ticket: 0,
        on_review: 0,
        error_id_reviewer: null,
        accept_paragon: 0,
        deleted_at: null,
      },
      quillOptions: {
        modules: { toolbar: "#toolbar" },
      },
      statusPopover: false,
      files: [],
      statuses: [],
      users: [],
      fieldsTracking: [
        {
          key: "created_at",
          label: "Date",
        },
        {
          key: "status",
          label: "Status",
        },
      ],
      itemsTracking: [],
      modalTrackingOwner: false,
      keyOwner: 1,
      modalFiles: false,
      toogleOption: false,
      toogleComment: false,
      priorities: [],
      countDown: null,
      // LUIS MODIFY:
      errors: [],
      teams: [],
      pg_developers: [],
      showRejected: false,
    };
  },
  computed: {
    colorError() {
      return `color: ${
        this.details.errorColor || "#AFAFAF"
      } !important; background: rgba(${this.hexToRgb(
        this.details.errorColor || "#AFAFAF"
      )}, 0.2)!important; font-size: 12px;`;
    },
    roles() {
      return store.getters["HelpdeskStore/G_HELPDESK_ROLES"];
    },
    currentUser() {
      return store.getters["auth/currentUser"];
    },
    colorStatus() {
      return `color: ${
        this.details.statusColor
      } !important; background: rgba(${this.hexToRgb(
        this.details.statusColor
      )}, 0.2)!important; font-size: 12px;`;
    },
    colorSpecial() {
      return `color: ${
        this.details.specialColor
      } !important; background: rgba(${this.hexToRgb(
        this.details.specialColor
      )}, 0.2)!important; font-size: 12px;`;
    },
    developersList() {
      const developers = this.pg_developers
        .filter((dev) => dev.pg_team_id === this.details.teamId)
        .map((dev) => {
          return {
            id: dev.id,
            name: dev.first_name + " " + dev.last_name,
          };
        });
      return developers.length !== 0
        ? developers
        : [{ id: 0, name: "Developer" }];
    },
    isChiefParagon() {
      this.isChief && this.currentUser.modul_id == 12;
    },
    isReviewer() {
      return this.currentUser.is_reviewer;
    },
    isShowEngSoft() {
      return this.currentUser.eng_soft || this.isChiefParagon;
    },
    showErrorType() {
      return (
        this.details.category_ticket !== "support" &&
        (this.isReviewer || this.isCeo || this.isShowEngSoft) &&
        this.details.deleted_at == null
      );
    },
    hasActions() {
      const { status_id, accept_paragon } = this.details;
      return (
        (status_id == 1 && this.isReviewer) ||
        (this.isShowEngSoft && !accept_paragon)
      );
    },
    showStatus() {
      const { status_id } = this.details;
      return (
        (!this.isShowEngSoft && status_id > 1) ||
        (!this.isReviewer && !this.isShowEngSoft && status_id == 1)
      );
    },
  },
  mounted() {
    this.toggleModal("modalDetailsHelpdesk");
  },
  created() {
    this.searchIsuue();
    this.getOwners();
  },
  methods: {
    styleColor(color = "#878484", type) {
      const colour = this.hexToRgb(color);

      if (this.isDarkSkin) {
        return `
              background-color: rgba(${colour}, 0.2);
              color: ${color};
          `;
      } else {
        if (type) {
          color = "#878484";
        }
        return `
                  background-color: transparent;
                  color: ${color};
                  border: 1px solid ${color};
              `;
      }
    },
    async changeSpecialTicket(item) {
      try {
        const confirm = await this.showConfirmSwal(
          "Are you sure to change special?",
          "You won't be able to revert this!"
        );
        if (!confirm.value) {
          return;
        }
        await helpdeskService.updateSpecial({
          ticket_id: this.ticket_id,
          special: 1,
          due_date: moment(item.due_date).format("YYYY-MM-DD HH:mm:ss"),
        });
        this.details.specialName = item.text;
        this.details.specialColor = item.specialColor;
        this.details.special = 1;
        this.$emit("refreshKanban", null);
        this.showSuccessSwal("Success", "Special Updated Successfully");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    closeModal() {
      this.$emit("close");
    },
    formatDateTime(date) {
      return moment(date).format("MM/DD/YYYY HH:mm");
    },
    async searchIsuue() {
      try {
        this.addPreloader();
        const params = {
          ticket_id: this.ticket_id,
          user_id: this.currentUser.user_id,
        };
        const { data } = await helpdeskService.getDetailsTicket(params);
        for (const key in data.data) {
          if (this.details.hasOwnProperty(key)) {
            this.details[key] = data.data[key];
          }
        }
        if (this.roles.isReviewer) {
          this.details.owner_id = null;
          this.statuses = data.statuses.filter((item) => item.id <= 2);
        }
        if (this.currentUser.eng_soft || this.currentUser.user_id == 41) {
          this.statuses = data.statuses.filter(
            (item, idx) => item.id > 1 && idx < data.statuses.length - 1
          );
        }
        this.errors = data.error_types;
        this.teams = [...data.teams, { id: 0, name: "Others" }] || [];
        this.pg_developers = data.pg_developers;
        this.priorities = data.priorities;
        const { color, name } = data.statuses.find(
          (item) => item.id == this.details.status_id
        );
        if (this.details.error_team_id && this.details.error_id == 1) {
          const { id: teamId, name: teamName } = data.teams.find(
            (item) => item.id == this.details.error_team_id
          );
          this.details.teamName = teamName;
          this.details.teamId = teamId;
          this.details.teamSelect = { id: teamId, name: teamName };
        }
        if (this.details.error_id) {
          const { color: colorError, name: nameError } = data.error_types.find(
            (item) => item.id == this.details.error_id
          );
          this.details.errorName = nameError;
          this.details.errorColor = colorError;
        }
        if (this.details.error_id_reviewer) {
          const { color: colorError, name: nameError } = data.error_types.find(
            (item) => item.id == this.details.error_id_reviewer
          );
          this.details.errorRevName = nameError;
          this.details.errorRevColor = colorError;
        }
        this.details.statusName = name;
        this.details.statusColor = color;
        this.details.due_date = this.details.due_date
          ? moment(this.details.due_date).format("MM/DD/YYYY HH:mm")
          : null;
        this.files = data.files;
        this.modal = true;
        this.itemsTracking = data.tracking;
        this.refreshCountNav();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getOwners() {
      this.users = [];
      const data = await helpdeskService.getOwners();
      data.forEach((item, index) => {
        if (index == 0 || index == data.length - 1)
          this.users.push({
            value: item.id,
            text: item.name,
          });
      });
    },
    async refreshCountNav() {
      const params = {
        modul_id: 12,
        user_id: this.currentUser.user_id,
      };
      const response = await helpdeskService.getCounterNotifications(params);
      if (Object.keys(response.data).length > 0) {
        navMenuItems.forEach((item) => {
          if (item?.route === "helpdesk-ti") {
            item.tag = response.data.total;
          }
        });
      }
    },
    async changeErrorType(errorId) {
      try {
        const confirm = await this.showConfirmSwal(
          "Are you sure to change error type?",
          "You won't be able to revert this!"
        );
        if (!confirm.value) {
          return;
        }
        this.addPreloader();
        const params = {
          ticketId: this.ticket_id,
          errorId: errorId,
          userId: this.currentUser.user_id,
          isReviewer: this.roles.isReviewer,
        };
        await helpdeskService.updateErrorTicket(params);
        if (errorId != 1) {
          this.details.teamId = null;
        }
        this.searchIsuue();
        this.refreshKanban();
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async changeTeam(devId) {
      try {
        this.addPreloader();
        const params = {
          ticketId: this.ticket_id,
          teamId: this.details.teamId,
          developerId: devId,
          userId: this.currentUser.user_id,
        };
        await helpdeskService.updateTeamDeveloperTicket(params);
        this.searchIsuue();
        this.refreshKanban();
      } catch (e) {
        alert(e);
      } finally {
        this.removePreloader();
        if (devId) {
          this.$toast({
            component: ToastificationContentVue,
            position: "top-right",
            props: {
              title: `Developer Updated`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `Developer updated successfully`,
            },
          });
        }
      }
    },
    async changeStatusTicket(status) {
      try {
        const confirm = await this.showConfirmSwal(
          status == 2
            ? "Are you sure to send to Paragon?"
            : "Are you sure to change status?",
          "You won't be able to revert this!"
        );
        if (!confirm.value) {
          return;
        }
        this.addPreloader();
        const params = {
          ticketId: this.ticket_id,
          statusId: status,
          userId: this.currentUser.user_id,
          isReviewer: this.currentUser.is_reviewer,
        };
        await helpdeskService.updateStatusTicket(params);
        this.searchIsuue();
        this.refreshKanban();
        if (this.currentUser.is_reviewer || status == 1) {
          this.$emit("close");
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    CloseModalAndRefresh(status) {
      this.searchIsuue();
      this.refreshKanban();
      if (this.currentUser.is_reviewer || status == 1) {
        this.$emit("close");
      }
    },

    refreshKanban() {
      this.$emit("refreshKanban", null);
    },

    async updateOwner() {
      try {
        const confirm = await this.showConfirmSwal(
          "Are you sure to change owner?",
          "You won't be able to revert this!"
        );
        if (!confirm.isConfirmed) {
          this.details.owner_id = null;
          return;
        }
        this.addPreloader();
        const params = {
          ticket_id: this.ticket_id,
          owner_id: this.details.owner_id,
          user_id: this.currentUser.user_id,
        };
        await helpdeskService.updateOwner(params);
        this.showGenericToast({
          title: "Success",
          text: "Owner Updated Successfully",
        });
        if (this.details.owner_id != this.currentUser.user_id) {
          this.$emit("close");
        }
        this.refreshKanban();
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    openModalTracking() {
      this.keyOwner = this.keyOwner + 1;
      this.modalTrackingOwner = true;
    },

    closeModalTrackingOwner() {
      this.modalTrackingOwner = false;
    },
  },
};
</script>
<style scoped>
.close-btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #4d4d4d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-btn-light {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.max-height-content {
  max-height: 60vh !important;
}
.title-header {
  font-size: 1rem;
  font-weight: 400;
  color: #7a8189;
}
.title-ticket {
  font-size: 1.8rem;
  font-weight: 500;
}
.show-module {
  box-shadow: 0 0 1.2px 1px rgb(0 0 0 / 0.1);
}
.bg-orange-i {
  color: #ff6045;
  font-size: 1.5rem;
}
.bg-orange {
  background: #ff6045 !important;
  border: 1px solid #ff6045 !important;
  color: white !important;
}
.module-name {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
}
.attr-text {
  font-size: 1rem;
  font-weight: 500;
}
.attr-content {
  /* background-color: white; */
  box-shadow: 0 0 1.2px 1px rgb(0 0 0 / 0.1);
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  display: inline-block;
}
.content-att {
  place-items: center;
  border-radius: 5px;
}
.bg-rgba {
  background: rgb(0 0 0 / 18%) !important;
}
.bg-rgba-light {
  background: rgb(0 0 0 / 4%) !important;
}
</style>

<style lang="scss">
.body-class-detail-kanbam {
  height: 80vh;
  padding: 0 !important;
  font-family: "Rubik", sans-serif;
}
.content-class-detail-kanbam {
  overflow: hidden;
}
.wo-padding {
  button {
    padding: 0 !important;
  }
}
.overflow-hidden {
  overflow: hidden;
}
.text-on-review {
  color: #00d25b !important;
  font-weight: 700;
}
</style>

<style>
.transparent-datetime-kanban:hover {
  background: transparent !important;
}
</style>
