import store from "@/store";
import Vue from "vue";

let focus = true;
// Evento que se dispara cuando la pestaña recibe el foco
window.addEventListener('focus', function() {
  focus = true;
});

// Evento que se dispara cuando la pestaña pierde el foco
window.addEventListener('blur', function() {
  focus = false;
});

export const AppointmentToast = () => {
  window.socket.bind(
    "appointment-toast",
    async ({usersToSend}) => {

      const { modul_id: current_module_id, user_id } = store.getters["auth/currentUser"];
      let condition = usersToSend.some(
        (x) => x.module_id === current_module_id && x.id === user_id
      )
      const showToastValue =  store.getters['AlertAppointment/G_SHOW_TOAST']

      if (condition) {
        store.commit("AlertAppointment/M_SHOW_TOAST", !showToastValue);
      }

  });
};

export const AppointmentNotice = () => {
  window.socket.bind(
    "appointment-notice",
    async ({ alerts}) => {

      const { modul_id: current_module_id, user_id } = store.getters["auth/currentUser"];
      alerts.forEach((element)=>{
        const {alert,user} = element ;
        const condition = (user.module_id === current_module_id && user.id === user_id)
        // Si es supervisor de financial appointment puede estar en cualqueir modulo
        const condition2 = (user.id === user_id)
        if (condition || condition2) {
          const notifyNewMessageBrowser = () => {
            if (Notification.permission === "granted") {
              const notification = new Notification("Appointment", {
                body: `You have an appointment with ${alert.lead_name} in 10 minutes`,
                icon: "",
                // Opcional: URL de la imagen para el icono
              });
              notification.onclick = () => {
                window.focus();
              };
            } else {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  const notification = new Notification("Appointment", {
                    body: `You have an appointment with ${alert.lead_name} in 10 minutes`,
                    icon: "",
                    // Opcional: URL de la imagen para el icono
                  });
                  notification.onclick = () => {
                    window.focus();
                  };
                }
              });
            }

          };
          if(!focus){
            notifyNewMessageBrowser();
          }
          store.commit("AlertAppointment/M_OpenNotice", true);
          store.commit("AlertAppointment/M_DataAppointmentNotice", alert);

        }


      })
    }
  );
};


export const CloseAllAppointmentAlert = () => {
  window.socket.bind("client-close-all-appointment-notification", async (data) => {
    const { user_id } = store.getters["auth/currentUser"];
    if (data.user_id == user_id) {
      store.commit("AlertAppointment/M_OpenAlert", false);
      // store.commit("AlertAppointment/M_DataAppointment", null);
    }
  });
};

export const CloseAllAppointmentNotice = () => {
  window.socket.bind("client-close-all-appointment-notice", async (data) => {
    const { user_id } = store.getters["auth/currentUser"];
    if (data.user_id == user_id) {
      store.commit("AlertAppointment/M_OpenNotice", false);
      // store.commit("AlertAppointment/M_DataAppointment", null);
    }
  });
};

export const AppointmentAlert = () => {
  window.socket.bind(
    "appointment-alert",
    async ({ alerts}) => {

      const { modul_id: current_module_id, user_id } = store.getters["auth/currentUser"];
      alerts.forEach((element)=>{
        const {alert,user} = element ;

        const condition = (user.module_id === current_module_id && user.id === user_id)
        // Si es supervisor de financial appointment puede estar en cualqueir modulo
        const condition2 = (user.id === user_id)
        if (condition || condition2) {
          const notifyNewMessageBrowser = () => {
            if (Notification.permission === "granted") {
              const notification = new Notification("Appointment", {
                body: `You have an appointment with ${alert.lead_name}`,
                icon: "",
                // Opcional: URL de la imagen para el icono
              });
              notification.onclick = () => {
                window.focus();
              };
            } else {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  const notification = new Notification("Appointment", {
                    body: `You have an appointment with ${alert.lead_name}`,
                    icon: "",
                    // Opcional: URL de la imagen para el icono
                  });
                  notification.onclick = () => {
                    window.focus();
                  };
                }
              });
            }

          };
          if(!focus){
            notifyNewMessageBrowser();
          }
          store.commit("AlertAppointment/M_OpenAlert", true);
          store.commit("AlertAppointment/M_DataAppointment", alert);

        }


      })
    }
    );
};

