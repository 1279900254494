export default [
    {
        key: "content",
        label: "Account Name",
        classification: null,
    },
    {
        key: "a_number",
        label: "Account #",
    },
    {
        key: "type",
        label: "Account Type",
    },
    {
        key: "a_status",
        label: "Account Status",
    },
    {
        key: "a_description",
        label: "Account Description",
    },
    {
        key: "monthly",
        label: "Monthly Payment",
    },
    {
        key: "date",
        label: "Date Opened",
    },
    {
        key: "balance",
        label: "Balance",
    },
    {
        key: "n_months",
        label: "No. of Months (terms)",
    },
    {
        key: "h_credit",
        label: "Highest Credit",
    },
    {
        key: "c_limit",
        label: "Credit Limit",
    },
    {
        key: "p_status",
        label: "Payment Status",
    },
    {
        key: "comments",
        label: "Comments",
    },
    {
        key: "lp_status",
        label: "Last Payment Status",
    },
    {
        key: "detail",
        label: "Show More Details",
    },
    {
        key: "status",
        label: "Status",
    },
]