import { amgApi } from "@/service/axios";

class MassMessageService {

    async leadHistorySms(payload) {
        try {
            const data = await amgApi.post("/messages/lead-history-sms", payload);
            return data;
        } catch (error) {
            console.log("Something went wrong on leadHistorySms:", error);
            throw error;
        }
    }
}

export default new MassMessageService();
