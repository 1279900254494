import pendingPaymentsRouter from '@/views/ce-digital/sub-modules/financial/view/pending-payments/router/pending-payments.router'
import dashboardRouter from '@/views/ce-digital/sub-modules/financial/view/dashboards/ce-dashboard.router'
import commissionsRouter from '@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions-router'
import otherPaymentsRouter from '@/views/ce-digital/sub-modules/financial/view/other-payments/other-payments.router.js'
import zeroPaymentRouter from '@/views/ce-digital/sub-modules/financial/view/zero-payment/zero-payment-ce-digital.router'
import chargesMadeRouter from '@/views/ce-digital/sub-modules/financial/view/charges-made/charges-made.router'
import clients from '@/views/ce-digital/sub-modules/financial/view/clients/clients.router'
import notificationsAppRouter from '@/views/ce-digital/sub-modules/financial/notifications-app/router/index'
import deletedCardsRouter from '@/views/ce-digital/sub-modules/financial/view/deleted-cards/deleted-cards.router'
import appPaymentsMadeRouter from '@/views/ce-digital/sub-modules/financial/view/app-payments-made/app-payments-made.router'
import leadRouter from '@/views/ce-digital/sub-modules/financial/view/leads/leads.router'
import helpDeskRouter from '@/views/ce-digital/sub-modules/financial/view/helpdesk/helpdesk.route'
import generalCommissionsRouter from '@/views/ce-digital/sub-modules/financial/view/general-commissions/general-commissions.router'
import CeFinancialRouter from '@/views/ce-digital/sub-modules/financial/view/attendance/ce-financial.router'
import EmployeeClaimsRouter from "@/views/ce-digital/sub-modules/financial/view/employee-claims/router/employee-claims.router"
import chargeBackRouteFinancial from "@/views/credit-experts/views/charge-back/charge-back.router";
import FinancialMain from '@/views/ce-digital/sub-modules/financial/view/FinancialMain.vue'
import PaymentsRouter from '@/views/ce-digital/sub-modules/financial/view/payments/payments.router'
import RequestRouter from '@/views/ce-digital/sub-modules/financial/view/request/request.router.js'
//import FinancialProjectsRouter from '@/views/ce-digital/sub-modules/financial/view/projects/financial-projects.router';
import CallLogRouter from "@/views/ce-digital/sub-modules/financial/view/ring-central/call-log/call-log.router.js";
import CourtInfoRouter from "@/views/ce-digital/sub-modules/financial/view/cour-info/router/ds-court-info.router";

import DashboardCalls from "@/views/ce-digital/sub-modules/financial/view/ring-central/dashboard-calls/dashboard.router.js";

import MOFRouter from '@/views/ce-digital/sub-modules/financial/view/manual-of-functions/manual-of-functions.router.js';
import RecruitmentProcessRouter from '@/views/ce-digital/sub-modules/financial/view/recruitment-process/financial-recruitment_process.router';
import CustomerTicketRouter from '@/views/ce-digital/sub-modules/financial/view/customer-tickets/financial-customer-tickets.router';
import InconsistentTransactionRouter from '@/views/ce-digital/sub-modules/financial/view/inconsistent-transactions/router/inconsistent-transactions.router.js';
import DashboardClient from '@/views/ce-digital/sub-modules/financial/view/clients/dashboard/index.router';

const routes = [
  {
    path: '/cedigital/financial',
    redirect: { name: 'ce-dashboard' },
    component: FinancialMain,
    meta: {
      module: 23,
      parentModule: 21,
      program: 3,
    },
    children: [
      ...pendingPaymentsRouter,
      ...dashboardRouter,
      ...commissionsRouter,
      ...otherPaymentsRouter,
      ...zeroPaymentRouter,
      ...chargesMadeRouter,
      ...clients,
      ...leadRouter,
      ...generalCommissionsRouter,
      ...notificationsAppRouter,
      ...deletedCardsRouter,
      ...appPaymentsMadeRouter,
      helpDeskRouter,
      ...chargeBackRouteFinancial,
      ...PaymentsRouter,
      ...CeFinancialRouter,
      ...EmployeeClaimsRouter,
      ...RequestRouter,
      //...FinancialProjectsRouter,
      ...CallLogRouter,
      ...CourtInfoRouter,
      ...DashboardCalls,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...CustomerTicketRouter,
      ...InconsistentTransactionRouter,
      DashboardClient,
    ],
  },

];

export default routes;
