import { amgApi } from "@/service/axios";
class NcrProgramsService {
  async getNcrProgramas(params) {
    try {
      const data = await amgApi.post(
        "commons/ncr-programs/get-ncr-progrmas",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getNcrProgramas");
    }
  }
  async getStatusNcr() {
    try {
      const data = await amgApi.post("commons/ncr-programs/get-status-ncr");
      return data.data;
    } catch (error) {
      console.log("Error getStatusNcr");
    }
  }
  async processncrsearch(params){
    try {
      const data = await amgApi.post("commons/process-ncr-search",params);
      return data.data;
    } catch (error) {
      console.log("Error getStatusNcr");
    }
  }
  async searchClientNcr(params) {
    try {
      const data = await amgApi.post(
        "/lead/ncr/search-client-ncr-realtor",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error searchClientNcr");
    }
  }
  async insertNcr(params) {
    try {
      const data = await amgApi.post("commons/insert-ncr", params);
      return data.data;
    } catch (error) {
      console.log("Error insertNcr");
    }
  }
  async getCardNcr(params) {
    try {
      const data = await amgApi.post(
        "commons/ncr-programs/get-card-ncr",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getCardNcr");
    }
  }
  async getTrackingNcr(params) {
    try {
      const data = await amgApi.post(
        "commons/ncr-programs/get-tracking-ncr",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getTrackingNcr");
    }
  }
  async getCounterNcrPrograns(params) {
    try {
      const data = await amgApi.post(
        "commons/ncr-programs/get-counter-ncr-programs",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getCounterNcrPrograns");
    }
  }
  async getCounterNcrRealtor(params) {
    try {
      const data = await amgApi.post("commons/get-count-read-realtor", params);
      return data.data;
    } catch (error) {
      console.log("Error getCounterNcrRealtor");
    }
  }
}
export default new NcrProgramsService();
