export default [
  {
    path: "dispute-results-origin",
    name: "connection-dispute-results-origin",
    component: () =>
      import(
        /* webpackChunkName: "ConnectionDisputeResults" */
        "@/views/ce-digital/sub-modules/connection/views/dispute-results/views/DisputeResultsOrigin.vue"
      ),
    redirect: { name: "dispute-results-in-process-cn-origin" },
    meta: {
      pageTitle: "Commissions",
      parentModule: 21,
      module: 20,
      moduleId: 20,
      permittedRoles: [1, 2, 14, 15, 17],
      breadcrumb: [
        {
          text: "Commissions",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "dispute-results-in-process-cn-origin",
        component: () =>
          import(
            /* webpackChunkName: "group-user" */ "@/views/commons/components/dispute-results/views/components/TableInProcess.vue"
          ),
        meta: {
          parentModule: 21,
          module: 20,
          moduleId: 20,
          permittedRoles: [1, 2, 14, 15, 17],
          status: 0,
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "In Process",
              active: true,
            },
          ],
        },
      },
      {
        path: "",
        name: "dispute-results-completed-cn-origin",
        component: () =>
          import(
            /* webpackChunkName: "group-user" */ "@/views/commons/components/dispute-results/views/components/TableCompleted.vue"
          ),
        meta: {
          parentModule: 21,
          module: 20,
          moduleId: 20,
          permittedRoles: [1, 2, 14, 15, 17],
          status: 1,
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "dispute-results",
    name: "connection-dispute-results",
    component: () =>
      import(
        /* webpackChunkName: "ConnectionDisputeResults" */
        "@/views/ce-digital/sub-modules/connection/views/dispute-results/views/DisputeResults.vue"
      ),
    redirect: { name: "dispute-results-in-process-cn" },
    meta: {
      pageTitle: "Commissions",
      parentModule: 21,
      module: 20,
      moduleId: 20,
      permittedRoles: [1, 2, 14, 15, 17],
      breadcrumb: [
        {
          text: "Commissions",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "dispute-results-in-process-cn",
        component: () =>
          import(
            /* webpackChunkName: "group-user" */ "@/views/commons/components/dispute-results/views/components/tabs/TableInProcess.vue"
          ),
        meta: {
          parentModule: 21,
          module: 20,
          moduleId: 20,
          permittedRoles: [1, 2, 14, 15, 17],
          status: 0,
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "In Process",
              active: true,
            },
          ],
          tab: 'IN PROCESS'
        },
      },
      {
        path: "",
        name: "dispute-results-completed-cn",
        component: () =>
          import(
            /* webpackChunkName: "group-user" */ "@/views/commons/components/dispute-results/views/components/tabs/TableCompleted.vue"
          ),
        meta: {
          parentModule: 21,
          module: 20,
          moduleId: 20,
          permittedRoles: [1, 2, 14, 15, 17],
          status: 1,
          pageTitle: "Update Request",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
          tab: 'COMPLETED'
        },
      },
    ],
  },
];
