<template>
  <div>
    <b-modal
      v-model="openModal"
      modal
      title="CREATE CASE"
      size="xmd"
      modal-class="custom-modal-amg"
      hide-footer
      title-tag="h3"
      header-bg-variant="transparent"
      :no-close-on-backdrop="true"
      header-class="p-0 custom-header"
      @hidden="$emit('onlyClose')"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            Create Case
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="$emit('onlyClose')"
            />
          </div>
        </div>
      </template>
      <div class="mb-2">
        <template v-if="clientData">
          <b-row>
            <b-col cols="12" md="6" xl="4">
              <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
                <p
                  class="m-0"
                  style="gap: 5px"
                >
                  {{ clientData.account == "-" ? "LEAD" : "CLIENT" }} NAME | {{ clientData.lead_name }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6" xl="4">
              <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
                <p
                  class="m-0"
                  style="gap: 5px"
                >
                  Account | {{ clientData.account }}
                </p>
              </div>
            </b-col>
            <b-col cols="12" md="6" xl="4">
              <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
                <p
                  class="m-0"
                  style="gap: 5px"
                >
                  Start date | {{ clientData.start_date | myGlobal }}
                </p>
              </div>
            </b-col>
          </b-row>
        </template>
      </div>

      <form-wizard
        ref="wizardCase"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :start-index="currentStep"
        :finish-button-text="buttonNextText"
        :hide-buttons="false"
        back-button-text="Previous"
        @on-complete="finishAnswer"
        v-if="uploadInfo && !isDebtSolution"
      >
        <tab-content title="Information" :before-change="validateFirstStep">
          <ValidationObserver
            ref="formInformationValidation"
            lazy
            v-bind:vid="'form-information'"
          >
            <b-row>
              <!-- switch -->
              <b-col
                offset-xl="5"
                offset-lg="5"
                offset-md="0"
                offset-sm="0"
                xl="3"
                lg="3"
                md="6"
                sm="6"
                v-if="clientId && modulId == 5"
                class="text-right p-0"
              >
                <b-form-group>
                  <label style="color: #e26969; margin-right: 1rem"
                    >CREDITOR</label
                  >
                  <label class="switch">
                    <b-form-checkbox
                      v-model="checked"
                      value="1"
                      switch
                      type="checkbox"
                    />
                  </label>
                  <label style="color: #56bec4; margin-left: 0.6rem"
                    >DEBT</label
                  >
                </b-form-group>
              </b-col>

              <!-- Always Available -->
              <b-col
                xl="4"
                lg="4"
                md="6"
                sm="6"
                class="text-right p-0"
                :offset-xl="
                  clientId == null && (modulId == 5 || modulId != 5)
                    ? 8
                    : clientId && modulId != 5
                    ? 8
                    : 0
                "
                :offset-lg="
                  clientId == null && modulId == 5
                    ? 8
                    : clientId && modulId != 5
                    ? 8
                    : 0
                "
                :offset-md="
                  clientId == null && modulId == 5
                    ? 8
                    : clientId && modulId != 5
                    ? 6
                    : 0
                "
                :offset-sm="
                  clientId == null && modulId == 5
                    ? 8
                    : clientId && modulId != 5
                    ? 6
                    : 0
                "
              >
                <b-form-group>
                  <label class="font-weight-bolder">
                    {{ clientId == null ? "Lead" : "Client" }}
                    Always Available
                  </label>
                  <label class="switch ml-1">
                    <b-form-checkbox
                      v-model="formCase.switchAvailableAlways"
                      :unchecked-value="0"
                      switch
                      @change="changeSwitchAvailableAlways"
                    />
                  </label>
                </b-form-group>
              </b-col>

              <!-- Types Document -->
              <b-col xl="6" lg="6" cols="12">
                <span class="title-tag">SERVICE TYPE</span>
                <div class="border border-primary rounded">
                  <p
                    class="text-primary d-flex align-items-center justify-content-center my-1s"
                  >
                    {{
                      formCase.typeService == 1
                        ? "ANSWER"
                        : formCase.typeService == 2
                        ? "INVESTIGATION"
                        : "UNDEFINED"
                    }}
                  </p>
                </div>
              </b-col>

              <b-col xl="6" lg="6" cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="documentSelect"
                  rules="required"
                >
                  <!-- businessRules.documentSelected == WG || -->
                  <b-form-group
                    id="document-select"
                    label="Document Types"
                    label-for="service-select"
                    :description="
                      (businessRules.documentSelected == SM ||
                        businessRules.documentSelected == BL) &&
                      businessRules.responseTime
                        ? `Response Time ${businessRules.responseTime} days`
                        : null
                    "
                  >
                    <v-select
                      :options="allTypesDocument"
                      label="name"
                      v-model="formCase.typeDocument"
                      :reduce="(x) => x.id"
                      :searchable="true"
                      placeholder="Select document"
                      :class="errors[0] ? 'border-danger' : null"
                      :clearable="false"
                      :selectable="(option) => !option.disabled"
                      @input="changeDocument"
                    >
                      <template #selected-option="{ name, code }">
                        <span
                          >{{ name }}
                          <span v-if="code != null" class="text-primary"
                            >({{ code }})
                          </span></span
                        >
                      </template>
                      <template #option="{ name, code }">
                        <span
                          >{{ name }}
                          <span v-if="code != null" class="text-primary"
                            >({{ code }})
                          </span></span
                        >
                      </template>
                    </v-select>
                    <label v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </label>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- Court Case Number -->
              <b-col xl="6" lg="6" cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="courtNumberInput"
                  rules="required"
                >
                  <b-form-group
                    id="courtNumber-input"
                    label="Court Case Number"
                    label-for="courtNumber-input"
                  >
                    <b-form-input
                      v-model="formCase.courtCaseNumber"
                      aria-describedby="input-live-help input-live-feedback"
                      placeholder="Enter court case number"
                      :class="
                        errors[0] || errorCaseNumber ? 'border-danger' : null
                      "
                    ></b-form-input>
                    <label
                      v-if="errors[0] || errorCaseNumber"
                      class="text-danger"
                    >
                      {{ errors[0] ? errors[0] : errorCaseNumber }}
                    </label>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <!-- Document Date -->
              <b-col xl="6" lg="6" md="12" sm="12" cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="documentDateInput"
                  rules="required"
                >
                  <b-form-group
                    id="documentDate-input"
                    label="Document Date"
                    label-for="documentDate-input"
                  >
                    <b-form-datepicker
                      v-model="formCase.documentDate"
                      placeholder="Enter document date"
                      :class="errors[0] ? 'border-danger' : null"
                      :disabled="formCase.typeDocument == null"
                      @input="changeDocumentDate"
                    >
                    </b-form-datepicker>
                    <label v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </label>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <!-- Accounts -->
              <b-col cols="6" v-if="clientId && modulId == 5 && checked">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="accountsSelect"
                  rules="required"
                >
                  <b-form-group
                    id="accounts-type-select"
                    label="Accounts"
                    label-for="accounts-type-select"
                  >
                    <v-select
                      :options="allAccountsType"
                      label="name"
                      v-model="formCase.accountTypeId"
                      :reduce="(x) => x.id"
                      :searchable="true"
                      placeholder="Select account"
                      :class="errors[0] ? 'border-danger' : null"
                      :clearable="false"
                    ></v-select>
                    <label v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </label>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <!-- Creditors -->
              <b-col cols="6" v-if="!checked">
                <ValidationProvider v-slot="{ errors }" rules="customRequired">
                  <b-form-group label="Creditors" label-for="creditors">
                    <vue-autosuggest
                      ref="autocomplete"
                      :suggestions="filteredOptions"
                      :get-suggestion-value="getSuggestionValue"
                      :input-props="{
                        id: 'autosuggest__input',
                        class: ['form-control', errors[0]],
                      }"
                      @closed="
                        creditor
                          ? (nameCreditor = nameCreditor)
                          : (nameCreditor = null)
                      "
                      @input="onInputChange"
                      @selected="selectHandler"
                      v-model="nameCreditor"
                    >
                      <template slot="before-input">
                        <div
                          class="lds-dual-ring"
                          v-show="creditorsLoading"
                        ></div>
                      </template>
                      <template slot-scope="{ suggestion }">
                        <span class="my-suggestion-item cursor-pointer">
                          {{ suggestion.item.value }}
                        </span>
                      </template>
                    </vue-autosuggest>
                    <label v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </label>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <!-- Attorney -->
              <b-col xl="6" lg="6" cols="12">
                <b-form-group id="attorney-input" label-for="attorney-input">
                  <template #label>
                    <div class="d-flex justify-content-between">
                      <span>Attorney</span>
                      <feather-icon
                        icon="PlusCircleIcon"
                        size="15"
                        class="cursor-pointer text-primary"
                        v-b-tooltip.hover.topright="'ADD ATTORNEY'"
                        @click="openModalAttorneyManagment()"
                      />
                    </div>
                  </template>
                  <v-select
                    label="name"
                    v-model="formCase.attorney"
                    :searchable="true"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="Enter attorney"
                    :options="attorneys"
                    :reduce="(x) => x.id"
                  ></v-select>
                </b-form-group>
              </b-col>

              <!-- State -->
              <b-col xl="6" lg="6" md="12" sm="12" cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="courtSelect"
                  rules="required"
                >
                  <b-form-group
                    id="court-select"
                    label-for="court-select"
                    label="State"
                  >
                    <v-select
                      v-model="formCase.state_id"
                      :options="G_STATES_EEUU"
                      :reduce="(el) => el.id"
                      :class="errors[0] ? 'border-danger' : null"
                      @input="changeState"
                    ></v-select>
                    <label v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </label>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                xl="6"
                lg="6"
                md="12"
                sm="12"
                cols="12"
                v-if="showDiscountDate"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="discountDateInput"
                  rules="required"
                >
                  <b-form-group
                    id="discountDate-input"
                    label="Discount Date"
                    label-for="documentDate-input"
                  >
                    <b-form-datepicker
                      v-model="formCase.discountDate"
                      placeholder="Enter discount date"
                      :class="errors[0] ? 'border-danger' : null"
                      @input="changeDiscountDate"
                    >
                    </b-form-datepicker>
                    <label v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </label>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                xl="6"
                lg="6"
                md="12"
                sm="12"
                cols="12"
                v-if="showDiscountAmount"
              >
                <ValidationProvider name="discountAmount" v-slot="{ errors }">
                  <b-form-group
                    id="discounAmount-input"
                    label="Discount Amount"
                    label-for="documentDate-input"
                  >
                    <money
                      v-bind="vMoney"
                      v-model="formCase.discountAmount"
                      class="form-control"
                      :class="{
                        'border-danger': errors[0],
                      }"
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <transition name="fade" mode="out-in">
                <b-col cols="6" v-if="!availableEdit">
                  <div>
                    <!-- Available Days -->
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="AvailableDaysSelect"
                    >
                      <b-form-group
                        label-class="font-weight-bolder"
                        label="Available Days"
                        label-for="TypeDays"
                      >
                        <v-select
                          id="available-days-select"
                          v-model="formCase.availableDays"
                          :dir="'ltr'"
                          :multiple="true"
                          transition="multiple"
                          label="name"
                          :options="dayOptions"
                          :class="[
                            { 'disable-class': !availableEdit },
                            {
                              'border-danger rounded':
                                errors[0] && !availableEdit,
                            },
                          ]"
                          :closeOnSelect="false"
                          :disabled="availableEdit"
                        />
                        <label
                          v-if="errors[0] && !availableEdit"
                          class="text-danger"
                        >
                          {{ errors[0] }}
                        </label>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </b-col>
              </transition>

              <!-- Available Hour From -->
              <transition name="fade" mode="out-in">
                <b-col
                  xl="3"
                  lg="3"
                  md="3"
                  sm="12"
                  cols="12"
                  v-if="!availableEdit"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="AvailableHourFromSelect"
                  >
                    <b-form-group
                      label-class="font-weight-bolder"
                      label="Available Hour From"
                      label-for="TypeHourFrom"
                    >
                      <kendo-timepicker
                        :format="'HH:mm'"
                        v-model="formCase.availableHourFrom"
                        :interval="15"
                        class="rounded bg-transparent"
                        placeholder="Hour"
                        :disabled="availableEdit"
                        :min="minTime"
                        style="height: 2.73rem; width: 100%"
                        :class="
                          errors[0] && !availableEdit
                            ? 'rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                            : 'rounded bg-transparent k-picker-custom picker-select-date'
                        "
                      />
                      <label
                        v-if="errors[0] && !availableEdit"
                        class="text-danger"
                      >
                        {{ errors[0] }}
                      </label>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </transition>

              <!-- Available Hour To -->
              <transition name="fade" mode="out-in">
                <b-col
                  xl="3"
                  lg="3"
                  md="3"
                  sm="12"
                  cols="12"
                  v-if="!availableEdit"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="AvailableHourToSelect"
                  >
                    <b-form-group
                      label-class="font-weight-bolder"
                      label="Available Hour To"
                      label-for="TypeHourTo"
                    >
                      <kendo-timepicker
                        :format="'HH:mm'"
                        v-model="formCase.availableHourTo"
                        :interval="15"
                        class="rounded bg-transparent"
                        placeholder="Hour"
                        :disabled="availableEdit"
                        :min="minTime"
                        style="height: 2.73rem; width: 100%"
                        :class="
                          errors[0] && !availableEdit
                            ? 'rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                            : 'rounded bg-transparent k-picker-custom picker-select-date'
                        "
                      />
                      <label
                        v-if="errors[0] && !availableEdit"
                        class="text-danger"
                      >
                        {{ errors[0] }}
                      </label>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </transition>

              <!-- Court -->
              <b-col
                xl="6"
                lg="6"
                md="12"
                sm="12"
                cols="12"
                v-if="isDebtSolution"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="courtSelect"
                  :rules="formCase.state_id == 'CA' ? 'required' : ''"
                >
                  <b-form-group id="court-select" label-for="court-select">
                    <template #label>
                      <div class="d-flex justify-content-between">
                        <span>Court</span>
                        <feather-icon
                          icon="PlusCircleIcon"
                          size="15"
                          class="cursor-pointer text-primary"
                          v-b-tooltip.hover.topright="'ADD COURT'"
                          @click="openModalCourtManagment()"
                        />
                      </div>
                    </template>
                    <v-select
                      :options="allCourt"
                      label="name"
                      v-model="formCase.courtId"
                      :reduce="(x) => x.id"
                      :searchable="true"
                      placeholder="Select Court"
                      :class="errors[0] ? 'border-danger' : null"
                    >
                      <template #no-options>
                        No courts for state selected
                      </template>
                    </v-select>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <!-- files -->
              <b-col xl="12" lg="12" md="12" sm="12" cols="12">
                <ValidationProvider v-slot="{ errors }" name="FileRequired">
                  <b-form-group
                    id="files-drag-and-drop"
                    label="Files"
                    label-for="files-drag-and-drop"
                  >
                    <drag-and-drop
                      ref="dyd"
                      class="w-100"
                      v-model="files"
                      :filesArray="files"
                      :class="errors[0] ? 'border-danger' : null"
                    />
                    <label v-if="errors[0]" class="text-danger">
                      {{ errors[0] }}
                    </label>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </ValidationObserver>
        </tab-content>

        <tab-content title="Payment">
          <ValidationObserver
            ref="formPayValidation"
            lazy
            v-bind:vid="'form-payment'"
          >
            <b-row>
              <b-col lg="5" md="5" sm="5" xl="5">
                <div>
                  <ValidationProvider
                    name="price"
                    :rules="`required`"
                    v-slot="{ errors }"
                  >
                    <h5 class="text-left">Cost</h5>
                    <div
                      class="rounded border-secondary font-medium-1 text-center pricesdiv"
                      :class="{ 'border-danger': errors[0] }"
                    >
                      <b-form-radio-group
                        v-model="amount"
                        :options="optionsAmount"
                        name="radio-validation"
                        style="padding: 6px 0px"
                        @input="changeAmount"
                      />
                    </div>
                  </ValidationProvider>
                </div>
              </b-col>

              <b-col xl="3" lg="3" md="3" sm="4" v-if="amount === 'other'">
                <ValidationProvider
                  name="otherAmount"
                  :rules="
                    'required|validate-amount' +
                    `|min_value:${validationMinAmount}`
                  "
                  v-slot="{ errors }"
                >
                  <money
                    v-bind="vMoney"
                    v-model="otherAmount"
                    class="form-control"
                    style="margin-top: 22px"
                    :class="{
                      'border-danger': errors[0] && vmoneyValidate,
                    }"
                  />
                  <label v-if="errors[0] && vmoneyValidate" class="text-danger">
                    {{
                      errors[0] == "is min"
                        ? `Min value is ${validationMinAmount}`
                        : errors[0]
                    }}
                  </label>
                </ValidationProvider>
              </b-col>

              <b-col :offset="amount === 'other' ? 2 : 5" cols="2">
                <b-button
                  class="mt-2 float-right"
                  variant="outline-success"
                  v-b-tooltip.hover.left="'ADD CARD'"
                  @click="openCreateCreditCard()"
                >
                  <feather-icon
                    class="text-success"
                    icon="CreditCardIcon"
                    size="15"
                  />
                </b-button>
              </b-col>
            </b-row>

            <b-row
              class="mt-1 px-1"
              v-if="amount != 'No Payment' && amount != null"
            >
              <ValidationProvider
                name="otherAmount"
                rules="required"
                v-slot="{ errors }"
              >
                <b-form-group label="Options">
                  <b-form-radio-group
                    id="radio-slots"
                    v-model="optionCostSelected"
                    :options="costOptions"
                    name="radio-options-slots"
                    :class="{ 'border-danger': errors[0] }"
                  >
                  </b-form-radio-group>
                </b-form-group>
              </ValidationProvider>
            </b-row>

            <b-row>
              <b-col cols="12" v-if="amount == 'No Payment'" class="mt-1">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="motive_id"
                  rules="required"
                >
                  <b-form-group
                    id="motive-input"
                    label="Motive"
                    label-for="motive-input"
                  >
                    <v-select
                      :options="motives"
                      label="description"
                      v-model="motive_id"
                      :reduce="(x) => x.id"
                      :searchable="true"
                      placeholder="Select a motive"
                      :class="errors[0] ? 'border-danger' : null"
                      :clearable="false"
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col cols="12" class="mt-1" v-if="amount != 'No Payment'">
                <h5 class="text-left mb-0">Cards</h5>
                <credit-card-table
                  :cards="cards"
                  :optionName="amount"
                  @cardId="cardId"
                  :usedCard="usedCard"
                  :disabledCards="cardsToDisable"
                  ref="creditCardTable"
                />
              </b-col>
            </b-row>
          </ValidationObserver>
        </tab-content>
      </form-wizard>

      <div v-else>
        <ValidationObserver
          ref="formInformationValidation"
          lazy
          v-bind:vid="'form-information'"
        >
          <b-row>
            <!-- switch -->
            <b-col
              offset-xl="5"
              offset-lg="5"
              offset-md="0"
              offset-sm="0"
              xl="3"
              lg="3"
              md="6"
              sm="6"
              v-if="clientId && modulId == 5"
              class="text-right p-0"
            >
              <b-form-group>
                <label style="color: #e26969; margin-right: 1rem"
                  >CREDITOR</label
                >
                <label class="switch">
                  <b-form-checkbox
                    v-model="checked"
                    value="1"
                    switch
                    type="checkbox"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">DEBT</label>
              </b-form-group>
            </b-col>

            <!-- Always Available -->
            <b-col
              xl="4"
              lg="4"
              md="6"
              sm="6"
              class="text-right p-0"
              :offset-xl="
                clientId == null && (modulId == 5 || modulId != 5)
                  ? 8
                  : clientId && modulId != 5
                  ? 8
                  : 0
              "
              :offset-lg="
                clientId == null && modulId == 5
                  ? 8
                  : clientId && modulId != 5
                  ? 8
                  : 0
              "
              :offset-md="
                clientId == null && modulId == 5
                  ? 8
                  : clientId && modulId != 5
                  ? 6
                  : 0
              "
              :offset-sm="
                clientId == null && modulId == 5
                  ? 8
                  : clientId && modulId != 5
                  ? 6
                  : 0
              "
            >
              <b-form-group>
                <label class="font-weight-bolder">
                  {{ clientId == null ? "Lead" : "Client" }}
                  Always Available
                </label>
                <label class="switch ml-1">
                  <b-form-checkbox
                    v-model="formCase.switchAvailableAlways"
                    :unchecked-value="0"
                    switch
                    @change="changeSwitchAvailableAlways"
                  />
                </label>
              </b-form-group>
            </b-col>

            <b-col xl="6" lg="6" cols="12">
              <span class="title-tag">SERVICE TYPE</span>
              <div class="border border-primary rounded">
                <p
                  class="text-primary d-flex align-items-center justify-content-center my-1s"
                >
                  {{
                    formCase.typeService == 1
                      ? "ANSWER"
                      : formCase.typeService == 2
                      ? "INVESTIGATION"
                      : "UNDEFINED"
                  }}
                </p>
              </div>
            </b-col>
            <!-- Types Document -->
            <b-col xl="6" lg="6" cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="documentSelect"
                rules="required"
              >
                <!-- businessRules.documentSelected == WG || -->
                <b-form-group
                  id="document-select"
                  label="Document Types"
                  label-for="service-select"
                  :description="
                    (businessRules.documentSelected == SM ||
                      businessRules.documentSelected == BL) &&
                    businessRules.responseTime
                      ? `Response Time ${businessRules.responseTime} days`
                      : null
                  "
                >
                  <v-select
                    :options="allTypesDocument"
                    label="name"
                    v-model="formCase.typeDocument"
                    :reduce="(x) => x.id"
                    :searchable="true"
                    placeholder="Select document"
                    :class="errors[0] ? 'border-danger' : null"
                    :clearable="false"
                    :selectable="(option) => !option.disabled"
                    @input="changeDocument"
                  >
                    <template #selected-option="{ name, code }">
                      <span
                        >{{ name }}
                        <span v-if="code != null" class="text-primary"
                          >({{ code }})
                        </span></span
                      >
                    </template>
                    <template #option="{ name, code }">
                      <span
                        >{{ name }}
                        <span v-if="code != null" class="text-primary"
                          >({{ code }})
                        </span></span
                      >
                    </template>
                  </v-select>
                  <label v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </label>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <!-- Court Case Number -->
            <b-col xl="6" lg="6" cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="courtNumberInput"
                rules="required"
              >
                <b-form-group
                  id="courtNumber-input"
                  label="Court Case Number"
                  label-for="courtNumber-input"
                >
                  <b-form-input
                    v-model="formCase.courtCaseNumber"
                    aria-describedby="input-live-help input-live-feedback"
                    placeholder="Enter court case number"
                    :class="
                      errors[0] || errorCaseNumber ? 'border-danger' : null
                    "
                  ></b-form-input>
                  <label
                    v-if="errors[0] || errorCaseNumber"
                    class="text-danger"
                  >
                    {{ errors[0] ? errors[0] : errorCaseNumber }}
                  </label>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- Document Date -->
            <b-col xl="6" lg="6" md="12" sm="12" cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="documentDateInput"
                rules="required"
              >
                <b-form-group
                  id="documentDate-input"
                  label="Document Date"
                  label-for="documentDate-input"
                >
                  <b-form-datepicker
                    v-model="formCase.documentDate"
                    placeholder="Enter document date"
                    :class="errors[0] ? 'border-danger' : null"
                    :disabled="formCase.typeDocument == null"
                    @input="changeDocumentDate"
                  >
                  </b-form-datepicker>
                  <label v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </label>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- Accounts -->
            <b-col cols="6" v-if="clientId && modulId == 5 && checked">
              <ValidationProvider
                v-slot="{ errors }"
                name="accountsSelect"
                rules="required"
              >
                <b-form-group
                  id="accounts-type-select"
                  label="Accounts"
                  label-for="accounts-type-select"
                >
                  <v-select
                    :options="allAccountsType"
                    label="name"
                    v-model="formCase.accountTypeId"
                    :reduce="(x) => x.id"
                    :searchable="true"
                    placeholder="Select account"
                    :class="errors[0] ? 'border-danger' : null"
                    :clearable="false"
                  >
                    <template v-slot:option="option">
                      {{ option.name }} (#. {{ option.account }})
                    </template>
                    <template #selected-option="option">
                      {{ option.name }} (#. {{ option.account }})
                    </template>
                  </v-select>
                  <label v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </label>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <!-- Creditors -->
            <b-col cols="6" v-if="!checked">
              <ValidationProvider v-slot="{ errors }" rules="customRequired">
                <b-form-group label="Creditors" label-for="creditors">
                  <vue-autosuggest
                    ref="autocomplete"
                    :suggestions="filteredOptions"
                    :get-suggestion-value="getSuggestionValue"
                    :input-props="{
                      id: 'autosuggest__input',
                      class: ['form-control', errors[0]],
                    }"
                    @closed="
                      creditor
                        ? (nameCreditor = nameCreditor)
                        : (nameCreditor = null)
                    "
                    @input="onInputChange"
                    @selected="selectHandler"
                    v-model="nameCreditor"
                  >
                    <template slot="before-input">
                      <div
                        class="lds-dual-ring"
                        v-show="creditorsLoading"
                      ></div>
                    </template>
                    <template slot-scope="{ suggestion }">
                      <span class="my-suggestion-item cursor-pointer">
                        {{ suggestion.item.value }}
                      </span>
                    </template>
                  </vue-autosuggest>
                  <label v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </label>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- Attorney -->
            <b-col xl="6" lg="6" cols="12">
              <b-form-group id="attorney-input" label-for="attorney-input">
                <template #label>
                  <div class="d-flex justify-content-between">
                    <span>Attorney</span>
                    <feather-icon
                      icon="PlusCircleIcon"
                      size="15"
                      class="cursor-pointer text-primary"
                      v-b-tooltip.hover.topright="'ADD ATTORNEY'"
                      @click="openModalAttorneyManagment()"
                    />
                  </div>
                </template>
                <v-select
                  label="name"
                  v-model="formCase.attorney"
                  :searchable="true"
                  aria-describedby="input-live-help input-live-feedback"
                  placeholder="Enter attorney"
                  :options="attorneys"
                  :reduce="(x) => x.id"
                ></v-select>
              </b-form-group>
            </b-col>

            <!-- State -->
            <b-col xl="6" lg="6" md="12" sm="12" cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="stateSelect"
                rules="required"
              >
                <b-form-group
                  id="state-select"
                  label-for="state-select"
                  label="State"
                >
                  <v-select
                    v-model="formCase.state_id"
                    :options="G_STATES_EEUU"
                    :reduce="(el) => el.id"
                    :class="errors[0] ? 'border-danger' : null"
                    @input="changeState"
                  ></v-select>
                  <label v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </label>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              xl="6"
              lg="6"
              md="12"
              sm="12"
              cols="12"
              v-if="showDiscountDate"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="discountDateInput"
                rules="required"
              >
                <b-form-group
                  id="discountDate-input"
                  label="Discount Date"
                  label-for="documentDate-input"
                >
                  <b-form-datepicker
                    v-model="formCase.discountDate"
                    placeholder="Enter discount date"
                    :class="errors[0] ? 'border-danger' : null"
                    @input="changeDiscountDate"
                  >
                  </b-form-datepicker>
                  <label v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </label>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              xl="6"
              lg="6"
              md="12"
              sm="12"
              cols="12"
              v-if="showDiscountAmount"
            >
              <ValidationProvider
                name="discountAmount"
                v-slot="{ errors }"
                :rules="
                  requiredCost
                    ? `required|min_value:${validationMinAmount}`
                    : ''
                "
              >
                <b-form-group
                  id="discounAmount-input"
                  label="Discount Amount"
                  label-for="documentDate-input"
                >
                  <money
                    v-bind="vMoney"
                    v-model="formCase.discountAmount"
                    class="form-control"
                    :class="{
                      'border-danger': errors[0],
                    }"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <transition name="fade" mode="out-in">
              <b-col cols="6" v-if="!availableEdit">
                <div>
                  <!-- Available Days -->
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="AvailableDaysSelect"
                  >
                    <b-form-group
                      label-class="font-weight-bolder"
                      label="Available Days"
                      label-for="TypeDays"
                    >
                      <v-select
                        id="available-days-select"
                        v-model="formCase.availableDays"
                        :dir="'ltr'"
                        :multiple="true"
                        transition="multiple"
                        label="name"
                        :options="dayOptions"
                        :class="[
                          { 'disable-class': !availableEdit },
                          {
                            'border-danger rounded':
                              errors[0] && !availableEdit,
                          },
                        ]"
                        :closeOnSelect="false"
                        :disabled="availableEdit"
                      />
                      <label
                        v-if="errors[0] && !availableEdit"
                        class="text-danger"
                      >
                        {{ errors[0] }}
                      </label>
                    </b-form-group>
                  </ValidationProvider>
                </div>
              </b-col>
            </transition>

            <!-- Available Hour From -->
            <transition name="fade" mode="out-in">
              <b-col
                xl="3"
                lg="3"
                md="3"
                sm="12"
                cols="12"
                v-if="!availableEdit"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="AvailableHourFromSelect"
                >
                  <b-form-group
                    label-class="font-weight-bolder"
                    label="Available Hour From"
                    label-for="TypeHourFrom"
                  >
                    <kendo-timepicker
                      :format="'HH:mm'"
                      v-model="formCase.availableHourFrom"
                      :interval="15"
                      class="rounded bg-transparent"
                      placeholder="Hour"
                      :disabled="availableEdit"
                      :min="minTime"
                      style="height: 2.73rem; width: 100%"
                      :class="
                        errors[0] && !availableEdit
                          ? 'rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                          : 'rounded bg-transparent k-picker-custom picker-select-date'
                      "
                    />
                    <label
                      v-if="errors[0] && !availableEdit"
                      class="text-danger"
                    >
                      {{ errors[0] }}
                    </label>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </transition>

            <!-- Available Hour To -->
            <transition name="fade" mode="out-in">
              <b-col
                xl="3"
                lg="3"
                md="3"
                sm="12"
                cols="12"
                v-if="!availableEdit"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="AvailableHourToSelect"
                >
                  <b-form-group
                    label-class="font-weight-bolder"
                    label="Available Hour To"
                    label-for="TypeHourTo"
                  >
                    <kendo-timepicker
                      :format="'HH:mm'"
                      v-model="formCase.availableHourTo"
                      :interval="15"
                      class="rounded bg-transparent"
                      placeholder="Hour"
                      :disabled="availableEdit"
                      :min="minTime"
                      style="height: 2.73rem; width: 100%"
                      :class="
                        errors[0] && !availableEdit
                          ? 'rounded bg-transparent k-picker-custom border-error-datepicker picker-select-date'
                          : 'rounded bg-transparent k-picker-custom picker-select-date'
                      "
                    />
                    <label
                      v-if="errors[0] && !availableEdit"
                      class="text-danger"
                    >
                      {{ errors[0] }}
                    </label>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </transition>

            <!-- Court -->
            <b-col
              xl="6"
              lg="6"
              md="12"
              sm="12"
              cols="12"
              v-if="isDebtSolution"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="courtSelect"
                :rules="formCase.state_id == 'CA' ? 'required' : ''"
              >
                <b-form-group id="court-select" label-for="court-select">
                  <template #label>
                    <div class="d-flex justify-content-between">
                      <span>Court</span>
                      <feather-icon
                        icon="PlusCircleIcon"
                        size="15"
                        class="cursor-pointer text-primary"
                        v-b-tooltip.hover.topright="'ADD COURT'"
                        @click="openModalCourtManagment()"
                      />
                    </div>
                  </template>
                  <v-select
                    :options="allCourt"
                    label="name"
                    v-model="formCase.courtId"
                    :reduce="(x) => x.id"
                    :searchable="true"
                    placeholder="Select Court"
                    :class="errors[0] ? 'border-danger' : null"
                  >
                    <template #no-options>
                      No courts for state selected
                    </template>
                  </v-select>
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <!-- files -->
            <b-col xl="12" lg="12" md="12" sm="12" cols="12">
              <ValidationProvider v-slot="{ errors }" name="FileRequired">
                <b-form-group
                  id="files-drag-and-drop"
                  label="Files"
                  label-for="files-drag-and-drop"
                >
                  <drag-and-drop
                    ref="dyd"
                    class="w-100"
                    v-model="files"
                    :filesArray="files"
                    :class="errors[0] ? 'border-danger' : null"
                  />
                  <label v-if="errors[0]" class="text-danger">
                    {{ errors[0] }}
                  </label>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-button
            @click="registerCaseDS"
            variant="outline-success"
            class="float-right"
          >
            CREATE CASE
          </b-button>
        </ValidationObserver>
      </div>
    </b-modal>

    <CreateCreditCard
      v-if="createCreditOn"
      :lead="leadId"
      @reload="refreshCards"
      @close="closeCreateCreditCard"
    />

    <modal-attorney-management
      v-if="showAttorneyModal"
      :attorneyId="null"
      @close="(showAttorneyModal = false), getAttorneys()"
    />
    <!-- , getAllCourtTypeCiByState() -->
    <modal-court-management
      v-if="showCourtModal"
      :courtId="null"
      @close="showCourtModal = false"
      @closing="changeState(formCase.state_id)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// utilities
import { VueAutosuggest } from "vue-autosuggest";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import VMoney from "v-money";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import moment from "moment";
// components
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import CreditorsService from "@/views/debt-solution/views/creditors/service/creditors.service.js";
import CreditCardTable from "@/views/commons/components/ncr-programs/components/table/card/CreditCardTable.vue";
import SettingsService from "@/views/debt-solution/views/settings/views/manageCI/services/Settings.service.js";
import CreateCreditCard from "@/views/commons/components/clients/dashboard/options/pay-module/modals/CreateCreditCard.vue";
import ModalAttorneyManagement from "@/views/debt-solution/views/settings/views/manageCI/components/modal/ModalAttorneyManagement.vue";
import ModalCourtManagement from "@/views/debt-solution/views/settings/views/manageCI/components/modal/ModalCourtManagement.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  components: {
    FormWizard,
    TabContent,
    VueAutosuggest,
    vSelect,
    VMoney,
    DragAndDrop,
    CreditCardTable,
    CreateCreditCard,
    ModalAttorneyManagement,
    ModalCourtManagement,
    StatusAccount,
  },
  directives: { money: VMoney },
  props: {
    leadId: {
      type: Number,
    },
    modulId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    minTime() {
      let dTime = this.formCase.availableHourFrom || "00:00";
      let contactDtime = "01/01/1970 " + dTime;
      return new Date(contactDtime);
    },
    clientId() {
      return this.$route.params.idClient;
    },
    buttonNextText() {
      if (this.currentStep == 1) {
        return "Create";
      }
      return "Next";
    },
    isDebtSolution() {
      return this.$route.matched[0].meta.module == 5;
    },
    ...mapGetters({
      G_STATES_EEUU: "StandarStore/G_STATES_EEUU",
    }),
    responseTimeMajorThanDocumentDate() {
      let today = moment();
      let documentDate = moment(this.formCase.documentDate);
      // subtract today days with document date
      let diff = today.diff(documentDate, "days");
      let responseTime = this.businessRules.responseTime;
      // compare if diff is greather than response time
      return diff > responseTime;
    },
  },
  data() {
    return {
      optionCostSelected: null,
      openModal: true,
      isBusy: false,
      observation: "",
      formCase: {
        typeService: null,
        typeDocument: null,
        courtCaseNumber: "",
        attorney: null,
        documentDate: null,
        courtId: null,
        accountTypeId: null,
        idCreditor: null,
        availableDays: [],
        availableHourFrom: "",
        availableHourTo: "",
        switchAvailableAlways: 0,
        // for WG
        discountDate: null,
        discountAmount: 0,
        state_id: null,
      },
      motive_id: null,
      clientData: [],
      allTypesService: [],
      allTypesDocument: [],
      allCourt: [],
      attorneys: [],
      allAccountsType: [],
      disabledDocument: true,
      filteredOptions: [],
      creditor: null,
      idCreditor: "",
      nameCreditor: "",
      files: [],
      dayOptions: [
        { name: "Sunday", id: 1 },
        { name: "Monday", id: 2 },
        { name: "Tuesday", id: 3 },
        { name: "Wednesday", id: 4 },
        { name: "Thursday", id: 5 },
        { name: "Friday", id: 6 },
        { name: "Saturday", id: 7 },
      ],
      minDate: new Date(1000, 1, 1),
      maxDate: new Date(2050, 9, 1),
      value: "00:00 AM",
      optionsAmount: [],
      cards: [],
      usedCard: null,
      cardsToDisable: [],
      amount: null,
      creditorsLoading: false,
      createCreditOn: false,
      errorCaseNumber: null,
      uploadInfo: false,
      currentStep: 0,
      otherAmount: 0,
      vMoney: {
        decimal: ",",
        thousand: ".",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        min: 0.0,
        masked: false,
      },
      vmoneyValidate: false,
      checked: false,
      validationMinAmount: 0,
      availableEdit: false,
      showAttorneyModal: false,
      showCourtModal: false,
      businessRules: {
        responseTime: 0,
        courtIsNotFromCA: false,
        caseIsFromCA: false,
        documentSelected: null,
        documentMinCost: null,
        documentDiscountPercent: null,
        documentSelectedName: null,
      },
      showDiscountDate: false,
      showDiscountAmount: false,
      requiredCost: false,

      WG: 6,
      BL: 7,
      SM: 1,
      selectedAmountOption: {},
      currentDocumentServices: [],
      costOptions: [],
      settings: [],
      finallyCost: {},
      motives: [],
      clientStatus: {},
    };
  },
  mounted() {
    if (this.isDebtSolution) {
      this.checked = 1;
    }
  },
  watch: {
    amount: function (val) {
      if (val != "other") {
        this.buildOptions(val);
      } else {
        this.costOptions = [];
      }
    },
    otherAmount: function (val) {
      this.buildOptions(val);
    },
    optionCostSelected: function (val) {
      const selectedOption = this.costOptions.find(
        (option) => option.value === val
      );
      this.finallyCost = selectedOption;
    },

    "businessRules.documentSelected": function (val) {
      // fields
      if (val == this.WG || val == this.BL) {
        this.showDiscountDate = true;
        this.showDiscountAmount = true;
      } else {
        this.showDiscountDate = false;
        this.showDiscountAmount = false;
      }
      // set required amount discounted
      if (val == this.BL) {
        this.requiredCost = true;
      } else {
        this.requiredCost = false;
      }
    },
    checked() {
      this.formCase.accountTypeId = null;
      this.formCase.idCreditor = null;
      this.nameCreditor = null;
      this.filteredOptions = [];
      this.creditor = null;
    },
  },
  async created() {
    await Promise.all([
      this.getStatesEEUU(),
      this.getClientOrLeadData(),
      // this.getAllServiceTypeCi(),
      this.getCiServiceTypes(),
      this.getCiDocuments(),
      this.getAllAccountsTypeCi(),
      // this.getAllCourtTypeCiByState(),
      this.getAttorneys(),
      this.modulId == 5 ? this.getDsClientSchedule() : null,
      await this.getCiMotives(),
    ]);
    this.formCase.typeService = null;
  },
  methods: {
    addZeroPaymentOption() {
      this.optionsAmount.push({
        value: "No Payment",
        text: "No Payment",
        fractionType: "single",
        totalCost: 0,
      });
    },
    async getCiMotives() {
      try {
        this.addPreloader();
        const { data } = await CourtInfoService.getCiMotives();
        this.motives = data;
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },

    async buildOptions(amount) {
      this.costOptions = [];
      let documentMinCost = amount;
      this.validationMinAmount = this.businessRules.documentMinCost;

      // 1 payment
      let completeAmount = {
        value: documentMinCost,
        text: `${this.formatAmount(documentMinCost)} (1 installement)`, //  (1 Payment)
        fractionType: "single",
        totalCost: documentMinCost,
      };

      // 2 payments
      let percentConfigurated = this.settings[0].initial_cost_percent;
      let minCost = (documentMinCost * percentConfigurated) / 100;
      let newAmount = {
        value: minCost,
        text: `${this.formatAmount(minCost)} (2 installements)`, // funca
        fractionType: "multiple",
        totalCost: documentMinCost,
      };

      this.costOptions.push(completeAmount);
      this.costOptions.push(newAmount);
    },

    changeAmount() {
      const selectedOption = this.optionsAmount.find(
        (option) => option.value === this.amount
      );
      this.selectedAmountOption = selectedOption;
    },
    ...mapActions({
      A_GET_STATES_EEUU: "StandarStore/A_GET_STATES_EEUU",
    }),
    async getStatesEEUU() {
      try {
        await this.A_GET_STATES_EEUU({});
      } catch (error) {
        console.log("Something went wrong on getStatesEEUU", error);
        throw error;
      }
    },
    closeModalCourtInfo() {
      this.$emit("closeModalCourtInfo");
    },
    changeSwitchAvailableAlways() {
      if (this.formCase.switchAvailableAlways == 1) {
        this.availableEdit = true;
      } else {
        this.availableEdit = false;
      }
      this.formCase.availableDays = [];
      this.formCase.availableHourFrom = "";
      this.formCase.availableHourTo = "";
    },
    async getDsClientSchedule() {
      try {
        const { data } = await CourtInfoService.getDsClientSchedule({
          client_account_id: this.clientId,
        });
        if (data.length > 0) {
          const hourFrom = data[0].time_from;
          const toFrom = data[0].time_to;
          const schedules = data.map((schedule) => {
            const option = this.dayOptions.find(
              (day) => day.name.toUpperCase() === schedule.date
            );
            return {
              id: option.id,
              name: option.name,
            };
          });
          this.formCase.availableDays = schedules;
          this.formCase.availableHourFrom = hourFrom;
          this.formCase.availableHourTo = toFrom;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async validateFirstStep() {
      const compInformation = this.$refs.formInformationValidation;
      const isValidInformation = await compInformation.validate();
      await this.setCost();
      if (isValidInformation) {
        this.currentStep = 1;
      }
      if (await this.validateCaseNumber()) {
        return;
      } else {
        return isValidInformation;
      }
    },
    async setCost() {
      try {
        this.optionsAmount = [];
        const { data: settings } =
          await CourtInfoService.getCiGeneralSettings();
        this.settings = settings;

        // set price to default configurated if service is investigation
        if (this.formCase.typeService == 2) {
          this.businessRules.documentMinCost = settings[0].investigation_cost;
        }

        if (this.isDebtSolution) {
          this.validationMinAmount = this.businessRules.documentMinCost;
          this.amount = this.validationMinAmount;
          let newAmount = {
            value: this.validationMinAmount,
            text: `${this.formatAmount(this.validationMinAmount)}`,
            fractionType: "single",
          };
          this.optionsAmount.push(newAmount);
          this.optionsAmount.push({
            value: "other",
            text: "Other",
            fractionType: "single",
          });
          this.addZeroPaymentOption();
        } else {
          // For other programs, set initial cost
          let documentMinCost = this.businessRules.documentMinCost;
          let completeAmount = {
            value: documentMinCost,
            text: `${this.formatAmount(documentMinCost)}`, //  (1 Payment)
            fractionType: "single",
          };
          this.optionsAmount.push(completeAmount);
          this.optionsAmount.push({
            value: "other",
            text: "Other",
            fractionType: "single",
          });
          this.addZeroPaymentOption();
        }

        // set cost rules when is BL
        if (this.businessRules.documentSelected == this.BL) {
          await this.applyBusinessRulesCostBL();
        }
      } catch (error) {
        console.log(error);
      }
    },

    formatAmount(amount) {
      const formattedAmount = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount);
      return formattedAmount;
    },

    async applyBusinessRulesCostBL() {
      this.optionsAmount = [];

      const discountedAmount = this.formCase.discountAmount; // $ amount discounted of client or lead
      const minCost = this.businessRules.documentMinCost; // $ min cost of the service
      const discountPercent = this.businessRules.documentDiscountPercent; // % document discount percent
      // apply discountPercent to discountedAmount
      const possibleMinCost = (discountedAmount * discountPercent) / 100; // possible min cost
      this.validationMinAmount =
        possibleMinCost < minCost ? minCost : possibleMinCost;
      this.amount = this.validationMinAmount;

      let newAmount = {
        value: this.validationMinAmount,
        text: `${this.formatAmount(this.validationMinAmount)}`,
        fractionType: "single",
      };
      this.optionsAmount = [];
      this.optionsAmount.push(newAmount);
      this.optionsAmount.push({
        value: "other",
        text: "Other",
        fractionType: "single",
      });
      this.addZeroPaymentOption();
    },

    async registerCaseDS() {
      const compInformation = this.$refs.formInformationValidation;
      const isValidInformation = await compInformation.validate();

      if (await this.validateCaseNumber()) {
        return;
      }

      if (isValidInformation) {
        this.createCase();
      }
    },

    async finishAnswer() {
      this.vmoneyValidate = true;
      const compPay = this.$refs.formPayValidation;
      const isValidPay = await compPay.validate();

      if (isValidPay) {
        this.createCase();
      }
      return isValidPay;
    },

    async getClientOrLeadData() {
      try {
        this.addPreloader();
        const response = await CourtInfoService.getDataClientById({
          lead_id: this.leadId,
          module_id: this.modulId,
        });
        if (response.length > 0) {
          this.clientData = response[0];
          this.cards = JSON.parse(response[0].cards);
          this.clientStatus = JSON.parse(response[0].client_status);
          this.uploadInfo = true;
        } else {
          this.clientData = null;
        }
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with search!"
        );
      } finally {
        this.removePreloader();
      }
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.value;
    },
    async onInputChange(text) {
      this.creditor = null;
      this.creditorsLoading = true;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        this.creditorsLoading = false;
        return;
      }
      const { data } = await CreditorsService.searchCreditors(text);
      this.filteredOptions = [{ data: [...data] }];
      this.creditorsLoading = false;
    },
    selectHandler(value) {
      this.creditor = value.item.id;
    },
    async createCase() {
      if (await this.validateCaseNumber()) {
        return;
      }
      try {
        let assignedTo = null;

        const allowedRoles = new Set([1, 2, 17]);

        if (!allowedRoles.has(this.currentUser.role_id) && this.modulId == 5) {
          assignedTo = this.currentUser.user_id;
        }
        this.formCase.idCreditor = this.creditor;
        const form = new FormData();
        this.files.forEach((file) => {
          form.append("files[]", file, file.name);
        });
        form.append("modul_id", this.modulId);
        form.append("account", this.clientData ? this.clientData.account : "");
        form.append("lead_id", this.leadId);
        form.append("client_account_id", this.clientId || null);
        form.append("user_id", this.currentUser.user_id);
        form.append("in_assignedTo", assignedTo);
        form.append("document_name", this.businessRules.documentSelectedName);
        form.append("form_case", JSON.stringify(this.formCase));

        if (!this.isDebtSolution) {
          form.append("card_id", this.usedCard ?? this.card);
          // form.append("amount", this.finallyCost.value);
          form.append(
            "amount",
            this.amount == "No Payment" ? null : this.finallyCost.value
          );
          form.append(
            "fraction_type",
            this.amount == "No Payment" ? null : this.finallyCost.fractionType
          );
          form.append(
            "total_cost",
            this.amount == "No Payment" ? null : this.finallyCost.totalCost
          );

          if (this.amount == "No Payment") {
            form.append("motive_id", this.motive_id);
          }
        }

        let confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          this.addPreloader();
          const response = await CourtInfoService.createCaseCourtInfo(form);
          console.log(response);

          if (response.status == "error") {
            this.showGenericToast({
              position: "top-right",
              variant: response.info.variant,
              icon: response.info.icon,
              title: response.info.title,
              text: response.message,
            });
          }

          if (response.status == "success") {
            this.files = [];
            let customMessage =
              this.formCase.typeService == 2
                ? "Registration successful! Your case will be addressed within the next 24 hours"
                : "Registration successful!";
            this.showSuccessSwal("Success", customMessage);
            this.closeModalCourtInfo();
          }
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    applyBusinessRulesSummons() {
      let today = moment();
      let documentDate = moment(this.formCase.documentDate);
      // subtract today days with document date
      let diff = today.diff(documentDate, "days");
      let responseTime = this.businessRules.responseTime;
      // compare if diff is greather than response time
      if (diff > responseTime) {
        this.formCase.typeService = 2;
      } else {
        this.formCase.typeService = 1;
      }
    },
    applyBusinessRulesWG() {
      let today = moment();
      let discountDate = moment(this.formCase.discountDate);
      // subtract today days with discount date
      let diff = today.diff(discountDate, "days");
      let responseTime = this.businessRules.responseTime;
      // compare if diff is greather than response time
      if (diff > responseTime) {
        this.formCase.typeService = 2;
      }
    },
    applyBusinessRulesBL() {
      let today = moment();
      let discountDate = moment(this.formCase.discountDate);
      // subtract today days with discount date
      let diff = today.diff(discountDate, "days");
      let responseTime = this.businessRules.responseTime;
      // compare if diff is greather than response time
      if (diff > responseTime) {
        this.formCase.typeService = 2;
      }
    },
    async getAllAccountsTypeCi() {
      try {
        this.addPreloader();
        const response = await CourtInfoService.getAllCreditorsAccountsCi({
          client_account_id: this.clientId,
        });
        this.allAccountsType = response;
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong searching all Supervisors!"
        );
      }
    },
    async getAllCourtTypeCiByState(stateSlug) {
      try {
        let params = {
          stateSlug: stateSlug,
        };
        const { data } = await CourtInfoService.getAllCourtTypeCiByState(
          params
        );
        this.allCourt = data;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong searching all Supervisors!"
        );
      }
    },
    cardId(value) {
      this.card = value.id;
    },
    openCreateCreditCard() {
      this.createCreditOn = true;
    },
    closeCreateCreditCard() {
      this.createCreditOn = false;
    },
    async validateCaseNumber() {
      const params = {
        case_number: this.formCase.courtCaseNumber,
        caseId: null,
      };
      const resp = await amgApi.post(
        "debt-solution/court-info/get-validate-case-number",
        params
      );
      if (resp.data.status == 200) {
        if (resp.data.state) {
          this.errorCaseNumber = "the case number is not available";
          return true;
        } else {
          this.errorCaseNumber = null;
          return false;
        }
      }
    },
    async refreshCards() {
      this.getClientOrLeadData();
    },
    async getAttorneys() {
      try {
        const { data } = await SettingsService.listAttorneys();
        this.attorneys = data;
      } catch (error) {
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong searching all Supervisors!"
        );
      }
    },
    openModalAttorneyManagment() {
      this.showAttorneyModal = true;
    },
    openModalCourtManagment() {
      this.showCourtModal = true;
    },
    async getCiServiceTypes() {
      try {
        const { data } = await CourtInfoService.getCiServiceTypes();
        this.allTypesService = data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong getCiServiceTypes!"
        );
      }
    },
    async getCiDocuments() {
      try {
        const { data } = await CourtInfoService.getCiDocuments();
        this.allTypesDocument = data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong getCiDocuments!"
        );
      }
    },
    async changeCourt($event) {
      let courtIsNotFromCA = Boolean(await this.courtIsNotFromCA($event));
      this.businessRules.courtIsNotFromCA = courtIsNotFromCA;
    },

    async courtIsNotFromCA(courtId) {
      try {
        const { data } = await CourtInfoService.courtIsNotFromCA({
          court_id: courtId,
        });
        return data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong courtIsNotFromCA!"
        );
      }
    },
    async changeDocument($event) {
      let rows = await this.getCiDocument($event);
      rows = JSON.parse(rows[0].services);
      const hasAnswer = rows.some((item) => item.service === 1);
      this.currentDocumentServices = rows;

      if (hasAnswer) {
        this.formCase.typeService = 1;
      } else {
        this.formCase.typeService = 2;
      }

      let documentSelect = rows.find(
        (item) => item.service === this.formCase.typeService
      );
      this.businessRules.responseTime = documentSelect.response_time;
      this.businessRules.documentSelected = documentSelect.id;
      this.businessRules.documentMinCost = documentSelect.cost;
      this.businessRules.documentDiscountPercent =
        documentSelect.discount_percent;
      this.businessRules.documentSelectedName = documentSelect.name;

      // override the rule validations and set the state validation
      if (this.formCase.state_id != null) {
        this.changeState(this.formCase.state_id);
      }
    },

    changeDocumentDate() {
      // if is summons
      if (this.businessRules.documentSelected == this.SM) {
        this.formCase.typeService = this.responseTimeMajorThanDocumentDate
          ? 2
          : 1;
      }
    },

    changeDiscountDate(dateSelected) {
      // ||
      //   this.businessRules.documentSelected == this.WG
      if (this.businessRules.documentSelected == this.BL) {
        let today = moment();
        let discountDate = moment(dateSelected);
        // subtract today days with discount date
        let diff = today.diff(discountDate, "days");
        let responseTime = this.businessRules.responseTime;
        // compare if diff is greather than response time
        if (diff > responseTime) {
          this.formCase.typeService = 2;
        } else {
          this.formCase.typeService = 1;
        }
      }
    },

    async changeState(state) {
      if ((state == "CA") == true && !this.responseTimeMajorThanDocumentDate) {
        console.log(state, "state");
        // if document has answer service type
        let hasAnswer = this.currentDocumentServices.some(
          (item) => item.service === 1
        );
        if (hasAnswer) {
          this.formCase.typeService = 1;
        }
      } else {
        this.formCase.typeService = 2;
      }
      this.formCase.courtId = null;
      await this.getAllCourtTypeCiByState(state);
    },

    async getCiDocument(documentId) {
      try {
        const { data } = await CourtInfoService.getCiDocument({
          document_id: documentId,
        });
        return data;
      } catch (error) {
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong getCiDocument!"
        );
      }
    },
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.kk-picker-wrapp .k-timepicker .k-picker-wrap {
  border: 1px solid red !important;
}
.pac-container.pac-logo:after {
  display: none !important;
}
.select__creditor--error {
  border: 1px solid #fc424a;
  border-radius: 0 5px 5px 0;
}

.select__creditor {
  flex: 1;
}

.dark-layout .border-right-separator {
  border-right: 1px solid #747476;
}

.border-right-separator {
  border-right: 1px solid #e0e0e0;
}
</style>
