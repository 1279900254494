import { amgApi } from "@/service/axios";

class SettlementService {
  async getOffersApproved(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/get-offers-by-status",
        params
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async countSettlements(params) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/settlements-made/counter-settlements-by-tab",
        params
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async countOffersPending(payload) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/settlements-made/count-offers-pending",
        payload
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getCountByPriorityOrder() {
    try {
      const data = await amgApi.get(
        "/debt-solution/settlements-made/get-count-by-priority-order-ds"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getPaymentStatusTotal() {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/get-payment-status-total"
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async confirmDebit(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/confirm-debit",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async rejectedDebit(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/rejected-debit",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  async getStatusOrdersPayment(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/get-status-orders-payment",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async addPurchaseOrder(params) {
    try {
      const { status } = await amgApi.post(
        "/debt-solution/settlements-made/add-purchase-order",
        params
      );
      return status;
    } catch (error) {
      throw error;
    }
  }

  async getCorrespondenceAccounts() {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/get-correspondence-accounts"
      );

      return data;
    } catch (error) {
      throw error;
    }
  }
  async getCountByPriorityOrder() {
    try {
      const data = await amgApi.get(
        "/debt-solution/settlements-made/count-unfinished"
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCheckManual(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/check-manual/index",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async sendRequestDeposit(params) {
    try {
      const data = await amgApi.post(
        "/debt-solution/settlements-made/deposit/store",
        params
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async countDeposits() {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/settlements-made/deposit/counter"
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }
}

export default new SettlementService();
