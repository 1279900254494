export default [
  {
    path: "clients",
    name: "quality-clients",
    redirect: { name: "quality-clients-main" },
    component: () =>
      import(
        /* webpackChunkName: "QualityClients" */ "@/views/quality/views/clients/Clients.vue"
      ),
    meta: {
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "quality-clients-tab",
        redirect: { name: "quality-clients-main" },
        component: () =>
          import(
            /* webpackChunkName: "QualityClientsTab" */ "@/views/quality/views/clients/components/ClientsTab.vue"
          ),
        meta: {
          type: "clients",
          tab: 1,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Settings",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "", //url
            name: "quality-clients-main", //name
            component: () =>
              import(
                /* webpackChunkName: "QualityClientsMain" */ "@/views/quality/views/clients/components/ClientsGridTable.vue"
              ),
            meta: {
              type: "clients",
              tab: 1,
              pageTitle: "Clients",
              list_table: "Main",
              breadcrumb: [
                {
                  text: "Main",
                  active: true,
                },
              ],
            },
          },
          {
            path: "/old", //url
            name: "quality-clients-main-old", //name
            component: () =>
              import(
                /* webpackChunkName: "QualityClientsMain" */ "@/views/quality/views/clients/components/ClientsGridTableOld.vue"
              ),
            meta: {
              type: "clients",
              tab: 1,
              pageTitle: "Clients Old",
              list_table: "Main",
              breadcrumb: [
                {
                  text: "Main",
                  active: true,
                },
              ],
            },
          },
          {
            path: "archived", //url
            name: "quality-clients-archived", //name
            component: () =>
              import(
                /* webpackChunkName: "QualityClientsArchived" */ "@/views/quality/views/clients/components/ClientsGridTable.vue"
              ),
            meta: {
              type: "clients",
              tab: 1,
              pageTitle: "Clients",
              list_table: "Archived",
              breadcrumb: [
                {
                  text: "Archived",
                  active: true,
                },
              ],
            },
          },
          {
            path: "calls", //url
            name: "quality-clients-calls", //name
            //redirect: { name: "charge_active" },
            component: () =>
              import(
                /* webpackChunkName: "QualityClientsCalls" */ "@/views/quality/views/clients/components/ClientsGridTable.vue"
              ),
            meta: {
              type: "clients",
              list_table: "CallsRound",
              tab: 1,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Calls Round",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "shared",
        name: "quality-shared-tab",
        component: () =>
          import(
            /* webpackChunkName: "QualityClientsShared" */ "@/views/commons/components/clients/components/Shared.vue"
          ),
        meta: {
          tab: 2,
          pageTitle: "Clients",
          program: 5,
          breadcrumb: [
            {
              text: "Shared",
              active: true,
            },
          ],
          route: "bussiness",
        },
      },
    ],
  },
  {
    path: "clients/account/:idClient",
    name: "quality-client-dashboard",
    redirect: { name: "personal-information-quality" },
    component: () =>
      import(
        /* webpackChunkName: "QualityClientsAccountDashboard" */ "@/views/quality/views/clients/dashboard/AccountQuality.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay",
        name: "pay-quality",
        component: () =>
          import(
            /* webpackChunkName: "QualityClientsAccountDashboardPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "task-notes",
        name: "task-notes-quality",
        component: () =>
          import(
            /* webpackChunkName: "QualityClientsAccountDashboardTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information",
        name: "personal-information-quality",
        component: () =>
          import(
            /* webpackChunkName: "QualityClientsAccountDashboardPersonalInformation" */ "@/views/quality/views/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files",
        name: "client-files-quality",
        component: () =>
          import(
            /* webpackChunkName: "QualityClientsAccountDashboardClientFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr",
        name: "acr-quality",
        component: () =>
          import(
            /* webpackChunkName: "QualityClientsAccountDashboardAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "dis",
        name: "dis-quality",
        redirect: { name: "analysis-cr-qua" },
        component: () =>
          import(
            /* webpackChunkName: "QualityClientsAccountDashboardDis" */ "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Dispute",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "analysis",
            name: "analysis-cr-qua",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/analysis/AccountsClients.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Items",
                  active: true,
                },
              ],
            },
          },
          {
            path: "dispute-detail",
            name: "dispute-detail-qua",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/DisputeDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Disputes",
                  active: true,
                },
              ],
            },
          },
          {
            path: "report-detail",
            name: "report-detail-qua",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Credit Report",
                  active: true,
                },
              ],
            },
          },
          {
            path: "updates-detail",
            name: "updates-detail-qua",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/UpdateDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Updates",
                  active: true,
                },
              ],
            },
          },
            {
            path: "workplan-detail",
            name: "workplan-detail-qua",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Workplan",
                  active: true,
                },
              ],
            },
          },
            {
            path: "recommendation-detail",
            name: "recommendation-detail-qua",
            component: () =>
              import(
                /* webpackChunkName: "QuaClientAccountDisDetail" */   "@/views/commons/components/clients/dashboard/options/services/services.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Recommendations",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "acc",
        name: "acc-quality",
        component: () =>
          import(
            /* webpackChunkName: "QuaClientAccountAcc" */ "@/views/commons/components/clients/dashboard/options/acc-module/Acc.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acc",
              active: true,
            },
          ],
        },
      },
      {
        path: "report",
        name: "report-quality",
        component: () =>
          import(
            /* webpackChunkName: "QualityClientsAccountDashboardReport" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Report",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log",
        name: "call-log-client-quality",
        redirect: { name: "call-log-conversation-quality" },
        component: () =>
          import(
            "@/views/commons/components/ring-central/call-log/tabs/ClientTabs.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
          callConversationRoute: "call-log-conversation-quality",
          callNoConversationRoute: "call-log-not-conversation-quality",
        },

        children: [
          {
            path: "conversation",
            name: "call-log-conversation-quality",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Conversation",
                  active: true,
                },
              ],
              isConversation: 1,
            },
          },
          {
            path: "no-conversation",
            name: "call-log-not-conversation-quality",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "No Conversation",
                  active: true,
                },
              ],
              isConversation: 0,
            },
          },
        ],
      },
      {
        path: "services",
        name: "services-client-quality",
        component: () =>
          import(
            /* webpackChunkName: "servicesClient" */
            "@/views/commons/components/clients/dashboard/options/services/services.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 18,
          pageTitle: "Services",
          breadcrumb: [{ text: "Services", active: true }],
        },
      },
    ],
  },
];
