export default [
    {
        path: 'dashboard',
        name: 'dashboard-employee-claims-rrhh',
        component: () => import(/* webpackChunkName: "RrhhEmployeesMain" */ '@/views/rrhh/views/dashboard/MainDashboardClaims.vue'),
        meta: {
            pageTitle: "Staff Claims",
            permittedRoles: [1, 2, 17],
            breadcrumb: [{
                text: 'Dashboard',
                active: true,
            }],
            tabOthersClaimsRoute: 'rrhh-employee-claims-others-claims',
            tabEmployeeClaimListRoute: 'rrhh-employee-claims-list',
        },
    }
]
