import CrmServices from "../../services/crm.service";

const DEFAULT_BODY_GET_SELLERS_CRM = {
  roles: "[1,2,5]",
  type: "1",
};
const DEFAULT_BODY_GET_CAPTURED_CRM = {
  roles: "[]",
  type: "1",
};
export default {
  namespaced: true,
  state: {
    ProgramHeader: null,
    Program: null,
    Catcher: null,
    Seller: null,
    sellersCrm: [{ id: 0, label: "All" }],
    capturedCrm: [],
    S_CLIENTS_COUNTER: [],
    S_ADDCHANGE_COUNTERS: null,
    S_SALE_LEADS_COUNTER: null,
    // TODO Hacerlo global
    programs: {},
    sources: {},
    states: {},
    countries: {},
    work_status: [
      { id: 1, label: "Employed" },
      { id: 2, label: "Self Employed" },
      { id: 3, label: "Retired" },
      { id: 4, label: "Student" },
      { id: 5, label: "Disability" },
      { id: 6, label: "Unemployed" },
    ],
    legal_status: [
      { id: 1, label: "U.S. Citizen" },
      { id: 2, label: "Residente Alien" },
      { id: 3, label: "Non-Resident Alietn" },
      { id: 4, label: "Student Green Card" },
      { id: 5, label: "Work Green Card" },
    ],
    statusip: [
      { id: 0, label: "All" },
      { id: 2, label: "Yes" },
      { id: 1, label: "No" },
    ],
    statusFilter: [
      { id: 0, label: "All", variant: "" },
      { id: 1, label: "Pending", variant: "warning" },
      { id: 2, label: "Revision", variant: "primary" },
      { id: 3, label: "Disapproved", variant: "danger" },
      { id: 4, label: "Approved", variant: "success" },
      { id: 5, label: "In Supervisor", variant: "info" },
      { id: 8, label: "Observed", variant: "info" },
    ],
    statusAnnuled: [
      { id: 0, label: "Pending", variant: "warning" },
      { id: 1, label: "Pending", variant: "warning" },
      { id: 2, label: "Revision", variant: "primary" },
      { id: 3, label: "Disapproved", variant: "danger" },
      { id: 4, label: "Approved", variant: "success" },
    ],
    statusAddChange: [
      { id: 0, label: "All", variant: "" },
      { id: 1, label: "Pending", variant: "warning" },
      { id: 2, label: "Underview", variant: "primary" },
      { id: 3, label: "Disapproved", variant: "danger" },
      { id: 4, label: "Approved", variant: "success" },
      { id: 5, label: "In Supervisor", variant: "info" },
      { id: 6, label: "In Ceo", variant: "info" },
      { id: 7, label: "Return", variant: "info" },
    ],
    statusNewClient: [
      { id: 0, label: "Pending", variant: "warning" },
      { id: 1, label: "Pending", variant: "warning" },
      { id: 2, label: "Underview", variant: "primary" },
      { id: 3, label: "Disapproved", variant: "danger" },
      { id: 4, label: "Approved", variant: "success" },
      { id: 5, label: "In Supervisor", variant: "info" },
      { id: 6, label: "In Ceo", variant: "info" },
      { id: 7, label: "Return", variant: "info" },
      // { id: 8, label: 'Observed', variant: 'warning' },
      { id: 8, label: "Underview", variant: "warning" },
      { id: 9, label: "Disapproved", variant: "danger" }, // Rejected By Program
      { id: 10, label: "Disapproved", variant: "danger" }, // Rejected By MG
      { id: 11, label: "Approved", variant: "success" }, // Approved by MG
    ],
    typeOfSale: [
      { id: 0, label: "All" },
      { id: 1, label: "Add Service" },
      { id: 2, label: "Change Service" },
    ],
    refreshSalesMade: 1,
    S_PROGRAMS: [],
  },
  getters: {
    G_PROGRAMS_CRM_DONE(state) {
      return state.S_PROGRAMS;
    },
    G_ADDCHANGE_COUNTERS(state) {
      return state.S_ADDCHANGE_COUNTERS;
    },
    G_SALE_LEADS_COUNTER(state) {
      return state.S_SALE_LEADS_COUNTER;
    },
    G_CLIENTS_COUNTER(state) {
      return state.S_CLIENTS_COUNTER;
    },
    G_CLIENTS_COUNTER_CURRENT(state) {
      const counter = state.S_CLIENTS_COUNTER.find(
        (counter) => counter.status === 8
      );
      return counter ? counter.count : 0;
    },
    G_CLIENTS_COUNTER_ONE_MONTH_LATE(state) {
      const counter = state.S_CLIENTS_COUNTER.find(
        (counter) => counter.status === 9
      );
      return counter ? counter.count : 0;
    },
    G_CLIENTS_COUNTER_TWO_MONTH_LATE(state) {
      const counter = state.S_CLIENTS_COUNTER.find(
        (counter) => counter.status === 10
      );
      return counter ? counter.count : 0;
    },
    G_CLIENTS_COUNTER_TOTAL_ACTIVE(state, getters) {
      const currentCounter = getters.G_CLIENTS_COUNTER_CURRENT;
      const oneMonthLateCounter = getters.G_CLIENTS_COUNTER_ONE_MONTH_LATE;
      const twoMonthsLateCounter = getters.G_CLIENTS_COUNTER_TWO_MONTH_LATE;
      return currentCounter + oneMonthLateCounter + twoMonthsLateCounter;
    },
    G_CLIENTS_COUNTER_HOLD(state) {
      const counter = state.S_CLIENTS_COUNTER.find(
        (counter) => counter.status === 2
      );
      return counter ? counter.count : 0;
    },
    G_CLIENTS_COUNTER_CANCELED(state) {
      const counter = state.S_CLIENTS_COUNTER.find(
        (counter) => counter.status === 4
      );
      return counter ? counter.count : 0;
    },
    G_CLIENTS_COUNTER_CLOSED(state) {
      const counter = state.S_CLIENTS_COUNTER.find(
        (counter) => counter.status === 6
      );
      return counter ? counter.count : 0;
    },
    G_CLIENTS_COUNTER_TOTAL_OUTSTANDING(state, getters) {
      const holdCounter = getters.G_CLIENTS_COUNTER_HOLD;
      const canceledCounter = getters.G_CLIENTS_COUNTER_CANCELED;
      const closedCounter = getters.G_CLIENTS_COUNTER_CLOSED;
      return holdCounter + canceledCounter + closedCounter;
    },
    G_CLIENTS_COUNTER_IN_PROGRESS(state) {
      const counter = state.S_CLIENTS_COUNTER.find(
        (counter) => counter.status === 11
      );
      return counter ? counter.count : 0;
    },
    G_CLIENTS_COUNTER_POTENTIAL(state) {
      const counter = state.S_CLIENTS_COUNTER.find(
        (counter) => counter.status === 12
      );
      return counter ? counter.count : 0;
    },
    G_CLIENTS_COUNTER_STAND_BY(state) {
      const counter = state.S_CLIENTS_COUNTER.find(
        (counter) => counter.status === 13
      );
      return counter ? counter.count : 0;
    },
    G_CLIENTS_COUNTER_TOTAL_LOYAL(state, getters) {
      const inProgressCounter = getters.G_CLIENTS_COUNTER_IN_PROGRESS;
      const potentialCounter = getters.G_CLIENTS_COUNTER_POTENTIAL;
      const standByCounter = getters.G_CLIENTS_COUNTER_STAND_BY;
      return inProgressCounter + potentialCounter + standByCounter;
    },
    getSellerEmpty(state) {
      return state.sellersCrm.empty();
    },
    getCapturedEmpty(state) {
      return Object.keys(state.capturedCrm).length === 0;
    },
  },
  mutations: {
    M_SET_PROGRAMS(state, value) {
      state.S_PROGRAMS = value;
    },
    M_SET_ADDCHANGE_COUNTERS(state, params) {
      state.S_ADDCHANGE_COUNTERS = params;
    },
    M_SET_LEAD_SALE_COUNTERS(state, params) {
      state.S_SALE_LEADS_COUNTER = params;
    },
    M_SET_CLIENTS_COUNTER(state, params) {
      state.S_CLIENTS_COUNTER = params;
    },
    SET_PROGRAM(state, payload) {
      state.Program = payload;
    },
    SET_CATCHER(state, payload) {
      state.Catcher = payload;
    },
    SET_SELLER(state, payload) {
      state.Seller = payload;
    },
    SET_PROGRAM_HEADER(state, payload) {
      state.ProgramHeader = payload;
    },
    SET_SELLERS_CRM(state, sellersCrm) {
      state.sellersCrm = sellersCrm;
    },
    SET_CAPTURED_CRM(state, capturedCrm) {
      state.capturedCrm = capturedCrm;
    },
    // TODO Hacerlo global
    SET_PROGRAMS(state, programs) {
      state.programs = programs;
    },
    SET_SOURCES(state, sources) {
      state.sources = sources;
    },
    SET_STATES(state, states) {
      state.states = states;
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_REFRESH_SALES_MADE(state, refreshSalesMade) {
      state.refreshSalesMade = refreshSalesMade;
    },
  },
  actions: {
    SET_PROGRAM_HEADER({ commit }, data) {
      commit("SET_PROGRAM_HEADER", data);
    },
    SET_PROGRAM({ commit }, data) {
      commit("SET_PROGRAM", data);
    },
    SET_CATCHER({ commit }, data) {
      commit("SET_CATCHER", data);
    },
    SET_SELLER({ commit }, data) {
      commit("SET_SELLER", data);
    },

    async getSellers(
      { commit },
      payload = { module: 2, body: DEFAULT_BODY_GET_SELLERS_CRM }
    ) {
      const sellers = await CrmServices.getSellersCrm(
        payload.module,
        payload.body
      );

      const formatedSellers = sellers.map((seller) => ({
        id: seller.id,
        label: seller.user_name,
      }));
      commit("SET_SELLERS_CRM", [
        {
          id: 0,
          label: "All",
        },
        ...formatedSellers,
      ]);
    },

    async getCaptured(
      { commit },
      payload = { module: 2, body: DEFAULT_BODY_GET_CAPTURED_CRM }
    ) {
      const captured = await CrmServices.getCapturedCrm(
        payload.module,
        payload.body
      );
      const formatedCaptured = captured.map((cap) => ({
        id: cap.id,
        label: cap.user_name,
      }));
      commit("SET_CAPTURED_CRM", formatedCaptured);
    },
    // TODO Hacerlo global
    async getPrograms({ commit }) {
      const programs = await CrmServices.getPrograms();
      const formatedPrograms = programs.map((pro) => ({
        id: pro.id,
        label: pro.name,
      }));
      commit("SET_PROGRAMS", formatedPrograms);
    },
    async getSources({ commit }) {
      const sources = await CrmServices.getSources();
      const formatedSources = sources.map((source) => ({
        id: source.id,
        label: source.name,
      }));
      commit("SET_SOURCES", formatedSources);
    },
    async getStates({ commit }) {
      const states = await CrmServices.getStates({ type: 1 });
      const formatedStates = states.map((state) => ({
        id: state.slug,
        label: state.slug,
      }));
      commit("SET_STATES", formatedStates);
    },
    async getCountries({ commit }) {
      const countries = await CrmServices.getCountries();
      const formatedCountries = countries.map((country) => ({
        id: country.id,
        label: country.name,
      }));
      commit("SET_COUNTRIES", formatedCountries);
    },
    async A_GET_SPECIALIST_COUNTER({ commit }) {
      const { data } = await CrmServices.getSpecialistCounter();
      commit("M_SET_CLIENTS_COUNTER", data);
    },
    async A_GET_ADDCHANGE_COUNTERS({ commit }, payload) {
      const data = await CrmServices.getClientCounters(payload);
      commit("M_SET_ADDCHANGE_COUNTERS", data);
      return data;
    },
    async A_GET_LEAD_SALE_COUNTERS({ commit }, payload) {
      const result = await CrmServices.countSalesMadeByLead(payload);
      const {
        TOTAL_CRM,
        TOTAL_ORIGEN_FOR_APROVED,
        TOTAL_ORIGEN_DISAPPROVED,
        TOTAL_DESTINO_FOR_APROVED,
      } = result;
      const total =
        TOTAL_CRM +
        TOTAL_DESTINO_FOR_APROVED +
        TOTAL_ORIGEN_DISAPPROVED +
        TOTAL_ORIGEN_FOR_APROVED;
      commit("M_SET_LEAD_SALE_COUNTERS", {
        total,
        result,
      });
      return total;
    },
    async A_GET_PROGRAMS({ commit }, payload) {
      const result = await CrmServices.getProgramsCrmDone();
      commit("M_SET_PROGRAMS", result);
    },
  },
};
