<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    title="Client Risk Classification Tracking"
    hide-footer
    size="lg"
    @hidden="closeModal"
  >
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      no-visible-principal-filter="true"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :noVisiblePrincipalFilter="true"
      @reload="$refs['refClientsRiskyTracking'].refresh()"
    >
      <template #table>

        <b-table
          slot="table"
          ref="refClientsRiskyTracking"
          primary-key="id"
          show-empty
          sticky-header
          small
          responsive="sm"
          :fields="fields"
          :items="myProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template
            v-slot:cell(type)="data"
          >
            <div

              class="d-flex justify-content-center"
            >
            <b-badge
                style="width: 100px"
                :variant="
                  getRiskyBadgeVariant(data.item.level)
                "
                >{{
                  getRiskyLevelName(data.item.level)
                }}</b-badge
              >

            </div>
          </template>

          <template v-slot:cell(created_at)="data">
            <div>
              {{ data.item.created_at | myGlobalDay }}
            </div>
          </template>
        </b-table>
      </template>

    </filter-slot>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex'

import RiskyService from '@/views/management/views/risky-clients/risky.service'

export default {
  props: {

    data: {},

  },
  data() {
    return {
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      startPage: null,
      endPage: null,
      nextPage: null,

      toPage: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      ownControl: false,
      sortBy: 'created_at',
      sortDesc: true,
      spinnerOn: false,
      fields: [
        {
          key: 'specialist_name',
          label: 'Specialist Name',
        },
        {
          key: 'type',
          label: 'Type',
          thStyle: 'width: 140px; text-align: center;',
        },
        {
          key: 'comments',
          label: 'Comments',
          thStyle: 'width: 300px;',
        },
        {
          key: 'created_at',
          label: 'Created At',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({

      currentUser: 'auth/currentUser',

    }),
  },
  created() {
    this.ownControl = true
  },

  methods: {

    closeModal() {
      this.$emit('close')
    },

    async myProvider(ctx) {
      const params = {
        client_account: this.data.id_account,
        order: ctx.sortDesc === 1 ? 'desc' : 'asc',
        page: ctx.currentPage,
        per_page: ctx.perPage,
      }
      const data = await RiskyService.getRiskyTypeByClientId(params)
      const items = data.data.data
      this.startPage = data.data.from
      this.paginate.currentPage = data.data.current_page
      this.paginate.perPage = data.data.per_page
      this.nextPage = this.startPage + 1
      this.endPage = data.data.last_page
      this.totalRows = data.data.total
      this.toPage = data.data.to
      // Must return an array of items or an empty array if an error occurred
      return items || []
    },

    getRiskyBadgeVariant(level) {
      switch (level) {
        case 1:
          return "info";

        case 2:
          return "warning";

        case 3:
        return "danger";

        default:
        return "success";
      }
    },

    getRiskyLevelName(level) {
      switch (level) {
        case 1:
          return "RISK - LOW";

        case 2:
          return "RISK - MEDIUM";

        case 3:
          return "RISK - HIGH";

        default:
        return "NO RISK";
      }
    },
  },
}
</script>

<style scoped>

</style>
