export default [
  {
    path: "dashboard",
    name: "taxresearch-dashboard",
    component: () =>
      import(
        /* webpackChunkName: "TaxResearchDashboardMain" */ "@/views/tax-research/views/dashboard/TrDashboard.vue"
      ),
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
        },
      ],
    },
  },
];
