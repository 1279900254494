export default [
  {
    path: '/',
    name: 'users-users',
    redirect: { name: 'users-active' },
    component: () => import(/* webpackChunkName: "Users" */ '@/views/users/views/users/Users.vue'),
    meta: {
      // permittedRoles: [1, 2],
      isClientsTab: true,
      route: 'users',
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'users-active',
        component: () => import(/* webpackChunkName: "UsersTabActive" */ '@/views/users/views/users/components/TabActive.vue'),
        meta: {
          isActive: 1,
          pageTitle: 'Users',
          route: 'users',
          breadcrumb: [
            {
              text: 'Active',
              active: true,
            },
          ],
        },
      },
      {
        path: 'inactive',
        name: 'users-inactive',
        component: () => import(/* webpackChunkName: "UsersTabInactive" */ '@/views/users/views/users/components/TabActive.vue'),
        meta: {
          isActive: 0,
          pageTitle: 'Users',
          route: 'users',
          breadcrumb: [
            {
              text: 'Inactive',
              active: true,
            },
          ],
        },
      },
    ],
  },
];
