<template>
  <b-modal
    v-model="isActive"
    @hidden="closeMe"
    title="History Of Email"
    title-tag="h3"
    size="xmd"
    hide-footer
    scrollable
  >
    <b-table :items="history" :fields="fields" show-empty sticky-header="50vh">
      <template #cell(user_name)="data">
        {{ data.item.user_name }} {{ data.item.created_at | myGlobalDay }}
      </template>
      <template #cell(content)="data">
        <div v-html="data.item.content"></div>
      </template>
      <template #cell(files)="data">
        <div v-if="data.item.files != null">
          <li v-for="(file, index) in JSON.parse(data.item.files)" :key="index">
            <a
              :href="`${data.item.route_t}/FilesEmails/${file}`"
              target="_blank"
              >{{ file }}</a
            >
          </li>
        </div>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import ClientsService from "@/views/commons/components/clients/services/clients.services.js";

export default {
  props: {
    leadId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isActive: true,
      history: [],
      fields: [
        { key: "user_name", label: "Send By", class: "w-25" },
        { key: "content", label: "Content" },
        { key: "files", label: "Files" },
      ],
    };
  },
  mounted() {
    this.showEmails();
  },
  methods: {
    closeMe() {
      this.$emit("closeModal");
    },
    async showEmails() {
      try {
        this.addPreloader();
        const { data } = await ClientsService.leadEmailHistory({
          id: this.leadId,
        });
        this.history = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>