<template>
  <div class="container-filter-message">
    <div class="b-input-container">
      <b-form-input
        type="text"
        class="b-input-custom"
        placeholder="Search..."
        v-model="searchTxt"
        @input="searchMessages"
      />
      <!-- <b-button variant="primary">
          <feather-icon icon="SearchIcon"></feather-icon>
        </b-button> -->
    </div>
    <div
      class="message"
      v-for="message in messagesFind"
      :key="message._id"
      :class="messageId == message._id ? 'active' : ''"
      @click="messageId = message._id"
    >
      <div class="d-flex justify-content-between">
        <div v-if="message.direction == 'Outbound'">
          <span>
            {{
              message.createdBy ? message.createdBy.name : credential.rc_name
            }}
          </span>
          <feather-icon
            icon="CornerRightUpIcon"
            style="color: #006400"
            v-b-tooltip.hover
            title="Outbound"
          />
        </div>

        <div v-if="message.direction == 'Inbound'">
          <feather-icon
            icon="CornerLeftDownIcon"
            style="color: #7fb3d5"
            v-b-tooltip.hover
            title="Inbound"
          />
          <span>
            {{
              currentChat.leadName
                ? currentChat.leadName
                : currentChat.leadPhoneNumber
            }}
          </span>
        </div>

        <div>
          <span class="date">{{ naturalDate(message.creationTime) }}</span>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </div>
      </div>
      <p class="text" v-html="highlightMatchingText(message.subject)"></p>
    </div>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import NestSmsService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
export default {
  props: {
    value: {
      type: String,
    },

    credential: {
      type: Object,
      required: true,
    },

    currentChat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      messagesFind: [],
      searchTxt: "",
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    messageId: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  methods: {
    async searchMessages() {
      if (this.searchTxt == "") {
        this.messagesFind = [];
        return;
      }
      const params = {
        chatId: this.currentChat._id,
        text: this.searchTxt,
      };
      try {
        this.addPreloader();

        const { data } = await NestSmsService.searchMessages(params);
        this.messagesFind = data;
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },

    highlightMatchingText(text) {
      const searchTextLower = this.searchTxt.toLowerCase();
      const regex = new RegExp(`(${searchTextLower})`, "gi");
      return text.replace(regex, `<span class="match">$1</span>`);
    },
  },

  watch: {
    currentChat() {
      this.messagesFind = [];
      this.searchTxt = "";
    },
  },
};
</script>
  
  <style lang="scss">
.header-rc-chat-search-message {
  font-family: "Rubick" !important;
  width: 100%;
  background-color: #f0f2f5;
  min-height: 60px;
  display: flex;
  padding: 0 2rem;
  align-items: center;
  border-left: 1px solid #ced0d1;
  position: sticky;
  top: 0;
  z-index: 100;
}

.container-filter-message {
  border-left: 1px solid #ced0d1;
  height: inherit;
  overflow-y: auto;

  .b-input-container {
    display: flex;
    padding: 1rem;
    .b-input-custom {
      background-color: #f0f2f5;
      width: 100%;
    }
  }

  .message {
    padding: 1rem;
    cursor: pointer;
    border-bottom: 1px solid #e9edef;
    overflow-x: hidden;

    .date,
    .text {
      color: #6b7981;
    }

    .match {
      color: #0099dd;
    }
  }

  .message:hover {
    background-color: #f5f6f6;
  }

  .active {
    background-color: #f5f6f6;
  }
}

.dark-layout {
  .header-rc-chat-search-message {
    background-color: #202c33;
    border-left: 1px solid #374035;
  }

  .container-filter-message {
    border-left: 1px solid #374035;
    background-color: #111b21;

    .b-input-container {
      .b-input-custom {
        background-color: #202c33;
      }
    }

    .message {
      background-color: #111b21;
      border-bottom: 1px solid #202c33;

      .date,
      .text {
        color: #f5f6f6;
      }

      .match {
        color: #00a884;
      }
    }
    .message:hover {
      background-color: #202c33;
    }

    .active {
      background-color: #202c33;
    }
  }
}
</style>