import derivationsRouter from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.router";
import leads from "@/views/ce-digital/sub-modules/sales/views/leads/router/leads.router";
import clients from "@/views/ce-digital/sub-modules/sales/views/clients/router/clients.router";
import ceSalesMadeRouter from "@/views/ce-digital/sub-modules/sales/views/sales-made/sales-made.router";
import commissionsRouter from "@/views/ce-digital/sub-modules/sales/views/commissions/commissions.router";
import ceSalesDashboardRouter from "@/views/ce-digital/sub-modules/sales/views/dashboards/ce-sales-dashboard.router";
import calendarRoute from "@/views/ce-digital/sub-modules/sales/views/calendar/calendar.router";
import helpdeskRoute from "@/views/ce-digital/sub-modules/sales/views/helpdesk/helpdesk.route";
import CeSalesAttendanceRouter from "@/views/ce-digital/sub-modules/sales/views/attendance/ce-sales-attendance.router";
import EmployeeClaimsRouter from "@/views/ce-digital/sub-modules/sales/views/employee-claims/router/employee-claims.router";
import CeSalesMain from "@/views/ce-digital/sub-modules/sales/CeSalesMain.vue";
import RequestRouter from "@/views/ce-digital/sub-modules/sales/views/request/request.router.js";
//import SalesProjectsRouter from "@/views/ce-digital/sub-modules/sales/views/projects/sales-projects.router";
import CallLogRouter from "@/views/ce-digital/sub-modules/sales/views/ring-central/call-log/call-log.router.js";
import MOFRouter from '@/views/ce-digital/sub-modules/sales/views/manual-of-functions/manual-of-functions.router.js';
import RecruitmentProcessRouter from '@/views/ce-digital/sub-modules/sales/views/recruitment-process/sales-recruitment_process.router';
import requestNcrRouter from "@/views/ce-digital/sub-modules/sales/views/request-ncr/request-ncr.router.js";
import CourtInfoRouter from "@/views/ce-digital/sub-modules/sales/views/cour-info/router/ds-court-info.router";

import DashboardCalls from "@/views/ce-digital/sub-modules/sales/views/ring-central/dashboard-calls/dashboard.router.js";

import CustomerTicketRouter from '@/views/ce-digital/sub-modules/sales/views/customer-tickets/sales-customer-tickets.router';

const routes = [
  {
    path: "/cedigital/sales",
    redirect: { name: "ce-dashboard-sales" },
    meta: { module: 26, parentModule: 21 },
    component: CeSalesMain,
    children: [
      ...derivationsRouter,
      ...leads,
      ...clients,
      ...ceSalesMadeRouter,
      ...ceSalesDashboardRouter,
      ...commissionsRouter,
      ...calendarRoute,
      helpdeskRoute,
      ...CeSalesAttendanceRouter,
      ...EmployeeClaimsRouter,
      ...RequestRouter,
      //...SalesProjectsRouter,
      ...CallLogRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...requestNcrRouter,
      ...CourtInfoRouter,
      ...DashboardCalls,
      ...CustomerTicketRouter,
    ],
  },
];

export default routes;
