<template>
  <div class="modal-vue-file-formats-ci">
    <b-row class="container-upload-ci-cf px-1" v-if="files.length > 0">
      <b-col
        sm="12"
        md="6"
        lg="4"
        class="mb-2 ci-botton"
        v-for="(item, index) in files"
        :key="index"
      >
        <b-row class="new-border">
          <div>
            <b-col cols="12">
              <div class="d-flex justify-content-between">
                <div
                  class="header"
                  v-b-tooltip.hover.bottom="item.document_type"
                >
                  {{ item.document_type | limitChars(30) }}
                </div>
              </div>
            </b-col>
            <b-col
              cols="12"
              v-for="(format, formatIndex) in JSON.parse(item.case_formats)"
              :key="formatIndex"
            >
              <div
                class="d-flex align-items-center justify-content-between pl-2 mt-1"
              >
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="FileIcon"
                    class="mr-1"
                    size="15"
                  ></feather-icon>
                  {{ format.name | limitChars(30) }}
                </div>
                <div class="d-flex align-items-center">
                  <feather-icon
                    :id="`pre-${formatIndex}`"
                    icon="EyeIcon"
                    class="text-primary cursor-pointer"
                    @click="openPreviewPdf(format.url, format.name)"
                  ></feather-icon>
                </div>
              </div>
            </b-col>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <div v-else class="text-center mb-2 no-files-timeline">
      No matching formats found
    </div>
    <preview-pdf
      v-if="showPreviewPdf"
      :pdf-url="pdfFile"
      :pdf-name="pdfName"
      @close="showPreviewPdf = false"
    ></preview-pdf>
  </div>
</template>
<script>
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";
export default {
  components: { PreviewPdf },
  props: {
    caseId: {
      type: Number,
      default: null,
    },
    step: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      files: [],
      showPreviewPdf: false,
    };
  },
  created() {
    this.getCiCaseFormats();
  },
  methods: {
    async getCiCaseFormats() {
      try {
        const { data } = await CourtInfoService.getAllCaseFormatsByCase({
          case_id: this.caseId,
          case_type_document_id: this.step,
        });
        this.files = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    openPreviewPdf(url, name) {
      this.pdfFile = url;
      this.pdfName = name;
      this.showPreviewPdf = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-vue-file-formats-ci {
  .container-upload-ci-cf {
    font-family: "Rubick";

    .new-border {
      border: 1px dotted #82868b;
      border-radius: 5px;
      padding: 10px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      height: 200px;
    }

    .header {
      display: flex;
      align-items: center;
      font-size: 15px;
    }
  }
}
</style>