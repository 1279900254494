<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-header-close
      hide-header
      :body-class="`rounded`"
      no-close-on-backdrop
      size="summary"
      @hidden="isActive = false"
      centered
      :ok-only="true"
      ok-variant="success"
      hide-footer
    >
      <div>
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
        >
          <div class="d-flex align-items-center">
            <div class="icon-badge mr-1 bg-light-info">
              <amg-icon icon="FilePdfIcon" size="18" />
            </div>
            <span class="text-info font-strong text-uppercase">
              {{ alertTitle }}
            </span>
          </div>
          <div
            class="close-modal"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
            @click="closeModal()"
          >
            <FeatherIcon icon="XIcon" size="16" />
          </div>
        </div>
        <hr />

        <div class="d-flex flex-column" style="gap: 15px">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CLIENT
            </span>
            <span class="font-strong">{{ dataSendReport.client_name }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="MapIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              DEPARTMENT
            </span>
            <span class="font-strong">{{
              nameModule(dataSendReport.module_name)
            }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserCheckIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CREATED BY
            </span>
            <span class="font-strong">{{ dataSendReport.created_by }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <amg-icon
                icon="FilePdfIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />

              REPORT
            </span>
            <div style="width: 20px">
              <a target="_blank" class="pr-2" :href="dataSendReport.urlFile">
                <b-img
                  :src="require('@/assets/images/icons/pdf.png')"
                  width="15"
                  height="20"
                >
                </b-img>
              </a>
            </div>
          </div>
        </div>
        <hr />

        <b-row>
          <b-col>
            <b-button
              class="w-100 block"
              target="_blank"
              variant="info"
              @click="goToReport"
              >GO TO REPORT 
            </b-button>
          </b-col>
          <b-col>
            <b-button
              class="w-100 block"
              variant="success"
              @click="closeModal()"
              >OK</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      targetPath: "correspondence-sending-reports-in-proccess",
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_IS_ACTIVE: "AutoAlertSendReport/G_IS_ACTIVE",
      G_DATA_SEND_REPORT: "AutoAlertSendReport/G_DATA_SEND_REPORT",
      alertTitle: "AutoAlertSendReport/G_TITLE",
    }),

    isActive: {
      get() {
        return this.G_IS_ACTIVE;
      },
      set(value) {
        this.$store.commit("AutoAlertSendReport/M_OPEN_ALERT", value);
      },
    },

    currentPath() {
      return this.$route.name;
    },

    dataSendReport: {
      get() {
        return this.G_DATA_SEND_REPORT;
      },
      set(value) {
        this.$store.commit("AutoAlertSendReport/M_DATA_SEND_REPORT", value);
      },
    },
  },

  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "SendingReportStore/CHANGE_REFRESH_TABLE",
    }),
    nameModule(module) {
      if (module == "CUSTOMER SERVICE REGULAR") {
        return "CREDIT EXPERTS (REGULAR)";
      } else if (module == "CUSTOMER SERVICE DIGITAL") {
        return "CREDIT EXPERTS (DIGITAL)";
      } else {
        return module;
      }
    },

    goToReport() {
      this.closeModal();
      if (this.currentPath == this.targetPath) {
        this.CHANGE_REFRESH_TABLE(true);
      } else {
        this.$router.push({
          name: this.targetPath,
        });
      }
    },
    closeModal() {
      const channel = socket.channel("request-ncr-notifications", {});
      channel.trigger("client-close-send-report-notification", {
        message: "close all",
        user_id: this.currentUser.user_id,
      });
      this.isActive = false;
    },
  },
  created() {
    this.dataSendReport;
  },
};
</script>



