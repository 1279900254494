const state = {
  hoursCounter: {
    workingHours: 0,
    holidaysHours: 0,
  },
};
const getters = {
  G_HOURS_COUNTER() {
    return state.hoursCounter;
  },
};
const mutations = {
  SET_HOURS_COUNTER(state, payload) {
    state.hoursCounter = payload;
  },
};
const actions = {
  async A_SET_HOURS_COUNTER({ commit }, params) {
    commit('SET_HOURS_COUNTER', params);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
