export default [
  {
    path: '/my-profile',
    name: 'users-my-profile',
    component: () => import(/* webpackChunkName: "MyProfile" */ '../user-view/UsersView.vue'),
    meta: {
      pageTitle: 'My Profile',
      breadcrumb: [
        {
          text: 'My Profile',
        },
      ],
      layout: 'horizontal',
    },
    children: [
      {
        path: '/my-profile/other-information',
        name: 'users-my-profile-other-information',
        component: () => import(/* webpackChunkName: "OtherIfnrormation" */ '@/commons/users/user-view/others-views/views/OtherInformation.vue'),
        meta: {
          pageTitle: 'My Profile',
          breadcrumb: [
            {
              text: 'My Profile',
            },
          ],
          layout: 'horizontal',
        },
      },
      {
        path: '/my-profile/account-information',
        name: 'users-my-profile-account-information',
        component: () => import(/* webpackChunkName: "OtherIfnrormation" */ '@/commons/users/user-view/others-views/views/AccountInformation.vue'),
        meta: {
          pageTitle: 'My Profile',
          breadcrumb: [
            {
              text: 'My Profile',
            },
          ],
          layout: 'horizontal',
        },
      },
      {
        path: '/my-profile/work-experiencie',
        name: 'users-my-profile-work-experiencie',
        component: () => import(/* webpackChunkName: "OtherIfnrormation" */ '@/commons/users/user-view/others-views/views/WorkExperiencie.vue'),
        meta: {
          pageTitle: 'My Profile',
          breadcrumb: [
            {
              text: 'My Profile',
            },
          ],
          layout: 'horizontal',
        },
      },
    ],
  },

];
