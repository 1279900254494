import { amgApi } from '@/service/axios'

class DirectsRoundService {
    async getDirectRound(body) {
        try {
            const data = await amgApi.post('/dispute/search-direct-letter', body)
            return data;
        } catch (error) {
            console.error('Something went wrong on search-direct-letter:', error)
            throw error
        }
    }



    async addUrlTemporaryPdf(body) {
      try {
          const data = await amgApi.post('/dispute/add-url-temporary-pdf', body)
          return data;
      } catch (error) {
          console.error('Something went wrong on add-url-temporary-pdf:', error)
          throw error
      }
  }

    async deleteRound(body) {
        try {
            const data = await amgApi.post('/dispute/delete-round-letter', body)
            return data;
        } catch (error) {
            console.error('Something went wrong on delete-round-letter:', error)
            throw error
        }
    }

    async getPendingDirectRoundCount() {
        try {
            const { data } = await amgApi.post('specialists-digital/get-pending-direct-round-count');
            return data

        } catch (error) {
            console.error("Something went wrong on getPendingDirectRoundCount", error);
        }
    }

    async setDataDirect(params) {
        try {
            const data = await amgApi.post('dispute/set-data-direct', params);
            return data;
        } catch (error) {
            console.error("Something went wrong on setDataDirect", error);
        }
    }

    async processRoundLetters(params) {
        //params : {id:string (round_id) ,user: int(user_id), type: int,obs: string}

        try {
            const data = await amgApi.post('debt-solution/insert-process-letters', params);
            return data;
        } catch (error) {
            console.error("Something went wrong on processRoundLetters", error);
        }
    }

    async getDataDirect(params) {
        try {
            const data = await amgApi.post('dispute/get-data-direct', params);
            return data;
        } catch (error) {
            console.error("Something went wrong on getDataDirect", error);
        }
    }
    async saveFilesDirectDispute(formData){
        try{
            const data  = await amgApi.post('dispute/save-files-direct-dispute', formData);
            return data;
        }catch(error){
            console.log("Something went wrong on saveFilesDirectDispute",error)
            return {error}
        }
    }
}

export default new DirectsRoundService()
