import store from '@/store';
import Vue from 'vue';

const fixObservation = () => {
  window.socket.bind('fix-observation-notification', async data => {
    if (data.createdBy === store.state.auth.currentUser.user_id && store.state.ticketmessagesstore.S_PROJECT_SELECTED !== data.projectId) {
      await Vue.prototype.$amgAlert({
        title: 'Observation Fixed',
        icon: 'CheckIcon',
        subtitle: `Observation from project ${data.projectName} has been fixed`,
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }
  });
};
export default fixObservation;
