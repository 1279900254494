<template>
  <div>
    <template v-if="isNewSale == 1">
      <modal-notes-first-current
        :noteInfo="noteInfo"
        :is-new-sale="true"
        :sales-completed="salesCompleted"
        @hidden="hideModal"
        @refreshNotes="refreshNotes"
      />
    </template>
    <template v-else>
      <modal-notes-first-current
        :noteInfo="noteInfo"
        :is-new-sale="false"
        :sales-completed="salesCompleted"
        @hidden="hideModal"
        @refreshNotes="refreshNotes"
        @showOldVersion="showModalNotesFirstCurrent = false"
        v-if="showModalNotesFirstCurrent"
      />
      <template v-if="createsMomentIsBefore">
        <modal-notes-first-old
          @hide="hideModal"
          :noteInfo="noteInfo"
          :show-new-version="showNewVersion"
          :sales-completed="salesCompleted"
          @showNewVersion="showModalNotesFirstCurrent = true"
          @refreshNotes="refreshNotes"
          v-if="!showModalNotesFirstCurrent"
        />
      </template>
      <template v-else>
        <modal-notes-first-new
          @hide="hideModal"
          :noteInfo="noteInfo"
          :show-new-version="showNewVersion"
          :sales-completed="salesCompleted"
          @showNewVersion="showModalNotesFirstCurrent = true"
          @refreshNotes="refreshNotes"
          v-if="!showModalNotesFirstCurrent"
        />
      </template>
    </template>
  </div>
</template>

<script>
import ModalNotesFirstNew from "@/views/commons/components/first-notes/business/ModalNotesFirstNew.vue";
import ModalNotesFirstOld from "@/views/commons/components/first-notes/business/ModalNotesFirstOld.vue";
import ModalNotesFirstCurrent from "@/views/commons/components/first-notes/business/ModalNotesFirstCurrent.vue";
export default {
  name: "ModalNotesFirst",
  components: {
    ModalNotesFirstNew,
    ModalNotesFirstOld,
    ModalNotesFirstCurrent,
  },
  props: {
    noteInfo: {
      type: Object,
      required: true,
      default: () => ({
        programSelected: "",
        roleId: null,
        notesProgram: null,
        nameProgram: null,
        nameClient: null,
        salesMades: null,
        module: null,
        type: null,
        editModal: false,
        statusSale: null,
        sourcesName: null,
        pack: null,
        created: null,
        originCountry: null,
        idLead: null,
        notSeller: null,
        capturedName: null,
        sellerName: null,
        trackings: null,
        notes_status: null,
        notes_status_new: null,
      }),
    },
    showNewVersion: {
      type: Boolean,
      default: false,
    },
    salesCompleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      creates: "",
      isNewSale: false,
      showModalNotesFirstCurrent: false,
    };
  },
  computed: {
    createsMoment() {
      return this.$moment(this.creates);
    },
    deployMoment() {
      return this.$moment("2022-03-14");
    },
    createsMomentIsBefore() {
      return this.createsMoment.isBefore(this.deployMoment);
    },
  },
  async created() {
    try {
      const response = await amgApi.post("/sale/get-creates-sale", {
        sale_id: this.noteInfo.saleId,
      });
      if (this.isResponseSuccess(response)) {
        this.creates = response.data.creates;
        this.isNewSale = response.data.is_new;
        this.showModalNotesFirstCurrent = this.isNewSale == 2;
      }
    } catch (error) {
      this.showErrorSwal(error);
    }
  },
  methods: {
    hideModal(status) {
      this.modalUp = false;
      this.$emit("hide", status);
      this.removePreloader();
    },
    refreshNotes() {
      this.hideModal();
      this.$emit("refreshNotes");
      this.$emit("reload");
    },
  },
};
</script>

<style scoped>
.quill-editor {
  height: 100px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
