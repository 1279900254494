import { amgApi } from '@/service/axios'

class NotesService {
  async getListTypeGoal() {
    try {
      const { data } = await amgApi.get('/commons/get-list-type-goal')
      return data
    } catch (error) {
      throw error
    }
  }

  async insertFirstNote(params) {
    try {
      const { data } = await amgApi.post(
        '/note/first-note/insert-first-note',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getFirstNote(params) {
    try {
      const { data } = await amgApi.post(
        '/note/first-note/get-first-note',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async updateFirstNote(params) {
    try {
      const { data } = await amgApi.post(
        '/note/first-note/update-first-note',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async notesSales(params) {
    try {
      const { data } = await amgApi.post('/sales-made/get-sale-notes', params)
      return data
    } catch (error) {
      throw error
    }
  }

  async insertSaleNotes(params) {
    try {
      const { data } = await amgApi.post(
        '/sales-made/insert-sales-notes',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async updateClientType(params) {
    try {
      const { data } = await amgApi.post(
        '/note/first-note/update-client-type',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async insertClientType(params) {
    try {
      const { data } = await amgApi.post(
        '/sales-made/insert-client-type',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async validateDateAndHour(params) {
    try {
      const { data } = await amgApi.post(
        '/sales-made/validate-date-and-hour',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async validateDateAndHourRevision(params) {
    try {
      const data = await amgApi.post(
        '/sales-made/validate-date-and-hour-revission',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async updateDateAndHourRevision(params) {
    try {
      const data = await amgApi.post(
        '/sales-made/update-date-and-hour-revission',
        params,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getContactSchedule(params) {
    try {
      const data = await amgApi.post(
        '/note/schedule-client/get-schedule-client', params
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async addContactSchedule(params) {
    try {
      const data = await amgApi.post(
        '/note/schedule-client/add-schedule-client', params
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async duplicateContactSchedule(params) {
    try {
      const data = await amgApi.post(
        '/note/schedule-client/duplicate-contact-schedule', params
      )
      return data
    } catch (error) {
      throw error

    }
  }

  async deletionLogicalSchedule(params) {
    try {
      const data = await amgApi.post(
        '/note/schedule-client/deletion-logical-contact-schedule', params
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async deletionScheduleByDay(params) {
    try {
      const data = await amgApi.post(
        '/note/schedule-client/deletion-schedule-by-day', params
      )
    } catch (error) {

    }
  }

  async editSchedule(params) {
    try {
      const data = await amgApi.post(
        '/note/schedule-client/edit-contact-schedule', params
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getDsListCredits(params) {
    try {
      const data = await amgApi.post(
        '/sales-made/debt-solution/get-credits-debt-solution', params
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async getLeadNotes(payload) {
    try {
      const data = await amgApi.post('/note/index', payload);
      return data
    } catch (error) {
      throw error
    }
  }
}

export default new NotesService()
