var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"change-columns-by-client":"","start-page":_vm.startPage,"to-page":_vm.toPage,"double-paginate":false},on:{"reload":function($event){return _vm.$refs['smsHistory'].refresh()},"onChangeCurrentPage":function($event){return _vm.$refs['smsHistory'].refresh()},"reset-all-filters":function($event){return _vm.$refs['smsHistory'].refresh()}},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('div',{staticClass:"d-flex ml-2"},[_c('b-button',{attrs:{"disabled":_vm.isResponsibleLead,"variant":"success"},on:{"click":_vm.openModalSendSms}},[_vm._v("Send SMS")])],1)]},proxy:true}])},[_c('b-table',{ref:"smsHistory",class:_vm.isDarkSkin ? 'custom-table-dark' : 'custom-table',attrs:{"slot":"table","id":"smsHistory","items":_vm.myProvider,"fields":_vm.fields,"filter":_vm.filters,"table-class":"text-nowrap","responsive":"sm","no-border-collapse":"","sticky-header":"245px","show-empty":"","busy":_vm.isBusy,"no-provider-filtering":""},on:{"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(sent_by)",fn:function(data){return [_c('div',{staticClass:"w-100 custom-space d-flex justify-content-left align-items-center",class:_vm.isDarkSkin
              ? 'bg-dark'
              : 'bg-white border-top-light border-bottom-light border-left-light'},[_c('div',{staticClass:"w-100 m-0"},[_c('span',[_vm._v(_vm._s(data.item.sent_by))]),_c('br'),_c('strong',[_vm._v(_vm._s(_vm._f("myGlobal")(data.item.created_at)))])])])]}},{key:"cell(type)",fn:function(data){return [_c('div',{staticClass:"w-100 d-flex justify-content-left align-items-center custom-space",class:_vm.isDarkSkin
              ? 'bg-dark'
              : 'bg-white border-top-light border-bottom-light'},[_vm._v(" "+_vm._s(data.item.mass ? data.item.mass : "---")+" ")])]}},{key:"cell(line)",fn:function(data){return [_c('div',{staticClass:"w-100 d-flex justify-content-left align-items-center custom-space text-primary",class:_vm.isDarkSkin
              ? 'bg-dark'
              : 'bg-white border-top-light border-bottom-light'},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PhoneCallIcon"}}),_vm._v(" "+_vm._s(data.item.line ? data.item.line : "---")+" ")],1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"w-100 d-flex justify-content-center align-items-center custom-space",class:_vm.isDarkSkin
              ? 'bg-dark'
              : 'bg-white border-top-light border-bottom-light border-right-light'},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-primary cursor-pointer",attrs:{"icon":"SearchIcon","title":"Show Content","size":"17"},on:{"click":function($event){return _vm.openModalContent(
                data.item.mass,
                data.item.content,
                data.item.created_at,
                data.item.sent_by
              )}}})],1)]}}])})],1),(_vm.modalShow)?_c('modal-content',{attrs:{"list-content":_vm.listContent,"list-name":_vm.listName,"list-author":_vm.listAuthor,"list-date":_vm.listDate,"list-type":_vm.listType},on:{"close":function($event){_vm.modalShow = false}}}):_vm._e(),(_vm.modalSendSms)?_c('modal-send-sms',{attrs:{"smss":[],"typesms":1,"sms":[_vm.leadId],"name-leads":[
      {
        id: _vm.leadId,
        nick: _vm.leadData.nickname,
        name: _vm.leadData.lead_name,
      } ]},on:{"hide":function($event){_vm.modalSendSms = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }