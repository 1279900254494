export default [
  {
    path: "leads",
    name: "debtsolution-leads",
    redirect: { name: "debtsolution-leads-list" },
    component: () =>
      import(
        /* webpackChunkName: "DsLead" */ "@/views/commons/components/lead-programs/Lead.vue"
      ),
    meta: {
      route: "debtsolution",
      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Leads",
        },
      ],
    },
    children: [
      {
        path: "",
        name: "debtsolution-leads-list",
        component: () =>
          import(
            /* webpackChunkName: "DsLeadList" */ "@/views/commons/components/lead-programs/components/lead-table/LeadTable.vue"
          ),
        meta: {
          route: "debtsolution",
          isClientsTab: true,
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
              to: "/debtsolution/leads",
              active: true,
            },
          ],
        },
      },
    ],
  },

  {
    path: "leads/show/:id",
    name: "debtsolution-lead-show",
    component: () =>
      import(/* webpackChunkName: "DsLeadShow" */ "@/views/crm/views/Lead/lead-module/dashboard/default.vue"),
    props: true,
    meta: {
      isClientsTab: true,
      route: "debtsolution",
      contentClass: "leads-app",
    },
  },
  {
    path: "leads/report/:idlead/:idfile",
    name: "debtsolution-report-lead",
    component: () =>
      import(/* webpackChunkName: "DsLeadNCRReport" */ "@/views/commons/components/ncr/components/report/ReportLead.vue"),
    props: true,
    meta: {
      route: "debtsolution",
      isClientsTab: true,
      pendingRoute: "ncr-pending",
      returnedRoute: "ncr-returned",
      completedRoute: "ncr-completed",
      reportLeadRoute: "report-lead",
      leadRoute: "lead-show",
    },
  },
]
