import store from '@/store';
import Notifications from '@/service/notification/index';
import StatusChangeService from '@/views/crm/views/status-change/service/status-change.service';
import InitialPaymentService from '@/views/administration/views/initial-payment/service/initial-payment.service';
import NcrLeadsService from '@/views/administration/views/ncr-leads/services/NcrLeads.service.js';
import LettersService from '@/views/administration/views/letters/services/letters.service.js';
import ChargeBackService from '@/views/commons/components/charge-back/charge-back.service.js';
import poBoxService from '@/views/commons/components/file-po-box/services/file-po-box.service.js';
import schedulesService from '@/views/commons/components/schedules/services/schedules.service';
import employeeClaims from '@/views/commons/commons-counter';
import RequestService from '@/views/commons/components/request/services/request.service.js';
import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';
import salesMadeService from '../commons/components/sales-made/add-sale/service/sales-made-service';

const countStatusChange = async () => {
  const params = {
    moduleid: 4,
  };
  const data = await StatusChangeService.getStatusChangeCounter(params);
  const counter = await data[0].counter;
  if (data) {
    const payload = {
      routeName: 'administration-status-change',
      tag: counter > 99 ? '99+' : counter,
    };
    store.dispatch('StatusChangeStore/A_SET_COUNTERS_STATUS_CHANGE', data);
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const countInitialPayment = async () => {
  const params = {
    status: 1,
  };
  const data = await InitialPaymentService.initialPaymentCounter(params);
  const counter = await data.data;
  if (data) {
    const payload = {
      routeName: 'initial-payment-administration',
      tag: counter > 99 ? '99+' : counter,
    };
    store.dispatch('InitialPaymentStore/A_SET_COUNTERS_INITIAL_PAYMENT', data);
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const countChargeBack = async () => {
  const params = {
    modul: null,
  };
  const data = await ChargeBackService.getCountNotificationsChargeBack(params);
  if (data) {
    const counter = await data[0].cc;

    const payload = {
      routeName: 'administration-charge-back',
      tag: counter > 99 ? '99+' : counter,
    };
    store.dispatch('ChargeBackStore/A_SET_COUNTERS_CHARGE_BACK', data);
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const countPoBox = async () => {
  const { data } = await poBoxService.getPoBoxCounter();
  if (data) {
    const counter = await data[0].counter;
    const payload = {
      routeName: 'administration-po-box',
      tag: counter > 99 ? '99+' : counter,
    };
    store.dispatch('poBoxStore/A_SET_COUNTERS_PO_BOX', data);
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const countInProcessNcrLeads = async () => {
  const params = {
    user_id: store.state.auth.currentUser.user_id,
    modul: 4,
  };

  const { data } = await NcrLeadsService.ncrLeadsCountInProcess(params);
  const counter = await data[0].total;
  const payload = {
    routeName: 'administration-ncr-leads',
    tag: counter > 99 ? '99+' : counter,
  };
  store.dispatch('NcrLeadsStore/A_SET_COUNTER_NCR_LEADS', data[0]);
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};
const countInProcessLetters = async () => {
  const params = {
    id: store.state.auth.currentUser.user_id,
    type: 2,
    program: 3,
    role: store.state.auth.currentUser.role_id,
    modul: 4,
  };

  const { data, status } = await LettersService.getCounterLetter(params);
  if (status == 200) {
    if (data[0]) {
      const counter = data[0].cont;
      const payload = {
        routeName: 'administration-letters',
        tag: counter > 99 ? '99+' : counter,
      };
      store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
    }
  }
};
const countSchedules = async () => {
  const params = { moduleid: store.state.auth.currentUser.modul_id };
  const data = await schedulesService.getAllCounters(params);
  const payload = {
    routeName: 'schedules-administration',
    tag: data.data.grandTotal > 99 ? '99+' : data.data.grandTotal,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const countSalesMade = async () => {
  const data = await salesMadeService.getSalesMadeAdmCounter();
  if (data.status === 200) {
    const payload = {
      routeName: 'administration-sales-made',
      tag: data.data > 99 ? '99+' : data.data,
    };

    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: 'request-administration',
    tag: data[0].counter,
  };

  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'administration-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'administration-recruitment-process',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({ user_id, moduleId });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17 || role_id === 6)) {
    const payload = {
      routeName: 'administration-customer-tickets',
      tag: data[0].v_total_open,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const allAdministrationCounter = async () => {
  Promise.all([
    countStatusChange(),
    projectsCounter(),
    countInitialPayment(),
    countChargeBack(),
    countPoBox(),
    countInProcessNcrLeads(),
    countInProcessLetters(),
    countSchedules(),
    countSalesMade(),
    requestNotAsignedCounter(),
    employeeClaims('admin-employee-claims'),
    applicantsCounter(),
    getPendinCustomerTickets(),
  ]);
};

export default allAdministrationCounter;
