<template>
  <b-card class="lead-files-card">
    <b-card-title>
      <h4
        class="font-weight-bolder pb-1 border-bottom-secondary border-2 title-lead-files"
      >
        Files
      </h4>
    </b-card-title>
    <b-table
      v-if="S_FILES_LEADS.length"
      show-empty
      responsive="sm"
      :fields="fields"
      :items="S_FILES_LEADS"
      :busy.sync="isBusy"
      class="mb-0 files-grid diego-scrollbar"
      :class="isDarkSkin ? 'custom-table-dark' : 'custom-table'"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <template #cell(file_name)="data">
        <div
          style="min-width: 180px !important"
          class="w-100 d-flex custom-space"
          :class="
            isDarkSkin
              ? 'bg-custom-dark '
              : 'bg-white border-top-light border-bottom-light border-left-light'
          "
        >
          <a
            v-if="data.item.isDisabled"
            :href="data.item.route"
            target="_blank"
            :id="`file_${data.index}`"
            class="font-weight-bolder font-size-md"
          >
            <amg-icon
              :class="getIcon(data.item.extension).color"
              :icon="getIcon(data.item.extension).icon"
              size="17"
              class="mr-50"
            />
            {{
              data.item.file_name.length > maxLetter
                ? `...${data.item.file_name.substr(maxLetter * -1)}`
                : data.item.file_name
            }}
            <b-tooltip
              v-if="data.item.file_name.length > maxLetter"
              :target="`file_${data.index}`"
            >
              {{ data.item.file_name }}
            </b-tooltip>
          </a>
          <validation-observer v-else :ref="`refFormObserver${data.index}`">
            <validation-provider
              #default="validationContext"
              name="File Name"
              rules="required"
            >
              <b-input-group label-for="file-name" class="input-group-sm">
                <b-form-input
                  id="file-name"
                  :ref="`fileName${data.index}`"
                  v-model="data.item.custom_file_name"
                  :state="getValidationState(validationContext)"
                  @keyup.enter="onSubmit(data.index, data.item)"
                  @keyup.esc="data.item.isDisabled = true"
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-success"
                    @click="onSubmit(data.index, data.item)"
                  >
                    <feather-icon v-if="!isLoading" icon="CheckIcon" />
                    <b-spinner v-else small />
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-danger"
                    @click="data.item.isDisabled = true"
                  >
                    <feather-icon v-if="!isLoading" icon="XIcon" />
                    <b-spinner v-else small />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </validation-provider>
          </validation-observer>
        </div>
      </template>

      <template #cell(size)="data">
        <div
          class="w-100 custom-space"
          :class="
            isDarkSkin
              ? 'bg-custom-dark '
              : 'bg-white border-top-light border-bottom-light'
          "
        >
          {{ data.item.size | typeSize }}
        </div>
      </template>

      <template #cell(created_by)="data">
        <div
          style="min-width: 120px"
          class="w-100 custom-space"
          :class="{
            'bg-custom-dark ': isDarkSkin,
            'bg-white border-top-light border-bottom-light': !isDarkSkin,
            'border-right-light': !isDarkSkin && moduleId != 15,
          }"
        >
          <img
            :src="
              data.item.user_image
                ? data.item.user_image
                : require('@/assets/images/logo/amg_logo.svg')
            "
            class="custom-img mr-50"
            :style="!data.item.user_image ? 'opacity: 0.1' : null"
          />
          <div style="white-space: nowrap" class="font-size-md">
            {{ data.item.user_upload }}
            <br />
            <strong class="text-secondary">{{
              data.item.created_at | myGlobalWithHour
            }}</strong>
          </div>
        </div>
      </template>

      <template #cell(actions)="data">
        <div
          class="w-100 text-center custom-space"
          :class="{
            'bg-custom-dark ': isDarkSkin,
            'bg-white border-top-light border-bottom-light': !isDarkSkin,
            'border-right-light': !isDarkSkin && moduleId == 15,
          }"
        >
          <b-button
            variant="flat-warning"
            class="button-little-size rounded-circle"
            :disabled="isLoading"
            @click="
              data.item.isDisabled = !data.item.isDisabled;
              data.item.custom_file_name = data.item.file_name;
            "
          >
            <feather-icon v-if="!isLoading" icon="Edit2Icon" />
            <b-spinner v-else small />
          </b-button>
          <b-button
            variant="flat-danger"
            class="button-little-size rounded-circle ml-1"
            :disabled="isLoading"
            @click="onDeleteFile(data.item)"
          >
            <feather-icon v-if="!isLoading" icon="Trash2Icon" />
            <b-spinner v-else small />
          </b-button>
        </div>
      </template>
    </b-table>

    <div
      v-else
      class="custom-empty-content d-flex justify-content-center align-items-center position-relative rounded-lg"
      :class="isDarkSkin ? 'bg-custom-dark ' : null"
    >
      <img
        :src="require('@/assets/images/accounts/no-report.svg')"
        class="custom-img-empty"
        width="180px"
        height="180px"
      />
      <span class="mt-2 custom-message"> No Files</span>
    </div>

    <template v-if="moduleId === 15" #footer>
      <div class="text-right">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="$bvModal.show('modal-upload-file')"
        >
          <span class="align-middle">Upload File</span>
        </b-button>
      </div>
    </template>

    <b-modal
      v-if="moduleId === 15"
      id="modal-upload-file"
      title="Upload File"
      modal-class="modal-primary"
      button-size="sm"
      ok-title="Ok"
    >
      <drag-and-drop v-model="files" :filesArray="files" />
      <template #modal-footer>
        <b-button
          v-show="files.length"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="onUploadFile"
        >
          <feather-icon icon="UploadIcon" class="mr-50" />
          <span class="align-middle">Upload</span>
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";

import Ripple from "vue-ripple-directive";

import formValidation from "@core/comp-functions/forms/form-validation";

import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";

export default {
  components: {
    DragAndDrop,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      /* G_TEMPLATES: 'CrmTemplateStore/G_TEMPLATES' */
    }),
    ...mapState({
      S_FILES_LEADS: (event) => event.CrmLeadStore.S_FILES_LEADS,
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isResponsible() {
      return !(
        this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
          .is_responsible === this.currentUser.user_id ||
        this.$store.getters["NotificationStore/G_IS_RESPONSIBLE_CEO"]
          .is_responsible === null
      );
    },
  },
  created() {
    this.authUser = this.currentUser;
    this.getFilesLeads();
  },
  data() {
    return {
      authUser: {},
      fields: [
        { key: "file_name", label: "Name", tdClass: "py-50 px-0" },
        { key: "size", thClass: "text-left", tdClass: "py-50 px-0" },
        { key: "created_by", label: "upload by", tdClass: "py-50 px-0" },
        this.moduleId === 15 ? { key: "actions", tdClass: "py-50 px-0" } : null,
      ],
      files: [],
      isBusy: false,
      isLoading: false,
      maxLetter: 40,
    };
  },
  directives: { Ripple },
  methods: {
    ...mapActions({
      A_GET_FILES_LEADS: "CrmLeadStore/A_GET_FILES_LEADS",
      A_UPDATE_FILE_NAME_LEAD: "CrmLeadStore/A_UPDATE_FILE_NAME_LEAD",
      A_SET_FILE_LEAD: "CrmLeadStore/A_SET_FILE_LEAD",
      A_DELETE_FILES_LEADS: "CrmLeadStore/A_DELETE_FILES_LEADS",
    }),
    async getFilesLeads(orderby, order) {
      try {
        this.isBusy = true;
        await this.A_GET_FILES_LEADS({
          id_lead: this.lead.id,
          orderby: orderby || 2,
          order: order || "asc",
        });
        this.isBusy = false;
      } catch (error) {
        console.log("Something went wrong getFilesLeads", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
        this.isBusy = false;
      }
    },
    getIcon(extension) {
      switch (true) {
        case extension === "pdf":
          return { icon: "FilePdfIcon", color: "text-danger" };
        case ["ppt", "pptx"].includes(extension):
          return { icon: "FilePowerpointIcon", color: "text-warning" };
        case ["xlsx", "csv"].includes(extension):
          return { icon: "FileExcelIcon", color: "text-success" };
        case extension === "docx":
          return { icon: "FileWordIcon", color: "text-primary" };
        case ["png", "jpg", "jpeg", "ico"].includes(extension):
          return { icon: "FileCsvIcon", color: "text-info" };
      }
      return { icon: "FileChartIcon", color: "text-primary" };
    },
    async onSubmit(index, item) {
      if (await this.$refs[`refFormObserver${index}`].validate()) {
        this.showConfirmSwal()
          .then(async (result) => {
            if (result.value) {
              this.isLoading = true;

              const response = await this.A_UPDATE_FILE_NAME_LEAD({
                file_id: item.id,
                name_file: item.custom_file_name,
                user_id: this.authUser.user_id,
              });
              if (this.isResponseSuccess(response)) {
                this.S_FILES_LEADS[index].file_name = item.custom_file_name;
                this.S_FILES_LEADS[index].isDisabled = true;
                this.showToast(
                  "success",
                  "top-right",
                  "Success!",
                  "CheckIcon",
                  "Successful operation"
                );
              } else
                this.showToast(
                  "warning",
                  "top-right",
                  "Warning!",
                  "AlertTriangleIcon",
                  "Something went wrong.",
                  response.message
                );
              this.isLoading = false;
            }
          })
          .catch((error) => {
            console.log("Something went wrong onSubmit", error);
            this.showErrorSwal();
          });
      }
    },
    async onUploadFile() {
      try {
        this.addPreloader();
        const body = new FormData();
        this.files.forEach((file) => {
          body.append("images[]", file, file.name);
        });
        body.append("user_id", this.authUser.user_id);
        body.append("id_lead", this.lead.id);
        body.append("module_id", this.moduleId);
        const response = await this.A_SET_FILE_LEAD(body);
        if (this.isResponseSuccess(response)) {
          await this.getFilesLeads();
          this.files = [];
          this.$bvModal.hide("modal-upload-file");
          this.removePreloader();
          this.showToast(
            "success",
            "top-right",
            "Success!",
            "CheckIcon",
            "Successful operation"
          );
        } else
          this.showToast(
            "warning",
            "top-right",
            "Warning!",
            "AlertTriangleIcon",
            "Something went wrong.",
            response.message
          );
        this.removePreloader();
      } catch (error) {
        console.log("Something went wrong onUploadFile", error);
        this.showErrorSwal();
        this.removePreloader();
      }
    },
    onDeleteFile(item) {
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            this.addPreloader();
            const response = await this.A_DELETE_FILES_LEADS({
              file_id: item.id,
              user_id: this.authUser.user_id,
            });
            if (response)
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            else
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                "Something went wrong.",
                response.message
              );
            this.removePreloader();
          }
        })
        .catch((error) => {
          console.log("Something went wrong onDeleteFile", error);
          this.showErrorSwal();
          this.removePreloader();
        });
    },
  },
  mounted() {},
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { refFormObserver, getValidationState } = formValidation(() => {});

    return {
      refFormObserver,
      getValidationState,
    };
  },
  filters: {
    typeSize(value) {
      // actually save the file in Kb
      const gb = parseInt(value / 1024 ** 2);
      const mb = parseInt(value / 1024);

      let type = "";
      switch (true) {
        case gb > 0:
          type = "Gb";
          break;
        case mb > 0:
          type = "Mb";
          break;
        default:
          type = "Kb";
          break;
      }
      return `${value} ${type}`;
    },
  },
};
</script>
<style scoped>
.mh-5 {
  min-height: 500px;
}
.custom-table
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .dark-layout
  .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th {
  background-color: #3a72ea !important;
  color: rgba(17, 17, 19, 1);
  padding: 12px 10px !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  border: none !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:first-child {
  border-top-left-radius: 10px !important;
}
.custom-table >>> .table tr td,
.custom-table-dark >>> .table tr td {
  border: none !important;
}
.custom-table
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child,
.custom-table-dark
  >>> .table:not(.table-dark):not(.table-light)
  thead:not(.thead-dark)
  th:last-child {
  border-top-right-radius: 10px !important;
}
.custom-table >>> tbody tr td {
  border-style: none !important;
}
.custom-table >>> .table tr td:first-child,
.custom-table-dark >>> .table tr td:first-child {
  border-radius: 10px 0px 0px 10px !important;
}
.custom-table >>> .table tr td:last-child,
.custom-table-dark >>> .table tr td:last-child {
  border-radius: 0px 10px 10px 0px !important;
}
.custom-table >>> .table tr td .custom-space,
.custom-table-dark >>> .table tr td .custom-space {
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
}
.custom-table >>> .table tr td:first-child .custom-space,
.custom-table-dark >>> .table tr td:first-child .custom-space {
  border-radius: 10px 0px 0px 10px !important;
  padding-left: 20px !important;
}
.custom-table >>> .table tr td:last-child .custom-space,
.custom-table-dark >>> .table tr td:last-child .custom-space {
  border-radius: 0px 10px 10px 0px !important;
}
.b-table > tbody > tr {
  border: none !important;
}
.custom-img {
  width: 28px !important;
  height: 28px !important;
  border-radius: 50% !important;
}
.font-size-md {
  font-size: 13px;
}
.custom-img-empty {
  opacity: 0.1;
}
.custom-message {
  position: absolute;
  font-size: 25px;
  font-weight: bolder;
}
.custom-empty-content {
  height: 300px !important;
}
.bg-custom-dark {
  background: #111113;
}
.title-lead-files {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #111113;
}
</style>
<style lang="scss" scoped>
.dark-layout {
  .title-lead-files {
    color: #fafafa;
  }
}
.lead-files-card {
  max-height: 60vh !important;
  overflow-y: hidden;
  overflow-x: hidden;
  .files-grid {
    max-height: 50vh !important;
    overflow-y: auto;
    overflow-x: auto;
    padding-right: 10px;
  }
}
</style>
