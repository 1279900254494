export default [
  {
    path: "call-log",
    name: "call-log-section-claimdepartment",
    redirect: { name: "call-log-internal-claimdepartment" },
    component: () =>
      import(
        /* webpackChunkName: "Managment call-log" */
        "@/views/commons/components/ring-central/call-log/CallLog.vue"
      ),
    meta: {
      pageTitle: "Call log",
      permittedRoles: [1, 2, 17],
      breadcrumb: [
        {
          text: "call log",
          active: true,
        },
      ],
      callLogInternalRoute: 'call-log-internal-claimdepartment',
      callLogExternalRoute: 'call-log-external-claimdepartment',
      callLogOthersRoute: 'call-log-others-claimdepartment'

    },
    children: [
      {
        path: "internal",
        name: "call-log-internal-claimdepartment",
        component: () =>
          import(
            /* webpackChunkName: "call-log-all" */
            "@/views/commons/components/ring-central/call-log/components/ListCallLog.vue"
          ),
        meta: {
          pageTitle: "Call log",
          permittedRoles: [1, 2, 17],
          breadcrumb: [
            {
              text: "Internal",
              active: true,
            },
          ],
          status: 1,
        },
      },
      {
        path: "external",
        name: "call-log-external-claimdepartment",
        redirect: { name: "call-log-external-inbound-claimdepartment" },
        component: () =>
          import(
            /* webpackChunkName: "call-log-all" */
            "@/views/commons/components/ring-central/call-log/tabs/CallLogTabs.vue"
          ),
        meta: {
          pageTitle: "Call log",
          permittedRoles: [1, 2, 17],
          breadcrumb: [
            {
              text: "External",
              active: true,
            },
          ],
          callLogExternalInboundRoute: 'call-log-external-inbound-claimdepartment',
          callLogExternalOutboundRoute: 'call-log-external-outbound-claimdepartment'
        },

        children: [
          {
            path: "inbound",
            name: "call-log-external-inbound-claimdepartment",
            component: () => import(
              /* webpackChunkName: "call-log-all" */
              "@/views/commons/components/ring-central/call-log/components/ListCallLog.vue"
            ),
            meta: {
              pageTitle: "Call log",
              permittedRoles: [1, 2, 17],
              breadcrumb: [
                {
                  text: "Inbound",
                  active: true,
                },
              ],
              status: 2,
            },
          },
          {
            path: "outbound",
            name: "call-log-external-outbound-claimdepartment",
            component: () => import(
              /* webpackChunkName: "call-log-all" */
              "@/views/commons/components/ring-central/call-log/components/ListCallLog.vue"
            ),
            meta: {
              pageTitle: "Call log",
              permittedRoles: [1, 2, 17],
              breadcrumb: [
                {
                  text: "Outbound",
                  active: true,
                },
              ],
              status: 3,
            },
          }
        ]
      },
      {
        path: "others",
        name: "call-log-others-claimdepartment",
        component: () =>
          import(
            /* webpackChunkName: "call-log-all" */
            "@/views/commons/components/ring-central/call-log/components/ListCallLog.vue"
          ),
        meta: {
          pageTitle: "Call log",
          permittedRoles: [1, 2, 17],
          breadcrumb: [
            {
              text: "External",
              active: true,
            },
          ],
          status: 4,
        },
      },
    ],
  },
]
