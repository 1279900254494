export default [
    {
        path: 'qr-generator',
        name: 'qr-generator-rrhh',
        component: () => import(/* webpackChunkName: "RRHHQrGenerator" */ '@/views/assistance/views/qr-generator/QrGenerator.vue'),
        meta: {
            pageTitle: 'QR Generator',
            breadcrumb: [{
                text: 'QR Generator',
            }],
        },
    },
]