<template>
  <div>
    <div>
      <filter-slot
        :total-rows="totalRowsSearch"
        :paginate="paginateSearch"
        :start-page="startPageSearch"
        :to-page="toPageSearch"
        :filter="[]"
        :filter-principal="filterPrincipal"
        :double-paginate="false"
        @onChangeCurrentPage="searchLeadsMessages()"
        @reload="searchLeadsMessages()"
        :noVisiblePrincipalFilter="true"
      >
        <template #buttons-filter>
          <div class="d-flex align-items-center w-100">
            <b-input-group class="mr-1">
              <b-form-input
                v-if="filterPrincipal.type === 'input'"
                v-model="filterPrincipal.model"
                :type="filterPrincipal.inputType"
                :placeholder="filterPrincipal.placeholder"
                @keyup.enter="searchLeadsMessages()"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="searchLeadsMessages()">
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button variant="primary" v-b-toggle.sidebar-right-leads-search>
              <div class="d-flex justify-content-between">
                <feather-icon icon="FilterIcon" size="15" />
              </div>
            </b-button>
          </div>
        </template>

        <template #table>
          <div class="w-100 d-flex">
            <b-button
              v-if="listSearchLeads.length != 0"
              variant="outline-success"
              @click="saveLeads(1)"
              class="ml-2"
              :disabled="auxListLeads.length == 0"
            >
              <feather-icon icon="SaveIcon" />
              Save ({{ auxListLeads.length }})
            </b-button>

            <b-button
              class="ml-2"
              variant="outline-primary"
              @click="saveLeads(2)"
              v-if="totalRowsSearch > 0"
            >
              <feather-icon icon="SaveIcon" />
              Select All ({{ totalRowsSearch }})</b-button
            >

            <div
              style="
                padding: 5px;
                border-radius: 0.358rem;
                border: 1px solid #8f5fe8 !important;
              "
              class="ml-3 d-flex align-items-center justify-content-center cursor-pointer text-info"
              @click="showDetail = true"
            >
              <feather-icon icon="UserIcon" style="margin-right: 3px" />
              Leads Added ({{ G_CURRENT_TOPIC_LEADS_COUNT }})
            </div>
          </div>

          <div
            class="px-2 list-border-top sms-topic-list"
            v-if="!loadingSearch"
          >
            <b-row
              v-if="listSearchLeads.length > 0"
              class="container-leads pb-1"
            >
              <b-col
                class="mt-1"
                lg="3"
                md="4"
                sm="6"
                cols="12"
                v-for="element in listSearchLeads"
                :key="element.id"
              >
                <div
                  class="lead-card-item border"
                  @click="selectElement(element.id, element.client_account)"
                >
                  <div class="w-100">
                    <div class="text-truncate">
                      <b-badge
                        :variant="
                          element.account ? 'light-success' : 'light-warning'
                        "
                        >{{
                          element.name != ""
                            ? element.name
                            : element.nickname | limitChars(30)
                        }}</b-badge
                      >
                    </div>
                    <div>
                      <b-badge variant="light-primary">{{
                        element.account
                      }}</b-badge>
                    </div>
                    <div>
                      {{ element.mobile }}
                    </div>
                  </div>
                  <feather-icon
                    icon="CircleIcon"
                    class="icon-selected"
                    size="20"
                    v-if="validateIfExists(element.id)"
                  >
                  </feather-icon>
                  <feather-icon
                    icon="CheckIcon"
                    class="text-success"
                    size="20"
                    v-else
                  >
                  </feather-icon>
                </div>
              </b-col>
            </b-row>
            <div class="text-center mt-2" v-else>
              There are no records matching your request
            </div>
          </div>

          <div v-else class="px-2 list-border-top">
            <b-row v-if="listSearchLeads.length > 0">
              <b-col
                class="mt-1"
                lg="3"
                md="4"
                sm="6"
                cols="12"
                v-for="n in Array.from({ length: 30 }, (_, i) => i + 1)"
                v-bind:key="n"
              >
                <b-skeleton height="40px"></b-skeleton>
              </b-col>
            </b-row>
          </div>
        </template>
      </filter-slot>
    </div>

    <b-sidebar
      id="sidebar-right-leads-search"
      bg-variant="white"
      text-variant="light"
      right
      width="25%"
      shadow
      title="Advanced Search"
    >
      <div class="mx-2 mt-2">
        <b-row>
          <b-col cols="6">
            <b-form-group label="From: ">
              <b-form-datepicker
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                v-model="fromDate"
                locale="en"
                placeholder="Date"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="To: ">
              <b-form-datepicker
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                v-model="toDate"
                locale="en"
                placeholder="Date"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <!-- LEADS -->
      <div class="mx-2">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Status Lead: ">
              <v-select
                v-if="moduleId == 2"
                size="sm"
                v-model="statusLead"
                :options="statusLeadOptions"
                label="name"
              />

              <v-select
                v-if="moduleId == 15"
                size="sm"
                v-model="statusLeadMM"
                :options="leadStatusMM"
                label="name"
              />
            </b-form-group>
          </b-col>

          <b-col :cols="appointment == 1 ? '6' : '12'">
            <b-form-group label="Appointment: ">
              <v-select
                v-model="appointment"
                :options="[
                  { id: 1, value: 'YES' },
                  { id: 2, value: 'NO' },
                ]"
                label="value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" v-if="appointment && appointment.id == 1">
            <b-form-group label="Answer: ">
              <v-select
                v-model="appointment_attend"
                :options="[
                  { id: 1, value: 'YES' },
                  { id: 0, value: 'NO' },
                ]"
                label="value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="CR: ">
              <v-select
                v-model="leadCr"
                :options="crLeadOptions"
                label="value"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group label="Services: ">
              <v-select
                size="sm"
                v-model="programsLead"
                :options="programsLeadOptions"
                label="name"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group :label="moduleId == 15 ? 'ST/AD' : 'State:'">
              <v-select
                v-model="stateUsa"
                :options="statesUsaOptions"
                label="slug"
              /> </b-form-group
          ></b-col>

          <b-col cols="6">
            <b-form-group label="Document Type: ">
              <v-select
                size="sm"
                v-model="typeDocLead"
                :options="G_TYPE_DOCS"
                label="label"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" v-if="moduleId == 15">
            <b-form-group label="Potential: ">
              <v-select
                size="sm"
                v-model="leadPotential"
                :options="[
                  { value: null, label: 'ALL' },
                  { value: 1, label: 'YES' },
                  { value: 2, label: 'NO' },
                ]"
                label="label"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6" v-if="moduleId == 15">
            <b-form-group label="Owner: ">
              <v-select
                size="sm"
                v-model="leadOwner"
                :options="ownerLeadOptions"
                label="user_name"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <template #footer>
        <div class="d-flex justify-content-end mb-1 mr-1">
          <div class="d-flex">
            <b-button variant="info" class="mr-1" @click="resetAllFilters"
              >Reset</b-button
            >
            <b-button variant="primary" @click="searchLeadsMessages"
              >Search</b-button
            >
          </div>
        </div>
      </template>
    </b-sidebar>

    <detail-leads-add
      v-if="showDetail"
      :list-id="listId"
      @hidden="showDetail = false"
      @updateList="updateLeadList()"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import GlobalStoreService from "@/service/global/index.js";

import MigrationService from "@/views/social-network/views/migrations/migrations.service.js";
import ClientService from "@/views/commons/components/clients/services/clients.services.js";

import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";

// components
import UserGroupIcon from "@/views/debt-solution/views/dashboard/icons/UserGroupIcon.vue";
import HandsIcon from "@/views/debt-solution/views/dashboard/icons/HandsIcon.vue";
import DetailLeadsAdd from "@/views/commons/components/ring-central/chat-sms/components/utils/ModalDetailLeadsAdd.vue";

// data
import StatusLeadOptions from "@/views/commons/components/ring-central/mass-messages/data/LeadStatus.data.js";
import CreditReportOptions from "@/views/commons/components/ring-central/mass-messages/data/CreditReport.data.js";

export default {
  components: {
    UserGroupIcon,
    HandsIcon,
    DetailLeadsAdd,
  },
  props: {
    listId: {
      type: [Number, String],
      required: true,
    },
    moduleId: {
      type: [Number, String],
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_TYPE_DOCS: "CrmGlobalStore/G_TYPE_DOCS",
      G_CURRENT_TOPIC: "SmsInboxStore/G_CURRENT_TOPIC",

      G_CURRENT_TOPIC_LEADS_COUNT: "SmsInboxStore/G_CURRENT_TOPIC_LEADS_COUNT",
    }),

    titleModal() {
      return "SEARCH LEADS";
    },
  },
  data() {
    return {
      showDetail: false,
      loadingInfo: false,

      listSearchLeads: [],
      auxListLeads: [],

      loadingSearch: true,

      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by Name",
        model: "",
      },
      totalRowsSearch: 0,
      paginateSearch: {
        currentPage: 1,
        perPage: 50,
      },
      startPageSearch: 0,
      toPageSearch: 0,

      statesUsaOptions: [],
      stateUsa: null,

      // Lead
      statusLeadOptions: StatusLeadOptions,
      statusLead: null,
      statusLeadMM: null,
      crLeadOptions: CreditReportOptions,
      leadCr: null,
      ownerLeadOptions: [],
      leadOwner: null,
      advisorsOptions: [],
      advisorClient: null,
      programsLeadOptions: [],
      programsLead: null,

      leadStatusMM: [],

      typeDocLead: null,
      statesEuLead: null,
      leadPotential: null,
      hasApp: null,
      usaStateCe: null,
      appointment: null,
      appointment_attend: null,

      // general
      fromDate: null,
      toDate: null,
      typeDocOptions: [
        { value: "0", label: "ALL" },
        { value: "1", label: "SSN" },
        { value: "2", label: "ITIN" },
        { value: "3", label: "CPN" },
        { value: "4", label: "NONE" },
      ],
    };
  },

  methods: {
    ...mapMutations({
      M_UPDATE_CURRENT_TOPIC: "SmsInboxStore/M_UPDATE_CURRENT_TOPIC",
      M_UPDATE_TOPIC_BY_ID: "SmsInboxStore/M_UPDATE_TOPIC_BY_ID",
    }),

    ...mapActions({
      A_GET_CURRENT_TOPIC_LEADS_COUNT:
        "SmsInboxStore/A_GET_CURRENT_TOPIC_LEADS_COUNT",
    }),

    async getLeadsStatusMm() {
      const { data } = await MigrationService.getStatusLead();
      this.statusLeadOptions = data;
    },
    async getAdvisors() {
      let data;
      if (this.moduleId == 11) {
        data = await GlobalStoreService.getSellers(11, {
          type: 1,
          roles: "[]",
        });
        this.advisorsOptions = data.data;
      } else {
        data = await ClientService.usersPrograms({
          idmodule: this.moduleId,
          idrole: this.currentUser.role_id,
          iduser: this.currentUser.user_id,
        });
        this.advisorsOptions = data;
      }
    },
    async getStatesEU() {
      const { data } = await GlobalStoreService.getStatesEEuu();
      this.statesUsaOptions = data;
    },

    async getOwners() {
      const { data } = await GlobalStoreService.getOwners({
        modul: this.moduleId,
        body: { roles: "[]", type: "1" },
      });
      this.ownerLeadOptions = data;
    },

    async getPrograms() {
      const { data } = await GlobalStoreService.getPrograms({});
      this.programsLeadOptions = data;
    },

    async searchLeadsMessages() {
      if (!this.loadingInfo) return;

      this.loadingSearch = true;
      this.addPreloader();

      try {
        const params = {
          // general
          topic_id: this.listId,
          module_id: this.moduleId,
          search_txt: this.filterPrincipal.model,
          from_date: this.fromDate,
          to_date: this.toDate,
          per_page: this.paginateSearch.perPage,
          page: this.paginateSearch.currentPage,

          state_usa: this.stateUsa ? this.stateUsa.slug : null,
          role_id: this.currentUser.role_id,

          // lead
          lead_status: this.statusLead ? this.statusLead.id : null,
          lead_status_mm: this.statusLeadMM ? this.statusLeadMM.id : null,
          lead_owner: this.leadOwner ? this.leadOwner.id : null,

          lead_cr: this.leadCr ? this.leadCr.id : null,
          // program: filterPrograms,
          program: this.programsLead ? this.programsLead.id : null,
          type_doc: this.typeDocLead ? this.typeDocLead.value : null,
          lead_potential: this.leadPotential ? this.leadPotential.value : null,
          appointment: this.appointment ? this.appointment.id : null,
          appointment_attend: this.appointment_attend
            ? this.appointment_attend.id
            : null,
        };

        const { data } = await SmsService.getLeadsList(params);

        this.startPageSearch = data.from ? data.from : 0;
        this.totalRowsSearch = data.total;
        this.toPageSearch = data.to ? data.to : 0;
        this.listSearchLeads = data.data;
        this.loadingSearch = false;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    selectElement(id, client_account) {
      if (this.readOnly) return;

      const index = this.auxListLeads.findIndex((lead) => lead.id == id);

      if (index !== -1) {
        this.auxListLeads.splice(index, 1);
      } else {
        this.auxListLeads.push({ id, client_account });
      }
    },

    validateIfExists(id) {
      const resp = this.auxListLeads.find((element) => element.id == id);
      return resp == undefined;
    },

    async saveLeads(option) {
      if (option == 1 && this.auxListLeads.length == 0) {
        this.showInfoSwal(
          "There are no leads",
          "Please select a list of the leads"
        );
        return;
      }

      if (option == 2) {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }
      }

      // let filterPrograms =
      //   this.programsLead != null ? this.programsLead.toString() : "";

      try {
        this.addPreloader();
        const filters = this.generateFilters();

        const params = {
          filters: filters,

          topic_id: this.listId,
          list_ids: this.auxListLeads,
          user_id: this.currentUser.user_id,
          option_save: option,

          module_id: this.moduleId,
          per_page: this.paginateSearch.perPage,
          page: this.paginateSearch.currentPage,
          role_id: this.currentUser.role_id,

          search_txt: this.filterPrincipal.model,
          from_date: this.fromDate,
          to_date: this.toDate,
          state_usa: this.stateUsa ? this.stateUsa.slug : null,
          lead_status: this.statusLead ? this.statusLead.id : null,
          lead_status_mm: this.statusLeadMM ? this.statusLeadMM.id : null,
          lead_owner: this.leadOwner ? this.leadOwner.id : null,
          lead_cr: this.leadCr ? this.leadCr.id : null,
          program: this.programsLead ? this.programsLead.id : null,
          type_doc: this.typeDocLead ? this.typeDocLead.value : null,
          lead_potential: this.leadPotential ? this.leadPotential.value : null,
          appointment: this.appointment ? this.appointment.id : null,
          appointment_attend: this.appointment_attend
            ? this.appointment_attend.id
            : null,
        };

        const { data, status } = await SmsService.saveLeads(params);
        if (status == 200) {
          this.A_GET_CURRENT_TOPIC_LEADS_COUNT({
            topic_id: this.listId,
          });

          this.showSuccessSwal();
          this.auxListLeads = [];
          this.searchLeadsMessages();
          if (this.G_CURRENT_TOPIC && this.G_CURRENT_TOPIC.id == this.listId) {
            this.M_UPDATE_CURRENT_TOPIC(data);
          }
          this.M_UPDATE_TOPIC_BY_ID({
            topicId: this.listId,
            column: "totalLeads",
            value: data,
          });
          this.$emit("wasSaved");
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    async getListInfo() {
      const { data } = await SmsService.getRcList(this.listId);
      const { filters } = data.data;
      if (filters) {
        this.setFilters(filters);
      }
      this.loadingInfo = true;
      this.searchLeadsMessages();
    },

    resetAllFilters() {
      this.statusLead = null;
      this.statusLeadMM = null;
      this.leadOwner = null;

      this.leadCr = null;
      this.advisorClient = null;
      this.programsLead = null;
      this.stateUsa = null;
      this.typeDocLead = null;
      this.leadPotential = null;
      this.filterPrincipal.model = null;

      this.appointment = null;
      this.appointment_attend = null;

      this.fromDate = null;
      this.toDate = null;
    },

    async getLeadsStatusMM() {
      const { data } = await MigrationService.getStatusLead();
      this.leadStatusMM = data;
    },

    updateLeadList() {
      this.A_GET_CURRENT_TOPIC_LEADS_COUNT({
        topic_id: this.listId,
      });
      this.searchLeadsMessages();
      this.showDetail = false;
    },

    setFilters(filters) {
      filters.forEach((element) => {
        switch (element.name) {
          case "filterPrincipal.model":
            this.filterPrincipal.model = element.value;
            break;
          case "fromDate":
            this.fromDate = element.value;
            break;
          case "toDate":
            this.toDate = element.value;
            break;
          case "statusLead":
            this.statusLead = element.value;
            break;
          case "statusLeadMM":
            this.statusLeadMM = element.value;
            break;
          case "appointment":
            this.appointment = element.value;
            break;
          case "appointment_attend":
            this.appointment_attend = element.value;
            break;
          case "leadCr":
            this.leadCr = element.value;
            break;
          case "programsLead":
            this.programsLead = element.value;
            break;
          case "stateUsa":
            this.stateUsa = element.value;
            break;
          case "typeDocLead":
            this.typeDocLead = element.value;
            break;
          case "leadOwner":
            this.leadOwner = element.value;
            break;
          case "leadPotential":
            this.leadPotential = element.value;
            break;

          default:
            break;
        }
      });
    },

    generateFilters() {
      let newArrayFilters = [];

      if (this.filterPrincipal.model) {
        newArrayFilters.push({
          name: "filterPrincipal.model",
          value: this.filterPrincipal.model,
          value_alias: this.filterPrincipal.model,
          label: "Search By",
        });
      }

      if (this.fromDate) {
        newArrayFilters.push({
          name: "fromDate",
          value: this.fromDate,
          value_alias: this.fromDate,
          label: "From Date",
        });
      }

      if (this.toDate) {
        newArrayFilters.push({
          name: "toDate",
          value: this.toDate,
          value_alias: this.toDate,
          label: "To Date",
        });
      }

      if (this.statusLead) {
        newArrayFilters.push({
          name: "statusLead",
          value: this.statusLead,
          value_alias: this.statusLead.name,
          label: "Status Lead",
        });
      }

      if (this.statusLeadMM) {
        newArrayFilters.push({
          name: "statusLeadMM",
          value: this.statusLeadMM,
          value_alias: this.statusLeadMM.name,
          label: "Status Lead",
        });
      }

      if (this.appointment) {
        newArrayFilters.push({
          name: "appointment",
          value: this.appointment,
          value_alias: this.appointment.value,
          label: "Appointment",
        });
      }

      if (this.appointment_attend) {
        newArrayFilters.push({
          name: "appointment_attend",
          value: this.appointment_attend,
          value_alias: this.appointment_attend.value,
          label: "Appointment Answer",
        });
      }

      if (this.leadCr) {
        newArrayFilters.push({
          name: "leadCr",
          value: this.leadCr,
          value_alias: this.leadCr.value,
          label: "CR",
        });
      }

      if (this.programsLead) {
        newArrayFilters.push({
          name: "programsLead",
          value: this.programsLead,
          value_alias: this.programsLead.name,
          label: "Service",
        });
      }

      if (this.stateUsa) {
        newArrayFilters.push({
          name: "stateUsa",
          value: this.stateUsa,
          value_alias: this.stateUsa.slug,
          label: this.moduleId == 15 ? "ST/AD" : "State",
        });
      }

      if (this.typeDocLead) {
        newArrayFilters.push({
          name: "typeDocLead",
          value: this.typeDocLead,
          value_alias: this.typeDocLead.label,
          label: "Document Type",
        });
      }

      if (this.leadOwner) {
        newArrayFilters.push({
          name: "leadOwner",
          value: this.leadOwner,
          value_alias: this.leadOwner.user_name,
          label: "Owner",
        });
      }

      if (this.leadPotential) {
        newArrayFilters.push({
          name: "leadPotential",
          value: this.leadPotential,
          value_alias: this.leadPotential.label,
          label: "Potential",
        });
      }

      return newArrayFilters;
    },
  },

  async created() {
    await this.getListInfo();
    this.getAdvisors();
    this.getStatesEU();

    this.getOwners();
    this.getPrograms();
  },

  mounted() {
    if (this.moduleId == 15) {
      this.getLeadsStatusMM();
    }
  },
};
</script>

<style lang="scss">
.sms-topic-list {
  .table-responsive {
    overflow-x: initial;
  }

  .container-leads {
    border: 1px solid #1d1d20;
    margin: 20px 0;
    border-radius: 10px;
  }

  .button-icon-ms,
  .lead-card-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    background-color: #f3f2f7;
    color: #656565;
  }

  .button-icon-ms {
    flex-direction: column;
    justify-content: center;
    padding: 10px 15px;
  }

  .lead-card-item {
    padding: 10px;
    justify-content: space-between;
    height: 100%;

    .icon-selected {
      color: #323232;
    }
  }

  .lead-card-item:hover,
  .button-icon-ms:hover {
    background-color: #cfefff;
  }

  .lead-card-item-active,
  .button-icon-ms-active {
    background-color: #cfefff;
  }

  .count-item {
    font-size: 12px;
  }
}

.dark-layout {
  .sms-topic-list {
    .container-leads {
      border: 1px solid #4b4b4b;
    }

    .lead-card-item,
    .button-icon-ms {
      background-color: #1d1d20;
      color: #ffffff;

      .icon-selected {
        color: #ffffff;
      }
    }

    .lead-card-item:hover,
    .button-icon-ms:hover {
      background-color: #323232;
      border: 1px solid #323232;
    }

    .lead-card-item-active,
    .button-icon-ms-active {
      background-color: #323232;
      border: 1px solid #323232;
    }

    .list-border-top {
      border-top: 1px solid #323232;
    }
  }
}
</style> 