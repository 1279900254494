import ClientsRouter from "@/views/specialist-digital/views/clients/clients.router";
import PreocessCRRouter from "@/views/specialist-digital/views/process-cr/process-cr.router";
import SpecialistsDashboardRouter from "@/views/specialist-digital/views/dashboard/specialists-dashboard.router";
import ApplicationsRouter from "@/views/specialist-digital/views/applications/applications.router";
import RealtorCrRouter from "@/views/specialist-digital/views/cr-realtor/cr-realtor.router.js";
import LettersRoundRouter from "@/views/specialist-digital/views/letters-round/letters-round.router";
import DirectsRoundRouter from "@/views/specialist-digital/views/directs-round/directs-round.router";
import UpdatesRouter from "@/views/specialist-digital/views/updates/updates.router";
import ConnectionClientsRouter from "@/views/specialist-digital/views/connection-clients/router/connection-clients.router.js";
import SpDigitalAttendanceRouter from "@/views/specialist-digital/views/attendance/sp-digital-attendance.router";
import EmployeeClaimsRouter from "@/views/specialist-digital/views/employee-claims/router/employee-claims.router";
import SpecialistDigitalMain from "@/views/specialist-digital/views/SpecialistDigitalMain.vue";
import RequestRouter from "@/views/specialist-digital/views/request/request.router.js";
import specialistDigitalProjectsRouter from "@/views/specialist-digital/views/projects/specialist-digital-projects.router";
import CallLogRouter from "@/views/specialist-digital/views/ring-central/call-log/call-log.router.js";
import MOFRouter from '@/views/specialist-digital/views/manual-of-functions/manual-of-functions.router.js';
import RecruitmentProcessRouter from '@/views/specialist-digital/views/recruitment-process/specialist-digital-recruitment_process.router';
import requestNcrRouter from "@/views/specialist-digital/views/request-ncr/request-ncr.router.js";
import leadsRouter from "@/views/specialist-digital/views/leads/leads.router.js";
import CourtInfoRouter from "@/views/specialist-digital/views/cour-info/router/ds-court-info.router";
import DashboardCalls from "@/views/specialist-digital/views/ring-central/dashboard-calls/dashboard.router.js";

import CustomerTicketsRouter from '@/views/specialist-digital/views/customer-tickets/specialist-digital-customer-tickets.router';

const routes = [
  {
    path: "/specialistdigital",
    redirect: { name: "specialists-digital-dashboard" },
    meta: {
      module: 25,
    },
    component: SpecialistDigitalMain,
    children: [
      ...ClientsRouter,
      ...ConnectionClientsRouter,
      ...ApplicationsRouter,
      ...RealtorCrRouter,
      ...LettersRoundRouter,
      ...DirectsRoundRouter,
      ...PreocessCRRouter,
      ...SpecialistsDashboardRouter,
      ...UpdatesRouter,
      ...SpDigitalAttendanceRouter,
      ...specialistDigitalProjectsRouter,
      ...EmployeeClaimsRouter,
      ...RequestRouter,
      ...CallLogRouter,
      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...requestNcrRouter,
      ...leadsRouter,
      ...CourtInfoRouter,
      ...DashboardCalls,
      ...CustomerTicketsRouter,
    ],
  },
];

export default routes;
