import store from "@/store"; // To get user(s)
import Vue from "vue"; // To get the 'component' of 'Notification';

const notificationRequestRefundVoidToManagement = () => {
  window.socket.bind("notification-refund-void-to-management", async (data) => {
    const userModule = store.state.auth.currentUser.main_module;

    // show alert to management
    if (userModule === 16) {
      await Vue.prototype.$amgAlert({
        title: "REQUEST",
        icon: "ArrowRightCircleIcon",
        subtitle: `REQUEST OF '${data.type_request}'`,
        data: [
          {
            icon: `${data.type_request === "VOID" ? "CoinIcon" : "ClockIcon"}`,
            title: "Type",
            description: data.type_request,
          },
          {
            icon: "GridIcon",
            title: "Departament",
            description: data.department,
          },
          {
            icon: "UserIcon",
            title: "Requested by",
            description: data.user_name,
          },
        ],
        ok: async () => {
          await window.amgApi.post("/commons/close-all-swal", data);
        },
      });
    }
  });
};

export default notificationRequestRefundVoidToManagement;
