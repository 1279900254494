<template>
  <div>
    <b-modal
      ref="modalExpense"
      modal-class="modal-primary"
      title-class="h3 text-white"
      :title="titleModal"
      size="lg"
      @hidden="closeModal()"
      centered
      :ok-title="titleBtn"
      no-close-on-backdrop
      ok-variant="info"
      body-class="modal-expenses"
      cancel-title="CANCEL"
      @ok="handleOk"
    >
      <ValidationObserver ref="formExpenseValidate">
        <CreditCardForm
          :disabledType="disabledType"
          :formExpense="formExpense"
          :programIdClient="programIdClient"
          :typeExpenses="typeExpenses"
          :methodScope="methodScope"
          :typeScope="typeScope"
          :dataCardsExpense="dataCardsExpense"
          v-if="formExpense.method === 'CREDIT_CARD'"
          @removeFile="removeFile"
          @previewPdf="previewPdf"
        >
          <template #selectors-general>
            <SelectorsForm :formExpense="formExpense" :item="item" />
          </template>
          <template #transaction-inputs>
            <TransactionInputs
              :inDashboardClient="inDashboardClient"
              isCreditCard
              :programIdClient="programIdClient"
              :vmoneyValidate="vmoneyValidate"
              :formExpense="formExpense"
              :account_number="account_number"
            />
          </template>
        </CreditCardForm>
        <CheckForm
          v-if="formExpense.method === 'CHECK'"
          :formExpense.sync="formExpense"
          :disabledType="disabledType"
          :programIdClient="programIdClient"
          :methodScope="methodScope"
          :typeScope="typeScope"
          @removeFile="removeFile"
          @previewPdf="previewPdf"
        >
          <template #selectors-general>
            <SelectorsForm :formExpense="formExpense" isCheck />
          </template>
          <template #transaction-inputs>
            <TransactionInputs
              :inDashboardClient="inDashboardClient"
              isCheck
              :programIdClient="programIdClient"
              :vmoneyValidate="vmoneyValidate"
              :formExpense.sync="formExpense"
              :account_number="account_number"
            />
          </template>
        </CheckForm>
        <ECheckForm
          :disabledType="disabledType"
          :formExpense.sync="formExpense"
          :methodScope="methodScope"
          :typeScope="typeScope"
          :programIdClient="programIdClient"
          @removeFile="removeFile"
          @previewPdf="previewPdf"
          v-if="formExpense.method === 'E-CHECK'"
        >
          <template #selectors-general>
            <SelectorsForm :formExpense="formExpense" />
          </template>
          <template #transaction-inputs>
            <TransactionInputs
              :inDashboardClient="inDashboardClient"
              isECheck
              :programIdClient="programIdClient"
              :vmoneyValidate="vmoneyValidate"
              :formExpense.sync="formExpense"
              :account_number="account_number"
            />
          </template>
        </ECheckForm>
      </ValidationObserver>
    </b-modal>

    <preview-pdf
      v-if="previewUrl"
      :pdf-url="previewUrl"
      :pdf-name="pdfName"
      @close="clearPreview"
    />
  </div>
</template>
<script>
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";
import { mapGetters, mapActions } from "vuex";
import PreviewPdf from "@/views/debt-solution/views/court-info/components/PreviewPdf.vue";
import CreditCardForm from "@/views/commons/expenses/components/Modal/forms/CreditCardForm.vue";
import SelectorsForm from "@/views/commons/expenses/components/Modal/forms/SelectorsForm.vue";
import TransactionInputs from "@/views/commons/expenses/components/Modal/forms/TransactionInputs.vue";
import CheckForm from "@/views/commons/expenses/components/Modal/forms/CheckForm.vue";
import ECheckForm from "@/views/commons/expenses/components/Modal/forms/ECheckForm.vue";

export default {
  name: "AddOrEditExpense",
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    account_number: {
      type: String,
      default: () => null,
    },
    programIdClient: {
      type: Number,
      default: () => null,
    },
    tab: {
      type: String,
      default: "CREDIT_CARD",
    },
    hasCheck: {
      type: Boolean,
      default: false,
    },
    hasCreditCard: {
      type: Boolean,
      default: false,
    },
    hasECheck: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PreviewPdf,
    CreditCardForm,
    SelectorsForm,
    TransactionInputs,
    CheckForm,
    ECheckForm,
  },

  data() {
    return {
      vmoneyValidate: false,
      types: [
        {
          text: "AMG",
          value: "AMG",
          visible: true,
        },
        {
          text: "CLIENT",
          value: "CLIENT",
          visible: true,
        },
      ],
      methods: [
        {
          text: "CREDIT CARD",
          value: "CREDIT_CARD",
          visible: this.hasCreditCard,
        },
        {
          text: "CHECK",
          value: "CHECK",
          visible: this.hasCheck,
        },
        {
          text: "E-CHECK",
          value: "E-CHECK",
          visible: this.hasECheck,
        },
      ],
      formExpense: {
        id: 0,
        type: "AMG",
        method: null,
        amount: 0,
        date: null,
        recurring_payment: 0,
        start_date: null,
        description_expense_id: null,
        payable_expense_id: null,
        credit_card_expense_id: null,
        clients_expenses: [],
        status_expense_id: 2,
        bank_check_account_id: null,
        street: null,
        payable_expense_address_id: null,
        reference_number: null,
        file: null,
        check_number: null,
        check_file: null,
        check_file_name: null,
        from_ceo: false,
      },
      previewUrl: null,
      pdfName: null,
      dataDescriptionExpense: [],
      dataPayableExpense: [],
      dataCardsExpense: [],
      dataPayableExpenseAddresses: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    typeMethods() {
      return this.methods.filter((item) => item.visible);
    },
    typeExpenses() {
      const program =
        !!this.programId ||
        (this.openFromDashboardCeo && this.tab != "CREDIT_CARD");
      return this.types
        .map((item) => ({
          ...item,
          visible: item.value === "CLIENT" ? program : true,
        }))
        .filter((item) => item.visible);
    },
    openFromDashboardCeo() {
      return this.$route.matched[0].name === "ceo-dashboard";
    },
    moduleId() {
      return this.openFromDashboardCeo ? 1 : this.$route.matched[0].meta.module;
    },
    programId() {
      return this.$route.matched[0].meta.program || null;
    },
    disabledType() {
      return this.openFromDashboardCeo
        ? false
        : this.inDashboardClient ||
            !this.programId ||
            !!(this.item && this.item.id > 0);
    },
    typeScope() {
      return {
        attr: {
          options: this.typeExpenses,
          searchable: false,
          clearable: false,
          label: "text",
          reduce: (item) => item.value,
        },
      };
    },
    methodScope() {
      return {
        attr: {
          options: this.typeMethods,
          searchable: false,
          clearable: false,
          label: "text",
          reduce: (item) => item.value,
        },
      };
    },
    isAmg() {
      return this.formExpense.type === "AMG";
    },
    getParams() {
      const params = {
        ...this.formExpense,
        module_id: this.moduleId,
        user_id: this.currentUser.user_id,
        status_check: this.isCreditCard ? null : 2,
        is_offer: 0,
        status_expense_id:
          this.openFromDashboardCeo && this.isCreditCard ? 1 : 2,
      };
      const formData = new FormData();
      Object.entries(params).forEach(([key, value]) => {
        if (key == "clients_expenses") {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value);
        }
      });
      return formData;
    },
    titleModal() {
      if (this.item && this.item.id > 0) return "UPDATE RECURRING PAYMENT";
      return this.isCreditCard
        ? "REGISTER EXPENSE"
        : this.isCeo
        ? "ADD CHECK"
        : this.isCheck
        ? "REQUEST CHECK"
        : "REGISTER E-CHECK";
    },
    titleBtn() {
      const valid = this.item && this.item.id > 0;
      const title = valid ? "SEND TO CEO" : "REGISTER EXPENSE";
      return this.isCreditCard
        ? title.toUpperCase()
        : this.isCeo
        ? "SEND TO CORRESPONDENCE"
        : this.isCheck
        ? "REQUEST CHECK"
        : "REGISTER E-CHECK";
    },
    inDashboardClient() {
      return !(
        this.account_number === "" ||
        this.account_number === null ||
        this.account_number === undefined
      );
    },

    requiredDetailClient() {
      let dataClients = this.formExpense.clients_expenses;
      const noAmounts = dataClients.filter((item) => item.amount === 0).length;
      const total = dataClients.length;
      const message = [
        "Details have not been added yet.",
        "All amounts in the clients table must be greater than 0.",
      ];
      const position = total === 0 ? 0 : 1;
      return {
        valid: total === 0 || noAmounts > 0,
        message: message[position],
      };
    },
    isCreditCard() {
      return this.formExpense.method === "CREDIT_CARD";
    },

    isECheck() {
      return this.formExpense.method === "E-CHECK";
    },
    isCheck() {
      return this.formExpense.method === "CHECK";
    },
  },
  watch: {
    "formExpense.type"(newValue, oldValue) {
      this.formExpense.amount = 0;
      this.vmoneyValidate = false;
      this.formExpense.start_date = null;
      this.formExpense.recurring_payment = 0;
    },
    "formExpense.recurring_payment"() {
      this.formExpense.start_date = null;
    },
    "formExpense.method"() {
      this.formExpense.clients_expenses = [];
    },
    tab: {
      handler(value) {
        switch (value) {
          case "CREDIT_CARD":
            this.formExpense.method = this.hasCreditCard ? value : null;
            break;
          case "CHECK":
            this.formExpense.method = this.hasCheck ? value : null;
            break;
          default:
            this.formExpense.method = this.hasCheck ? value : null;
            break;
        }
      },
      deep: true,
      immediate: true,
    },
    account_number: {
      async handler(value) {
        if (value != "" && value != null && value != undefined) {
          this.formExpense.type = "CLIENT";
        }
      },
      immediate: true,
      deepp: true,
    },
  },
  async created() {
    this.addPreloader();
    this.formExpense.from_ceo = this.openFromDashboardCeo;
    await this.loadCardsExpense();
    await this.loadItem();
    if (this.item && this.item.start_date) {
      this.formExpense.start_date = this.item.start_date;
    }
    if (this.item) {
      const { credit_card_expense_id } = this.item;
      this.formExpense.credit_card_expense_id = credit_card_expense_id;
    }
    await this.A_GET_PROGRAMS();
    this.removePreloader();
    this.toggleModal("modalExpense");
  },
  methods: {
    ...mapActions({
      A_COUNT_EXPENSES_BY_CLIENT: "ExpensesStore/A_COUNT_EXPENSES_BY_CLIENT",
      A_GET_PROGRAMS: "TransactionStatusStore/A_GET_PROGRAMS",
      A_TOTAL_EXPENSES_BY_CLIENT: "ExpensesStore/A_TOTAL_EXPENSES_BY_CLIENT",
      A_COUNT_CHECK_EXPENSES_BY_CLIENT:
        "ExpensesStore/A_COUNT_CHECK_EXPENSES_BY_CLIENT",
      refreshAfterCreateExpense: "ExpensesRequest/refreshAfterCreateExpense",
    }),
    previewPdf(file) {
      if (file && file.type === "application/pdf") {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewUrl = e.target.result;
          this.pdfName = file.name;
        };
        reader.readAsDataURL(file);
      }
    },
    removeFile() {
      this.formExpense.file = null;
      this.previewUrl = null;
    },
    clearPreview() {
      this.previewUrl = null;
    },
    closeModal() {
      this.$emit("close");
    },
    async loadCardsExpense() {
      try {
        const data = await DepartmentExpenses.getAllCardsExpensesByModule({
          module_id: this.openFromDashboardCeo ? null : this.moduleId,
          program_id: this.programId,
        });
        this.dataCardsExpense = data;
      } catch (ex) {}
    },
    async handleOk(ev) {
      try {
        this.vmoneyValidate = this.formExpense.type === "AMG";
        ev.preventDefault();
        let isValid = await this.$refs["formExpenseValidate"].validate();
        if (!isValid) {
          return 0;
        } else {
          if (this.requiredDetailClient.valid && !this.isAmg)
            throw new Error(this.requiredDetailClient.message);
          const response = await this.showConfirmSwal();
          if (!response.isConfirmed) return;
          this.addPreloader();
          if (this.formExpense.id === 0) {
            await this.createExpense();
            if (this.account_number) {
              // from dashboard client, update counter
              await this.A_COUNT_EXPENSES_BY_CLIENT(
                this.$route.params.idClient
              );
              await this.A_TOTAL_EXPENSES_BY_CLIENT(
                this.$route.params.idClient
              );
              await this.A_COUNT_CHECK_EXPENSES_BY_CLIENT(
                this.$route.params.idClient
              );
              await this.refreshAfterCreateExpense(true);
            }
          } else {
            await this.updateExpense();
          }
          this.removePreloader();
        }
      } catch (ex) {
        this.removePreloader();
        this.showAlertWarning(ex.message ? ex.message : ex);
      }
    },
    async createExpense() {
      try {
        await DepartmentExpenses.store(this.getParams);
        this.showAlertSuccess("Expense created successfully");
        this.$emit("reload", {
          tab: this.formExpense.method,
        });
        this.closeModal();
      } catch (ex) {
        this.showAlertDanger(ex.message);
        this.removePreloader();
      }
    },
    async updateExpense() {
      try {
        await DepartmentExpenses.update(this.formExpense.id, this.getParams);
        this.showAlertSuccess("Expense updated successfully");
        this.$emit("reload");
        this.closeModal();
      } catch (ex) {
        this.showAlertDanger(ex.message);
        this.removePreloader();
      }
    },
    async loadItem() {
      if (this.item) {
        const copyObj = JSON.parse(JSON.stringify(this.item));
        this.formExpense = { ...copyObj };
        this.formExpense.file = null;
        this.formExpense.reference_number = null;
      }
    },
    showAlertWarning(message) {
      this.showToast(
        "warning",
        "top-right",
        "Warning",
        "AlertTriangleIcon",
        message
      );
    },
    showAlertDanger(message) {
      this.showToast("danger", "top-right", "Error", "XIcon", message);
    },
    showAlertSuccess(message) {
      this.showToast("success", "top-right", "Success!", "CheckIcon", message);
    },
  },
};
</script>
<style lang="scss">
.invalid-select {
  .vs__dropdown-toggle {
    border: 1px solid #fc424a !important;
  }
}
.modal-expenses {
  .form-group {
    legend.required {
      position: relative;
    }
    // Selector para aplicar estilos a la etiqueta si el input es requerido
    legend.required::after {
      content: " * ";
      color: red;
    }
  }
  .form-group .custom-control-label {
    position: relative;
  }
}
</style>
