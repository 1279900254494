<template>
  <b-modal
      ref="derivationActionModal"
      title-class="h4 text-white"
      modal-class="custom-modal-amg"
      centered
      size="lg"
      title="SEND TO SALES"
      header-class="p-0"
      no-close-on-backdrop
      @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Send To Sales
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <b-row cols="2" class="my-1">
      <b-col>
        <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
          <p
            class="m-0"
            style="gap: 5px"
          >
            Client | {{ derivationData.lead_name }}
          </p>
        </div>
      </b-col>
      <b-col>
        <div class="info-container" :class="isDarkSkin ? 'text-white' : 'text-dark'">
          <p
            class="m-0"
            style="gap: 5px"
          >
            Catcher | {{ catcherName }}
          </p>
        </div>
      </b-col>
    </b-row>

    <ValidationObserver ref="form">
      <b-row cols="1">
        <b-col>
          <ValidationProvider v-slot="{errors}" name="subject" rules="required">
            <b-form-group
                label="Notes"
            >
              <b-textarea
                  v-model="contentNote"
                  :state="errors[0] ? false : null"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>

    <template #modal-footer>
      <b-button
        :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
        @click="applyLeadClientDerivation"
      >SEND</b-button>
    </template>

  </b-modal>
</template>

<script>
import VSelect from "vue-select";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import {mapGetters} from "vuex";

export default {
  name: "DerivationActionModal",
  components: {
    VSelect,
  },
  props: {
    derivationData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      contentNote: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    catcherName() {
      return this.derivationData.client_id ? this.currentUser.fullName : this.derivationData?.owner
    },
  },
  mounted() {
    this.toggleModal('derivationActionModal')
  },
  methods: {
    async applyLeadClientDerivation() {
      const validation = await this.$refs.form.validate();
      if (validation) {
        try {
          const confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            this.addPreloader();
            const params = {
              lead_id: this.derivationData.id,
              client_id: this.derivationData.client_id,
              catcher_by: this.derivationData.client_id ? this.currentUser.user_id : this.derivationData.user_id ,
              program_origin_id: this.$route.matched[0].meta.module >= 20 && this.$route.matched[0].meta.module <= 26 || this.$route.matched[0].meta.module === 6  ? 3 : null,
              module_id: this.$route.matched[0].meta.module,
              notes: this.contentNote,
            }
            const response = await DerivationsService.applyLeadClientDerivation(params)
            if (response.status === 200) {
              this.$emit('send')
              this.removePreloader()
              this.closeModal()
              this.showToast('success')
            }
          }
        } catch (e) {
          this.removePreloader()
          console.log(e)
        }
      }
    },
    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
  border: none !important;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
  border: none !important;
}
</style>