<template>
  <div>
    <b-modal
      modal
      title="SEND EMAIL"
      v-model="modalSend"
      size="xmd"
      scrollable
      modal-class="custom-modal-amg"
      @hidden="closeModal"
      header-bg-variant="transparent"
      title-tag="h3"
      :no-close-on-backdrop="true"
      header-class="p-0"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            Send Email
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="closeModal"
            />
          </div>
        </div>
      </template>
      <validation-observer ref="form">
        <!-- Form -->
        <b-form class="p-1">
          <!-- Title Clients -->
          <b-form-group>
            <b-input-group prepend="Clients">
              <div
                class="form-control h-auto bg-transparent border-secondary sms-container"
              >
                <template v-for="(item, index) in nameLeads">
                  <span :key="index">
                    <span>{{ item.name }}</span>
                    <feather-icon
                      v-if="nameLeads.length > 1"
                      class="text-danger cursor-pointer"
                      icon="MinusCircleIcon"
                      @click="deleteAccount(item.id)"
                    />&nbsp;
                  </span>
                </template>
              </div>
            </b-input-group>
          </b-form-group>
          <!-- Add Quick SMS -->
          <b-form-group
            class="mt-2"
            label="Add Quick SMS"
            label-for="first-name"
            label-cols-md="2"
          >
            <b-input-group>
              <v-select
                v-model="optionsms"
                style="flex: 1 1 auto"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="subject"
                :options="quicks"
                @input="selectsms"
              />
              <b-input-group-append>
                <b-button
                  variant="outline-info"
                  @click="$bvModal.show('modal-quick-email')"
                >
                  <feather-icon
                    icon="AlignJustifyIcon"
                    class="cursor-pointer mr-1"
                  />Manage Quick Email
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <!-- Input Null -->
          <validation-provider
            v-slot="{ errors }"
            name="subject"
            rules="required"
          >
            <b-form-group class="mt-2" label-for="first-name">
              <b-form-input
                v-model="subject"
                placeholder="Subject"
                style="flex: 1 1 auto"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>

          <SmartNoteInput
            :key="selectQuick"
            class="mb-1"
            v-model="contmessage"
            :rows="6"
            :placeholder="'Write new message'"
            :has-error="contmessage.length > 1000 ? true : false"
            required
            @valid="handleValid"
          >
            <template #messages>
              <b-row class="mb-1">
                <b-col cols="6" class="d-flex justify-content-start">
                  <small tabindex="-1" class="form-text text-danger"
                    >Max: 1000 characters</small
                  >
                </b-col>
                <b-col cols="6" class="d-flex justify-content-end">
                  <small
                    tabindex="-1"
                    class="form-text"
                    :class="
                      contmessage.length > 1000
                        ? 'text-danger'
                        : 'text-secondary'
                    "
                    >{{ contmessage.length }} / 1000</small
                  >
                </b-col>
              </b-row>
            </template>
          </SmartNoteInput>
          <span class="text-danger" v-if="!smartNoteValid">
            Optimized message is required
          </span>
          <drag-and-drop
            v-model="files"
            :files-array="files"
            v-if="!isClaimSubject"
          />

          <!-- Save as Note -->
          <b-row v-if="!isClaimSubject">
            <b-col cols="7">
              <small tabindex="-1" class="form-text text-danger"
                >Upload Files Max 8MB</small
              >
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!-- VARS and Send -->
      <template #modal-footer>
        <b-row class="w-100">
          <b-col cols="7"></b-col>
          <b-col cols="3">
            <div class="d-flex justify-content-end mt-2" v-if="!is_for_leads">
              <b-form-checkbox v-model="savenote" name="check-button" switch>
                <span>Save as Note</span>
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col cols="2">
            <div class="d-flex justify-content-end align-items-center mt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                @click="onSubmit"
                :disabled="!contmessage || !smartNoteValid"
              >
                <template v-if="isLoading">
                  <b-spinner small />
                  <span>Loading...</span>
                </template>
                <span v-else>Send</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="w-100" v-if="!is_for_leads">
          <b-col lg="12">
            <b-form-group label="VARS" class="w-100">
              <div class="d-flex">
                <b-input-group size="sm" class="mr-1">
                  <b-input-group-prepend is-text>@1</b-input-group-prepend>
                  <b-form-input placeholder="FIRST NAME" readonly />
                </b-input-group>

                <b-input-group size="sm" class="mr-1">
                  <b-input-group-prepend is-text>@2</b-input-group-prepend>
                  <b-form-input placeholder="LAST NAME" readonly />
                </b-input-group>

                <b-input-group size="sm" class="mr-1">
                  <b-input-group-prepend is-text>@3</b-input-group-prepend>
                  <b-form-input placeholder="ADVISOR NAME" readonly />
                </b-input-group>

                <b-input-group size="sm" class="mr-1">
                  <b-input-group-prepend is-text>@4</b-input-group-prepend>
                  <b-form-input placeholder="PHONE ADVISOR" readonly />
                </b-input-group>

                <b-input-group size="sm">
                  <b-input-group-prepend is-text>@5</b-input-group-prepend>
                  <b-form-input placeholder="PROGRAM NAME" readonly />
                </b-input-group>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- Modal quick email -->
    <b-modal
      id="modal-quick-email"
      title-class="h4 text-white"
      modal-class="custom-modal-amg"
      header-bg-variant="transparent"
      centered
      size="lg"
      title="Quick Email"
      hide-footer
      header-class="p-0"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            Quick Email
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="$bvModal.hide('modal-quick-email')"
            />
          </div>
        </div>
      </template>
      <modal-quick-email />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
// Import Services
import ClientService from "../services/clients.services";
// Import Components
import ModalQuickEmail from "../components/clientsEmail/ModalQuickEmail.vue";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop";
import SmartNoteInput from "@/commons/smart-input/SmartNoteInput.vue";
export default {
  components: {
    ModalQuickEmail,
    vSelect,
    DragAndDrop,
    SmartNoteInput,
  },
  props: {
    nameLeads: {
      type: Array,
    },
    modalSendEmail: {
      type: Boolean,
    },
    isClaimSubject: {
      type: Boolean,
      default: false,
    },
    is_for_leads: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalSend: this.modalSendEmail,
      // quicks: [],
      optionsms: "",
      subject: "",
      contmessage: "",
      files: [],
      isLoading: false,
      savenote: true,
      uploadPercentage: 0,
      smartNoteValid: null,
      selectQuick: 0,
    };
  },
  computed: {
    ...mapState("clients-store", ["quicks"]),
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    handleValid(state) {
      this.smartNoteValid = state;
    },
    ...mapActions("clients-store", ["SET_QUICKS"]),
    closeModal() {
      this.$emit("closeModal");
    },
    deleteAccount(id) {
      for (let i = 0; i < this.nameLeads.length; i++) {
        if (this.nameLeads[i].id == id) {
          this.nameLeads.splice(i, 1);
        }
      }
    },
    allQuicksEmail: async function () {
      try {
        await this.SET_QUICKS({
          modul: this.moduleId,
        });
      } catch (error) {
        console.log("something went wrong with allquicksemail", error);
      }
    },
    selectsms() {
      const index = this.quicks
        .map((el) => el.id)
        .indexOf(this.optionsms ? this.optionsms.id : null);
      if (index !== -1) {
        this.subject = this.quicks[index].subject;
        const format = this.quicks[index].content
          ? this.quicks[index].content.replace(/<br \/>/g, "\n")
          : "";
        this.contmessage = format;
        this.selectQuick += 1;
      } else {
        this.subject = "";
        this.contmessage = "";
      }
    },
    validateSmartInput() {
      if (this.contmessage.length > 1000) {
        this.showToast(
          "warning",
          "top-right",
          "Oops!",
          "XIcon",
          "The message must be less than 1000 characters"
        );
        return;
      }
    },
    async onSubmit() {
      this.validateSmartInput();

      const validation = await this.$refs.form.validate();
      if (validation) {
        const confirm = await this.showConfirmSwal();
        if (confirm.isConfirmed) {
          try {
            this.addPreloader();
            this.contmessage = this.contmessage.replace(/\r?\n/g, "<br />");
            let formData = new FormData();
            this.files.forEach((file) => {
              formData.append("files[]", file, file.name);
            });
            formData.append("contmessage", this.contmessage);
            formData.append("is_for_leads", this.is_for_leads);
            formData.append("user", this.currentUser.user_id);
            formData.append("subject", this.subject);
            let clientsId = this.nameLeads.map((element) => element.id);
            formData.append("sms", clientsId);
            formData.append("module", this.moduleId);
            formData.append(
              "modul",
              this.isClaimSubject ? this.nameLeads[0].moduleId : this.moduleId
            );
            formData.append("savenote", this.savenote == true ? 1 : 0);
            if (this.moduleId == 18) {
              formData.append("type", "EMAIL");
              formData.append("claimId", this.nameLeads[0].claimId);
              formData.append("subtype", 1);
              formData.append("accountId", this.nameLeads[0].id);
            }
            const data = await ClientService.sendEmail(formData, {
              onUploadProgress: function (progressEvent) {
                this.uploadPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
              }.bind(this),
            });
            this.closeModal();
            this.removePreloader();
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Message Sent Successfully"
            );
            this.$emit("refresh");
          } catch (error) {
            console.log(error);
            this.removePreloader();
            this.showErrorSwal(error);
          }
        }
      }
    },
  },
  created() {
    this.allQuicksEmail();
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
</style>
