<template>
  <div>
    <span v-html="currentText" />
  </div>
</template>

<script>
export default {
  props: {
    textToType: {
      type: String,
      required: true,
    },
    typingSpeed: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      currentText: "",
      index: 0,
      intervalId: null,
    };
  },
  methods: {
    startTyping() {
      this.intervalId = setInterval(() => {
        this.currentText += this.textToType[this.index];
        this.index++;

        if (this.index === this.textToType.length) {
          clearInterval(this.intervalId);
        }
      }, this.typingSpeed);
    },
  },
  mounted() {
    this.startTyping();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
/* Add styles as needed */
</style>
