<template>
  <b-modal
    v-model="onControl"
    title="New Observation"
    title-tag="h3"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    size="lg"
    @hidden="close"
  >
    <validation-observer ref="form">
      <validation-provider
        v-slot="{ errors }"
        name="title"
        rules="required"
      >
        <b-form-group label="Title">
          <b-form-input
            v-model="newObservation.title"
            type="text"
            :state="errors[0] ? false : null"
          />
        </b-form-group>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="description"
        rules="required"
      >
        <b-form-group label="Description">
          <b-form-textarea
            v-model="newObservation.description"
            rows="3"
            :state="errors[0] ? false : null"
            max-rows="6"
          />
        </b-form-group>
      </validation-provider>
    </validation-observer>
    <template #modal-footer>
      <b-button
        v-if="!insertObservationLoading"
        variant="success"
        class="rounded"
        type="submit"
        @click="insertObservation"
      >Save</b-button>
      <b-button
        v-else
        variant="success"
        disabled
      >
        <b-spinner
          small
          type="grow"
        />Loading...
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ClientsOptionsServices from '@/views/commons/components/clients/dashboard/services/clients.options.services'

export default {
  name: 'CreateObservationModal',
  data() {
    return {
      newObservation: {
        modal: false,
        title: null,
        description: null,
      },
      insertObservationLoading: false,
      onControl: false,
    }
  },
  async created() {
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async insertObservation() {
      const result = await this.$refs.form.validate()
      if (result) {
        this.insertObservationLoading = true
        const params = {
          title: this.newObservation.title,
          text: this.newObservation.title,
          description: this.newObservation.description,
        }
        const data = await ClientsOptionsServices.insertObservationFilesApp(params)
        if (data.status === 200) {
          this.insertObservationLoading = false
          this.showSuccessSwal()
          this.$emit('addedObservation', params)
          this.close()
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
