<template>
  <div>
    <b-modal
      v-model="showModal"
      size="xmd"
      title="Expenses"
      scrollable
      @hidden="closeModal"
      hide-footer
    >
      <b-nav card-header pills class="m-0">
        <b-nav-item
          v-for="(title, index) in options"
          :key="index"
          :active="index == activeTab"
          :link-classes="['px-3', bgTabsNavs]"
          @click="changeNav(index)"
          >{{ title }}
          <span class="ml-1">
            <feather-icon
              icon
              :badge="
                index === 0
                  ? total_expenses
                  : index === 1
                  ? total_check_expenses
                  : total_echeck_expenses
              "
              badge-classes="badge-danger"
            />
          </span>
        </b-nav-item>
      </b-nav>

      <div style="border-top: 3px solid #0090e7">
        <credit-card-expenses
          v-if="activeTab === 0"
          :showCreateButton="isAssignCardModule"
        />
        <check-expenses
          v-if="[1,2].includes(activeTab)"
          :showCreateButton="showButton"
          :tabSelected="activeTab"
        />
      </div>
    </b-modal>
    <add-or-edit-expense-modal
      v-if="showAddOrEditExpenseModal"
      :account_number="client.account"
      :programIdClient="client.program_id"
      @reload="refreshAfterCreateExpense(true)"
      @close="showAddOrEditExpenseModal = false"
      :hasCreditCard="isAssignCardModule"
      :hasCheck="isAssignCheckModule"
    />
  </div>
</template>

<script>
import CreditCardExpenses from "@/views/commons/components/clients/dashboard/options/pay-module/components/CreditCardExpenses.vue";
import AddOrEditExpenseModal from "@/views/commons/expenses/components/Modal/AddOrEditExpense.vue";
import ExpensesService from "@/views/commons/expenses/services/expenses-department.service.js";
import CheckExpenses from "@/views/commons/components/clients/dashboard/options/pay-module/components/CheckExpenses.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ExpensesModal",
  components: {
    CreditCardExpenses,
    AddOrEditExpenseModal,
    CheckExpenses,
  },
  data() {
    return {
      showModal: false,
      showAddOrEditExpenseModal: false,
      options: ["CREDIT CARDS", "CHECKS", "E-CHECKS"],
      activeTab: 0,
      isAssignCardModule: false,
      existCardExpenses: false,
      isAssignCheckModule: false,
      isAssignEcheckModule: false,
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
      pending_expenses_ch: "ExpensesStore/G_PENDING_EXPENSES_CLIENT",
      confirmed_expenses_ch: "ExpensesStore/G_CONFIRMED_EXPENSES_CLIENT",
      total_expenses: "ExpensesStore/G_TOTAL_EXPENSES_CLIENT",
      total_check_expenses: "ExpensesStore/G_TOTAL_CHECK_EXPENSES_CLIENT",
      total_echeck_expenses: "ExpensesStore/G_TOTAL_ECHECK_EXPENSES_CLIENT",
    }),
    clientProfit() {
      return this.client.profit <= 0 ? 0 : this.client.profit;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    userCanAddExpense() {
      if ([12, 20, 21, 22, 23, 24, 26].includes(this.moduleId)) {
        return [1, 17].includes(this.currentUser.role_id);
      } else {
        return [1, 2].includes(this.currentUser.role_id);
      }
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
    showButton() {
      return this.activeTab == 1 ? this.isAssignCheckModule : this.isAssignEcheckModule;
    }
  },
  async created() {
    this.showModal = true;
    this.existCardModule();
    this.refreshCountCheckExpenses(this.$route.params.idClient);
    this.refreshCountECheckExpenses(this.$route.params.idClient);
  },
  methods: {
    ...mapActions({
      refreshAfterCreateExpense: "ExpensesRequest/refreshAfterCreateExpense",
      refreshCountCheckExpenses:
        "ExpensesStore/A_COUNT_CHECK_EXPENSES_BY_CLIENT",
      refreshCountECheckExpenses:
        "ExpensesStore/A_COUNT_ECHECK_EXPENSES_BY_CLIENT",
    }),
    changeNav(index) {
      this.activeTab = index;
    },
    closeModal() {
      this.$emit("close");
    },
    async existCardModule() {
      try {
        const data = await ExpensesService.existCardModule({
          module_id: this.moduleId,
          program_id: this.programId,
        });
        this.existCardExpenses = data.exists_card_expenses;
        this.isAssignCardModule = data.has_credit_card_assigned;
        this.isAssignCheckModule = data.has_bank_accounts;
        this.isAssignEcheckModule = data.has_echeck_assigned;
      } catch (error) {}
    },
  },
};
</script>
