import store from '@/store';
import router from '@/router';

const userSubmoduleStatusSession = () => {
  window.socket.bind('user-submodule-status-session', async data => {
    // console.log(data);
    const sessionId = store.state.auth.currentUser.user_id;
    const moduleId = router.currentRoute.matched[0]?.meta?.module;
    if (data.to_id === sessionId) {
      await store.dispatch('UserStore/A_GET_USER_STATUS_SUBMODULE_SESSION', {
        user_id: sessionId,
        module_id: moduleId,
      });
    }
  });
};
export default userSubmoduleStatusSession;
