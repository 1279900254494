<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  created() {
    this.redirectToDashboard();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    redirectToDashboard() {
      if (this.$route.name != "ce-digital-customer-service") {
        return;
      }
      if ([14, 2].includes(this.currentUser.role_id)) {
        return this.$router.push({ name: "ce-dashboard-super" });
      }
      if ([15, 16].includes(this.currentUser.role_id)) {
        return this.$router.push({ name: "ce-dashboard-agent" });
      }
      return this.$router.push({ name: "creditexperts-digital-clients" });
    },
  },
};
</script>

<style></style>
