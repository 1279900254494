export default {
  namespaced: true,
  state: {
    S_OPEN_MODAL_ACTIONS: {
      bool: false,
      id_alert: null,
    },
    S_AGENT_OPERATION: null,
    S_SEC_TO_LEAVE: null,
    S_ALERT_TYPE: null,
  },
  mutations: {
    OPEN_MODAL_ACTIONS(state, params) {
      state.S_OPEN_MODAL_ACTIONS = params;
    },
    AGENT_OPERATION(state, params) {
      state.S_AGENT_OPERATION = params;
    },
    SEC_TO_LEAVE(state, params) {
      state.S_SEC_TO_LEAVE = params;
    },
    ALERT_TYPE(state, params) {
      state.S_ALERT_TYPE = params;
    },
  },
  actions: {
    A_OPEN_MODAL_ACTIONS({ commit }, data) {
      commit("OPEN_MODAL_ACTIONS", data);
    },
    A_AGENT_OPERATION({ commit }, data) {
      commit("AGENT_OPERATION", data);
    },
    A_ALERT_TYPE({ commit }, data) {
      commit("ALERT_TYPE", data);
    },
    A_SEC_TO_LEAVE({ commit }, data) {
      commit("SEC_TO_LEAVE", data);
    },
  },
  getters: {
    G_OPEN_MODAL_ACTIONS(state) {
      return state.S_OPEN_MODAL_ACTIONS;
    },
    G_AGENT_OPERATION(state) {
      return state.S_AGENT_OPERATION;
    },
    G_SEC_TO_LEAVE(state) {
      return state.S_SEC_TO_LEAVE;
    },
    G_ALERT_TYPE(state) {
      return state.S_ALERT_TYPE;
    },
  },
};
