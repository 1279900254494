<template>
  <ul class="list-unstyled w-100 m-0">
    <li
      class="w-100 d-flex flex-column justify-content-between align-items-center custom-height"
    >
      <div class="d-flex justify-content-center align-items-center flex-grow-1">
        <div v-for="(lbl, index) in generalInfo" :key="index">
          <span
            v-if="lbl.key === 'see_more'"
            class="text-truncate"
            style="max-width: 250px"
            >{{ item[lbl.key] }}</span
          >
          <template v-if="lbl.key === 'circle_checked'">
            <div class="w-100 d-flex justify-content-center align-items-center">
              <!--READ ONLY PRIORITY-->
              <template v-if="item.id != '-'">
                <div
                  class="text-white d-flex justify-content-between align-items-center px-50 py-50 rounded-xlg position-relative"
                  style="width: 170px; position: relative; transform: scale(0.9)"
                  :style="
                    item.priority
                      ? `background-color: ${
                          others.priorityColor[item.priority.toLowerCase()]
                        } !important;`
                      : 'background-color: rgb(130, 134, 139)'
                  "
                >
                  <div
                    class="d-flex justify-content-start align-items-center w-100 pl-50"
                  >
                    <tabler-icon
                      v-if="!others.openBySeller"
                      :icon="item.new_item_selected || item.new_item_selected == 1
                          ? 'CircleCheckIcon'
                          : 'CircleIcon'
                      "
                      class="mr-50 text-white rounded-circle"
                      :class="[editMode ? 'hover-me cursor-pointer' : 'bg-secondary',
                        item.new_item_selected == 1 ? 'bg-primary' : '']"
                      size="20"
                      @click="editMode && selectBureauToNewItem(item)"
                    />
                    <span
                      class="text-uppercase font-weight-bolder p-0 m-0 text-white"
                    >
                      {{ item.priority }}
                      <p 
                        v-if="item.saved_new_item_at" 
                        style="font-size: 10px" 
                        class="m-0 cursor-info"
                        v-b-tooltip.hover.right="'Saved as New Item at'"
                      >
                        {{ item.saved_new_item_at | myGlobal }}
                      </p>
                    </span>
                  </div>
                </div>
              </template>
              <template v-else>
                <!--PLACEHOLDER OF BUREAU -->
                <img
                  v-if="item.bureau_id == 1"
                  :src="require('@/assets/images/icons/transunion.png')"
                  width="30px"
                  height="30px"
                  class="custom-bg-logo"
                />
                <img
                  v-else-if="item.bureau_id == 2"
                  :src="require('@/assets/images/icons/experian.png')"
                  width="30px"
                  height="30px"
                  class="custom-bg-logo"
                />
                <img
                  v-else-if="item.bureau_id == 3"
                  :src="require('@/assets/images/icons/equifax.png')"
                  width="30px"
                  height="30px"
                  class="custom-bg-logo"
                />
              </template>
              <!--EDIT PRIORITY-->
            </div>
          </template>
        </div>
      </div>
    </li>
    <!--DETAILS OF BUREAU-->
    <template v-for="(lbl, key) in bureauDetails">
      <li
        class="padding w-100 d-flex justify-content-center text-capitalize text-center"
        :key="key"
        :style="{ height: customHeight(lbl) }"
      >
        <template v-if="lbl.key === 'select'">
          <b-form-checkbox
            v-if="
              item.type_classification != 'LOW' &&
              item.type_classification != '-'
            "
            v-model="item.is_remove"
            :state="true"
            :disabled="approvedAccounts"
          />
          <span v-else>-</span>
        </template>
        <template v-else-if="lbl.key === 'payment_status'">
          <span class="text-truncate d-flex justify-content-center align-items-center" style="max-width: 200px">
            {{ item.payment_status }}
          </span>
        </template>
        <template v-else-if="lbl.key === 'comments'">
          <!-- <span class="text-truncate d-flex justify-content-center align-items-center" style="max-width: 200px">
            {{ item.comments || "-" }}
          </span> -->
          <span
            v-if="item.comments"
            class="text-truncate d-flex justify-content-start align-items-center"
            style="max-width: 180px"
            :id="`comment_${item.id}`"
          >
            {{ item.comments.length > maxLetter ? `${item.comments.slice(0,maxLetter)}...`:item.comments }}
            <b-tooltip v-if="item.comments.length > maxLetter" :target="`comment_${item.id}`">
              {{ item.comments }}
            </b-tooltip>
          </span>
          <span
            class="d-flex justify-content-center align-items-center"
            v-else
          >
          {{"-"}}
          </span>
        </template>
        <template v-else-if="lbl.key === 'balance'">
          <span class="text-truncate d-flex justify-content-center align-items-center" style="max-width: 200px">
            $ {{ isNaN(item.balance) ? 0 : item.balance | currency }}
          </span>
        </template>
        <template v-else-if="lbl.key === 'monthly_payment'">
          <span class="text-truncate d-flex justify-content-center align-items-center" style="max-width: 200px">
            $
            {{
              isNaN(item.monthly_payment) ? 0 : item.monthly_payment | currency
            }}
          </span>
        </template>
        <template v-else-if="lbl.key === 'credit_limit'">
          <span class="text-truncate d-flex justify-content-center align-items-center" style="max-width: 250px">
            $
            {{ isNaN(item.credit_limit) ? 0 : item.credit_limit | currency }}
          </span>
        </template>
        <template v-else-if="lbl.key === 'h_credit'">
          <span class="text-truncate d-flex justify-content-center align-items-center" style="max-width: 180px">
            $ {{ isNaN(item.h_credit) ? 0 : item.h_credit | currency }}
          </span>
        </template>
        <template v-else-if="lbl.key === 'creditor_name'">
          <span class="text-truncate d-flex justify-content-start align-items-center" style="max-width: 180px" :id="`account_${item.id}`">
            {{ item.creditor_name.length > maxLetter ? `${item.creditor_name.slice(0,maxLetter)}...`:item.creditor_name }}
            <b-tooltip v-if="item.creditor_name.length > maxLetter" :target="`account_${item.id}`">
              {{ item.creditor_name }}
            </b-tooltip>
          </span>
        </template>
        <template v-else-if="lbl.key === 'circle_checked'">
          <div class="w-100 d-flex justify-content-center align-items-center">
            <feather-icon
              v-if="!others.openBySeller"
              :icon="
                item[item._bureau] == 1 && item.type_classification != 'LOW'
                  ? 'CheckCircleIcon'
                  : 'CircleIcon'
              "
              :class="[classResults(item, item._bureau), 'mr-1', '']"
              size="30"
              @click="
                analysisEvaluationForTab == 'OBSERVED' &&
                  editMode &&
                  changeBoureauResult(item)
              "
            />
            <div
              v-if="
                (item.edit_data == 0 ||
                  (!editMode && others.currentUser.modul_id != 2)) &&
                item.type_classification != '-'
              "
              class="text-white d-flex justify-content-center align-items-center px-1 py-50 rounded-xlg"
              :style="
                item.priority
                  ? `background-color: ${
                      others.priorityColor[item.priority.toLowerCase()]
                    } !important;`
                  : ''
              "
            >
              <h5 class="text-uppercase font-weight-bolder p-0 m-0 text-white">
                {{ item.priority }}
              </h5>
            </div>
            <div
              v-if="
                item.edit_data == 1 &&
                (editMode ||
                  (others.openBySeller &&
                    item.priority == 'needs confirmation'))
              "
            >
              <v-select
                class="text-uppercase"
                v-model="item.priority"
                :clearable="true"
                label="label"
                :options="others.removeOptions"
                :reduce="(option) => option.value"
                @input="changeClassification(item)"
                style="width: 140px; height: 40px"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <span class="text-truncate d-flex justify-content-center align-items-center" style="max-width: 250px">{{
            item[lbl.key]
          }}</span>
        </template>
      </li>
    </template>
  </ul>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ItemBureau",
  props: {
    labelsRemove: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    analysisEvaluationForTab: {
      type: String | undefined,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
    others: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      variantBadge: {
        UPDATED: "info",
        DELETED: "success",
        "IN DISPUTE": "warning",
      },
      maxLetter: 20,
      arrayNewItems: [],
    };
  },
  watch: {
    "item.is_remove": {
      handler() {
        this.item.removed =
          this.item.is_remove && !!this.item.selected_negotiate;
      },
      deep: true,
    },
  },
  methods: {
    classResults(item, column) {
      return {
        "text-success": item[column] == 1,
        "text-success cursor-pointer icon":
          item[column] == 1 &&
          this.analysisEvaluationForTab == "OBSERVED" &&
          this.editMode,
        "text-warning cursor-pointer icon":
          (item[column] == 0 || item[column] == null) &&
          this.analysisEvaluationForTab == "OBSERVED" &&
          this.editMode,
        "text-secondary":
          (item[column] == 0 || item[column] == null) &&
          this.analysisEvaluationForTab != "OBSERVED",
        hide_not_selected: item["priority"] == "-",
      };
    },
    customHeight(lbl) {
      let height = null;
      switch (lbl.key) {
        case "circle_checked":
          height = lbl.height;
          break;
        case "select":
          height = lbl.height;
          break;
        default:
          height = null;
          break;
      }
      return height;
    },
    changeBoureauResult(item) {
      if (item.id != "-" && item.type_classification != "LOW") {
        const type = item._bureau.split("_")[0];
        this.$emit("changeBoureauResult", item, type);
      }
    },
    selectBureauToNewItem(item) {
      item.new_item_selected = !item.new_item_selected;
      this.$emit("selectBureauToNewItem", item);
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    generalInfo() {
      return this.labelsRemove.filter((label) => !label.info);
    },
    bureauDetails() {
      return this.labelsRemove.filter((label) => label.info);
    },
    checkboxDisabled() {
      return this.others.approvedAccounts === 0 ? false : true;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  mounted() {
    this.item.removed = this.item.is_remove && !!this.item.selected_negotiate;
  },
};
</script>
<style scoped>
li.padding {
  height: 35px;
  /* padding: 0.5rem !important; */
  border-top: 1px solid #e3e5ec !important;
}
.hide_not_selected {
  display: none !important;
}

.custom-checkbox
  >>> .custom-control-input
  ~ .custom-control-label:before{
    border-radius: 50px !important;
    border: none !important;
}

/* .custom-checkbox
  >>> .custom-control-input:checked
  ~ .custom-control-label:before {
  background-color: #2ecc71 !important;
} */
.custom-checkbox-nego
  >>> .custom-control-input:checked:disabled
  ~ .custom-control-label:after,
  .custom-checkbox-nego
  >>> .custom-control-input:checked:disabled
  ~ .custom-control-label:before{
    border-radius: 50px !important;
    border: none !important;
}
.custom-checkbox-nego
  >>> .custom-control-input:checked
  ~ .custom-control-label:before {
  background-color: #dc3545 !important;
}
.custom-checkbox-nego
  >>> .custom-control-input:checked
  ~ .custom-control-label:after {
  background-color: #dc3545 !important;
  cursor: not-allowed !important;
  border-radius: 5px !important;
}
.custom-bg-logo {
  opacity: 0.3 !important;
  filter: grayscale(100%) !important;
}
.rounded-xlg {
  border-radius: 13px !important;
}
.custom-badge {
  position: absolute !important;
  top: -13px;
  right: 0px !important;
}
.custom-height {
  height: 100px !important;
}
</style>
