<template>
  <div>
    <b-row class="mb-1">
      <b-form-checkbox
        id="goapplication"
        v-model="applicant"
        :disabled="readOnly"
        class="ml-1"
        >Co applicant</b-form-checkbox
      >
    </b-row>
    <b-row v-if="applicant === true" class="mt-1">
      <b-col>
        <b-card
          header-text-variant="white"
          header-bg-variant="info"
          header-class="p-0"
          body-class="pt-1 card-body-border"
        >
          <template #header>
            <div style="padding-top: 12px; padding-left: 20px">
              <p class="font-weight-bolder">Co-Applicant personal data</p>
            </div>
          </template>
          <b-row>
            <b-col lg="3">
              <label for="">First Name</label>
              <b-form-input
                v-model="coApp.first_name"
                type="text"
                :disabled="readOnly"
              />
            </b-col>
            <b-col lg="3">
              <label for="">Middle Name</label>
              <b-form-input v-model="coApp.middle_name" :disabled="readOnly" />
            </b-col>
            <b-col lg="3">
              <label for>Last Name</label>
              <b-form-input v-model="coApp.last_name" :disabled="readOnly" />
            </b-col>
            <b-col lg="3">
              <label for="">Status</label>
              <v-select
                v-model="coApp.status"
                :options="states_leads"
                :reduce="(value) => value.id"
                :disabled="readOnly"
                label="name"
              />
            </b-col>
            <b-col lg="3" class="mt-1">
              <label for="">Date of birth</label>
              <b-form-datepicker
                id="date5"
                v-model="coApp.dob"
                locale="en"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                name="date5"
                :disabled="readOnly"
              />
            </b-col>
            <b-col lg="3" class="mt-1">
              <label> SSN </label>
              <b-form-input
                id="SSN2"
                v-model="coApp.ssn"
                v-mask="'###-##-####'"
                name="ssn2"
                :disabled="readOnly"
                maxlength="11"
              />
            </b-col>
            <b-col lg="3" class="mt-1">
              <label for="">Phone number</label>
              <b-form-input
                id="work1"
                v-model="coApp.phone"
                v-mask="'(###) ###-####'"
                name="work1"
                maxlength="14"
                :disabled="readOnly"
              />
            </b-col>
            <b-col lg="3" class="mt-1">
              <label for="">Email</label>
              <b-form-input
                id="coApplicantEmail"
                v-model="coApp.email"
                name="coApplicantEmail"
                type="email"
                :disabled="readOnly"
              />
            </b-col>
            <b-col lg="3" class="mt-1">
              <label for="">Co-applicant Civil Status</label>
              <v-select
                v-model="coApp.cstatus"
                :disabled="readOnly"
                :options="[
                  'SINGLE',
                  'ENGAGED',
                  'MARRIED',
                  'DIVORCED',
                  'WIDOW/ER',
                ]"
              />
            </b-col>
            <b-col lg="3" class="mt-1">
              <label for="">Co-applicant employer</label>
              <v-select
                id="employer"
                v-model="coApp.employer"
                :options="optionsVSelect.optionsApplicantEmployer"
                :reduce="(opts) => opts.label"
                :disabled="readOnly"
              />
            </b-col>
            <b-col lg="3" class="mt-1">
              <label for="">Co-applicant Dependents</label>
              <v-select
                id="dependents2"
                v-model="coApp.dependents"
                :options="optionsVSelect.optionsApplicantDepentends"
                :reduce="(opts) => opts.label"
                :disabled="readOnly"
              />
            </b-col>
            <b-col cols="3">
              <div
                class="h-100 d-flex flex-column justify-content-center align-items-center"
              >
                <div>Availability to give full amounts?</div>
                <div style="margin-top: 3px"
                  class="d-flex ml-1 align-items-center justify-content-center"
                >
                  <b-form-checkbox
                    id="fullAmounts"
                    v-model="coApp.full_amounts"
                    name="fullAmounts"
                    :disabled="readOnly"
                    switch
                  />
                  <span v-if="coApp.full_amounts != ''">YES</span>
                  <span v-else>NO</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import VSelectOptions from "@/views/crm/views/clients/components/data/options.modal-questionnaire";

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    value: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    coApp: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  data() {
    return {
      applicant: true,
      states_leads: [],
      optionsVSelect: VSelectOptions,
    };
  },
  methods: {
    async statesleads() {
      try {
        const response = await amgApi.get("/lead/get-state-leads");
        this.states_leads = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },

  async mounted() {
    await this.statesleads();
  },
};
</script>
