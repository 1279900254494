import { amgApi } from "@/service/axios";

class FilePOBoxService {
  async getFoldersByClient(fileId) {
    const data = await amgApi.get(
      `/commons/po-box/get-folders-by-client/${fileId}`
    );
    return data;
  }

  async deletePoBox(fileId) {
    const data = await amgApi.post(`/commons/po-box/delete-po-box/${fileId}`);
    return data;
  }

  async getPOBoxes(params) {
    const data = await amgApi.post("/commons/po-box/get-po-boxes", params);
    return data;
  }

  async getVerifiedPOBox(params) {
    const data = await amgApi.post(
      "/commons/po-box/get-verified-pobox",
      params
    );
    return data;
  }

  async savePoBox(params) {
    const data = await amgApi.post("/commons/po-box/save-po-box", params);
    return data;
  }

  async programs() {
    const data = await amgApi.get("/commons/programs");
    return data;
  }

  async getClientsNotInVerifiedPoboxes(params) {
    const data = await amgApi.post(
      "/commons/po-box/get-clients-not-in-verified-po-boxes",
      params
    );
    return data;
  }

  async copyPoBoxToClientFolder(params) {
    const data = await amgApi.post("/commons/po-box/copy-po-box", params);
    return data;
  }

  async getPoBoxCounter(params) {
    const data = await amgApi.get("/commons/po-box/count-po-box", params);
    return data;
  }
}
export default new FilePOBoxService();
