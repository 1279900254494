import store from '@/store';
import Vue from 'vue';

const meetingNotification = () => {
  window.socket.bind('project-meeting', async data => {
    let temps = false;
    const currentSession = store.state.auth.currentUser.user_id;
    const user_meeting = data.mettingData
      .filter(obj => obj.user_id === currentSession)
      .map(obj => ({
        user_id: obj.user_id,
        date: obj.date,
        meetting_name: obj.meetting_name,
        place: obj.place,
        priority: obj.priority,
        project_name: obj.project_name,
      }));
    if (user_meeting.length > 0) {
      temps = true;
    }
    if (temps && user_meeting[0].user_id === currentSession) {
      const priorityClass = user_meeting[0].priority.toLowerCase();
      let icon = 'InfoIcon';
      switch (priorityClass) {
        case 'high':
          icon = 'AlertTriangleIcon';
          break;
        case 'medium':
          icon = 'AlertCircleIcon';
          break;
        case 'low':
          icon = 'CheckCircleIcon';
          break;
        default:
          icon = 'UnknownIcon'; // Set a default icon here
          break;
      }
      user_meeting[0].priority = user_meeting[0].priority.toUpperCase();
      await Vue.prototype.$amgAlert({
        title: 'You have a new Meeting',
        icon,
        subtitle: `Priority ${user_meeting[0].priority}`,
        data: [
          {
            icon: 'BriefcaseIcon',
            title: 'Meetting',
            description: user_meeting[0].meetting_name,
          },
          {
            icon: 'ArrowDownCircleIcon',
            title: 'Place',
            description: user_meeting[0].place,
          },
          {
            icon: 'FolderIcon',
            title: 'Project',
            description: user_meeting[0].project_name,
          },
          {
            icon: 'ClockIcon',
            title: 'DATE',
            description: user_meeting[0].date,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', data); },
      });
    }
    // console.log(user_meeting);
  });
};
export default meetingNotification;
