<template>
  <b-button
    variant="primary"
    @click="$emit('click')"
  >
    Update
  </b-button>
</template>

<script>
export default {
  name: 'ButtonUpdate',
}
</script>

<style scoped>

</style>
