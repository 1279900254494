import Vue from 'vue';
import store from '@/store/index';

const PayrollNotification = () => {
  window.socket.bind('payroll-notifications', async ({ message, to_id, title, succesful }) => {
    const currentSession = store.state.auth.currentUser.user_id;
     await store.dispatch("PayrollStore/A_SET_GENERATED_PAYROLL", 1);
    if (to_id == currentSession) {
      const { isConfirmed } = await Vue.swal.fire({
        icon: succesful == 1 ? 'success' : 'error',
        title,
        text: message,
      });

      if (isConfirmed) { await window.amgApi.post('/commons/close-all-swal', { to_id }); }
    }
  });
};
export default PayrollNotification;
