import { amgApi } from "@/service/axios";

class AgentAwayService {
  async checkPassword(params) {
    let res = await amgApi.post(
      "credit-experts-digital/check-agent-pass",
      params
    );

    return res;
  }

  async getPauses(params) {
    let res = await amgApi.post(
      "credit-experts-digital/get-pauses",
      params
    );

    return res.data;
  }

  async getAgentDetail(params) {
    let res = await amgApi.post(
      "credit-experts-digital/agent-pause-detail",
      params
    );

    return res.data;
  }
}

export default new AgentAwayService();
