import {
    assignCreditAnalystNotification,
    toRevisionUrNotification,
    returnUrNotification,
    completeUrNotification
    
  } from "@/views/commons/components/dispute-results/socket/updateRequest";

  const subscribeUpdateRequestSocket = (socket) => {
    try {
      socket.subscribe("update-request-notifications");
      assignCreditAnalystNotification();
      toRevisionUrNotification();
      returnUrNotification();
      completeUrNotification();
    } catch (error) {
      console.error(error, "error");
    }
  };

  export default subscribeUpdateRequestSocket;
