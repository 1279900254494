import leadsNav from "@/views/administration/views/leads/leads.nav";
import salesMadeNav from "@/views/administration/views/sales-made/sales-made.nav";
import zeroPaymentNav from "@/views/administration/views/zero-payment/navigation/zero-payment.navigation";
import fileManagerNav from "@/views/administration/views/file-manager/file-manager.nav";
import filePoBoxNav from "@/views/administration/views/file-po-box/file-po-box.nav";
import calendarNav from "@/views/administration/views/calendar/calendar.nav";
import settingsNav from "@/views/administration/views/settings/settings.nav";
import schedulesNav from "@/views/administration/views/schedules/schedules.nav";
import loansNav from "@/views/administration/views/loans/loans.nav";
import helpdeskNav from "@/views/administration/views/helpdesk/helpdesk.nav";
import productRequestNav from "@/views/administration/views/request/request.nav.js";
import initialPaymentNav from "@/views/administration/views/initial-payment/initial-payment.nav";
import statusChangeNav from "@/views/administration/views/status-change/status-change.nav";
import clientsNav from "@/views/administration/views/clients/clients-group.navigation";
import reportNav from "@/views/administration/views/report/report.nav";
import migrationsNav from "@/views/administration/views/migrations/migrations.nav";
import letterNav from "@/views/administration/views/letters/navigation/letters.navigation";
import registerPaymentNav from "@/views/administration/views/register-payment/register-payment.nav";
import commissionNav from "@/views/administration/views/comissions/commisions.nav";
import employeeClaimsNavigation from "@/views/administration/views/employee-claims/navigation/employee-claims.nav";
import CallLogNavigation from "@/views/administration/views/call-log/call-log.nav.js";
import RecruitmentProcessNavigation from "@/views/administration/views/recruitment-process/administration-recruitment_process.navigation.js";
import MOFNavigation from "@/views/administration/views/manual-of-functions/manual-of-functions.nav";
import chargeBackNav from "@/views/administration/views/charge-back/charge-back.nav.js";
import CourtInfoNavigation from "@/views/administration/views/cour-info/navigation/ds-court-info.nav";
import CustomerTicketNavigation from "@/views/administration/views/customer-tickets/administration-customer-tickets.navigation";
import DashboardNavigation from "@/views/administration/views/dashboard/navigation/dashboard.nav.js";


const navigation = [
  {
    header: "ADMINISTRATION",
  },
  DashboardNavigation,
  ...leadsNav,
  // ...salesMadeNav,
  ...zeroPaymentNav,
  ...letterNav,
  ...chargeBackNav,
  // ...commissionNav,
  clientsNav,
  ...statusChangeNav,
  ...registerPaymentNav,
  ...reportNav,
  migrationsNav,
  fileManagerNav,
  filePoBoxNav,
  calendarNav,
  settingsNav,
  schedulesNav,
  loansNav,
  helpdeskNav,
  initialPaymentNav,
  productRequestNav,
  employeeClaimsNavigation,
  //AdministrationProjectsNavigation,
  ...CallLogNavigation,
  ...CourtInfoNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  CustomerTicketNavigation,
];

export default navigation;
