<template>
  <feather-icon
    v-if="iconStatus.status_account"
    id="icon"
    icon="CircleIcon"
    size="14"
    :class="colorStatus"
  />
</template>
<script>
export default {
  props: {
    iconStatus: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    colorStatus() {
      let selected;

      switch (this.iconStatus.status_account) {
        case 1:
          selected = "green marginTR";
          break;
        case 2:
          if (
            this.iconStatus.validate_sp == 1 ||
            this.iconStatus.validate_sp == 2
          ) {
            selected = "red marginTR";
          }
          break;
        case 3:
          selected = "blue marginTR";
          break;
        case 4:
          selected = "red marginTR";
          break;
        case 5:
          selected = "red marginTR";
          break;
        case 6:
          selected = "red marginTR";
          break;
      }
      return selected;
    },
  },
};
</script>
<style scoped>
#icon {
  border-radius: 50%;
  border: none;
}
.marginTR {
  margin-top: 3px;
  margin-right: 5px;
}
.green {
  color: #00cc00;
  border-color: #00cc00;
  background: #00cc00;
}
.red {
  color: red;
  border-color: red;
  background: red;
}
.blue {
  color: #0066ff;
  border-color: #00cc00;
  background: #0066ff;
}
</style>
