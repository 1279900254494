export default [
  {
    path: "bank-of-flyers",
    name: "bank-of-flyers-creative",
    redirect: { name: "list-active-creative" },
    component: () =>
      import(
        /* webpackChunkName: "CRBankOfFiyers" */ "@/views/commons/components/bank-of-flyers/BankOfFlyers.vue"
      ),
    children: [
      {
        path: "active",
        component: () =>
          import(
            /* webpackChunkName: "CRBankOfFiyersActive" */ "@/views/commons/components/bank-of-flyers/bank-of-flyers-module/bank-of-flyers-list-active/BankOfFlyerActive.vue"
          ),
        name: "list-active-creative",
        meta: {
          permittedRoles: [1, 2, 17, 18, 19],
          pageTitle: "Bank of flyers",
          route: "creative",
          breadcrumb: [
            {
              text: "Active",
              active: true,
            },
          ],
        },
      },
      {
        path: "inactive",
        component: () =>
          import(
            /* webpackChunkName: "CRBankOfFiyersInactive" */ "@/views/commons/components/bank-of-flyers/bank-of-flyers-module/bank-of-flyers-list-inactive/BankOfFlyerInactive.vue"
          ),
        name: "list-inactive-creative",
        meta: {
          pageTitle: "Bank of flyers",
          route: "creative",
          breadcrumb: [
            {
              text: "Inactive",
              active: true,
            },
          ],
        },
      },
      {
        path: "preactive",
        component: () =>
          import(
            /* webpackChunkName: "CRBankOfFiyersPreactive" */ "@/views/commons/components/bank-of-flyers/bank-of-flyers-module/bank-of-flyer-preactive/BankOfFlyerPreActive.vue"
          ),
        name: "list-preactive-creative",
        meta: {
          pageTitle: "Bank of flyers",
          route: "creative",
          breadcrumb: [
            {
              text: "Preactive",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      pageTitle: "Bank of flyers",
      route: "creative",
      breadcrumb: [
        {
          text: "Bank of flyers",
          active: true,
        },
      ],
    },
  },
]
