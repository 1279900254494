export default [
    {
        path: 'users',
        name: 'users-crud',
        redirect: { name: "users-active" },
        component: () => import(/* webpackChunkName: "UsersCrud" */ '../user-view/UsersView.vue'),
        meta: {
            pageTitle: 'User',
            route: 'users',
            breadcrumb: [
                {
                    text: 'Create User',
                }
            ],
            layout: "horizontal"
        },
        children: [
            {
                path: "/users/edit/:id",
                name: "users-edit",
                component: () =>
                    import(/* webpackChunkName: "UsersEdit" */ "@/views/users/commons/users/user-view/UsersView.vue"),
                meta: {
                    tittleBtn: 'Update',
                    pageTitle: "Edit User",
                    route: 'users',
                    breadcrumb: [
                        {
                            text: "User",
                            active: true,
                        },
                    ],
                },
            },
            {
                path: "/users/create",
                name: "users-create",
                component: () =>
                    import(/* webpackChunkName: "UsersCreate" */ "@/views/users/commons/users/user-view/UsersView.vue"),
                meta: {
                    tittleBtn: 'Save',
                    route: 'users',
                    pageTitle: "Create User",
                    breadcrumb: [
                        {
                            text: "User",
                            active: true,
                        },
                    ],
                },
            },
        ],
    },
]