export default [
  {
    title: "Appointments",
    icon: "CalendarIcon",
    route: "connection-appointment",
    tag: 0,
  }
]
  
  
