export default [

  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: { year: "numeric", month: "numeric", day: "numeric" },
    cols: 6,
    visible: true,
  },

  {
    type: "custom-select",
    margin: true,
    showLabel: true,
    label: "Client Status",
    model: null,
    options: [],
    reduce: "value",
    selectText: "label",
    cols: 12,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Payment Type",
    model: null,
    options: [
      { id: 0, text: "All" },
      { id: 1, text: "Automatic" },
      { id: 2, text: "Manual" },
      { id: 3, text: "Others" },
    ],
    reduce: "id",
    selectText: "text",
    cols: 5,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Seller",
    model: null,
    options: [],
    reduce: "id",
    selectText: "user_name",
    cols: 7,
  },
]
