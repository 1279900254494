import leadsRoute from "../views/leads/leads.router";
import clientRoute from "../views/clients/clients.router";
import callRoundsRoute from "../views/call-rounds/call-rounds.router";
import zeroPayment from "@/views/boost-credit/views/zero-payment/router/index.js";
import applicationsRouter from "@/views/boost-credit/views/applications/applications.router";
import ncrRealtor from "@/views/boost-credit/views/ncr-realtor/router/index";
import ncrProgram from "@/views/boost-credit/views/ncr-programs/ncr-programs.router";
import RequestNcrRouter from "@/views/boost-credit/views/request-ncr/request-ncr.router";
import RequestWorkplanRouter from "@/views/boost-credit/views/request-workplan/request-workplan.router.js";
import letterRoute from "@/views/boost-credit/views/letters/letters.router";
import DirectRouter from "@/views/boost-credit/views/direct/router/direct.router.js";
import disputeResults from "@/views/boost-credit/views/dispute-results/router/index";
import ncrRoute from "@/views/boost-credit/views/ncr/ncr-boost-credit.router";
import reportsRoute from "@/views/boost-credit/views/reports/reports.router";
import paymentsRoute from "@/views/boost-credit/views/payments/payments.router";
import trackingListRoute from "@/views/boost-credit/views/tracking-list/router/tracking-list.router";
import saleMadeRouter from "@/views/boost-credit/views/sales-made/sales-made.router";
import commissionRoute from "@/views/boost-credit/views/commission/commission.router";
import chargeBackRoute from "@/views/boost-credit/views/charge-back/charge-back.router";
import calendarRoute from "@/views/boost-credit/views/calendar/calendar.router";
import helpdeskRoute from "@/views/boost-credit/views/helpdesk/helpdesk.route";
import fileManagerRoute from "@/views/boost-credit/views/file-manager/file-manager.router";
import loansRoute from "@/views/boost-credit/views/loans/loans.router";
import schedulesRouter from "@/views/boost-credit/views/schedules/schedules.router";
import performanceRouter from "@/views/boost-credit/views/advisor-performance/performance.router";
import ProductRequestRouter from "@/views/boost-credit/views/request/request.router.js";
import claimsNav from "@/views/boost-credit/views/claims-v2/claims.router";
import OtherPaymentsRouter from "@/views/boost-credit/views/other-payments/other-payments.router";
import BoostCreditMain from "@/views/boost-credit/views/BoostCreditMain.vue";
import BcAttendanceRouter from "@/views/boost-credit/views/attendance/bc-attendance.router";
//import BoostCreditsProjectsRouter from "@/views/boost-credit/views/projects/boost-credits-projects.router";
import EmployeeClaimsRouter from "@/views/boost-credit/views/employee-claims/router/employee-claims.router";
import CallLogRouter from "@/views/boost-credit/views/ring-central/call-log/call-log.router.js";
import CourtInfoRouter from "@/views/boost-credit/views/cour-info/router/ds-court-info.router";

import DashboardCalls from "@/views/boost-credit/views/ring-central/dashboard-calls/dashboard.router.js";
import MOFRouter from "@/views/boost-credit/views/manual-of-functions/manual-of-functions.router.js";
import RecruitmentProcessRouter from "@/views/boost-credit/views/recruitment-process/boost-credit-recruitment_process.router";
import RequestClientStatusRouter from "@/views/boost-credit/views/request-client-status/router/request-client-status.router";
import CustomerTicketsRouter from "@/views/boost-credit/views/customer-tickets/boost-credit-customer-tickets.router";
import SendingReportRouter from "@/views/boost-credit/views/sending-report/sending-report.router.js";
import AppointmentRouter from "@/views/boost-credit/views/appointment/appointment.router";
import PotencialAppointmentRouter from "@/views/boost-credit/views/potencial-appointment/potencial-appointment.route";
import OtherServicesRouter from "@/views/boost-credit/views/other-services/other-services.router.js";
import CommissionsRouter from "@/views/boost-credit/views/commission-specialists/commissions-specialists.router";
import InconsistentTransactionRouter from "@/views/boost-credit/views/inconsistent-transactions/router/inconsistent-transactions.router.js";
import BcDashboard from "@/views/boost-credit/views/dashboard/router/bc-dashboard.router.js";

import BoostCreditPendingPaymentsRouter from "@/views/boost-credit/views/pending-payments/router/pending-payments.router.js";

import CallImpressionRouter from "@/views/boost-credit/views/call-impression/router/call-impression.router.js";

const routes = [
  {
    path: "/boostcredit",
    redirect: {
      name: "boostcredit-dashboard",
    },
    component: BoostCreditMain,
    meta: { module: 7, program: 2 },
    children: [
      ...BcDashboard,
      ...leadsRoute,
      ...clientRoute,
      ...callRoundsRoute,
      ...zeroPayment,
      ...applicationsRouter,
      ...ncrRealtor,
      ...ncrProgram,
      ...RequestNcrRouter,
      ...RequestWorkplanRouter,
      ...letterRoute,
      ...DirectRouter,
      ...disputeResults,
      ...ncrRoute,
      ...reportsRoute,
      ...trackingListRoute,
      ...paymentsRoute,
      ...saleMadeRouter,
      ...commissionRoute,
      ...chargeBackRoute,
      ...calendarRoute,
      ...loansRoute,
      ...schedulesRouter,
      ...performanceRouter,
      ...claimsNav,
      helpdeskRoute,
      fileManagerRoute,
      ...ProductRequestRouter,
      ...OtherPaymentsRouter,
      ...BcAttendanceRouter,
      ...EmployeeClaimsRouter,
      //...BoostCreditsProjectsRouter,
      ...CallLogRouter,
      ...CourtInfoRouter,
      ...DashboardCalls,

      ...RecruitmentProcessRouter,
      ...MOFRouter,
      ...CustomerTicketsRouter,
      ...SendingReportRouter,
      ...AppointmentRouter,
      ...PotencialAppointmentRouter,
      ...OtherServicesRouter,
      ...CommissionsRouter,
      ...RequestClientStatusRouter,
      ...InconsistentTransactionRouter,

      ...BoostCreditPendingPaymentsRouter,
      ...CallImpressionRouter
    ],
  },
];

export default routes;
