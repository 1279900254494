import PerformanceUpdatedNotification from "@/views/commons/components/advisor-performance/performance-updated.pusher.js"
import UnavailableBalanceNotification from "@/views/debt-solution/views/settlements-made/report-unavailable-balance.pusher.js"
import TimelineGeneratedNotification from "@/views/commons/components/clients/dashboard/information-client/time-line/socket/timeline-generated-notification"
import { BinnacleMessageNotification } from "@/views/crm/crm.socket.js"
import { PhaseChangeNotification,NewCasesNotification,ReconfirmCasesNotification } from "@/views/commons/components/clients/clients-notification.js"
import { PermissionNotificationDS, PermissionAttenNotificationDS } from "@/views/commons/components/permissions/permission-notifications.js"
import { RecurrencyTaskForTracking } from "@/views/debt-solution/ds-court-info.js"; 

const subscribeSocketDebtSolution = (socket) => {
  try {
    socket.subscribe("debtsolution-notifications")
    PerformanceUpdatedNotification()
    UnavailableBalanceNotification()
    TimelineGeneratedNotification()
    BinnacleMessageNotification()
    PhaseChangeNotification()
    PermissionNotificationDS()
    PermissionAttenNotificationDS()
    RecurrencyTaskForTracking()
    NewCasesNotification()
    ReconfirmCasesNotification() 
  } catch (error) {
    console.error(error, "error")
  }
}

export default subscribeSocketDebtSolution
