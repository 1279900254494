export default [
  {
    path: "schedules",
    name: "schedules-tax-research",
    redirect: { name: "report-tax-research" },
    component: () => import(/* webpackChunkName: "TaxResearchSchedules" */ "@/views/commons/components/schedules/SchedulesComponent.vue"),
    children: [
      {
        path: "report",
        name: "report-tax-research",
        component: () => import(/* webpackChunkName: "TaxResearchSchedulesReport" */ "@/views/commons/components/schedules/SchedulesReport.vue"),
        meta: {
          route: "taxresearch",
          permittedRoles: [1, 2],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Reports By Module",
              active: true,
            },
          ],
        },
      },
      {
        path: "report-by-user",
        name: "report-by-user-tax-research",
        component: () => import(/* webpackChunkName: "TaxResearchSchedulesReportByUser" */ "@/views/commons/components/schedules/SchedulesReportByUser.vue"),
        meta: {
          route: "taxresearch",
          permittedRoles: [1, 2],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Report by User",
              active: true,
            },
          ],
        },
      },
      {
        path: "overtime",
        name: "overtime-tax-research",
        component: () => import(/* webpackChunkName: "TaxResearchSchedulesOvertime" */ "@/views/commons/components/schedules/SchedulesOvertime.vue"),
        meta: {
          route: "taxresearch",
          permittedRoles: [1, 2],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Overtime",
              active: true,
            },
          ],
        },
      },
      {
        path: "justifications",
        name: "justifications-tax-research",
        component: () => import(/* webpackChunkName: "TaxResearchSchedulesJustifications" */ "@/views/commons/components/schedules/SchedulesJustifications.vue"
        ),
        meta: {
          route: "taxresearch",
          permittedRoles: [1, 2],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Justifications",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      route: "taxresearch",
      permittedRoles: [1, 2],
    },
  },
];
