<template>
  <div>
    <b-modal
      ref="chat"
      size="caro"
      :title="`Chat: ${customerTicketChat.name_client}`"
      title-class="h3 font-weight-bolder text-white"
      hide-footer
      :body-bg-variant="isDarkSkin ? 'black' : 'light'"
      scrollbar
      centered
      @hidden="closeModal()"
    >
      <div class="customer-chat">
        <div class="chat-sidebar">
          <section
            v-if="showTicketInformation"
            style="position: relative"
            class="ticket-information px-1"
            :style="
              isDarkSkin
                ? 'background-color: #212123'
                : 'background-color: #FFFFFF'
            "
          >
            <div class="d-flex align-items-end">
              <div
                class="list-group-item-primary rounded"
                style="padding: 4px; margin-right: 5px"
              >
                <tabler-icon
                  icon="TicketIcon"
                  size="16"
                  class="text-primary"
                  style="transform: rotate(-45deg)"
                />
              </div>
              <span style="font-size: 16px" class="font-weight-bolder">
                Information
              </span>
              <b-button
                v-if="isCeo"
                size="sm"
                class="ml-auto btn-chat-ceo font-weight-bolder"
                @click="openModalTicketSummary"
              >
                <feather-icon icon="FeatherIcon" /> Add Summary
              </b-button>
            </div>
            <hr />
            <div class="ticket-information-container" style="margin-top: 10px">
              <div
                v-for="item in ticketItems"
                :key="`ticket-item-${item.key}`"
                class="ticket-information-item ml-2"
              >
                <span
                  :class="item.name === 'Description' ? 'd-none' : ''"
                  style="
                    min-width: 100px;
                    font-size: 13px;
                    font-weight: 600;
                    font-family: 'Inter', sans-serif;
                  "
                  class="text-dark text-capitalize"
                  :style="
                    isDarkSkin
                      ? 'color: #BBBBBB !important;'
                      : 'color: #69696A !important;'
                  "
                >
                  {{ item.name }}
                </span>
                <div
                  class="position-relative"
                  :class="item.key.toLowerCase() === 'description' ? '' : ''"
                >
                  <b-badge
                    v-if="['status', 'priority'].includes(item.key)"
                    style="font-size: 13px"
                    :variant="item.class"
                    class="d-flex justify-content-start align-items-center w-auto text-capitalize"
                  >
                    <feather-icon
                      v-if="item.icon"
                      :icon="item.icon"
                      size="15"
                      style="color: currentColor; margin-right: 5px"
                    />
                    {{ item.value }}
                  </b-badge>
                  <template
                    v-else-if="
                      item.key.toLowerCase() === 'motive' && !!item.value
                    "
                  >
                    <span
                      :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                      class="text-capitalize"
                      style="
                        max-height: 100px;
                        max-width: 200px;
                        overflow-y: auto;
                        font-size: 13px;
                      "
                    >
                      {{
                        item.value.length > limitMotive && !showAllMotive
                          ? item.value.substring(0, limitMotive)
                          : item.value
                      }}
                    </span>
                    <span
                      v-b-tooltip.hover.top="'Show description'"
                      v-b-tooltip.hover.v-info
                      class="btn-chat-ceo"
                      style="
                        cursor: pointer;
                        color: white;
                        padding: 1px 4px 4px 4px;
                        border-radius: 5px;
                      "
                      @click="openModalShow(item, customerTicketChat)"
                    >
                      <feather-icon icon="EyeIcon" size="16" />
                    </span>
                  </template>
                  <span
                    v-else
                    style="font-size: 13px"
                    class="text-capitalize"
                    :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                  >
                    <template v-if="item.key === 'createdAt'">
                      {{ item.value | myGlobalDay }}
                    </template>
                    <template v-else>
                      {{ item.value }}
                    </template>
                  </span>
                </div>
              </div>

              <template v-if="isCeo">
                <div class="d-flex justify-content-start align-items-end mt-1">
                  <div
                    class="list-group-item-primary rounded"
                    style="padding: 4px; margin-right: 5px"
                  >
                    <tabler-icon
                      icon="SettingsIcon"
                      size="16"
                      class="text-primary"
                    />
                  </div>

                  <span style="font-size: 16px" class="font-weight-bolder">
                    Actions
                  </span>
                </div>
                <div style="border-top: 1px solid #ebe9f1 !important" />
                <div
                  class="ticket-information-container"
                  style="margin: 7px 0px 0px 0px"
                >
                  <div class="d-flex justify-content-center flex-column px-1">
                    <b-button-group
                      :hidden="
                        status_customer_ticket >= 4 ||
                        status_customer_ticket === 1
                      "
                    >
                      <b-button
                        v-if="status_customer_ticket === 2"
                        size="sm"
                        variant="outline-danger"
                        :disabled="status_customer_ticket === 1"
                        @click="openSolveTicketModal(4, customerTicketChat)"
                      >
                        REJECT
                      </b-button>
                      <b-button
                        v-if="
                          status_customer_ticket === 2 ||
                          status_customer_ticket === 1
                        "
                        size="sm"
                        variant="outline-warning"
                        :disabled="status_customer_ticket === 1"
                        @click="openCustomerChat(customerTicketChat, false)"
                      >
                        <!-- @click="sendToInProgress" -->
                        SEND IN PROGRESS
                      </b-button>
                      <template
                        v-if="customerTicketChat.type_ticket != 'PAYMENTS'"
                      >
                        <b-button
                          v-if="status_customer_ticket === 3"
                          size="sm"
                          variant="outline-primary"
                          @click="
                            openSolveTicketModal(
                              'fidelized',
                              customerTicketChat
                            )
                          "
                        >
                          SOLVED
                        </b-button>
                        <b-button
                          v-if="status_customer_ticket === 3"
                          size="sm"
                          variant="outline-warning"
                          @click="
                            openSolveTicketModal(
                              'no-fidelized',
                              customerTicketChat
                            )
                          "
                        >
                          NOT SOLVED
                        </b-button>
                      </template>
                      <template v-else>
                        <b-button
                          v-if="status_customer_ticket === 3"
                          class="text-center mx-3"
                          size="sm"
                          variant="outline-primary"
                          @click="openSolveTicketModal(9, customerTicketChat)"
                        >
                          COMPLETED
                        </b-button>
                      </template>
                    </b-button-group>
                    <p
                      v-if="status_customer_ticket >= 4"
                      class="text-success text-center"
                    >
                      <feather-icon icon="CheckCircleIcon" /> NO SHARES FOR NOW
                    </p>
                  </div>
                </div>
              </template>
              <div class="d-flex justify-content-start align-items-end mt-1">
                <div
                  class="list-group-item-primary rounded"
                  style="padding: 4px; margin-right: 5px"
                >
                  <tabler-icon icon="ListIcon" size="16" class="text-primary" />
                </div>

                <span style="font-size: 16px" class="font-weight-bolder">
                  Tasks
                </span>
              </div>
              <div style="border-top: 1px solid #ebe9f1 !important" />
              <b-button-group class="px-1">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="addTask(customerTicketChat)"
                >
                  CREATE TASK
                </b-button>
                <b-button
                  size="sm"
                  variant="outline-info"
                  @click="viewListTasks(customerTicketChat)"
                >
                  SHOW TASKS
                </b-button>
              </b-button-group>
            </div>
          </section>
          <section
            class="ticket-participant"
            :style="
              isDarkSkin
                ? 'background-color: #212123'
                : 'background-color: #FFFFFF'
            "
          >
            <template>
              <template v-if="!showAddParticipants">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-start align-items-end">
                    <div
                      class="list-group-item-primary rounded"
                      style="padding: 4px; margin-right: 5px"
                    >
                      <tabler-icon
                        size="16"
                        class="text-primary"
                        icon="UsersIcon"
                      />
                    </div>
                    <span style="font-size: 16px" class="font-weight-bolder">
                      <span v-if="selectedView === 1">
                        Users ({{ ticketParticipants.length }})
                      </span>
                      <span v-if="selectedView === 2">
                        Calls ({{ callsAudio.length }})
                      </span>
                      <span v-if="selectedView === 3"> Files </span>
                    </span>
                  </div>
                  <div
                    class="w-auto rounded overflow-hidden d-flex justify-content-center align-item-center p-0 m-0 border-primary"
                    :class="isDarkSkin ? 'bg-transparent' : ''"
                  >
                    <div
                      v-for="(item, index) in filterOptionsView"
                      :key="index"
                      class="position-relative w-auto d-flex justify-content-center align-items-center"
                    >
                      <input
                        :id="`option_${index}`"
                        v-model="selectedView"
                        type="radio"
                        :value="item.value"
                        name="otionsView"
                        class="d-none"
                      />
                      <label
                        :for="`option_${index}`"
                        class="text-center m-0 cursor-pointer rounded-sm paint"
                        style="padding: 8px 10px"
                        :class="
                          item.value === selectedView
                            ? 'btn-chat-ceo text-white'
                            : ''
                        "
                      >
                        <feather-icon
                          :icon="item.icon ? item.icon : 'CircleIcon'"
                          size="13"
                          style="margin-right: 5px"
                        />
                        <span
                          class="p-0 m-0 text-capitalize"
                          style="font-size: 13px"
                          >{{ item.text }}</span
                        >
                      </label>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="" v-if="selectedView === 1">
                  <!--PARTICIPANTS-->
                  <b-row
                    class="customer-class"
                    style="height: 250px !important"
                    :style="
                      myParticipants.length === ticketParticipants.length
                        ? 'overflow-y: auto !important;'
                        : 'overflow-y: hidden !important;'
                    "
                  >
                    <b-col
                      v-for="(item, index) in myParticipants"
                      :key="index"
                      cols="4"
                      class="d-block text-center"
                      :class="{
                        'cursor-pointer': getUserId === 1 && item.id_user !== 1,
                      }"
                      style="padding: 3px"
                      @click="openModalEmployeeActivityRecords(item)"
                    >
                      <b-avatar
                        v-b-tooltip.hover.top="item.status_session_name"
                        size="40"
                        :src="item.image"
                        variant="light-primary"
                        badge
                        class="badge-minimal"
                        :badge-variant="item.badge"
                      >
                        <feather-icon
                          v-if="!item.image"
                          icon="UserIcon"
                          size="19"
                        />
                      </b-avatar>
                      <div
                        class="position-relative text-center"
                        style="margin-top: 5px"
                      >
                        <strong>{{ item.user_name }}</strong
                        ><br />
                        <div
                          class="position-relative d-flex flex-wrap justify-content-center align-items-center"
                        >
                          <small
                            v-for="(module, indexm) in JSON.parse(item.modules)"
                            :key="indexm * -1"
                            :class="
                              indexm < JSON.parse(item.modules).length - 1
                                ? 'border-right'
                                : ''
                            "
                            style="padding: 2px 5px"
                          >
                            {{ module.role_name }}
                          </small>
                        </div>
                      </div>
                    </b-col>
                    <!--buttons to actions-->
                    <b-col
                      v-if="
                        ticketParticipants.length < limitParticipants && isCeo
                      "
                      cols="4"
                      class="d-flex flex-column align-items-center"
                    >
                      <b-button
                        v-if="hideAfterInProgress()"
                        class="rounded-circle"
                        style="padding: 10px"
                        variant="primary"
                        size="sm"
                        @click="showSectionAddParticipants"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="20"
                          class="text-white"
                        />
                      </b-button>
                      <strong
                        v-if="hideAfterInProgress()"
                        class="text-primary text-center"
                        style="line-height: 14px; margin-top: 2px"
                        >Add<br />
                        participant</strong
                      >
                    </b-col>
                  </b-row>
                  <!--BUTTONS TO ACTIONS-->
                  <b-row
                    v-if="ticketParticipants.length >= limitParticipants"
                    class="pt-1"
                  >
                    <b-col
                      v-if="isCeo"
                      cols="4"
                      class="d-flex flex-column justify-content-center align-items-center"
                    >
                      <b-button
                        v-if="hideAfterInProgress()"
                        class="p-1 rounded-circle"
                        variant="primary"
                        style="transform: scale(0.9)"
                        @click="showSectionAddParticipants"
                      >
                        <feather-icon
                          icon="PlusIcon"
                          size="20"
                          class="text-white"
                        />
                      </b-button>
                      <strong
                        v-if="hideAfterInProgress()"
                        class="text-primary text-center"
                        style="line-height: 14px; margin-top: 8px"
                        >Add<br />
                        participant</strong
                      >
                    </b-col>
                    <b-col
                      v-if="ticketParticipants.length > limitParticipants"
                      cols="4"
                      class="d-flex flex-column justify-content-center align-items-center"
                    >
                      <b-button
                        style="width: 48px; height: 48px"
                        class="p-1 rounded-circle d-flex justify-content-center align-items-center"
                        variant="info"
                        size="sm"
                        @click="showAllParticipants = !showAllParticipants"
                      >
                        <strong
                          >{{ showAllParticipants ? " - " : " + " }}
                          {{
                            ticketParticipants.length - limitParticipants
                          }}</strong
                        >
                      </b-button>
                      <strong
                        class="text-info text-center"
                        style="line-height: 14px; margin-top: 8px"
                      >
                        Show<br />
                        {{ showAllParticipants ? "less" : "all" }}</strong
                      >
                    </b-col>
                  </b-row>
                </div>
                <template v-else-if="selectedView === 2">
                  <b-row
                    class="list-scroller"
                    style="max-height: 250px; overflow-y: auto"
                  >
                    <b-col class="d-block">
                      <template v-if="callsAudio.length">
                        <div
                          v-for="(item, index) in callsAudio"
                          :key="index + 1 * -1"
                          class="d-flex justify-content-center align-items-center rounded"
                          style="padding: 0px 10px; margin: 3px 0px"
                          :class="
                            item.from_name.toLowerCase() === 'roger segura'
                              ? 'border-info text-white'
                              : ''
                          "
                        >
                          <div
                            :id="`call_id_${item.call_id}`"
                            class="position-relative mr-1"
                            style="
                              width: auto;
                              min-width: 20px;
                              min-height: 20px;
                            "
                          >
                            <feather-icon
                              icon="InfoIcon"
                              size="20"
                              :class="
                                item.from_name.toLowerCase() === 'roger segura'
                                  ? 'text-info'
                                  : 'text-warning'
                              "
                              class="cursor-pointer text-white"
                            />
                            <small
                              v-if="
                                item.from_name.toLowerCase() === 'roger segura'
                              "
                              class="text-success"
                            >
                              CEO
                            </small>
                          </div>
                          <b-tooltip
                            placement="right"
                            :variant="
                              item.from_name.toLowerCase() === 'roger segura'
                                ? 'info'
                                : 'warning'
                            "
                            :target="`call_id_${item.call_id}`"
                          >
                            <b-list-group
                              :style="
                                isDarkSkin
                                  ? 'background:#3c342d !important; border: none;'
                                  : ''
                              "
                            >
                              <b-list-group-item
                                class="bg-transparent border-white"
                              >
                                <b class="mr-1">From:</b> {{ item.from_name
                                }}<br />
                                <b class="mr-1">Number: </b>{{ item.from_number
                                }}<br />
                              </b-list-group-item>
                              <b-list-group-item
                                class="bg-transparent border-white"
                              >
                                <b class="mr-1">To:</b> {{ item.to_name }}<br />
                                <b class="mr-1">Number: </b>{{ item.to_number
                                }}<br />
                              </b-list-group-item>
                              <b-list-group-item
                                class="bg-transparent border-white"
                              >
                                <b class="mr-1">Status:</b>
                                <b>{{ item.status }}</b
                                ><br />
                                <b class="mr-1">Start time: </b
                                >{{ item.start_time | myGlobalDay }}<br />
                              </b-list-group-item>
                            </b-list-group>
                          </b-tooltip>
                          <audio-player
                            style="width: 90% !important"
                            :index="index"
                            :time="item.duration"
                            :src="item.record_url ? item.record_url : ''"
                          />
                        </div>
                      </template>
                      <template v-else>
                        <div
                          class="d-flex justify-content-center align-items-center"
                          style="height: 250px"
                        >
                          <template v-if="busyCalls">
                            <feather-icon
                              icon="LoaderIcon"
                              size="30"
                              class="text-white mr-1 load"
                            />
                            <strong class="text-white" style="font-size: 20px"
                              >Loading...</strong
                            >
                          </template>
                          <template v-else>
                            <feather-icon
                              icon="MicOffIcon"
                              size="30"
                              class="text-white mr-1"
                            />
                            <strong class="text-white" style="font-size: 20px"
                              >No audio</strong
                            >
                          </template>
                        </div>
                      </template>
                    </b-col>
                  </b-row>
                </template>
                <!--FILES: AUDIO, IMAGE, ETC-->
                <template v-else-if="selectedView === 3">
                  <div
                    class="position-relative list-scroller"
                    style="max-height: 250px; overflow-y: auto"
                  >
                    <template v-if="optionFiles">
                      <div :key="keyFiles">
                        <section
                          v-for="(item, key) in optionFiles"
                          :key="key"
                          class="pb-2 border-bottom"
                        >
                          <div
                            class="d-flex justify-content-between align-items-center p-1"
                          >
                            <strong
                              class="text-primary"
                              style="font-size: 13px"
                              >{{ key }}</strong
                            >
                            <b-button
                              variant="outline-primary"
                              size="sm"
                              @click="
                                viewAllFiles(
                                  optionFiles[key][0]['type_id'],
                                  customerTicketChat.id
                                )
                              "
                            >
                              View All
                            </b-button>
                          </div>
                          <div
                            class="d-flex justify-content-start align-items-center flex-wrap overflow-hidden w-100"
                          >
                            <div
                              v-for="(file, index) in item.filter(
                                (file) => file.file !== null
                              )"
                              :key="index"
                              style="border: none !important; margin: 3px 5px"
                              :class="
                                key.toLowerCase() === 'image'
                                  ? 'w-auto'
                                  : 'w-100'
                              "
                            >
                              <audio-player
                                v-if="file.type_name.toLowerCase() === 'audio'"
                                :index="index + 1 * -100"
                                :src="file.file ? file.file : ''"
                                :chat="true"
                                style="width: 90%; height: auto"
                              />
                              <div
                                v-else-if="
                                  file.type_name.toLowerCase() === 'image'
                                "
                                class="position-relative cursor-pointer contain-img"
                                @click="showImageViewer(file.file)"
                              >
                                <div
                                  class="placeholder-img d-flex justify-content-center align-items-center"
                                >
                                  <feather-icon
                                    icon="EyeIcon"
                                    class="text-white"
                                    size="20"
                                  />
                                </div>
                                <img
                                  :src="file.file"
                                  :alt="`${file.type_name}_${index}`"
                                  style="
                                    max-width: 100px;
                                    width: 100%;
                                    height: 100px;
                                  "
                                  class="rounded"
                                />
                              </div>
                              <b-row
                                v-else-if="
                                  file.type_name.toLowerCase() === 'file'
                                "
                              >
                                <b-col cols="12">
                                  <div
                                    class="d-flex justify-content-between align-items-center px-1"
                                    style="margin: 5px 0px"
                                  >
                                    <div>
                                      <feather-icon
                                        icon="FileTextIcon"
                                        :class="styleFile.color[file.extension]"
                                        class="mr-1"
                                        size="20"
                                      />
                                      <b
                                        class="mr-1"
                                        :class="
                                          isDarkSkin
                                            ? 'text-white'
                                            : 'text-black'
                                        "
                                        style="font-size: 14px"
                                        >{{
                                          styleFile.name[file.extension] ||
                                          "OTHER"
                                        }}</b
                                      >
                                      <span
                                        style="font-size: 14px"
                                        :class="
                                          isDarkSkin
                                            ? 'text-white'
                                            : 'text-black'
                                        "
                                        >{{
                                          file.created_at | myGlobalDay
                                        }}</span
                                      >
                                    </div>
                                    <a
                                      v-b-tooltip.hover.v-warning
                                      :href="file.file"
                                      target="_blank"
                                      class="d-flex justify-content-start align-items-center rounded hover-me w-auto mr-1"
                                      title="DOWNLOAD"
                                    >
                                      <feather-icon
                                        icon="DownloadIcon"
                                        size="18"
                                        class="text-warning"
                                      />
                                    </a>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </div>
                        </section>
                      </div>
                    </template>
                    <template v-else>
                      <section
                        style="height: 250px"
                        class="d-flex justify-content-center align-items-center"
                      >
                        <template v-if="busyFiles">
                          <feather-icon
                            icon="LoaderIcon"
                            size="30"
                            class="mr-1 text-white load"
                          />
                          <b style="font-size: 20px" class="text-white"
                            >Loading ...</b
                          >
                        </template>
                        <template v-else>
                          <feather-icon
                            icon="EyeOffIcon"
                            size="30"
                            class="mr-1 text-white"
                          />
                          <b style="font-size: 20px" class="text-white"
                            >No Files</b
                          >
                        </template>
                      </section>
                    </template>
                  </div>
                </template>
              </template>
              <!--ADD USERS-->
              <template v-else-if="showAddParticipants">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-start align-items-center">
                    <div
                      class="list-group-item-primary rounded mr-1"
                      style="padding: 6px 10px"
                    >
                      <feather-icon
                        icon="UsersIcon"
                        size="18"
                        class="text-primary"
                      />
                    </div>
                    <h3
                      class="m-0"
                      :class="isDarkSkin ? 'text-white' : 'text-black'"
                    >
                      Add participants
                    </h3>
                  </div>
                  <div class="d-flex justify-content-center align-items-center">
                    <b-badge variant="primary" style="font-size: 13px">
                      {{ addTicketParticipants.length }}
                    </b-badge>
                  </div>
                </div>
                <hr />
                <div class="my-1">
                  <b-row>
                    <b-col cols="12">
                      <b-form-input
                        v-model="searchParticipants"
                        style="border-radius: 50px !important"
                        placeholder="Search user name or employee name ..."
                        @keyup="getUsers"
                      />
                    </b-col>
                  </b-row>
                </div>
                <!--users-->
                <div
                  class="position-relative"
                  style="height: 100%; min-height: 650px; max-height: 650px"
                >
                  <!--SELECTED USERS-->
                  <div
                    v-if="addTicketParticipants.length"
                    class="rounded-lg shadow list-scroller"
                    style="max-height: 250px; overflow-y: auto"
                    :style="
                      allEmployeeAdded
                        ? 'max-height: 500px;'
                        : 'max-height:250px;'
                    "
                  >
                    <b-list-group flush class="">
                      <b-list-group-item
                        v-for="(item, index) in addTicketParticipants"
                        :key="index"
                        class="d-flex justify-content-between align-items-center border-0"
                        :class="isDarkSkin ? 'bg-transparent' : ''"
                      >
                        <div
                          class="d-flex justify-content-center align-items-center"
                        >
                          <b-avatar
                            :id="`item-${index}`"
                            :src="item.image"
                            variant="info"
                            style="margin: 5px"
                            :alt="item.user_name"
                          />
                          <div class="d-block ml-1">
                            <strong :class="item.isNew ? 'text-primary' : ''">{{
                              item.user_name
                            }}</strong
                            ><br />
                            <small
                              v-for="(module, indexm) in JSON.parse(
                                item.modules
                              )"
                              :key="indexm * -1"
                              :class="
                                index === JSON.parse(item.modules) - 1
                                  ? 'border-right'
                                  : ''
                              "
                            >
                              {{ module.role_name }}
                            </small>
                          </div>
                        </div>
                        <feather-icon
                          v-if="
                            ![1, customerTicketChat.created_by_id].includes(
                              item.id_user
                            )
                          "
                          v-b-tooltip.hover.v-danger
                          v-b-tooltip.hover.left
                          icon="XCircleIcon"
                          size="20"
                          class="text-danger hover-me cursor-pointer"
                          title="REMOVE"
                          @click="toggleParticipant(item)"
                        />
                        <b
                          v-else
                          class="text-success text-uppercase"
                          style="font-size: 11px !important"
                          >{{ item.id_user === 1 ? "ceo" : "creator" }}</b
                        >
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <!--LIST OF USERS-->
                  <div
                    class="position-relative list-scroller-2 bg-transparent"
                    style="overflow-y: auto"
                    :style="`max-height: calc(550px - ${
                      addTicketParticipants.length < 4
                        ? addTicketParticipants.length * 60
                        : 4 * 60
                    }px) !important;`"
                  >
                    <b-list-group flush>
                      <template v-if="myDataUsers.length">
                        <b-list-group-item
                          v-for="(item, index) in myDataUsers"
                          :key="index"
                          class="d-flex justify-content-between align-items-center border-0 bg-transparent hover-bg-secondary rounded"
                        >
                          <div
                            class="d-flex justify-content-center align-items-center"
                          >
                            <b-avatar
                              :id="`item-select-${index}`"
                              :src="item.image"
                              variant="info"
                              style="margin: 5px"
                              :alt="item.user_name"
                            />
                            <div class="d-block ml-1">
                              <strong>{{ item.user_name }}</strong
                              ><br />
                              <small
                                v-for="(module, indexm) in JSON.parse(
                                  item.modules
                                )"
                                :key="indexm * -1"
                                :class="
                                  index === JSON.parse(item.modules) - 1
                                    ? 'border-right'
                                    : ''
                                "
                              >
                                {{ module.role_name }}
                              </small>
                            </div>
                          </div>
                          <feather-icon
                            v-if="item.id_user !== 1"
                            v-b-tooltip.hover.v-success
                            v-b-tooltip.hover.left
                            icon="PlusCircleIcon"
                            size="20"
                            class="text-success hover-me cursor-pointer"
                            title="ADD"
                            @click="toggleParticipant(item)"
                          />
                        </b-list-group-item>
                      </template>
                      <template v-else>
                        <b-list-group-item
                          class="d-flex justify-content-center align-items-center border-0 bg-transparent hover-bg-secondary"
                        >
                          {{
                            allEmployeeAdded
                              ? "All employees are already added"
                              : "Employee(s) No found"
                          }}
                        </b-list-group-item>
                      </template>
                    </b-list-group>
                  </div>
                  <!--ACTIONS-->
                  <b-row
                    class="mt-2"
                    style="position: absolute; bottom: 10px; right: 0px"
                  >
                    <b-col cols="12" class="d-flex justify-content-end">
                      <b-button
                        variant="secondary"
                        class="mr-1"
                        @click="closeAddParticipants"
                      >
                        Cancel
                      </b-button>
                      <b-button variant="success" @click="saveAddParticipants">
                        <feather-icon icon="SaveIcon" />
                        Save
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </template>
            </template>
            <!--CALLS AUDIO-->
          </section>
        </div>
        <!--MESSAGES TICKET-->
        <div
          class="chat-container position-relative"
          :class="isDarkSkin ? 'bg-black' : 'bg-white'"
          @dragenter="onDragEnter"
          @dragover="onDragOver"
          @dragleave="onDragLeave"
          @drop="onDrop"
        >
          <!--? Message header-bar-->
          <header
            style="
              position: absolute;
              top: 0px;
              left: 0px;
              z-index: 9;
              border-bottom: 1px solid rgba(238, 238, 238, 1);
            "
            :style="
              isDarkSkin
                ? 'background-color: #121212'
                : 'background-color: #fbfbfb'
            "
            class="p-1 w-100 d-block d-xl-flex justify-content-between align-items-center position-relative font-weight-bolder"
          >
            <b-list-group class="m-0 p-0" v-if="customerTicketChat.type_ticket !== 'EXPENSES'">
              <b-list-group-item
                class="bg-transparent d-flex align-items-center pb-0"
                style="border: none"
              >
                <router-link
                  v-if="isCeo"
                  class="text-capitalize"
                  style="font-size: 16px"
                  :to="{
                    name: 'ticket-customer-lead-show',
                    params: {
                      id: customerTicketChat.lead_id_client,
                    },
                  }"
                  target="_blank"
                >
                  {{
                    customerTicketChat.client_account ? "Client: " : "Lead: "
                  }}
                  {{ customerTicketChat.name_client }}
                </router-link>
                <router-link
                  v-else
                  v-b-tooltip.hover.top="'Lead dashboard'"
                  class="text-capitalize"
                  style="font-size: 16px"
                  :class="textLink"
                  :to="`/${
                    isCeo ? 'debtsolution' : routeModule(currentUser.modul_id)
                  }/leads/${
                    currentUser.modul_id === 2 || currentUser.modul_id === 4
                      ? ''
                      : 'show/'
                  }${customerTicketChat.lead_id_client}`"
                  target="_blank"
                >
                  {{
                    customerTicketChat.client_account ? "Client: " : "Lead: "
                  }}
                  {{ customerTicketChat.name_client }}
                </router-link>
                <span
                  v-if="customerTicketChat.number_phone"
                  class="m-0 ml-1"
                  style="font-size: 16px"
                >
                  {{ customerTicketChat.number_phone }}
                </span>
              </b-list-group-item>
              <div
                v-if="customerTicketChat.client_account"
                style="display: flex; gap: 1rem; padding: 0 0 0 1rem"
              >
                <div style="display: flex; align-items: center; gap: 0.3rem">
                  <status-account :account="customerTicketChat" :text="false" />
                  <router-link
                    :class="isDarkSkin ? 'text-white' : 'text-dark'"
                    :to="{
                      name: dashboardRouteNameByClient(customerTicketChat),
                      params: {
                        idClient: customerTicketChat.client_account_id,
                      },
                    }"
                    target="_blank"
                  >
                    {{ customerTicketChat.account }}
                  </router-link>
                </div>
                <div
                  v-for="account in uniqueAccounts(
                    JSON.parse(customerTicketChat.accounts),
                    customerTicketChat.client_account_id
                  )"
                  :key="account.id"
                  class="d-flex"
                  style="align-items: center; gap: 1rem"
                >
                  <status-account :account="account" :text="false" />
                  <router-link
                    :class="isDarkSkin ? 'text-white' : 'text-dark'"
                    :to="{
                      name: dashboardRouteNameByClient(account),
                      params: {
                        idClient: account.id,
                      },
                    }"
                    target="_blank"
                  >
                    {{ account.account }}
                  </router-link>
                </div>
              </div>
            </b-list-group>
            <div class="d-flex justify-content-end align-items-center w-auto">
              <template v-if="searchMessage">
                <feather-icon
                  v-b-tooltip.hover.v-danger
                  title="CLOSE"
                  :size="searchMessage ? '30' : '20'"
                  icon="XCircleIcon"
                  class="cursor-pointer text-danger"
                  @click="searchMessage = false"
                />
                <b-form-input
                  id="txt-search-message"
                  v-model="searchInput"
                  class="input-search-message active-search mx-1 shadow"
                  placeholder="Search message..."
                  style="border: none !important"
                />
              </template>
              <feather-icon
                v-b-tooltip.hover.v-primary
                :size="searchMessage ? '30' : '25'"
                icon="SearchIcon"
                class="cursor-pointer mr-2"
                title="SEARCH"
                @click="searchMessage = true"
              />
            </div>
          </header>
          <!--! CONTAIN MESSAGES -->
          <div
            id="chat"
            class="messages-container"
            style="padding-top: 0px; padding-bottom: 30px"
            :style="[
              isDarkSkin
                ? { 'background-color': '#212123' }
                : { 'background-color': '#FFFFFF' },
              isDraggingFile ? { border: '2px dashed #007bff !important' } : {},
            ]"
          >
            <!--!Chat messages area -->
            <template v-if="!modalUploadFiles">
              <!--Messages-Container -->
              <div
                v-for="(message, index) in messages"
                :id="`message-${message.chatTicketMessageId}`"
                :key="`message-${index}`"
                style="display: flex; column-gap: 1em; z-index: 2"
                :class="
                  message.creatorId !== getUserId
                    ? ![6, 7].includes(message.typeMessage)
                      ? 'message-left'
                      : isDarkSkin
                      ? 'message-center-dark'
                      : 'message-center'
                    : [6, 7].includes(message.typeMessage)
                    ? isDarkSkin
                      ? 'message-center-dark'
                      : 'message-center'
                    : 'message-right'
                "
                class="mt-1 position-relative"
              >
                <!--?If type of message is invitation o notificacentertion show this view-->
                <div
                  v-if="[6, 7].includes(message.typeMessage)"
                  class="message-notification"
                >
                  {{ message.message }}
                </div>
                <div
                  v-if="
                    lastMessageIdNotviewed == message.chatTicketMessageId &&
                    lastMessageIdNotviewed &&
                    countMessagesNotViewed > 0
                  "
                  class="message-not-viewed"
                  :style="
                    [6, 7].includes(message.typeMessage)
                      ? 'left:40%'
                      : 'top:-2.7em;left:40%'
                  "
                  :class="
                    isDarkSkin
                      ? 'more-options-selector-container-dark'
                      : 'more-options-selector-container-light'
                  "
                >
                  {{ countMessagesNotViewed }} messages not viewed
                </div>
                <!--?Else show reamining types  first show creatorImage and last message contain-->
                <div
                  v-if="![6, 7].includes(message.typeMessage)"
                  class="d-flex flex-row column-gap-2 position-relative"
                  :class="
                    message.creatorId !== getUserId
                      ? 'flex-row '
                      : 'flex-row-reverse'
                  "
                >
                  <!--?Creator message image-->
                  <div class="message-creator-image">
                    <b-avatar
                      :id="`message-${index}`"
                      :src="message.creatorImage"
                      variant="info"
                      style="margin: 5px"
                      alt="message-creator-img"
                    />
                    <b-tooltip
                      triggers="hover"
                      :target="`message-${index}`"
                      :title="message.creatorName"
                      :delay="{ show: 100, hide: 50 }"
                    />
                  </div>
                  <!--!Remaining messages Types Container-->
                  <!--?If message creator it's same that current chat user set position on rigth else on left-->
                  <!--*Message body-->
                  <div class="message-content">
                    <div
                      :id="`message-item-${message.chatTicketMessageId}`"
                      style="border-radius: 16px"
                      class="message-item pb-1 px-2 message-body"
                      :class="
                        message.creatorId !== getUserId
                          ? isDarkSkin
                            ? 'bg-secondary-chat'
                            : 'bg-light-chat'
                          : isDarkSkin
                          ? 'bg-ceo-chat text-white'
                          : ''
                      "
                      @click="closeReactionsOptionsSelectors()"
                    >
                      <!--*Message edited-->

                      <!--* Message Replied-->
                      <div
                        v-if="message.repliedMessage"
                        class="rounded mb-1 is-reply-message position-relative"
                        :style="
                          isDarkSkin
                            ? 'background:#34495E'
                            : 'background:#D0D3D4'
                        "
                      >
                        <div
                          v-for="(
                            messageReplied, messageRepliedIndex
                          ) in JSON.parse(message.repliedMessage)"
                          :key="`messageReplied-${messageRepliedIndex}`"
                          class="d-flex flex-row rounded"
                          style="cursor: pointer"
                          @click="
                            scrollToMessage(messageReplied.chatTicketMessageId)
                          "
                        >
                          <div
                            class="d-flex flex-column ml-1 justify-content-center"
                            style="padding-right: 1em; padding-top: 0.5em"
                          >
                            <div
                              v-if="messageReplied.is_note == 1"
                              class="message-note"
                            >
                              <feather-icon icon="BookIcon" size="18" />
                              <span> Message Note </span>
                            </div>
                            <span style="color: #53bdeb"
                              ><strong>{{ messageReplied.creatorName }}</strong>
                            </span>

                            <template>
                              <span v-if="messageReplied.typeMessage == 1">{{
                                messageReplied.message
                              }}</span>
                              <div v-if="messageReplied.typeMessage == 4">
                                <feather-icon icon="ImageIcon" size="20" />
                                <span>Image</span>
                              </div>
                              <div v-if="messageReplied.typeMessage == 3">
                                <feather-icon icon="MusicIcon" size="20" />
                                <span>Audio</span>
                              </div>
                              <div
                                v-if="
                                  [2, 5].includes(messageReplied.typeMessage)
                                "
                              >
                                <feather-icon icon="FileIcon" size="20" />
                                <span>File</span>
                              </div>
                            </template>
                            <template>
                              <div v-if="messageReplied.typeMessage == 8">
                                <span
                                  class="message-text"
                                  style="
                                    background-color: transparent !important;
                                  "
                                >
                                  <p
                                    v-if="
                                      message.message.length > limitChars &&
                                      !idAllChars.includes(index)
                                    "
                                    style="
                                      background-color: transparent !important;
                                    "
                                  >
                                    {{
                                      message.message.length >= limitChars
                                        ? `${message.message.substring(
                                            0,
                                            limitChars
                                          )}...`
                                        : message.message
                                    }}
                                  </p>
                                  <p
                                    style="
                                      background-color: transparent !important;
                                      white-space: break-spaces;
                                      margin-bottom: 4px;
                                    "
                                    v-html="
                                      createTagMessageContainer(message, true)
                                    "
                                  />

                                  <b-button
                                    v-if="message.message.length > limitChars"
                                    variant="link"
                                    class="show-more"
                                    :class="
                                      isDarkSkin
                                        ? 'text-white border-light'
                                        : 'text-info border-info'
                                    "
                                    style="margin: 3px"
                                    @click="showAllChars(index)"
                                  >
                                    {{
                                      idAllChars.includes(index)
                                        ? "Show Less"
                                        : "...Show More"
                                    }}
                                  </b-button>
                                </span>
                              </div>
                            </template>
                            <template>
                              <div
                                v-if="
                                  messageReplied.typeMessage == 9 ||
                                  messageReplied.mettingResult
                                "
                              >
                                <div class="message-text">
                                  <feather-icon
                                    icon="PhoneCallIcon"
                                    size="15"
                                  />
                                  Meeting Result
                                </div>
                                <span
                                  v-if="
                                    messageReplied.message.trim().length !== 0
                                  "
                                  class="message-text"
                                  style="
                                    background-color: transparent !important;
                                  "
                                >
                                  <p
                                    v-if="
                                      message.message.length > limitChars &&
                                      !idAllChars.includes(index) &&
                                      !messageReplied.mettingResult
                                    "
                                    style="
                                      background-color: transparent !important;
                                    "
                                  >
                                    {{
                                      message.message.length >= limitChars
                                        ? `${message.message.substring(
                                            0,
                                            limitChars
                                          )}...`
                                        : message.message
                                    }}
                                  </p>
                                  <p
                                    style="
                                      background-color: transparent !important;
                                      white-space: break-spaces;
                                      margin-bottom: 1px;
                                    "
                                    v-html="
                                      createTagMessageContainer(message, true)
                                    "
                                  />

                                  <b-button
                                    v-if="message.message.length > limitChars"
                                    variant="link"
                                    class="show-more"
                                    :class="
                                      isDarkSkin
                                        ? 'text-white border-light'
                                        : 'text-info border-info'
                                    "
                                    style="margin: 3px"
                                    @click="showAllChars(index)"
                                  >
                                    {{
                                      idAllChars.includes(index)
                                        ? "Show Less"
                                        : "...Show More"
                                    }}
                                  </b-button>
                                </span>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="message.edited"
                        class="message-edit"
                        style="margin-bottom: 5px"
                      >
                        <feather-icon icon="Edit2Icon" size="15" />
                        Edited
                      </div>
                      <div
                        v-if="message.is_note == 1"
                        class="message-note"
                        style="margin-bottom: 5px"
                      >
                        <feather-icon icon="BookIcon" size="18" />
                        <span> Message Note </span>
                      </div>
                      <!--?Creator Name-->

                      <b
                        class="text-capitalize"
                        style="font-size: 13px; margin-bottom: 5px"
                        :class="
                          message.creatorId !== getUserId ? '' : 'text-white'
                        "
                        >{{ message.creatorName }}</b
                      >

                      <!--!Message Type Image-->
                      <template>
                        <div
                          v-if="message.typeMessage == 4"
                          style="padding: 3px"
                          class="position-relative contain-img"
                        >
                          <div
                            class="placeholder-img d-flex justify-content-center align-items-center"
                            @click="showImageViewer(message.message)"
                          >
                            <feather-icon
                              icon="EyeIcon"
                              class="text-white"
                              size="20"
                            />
                          </div>
                          <img
                            v-if="message.createdAt"
                            :src="message.message"
                            alt="image"
                            style="
                              opacity: 1;
                              width: auto;
                              height: auto;
                              max-height: 300px;
                              max-width: 300px;
                              min-width: 50px;
                              min-height: 50px;
                            "
                          />
                          <img
                            v-else
                            :src="message.message"
                            alt="image"
                            style="opacity: 0.7; width: 300px; height: 300px"
                          />
                        </div>
                      </template>
                      <!--!Message Type Audio-->
                      <template>
                        <div
                          v-if="message.typeMessage == 3"
                          style="padding: 3px"
                        >
                          <audio-player
                            v-if="!message.createdAt"
                            :src="message.message ? message.message : ''"
                            :index="index"
                            :chat="true"
                          /><audio-player
                            v-else
                            :src="message.message"
                            :index="index"
                            :chat="true"
                          />
                          <template>
                            <tabler-icon
                              v-if="message.createdAt"
                              icon="ChecksIcon"
                              size="17"
                              class="mx-1"
                              :class="
                                isDarkSkin ? 'text-white' : 'text-secondary'
                              "
                              style="
                                position: absolute;
                                right: 0px;
                                bottom: 5px !important;
                              "
                            />
                            <tabler-icon
                              v-else
                              icon="CheckIcon"
                              size="17"
                              class="mx-1"
                              :class="
                                isDarkSkin ? 'text-white' : 'text-secondary'
                              "
                              style="
                                position: absolute;
                                right: 0px;
                                bottom: 5px !important;
                              "
                            />
                          </template>
                        </div>
                      </template>
                      <!--!Message Type Text -->
                      <template>
                        <div v-if="message.typeMessage == 1">
                          <span
                            class="message-text"
                            style="background-color: transparent !important"
                          >
                            <p
                              v-if="
                                message.message.length > limitChars &&
                                !idAllChars.includes(index)
                              "
                              style="background-color: transparent !important"
                            >
                              {{
                                message.message.length >= limitChars
                                  ? `${message.message.substring(
                                      0,
                                      limitChars
                                    )}...`
                                  : message.message
                              }}
                            </p>
                            <p
                              :class="
                                message.creatorId !== getUserId
                                  ? 'text-dark'
                                  : 'text-white'
                              "
                              v-else
                              style="
                                background-color: transparent !important;
                                white-space: break-spaces;
                                margin-bottom: 5px;
                              "
                              v-html="highlightText(message)"
                            />

                            <b-button
                              v-if="message.message.length > limitChars"
                              variant="link"
                              class="p-0 m-0 font-weight-bolder show-more"
                              :class="isDarkSkin ? 'text-white' : 'text-info'"
                              @click="showAllChars(index)"
                            >
                              {{
                                idAllChars.includes(index)
                                  ? "Show Less"
                                  : "...Show More"
                              }}
                            </b-button>
                          </span>
                        </div>
                      </template>
                      <!--!Message Type Tag-->

                      <!--!Message Type Meeting Result-->
                      <template>
                        <div
                          v-if="
                            message.typeMessage == 9 || message.mettingResult
                          "
                        >
                          <div class="message-text">
                            <feather-icon icon="PhoneCallIcon" size="15" />
                            Meeting Result
                          </div>
                          <span
                            v-if="message.message.trim().length !== 0"
                            class="message-text"
                            style="background-color: transparent !important"
                          >
                            <p
                              v-if="
                                message.message.length > limitChars &&
                                !idAllChars.includes(index) &&
                                !message.mettingResult
                              "
                              style="background-color: transparent !important"
                            >
                              {{
                                message.message.length >= limitChars
                                  ? `${message.message.substring(
                                      0,
                                      limitChars
                                    )}...`
                                  : message.message
                              }}
                            </p>
                            <p
                              v-if="!message.mettingResult"
                              style="
                                background-color: transparent !important;
                                white-space: break-spaces;
                                margin-bottom: 0px;
                              "
                              v-html="createTagMessageContainer(message, true)"
                            />

                            <b-button
                              v-if="message.message.length > limitChars"
                              variant="link"
                              class="show-more"
                              :class="
                                isDarkSkin
                                  ? 'text-white border-light'
                                  : 'text-info border-info'
                              "
                              style="margin: 3px"
                              @click="showAllChars(index)"
                            >
                              {{
                                idAllChars.includes(index)
                                  ? "Show Less"
                                  : "...Show More"
                              }}
                            </b-button>
                          </span>
                          <template>
                            <tabler-icon
                              v-if="message.createdAt"
                              icon="ChecksIcon"
                              size="17"
                              class="mx-1"
                              :class="
                                isDarkSkin ? 'text-white' : 'text-secondary'
                              "
                              style="
                                position: absolute;
                                right: 0px;
                                bottom: 5px !important;
                              "
                            />
                            <tabler-icon
                              v-else
                              icon="CheckIcon"
                              size="17"
                              class="mx-1"
                              :class="
                                isDarkSkin ? 'text-white' : 'text-primary'
                              "
                              style="
                                position: absolute;
                                right: 0px;
                                bottom: 5px;
                              "
                            />
                          </template>
                        </div>
                      </template>
                      <template>
                        <div v-if="message.typeMessage == 8">
                          <span
                            class="message-text"
                            style="background-color: transparent !important"
                          >
                            <p
                              v-if="
                                message.message.length > limitChars &&
                                !idAllChars.includes(index)
                              "
                              style="background-color: transparent !important"
                            >
                              {{
                                message.message.length >= limitChars
                                  ? `${message.message.substring(
                                      0,
                                      limitChars
                                    )}...`
                                  : message.message
                              }}
                            </p>
                            <p
                              v-if="message.message.includes('@')"
                              style="
                                background-color: transparent !important;
                                white-space: break-spaces;
                                margin-bottom: 0px;
                              "
                              v-html="createTagMessageContainer(message)"
                            />
                            <span style="color: #c0392b; font-weight: 600">{{
                              message.mettingResult
                                ? message.mettingResult
                                : "" + " "
                            }}</span>

                            <b-button
                              v-if="message.message.length > limitChars"
                              variant="link"
                              class="show-more"
                              :class="
                                isDarkSkin
                                  ? 'text-white border-light'
                                  : 'text-info border-info'
                              "
                              style="margin: 3px"
                              @click="showAllChars(index)"
                            >
                              {{
                                idAllChars.includes(index)
                                  ? "Show Less"
                                  : "...Show More"
                              }}
                            </b-button>
                          </span>
                        </div>
                      </template>
                      <template>
                        <!--!Message Type File-->
                        <div
                          v-if="
                            message.typeMessage == 2 || message.typeMessage == 5
                          "
                          style="padding: 3px"
                        >
                          <a
                            v-if="message.createdAt"
                            :href="message.message"
                            target="_blank"
                          >
                            <img
                              alt="file-img"
                              :src="getFileImage(getFileName(message.message))"
                              style="
                                width: 30px;
                                height: 30px;
                                max-width: 40px;
                                max-height: 50px;
                              "
                            />

                            <span :style="isDarkSkin ? 'color:white' : ''">{{
                              getFileName(message.message)
                            }}</span>
                          </a>
                          <a
                            v-else
                            :href="message.message"
                            style="width: 30px; height: 30px"
                          >
                            <img
                              alt="file-img"
                              target="_blank"
                              :src="getFileImage(message.messageName)"
                              style="
                                width: 30px;
                                height: 30px;
                                max-width: 50px;
                                max-height: 50px;
                              "
                            />
                            <p
                              style="
                                background-color: transparent;
                                white-space: break-spaces;
                                margin-bottom: 0px;
                              "
                              :style="isDarkSkin ? 'color:white' : ''"
                            >
                              {{ getFileName(message.messageName) }}
                            </p>
                          </a>
                          <!--AQUI SE ENCUENTRAN LAS PERSONAS QUE LO HAN VISTO-->
                          <template>
                            <tabler-icon
                              v-if="message.createdAt"
                              icon="ChecksIcon"
                              size="17"
                              class="mx-1"
                              :class="
                                isDarkSkin ? 'text-white' : 'text-secondary'
                              "
                              style="
                                position: absolute;
                                right: 0px;
                                bottom: 5px !important;
                              "
                            />
                            <tabler-icon
                              v-else
                              icon="CheckIcon"
                              size="17"
                              class="mx-1"
                              :class="
                                isDarkSkin ? 'text-white' : 'text-secondary'
                              "
                              style="
                                position: absolute;
                                right: 0px;
                                bottom: 5px !important;
                              "
                            />
                          </template>
                        </div>
                      </template>
                      <!--?Message created At-->

                      <small
                        v-if="message.createdAt"
                        style="position: absolute; bottom: -20px; right: 0px"
                      >
                        <tabler-icon
                          :icon="message.createdAt ? 'ChecksIcon' : 'CheckIcon'"
                          size="17"
                          :class="isDarkSkin ? 'text-white' : 'text-secondary'"
                        />
                        {{ message.createdAt | customizedFormatDate }}
                      </small>
                    </div>
                    <!--!Message Footer-->
                    <div
                      class="message-footer d-flex justify-content-end align-items-center mt-2"
                      style="position: relative; z-index: 2"
                    >
                      <div
                        v-if="message.createdAt"
                        class="message-reactions-container"
                      >
                        <ul
                          class="d-flex flew-row"
                          style="list-style: none; column-gap: 0.2em; margin: 0"
                        >
                          <li
                            v-for="(
                              messageReaction, messageReactionInfo
                            ) in agrupedReactions(message.reactionsInfo)"
                            :key="`message-reaction-${messageReactionInfo}`"
                            style="
                              display: flex;
                              column-gap: 0.2em;
                              border-radius: 1em;
                              width: 40px;
                              height: 25px;
                            "
                            class="cursor-pointer d-flex justify-content-center align-items-center"
                            :class="
                              isDarkSkin
                                ? 'more-options-selector-container-dark'
                                : 'more-options-selector-container-light'
                            "
                            @mouseenter="
                              showMessageReactions(
                                message.chatTicketMessageId,
                                messageReaction
                              )
                            "
                            @mouseleave="
                              reactionsDetailsView = {
                                show: null,
                                data: null,
                              }
                            "
                          >
                            <img
                              alt="reaction-owo"
                              :src="messageReaction.fileName"
                              style="width: 20px; height: 20px"
                            />
                            <span v-if="messageReaction.count > 1">{{
                              messageReaction.count
                            }}</span>
                          </li>
                        </ul>
                      </div>
                      <div
                        v-if="
                          reactionsDetailsView.show ==
                            message.chatTicketMessageId &&
                          reactionsDetailsView.data != null
                        "
                        class="reaction-details-container"
                        style="
                          position: absolute;
                          bottom: 2.5em;
                          padding: 1.2em 1em;
                          border-radius: 0.5em;
                          width: auto;
                        "
                        :class="
                          !isDarkSkin
                            ? 'more-reaction-detail-container-light'
                            : 'more-reaction-detail-container-dark'
                        "
                      >
                        <ul
                          class="d-flex flex-column justify-content-between m-0 p-0"
                          style="row-gap: 0.7em; position: relative"
                        >
                          <img
                            alt="reaction-detail-img"
                            :src="
                              getReactionUrl(
                                reactionsDetailsView.data[0].fileName
                              )
                            "
                            style="
                              width: 50px;
                              height: 50px;
                              position: absolute;
                              top: -3.5em;
                              left: 40%;
                            "
                          />
                          <li
                            v-for="(
                              detail, detailIndex
                            ) in reactionsDetailsView.data"
                            :key="`reaction-detail-${detailIndex}`"
                            style="list-style: none; column-gap: 0.5em"
                            class="d-flex flex-row mx-0 align-items-center justify-content-start"
                          >
                            <img
                              alt="creator-reaction-detail"
                              :src="
                                ticketParticipants.find(
                                  (item) => item.id_user == detail.id
                                ).image
                              "
                              style="
                                width: 30px;
                                height: 30px;
                                border-radius: 50%;
                              "
                            />
                            <span>{{ detail.creatorName }}</span>
                          </li>
                        </ul>
                      </div>
                      <!--!Message Viewers-->
                      <div
                        v-if="
                          message.viewersInfo !== null &&
                          typeof message.viewersInfo !== 'undefined'
                        "
                        :key="`message-viewersInfo-${message.chatTicketMessageId}`"
                        class="d-flex flex-row"
                      >
                        <div
                          v-for="(viewer, viewerIndex) in JSON.parse(
                            message.viewersInfo
                          ).filter(
                            (viewer) => viewer.viewedBy != message.creatorId
                          )"
                          :key="viewerIndex"
                          style="margin: 0px 3px"
                        >
                          <b-avatar
                            :id="`message-${message.chatTicketMessageId}-viewer-${viewerIndex}`"
                            :src="viewer.viewerImage"
                            variant="info"
                            style="margin: 5px"
                            size="1.5em"
                            alt="user-img"
                          />

                          <b-tooltip
                            triggers="hover"
                            :target="`message-${message.chatTicketMessageId}-viewer-${viewerIndex}`"
                            :title="viewer.viewerName"
                            :delay="{ show: 100, hide: 50 }"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--*Reaction Selector -->
                  <div
                    v-if="message.createdAt"
                    class="d-flex align-items-center"
                    style="position: relative; height: 100%"
                    :class="message.creatorId !== getUserId ? 'ml-1' : 'mr-1'"
                  >
                    <feather-icon
                      class=""
                      icon="SmileIcon"
                      size="22"
                      style="cursor: pointer"
                      @click="
                        showMenuReactionSelector(message.chatTicketMessageId)
                      "
                    />
                    <div
                      v-show="
                        showReactionSelector == message.chatTicketMessageId
                      "
                      :id="`reaction-selector-${message.chatTicketMessageId}`"
                      class="reactions-selector-container flex-row d-none"
                      style="
                        position: absolute;
                        border-radius: 2em;
                        padding: 0.8em 1em;
                      "
                      :class="
                        isDarkSkin
                          ? 'more-options-selector-container-dark'
                          : 'more-options-selector-container-light'
                      "
                      :style="
                        message.creatorId == getUserId
                          ? 'right:5em'
                          : 'left:5em'
                      "
                    >
                      <img
                        v-for="(
                          reactionItem, reactionIndex
                        ) in getReactionsUrls"
                        :key="`reaction-${reactionIndex}`"
                        :src="reactionItem"
                        alt="reaction"
                        class="reaction-selector-item"
                        style="width: 25px; height: 25px; margin: 0px 2px"
                        @click="
                          sendReaction(reactionItem, reactionIndex + 1, message)
                        "
                      />
                    </div>
                  </div>
                  <!--*Reply and Edit Message-->
                  <div
                    v-if="message.createdAt"
                    class="d-flex align-items-center justify-content-center"
                    style="position: relative; height: 100"
                    :class="message.creatorId !== getUserId ? 'ml-1' : 'mr-1'"
                  >
                    <feather-icon
                      class="options-selector-container d-flex flex-row"
                      icon="MoreVerticalIcon"
                      size="22"
                      style="cursor: pointer"
                      @click="
                        showMenuMoreOptionsSelector(message.chatTicketMessageId)
                      "
                    />
                    <div
                      v-show="
                        showMoreOptionsSelector == message.chatTicketMessageId
                      "
                      :id="`more-options-selector-${message.chatTicketMessageId}`"
                      class="more-options-selector-container d-none flex-column mt-1"
                      style="
                        position: absolute;
                        width: 120px;
                        row-gap: 0.5em;
                        padding: 0.8em 1em;
                        border-radius: 1em;
                      "
                      :style="
                        message.creatorId == getUserId
                          ? 'right:3em'
                          : 'left:2em'
                      "
                      :class="
                        isDarkSkin
                          ? 'more-options-selector-container-dark'
                          : 'more-options-selector-container-light'
                      "
                    >
                      <div
                        v-for="(option, optionIndex) in moreOptions.filter(
                          (option) => {
                            if (option.key == 'edit') {
                              return (
                                message.creatorId == getUserId &&
                                (showMoreOptionsSelector ==
                                message.chatTicketMessageId
                                  ? validateEditMessage(message)
                                  : false) &&
                                message.typeMessage == 1
                              );
                            }
                            if (option.key == 'add-note') {
                              return (
                                isCeo &&
                                message.creatorId == getUserId &&
                                [1, 8, 9].includes(message.typeMessage)
                              );
                            }
                            return true;
                          }
                        )"
                        :key="`option-${optionIndex}`"
                        class="more-options-item"
                        :class="isDarkSkin ? 'option-dark' : 'option-light'"
                      >
                        <span @click="() => option.onclick(message)">{{
                          message.is_note == 1 && option.key == "add-note"
                            ? "Remove Note"
                            : option.name
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <!--DRAG AND DROP FILES-->
            <div
              v-else
              class="drag-drop-files"
              @dragenter="onDragEnter"
              @dragover="onDragOver"
              @dragleave="onDragLeave"
              @drop="onDrop"
            >
              <!--close container drag and drop-->
              <feather-icon
                v-if="!loadFiles"
                icon="XCircleIcon"
                class="text-danger hover-me cursor-pointer rounded-circle"
                size="25"
                style="
                  position: fixed !important;
                  top: 10px;
                  right: 10px;
                  z-index: 2;
                "
                @click="closeDragDropFiles"
              />
              <!--preview of file-->
              <div
                class="d-flex justify-content-center align-items-center"
                style="height: 80%; width: 100%"
              >
                <img
                  v-if="
                    ['png', 'jpg', 'jpeg', 'svg', 'gif'].includes(
                      getExtensionFile(fileSelected.name)
                    )
                  "
                  class="preview-img"
                  :src="fileSelected.imgUrl"
                  :alt="`image_${fileSelected.imgUrl}`"
                />
                <div
                  v-else
                  class="d-block d-md-flex justify-content-center text-center justify-content-md-around align-items-center w-100"
                >
                  <img
                    style="
                      width: 60%;
                      height: 60%;
                      max-width: 200px;
                      max-height: 200px;
                      min-height: 150px;
                      min-width: 150px;
                    "
                    :src="getFileImage(fileSelected.name)"
                    :alt="`image_${fileSelected.name}`"
                    class="m-1"
                  />
                  <div class="d-flex flex-column text-center">
                    <strong
                      class="text-uppercase"
                      style="
                        font-size: 20px;
                        max-width: 450px;
                        word-break: break-all;
                      "
                      >{{ fileSelected.name }}</strong
                    >
                    <span style="font-size: 20px">No preview available</span>
                    <span style="font-size: 20px"
                      >{{ fileSelected.size | convertSize }} -
                      {{ fileSelected.name | typeFile }}</span
                    >
                  </div>
                </div>
              </div>
              <!--minimalist files-->
              <div
                class="w-100 d-flex text-center justify-content-center align-items-center"
                style="border-top: 2px dashed #0072b1; height: 20%"
              >
                <!--CONTAIN FILES DRAG AND DROP-->
                <div
                  class="d-flex justify-content-center align-items-center flex-wrap"
                  style="width: auto; overflow-y: auto; height: 100%"
                >
                  <div
                    v-for="(item, index) in filesSelected"
                    :key="index"
                    class="file"
                    :class="isDarkSkin ? 'bg-transparent border' : 'bg-white'"
                    :style="
                      indexSelected === index
                        ? 'border: 1px solid red !important; box-shadow: 0px 0px 1px 2px #fc424a7a !important;'
                        : ''
                    "
                    @click="selectedFile(item, index)"
                  >
                    <img
                      style="width: 60px; height: 60px"
                      :src="getFileImage(item.name)"
                      :alt="`image_${index}`"
                    />
                    <feather-icon
                      v-if="!loadFiles"
                      icon="Trash2Icon"
                      size="25"
                      class="text-danger cursor-pointer hover-me rounded-sm"
                      :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
                      title="REMOVE FILE"
                      style="
                        position: absolute;
                        right: 0px;
                        bottom: 0px;
                        padding: 4px;
                      "
                      @click="removeFile(index)"
                    />
                    <b-spinner
                      v-else
                      variant="primary"
                      style="width: 20px; height: 20px"
                    />
                  </div>
                  <div
                    class="file"
                    :class="
                      !isDarkSkin
                        ? 'bg-white'
                        : 'bg-transparent border d-flex justify-content-center align-items-center'
                    "
                    style="width: 75px; height: 75px"
                    @click="openFileInput()"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="22"
                      class="cursor-pointer hover-me"
                      :class="isDarkSkin ? 'text-white' : 'text-dark'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--?Arrow button to scroll bottm-->
          <div
            v-show="viewArrowToGoDown && !modalUploadFiles"
            id="arrow-down"
            class="cursor-pointer"
            :class="pendingMessagesToReadCount > 0 ? 'jump' : ''"
            @click="scrollAndViewMessages()"
          >
            <feather-icon icon="ArrowDownIcon" size="25" class="text-white" />
            <span
              v-if="pendingMessagesToReadCount > 0"
              style="
                background: red;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: absolute;
                text-align: center;
                top: 0;
                color: white;
              "
              >{{ pendingMessagesToReadCount }}</span
            >
          </div>
          <div
            v-if="
              checkisTag &&
              ticketParticipants.filter((participant) =>
                `@${participant.user_name}`
                  .toLowerCase()
                  .includes(tagSearch.text.toLowerCase())
              ).length > 0
            "
            class="menu-tag-participants"
            :class="
              isDarkSkin
                ? 'more-options-selector-container-dark'
                : 'more-options-selector-container-light'
            "
          >
            <ul
              style="margin: 0; padding: 0; row-gap: 0.2em"
              class="d-flex flex-column"
            >
              <li
                v-for="(tagItem, tagIndex) in ticketParticipants.filter(
                  (participant) =>
                    `@${participant.user_name}`
                      .toLowerCase()
                      .includes(tagSearch.text.toLowerCase())
                )"
                :key="`tag-${tagIndex}`"
                style="
                  list-style: none;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: start;
                  cursor: pointer;
                  column-gap: 0.5em;
                  row-gap: 1em;
                  padding: 0.5em 0;
                "
                @click="addTag(tagItem)"
              >
                <b-avatar
                  v-b-tooltip.hover.top="tagItem.status_session_name"
                  size="40"
                  :src="tagItem.image"
                  variant="light-primary"
                  badge
                  class="badge-minimal"
                  :badge-variant="tagItem.badge"
                >
                  <feather-icon
                    v-if="!tagItem.image"
                    icon="UserIcon"
                    size="19"
                  />
                </b-avatar>
                <span> {{ tagItem.user_name }}</span>
              </li>
            </ul>
          </div>
          <div
            v-if="repliedMessage"
            class="d-flex flex-row rounded w-100 justify-content-between align-items-center"
            :style="isDarkSkin ? 'background:#34495E' : 'background:#D0D3D4'"
            style="z-index: 2"
          >
            <div class="replied-message-content d-flex flex-row">
              <div
                style="
                  width: 4px;
                  height: 100%;
                  background: #53bdeb;
                  border-top-left-radius: 7.5px;
                  border-bottom-left-radius: 7.5px;
                  padding: 2.5em 0;
                "
              />
              <div class="d-flex flex-row ml-1 justify-content-between">
                <div class="d-flex flex-column">
                  <div
                    v-if="repliedMessage.is_note == 1"
                    class="message-note mt-1"
                  >
                    <feather-icon icon="BookIcon" size="18" />
                    <span> Message Note </span>
                  </div>
                  <span style="color: #53bdeb"
                    ><strong>{{ repliedMessage.creatorName }}</strong></span
                  >

                  <template>
                    <span v-if="repliedMessage.typeMessage == 1">{{
                      repliedMessage.message
                    }}</span>
                    <div v-if="repliedMessage.typeMessage == 4">
                      <feather-icon icon="ImageIcon" size="20" />
                      <span>Image</span>
                    </div>
                    <div v-if="repliedMessage.typeMessage == 3">
                      <feather-icon icon="MusicIcon" size="20" />
                      <span>Audio</span>
                    </div>
                    <div v-if="[2, 5].includes(repliedMessage.typeMessage)">
                      <feather-icon icon="FileIcon" size="20" 9 />
                      <span>File</span>
                    </div>
                    <div
                      v-if="
                        repliedMessage.typeMessage == 9 ||
                        repliedMessage.mettingResult
                      "
                      class="d-flex flex-column"
                    >
                      <div class="d-flex flex-row gap-1">
                        <feather-icon icon="PhoneCallIcon" size="20" />
                        <span>Meeting Result</span>
                      </div>
                      <span v-if="!repliedMessage.mettingResult">
                        {{ repliedMessage.message }}</span
                      >
                    </div>
                    <div v-if="repliedMessage.typeMessage == 8" class="mb-0">
                      <p
                        style="
                          background-color: transparent !important;
                          white-space: break-spaces;
                          margin-bottom: 0px;
                        "
                        v-html="createTagMessageContainer(repliedMessage)"
                      />
                      <span
                        v-if="repliedMessage.mettingResult"
                        style="color: rgb(192, 57, 43)"
                        >{{ repliedMessage.mettingResult }}</span
                      >
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <feather-icon
              icon="XIcon"
              size="20"
              class="mr-1 close-reply-message"
              @click="closeReplyMessage()"
            />
          </div>

          <!--!bottom bar message -->
          <div
            class="chat-actions-bar"
            style="border-top: 1px solid #e0e0e0"
            :style="
              !isDarkSkin
                ? 'background-color: #fbfbfb !important; pointer-events: ' +
                  (actionBarActive ? 'auto' : 'none')
                : 'background-color: #121212 !important; pointer-events: ' +
                  (actionBarActive ? 'auto' : 'none')
            "
          >
            <template>
              <b-form-checkbox
                v-if="
                  isCeo &&
                  (!modalUploadFiles ||
                    (modalUploadFiles && messageInput.trim().length > 0)) &&
                  !showAudioRecorder
                "
                id="add-note"
                v-model="isAddingNote"
                class="chat-action-bar-icon hover-me cursor-pointer mx-1"
                :class="isAddingNote ? 'text-success' : 'text-secondary'"
                switch
              />
              <span
                v-if="
                  isCeo &&
                  (!modalUploadFiles ||
                    (modalUploadFiles && messageInput.trim().length > 0)) &&
                  !showAudioRecorder
                "
                :style="isAddingNote ? 'color: #3F7AFA' : ''"
              >
                {{
                  customerTicketChat.client_account
                    ? "Add note to client"
                    : "Add note to Lead"
                }}
              </span>
              <b-tooltip
                :variant="isDarkSkin ? 'dark' : 'light'"
                placement="top"
                target="add-note"
                :title="
                  customerTicketChat.client_account
                    ? 'Add note to client'
                    : 'Add note to Lead'
                "
              />
            </template>
            <span
              style="font-size: 25px"
              class="chat-action-bar-icon hover-me cursor-pointer text-warning"
              @click="showEmojiSelector = !showEmojiSelector"
            >
              😀
            </span>
            <emoji-selector
              v-if="showEmojiSelector"
              class="emoji-selector"
              style="z-index: 999"
              @addEmoji="(character) => addEmoji(character)"
            />
            <svg
              width="25"
              height="25"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="chat-action-bar-icon hover-me cursor-pointer"
              @click="openFileInput()"
            >
              <g id="Plus">
                <path
                  id="Line_185"
                  d="M11.0001 7.32733V14.6537"
                  :stroke="isDarkSkin ? 'white' : '#16161A'"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Line_186"
                  d="M14.6668 10.9905H7.3335"
                  :stroke="isDarkSkin ? 'white' : '#16161A'"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Path"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.6857 1H6.31429C3.04762 1 1 3.31208 1 6.58516V15.4148C1 18.6879 3.0381 21 6.31429 21H15.6857C18.9619 21 21 18.6879 21 15.4148V6.58516C21 3.31208 18.9619 1 15.6857 1Z"
                  :stroke="isDarkSkin ? 'white' : '#16161A'"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
            </svg>
            <feather-icon
              v-if="editedMessage && !sendNote"
              icon="XIcon"
              size="25"
              class="close-reply-message"
              @click="closeEditMessage()"
            />
            <b-form-file
              ref="filepicker"
              v-model="files"
              multiple
              style="display: none"
            />

            <div style="width: 70%">
              <textarea
                id="text-area"
                ref="textArea"
                v-model="messageInput"
                class="chat-actions-input pt-1"
                placeholder="Say Something"
                contenteditable="true"
                :disabled="showAudioRecorder"
                style="resize: none; width: 100%"
                :style="
                  isDarkSkin
                    ? 'background-color: #4A4A4A; color:white'
                    : 'background-color: #FFFFFF; color: black;'
                "
                @keydown.enter.prevent="handleEnter"
                @paste="handlePaste"
              />
            </div>
            <audio-recorder
              v-show="showAudioRecorder"
              ref="audio-recorder"
              @closeAudio="showAudioRecorder = false"
              @sendAudio="(audioBlob) => sendAudio(audioBlob)"
            />

            <template>
              <feather-icon
                v-if="messageInput.trim() === '' && !modalUploadFiles"
                id="AUDIO_RECORDING"
                size="25"
                icon="MicIcon"
                class="chat-action-bar-icon hover-me cursor-pointer"
                :class="
                  isRecording
                    ? 'text-success jump'
                    : isDarkSkin
                    ? 'text-white'
                    : 'text-dark'
                "
                @click="startRecording()"
              />
              <b-tooltip
                :variant="isRecording ? 'success' : ''"
                placement="top"
                target="AUDIO_RECORDING"
              >
                {{ !isRecording ? "RECORD AUDIO" : "RECORDING" }}
              </b-tooltip>
            </template>
            <feather-icon
              v-if="
                (!showAudioRecorder && messageInput.trim() !== '') ||
                modalUploadFiles
              "
              size="25"
              icon="SendIcon"
              class="chat-action-bar-icon hover-me cursor-pointer"
              @click="
                files.length > 0 || filesSelected.length > 0
                  ? messageInput.trim() !== ''
                    ? sendMultipleMessagesTypes(filesSelected)
                    : uploadFiles(filesSelected)
                  : sendMessageHandler()
              "
            />
          </div>
          <!--!Search Message Input-->
          <div
            v-show="searchMessage"
            :class="!isDarkSkin ? 'chat-search-bar' : 'chat-search-bar-dark'"
          >
            <div
              v-if="searchInput"
              class="chat-search-counter text-uppercase mb-1"
            >
              <strong>
                {{
                  messages.filter((message) => {
                    if (!searchInput) return true;
                    return message.message
                      .toLowerCase()
                      .includes(searchInput.toLowerCase());
                  }).length
                }}
                messages Found
              </strong>
            </div>
            <div
              v-if="searchInput"
              style="height: 80%; overflow: auto"
              class="search-container"
            >
              <div
                v-for="(messageFiltered, messageIndex) in messages.filter(
                  (message) => {
                    if (!searchInput) return true;

                    // if(![1,6,7].includes(message.typeMessage)) return true
                    if ([2, 5].includes(message.typeMessage)) {
                      return getFileName(message.message)
                        .toLowerCase()
                        .includes(searchInput.toLowerCase());
                    }
                    return message.message
                      .toLowerCase()
                      .includes(searchInput.toLowerCase());
                  }
                )"
                :key="messageIndex"
                class="p-2"
                :class="isDarkSkin ? 'found-message-dark' : 'found-message'"
                @click="scrollToMessage(messageFiltered.chatTicketMessageId)"
              >
                <strong>{{ messageFiltered.creatorName }}</strong>
                <div v-if="![1, 6, 7].includes(messageFiltered.typeMessage)">
                  <div
                    v-if="messageFiltered.typeMessage == 4"
                    style="padding: 3px"
                  >
                    <img
                      v-if="messageFiltered.createdAt"
                      :src="messageFiltered.message"
                      alt="image"
                      style="opacity: 1; width: 50px; height: 50px"
                    />
                    <img
                      v-else
                      :src="messageFiltered.message"
                      alt="image"
                      style="opacity: 0.7; width: 50px; height: 50px"
                    />
                  </div>
                  <div
                    v-if="messageFiltered.typeMessage == 3"
                    style="padding: 3px"
                  >
                    <audio-player
                      v-if="!messageFiltered.createdAt"
                      :src="
                        messageFiltered.message ? messageFiltered.message : ''
                      "
                      :index="index"
                    /><audio-player
                      v-else
                      :src="messageFiltered.message"
                      :index="index"
                    />
                  </div>

                  <div
                    v-if="
                      messageFiltered.typeMessage == 2 ||
                      messageFiltered.typeMessage == 5
                    "
                    style="padding: 3px"
                  >
                    <a
                      v-if="messageFiltered.createdAt"
                      :href="messageFiltered.message"
                      target="_blank"
                    >
                      <img
                        alt="file-img"
                        :src="
                          getFileImage(getFileName(messageFiltered.message))
                        "
                        style="width: 30px; height: 30px"
                      />

                      <span>{{ getFileName(messageFiltered.message) }}</span>
                    </a>
                    <a
                      v-else
                      :href="messageFiltered.message"
                      style="width: 30px; height: 30px"
                      target="_blank"
                    >
                      <img
                        alt="file-img"
                        :src="getFileImage(messageFiltered.messageName)"
                        style="width: 30px; height: 30px"
                      />
                      <span>{{
                        getFileImage(messageFiltered.messageName)
                      }}</span>
                    </a>
                  </div>
                </div>
                <div v-else>
                  {{ messageFiltered.message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <modal-file
        v-if="showAllFile"
        :file-type="selectedChat"
        @hidden="showAllFile = false"
      />
    </b-modal>
    <!--MODAL IMAGE VIEWS-->
    <image-viewer
      v-if="showImage"
      :url-image="urlImage"
      @hidden="showImage = false"
    />

    <solve-ticket-modal
      v-if="solveTicketModalBoolean"
      :customer-ticket="customerTicketChat"
      :new-status="statusF ? null : newStatus"
      :status-fidelization="statusF"
      @hidden="solveTicketModalBoolean = false"
      @closeModal="solveTicketModalBoolean = false"
      @refreshTable="closeModal()"
    />
    <modal-show-description
      v-if="openModalShowDescription"
      :ticket="motiveDescription"
      :details="detailsTicket"
      @close="closeModalShow"
    />
    <modal-employee-activity-records
      v-if="modalEmployeeActivityRecords"
      :employee="selectedParticipant"
      @close="modalEmployeeActivityRecords = false"
      @hidden="modalEmployeeActivityRecords = false"
    />
    <modal-ticket-summary
      v-if="showModalTicketSummary"
      :customer-ticket="customerTicketChat"
      @hidden="showModalTicketSummary = false"
    />
    <modal-task-component
      v-if="showModalAddTask"
      :ticket-customer="paramsAddTask"
      @successTask="successTask"
      @closeModal="closeAddTask"
    />
    <modal-list-tasks
      v-if="showModalListTaks"
      :ticket-customer="paramsListTaks"
      @closeModal="closeListTasks"
    />
  </div>
</template>
<script>
// import { mapActions } from 'vuex';
import TicketService from "@/views/commons/components/customer-tickets/services/ticket-customer.chat.service";
import ParticipantsServices from "@/views/commons/participants-tickets/services/participants.services";
import AudioRecorder from "@/views/commons/components/customer-tickets/chat-components/AudioRecorder.vue";
import AudioPlayer from "@/views/commons/components/customer-tickets/chat-components/AudioPlayer.vue";
import ModalFile from "@/views/commons/components/customer-tickets/chat-components/ModalFiles.vue";
import EmojiSelector from "@/views/commons/components/customer-tickets/chat-components/EmojiSelector.vue";
import store from "@/store";
import ImageViewer from "@/views/commons/components/customer-tickets/chat-components/ImageViewer.vue";
import moment from "moment";
import CallLogService from "@/views/commons/components/ring-central/call-log/service/call-log.service";
import SolveTicketModal from "@/views/commons/components/customer-tickets/grid-components/SolveTicketModal.vue";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
import ModalShowDescription from "@/views/commons/components/customer-tickets/components/modal/ModalShowDescription.vue";
import ModalEmployeeActivityRecords from "@/views/commons/components/customer-tickets/chat-components/ModalEmployeeActivityRecords.vue";
import ModalTicketSummary from "@/views/commons/components/customer-tickets/components/modal/ModalTicketSummary.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ModalTaskComponent from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalTask.vue";
import ModalListTasks from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalListTasks.vue";

export default {
  components: {
    AudioRecorder,
    AudioPlayer,
    ModalFile,
    EmojiSelector,
    ImageViewer,
    SolveTicketModal,
    ModalShowDescription,
    ModalEmployeeActivityRecords,
    ModalTicketSummary,
    StatusAccount,
    ModalTaskComponent,
    ModalListTasks,
  },
  filters: {
    customizedFormatDate(value) {
      const currentDate = moment().format("YYYY-MM-DD");
      const currentTime = moment().format("HH:mm");
      const myDate = moment(value).format("YYYY-MM-DD");
      const myTime = moment(value).format("HH:mm");

      let newValue = null;
      if (!value) {
        newValue = null;
      } else if (myDate === currentDate && myTime === currentTime) {
        newValue = "Now";
      }
      if (myDate === currentDate) {
        newValue = `Today ${myTime}`;
      } else if (
        myDate < currentDate &&
        moment(currentDate).diff(myDate, "days") <= 1
      ) {
        newValue = moment(value).format("MM/DD/YYYY HH:mm");
      } else {
        newValue = moment(value).format("MM/DD/YYYY HH:mm");
      }
      return newValue;
    },
    typeFile(value) {
      if (!value) return null;
      let ext = value.split(".").length > 1 ? value.split(".").pop() : null;
      if (ext) {
        ext = ext.toLowerCase();
        if (["png", "jpg", "jpeg", "svg", "gif"].includes(ext)) return "image";
        if (["mp3", "wav", "ogg"].includes(ext)) return "audio";
        if (["mp4", "avi", "mov", "wmv", "flv", "3gp", "webm"].includes(ext)) {
          return "video";
        }
        if (["docx", "doc"].includes(ext)) return "word";
        if (["ppt", "pptx"].includes(ext)) return "power point";
        if (["xls", "xlsx"].includes(ext)) return "Excel";
      }

      return ext || "unknown";
    },
    convertSize(value) {
      const letter = ["KB", "MB", "GB"]; // letters
      const sizeValues = [1024, 1024 ** 2, 1024 ** 3]; // values to convert of according of letter (** = pow)

      let valueConverted = value;
      for (let index = sizeValues.length - 1; index >= 0; index -= 1) {
        if (value >= sizeValues[index] || index === 0) {
          valueConverted = `${(value / sizeValues[index]).toFixed(2)} ${
            letter[index]
          }`;

          break;
        }
      }
      return valueConverted;
    },
  },
  props: {
    customerTicketChat: {
      type: Object,
      required: true,
    },

    fileType: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      status_customer_ticket: null,
      modalEmployeeActivityRecords: false,
      selectedParticipant: {},
      openModalShowDescription: false,
      motiveDescription: null,
      detailsTicket: null,
      selectedFileType: {},
      selectedChat: {},
      showAllFile: false,
      messageInput: "",
      messages: [],
      chatContainer: null,
      isRecording: false,
      ticketItems: [],
      recording: false,
      ticketParticipants: [],
      files: [],
      showAllParticipants: false,
      limitParticipants: 6,
      optionsRoles: [1, 2, 17], // CEO, SUPERVISOR OR CHIEF
      bgColorStatus: {
        1: "light-secondary",
        2: "light-primary",
        3: "light-warning",
        4: "light-danger",
        5: "light-info",
        6: "light-success",
        7: "light-info",
      },
      showAudioRecorder: false,
      bgColorPriority: {
        color: {
          low: "light-primary",
          medium: "light-warning",
          high: "light-danger",
        },
        icon: {
          low: null,
          medium: "AlertCircleIcon",
          high: "AlertTriangleIcon",
        },
      },
      showAddParticipants: false,
      showEmojiSelector: false,
      solveTicketModalBoolean: false,
      dataUsers: [],
      addTicketParticipants: [], // current and new participants
      searchParticipants: null,
      userImage: null,
      searchInput: null,
      optionsView: [
        {
          text: "users",
          value: 1,
          icon: "UsersIcon",
        },
        {
          text: "calls",
          value: 2,
          icon: "PhoneCallIcon",
        },
        {
          text: "files",
          value: 3,
          icon: "FolderIcon",
        },
      ],
      selectedView: 1,
      callsAudio: [],
      limitChars: 350, // chars
      idAllChars: [],
      optionFiles: null,
      styleFile: {
        color: {
          xlsx: "text-success",
          doc: "text-primary",
          docx: "text-primary",
          docs: "text-primary",
          pptx: "text-warning",
          pdf: "text-danger",
        },
        name: {
          xlsx: "Excel",
          doc: "Word",
          docs: "Word",
          docx: "Word",
          pptx: "Power Point",
          pdf: "Pdf",
        },
      },
      showAllMotive: false,
      limitMotive: 100,
      showAllDescription: false,
      limitDescription: 100,
      showImage: false,
      urlImage: null,
      actionBarActive: true,
      assetsBaseUrl: `${process.env.BASE_URL}assets/images/files-types/`,
      reactionsBaseUrl: `${process.env.BASE_URL}assets/images/reactions/`,
      searchMessage: false,
      keyFiles: 0,
      busyCalls: false,
      busyFiles: false,
      showReactionSelector: false,
      viewArrowToGoDown: false,
      pendingMessagesToReadCount: 0,
      modalUploadFiles: false,
      isDraggingOver: false,
      isDraggingFile: false,
      selectedFiles: [],
      showMoreOptionsSelector: null,
      editedMessage: null,
      moreOptions: [
        {
          key: "edit",
          name: "Edit Message",
          onclick: this.editMessage,
        },
        {
          key: "reply",
          name: "Reply Message",
          onclick: this.replyMessage,
        },
        {
          key: "add-note",
          name: "Add Note",
          onclick: this.addNote,
        },
      ],
      loadFiles: false,
      repliedMessage: null,
      fileSelected: {},
      maxSizeFiles: {
        KB: 1024,
        MB: 1024 ** 2,
        GB: 1024 ** 3,
      },
      totalMaxSize: {
        letter: "MB",
        value: 10,
      },
      indexSelected: null,
      extensionsNoAccepted: ["avi", "ogv", "webm"],
      actualDbTime: null,
      dbTimeInterval: null,
      filesSelected: [],
      reactionsDetailsView: {
        show: null,
        data: null,
      },
      taggeds: [],
      checkisTag: false,
      cursorPosition: 0,
      tagSearch: {
        position: {
          initial: 0,
          final: 0,
        },
        text: "",
      },
      lastMessageId: null,
      scrollableContainer: null,
      lastMessageIdNotviewed: null,
      countMessagesNotViewed: 0,
      completeLoad: false,
      showModalTicketSummary: false,
      showModalTicketSummaryTracking: false,
      isAddingNote: false,
      noteId: null,
      sendNote: false,
      newStatus: null,
      statusF: null,
      paramsAddTask: {},
      showModalAddTask: false,
      paramsListTaks: {},
      showModalListTaks: false,
    };
  },
  computed: {
    // get currentUser object with modul role id etc
    currentUser() {
      return store.getters["auth/currentUser"];
    },
    // get current ticket id from store
    currentTicket() {
      return store.getters["TicketCustomerStore/G_OPEN_TICKET_CUSTOMER_ID"];
    },
    // get current session uuid from store
    getCurrentSesion() {
      return store.getters[
        "TicketCustomerStore/G_TICKET_CUSTOMER_CURRENT_SESSION"
      ];
    },
    // get new messages from store
    getNewCustomerMessages() {
      return store.getters["TicketCustomerStore/G_NEW_TICKET_CUSTOMER_MESSAGE"];
    },
    // get new viewer from sended message in store
    getNewViewer() {
      return store.getters[
        "TicketCustomerStore/G_NEW_VIEWER_TICKET_CUSTOMER_MESSAGE"
      ];
    },
    getParticipantStatus() {
      return this.statusSessionBadge(
        store.getters["TicketCustomerStore/G_TICKET_PARTICIPANTS_STATUS"]
      );
    },
    newReaction() {
      return store.state.TicketCustomerStore.S_NEW_REACTION_CUSTOMER_MESSAGE;
    },
    // get new message when a different user send a message an saved it in store
    newMessage() {
      return store.state.TicketCustomerStore.S_NEW_TICKET_CUSTOMER_MESSAGE;
    },
    // xd?
    newViewer() {
      return store.state.TicketCustomerStore
        .S_NEW_VIEWER_TICKET_CUSTOMER_MESSAGE;
    },
    // get user name concated from currentuser store object
    getUserName() {
      return `${this.currentUser.first_name} ${this.currentUser.last_name}`;
    },
    // get id from currentUser store object
    getUserId() {
      return this.currentUser.user_id;
    },
    // get participants from sidebar participants selector
    myParticipants() {
      let participants = [];
      if (this.getParticipantStatus != null) {
        participants = this.getParticipantStatus.slice(
          0,
          this.showAllParticipants
            ? this.getParticipantStatus.length
            : this.limitParticipants
        );
      }
      return participants;
    },
    myDataUsers() {
      return this.dataUsers.filter(
        (item) =>
          !this.addTicketParticipants.find(
            (participant) => participant.id_user === item.id_user
          )
      );
    },
    allEmployeeAdded() {
      return this.addTicketParticipants.length === this.dataUsers.length;
    },
    isCeo() {
      return this.currentUser.user_id === 1;
    },
    getParticipantPhoto() {
      return this.ticketParticipants.find(
        (participant) => participant.id_user === this.getUserId
      ).image;
    },
    showTicketInformation() {
      return !this.showAddParticipants;
    },
    getReactionsUrls() {
      return [
        `${this.reactionsBaseUrl}like.svg`,
        `${this.reactionsBaseUrl}check.svg`,
        `${this.reactionsBaseUrl}love.svg`,
      ];
    },
    filterOptionsView() {
      if (this.customerTicketChat.type_ticket == "EXPENSES") {
        return this.optionsView.filter((item) => item.value == 1);
      } else {
        return this.optionsView;
      }
    },
  },
  watch: {
    // watch new messages and scroll that
    cursorPosition() {
      const lastChar = this.messageInput.substring(
        this.cursorPosition - 1,
        this.cursorPosition
      );
      const lastChar2 = this.messageInput.substring(
        this.cursorPosition - 2,
        this.cursorPosition - 1
      );
      if (lastChar.trim() === "@") {
        if (lastChar2.trim() === "@") {
          this.checkisTag = false;
          return;
        }
        this.checkisTag = true;
        const previusAt = this.messageInput
          .substring(0, this.cursorPosition)
          .lastIndexOf("@");
        this.tagSearch.position.initial =
          previusAt == -1 ? this.cursorPosition : previusAt;
        this.tagSearch.position.final = this.cursorPosition;
        this.tagSearch.text = this.messageInput.substring(
          this.tagSearch.position.initial,
          this.tagSearch.position.final
        );
      } else {
        const nextAt =
          this.cursorPosition -
            this.messageInput
              .substring(this.cursorPosition, this.messageInput.length)
              .indexOf("@") >
          0
            ? this.cursorPosition -
              this.messageInput
                .substring(this.cursorPosition, this.messageInput.length)
                .indexOf("@")
            : this.messageInput.length;
        const previusAt = this.messageInput
          .substring(0, this.cursorPosition - 1)
          .lastIndexOf("@");
        this.tagSearch.position.final =
          nextAt == -1 ? this.messageInput.length : nextAt;
        this.tagSearch.position.initial = previusAt == -1 ? 0 : previusAt;
        this.tagSearch.text = this.messageInput.substring(
          this.tagSearch.position.initial,
          nextAt == -1 ? this.messageInput.length : nextAt
        );
      }
      if (!this.taggeds) return;
      for (let i = 0; i < this.taggeds.length; i++) {
        if (!this.messageInput.includes(`@${this.taggeds[i].creatorName}`)) {
          this.taggeds.splice(i, 1);
        }
      }
    },
    messages(oldval, newVal) {},
    completeLoad(oldval, newVal) {
      if (oldval) {
        const scrollable = document.getElementById("chat");

        if (
          typeof scrollable !== "undefined" &&
          scrollable !== null &&
          typeof this.messages !== "undefined"
        ) {
          this.scrollableContainer = document.getElementById("chat");
          const resizeObserver = new ResizeObserver(this.scrollToBottom);
          if (
            this.viewArrowToGoDown &&
            !this.lastMessageId &&
            this.messages[this.messages.length - 1].creatorId !== this.getUserId
          ) {
            this.pendingMessagesToReadCount += 1;

            return;
          }
          resizeObserver.observe(this.scrollableContainer);
          this.scrollableContainer.addEventListener(
            "scroll",
            this.onScrollChat
          );
        }
      }
    },
    newMessage(oldval) {
      /**
       * ? This methods are called when an user receive a new message and the chat is open
       */
      /** NEW MESSAGE */

      this.addNewMessage();
      this.viewMessage(oldval);
      const resizeObserver = new ResizeObserver(this.scrollToBottom);
      if (
        this.viewArrowToGoDown &&
        this.messages[this.messages.length - 1].creatorId !== this.getUserId
      ) {
        this.pendingMessagesToReadCount += 1;

        return;
      }
      resizeObserver.observe(this.scrollableContainer);
    },
    newViewer: {
      handler() {
        /*
         * ? This methods are called when an user view a message or multiple messages
         */
        // check if this.getNewViewer contains key chatTicketMessageIds for view multiple messages
        if (this.getNewViewer.hasOwnProperty("chatTicketMessageIds")) {
          this.addNewViewerToMessages(this.getNewViewer);
          return;
        }
        // check if this.getNewViewer contains key chatTicketMessageId for view single message
        this.addNewViewerToMessage(this.getNewViewer);
      },
      deep: true,
    },
    files(oldVal) {
      // no send when drag and drop files
      if (oldVal.length > 0 && !this.modalUploadFiles) {
        // send files to backend
        this.uploadFiles(oldVal);
      } else if (oldVal.length > 0 && this.modalUploadFiles) {
        const imgUrl = URL.createObjectURL(oldVal[0]);
        oldVal[0].imgUrl = imgUrl;
        this.filesSelected.push(oldVal[0]); // container all files
        this.fileSelected = oldVal[0]; // only file selected
        this.indexSelected = this.filesSelected.length - 1;
      }
    },
    newReaction(oldVal) {
      this.addNewReaction(oldVal);
    },
  },
  async mounted() {
    this.toggleModal("chat");

    this.status_customer_ticket = this.customerTicketChat.id_status;
    if (![2, 3].includes(this.customerTicketChat.id_status)) {
      this.actionBarActive = false;
    } else if (this.customerTicketChat.id_status === 2) {
      this.actionBarActive = this.getUserId === 1;
    }
    await this.getLastMessageId();
    this.setTicketCustomerChatCurrentSession();
    this.assignTicketInformation();
    await this.getUsers();
    await this.getParticipantsTicket();
    if (this.customerTicketChat.type_ticket !== "EXPENSES") {
      await this.getLeadCallLog();
    }
    this.chatContainer = document.getElementById("chat");

    await this.getMessages();
    await this.getFilesTicketsCustomer();
    await this.initDbTimer();
    const textArea = document.getElementById("text-area");
    textArea.focus();
    textArea.addEventListener("keyup", (event) => {
      this.cursorPosition = event.target.selectionStart;
      if (event.key === "Enter") {
        this.checkisTag = false;
      }
      if (!this.taggeds) return;
      for (let i = 0; i < this.taggeds.length; i++) {
        if (!this.messageInput.includes(`@${this.taggeds[i].creatorName}`)) {
          this.taggeds.splice(i, 1);
        }
      }
    });
    textArea.addEventListener("keydown", (event) => {
      this.cursorPosition = event.target.selectionStart;
      const lastChar = this.messageInput.substring(
        this.cursorPosition - 1,
        this.cursorPosition
      );

      if (event.key === "Backspace" && lastChar.trim() === "@") {
        this.checkisTag = false;
      }
    });
    this.completeLoad = true;
  },
  async destroyed() {
    await TicketService.deleteCurrentSession({
      currentSession: this.getCurrentSesion,
    });
    this.setCurrentSesion(null);
    this.setCurrentTicketChat(null);
    this.messages = [];
    clearInterval(this.dbTimeInterval);
  },
  methods: {
    async handleUserResponsibleChange(ticket, value) {
      try {
        const params = {
          ticket_id: ticket.id,
          user_id: value === 1 ? this.currentUser.user_id : null,
        };
        const data = await CustomerTicketsService.setUserResposible(params);
        if (data.status === 200) {
          this.$emit("refreshTable");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async sendMultipleMessagesTypes(files) {
      const temporaryFiles = files;
      // const temporaryFiles = this.files;
      await this.sendMessageHandler();
      await this.uploadFiles(temporaryFiles);
    },
    getParticipantPhotoTag(id_user) {
      return this.ticketParticipants.find(
        (participant) => participant.id_user === id_user
      ).image;
    },
    statusSessionBadge(participants) {
      if (participants != null) {
        for (let i = 0; i < participants.length; i += 1) {
          const participant = participants[i];
          let badge = "secondary";

          switch (participant.status_session_id) {
            case 1:
              badge = "success";
              break;
            case 2:
              badge = "warning";
              break;
            case 3:
              badge = "danger";
              break;
            case 5:
              badge = "info";
              break;
            case 6:
              badge = "primary";
              break;
            case 7:
              badge = "info";
              break;
            case 8:
              badge = "primary";
              break;
            default:
              badge = "secondary";
          }
          participant.badge = badge;
        }
      }
      return participants;
    },
    openModalShow(item, customerTicketChat) {
      this.openModalShowDescription = true;
      this.motiveDescription = item;
      this.detailsTicket = customerTicketChat;
    },
    async initDbTimer() {
      const data = await TicketService.getCurrentChatTime();
      this.actualDbTime = data.data;
      this.dbTimeInterval = setInterval(() => {
        this.actualDbTime = new Date(this.actualDbTime);
        this.actualDbTime = this.actualDbTime.getTime() + 1000; // Agrega un segundo (1000 milisegundos)
      }, 1000);
    },
    closeModalShow() {
      // this.refreshTable();
      this.openModalShowDescription = false;
    },
    // ...mapActions({
    //   A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD:
    //     'NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD',
    //   A_COUNT_INDICATORS_TICKETS:
    //     'NotificationStore/A_COUNT_INDICATORS_TICKETS',
    //   A_COUNT_PENDING_CUSTOMER_TICKETS:
    //     'NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS',
    //   A_SET_TICKET_PARTICIPANTS_STATUS:
    //     'TicketCustomerStore/A_SET_TICKET_PARTICIPANTS_STATUS',
    // }),

    addNewReaction(newR) {
      const messageIndex = this.messages.findIndex(
        (message) => message.chatTicketMessageId === newR.chatTicketMessageId
      );
      const newReaction = {
        reactionTableId: newR.reactionTableId,
        reactionId: newR.reactionId,
        reactionFile: this.getFileName(newR.reactionFile),
        creatorName: newR.creatorName,
        createdBy: newR.createdBy,
      };
      if (!this.messages[messageIndex].reactionsInfo) {
        this.messages[messageIndex].reactionsInfo = JSON.stringify([
          newReaction,
        ]);
      } else {
        const reactionsInfo = JSON.parse(
          this.messages[messageIndex].reactionsInfo
        );
        const reactionIndex = reactionsInfo.findIndex(
          (reaction) => reaction.reactionTableId === newR.reactionTableId
        );
        if (reactionIndex === -1) {
          reactionsInfo.push(newReaction);
        } else {
          reactionsInfo.splice(reactionIndex, 1);
        }
        this.messages[messageIndex].reactionsInfo =
          JSON.stringify(reactionsInfo);
      }
      this.$forceUpdate(`message-${messageIndex}`);
    },
    async handleEnter(event) {
      // Si se presiona Enter y hay archivos, envia los archivos
      if (event.key === "Enter" && this.modalUploadFiles) {
        // this.uploadFiles(this.filesSelected);
        this.sendMultipleMessagesTypes(this.filesSelected);
      } else if (event.shiftKey) {
        // Si se presiona Enter + Shift, agrega una nueva línea
        this.messageInput += "\n";
      } else if (event.key === "Enter") {
        // Si se presiona Enter solo, envía el mensaje
        this.sendMessageHandler();
        const textArea = document.getElementById("text-area");
        textArea.selectionStart = 0;
        textArea.selectionEnd = 0;
        textArea.focus();
        // Limpia el campo de entrada después de enviar el mensaje
        this.messageInput = "";
      }
      // Evita que se agregue una nueva línea al campo de entrada
      event.preventDefault();
    },
    onScrollChat() {
      const { scrollTop, scrollHeight, clientHeight } =
        document.getElementById("chat");
      const actualPosition = (
        scrollTop /
        (scrollHeight - clientHeight)
      ).toFixed(2);
      this.viewArrowToGoDown = actualPosition < 0.9;
      if (!this.viewArrowToGoDown) {
        this.pendingMessagesToReadCount = 0;
      }
      return actualPosition;
    },
    dashboardRouteNameByClient(account) {
      let route = null;
      if (account.program_id !== 3) {
        const routers = {
          1: "bussiness-dashboard-general",
          2: "boost-credit-dashboard-general",
          // 3: 'credit-experts-dashboard-general',
          4: "debt-solution-dashboard-general",
          5: "tax-research-dashboard-general",
          6: "court-info-dashboard-general",
          7: "specialists-dashboard-general",
          8: "bookeeping-dashboard-general",
          9: "paragon-dashboard-general",
          // 18: 'quality-client-dashboard',
          // 22: 'ce-customer-service-dashboard',
        };
        route = routers[account.program_id] || null;
      }
      if (account.program_id === 3) {
        if (account.client_type_id === 1) {
          route = "credit-experts-dashboard-general";
        }
        if (account.client_type_id === 2) {
          route = "ce-customer-service-dashboard-general";
        }
      }
      return route;
    },
    setCurrentSesion(currentSession) {
      store.commit(
        "TicketCustomerStore/SET_TICKET_CUSTOMER_CURRENT_SESSION",
        currentSession
      );
    },
    setCurrentTicketChat(ticketChatId) {
      store.commit(
        "TicketCustomerStore/SET_OPEN_TICKET_CUSTOMER_ID",
        ticketChatId
      );
    },
    updatePendingMessages() {
      store.dispatch("NotificationStore/A_PENDING_CUSTOMER_TICKETS");
    },
    addEmoji(character) {
      this.messageInput += character;
    },
    async setTicketCustomerChatCurrentSession() {
      this.setCurrentTicketChat(this.customerTicketChat.id);
      const params = {
        ticketCustomerId: this.customerTicketChat.id,
        createdBy: this.getUserId,
      };
      const data = await TicketService.setCurrentSession(params);
      this.setCurrentSesion(data.data.session.currentSession);
    },
    highlightText(text) {
      this.addPreloader();
      if (!this.searchInput) {
        this.removePreloader();
        return text.message;
      }
      const regex = new RegExp(this.searchInput, "gi");
      const result = text.message.replace(
        regex,
        (match) => `<b style="background-color:yellow">${match}</b>`
      );
      this.removePreloader();

      return result;
    },
    scrollToMessage(chatTicketMessageId) {
      const messageDiv = document.getElementById(
        `message-item-${chatTicketMessageId}`
      );
      if (messageDiv) {
        const newspaperSpinning = [
          { backgroundColor: "#FBFCFC" },
          { backgroundColor: "#d0f0ff" },
        ];

        const newspaperTiming = {
          duration: 1500,
          iterations: 1,
        };
        messageDiv.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
        const onScrollComplete = () => {
          // Después de que termine el scroll, agrega la clase de estilo
          messageDiv.animate(newspaperSpinning, newspaperTiming);

          // Desvincula el evento después de su ejecución
          this.scrollableContainer.removeEventListener(
            "scroll",
            onScrollComplete
          );
        };

        // Agrega un evento para detectar cuando el scroll está completo
        this.scrollableContainer.addEventListener("scroll", onScrollComplete);

        // this.lastMessageId = null;
      }
    },
    async sendReaction(reaction, reactionId, message) {
      try {
        if (!this.actionBarActive) return;
        this.showEmojiSelector = false;
        this.showReactionSelector = null;
        this.closeReactionsOptionsSelectors();
        const lastIndex = this.checkReaction(reaction, reactionId, message);
        const params = {
          ticketCustomerId: this.customerTicketChat.id,
          creatorId: this.getUserId,
          creatorName: this.getUserName,
          session: this.getCurrentSesion,
          customerTicketChat: this.customerTicketChat,
          chatTicketMessageId: message.chatTicketMessageId,
          reactionFile: this.getFileName(reaction),
          reactionId,
          reactionTableId: lastIndex,
        };
        const reactionTableId = await TicketService.sendReaction(params);

        if (reactionTableId.data === 0) return;
        const reactionsInfo = JSON.parse(message.reactionsInfo);
        reactionsInfo[lastIndex].reactionTableId = reactionTableId.data;
        message.reactionsInfo = JSON.stringify(reactionsInfo);
      } catch (e) {
        console.error(e);
      }
    },
    checkReaction(reactionUrl, reactionId, message) {
      const newReaction = {
        reactionId,
        reactionFile: this.getFileName(reactionUrl),
        createdBy: this.getUserId,
        creatorName: this.getUserName,
        reactionTableId: 0,
      };

      if (!message.reactionsInfo) {
        message.reactionsInfo = JSON.stringify([newReaction]);

        return 0;
      }
      const reactionsParsed = JSON.parse(message.reactionsInfo);
      const findedReaction = reactionsParsed.findIndex(
        (reaction) =>
          reaction.reactionId === reactionId &&
          reaction.createdBy === this.getUserId
      );
      const lastIndex = reactionsParsed.length;
      if (findedReaction === -1) {
        reactionsParsed.push(newReaction);
        message.reactionsInfo = JSON.stringify(reactionsParsed);
        return lastIndex;
      }
      const chatReactionId = reactionsParsed[findedReaction].reactionTableId;
      reactionsParsed.splice(findedReaction, 1);
      message.reactionsInfo = JSON.stringify(reactionsParsed);
      return chatReactionId;
    },
    async sendMessage() {
      try {
        // cuando el ceo envia el primer mensaje cambiar el estado a IN PROGRESS
        const typeMessages = this.messages.filter(
          (message) => message.typeMessage !== 6
        );
        if (typeMessages.length === 0) {
          this.openCustomerChat(this.customerTicketChat, true);
        }
        if (!this.actionBarActive) return;
        this.showEmojiSelector = false;
        if (this.messageInput.trim() === "") return;
        const params = {
          ticketCustomerId: this.customerTicketChat.id,
          message: this.messageInput.trim(),
          creatorId: this.getUserId,
          creatorName: this.getUserName,
          creatorImage: this.getParticipantPhoto,
          ticketTitle: this.customerTicketChat.title,
          session: this.getCurrentSesion,
          typeMessage: this.taggeds.length > 0 ? 8 : 1,
          isAddingNote: this.isAddingNote,
          customerTicketChat: this.customerTicketChat,
          fileType: this.fileType,
          taggeds: JSON.stringify(this.taggeds),
        };
        const newMessageIndex = this.messages.length;
        this.messages.push(params);
        this.clearMessage();
        this.scrollToBottom();
        const data = await TicketService.sendMessage(params);
        this.messages[newMessageIndex] = data.data.data;
        this.$forceUpdate(`message-spinner-${newMessageIndex}`);
        this.taggeds = [];
      } catch (error) {
        console.error(error);
        this.showWarningSwal(error);
      }
    },
    async addNote(message) {
      this.closeReactionsOptionsSelectors();
      this.showMoreOptionsSelector = null;
      this.editedMessage = message;
      this.sendNote = true;
      this.taggeds = JSON.parse(message.taggeds);
      this.repliedMessage = null;
      this.taggeds = this.taggeds ? this.taggeds : [];
      this.isAddingNote = true;
      const params = {
        ticketCustomerId: this.customerTicketChat.id,
        message: this.editedMessage.message,
        creatorId: this.getUserId,
        creatorName: this.getUserName,
        creatorImage: this.getParticipantPhoto,
        ticketTitle: this.customerTicketChat.title,
        session: this.getCurrentSesion,
        typeMessage:
          this.taggeds.length > 0 ? 8 : this.editedMessage.typeMessage,
        isAddingNote: this.isAddingNote,
        customerTicketChat: this.customerTicketChat,
        fileType: this.fileType,
        chatTicketMessageId: this.editedMessage.chatTicketMessageId,
        repliedMessage: this.editedMessage.repliedMessage,
        noteId: this.noteId,
        taggeds: JSON.stringify(this.taggeds),
      };
      const editedMessageIndex = this.messages.findIndex(
        (message) =>
          message.chatTicketMessageId === this.editedMessage.chatTicketMessageId
      );
      this.addPreloader();
      if (message.is_note === 1) {
        this.noteId = null;
        const params2 = {
          chatTicketMessageId: message.chatTicketMessageId,
          leadId: this.customerTicketChat.lead_id,
          clientAccountId: this.customerTicketChat.client_account_id,
        };
        const data = await TicketService.getNoteNoteAccountId(params2);
        if (data.data.length > 0) {
          params.noteId = data.data[0].id;
          params.isAddingNote = false;
          const data2 = await TicketService.sendMessage(params);
          this.isAddingNote = false;
          this.messages[editedMessageIndex] = data2.data.data;
          this.editedMessage = null;
          this.sendNote = false;
          this.clearMessage();
          this.$forceUpdate(`message-${editedMessageIndex}`);
          this.removePreloader();

          this.taggeds = [];
          return;
        }
      }

      this.isAddingNote = false;
      const data = await TicketService.sendMessage(params);
      this.messages[editedMessageIndex] = data.data.data;
      this.editedMessage = null;
      this.sendNote = false;
      this.clearMessage();
      this.$forceUpdate(`message-${editedMessageIndex}`);
      this.removePreloader();

      this.taggeds = [];
    },
    async editMessage(message) {
      this.noteId = null;
      this.messageInput = message.message;
      this.showMoreOptionsSelector = null;
      this.editedMessage = message;
      this.taggeds = JSON.parse(message.taggeds);
      this.repliedMessage = null;
      this.closeReactionsOptionsSelectors();
      const params = {
        chatTicketMessageId: message.chatTicketMessageId,
        leadId: this.customerTicketChat.lead_id,
        clientAccountId: this.customerTicketChat.client_account_id,
      };
      const data = await TicketService.getNoteNoteAccountId(params);
      if (data.data.length > 0) {
        this.noteId = data.data[0].id;
        this.isAddingNote = true;
      }
    },
    async sendEditedMessage() {
      if (!this.actionBarActive) return;
      this.showEmojiSelector = false;
      this.showMoreOptionsSelector = null;
      if (this.messageInput.trim() === "") return;
      this.taggeds = this.taggeds ? this.taggeds : [];

      const params = {
        ticketCustomerId: this.customerTicketChat.id,
        message: this.messageInput,
        creatorId: this.getUserId,
        creatorName: this.getUserName,
        creatorImage: this.getParticipantPhoto,
        ticketTitle: this.customerTicketChat.title,
        session: this.getCurrentSesion,
        typeMessage:
          this.taggeds.length > 0 ? 8 : this.editedMessage.typeMessage,
        isAddingNote: this.isAddingNote,
        customerTicketChat: this.customerTicketChat,
        fileType: this.fileType,
        chatTicketMessageId: this.editedMessage.chatTicketMessageId,
        repliedMessage: this.editedMessage.repliedMessage,
        noteId: this.noteId,
        taggeds: JSON.stringify(this.taggeds),
      };
      const editedMessageIndex = this.messages.findIndex(
        (message) =>
          message.chatTicketMessageId === this.editedMessage.chatTicketMessageId
      );
      const data = await TicketService.sendMessage(params);
      this.messages[editedMessageIndex] = data.data.data;
      this.isAddingNote = false;
      this.editedMessage = null;
      this.clearMessage();
      this.$forceUpdate(`message-${editedMessageIndex}`);
      this.taggeds = [];
    },
    clearMessage() {
      this.messageInput = "";
      this.files = [];
      this.modalUploadFiles = false;
    },
    async getMessages() {
      const params = {
        ticketCustomerId: this.customerTicketChat.id,
      };
      try {
        this.addPreloader();
        const messages = await TicketService.getMessages(params);
        this.messages = messages.data.data;
        this.removePreloader();
        const viewedMessages = this.getNotViewedMessages();
        if (viewedMessages.length === 0) return;
        const params2 = {
          viewedMessages,
          viewerInfo: {
            viewerId: this.getUserId,
            viewerName: this.getUserName,
            viewerImage: this.getParticipantPhoto,
          },
          ticketCustomerId: this.customerTicketChat.id,
          session: this.getCurrentSesion,
        };
        await TicketService.viewTicketCustomerMessages(params2);
        this.updatePendingMessages();
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE",
          {
            user_id: this.currentUser.user_id,
          }
        );
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT",
          {
            user_id: this.currentUser.user_id,
          }
        );
        if (typeof this.$route !== "undefined") {
          if (this.$route.hasOwnProperty("params")) {
            if (this.$route.params.hasOwnProperty("idClient")) {
              await store.dispatch(
                "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
                {
                  user_id: this.currentUser.user_id,
                  client_account_id: this.$route.params.idClient,
                }
              );
            }
          }
        } else {
          await store.dispatch(
            "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_DASHBOARD",
            {
              user_id: this.currentUser.user_id,
              client_account_id: this.customerTicketChat.client_account_id,
            }
          );
        }

        this.$emit("refreshTable");
      } catch (error) {
        console.error(error);
        this.removePreloader();
        this.showWarningSwal(error);
      }
    },
    async addNewMessage() {
      if (this.getNewCustomerMessages.session === this.getCurrentSesion) return;
      if (this.getNewCustomerMessages.edited) {
        const editedMessageIndex = this.messages.findIndex(
          (message) =>
            message.chatTicketMessageId ===
            this.getNewCustomerMessages.chatTicketMessageId
        );
        this.messages[editedMessageIndex] = this.getNewCustomerMessages;
        this.$forceUpdate(`message-${editedMessageIndex}`);
        return;
      }
      if (this.getNewCustomerMessages.replied) {
        this.message.push(this.messages.push(this.getNewCustomerMessages));
      }
      if (this.messages.length > 0) {
        if (
          this.messages[this.messages.length - 1].chatTicketMessageId ===
          this.getNewCustomerMessages.chatTicketMessageId
        ) {
          return;
        }
      }
      this.messages.push(this.getNewCustomerMessages);

      // refresh files
      const typeFiles = [2, 3, 4, 5]; // 2: Files, 3: Audio, 4: Image, 5: pdf
      const typeMessage = parseInt(this.getNewCustomerMessages.typeMessage, 10);
      if (typeFiles.includes(typeMessage)) {
        await this.getFilesTicketsCustomer();
      }

      this.$forceUpdate(
        `message-viewersInfo-${this.getNewCustomerMessages.chatTicketMessageId}`
      );
    },
    async viewMessage(oldval) {
      try {
        const params = {
          viewerId: this.getUserId,
          chatTicketMessageId: this.getNewCustomerMessages.messageId,
          viewerName: this.getUserName,
          viewerImage: this.getParticipantPhoto,
          ticketCustomerId: this.customerTicketChat.id,
          session: this.getCurrentSesion,
          creatorId: this.getUserId,
          creatorName: this.getUserName,
          viewerAt: this.getNewCustomerMessages.viewerAt,
          creatorImage: this.getParticipantPhoto,
        };
        await TicketService.viewTicketCustomerMessage(params);
        this.addNewViewerToMessage(params);
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
          {
            user_id: this.currentUser.user_id,
          }
        );
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT",
          {
            user_id: this.currentUser.user_id,
          }
        );
        await store.dispatch(
          "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE"
        );

        await store.dispatch("NotificationStore/A_COUNT_INDICATORS_TICKETS", {
          user_id: this.currentUser.user_id,
          module_id: this.currentUser.modul_id,
        });
        this.$emit("refreshTable");
      } catch (error) {
        console.error(error);
      }
    },
    closeModal() {
      this.$emit("close");
    },
    addNewViewerToMessage(viewerData) {
      try {
        // const lastMessage = this.messages[this.messages.length - 1];
        const findMessage = this.messages.find(
          (message) =>
            message.chatTicketMessageId === viewerData.chatTicketMessageId
        );
        const viewerInfo = {
          viewerBy: viewerData.creatorId,
          viewerName: viewerData.creatorName,
          viewerAt: viewerData.viewerAt,
          viewerImage: viewerData.creatorImage,
        };
        if (findMessage.creatorId === viewerInfo.viewerBy) return;

        if (findMessage.hasOwnProperty("viewersInfo")) {
          // check if lastMessage.viewersInfo is null
          if (findMessage.viewersInfo === null) {
            // create a new array with viewerInfo
            findMessage.viewersInfo = JSON.stringify([viewerInfo]);
          } else {
            // check if findMessage.viewersInfo is not null
            const viewersInfo = JSON.parse(findMessage.viewersInfo);
            if (
              viewersInfo.find(
                (viewer) => viewer.viewerBy === viewerData.creatorId
              )
            ) {
              return;
            }
            viewersInfo.push(viewerInfo);
            findMessage.viewersInfo = JSON.stringify(viewersInfo);
          }
        } else {
          // create property  and set value
          findMessage.viewersInfo = JSON.stringify([viewerInfo]);
        }
        this.$forceUpdate(
          `message-viewersInfo-${findMessage.chatTicketMessageId}`
        );
      } catch (error) {
        console.error(error);
      }
    },
    addNewViewerToMessages(messagesData) {
      try {
        const viewerInfo = {
          viewerBy: messagesData.creatorId,
          viewerName: messagesData.creatorName,
          viewerAt: messagesData.viewerAt,
          viewerImage: messagesData.creatorImage,
        };

        messagesData.chatTicketMessageIds.forEach((messageId) => {
          const message = this.messages.find(
            (message2) => message2.chatTicketMessageId === messageId
          );
          if (message === null) return;
          if (typeof message === "undefined") return;
          if (message.creatorId === viewerInfo.viewerBy) return;
          if (message.hasOwnProperty("viewersInfo")) {
            // check if lastMessage.viewersInfo is null
            if (message.viewersInfo === null) {
              // create a new array with viewerInfo
              message.viewersInfo = JSON.stringify([viewerInfo]);
            } else {
              // check if lastMessage.viewersInfo is not null
              const viewersInfo = JSON.parse(message.viewersInfo);
              viewersInfo.push(viewerInfo);
              message.viewersInfo = JSON.stringify(viewersInfo);
            }
          } else {
            // create property  and set value
            message.viewersInfo = JSON.stringify([viewerInfo]);
          }
          this.$forceUpdate(
            `message-viewersInfo-${message.chatTicketMessageId}`
          );
        });
      } catch (e) {
        console.error(e);
      }
    },
    getNotViewedMessages() {
      if (this.messages.length === 0) return [];
      const notViewedMessages = this.messages
        .filter((message) => {
          if (message.hasOwnProperty("viewersInfo")) {
            if (message.viewersInfo !== null) {
              const viewersInfo = JSON.parse(message.viewersInfo);
              const findViewed = viewersInfo.find(
                (viewer) => viewer.viewedBy === this.getUserId
              );
              return typeof findViewed === "undefined";
            }
            return true;
          }
          return true;
        })
        .map((message) => message.chatTicketMessageId);
      return notViewedMessages;
    },
    openFileInput() {
      this.$refs.filepicker.$el.childNodes[0].click();
      this.isAddingNote = false;
    },
    async uploadFiles(files) {
      // drag and drop or clipborad no permited double send
      // if (this.modalUploadFiles && this.loadFiles) return;
      try {
        if (!this.actionBarActive) return;
        this.loadFiles = true;
        this.showAudioRecorder = false;
        const formData = new FormData();
        const params = {
          ticketCustomerId: this.customerTicketChat.id,
          creatorId: this.getUserId,
          creatorName: this.getUserName,
          creatorImage: this.getParticipantPhoto,
          ticketTitle: this.customerTicketChat.title,
          session: this.getCurrentSesion,
          customerTicketChat: this.customerTicketChat,
          fileType: this.fileType,
        };
        formData.append("ticketCustomerId", this.customerTicketChat.id);
        formData.append("creatorId", this.getUserId);
        formData.append("creatorName", this.getUserName);
        formData.append("creatorImage", this.getParticipantPhoto);
        formData.append("ticketTitle", this.customerTicketChat.title);
        formData.append("session", this.getCurrentSesion);
        formData.append(
          "customerTicketChat",
          JSON.stringify(this.customerTicketChat)
        );
        for (let i = 0; i < files.length; i += 1) {
          params.typeMessage = this.getFileType(files[i].name);
          formData.append("typeMessage", params.typeMessage);
          params.message = URL.createObjectURL(files[i]);
          params.messageName = files[i].name;
          const newMessageIndex = this.messages.length;
          this.messages.push(params);
          if (formData.has("fileType")) formData.delete("fileType");
          formData.append("fileType", this.getFileType(files[i].name));

          if (formData.has("files[]")) formData.delete("files[]");
          formData.append("files[]", files[i]);
          const data = await TicketService.sendMessage(formData);
          this.messages[newMessageIndex] = data.data.data;
          this.clearMessage();
          this.scrollToBottom();
        }
        await this.getFilesTicketsCustomer();
        this.closeDragDropFiles();
      } catch (e) {
        this.loadFiles = false;
        this.showErrorSwal();
        console.error(e);
      }
    },
    async sendAudio(blob) {
      try {
        if (!this.actionBarActive) return;
        this.showAudioRecorder = false;
        const params = {
          ticketCustomerId: this.customerTicketChat.id,
          creatorId: this.getUserId,
          creatorName: this.getUserName,
          creatorImage: this.getParticipantPhoto,
          ticketTitle: this.customerTicketChat.title,
          session: this.getCurrentSesion,
          customerTicketChat: this.customerTicketChat,
          fileType: this.fileType,
        };
        params.typeMessage = this.getFileType("blob.wav");
        params.message = URL.createObjectURL(blob);
        const newMessageIndex = this.messages.length;
        this.messages.push(params);
        const formData = new FormData();
        formData.append("ticketCustomerId", this.customerTicketChat.id);
        formData.append("creatorId", this.getUserId);
        formData.append("creatorName", this.getUserName);
        formData.append("ticketTitle", this.customerTicketChat.title);
        formData.append("creatorImage", this.getParticipantPhoto);
        formData.append("typeMessage", 3);
        formData.append("session", this.getCurrentSesion);
        formData.append(
          "customerTicketChat",
          JSON.stringify(this.customerTicketChat)
        );
        formData.append("fileType", this.fileType);
        formData.append("files[]", blob);
        const data = await TicketService.sendMessage(formData);
        this.messages[newMessageIndex] = data.data.data;
        this.clearMessage();
        await this.getFilesTicketsCustomer();
      } catch (e) {
        console.error(e);
      }
    },
    getFileType(filename) {
      const ext = filename.split(".").pop().toLowerCase();
      if (ext === "pdf") return 2;
      if (["png", "jpg", "jpeg", "svg", "gif"].includes(ext)) return 4;
      if (["mp3", "wav", "ogg"].includes(ext)) return 3;
      if (["docx", "doc", "ppt", "pptx", "xls", "xlsx"].includes(ext)) return 2;
      return 2;
    },
    uniqueAccounts(accounts, clientAccountId) {
      // Filtrar la lista para eliminar elementos con el mismo id que client_account_id
      return accounts.filter((account) => account.id !== clientAccountId);
    },
    filterColor(value) {
      switch (value) {
        case 1:
          return "#00cc00";
        case 2:
          return "#ffc107";
        case 3:
          return "#0066ff";
        case 4:
          return "red";
        case 5:
          return "red";
        case 6:
          return "red";
        default:
          return "gray";
      }
    },
    async getParticipantsTicket() {
      try {
        const params = {
          ticketCustomerId: this.customerTicketChat.id,
        };

        const { data } = await ParticipantsServices.getParticipantsTickect(
          params
        );

        // return only the unique roles
        const newValues = data.map((element) => {
          const { modules } = element;
          const uniqueRole = [];
          const myModules = JSON.parse(modules).filter((item) => {
            if (
              this.optionsRoles.includes(item.role_id) &&
              !uniqueRole.includes(item.role_id)
            ) {
              uniqueRole.push(item.role_id);
              return true;
            }
            return false;
          });

          // Crear un nuevo objeto con las modificaciones en los módulos
          return {
            ...element,
            modules: JSON.stringify(myModules),
          };
        });

        this.ticketParticipants = newValues;
        store.dispatch(
          "TicketCustomerStore/A_SET_TICKET_PARTICIPANTS_STATUS",
          this.ticketParticipants
        );
        // this.A_SET_TICKET_PARTICIPANTS_STATUS(this.ticketParticipants);
      } catch (error) {
        this.showErrorSwal();
        console.error("error in getParticipantsTicket: ", error);
      }
    },
    startRecording() {
      this.isAddingNote = false;
      if (!this.actionBarActive) return;

      this.showAudioRecorder = true;

      this.isRecording = !this.isRecording;
      if (this.isRecording) {
        this.$refs["audio-recorder"].startRecording();
      } else {
        this.$refs["audio-recorder"].stopRecording();
      }
    },
    getPdfBlob(pdfUrl) {
      return `https://docs.google.com/gview?url=${pdfUrl}&embedded=true`;
    },
    async getUsers() {
      try {
        this.isBusy = true;
        const params = {
          rolesId: JSON.stringify(this.optionsRoles),
          search: this.searchParticipants,
          perpage: 50,
          npage: 1,
          moduleId: null,
        };
        const { data } = await ParticipantsServices.getUserByRoles(params);
        this.dataUsers = data.data.map((element) => {
          const { modules } = element;
          const uniqueRole = [];
          const myModules = JSON.parse(modules).filter((item) => {
            if (
              this.optionsRoles.includes(item.role_id) &&
              !uniqueRole.includes(item.role_id)
            ) {
              uniqueRole.push(item.role_id);
              return true;
            }
            return false;
          });

          // Crear un nuevo objeto con las modificaciones en los módulos
          return {
            ...element,
            modules: JSON.stringify(myModules),
          };
        });
      } catch (error) {
        this.isBusy = false;
        this.showErrorSwal();
        console.error("error in getData: ", error);
      }
      return [];
    },
    toggleParticipant(item) {
      const index = this.addTicketParticipants.findIndex(
        (participant) => participant.id_user === item.id_user
      );

      if (index === -1) {
        this.addTicketParticipants = [...this.addTicketParticipants];
        this.addTicketParticipants.unshift({ ...item, isNew: true });
      } else {
        this.addTicketParticipants = this.addTicketParticipants.filter(
          (participant) => participant.id_user !== item.id_user
        );
      }
    },
    closeAddParticipants() {
      this.addTicketParticipants = [];
      this.showAddParticipants = false;
    },
    showSectionAddParticipants() {
      this.showAddParticipants = true;
      this.addTicketParticipants = this.ticketParticipants;
    },
    async getLastMessageId() {
      try {
        const params = {
          ticketCustomerId: this.customerTicketChat.id,
          userId: this.getUserId,
        };
        const data = await TicketService.getLastMessageNotViewed(params);
        this.lastMessageId = data.data[0].lastMessageId;
        this.lastMessageIdNotviewed = this.lastMessageId;
      } catch (error) {
        console.error("error in getLastMessageId: ", error);
      }
    },
    scrollToBottom() {
      if (this.lastMessageId) {
        const findIndex = this.messages.findIndex(
          (message) => message.chatTicketMessageId === this.lastMessageId
        );
        const notViewedMessagesCount = this.messages.length - findIndex;
        const messageToScroll =
          notViewedMessagesCount <= 4
            ? this.lastMessageId
            : this.messages[
                findIndex > this.messages.length - 1
                  ? this.messages.length - 1
                  : findIndex - 1
              ].chatTicketMessageId;
        const divToScrollTo = document.getElementById(
          `message-${messageToScroll}`
        );
        this.scrollableContainer.scrollTop = divToScrollTo.offsetTop;
        // get div position
        this.getNotViewedMessagesCount();
        this.lastMessageId = null;
      } else {
        this.scrollableContainer.scrollTop =
          this.scrollableContainer.scrollHeight;
      }

      // this.scrollableContainer.addEventListener('scroll', this.onScrollChat);
    },
    scrollAndViewMessages() {
      const chat = document.getElementById("chat");
      chat.scrollTop = chat.scrollHeight;
      this.pendingMessagesToReadCount = 0;
    },
    getNotViewedMessagesCount() {
      if (this.lastMessageIdNotviewed) {
        const findIndex = this.messages.findIndex(
          (message) => message.chatTicketMessageId === this.lastMessageId
        );
        this.countMessagesNotViewed = this.messages.length - findIndex;
        return;
      }
      this.countMessagesNotViewed = 0;
    },
    async saveAddParticipants() {
      try {
        const totalParticipants = this.addTicketParticipants.length;
        const { isConfirmed } = await this.showConfirmSwal(
          "Are you sure ?",
          `Are you sure to add ${totalParticipants} participants ?`
        );
        if (!isConfirmed) return;

        this.addPreloader();
        // we separate the removed participants from the actives participants
        const removeParticipants = this.ticketParticipants.filter(
          (noModify) =>
            !this.addTicketParticipants.some(
              (itemParticipant) => itemParticipant.id_user === noModify.id_user
            )
        );
        const newParticipants = this.addTicketParticipants.filter(
          (item) =>
            !this.ticketParticipants.some(
              (noModify) => noModify.id_user === item.id_user
            )
        );
        const params = {
          ticketCustomerId: this.customerTicketChat.id,
          participantsRemove: removeParticipants,
          participantsActives: this.addTicketParticipants,
          participantsNew: newParticipants, // only notification to new participants
          createdBy: this.currentUser.user_id,
          createdByName: this.currentUser.fullName,
          otherData: this.customerTicketChat,
          fileType: this.fileType,
        };
        const { status } = await ParticipantsServices.updateParticipants(
          params
        );
        if (status === 200) {
          this.showSuccessSwal();
          this.showAddParticipants = false;
          this.getParticipantsTicket();
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.error("error in saveAddParticipants from chat:", error);
      }
    },
    assignTicketInformation() {
      this.ticketItems = [
        {
          key: "ticketId",
          name: "Ticket ID",
          value: `# ${this.customerTicketChat.code || "no code"}`,
          class: null,
          icon: null,
        },
        {
          key: "requestBy",
          name: "Request by",
          value: this.customerTicketChat.created_by,
          class: null,
          icon: null,
        },
        {
          key: "createdAt",
          name: "Created at",
          value: this.customerTicketChat.created_at,
          class: null,
          icon: null,
        },
        {
          key: "status",
          name: "status",
          value: this.customerTicketChat.status,
          class: this.bgColorStatus[this.customerTicketChat.id_status],
          icon: null,
        },
        {
          key: "priority",
          name: "Priority",
          value: this.customerTicketChat.priority,
          class: this.bgColorPriority.color[this.customerTicketChat.priority],
          icon: this.bgColorPriority.icon[this.customerTicketChat.priority],
        },
        {
          key: "motive",
          name: "Motive",
          value: this.customerTicketChat.motive,
          class: null,
          icon: null,
        },
      ];
    },
    async getLeadCallLog() {
      try {
        this.busyCalls = true;
        const params = {
          lead_id: this.customerTicketChat.lead_id
            ? this.customerTicketChat.lead_id
            : this.customerTicketChat.lead_id_client,
          client_account: null,
          search_txt: "",
          per_page: 100,
          page: 1,
          date_from: null,
          date_to: null,
          status: null,
          hidden: null,
          module_id: null,
          ceo_calls: null,
        };
        const { data } = await CallLogService.getCallLogsByLeadId(params);
        this.callsAudio = data.data;
        this.busyCalls = false;
      } catch (error) {
        this.showErrorSwal();
        console.error("error in getLeadCallLog from chat:", error);
        this.busyCalls = false;
      }
    },
    showAllChars(value) {
      if (this.idAllChars.includes(value)) {
        this.idAllChars = this.idAllChars.filter((item) => item !== value);
      } else {
        this.idAllChars.push(value);
      }
    },
    async getFilesTicketsCustomer() {
      try {
        this.busyFiles = true;
        const params = {
          ticketCustomerId: this.customerTicketChat.id,
          typeFiles: null,
          limitFiles: 6,
        };
        const { data } = await ParticipantsServices.getFilesTicketsCustomer(
          params
        );
        this.keyFiles += 1;
        this.optionFiles = data;
        this.busyFiles = false;
      } catch (error) {
        this.showErrorSwal();
        console.error("error in getFilesTicketsCustomer from chat:", error);
        this.busyFiles = false;
      }
    },
    viewAllFiles(typeFileId, ticketId) {
      this.showAllFile = true;
      this.selectedChat = {
        typeFileId,
        ticketId,
      };
      this.selectedFileType = this.selectedChat;
    },

    getFileName(file) {
      // if file is blob return name
      if (file instanceof Blob) return file.name;
      const name = file.split("?")[0].split("/");
      return name[name.length - 1];
    },
    getFileImage(file) {
      if (!file) return null;
      const ext = file.split(".").pop().toLowerCase();
      return `${this.assetsBaseUrl + ext}.png`;
    },
    showImageViewer(url) {
      this.showImage = true;
      this.urlImage = url;
    },
    agrupedReactions(reactionsInfo) {
      if (!reactionsInfo) return;
      const reactionsAgruped = [];

      const reactionsParsed = JSON.parse(reactionsInfo);
      reactionsParsed.forEach((reaction) => {
        const findedGroup = reactionsAgruped.findIndex(
          (reactionGroup) => reactionGroup.reactionId == reaction.reactionId
        );
        if (findedGroup == -1) {
          reactionsAgruped.push({
            reactionId: reaction.reactionId,
            count: 1,
            fileName: this.getReactionUrl(reaction.reactionFile),
          });
        } else {
          reactionsAgruped[findedGroup].count++;
        }
      });
      return reactionsAgruped;
      // reactionsParsed.forEach(reactionItem=>{
      //   const
      // })
    },
    getReactionUrl(fileName) {
      return `${this.reactionsBaseUrl}${fileName}`;
    },
    onDragEnter(event) {
      if (!this.actionBarActive) return;
      this.preventDefault(event);
      this.isDraggingOver = true;
      this.isDraggingFile = true;
    },
    // Evento cuando el elemento es arrastrado dentro del contenedor
    onDragOver(event) {
      if (!this.actionBarActive) return;
      this.preventDefault(event);
      this.isDraggingOver = true;
    },
    // Evento cuando el elemento es arrastrado fuera del contenedor
    onDragLeave(event) {
      if (!this.actionBarActive) return;
      this.preventDefault(event);
      this.isDraggingOver = false;
      this.isDraggingFile = false; // Actualiza el dato para indicar que ya no se está arrastrando un archivo
    },
    // Evento cuando el archivo es soltado en el contenedor
    onDrop(event) {
      this.isAddingNote = false;

      if (!this.actionBarActive) return;
      this.preventDefault(event);
      this.isDraggingOver = false;
      this.isDraggingFile = false; // Actualiza el dato para indicar que ya no se está arrastrando un archivo

      const blob = event.dataTransfer.files;
      const ext = this.getExtensionFile(blob[0].name);

      // valida extension
      if (this.extensionsNoAccepted.includes(ext)) {
        this.showWarningSwal(
          "INFORMATION IMPORTANT",
          "The file extension is not accepted"
        );
        return;
      }

      // create url to file
      const imgUrl = URL.createObjectURL(blob[0]);
      blob[0].imgUrl = imgUrl;

      // sumamos el tamano de los archivos junto con el que se esta subiendo

      const sizeMax = this.maxSizeFiles[this.totalMaxSize.letter];
      const sizeFile = (blob[0].size / sizeMax).toFixed(2);

      // validamos que no supere el maximo establecido
      if (sizeFile > this.totalMaxSize.value) {
        this.showWarningSwal(
          "INFORMATION IMPORTANT",
          "The maximum size of the files is 10MB"
        );
        this.modalUploadFiles = this.files.length !== 0;
        return;
      }

      // add file to files array
      this.filesSelected.push(...blob);
      this.fileSelected = blob[0];
      this.indexSelected = this.filesSelected.length - 1;

      this.modalUploadFiles = true;
    },

    preventDefault(event) {
      event.preventDefault();
      event.stopPropagation();
    },
    convertToIcon(fileName) {
      let ext =
        fileName.split(".").length > 1 ? fileName.split(".").pop() : null;
      if (ext) {
        ext = ext.toLowerCase();
        if (["png", "jpg", "jpeg", "svg", "gif"].includes(ext)) {
          return { icon: "ImageIcon", class: "secondary" };
        }
        if (["mp3", "wav", "ogg"].includes(ext)) {
          return { icon: "MicIcon", class: "text-info" };
        }
        if (["mp4", "avi", "mov", "wmv", "flv", "3gp", "webm"].includes(ext)) {
          return { icon: "FilmIcon", class: "text-info" };
        }
        if (["docx", "doc"].includes(ext)) {
          return { icon: "FileTextIcon", class: "text-primary" };
        }
        if (["ppt", "pptx"].includes(ext)) {
          return { icon: "FileTextIcon", class: "text-warning" };
        }
        if (["xls", "xlsx"].includes(ext)) {
          return { icon: "FileTextIcon", class: "text-success" };
        }
        if (ext === "pdf") {
          return { icon: "FileTextIcon", class: "text-danger" };
        }
      }
      return { icon: "FileIcon", class: "secondary" };
    },
    removeFile(index) {
      this.filesSelected.splice(index, 1);
      this.modalUploadFiles = this.filesSelected.length > 0;
    },
    closeDragDropFiles() {
      this.filesSelected = [];
      this.modalUploadFiles = false;
      this.loadFiles = false;
    },
    handlePaste(event) {
      const { items } =
        event.clipboardData || event.originalEvent.clipboardData; // event to ANY BROWSER when paste file from clipboard

      const sizeMax = this.maxSizeFiles[this.totalMaxSize.letter]; // limit total

      items.forEach((item) => {
        if (item.kind === "file") {
          const blob = item.getAsFile();
          blob.imgUrl = URL.createObjectURL(blob);

          // sum size files with new file
          const sizeFile = (blob.size / sizeMax).toFixed(2);

          // we validate that the total size of the files does not exceed the limit
          if (sizeFile <= this.totalMaxSize.value) {
            // add file to files array
            this.filesSelected.push(blob);
            this.modalUploadFiles = true;
            this.fileSelected = blob;
          } else {
            this.showWarningSwal(
              "INFORMATION IMPORTANT",
              "The maximum size of the files is 10MB"
            );
            return false;
          }
        }
      });

      this.indexSelected = this.filesSelected.length - 1;
    },
    getExtensionFile(fileName) {
      if (!fileName) return null;
      return fileName.split(".").length > 1 ? fileName.split(".").pop() : null;
    },
    addTag(item) {
      const findTag = this.taggeds.findIndex(
        (tag) => tag.creatorName === item.user_name
      );

      if (
        this.messageInput.substring(
          this.cursorPosition - 1,
          this.cursorPosition
        ) === "@"
      ) {
        this.messageInput = `${this.messageInput.substring(
          0,
          this.tagSearch.position.initial
        )}@${item.user_name}${this.messageInput.substring(
          this.tagSearch.position.final,
          this.messageInput.length
        )}`;

        this.cursorPosition += item.user_name.length;
      } else {
        this.messageInput = `${this.messageInput.substring(
          0,
          this.tagSearch.position.initial
        )}@${item.user_name}${this.messageInput.substring(
          this.tagSearch.position.final,
          this.messageInput.length
        )}`;
        this.cursorPosition += item.user_name.length + 1;
      }
      this.checkisTag = false;

      if (findTag !== -1) return;
      this.taggeds.push({
        creatorName: item.user_name,
        creatorId: item.id_user,
      });
    },
    selectedFile(item, index) {
      this.fileSelected = item;
      this.indexSelected = index;
    },
    async openCustomerChat(ticket, changeFromChat) {
      this.customerTicketChat = ticket;
      // this.paramsTicket.ticketCustomerId = ticket.id;

      // this.openchat = true;
      if (ticket.id_status === 2) {
        try {
          const params = {
            user_id: this.currentUser.user_id,
            current_ticket: ticket.id,
            current_status_ticket: ticket.id_status,
            customerTicketChat: ticket,
          };
          if (!changeFromChat) {
            const response = await this.showConfirmSwal();

            if (!response.isConfirmed) {
              return;
            }
          }

          this.addPreloader();
          const data = await CustomerTicketsService.ChangeOpenStatusTicket(
            params
          );
          if (data.status === 200) {
            await store.dispatch(
              "NotificationStore/A_COUNT_INDICATORS_TICKETS",
              {
                user_id: this.currentUser.user_id,
                module_id: this.currentUser.main_module,
              }
            );

            await store.dispatch(
              "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT",
              {
                user_id: this.currentUser.user_id,
              }
            );
            await store.dispatch(
              "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_MODULE"
            );
            await store.dispatch(
              "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
              {
                user_id: this.currentUser.user_id,
                client_account_id: null,
              }
            );
            // await this.A_COUNT_PENDING_CUSTOMER_TICKETS({
            //   user_id: this.currentUser.user_id,
            //   client_account_id: null,
            // });
            this.removePreloader();
            this.actionBarActive = true;
            this.status_customer_ticket = 3;
            // this.closeModal();
            this.showSuccessSwal();
          }
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal(error);
          console.error(error);
          // we read all items and filter only files
        }
      }
    },
    openSolveTicketModal(status, item) {
      this.statusF =
        status == "fidelized" ? 6 : status == "no-fidelized" ? 5 : null;
      this.newStatus = status;
      this.customerTicketSelected = item;
      this.solveTicketModalBoolean = true;
    },
    openModalEmployeeActivityRecords(item) {
      if (item.id_user === 1) return;
      if (this.getUserId !== 1) return;
      this.selectedParticipant = item;
      this.modalEmployeeActivityRecords = true;
    },
    replyMessage(message) {
      this.messageInput = "";
      this.closeReactionsOptionsSelectors();
      this.repliedMessage = message;
      this.showMoreOptionsSelector = null;
      this.editedMessage = null;
    },
    closeReplyMessage() {
      this.repliedMessage = null;
      this.closeReactionsOptionsSelectors();
    },
    closeEditMessage() {
      this.messageInput = "";
      this.editedMessage = null;
      this.closeReactionsOptionsSelectors();
    },
    async sendReplyMessage() {
      if (!this.actionBarActive) return;
      this.showEmojiSelector = false;
      if (this.messageInput.trim() === "") return;
      const params = {
        ticketCustomerId: this.customerTicketChat.id,
        message: this.messageInput,
        creatorId: this.getUserId,
        creatorName: this.getUserName,
        creatorImage: this.getParticipantPhoto,
        ticketTitle: this.customerTicketChat.title,
        session: this.getCurrentSesion,
        typeMessage: this.taggeds.length > 0 ? 8 : 1,
        isAddingNote: this.isAddingNote,
        customerTicketChat: this.customerTicketChat,
        fileType: this.fileType,
        repliedMessageId: this.repliedMessage.chatTicketMessageId,
        repliedMessage: {
          chatTicketMessageId: this.repliedMessage.chatTicketMessageId,
          creatorName: this.repliedMessage.creatorName,
          message: this.repliedMessage.message,
          typeMessage: this.repliedMessage.typeMessage,
          is_note: this.repliedMessage.is_note,
        },
        taggeds: JSON.stringify(this.taggeds),
      };
      this.repliedMessage = null;
      const newMessageIndex = this.messages.length;
      const params2 = params;
      params2.repliedMessage = JSON.stringify([params2.repliedMessage]);
      this.messages.push(params2);
      this.scrollToBottom();

      this.clearMessage();
      const data = await TicketService.sendMessage(params);
      data.data.data.repliedMessage = params2.repliedMessage;
      this.messages[newMessageIndex] = data.data.data;
      this.$forceUpdate(`message-${newMessageIndex}`);
      this.taggeds = [];
    },

    validateEditMessage(message) {
      try {
        const now = this.actualDbTime;
        // Convierte la fecha del mensaje a objeto Date
        const messageTime = new Date(message.createdAt);

        // Calcula la diferencia en milisegundos
        const difference = now - messageTime;

        // Define la duración de media hora en milisegundos (30 minutos * 60 segundos * 1000 milisegundos)
        const halfHour = 30 * 60 * 1000;

        // Compara si la diferencia es menor que media hora
        return difference < halfHour;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async sendMessageHandler() {
      if (this.repliedMessage) {
        await this.sendReplyMessage();
        return;
      }
      if (this.editedMessage) {
        await this.sendEditedMessage();
        return;
      }
      await this.sendMessage();
      this.isAddingNote = false;
      this.noteId = null;
    },

    routeModule(module) {
      const routes = {
        6: "creditexperts",
        21: "creditexperts",
        22: "cedigital/customerservice",
        23: "cedigital/financial",
        26: "cedigital/sales",
        20: "cedigital/connection",
        4: "administration",
        2: "crm",
        3: "bussiness/leads",
        7: "boostcredit",
        18: "quality",
        8: "taxresearch",
        11: "specialists",
        25: "specialistdigital",
        12: "ti", // paragon
        17: "rrhh",
        5: "debtsolution",
        10: "claimdepartment", // court info
        1: "debtsolution",
      };
      return routes[module];
    },
    closeReactionsOptionsSelectors() {
      const moreOptionsSelectors = document.getElementsByClassName(
        "more-options-selector-container"
      );
      for (let i = 0; i < moreOptionsSelectors.length; i++) {
        const element = moreOptionsSelectors[i];
        element.classList.add("d-none");
        element.classList.remove("d-flex");
      }
      const reactionsContainers = document.getElementsByClassName(
        "reactions-selector-container"
      );
      for (let i = 0; i < reactionsContainers.length; i++) {
        const element = reactionsContainers[i];

        element.classList.add("d-none");
        element.classList.remove("d-flex");
      }
      this.reactionsDetailsView.show = null;
      this.reactionsDetailsView.data = null;
      this.checkisTag = false;
    },
    showMenuReactionSelector(messageId) {
      this.showReactionSelector =
        this.showReactionSelector == messageId ? null : messageId;

      if (this.showReactionSelector) {
        this.closeReactionsOptionsSelectors();
        const reactionSelector = document.getElementById(
          `reaction-selector-${messageId}`
        );
        reactionSelector.classList.remove("d-none");

        reactionSelector.classList.add("d-flex");
        reactionSelector.animate(
          [{ transform: "scale(0)" }, { transform: "scale(1)" }],
          {
            duration: 300,
            easing: "ease-in-out",
          }
        );
      } else {
        this.closeReactionsOptionsSelectors();
        const reactionSelector = document.getElementById(
          `reaction-selector-${messageId}`
        );
        reactionSelector.classList.add("d-none");
        reactionSelector.classList.remove("d-flex");
        reactionSelector.animate(
          [{ transform: "scale(1)" }, { transform: "scale(0)" }],
          {
            duration: 300,
            easing: "ease-in-out",
          }
        );
      }
      this.showMoreOptionsSelector = false;
    },
    async showMessageReactions(messageId, messageReaction) {
      try {
        const params = {
          messageId,
          reactionId: messageReaction.reactionId,
        };
        this.closeReactionsOptionsSelectors();
        const data = await TicketService.getMessageReactionsDetail(params);
        this.reactionsDetailsView.show = messageId;
        this.reactionsDetailsView.data = data.data;
      } catch (error) {
        console.error(error);
      }
    },
    showMenuMoreOptionsSelector(messageId) {
      this.showMoreOptionsSelector =
        this.showMoreOptionsSelector === messageId ? null : messageId;
      this.showReactionSelector = false;
      const moreOptionsSelector = document.getElementById(
        `more-options-selector-${messageId}`
      );
      if (this.showMoreOptionsSelector) {
        this.closeReactionsOptionsSelectors();

        moreOptionsSelector.classList.remove("d-none");
        moreOptionsSelector.classList.add("d-flex");
        moreOptionsSelector.animate(
          [{ transform: "scale(0)" }, { transform: "scale(1)" }],
          {
            duration: 300,
            easing: "ease-in-out",
          }
        );
      } else {
        this.closeReactionsOptionsSelectors();

        moreOptionsSelector.classList.add("d-none");
        moreOptionsSelector.classList.remove("d-flex");
        moreOptionsSelector.animate(
          [{ transform: "scale(1)" }, { transform: "scale(0)" }],
          {
            duration: 300,
            easing: "ease-in-out",
          }
        );
      }
    },

    hideAfterInProgress() {
      return (
        this.customerTicketChat.id_status === 1 ||
        this.customerTicketChat.id_status === 2 ||
        this.customerTicketChat.id_status === 3
      );
    },
    createTagMessageContainer(message, reply) {
      if (!message.taggeds || !message.hasOwnProperty("taggeds"));
      let repliedMessage =
        typeof message.repliedMessage !== "undefined"
          ? JSON.parse(message.repliedMessage)
          : null;
      if (repliedMessage) {
        repliedMessage = repliedMessage[0].message;
      }
      // });
      let originalMessage = message.message;
      if (!message.taggeds) return originalMessage;
      // Datos simulados de taggeds
      const taggeds = JSON.parse(message.taggeds).map(
        (tagged) => tagged.creatorName
      );
      if (message.repliedMessage) {
        if (reply) {
          originalMessage = JSON.parse(message.repliedMessage)[0].message;

          const tagsReply = JSON.parse(message.repliedMessage)[0].message.split(
            "@"
          );
          tagsReply.forEach((tagged) => {
            if (tagged != "") {
              taggeds.push(tagged.trim().replace("@", ""));
            }
          });
        }
      }
      if (
        originalMessage === null ||
        originalMessage === undefined ||
        originalMessage === "" ||
        originalMessage === "null"
      ) {
        return "";
      }
      taggeds.forEach((tagged) => {
        const mentionRegex = new RegExp(`(@${tagged}\\b)`, "g");
        const color = this.isDarkSkin ? "white" : "red";
        originalMessage = originalMessage.replace(
          mentionRegex,
          (match) =>
            `<span class="tag" style="color:${color};font-weight:bold">${match}</span>`
        );
      });
      // En updatedMessage tendrás el mensaje actualizado
      return originalMessage;
      // return tagMessageContainer;
    },
    openModalTicketSummary() {
      this.showModalTicketSummary = true;
    },
    closeAddTask() {
      this.paramsAddTask = {};
      this.showModalAddTask = false;
    },
    addTask({ code, id }) {
      this.showModalAddTask = true;
      this.paramsAddTask = {
        code,
        id,
      };
    },
    successTask() {
      this.paramsAddTask = {};
      this.showModalAddTask = false;
    },
    async viewListTasks({ code, id }) {
      this.paramsListTaks = {
        code,
        id,
      };
      this.showModalListTaks = true;
    },
    closeListTasks() {
      this.paramsListTaks = {};
      this.showModalListTaks = false;
    },
  },
};
</script>
  <style scoped lang="scss">
$white-color: white;
$black: #212124;
$black-2: #3a3a3a;
$black-3: #4a4a4a;
$black-light: #34495e;
$light-primary: #3270ff;
@mixin theme($color: $white-color, $font-size: 16px) {
  font-size: $font-size;
  color: $color;
}
@mixin section(
  $bcolor: $white-color,
  $padding: 2.5em 1.5em,
  $border-radius: 20px,
  $opacity: 0.9
) {
  background-color: $bcolor;
  padding: $padding;
  border-radius: $border-radius;
  opacity: $opacity;
}
.chat-sidebar {
  max-height: 85vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  .ticket-information,
  .ticket-participant {
    border-radius: 5px !important;
    padding: 1rem;
    .ticket-information-container {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;

      .ticket-information-item {
        display: flex;
        gap: 1rem;
        padding: 0 0rem 0 0;
      }
    }
  }
}

.chat-container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  height: 85vh;
  max-height: auto;
  min-height: 600px;

  width: 100%;
  position: relative;
  .chat-search-bar,
  .chat-search-bar-dark {
    z-index: 3;
    padding: 3em 10px 20px 10px;
    position: absolute;
    right: 0;
    // top: 6em;
    height: 100%;
    width: 40%;
    min-width: 300px;
    transition: 0.3s ease-in-out;
    animation: slideInFromRight 0.3s ease-in-out;
    .chat-search-counter {
      margin-top: 3em;
      padding: 0.5em 1em;
    }
    .search-container {
      padding-right: 2em;
    }
    @media (max-width: 1199px) {
      .chat-search-counter {
        margin-top: 8em;
        padding: 0.5em 1em;
      }
    }
  }
  .chat-search-bar {
    background-color: #82868b;
  }
  .chat-search-bar-dark {
    background-color: #82868b;
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  #arrow-down {
    position: absolute;
    bottom: 10em;
    width: auto;
    height: auto;
    border-radius: 50%;
    padding: 1em;
    right: 5em;
    z-index: 5;
    transition: 0.5s ease-in-out;
    background-color: #0090e7;
    &:hover {
      background-color: #0072b1;
    }
  }
  .messages-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100% !important;
    padding: 1em 0;
    row-gap: 2em;
    position: relative;

    .message-item {
      background-color: $light-primary;
      width: auto;
      position: relative;
      max-width: 70vh;
      min-width: 150px;
      padding: 10px 15px;
      height: auto;
      display: flex;
      flex-direction: column;
      span {
        word-break: keep-all !important;
        justify-content: justify;
      }
      .message-text {
        word-break: keep-all !important;
        justify-content: justify;
      }
    }
    .rounded-left {
      border-radius: 16px 16px 16px 16px !important;
    }
    .rounded-right {
      border-radius: 16px 16px 0px 16px !important;
    }
    .message-left {
      width: 100%;
      align-self: flex-start;
      flex-direction: row;
      margin-left: 1em;
    }
    .message-right {
      width: 100%;
      align-self: flex-end;
      margin-right: 1em;
      flex-direction: row-reverse;
    }
    .message-center,
    .message-center-dark {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // background-color: #d0f0ff;
    }
    .message-center .message-notification,
    .message-center-dark .message-notification {
      width: auto;
      padding: 8px 15px;
      border-radius: 50px;
    }
    .message-center .message-notification {
      background-color: #7c59ff;
      color: white;
    }
    .warning-bg {
      background-color: #bb7739;
    }
    .primary-bg {
      background-color: #7c59ff;
      color: white;
    }
    .message-center-dark .message-notification {
      background-color: #82868b;
    }
  }

  .chat-actions-bar {
    z-index: 2;
    width: 100%;
    height: 100px;
    border-radius: 0px 0px 5px 5px;
    background-color: $light-primary;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    position: relative;
    .chat-action-bar-icon {
      margin: 3px 20px;
    }
    .emoji-selector {
      position: absolute;
      top: -20em;
      left: 10em;
    }
  }
  .chat-actions-input {
    width: 70%;
    height: 70%;
    background-color: $white-color;
    border-radius: 3em;
    padding-left: 2em;
    padding-right: 2em;
    outline: none !important;
    border-color: transparent;
    transition: 0.3s ease-in-out;
  }
  .chat-actions-input:focus,
  .chat-actions-input:hover {
    box-shadow: inset 0px 0px 5px rgba(128, 128, 128, 0.356);
  }
}
.reaction-selector-item {
  &:hover {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    animation: jump 0.5s ease-in-out;
  }
  @keyframes jump {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(1.3);
    }
  }
}
.more-options-item {
  border-bottom: 1px solid #e5e7e9;

  &:hover {
    cursor: pointer;
  }
}
.more-options-item.option-dark {
  color: black;
  &:hover {
    font-weight: 600;
  }
}
.more-options-item.option-light {
  &:hover {
    font-weight: 600;
    color: black;
  }
}
.menu-tag-participants {
  max-height: 30%;
  overflow: auto;
  z-index: 2;
  width: 98%;
  padding: 0.8em 1em;
  border-radius: 0.6em;
  margin: 0 auto;
  margin-bottom: 0.5em;
  ul {
    li {
      &:hover {
        cursor: pointer;
        font-weight: 600;
      }
    }
  }
}
</style>
  <style lang="scss" scoped>
/* Estilos del scrollbar */
/* Webkit (Chrome, Safari, Edge) */
.customer-class::-webkit-scrollbar,
.messages-container::-webkit-scrollbar,
.list-scroller::-webkit-scrollbar,
.list-scroller-2::-webkit-scrollbar {
  width: 8px;
  /* Ancho del scrollbar vertical */
  height: 8px;
  /* Alto del scrollbar horizontal */
  transition: 0.5s ease-in-out;
}

/* Estilos del thumb (la parte arrastrable del scrollbar) */
/* Webkit */
.customer-class::-webkit-scrollbar-thumb,
.messages-container::-webkit-scrollbar-thumb {
  background: #54b4f0;
  /* Color del thumb */
  border-radius: 6px;
  /* Borde redondeado del thumb */
  transition-duration: 0.5s ease-in-out;
}

/* Estilos del track (la pista del scrollbar) */
/* Webkit */
.customer-class::-webkit-scrollbar-track,
.messages-container::-webkit-scrollbar-track,
.list-scroller::-webkit-scrollbar-track,
.list-scroller-2::-webkit-scrollbar-track {
  background: transparent;
  /* Color de la pista del scrollbar */
  border-radius: 6px;
  /* Borde redondeado del thumb */
}

.list-scroller:hover::-webkit-scrollbar-thumb {
  background-color: #2ea0e7;
}
.list-scroller-2:hover::-webkit-scrollbar-thumb {
  background-color: #3cdf82;
}
.hover-bg-secondary:hover {
  background-color: #c8c8cc34 !important;
}
.paint {
  transition: 0.3s ease-in-out;
}
.hover-me-link {
  color: #2a85da !important;
  border: 1px solid #2a85da;
  position: relative;
  transition: 0.2s ease-in-out;
  font-size: 16px;
  padding: 5px 8px;
  border-radius: 5px;
}
.hover-me-link:hover {
  background-color: #2a85da !important;
  color: white !important;
}

.hover-me-link-dark {
  color: #30ce30 !important;
  border: 1px solid #30ce30;
  position: relative;
  transition: 0.2s ease-in-out;
  font-size: 16px;
  padding: 5px 8px;
  border-radius: 5px;
}
.hover-me-link-dark:hover {
  background-color: #30ce30 !important;
  color: white !important;
}
.jump {
  animation: jumper 0.5s infinite alternate;
}
@keyframes jumper {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.13);
  }
}
.input-search-message {
  width: 100%;
  animation: grow 0.5s linear;
}
@keyframes grow {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}
.contain-img .placeholder-img {
  display: none !important;
  cursor: pointer;
}
.contain-img:hover .placeholder-img {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #9b9898b6;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex !important;
}
.found-message,
.found-message-dark {
  transition: 0.2s ease-in-out;
  border-top: 0.5px solid white;
  border-bottom: 0.5px solid white;
  cursor: pointer;
}
.found-message:hover {
  background-color: #a9d9f0;
}
.found-message-dark:hover {
  background-color: #707377;
}
.load {
  animation: loading 1s ease-in-out infinite;
}
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.drag-drop-files {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 2px dashed #0090e7;
  border-radius: 5px;
  height: 100%;
  margin-top: 20px;
  backdrop-filter: blur(5px);
  z-index: 2;
  transition: 0.5s ease-in-out;
  padding: 10px;
  overflow: hidden;
}
.drag-drop-files .file {
  padding: 5px;
  border-radius: 5px;
  /* box-shadow: 0px 1px 1px 2px #0073b131; */
  border: 2px solid #0073b131;
  width: auto;
  height: auto;
  min-height: 75px;
  min-width: 75px;
  margin: 10px;
  position: relative;
  display: block;
  /* justify-content: center;
    align-items: center; */
  cursor: pointer;
}
.drag-drop-files .file .important-data {
  font-weight: bolder !important;
  display: flex;
  width: 100%;
  word-break: break-all;
}
.drag-drop-files .file .others-data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.preview-img {
  max-height: 90%;
  max-width: 90%;
  cursor: none;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
}
.more-options-selector-container-light {
  background: #d0f0ff;
}
.more-options-selector-container-dark {
  background: #0090e7;
}
.close-reply-message:hover {
  cursor: pointer;
  color: black;
}
.more-reaction-detail-container-light {
  background: #c2ecf4;
}
.more-reaction-detail-container-dark {
  background: #2e86c1;
}
.is-reply-message {
  content: "";
  height: 100%;
  border-top-left-radius: 7.5px;
  border-bottom-left-radius: 7.5px;
  border-left: 5px solid #53bdeb;
  top: 0px;
  left: 0px;
  position: absolute;
}

@media screen and (max-width: 1500px) {
  .ticket-information-item {
    display: block !important;
  }
}
.message-not-viewed {
  position: absolute;
  padding: 0.5em 1em;
  border-radius: 5px;
}
.message-note {
  font-weight: bold;
}

.btn-chat-ceo {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  font-weight: 600 !important;
}

.bg-ceo-chat {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
}

.customer-chat {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
}

.bg-light-chat {
  background: #f4f4f7 !important;
}

.bg-secondary-chat {
  background: #323232 !important;
}

.message-right {
  a,
  .show-more {
    color: #eef1bf !important;
    font-weight: 600;
  }
  .message-edit,
  .message-text,
  .message-note,
  p {
    color: white !important;
    margin-bottom: 0px !important;
  }
}
</style>
