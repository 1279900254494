import DashboardService from '@/views/specialists/sub-modules/financial-approval/views/dashboard/services/dashboard.services';

export default {
    namespaced: true,
    state: {
        S_DEPARTMENTS: [],
    },
    getters: {
        G_DEPARTMENTS(state){
            return state.S_DEPARTMENTS;
        }
    },
    mutations: {
        M_DEPARTMENTS(state, payload){
            state.S_DEPARTMENTS = payload;
        }
    },
    actions: {
        async A_DEPARTMENTS({ commit }){
            try{
                const { data } = await  DashboardService.getDepartments();
                commit('M_DEPARTMENTS', data);
            }catch(error){
                throw error;
            }
        }
    }
}