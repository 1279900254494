<template>
  <div>
    <div
      :class="`d-flex align-items-center w-100 mt-1 mb-2 justify-content-${
        typeData == 'status' ? 'between' : 'center'
      }`"
    >
      <div
        class="d-flex align-items-center justify-content-center"
        style="gap: 7px"
      >
        <span class="title-alert font-strong text-uppercase">
          {{ title }}
        </span>
      </div>
      <div
        v-if="typeData == 'status'"
        class="bg-close text-secondary cursor-pointer"
        @click="close()"
      >
        <feather-icon icon="XIcon" size="16" variant="dark" />
      </div>
    </div>
    <div class="d-flex align-items-center mt-1">
      <div class="icon-badge mr-1 pending">
        <feather-icon
          icon="ClockIcon"
          size="20"
          class="text-warning"
        ></feather-icon>
      </div>
      <span>
        <span
          class="text-warning text-uppercase font-weight-bolder"
          style="font-size: 15px"
        >
          {{ subtitle }}
        </span>
      </span>
    </div>
    <hr />
    <div class="max-height-500">
      <div class="container">
        <div class="lines">
          <div v-for="(item, index) in timeLine" :key="index">
            <div class="dot">
              <feather-icon
                icon="CheckIcon"
                class="font-weight-bolder text-light"
                size="10"
              />
            </div>
            <div
              v-if="!(typeData == 'error' && index == timeLine.length - 1)"
              class="line"
              :key="`l-${index}`"
            ></div>
          </div>
          <div v-if="typeData !== 'error'" class="dot last"></div>
        </div>

        <div class="timelines">
          <div class="timeline" v-for="(item, index) in timeLine" :key="index">
            <template v-if="item.review">
              <span
                class="title-timeline text-uppercase"
                :class="{ active: timeLine.length - 1 == index }"
              >
                {{
                  `${typeData == "status" ? "TICKET " : ""}${
                    item.title == "In Progress" ? "In Paragon" : item.title
                  }`
                }}
              </span>
            </template>
            <template v-else>
              <span
                class="title-timeline text-uppercase"
                :class="{ active: timeLine.length - 1 == index }"
              >
                {{ `${typeData == "status" ? "TICKET " : ""}${item.title}` }}
              </span>
            </template>
            <span
              class="date-timeline"
              :class="{ active: timeLine.length - 1 == index }"
            >
              {{ item.date | myGlobalDay }}
            </span>
          </div>
          <div v-if="typeData !== 'error'" class="timeline last">
            <span class="title-timeline">END</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subtitle: String,
    timeLine: {
      type: Array,
      default: () => [],
    },
    timelineRef: {
      type: String,
      default: "amg-timeline",
    },
    typeData: {
      type: String,
      default: "status",
    },
  },
  data() {
    return {};
  },
  emmit: ["close"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>


<style lang="scss" scoped>
.container {
  width: 100%;
  display: flex;
  padding: 0px 10px;

  .lines {
    margin-top: 6px;
    margin-right: 10px;

    .dot {
      width: 14px;
      height: 14px;
      background: #15b8a6;
      border-radius: 7px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.last {
        background: transparent;
        border: 1px solid #bccada;
      }
    }

    .line {
      height: 25px;
      width: 1.2px;
      background: #0b8476;
      margin-left: 6px;
      &.last {
        background: #bccada;
      }
    }
  }
}
.timelines {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.timeline {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 7px 0px;
  &.last {
    margin: 0;
    align-items: flex-end;
  }
}

.title-timeline {
  font-size: 14px;
  &.active {
    font-weight: bold;
    color: #0b8476;
  }
}

.date-timeline {
  font-size: 13px;
  &.active {
    font-weight: bold;
    color: #0b8476;
  }
}

.icon-badge {
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &.completed {
    background-color: rgba(40, 199, 111, 0.1);
    color: #ff9f43;
  }
  &.pending {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.reactivated {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.scaled {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
}

.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}

.bg-close {
  color: #ececec;
  .dark {
    background-color: #585858;
  }
}
.grid-flow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
.max-height-500 {
  min-height: 100px;
  max-height: 500px;
  overflow-y: auto;
}
</style>