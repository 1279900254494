import { amgApi } from "@/service/axios";
export default {
  namespaced: true,
  state: {
    S_IsActive: false,
    S_DEAlertInfo: null,
  },
  getters: {
    G_IsActive(state) {
      return state.S_IsActive;
    },
    G_DEAlertInfo(state) {
      return state.S_DEAlertInfo;
    },
  },
  mutations: {
    M_IsActive(state, payload) {
      state.S_IsActive = payload;
    },
    M_DEAlertInfo(state, payload) {
      state.S_DEAlertInfo = payload;
    },
  },
  actions: {
    async A_VIEW_ALERT({ commit }, data) {
      try {
        const response = await amgApi.post(
          "/expenses/recurring-payments/view-alert",
          data
        );
        return response;
      } catch (error) {
        console.log("A_VIEW_ALERT ERROR [ACTION]", error);
        throw error;
      }
    },
  },
};
