
const state = {

}
const mutations = {

}
const actions = {

}
const getters = {

}

export default {
  namespaced:true,
  state,
  mutations,
  actions,
  getters
}
