<template>
  <b-modal
    v-model="onControl"
    :size="!params.readonly && hideAfterInProgress() ? 'xl' : 'md'"
    centered
    @hidden="closeModal"
    title-class="h3 text-white text-uppercase font-weight-bolder p-1 w-100 d-flex text-center"
    modal-class="custom-modal-amg"
    header-close-variant="primary"
  >
    <template #modal-title>
      <feather-icon
        icon="UsersIcon"
        size="18"
        class="mr-1"
        style="margin-top: 0px"
      />
      Participants of ticket
      {{ `${params.code ? `#${params.code}` : ""} - ${params.name_client}` }}
    </template>
    <b-row>
      <b-col
        cols="12"
        :lg="!params.readonly && hideAfterInProgress() ? '6' : '12'"
      >
        <b-card class="shadow-none">
          <b-container
            class="p-1 rounded-lg"
            :style="
              isDarkSkin
                ? 'background-color: #3A393C;'
                : 'background-color: #CEEDFF;'
            "
          >
            <b-row class="px-1 px-lg-3 pb-3">
              <div
                class="w-100 d-flex justify-content-between align-items-center mb-1 border-bottom-primary py-1"
              >
                <h3
                  class="font-weight-bolder text-primary px-1 text-uppercase d-flex justify-content-center align-items-center"
                >
                  Participants
                </h3>
                <b-badge
                  variant="info"
                  class="font-weight-bolder p-1"
                  name="count-participants"
                  style="font-size: 15px"
                >
                  {{ `Total : ${participants.length}` }}
                </b-badge>
              </div>
              <div
                class="w-100 rounded py-1 px-2"
                style="max-height: 500px; overflow-y: auto"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in participants"
                    :key="index"
                    cols="12"
                    xl="6"
                    style="padding: 5px"
                  >
                    <div
                      class="d-flex justify-content-start align-items-center border-primary rounded p-1 bg-primary"
                      style="min-height: 90px"
                    >
                      <b-avatar
                        size="58"
                        :src="item.image"
                        variant="light-primary"
                        class="mr-1"
                      >
                        <feather-icon
                          v-if="!item.image"
                          icon="UserIcon"
                          size="19"
                        />
                      </b-avatar>
                      <div class="d-block w-100 text-white">
                        <strong
                          class="text-capitalize"
                          style="font-size: 15px"
                          >{{ item.user_name }}</strong
                        ><br />
                        <!-- <span class="text-capitalize">{{ item.employee_name }}</span> -->
                        <br />
                        <span>{{
                          getMainRolName(JSON.parse(item.modules))
                        }}</span>
                      </div>
                      <strong
                        v-if="[1, params.created_by_id].includes(item.id_user)"
                        class="text-success"
                      >
                        {{ item.id_user === 1 ? "CEO" : "CREATOR" }}
                      </strong>
                      <div
                        v-else-if="!params.readonly && hideAfterInProgress()"
                        style="min-width: 30px; min-height: 30px"
                      >
                        <feather-icon
                          v-b-tooltip.hover.v-danger
                          v-b-tooltip.hover.bottom
                          title="REMOVE"
                          icon="XCircleIcon"
                          size="25"
                          class="hover-me text-danger cursor-pointer"
                          @click="toggleSelectedEmployee(item)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-row>
            <b-list-group
              :class="
                !participants.length
                  ? 'd-none justify-content-center align-items-center'
                  : 'border rounded'
              "
              class="list-participants d-none"
              style="height: 580px; overflow-y: auto"
            >
              <template v-if="participants.length">
                <b-list-group-item
                  v-for="(item, index) in participants"
                  :key="index"
                  class="d-block"
                  :class="isDarkSkin ? 'bg-transparent' : ''"
                >
                  <div
                    class="d-flex justify-content-between align-items-center rounded-0"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center"
                      style="height: 50px"
                    >
                      <feather-icon icon="UserIcon" size="20" class="mr-2" />
                      <div>
                        <strong class="tex-capitalize">{{
                          item.user_name
                        }}</strong
                        ><br />
                        <span class="tex-capitalize">{{
                          item.employee_name
                        }}</span>
                      </div>
                    </div>
                    <strong
                      v-if="item.id_user === 1"
                      style="font-size: 12px"
                      class="text-success"
                    >
                      CEO
                    </strong>
                    <strong
                      v-else-if="params.created_by_id === item.id_user"
                      style="font-size: 12px"
                      class="text-success"
                    >
                      {{ item.id_user === 1 ? "CEO" : "CREATOR" }}
                    </strong>
                    <feather-icon
                      v-else-if="!params.readonly && hideAfterInProgress()"
                      v-b-tooltip.hover.v-danger
                      v-b-tooltip.hover.bottom
                      title="REMOVE"
                      icon="XIcon"
                      size="18"
                      class="hover-me text-danger cursor-pointer"
                      @click="toggleSelectedEmployee(item)"
                    />
                  </div>
                </b-list-group-item>
              </template>
              <template v-else>
                <b-list-group
                  style="height: 400px; width: 100%; font-size: 20px"
                  class="d-flex justify-content-end align-items-center text-center position-relative"
                >
                  <img
                    src="@/assets/images/icons/no-user-image.png"
                    width="200"
                    height="200"
                    style="filter: grayscale(100); opacity: 0.2"
                  />
                  <strong
                    class="mt-3 text-light text-uppercase"
                    style="font-size: 20px; letter-spacing: 2px"
                    >No participants</strong
                  >
                </b-list-group>
              </template>
            </b-list-group>
          </b-container>
        </b-card>
      </b-col>
      <b-col v-if="!params.readonly && hideAfterInProgress()" cols="12" lg="6">
        <b-card class="shadow-none p-0 m-0">
          <filter-slot
            :filter="filters"
            :filter-principal="filterPrincipal"
            :total-rows="totalRows"
            :paginate="paginate"
            :start-page="startPage"
            :to-page="toPage"
            @reload="refreshTable"
          >
            <b-table
              ref="listTableParticipants"
              slot="table"
              sticky-header="40vh"
              :busy.sync="isBusy"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :items="getUsers"
              :fields="fields"
              :head-row-variant="'grid-custom-tickets-header'"
              :per-page="paginate.perPage"
              :current-page="paginate.currentPage"
              :filter="filters"
              show-empty
              responsive
              no-provider-filtering
              class="position-relative table-new-customization"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading...</strong>
                </div>
              </template>
              <template #head(selected)>
                <div class="d-flex justify-content-center align-items">
                  <b-form-checkbox
                    v-model="allParticipants"
                    :state="allParticipants ? true : null"
                    @change="selectAllParticipants"
                  />
                  <b-badge
                    name="count-participants"
                    :variant="participants.length ? 'primary' : 'secondary'"
                    class="ml-1 jump"
                    style="font-size: 12px"
                  >
                    {{ participants.length }}
                  </b-badge>
                </div>
              </template>
              <template #cell(selected)="{ item }">
                <div class="d-flex justify-content-center">
                  <strong
                    v-if="item.id_user === 1"
                    class="text-success"
                    style="font-size: 12px"
                  >
                    CEO
                  </strong>
                  <strong
                    v-else-if="params.created_by_id === item.id_user"
                    class="text-success"
                    style="font-size: 12px"
                  >
                    CREATOR
                  </strong>
                  <b-form-checkbox
                    v-else
                    v-model="item.selected"
                    :value="true"
                    :unchecked-value="false"
                    name="selected"
                    :disabled="item.id_user === 1"
                    @change="toggleSelectedEmployee(item)"
                  />
                </div>
              </template>
              <template #cell(modules)="{ item, index }">
                <ul class="p-0 m-0 list-unstyled">
                  <li
                    v-for="(module, i) in JSON.parse(item.modules).slice(
                      0,
                      index === indexShowAllModules
                        ? JSON.parse(item.modules).length
                        : limitShowModule
                    )"
                    :key="i"
                  >
                    <feather-icon icon="BookmarkIcon" size="12" />
                    <span
                      :class="
                        (!filter_module &&
                          optionsRoles.includes(module.role_id)) ||
                        filter_module === module.id
                          ? 'font-weight-bolder'
                          : ''
                      "
                      style="margin: 0px 5px"
                      >{{ module.name }}</span
                    >
                    -
                    <feather-icon
                      icon="UserIcon"
                      size="12"
                      style="margin: 0px 5px"
                    />
                    <span
                      :class="
                        (!filter_module &&
                          optionsRoles.includes(module.role_id)) ||
                        filter_module === module.id
                          ? 'font-weight-bolder'
                          : ''
                      "
                      >{{ module.role_name }}</span
                    >
                  </li>
                </ul>
                <b-button
                  v-if="JSON.parse(item.modules).length > limitShowModule"
                  variant="outline-success"
                  size="sm"
                  @click="showAllModules(index)"
                >
                  {{
                    index === indexShowAllModules ? "show less" : "...show all"
                  }}
                </b-button>
              </template>
              <template #cell(employee)="{ item }">
                <strong>{{ item.user_name }}</strong
                ><br />
                <span>{{ item.employee_name }}</span>
              </template>
            </b-table>
          </filter-slot>
        </b-card>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-row>
        <b-col v-if="!params.readonly && hideAfterInProgress()" cols="12">
          <b-button
            variant="custom-amg"
            class="float-right"
            :disabled="!participants.length"
            @click="saveParticipants"
          >
            <feather-icon icon="SaveIcon" />
            Assign
          </b-button>
          <b-button
            variant="danger"
            class="float-right mr-1"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import fields from "@/views/commons/participants-tickets/data/listParticipants.fields";
import ParticipantsServices from "@/views/commons/participants-tickets/services/participants.services";
import helpdeskService from "@/views/commons/components/helpdesk/services/helpdesk.service";
import filters from "@/views/commons/participants-tickets/data/listParticipants.filter";

export default {
  props: {
    params: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      onControl: false,
      sortBy: null,
      sortDesc: false,
      toPage: 0,
      startPage: 0,
      totalRows: 0,
      isBusy: false,
      fields,
      filters,
      items: [],
      participants: [],
      participantsNoModify: [],
      paginate: {
        perPage: 100,
        currentPage: 1,
      },
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "User name or employee name...",
        model: "",
      },
      allParticipants: false,
      indexShowAllModules: null,
      limitShowModule: 3,
      optionsRoles: [1, 2, 17], // CEO, SUPERVISOR AND CHIEF
      creatorAndCEO: [], // CEO and creator
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    filter_module() {
      return this.filters[0].model;
    },
  },
  async created() {
    await this.getParticipantsTicket();
    await this.getModules();
  },
  mounted() {
    this.onControl = true;
  },
  methods: {
    getMainRolName(item) {
      for (let r of item) {
        if (r.main_module === 1) {
          return r.role_name;
        }
      }
      return "";
    },

    refreshTable() {
      this.$refs.listTableParticipants.refresh();
    },
    toggleSelectedEmployee(user) {
      const existIndex = this.participants.findIndex(
        (item) => item.id_user === user.id_user
      );

      if (existIndex === -1) {
        this.participants.push(user);
      } else {
        this.participants.splice(existIndex, 1);
      }

      // we add a animation
      const elementCountParticipants =
        document.getElementsByName("count-participants");
      if (elementCountParticipants.length) {
        elementCountParticipants.forEach((element) => {
          element.classList.add("jump");
        });
      }

      // we traversing the array with all employees
      this.items.forEach((element) => {
        if (element.id_user === user.id_user)
          element.selected = existIndex === -1;
      });
      this.allParticipants = this.participants.length === this.items.length;

      // we quit a animation
      if (elementCountParticipants.length) {
        setTimeout(() => {
          elementCountParticipants.forEach((element) => {
            element.classList.remove("jump");
          });
        }, 500);
      }
    },
    async saveParticipants() {
      try {
        const totalParticipants = this.participants.length;
        const { isConfirmed } = await this.showConfirmSwal(
          "Are you sure ?",
          `Are you sure to add ${totalParticipants} participants ?`
        );
        if (!isConfirmed) return;

        this.addPreloader();
        // we separate the removed participants from the actives participants
        const removeParticipants = this.participantsNoModify.filter(
          (noModify) =>
            !this.participants.some(
              (itemParticipant) => itemParticipant.id_user === noModify.id_user
            )
        );
        const newParticipants = this.participants.filter(
          (item) =>
            !this.participantsNoModify.some(
              (noModify) => noModify.id_user === item.id_user
            )
        );
        const params = {
          ticketCustomerId: this.params.id,
          participantsRemove: removeParticipants,
          participantsActives: this.participants,
          participantsNew: newParticipants, // only notification to new participants
          createdBy: this.currentUser.user_id,
          createdByName: this.currentUser.fullName,
          otherData: this.params,
        };
        const { status } = await ParticipantsServices.updateParticipants(
          params
        );
        if (status === 200) {
          this.showSuccessSwal();
          this.$emit("saved");
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log("error ocurred in saveParticipants: ", error);
      }
    },
    async getUsers() {
      try {
        this.isBusy = true;
        const params = {
          rolesId: JSON.stringify(this.optionsRoles),
          search: this.filterPrincipal.model,
          perpage: this.paginate.perPage,
          npage: this.paginate.currentPage,
          moduleId: this.filters[0].model,
        };
        const { data } = await ParticipantsServices.getUserByRoles(params);
        if (data.data) {
          this.items = data.data.map((item) => ({ ...item, selected: false }));
        }
        this.toPage = data.to || 0;
        this.startPage = data.from || 0;
        this.endPage = data.last_page || 0;
        this.totalRows = data.total || 0;
        this.paginate.currentPage = data.current_page || 0;
        this.paginate.perPage = data.per_page || 0;
        this.isBusy = false;

        // we separate the "id" participants of the all "users"
        const users = this.participants.map((item) => item.id_user);

        // we add the selected = true to the existing participants
        this.items = data.data.map((item) => ({
          ...item,
          selected: users.includes(item.id_user),
        }));
        this.allParticipants = this.participants.length === this.items.length;

        // we separate the CEO and creator (they always have to be in the participants) only when the var "this.creatorAndCEO" is empty
        if (!this.creatorAndCEO.length) {
          this.creatorAndCEO = data.data.filter((item) =>
            [1, this.params.created_by_id].includes(item.id_user)
          );
        }

        return this.items;
      } catch (error) {
        this.isBusy = false;
        this.showErrorSwal();
        console.log("error in getData: ", error);
      }
      return [];
    },
    async getParticipantsTicket() {
      try {
        const params = {
          ticketCustomerId: this.params.id,
        };
        const { data } = await ParticipantsServices.getParticipantsTickect(
          params
        );
        this.participants = data.map((item) => ({ ...item, selected: true }));
        this.participantsNoModify = data.map((item) => ({
          ...item,
          selected: true,
        }));
      } catch (error) {
        this.showErrorSwal();
        console.log("error in getParticipantsTicket: ", error);
      }
    },
    closeModal() {
      this.onControl = false;
      this.$emit("hidden");
    },
    selectAllParticipants() {
      if (this.allParticipants) {
        // we add the users selected
        this.participants = this.items.filter((item) =>
          ![1, this.params.created_by_id].includes(item.id_user)
            ? { ...item, selected: true }
            : null
        );

        // we verify if the CEO and creator are in the participants else we add them
        this.participants = [...this.participants, ...this.creatorAndCEO];
      } else {
        this.participants = this.creatorAndCEO;
      }
      // this.participants = this.allParticipants ? this.items.map(item => ({ ...item, selected: true })) : this.items.filter(item => [1, this.params.created_by_id].includes(item.id_user));
      this.items.forEach((element) => {
        element.selected =
          this.allParticipants &&
          ![1, this.params.created_by_id].includes(element.id_user);
      });
    },
    showAllModules(index) {
      this.indexShowAllModules =
        this.indexShowAllModules !== index ? index : null;
    },
    async getModules() {
      const response = await helpdeskService.getModules();
      if (response.status === 200) {
        this.filters[0].options = response.data;
      }
    },
    hideAfterInProgress() {
      return (
        this.params.id_status === 1 ||
        this.params.id_status === 2 ||
        this.params.id_status === 3
      );
    },
  },
};
</script>
<style scoped>
.grid-custom-tickets-header {
  background-color: #3a72ea !important;
  color: white !important;
  font-weight: bold !important;
}

.jump {
  animation: jumper 0.5s ease-in-out;
}

@keyframes jumper {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/* Estilos del scrollbar */
/* Webkit (Chrome, Safari, Edge) */
.list-participants::-webkit-scrollbar {
  width: 8px;
  /* Ancho del scrollbar vertical */
  height: 8px;
  /* Alto del scrollbar horizontal */
}

/* Estilos del thumb (la parte arrastrable del scrollbar) */
/* Webkit */
.list-participants::-webkit-scrollbar-thumb {
  background: #0073e6;
  /* Color del thumb */
  border-radius: 6px;
  /* Borde redondeado del thumb */
  transition-duration: 0.5s ease-in-out;
}

/* Estilos del track (la pista del scrollbar) */
/* Webkit */
.list-participants::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color de la pista del scrollbar */
  border-radius: 6px;
  /* Borde redondeado del thumb */
}
</style>
