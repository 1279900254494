export default [
  {
    path: "payments",
    name: "analyst-department-payments",
    component: () =>
      import(
        /* webpackChunkName: "paymentsSpecialist" */
        "@/views/specialists/views/payments/Payments.vue"
      ),
    meta: {
      pageTitle: "Payments",
      breadcrumb: [
        {
          text: "Payments",
          active: true,
        },
      ],
      route: "specialists",
    },
  },
];
