<template>
  <b-modal
    v-model="showModal"
    size="lg"
    header-bg-variant="success"
    no-close-on-backdrop
    @hidden="closeModal"
  >
    <template #modal-title>
      <h5 class="text-uppercase text-light font-weight-bolder p-0 d-flex">
        Attend meeting of ticket {{ `"#${ticket.code}"` }} of client {{ `"${ticket.name_client}"` }}
      </h5>
    </template>
    <b-container
      fluid
      class="pb-1 pt-2"
    >
      <!--OPTIONS-->
      <b-row>
        <b-col cols="12">
          <b-button-group class="w-100">
            <b-button
              :variant="status===4 ? 'success':'outline-success'"
              class="w-100 text-uppercase"
              @click="status=4"
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="16"
                style="margin-right: 8px;"
              />
              Attended
            </b-button>
            <b-button
              class="w-100 text-uppercase"
              :variant="status===5 ? 'secondary':'outline-secondary'"
              @click="status=5"
            >
              <feather-icon
                icon="UserMinusIcon"
                size="16"
                style="margin-right: 8px;"
              />
              Reprogram
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <!--ATTENDED-->
      <b-row
        v-if="status === 4"
        class="mt-1"
      >
        <b-col cols="12">
          <validation-observer ref="formAttender">
            <validation-provider
              v-slot="{errors}"
            >
              <b-form-group label="Description Note">
                <b-form-textarea
                  id="description"
                  v-model="description"
                  class="text-area-box roboto-class"
                  name="text"
                  cols="40"
                  rows="20"
                  placeholder="Describe your note ... "
                  :state="errors[0]?false:null"
                />
                <b-form-checkbox
                  v-model="addNote"
                  class="mt-2"
                  switch
                >
                  Add Description to Note
                </b-form-checkbox>
              </b-form-group>
            </validation-provider>
          </validation-observer>
        </b-col>
      </b-row>
      <!--NOT ATTENDED-->
      <b-row
        v-if="status === 5"
        class="mt-3"
      >
        <b-col cols="12">
          <h5 class="font-weight-bolder text-uppercase text-primary d-flex text-center justify-content-center align-items-center w-100 text-uppercase pt-2 pb-1 border-top">
            Reprogramm Meeting
          </h5>
          <body-meeting-ticket
            :ticket-customer="ticketCustomerParams"
            :return-hours="true"
            :default-today="false"
            :disabled-previous-month="true"
            @selectedHours="selectedHoursToReprogramm"
          />
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <b-row class="d-flex justify-content-end">
        <b-button
          variant="secondary"
          class="mr-1"
          @click="closeModal"
        >
          CANCEL
        </b-button>
        <b-button
          variant="success"
          @click="saveAttendTicked"
        >
          <feather-icon
            :icon="status===4?'CheckCircleIcon':'UserMinusIcon'"
          />
          {{ status === 4 ? 'ATTENDED' : (!hoursReprogram ? 'REPROGRAM' : 'NOT ATTENDED (REPROGRAMM)') }}
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import CustomerTicketsServices from '@/views/commons/components/customer-tickets/services/customer-tickets.service';
import BodyMeetingTicket from '@/views/commons/components/customer-tickets/components/modal/meetings/BodyMeetingTicket.vue';
import store from '@/store';

export default {
  components: {
    BodyMeetingTicket,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    status: {
      type: Number,
      default: 4,
    }
  },
  data() {
    return {
      showModal: false,
      description: null,
      optionsStatus: [
        { text: 'Attended', value: 4 },
        { text: 'Not Attended', value: 5 },
      ],
      hoursReprogram: null,
      ticketCustomerParams: {
        module_id: this.ticket.module_id,
      },
      addNote: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  watch: {
    status(newVal) {
      this.hoursReprogram = null;
      this.description = null;
    },
  },
  mounted() {
    this.showModal = true;
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    async saveAttendTicked() {
      try {
        // validate only status 4
        if (this.status === 4) {
          const isValid = await this.$refs.formAttender.validate();
          if (!isValid) return;
        }

        // validate only status 5
        let save = false;
        if (this.status === 5 && !this.hoursReprogram) {
          const { isConfirmed } = await this.showConfirmSwal('NO DATE SELECTED', 'Are you sure you want to save the meeting without rescheduling?');
          if (!isConfirmed) return;
          save = true;
        }

        if (!this.validHours()) return;

        if (!save) {
          const { isConfirmed } = await this.showConfirmSwal();
          if (!isConfirmed) return;
        }

        this.addPreloader();

        let newMeeting = null;
        if (this.hoursReprogram) {
          newMeeting = this.hoursReprogram.map(item => ({
            meetingDate: item.meeting_date || null,
            meetingStart: item.range_start || null,
            meetingEnd: item.range_end || null,
            status: 2,
          }));
        }

        let isCorrelative = 0;
        if (!!this.hoursReprogram && this.hoursReprogram.length > 1) {
          isCorrelative = 1;
        }
        const params = {
          ticketCustomerId: this.ticket.id,
          createdBy: this.currentUser.user_id,
          createdByName: this.currentUser.fullName,
          descriptionNote: this.description,
          clientName: this.ticket.name_client,
          ticketCode: this.ticket.code,
          isCorrelative,
          status: this.status,
          newMeeting,
          account: this.ticket.account,
          numberPhone: this.ticket.number_phone,
          isAddingNote: this.addNote,
        };

        const { status } = await CustomerTicketsServices.updateMeetingTicketCustomer(params);
        this.removePreloader();
        if (status === 200) {
          this.showSuccessSwal();
        }
        this.$emit('success');
        await store.dispatch('NotificationStore/A_MEETINGS_PENDING_CUSTOMER_TICKETS');
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log('error in saveAttendTicked: ', error);
      }
    },
    selectedHoursToReprogramm(hours) {
      this.hoursReprogram = hours.length > 0 ? hours : null;
    },
    validHours() {
      if (!this.hoursReprogram || this.hoursReprogram.length === 1) {
        return true;
      }

      let isValid = true;
      this.hoursReprogram.forEach((item, index) => {
        const beforeHourEnd = index > 0 ? this.hoursReprogram[index - 1].range_end : null;

        if (beforeHourEnd && beforeHourEnd !== item.range_start) {
          isValid = false;
        }
      });

      if (!isValid) {
        this.showWarningSwal('VERY IMPORTANT', 'You cannot select more than one range of hours that are not correlative.');
      }
      return isValid;
    },
  },
};
</script>
