import { amgApi } from "@/service/axios";

class SalesMadeService {


    async sendAutorize(body) {
        try {
            const request = await amgApi.post("/authorize/create-subscription", body);
            return request;
        } catch (error) {
            console.log("Something went wrong on getOwners:", error);
            throw error;
        }
    }
    async sendAutorizeProgram(body) {
        try {
            const request = await amgApi.post("/authorize/create-subscription-program", body);
            return request;
        } catch (error) {
            console.log("Something went wrong on sendAutorizeProgram:", error);
            throw error;
        }
    }

    async insertClientType(body) {
        try {
            const request = await amgApi.post("/sales-made/insert-client-type-administration", body);
            return request;
        } catch (error) {
            console.log("Something went wrong on getOwners:", error);
            throw error;
        }
    }

    async send(body) {
        try {
            const request = await amgApi.post("/sales-made/revision-sale", body);
            return request;
        } catch (error) {
            console.log("Something went wrong on getOwners:", error);
            throw error;
        }
    }

    async observeSale(body) {
        try {
            const request = await amgApi.post("/sales-made/observe-sale", body);
            return request;
        } catch (error) {
            console.log("Something went wrong on getOwners:", error);
            throw error;
        }
    }

    async rejecteSale(body) {
        try {
            const request = await amgApi.post("/sales-made/rejecte-sale", body);
            return request;
        } catch (error) {
            console.log("Something went wrong on getOwners:", error);
            throw error;
        }
    }

    /* TAB OBSERVED SALES */
    async getObservedSales(body) {
        try {
            const data = await amgApi.post("/sales-made/get-observed-sales", body);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getReportRequest(body) {
        try {
            const data = await amgApi.post("/sales-made/get-report-request", body);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async addCreditors(body) {
        try {
            const data = await amgApi.post("/sales-made/debt-solution/add-creditors", body);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async verifyIfhasAnalysis(body) {
        try {
            const data = await amgApi.post("/sales-made/debt-solution/verify-if-has-analysis", body);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async verifyIfExistCR(body) {
        try {
            const data = await amgApi.post("/sales-made/debt-solution/verify-if-exist-cr", body);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async editCreditorData(body){
        try {
            const data = await amgApi.post("/sales-made/debt-solution/edit-creditor-data", body);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async verifyIfSelectAccountsToRemove(body){
        try {
            const data = await amgApi.post("/sales-made/debt-solution/verify-if-select-accounts-to-remove", body);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getAccountsToRemove(body){
        try {
            const data = await amgApi.post("/sales-made/debt-solution/get-accounts-to-remove", body);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getFeeAndTotalAmount(params){
        try {
            const data = await amgApi.post("/sales-made/debt-solution/get-fee-and-total-amount", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async UpdateProgramPaymethAndRetainer(params){
        try {
          const data = await amgApi.post("/sales-made/debt-solution/update-debt-retainer-program-payment",params)
          return data
        } catch (error) {
          console.log("Error in UpdateProgramPaymethAndRetainer")
        }
    }

    async getEventAdditionalData(params){
        try {
            const data = await amgApi.post("/sales-made/debt-solution/get-event-additional-data",params)
            return data
          } catch (error) {
            throw error;
          }
    }

    async validateIfSellIsComplete(params){
        try {
            const data = await amgApi.post("/sales-made/debt-solution/validate-if-sell-is-complete",params)
            return data
        } catch (error) {
            throw error;
        }
    }

    async getDebtsTypes(){
        try {
            const data = await amgApi.get("/sales-made/debt-solution/get-debts-types");
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getNcrToCEModal(params){
        try {
            const data = await amgApi.post("/sales-made/debt-solution/get-ncr-to-ce-modal", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getSettlementsFirstProgram(params){
        try {
            const data = await amgApi.post("/sales-made/get-settlements-first-program", params);
            return data;
        } catch (error) {
            throw error;
        }
    }

    async getDataFirstProgram(params){
        try {
            const data = await amgApi.post("/sales-made/get-data-first-program", params);
            return data;
        } catch (error) {
            throw error;
        }
    }
}

export default new SalesMadeService();