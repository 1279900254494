/*import LeadList from "./lead-module/list/LeadsList.vue";
import LeadSnList from "./lead-module/list/LeadsSnList.vue";
import LeadWPotentialList from "./lead-module/list/LeadsWPotentialList.vue";
*/

export default [
  {
    path: "performance",
    name: "performance-creditexperts",
    component: () => import(/* webpackChunkName: "CreditExpertsPerformance" */ "@/views/commons/components/advisor-performance/Performance.vue"),
    //redirect: { name: "lead-crm-lead-list" },
    meta: {
      pageTitle: "Advisor Performance",
      breadcrumb: [
        {
          text: "Advisor Performance",
        },
      ],
      permittedRoles: [1, 2, 11],
    },
  },

  /*{
        path: "/crm/leads/:id",
        name: "lead-show",
        component: () => import("./lead-module/dashboard/default.vue"),
        meta: {
        route: "crm",
        contentClass: 'leads-app'
        },
    },*/
];
