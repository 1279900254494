import Vue from 'vue';
import store from '@/store';

const CorpMailStatus = () => {
  window.socket.bind('notification-corp-mail-status', async response => {
    const { user_id } = store.state.auth.currentUser;

    if (response.arrUsers.includes(user_id)) {
      Vue.prototype.$amgAlert({
        title: 'Employee\'s corporate email',
        icon: response.status == 'COMPLETED' ? 'CheckCircleIcon' : 'XCircleIcon',
        subtitle: `Status ${response.status}`,
        data: [
          { icon: 'UserIcon', title: 'EMPLOYEE NAME', description: response.userName },

        ],
      });
    }
  });
};

export default CorpMailStatus;
