<template>
  <b-modal
    ref="refModal"
    v-model="modalShow"
    @hide="$emit('close')"
    scrollable
    header-bg-variant="secondary"
    hide-footer
    header-class="p-0 w-100"
    modal-class="modal-preview-pdf-3000"
    body-class="preview-pdf-body"
  >
    <template #modal-header>
      <div
        class="container-header-pdf px-1 d-flex justify-content-between align-items-center w-100"
      >
        <div>{{ pdfName }}</div>
        <feather-icon
          icon="XIcon"
          class="icon-pdf cursor-pointer"
          @click="$emit('close')"
          size="24"
        ></feather-icon>
      </div>
    </template>
    <div v-if="isNotLoading" class="container-body-pdf" ref="container">
      <div v-for="i in numPages" :key="i" class="page" :id="i">
        <v-pdf :id="'page-' + i" :src="src" :page="i"></v-pdf>
        <br v-if="numPages != currentPage" />
      </div>
    </div>
    <b-card v-else class="container-body-pdf">
      <b-skeleton-img></b-skeleton-img>
      <b-skeleton animation="wave" width="85%" class="mt-1"></b-skeleton>
      <b-skeleton animation="wave" width="55%"></b-skeleton>
      <b-skeleton animation="wave" width="70%"></b-skeleton>
    </b-card>
    <div class="container-bottom-pdf">
      <div class="enumerators-body">
        <feather-icon
          :class="currentPage != 1 ? 'icon-pdf' : 'icon-pdf-disabled'"
          icon="ChevronsLeftIcon"
          @click="goPage(1)"
        ></feather-icon>
        <feather-icon
          :class="currentPage != 1 ? 'icon-pdf' : 'icon-pdf-disabled'"
          icon="ChevronLeftIcon"
          @click="goPage(currentPage - 1)"
        ></feather-icon>
        <div class="d-flex align-items-center mx-1 enumerators">
          {{ currentPage }} / {{ numPages }}
        </div>
        <feather-icon
          :class="currentPage != numPages ? 'icon-pdf' : 'icon-pdf-disabled'"
          icon="ChevronRightIcon"
          @click="goPage(currentPage + 1)"
        ></feather-icon>
        <feather-icon
          :class="currentPage != numPages ? 'icon-pdf' : 'icon-pdf-disabled'"
          icon="ChevronsRightIcon"
          @click="goPage(numPages)"
        ></feather-icon>
      </div>
    </div>
  </b-modal>
</template>

<script>
import VPdf from "vue-pdf";

export default {
  components: {
    VPdf,
  },
  props: {
    pdfFile: {
      default: null,
      required: false,
    },
    pdfUrl: {
      type: String,
      default: null,
      required: false,
    },
    pdfName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalShow: true,
      numPages: 0,
      currentPage: 1,
      src: this.pdfUrl ? VPdf.createLoadingTask(this.pdfUrl) : null,
      isNotLoading: false,
    };
  },

  methods: {
    goPage(page) {
      const element = document.getElementById("page-" + page);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    async readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    checkVisibleDiv() {
      this.$nextTick(() => {
        const element = document.querySelector(".preview-pdf-body");
        const container = this.$refs.container;
        const pages = container.querySelectorAll(".page");
        const containerTop = element.getBoundingClientRect().top;
        const containerHeight = element.clientHeight;
        pages.forEach((page) => {
          const pageRect = page.getBoundingClientRect();
          if (
            pageRect.top < (containerTop + containerHeight) / 2 &&
            pageRect.bottom > (containerTop + containerHeight) / 2
          ) {
            if (this.currentPage != page.id) {
              this.currentPage = parseInt(page.id);
            }
          }
        });
      });
    },
  },

  async mounted() {
    this.isNotLoading = false;
    if (!this.src) {
      const selectedFile = this.pdfFile;
      if (selectedFile && selectedFile.type === "application/pdf") {
        try {
          const arrayBuffer = await this.readFileAsArrayBuffer(selectedFile);
          this.src = await VPdf.createLoadingTask({ data: arrayBuffer });
        } catch (error) {
          console.error("Error PDF:", error);
        }
      }
    }

    await this.src.promise.then((pdf) => {
      this.numPages = pdf.numPages;
    });
    this.$nextTick(() => {
      const element = document.querySelector(".preview-pdf-body");
      if (element) {
        element.addEventListener("scroll", this.checkVisibleDiv);
      }
    });
    this.isNotLoading = true;
  },
  beforeDestroy() {
    const element = document.querySelector(".preview-pdf-body");
    if (element) {
      element.removeEventListener("scroll", this.checkVisibleDiv);
    }
  },
};
</script>

<style lang="scss">
.modal-preview-pdf-3000 {
  .modal-body {
    padding: 0;
  }

  .container-header-pdf {
    font-family: "Rubick";
    background-color: #0090e7;
    color: white;
    font-size: 30px;
    padding: 6px;
  }
  .container-body-pdf {
    position: relative;
    z-index: 1;
    overflow: auto;
  }
  .container-bottom-pdf {
    font-family: "Rubick";
    position: sticky;
    bottom: 10px;
    z-index: 10;
    widows: 100%;
    display: flex;
    justify-content: center;

    .enumerators-body {
      display: flex;
      justify-content: center;
      width: 30%;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.6);
      box-shadow: 0 4px 24px 0 rgba(107, 106, 106, 0.1);
      color: rgb(225, 225, 225);

      .icon-pdf {
        height: 25px;
        width: auto;
        cursor: pointer;
      }

      .icon-pdf:hover {
        transition: 0.6s;
        transform: scale(1.5);
      }

      .icon-pdf-disabled {
        height: 25px;
        width: auto;
        color: #a1a1a1;
      }

      .enumerators {
        font-size: 15px;
        font-weight: bolder;
      }
    }
  }
}
</style>