export default [
  {
    path: "other-payments",
    name: "pr-other-payments-management",
    component: () =>
      import(
        "@/views/commons/components/other-payments/view/component/OtherPaymentsTabs.vue"
      ),
    redirect: { name: "pr-other-payments-deposit-zelle-management" },
    meta: {
      pageTitle: "Other Payments",
      breadcrumb: [
        {
          text: "Other Payments",
        },
      ],
      module: 12,
      routeDepositZelle: "pr-other-payments-deposit-zelle-management",
      routeCash: "pr-other-payments-cash-management",
      routeMoneyOrder: "pr-other-payments-money-order-management",
    },
    children: [
      {
        path: "deposit-zelle",
        name: "pr-other-payments-deposit-zelle-management",
        component: () =>
          import(
            "@/views/commons/components/other-payments/view/component/OtherPaymentsSubTabs.vue"
          ),
        redirect: {
          name: "pr-other-payments-deposit-zelle-pending-management",
        },
        meta: {
          status: 1,
          tabTitle: "Deposit / Zelle",
          pageTitle: "Other Payments",
          breadcrumb: [
            {
              text: "Deposit / Zelle",
              active: true,
            },
          ],
          module: 12,
          routePending: "pr-other-payments-deposit-zelle-pending-management",
          routeCompleted:
            "pr-other-payments-deposit-zelle-completed-management",
        },
        children: [
          {
            path: "pending",
            name: "pr-other-payments-deposit-zelle-pending-management",
            component: () =>
              import(
                "@/views/commons/components/other-payments/view/component/OtherPaymentsTable.vue"
              ),
            meta: {
              status: 1,
              tabTitle: "Pending",
              pageTitle: "Other Payments",
              breadcrumb: [
                {
                  text: "Deposit / Zelle > Pending",
                  active: true,
                },
              ],
              module: 12,
            },
          },
          {
            path: "completed",
            component: () =>
              import(
                "@/views/commons/components/other-payments/view/component/OtherPaymentsTable.vue"
              ),
            name: "pr-other-payments-deposit-zelle-completed-management",
            meta: {
              status: 2,
              tabTitle: "Completed",
              pageTitle: "Other Payments",
              breadcrumb: [
                {
                  text: "Deposit / Zelle > Completed",
                  active: true,
                },
              ],
              module: 12,
            },
          },
        ],
      },
      {
        path: "cash",
        name: "pr-other-payments-cash-management",
        component: () =>
          import(
            "@/views/commons/components/other-payments/view/component/OtherPaymentsSubTabs.vue"
          ),
        redirect: { name: "pr-other-payments-cash-pending-management" },
        meta: {
          status: 2,
          tabTitle: "Cash",
          pageTitle: "Other Payments",
          breadcrumb: [
            {
              text: "Cash",
              active: true,
            },
          ],
          module: 12,
          routePending: "pr-other-payments-cash-pending-management",
          routeCompleted: "pr-other-payments-cash-completed-management",
        },
        children: [
          {
            path: "pending",
            component: () =>
              import(
                "@/views/commons/components/other-payments/view/component/OtherPaymentsTable.vue"
              ),
            name: "pr-other-payments-cash-pending-management",
            meta: {
              status: 1,
              tabTitle: "Pending",
              pageTitle: "Other Payments",
              breadcrumb: [
                {
                  text: "Cash > Pending",
                  active: true,
                },
              ],
              module: 12,
            },
          },
          {
            path: "completed",
            component: () =>
              import(
                "@/views/commons/components/other-payments/view/component/OtherPaymentsTable.vue"
              ),
            name: "pr-other-payments-cash-completed-management",
            meta: {
              status: 2,
              tabTitle: "Completed",
              pageTitle: "Other Payments",
              breadcrumb: [
                {
                  text: "Cash > Completed",
                  active: true,
                },
              ],
              module: 12,
            },
          },
        ],
      },
      {
        path: "money-order",
        name: "pr-other-payments-money-order-management",
        component: () =>
          import(
            "@/views/commons/components/other-payments/view/component/OtherPaymentsSubTabs.vue"
          ),
        redirect: { name: "pr-other-payments-money-order-pending-management" },
        meta: {
          status: 3,
          tabTitle: "Money Order / Cashier Check / Check",
          pageTitle: "Other Payments",
          breadcrumb: [
            {
              text: "Money Order / Cashier Check / Check",
              active: true,
            },
          ],
          module: 12,
          routePending: "pr-other-payments-money-order-pending-management",
          routeCompleted: "pr-other-payments-money-order-completed-management",
        },
        children: [
          {
            path: "pending",
            name: "pr-other-payments-money-order-pending-management",
            component: () =>
              import(
                "@/views/commons/components/other-payments/view/component/OtherPaymentsTable.vue"
              ),
            meta: {
              status: 1,
              tabTitle: "Pending",
              pageTitle: "Other Payments",
              breadcrumb: [
                {
                  text: "Money Order > Pending",
                  active: true,
                },
              ],
              module: 12,
            },
          },
          {
            path: "completed",
            name: "pr-other-payments-money-order-completed-management",
            component: () =>
              import(
                "@/views/commons/components/other-payments/view/component/OtherPaymentsTable.vue"
              ),
            meta: {
              status: 2,
              tabTitle: "Completed",
              pageTitle: "Other Payments",
              breadcrumb: [
                {
                  text: "Money Order > Completed",
                  active: true,
                },
              ],
              module: 12,
            },
          },
        ],
      },
    ],
  },
]
