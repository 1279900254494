import { amgApi } from "@/service/axios";

class SettingsService {
  async getSettingsManageCI(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/manage-ci/get-settings-manage-ci",
        params
      );
      return data;
    } catch (error) {
      console.log("Error SettingsService: getSettingsManageCI");
      throw error;
    }
  }
  async saveNewSettingCourtManagement(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/manage-ci/save-new-setting-ci",
        params
      );
      return data;
    } catch (error) {
      console.log("Error SettingsService: saveNewSettingCI");
      throw error;
    }
  }

  async getCourtManagementById(params){
    try{
      const data = await amgApi.post("debt-solution/manage-ci/get-court-management-by-id",params);
      return data;
    }catch(error){
      console.log("Error SettingsService: getCourtManagementById");
      throw error;
    }
  }

  async deleteCourtManagement(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/manage-ci/change-state-ci-setting",
        params
      );
      return data;
    } catch (error) {
      console.log("Error SettingsService: deleteCourtManagement");
      throw error;
    }
  }
  async getAttorneyManageCI(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/attorneys-ci/list-ci-attorney",
        params
      );
      return data;
    } catch (error) {
      console.log("Error SettingsService: getAttorneyManageCI");
      throw error;
    }
  }
  async saveNewAttorneyManagement(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/attorneys-ci/create-update-attorney",
        params
      );
      return data;
    } catch (error) {
      console.log("Error SettingsService: saveNewAttorneyManagement");
      throw error;
    }
  }
  async getAttorneyManagementById(params){
    try{
      const data = await amgApi.post(
        "debt-solution/attorneys-ci/get-attorney-management-by-id",params);
      return data;
    }catch(error){
      console.log("Error SettingsService: getAttorneyManagementById");
      throw error;
    }
  }
  async listAttorneys(){
    try{
      const data = await amgApi.post(
        "debt-solution/attorneys-ci/list-attorneys");
      return data;
    }catch(error){
      console.log("Error SettingsService: getAttorneyManagementById");
      throw error;
    }
  }
}

export default new SettingsService();
