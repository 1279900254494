export default [
    {
        path: "review",
        name: "calls-review",
        redirect: { name: "calls-review-pending" },
        component: () =>
            import(/* webpackChunkName: "CallReviewMain" */ "@/views/quality/views/calls-review/CallReviewMain.vue"),
        meta: {
            pageTitle: "Calls Review",
            breadcrumb: [
                {
                    text: "Calls Review",
                },
            ],
        },

        children: [
            {
                path: "pending",
                name: "calls-review-pending",
                component: () =>
                    import(
                        /* webpackChunkName: "call-review" */
                        "@/views/quality/views/calls-review/components/CallReview.vue"
                    ),
                    //  import(
                    //     /* webpackChunkName: "call-review" */
                    //     "@/views/quality/views/calls-review/components/DashboardCallsReview.vue"
                    // ),
                meta: {
                    pageTitle: "Calls Review",
                    breadcrumb: [
                        {
                            text: "Pending",
                            active: true,
                        },
                    ],
                    status: 1,
                },
            }, {
                path: "done",
                name: "calls-review-done",
                component: () =>
                    import(
                        /* webpackChunkName: "call-review" */
                        "@/views/quality/views/calls-review/components/CallReview.vue"
                    ),
                meta: {
                    pageTitle: "Calls Review",
                    breadcrumb: [
                        {
                            text: "Done",
                            active: true,
                        },
                    ],
                    status: 2,
                },
            },
        ]
    },
]