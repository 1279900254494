<template>
  <div>
    <ValidationObserver ref="form">
      <b-modal
        ref="myModal"
        v-model="ownControl"
        modal-class="modal-primary"
        modal
        size="lg"
        scrollable
        header-class="p-0"
        header-bg-variant="transparent border-bottom border-bottom-2"
        @hidden="hideModal(false, 0)"
      >
        <!-- HEADER START -->
        <template v-slot:modal-header>
          <modal-service-header
            :type-modal="typeModal"
            :users-services="usersServices"
            :programs-all="programsAll"
            :header-s="headerS"
            :sales="salesClient"
            :two-per-row="true"
            @changeProgram="changeProgram"
            @close="hideModal(false, 0)"
          />
        </template>
        <!-- HEADER END -->
        <!-- BODY START -->
        <b-container>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Select a payment type"
                v-slot="{ ariaDescribedby }"
              >
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <b-form-radio-group
                    id="radio-group-1"
                    v-model="selected"
                    :disabled="isModalShow"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    :class="{ 'border-danger': errors[0] }"
                    name="radio-options"
                  ></b-form-radio-group>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col cols="4" offset="4">
              <ValidationProvider v-slot="{ errors }" rules="required">
                <b-card
                  :header="`${selected === 1 ? 'SINGLE' : 'MONTHLY'} FEE`"
                  header-bg-variant="info"
                  header-class="text-white py-1 font-weight-bolder "
                  class="mb-0"
                >
                  <b-row class="mt-1">
                    <b-col
                      cols="2"
                      class="
                        d-flex
                        align-items-center
                        justify-content-center
                        text-success
                        font-medium-5
                      "
                      >$</b-col
                    >
                    <b-col v-if="selected === 1">
                      <b-form-select
                        v-model="fee"
                        :disabled="isModalShow"
                        :options="singleOptions"
                        class="text-center"
                        :class="{ 'border-danger': errors[0] }"
                      />
                    </b-col>
                    <b-col v-else-if="selected === 2">
                      <b-form-select
                        v-model="fee"
                        :disabled="isModalShow"
                        :options="monthlyOptions"
                        class="text-center"
                        :class="{ 'border-danger': errors[0] }"
                      />
                    </b-col>
                    <b-col v-else>
                      <money
                        v-model="fee"
                        v-bind="{
                          decimal: '.',
                          thousands: ',',
                          prefix: '',
                          precision: 2,
                          masked: false,
                        }"
                        class="form-control text-center"
                        :class="{ 'border-danger': errors[0] && validateMoney }"
                        :disabled="isModalShow"
                      />
                    </b-col>
                  </b-row>
                </b-card>
              </ValidationProvider>
            </b-col>
          </b-row>
        </b-container>

        <!-- BODY END -->
        <!--  FOOTER START -->
        <template #modal-footer>
          <!-- total -->
          <b-container fluid>
            <b-row v-if="!isModalShow">
              <b-col
                v-if="!isModalAdd"
                class="d-flex align-items-center justify-content-end"
              >
                <button-cancel class="mr-1" @click="hideModal(false, 0)" />
                <button-save @click="saveRates" />
              </b-col>
              <b-col v-else class="d-flex justify-content-end">
                <b-button
                  v-if="isModalAdd"
                  variant="success"
                  @click="saveRates"
                >
                  Continue
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </template>
        <!-- FOOTER END -->
      </b-modal>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import ModalServiceHeader from "@/views/crm/views/sales-made/components/modals/services/ModalServiceHeader.vue";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";

export default {
  components: {
    ButtonSave,
    ModalServiceHeader,
    ButtonCancel,
  },
  props: {
    // modalServices: {
    //   type: Boolean,
    //   required: true,
    // },
    salesClient: {
      type: Object,
      default: () => ({
        event_id: "",
        account_id: "",
        id: "",
        lead_id: "",
      }),
    },
    typeModal: {
      type: Number,
      default: 1,
      // 1: complete rates crm, 2: detail of sale, 3: add Services
      // 4: change Services, 5 show add change Services, 6  add  services programs
    },
    usersServices: {
      type: Array,
      default: () => [],
    },
    programsAll: {
      type: Array,
      default: () => [],
    },
    headerS: {
      type: Object,
      default: () => ({ program: "", seller: "", captured: "" }),
    },
  },
  data() {
    return {
      program: 3,
      options: [
        { text: "Single Payment", value: 1 },
        { text: "Monthly Payment", value: 2 },
        { text: "Other Monthly Payment", value: 3 },
      ],
      singleOptions: [374.95, 499.95, 649.95],
      monthlyOptions: [74.99, 99.99, 199.99],
      selected: 1,
      fee: 0,
      lead: null,
      observation: "Services",
      otherspayments: [],
      add_json_ce: [],
      validateMoney: false,
      score_id: null,
      json_ce: null,
      ownControl: false,
      totalSuggeste: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isModalShow() {
      return this.typeModal == 2 || this.typeModal == 5;
    },
    isModalAdd() {
      return this.typeModal == 3 || this.typeModal == 4 || this.typeModal == 6;
    },
  },
  async mounted() {
    this.addPreloader();
    if (this.program) {
      await this.showRates();
    }
    if (this.isModalAdd) {
      await this.getScore();
    }
    this.ownControl = true;
    this.removePreloader();
  },
  methods: {
    async saveRates() {
      this.validateMoney = true;
      // Validate Money
      try {
        const success = await this.$refs.form.validate();
        if (success) {
          let message = "";
          let route = "";
          let typeADD = "";
          const prices = [];
          // Depends of the Modal type
          switch (this.typeModal) {
            case 1:
              message = "complete Rates";
              route = "/sales-made/attendend-sale";
              break;
            case 3:
              message = "add new service";
              route = "/commons/attendend-program";
              typeADD = 1;
              break;
            case 4:
              message = "change service";
              route = "/commons/attendend-program";
              typeADD = 2;
              break;
            case 6:
              message = "add new service";
              route = "/sale/insert-lead-attendance";
              break;
            default:
              break;
          }

          const param = {
            prices,
            observation: this.observation,
            contract: 1,
            program: this.program,
            fee: this.fee.toString(),
            suggested: this.totalSuggeste,
            otherpricesp: this.otherspayments,
            event: this.salesClient.event_id,
            json_noce: this.add_json_ce,
            stateid: 0,
            ce_payment_type: this.selected,
            // Diferents to add change Services
            account: this.salesClient.account_id
              ? this.salesClient.account_id
              : "",
            captured:
              typeADD == 1 || typeADD == 2
                ? this.salesClient.captured.id
                : this.headerS.captured.id
                ? this.headerS.captured.id
                : this.salesClient.capture_id,
            seller:
              typeADD == 1 || typeADD == 2
                ? this.salesClient.seller.id
                : this.headerS.seller.id
                ? this.headerS.captured.id
                : this.salesClient.seller_id,
            type: typeADD,
            user_id: this.currentUser.user_id,
            module: this.currentUser.modul_id,
            id_score: this.score_id,
            json_ce: this.json_ce,
          };
          const result = await this.showConfirmSwal(
            `Are you sure you want to ${message}`
          );
          if (result.value) {
            this.addPreloader();
            const response = await amgApi.post(`${route}`, param);
            if (response.status === 200) {
              this.$emit("reload");
              this.hideModal(true, this.program);
              this.removePreloader();
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async showRates() {
      try {
        const response = await amgApi.post(
          "/sales-made/get-details-sales-made",
          { id: this.salesClient.id }
        );
        if (response.status === 200) {
          this.fee = response.data[0].value_payment_ce;
          this.selected = response.data[0].type_payment_ce ?? 1;
          this.removePreloader();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getScore() {
      try {
        const response = await amgApi.post("/attend/get-score-attend", {
          lead_id: this.salesClient.lead_id,
        });
        if (response.status === 200) {
          this.score_id = response.data.score_id;
        }
      } catch (error) {
        console.error(error);
      }
    },
    hideModal(refresh, programSelect) {
      this.$refs['myModal'].hide();
      this.$emit("closeModal", refresh, programSelect);
    },
    changeProgram(headerS) {
      this.$emit("changeProgram", headerS);
    },
  },
};
</script>

<style scoped>
select:disabled,
input:disabled {
  background-color: transparent !important;
}
</style>
