import Pusher from "pusher-js"

import {
  closeAllAutoAlerts,
  openAlert,
  showToastAlert,
} from "@/views/ce-digital/sub-modules/customer-service/views/auto-alerts/auto-alert.socket"

const subscribeAutoAlertSocket = (socket) => {
  try {
    socket.subscribe("channel-auto-alert")
    closeAllAutoAlerts()
    openAlert()
    showToastAlert()
  } catch (error) {
    console.error(error, "error")
  }
}

export default subscribeAutoAlertSocket
