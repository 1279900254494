export default [
  {
    path: "/admin/upload",
    name: "setting-upload",
    component: () => import("@/views/commons/configurations/upload/Upload.vue"),
    meta: {
      pageTitle: "Upload Router",
      breadcrumb: [
        {
          text: "Upload Router",
        },
      ],
    },
  },
];
