export default [
  {
    path: "leads",
    name: "lead-crm",
    component: () => import(/* webpackChunkName: "CrmLead" */ "./Lead.vue"),
    redirect: { name: "lead-crm-lead-list" },
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "CrmLead" */ "./lead-module/list/LeadsList.vue"
          ),
        name: "lead-crm-lead-list",
        meta: {
          route: "crm",
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
              to: "/crm/leads/",
              active: true,
            },
          ],
          contentClass: "leads-app",
        },
      },
      {
        path: "sn",
        component: () =>
          import(
            /* webpackChunkName: "CrmLeadListSn" */ "./lead-module/list/LeadsSnList.vue"
          ),
        name: "lead-crm-lead-sn-list",
        meta: {
          route: "crm",
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads Sn",
              to: "/crm/leads/sn",
              active: true,
            },
          ],
          contentClass: "leads-app",
        },
      },
      {
        path: "w-potential",
        component: () =>
          import(
            /* webpackChunkName: "CrmLeadsWPotentialList" */ "./lead-module/list/LeadsWPotentialList.vue"
          ),
        name: "lead-crm-lead-w-potential-list",
        meta: {
          route: "crm",
          permittedRoles: [1, 2, 17],
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads W Potential",
              to: "/crm/leads/w-potential",
              active: true,
            },
          ],
          contentClass: "leads-app",
        },
      },
      {
        path: 'websites-leads',
        component: () => import(/* webpackChunkName: 'WebsiteLeadsPotential' */ '@/views/crm/views/Lead/lead-module/list/WebsiteLeads.vue'),
        name: 'lead-crm-website-leads',
        meta: {
          route: 'crm',
          permittedRoles: [1, 2, 17],
          pageTitle: 'Leads',
          tab: 4,
          breadcrumb: [
            {
              text: 'Websites',
              to: '/crm/leads/websites-leads',
              active: true,
            },
          ],
          contentClass: 'leads-app',
        },
      },
      {
        path: 'recycle',
        component: () => import(/* webpackChunkName: 'WebsiteLeadsPotential' */ '@/views/crm/views/Lead/lead-module/list/LeadRecycle.vue'),
        name: 'lead-crm-recycle-leads',
        meta: {
          route: 'crm',
          pageTitle: 'Leads',
          connectionStatus: 'CLOSED',
          breadcrumb: [
            {
              text: 'Recycle',
              to: '/crm/leads/recycle',
              active: true,
            },
          ],
          contentClass: 'leads-app',
        },
      },
      {
        path: ":id",
        name: "lead-show",
        component: () =>
          import(
            /* webpackChunkName: "CrmLeadShow" */ "./lead-module/dashboard/default.vue"
          ),
        meta: {
          route: "crm",
          contentClass: "leads-app",
        },
      },
    ],
  },
]
