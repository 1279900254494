import { amgApi } from "@/service/axios";
import store from "@/store";

class AccountsActionsService {
  async getUsersAllPrograms(params) {
    try {
      const data = await amgApi.post("commons/users-all-programs", params);
      return data.data;
    } catch (error) {
      console.log("Error getUsersAllPrograms");
    }
  }
  async getAccountsActions(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/get-accounts-actions",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getAccountsActions");
    }
  }
  async getTrackingAccountsActions(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/get-tracking-accounts-actions",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getTrackingAccountsActions");
    }
  }
  async updateAccountActionId(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/update-account-action-id",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error updateAccountActionId");
    }
  }

  async searchOfferByStatus(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/get-offers-by-status",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error searchOfferByStatus");
    }
  }

  async updateOffer(params) {
    try {
      const data = await amgApi.post("debt-solution/update-offer", params);
      return data;
    } catch (error) {
      console.log("Error searchOfferByStatus");
    }
  }

  async counterOffer(params) {
    try {
      const data = await amgApi.post("debt-solution/get-counter-offer", params);
      const total = data.data;
      store.dispatch(
        "DebtSolutionSettlementStore/A_SET_COUNTER_SETTLEMENTS",
        total
      );
      return total;
    } catch (error) {
      console.log("Error counterOffer");
    }
  }

  async getTransfersComplete(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/transfer-complete/index",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error getTransfersComplete");
    }
  }

  async transferAccepted(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/transfer-complete/update",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error transferAccepted");
    }
  }

  async generatePaymentOrder(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/generate-payment-order",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error generatePaymentOrder");
    }
  }
  async counterMigrateOffers(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/counter-migrate-offers",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error counterMigrateOffers");
    }
  }

  async changePaymentMethod(params) {
    try {
      const data = await amgApi.post(
        "debt-solution/change-payment-method",
        params
      );
      return data.data;
    } catch (error) {
      console.log("Error changePaymentMethod");
    }
  }
}
export default new AccountsActionsService();
