export default [{
  path: '/paragon-dashboard-client/:idClient',
  name: 'paragon-dashboard-general',
  redirect: { name: 'client-information-general' },
  component: () => import(/* webpackChunkName: "PRClientsAccount" */ '@/views/paragon/views/clients/dashboard/views/Dashboard.vue'),
  meta: {
    general: true,
    pageTitle: 'Client',
    breadcrumb: [
      {
        text: 'Personal Information',
        active: true,
      },
    ],
  },
  children: [
    {
      path: 'information-general',
      name: 'client-information-general',
      component: () => import(/* webpackChunkName: "PRClientsAccountInformation" */ '@/views/paragon/views/clients/dashboard/views/components/sections/Information.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'task-notes-general',
      name: 'client-task-notes-general',
      component: () => import(/* webpackChunkName: "PRClientsAccountTaskNotes" */ '@/views/paragon/views/clients/dashboard/views/components/sections/TaskNotes.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'payments-general',
      name: 'client-payments-general',
      component: () => import(/* webpackChunkName: "PRClientsAccountPayments" */'@/views/paragon/views/clients/dashboard/views/components/sections/Payments.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'files-general',
      name: 'client-o-files-general',
      component: () => import(/* webpackChunkName: "PRClientsAccountFiles" */'@/views/paragon/views/clients/dashboard/views/components/sections/Files.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'access-credentials-general',
      name: 'client-access-credentials-general',
      component: () => import(/* webpackChunkName: "PRClientsAccountCredentials" */'@/views/paragon/views/clients/dashboard/views/components/sections/AccessCredential.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'scores-general',
      name: 'client-scores-general',
      component: () => import(/* webpackChunkName: "PRClientsAccountScores" */'@/views/paragon/views/clients/dashboard/views/components/sections/Scores.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Personal Information',
            active: true,
          },
        ],
      },
    },
    {
      path: 'tickets',
      name: 'client-tickets-general',
      component: () => import(/* webpackChunkName: "PRClientsAccountScores" */'@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue'),
      meta: {
        pageTitle: 'Client',
        breadcrumb: [
          {
            text: 'Tickets',
            active: true,
          },
        ],
      },
    },
  ],

}];
