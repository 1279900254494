import RecruitmentProcessMain from '@/views/commons/components/recruitment-process/RecruitmentProcessMain.vue';
import store from '@/store';

const { auth } = store.state;
const userId = !auth.currentUser ? '0' : auth.currentUser.user_id;
export default [
  {
    path: '/logistic/recruitment-process',
    name: 'logistic-recruitment-process',
    component: RecruitmentProcessMain,
    redirect: { name: userId !== 1 ? 'logistic-request-recruitment': 'logistic-request-recruitment-ceo' },

    meta: {
      pageTitle: 'Recruitment Process',
      breadcrumb: [
        {
          text: 'Recruitment Process',
        },
      ],
      permittedRoles: [1, 2, 17],
    },
    children: [
      {
        path: 'request-recruitment',
        component: () => import(
          /* webpackChunkName: "RequestRecruitment" */ '@/views/commons/components/recruitment-process/components/MainGrid.vue'
        ),
        name: 'logistic-request-recruitment',
        meta: {
          pageTitle: 'Recruitment Process',
          breadcrumb: [
            {
              text: 'Request Recruitment',
              active: true,
            },
          ],
          permittedRoles: [1, 2, 17],

          requestRecruitment: 'logistic-request-recruitment',
          approvedRecruitment: 'logistic-approved-recruitment',
          requestRecruitmentCeo: 'logistic-request-recruitment-ceo',
          tab: 1,
        },
      },
      {
        path: 'approved-recruitment',
        component: () => import(
          '@/views/commons/components/recruitment-process/components/ApprovedCeoGrid.vue'
        ),
        name: 'logistic-approved-recruitment',
        meta: {
          pageTitle: 'Recruitment Process',
          breadcrumb: [
            {
              text: 'Approved Recruitment',
              active: true,
            },
          ],
          permittedRoles: [1, 2, 17],

          requestRecruitment: 'logistic-request-recruitment',
          approvedRecruitment: 'logistic-approved-recruitment',
          requestRecruitmentCeo: 'logistic-request-recruitment-ceo',
          tab: 2,
        },
      },
      {
        path: 'request-recruitment-ceo',
        component: () => import(
          '@/views/commons/components/recruitment-process/components/GridRecruitmentCeo.vue'
        ),
        name: 'logistic-request-recruitment-ceo',
        meta: {
          pageTitle: 'Recruitment Process',
          breadcrumb: [
            {
              text: 'Pending Approved',
              active: true,
            },
          ],
          permittedRoles: [1, 2, 17],

          requestRecruitment: 'logistic-request-recruitment',
          approvedRecruitment: 'logistic-approved-recruitment',
          requestRecruitmentCeo: 'logistic-request-recruitment-ceo',
          tab: 3,
        },
      },
    ],
  },
];
