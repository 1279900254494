<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 border-0">
      <filter-slot
        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refListTable'].refresh()"
      >
        <b-table
          slot="table"
          ref="refListTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          sticky-header="68vh"
          small
          sort-icon-left
          show-empty
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :fields="fields"
          :items="myProvider"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :busy.sync="isBusy"
      
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <!-- Column: Name -->
          <template #cell(lead_name)="data">


            <router-link
                :class="[textLink]"
                target="_blank"
                class
                :to="{
                  name: 'financial-leads-lead-show',
                  params: {
                    id: data.item.lead_id,
                  },

                }"
            ><span>{{ data.item.lead_name }}</span>
            </router-link>
            <div
              v-if="data.item.user_responsible_date"
              class="text-warning font-weight-bolder"
            >
              <feather-icon
                style="margin-right: 3px;"
                icon="StarIcon"
              />
              CEO's client
            </div>
          </template>

        <!-- Column: Mobile -->
          <template #cell(mobile)="data">
            <div class="pt-1">
              <small>{{ data.item.mobile }}</small> 
            </div>
          </template>
          <!-- Column: Credit Report -->
          <template #cell(credit_report)="data">
            <div class="pt-1">
              <strong
                :class="`text-${
                  data.item.credit_report == 1 ? 'danger' : 'success'
                }`"
                >{{ data.item.credit_report == 1 ? "NO" : "YES" }}</strong
              >
            </div>
          </template>
          <!-- Column: Created By -->
          <template #cell(created_at)="data">
            <div class="pt-1">
              <small>{{ data.item.created_at | myDate }}</small>
            </div>
          </template>
            <!-- Column: Tracking -->
            <template #cell(tracking)="data">
            <div class="text-center">
              <b-button variant="outline" @click="openModalTracking(data.item)">
                <feather-icon
                  class="text-primary"
                  v-b-tooltip.hover.right="'Tracking'"
                  icon="ListIcon"
                />
              </b-button>
            </div>
          </template>

          <template #cell(client_ceo)="data">
            <span class="text-warning">
              <template
                v-if="data.item.user_responsible_date"
                style="margin-right: 5px;"
              >From </template>
              {{ data.item.user_responsible_date | myGlobal }}
            </span>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
    <derivation-tracking-modal
      v-if="derivationTrackingModal"
      :derivation-data="derivationData"
      @closeModal="derivationTrackingModal = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dataFilters from "./dataFilters.data";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import LeadService from "../../service/lead.service";
import ClientService from "@/views/ce-digital/sub-modules/sales/views/clients/service/clients.service";
import DerivationTrackingModal from "@/views/ce-digital/sub-modules/sales/views/derivations/components/modals/DerivationTrackingModal.vue";
export default {
  components: {
    FilterSlot,
    DerivationTrackingModal
  },
  data() {
    return {
      fields: [
  {
    key: "lead_name",
    label: "Name",
    thClass: "text-left",
  },
  {
    key: "mobile",
    thClass: "text-left",
  },
  {
    key: "credit_report",
    label: "CR",
    thClass: "text-left",
  },
  {
    key: "seller_by",
    label: "seller",
    thClass: "text-center",
    tdClass: "text-center pt-2",
  },
  {
    key: "sale",
    thClass: "text-center",
    tdClass: "text-center pt-2",
  },
  {
    label: "Creation Date",
    thClass: "text-center",
    tdClass: "text-center",
    key: "created_at",
   
  },
  {
    key: "tracking",
    thClass: "text-center",
    tdClass: "text-center pt-1",
  },
  {
    key: "client_ceo",
    label: "client of ceo",
    thClass: "text-center",
    tdClass: "text-center pt-1",
  },
],
      filter:  [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "CR",
    model: null,
    options: [
      { id: "0", label: "ALL" },
      { id: "2", label: "YES" },
      { id: "1", label: "NO" },
    ],
    reduce: "id",
    seletText: "label",
    cols: 4,
  },
  {
        type: "select",
        margin: true,
        showLabel: true,
        label: "Seller",
        model: null,
        options: [],
        reduce: "id",
        selectText: "user_name",
        cols: 8,
      },

],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      perPageOptions: [10, 25, 50, 100],
      sortBy: "",
      sortDesc: true,
      rowData: {},
      derivationTrackingModal: false,
      startPage: 0,
      toPage: 0,
      totalRows: 0,
      derivationData:null

    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    moduleId() {
      return this.$route.matched[0].meta.module;
    },

  },
  async created() {
    await this.getSellers();  },
  methods: {
    // Traking 
    openModalTracking(data) {
      this.derivationTrackingModal = true;
      this.derivationData = {lead_id: data.lead_id};
    },
    
      //Seller filter
    async getSellers() {
      try {
   
          const { data, status } = await ClientService.getSellers({
            module_id: 26, //  Sales
            role_id: 5,
          });
          if (status == 200) {

            this.filter[3].options = data;
          }
        
      } catch (error) {
        console.log(error);
      }
    },

// info grid
    async myProvider() {
      try {
        const resp = await LeadService.getLeadsSales({
          name_text: this.filterPrincipal.model,
          lead_status: null,
          date_from: this.filter[0].model,
          date_to: this.filter[1].model,
          cr: this.filter[2].model,
          user_seller: this.filter[3].model,
          orderby: 8,
          order: "desc",
          module_id: this.moduleId,
          userid: this.currentUser.user_id,
          roleid: this.currentUser.role_id,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
        });
        this.startPage = resp.from;
        this.toPage = resp.to;
        this.totalRows = resp.total;
        this.paginate.currentPage = resp.current_page;
        this.paginate.perPage = resp.per_page;
        return  resp.data || [];
      } catch (error) {
        console.log("Something went wrong myProvider", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },

  },

};
</script>

<style lang="scss" scoped>
.table-responsive > div {
  min-height: 15rem;
}
</style>
