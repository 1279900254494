export default {
    path: "dashboard",
    name: "accounts-resolution-dashboard",
    component: () =>
        import(
        /* webpackChunkName: "ConnectionDashboard" */ "@/views/specialists/sub-modules/accounts-resolution/views/dashboard/component/Dashboard.vue"
        ),
    meta: {
        pageTitle: "Dashboard",
        parentModule: 11,
        module: 28,
        moduleId: 28,
        breadcrumb: [
            {
                text: "Dashboard",
                active: true,
            },
        ],
    },
};
