import CeSOfficeHoursNavigation from '@/views/ce-digital/sub-modules/settings/views/office-hours/office-hours.nav'
import CeSNotesNavigation from '@/views/ce-digital/sub-modules/settings/views/notes/notes.nav'
import CeCommissionsNavigation from '@/views/ce-digital/sub-modules/settings/views/commissions/ce-set-commissions-nav'
import CeUsersNavigation from '@/views/ce-digital/sub-modules/settings/views/users/navigation/users.navigation'
import CeMigrationsNavigation from '@/views/ce-digital/sub-modules/settings/views/migrations/navigation/migrations.navigation'
import CeMessagesNavigation from '@/views/ce-digital/sub-modules/settings/views/messages/ce-set-messages-nav'
import CePermissionsNav from '@/views/ce-digital/sub-modules/settings/views/permissions/ce-permissions-nav'
import UsersRelationshipNavigation from '@/views/ce-digital/sub-modules/settings/views/users-relationship/navigation'
import CeMotivesNavigation from '@/views/ce-digital/sub-modules/settings/views/motives/motives.nav.js'
import CeSetPricesNav from '@/views/ce-digital/sub-modules/settings/views/prices/ce-set-prices-nav.js'
//import SettingsProjectsNavigation from '@/views/ce-digital/sub-modules/settings/views/projects/settings-projects.navigation';
import MOFNavigation from '@/views/ce-digital/sub-modules/settings/views/manual-of-functions/manual-of-functions.nav.js';

// data
import sidebarItems from "@/views/credit-experts/data/sidebarItems";

const navigation = [
  {
    header: 'SETTINGS',
    module: 24,
    modules: sidebarItems,
  },
  ...CeUsersNavigation,
  ...CeSOfficeHoursNavigation,
  ...CeSNotesNavigation,
  ...CeCommissionsNavigation,
  ...CeSetPricesNav,
  ...CeMessagesNavigation,
  CeMigrationsNavigation,
  CePermissionsNav,
  ...UsersRelationshipNavigation,
  ...CeMotivesNavigation,
  //SettingsProjectsNavigation,
  ...MOFNavigation,
]
export default navigation
