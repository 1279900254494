import { amgApi } from "@/service/axios";

class SalesMadeCeDigitalService {
  async getAddChangeCounterForCeDigital() {
    try {
      const { data } = await amgApi.get('/credit-experts-digital/sales/get-add-change-counter-for-ce-digital');
      return data;
    } catch (error) {
      console.log("Something went wrong on getAddChangeCounterForCeDigital:", error);
      throw error;
    }
  }
  async getNewClientsCounterForCeDigital() {
    try {
      const { data } = await amgApi.get('/credit-experts-digital/sales/get-new-clients-counter-for-ce-digital');
      return data;
    } catch (error) {
      console.log("Something went wrong on getAddChangeCounterForCeDigital:", error);
      throw error;
    }
  }
}

export default new SalesMadeCeDigitalService();
