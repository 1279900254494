/* eslint-disable no-useless-catch */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { amgApi } from "@/service/axios";

class CustomerTicketsService {
  async getCustomerTickets(body) {
    try {
      return await amgApi.post("customer-tickets/get-customer-tickets", body);
    } catch (error) {
      console.log("Something went wrong on Customer tickets:", error);
      throw error;
    }
  }

  async getCustomerByModule(body) {
    try {
      return await amgApi.post(
        "/customer-tickets/get-customer-by-module",
        body
      );
    } catch (error) {
      console.log("Something went wrong on Customer tickets:", error);
      throw error;
    }
  }

  async insertCustomerTicket(body) {
    try {
      return await amgApi.post(
        "/customer-tickets/insert-customer-ticket",
        body
      );
    } catch (error) {
      console.log("Something went wrong on Customer tickets:", error);
      throw error;
    }
  }

  async getFilesSaved(body) {
    try {
      return await amgApi.post("/customer-tickets/get-files-saved", body);
    } catch (error) {
      console.log("Something went wrong on Customer tickets:", error);
      throw error;
    }
  }

  async closeTicket(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/close-ticket",
        body
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async refreshTicketCustomerPayments(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/refresh-ticket-customer-payments",
        body
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async ChangeOpenStatusTicket(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/change-open-status-ticket",
        body
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async AddNewMotiveCustomerTicket(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/add-new-motive-customer",
        body
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async GetMotiveCustomerTicket(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-motive-customer-ticket",
        body
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getMessagesPending() {
    try {
      const { data, status } = await amgApi.get(
        "/customer-tickets/get-messages-pending"
      );
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getUserByRoles(body) {
    try {
      const data = await amgApi.post(
        "customer-tickets/get-user-by-roles",
        body
      );
      return data;
    } catch (error) {
      console.log("error in services getUserByRoles: ", error);
    }
  }

  async getParticipantsTickect(body) {
    try {
      const data = await amgApi.post(
        "customer-tickets/get-participants-ticket-customer",
        body
      );
      return data;
    } catch (error) {
      console.log("error in services getParticipantsTicket: ", error);
    }
  }

  async getCountPendingCustomerTicketsByModule(ctx) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-count-pending-customer-tickets-by-module",
        ctx
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async listStatussesPerUser(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/list-statusses-per-user",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getMeetingsHours(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-meetings-hours",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async saveMeetingsHours(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/save-meetings-hours",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async editMeetingsHours(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/edit-meetings-hours",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getStatussesCountersPerUser(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-statusses-counter-per-user",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getMeetingsHoursCeo(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-meetings-hours-ceo",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async insertMeetingTicket(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/insert-meeting-ticket",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getMeetingTicketCustomer(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-meetings-ticket-customer",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getMeetingTrackingTicketCustomer(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-meetings-tracking-ticket-customer",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async cancelMeetingTicketCustomer(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/cancel-meeting-ticket-customer",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async updateMeetingTicketCustomer(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/update-meeting-ticket-customer",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getTicketCustomerMeetingsToAnswer(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-ticket-customer-meetings-to-answer",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getTicketCustomerMeetingByRangeHours(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-ticket-customer-meeting-by-range-hours",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async deletedTicketCustomerMeetings(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/deleted-ticket-customer-meetings",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async setUserResposible(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/set-user-responsible",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async insertTicketCustomerTask(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/insert-ticket-customer-task",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async getMyClientsList(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-my-clients-list",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async getTicketCustomerTasks(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-ticket-customer-tasks",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async deletedTicketCustomerTask(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/cancel-ticket-customer-task",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async updateTicketCustomerTask(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/update-ticket-customer-task",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async getTicketCustomerTaskId(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-ticket-customer-task-by-id",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async getTicketCustomerTaskCalendar(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-ticket-customer-task-calendar",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async insertTicketCustomerSummary(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/insert-ticket-customer-summary",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async getTicketCustomerSummary(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-ticket-customer-summary",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async getTicketCustomerSummaryTracking(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-ticket-customer-summary-tracking",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async getRangeModulesAvailability(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-range-modules-availability",
        body
      );
      return { data, status };
    } catch (error) {
      console.log("error", error);
    }
  }

  async updateRangeModulesAvailability(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/update-range-modules-availability",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getAllUsersAvailability(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-all-users-availability",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async saveAllUsersAvailability(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/save-all-users-availability",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getSaveUsersAvailability(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-save-users-availability",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getTrackingClientOfCeo(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-tracking-client-of-ceo",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getHistoryClientOfCeo(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-history-client-of-ceo",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getPaymentDetailsCustomerTickets(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-payments-details-customer-tickets",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async requestTicketFidelized(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/request-ticket-fidelized",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }

  async getDetailsTicketFidelized(body) {
    try {
      const { data, status } = await amgApi.post(
        "/customer-tickets/get-details-ticket-fidelized",
        body
      );
      return { data, status };
    } catch (error) {
      console.log(error);
    }
  }
}

export default new CustomerTicketsService();
