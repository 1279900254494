import { applicationTabs } from "@/views/commons/components/applications/router/application.tabs";

export default [
  {
    path: "applications",
    name: "financial-approval-applications",
    redirect: { name: "fa-application-pending" },
    component: () => import(/* webpackChunkName: "FinancialApprovalApplications" */ "@/views/commons/components/applications/Applications.vue"),
    meta: {
      router: "fa",
      pageTitle: "Credit Cards",
      breadcrumb: [
        {
          text: "CREDIT CARDS",
          active: true,
        },
      ],
      routePending: "fa-application-pending",
      routeCompleted: "fa-application-completed",
      routeExpired: "fa-application-expired",
      routeSuccessfull: "fa-application-successfull",
      routeUnsuccessful: "fa-application-unsuccessful",
      permittedRoles: [1, 2, 17, 25],
      program: 7,
      module: 29
    },
    // applicationTabs = (abv, permitted_roles = null ,type)
    children: applicationTabs("fa",[1, 2, 17, 25]),
  },
];
