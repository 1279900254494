import DashboardRouter from "@/views/specialists/sub-modules/financial-approval/views/dashboard/router/dashboard.router";
import FinancialApprovalMain from "@/views/specialists/sub-modules/financial-approval/FinancialApprovalMain.vue";
import FinancialApprovalAttendance from "@/views/specialists/sub-modules/financial-approval/views/attendance/financial-approval-attendance.router.js";
import SettingsRouter from "@/views/specialists/sub-modules/financial-approval/views/settings/router/settings.router";
import RequestNcrRouter from "@/views/specialists/sub-modules/financial-approval/views/request-ncr/router/request-ncr.router";
import ClientsRouter from "@/views/specialists/sub-modules/financial-approval/views/clients/router/clients.router";
import LeadsRouter from "@/views/specialists/sub-modules/financial-approval/views/leads/router/leads.router";
import RecomendationRouter from "@/views/specialists/sub-modules/financial-approval/views/recomendations/router/recomendations.router.js";
import ApplicationsRouter from "@/views/specialists/sub-modules/financial-approval/views/applications/applications.router";
import AppointmentRouter from "@/views/specialists/sub-modules/financial-approval/views/appointment/appointment.router";
import OtherServicesRouter from "@/views/specialists/sub-modules/financial-approval/views/other-services/other-services.router.js";
import PotencialRoute from "@/views/specialists/sub-modules/financial-approval/views/potencial-appointment/potencial-appointment.route";
import FileManagerRouter from "@/views/specialists/sub-modules/financial-approval/views/file-manager/file-manager-fa.router.js";
import CommissionsRouter from "@/views/specialists/sub-modules/financial-approval/views/commissions/commissions.router";
import HelpdeskRouter from "@/views/specialists/sub-modules/financial-approval/views/support/helpdesk/helpdesk.router";
import InquiriesRouter from "@/views/specialists/sub-modules/financial-approval/views/support/inquiries/inquiries.router";
import ProductRequestRouter from "@/views/specialists/sub-modules/financial-approval/views/support/logistic-request/request.router";
import InconsistentTransactionRouter from "@/views/specialists/sub-modules/financial-approval/views/inconsistent-transactions/router/inconsistent-transactions.router.js";

import PendingPaymentsRouter from "@/views/specialists/sub-modules/financial-approval/views/pending-payments/router/pending-payments.router.js";
import SalesMadeRouter from "@/views/specialists/sub-modules/financial-approval/views/sales-made/sales-made.router.js"

const routes = [
  {
    path: "/specialists/financial-approval",
    redirect: { name: "financial-approval-dashboard" },
    component: FinancialApprovalMain,
    meta: {
      module: 29,
      parentModule: 11,
      program: 7,
    },
    children: [
      DashboardRouter,
      ...FinancialApprovalAttendance,
      ...RequestNcrRouter,
      ...SettingsRouter,
      ...ClientsRouter,
      ...LeadsRouter,
      ...RecomendationRouter,
      ...ApplicationsRouter,
      ...AppointmentRouter,
      ...OtherServicesRouter,
      ...PotencialRoute,
      FileManagerRouter,
      ...CommissionsRouter,
      ...HelpdeskRouter,
      ...InquiriesRouter,
      ...ProductRequestRouter,
      ...InconsistentTransactionRouter,
      ...PendingPaymentsRouter,
      ...SalesMadeRouter
    ],
  },
];

export default routes;
