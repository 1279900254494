export default [
  {
    path: "court-info",
    name: "court-info-quality",
    redirect: { name: "court-info-cases-quality-in-progress" },
    component: () =>
      import(
        /* webpackChunkName: "CourtInfoMain" */
        "@/views/debt-solution/views/court-info/CourtInfoMain.vue"
      ),
    meta: {
      pageTitle: "Cases",
      tabs: [
        {
          name: "PROGRESS",
          route: "court-info-cases-quality-in-progress",
        },
        {
          name: "UPDATE",
          route: "court-info-cases-quality-update",
        },
        {
          name: "DONE",
          route: "court-info-cases-quality-done",
        },
      ],
    },
    children: [
      {
        path: "cases/progress",
        name: "court-info-cases-quality-in-progress",
        component: () =>
          import(
            /* webpackChunkName: "CIGridTableInProcess" */
            "@/views/debt-solution/views/court-info/components/CIGridTable.vue"
          ),
        meta: {
          pageTitle: "Court Info",
          breadcrumb: [
            {
              text: "Cases",
              active: true,
            },
          ],
          tab: 1,
        },
      },
      {
        path: "cases/update",
        name: "court-info-cases-quality-update",
        component: () =>
          import(
            /* webpackChunkName: "CIGridTableInProcess" */
            "@/views/debt-solution/views/court-info/components/CIGridTable.vue"
          ),
        meta: {
          pageTitle: "Court Info",
          breadcrumb: [
            {
              text: "Cases",
              active: true,
            },
          ],
          tab: 3,
        },
      },
      {
        path: "cases/done",
        name: "court-info-cases-quality-done",
        component: () =>
          import(
            /* webpackChunkName: "CIGridTableDone" */
            "@/views/debt-solution/views/court-info/components/CIGridTable.vue"
          ),
        meta: {
          pageTitle: "Court Info",
          breadcrumb: [
            {
              text: "Cases",
              active: true,
            },
          ],
          tab: 2,
        },
      },
    ],
  },
]
