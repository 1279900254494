export default {
  path: "helpdesk",
  name: "helpdesk-connection",
  component: () =>
    import(
      /* webpackChunkName: "ConnectionHelpDesk" */
      "@/views/ce-digital/sub-modules/connection/views/helpdesk/Helpdesk.vue"
    ),
  meta: {
    pageTitle: "Help Desk",
    module: 20,
    permittedRoles: [1, 2, 14, 15, 17],
    breadcrumb: [
      {
        text: "Help Desk",
        active: true,
      },
    ],
    onlyChief: true,
  },
}
