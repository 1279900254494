
export default [
  {
    path: '/managment/paragon-soft',
    name: 'managment-paragon-soft',
    component: () => import(/* webpackChunkName: "ManaPayRoll" */ '@/views/commons/components/paragon-soft/ParagonSoftMain.vue'),
    meta: {
      pageTitle: 'Soft Projects',
      permittedRoles: [1],
    },
  },
];
