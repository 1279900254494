import store from '@/store';
import Notifications from '@/service/notification/index';
import CreativeRequestService from "@/views/commons/components/creative-requests/services/creative.request";
import employeeClaims from '@/views/commons/commons-counter';
import RequestService from '@/views/commons/components/request/services/request.service.js';
import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
      routeName: 'request-creative',
      tag: data[0].counter
  }
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload)
}

const designRequestCounter = async () => {
  const params = {
    modul_id: 27,
    user_id: store.state.auth.currentUser.user_id,
    role_id: store.state.auth.currentUser.role_id,
  };
  const { data } = await CreativeRequestService.getdesignRequestCounterNotifications(params)
    const payload = {
      routeName: "creative-request-options",
      // contador
      tag:  data[0].total,
    }
    store.commit('CreativeDesignStore/SET_COUNTERS', data[0]);
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
  
}

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'creative-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};
const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'creative-recruitment-process',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({ user_id, moduleId });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: 'creative-customer-tickets',
      tag: data[0].v_total_open,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const allCountersCreative = async () => {
  Promise.all([
    projectsCounter(),
    designRequestCounter(),
    employeeClaims('creative-employee-claims'),
    requestNotAsignedCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
  ]);
};

export default allCountersCreative;
