import useExpensesAuth from "./useLogin"

const { isLogged } = useExpensesAuth()

export default {
	path: "expenses",
	name: "expenses-bussiness",
	redirect: { name: "expenses-login-bussiness" },
	component: () => import(/* webpackChunkName: "BusinessExpenses" */ "./index.vue"),
	meta: {
		pageTitle: "Expenses",
		breadcrumb: [
			{
				text: "Expenses",
				active: true,
			},
		],
	},
	children: [
		{
			path: "login",
			name: "expenses-login-bussiness",
			component: () => import(/* webpackChunkName: "BusinessExpensesLogin" */ "./components/login.vue"),
			meta: {
				pageTitle: "Expenses",
				breadcrumb: [
					{
						text: "Login",
						active: true,
					},
				],
			},
			beforeEnter: (to, from, next) => {
				if (isLogged.value) {
					next({ name: "expenses-index-bussiness" })
				}
				next()
			},
		},
		{
			path: "list",
			name: "expenses-index-bussiness",
			component: () => import(/* webpackChunkName: "BusinessExpensesIndex" */ "./components/expenses.vue"),
			meta: {
				pageTitle: "Expenses",
				breadcrumb: [
					{
						text: "List",
						active: true,
					},
				],
				isDeletedTab: 0,
			},
			beforeEnter: (to, from, next) => {
				if (!isLogged.value) {
					next({ name: "expenses-login-bussiness" })
				}
				next()
			},
		},
		{
			path: "deleted",
			name: "expenses-deleted-bussiness",
			component: () => import(/* webpackChunkName: "BusinessExpensesDeleted" */ "./components/deleted.vue"),
			meta: {
				pageTitle: "Expenses",
				breadcrumb: [
					{
						text: "Deleted",
						active: true,
					},
				],
				isDeletedTab: 1,
			},
		},
	],
}
