

export default [
  {
    path: "schedules",
    name: "schedules-claimdepartment",
    redirect: "/claimdepartment/schedules/report",
    component: () => import(/* webpackChunkName: "ClaimDepartamentSchedules" */ "@/views/commons/components/schedules/SchedulesComponent.vue"),
    children: [
      {
        path: "report",
        name: "report-claimdepartment",
        component: () => import(/* webpackChunkName: "ClaimDepartamentSchedulesReport" */ "@/views/commons/components/schedules/SchedulesReport.vue"),
        meta: {
          route: "claimdepartment",
          permittedRoles: [1, 2],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Reports By Module",
              active: true,
            },
          ],
        },
      },
      {
        path: "overtime",
        name: "overtime-claimdepartment",
        component: () => import(/* webpackChunkName: "ClaimDepartamentSchedulesOvertime" */ "@/views/commons/components/schedules/SchedulesOvertime.vue"),
        meta: {
          tag: 1,
          route: "claimdepartment",
          permittedRoles: [1, 2],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Overtime",
              active: true,
            },
          ],
        },
      },
      {
        path: "justifications",
        name: "justifications-claimdepartment",
        component: () => import(/* webpackChunkName: "ClaimDepartamentSchedulesJustifications" */ "@/views/commons/components/schedules/SchedulesJustifications.vue"),
        meta: {
          tag: 2,
          route: "claimdepartment",
          permittedRoles: [1, 2],
          pageTitle: "Schedules",
          breadcrumb: [
            {
              text: "Justifications",
              active: true,
            },
          ],
        },
      },
    ],
    meta: {
      route: "claimdepartment",
      permittedRoles: [1, 2],
    },
  },
];
