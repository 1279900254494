<template>
  <div class="search-rc-calls-active">
    <validation-observer ref="formSearchCalls">
      <div class="text-center mb-1"></div>
      <b-row>
        <b-col
          cols="12"
          class="mt-2"
          style="padding-left: 0 !important; padding-right: 0 !important"
        >
          <b-table
            ref="selectableTable"
            :items="calls"
            :fields="visibledFields"
            thead-tr-class="thead-class"
            show-empty
          >
            <!-- is empty  slot -->
            <template #empty>
              <div class="text-center">
                <h4>No records found</h4>
              </div>
            </template>
            <template #head(selector)>
              <b-form-checkbox :checked="allSelected" @change="selectAll()">
              </b-form-checkbox>
            </template>
            <template #cell(selector)="data">
              <b-form-checkbox
                v-model="data.item.selected"
                name="selected"
                @change="resetSelecteds()"
              ></b-form-checkbox>
            </template>
            <template #cell(direction)="data">
              <div>{{ data.item.direction }}</div>
            </template>

            <template #cell(start_time)="data">
              <div>{{ data.item.start_time | myGlobalWithHour }}</div>
            </template>
            <!-- <template #cell(status)="data">
              <div>{{ data.item.result }}</div>
            </template> -->

            <!-- <template #cell(duration)="data">
              <div class="w-100 d-flex justify-content-around text-center">
                {{
                  data.item.result != "Disconnected"
                    ? "-"
                    : convertMSeconds(data.item.duration)
                }}
              </div>
            </template> -->
            <template #cell(record_url)="data">
              <div class="w-100 d-flex justify-content-around text-center">
                <div style="position: sticky; top: 0; z-index: 1">
                  <new-wave-surfer-player
                    :url="data.item.record_url"
                    :options="audioOptions"
                    :wave-surfer-id="`wave-surfer-${data.item.id}`"
                    :show-duration="true"
                    :elapsed-time-width="95"
                  />
                </div>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import NewWaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/NewWaveSurferPlayer.vue";
import CallLogService from "@/views/commons/components/ring-central/call-log/service/call-log.service.js";
import RingcentralService from "@/service/commons/ringcentral.service.js";
import Fields from "@/views/commons/components/ring-central/call-log/data/calls-grid.fields.js";
import { modifyVisibility } from "@/helpers/fields-table";
import { mapGetters } from "vuex";

export default {
  components: {
    NewWaveSurferPlayer,
  },
  props: {
    leadId: {
        type: Number,
        required: true,
    },
    selectable: {
        type: Boolean,
        default: false,
    },
    typeSale: {
        type: String,
        required: true,
    },
    eventId: {
      type: Number,
      required: true,
    }
  },

  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    visibledFields() {
      modifyVisibility(this.fields, "selector", this.selectable);
      return this.fields.filter((field) => field.visible);
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  data() {
    return {
      numbers: [],
      calls: [],
      selected: [],
      isCurrentPhone: true,
      searchInProgress: null,
      fields: Fields,
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 2,
        backend: "MediaElement",
        minPxPerSec: 0,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      call_recording_queue_counter: {
        total: 0,
        failed: 0,
        pending: 0,
        completed: 0,
        progress: 0,
      },
      callsSelected: [],
      allSelected: false,
    };
  },
  watch: {
    calls: {
      handler() {
        this.$emit("countCalls", this.calls.length);
      },
    },
  },

  async created() {
    await this.getCalls();
  },

  methods: {
    async getCalls(){
        const params = {
            leadId: this.leadId,
            moduleId: this.currentUser.modul_id,
        }
        try {
          this.addPreloader();
          const { data } = await CallLogService.getCallsByLead(params);
          this.calls = data.map(item => {
            item.selected = !this.selectable;
            return item;
          });
          if(this.typeSale == "leads" && this.calls.length == 0){
            this.$emit("defineDisabledButton", true);
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
    },
    async processCalls() {
      try {
        this.addPreloader();
        let callsSelected = this.calls.filter(item => item.selected);
        let params = {
          calls: callsSelected,
          typeSale: this.typeSale,
          userId: this.currentUser.user_id,
          leadId: this.leadId,
          eventId: this.eventId
        };
        const response = await amgApi.post("/note/note/merge-calls", params);
        if (response.status === 200) {
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Successfull process"
          );
          this.$emit("refreshTable");
          this.$emit("closeModal");
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    selectAll(){
      this.allSelected = !this.allSelected;
      this.calls.forEach(item => item.selected = this.allSelected);
      this.resetSelecteds();
    },
    resetSelecteds(){
      this.callsSelected = this.calls.filter(item => item.selected);
      this.$emit("defineDisabledButton", this.callsSelected.length == 0);
    }
  },
};
</script>

<style lang="scss">
.search-rc-calls-active {
  table {
    tr {
      border-top: rgba(0, 0, 0, 0);
    }
  }

  .thead-class {
    th {
      background-color: #0077e6 !important;
      color: #fff !important;
    }
  }

  .icon-edit-float {
    position: relative;
    right: 5px;
    top: -10px;
    background-color: #0090e7;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    &.active {
      background-color: #ff7b00;
    }
  }

  .call-in-progress {
    border-radius: 5px;
    background-color: #3ffaa01c;
    color: #606060;
  }

  .was-validated .custom-control-input:valid ~ .custom-control-label,
  .custom-control-input.is-valid ~ .custom-control-label {
    color: #606060;
  }
}

.dark-layout {
  .search-rc-calls-active {
    .table.b-table > tbody .b-table-row-selected.table-active td {
      background-color: #3c3c3c;
    }
  }

  .call-in-progress {
    background-color: #2d2e30;
    color: #ffffff;
  }
}

.counter-padding {
  padding: 0.2rem 0.5rem;
}
</style>