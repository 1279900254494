<template>
  <div>
    <header-sidebar
      v-if="showHeader"
      :lead-id="leadId"
      :credential="credential"
    />

    <div class="custom-chat-rc-lead">
      <b-row style="margin: 0 !important" class="h-100">
        <b-col
          :cols="
            activeSearchTextChat || activeChatInfo || activeChatInfoHighlight
              ? '8'
              : '12'
          "
          style="padding: 0 !important; height: inherit"
        >
          <content-header
            v-if="currentChat"
            :current-chat="currentChat"
            @activeSearch="activeMenuRight('search')"
            @activeChatInfo="activeMenuRight('info')"
          />
          <rc-chat-content
            v-if="currentChat"
            class="not-overflow"
            :message-id="messageFind"
            :credential="credential"
            :current-chat="currentChat"
            @filesUploadSize="setFilesSize"
          />
          <rc-chat-welcome
            v-show="!currentChat"
            @chatCreated="$emit('chatCreated')"
            :find-all="false"
            :credential="credential"
            :to-number="leadPhoneNumber"
          />
        </b-col>

        <transition name="slide">
          <b-col
            cols="4"
            style="padding: 0 !important"
            v-if="activeSearchTextChat"
          >
            <div class="header-rc-chat-search-message">
              <div class="container-info">
                <feather-icon
                  icon="XIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="activeMenuRight('search')"
                ></feather-icon>
                <span class="ml-2 font-weight-bolder">Search Message</span>
              </div>
            </div>

            <rc-chat-filter-message
              class="not-overflow"
              v-model="messageFind"
              :credential="credential"
              :current-chat="currentChat"
            />
          </b-col>
        </transition>
        <transition name="slide">
          <b-col cols="4" style="padding: 0 !important" v-if="activeChatInfo">
            <div class="header-rc-chat-search-message">
              <div class="container-info">
                <feather-icon
                  icon="XIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="activeMenuRight('info')"
                ></feather-icon>
                <span class="ml-2 font-weight-bolder">Chat Info</span>
              </div>
            </div>

            <rc-chat-info
              :current-chat="currentChat"
              @openMessageHighlight="activeMenuRight('highlight')"
            />
          </b-col>
        </transition>

        <transition name="slide">
          <b-col
            cols="4"
            style="padding: 0 !important"
            v-if="activeChatInfoHighlight"
          >
            <div class="header-rc-chat-search-message new-border-2">
              <div class="container-info">
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="20"
                  class="cursor-pointer"
                  @click="activeMenuRight('info')"
                ></feather-icon>
                <span class="ml-2 font-weight-bolder">Marked Messages</span>
              </div>
            </div>

            <rc-messages-highlight
              class="not-overflow"
              v-model="messageFind"
              :current-chat="currentChat"
            />
          </b-col>
        </transition>
      </b-row>
    </div>
  </div>
</template>

<script>
import HeaderSidebar from "@/views/commons/components/ring-central/chat-sms/components/RcChatHeaderSidebar.vue";

import ContentHeader from "@/views/commons/components/ring-central/chat-sms/components/RcChatHeaderContent.vue";
import RcChatSidebar from "@/views/commons/components/ring-central/chat-sms/components/RcChatSidebar.vue";
import RcChatContent from "@/views/commons/components/ring-central/chat-sms/components/RcChatContent.vue";
import RcChatFilterMessage from "@/views/commons/components/ring-central/chat-sms/components/RcChatFilterMessageChat.vue";
import RcChatWelcome from "@/views/commons/components/ring-central/chat-sms/components/RcChatWelcome.vue";
import RcChatInfo from "@/views/commons/components/ring-central/chat-sms/components/RcChatInfo.vue";

import { mapMutations, mapGetters, mapActions } from "vuex";
import RcMessagesHighlight from "@/views/commons/components/ring-central/chat-sms/components/RcChatMessagesHigh.vue";

export default {
  props: {
    leadId: {
      type: [String, Number],
    },

    leadPhoneNumber: {
      type: String,
      required: true,
    },

    showHeader: {
      type: Boolean,
      default: true,
    },

    currentChat: {
      type: Object,
    },

    credential: {
      type: Object,
      required: true,
    },
  },

  components: {
    RcChatSidebar,
    RcChatContent,
    RcChatFilterMessage,
    ContentHeader,
    HeaderSidebar,
    RcChatWelcome,
    RcChatInfo,
    RcMessagesHighlight,
  },

  data() {
    return {
      activeSearchTextChat: false,
      activeChatInfo: false,
      messageFind: null,
      filesUpload: 0,
      activeChatInfoHighlight: false,
    };
  },

  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  methods: {
    ...mapMutations({
      HIDE_ICON_SMS: "SmsInboxStore/M_SET_SHOW_ICON",
    }),

    ...mapActions({
      A_GET_USERS_IMAGES: "SmsInboxStore/A_GET_USERS_IMAGES",
    }),

    activeMenuRight(menu) {
      if (this.filesUpload > 0) return;
      switch (menu) {
        case "search":
          this.activeChatInfo = false;
          this.activeChatInfoHighlight = false;
          this.activeSearchTextChat = !this.activeSearchTextChat;
          break;
        case "info":
          this.activeSearchTextChat = false;
          this.activeChatInfoHighlight = false;
          this.activeChatInfo = !this.activeChatInfo;
          break;

        case "highlight":
          this.activeSearchTextChat = false;
          this.activeChatInfo = false;
          this.activeChatInfoHighlight = !this.activeChatInfoHighlight;
          break;
        default:
          break;
      }
    },

    setFilesSize(evt) {
      this.filesUpload = evt;
    },
  },

  watch: {
    // credential(value) {
    //   this.getClientChat(value);
    // },

    filesUpload() {
      this.activeChatInfo = false;
      this.activeChatInfoHighlight = false;
      this.activeSearchTextChat = false;
    },
  },

  mounted() {
    this.HIDE_ICON_SMS(false);
    this.A_GET_USERS_IMAGES(this.moduleId)
  },

  beforeDestroy() {
    this.HIDE_ICON_SMS(true);
  },
};
</script>

<style lang="scss">
.custom-chat-rc-lead {
  height: 80vh !important;
  max-height: 80vh !important;
}
</style>