import documentServiceSettings from "@/views/debt-solution/views/settings/views/documents-config/document-service-type/service/document-service-type.service.js";

export default {
  namespaced: true,
  state: {
    S_DOCUMENT_SERVICE_TYPE_ACTIVES: [],
  },
  getters: {
    G_DOCUMENT_SERVICE_TYPE_ACTIVES(state) {
      return state.S_DOCUMENT_SERVICE_TYPE_ACTIVES;
    },
  },
  mutations: {
    M_DOCUMENT_SERVICE_TYPE_ACTIVES(state, params) {
      state.S_DOCUMENT_SERVICE_TYPE_ACTIVES = params;
    },
  },
  actions: {
    async A_GET_DOCUMENT_SERVICE_TYPE_ACTIVES({ commit }) {
      try {
        const response =
          await documentServiceSettings.getDocumentServiceTypeActives();
        commit("M_DOCUMENT_SERVICE_TYPE_ACTIVES", response);
      } catch (error) {
        throw error;
      }
    },
  },
};
