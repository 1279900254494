<template>
  <b-modal
    v-model="isShow"
    ref="modal-case-tracking"
    :title="getTitle"
    title-tag="h3"
    size="lg"
    modal-class="modal-primary"
    hide-footer
    no-close-on-backdrop
    @hidden="close"
  >
    <b-table
      ref="trackingTable"
      class="blue-scrollbar"
      primary-key="id"
      empty-text="No matching records found"
      responsive="md"
      sticky-header="50vh"
      small
      show-empty
      no-provider-filtering
      :fields="fields"
      :items="myProvider"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(name)="data">
        <b-badge
          :variant="'light-' + getVariant(data.item.current_step_id)"
          class="text-capitalize"
        >
          {{ data.item.name }}
          <feather-icon
            class="ml-25"
            :icon="getIcon(data.item.current_step_id)"
            size="16"
          />
        </b-badge>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
export default {
  props: {
    caseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isShow: true,
      isBusy: false,
      fields: [
        {
          key: "name",
          label: "ACTION",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_by",
          label: "CREATED BY",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "created_at",
          label: "Created AT",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
          formatter: (value) => {
            return `${this.$options.filters.myGlobalWithHour(value)}`;
          },
        },
      ],
      case_number: null,
      steps: [
        {
          id: 1,
          icon: "FilePlusIcon",
          color: "primary",
        },
        {
          id: 2,
          icon: "CheckIcon",
          color: "success",
        },
        {
          id: 3,
          icon: "CalendarIcon",
          color: "warning",
        },
        {
          id: 4,
          icon: "ArchiveIcon",
          color: "primary",
        },
        {
          id: 5,
          icon: "FlagIcon",
          color: "success",
        },
        {
          id: 6,
          icon: "TrelloIcon",
          color: "primary",
        },
        {
          id: 7,
          icon: "ThumbsUpIcon",
          color: "success",
        },
        {
          id: 100,
          icon: "FileTextIcon",
          color: "info",
        },
        {
          id: 101,
          icon: "UploadIcon",
          color: "info",
        },
        {
          id: 102,
          icon: "MailIcon",
          color: "info",
        },
        {
          id: 103,
          icon: "MailIcon",
          color: "warning",
        },
        {
          id: 104,
          icon: "MailIcon",
          color: "warning",
        },
      ],
    };
  },
  computed: {
    getTitle() {
      if (this.case_number) {
        return "TRACKING - CASE #" + this.case_number;
      } else {
        return "TRACKING";
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async myProvider() {
      try {
        this.addPreloader();
        const { data, status } = await CourtInfoService.getCaseTracking({
          caseId: this.caseId,
        });
        if (status == 200) {
          if (data.length > 0) {
            this.case_number = data[0].case_number;
          }
          return data;
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },

    getIcon(step) {
      return this.steps.find((item) => item.id == step).icon;
    },
    getVariant(step) {
      return this.steps.find((item) => item.id == step).color;
    },
  },
};
</script>