<template>
  <div>
    <header-slot :clients-search="true">
      <template #actions>
        <b-row class="w-100 p-0 m-0">
          <b-col
            cols="12"
            class="p-0 mb-2 d-flex justify-content-end"
            style="gap: 1rem"
          >
            <div>
              <b-button
                variant="outline-info"
                class="position-relative"
                @click="showModalTask = true"
              >
                <b-badge
                  v-if="G_TASKS_PENDING_CUSTOMER_TICKETS > 0"
                  variant="danger"
                  style="top: -10px; right: -10px; position: absolute"
                  pill
                >
                  {{
                    G_TASKS_PENDING_CUSTOMER_TICKETS > 99
                      ? "+99"
                      : G_TASKS_PENDING_CUSTOMER_TICKETS
                  }}
                </b-badge>
                <feather-icon icon="ClipboardIcon" size="15" class="mr-50" />
                Tasks
              </b-button>
            </div>
            <div
              v-if="currentUser.modul_id !== 17 && currentUser.modul_id !== 27"
            >
              <b-button
                v-if="$route.meta.tab === 4"
                variant="primary"
                @click="openModalCreateCustomerTicket"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="15"
                  class="mr-50 text-white"
                />New Ticket
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </header-slot>
    <header-indicators
      class="mb-2"
      @updateTicketC="updateTicketCustomer"
      @filterTable="filterTable"
    />
    <div>
      <b-tabs
        v-if="currentTabType == 'customer'"
        v-model="activeTab"
        pills
        content-class="mt-3"
        nav-class="mb-0"
        active-nav-item-class="bg-primary box-shadow-primary border-primary primary"
      >
        <b-tab :key="0" :title-link-class="[bgTabsNavs, 'px-3']">
          <template #title>
            In
            <span
              v-if="G_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT.counter_in > 0"
              class="ml-2"
            >
              <feather-icon
                icon
                :badge="
                  G_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT.counter_in > 99
                    ? '99+'
                    : G_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT.counter_in
                "
                badge-classes="badge-danger badge-glow counter-tabs-customer-tickets-in-out"
              />
            </span>
          </template>
          <grid-customer-tickets
            v-if="activeTab === 0"
            ref="routeView"
            :in-out="1"
            style="margin-top: -3rem"
          />
        </b-tab>
        <b-tab :key="1" :title-link-class="[bgTabsNavs, 'px-3']">
          <template #title>
            Out
            <span
              v-if="G_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT.counter_out > 0"
              class="ml-1"
            >
              <feather-icon
                icon
                :badge="
                  G_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT.counter_out > 99
                    ? '99+'
                    : G_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT.counter_out
                "
                badge-classes="badge-danger badge-glow counter-tabs-customer-tickets-in-out"
              />
            </span>
          </template>
          <grid-customer-tickets
            v-if="activeTab === 1"
            ref="routeView"
            :in-out="2"
            style="margin-top: -3rem"
          />
        </b-tab>
      </b-tabs>
      <div v-else>
        <b-nav class="mt-1" pills>
          <b-nav-item
            :to="{ name: tabRouteInProgress }"
            exact
            exact-active-class="active"
            :link-classes="['px-3 h-full', bgTabsNavs]"
          >
            In progress
            <b-badge
              variant="danger"
              :class="
                G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_in_progress > 0 ||
                'd-none'
              "
              class="rounded-circle"
              :style="'margin-left:10px'"
              >{{
                G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_in_progress > 99
                  ? "+99"
                  : G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_in_progress
              }}</b-badge
            >
          </b-nav-item>
          <b-nav-item
            :to="{ name: tabRouteCompleted }"
            exact
            exact-active-class="active"
            :link-classes="['px-3 h-full', bgTabsNavs]"
          >
            {{ currentTabType === "customer" ? "Resolved" : "Completed" }}
            <b-badge
              variant="danger"
              :class="
                G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_solved > 0 || 'd-none'
              "
              class="rounded-circle"
              :style="'margin-left:10px'"
              >{{
                G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_solved > 99
                  ? "+99"
                  : G_COUNT_PENDING_CUSTOMER_TICKETS.v_total_solved
              }}</b-badge
            >
          </b-nav-item>
        </b-nav>

        <div class="border-top-primary" style="margin-top: -1rem">
          <router-view :key="`${$route.name}`" />
        </div>
      </div>
    </div>

    <modal-create-customer-ticket
      v-if="showModalCreateCustomerTicket"
      @hidden="showModalCreateCustomerTicket = false"
      @saved="refresh"
    />
    <modal-calendar-task
      v-if="showModalTask"
      @closeModal="showModalTask = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import GridCustomerTickets from "@/views/commons/components/customer-tickets/views/GridCustomerTickets.vue";
import ModalCreateCustomerTicket from "@/views/commons/components/customer-tickets/components/modal/ModalCreateCustomerTicket.vue";
import HeaderIndicators from "@/layouts/components/navbar/components/customer-tickets/modals/IndicatorsTickets.vue";
import ModalCalendarTask from "@/views/commons/components/customer-tickets/components/modal/tasks/ModalCalendarTask.vue";

export default {
  components: {
    GridCustomerTickets,
    ModalCreateCustomerTicket,
    HeaderIndicators,
    ModalCalendarTask,
  },

  data() {
    return {
      showModalCreateCustomerTicket: false,
      activeTab: 0,
      showModalTask: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_USER_STATUS_SESSION_SUB_MODULE:
        "UserStore/G_USER_STATUS_SESSION_SUB_MODULE",
      G_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT",
      currentType: "TicketCustomerStore/G_TYPE_TICKET",
      G_TASKS_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/G_TASKS_PENDING_CUSTOMER_TICKETS",
      G_COUNT_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/G_COUNT_PENDING_CUSTOMER_TICKETS",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
    programId() {
      return this.$route.matched[0]?.meta?.program;
    },
    tabRouteCompleted() {
      const path = this.$route.matched[3].parent.meta.type;
      const meta = this.$route.matched[2].meta;

      const routes = {
        customer: meta.routeCustomerCompleted,
        payment: meta.routePaymentCompleted,
        expense: meta.routeExpenseCompleted,
      };

      return routes[path] || null;
    },
    tabRouteInProgress() {
      const path = this.$route.matched[3].parent.meta.type;
      const meta = this.$route.matched[2].meta;
      const routes = {
        customer: meta.routeCustomerInProgress,
        payment: meta.routePaymentInProgress,
        expense: meta.routeExpenseInProgress,
      };
      return routes[path] || null;
    },
    orderMatched() {
      const sizeMatched = {
        5: 3,
        4: 2,
        3: 1,
      };
      return sizeMatched[this.$route.matched.length] || "Unknown";
    },
    currentTabType() {
      return this.$route.matched[this.orderMatched].meta.type;
    },
  },
  async created() {
    await this.A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT({
      user_id: this.currentUser.user_id,
    });
    await this.A_TASKS_PENDING_CUSTOMER_TICKETS({
      user_id: this.currentUser.user_id,
    });
    await this.A_COUNT_PENDING_CUSTOMER_TICKETS({
      user_id: this.currentUser.user_id,
      client_account_id: null,
      type_ticket: this.currentTabType,
    });
    this.SET_CURRENT_VIEW("modalticketcustomer");
  },
  destroyed() {
    this.SET_CURRENT_VIEW(null);
  },
  methods: {
    ...mapMutations({
      SET_CURRENT_VIEW: "TicketCustomerStore/SET_CURRENT_VIEW",
    }),
    ...mapActions({
      A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS_BY_IN_OUT",
      A_COUNT_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/A_COUNT_PENDING_CUSTOMER_TICKETS",
      A_SET_TYPE_TICKET: "TicketCustomerStore/A_SET_TYPE_TICKET",
      A_TASKS_PENDING_CUSTOMER_TICKETS:
        "NotificationStore/A_TASKS_PENDING_CUSTOMER_TICKETS",
    }),
    openModalCreateCustomerTicket() {
      this.showModalCreateCustomerTicket = true;
    },
    refresh() {
      this.showModalCreateCustomerTicket = false;
      this.$refs.routeView.refreshTableReload();
    },
    updateTicketCustomer(state) {
      this.$refs.routeView.updateTicket(state);
    },
    filterTable(type) {
      this.A_SET_TYPE_TICKET(type);
    },
  },
  beforeRouteUpdate(to, from, next) {
    const typeTicket = to.matched[2].meta.type;
    this.filterTable(typeTicket);
    next();
  },
};
</script>
<style >
.counter-tabs-customer-tickets-in-out {
  position: relative;
  /* top: 2px !important; */
  left: 1px !important;
}
</style>
