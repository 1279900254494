import { amgApi } from "@/service/axios";

class DocumentServiceTypeService {
  async getDocumentsServiceType(params) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/document-service-type/get-document-service-type",
        params
      );
      return data;
    } catch (error) {
      console.log("Error DocumentServiceTypeService: getDocumentsServiceType");
      throw error;
    }
  }

  async getDocumentsServiceTypeById(params) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/document-service-type/get-document-service-type-by-id",
        params
      );
      return data;
    } catch (error) {
      console.log(
        "Error DocumentServiceTypeService: getDocumentsServiceTypeById"
      );
      throw error;
    }
  }

  async createDocumentServiceType(params) {
    try {
      return await amgApi.post(
        "/debt-solution/document-service-type/create-document-service-type",
        params
      );
    } catch (error) {
      console.log(
        "Error DocumentServiceTypeService: createDocumentServiceType"
      );
      throw error;
    }
  }

  async updateDocumentServiceType(params) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/document-service-type/update-document-service-type",
        params
      );
      return data;
    } catch (error) {
      console.log(
        "Error DocumentServiceTypeService: updateDocumentServiceType"
      );
      throw error;
    }
  }

  async deleteDocumentServiceType(params) {
    try {
      const { data } = await amgApi.post(
        "/debt-solution/document-service-type/delete-document-service-type",
        params
      );
      return data;
    } catch (error) {
      console.log(
        "Error DocumentServiceTypeService: deleteDocumentServiceType"
      );
      throw error;
    }
  }

  async getDocumentServiceTypeActives() {
    try {
      const { data } = await amgApi.get(
        "/debt-solution/document-service-type/get-document-service-type-actives"
      );
      return data;
    } catch (error) {
      console.log(
        "Error DocumentServiceTypeService: getDocumentServiceTypeActives"
      );
      throw error;
    }
  }
}

export default new DocumentServiceTypeService();
