<template>
  <div>
    <header-slot>
      <template #indicators>
        <div
          v-if="$route.matched[2].name===$route.meta.chiefDashboardRoute "
          class="hours-counter"
        >
          <div
            class="hours"
            :style="!isDarkSkin?'background:white' : 'background:#292C33'"
          >
            <span class="hours-text">Working hours:</span>
            <span class="hours-value">{{ hoursCounter.workingHours }}</span>
          </div>
          <div
            class="hours"
            :style="!isDarkSkin?'background:white' : 'background:#292C33'"
          >
            <span class="hours-text">Holidays hours:</span>
            <span class="hours-value">{{ hoursCounter.holidaysHours }}</span>
          </div>

        </div>

      </template>
    </header-slot>
    <div class="header-items">
      <b-nav
        class="m-0"
        pills
        style="padding-top: 2em!important;
"
      >
        <b-nav-item
          :to=" {name : $route.meta.chiefDashboardRoute}"
          exact
          exact-active-class="active"
          :active="$route.matched[2].name == $route.meta.chiefDashboardRoute"
          :link-classes="['px-3',bgTabsNavs]"
        >
          My Department Attendance
        </b-nav-item>

        <!-- 16 is management -->
        <b-nav-item
          v-if="moduleId === 16"
          :to=" {name :'management-attendance-dashboard'}"
          exact
          exact-active-class="active"
          :active="$route.matched[2].name == $route.meta.attendanceDashboard"
          :link-classes="['px-3',bgTabsNavs]"
        >
          AMG Attendance
        </b-nav-item>

        <!-- 17 is RRHH -->
        <b-nav-item
          v-if="moduleId === 17"
          :to=" {name :'rrhh-attendance-dashboard'}"
          exact
          exact-active-class="active"
          :active="$route.matched[2].name == $route.meta.attendanceDashboard"
          :link-classes="['px-3',bgTabsNavs]"
        >
          AMG Attendance
        </b-nav-item>

        <!-- 16 is management and 17 is RRHH -->
        <b-nav-item
          v-if="[16,17].includes(moduleId)"
          :to=" {name :$route.meta.attendanceHolidays}"
          exact
          exact-active-class="active"
          :active="$route.matched[2].name == $route.meta.attendanceHolidays"
          :link-classes="['px-3',bgTabsNavs]"
        >
          Holidays
        </b-nav-item>

        <!-- 16 is management and 17 is RRHH -->
        <b-nav-item
          v-if="[16,17].includes(moduleId)"
          :to=" {name :$route.meta.attendanceVacations}"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :active="$route.matched[2].name == $route.meta.attendanceVacations"
        >
          Vacations
        </b-nav-item>

        <!-- 16 is management and 17 is RRHH -->
        <b-nav-item
          v-if="[16,17].includes(moduleId)"
          :to=" {name :$route.meta.attendanceQrGenerator}"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :active="$route.matched[2].name == $route.meta.attendanceQrGenerator"
        >
          Generate QR
        </b-nav-item>

        <!--  16 is management-->
        <b-nav-item
          v-if="moduleId === 16"
          :to="{ name: $route.meta.attendanceTolerance }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :active="$route.matched[2].name == $route.meta.attendanceTolerance"
        >
          Tolerance
        </b-nav-item>

        <b-nav-item
          :to=" {name :$route.meta.departmentalSchedules}"
          exact
          exact-active-class="active"
          :link-classes="['px-3',bgTabsNavs]"
          :active="$route.matched[2].name == $route.meta.departmentalSchedules"
        >
          Departamental Schedules
        </b-nav-item>

        <b-nav-item
          :to="{ name: $route.meta.attendanceEmployeesSchedule }"
          exact
          exact-active-class="active"
          :link-classes="['px-3', bgTabsNavs]"
          :active="
            $route.matched[2].name == $route.meta.attendanceEmployeesSchedule
          "
        >
          Employees Schedule
        </b-nav-item>

      </b-nav>

    </div>
    <div class="border-top-primary">
      <router-view :key="$route.name" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AttendanceMain',
  data() {
    return {
    };
  },
  computed: {
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isRRhh() {
      return this.moduleId === 17;
    },
    isManagement() {
      return this.moduleId === 16;
    },
    ...mapGetters({
      hoursCounter: 'AttendanceStore/G_HOURS_COUNTER',
    }),
  },
};
</script>
<style scoped>
.header-items {
}
.hours-counter{
  position: sticky;
  right: 0;
  display: flex;
  flex-direction: row;
  gap: 1em;
}.hours{
  background-color: white;
  border-radius: 7px;
  width: 23em;
  height: 5em;
  display:flex;
  align-items: center;
  padding: 0 2.25em;

}.hours-text{
  font-family: 'Montserrat', sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 14px;
}.hours-value{
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 22px;
  margin-left: 0.5em;
  text-align: center;
  color: #8F5FE8;

}.hours:last-child .hours-value{
  margin-left: 1.5em;
  color: #00D25B;

}
</style>
