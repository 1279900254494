export default [
  {
    path: "ncr/realtor",
    name: "credit-experts-ncr-realtor", //credit-experts-ncr-realtor
    component: () =>import(/* webpackChunkName: "CreditExpertsNcrRealtor" */ "@/views/credit-experts/views/ncr-realtor/views/NcrRealtorTemplate.vue"),
    redirect: { name: "ce-ncr-realtor-in-process" },
    meta: {
      pageTitle: "NCR Realtor",
      is_digital: 1,
      breadcrumb: [
        {
          text: "NCR Realtor",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "in-process",
        name: "ce-ncr-realtor-in-process",
        component: () =>import(/* webpackChunkName: "CreditExpertsNcrRealtorInProcess" */ "@/views/commons/components/ncr-realtor/views/components/TableNcrRealtor.vue"),
        meta: {
          statusNCR: 0,
          pageTitle: "NCR Realtor",
          is_digital: 1,
          breadcrumb: [
            {
              text: "NCR Realtor",
              active: true,
            },
          ],
        },
      },
      {
        path: "completed",
        name: "ce-ncr-realtor-completed",
        component: () => import(/* webpackChunkName: "CreditExpertsNcrRealtorCompleted" */ "@/views/commons/components/ncr-realtor/views/components/TableNcrRealtor.vue"),
        meta: {
          statusNCR: 1,
          is_digital: 1,
          pageTitle: "NCR Realtor",
          breadcrumb: [
            {
              text: "NCR Realtor",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
