const { amgApi } = require("@/service/axios");

class RequestWorkplanService {
  constructor() {
    this.routeAppointment = "other-services";
    this.routeAppointmentTracking = "other-services/tracking";
    this.routeAppointmentFiles = "other-services/files";
    this.routeOtherServices = "other-services";
  }

  async search(params) {
    try {
      const data = await amgApi.post(
        `${this.routeAppointment}/index`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async searchOtherServices(params){
    try {
      const data = await amgApi.post(`${this.routeOtherServices}/index`, params);
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateReprogrammingAppointment(params) {
    try {
      const data = await amgApi.post(
        `potential-appointment/store`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }
  async searchTracking(params) {
    try {
      const data = await amgApi.post(
        `${this.routeAppointmentTracking}/index`,
        params
      );
      return data.data;
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateAppointmentStatus(params) {
    try {
      const {data,status} = await amgApi.post(
        `${this.routeAppointment}/update-status`,
        params
      );
      return {data,status};
    } catch (error) {
      throw new Error(error);
    }
  }

  async getAppointmentByClientId(params) {
    try {
      const {data} = await amgApi.post(
        `${this.routeAppointment}/show`,
        params
      );
      return data;
    } catch (error) {
      throw new Error(error);
    }
  }
  //FILES
  async getFiles(parameter) {
    try {
      let data = await amgApi.post(`${this.routeAppointmentFiles}/index`, {
        appointment_id: parameter,
      });
      return data;
    } catch (error) {
       throw error;
    }
  }
  async uploadFiles(parameters) {
    try {
      let data = await amgApi.post(`${this.routeAppointmentFiles}/store`, parameters);
      return data.status === 200;
    } catch (error) {
       throw error;
    }
  }
  async deleteFile(parameters) {
    try {
      let data = await amgApi.post(`${this.routeAppointmentFiles}/delete`, parameters);
      return data.status === 200;
    } catch (error) {
       throw error;
    }
  }
  //END FILES
  async sendCompleted(parameters) {
    try {
      const data = await amgApi.post(`${this.routeAppointmentFiles}/send-completed`, parameters);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async saveOtherPayment(params){
    try{
      const data = await amgApi.post("other-services/charge-others/store", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateStatus(params){
    try{
      const data = await amgApi.post("other-services/update-status", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateStatusPaid(params){
    try{
      const data = await amgApi.post("other-services/update-status-paid", params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCountPending(params){
    try{
      const { data } = await amgApi.post('other-services/utils/get-count-pending-other-services', params);
      return data;
    }catch(error){
      throw error;
    }
  }

  async removeRegister(params){
    try {
      const { data } = await amgApi.post('other-services/remove-register', params);
      return data;
    } catch (error) {
      throw error;
    }
  }

}

export default new RequestWorkplanService();
