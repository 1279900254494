<template>
  <div>

    <b-modal
      ref="modal_recruitment_process"
      v-model="onControl"
      size="sm"
      title="Add new ticket Motive"
      header-bg-variant="info"
      modal-class="modal-danger"
      no-close-on-backdrop
      centered
      @hidden="close"
    >
      <validation-observer ref="observer">
        <b-row class="my-1">
          <b-col>
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                rules="required|max:50"
                name="title"
              >
                <b-form-group label="Ticket title">
                  <b-form-input
                    v-model="newMotiveCustomerTicket"
                    placeholder="Write the new ticket title"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                  /><small
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-around align-items-center">
          <b-button
            variant="primary"
            @click="save()"
          >
            Save
          </b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VMoney } from 'v-money';
import CustomerTicketsService from '@/views/commons/components/customer-tickets/services/customer-tickets.service';

export default {
  components: {
  },
  directives: { money: VMoney },
  props: {
    recruitment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      onControl: false,
      newMotiveCustomerTicket: null,
      result: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  watch: {
  },
  async created() {
    this.onControl = true;
  },

  async mounted() {
    this.errorMessage = true;
  },
  methods: {
    close() {
      this.$emit('close');
    },
    preventNegativeInput(event) {
      const invalidKeys = ['-', '+', '*', '/', '=', '!', '@', '#', '$', '%', '^', '&', '(', ')'];
      if (invalidKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    async save() {
      try {
        const validation = await this.$refs.observer.validate();
        if (!validation) return;

        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();

        const params = {
          current_user_id: this.currentUser.user_id,
          new_motive_name: this.newMotiveCustomerTicket,
        };

        const result = await CustomerTicketsService.AddNewMotiveCustomerTicket(params);

        this.errorMessage = false;
        this.result = result.data;

        if (this.result[0]) {
          this.showWarningSwal(null, this.result[0].Exist_Message);
        } else {
          this.$emit('close');
          this.showSuccessSwal(null, 'Motive added successfully!');
          this.$emit('updateMotives');
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.error(error);
        this.showErrorSwal(error);
      }
    },

  },
};
</script>
<style scope>
</style>
