<template>
  <b-modal
    v-model="openModalApprove"
    modal
    centered
    :title="moduleId === 22 ? 'CHIEF PASSWORD' : 'SUPERVISOR PASSWORD'"
    size="sm"
    modal-class="modal-primary "
    hide-footer
    title-tag="h3"
    :no-close-on-backdrop="true"
    @hidden="closeModalApprove"
  >
    <b-row>
      <b-col cols="12">
        <b-input-group class="mr-1">
          <b-form-input
            v-model="password"
            type="password"
            placeholder="Password"
          />
          <b-input-group-append>
            <b-button variant="primary" @click="decideApprove">
              Approve
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <small v-if="errorPassword" style="color: red">
          Incorrect Password</small
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
// import Services
import ClientDashboardService from "../../services/clients.dashboard.services";

export default {
  props: {
    modalApproveSupervisor: {
      type: Boolean,
    },
    fromBcPay: {
      type: Boolean,
      default: false,
    },
    typeApprove: null,
  },
  data() {
    return {
      openModalApprove: this.modalApproveSupervisor,
      password: "",
      errorPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    closeModalApprove() {
      this.$emit("closeModalApprove");
    },
    async decideApprove() {
      if (this.fromBcPay && this.moduleId === 7) {
        await this.approveSupervisorBc();
      } else {
        await this.approveSuperviser();
      }
    },
    async approveSuperviser() {
      try {
        const params = {
          password: this.password,
          id: this.currentUser.user_id,
          modul: this.moduleId,
        };
        this.addPreloader();
        const response = await ClientDashboardService.approveSuperviser(params);
        if (response.status == 200) {
          if (response.data.status == 1) {
            this.$emit("approveSup");
            this.closeModalApprove();
            this.errorPassword = false;
          } else {
            this.errorPassword = true;
          }
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with Approve!"
        );
      }
    },
    async approveSupervisorBc() {
      try {
        const params = {
          password: this.password,
          id: this.currentUser.user_id,
          modul: this.moduleId,
        };
        this.addPreloader();
        const response = await ClientDashboardService.approveSuperviser(params);
        if (response.status == 200) {
          if (response.data.status == 1) {
            // this.$emit('approveSup')
            // this.closeModalApprove()
            // this.errorPassword = false
            this.closeModalApprove();
            if (this.typeApprove === 1) {
              this.$emit("approveSupChar");
            } else {
              this.$emit("approveSup");
            }
            this.errorPassword = false;
          } else {
            this.errorPassword = true;
          }
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with Approve!"
        );
      }
    },
  },
};
</script>
