<template>
  <div>
    <div class="w-100 text-right">
      <b-button variant="info" class="mb-2" @click="modalQuickCreateOpen"
        >Create</b-button
      >
    </div>
    <b-table
      show-empty
      sticky-header
      small
      responsive="sm"
      :fields="fieldsQuicks"
      :items="quicks"
      :busy="isBusy"
      class="custom-table"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>

      <!-- Column: SMS -->
      <template #cell(sms)="data">
        <change-sms :SmsData="data.item.sms" />
      </template>

      <!-- Column: CreatedBy -->
      <template #cell(created_by)="data">
        <span>{{ data.item.user_created }}</span>
        <br />
        <span>{{ data.item.created_at | myGlobal }}</span>
      </template>

      <!-- Column: UpdatedBy -->
      <template #cell(updated_by)="data">
        <span>{{ data.item.user_updated }}</span>
        <br />
        <span v-if="data.item.updated_at">{{
          data.item.updated_at | myGlobal
        }}</span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <actions-table
          :options="['edit', 'delete']"
          :row-data="data.item"
          @onRowEdit="modalQuickEditOpen(data.item)"
          @onRowDelete="modalQuickDelete(data.item.id)"
        />
      </template>
    </b-table>

    <!-- modal SAVE QUICK SMS -->
    <b-modal
      id="modal-quick-sms-save"
      title-class="h4 text-white"
      centered
      size="lg"
      header-bg-variant="transparent"
      modal-class="custom-modal-amg"
      :title="quickData.id ? 'Edit Quick Sms' : 'New Quick Sms'"
      hide-footer
      header-class="p-0"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            {{ quickData.id ? "Edit Quick Sms" : "New Quick Sms" }}
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="$bvModal.hide('modal-quick-sms-save')"
            />
          </div>
        </div>
      </template>
      <modal-quick-sms-save :quick-data="quickData" />
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import ActionsTable from "./ActionsTable.vue";
import ModalQuickSmsSave from "./ModalQuickSmsSave.vue";
import ChangeSms from "./ChangeSms.vue";
export default {
  components: {
    ActionsTable,
    ModalQuickSmsSave,
    ChangeSms,
  },
  props: {
    quicks: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  data() {
    return {
      blankQuickData: {
        id: null,
        sms: "",
        title: "",
      },
      quickData: new Object(),
      userId: null,
      roleId: null,
      isBusy: false,
      fieldsQuicks: [
        { key: "title" },
        { key: "sms" },
        { key: "created_by" },
        { key: "updated_by" },
      ],
    };
  },
  methods: {
    ...mapActions({
      A_DELETE_SMS_QUICK: "CrmSmsStore/A_DELETE_SMS_QUICK",
    }),

    onShowMore(id) {
      const index = this.quicks.map((el) => el.id).indexOf(id);
      if (index !== -1) {
        this.quicks[index].showMore = !this.quicks[index].showMore;
      }
    },
    modalQuickCreateOpen() {
      this.quickData = JSON.parse(JSON.stringify(this.blankQuickData));
      this.$bvModal.show("modal-quick-sms-save");
    },
    modalQuickEditOpen(item) {
      this.quickData = item;
      this.$bvModal.show("modal-quick-sms-save");
    },
    async modalQuickDelete(id) {
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            this.addPreloader();
            const response = await this.A_DELETE_SMS_QUICK({ id });
            this.removePreloader();
            if (response.status == 200) {
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                response.message
              );
          }
        })
        .catch((error) => {
          this.removePreloader();
          console.log("Something went wrong modalQuickDelete", error);
          this.showToast(
            "danger",
            "top-right",
            "Oop!",
            "AlertOctagonIcon",
            this.getInternalErrors(error)
          );
        });
    },
  },
  created() {
    this.userId = this.currentUser.user_id;
    this.roleId = this.currentUser.role_id;
    if ([1, 2].includes(this.roleId) || this.moduleId == 15)
      this.fieldsQuicks.push({ key: "actions" });
  },
};
</script>
<style scoped>
* {
  --primary-color: #3f7afa;
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-table >>> th{
  background-color: var(--primary-color) !important;
  color: white !important;
}
.custom-table >>> th:first-child{
  border-top-left-radius: 10px !important;
}
.custom-table >>> th:last-child{
  border-top-right-radius: 10px !important;
}
</style>
