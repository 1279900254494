<template>
  <b-modal
    ref="modalDetailClassification"
    size="caro"
    title="Detail Classification"
    title-tag="h3"
    hide-footer
    modal-class="modal-primary"
    scrollable
    @hidden="closeModal"
  >
    <b-row class="mb-2">
      <b-input-group class="col-3">
        <b-input-group-prepend class="w-25">
          <b-input-group-text class="w-100 badge-name-group">
            <span>LEAD NAME</span>
          </b-input-group-text>
        </b-input-group-prepend>
        <div class="form-control d-flex align-items-center bg-transparent">
          {{ nameLead }}
        </div>
      </b-input-group>
      <b-input-group class="col-4">
        <b-input-group-prepend class="w-45">
          <b-input-group-text class="w-100 badge-name-group">
            <span>SUGGEST PROGRAMS</span>
          </b-input-group-text>
        </b-input-group-prepend>
        <div class="form-control d-flex align-items-center bg-transparent">
          <b-badge
            variant="light-info"
            class="ml-1"
            v-for="item in suggestProgram"
            :key="item"
          >
            {{ item }}
          </b-badge>
        </div>
      </b-input-group>
      <b-input-group class="col-3">
        <b-input-group-prepend class="w-45">
          <b-input-group-text class="w-100 badge-name-group">
            <span>SOLUTION PROBABILITY</span>
          </b-input-group-text>
        </b-input-group-prepend>
        <div class="form-control d-flex align-items-center bg-transparent">
          <b-badge :variant="colorAvg" class="ml-1" @click="showDetail = true">
            {{ average.toFixed(2) }} % OF REMOVE
          </b-badge>
        </div>
      </b-input-group>
      <b-input-group class="col-2">
        <b-input-group-prepend class="w-55">
          <b-input-group-text class="w-100 badge-name-group">
            <span>TOTAL ACCOUNTS</span>
          </b-input-group-text>
        </b-input-group-prepend>
        <div class="form-control d-flex align-items-center bg-transparent">
          <b-badge variant="light-primary">
            {{ totalAccounts }} ACCOUNTS
          </b-badge>
        </div>
      </b-input-group>
    </b-row>
    <b-table
      id="tab-claims"
      ref="table-claims"
      :items="data"
      :fields="fields"
      show-empty
    >
      <template #cell(type)="data">
        <b-badge
          :variant="
            data.item.type == 'ACCOUNTS' ? 'light-primary' : 'light-warning'
          "
          class="text-capitalize"
        >
          {{ data.item.type }}
        </b-badge>
      </template>
      <template #cell(probability)="data">
        <b-badge
          :variant="
            data.item.probability > 50
              ? 'light-success'
              : data.item.probability > 25
              ? 'light-warning'
              : 'light-danger'
          "
          class="text-capitalize"
        >
          {{ data.item.probability }} %
        </b-badge>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import moment from "moment"
import NcrRequestService from "@/views/commons/components/request-ncr/services/ncr-request.service.js"
export default {
  components: {},
  props: {
    score_id: {
      type: Number,
      default: 0,
    },
    nameLead: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: [],
      suggestProgram: [],
      average: 0,
      totalAccounts: 0,
    }
  },
  mounted() {
    this.toggleModal("modalDetailClassification")
  },
  computed: {
    fields() {
      return [
        { key: "account" },
        {
          key: "date_opened",
          thClass: "text-center",
          tdClass: "text-center",
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY")
          },
        },
        {
          key: "type",
          label: "Category",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "classification",
          label: "Type",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "probability",
          label: "Probability of remove",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "recommendation",
          label: "Result",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ]
    },
    colorAvg() {
      return this.average > 50
        ? "light-success"
        : this.average > 20
        ? "light-warning"
        : "light-danger"
    },
  },
  created() {
    this.getDetail()
  },
  methods: {
    closeModal() {
      this.$emit("close")
    },
    async getDetail() {
      const data = await NcrRequestService.getClassificationNcr({
        score_id: this.score_id,
      })
      this.data = data

      const accounts = data.filter((item) => item.type === "ACCOUNTS")
      const sum = accounts.reduce((a, b) => a + b.probability, 0)
      const avg = sum / accounts.length || 0
      this.totalAccounts = accounts.length
      this.average = avg
      if (accounts.length === 0) {
        this.suggestProgram = ["BOOST CREDIT"]
      }
      if (avg > 50) {
        this.suggestProgram = ["CREDIT EXPERTS"]
      } else if (avg > 20) {
        this.suggestProgram = ["CREDIT EXPERTS", "DEBT SOLUTION"]
      } else {
        this.suggestProgram = ["DEBT SOLUTION"]
      }
    },
  },
}
</script>

<style>
.w-45 {
  width: 45% !important;
}
.w-55 {
  width: 55% !important;
}
</style>