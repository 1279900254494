// import PaymentsClientsGrid from './components/PaymentsClientsGrid.vue'
export default [
    {
      path: "payments",
      name: "credit-experts-financial-payment",
      redirect: { name: "credit-experts-financial-payment-list-clients" },
      component: () => import(/* webpackChunkName: "CreditExpertsPayments" */ "@/views/commons/components/payments/views/components/PaymentsTab.vue"),
      meta: {
        pageTitle: "Payments",
        permittedRoles: [1, 2, 11, 3, 17],
        breadcrumb: [
          {
            text: "Payments",
            active: true,
          },
        ],
        route: "credit-experts-financial",
      },
      children: [
        {
          path: "clients",
          component: () => import(/* webpackChunkName: "CreditExpertsPaymentsClients" */ "@/views/commons/components/payments/views/components/PaymentsClientsGrid.vue"),
          name: "credit-experts-financial-payment-list-clients",
          meta: {
            pageTitle: "Payments",
            permittedRoles: [1, 2, 11, 3, 17],
            breadcrumb: [
              {
                text: "Clients",
                active: true,
              },
            ],
            route: "credit-experts-financial",
          },
        },
        {
          path: "leads",
          component: () => import(/* webpackChunkName: "CreditExpertsPaymentsLeads" */ "@/views/commons/components/payments/views/components/PaymentsLeadsGrid.vue"),
          name: "credit-experts-financial-payment-list-leads",
          meta: {
            pageTitle: "Payments",
            permittedRoles: [1, 2, 11, 3, 17],
            breadcrumb: [
              {
                text: "Leads",
                active: true,
              },
            ],
            route: "credit-experts-financial",
          },
        },
      ],
    },
  ];
  