import store from "@/store"
import ClaimsV2Service from "@/views/commons/components/claims_v2/services/claims-v2.service.js";
import InquiriesClaimService from "@/views/commons/components/claims_v2/views/claim-inquiries/services/inquiry-claim.service.js";

export default {
  namespaced: true,
  state: {
    dataClaims: [],
    refreshTable: false,
    S_PENDING_CLAIMS_1: 0,
    S_PENDING_CLAIMS_2: 0,
    S_PENDING_CLAIMS_3: 0,
    S_PENDING_INQUIRIES: 0,
    S_SOLVED_INQUIRIES: 0,

  },
  getters: {
    getRefreshTable: (state) => state.refreshTable,
    G_PENDING_CLAIMS_1(state) {
      return state.S_PENDING_CLAIMS_1
    },
    G_PENDING_CLAIMS_2(state) {
      return state.S_PENDING_CLAIMS_2
    },
    G_PENDING_CLAIMS_3(state) {
      return state.S_PENDING_CLAIMS_3
    },
    G_PENDING_INQUIRIES(state) {
      return state.S_PENDING_INQUIRIES
    },
    G_SOLVED_INQUIRIES(state) {
      return state.S_SOLVED_INQUIRIES
    }
  },
  mutations: {
    SET_REFRESH_TABLE(state, payload) {
      state.refreshTable = payload;
    },
    M_PENDING_CLAIMS_1(state, payload) {
      state.S_PENDING_CLAIMS_1 = payload
    },
    M_PENDING_CLAIMS_2(state, payload) {
      state.S_PENDING_CLAIMS_2 = payload
    },
    M_PENDING_CLAIMS_3(state, payload) {
      state.S_PENDING_CLAIMS_3 = payload
    },
    M_PENDING_INQUIRIES(state, payload) {
      state.S_PENDING_INQUIRIES = payload
    },
    M_SOLVED_INQUIRIES(state, payload) {
      state.S_SOLVED_INQUIRIES = payload
    },
  },
  actions: {
    CHANGE_REFRESH_TABLE({ commit }, data) {
      commit("SET_REFRESH_TABLE", data);
    },

    async A_PENDING_CLAIMS({commit}, module) {
      const { pending_claims_1, pending_claims_2, pending_claims_3 } = await ClaimsV2Service.getClaimsCounters({});

      commit("M_PENDING_CLAIMS_1", pending_claims_1)
      commit("M_PENDING_CLAIMS_2", pending_claims_2)
      commit("M_PENDING_CLAIMS_3", pending_claims_3)

      // update sidebar
      const sideCounter = module == 16? pending_claims_3 : pending_claims_1 + pending_claims_2

      if(sideCounter > 0){
        const name = {
          16: "claims-management",
          18: "claims-quality",
        }

        const payload = {
          routeName: name[module] || null,
          tag: sideCounter > 99 ? "+99" : sideCounter,
        }

        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
      }
    },

    async A_PENDING_INQUIRIES ({commit}) {
      const { pending_inquiries, solved_inquiries } = await InquiriesClaimService.getInquiriesCounter({user_id: store.state.auth.currentUser.user_id});

      commit("M_PENDING_INQUIRIES", pending_inquiries)
      commit("M_SOLVED_INQUIRIES", solved_inquiries)

      if(pending_inquiries > 0) {
        const payload = {
          // routeName: 'specialists-inquiries',
          routeName: 'analyst-department-inquiries',
          tag: pending_inquiries > 99? "+99" : pending_inquiries,
        }

        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
      }

      if(solved_inquiries > 0) {
        const payload = {
          routeName: 'quality-inquiries',
          tag: solved_inquiries > 99? "+99" : solved_inquiries,
        }

        store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload)
      }
    }
  },
};
