export default {
  namespaced: true,
  state: {
    S_SELECT_DATE: [],
    S_SELECTED_USER: null,
  },
  mutations: {
    M_SELECT_DATE(state, params) {
      state.S_SELECT_DATE = params
    },
    M_SET_SELECTED_USER(state, params) {
      state.S_SELECTED_USER = params
    },
  },
  actions: {
    A_SELECT_DATE({ commit }, data) {
      commit('M_SELECT_DATE', data)
    },
  },
  getters: {
    G_SELECT_DATE(state) {
      return state.S_SELECT_DATE
    },
    G_SELECTED_USER: state => state.S_SELECTED_USER,
  },
}
