import Vue from "vue";
import Vuex from "vuex";

import StatusChange from '@/views/commons/components/status-change/store/status-change.store'
import InitialPayment from "@/views/administration/views/initial-payment/store/initial-payment-counter.store";
import NcrLeads from "@/views/commons/components/ncr-leads/store/ncr-leads.store.js";
import ChargeBack from "@/views/administration/views/charge-back/store/charge-back.store.js";
import poBoxStore from "@/views/commons/components/file-po-box/file-po-box.store.js";

Vue.use(Vuex);

export default {
    StatusChangeStore: StatusChange,
    InitialPaymentStore: InitialPayment,
    NcrLeadsStore: NcrLeads,
    ChargeBackStore: ChargeBack,
    poBoxStore: poBoxStore
};