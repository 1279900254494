<template>
  <div>
    <header-slot />
    <b-nav card-header pills class="m-0">
      <!-- <b-nav-item
        :to="{ name: 'sales-made-cedigital-lead' }"
        :class="{ 'active bg-tab rounded-top': isNewClient }"
        :link-classes="[
          linkClasses,
          newClientCounter == 0 ? 'padding-bottom-tabs-without-badge' : '',
        ]"
        :active="isTabLead"
        >Leads

        <b-badge
          v-if="newClientCounter && newClientCounter > 0"
          pill
          variant="danger"
          class="ml-1 mr-auto"
          >{{ newClientCounter }}</b-badge
        >
      </b-nav-item> -->
      <b-nav-item
        :to="{ name: 'sales-made-cedigital-lead' }"
        :class="{ 'active bg-tab rounded-top': isTabLead }"
        exact-active-class="active"
        :link-classes="['h-full px-3', bgTabsNavs]"
        :active="isTabLead"
        >Leads
        <span v-if="counterSaleLead" class="ml-1">
          <b-badge pill variant="danger">
            {{ counterSaleLead.total > 99 ? "99+" : counterSaleLead.total }}
          </b-badge>
        </span>
      </b-nav-item>
      <!-- <b-nav-item
        :to="{ name: 'sales-made-cedigital-client' }"
        exact
        exact-active-class="active"
        :link-classes="[
          'px-3',
          bgTabsNavs,
          'padding-bottom-tabs',
          addChangeCounter == 0 ? 'padding-bottom-tabs-without-badge' : '',
        ]"
        :active="isTabClient"
        >Clients

        <b-badge
          v-if="addChangeCounter && addChangeCounter > 0"
          pill
          variant="danger"
          class="ml-1 mr-auto"
          >{{ addChangeCounter }}</b-badge
        >
      </b-nav-item> -->
      <b-nav-item
        :to="{ name: 'sales-made-cedigital-client' }"
        :class="{ 'active bg-tab rounded-top': isTabClient }"
        exact-active-class="active"
        :link-classes="['h-full px-3', bgTabsNavs]"
        :active="isTabClient"
        >Clients
        <span v-if="G_ADDCHANGE_COUNTERS > 0" class="ml-1">
          <b-badge pill variant="danger">
            {{ G_ADDCHANGE_COUNTERS > 99 ? "99+" : G_ADDCHANGE_COUNTERS }}
          </b-badge>
        </span>
      </b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import SalesMadeCeDigitalService from "@/views/ce-digital/sub-modules/sales/views/sales-made/sales-made-ce-digital.service";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "SalesMade",
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_ADDCHANGE_COUNTERS: "CrmSaleMadeStore/G_ADDCHANGE_COUNTERS",
      G_SALE_LEADS_COUNTER: "CrmSaleMadeStore/G_SALE_LEADS_COUNTER",
    }),
    isNewClient() {
      return this.$route.name === "sales-made-cedigital-lead";
    },
    linkClasses() {
      if (this.isNewClient)
        return ["px-3", "bg-tab", "text-white", "padding-bottom-tabs"];
      return ["px-3", "padding-bottom-tabs", this.bgTabsNavs];
    },
    isTabLead() {
      return [
        this.$route.matched[2].meta.routePendingLead,
        this.$route.matched[2].meta.routeForApprovedLead,
        this.$route.matched[2].meta.routeDoneLead,
      ].includes(this.$route.name);
    },
    isTabClient() {
      return [
        this.$route.matched[2].meta.routePendingClient,
        this.$route.matched[2].meta.routeForApprovedClient,
        this.$route.matched[2].meta.routeForApprovedClientInProcess,
        this.$route.matched[2].meta.routeForApprovedClientCompleted,
        this.$route.matched[2].meta.routeDoneClient,
      ].includes(this.$route.name);
    },
    counterSaleLead() {
      if (this.G_SALE_LEADS_COUNTER && this.G_SALE_LEADS_COUNTER.total > 0) {
        return this.G_SALE_LEADS_COUNTER;
      }
      return null;
    },
  },
  data() {
    return {
      addChangeCounter: 0,
      newClientCounter: 0,
    };
  },
  mounted() {
    Promise.all([
      this.getAddChangeCounterForCeDigital(),
      this.getNewClientsCounterForCeDigital(),
    ]);
  },
  methods: {
    async getAddChangeCounterForCeDigital() {
      try {
        const response =
          await SalesMadeCeDigitalService.getAddChangeCounterForCeDigital();
        this.addChangeCounter = response;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getNewClientsCounterForCeDigital() {
      try {
        const response =
          await SalesMadeCeDigitalService.getNewClientsCounterForCeDigital();
        this.newClientCounter = response;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style scoped>
.bg-tab {
  background-color: #0090e7;
}
.padding-bottom-tabs {
  padding-bottom: 10px !important;
}
.padding-bottom-tabs-without-badge {
  padding-bottom: 16px !important;
}
</style>
