import ClaimsNavigation from "@/views/tax-research/views/claims-v2/claims.nav";
import ClientsNavigation from "@/views/tax-research/views/clients/clients-group.navigation";
import ProductRequestNavigation from "@/views/tax-research/views/request/request.nav.js";
import LeadsNavigation from "@/views/tax-research/views/leads/leads.navigation";
import HelpDeskNavigation from "@/views/tax-research/views/helpdesk/helpdesk.nav";
import SchedulesNavigation from "@/views/tax-research/views/schedules/schedules.nav";
import PaymentsNavigation from "@/views/tax-research/views/payments/payments.nav";
import FileManagerNavigation from "@/views/tax-research/views/file-manager/file-manager.nav";
import ChargeBackNavigation from "@/views/tax-research/views/charge-back/charge-back.nav";
import PaymentReportNavigation from "@/views/tax-research/views/report/report.nav";
import CommisionsNavigation from "@/views/tax-research/views/commissions/commissions.nav";
import SalesMadeNavigation from "@/views/tax-research/views/sales-made/sales-made.nav";
import OtherPaymentsNavigation from "@/views/tax-research/views/other-payments/other-payments.nav";
import EmployeeClaimsNavigation from "@/views/tax-research/views/employee-claims/navigation/employee-claims.nav";
import RingCentralNav from "@/views/tax-research/views/ring-central/ring-central.nav.js";
import MOFNavigation from "@/views/tax-research/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/tax-research/views/recruitment-process/tax-research-recruitment_process.navigation";

import RequestNcrNavigation from "@/views/tax-research/views/request-ncr/request-ncr.nav";
import CourtInfoNavigation from "@/views/tax-research/views/cour-info/navigation/ds-court-info.nav";

import InconsistentTransactionNavigation from "@/views/tax-research/views/inconsistent-transactions/navigation/inconsistent-transactions.nav.js";
import taxresearchDashboardNav from "@/views/tax-research/views/dashboard/navigation/taxresearch-dashboard.nav.js";
import CallImpressionNavigation from "@/views/tax-research/views/call-impression/navigation/call-impression.navigation.js";

const navigation = [
  {
    header: "TAX RESEARCH",
  },
  taxresearchDashboardNav,
  LeadsNavigation,
  ClientsNavigation,
  ClaimsNavigation,
  SalesMadeNavigation,
  PaymentReportNavigation,
  PaymentsNavigation,
  ChargeBackNavigation,
  FileManagerNavigation,
  SchedulesNavigation,
  HelpDeskNavigation,
  ProductRequestNavigation,
  OtherPaymentsNavigation,
  EmployeeClaimsNavigation,
  RingCentralNav,
  ...CourtInfoNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  ...InconsistentTransactionNavigation,
  ...CallImpressionNavigation
];
export default navigation;
