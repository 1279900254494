import paymentsNav from "@/views/bookeeping/views/payments/payments.nav";
import FileManagerNav from "@/views/bookeeping/views/file-manager/file-manager.nav";
import helpdeskNav from "@/views/bookeeping/views/helpdesk/helpdesk.nav";
import leadNav from "@/views/bookeeping/views/leads/leads.nav";
import clientsNav from "@/views/bookeeping/views/clients/clients-group.navigation";
import saleMadeNav from "@/views/bookeeping/views/sales-made/sales-made.nav";
import RequestWorkPlanNav from "@/views/bookeeping/views/request-workplan/request-workplan.nav.js";
import reportNav from "@/views/bookeeping/views/reports/reports.nav";
import calendarNav from "@/views/bookeeping/views/calendar/calendar.nav";
import commissionsNav from "@/views/bookeeping/views/commissions/commissions.nav";
import loansNav from "@/views/bookeeping/views/loans/loans.nav";
import schedulesNav from "@/views/bookeeping/views/schedules/schedules.nav";
import chargeBackNav from "@/views/bookeeping/views/charge-back/charge-back.nav";
import trackingListNav from "@/views/bookeeping/views/tracking-list/tracking-list.nav";
import zeroPaymentNav from "@/views/bookeeping/views/zero-payment/navigation/zero-payment.navigation";
import ProductRequestNav from "@/views/bookeeping/views/request/request.nav.js";
import claimsNav from "@/views/bookeeping/views/claims-v2/claims.nav.js";
import EmployeeClaimsNav from "@/views/bookeeping/views/employee-claims/navigation/employee-claims.nav";
import RingCentralNav from "@/views/bookeeping/views/ring-central/ring-central.nav.js";
import CourtInfoNavigation from "@/views/bookeeping/views/cour-info/navigation/ds-court-info.nav";
import RecruitmentProcessNavigation from "@/views/bookeeping/views/recruitment-process/bookeeping-recruitment_process.navigation";
import MOFNavigation from "@/views/bookeeping/views/manual-of-functions/manual-of-functions.nav.js";
import InconsistentTransactionNavigation from "@/views/bookeeping/views/inconsistent-transactions/navigation/inconsistent-transactions.nav.js";
import DashboardNavigation from "@/views/bookeeping/views/dashboard/navigation/bookeeping-dashboard.nav.js";
// import PendingPaymentsNavigation from '@/views/bookeeping/views/pending-payments/navigation/pending-payments.navigation.js';
import CallImpressionNavigation from "@/views/bookeeping/views/call-impression/navigation/call-impression.navigation.js";


const navigation = [
  {
    header: "KEYBOOK",
  },
  DashboardNavigation,
  ...leadNav,
  clientsNav,
  // ...PendingPaymentsNavigation,
  claimsNav,
  ...zeroPaymentNav,
  ...saleMadeNav,
  // commissionsNav,
  trackingListNav,
  RequestWorkPlanNav,
  ...reportNav,
  ...paymentsNav,
  chargeBackNav,
  FileManagerNav,
  calendarNav,
  schedulesNav,
  loansNav,
  helpdeskNav,
  ProductRequestNav,
  EmployeeClaimsNav,
  RingCentralNav,
  ...CourtInfoNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  ...InconsistentTransactionNavigation,
  ...CallImpressionNavigation,
];

export default navigation;
