<template>
  <b-modal
    :ref="alertRef"
    hide-footer
    hide-header-close
    hide-header
    :body-class="`rounded`"
    :no-close-on-backdrop="persistent"
    size="summary"
    centered
    @hidden="isActive = false"
  >
    <div>
      <div
        class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
      >
        <div
          class="d-flex align-items-center Justify-content-center"
          style="gap: 7px"
        >
          <span class="title-alert font-strong">{{ title }}</span>
        </div>
        <div
          class="bg-close text-secondary cursor-pointer"
          @click="closeOnClose()"
        >
          <feather-icon icon="XIcon" size="16" variant="dark" />
        </div>
      </div>
      <div v-if="iconCreatedBy" class="d-flex align-items-center mt-1">
        <div class="icon-badge mr-1 pending">
          <feather-icon :icon="iconCreatedBy" size="20" />
        </div>
        <span>
          <span :class="colorText" class="text-uppercase">
            <change-sms v-if="created_by" :sms-data="created_by" />
          </span>
        </span>
      </div>

      <!-- <div
        v-if="iconsubtitle3"
        class="d-flex align-items-center mt-1"
      >
        <div class="icon-badge mr-1 pending">
           <feather-icon           
           :icon="iconsubtitle3"
           size="20"            
           />
          </div>
          <span>
               <span
              :class="colorText"
              class="text-uppercase"
               >
              <change-sms
              v-if="subtitle3"
              :sms-data="subtitle3"
              />
              </span>
          </span>          
      </div> -->

      <div v-if="iconsubtitle4" class="d-flex align-items-center mt-1">
        <div class="icon-badge mr-1 pending">
          <feather-icon :icon="iconsubtitle4" size="20" />
        </div>
        <span>
          <span :class="colorText" class="text-uppercase">
            <change-sms v-if="subtitle4" :sms-data="subtitle4" />
          </span>
        </span>
      </div>

      <div class="d-flex align-items-center mt-1">
        <div class="icon-badge mr-1 pending">
          <feather-icon :icon="icon" size="20" :class="colorIcons(icon)" />
        </div>
        <span>
          <span :class="colorText" class="text-uppercase">
            <change-sms v-if="subtitle" :sms-data="subtitle" />
          </span>
        </span>
      </div>
      <div v-if="iconsubtitle2" class="d-flex align-items-center mt-1">
        <div class="icon-badge mr-1 pending">
          <feather-icon :icon="iconsubtitle2" size="20" />
        </div>
        <span>
          <span :class="colorText" class="text-uppercase">
            <change-sms v-if="subtitle2" :sms-data="subtitle2" />
          </span>
        </span>
      </div>
      <div></div>
      <hr />
      <div class="d-flex flex-column" style="gap: 15px">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="d-flex justify-content-between"
          @click="
            item.hasOwnProperty('dataComponent') ? (showComponent = true) : null
          "
        >
          <div class="d-flex">
            <feather-icon
              v-if="item.icon"
              :icon="item.icon"
              class="mr-1 font-strong"
              size="14"
              svg-classes="font-small-4"
            />
            <span class="font-strong text-right">
              {{ item.title }}
            </span>
          </div>

          <span class="font-strong pl-1">
            <change-sms
              v-if="item.description"
              :sms-data="item.description"
              text-align="right"
          /></span>
          <template v-if="item.hasOwnProperty('dataComponent')">
            <component
              :is="item.dataComponent.name"
              v-if="showComponent"
              v-bind="item.dataComponent.props"
              @close="
                showComponent = false;
                closeOnClose();
              "
            />
          </template>
        </div>
      </div>
      <hr v-if="data.length > 0" />
      <template v-if="hasComponent">
        <div class="grid-flow">
          <b-button
            variant="primary"
            target="_blank"
            @click.prevent="showComponent = true"
            >{{ okComponentTitle }}
          </b-button>
          <b-button variant="primary" target="_blank" @click.prevent="closeOnOK"
            >{{ okTitle }}
          </b-button>
        </div>
      </template>
      <b-row v-else>
        <b-col v-if="onlyClose" cols="6">
          <b-button class="w-100" variant="secondary" @click="closeOnClose()">
            Close
          </b-button>
        </b-col>
        <b-col :cols="onlyClose ? '6' : '12'">
          <b-button
            class="w-100"
            variant="primary"
            target="_blank"
            @click.prevent="closeOnOK"
            >{{ okTitle }}
          </b-button>
        </b-col>
      </b-row>
    </div>
    <template v-if="hasComponent">
      <component
        :is="dataComponent.name"
        v-if="showComponent"
        v-bind="dataComponent.props"
        @close="
          showComponent = false;
          close();
        "
      />
    </template>
  </b-modal>
</template>
<script>
import store from "@/store";
import { mapGetters } from "vuex";
import ModalDetails from "@/views/commons/components/helpdesk/components/modals/ModalDetailsKanban.vue";
import ChangeSms from "@/views/commons/components/clients/components/clientsSms/ChangeSms";
import ModalTicketCustomer from "@/views/commons/components/customer-tickets/chat-components/TicketCostumerChat.vue";

export default {
  name: "AlertClaimEmployee",
  components: {
    ChangeSms,
    ModalDetails,
    ModalTicketCustomer,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    subtitle2: {
      type: String,
      default: "",
    },
    subtitle3: {
      type: String,
      default: "",
    },
    subtitle4: {
      type: String,
      default: "",
    },
    created_by: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconsubtitle2: {
      type: String,
      default: "",
    },
    iconsubtitle3: {
      type: String,
      default: "",
    },
    iconsubtitle4: {
      type: String,
      default: "",
    },
    iconCreatedBy: {
      type: String,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
    ok: {
      type: Function,
      default: () => {},
    },
    onlyClose: {
      type: Boolean,
      default: false,
    },
    closeSkt: {
      type: Function,
      default: () => {},
    },
    okTitle: {
      type: String,
      default: "OK",
    },
    okComponentTitle: {
      type: String,
      default: "OK",
    },
    alertRef: {
      type: String,
      default: "amg-alert",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    dataComponent: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showComponent: false,
    };
  },
  mounted() {
    this.toggleModal(this.alertRef);
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    colorText() {
      const status = this.dataClaim?.status;
      const color = {
        COMPLETED: "text-success font-strong",
        REACTIVATED: "text-warning font-strong",
        PENDING: "text-warning font-strong",
        SCALED: "text-warning font-strong",
      };
      return color[status];
    },
    hasComponent() {
      return !!this.dataComponent;
    },
  },
  methods: {
    createComponent() {
      return resolveComponent(this.dataComponent.name);
    },
    close() {
      this.$refs[this.alertRef].hide();
    },
    colorIcons(icon) {
      const color = {
        BookOpenIcon: "text-warning",
        AlertCircleIcon: "text-warning",
        CheckCircleIcon: "text-success",
        CheckIcon: "text-success",
        InfoIcon: "text-info",
        XIcon: "text-danger",
      };
      return color[icon] || "text-warning";
    },
    closeOnOK() {
      this.ok();
      this.close();
    },
    closeOnClose() {
      this.closeSkt();
      this.close();
    },
  },
  watch: {
    showComponent(val) {
      store.commit("StandarStore/SET_SHOW_AMG_ALERT", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-badge {
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &.completed {
    background-color: rgba(40, 199, 111, 0.1);
    color: #ff9f43;
  }
  &.pending {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.reactivated {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.scaled {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
}

.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}

.bg-close {
  color: #ececec;
  .dark {
    background-color: #585858;
  }
}
.grid-flow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
</style>
