<template>
  <b-input-group v-if="!okPassword && isRequired && isModalAddThis">
    <b-form-input
      v-model="password"
      type="password"
      :style="
        errorPassword == true
          ? 'border:1px solid red'
          : 'border: 2px solid #ccc'
      "
    />
    <b-input-group-append>
      <b-button
        variant="info"
        size="sm"
        v-if="!okPassword"
        @click="verifyPassword"
      >
        <feather-icon icon="CheckIcon" />Approve Supervisor
      </b-button>
      <b-button v-else size="sm" variant="info" disabled>
        <b-spinner small />Loading...
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      errorPassword: false,
      okPassword: false,
      password: "",
    };
  },
  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isModalAddThis: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async verifyPassword() {
      try {
        this.addPreloader();
        const response = await amgApi.post("/commons/get-password-supervisor", {
          module_id: this.moduleId,
          field_pass: this.password,
          type: 1,
        });
        if (response.data) {
          this.getPassSuper = response.data;
          if (this.getPassSuper === "ok") {
            this.errorPassword = false;
            this.okPassword = true;
            this.password = "";
            this.validatenext1 = true;
            this.show = false;
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Password correct"
            );
            this.$emit("okPassword", this.okPassword);
          } else {
            this.validatenext1 = false;
            this.show = false;
            this.errorPassword = true;
            this.showToast(
              "danger",
              "top-right",
              "Danger",
              "XIcon",
              "Incorrect password"
            );
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>