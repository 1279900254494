export default [
  {
    path: "clients",
    name: "boost-credit-clients",
    component: () =>
      import(
        /* webpackChunkName: "BCClientsMain" */ "./components/clients/tab/ClientsTabNew.vue"
      ),
    redirect: { name: "boost-c-clients" },
    meta: {
      // permittedRoles: [1, 2],
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "boost-c-clients",
        component: () =>
          import(
            /* webpackChunkName: "BCClientsList" */ "./components/clients/ClientsGridNew"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Clients",
              active: true,
            },
          ],
        },
      },
      {
        path: "shared",
        name: "boost-c-shared",
        component: () =>
          import(
            /* webpackChunkName: "BCClientShared" */ "@/views/commons/components/clients/components/Shared.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Shared",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "clients/account/:idClient",
    name: "boost-credit-dashboard",
    redirect: { name: "personal-information-boost-credit" },
    component: () =>
      import(
        /* webpackChunkName: "BCClientAccount" */ "./dashboard/AccountBoostCredit.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay",
        name: "pay-boost-credit",
        component: () =>
          import(
            /* webpackChunkName: "BCClientPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "dis",
        name: "dis-boost-credit",
        redirect: { name: "analysis-cr-bc" },
        component: () =>
          import(
            /* webpackChunkName: "BCClientDis" */ "@/views/commons/components/clients/dashboard/options/dis-module/CreditHistoryMain.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Dispute",
              active: true,
            },
          ],
        },
        children: [
          {
            path: "analysis",
            name: "analysis-cr-bc",
            component: () =>
              import(
                /* webpackChunkName: "BoostCreditAnalysisCR" */ "@/views/commons/components/clients/dashboard/analysis/AccountsClients.vue"
              ),
            meta: {
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Items",
                  active: true,
                },
              ],
            },
          },
          {
            path: "dispute-detail",
            name: "dispute-detail-bc",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/DisputeDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Disputes",
                  active: true,
                },
              ],
            },
          },
          {
            path: "report-detail",
            name: "report-detail-bc",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Credit Report",
                  active: true,
                },
              ],
            },
          },
          {
            path: "updates-detail",
            name: "updates-detail-bc",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/UpdateDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Updates",
                  active: true,
                },
              ],
            },
          },
          {
            path: "workplan-detail",
            name: "workplan-detail-bc",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/WorkPlanDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Workplan",
                  active: true,
                },
              ],
            },
          },
          {
            path: "recommendation-detail",
            name: "recommendation-detail-bc",
            component: () =>
              import(
                /* webpackChunkName: "recommendationDetailBc" */ "@/views/commons/components/clients/dashboard/options/services/services.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Clients",
              breadcrumb: [
                {
                  text: "Credit > Recommendations",
                  active: true,
                },
              ],
            },
          },
          {
            path: "credit-report-detail",
            name: "creport-detail-bc",
            component: () =>
              import(
                /* webpackChunkName: "DsClientAccountDisDetail" */ "@/views/commons/components/clients/dashboard/options/dis-module/CReportDetail.vue"
              ),
            meta: {
              isClientsTab: true,
              pageTitle: "Credit Report Detail",
              breadcrumb: [
                {
                  text: "Credit Report Detail",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "task-notes",
        name: "task-notes-boost-credit",
        component: () =>
          import(
            /* webpackChunkName: "BCClientTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotesNew.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information",
        name: "personal-information-boost-credit",
        component: () =>
          import(
            /* webpackChunkName: "BCClientPersonalInformation" */ "@/views/boost-credit/views/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files",
        name: "client-files-boost-credit",
        component: () =>
          import(
            /* webpackChunkName: "BCClientFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr",
        name: "acr-boost-credit",
        component: () =>
          import(
            /* webpackChunkName: "BCClientAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "report",
        name: "report-boost-credit",
        component: () =>
          import(
            /* webpackChunkName: "BCClientReport" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log",
        name: "call-log-boost-credit",
        redirect: { name: "call-log-conversation-boost-credit" },
        component: () =>
          import(
            /* webpackChunkName: "BCClientReport" */ "@/views/commons/components/ring-central/call-log/tabs/ClientTabs.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
          callConversationRoute: "call-log-conversation-boost-credit",
          callNoConversationRoute: "call-log-not-conversation-boost-credit",
        },

        children: [
          {
            path: "conversation",
            name: "call-log-conversation-boost-credit",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Internal",
                  active: true,
                },
              ],
              isConversation: 1,
            },
          },
          {
            path: "no-conversation",
            name: "call-log-not-conversation-boost-credit",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Internal",
                  active: true,
                },
              ],
              isConversation: 0,
            },
          },
        ],
      },
      {
        path: "services",
        name: "services-client-boost-credit",
        component: () =>
          import(
            /* webpackChunkName: "servicesClient" */
            "@/views/commons/components/clients/dashboard/options/services/services.vue"
          ),
        meta: {
          isClientsTab: true,
          module: 7,
          pageTitle: "Services",
          breadcrumb: [{ text: "Services", active: true }],
        },
      },
      {
        path: "tickets",
        name: "client-boost-credit-tickets",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
