<template>
  <b-modal
    ref="modalPayableExpense"
    :title="title"
    size="sm"
    centered
    title-class="h4 text-white font-weight-bolder"
    header-class="class_modal_js"
    @ok="handleOk"
    @hidden="handleHidden"
    ok-title="Create"
  >
    <ValidationObserver ref="form">
      <b-row>
        <b-col>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <b-form-group label="Name">
              <b-form-input
                autofocus
                v-model="name"
                :state="errors.length > 0 ? false : null"
                @keyup.enter="handleOk"
              />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import DepartmentExpenses from "@/views/commons/expenses/services/expenses-department.service";
export default {
  name: "ModalCreatePayableModal",
  props: {
    method: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: null,
    };
  },
  computed: {
    openFromDashboardCeo() {
      return this.$route.matched[0].name === "ceo-dashboard";
    },
    moduleId() {
      return this.openFromDashboardCeo ? 1 : this.$route.matched[0].meta.module;
    },
    title() {
      let title;
      switch (this.method) {
        case "CREDIT_CARD":
          title = "CREATE VENDOR";
          break;
        case "CHECK":
          title = "CREATE PAY TO";
          break;
        default:
          title = "CREATE VENDOR";
          break;
      }
      return title;
    },
  },
  methods: {
    handleShow() {
      // reset data
    },
    async handleOk(ev) {
      ev.preventDefault();
      let isValid = await this.$refs["form"].validate();
      if (!isValid) return;
      await this.createPayableTo();
    },
    handleHidden() {
      // reset
      this.$emit("closing");
    },
    async createPayableTo() {
      try {
        this.addPreloader();
        let res = await DepartmentExpenses.insertPayableExpenses({
          description: this.name,
          module_id: this.moduleId,
          method: this.method,
        });
        this.$emit("refresh", res);
        this.$emit("closing");
        this.name = null;
        this.removePreloader();
      } catch (e) {
        this.removePreloader();
        this.showAlertWarning(e);
      }
    },
    showAlertWarning(message) {
      this.showToast(
        "warning",
        "top-right",
        "Warning",
        "AlertTriangleIcon",
        message
      );
    },
  },
  mounted() {
    this.toggleModal("modalPayableExpense");
  },
};
</script>
