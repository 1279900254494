export default {
    namespaced: true,
    state: {
        S_COUNTER_PO_BOX: 0,
    },
    mutations: {
        M_SET_COUNTER_PO_BOX(state, payload) {
            state.S_COUNTER_PO_BOX = payload
        },

    },
    actions: {
        A_SET_COUNTERS_PO_BOX({ commit }, payload) {
            commit('M_SET_COUNTER_PO_BOX', payload)
        },
    }
}
