export default {
    namespaced: true,
    state: {
      refreshTable: false,
    },
    getters: {
      getRefreshTable: (state) => state.refreshTable,
    },
    mutations: {
      SET_REFRESH_TABLE(state, payload) {
        state.refreshTable = payload;
      },
    },
    actions: {
      CHANGE_REFRESH_TABLE({ commit }, data) {
        commit("SET_REFRESH_TABLE", data);
      },
    },
  };
