/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class NavbarService {
  async getUserNotifications(userId) {
    const data = await amgApi.post('/notification/get-notifications', {
      id: userId,
    });
    return data.data;
  }

  async getSellerApointments(payload) {
    const data = await amgApi.post('/notification/get-appointments-notification', payload);
    return data.data;
  }

  async getAllAppointmentNotifications(payload) {
    const data = await amgApi.post('/notification/get-all-appointments-notification', payload);
    return data;
  }

  async getMeetings(payload) {
    try {
      const data = await amgApi.post('/miro/meetings/get-meetings', payload);
      return data;
    } catch (error) {
      console.log('error to get meetings', error);
    }
  }

  async getCustomerTicketsNotifications(payload) {
    try {
      const data = await amgApi.post('/notification/get-customer-tickets-notification', payload);
      return data;
    } catch (error) {
      console.log('error to get customer tickets notifications', error);
    }
  }

  async getAutomaticPaymentsNotificationCounter(payload) {
    try {
      const data = await amgApi.post('/notification/get-automatic-payments-notification-counter', payload);
      return data;
    } catch (error) {
      console.log('error to get automatic payments notifications', error);
    }
  }

  async getAutomaticPaymentsNotifications(payload) {
    try {
      const data = await amgApi.post('/notification/get-automatic-payments-notification', payload);
      return data;
    } catch (error) {
      console.log('error to get automatic payments notifications', error);
    }
  }

  async getAutomaticPaymentsNotificationTabs(params) {
    const data = await amgApi.post(
      "/notification/get-automatic-payments-notification-tabs",
      params
    )
    return data.data
  }
}
export default new NavbarService();
