<template>
  <div>
    <b-modal
      ref="myModal"
      modal
      v-model="modalService"
      size="lg"
      header-class="p-0"
      header-bg-variant="transparent border-bottom border-bottom-2"
      modal-class="modal-primary"
      title-class="h2 text-white"
      hide-footer
      :no-close-on-backdrop="true"
    >
      <!-- HEADER START -->
      <template v-slot:modal-header>
        <modal-service-header
          :type-modal="typeModal"
          :programs-all="programsAll"
          :users-services="usersServices"
          :sales="Client"
          :twoPerRow="false"
          :type-service="'add/change'"
          @close="hideModal(false)"
          @changeProgram="changeProgram"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalServiceHeader from "@/views/crm/views/sales-made/components/modals/services/ModalServiceHeader.vue";
import ButtonCancel from "@/views/commons/utilities/ButtonCancel.vue";
import ButtonSave from "@/views/commons/utilities/ButtonSave.vue";
// Import Services
import ClientService from "../services/clients.services";
export default {
  components: {
    ButtonSave,
    ButtonCancel,
    ModalServiceHeader,
  },
  props: {
    modalOpenAddService: {
      type: Boolean,
    },
    detailsClient: {
      type: Object,
    },
    typeModal: {
      type: [Number, String],
    },
    originalEventId: {
      type: Number,
      required: false,
    }
  },
  data() {
    return {
      modalService: this.modalOpenAddService,
      Client: {},
      programsAll: [],
      usersServices: [],
      programName: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async getScore() {
      try {
        const response = await amgApi.post("/attend/get-score-attend", {
          lead_id: this.detailsClient.lead_id,
        });
        if (response.status === 200) {
          this.scoreId = response.data.score_id;
        }
      } catch (error) {
        console.error(error);
      }
    },

    ...mapActions("crm-store", ["SET_PROGRAM_HEADER"]),
    closeModal() {
      this.$emit("closeModalService");
    },
    changeProgram(data) {
      if (data) {
        this.$emit("changeProgram", data);
      }
    },
    hideModal() {
      this.$refs["myModal"].hide();
      this.$emit("hideModal", false);
    },
    usersAllPrograms: async function () {
      try {
        const data = await ClientService.usersAllPrograms({
          id: this.moduleId,
        });
        this.usersServices = data.data;
        this.usersServices.unshift({ id: 1, user_name: "CEO" });
      } catch (error) {
        console.log(error);
      }
    },
    searchPrograms: async function (lead_id) {
      try {
        const response = await ClientService.searchClientPayment({
          id: lead_id,
        });
        if (response.status == 200) {
          let programs = response.data;
          const data = await ClientService.programs();
          this.programsAll = data.data;
          this.programsAll = this.programsAll.filter(
            (program) => program.id != 9
          ); // different of paragon, paragon is not a program anymore, neither a service
          programs.sort(function (a, b) {
            return a.program_id - b.program_id;
          });
          for (var i = 0; i < this.programsAll.length; i++) {
            for (var y = 0; y < programs.length; y++) {
              if (this.programsAll[i].id == programs[y].program_id) {
                this.programsAll.splice(i, 1);
              }
            }
          }
          var sper = this.currentUser.sale_permission;
          var spn = [];
          if (sper.length != this.programsAll.length) {
            sper.map((spe) => {
              this.programsAll.map((pro) => {
                if (spe.id == pro.id) {
                  spn.push(pro);
                }
              });
            });
            this.programsAll = spn;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    this.changeProgram();

    this.SET_PROGRAM_HEADER(this.programName);
    await this.getScore();
  },
  created() {
    if (Boolean(this.detailsClient.program) === true) {
      this.programName = this.detailsClient.program;
    } else {
      this.programName = JSON.parse(this.detailsClient.accounts)[0].program;
    }
    this.Client = {
      lead_name: this.detailsClient.lead_name,
      program: this.programName,
      ...this.detailsClient,
    };
    this.SET_PROGRAM_HEADER(null);
    this.searchPrograms(this.detailsClient.lead_id);
    this.usersAllPrograms();
  },
};
</script>
