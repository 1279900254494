
import { saleMadeTabs } from "@/views/commons/components/sales-made/router/sale-made.router";
export default [
  {
    path: "sales-made",
    name: "spfa-sales-made",
    redirect: { name: "sales-made-spfa-lead" },
    component: () =>
      import(
        "@/views/specialists/sub-modules/financial-approval/views/sales-made/SalesMade.vue"
      ),
    meta: {
      program: 7,
      permittedRoles: [1, 2, 3],
      pageTitle: "Sales Made",
      breadcrumb: [
        {
          text: "Sales Made",
        },
      ],
    },
    children: saleMadeTabs("spfa")
  },
];
