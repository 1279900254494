<template>
  <div>
    <b-row v-if="isClient" class="client-info">
      <!-- Client -->
      <b-col cols="6" class="p-0">
        <div
          class="mr-1"
          :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
        >
          <p>Client | {{ client.client_name }}</p>
        </div>
      </b-col>
      <b-col cols="6" class="p-0">
        <div
          class="ml-1"
          :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
        >
          <p>Program | {{ client.program_name }}</p>
        </div>
      </b-col>
    </b-row>
    <b-row v-else class="client-info">
      <b-col cols="6" class="p-0">
        <div
          class="mr-1"
          :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
        >
          <p>
            Lead |
            {{
              lead.first_name
                ? lead.first_name + " " + lead.last_name
                : lead.lead_name
            }}
          </p>
        </div>
      </b-col>
      <b-col cols="6" class="p-0"> </b-col>
    </b-row>
    <b-table
      show-empty
      sticky-header
      responsive
      :fields="fields"
      :items="items"
      :busy="isBusy"
      class="table-new-customization custom-table"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(main_row)="data">
        <div class="w-100 d-flex justify-content-between">
          <span v-if="!data.item.seeHideCell">
            {{
              data.item.main_row_hide
                ? data.item.main_row_hide
                : data.item.main_row
            }}
          </span>
          <span v-else>
            {{
              data.item.main_row_hide
                ? data.item.main_row
                : data.item.main_row_hide
            }}
          </span>
          <feather-icon
            v-if="
              data.item.main_row_hide &&
              (isSupervisor || isCeo || isCoordinator || isChief)
            "
            class="cursor-pointer mr-3"
            size="16"
            :icon="!data.item.seeHideCell ? 'EyeIcon' : 'EyeOffIcon'"
            @click="data.item.seeHideCell = !data.item.seeHideCell"
          />
        </div>
      </template>
      <template #cell(created_by)="data">
        <div class="row align-items-center">
          <div class="col-2 p-0">
            <b-avatar
              variant="info"
              :src="
                data.item.user_image_created
                  ? data.item.user_image_created
                  : require('@/assets/images/logo/amg_logo.svg')
              "
            ></b-avatar>
          </div>
          <div class="col-9 px-1">
            <span>{{ data.item.created_name_user }}</span> <br />
            <span>{{ data.item.created_at | myGlobalDay }}</span>
          </div>
        </div>
      </template>
      <template #cell(updated_by)="data">
        <div class="row align-items-center" v-if="data.item.updated_at != null">
          <div class="col-2 p-0">
            <b-avatar
              variant="info"
              :src="
                data.item.user_image_updated
                  ? data.item.user_image_updated
                  : require('@/assets/images/logo/amg_logo.svg')
              "
            ></b-avatar>
          </div>
          <div class="col-9 px-1">
            <span>{{ data.item.updated_name_user }}</span> <br />
            <span>{{ data.item.updated_at | myGlobalDay }}</span>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import Ripple from "vue-ripple-directive";

export default {
  components: {},
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      client: "DebtSolutionClients/G_CLIENTS",
      /* G_TEMPLATES: 'CrmTemplateStore/G_TEMPLATES' */
    }),
    ...mapState({
      /* S_TEMPLATES: event => event.CrmTemplateStore.S_TEMPLATES */
    }),
  },
  created() {
    this.authUser = this.currentUser;
  },
  data() {
    return {
      authUser: new Object(),
      fields: [
        { key: "main_row", label: this.name },
        { key: "created_by" },
        { key: "updated_by" },
      ],
    };
  },
  directives: { Ripple },
  methods: {
    ...mapActions({
      /* A_GET_TEMPLATES: 'CrmTemplateStore/A_GET_TEMPLATES' */
    }),
  },
  mounted() {},
  props: {
    isClient: {
      type: Boolean,
      required: false,
      default: false,
    },
    lead: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "name",
    },
    isBusy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {},
};
</script>
<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
