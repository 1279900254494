<template>
  <b-container fluid>
    <b-row class="mb-1">
      <b-col cols="8">
        <slot name="left-content">
          <div></div>
        </slot>
        <validation-observer ref="formProgramPayment" class="d-flex">
          <b-form-group label="Program payment">
            <validation-provider
              v-slot="{ errors }"
              rules="customRequired|money-1"
            >
              <b-input-group prepend="$">
                <money
                  v-model="programPayment"
                  v-bind="vMoney2"
                  class="form-control"
                  :class="{ 'border-danger': errors[0] && validateMoney }"
                  :disabled="!isRejected"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Retainer fee" class="ml-3">
            <validation-provider v-slot="{ errors }" rules="customRequired">
              <v-select
                v-model="retainerFee"
                :options="retainerFeeOptions"
                :reduce="(item) => item.value"
                :class="errors[0] ? 'border-color:red' : ''"
                :disabled="!isRejected"
                label="label"
                :clearable="false"
              />
            </validation-provider>
          </b-form-group>
          <b-form-group label="Settlement cost" class="ml-3">
            <validation-provider rules="customRequired">
              <b-form-select
                id="settlement"
                v-model="settlementCost"
                :disabled="!isRejected"
              >
                <b-form-select-option
                  v-for="(item, index) in arraySettlementCost"
                  :key="index"
                  :value="item"
                  >{{ item + ".00 %" }}</b-form-select-option
                >
              </b-form-select>
            </validation-provider>
          </b-form-group>
          <div class="d-flex align-items-center">
            <feather-icon
              icon="SaveIcon"
              size="30"
              class="pt-1 ml-1 "
              :class="!isRejected ? 'text-secondary': 'text-primary cursor-pointer'"
              @click="addProgramPayment"
            />
          </div>
        </validation-observer>
      </b-col>
      <b-col cols="4">
        <div class="d-flex justify-content-end mt-1">
          <b-button
            v-if="!isModalShow"
            variant="outline-secondary"
            class="mr-1"
            @click="addNewCreditor"
            >Add Creditor</b-button
          >
          <button-export-pdf
            :disabled="creditors.length === 0"
            @click="salesClient.account_id ? downloadPdf() : downloadPdfEvent()"
          />
        </div>
      </b-col>
    </b-row>
    <ValidationObserver ref="form">
      <b-row>
        <b-table responsive :fields="fields" :items="creditors">
          <template v-slot:cell(unsecured)="data">
            <div class="w-100 text-center">
              <feather-icon v-if="data.item.unsecured == 1" icon="CircleIcon" />
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <div
              v-if="!isModalShow"
              class="d-flex align-items-center justify-content-center"
            >
              <feather-icon
                v-if="data.item.ncr_request_id == null"
                class="cursor-pointer text-info font-medium-4 mr-1"
                icon="EditIcon"
                @click="editCreditor(data.item.id)"
              />
              <template v-else>
                <feather-icon
                  v-if="!data.item.editData"
                  class="cursor-pointer text-warning font-medium-4 mr-1"
                  icon="EditIcon"
                  @click="data.item.editData = true"
                />
                <feather-icon
                  v-if="data.item.editData"
                  icon="CheckIcon"
                  class="text-success cursor-pointer font-medium-4 mr-1"
                  @click="saveData(data.item)"
                />
                <feather-icon
                  v-if="data.item.editData"
                  icon="XIcon"
                  class="text-danger cursor-pointer font-medium-4 mr-1"
                  @click="cancelEdit(data.item)"
                />
              </template>
              <feather-icon
                
                class="cursor-pointer text-danger font-medium-4"
                icon="TrashIcon"
                @click="deleteCreditor(data.item.id)"
              />
            </div>
          </template>
          <template v-slot:cell(balance)="data">
            $ {{ data.value | currency }}
          </template>

          <!-- TYPE -->
          <template #cell(typt)="data">
            <div v-if="data.item.editData">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-select
                  v-model="data.item.ds_type_id"
                  :options="types"
                  :reduce="(value) => value.id"
                  label="value"
                  transition=""
                  :clearable="false"
                  :state="errors[0] ? false : null"
                />
              </ValidationProvider>
            </div>
            <div v-else>
              {{ data.item.type }}
            </div>
          </template>

          <!-- MONTHLY -->
          <template v-slot:cell(monthly)="data">
            <div v-if="!data.item.editData">
              <span> $ {{ data.value | currency }} </span>
            </div>
            <div v-else>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <money
                  v-model="data.item.monthly"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: '$',
                    precision: 2,
                  }"
                  class="form-control form-control-sm"
                  style="width: 100px"
                  :state="errors[0] ? false : null"
                />
              </ValidationProvider>
            </div>
          </template>

          <!-- MONTHS -->
          <template #cell(months)="{ item }">
            <div v-if="!item.editData">
              <span>
                {{ item.months }}
              </span>
            </div>
            <div v-else>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-input
                  type="number"
                  v-model="item.months"
                  class="form-control form-control-sm"
                  style="width: 60px"
                  :state="errors[0] ? false : null"
                ></b-input>
              </ValidationProvider>
            </div>
          </template>

          <!-- INTEREST -->
          <template #cell(interest)="{ item }">
            <div v-if="!item.editData">
              <span>
                {{ item.interest }}
              </span>
            </div>
            <div v-else>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <money
                  v-model="item.interest"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: '%',
                    precision: 2,
                  }"
                  class="form-control form-control-sm"
                  style="width: 70px"
                  :state="errors[0] ? false : null"
                />
              </ValidationProvider>
            </div>
          </template>
          <template #bottom-row>
            <td colspan="1" />
            <td>
              <div>Total</div>
            </td>
            <td class="bg-info">
              <div class="text-white">$ {{ total_balance | currency }}</div>
            </td>
            <td class="bg-info">
              <div class="text-white">$ {{ total_monthly | currency }}</div>
            </td>
            <td colspan="1" class="bg-info" />
            <td class="bg-info rounded-right">
              <div class="text-white">{{ total_interest + " %" }}</div>
            </td>
            <td colspan="3" />
          </template>
        </b-table>
      </b-row>
    </ValidationObserver>
    <b-row v-if="!requiredNewApprov">
      <b-col cols="4">
        <field-approve-supervisor
          :module-id="moduleToValidatePassword"
          :isRequired="!passwordIsCorrectAndCreditorLength && isNotAuth"
          :is-modal-add-this="isModalAddThis"
          @okPassword="reSendOkPassword"
        />
      </b-col>
    </b-row>
    <b-modal
      v-model="openmodal"
      size="xmd"
      :title="statemodal == 1 ? 'ADD CREDITOR' : 'EDIT CREDITOR'"
      no-close-on-backdrop
      title-tag="h3"
      @ok.prevent="
        statemodal == 1
          ? $refs.modalCreditorNew.savecreditor()
          : $refs.modalCreditorNew.updatecreditor()
      "
    >
      <modal-add-creditor-new
        ref="modalCreditorNew"
        :idevent="salesClient.event_id"
        :state="statemodal"
        :idcreditor="idcreditor"
        :account-prog="salesClient.account_id"
        :type_in="0"
        :client_id="null"
        :salesClient="salesClient"
        @click="closemodal1"
        @reload="updateFinancialAnalysisAndFee"
        :isFromFirstStep="isFromFirstStep"
      />
      <template #modal-footer="{ ok, cancel }">
        <div style="display: flex; justify-content: end; align-items: center">
          <b-button
            variant="primary"
            class="rounded"
            style="border-width: 1px; margin-right: 7px"
            @click="ok()"
            >{{ statemodal == 1 ? "Add" : "Update" }}</b-button
          >
          <b-button variant="secondary" class="rounded mr-2" @click="cancel()">
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>

    <modal-add-creditor-new-ds
      v-if="showModalAddCreditor"
      ref="modalCreditorNewDs"
      :idevent="salesClient.event_id"
      :state="1"
      :client_id="null"
      :type_in="0"
      :true-retainer="1"
      :lead_id="salesClient.lead_id"
      :dataCred="currentCreditor"
      @reload="updateFinancialAnalysisAndFee"
      @close="showModalAddCreditor = false"
    />

    <modal-result-analysis-NCR
      v-if="showResultAnalisys"
      :params="paramsResultAnalysis"
      @closeModal="showResultAnalisys = false"
    ></modal-result-analysis-NCR>
  </b-container>
</template>

<script>
import ModalAddCreditorNew from "@/views/crm/views/sales-made/components/modals/services/debt-solution/ModalAddCreditorNew.vue";
import ButtonExportPdf from "@/views/commons/utilities/ButtonExportPdf.vue";
import ModalAddCreditorNewDs from "@/views/crm/views/Lead/lead-module/dashboard/modal/ModalAddCreditorNewDs.vue";
import ServiceLeads from "@/views/commons/components/lead-programs/service/lead.service.js";
import FieldApproveSupervisor from "@/views/commons/utilities/FieldApproveSupervisor.vue";
import { mapGetters } from "vuex";
import Fields from "@/views/crm/views/sales-made/components/modals/services/debt-solution/fields.data.js";
import SalesMadeServices from "@/views/crm/views/sales-made/services/sales-made.service.js";
import ModalResultAnalysisNCR from "@/views/commons/components/request-ncr/views/modals/ModalResultAnalysisNCR.vue";

export default {
  components: {
    ButtonExportPdf,
    ModalAddCreditorNew,
    ModalAddCreditorNewDs,
    FieldApproveSupervisor,
    ModalResultAnalysisNCR,
  },
  props: {
    salesClient: Object,
    idleyend: String,
    typeModal: {
      type: Number,
      default: 1,
      // 1: create, 2: show
    },
    isModalShow: Boolean,
    isModalAdd: Boolean,
  },
  data() {
    return {
      isFromFirstStep: true,
      blocking: true,
      creditors: [],
      openmodal: false,
      showModalAddCreditor: false,
      title_modal: "",
      currentCreditor: {},
      typeModalCreditor: 1,
      idcreditor: "",
      statemodal: "",
      errorPassword: false,
      okPassword: false,
      password: "",
      validatenext1: false,
      show: false,
      total_balance: "",
      total_monthly: "",
      total_interest: "",
      id_history: "",
      id_analisis: "",
      exportPdfDisabled: false,
      fields: Fields,
      notExistCreditors: false,
      retainerFeeOptions: [
        { value: "0.0", label: "0%" },
        { value: "0.01", label: "1%" },
        { value: "0.02", label: "2%" },
        { value: "0.03", label: "3%" },
        { value: "0.04", label: "4%" },
        { value: "0.05", label: "5%" },
        { value: "0.06", label: "6%" },
        { value: "0.07", label: "7%" },
        { value: "0.08", label: "8%" },
        { value: "0.09", label: "9%" },
        { value: "0.1", label: "10%" },
      ],
      arraySettlementCost: Array.from({ length: 21 }, (_, index) => index + 5),
      vMoney2: {
        maxlength: 10,
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false,
      },
      programPayment: 0,
      retainerFee: "0.1",
      settlementCost: 25,
      validateMoney: false,
      showResultAnalisys: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isNotAuth() {
      return ![1, 2, 17].includes(this.currentUser.role_id);
    },
    passwordIsCorrectAndCreditorLength() {
      return (
        (this.okPassword && this.creditors.length > 0) ||
        (this.creditors[0] ? this.creditors[0].state1 == 1 : true)
      );
    },
    isModalAddThis() {
      return (
        this.typeModal === 1 ||
        this.typeModal === 3 ||
        this.typeModal === 4 ||
        this.typeModal === 6
      );
    },
    isInModuleConnection() {
      return this.$route.matched[0].meta.module == 20;
    },
    clientAccountId() {
      if (
        this.typeModal === 3 ||
        this.typeModal === 4 ||
        this.typeModal === 5
      ) {
        return this.salesClient.account_id;
      }
      if (this.isInModuleConnection) {
        return this.salesClient.account_id;
      }
      return null;
    },
    clientAccountIdForSaveFirst() {
      if (this.typeModal == 3 || this.typeModal == 4) {
        return this.salesClient.account_id;
      }
      if (this.isInModuleConnection) {
        return this.salesClient.account_id;
      }
      return null;
    },
    requiredNewApprov() {
      return this.$route.meta.requiredNewAppr;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    moduleToValidatePassword() {
      return this.moduleId == 2 ? 2 : 5; // si es CRM  validar contrasena de Adrian, caso contrario de Emma (DS);
    },
    isNotAuth() {
      return ![1, 2].includes(this.currentUser.role_id) || this.moduleId != 5;
    },
    isRejected(){
      const status = [3,16,14,15,1];
      return status.includes(this.salesClient.status);
    }
  },
  watch: {
    passwordIsCorrectAndCreditorLength(newVal) {
      this.$emit("input", newVal);
    },
  },
  async created() {
    // await this.verifyIfhasAnalysis();
    await this.allTypes();
    await this.allDebtSolution();
    await this.getEventAdditionalData();
  },
  methods: {
    async openImportSettlementModal(){
      const params = {
        ca_id_first_program: this.salesClient.client_account_id
      }
      const { data } = await SalesMadeServices.getSettlementsFirstProgram(params);
      this.paramsResultAnalysis = {
        leadName: this.salesClient.client,
        leadId: this.salesClient.lead_id,
        clientId: this.salesClient.client_account_id,
        ncrRequestId: data[0].ncr_id,
        scoreId: data[0].score_id,
        is_analyzed: 1
      }
      this.showResultAnalisys = true;
    },
    async getEventAdditionalData() {
      try {
        const { data } = await SalesMadeServices.getEventAdditionalData({
          eventId: this.salesClient.event_id,
        });
        this.retainerFee =
          data[0].retainer_fee == null
            ? this.retainerFee
            : data[0].retainer_fee;
        this.retainerFee =
          this.retainerFee == "0.10" ? "0.1" : this.retainerFee;
        this.programPayment =
          data[0].program_payment == null
            ? this.programPayment
            : data[0].program_payment;
        this.settlementCost =
          data[0].settlement_cost == null
            ? this.settlementCost
            : data[0].settlement_cost;
      } catch (error) {
        this.showErrorSwal();
      }
    },
    reSendOkPassword(value) {
      this.$emit("input", value);
      this.okPassword = value;
    },
    addNewCreditor() {
      if (!this.salesClient.ds_analysis_options_id) {
        this.openmodal = true;
        this.statemodal = 1;
      } else {
        this.typeModalCreditor = 1;
        this.currentCreditor = {};
        this.title_modal = "Add Creditor";
        this.showModalAddCreditor = true;
      }
    },
    needsValidatePassword() {
      return this.creditors.filter((element) => element.state1 == 1).length > 0;
    },
    async allDebtSolution() {
      try {
        const response = await amgApi.post(
          "/sales-made/debt-solution/get-credits-debt-solution",
          {
            event:
              this.typeModal === 3 ||
              this.typeModal === 4 ||
              this.typeModal === 5
                ? null
                : this.salesClient.event_id,
            account: this.clientAccountId,
          }
        );
        if (response.status === 200) {
          this.creditors = response.data;
          if (this.creditors.length > 0) {
            this.total_balance = this.creditors[0].total_balance;
            this.total_monthly = this.creditors[0].total_monthly;
            this.total_interest = this.creditors[0].total_interest;
            this.id_history = this.creditors[0].id_history;
            this.id_analisis = this.creditors[0].id_analisis;
            this.$emit("stateCreditor", this.creditors[0].state1);
            this.creditors = this.creditors.map((item) => {
              return {
                ...item,
                editData: false,
                monthsCpy: item.months,
                monthlyCpy: item.monthly,
                interestCpy: item.interest,
                dsTypeIdCpy: item.ds_type_id_cpy,
              };
            });
          } else {
            this.notExistCreditors = true;
          }
          this.removePreloader();
          this.showModalAddCreditor = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async allTypes() {
      try {
        const response = await SalesMadeServices.getDebtsTypes();
        if (response.status === 200) {
          this.types = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async addProgramPayment() {
      if(!this.isRejected) return;
      this.validateMoney = true;
      const success = await this.$refs.formProgramPayment.validate();
      if (success) {
        // const confirm = await this.showConfirmSwal();
        // if (!confirm.isConfirmed) return;

        try {
          this.addPreloader();
          let params = {
            id_event: this.salesClient.event_id,
            program_payment: this.programPayment,
            retainer_fee: this.retainerFee,
            settlement_cost: this.settlementCost,
          };
          const { data, status } =
            await SalesMadeServices.UpdateProgramPaymethAndRetainer(params);
            this.showToast(
              "success",
              "top-right",
              "Success",
              "CheckIcon",
              "Program payment values registered successfully!"
            );
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    cancelEdit(item) {
      item.monthly = parseFloat(item.monthlyCpy);
      item.months = item.monthsCpy;
      item.interest = item.interestCpy;
      item.ds_type_id = item.ds_type_id_cpy;
      item.editData = false;
    },
    async saveData(item) {
      const validation = await this.$refs.form.validate();
      if (!validation) return;

      const confirm = await this.showConfirmSwal();
      if (!confirm.isConfirmed) return;

      const params = {
        id: item.id,
        monthly: item.monthly,
        months: item.months,
        interest: item.interest,
        ds_type_id: item.ds_type_id,
      };
      try {
        this.addPreloader();
        const { data } = await SalesMadeServices.editCreditorData(params);
        if (data.success) {
          this.showToast(
            "success",
            "top-right",
            "Success!!",
            "CheckIcon",
            "Successfully process"
          );
          this.allDebtSolution();
        } else {
          this.showToast(
            "error",
            "top-right",
            "Oops!",
            "XIcon",
            "Failed process"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async editCreditor(id) {
      try {
        this.openmodal = true;
        this.statemodal = 0;
        this.idcreditor = id;
      } catch (error) {
        console.error(error);
      }
    },
    async closemodal1() {
      this.openmodal = false;
      await this.allDebtSolution();
    },
    async deleteCreditor(id) {
      try {
        const result = await this.showConfirmSwal(
          "Are you Sure?",
          "Before finalizing you must save."
        );
        if (result.value) {
          const response = await amgApi.post(
            "/sales-made/debt-solution/delete-credit",
            { id }
          );
          if (response.status === 200) {
            if (this.salesClient.ds_analysis_options_id) {
              await this.updateFinancialAnalysisAndFee();
            } else {
              await this.allDebtSolution();
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },

    async nextfirst(id, type) {
      try {
        if (this.isModalShow) return true;
        if (type == 1 || type == 2) {
          return await this.axiosNext(id, type);
        }
        if (type == 4) {
          return await this.saveant(id, type);
        }
        if (this.dato12 == null || this.dato12 == "") {
          this.errorGoal = true;
          return false;
        }
        this.errorGoal = false;
        if (this.date3 == null || this.date3 == "") {
          this.errorDate = true;
          return false;
        }
        this.errorDate = false;
        return await this.axiosNext(id, type);
      } catch (error) {
        throw error;
      }
    },
    async saveant(id, type) {
      try {
        const response = await amgApi.post(
          "/sales-made/debt-solution/save-first-debt-solution",
          {
            type,
            id,
            event: this.salesClient.event_id,
            account: null,
            total_due: this.mont12,
            cost: this.mont13,
            monthly: this.mont14,
            porctrf: this.porctrf,
            retainer: this.mont15,
            months: this.mont16,
            years: this.mont17,
            estimated: this.mont18,
            id_history: this.id_history,
            id_analisis: this.id_analisis,
            name1: this.name1,
            last1: this.last1,
            date1: this.date1,
            ssn1: this.ssn1,
            applicant: this.applicant,
            name2: this.name2,
            last2: this.last2,
            date2: this.date2,
            ssn2: this.ssn2,
            address: this.address,
            city: this.city,
            state: this.state,
            zipcode: this.zipcode,
            civil: this.civil,
            civil2: this.civil2,
            dependents: this.dependents,
            dependents2: this.dependents2,
            phone1: this.phone1,
            employer1: this.employer1,
            workphone1: this.workphone1,
            employer2: this.employer2,
            workphone2: this.workphone2,
            dato1: this.dato1,
            dato2: this.dato2,
            dato3: this.dato3,
            dato4: this.dato4,
            dato5: this.dato5,
            dato6: this.dato6,
            dato7: this.dato7,
            dato8: this.dato8,
            dato91: this.dato91,
            dato92: this.dato92,
            dato93: this.dato93,
            dato94: this.dato94,
            dato95: this.dato95,
            dato10: this.dato10,
            dato11: this.dato11,
            dato12: this.dato12,
            dato13: this.dato13,
            dato14: this.dato14,
            dato15: this.dato15,
            date3: this.date3,
            date4: this.date4,
            date5: this.date5,
            date6: this.date6,
            others: this.others,
            middle1: this.middle1,
            state_lead1: this.state_lead1,
            middle2: this.middle2,
            state_lead2: this.state_lead2,
            phone2: this.phone2,
            email: this.email,
            valorutility: this.valorutility,
            montoutlity: this.montoutlity,
            valorothers: this.valorothers,
            montoothers: this.montoothers,
            housing: this.housing == false ? 0 : 1,
          }
        );
        if (response.status === 200) {
          await this.allDebtSolution();
          return true;
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async axiosNext(id, type) {
      try {
        const result = await this.showConfirmSwal(
          "Are you sure of continue ?",
          "Before finalizing you must save."
        );
        if (result.value) {
          this.addPreloader();
          const response = await amgApi.post(
            "/sales-made/debt-solution/save-first-debt-solution",
            {
              type,
              id,
              event:
                this.typeModal == 3 || this.typeModal == 4
                  ? null
                  : this.salesClient.event_id,
              account: this.clientAccountIdForSaveFirst,
            }
          );
          if (response.status === 200) this.$emit("nextStep");
          return true;
        }
        return false;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async downloadPdf() {
      this.addPreloader();
      const params = {
        id: this.salesClient.account_id,
        eventId: this.salesClient.event_id,
      };

      const response = await amgApi.post(
        "/sales-made/debt-solution/imprimir-file-appotc",
        params,
        {
          responseType: "blob",
        }
      );
      await this.forceFileDownload(response.data, "LIST_ACCOUNTS.pdf");
      this.removePreloader();
    },
    async downloadPdfEvent() {
      this.addPreloader();
      const params = {
        id: this.salesClient.account_id,
        eventId: this.salesClient.event_id,
      };

      const response = await amgApi.post(
        "/sales-made/debt-solution/imprimir-file-appot",
        params,
        {
          responseType: "blob",
        }
      );
      await this.forceFileDownload(response.data, "LIST_ACCOUNTS.pdf");
      this.removePreloader();
    },
    getCreditorsLength() {
      return this.creditors.length;
    },

    async updateFinancialAnalysisAndFee() {
      const params = {
        id_event: this.salesClient.event_id,
        option: 0, // option for update
      };
      try {
        if (this.salesClient.ds_analysis_options_id) {
          const { data } = await ServiceLeads.financialAnalysisOption(params);
        }
        await this.allDebtSolution();
        this.$emit("getCreditsDS");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
