import store from "@/store";
import router from "@/router/index";

export const closeAllAutoAlerts = () => {
  const { user_id } = store.getters["auth/currentUser"];
  window.socket.bind("client-close-all-auto-alert", async (data) => {
    if (data.user_id === user_id) {
      store.commit("AutoAlertStore/OPEN_ALERT", false);
      store.commit("AutoAlertStore/ID_ALERT", null);
      store.commit("AutoAlertStore/CLIENT_DATA", null);
    }
  });
};

export const openAlert = () => {
  const { user_id } = store.getters["auth/currentUser"];
  window.socket.bind("alert-customer-service", async (data) => {
    const isDashboard =
      router.currentRoute.matched[1]?.name === "ce-customer-service-dashboard";
    const isCustomer =
      router.currentRoute.matched[1]?.name ===
      "ce-customer-service-dashboard-general";
    const inModule = router.currentRoute.matched[0]?.meta?.module === 22;
    const noAlert = store.state.AutoAlertStore.S_OPEN_ALERT;
    if (
      data.users.includes(user_id) &&
      inModule &&
      !isDashboard &&
      !noAlert &&
      !isCustomer
    ) {
      data.alerts?.forEach((element) => {
        const { alert, user } = element;
        if (user.id === user_id) {
          store.commit("AutoAlertStore/ID_ALERT", alert.id);
          store.commit("AutoAlertStore/CLIENT_DATA", alert);
          store.commit("AutoAlertStore/OPEN_ALERT", true);
        }
      });
    }
  });
};

export const showToastAlert = () => {
  const { user_id } = store.getters["auth/currentUser"];

  window.socket.bind("show-toast-alert", async (data) => {
    const inModule = router.currentRoute.matched[0]?.meta?.module === 22;
    const isDashboard =
      router.currentRoute.matched[1]?.name === "ce-customer-service-dashboard";
    const isCustomer =
      router.currentRoute.matched[1]?.name ===
      "ce-customer-service-dashboard-general";
    if (
      inModule &&
      data.users.includes(user_id) &&
      !isDashboard &&
      !isCustomer
    ) {
      const timeLastToast = store.state.AutoAlertStore.S_TIME_LAST_TOAST;
      const noAlert = store.state.AutoAlertStore.S_OPEN_ALERT;
      const timeNow = new Date();
      const diff = timeNow - timeLastToast;
      const minutes = Math.floor(diff / 1000 / 60);
      if ((minutes > 1 && !noAlert) || (!timeLastToast && !noAlert)) {
        store.commit("AutoAlertStore/SHOW_TOAST", true);
        store.commit("AutoAlertStore/TIME_LAST_TOAST", new Date());
      }
    }
  });
};
