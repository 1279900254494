

export default [
    {
        path: "loans",
        name: "loans-paragon",
        redirect: { name: 'loans-module-ti' },
        component: () => import(/* webpackChunkName: "PRLoans" */ '@/views/commons/components/loans/LoansComponent.vue'),
        children: [
            {
                path: 'my-loans',
                name: "my-loans-paragon",
                component: () => import(/* webpackChunkName: "PRLoansMyLoans" */ '@/views/commons/components/loans/LoansView.vue'),
                meta: {
                    tab: 1,
                    pageTitle: "Loans",
                    breadcrumb: [
                        {
                            text: "My Loans",
                            active: true,
                        },
                    ],
                    route: "ti",
                }
            },
            {
                path: "loans-module",
                name: "loans-module-ti",
                component: () => import(/* webpackChunkName: "PRLoansModule" */ '@/views/commons/components/loans/LoansView.vue'),
                meta: {
                    tab: 2,
                    route: "ti",
                    pageTitle: "Loans",
                    breadcrumb: [
                        {
                            text: "Loans By Module",
                            active: true
                        }
                    ]
                }
            }
        ],
        meta: {
            tab: 1,
            route: "ti",
        }
    }
]