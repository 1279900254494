import store from '@/store';
import Notifications from '@/service/notification/index';
import NcrProgramsService from '@/views/commons/components/ncr-programs/service/ncr-programs.service';
import employeeClaims from '@/views/commons/commons-counter';
import RequestService from '@/views/commons/components/request/services/request.service.js';
import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';
import LettersRoundService from "@/views/specialists/sub-modules/analyst-department/views/letter-round/services/letters-rounds.service.js";
import PotentialService from "@/views/commons/components/potencial-appointment/services/potencial-appointments.service";
import OtherServicesService from "@/views/commons/components/other-services/service/appointment.service";
import AppointmentService from '@/views/commons/components/appointments/service/appointment.service';
import ApplicationService from '@/views/commons/components/applications/services/applications.service';

const ncrProgramsCounter = async () => {
  const params = {
    id: store.state.auth.currentUser.user_id,
    type: store.state.auth.currentUser.role_id <= 2 ? 1 : 2,
    program: 3, // Credit-Experts
    role: store.state.auth.currentUser.role_id,
    modul: store.state.auth.currentUser.modul_id,
  };
  const data = await NcrProgramsService.getCounterNcrPrograns(params);
  if (data.length > 0) {
    const payload = {
      routeName: 'cdigital-ncr-programs',
      tag: data[0].cont,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};
const ncrRealtorCounter = async () => {
  const params = {
    iduser: store.state.auth.currentUser.user_id,
    is_digital: 2,
    num: 1,
  };
  const data = await NcrProgramsService.getCounterNcrRealtor(params);
  if (data.length > 0) {
    const payload = {
      routeName: 'cdigital-ncr-realtor',
      tag: data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: 'request-customerservice',
    tag: data[0].counter,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'customer-service-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};
const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'customer-service-recruitment-process',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const { data } = await Notifications.getCountPendingCustomerTickets({ user_id });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: 'customer-service-customer-tickets',
      tag: data[0].v_total_open,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const returnedLettersCounter = async () => {
  const { data } = await LettersRoundService.getCounterReturnedLettersDepartment({
    moduleId: 22,
    isConnection: false,
  })

  // const value = data > 99 ? "99+" : data;

  const payload = {
    routeName: "csd-letters-dispute",
    tag: data,
  }

  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
}

const getPendingPotential = async () => {
  const { modul_id } = store.state.auth.currentUser;
  const data = await PotentialService.getCountPending({ module_id: modul_id });
  const payload = {
    routeName: "customerservice-potencial-appointment",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const getPendingOtherServices = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  const data = await OtherServicesService.getCountPending({
    module_id: modul_id,
    user_id,
    role_id,
  });
  const payload = {
    routeName: "customerservice-other-services",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};
const getPendingAppointment = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  const { pending } = await AppointmentService.getCountAppointmentsPending({
    module_id: modul_id,
    user_id,
    role_id,
  });
  const payload = {
    routeName: "customerservice-appointment",
    tag: pending,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};
const getPendingApplications = async () => {
  const { modul_id, user_id, role_id } = store.state.auth.currentUser;
  // let specialist_id = [1, 2].includes(role_id) || (role_id === 11 && modul_id === 6) ? null : user_id;
  let advisorId = ([1, 2, 17].includes(role_id) || (role_id === 11 && modul_id === 6) || [11, 25, 28, 29, 30].includes(modul_id)) ? null : user_id;
  const params = {
    user_id,
    role_id,
    program_id: 3,
    specialist_id: null,
    module_id: modul_id,
    advisor_id: advisorId,
  };
  const data = await ApplicationService.getCountPending(params);
  const payload = {
    routeName: "customerservice-applications",
    tag: data,
  };
  store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
};

const allCountersCeDigitalCustomerService = async () => {
  Promise.all([
    ncrProgramsCounter(),
    projectsCounter(),
    ncrRealtorCounter(),
    employeeClaims('cs-employee-claims'),
    requestNotAsignedCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
    returnedLettersCounter(),
    getPendingPotential(),
    getPendingOtherServices(),
    getPendingAppointment(),
    getPendingApplications(),
  ]);
};
export default allCountersCeDigitalCustomerService;
