// utils/moduleUtils.js
export function getModuleText(idModule) {
  const moduleTextMap = {
    1: 'users',
    2: 'crm',
    3: 'business',
    4: 'administration',
    5: 'debt-solution',
    6: 'credit-experts',
    7: 'boost-credit',
    8: 'tax-research',
    9: 'correspondence',
    10: 'court-info',
    11: 'specialists',
    12: 'paragon',
    13: 'recovery',
    14: 'bookeeping',
    15: 'social-network',
    16: 'management',
    17: 'rrhh',
    18: 'quality',
    19: 'logistic',
    20: 'connection',
    21: 'credit-experts',
    22: 'customer-service',
    23: 'financial',
    24: 'settings',
    25: 'specialists digital',
    26: 'sales',
    27: 'creative',
    28: 'analyst-department',
    29: 'financial-approval',
    30: 'accounts-resolution'
  };

  return moduleTextMap[idModule] || 'ceo';
}
