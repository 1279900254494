export default [
  {
    path: "helpdesk",
    name: "analyst-department-helpdesk",
    component: () =>
      import(
        /* webpackChunkName: "helpdesk" */
        "@/views/specialists/views/helpdesk/Helpdesk.vue"
      ),
    meta: {
      module: 28,
      route: "helpdesk",
      permittedRoles: [1, 2, 3, 8, 14, 15, 17],
      pageTitle: "Helpdesk",
      breadcrumb: [
        {
          text: "Helpdesk",
          active: true,
        },
      ],
    },
  },
];
