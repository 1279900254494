import { potencialTabRoute } from "@/views/commons/components/potencial-appointment/router/potencial.tab.route";

export default [
  {
    path: "potential",
    name: "connection-potencial-appointment",
    redirect: { name: "cn-potencial-appointment-in-process" },
    component: () => import("@/views/commons/components/potencial-appointment/views/PotencialMain"),
    meta: {
      pageTitle: "Potential",
      router: "cn-potencial-appointment",
      breadcrumb: [
        {
          text: "Potential",
          active: true,
        },
      ],
      routeInProcess: "cn-potencial-appointment-in-process",
      routeFinished: "cn-potencial-appointment-finished",
      routeCompleted: "cn-potencial-appointment-completed",
      routeRejected: "cn-potencial-appointment-rejected",
      routeExpired: "cn-potencial-appointment-expired",
      program: 3
    },
    children: potencialTabRoute("cn-potencial-appointment", "Potential")
  }
];