import store from "@/store";
import router from "@/router/index";
import Vue from "vue";
import moment from "moment";
import { swalInfoIcon } from "@/icons/statusIcons";

const BinnacleMessageNotification = () => {
    window.socket.bind("binnacle-alert-debtsolution-notifications", async (response) => {
        const { before_user_id, client_name, seller_id, user_name, message } = response.message;
        const user_id = store.state.auth.currentUser.user_id;
        let view_alert = false;

        if (before_user_id != null) {
            if (before_user_id == user_id) {
                view_alert = true;
            }
        } else {
            if (seller_id == user_id) {
                view_alert = true;
            }
        }
        if (view_alert) {
            const htmlAlert = `
                        <img src="/assets/images/icons/swal/message-icon.svg" style="margin-bottom: 10px; max-width:60px"> <br/>
                        <h2> New Message </h2>
                        SALE : <span style="font-weight: bold;">${client_name}</span> <br/> 
                        FROM: <span">${user_name}</span> <br /> 
                        <div style="margin-top:10px">
                            <span>Message: ${message}</span>.
                        </div>`;

            const result = await Vue.swal.fire({
                html: htmlAlert,
            });
        }
    });
}

const DerivedToSupervisorCrmNotification = () => {
    window.socket.bind("derived-to-supervisor-crm-notifications", async (response) => {

        const { client, state } = response.message;
        const role_id = store.state.auth.currentUser.role_id;
        const module_id = router.currentRoute.matched[0]?.meta?.module;
        let view_alert = false;
        if (module_id == 2) {
            if (role_id == 2) {
                view_alert = true;
            }
        }
        if (view_alert) {
            const htmlAlert = `
                        <h2> ${state == 11 ? 'Approved Sale Made' : 'Derived Sale Made'} </h2>
                        The lead <span style="font-weight: bold;">${client}</span> was <span style="font-weight: bold;">${state == 11 ? 'Approved' : 'Derived'}</span> by ${state == 11 ? 'Management' : 'DS Supervisor'} <br/> 
                        </span>`;

            const result = await Vue.swal.fire({
                imageUrl: swalInfoIcon,
                imageWidth: 70,
                html: htmlAlert,
            });
        }
    });
}

const DerivedToSupervisorDebtSolutionNotification = () => {
    window.socket.bind("derived-to-supervisor-debt-solution-crm-notifications", async (response) => {

        const { client, state } = response.message;
        const role_id = store.state.auth.currentUser.role_id;
        const module_id = router.currentRoute.matched[0]?.meta?.module;
        let view_alert = false;

        if (module_id == 5) {
            if (role_id == 2) {
                view_alert = true;
            }
        }

        if (view_alert) {
            const htmlAlert = `
                        <h2> ${state == 11 ? 'Approved Sale Made' : 'Derived Sale Made'} </h2>
                        The lead <span style="font-weight: bold;">${client}</span> was <span style="font-weight: bold;">${state == 11 ? 'Approved' : 'Derived'}</span> ${state == 11 ? 'by Management' : 'by CRM Seller'} <br/> 
                        </span>`;

            const result = await Vue.swal.fire({
                imageUrl: swalInfoIcon,
                imageWidth: 70,
                html: htmlAlert,
            });
        }
    });
}

export { BinnacleMessageNotification, DerivedToSupervisorCrmNotification, DerivedToSupervisorDebtSolutionNotification };
