export default {
  path: "dashboard",
  name: "financial-approval-dashboard",
  component: () =>
    import(
      /* webpackChunkName: "DashboardServicesFinancial" */ "@/views/specialists/sub-modules/financial-approval/views/dashboard/DashboardMain.vue"
    ),
  redirect: { name: 'dashboardNCR' },
  children: [
    {
      name: "dashboardNCR",
      path: "NCR",
      component: () =>
        import(
          /* webpackChunkName: "DashboardNCRFinancial" */ "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/DashboardNcr.vue"
        ),
      meta: {
        pageTitle: "Dashboard",
        parentModule: 11,
        module: 28,
        moduleId: 28,
        breadcrumb: [
          {
            text: "NCR",
            active: true,
          },
        ],
      },

    },
    {
      name: "dashboardServices",
      path: "Services",
      component: () =>
        import(
          /* webpackChunkName: "DashboardServicesFinancial" */ "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Dashboard.vue"
        ),
      meta: {
        pageTitle: "Dashboard",
        parentModule: 11,
        module: 28,
        moduleId: 28,
        breadcrumb: [
          {
            text: "Services",
            active: true,
          },
        ],
      },
    },
    {
      name: "dashboardAnalysisCr",
      path: "analysis-cr",
      redirect: { name: 'dashboardLead' },
      component: () =>
        import(
          /* webpackChunkName: "DashboardAnalysis" */ "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/DashboardAnalysis.vue"
        ),
      meta: {
        pageTitle: "Dashboard",
        parentModule: 11,
        module: 28,
        moduleId: 28,
        breadcrumb: [
          {
            text: "Analysis Cr",
            active: true,
          },
        ],
      },
      children: [
        {
          name: "dashboardLead",
          path: "Efficiency",
          component: () =>
            import(
              /* webpackChunkName: "DashboardEfficiency" */ "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/Efficiency/DashboardEfficiency.vue"
            ),
          meta: {
            pageTitle: "Dashboard",
            parentModule: 11,
            module: 28,
            moduleId: 28,
            breadcrumb: [
              {
                text: "Lead",
                active: true,
              },
            ],
            dashboardLead: 'dashboardLead',
            dashboardSettlement: 'dashboardSettlement',
            dashboardRemoved: 'dashboardRemoved'
          },
        },
        {
          name: "dashboardSettlement",
          path: "for-settlement",
          component: () =>
            import(
              /* webpackChunkName: "DashboardSettlement" */ "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/settlement/DashboardSettlement.vue"
            ),
          meta: {
            pageTitle: "Dashboard",
            parentModule: 11,
            module: 28,
            moduleId: 28,
            breadcrumb: [
              {
                text: "For Settlement",
                active: true,
              },
            ],
            dashboardLead: 'dashboardLead',
            dashboardSettlement: 'dashboardSettlement',
            dashboardRemoved: 'dashboardRemoved'
          },
        },
        {
          name: "dashboardRemoved",
          path: "for-removed",
          component: () =>
            import(
              /* webpackChunkName: "DashboardRemoved" */ "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/RemoveJourneyMain.vue"
            ),
          meta: {
            pageTitle: "Dashboard",
            parentModule: 11,
            module: 28,
            moduleId: 28,
            breadcrumb: [
              {
                text: "For Settlement",
                active: true,
              },
            ],
            dashboardLead: 'dashboardLead',
            dashboardSettlement: 'dashboardSettlement',
            dashboardRemoved: 'dashboardRemoved'
          },
        },
      ],
    },
    {
      name: "dashboardAutomaticPayments",
      path: "AutomaticPayments",
      component: () =>
        import(
      /* webpackChunkName: "DashboardAutomaticPaymentsFinancial" */ "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/DashboardAutomaticPayments.vue"
        ),
      meta: {
        pageTitle: "Dashboard",
        parentModule: 11,
        moduleId: 28,
        breadcrumb: [
          {
            text: "Automatic Payments",
            active: true,
          },
        ],
      },
    },
    {
      name: "dashboardExpenses",
      path: "expenses",
      component: () =>
        import(
      /* webpackChunkName: "DashboardExpensesFinancial" */ "@/views/commons/expenses/components/department/DepartmentExpenses.vue"
        ),
      meta: {
        pageTitle: "Dashboard",
        parentModule: 11,
        moduleId: 28,
        breadcrumb: [
          {
            text: "Expenses",
            active: true,
          },
        ],
      },
    },
  ],
};
