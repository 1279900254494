<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-header-close
      hide-header
      :body-class="`rounded`"
      no-close-on-backdrop
      size="summary"
      @hidden="isActive = false"
      centered
      :ok-only="true"
      ok-variant="success"
      hide-footer
    >
      <div v-if="dataNcr">
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-1 mb-2"
        >
          <div class="d-flex align-items-center" style="gap: 7px">
            <span class="title-alert font-strong">
              NCR {{ dataNcr.type_ncr }}</span
            >
          </div>
          <div
            class="close-modal"
            @click="closeModal()"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
          >
            <feather-icon icon="XIcon" size="16" />
          </div>
        </div>
        <div class="d-flex align-items-center mt-1">
          <div class="icon-badge mr-1" :class="badgeColor">
            <feather-icon :icon="iconTitle" size="20"></feather-icon>
          </div>
          <span>
            <span :class="colorText" class="text-uppercase"> {{ title }} </span>
          </span>
        </div>
        <hr />
        <div class="d-flex flex-column" style="gap: 15px">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              {{ dataNcr.client_or_lead == 1 ? "CLIENT" : "LEAD" }}
            </span>
            <span class="font-strong">{{ dataNcr.client }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="MapIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              DEPARTMENT
            </span>
            <span class="font-strong">{{
              nameModule(dataNcr.module_name)
            }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CHARGE
            </span>
            <span class="font-strong">$ {{ dataNcr.charge }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserCheckIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              REQUESTED BY
            </span>
            <span class="font-strong">{{ dataNcr.created_by }}</span>
          </div>
        </div>
      </div>
      <hr />
      <b-row>
        <b-col
          v-if="
            dataNcr.status == 'COMPLETED' &&
            ((moduleId == 22 && !isAgent) || moduleId != 22)
          "
        >
          <router-link
            class="text-whit btn btn-primary w-100 block"
            :to="{
              name: setRoute,
              params: setId,
            }"
            target="_blank"
          >
            OPEN DASHBOARD
          </router-link>
        </b-col>
        <b-col
          v-if="dataNcr.status != 'COMPLETED' || (moduleId == 22 && isAgent)"
        >
          <b-button
            class="w-100 block"
            target="_blank"
            variant="info"
            @click="goToNcr"
            >GO TO NCR</b-button
          >
        </b-col>
        <b-col>
          <b-button class="w-100 block" variant="success" @click="closeModal()"
            >OK</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AlertNcr",
  data() {
    return {
      detailNcr: null,
    };
  },
  computed: {
    ...mapGetters({
      G_IS_ACTIVE: "AutoAlertNcr/G_IS_ACTIVE",
      G_DATA_NCR: "AutoAlertNcr/G_DATA_NCR",
      title: "AutoAlertNcr/G_TITLE",
      currentUser: "auth/currentUser",
      clientDashboardRoutes: "clients-store/clientDashboardRoutes",
      leadDashboardRoutes: "clients-store/leadDashboardRoutes",
    }),

    isActive: {
      get() {
        return this.G_IS_ACTIVE;
      },
      set(value) {
        this.$store.commit("AutoAlertNcr/M_OPEN_ALERT", value);
        setTimeout(() => {
          this.dataNcr = null;
        }, 500);
      },
    },

    dataNcr: {
      get() {
        return this.G_DATA_NCR;
      },
      set(value) {
        this.$store.commit("AutoAlertNcr/M_DATA_NCR", value);
      },
    },

    moduleId() {
      return this.$route.matched[0].meta.module || this.currentUser.modul_id;
    },

    iconTitle() {
      const status = this.dataNcr?.status;
      let icons = {
        PENDING: "CheckIcon",
        ANNULLED: "XOctagonIcon",
        FAILED: "XOctagonIcon",
        "IN PROCESS": "InfoIcon",
        COMPLETED: "CheckIcon",
      };
      return icons[status] || "AlertTriangleIcon";
    },

    colorText() {
      const status = this.dataNcr?.status;
      let color = {
        PENDING: "text-success font-strong",
        ANNULLED: "text-danger font-strong",
        FAILED: "text-danger font-strong",
        "IN PROCESS": "text-warning font-strong",
        COMPLETED: "text-success font-strong",
      };
      return color[status] || "text-warning font-strong";
    },

    badgeColor() {
      const status = this.dataNcr?.status;
      let classes = {
        PENDING: "bg-light-success",
        ANNULLED: "bg-light-danger",
        FAILED: "bg-light-danger",
        "IN PROCESS": "bg-light-warning",
        COMPLETED: "bg-light-success",
      };
      return classes[status] || "bg-light-warning";
    },

    leadDashboardRouteName() {
      return this.leadDashboardRoutes[this.moduleId] || null;
    },

    clientDashboardRouteName() {
      return this.clientDashboardRoutes[this.moduleId] || null;
    },

    nameModuleRoute() {
      const routers = {
        2: "crm",
        3: "bu",
        5: "ds",
        6: "ce",
        7: "bc",
        8: "tr",
        10: "cdp",
        29: "sp", // mncr // 11: "sp",
        20: "cn",
        22: "csd",
      };
      return routers[this.moduleId] || null;
    },
    setId() {
      return this.dataNcr.client_or_lead == 1
        ? { idClient: this.dataNcr.client_account_id }
        : { id: this.dataNcr.lead_id };
    },

    setRoute() {
      return this.dataNcr.client_or_lead == 1
        ? this.clientDashboardRouteName
        : this.leadDashboardRouteName;
    },
  },
  methods: {
    ...mapActions({
      CHANGE_REFRESH_TABLE: "RequestNcrStore/CHANGE_REFRESH_TABLE",
    }),
    closeModal() {
      const channel = socket.channel("request-ncr-notifications", {});
      channel.trigger("client-close-all-ncr-notification", {
        message: "close all",
        user_id: this.currentUser.user_id,
      });
      this.isActive = false;
    },
    closeModalDetail() {
      this.detailNcr = null;
      this.isActive = false;
    },
    goToNcr() {
      this.closeModal();
      const subTabName = this.$route.name;
      const statusMap = {
        "IN PROCESS": "pending",
        PENDING: "pending",
        COMPLETED: "finished",
        FAILED: "finished",
        ANNULLED: "annulled",
      };
      const status = statusMap[this.dataNcr.status];
      const clientOrLead = this.dataNcr.client_or_lead;
      const targetTabName = `${this.nameModuleRoute}-ncr-${
        clientOrLead == 1 ? "client" : "lead"
      }-${status}`;

      if (subTabName == targetTabName) {
        this.CHANGE_REFRESH_TABLE(true);
      } else {
        this.$router.push({ name: targetTabName });
      }
    },

    nameModule(module) {
      if (module == "CUSTOMER SERVICE REGULAR") {
        return "CREDIT EXPERTS (REGULAR)";
      } else if (module == "CUSTOMER SERVICE DIGITAL") {
        return "CREDIT EXPERTS (DIGITAL)";
      } else {
        return module;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-badge {
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
}
.close-modal {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}
</style>
