export default {
  namespaced: true,
  state: {
    S_ID_PIECE_CREATIVE_DESIGN: 0,
    S_ID_TYPE_CREATIVE_DESIGN: 0,
    S_DESIGN_REQUEST_CHOSEN: {},
    S_FORM_CREATIVE_DESIGN: [], 
    S_MATERIAL_DATA:[],
    S_ID_MATERIAL_DATA: 0,
  },
  getters: {
    G_REFRESH_ID_PIECE_CREATIVE_DESIGN(state) {
      return state.S_ID_PIECE_CREATIVE_DESIGN;
    },
    G_REFRESH_ID_TYPE_CREATIVE_DESIGN(state) {
      return state.S_ID_TYPE_CREATIVE_DESIGN;
    },
    G_DESIGN_REQUEST_CHOSEN(state) {
      return state.S_DESIGN_REQUEST_CHOSEN;
    },
    G_FORM_DESIGN_REQUEST(state) {
      return state.S_FORM_CREATIVE_DESIGN;
    },
    G_REFRESH_MATERIAL_DATA(state){
      return state.S_MATERIAL_DATA;
    },
    G_ID_MATERIAL_DATA(state){
      return state.S_ID_MATERIAL_DATA;
    },



  },
  mutations: {
    M_REFRESH_ID_PIECE_CREATIVE_DESIGN(state, payload) {
      state.S_ID_PIECE_CREATIVE_DESIGN = payload;
    },
    M_REFRESH_ID_TYPE_CREATIVE_DESIGN(state, payload) {
      state.S_ID_TYPE_CREATIVE_DESIGN = payload;
    },
    M_DESIGN_REQUEST_CHOSEN(state, payload) {
      state.S_DESIGN_REQUEST_CHOSEN = payload;
    },
    M_FORM_DESIGN_REQUEST(state, payload) {
      state.S_FORM_CREATIVE_DESIGN = payload;
    },
    M_REFRESH_MATERIAL_DATA(state, payload) {
      state.S_MATERIAL_DATA = payload;
    },
    M_REFRESH_ID_MATERIAL_DATA(state, payload) {
      state.S_ID_MATERIAL_DATA = payload;
    },


  },
  actions: {
    A_REFRESH_ID_PIECE_CREATIVE_DESIGN({ commit }, params) {
      commit('M_REFRESH_ID_PIECE_CREATIVE_DESIGN', params);
    },
    A_REFRESH_ID_TYPE_CREATIVE_DESIGN({ commit }, params) {
      commit('M_REFRESH_ID_TYPE_CREATIVE_DESIGN', params);
    },
    A_DESIGN_REQUEST_CHOSEN({ commit }, params) {
      commit('M_DESIGN_REQUEST_CHOSEN', params);
    },
    A_FORM_DESIGN_REQUEST({ commit }, params) {
      commit('M_FORM_DESIGN_REQUEST', params);
    },
    A_REFRESH_MATERIAL_DATA({ commit }, params) {
      commit('M_REFRESH_MATERIAL_DATA', params);
    },
    A_REFRESH_ID_MATERIAL_DATA({ commit }, params) {
      commit('M_REFRESH_ID_MATERIAL_DATA', params);
    },

  },
};
