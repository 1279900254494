import AssistanceMain from '@/views/assistance/views/assistanceMain.vue';

export default [
  {
    path: '/management/attendance',
    name: 'management-dashboard-attendance',
    redirect: { name: 'management-attendance-dashboard' },
    component: AssistanceMain,
    children: [
      {
        path: 'chief-attendance-dashboard',
        name: 'management-chief-attendance-dashboard',
        component: () => import('@/views/assistance/views/dashboard/views/GraphicReport.vue'),
        meta: {
          pageTitle: 'Attendance',
          breadcrumb: [
            {
              text: 'My Department Attendance',
            },
          ],
          permittedRoles: [1, 2, 17],
          attendanceTolerance: 'management-attendance-tolerance',
          chiefDashboardRoute: 'management-chief-attendance-dashboard',
          attendanceDashboard: 'management-attendance-dashboard',
          attendanceHolidays: 'management-attendance-holidays',
          attendanceVacations: 'management-attendance-vacations',
          attendanceVacationsExistent: 'management-attendance-vacations-existent',
          attendanceVacationsDeleted: 'management-attendance-vacations-deleted',
          attendanceQrGenerator: 'management-attendance-qr-generator',
          attendanceEmployeesSchedule: 'management-attendance-employees-schedule',
          tabRole: 'chief',
          departmentalSchedules: 'management-departmental-schedules',
        },
      },
      {
        path: 'attendance-dashboard',
        name: 'management-attendance-dashboard',
        component: () => import('@/views/assistance/views/dashboard/views/GraphicReport.vue'),
        meta: {
          pageTitle: 'Attendance',
          breadcrumb: [
            {
              text: 'AMG Attendance',
            },
          ],
          permittedRoles: [1, 2, 17],
          attendanceTolerance: 'management-attendance-tolerance',
          chiefDashboardRoute: 'management-chief-attendance-dashboard',
          attendanceDashboard: 'management-attendance-dashboard',
          attendanceHolidays: 'management-attendance-holidays',
          attendanceVacations: 'management-attendance-vacations',
          attendanceVacationsExistent: 'management-attendance-vacations-existent',
          attendanceVacationsDeleted: 'management-attendance-vacations-deleted',
          attendanceQrGenerator: 'management-attendance-qr-generator',
          attendanceEmployeesSchedule: 'management-attendance-employees-schedule',
          tabRole: 'mng', // management
          departmentalSchedules: 'management-departmental-schedules',
        },
      },
      {
        path: 'holidays',
        name: 'management-attendance-holidays',
        component: () => import('@/views/assistance/views/dashboard/components/holidays/views/components/HolidaysComponent.vue'),
        meta: {
          pageTitle: 'Attendance',
          breadcrumb: [
            {
              text: 'Holidays',
            },
          ],
          permittedRoles: [1, 2, 17],
          attendanceTolerance: 'management-attendance-tolerance',
          chiefDashboardRoute: 'management-chief-attendance-dashboard',
          attendanceDashboard: 'management-attendance-dashboard',
          attendanceHolidays: 'management-attendance-holidays',
          attendanceVacations: 'management-attendance-vacations',
          attendanceVacationsExistent: 'management-attendance-vacations-existent',
          attendanceVacationsDeleted: 'management-attendance-vacations-deleted',
          attendanceQrGenerator: 'management-attendance-qr-generator',
          departmentalSchedules: 'management-departmental-schedules',
          attendanceEmployeesSchedule: 'management-attendance-employees-schedule',
        },
      },
      {
        path: 'vacations',
        name: 'management-attendance-vacations',
        component: () => import('@/views/assistance/views/dashboard/components/vacations/views/components/VacationsComponent.vue'),
        redirect: {
          name: 'management-attendance-vacations-existent',
        },
        meta: {
          pageTitle: 'Attendance',
          breadcrumb: [
            {
              text: 'Vacations',
            },
          ],
          permittedRoles: [1, 2, 17],
          attendanceTolerance: 'management-attendance-tolerance',
          chiefDashboardRoute: 'management-chief-attendance-dashboard',
          attendanceDashboard: 'management-attendance-dashboard',
          attendanceHolidays: 'management-attendance-holidays',
          attendanceVacations: 'management-attendance-vacations',
          attendanceVacationsExistent: 'management-attendance-vacations-existent',
          attendanceVacationsDeleted: 'management-attendance-vacations-deleted',
          attendanceQrGenerator: 'management-attendance-qr-generator',
          departmentalSchedules: 'management-departmental-schedules',
          attendanceEmployeesSchedule: 'management-attendance-employees-schedule',
        },
        children: [
          {
            path: 'existent',
            name: 'management-attendance-vacations-existent',
            component: () => import('@/views/assistance/views/dashboard/components/vacations/views/components/VacationsTab.vue'),
            meta: {
              pageTitle: 'Attendance',
              breadcrumb: [
                {
                  text: 'Existent Vacations',
                },
              ],
              attendanceTolerance: 'management-attendance-tolerance',
              chiefDashboardRoute: 'management-chief-attendance-dashboard',
              attendanceDashboard: 'management-attendance-dashboard',
              attendanceHolidays: 'management-attendance-holidays',
              attendanceVacations: 'management-attendance-vacations',
              attendanceVacationsExistent: 'management-attendance-vacations-existent',
              attendanceVacationsDeleted: 'management-attendance-vacations-deleted',
              attendanceQrGenerator: 'management-attendance-qr-generator',
              attendanceEmployeesSchedule: 'management-attendance-employees-schedule',
              idTab: 1,
              departmentalSchedules: 'management-departmental-schedules',
            },
          },
          {
            path: 'deleted',
            name: 'management-attendance-vacations-deleted',
            component: () => import('@/views/assistance/views/dashboard/components/vacations/views/components/VacationsTab.vue'),
            meta: {
              pageTitle: 'Attendance',
              breadcrumb: [
                {
                  text: 'Deleted Vacations',
                },
              ],
              attendanceTolerance: 'management-attendance-tolerance',
              chiefDashboardRoute: 'management-chief-attendance-dashboard',
              attendanceDashboard: 'management-attendance-dashboard',
              attendanceHolidays: 'management-attendance-holidays',
              attendanceVacations: 'management-attendance-vacations',
              attendanceVacationsExistent: 'management-attendance-vacations-existent',
              attendanceVacationsDeleted: 'management-attendance-vacations-deleted',
              attendanceQrGenerator: 'management-attendance-qr-generator',
              attendanceEmployeesSchedule: 'management-attendance-employees-schedule',
              idTab: 2,
              departmentalSchedules: 'management-departmental-schedules',
            },
          },
        ],
      },
      {
        path: 'qr-generator',
        name: 'management-attendance-qr-generator',
        component: () => import('@/views/assistance/views/qr-generator/QrGenerator.vue'),
        meta: {
          pageTitle: 'Attendance',
          breadcrumb: [
            {
              text: 'QR Generator',
            },
          ],
          permittedRoles: [1, 2, 17],
          attendanceTolerance: 'management-attendance-tolerance',
          chiefDashboardRoute: 'management-chief-attendance-dashboard',
          attendanceDashboard: 'management-attendance-dashboard',
          attendanceHolidays: 'management-attendance-holidays',
          attendanceVacations: 'management-attendance-vacations',
          attendanceVacationsExistent: 'management-attendance-vacations-existent',
          attendanceVacationsDeleted: 'management-attendance-vacations-deleted',
          attendanceQrGenerator: 'management-attendance-qr-generator',
          departmentalSchedules: 'management-departmental-schedules',
          attendanceEmployeesSchedule: 'management-attendance-employees-schedule',
        },
      },
      {
        path: 'tolerance',
        name: 'management-attendance-tolerance',
        component: () => import(/* webpackChunkName: "ManagementSettings" */ '@/views/management/views/settings/Settings.vue'),
        meta: {
          pageTitle: 'Attendance',
          breadcrumb: [
            {
              text: 'Tolerance',
            },
          ],
          permittedRoles: [1, 2, 17],
          attendanceTolerance: 'management-attendance-tolerance',
          chiefDashboardRoute: 'management-chief-attendance-dashboard',
          attendanceDashboard: 'management-attendance-dashboard',
          attendanceHolidays: 'management-attendance-holidays',
          attendanceVacations: 'management-attendance-vacations',
          attendanceVacationsExistent: 'management-attendance-vacations-existent',
          attendanceVacationsDeleted: 'management-attendance-vacations-deleted',
          attendanceQrGenerator: 'management-attendance-qr-generator',
          departmentalSchedules: 'management-departmental-schedules',
          attendanceEmployeesSchedule: 'management-attendance-employees-schedule',
        },
      },

      {
        path: 'schedule',
        name: 'management-attendance-employees-schedule',
        component: () => import(/* webpackChunkName: "ManagementEmployeesSchedule" */ '@/views/assistance/views/employees-schedule/EmployeesSchedule.vue'),
        meta: {
          pageTitle: 'Attendance',
          breadcrumb: [
            {
              text: 'Schedule',
            },
          ],
          permittedRoles: [1, 2, 17],
          attendanceTolerance: 'management-attendance-tolerance',
          chiefDashboardRoute: 'management-chief-attendance-dashboard',
          attendanceDashboard: 'management-attendance-dashboard',
          attendanceHolidays: 'management-attendance-holidays',
          attendanceVacations: 'management-attendance-vacations',
          attendanceVacationsExistent: 'management-attendance-vacations-existent',
          attendanceVacationsDeleted: 'management-attendance-vacations-deleted',
          attendanceQrGenerator: 'management-attendance-qr-generator',
          departmentalSchedules: 'management-departmental-schedules',
          attendanceEmployeesSchedule: 'management-attendance-employees-schedule',
        },
      },

      {
        path: 'departmental-schedules',
        name: 'management-departmental-schedules',
        component: () => import('@/views/assistance/views/dashboard/components/departamentalSchedules/views/components/DepartamentalSchedulesComponent.vue'),
        meta: {
          pageTitle: 'Attendance',
          breadcrumb: [
            {
              text: 'Departamental Schedules',
            },
          ],
          permittedRoles: [1, 2, 17],
          attendanceTolerance: 'management-attendance-tolerance',
          chiefDashboardRoute: 'management-chief-attendance-dashboard',
          attendanceDashboard: 'management-attendance-dashboard',
          attendanceHolidays: 'management-attendance-holidays',
          attendanceVacations: 'management-attendance-vacations',
          attendanceVacationsExistent: 'management-attendance-vacations-existent',
          attendanceVacationsDeleted: 'management-attendance-vacations-deleted',
          attendanceQrGenerator: 'management-attendance-qr-generator',
          departmentalSchedules: 'management-departmental-schedules',
          attendanceEmployeesSchedule: 'management-attendance-employees-schedule',
        },
      },
    ],
  },
];
