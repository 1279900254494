<template>
  <b-modal
    ref="modalName"
    modal-class="modal-primary"
    title-class="h4 text-white"
    title="Detail Request Fidelized"
    size="lg"
    @hidden="closeModal()"
    hide-footer
    centered
    no-close-on-backdrop
  >
    <b-table
      ref="detailTable"
      class="blue-scrollbar position-relative table-new-customization"
      :items="myProvider"
      :fields="Fields"
      :busy="isBusy"
      primary-key="id"
      responsive="md"
      sticky-header="50vh"
      show-empty
      no-provider-filtering
      :per-page="FiltersForSlot.paginate.perPage"
      :current-page="FiltersForSlot.paginate.currentPage"
    >
      <template #cell(created_at)="data">
        {{ data.item.created_at | myGlobalDay }}
      </template>
      <template #cell(status)="data">
        <b-badge :variant="badgeColor(data.item.status)">
          {{ data.item.status }}
        </b-badge>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import CustomerTicketsService from "@/views/commons/components/customer-tickets/services/customer-tickets.service";
export default {
  props: {
    ticketId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      Fields: [
        { label: "Status", key: "status" },
        { label: "Description", key: "description" },
        { label: "Created By", key: "created_by" },
        { label: "Created At", key: "created_at" },
      ],
      FiltersForSlot: {
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name...",
          model: "",
        },
      },
      isBusy: false,
    };
  },
  mounted() {
    this.toggleModal("modalName");
  },
  created() {},
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    badgeColor(status) {
      const color = {
        REQUEST: "primary",
        RETURN: "warning",
      };
      return color[status] || "success";
    },
    async myProvider() {
      const { data } = await CustomerTicketsService.getDetailsTicketFidelized({
        ticketId: this.ticketId,
      });
      return data.data || [];
    },
  },
};
</script>