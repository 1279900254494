<template>
  <b-modal
    v-model="onControl"
    title="ALL NOTES"
    title-tag="h3"
    modal-class="custom-modal-amg"
    title-class="h3 text-white font-weight-bolder"
    header-class="p-0"
    header-bg-variant="transparent"
    size="xlg"
    hide-footer
    scrollable
    @hidden="close"
    no-enforce-focus
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <span>ALL NOTES</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <b-container>
      <div class="header-card-call" :class="{ dark: isDarkSkin }">
        <div class="d-flex p-2 justify-content-between gap-10 w-100">
          <div class="header-card-call-child" :class="{ dark: isDarkSkin }">
            <div class="header-card-call-child-name">
              Client | {{ nameClient }}
            </div>
          </div>
          <div class="header-card-call-child" :class="{ dark: isDarkSkin }">
            <div class="header-card-call-child-name">Year | {{ year }}</div>
          </div>
        </div>
      </div>
      <div
        style="position: relative; margin: 0 20px; min-height: 250px"
        :key="key"
      >
        <b-tabs
          class="mt-2"
          lazy
          active-tab-class="p-0"
          pills
          nav-class="mb-0"
          active-nav-item-class="bg-info box-shadow-info border-info info"
        >
          <template>
            <b-tab
              v-for="tab in tabs"
              @click="onChangeTabs(tab)"
              :key="tab.type_id"
              :title-link-class="[bgTabsNavs, 'h-full sub-tab px-3']"
            >
              <template #title>
                {{ tab.type == "COMMENTARY" ? "COMMENTS" : tab.type }}
                <span class="ml-2">
                  <feather-icon
                    icon
                    :badge="
                      getTabCounter(tab.type) > 99
                        ? '99+'
                        : getTabCounter(tab.type)
                    "
                    badge-classes="badge-danger badge-glow"
                  />
                </span>
              </template>

              <div class="card-note-cmp" :class="{ dark: isDarkSkin }">
                <div
                  v-for="note in notesShow"
                  :key="note.id"
                  class="rounded card-note"
                  :class="
                    isDarkSkin ? 'note-card-note-dark' : 'note-card-note-light'
                  "
                  style="position: relative"
                >
                  <div
                    v-if="
                      (tab.type == 'CONVERSATION' ||
                        tab.type == 'NEGOTIATION') &&
                      note.sentiment
                    "
                    class="sentiment d-flex justify-content-start m-0"
                    :style="{
                      background:
                        getColorSentiment(note.sentiment_id) + '22 !important',
                      color:
                        getColorSentiment(note.sentiment_id) + ' !important',
                    }"
                  >
                    <span v-if="note.sentiment" class=""
                      >Sentiment : {{ note.sentiment }}</span
                    >
                    <div class="pl-1 d-flex align-self-center">
                      <feather-icon
                        :id="`details_sentiment_notes_accounts` + note.id"
                        icon="InfoIcon"
                        size="15"
                        class="cursor-pointer"
                      />

                      <b-tooltip
                        :target="`details_sentiment_notes_accounts` + note.id"
                        triggers="hover"
                        placement="top"
                      >
                        <div class="w-100 d-flex justify-content-start">
                          <div class="d-flex flex-column">
                            <span class="text-primary text-weight-bolder"
                              >· Neutral : {{ note.sentiment_neutral }} %
                            </span>
                            <span class="text-success text-weight-bolder"
                              >· Positive : {{ note.sentiment_positive }} %
                            </span>
                            <span class="text-danger text-weight-bolder"
                              >· Negative : {{ note.sentiment_negative }} %
                            </span>
                            <span class="text-info text-weight-bolder"
                              >· Mixed : {{ note.sentiment_mixed }} %
                            </span>
                          </div>
                        </div>
                      </b-tooltip>
                    </div>
                  </div>

                  <div class="sentiment-right d-flex justify-content-end">
                    <b-button
                      v-if="
                        (isCeo || isChief || isSupervisor) &&
                        (tab.type == 'CONVERSATION' ||
                          tab.type == 'NEGOTIATION') &&
                        validateCalls(note.calls)
                      "
                      size="sm"
                      variant="outline-primary"
                      @click="sendToTranscribe(note)"
                      :disabled="note.transcription_status == 'IN_PROGRESS'"
                    >
                      Regenerate Summary
                    </b-button>
                    <b-button
                      v-if="note.versions > 0"
                      class="ml-1"
                      size="sm"
                      variant="outline-primary"
                      @click="showAllNotesVersionModal(note)"
                    >
                      <feather-icon
                        icon="ListIcon"
                        v-b-tooltip.hover
                        title="Show previous notes"
                      />
                    </b-button>
                  </div>

                  <div
                    class="clearfix mb-2"
                    :class="note.sentiment ? 'mt-3' : ''"
                  >
                    <div class="options" v-if="note.note_status == 'DRAFT'">
                      <b-badge variant="warning" class="text-black">
                        {{ note.note_status }}
                      </b-badge>
                      <!-- dots -->
                      <b-dropdown
                        size="xs"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template #button-content>
                          <div class="container-actions">
                            <feather-icon
                              class="cursor-pointer text-secondary"
                              v-b-tooltip.hover
                              title="More options"
                              icon="MoreVerticalIcon"
                            />
                          </div>
                        </template>
                        <b-dropdown-item @click="openEditNoteModal(note)">
                          <feather-icon icon="Edit2Icon" class="text-primary" />
                          <span class="text-primary"> Edit Note </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>

                    <div
                      class="comment-vhtml"
                      v-html="
                        note.change_original ? note.original_text : note.content
                      "
                    ></div>
                    <!-- <div
                      class="d-flex justify-content-end"
                      v-if="note.original_text != '' && note.original_text"
                    >
                      <b-button
                        v-if="isCeo"
                        variant="outline-primary"
                        style="margin-top: 10px"
                        size="sm"
                        @click="note.change_original = !note.change_original"
                      >
                        Show {{ note.change_original ? "" : "original" }} note
                      </b-button>
                    </div> -->
                  </div>

                  <div
                    v-if="
                      tab.type == 'CONVERSATION' || tab.type == 'NEGOTIATION'
                    "
                  >
                    <div v-if="note.queue_status == 'PENDING'">
                      <feather-icon
                        size="15"
                        icon="LoaderIcon"
                        class="text-warning"
                      />
                      <span class="pl-1" style="font-weight: 400"
                        >Call in Queue</span
                      >
                    </div>
                    <div v-else class="d-flex justify-content-start">
                      <div class="d-flex">
                        <div class="align-self-center">
                          <feather-icon size="15" icon="PhoneCallIcon" />
                        </div>
                        <div class="pl-1 d-flex" style="font-weight: 600">
                          <span
                            class="align-self-center"
                            style="font-size: 15px"
                            >Call Record:</span
                          >
                          <div
                            class="pl-1"
                            v-if="
                              validateCalls(note.calls) &&
                              note.transcription_status != null
                            "
                          >
                            <b-button
                              class="d-flex"
                              style="
                                padding: 5px !important;
                                border-radius: 30px;
                                border: 0;
                              "
                              :style="{
                                background:
                                  getStyleButtonTranscription(
                                    note.transcription_status
                                  )[1] + ' !important',
                              }"
                              @click="
                                note.transcription_status == 'PENDING' &&
                                note.calls.length > 0
                                  ? generateTranscribe(
                                      note.id,
                                      note.calls[0].route_file
                                    )
                                  : false
                              "
                            >
                              <div
                                class="circle"
                                :style="{
                                  background:
                                    note.transcription_status == 'PENDING'
                                      ? '#fff !important'
                                      : '#000 !important',
                                }"
                              >
                                <img
                                  v-if="
                                    note.transcription_status == 'COMPLETED'
                                  "
                                  src="@/assets/images/icons/check.svg"
                                  alt="image"
                                />
                                <img
                                  v-if="
                                    note.transcription_status == 'IN_PROGRESS'
                                  "
                                  src="@/assets/images/icons/rotate.svg"
                                  alt="image"
                                  class="spinner-transcription"
                                />
                              </div>
                              <span
                                class="align-self-center"
                                style="
                                  font-size: 12px;
                                  margin-left: 5px !important;
                                  margin-right: 5px !important;
                                "
                              >
                                {{
                                  getStyleButtonTranscription(
                                    note.transcription_status
                                  )[0]
                                }}
                              </span>
                            </b-button>
                          </div>
                          <span style="font-weight: 400">{{
                            validateCalls(note.calls) ? "" : "No record"
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="validateCalls(note.calls)">
                      <div
                        v-for="(audio, index) in note.calls"
                        :key="audio.record"
                      >
                        <new-wave-surfer-player
                          :url="audio.record"
                          :options="audioOptions"
                          :wave-surfer-id="`wave-surfer-${index}${note.id}`"
                          :show-duration="true"
                          :elapsed-time-width="95"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    class="d-flex justify-content-start mt-1"
                    v-if="note.url_file"
                  >
                    <div class="d-flex">
                      <div>
                        <feather-icon size="15" icon="PhoneCallIcon" />
                      </div>
                      <div class="pl-1" style="font-weight: 600">
                        File:
                        <span
                          style="font-weight: 400"
                          class="text-primary clickable"
                          @click="downloadNoteFile(note)"
                          >{{ note.file_name }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="isDarkSkin"
                    style="
                      border: 1px solid rgb(241, 239, 239);
                      margin-bottom: 12px;
                      margin-top: 5px;
                    "
                  />

                  <div
                    v-else
                    style="
                      border: 1px solid rgba(231, 235, 246, 1);
                      margin-bottom: 12px;
                      margin-top: 5px;
                    "
                  />

                  <div class="d-flex justify-content-between mt-1">
                    <div style="font-size: 13px">
                      {{ note.created_at | myDateGlobalWithHour }}
                    </div>
                    <div style="font-size: 13px">
                      <b-avatar
                        :src="note.user_thumb"
                        size="1.5rem"
                        variant="light-primary"
                        class="mr-1"
                      />
                      {{ note.user_name }}
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </template>
        </b-tabs>
        <div v-if="!isLoading && allNotes.length == 0" class="text-center my-2">
          <strong>There are no notes to show</strong>
        </div>
        <div v-if="isLoading" class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
        <div class="absolute-linker" :class="{ dark: isDarkSkin }">
          <div
            class="d-flex justify-content-between align-items-center h-full px-1"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
              class="cursor-pointer"
              @click="prev"
            />
            <span>{{ months[month - 1] }} {{ year }}</span>
            <feather-icon
              icon="ChevronRightIcon"
              size="20"
              class="cursor-pointer"
              @click="next"
            />
          </div>
        </div>
      </div>
    </b-container>
    <add-notes-modal
      v-if="addNotesModalController"
      :note-data="noteToSend"
      :name-client="nameClient"
      :editing="isEditing"
      @close="closeEditNoteModal"
      @reload="getAllNotes"
    />
    <notes-version-modal
      v-if="showNotesVersionModal"
      :note-id="noteId"
      @close="showNotesVersionModal = false"
      :reload="reload"
    />
  </b-modal>
</template>

<script>
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import AddNotesModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/AddNotesModal.vue";
import NewWaveSurferPlayer from "@/views/management/views/approval-process/settlement-approval/components/NewWaveSurferPlayer.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import NotesVersionModal from "@/views/commons/components/clients/dashboard/options/task-notes-module/modals/NotesVersionModal.vue";

export default {
  components: {
    AddNotesModal,
    NewWaveSurferPlayer,
    NotesVersionModal,
  },
  props: {
    idAccount: null,
    month: null,
    year: null,
    nameClient: null,
  },
  data() {
    return {
      onControl: false,
      isEditing: false,
      noteToSend: [],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      allNotes: [],
      addNotesModalController: false,
      currentYear: "",
      currentMonth: "",
      isLoading: false,
      edit: true,
      tabs: [],
      currentNotesTypeId: null,
      notesShow: [],
      changeOriginal: false,
      key: 0,
      audioOptions: {
        waveColor: "#99989A",
        progressColor: "#0077E6",
        barWidth: 3,
        barRadius: 3,
        cursorWidth: 0,
        height: 20,
        barGap: 2,
        backend: "MediaElement",
        minPxPerSec: 0,
        normalizeToZero: true,
        waveOutlineWidth: 0,
        barMinHeight: 2,
      },
      noteId: null,
      showNotesVersionModal: false,
      reload: 0,
    };
  },
  async created() {
    this.bindUpdateAutomaticNotes();
    this.onControl = true;
    this.currentYear = moment().format("YYYY");
    this.currentMonth = moment().format("M");
    await this.getAllNotes();
  },
  beforeDestroy() {
    try {
      window.socket.unbind("update-manual-notes");
    } catch (error) {
      console.log("update-manual-notes", error);
    }
  },
  mounted() {
    console.log("mounted");
  },
  methods: {
    async sendToTranscribe(note) {
      try {
        const { isConfirmed, value } = await this.showSimpleInputSwal({
          input: "text",
          title: "Why do you want to regenerate the summary?",
          inputPlaceholder: "Ex: The summary is not correct",
        });

        if (!isConfirmed) return;

        this.addPreloader();

        const { data } = await ClientsOptionsServices.sendToTranscribe({
          note_id: note.id,
          reason: value,
          created_by: this.currentUser.user_id,
        });

        this.showTimedToast({
          title: data.message,
          timeout: 5000,
        });
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async showAllNotesVersionModal(note) {
      try {
        this.showNotesVersionModal = true;
        this.noteId = note.id;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async bindUpdateAutomaticNotes() {
      try {
        window.socket.bind("update-manual-notes", async ({ data }) => {
          const found = this.allNotes.some((tab) =>
            tab.notes.some((note) => note.id === data.note_id)
          );
          if (found) {
            const noteIndex = this.notesShow.findIndex(
              (note) => note.id === data.note_id
            );

            if (noteIndex !== -1) {
              let note_data = await this.getNoteById({ note_id: data.note_id });

              if (note_data.length > 0) {
                let note = note_data[0];
                note.change_original = false;
                console.log("note", note);
                this.$set(this.notesShow, noteIndex, note);
                this.reload = this.reload + 1;
                console.log("updated");
              }
            }
          }
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    close() {
      this.$emit("close");
    },
    validateCalls(calls) {
      if (calls.length > 0) {
        return !calls.every((call) => call.call_status === "CALL NOT FOUND");
      } else {
        return false;
      }
    },
    async next() {
      if (this.month < 12) {
        this.month++;
      } else {
        this.month = 1;
        this.year++;
      }
      await this.getAllNotes();
    },
    async prev() {
      if (this.month > 1) {
        this.month--;
      } else {
        this.month = 12;
        this.year--;
      }
      await this.getAllNotes();
    },
    getTabCounter(type) {
      return (
        this.allNotes.find((item) => item.type === type)?.notes?.length || 0
      );
    },
    onChangeTabs(tab) {
      this.currentNotesTypeId = tab.type_id;
      const index = this.tabs.findIndex(
        (item) => item.type_id === this.currentNotesTypeId
      );
      this.notesShow = this.allNotes[index].notes;
    },
    async getNoteById(id) {
      try {
        const { data } = await ClientsOptionsServices.getAllNotesById(id);
        return data;
      } catch (e) {
        this.showErrorSwal(e);
      }
    },
    async getAllNotes() {
      this.allNotes = [];
      this.notesShow = [];
      this.tabs = [];
      this.currentNotesTypeId = null;
      this.key++;
      try {
        this.isLoading = true;
        const { data } = await ClientsOptionsServices.getAllNotes({
          account: this.$route.params.idClient
            ? this.$route.params.idClient
            : this.idAccount,
          year: this.year ? this.year : null,
          month: this.month ? this.month : null,
          user_id: this.currentUser.user_id,
          module_id: this.moduleId,
        });
        const currentNotes = data;

        if (currentNotes.length > 0) {
          this.tabs = currentNotes.map((item) => ({
            type: item.type,
            type_id: item.type_id,
          }));

          if (this.tabs.length > 0) {
            this.allNotes = data;

            this.currentNotesTypeId =
              this.currentNotesTypeId == null
                ? this.allNotes[0].type_id
                : this.currentNotesTypeId;

            const index = this.tabs.findIndex(
              (item) => item.type_id === this.currentNotesTypeId
            );

            this.notesShow = this.allNotes[index].notes;

            this.notesShow.forEach((element) => {
              if (element.content != null) {
                element.content = element.content.replaceAll(
                  "<img",
                  "<img style='max-width: 100%;'"
                );
              }

              // replace ** with <b> and </b>
              // element.content = element.content.replaceAll("**", "<b>");
              // element.content = element.content.replaceAll("**", "</b>");
            });
          }
        }
        this.isLoading = false;
      } catch (e) {
        this.isLoading = true;
        this.showErrorSwal(e);
      }
    },
    openEditNoteModal(note) {
      this.noteToSend = note;
      this.isEditing = true;
      this.addNotesModalController = true;
    },
    closeEditNoteModal() {
      this.noteToSend = {};
      this.isEditing = false;
      this.addNotesModalController = false;
    },
    async downloadNoteFile(item) {
      try {
        this.addPreloader();
        if (item.record_url != null && item.record_url != "") {
          window.open(item.record_url);
          return;
        }
        const data = await ClientsOptionsServices.downloadNoteFile({
          route: item.url_file,
          file: item.file_name,
        });
        window.open(data.data);
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    editNote() {
      this.edit = !this.edit;
    },
    async generateTranscribe(id, route) {
      try {
        this.addPreloader();
        const data = await ClientsOptionsServices.generateTranscribe({
          jobName: id,
          filePath: route,
        });

        if (data.status === 200) {
          this.$toast.success("generate transcribe successfully");
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
        await this.getAllNotes();
      }
    },
    getStyleButtonTranscription(status) {
      switch (status) {
        case "PENDING":
          return ["PENDING TRANSCRIPTION", "#FF8D20"];
        case "IN_PROGRESS":
          return ["TRANSCRIPTION IN PROCESS", "#0090E7"];
        case "COMPLETED":
          return ["COMPLETE TRANSCRIPTION", "#00D25B"];
        default:
          null;
      }
    },
    getSrcTranscription(status) {
      switch (status) {
        case "IN_PROGRESS":
          return "@/assets/images/icons/rotate.svg";
        case "COMPLETED":
          return "@/assets/images/icons/check.svg";
      }
    },
    getColorSentiment(sentiment) {
      switch (sentiment) {
        case 1:
          return "#0090E7";
        case 2:
          return "#00D25B";
        case 3:
          return "#F10000";
        case 4:
          return "#6E3CCD";
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    onlyRead() {
      return this.moduleId === 18;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
.note-card-note-light {
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  margin: 10px 20px;
  padding: 10px 35px;
  width: 100%;
}

.note-card-note-dark {
  margin: 0 auto;
  margin: 10px 20px;
  padding: 10px 35px;
  width: 100%;
  background: #ffffff0f;
}

.card-note-cmp {
  padding: 10px 25px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fafafa;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-height: 50rem;
  overflow-y: auto;
  overflow-x: hidden;
  &.dark {
    background: #242425;
  }
}

.header-card-call {
  margin: 0 20px;
  height: 7rem;
  display: flex;
  align-items: center;
  background: #fafafa;
  border-radius: 5px;
  &.dark {
    background: #242425;
  }
}

.header-card-call {
  margin: 0 20px;
  height: 7rem;
  display: flex;
  align-items: center;
  background: #fafafa;
  border-radius: 5px;
  &.dark {
    background: #242425;
  }
}
.header-card-call-child {
  width: 49%;
  border-radius: 5px;
  border: 1.2px solid #434245;
  &.dark {
    border: 1.2px solid #e0e0e0;
  }
  text-align: center;
}

.header-card-call-child-name {
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 15px;
}

.absolute-linker {
  position: absolute;
  width: 16rem;
  height: 37px;
  background: #fafafa;
  top: 0;
  right: 0;
  background: #fafafa;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  &.dark {
    background: #242425;
  }
}
.clearfix {
  position: relative;
  .options {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.spinner-transcription {
  display: inline-block;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.sentiment {
  padding: 10px 40px 10px 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 5px 20px 20px 0px;
  font-family: "Montserrat";
  font-weight: 900;
}

.sentiment-right {
  position: relative;
}
</style>

<style>
.comment-vhtml > p {
  margin: 0 !important;
}

.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.swal2-custom-container {
  z-index: 9999 !important;
}
</style>
