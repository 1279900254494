<template>
  <div @scroll="loadMoreContacts" class="container-contatcs-pa">
    <div class="container-contacts">
      <div class="search-chat-top py-2">
        <div class="search-new-contact">
          <b-form-input
            type="text"
            class="custom-input"
            v-model="searchTxt"
            @keydown.enter="getFilterMessages"
            placeholder="Search Lead, Text"
          />
        </div>
        <div class="container-filters mt-1">
          <div
            class="filter-v2"
            @click="changeTabFilter('INTERACTED')"
            :class="
              optionsAll.includes(G_CURRENT_TAB_CONTACTS)
                ? 'active-filter-v2'
                : ''
            "
          >
            ALL
          </div>
          <div
            class="filter-v2"
            style="position: relative"
            @click="changeTabFilter('unread')"
            :class="
              G_CURRENT_TAB_CONTACTS == 'unread' ? 'active-filter-v2' : ''
            "
          >
            UNREAD
            <span
              class="badge badge-up badge-pill badge-important cursor-pointer"
              v-if="G_COUNTER_MESSAGES_NOT_READ_PHONE != 0"
            >
              {{ G_COUNTER_MESSAGES_NOT_READ_PHONE }}</span
            >
          </div>
          <div
            class="filter-v2"
            @click="activeStatusTab()"
            :class="G_LIST_ACTIVE ? 'active-filter-v2' : ''"
          >
            LISTS
          </div>
        </div>

        <div
          class="container-filters mt-1"
          v-if="optionsAll.includes(G_CURRENT_TAB_CONTACTS)"
        >
          <div
            class="filter-v2"
            @click="changeTabFilter('INTERACTED')"
            :class="
              G_CURRENT_TAB_CONTACTS == 'INTERACTED' ? 'active-filter-v2' : ''
            "
          >
            INTERACTED
          </div>
          <div
            class="filter-v2"
            @click="changeTabFilter('NOT INTERACTED')"
            :class="
              G_CURRENT_TAB_CONTACTS == 'NOT INTERACTED'
                ? 'active-filter-v2'
                : ''
            "
          >
            NOT INTERACTED
          </div>
        </div>
      </div>

      <div>
        <div
          v-if="searchTxt != '' && searchTxt && chatsByCredential.length != 0"
          class="pl-2 py-1 title-filter"
        >
          LEADS
        </div>

        <div
          class="contact"
          v-for="item in chatsByCredential"
          :key="item._id"
          @click="setCurrentChat(item)"
          :class="setActiveStatus(item)"
        >
          <div class="d-flex align-items-center w-100">
            <div class="w-100">
              <b-row>
                <b-col cols="9">
                  <div
                    class="lead-name w-100 text-truncate"
                    style="margin-bottom: 5px"
                  >
                    <feather-icon
                      v-if="item.recycledBy"
                      icon="ArchiveIcon"
                      class="text-warning"
                      style="margin-right: 4px"
                      v-b-tooltip.hover
                      title="In Recycle"
                    />

                    <span
                      v-if="item.leadName"
                      class="text"
                      v-html="highlightMatchingText(item.leadName)"
                    ></span>

                    <feather-icon
                      v-if="!item.leadId"
                      icon="AlertCircleIcon"
                      class="text-warning"
                      style="margin-right: 4px"
                      v-b-tooltip.hover
                      title="Without lead"
                    />

                    <span
                      v-if="item.leadPhoneNumber"
                      class="text"
                      style="margin-left: 2px"
                      v-html="highlightMatchingText(item.leadPhoneNumber)"
                    ></span>
                  </div>

                  <div v-if="item.lastMessage">
                    <div v-if="item.lastMessage.type == 'SMS'">
                      <div
                        class="w-100 text-truncate"
                        v-if="
                          item.lastMessage.subject &&
                          item.lastMessage.subject != ''
                        "
                      >
                        {{ item.lastMessage.subject }}
                      </div>

                      <div
                        v-if="item.lastMessage.attachments.length > 0"
                        class="d-flex align-items-center"
                      >
                        <feather-icon
                          v-if="
                            item.lastMessage.attachments[0].contentType.startsWith(
                              'image/'
                            )
                          "
                          icon="ImageIcon"
                        ></feather-icon>
                        <span style="margin-left: 4px" class="text-truncate">
                          Attachment
                        </span>
                      </div>
                    </div>

                    <div
                      v-if="item.lastMessage.type == 'VoiceMail'"
                      class="d-flex align-items-center"
                    >
                      <feather-icon
                        icon="VoicemailIcon"
                        style="margin-right: 5px"
                      />
                      Voice message
                      <span style="margin-left: 5px"
                        >({{
                          convertSecondsShort(
                            item.lastMessage.attachments[0].vmDuration
                          )
                        }})</span
                      >
                    </div>

                    <div v-if="item.lastMessage.type == 'Fax'">
                      <feather-icon icon="FileIcon" />
                      <span style="margin-left: 4px">
                        {{
                          item.lastMessage.attachments[0].id +
                          "." +
                          item.lastMessage.attachments[0].contentType.split(
                            "/"
                          )[1]
                        }}
                      </span>
                    </div>
                  </div>

                  <div v-else class="rc-text-muted">
                    Conversation not started
                  </div>
                </b-col>
                <b-col cols="3" style="padding-left: 0 !important">
                  <div class="text-end">
                    <div v-if="item.lastMessage">
                      {{ naturalDate(item.lastMessage.creationTime) }}
                    </div>
                    <div v-else>{{ naturalDate(item.createdAt) }}</div>
                  </div>
                  <div
                    class="text-end"
                    v-if="item.unreadCount && item.unreadCount > 0"
                  >
                    <b-badge
                      v-if="item.lastMessage.answerMessageId"
                      pill
                      variant="primary"
                      style="margin-top: 4px; margin-right: 2px"
                      v-b-tooltip.hover
                      title="Answer List"
                      >A</b-badge
                    >
                    <b-badge pill variant="danger" style="margin-top: 4px">{{
                      item.unreadCount
                    }}</b-badge>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>

        <div v-if="searchTxt != '' && searchTxt">
          <div
            class="pl-2 py-1 title-filter"
            v-if="chatWithMessage.length != 0"
          >
            MESSAGES
          </div>

          <div
            class="contact"
            v-for="item in chatWithMessage"
            :key="`message_${item._id}`"
            @click="setCurrentChatFindMessage(item)"
          >
            <div class="d-flex align-items-center w-100">
              <div class="w-100">
                <b-row>
                  <b-col cols="9">
                    <div
                      class="lead-name w-100 text-truncate"
                      style="margin-bottom: 5px"
                    >
                      {{ item.chat.leadName ? item.chat.leadName : "" }}
                      <feather-icon
                        v-if="!item.leadId"
                        icon="AlertCircleIcon"
                        class="text-warning"
                        style="margin-right: 4px"
                        v-b-tooltip.hover
                        title="Without lead"
                      />
                      {{ item.chat.leadPhoneNumber }}
                    </div>

                    <div>
                      <div v-if="item.type == 'SMS'">
                        <div
                          class="w-100 text-truncate"
                          v-if="item.subject && item.subject != ''"
                        >
                          <p
                            class="text"
                            v-html="highlightMatchingText(item.subject)"
                          ></p>
                        </div>

                        <div
                          v-if="item.attachments.length > 0"
                          class="d-flex align-items-center"
                        >
                          <feather-icon
                            v-if="
                              item.attachments[0].contentType.startsWith(
                                'image/'
                              )
                            "
                            icon="ImageIcon"
                          ></feather-icon>
                          <span style="margin-left: 4px" class="text-truncate">
                            Attachment
                          </span>
                        </div>
                      </div>

                      <div
                        v-if="item.type == 'VoiceMail'"
                        class="d-flex align-items-center"
                      >
                        <feather-icon
                          icon="VoicemailIcon"
                          style="margin-right: 5px"
                        />
                        Voice message
                        <span style="margin-left: 5px"
                          >({{
                            convertSecondsShort(item.attachments[0].vmDuration)
                          }})</span
                        >
                      </div>

                      <div v-if="item.type == 'Fax'">
                        <feather-icon icon="FileIcon" />
                        <span style="margin-left: 4px">
                          {{
                            item.attachments[0].id +
                            "." +
                            item.attachments[0].contentType.split("/")[1]
                          }}
                        </span>
                      </div>
                    </div>
                  </b-col>
                  <b-col cols="3" style="padding-left: 0 !important">
                    <div class="text-end">
                      <div>
                        {{ naturalDate(item.creationTime) }}
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>

        <div
          class="text-center border-top pt-1"
          v-if="chatsByCredential.length == 0 && chatWithMessage.length == 0"
        >
          No results found.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NestSmsService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import io from "socket.io-client";

export default {
  props: {
    value: {
      type: Object,
    },

    credential: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      searchTxt: null,
      newSearchTxt: null,

      loadingMessages: false,
      showModalCreateChat: false,
      contactsForCredentialMore: true,
      contactsForCredentiaPage: 1,
      socket: null,
      chatsByCredential: [],

      chatWithMessage: [],
      optionsAll: ["INTERACTED", "NOT INTERACTED"],
    };
  },

  computed: {
    ...mapGetters({
      G_CURRENT_TAB_CONTACTS: "SmsInboxStore/G_CURRENT_TAB_CONTACTS",
      G_LIST_ACTIVE: "SmsInboxStore/G_LIST_ACTIVE",
      G_COUNTER_MESSAGES_NOT_READ_PHONE:
        "SmsInboxStore/G_COUNTER_MESSAGES_NOT_READ_PHONE",
      currentUser: "auth/currentUser",
    }),
  },

  methods: {
    ...mapMutations({
      M_REMOVE_CHAT_BY_ID: "SmsInboxStore/M_REMOVE_CHAT_BY_ID",
      M_SET_CURRENT_TAB_CONTACTS: "SmsInboxStore/M_SET_CURRENT_TAB_CONTACTS",
      M_SET_LIST_ACTIVE: "SmsInboxStore/M_SET_LIST_ACTIVE",
    }),

    ...mapActions({
      A_GET_COUNTER_UNREAD_MESSAGES:
        "SmsInboxStore/A_GET_COUNTER_UNREAD_MESSAGES",
      A_GET_ALL_MESSAGES_UNREAD: "SmsInboxStore/A_GET_ALL_MESSAGES_UNREAD",
    }),

    changeTabFilter(tab) {
      this.setCurrentChat(null);
      this.M_SET_CURRENT_TAB_CONTACTS(tab);
    },

    activeStatusTab() {
      this.M_SET_LIST_ACTIVE(true);
    },

    async getChats() {
      if (this.loadingMessages) return;

      if (!this.contactsForCredentialMore) return;

      if (this.searchTxt == "") {
        this.searchTxt = null;
      }

      try {
        this.loadingMessages = true;
        this.addPreloader();

        const params = {
          credentialId: this.credential.id,
          page: this.contactsForCredentiaPage,

          text: this.searchTxt,
          filterAll: this.G_CURRENT_TAB_CONTACTS,
        };

        const { data } = await NestSmsService.getChats(params);

        const chats = data.chats;
        const messages = data.messages;

        if (chats.length == 0 && messages.length == 0) {
          this.contactsForCredentialMore = false;
        }
        const auxArray = [...this.chatsByCredential];
        const newContacts = [...auxArray, ...chats];

        const auxArray2 = [...this.chatWithMessage];
        const newMessages = [...auxArray2, ...messages];

        this.contactsForCredentiaPage++;

        this.chatsByCredential = newContacts;
        this.chatWithMessage = newMessages;
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
        this.loadingMessages = false;
      }
    },

    loadMoreContacts() {
      const contactList = this.$el;
      if (
        contactList.scrollTop +
          contactList.clientHeight +
          contactList.scrollHeight * 0.02 >=
          contactList.scrollHeight &&
        this.contactsForCredentialMore
      ) {
        this.getChats();
      }
    },

    setCurrentChat(chat) {
      this.$emit("input", chat);
    },

    setCurrentChatFindMessage(item) {
      this.$emit("input", item.chat);

      const el = this;
      setTimeout(async function () {
        el.$emit("messageFind", {
          id: item._id,
          search: el.searchTxt,
        });
      }, 1000);
    },

    setActiveStatus(chat) {
      if (this.value) {
        if (this.value._id == chat._id) {
          return "active";
        }
      }
      return "";
    },

    // MUTATIONS

    addNewChat(chat) {
      const { credentialId } = chat;
      if (
        credentialId == this.credential.id &&
        this.G_CURRENT_TAB_CONTACTS == "NOT INTERACTED"
      ) {
        this.chatsByCredential.unshift(chat);
      }
    },

    removeChatById(chat) {
      const existingContactIndex = this.chatsByCredential.findIndex(
        (contact) => contact._id === chat._id
      );

      if (existingContactIndex !== -1) {
        this.chatsByCredential.splice(existingContactIndex, 1);
      }

      if (this.value._id == chat._id) {
        this.$emit("input", null);
        this.showInfoSwal("This chat was blocked by " + chat.blockedBy.name);
      }
    },

    updateChat(chat) {
      const existingContactIndex = this.chatsByCredential.findIndex(
        (contact) => contact._id == chat._id
      );

      if (existingContactIndex !== -1) {
        const { leadId, leadName, recycledBy } = chat;
        this.chatsByCredential[existingContactIndex].leadId = leadId;
        this.chatsByCredential[existingContactIndex].leadName = leadName;
        this.chatsByCredential[existingContactIndex].recycledBy = recycledBy;
      }
    },

    addLastMessageChat(message) {
      console.log(message);
      const existIndex = this.chatsByCredential.findIndex(
        (chat) => chat._id == message.chatId
      );
      if (existIndex !== -1) {
        let newChat = this.chatsByCredential[existIndex];
        newChat.lastMessage = message;

        if (message.direction == "Inbound" && message.readStatus == "Unread") {
          let countMessage = (newChat.unreadCount ?? 0) + 1;
          newChat.unreadCount = countMessage;
        }

        this.chatsByCredential.splice(existIndex, 1);
        this.chatsByCredential.unshift({ ...newChat });
      }
    },

    readAllContact(chatId) {
      const existingContactIndex = this.chatsByCredential.findIndex(
        (contact) => contact._id == chatId
      );
      if (existingContactIndex !== -1) {
        this.chatsByCredential[existingContactIndex].unreadCount = 0;
      }
    },

    // SOCKETS

    async bindReadAllMessage() {
      try {
        const el = this;

        this.socket.on("notification-message-created", (data) => {
          this.addLastMessageChat(data);

          if (data.direction == "Inbound" && data.readStatus == "Unread") {
            el.A_GET_COUNTER_UNREAD_MESSAGES(el.credential.id);
            el.A_GET_ALL_MESSAGES_UNREAD();
          }
        });

        this.socket.on("notification-read-all-messages", (data) => {
          const { chatId } = data;
          this.readAllContact(chatId);
          el.A_GET_COUNTER_UNREAD_MESSAGES(el.credential.id);
          el.A_GET_ALL_MESSAGES_UNREAD();
        });

        this.socket.on("notification-new-chat", (data) => {
          this.addNewChat(data);
        });

        this.socket.on("notification-update-chat", (data) => {
          const chat = data;

          if (chat.isBlocked) {
            this.removeChatById(chat);
          } else {
            this.updateChat(chat);
          }
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    initWebsocket() {
      const connectionOptions = {
        "force new connection": true,
        reconnectionAttempts: "Infinity",
        timeout: 10000,
        transports: ["websocket"],
      };
      this.socket = io(process.env.VUE_APP_NEST_BACK, connectionOptions);
    },

    resetAll() {
      this.chatsByCredential = [];
      this.chatWithMessage = [];
      this.contactsForCredentialMore = true;
      this.contactsForCredentiaPage = 1;
      this.getChats();
    },

    getFilterMessages() {
      if (!this.searchTxt) return;

      const trimmedSearchTxt = this.searchTxt.trim();
      if (trimmedSearchTxt === "") return;

      this.newSearchTxt = this.searchTxt;
      this.resetAll();
    },

    highlightMatchingText(text) {
      const newSearchTxt = this.newSearchTxt;
      if (newSearchTxt == "" || !newSearchTxt) return text;
      const searchTextLower = newSearchTxt.toLowerCase();
      const regex = new RegExp(`(${searchTextLower})`, "gi");
      return text.replace(regex, `<span class="match">$1</span>`);
    },
  },

  watch: {
    credential(value) {
      this.resetAll();
      if (!value) return;
      this.A_GET_COUNTER_UNREAD_MESSAGES(value.id);
    },

    // value(newValue) {
    //   if (!newValue) return;
    //   this.A_GET_COUNTER_UNREAD_MESSAGES(this.credential.id);
    // },

    searchTxt(value) {
      if (value == "" && !value) {
        this.resetAll();
      }
    },

    G_CURRENT_TAB_CONTACTS() {
      this.resetAll();
    },
  },

  created() {
    this.initWebsocket();
  },

  mounted() {
    this.bindReadAllMessage();
    this.getChats();

    this.A_GET_COUNTER_UNREAD_MESSAGES(this.credential.id);
  },

  beforeDestroy() {
    this.socket.disconnect();
  },
};
</script>

<style lang="scss">
.container-contatcs-pa {
  background-color: #ebebeb;
  height: inherit;
  overflow-y: auto;

  .container-contacts {
    font-family: "Rubick" !important;

    .search-chat-top {
      position: sticky;
      top: 0;
      background-color: #ebebeb;
      z-index: 10;
    }

    .search-new-contact {
      display: flex;
      align-items: center;
      height: 45px;

      .custom-input {
        height: 100%;
        background-color: #f0f2f5;
      }
    }

    .container-filters {
      border-radius: 10px;
      margin-top: 1rem;
      display: flex;
      // align-items: center;
      // justify-content: center;
      position: relative;

      .filter-v2 {
        margin: 0 8px;
        cursor: pointer;
        padding: 5px 8px;
        border-radius: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .active-filter-v2 {
        color: #fff;
        background-color: #0090e7;
      }
    }

    .contact {
      cursor: pointer !important;
      height: 80px;
      width: 100%;
      background-color: #ebebeb;
      display: flex;
      align-items: center;
      border-top: 1px solid #e3e8e7;

      .text-end {
        text-align: end;
      }
    }

    .contact:hover {
      background-color: #f0faff;
    }

    .title-filter {
      background-color: #f0faff;
    }
    .active {
      background-color: #f0faff;
      border-left: 8px solid #0099dd;
    }
  }
}

.dark-layout {
  .container-contatcs-pa {
    background-color: #111b21;

    .container-contacts {
      .search-chat-top {
        background-color: #111b21;
      }

      .active,
      .title-filter {
        background-color: #202c33 !important;
      }

      .contact {
        background-color: #111b21;
        border-top: 1px solid #202c33;
      }

      .contact:hover {
        background-color: #202c33;
      }

      .search-new-contact {
        .custom-input {
          background-color: #202c33;
        }
      }
    }
  }
}

.container-contatcs-pa {
  @media (min-width: 1651px) {
    .search-chat-top {
      padding: 0 2rem;
    }

    .contact {
      padding: 1rem 2rem;
      font-size: 13.5px;

      .text-end {
        font-size: 11px;
      }
    }
  }

  @media (min-width: 1201px) and (max-width: 1650px) {
    .search-chat-top {
      padding: 0 1.6rem;
    }

    .contact {
      padding: 1rem 1.6rem;
      font-size: 12.5px;

      .text-end {
        font-size: 10.5px;
      }
    }
  }

  @media (max-width: 1200px) {
    .search-chat-top {
      padding: 0 1.4rem;
    }

    .contact {
      padding: 1rem 1.2rem;
      font-size: 11.5px;

      .text-end {
        font-size: 9.5px;
      }
    }
  }
}
</style>