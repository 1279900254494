export default [
  {
    path: "directs-round",
    name: "specialistdigital-directs-round",
    component: () =>
      import(
        /* webpackChunkName: "SpecialistDigitalDirectsRound" */ "@/views/specialist-digital/views/directs-round/directs-round.vue"
      ),
    redirect: { name: "pending-directs-round-specialistdigital" },
    meta: {
      pageTitle: "Direct Dispute",
      breadcrumb: [
        {
          text: "Direct Dispute",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pending",
        name: "pending-directs-round-specialistdigital",
        component: () =>
          import(
            /* webpackChunkName: "SpecialistDigitalDirectsRoundPending" */ "@/views/specialist-digital/views/directs-round/components/DirectLetters.vue"
          ),
        meta: {
          typeTab: "pending",
          pageTitle: "Direct Dispute",
          breadcrumb: [
            {
              text: "Pending",
              active: true,
            },
          ],
        },
        props: { statusDirectType: "pending" },
      },
      {
        path: "completed",
        name: "completed-directs-round-specialistdigital",
        component: () =>
          import(
            /* webpackChunkName: "SpecialistDigitalDirectsRoundCompleted" */ "@/views/specialist-digital/views/directs-round/components/DirectLetters.vue"
          ),
        meta: {
          typeTab: "completed",
          pageTitle: "Direct Dispute",
          breadcrumb: [
            {
              text: "Completed",
              active: true,
            },
          ],
        },
        props: { statusDirectType: "completed" },
      },
    ],
  },
];
