import { amgApi } from '@/service/axios'

class StatusChangeService {

    async getClientsConnection(params) {
        const data = await amgApi.post('clients/search-clients-connection', params)
        return data.data
    }
    async getTrackingListViewConnection(params) {
        const data = await amgApi.post('clients/tracking-list-view-connection', params)
        return data.data
    }
    async getCounterHoldClients(params) {
        const data = await amgApi.post('clients/count-clients-on-status-change', params)
        return data.data[0].counter
    }
    async getTrackingListByClientId(params) {
        const data = await amgApi.post('clients/tracking-list-view-connection', params)
        return data;
    }
    async sendOrReturnClient(params) {
        const data = await amgApi.post('clients/pass-client-to-connection', params)
        return data;
    }
    async getStatusChangeCounter(params) {
        const data = await amgApi.post("clients/count-clients-on-status-change", params);
        return data.data;
    }
}
export default new StatusChangeService()
