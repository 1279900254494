import store from '@/store'
import Vue from "vue";
import router from "@/router";
import ClientDashboardService from "@/views/paragon/views/clients/dashboard/services/dashboard.service"; 

const PhaseChangeNotification = () => {
  window.socket.bind('phase-notification-debtsolution', async (response) => {
    const { user_name, user_id, phase_id, subtitle, client_name } = response;
    const current_user_id = store.state.auth.currentUser.user_id;
    const current_module_id = store.state.auth.currentUser.modul_id; 
    const current_role_id = store.state.auth.currentUser.role_id; 
    if(current_module_id == 5){
        if (current_user_id != user_id && current_role_id==2) { 
            Vue.prototype.$amgAlert({
                title: 'Phase Change',
                subtitle: subtitle,
                data: [
                    { icon: 'UserIcon', title: 'CLIENT', description: client_name },
                    { icon: 'UserIcon', title: 'EMPLOYEE', description: user_name },
                    { icon: 'RepeatIcon', title: 'PHASE', description: phase_id == 6 ? 'REMOVED' : 'NO BALANCE' },
                    
                ],
            

                okTitle: 'Go to Accounts Actions',
                icon:"DollarSignIcon",
                ok: () => {
                const goSection = "debtsolution-accounts-actions-list";
                    if (router.currentRoute.name !== goSection) {                    
                        router.push({ name: goSection });
                    }
                },
            });
        }
    }
  })
}

const NewCasesNotification = () => {
    window.socket.bind('new-cases-notification', async (response) => {
      const { case_number, user_id, username, clientname, document_type, service_type, is_client } = response;
      const current_user_id = store.state.auth.currentUser.user_id;
      const current_module_id = store.state.auth.currentUser.modul_id; 
      const current_role_id = store.state.auth.currentUser.role_id;
      const dataCloseSwal = {
        to_id: [current_user_id], 
      }  
      if(current_module_id == 5){ 
          if (current_role_id==2) { 
              if(current_user_id != user_id){
                Vue.prototype.$amgAlert({
                    title: 'New Court Case',
                    subtitle: 'CASE :'+case_number,
                    data: [
                        { icon: 'UserIcon', title: is_client, description: clientname },
                        { icon: 'UserIcon', title: 'REQUEST BY', description: username },
                        { icon: 'FileTextIcon', title: 'DOCUMENT TYPE', description: document_type },
                        { icon: 'FileIcon', title: 'SERVICE TYPE', description: service_type },
                    ],
                
    
                    okTitle: current_module_id==5?'Go to Court Info':'OK',
                    icon:"BookOpenIcon",
                    ok: async () => {
                    const goSection = "court-info-cases-debtsolution-in-progress";
                        if (router.currentRoute.name !== goSection && current_module_id==5) {                    
                            router.push({ name: goSection });
                            await window.amgApi.post('/commons/close-all-swal', response);
                        }
                    },
                });
              } 
            
            const { data } = await ClientDashboardService.getCountCasesPending({role_id: current_role_id,advisor_id: current_user_id});
            const payload = {
                routeName: "court-info-debtsolution",
                tag: current_role_id == 2 ?data.quantity_s[0].quantity : data.quantity_a[0].quantity,
            } 
            store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
          }
      }
    })
  }

  const ReconfirmCasesNotification = () => {
    window.socket.bind('reconfirm-cases-notification', async (response) => { 
      const { case: caseObject ,name_phase, user_action  } = response;  
      const current_user_id = store.state.auth.currentUser.user_id;
      const current_module_id = store.state.auth.currentUser.modul_id; 
      const current_role_id = store.state.auth.currentUser.role_id;   
      const dataCloseSwal = {
        to_id: [current_user_id], 
      }
            if(caseObject){
              if(name_phase){
                if(caseObject[0].created_by == current_user_id && current_user_id != user_action){
                  Vue.prototype.$amgAlert({
                    title: 'UPDATED CASE',
                    subtitle: 'CASE :'+caseObject[0].case_number,
                    data: [ 
                        { icon: 'FileIcon', title: 'TYPE', description: name_phase },
                        { icon: 'UserIcon', title: caseObject[0].is_client, description: caseObject[0].clientname }, 
                        { icon: 'FileIcon', title: 'SERVICE TYPE', description: caseObject[0].service_type }, 
                    ],
                
    
                    okTitle: current_module_id==5?'Go to Court Info':'OK',
                    icon:"BookOpenIcon",
                    ok: () => {
                    const goSection = "court-info-cases-debtsolution-in-progress"; 
                        if (router.currentRoute.name !== goSection && current_module_id==5) {
                            router.push({ name: goSection }); 
                        }
                        window.amgApi.post('/commons/close-all-swal', dataCloseSwal);
                    },
                  });
                }
              } else
              if(caseObject[0].advisor_id == current_user_id){
                Vue.prototype.$amgAlert({
                  title: 'New Court Case Assigned',
                  subtitle: 'CASE :'+caseObject[0].case_number,
                  data: [
                      { icon: 'UserIcon', title: caseObject[0].is_client, description: caseObject[0].clientname }, 
                      { icon: 'FileIcon', title: 'SERVICE TYPE', description: caseObject[0].service_type },
                  ],
              
  
                  okTitle: 'Go to Court Info',
                  icon:"BookOpenIcon",
                  ok: () => {
                  const goSection = "court-info-cases-debtsolution-in-progress";
                      if (router.currentRoute.name !== goSection) {                    
                          router.push({ name: goSection }); 
                      }
                      window.amgApi.post('/commons/close-all-swal', dataCloseSwal);
                  },
               });
              }  
            } 
            if(current_module_id == 5){
              const { data } = await ClientDashboardService.getCountCasesPending({role_id: current_role_id,advisor_id: current_user_id});
            const payload = {
                routeName: "court-info-debtsolution",
                tag: current_role_id == 2 ?data.quantity_s[0].quantity : data.quantity_a[0].quantity,
            } 
            store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload); 
            }
             
    })
  }

export {PhaseChangeNotification,NewCasesNotification,ReconfirmCasesNotification}

