import LettersNav from "@/views/correspondence/views/letters/letters.nav";
import LettersToClientsNav from "@/views/correspondence/views/letters-to-clients/navigation/lettersToClients.navigation";
import FileManagerNav from "@/views/correspondence/views/file-manager/file-manager.nav";
import FilePOBoxNav from "@/views/correspondence/views/file-po-box/file-po-box.nav";
import MarketingLettersNav from "@/views/correspondence/views/marketing-letters/navigation/marketing-letters.navigation";
import ProductRequestNav from "@/views/correspondence/views/request/request.nav.js";
import OtherPayments from "@/views/correspondence/views/other-payments/navigation/other-payments.nav";
import EmployeeClaimsNav from "@/views/correspondence/views/employee-claims/navigation/employee-claims.nav";
import SendingCreditors from "@/views/correspondence/views/sending-creditors/navigation/sending-creditors.navigation";
import Settings from "@/views/correspondence/views/settings/navigation/settings.navigation";
//import CorrespondenceProjectsNavigation from "@/views/correspondence/views/projects/correspondence-projects.navigation";
import CallLogNavigation from "@/views/correspondence/views/call-log/call-log.nav.js";
import SendingReports from "@/views/correspondence/views/sending-reports/navigation/sending-reports.nav.js";
import MOFNavigation from "@/views/correspondence/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/correspondence/views/recruitment-process/correspondence-recruitment_process.navigation";
import CustomerTicketsNavigation from "@/views/correspondence/views/customer-tickets/correspondence-customer-tickets.navigation";
import DashboardNavigation from "@/views/correspondence/views/dashboard/navigation/dashboard.nav.js";
import ChecksNavigation from "@/views/correspondence/views/checks/checks.nav.js";


const navigation = [
  {
    header: "CORRESPONDENCE",
  },
  DashboardNavigation,
  ...LettersNav,
  ...LettersToClientsNav,
  ...ChecksNavigation,
  FileManagerNav,
  ...FilePOBoxNav,
  ...MarketingLettersNav,
  ProductRequestNav,
  ...SendingCreditors,
  OtherPayments,
  EmployeeClaimsNav,
  ...Settings,
  //CorrespondenceProjectsNavigation,
  ...CallLogNavigation,
  ...SendingReports,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  CustomerTicketsNavigation,
];

export default navigation;
