import ClientDashboardService from "@/views/commons/components/clients/dashboard/services/clients.dashboard.services"
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services"
import ClaimService from "@/views/commons/components/claims_v2/services/claims-v2.service.js"

const state = {
  S_CLIENTS: [],
  S_UPLOAD_FILES_CLAIM: [],
  S_COUNT_CREDIT_CARD: [],
  S_COUNT_FILES: [],
  S_COUNT_FILES_APP: [],
  S_COUNT_DISPUTE: [],
  S_UPDATE_EVALUATED_BY_SP: 0,
  S_SETTLEMENTS_COUNT: 0,
  S_NOTES: [],
  S_CLAIMS: [],
  S_UPDATED_REQUEST: null,
  S_FINISHED_DASHBOARD_ACTION: null,
  S_UPDATE_PAYMENTS_TABLE: false,
  S_CLAIMS_QUALITY: [],
  amountSaved: 0,
  maximum_balance: 0,
}
const getters = {
  G_CLIENTS() {
    const clients = state.S_CLIENTS
    return clients
  },

  G_UPLOAD_FILES_CLAIM() {
    const uploadFilesClaim = state.S_UPLOAD_FILES_CLAIM
    return uploadFilesClaim
  },

  G_COUNT_CREDIT_CARD() {
    const countCreditCard = state.S_COUNT_CREDIT_CARD
    return countCreditCard
  },

  G_COUNT_FILES() {
    const countFiles = state.S_COUNT_FILES
    return countFiles
  },

  G_COUNT_FILES_APP() {
    return state.S_COUNT_FILES_APP
  },

  G_COUNT_DISPUTE() {
    const countDispute = state.S_COUNT_DISPUTE
    return countDispute
  },
  G_UPDATE_EVALUATED_BY_SP() {
    return state.S_UPDATE_EVALUATED_BY_SP
  },
  G_NOTES() {
    const notes = state.S_NOTES
    return notes
  },
  G_CLAIMS() {
    return state.S_CLAIMS
  },

  G_CLAIMS_QUALITY() {
    return state.S_CLAIMS_QUALITY
  },

  G_UPDATED_REQUEST() {
    return state.S_UPDATED_REQUEST
  },
  G_FINISHED_DASHBOARD_ACTION() {
    return state.S_FINISHED_DASHBOARD_ACTION
  },
  G_SETTLEMENTS_COUNT() {
    return state.S_SETTLEMENTS_COUNT
  },
  G_UPDATE_PAYMENTS_TABLE() {
    return state.S_UPDATE_PAYMENTS_TABLE
  },
  G_MAXIMUM_BALANCE() {
    return state.maximum_balance
  },
  G_AMOUNTSAVED() {
    return state.amountSaved
  },
}

const mutations = {
  SET_CLIENTS(state, payload, response) {
    state.S_CLIENTS = payload
    state.S_ClIENTS_RESPONSE = response
  },

  SET_FEE_AND_CHARGES(state, payload) {
    state.S_CLIENTS.total_charge = payload
    state.S_CLIENTS.balance =
      state.S_CLIENTS.total_payments - state.S_CLIENTS.total_charge
  },

  SET_STATUS_WEL(state, payload) {
    state.S_CLIENTS.s_welcome = payload
  },

  SET_UPLOAD_FILES_CLAIM(state, payload) {
    state.S_UPLOAD_FILES_CLAIM = payload
  },

  SET_COUNT_CREDIT_CARD(state, payload) {
    state.S_COUNT_CREDIT_CARD = payload
  },
  SET_COUNT_FILES(state, payload) {
    state.S_COUNT_FILES = payload
  },
  SET_COUNT_FILES_APP(state, payload) {
    state.S_COUNT_FILES_APP = payload
  },
  SET_COUNT_DISPUTE(state, payload) {
    state.S_COUNT_DISPUTE = payload
  },
  SET_UPDATE_EVALUATED_BY_SP(state, payload) {
    state.S_UPDATE_EVALUATED_BY_SP = payload
  },
  SET_COUNT_SETTLEMENT(state, payload) {
    state.S_SETTLEMENTS_COUNT = payload
  },

  SET_NOTES(state, payload) {
    state.S_NOTES = payload
  },
  SET_CLAIMS(state, payload) {
    state.S_CLAIMS = payload
  },
  SET_CLAIMS_QUALITY(state, payload) {
    state.S_CLAIMS_QUALITY = payload
  },

  SET_S_UPDATED_REQUEST(state, payload) {
    state.S_UPDATED_REQUEST = payload
  },
  SET_S_FINISHED_DASHBOARD_ACTION(state, payload) {
    state.S_FINISHED_DASHBOARD_ACTION = payload
  },
  SET_UPDATE_PAYMENTS_TABLE(state, payload) {
    state.S_UPDATE_PAYMENTS_TABLE = payload
  },

  SET_UPDATE_ALTERNATIVE_CONTACT(state, payload) {
    state.S_CLIENTS = { ...state.S_CLIENTS, ...payload }
  },
  SET_MAXIMUM_BALANCE(state, payload) {
    state.maximum_balance = payload
  },
  SET_AMOUNTSAVED(state, payload) {
    state.amountSaved = payload
  },
  SET_TIMELINE_GENERATED(state, payload) {
    state.S_CLIENTS.generated_timeline = payload
  },
}
const actions = {
  async A_GET_CLIENTS({ commit }, params) {
    try {
      let clients = await ClientDashboardService.search(params)
      const response = clients
      clients = clients.data[0]
      clients.goals = clients.goals == null ? [] : JSON.parse(clients.goals)
      commit("SET_CLIENTS", clients)
      commit("SET_AMOUNTSAVED", clients.saving)
      commit("SET_MAXIMUM_BALANCE", clients.total_actual_balance)
      return response
    } catch (error) {
      console.log("ERROR_GET_CLIENTS [ACTION]", error)
      throw error
    }
  },

  async A_GET_FEE_AND_CHARGES({ commit }, params) {
    try {
      const clients = await ClientDashboardService.getFeeAndCharges(params)
      const response = clients.data[0].total_charge
      commit("SET_FEE_AND_CHARGES", response)
      return response
    } catch (error) {
      console.log("A_GET_FEE_AND_CHARGES [ACTION]", error)
      throw error
    }
  },

  A_SET_STATUS_WEL({ commit }, params) {
    commit("SET_STATUS_WEL", params)
  },

  A_SET_UPDATED_REQUEST({ commit }, params) {
    commit("SET_S_UPDATED_REQUEST", params)
  },
  A_SET_FINISHED_DASHBOARD_ACTION({ commit }, params) {
    commit("SET_S_FINISHED_DASHBOARD_ACTION", params)
  },

  async A_COUNT_CREDIT_CARD({ commit }, params) {
    try {
      let countData = []
      const data = await ClientsOptionsServices.getCountExpirationCardTab(
        params
      )
      if (data.status == 200) {
        countData =
          data.data[0].countExpiration > 99
            ? "+99"
            : data.data[0].countExpiration
      }

      commit("SET_COUNT_CREDIT_CARD", countData)
      return countData
    } catch (error) {
      console.log("ERROR_COUNT_CREDIT_CARD [ACTION]", error)
      throw error
    }
  },

  async A_COUNT_DISPUTE({ commit }, params) {
    try {
      let countDisputeClient = []
      const response = await ClientsOptionsServices.counterDispute(params)
      countDisputeClient = response.data.length
      commit("SET_COUNT_DISPUTE", countDisputeClient)
      return countDisputeClient
    } catch (error) {
      console.log("ERROR_COUNT_DISPUTE [ACTION]", error)
      throw error
    }
  },

  async A_UPDATE_EVALUATED_BY_SP({ commit }, params) {
    try {
      let countUpdateEvaluatedBySp = []
      const response =
        await ClientsOptionsServices.updateFromSpecialistEvaluated(params)
      countUpdateEvaluatedBySp = response.data[0].cc

      commit("SET_UPDATE_EVALUATED_BY_SP", countUpdateEvaluatedBySp)
    } catch (e) {
      console.log("ERROR_A_UPDATE_EVALUATED_BY_SP", e)
    }
  },

  async A_COUNT_FILES({ commit }, params) {
    try {
      let countfileclient = []
      const response = await ClientsOptionsServices.getFileCountClient(params)
      countfileclient = response.data[0].countfile
      commit("SET_COUNT_FILES", countfileclient)
      commit("SET_COUNT_FILES_APP", response.data[0].files_app)
      return countfileclient
    } catch (error) {
      console.log("ERROR_COUNT_DISPUTE [ACTION]", error)
      throw error
    }
  },

  async A_GET_SETTLEMENT_COUNT({ commit }, params) {
    let res = await amgApi.post("/debt-solution/get-active-offers-counter", {
      client_account_id: params.accountId,
      user_id: params.userId,
      module_id: params.module_id,
    })

    commit("SET_COUNT_SETTLEMENT", res.data)
    return res.data
  },

  async A_NOTES({ commit }, params) {
    try {
      let notes = []
      const data = await ClientsOptionsServices.getNotesYear(params)
      notes = data.data
      commit("SET_NOTES", notes)
      return notes
    } catch (error) {
      console.log("ERROR_NOTES [ACTION]", error)
      throw error
    }
  },

  async A_CLAIMS({ commit }, params) {
    try {
      let claims = []
      const data = await ClientDashboardService.getHistoryClaims(params)
      claims = data.data
      commit("SET_CLAIMS", claims)
      return claims
    } catch (error) {
      console.log("ERROR_CLAIMS [ACTION]", error)
      throw error
    }
  },

  actualizarAmountSaved({ commit }, nuevoValor) {
    commit("SET_AMOUNTSAVED", nuevoValor)
  },

  async A_SET_TIME_LINE({ commit }, params) {
    commit("SET_TIMELINE_GENERATED", params)
  },

  async A_CLAIMS_QUALITY({ commit }, params) {
    const { data } = await ClaimService.getClaimData(params)
    commit("SET_CLAIMS_QUALITY", data)
    return data
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
