<template>
  <b-modal
    v-model="ownControl"
    title="Create Credit Card"
    title-tag="h3"
    hide-footer
    size="lg"
    header-bg-variant="transparent"
    modal-class="custom-modal-amg"
    header-class="p-0"
    @hidden="closeModal"
  >
    <template v-slot:modal-header>
      <div class="header-modal d-flex justify-content-between">
        <h3 class="text-white m-0 font-weight-bold">
          Create Credit Card
        </h3>
        <div>
          <feather-icon
            icon="XIcon"
            size="22"
            class="close-button"
            @click="closeModal"
          />
        </div>
      </div>
    </template>
    <ValidationObserver ref="form">
      <b-row class="font-bureau-style">
        <b-col cols="12" md="6">
          <div class="form-group">
            <label>Card Holder Name</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-input
                class="border-hover-p"
                id="card_holder"
                name="card_holder"
                v-model="form.cardholdername"
                type="text"
                placeholder="Card Holder Name"
                :class="{ 'border border-danger': errors[0] }"
                :disabled="modalView"
              />
            </ValidationProvider>
          </div>
        </b-col>

        <b-col cols="12" md="6" v-if="modalView">
          <div class="form-group">
            <label>Card Number</label>
            <b-input-group>
              <template #append>
                <b-button
                  v-if="!showCard"
                  variant="primary"
                  class="p-0 m-0"
                  style="width: 50px"
                  @click="eyeCard"
                >
                  <feather-icon icon="EyeOffIcon" class="pointer" />
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  class="p-0 m-0"
                  style="width: 50px"
                  @click="eyeCard"
                >
                  <feather-icon icon="EyeIcon" class="pointer" />
                </b-button>
              </template>
              <b-form-input v-model="cardNumber" />
            </b-input-group>
          </div>
        </b-col>
        <b-col cols="12" md="6" v-else>
          <div class="form-group">
            <label>Card Number</label>
            <b-row>
              <b-col cols="3">
                <ValidationProvider
                  rules="required|length:4"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="border-hover-p"
                    ref="input-1"
                    @input="activeFocus(1, 4)"
                    v-model="cardnumber1"
                    type="text"
                    :class="{ 'border border-danger': errors[0] }"
                  />
                </ValidationProvider>
              </b-col>
              <b-col cols="3">
                <ValidationProvider
                  rules="required|length:4"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="border-hover-p"
                    ref="input-2"
                    @input="activeFocus(2, 4)"
                    v-model="cardnumber2"
                    type="text"
                    :class="{ 'border border-danger': errors[0] }"
                  />
                </ValidationProvider>
              </b-col>
              <b-col cols="3">
                <ValidationProvider
                  rules="required|length:4"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="border-hover-p"
                    ref="input-3"
                    @input="activeFocus(3, 4)"
                    v-model="cardnumber3"
                    type="text"
                    :class="{ 'border border-danger': errors[0] }"
                  />
                </ValidationProvider>
              </b-col>
              <b-col cols="3">
                <ValidationProvider
                  rules="required|min:3|max:4"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="border-hover-p"
                    ref="input-4"
                    @input="activeFocus(4, 4)"
                    v-model="cardnumber4"
                    type="text"
                    :class="{ 'border border-danger': errors[0] }"
                  />
                </ValidationProvider>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="4" md="2">
          <div class="form-group">
            <label>MM</label>
            <ValidationProvider rules="required|length:2" v-slot="{ errors }">
              <b-form-input
                class="border-hover-p"
                type="text"
                id="card-expi-month"
                maxlength="2"
                ref="input-5"
                @input="activeFocus(5, 2)"
                v-model="form.card_expi_month"
                :class="{ 'border border-danger': errors[0] }"
                :disabled="modalView"
              />
            </ValidationProvider>
          </div>
        </b-col>
        <b-col cols="4" md="2">
          <div class="form-group">
            <label>YY</label>
            <ValidationProvider rules="required|length:2" v-slot="{ errors }">
              <b-form-input
                class="border-hover-p"
                type="text"
                id="card-expi-year"
                maxlength="2"
                ref="input-6"
                @input="activeFocus(6, 2)"
                v-model="form.card_expi_year"
                :class="{ 'border border-danger': errors[0] }"
                :disabled="modalView"
              />
            </ValidationProvider>
          </div>
        </b-col>
        <b-col cols="4" md="2" v-if="dataCard != null">
          <div class="form-group">
            <label>CVV</label>
            <b-input-group>
              <template #append>
                <b-button
                  v-if="!showCode"
                  variant="primary"
                  class="m-0 p-0"
                  style="width: 40px"
                  @click="eyeCode"
                >
                  <feather-icon icon="EyeOffIcon" class="pointer" />
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  class="m-0 p-0"
                  style="width: 40px"
                  @click="eyeCode"
                >
                  <feather-icon icon="EyeIcon" class="pointer" />
                </b-button>
              </template>
              <b-form-input v-model="cardCVC" />
            </b-input-group>
          </div>
        </b-col>
        <b-col cols="4" md="2" v-else>
          <div class="form-group">
            <label>CVV</label>
            <ValidationProvider
              rules="required|min:3|max:4"
              v-slot="{ errors }"
            >
              <b-form-input
                class="border-hover-p"
                v-model="form.cardsecuritycode"
                ref="input-7"
                id="card-cvv"
                max="4"
                type="text"
                maxlength="4"
                :class="{ 'border border-danger': errors[0] }"
                :disabled="modalView"
              />
            </ValidationProvider>
          </div>
        </b-col>
        <b-col cols="12" md="6" v-if="type !== 'amg-cards'">
          <div class="form-group">
            <input type="text" v-model="moreInfo" class="d-none" />
            <label>Billing Address is the same the Mailling Address ?</label>
            <b-row>
              <b-col cols="6" class="px-1">
                <b-button
                  @click="moreInfo = 1"
                  class="btn rounded w-100 btn-gray-selector"
                  :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
                  :variant="`${moreInfo == 1 ? 'primary' : ''}`"
                  :disabled="modalView"
                >
                  Yes
                </b-button>
              </b-col>
              <b-col cols="6" class="px-1">
                <b-button
                  @click="moreInfo = 0"
                  class="btn rounded w-100 btn-gray-selector"
                  :variant="`${moreInfo == 0 ? 'primary' : ''}`"
                  :disabled="modalView"
                >
                  No
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="moreInfo == 0" class="font-bureau-style">
        <b-col cols="12">
          <div class="form-group">
            <label>Mailing address</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <vue-google-autocomplete
                ref="address_create_card_modal"
                id="address_create_card_modal"
                class="form-control boder-hover-p"
                placeholder="Please type your address"
                v-on:placechanged="getAddressData"
                country="us"
                v-model="form.address"
                :class="{ 'border border-danger': errors[0] }"
              ></vue-google-autocomplete>
            </ValidationProvider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label>City</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-input
                class="border-hover-p"
                v-model="form.city"
                id="city"
                type="text"
                placeholder="City"
                :class="{ 'border border-danger': errors[0] }"
              />
            </ValidationProvider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label>State</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <v-select
                v-model="form.state"
                :reduce="(el) => el.value"
                :options="states"
                :clearable="false"
                :class="{ 'border border-danger': errors[0] }"
              ></v-select>
            </ValidationProvider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label>Zip Code</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-input
                class="border-hover-p"
                v-model="form.zipcode"
                id="zipcode"
                type="text"
                placeholder="Zip Code"
                :class="{ 'border border-danger': errors[0] }"
              />
            </ValidationProvider>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label>Country</label>
            <ValidationProvider rules="required" v-slot="{ errors }">
              <b-form-input
                class="border-hover-p"
                disabled
                v-model="form.country"
                id="country"
                type="text"
                placeholder="Country"
                :class="{ 'border border-danger': errors[0] }"
              />
            </ValidationProvider>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!modalView">
        <b-col md="12" class="mt-2 text-right">
          <b-button
            class="btn-update-sn rounded font-bureau-style text-white"
            :class="isDarkSkin ? 'custom-button-dark' : 'custom-button-light'"
            variant="primary"
            @click="createCard"
          >
            <i class="fas fa-save"></i> Save
          </b-button>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

// Components
import VueGoogleAutocomplete from "vue-google-autocomplete";
import vSelect from "vue-select";

// Services
import { amgApi } from "@/service/axios";
import crmGlobal from "@/views/crm/services/global";

export default {
  components: { VueGoogleAutocomplete, vSelect },
  props: {
    lead: {
      type: Number,
      default: null,
    },
    dataCard: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      ownControl: false,
      modalView: false,
      showCard: false,
      showCode: false,
      address_create_card_modal: "",
      states: [],
      cards: [],
      moreInfo: "1",
      form: {
        idlead: this.lead,
        card_expi_month: "",
        card_expi_year: "",
        cardnumber: "",
        cardsecuritycode: "",
        city: "",
        state: "",
        country: "United Stated",
        zipcode: "",
        address: "",
        cardholdername: "",
        street: "",
        user: null,
      },
      cardnumber1: "",
      cardnumber2: "",
      cardnumber3: "",
      cardnumber4: "",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
    }),

    cardCVC() {
      return this.form.cardsecuritycode.length == 3
        ? this.showCode
          ? this.form.cardsecuritycode
          : "XX" + this.form.cardsecuritycode.substr(2)
        : this.showCode
        ? this.form.cardsecuritycode
        : "xxx" + this.form.cardsecuritycode.substr(3);
    },

    cardNumber() {
      if (this.showCard) {
        return this.form.cardnumber;
      } else {
        return "XXXX-XXXX-XXXX-" + this.cardnumber4;
      }
    },
  },
  methods: {
    async getEeuuStates() {
      try {
        const response = await crmGlobal.getStatesEeuu({});

        if (response.status == 200) {
          response.data.map((item) =>
            this.states.push({ value: item.slug, label: item.state })
          );
        }
      } catch (error) {
        throw error;
      }
    },
    activeFocus(index, max) {
      let inputValue = this.$refs?.[`input-${index}`];
      if (inputValue.value.length === max - 1) {
        const nextElement = this.$refs?.[`input-${index + 1}`];
        if (nextElement) nextElement.focus();
      }
    },
    getAddressData(mainAddress) {
      let address = mainAddress.street_number
        ? mainAddress.street_number + " " + mainAddress.route
        : mainAddress.route;

      this.direccion = mainAddress;
      this.address_create_card_modal =
        this.direccion.street_number + " " + this.direccion.route;
      var x = document.getElementById("address_create_card_modal");
      x.value = this.address_create_card_modal;

      this.form.street = address;
      this.form.address = this.address_create_card_modal;
      this.form.city = mainAddress.locality;
      this.form.state = mainAddress.administrative_area_level_1;
      this.form.zipcode = mainAddress.postal_code;
    },
    createCard() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.form.cardnumber =
          this.cardnumber1 +
          "-" +
          this.cardnumber2 +
          "-" +
          this.cardnumber3 +
          "-" +
          this.cardnumber4;
        var x = document.getElementById("address_create_card_modal");
        if (x)
          if (x.value != null && x.value != "") {
            this.form.street = x.value;
          } else {
            this.form.street = "";
          }
        this.showConfirmSwal().then((result) => {
          if (result.isConfirmed) {
            this.$store.commit("app/SET_LOADING", true);

            const params = {
              idlead: this.lead,
              card_expi_month: this.form.card_expi_month,
              card_expi_year: this.form.card_expi_year,
              cardnumber: this.form.cardnumber,
              cardsecuritycode: this.form.cardsecuritycode,
              city: this.form.city,
              state: this.form.state,
              country: this.form.country,
              zipcode: this.form.zipcode,
              address: this.form.address,
              cardholdername: this.form.cardholdername,
              street: this.form.street,
              user: this.currentUser.user_id,
            };

            amgApi
              .post(
                "/cards/create-cards",

                params
              )
              .then((response) => {
                this.cards = response.data;
                this.$emit("reload");
                this.closeModal();

                this.$store.commit("app/SET_LOADING", false);
                this.showSuccessSwal("Credit Card created successfully");
              })
              .catch((error) => {
                console.error(error);
                this.$store.commit("app/SET_LOADING", false);
                this.showToast(
                  "danger",
                  "top-right",
                  "Error",
                  "XIcon",
                  "Something went wrong!"
                );
              });
          }
        });
      });
    },
    closeModal() {
      this.form.cardnumber = "";
      this.$emit("close");
    },

    async eyeCard() {
      try {
        if (this.form.cardnumber == "" || this.form.cardnumber == null) {
          const { data } = await crmGlobal.getOriginalTarget({
            id: this.dataCard.id,
          });
          this.form.cardnumber = data[0];
        }
      } catch (error) {
        console.log(error);
        this.form.cardnumber = "";
      } finally {
        this.showCard = !this.showCard;
      }
    },

    eyeCode() {
      this.showCode = !this.showCode;
    },
  },
  async created() {
    if (this.dataCard != null) {
      this.form.cardholdername = this.dataCard.cardholdername;
      this.form.card_expi_month = this.dataCard.card_expi_month;
      this.form.card_expi_year = this.dataCard.card_expi_year;
      this.form.cardsecuritycode = this.dataCard.cardsecuritycode;
      this.cardnumber4 = this.dataCard.cardnumber;
      this.modalView = true;
    } else {
      await this.getEeuuStates();
    }

    if (this.type === "amg-cards") {
      this.moreInfo = 0;
    }
    this.ownControl = true;
  },
};
</script>

<style scoped>
* {
  --primary-color: #3f7afa;
}
.font-bureau-style {
  color: #706989;
}

label {
  font-weight: 300 !important;
}
.border-hover-p:hover,
.border-hover-p:active {
  border: 2px solid #7000ff !important;
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15));
}
.header-modal {
  background-color: var(--primary-color) !important;
  color: white;
  width: 100% !important;
  padding: 12px;
  border-radius: 1.5rem 1.5rem 0px 0px;
}
.close-button {
  background-color: white;
  color: var(--primary-color);
  cursor: pointer;
  border-radius: 3px;
}
.custom-button-dark {
  background-color: var(--primary-color) !important;
  color: black !important;
}
.custom-button-light {
  background-color: var(--primary-color) !important;
  color: white !important;
}
</style>
