export default [
    {
      id: 0,
      value: "ALL",
    },
    {
      id: 2,
      value: "YES",
    },
    {
      id: 1,
      value: "NO",
    },
  ]