<template>
  <div class="px-2 mt-1">
    <header-slot>
      <template #actions>
        <b-button
          variant="success"
          class="mr-1"
          @click="openModalRequestPersonnel()"
        >
          <feather-icon
            icon="UserPlusIcon"
            size="15"
            class="mr-50 text-white"
          />Request personnel
        </b-button>
      </template>
    </header-slot>
    <b-nav
      v-if="currentUser.role_id === 1 || currentUser.modul_id === 17"
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        :to="{ name: $route.meta.requestRecruitment }"
        exact
        exact-active-class="active"
        :active="$route.matched[2].name == $route.meta.requestRecruitment"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Request Recruitment
        <span
          v-if="counter > 0"
          class="ml-2"
        >
          <feather-icon
            icon
            :badge="counter <= 99 ? counter : '99+'"
            badge-classes="badge-danger"
          />
        </span>
      </b-nav-item>
      <b-nav-item
        v-if="currentUser.main_module===17"
        :to="{ name: $route.meta.approvedRecruitment }"
        exact
        exact-active-class="active"
        :active="$route.matched[2].name == $route.meta.approvedRecruitment"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Approved
      </b-nav-item>
      <b-nav-item
        v-if="currentUser.role_id === 1"
        :to="{ name: $route.meta.requestRecruitmentCeo }"
        exact
        exact-active-class="active"
        :active="$route.matched[2].name == $route.meta.requestRecruitmentCeo"
        :link-classes="['px-3', bgTabsNavs]"
      >
        Pending Approve by Ceo
      </b-nav-item>
    </b-nav>
    <!-- <main-grid
      v-else
    /> -->
    <b-card
      :key="gridReload"
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.fullPath" />
    </b-card>
    <modal-request-personnel
      v-if="showModalRequestPersonnel"
      @closeModalRequestPersonnel="closeModalRequestPersonnel"
      @refreshTableOnCreate="refreshTableOnCreate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalRequestPersonnel from '@/views/commons/components/recruitment-process/components/modals/ModalRequestPersonnel.vue';

export default {
  components: {
    ModalRequestPersonnel,
  },
  data() {
    return {
      gridReload: 0,
      showModalRequestPersonnel: false,
    };
  },
  computed: {
    isManagement() {
      return this.$route.matched[0].meta.module === 16;
    },
    ...mapGetters({
      counter_management: 'SalaryRecordStore/G_COUNT_MANAGEMENT',
      counter_rrhh: 'SalaryRecordStore/G_COUNT_RRHH',
      currentUser: 'auth/currentUser',
    }),
    counter() {
      return this.isManagement ? this.counter_management : this.counter_rrhh;
    },
  },
  async created() {
    await this.$store.dispatch('SalaryRecordStore/A_GET_COUNTER_SALARY');
  },
  methods: {
    refreshTableOnCreate() {
      this.gridReload += 1;
    },
    openModalRequestPersonnel() {
      this.showModalRequestPersonnel = true;
    },
    closeModalRequestPersonnel() {
      this.showModalRequestPersonnel = false;
    },
  },
};
</script>
