import store from '@/store'
import Vue from "vue";

const SentCiLetterNotification = () => {
    window.socket.bind('correspondence-ci-notification-debtsolution', async (response) => {
        const { user, number } = response;

        const current_user_id = store.state.auth.currentUser.user_id;

        if (user == current_user_id) {
            Vue.prototype.$amgAlert({
                title: 'Documents Sent',
                subtitle: 'Documents Sent For Correspondence',
                data: [
                    { icon: 'BookIcon', title: 'CASE NUMBER', description: number },
                ],

                okTitle: 'Ok',
                icon: "CheckIcon",
            });
        }
    })
}


export { SentCiLetterNotification }

