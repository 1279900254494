export default [
  {
    path: 'activities',
    name: 'activities-social-network',
    component: () => import(/* webpackChunkName: "SnActivities" */ '@/views/social-network/views/activities/MainActivities.vue'),
    redirect: { name: 'sn-schedule' },
    // permittedRoles: [10],
    children: [
      {
        path: '',
        redirect: { name: 'sn-schedule' },
        // permittedRoles: [10],
      },
      {
        path: 'schedule',
        component: () => import(/* webpackChunkName: "SnActivitiesSchedule" */ '@/views/social-network/views/activities/schedule/Schedule.vue'),
        name: 'sn-schedule',
        meta: {
          pageTitle: 'Activities',
          breadcrumb: [
            {
              text: 'Activities'
            },
            {
              text: 'Schedule',
              active: true
            },
          ],
        },
      },
      {
        path: 'weekly-activities',
        component: () => import(/* webpackChunkName: "SnActivitiesWeekly" */ '@/views/social-network/views/activities/weekly/Weekly.vue'),
        name: 'sn-weekly-activities',
        meta: {
          pageTitle: 'Activities',
          breadcrumb: [
            {
              text: 'Activities'
            },
            {
              text: 'Weekly Activities',
              active: true
            },
          ],
        },
      },
    ],
  },
]
