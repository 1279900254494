<template>
  <b-card>
    <b-row class="px-1 mb-2">
      <h3
        class="font-weight-bolder px-1 border-bottom-secondary border-2 pb-1 w-100"
      >
        Contact Information
      </h3>
    </b-row>
    <b-row>
      <!-- Contact Method -->
      <b-col md="6" lg="3">
        <validation-provider
          v-slot="{ errors }"
          name="Contact Method"
          rules="required"
        >
          <b-form-group
            label-for="contact-method"
            :state="errors[0] ? false : null"
          >
            <template #label>
              <span class="font-ce"> Preferred Contact Method </span>
            </template>
            <v-select
              v-model="userData.contact_method"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="contactMethodOptions"
              :clearable="false"
              input-id="contact-method"
              :reduce="(el) => el.value"
              :disabled="disabledForm"
              multiple
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Contact Method ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6" lg="3">
        <!-- Mobile -->
        <validation-provider v-slot="{ errors }" name="Mobile" rules="required">
          <b-form-group label="Phone (M)" label-for="mobile">
            <template #label>
              <span
                class="font-ce d-flex justify-content-between align-items-center"
              >
                Phone (M)
                <span
                  v-if="userData.id"
                  class="see-tracking"
                  @click="onModalTrackingChangeOpen(3, 'PHONE (M)')"
                  >See Tracking</span
                >
              </span>
            </template>
            <b-input-group>
              <b-form-input
                id="mobile"
                v-model="userData.mobile"
                trim
                maxlength="14"
                :disabled="userData.id && disabled.mobile"
                :state="errors[0] ? false : null"
                class="font-ce"
                @keyup.native="mobile()"
              />
              <template v-if="userData.id && !disabledForm">
                <b-input-group-append
                  v-if="!disabled.mobile"
                  class="border-right"
                >
                  <b-button
                    variant="outline-primary"
                    class="btn-sm"
                    @click="onSubmitFields('phonem', 3, 'mobile')"
                  >
                    <feather-icon icon="SaveIcon" class="cursor-pointer" />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append class="border-right">
                  <b-button
                    variant="outline-warning"
                    class="btn-sm"
                    @click="toggleElement('mobile')"
                  >
                    <feather-icon
                      v-if="disabled.mobile"
                      icon="Edit2Icon"
                      class="cursor-pointer"
                    />
                    <amg-icon
                      v-else
                      icon="Edit2SlashIcon"
                      class="cursor-pointer"
                    />
                  </b-button>
                </b-input-group-append>
              </template>
            </b-input-group>
            <span v-if="errors[0]" class="text-danger">{{
              `Mobile ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6" lg="3">
        <!-- Phone -->
        <validation-provider name="Phone">
          <b-form-group label="Phone (H)" label-for="phone">
            <template #label>
              <span
                class="font-ce d-flex justify-content-between align-items-center"
              >
                Phone (H)
                <span
                  v-if="userData.id"
                  class="see-tracking"
                  @click="onModalTrackingChangeOpen(2, 'PHONE (H)')"
                  >See Tracking</span
                >
              </span>
            </template>
            <b-input-group>
              <b-form-input
                id="phone"
                v-model="userData.phone"
                trim
                maxlength="14"
                class="font-ce"
                :disabled="userData.id && disabled.phone"
                @keyup.native="phone()"
              />
              <template v-if="userData.id && !disabledForm">
                <b-input-group-append
                  v-if="!disabled.phone"
                  class="border-right"
                >
                  <b-button
                    variant="outline-primary"
                    class="btn-sm"
                    @click="onSubmitFields('phoneh', 2, 'phone')"
                  >
                    <feather-icon icon="SaveIcon" class="cursor-pointer" />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append class="border-right">
                  <b-button
                    variant="outline-warning"
                    class="btn-sm"
                    @click="toggleElement('phone')"
                  >
                    <feather-icon
                      v-if="disabled.phone"
                      icon="Edit2Icon"
                      class="cursor-pointer"
                    />
                    <amg-icon
                      v-else
                      icon="Edit2SlashIcon"
                      class="cursor-pointer"
                    />
                  </b-button>
                </b-input-group-append>
              </template>
            </b-input-group>
          </b-form-group>
        </validation-provider>
      </b-col>
      <!-- Email -->
      <b-col md="6" lg="3">
        <validation-provider v-slot="{ errors }" name="Email">
          <b-form-group label="Email" label-for="email">
            <template #label>
              <span class="font-ce"> Email </span>
            </template>
            <b-form-input
              id="email"
              v-model="userData.email"
              trim
              class="font-ce"
              :state="errors[0] ? false : null"
              :disabled="disabledForm"
            />
            <span v-if="errors[0]" class="text-danger font-ce">{{
              `Email ${errors[0]}`
            }}</span>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <address-component
      :address-data="userData.address"
      @onSubmitAddress="onSubmitAddress"
      @onModalTrackingChangeOpen="onModalTrackingChangeOpen(1, 'ADDRESS')"
      :isDisabled="disabledForm"
    />
    <b-row>
      <b-col md="12" v-if="!disabledForm">
        <b-form-group>
          <b-form-checkbox
            name="another-address"
            v-model="hideWithOtherAddress"
            class="mt-2"
            style="visibility: hidden; position: absolute"
            >Another address?</b-form-checkbox
          >
          <div
            class="d-flex justify-content-center"
            :class="
              hideWithOtherAddress
                ? 'cancel-other-address'
                : 'add-other-address'
            "
            @click="toggleCheckbox"
          >
            {{
              hideWithOtherAddress
                ? "- Cancel other address"
                : "+ Add other address"
            }}
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <address-component
      v-if="hideWithOtherAddress"
      :address-data="userData.otherAddress"
      @onSubmitAddress="onSubmitAnotherAddress"
      @onModalTrackingChangeOpen="onModalTrackingChangeOpen(8, 'OTHER ADDRESS')"
      :isDisabled="disabledForm"
    />
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import AddressComponent from "@/views/commons/components/leads/leads-form-components/AddressComponent.vue";

export default {
  components: {
    AddressComponent,
    VueGoogleAutocomplete,
  },
  props: {
    disabledForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    userData: {
      type: Object,
      required: true,
      default: () => new Object(),
    },
    blankUserFields: {
      type: Object,
      required: false,
    },
    typeForm: {
      type: String,
      required: false, // newLead, editLead
    },
  },
  data() {
    return {
      contactMethodOptions: [
        { label: "PHONE", value: "PHONE" },
        { label: "SMS", value: "SMS" },
        { label: "EMAIL", value: "EMAIL" },
        { label: "WHATSAPP", value: "WHATSAPP" },
      ],
      blankUserData: new Object(),
      disabled: {
        ssn: true,
        itin: true,
        other: true,
        phone: true,
        mobile: true,
        saveSSN: false,
        saveITIN: false,
      },
      hideWithOtherAddress: false,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  created() {
    this.setDataBlank("userData");
  },
  methods: {
    ...mapActions({
      A_UNIQUE_MOBILE: "CrmGlobalStore/A_UNIQUE_MOBILE",
      A_SET_REQUEST_LEADS: "CrmLeadStore/A_SET_REQUEST_LEADS",
      A_UPDATE_FIELDS_LEAD: "CrmLeadStore/A_UPDATE_FIELDS_LEAD",
    }),
    toggleCheckbox() {
      this.hideWithOtherAddress = !this.hideWithOtherAddress;
    },
    encryptDocument(document) {
      return document ? `xxx-xx-${document.substr(7)}` : "";
    },
    setDataBlank(key) {
      this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`] =
        Object.assign({}, this[key]);
    },
    resetElement(key, subkey) {
      const object = this[`blank${key.charAt(0).toUpperCase()}${key.slice(1)}`];
      this[key][subkey] = object[subkey];
    },
    phone() {
      var x = this.userData.phone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.userData.phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    async mobile() {
      try {
        var x = this.userData.mobile
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.userData.mobile = !x[2]
          ? x[1]
          : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        if (this.userData.mobile.length == 14) {
          this.isPreloading(true);
          const response = await this.A_UNIQUE_MOBILE({
            mobile: this.userData.mobile,
          });
          if (response.status == 200) {
            if (response.data.error_code == "MOBILE_EXISTS") {
              this.showConfirmSwal("Are you sure?", response.data.message, {
                confirmButtonText:
                  this.typeForm == "newLead"
                    ? "REQUEST LEAD TO SOCIAL NETWORK"
                    : "OK",
              })
                .then(async (result) => {
                  if (result.value && this.typeForm == "newLead") {
                    this.isPreloading(true);
                    await this.A_SET_REQUEST_LEADS({
                      lead_id: response.data.lead_id,
                      lead_name: response.data.message,
                    });
                    this.isPreloading(false);
                  }
                })
                .catch((error) => {
                  throw error;
                });
            }
            this.isPreloading(false);
          }
        }
      } catch (error) {
        console.log("Something went wrong mobile:", error);
        this.isPreloading(false);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    toggleElement(key) {
      this.disabled[key] = !this.disabled[key];
      if (this.disabled[key]) this.resetElement("userData", key);
    },
    async onSubmitFields(key, typee, subkey) {
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            this.isPreloading(true);
            const response = await this.A_UPDATE_FIELDS_LEAD({
              ...this.blankUserFields,
              typee,
              [key]: this.userData[subkey]
                ? this.userData[subkey]
                : this.userData[key],
            });
            if (this.userData[subkey]) {
              key = subkey;
            }
            this.isPreloading(false);
            if (this.isResponseSuccess(response)) {
              this.blankUserData[key] = this.userData[key];
              this.toggleElement(key);
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                "Something went wrong." + response.message
              );
          }
        })
        .catch((error) => {
          console.log("Something went wrong onSubmitFields: ", error);
          this.isPreloading(false);
          this.showErrorSwal();
        });
    },
    async onSubmitAddress() {
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            this.isPreloading(true);
            const response = await this.A_UPDATE_FIELDS_LEAD({
              ...this.blankUserFields,
              typee: 1,
              street: this.userData.address.street,
              city: this.userData.address.city,
              state: this.userData.address.state,
              zipcode: this.userData.address.zipcode,
              country: this.userData.address.country,
            });
            this.isPreloading(false);
            if (this.isResponseSuccess(response)) {
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                "Something went wrong." + response.message
              );
          }
        })
        .catch((error) => {
          console.log("Something went wrong onSubmitAddress: ", error);
          this.isPreloading(false);
          this.showErrorSwal();
        });
    },
    async onSubmitAnotherAddress() {
      this.showConfirmSwal()
        .then(async (result) => {
          if (result.value) {
            this.isPreloading(true);
            const response = await this.A_UPDATE_FIELDS_LEAD({
              ...this.blankUserFields,
              typee: 8,
              other_street: this.userData.otherAddress.street,
              other_city: this.userData.otherAddress.city,
              other_state: this.userData.otherAddress.state,
              other_zipcode: this.userData.otherAddress.zipcode,
              other_country: this.userData.otherAddress.country,
            });
            this.isPreloading(false);
            if (this.isResponseSuccess(response)) {
              this.showToast(
                "success",
                "top-right",
                "Success!",
                "CheckIcon",
                "Successful operation"
              );
            } else
              this.showToast(
                "warning",
                "top-right",
                "Warning!",
                "AlertTriangleIcon",
                "Something went wrong." + response.message
              );
          }
        })
        .catch((error) => {
          console.log("Something went wrong onSubmitAnotherAddress: ", error);
          this.isPreloading(false);
          this.showErrorSwal();
        });
    },
    onModalTrackingChangeOpen(type, name) {
      this.$emit("onModalTrackingChangeOpen", {
        type,
        name,
        mapFunction: (el) => ({
          ...el,
          main_row: el.fields,
          main_row_hide: el.fields_secret,
          seeHideCell: false,
        }),
      });
    },
  },
  mounted() {
    if (this.userData.id)
      this.hideWithOtherAddress = this.userData.otherAddress.street !== null;
  },
  watch: {
    hideWithOtherAddress(current, old) {
      if (current) {
        this.userData.another_address = 1;
      } else {
        this.userData.another_address = 0;
        this.userData.otherAddress.street = "";
        this.userData.otherAddress.city = "";
        this.userData.otherAddress.state = "";
        this.userData.otherAddress.zipcode = "";
        this.userData.otherAddress.country = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.sidebar-xl {
  width: 90rem;
}
.add-other-address {
  border-radius: 10px;
  border: 3px dotted #08ba25;
  color: #08ba25;
  padding: 1rem;
  cursor: pointer;
}
.cancel-other-address {
  border-radius: 10px;
  border: 3px dotted #ff0000;
  color: #ff0000;
  padding: 1rem;
  cursor: pointer;
}
.see-tracking {
  cursor: pointer;
  color: rgb(0, 183, 255);
  transition: text-decoration 0.1s, font-weight 0.1s;
}
.see-tracking:hover {
  text-decoration: underline;
  font-weight: bold;
}
</style>
<style scoped>
* {
  font-family: "Montserrat", sans-serif !important;
}
.form-group {
  font-size: 16px !important;
}
.card-title h4 {
  font-size: 22px;
}
.font-ce {
  font-size: 16px;
}
</style>
