import { amgApi } from '@/service/axios';
import store from '@/store';

class WebsiteLeadsServices {
  async markWebsiteLeadAsSeen(params) {
    try {
      const { data } = await amgApi.post('/website-leads/update', params);
      return data;
    } catch (error) {
      console.log('Something went wrong on markWebsiteLeadAsSeen:', error);
      throw error;
    }
  }

  async getWebsiteLeadsUnseen(params) {
    try {
      const { data } = await amgApi.post('/website-leads/show', params);
      return data;
    } catch (error) {
      console.log('Something went wrong on getWebsiteLeadsUnseen:', error);
      throw error;
    }
  }

  async updateLeadsUnseenCounter() {
    try {
      const { data } = await amgApi.post('/website-leads/show');
      console.log(data);
      const payload = {
        routeName: 'lead-crm',
        tag: data.data.leads_unseen,
      };
      store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
      store.commit('LeadsStore/M_SET_WEBSITE_LEADS_UNSEED', data.data.leads_unseen);
      return data;
    } catch (error) {
      console.log('Something went wrong on getWebsiteLeadsUnseen:', error);
      throw error;
    }
  }
}

export default new WebsiteLeadsServices();
