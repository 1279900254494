export default [
  {
    path: "clients",
    name: "tax-research-clients",
    redirect: { name: "tax-research-clients-main" },
    component: () =>
      import(
        /* webpackChunkName: "TaxResearchClient" */ "@/views/tax-research/views/clients/Clients.vue"
      ),
    meta: {
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Clients",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "tax-research-clients-main",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientTabMain" */ "@/views/tax-research/views/clients/components/ClientsGridTable.vue"
          ),
        meta: {
          type: "clients",
          tab: 1,
          pageTitle: "Clients",
          list_table: "Main",
          breadcrumb: [
            {
              text: "Main",
              active: true,
            },
          ],
        },
      },
      {
        path: "shared",
        name: "tax-research-shared-tab",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientShared" */ "@/views/commons/components/clients/components/Shared.vue"
          ),
        meta: {
          tab: 3,
          pageTitle: "Clients",
          program: 5,
          breadcrumb: [
            {
              text: "Shared",
              active: true,
            },
          ],
          route: "bussiness",
        },
      },
      {
        path: "archived",
        name: "tax-research-clients-archived",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientTabArchived" */ "@/views/tax-research/views/clients/components/ClientsGridTable.vue"
          ),
        meta: {
          type: "clients",
          tab: 1,
          pageTitle: "Clients",
          list_table: "Archived",
          breadcrumb: [
            {
              text: "Archived",
              active: true,
            },
          ],
        },
      },
      {
        path: "calls",
        name: "tax-research-clients-calls",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientTabCalls" */ "@/views/tax-research/views/clients/components/ClientsGridTable.vue"
          ),
        meta: {
          type: "clients",
          list_table: "CallsRound",
          tab: 1,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Calls Round",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "clients/account/:idClient",
    name: "tax-research-client-dashboard",
    redirect: { name: "personal-information-tax-research" },
    component: () =>
      import(
        /* webpackChunkName: "TaxResearchClientDashboard" */ "@/views/tax-research/views/clients/dashboard/AccountTaxResearch.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay",
        name: "pay-tax-research",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "task-notes",
        name: "task-notes-tax-research",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information",
        name: "personal-information-tax-research",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientPersonalInformation" */ "@/views/tax-research/views/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files",
        name: "client-files-tax-research",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr",
        name: "acr-tax-research",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log",
        name: "call-log-tax-research",
        redirect: { name: "call-log-conversation-tax-research" },
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientAcr" */ "@/views/commons/components/ring-central/call-log/tabs/ClientTabs.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
          callConversationRoute: "call-log-conversation-tax-research",
          callNoConversationRoute: "call-log-not-conversation-tax-research",
        },

        children: [
          {
            path: "conversation",
            name: "call-log-conversation-tax-research",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Internal",
                  active: true,
                },
              ],
              isConversation: 1,
            },
          },
          {
            path: "no-conversation",
            name: "call-log-not-conversation-tax-research",
            component: () =>
              import(
                /* webpackChunkName: "call-log-all" */
                "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
              ),
            meta: {
              pageTitle: "Call log",
              breadcrumb: [
                {
                  text: "Internal",
                  active: true,
                },
              ],
              isConversation: 0,
            },
          },
        ],
      },
      {
        path: "tickets",
        name: "client-tickets-tax-research",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
