export default [
  {
    path: "zero-payment",
    name: "zero-payment-debt-solution",
    component: () => import(/* webpackChunkName: "DSZeroPayment" */ "@/views/debt-solution/views/zero-payment/TemplateZeroPayment.vue"),
    meta: {
      pageTitle: "Zero Payment",
      breadcrumb: [
        {
          text: "ZERO PAYMENT",
          active: true,
        },
      ],
      permittedRoles: [1, 2]
    },
  },
];