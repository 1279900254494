export default [
    {
        path: 'bank-of-flyers',
        name: 'bank-of-flyers-paragon',
        redirect: { name: 'list-active-paragon' },
        component: () => import(/* webpackChunkName: "PRBankOfFiyers" */ '@/views/commons/components/bank-of-flyers/BankOfFlyers.vue'),
        children: [
            {
                path: 'active',
                component: () => import(/* webpackChunkName: "PRBankOfFiyersActive" */ '@/views/commons/components/bank-of-flyers/bank-of-flyers-module/bank-of-flyers-list-active/BankOfFlyerActive.vue'),
                name: 'list-active-paragon',
                meta: {
                    permittedRoles: [1, 3, 7, 17],
                    pageTitle: "Bank of flyers",
                    breadcrumb: [{
                        text: 'Active',
                        active: true,
                    }],
                    route: "ti"
                },
            },
            {
                path: 'inactive',
                component: () => import(/* webpackChunkName: "PRBankOfFiyersInactive" */ '@/views/commons/components/bank-of-flyers/bank-of-flyers-module/bank-of-flyers-list-inactive/BankOfFlyerInactive.vue'),
                name: 'list-inactive-paragon',
                meta: {
                    pageTitle: "Bank of flyers",
                    breadcrumb: [{
                        text: 'Inactive',
                        active: true,
                    }],
                    route: "ti"
                },
            },
            {
                path: 'preactive',
                component: () => import(/* webpackChunkName: "PRBankOfFiyersPreactive" */ '@/views/commons/components/bank-of-flyers/bank-of-flyers-module/bank-of-flyer-preactive/BankOfFlyerPreActive.vue'),
                name: 'list-preactive-paragon',
                meta: {
                    pageTitle: "Bank of flyers",
                    breadcrumb: [{
                        text: 'Preactive',
                        active: true,
                    }],
                    route: "ti"
                },
            },

        ],
        meta: {
            pageTitle: "Bank of flyers",
            breadcrumb: [{
                text: 'Bank of flyers',
                active: true,
            }],
            route: "ti"
        },
    },
]
