export default [
  {
    path: "/management/approval-process",
    name: "management-approved",
    redirect: { name: "management-approved-permissions" },
    component: () =>
      import(
        /* webpackChunkName: "ManaApprovalProcessMain" */ "@/views/management/views/approval-process/ApprovalProcess.vue"
      ),
    meta: {
      pageTitle: "Approval Process",
      breadcrumb: [
        {
          text: "Approval Process",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "permissions",
        component: () =>
          import(
            /* webpackChunkName: "ManaApprovalProcessPermissions" */ "@/views/management/views/approval-process/permissions/view/components/SettingsPermissions.vue"
          ),

        name: "management-approved-permissions",
        meta: {
          pageTitle: "Approval Process",
          breadcrumb: [
            {
              text: "Permissions",
              active: true,
            },
          ],
        },
      },
      {
        path: "credit-experts-sellers",
        component: () =>
          import(
            "@/views/management/views/approval-process/sales/SellerLevel.vue"
          ),
        name: "credit-experts-sellers-permissions",
        redirect: { name: "credit-experts-sellers-pending" },
        meta: {
          pageTitle: "Approval Process",
          breadcrumb: [
            {
              text: "Credit Experts Sellers",
              active: true,
            },
          ],
          module: 16,
        },
        children: [
          {
            path: "pending",
            component: () =>
              import(
                "@/views/management/views/approval-process/sales/components/SalesPermissions.vue"
              ),
            name: "credit-experts-sellers-pending",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              pendingTab: true,
              breadcrumb: [
                {
                  text: "Credit Experts Sellers",
                  active: false,
                },
                {
                  active: true,
                  text: "Pending",
                },
              ],
            },
          },
          {
            path: "completed",
            component: () =>
              import(
                "@/views/management/views/approval-process/sales/components/GridTable.vue"
              ),
            name: "credit-experts-sellers-completed",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              breadcrumb: [
                {
                  text: "Credit Experts Sellers",
                  active: false,
                },
                {
                  text: "Completed",
                  active: true,
                },
              ],
            },
          },
          {
            path: "rejected",
            component: () =>
              import(
                "@/views/management/views/approval-process/sales/components/GridTable.vue"
              ),
            name: "credit-experts-sellers-rejected",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              breadcrumb: [
                {
                  text: "Credit Experts Sellers",
                  active: false,
                },
                {
                  text: "Rejected",
                  active: true,
                },
              ],
            },
          },
          {
            path: "canceled",
            component: () =>
              import(
                "@/views/management/views/approval-process/sales/components/GridTable.vue"
              ),
            name: "credit-experts-sellers-canceled",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              breadcrumb: [
                {
                  text: "Credit Experts Sellers",
                  active: false,
                },
                {
                  text: "Canceled",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "settlement-approved",
        component: () =>
          import(
            /* webpackChunkName: "ManaApprovalProcessSettlement" */ "@/views/management/views/approval-process/settlement-approval/Settlements.vue"
          ),

        name: "settlements",
        redirect: { name: "settlements-pending" },
        meta: {
          pageTitle: "Approval Process",
          module: 16,
          pendingTab: true,
          breadcrumb: [
            {
              text: "Settlements",
              active: false,
            },
          ],
        },
        children: [
          {
            path: "pending",
            component: () =>
              import(
                /* webpackChunkName: "ManaSettlementsPending" */ "@/views/management/views/approval-process/settlement-approval/components/GridTable.vue"
              ),

            name: "settlements-pending",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              pendingTab: true,
              breadcrumb: [
                {
                  text: "Settlements",
                  active: false,
                },
                {
                  active: true,
                  text: "Pending",
                },
              ],
            },
          },
          {
            path: "completed",
            component: () =>
              import(
                /* webpackChunkName: "ManaSettlemenetsCompleted" */ "@/views/management/views/approval-process/settlement-approval/components/GridTable.vue"
              ),

            name: "settlements-completed",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              breadcrumb: [
                {
                  text: "Settlements",
                  active: false,
                },
                {
                  text: "Completed",
                  active: true,
                },
              ],
            },
          },
          {
            path: "rejected",
            component: () =>
              import(
                /* webpackChunkName: "ManaSettlementsRejected" */ "@/views/management/views/approval-process/settlement-approval/components/GridTable.vue"
              ),

            name: "settlements-rejected",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              breadcrumb: [
                {
                  text: "Settlements",
                  active: false,
                },
                {
                  text: "Rejected",
                  active: true,
                },
              ],
            },
          },
        ],
      },
      {
        path: "other-payments",
        name: "other-payments",
        component: () =>
          import(
            /* webpackChunkName: "ManaOtherPaymentsMain" */ "@/views/management/views/approval-process/other-payments/OtherPaymentsTabs"
          ),
        redirect: { name: "other-payments-pending" },
        meta: {
          pageTitle: "Approval Process",
          parentModule: 21,
          module: 23,
          pendingRoute: "other-payments-pending",
          completedRoute: "other-payments-completed",
          rejectedRoute: "other-payments-rejected",
        },
        children: [
          {
            path: "pending",
            component: () =>
              import(
                /* webpackChunkName: "ManaOtherPaymentsPending" */ "@/views/management/views/approval-process/other-payments/components/PendingTab"
              ),
            name: "other-payments-pending",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              pendingTab: true,
              breadcrumb: [
                {
                  text: "Other Payments",
                  active: false,
                },
                {
                  text: "Pending",
                  active: true,
                },
              ],
              pendingRoute: "other-payments-pending",
              completedRoute: "other-payments-completed",
              rejectedRoute: "other-payments-rejected",
            },
          },
          {
            path: "completed",
            component: () =>
              import(
                /* webpackChunkName: "ManaOtherPaymentsCompleted" */ "@/views/management/views/approval-process/other-payments/components/CompletedTab"
              ),
            name: "other-payments-completed",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              breadcrumb: [
                {
                  text: "Other Payments",
                  active: false,
                },
                {
                  text: "Completed",
                  active: true,
                },
              ],
              pendingRoute: "other-payments-pending",
              completedRoute: "other-payments-completed",
              rejectedRoute: "other-payments-rejected",
            },
          },
          {
            path: "rejected",
            component: () =>
              import(
                /* webpackChunkName: "ManaOtherPaymentsRejected" */ "@/views/management/views/approval-process/other-payments/components/RejectedTab"
              ),
            name: "other-payments-rejected",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              breadcrumb: [
                {
                  text: "Other Payments",
                  active: false,
                },
                {
                  text: "Rejected",
                  active: true,
                },
              ],
              pendingRoute: "other-payments-pending",
              completedRoute: "other-payments-completed",
              rejectedRoute: "other-payments-rejected",
            },
          },
        ],
      },
      {
        path: "disapproved-sale",
        name: "disapproved-sale",
        component: () =>
          import(
            /* webpackChunkName: "DisapprovedSaleDSTabsMain" */ "@/views/management/views/approval-process/disapproved-sale-ds/view/DisapprovedSaleDSTabs"
          ),
        redirect: { name: "disapproved-sale-from-ds" },
        meta: {
          pageTitle: "Disapproved sale",
          parentModule: 21,
          module: 23,
          fromDSRoute: "disapproved-sale-from-ds",
          approvedRoute: "disapproved-sale-approved",
          rejectedRoute: "disapproved-sale-rejected",
        },
        children: [
          {
            path: "disapproved-sale-from-ds",
            component: () =>
              import(
                /* webpackChunkName: "DisapprovedSaleFromDS" */ "@/views/debt-solution/views/sales-made/components/pending/PendingComponent.vue"
              ),
            name: "disapproved-sale-from-ds",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              pendingTab: true,
              breadcrumb: [
                {
                  text: "Disapproved sale",
                  active: false,
                },
                {
                  text: "From DS",
                  active: true,
                },
              ],
              fromDSRoute: "disapproved-sale-from-ds",
              approvedRoute: "disapproved-sale-approved",
              rejectedRoute: "disapproved-sale-rejected",
              tab: 3,
            },
          },
          {
            path: "disapproved-sale-approved",
            component: () =>
              import(
                /* webpackChunkName: "DisapprovedSaleApproved" */ "@/views/debt-solution/views/sales-made/components/pending/PendingComponent.vue"
              ),
            name: "disapproved-sale-approved",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              pendingTab: true,
              breadcrumb: [
                {
                  text: "Disapproved sale",
                  active: false,
                },
                {
                  text: "Approved",
                  active: true,
                },
              ],
              fromDSRoute: "disapproved-sale-from-ds",
              approvedRoute: "disapproved-sale-approved",
              rejectedRoute: "disapproved-sale-rejected",
              tab: 5,
            },
          },
          {
            path: "disapproved-sale-rejected",
            component: () =>
              import(
                /* webpackChunkName: "DisapprovedSaleRejected" */ "@/views/debt-solution/views/sales-made/components/pending/PendingComponent.vue"
              ),
            name: "disapproved-sale-rejected",
            meta: {
              pageTitle: "Approval Process",
              module: 16,
              pendingTab: true,
              breadcrumb: [
                {
                  text: "Disapproved sale",
                  active: false,
                },
                {
                  text: "Rejected",
                  active: true,
                },
              ],
              fromDSRoute: "disapproved-sale-from-ds",
              approvedRoute: "disapproved-sale-approved",
              rejectedRoute: "disapproved-sale-rejected",
              tab: 4,
            },
          },
        ],
      },
    ],
  },
];
