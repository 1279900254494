<template>
  <div>
    <b-modal
      v-model="showModal"
      size="xlg"
      scrollable
      title="New Items"
      header-bg-variant="transparent"
      modal-class="custom-modal-amg"
      :hide-footer="hideFooter || [11, 28, 29].includes(currentUser.modul_id)"
      @hidden="closeModal"
    >
      <template v-slot:modal-header>
        <div class="header-modal d-flex justify-content-between">
          <h3 class="text-white m-0 font-weight-bold">
            New Items
          </h3>
          <div>
            <feather-icon
              icon="XIcon"
              size="22"
              class="close-button"
              @click="closeModal"
            />
          </div>
        </div>
      </template>
      <b-row class="py-1">
        <b-col cols="12" md="4" lg="4">
          <div
            class="info-container"
            :class="isDarkSkin ? 'text-white' : 'text-dark'"
          >
            <p class="m-0" style="gap: 5px">
              Client | {{ clientData.client_name }}
            </p>
          </div>
        </b-col>
        <b-col v-if="hideAccount" cols="12" md="4" lg="4">
          <div
            class="info-container"
            :class="isDarkSkin ? 'text-white' : 'text-dark'"
          >
            <p class="m-0" style="gap: 5px">
              Client Account | {{ clientData.client_account }}
            </p>
          </div>
        </b-col>
      </b-row>
      <div>
        <ItemsToRemove
          ref="itemsToRemove"
          :dataGrid="removeData"
          :editMode="editMode"
          :clientData="clientData"
          @savedData="showModal = false"
        />
      </div>
      <template #modal-footer>
        <div>
          <b-button variant="primary" @click="openPayModal()" :disabled="disablePayButton"> Pay </b-button>
        </div>
      </template>
    </b-modal>
    <AddChargeModal
      v-if="showAddCharge"
      :action="1"
      :data="paymentData"
      :allPaymentMethods="false"
      :sendFrom="sendFrom"
      :hideOtherPayments="true"
      :hideFooter="true"
      :extraData="extraData"
      :typeProcess="1"
      @refreshTable="successPay()"
      @close="showAddCharge = false"
    />
  </div>
</template>
<script>
import ItemsToRemove from "@/views/commons/components/request-ncr/views/components/ItemsToRemove.vue";
import AddChargeModal from "@/views/commons/components/other-services/view/component/modal/AddChargeModal.vue";
import { amgApi } from "@/service/axios";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ItemsToRemove,
    AddChargeModal,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    hideAccount: {
      type: Boolean,
      default: false,
    },
    additionalData: {
      type: Object,
      default: () => ({
        charge_id: null,
      }),
    },
    hideFooter: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showModal: false,
      removeData: [],
      showAddCharge: false,
      sendFrom: "app_main",
      paymentData: {},
      disablePayButton: false,
      type_charge: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.getRemoveAccounts();
    await this.getTypeChargeNewItem();
    this.showModal = true;
  },
  methods: {
    ...mapActions({
      A_GET_NEW_ITEMS_COUNTER: "NewItemsStore/A_GET_NEW_ITEMS_COUNTER",
    }),
    async getTypeChargeNewItem() {
      try {
        const { data } = await amgApi.get("request-ncr/new-items/type-charge");
        this.type_charge = data[0]
      } catch (error) {
        this.showErrorSwal(error);
      }

    },
    async getRemoveAccounts() {
      const params = {
        client_id: this.clientData.client_account_id,
        charge_id: this.additionalData.charge_id,
      };
      try {
        this.addPreloader();
        const { data } = await amgApi.post(
          "request-ncr/new-items/get",
          params
        );
        this.removeData = data.map((item) => {
          return {
            ...item,
            showDetails: false,
            items: item.items.map(
              (item) => {
                return {
                 ...item,
                  is_remove: item.is_remove == 1? true : false,
                  new_item_selected: false
                };
              }
            )
          };
        });
        this.disablePayButton = this.removeData.length == 0;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    saveData() {
      this.$refs.itemsToRemove.saveData();
    },
    openPayModal() {
      const verify = this.verifySelectedItems();
      if (!verify) {
        this.showToast(
          "warning",
          "top-right",
          "Oops!",
          "AlertOctagonIcon",
          "You must select at least one item to continue."
        );
        return;
      }

      this.paymentData.account_id = this.clientData.client_account_id;
      this.paymentData.client_account = this.clientData.client_account;
      this.paymentData.client_name = this.clientData.client_name;
      this.paymentData.type_description = this.type_charge.description;
      this.paymentData.type_id = this.type_charge.id;
      // this.paymentData.id = this.clientData.id;
      this.paymentData.lead_id = this.clientData.lead_id;
      // this.paymentData.charge_description = "XXX";
      this.paymentData.account = this.clientData.client_account;
      // this.paymentData.type_id = 14;
      this.paymentData.minimum_price = 0;
      this.extraData = {
        client_account_id: this.clientData.client_account_id,
        items: this.$refs["itemsToRemove"].arrayNewItems,
        client_name: this.clientData.client_name,
      }
      this.showAddCharge = true;
    },
    verifySelectedItems() {
      const aux = this.$refs["itemsToRemove"].arrayNewItems;
      return aux.length > 0;
    },
    async successPay() {
      // this.$refs.itemsToRemove.saveData();
      this.$store.dispatch("NewItemsStore/A_REFRESH_CHARGES", true)
      await this.A_GET_NEW_ITEMS_COUNTER({
        client_account_id: this.$route.params.idClient,
      });
      this.$store.dispatch("NewItemsStore/A_REFRESH_TO_REMOVE", true)
      this.$refs.itemsToRemove.getCountAccountsToRemove();
      this.closeModal();
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>
