<template>
  <b-modal
    ref="refAssignSellerModal"
    title-class="h4 text-white"
    modal-class="modal-primary"
    centered
    size="lg"
    title="ASSIGN SELLER"
    no-close-on-backdrop
    @hidden="closeModal"
  >
    <ValidationObserver ref="form">
      <b-row class="my-2">
        <b-col>
          <h5>FROM</h5>
          <p
              class="
              rounded
              text-primary
              border-primary
              font-medium-1
              text-center
              py10
            "
          >
            {{ derivationData.derived_from }}
          </p>
        </b-col>
        <b-col>
          <h5>{{ derivationData.client_account_id ? 'CLIENT' : 'LEAD' }}</h5>
          <p
              class="
              rounded
              text-primary
              border-primary
              font-medium-1
              text-center
              py10
            "
          >
            {{ derivationData.lead_client_name }}
          </p>
        </b-col>
        <b-col>
          <h5>CATCHER</h5>
          <p
              class="
              rounded
              text-primary
              border-primary
              font-medium-1
              text-center
              py10
            "
          >
            {{ derivationData.catcher_name }}
          </p>
        </b-col>
        <b-col cols="12">
          <validation-provider v-slot="{errors}" name="Subject" rules="required">
            <b-form-group
                label="SELECT SELLER: "
            >
              <v-select
                v-model="seller"
                :class="{'border-danger rounded': errors[0]}"
                :options="sellerItems"
                label="user_name"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </ValidationObserver>

    <template #modal-footer>
      <b-button
        variant="success"
        @click="assignSeller"
      >SAVE</b-button>
    </template>

  </b-modal>
</template>

<script>
import VSelect from "vue-select";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import {mapGetters} from "vuex";

export default {
  name: "AssignSellerModal",
  components: {
    VSelect,
  },
  props: {
    derivationData: {
      derivationData: {
        type: Object,
        Default: null,
      },
    }
  },
  data() {
    return {
      sellerItems: [],
      seller: null,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  async mounted() {
    this.toggleModal('refAssignSellerModal')
    await this.getSellers()
  },
  methods: {
    async assignSeller() {
      try {
        const validate = await this.$refs.form.validate();
        if (validate) {
          // CONFIRM SWALL
          const confirm = await this.showConfirmSwal('Are you sure?', 'This will assign Seller!')
          if (confirm.isConfirmed) {
            this.addPreloader()
            const params = {
              created_by: this.seller.id,
              lead_id: this.derivationData.lead_id,
              lead_client_derivation_id: this.derivationData.lead_client_derivation_id,
            }
            const response = await DerivationsService.addSellerCDS(params)
            if (response.status === 200) {
              this.removePreloader()
              this.closeModal()
              this.$emit('refresh')
              this.showToast('success')
            }
          }
        }
      } catch (e) {
        console.log(e)
        this.removePreloader()
      }
    },
    async getSellers() {
      const data = await DerivationsService.getSellerItem()
      if (data.status === 200) {
        this.sellerItems = data.data
      }
    },
    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>

<style scoped>

</style>