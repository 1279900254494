<template>
  <div>
    <b-modal
      v-model="isActive"
      hide-footer
      hide-header-close
      hide-header
      :body-class="`rounded`"
      no-close-on-backdrop
      no-close-on-esc
      size="summary"
      @hidden="isActive = false"
      centered
    >
      <div v-if="DEAlertInfo">
        <div
          class="d-flex justify-content-between align-items-center w-100 mt-2 mb-2"
        >
          <div class="icon-badge mr-1" :class="badgeColor">
            <feather-icon :icon="iconTitle" size="15"></feather-icon>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div
              :class="colorText"
              class="text-uppercase"
              style="font-size: 20px"
            >
              {{ title }}
            </div>
          </div>
          <div
            class="close-modal"
            @click="closeModal"
            :class="isDarkSkin ? 'bg-dark' : 'bg-light text-secondary'"
          >
            <feather-icon icon="XIcon" size="16" />
          </div>
        </div>

        <hr />
        <div class="d-flex flex-column" style="gap: 15px">
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong text-uppercase">
              <tabler-icon
                :icon="iconMethod"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              Method
            </span>
            <span class="font-strong">
              {{ DEAlertInfo.method || "" }}
            </span>
          </div>
          <div
            class="d-flex justify-content-between align-items-center"
            v-if="DEAlertInfo.check_account_number"
          >
            <span class="font-strong">
              <tabler-icon
                icon="HashIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              Check Number
            </span>
            <span class="font-strong">
              {{ DEAlertInfo.check_account_number || "" }}
            </span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="TagIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              TYPE
            </span>
            <span class="font-strong">
              {{ DEAlertInfo.type || "" }}
            </span>
          </div>
          <div
            class="d-flex justify-content-between align-items-center"
            v-if="DEAlertInfo.credit_card_number"
          >
            <span class="font-strong">
              <feather-icon
                icon="CreditCardIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              CREDIT CARD
            </span>
            <span class="font-strong"
              >XXXX-XXXX-XXXX-{{
                DEAlertInfo.credit_card_number.slice(-4)
              }}</span
            >
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong text-uppercase">
              <feather-icon
                icon="BriefcaseIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              {{ titleVendor }}
            </span>
            <span class="font-strong">{{
              DEAlertInfo.payable_description
            }}</span>
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong text-uppercase">
              <feather-icon
                :icon="isCheck || isECheck ? 'FramerIcon' : 'AlignLeftIcon'"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              {{ titleConcept }}
            </span>
            <span v-if="!see_more_concept">
              <span v-if="DEAlertInfo.expense_description.length > 20">
                <span class="font-strong">{{
                  DEAlertInfo.expense_description.substr(0, 20)
                }}</span>
                <span @click="see_more_concept = true" class="see-more"
                  >...See more</span
                >
              </span>
              <span v-else class="font-strong">{{
                DEAlertInfo.expense_description
              }}</span>
            </span>
            <span v-else @click="see_more_concept = false" class="see-more"
              >See less</span
            >
          </div>
          <div v-if="see_more_concept" class="font-strong">
            <span>{{ DEAlertInfo.expense_description }}</span>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              AMOUNT
            </span>
            <span class="font-strong"
              >$ {{ DEAlertInfo.amount | currency }}</span
            >
          </div>

          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong text-uppercase">
              <feather-icon
                icon="CalendarIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              {{ titleDate }}
            </span>
            <span class="font-strong">{{ DEAlertInfo.date | myGlobal }}</span>
          </div>

          <!-- <div
            v-if="DEAlertInfo.type == 'AMG' && DEAlertInfo.recurring_payment"
            class="d-flex justify-content-between align-items-center"
          >
            <span class="font-strong">
              <feather-icon
                icon="CalendarIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              NEXT PAYMENT DATE
            </span>
            <span class="font-strong">{{
              DEAlertInfo.start_date | myGlobal
            }}</span>
          </div> -->
          <div class="d-flex justify-content-between align-items-center">
            <span class="font-strong">
              <feather-icon
                icon="UserIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              {{
                ["CHECK", "E-CHECK"].includes(DEAlertInfo.method)
                  ? "REQUESTED BY"
                  : "CREATED BY"
              }}
            </span>
            <span class="font-strong">
              {{ DEAlertInfo.full_name || "" }}
            </span>
          </div>
          <template v-if="isCheck">
            <template v-if="DEAlertInfo.address">
              <div class="d-flex justify-content-between align-items-center">
                <span class="font-strong text-uppercase">
                  <tabler-icon
                    icon="MapPinIcon"
                    class="mr-1 font-strong"
                    size="14"
                    svgClasses="font-small-4"
                  />
                  Address
                </span>
                <span v-if="!see_more_address">
                  <span v-if="DEAlertInfo.address.length > 20">
                    <span class="font-strong">{{
                      DEAlertInfo.address.substr(0, 20)
                    }}</span>
                    <span @click="see_more_address = true" class="see-more"
                      >...See more</span
                    >
                  </span>
                  <span v-else class="font-strong">{{
                    DEAlertInfo.address
                  }}</span>
                </span>
                <span v-else @click="see_more_address = false" class="see-more"
                  >See less</span
                >
              </div>
              <div v-if="see_more_address" class="font-strong">
                <span>{{ DEAlertInfo.address }}</span>
              </div>
            </template>
          </template>
          <div
            v-if="DEAlertInfo.observation"
            class="d-flex justify-content-between align-items-center"
          >
            <span class="font-strong">
              <feather-icon
                icon="EyeIcon"
                class="mr-1 font-strong"
                size="14"
                svgClasses="font-small-4"
              />
              OBSERVATION
            </span>
            <span v-if="!see_more">
              <span v-if="DEAlertInfo.observation.length > 20">
                <span class="font-strong">{{
                  DEAlertInfo.observation.substr(0, 20)
                }}</span>
                <span @click="see_more = true" class="see-more"
                  >...See more</span
                >
              </span>
              <span v-else class="font-strong">{{
                DEAlertInfo.observation
              }}</span>
            </span>
            <span v-else @click="see_more = false" class="see-more"
              >See less</span
            >
          </div>
          <div v-if="see_more" class="font-strong">
            <span>{{ DEAlertInfo.observation }}</span>
          </div>
        </div>
        <hr />
        <div>
          <b-row v-if="alert_buttons">
            <b-col
              :cols="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-button
                size="md"
                variant="outline-success"
                class="w-100"
                @click="closeModal"
                >OK
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import ExpensesService from "@/views/commons/expenses/services/expenses-department.service.js";
export default {
  name: "DepartmentExpensesNotification",
  data() {
    return {
      alert_buttons: true,
      see_more: false,
      see_more_address: false,
      see_more_concept: false,
    };
  },
  computed: {
    title() {
      let title = {
        APPROVED: "CONFIRMED EXPENSE",
        REJECTED: "REJECTED EXPENSE",
        NEW: "NEW EXPENSE TO REVIEW",
        REJECTED_BY_CEO: "REJECTED EXPENSE BY CEO",
        CANCELLED_BY_CORRESPONDENCE: "CANCELLED BY CORRESPONDENCE",
        NOT_COLLECTED: "NOT COLLECTED",
      };
      return title[this.DEAlertInfo.status] || "EXPENSE";
    },
    colorText() {
      let color = {
        REJECTED: "text-danger font-strong",
        APPROVED: "text-success font-strong",
        NEW: "text-info font-strong",
        REJECTED_BY_CEO: "text-danger font-strong",
        CANCELLED_BY_CORRESPONDENCE: "text-danger font-strong",
        NOT_COLLECTED: "text-danger font-strong",
      };
      return color[this.DEAlertInfo.status] || "text-warning font-strong";
    },
    badgeColor() {
      let classes = {
        REJECTED: "bg-light-danger",
        APPROVED: "bg-light-success",
        NEW: "bg-light-info",
        REJECTED_BY_CEO: "bg-light-danger",
        CANCELLED_BY_CORRESPONDENCE: "bg-light-danger",
        NOT_COLLECTED: "bg-light-danger",
      };
      return classes[this.DEAlertInfo.status] || "bg-light-warning";
    },
    iconTitle() {
      let icons = {
        APPROVED: "CheckIcon",
        REJECTED: "XOctagonIcon",
        NEW: "InfoIcon",
        REJECTED_BY_CEO: "XOctagonIcon",
        CANCELLED_BY_CORRESPONDENCE: "XOctagonIcon",
        NOT_COLLECTED: "XOctagonIcon",
      };
      return icons[this.DEAlertInfo.status] || "AlertTriangleIcon";
    },
    iconMethod() {
      if (this.DEAlertInfo) {
        const method = this.DEAlertInfo.method;
        if (["CHECK", "E-CHECK"].includes(method)) {
          return "CashIcon";
        } else if (method === "CREDIT_CARD") {
          return "CreditCardIcon";
        }
      }
    },
    ...mapGetters({
      G_IsActive: "DepartmentExpensesAlertStore/G_IsActive",
      G_DEAlertInfo: "DepartmentExpensesAlertStore/G_DEAlertInfo",
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0]?.meta?.module;
    },

    isActive: {
      get() {
        return this.G_IsActive;
      },
      set(value) {
        this.$store.commit("DepartmentExpensesAlertStore/M_IsActive", value);
      },
    },

    DEAlertInfo: {
      get() {
        return this.G_DEAlertInfo;
      },
      set(value) {
        this.$store.commit("DepartmentExpensesAlertStore/M_DEAlertInfo", value);
      },
    },
    isCheck() {
      return this.DEAlertInfo.method === "CHECK";
    },
    isECheck() {
      return this.DEAlertInfo.method === "E-CHECK";
    },
    titleVendor() {
      let title;
      switch (this.DEAlertInfo.method) {
        case "CREDIT_CARD":
          title = "Vendor";
          break;
        case "E-CHECK":
        case "CHECK":
          title = "Pay to";
          break;
        default:
          title = "Vendor";
          break;
      }
      return title;
    },
    titleConcept() {
      let title;
      switch (this.DEAlertInfo.method) {
        case "CREDIT_CARD":
          title = "Concept";
          break;
        case "E-CHECK":
        case "CHECK":
          title = "Memo";
          break;
        default:
          title = "Concept";
          break;
      }
      return title;
    },
    titleDate() {
      let title;
      switch (this.DEAlertInfo.method) {
        case "CREDIT_CARD":
          title = "Payment Date";
          break;
        case "E-CHECK":
        case "CHECK":
          title = "Date";
          break;
        default:
          title = "Date";
          break;
      }
      return title;
    },
  },
  mounted() {},
  methods: {
    ...mapActions({}),
    async saveExpense() {
      const { isConfirmed } = await this.showConfirmSwal();
      if (!isConfirmed) return;
      try {
        const data = await ExpensesService.saveExpense({ ...this.DEAlertInfo });

        this.showSuccessSwal(data.message);
        this.closeModal();
      } catch (error) {
        console.log(error);
        this.showErrorSwal(e);
      }
    },
    closeModal() {
      this.isActive = false;
      // const channel = socket.channel("recurring-payments", {});
      // channel.trigger("client-close-all-rp-notification", {
      //   user_id: this.currentUser.user_id,
      //   modul_id: this.currentUser.modul_id,
      // });
    },
  },
  async created() {},
  watch: {
    DEAlertInfo(value) {
      console.log({ DEAlertInfo: value });
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-badge {
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &.completed {
    background-color: rgba(40, 199, 111, 0.1);
    color: #468844;
  }
  &.pending {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.reactivated {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
  &.scaled {
    background-color: rgba(255, 159, 67, 0.1);
    color: #ff9f43;
  }
}

.close-modal {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  padding: 0.5rem 0.7rem;
  background-color: #ececec;
  border-radius: 0.25rem;
  &:hover {
    background-color: #585858;
    color: #ececec;
    cursor: pointer;
  }
}

.title-alert {
  font-size: 1.2rem;
}
.see-more {
  cursor: pointer;
  color: rgb(0, 183, 255);
  transition: text-decoration 0.1s, font-weight 0.1s;
}
.see-more:hover {
  text-decoration: underline;
  font-weight: bold;
}
</style>
