export const appointmentTabs = (abv, title, permitted_roles=null) => {
    return [
        {
            path: "pending",
            name: `${abv}-pending`,

            component: () => import(
                "@/views/commons/components/appointments/view/component/TableAppointment.vue"
            ),

            meta: {
                router: "financial-approval",
                statusApp: 1,
                tabName: "PENDING",
                pageTitle: `${title}`,
                breadcrumb: [
                    {
                        text: "Pending",
                        active: true,
                    },
                ],
                permittedRoles: permitted_roles,
                routePending: `${abv}-pending`,
                routeInProcess: `${abv}-in-process`,
                routeCompleted: `${abv}-completed`,
                routeAccepted: `${abv}-accepted`,
                routeRejected: `${abv}-rejected`,
                routeExpired: `${abv}-expired`,
            },
        },
        {
          path: "in-process",
          name: `${abv}-in-process`,

          component: () => import(
              "@/views/commons/components/appointments/view/component/TableAppointment.vue"
          ),

          meta: {
              router: "financial-approval",
              statusApp: 2,
              tabName: "IN PROCESS",
              pageTitle: `${title}`,
              breadcrumb: [
                  {
                      text: "In process",
                      active: true,
                  },
              ],
              permittedRoles: permitted_roles,
              routePending: `${abv}-pending`,
              routeInProcess: `${abv}-in-process`,
              routeCompleted: `${abv}-completed`,
              routeAccepted: `${abv}-accepted`,
              routeRejected: `${abv}-rejected`,
              routeExpired: `${abv}-expired`,
          },
      },
        {
            path: "completed",
            name: `${abv}-completed`,
            redirect: { name: `${abv}-accepted` },

            component: () => import("@/views/commons/components/appointments/view/InProcessSubTab.vue"),
            meta: {
                router: "financial-approval",
                pageTitle: `${title}`,
                breadcrumb: [
                    {
                        text: "Completed",
                        active: true,
                    },
                ],
                tabName: "COMPLETED",
                permittedRoles: permitted_roles,
                routePending: `${abv}-pending`,
                routeInProcess: `${abv}-in-process`,
                routeCompleted: `${abv}-completed`,
                routeAccepted: `${abv}-accepted`,
                routeRejected: `${abv}-rejected`,
                routeExpired: `${abv}-expired`,
            },
            children: [{
                path: "accepted",
                name: `${abv}-accepted`,
                component: () =>
                    import(
                        "@/views/commons/components/appointments/view/component/TableAppointment.vue"
                    ),
                meta: {
                    pageTitle: `${title}`,
                    statusApp: 3,
                    tabName: "ACCEPTED",
                    breadcrumb: [
                        {
                            text: "Accepted",
                            active: true,
                        },
                    ],
                    permittedRoles: permitted_roles,
                    routePending: `${abv}-pending`,
                    routeInProcess: `${abv}-in-process`,
                    routeAttended: `${abv}-attended`,
                    routeNoAttended: `${abv}-not-attended`,
                    routeExpired: `${abv}-expired`,
                },
            },
            {
                path: "rejected",
                name: `${abv}-rejected`,
                component: () =>
                    import(
                        "@/views/commons/components/appointments/view/component/TableAppointment.vue"
                    ),
                meta: {
                    pageTitle: `${title}`,
                    tabName: "REJECTED",
                    statusApp: 4,
                    breadcrumb: [
                        {
                            text: "Rejected",
                            active: true,
                        },
                    ],
                    permittedRoles: permitted_roles,
                },

            },
            ]
        },
        {
            path: "expired",
            name: `${abv}-expired`,
            component: () =>
                import(
                    "@/views/commons/components/appointments/view/component/TableAppointment.vue"
                ),
            meta: {
                pageTitle: `${title}`,
                tabName: "expired",
                statusApp: 5,
                breadcrumb: [
                    {
                        text: "Expired",
                        active: true,
                    },
                ],
                permittedRoles: permitted_roles,
                routePending: `${abv}-pending`,
                routeInProcess: `${abv}-in-process`,
                routeCompleted: `${abv}-completed`,
                routeAccepted: `${abv}-accepted`,
                routeRejected: `${abv}-rejected`,
                routeExpired: `${abv}-expired`,
            },
        }
    ]
}
