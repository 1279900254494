export default {
  path: 'helpdesk',
  name: 'helpdesk-administration',
  component: () => import('@/views/administration/views/helpdesk/Helpdesk.vue'),
  meta: {
    pageTitle: 'Help Desk',
    breadcrumb: [
      {
        text: 'Help Desk',
        active: true,
      },
    ],
    permittedRoles: [1, 2, 6],
  },
}
