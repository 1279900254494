export default [
  {
    path: "claims",
    name: "claims-claimdepartment",
    component: () =>
      import(/* webpackChunkName: "LevelClaim" */ "@/views/commons/components/claims_v2/LevelClaim.vue"),
    redirect: { name: "claims-claimdepartment-LevelZero" },
    meta: {
      pageTitle: "Claims",
      breadcrumb: [
        {
          text: "Claims",
        },
      ],
      routeLevelZero: "claims-claimdepartment-LevelZero",
      routeLevelOne: "claims-claimdepartment-LevelOne",
      routeLevelTwo: "claims-claimdepartment-LevelTwo",
      routeLevelThree: "claims-claimdepartment-LevelThree",
      routeAnnulled: "claims-claimdepartment-annulled",
      routeFinished: "claims-claimdepartment-finished",
    },
    children: [
      {
        path: "level-zero",
        name: "claims-claimdepartment-LevelZero",
        component: () =>
          import(/* webpackChunkName: "LevelZero" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"),
        meta: {
          isClientsTab: true,
          pageTitle: "Claims",
          breadcrumb: [
            {
              text: "Level-zero",
              active: true,
            },
          ],
          routeLevelZero: "claims-claimdepartment-LevelZero",
          routeLevelOne: "claims-claimdepartment-LevelOne",
          routeLevelTwo: "claims-claimdepartment-LevelTwo",
          routeLevelThree: "claims-claimdepartment-LevelThree",
          routeAnnulled: "claims-claimdepartment-annulled",
          routeFinished: "claims-claimdepartment-finished",
          stateLevel: 1,
          stateClaim: "SCALED",
        },
      },
      {
        path: "level-one",
        name: "claims-claimdepartment-LevelOne",
        component: () => import(/* webpackChunkName: "LevelOne" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"),
        meta: {
          isClientsTab: false,
          pageTitle: "Claims",
          breadcrumb: [
            {
              text: "Level-one",
              active: true,
            },
          ],
          routeLevelZero: "claims-claimdepartment-LevelZero",
          routeLevelOne: "claims-claimdepartment-LevelOne",
          routeLevelTwo: "claims-claimdepartment-LevelTwo",
          routeLevelThree: "claims-claimdepartment-LevelThree",
          routeAnnulled: "claims-claimdepartment-annulled",
          routeFinished: "claims-claimdepartment-finished",
          stateLevel: 2,
          stateClaim: "SCALED",
        },
      },
      {
        path: "level-two",
        name: "claims-claimdepartment-LevelTwo",
        component: () => import(/* webpackChunkName: "LevelTwo" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"),
        meta: {
          isClientsTab: false,
          pageTitle: "Claims",
          breadcrumb: [
            {
              text: "Level-two",
              active: true,
            },
          ],
          routeLevelZero: "claims-claimdepartment-LevelZero",
          routeLevelOne: "claims-claimdepartment-LevelOne",
          routeLevelTwo: "claims-claimdepartment-LevelTwo",
          routeLevelThree: "claims-claimdepartment-LevelThree",
          routeAnnulled: "claims-claimdepartment-annulled",
          routeFinished: "claims-claimdepartment-finished",
          stateLevel: 3,
          stateClaim: "SCALED",
        },
      },
      {
        path: "level-three",
        name: "claims-claimdepartment-LevelThree",
        component: () => import(/* webpackChunkName: "LevelThree" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"),
        meta: {
          isClientsTab: false,
          pageTitle: "Claims",
          breadcrumb: [
            {
              text: "Level-three",
              active: true,
            },
          ],
          routeLevelZero: "claims-claimdepartment-LevelZero",
          routeLevelOne: "claims-claimdepartment-LevelOne",
          routeLevelTwo: "claims-claimdepartment-LevelTwo",
          routeLevelThree: "claims-claimdepartment-LevelThree",
          routeAnnulled: "claims-claimdepartment-annulled",
          routeFinished: "claims-claimdepartment-finished",
          stateClaim: "SCALED",
          stateLevel: 4,
        },
      },
      {
        path: "annulled",
        name: "claims-claimdepartment-annulled",
        component: () =>
          import(
            /* webpackChunkName: "Pending" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"
          ),
        meta: {
          status: 5,
          pageTitle: "Claims",
          breadcrumb: [
            {
              text: "Annulled",
              active: true,
            },
          ],
          routeLevelZero: "claims-claimdepartment-LevelZero",
          routeLevelOne: "claims-claimdepartment-LevelOne",
          routeLevelTwo: "claims-claimdepartment-LevelTwo",
          routeLevelThree: "claims-claimdepartment-LevelThree",
          routeAnnulled: "claims-claimdepartment-annulled",
          routeFinished: "claims-claimdepartment-finished",
          stateClaim: "ANNULLED",
          stateLevel: null,
        },
      },
      {
        path: "finished",
        component: () =>
          import(
            /* webpackChunkName: "Pending" */ "@/views/commons/components/claims_v2/views/Levels/LevelClaimGrid.vue"
          ),
        name: "claims-claimdepartment-finished",
        meta: {
          status: 2,
          pageTitle: "Claims",
          breadcrumb: [
            {
              text: "Finished",
              active: true,
            },
          ],
          routeLevelZero: "claims-claimdepartment-LevelZero",
          routeLevelOne: "claims-claimdepartment-LevelOne",
          routeLevelTwo: "claims-claimdepartment-LevelTwo",
          routeLevelThree: "claims-claimdepartment-LevelThree",
          routeAnnulled: "claims-claimdepartment-annulled",
          routeFinished: "claims-claimdepartment-finished",
          stateClaim: "COMPLETED",
          stateLevel: null
        },
      },
    ],
  },
]