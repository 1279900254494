<template>
  <div>
    <validation-observer ref="form">
      <b-row>
        <b-col cols="6">
          <b-form-group label="Creditor Name">
            <validation-provider v-slot="{ errors }" rules="customRequired">
              <vue-autosuggest
                ref="autocomplete"
                id="creditor"
                v-model="tempEntryCreditorName"
                :suggestions="filteredOptions"
                :get-suggestion-value="getSuggestionValue"
                :input-props="{
                  id: 'autosuggest__input',
                  class: ['form-control ', errors[0]],
                  placeholder: 'Select',
                }"
                @input="onInputChange"
                @selected="selectHandler"
              >
                <template slot="before-input">
                  <div class="lds-dual-ring" v-show="creditorsLoading"></div>
                </template>
                <template slot-scope="{ suggestion }">
                  <span class="my-suggestion-item">{{
                    suggestion.item.value
                  }}</span>
                </template>
              </vue-autosuggest>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <validation-provider v-slot="{ errors }" rules="customRequired">
            <b-form-group :class="errors[0]" label="Type">
              <v-select
                v-model="type"
                :options="types"
                :reduce="(value) => value.id"
                label="value"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Months Behind">
            <validation-provider v-slot="{ errors }" rules="customRequired">
              <input
                id="month"
                v-model="month"
                v-mask="'######'"
                class="form-control"
                type="text"
                :class="errors[0]"
              />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6" v-if="isCreditCard && isMonthsZero" class="mt-1">
          <b-form-group label="Automatic Payments">
            <div>
              <label style="color: #e26969; margin-right: 1rem">OFF</label>
              <label class="switch">
                <b-form-checkbox
                  v-model="automaticPayments"
                  switch
                  type="checkbox"
                  value="1"
                />
              </label>
              <label style="color: #56bec4; margin-left: 0.6rem">ON</label>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="3" v-if="isReposition">
          <b-form-group label="Has Car?">
            <div>
              <label style="color: #e26969; margin-right: 1rem">NO</label>
              <label class="switch">
                <b-form-checkbox
                  v-model="hasCar"
                  switch
                  type="checkbox"
                  value="1"
                />
              </label>
              <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="3" v-if="isReposition && !hasCar">
          <b-form-group label="Was Auctioned?">
            <div>
              <label style="color: #e26969; margin-right: 1rem">NO</label>
              <label class="switch">
                <b-form-checkbox
                  v-model="isAuctioned"
                  switch
                  type="checkbox"
                  value="1"
                />
              </label>
              <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="6" v-if="isPersonalLoan">
          <b-form-group label="Has Warranty?">
            <div>
              <label style="color: #e26969; margin-right: 1rem">NO</label>
              <label class="switch">
                <b-form-checkbox
                  v-model="hasWarranty"
                  switch
                  type="checkbox"
                  value="1"
                />
              </label>
              <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="6" v-if="isPersonalLoan && hasWarranty">
          <validation-provider rules="customRequired" v-slot="{ errors }">
            <b-form-group label="Motive" :class="errors[0]">
              <v-select
                v-model="personalLoanMotive"
                :options="personalLoanMotives"
                :reduce="(value) => value.id"
                label="value"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="6" v-if="isCT">
          <b-form-group label="Judgment Exist?">
            <div>
              <label style="color: #e26969; margin-right: 1rem">NO</label>
              <label class="switch">
                <b-form-checkbox
                  v-model="judgmentExists"
                  switch
                  type="checkbox"
                  value="1"
                />
              </label>
              <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="6" v-if="isCT && judgmentExists">
          <validation-provider rules="customRequired" v-slot="{ errors }">
            <b-form-group :class="errors[0]" label="Judgment Type">
              <v-select
                v-model="judgmentOption"
                :options="judgmentOptions"
                :reduce="(value) => value.id"
                label="value"
              />
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="6" v-if="isCollection">
          <b-form-group label="Do you know the collection?">
            <div>
              <label style="color: #e26969; margin-right: 1rem">NO</label>
              <label class="switch">
                <b-form-checkbox
                  v-model="hasCollectionKnowledge"
                  switch
                  type="checkbox"
                  value="1"
                />
              </label>
              <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
            </div>
          </b-form-group>
        </b-col>

        <b-col cols="6" v-if="isCollection && hasCollectionKnowledge">
          <b-form-group label="Collection Name">
            <validation-provider v-slot="{ errors }" rules="customRequired">
              <vue-autosuggest
                ref="autocomplete2"
                id="collectionName"
                v-model="tempEntryCollectionName"
                :suggestions="filteredOptions2"
                :get-suggestion-value="getSuggestionValue2"
                :input-props="{
                  id: 'autosuggest__input',
                  class: ['form-control ', errors[0]],
                  placeholder: 'Select',
                }"
                @input="onInputChange2"
                @selected="selectHandler2"
              >
                <template slot="before-input">
                  <div class="lds-dual-ring" v-show="creditorsLoading2"></div>
                </template>
                <template slot-scope="{ suggestion }">
                  <span class="my-suggestion-item">{{
                    suggestion.item.value
                  }}</span>
                </template>
              </vue-autosuggest>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="4" v-if="isOthers">
          <b-form-group label="New Debt Type">
            <validation-provider
              v-slot="{ errors }"
              rules="customRequired|max:50"
            >
              <b-form-input
                v-model="newDebtType"
                maxlength="50"
                :class="errors[0]"
              />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6" sm="12" xs="12" md="12" lg="6" v-if="isCreditCard">
          <b-form-group label="Credit Card Number">
            <b-row>
              <!-- Input Number 1 -->
              <b-col sm="3" xs="3" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="cardnumber1"
                  rules="customRequired|min:4|max:4"
                >
                  <b-form-input
                    maxlength="4"
                    v-model="card.number1"
                    :class="{
                      'is-invalid': errors[0],
                      'is-invalid': card_number_emty1 == true,
                    }"
                    ref="input-1"
                    @input="activeFocus(1, 4)"
                    @blur="fillWithAstreisks(1)"
                  />
                </validation-provider>
              </b-col>

              <!-- Input Number 2 -->
              <b-col sm="3" xs="3" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="cardnumber2"
                  rules="customRequired|min:4|max:4"
                >
                  <b-form-input
                    maxlength="4"
                    v-model="card.number2"
                    :class="{
                      'is-invalid': errors[0],
                      'is-invalid': card_number_emty2 == true,
                    }"
                    ref="input-2"
                    @input="activeFocus(2, 4)"
                    @blur="fillWithAstreisks(2)"
                  />
                </validation-provider>
              </b-col>

              <!-- Input Number 3 -->
              <b-col sm="3" xs="3" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="cardnumber3"
                  rules="customRequired|min:4|max:4"
                >
                  <b-form-input
                    v-model="card.number3"
                    maxlength="4"
                    :class="{
                      'is-invalid': errors[0],
                      'is-invalid': card_number_emty3 == true,
                    }"
                    ref="input-3"
                    @input="activeFocus(3, 4)"
                    @blur="fillWithAstreisks(3)"
                  />
                </validation-provider>
              </b-col>

              <!-- Input Number 4 -->
              <b-col sm="3" xs="3" md="3">
                <validation-provider
                  v-slot="{ errors }"
                  name="cardnumber4"
                  :rules="'customRequired|max:' + maxCardLength"
                >
                  <b-form-input
                    v-model="card.number4"
                    :maxlength="maxCardLength"
                    :class="{
                      'is-invalid': errors[0],
                      'is-invalid': card_number_emty4 == true,
                    }"
                    ref="input-4"
                    @input="activeFocus(4, 4)"
                    @blur="fillWithAstreisks(4)"
                  />
                </validation-provider>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>

        <b-col cols="6" v-if="!isCreditCard">
          <b-form-group label="Account Number">
            <validation-provider v-slot="{ errors }" rules="customRequired">
              <input
                id="account"
                v-model="account"
                v-mask="'##############################'"
                class="form-control"
                type="text"
                :class="{
                  'is-invalid': errors[0],
                  'is-invalid': account_number_val == true,
                }"
              />
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6"
          ><validation-provider
            v-slot="{ errors }"
            rules="customRequired|money-1"
          >
            <b-form-group label="Total Balance">
              <b-input-group prepend="$">
                <money
                  v-model="balance"
                  v-bind="vMoney"
                  class="form-control"
                  :style="errors[0] && validateMoney ? 'border-color:red' : ''"
                />
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Monthly Current Payment">
            <validation-provider
              v-slot="{ errors }"
              rules="customRequired|money-1"
            >
              <b-input-group prepend="$">
                <money
                  v-model="monthly"
                  v-bind="vMoney"
                  class="form-control"
                  :style="errors[0] && validateMoney ? 'border-color:red' : ''"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Interest Rate">
            <validation-provider
              v-slot="{ errors }"
              rules="customRequired|money-1"
            >
              <b-input-group
                :prepend-class="errors[0]"
                append="%"
                :class="{
                  'is-invalid': errors[0],
                  'is-invalid': interest_rate_val == true,
                }"
              >
                <b-form-input
                  v-model="interes"
                  v-mask="'##.##'"
                  :class="{
                    'is-invalid': errors[0],
                    'is-invalid': interest_rate_val == true,
                  }"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="6" v-if="moduleId == 2">
          <b-form-group label="Possible monthly payment">
            <validation-provider
              v-slot="{ errors }"
              rules="customRequired|money-1"
            >
              <b-input-group
                prepend="$"
                :class="{
                  'is-invalid': errors[0],
                  'is-invalid': possible_monthly_payment_val == true,
                }"
              >
                <money
                  v-model="possibleMonthly"
                  v-bind="vMoney"
                  :class="{
                    'is-invalid': errors[0],
                    'is-invalid': possible_monthly_payment_val == true,
                  }"
                  class="form-control"
                  :style="errors[0] && validateMoney ? 'border-color:red' : ''"
                />
              </b-input-group>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="3" class="mt-1">
          <b-form-group label="Unsecured Type">
            <validation-provider>
              <div>
                <label style="color: #e26969; margin-right: 1rem">OFF</label>
                <label class="switch">
                  <b-form-checkbox
                    v-model="unsecured"
                    switch
                    type="checkbox"
                    value="1"
                    disabled
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">ON</label>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="3" v-if="trueRetainer == 1" class="mt-1">
          <b-form-group label="Add Retainer Fee">
            <validation-provider>
              <div>
                <label style="color: #e26969; margin-right: 1rem">NO</label>
                <label class="switch">
                  <b-form-checkbox
                    v-model="retainerFee"
                    switch
                    type="checkbox"
                    value="1"
                    :disabled="state == 0"
                  />
                </label>
                <label style="color: #56bec4; margin-left: 0.6rem">YES</label>
              </div>
            </validation-provider>
          </b-form-group>
        </b-col>
        <template v-if="salesClient">
          <b-col cols="12" v-if="!salesClient.ds_analysis_options">
            <bills-select @bills="bills = $event"></bills-select>
          </b-col>
        </template>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import BillsSelect from "@/views/crm/views/sales-made/components/modals/services/debt-solution/components/BillsSelect.vue";
import { VueAutosuggest } from "vue-autosuggest";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import { ValidationProvider, extend } from "vee-validate";
import { amgApi } from "@/service/axios.js";
import { required } from "vee-validate/dist/rules";
extend("customRequired", {
  ...required,
  message: "is-invalid",
});

export default {
  name: "ModalAddCreditorNew",
  components: {
    VueAutosuggest,
    vSelect,
    ValidationProvider,
    BillsSelect,
  },
  props: [
    "idevent",
    "state",
    "idcreditor",
    "accountProg",
    "type_in",
    "client_id",
    "trueRetainer",
    "salesClient",
    "isFromFirstStep",
  ],
  data() {
    return {
      validateOnOpen: false,
      tempEntryCreditorName: "",
      tempEntryCollectionName: "",
      personalLoanMotives: [
        { id: 1, value: "Home" },
        { id: 2, value: "Car" },
        { id: 3, value: "Others" },
      ],
      judgmentOptions: [
        { id: 1, value: "WG" },
        { id: 2, value: "Lien" },
        { id: 3, value: "Bank Levy" },
        { id: 4, value: "None" },
      ],
      newDebtType: "",
      judgmentOption: "",
      personalLoanMotive: "",
      extraInput: "",
      collectionName: "",
      hasCollectionKnowledge: false,
      judgmentExists: false,
      hasWarranty: false,
      hasCar: false,
      isAuctioned: false,
      card: {
        number1: "",
        number2: "",
        number3: "",
        number4: "",
      },
      maxCardLength: 1,
      filteredOptions: [],
      filteredOptions2: [],
      creditorsLoading: false,
      creditorsLoading2: false,
      collectionNameId: null,
      types: [],
      creditor: "",
      account: "",
      balance: "",
      monthly: "",
      possibleMonthly: "",
      month: null,
      interes: 3,
      type: null,
      bills: null,
      unsecured: 1,
      automaticPayments: false,
      creditors: [],
      valuecreditor: "",
      errorCredit: false,
      errorAccount: false,
      errorBalance: false,
      errorPayment: false,
      errorMonths: false,
      errorInteres: false,
      errorType: false,
      errorUnsecured: false,
      retainerFee: 0,
      vMoney: {
        maxlength: 10,
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false,
      },
      useWatch: true,
      validateMoney: false,
      creditorName: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isMonthsZero() {
      return this.month == 0 && this.month !== null && this.month !== "";
    },
    isCreditCard() {
      return this.type == 1;
    },
    isReposition() {
      return this.type == 2;
    },
    isPersonalLoan() {
      return this.type == 3;
    },
    isCT() {
      return this.type == 6;
    },
    isCollection() {
      return this.type == 8;
    },
    isOthers() {
      return this.type == 9;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },

    card_number_emty1() {
      return this.card.number1 == "" && this.validateOnOpen === true;
    },
    card_number_emty2() {
      return this.card.number2 == "" && this.validateOnOpen === true;
    },
    card_number_emty3() {
      return this.card.number3 == "" && this.validateOnOpen === true;
    },
    card_number_emty4() {
      return this.card.number4 == "" && this.validateOnOpen === true;
    },
    account_number_val() {
      return (
        (this.account == "" || this.account == null) &&
        this.validateOnOpen === true
      );
    },
    possible_monthly_payment_val() {
      return (
        (this.possibleMonthly == "" ||
          this.possibleMonthly == null ||
          this.possibleMonthly <= 0) &&
        this.validateOnOpen === true
      );
    },
    interest_rate_val() {
      return (
        (this.interes == "" || this.interes == null || this.interes <= 0) &&
        this.validateOnOpen === true
      );
    },
  },
  watch: {

    "card.number1"(val) {
      if (this.useWatch === true) {
        let firstChar = val.charAt(0);
        // if first char starts with 4, 5 or 6 the card can be 16 digits long
        if (firstChar == 4 || firstChar == 5 || firstChar == 6) {
          this.maxCardLength = 4;
          this.card.number2 = "";
          this.card.number3 = "";
          this.card.number4 = "";
        }
        // if first char starts with 3 the card can be 15 digits long
        else if (firstChar == 3) {
          this.maxCardLength = 3;
          this.card.number2 = "";
          this.card.number3 = "";
          this.card.number4 = "";
        }
      }
    },
  },
  async mounted() {
    if (this.state == 0) {
      this.useWatch = false;
      await this.editcreditor();
      this.useWatch = true;
    }

    if (this.isFromFirstStep === true) {
      this.validateOnOpen = true;
    }
  },
  async created() {
    await this.allTypes();
  },
  methods: {
    fillWithAstreisks(index) {
      if (index === 1) {
        this.card.number1 = this.card.number1.padEnd(4, "*");
      }
      if (index === 2) {
        this.card.number2 = this.card.number2.padEnd(4, "*");
      }
      if (index === 3) {
        this.card.number3 = this.card.number3.padEnd(4, "*");
      }
      if (index === 4) {
        this.card.number4 = this.card.number4.padEnd(this.maxCardLength, "*");
        this.card.number1 = this.card.number1.padEnd(4, "*");
        this.card.number2 = this.card.number2.padEnd(4, "*");
        this.card.number3 = this.card.number3.padEnd(4, "*");
      }
    },

    formattedDisplay(result) {
      return result.value;
    },
    closeModal() {
      this.$emit("click", false);
    },
    async allTypes() {
      try {
        const response = await amgApi.get(
          "/sales-made/debt-solution/get-debts-types"
        );
        if (response.status === 200) {
          this.types = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async savecreditor() {
      const unsecuredToSend = this.unsecured === 1;
      this.validateMoney = true;
      // Validate Money
      const success = await this.$refs.form.validate();
      if (success) {
        const result = await this.showConfirmSwal(
          "Are you Sure?",
          "Before finalizing you must save."
        );
        if (result.value) {
          this.addPreloader();
          const route = "/sales-made/debt-solution/save-credits-debt-solution";
          let params = {
            event: this.idevent,
            accountProg: this.accountProg,
            creditor: this.creditor,
            // if is credit card save the number of each square else save the field value
            account:
              this.type == 1
                ? this.card.number1 +
                  this.card.number2 +
                  this.card.number3 +
                  this.card.number4
                : this.account,
            balance: this.balance,
            monthly: this.monthly,
            possibleMonthly: this.possibleMonthly,
            month: this.month,
            interes: this.interes,
            type: this.type,
            unsecured: unsecuredToSend,
            type_in: this.type_in,
            user: this.currentUser.user_id,
            client_id: this.client_id,
            retainerFee: this.retainerFee == true ? 1 : 0,
            id_account: this.$route.params.idClient,
            card_number:
              this.card.number1 +
              this.card.number2 +
              this.card.number3 +
              this.card.number4,
          };
          params.has_automatic_payments =
            this.isCreditCard && this.isMonthsZero
              ? this.automaticPayments
              : null;
          params.has_car = this.isReposition ? this.hasCar : false;
          params.was_auctioned =
            this.isReposition && !this.hasCar ? this.isAuctioned : null;
          params.has_warranty = this.isPersonalLoan ? this.hasWarranty : null;
          params.motives =
            this.isPersonalLoan && this.hasWarranty
              ? this.personalLoanMotive
              : null;
          params.judgment_exists = this.isCT ? this.judgmentExists : null;
          params.judgment_type =
            this.isCT && this.judgmentExists ? this.judgmentOption : null;
          params.has_collection_knowledge = this.isCollection
            ? this.hasCollectionKnowledge
            : null;
          params.collection_name =
            this.isCollection && this.hasCollectionKnowledge
              ? this.collectionName
              : null;
          params.collection_name_id =
            this.isCollection && this.hasCollectionKnowledge
              ? this.collectionNameId
              : null;
          params.new_debt_type = this.isOthers ? this.newDebtType : null;
          params.bills = this.bills;
          const response = await amgApi.post(route, params);

          if (response.status === 200) {
            this.$emit("reload");
            this.$emit("reloadAmountSaved");
            this.$emit("click", false);
            this.showSuccessSwal();
          }
          this.removePreloader();
        }
      }
    },
    async editcreditor() {
      try {
        this.addPreloader();
        const response = await amgApi.post("/sale/get-creditor-by-id", {
          id: this.idcreditor,
        });
        if (response.status === 200) {
          this.creditor = response.data[0].credit_id;
          this.account = response.data[0].account;
          this.balance = response.data[0].balance;
          this.monthly = response.data[0].monthly;
          this.month = response.data[0].months;
          this.interes = response.data[0].interest;
          this.type = response.data[0].type_id;
          this.unsecured = response.data[0].unsecured;
          this.valuecreditor = response.data[0].credit_name;
          this.automaticPayments = response.data[0].has_automatic_payments;
          this.hasCar = response.data[0].has_car;
          this.isAuctioned = response.data[0].was_auctioned;
          this.hasWarranty = response.data[0].has_warranty;
          this.personalLoanMotive = response.data[0].motives;
          this.judgmentExists = response.data[0].judgment_exists;
          this.judgmentOption = response.data[0].judgment_type;
          this.hasCollectionKnowledge =
            response.data[0].has_collection_knowledge;
          this.collectionName = response.data[0].collection_name;
          this.newDebtType = response.data[0].new_debt_type;

          if (response.data[0].card_number) {
            this.card.number1 = response.data[0].card_number.slice(0, 4);
            this.card.number2 = response.data[0].card_number.slice(4, 8);
            this.card.number3 = response.data[0].card_number.slice(8, 12);
            this.card.number4 = response.data[0].card_number.slice(12, 16);

            if (
              this.card.number1.charAt(0) == 4 ||
              this.card.number1.charAt(0) == 5 ||
              this.card.number1.charAt(0) == 6
            ) {
              this.maxCardLength = 4;
            }
            // if first char starts with 3 the card can be 15 digits long
            else if (this.card.number1.charAt(0) == 3) {
              this.maxCardLength = 3;
            }
          }
          //set for validation
          this.tempEntryCreditorName = this.valuecreditor;
          this.tempEntryCollectionName = this.collectionName;

          this.$refs.autocomplete.value = this.valuecreditor;
          // this.$refs.autocomplete2.value = this.collectionName;
          this.removePreloader();
        }
      } catch (error) {
        // this.showErrorSwal(error);
        console.log(error);
        this.removePreloader();
      }
    },
    async updatecreditor() {
      try {
        const result = await this.showConfirmSwal(
          "Are you Sure?",
          "Before finalizing you must save."
        );
        if (result.value) {
          this.addPreloader();
          let params = {
            id: this.idcreditor,
            event: this.idevent,
            accountProg: this.accountProg,
            creditor: this.creditor,
            account: this.account,
            balance: this.balance,
            monthly: this.monthly,
            month: this.month,
            interes: this.interes,
            type: this.type,
            unsecured: this.unsecured,
            type_in: this.type_in,
            client_id: this.client_id,

            card_number:
              this.card.number1 +
              this.card.number2 +
              this.card.number3 +
              this.card.number4,
          };
          // if condition is true set value else set false or null
          params.has_automatic_payments =
            this.isCreditCard && this.isMonthsZero
              ? this.automaticPayments
              : false;
          params.has_car = this.isReposition ? this.hasCar : null;
          params.was_auctioned =
            this.isReposition && !this.hasCar ? this.isAuctioned : false;
          params.has_warranty = this.isPersonalLoan ? this.hasWarranty : null;
          params.motives =
            this.isPersonalLoan && this.hasWarranty
              ? this.personalLoanMotive
              : null;
          params.judgment_exists = this.isCT ? this.judgmentExists : null;
          params.judgment_type =
            this.isCT && this.judgmentExists ? this.judgmentOption : null;
          params.has_collection_knowledge = this.isCollection
            ? this.hasCollectionKnowledge
            : null;
          params.collection_name =
            this.isCollection && this.hasCollectionKnowledge
              ? this.collectionName
              : null;
          params.collection_name_id =
            this.isCollection && this.hasCollectionKnowledge
              ? this.collectionNameId
              : null;
          params.new_debt_type = this.isOthers ? this.newDebtType : null;
          const response = await amgApi.post(
            "/sale/update-credit-list-ds",
            params
          );
          if (response.status === 200) {
            this.$emit("click", false);
            this.$emit("reload");
            this.showSuccessSwal();
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.removePreloader();
      }
    },
    onInputChange(text) {
      this.creditor = null;
      this.creditorsLoading = true;
      if (text === "" || text === undefined) {
        this.filteredOptions = [];
        this.creditorsLoading = false;
        return;
      }
      amgApi
        .post(`/sales-made/debt-solution/search-creditors?q=${text}`)
        .then((response) => {
          if (response.status === 200) {
            this.filteredOptions = [{ data: [...response.data] }];
            this.creditorsLoading = false;
          }
        });
    },
    onInputChange2(text) {
      this.collectionName = null;
      this.creditorsLoading2 = true;
      if (text === "" || text === undefined) {
        this.filteredOptions2 = [];
        this.creditorsLoading2 = false;
        return;
      }
      amgApi
        .post(`/sales-made/debt-solution/search-creditors?q=${text}`)
        .then((response) => {
          if (response.status === 200) {
            this.filteredOptions2 = [{ data: [...response.data] }];
            this.creditorsLoading2 = false;
          }
        });
    },
    selectHandler(value) {
      this.creditor = value.item.id;
    },
    selectHandler2(value) {
      this.collectionName = value.item.value;
      this.collectionNameId = value.item.id;
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.value;
    },
    getSuggestionValue2(suggestion) {
      return suggestion.item.value;
    },

    activeFocus: function (index, max) {
      let inputValue = this.$refs?.[`input-${index}`];
      if (inputValue.value.length === max - 1) {
        const nextElement = this.$refs?.[`input-${index + 1}`];
        if (nextElement) nextElement.focus();
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
.vue-autosuggest-error > div > input {
  border: 1px solid red !important;
}
.bri-select .vs__dropdown-toggle {
  border: 1px solid red !important;
}

.lds-dual-ring {
  position: absolute;
  right: 3rem;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 1.2rem;
  height: 1.2rem;
  margin: 10px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
