import CustomerTicketsMain from "@/views/commons/components/customer-tickets/CustomerTicketsMain.vue";
import { ticketsTabs } from "@/views/customer-tickets/router/tickets.router.js";

export default [
  {
    path: "/business/tickets",
    name: "business-customer-tickets",
    redirect: { name: "customer-ticket-business-in-progress" },
    component: CustomerTicketsMain,
    meta: {
      pageTitle: "Customer Tickets",
      breadcrumb: [
        {
          text: "My tickets",
        },
      ],
      permittedRoles: [2, 17],
      tab: 4,
    },
    children: ticketsTabs("business", [2, 17]),
  },
];
