import { saleMadeTabs } from "@/views/commons/components/sales-made/router/sale-made-crm.router";

export default {
  path: "salesmade",
  name: "sales-made-crm",
  redirect: { name: "sales-made-crm-lead" },
  component: () => import("./SalesMade.vue"),
  // children: [
  //   // Note we provide the above parent route name on the default child tab
  //   // route to ensure this tab is rendered by default when using named routes
  //   {
  //     path: "",
  //     component: () =>
  //       import(
  //        /* webpackChunkName: "NewClientComponent" */ "@/views/crm/views/sales-made/components/NewClientComponent.vue"
  //       ),
  //     name: "sales-made-crm-new-client",
  //     meta: {
  //       pageTitle: "Sales Made",
  //       breadcrumb: [
  //         {
  //           text: "New Clients",
  //           active: true,
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     path: "programs",
  //     component: () =>
  //       import(
  //        /* webpackChunkName: "AddChangeComponent" */ "@/views/crm/views/sales-made/components/AddChangeComponent.vue"
  //       ),
  //     name: "sales-made-crm-add-change",
  //     meta: {
  //       program: 0,
  //       permittedRoles: [1, 2, 17],
  //       pageTitle: "Sales Made",
  //       breadcrumb: [
  //         {
  //           text: "Add/Change Service",
  //           active: true,
  //         },
  //       ],
  //     },
  //   },
  //   {
  //     path: "annul",
  //     component: () =>
  //       import(/* webpackChunkName: "AnnuledComponent" */ "@/views/crm/views/sales-made/components/AnnuledComponent.vue"),
  //     name: "sales-made-crm-annulled",
  //     meta: {
  //       permittedRoles: [1, 2, 17],
  //       pageTitle: "Sales Made",
  //       breadcrumb: [
  //         {
  //           text: "Annulled",
  //           active: true,
  //         },
  //       ],
  //     },
  //   },
  // ],
  children: saleMadeTabs("crm"),
  meta: {
    pageTitle: "Sales Made",
    breadcrumb: [
      {
        text: "Sales Made",
      },
    ],
  },
};
