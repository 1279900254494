export default [
    {
        path: "checks",
        name: "correspondence-checks",
        redirect: { name: "correspondence-checks-new" },
        meta: {
            pageTitle: "Checks",
            breadcrumb: [
                {
                    text: "Checks",
                    active: true,
                },
            ],
            routeNew: "correspondence-checks-new",
            routeSent: "correspondence-checks-sent",
            routeCancelled: "correspondence-checks-cancelled",
        },
        component: () =>
            import(
                /* webpackChunkName: "DefaultNameSection" */
                "@/views/correspondence/views/checks/view/CorrespondenceChecksMain.vue"
            ),
        children: [
            {
                path: "new",
                name: "correspondence-checks-new",
                component: () =>
                    import(
                        "@/views/correspondence/views/checks/view/CorrespondenceChecksGrid.vue"
                    ),
                meta: {
                    pageTitle: "Checks",
                    tabName: "Checks",
                    breadcrumb: [
                        {
                            text: "New",
                            active: true,
                        },
                    ],
                    tabSelected: 1,
                },
            },
            {
                path: "sent",
                name: "correspondence-checks-sent",
                component: () =>
                    import(
                        "@/views/correspondence/views/checks/view/CorrespondenceChecksGrid.vue"
                    ),
                meta: {
                    pageTitle: "Checks",
                    tabName: "Checks",
                    breadcrumb: [
                        {
                            text: "Sent",
                            active: true,
                        },
                    ],
                    tabSelected: 2,
                },
            },
            {
                path: "cancelled",
                name: "correspondence-checks-cancelled",
                component: () =>
                    import(
                        "@/views/correspondence/views/checks/view/CorrespondenceChecksGrid.vue"
                    ),
                meta: {
                    pageTitle: "Checks",
                    tabName: "Checks",
                    breadcrumb: [
                        {
                            text: "Cancelled",
                            active: true,
                        },
                    ],
                    tabSelected: 3,
                },
            },
        ],
    }
]
