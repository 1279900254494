import Vue from 'vue'
import store from '@/store/index'
import allCountersCeDigitalConnection from '@/views/ce-digital/sub-modules/connection/cedigital-connection-counter'

const NewClientsToAgentNotification = () => {
  const currentSession = store.state.auth.currentUser.user_id

  window.socket.bind('clients-notifications-connection', async ({ message, to_id })=>{


    if(to_id == currentSession){  

      await allCountersCeDigitalConnection()

      const {isConfirmed,isDismissed} = await Vue.swal.fire({
        icon: "success",
        title: 'Notice!',
        text: message,
      });

      if(isConfirmed )
          await window.amgApi.post("/commons/close-all-swal", {to_id});

    }

  })
}


export default NewClientsToAgentNotification;
