<template>
  <div
    class="container-icon-chat"
    :class="leadPhoneNumber ? 'container-icon-chat-active' : ''"
    @click="goToMessages"
    id="tooltip-target-1"
  >
    <tabler-icon
      :badge="G_COUNTER_MESSAGES_NOT_READ_LEAD"
      badge-classes="badge-important"
      icon="MessageIcon"
      size="25"
      class="cursor-pointer"
    />

    <b-tooltip target="tooltip-target-1" triggers="hover">
      {{ leadPhoneNumber ? "Sms Inbox" : "Without a phone number" }}
    </b-tooltip>

    <b-modal
      v-model="showModal"
      @hidden="clearAll"
      :size="modalSize"
      hide-footer
      title="SMS INBOX"
      title-class="h3 text-white font-weight-bolder"
    >
      <rc-chat-one
        v-if="credential"
        :lead-id="leadId"
        :lead-phone-number="leadPhoneNumber"
        :credential="credential"
        :current-chat="currentChat"
        @chatCreated="getClientChat()"
      />
    </b-modal>
  </div>
</template>

<script>
import io from "socket.io-client";
import { mapGetters, mapActions } from "vuex";
import RcChatOne from "@/views/commons/components/ring-central/chat-sms/RcChatOne.vue";
import SmsService from "@/views/commons/components/ring-central/chat-sms/service/sms.service.js";
import NestService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";

export default {
  components: {
    RcChatOne,
  },
  props: {
    leadPhoneNumber: {
      type: String,
    },
    leadId: {
      type: [String, Number],
    },
    leadName: {
      type: String,
    },
  },

  data() {
    return {
      showModal: false,
      socket: null,

      credential: null,
      currentChat: null,
    };
  },

  computed: {
    ...mapGetters({
      G_COUNTER_MESSAGES_NOT_READ_LEAD:
        "SmsInboxStore/G_COUNTER_MESSAGES_NOT_READ_LEAD",
      currentBreakPoint: "app/currentBreakPoint",
    }),

    modalSize() {
      if (this.currentBreakPoint == "xxl") {
        return "xls";
      } else {
        return "caro";
      }
    },
  },
  methods: {
    ...mapActions({
      A_GET_COUNTER_MESSAGES_NOT_READ_LEAD:
        "SmsInboxStore/A_GET_COUNTER_MESSAGES_NOT_READ_LEAD",
    }),

    async getClientChat() {
      const params = {
        modulePhoneNumber: this.credential.number_format,
        clientPhoneNumber: this.leadPhoneNumber,
      };

      try {
        const { data } = await NestService.getClientChat(params);
        if (data) {
          this.currentChat = data;
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    clearAll() {
      this.showModal = false;
    },

    async getNumbersForModule() {
      if (!this.leadPhoneNumber) return;

      const { data } = await SmsService.getCredentialByLeadId(this.leadId);
      this.credential = data[0];

      this.getClientChat();

      this.A_GET_COUNTER_MESSAGES_NOT_READ_LEAD({
        phoneNumber: this.leadPhoneNumber,
        credentialId: this.credential.id,
      });
    },

    goToMessages() {
      if (!this.leadPhoneNumber) return;
      this.showModal = true;
    },

    async bindReadAllMessage() {
      try {
        this.socket.on("notification-message-created", (data) => {
          if (data.direction == "Inbound" && data.readStatus == "Unread") {
            this.A_GET_COUNTER_MESSAGES_NOT_READ_LEAD({
              phoneNumber: this.leadPhoneNumber,
              credentialId: this.credential.id,
            });
          }
        });

        this.socket.on("notification-read-all-messages", (data) => {
          this.A_GET_COUNTER_MESSAGES_NOT_READ_LEAD({
            phoneNumber: this.leadPhoneNumber,
            credentialId: this.credential.id,
          });
        });
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    initWebsocket() {
      const connectionOptions = {
        "force new connection": true,
        reconnectionAttempts: "Infinity",
        timeout: 10000,
        transports: ["websocket"],
      };
      this.socket = io(process.env.VUE_APP_NEST_BACK, connectionOptions);
    },
  },

  created() {
    this.initWebsocket();
    this.getNumbersForModule();
  },

  mounted() {
    this.bindReadAllMessage();
  },
};
</script>

<style lang="scss" scoped>
.container-icon-chat {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #6e6b7b;

  .badge-pending {
    position: absolute;
    background-color: red;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -5px;
    right: -5px;
  }
}

.container-icon-chat-active {
  background-color: #0527ff;
  color: #ffffff;
}

.dark-layout {
  .container-icon-chat {
  }
}
</style>