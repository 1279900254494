import store from "@/store"
import Vue from "vue"
import router from '@/router/index'

const migrationRespondNotification = () => {
  window.socket.bind("migration-request-respond", async (dataNotification) => {
    const sessionId = store.state.auth.currentUser.user_id
    const arrUsers = dataNotification.to_id
    const newClientTypeId = dataNotification.new_client_type_id
    const destinationRoute = newClientTypeId === 1 ? "creditexperts-clients" : "creditexperts-digital-clients"
    if(arrUsers.includes(Number(sessionId))) {
      var randomTime = Math.floor(Math.random() * 20 + 1) * 50
      setTimeout(async function () {
        const result = await Vue.swal.fire({
          icon: "success",
          html: `<h2 style="font-weight: 600;">${dataNotification.message}</h2>`,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Go to Clients",
          cancelButtonText: "Cancel",
        });
        if (result.value) {
          router.push({ name: destinationRoute })
          .catch( (err) => {
            if(
              err.name !== 'NavigationDuplicated' &&
              !err.message.includes('Avoided redundant navigation to current location')
            ) {
              throw err;
            }
          });
          await window.amgApi.post("/commons/close-all-swal", dataNotification)
        } else {
          await window.amgApi.post("/commons/close-all-swal", dataNotification)
        }
      }, randomTime);
    }
  });
};

export default migrationRespondNotification
