<template>
  <b-modal
    ref="modal-tracking-assing-case"
    title="ASSIGNED TRACKING"
    title-tag="h3"
    size="lg"
    modal-class="modal-primary"
    hide-footer
    no-close-on-backdrop
    @hidden="close"
  >
    <b-table
      ref="trackingTable"
      class="blue-scrollbar"
      primary-key="id"
      empty-text="No matching records found"
      responsive="md"
      sticky-header="50vh"
      small
      show-empty
      no-provider-filtering
      :fields="fields"
      :items="myProvider"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
    </b-table>
  </b-modal>
</template>
<script>
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";
export default {
  props: {
    caseId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      fields: [
        { key: "commentary", label: "Commentary", sortable: false },
        { key: "advisor_id", label: "Advisor", sortable: false },
        { key: "created_by", label: "Created By", sortable: false },
        { key: "created_at", label: "Created AT", sortable: false },
        { key: "updated_at", label: "Update AT", sortable: false },
      ],
    };
  },
  mounted() {
    this.toggleModal("modal-tracking-assing-case");
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async myProvider() {
      try {
        this.addPreloader();
        const { data } = await CourtInfoService.getTrackingAssingCaseCourtInfo({
          caseId: this.caseId,
        });
        return data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>