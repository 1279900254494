export default [
  {
    path: '/rrhh/payroll-payments',
    name: 'rrhh-payroll-payments',
    component: () => import('@/views/commons/components/payroll-payments/PayrollPayments.vue'),
    // redirect: { name: 'rrhh-payroll-AMG' },
    meta: {
      pageTitle: 'Payroll payments',
      breadcrumb: [
        {
          text: 'Payroll payments',
          active: true,
        },
      ],
      permittedRoles: [1, 2, 17,29],
    },
  },
];
