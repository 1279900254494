<template>
  <b-modal
    id="AddEditCredential"
    v-model="onControl"
    size="lg"
    centered
    no-close-on-backdrop
    @hidden="close"
    header-class="p-0"
    header-bg-variant="transparent"
    header-text-variant="primary"
    modal-class="custom-modal-amg"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <div class="d-flex align-items-center">
          <feather-icon icon="UploadCloudIcon" class="text-white" size="22" />
          <span> UPLOAD FILES</span>
        </div>
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="close()"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>
    <validation-observer ref="fileForm">
      <b-row v-if="state == 'single'">
        <b-col cols="4">
          <validation-provider v-slot="{ errors }" name="Type" rules="required">
            <b-form-group label="TYPE">
              <b-form-select v-model="form.type" :options="typesOptions" />
              <span v-if="errors[0]" class="text-danger">
                Type {{ errors[0] }}</span
              >
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="4">
          <validation-provider v-slot="{ errors }" name="doe" rules="required">
            <b-form-group label="DOE">
              <b-form-datepicker
                v-model="form.datexp"
                :min="new Date()"
                locale="en"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
              />
              <span v-if="errors[0]" class="text-danger">
                DOE {{ errors[0] }}</span
              >
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="4">
          <validation-provider v-slot="{ errors }" name="file" rules="required">
            <b-form-group label="FILE">
              <b-form-file v-model="fileToUpload" @input="fileFill" />
              <span v-if="errors[0]" class="text-danger">
                File {{ errors[0] }}</span
              >
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row v-else class="mb-2">
        <b-col cols="12">
          <drag-and-drop
            ref="file-manager"
            v-model="uploadFiles"
            :files-array="uploadFiles"
          />
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <b-button
        variant="custom-amg"
        @click="state == 'single' ? saveOneFile() : saveMultipleFiles()"
      >
        Upload
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import ClientsOptionsServices from "@/views/commons/components/clients/dashboard/services/clients.options.services";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    DragAndDrop,
  },
  props: {
    state: {
      required: true,
      type: String,
      default: "single",
    },
    selectedFolder: {
      required: true,
      type: [Array, Object],
    },
  },
  data() {
    return {
      onControl: false,
      form: {
        image: "",
        namedoc: "",
        account: "",
        size: "",
        user_id: "",
        type: "",
        datexp: "",
        folder_name: "",
        folder_id: "",
      },
      typesOptions: [
        {
          value: "ID",
          text: "ID",
        },
        {
          value: "UB",
          text: "UB",
        },
        {
          value: "Others",
          text: "Others",
        },
      ],
      uploadFiles: [],
      fileToUpload: [],
    };
  },
  created() {
    this.onControl = true;
    this.form.account = this.$route.params.idClient
      ? this.$route.params.idClient
      : this.dataFile.client_account_id;
    this.form.user_id = this.currentUser.user_id;
  },
  methods: {
    ...mapActions({
      A_UPDATE_TABLE:
        "SpecialistsDigitalRoundLettersStore/A_S_UPDATE_LETTER_TABLE",
    }),
    close() {
      this.$emit("close");
    },
    async saveMultipleFiles() {
      try {
        this.addPreloader();
        const formData = new FormData();
        if (this.uploadFiles !== null && this.uploadFiles !== "") {
          this.uploadFiles.forEach((file) => {
            formData.append("images[]", file, file.name);
          });
        }
        const folder =
          this.selectedFolder.id === null ? "" : this.selectedFolder.id;
        const client_account_id = this.$route.params.idClient
          ? this.$route.params.idClient
          : this.dataFile.client_account_id;
        formData.append("account", client_account_id);
        formData.append("user_id", this.currentUser.user_id);
        formData.append("folder_id", folder);
        formData.append("folder_name", this.selectedFolder.file_name);

        const headers = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          }.bind(this),
        };
        const data = await ClientsOptionsServices.saveMultipleFilesAccount(
          formData,
          headers
        );
        if (data.status === 200) {
          this.$emit("reload");
          this.showSuccessSwal();
          this.close();
          await Promise.all([
            this.$store.dispatch("DebtSolutionClients/A_COUNT_FILES", {
              idaccountclient: this.$route.params.idClient
                ? this.$route.params.idClient
                : this.dataFile.client_account_id,
            }),
          ]);
        }
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
    async saveOneFile() {
      try {
        const result = await this.$refs.fileForm.validate();
        if (result) {
          this.form.folder_id = this.selectedFolder.id;
          this.form.folder_name = this.selectedFolder.file_name;
          this.addPreloader();
          const data = await ClientsOptionsServices.saveOneFileAccount(
            this.form
          );
          if (data.status === 200) {
            this.$emit("reload");
            this.showSuccessSwal();
            this.isShownUpload = false;
            this.removePreloader();
            await Promise.all([
              this.$store.dispatch("DebtSolutionClients/A_COUNT_FILES", {
                idaccountclient: this.$route.params.idClient
                  ? this.$route.params.idClient
                  : this.dataFile.client_account_id,
              }),
            ]);
            await this.A_UPDATE_TABLE(true);
            await this.getMissingFiles();
            this.close();
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        this.removePreloader();
      }
    },
    async fileFill() {
      this.form.namedoc = this.fileToUpload.name;
      this.form.size = this.fileToUpload.size;
      const reader = new FileReader();
      reader.readAsDataURL(this.fileToUpload);
      reader.onload = async (file) => {
        this.form.image = file.target.result;
      };
    },
    async getMissingFiles() {
      const { data } = await ClientsOptionsServices.getMissingFiles({
        accountId: this.$route.params.idClient,
      });
      this.client.missing_files = data.data[0].missing_files;
      await this.$store.dispatch("ReportStore/A_MISSING_FILES", data);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      client: "DebtSolutionClients/G_CLIENTS",
      updateLetterTable:
        "SpecialistsDigitalRoundLettersStore/G_UPDATE_LETTER_TABLE",
    }),
  },
};
</script>

<style scoped lang="scss">
.header-modal {
  border-radius: 3px 3px 0px 0px;
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}
.header-modal {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}
</style>
