<template>
  <b-modal
    ref="type-action"
    title-class="h4 text-white"
    modal-class="modal-primary"
    centered
    size="lg"
    :title="`SEND TO ${clientData.actionType == 2 ? 'TRACING' : 'RECYCLED'}`"
    @hidden="closeModal"
    no-close-on-backdrop
  >
    <b-row cols="2">
      <b-col>
        <b-form-group class="mt-2">
          <b-input-group :prepend="$route.meta.section.toUpperCase()">
            <div class="form-control h-auto bg-transparent border-secondary">
              {{ clientData.lead_name }}
            </div>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group class="mt-2">
          <b-input-group prepend="CATCHER">
            <div class="form-control h-auto bg-transparent border-secondary">
              {{ clientData.catcher_by }}
            </div>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <ValidationObserver ref="formTypeAction">
      <b-row cols="1">
        <b-col>
          <validation-provider
            v-slot="{ errors }"
            name="subject"
            rules="required"
          >
            <b-form-group label="Observation">
              <b-textarea v-model="obs" :state="errors[0] ? false : null" />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </ValidationObserver>

    <template #modal-footer>
      <b-button variant="success" @click="onSubmit">SAVE</b-button>
    </template>
  </b-modal>
</template>

<script>
import vSelect from "vue-select";
import LeadService from "@/views/ce-digital/sub-modules/sales/views/leads/service/leads.service";
import { mapGetters } from "vuex";

export default {
  name: "Derivation",
  components: { vSelect },
  props: {
    clientData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      obs: null,
      value: null,
      selectOptions: [
        { label: "TRACING", id: 2 },
        { label: "RECYCLED", id: 4 },
      ],
    };
  },
  mounted() {
    this.toggleModal("type-action");
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    typeSection() {
      switch (this.$route.name) {
        case "sales-clients-news":
          return 1; // New
          break;
        case "sales-clients-tracing":
          return 2; // Tracing
          break;
        case "sales-clients-success":
          return 3; // Success
          break;
        case "sales-clients-recycled":
          return 4; // One Recycled
          break;
      }
    },
  },

  methods: {
    async onSubmit() {
      const validation = await this.$refs.formTypeAction.validate();
      if (validation) {
        try {
          const confirm = await this.showConfirmSwal();
          if (confirm.isConfirmed) {
            this.addPreloader();

            const params = {
              derivation_id: this.clientData.derivation_id,
              status: this.clientData.actionType, // Tracing(2) or Recycled(4)
              type: this.clientData.type, // Lead(1) or Client(2)
              user_id: this.currentUser.user_id,
              obs: this.obs,
            };
            const response = await LeadService.updateLeadsAndClients(params);
            if (response.status === 200) {
              this.removePreloader();
              this.closeModal();
              this.$emit("refresh");
            }
          }
        } catch (e) {
          this.removePreloader();
          console.log(e);
        }
      }
    },
    closeModal() {
      this.$emit("hideModal");
    },
  },
};
</script>

<style scoped></style>
