import store from "@/store";
import Notifications from '@/service/notification/index';
import ZeroPaymentService from './view/zero-payment/zero-payment-ce-digital.service'
import DeletedCardsService from '@/views/ce-digital/sub-modules/financial/view/deleted-cards/deleted-cards.service'
import AppPaymentsMadeService
  from '@/views/ce-digital/sub-modules/financial/view/app-payments-made/app-payments-made.service'
  import employeeClaims from "@/views/commons/commons-counter"
  import RequestService from "@/views/commons/components/request/services/request.service.js"
import RecruitmentService from "@/views/commons/components/recruitment-process/services/recruiment.service";

const zeroPaymentFinancialCounter = async () => {
  const data = await ZeroPaymentService.getZeroPaymentFinancialCounter();
  if (data.data) {
    const payload = {
      routeName: "ce-zero-payment",
      tag: data.data,
    };
    store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
  }
};

const deletedCardsCounter = async () => {
  const data = await DeletedCardsService.getNotSeenDeletedCards()
  if (data.data) {
    const payload = {
      routeName: 'ce-digital-deleted-cards',
      tag: data.data > 99 ? '99+' : data.data,
    }
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload)
  }
}

const appPaymentsMadeCounter = async () => {
  const data = await AppPaymentsMadeService.getNotSeenAppPayMade()
  if (data.data) {
    const payload = {
      routeName: 'ce-digital-app-payments-made',
      tag: data.data > 99 ? '99+' : data.data,
    }
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload)
  }
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'financial-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id}
  const data = await RequestService.getCounterNotAsigned(params)

  const payload = {
      routeName: 'request-financial',
      tag: data[0].counter
  }
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload)
}

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'financial-recruitment-process',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const { data } = await Notifications.getCountPendingCustomerTickets({ user_id });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: 'financial-customer-tickets',
      tag: data[0].v_total_open,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const allCountersCeDigitalFinancial = async () => {
  Promise.all([
    zeroPaymentFinancialCounter(),
    projectsCounter(),
    deletedCardsCounter(),
    appPaymentsMadeCounter(),
    employeeClaims("financial-employee-claims") ,
    requestNotAsignedCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
  ]);
};
export default allCountersCeDigitalFinancial;
