export default [
    {
      path: "settings",
      name: "correspondence-settings",
      component: () => import(/* webpackChunkName: "CorrespondenceSettings" */ "@/views/correspondence/views/settings/Settings.vue"),
      redirect: { name: "correspondence-origin-states"},
      meta: {
        pageTitle: "Settings",
        breadcrumb: [
          {
            text: "Settings",
          },
        ],
      },
      children:[
        {
          path: "",
          name: "correspondence-origin-states",
          component: () => import("@/views/correspondence/views/settings/components/OriginState.vue"),
          meta: {
            pageTitle: "Settings",
            breadcrumb: [
              {
                text: "Origin States"
              }
            ]
          }
        },
        {
          path: "",
          name: "correspondence-account-number",
          component: () => import("@/views/correspondence/views/settings/components/AccountNumber.vue"),
          meta: {
            pageTitle: "Settings",
            breadcrumb: [
              {
                text: "Account Number"
              }
            ]
          }
        }
      ]
    },
  ];
  