import globalService from "@/service/global/index.js";

export default {
  namespaced: true,
  state: {
    S_REFRESH: false,
    S_PROGRAMS: [],
  },
  getters: {
    G_REFRESH: (state) => state.S_REFRESH,
    G_PROGRAMS: (state) => {
      return state.S_PROGRAMS || [1, 8, 3];
    },
  },
  mutations: {
    M_REFRESH: (state) => {
      state.S_REFRESH = !state.S_REFRESH;
    },

    M_SET_PROGRAMS(state, payload) {
      state.S_PROGRAMS = payload || [1, 8, 3];
    },
  },
  actions: {
    A_REFRESH: ({ commit }) => {
      commit("M_REFRESH");
    },

    async A_GET_PROGRAMS({ commit }) {
      try {
        const { data } = await globalService.getProgramsNewStatus();
        const programs = data.map((program) => program.program_id);
        commit("M_SET_PROGRAMS", programs);
      } catch (error) {
        throw error;
      }
    },
  },
};
