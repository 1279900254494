export default [
  {
    path: "letters",
    name: "correspondence-letters",
    redirect: { name: "correspondence-letters-pending" },
    component: () => import(/* webpackChunkName: "CorrespondenceLetters" */ "@/views/correspondence/views/letters/Letters.vue"),
    meta: {
      pageTitle: "Letters",
      breadcrumb: [
        {
          text: "Letters",
        },
      ],
    },
    children: [
      {
        path: "",
        name: "correspondence-letters-pending",
        component: () => import(/* webpackChunkName: "CorrespondenceLettersPending" */ "@/views/correspondence/views/letters/components/tables/LettersTablePending.vue"),
        meta: {
          status: 1,
          pageTitle: "Letters",
          breadcrumb: [
            {
              text: "Letters to bureaus",
              active: true,
            },
          ],
        },
      },
      {
        path: "sent",
        name: "correspondence-letters-send",
        component: () => import(/* webpackChunkName: "CorrespondenceLettersSend" */ "@/views/correspondence/views/letters/components/tables/LettersTableSend.vue"),
        meta: {
          status: 2,
          pageTitle: "Letters",
          breadcrumb: [
            {
              text: "Letters to bureaus",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
