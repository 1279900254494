import UserLogService from "@/views/management/views/users-log/services/users-log.service.js";
let previousArrayRoutes = [];
let previousIdUserLog = null;
class UserLogEvent {
  async processUserLogEventOnChangeTab(userData, matched, component = {}) {
    try {
      if (!userData) return;
      const arrayRoutes = this.processRouteUserLog(matched, component);
      const params = {
        module_id: matched[0]?.meta?.module ?? null,
        user_id: userData.user_id,
        details: arrayRoutes,
      };
      const { data } = await UserLogService.storeUserLog(params);
      await this.processSessionStorageUserLog(
        data.data,
        arrayRoutes,
        component
      );
      return data;
    } catch (error) {
      console.error("Error in UserLogEvent::processUserLogOnChangeTab", error);
    }
  }
  async processUserLogEvent(userData, matched, component = {}) {
    try {
      if (!userData) return;
      const arrayRoutes = this.processRouteUserLog(matched, component);
      if (this.checkIfRouteIsTheSame(arrayRoutes, component)) return;
      const params = {
        module_id: matched[0]?.meta?.module ?? null,
        user_id: userData.user_id,
        details: arrayRoutes,
      };
      const { data } = await UserLogService.storeUserLog(params);
      await this.processPageVisitTime(component);
      await this.processSessionStorageUserLog(
        data.data,
        arrayRoutes,
        component
      );
      return data;
    } catch (error) {
      console.error(
        "Error in UserLogEvent::processUserLogEventOnNavigation",
        error
      );
    }
  }

  processRouteUserLog(matched, component) {
    const routes = matched;
    let arrayRoutes = [];
    const type = component?.type ?? 1;

    routes.map((route) => {
      const path = route?.path ?? null;
      const currentRoute = this.getLastPartOfRoute(path);
      const params = {
        route_name: currentRoute,
        description_name: this.removeQuotesRoute(
          route?.meta?.breadcrumb?.[0]?.text,
          currentRoute
        ),
        type: 1,
      };
      arrayRoutes.push(params);
    });

    if (this.isComponent(component)) {
      const params = {
        route_name: component.component_type,
        description_name: component.title,
        type: type,
      };
      arrayRoutes.push(params);
    }

    return arrayRoutes;
  }

  getLastPartOfRoute(route) {
    if (!route) return null;
    const routeParts = route.split("/");
    return routeParts[routeParts.length - 1];
  }

  removeQuotesRoute(breadcrumbText, currentRoute) {
    let string = !breadcrumbText ? currentRoute : breadcrumbText;
    string = string.replace(/-/g, " ");
    string = string.replace(/\b\w/g, (l) => l.toUpperCase());
    return string;
  }

  isComponent(component) {
    return Object.keys(component).length > 0 ? true : false;
  }
  checkIfRouteIsTheSame(arrayRoutes, component) {
    if (this.isComponent(component)) return false;
    this.initSessionStorage();
    if (previousArrayRoutes.length === 0) return false;

    const isTheSame = this.compareArrays(previousArrayRoutes, arrayRoutes);
    return isTheSame;
  }

  compareArrays(array1, array2) {
    return (
      array1.length === array2.length &&
      array1.every((value, index) => {
        return (
          value.route_name === array2[index].route_name &&
          value.description_name === array2[index].description_name
        );
      })
    );
  }

  async processSessionStorageUserLog(id, arrayRoutes, component) {
    try {
      if (this.isComponent(component)) return;
      let currentArrayRoutes = JSON.stringify(arrayRoutes);

      let arraySessionStorage = [
        { key: "previousArrayRoutes", value: currentArrayRoutes },
        { key: "previousIdUserLogRoute", value: id },
      ];
      arraySessionStorage.map((item) => {
        this.setItemSessionStorage(item.key, item.value);
      });
    } catch (error) {
      console.error(
        "Error in UserLogEvent::processSessionStorageUserLog",
        error
      );
    }
  }

  async processPageVisitTime(component = {}) {
    try {
      if (this.isComponent(component)) return;
      const params = {
        id: previousIdUserLog,
      };
      await this.updateUserLogActivity(params);
      this.processRemoveItemSessionStorage();
    } catch (error) {
      console.error("Error in UserLogEvent::processPageVisitTime", error);
    }
  }

  initSessionStorage() {
    previousArrayRoutes =
      JSON.parse(this.getItemsSessionStorage("previousArrayRoutes")) || [];
    previousIdUserLog =
      this.getItemsSessionStorage("previousIdUserLogRoute") || null;
  }

  getItemsSessionStorage(key) {
    return sessionStorage.getItem(key);
  }

  setItemSessionStorage(key, value) {
    sessionStorage.setItem(key, value);
  }

  processRemoveItemSessionStorage() {
    let arraySessionStorage = [
      { key: "previousArrayRoutes" },
      { key: "previousIdUserLogRoute" },
    ];

    arraySessionStorage.map((item) => {
      this.removeItemSessionStorage(item.key);
    });

    this.resetSessionStorageVariables();
  }

  removeItemSessionStorage(key) {
    sessionStorage.removeItem(key);
  }

  resetSessionStorageVariables() {
    previousArrayRoutes = [];
    previousIdUserLog = null;
  }

  async updateUserLogActivity(params) {
    try {
      if (!params) return;
      const headers = {
        "Content-Type": "application/json",
        Method: "POST",
      };
      await UserLogService.updateUserLogActivity(params, headers);
    } catch (error) {
      console.error("Error in UserLogEvent::updateUserLogActivity", error);
    }
  }
}

export default new UserLogEvent();
