export default [
  {
    path: 'leads',
    name: 'claimdepartment-leads',
    redirect: { name: 'claimdepartment-leads-list' },
    component: () => import(/* webpackChunkName: "ClaimDepartamentLeads" */ '@/views/commons/components/lead-programs/Lead.vue'),
    meta: {
      pageTitle: 'Leads',
      breadcrumb: [
        {
          text: 'Leads',
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'claimdepartment-leads-list',
        component: () => import(/* webpackChunkName: "ClaimDepartamentLeadsList" */ '@/views/commons/components/lead-programs/components/lead-table/LeadTable.vue'),
        meta: {
          route: 'claimdepartment',
          isClientsTab: true,
          pageTitle: 'Leads',
          breadcrumb: [
            {
              text: 'Leads',
              to: '/claimdepartment/leads',
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: 'leads/show/:id',
    name: 'claimdepartment-lead-show',
    component: () => import(/* webpackChunkName: "ClaimDepartamentLeadsShow" */ '@/views/crm/views/Lead/lead-module/dashboard/default.vue'),
    props: true,
    meta: {
      isClientsTab: true,
      route: 'claimdepartment',
      contentClass: 'leads-app',
    },
  },
  {
    path: 'leads/report/:idlead/:idfile',
    name: 'claimdepartment-report-lead',
    component: () => import(/* webpackChunkName: "ClaimDepartamentLeadsReport" */ '@/views/commons/components/ncr/components/report/ReportLead.vue'),
    props: true,
    meta: {
      isClientsTab: true,
      pendingRoute: 'ncr-pending',
      returnedRoute: 'ncr-returned',
      completedRoute: 'ncr-completed',
      reportLeadRoute: 'report-lead',
      leadRoute: 'lead-show',
    },
  },
]
