import { saleMadeTabs } from "@/views/commons/components/sales-made/router/sale-made.router";
export default {
  path: "sales-made",
  name: "sales-made-tax-research",
  redirect: { name: "sales-made-tax-research-lead-pending" },
  component: () =>
    import(
      /* webpackChunkName: "TaxResearchSalesMade" */ "@/views/tax-research/views/sales-made/index.vue"
    ),
  meta: {
    program: 5, // ??
    pageTitle: "Sales Made",
    breadcrumb: [
      {
        text: "Sales Made",
      },
    ],
  },
  // children: [
  // 	{
  // 		path: "add-change",
  // 		name: "sales-made-tax-research-add-change",
  // 		component: () => import(/* webpackChunkName: "TaxResearchSalesMadeAddChange" */ "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue"),
  // 		meta: {
  // 			program: 5,
  // 			pageTitle: "Sales Made",
  // 			breadcrumb: [
  // 				{
  // 					text: "Sales Made",
  // 					active: true,
  // 				},
  // 			],
  // 		},
  // 	},
  // 	{
  // 		path: "add-sale",
  // 		name: "sales-made-tax-research-add-sale",
  // 		component: () => import(/* webpackChunkName: "TaxResearchSalesMadeAddSale" */ "@/views/commons/components/sales-made/add-sale/components/SalesMadeAddSaleComponent.vue"),
  // 		meta: {
  // 			type: 0,
  // 			program: 5,
  // 			pageTitle: "Sales Made",
  // 			breadcrumb: [
  // 				{
  // 					text: "Sales Made",
  // 					active: true,
  // 				},
  // 			],
  // 		},
  // 	},
  // ],
  children: saleMadeTabs("tax-research"),
};
