export default [
  {
    path: "marketing-letters",
    name: "correspondence-marketing-letters",
    redirect: { name: "correspondence-marketing-letters-corporate-agent" },
    component: () => import(/* webpackChunkName: "CorrespondenceMarketingLetters" */ "@/views/correspondence/views/marketing-letters/view/components/marketingLettersTab.vue"),
    meta: {
      pageTitle: "Marketing Letters",
      breadcrumb: [
        {
          text: "Marketing Letters",
          active: true,
        },
      ],
      permittedRoles: [1, 2, 3],
    },
    children: [
      {
        path: "corporate-agent",
        component: () => import(/* webpackChunkName: "CorrespondenceMarketingLettersCorporateAgent" */ "@/views/correspondence/views/marketing-letters/view/components/TabAgent.vue"),
        name: "correspondence-marketing-letters-corporate-agent",
        redirect: {
          name: "correspondence-marketing-letters-corporate-agent-pending",
        },
        meta: {
          pageTitle: "Marketing Letters",
          breadcrumb: [
            {
              text: "Corporate agent",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 3],
          pendingTab:
            "correspondence-marketing-letters-corporate-agent-pending",
          sentTab: "correspondence-marketing-letters-corporate-agent-sent",
        },
        children: [
          {
            path: "pending",
            component: () => import(/* webpackChunkName: "CorrespondenceMarketingLettersCorporateAgentPending" */ "@/views/correspondence/views/marketing-letters/view/components/Pending.vue"),
            name: "correspondence-marketing-letters-corporate-agent-pending",
            meta: {
              pageTitle: "Marketing Letters",
              breadcrumb: [
                {
                  text: "Pending",
                  active: true,
                },
              ],
              permittedRoles: [1, 2, 3],
              pendingTab:
                "correspondence-marketing-letters-corporate-agent-pending",
              sentTab: "correspondence-marketing-letters-corporate-agent-sent",
            },
          },
          {
            path: "sent",
            component: () => import(/* webpackChunkName: "CorrespondenceMarketingLettersCorporateAgentSent" */ "@/views/correspondence/views/marketing-letters/view/components/Sent.vue"),
            name: "correspondence-marketing-letters-corporate-agent-sent",
            meta: {
              pageTitle: "Marketing Letters",
              breadcrumb: [
                {
                  text: "Sent",
                  active: true,
                },
              ],
              permittedRoles: [1, 2, 3],
              pendingTab:
                "correspondence-marketing-letters-corporate-agent-pending",
              sentTab: "correspondence-marketing-letters-corporate-agent-sent",
            },
          },
        ],
      },
      {
        path: "keybook",
        component: () => import("../view/components/TabAgent.vue"),
        component: () => import(/* webpackChunkName: "CorrespondenceMarketingLettersKeybook" */ "@/views/correspondence/views/marketing-letters/view/components/TabAgent.vue"),
        name: "correspondence-marketing-letters-keybook",
        redirect: { name: "correspondence-marketing-letters-keybook-pending" },
        meta: {
          pageTitle: "Marketing Letters",
          breadcrumb: [
            {
              text: "Keybook",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 3],
          pendingTab: "correspondence-marketing-letters-keybook-pending",
          sentTab: "correspondence-marketing-letters-keybook-sent",
        },
        children: [
          {
            path: "pending",
            component: () => import(/* webpackChunkName: "CorrespondenceMarketingLettersKeybookPending" */ "@/views/correspondence/views/marketing-letters/view/components/Pending.vue"),
            name: "correspondence-marketing-letters-keybook-pending",
            meta: {
              pageTitle: "Marketing Letters",
              breadcrumb: [
                {
                  text: "Pending",
                  active: true,
                },
              ],
              permittedRoles: [1, 2, 3],
              pendingTab: "correspondence-marketing-letters-keybook-pending",
              sentTab: "correspondence-marketing-letters-keybook-sent",
            },
          },
          {
            path: "sent",
            component: () => import(/* webpackChunkName: "CorrespondenceMarketingLettersKeybookSent" */ "@/views/correspondence/views/marketing-letters/view/components/Sent.vue"),
            name: "correspondence-marketing-letters-keybook-sent",
            meta: {
              pageTitle: "Marketing Letters",
              breadcrumb: [
                {
                  text: "Sent",
                  active: true,
                },
              ],
              permittedRoles: [1, 2, 3],
              pendingTab: "correspondence-marketing-letters-keybook-pending",
              sentTab: "correspondence-marketing-letters-keybook-sent",
            },
          },
        ],
      },
      {
        path: "court-info",
        component: () => import(/* webpackChunkName: "CorrespondenceMarketingLettersCourtInfo" */ "@/views/correspondence/views/marketing-letters/view/components/TabAgent.vue"),
        name: "correspondence-marketing-letters-court-info",
        redirect: {
          name: "correspondence-marketing-letters-court-info-pending",
        },
        meta: {
          pageTitle: "Marketing Letters",
          breadcrumb: [
            {
              text: "Court Info",
              active: true,
            },
          ],
          permittedRoles: [1, 2, 3],
          pendingTab: "correspondence-marketing-letters-court-info-pending",
          sentTab: "correspondence-marketing-letters-court-info-sent",
        },
        children: [
          {
            path: "pending",
            component: () => import(/* webpackChunkName: "CorrespondenceMarketingLettersCourtInfoPending" */ "@/views/correspondence/views/marketing-letters/view/components/Pending.vue"),
            name: "correspondence-marketing-letters-court-info-pending",
            meta: {
              pageTitle: "Marketing Letters",
              breadcrumb: [
                {
                  text: "Pending",
                  active: true,
                },
              ],
              permittedRoles: [1, 2, 3],
              pendingTab: "correspondence-marketing-letters-court-info-pending",
              sentTab: "correspondence-marketing-letters-court-info-sent",
            },
          },
          {
            path: "sent",
            component: () => import(/* webpackChunkName: "CorrespondenceMarketingLettersCourtInfoSent" */ "@/views/correspondence/views/marketing-letters/view/components/Sent.vue"),
            name: "correspondence-marketing-letters-court-info-sent",
            meta: {
              pageTitle: "Marketing Letters",
              breadcrumb: [
                {
                  text: "Sent",
                  active: true,
                },
              ],
              permittedRoles: [1, 2, 3],
              pendingTab: "correspondence-marketing-letters-court-info-pending",
              sentTab: "correspondence-marketing-letters-court-info-sent",
            },
          },
        ],
      },
    ],
  },
]
