<template>
  <b-img
    :src="`/assets/images/icons/cards-icon/${type_card}.svg`"
    height="35"
    :alt="nameTypeCard"
    v-b-tooltip.hover.top="nameTypeCard"
  />
</template>

<script>
export default {
  props: {
    type_card: {
      type: String,
      default: "X",
    },
  },
  computed: {
    nameTypeCard() {
      const names = {
        A: "American Express",
        D: "Discover",
        DI: "Diners Club",
        J: "JCB",
        M: "Mastercard",
        V: "Visa",
        X: "Other Card",
      };
      return names[this.type_card] || "Unknown";
    },
  },
};
</script>

<style>
</style>