import leadsNav from "@/views/credit-experts/views/leads/leads.nav";
import clientsNav from "@/views/credit-experts/views/clients/clients-group.navigation";
import saleMadeNav from "@/views/credit-experts/views/sales-made/sales-made.nav";
import zeroPayment from "@/views/credit-experts/views/zero-payment/navigation/zero-payment.navigation";
import applicationsNavigation from "@/views/credit-experts/views/applications/applications.navigation";
import DisputeNav from "@/views/credit-experts/views/dispute/navigation/dispute.nav.js";
import disputeResultsNav from "@/views/credit-experts/views/dispute-results/navigation/dispute-result.navigation";
import trackingListNav from "@/views/credit-experts/views/tracking-list/navigation/tracking-list.nav";
import reportsNav from "@/views/credit-experts/views/reports/reports.nav";
import commissionNav from "@/views/credit-experts/views/commission/commission.nav";
import paymentsNav from "@/views/credit-experts/views/payments/payments.nav";
import RequestNcrNav from "@/views/credit-experts/views/request-ncr/request-ncr.nav";
import RequestWorkplanNav from "@/views/credit-experts/views/request-workplan/request-workplan.nav.js";
import calendarNav from "@/views/credit-experts/views/calendar/calendar.nav";
import helpdeskNav from "@/views/credit-experts/views/helpdesk/helpdesk.nav";
import FileManagerNav from "@/views/credit-experts/views/file-manager/file-manager.nav";
import loansNav from "@/views/credit-experts/views/loans/loans.nav";
import schedulesNav from "@/views/credit-experts/views/schedules/schedules.nav";
import performanceNav from "@/views/credit-experts/views/advisor-performance/performance.nav";
import ProductRequestNav from "@/views/credit-experts/views/request/request.nav.js";
import claimsNav from "@/views/credit-experts/views/claims-v2/claims.nav";
import dashboardNav from "@/views/credit-experts/views/dashboard/dashboard.nav";
import StatusChangeNav from "@/views/credit-experts/views/status-change/status-change.navigation";
import NotificationsNav from "@/views/credit-experts/views/notification-app/navigation/notification-app.navigation";
import MigrationsNav from "@/views/credit-experts/views/migrations/navigation/migration.navigation";
import callRoundsRoute from "../views/call-rounds/call-rounds.navigation";
import OtherPaymentsNav from "@/views/credit-experts/views/other-payments/other-payments.nav";
import EmployeeClaimsNavigation from "@/views/credit-experts/views/employee-claims/navigation/employee-claims.nav";
//import CreditExpertsProjectsNavigation from "@/views/credit-experts/views/projects/credit-experts-projects.navigation";
import RingCentralNavigation from "@/views/credit-experts/views/ring-central/ring-central.nav.js";
import CourtInfoNavigation from "@/views/credit-experts/views/cour-info/navigation/ds-court-info.nav";
import MOFNavigation from "@/views/credit-experts/views/manual-of-functions/manual-of-functions.nav.js";
import RecruitmentProcessNavigation from "@/views/credit-experts/views/recruitment-process/credit-experts-recruitment_process.navigation";
import SendingReportNavigation from "@/views/credit-experts/views/sending-report/sending-report.nav.js";
import AppointmentNav from "@/views/credit-experts/views/appointment/appointment.navigation";
import PotencialAppointmentNav from "@/views/credit-experts/views/potencial-appointment/potencial.nav";
import OtherServicesNav from "@/views/credit-experts/views/other-services/other-services.navigation.js";
import CommissionForSpecialistsNavigation from "@/views/credit-experts/views/commission-specialists/commissions-specialists.navigation";

// data
import sidebarItems from "@/views/credit-experts/data/sidebarItems";
import { sumTag } from "@/@core/utils/utils";

const navigation = [
  {
    header: "CUSTOMER SERVICE REGULAR",
    module: 21,
    modules: sidebarItems,
  },
  ...dashboardNav,
  ...leadsNav,
  clientsNav,
  claimsNav,
  ...zeroPayment,
  {
    title: "Services",
    icon: "CreditCardIcon",
    tag: sumTag([
      ...AppointmentNav,
      ...applicationsNavigation,
      ...OtherServicesNav,
      CommissionForSpecialistsNavigation,
      // ...PotencialAppointmentNav
    ]),
    children: [
      ...AppointmentNav,
      ...applicationsNavigation,
      ...OtherServicesNav,
      CommissionForSpecialistsNavigation,
      // ...PotencialAppointmentNav
    ],
  },
  RequestNcrNav,
  RequestWorkplanNav,
  DisputeNav,
  ...disputeResultsNav,
  ...StatusChangeNav,
  // ...saleMadeNav,
  // ...commissionNav,
  ...trackingListNav,
  ...reportsNav,
  ...paymentsNav,
  ...callRoundsRoute,
  FileManagerNav,
  calendarNav,
  ...performanceNav,
  schedulesNav,
  loansNav,
  helpdeskNav,
  NotificationsNav,
  ProductRequestNav,
  MigrationsNav,
  OtherPaymentsNav,
  EmployeeClaimsNavigation,
  //CreditExpertsProjectsNavigation,
  RingCentralNavigation,
  ...CourtInfoNavigation,
  RecruitmentProcessNavigation,
  ...MOFNavigation,
  ...SendingReportNavigation,
];
export default navigation;
