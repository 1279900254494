<template>
  <b-modal
    ref="annulled-sales-clients-modal"
    title="Annulled Sales"
    title-class="h3 text-white font-weight-bolder"
    size="xmd"
    no-close-on-backdrop
    modal-class="modal-primary"
    @hidden="hiddenModal"
    hide-footer
  >
    <b-container fluid>
      <filter-slot
        :filter="[]"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refListTable'].refresh()"
      >
        <template #buttons>
          <!-- <div class="d-flex">
            <v-select
              v-model="yearSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              label="label"
              :options="years"
              :reduce="(option) => option.value"
              class="ml-1"
              style="width: 180px"
              @input="$refs['refListTable'].refresh()"
            />
            <v-select
              v-model="programSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              label="value"
              :options="programList"
              :reduce="(option) => option.id"
              class="ml-1"
              @input="$refs['refListTable'].refresh()"
            />
          </div> -->
        </template>
        <!-- table -->
        <b-table
          slot="table"
          ref="refListTable"
          primary-key="id"
          :busy.sync="isBusy"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          no-provider-filtering
          table-class="text-nowrap"
          :items="myProvider"
          :fields="fields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          sticky-header
          small
          sort-icon-left
          show-empty
        >
          <template #cell(client)="{ item }">
            <router-link
              :class="textLink"
              :to="routerClient(item)"
              target="_blank"
            >
              {{ item.client }}
            </router-link>
            <div>
              {{ item.account }}
            </div>
          </template>
          <template #cell(type)="{ item }">
            <div v-if="item.type === 1" v-b-tooltip.bottom="'Add'">AD</div>
            <div v-else v-b-tooltip.bottom="'Change'">CH</div>
          </template>
          <template #cell(first_program)="{ item }">
            <program-icon
              :programName="item.first_program"
              :programId="item.first_program_id"
            />
          </template>
          <template #cell(program)="{ item }">
            <program-icon
              :programName="item.program"
              :programId="item.program_id"
            />
          </template>
          <template #cell(executives)="{ item }">
            <div class="d-flex flex-column">
              <span class="text-info" v-b-tooltip.hover.top="`Seller`">
                {{ item.seller }}
              </span>
              <span class="text-warning" v-b-tooltip.hover.top="`Catcher`">
                {{ item.captured }}
              </span>
            </div>
          </template>
          <template #cell(notes)="data">
            <b-icon
              v-if="data.item.creates > '2021-05-16 00:00:00'"
              icon="chat-square-text-fill"
              class="cursor-pointer"
              :variant="
                data.item.notes_status_new == null
                  ? 'muted'
                  : data.item.notes_status_new == 0
                  ? 'success'
                  : 'warning'
              "
              @click="notesModal(data.item, data.index)"
            />
            <b-icon
              v-else
              icon="chat-square-text-fill"
              class="cursor-pointer"
              :variant="data.item.notes_status === 0 ? 'muted' : 'success'"
              @click="notesModal(data.item, data.index)"
            />
          </template>
          <template #cell(dates)="{ item }">
            <span class="text-warning d-block">{{
              item.annulled_by_name
            }}</span>
            <span
              class="text-warning d-block"
              v-b-tooltip.hover.top="'Annulled at'"
              >{{ item.annulled_at | myGlobalWithHour }}</span
            >
          </template>
          <template #cell(fee)="{ item }">
            $ {{ item.fee | currency }}
          </template>
        </b-table>
      </filter-slot>
    </b-container>

    <component
      :is="modalData.notes.programSelected"
      v-if="modal.notes"
      :note-info="modalData.notes"
      @hide="modal.notes = false"
    />
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import ModalEventReschedule from "@/views/crm/views/Lead/lead-event/ModalRescheduleEvent.vue";
import { mapGetters } from "vuex";
import ServiceLeads from "@/views/commons/components/lead-programs/service/lead.service.js";
import ModalNotesTax from "@/views/commons/components/first-notes/ModalNotesTax.vue";
import ModalNotesFirst from "@/views/commons/components/first-notes/ModalNotesFirst.vue";
import ModalNotesBoost from "@/views/commons/components/first-notes/ModalNotesBoost.vue";
import ModalNotesParagon from "@/views/commons/components/first-notes/ModalNotesParagon.vue";
import ModalNotesCredit from "@/views/commons/components/first-notes/ModalNotasCredit.vue";
import ModalNotesAll from "@/views/commons/components/first-notes/ModalNotesAll.vue";
import ModalNotesSpecialist from "@/views/commons/components/first-notes/ModalNotesSpecialist.vue";
import FilterSlot from "@/views/crm/views/sales-made/components/slots/FilterSlot.vue";
import { maxYearFilter } from "@/helpers/filters-table.js";
import ProgramIcon from "@/views/commons/utilities/ProgramIcon.vue";
import SalesMadeService from "@/views/commons/sales-made/sales-made.service.js";
export default {
  name: "NotSoldsModal",
  components: {
    BModal,
    ProgramIcon,
    ModalEventReschedule,
    ModalNotesBoost,
    ModalNotesParagon,
    ModalNotesFirst,
    ModalNotesTax,
    ModalNotesCredit,
    ModalNotesAll,
    ModalNotesSpecialist,
    FilterSlot,
  },
  props: {
    sellerId: {
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      perPageOptions: [10, 25, 50, 100],
      perPage: 50,
      totalUsers: 0,
      totalRows: 0,
      toPage: 0,
      startPage: 0,
      fields: [
        {
          key: "client",
          label: "Client",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "first_program",
          label: "OR",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "program",
          label: "DE",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "executives",
          label: "Executives",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "fee",
          label: "Fee",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "notes",
          label: "Notes",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "dates",
          label: "Annulled by",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
      years: [],
      maxYear: null,
      yearSelected: null,
      salesList: [],
      modalData: {
        notes: {},
      },
      modal: {
        notes: false,
      },
      programSelected: null,
      programList: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    programId() {
      return this.moduleId == 2 ? 0 : this.$route.matched[1].meta.program;
    },
  },
  async created() {
    this.generateYears();
    await this.getPrograms();
  },
  methods: {
    routerClient(item) {
      switch (this.moduleId) {
        case 2:
          return this.$route.meta.modulUrl
            ? `/${this.$route.meta.modulUrl}/leads/show/${item.lead_id}`
            : `/crm/leads/${item.lead_id}`;
        case 3:
          return {
            name: "bussiness-dashboard-clients",
            params: { idClient: item.client_account_id },
          };
        case 4:
          return `/administration/sales-made/report/${item.lead_id}`;
        case 5:
          return `/debtsolution/clients/account/${item.client_account_id}`;
        case 7:
          return `/boostcredit/clients/account/${item.client_account_id}`;
        case 8:
          return {
            name: "tax-research-client-dashboard",
            params: { idClient: item.client_account_id },
          };
        case 10:
          return {
            name: "claim-department-dashboard",
            params: { idClient: item.client_account_id },
          };
        case 12:
          return `/ti/clients/account/${item.client_account_id}`;
        case 14:
          return {
            name: "bookeeping-dashboard",
            params: { idClient: item.client_account_id },
          };
        case 18:
          return `/quality/clients/account/${item.client_account_id}`;
        case 20:
          return {
            name: "connection-clients-account",
            params: { idClient: item.client_account_id },
          };
        case 22:
          return this.routeToCustomerService(item.client_account_id);
        case 26:
          return { name: "sales-lead-show", params: { id: item.leadId } };
        case 29:
          return `/specialists/financial-approval/clients/account/${item.client_account_id}`;
        default:
          if (
            this.programId == 3 &&
            this.moduleId !== 26 &&
            this.moduleId !== 20
          ) {
            return `/creditexperts/clients/account/${item.client_account_id}`;
          }
          return null;
      }
    },
    async myProvider() {
      try {
        this.addPreloader();
        const resp = await SalesMadeService.getAnnulledSalesClients({
          destination_program: this.programSelected,
          actual_program: this.programId,
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          year: this.yearSelected,
          text: this.filterPrincipal.model,
          seller: this.sellerId,
        });
        this.startPage = resp.from;
        this.toPage = resp.to;
        this.totalRows = resp.total;
        this.paginate.currentPage = resp.current_page;
        this.paginate.perPage = resp.per_page;
        return resp.data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    hiddenModal() {
      this.$emit("close");
    },
    generateYears() {
      this.maxYear = maxYearFilter();
      this.yearSelected = this.maxYear;
      let years = [];

      // last pengind sales register are greater than 2019
      for (let i = this.maxYear; i >= 2019; i--) {
        years.push({
          value: i,
          label: i,
        });
      }
      this.years = years;
    },
    async getPrograms() {
      try {
        const { data } = await ServiceLeads.getAllPrograms();
        this.programList = data;
      } catch (error) {
        this.showErrorSwallow(error);
      }
    },
    async notesModal(data, index) {
      // this.addPreloader();

      this.modalData.notes.capturedName = data.captured;
      this.modalData.notes.sellerName = data.seller;
      this.modalData.notes.trackings = data.trackings; //
      this.modalData.notes.nameProgram = data.program;
      this.modalData.notes.nameClient = data.client;
      this.modalData.notes.statusSale = data.status;
      this.modalData.notes.sourcesName = data.sourcesname;
      this.modalData.notes.pack = data.pack;
      this.modalData.notes.originCountry = data.origin_country;
      this.modalData.notes.idLead = data.lead_id;
      this.modalData.notes.created = data.creates;
      this.modalData.notes.saleId = data.id;
      this.modalData.notes.module = this.currentUser.modul_id;
      this.modalData.notes.rolId = this.currentUser.role_id;
      this.modalData.notes.notes_status = data.notes_status;
      this.modalData.notes.notes_status_new = data.notes_status_new;
      this.modalData.notes.seller_id = data.seller_id;
      this.modalData.notes.haveRates = data.haveRates;
      this.modalData.notes.programId = data.program_id;
      this.modalData.notes.event_id = data.event_id;
      this.modalData.notes.editModal = false;
      this.modalData.notes.notSeller = true;
      this.openModalNotes(data.creates, data.program_id, index);
    },
    openModalNotes(created, program, index) {
      this.selectedIndex = index;
      switch (true) {
        case created >= "2020-05-28" && program == 1:
          this.modalData.notes.programSelected = "ModalNotesFirst"; // ready
          break;
        case created >= "2020-10-29" && program == 2:
          this.modalData.notes.programSelected = "ModalNotesBoost"; // ready
          break;
        case created >= "2021-03-04" && program == 3:
          this.modalData.notes.programSelected = "ModalNotesCredit"; // ready
          break;
        case created >= "2020-09-24" && program == 5:
          this.modalData.notes.programSelected = "ModalNotesTax"; // ready
          break;
        case created >= "2020-10-23" && program == 7:
          this.modalData.notes.programSelected = "ModalNotesSpecialist"; // ready
          break;
        case program == 9:
          this.modalData.notes.programSelected = "ModalNotesParagon"; // ready
          break;
        default:
          this.modalData.notes.programSelected = "ModalNotesAll";
          break;
      }
      this.modal.notes = true;
    },
  },
  mounted() {
    this.toggleModal("annulled-sales-clients-modal");
  },
};
</script>
