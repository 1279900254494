<template>
  <div class="position-relative w-100">
    <!--DAYS OF CALENDAR-->
    <div class="container-calendar d-block">
      <!--names-->
      <div class="grid-calendar border-bottom">
        <span class="name-day">sun</span>
        <span class="name-day">mon</span>
        <span class="name-day">tue</span>
        <span class="name-day">wed</span>
        <span class="name-day">thu</span>
        <span class="name-day">fri</span>
        <span class="name-day">sat</span>
      </div>
      <!--GRID CALENDAR-->
      <div
        class="grid-calendar"
      >
        <div
          v-for="(space, index) in spaceEmptyStart"
          :key="((index + 1) * -1)"
          class="number-day"
          style="background-color: transparent !important;pointer-events: none;"
        />
        <!--DAYS-->
        <div
          v-for="(item, index) in optionDates"
          :key="index"
          class="position-relative number-day"
          :class="[
            'position-relative',
            'number-day',
            {
              'hasSchedule-dark': isDarkSkin && isDayInContainerHours(item.day),
              'hasSchedule-light': !isDarkSkin && isDayInContainerHours(item.day)
            },
            {
              'day-selected': item.selected,
              'highlight': item.highlight,
              'day-primary': variant === 'primary',
              'day-warning': variant === 'warning',
              'day-info': variant === 'info',
            }
          ]"
          :style="{
            'pointer-events': item.disabled ? 'none' : 'auto',
            'color': item.disabled ? '#82868B' : '',
            'background-color': item.hasSchedule ? '#7cbdf0 !important; color:black !important' : '',
          }"
          @click="selectedDays(item.day)"
        >
          <span>{{ item.day }}</span>

          <div v-if="item.notification && item.notification > 0">
            <span class="pending-meetings-count">{{ item.notification }}</span>
          </div>
          <div
            v-else-if="item.meetings && countMeetingsPerday(JSON.parse(item.meetings)) > 0"
            class="pending-meetings-count"
          >
            <span class="text-bold"> {{ countMeetingsPerday(JSON.parse(item.meetings)) }}</span>
          </div>
          <small
            v-if="item.date && item.date === todayDate"
            class="indicator"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  props: {
    month: {
      type: Number,
      default: parseInt(moment().format('MM'), 10),
      required: true,
    },
    year: {
      type: Number,
      default: parseInt(moment().format('YYYY'), 10),
      required: true,
    },
    hoursAvailables: {
      type: Array,
      default: () => [],
      required: false,
    },
    disabledOldDays: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    showToday: {
      type: Boolean,
      default: false,
    },
    containerHours: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      currentMonth: parseInt(moment().format('MM'), 10),
      currentYear: parseInt(moment().format('YYYY'), 10),
      today: parseInt(moment().format('D'), 10),
      todayDate: moment().format('YYYY-MM-DD'),
      optionDates: [],
      optionsSelectedDays: [], // DAYS selected
    };
  },
  computed: {
    spaceEmptyStart() {
      return parseInt(
        moment(`${this.year}-${this.month}`, 'YYYY-MM').startOf('month').day(),
        10,
      );
    },
  },
  mounted() {
    if (this.hoursAvailables.length > 0) {
      this.optionDates = this.hoursAvailables.map(item => ({
        ...item,
        selected: item.selected || false,
        highlight: item.highlight || false,
        disabled: item.disabled || false,
      }));
    } else {
      this.generateDates();
    }
    // Encontrar el objeto correspondiente al día actual en optionDates
    const todaySelected = this.optionDates.find(day => day.date === this.todayDate);

    if (todaySelected && todaySelected.selected) {
      // Simular clic en el día actual seleccionado
      this.selectedDays(todaySelected.day);
    }
  },
  methods: {
    hasValidMeetings(meetings) {
      return meetings && this.safelyParseJSON(meetings) && this.countMeetingsPerday(meetings) > 0;
    },

    safelyParseJSON(jsonString) {
      try {
        return JSON.parse(jsonString);
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return null;
      }
    },
    countMeetingsPerday(meetings) {
      let meetingsCount = null;
      let correlative = null;
      if (meetings) {
        meetings.forEach(meeting => {
          if (meeting.nro_correlative) {
            if (meeting.nro_correlative !== correlative && [1, 2].includes(meeting.status_id)) {
              meetingsCount += 1;
            }
            correlative = meeting.nro_correlative;
          } else if ([1, 2].includes(meeting.status_id)) {
            meetingsCount += 1;
          }
        });
      }
      return meetingsCount;

      // Ahora tienes un objeto con el número total de reuniones únicas por día
    },

    isDayInContainerHours(day) {
    // Verifica si el día está presente en containerHours
      return this.containerHours.some(item => item.day === day.toString());
    },

    generateDates() {
      const daysInMonth = moment(`${this.year}-${this.month}`, 'YYYY-MM').daysInMonth();
      const dates = [];
      for (let i = 1; i <= daysInMonth; i += 1) {
        const dateMy = moment(`${this.year}-${this.month}-${i}`, 'YYYY-MM-DD').format('YYYY-MM-DD');
        const isHasSchedule = this.containerHours.some(hour => {
          const day = moment(hour.date).format('D');
          return parseInt(day, 10) === i;
        });
        dates.push({
          day: i,
          date: dateMy,
          selected: this.showToday ? dateMy === this.todayDate : false,
          disabled: false,
          highlight: false,
          hasSchedule: isHasSchedule,
          notification: null,
        });
      }
      this.optionDates = dates;
    },
    selectedDays(day) {
      if (this.optionsSelectedDays.includes(day)) {
        // remove day selected
        this.optionsSelectedDays = this.optionsSelectedDays.filter(item => item !== day);
      } else if (this.multiple) {
        // add multiple day selected
        this.optionsSelectedDays.push(day);
      } else {
        // add single day selected
        this.optionsSelectedDays = [day];
      }

      this.optionDates.forEach(item => {
        if (this.optionsSelectedDays.includes(item.day)) {
          item.selected = true;
        } else {
          item.selected = false;
        }
      });

      // return objects days selected
      const returnDays = this.optionsSelectedDays.map(itemDay => {
        const findDay = this.optionDates.find(dayItem => dayItem.day === itemDay);
        return {
          ...findDay,
          selected: true,
        };
      });
      this.$emit('selectedDays', returnDays);
    },
  },
};
</script>
<style scoped>
*{
  --custom-blue: #2591E7;
  --custom-blue-hover: rgba(68, 155, 221, 0.741);
  --custom-warning: #FFC107;
  --custom-warning-hover: rgba(255, 193, 7, 0.741);
  --custom-info: #8f5fe8;
  --custom-info-hover: rgba(143, 95, 232, 0.741);
}
.grid-calendar {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0px;
  margin: 0px auto;
  position: relative;
}

.grid-calendar .name-day,
.grid-calendar .number-day,
.grid-calendar .number-day-dark-skin {
  text-align: center;
  font-size: 10px;
  padding: 10px 0px;
  /* border-bottom: 1px solid #ccc; */
  text-transform: uppercase;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.grid-calendar .name-day {
  font-weight: 600;
}

.grid-calendar .number-day,
.grid-calendar .number-day-dark-skin {
  cursor: pointer;
  margin: 2px;
  border-radius: 5px !important;
}

.grid-calendar .number-day:hover,
.grid-calendar .number-day-dark-skin:hover {
  background-color: var(--custom-blue-hover);
  /* border-radius: 5px; */
}
/*
.grid-calendar .number-day span,
.grid-calendar .number-day-dark-skin span {
  transition: 0.3s ease-in-out all;
} */

.grid-calendar .number-day:hover span,
.grid-calendar .number-day-dark-skin:hover span {
  cursor: pointer;
  font-size: 15px;
  transform: traslateY(-5px);
  color: white;
}

.grid-calendar .number-day.day-primary:hover,{
  background-color: var(--custom-blue) !important;
}
.grid-calendar .number-day.day-warning:hover{
  background-color: var(--custom-warning) !important;
}
.grid-calendar .number-day.day-info:hover{
  background-color: var(--custom-info) !important;
}
.day-selected{
  background-color: var(--custom-blue) !important;
  color: white !important;
}
.day-selected.day-primary{
  background-color: var(--custom-blue) !important;
}
.day-selected.day-warning{
  background-color: var(--custom-warning) !important;
}
.day-selected.day-info{
  background-color: var(--custom-info) !important;
}
.day-selected span{
  font-size: 15px;
}
.day-selected .indicator{
  background-color: white !important;
}

.indicator,
.indicator-dark {
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  bottom: 3px;
  transition: 0.3s ease-in-out all;
}
.indicator{
  background-color: currentColor;
}
.grid-calendar .number-day:hover .indicator,
.grid-calendar .number-day-dark-skin:hover .indicator {
  background-color: white;
}
.day-primary.highlight{
  border-radius: 5px !important;
  border: 1px solid #2591E7;
  color: #2591E7;
}
.day-warning.highlight{
  border-radius: 5px !important;
  border: 1px solid #FFC107;
  color: #FFC107;
}
.day-info.highlight{
  border-radius: 5px !important;
  border: 1px solid #8f5fe8;
  color: #8f5fe8;
}
.hasSchedule-light {
  background-color: #d3ecfd;
}
.hasSchedule-dark {
  background-color: #202022;
}.pending-meetings-count{
  position:absolute;
  width:22px;
  height:21px;
  border-radius:50%;
  background:red;
  top:-0.5em;
  right:-0.5em;
  color:white;
}
</style>
