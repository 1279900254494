import Vue from 'vue'
import Vuex from 'vuex'

import NcrRealtorStore from '@/views/specialists/views/ncr-realtor/store/index'
import DirectRound from '@/views/specialists/views/direct-round/store/index'
import ZeroPaymentStore from '@/views/specialists/views/zero-payment/store/index'
import DirectRoundAnalystDepartament from '@/views/specialists/sub-modules/analyst-department/views/direct-round/store/index';
Vue.use(Vuex)
export default {
    SpecialistNcrRealtorStore:NcrRealtorStore,
    SpecialistDirectRoundStore:DirectRound,
    AnalystDepartamentDirectRoundStore:DirectRoundAnalystDepartament,
    SpecialistZeroPaymentStore:ZeroPaymentStore
}
