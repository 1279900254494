<template>
  <div>
    <template v-if="isDone">
      <b-tabs
        v-model="activeTab"
        pills
        content-class="mt-3"
        nav-class="mb-0 tab-done-crm mt-1 ml-2 mb-0"
        active-nav-item-class="bg-info box-shadow-info border-info info"
        card-header
        @input="handelClick"
      >
        <b-tab
          v-for="(item, index) in programs"
          :key="index"
          :title-link-class="[bgTabsNavs, 'sub-tab px-3 h-full']"
        >
          <template #title>
            <div
              v-b-tooltip.hover.bottom="item.alias"
              v-b-tooltip.hover.v-primary
              style="padding: 5px 30px"
            >
              <template v-if="item.icons">
                <img
                  :src="`/assets/${item.icons}`"
                  alt="Logo"
                  height="30"
                  width="30"
                />
              </template>
              <template v-else>
                {{ item.alias }}
              </template>
            </div>
          </template>
          <slot name="content" v-if="activeTab === index" />
        </b-tab>
      </b-tabs>
    </template>
    <template v-else>
      <slot name="content" />
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ProgramsCRMDone",
  props: {
    isDone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: null,
    };
  },
  computed: {
    ...mapGetters({
      programs: "CrmSaleMadeStore/G_PROGRAMS_CRM_DONE",
    }),
  },
  created() {},
  methods: {
    handelClick() {
      if (this.activeTab == null) return;
      this.$emit("changeTab", this.programs[this.activeTab]);
    },
  },
};
</script>
<style>
.tab-done-crm .nav-link {
  padding: 0 !important;
}
</style>