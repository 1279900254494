import { amgApi } from "@/service/axios"

class ZeroPaymentService {
  async getCeDigitalZeroPayment(parameters) {
    /*
            Recibe los siguientes parametros
            name_text: contenido del input para busqueda
            date_from: fecha de origen para filtrar
            date_to: fecha de fin para filtrar
            zp_status: id del estado del zero payment
            perpage: cantidad de items por pagina
            npage: cantidad de paginas
        */
    try {
      let data = await amgApi.post(
        "credit-experts-digital/financial/get-ce-digital-zero-payment",
        parameters
      )
      return data
    } catch (error) {
      console.error(error)
    }
  }
  async getZeroPaymentFinancialCounter() {
    /*
            Recibe los siguientes parametros
            name_text: contenido del input para busqueda
            date_from: fecha de origen para filtrar
            date_to: fecha de fin para filtrar
            zp_status: id del estado del zero payment
            perpage: cantidad de items por pagina
            npage: cantidad
        */
    try {
      let data = await amgApi.get(
        "credit-experts-digital/financial/get-zero-payment-counter-financial"
      )
      return data
    } catch (error) {
      console.error(error)
    }
  }
}
export default new ZeroPaymentService()
