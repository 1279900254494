<template>
  <validation-observer ref="form">
    <b-modal
      v-model="modal.revission"
      :title="revission.reject ? 'Disapprove sale' : title"
      title-class="h3 text-white font-weight-bolder"
      size="lg"
      no-close-on-backdrop
      modal-class="modal-primary"
      :header-bg-variant="revission.reject || [3,14].includes(revission.type) ? 'danger' : 'primary'"
    >
      <b-container fluid>
        <program-client-header
          :client="revission.nameClient"
          :program="revission.nameProgram"
          class="sticky-top mt-1"
        />
        <!-- Select Advisor -->
        <!-- (moduleId == 4 || moduleId == 5 || moduleId == 16) && -->
        <b-row v-if="revission.type == 4 && !revission.reject">
          <b-col sm="6">
            <validation-provider
              v-slot="{ errors }"
              name="selectAdvisor"
              :rules="revission.client_type_id == 1 ? 'required' : ''"
            >
              <!-- :rules="revission.client_type_id === 1 ? 'required' : ''" -->
              <b-form-group label="Advisor" class="mt-1">
                <v-select
                  v-model="advisorId"
                  label="user_name"
                  :disabled="revission.client_type_id === 2"
                  :options="advisor"
                  :reduce="(val) => val.id"
                  transition=""
                  :class="{
                    'border-danger': errors[0],
                  }"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col sm="6" v-if="moduleId == 4">
            <b-form-group label="Client Type">
              <b-badge variant="primary" v-if="revission.client_type_id == 1"
                >REGULAR</b-badge
              >
              <b-badge variant="success" v-else>DIGITAL</b-badge>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <validation-provider
            v-slot="{ errors }"
            name="typeOfAgreement"
            :rules="revission.reject || [3,14].includes(revission.type) ? 'required' : ''"
            class="w-100 px-1"
          >
            <!-- <h3 class="mt-2">Observation</h3> -->
            <b-form-group label="Observation" class="mt-1">
              <b-form-textarea
                id="textarea-1"
                v-model="observation"
                placeholder="Enter observation..."
                rows="5"
                max-rows="6"
                :class="{ 'border-danger': errors[0] }"
              />
            </b-form-group>
          </validation-provider>
        </b-row>
        <b-row v-if="revission.type === 4 && !revission.reject" class="mt-1">
          <b-form-checkbox v-model="sendSms" switch> Send SMS </b-form-checkbox>
        </b-row>
      </b-container>
      <template #modal-footer>
        <b-button
          :disabled="disabledButton"
          :variant="
            revission.reject || [3,14].includes(revission.type)
              ? 'outline-danger'
              : 'outline-success'
          "
          @click="revision"
          >{{ revission.reject || [3,14].includes(revission.type) ? "DISAPPROVE" : buttonText }}</b-button
        >
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import vSelect from "vue-select";
import ProgramClientHeader from "@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue";
import DerivationsService from "@/views/ce-digital/sub-modules/sales/views/derivations/derivations.service";
import { amgApi } from "@/service/axios";
import SalesMadeService from "@/views/crm/views/sales-made/services/sales-made.service";
import { mapGetters, mapActions } from "vuex";
import { salesMadeCounter } from "@/views/commons/commons-counter.js";
import SalesMadeService2 from "@/views/commons/components/sales-made/add-sale/service/sales-made-service.js";
export default {
  name: "RevissionModal",
  components: { ProgramClientHeader, vSelect },
  props: {
    modal: {
      required: true,
      type: Object,
    },
    revission: {
      required: true,
      type: Object,
    },
    toProgramId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      advisor: [],
      advisorId: null,
      sendSms: false,
      observation: "",
      disabledButton:
        this.revission.type === 4 && this.revission.initialPayment == 1,
    };
  },
  computed: {
    programId() {
      return this.$route.matched[1].meta.program;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_SIDEBAR_ITEMS: "SidebarStore/G_SIDEBAR_ITEMS",
    }),
    title() {
      // if (this.revission.type === 2 && this.revission.idProgram === 4 && this.moduleId != 26)
      //   return "Send to DS supervisor for revision";
      // if (this.revission.type === 5 && this.revission.idProgram === 4 && this.moduleId != 26)
      //   return "Send to CRM supervisor for revision";
      // if (this.revission.type === 2) return "Send to revission";
      // if (this.revission.type === 3) return "Disapprove";
      // if (this.revission.type === 4 || this.revission.type === 11) return "Approve";
      // if (this.revission.type === 5) return "Send to supervisor";
      // if (this.revission.type === 6) return "Send to ceo";
      // if (this.revission.type === 7) return "Return";
      switch (this.revission.type) {
        case 2:
          return "Send to Chief";
        case 3:
          return "Send to revision";
        case 4:
          return "Approve Sale";
        case 12:
          return "Send to Program";
        case 14:
          return "Return to Origin Program";
      }
      return "Send to revision";
    },
    buttonText() {
      switch (this.revission.type) {
        case 2:
          return "Send";
        case 3:
          return "Disapprove";
        case 4:
          return "Approve";
        case 12:
          return "Send";
        case 14:
          return "Disapprove";
      }
      return "Send";
    },
    flexCenter: function () {
      return "d-flex justify-content-center align-items-center";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    S_COUNTER_DISAPPROVED_SALE: {
      get() {
        return this.$store.state["SellerPermissionsStore"]
          .S_COUNTER_DISAPPROVED_SALE;
      },
      set(value) {
        this.$store.commit(
          "SellerPermissionsStore/M_COUNTER_DISAPPROVED_SALE",
          value
        );
      },
    },
  },
  async created() {
    this.listAdvisor();
  },
  methods: {
    ...mapActions({
      A_GET_ADDCHANGE_COUNTERS: "CrmSaleMadeStore/A_GET_ADDCHANGE_COUNTERS",
      A_GET_LEAD_SALE_COUNTERS: "CrmSaleMadeStore/A_GET_LEAD_SALE_COUNTERS",
    }),
    getMessageSuccess() {
      if (this.toProgramId != 4) {
        return "The sale has been sent to administration";
      }
      if (
        this.toProgramId == 4 &&
        this.revission.type == 2 &&
        this.moduleId === 26
      ) {
        return "The sale has been approved successfully";
      }
      if (this.toProgramId == 4 && this.revission.type == 7) {
        return "The sale has been return to seller";
      }
      if (this.toProgramId == 4 && this.revission.type == 5) {
        return "The sale has been sent to CRM supervisor for revision";
      }
      if (this.toProgramId == 4 && this.revission.type == 2) {
        return "The sale has been sent to DS supervisor for revision";
      }
    },
    async listAdvisor() {
      /* Get all advisor for module */
      try {
        if (this.revission.idProgram == 1) module = 3;
        else if (this.revission.idProgram == 2) module = 7;
        else if (this.revission.idProgram == 3) module = 6;
        else if (this.revission.idProgram == 4) module = 5;
        else if (this.revission.idProgram == 5) module = 8;
        else if (this.revission.idProgram == 6) module = 10;
        else if (this.revission.idProgram == 7) module = 11;
        else if (this.revission.idProgram == 8) module = 14;
        else module = 12;
        const response = await amgApi.post(`/commons/user-modulep/${module}`, {
          // eslint-disable-next-line no-nested-ternary
          roles: module === 12 ? "[8]" : module === 11 ? "[1,2,8]" : "[1,2,3]",
          type: "1",
        });
        if (response.status === 200) this.advisor = response.data;
        if (this.revission.type === 2) this.sendSms = true;
      } catch (error) {
        console.error(error);
      }
    },
    async insertTrackingDerivation(status) {
      if (
        this.moduleId === 26 ||
        ((this.moduleId === 4 || this.moduleId === 5) &&
          this.revission.comes_from === 26)
      ) {
        const params = {
          lead_id: this.revission.leadId,
          sale_status_id: status,
          seller_id: this.currentUser.user_id,
          sale_id: this.revission.idsales,
        };
        await DerivationsService.insertTrackingSaleStatusDerivation(params);
      }
    },
    async revision() {
      const response = await this.$refs.form.validate();
      if (!response) return;
      if (this.revission.reject) {
        await this.disapproveSaleMadeLeads();
        return;
      } else if (this.revission.type !== 4) {
        await this.send();
      } else {
        await this.sendAutorize();
      }
      await salesMadeCounter();
    },
    async disapproveSaleMadeLeads() {
      try {
        this.addPreloader();
        const params = {
          status: this.assignStatusDisapprove(this.revission),
          user_id: this.currentUser.user_id,
          sale_id: this.revission.idsales,
          comments: this.observation,
          status_tracking: this.setStatusTracking(this.revission),
        };
        await SalesMadeService2.disapproveSaleMadeLeads(params);
        this.$emit("refreshTable");
        this.$emit("close");
        this.showToast("success", "top-right", "Success!", "CheckIcon");
        await salesMadeCounter();
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    assignStatusDisapprove(item) {
      const statusSale = {
        2: 3,
        13: item.typeSale == 0 ? 15 : 16,
        12: 14,
      };
      return statusSale[item.status] || "Error";
    },
    setStatusTracking(item) {
      const statusSale = {
        2: 3,
        13: item.typeSale == 0 ? 10 : 11,
        12: 9,
      };
      return statusSale[item.status] || "Error";
    },
    async send() {
      try {
        this.disabledButton = true;
        this.$store.commit("app/SET_LOADING", true);
        const params = {
          sale_id: this.revission.idsales,
          state: this.revission.type,
          observation: this.observation,
          user_id: this.revission.user_id,
          client: this.revission.nameClient,
          advisor_id: this.advisorId,
          namesession: this.revission.sessioName,
          leadid: this.revission.leadId,
          program: this.revission.nameProgram,
          programid: this.revission.idProgram,
          firstprogram: null,
          firstacccout: null,
          firstprogramname: null,
          sendSms: this.sendSms,
          modul: this.revission.modul,
          sellerName: this.revission.sellerName,
          language: this.revission.language,
          last_name: this.revission.last_name,
          client_account_id: this.revission.account,
          module: this.currentUser.modul_id,
          role: this.currentUser.role_id,
        };
        const response = await SalesMadeService.send(params);

        if (response.status === 200) {
          if (this.revission.type === 2 || this.revission.type === 6) {
            await this.insertTrackingDerivation(6);
          }
          if (this.revission.type === 5) {
            await this.insertTrackingDerivation(5);
          }

          if (this.revission.type == 5) {
            this.$emit("removeFromTable", this.revission.idsales);
          }
          this.$emit("refreshTable");
          this.$emit("response", true);
          this.$emit("click", false);
          this.showSuccessSwal(
            "Success"
            // this.getMessageSuccess()
            // lgmd this.toProgramId == 4 ? "The sale has been derived to Debt Solutions" : "The sale has been sent to administration"
          );
        }
        this.$store.commit("app/SET_LOADING", false);
      } catch (error) {
        this.disabledButton = false;
        this.showToast("danger", "top-right", "Error", "XIcon", error);
        this.$store.commit("app/SET_LOADING", false);
      }
    },
    async sendAutorize() {
      try {
        this.disabledButton = true;
        this.$store.commit("app/SET_LOADING", true);
        const params = {
          account: this.revission.account,
          advisor_id: this.advisorId,
          client: this.revission.nameClient,
          date: "",
          equifax: "",
          experian: "",
          image: "",
          leadid: this.revission.leadId,
          namefile: "",
          namesession: this.revission.sessioName,
          observation: this.observation,
          program: this.revission.nameProgram,
          programid: this.revission.idProgram,
          provider: "",
          role_id: this.currentUser.role_id,
          sale_id: this.revission.idsales,
          size: "",
          state: this.revission.type, //
          transunion: "",
          user_id: this.revission.user_id,
          firstprogram: this.revission.first_program_id,
          firstacccout: this.revission.account_id,
          firstprogramname: this.revission.first_program,
          type: this.revission.sale_type,
          moduleAction: this.moduleId, // MG 16
        };

        let response;

        if (this.revission?.sale_type == 1 || this.revission?.sale_type == 2) {
          response = await SalesMadeService.sendAutorizeProgram(params);
        } else {
          response = await SalesMadeService.sendAutorize(params);
        }
        if (this.moduleId == 16) {
          this.S_COUNTER_DISAPPROVED_SALE--;
        }
        if (response.status === 200) {
          const params2 = {
            sale_id: this.revission.idsales,
            type: this.revission.client_type_id,
          };
          await SalesMadeService.insertClientType(params2);
          await this.insertTrackingDerivation(8);

          this.$emit("response", true);
          this.$emit("click", false);
          this.$store.commit("app/SET_LOADING", false);
          this.showToast("success", "top-right", "Success!", "CheckIcon");
        }
      } catch (error) {
        this.disabledButton = false;
        this.showToast("danger", "top-right", "Error", "XIcon", error);
        this.$store.commit("app/SET_LOADING", false);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
