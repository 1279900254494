var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"xmd","header-class":"p-0","header-bg-variant":"transparent","scrollable":"","modal-class":"custom-modal-amg"},on:{"hide":function($event){return _vm.hideModal(false)}},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('HeaderModalNotes',{attrs:{"program":"Specialist","info":_vm.noteInfo},on:{"close":function($event){return _vm.hideModal(false)}}})]},proxy:true},{key:"modal-footer",fn:function(){return [(_vm.newNote)?[(_vm.showButtonSave)?_c('button-save',{on:{"click":_vm.saveNotesIncomplete}}):_vm._e(),(_vm.showButtonSave)?_c('button-save-and-complete',{on:{"click":_vm.saveNotesCompleted}}):_vm._e(),((_vm.showNewButtonUpdate || _vm.showNewButtonUpdateAdmin) && _vm.noteInfo.editModal)?_c('button-update',{on:{"click":_vm.updateNotesCompleted}}):_vm._e()]:[(_vm.showButtonSave)?_c('button-save',{on:{"click":_vm.saveNotesIncomplete}}):_vm._e(),(_vm.showButtonUpdate && _vm.noteInfo.editModal)?_c('button-update',{on:{"click":_vm.updateNotesCompleted}}):_vm._e()]]},proxy:true}]),model:{value:(_vm.modalUp),callback:function ($$v) {_vm.modalUp=$$v},expression:"modalUp"}},[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Type of Services","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"typeOfServices","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{class:{ 'border-danger rounded': errors[0] },attrs:{"options":_vm.note.typeOfServices.options,"disabled":_vm.disabled},on:{"change":_vm.changeTypeService},model:{value:(_vm.note.typeOfServices.value),callback:function ($$v) {_vm.$set(_vm.note.typeOfServices, "value", $$v)},expression:"note.typeOfServices.value"}})]}}])})],1)],1),(_vm.dateTypeAgreement)?_c('b-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"typeOfAgreement","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Type of Agreement","label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.note.typeOfAgreement.options},model:{value:(_vm.note.typeOfAgreement.value),callback:function ($$v) {_vm.$set(_vm.note.typeOfAgreement, "value", $$v)},expression:"note.typeOfAgreement.value"}})],1)]}}],null,false,2352462322)})],1):_vm._e()],1),(_vm.note.typeOfServices.value === '1')?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label-class":"font-weight-bolder","label":"Previous ID Numbers"}},[_c('validation-provider',{attrs:{"name":"previousIdNumbers","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.note.previousIdNumbers.options},model:{value:(_vm.note.previousIdNumbers.value),callback:function ($$v) {_vm.$set(_vm.note.previousIdNumbers, "value", $$v)},expression:"note.previousIdNumbers.value"}})]}}],null,false,1589242268)})],1),_c('b-form-group',{attrs:{"label":"Time","label-class":"font-weight-bolder","label-cols":"2"}},[_c('validation-provider',{attrs:{"name":"previousIdNumbersTime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-75",class:{ 'border-danger rounded': errors[0] },attrs:{"size":"sm"},model:{value:(_vm.note.previousIdNumbers.time),callback:function ($$v) {_vm.$set(_vm.note.previousIdNumbers, "time", $$v)},expression:"note.previousIdNumbers.time"}})]}}],null,false,4037115486)})],1)],1),_c('b-col',{staticClass:"font-weight-bolder d-flex align-items-center",attrs:{"cols":"2"}},[_vm._v(" TO ")]),_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label-class":"font-weight-bolder","label":"Current ID Numbers"}},[_c('validation-provider',{attrs:{"name":"currentIdNumbers","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.note.currentIdNumbers.options},model:{value:(_vm.note.currentIdNumbers.value),callback:function ($$v) {_vm.$set(_vm.note.currentIdNumbers, "value", $$v)},expression:"note.currentIdNumbers.value"}})]}}],null,false,1589850172)})],1),_c('b-form-group',{attrs:{"label":"Time","label-class":"font-weight-bolder","label-cols":"2"}},[_c('validation-provider',{attrs:{"name":"currentIdNumbersTime","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-75",class:{ 'border-danger rounded': errors[0] },attrs:{"size":"sm"},model:{value:(_vm.note.currentIdNumbers.time),callback:function ($$v) {_vm.$set(_vm.note.currentIdNumbers, "time", $$v)},expression:"note.currentIdNumbers.time"}})]}}],null,false,3019776774)})],1)],1)],1):(_vm.note.typeOfServices.value === '2')?_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"ID Type Number","label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"idTypeNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.idTypeNumber.options},model:{value:(_vm.note.idTypeNumber.value),callback:function ($$v) {_vm.$set(_vm.note.idTypeNumber, "value", $$v)},expression:"note.idTypeNumber.value"}})]}}])})],1)],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"studentLoanType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Student Loan Type","label-class":"font-weight-bolder"}},[_c('b-form-radio-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.studentLoanType.options},model:{value:(_vm.note.studentLoanType.value),callback:function ($$v) {_vm.$set(_vm.note.studentLoanType, "value", $$v)},expression:"note.studentLoanType.value"}})],1)]}}])})],1),_c('b-col',[_c('b-form-group',{attrs:{"label-class":"font-weight-bolder","label":"Amount Owed"}},[_c('validation-provider',{attrs:{"name":"amountOwed","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control form-control-sm",class:{ 'border-danger': errors[0] },attrs:{"size":"sm"},model:{value:(_vm.note.amountOwed.value),callback:function ($$v) {_vm.$set(_vm.note.amountOwed, "value", $$v)},expression:"note.amountOwed.value"}},'money',{ precision: 2, prefix: '$  ' },false))]}}])})],1)],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"5"}},[_c('b-form-group',{attrs:{"label":"Origin Country","label-class":"font-weight-bolder"}},[_c('v-select',{attrs:{"clearable":false,"disabled":_vm.disabled,"label":"name","reduce":function (value) { return value.id; },"options":_vm.note.country.options},model:{value:(_vm.note.country.value),callback:function ($$v) {_vm.$set(_vm.note.country, "value", $$v)},expression:"note.country.value"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"pending","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pending","label-class":"font-weight-bolder"}},[(_vm.isAfterLastDeploy)?_c('b-form-checkbox-group',{class:{ 'border-danger': errors[0] },attrs:{"options":_vm.note.pending.options},model:{value:(_vm.note.pending.values),callback:function ($$v) {_vm.$set(_vm.note.pending, "values", $$v)},expression:"note.pending.values"}}):_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.pending.value),callback:function ($$v) {_vm.$set(_vm.note.pending, "value", $$v)},expression:"note.pending.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"inconvenience","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Inconvenience","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.inconvenience.value),callback:function ($$v) {_vm.$set(_vm.note.inconvenience, "value", $$v)},expression:"note.inconvenience.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"information","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Information","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.information.value),callback:function ($$v) {_vm.$set(_vm.note.information, "value", $$v)},expression:"note.information.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"recommendations","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Recommendations","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.recommendations.value),callback:function ($$v) {_vm.$set(_vm.note.recommendations, "value", $$v)},expression:"note.recommendations.value"}})],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"goal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Goal","label-class":"font-weight-bolder"}},[_c('quill-editor',{class:{ 'border-danger rounded': errors[0] },attrs:{"disabled":_vm.disabled,"options":_vm.editorOption},model:{value:(_vm.note.goal.value),callback:function ($$v) {_vm.$set(_vm.note.goal, "value", $$v)},expression:"note.goal.value"}})],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }