export default [
  {
    // 0
    key: "priority",
    label: "priority",
    visible: true,
  },
  {
    // 0
    key: "code",
    label: "Code",
    visible: true,
  },
  {
    // 1
    key: "name_client",
    label: "Client",
    visible: true,
  },
  {
    // 1
    key: "detail_expense",
    label: "transaction details",
    visible: false,
    tdClass: "p-0"
  },
  {
    // 2
    key: "motive",
    label: "Motive",
    visible: true,
    thClass: "text-center",
  },
  {
    // 3
    key: "status",
    label: "Status",
    visible: true,
    thClass: "text-center",
  },
  {
    key: "last_payment",
    label: "LP",
    thClass: "text-center",
    tdClass: "text-center",
    visible: false,
  },
  {
    // 4
    key: "created_at",
    label: "Created By",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    // 5
    key: "updated_at",
    label: "Updated At",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    // 6
    key: "participants",
    label: "Participants",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    // 7
    key: "chat",
    label: "chat",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    // 8
    key: "meetings",
    label: "meetings",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    // 9
    key: "task",
    label: "task",
    thClass: "text-center",
    tdClass: "text-center",
    visible: false,
  },
  {
    // 10
    key: "actions",
    label: "CLOSE",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "client_ceo",
    label: "client ceo",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
  {
    key: "tracking",
    label: "tracking",
    thClass: "text-center",
    tdClass: "text-center",
    visible: true,
  },
];
