export default [
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "From",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
    },
    {
        type: "datepicker",
        margin: true,
        showLabel: true,
        label: "To",
        placeholder: "Date",
        class: "font-small-3",
        model: null,
        locale: "en",
        dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        cols: 6,
    },
    {
        type: "select",
        margin: true,
        placeholder: '--Select--',
        showLabel: true,
        label: "Type",
        model: null,
        options: [],
        reduce: "id",
        selectText: "value",
        cols: 12,
        visible: true
      },
      {
        type: "select",
        margin: true,
        showLabel: true,
        placeholder: '--Select-',
        label: "Select Status",
        model: null,
        options: [],
        reduce: "id",
        selectText: "value",
        cols: 12,
        visible: true
      },
      {
        type: "select",
        margin: true,
        placeholder: '--Select--',
        showLabel: true,
        label: "Method",
        model: null,
        options: [],
        reduce: "id",
        selectText: "value",
        cols: 12,
        visible: true
      },
];