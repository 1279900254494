<template>
  <div>
    <b-tabs
      active-tab-class="p-0 "
      pills
      nav-class="mb-0 mt-1"
      active-nav-item-class="bg-info box-shadow-info border-info info"
    >
      <b-tab
        v-for="(title, index) in ['PENDING', 'CONFIRMED', 'REJECTED']"
        :key="index"
        :title="title"
        :active="index === 0"
        @click="changeTab(index + 1)"
        :title-link-class="[bgTabsNavs, 'sub-tab px-3']"
      >
        <template #title>
          {{ title }}
          <span class="ml-1">
            <feather-icon
              v-if="getCounterByTabTitle(index) > 0"
              icon
              :badge="getCounterByTabTitle(index)"
              badge-classes="badge-danger"
            />
          </span>
        </template>
      </b-tab>
      <div>
        <div class="d-flex justify-content-end">
          <b-button
            v-if="showCreateButton"
            @click="showAddOrEditExpenseModal = true"
            variant="primary"
          >
            Add expense
          </b-button>
        </div>
        <credit-card-expenses-table :tab="tab" :caId="$route.params.idClient" />
      </div>
    </b-tabs>

    <add-or-edit-expense-modal
      v-if="showAddOrEditExpenseModal"
      :account_number="client.account"
      :programIdClient="client.program_id"
      :tab="'CREDIT_CARD'"
      @close="showAddOrEditExpenseModal = false"
      :hasCreditCard="true"
    />
  </div>
</template>
<script>
import CreditCardExpensesTable from "@/views/commons/components/clients/dashboard/options/pay-module/components/CreditCardExpensesTable.vue";
import AddOrEditExpenseModal from "@/views/commons/expenses/components/Modal/AddOrEditExpense.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    CreditCardExpensesTable,
    AddOrEditExpenseModal
  },
  props: {
    showCreateButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tab: 1,
      showAddOrEditExpenseModal: false,
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      total_expenses: "ExpensesStore/G_TOTAL_EXPENSES_CLIENT",
      pending_expenses: "ExpensesStore/G_PENDING_EXPENSES_CLIENT",
      confirmed_expenses: "ExpensesStore/G_CONFIRMED_EXPENSES_CLIENT",
    }),
  },
  methods: {
    async changeTab(tab) {
      this.tab = tab;
    },
    getCounterByTabTitle(index) {
      const counters = {
        1: this.pending_expenses,
        2: this.confirmed_expenses,
      };

      return counters[index + 1] || 0;
    },
  },
};
</script>