<template>
  <div class="timeline-payment-schedule">
    <b-row class="m-0 mb-3">
      <b-col cols="12" md="6">
        <span> CLIENT </span>
        <div class="border border-primary rounded">
          <p
            class="text-primary d-flex align-items-center justify-content-center my-1s"
            style="gap: 5px"
          >
            {{ timelineEvents[0].lead_name }}
          </p>
        </div>
      </b-col>
      <b-col cols="12" md="6">
        <span>ACCOUNT</span>
        <div class="border border-primary rounded">
          <p
            class="text-primary d-flex align-items-center justify-content-center my-1s"
            style="gap: 5px"
          >
            {{ timelineEvents[0].account }}
          </p>
        </div>
      </b-col>
    </b-row>
    <div class="container mb-2">
      <b-row>
        <b-col>
          <div class="timeline-steps">
            <div
              class="timeline-step mb-2"
              v-for="(event, index) in timelineEvents"
              :key="index"
            >
              <div class="timeline-content">
                <b-badge
                  :variant="
                    event.is_active == 1 ? 'light-success' : 'light-danger'
                  "
                  class="icon-badge cursor-pointer"
                  v-b-tooltip.hover.top="`Open Payment Schedule`"
                  @click="openPaymentSchedule(event)"
                >
                  <feather-icon icon="CalendarIcon" size="16" />
                </b-badge>

                <p class="h6 mt-2 mb-1" :class="isDarkSkin ? 'text-white' : ''">
                  {{ event.created_at | myGlobalDay }}
                </p>
                <p
                  class="h6 text-primary mb-1 mb-lg-0 text-truncate"
                  v-b-tooltip.hover.top="event.comment"
                >
                  <strong>{{ event.comment }}</strong>
                </p>
                <p
                  class="h6 mb-1 mb-lg-0"
                  :class="event.is_active == 1 ? 'text-success' : 'text-danger'"
                >
                  <strong v-if="event.is_active == 1"> Active </strong>
                  <strong v-else> Inactive </strong>
                </p>
                <!-- <p
                  class="h6 mb-0 mb-lg-0"
                  :class="isDarkSkin ? 'text-white' : 'text-muted'"
                >
                  {{ event.created_by }}
                </p> -->
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-if="showPaymentSchedule" class="container payment-schedule">
      <custom-table
        :client-account-id="clientAccountId"
        :payment-schedule-id="currentPaymentSchedule"
        read-only
      />
    </div>
  </div>
</template>
  <script>
import fields from "@/views/commons/components/clients/components/paymentSchedule/data/fields.paymentSchedule.data.js";
import PaymentScheduleService from "@/views/commons/components/clients/components/paymentSchedule/service/payment-schedule.service.js";
import moment from "moment";
export default {
  components: {
    "custom-table": () =>
      import(
        "@/views/commons/components/clients/dashboard/options/pay-module/components/PaymentScheduleTable.vue"
      ),
  },
  props: {
    timelineEvents: {
      type: Array,
      required: true,
    },
    clientAccountId: {
      type: String,
      required: true,
    },
  },
  filters: {
    formatDate(value) {
      if (!value) return "-";
      return moment(String(value)).format("MM/DD/YYYY");
    },
  },
  data() {
    return {
      showPaymentSchedule: false,
      fields: fields,
      items: [],
      name: "",
      isBusy: false,
      currentPaymentSchedule: null,
    };
  },
  mounted() {},
  methods: {
    openPaymentSchedule(event) {
      this.items = [];
      console.log(event);
      this.currentPaymentSchedule = event.payment_schedule_id;
      this.showPaymentSchedule = true;
      this.name = event.comment;
      // this.getPaymentScheduleByClient(event);
    },
    async getPaymentScheduleByClient(event) {
      try {
        this.isBusy = true;
        const { data } =
          await PaymentScheduleService.getPaymentScheduleByClient({
            clientAccountId: event.client_account_id,
            payment_schedule_id: event.payment_schedule_id,
          });
        if (data.length == 0) return;
        this.items = data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.isBusy = false;
      }
    },
  },
};
</script>
  <style lang="scss">
.timeline-payment-schedule {
  .payment-schedule {
    .card {
      background: unset !important;
      .schedule-title {
        p {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }
  }
  .timeline-steps {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .timeline-steps .timeline-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 1rem;
  }

  @media (min-width: 768px) {
    .timeline-steps .timeline-step:not(:last-child):after {
      content: "";
      display: block;
      border-top: 0.25rem dotted #3b82f6;
      width: 3.46rem;
      position: absolute;
      left: 7.5rem;
      top: 0.3125rem;
    }
    .timeline-steps .timeline-step:not(:first-child):before {
      content: "";
      display: block;
      border-top: 0.25rem dotted #3b82f6;
      width: 3.8125rem;
      position: absolute;
      right: 7.5rem;
      top: 0.3125rem;
    }
  }

  .timeline-steps .timeline-content {
    width: 10rem;
    text-align: center;
  }

  .timeline-steps .timeline-content {
    .icon-badge {
      margin-top: -1rem;
      border-radius: 1.5rem;
      height: 3rem;
      width: 3rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease-in-out;
      &:hover {
        transform: scale(1.5);
      }
    }
  }
}
</style> 