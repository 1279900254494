export default [
  {
    path: "/tax-research-dashboard-client/:idClient",
    name: "tax-research-dashboard-general",
    redirect: { name: "personal-information-tax-research-general" },
    component: () =>
      import(
        /* webpackChunkName: "TaxResearchClientDashboard" */ "@/views/tax-research/views/clients/dashboard/AccountTaxResearch.vue"
      ),
    props: true,
    meta: {
      moduleName: "tax-research",
      general: true,
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay-general",
        name: "pay-tax-research-general",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientPay" */ "@/views/commons/components/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "task-notes-general",
        name: "task-notes-tax-research-general",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information-general",
        name: "personal-information-tax-research-general",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientPersonalInformation" */ "@/views/tax-research/views/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files-general",
        name: "client-files-tax-research-general",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr-general",
        name: "acr-tax-research-general",
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log-general",
        name: "call-log-tax-research-general",
        // redirect: { name: 'tax-research-client-general-call' },
        component: () =>
          import(
            /* webpackChunkName: "TaxResearchClientAcr" */ "@/views/business/views/clients/dashboard/views/components/sections/CallLogSubtabs.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
        },
        // children: [
        //   {
        //     path: 'general-call',
        //     name: 'tax-research-client-general-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'General',
        //       breadcrumb: [
        //         {
        //           text: 'General',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     path: 'ceo-call',
        //     name: 'tax-research-client-ceo-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/CeoCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'CEO calls',
        //       breadcrumb: [
        //         {
        //           text: 'CEO calls',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        path: "tickets",
        name: "client-tickets-tax-research-general",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
