// IMPORT LIBRARIES

import crmLead from '@/views/crm/services/lead'
import AppointmentsService from '@/views/crm/views/appoiments/service/appointments.service'

const state = {
  S_COUNTER_TODAY: 0,
  S_COUNTER_RECOVERY: 0,
}
const getters = {
  G_COUNTER_TODAY() {
    return state.S_COUNTER_TODAY
  },
  G_COUNTER_RECOVERY() {
    return state.S_COUNTER_RECOVERY
  },
}
const mutations = {
  M_COUNTER_TODAY(state, params) {
    state.S_COUNTER_TODAY = params
  },
  M_COUNTER_RECOVERY(state, params) {
    state.S_COUNTER_RECOVERY = params
  },
}
const actions = {
  async A_GET_COUNT_APPOINTMENT_TODAY_RECOVERY({ commit }, body) {
    try {
      const response = await AppointmentsService.getCountAppointmentTodayAndRecovery(body)
      if (response.status === 200) {
        commit('M_COUNTER_TODAY', response.data[0].counterToday)
        commit('M_COUNTER_RECOVERY', response.data[0].counterRecovery)
      }
      return response
    } catch (error) {
      console.log('ERROR_A_GET_COUNT_APPOINTMENT_TODAY_RECOVERY [ACTION]', error)
      throw error
    }
  },
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
