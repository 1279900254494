export default [
  {
    path: "settings",
    name: "settings-analyst-department",
    component: () =>
      import(
        "@/views/specialists/sub-modules/analyst-department/views/settings/Settings.vue"
      ),

    redirect: { name: "assignment-modification" },
    meta: {
      pageTitle: "Settings",
      parentModule: 11,
      module: 28,
      permittedRoles: [1, 2, 17],
      moduleId: 28,
      breadcrumb: [
        {
          text: "Settings",
          active: true,
        },
      ],
    },
    children: [
      {
        path: 'assignment-modification',
        name: 'assignment-modification',
        component: () => import('@/views/specialists/sub-modules/analyst-department/views/settings/views/AssignmentModification.vue'),
        meta: {
          permittedRoles: [1, 2, 17],
          type: "settings",
          tab: 6,
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Assignment Modification",
              active: true,
            },
          ],
        },
      },
      {
        path: "keywords",
        name: "keywords",
        component: () => import("@/views/specialists/sub-modules/analyst-department/views/settings/views/ClassificationNcr.vue"),
        meta: {
          permittedRoles: [1, 2 , 17],
          type: "settings",
          tab: 5,
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Keywords",
              active: true,
            }
          ]
        }
      },
      {
        path: "classification-ncr",
        name: "classification-ncr",
        component: () => import("@/views/specialists/sub-modules/analyst-department/views/settings/views/ClassificationNcr.vue"),
        meta: {
          permittedRoles: [1, 2, 17],
          type: "settings",
          tab: 6,
          pageTitle: "Settings",
          breadcrumb: [
            {
              text: "Accounts",
              active: true,
            }
          ]
        }
      }
    ],
  },
];

