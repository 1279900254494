export default [
  {
    path: "sending-reports",
    name: "correspondence-sending-reports",
    redirect: { name: "correspondence-sending-reports-in-proccess" },
    component: () => import(/* webpackChunkName: "CorrespondenceSendingReports" */ "@/views/correspondence/views/sending-reports/view/SendingReports.vue"),
    meta: {
      pageTitle: "Sending Reports",
      route: "correspondence",
      breadcrumb: [
        {
          text: "Sending Reports",
          active: true,
        },
      ],

    },
    children: [
      {
        path: "in-proccess",
        component: () => import(/* webpackChunkName: "CorrespondenceSendingReportsInProccess" */ "@/views/correspondence/views/sending-reports/view/components/Grid.vue"),
        name: "correspondence-sending-reports-in-proccess",
        route: "correspondence",
        meta: {
          pageTitle: "Sending Reports",
          breadcrumb: [
            {
              text: "In Proccess",
              active: true,
            },
          ],
          inProccessTab:
            "correspondence-sending-reports-in-proccess",
          sentTab: "correspondence-sending-reports-sent",
          notSentTab: "correspondence-sending-reports-not-sent",


        },
      },
      {
        path: "sent",
        component: () => import(/* webpackChunkName: "CorrespondenceSendingreportsSent" */ "@/views/correspondence/views/sending-reports/view/components/Grid.vue"),
        name: "correspondence-sending-reports-sent",
        route: "correspondence",
        meta: {
          pageTitle: "Sending Reports",
          breadcrumb: [
            {
              text: "Sent",
              active: true,
            },
          ],
          inProccessTab:
            "correspondence-sending-reports-in-proccess",
          sentTab: "correspondence-sending-reports-sent",
          notSentTab: "correspondence-sending-reports-not-sent",

        },
      },
    ],
  },
];
