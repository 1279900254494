export default [
  {
    path: "leads",
    name: "leads-financial-approval-department",
    component: () =>
      import(
        /* webpackChunkName: "SpecialistsLeads" */
        "@/views/commons/components/lead-programs/Lead.vue"
      ),
    redirect: { name: "specialists-fa-leads-list" },
    meta: {
      module: 11,
      route: "specialist",
      permittedRoles: [1, 2, 3, 8, 14, 15, 17],
      pageTitle: "Leads",
      breadcrumb: [
        {
          text: "Leads",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "",
        name: "specialists-fa-leads-list",
        component: () =>
          import(
            /* webpackChunkName: "SpecialistsLeadsList" */
            "@/views/commons/components/lead-programs/components/lead-table/LeadTable.vue"
          ),
        meta: {
          module: 11,
          route: "specialists",
          isClientsTab: true,
          permittedRoles: [1, 2, 3, 8, 14, 15, 17],
          pageTitle: "Leads",
          breadcrumb: [
            {
              text: "Leads",
              to: "/specialists/leads",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: "leads/show/:id",
    name: "specialist-fa-lead-show",
    component: () =>
      import(
        /* webpackChunkName: "SpecialistLeadsShow" */
        "@/views/crm/views/Lead/lead-module/dashboard/default.vue"
      ),
    props: true,
    meta: {
      isClientsTab: true,
      module: 11,
      permittedRoles: [1, 2, 3, 8, 14, 15, 17],
      route: "specialists",
      contentClass: "leads-app",
    },
  },
];
