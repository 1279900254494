import { amgApi } from "@/service/axios";

class ClientServiceBusiness {
  async search(params) {
    const { data } = await amgApi.post(
      "/clients/search-clients-business",
      params
    );
    return data;
  }
  async usersPrograms(params) {
    const data = await amgApi.post("/commons/users-programs", params);
    return data.data;
  }
  async counterClients(params) {
    const data = await amgApi.post("/clients/counter-clients", params);
    return data.data;
  }
  async counterClientsFinancial(params) {
    const data = await amgApi.post("/clients/counter-clients-financial", params);
    return data.data;
  }
}
export default new ClientServiceBusiness();
