/* eslint-disable no-useless-catch */
/* eslint-disable class-methods-use-this */
import { amgApi } from '@/service/axios';

class ProfileService {
  async verifyIfHasEssentialDocuments(body) {
    try {
      const { data, status } = await amgApi.post('/profile/verify-essential-documents', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getCountersEssentialDocuments(body) {
    try {
      const { data, status } = await amgApi.post('/profile/get-counters-essential-documents', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async downloadCurrentPayroll(body) {
    try {
      const { data, status } = await amgApi.post('/payroll-payments/download-pdf-payroll-payment', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getTotalHoursTotalMarks(body) {
    try {
      const { data, status } = await amgApi.post('/profile/get-total-hours-total-marks', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getClaimsCounters(body) {
    try {
      const { data, status } = await amgApi.post('/profile/get-claims-counter', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getScheduleByEmployee(body) {
    try {
      const { data, status } = await amgApi.post('/profile/get-schedule-by-employee', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getTotalPerMonth(body) {
    try {
      const { data, status } = await amgApi.post('/attendance/get-total-per-month', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getOvertimePending(body) {
    try {
      const { data, status } = await amgApi.post('/attendance/get-overtime-pending', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getMarksEmployeeByDay(body) {
    try {
      const { data, status } = await amgApi.post('/attendance/get-details-attendance', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getNotesUser(body) {
    try {
      const { data, status } = await amgApi.post('/profile/get-notes-user', body);
      return { data, status };
    } catch (error) {
      throw error;
    }
  }

  async getPendingCustomerTicket(body) {
    try {
      const { data } = await amgApi.post('/customer-tickets/get-pending-customer-tickets', body);
      return { data };
    } catch (error) {
      throw error;
    }
  }
}

export default new ProfileService();
