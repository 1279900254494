import store from "@/store";
import Vue from "vue";
import router from "@/router";

const RescheduleTelNotification = () => {
  window.socket.bind(
    "reschedule-tel-metamedia-notifications",
    async (response) => {
      const data = response.obj_notification;
      const user_id = store.state.auth.currentUser.user_id;
      const module_id = router.currentRoute.matched[0]?.meta?.module;
      if (data.user_id == user_id && module_id == 2) {
        await store.dispatch("NotificationStore/A_GET_NOTIFICATIONS", {
          id: user_id,
        });
        await store.dispatch(
          "modal-actions/A_VALIDATE_RESCHEDULE_APPOINTMENT",
          data
        );
      }
    }
  );
};

const showNoAnswered = () => {
  window.socket.bind("metamedia-no-answered", async (data) => {
    const { modul_id, user_id } = store.state.auth.currentUser;
    const { notification } = data;

    if (modul_id === 15) {
      const icon = "InfoIcon";
      const { title, description, updatedBy } = notification;

      Vue.prototype.$amgAlert({
        title,
        subtitle: description,
        data: [
          { icon: "InfoIcon", title: "Updated By: ", description: updatedBy },
        ],
        okTitle: "Go to Appointment No Answered",
        icon,
        ok: () => {
          router.push({ name: "appointment-no-answered" });
          window.amgApi.post("/commons/close-all-swal", { to_id: user_id });
        },
      });
    }
  });
};

export { RescheduleTelNotification, showNoAnswered };
