export default {
    namespaced: true,
    state: {
        S_DERIVATION_CLIENT_COUNTER: null,
        S_DERIVATION_LEAD_COUNTER: null,
    },
    mutations: {
        SET_DERIVATION_CLIENT_COUNTER(state, params) {
            state.S_DERIVATION_CLIENT_COUNTER = params
        },
        SET_DERIVATION_LEAD_COUNTER(state, params) {
            state.S_DERIVATION_LEAD_COUNTER = params
        }
    },
    actions: {
        A_DERIVATION_CLIENT_COUNTER({commit}, data) {
            commit("SET_DERIVATION_CLIENT_COUNTER", data)
        },
        A_DERIVATION_LEAD_COUNTER({commit}, data) {
            commit("SET_DERIVATION_LEAD_COUNTER", data)
        },
    },
    getters: {
        G_DERIVATION_LEAD_COUNTER(state) {
            return state.S_DERIVATION_LEAD_COUNTER
        },
        G_DERIVATION_CLIENT_COUNTER(state) {
            return state.S_DERIVATION_CLIENT_COUNTER
        }
    }
}