export default [
  {
    path: "/court-info-dashboard-client/:idClient",
    name: "court-info-dashboard-general",
    redirect: { name: "personal-information-claim-department-general" },
    component: () =>
      import(
        /* webpackChunkName: "ClaimDepartamentClientsDashboard" */ "@/views/court-info/views/clients/dashboard/AccountCourtInfo.vue"
      ),
    props: true,
    meta: {
      moduleName: "court-info",
      general: true,
      isClientsTab: true,
      pageTitle: "Clients",
      breadcrumb: [
        {
          text: "Account",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "pay-general",
        name: "pay-claim-department-general",
        component: () =>
          import(
            /* webpackChunkName: "ClaimDepartamentClientsDashboardPay" */ "@/views/court-info/views/clients/dashboard/options/pay-module/Pay.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Pay",
              active: true,
            },
          ],
        },
      },
      {
        path: "task-notes-general",
        name: "task-notes-claim-department-general",
        component: () =>
          import(
            /* webpackChunkName: "ClaimDepartamentClientsDashboardTaskNotes" */ "@/views/commons/components/clients/dashboard/options/task-notes-module/TaskNotes.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Task & Notes",
              active: true,
            },
          ],
        },
      },
      {
        path: "personal-information-general",
        name: "personal-information-claim-department-general",
        component: () =>
          import(
            /* webpackChunkName: "ClaimDepartamentClientsDashboardPersonalInformation" */ "@/views/court-info/views/clients/dashboard/information-client/ClientInformation.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Personal Information",
              active: true,
            },
          ],
        },
      },
      {
        path: "client-files-general",
        name: "client-files-claim-department-general",
        component: () =>
          import(
            /* webpackChunkName: "ClaimDepartamentClientsDashboardFiles" */ "@/views/commons/components/clients/dashboard/options/files-module/Files.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Files",
              active: true,
            },
          ],
        },
      },
      {
        path: "acr-general-general",
        name: "acr-claim-department-general",
        component: () =>
          import(
            /* webpackChunkName: "ClaimDepartamentClientsDashboardAcr" */ "@/views/commons/components/clients/dashboard/options/acr-module/Acr.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "report-general",
        name: "report-claim-department-general",
        component: () =>
          import(
            /* webpackChunkName: "ClaimDepartamentClientsDashboardReport" */ "@/views/commons/components/clients/dashboard/options/report-module/Report.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Clients",
          breadcrumb: [
            {
              text: "Acr",
              active: true,
            },
          ],
        },
      },
      {
        path: "call-log-general",
        name: "call-log-claim-department-general",
        component: () =>
          import(
            /* webpackChunkName: "ClaimDepartamentClientsDashboardReport" */ "@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue"
          ),
        meta: {
          isClientsTab: true,
          pageTitle: "Call Log",
          breadcrumb: [
            {
              text: "Call Log",
              active: true,
            },
          ],
        },
        // children: [
        //   {
        //     path: 'general-call',
        //     name: 'court-info-client-general-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/ClientCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'General',
        //       breadcrumb: [
        //         {
        //           text: 'General',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        //   {
        //     path: 'ceo-call',
        //     name: 'court-info-client-ceo-call',
        //     component: () => import('@/views/commons/components/ring-central/call-log/components/CeoCallLog.vue'),
        //     meta: {
        //       isClientsTab: true,
        //       pageTitle: 'CEO calls',
        //       breadcrumb: [
        //         {
        //           text: 'CEO calls',
        //           active: true,
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        path: "tickets",
        name: "client-tickets-claim-department-general",
        component: () =>
          import(
            /* webpackChunkName: "PRClientsAccountScores" */ "@/views/paragon/views/clients/dashboard/views/components/sections/Tickets.vue"
          ),
        meta: {
          pageTitle: "Client",
          breadcrumb: [
            {
              text: "Tickets",
              active: true,
            },
          ],
        },
      },
    ],
  },
];
