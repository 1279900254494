import store from "@/store"; // To get user(s)
import Vue from "vue"; // To get the 'component' of 'Notification';

// notification answered request transaction refund or void
const notificationAnsweredRequestTransaction = () => {
  window.socket.bind(
    "notification-aswered-request-refund-or-void",
    async (data) => {
      const userId = store.state.auth.currentUser.user_id;

      // show alert to request by user
      if (userId === data.request_by_id) {
        await Vue.prototype.$amgAlert({
          title: "ANSWER",
          icon: "ArrowLeftCircleIcon",
          subtitle: `ANSWER TO REQUEST OF '${
            data.type_request == 2 ? "REFUND" : "VOID"
          }'`,
          data: [
            {
              icon: "UserIcon",
              title: "CLIENT",
              description: data.client_name,
            },
            {
              icon: `${data.type_request === 1 ? "CoinIcon" : "ClockIcon"}`,
              title: "TYPE",
              description: data.type_request === 2 ? "REFUND" : "VOID",
            },
            {
              icon:
                data.status.toUpperCase() === "APPROVED"
                  ? "CheckIcon"
                  : "XIcon",
              title: "STATUS",
              description: data.status,
            },
            {
              icon: "UserIcon",
              title: "ACTION BY",
              description: data.executed_by_name,
            },
          ],
          ok: async () => {
            await window.amgApi.post("/commons/close-all-swal", data);
          },
        });
      }
    }
  );
};

export default notificationAnsweredRequestTransaction;
