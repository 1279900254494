export default 
    {
    path: 'creative-request',
    name: 'creative-request-options',
    component: () => import(/* webpackChunkName: "SnSettings" */ '@/views/commons/components/creative-requests/creativeRequest.vue'),
    meta: {
      pageTitle: 'Design request',
      breadcrumb: [{
        text: 'Creative Design',
      }],
      permittedRoles: [1, 2, 17, 18, 19, 26, 30],
    },
    redirect: { name: 'creative-pending' },
    children: [
      {
        path: 'pending',
        name: 'creative-pending',
        component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/commons/components/creative-requests/components/RequestInProcessGrid.vue'
        ),
        meta: {
          pageTitle: 'Creative Design',
          moduleName: 'creative',
          status: "pending",
          breadcrumb: [
            {
              text: 'Pending',
              active: true
            }
          ],
          permittedRoles: [1, 2, 17, 18, 19, 26, 30],
        }
      },
      {
        path: 'in-process',
        name: 'creative-in-process',
        component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/commons/components/creative-requests/components/RequestInProcessGrid.vue'
        ),
        meta: {
          pageTitle: 'Creative Design',
          moduleName: 'creative',
          status: "in process",
          breadcrumb: [
            {
              text: 'In process',
              active: true
            }
          ],
          permittedRoles: [1, 2, 17, 18, 19, 26, 30],
        }
      },   
      {
        path: 'finished',
        name: 'creative-finished',
        component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/commons/components/creative-requests/components/RequestInProcessGrid.vue'
        ),
        meta: {
          pageTitle: 'Creative Design',
          moduleName: 'creative',
          status: "finished",
          breadcrumb: [
            {
              text: 'Finished',
              active: true
            }
          ],
          permittedRoles: [1, 2, 17, 18, 19, 26, 30],
        }
      },   
      {
        path: 'approved',
        name: 'creative-approved',
        component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/commons/components/creative-requests/components/RequestInProcessGrid.vue'
        ),
        meta: {
          pageTitle: 'Creative Design',
          moduleName: 'creative',
          status: "approved",
          breadcrumb: [
            {
              text: 'Approved',
              active: true
            }
          ],
          permittedRoles: [1, 2, 17, 18, 19, 26, 30],
        }
      },  
      {
        path: 'remarks',
        name: 'creative-observed',        
        component: () => import(
        /* webpackChunkName: "SnSettingsMotives" */ '@/views/commons/components/creative-requests/components/RequestInProcessGrid.vue'
        ),
        meta: {
          pageTitle: 'Creative Design',
          moduleName: 'creative',
          status: "observed",
          breadcrumb: [
            {
              text: 'Remarks',
              active: true
            }
          ],
          permittedRoles: [1, 2, 17, 18, 19, 26, 30],
        }
      },
    ]
 }

