export default [
  {
    key: 'radio',
    sortable: false,
    label: '',
  },
  {
    key: 'card_holder_name',
    sortable: false,
    label: 'Card Holder Name',
  },
  {
    key: 'card_number',
    sortable: false,
    label: 'Card Number',
  },
  {
    key: 'type',
    sortable: false,
    label: 'Type',
  },
  {
    key: 'mm',
    sortable: false,
    label: 'MM',
  },
  {
    key: 'yy',
    sortable: false,
    label: 'YY',
  },

  {
    key: 'cvc',
    sortable: false,
    label: 'CVC',
  },
  {
    key: 'created_by',
    sortable: false,
    label: 'Created by',
  },
  {
    key: 'actions',
    sortable: false,
    label: 'Actions',
  },
]
