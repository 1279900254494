import { sumTag } from '@/@core/utils/utils';
import customerTickets from '@/views/ce-digital/sub-modules/customer-service/views/customer-tickets/customer-service-customer-tickets.navigation';
import clients from '@/views/ce-digital/sub-modules/customer-service/views/clients-table/navigation/clients.navigation';

export default {
  title: 'Clients',
  icon: 'UsersIcon',
  children: [clients, customerTickets],
  tag: sumTag([clients, customerTickets]),
};
