<template>
  <b-modal
    v-model="modalShow"
    @hidden="$emit('hidden')"
    :title="modalTitle"
    hide-footer
    body-class="rc-chat-info-2"
  >
    <b-row>
      <b-col
        v-for="(lead, index) in leadsList"
        :key="`clients_${index}`"
        cols="3"
        class="card-lead-rc p-1 mt-1"
        @click="setClient(lead)"
        :class="leadId == lead.id ? 'card-lead-rc-active' : ''"
      >
        <router-link
          :to="`/crm/leads/${lead.id}`"
          target="_blank"
          class="d-block text-primary"
          :class="textLink"
        >
          {{ getName(lead) | myFontCapitalize }}
        </router-link>

        <div>
          <span>{{ lead.created_at | myGlobal }}</span>
        </div>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-end mt-5">
      <b-button variant="secondary" class="mr-1" @click="$emit('hidden')">
        Cancel
      </b-button>
      <b-button variant="primary" @click="updateChatInformation()">
        Sync
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import NestService from "@/views/commons/components/ring-central/chat-sms/service/sms-nest.service.js";
import { mapGetters } from "vuex";
export default {
  props: {
    leadsList: {
      type: Array,
      required: true,
    },

    currentChat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalShow: true,
      leadId: null,
      leadName: "",
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),

    modalTitle() {
      return `Choose a lead for the mobile ${this.currentChat.leadPhoneNumber}`;
    },
  },

  methods: {
    async updateChatInformation() {
      try {
        this.addPreloader();

        const params = {
          leadId: this.leadId,
          leadName: this.leadName,
        };

        const { data, status } = await NestService.updateChat(
          this.currentChat._id,
          params
        );
        if (status == 200) {
          this.showSuccessSwal("OPERATION SUCCESSFULLY");
          this.$emit("hidden");
        }
      } catch (e) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    getName(lead) {
      if (lead.first_name && lead.last_name) {
        return `${lead.first_name} ${lead.last_name}`;
      }

      if (lead.nickname) {
        return lead.nickname;
      }

      return "Unknown";
    },

    setClient(lead) {
      if (lead.id == this.leadId) {
        this.leadId = null;
        this.leadName = "";
      } else {
        this.leadId = lead.id;
        this.leadName = this.getName(lead);
      }
    },
  },
};
</script>

<style lang="scss">
.rc-chat-info-2 {
  .card-lead-rc {
    cursor: pointer;
    border: 1px solid #0099dd;
    border-radius: 20px;
    margin: 0 5px;
  }

  .card-lead-rc-active {
    background-color: #f0faff;
  }
}

.dark-layout {
  .rc-chat-info-2 {
    background-color: #111b21 !important;

    .card-lead-rc-active {
      background-color: #202c33;
      color: #fff;
    }
  }
}
</style>