import Vue from "vue";
import Vuex from "vuex";
// Modules
import appConfig from "@/store/app-config";
import verticalMenu from "@/store/vertical-menu";
import auth from "@/store/auth";
import CrmStore from "@/views/crm/store";
import saleMade from "@/views/crm/views/sales-made/sale-made.store";
import CommissionsStore from "@/views/commons/components/commissions/store";
import ClientCompanyCommonsStore from "@/views/commons/components/list-client-company/store";
import LoansStore from "@/views/commons/components/loans/store";
import GlobalStore from "@/views/store";
import stickyNotesStoreModule from "@/layouts/components/navbar/components/sticky-notes/store/sticky-notes.store";
import TaskStore from "@/store/task";
import NotificationStore from "@/store/notification";
import OfferNotificationStore from "@/store/offer-notification/index.js";
import NCRStore from "@/views/commons/components/ncr/store";
import UserStore from "@/store/user";
import ProfileStore from "@/store/profile";
import MessageStore from "@/store/message";
import SidebarStore from "@/store/sidebar";
import HelpdeskStore from "@/views/commons/components/helpdesk/store";
import StandarStore from "@/store/global";
import SocialNetworkStore from "@/views/social-network/store";
import ManagementStore from "@/views/management/store";
import SchedulesStore from "@/views/commons/components/schedules/store";
import LogisticStore from "@/views/logistic/store";
import DebtSolutionStore from "@/views/debt-solution/store";
import BoostCreditStore from "@/views/boost-credit/store";
import TaxResearchStore from "@/views/tax-research/store";
import CorrespondenceStore from "@/views/correspondence/store";
import ParagonStore from "@/views/paragon/store";
import BusinessStore from "@/views/business/store";
import ModulelUserStore from "@/views/users/store";
import ClientsStore from "@/views/commons/components/clients/store";
import ReportStore from "@/views/commons/components/clients/dashboard/options/report-module/store/report.store";
import CeDigitalStore from "@/views/ce-digital/store";
import SpecialistsDigitalStore from "@/views/specialist-digital/store";
import CreditExpertsStore from "@/views/credit-experts/store";
import SpecialistsStore from "@/views/specialists/store";
import AdministrationStore from "@/views/administration/store";
import ModalActionsStore from "@/store/modal-actions";
import CreativeStore from "@/views/creative/store";
import OtherPaymentStore from "@/views/commons/components/other-payments/store/other-payment.store";
import VendorStore from "@/views/logistic/views/vendors/store/index.js";
import EmployeeClaimsStore from "@/views/commons/employee-claims/store/employee-claims.store";
import ClaimsV2Store from "@/views/commons/components/claims_v2/store";
import RequestNcrStore from "@/views/commons/components/request-ncr/store";
import AutoAlertClaim from "@/views/commons/components/claims_v2/store/auto.alert-claim-store";
// APPOINTMENT
import AlertAppointment from "@/views/commons/components/appointments/store/alert-appointment-store.js";
import AppointmentStore from "@/views/commons/components/appointments/store/appointments.store";
// ------------
import SalesMadeStore from "@/views/commons/sales-made/sales-made.store";
import DepartmentExpensesAlertStore from "@/views/commons/expenses/store/department-expenses-alert.store";
import RequestWorkPlanStore from "@/views/commons/components/request-workplan/store/index.js";
import InventoryLogistic from "@/views/logistic/views/inventory2/store";
import AutoAlertNcr from "@/views/commons/components/request-ncr/store/auto.alert-ncr-store";
import AutoAlertRc from "@/views/commons/components/risky-clients/store/alert-risk-client.store.js";
import PermissionAction from "@/views/commons/components/permissions/store/permission-action.store.js";
import AutoAlertWorkPlan from "@/views/commons/components/request-workplan/store/auto.alert-ncr-store";
import AutoAlertLetterRound from "@/views/commons/components/letters/store/auto.alert-letters-round-store.js";
import AutoAlertUpdateRequest from "@/views/commons/components/dispute-results/store/auto-alert-update-store";
import AutoAlertSendReport from "@/views/commons/components/clients/dashboard/options/report-module/store/alert-send-report-store.js";
import AutoAlertReturnedLetters from "@/views/commons/components/letters/store/alert-returned-letters.store.js";

import TicketMessagesStore from "@/views/commons/components/projects/store/ticket-messages-store";
import DocusignStore from "@/store/docusign/index.js";
import TransactionStatusStore from "@/store/transaction-status/index.js";
// import ManualsOfFunctions from "@/views/commons/components/manual-of-functions/store/store.js";
import app from "@/store/app/index.js";
import AttendanceStore from "@/views/assistance/store/attendance-dashboard.store.js";
import QualityRiskyClientsStore from "@/views/quality/views/risky-clients/store/index.js";
import TicketCustomerStore from "@/views/commons/components/customer-tickets/store/ticker-customer.store.js";
import CreativeDesignStore from "@/views/commons/components/creative-requests/store/index.js";
import BinnacleStore from "@/views/commons/binnacle/store/binnacle.store";
import CeoStore from "@/views/ceo/dashboard/store/index";
import SoftProjectsBinnacleStore from "@/store/soft-projects-binnacle/index.js";
import LeadsStore from "@/views/crm/views/Lead/leads.store";
import OtherServices from "@/views/commons/components/other-services/store/otherServices.store";
import PotentialStore from "@/views/commons/components/potencial-appointment/store/potential-appoitment.store";
import DashboardFinancialStore from "@/views/specialists/sub-modules/financial-approval/views/dashboard/store/dashboard.store";
import LeadNcrManualAccountStore from "@/views/commons/components/request-ncr/store/lead-ncr-manual-accounts.store";
import NewItemsStore from "@/views/commons/components/request-ncr/store/new-items.store.js";
import SmsInboxStore from "@/views/commons/components/ring-central/chat-sms/store/index.js";
import SaleMadeStore from "@/views/commons/components/sales-made/store/sale-made.store";
import ExpensesStore from "@/views/commons/expenses/store/expenses.store";
import ExpensesRequest from "@/views/ceo/dashboard/modals/expenses-request/store/expenses-request.store.js";
import MessengerMetaStore from "@/store/messenger-meta/index.js";
import CheckStore from "@/views/correspondence/views/checks/store/checks.store.js";

Vue.use(Vuex);

const initialState = {
  CrmStore: { ...CrmStore.state },
  SocialNetworkStore: { ...SocialNetworkStore.state },
  DebtSolutionStore: { ...DebtSolutionStore.state },
  BoostCreditStore: { ...BoostCreditStore.state },
  saleMade: { ...saleMade.state },
  CommissionsStore: { ...CommissionsStore.state },
  ClientCompanyCommonsStore: { ...ClientCompanyCommonsStore.state },
  LoansStore: { ...LoansStore.state },
  GlobalStore: { ...GlobalStore.state },
  stickyNotesStoreModule: { ...stickyNotesStoreModule.state },
  TaskStore: { ...TaskStore.state },
  NotificationStore: { ...NotificationStore.state },
  NCRStore: { ...NCRStore.state },
  UserStore: { ...UserStore.state },
  MessageStore: { ...MessageStore.state },
  SidebarStore: { ...SidebarStore.state },
  HelpdeskStore: { ...HelpdeskStore.state },
  SchedulesStore: { ...SchedulesStore.state },
  ClientsStore: { ...ClientsStore.state },
  ReportStore: { ...ReportStore.state },
  ManagementStore: { ...ManagementStore.state },
  LogisticStore: { ...LogisticStore.state },
  ParagonStore: { ...ParagonStore.state },
  TaxResearchStore: { ...TaxResearchStore.state },
  ModulelUserStore: { ...ModulelUserStore.state },
  CorrespondenceStore: { ...CorrespondenceStore.state },
  CeDigitalStore: { ...CeDigitalStore.state },
  SpecialistsDigitalStore: { ...SpecialistsDigitalStore },
  CreditExpertsStore: { ...CreditExpertsStore.state },
  SpecialistsStore: { ...SpecialistsStore.state },
  AdministrationStore: { ...AdministrationStore.state },
  ModalActionsStore: { ...ModalActionsStore.state },
  OfferNotificationStore: { ...OfferNotificationStore.state },
  CreativeStore: { ...CreativeStore.state },
  ClaimsV2Store: { ...ClaimsV2Store.state },
  RequestNcrStore: { ...RequestNcrStore.state },
  OtherPaymentStore: { ...OtherPaymentStore.state },
  EmployeeClaimsStore: { ...EmployeeClaimsStore.state },
  AutoAlertClaim: { ...AutoAlertClaim.state },
  AlertAppointment: { ...AlertAppointment.state },
  AppointmentStore: { ...AppointmentStore.state },
  SalesMadeStore: { ...SalesMadeStore.state },
  DepartmentExpensesAlertStore: { ...DepartmentExpensesAlertStore.state },
  auth: { ...auth.state },
  TicketMessagesStore: { ...TicketMessagesStore.state },
  DocusignStore: { ...DocusignStore.state },
  PermissionActionStore: { ...PermissionAction.state },
  AutoAlertNcr: { ...AutoAlertNcr.state },
  // ManualsOfFunctions: { ...ManualsOfFunctions },
  AttendanceStore: { ...AttendanceStore.state },
  TicketCustomerStore: { ...TicketCustomerStore.state },
  TransactionStatusStore: { ...TransactionStatusStore.state },
  CreativeDesignStore: { ...CreativeDesignStore.state },
  CeoStore: { ...CeoStore.state },
  AutoAlertRc: { ...AutoAlertRc.state },
  RequestWorkPlanStore: { ...RequestWorkPlanStore.state },
  AutoAlertWorkPlan: { ...AutoAlertWorkPlan.state },
  AutoAlertLetterRound: { ...AutoAlertLetterRound.state },
  AutoAlertSendReport: { ...AutoAlertSendReport.state },
  AutoAlertReturnedLetters: { ...AutoAlertReturnedLetters.state },
  QualityRiskyClientsStore: { ...QualityRiskyClientsStore.state },
  AutoAlertUpdateRequest: { ...AutoAlertUpdateRequest.state },
  BinnacleStore: { ...BinnacleStore.state },
  SoftProjectsBinnacleStore: { ...SoftProjectsBinnacleStore.state },
  LeadsStore: { ...LeadsStore },
  OtherServices: { ...OtherServices.state },
  PotentialStore: { ...PotentialStore.state },
  DashboardFinancialStore: { ...DashboardFinancialStore.state },
  LeadNcrManualAccountStore: { ...LeadNcrManualAccountStore.state },
  NewItemsStore: { ...NewItemsStore.state },
  SmsInboxStore: { ...SmsInboxStore.state },
  SaleMadeStore: { ...SaleMadeStore.state },
  ExpensesStore: { ...ExpensesStore.state },
  MessengerMetaStore: { ...MessengerMetaStore.state },
  CheckStore: { ...CheckStore.state },
};

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    "crm-store": saleMade,
    ...CrmStore,
    ...SocialNetworkStore,
    ...DebtSolutionStore,
    ...BoostCreditStore,
    ...ParagonStore,
    ...TaxResearchStore,
    ...ModulelUserStore,
    "commissions-store": CommissionsStore,
    "client-company-commons-store": ClientCompanyCommonsStore,
    "loans-store": LoansStore,
    "global-store": GlobalStore,
    StandarStore,
    TaskStore,
    "sticky-notes": stickyNotesStoreModule,
    "ncr-store": NCRStore,
    "clients-store": ClientsStore,
    ReportStore,
    NotificationStore,
    UserStore,
    ProfileStore,
    MessageStore,
    SidebarStore,
    HelpdeskStore,
    SchedulesStore,
    ...ManagementStore,
    ...LogisticStore,
    ...BusinessStore,
    ...CorrespondenceStore,
    ...CeDigitalStore,
    ...SpecialistsDigitalStore,
    ...CreditExpertsStore,
    ...AdministrationStore,
    ...SpecialistsStore,
    "modal-actions": ModalActionsStore,
    OfferNotificationStore,
    ...CreativeStore,
    "other-payment-store": OtherPaymentStore,
    EmployeeClaimsStore,
    VendorStore,
    ClaimsV2Store,
    RequestNcrStore,
    AutoAlertClaim,
    AlertAppointment,
    AppointmentStore,
    SalesMadeStore,
    DepartmentExpensesAlertStore,
    InventoryLogistic,
    ticketmessagesstore: TicketMessagesStore,
    DocusignStore,
    PermissionAction,
    AutoAlertNcr,
    // ManualsOfFunctions,
    AttendanceStore,
    TicketCustomerStore,
    TransactionStatusStore,
    CreativeDesignStore,
    ...CeoStore,
    AutoAlertRc,
    RequestWorkPlanStore,
    AutoAlertWorkPlan,
    AutoAlertLetterRound,
    AutoAlertSendReport,
    AutoAlertReturnedLetters,
    QualityRiskyClientsStore,
    AutoAlertUpdateRequest,
    BinnacleStore,
    SoftProjectsBinnacleStore,
    LeadsStore,
    OtherServices,
    PotentialStore,
    DashboardFinancialStore,
    LeadNcrManualAccountStore,
    NewItemsStore,
    SmsInboxStore,
    MessengerMetaStore,
    SaleMadeStore,
    ExpensesStore,
    ExpensesRequest,
    CheckStore,
  },
  mutations: {
    resetState(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], initialState[key]);
      });
    },
  },
  actions: {},
  state: {
    defaultRows: 50,
  },
  strict: process.env.DEV,
});
