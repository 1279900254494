import otherPayments from "@/views/specialists/sub-modules/analyst-department/views/other-payments/other-payments.nav.js";
import payments from "@/views/specialists/sub-modules/analyst-department/views/payments/payments.nav.js";
import chargeBack from "@/views/specialists/sub-modules/analyst-department/views/charge-back/charge-back.nav.js";

export default {
    title: "Financial",
    icon: "FileTextIcon",
    children: [otherPayments,payments,chargeBack
     ],
};
  