import { amgApi } from '@/service/axios'

class AppPaymentsMadeService {
  async getAppPaymentsMade(body) {
    try {
      const data = await amgApi.post('credit-experts-digital/financial/get-app-payments-made', body)
      return data
    } catch (error) {
      console.log('ERRORS ON AppPaymentsMadeService: getAppPaymentsMade')
    }
  }
  async markAsSeenAppPayMade(body) {
    try {
      const data = await amgApi.post('credit-experts-digital/financial/mark-as-seen-app-pay-made', body)
      return data
    } catch (error) {
      console.log('ERRORS ON AppPaymentsMadeService: markAsSeenAppPayMade')
    }
  }
  async getNotSeenAppPayMade() {
    try {
      const data = await amgApi.get('credit-experts-digital/financial/get-not-seen-app-pay-made')
      return data
    } catch (error) {
      console.log('ERRORS ON AppPaymentsMadeService: getNotSeenAppPayMade')
    }
  }
}

export default new AppPaymentsMadeService()
