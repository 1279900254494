import {amgApi} from '@/service/axios'



class ConnectionUsersRelationshipService{

    BASE_URL = 'credit-expert/connection'

    async relationshipUsers(){

        try {
            const { data } = await amgApi.post(`${this.BASE_URL}/get-users-to-relationship`)
            return data
        } catch (error) {
            console.error(error)
        }
    }
    async relationshipUsersAllAgents(){
        try {
            const { data } = await amgApi.post(`${this.BASE_URL}/get-users-all-agents`)
            return data
        } catch (error) {
            console.error(error)
        }
    }
    async getRelationships(parameters){
        // params: { page: int(current_page), per_page: int,
        //          agent_id : int(for search), specialist_id : int (for search), admin_id : int(for search) }

        try {
            const { data } = await amgApi.post(`${this.BASE_URL}/get-connection-users-relationship`,parameters)

            return data

        } catch (error) {
            console.error(error)
        }
    }
    async getRelationshipTracking(agent_id){

        try {

            const { data } = await amgApi.post(`${this.BASE_URL}/get-relationship-tracking`,{ agent_id }) 

            return data

        } catch (error) {
            console.error(error)
        }


    }

    async createNewRelationship(parameters){
        // params: { agent_id :int (required), specialist_id: int (required),admin_id : int (required)}
        try {
            const { data } = await amgApi.post(`${this.BASE_URL}/assign-users-relationship`,parameters)

            return data

        } catch (error) {
            console.error(error.msg[2])
        }
    }
    async getRelationship(agent_id){
        try {
            const { data } = await amgApi.post(`${this.BASE_URL}/get-relationship`,{agent_id})

            return data

        } catch (error) {
            console.error(error.msg[2])
        }
    }
    async editRelationship(parameters){
        // params: { agent_id :int (required), specialist_id: int (required),admin_id : int (required)}

        try{
            const { data } = await amgApi.post(`${this.BASE_URL}/assign-users-relationship`,parameters)

            return data

        }catch(error){
            console.error(error.msg[2])
        }

    }
    async deleteRelationship(agent_id){
        
        try{
            const { data,status } = await amgApi.post(`${this.BASE_URL}/delete-user-relationship`,{agent_id})

            return {data,status}

        }catch(error){
            console.error(error.msg[2])
        }

    }

    async checkIfAgentHasRelationShip(agent_id){
        try {
            const { data } = await amgApi.post(`${this.BASE_URL}/check-if-agent-has-relationship`, agent_id)
            return data
        } catch(error) {
            console.error(error)
        }
    }


}



export default new ConnectionUsersRelationshipService()