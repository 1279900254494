import DashboardRolesSales from '@/views/ce-digital/sub-modules/sales/views/dashboards/DashboardRolesSales.vue'

export default [
  {
    path: 'dashboard',
    name: 'ce-dashboard-sales',
    component: DashboardRolesSales,
    meta: {
      parentModule: 21,
      permittedRoles: [1, 2, 5, 14, 17],
      module: 26,
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true,
        },
      ],
    },
  },
]
