export default [
  {
    path: "employee-claims",
    name: "mg-employee-claims",
    redirect: { name: "mg-employee-claims-all" },
    component: () =>
      import(
        /* webpackChunkName: "MG-employee-claims" */ "@/views/management/views/employee-claims/EmployeeClaims.vue"
      ),
    meta: {
      pageTitle: "Staff Claims",
      breadcrumb: [
        {
          text: "Staff Claims",
          active: true,
        },
      ],
    },
    children: [
      {
        path: "all",
        name: "mg-employee-claims-all",
        component: () =>
          import(
            /* webpackChunkName: "MG-employee-claims-grid-all" */ "@/views/commons/employee-claims/components/Grid.vue"
          ),
        meta: {
          pageTitle: "Staff Claims",
          breadcrumb: [
            {
              text: "All Claims",
              active: true,
            },
          ],
          allClaims: 1,
          claimsAllTab: 'mg-employee-claims-all',
          claimsListTab: 'mg-employee-claims-list',
          employeeClaimsDashboardRoute: 'dashboard-claims-management',
        },
      },
      {
        path: "list",
        name: "mg-employee-claims-list",
        component: () =>
          import(
            /* webpackChunkName: "MG-employee-claims-grid-list" */ "@/views/commons/employee-claims/components/Grid.vue"
          ),
        meta: {
          pageTitle: "Staff Claims",
          breadcrumb: [
            {
              text: " My Claims",
              active: true,
            },
          ],
          claimsAllTab: 'mg-employee-claims-all',
          claimsListTab: 'mg-employee-claims-list',
          employeeClaimsDashboardRoute: 'dashboard-claims-management',
        },
      },

    ],
  },
];
