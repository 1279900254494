export default [
  {
    path: 'clients',
    name: 'ce-digital-financial-clients',
    component: () => import(/* webpackChunkName: "CEDFinancialClients" */ "@/views/ce-digital/sub-modules/financial/view/clients/Clients.vue"),
    redirect: { name: 'ce-digital-financial-clients-active' },
    children: [
      {
        path: 'hold',
        component: () => import(/* webpackChunkName: "CEDFinancialClientsHold" */ "@/views/ce-digital/sub-modules/financial/view/clients/components/HoldTab.vue"),
        name: 'ce-digital-financial-clients-hold',
        meta: {
          pageTitle: 'Clients',
          parentModule: 21,
          pendingTab: true,
          breadcrumb: [

            {
              text: 'Hold',
              active: true,
            },
          ],

        },
      },
      {
        path: 'loyal',
        component: () => import(/* webpackChunkName: "CEDFinancialClientsLoyal" */ "@/views/ce-digital/sub-modules/financial/view/clients/components/LoyalTab.vue"),
        name: 'ce-digital-financial-clients-loyal',
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [

            {
              text: 'Active',
              active: true,
            },
          ],

        },
      },
      {
        path: 'active',
        component: () => import(/* webpackChunkName: "CEDFinancialClientsActive" */ "@/views/ce-digital/sub-modules/financial/view/clients/components/ActiveTab.vue"),
        name: 'ce-digital-financial-clients-active',
        meta: {
          pageTitle: 'Clients',
          breadcrumb: [

            {
              text: 'Active',
              active: true,
            },
          ],

        },
      },
    ],
    meta: {
      parentModule: 21,

    },
  },
]
