<template>
  <div>
    <b-modal
      v-model="ownControl"
      modal
      centered
      title="DETAIL OF THE PAYMENT METHOD"
      size="fmd"
      modal-class="custom-modal-amg"
      title-tag="h3"
      title-class="text-white font-weight-bolder"
      body-class="px-0"
      hide-footer
      :no-close-on-backdrop="true"
      @hidden="closeModalHistoryPayment"
      header-bg-variant="transparent"
      header-class="p-0"
    >
      <template v-slot:modal-header>
        <div class="header-modal">
          <div class="d-flex align-items-center">
            <span>HISTORY MONTHLY PAYMENT</span>
          </div>
          <div
            class="container-icons"
            :class="isDarkSkin ? 'container-icons--dark' : ''"
            @click="closeModalHistoryPayment"
          >
            <feather-icon
              icon="XIcon"
              size="18"
              class="pointer"
              :class="isDarkSkin ? 'text-light' : 'text-primary'"
            />
          </div>
        </div>
      </template>
      <!-- Titles -->
      <b-row class="client-info">
        <!-- Client -->
        <b-col cols="6" class="p-0">
          <div
            class="mr-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>Client | {{ client.client_name || clientProp.client_name }}</p>
          </div>
        </b-col>
        <b-col cols="6" class="p-0">
          <div
            class="ml-1"
            :class="isDarkSkin ? 'border-custom' : 'border-custom-light'"
          >
            <p>
              Program | {{ client.program_name || clientProp.program_name }}
            </p>
          </div>
        </b-col>
      </b-row>
      <!-- Table -->
      <b-table
        :items="searchHistoryPayment"
        :fields="fields"
        primary-key="id"
        table-class="text-nowrap"
        responsive
        show-empty
        sticky-header="70vh"
        :busy="isBusy"
        class="table-new-customization custom-table"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1" />
            <strong>Loading ...</strong>
          </div>
        </template>

        <template #cell(authorizel_id)="data">
          <div>
            <feather-icon
              v-if="data.item.updater_name"
              icon="ChevronsDownIcon"
              class="text-danger font-medium-1"
              size="16"
              v-b-tooltip.hover="`Recurring Billing Inactive`"
            />
            <feather-icon
              v-else
              icon="ChevronsUpIcon"
              class="text-success font-medium-1"
              size="16"
              v-b-tooltip.hover="`Recurring Billing Active`"
            />
            <span>
              {{
                data.item.authorizel_id == null ? "-" : data.item.authorizel_id
              }}
            </span>
          </div>
        </template>

        <template #cell(cardnumber)="data">
          <div>
            <span>{{
              data.item.cardnumber == null ? "-" : data.item.cardnumber
            }}</span>
          </div>
        </template>
        <template #cell(day_payment)="data">
          <div>
            <span>{{
              data.item.day_payment == null ? "-" : data.item.day_payment
            }}</span>
            {{ startDate(data.item) }}
            <br />
            <span
              class="text-primary"
              v-if="data.item.ocurrences > 0 && data.item.ocurrences <= 10"
            >
              {{ data.item.ocurrences }} ocurrences
            </span>
          </div>
        </template>
        <template #cell(monthly_amount)="data">
          <div>
            <span>{{ "$ " + data.item.monthly_amount }}</span>
          </div>
        </template>
        <template #cell(method_payment)="data">
          <div>
            <span>{{
              data.item.method_payment == 0 ? "Credit Card" : "Others"
            }}</span>
          </div>
        </template>
        <template #cell(type_payment)="data">
          <div>
            <span>{{
              data.item.type_payment == 1
                ? "Manual"
                : data.item.type_payment == 0
                ? "Automatic"
                : ""
            }}</span>
          </div>
        </template>
        <template #cell(creator_name)="data">
          <div class="row align-items-center">
            <div class="col-2 p-0">
              <b-avatar
                variant="info"
                :src="
                  data.item.user_image_created
                    ? data.item.user_image_created
                    : require('@/assets/images/logo/amg_logo.svg')
                "
              ></b-avatar>
            </div>
            <div class="col-9 px-1">
              <span>{{ data.item.creator_name }}</span> <br />
              <span>{{ data.item.created_at | myGlobalDay }}</span>
            </div>
          </div>
        </template>
        <template #cell(updater_name)="data">
          <div
            class="row align-items-center"
            v-if="data.item.updated_at != null"
          >
            <div class="col-2 p-0">
              <b-avatar
                variant="info"
                :src="
                  data.item.user_image_updated
                    ? data.item.user_image_updated
                    : require('@/assets/images/logo/amg_logo.svg')
                "
              ></b-avatar>
            </div>
            <div class="col-9 px-1">
              <span>{{ data.item.updater_name }}</span> <br />
              <span>{{ data.item.updated_at | myGlobalDay }}</span>
            </div>
          </div>
        </template>
        <template #cell(created_at)="data">
          {{ data.item.creator_name }}
          <br />
          {{ data.item.created_at | myGlobal }}
        </template>
        <template #cell(updated_at)="data">
          {{ data.item.updater_name }}
          <br />
          {{ data.item.updated_at | myGlobal }}
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ClientDashboardService from "../../services/clients.dashboard.services";

export default {
  props: {
    clientProp: {
      type: Object,
      default: () => ({
        id: null,
        program_name: null,
        client_name: null,
      }),
    },
  },
  components: {
    ClientDashboardService,
  },

  data() {
    return {
      ownControl: false,
      isBusy: false,
      openModalHistoryPayment: this.modalHistoryPayment,
      historys: [],
      fields: [
        {
          key: "authorizel_id",
          label: "Id Autorize",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "cardnumber",
          label: "Number Card",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "day_payment",
          label: "Start Day",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "monthly_amount",
          label: "Monthly Amount",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "method_payment",
          label: "Method Payment",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "type_payment",
          label: "Type Payment",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "created_at",
          label: "User Creator",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "updated_at",
          label: "User Updater",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      client: "DebtSolutionClients/G_CLIENTS",
      currentUser: "auth/currentUser",
    }),
  },
  created() {
    this.ownControl = true;
  },
  methods: {
    closeModalHistoryPayment() {
      this.$emit("close");
    },
    async searchHistoryPayment() {
      try {
        this.isBusy = true;
        const response = await ClientDashboardService.searchHistoryMonthly({
          id: this.$route.params.idClient || this.clientProp?.id,
        });
        if (response.status == 200) {
          this.isBusy = false;
          this.historys = response.data;
          return this.historys;
        }
        return this.historys;
      } catch (error) {
        this.isBusy = false;
        console.log(error);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong with history!"
        );
      }
    },
    startDate(item) {
      if (item.day_payment == null) {
        return "-";
      }
      const day =
        item.day_payment < 10 ? `0${item.day_payment}` : item.day_payment;
      const year = item.year_payment;
      const month = item.month_payment
        ? item.month_payment < 10
          ? `0${item.month_payment}`
          : item.month_payment
        : null;
      let startDate = day;
      if (month) {
        startDate = `${month}/${day}/${year}`;
      }
      return startDate;
    },
  },
};
</script>
<style scoped>
.custom-table >>> thead tr th,
.custom-table >>> tfoot tr th {
  background-color: #3f7afa !important;
  border-color: #3f7afa !important;
  color: #fff;
}
</style>
<style scoped lang="scss">
@import "@/views/commons/components/clients/dashboard/information-client/styles/table-modal.scss";
</style>
