<template>
  <b-row class="p-2">
    <b-col md="6">
      <b-card
        class="shadow-none p-0 m-0"
      >
        <div>
          <h3 style="font-weight: bold">
            Add permissions
          </h3>
          <h6>Select the users to add permissions</h6>
        </div>
        <filter-slot
          :filter="filter"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="refreshTable"
        >
          <b-table
            ref="tableUsers"
            slot="table"
            sticky-header="40vh"
            :busy.sync="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items="getUsers"
            :fields="fields"
            :head-row-variant="'grid-custom-tickets-header'"
            :per-page="paginate.perPage"
            :current-page="paginate.currentPage"
            :filter="filter"
            show-empty
            responsive
            no-provider-filtering
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading...</strong>
              </div>
            </template>
            <!-- NUMBER -->
            <template #cell(number)="data">
              <div>
                {{ data.item.number }}
              </div>
            </template>
            <!-- EMPLOYEE -->
            <template #cell(employee)="data">
              <div>
                {{ data.item.first_name }}
                {{ data.item.last_name }}
              </div>
            </template>
            <!-- DEPARTMENT NAME -->
            <template #cell(department_name)="data">
              <div>
                {{ data.item.department_name }}
              </div>
            </template>
            <!-- MODULE -->
            <template #cell(modules)="data">
              <div v-if="data.item.json_result">

                <div
                  v-for="(item, index) in JSON.parse(data.item.json_result).slice(0, totalShowModules)"
                  :key="index"
                >
                  {{ item.module_name }}
                </div>
                <template v-if="arrayShowModule.includes(data.index)">
                  <div
                    v-for="(item2) in JSON.parse(data.item.json_result).slice(totalShowModules)"
                    :key="item2.id"
                  >
                    {{ item2.module_name }}
                  </div>
                </template>
                <b-badge
                  v-if=" data.item.json_result? JSON.parse(data.item.json_result).length > totalShowModules : false"
                  variant="dark"
                  class="cursor-pointer"
                  @click="showmore(data.index)"
                >
                  see more
                </b-badge>
              </div>
            </template>
            <template #cell(actions)="data">
              <span
                v-if="data.item.is_exist === 0"
                v-b-tooltip.hover.top="'Add user'"
                v-b-tooltip.hover.v-success
                class="text-success cursor-pointer"
                @click="addOrDeleteUser(data.item, 1)"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  size="30"
                />
              </span>
              <span v-else>
                <div>
                  Already selected
                </div>
              </span>
            </template>
          </b-table>
        </filter-slot>
      </b-card>
    </b-col>
    <b-col md="6">
      <b-card
        :class="isDarkSkin ? 'bg-dark' : 'bg-light'"
        title="Users with permissions"
        sub-title="Select the user to remove it from the list"
      >
        <b-overlay
          :show="showLoading"
          variant="primary"
          :opacity="0.5"
          spinner-variant="primary"
          rounded="xl"
          style="max-height: 500px;"
        >
          <div style="display:flex; gap:1rem 0; flex-wrap: wrap; justify-content: space-between;">
            <div
              v-if="saveUsersAvailability.length === 0"
              style="display: flex; width: 100%;justify-content: center; text-align: center;height: 8rem; align-items: center;"
            >
              <span style="color:#4C4C4D; font-size:25px">
                There are no users with permissions
                <div class="pt-1">
                  <feather-icon
                    icon="SlashIcon"
                    size="40"
                  />
                </div>
              </span>
            </div>
            <div
              v-for="(item, index) in saveUsersAvailability"
              :key="index"
              style="display:flex"
            >
              <div
                :style="isDarkSkin?'background-color: #1F1F2E': 'background-color: white'"
                style="display: flex;justify-content: space-between;align-items: center;padding: 1rem; border-radius:5px; width:19rem"
              >
                <div>
                  <div>
                    <span style="font-weight: bold;"> {{ item.first_name }} </span>
                    <span style="font-weight: bold;"> {{ item.last_name }}</span>
                    <div v-if="item.json_result">

                      <div
                        v-for="(itemModule, indexModule) in JSON.parse(item.json_result).slice(0, totalShowModulest)"
                        :key="indexModule"
                      >
                        <span
                          :style="itemModule.module_name=== 'CUSTOMER SERVICE REGULAR' ||
                            itemModule.module_name=== 'CUSTOMER SERVICE DIGITAL'?'display:none !important':'' ||
                              itemModule.module_name=== 'SALES'?'display:none !important':'' ||
                                itemModule.module_name=== 'SETTINGS'?'display:none !important':'' ||
                                  itemModule.module_name=== 'CONNECTION'?'display:none !important':'' ||
                                    itemModule.module_name=== 'FINANCIAL'?'display:none !important':''"
                        >{{ itemModule.module_name }}</span>
                      </div>
                      <template v-if="arrayShowModulet.includes(index)">
                        <div
                          v-for="(item2) in JSON.parse(item.json_result).slice(totalShowModulest)"
                          :key="item2.id"
                        >
                          <span
                            :style="item2.module_name=== 'CUSTOMER SERVICE REGULAR' ||
                              item2.module_name=== 'CUSTOMER SERVICE DIGITAL'?'display:none !important':'' ||
                                item2.module_name=== 'SALES'?'display:none !important':'' ||
                                  item2.module_name=== 'SETTINGS'?'display:none !important':'' ||
                                    item2.module_name=== 'CONNECTION'?'display:none !important':'' ||
                                      item2.module_name=== 'FINANCIAL'?'display:none !important':''"
                          >{{ item2.module_name }}</span>
                        </div>
                      </template>
                      <b-badge
                        v-if=" item.json_result? JSON.parse(item.json_result).length > totalShowModulest : false"
                        variant="dark"
                        class="cursor-pointer"
                        style="border:1px solid"
                        @click="showmoret(index)"
                      >
                        see more
                      </b-badge>
                    </div>
                  </div>
                  <div>{{ item.department_name }}</div>
                </div>
                <div>
                  <span
                    v-b-tooltip.hover.top="'Delete user'"
                    v-b-tooltip.hover.v-danger
                    class="text-danger cursor-pointer"
                    @click="addOrDeleteUser(item, 0)"
                  >
                    <feather-icon
                      icon="XSquareIcon"
                      size="40"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>

      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomerService from '@/views/commons/components/customer-tickets/services/customer-tickets.service';
import fields from '@/views/management/views/settings/components/users_availability/data/UsersFields';
import FiltersData from '@/views/management/views/settings/components/users_availability/data/UsersFilters';
import EmployeesService from '@/views/management/views/employees/employees.service';

export default {
  components: { },

  data() {
    return {
      showLoading: true,
      sortBy: null,
      sortDesc: false,
      toPage: 0,
      startPage: 0,
      totalRows: 0,
      isBusy: false,
      fields,
      filter: FiltersData,
      paginate: {
        perPage: 100,
        currentPage: 1,
      },
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'User name or employee name...',
        model: '',
      },
      usersAvailability: [],
      arrayShowModule: [],
      totalShowModules: 1,
      usersAdded: [],
      saveUsersAvailability: [],
      userDelete: [],

      totalShowModulest: 1,
      arrayShowModulet: [],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  created() {
  },
  async mounted() {
    await this.getDepartments();
    await this.getUsersAvailability();
  },
  methods: {

    async getUsers() {
      try {
        const ctx = {
          perPage: this.paginate.perPage,
          currentPage: this.paginate.currentPage,
          department: this.filter[0].model,
          employee: this.filterPrincipal.model,
        };
        const response = await CustomerService.getAllUsersAvailability(ctx);
        if (response.status === 200) {
          this.usersAvailability = response.data.data;
          this.startPage = response.data.from;
          this.paginate.currentPage = response.data.current_page;
          this.paginate.perPage = response.data.per_page;
          this.totalRows = response.data.total;
          this.toPage = response.data.to;
          console.log(this.usersAvailability);
          return this.usersAvailability;
        }
      } catch (error) {
        console.log(error);
      }
      return null;
    },
    async getUsersAvailability() {
      try {
        const ctx = {
          perPage: this.paginate.perPage,
        };
        const response = await CustomerService.getSaveUsersAvailability(ctx);
        if (response.status === 200) {
          this.saveUsersAvailability = response.data;
          this.showLoading = false;
          return this.saveUsersAvailability;
        }
      } catch (error) {
        console.log(error);
      }
      return null;
    },

    async addOrDeleteUser(user, option) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        if (option === 1) {
          this.usersAdded.push(user);
          console.log(this.usersAdded);
        } else {
          this.userDelete.push(user);
          console.log(this.userDelete);
        }
        const ctx = {
          users: option === 1 ? this.usersAdded : this.userDelete,
          action: option === 1 ? 'add' : 'delete',
        };
        const response = await CustomerService.saveAllUsersAvailability(ctx);
        if (response.status === 200) {
          console.log('success');
          this.usersAdded = [];
          this.userDelete = [];
          this.getUsersAvailability();
          this.refreshTable();
        }

        // console.error('Error en la actualización:', response.status, response.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getDepartments() {
      try {
        const params = {};
        const data = await EmployeesService.getAllDepartments(params);

        this.department = data.data;
        // Must return an array of items or an empty array if an error occurred
        this.filter[0].options = this.department;
        return this.department;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    handleCheckboxChange(user) {
      console.log(user);
    },
    showmore(index) {
      if (this.arrayShowModule.includes(index)) {
        this.arrayShowModule = this.arrayShowModule.filter(item => item !== index);
        return;
      }
      this.arrayShowModule.push(index);
    },
    showmoret(index) {
      if (this.arrayShowModulet.includes(index)) {
        this.arrayShowModulet = this.arrayShowModulet.filter(item => item !== index);
        return;
      }
      this.arrayShowModulet.push(index);
    },
    refreshTable() {
      this.$refs.tableUsers.refresh();
    },

  },
};
</script>

<style scoped>
.card-body{
  min-height: 52rem;
  overflow: scroll;
}
</style>
