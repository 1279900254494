export default [
    {
      path: "commissions",
      name: "boostcredit-commissions",
      component: () => import(/* webpackChunkName: "BCCommissions" */ "@/views/boost-credit/views/commission/Commissions.vue"),
      meta: {
        program: 2,
        pageTitle: "Commissions",
        breadcrumb: [
          {
            text: "Commissions",
          },
        ],
      },
    },
  ];