import Vue from 'vue';
import store from '@/store';

const NotificationDeletedMeeting = () => {
  window.socket.bind('notification-deleted-meeting-ticket-customer', async data => {
    const { information, usersId, rangeDisabled } = data;
    const { user_id } = store.state.auth.currentUser;

    if (usersId.includes(user_id)) {
      await Vue.prototype.$amgAlert({
        title: 'TICKET CUSTOMER MEETING',
        icon: 'XIcon',
        subtitle: 'CANCEL MEETING',
        data: [
          {
            icon: 'BriefcaseIcon',
            title: 'TICKETS',
            description: JSON.parse(information[user_id]).join(', '),
          },
          {
            description: `The ceo has removed the "${rangeDisabled.hour_start} - ${rangeDisabled.hour_end}" time range for the day ${rangeDisabled.date} and has cancelled the pending or rescheduled meetings of these tickets in which you are participating`,
          },
        ],
        ok: async () => { await window.amgApi.post('/commons/close-all-swal', { to_id: user_id }); },

      });
    }
  });
};
export default NotificationDeletedMeeting;
