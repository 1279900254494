import direct from "@/views/specialists/sub-modules/analyst-department/views/direct-round/navigation/direct-round.nav";
import letters from "@/views/specialists/sub-modules/analyst-department/views/letter-round/navigation/letter-round.nav";

export default {
  title: "Dispute",
  icon: "FileTextIcon",
  // route: 'analyst-department-dispute',
  tag: 0,
  children: [direct, letters],
};
