import store from '@/store';
import Notifications from '@/service/notification/index';
import DirectsRoundService from '@/views/specialist-digital/views/directs-round/directs-round.service.js';
import ApplicationService from '@/views/commons/components/applications/services/applications.service';
import NcrProgramsServices from '@/views/commons/components/ncr-programs/service/ncr-programs.service.js';
import UpdateServices from '@/views/specialist-digital/views/updates/services/update.service';
import employeeClaims from '@/views/commons/commons-counter';
import RequestService from '@/views/commons/components/request/services/request.service.js';
import NcrRequestService from '@/views/commons/components/request-ncr/services/ncr-request.service.js';
import RecruitmentService from '@/views/commons/components/recruitment-process/services/recruiment.service';
import ProcesCRServices from './views/process-cr/services/process-cr.services';
import LettersService from './views/letters-round/services/letters-round.service';

const ncrRealtorCounter = async () => {
  const params = {
    iduser: store.state.auth.currentUser.user_id,
    is_digital: 2,
    num: 2,
  };
  const data = await NcrProgramsServices.getCounterNcrRealtor(params);
  if (data.length > 0) {
    const payload = {
      routeName: 'specialist-digital-cr-realtor',
      tag: data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};
const returnedLettersCounter = async () => {
  const data = await LettersService.getReturnedLettersCount();
  if (data.length > 0) {
    const payload = {
      routeName: 'specialistdigital-letters',
      tag: data[0].count > 99 ? '+99' : data[0].count,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
    store.dispatch(
      'SpecialistsDigitalRoundLettersStore/A_SET_COUNTER_RETURNED_ROUND_LETTERS',
      { pending: data[0].count },
    );
  }
};
const pendingDirectRounds = async () => {
  const data = await DirectsRoundService.getPendingDirectRoundCount();
  if (data.length > 0) {
    const payload = {
      routeName: 'specialistdigital-directs-round',
      tag: data[0].count > 99 ? '+99' : data[0].count,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
    store.dispatch(
      'SpecialistsDigitalDirectRoundsStore/A_SET_COUNTER_PENDING_DIRECT_ROUNDS',
      { pending: data[0].count },
    );
  }
};
const countProcessCE = async () => {
  const { data } = await ProcesCRServices.getCountProcessCE();
  if (data.length > 0) {
    const payload = {
      routeName: 'specialistdigital-process-cr',
      tag: data[0].cc > 99 ? '99+' : data[0].cc,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getCountClientCE = async () => {
  const { data } = await ProcesCRServices.getCountClientCE();
  if (data.length > 0) {
    const payload = {
      routeName: 'specialistdigital-clients',
      tag: data[0].counter > 99 ? '99+' : data[0].counter,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};
const applicationCounter = async () => {
  const params = {
    id: store.state.auth.currentUser.user_id,
  };
  const data = await ApplicationService.getNotificationsApp(params);

  if (data.length > 0) {
    const payload = {
      routeName: 'specialist-digital-applications',
      tag: data[0].cn > 99 ? '99+' : data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};
const getCountUpdates = async () => {
  const { data } = await UpdateServices.getCountUpdates();
  if (data.length > 0) {
    const payload = {
      routeName: 'specialistdigital-updates',
      tag: data[0].counter > 99 ? '99+' : data[0].counter,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const requestNotAsignedCounter = async () => {
  const params = { moduleId: store.state.auth.currentUser.modul_id };
  const data = await RequestService.getCounterNotAsigned(params);

  const payload = {
    routeName: 'request-specialistdigital',
    tag: data[0].counter,
  };
  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const projectsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await Notifications.getProjectsPendingNotification(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'specialist-digital-projects',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const requestNcrCounter = async () => {
  const { pending_ncr_client, pending_ncr_lead } = await NcrRequestService.getCountersNcr({});

  const sideCounter = pending_ncr_client + pending_ncr_lead;

  const payload = {
    routeName: 'request-ncr-specialists-digital',
    tag: sideCounter > 99 ? '+99' : sideCounter,
  };

  store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
};

const applicantsCounter = async () => {
  const module = store.state.auth.currentUser.modul_id;
  const params = {
    module_id: module,
  };
  const data = await RecruitmentService.getApplicantsPendingCounter(params);
  if (data.data[0].cn > 0) {
    const payload = {
      routeName: 'specialist-digital-recruitment-process',
      tag: data.data[0].cn,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const getPendinCustomerTickets = async () => {
  const { user_id, role_id } = store.state.auth.currentUser;
  const moduleId = null;
  const { data } = await Notifications.getCountPendingCustomerTickets({ user_id, moduleId });
  if (data[0].v_total_open > 0 && (role_id === 2 || role_id === 17)) {
    const payload = {
      routeName: 'specialist-digital-customer-tickets',
      tag: data[0].v_total_open,
    };
    store.commit('SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY', payload);
  }
};

const allCounterSpecialistsDigital = async () => {
  Promise.all([
    returnedLettersCounter(),
    projectsCounter(),
    pendingDirectRounds(),
    countProcessCE(),
    getCountClientCE(),
    applicationCounter(),
    ncrRealtorCounter(),
    getCountUpdates(),
    employeeClaims('specialist-d-employee-claims'),
    requestNotAsignedCounter(),
    requestNcrCounter(),
    applicantsCounter(),
    getPendinCustomerTickets(),
  ]);
};
export default allCounterSpecialistsDigital;
