<template>
  <div>
    <b-modal
      v-model="mutableIfModal"
      modal
      :title="title"
      size="lg"
      modal-class="modal-primary"
      title-tag="h3"
      hide-footer
      body-class="mb-2"
      :no-close-on-backdrop="true"
      centered
      @hidden="closeModal"
    >
      <!-- Form -->
      <ValidationObserver ref="form">
        <b-row style="margin: 0">
          <base-refund>
            <!-- Row Client -->
            <template #client>
              <b-form-input
                v-model="dataVoid.client_name"
                disabled
              />
            </template>
            <!-- Row Transaction ID -->
            <template #transaction>
              <b-form-input
                v-model="dataVoid.idtransaction"
                disabled
              />
            </template>
            <!-- Row Amount -->
            <template #amount>
              <validation-observer
                ref="form2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="amount"
                  :rules="`required|max_value:${initialVoidRefundAmount}|min_value:0`"
                >
                  <money
                    id="input-1a"
                    v-model="dataVoid.amount"
                    style="'border-color:rgba(255, 255, 255, 0.4)'"
                    v-bind="maskMoney"
                    class="form-control"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    $ {{ errors[0].includes('min')?`0 ${errors[0]} amount`:`${initialVoidRefundAmount} ${errors[0]} amount` }}
                  </small>

                </validation-provider>
              </validation-observer>
            </template>
            <!-- Row Date -->
            <template #date>
              <b-form-input
                v-model="statusDate"
                disabled
              />
            </template>
          </base-refund>
          <!-- Input Text Tarea Comment -->
          <div
            v-if="isPartial && dataVoid.type==1"
            class="col-lg-12 mt-1"
          >

            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  class="title_lable d-flex justify-content-center"
                  style="width: 15em"
                >{{ `PROGRAM ${title}` }}
                </b-input-group-text>
              </template>
              <b-form-datepicker
                v-model="programVoid"
                :min="minimumProgramVoid"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
            </b-input-group>
          </div>
          <div class="col-lg-12 mt-1">
            <ValidationProvider
              v-slot="{ errors }"
              name="comment"
              rules="required"
            >
              <div class="form-group row">
                <span>Comment</span>
                <b-textarea
                  v-model="comment"
                  class="input-form"
                  style="height: 140px"
                  :class="{ 'border-danger': errors[0] }"
                />
              </div>
            </ValidationProvider>
          </div>
          <!-- Button Submit -->
          <div class="col-lg-12">
            <div
              class="
                d-flex
                flex-column
                justify-content-center
                align-items-center
              "
            >
              <b-button
                v-if="
                  isCeo == 1 || ((isSupervisor == 2 || isChief) && moduleId == 4)
                "
                variant="success"
                @click="isPartial?sendPartialTransaction():sendVoid()"
              >
                SUBMIT {{ title }}
              </b-button>
            </div>
          </div>
        </b-row>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
// Import Base Refund
import BaseRefund from '../BaseComponent/BaseRefund.vue';
// Import Services
import PaymentService from '../service/payments.service';

export default {
  components: {
    BaseRefund,
  },
  props: {
    modalRefund: {
      type: Boolean,
    },
    global: {
      type: Object,
    },
    dataVoid: {
      type: Object,
    },
  },
  data() {
    return {
      mutableIfModal: this.modalRefund,
      comment: '',
      maskMoney: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        precision: 2,
        masked: false,
      },
      initialVoidRefundAmount: null,
      title: '',
      programVoid: null,
      minimumProgramVoid: null,
    };
  },
  computed: {
    statusAmount() {
      return `$ ${this.dataVoid.amount}`;
    },
    statusDate() {
      return this.dataVoid.settlement_date
        ? moment(this.dataVoid.settlement_date).format('MM/DD/YYYY')
        : '-';
    },
    isPartial() {
      return (
        parseFloat(this.dataVoid.amount)
        !== parseFloat(this.initialVoidRefundAmount)
      );
    },

    ...mapGetters({
      currentUser: 'auth/currentUser',
      isSupervisor: 'auth/isSupervisor',
      isCeo: 'auth/isCeo',
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    'dataVoid.amount': function () {
      if (this.isPartial) {
        this.title = this.title.includes('VOID')
          ? 'PARTIAL VOID'
          : ' PARTIAL REFUND';
      } else {
        this.title = this.title.includes('VOID') ? 'VOID' : 'REFUND';
      }
    },

  },
  created() {
    this.statusTitle();
    this.initialVoidRefundAmount = this.dataVoid.amount;
    if (this.dataVoid.settlement_date) {
      const date = new Date(this.dataVoid.settlement_date);
      const date2 = new Date(date.getTime() + 24 * 60 * 60 * 1000);
      this.minimumProgramVoid = date2.toISOString().slice(0, 10);
      this.programVoid = date2.toISOString().slice(0, 10);
    } else {
      this.minimumProgramVoid = new Date().toISOString().slice(0, 10);
      this.programVoid = new Date().toISOString().slice(0, 10);
    }
  },
  methods: {
    statusTitle() {
      this.title = this.dataVoid.type == 1
        ? 'VOID'
        : this.dataVoid.type == 2
          ? 'REFUND'
          : '';
    },
    closeModal() {
      this.$emit('close', false);
    },
    updateGrid() {
      this.$emit('updateGrid', false);
    },
    async sendPartialTransaction() {
      let data = null;
      const form2 = await this.$refs.form2.validate();
      const form = await this.$refs.form.validate();
      const params = {
        idtransaction: this.dataVoid.idtransaction,
        idmerchant: this.dataVoid.idmerchant,
        comment: this.comment,
        iduser: this.currentUser.user_id,
        idUser: this.currentUser.user_id,
        amount: this.dataVoid.amount,
        client_name: this.dataVoid.lead_name,
      };
      if (!form || !form2) {
        return;
      }
      const confirm = await this.showConfirmSwal(
        'Are you sure?',
        'You won\'t be able to revert this!',
      );
      if (confirm.isConfirmed) {
        try {
          this.addPreloader();
          if (this.dataVoid.type === 1) {
            const date = new Date(this.dataVoid.settlement_date);
            const hours = (`0${date.getHours()}`).slice(-2);
            const minutes = (`0${date.getMinutes()}`).slice(-2);
            const seconds = (`0${date.getSeconds()}`).slice(-2);
            const programmedVoid = `${this.programVoid} ${hours}:${minutes}:${seconds}`;
            params.datetoPay = programmedVoid;
            data = await PaymentService.programPartialVoidTransaction(params);
          } else {
            data = await PaymentService.refundPartialTransaction(params);
          }
          if (data.status === 200) {
            this.removePreloader();
            this.showSuccessSwal(data.data.message);
            this.closeModal();
          }
        } catch (error) {
          this.removePreloader();
          this.showErrorSwal(error);
        }
      }
    },
    // Send two types of refund
    async sendVoid() {
      const form2 = await this.$refs.form2.validate();

      this.$refs.form.validate().then(async success => {
        if (!success || !form2) {

        } else if (this.dataVoid.type == 1) {
          // Void
          const confirm = await this.showConfirmSwal(
            'Are you sure?',
            'You won\'t be able to revert this!',
          );
          if (confirm.isConfirmed) {
            try {
              // Request Void Transaction
              this.addPreloader();
              const params = {
                idtransaction: this.dataVoid.idtransaction,
                idmerchant: this.dataVoid.idmerchant,
                comment: this.comment,
                iduser: this.currentUser.user_id,
                client_name: this.dataVoid.lead_name,
              };

              const data = await PaymentService.voidTransaction(params);
              this.removePreloader();
              if (data.status == 200) {
                if (data.data.code == 1) {
                  this.closeModal();
                  this.updateGrid();
                  this.$swal.fire({
                    icon: 'success',
                    title: 'OPERATION SUCCESSFULLY',
                  });
                } else {
                  this.$swal.fire({
                    icon: 'warning',
                    title: data.data.message,
                  });
                  this.closeModal();
                  this.updateGrid();
                }
              } else {
                this.$swal.fire({
                  icon: 'warning',
                  title: data.data.message,
                });
                this.closeModal();
                this.updateGrid();
              }
            } catch (error) {
              this.removePreloader();
              this.showErrorSwal(error);
            }
          }
        } else {
          // Request Refund Transaction
          const confirm = await this.showConfirmSwal(
            'Are you sure?',
            'You won\'t be able to revert this!',
          );
          if (confirm.isConfirmed) {
            try {
              this.addPreloader();
              const data = await PaymentService.refundTransaction({
                idtransaction: this.dataVoid.idtransaction,
                idmerchant: this.dataVoid.idmerchant,
                comment: this.comment,
                iduser: this.currentUser.user_id,
                amount: this.dataVoid.amount,
              });
              this.removePreloader();
              if (data.status == 200) {
                if (data.data.code == 1) {
                  this.closeModal();
                  this.$swal.fire({
                    icon: 'success',
                    title: 'OPERATION SUCCESSFULLY',
                  });
                  this.updateGrid();
                } else {
                  this.$swal.fire({
                    icon: 'warning',
                    title: data.data.message,
                  });
                  this.closeModal();
                  this.updateGrid();
                }
              } else {
                this.$swal.fire({
                  icon: 'warning',
                  title: data.data.message,
                });
                this.closeModal();
                this.updateGrid();
              }
            } catch (error) {
              this.removePreloader();
              this.showErrorSwal(error);
            }
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.input-group > .input-group-prepend {
  flex: 0 0 40%;
}
.input-group .input-group-text {
  width: 100%;
}
</style>
